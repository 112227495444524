import React, { Component } from "react";
import { Container, Row, Col } from 'react-bootstrap';
import _ from "lodash";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { Typography, Select, Input, Button  } from 'antd';
import { getEditDMList } from "../../../../redux/actions/calendarAction";
import { getAllHierarchies,getAllAttributes, getMerchDetails, getLabelAndUOM } from "../../../../redux/actions/adgroup";
import SkuSelectionMaster from "./SkuSelectionMaster";
import PageLoader from "../../../../utils/Loader";
import CloseCircleFilled from '@ant-design/icons/CloseCircleFilled';

const { Text } = Typography;

const {
  // ADGROUP_LABELS,
  // ADGROUP_UOM_LIST,
  // MERCHID_MAPPING_LIST,
  ADGROUP_TYPE_OPTIONS
} = require('./utils/adgroup-constants');
export class CreateMasterAdGroup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      merchOptions: [],
      MERCHID_MAPPING_LIST : [],
      ADGROUP_LABELS : [],
      adgroup_labels : [],
      adgroup_uom_list : [],
      ADGROUP_UOM_LIST : [],
      // tempMerchOptions: [],
      adgroupNameValue: null,
      adgroupDescriptionValue: null,
      selectedAdgroupTypeValue: 'A',
      selectedMerchId: null,
      signDescriptionValue: null,
      selectedLabelValue: null,
      sublineValue: null,
      selected_UOM_Value: null,

      hierarchyBtnClicked: false,

      // adgroupNameValue: "Test",
      // adgroupDescriptionValue: "Test",
      // selectedAdgroupTypeValue: 'A',
      // selectedMerchId: [119],
      // signDescriptionValue: 'Test',
      // selectedLabelValue: 'ARTSMITH',
      // sublineValue: null,
      // selected_UOM_Value: "EA",

      // hierarchyBtnClicked: false,
      // pageLoading: false
    };
  }

  componentDidMount = async () => {


    const merchDetails = await this.props.getMerchDetails();
    const AdgroupDropDownData = await this.props.getLabelAndUOM();

    const tempAdGroupLabels = AdgroupDropDownData.data[0].vendor_label.map(data => {
         return {
            label : data,
            value : data
         }
    })

    this.setState ({adgroup_labels :  _.cloneDeep(tempAdGroupLabels)});

    const tempUomLabels = AdgroupDropDownData.data[0].uom.map(data => {
      return {
         label : data,
         value : data
      }
 })

    this.setState ({adgroup_uom_list :  _.cloneDeep(tempUomLabels)});

     const tempMerchDetailsList = merchDetails.buyerDetails.map(buyer => {
      return {
        Merch_Id: buyer.buyer_id,
        GMM : buyer.gmm,
        MerchandiseBuyerName: buyer.buyer_name  ?  buyer.buyer_name  : "N/A"
      }
    })

    this.setState ({MERCHID_MAPPING_LIST :  _.cloneDeep(tempMerchDetailsList)});

    const tempMerchDetails = merchDetails.buyerDetails.map(buyer => {
      const key = buyer.buyer_name ? `${buyer.buyer_id}_${buyer.buyer_name}` : buyer.buyer_id;
      return {
        label: buyer.buyer_id,
        value: buyer.buyer_id,
        key: key
      }
    })

    this.setState({merchOptions : tempMerchDetails});


    this.props.getAllHierarchies()
    this.props.getAllAttributes()
    // let merchId = localStorage.getItem("merchid"),
    //   merchIdArry = merchId && merchId !== "null" ? JSON.parse(merchId) : [],
    //   merchOptions = [];

    // _.map(merchIdArry, (item) => {
    //   // let labelVar = item.shops
    //   //   ? item.merch_id + " - " + item.shops
    //   //   : item.merch_id;
    //   let labelVar = item.shops
    //     ? item.merch_id
    //     : item.merch_id;
    //   merchOptions.push({ value: item.merch_id, label: labelVar });
    // });
    // let val = !_.isEmpty(merchIdArry) ? merchOptions[0] : "";
    // const sortedMerchOptions = _.sortBy(merchOptions, ['value']);

    // this.setState({ merchOptions: sortedMerchOptions });
    // this.props.merchIdHandler(val);
  };

  handleSelectionChange = (e, columnName) => {
    let inputValue = _.get(e, 'target.value', null);

    if (_.isNil(columnName) || _.isNil(inputValue)) {
      return null;
    }

    if (_.eq(columnName, 'adgroupNameValue')) {
      inputValue = _.toUpper(inputValue);
    }

    this.setState({ [columnName]: inputValue })
  }

  handleSelectionChangeV2 = (value, columnName) => {
    if (_.isNil(columnName) || _.isNil(value)) {
      return null;
    }
    this.setState({ [columnName]: value })
  }

  togglePageLoading = (v) => {
    this.setState({ pageLoading: v })
  }

  render() {
    const { 
      adgroupNameValue, 
      adgroupDescriptionValue,
      selectedAdgroupTypeValue, 
      selectedMerchId,
      signDescriptionValue,
      selectedLabelValue,
      selected_UOM_Value,
      sublineValue,
      hierarchyBtnClicked,
      pageLoading
    } = this.state;

    const isHierarchyBtnEnabled= !_.isEmpty(adgroupNameValue) && !_.isEmpty(adgroupDescriptionValue) && !_.isEmpty(selectedAdgroupTypeValue) && !_.isEmpty(selectedMerchId) 
    && !_.isEmpty(signDescriptionValue) && !_.isEmpty(selectedLabelValue) && !_.isEmpty(selected_UOM_Value);

   
    let merchantBuyerName = null;
    let GMM = null;

    if(!_.isEmpty(selectedMerchId)) {
      const mapping = _.find(this.state.MERCHID_MAPPING_LIST, (o) => _.eq(o.Merch_Id, selectedMerchId));
      merchantBuyerName = _.get(mapping, 'MerchandiseBuyerName', null);
      GMM = _.get(mapping, 'GMM', null);
    }

    return (
      <div>
        <PageLoader loader={pageLoading}>

          <div className="childCompStyling" style={{ border: "none" }}>

            <div className="createClusterDiv card cardStyles cardGap">

              <Container fluid>

                <Row style={{ marginTop: '10px', marginBottom: '10px'}}>
                  <Col lg>
                    <p className="summaryPText">Adgroup Name</p>
                    <Input
                      id="adgroupNameInput" 
                      showCount 
                      maxLength={10}
                      placeholder="Please Enter Adgroup Name"
                      onChange={(e) => this.handleSelectionChange(e, 'adgroupNameValue')}
                      value={adgroupNameValue}
                      style={{ width: '100%'}}
                      allowClear={false}
                      status={_.isEmpty(adgroupNameValue) ? "error" : null}
                      suffix={_.isEmpty(adgroupNameValue) ? <CloseCircleFilled /> : null}
                    />
                  </Col>
                  <Col lg>
                    <p className="summaryPText">Adgroup Description </p>
                    <Input
                      id="adgroupDescriptionInput" 
                      showCount 
                      maxLength={100}
                      placeholder="Please Enter Adgroup Description"
                      onChange={(e) => this.handleSelectionChange(e, 'adgroupDescriptionValue')}
                      value={adgroupDescriptionValue}
                      style={{ width: '100%'}}
                      allowClear={false}
                      status={_.isEmpty(adgroupDescriptionValue) ? "error" : null}
                      suffix={_.isEmpty(adgroupDescriptionValue) ? <CloseCircleFilled /> : null}
                    />
                  </Col>
                  <Col lg>
                    <p className="summaryPText">Adgroup Type</p>
                    <Select
                      placeholder="Please Select Adgroup Type"
                      value={selectedAdgroupTypeValue}
                      options={[
                        {
                            "label": "Active",
                            "value": "A"
                        },
                        {
                            "label": "Clearance",
                            "value": "C",
                            "disabled": (localStorage.getItem("role") === "2")
                        },
                        {
                          "label": "Deactive",
                          "value": "D",
                          "disabled": true
                        }
                      ]}
                      onChange={(e) => this.handleSelectionChangeV2(e, 'selectedAdgroupTypeValue')}
                      style={{ width: '100%'}}
                      allowClear={false}
                      suffixIcon={_.isEmpty(selectedAdgroupTypeValue) ? <CloseCircleFilled style={{ color: 'red'}} /> : null}
                    />
                  </Col>
                  <Col lg>
                    <p className="summaryPText">Merch Id</p>
                    <Select
                      placeholder="Please Select Merch Id"
                      value={selectedMerchId}
                      options={this.state.merchOptions}
                      onChange={(e) => this.handleSelectionChangeV2(e, 'selectedMerchId')}
                      style={{ width: '100%'}}
                      allowClear={false}
                      showSearch={true}
                      filterOption={(input, option) =>
                        option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                        option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      suffixIcon={_.isEmpty(selectedMerchId) ? <CloseCircleFilled style={{ color: 'red'}} /> : null}
                    />
                  </Col>
                </Row>

                <Row style={{ marginTop: '10px', marginBottom: '10px'}}>
                  <Col lg>
                    <p className="summaryPText">Sign Description</p>
                    <Input
                      id="signDescriptionInput" 
                      showCount 
                      maxLength={128}
                      placeholder="Please Enter Sign Description"
                      onChange={(e) => this.handleSelectionChange(e, 'signDescriptionValue')}
                      value={signDescriptionValue}
                      style={{ width: '100%'}}
                      allowClear={false}
                      status={_.isEmpty(signDescriptionValue) ? "error" : null}
                      suffix={_.isEmpty(signDescriptionValue) ? <CloseCircleFilled /> : null}
                    />
                  </Col>
                  <Col lg>
                    <p className="summaryPText">Label</p>
                    <Select
                      placeholder="Please Select Label"
                      value={selectedLabelValue}
                      options={this.state.adgroup_labels}
                      onChange={(e) => this.handleSelectionChangeV2(e, 'selectedLabelValue')}
                      style={{ width: '100%'}}
                      allowClear={false}
                      suffixIcon={_.isEmpty(selectedLabelValue) ? <CloseCircleFilled style={{ color: 'red'}} /> : null}
                      showSearch={true}
                      filterOption={(input, option) =>
                        option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                        option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                    />
                  </Col>
                  <Col lg>
                    <p className="summaryPText">Unit Of Measure</p>
                    <Select
                      placeholder="Please Select Unit of Measure"
                      value={selected_UOM_Value}
                      options={this.state.adgroup_uom_list}
                      onChange={(e) => this.handleSelectionChangeV2(e, 'selected_UOM_Value')}
                      style={{ width: '100%'}}
                      allowClear={false}
                      suffixIcon={_.isEmpty(selected_UOM_Value) ? <CloseCircleFilled style={{ color: 'red'}} /> : null}
                    />
                  </Col>
                  <Col lg>
                    <p className="summaryPText">Subline</p>
                    <Input
                      id="sublineInput" 
                      showCount 
                      maxLength={75}
                      placeholder="Subline"
                      onChange={(e) => this.handleSelectionChange(e, 'sublineValue')}
                      value={sublineValue}
                      style={{ width: '100%'}}
                      allowClear={false}
                    />
                  </Col>
                </Row>

                <Row style={{ marginTop: '10px'}}>
                  <Col>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '10px'}}>
                      <div className="col-md-3" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <p className="summaryPText invisible">text</p>

                        <Button 
                          type="primary" 
                          onClick={() => {
                            this.setState({ hierarchyBtnClicked: true })
                          }}
                          disabled={!isHierarchyBtnEnabled}
                        >
                          Hierarchy & Attribute Selection
                        </Button>
                        
                      </div>
                    </div>
                  </Col>
                </Row>
                
                <hr style={{height: '1px', borderWidth: '0', backgroundColor: '#d3d3d3'}} />
                <Row>
                  <Col lg>
                    <Row>
                    </Row>

                    <Row>
                      <Col lg>
                          <Text className="summaryPText">Merch Mgr. Name: </Text>
                          <Text code>{merchantBuyerName}</Text>
                      </Col>
                      <Col>
                        <Text className="summaryPText">GMM: </Text>
                        <Text code>{GMM}</Text>
                      </Col>
                      <Col>
                      </Col>
                      <Col>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Container>


            </div>

            <SkuSelectionMaster 
              adgroupName={adgroupNameValue}
              adgroupDescription={adgroupDescriptionValue}
              selectedAdgroupType={selectedAdgroupTypeValue}
              merchId={selectedMerchId}
              signDescription={signDescriptionValue}
              label={selectedLabelValue}
              uom={selected_UOM_Value}
              subline={sublineValue}
              gmm={GMM}
              merchantBuyerName={merchantBuyerName}
              isHierarchyBtnEnabled={isHierarchyBtnEnabled}
              hierarchyBtnClicked={hierarchyBtnClicked}
              togglePageLoading={this.togglePageLoading}
              pageLoading={pageLoading}
              pageType={'create'}
            />
          </div>
          
        </PageLoader>

      </div>
    );
  }
}

CreateMasterAdGroup.propTypes = {
  getEditDMList: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({});


export default connect(mapStateToProps, {
  getEditDMList,
  getAllHierarchies,
  getAllAttributes,
  getMerchDetails,
  getLabelAndUOM
})(withRouter(CreateMasterAdGroup));
