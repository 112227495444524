import React, { Component } from "react";
import CreateMasterAdGroup from "../AdgroupMaster/CreateMasterAdgroup";
import AddAdgroupRow from "./AddAdgroupRow";
import {
  getSKUDetailsFromAdgroupId,
  adgroupListForCreate,
  createAdgroup,
  createBulkAdgroup,
  fetchRegSalesPrices,
  fetchRegPrices,
  fetchPromoBuildStatus
} from "../../../../redux/actions/adgroup";
import { createMasterAdgroup } from "../../../../redux/actions/adgroupMaster";
import {
  editReferenceSku,
  infoReferenceSku
} from "../../../../redux/actions/referenceSkuActions";
import { usersLogReport } from "../../../../redux/actions/userAction";
import { saveAdgroup } from "../../../../redux/actions/SimulationAction";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import AlertDanger from "../../../../utils/AlertMessage/alertDanger";
import AlertSuccess from "../../../../utils/AlertMessage/alertSuccess";
import SkuModal from "./SkuModalForAdgroupCreate";
import { getEditDMList } from "../../../../redux/actions/calendarAction";
import { similarSkuProductsList } from "../../../../redux/actions/referenceSkuActions";
import _ from "lodash";
import PageLoader from "../../../../utils/Loader";
import BackToCalendar from "../../../../utils/BackToCalendar";
import queryString from "query-string";
import moment from "moment";
import offerTypeOptions from "../../../../utils/OfferTypes";
import OfferReplaceModal from "../Promo Simulation/OfferReplaceModal";
import Modal from "react-awesome-modal";
import { maxProductCount } from '../../constants';
import { PromotionCalendarGA } from '../../../../utils/GA/GAUtils';
import { alertTimer } from "../../../../utils/alertTimer";
import {getSignShellOptions} from '../../../../redux/actions/signShellAction'
import {getPromosData} from '../../../../redux/actions/adgroup'

import {
  SIGNSHELL_OPTIONS,
  PROMO_TYPE_OPTIONS
} from "./constants";

import {
  AtypeOfferValidator,
  PtypeOfferValidator,
  FtypeOfferValidator,
  GtypeOfferValidator,
  BtypeOfferValidator,
  CtypeOfferValidator,
  DtypeOfferValidator
} from "../../../../utils/offerValueValidators";
// import { isIt } from "../../../../utils/getRoleName";

let promotionName = null,
  campaignStartDate = null,
  campaignEndDate = null,
  campaignDetailsForCreateGlobal;

export class CreateOffer extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      isSaveClicked: false,
      key: "existingAdgrp",
      campaignDetailsForCreate: "",
      showSelectProducts: false,
      showSave: false,
      disableSave: true,
      adgroupName: "",
      adgroupDesc: "",
      adgroupEventId: "",
      adgroupOfferType: "",
      adgroupOfferValue: "",
      adgroupStartDate: "",
      adgroupEndDate: "",
      eventType: "",
      adgroupCampaignId: "",
      productListCreate: "",
      adgroupOptionArray: [],
      showErrorFlag: false,
      showAdgroupSavedMessage: false,
      clearAdGroupName: false,
      showEmptyFieldsMessage: false,
      adgroupsEditedMessage: false,
      clearProductList: false,
      eventTypeOptions: [],
      showSkuModal: false,
      SKUTableData: [],
      skuFootnote: [],
      tableLoader: true,
      adgroupSaved: false,
      showSaveBtnAddFromExisting: false,
      savedRefSkuMsg: false,
      adgroupLoader: false,
      somethingWentWrong: false,
      adgroupExistsMsg: "",
      selectRefSkuError: false,
      showDetailPopup: false,
      refSkuInfoResponse: "",
      refSkuOptions: [],
      refreshRows: false,
      adgroupRowValue: [
        {
          key: 0,
          adgroupName: [],
          adgroupDesc: "",
          selectedDate: [],
          selectedEvent: [],
          startDate: "",
          endDate: "",
          offerType: [],
          offerValue: "",
          fixedPriceQty: "",
          minQtyForOffer: "",
          bonusBuy: "",
          merchId: "",
          eventId: "",
          eventDesc: "",
          promo_sign_shell: [],
          ty_buy_min_value: "",
          ty_get_max_value: "",
          promo_event_type: [],
          uas: [],
          adgroupType: "",
          disableRowSave: false,
          type: ""
        }
      ],
      fromAdgroupMaster: false,
      alertDanger: false,
      alertDangerMsg: "",
      showOfferReplaceModal: false,
      showOfferReplaceText: "",
      keyStore: null,
      merchId: "",
      saveBeforeClose: false,
      errorMsg: '',
      isSuccess: false,
      successMessage: "",
      isFailure : false,
      failureMessage : "",
      promoBuildStatus: "P" ,
      SIGNSHELL_OPTIONS2 : []
    };
  }

  promoTypeFormatter = value => {
    let promoTypeSet = JSON.parse(localStorage.getItem("promo_type"));
    if (value) {
      // return promoTypeSet ? promoTypeSet[value] : value;
      return value;
    }
  };

  getPromoType = value => {
    if (value === "D") {
      return "D"
    }

    return "P";
  }
  componentDidMount = async () => {
    let tempData = await this.props.getSignShellOptions()

    const signShellArray = tempData.signShell;
    const formattedData = signShellArray.map(item => ({
      value : item.value ,
      label : item.label
    }))

    this.setState({
      SIGNSHELL_OPTIONS2 : formattedData
    }, ()=> {
      console.log(this.state.SIGNSHELL_OPTIONS2)
    })
    
    let campaignID = localStorage.getItem("campaignId");

    let queries = queryString.parse(this.props.location.search);
    if (queries.fromAdgroupMaster) {
      this.setState({
        fromAdgroupMaster: true
      });
    }
    var eventTypeOptions = [],
      adgroupDropdownList = [];
    campaignDetailsForCreateGlobal = localStorage.getItem(
      "campaignDetailsForCreate"
    );

    if (!_.isEmpty(campaignDetailsForCreateGlobal)) {
      this.setState({
        campaignDetailsForCreate: campaignDetailsForCreateGlobal
      });

      let resForAdgroup = await this.props.adgroupListForCreate({
        campaignId: parseInt(campaignDetailsForCreateGlobal),
        searchKey: "",
        h3:
          localStorage.getItem("shop") &&
            localStorage.getItem("shop") !== "null"
            ? localStorage.getItem("shop").split(",")
            : [],
        h5:
          localStorage.getItem("businessDesc") &&
            localStorage.getItem("businessDesc") !== "null"
            ? localStorage.getItem("businessDesc").split(",")
            : [],
        h6:
          localStorage.getItem("customerGrp") &&
            localStorage.getItem("customerGrp") !== "null"
            ? localStorage.getItem("customerGrp").split(",")
            : []
      });

      if (resForAdgroup.status) {
        resForAdgroup.data.map(resData => {
          adgroupDropdownList.push({
            value: resData.name,
            label: resData.name,
            adgroupId: resData.adgroup_id,
            adgroup_desc: resData.description,
            productList: resData.product_list,
            merchId: resData.merch_id,
            adgroupType: resData.adgroup_type,
          });
          return resData;
        });

        this.setState({
          adgroupOptionArray: adgroupDropdownList
        });
      } else {
        this.props.history.push("/promotion");
      }
      if (queries.fromAdgroupMaster) {
        return;
      }
      let res = await this.props.getEditDMList({
        campaignId: campaignDetailsForCreateGlobal
      });

      if (res.status) {
        res.data.all_event_data.map(eventOptions => {
          let promoTypeSet = JSON.parse(localStorage.getItem("promo_type")),
            found = _.filter(res.data, {
              event_type: eventOptions.event_type
            }),
            dateRangeArr = _.map(found, item => {
              return {
                value:
                  item.start_date.slice(0, 10) +
                  "-" +
                  item.end_date.slice(0, 10),
                label:
                  item.start_date.slice(5, 10).replace(/-/g, "/") +
                  "-" +
                  item.end_date.slice(5, 10).replace(/-/g, "/")
              };
            });
          let eventLabel = this.promoTypeFormatter(eventOptions.event_type);
          let promoTypeLabel = this.getPromoType(eventOptions.type);
          eventTypeOptions.push({
            value:
              eventOptions.type +
              "-" +
              eventLabel +
              "-" +
              moment(eventOptions.start_date).format("MM/DD/YYYY") +
              "-" +
              moment(eventOptions.end_date).format("MM/DD/YYYY") + 
               "-" +
              eventOptions.promo_group_id,

            // label: promoTypeSet
            //   ? promoTypeSet[eventOptions.event_type]
            //   : eventOptions.event_type,
            label:
              eventOptions.type +
              "-" +
              eventLabel +
              "-" +
              moment(eventOptions.start_date).format("MM/DD/YYYY") +
              "-" +
              moment(eventOptions.end_date).format("MM/DD/YYYY") + "-" + eventOptions.description,

            eventId: eventOptions.event_id,
            event_type: eventOptions.event_type,
            dateRange: dateRangeArr,
            start_date: eventOptions.start_date,
            end_date: eventOptions.end_date,
            eventDesc: eventOptions.description || 'desc',
            promo_sign_shell: this.state.SIGNSHELL_OPTIONS2.filter(option => option.value.toUpperCase() == eventOptions.sign_shell.toUpperCase()),
            // promo_sign_shell : this.state.SIGNSHELL_OPTIONS2 ,
            promo_event_type: PROMO_TYPE_OPTIONS.filter(option => option.value == promoTypeLabel),
            type: eventOptions.type,
            eventGId: eventOptions.g_eid,
            promo_group_id: eventOptions.promo_group_id
          });
          return eventOptions;
        });
        this.setState({
          eventTypeOptions: eventTypeOptions // _.uniqBy(eventTypeOptions, "value")
        });
      }

      // await this.adgroupKeyDownHandler();
    }

    let refSkuSearchRes = await this.props.similarSkuProductsList({
      modelable_sku: true,
      search_key: ""
    });
    let list = _.map(refSkuSearchRes.data, item => {
      return {
        value: item.unique_id1,
        label: item.unique_id1
      };
    });
    list.unshift({ value: "none", label: "none" });

    const reqObj = { 
      campaign_id: campaignID
    }

    const promoBuildDetails = await this.props.fetchPromoBuildStatus(reqObj);

    this.setState({
      refSkuOptions: list,
      fromAdgroupMaster: queries.fromAdgroupMaster ? true : false,
      promoBuildStatus: promoBuildDetails[0] ? promoBuildDetails[0].promo_build_status : 'P'
    });
  };

  componentWillMount = () => {
    promotionName = localStorage.getItem("campaignName");
    campaignStartDate = localStorage.getItem("campStartDate");
    campaignEndDate = localStorage.getItem("campEndDate");
  };
  componentWillReceiveProps(nextProps) {
    if (nextProps.clearAdGroupName === true) {
      this.setState({
        clearAdGroupName: true
      });

      setTimeout(() => {
        this.setState({
          clearAdGroupName: false
        });
      }, 100);
    }

    if (nextProps.clearProductList === true) {
      this.setState({
        clearProductList: true
      });

      setTimeout(() => {
        this.setState({
          clearProductList: false
        });
      }, 200);
    }
  }
  merchIdHandler = merchId => {
    this.setState({
      merchId
    });
  };

  adgroupKeyDownHandler = async val => {
    if (val.length) {
      let adgroupDropdownList = [];

      let resForAdgroup = await this.props.adgroupListForCreate({
        campaignId: parseInt(campaignDetailsForCreateGlobal),
        searchKey: val,
        h3:
          localStorage.getItem("shop") &&
            localStorage.getItem("shop") !== "null"
            ? localStorage.getItem("shop").split(",")
            : [],
        h5:
          localStorage.getItem("businessDesc") &&
            localStorage.getItem("businessDesc") !== "null"
            ? localStorage.getItem("businessDesc").split(",")
            : [],
        h6:
          localStorage.getItem("customerGrp") &&
            localStorage.getItem("customerGrp") !== "null"
            ? localStorage.getItem("customerGrp").split(",")
            : []
      });

      if (resForAdgroup.status) {
        resForAdgroup.data.map(resData => {
          adgroupDropdownList.push({
            value: resData.name,
            label: resData.name,
            adgroupId: resData.adgroup_id,
            adgroup_desc: resData.description,
            productList: resData.product_list,
            merchId: resData.merch_id,
            adgroupType: resData.adgroup_type
          });
          return resData;
        });

        this.setState({
          adgroupOptionArray: adgroupDropdownList
        });
      } else {
        this.props.history.push("/promotion");
      }
    }
  };

  showSelectProducts = e => {
    this.setState({
      showSelectProducts: !this.state.showSelectProducts
    });
  };

  getRegSalesPrices = async (key) => {
    let { adgroupRowValue } = this.state,
      campaignDetailsForCreate = localStorage.getItem(
        "campaignDetailsForCreate"
      );
    let selectedDateValidity = moment(adgroupRowValue[key] && adgroupRowValue[key].selectedEvent && adgroupRowValue[key].selectedEvent[0] && adgroupRowValue[key].selectedEvent[0].end_date).isSameOrAfter(moment(), 'day')
    let adgroupRow = _.cloneDeep(adgroupRowValue);
    let adgroupRowForApi = JSON.parse(JSON.stringify(adgroupRowValue));

    let selectedOffer = _.find(adgroupRow, { key: key });

    let fieldName =
      offerTypeOptions.fieldValidation1[selectedOffer.offerType.value];

    this.submitGA("Click", "Clicked_getRegSalesPrice", 1);

    // return;
    if (fieldName) {
      let findEmptyFields = _.find(fieldName, field => {
        return (
          !selectedOffer[field] ||
          selectedOffer[field] === "" ||
          selectedOffer[field] === 0
        );
      });
      if (!_.isEmpty(findEmptyFields)) {
        this.setState({
          alertDanger: true,
          alertDangerMsg: "Please enter all the fields"
        });
        setTimeout(() => {
          this.setState({ alertDanger: false, alertDangerMsg: "" });
        }, alertTimer);
        return;
      }
    } else {
      //Check if values is entered or not
      this.setState({
        alertDanger: true,
        alertDangerMsg: "Please select offer for selected Adgroup"
      });
      setTimeout(() => {
        this.setState({ alertDanger: false, alertDangerMsg: "" });
      }, alertTimer);
      return;
    }
    if(selectedOffer.offerType.value === "A") {
      const values = {
        plannedAmount: selectedOffer.offerValue,
        adgroupName: selectedOffer.adgroupName.value
      }

      const promoRegPrices = {
        regLow : null,
        regHigh: null
      }

      const offerValueValidationRes = AtypeOfferValidator(values, promoRegPrices, true);

      if(offerValueValidationRes.isInvalid){
        this.setState({
          alertDanger: offerValueValidationRes.isInvalid,
          alertDangerMsg: offerValueValidationRes.invalidationMsg
        });

        setTimeout(() => {
          this.setState({ alertDanger: false, alertDangerMsg: "" });
        }, alertTimer);
        return;
      }
    }

    if(selectedOffer.offerType.value === "P") {
      const values = {
        plannedAmount: selectedOffer.offerValue,
        adgroupName: selectedOffer.adgroupName.value
      }

      const promoRegPrices = {
        regLow : null,
        regHigh: null
      }

      const offerValueValidationRes = PtypeOfferValidator(values, promoRegPrices, true);

      if(offerValueValidationRes.isInvalid){
        this.setState({
          alertDanger: offerValueValidationRes.isInvalid,
          alertDangerMsg: offerValueValidationRes.invalidationMsg
        });

        setTimeout(() => {
          this.setState({ alertDanger: false, alertDangerMsg: "" });
        }, alertTimer);
        return;
      }
    }

    if(selectedOffer.offerType.value === "F") {
      const values = {
        plannedAmount: selectedOffer.offerValue,
        fixedPriceQty: selectedOffer.fixedPriceQty,
        adgroupName: selectedOffer.adgroupName.value
      }

      const promoRegPrices = {
        regLow : null,
        regHigh: null
      }

      const offerValueValidationRes = FtypeOfferValidator(values, promoRegPrices, true);

      if(offerValueValidationRes.isInvalid){
        this.setState({
          alertDanger: offerValueValidationRes.isInvalid,
          alertDangerMsg: offerValueValidationRes.invalidationMsg
        });

        setTimeout(() => {
          this.setState({ alertDanger: false, alertDangerMsg: "" });
        }, alertTimer);
        return;
      }
    }

    if(selectedOffer.offerType.value === "G") {
      const values = {
        minQuantity: selectedOffer.minQtyForOffer, 
        bonusBuy: selectedOffer.bonusBuy, 
        buyMinVal: selectedOffer.ty_buy_min_value, 
        getMaxVal: selectedOffer.ty_get_max_value,
        adgroupName: selectedOffer.adgroupName.value
      }

      const promoRegPrices = {
        regLow : null,
        regHigh: null
      }

      const offerValueValidationRes = GtypeOfferValidator(values, promoRegPrices, true);

      if(offerValueValidationRes.isInvalid){
        this.setState({
          alertDanger: offerValueValidationRes.isInvalid,
          alertDangerMsg: offerValueValidationRes.invalidationMsg
        });

        setTimeout(() => {
          this.setState({ alertDanger: false, alertDangerMsg: "" });
        }, alertTimer);
        return;
      }
    }

    if(selectedOffer.offerType.value === "B") {
      const values = {
        minQuantity: selectedOffer.minQtyForOffer, 
        bonusBuy: selectedOffer.bonusBuy, 
        plannedAmount: selectedOffer.offerValue,
        adgroupName: selectedOffer.adgroupName.value
      }

      const promoRegPrices = {
        regLow : null,
        regHigh: null
      }

      const offerValueValidationRes = BtypeOfferValidator(values, promoRegPrices, true);

      if(offerValueValidationRes.isInvalid){
        this.setState({
          alertDanger: offerValueValidationRes.isInvalid,
          alertDangerMsg: offerValueValidationRes.invalidationMsg
        });

        setTimeout(() => {
          this.setState({ alertDanger: false, alertDangerMsg: "" });
        }, alertTimer);
        return;
      }
    }

    if(selectedOffer.offerType.value === "C") {
      const values = {
        plannedAmount: selectedOffer.offerValue,
        minQuantity: selectedOffer.minQtyForOffer, 
        bonusBuy: selectedOffer.bonusBuy, 
        buyMinVal: selectedOffer.ty_buy_min_value, 
        getMaxVal: selectedOffer.ty_get_max_value,
        adgroupName: selectedOffer.adgroupName.value
      }
      const promoRegPrices = {
        regLow : null,
        regHigh: null
      }
      const offerValueValidationRes = CtypeOfferValidator(values, promoRegPrices, true);

      if(offerValueValidationRes.isInvalid){
        this.setState({
          alertDanger: offerValueValidationRes.isInvalid,
          alertDangerMsg: offerValueValidationRes.invalidationMsg
        });

        setTimeout(() => {
          this.setState({ alertDanger: false, alertDangerMsg: "" });
        }, alertTimer);
        return;
      }
    }

    if(selectedOffer.offerType.value === "D") {
      const values = {
        minQuantity: selectedOffer.minQtyForOffer, 
        plannedAmount: selectedOffer.offerValue,
        adgroupName: selectedOffer.adgroupName.value
      }
      const promoRegPrices = {
        regLow : null,
        regHigh: null
      }
      const offerValueValidationRes = DtypeOfferValidator(values, promoRegPrices, true);

      if(offerValueValidationRes.isInvalid){
        this.setState({
          alertDanger: offerValueValidationRes.isInvalid,
          alertDangerMsg: offerValueValidationRes.invalidationMsg
        });

        setTimeout(() => {
          this.setState({ alertDanger: false, alertDangerMsg: "" });
        }, alertTimer);
        return;
      }
    }

    console.log("selectedOffer", selectedOffer);

    const reqObj = {
      eventId: selectedOffer.eventId,
      adgroup_id: selectedOffer.adgroupName.adgroupId,
      offerType: selectedOffer.offerType.value,
      minQuantity: selectedOffer.minQtyForOffer,
      bonusPay: selectedOffer.bonusBuy,
      plannedAmount: selectedOffer.offerValue,
      fixedPriceQuantity: selectedOffer.fixedPriceQty
    }

    this.setState({ adgroupLoader: true })
    this.props.fetchRegSalesPrices(reqObj).then(res => {

      if (res.data) {
        adgroupRowValue[key].regLow = res.data[0].regLow
        adgroupRowValue[key].regHigh = res.data[0].regHigh
        adgroupRowValue[key].saleLow = res.data[0].saleLow
        adgroupRowValue[key].saleHigh = res.data[0].saleHigh
        console.log(res);

        this.setState({ adgroupRowValue: adgroupRowValue, adgroupLoader: false })
      }

    })
  }

  saveAdgroupValues = async () => {
    this.setState({ 
    adgroupLoader: true
  });



    // if (this.state.fromAdgroupMaster) {
    //   let res = await this.props.createMasterAdgroup({
    //     name: this.state.adgroupName,
    //     productList: this.state.productListCreate,
    //     adgroupDesc: this.state.adgroupDesc,
    //     merchId: this.state.merchId ? this.state.merchId.value : null
    //   });
    //   if (res.status) {
    //     this.setState({
    //       adgroupSaved: true,
    //       productListCreate: [],
    //       showAdgroupSavedMessage: true,
    //       adgroupLoader: false
    //     });

    //     setTimeout(() => {
    //       this.setState({
    //         showAdgroupSavedMessage: false
    //       });
    //     }, 2500);
    //   } else {
    //     this.setState({
    //       somethingWentWrong: true,
    //       adgroupLoader: false,
    //       adgroupExistsMsg: res.msg
    //     });

    //     setTimeout(() => {
    //       this.setState({ somethingWentWrong: false });
    //     }, 3300);
    //   }
    //   return;
    // }
    if (!_.isEmpty(this.state.productListCreate) && this.state.productListCreate.length <= maxProductCount) {
      let createAdgroupResponse = await this.props.createAdgroup({
        adgroupName: this.state.adgroupName,
        adgroupDesc: this.state.adgroupDesc,
        productList: this.state.productListCreate,
        eventId: this.state.adgroupEventId,
        campaignId: parseInt(this.state.adgroupCampaignId),
        offerValue: parseFloat(this.state.adgroupOfferValue),
        offerType: this.state.adgroupOfferType,
        startDate: this.state.adgroupStartDate,
        endDate: this.state.adgroupEndDate,
        eventType: this.state.eventType,
        createNewAdgroup: true
      });

      if (createAdgroupResponse.status) {
        if (createAdgroupResponse.inPromoApproval) {
          this.setState({
            isSuccess: true,
            successMessage: "Promo offer/s has been submitted for approval",
            adgroupLoader: false,
            productListCreate: [],
          });

          setTimeout(() => {
            this.setState({
              isSuccess: false,
              successMessage: ''
            });
          }, alertTimer);
        } else {
          this.setState({
            adgroupSaved: true,
            productListCreate: [],
            showAdgroupSavedMessage: true,
            adgroupLoader: false
          });

          setTimeout(() => {
            this.setState({
              showAdgroupSavedMessage: false
            });
          }, alertTimer);
        }
      } else {
        this.setState({
          somethingWentWrong: true,
          adgroupLoader: false,
          adgroupExistsMsg: "Something went wrong"
        });

        setTimeout(() => {
          this.setState({ somethingWentWrong: false });
        }, alertTimer);
      }
    } else {

      let errorMsg = _.isEmpty(this.state.productListCreate) ? "Please select product list!" : `Number of selected products should be less than ${maxProductCount} `
      this.setState({
        showErrorFlag: true,
        errorMsg
      });

      setTimeout(() => {
        this.setState({ showErrorFlag: false, errorMsg: '' });
      }, alertTimer);
    }
  };

  isnumberInRange = (value, minValue, maxValue) => {
    if(value >= minValue && value <= maxValue )
      return true;

    return false;
  }

  saveAdgroupValuesAddFromExisting = async (key, replace) => {
    this.setState({alertDanger: false, alertDangerMsg:""})
    let { adgroupRowValue } = this.state,
      campaignDetailsForCreate = localStorage.getItem(
        "campaignDetailsForCreate"
      );
    let selectedDateValidity = moment(adgroupRowValue[key] && adgroupRowValue[key].selectedEvent && adgroupRowValue[key].selectedEvent[0] && adgroupRowValue[key].selectedEvent[0].end_date).isSameOrAfter(moment(), 'day')
    let adgroupRow = _.cloneDeep(adgroupRowValue);
    let adgroupRowForApi = JSON.parse(JSON.stringify(adgroupRowValue));

    let adgroupRowFound = _.find(adgroupRow, { key: key });

    let fieldName =
      offerTypeOptions.fieldValidation1[adgroupRowFound.offerType.value];

    const regReqObj = [
      {
        adgroup_id: adgroupRowFound.adgroupName.adgroupId,
        eventId: adgroupRowFound.selectedEvent[0].eventId,
      }
    ];

    const regPricesRes = await this.props.fetchRegPrices(regReqObj);

    const regpricesObj = {};

    regPricesRes.forEach(ele => {
      const promoKey = `${ele.eventId}_${ele.adgroup_id}`;
      regpricesObj[promoKey] = ele;
    })

    // return;
    if (fieldName) {
      let findEmptyFields = _.find(fieldName, field => {
        return (
          !adgroupRowFound[field] ||
          adgroupRowFound[field] === "" ||
          adgroupRowFound[field] === 0
        );
      });
      if (!_.isEmpty(findEmptyFields)) {
        this.setState({
          alertDanger: true,
          alertDangerMsg: "Please enter all the fields"
        });
        setTimeout(() => {
          this.setState({ alertDanger: false, alertDangerMsg: "" });
        }, alertTimer);
        return;
      }
    } else {
      //Check if values is entered or not
      this.setState({
        alertDanger: true,
        alertDangerMsg: "Please select offer for selected Adgroup"
      });
      setTimeout(() => {
        this.setState({ alertDanger: false, alertDangerMsg: "" });
      }, alertTimer);
      return;
    }

    let buyMinVal = adgroupRowFound.ty_buy_min_value;
    let getMaxVal = adgroupRowFound.ty_get_max_value;

    const promoKey = `${adgroupRowFound.selectedEvent[0].eventId}_${adgroupRowFound.adgroupName.adgroupId}`
    const promoRegPrices = regpricesObj[promoKey];

    if(adgroupRowFound.offerType.value === "A") {
      const values = {
        plannedAmount: adgroupRowFound.offerValue,
        adgroupName: adgroupRowFound.adgroupName.value
      }
      const offerValueValidationRes = AtypeOfferValidator(values, promoRegPrices);

      if(offerValueValidationRes.isInvalid){
        this.setState({
          alertDanger: offerValueValidationRes.isInvalid,
          alertDangerMsg: offerValueValidationRes.invalidationMsg
        });

        setTimeout(() => {
          this.setState({ alertDanger: false, alertDangerMsg: "" });
        }, alertTimer);
        return;
      }
    }

    if(adgroupRowFound.offerType.value === "P") {
      const values = {
        plannedAmount: adgroupRowFound.offerValue,
        adgroupName: adgroupRowFound.adgroupName.value
      }
      const offerValueValidationRes = PtypeOfferValidator(values, promoRegPrices);

      if(offerValueValidationRes.isInvalid){
        this.setState({
          alertDanger: offerValueValidationRes.isInvalid,
          alertDangerMsg: offerValueValidationRes.invalidationMsg
        });

        setTimeout(() => {
          this.setState({ alertDanger: false, alertDangerMsg: "" });
        }, alertTimer);
        return;
      }
    }

    if(adgroupRowFound.offerType.value === "F") {
      const values = {
        plannedAmount: adgroupRowFound.offerValue,
        fixedPriceQty: adgroupRowFound.fixedPriceQty,
        adgroupName: adgroupRowFound.adgroupName.value
      }
      const offerValueValidationRes = FtypeOfferValidator(values, promoRegPrices);

      if(offerValueValidationRes.isInvalid){
        this.setState({
          alertDanger: offerValueValidationRes.isInvalid,
          alertDangerMsg: offerValueValidationRes.invalidationMsg
        });

        setTimeout(() => {
          this.setState({ alertDanger: false, alertDangerMsg: "" });
        }, alertTimer);
        return;
      }
    }

    if(adgroupRowFound.offerType.value === "G") {
      const values = {
        minQuantity: adgroupRowFound.minQtyForOffer, 
        bonusBuy: adgroupRowFound.bonusBuy, 
        buyMinVal: adgroupRowFound.ty_buy_min_value, 
        getMaxVal: adgroupRowFound.ty_get_max_value,
        adgroupName: adgroupRowFound.adgroupName.value
      }
      const offerValueValidationRes = GtypeOfferValidator(values, promoRegPrices);

      if(offerValueValidationRes.isInvalid){
        this.setState({
          alertDanger: offerValueValidationRes.isInvalid,
          alertDangerMsg: offerValueValidationRes.invalidationMsg
        });

        setTimeout(() => {
          this.setState({ alertDanger: false, alertDangerMsg: "" });
        }, alertTimer);
        return;
      }
    }

    if(adgroupRowFound.offerType.value === "B") {
      const values = {
        minQuantity: adgroupRowFound.minQtyForOffer, 
        bonusBuy: adgroupRowFound.bonusBuy, 
        plannedAmount: adgroupRowFound.offerValue,
        adgroupName: adgroupRowFound.adgroupName.value
      }
      const offerValueValidationRes = BtypeOfferValidator(values, promoRegPrices);

      if(offerValueValidationRes.isInvalid){
        this.setState({
          alertDanger: offerValueValidationRes.isInvalid,
          alertDangerMsg: offerValueValidationRes.invalidationMsg
        });

        setTimeout(() => {
          this.setState({ alertDanger: false, alertDangerMsg: "" });
        }, alertTimer);
        return;
      }
    }

    if(adgroupRowFound.offerType.value === "C") {
      const values = {
        plannedAmount: adgroupRowFound.offerValue,
        minQuantity: adgroupRowFound.minQtyForOffer, 
        bonusBuy: adgroupRowFound.bonusBuy, 
        buyMinVal: adgroupRowFound.ty_buy_min_value, 
        getMaxVal: adgroupRowFound.ty_get_max_value,
        adgroupName: adgroupRowFound.adgroupName.value
      }
      const offerValueValidationRes = CtypeOfferValidator(values, promoRegPrices);

      if(offerValueValidationRes.isInvalid){
        this.setState({
          alertDanger: offerValueValidationRes.isInvalid,
          alertDangerMsg: offerValueValidationRes.invalidationMsg
        });

        setTimeout(() => {
          this.setState({ alertDanger: false, alertDangerMsg: "" });
        }, alertTimer);
        return;
      }
    }

    if(adgroupRowFound.offerType.value === "D") {
      const values = {
        minQuantity: adgroupRowFound.minQtyForOffer, 
        plannedAmount: adgroupRowFound.offerValue,
        adgroupName: adgroupRowFound.adgroupName.value
      }
      const offerValueValidationRes = DtypeOfferValidator(values, promoRegPrices);

      if(offerValueValidationRes.isInvalid){
        this.setState({
          alertDanger: offerValueValidationRes.isInvalid,
          alertDangerMsg: offerValueValidationRes.invalidationMsg
        });

        setTimeout(() => {
          this.setState({ alertDanger: false, alertDangerMsg: "" });
        }, alertTimer);
        return;
      }
    }

    this.setState({
      adgroupLoader: true
    });

    let selectedAdgroupSkus = {};
    selectedAdgroupSkus[adgroupRowFound.adgroupName.label] =
      adgroupRowFound.adgroupName.productList || {};
    let obj = {
      selectedAdgroupSkus: selectedAdgroupSkus,
      cluster: null,
      startDate: moment(adgroupRowFound.selectedEvent[0].start_date).format(
        "YYYY-MM-DD"
      ),
      endDate: moment(adgroupRowFound.selectedEvent[0].end_date).format(
        "YYYY-MM-DD"
      ),
      fiscalYear: "2019-01-01",
      preSaveOfferType:
        adgroupRowFound.fixedPriceQty > 1
          ? "nForM"
          : adgroupRowFound.offerType.value,
      preSaveOfferValue: adgroupRowFound.offerValue
        ? parseFloat(adgroupRowFound.offerValue)
        : 0,
      campaignId: parseInt(campaignDetailsForCreate),
      campaignStartDate: moment(campaignStartDate).format("YYYY-MM-DD"),
      campaignEndDate: moment(campaignEndDate).format("YYYY-MM-DD"),
      adgroupId: adgroupRowFound.adgroupName.adgroupId,
      promoId: null,
      index: 0,
      promoType: adgroupRowFound.selectedEvent[0].event_type.toLowerCase(),
      commentText: "",
      override_comment: "",
      updateFlag: 1,
      quantity_forecast_override: null, //"",
      revenue_forecast_override: null, //"",
      margin_forecast_override: null, //"",
      gm_override: null, //null,//"",
      bonusbyquantity: adgroupRowFound.bonusBuy
        ? parseFloat(adgroupRowFound.bonusBuy)
        : 0,
      minquantity: adgroupRowFound.minQtyForOffer
        ? parseFloat(adgroupRowFound.minQtyForOffer)
        : 0,
      fixedpricequantity: adgroupRowFound.fixedPriceQty
        ? parseFloat(adgroupRowFound.fixedPriceQty)
        : 0,
      offerquantity: 0,
      replace: replace ? true : false,
      eventId: adgroupRowFound.selectedEvent[0].eventId,
      promo_event_type: adgroupRowFound.promo_event_type && adgroupRowFound.promo_event_type.length > 0 ? adgroupRowFound.promo_event_type[0].value : "P",
      promo_sign_shell: adgroupRowFound.promo_sign_shell && adgroupRowFound.promo_sign_shell.length > 0 ? adgroupRowFound.promo_sign_shell[0].value : "",
      uas: adgroupRowFound.uas && adgroupRowFound.uas.length > 0 ? adgroupRowFound.uas[0].value : "-",
      ty_buy_min_value: adgroupRowFound.ty_buy_min_value ? parseFloat(adgroupRowFound.ty_buy_min_value) : 0,
      ty_get_max_value: adgroupRowFound.ty_get_max_value ? parseFloat(adgroupRowFound.ty_get_max_value) : 0,
      type: adgroupRowFound.type,
      addNewOffer : true,
      user_role :  localStorage.getItem("role")
    };

    if (selectedDateValidity) {
      this.props.usersLogReport({ screen: "add_new_offer", action: "save", userId: localStorage.getItem("email") });

      //GA 
      this.submitGA("Click", "Clicked_AddOffer_SaveButton", 1);

      if(!this.state.fromAdgroupMaster && obj.replace) {
      let eventId = [],adgroupId = [], campaignId = [];
      eventId.push(obj.eventId);
      adgroupId.push(obj.adgroupId);
      campaignId.push(obj.campaignId);

      let promosData = await this.props.getPromosData({eventId, adgroupId, campaignId});

      if(promosData.status) {

      let futureDataValues = {}, currentDataValues = {};
      let key = obj.adgroupId + '_' + obj.eventId + '_' + obj.campaignId;

      futureDataValues[key] = 
            (obj.uas)+'_'+
            (obj.preSaveOfferType)+'_'+
            (obj.preSaveOfferValue)+'_'+
            (obj.fixedpricequantity)+'_'+
            (obj.minquantity)+'_'+
            (obj.bonusbyquantity)+'_'+
            (obj.ty_buy_min_value)+'_'+
            (obj.ty_get_max_value)

            for(let it of promosData.data){
              let key = it.adgroup_id + '_' + it.event_id + '_' + it.campaign_id;
              currentDataValues[key] = 
              (it.uas)+'_'+
              (it.offer_type)+'_'+
              (it.offer_value>0  ? it.offer_value : 0)+'_'+
              (it.fixed_price_quantity>0  ? it.fixed_price_quantity : 0)+'_'+
              (it.min_quantity>0  ? it.min_quantity : 0)+'_'+
              (it.bonus_by_quantity>0  ? it.bonus_by_quantity : 0)+'_'+
              (it.buy_min_value>0  ? it.buy_min_value : 0)+'_'+
              (it.get_max_value>0  ? it.get_max_value : 0)
          }
      
       if(!this.state.fromAdgroupMaster && currentDataValues[key]  === futureDataValues[key] && obj.replace) {
        console.log("yes");
        this.setState({
            isFailure: true,
            failureMessage: `No new changes have been made.`
          });
          setTimeout(() => {
            this.setState({
              isFailure: false,
              failureMessage: ''
            });
          }, alertTimer);

          this.setState({
            adgroupLoader: false,
        });
        return ;
   }
 }
}

      let res = await this.props.saveAdgroup(obj);

      this.setState({
        adgroupLoader: false
      });
      if (res.status) {
        if (res.inPromoApproval) {
          this.setState({
            isSuccess: true,
            successMessage: "Promo offer/s has been submitted for approval",
            adgroupLoader: false,
            productListCreate: [],
          });

          let found = _.find(adgroupRowValue, { key: key });
        found.disableRowSave = true;
  
          setTimeout(() => {
            this.setState({
              isSuccess: false,
              successMessage: ''
            });
          }, alertTimer);
        } else {
          let found = _.find(adgroupRowValue, { key: key });
        found.disableRowSave = true;

        this.setState({
          showOfferReplaceModal: false,
          adgroupsEditedMessage: true,
          adgroupRowValue
        });
        setTimeout(() => {
          this.setState({ adgroupsEditedMessage: false });
        }, alertTimer);}
      } else if (res.isAxiosError) {
        this.setState({
          somethingWentWrong: true,
          adgroupExistsMsg: "Something went wrong! Please try again"
        });
        setTimeout(() => {
          this.setState({ somethingWentWrong: false, adgroupExistsMsg: "" });
        }, alertTimer);
      } else {
        if(res.msg == "Duplicate Approval Record Found"){
          this.setState({
            isFailure: true,
            failureMessage: `An offer with ${res.data.adgroupName} and ${res.data.ty_event} with type ${res.data.offer_type} and value ${res.data.offer_value} already exists in Approval Pipeline.`
          });
          setTimeout(() => {
            this.setState({
              isFailure: false,
              failureMessage: ''
            });
          }, alertTimer);
        }
        else {
          this.setState({
          keyStore: key,
          showOfferReplaceModal: true,
          showOfferReplaceText: res.msg
        });
      }
      }
    } else {
      this.setState({
        adgroupLoader: false,
        notFutureEvent: true,
        adgroupName: adgroupRowFound.adgroupName.label,
        adGroupStartDate: adgroupRowFound.selectedEvent[0].start_date
      })
      setTimeout(() => {
        this.setState({
          notFutureEvent: false,
        });
      }, alertTimer);
    }
  };

  createAdgroupData = productList => {
    this.setState({
      productListCreate: productList
    });
    if (productList.length) {
      this.setState({
        showSave: true
      });
    } else {
      this.setState({
        showSave: false
      });
    }
  };

  closeModal = () => {
    this.setState({
      showOfferReplaceModal: !this.state.showOfferReplaceModal
    });
  };
  addRows = () => {
    let { adgroupRowValue } = this.state;
    adgroupRowValue.push({
      key: adgroupRowValue[adgroupRowValue.length - 1].key + 1,
      adgroupName: [],
      adgroupDesc: "",
      selectedDate: [],
      selectedEvent: [],
      startDate: "",
      endDate: "",
      offerType: [],
      offerValue: "",
      fixedPriceQty: "",
      minQtyForOffer: "",
      bonusBuy: "",
      productList: [],
      adgroupType: "",
      ty_buy_min_value: "",
      ty_get_max_value: "",
      promo_sign_shell: [],
      uas: [],
      promo_event_type: [],
      type: ""
    });

    this.setState({ adgroupRowValue });
    this.submitGA("Click", "Clicked_AddNewRow", 1);
  };

  rowValueUpdateHandler = (keyNum, name, val, value) => {
    let { adgroupRowValue } = this.state,
      found = _.find(adgroupRowValue, { key: keyNum });

    if (name === "adgroupName") {
      this.setState({
        SKUTableData: true
      });
      found.adgroupName = val;
      found.adgroupDesc = val.adgroup_desc;
      found.merchId = val.merchId || 0;
      found.adgroupType = val.adgroupType;
    } else if (name === "DM") {
      found.selectedDate = value;
      //found.selectedEvent = [];
      found.startDate = val.slice(0, 10);
      found.endDate = val.slice(11, 21);
    } else if (name === "eventType") {
      found.selectedEvent = [value];
      found.selectedDate = [];
      found.eventId = value.eventId;
      found.eventDesc = value.eventDesc;
      found.promo_sign_shell = value.promo_sign_shell;
      found.type = value.type;
      found.promo_event_type = value.promo_event_type;
      found.eventGId = value.eventGId
    } else if (name === "offerType") {
      found.offerType = val;
      found.offerValue =  "";
      found.fixedPriceQty = val.value == "F" ? 1: "";
      found.minQtyForOffer = "";
      found.bonusBuy = "";
      found.ty_buy_min_value = "";
      found.ty_get_max_value = "";
      this.setState({
        showSaveBtnAddFromExisting: true
      });
    } else if (name === "offerValue") {
      let type = found.offerType.value;
      if ((type === "P" || type === "B" || type === "D") && val > 99) return;
      else if ((type === "F" || type === "A" || type === "C") && val > 99999)
        return;
      found.offerValue = val;
    } else if (name === "fixedPriceQty") {
      if (val > 99) return;
      found.fixedPriceQty = val;
    } else if (name === "minQtyForOffer") {
      if (val > 99) return;
      found.minQtyForOffer = val;
    } else if (name === "bonusBuy") {
      if (val > 99) return;
      found.bonusBuy = val;
    } else if (name === "ty_buy_min_value") {
      if (val > 99999) return;
      found.ty_buy_min_value = val;
    } else if (name === "ty_get_max_value") {
      if (val > 99999) return;
      found.ty_get_max_value = val;
    }

    if (name === "promo_sign_shell") {
      found.promo_sign_shell = [val];
    }

    if (name === "uas") {
      found.uas = [val];
    }

    if (name === "promo_event_type") {
      found.promo_event_type = [val];
    }
    this.setState({ adgroupRowValue });
  };

  saveRefSkuDetail = async rowData => {
    let params = null;
    if (Object.keys(rowData).length) {
      this.setState({
        adgroupLoader: true
      });
      params = {
        skuId: rowData.unique_id1,
        referenceSku: rowData.selected_sku === "none" ? null : rowData.selected_sku
      };
      let saveRefSkuResponse = await this.props.editReferenceSku(params);

      if (saveRefSkuResponse.status) {
        this.setState({
          adgroupLoader: false,
          savedRefSkuMsg: true
        });

        setTimeout(() => {
          this.setState({
            savedRefSkuMsg: false
          });
        }, alertTimer);
        this.SKUListHandler(rowData.adgroupId);
      }
    } else {
      this.setState({
        selectRefSkuError: true
      });

      setTimeout(() => {
        this.setState({
          selectRefSkuError: false
        });
      }, alertTimer);
    }
  };

  refSkuSearchHandler = async (key, id) => {
    let { SKUTableData } = this.state;
    let refSkuSearchRes = await this.props.similarSkuProductsList({
      modelable_sku: true,
      search_key: key
    });
    let list = _.map(refSkuSearchRes.data, item => {
      return {
        value: item.unique_id1,
        label: item.unique_id1
      };
    });
    list.unshift({ value: "none", label: "none" });

    let found = _.find(SKUTableData, { unique_id1: id });
    found.refSkuOptions = list;

    this.setState({ refSkuOptions: list, refreshRows: true, SKUTableData });
  };

  refreshRowsHandler = () => {
    this.setState({ refreshRows: false });
  };

  SKUListHandler = async adgroupId => {
    let skuFootnote = [];
    this.setState({ showSkuModal: true, tableLoader: true });
    let res = await this.props.getSKUDetailsFromAdgroupId({
      adgroupId: adgroupId
    });

    if (res.status) {
      if (res.data[0].footnote != null) {
        skuFootnote.push(res.data[0].footnote);
      }

      this.setState({
        skuFootnote
      });

      res.data.map(item => {
        item.saveRefSkuDetail = this.saveRefSkuDetail;
        item.refSkuSearchHandler = this.refSkuSearchHandler;
        item.refSkuOptions = this.state.refSkuOptions;
        item.original_reference_sku = item.reference_sku;
        item.adgroupId = adgroupId;
        return item;
      });
    } else {
      this.props.history.push("/promotion");
    }

    this.setState({ SKUTableData: res.data, tableLoader: false });
  };

  // receiveAdgroupDetails = (
  //   name,
  //   desc,
  //   eventType,
  //   eventId,
  //   offerType,
  //   offerValue,
  //   campaignDetail,
  //   selectedDate
  // ) => {
  //   this.setState({
  //     adgroupName: name,
  //     adgroupStartDate: selectedDate ? selectedDate.value.slice(0, 10) : "",
  //     adgroupEndDate: selectedDate ? selectedDate.value.slice(11, 21) : "",
  //     eventType: eventType,
  //     adgroupDesc: desc,
  //     adgroupEventId: eventId,
  //     adgroupOfferType: offerType,
  //     adgroupOfferValue: offerValue,
  //     adgroupCampaignId: campaignDetail
  //   });
  // };
  receiveAdgroupName = name => {
    this.setState({
      adgroupName: name
    });
  };
  receiveAdgroupDesc = desc => {
    this.setState({
      adgroupDesc: desc
    });
  };
  receiveEventType = eventType => {
    this.setState({
      eventType: eventType
    });
  };
  receiveSelectedDate = (selectedDate, eventId) => {
    this.setState({
      adgroupStartDate: selectedDate.value.slice(0, 10),
      adgroupEndDate: selectedDate.value.slice(11, 21),
      adgroupEventId: eventId
    });
  };
  receiveOfferType = offerType => {
    this.setState({
      adgroupOfferType: offerType
    });
  };

  receiveOfferValue = offerVal => {
    this.setState({
      adgroupOfferValue: offerVal
    });
  };
  submitGA(action, label, value) {
    PromotionCalendarGA({ action, label, value });
  }
  render() {
    return (
      <div>
        <div className="alertDivs">
          {this.state.showErrorFlag ? (
            <div>
              <AlertDanger
                message={this.state.errorMsg}
                show={this.state.showErrorFlag}
              />
            </div>
          ) : null}

          {this.state.alertDanger ? (
            <AlertDanger
              message={this.state.alertDangerMsg}
              show={this.state.alertDanger}
            />
          ) : null}

          {this.state.showAdgroupSavedMessage ? (
            <div>
              <AlertSuccess
                message="Offer(s) added successfully"
                show={this.state.showAdgroupSavedMessage}
              />
            </div>
          ) : null}

          {this.state.notFutureEvent ? (
            <AlertDanger
              message={`The Adroup ${this.state.adgroupName} whose Event Type ${this.state.adGroupStartDate} is of a past date,  
                  Please try again with different Event type`}
              show={this.state.notFutureEvent}
            />
          ) : null}

          {this.state.adgroupsEditedMessage ? (
            <div>
              <AlertSuccess
                message="Offer(s) added successfully"
                show={this.state.adgroupsEditedMessage}
              />
            </div>
          ) : null}

          {this.state.savedRefSkuMsg ? (
            <div>
              <AlertSuccess
                message="Reference SKU details saved!"
                show={this.state.savedRefSkuMsg}
              />
            </div>
          ) : null}

          {this.state.showEmptyFieldsMessage ? (
            <div>
              <AlertDanger
                message="Please make selection for all fields!"
                show={this.state.showEmptyFieldsMessage}
              />
            </div>
          ) : null}

          {this.state.somethingWentWrong ? (
            <div>
              <AlertDanger
                message={`${this.state.adgroupExistsMsg}`}
                show={this.state.somethingWentWrong}
              />
            </div>
          ) : null}

          {this.state.selectRefSkuError ? (
            <div>
              <AlertDanger
                message="Please select Reference SKU to proceed"
                show={this.state.selectRefSkuError}
              />
            </div>
          ) : null}

          {this.state.isSuccess ? (
            <AlertSuccess
              message={this.state.successMessage}
              show={this.state.isSuccess}
            />
          ) : null}
          {this.state.isFailure ? (
            <AlertDanger
              message={this.state.failureMessage}
              show={this.state.isFailure}
            />
          ) : null}
        </div>

        <PageLoader loader={this.state.adgroupLoader || this.props.loader}>
          <div className="card cardStyles cardGap">
            <div id="cardbox1" style={{ padding: "1px 12px 10px 20px" }}>
              <div className="createClusterDiv">
                <div className="row pl-3">
                  {this.state.fromAdgroupMaster ? (
                    <div className="row col-md-3">
                      <i
                        className="fa fa-angle-double-left double-left-icon"
                        onClick={() =>
                          this.props.history.push("/adgroupMaster")
                        }
                        aria-hidden="true"
                      ></i>
                      <p
                        id="backToCalLink"
                        onClick={() =>
                          this.props.history.push("/adgroupMaster")
                        }
                      >
                        Back to Adgroup Master
                      </p>
                    </div>
                  ) : (
                    <BackToCalendar history={this.props.history} />
                  )}

                  <div className="col-md-6">
                    {" "}
                    <h4 className="promoHeading">
                      {this.state.fromAdgroupMaster
                        ? "Create New AdGroup"
                        : "Add New Offer"}
                    </h4>
                  </div>
                </div>

                {!this.state.fromAdgroupMaster ? (
                  <div className="row">
                    <div className="col-md-8">
                      <div className="row" style={{ marginLeft: "12px" }}>
                        <div className="col-md-5">
                          <span className="promoNameText row">
                            Campaign name:{" "}
                            <p className="promonameResult">{`${moment(campaignStartDate).format("MM/DD/YYYY")} - ${moment(campaignEndDate).format("MM/DD/YYYY")}`}</p>
                          </span>
                        </div>
                        <div className="col-md-3">
                          <span
                            className="promoNameText row"
                            style={{ paddingLeft: "2%" }}
                          >
                            Start Date:
                            <p className="promonameResult">
                              {moment(campaignStartDate).format("MM/DD/YYYY")}
                            </p>
                          </span>
                        </div>
                        <div className="col-md-3">
                          <span
                            className="promoNameText row"
                            style={{ paddingLeft: "2%" }}
                          >
                            End Date:
                            <p className="promonameResult">
                              {moment(campaignEndDate).format("MM/DD/YYYY")}
                            </p>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
                {!this.state.fromAdgroupMaster && (
                  <>
                    {this.state.adgroupRowValue.map((rowItem, index) => (
                      <>
                        <AddAdgroupRow
                          row={rowItem}
                          adgroupOptionArray={this.state.adgroupOptionArray}
                          rowLength={this.state.adgroupRowValue.length}
                          saveAdgroupValues={
                            this.saveAdgroupValuesAddFromExisting
                          }
                          index={index}
                          addRows={this.addRows}
                          deleteRows={this.deleteRows}
                          rowValueUpdateHandler={this.rowValueUpdateHandler}
                          SKUListHandler={this.SKUListHandler}
                          eventTypeOptions={this.state.eventTypeOptions}
                          adgroupKeyDownHandler={this.adgroupKeyDownHandler}
                          getRegSalesPrices={this.getRegSalesPrices}
                          promoBuildStatus={this.state.promoBuildStatus}
                        />
                        {index < this.state.adgroupRowValue.length - 1 ? (
                          <hr />
                        ) : null}
                      </>
                    ))}

                    {this.state.showSaveBtnAddFromExisting ? (
                      <div className="selectProductsBtn text-center">
                        <button
                          type="button"
                          className="btn btnSaveProducts"
                          onClick={() => {
                            this.addRows();
                          }}
                          // disabled = {isIt()}
                        >
                          Add row
                        </button>
                      </div>
                    ) : (
                      <div />
                    )}
                  </>
                  // ) : (
                  // <Tabs
                  //   id="controlled-tab-example"
                  //   activeKey={this.state.key}
                  //   onSelect={key => this.setState({ key })}
                  //   style={{ overflowY: "hidden" }}
                  // >
                  //   <Tab
                  //     eventKey="existingAdgrp"
                  //     title="Add from Existing AdGroup"
                  //   >

                  //   </Tab>

                  //  <Tab eventKey="createNew" title="Create New AdGroup">
                  //   <CreateAdGroup
                  //     showErrorMessage={() => {
                  //       this.showErrorMessage();
                  //     }}
                  //     // hideCreateAdGroup={adgroupName => {
                  //     //   this.hideCreateAdGroup(adgroupName);
                  //     // }}
                  //     createAdGroupValue={productList => {
                  //       this.createAdgroupData(productList);
                  //     }}
                  //     sendAdGroupValuesCreate={name => {
                  //       this.receiveAdGroupCreateValues(name);
                  //     }}
                  //     clearAdGroupName={this.state.clearAdGroupName}
                  //     clearProductList={this.state.clearProductList}
                  //     sendAdgroupDetails={(
                  //       name,
                  //       desc,
                  //       eventType,
                  //       eventId,
                  //       offerType,
                  //       offerValue,
                  //       campaignDetailsForCreate,
                  //       selectedDate
                  //     ) => {
                  //       this.receiveAdgroupDetails(
                  //         name,
                  //         desc,
                  //         eventType,
                  //         eventId,
                  //         offerType,
                  //         offerValue,
                  //         campaignDetailsForCreate,
                  //         selectedDate
                  //       );
                  //     }}
                  //     sendAdgroupName={name => {
                  //       this.receiveAdgroupName(name);
                  //     }}
                  //     sendAdgroupDesc={desc => {
                  //       this.receiveAdgroupDesc(desc);
                  //     }}
                  //     sendEventType={eventType => {
                  //       this.receiveEventType(eventType);
                  //     }}
                  //     sendSelectedDate={(date, eventId) => {
                  //       this.receiveSelectedDate(date, eventId);
                  //     }}
                  //     sendOfferType={offerType => {
                  //       this.receiveOfferType(offerType);
                  //     }}
                  //     sendOfferValue={offerVal => {
                  //       this.receiveOfferValue(offerVal);
                  //     }}
                  //     couponSaved={this.state.adgroupSaved}
                  //     couponSavedHandler={() =>
                  //       this.setState({ adgroupSaved: false })
                  //     }
                  //   />
                  // </Tab> */}

                  // </Tabs>
                  // <CreateMasterAdGroup
                  //   showErrorMessage={() => {
                  //     this.showErrorMessage();
                  //   }}
                  //   hideCreateAdGroup={adgroupName => {
                  //     this.hideCreateAdGroup(adgroupName);
                  //   }}
                  //   createAdGroupValue={productList => {
                  //     this.createAdgroupData(productList);
                  //   }}
                  //   sendAdGroupValuesCreate={name => {
                  //     this.receiveAdGroupCreateValues(name);
                  //   }}
                  //   clearAdGroupName={this.state.clearAdGroupName}
                  //   clearProductList={this.state.clearProductList}
                  //   sendAdgroupDetails={(
                  //     name,
                  //     desc,
                  //     eventType,
                  //     eventId,
                  //     offerType,
                  //     offerValue,
                  //     campaignDetailsForCreate,
                  //     selectedDate
                  //   ) => {
                  //     this.receiveAdgroupDetails(
                  //       name,
                  //       desc,
                  //       eventType,
                  //       eventId,
                  //       offerType,
                  //       offerValue,
                  //       campaignDetailsForCreate,
                  //       selectedDate
                  //     );
                  //   }}
                  //   sendAdgroupName={name => {
                  //     this.receiveAdgroupName(name);
                  //   }}
                  //   sendAdgroupDesc={desc => {
                  //     this.receiveAdgroupDesc(desc);
                  //   }}
                  //   sendEventType={eventType => {
                  //     this.receiveEventType(eventType);
                  //   }}
                  //   sendSelectedDate={(date, eventId) => {
                  //     this.receiveSelectedDate(date, eventId);
                  //   }}
                  //   sendOfferType={offerType => {
                  //     this.receiveOfferType(offerType);
                  //   }}
                  //   sendOfferValue={offerVal => {
                  //     this.receiveOfferValue(offerVal);
                  //   }}
                  //   couponSaved={this.state.adgroupSaved}
                  //   couponSavedHandler={() =>
                  //     this.setState({ adgroupSaved: false })
                  //   }
                  //   merchIdHandler={this.merchIdHandler}
                  //   merchId={this.state.merchId}
                  // />
                )}
                {this.state.showSave || this.state.fromAdgroupMaster ? (
                  <div className="selectProductsBtn text-center">
                    <button
                      type="button"
                      className="btn btnSaveProducts"
                      disabled={
                        this.state.fromAdgroupMaster &&
                          _.isEmpty(this.state.adgroupName)
                          ? true
                          : false
                      }
                      onClick={() => {
                        this.saveAdgroupValues();
                      }}
                    >
                      Save
                    </button>
                  </div>
                ) : (
                  <div />
                )}
              </div>
            </div>
          </div>
        </PageLoader>

        {this.state.showOfferReplaceModal ? (
          <OfferReplaceModal
            closeModal={this.closeModal}
            onReplaceOffer={() => {
              this.closeModal();
              this.saveAdgroupValuesAddFromExisting(this.state.keyStore, true);
            }}
            showOfferReplaceText={this.state.showOfferReplaceText}
          />
        ) : null}
        {this.state.showSkuModal ? (
          <SkuModal
            tableData={this.state.SKUTableData}
            refreshRows={this.state.refreshRows}
            refreshRowsHandler={this.refreshRowsHandler}
            closeSkuModal={() => {
              var refSkuNotSaved = _.find(this.state.SKUTableData, {
                sku_saved: false
              });
              if (refSkuNotSaved) {
                this.setState({ saveBeforeClose: true });
              } else {
                this.setState({
                  showSkuModal: false,
                  showDetailPopup: false,
                  saveBeforeClose: false
                });
              }
            }}
            closeDetailPopup={() => this.setState({ showDetailPopup: false })}
            loaderAg={this.state.tableLoader}
            footnote={this.state.skuFootnote}
            showDetailPopup={this.state.showDetailPopup}
            refSkuInfoResponse={this.state.refSkuInfoResponse}
          />
        ) : null}

        {this.state.saveBeforeClose ? (
          <Modal
            visible={true}
            width="400px"
            height="200px"
            effect="fadeInDown"
          >
            <h5 className="delete-confirmatn-modal text-left mt-3">
              There are some unsaved changes. Do you wish to continue?
            </h5>
            <div className="text-center delete-btns">
              <button
                className="btn btn-outline-secondary"
                onClick={() => {
                  this.setState({ saveBeforeClose: false });
                }}
              >
                No
              </button>
              <button
                className="btn btn-outline-secondary ml-3"
                onClick={() =>
                  this.setState({
                    showSkuModal: false,
                    showDetailPopup: false,
                    saveBeforeClose: false
                  })
                }
              >
                Yes
              </button>
            </div>
          </Modal>
        ) : null}
      </div>
    );
  }
}

CreateOffer.propTypes = {
  createBulkAdgroup: PropTypes.func.isRequired,
  createAdgroup: PropTypes.func.isRequired,
  getSKUDetailsFromAdgroupId: PropTypes.func.isRequired,
  getEditDMList: PropTypes.func.isRequired,
  adgroupListForCreate: PropTypes.func.isRequired,
  similarSkuProductsList: PropTypes.func.isRequired,
  editReferenceSku: PropTypes.func.isRequired,
  infoReferenceSku: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  getAdGroupData: state.adGroupData.getAdGroupData,
  loader: state.adGroupData.loader,
  errors: state.errors
});

export default connect(mapStateToProps, {
  createAdgroup,
  createBulkAdgroup,
  similarSkuProductsList,
  getSKUDetailsFromAdgroupId,
  getEditDMList,
  getPromosData,
  editReferenceSku,
  infoReferenceSku,
  adgroupListForCreate,
  createMasterAdgroup,
  saveAdgroup,
  usersLogReport,
  fetchRegSalesPrices,
  fetchRegPrices,
  fetchPromoBuildStatus ,
  getSignShellOptions
})(withRouter(CreateOffer));
