import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import SearchProducts from "../Adgroup/AdGroupSearchProducts";
import SelectProducts from "../Adgroup/AdGroupSelectProducts";
import OfferTypes from "../../../../utils/OfferTypes";
import Select from "react-select";
import AlertDanger from "../../../../utils/AlertMessage/alertDanger";
import { Element, scroller } from "react-scroll";
import {
  getCouponList,
  getEventCouponList
} from "../../../../redux/actions/couponAction";
import _ from "lodash";
import moment from "moment";
import { updateProductsWithAdgroup } from "../../../../redux/actions/adgroup";
import { PromotionCalendarGA } from '../../../../utils/GA/GAUtils';
import { alertTimer } from "../../../../utils/alertTimer";

//let promotionName = null, campaignStartDate= null, campaignEndDate=null, campaignId = null;
export class CreateCouponSelections extends Component {
  constructor(props) {
    super(props);
    // this.addSKU = React.createRef();
    // this.selectSKURef = React.createRef();
    this.state = {
      description: "",
      openSearchProducts: true,
      showSearchSkus: false,
      showSelectProducts: false,
      productDataFromChild: [],
      eventTypeSelected: "",
      couponTypeSelected: "",
      couponValueSelected: "",
      showErrorMessage: true,
      clearProductList: false,
      showReviewSku: false,
      showEnterAllFieldsError: false,
      skuAlreadyAdded: false,
      eventTypeOptions: [],
      editCouponData: null,
      showNoCouponEventsError: false
    };
  }

  componentDidMount = async () => {
    var eventTypeOptions = [],
      localCampId = parseInt(localStorage.getItem("campaignId")), res = [];
    if (this.props.editCoupon) {
      let couponId = localStorage.getItem("edit_coupon_id");
      if (localCampId === null) {
        this.props.history.push("/promotion");
        window.location.reload();
      }
      res = await this.props.getCouponList({
        couponId: couponId,
        campaignId: localCampId
      });

      let skuRes = [];
      if (!_.isEmpty(res.data) && !_.isEmpty(res.data.sku_list)) {
        skuRes = await this.props.updateProductsWithAdgroup({
          sku: res.data ? res.data.sku_list : []
        });
      }
      if (res.status) {
        if (_.isEmpty(res.data)) {
          this.props.history.push("/promotion");
          window.location.reload();
        }
        // this.props.onDatesChange({
        //   startDate: {value: moment(res.data.start_date), label: res.data.start_date},
        //   endDate: {value: moment(res.data.end_date), label: res.data.end_date}
        // });
        // this.props.onDatesChange({
        //   value:
        //     res.data.start_date.slice(0, 10) +
        //     "-" +
        //     res.data.end_date.slice(0, 10),
        //   label:
        //     res.data.start_date.slice(5, 10).replace(/-/g, "/") +
        //     "-" +
        //     res.data.end_date.slice(5, 10).replace(/-/g, "/"),
        //   couponEventId: res.data.coupon_events_id
        // })

        this.setState({
          editCouponData: res.data,
          showReviewSku: true,
          productDataFromChild: skuRes.data ? skuRes.data : []
        });
        this.props.editCouponActive(
          res.data.forecasted_units,
          res.data.forecasted_revenue,
          res.data.forecasted_margin,
          res.data.gm,
          res.data.coupon_id
        );
        // this.props.onEventTypeSelect({
        //   value: res.data.event_type,
        //   label: res.data.event_type
        // });
        this.props.onDescriptionChange(res.data.description);
        this.props.onCouponTypeSelected({
          value: res.data.coupon_type,
          label: res.data.coupon_type
        });
        this.props.onCouponValueSelected(res.data.coupon_discount);

        // var x = this.selectSKURef.current.getAttribute("href").replace("#", ""),
        //   item = document.getElementById(x);
        // item.classList.remove("collapsed", "hide");
        // item.classList.add("show");
      } else {
        this.props.history.push("/promotion");
      }
    } //else {

    let resForCouponEvents = await this.props.getEventCouponList({
      campaignId: localCampId
    });
    if (resForCouponEvents.status) {
      if (resForCouponEvents.data.length) {
        resForCouponEvents.data.map(eventOptions => {
          let found = _.filter(resForCouponEvents.data, {
            event_type: eventOptions.event_type
          }),
            dateRangeArr = _.map(found, daterange => {
              return {
                value:
                  daterange.start_date.slice(0, 10) +
                  "-" +
                  daterange.end_date.slice(0, 10),
                label:
                  daterange.start_date.slice(5, 10).replace(/-/g, "/") +
                  "-" +
                  daterange.end_date.slice(5, 10).replace(/-/g, "/"),
                couponEventId: daterange.coupon_events_id
              };
            });

          eventTypeOptions.push({
            value: eventOptions.event_type,
            label: eventOptions.event_type,
            dateRange: dateRangeArr
          });
          return eventOptions;
        });
      } else {
        this.setState({
          showNoCouponEventsError: true
        });
      }

      this.setState({
        eventTypeOptions: _.uniqBy(eventTypeOptions, "value")
      });
      if (this.props.editCoupon && res.status) {
        let couponTypeParam = _.find(eventTypeOptions, { value: res.data.event_type }),
          dateRangeSelected = _.find(couponTypeParam.dateRange, {
            value: (res.data.start_date.slice(0, 10) + "-" +
              res.data.end_date.slice(0, 10))
          });

        this.props.onEventTypeSelect(couponTypeParam);

        this.props.onDatesChange(dateRangeSelected)
      }
    }
    //}
  };
  componentWillReceiveProps(props) {
    if (props.clearProductList === true) {
      this.setState({
        clearProductList: true
      });

      setTimeout(() => {
        this.setState({
          clearProductList: false
        });
      }, 500);
    }
    if (this.props.couponSaved) {
      this.setState({
        showReviewSku: false,
        showSearchSkus: false
      });
      // this.props.onDatesChange({
      //   val: null
      // });
      // var x = this.selectSKURef.current.getAttribute("href").replace("#", ""),
      //   item = document.getElementById(x);
      // item.classList.remove("collapse", "show");
      // item.classList.add("hide");
      // var y = this.addSKU.current.getAttribute("href").replace("#", ""),
      //   itemY = document.getElementById(y);
      // itemY.classList.remove("collapse", "show");
      // itemY.classList.add("hide");
    }
  }

  showSelectProducts = e => {
    this.props.showSaveButton();
    this.setState({ showSelectProducts: true });
  };

  showErrorMessage = () => {
    this.setState({
      showErrorMessage: true
    });
  };

  addSkuBtnClicked = () => {
    let { startDate, endDate } = this.props,
      {
        description,
        couponTypeSelected,
        couponValueSelected,
        eventTypeSelected
      } = this.props;
    if (
      !_.isEmpty(description) &&
      !_.isEmpty(couponTypeSelected) &&
      startDate != null &&
      endDate != null &&
      !_.isEmpty(couponValueSelected) &&
      !_.isEmpty(eventTypeSelected)
    ) {
      // var x = this.addSKU.current.getAttribute("href").replace("#", ""),
      //   item = document.getElementById(x);
      // item.classList.remove("collapsed", "hide");
      // item.classList.add("show");

      this.setState({
        showSearchSkus: true
      });
    } else {
      this.setState({
        showEnterAllFieldsError: true
      });

      setTimeout(() => {
        this.setState({
          showEnterAllFieldsError: false
        });
      }, alertTimer);
    }
  };

  sendSelectedProducts = productsSelected => {
    // this.props.sendSelectedProducts(productsSelected)
    if (productsSelected.length) {
      // var x = this.selectSKURef.current.getAttribute("href").replace("#", ""),
      //   item = document.getElementById(x);
      // item.classList.remove("collapsed", "hide");
      // item.classList.add("show");
      let { productDataFromChild } = this.state,
        //combining array without any duplicates in it
        updatedProductDataFromChild = _.unionBy(
          productDataFromChild,
          productsSelected,
          "unique_id1"
        ),
        //mainArray length must be greater than childArray
        mainArray = productDataFromChild,
        childArray = updatedProductDataFromChild;
      if (productDataFromChild.length < updatedProductDataFromChild.length) {
        mainArray = updatedProductDataFromChild;
        childArray = productDataFromChild;
      }
      let isDifferent = _.differenceBy(mainArray, childArray, "unique_id1");
      if (_.isEmpty(isDifferent)) {
        this.setState({ skuAlreadyAdded: true });
        setTimeout(() => {
          this.setState({ skuAlreadyAdded: false });
        }, alertTimer);
      }
      this.setState({
        showReviewSku: true,
        productDataFromChild: updatedProductDataFromChild
      });

      scroller.scrollTo("showReviewSkuElement", {
        duration: 800,
        delay: 0,
        smooth: "easeInOutQuart"
      });
    }
  };

  productSelectedDataHandler = rowsToDelete => {
    let difference = _.differenceBy(
      this.state.productDataFromChild,
      rowsToDelete,
      "unique_id1"
    );
    this.setState({ productDataFromChild: difference });
  };
  isOutsideRangeHandler = day => {
    let formattedDate = moment(day).format(),
      startDate = moment(this.props.campaignStartDate).format(),
      endDate = moment(this.props.campaignEndDate)
        .add(1, "d")
        .format(),
      isSameOrAfter = moment(formattedDate).isSameOrAfter(startDate),
      isSameOrBefore = moment(formattedDate).isSameOrBefore(endDate);

    return !(isSameOrAfter && isSameOrBefore);
  };

  couponSaveHandler = () => {
    !_.isEmpty(this.props.description) &&
      !_.isEmpty(this.props.couponTypeSelected) &&
      this.props.startDate != null &&
      this.props.endDate != null &&
      !_.isEmpty(this.props.couponValueSelected) &&
      !_.isEmpty(this.props.eventTypeSelected)
      ? this.props.openCommentModal()
      : this.setState({
        showEnterAllFieldsError: true
      });

    setTimeout(() => {
      this.setState({
        showEnterAllFieldsError: false
      });
    }, alertTimer);
  }
  submitGA(action, label) {
    PromotionCalendarGA({ action, label });
  }
  render() {
    return (
      <>
        <div className="alertDivs">
          {this.state.showEnterAllFieldsError ? (
            <AlertDanger
              message="Please enter all fields!"
              show={this.state.showEnterAllFieldsError}
            />
          ) : null}

          {this.state.showNoCouponEventsError ? (
            <AlertDanger
              message="No coupon events for this campaign. Please create coupon events before proceeding"
              show={this.state.showNoCouponEventsError}
            />
          ) : null}

          {this.state.skuAlreadyAdded ? (
            <AlertDanger
              message="SKU already added!"
              show={this.state.skuAlreadyAdded}
            />
          ) : null}
        </div>
        <div className="row pipelineCopydiv">
          <div className="col-md-8">
            <div className="row" style={{ marginLeft: "12px" }}>
              <div className="col-md-4">
                <span className="promoNameText row">
                  Promotion Name:{" "}
                  <p className="promonameResult">{this.props.promotionName}</p>
                </span>
              </div>
              <div className="col-md-3">
                <span
                  className="promoNameText row"
                  style={{ paddingLeft: "2%" }}
                >
                  Start Date:
                  <p className="promonameResult">
                    {moment(this.props.campaignStartDate).format("MM/DD/YYYY")}
                  </p>
                </span>
              </div>
              <div className="col-md-3">
                <span
                  className="promoNameText row"
                  style={{ paddingLeft: "2%" }}
                >
                  End Date:
                  <p className="promonameResult">
                    {moment(this.props.campaignEndDate).format("MM/DD/YYYY")}
                  </p>
                </span>
              </div>
              {/* <div className="col-md-2">
                <span
                  className="promoNameText row"
                  style={{ paddingLeft: "2%" }}
                >
                  # of days:<p className="promonameResult">
                        {this.props.diffDays ? this.props.diffDays : ""}
                      </p>
                </span>
              </div> */}
            </div>
          </div>
        </div>
        <div className="childCompStyling" style={{ border: "none" }}>
          <div className="createClusterDiv card cardStyles">
            <div className="row coupon-label-styling">
              <div className="col-md-2">
                <p className="labelAdGroup mb-1">Event Type</p>{" "}
              </div>
              <div className="col-md-3">
                {" "}
                <p className="labelAdGroup mb-1">Time Period</p>
              </div>
              <div className="col-md-3">
                <p className="labelAdGroup mb-1">Category Description</p>{" "}
              </div>
              <div className="col-md-2">
                <p className="labelAdGroup mb-1">Coupon Type</p>{" "}
              </div>
              <div className="col-md-2">
                <p className="labelAdGroup mb-1">Coupon Discount</p>{" "}
              </div>
            </div>
            <div className="row coupon-selection-styling">
              <div className="col-md-2">
                <Select
                  className="CompareSelect"
                  placeholder="Event type"
                  options={this.state.eventTypeOptions}
                  value={this.props.eventTypeSelected}
                  onChange={val => {
                    this.props.onEventTypeSelect(val)
                    this.submitGA("Click", "Clicked_CouponEventTypeButton");
                  }}
                />
              </div>

              <div className="col-md-3 date-picker-coupon">
                <div style={{ textAlign: "center" }}>
                  <Select
                    className="create-adgrp-select"
                    placeholder="Select Date"
                    isDisabled={_.isEmpty(this.props.eventTypeSelected)}
                    options={this.props.activeDateArr}
                    value={this.props.selectedDate}
                    onChange={val => {
                      this.props.onDatesChange(val);
                    }}
                  />
                </div>
              </div>

              <div className="col-md-3">
                <input
                  type="text"
                  className="adGroupInputStyling"
                  id="descriptionInput"
                  //aria-describedby="emailHelp"
                  placeholder="Category Description"
                  value={this.props.description}
                  onChange={e => this.props.onDescriptionChange(e.target.value)}
                />
              </div>

              <div className="col-md-2">
                <Select
                  className="CompareSelect"
                  placeholder="Coupon type"
                  options={OfferTypes.couponType}
                  value={this.props.couponTypeSelected}
                  onChange={val => {
                    this.props.onCouponTypeSelected(val);
                    this.submitGA("Click", "Clicked_CouponTypeButton");
                  }}
                />
              </div>

              <div className="col-md-2">
                <div className="position-relative">
                  <input
                    placeholder="Enter a value"
                    step="any"
                    min="0"
                    max="95"
                    className="form-control selectOffer"
                    value={this.props.couponValueSelected}
                    onChange={e =>
                      this.props.onCouponValueSelected(e.target.value)
                    }
                  />
                </div>
              </div>
            </div>

            {this.props.children}
            <div className="row" style={{ marginBottom: "1%" }}>
              <div className="text-right col-7">
                <button
                  type="button"
                  className="btn btn-outline-primary promo3Buttons"
                  style={{ width: "110px" }}
                  disabled={this.state.addSkuBtnDisabled}
                  onClick={() => {
                    this.couponSaveHandler()
                    this.submitGA("Click", "Clicked_CouponSaveButton");
                  }}
                >
                  Save
                </button>
              </div>
              {/* <div className="text-left col-5">
                <a
                  href="#"
                  onClick={() => this.addSkuBtnClicked()}
                  style={{ textDecoration: "underline", color: "#2d69eb" }}
                >
                  Add SKU (optional)
                </a>
              </div> */}
            </div>
          </div>

          {/* <div className="card">
            <div id="accordion" className="accordion">
              <div
                className={
                  this.state.showSearchSkus
                    ? "card-header collapsed"
                    : "enableaccordian card-header collapsed"
                }
                data-toggle="collapse"
                data-parent="#accordion"
                href="#collapseSearchOne"
                ref={this.addSKU}
              >
                <a href="#" className="card-title">Select SKU</a>
              </div>

              <div
                id="collapseSearchOne"
                className="pb-5 card-body collapse"
                data-parent="#accordion"
              >
                <SearchProducts
                  sendSelectedProducts={this.sendSelectedProducts}
                  couponSaved={this.props.couponSaved}
                  couponSavedHandler={this.props.couponSavedHandler}
                />
              </div>
            </div>
          </div>
          <Element name="showReviewSkuElement" className="element" />

          <div className="card">
            <div id="accordion" className="accordion">
              <div
                className={
                  this.state.showReviewSku
                    ? "card-header collapsed"
                    : "enableaccordian card-header collapsed"
                }
                data-toggle="collapse"
                data-parent="#accordion"
                href="#collapseSearchTwo"
                ref={this.selectSKURef}
              >
                <a href="#" className="card-title">Review SKU List</a>
              </div>
              <div
                id="collapseSearchTwo"
                className="card-body collapse"
                data-parent="#accordion"
              >
                <SelectProducts
                  hideCreateAdGroup={adgroupName =>
                    this.hideCreateAdGroup(adgroupName)
                  }
                  productSelectedData={this.state.productDataFromChild}
                  createAdGroupValue={this.props.createAdGroupValue}
                  clearProductList={this.state.clearProductList}
                  productSelectedDataHandler={this.productSelectedDataHandler}
                  couponSaved={this.props.couponSaved}
                  fromCoupon={true}
                />
              </div>
            </div>
          </div>
        
         */}
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({});

export default connect(mapStateToProps, {
  getCouponList,
  getEventCouponList,
  updateProductsWithAdgroup
})(withRouter(CreateCouponSelections));
