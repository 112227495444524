import React, { Component } from "react";
import { connect } from "react-redux";
import DatePicker from "react-datepicker";
import "react-dates/lib/css/_datepicker.css";
import MultiSelectAll from "../EventMaster/MultiSelectDropdown";
import moment from "moment";
import './Reports.css'
import { getAgparReportData, getAdGroupDetails, getThemeDetails, getBuyerDetails } from "../../../../redux/actions/reportsAction";

import ColumnDef from "../../../tables/colDefs/ReportsColDef";
import DataTable from "../../../tables";

import _ from "lodash";
import { Container, Row, Col } from 'react-bootstrap';
import "react-dates/initialize";
import { Button, Input } from 'antd';
import AlertSuccess from "../../../../utils/AlertMessage/alertSuccess";
import AlertDanger from "../../../../utils/AlertMessage/alertDanger";
import { getBrowserTimeZone,getClientTimezone, comparator } from "../../../../utils/valueFormatters";
import { alertTimer } from "../../../../utils/alertTimer";
const { TextArea } = Input;



class AgparReport extends Component {

  constructor(props) {
    super(props);
    this.state = {
      showLoader: false,
      isSuccess: false,
      successMessage: '',
      isFailure: false,
      failureMessage: "",
      buyerOptions: [],
      selectedBuyer: [],
      adGroupOptions: [],
      selectedadGroup: [],
      themeOptions: [],
      selectedTheme: [],
      selectedFilters: {},
      typedAdGroupId: [],
      typedBuyerId: [],
      typedThemeId: [],
      dateRanges: {
        first: { from: null, to: null },
        second: { from: null, to: null },
        third: { from: null, to: null },
        fourth: { from: null, to: null },
      },

      tableData: {},
      alretMessage: null,
      showAlert: false,
      alretFailure: false,
      alretFailureMessage: null
    }
  }

  handleTextareaChange = (e, fields) => {
    if (fields === 'typedThemeId') {
      const keys = ['first', 'second', 'third', 'fourth'];
      const tempDateReanges = {}
      keys.forEach(key => {
        tempDateReanges[key] = { from: null, to: null };
      });
      this.setState({
        dateRanges: _.cloneDeep(tempDateReanges)
      })
    }
    // const values = e.target.value ? e.target.value.split(',') : [];
    let values ;
    if(fields === "typedAdGroupId"){
      values = e.target.value
    ? e.target.value
        .split('\n')
        .flatMap(line => line.split(','))
        .map(value => value.trimStart().toUpperCase())
    : [];
    }

    else {
      values = e.target.value
  ? e.target.value
      .split('\n')
      .flatMap(line => line.split(','))
      .map(value => value.trim())
  : [];
    }
    let updatedValues = {}
    updatedValues[`${fields}`] = values;
    switch (fields) {
      case "typedBuyerId":
        let selectedBuyers = this.props.buyerOptions.filter(obj => {
          if (values.indexOf(String(obj.label)) !== -1) {
            obj['pastedValue'] = true;
            if (obj.hasOwnProperty("dropdownValue")) {
              delete obj["dropdownValue"]
            }
          } else {
            delete obj["pastedValue"]
          }
          return obj;
        }).filter(obj => obj.pastedValue);
        if (this.state.selectedFilters.buyer) {
          const dropdownValues = this.state.selectedFilters.buyer.filter(obj => !obj.hasOwnProperty("pastedValue") && (obj.hasOwnProperty("dropdownValue") && obj.dropdownValue))
          const copiedValues = this.state.selectedFilters.buyer.filter(obj => values.indexOf(String(obj.label)) !== -1 && obj['pastedValue'])
          const buyers = dropdownValues.concat(copiedValues).concat([...selectedBuyers])
          const buyersList = _.uniqBy(buyers, 'label');
          if (buyersList.length == this.props.buyerOptions.length) {
            buyersList.unshift({
              label: "Select all",
              value: "all"
            })
          }
          updatedValues["selectedFilters"] = { ...this.state.selectedFilters, buyer: [...buyersList] }
        } else {
          if (selectedBuyers.length == this.props.buyerOptions.length) {
            selectedBuyers.unshift({
              label: "Select all",
              value: "all"
            })
          }
          updatedValues["selectedFilters"] = { ...this.state.selectedFilters, buyer: selectedBuyers }
        }
        break;
      case "typedAdGroupId":
        let adgroup = this.props.adGroupOptions.filter(obj => {
          if (values.indexOf(String(obj.label)) !== -1) {
            obj['pastedValue'] = true;
            if (obj.hasOwnProperty("dropdownValue")) {
              delete obj["dropdownValue"]
            }
          } else {
            delete obj["pastedValue"]
          }
          return obj;
        }).filter(obj => obj.pastedValue)
        if (this.state.selectedFilters.adgroup) {
          const dropdownValues = this.state.selectedFilters.adgroup.filter(obj => !obj.hasOwnProperty("pastedValue") && (obj.hasOwnProperty("dropdownValue") && obj.dropdownValue))
          const copiedValues = this.state.selectedFilters.adgroup.filter(obj => values.indexOf(String(obj.label)) !== -1 && obj['pastedValue'])
          const adgroups = dropdownValues.concat(copiedValues).concat([...adgroup])
          const adgroupList = _.uniqBy(adgroups, 'label');
          if (adgroupList.length == this.props.adGroupOptions.length) {
            adgroupList.unshift({
              label: "Select all",
              value: "all"
            })
          }
          updatedValues["selectedFilters"] = {...this.state.selectedFilters, adgroup: [...adgroupList] }
        } else {
          if (adgroup.length == this.props.adGroupOptions.length) {
            adgroup.unshift({
              label: "Select all",
              value: "all"
            })
          }
          updatedValues["selectedFilters"] = { ...this.state.selectedFilters, adgroup: adgroup }
        }
        break;

      case "typedThemeId":
        let themesData = this.props.themeOptions.filter(obj => {
          if (values.indexOf(String(obj.value)) !== -1) {
            obj['pastedValue'] = true;
            if (obj.hasOwnProperty("dropdownValue")) {
              delete obj["dropdownValue"]
            }
          } else {
            delete obj["pastedValue"]
          }
          return obj;
        }).filter(obj => obj.pastedValue)
        if (this.state.selectedFilters.themes) {
          const dropdownValues = this.state.selectedFilters.themes.filter(obj => !obj.hasOwnProperty("pastedValue") && (obj.hasOwnProperty("dropdownValue") && obj.dropdownValue))
          const copiedValues = this.state.selectedFilters.themes.filter(obj => values.indexOf(String(obj.value)) !== -1 && obj['pastedValue'])
          const themes = dropdownValues.concat(copiedValues).concat([...themesData])
          const themesList = _.uniqBy(themes, 'value');
          updatedValues["selectedFilters"] = { ...this.state.selectedFilters, themes: [...themesList] }
        } else {
          if (themesData.length == this.props.themeOptions.length) {
            themesData.unshift({
              label: "Select all",
              value: "all"
            })
          }
          updatedValues["selectedFilters"] = { ...this.state.selectedFilters, themes: themesData }
        }
        break;
      default:
        break;
    }
    console.log("Updated values", updatedValues)
    this.setState({ ...updatedValues });
  }



  handleReportDateChange(reportDate, index, type) {
    if (this.state.selectedFilters.themes) this.state.selectedFilters.themes = [];
    if (this.state.typedThemeId) this.state.typedThemeId = []
    const dateRangesCopy = { ...this.state.dateRanges };
    if (type === "from") {
      dateRangesCopy[index].from = reportDate;
    }

    else {
      const { from } = dateRangesCopy[index];
      if (from && reportDate && reportDate.getTime() < from.getTime()) {
        this.setState({ isFailure: true, failureMessage: "To date should be greater than From date" })
        setTimeout(() => {
          this.setState({
            isFailure: false,
            failureMessage: ""
          });
        }, alertTimer);
        return;
      }
      else {
        dateRangesCopy[index].to = reportDate;
      }
    }
    this.setState({ dateRanges: dateRangesCopy });
  }

  handleFilterSelectionChange = (value, key) => {
    if (key === 'themes') {
      const keys = ['first', 'second', 'third', 'fourth'];
      const tempDateReanges = {}
      keys.forEach(key => {
        tempDateReanges[key] = { from: null, to: null };
      });
      this.setState({
        dateRanges: _.cloneDeep(tempDateReanges)
      })
    }
    const tempSelectedfilters = _.cloneDeep(this.state.selectedFilters);
    tempSelectedfilters[key] = [...value].filter(obj => {
      if (obj.hasOwnProperty("pastedValue")) {
        delete obj.pastedValue;
      }
      obj["dropdownValue"] = true;
      return obj;
    });
    if(tempSelectedfilters['themes'] && tempSelectedfilters['themes'].length > 10 ) {
      this.setState({
        alretFailure: true,
        alretFailureMessage: "Maximum of 10 themes selection is allowed"
      });
      setTimeout(() => {
        this.setState({ alretFailure: false, alretFailureMessage: '' });
      }, alertTimer);
    } else {
      this.setState({ selectedFilters: _.cloneDeep(tempSelectedfilters) })
    }
  }

  isSelectAllSelected = (data) => {
    return data.some(item => item.value === "all");
  }

  handleFilter = () => {
    const reqObj = {};

    this.props.handleFilter(this.state.selectedFilters)
  }

  handleCancel = () => {

    this.setState({
      selectedFilters: {}
    })
  }

  formatTableData = (reportData) => {
    let formattedData = [];
    const themes = reportData.themes.map(theme => theme.theme_name);

    reportData.data.forEach(adgroup => {
      const adgroupKeys = Object.keys(adgroup);
      const adgroupThemesLength = [];

      adgroupKeys.forEach(key => {
        if (themes.includes(key)) {
          adgroupThemesLength.push(adgroup[key].length)
        }
      });

      const maxThemeLength = Math.max(...adgroupThemesLength);

      for (let i = 0; i < maxThemeLength; i++) {
        const rowObj = {
          adgroup_name: adgroup.adgroup_name,
          adgroup_desc: adgroup.adgroup_desc,
          buyer: adgroup.buyer,
        }
        themes.forEach(theme => {
          if (adgroup[theme] && adgroup[theme][i]) {
            rowObj[`${theme}_type`] = adgroup[theme][i]['type']
            // rowObj[`${theme}_from`] = getBrowserTimeZone(adgroup[theme][i]['from'],"MM-DD-YYYY")
            // rowObj[`${theme}_to`] = getBrowserTimeZone(adgroup[theme][i]['to'],"MM-DD-YYYY")
            rowObj[`${theme}_from`] = moment(adgroup[theme][i]['from']).format("MM-DD-YYYY")
            rowObj[`${theme}_to`] = moment(adgroup[theme][i]['to']).format("MM-DD-YYYY")
            
            let offerValue = adgroup[theme][i]['offer'];
        console.log(offerValue);

        // Adding a zero to decimal part if it's one digit
        offerValue = offerValue.replace(/(\.\d$)/, "$10");

        // Adding a zero to decimal part if it's missing
        offerValue = offerValue.replace(/(\$ \d+)(\.)(\d{1})(\sOFF|$)/, "$1.$30$4");

        // Adding space between $ and numeric value if no space exists
        offerValue = offerValue.replace(/(\$)(\d)/, "$1 $2");

        // Adding space between numeric value and OFF if OFF follows immediately
        offerValue = offerValue.replace(/(\d)(OFF)/, "$1 $2");

        // Ensuring there are two digits after the decimal point, even with multiple dots
        offerValue = offerValue.replace(/(\$ \d+)(\.+)(\d+)(\sOFF|$)/, function(match, p1, p2, p3, p4) {
            return p1 + "." + p3.padEnd(2, "0") + p4;
        });
        rowObj[`${theme}_offer`] = offerValue;
          } else {
            rowObj[`${theme}_type`] = '';
            rowObj[`${theme}_from`] = '';
            rowObj[`${theme}_to`] = '';
            rowObj[`${theme}_offer`] = '';
            // rowObj[`${theme}_price_list`] = '';
          }
        })

        formattedData.push(rowObj);
      }
    })


    return formattedData;
  }

  convertDateFormat(inputDate) {
    // Create a Date object from the input date string
    const date = new Date(inputDate);

    // Extract year, month, and day components
    const year = date.getFullYear().toString().slice(-2); // Get the last two digits
    const month = ('0' + (date.getMonth() + 1)).slice(-2); // Adjust month to be zero-based
    const day = ('0' + date.getDate()).slice(-2); // Add leading zero if needed

    // Create the formatted date string 'yy/mm/dd'
    const formattedDate = year + '/' + month + '/' + day;

    return formattedDate;
  }

  generateTableColDef = (data) => {
    let fomattedColDef = [
      {
        headerName: "Adgroup name",
        field: "adgroup_name",
        headerClass: "blue-header-bg",
        filter: "agTextColumnFilter",
      },
      {
        headerName: "Adgroup Desc",
        field: "adgroup_desc",
        headerClass: "blue-header-bg",
        filter: "agTextColumnFilter",
      },
      {
        headerName: "Buyer ID",
        field: "buyer",
        headerClass: "blue-header-bg",
        filter: "agTextColumnFilter",
      },
    ];
    console.log("ColumnDef", ColumnDef);
    if (data.themes && data.themes.length > 0) {
      data.themes.forEach(theme => {
        let formatted_start_date = moment(theme.start_date).format("MM/DD/YYYY");
        let formatted_end_date = moment(theme.end_date).format("MM/DD/YYYY");
        // let formatted_start_date = getBrowserTimeZone(theme.start_date,"MM/DD/YYYY");
        // let formatted_end_date = getBrowserTimeZone(theme.end_date,"MM/DD/YYYY");
        const themeColDef = {
          headerName: `${theme.theme_name} [${formatted_start_date} - ${formatted_end_date}]`,
          headerClass: "blue-header-bg custom-red-font",
          children: [
            { headerName: 'Type', field: `${theme.theme_name}_type`, width: 140 },
            { headerName: 'From', field: `${theme.theme_name}_from`, width: 140 },
            { headerName: 'To', field: `${theme.theme_name}_to`, width: 140 },
            { headerName: 'Offer', field: `${theme.theme_name}_offer`, width: 140 },
            // { headerName: 'Price List', field: `${theme.theme_name}_price_list`, width: 140 }

          ],
          sortable: true,
          comparator: function (date1, date2) {
            return comparator(date1, date2, "MM-DD-YYYY");
          },
        }
        fomattedColDef.push(themeColDef);
      })
    }
    return fomattedColDef;
  }

  handleGenerateReport = async (type) => {

    const reqObj = {
      buyer: [],
      adgroup: [],
      themes: [],
      dateRanges: []
    };

    const convertTypedBuyer = this.state.typedBuyerId.map(data => parseInt(data));
    if (this.state.selectedFilters.buyer) {
      const buyers_value = this.state.selectedFilters.buyer.filter(data => data.value !== "all").map(data => data.value);
      reqObj.buyer = buyers_value.concat(convertTypedBuyer);
    }
    else {
      reqObj.buyer = convertTypedBuyer;
    }

    const convertTypedAdgroup = this.state.typedAdGroupId.map(data => parseInt(data));
    if (this.state.selectedFilters.adgroup) {
      const adgroups_value = this.state.selectedFilters.adgroup.filter(data => data.value !== "all").map(data => data.value);
      reqObj.adgroup = adgroups_value.concat(convertTypedAdgroup);
    }
    else {
      reqObj.adgroup = convertTypedAdgroup;
    }

    if (this.state.selectedFilters.themes) {
      const themes_labels = this.state.selectedFilters.themes.filter(data => data.value !== "Select all").map(data => data.value);
      reqObj.themes = themes_labels.concat(this.state.typedThemeId);
    }
    else {
      reqObj.themes = this.state.typedThemeId;
    }


    const dates = this.state.dateRanges;

    Object.keys(dates).forEach(index => {
      const { from, to } = dates[index];
      if (to) {
        reqObj.dateRanges.push({
          from: moment(from).format("YYYY-MM-DD"),
          to: moment(to).format("YYYY-MM-DD"),
        });
      }
    });

    if (type == 'download') {
      reqObj['type'] = 'download';
      reqObj['user_time_zone'] = getClientTimezone();
    }


    console.log("reqObj", reqObj);
    console.log("date", this.state.dateRanges);

    const result = await this.props.getAgparReportData(reqObj);
    console.log("Result ", result)
    if (type != 'download') {
      const tableColumDefs = this.generateTableColDef(result);
      const formattedData = this.formatTableData(result);

      let tableDataObj = {
        header: tableColumDefs,
        defaultColDef: ColumnDef.defaultColDef,
        data: formattedData,
      };
      this.setState({ tableData: _.cloneDeep(tableDataObj) });
    } else {
      if (result.downloadUrl) {
        window.open(result.downloadUrl, '_self');
        this.setState({
          showAlert: true,
          alretMessage: "File Downloaded successfully"
        })
      } else {
        this.setState({
          showAlert: true,
          alretMessage: "No data found"
        })
      }
      setTimeout(() => {
        this.setState({ showAlert: false, alretMessage: '' });
      }, alertTimer);
    }
  }
  resetSelections = () => {
    // Reset all the selections and filters
    this.setState({
      selectedBuyer: [],
      selectedadGroup: [],
      selectedTheme: [],
      selectedFilters: {},
      typedAdGroupId: [],
      typedBuyerId: [],
      typedThemeId: [],
      dateRanges: {
        first: { from: null, to: null },
        second: { from: null, to: null },
        third: { from: null, to: null },
        fourth: { from: null, to: null },
      },
      tableData: {}
    });
  };

  render() {
    return (
      <>
        <div className="alertDivs">
          {this.state.alretMessage ? (
            <div>
              <AlertSuccess
                message={this.state.alretMessage}
                show={this.state.showAlert}
              />
            </div>
          ) : null}
          {this.state.alretFailure ? (
          <div>
              <AlertDanger
                message={this.state.alretFailureMessage}
                show={this.state.alretFailure}
              />
            </div>
               ) : null}
        </div>
        <div className="reportContainer">
          <div className="filterContainer">
            <Container fluid>
              <Row style={{ marginTop: '10px', marginBottom: '10px' }}>
              <Col lg>
                  <div className="filterField" >
                    <p className="dropdownLabel">Themes <span style={{ color: "red", marginLeft: "2px" }}>*</span></p>
                    <MultiSelectAll
                      placeholder="Select Themes"
                      options={[
                      // {
                      //   label: "Select all",
                      //   value: "all"
                      // },
                      ...this.props.themeOptions]}
                      value={this.state.selectedFilters.themes ? this.state.selectedFilters.themes : []}
                      onchange={this.handleFilterSelectionChange}
                      keyValue={'themes'}
                    />
                  </div>
                </Col>

                <Col lg>
                  <p className="summaryPText">Themes <span style={{ color: "red", marginLeft: "2px" }}>*</span></p>
                  <TextArea
                    placeholder="Themes"
                    autoSize={{
                      minRows: 1,
                      maxRows: 10,
                    }}
                    maxLength={1000}
                    value={this.state.typedThemeId.join(',')}
                    onChange={(e) => this.handleTextareaChange(e, 'typedThemeId')}
                  />
                </Col>
                <Col lg>
                  <div className="filterField" >
                    <p className="dropdownLabel">Buyer ID</p>
                    <MultiSelectAll
                      placeholder="Select Buyer ID"
                      options={[{
                        label: "Select all",
                        value: "all"
                      },
                      ...this.props.buyerOptions]}
                      value={this.state.selectedFilters.buyer ? this.state.selectedFilters.buyer : []}
                      onchange={this.handleFilterSelectionChange}
                      keyValue={'buyer'}
                    />
                  </div>
                </Col>

                <Col lg>
                  <p className="summaryPText">Buyer ID</p>
                  <TextArea
                    placeholder="Buyer ID"
                    autoSize={{
                      minRows: 1,
                      maxRows: 10,
                    }}
                    maxLength={1000}
                    value={this.state.typedBuyerId.join(',')}
                    onChange={(e) => this.handleTextareaChange(e, 'typedBuyerId')}
                  />
                </Col>
            
              </Row>


              <Row style={{ marginTop: '10px', marginBottom: '10px' }}>
               
              <Col lg={3}>
                  <div className="filterField" >
                    <p className="dropdownLabel">Adgroup name</p>
                    <MultiSelectAll
                      placeholder="Select Adgroup name"
                      options={[{
                        label: "Select all",
                        value: "all"
                      },
                      ...this.props.adGroupOptions]}
                      value={this.state.selectedFilters.adgroup ? this.state.selectedFilters.adgroup : []}
                      onchange={this.handleFilterSelectionChange}
                      keyValue={'adgroup'}
                    />
                  </div>
                </Col>
                <Col lg={3}>
                  <p className="summaryPText">Adgroup name</p>
                  <TextArea
                    placeholder="Adgroup name"
                    autoSize={{
                      minRows: 1,
                      maxRows: 10,
                    }}
                    maxLength={1000}
                    value={this.state.typedAdGroupId.join(',')}
                    onChange={(e) => this.handleTextareaChange(e, 'typedAdGroupId')}
                  />
                </Col>
              </Row>
              <Row>
                <Col lg={5}>
                <hr style={{ height: '1px', borderWidth: '0', backgroundColor: '#d3d3d3' }} />
                </Col>
                <Col lg={1}>
                  <p>OR</p>
                </Col>
                <Col lg={6}>
                <hr style={{ height: '1px', borderWidth: '0', backgroundColor: '#d3d3d3' }} />
                </Col>
              </Row>



              <Row style={{ marginTop: '10px', marginBottom: '10px' }}>
                <Col lg>
                  <div className="text-center merchant-lockout">
                    <p className="labelAdGroup mb-0">From <span style={{ color: "red", marginLeft: "2px" }}>*</span></p>
                    <DatePicker
                      className="datePickerValueStyling"
                      selected={
                        this.state.dateRanges.first.from
                      }
                      onChange={(date) => {
                        this.handleReportDateChange(date, "first", "from")
                      }}
                      dateFormat="MM/dd/yyyy"
                      placeholderText="From"
                      disabled={this.state.selectedFilters.themeId && this.state.selectedFilters.themeId.length > 0}
                      popperPlacement="bottom-end"
                      popperProps={{
                        positionFixed: true
                      }}
                    />
                  </div>
                </Col>



                <Col lg>
                  <div className=" text-center merchant-lockout">
                    <p className="labelAdGroup mb-0">To <span style={{ color: "red", marginLeft: "2px" }}>*</span></p>
                    <DatePicker
                      className="datePickerValueStyling"
                      selected={this.state.dateRanges.first.to}
                      onChange={(date) => {
                        this.handleReportDateChange(date, "first", "to")
                      }}
                      dateFormat="MM/dd/yyyy"
                      minDate={
                        this.state.dateRanges.first.from
                      }
                      placeholderText="To"
                      disabled={!this.state.dateRanges.first.from}
                      popperPlacement="bottom-end"
                      popperProps={{
                        positionFixed: true
                      }}
                    />
                  </div>
                </Col>


                <Col lg>
                  <div className="text-center merchant-lockout">
                    <p className="labelAdGroup mb-0">From</p>
                    <DatePicker
                      className="datePickerValueStyling"
                      selected={this.state.dateRanges.second.from}
                      onChange={(date) => {
                        this.handleReportDateChange(date, "second", "from")
                      }}
                      dateFormat="MM/dd/yyyy"
                      placeholderText="From"
                      disabled={!this.state.dateRanges.first.to}
                      popperPlacement="bottom-end"
                      popperProps={{
                        positionFixed: true
                      }}
                    />
                  </div>
                </Col>


                <Col lg>
                  <div className="text-center merchant-lockout">
                    <p className="labelAdGroup mb-0">To</p>
                    <DatePicker
                      className="datePickerValueStyling"
                      selected={this.state.dateRanges.second.to}
                      onChange={(date) => {
                        this.handleReportDateChange(date, "second", "to")
                      }}
                      dateFormat="MM/dd/yyyy"
                      minDate={
                        this.state.dateRanges.second.from
                      }
                      placeholderText="To"
                      disabled={!this.state.dateRanges.second.from}
                      popperPlacement="bottom-end"
                      popperProps={{
                        positionFixed: true
                      }}
                    />
                  </div>
                </Col>
              </Row>

              <Row style={{ marginTop: '10px', marginBottom: '10px' }}>
                <Col lg>
                  <div className="text-center merchant-lockout">
                    <p className="labelAdGroup mb-0">From</p>
                    <DatePicker
                      className="datePickerValueStyling"
                      selected={this.state.dateRanges.third.from}
                      onChange={(date) => {
                        this.handleReportDateChange(date, "third", "from")
                      }}
                      dateFormat="MM/dd/yyyy"
                      placeholderText="From"
                      disabled={!this.state.dateRanges.second.to}
                      popperPlacement="bottom-end"
                      popperProps={{
                        positionFixed: true
                      }}
                    />
                  </div>
                </Col>



                <Col lg>
                  <div className=" text-center merchant-lockout">
                    <p className="labelAdGroup mb-0">To</p>
                    <DatePicker
                      className="datePickerValueStyling"
                      selected={this.state.dateRanges.third.to}
                      onChange={(date) => {
                        this.handleReportDateChange(date, "third", "to")
                      }}
                      dateFormat="MM/dd/yyyy"
                      minDate={
                        this.state.dateRanges.third.from
                      }
                      placeholderText="To"
                      disabled={!this.state.dateRanges.third.from}
                      popperPlacement="bottom-end"
                      popperProps={{
                        positionFixed: true
                      }}
                    />
                  </div>
                </Col>


                <Col lg>
                  <div className="text-center merchant-lockout">
                    <p className="labelAdGroup mb-0">From</p>
                    <DatePicker
                      className="datePickerValueStyling"
                      selected={this.state.dateRanges.fourth.from}
                      onChange={(date) => {
                        this.handleReportDateChange(date, "fourth", "from")
                      }}
                      dateFormat="MM/dd/yyyy"
                      placeholderText="From"
                      disabled={!this.state.dateRanges.third.to}
                      popperPlacement="bottom-end"
                      popperProps={{
                        positionFixed: true
                      }}
                    />
                  </div>
                </Col>


                <Col lg>
                  <div className="text-center merchant-lockout">
                    <p className="labelAdGroup mb-0">To</p>
                    <DatePicker
                      className="datePickerValueStyling"
                      selected={this.state.dateRanges.fourth.to}
                      onChange={(date) => {
                        this.handleReportDateChange(date, "fourth", "to")
                      }}
                      dateFormat="MM/dd/yyyy"
                      minDate={
                        this.state.dateRanges.fourth.from
                      }
                      placeholderText="To"
                      disabled={!this.state.dateRanges.fourth.from}
                      popperPlacement="bottom-end"
                      popperProps={{
                        positionFixed: true
                      }}
                    />
                  </div>
                </Col>
              </Row>

              <Row style={{ marginTop: '10px', marginBottom: '10px' }}>
                <Col className="d-flex justify-content-end">
                  <div className="filterBtnContainer" style={{ marginTop: '10px' }}>
                    <Button
                      type="primary"
                      onClick={this.resetSelections}
                      style={{ marginLeft: '10px' }}
                      disabled={!(  (this.state.selectedFilters.themes && this.state.selectedFilters.themes.length > 0) || (this.state.dateRanges.first.from) || (this.state.selectedFilters.buyer && this.state.selectedFilters.buyer.length > 0) || (this.state.typedBuyerId.length>0) || (this.state.selectedFilters.adgroup && this.state.selectedFilters.adgroup.length > 0) || (this.state.typedAdGroupId.length>0) || (this.state.dateRanges.first.to) || (this.state.dateRanges.second.to) || (this.state.dateRanges.third_to) || (this.state.dateRanges.fourth.to) || (this.state.dateRanges.second.from) || (this.state.dateRanges.third.from) || (this.state.dateRanges.fourth.from)  )}
                    >
                      Reset
                    </Button>
                    <Button
                      type="primary"
                      onClick={() => this.handleGenerateReport('download')}
                      className="downloadBtn"
                      disabled={!((this.state.selectedFilters.themes && this.state.selectedFilters.themes.length > 0) || (this.state.dateRanges.first.to))}
                    >
                      Download <i className="fa fa-download" aria-hidden="true" />
                    </Button>
                    <Button
                      type="primary"
                      onClick={() => this.handleGenerateReport('generate')}
                      disabled={!((this.state.selectedFilters.themes && this.state.selectedFilters.themes.length > 0) || (this.state.dateRanges.first.to))}
                    >
                      Generate
                    </Button>
                  </div>
                </Col>
              </Row>

            </Container>
          </div>
        </div>
        <hr style={{ height: '1px', borderWidth: '0', backgroundColor: '#d3d3d3' }} />
        <div>

          {
            this.state.tableData.data &&
            <div>
              <DataTable
                tableData={this.state.tableData}
                suppressRowClickSelection={true}
              />
            </div>
          }
        </div>
      </>
    )
  }
};

const mapStateToProps = (state) => {
  return {
    // loader: state.eventMaster.loader,
    // deactivatePromoData: state.eventMaster.deactivatePromoData,
    buyerOptions: state.reports.buyerOptions,
    adGroupOptions: state.reports.adGroupOptions,
    themeOptions: state.reports.themeOptions
  };
};

export default connect(
  mapStateToProps,
  {
    getBuyerDetails,
    getAdGroupDetails,
    getThemeDetails,
    getAgparReportData
  }
)(AgparReport);