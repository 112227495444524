const { MERCHANDISE_HIERARCHY_LIST } = require("./merchIdHierarchyMapping");
const {
   MERCH_CAT_OPTIONS,
   CATEGORY_OPTIONS,
   SHOP_DESCRIPTION_OPTIONS,
   AREA_OPTIONS,
   BUSINESS_OPTIONS,
   CUSTOMERGROUP_OPTIONS,
   VENDOR_OPTIONS,
   ATTRIBUTE_COLOR_OPTIONS,
   ATTRIBUTE_FABRIC_OPTIONS,
   ATTRIBUTE_SIZE_OPTIONS,
   ATTRIBUTE_THEME_OPTIONS,
   PRODUCT_BRAND_OPTIONS
} = require("./attributeMappings");

// const ADGROUP_LABELS_OLD = [
// {
//   "value": "Bee Nice",
//   "label": "Bee Nice"
// },
// {
//   "value": "Create-a-Treat",
//   "label": "Create-a-Treat"
// },
// {
//   "value": "3M® Scotch Brite®",
//   "label": "3M® Scotch Brite®"
// },
// {
//   "value": "Abstract",
//   "label": "Abstract"
// },
// {
//   "value": "Accuquilt Go!®",
//   "label": "Accuquilt Go!®"
// },
// {
//   "value": "Accuquilt®",
//   "label": "Accuquilt®"
// },
// {
//   "value": "Activa",
//   "label": "Activa"
// },
// {
//   "value": "Adhesive Tech™",
//   "label": "Adhesive Tech™"
// },
// {
//   "value": "Advantus",
//   "label": "Advantus"
// },
// {
//   "value": "Airtex®",
//   "label": "Airtex®"
// },
// {
//   "value": "Aleene's®",
//   "label": "Aleene's®"
// },
// {
//   "value": "Alex",
//   "label": "Alex"
// },
// {
//   "value": "Alliance",
//   "label": "Alliance"
// },
// {
//   "value": "Amav®",
//   "label": "Amav®"
// },
// {
//   "value": "Amazing Clear Cast",
//   "label": "Amazing Clear Cast"
// },
// {
//   "value": "American Craft",
//   "label": "American Craft"
// },
// {
//   "value": "American Girl®",
//   "label": "American Girl®"
// },
// {
//   "value": "American Tombow",
//   "label": "American Tombow"
// },
// {
//   "value": "Americana®",
//   "label": "Americana®"
// },
// {
//   "value": "Anchor",
//   "label": "Anchor"
// },
// {
//   "value": "Anvil® by Gildan®",
//   "label": "Anvil® by Gildan®"
// },
// {
//   "value": "Apple Barrel®",
//   "label": "Apple Barrel®"
// },
// {
//   "value": "Arrow",
//   "label": "Arrow"
// },
// {
//   "value": "Art Of Autumn™",
//   "label": "Art Of Autumn™"
// },
// {
//   "value": "Artbin®",
//   "label": "Artbin®"
// },
// {
//   "value": "Arteza®",
//   "label": "Arteza®"
// },
// {
//   "value": "Artist Brands",
//   "label": "Artist Brands"
// },
// {
//   "value": "Artskills",
//   "label": "Artskills"
// },
// {
//   "value": "Artsmart Kids™",
//   "label": "Artsmart Kids™"
// },
// {
//   "value": "Artsmith",
//   "label": "Artsmith"
// },
// {
//   "value": "Artsmith™",
//   "label": "Artsmith™"
// },
// {
//   "value": "As Seen On TV",
//   "label": "As Seen On TV"
// },
// {
//   "value": "Avaria",
//   "label": "Avaria"
// },
// {
//   "value": "avaria",
//   "label": "avaria"
// },
// {
//   "value": "Babyville Boutique™",
//   "label": "Babyville Boutique™"
// },
// {
//   "value": "Back To Campus™",
//   "label": "Back To Campus™"
// },
// {
//   "value": "Badgley Mischka",
//   "label": "Badgley Mischka"
// },
// {
//   "value": "Balene™",
//   "label": "Balene™"
// },
// {
//   "value": "Ball®",
//   "label": "Ball®"
// },
// {
//   "value": "Beach Babe",
//   "label": "Beach Babe"
// },
// {
//   "value": "Beacon",
//   "label": "Beacon"
// },
// {
//   "value": "Beadalon®",
//   "label": "Beadalon®"
// },
// {
//   "value": "Beaders Paradise",
//   "label": "Beaders Paradise"
// },
// {
//   "value": "Bear Works®",
//   "label": "Bear Works®"
// },
// {
//   "value": "Becky Higgins®",
//   "label": "Becky Higgins®"
// },
// {
//   "value": "Bedazzler™",
//   "label": "Bedazzler™"
// },
// {
//   "value": "Belle",
//   "label": "Belle"
// },
// {
//   "value": "Bendon™",
//   "label": "Bendon™"
// },
// {
//   "value": "Bernat®",
//   "label": "Bernat®"
// },
// {
//   "value": "Bernina of America",
//   "label": "Bernina of America"
// },
// {
//   "value": "Better Homes And Gardens®",
//   "label": "Better Homes And Gardens®"
// },
// {
//   "value": "Big Twist™",
//   "label": "Big Twist™"
// },
// {
//   "value": "Big Twist™ & K + C™",
//   "label": "Big Twist™ & K + C™"
// },
// {
//   "value": "Binney & Smith®",
//   "label": "Binney & Smith®"
// },
// {
//   "value": "Bliss",
//   "label": "Bliss"
// },
// {
//   "value": "Bloom Room Artisan™",
//   "label": "Bloom Room Artisan™"
// },
// {
//   "value": "Bloom Room®",
//   "label": "Bloom Room®"
// },
// {
//   "value": "Blue Mood",
//   "label": "Blue Mood"
// },
// {
//   "value": "Blue Moon®",
//   "label": "Blue Moon®"
// },
// {
//   "value": "Blumenthal",
//   "label": "Blumenthal"
// },
// {
//   "value": "Bob Ross®",
//   "label": "Bob Ross®"
// },
// {
//   "value": "Boye®",
//   "label": "Boye®"
// },
// {
//   "value": "Brewster",
//   "label": "Brewster"
// },
// {
//   "value": "Bridal Inspirations™",
//   "label": "Bridal Inspirations™"
// },
// {
//   "value": "Brother International",
//   "label": "Brother International"
// },
// {
//   "value": "Bucilla®",
//   "label": "Bucilla®"
// },
// {
//   "value": "Buffalo Batting",
//   "label": "Buffalo Batting"
// },
// {
//   "value": "Burda®",
//   "label": "Burda®"
// },
// {
//   "value": "Burnes",
//   "label": "Burnes"
// },
// {
//   "value": "Busykids™",
//   "label": "Busykids™"
// },
// {
//   "value": "Buttercream™",
//   "label": "Buttercream™"
// },
// {
//   "value": "Buttercre™Luxe Craft",
//   "label": "Buttercre™Luxe Craft"
// },
// {
//   "value": "Butterick®",
//   "label": "Butterick®"
// },
// {
//   "value": "Buttoneer®",
//   "label": "Buttoneer®"
// },
// {
//   "value": "Buzzy®",
//   "label": "Buzzy®"
// },
// {
//   "value": "Cactus",
//   "label": "Cactus"
// },
// {
//   "value": "Cake Craft™",
//   "label": "Cake Craft™"
// },
// {
//   "value": "Camo Couture™",
//   "label": "Camo Couture™"
// },
// {
//   "value": "Camp Ann",
//   "label": "Camp Ann"
// },
// {
//   "value": "Canon®",
//   "label": "Canon®"
// },
// {
//   "value": "Canson®",
//   "label": "Canson®"
// },
// {
//   "value": "Canson® & Strathmore®",
//   "label": "Canson® & Strathmore®"
// },
// {
//   "value": "Canvas Keepsakes™",
//   "label": "Canvas Keepsakes™"
// },
// {
//   "value": "Carbona®",
//   "label": "Carbona®"
// },
// {
//   "value": "Carolina®",
//   "label": "Carolina®"
// },
// {
//   "value": "Caron®",
//   "label": "Caron®"
// },
// {
//   "value": "Casa Collection™",
//   "label": "Casa Collection™"
// },
// {
//   "value": "Ceramcoat",
//   "label": "Ceramcoat"
// },
// {
//   "value": "Charmed Life",
//   "label": "Charmed Life"
// },
// {
//   "value": "Cheer & Co.™",
//   "label": "Cheer & Co.™"
// },
// {
//   "value": "Chicago Metallic™",
//   "label": "Chicago Metallic™"
// },
// {
//   "value": "Christopher Lowell Collection®",
//   "label": "Christopher Lowell Collection®"
// },
// {
//   "value": "Cleckheaton",
//   "label": "Cleckheaton"
// },
// {
//   "value": "Clip Eez",
//   "label": "Clip Eez"
// },
// {
//   "value": "Cloud 9",
//   "label": "Cloud 9"
// },
// {
//   "value": "Clover",
//   "label": "Clover"
// },
// {
//   "value": "Clover® Mini Iron™",
//   "label": "Clover® Mini Iron™"
// },
// {
//   "value": "Coastal",
//   "label": "Coastal"
// },
// {
//   "value": "Coats & Clark®",
//   "label": "Coats & Clark®"
// },
// {
//   "value": "Coca-Cola®",
//   "label": "Coca-Cola®"
// },
// {
//   "value": "Colart",
//   "label": "Colart"
// },
// {
//   "value": "Color Pour",
//   "label": "Color Pour"
// },
// {
//   "value": "Color Pour Resin",
//   "label": "Color Pour Resin"
// },
// {
//   "value": "Colorbok®",
//   "label": "Colorbok®"
// },
// {
//   "value": "ColorShot",
//   "label": "ColorShot"
// },
// {
//   "value": "Comfort Colors®",
//   "label": "Comfort Colors®"
// },
// {
//   "value": "Conair",
//   "label": "Conair"
// },
// {
//   "value": "Contact Brand",
//   "label": "Contact Brand"
// },
// {
//   "value": "Copco®",
//   "label": "Copco®"
// },
// {
//   "value": "Core'dinations®",
//   "label": "Core'dinations®"
// },
// {
//   "value": "Cosmo Girl!®",
//   "label": "Cosmo Girl!®"
// },
// {
//   "value": "Cousin®",
//   "label": "Cousin®"
// },
// {
//   "value": "Cra-Z-Art®",
//   "label": "Cra-Z-Art®"
// },
// {
//   "value": "Craft & Scrap Essentials™",
//   "label": "Craft & Scrap Essentials™"
// },
// {
//   "value": "Craft Essentials™",
//   "label": "Craft Essentials™"
// },
// {
//   "value": "Craft-N-Carry™",
//   "label": "Craft-N-Carry™"
// },
// {
//   "value": "Crafted Living",
//   "label": "Crafted Living"
// },
// {
//   "value": "Crafter's Choice™",
//   "label": "Crafter's Choice™"
// },
// {
//   "value": "Craftologie®",
//   "label": "Craftologie®"
// },
// {
//   "value": "Crafty Productictions",
//   "label": "Crafty Productictions"
// },
// {
//   "value": "Crayola®",
//   "label": "Crayola®"
// },
// {
//   "value": "Crayola® & LEGO®",
//   "label": "Crayola® & LEGO®"
// },
// {
//   "value": "Create-a-Treat",
//   "label": "Create-a-Treat"
// },
// {
//   "value": "Creative Couture",
//   "label": "Creative Couture"
// },
// {
//   "value": "Creative Girl ByJulieComstock™",
//   "label": "Creative Girl ByJulieComstock™"
// },
// {
//   "value": "Creative U",
//   "label": "Creative U"
// },
// {
//   "value": "Creativity For Kids®",
//   "label": "Creativity For Kids®"
// },
// {
//   "value": "Creativity Kits™",
//   "label": "Creativity Kits™"
// },
// {
//   "value": "Cricut Expression® 2",
//   "label": "Cricut Expression® 2"
// },
// {
//   "value": "Cricut®",
//   "label": "Cricut®"
// },
// {
//   "value": "Crop In Style®",
//   "label": "Crop In Style®"
// },
// {
//   "value": "Crop-A-Dile™",
//   "label": "Crop-A-Dile™"
// },
// {
//   "value": "Cropper Hopper™",
//   "label": "Cropper Hopper™"
// },
// {
//   "value": "Croscill®",
//   "label": "Croscill®"
// },
// {
//   "value": "Crypton®",
//   "label": "Crypton®"
// },
// {
//   "value": "Cuddly Socks™",
//   "label": "Cuddly Socks™"
// },
// {
//   "value": "Cupcake Creations™",
//   "label": "Cupcake Creations™"
// },
// {
//   "value": "Cupixel",
//   "label": "Cupixel"
// },
// {
//   "value": "Curiosity Kits®",
//   "label": "Curiosity Kits®"
// },
// {
//   "value": "Currier & Ives®",
//   "label": "Currier & Ives®"
// },
// {
//   "value": "Curve Decor™",
//   "label": "Curve Decor™"
// },
// {
//   "value": "Custom Framing",
//   "label": "Custom Framing"
// },
// {
//   "value": "Custom Moulding",
//   "label": "Custom Moulding"
// },
// {
//   "value": "Cuttlebug™",
//   "label": "Cuttlebug™"
// },
// {
//   "value": "Darice®",
//   "label": "Darice®"
// },
// {
//   "value": "Dark Matter",
//   "label": "Dark Matter"
// },
// {
//   "value": "David Tutera™",
//   "label": "David Tutera™"
// },
// {
//   "value": "Daylight",
//   "label": "Daylight"
// },
// {
//   "value": "DCWV®",
//   "label": "DCWV®"
// },
// {
//   "value": "Debbie Mumm®",
//   "label": "Debbie Mumm®"
// },
// {
//   "value": "Deborah Norville Collection™",
//   "label": "Deborah Norville Collection™"
// },
// {
//   "value": "Decoart®",
//   "label": "Decoart®"
// },
// {
//   "value": "Decorator's Choice®",
//   "label": "Decorator's Choice®"
// },
// {
//   "value": "Decorware Inc",
//   "label": "Decorware Inc"
// },
// {
//   "value": "Delta",
//   "label": "Delta"
// },
// {
//   "value": "Desert Muse",
//   "label": "Desert Muse"
// },
// {
//   "value": "Design Studio",
//   "label": "Design Studio"
// },
// {
//   "value": "Design Works™",
//   "label": "Design Works™"
// },
// {
//   "value": "Designer Vault™",
//   "label": "Designer Vault™"
// },
// {
//   "value": "Deval",
//   "label": "Deval"
// },
// {
//   "value": "Diamond Dotz®",
//   "label": "Diamond Dotz®"
// },
// {
//   "value": "Dimensions",
//   "label": "Dimensions"
// },
// {
//   "value": "Discovery™",
//   "label": "Discovery™"
// },
// {
//   "value": "Disney®",
//   "label": "Disney®"
// },
// {
//   "value": "Disney® Pixar",
//   "label": "Disney® Pixar"
// },
// {
//   "value": "Diva Kit",
//   "label": "Diva Kit"
// },
// {
//   "value": "DMC®",
//   "label": "DMC®"
// },
// {
//   "value": "Donna Dewberry™",
//   "label": "Donna Dewberry™"
// },
// {
//   "value": "Doodle-Loom®",
//   "label": "Doodle-Loom®"
// },
// {
//   "value": "dreamhappy™",
//   "label": "dreamhappy™"
// },
// {
//   "value": "Dremel",
//   "label": "Dremel"
// },
// {
//   "value": "Dress It Up®",
//   "label": "Dress It Up®"
// },
// {
//   "value": "Dritz®",
//   "label": "Dritz®"
// },
// {
//   "value": "Duck® Brand",
//   "label": "Duck® Brand"
// },
// {
//   "value": "Duncan",
//   "label": "Duncan"
// },
// {
//   "value": "Dunecraft®",
//   "label": "Dunecraft®"
// },
// {
//   "value": "Duro®",
//   "label": "Duro®"
// },
// {
//   "value": "Dust Off®",
//   "label": "Dust Off®"
// },
// {
//   "value": "Dutch Boy®",
//   "label": "Dutch Boy®"
// },
// {
//   "value": "DW Home",
//   "label": "DW Home"
// },
// {
//   "value": "Dylite®",
//   "label": "Dylite®"
// },
// {
//   "value": "Dylon®",
//   "label": "Dylon®"
// },
// {
//   "value": "E6000",
//   "label": "E6000"
// },
// {
//   "value": "Easter",
//   "label": "Easter"
// },
// {
//   "value": "Easter Craft",
//   "label": "Easter Craft"
// },
// {
//   "value": "Easy Stitch 'n Save®",
//   "label": "Easy Stitch 'n Save®"
// },
// {
//   "value": "Eberhard Faber®",
//   "label": "Eberhard Faber®"
// },
// {
//   "value": "Eclipse",
//   "label": "Eclipse"
// },
// {
//   "value": "Ecocraft™",
//   "label": "Ecocraft™"
// },
// {
//   "value": "Eddie Bauer®",
//   "label": "Eddie Bauer®"
// },
// {
//   "value": "Eddie Bauer® & Christopher",
//   "label": "Eddie Bauer® & Christopher"
// },
// {
//   "value": "EK Success®",
//   "label": "EK Success®"
// },
// {
//   "value": "Element Essense",
//   "label": "Element Essense"
// },
// {
//   "value": "Elmer's®",
//   "label": "Elmer's®"
// },
// {
//   "value": "Entire Stock",
//   "label": "Entire Stock"
// },
// {
//   "value": "Escape To Paradise™",
//   "label": "Escape To Paradise™"
// },
// {
//   "value": "Escape To Paradise™/Americana™",
//   "label": "Escape To Paradise™/Americana™"
// },
// {
//   "value": "Evening Inspirations",
//   "label": "Evening Inspirations"
// },
// {
//   "value": "Everything Mary",
//   "label": "Everything Mary"
// },
// {
//   "value": "Expressive Design Group",
//   "label": "Expressive Design Group"
// },
// {
//   "value": "Fab Lab™",
//   "label": "Fab Lab™"
// },
// {
//   "value": "Faber Castell",
//   "label": "Faber Castell"
// },
// {
//   "value": "Fabri-Tac®",
//   "label": "Fabri-Tac®"
// },
// {
//   "value": "Fabriano EcoQua",
//   "label": "Fabriano EcoQua"
// },
// {
//   "value": "Fabricmate™",
//   "label": "Fabricmate™"
// },
// {
//   "value": "Fabricraft™",
//   "label": "Fabricraft™"
// },
// {
//   "value": "Fairfield®",
//   "label": "Fairfield®"
// },
// {
//   "value": "Fashion & Fluff™",
//   "label": "Fashion & Fluff™"
// },
// {
//   "value": "Favorite Findings",
//   "label": "Favorite Findings"
// },
// {
//   "value": "Fetco®",
//   "label": "Fetco®"
// },
// {
//   "value": "Fibercraft International®",
//   "label": "Fibercraft International®"
// },
// {
//   "value": "Fieldcrest®",
//   "label": "Fieldcrest®"
// },
// {
//   "value": "Fimo®",
//   "label": "Fimo®"
// },
// {
//   "value": "Fine Art Markers",
//   "label": "Fine Art Markers"
// },
// {
//   "value": "FINE ART PAPER PADS",
//   "label": "FINE ART PAPER PADS"
// },
// {
//   "value": "Fiskars®",
//   "label": "Fiskars®"
// },
// {
//   "value": "Fleeces Pieces®",
//   "label": "Fleeces Pieces®"
// },
// {
//   "value": "Flings",
//   "label": "Flings"
// },
// {
//   "value": "Floracraft®",
//   "label": "Floracraft®"
// },
// {
//   "value": "Florasens™",
//   "label": "Florasens™"
// },
// {
//   "value": "Foamies®",
//   "label": "Foamies®"
// },
// {
//   "value": "Folk Art®",
//   "label": "Folk Art®"
// },
// {
//   "value": "Fons & Porter™",
//   "label": "Fons & Porter™"
// },
// {
//   "value": "Foodcrafting Inspirations™",
//   "label": "Foodcrafting Inspirations™"
// },
// {
//   "value": "Forcefield",
//   "label": "Forcefield"
// },
// {
//   "value": "Foundry",
//   "label": "Foundry"
// },
// {
//   "value": "Fourstar",
//   "label": "Fourstar"
// },
// {
//   "value": "Fray Check™",
//   "label": "Fray Check™"
// },
// {
//   "value": "Fredrix®",
//   "label": "Fredrix®"
// },
// {
//   "value": "Fujifilm® Instax™",
//   "label": "Fujifilm® Instax™"
// },
// {
//   "value": "Funkins™",
//   "label": "Funkins™"
// },
// {
//   "value": "Furryart™",
//   "label": "Furryart™"
// },
// {
//   "value": "Fuzzy®",
//   "label": "Fuzzy®"
// },
// {
//   "value": "Gallery J®",
//   "label": "Gallery J®"
// },
// {
//   "value": "Gamblin 1980 Oil",
//   "label": "Gamblin 1980 Oil"
// },
// {
//   "value": "Ganz",
//   "label": "Ganz"
// },
// {
//   "value": "Gelly Roll®",
//   "label": "Gelly Roll®"
// },
// {
//   "value": "Gerber®",
//   "label": "Gerber®"
// },
// {
//   "value": "Get A Grip",
//   "label": "Get A Grip"
// },
// {
//   "value": "GHI",
//   "label": "GHI"
// },
// {
//   "value": "Ghirardelli",
//   "label": "Ghirardelli"
// },
// {
//   "value": "Gick®",
//   "label": "Gick®"
// },
// {
//   "value": "Gildan®",
//   "label": "Gildan®"
// },
// {
//   "value": "Gilded Glam",
//   "label": "Gilded Glam"
// },
// {
//   "value": "Gingher®",
//   "label": "Gingher®"
// },
// {
//   "value": "Glassmates",
//   "label": "Glassmates"
// },
// {
//   "value": "Glitterbug™",
//   "label": "Glitterbug™"
// },
// {
//   "value": "Glowforge",
//   "label": "Glowforge"
// },
// {
//   "value": "Golden®",
//   "label": "Golden®"
// },
// {
//   "value": "Goo Gone®",
//   "label": "Goo Gone®"
// },
// {
//   "value": "Gorilla®",
//   "label": "Gorilla®"
// },
// {
//   "value": "Graber®",
//   "label": "Graber®"
// },
// {
//   "value": "Gutermann®",
//   "label": "Gutermann®"
// },
// {
//   "value": "Gypsy™",
//   "label": "Gypsy™"
// },
// {
//   "value": "Halcraft®",
//   "label": "Halcraft®"
// },
// {
//   "value": "Hamilton Beach®",
//   "label": "Hamilton Beach®"
// },
// {
//   "value": "Hampton Home Decor",
//   "label": "Hampton Home Decor"
// },
// {
//   "value": "Handmade Halloween",
//   "label": "Handmade Halloween"
// },
// {
//   "value": "Handmade Holiday",
//   "label": "Handmade Holiday"
// },
// {
//   "value": "Hannah Montana",
//   "label": "Hannah Montana"
// },
// {
//   "value": "Happy",
//   "label": "Happy"
// },
// {
//   "value": "Harley Davidson®",
//   "label": "Harley Davidson®"
// },
// {
//   "value": "Harry Potter™",
//   "label": "Harry Potter™"
// },
// {
//   "value": "Hazel & Ruby®",
//   "label": "Hazel & Ruby®"
// },
// {
//   "value": "Heat'n'Bond®",
//   "label": "Heat'n'Bond®"
// },
// {
//   "value": "Heidi Grace Designs",
//   "label": "Heidi Grace Designs"
// },
// {
//   "value": "Heidi Swapp™",
//   "label": "Heidi Swapp™"
// },
// {
//   "value": "Heirloom",
//   "label": "Heirloom"
// },
// {
//   "value": "Hello Kitty",
//   "label": "Hello Kitty"
// },
// {
//   "value": "Hello Lovely",
//   "label": "Hello Lovely"
// },
// {
//   "value": "Hero Arts",
//   "label": "Hero Arts"
// },
// {
//   "value": "Hexabits™",
//   "label": "Hexabits™"
// },
// {
//   "value": "Hey Doll",
//   "label": "Hey Doll"
// },
// {
//   "value": "HGTV Home",
//   "label": "HGTV Home"
// },
// {
//   "value": "Hi Sig Series & Levolor",
//   "label": "Hi Sig Series & Levolor"
// },
// {
//   "value": "Highschool Musical",
//   "label": "Highschool Musical"
// },
// {
//   "value": "hildie & jo®",
//   "label": "hildie & jo®"
// },
// {
//   "value": "Hip In A Hurry™",
//   "label": "Hip In A Hurry™"
// },
// {
//   "value": "Hip In A Hurry™ DCWV™",
//   "label": "Hip In A Hurry™ DCWV™"
// },
// {
//   "value": "Hirschberg",
//   "label": "Hirschberg"
// },
// {
//   "value": "Hollywood Fashion Secrets®",
//   "label": "Hollywood Fashion Secrets®"
// },
// {
//   "value": "Holmes®",
//   "label": "Holmes®"
// },
// {
//   "value": "Home Elegance",
//   "label": "Home Elegance"
// },
// {
//   "value": "Home Essentials™",
//   "label": "Home Essentials™"
// },
// {
//   "value": "Home Inspirations&Candle-lite",
//   "label": "Home Inspirations&Candle-lite"
// },
// {
//   "value": "Home Inspirations™",
//   "label": "Home Inspirations™"
// },
// {
//   "value": "Home Inspirations™ & KC™",
//   "label": "Home Inspirations™ & KC™"
// },
// {
//   "value": "Homedics",
//   "label": "Homedics"
// },
// {
//   "value": "Honeyware",
//   "label": "Honeyware"
// },
// {
//   "value": "Hotfix™",
//   "label": "Hotfix™"
// },
// {
//   "value": "Hudson 43®",
//   "label": "Hudson 43®"
// },
// {
//   "value": "Hudson 43™ and Place & Time",
//   "label": "Hudson 43™ and Place & Time"
// },
// {
//   "value": "Hudson 43™& Haven Street",
//   "label": "Hudson 43™& Haven Street"
// },
// {
//   "value": "Husqvarna Viking® Jade 20™",
//   "label": "Husqvarna Viking® Jade 20™"
// },
// {
//   "value": "Husqvarna® Viking®",
//   "label": "Husqvarna® Viking®"
// },
// {
//   "value": "ICA",
//   "label": "ICA"
// },
// {
//   "value": "Illinois Bronze®",
//   "label": "Illinois Bronze®"
// },
// {
//   "value": "Illooms®",
//   "label": "Illooms®"
// },
// {
//   "value": "Image Hill®",
//   "label": "Image Hill®"
// },
// {
//   "value": "ImpressArt®",
//   "label": "ImpressArt®"
// },
// {
//   "value": "In The Garden™",
//   "label": "In The Garden™"
// },
// {
//   "value": "Indigo Mist",
//   "label": "Indigo Mist"
// },
// {
//   "value": "Inglow®",
//   "label": "Inglow®"
// },
// {
//   "value": "Inkadinkado®",
//   "label": "Inkadinkado®"
// },
// {
//   "value": "Insul-Bright™",
//   "label": "Insul-Bright™"
// },
// {
//   "value": "Intercraft®",
//   "label": "Intercraft®"
// },
// {
//   "value": "Interior Accents®",
//   "label": "Interior Accents®"
// },
// {
//   "value": "iPod®",
//   "label": "iPod®"
// },
// {
//   "value": "Janlynn",
//   "label": "Janlynn"
// },
// {
//   "value": "Janlynn & Wrights",
//   "label": "Janlynn & Wrights"
// },
// {
//   "value": "Janome America Inc",
//   "label": "Janome America Inc"
// },
// {
//   "value": "Jelly Belly®",
//   "label": "Jelly Belly®"
// },
// {
//   "value": "Jesse James",
//   "label": "Jesse James"
// },
// {
//   "value": "Jessware",
//   "label": "Jessware"
// },
// {
//   "value": "Jewelry Made by Me",
//   "label": "Jewelry Made by Me"
// },
// {
//   "value": "JHB International®",
//   "label": "JHB International®"
// },
// {
//   "value": "Jo-Ann Idea™",
//   "label": "Jo-Ann Idea™"
// },
// {
//   "value": "JOANN",
//   "label": "JOANN"
// },
// {
//   "value": "John Bead",
//   "label": "John Bead"
// },
// {
//   "value": "John Deere™",
//   "label": "John Deere™"
// },
// {
//   "value": "Jolee's®",
//   "label": "Jolee's®"
// },
// {
//   "value": "Juicy Couture®",
//   "label": "Juicy Couture®"
// },
// {
//   "value": "Juki America Inc",
//   "label": "Juki America Inc"
// },
// {
//   "value": "June Tailor®",
//   "label": "June Tailor®"
// },
// {
//   "value": "K + C™",
//   "label": "K + C™"
// },
// {
//   "value": "K&Company",
//   "label": "K&Company"
// },
// {
//   "value": "Kaleidoscope",
//   "label": "Kaleidoscope"
// },
// {
//   "value": "Keep Box™",
//   "label": "Keep Box™"
// },
// {
//   "value": "Keepsake Calico",
//   "label": "Keepsake Calico"
// },
// {
//   "value": "Keepsake Garden Collection",
//   "label": "Keepsake Garden Collection"
// },
// {
//   "value": "Kelly Creates",
//   "label": "Kelly Creates"
// },
// {
//   "value": "KI®",
//   "label": "KI®"
// },
// {
//   "value": "Kid Concoctions™",
//   "label": "Kid Concoctions™"
// },
// {
//   "value": "Kids Camp",
//   "label": "Kids Camp"
// },
// {
//   "value": "Kids Kits®",
//   "label": "Kids Kits®"
// },
// {
//   "value": "Kidzors™",
//   "label": "Kidzors™"
// },
// {
//   "value": "Kirsch®",
//   "label": "Kirsch®"
// },
// {
//   "value": "Kitchen Aid®",
//   "label": "Kitchen Aid®"
// },
// {
//   "value": "Klutz®",
//   "label": "Klutz®"
// },
// {
//   "value": "Knifty Knitter®",
//   "label": "Knifty Knitter®"
// },
// {
//   "value": "Knit & Crochet™",
//   "label": "Knit & Crochet™"
// },
// {
//   "value": "Knit Collage",
//   "label": "Knit Collage"
// },
// {
//   "value": "Knit-Wit®",
//   "label": "Knit-Wit®"
// },
// {
//   "value": "KONA®",
//   "label": "KONA®"
// },
// {
//   "value": "Krazy Glue",
//   "label": "Krazy Glue"
// },
// {
//   "value": "Krylon®",
//   "label": "Krylon®"
// },
// {
//   "value": "Kunin®",
//   "label": "Kunin®"
// },
// {
//   "value": "Kwik Sew®",
//   "label": "Kwik Sew®"
// },
// {
//   "value": "LA Collection",
//   "label": "LA Collection"
// },
// {
//   "value": "LA Mode®",
//   "label": "LA Mode®"
// },
// {
//   "value": "Laliberi™",
//   "label": "Laliberi™"
// },
// {
//   "value": "Land Of The Free",
//   "label": "Land Of The Free"
// },
// {
//   "value": "Latique",
//   "label": "Latique"
// },
// {
//   "value": "LAURdiy",
//   "label": "LAURdiy"
// },
// {
//   "value": "Legacy Studio™",
//   "label": "Legacy Studio™"
// },
// {
//   "value": "Legacy Studio™Indonesian&Quilt",
//   "label": "Legacy Studio™Indonesian&Quilt"
// },
// {
//   "value": "LeGale®",
//   "label": "LeGale®"
// },
// {
//   "value": "LEGO®",
//   "label": "LEGO®"
// },
// {
//   "value": "Levolor®",
//   "label": "Levolor®"
// },
// {
//   "value": "Lia Griffith",
//   "label": "Lia Griffith"
// },
// {
//   "value": "Libbey®",
//   "label": "Libbey®"
// },
// {
//   "value": "Liberty",
//   "label": "Liberty"
// },
// {
//   "value": "Lifestyle Craft",
//   "label": "Lifestyle Craft"
// },
// {
//   "value": "Lil Teammates®",
//   "label": "Lil Teammates®"
// },
// {
//   "value": "Lil' Pop!™",
//   "label": "Lil' Pop!™"
// },
// {
//   "value": "Lily's®",
//   "label": "Lily's®"
// },
// {
//   "value": "Lily® Sugar 'n Cream®",
//   "label": "Lily® Sugar 'n Cream®"
// },
// {
//   "value": "Lion Brand®",
//   "label": "Lion Brand®"
// },
// {
//   "value": "Liquid Illusions™",
//   "label": "Liquid Illusions™"
// },
// {
//   "value": "Liquid Stitch®",
//   "label": "Liquid Stitch®"
// },
// {
//   "value": "Liquitex®",
//   "label": "Liquitex®"
// },
// {
//   "value": "Little Makers™",
//   "label": "Little Makers™"
// },
// {
//   "value": "Little Makers™ & POP!™",
//   "label": "Little Makers™ & POP!™"
// },
// {
//   "value": "Loctite®",
//   "label": "Loctite®"
// },
// {
//   "value": "Loew-Cornell®",
//   "label": "Loew-Cornell®"
// },
// {
//   "value": "Lucky Brand",
//   "label": "Lucky Brand"
// },
// {
//   "value": "Luminara®",
//   "label": "Luminara®"
// },
// {
//   "value": "Luminous Luster",
//   "label": "Luminous Luster"
// },
// {
//   "value": "Luxite®",
//   "label": "Luxite®"
// },
// {
//   "value": "Luxury Collection",
//   "label": "Luxury Collection"
// },
// {
//   "value": "Luxury Loft",
//   "label": "Luxury Loft"
// },
// {
//   "value": "Make It A Party",
//   "label": "Make It A Party"
// },
// {
//   "value": "Make It Love It™",
//   "label": "Make It Love It™"
// },
// {
//   "value": "Making Magic™",
//   "label": "Making Magic™"
// },
// {
//   "value": "Making Memories™",
//   "label": "Making Memories™"
// },
// {
//   "value": "Makit & Bakit®",
//   "label": "Makit & Bakit®"
// },
// {
//   "value": "Malden®",
//   "label": "Malden®"
// },
// {
//   "value": "Martha Stewart Crafts™",
//   "label": "Martha Stewart Crafts™"
// },
// {
//   "value": "Marvy® Uchida",
//   "label": "Marvy® Uchida"
// },
// {
//   "value": "Mary Ellen's",
//   "label": "Mary Ellen's"
// },
// {
//   "value": "Masterpieces",
//   "label": "Masterpieces"
// },
// {
//   "value": "McCall's®",
//   "label": "McCall's®"
// },
// {
//   "value": "McG",
//   "label": "McG"
// },
// {
//   "value": "Me & My Big Ideas®",
//   "label": "Me & My Big Ideas®"
// },
// {
//   "value": "Me & My Peeps™",
//   "label": "Me & My Peeps™"
// },
// {
//   "value": "Melissa & Doug®",
//   "label": "Melissa & Doug®"
// },
// {
//   "value": "Meltable Candy Wafers",
//   "label": "Meltable Candy Wafers"
// },
// {
//   "value": "Memories Made Easy",
//   "label": "Memories Made Easy"
// },
// {
//   "value": "Memory Bootique™",
//   "label": "Memory Bootique™"
// },
// {
//   "value": "Metal Xpressions",
//   "label": "Metal Xpressions"
// },
// {
//   "value": "Mighty Bright®",
//   "label": "Mighty Bright®"
// },
// {
//   "value": "Milestones™",
//   "label": "Milestones™"
// },
// {
//   "value": "MISTI®",
//   "label": "MISTI®"
// },
// {
//   "value": "Mix & Mingle",
//   "label": "Mix & Mingle"
// },
// {
//   "value": "Mix the Media",
//   "label": "Mix the Media"
// },
// {
//   "value": "MLB®",
//   "label": "MLB®"
// },
// {
//   "value": "Mod Podge®",
//   "label": "Mod Podge®"
// },
// {
//   "value": "Moda Dea®",
//   "label": "Moda Dea®"
// },
// {
//   "value": "Modern Guise",
//   "label": "Modern Guise"
// },
// {
//   "value": "Mohawk",
//   "label": "Mohawk"
// },
// {
//   "value": "Momenta®",
//   "label": "Momenta®"
// },
// {
//   "value": "Mountain Mist®",
//   "label": "Mountain Mist®"
// },
// {
//   "value": "Mrs. Grossman's",
//   "label": "Mrs. Grossman's"
// },
// {
//   "value": "MSC Collectibles",
//   "label": "MSC Collectibles"
// },
// {
//   "value": "Mundial®",
//   "label": "Mundial®"
// },
// {
//   "value": "MYO",
//   "label": "MYO"
// },
// {
//   "value": "NA",
//   "label": "NA"
// },
// {
//   "value": "Nate Berkus™",
//   "label": "Nate Berkus™"
// },
// {
//   "value": "Nature Fil",
//   "label": "Nature Fil"
// },
// {
//   "value": "Natures Own",
//   "label": "Natures Own"
// },
// {
//   "value": "NBA®",
//   "label": "NBA®"
// },
// {
//   "value": "Nes & Hey Doll",
//   "label": "Nes & Hey Doll"
// },
// {
//   "value": "New Look",
//   "label": "New Look"
// },
// {
//   "value": "Next Level Apparel",
//   "label": "Next Level Apparel"
// },
// {
//   "value": "NFL®",
//   "label": "NFL®"
// },
// {
//   "value": "Nickelodeon®",
//   "label": "Nickelodeon®"
// },
// {
//   "value": "Noel Collection",
//   "label": "Noel Collection"
// },
// {
//   "value": "Nordic Ware®",
//   "label": "Nordic Ware®"
// },
// {
//   "value": "NSI®",
//   "label": "NSI®"
// },
// {
//   "value": "NSI® Imagine Nation®",
//   "label": "NSI® Imagine Nation®"
// },
// {
//   "value": "Nu-foam®",
//   "label": "Nu-foam®"
// },
// {
//   "value": "Nurture Nest®",
//   "label": "Nurture Nest®"
// },
// {
//   "value": "Offray®",
//   "label": "Offray®"
// },
// {
//   "value": "Olfa®",
//   "label": "Olfa®"
// },
// {
//   "value": "Oliso®",
//   "label": "Oliso®"
// },
// {
//   "value": "Omnigrid®",
//   "label": "Omnigrid®"
// },
// {
//   "value": "Once Upon A Playtime™",
//   "label": "Once Upon A Playtime™"
// },
// {
//   "value": "One Stroke",
//   "label": "One Stroke"
// },
// {
//   "value": "One Together",
//   "label": "One Together"
// },
// {
//   "value": "Open Road",
//   "label": "Open Road"
// },
// {
//   "value": "Optiart™",
//   "label": "Optiart™"
// },
// {
//   "value": "ORACAL®",
//   "label": "ORACAL®"
// },
// {
//   "value": "Organizing Essentials™",
//   "label": "Organizing Essentials™"
// },
// {
//   "value": "OttLite®",
//   "label": "OttLite®"
// },
// {
//   "value": "Oxford Street Jewelry",
//   "label": "Oxford Street Jewelry"
// },
// {
//   "value": "Paddywax Lolli™",
//   "label": "Paddywax Lolli™"
// },
// {
//   "value": "Paint Pots",
//   "label": "Paint Pots"
// },
// {
//   "value": "Painted By Me™",
//   "label": "Painted By Me™"
// },
// {
//   "value": "Palmer",
//   "label": "Palmer"
// },
// {
//   "value": "Paper House",
//   "label": "Paper House"
// },
// {
//   "value": "Paperkins",
//   "label": "Paperkins"
// },
// {
//   "value": "Park Lane®",
//   "label": "Park Lane®"
// },
// {
//   "value": "Parklane®",
//   "label": "Parklane®"
// },
// {
//   "value": "Patons®",
//   "label": "Patons®"
// },
// {
//   "value": "Patriotic",
//   "label": "Patriotic"
// },
// {
//   "value": "Peel'NStick™",
//   "label": "Peel'NStick™"
// },
// {
//   "value": "Peel'NStick™ Zots™",
//   "label": "Peel'NStick™ Zots™"
// },
// {
//   "value": "Peeps®",
//   "label": "Peeps®"
// },
// {
//   "value": "Pellon®",
//   "label": "Pellon®"
// },
// {
//   "value": "Pepperell Braiding Co Inc",
//   "label": "Pepperell Braiding Co Inc"
// },
// {
//   "value": "Pergamano®",
//   "label": "Pergamano®"
// },
// {
//   "value": "Perler®",
//   "label": "Perler®"
// },
// {
//   "value": "Picnic Spring",
//   "label": "Picnic Spring"
// },
// {
//   "value": "Pillsbury®",
//   "label": "Pillsbury®"
// },
// {
//   "value": "Pink RIbbon",
//   "label": "Pink RIbbon"
// },
// {
//   "value": "Pioneer®",
//   "label": "Pioneer®"
// },
// {
//   "value": "Place & Time®",
//   "label": "Place & Time®"
// },
// {
//   "value": "Plaid®",
//   "label": "Plaid®"
// },
// {
//   "value": "Play-Doh®",
//   "label": "Play-Doh®"
// },
// {
//   "value": "Plus-Plus",
//   "label": "Plus-Plus"
// },
// {
//   "value": "Polaroid Snap",
//   "label": "Polaroid Snap"
// },
// {
//   "value": "Polaroid ZIP",
//   "label": "Polaroid ZIP"
// },
// {
//   "value": "Poly-Fil®",
//   "label": "Poly-Fil®"
// },
// {
//   "value": "Polymark™",
//   "label": "Polymark™"
// },
// {
//   "value": "Polymer Clay & Craft Oven™",
//   "label": "Polymer Clay & Craft Oven™"
// },
// {
//   "value": "Pop Couture",
//   "label": "Pop Couture"
// },
// {
//   "value": "POP!",
//   "label": "POP!"
// },
// {
//   "value": "POP!™",
//   "label": "POP!™"
// },
// {
//   "value": "Post-It®",
//   "label": "Post-It®"
// },
// {
//   "value": "Pounder®",
//   "label": "Pounder®"
// },
// {
//   "value": "Premier®",
//   "label": "Premier®"
// },
// {
//   "value": "Premium Brands",
//   "label": "Premium Brands"
// },
// {
//   "value": "Pretty Paper™",
//   "label": "Pretty Paper™"
// },
// {
//   "value": "Princeton®",
//   "label": "Princeton®"
// },
// {
//   "value": "Prismacolor®",
//   "label": "Prismacolor®"
// },
// {
//   "value": "Project Runway",
//   "label": "Project Runway"
// },
// {
//   "value": "Provo Craft®",
//   "label": "Provo Craft®"
// },
// {
//   "value": "Prym",
//   "label": "Prym"
// },
// {
//   "value": "Purl Essence®",
//   "label": "Purl Essence®"
// },
// {
//   "value": "Purse-N-Alize It!",
//   "label": "Purse-N-Alize It!"
// },
// {
//   "value": "Quilt Block Of The Month",
//   "label": "Quilt Block Of The Month"
// },
// {
//   "value": "Quilter's Palette™",
//   "label": "Quilter's Palette™"
// },
// {
//   "value": "Quilter's Showcase",
//   "label": "Quilter's Showcase"
// },
// {
//   "value": "Quincrafts®",
//   "label": "Quincrafts®"
// },
// {
//   "value": "Rainbow",
//   "label": "Rainbow"
// },
// {
//   "value": "Ready-to-Decorate",
//   "label": "Ready-to-Decorate"
// },
// {
//   "value": "Red Heart®",
//   "label": "Red Heart®"
// },
// {
//   "value": "Red Tag",
//   "label": "Red Tag"
// },
// {
//   "value": "Red White & Bold",
//   "label": "Red White & Bold"
// },
// {
//   "value": "Reeves",
//   "label": "Reeves"
// },
// {
//   "value": "Res-Q™",
//   "label": "Res-Q™"
// },
// {
//   "value": "Richloom Studio®",
//   "label": "Richloom Studio®"
// },
// {
//   "value": "Rit",
//   "label": "Rit"
// },
// {
//   "value": "Roc-Lon®",
//   "label": "Roc-Lon®"
// },
// {
//   "value": "Rose Art®",
//   "label": "Rose Art®"
// },
// {
//   "value": "Rowenta®",
//   "label": "Rowenta®"
// },
// {
//   "value": "Royal & Langnickel®",
//   "label": "Royal & Langnickel®"
// },
// {
//   "value": "Royal Coat®",
//   "label": "Royal Coat®"
// },
// {
//   "value": "Royale®",
//   "label": "Royale®"
// },
// {
//   "value": "Ruggables",
//   "label": "Ruggables"
// },
// {
//   "value": "Running 8th's™",
//   "label": "Running 8th's™"
// },
// {
//   "value": "Russell Athletic®",
//   "label": "Russell Athletic®"
// },
// {
//   "value": "Rust-oleum®",
//   "label": "Rust-oleum®"
// },
// {
//   "value": "Rust-oleum® & Pinty Plus",
//   "label": "Rust-oleum® & Pinty Plus"
// },
// {
//   "value": "Safari® LTD",
//   "label": "Safari® LTD"
// },
// {
//   "value": "Sands Alive™",
//   "label": "Sands Alive™"
// },
// {
//   "value": "Sandylion®",
//   "label": "Sandylion®"
// },
// {
//   "value": "Save the Date",
//   "label": "Save the Date"
// },
// {
//   "value": "Save the Date™",
//   "label": "Save the Date™"
// },
// {
//   "value": "Scents of Style",
//   "label": "Scents of Style"
// },
// {
//   "value": "Scentsicles",
//   "label": "Scentsicles"
// },
// {
//   "value": "Scotch®",
//   "label": "Scotch®"
// },
// {
//   "value": "Scotchgard™",
//   "label": "Scotchgard™"
// },
// {
//   "value": "Scrap Essentials™",
//   "label": "Scrap Essentials™"
// },
// {
//   "value": "Scrappy Cat",
//   "label": "Scrappy Cat"
// },
// {
//   "value": "Scribbles®",
//   "label": "Scribbles®"
// },
// {
//   "value": "Sculpey®",
//   "label": "Sculpey®"
// },
// {
//   "value": "Sea To Sea™",
//   "label": "Sea To Sea™"
// },
// {
//   "value": "Seaport",
//   "label": "Seaport"
// },
// {
//   "value": "Seascape",
//   "label": "Seascape"
// },
// {
//   "value": "Seattle's Best®",
//   "label": "Seattle's Best®"
// },
// {
//   "value": "Sensations™",
//   "label": "Sensations™"
// },
// {
//   "value": "Serge.well™",
//   "label": "Serge.well™"
// },
// {
//   "value": "Serta®",
//   "label": "Serta®"
// },
// {
//   "value": "Sew Classics™",
//   "label": "Sew Classics™"
// },
// {
//   "value": "Sew Essentials™",
//   "label": "Sew Essentials™"
// },
// {
//   "value": "Sew For The Cure®",
//   "label": "Sew For The Cure®"
// },
// {
//   "value": "Sharper Image®",
//   "label": "Sharper Image®"
// },
// {
//   "value": "Sharpie®",
//   "label": "Sharpie®"
// },
// {
//   "value": "She Who Sews",
//   "label": "She Who Sews"
// },
// {
//   "value": "Shopkins™",
//   "label": "Shopkins™"
// },
// {
//   "value": "Showoffs™",
//   "label": "Showoffs™"
// },
// {
//   "value": "Signature Series™",
//   "label": "Signature Series™"
// },
// {
//   "value": "Silhouette Cameo®",
//   "label": "Silhouette Cameo®"
// },
// {
//   "value": "Silhouette®",
//   "label": "Silhouette®"
// },
// {
//   "value": "Silkessence™",
//   "label": "Silkessence™"
// },
// {
//   "value": "Silly Putty®",
//   "label": "Silly Putty®"
// },
// {
//   "value": "Simple Treasures™",
//   "label": "Simple Treasures™"
// },
// {
//   "value": "Simplicity®",
//   "label": "Simplicity®"
// },
// {
//   "value": "Simply Art™",
//   "label": "Simply Art™"
// },
// {
//   "value": "Simply Autumn",
//   "label": "Simply Autumn"
// },
// {
//   "value": "Simply Autumn Craft",
//   "label": "Simply Autumn Craft"
// },
// {
//   "value": "Simply Autumn™",
//   "label": "Simply Autumn™"
// },
// {
//   "value": "Simply Grosgrain®",
//   "label": "Simply Grosgrain®"
// },
// {
//   "value": "Simply Painting™",
//   "label": "Simply Painting™"
// },
// {
//   "value": "Simply Soft®",
//   "label": "Simply Soft®"
// },
// {
//   "value": "Simply Spray™",
//   "label": "Simply Spray™"
// },
// {
//   "value": "Simply Spring",
//   "label": "Simply Spring"
// },
// {
//   "value": "Singer®",
//   "label": "Singer®"
// },
// {
//   "value": "Singer® Confidence™",
//   "label": "Singer® Confidence™"
// },
// {
//   "value": "Singer® Curvy™",
//   "label": "Singer® Curvy™"
// },
// {
//   "value": "Singer® Fashion Mate™",
//   "label": "Singer® Fashion Mate™"
// },
// {
//   "value": "Singer® Futura™",
//   "label": "Singer® Futura™"
// },
// {
//   "value": "Singer® Heavy Duty™",
//   "label": "Singer® Heavy Duty™"
// },
// {
//   "value": "Singer® Inspiration™",
//   "label": "Singer® Inspiration™"
// },
// {
//   "value": "Singer® Patchwork™",
//   "label": "Singer® Patchwork™"
// },
// {
//   "value": "Singer® Prelude",
//   "label": "Singer® Prelude"
// },
// {
//   "value": "Singer® ProFinish™",
//   "label": "Singer® ProFinish™"
// },
// {
//   "value": "Singer® Simple™",
//   "label": "Singer® Simple™"
// },
// {
//   "value": "Singer® Start™",
//   "label": "Singer® Start™"
// },
// {
//   "value": "Singer® Stylist™",
//   "label": "Singer® Stylist™"
// },
// {
//   "value": "Singer® Talent™",
//   "label": "Singer® Talent™"
// },
// {
//   "value": "Singer® Tradition™",
//   "label": "Singer® Tradition™"
// },
// {
//   "value": "Sirdar",
//   "label": "Sirdar"
// },
// {
//   "value": "Siser®",
//   "label": "Siser®"
// },
// {
//   "value": "Sizzix®",
//   "label": "Sizzix®"
// },
// {
//   "value": "Skullduggery Inc",
//   "label": "Skullduggery Inc"
// },
// {
//   "value": "Smart Space™",
//   "label": "Smart Space™"
// },
// {
//   "value": "Smart Trump®",
//   "label": "Smart Trump®"
// },
// {
//   "value": "Smarties®",
//   "label": "Smarties®"
// },
// {
//   "value": "Smooth Finish™",
//   "label": "Smooth Finish™"
// },
// {
//   "value": "Soap Expressions™",
//   "label": "Soap Expressions™"
// },
// {
//   "value": "Soft & Bright®",
//   "label": "Soft & Bright®"
// },
// {
//   "value": "Soft N Crafty®",
//   "label": "Soft N Crafty®"
// },
// {
//   "value": "Softee®",
//   "label": "Softee®"
// },
// {
//   "value": "Spacebag®",
//   "label": "Spacebag®"
// },
// {
//   "value": "Sparkling Woodland",
//   "label": "Sparkling Woodland"
// },
// {
//   "value": "Springfield",
//   "label": "Springfield"
// },
// {
//   "value": "Springs Creative",
//   "label": "Springs Creative"
// },
// {
//   "value": "Square by Design®",
//   "label": "Square by Design®"
// },
// {
//   "value": "Staedtler®",
//   "label": "Staedtler®"
// },
// {
//   "value": "Stampendous®",
//   "label": "Stampendous®"
// },
// {
//   "value": "Starbucks®",
//   "label": "Starbucks®"
// },
// {
//   "value": "Stars N Stripes",
//   "label": "Stars N Stripes"
// },
// {
//   "value": "Sterilite®",
//   "label": "Sterilite®"
// },
// {
//   "value": "Stir™",
//   "label": "Stir™"
// },
// {
//   "value": "Stir™ & Melt'Ems",
//   "label": "Stir™ & Melt'Ems"
// },
// {
//   "value": "Stir™ & Wilton®",
//   "label": "Stir™ & Wilton®"
// },
// {
//   "value": "Stitch Nation",
//   "label": "Stitch Nation"
// },
// {
//   "value": "Strathmore®",
//   "label": "Strathmore®"
// },
// {
//   "value": "Styrofoam™",
//   "label": "Styrofoam™"
// },
// {
//   "value": "Suedesays™",
//   "label": "Suedesays™"
// },
// {
//   "value": "Sulky®",
//   "label": "Sulky®"
// },
// {
//   "value": "Sullivans",
//   "label": "Sullivans"
// },
// {
//   "value": "Sulyn",
//   "label": "Sulyn"
// },
// {
//   "value": "Sunbrella®",
//   "label": "Sunbrella®"
// },
// {
//   "value": "Surebonder®",
//   "label": "Surebonder®"
// },
// {
//   "value": "Susan Winget",
//   "label": "Susan Winget"
// },
// {
//   "value": "Swarovski Elements",
//   "label": "Swarovski Elements"
// },
// {
//   "value": "Sweet Creations By Good Cook™",
//   "label": "Sweet Creations By Good Cook™"
// },
// {
//   "value": "Sweet Sugarbelle",
//   "label": "Sweet Sugarbelle"
// },
// {
//   "value": "Sweetshop™",
//   "label": "Sweetshop™"
// },
// {
//   "value": "Sweetworks®",
//   "label": "Sweetworks®"
// },
// {
//   "value": "Symphony",
//   "label": "Symphony"
// },
// {
//   "value": "T.S Shure",
//   "label": "T.S Shure"
// },
// {
//   "value": "Tails & Noses®",
//   "label": "Tails & Noses®"
// },
// {
//   "value": "Talens Art Creation",
//   "label": "Talens Art Creation"
// },
// {
//   "value": "Tango®",
//   "label": "Tango®"
// },
// {
//   "value": "Taylor Seville®",
//   "label": "Taylor Seville®"
// },
// {
//   "value": "Teresa Collins™",
//   "label": "Teresa Collins™"
// },
// {
//   "value": "The Big Bolt™",
//   "label": "The Big Bolt™"
// },
// {
//   "value": "The Board Dudes®",
//   "label": "The Board Dudes®"
// },
// {
//   "value": "The Cookie Exchange®",
//   "label": "The Cookie Exchange®"
// },
// {
//   "value": "The Grace Company",
//   "label": "The Grace Company"
// },
// {
//   "value": "The Paper Company",
//   "label": "The Paper Company"
// },
// {
//   "value": "The Warm® Company",
//   "label": "The Warm® Company"
// },
// {
//   "value": "Therm-o-web®",
//   "label": "Therm-o-web®"
// },
// {
//   "value": "Thomasville®",
//   "label": "Thomasville®"
// },
// {
//   "value": "Thread Magic™",
//   "label": "Thread Magic™"
// },
// {
//   "value": "Tim Holtz",
//   "label": "Tim Holtz"
// },
// {
//   "value": "Timeless",
//   "label": "Timeless"
// },
// {
//   "value": "TLC®",
//   "label": "TLC®"
// },
// {
//   "value": "Tonic®",
//   "label": "Tonic®"
// },
// {
//   "value": "Top Notch™",
//   "label": "Top Notch™"
// },
// {
//   "value": "Totally Tiffany",
//   "label": "Totally Tiffany"
// },
// {
//   "value": "True Colors",
//   "label": "True Colors"
// },
// {
//   "value": "Tulip®",
//   "label": "Tulip®"
// },
// {
//   "value": "Tuscany",
//   "label": "Tuscany"
// },
// {
//   "value": "Twilight",
//   "label": "Twilight"
// },
// {
//   "value": "TY®",
//   "label": "TY®"
// },
// {
//   "value": "Uchida®",
//   "label": "Uchida®"
// },
// {
//   "value": "Ultimate Sweater Machine™",
//   "label": "Ultimate Sweater Machine™"
// },
// {
//   "value": "Ultrasuede®",
//   "label": "Ultrasuede®"
// },
// {
//   "value": "Unique Stitch®",
//   "label": "Unique Stitch®"
// },
// {
//   "value": "Van Gogh",
//   "label": "Van Gogh"
// },
// {
//   "value": "Velcro®",
//   "label": "Velcro®"
// },
// {
//   "value": "Verilux®",
//   "label": "Verilux®"
// },
// {
//   "value": "Vintaj",
//   "label": "Vintaj"
// },
// {
//   "value": "Visa®",
//   "label": "Visa®"
// },
// {
//   "value": "Vogue®",
//   "label": "Vogue®"
// },
// {
//   "value": "Walnut Hollow®",
//   "label": "Walnut Hollow®"
// },
// {
//   "value": "Warm & Natural®",
//   "label": "Warm & Natural®"
// },
// {
//   "value": "Warm & White®",
//   "label": "Warm & White®"
// },
// {
//   "value": "Warm&Natural® And Warm&White®",
//   "label": "Warm&Natural® And Warm&White®"
// },
// {
//   "value": "Waverly®",
//   "label": "Waverly®"
// },
// {
//   "value": "We R Memory Keepers™",
//   "label": "We R Memory Keepers™"
// },
// {
//   "value": "Westex International",
//   "label": "Westex International"
// },
// {
//   "value": "Westri®",
//   "label": "Westri®"
// },
// {
//   "value": "Wild Wire™",
//   "label": "Wild Wire™"
// },
// {
//   "value": "Wilton®",
//   "label": "Wilton®"
// },
// {
//   "value": "Wilton® & Satin Ice®",
//   "label": "Wilton® & Satin Ice®"
// },
// {
//   "value": "Winsor & Newton™",
//   "label": "Winsor & Newton™"
// },
// {
//   "value": "Wintuk®",
//   "label": "Wintuk®"
// },
// {
//   "value": "Wonder Under®",
//   "label": "Wonder Under®"
// },
// {
//   "value": "Wonderfil®",
//   "label": "Wonderfil®"
// },
// {
//   "value": "Wonderform®",
//   "label": "Wonderform®"
// },
// {
//   "value": "Woodline Works Corp",
//   "label": "Woodline Works Corp"
// },
// {
//   "value": "Woodsies™",
//   "label": "Woodsies™"
// },
// {
//   "value": "Wool and the Gang",
//   "label": "Wool and the Gang"
// },
// {
//   "value": "Works of Ahhh...®",
//   "label": "Works of Ahhh...®"
// },
// {
//   "value": "Wrights®",
//   "label": "Wrights®"
// },
// {
//   "value": "X-Acto®",
//   "label": "X-Acto®"
// },
// {
//   "value": "Xyron®",
//   "label": "Xyron®"
// },
// {
//   "value": "Yankee Candle®",
//   "label": "Yankee Candle®"
// },
// {
//   "value": "Yarntainer™",
//   "label": "Yarntainer™"
// },
// {
//   "value": "Yasutomo®",
//   "label": "Yasutomo®"
// },
// {
//   "value": "Yudu™",
//   "label": "Yudu™"
// },
// {
//   "value": "Zig®",
//   "label": "Zig®"
// },
// {
//   "value": "Zink",
//   "label": "Zink"
// },
// {
//   "value": "Zots™",
//   "label": "Zots™"
// },
// {
//   "value": "ZURU",
//   "label": "ZURU"
// },
// {
//   "value": "Red,White& Bold",
//   "label": "Red,White& Bold"
// }
// ]

const ADGROUP_LABELS = [
   {
       "value": "\"Bee Nice\"",
       "label": "\"Bee Nice\""
   },
   {
       "value": "3M® Scotch Brite®",
       "label": "3M® Scotch Brite®"
   },
   {
       "value": "Accuquilt®",
       "label": "Accuquilt®"
   },
   {
       "value": "Activa",
       "label": "Activa"
   },
   {
       "value": "Adhesive Tech™",
       "label": "Adhesive Tech™"
   },
   {
       "value": "Advantus",
       "label": "Advantus"
   },
   {
       "value": "Airtex®",
       "label": "Airtex®"
   },
   {
       "value": "Aleene's®",
       "label": "Aleene's®"
   },
   {
       "value": "Alex",
       "label": "Alex"
   },
   {
       "value": "Alliance",
       "label": "Alliance"
   },
   {
       "value": "Amav®",
       "label": "Amav®"
   },
   {
       "value": "American Craft",
       "label": "American Craft"
   },
   {
       "value": "American Girl®",
       "label": "American Girl®"
   },
   {
       "value": "American Greetings",
       "label": "American Greetings"
   },
   {
       "value": "American Tombow",
       "label": "American Tombow"
   },
   {
       "value": "Americana",
       "label": "Americana"
   },
   {
       "value": "Americana®",
       "label": "Americana®"
   },
   {
       "value": "Anvil® by Gildan®",
       "label": "Anvil® by Gildan®"
   },
   {
       "value": "Apple Barrel®",
       "label": "Apple Barrel®"
   },
   {
       "value": "Arrow",
       "label": "Arrow"
   },
   {
       "value": "Art Of Autumn™",
       "label": "Art Of Autumn™"
   },
   {
       "value": "Artbin®",
       "label": "Artbin®"
   },
   {
       "value": "Artist Brands",
       "label": "Artist Brands"
   },
   {
       "value": "Artskills",
       "label": "Artskills"
   },
   {
       "value": "Artsmart Kids™",
       "label": "Artsmart Kids™"
   },
   {
       "value": "ARTSMITH",
       "label": "ARTSMITH"
   },
   {
       "value": "Artsmith™",
       "label": "Artsmith™"
   },
   {
       "value": "As Seen On TV",
       "label": "As Seen On TV"
   },
   {
       "value": "Autumn",
       "label": "Autumn"
   },
   {
       "value": "Autumn &Halloween",
       "label": "Autumn &Halloween"
   },
   {
       "value": "avaria",
       "label": "avaria"
   },
   {
       "value": "Avaria",
       "label": "Avaria"
   },
   {
       "value": "Babyville Boutique™",
       "label": "Babyville Boutique™"
   },
   {
       "value": "Back To Campus™",
       "label": "Back To Campus™"
   },
   {
       "value": "Balene™",
       "label": "Balene™"
   },
   {
       "value": "Ball®",
       "label": "Ball®"
   },
   {
       "value": "Beach Babe",
       "label": "Beach Babe"
   },
   {
       "value": "Beacon",
       "label": "Beacon"
   },
   {
       "value": "Beadalon®",
       "label": "Beadalon®"
   },
   {
       "value": "Beaders Paradise",
       "label": "Beaders Paradise"
   },
   {
       "value": "Bear Works®",
       "label": "Bear Works®"
   },
   {
       "value": "Becky Higgins®",
       "label": "Becky Higgins®"
   },
   {
       "value": "Bedazzler™",
       "label": "Bedazzler™"
   },
   {
       "value": "Belle",
       "label": "Belle"
   },
   {
       "value": "Bendon™",
       "label": "Bendon™"
   },
   {
       "value": "Bernat®",
       "label": "Bernat®"
   },
   {
       "value": "Bernina of America",
       "label": "Bernina of America"
   },
   {
       "value": "Better Homes And Gardens®",
       "label": "Better Homes And Gardens®"
   },
   {
       "value": "Big Twist™",
       "label": "Big Twist™"
   },
   {
       "value": "Binney & Smith®",
       "label": "Binney & Smith®"
   },
   {
       "value": "Bliss",
       "label": "Bliss"
   },
   {
       "value": "Bloom Room & Place & Time",
       "label": "Bloom Room & Place & Time"
   },
   {
       "value": "Bloom Room Artisan™",
       "label": "Bloom Room Artisan™"
   },
   {
       "value": "Bloom Room Littles™",
       "label": "Bloom Room Littles™"
   },
   {
       "value": "Bloom Room®",
       "label": "Bloom Room®"
   },
   {
       "value": "Blooming Autumn™",
       "label": "Blooming Autumn™"
   },
   {
       "value": "Blooming Holiday™",
       "label": "Blooming Holiday™"
   },
   {
       "value": "Blooming Holiday™Littles",
       "label": "Blooming Holiday™Littles"
   },
   {
       "value": "Blooming Spring™",
       "label": "Blooming Spring™"
   },
   {
       "value": "Blue Mood",
       "label": "Blue Mood"
   },
   {
       "value": "Blue Moon®",
       "label": "Blue Moon®"
   },
   {
       "value": "Blumenthal",
       "label": "Blumenthal"
   },
   {
       "value": "Bob Ross®",
       "label": "Bob Ross®"
   },
   {
       "value": "Boye®",
       "label": "Boye®"
   },
   {
       "value": "Brewster",
       "label": "Brewster"
   },
   {
       "value": "Bridal Inspirations™",
       "label": "Bridal Inspirations™"
   },
   {
       "value": "Bright Ideas",
       "label": "Bright Ideas"
   },
   {
       "value": "Bright Ideas & Bloom Room",
       "label": "Bright Ideas & Bloom Room"
   },
   {
       "value": "Brother International",
       "label": "Brother International"
   },
   {
       "value": "Bucilla®",
       "label": "Bucilla®"
   },
   {
       "value": "Buffalo Batting",
       "label": "Buffalo Batting"
   },
   {
       "value": "Burda®",
       "label": "Burda®"
   },
   {
       "value": "Burnes",
       "label": "Burnes"
   },
   {
       "value": "Busykids™",
       "label": "Busykids™"
   },
   {
       "value": "Buttercream™",
       "label": "Buttercream™"
   },
   {
       "value": "Buttercre™Luxe Craft",
       "label": "Buttercre™Luxe Craft"
   },
   {
       "value": "Butterick®",
       "label": "Butterick®"
   },
   {
       "value": "Buttoneer®",
       "label": "Buttoneer®"
   },
   {
       "value": "Buzzy®",
       "label": "Buzzy®"
   },
   {
       "value": "Cactus",
       "label": "Cactus"
   },
   {
       "value": "Cake Craft™",
       "label": "Cake Craft™"
   },
   {
       "value": "Camo Couture™",
       "label": "Camo Couture™"
   },
   {
       "value": "Camp Ann",
       "label": "Camp Ann"
   },
   {
       "value": "Canon®",
       "label": "Canon®"
   },
   {
       "value": "Canson®",
       "label": "Canson®"
   },
   {
       "value": "Canson® & Strathmore®",
       "label": "Canson® & Strathmore®"
   },
   {
       "value": "Canvas Keepsakes™",
       "label": "Canvas Keepsakes™"
   },
   {
       "value": "Carbona®",
       "label": "Carbona®"
   },
   {
       "value": "Carolina®",
       "label": "Carolina®"
   },
   {
       "value": "Caron®",
       "label": "Caron®"
   },
   {
       "value": "Casa Collection™",
       "label": "Casa Collection™"
   },
   {
       "value": "Ceramcoat",
       "label": "Ceramcoat"
   },
   {
       "value": "Charmed Life",
       "label": "Charmed Life"
   },
   {
       "value": "Cheer & Co.™",
       "label": "Cheer & Co.™"
   },
   {
       "value": "Chicago Metallic™",
       "label": "Chicago Metallic™"
   },
   {
       "value": "Christopher Lowell Collection®",
       "label": "Christopher Lowell Collection®"
   },
   {
       "value": "Cleckheaton",
       "label": "Cleckheaton"
   },
   {
       "value": "Clip Eez",
       "label": "Clip Eez"
   },
   {
       "value": "Cloud 9",
       "label": "Cloud 9"
   },
   {
       "value": "Clover",
       "label": "Clover"
   },
   {
       "value": "Clover® Mini Iron™",
       "label": "Clover® Mini Iron™"
   },
   {
       "value": "Coastal",
       "label": "Coastal"
   },
   {
       "value": "Coats & Clark®",
       "label": "Coats & Clark®"
   },
   {
       "value": "Coca-Cola®",
       "label": "Coca-Cola®"
   },
   {
       "value": "Colart",
       "label": "Colart"
   },
   {
       "value": "Color Pour",
       "label": "Color Pour"
   },
   {
       "value": "Color Pour Resin",
       "label": "Color Pour Resin"
   },
   {
       "value": "Colorbok®",
       "label": "Colorbok®"
   },
   {
       "value": "Comfort Colors®",
       "label": "Comfort Colors®"
   },
   {
       "value": "Conair",
       "label": "Conair"
   },
   {
       "value": "Contact Brand",
       "label": "Contact Brand"
   },
   {
       "value": "Copco®",
       "label": "Copco®"
   },
   {
       "value": "Core'dinations®",
       "label": "Core'dinations®"
   },
   {
       "value": "Cosmo Girl!®",
       "label": "Cosmo Girl!®"
   },
   {
       "value": "Cousin®",
       "label": "Cousin®"
   },
   {
       "value": "Cra-Z-Art®",
       "label": "Cra-Z-Art®"
   },
   {
       "value": "Craft & Scrap Essentials™",
       "label": "Craft & Scrap Essentials™"
   },
   {
       "value": "Craft Essentials™",
       "label": "Craft Essentials™"
   },
   {
       "value": "Craft-N-Carry™",
       "label": "Craft-N-Carry™"
   },
   {
       "value": "Crafted Living",
       "label": "Crafted Living"
   },
   {
       "value": "Crafter's Choice™",
       "label": "Crafter's Choice™"
   },
   {
       "value": "Craftologie®",
       "label": "Craftologie®"
   },
   {
       "value": "Crafty Productictions",
       "label": "Crafty Productictions"
   },
   {
       "value": "Crayola®",
       "label": "Crayola®"
   },
   {
       "value": "Creative Couture",
       "label": "Creative Couture"
   },
   {
       "value": "Creative Girl ByJulieComstock™",
       "label": "Creative Girl ByJulieComstock™"
   },
   {
       "value": "Creative U",
       "label": "Creative U"
   },
   {
       "value": "Creativity For Kids®",
       "label": "Creativity For Kids®"
   },
   {
       "value": "Creativity Kits™",
       "label": "Creativity Kits™"
   },
   {
       "value": "Cricut Expression® 2",
       "label": "Cricut Expression® 2"
   },
   {
       "value": "Cricut®",
       "label": "Cricut®"
   },
   {
       "value": "Crop In Style®",
       "label": "Crop In Style®"
   },
   {
       "value": "Crop-A-Dile™",
       "label": "Crop-A-Dile™"
   },
   {
       "value": "Cropper Hopper™",
       "label": "Cropper Hopper™"
   },
   {
       "value": "Croscill®",
       "label": "Croscill®"
   },
   {
       "value": "Crypton®",
       "label": "Crypton®"
   },
   {
       "value": "Cuddly Socks™",
       "label": "Cuddly Socks™"
   },
   {
       "value": "Cupcake Creations™",
       "label": "Cupcake Creations™"
   },
   {
       "value": "Curiosity Kits®",
       "label": "Curiosity Kits®"
   },
   {
       "value": "Currier & Ives®",
       "label": "Currier & Ives®"
   },
   {
       "value": "Curve Decor™",
       "label": "Curve Decor™"
   },
   {
       "value": "Custom Framing",
       "label": "Custom Framing"
   },
   {
       "value": "Custom Moulding",
       "label": "Custom Moulding"
   },
   {
       "value": "Cuttlebug™",
       "label": "Cuttlebug™"
   },
   {
       "value": "Darice®",
       "label": "Darice®"
   },
   {
       "value": "Dark Matter",
       "label": "Dark Matter"
   },
   {
       "value": "David Tutera™",
       "label": "David Tutera™"
   },
   {
       "value": "Daylight",
       "label": "Daylight"
   },
   {
       "value": "DCWV®",
       "label": "DCWV®"
   },
   {
       "value": "Debbie Mumm®",
       "label": "Debbie Mumm®"
   },
   {
       "value": "Deborah Norville Collection™",
       "label": "Deborah Norville Collection™"
   },
   {
       "value": "Decoart®",
       "label": "Decoart®"
   },
   {
       "value": "Decorator's Choice®",
       "label": "Decorator's Choice®"
   },
   {
       "value": "Decorware Inc",
       "label": "Decorware Inc"
   },
   {
       "value": "Delta",
       "label": "Delta"
   },
   {
       "value": "Desert Muse",
       "label": "Desert Muse"
   },
   {
       "value": "Design Studio",
       "label": "Design Studio"
   },
   {
       "value": "Design Works™",
       "label": "Design Works™"
   },
   {
       "value": "Designer Vault™",
       "label": "Designer Vault™"
   },
   {
       "value": "Deval",
       "label": "Deval"
   },
   {
       "value": "Diamond Dotz®",
       "label": "Diamond Dotz®"
   },
   {
       "value": "Dimensions",
       "label": "Dimensions"
   },
   {
       "value": "Discovery™",
       "label": "Discovery™"
   },
   {
       "value": "Disney®",
       "label": "Disney®"
   },
   {
       "value": "Disney® Pixar",
       "label": "Disney® Pixar"
   },
   {
       "value": "Diva Kit",
       "label": "Diva Kit"
   },
   {
       "value": "DMC®",
       "label": "DMC®"
   },
   {
       "value": "Donna Dewberry™",
       "label": "Donna Dewberry™"
   },
   {
       "value": "Doodle-Loom®",
       "label": "Doodle-Loom®"
   },
   {
       "value": "dreamhappy™",
       "label": "dreamhappy™"
   },
   {
       "value": "Dremel",
       "label": "Dremel"
   },
   {
       "value": "Dress It Up®",
       "label": "Dress It Up®"
   },
   {
       "value": "Dritz®",
       "label": "Dritz®"
   },
   {
       "value": "Duck® Brand",
       "label": "Duck® Brand"
   },
   {
       "value": "Duncan",
       "label": "Duncan"
   },
   {
       "value": "Dunecraft®",
       "label": "Dunecraft®"
   },
   {
       "value": "Duro®",
       "label": "Duro®"
   },
   {
       "value": "Dust Off®",
       "label": "Dust Off®"
   },
   {
       "value": "Dutch Boy®",
       "label": "Dutch Boy®"
   },
   {
       "value": "DW Home",
       "label": "DW Home"
   },
   {
       "value": "Dylite®",
       "label": "Dylite®"
   },
   {
       "value": "Dylon®",
       "label": "Dylon®"
   },
   {
       "value": "Easter",
       "label": "Easter"
   },
   {
       "value": "Easter Craft",
       "label": "Easter Craft"
   },
   {
       "value": "Easter Littles™",
       "label": "Easter Littles™"
   },
   {
       "value": "Easy Stitch 'n Save®",
       "label": "Easy Stitch 'n Save®"
   },
   {
       "value": "Eberhard Faber®",
       "label": "Eberhard Faber®"
   },
   {
       "value": "Eclipse",
       "label": "Eclipse"
   },
   {
       "value": "Ecocraft™",
       "label": "Ecocraft™"
   },
   {
       "value": "Eddie Bauer®",
       "label": "Eddie Bauer®"
   },
   {
       "value": "Eddie Bauer® & Christopher",
       "label": "Eddie Bauer® & Christopher"
   },
   {
       "value": "EK Success®",
       "label": "EK Success®"
   },
   {
       "value": "Element Essense",
       "label": "Element Essense"
   },
   {
       "value": "Elmer's®",
       "label": "Elmer's®"
   },
   {
       "value": "Entire Stock",
       "label": "Entire Stock"
   },
   {
       "value": "Escape To Paradise™",
       "label": "Escape To Paradise™"
   },
   {
       "value": "Escape To Paradise™/Americana™",
       "label": "Escape To Paradise™/Americana™"
   },
   {
       "value": "Evening Inspirations",
       "label": "Evening Inspirations"
   },
   {
       "value": "Everything Mary",
       "label": "Everything Mary"
   },
   {
       "value": "Expressive Design Group",
       "label": "Expressive Design Group"
   },
   {
       "value": "Fab Lab™",
       "label": "Fab Lab™"
   },
   {
       "value": "Faber Castell",
       "label": "Faber Castell"
   },
   {
       "value": "Fabri-Tac®",
       "label": "Fabri-Tac®"
   },
   {
       "value": "Fabricmate™",
       "label": "Fabricmate™"
   },
   {
       "value": "Fabricraft™",
       "label": "Fabricraft™"
   },
   {
       "value": "Fairfield®",
       "label": "Fairfield®"
   },
   {
       "value": "Fall For All",
       "label": "Fall For All"
   },
   {
       "value": "Fall For All/Malers Halloween",
       "label": "Fall For All/Malers Halloween"
   },
   {
       "value": "Fall Into Color Littles™",
       "label": "Fall Into Color Littles™"
   },
   {
       "value": "Fall Into Color™",
       "label": "Fall Into Color™"
   },
   {
       "value": "Fall Into Color™ Scarecrows",
       "label": "Fall Into Color™ Scarecrows"
   },
   {
       "value": "Fashion & Fluff™",
       "label": "Fashion & Fluff™"
   },
   {
       "value": "Favorite Findings",
       "label": "Favorite Findings"
   },
   {
       "value": "Fetco®",
       "label": "Fetco®"
   },
   {
       "value": "Fibercraft International®",
       "label": "Fibercraft International®"
   },
   {
       "value": "Fieldcrest®",
       "label": "Fieldcrest®"
   },
   {
       "value": "Fimo®",
       "label": "Fimo®"
   },
   {
       "value": "Fine Art Markers",
       "label": "Fine Art Markers"
   },
   {
       "value": "FINE ART PAPER PADS",
       "label": "FINE ART PAPER PADS"
   },
   {
       "value": "Fiskars®",
       "label": "Fiskars®"
   },
   {
       "value": "Fleeces Pieces®",
       "label": "Fleeces Pieces®"
   },
   {
       "value": "Flings",
       "label": "Flings"
   },
   {
       "value": "Floracraft®",
       "label": "Floracraft®"
   },
   {
       "value": "Floral Inspirations™",
       "label": "Floral Inspirations™"
   },
   {
       "value": "Florasens™",
       "label": "Florasens™"
   },
   {
       "value": "Foamies®",
       "label": "Foamies®"
   },
   {
       "value": "Folk Art®",
       "label": "Folk Art®"
   },
   {
       "value": "Fons & Porter™",
       "label": "Fons & Porter™"
   },
   {
       "value": "Foodcrafting Inspirations™",
       "label": "Foodcrafting Inspirations™"
   },
   {
       "value": "Forcefield",
       "label": "Forcefield"
   },
   {
       "value": "Fourstar",
       "label": "Fourstar"
   },
   {
       "value": "Fray Check™",
       "label": "Fray Check™"
   },
   {
       "value": "Fresh Picked Greens™",
       "label": "Fresh Picked Greens™"
   },
   {
       "value": "Fresh Picked Spring™",
       "label": "Fresh Picked Spring™"
   },
   {
       "value": "Fresh Picked Summer™",
       "label": "Fresh Picked Summer™"
   },
   {
       "value": "Fujifilm® Instax™",
       "label": "Fujifilm® Instax™"
   },
   {
       "value": "Full Bloom",
       "label": "Full Bloom"
   },
   {
       "value": "Funkins™",
       "label": "Funkins™"
   },
   {
       "value": "Furryart™",
       "label": "Furryart™"
   },
   {
       "value": "Fuzzy®",
       "label": "Fuzzy®"
   },
   {
       "value": "Gallery J®",
       "label": "Gallery J®"
   },
   {
       "value": "Ganz",
       "label": "Ganz"
   },
   {
       "value": "Gelly Roll®",
       "label": "Gelly Roll®"
   },
   {
       "value": "Gerber®",
       "label": "Gerber®"
   },
   {
       "value": "Get A Grip",
       "label": "Get A Grip"
   },
   {
       "value": "GHI",
       "label": "GHI"
   },
   {
       "value": "Ghirardelli",
       "label": "Ghirardelli"
   },
   {
       "value": "Gick®",
       "label": "Gick®"
   },
   {
       "value": "Gildan®",
       "label": "Gildan®"
   },
   {
       "value": "Gilded Glam",
       "label": "Gilded Glam"
   },
   {
       "value": "Gingher®",
       "label": "Gingher®"
   },
   {
       "value": "Glassmates",
       "label": "Glassmates"
   },
   {
       "value": "Glitterbug™",
       "label": "Glitterbug™"
   },
   {
       "value": "Golden®",
       "label": "Golden®"
   },
   {
       "value": "Goo Gone®",
       "label": "Goo Gone®"
   },
   {
       "value": "Graber®",
       "label": "Graber®"
   },
   {
       "value": "Gutermann®",
       "label": "Gutermann®"
   },
   {
       "value": "Gypsy™",
       "label": "Gypsy™"
   },
   {
       "value": "Halcraft®",
       "label": "Halcraft®"
   },
   {
       "value": "Halloween",
       "label": "Halloween"
   },
   {
       "value": "Hamilton Beach®",
       "label": "Hamilton Beach®"
   },
   {
       "value": "Hampton Home Decor",
       "label": "Hampton Home Decor"
   },
   {
       "value": "Handmade Halloween",
       "label": "Handmade Halloween"
   },
   {
       "value": "Handmade Holiday",
       "label": "Handmade Holiday"
   },
   {
       "value": "Hannah Montana",
       "label": "Hannah Montana"
   },
   {
       "value": "Happy",
       "label": "Happy"
   },
   {
       "value": "Harley Davidson®",
       "label": "Harley Davidson®"
   },
   {
       "value": "Harry Potter™",
       "label": "Harry Potter™"
   },
   {
       "value": "Hazel & Ruby®",
       "label": "Hazel & Ruby®"
   },
   {
       "value": "Heat'n'Bond®",
       "label": "Heat'n'Bond®"
   },
   {
       "value": "Heidi Grace Designs",
       "label": "Heidi Grace Designs"
   },
   {
       "value": "Heidi Swapp™",
       "label": "Heidi Swapp™"
   },
   {
       "value": "Heirloom",
       "label": "Heirloom"
   },
   {
       "value": "Hello Kitty",
       "label": "Hello Kitty"
   },
   {
       "value": "Hello Lovely",
       "label": "Hello Lovely"
   },
   {
       "value": "Hello Spring Decor™",
       "label": "Hello Spring Decor™"
   },
   {
       "value": "Hello Spring Floral™",
       "label": "Hello Spring Floral™"
   },
   {
       "value": "Hello Spring Gardening™",
       "label": "Hello Spring Gardening™"
   },
   {
       "value": "Hello Spring™",
       "label": "Hello Spring™"
   },
   {
       "value": "Hello Spring™ Crafted Garden",
       "label": "Hello Spring™ Crafted Garden"
   },
   {
       "value": "Hello Spring™ Crafted Living",
       "label": "Hello Spring™ Crafted Living"
   },
   {
       "value": "Hello Summer™",
       "label": "Hello Summer™"
   },
   {
       "value": "Hero Arts",
       "label": "Hero Arts"
   },
   {
       "value": "Hexabits™",
       "label": "Hexabits™"
   },
   {
       "value": "Hey Doll",
       "label": "Hey Doll"
   },
   {
       "value": "HGTV Home",
       "label": "HGTV Home"
   },
   {
       "value": "Hi Sig Series & Levolor",
       "label": "Hi Sig Series & Levolor"
   },
   {
       "value": "Highschool Musical",
       "label": "Highschool Musical"
   },
   {
       "value": "hildie & jo®",
       "label": "hildie & jo®"
   },
   {
       "value": "Hip In A Hurry™",
       "label": "Hip In A Hurry™"
   },
   {
       "value": "Hip In A Hurry™ DCWV™",
       "label": "Hip In A Hurry™ DCWV™"
   },
   {
       "value": "Hirschberg",
       "label": "Hirschberg"
   },
   {
       "value": "Holiday",
       "label": "Holiday"
   },
   {
       "value": "Holiday & Autumn",
       "label": "Holiday & Autumn"
   },
   {
       "value": "Holiday & Floral Inspirations",
       "label": "Holiday & Floral Inspirations"
   },
   {
       "value": "Holiday & Home Inspirations",
       "label": "Holiday & Home Inspirations"
   },
   {
       "value": "Holiday Cheer",
       "label": "Holiday Cheer"
   },
   {
       "value": "Holiday Cheer/Susan Winget",
       "label": "Holiday Cheer/Susan Winget"
   },
   {
       "value": "HolidayCheer/SparklingWoodland",
       "label": "HolidayCheer/SparklingWoodland"
   },
   {
       "value": "Hollywood Fashion Secrets®",
       "label": "Hollywood Fashion Secrets®"
   },
   {
       "value": "Holmes®",
       "label": "Holmes®"
   },
   {
       "value": "Home Elegance",
       "label": "Home Elegance"
   },
   {
       "value": "Home Essentials™",
       "label": "Home Essentials™"
   },
   {
       "value": "Home Inspirations&Candle-lite",
       "label": "Home Inspirations&Candle-lite"
   },
   {
       "value": "Home Inspirations™",
       "label": "Home Inspirations™"
   },
   {
       "value": "Home Inspirations™ & KC™",
       "label": "Home Inspirations™ & KC™"
   },
   {
       "value": "Homedics",
       "label": "Homedics"
   },
   {
       "value": "Honeyware",
       "label": "Honeyware"
   },
   {
       "value": "Hotfix™",
       "label": "Hotfix™"
   },
   {
       "value": "Hudson 43®",
       "label": "Hudson 43®"
   },
   {
       "value": "Hudson 43™ and Place & Time",
       "label": "Hudson 43™ and Place & Time"
   },
   {
       "value": "Hudson 43™& Haven Street",
       "label": "Hudson 43™& Haven Street"
   },
   {
       "value": "Husqvarna Viking® Jade 20™",
       "label": "Husqvarna Viking® Jade 20™"
   },
   {
       "value": "Husqvarna® Viking®",
       "label": "Husqvarna® Viking®"
   },
   {
       "value": "ICA",
       "label": "ICA"
   },
   {
       "value": "Illinois Bronze®",
       "label": "Illinois Bronze®"
   },
   {
       "value": "Illooms®",
       "label": "Illooms®"
   },
   {
       "value": "Image Hill®",
       "label": "Image Hill®"
   },
   {
       "value": "ImpressArt®",
       "label": "ImpressArt®"
   },
   {
       "value": "In The Garden™",
       "label": "In The Garden™"
   },
   {
       "value": "Indigo Mist",
       "label": "Indigo Mist"
   },
   {
       "value": "Inglow®",
       "label": "Inglow®"
   },
   {
       "value": "Inkadinkado®",
       "label": "Inkadinkado®"
   },
   {
       "value": "Insul-Bright™",
       "label": "Insul-Bright™"
   },
   {
       "value": "Intercraft®",
       "label": "Intercraft®"
   },
   {
       "value": "Interior Accents®",
       "label": "Interior Accents®"
   },
   {
       "value": "iPod®",
       "label": "iPod®"
   },
   {
       "value": "Janlynn",
       "label": "Janlynn"
   },
   {
       "value": "Janlynn & Wrights",
       "label": "Janlynn & Wrights"
   },
   {
       "value": "Janome America Inc",
       "label": "Janome America Inc"
   },
   {
       "value": "Jelly Belly®",
       "label": "Jelly Belly®"
   },
   {
       "value": "Jesse James",
       "label": "Jesse James"
   },
   {
       "value": "Jessware",
       "label": "Jessware"
   },
   {
       "value": "JHB International®",
       "label": "JHB International®"
   },
   {
       "value": "Jo-Ann Idea™",
       "label": "Jo-Ann Idea™"
   },
   {
       "value": "JOANN",
       "label": "JOANN"
   },
   {
       "value": "John Bead",
       "label": "John Bead"
   },
   {
       "value": "John Deere™",
       "label": "John Deere™"
   },
   {
       "value": "Jolee's®",
       "label": "Jolee's®"
   },
   {
       "value": "Juicy Couture®",
       "label": "Juicy Couture®"
   },
   {
       "value": "Juki America Inc",
       "label": "Juki America Inc"
   },
   {
       "value": "June Tailor®",
       "label": "June Tailor®"
   },
   {
       "value": "K + C™",
       "label": "K + C™"
   },
   {
       "value": "K&Company",
       "label": "K&Company"
   },
   {
       "value": "Kaleidoscope",
       "label": "Kaleidoscope"
   },
   {
       "value": "Keep Box™",
       "label": "Keep Box™"
   },
   {
       "value": "Keepsake Calico",
       "label": "Keepsake Calico"
   },
   {
       "value": "Keepsake Garden Collection",
       "label": "Keepsake Garden Collection"
   },
   {
       "value": "Kelly Creates",
       "label": "Kelly Creates"
   },
   {
       "value": "Kid Concoctions™",
       "label": "Kid Concoctions™"
   },
   {
       "value": "Kids Camp",
       "label": "Kids Camp"
   },
   {
       "value": "Kids Kits®",
       "label": "Kids Kits®"
   },
   {
       "value": "Kidzors™",
       "label": "Kidzors™"
   },
   {
       "value": "Kirsch®",
       "label": "Kirsch®"
   },
   {
       "value": "Kitchen Aid®",
       "label": "Kitchen Aid®"
   },
   {
       "value": "KI®",
       "label": "KI®"
   },
   {
       "value": "Klutz®",
       "label": "Klutz®"
   },
   {
       "value": "Knifty Knitter®",
       "label": "Knifty Knitter®"
   },
   {
       "value": "Knit & Crochet™",
       "label": "Knit & Crochet™"
   },
   {
       "value": "Knit Collage",
       "label": "Knit Collage"
   },
   {
       "value": "Knit-Wit®",
       "label": "Knit-Wit®"
   },
   {
       "value": "KONA®",
       "label": "KONA®"
   },
   {
       "value": "Krazy Glue",
       "label": "Krazy Glue"
   },
   {
       "value": "Krylon®",
       "label": "Krylon®"
   },
   {
       "value": "Kunin®",
       "label": "Kunin®"
   },
   {
       "value": "Kwik Sew®",
       "label": "Kwik Sew®"
   },
   {
       "value": "LA Collection",
       "label": "LA Collection"
   },
   {
       "value": "LA Mode®",
       "label": "LA Mode®"
   },
   {
       "value": "Laliberi™",
       "label": "Laliberi™"
   },
   {
       "value": "Land Of The Free",
       "label": "Land Of The Free"
   },
   {
       "value": "Latique",
       "label": "Latique"
   },
   {
       "value": "LAURdiy",
       "label": "LAURdiy"
   },
   {
       "value": "Legacy Studio™",
       "label": "Legacy Studio™"
   },
   {
       "value": "Legacy Studio™Indonesian&Quilt",
       "label": "Legacy Studio™Indonesian&Quilt"
   },
   {
       "value": "LEGO®",
       "label": "LEGO®"
   },
   {
       "value": "Levolor®",
       "label": "Levolor®"
   },
   {
       "value": "Lia Griffith",
       "label": "Lia Griffith"
   },
   {
       "value": "Libbey®",
       "label": "Libbey®"
   },
   {
       "value": "Liberty London™",
       "label": "Liberty London™"
   },
   {
       "value": "Lifestyle Craft",
       "label": "Lifestyle Craft"
   },
   {
       "value": "Lil Teammates®",
       "label": "Lil Teammates®"
   },
   {
       "value": "Lil' Pop!™",
       "label": "Lil' Pop!™"
   },
   {
       "value": "Lily's®",
       "label": "Lily's®"
   },
   {
       "value": "Lily® Sugar 'n Cream®",
       "label": "Lily® Sugar 'n Cream®"
   },
   {
       "value": "Lion Brand®",
       "label": "Lion Brand®"
   },
   {
       "value": "Liquid Illusions™",
       "label": "Liquid Illusions™"
   },
   {
       "value": "Liquid Stitch®",
       "label": "Liquid Stitch®"
   },
   {
       "value": "Liquitex®",
       "label": "Liquitex®"
   },
   {
       "value": "Little Makers™",
       "label": "Little Makers™"
   },
   {
       "value": "Loctite®",
       "label": "Loctite®"
   },
   {
       "value": "Loew-Cornell®",
       "label": "Loew-Cornell®"
   },
   {
       "value": "Lucky Brand",
       "label": "Lucky Brand"
   },
   {
       "value": "Luminara®",
       "label": "Luminara®"
   },
   {
       "value": "Luminous Luster",
       "label": "Luminous Luster"
   },
   {
       "value": "Luxite®",
       "label": "Luxite®"
   },
   {
       "value": "Luxury Collection",
       "label": "Luxury Collection"
   },
   {
       "value": "Luxury Loft",
       "label": "Luxury Loft"
   },
   {
       "value": "Make It A Party",
       "label": "Make It A Party"
   },
   {
       "value": "Make It Love It™",
       "label": "Make It Love It™"
   },
   {
       "value": "Maker's Halloween Littles™",
       "label": "Maker's Halloween Littles™"
   },
   {
       "value": "Maker's Halloween™",
       "label": "Maker's Halloween™"
   },
   {
       "value": "Maker's Holiday™",
       "label": "Maker's Holiday™"
   },
   {
       "value": "Maker's Holiday™Littles",
       "label": "Maker's Holiday™Littles"
   },
   {
       "value": "Makers Halloween™ Craft",
       "label": "Makers Halloween™ Craft"
   },
   {
       "value": "Makers Halloween™ Scarecrows",
       "label": "Makers Halloween™ Scarecrows"
   },
   {
       "value": "Making Magic™",
       "label": "Making Magic™"
   },
   {
       "value": "Making Memories™",
       "label": "Making Memories™"
   },
   {
       "value": "Makit & Bakit®",
       "label": "Makit & Bakit®"
   },
   {
       "value": "Malden®",
       "label": "Malden®"
   },
   {
       "value": "Martha Stewart Crafts™",
       "label": "Martha Stewart Crafts™"
   },
   {
       "value": "Marvy® Uchida",
       "label": "Marvy® Uchida"
   },
   {
       "value": "Mary Ellen's",
       "label": "Mary Ellen's"
   },
   {
       "value": "Masterpieces",
       "label": "Masterpieces"
   },
   {
       "value": "McCall's®",
       "label": "McCall's®"
   },
   {
       "value": "McG",
       "label": "McG"
   },
   {
       "value": "Me & My Big Ideas®",
       "label": "Me & My Big Ideas®"
   },
   {
       "value": "Me & My Peeps™",
       "label": "Me & My Peeps™"
   },
   {
       "value": "Melissa & Doug®",
       "label": "Melissa & Doug®"
   },
   {
       "value": "Meltable Candy Wafers",
       "label": "Meltable Candy Wafers"
   },
   {
       "value": "Memories Made Easy",
       "label": "Memories Made Easy"
   },
   {
       "value": "Memory Bootique™",
       "label": "Memory Bootique™"
   },
   {
       "value": "Metal Xpressions",
       "label": "Metal Xpressions"
   },
   {
       "value": "Mighty Bright®",
       "label": "Mighty Bright®"
   },
   {
       "value": "Milestones™",
       "label": "Milestones™"
   },
   {
       "value": "MISTI®",
       "label": "MISTI®"
   },
   {
       "value": "Mix & Mingle",
       "label": "Mix & Mingle"
   },
   {
       "value": "Mix the Media",
       "label": "Mix the Media"
   },
   {
       "value": "MLB®",
       "label": "MLB®"
   },
   {
       "value": "Mod Podge®",
       "label": "Mod Podge®"
   },
   {
       "value": "Moda Dea®",
       "label": "Moda Dea®"
   },
   {
       "value": "Modern Guise",
       "label": "Modern Guise"
   },
   {
       "value": "Mohawk",
       "label": "Mohawk"
   },
   {
       "value": "Momenta®",
       "label": "Momenta®"
   },
   {
       "value": "Mountain Mist®",
       "label": "Mountain Mist®"
   },
   {
       "value": "Mrs. Grossman's",
       "label": "Mrs. Grossman's"
   },
   {
       "value": "MSC Collectibles",
       "label": "MSC Collectibles"
   },
   {
       "value": "Mundial®",
       "label": "Mundial®"
   },
   {
       "value": "MYO",
       "label": "MYO"
   },
   {
       "value": "NA",
       "label": "NA"
   },
   {
       "value": "Nate Berkus™",
       "label": "Nate Berkus™"
   },
   {
       "value": "Nature Fil",
       "label": "Nature Fil"
   },
   {
       "value": "Natures Own",
       "label": "Natures Own"
   },
   {
       "value": "NBA®",
       "label": "NBA®"
   },
   {
       "value": "Nes & Hey Doll",
       "label": "Nes & Hey Doll"
   },
   {
       "value": "New Look",
       "label": "New Look"
   },
   {
       "value": "NFL®",
       "label": "NFL®"
   },
   {
       "value": "Nickelodeon®",
       "label": "Nickelodeon®"
   },
   {
       "value": "Noel Collection",
       "label": "Noel Collection"
   },
   {
       "value": "Nordic Ware®",
       "label": "Nordic Ware®"
   },
   {
       "value": "NSI®",
       "label": "NSI®"
   },
   {
       "value": "NSI® Imagine Nation®",
       "label": "NSI® Imagine Nation®"
   },
   {
       "value": "Nu-foam®",
       "label": "Nu-foam®"
   },
   {
       "value": "Nurture Nest®",
       "label": "Nurture Nest®"
   },
   {
       "value": "Offray®",
       "label": "Offray®"
   },
   {
       "value": "Olfa®",
       "label": "Olfa®"
   },
   {
       "value": "Oliso®",
       "label": "Oliso®"
   },
   {
       "value": "Omnigrid®",
       "label": "Omnigrid®"
   },
   {
       "value": "Once Upon A Playtime™",
       "label": "Once Upon A Playtime™"
   },
   {
       "value": "One Stroke",
       "label": "One Stroke"
   },
   {
       "value": "One Together",
       "label": "One Together"
   },
   {
       "value": "Open Road",
       "label": "Open Road"
   },
   {
       "value": "Optiart™",
       "label": "Optiart™"
   },
   {
       "value": "ORACAL®",
       "label": "ORACAL®"
   },
   {
       "value": "Organizing Essentials & Hud 43",
       "label": "Organizing Essentials & Hud 43"
   },
   {
       "value": "Organizing Essentials™",
       "label": "Organizing Essentials™"
   },
   {
       "value": "OttLite®",
       "label": "OttLite®"
   },
   {
       "value": "Oxford Street Jewelry",
       "label": "Oxford Street Jewelry"
   },
   {
       "value": "Paint Pots",
       "label": "Paint Pots"
   },
   {
       "value": "Painted By Me™",
       "label": "Painted By Me™"
   },
   {
       "value": "Palmer",
       "label": "Palmer"
   },
   {
       "value": "Paper House",
       "label": "Paper House"
   },
   {
       "value": "Paperkins",
       "label": "Paperkins"
   },
   {
       "value": "Park Lane®",
       "label": "Park Lane®"
   },
   {
       "value": "Parklane®",
       "label": "Parklane®"
   },
   {
       "value": "Patio Oasis",
       "label": "Patio Oasis"
   },
   {
       "value": "Patons®",
       "label": "Patons®"
   },
   {
       "value": "Patriotic",
       "label": "Patriotic"
   },
   {
       "value": "Patriotic Inspirations™",
       "label": "Patriotic Inspirations™"
   },
   {
       "value": "Peel'NStick™",
       "label": "Peel'NStick™"
   },
   {
       "value": "Peel'NStick™ Zots™",
       "label": "Peel'NStick™ Zots™"
   },
   {
       "value": "Peeps®",
       "label": "Peeps®"
   },
   {
       "value": "Pellon®",
       "label": "Pellon®"
   },
   {
       "value": "Pepperell Braiding Co Inc",
       "label": "Pepperell Braiding Co Inc"
   },
   {
       "value": "Pergamano®",
       "label": "Pergamano®"
   },
   {
       "value": "Perler®",
       "label": "Perler®"
   },
   {
       "value": "Picnic Spring",
       "label": "Picnic Spring"
   },
   {
       "value": "Pillsbury®",
       "label": "Pillsbury®"
   },
   {
       "value": "Pink RIbbon",
       "label": "Pink RIbbon"
   },
   {
       "value": "Pioneer®",
       "label": "Pioneer®"
   },
   {
       "value": "Place & Time®",
       "label": "Place & Time®"
   },
   {
       "value": "Plaid®",
       "label": "Plaid®"
   },
   {
       "value": "Play-Doh®",
       "label": "Play-Doh®"
   },
   {
       "value": "Polaroid Snap",
       "label": "Polaroid Snap"
   },
   {
       "value": "Polaroid ZIP",
       "label": "Polaroid ZIP"
   },
   {
       "value": "Poly-Fil®",
       "label": "Poly-Fil®"
   },
   {
       "value": "Polymark™",
       "label": "Polymark™"
   },
   {
       "value": "Polymer Clay & Craft Oven™",
       "label": "Polymer Clay & Craft Oven™"
   },
   {
       "value": "Pop Couture",
       "label": "Pop Couture"
   },
   {
       "value": "POP!",
       "label": "POP!"
   },
   {
       "value": "Pop!™",
       "label": "Pop!™"
   },
   {
       "value": "Post-It®",
       "label": "Post-It®"
   },
   {
       "value": "Pounder®",
       "label": "Pounder®"
   },
   {
       "value": "Premier®",
       "label": "Premier®"
   },
   {
       "value": "Premium Brands",
       "label": "Premium Brands"
   },
   {
       "value": "Pretty Paper™",
       "label": "Pretty Paper™"
   },
   {
       "value": "Prismacolor®",
       "label": "Prismacolor®"
   },
   {
       "value": "Project Runway",
       "label": "Project Runway"
   },
   {
       "value": "Provo Craft®",
       "label": "Provo Craft®"
   },
   {
       "value": "Prym",
       "label": "Prym"
   },
   {
       "value": "Pumpkin Boutique™",
       "label": "Pumpkin Boutique™"
   },
   {
       "value": "Pure Honey™",
       "label": "Pure Honey™"
   },
   {
       "value": "Purl Essence®",
       "label": "Purl Essence®"
   },
   {
       "value": "Purse-N-Alize It!",
       "label": "Purse-N-Alize It!"
   },
   {
       "value": "Quilt Block Of The Month",
       "label": "Quilt Block Of The Month"
   },
   {
       "value": "Quilter's Palette™",
       "label": "Quilter's Palette™"
   },
   {
       "value": "Quilter's Showcase",
       "label": "Quilter's Showcase"
   },
   {
       "value": "Quincrafts®",
       "label": "Quincrafts®"
   },
   {
       "value": "Rainbow",
       "label": "Rainbow"
   },
   {
       "value": "Ready-to-Decorate",
       "label": "Ready-to-Decorate"
   },
   {
       "value": "Red",
       "label": "Red"
   },
   {
       "value": "Red Heart®",
       "label": "Red Heart®"
   },
   {
       "value": "Red Tag",
       "label": "Red Tag"
   },
   {
       "value": "Red White & Bold",
       "label": "Red White & Bold"
   },
   {
       "value": "Reeves",
       "label": "Reeves"
   },
   {
       "value": "Res-Q™",
       "label": "Res-Q™"
   },
   {
       "value": "Richloom Studio®",
       "label": "Richloom Studio®"
   },
   {
       "value": "Rit",
       "label": "Rit"
   },
   {
       "value": "Roc-Lon®",
       "label": "Roc-Lon®"
   },
   {
       "value": "Rose Art®",
       "label": "Rose Art®"
   },
   {
       "value": "Rowenta®",
       "label": "Rowenta®"
   },
   {
       "value": "Royal & Langnickel®",
       "label": "Royal & Langnickel®"
   },
   {
       "value": "Royal Coat®",
       "label": "Royal Coat®"
   },
   {
       "value": "Royale®",
       "label": "Royale®"
   },
   {
       "value": "Ruggables",
       "label": "Ruggables"
   },
   {
       "value": "Running 8th's™",
       "label": "Running 8th's™"
   },
   {
       "value": "Russell Athletic®",
       "label": "Russell Athletic®"
   },
   {
       "value": "Rust-oleum®",
       "label": "Rust-oleum®"
   },
   {
       "value": "Safari® LTD",
       "label": "Safari® LTD"
   },
   {
       "value": "Sands Alive™",
       "label": "Sands Alive™"
   },
   {
       "value": "Sandylion®",
       "label": "Sandylion®"
   },
   {
       "value": "Save the Date",
       "label": "Save the Date"
   },
   {
       "value": "Save the Date™",
       "label": "Save the Date™"
   },
   {
       "value": "Scents of Style",
       "label": "Scents of Style"
   },
   {
       "value": "Scentsicles",
       "label": "Scentsicles"
   },
   {
       "value": "Scotchgard™",
       "label": "Scotchgard™"
   },
   {
       "value": "Scotch®",
       "label": "Scotch®"
   },
   {
       "value": "Scrap Essentials™",
       "label": "Scrap Essentials™"
   },
   {
       "value": "Scrappy Cat",
       "label": "Scrappy Cat"
   },
   {
       "value": "Scribbles®",
       "label": "Scribbles®"
   },
   {
       "value": "Sculpey®",
       "label": "Sculpey®"
   },
   {
       "value": "Sea To Sea™",
       "label": "Sea To Sea™"
   },
   {
       "value": "Seaport",
       "label": "Seaport"
   },
   {
       "value": "Seascape",
       "label": "Seascape"
   },
   {
       "value": "Seattle's Best®",
       "label": "Seattle's Best®"
   },
   {
       "value": "Sensations™",
       "label": "Sensations™"
   },
   {
       "value": "Serge.well™",
       "label": "Serge.well™"
   },
   {
       "value": "Serta®",
       "label": "Serta®"
   },
   {
       "value": "Sew Classics™",
       "label": "Sew Classics™"
   },
   {
       "value": "Sew Essentials™",
       "label": "Sew Essentials™"
   },
   {
       "value": "Sew For The Cure®",
       "label": "Sew For The Cure®"
   },
   {
       "value": "Sharper Image®",
       "label": "Sharper Image®"
   },
   {
       "value": "Sharpie®",
       "label": "Sharpie®"
   },
   {
       "value": "She Who Sews",
       "label": "She Who Sews"
   },
   {
       "value": "Shopkins™",
       "label": "Shopkins™"
   },
   {
       "value": "Showoffs™",
       "label": "Showoffs™"
   },
   {
       "value": "Signature Series™",
       "label": "Signature Series™"
   },
   {
       "value": "Silhouette Cameo®",
       "label": "Silhouette Cameo®"
   },
   {
       "value": "Silhouette®",
       "label": "Silhouette®"
   },
   {
       "value": "Silkessence™",
       "label": "Silkessence™"
   },
   {
       "value": "Silly Putty®",
       "label": "Silly Putty®"
   },
   {
       "value": "Simple Treasures™",
       "label": "Simple Treasures™"
   },
   {
       "value": "Simplicity®",
       "label": "Simplicity®"
   },
   {
       "value": "Simply Art™",
       "label": "Simply Art™"
   },
   {
       "value": "Simply Autumn",
       "label": "Simply Autumn"
   },
   {
       "value": "Simply Autumn Craft",
       "label": "Simply Autumn Craft"
   },
   {
       "value": "Simply Autumn™",
       "label": "Simply Autumn™"
   },
   {
       "value": "Simply Grosgrain®",
       "label": "Simply Grosgrain®"
   },
   {
       "value": "Simply Painting™",
       "label": "Simply Painting™"
   },
   {
       "value": "Simply Soft®",
       "label": "Simply Soft®"
   },
   {
       "value": "Simply Spray™",
       "label": "Simply Spray™"
   },
   {
       "value": "Simply Spring",
       "label": "Simply Spring"
   },
   {
       "value": "Singer®",
       "label": "Singer®"
   },
   {
       "value": "Singer® Confidence™",
       "label": "Singer® Confidence™"
   },
   {
       "value": "Singer® Curvy™",
       "label": "Singer® Curvy™"
   },
   {
       "value": "Singer® Fashion Mate™",
       "label": "Singer® Fashion Mate™"
   },
   {
       "value": "Singer® Futura™",
       "label": "Singer® Futura™"
   },
   {
       "value": "Singer® Heavy Duty™",
       "label": "Singer® Heavy Duty™"
   },
   {
       "value": "Singer® Inspiration™",
       "label": "Singer® Inspiration™"
   },
   {
       "value": "Singer® Patchwork™",
       "label": "Singer® Patchwork™"
   },
   {
       "value": "Singer® Prelude",
       "label": "Singer® Prelude"
   },
   {
       "value": "Singer® ProFinish™",
       "label": "Singer® ProFinish™"
   },
   {
       "value": "Singer® Simple™",
       "label": "Singer® Simple™"
   },
   {
       "value": "Singer® Start™",
       "label": "Singer® Start™"
   },
   {
       "value": "Singer® Stylist™",
       "label": "Singer® Stylist™"
   },
   {
       "value": "Singer® Talent™",
       "label": "Singer® Talent™"
   },
   {
       "value": "Singer® Tradition™",
       "label": "Singer® Tradition™"
   },
   {
       "value": "Siser®",
       "label": "Siser®"
   },
   {
       "value": "Sizzix®",
       "label": "Sizzix®"
   },
   {
       "value": "Skullduggery Inc",
       "label": "Skullduggery Inc"
   },
   {
       "value": "Smart Space™",
       "label": "Smart Space™"
   },
   {
       "value": "Smart Trump®",
       "label": "Smart Trump®"
   },
   {
       "value": "Smarties®",
       "label": "Smarties®"
   },
   {
       "value": "Smooth Finish™",
       "label": "Smooth Finish™"
   },
   {
       "value": "Soap Expressions™",
       "label": "Soap Expressions™"
   },
   {
       "value": "Soft & Bright®",
       "label": "Soft & Bright®"
   },
   {
       "value": "Soft N Crafty®",
       "label": "Soft N Crafty®"
   },
   {
       "value": "Softee®",
       "label": "Softee®"
   },
   {
       "value": "Spacebag®",
       "label": "Spacebag®"
   },
   {
       "value": "Sparkling Woodland",
       "label": "Sparkling Woodland"
   },
   {
       "value": "Spring",
       "label": "Spring"
   },
   {
       "value": "Spring Inspirations™",
       "label": "Spring Inspirations™"
   },
   {
       "value": "Spring/Summer",
       "label": "Spring/Summer"
   },
   {
       "value": "Springfield",
       "label": "Springfield"
   },
   {
       "value": "Springs Creative",
       "label": "Springs Creative"
   },
   {
       "value": "Square by Design®",
       "label": "Square by Design®"
   },
   {
       "value": "Staedtler®",
       "label": "Staedtler®"
   },
   {
       "value": "Stampendous®",
       "label": "Stampendous®"
   },
   {
       "value": "Starbucks®",
       "label": "Starbucks®"
   },
   {
       "value": "Stars N Stripes",
       "label": "Stars N Stripes"
   },
   {
       "value": "Sterilite®",
       "label": "Sterilite®"
   },
   {
       "value": "Stir™",
       "label": "Stir™"
   },
   {
       "value": "Stir™ & Melt'Ems",
       "label": "Stir™ & Melt'Ems"
   },
   {
       "value": "Stir™ & Wilton®",
       "label": "Stir™ & Wilton®"
   },
   {
       "value": "Stitch Nation",
       "label": "Stitch Nation"
   },
   {
       "value": "Strathmore®",
       "label": "Strathmore®"
   },
   {
       "value": "Styrofoam™",
       "label": "Styrofoam™"
   },
   {
       "value": "Suedesays™",
       "label": "Suedesays™"
   },
   {
       "value": "Sulky®",
       "label": "Sulky®"
   },
   {
       "value": "Sullivans",
       "label": "Sullivans"
   },
   {
       "value": "Sulyn",
       "label": "Sulyn"
   },
   {
       "value": "Summer",
       "label": "Summer"
   },
   {
       "value": "Summer Sol™",
       "label": "Summer Sol™"
   },
   {
       "value": "Sunbrella®",
       "label": "Sunbrella®"
   },
   {
       "value": "Surebonder®",
       "label": "Surebonder®"
   },
   {
       "value": "Susan Winget",
       "label": "Susan Winget"
   },
   {
       "value": "Swarovski Elements",
       "label": "Swarovski Elements"
   },
   {
       "value": "Sweet Creations By Good Cook™",
       "label": "Sweet Creations By Good Cook™"
   },
   {
       "value": "Sweet Sugarbelle",
       "label": "Sweet Sugarbelle"
   },
   {
       "value": "Sweetshop",
       "label": "Sweetshop"
   },
   {
       "value": "Sweetshop Melt'Ems",
       "label": "Sweetshop Melt'Ems"
   },
   {
       "value": "Sweetworks®",
       "label": "Sweetworks®"
   },
   {
       "value": "Symphony",
       "label": "Symphony"
   },
   {
       "value": "Tails & Noses®",
       "label": "Tails & Noses®"
   },
   {
       "value": "Tango®",
       "label": "Tango®"
   },
   {
       "value": "Taylor Seville®",
       "label": "Taylor Seville®"
   },
   {
       "value": "Teresa Collins™",
       "label": "Teresa Collins™"
   },
   {
       "value": "Test",
       "label": "Test"
   },
   {
       "value": "The 5 & Twine™",
       "label": "The 5 & Twine™"
   },
   {
       "value": "The Big Bolt™",
       "label": "The Big Bolt™"
   },
   {
       "value": "The Board Dudes®",
       "label": "The Board Dudes®"
   },
   {
       "value": "The Boneyard™",
       "label": "The Boneyard™"
   },
   {
       "value": "The Cookie Exchange®",
       "label": "The Cookie Exchange®"
   },
   {
       "value": "The Grace Company",
       "label": "The Grace Company"
   },
   {
       "value": "The Paper Company",
       "label": "The Paper Company"
   },
   {
       "value": "The Warm® Company",
       "label": "The Warm® Company"
   },
   {
       "value": "Therm-o-web®",
       "label": "Therm-o-web®"
   },
   {
       "value": "Thomasville®",
       "label": "Thomasville®"
   },
   {
       "value": "Thread Magic™",
       "label": "Thread Magic™"
   },
   {
       "value": "Tim Holtz",
       "label": "Tim Holtz"
   },
   {
       "value": "Timeless",
       "label": "Timeless"
   },
   {
       "value": "TLC®",
       "label": "TLC®"
   },
   {
       "value": "Tonic®",
       "label": "Tonic®"
   },
   {
       "value": "Top Notch™",
       "label": "Top Notch™"
   },
   {
       "value": "Totally Tiffany",
       "label": "Totally Tiffany"
   },
   {
       "value": "True Colors",
       "label": "True Colors"
   },
   {
       "value": "Tulip®",
       "label": "Tulip®"
   },
   {
       "value": "Tuscany",
       "label": "Tuscany"
   },
   {
       "value": "Twilight",
       "label": "Twilight"
   },
   {
       "value": "TY®",
       "label": "TY®"
   },
   {
       "value": "Uchida®",
       "label": "Uchida®"
   },
   {
       "value": "Ultimate Sweater Machine™",
       "label": "Ultimate Sweater Machine™"
   },
   {
       "value": "Ultrasuede®",
       "label": "Ultrasuede®"
   },
   {
       "value": "Unique Stitch®",
       "label": "Unique Stitch®"
   },
   {
       "value": "Valentine",
       "label": "Valentine"
   },
   {
       "value": "Valentine Littles™",
       "label": "Valentine Littles™"
   },
   {
       "value": "Valentine's Day Craft",
       "label": "Valentine's Day Craft"
   },
   {
       "value": "Velcro®",
       "label": "Velcro®"
   },
   {
       "value": "Verilux®",
       "label": "Verilux®"
   },
   {
       "value": "Visa®",
       "label": "Visa®"
   },
   {
       "value": "Vogue®",
       "label": "Vogue®"
   },
   {
       "value": "Walnut Hollow®",
       "label": "Walnut Hollow®"
   },
   {
       "value": "Warm & Natural®",
       "label": "Warm & Natural®"
   },
   {
       "value": "Warm & White®",
       "label": "Warm & White®"
   },
   {
       "value": "Warm&Natural® And Warm&White®",
       "label": "Warm&Natural® And Warm&White®"
   },
   {
       "value": "Waverly®",
       "label": "Waverly®"
   },
   {
       "value": "We R Memory Keepers™",
       "label": "We R Memory Keepers™"
   },
   {
       "value": "Westex International",
       "label": "Westex International"
   },
   {
       "value": "Westri®",
       "label": "Westri®"
   },
   {
       "value": "Wild Blooms",
       "label": "Wild Blooms"
   },
   {
       "value": "Wild Summer",
       "label": "Wild Summer"
   },
   {
       "value": "Wild Wire™",
       "label": "Wild Wire™"
   },
   {
       "value": "Wilton®",
       "label": "Wilton®"
   },
   {
       "value": "Wilton® & Satin Ice®",
       "label": "Wilton® & Satin Ice®"
   },
   {
       "value": "Winsor & Newton™",
       "label": "Winsor & Newton™"
   },
   {
       "value": "Wintuk®",
       "label": "Wintuk®"
   },
   {
       "value": "Wonder Under®",
       "label": "Wonder Under®"
   },
   {
       "value": "Wonderfil®",
       "label": "Wonderfil®"
   },
   {
       "value": "Wonderform®",
       "label": "Wonderform®"
   },
   {
       "value": "Woodline Works Corp",
       "label": "Woodline Works Corp"
   },
   {
       "value": "Woodsies™",
       "label": "Woodsies™"
   },
   {
       "value": "Wool and the Gang",
       "label": "Wool and the Gang"
   },
   {
       "value": "Works of Ahhh...®",
       "label": "Works of Ahhh...®"
   },
   {
       "value": "Wrap It Up™",
       "label": "Wrap It Up™"
   },
   {
       "value": "Wrights®",
       "label": "Wrights®"
   },
   {
       "value": "X-Acto®",
       "label": "X-Acto®"
   },
   {
       "value": "Xyron®",
       "label": "Xyron®"
   },
   {
       "value": "Yankee Candle®",
       "label": "Yankee Candle®"
   },
   {
       "value": "Yarntainer™",
       "label": "Yarntainer™"
   },
   {
       "value": "Yasutomo®",
       "label": "Yasutomo®"
   },
   {
       "value": "Yudu™",
       "label": "Yudu™"
   },
   {
       "value": "Zig®",
       "label": "Zig®"
   },
   {
       "value": "Zink",
       "label": "Zink"
   },
   {
       "value": "Zots™",
       "label": "Zots™"
   }
]
const ADGROUP_UOM_LIST =  [
    {
       value: 'EA',
       label:  'EA',
       labelDesc: 'each'
    },
    {
       value: 'EA/PR',
       label:  'EA/PR',
       labelDesc: 'each/pair'
    },
    {
       value: 'EA/ST',
       label:  'EA/ST',
       labelDesc: 'each/set'
    },
    {
       value: 'EA/YD',
       label:  'EA/YD',
       labelDesc: 'each/yard'
    },
    {
       value: 'EA/YD/PNL',
       label:  'EA/YD/PNL',
       labelDesc: 'each/yard/panel'
    },
    {
       value: 'IN',
       label:  'IN',
       labelDesc: 'inch'
    },
    {
       value: 'IN/EA/YD',
       label:  'IN/EA/YD',
       labelDesc: 'inch/each/yard'
    },
    {
       value: 'IN/EA/YD/PNL',
       label:  'IN/EA/YD/PNL',
       labelDesc: 'inch/each/yard/panel'
    },
    {
       value: 'IN/YD',
       label:  'IN/YD',
       labelDesc: 'inch/yard'
    },
    {
       value: 'NA',
       label:  'NA',
       labelDesc: 'not applicable'
    },
    {
       value: 'PK',
       label:  'PK',
       labelDesc: 'pack'
    },
    {
       value: 'PNL',
       label:  'PNL',
       labelDesc: 'panel'
    },
    {
       value: 'PR',
       label:  'PR',
       labelDesc: 'pair'
    },
    {
       value: 'ST',
       label:  'ST',
       labelDesc: 'set'
    },
    {
       value: 'YD',
       label:  'YD',
       labelDesc: 'yard'
    },
    {
       value: 'YD/PNL',
       label:  'YD/PNL',
       labelDesc: 'yard/panel'
    }
  ];

const MERCHID_MAPPING_LIST = [
   {
      Merch_Id: '100',
      MerchDescription: ',Fabric - Misc',
      GMM: '01',
      MerchandiseBuyerName: 'ROB WILL'
   },
   {
      Merch_Id: '111',
      MerchDescription: 'Fashion Apparel Fabrics,Patterns,Special Occasion',
      GMM: '01',
      MerchandiseBuyerName: 'SERENA SHROGE'
   },
   {
      Merch_Id: '119',
      MerchDescription: 'Fleece Shop,Warm Shop',
      GMM: '01',
      MerchandiseBuyerName: 'STEPHANIE MADDEN'
   },
   {
      Merch_Id: '120',
      MerchDescription: 'Sewing Construction,Sewing Tech',
      GMM: '01',
      MerchandiseBuyerName: 'MICHAEL HUGHES'
   },
   {
      Merch_Id: '121',
      MerchDescription: 'Foam/Fiber,Licensed Shop,Team',
      GMM: '01',
      MerchandiseBuyerName: 'EMILY BOSE'
   },
   {
      Merch_Id: '129',
      MerchDescription: 'Cotton,Seasonal Cotton',
      GMM: '01',
      MerchandiseBuyerName: 'VICTORIA POPOVICH'
   },
   {
      Merch_Id: '133',
      MerchDescription: 'Home Dec Fabric,Trims',
      GMM: '01',
      MerchandiseBuyerName: 'CARLY KARNIS'
   },
   {
      Merch_Id: '200',
      MerchDescription: 'Traditional Craft - Misc',
      GMM: '02',
      MerchandiseBuyerName: 'ROB WILL'
   },
   {
      Merch_Id: '210',
      MerchDescription: 'Craft Supplies,Fine Art Supplies',
      GMM: '02',
      MerchandiseBuyerName: 'JON PORTZ'
   },
   {
      Merch_Id: '212',
      MerchDescription: 'Kids Crafts',
      GMM: '02',
      MerchandiseBuyerName: 'CASSIDY JONES'
   },
   {
      Merch_Id: '213',
      MerchDescription: 'Needle Arts',
      GMM: '02',
      MerchandiseBuyerName: 'SARAH SCROGGY'
   },
   {
      Merch_Id: '221',
      MerchDescription: 'Craft Textiles,Jewelry',
      GMM: '02',
      MerchandiseBuyerName: 'DONNA GOFF DAVIS'
   },
   {
      Merch_Id: '224',
      MerchDescription: 'Craft Technology,Papercrafting Supplies',
      GMM: '02',
      MerchandiseBuyerName: 'MARISSA CUMMINS'
   },
   {
      Merch_Id: '300',
      MerchDescription: 'Seasonal / Floral - Misc',
      GMM: '03',
      MerchandiseBuyerName: 'ROB WILL'
   },
   {
      Merch_Id: '310',
      MerchDescription: 'Home / Everyday - Misc',
      GMM: '03',
      MerchandiseBuyerName: 'ROB WILL'
   },
   {
      Merch_Id: '311',
      MerchDescription: 'Holiday Decor,Mini Seasons',
      GMM: '03',
      MerchandiseBuyerName: 'ALEXANDRIA CHICATELLI'
   },
   {
      Merch_Id: '314',
      MerchDescription: 'Autumn Decor,Halloween Decor,Spring Decor,Summer Decor',
      GMM: '03',
      MerchandiseBuyerName: 'JACLYN SCHULTE'
   },
   {
      Merch_Id: '315',
      MerchDescription: 'Frames,Storage',
      GMM: '03',
      MerchandiseBuyerName: 'JEFFREY RUDYK'
   },
   {
      Merch_Id: '320',
      MerchDescription: 'Basic Floral,Fall Floral,Holiday Floral,Spring/Summer Floral',
      GMM: '03',
      MerchandiseBuyerName: 'LORI FOURNIER'
   },
   {
      Merch_Id: '321',
      MerchDescription: 'Candles,Front End & Impulse',
      GMM: '03',
      MerchandiseBuyerName: 'SAMANTHA MIDOLO'
   },
   {
      Merch_Id: '330',
      MerchDescription: 'Foodcrafting Shop,Ribbon',
      GMM: '03',
      MerchandiseBuyerName: 'ASHLEY MARTIN'
   },
   {
      Merch_Id: '410',
      MerchDescription: 'Boss',
      GMM: '04',
      MerchandiseBuyerName: 'N/A'
   },
   {
      Merch_Id: '411',
      MerchDescription: 'Other',
      GMM: '04',
      MerchandiseBuyerName: 'N/A'
   },
   {
      Merch_Id: '420',
      MerchDescription: 'Creative Bug',
      GMM: '04',
      MerchandiseBuyerName: 'N/A'
   },
   {
      Merch_Id: '430',
      MerchDescription: 'Special Projects',
      GMM: '04',
      MerchandiseBuyerName: 'N/A'
   },
   {
      Merch_Id: '460',
      MerchDescription: 'Fabany',
      GMM: '04',
      MerchandiseBuyerName: 'N/A'
   },
   {
      Merch_Id: '849',
      MerchDescription: 'Custom Framing,Education Programs,Education-Misc',
      GMM: '08',
      MerchandiseBuyerName: 'N/A'
   },
];

const ADGROUP_TYPE_OPTIONS = [
   { label: 'Active', value: 'A' },
   { label: 'Deactive', value: 'D' },
   { label: 'Clearance', value: 'C' }
]



module.exports = {
  ADGROUP_LABELS: ADGROUP_LABELS,
  ADGROUP_UOM_LIST: ADGROUP_UOM_LIST,
  MERCHID_MAPPING_LIST: MERCHID_MAPPING_LIST,
  MERCHANDISE_HIERARCHY_LIST: MERCHANDISE_HIERARCHY_LIST,
  ADGROUP_TYPE_OPTIONS: ADGROUP_TYPE_OPTIONS,
  // MERCHCAT_ID_TO_DESC_MAPPING: MERCHCAT_ID_TO_DESC_MAPPING,
  // CATEGORY_ID_TO_DESC_MAPPING: CATEGORY_ID_TO_DESC_MAPPING,
  MERCH_CAT_OPTIONS: MERCH_CAT_OPTIONS,
  CATEGORY_OPTIONS: CATEGORY_OPTIONS,
  SHOP_DESCRIPTION_OPTIONS: SHOP_DESCRIPTION_OPTIONS,
  AREA_OPTIONS: AREA_OPTIONS,
  BUSINESS_OPTIONS: BUSINESS_OPTIONS,
  CUSTOMERGROUP_OPTIONS: CUSTOMERGROUP_OPTIONS,
  VENDOR_OPTIONS: VENDOR_OPTIONS,
  ATTRIBUTE_COLOR_OPTIONS: ATTRIBUTE_COLOR_OPTIONS,
  ATTRIBUTE_FABRIC_OPTIONS: ATTRIBUTE_FABRIC_OPTIONS,
  ATTRIBUTE_SIZE_OPTIONS: ATTRIBUTE_SIZE_OPTIONS,
  ATTRIBUTE_THEME_OPTIONS: ATTRIBUTE_THEME_OPTIONS,
  PRODUCT_BRAND_OPTIONS: PRODUCT_BRAND_OPTIONS
//   getHighHierarchyMapping: getHighHierarchyMapping
}