import React, { Component } from "react";
import { Input } from 'antd';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PageLoader from "../../../../utils/Loader";
import DateRangePicker from "../../../../utils/DateRangePicker/DateRangePicker.js";
import Multiselect from "../../../../utils/Multiselect";
import PropTypes from "prop-types";
import moment from "moment";
import ColumnDef from "../../../tables/colDefs/dayLevelOfferColdef";
import mapColumnDef from "../../../tables/colDefs/mapAdGroupModalColDef";
import DataTable from "../../../tables";
import "react-dates/lib/css/_datepicker.css";
import "../../../../utils/DateRangePicker/dateRangeStyling.css";
import { cloneDeep, toUpper, isEmpty } from "lodash";
import "./dayLevelOfferStyling.css";
import { alertTimer } from "../../../../utils/alertTimer.js";
import Alert from 'react-bootstrap/Alert'
import {
  getFiscalData,
  getDayLevelFilters,
  getDayLevelOffers,
  resetDayLevelOffer,
  getLyAdgroupData,
  getSavedViewData,
  saveCampaignViewName,
  getSavedViewNames,
  selectedBuyersList,
  selectedShopList,
  selectedAdgroupList,
  moreGroupSelected,
  downloadMasterExportAction,
  downloadDayLevelAction,
  getAdgroupDataRangeWise,
  updateFiscalDataAction
} from "../../../../redux/actions/dayLevelOfferAction";
import MapAdGroupModal from "./MapAdGroupModal";
import LYCellRenderer from "./LYCellRenderer";
import ViewNameModal from "./viewNameModal";
import {
  emptyStringValueFormatter,
  valueGMFormatterHandler,
  valueFormatterHandler,
  valueAURFormatterHandler
} from "../../../../utils/valueFormatters.js";
import AlertDanger from "../../../../utils/AlertMessage/alertDanger";
import AlertSuccess from "../../../../utils/AlertMessage/alertSuccess";
import Select from "react-select";
import _ from "lodash";
import masterDownload from './MasterDownload.png';
import { DayLevelOfferGA } from '../../../../utils/GA/GAUtils';
const { TextArea } = Input;
const customStyles = {
  menu: (provided, state) => ({
    ...provided,
    width: "265px",
    fontSize: "0.813rem",
    fontWeight: "normal",
    color: "#3e3e3e",
  }),
};
export class DayLevelOffer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: null,
      endDate: null,
      focusedInput: "",
      filterMetricsDisabled: true,
      adgroupSearchText: "",
      tableData: {},
      resetTable: false,
      showModal: false,
      frameworkComponents: { lYCellRenderer: LYCellRenderer },
      LYTYMapping: [],
      saveStatusAlert: false,
      showViewName: false,
      dayLevelExport: false,
      downLoadActive: false,
      dateRangeExceededAlert: false,
      // adgroupDL: this.props.dayLevelOfferAdgroupData,
      adgroupDL: this.props.adgroupDataRangeWise,
      rowData: [],
      savedViewSelected: "",
      filtersFromSave: false,
      timeout: 0,
      typedAdGroupId : [],
      selectedAdGroupFilter: []
    };
  }

  onAdGroupSearch = (e) => {
    var adgroupSearchText = "";
    if (/^[a-z0-9]+$/i.test(e.key) && e.key && e.key.length == 1) {
      adgroupSearchText = e.target.value + e.key;
    } else if (e.key === "Backspace") {
      adgroupSearchText = e.target.value.slice(0, -1);
    } else adgroupSearchText = e;

    this.setState({
      adgroupSearchText: adgroupSearchText,
    });

      let startDateFormat = JSON.stringify(this.state.startDate);
      let resultStartDate = startDateFormat.slice(1, 11);
      let endDateFormat = JSON.stringify(this.state.endDate);
      let resultEndDate = endDateFormat.slice(1, 11);

      let reqObj = {
        input: {
          inputSearchKey: adgroupSearchText ? [adgroupSearchText.toString().toUpperCase()] : [],
            key :  "typed"
        },
        selectedAdgroups: this.props.selectedAdgroups.adgroupSelectedValue,
        startDate: resultStartDate,
        endDate: resultEndDate
      };


      if (this.timeout) clearTimeout(this.timeout);
     this.timeout = setTimeout(() => {
        this.props.getAdgroupDataRangeWise(reqObj)
        let args = { adgroupdata: this.props.adgroupDataRangeWise };
        let resp =  this.props.selectedAdgroupList(this.state.selectedAdGroupFilter, [], args);
    }, 500);

    // }

    // let reqObj = {
    //   input: {
    //     inputSearchKey:
    //       adgroupSearchText && adgroupSearchText.toString().toUpperCase(),
    //   },
    //   selectedAdgroups: this.props.selectedAdgroups.adgroupSelectedValue,
    // };
    // console.log("🚀 ~ reqObj:", reqObj)
    // if (this.timeout) clearTimeout(this.timeout);
    // this.timeout = setTimeout(() => {
    //   this.props.getLyAdgroupData(reqObj);
    // }, 500);
  };

  onDatesChange = async (startDate) => {
    // this.setState({
    //   startDate: startDate.startDate,
    //   endDate: startDate.endDate,
    // });
    
    this.setState({ startDate : startDate.startDate, endDate:startDate.endDate }, async() => {
      let startDateFormat = JSON.stringify(startDate.startDate);
      let resultStartDate = startDateFormat.slice(1, 11);
      console.log("🚀 ~ DayLevelOffer ~ onDatesChange= ~ resultStartDate:", resultStartDate)
      let endDateFormat = JSON.stringify(startDate.endDate);
      let resultEndDate = endDateFormat.slice(1, 11);
      console.log("🚀 ~ DayLevelOffer ~ onDatesChange= ~ resultEndDate:", resultEndDate)

      let reqObj = {
        startDate: resultStartDate,
        endDate: resultEndDate,
      };
      console.log("🚀 ~ DayLevelOffer ~ this.setState ~ reqObj:", reqObj)
      if (resultStartDate && resultEndDate && (resultStartDate.length > 4 && resultEndDate.length > 4) ) {
        await this.props.getAdgroupDataRangeWise(reqObj);
      }
    })

    if (isEmpty(startDate.startDate) || isEmpty(startDate.endDate)) return;
    let startDateFormat = JSON.stringify(startDate.startDate);
    let resultStartDate = startDateFormat.slice(1, 11);
    let endDateFormat = JSON.stringify(startDate.endDate);
    let resultEndDate = endDateFormat.slice(1, 11);
    if (
      false && moment(resultEndDate, "YYYY-MM-DD").diff(
        moment(resultStartDate, "YYYY-MM-DD"),
        "weeks"
      ) > 13
    ) {
      this.setState({
        dateRangeExceededAlert: true,
        startDate: null,
        endDate: null,
      });

      setTimeout(() => {
        this.setState({
          dateRangeExceededAlert: false,
        });
      }, alertTimer);
    } else {
      this.setState({
        resultStartDate,
        resultEndDate,
      });

      if (
        (resultStartDate.length > 4 && resultEndDate.length > 4) ||
        !this.state.filtersFromSave
      ) {
        this.setState({
          filterMetricsDisabled: false,
        });
        let reqObj = {
          startDate: resultStartDate,
          endDate: resultEndDate,
        };
        this.props.getFiscalData(reqObj).then((resp) => {
          if (resp.status === 200) {
            // this.setTableData();
          }
        });
      }
    }
  };
  onFilter = async () => {
    let ty_values = [],
      ly_values = [];
      
    this.props.LYTYrowData.forEach((lyty) => {
      ty_values.push(lyty.ty_value);
      ly_values.push(lyty.ly_value);
    });

    let reqObj = {
      offerReq: {
        startDate: this.state.startDate,
        endDate: this.state.endDate,
        shopDesc: this.props.selectedShop.shopListForFilter,
        tyadgroup: ty_values,
        lyadgroup: ly_values,
        buyer: this.props.selectedBuyers.buyerListForFilter,
      },
    };
    await this.props.getDayLevelOffers(reqObj).then((resp) => {
      console.log("Responsdfasdf",resp)
      if (resp.status === 200) {
        this.setTableData(
          this.props.selectedAdditionalMetrics.moreGroupListForFilter
        );
      }
    });
  };
  componentDidMount() {
    let reqObj = {
      buyer: {
        requiredColumn: "merch_id",
      },

      shop: {
        requiredColumn: "shop_descr",
      },

      // adgroup: {
      //   inputSearchKey: "",
      // },
      savedView: {},
    };
    this.props.getDayLevelFilters(reqObj);
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.selectedAdditionalMetrics.moreGroupListForFilter !==
      this.props.selectedAdditionalMetrics.moreGroupListForFilter &&
      this.props.selectedAdgroups.adgroupListForFilter.length
    ) {
      this.setMoreColumns();
    }

    if (this.state.downLoadActive) {
      this.setState({ downLoadActive: false, dayLevelExport: true });
    }
  }
  dayLevelexportHandler = () => {
    this.setState({ dayLevelExport: false });
  };
  downloadHandler = () => {
    // this.setState({ downLoadActive: true });
    let ty_values = [],
    ly_values = [];
  this.props.LYTYrowData.forEach((lyty) => {
    ty_values.push(lyty.ty_value);
    ly_values.push(lyty.ly_value);
  });

  let reqObj = {
    offerReq: {
      startDate: this.state.startDate,
      endDate: this.state.endDate,
      shopDesc: this.props.selectedShop.shopListForFilter,
      tyadgroup: ty_values,
      lyadgroup: ly_values,
      buyer: this.props.selectedBuyers.buyerListForFilter,
      type: 'download',
      metric_options: this.props.selectedAdditionalMetrics.moreGroupSelectedValue.map(obj=> obj.value)
    },
  };
  console.log("Req Object",reqObj)
    this.props.downloadDayLevelAction(reqObj);
  };
  downloadMasterExport = async () => {
    //  let file_link = !_.isEmpty(localStorage.getItem("master_download_file_link"))
    //   ? JSON.parse(localStorage.getItem("master_download_file_link"))
    //   : "#";
    //   console.log("****************",localStorage.getItem("master_download_file_link"));
    // window.open(localStorage.getItem("master_download_file_link"), "_self")

    this.props.downloadMasterExportAction();
  };
  setMoreColumns = () => {
    this.setTableData(
      this.props.selectedAdditionalMetrics.moreGroupListForFilter
    );
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      nextProps.dayLevelOfferAdgroupData !== prevState.adgroupDL &&
      prevState.rowData
    ) {
      const copyRowData = prevState.rowData.reduce((result, item) => {
        item.adgroupArray = nextProps.dayLevelOfferAdgroupData;
        result.push(item);
        return result;
      }, []);

      return { rowData: copyRowData };
    }
  }

  dayLevelValueFormatter = (adGroup) => {
    let stringAdGroups = [
      "EndDate",
      "StartDate",
      "OfferType",
      "ShopDesc",
      "Date",
    ];
    if (stringAdGroups.includes(adGroup)) {
      return emptyStringValueFormatter;
    } else if (adGroup == "GM%") {
      return valueGMFormatterHandler;
    } else if (adGroup == "AUR") {
      return valueAURFormatterHandler;
    } else {
      return valueFormatterHandler;
    }
  };

  updateColDefs = (fixedColdefs, addMoreGroups) => {
    let adGroupList = this.props.LYTYrowData;
    let adGroupColumnDef = [];
    adGroupList.forEach((adGroup) => {
      let tyAdgroupCol = {
        headerName: ` ${adGroup.ty_adgroup} (TY promo info)`,
        children: [
          {
            headerName: `${adGroup.ty_ad_desc}`, //adGroup.ad_desc
            children: [
              {
                // headerName: "TY Actual Offer/ZPES Offer",
                // headerTooltip: "TY Actual Offer/ZPES Offer",
                headerName: "TY Offer",
                headerTooltip: "TY Offer",
                field: `${adGroup.ty_adgroup}_TYOffer`,
                tooltipField: `${adGroup.ty_adgroup}_TYOffer`,
                valueGetter: emptyStringValueFormatter,
                suppressMovable: true,
              },
              // {
              //   headerName: "TY Event Type",
              //   headerTooltip: "TY Event Type",
              //   field: `${adGroup.ty_adgroup}_TYEventType`,
              //   tooltipField: `${adGroup.ty_adgroup}_TYEventType`,
              //   valueGetter: emptyStringValueFormatter,
              //   suppressMovable: true,
              // },
            ],
            suppressMovable: true,
          },
        ],
      };
      let lyAdgroupCol = {
        headerName: ` ${adGroup.ly_adgroup} (LY promo info)`,
        children: [
          {
            headerName: `${adGroup.ly_ad_desc}`, //adGroup.ad_desc
            children: [
              {
                // headerName: "LY Actual Offer/ZPES Offer",
                // headerTooltip: "LY Actual Offer/ZPES Offer",
                headerName: "LY Offer",
                headerTooltip: "LY Offer",
                field: `${adGroup.ly_adgroup}_LYOffer`,
                tooltipField: `${adGroup.ly_adgroup}_LYOffer`,
                valueGetter: emptyStringValueFormatter,
                suppressMovable: true,
              },
              // {
              //   headerName: "LY Event Type",
              //   headerTooltip: "LY Event Type",
              //   field: `${adGroup.ly_adgroup}_LYEventType`,
              //   tooltipField: `${adGroup.ly_adgroup}_LYEventType`,
              //   valueGetter: emptyStringValueFormatter,
              //   suppressMovable: true,
              // },
            ],
            suppressMovable: true,
          },
        ],
      };
      if (addMoreGroups) {
        for (let i = 0; i < addMoreGroups.length; i++) {
          if (addMoreGroups[i] === "Offer Comparison") {
            lyAdgroupCol.children[0].children.push({
              headerName: `${addMoreGroups[i]}`,
              headerTooltip: `${addMoreGroups[i]}`,
              field: `${adGroup.ly_adgroup}_offerComparison`,
              tooltipField: `${adGroup.ly_adgroup}_offerComparison`,
              valueGetter: emptyStringValueFormatter,
              suppressMovable: true,
            });
          } else {
            tyAdgroupCol.children[0].children.push({
              headerName: `TY ${addMoreGroups[i]}`,
              headerTooltip: `TY ${addMoreGroups[i]}`,
              field: `${adGroup.ty_adgroup}_TY${addMoreGroups[i]}`,
              tooltipField: `${adGroup.ty_adgroup}_TY${addMoreGroups[i]}`,
              valueGetter: this.dayLevelValueFormatter(addMoreGroups[i]),
              suppressMovable: true,
            });
            let offerCompIndex;
            lyAdgroupCol.children[0].children.forEach((col) => {
              if (col.headerName === "Offer Comparison") {
                offerCompIndex = lyAdgroupCol.children[0].children.indexOf(col);
              }
            });
            if (offerCompIndex) {
              lyAdgroupCol.children[0].children.splice(offerCompIndex, 0, {
                headerName: `LY ${addMoreGroups[i]}`,
                headerTooltip: `LY ${addMoreGroups[i]}`,
                field: `${adGroup.ly_adgroup}_LY${addMoreGroups[i]}`,
                tooltipField: `${adGroup.ly_adgroup}_LY${addMoreGroups[i]}`,
                valueGetter: this.dayLevelValueFormatter(addMoreGroups[i]),
                suppressMovable: true,
              });
            } else
              lyAdgroupCol.children[0].children.push({
                headerName: `LY ${addMoreGroups[i]}`,
                headerTooltip: `LY ${addMoreGroups[i]}`,
                field: `${adGroup.ly_adgroup}_LY${addMoreGroups[i]}`,
                tooltipField: `${adGroup.ly_adgroup}_LY${addMoreGroups[i]}`,
                valueGetter: this.dayLevelValueFormatter(addMoreGroups[i]),
                suppressMovable: true,
              });
          }
        }
      }

      adGroupColumnDef.push(tyAdgroupCol);
      adGroupColumnDef.push(lyAdgroupCol);
    });

    return [...fixedColdefs, ...adGroupColumnDef];
  };

  setTableData = (addMoreGroups) => {
    if (this.props.offerTableData) {
      let dayOfferData =
        Object.keys(this.props.offerTableData).length &&
        Object.values(this.props.offerTableData);
      let coldefs = this.updateColDefs(
        ColumnDef.DayLevelOfferTable,
        addMoreGroups
      );
      this.setState((prevState) => ({
        ...prevState,
        tableData: {
          ...prevState.tableData,
          data: dayOfferData,
          header: coldefs,
          defaultColDef: ColumnDef.defaultColDef,
        },
      }));
    }
  };
  resetTableData = (e) => {
    this.setState(
      (prevState) => ({
        tableData: {},
        // ...prevState.tableData,
        resetTable: true,
        rowData: [],
        startDate: "",
        endDate: "",
        addMoreGroups: true,
        filterMetricsDisabled: true,
        LYTYMapping: [],
        savedViewSelected: "",
        filtersFromSave: false,
        typedAdGroupId : [],
        selectedAdGroupFilter : []
      }),
      () => this.props.resetDayLevelOffer()
    );
  };

  onEditLYTYMapping = (value) => {
    this.submitGA("Filter", "Selected_LYAdgroupDropdown");
    let lytymapping = this.state.LYTYMapping;

    let filtered = lytymapping.filter((mapping) => {
      if (mapping.TyAdgroup !== value.TyAdgroup) {
        return mapping;
      }
    });
    filtered.push(value);

    this.setState({ LYTYMapping: filtered });
  };

  onSaveAdGroupModal = async () => {
    this.submitGA("Click", "Clicked_MapLYAdgrop_ApplyButton");
    this.props.moreGroupSelected([]);
    let lytymapping = this.state.LYTYMapping;
    let updateMappedData = this.state.rowData.filter(function (obj) {
      return lytymapping.some(function (obj2) {
        if (obj.ty_adgroup == obj2.TyAdgroup) {
          obj.ly_adgroup = obj2.LyAdgroup;
          obj.ly_ad_desc = obj2.ly_ad_desc;
          obj.ly_value = obj2.ly_value;
          return obj;
        }
      });
    });
    let lyData = [],
      tyData = [];
    updateMappedData.forEach((lyty) => {
      lyData.push(lyty.ly_value);
      tyData.push(lyty.ty_value);
    });

    this.closeMapAdGroupModal();
    let reqObj = {
      offerReq: {
        startDate: this.state.resultStartDate,
        endDate: this.state.resultEndDate,
        shopDesc: this.props.selectedShop.shopListForFilter,
        tyadgroup: tyData,
        lyadgroup: lyData,
        buyer: this.props.selectedBuyers.buyerListForFilter,
      },
      updateMappedData: updateMappedData,
      LYTYrowData: this.state.rowData,
    };
    await this.props.getDayLevelOffers(reqObj);
    this.setTableData();
  };

  showAdGroupModal = () => {
    this.setState({ showModal: true });
  };
  closeMapAdGroupModal = () => {
    this.setState({ showModal: false, LYTYMapping: [] });
    this.submitGA("Click", "Clicked_MapLYAdgrop_CancleButton");
  };

  isOutsideRangeHandler = (day) => {
    let formattedDate = moment(day).format(),
      endDate = moment().format(),
      isSameOrBefore = moment(formattedDate).isSameOrBefore(endDate);

    return !isSameOrBefore;
  };

  savedViewSelectedHandler = async (value) => {
    this.buyerSelected([]);
    this.shopSelected([]);
    this.adGroupSelected([]);
    this.setState({
      savedViewSelected: value,
      filtersFromSave: true,
      filterMetricsDisabled: false,
      startDate: null,
      endDate: null,
      tableData: {},
    });

    let reqObj = {
      savedViewReq: {
        view_name: value.value,
      },
      filtersReq: {
        buyer: this.props.dayLevelOfferBuyer,
        shop: this.props.dayLevelOfferShopData,
        // adgroup: this.props.dayLevelOfferAdgroupData,
        adgroup: this.props.adgroupDataRangeWise,
      },
    };
    let status = await this.props.getSavedViewData(reqObj);
    if (status && this.props.LYTYrowData && this.props.LYTYrowData.length) {
      if (!this.props.selectedAdditionalMetrics.moreGroupListForFilter.length) {
        this.setTableData();
      }
      this.setState({ rowData: this.props.LYTYrowData });
    }
  };

  buyerSelected = (value) => {
    this.props.updateFiscalDataAction(this.props.fiscalData)
    this.props.selectedBuyersList(value, this.props.dayLevelOfferBuyer);
  };
  shopSelected = (value) => {
    this.props.updateFiscalDataAction(this.props.fiscalData)
    this.props.selectedShopList(value, this.props.dayLevelOfferShopData);
  };

  adGroupSelected = async (value) => {
    console.log("🚀 ~ DayLevelOffer ~ adGroupSelected= ~ value:", value)
    // let args = { adgroupdata: this.props.dayLevelOfferAdgroupData };
    let args = { adgroupdata: this.props.adgroupDataRangeWise };
    let resp = await this.props.selectedAdgroupList(value, [], args);
    this.setState({ rowData: this.props.LYTYrowData, selectedAdGroupFilter : value }, ()=> {
    });

    let tempSelectedfilters = _.cloneDeep(this.state.selectedAdGroupFilter);
    tempSelectedfilters = [...value].filter(obj => {
      if (obj.hasOwnProperty("pastedValue")) {
        delete obj.pastedValue;
      }
      obj["dropdownValue"] = true;
      return obj;
    });

    this.setState({ selectedAdGroupFilter: tempSelectedfilters })
  };


handleTextareaChange = async (e, field) => {
  const { value } = e.target;
  this.props.updateFiscalDataAction(this.props.fiscalData)
  if(!value){
    // let args = { adgroupdata: this.props.dayLevelOfferAdgroupData };
    let args = { adgroupdata: this.props.adgroupDataRangeWise };
    let resp =  this.props.selectedAdgroupList(this.state.selectedAdGroupFilter, [], args);
  }

  let values = [];
  if (field === 'typedAdGroupId') {
      values = value
      .split('\n')
      .flatMap(line => line.split(','))
      .map(value => value.trimStart().toUpperCase())
  }

  this.setState({typedAdGroupId: values});
  console.log("🚀 ~ DayLevelOffer ~ values:", values)

  const absentValues = values.filter(val =>
    !this.props.adgroupDataRangeWise.some(obj => obj.label === val)
);
  console.log("🚀 ~ DayLevelOffer ~ handleTextareaChange= ~ absentValues:", absentValues)
  if (absentValues.length > 0) {

    let startDateFormat = JSON.stringify(this.state.startDate);
    let resultStartDate = startDateFormat.slice(1, 11);
    let endDateFormat = JSON.stringify(this.state.endDate);
    let resultEndDate = endDateFormat.slice(1, 11);
    let reqObj = {
      input: {
        inputSearchKey:absentValues,
        key :  "typed"
      },
      startDate: resultStartDate,
      endDate: resultEndDate
    }
    console.log("🚀 ~ handleTextareaChange= ~ reqObj:", reqObj)
    // let data = await this.props.getLyAdgroupData(reqObj)
    let data = await this.props.getAdgroupDataRangeWise(reqObj)
    // this.setState({ selectedAdGroupFilter: this.props.dayLevelOfferAdgroupData})
    // let args = { adgroupdata: this.props.dayLevelOfferAdgroupData };
    // let resp =  this.props.selectedAdgroupList(data, [], args);
     console.log("🚀 ~ DayLevelOffer ~ handleTextareaChange= ~ data:", data);
  }


    // let adGroup = this.props.dayLevelOfferAdgroupData.filter(obj => {
    let adGroup = this.props.adgroupDataRangeWise.filter(obj => {
      if (values.indexOf(String(obj.label)) !== -1) {
        obj['pastedValue'] = true;
        if (obj.hasOwnProperty("dropdownValue")) {
          delete obj["dropdownValue"]
        }
      } 
      else {
        delete obj["pastedValue"]
      }
      return obj;
    }).filter(obj => obj.pastedValue)
    console.log("🚀 ~ DayLevelOffer ~ adGroup ~ adGroup:", adGroup)

    if (adGroup.length>0) {
      const dropdownValues = this.state.selectedAdGroupFilter.filter(obj => !obj.hasOwnProperty("pastedValue") && (obj.hasOwnProperty("dropdownValue") && obj.dropdownValue))
      const copiedValues = this.state.selectedAdGroupFilter.filter(obj => values.indexOf(String(obj.label)) !== -1 && obj['pastedValue'])
      const adgroups = dropdownValues.concat(copiedValues).concat([...adGroup])
      const adgroupList = _.uniqBy(adgroups, 'label');

      adgroupList.map (obj => {
        let found = this.props.LYTYrowData.some(item => item.ty_adgroup === obj.label);
        if(!found){
          let tempObj = {};
          tempObj["ty_adgroup"] = obj.label;
          tempObj["ly_adgroup"] = obj.label;
          tempObj["ty_value"] =   obj.value;
          tempObj["ly_value"] =   obj.value;
          tempObj["ty_ad_desc"] = obj.adgroup_desc;
          tempObj["ly_ad_desc"] = obj.adgroup_desc;
          // tempObj["adgroupArray"] = this.props.dayLevelOfferAdgroupData;
          tempObj["adgroupArray"] = this.props.adgroupDataRangeWise;
          this.props.LYTYrowData.push(tempObj)
        }
      })

      this.setState({rowData: this.props.LYTYrowData})
    //  let args = { adgroupdata: this.props.dayLevelOfferAdgroupData };
     let args = { adgroupdata: this.props.adgroupDataRangeWise };
     let resp =  this.props.selectedAdgroupList(adgroupList, [], args);
    
    }
    else {
      console.log("this.state.selectedAdGroupFilter",this.state.selectedAdGroupFilter);
      // let args = { adgroupdata: this.props.dayLevelOfferAdgroupData };
      let args = { adgroupdata: this.props.adgroupDataRangeWise };
      let resp =  this.props.selectedAdgroupList(this.state.selectedAdGroupFilter, [], args);
    }
}


  collapseTabsOnChange = () => {
    var x = document.getElementsByClassName("collapse");
    for (let item of x) {
      item.classList.remove("show");
    }

    var y = document.getElementsByClassName("card-header");
    for (let item of y) {
      item.classList.add("collapsed");
    }
  };
  clearData = async(value) => {
    if (value === "date") {
      this.setState({
        startDate: null,
        endDate: null,
      });
      // let reqObj = {empty : true};
      // this.props.getAdgroupDataRangeWise(reqObj);
    } else if (value === "buyer") {
      this.props.updateFiscalDataAction(this.props.fiscalData)
      this.props.selectedBuyersList([], this.props.dayLevelOfferBuyer);
    } else if (value === "shop") {
      this.props.updateFiscalDataAction(this.props.fiscalData)
      this.props.selectedShopList([], this.props.dayLevelOfferShopData);
    } else if (value === "adGroup") {
      this.props.updateFiscalDataAction(this.props.fiscalData)
      // let args = { adgroupdata: this.props.dayLevelOfferAdgroupData };
      let args = { adgroupdata: this.props.adgroupDataRangeWise };
      this.props.selectedAdgroupList([], [], args);
      this.setState({ selectedAdGroupFilter: [], typedAdGroupId : [] });
      this.setState({ tableData: {} });
    }

    this.collapseTabsOnChange();
  };

  onAdGroupClose = () => {
    this.props.updateFiscalDataAction(this.props.fiscalData)
    let adGroupText = this.state.adgroupSearchText.toString().substring(0, 3);
    if (adGroupText.length >= 3) {
      // let reqObj = {
      //   input: {
      //     inputSearchKey: adGroupText.toString().toUpperCase(),
      //   },
      //   selectedAdgroups: this.props.selectedAdgroups.adgroupSelectedValue,
      // };
      let startDateFormat = JSON.stringify(this.state.startDate);
      let resultStartDate = startDateFormat.slice(1, 11);
      let endDateFormat = JSON.stringify(this.state.endDate);
      let resultEndDate = endDateFormat.slice(1, 11);
      let reqObj = {
        input: {
          inputSearchKey: [adGroupText.toString().toUpperCase()],
            key :  "typed"
        },
        selectedAdgroups: this.props.selectedAdgroups.adgroupSelectedValue,
        startDate: resultStartDate,
        endDate: resultEndDate
      };
      // this.props.getLyAdgroupData(reqObj);
      this.props.getAdgroupDataRangeWise(reqObj);
      let args = { adgroupdata: this.props.adgroupDataRangeWise };
      let resp =  this.props.selectedAdgroupList(this.state.selectedAdGroupFilter, [], args);
    }
  };
  
  dropdownClose = () => {
    this.props.updateFiscalDataAction(this.props.fiscalData)
  };

  filterDisableCheck = () => {
    let startDate = this.state.startDate;
    let endDate = this.state.endDate;
    let adGroup =
      this.props.selectedAdgroups &&
      this.props.selectedAdgroups.adgroupListForFilter;
    if (startDate && endDate && adGroup && adGroup.length) {
      return false;
    } else {
      return true;
    }
  };

  showCampaignViewModal = () => {
    this.setState({ showViewName: true });
  };
  saveViewNameModal = async (viewName) => {
    let saveViewReqObj = {
      view_name: viewName,
      view_data: [
        { buyer: this.props.selectedBuyers.buyerListForFilter },
        { shop_desc: this.props.selectedShop.shopListForFilter },
        { map_adgroups: this.state.rowData },
        {
          additionalMetrics:
            this.props.selectedAdditionalMetrics.moreGroupListForFilter,
        },
      ],
    };
    this.props.saveCampaignViewName(saveViewReqObj).then((res) => {
      this.setState({
        saveStatusAlert: true,
        savedViewSelected: { label: viewName, value: viewName },
      });
      setTimeout(() => {
        this.setState({ saveStatusAlert: false });
      }, alertTimer);
    });
    this.closeViewNameModal();
  };
  closeViewNameModal = () => {
    this.setState({
      showViewName: false,
    });
  };
  submitGA(action, label) {
    DayLevelOfferGA({ action, label });
  }
  render() {
    return (
      <div>
        <div className="alertDivs">
          {this.state.dateRangeExceededAlert ? (
            <AlertDanger
              message="A maximum date range of 13 weeks can be selected!"
              show={this.state.dateRangeExceededAlert}
            />
          ) : null}

          {/* {this.props.adgroupRangeExceededAlert ? (
            <AlertDanger
              message="A maximum range of 10 Adgroups can be selected!"
              show={this.props.adgroupRangeExceededAlert}
            />
          ) : null} */}
          {
            this.state.saveStatusAlert ? (
              this.props.savedViewStatus.status ? (
                <AlertSuccess
                  message={this.props.savedViewStatus.data}
                  show={this.state.saveStatusAlert}
                />
              ) : (
                <AlertDanger
                  message={this.props.savedViewStatus.data}
                  show={this.state.saveStatusAlert}
                />
              )
            ) : null
          }
        </div >

        <h4 className="promoHeading">View Day Level Offer</h4>
        <div className=" card cardStyles">
          <div className="mt-3 pr-0 save-selectBox">
            {/* <label className="promoLbels mt-3" style={{ display: "flex" }}>Saved Views
              <div
                style={{ width: "265px", marginTop: "-0.7em", marginLeft: "1em" }}
              >
                <Select
                  styles={customStyles}
                  placeholder="Select"
                  isDisabled={this.props.loader}
                  options={this.props.savedViewNames}
                  onChange={(value) => {
                    this.savedViewSelectedHandler(value)
                    this.submitGA("Filter", "Selected_SavedViews");
                  }}
                  value={this.state.savedViewSelected}
                />
              </div></label> */}
            <div style={{ marginRight: "10%", marginLeft: "57.5%", marginTop: "5px" }}>
              {localStorage.getItem("role") === "1" ?
                <button
                  style={{ margin: 0 }}
                  type="button"
                  title="Download Master Export"
                  className="btn masterExp pipeline-button-class mr-0 position-relative"
                  onClick={() => {
                    this.downloadMasterExport();
                    this.submitGA("Click", "Clicked_DownloadMasterExportButton");
                  }}
                >
                  <img src={masterDownload} />
                </button>
                : null}
            </div>
          </div>
          <hr className="line-break"></hr>
          <div
            className="row selectionsDiv"
            style={{
              marginBottom: "0,5%",
              marginLeft: "-5%",
            }}
          >
            <div
              className="col-md-2 pr-0 compare-date-range-picker"
            // style={{ marginLeft: "2% !important" }}
            >
              {/* compare-date-range-picker pl-4 */}
              <label className="promoLbels">
                Time Period <span className="requiredField"> * </span>
              </label>
              <DateRangePicker
                focusedInput={this.state.focusedInput}
                onFocusChange={(focusedInput) =>
                  this.setState({ focusedInput })
                }
                startDate={this.state.startDate}
                endDate={this.state.endDate}
                onDatesChange={({ startDate, endDate }) => {
                  this.onDatesChange({ startDate, endDate })
                  this.submitGA("Filter", "Selected_TimePeriod");
                }}
                showClearDates
                isOutsideRange={() => false}
                renderMonthElement={true}
                disabled={this.props.loader}
              // isOutsideRange={(day) => this.isOutsideRangeHandler(day)}
              />
              {this.state.startDate || this.state.endDate ? (
                <i
                  className="fa fa-times DL-close2 mt-1 ml-4"
                  onClick={() => {
                    this.clearData("date")
                    this.submitGA("Filter", "Selected_TimePeriod");
                  }}
                />
              ) : null}
            </div>
            <div className="col-md-2 pl-4 pr-0">
              <div
                className={
                  this.state.filterMetricsDisabled ? "disable-true" : ""
                }
              >
                <label className="promoLbels">Buyer</label>
                <Multiselect
                  placeholder="Select Buyer"
                  optionsArray={this.props.dayLevelOfferBuyer}
                  onDropDownChange={(value) => {
                    this.buyerSelected(value)
                    this.submitGA("Filter", "Selected_Buyer");
                  }}
                  isDisabled={this.state.filterMetricsDisabled}
                  dropdownValue={
                    this.props.selectedBuyers &&
                    this.props.selectedBuyers.buyerSelectedValue
                  }
                  onClose={() => this.dropdownClose()}
                />
                {this.props.selectedBuyers &&
                  this.props.selectedBuyers.buyerSelectedValue.length ? (
                  <i
                    className="fa fa-times close2 mt-1 ml-4"
                    onClick={() => {
                      this.clearData("buyer")
                      this.submitGA("Filter", "Selected_Buyer");
                    }}
                  />
                ) : null}
              </div>
            </div>
            <div className="col-md-2 pl-4 pr-0">
              <div
                className={
                  this.state.filterMetricsDisabled ? "disable-true" : ""
                }
              >
                <label className="promoLbels">Shop</label>
                <Multiselect
                  placeholder="Select Shop"
                  optionsArray={this.props.dayLevelOfferShopData}
                  onDropDownChange={(value) => {
                    this.shopSelected(value)
                    this.submitGA("Filter", "Selected_Shop");
                  }}
                  isDisabled={this.state.filterMetricsDisabled}
                  dropdownValue={
                    this.props.selectedShop &&
                    this.props.selectedShop.shopSelectedValue
                  }
                  onClose={() => this.dropdownClose()}
                />
                {this.props.selectedShop &&
                  this.props.selectedShop.shopSelectedValue.length ? (
                  <i
                    className="fa fa-times close2 mt-1 ml-4"
                    onClick={() => {
                      this.clearData("shop")
                      this.submitGA("Filter", "Selected_Shop");
                    }}
                  />
                ) : null}
              </div>
            </div>
            <div className="col-md-2 pl-4 pr-0">
              <div
                className={
                  this.state.filterMetricsDisabled ? "disable-true" : ""
                }
              >
                <label className="promoLbels">
                  Adgroup <span className="requiredField"> * </span>
                </label>
                <Multiselect
                  placeholder="Select Adgroup"
                  // optionsArray={this.props.dayLevelOfferAdgroupData}
                  optionsArray={this.props.adgroupDataRangeWise}
                  onDropDownChange={(value) => {
                    this.adGroupSelected(value)
                    this.submitGA("Filter", "Selected_Adgroup");
                  }}
                  dropdownDisabled={this.state.adgroupSelectionDisabled}
                  dropdownValue={
                    this.props.selectedAdgroups &&
                    this.props.selectedAdgroups.adgroupSelectedValue
                  }
                  onKeyDown={(e) => this.onAdGroupSearch(e)}
                  onClose={() => {
                    this.dropdownClose()
                    this.onAdGroupClose()
                  }}
                />
                {this.props.selectedAdgroups &&
                  this.props.selectedAdgroups.adgroupSelectedValue &&
                  this.props.selectedAdgroups.adgroupSelectedValue.length ? (
                  <i
                    className="fa fa-times close2 mt-1 ml-4"
                    onClick={() => {
                      this.clearData("adGroup")
                      this.submitGA("Filter", "Selected_Adgroup");
                    }}
                  />
                ) : null}
              </div>
            </div>
            <div className="col-md-3 pl-4 pr-0">
            <div
                className={
                  this.state.filterMetricsDisabled ? "disable-true" : ""
                }
              >
              <label className="promoLbels">
                  Adgroup
                </label>
                <TextArea
                    placeholder="Adgroup name"
                    autoSize={{
                      minRows: 1,
                      maxRows: 10,
                    }}
                    maxLength={1000}
                    value={this.state.typedAdGroupId.join(',')}
                    onChange={(e) => this.handleTextareaChange(e,'typedAdGroupId')}
                  />
            </div>
            </div>
            
          </div>
          <div className="d-flex justify-content-end" style={{marginTop: '-5px'}}>
          <div className="btnStyle">
              <button
                type="button"
                className="btn btn-primary filterBtn"
                disabled={this.filterDisableCheck()}
                onClick={(e) => {
                  this.onFilter()
                  this.submitGA("Click", "Clicked_FilterButton");
                }}  
              >
                Generate
                {/* Filter */}
              </button>
            </div>
            <div className="btnStyle" style={{ marginRight: "30px" }}>
              <button
                className="btn btn-primary resetButton"
                onClick={() => {
                  this.resetTableData();
                  this.submitGA("Click", "Clicked_ResetButton");
                }}
              >
                Reset
              </button>
            </div>
            </div>
          <hr className="line-break"></hr>
          <PageLoader loader={this.props.loader}>
            <div
              className="row selectionsDiv"
              style={{
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
              }}
            >
              {this.state.tableData && this.state.tableData.header ? (
                <>
                  <div className="">
                    <button
                      className="btn btn-primary resetButton"
                      disabled={
                        !(this.state.tableData && this.state.tableData.header)
                      }
                      onClick={() => {
                        this.showAdGroupModal()
                        this.submitGA("Click", "Clicked_MapLyAdgroup");
                      }}
                    >
                      Map LY Adgroup
                    </button>
                  </div>
                  <div className="col-md-2 pl-4 pr-0 mb-3">
                    <div
                      className={
                        !(this.state.tableData && this.state.tableData.header)
                          ? "disable-true"
                          : ""
                      }
                    >
                      <label className="promoLbels">Add more Metrics</label>
                      <Multiselect
                        placeholder="Select Group"
                        optionsArray={ColumnDef.GroupOptions}
                        onDropDownChange={(value) => {
                          this.props.moreGroupSelected(
                            value,
                            ColumnDef.GroupOptions
                          );
                          this.submitGA("Filter", "Selected_AddMoreMetrics");
                        }}
                        isDisabled={
                          !(this.state.tableData && this.state.tableData.header)
                        }
                        dropdownValue={
                          this.props.selectedAdditionalMetrics
                            .moreGroupSelectedValue
                        }
                      />
                    </div>
                  </div>
                  {/* <div className="col-md-2 pl-4 pr-0 mb-3"></div> */}
                  <div className="">
                    {/* <button
                      style={{ margin: 0 }}
                      type="button"
                      title="Save Day Level View"
                      className="btn pipeline-button-class mr-2 position-relative "
                      onClick={() => {
                        this.showCampaignViewModal()
                        this.submitGA("Click", "Clicked_SaveDayLevelViewButton");
                      }}
                      disabled={
                        this.state.tableData &&
                          this.state.tableData.data &&
                          this.state.tableData.data.length
                          ? false
                          : true
                      }
                    >
                      <i className="fa fa-save font-weight-bold" />
                    </button> */}
                    <button
                      style={{ margin: 0 }}
                      type="button"
                      title="Download Day Level Offer Data"
                      className="btn pipeline-button-class ml-1 mr-1 position-relative"
                      onClick={() => {
                        this.downloadHandler()
                        this.submitGA("Click", "Clicked_DownloadDayLevelOfferDataButton");
                      }}
                      disabled={
                        this.state.tableData &&
                          this.state.tableData.data &&
                          this.state.tableData.data.length
                          ? false
                          : true
                      }
                    >
                      <i className="fa fa-download" aria-hidden="true" />
                    </button>
                  </div>
                </>
              ) : null}
            </div>

            {this.state.showModal ? (
              <MapAdGroupModal
                closeMapAdGroupModal={this.closeMapAdGroupModal}
                editableRowsHandler={this.editableRowsHandler}
                frameworkComponents={this.state.frameworkComponents}
                onSaveAdGroupModal={this.onSaveAdGroupModal}
                onEditLYTYMapping={this.onEditLYTYMapping}
                // lyDropdownData = {this.props.dayLevelOfferAdgroupData}
                onAdGroupSearch={this.onAdGroupSearch}
                adgroupList={this.state.rowData}
              />
            ) : null}

            {this.state.tableData && this.state.tableData.header ? (
              <>
              <div className="dayLevelOffer-grid-div mt-4">
                <DataTable
                  parentId="myGridForDayLevelOffer"
                  parentStyle={{
                    height: "calc(100vh - 400px)",
                    width: "90%",
                    position: "center",
                  }}
                  parentClass="ag-theme-balham"
                  tableData={cloneDeep(this.state.tableData)}
                  dayLevelExport={this.state.dayLevelExport}
                  dayLevelexportHandler={this.dayLevelexportHandler}
                />
                
              </div>
              <div className="dayLevelOffer-grid-div mt-4">
              <Alert key={"primary"} variant={"primary"}>
                 To view the complete report , please download the csv using the download button
                </Alert>
              </div>
            </>
            ) : null}
          </PageLoader>
          {this.state.showViewName ? (
            <ViewNameModal
              saveViewNameModal={this.saveViewNameModal}
              closeViewNameModal={this.closeViewNameModal}
              savedViewSelected={this.state.savedViewSelected}
              listSavedViews={this.props.savedViewNames}
            />
          ) : null}
        </div>
      </div >
    );
  }
}

DayLevelOffer.propTypes = {
  getDayLevelFilters: PropTypes.func.isRequired,
  getAdgroupDataRangeWise : PropTypes.func.isRequired,
  getDayLevelOffers: PropTypes.func.isRequired,
  resetDayLevelOffer: PropTypes.func.isRequired,
  getLyAdgroupData: PropTypes.func.isRequired,
  getFiscalData: PropTypes.func.isRequired,
  getSavedViewData: PropTypes.func.isRequired,
  saveCampaignViewName: PropTypes.func.isRequired,
  getSavedViewNames: PropTypes.func.isRequired,
  selectedBuyersList: PropTypes.func.isRequired,
  selectedShopList: PropTypes.func.isRequired,
  selectedAdgroupList: PropTypes.func.isRequired,
  moreGroupSelected: PropTypes.func.isRequired,
  downloadMasterExportAction: PropTypes.func,
  downloadDayLevelAction: PropTypes.func
};

const mapStateToProps = (state) => ({
  dayLevelOfferBuyer: state.dayLevelOffer.dayLevelOfferBuyer,
  dayLevelOfferShopData: state.dayLevelOffer.dayLevelOfferShopData,
  dayLevelOfferAdgroupData: state.dayLevelOffer.dayLevelOfferAdgroupData,
  adgroupDataRangeWise : state.dayLevelOffer.adgroupDataRangeWise,
  loader: state.dayLevelOffer.loader,
  offerTableData: state.dayLevelOffer.daylevelTable,
  mappedLyTy: state.dayLevelOffer.mappedLyTy,
  savedViewNames: state.dayLevelOffer.savedViewNames,
  selectedBuyers: state.dayLevelOffer.selectedBuyers,
  selectedShop: state.dayLevelOffer.selectedShop,
  selectedAdgroups: state.dayLevelOffer.selectedAdgroups,
  selectedAdditionalMetrics: state.dayLevelOffer.selectedAdditionalMetrics,
  LYTYrowData: state.dayLevelOffer.LYTYrowData,
  savedViewStatus: state.dayLevelOffer.savedViewStatus,
  adgroupRangeExceededAlert: state.dayLevelOffer.adgroupRangeExceededAlert,
  masterExportDownloadLink: state.dayLevelOffer.masterExportDownloadLink,
  fiscalData: state.dayLevelOffer.fiscalData
});

export default connect(mapStateToProps, {
  getFiscalData,
  getDayLevelFilters,
  getAdgroupDataRangeWise,
  getDayLevelOffers,
  resetDayLevelOffer,
  getLyAdgroupData,
  getSavedViewData,
  saveCampaignViewName,
  getSavedViewNames,
  selectedBuyersList,
  selectedShopList,
  selectedAdgroupList,
  moreGroupSelected,
  downloadMasterExportAction,
  downloadDayLevelAction,
  updateFiscalDataAction
})(withRouter(DayLevelOffer));
