import { getBrowserTimeZone, comparator } from "../../../utils/valueFormatters";
import moment from "moment";

const actionApproveRoles = [3,6];
const loggedUser = localStorage.getItem("role");

const dateComparator = function (date1, date2, format = "MM/DD/YYYY") {
  const parsedDate1 = moment(date1, format).toDate();
  const parsedDate2 = moment(date2, format).toDate();
  return parsedDate1.getTime() - parsedDate2.getTime();
};

export default {
  defaultColDef: {
    resizable: true,
    filter: true,
    sortable: true,
  },
  promoApprovalHeader: [
    {
      headerName: "Action",
      field: loggedUser == 3 ? "mar_approval_status" : loggedUser == 6 ? "di_approval_status" : "approval_status",
      headerClass: "blue-header-bg",
      cellRenderer: "promoApprovalActionRenderer",
      width: 200,
      filter: "agTextColumnFilter",
      hide: actionApproveRoles.includes(loggedUser) ? true : false
    },
    { 
      headerName: "Buyer", 
      field: "merchant_id",
      headerClass: "blue-header-bg",
      filter: "agTextColumnFilter",
      width: 100,
    },
    { 
      headerName: "Campaign", 
      field: "campaign_name",
      headerClass: "blue-header-bg",
      filter: "agTextColumnFilter",
      width: 210,
      valueGetter: function (params) {
        if (params.data && params.data.campaign_name) {
          // return getBrowserTimeZone(params.data.campaign_start_date,"MM/DD/YYYY") + " - " + getBrowserTimeZone(params.data.campaign_end_date,"MM/DD/YYYY");
          return params.data.campaign_start_date +  " - " + params.data.campaign_end_date;
        }
        return "-";
      },
      comparator: function (campaignName1, campaignName2) {
        const startDate1 = campaignName1.split(" - ")[0];
        const startDate2 = campaignName2.split(" - ")[0];
        return dateComparator(startDate1, startDate2, "MM/DD/YYYY");
      },
   },
   { 
    headerName: "Reason", 
    field: "entry_reason",
    headerClass: "blue-header-bg",
    filter: "agTextColumnFilter",
    width : 150
  },
   { 
    headerName: "Adgroup", 
    field: "adgroup_name",
    headerClass: "blue-header-bg",
    filter: "agTextColumnFilter",
    width : 150
 },
 { 
  headerName: "Sign Description", 
  field: "sign_description",
  headerClass: "blue-header-bg",
  filter: "agTextColumnFilter",
},
{ 
  headerName: "Event ID", 
  field: "event_id",
  headerClass: "blue-header-bg",
  filter: "agTextColumnFilter",
  width: 150,
},
//  { 
//   headerName: "Event", 
//   field: "event",
//   headerClass: "blue-header-bg",
//   filter: "agTextColumnFilter",
//   width: 250,
//   valueGetter: function (params) {
//     if (params.data && params.data.type && params.data.event_type && params.data.event_start_date && params.data.event_end_date) {
//       // return params.data.type + "-" + params.data.event_type + "-" + getBrowserTimeZone(params.data.event_start_date,"YYYY-MM-DD") + "-" + getBrowserTimeZone(params.data.event_end_date,"YYYY-MM-DD");
//       return params.data.type + "-" + params.data.event_type + "-" + params.data.event_start_date + "-" + params.data.event_end_date;

//     }
//     return "-";
//   }
// },
{ 
  headerName: "Event Desc", 
  field: "event_description",
  headerClass: "blue-header-bg",
  filter: "agTextColumnFilter",
  width : 150
},
{ 
  headerName: "Event Start Date", 
  field: "event_start_date",
  headerClass: "blue-header-bg",
  filter: "agTextColumnFilter",
  valueGetter: function (params) {
    if (params.data && params.data.event_start_date) {
      // return getBrowserTimeZone(params.data.event_start_date,"MM/DD/YYYY");
      return params.data.event_start_date;
    }
    return "-";
  },
  comparator: function (date1, date2) {
    return comparator(date1, date2, "MM/DD/YYYY");
  },
  width : 150
},
{ 
  headerName: "Event End Date", 
  field: "event_end_date",
  headerClass: "blue-header-bg",
  filter: "agTextColumnFilter",
  valueGetter: function (params) {
    if (params.data && params.data.event_end_date) {
      // return getBrowserTimeZone(params.data.event_end_date,"MM/DD/YYYY");
      return params.data.event_end_date;
    }
    return "-";
  },
  comparator: function (date1, date2) {
    return comparator(date1, date2, "MM/DD/YYYY");
  },
  width : 150
},
{ 
  headerName: "Old Offer", 
  field: "old_offer",
  headerClass: "blue-header-bg",
  filter: "agTextColumnFilter",
  valueGetter: function (params) {
    if (params && params.data && params.data.old_offer) {
      let offerValue = params.data.old_offer;
      // Add a space after "$" and before "OFF" if "OFF" follows a number 
      //Used Regex here -> if regex pattern matches it will add spaces between them , if offer format is correct it will not be affected
      offerValue = offerValue.replace(/\$(\d+)(OFF)/, "$ $1 $2");
      return offerValue;
    }
    return "-";
  },
  width : 175
  // valueGetter: function (params) {
  //   if (params.data.requested_for == 'ADD') {
  //     return 'O';
  //   }
  //   return params.data.old_offer;
  // }
},
{ 
  headerName: "New Offer", 
  field: "offer",
  headerClass: "blue-header-bg",
  filter: "agTextColumnFilter",
  valueGetter: function (params) {
    if (params && params.data && params.data.offer) {
      let offerValue = params.data.offer;
      // Add a space after "$" and before "OFF" if "OFF" follows a number 
      //Used Regex here -> if regex pattern matches it will add spaces between them , if offer format is correct it will not be affected
      offerValue = offerValue.replace(/\$(\d+)(OFF)/, "$ $1 $2");
      return offerValue;
    }
    return "-";
  },
  // valueGetter: function (params) {
  //   if (params.data.requested_for == 'DELETE') {
  //     return 'O';
  //   }
  //   return params.data.offer;
  // }
  width : 175
},
{
    headerName: "Edit Offer",
    field: "action",
    headerClass: "blue-header-bg",
    cellRenderer: "promoApprovalEditRenderer",
    width: 200,
    filter: "agTextColumnFilter",
    // hide: actionApproveRoles.includes(loggedUser) ? true : false
  },
{ 
  headerName: "Old UAS", 
  field: "old_uas",
  headerClass: "blue-header-bg",
  filter: "agTextColumnFilter",
  width: 100,
  // valueGetter: function (params) {
  //   // if (params.data.requested_for == 'ADD') {
  //   //   return "O"
  //   // }
  //   if (params.data.old_uas == "x") {
  //     return "Yes"
  //   }
  
  //   return "No"
  // }
},
{ 
  headerName: "New UAS", 
  field: "uas",
  headerClass: "blue-header-bg",
  filter: "agTextColumnFilter",
  width: 100,
  // valueGetter: function (params) {
  //   // if (params.data.requested_for == 'DELETE') {
  //   //   return 'O';
  //   // }
  //   if (params.data.uas == "x") {
  //     return "Yes"
  //   }
  
  //   return "No"
  // }
},
{ 
  headerName: "Changed By", 
  field: "change_requested_by",
  headerClass: "blue-header-bg",
  filter: "agTextColumnFilter",
},
{ 
  headerName: "Changed At", 
  field: "change_requested_at",
  headerClass: "blue-header-bg",
  filter: "agTextColumnFilter",
  valueGetter: function (params) {
    if (params.data && params.data.change_requested_at) {
      return getBrowserTimeZone(params.data.change_requested_at);
    }
    return "-";
  },

  comparator: function (date1, date2) {
    return comparator(date1, date2, "MM-DD-YYYY HH:mm:ss");
  },
},
{ 
  headerName: "Marketing status", 
  field: "mar_approval_status",
  headerClass: "blue-header-bg",
  filter: "agTextColumnFilter",
},
// { 
//   headerName: "Marketing Delete comments", 
//   field: "comment_mar",
//   headerClass: "blue-header-bg",
//   filter: "agTextColumnFilter",
//   width: 300,
// },
{ 
  headerName: "Marketing notes", 
  field: "note_mar",
  headerClass: "blue-header-bg",
  filter: "agTextColumnFilter",
  cellRenderer: "promoApprovalInputRenderer" ,
  width : 175
},
{ 
  headerName: "DI status", 
  field: "di_approval_status",
  headerClass: "blue-header-bg",
  filter: "agTextColumnFilter",
  width : 130
},

// {
//   headerName: "Edit Offer",
//   field: "action",
//   headerClass: "blue-header-bg",
//   cellRenderer: "promoApprovalEditRenderer",
//   width: 200,
//   filter: "agTextColumnFilter",
//   // hide: actionApproveRoles.includes(loggedUser) ? true : false
// },

// { 
//   headerName: "DI Delete comments", 
//   field: "comment_di",
//   headerClass: "blue-header-bg",
//   filter: "agTextColumnFilter",
// },
{
  headerName: "Marketing Actioned By",
  field: "marketing_user",
  width: 250,
  filter: "agTextColumnFilter",
  unSortIcon: true,
  resizable: true,
  sortable: true,
  headerClass: "blue-header-bg",
  valueGetter: function (params) {
    if (params.data && params.data.marketing_user) {
      return params.data.marketing_user;
    }
    return "-";
  },
  comparator: function (date1, date2) {
    return comparator(date1, date2, "MM-DD-YYYY HH:mm:ss");
  },
},
{
  headerName: "Marketing Actioned At",
  field: "marketing_approved_at",
  width: 250,
  filter: "agTextColumnFilter",
  unSortIcon: true,
  resizable: true,
  sortable: true,
  headerClass: "blue-header-bg",
  valueGetter: function (params) {
    if (params.data && params.data.marketing_approved_at) {
      return getBrowserTimeZone(params.data.marketing_approved_at);
      // return params.data.marketing_approved_at
    }
    return "-";
  },
},
{
  headerName: "DI Actioned By",
  field: "di_user",
  width: 250,
  filter: "agTextColumnFilter",
  unSortIcon: true,
  resizable: true,
  sortable: true,
  headerClass: "blue-header-bg",
  valueGetter: function (params) {
    if (params.data && params.data.di_user) {
      return params.data.di_user;
    }
    return "-";
  },
},
{
  headerName: "DI Actioned At",
  field: "di_approved_at",
  width: 200,
  filter: "agTextColumnFilter",
  unSortIcon: true,
  resizable: true,
  sortable: true,
  headerClass: "blue-header-bg",
  valueGetter: function (params) {
    if (params.data && params.data.di_approved_at ) {
      return getBrowserTimeZone(params.data.di_approved_at);
      // return params.data.di_approved_at
    }
    return "-";
  },
  comparator: function (date1, date2) {
    return comparator(date1, date2, "MM-DD-YYYY HH:mm:ss");
  },
},
{ 
  headerName: "Marketing Delete comments", 
  field: "comment_mar",
  headerClass: "blue-header-bg",
  filter: "agTextColumnFilter",
  width: 230,
},
{ 
  headerName: "DI Delete comments", 
  field: "comment_di",
  headerClass: "blue-header-bg",
  filter: "agTextColumnFilter",
},



// { 
//   headerName: "Label", 
//   field: "vendor_label",
//   headerClass: "blue-header-bg",
//   filter: "agTextColumnFilter",
// },
// { 
//   headerName: "Sub Line", 
//   field: "subline",
//   headerClass: "blue-header-bg",
//   filter: "agTextColumnFilter",
// },
// { 
//   headerName: "UoM", 
//   field: "uom",
//   headerClass: "blue-header-bg",
//   filter: "agTextColumnFilter",
//   width: 100,
// },

// { 
//   headerName: "Offer type", 
//   field: "offer_type",
//   headerClass: "blue-header-bg",
//   filter: "agTextColumnFilter",
//   width: 250,
// },
// { 
//   headerName: "Old Promo Type", 
//   field: "old_promo_type",
//   headerClass: "blue-header-bg",
//   filter: "agTextColumnFilter",
//   width: 150,
// },
// { 
//   headerName: "New Promo Type", 
//   field: "promo_type",
//   headerClass: "blue-header-bg",
//   filter: "agTextColumnFilter",
//   width: 150,
// },
// { 
//   headerName: "Old Sign Shell", 
//   field: "old_sign_shell",
//   headerClass: "blue-header-bg",
//   filter: "agTextColumnFilter",
// },
// { 
//   headerName: "New Sign shell", 
//   field: "sign_shell",
//   headerClass: "blue-header-bg",
//   filter: "agTextColumnFilter",
// },


// { 
//   headerName: "New Offer Type", 
//   field: "offer_type",
//   headerClass: "blue-header-bg",
//   filter: "agTextColumnFilter",
//   width: 150,
// },

// { 
//   headerName: "DI notes", 
//   field: "note_di",
//   headerClass: "blue-header-bg",
//   filter: "agTextColumnFilter",
//   cellRenderer: "promoApprovalInputRenderer"
// },







  ]
}

// function hideColumn(params) {

//   const loggedUser = localStorage.getItem("role");
//   return loggedUser != '3' || loggedUser !='6' ? true : false
// }