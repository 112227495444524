import axios from "../../utils/axios/index";
import { config } from "../../utils/config";
// import { LOADER, GET_ERRORS } from "./types";

////////////////////////////////////////////////////////////////
///////api calls from adgroup maaster main page start////////////////
///////////////////////////////////////////////////////////////
export const adgroupMasterData = reqObject => async dispatch => {
  const response = await axios
    .post(`${config.baseUrl}/adgroup/activeAdgroups`, reqObject)
    .then(res => {
      return res;
    })
    .catch(err => {
      return err;
    });
  return response;
};

export const updateMasterAdgroup = reqObject => async dispatch => {
  const response = await axios
    .post(`${config.baseUrl}/adgroup/updateAdgroups`, reqObject)
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
  return response;
};

////////////////////////////////////////////////////////////////
///////api calls from adgroup maaster main page end////////////////
///////////////////////////////////////////////////////////////



////////////////////////////////////////////////////////////////
///////api calls from create-master-adgroup or////////
/////// edit-master-adgroup page start////////////////
///////////////////////////////////////////////////////////////
export const createMasterAdgroup = reqObject => async dispatch => {
  const response = await axios
    .post(`${config.baseUrl}/adgroup/createAdgroups`, reqObject)
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
  return response;
};

export const editMasterAdgroup = reqObject => async dispatch => {
  const response = await axios
    .post(`${config.baseUrl}/adgroup/editAdgroups`, reqObject)
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
  return response;
};


export const getIncludeExcludeData = reqObject => async dispatch => {
  const response = await axios
    .post(`${config.baseUrl}/adgroup/includeExcludeSkuList`, reqObject)
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
  return response;
};

////////////////////////////////////////////////////////////////
///////api calls from create-master-adgroup or////////
/////// edit-master-adgroup page end////////////////
///////////////////////////////////////////////////////////////