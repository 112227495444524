import { combineReducers } from 'redux';
import errorReducer from './error';
import calendarReducer from './calendar';
import adGroupReducer from './adgroup';
import simulationReducer from './simulationReducer';
import createPromo from './createPromoReducer';
import summaryReducer from './summaryReducer';
import validationData from './validationReducer';
import loginReducer from './loginReducer';
import shopDataReducer from "./shopDataReducer";
import compareReducer from "./compareReducer";
import userReducer from "./userReducer";
import dayLevelOfferReducer from "./dayLevelOfferReducer";
import eventMasterReducer from './eventMasterReducer';
import promoApprovalReducer from './promoApprovalReducer';
import reportsReducer from "./reportsReducer";

export default combineReducers({
  errors: errorReducer,
  calendar: calendarReducer,
  adGroupData: adGroupReducer,
  simulationData: simulationReducer,
  createPromoData: createPromo,
  summaryData: summaryReducer,
  validationDatas: validationData,
  userLoginDetails: loginReducer,
  shopDataReducer: shopDataReducer,
  compareReducer: compareReducer,
  userDataReducer: userReducer,
  dayLevelOffer: dayLevelOfferReducer,
  eventMaster: eventMasterReducer,
  promoApproval: promoApprovalReducer,
  reports: reportsReducer
});
