import React, { Component } from "react";
import Select from "react-select";

export class LYCellRenderer extends Component {
  state = {
    value: this.props.value,
    selectOptions : this.props.data.adgroupArray,
  };
 
  refresh(params) {
    this.setState({
      value: params.value,
    });
    return true;
  }
  componentDidUpdate(prevProps, prevState){
    if(prevProps.data.adgroupArray !== prevState.selectOptions){
      this.setState({selectOptions : this.props.data.adgroupArray});
    }
  }
  onChangeHandler = (val) => {
    let TyLyMapping = { TyAdgroup: this.props.data.ty_adgroup, ty_value : this.props.data.ty_value,
                        LyAdgroup: val.label, ly_value : val.value, 
                        ly_ad_desc: val.adgroup_desc  };
    this.props.onEditLYTYMapping(TyLyMapping);
    this.setState({
      value: val,
    });
  };
 
  render() {
    return (
      <>
          <Select
            className="reference-sku-select"
            placeholder={this.props.value}
            options={this.state.selectOptions}
            onChange={(val) => this.onChangeHandler(val)}
            onInputChange={e => this.props.onAdGroupSearch(e)}
            styles={{ menuPortal: (base) => ({ ...base, zIndex: 20002}) }}
            menuPortalTarget={document.body}
            menuPosition={'fixed'}
          />
      </>
    );
  }
}

export default LYCellRenderer;
