import axios from "../../utils/axios/index";
import { config } from "../../utils/config";
import {
  ADGROUP_DATA,
  GET_ERRORS,
  LOADER,
  GET_CAMPAIGN_DM,
  UPDATE_PRODUCTS_ADGROUP,
  PROMO_FILTERS_SHOP,
  PROMO_FILTERS_MERCH,
  PROMO_FILTERS_ADGROUP,
  PRODUCT_DETAILS_ID,
  SET_ALL_HIERARCHIES_DATA,SET_ALL_ATTRIBUTES_DATA
} from "./types";
import setAuthToken from "../../utils/setAuthToken";
import { sortBy } from "lodash";

const _ = require("lodash");

export const getAdgroupData = reqObject => async dispatch => {
  dispatch({
    type: LOADER,
    payload: true
  });
  let res = await axios.get(
    `${config.baseUrl}/adgroup/listadgroups?searchkey=${reqObject}`
  );
  // .then(res => {
  dispatch({
    type: ADGROUP_DATA,
    payload: res.data.data
  });
  dispatch({
    type: LOADER,
    payload: false
  });
  return res.data;
  // })
  // .catch(err => {
  //   dispatch({
  //     type: GET_ERRORS,
  //     payload: err
  //   });
  // });
};

export const createAdgroup = reqObject => async dispatch => {
  const response = await axios.post(
    `${config.baseUrl}/promo/create`,
    reqObject
  );

  return response.data;
};

export const createBulkAdgroup = reqObject => async dispatch => {
  const response = await axios.post(
    `${config.baseUrl}/promo/bulk/create`,
    reqObject
  );

  return response.data;
};

export const updateProductsWithAdgroup = reqObject => async dispatch => {
  dispatch({
    type: LOADER,
    payload: true
  });
  let res = await axios.post(
    `${config.baseUrl}/adgroup/adgroupproductlist`,
    reqObject
  );
  dispatch({
    type: UPDATE_PRODUCTS_ADGROUP,
    payload: res.data
  });
  dispatch({
    type: LOADER,
    payload: false
  });
  return res.data;
};

export const updateProductsWithAdgroupAsync = reqObject => async dispatch => {
  const response = await axios.post(
    `${config.baseUrl}/adgroup/adgroupproductlist`,
    reqObject
  );

  return response.data;
};

export const getPromoFiltersShop = reqObject => dispatch => {
  //Set token to Auth header

  axios
    .post(`${config.baseUrl}/promo/filters`, reqObject)
    .then(res => {
      dispatch({
        type: PROMO_FILTERS_SHOP,
        payload: res.data.data
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err
      });
    });
};

export const getPromoFiltersMerch = reqObject => dispatch => {
  dispatch({
    type: LOADER,
    payload: true
  });
  axios
    .post(`${config.baseUrl}/promo/filters`, reqObject)
    .then(res => {
      dispatch({
        type: PROMO_FILTERS_MERCH,
        payload: res.data.data
      });
      dispatch({
        type: LOADER,
        payload: false
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err
      });
    });
};

export const getPromoFiltersAdgroup = reqObject => dispatch => {
  dispatch({
    type: LOADER,
    payload: true
  });
  axios
    .post(`${config.baseUrl}/promo/filters`, reqObject)
    .then(res => {
      dispatch({
        type: PROMO_FILTERS_ADGROUP,
        payload: res.data.data
      });
      dispatch({
        type: LOADER,
        payload: false
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err
      });
    });
};

export const getProductDetailsFromPrompoId = reqObject => async dispatch => {
  dispatch({
    type: LOADER,
    payload: true
  });
  let response = axios
    .post(`${config.baseUrl}/promo/productDetailsOfPromo`, reqObject)
    .then(res => {
      dispatch({
        type: PRODUCT_DETAILS_ID,
        payload: res.data.data
      });
      dispatch({
        type: LOADER,
        payload: false
      });
      return res.data;
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err
      });
      return err;
    });
  return response;
};

export const getCampaignDm = reqObject => async dispatch => {
  dispatch({
    type: LOADER,
    payload: true
  });
  let res = await axios.post(
    `${config.baseUrl}/executive/campaignsList`,
    reqObject
  );
  //.then(res => {
  dispatch({
    type: GET_CAMPAIGN_DM,
    payload: res.data.data
  });
  dispatch({
    type: LOADER,
    payload: false
  });
  return res.data;
  //})
  //.catch(err => {
  // dispatch({
  //   type: GET_ERRORS,
  //   payload: err
  // });
  //});
};

export const deleteAdgroup = reqObject => async dispatch => {
  // const response = await axios.post(
  //   `${config.baseUrl}/promo/deletePromos`,
  //   reqObject
  // );
  const response = await axios.post(
    `${config.baseUrl}/adgroup/deleteAdgroups`,
    reqObject
  );

  return response.data;
};

export const adgroupListForCreate = reqObject => async dispatch => {
  dispatch({
    type: LOADER,
    payload: true
  });
  const response = await axios.post(
    `${config.baseUrl}/promo/listAdgroups`,
    reqObject
  );

  let data = response.data.data;
  data = _.sortBy(data, [(obj) => {
    return obj.name;
  }]);
  response.data.data = data;
  dispatch({
    type: LOADER,
    payload: false
  });
  return response.data;
};

export const getSKUDetailsFromAdgroupId = reqObject => async dispatch => {
  const response = await axios.post(
    `${config.baseUrl}/promo/skusList`,
    reqObject
  );

  return response.data;
};

export const getAdgroupDetailsFromId = reqObject => async dispatch => {
  const response = await axios.get(
    `${config.baseUrl}/adgroup/details/${reqObject}`,
    reqObject
  );

  return response.data;
};

export const saveAdgroupFromIntermediate = reqObject => async dispatch => {
  const response = await axios.post(
    `${config.baseUrl}/promo/multipleOffers`,
    reqObject
  );

  return response.data;
};

export const getPromosData = reqObject => async dispatch => {
  const response = await axios.post(
    `${config.baseUrl}/promo/getPromosData`,
    reqObject
  );
  return response.data;
} 

export const productListFilters = reqObject => async dispatch => {
  const response = await axios.post(
    `${config.baseUrl}/adgroup/productListFilters`,
    reqObject
  );

  return response.data;
};

export const getValidCollections = reqObject => async dispatch => {
  const response = await axios.get(
    `${config.baseUrl}/adgroup/getValidCollections`,
    reqObject
  );

  return response.data;
};

export const getProductListV2 = reqObject => async dispatch => {
  const response = await axios.post(
    `${config.baseUrl}/adgroup/getProductListV2`,
    reqObject
  );

  return response.data;
};

export const validateAdgroup = reqObject => async dispatch => {
  const response = await axios.post(
    `${config.baseUrl}/adgroup/validateAdgroup`,
    reqObject
  );

  return response.data;
};

export const createAdgroupV2 = reqObject => async dispatch => {
  const response = await axios
    .post(`${config.baseUrl}/adgroup/createAdgroupV2`, reqObject)
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
  return response;
};

export const getAdgroup = reqObject => async dispatch => {
  const response = await axios.post(
    `${config.baseUrl}/adgroup/getAdgroup`,
    reqObject
  );

  return response.data;
};

export const updateAdgroupV2 = reqObject => async dispatch => {
  const response = await axios
    .post(`${config.baseUrl}/adgroup/updateAdgroupV2`, reqObject)
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
  return response;
};

export const getSkuListFromSkuIds = reqObject => async dispatch => {
  const response = await axios.post(
    `${config.baseUrl}/adgroup/getSkuListFromSkuIds`,
    reqObject
  );

  return response.data;
};

export const fetchRegSalesPrices = reqObject => async dispatch => {

  const response = await axios
    .post(`${config.baseUrl}/editDM/getRegLowHigh`, reqObject)
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
  return response;
}


export const getAllHierarchies = reqObject => async dispatch => {
  dispatch({
    type: LOADER,
    payload: true
  });
  const response = await axios.get(
    `${config.baseUrl}/adgroup/getAllHierarchies`,
    reqObject
  );
  dispatch({
    type: SET_ALL_HIERARCHIES_DATA,
    payload: response.data.data
  });
  dispatch({
    type: LOADER,
    payload: false
  });

  return response.data;
};



export const getMerchIdHierarchyMapping = reqObject => async dispatch => {
  const response = await axios.post(
    `${config.baseUrl}/adgroup/getMerchIdHierarchyMapping`,
    reqObject
  );

  return response.data.data;
};


export const getAllAttributes = reqObject => async dispatch => {
  dispatch({
    type: LOADER,
    payload: true
  });
  const response = await axios.get(
    `${config.baseUrl}/adgroup/getAllAttributes`,
    reqObject
  );
  dispatch({
    type: SET_ALL_ATTRIBUTES_DATA,
    payload: response.data.data
  });
  dispatch({
    type: LOADER,
    payload: false
  });

  return response.data;
};

export const fetchRegPrices = reqObject => async dispatch => {
  dispatch({
    type: LOADER,
    payload: true
  });
  const response = await axios
    .post(`${config.baseUrl}/editDM/getRegPrice`, reqObject)
    .then(res => {
      dispatch({
        type: LOADER,
        payload: false
      });
      
      return res.data.data;
     
    })
    .catch(err => {
      dispatch({
        type: LOADER,
        payload: false,
      });
      return err;
    });
  return response;
}

export const getMerchDetails = payload => async dispatch => {
  dispatch({
    type: LOADER,
    payload: true,
  });

  const response = await axios
    .post(`${config.baseUrl}/promo/getMerchantDetails`, payload)
    .then(res => {

      dispatch({
        type: LOADER,
        payload: false,
      });
      return res.data;
    })
    .catch(err => {
      dispatch({
        type: LOADER,
        payload: false,
      });
      return err;
    });
  return response;
}


export const getLabelAndUOM = payload => async dispatch => {
  dispatch({
    type: LOADER,
    payload: true,
  });

  const response = await axios
    .get(`${config.baseUrl}/adgroup/getLabelAndUOM`, payload)
    .then(res => {

      dispatch({
        type: LOADER,
        payload: false,
      });
      return res.data;
    })
    .catch(err => {
      dispatch({
        type: LOADER,
        payload: false,
      });
      return err;
    });
  return response;
}

export const fetchPromoBuildStatus = reqObject => async dispatch => {
  dispatch({
    type: LOADER,
    payload: true
  });
  const response = await axios
    .post(`${config.baseUrl}/promo/getPromoBuildStatus`, reqObject)
    .then(res => {
      dispatch({
        type: LOADER,
        payload: false
      });
      
      return res.data.campaigns;
     
    })
    .catch(err => {
      dispatch({
        type: LOADER,
        payload: false,
      });
      return err;
    });
  return response;
}