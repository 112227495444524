import React, { Component } from "react";
import _ from "lodash";
import numeral from "numeral";

var fieldNames = {
  gm_override: ["total_override_margin", "total_override_sales"],
  total_override_sales: ["total_override_margin", "gm_override"],
  total_override_margin: ["gm_override", "total_override_sales"],
};
export class PipelineCellRenderer extends Component {
  state = { data: "", value: "" };

  componentDidMount() {
    this.valueUpdateHandler(this.props.colDef.field);
  }
  valueUpdateHandler = (field) => {
    let val =
      this.props.data[field] === 0 || this.props.data[field]
        ? this.props.data[field]
        : "";
    if (field === "gm_override") {
      let stringified = val !== "" ? val.toString().split(".") : [];
      if (
        stringified.length === 2 &&
        stringified[1] &&
        stringified[1].length > 2
      ) {
        val = parseFloat(val).toFixed(2);
      }
    } else {
      val = val !== "" ? "$ " + numeral(val).format("0,0") : "";
    }
    this.setState({
      value: val,
    });
  };
  refresh(params) {
    this.setState({ data: "" });
    return true;
  }
  onChangeHandler = (val, fieldName) => {
    if(fieldName === "gm_override" &&
    !this.props.data.total_override_units){
        return;
      }
    let value = val.target.value;
    let val1 =
      typeof value === "string" && value.includes("$")
        ? value.replace(/\$|\,/gi, "")
        : typeof value === "string" && value.includes("%")
        ? value.replace("%", "")
        : value;

    let parsedVal = val1;
    let stringified = value.toString();
    if (
      (fieldName === "gm_override" && stringified.split(".").length - 1 > 1) ||
      fieldName !== "gm_override"
    ) {
      parsedVal = parseFloat(val1);
    }
    
    this.props.data[fieldName] = parsedVal || parsedVal === 0 ? parsedVal : "";
    this.props.data.rowRefreshHandler(this.props.node.id);
    this.valueUpdateHandler(this.props.colDef.field);
  };

  render() {
    let disableHandler = (field) => {
      if (
        localStorage.getItem("role") === "3" ||
        localStorage.getItem("role") === "5"
      ) {
        return true;
      } else if (
        (this.props.data.total_override_margin ||
          this.props.data.total_override_margin === 0) &&
        (this.props.data.total_override_sales ||
          this.props.data.total_override_sales === 0) &&
        (this.props.data.gm_override || this.props.data.gm_override === 0)
      ) {
        return false;
      } else if (
        !_.isEmpty(fieldNames[field]) &&
        (this.props.data[fieldNames[field][0]] ||
          this.props.data[fieldNames[field][0]] === 0) &&
        (this.props.data[fieldNames[field][1]] ||
          this.props.data[fieldNames[field][1]] === 0)
      ) {
        return true;
      }
      return false;
    };
    let { value } = this.state;
    let stringVal = value || value === 0 ? value.toString() : "";
    let perSign = value || value === 0 ? stringVal.length * 8 + "px" : 0;

    return (
      <div className="pipeline-adgroup-add pipeline-cellrendrer">
        <span
          className={
            this.props.colDef.field === "gm_override" &&
            (value || value === 0) &&
            stringVal.length &&
            this.props.data.rank === "1" &&
            this.props.data.total_override_units
              ? "percentage-sign"
              : "d-none"
          }
          style={{
            left: perSign,
          }}
        >
          %
        </span>
        {this.props.data.rank === "1" ? <input
          value={
            this.props.colDef.field === "gm_override" &&
            (this.props.data.rank !== "1" ||
              !this.props.data.total_override_units)
              ? ""
              : value
          }
          disabled={
            this.props.data.rank !== "1" //||
            // (this.props.colDef.field === "gm_override" &&
            //   !this.props.data.total_override_units)
              ? true
              : disableHandler(this.props.colDef.field)
          }
          className="reference-sku-input mt-0 ml-2 LY-adgrp-addition"
          placeholder=""
          onChange={(e) => this.onChangeHandler(e, this.props.colDef.field)}
        /> : ''}
        
      </div>
    );
  }
}

export default PipelineCellRenderer;
