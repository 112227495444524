import React, { Component } from "react";
import Select from "react-select";
import { getProductDetailsFromPrompoId } from "../../../../redux/actions/adgroup";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import _ from "lodash";
import PageLoader from "../../../../utils/Loader";
import Modal from "react-awesome-modal";
import DataTable from "../../../tables";
import ColumnDef from "../../../tables/colDefs/SkuListColDefs";
import RefSkuChildMessageRendrer from "../ReferenceSku/RefSkuChildMessageRendrer";
import RefSkuActionRenderer from "../ReferenceSku/RefSkuActionRenferer";
import "../ReferenceSku/ReferenceSkuStyling.css";
import AlertSuccess from "../../../../utils/AlertMessage/alertSuccess";
import AlertDanger from "../../../../utils/AlertMessage/alertDanger";
import {
  editReferenceSku,
  infoReferenceSku,
  similarSkuProductsList
} from "../../../../redux/actions/referenceSkuActions";
import ReferenceSkuDetails from "../../../../utils/ReferenceSkuDetails";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import { PromotionCalendarGA } from '../../../../utils/GA/GAUtils';
import { alertTimer } from "../../../../utils/alertTimer";

export class SkuList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOption: null,
      adgroupListWithLabels: [],
      adgroupDetailsFromIntermediate: [],
      tabKey: "TY",
      selectedAdGrp: null,
      isLYActive: false,
      activePromoType: [],
      TYSkuListDownload: false,
      LYSkuListDownload: false,
      showDetailPopup: false,
      refSkuInfoResponse: "",
      selectRefSkuError: false,
      skuListLoader: true,
      refreshRows: false,
      refSkuOptions: [],
      overlayNoRowsTemplate:
        '<span style="padding: 10px; boxShadow: 0 1px 15px 1px rgba(62, 57, 107); color: #2d69eb;  border: 1px solid lightgray">No TY SKU</span>'
    };
  }

  async componentDidMount() {
    let refSkuSearchRes = await this.props.similarSkuProductsList({
      modelable_sku: true,
      search_key: ""
    });
    let list = _.map(refSkuSearchRes.data, item => {
      return {
        value: item.unique_id1,
        label: item.unique_id1
      };
    });
    list.unshift({ value: "none", label: "none" });

    this.setState({ refSkuOptions: list });

    let adgroupDetailsFromIntermediate = JSON.parse(
        localStorage.getItem("adgroupNamesForUrl")
      ),
      selectedAdgroupDetails = _.find(adgroupDetailsFromIntermediate, {
        adgroup_id: this.props.selectedAdgroup.adgroup_id
      });

    this.setState({
      adgroupListWithLabels: this.props.adgroupListWithLabels,
      // adgroupWithEventType: this.props.adgroupWithEventType,
      selectedAdGrp: this.props.selectedAdgroup,
      // adgroupSelected:  this.props.selectedAdgroup,
      isLYActive: false,
      activePromoType: this.props.activeEventType,
      adgroupDetailsFromIntermediate
    });

    if (this.props.fromPipelineIntermediate) {
      let response = await this.props.getProductDetailsFromPrompoId({
        flag: "Intermediate",
        adgroup_id: selectedAdgroupDetails.adgroup_id,
        adgroup_name: selectedAdgroupDetails.adgroup_name,
        event_type: selectedAdgroupDetails.ly_event_type,
        start_date: selectedAdgroupDetails.ly_start_date,
        end_date: selectedAdgroupDetails.ly_end_date
      });
    } else {
      let response = await this.props.getProductDetailsFromPrompoId({
        flag: "pipe_line",
        promo_id: this.props.activeEventType.promoId
          ? [this.props.activeEventType.promoId]
          : null
      });
    }
    this.setState({
      skuListLoader: false
    });
  }

  saveRefSkuDetail = async rowData => {
    let params = null;
    if (Object.keys(rowData).length) {
      this.setState({
        skuListLoader: true
      });
      params = {
        skuId: rowData.unique_id1,
        referenceSku: rowData.selected_sku === "none" ? null : rowData.selected_sku
      };
      let saveRefSkuResponse = await this.props.editReferenceSku(params);

      if (saveRefSkuResponse.status) {
        this.setState({
          skuListLoader: false,
          savedRefSkuMsg: true
        });

        setTimeout(() => {
          this.setState({
            savedRefSkuMsg: false
          });
        }, alertTimer);

        let findAdgroup = _.find(this.props.offerDetails, {
            promo_id: this.state.selectedAdGrp.promo_id
          }),
          selectedAdgroupDetails = _.find(
            this.state.adgroupDetailsFromIntermediate,
            {
              adgroup_id: this.state.selectedAdGrp.adgroup_id
            }
          );

        if (this.props.fromPipelineIntermediate) {
          let response = await this.props.getProductDetailsFromPrompoId({
            flag: "Intermediate",
            adgroup_id: selectedAdgroupDetails.adgroup_id,
            adgroup_name: selectedAdgroupDetails.adgroup_name,
            event_type: selectedAdgroupDetails.event_type,
            start_date: selectedAdgroupDetails.start_date,
            end_date: selectedAdgroupDetails.end_date
          });
        } else {
          let response = await this.props.getProductDetailsFromPrompoId({
            flag: "pipe_line",
            promo_id: findAdgroup.promo_id ? [findAdgroup.promo_id] : null
          });
        }
      }
    } else {
      this.setState({
        selectRefSkuError: true
      });

      setTimeout(() => {
        this.setState({
          selectRefSkuError: false
        });
      }, alertTimer);
    }
  };

  onAdgroupChange = async value => {
    this.submitGA("Click", "Selected_Simulation_Adgroup");
    let valueVar = value;
    let findAdgroup = _.find(this.props.offerDetails, {
        promo_id: valueVar.promo_id
      }),
      selectedAdgroupDetails = _.find(
        this.state.adgroupDetailsFromIntermediate,
        {
          adgroup_id: value.adgroup_id
        }
      );

    let activeEventOption = this.props.adgroupWithEventType[valueVar.value];

    if (this.props.fromPipelineIntermediate) {
      let response = await this.props.getProductDetailsFromPrompoId({
        flag: "Intermediate",
        adgroup_id: selectedAdgroupDetails.adgroup_id,
        adgroup_name: selectedAdgroupDetails.adgroup_name,
        event_type: selectedAdgroupDetails.event_type,
        start_date: selectedAdgroupDetails.start_date,
        end_date: selectedAdgroupDetails.end_date
      });
    } else {
      let response = await this.props.getProductDetailsFromPrompoId({
        flag: "pipe_line",
        promo_id: findAdgroup.promo_id ? [findAdgroup.promo_id] : null
      });

      this.setState({
        activePromoType: activeEventOption[0]
      });
    }
    this.setState({
      selectedAdGrp: valueVar
    });
  };

  refSkuSearchHandler = async (key, id) => {
    let refSkuSearchRes = await this.props.similarSkuProductsList({
      modelable_sku: true,
      search_key: key
    });
    let list = _.map(refSkuSearchRes.data, item => {
      return {
        value: item.unique_id1,
        label: item.unique_id1
      };
    });
    list.unshift({ value: "none", label: "none" });

    this.setState({ refSkuOptions: list, refreshRows: true });
  };
  refreshRowsHandler = () => {
    this.setState({ refreshRows: false });
  };

  promoTypeHandler = async value => {
    this.submitGA("Click", "Selected_Simulation_Promotype");
    let valueVar = value;
    let findAdgroup = _.find(this.props.offerDetails, {
      promo_id: valueVar.promoId
    });
    let response = await this.props.getProductDetailsFromPrompoId({
      flag: "pipe_line",
      promo_id: findAdgroup.promo_id ? [findAdgroup.promo_id] : null
    });

    if (!response.status) {
      return;
    }
    this.setState({
      activePromoType: [valueVar]
    });
  };

  clickViewCalendar = () => {
    this.props.history.push("/promotion");
  };

  tabContent = (adgroupTableProducts, index, name) => {
    return (
      <div
        id={`agGridTableskuList${index}`}
        className="card reference-sku-table-cont"
        style={{ marginRight: "21px", marginLeft: "21px" }}
      >
        <DataTable
          index={index}
          tableData={adgroupTableProducts}
          overlayNoRowsTemplate={this.state.overlayNoRowsTemplate}
          thisVar={this}
          // autoSizeCol={true} //col messed up if uncommented
          viewSkuListDownload={
            name === "This_Year_SKU_Data"
              ? this.state.TYSkuListDownload
              : this.state.LYSkuListDownload
          }
          downloadName={name}
          viewSkuListDownloadHandler={this.skuListDownloadHandlerTable}
          refreshRows={this.state.refreshRows}
          refreshRowsHandler={this.refreshRowsHandler}
          frameworkComponents={{
            refSkuChildMessageRenderer: RefSkuChildMessageRendrer,
            refSkuActionRenderer: RefSkuActionRenderer
          }}
        />
      </div>
    );
  };
  viewSkuListDownloadHandler = () => {
    this.setState({
      TYSkuListDownload: this.state.tabKey === "TY" ? true : false,
      LYSkuListDownload: this.state.tabKey === "LY" ? true : false
    });
    this.submitGA("Click", "Clicked_Simulation_SKUListDownloadButton");
  };
  skuListDownloadHandlerTable = () => {
    this.setState({
      TYSkuListDownload: false,
      LYSkuListDownload: false
    });
  };

  tabHandler = tabKey => {
    this.setState({ tabKey });
    if (tabKey === "LY") {
      this.setState({ isLYActive: true });
      //this.props.isLyActiveHandler(true); LY Simulation code DO NOT DELETE
    } else if (tabKey === "TY") {
      this.setState({ isLYActive: false });
      //this.props.isLyActiveHandler(false); LY Simulation code DO NOT DELETE
    }
  };
  submitGA(action,label){
    PromotionCalendarGA({ action, label});
  }
  render() {
    let cyTableDataArr = [],
      lyTableDataArr = [];
    let TYfootnote =
      this.props.getProductDetailsPromoId &&
      this.props.getProductDetailsPromoId.cy &&
      this.props.getProductDetailsPromoId.cy.length &&
      this.props.getProductDetailsPromoId.cy[0].footnote != null ? (
        <div className="card">
          <p className="footnotSKuList">
            <i className="fa fa-exclamation-triangle" />
            &nbsp;{this.props.getProductDetailsPromoId.cy[0].footnote}
          </p>
        </div>
      ) : (
        ""
      );

    let LYfootnote =
      this.props.getProductDetailsPromoId &&
      this.props.getProductDetailsPromoId.ly &&
      this.props.getProductDetailsPromoId.ly.length &&
      this.props.getProductDetailsPromoId.ly[0].footnote != null ? (
        <div
          className="card"
          style={{ height: "40px", marginTop: "5px", paddingLeft: "16px" }}
        >
          <p className="footnotSKuList">
            <i className="fa fa-exclamation-triangle" />
            &nbsp;{this.props.getProductDetailsPromoId.ly[0].footnote}
          </p>
        </div>
      ) : (
        ""
      );

    if (
      this.props.getProductDetailsPromoId &&
      this.props.getProductDetailsPromoId.cy
    ) {
      cyTableDataArr = this.props.getProductDetailsPromoId.cy.map(item => {
        item.saveRefSkuDetail = this.saveRefSkuDetail;
        item.refSkuSearchHandler = this.refSkuSearchHandler;
        item.refSkuOptions = this.state.refSkuOptions;
        item.original_reference_sku= item.reference_sku;
        return item;
      });
    }

    if (
      this.props.getProductDetailsPromoId &&
      this.props.getProductDetailsPromoId.ly
    ) {
      lyTableDataArr = this.props.getProductDetailsPromoId.ly.map(item => {
        item.saveRefSkuDetail = this.saveRefSkuDetail;
        item.refSkuSearchHandler = this.refSkuSearchHandler;
        item.refSkuOptions = this.state.refSkuOptions;
        item.original_reference_sku= item.reference_sku;
        return item;
      });
    }

    var adgroupTableProductsTY = {
      header: ColumnDef.skuHeader,
      defaultColDef: ColumnDef.defaultColDef,
      data:
        this.props.getProductDetailsPromoId &&
        this.props.getProductDetailsPromoId.cy
          ? cyTableDataArr
          : []
    };
    var adgroupTableProductsLY = {
      header: ColumnDef.skuHeader,
      defaultColDef: ColumnDef.defaultColDef,
      data:
        this.props.getProductDetailsPromoId &&
        this.props.getProductDetailsPromoId.ly
          ? lyTableDataArr
          : []
    };

    var refSkuDetailTableData = {
      header: ColumnDef.skuHeader,
      defaultColDef: ColumnDef.defaultColDef,
      data: this.state.refSkuInfoResponse
    };

    return (
      <div>
        <div className="alertDivs">
          {this.state.savedRefSkuMsg ? (
            <div>
              <AlertSuccess
                message="Reference SKU details saved!"
                show={this.state.savedRefSkuMsg}
              />
            </div>
          ) : null}

          {this.state.selectRefSkuError ? (
            <div>
              <AlertDanger
                message="Please select Reference SKU to proceed"
                show={this.state.selectRefSkuError}
              />
            </div>
          ) : null}
        </div>

        <Modal
          visible={this.props.showSkuList}
          width="90%"
          height="90%"
          effect="fadeInDown"
          onClickAway={() => this.props.showSkuListHandler(false)}
        >
          <div className="closeicon">
            <span style={{ cursor: "pointer" }}>
              <i
                className="fa fa-times"
                aria-hidden="true"
                onClick={() => this.props.showSkuListHandler()}
              />
            </span>
          </div>
          <div>
            <div className="createClusterDiv">
              <h4 className="promoHeading">SKU List</h4>
              <div className="row skuAdgroup">
                <div className="col-xs-2">
                  <p className="labelAdGroupsku">AdGroup</p>
                </div>

                <div className=" col-md-2">
                  <Select
                    options={this.state.adgroupListWithLabels}
                    onChange={value => this.onAdgroupChange(value)}
                    value={this.state.selectedAdGrp}
                  />
                </div>
                {this.props.fromPipelineIntermediate ? null : (
                  <>
                    <div className="col-xs-2">
                      <p className="labelAdGroupsku">Promo Type</p>
                    </div>
                    <div className="col-md-2">
                      <Select
                        options={
                          this.state.selectedAdGrp
                            ? this.props.adgroupWithEventType[
                                this.state.selectedAdGrp.value
                              ]
                            : []
                        }
                        onChange={value => this.promoTypeHandler(value)}
                        value={this.state.activePromoType}
                      />
                    </div>
                  </>
                )}
              </div>
              <div className="card viewSkuDiv mt-2">
                <div className="view-sku-list-tab">
                  <PageLoader
                    loader={this.props.loader || this.state.skuListLoader}
                  >
                    <div className="sku-list-download">
                      
                      <button
                        type="button"
                        title="Download"
                        className="btn btnSaveProducts"
                        disabled={
                          this.state.isLYActive &&
                          adgroupTableProductsLY.data.length
                            ? false
                            : !this.state.isLYActive &&
                              adgroupTableProductsTY.data.length
                            ? false
                            : true
                        }
                        onClick={() => this.viewSkuListDownloadHandler()}
                      >
                        <i className="fa fa-download" aria-hidden="true" />
                      </button>
                    </div>
                    <Tabs
                      id="view-sku-list-tab"
                      activeKey={this.state.tabKey}
                      onSelect={key => this.tabHandler(key)}
                    >
                      <Tab eventKey="TY" title="TY">
                        {this.tabContent(
                          adgroupTableProductsTY,
                          1,
                          "This_Year_SKU_Data"
                        )}
                      </Tab>
                    </Tabs>

                    <div className="row">
                      <div className="col-md-7 footnoteSku">
                        {this.state.isLYActive ? LYfootnote : TYfootnote}
                      </div>
                      <div
                        className="col-md-2"
                        style={{ marginLeft: "75px" }}
                      />
                    </div>

                    {this.state.showDetailPopup ? (
                      <Modal
                        visible={true}
                        width="1000px"
                        height="auto"
                        effect="fadeInDown"
                        onClickAway={() => {
                          this.setState({
                            showDetailPopup: !this.state.showDetailPopup
                          });
                        }}
                      >
                        <PageLoader
                          loader={!this.state.refSkuInfoResponse.length}
                        >
                          <div className="closeicon">
                            <span style={{ cursor: "pointer" }}>
                              <i
                                className="fa fa-times"
                                aria-hidden="true"
                                onClick={() =>
                                  this.setState({ showDetailPopup: false })
                                }
                              />
                            </span>
                          </div>
                          <h5 className="text-center font-weight-bold mt-1">
                            Reference SKU details
                          </h5>
                          <ReferenceSkuDetails
                            infoRefSkuData={refSkuDetailTableData}
                          />
                        </PageLoader>
                      </Modal>
                    ) : null}
                  </PageLoader>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

SkuList.propTypes = {
  getProductDetailsFromPrompoId: PropTypes.func.isRequired,
  editReferenceSku: PropTypes.func.isRequired,
  similarSkuProductsList: PropTypes.func.isRequired,
  infoReferenceSku: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  getProductDetailsPromoId: state.adGroupData.getProductDetailsPromoId,
  loader: state.simulationData.loader
});

export default connect(mapStateToProps, {
  getProductDetailsFromPrompoId,
  editReferenceSku,
  infoReferenceSku,
  similarSkuProductsList
})(withRouter(SkuList));
