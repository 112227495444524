import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Modal from "react-awesome-modal";
import _ from "lodash";

import PageLoader from "../../../../utils/Loader";
import AlertDanger from "../../../../utils/AlertMessage/alertDanger";
import AlertSuccess from "../../../../utils/AlertMessage/alertSuccess";

import ColumnDef from "../../../tables/colDefs/PromoApprovalColDef";
import DataTable from "../../../tables";

import {
  getPromoApprovalData,
  updatePromoApprovalStatus,
  updateNotes,
  downloadReport
} from "../../../../redux/actions/promoApprovalAction";

import FilterPromoApproval from "./filterPromoApproval";
import PromoActionRenderer from "./promoApprovalActionRenderer";
import PromoApprovalModal from "./promoApprovalModal";
import PromoApprovalNotesModal from "./PromoApprovalNotesModal";
import PromoApprovalEditModal from "./PromoApprovalEditModal";
import PromoApprovalInputRenderer from "./promoApprovalnputRenderer";
import PromoEditRenderer from "./promoApprovalEditRenderer";
import "./promoApproval.css";

import { Button } from 'antd';
import { element } from "prop-types";
import { getClientTimezone } from "../../../../utils/valueFormatters";
import { config } from "../../../../utils/config";
import { getFilters, getSorting } from "./constants";
import { alertTimer } from "../../../../utils/alertTimer";

class PromoApproval extends Component {

  constructor(props) {
    super(props);
    this.state = {
      showLoader: false,
      isSuccess: false,
      successMessage: '',
      isFailure: false,
      failureMessage: "",
      tableData: {},
      showConfirmModal: false,
      showConfirmMsg: "",
      showCommentsModal: false,
      showNotesModal: false,
      showEditModal :  false,
      selectedNotesId : null,
      agGridProps : null,
      selectedGridDataForEdit : null,
      selectedPromo: {},
      updatedStatus: '',
      selectedFilters: {},
      page: 1,
      limit: 50,
      total_records: 0,
      filterButtonClicked: false,
      promoApprovalDataLength: 0,
      gridApi: null,
      gridColumnApi: null,
      actionClicked: false,
      rowIndex: "",
      gridParams: null,
      propsData : null
    }
    this.selectedPromoRef = React.createRef()
  }

  async componentDidMount() {

  }

  handleApproveReject = (approveStatus, rowData, index) => {
    let msg = ''
    if (approveStatus == "REJECTED") {
      msg = 'Do you want to reject the change request?'
    } else if (approveStatus == "ACCEPTED") {
      msg = 'Do you want to approve the change request?'
    }

    if (msg.length > 0) {
      this.setState({
        showConfirmModal: true,
        showConfirmMsg: msg,
        selectedPromo: _.cloneDeep(rowData),
        updatedStatus: approveStatus,
        rowIndex: index
      });
      this.selectedPromoRef.current = _.cloneDeep(rowData);
    }
  };

  isSelectAllSelected = (data) => {
    return data.some(item => item.value === "all");
  }

  handleNotesUpdateModal = async (flag,agGridProps,data) => {
    this.setState({
      showNotesModal : flag,
      selectedNotesId: data,
       agGridProps : agGridProps
    })
}

handleEditUpdateModal = async (flag, selectedGridData) => {
  this.setState({showEditModal : flag, selectedGridDataForEdit : selectedGridData})
}

  handleNotesUpdate = async (data, rowData) => {
    const reqObj = {
      id: rowData.id,
      note: data
    }

    const result = await this.props.updateNotes(reqObj)

    if (result.status) {
      this.setState({
        isSuccess: true,
        successMessage: "Notes updated successfully"
      });

      setTimeout(() => {
        this.setState({
          isSuccess: false,
          successMessage: ""
        });
      }, alertTimer);
    } else {
      this.setState({
        isFailure: true,
        failureMessage: "Notes updated failed"
      });

      setTimeout(() => {
        this.setState({
          isFailure: false,
          failureMessage: ""
        });
      }, alertTimer);
    }
  }

  rowRefreshHandler = (index) => {
    this.setState({
      actionClicked: !this.state.actionClicked,
      rowIndex: index,
    });
  };

  handleFilter = async (filters) => {

    const reqObj = {};

    // console.log('Handle filter called: ', filters);

    const newFilter = {
      buyerId: [],
      campaignIds: [],
      status: []
    };



    if (filters.buyerId && filters.buyerId.length) {
      if (typeof filters.buyerId[0] == 'object') newFilter.buyerId = filters.buyerId.map(d => d.value);
      if (newFilter.buyerId.includes('all')) newFilter.buyerId = [];
    }

    if (filters.campaignIds && filters.campaignIds.length) {
      newFilter.campaignIds = filters.campaignIds.map(d => d.value);
      if (newFilter.campaignIds.includes('all')) newFilter.campaignIds = [];
    }

    if (filters.status && filters.status.length) {
      newFilter.status = filters.status.map(d => d.value);
      if (newFilter.status.includes('all')) newFilter.status = [];
    }

    // console.log('New filter called: ', newFilter);

    // Object.keys(newFilter).map(key => {
    //   const isSelectAll = this.isSelectAllSelected(filters[key])

    //   reqObj[key] = isSelectAll ? [] : _.cloneDeep(filters[key]);
    // })
    const promoApprovalData = await this.props.getPromoApprovalData(newFilter);

    const updatedPromoApprovalData = promoApprovalData.data.map(promo => {
      promo.handleApproveReject = this.handleApproveReject;
      promo.handleNotesUpdate = this.handleNotesUpdate;
      promo.handleNotesUpdateModal = this.handleNotesUpdateModal;
      promo.handleEditUpdateModal = this.handleEditUpdateModal;
      promo.status = localStorage.getItem("role") == "3" ? promo.status_mar : localStorage.getItem("role") == "6" ? promo.status_di : "PENDING"
      return promo;
    })

    let tableDataObj = {
      header: ColumnDef.promoApprovalHeader,
      defaultColDef: ColumnDef.defaultColDef,
      data: updatedPromoApprovalData,
    };


    this.setState({
      // tableData: _.cloneDeep(tableDataObj),
      selectedFilters: _.cloneDeep(filters)
    })
  }

  handleFilterButtonClick = (filters) => {
    if (this.state.filterButtonClicked) {
      this.setState({ filterButtonClicked: false, page: 1 }, () => {
        this.setState({ filterButtonClicked: true, page: 1, selectedFilters: _.cloneDeep(filters) }, () => {
        });
      });
    } else {
      this.setState({ filterButtonClicked: true, page: 1, selectedFilters: _.cloneDeep(filters) }, () => {
      });
    }

  };


  handleConfirm = () => {
    if (this.state.updatedStatus == 'ACCEPTED') {
      // call approve api
      console.log(this.state.selectedPromo);
      const comment = null;
      this.handleApproveRejectConfirm(comment)
    } else if (this.state.updatedStatus == 'REJECTED') {
      this.setState({
        showCommentsModal: true
      })
    }

    this.setState({
      showConfirmModal: false,
      showConfirmMsg: ''
    })
  }

  closeModal = () => {
    this.setState({
      showConfirmModal: false,
      showConfirmMsg: '',
      updatedStatus: '',
      selectedPromo: {},
      showCommentsModal: false
    })
  }

  notesCloseModal = () => {
    this.setState({
     showNotesModal : false
    })
  }

  editCloseModal = () => {
    this.setState({showEditModal : false})
  }

  handleReset = () => {
    this.setState({
      tableData: {},
      filterButtonClicked: false
    })
  }

  handleApproveRejectConfirm = async (data) => {
    // call reject API
    const reqObj = {
      id: this.state.selectedPromo.id,
      status: this.state.updatedStatus,
      comment: data
    }

    const updateRes = await this.props.updatePromoApprovalStatus(reqObj);

    const params = this.state.gridParams

    if (updateRes.status) {
      this.setState({
        isSuccess: true,
        successMessage: updateRes.msg
      });

      params.api.forEachNode(node => {
        if(this.state.selectedPromo.id === node.data.id){
            node.updateData({ ...updateRes.data })
          }
      });

      setTimeout(async () => {
        this.setState({
          isSuccess: false,
          successMessage: ""
        });
        const dataSource = {
          getRows: async (params) => {
            // Use startRow and endRow for sending pagination to Backend
            // params.startRow : Start Page
            // params.endRow : End Page
            let newFilter = this.getReqParams(this.state.selectedFilters)
            // newFilter.page = params.endRow / this.state.limit;
            newFilter.page = this.state.page;
            newFilter.sorting = getSorting(params.sortModel)
            newFilter.filters = getFilters(params.filterModel)
            const promoApprovalData = await this.props.getPromoApprovalData({ ...newFilter });
            const updatedPromoApprovalData = promoApprovalData.data.map((promo) => {
              promo.handleApproveReject = this.handleApproveReject;
              promo.handleNotesUpdate = this.handleNotesUpdate;
              promo.handleNotesUpdateModal = this.handleNotesUpdateModal;
              promo.handleEditUpdateModal = this.handleEditUpdateModal;
              promo.status =
                localStorage.getItem("role") == "3"
                  ? promo.status_mar
                  : localStorage.getItem("role") == "6"
                    ? promo.status_di
                    : "PENDING";
              return promo;
            });
            params.successCallback(updatedPromoApprovalData, promoApprovalData.total_records)
          },
        };
        
        // await params.api.setDatasource(dataSource);

        // setTimeout(()=> {
        //   params.api.paginationGoToPage(this.state.page)
        // }, 1000)
      }, 5000);
    } else {
      this.setState({
        isFailure: true,
        failureMessage: updateRes.msg
      });

      setTimeout(() => {
        this.setState({
          isFailure: false,
          failureMessage: ""
        });
      }, alertTimer);
    }

    this.setState({
      showCommentsModal: false,
      showTextArea: false
    })

  }

  refreshComponent = () => {
    if (this.state.gridParams) {
      this.onGridReady(this.state.gridParams);
    }
    this.editCloseModal();
  }

  getObjValues = data => {
    let valuesArray = data.filter(ele => ele.value != 'all').map(element => element.value);

    return valuesArray;
  }

  handleDownload = async () => {
    const tempFilters = _.cloneDeep(this.state.selectedFilters);

    console.log("***************tempFilters", tempFilters);

    const reqObj = {
      buyerId: tempFilters.buyerId ? this.getObjValues(tempFilters.buyerId) : [],
      campaignIds: tempFilters.campaignIds ? this.getObjValues(tempFilters.campaignIds) : [],
      status: tempFilters.status ? this.getObjValues(tempFilters.status) : [],
      user_time_zone: getClientTimezone()
    }

    const result = await this.props.downloadReport(reqObj);

    window.open(result.downloadUrl, '_self');

  }

  getReqParams = (filters) => {
    const reqObj = {};

    console.log("Requested Params", this.state.selectedFilters)
    const newFilter = {
      buyerId: [],
      campaignIds: [],
      status: [],
      page: this.state.page || 1,
      limit: this.state.limit,
      total_records: this.state.total_records == 0 ? 0 : this.state.total_records
    };


    if (this.state.selectedFilters.buyerId && this.state.selectedFilters.buyerId.length) {
      if (typeof this.state.selectedFilters.buyerId[0] == "object")
        newFilter.buyerId = this.state.selectedFilters.buyerId.map((d) => d.value);
      if (newFilter.buyerId.includes("all")) newFilter.buyerId = [];
    }


    if (this.state.selectedFilters.campaignIds && this.state.selectedFilters.campaignIds.length) {
      newFilter.campaignIds = this.state.selectedFilters.campaignIds.map((d) => d.value);
      if (newFilter.campaignIds.includes("all")) newFilter.campaignIds = [];
    }


    if (this.state.selectedFilters.status && this.state.selectedFilters.status.length) {
      newFilter.status = this.state.selectedFilters.status.map((d) => d.value);
      if (newFilter.status.includes("all")) newFilter.status = [];
    }

    return newFilter;
  };

  onGridReady = async (params) => {
    const dataSource = {
      getRows: async (params) => {
        // Use startRow and endRow for sending pagination to Backend
        // params.startRow : Start Page
        // params.endRow : End Page
        let newFilter = this.getReqParams(this.state.selectedFilters)
        newFilter.page = params.endRow / this.state.limit;
        newFilter.sorting = getSorting(params.sortModel)
        newFilter.filters = getFilters(params.filterModel)
        const promoApprovalData = await this.props.getPromoApprovalData({ ...newFilter });
        const updatedPromoApprovalData = promoApprovalData.data.map((promo) => {
          promo.handleApproveReject = this.handleApproveReject;
          promo.handleNotesUpdate = this.handleNotesUpdate;
          promo.handleNotesUpdateModal = this.handleNotesUpdateModal;
          promo.handleEditUpdateModal = this.handleEditUpdateModal;
          promo.status =
            localStorage.getItem("role") == "3"
              ? promo.status_mar
              : localStorage.getItem("role") == "6"
                ? promo.status_di
                : "PENDING";
          return promo;
        });
        
        this.setState({
          page: params.endRow / this.state.limit,
          total_records : newFilter.page === 1 ? 0 : promoApprovalData.total_records
        })
        params.successCallback(updatedPromoApprovalData, promoApprovalData.total_records)
      },
    };
    this.setState({
      gridApi: params.api,
      gridColumnApi: params.columnApi,
      gridParams: params,    
    })
    await params.api.setDatasource(dataSource);
  }


  render() {
    return (
      <PageLoader loader={this.props.loader} className="promoApproval">
        <div className="alertDivs">
          {this.state.isFailure &&
            <AlertDanger
              message={this.state.failureMessage}
              show={this.state.isFailure}
            />
          }
          {this.state.isSuccess &&
            <AlertSuccess
              message={this.state.successMessage}
              show={this.state.isSuccess}
            />
          }
        </div>

        {/* <Modal visible={this.state.showConfirmModal} width="500px" height="200px" effect="fadeInUp" onClickAway={() => this.closeModal()}>
            <div className="modalContent">
              <h5>{this.state.showConfirmMsg}</h5>
              <div className="modalButtoncontainer">
                <Button
                  // type="link"
                  onClick={() => this.closeModal()}
                  style={{
                    marginRight: "15px"
                  }}
                >
                  No
                </Button>
                <Button
                  type="primary"
                  onClick={this.handleConfirm}
                >
                  Yes
                </Button>
              </div>
            </div>

          </Modal> */}

        <PromoApprovalModal
          showModal={this.state.showConfirmModal}
          showMsg={this.state.showConfirmMsg}
          closeModal={this.closeModal}
          handleConfirm={this.handleConfirm}
          propsData = {this.selectedPromoRef.current}
        />

        <PromoApprovalModal
          showModal={this.state.showCommentsModal}
          showMsg={"Reason for rejection"}
          closeModal={this.closeModal}
          handleConfirm={this.handleApproveRejectConfirm}
          showTextArea={true}
        />
      {this.state.showNotesModal &&
        <PromoApprovalNotesModal
          showModal={this.state.showNotesModal}
          showMsg={"Notes"}
          notesCloseModal={this.notesCloseModal}
          saveModal = {this.saveModal}
          selectedNotesId={this.state.selectedNotesId} 
          agGridProps = {this.state.agGridProps}
        />
     }

      {this.state.showEditModal && 
          <PromoApprovalEditModal 
          showEditModal = {this.state.showEditModal}
          selectedGridDataForEdit = {this.state.selectedGridDataForEdit}
          editCloseModal = {this.editCloseModal}
          onSuccessfulEdit = {this.refreshComponent}
          />
      }

        <div className="card cardStyles cardGap">
          <div className="px-4 mb-4">
            <div className="createClusterDiv">
              <div className="row mt-4 mx-0">
                <div className="col text-center">
                  <h4 className="promoHeading mb-0">Promo Approval</h4>
                </div>
              </div>
              <div className="promoApprovalContainer">
                <FilterPromoApproval
                  // handleFilter={this.handleFilter}
                  handleFilterButtonClick={this.handleFilterButtonClick}
                  handleReset={this.handleReset}
                />
                <hr style={{ height: '1px', borderWidth: '0', backgroundColor: '#d3d3d3' }} />
                {
                  // this.state.tableData.data && this.state.tableData.data.length > 0 &&
                  this.state.filterButtonClicked &&
                  <>
                    <div className="actionBtns">
                      <button
                        type="button"
                        title="Download"
                        className="btn pipeline-button-class mr-1 pipeline-btn-styling"
                        onClick={e => {
                          this.handleDownload();
                        }}
                      >
                        <i className="fa fa-download" aria-hidden="true" />
                      </button>
                    </div>
                    <div>
                      <DataTable
                        // tableData={this.state.tableData}
                        tableData={{
                          header: ColumnDef.promoApprovalHeader,
                          defaultColDef: ColumnDef.defaultColDef
                        }}
                        suppressRowClickSelection={true}
                        frameworkComponents={{
                          promoApprovalActionRenderer: PromoActionRenderer,
                          promoApprovalInputRenderer: PromoApprovalInputRenderer,
                          promoApprovalEditRenderer: PromoEditRenderer
                        }}
                        pagination={true}
                        paginationPageSize={this.state.limit}
                        cacheBlockSize={this.state.limit}
                        rowModelType={'infinite'}
                        onGridReady={this.onGridReady}
                        actionClicked={this.state.actionClicked}
                        rowRefreshHandler={this.rowRefreshHandler}
                        rowIndex={this.state.rowIndex}
                      />
                    </div>
                  </>
                }
              </div>
            </div>
          </div>
        </div>

      </PageLoader>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    loader: state.promoApproval.loader,
    promoApprovalData: state.promoApproval.promoApprovalData,
  };
};

export default connect(
  mapStateToProps,
  {
    getPromoApprovalData,
    updatePromoApprovalStatus,
    updateNotes,
    downloadReport
  }
)(PromoApproval);