export const ticketStatusCodes = {
  10: { value: 'New', color: '#81BEF7' },
  20: { value: 'In Progress', color: '#6CA669' },
  30: { value: 'On Hold', color: '#FA9863' },
  40: { value: 'Information Requested', color: '#CEA0CC' },
  50: { value: 'Solved', color: '#989995' },
  60: { value: 'Closed', color: '#222' },
};

export const ticketPriorityCodes = {
  10: { label: 'Emergency', value: 10, color: '#d9534f' },
  20: { label: 'Urgent', value: 20, color: '#f0ad4e' },
  30: { label: 'Normal', value: 30, color: '#337ab7' },
  40: { label: 'Low', value: 40, color: '#c8c8c8' },
};

export const ticketTypes = {
  132242: { label: 'Issue', value: 132242 },
  132241: { label: 'Request', value: 132241 },
};
