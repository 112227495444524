import React, { Component } from "react";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";

export class MultiSelect extends Component {

  render() {
    return (
      <ReactMultiSelectCheckboxes
        placeholderButtonLabel={this.props.placeHolder}
        options={this.props.optionsArray}
        isDisabled={this.props.dropdownDisabled}
        onChange={this.props.onDropDownChange}
        onKeyDown={this.props.onKeyDown}
        value={this.props.dropdownValue}
        onMenuClose={this.props.onClose}
      />
    );
  }
}

export default MultiSelect;
