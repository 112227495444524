import React, { Component } from "react";
import Select from "react-select";
import offerTypeOptions from "../../../../utils/OfferTypes";
import { PromotionCalendarGA } from '../../../../utils/GA/GAUtils';

export class OfferBuilder extends Component {
  state = {
    offerType: "",
    offerVal: "",
    fixedPriceQty: "",
    minQty: "",
    bonusBuy: "",
    buyPercent: "",
    getPercent: "",
    disableOfferAmt: true,
    disableFixedQty: true,
    disableMinQty: true,
    disableBonusBuy: true,
    disableBuyPercent: true,
    disableGetPercent: true,
  };
  offerTypeHandler = (val) => {
    this.setState({
      offerType: val,
      offerVal: "",
      fixedPriceQty: "",
      minQty: "",
      bonusBuy: "",
      buyPercent: "",
      getPercent: "",
    });

    if (val.value === "P" || val.value === "A") {
      this.disableHandler(false, true, true, true, true, true);
    } else if (val.value === "F") {
      this.disableHandler(false, false, true, true, true, true);
    } else if (val.value === "G") {
      this.disableHandler(true, true, false, false, false, false);
    } else if (val.value === "B") {
      this.disableHandler(false, true, false, false, true, true);
    } else if (val.value === "C") {
      this.disableHandler(false, true, false, false, false, false);
    } else {
      this.disableHandler(false, true, false, true, true, true);
    }
  };

  disableHandler(
    disableOfferAmt,
    disableFixedQty,
    disableMinQty,
    disableBonusBuy,
    disableBuyPercent,
    disableGetPercent
  ) {
    this.setState({
      disableOfferAmt,
      disableFixedQty,
      disableMinQty,
      disableBonusBuy,
      disableBuyPercent,
      disableGetPercent,
    });
  }
  submitGA(action,label){
    PromotionCalendarGA({ action, label});
  }
  render() {
    let { simulationDataFromParent } = this.props;
    let msg =
      simulationDataFromParent && simulationDataFromParent.sales_index_msg
        ? simulationDataFromParent.sales_index_msg
        : null;
    return (
      <div className="row simulationCharts">
        <div className="col-md-12 offerBuilderDiv my-2">
          <h5 className="offerBuilderText">Offer Builder</h5>
          <div className="card">
            <div className="row CompareSelectSimulation p-3">
              <div className="col">
                <label className="labelOfferBuilder">Event Type</label>
                <div>
                  <input
                    className="offer-builder-input"
                    type="text"
                    title={
                      this.props.fromPipelineIntermediate
                        ? this.props.intermediateActiveEvent.label
                        : this.props.intermediateActiveEvent.eventType
                    }
                    disabled={true}
                    style={{ height: "34px", width: "100%" }}
                    value={
                      this.props.fromPipelineIntermediate
                        ? this.props.intermediateActiveEvent.label
                        : this.props.intermediateActiveEvent.eventType
                    }
                  />
                </div>
              </div>
              <div className="col">
                <label className="labelOfferBuilder">Planned Offer Type</label>
                <Select
                  placeholder=""
                  options={offerTypeOptions.newAdgroupOfferTypes}
                  onChange={(value) => {
                    this.offerTypeHandler(value)
                    this.submitGA("Click", "Clicked_OfferBuilder_PlannedOfferTypeButton");
                  }}
                  value={this.state.offerType}
                />
              </div>
              <div className="col">
                <label className="labelOfferBuilder">Planned Offer Amt</label>
                <input
                  className="offer-builder-input"
                  type="number"
                  value={this.state.offerVal}
                  disabled={this.state.disableOfferAmt}
                  onChange={(e) => {
                    let val = parseFloat(e.target.value);
                    let type = this.state.offerType.value;
                    if (
                      (type === "P" || type === "B" || type === "D") &&
                      val > 99
                    )
                      return;
                    else if (
                      (type === "F" || type === "A" || type === "C") &&
                      val > 99999
                    )
                      return;
                    this.setState({ offerVal: val.toString() });
                  }}
                />
              </div>
              <div className="col">
                <label className="labelOfferBuilder">Fixed Price Qty</label>
                <input
                  className="offer-builder-input"
                  type="number"
                  value={this.state.fixedPriceQty}
                  disabled={this.state.disableFixedQty}
                  onChange={(e) => {
                    let val = e.target.value;
                    if (val > 99) return;
                    this.setState({ fixedPriceQty: val });
                  }}
                />
              </div>
              <div className="col">
                <label className="labelOfferBuilder">Min Qty for Offer</label>
                <input
                  className="offer-builder-input"
                  type="number"
                  value={this.state.minQty}
                  disabled={this.state.disableMinQty}
                  onChange={(e) => {
                    let val = e.target.value;
                    if (val > 99) return;
                    this.setState({ minQty: val });
                  }}
                />
              </div>
              <div className="col">
                <label className="labelOfferBuilder">Bonus Buy</label>
                <input
                  className="offer-builder-input"
                  type="number"
                  value={this.state.bonusBuy}
                  disabled={this.state.disableBonusBuy}
                  onChange={(e) => {
                    let val = e.target.value;
                    if (val > 99) return;
                    this.setState({ bonusBuy: val });
                  }}
                />
              </div>
              <div className="col">
                <label className="labelOfferBuilder">
                  TY (Buy $) Min value
                </label>
                <input
                  className="offer-builder-input"
                  type="number"
                  value={this.state.buyPercent}
                  disabled={this.state.disableBuyPercent}
                  onChange={(e) => {
                    let val = e.target.value;
                    if (val > 99) return;
                    this.setState({ buyPercent: val });
                  }}
                />
              </div>
              <div className="col">
                <label className="labelOfferBuilder">
                  TY (Get $) Max value
                </label>
                <input
                  className="offer-builder-input"
                  type="number"
                  value={this.state.getPercent}
                  disabled={this.state.disableGetPercent}
                  onChange={(e) => {
                    let val = e.target.value;
                    if (val > 99) return;
                    this.setState({ getPercent: val });
                  }}
                />
              </div>
            </div>
            <div className="text-center mb-2">
              <button
                type="button"
                className="btn btn-outline-primary btnPromo btnaddPadding"
                disabled={this.state.compareBtnDisabled}
                onClick={() => {
                  this.props.addComparedValues(
                    this.state.offerType,
                    this.state.offerVal,
                    this.state.fixedPriceQty,
                    this.state.minQty,
                    this.state.bonusBuy,
                    this.state.buyPercent,
                    this.state.getPercent
                  );
                  this.submitGA("Click", "Clicked_OfferBuilder_AddButton");
                }}
              >
                Add
              </button>
            </div>
          </div>
          {msg ? (
            <div className="row mt-3">
              <div className=" col-md-10 simulationAddOfferOption tooltipDiv">
                <div className="footerTextSimulation">
                  <p className="mb-0 simulationFooterText">
                    <i className="fa fa-exclamation-triangle pr-1" />
                    {msg}
                  </p>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

export default OfferBuilder;
