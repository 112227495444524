export const STATUS_OPTIONS = [{
  label: "Select all",
  value: "all"
},
{
  label: "Approved",
  value: "ACCEPTED"
},
{
  label: "Rejected",
  value: "REJECTED"
},
{
  label: "Pending",
  value: "PENDING"
},]

export const getFilters = (filterModel)=>{
  const filterKeys = Object.keys(filterModel)
  let filterObj = {}
  filterKeys.forEach(key=>{
    filterObj[`${key}`] = filterModel[`${key}`]['filter']
  })

return filterObj
}

export const getSorting = (sortModel)=>{
  let sortingObj = {}
  sortModel.forEach(obj=>{
    sortingObj[`${obj.colId}`] = obj.sort
  })

return sortingObj
}