import {
  USER_LOGIN,
  LOADER,
  USER_LOGGED_OUT
} from "../actions/types";

const initialState = {
  isAuthenticated: false,
  userData: {},
  promoTypeData: [],
  loading: true
};

export default function (state = initialState, action) {
  switch (action.type) {
    case USER_LOGIN:
      return {
        ...state,
        isAuthenticated: true,
        userData: action.payload
      };

    // case PROMO_TYPE_NAMES:
    //   return {
    //     ...state,
    //     isAuthenticated: true,
    //     promoTypeData: action.payload
    //   };
    case LOADER:
      return {
        ...state,
        loader: action.payload
      };

    case USER_LOGGED_OUT:
      return {
        ...state,
        isAuthenticated: false,
        userData: {},
        loader: false
      };

    default:
      return state;
  }
}
