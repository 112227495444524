import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import AlertDanger from "../../../../utils/AlertMessage/alertDanger";
import AlertSuccess from "../../../../utils/AlertMessage/alertSuccess";
import "./couponStyling.css";
import CreateCouponSelections from "./CreateCouponSelections";
import ColumnDef from "../../../tables/colDefs/CouponColDefs";
import PageLoader from "../../../../utils/Loader";
import DataTable from "../../../tables";
import _ from "lodash";
import {
  getCouponList,
  deleteCoupons,
  createCoupon
} from "../../../../redux/actions/couponAction";
import couponChildMsgRenderer from "./couponChildMsgRendrer";
import moment from "moment";
import Modal from "react-awesome-modal";
import BackToCalendar from "../../../../utils/BackToCalendar";
import { alertTimer } from "../../../../utils/alertTimer";

let promotionName = null,
  campaignStartDate = null,
  campaignEndDate = null,
  campaignId = null,
  couponIdVar = null;
export class CreateCoupons extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      showSelectProducts: false,
      showSave: false,
      productListCreate: [],
      clearProductList: false,
      showAlertDanger: false,
      forecastUnits: "",
      forecastMargin: "",
      forecastRevenue: "",
      gmPercent: "",
      showForcastUnitMandatoryError: false,
      showExceededForecastValueError: false,
      showThreeFieldsError: false,
      couponNotCreated: false,
      showCouponCollapsible: true,
      couponTableData: [],
      startDate: null,
      endDate: null,
      resultStartDate: null,
      eventTypeSelected: null,
      description: "",
      couponTypeSelected: null,
      couponValueSelected: "",
      couponCreated: false,
      couponSaved: false,
      loader: false,
      deletedCouponAlert: false,
      gmPercentageExceeded: false,
      activeDateArr: [],
      couponEventsId: [],
      selectedDate: [],
      showConfirmationModal: false,
      rowDetails: [],
      showCommentModal: false,
      commentValueForSave: ""
    };
  }

  componentWillMount = () => {
    campaignId = localStorage.getItem("campaignId");
    promotionName = localStorage.getItem("campaignName");
    campaignStartDate = localStorage.getItem("campStartDate");
    campaignEndDate = localStorage.getItem("campEndDate");

    let date1 = moment(promotionName).format("MM/DD/YYYY"),
      date2 = moment(campaignStartDate).format("MM/DD/YYYY");

    if (moment(date1).isSame(date2) === false) {
      this.props.history.push("/promotion");
    }
  };

  componentDidMount = async () => {
    if (this.props.editCoupon) {
      this.setState({
        loader: true,
        showSave: true
      });
    }
    let response = await this.props.getCouponList({
      couponId: "",
      campaignId: parseInt(campaignId)
    });
    response.data.map(item => {
      item.getfun = this.editCoupon;
      item.getfun1 = this.deleteCouponConfirm;
      item.campaignStartDate = campaignStartDate;
      item.campaignEndDate = campaignEndDate;
      return item;
    });
    this.setState({
      couponTableData: response.data
    });
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.clearProductList === true) {
      this.setState({
        clearProductList: true
      });

      setTimeout(() => {
        this.setState({
          clearProductList: false
        });
      }, 200);
    }
    if (
      nextProps.errors &&
      nextProps.errors.errors !== undefined &&
      Object.keys(nextProps.errors.errors).length > 0
    ) {
      this.setState({
        showAlertDanger: true
      });
      setTimeout(() => {
        this.setState({
          showAlertDanger: false
        });
      }, alertTimer);
    }
  }

  showSelectProducts = e => {
    this.setState({
      showSelectProducts: !this.state.showSelectProducts
    });
  };

  showSaveFn = () => {
    let payload = {
      productList: this.state.productListCreate
    };
    this.props.updateAdgroup(payload);
  };

  collapseTabEnable = () => {
    var x = document.getElementsByClassName("collapse");
    for (let item of x) {
      item.classList.remove("show");
    }

    var y = document.getElementsByClassName("card-header");
    for (let item of y) {
      item.classList.add("collapsed");
    }
  };
  onDatesChange = date => {
    this.setState({
      startDate: date.value.slice(0, 10),
      endDate: date.value.slice(11, 21),
      couponEventsId: date.couponEventId,
      selectedDate: date
    });
  };

  /***********************
  save forecast validation start
  *********************/
  saveCouponValues = async () => {
    this.closeCommentModal();
    let forecastedMarginCalc = 0,
      forecastedRevenueCal = 0,
      gmPercentCal = 0;
    if (
      (this.state.forecastMargin.length && this.state.forecastRevenue.length) ||
      (this.state.forecastMargin.length &&
        this.state.forecastRevenue.length &&
        this.state.gmPercent.length)
    ) {
      gmPercentCal =
        (this.state.forecastMargin / this.state.forecastRevenue) * 100;
      this.setState({
        gmPercent: Math.round(gmPercentCal * 100) / 100
      });
    } else if (
      this.state.forecastMargin.length &&
      this.state.gmPercent.length
    ) {
      forecastedRevenueCal =
        Math.round((this.state.forecastMargin / this.state.gmPercent) * 10000) /
        100;
      gmPercentCal = this.state.gmPercent;
      this.setState({
        forecastRevenue: forecastedRevenueCal
      });
    } else if (
      this.state.gmPercent.length &&
      this.state.forecastRevenue.length
    ) {
      forecastedMarginCalc = this.state.gmPercent * this.state.forecastRevenue;
      gmPercentCal = this.state.gmPercent;
      this.setState({
        forecastMargin: forecastedMarginCalc
      });
    }

    if (gmPercentCal > 99) {
      this.setState({ gmPercentageExceeded: true });
      setTimeout(() => {
        this.setState({ gmPercentageExceeded: false });
      }, alertTimer);
      return;
    }
    this.collapseTabEnable();
    let {
      startDate,
      endDate,
      eventTypeSelected,
      description,
      couponTypeSelected,
      couponValueSelected,
      forecastMargin,
      forecastUnits,
      forecastRevenue,
      productListCreate,
      couponEventsId
    } = this.state,
      reqHeadr = this.props.editCoupon
        ? {
          inputData: [
            {
              campaignId: campaignId,
              couponId: couponIdVar,
              startDate: JSON.stringify(
                moment(startDate).format("YYYY-MM-DD")
              ).slice(1, 11),
              endDate: JSON.stringify(
                moment(endDate).format("YYYY-MM-DD")
              ).slice(1, 11),
              couponDescription: description,
              eventType: eventTypeSelected.value,
              couponType: couponTypeSelected.value,
              couponDiscount: couponValueSelected,
              forecastedUnits: forecastUnits,
              forecastedRevenue: forecastRevenue
                ? forecastRevenue
                : forecastedRevenueCal,
              forecastedMargin: forecastMargin
                ? forecastMargin
                : forecastedMarginCalc,
              gm: gmPercentCal,
              skuList: productListCreate
            }
          ]
        }
        : {
          inputData: [
            {
              campaignId: campaignId,
              startDate: startDate,
              endDate: endDate,
              coupon_events_id: couponEventsId,
              eventType: eventTypeSelected.value,
              couponDescription: description,
              couponType: couponTypeSelected.value,
              couponDiscount: couponValueSelected,
              forecastedUnits: forecastUnits,
              forecastedRevenue: forecastRevenue
                ? forecastRevenue
                : forecastedRevenueCal,
              forecastedMargin: forecastMargin
                ? forecastMargin
                : forecastedMarginCalc,
              gm: gmPercentCal,
              skuList: productListCreate
            }
          ]
        };
    this.setState({
      loader: true
    });
    let createResponse = await this.props.createCoupon(reqHeadr);

    if (createResponse.status) {
      this.setState({
        loader: false
      });
      let response = await this.props.getCouponList({
        couponId: "",
        campaignId: parseInt(campaignId)
      });
      response.data.map(item => {
        item.getfun = this.editCoupon;
        item.getfun1 = this.deleteCouponConfirm;
        item.campaignStartDate = campaignStartDate;
        item.campaignEndDate = campaignEndDate;
        return item;
      });
      this.setState({
        couponTableData: response.data,
        showCouponCollapsible: false,
        couponCreated: true,
        couponSaved: true,
        // showSave: false,
        eventTypeSelected: null,
        selectedDate: [],
        description: "",
        couponTypeSelected: null,
        couponValueSelected: "",
        gmPercent: "",
        forecastMargin: "",
        forecastRevenue: "",
        forecastUnits: "",
        productListCreate: [],
        showCommentModal: false,
        commentValueForSave: ""
      });
      setTimeout(() => {
        this.setState({ couponCreated: false });
      }, alertTimer);
    } else {
      this.setState({ couponNotCreated: true });

      setTimeout(() => {
        this.setState({ couponNotCreated: false });
      }, alertTimer);
    }
  };

  /***********************
  save forecast validation end
  *********************/
  editCoupon = couponId => {
    localStorage.setItem("edit_coupon_id", couponId);
    this.props.history.push(`/editcoupon`);
    if (this.props.history.location.pathname === "/editcoupon") {
      window.location.reload();
    }
  };
  deleteCouponConfirm = rowDetails => {
    this.setState({ showConfirmationModal: true, rowDetails });
  };
  deleteCoupon = async rowDetails => {
    let response = await this.props.deleteCoupons({
      couponId: [rowDetails.coupon_id]
    });

    if (response.status) {
      this.setState({
        deletedCouponAlert: true,
        showConfirmationModal: false
      });

      let { couponTableData } = this.state;

      let responseList = await this.props.getCouponList({
        couponId: "",
        campaignId: parseInt(campaignId)
      });
      responseList.data.map(item => {
        item.getfun = this.editCoupon;
        item.getfun1 = this.deleteCouponConfirm;
        item.campaignStartDate = campaignStartDate;
        item.campaignEndDate = campaignEndDate;
        return item;
      });
      couponTableData = responseList.data;

      this.setState({ couponTableData });

      setTimeout(() => {
        this.setState({
          deletedCouponAlert: false
        });
      }, alertTimer);
    }
  };
  createAdgroupData = productList => {
    this.setState({
      productListCreate: productList
    });
    // this.setState({
    //   showSave: true
    // });
    if (productList.length) {
      this.setState({
        showSave: true
      });
    } else {
      this.setState({
        showSave: false
      });
    }
  };
  editCouponActive = (unit, rev, margin, gm, couponId) => {
    couponIdVar = couponId;
    this.setState({
      forecastUnits: `${unit}`,
      forecastRevenue: `${rev}`,
      forecastMargin: `${margin}`,
      gmPercent: `${Math.round(gm * 100) / 100}`,
      loader: false
    });
  };
  unitForecastChangeHandler = val => {
    if (val.length <= 10) {
      this.setState({ forecastUnits: _.isEmpty(val) ? "" : parseFloat(val).toFixed() });
    } else {
      this.setState({
        showExceededForecastValueError: true
      });

      setTimeout(() => {
        this.setState({
          showExceededForecastValueError: false
        });
      }, alertTimer);
    }
  };
  forecastedRevHandler = e => {
    if (e.target.value.length <= 10) {
      let { forecastMargin, gmPercent } = this.state;
      this.setState({
        forecastRevenue: e.target.value,
        gmPercent: !_.isEmpty(forecastMargin)
          ? Math.round((forecastMargin / e.target.value) * 10000) / 100
          : Math.round(gmPercent * 100) / 100
      });
    } else {
      this.setState({
        showExceededForecastValueError: true
      });

      setTimeout(() => {
        this.setState({
          showExceededForecastValueError: false
        });
      }, alertTimer);
    }
  };
  forecastedMarginHandler = e => {
    if (e.target.value.length <= 10) {
      let { forecastRevenue, gmPercent } = this.state;
      this.setState({
        forecastMargin: e.target.value,
        gmPercent: !_.isEmpty(forecastRevenue)
          ? Math.round((e.target.value / forecastRevenue) * 10000) / 100
          : Math.round(gmPercent * 100) / 100
      });
    } else {
      this.setState({
        showExceededForecastValueError: true
      });

      setTimeout(() => {
        this.setState({
          showExceededForecastValueError: false
        });
      }, alertTimer);
    }
  };
  saveComponentRendrer = () => {
    return (
      <>
        <div className="row selectionsDiv coupon-forecast-div mt-0">
          <div className="col-md-2" />
          <div className="col-md-2">
            <label className="coupon-label promoLbels">
              Forecasted Units <span className="requiredField"> * </span>
            </label>
            <input
              type="number"
              className="adGroupInputStyling"
              step="10"
              placeholder="Enter forecast units"
              value={this.state.forecastUnits}
              onChange={e => this.unitForecastChangeHandler(e.target.value)}
            />
          </div>
          <div className="col-md-2">
            <label className="coupon-label promoLbels">
              Forecasted Sales <span className="requiredField"> * </span>
            </label>
            <input
              type="number"
              className="adGroupInputStyling"
              placeholder="Enter forecast revenue"
              step="10.00"
              value={this.state.forecastRevenue}
              onChange={e => this.forecastedRevHandler(e)}
            />
          </div>
          <div className="col-md-2">
            <label className="coupon-label promoLbels">
              Forecasted Margin <span className="requiredField"> * </span>
            </label>
            <input
              type="number"
              className="adGroupInputStyling"
              placeholder="Enter forecast margin"
              step="10"
              value={this.state.forecastMargin}
              onChange={e => this.forecastedMarginHandler(e)}
            />
          </div>
          <div className="col-md-2">
            <label className="coupon-label promoLbels">
              GM % <span className="requiredField"> * </span>
            </label>
            <input
              type="number"
              className="adGroupInputStyling"
              placeholder="Enter GM%"
              style={{ width: "90%" }}
              step="10"
              value={this.state.gmPercent}
              onChange={e => {
                this.setState({ gmPercent: e.target.value });
              }}
            />{" "}
            %
          </div>
          <div className="col-md-2" />
        </div>
      </>
    );
  };

  showConfirmAlert = () => {
    this.setState({ showConfirmationModal: false });
  };
  openCommentModal = () => {
    let forecastedMarginCalc = 0,
      forecastedRevenueCal = 0,
      gmPercentCal = 0;

    if (!_.isEmpty(this.state.forecastUnits)) {
      if (
        (this.state.forecastMargin.length &&
          this.state.forecastRevenue.length) ||
        (this.state.forecastMargin.length && this.state.gmPercent.length) ||
        (this.state.gmPercent.length && this.state.forecastRevenue.length)
      ) {
        if (
          (this.state.forecastMargin.length &&
            this.state.forecastRevenue.length) ||
          (this.state.forecastMargin.length &&
            this.state.forecastRevenue.length &&
            this.state.gmPercent.length)
        ) {
          gmPercentCal =
            (this.state.forecastMargin / this.state.forecastRevenue) * 100;
          this.setState({
            gmPercent: Math.round(gmPercentCal * 100) / 100
          });
        } else if (
          this.state.forecastMargin.length &&
          this.state.gmPercent.length
        ) {
          forecastedRevenueCal =
            Math.round(
              (this.state.forecastMargin / this.state.gmPercent) * 10000
            ) / 100;
          gmPercentCal = this.state.gmPercent;
          this.setState({
            forecastRevenue: forecastedRevenueCal
          });
        } else if (
          this.state.gmPercent.length &&
          this.state.forecastRevenue.length
        ) {
          forecastedMarginCalc =
            this.state.gmPercent * this.state.forecastRevenue;
          gmPercentCal = this.state.gmPercent;
          this.setState({
            forecastMargin: forecastedMarginCalc
          });
        }

        if (gmPercentCal > 99) {
          this.setState({ gmPercentageExceeded: true });
          setTimeout(() => {
            this.setState({ gmPercentageExceeded: false });
          }, alertTimer);
          return;
        }
        this.setState({ showCommentModal: true });
      } else {
        this.setState({ showThreeFieldsError: true });

        setTimeout(() => {
          this.setState({ showThreeFieldsError: false });
        }, alertTimer);
      }
    } else {
      this.setState({
        showForcastUnitMandatoryError: true
      });

      setTimeout(() => {
        this.setState({
          showForcastUnitMandatoryError: false
        });
      }, alertTimer);
    }
  };
  closeCommentModal = () => {
    this.setState({ showCommentModal: false });
  };
  render() {
    // let disableaccordian = this.state.showCouponCollapsible
    //   ? "enableaccordian card-header collapsed"
    //   : "card-header collapsed";

    let tableData = {
      header: ColumnDef.couponHeader,
      defaultColDef: ColumnDef.defaultColDef,
      data: this.state.couponTableData
    };
    return (
      <div>
        <PageLoader loader={this.state.loader}>
          <div className="alertDivs">
            {this.state.showForcastUnitMandatoryError ? (
              <AlertDanger
                message="Forecasted unit is mandatory!"
                show={this.state.showForcastUnitMandatoryError}
              />
            ) : null}
            {this.state.gmPercentageExceeded ? (
              <AlertDanger
                message="GM percentage cannot be more than 99!"
                show={this.state.gmPercentageExceeded}
              />
            ) : null}
            {this.state.showExceededForecastValueError ? (
              <AlertDanger
                message="Exceeded forecast limit!"
                show={this.state.showExceededForecastValueError}
              />
            ) : null}
            {this.state.deletedCouponAlert ? (
              <AlertSuccess
                message="Coupon deleted successfully!"
                show={this.state.deletedCouponAlert}
              />
            ) : null}

            {this.state.showThreeFieldsError ? (
              <AlertDanger
                message="Enter atleast 3 forecasted values!" //"All Fields Are Mandatory!"
                show={this.state.showThreeFieldsError}
              />
            ) : null}
            {this.state.couponNotCreated ? (
              <AlertDanger
                message="Something Went Wrong! Please Try Again!"
                show={this.state.couponNotCreated}
              />
            ) : null}
            {this.state.couponCreated ? (
              <AlertSuccess
                message={
                  this.props.editCoupon
                    ? "Coupon edited successfully"
                    : "Coupon Successfully created!"
                }
                show={this.state.couponCreated}
              />
            ) : null}
          </div>
          <div className="card cardStyles">
            <div id="cardbox1">
              <div className="createClusterDiv">
                <div className="row">
                  <BackToCalendar history={this.props.history} />
                  <div className="col-md-6">
                    {" "}
                    <h4 className="promoHeading">
                      {this.props.editCoupon ? "Edit Coupon" : "Create Coupon"}
                    </h4>
                  </div>
                </div>

                <CreateCouponSelections
                  editCoupon={this.props.editCoupon}
                  showSaveButton={clicked => this.showSaveFn(clicked)}
                  showErrorMessage={() => this.showErrorMessage()}
                  hideCreateAdGroup={adgroupName =>
                    this.hideCreateAdGroup(adgroupName)
                  }
                  createAdGroupValue={productList =>
                    this.createAdgroupData(productList)
                  }
                  sendAdGroupValuesCreate={name =>
                    this.receiveAdGroupCreateValues(name)
                  }
                  clearProductList={this.state.clearProductList}
                  promotionName={promotionName}
                  campaignStartDate={campaignStartDate}
                  campaignEndDate={campaignEndDate}
                  startDate={this.state.startDate}
                  endDate={this.state.endDate}
                  onDatesChange={this.onDatesChange}
                  selectedDate={this.state.selectedDate}
                  activeDateArr={this.state.activeDateArr}
                  onEventTypeSelect={val =>
                    this.setState({
                      eventTypeSelected: val,
                      activeDateArr: val.dateRange,
                      selectedDate: []
                    })
                  }
                  onDescriptionChange={val =>
                    this.setState({ description: val })
                  }
                  onCouponTypeSelected={val =>
                    this.setState({ couponTypeSelected: val })
                  }
                  onCouponValueSelected={val =>
                    this.setState({ couponValueSelected: val })
                  }
                  eventTypeSelected={this.state.eventTypeSelected}
                  description={this.state.description}
                  couponTypeSelected={this.state.couponTypeSelected}
                  couponValueSelected={this.state.couponValueSelected}
                  couponSaved={this.state.couponSaved}
                  couponSavedHandler={() =>
                    this.setState({ couponSaved: false })
                  }
                  editCouponActive={this.editCouponActive}
                  //saveCouponValues={this.saveCouponValues}
                  openCommentModal={this.openCommentModal}
                >
                  {this.saveComponentRendrer()}
                </CreateCouponSelections>
              </div>

              <div className="collapseDivInner mt-8 mb-8">
                <div id="accordion" className="accordion">
                  <div className="card mb-0">
                    <div
                      className="card-header collapsed"
                      data-toggle="collapse"
                      href="#collapseOne"
                    >
                      <a className="card-title">Coupon</a>
                    </div>
                    <div
                      id="collapseOne"
                      className="card-body collapse"
                      data-parent="#accordion"
                    >
                      <DataTable
                        parentId="myGridForPipeline"
                        //parentClass="ag-theme-balham"
                        tableData={tableData}
                        suppressRowClickSelection={true}
                        overlayNoRowsTemplate='<span class="pipeline-span">No Data to show</span>'
                        autoSizeCol={true}
                        frameworkComponents={{
                          childMessageRenderer: couponChildMsgRenderer
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </PageLoader>
        {this.state.showConfirmationModal ? (
          <Modal
            visible={true}
            width="400px"
            height="200px"
            effect="fadeInDown"
            onClickAway={() => this.showConfirmAlert()}
          >
            <div className="closeicon">
              <span style={{ cursor: "pointer" }}>
                <i
                  className="fa fa-times"
                  aria-hidden="true"
                  onClick={() => this.showConfirmAlert()}
                />
              </span>
            </div>
            <h5 className="delete-confirmatn-modal text-left">
              Are you sure you want to delete this coupon?
            </h5>
            <div className="text-center delete-btns">
              <button
                className="btn btn-outline-secondary"
                onClick={() => this.showConfirmAlert()}
              >
                No
              </button>
              <button
                className="btn btn-outline-secondary ml-3"
                onClick={() => this.deleteCoupon(this.state.rowDetails)}
              >
                Yes
              </button>
            </div>
          </Modal>
        ) : null}
        {this.state.showCommentModal ? (
          <Modal
            visible={true}
            width="400px"
            height="250px"
            effect="fadeInDown"
            onClickAway={() => this.closeCommentModal()}
          >
            <div className="closeicon">
              <span style={{ cursor: "pointer" }}>
                <i
                  className="fa fa-times"
                  aria-hidden="true"
                  onClick={() => this.closeCommentModal()}
                />
              </span>
            </div>
            <p className="promoLbels text-center">Comment:</p>
            <input
              type="text"
              className="form-control card commentAreaInput comment-for-save"
              rows="5"
              placeholder="Enter your comment ⃰"
              value={this.state.commentValueForSave}
              onChange={e => {
                this.setState({
                  commentValueForSave: e.target.value
                });
              }}
            />

            <div className="text-center delete-btns">
              <button
                className="btn btn-outline-primary promo3Buttons"
                onClick={() => this.saveCouponValues()}
                disabled={this.state.commentValueForSave.length ? false : true}
              >
                Save
              </button>
            </div>
          </Modal>
        ) : null}
      </div>
    );
  }
}
const mapStateToProps = state => ({});

// export default connect(
//   mapStateToProps
// )(withRouter(CreateCoupons));

export default connect(mapStateToProps, {
  getCouponList,
  createCoupon,
  deleteCoupons
})(withRouter(CreateCoupons));
