import React from "react";
import { AgGridReact } from "ag-grid-react";
import numeral from "numeral";
import moment from "moment";
import _ from "lodash";
import { emptySpace } from "../../utils/emptySpace";
import "./tables.css";

function valueFormatterwithDollanRank(params) {
  if (
    (params.value || params.value === 0) &&
    params.node &&
    params.node.data &&
    params.node.data.rank === "1"
  ) {
    var value = params.value;
    let sign = Math.sign(value);
    let valueVar = Math.round(value * 100) / 100;

    return value === null || value === ""
      ? `${emptySpace}`
      : (sign === 1 || sign === 0) && valueVar !== 0
        ? "$ " + numeral(valueVar).format("0,0")
        : "$ " + numeral(valueVar).format("(0,0)");
  } else {
    return `${emptySpace}`;
  }
}
function valueFormatterwithoutDollanRank(params) {
  if (
    (params.value || params.value === 0) &&
    params.node &&
    params.node.data &&
    params.node.data.rank === "1"
  ) {
    var value = params.value;
    let sign = Math.sign(value);
    let valueVar = Math.round(value * 100) / 100;
    console.log(value);
    return value === null || value === ""
      ? `${emptySpace}`
      : (sign === 1 || sign === 0) && valueVar !== 0
        ? numeral(valueVar).format("0,0")
        : numeral(valueVar).format("(0,0)");
  } else {
    return `${emptySpace}`;
  }
}

function valueFormatterwithDollanRankIA(params) {
  if (
    params.value &&
    params.node &&
    params.node.data &&
    params.node.data.rank === "1"
  ) {
    var value = params.value;
    let sign = Math.sign(value);
    let valueVar = Math.round(value * 100) / 100;

    return value === null || value === "" || valueVar < 1
      ? `${emptySpace}`
      : (sign === 1 || sign === 0) && valueVar !== 0
        ? "$ " + numeral(valueVar).format("0,0")
        : "$ " + numeral(valueVar).format("(0,0)");
  } else {
    return `${emptySpace}`;
  }
}
function valueFormatterwithoutDollanRankIA(params) {
  if (
    params.value &&
    params.node &&
    params.node.data &&
    params.node.data.rank === "1"
  ) {
    var value = params.value;
    let sign = Math.sign(value);
    let valueVar = Math.round(value * 100) / 100;
    return value === null || value === "" || valueVar < 1
      ? `${emptySpace}`
      : (sign === 1 || sign === 0) && valueVar !== 0
        ? numeral(valueVar).format("0,0")
        : numeral(valueVar).format("(0,0)");
  } else {
    return `${emptySpace}`;
  }
}
function valueFormatterwithDolla(params) {
  if (params.value || params.value === 0) {
    var value = params.value;
    let sign = Math.sign(value);
    let valueVar = Math.round(value * 100) / 100;

    return value === null || value === ""
      ? `${emptySpace}`
      : (sign === 1 || sign === 0) && valueVar !== 0
        ? "$ " + numeral(valueVar).format("0,0")
        : "$ " + numeral(valueVar).format("(0,0)");
  } else {
    return `${emptySpace}`;
  }
}
function valueFormatterwithoutDolla(params) {
  if (params.value || params.value === 0) {
    var value = params.value;
    let sign = Math.sign(value);
    let valueVar = Math.round(value * 100) / 100;

    return value === null || value === ""
      ? `${emptySpace}`
      : (sign === 1 || sign === 0) && valueVar !== 0
        ? numeral(valueVar).format("0,0")
        : numeral(valueVar).format("(0,0)");
  } else {
    return `${emptySpace}`;
  }
}

function valueFormatterwithDecimals(params) {
  if (params.value || params.value === 0) {
    var value = params.value;
    let sign = Math.sign(value);
    let valueVar = Math.round(value * 100) / 100;

    return value === null || value === ""
      ? `${emptySpace}`
      : (sign === 1 || sign === 0) && valueVar !== 0
        ? "$ " + numeral(valueVar).format("0.00")
        : "$ " + numeral(valueVar).format("(0.00)");
  } else {
    return `${emptySpace}`;
  }
}

function valueGMFormatterHandlernRank(params) {
  if (
    (params.value || params.value === 0) &&
    params.node &&
    params.node.data &&
    params.node.data.rank === "1"
  ) {
    var value = params.value;
    let sign = Math.sign(value);
    let valueVar = Math.round(value * 100) / 100;

    return value === null || value === ""
      ? `${emptySpace}`
      : (sign === 1 || sign === 0) && valueVar !== 0
        ? numeral(valueVar).format("0.00") + " %"
        : numeral(valueVar).format("(0.00)") + " %";
  }
  return `${emptySpace}`;
}
function valueGMFormatterHandlernRankIA(params) {
  if (
    params.value &&
    params.node &&
    params.node.data &&
    params.node.data.rank === "1"
  ) {
    var value = params.value;
    let sign = Math.sign(value);
    let valueVar = Math.round(value * 100) / 100;

    return value === null || value === ""
      ? `${emptySpace}`
      : (sign === 1 || sign === 0) && valueVar !== 0
        ? numeral(valueVar).format("0.00") + " %"
        : numeral(valueVar).format("(0.00)") + " %";
  }
  return `${emptySpace}`;
}
function valueGMFormatterHandler(params) {
  if (params.value || params.value === 0) {
    var value = params.value;
    let sign = Math.sign(value);
    let valueVar = Math.round(value * 100) / 100;

    return value === null || value === ""
      ? `${emptySpace}`
      : (sign === 1 || sign === 0) && valueVar !== 0
        ? numeral(valueVar).format("0.00") + " %"
        : numeral(valueVar).format("(0.00)") + " %";
  }
  return `${emptySpace}`;
}
function varPerHandler(params) {
  let field = params.column.colDef.field,
    filed1,
    field2;
  if (field === "var_quantity") {
    filed1 = "ly_total_units";
    field2 = "ty_total_units";
  }
  if (field === "var_total_revenue") {
    filed1 = "ly_total_sales";
    field2 = "ty_total_sales";
  }
  if (field === "var_total_margin") {
    filed1 = "ly_total_margin";
    field2 = "ty_total_margin";
  }

  if (
    params.node.data[filed1] !== null &&
    params.node.data[filed1] !== 0 &&
    params.node.data[filed1] > 0 &&
    params.node.data[field2] !== null &&
    params.node.data[field2] !== 0
  ) {
    let val =
      ((params.node.data[field2] - params.node.data[filed1]) /
        params.node.data[filed1]) *
      100;
    let sign = Math.sign(val);

    return sign === 1 || sign === 0
      ? numeral(val).format("0.00") + " %"
      : numeral(val).format("(0.00)") + " %";
  }
  return `${emptySpace}`;
}

export default class DataTable extends React.Component {
  componentWillReceiveProps(nextProps) {
    if (nextProps.updateTable) {
      this.props.updateTableHandler();
      this.gridApi.updateRowData({ add: nextProps.compareTableDataArray });
    }
    if (nextProps.resetTable) {
      this.props.resetTableHandler();
      this.gridApi.setRowData([]);
    }
    if (nextProps.exportCompareData) {
      const params = {
        columnGroups: true,
        allColumns: true,
        fileName: "CompareOffersData",
      };
      params.processCellCallback = function (params) {
        if (
          params.column.colDef.field === "total_revenue" ||
          params.column.colDef.field === "total_margin"
        ) {
          return valueFormatterwithDolla(params);
        } else if (params.column.colId === "total_quantity") {
          return valueFormatterwithoutDolla(params);
        } else if (params.column.colId === "gm_percentage") {
          return valueGMFormatterHandler(params);
        } else {
          return params.value;
        }
      };
      this.props.exportDataHandler();
      this.gridApi.exportDataAsCsv(params);
    }

    if (nextProps.pipelineTableExport) {
      let columnKeysArray = [];
      _.map(nextProps.tableData.header, (item) => {
        // if (!item.hide) columnKeysArray.push(item.field);
        // if(item.field === "adgroup_name") columnKeysArray.push('extra');

            if (!item.hide || item.field === "promo_sign_shell" || item.field === "sign_description" || item.field==="vendor_label" || item.field === "subline" 
               || item.field === "total_actual_units" || item.field === "total_actual_sales" || item.field === "total_actual_margin" || item.field === "actual_gm"
               || item.field === "total_adjusted_units" || item.field === "total_adjusted_margin" || item.field === "total_adjusted_sales" || item.filed === "adjusted_gm"
            ) {
                  columnKeysArray.push(item.field);
            }

        return item;
      });

      const params = {
        columnGroups: true,
        allColumns: false,
        columnKeys: columnKeysArray,
        // customHeader: `Start Date: ${this.props.startDate}, End Date: ${this.props.endDate}`,
        fileName: `PromoPipelineData-${this.props.startDate}-${this.props.endDate}`,
      };
      params.processCellCallback = function (params) {
        if (
          params.column.colDef.field === "sku_count" ||
          params.column.colDef.field === "total_forecasted_units" ||
          params.column.colDef.field === "total_adjusted_units" ||
          params.column.colDef.field === "total_override_units" ||
          params.column.colDef.field === "total_actual_units"
        ) {
          return valueFormatterwithoutDollanRank(params);
        } else if (params.column.colDef.field === "ia_forecasted_units") {
          return valueFormatterwithoutDollanRankIA(params);
        } else if (params.column.colDef.field === "ly_total_units") {
          return valueFormatterwithoutDolla(params);
        } else if (
          params.column.colDef.field === "total_forecasted_sales" ||
          params.column.colDef.field === "total_actual_sales" ||
          params.column.colDef.field === "total_adjusted_sales" ||
          params.column.colDef.field === "total_override_sales" ||
          params.column.colDef.field === "total_forecasted_margin" ||
          params.column.colDef.field === "total_actual_margin" ||
          params.column.colDef.field === "total_adjusted_margin" ||
          params.column.colDef.field === "total_override_margin"
        ) {
          return valueFormatterwithDollanRank(params);
        } else if (
          params.column.colDef.field === "ia_forecasted_sales" ||
          params.column.colDef.field === "ia_forecasted_margin"
        ) {
          return valueFormatterwithDollanRankIA(params);
        } else if (
          params.column.colDef.field === "ly_total_sales" ||
          params.column.colDef.field === "ly_total_margin"
        ) {
          return valueFormatterwithDolla(params);
        } else if (
          params.column.colDef.field === "gm_override" ||
          params.column.colDef.field === "forecasted_gm" ||
          params.column.colDef.field === "actual_gm" ||
          params.column.colDef.field === "adjusted_gm"
        ) {
          return valueGMFormatterHandlernRank(params);
        } else if (params.column.colDef.field === "ia_forecasted_gm") {
          return valueGMFormatterHandlernRankIA(params);
        } else if (params.column.colDef.field === "ly_gm") {
          return valueGMFormatterHandler(params);
        } else {
          return params.value;
        }
      };
      this.props.pipelineTableExportHandler();
      this.gridApi.exportDataAsCsv(params);
    }
    if (nextProps.campTableExport) {
      const params = {
        columnGroups: true,
        // allColumns: false,
        // customHeader: `Start Date: ${this.props.startDate}, End Date: ${this.props.endDate}`,
        fileName: `calendar_data`,
      };
      this.props.campTableExportHandler();
      this.gridApi.exportDataAsCsv(params);
    }
    if (nextProps.dayLevelExport) {
      const params = {
        columnGroups: true,
        // allColumns: false,
        // customHeader: `Start Date: ${this.props.startDate}, End Date: ${this.props.endDate}`,
        fileName: `Day_Level_Offer_Data`,
      };
      this.props.dayLevelexportHandler();
      this.gridApi.exportDataAsCsv(params);
    }
    if (nextProps.skuListDownload) {
      const params = {
        columnGroups: true,
        allColumns: false,
        columnKeys: [
          "unique_id1",
          "name",
          "merch_cat",
          "catagory_desc",
          "shop_desc",
          "article_status",
          "vendor_name",
          "zone1_retail_price",
          "zone3_retail_price",
          "zone4_retail_price",
          "zone5_retail_price",
          "cost",
          "collection",
          "product_brand",
          "in_store_date",
          "prod_creation_date",
        ],
        fileName: `${nextProps.downloadName}`,
      };
      params.processCellCallback = function (params) {
        if (params.column.colId === "reference_sku") {
          if (params.node.data.unique_id1 === params.value) {
            return "-";
          }
          return params.value;
        } else if (
          params.column.colDef.field === "price" ||
          params.column.colDef.field === "cost"
        ) {
          return valueFormatterwithDecimals(params);
        } else {
          return params.value;
        }
      };
      this.props.skuListDownloadHandler();
      this.gridApi.exportDataAsCsv(params);
    }

    if (nextProps.viewSkuListDownload) {
      const params = {
        columnGroups: true,
        allColumns: false,
        columnKeys: [
          "unique_id1",
          "name",
          "merch_cat",
          "catagory_desc",
          "shop_desc",
          "article_status",
          "vendor_name",
          "price",
          "cost",
          "collection",
          "label",
          "color",
          "material",
          "joann_ref_sku",
          "ia_ref_sku",
          "original_reference_sku",
          "similar_sku_multiplier",
          "pattern",
          "online_sku",
          "in_store_date",
          "prod_creation_date",
          "fashion_basic_type_code_descr",
          "season_code_descr",
        ],
        fileName: `${nextProps.downloadName}`,
      };
      params.processCellCallback = function (params) {
        if (params.column.colId === "reference_sku") {
          if (params.node.data.unique_id1 === params.value) {
            return "-";
          }
          return params.value;
        } else if (
          params.column.colDef.field === "price" ||
          params.column.colDef.field === "cost"
        ) {
          return valueFormatterwithDecimals(params);
        } else {
          return params.value;
        }
      };
      this.props.viewSkuListDownloadHandler();
      console.log(params);
      this.gridApi.exportDataAsCsv(params);
    }
    /*Summary table csv in simulate screen*/
    if (nextProps.summaryTableExport) {
      const params = {
        columnGroups: true,
        allColumns: false,
        // customHeader: `Start Date: ${this.props.startDate}, End Date: ${this.props.endDate}`,
        fileName: "Summary_data"
      }
      params.processCellCallback = function (params) {
        if (params.column.colDef.field === "total_units") {
          return valueFormatterwithoutDolla(params);
        } else if (params.column.colDef.field === "total_sales" || params.column.colDef.field === "total_margin") {
          return valueFormatterwithDolla(params);
        } else if (params.column.colDef.field === "gm_percentage") {
          return valueGMFormatterHandler(params);
        } else {
          return params.value;
        }
      };
      console.log(params);
      this.props.summaryTableExportHandler();
      this.gridApi.exportDataAsCsv(params);
    }

    if (nextProps.referenceSkuExport) {
      const params = {
        columnGroups: true,
        allColumns: false,
        columnKeys: [
          "unique_id1",
          "name",
          "merch_cat",
          "catagory_desc",
          "shop_desc",
          "article_status",
          "vendor_name",
          "price",
          "cost",
          "collection",
          "label",
          "color",
          "material",
          "joann_ref_sku",
          "ia_ref_sku",
          "original_reference_sku",
          "similar_sku_multiplier",
          "pattern",
          "online_sku",
          "in_store_date",
          "prod_creation_date",
          "fashion_basic_type_code_descr",
          "season_code_descr",
        ],
        fileName: `${nextProps.downloadName}`,
      };
      params.processCellCallback = function (params) {
        if (params.column.colId === "reference_sku") {
          if (params.node.data.unique_id1 === params.value) {
            return "-";
          }
          return params.value;
        } else if (
          params.column.colDef.field === "price" ||
          params.column.colDef.field === "cost"
        ) {
          return valueFormatterwithDecimals(params);
        } else {
          return params.value;
        }
      };
      this.props.referenceSkuExportHandler();
      this.gridApi.exportDataAsCsv(params);
    }

    if (nextProps.couponTableExport) {
      const params = {
        columnGroups: true,
        allColumns: false,
        customHeader: `Start Date: ${this.props.startDate}, End Date: ${this.props.endDate}`,
        fileName: `CouponData-${this.props.startDate}-${this.props.endDate}`,
      };
      params.processCellCallback = function (params) {
        if (
          params.column.colDef.field === "forecasted_revenue" ||
          params.column.colDef.field === "forecasted_margin" ||
          params.column.colDef.field === "actual_revenue" ||
          params.column.colDef.field === "actual_margin"
        ) {
          return valueFormatterwithDolla(params);
        } else if (
          params.column.colDef.field === "forecasted_units" ||
          params.column.colDef.field === "actual_units"
        ) {
          return valueFormatterwithoutDolla(params);
        } else if (
          params.column.colDef.field === "gm" ||
          params.column.colDef.field === "actual_gm"
        ) {
          return valueGMFormatterHandler(params);
        } else {
          return params.value;
        }
      };
      this.props.couponTableExportHandler();
      this.gridApi.exportDataAsCsv(params);
    }
    if (
      nextProps.summaryExport1 ||
      nextProps.summaryExport2 ||
      nextProps.summaryExport3 ||
      nextProps.summaryExport4 ||
      nextProps.summaryExport5 ||
      nextProps.summaryExport6 ||
      nextProps.summaryExport7 ||
      nextProps.summaryExport8 ||
      nextProps.summaryExport9 ||
      nextProps.summaryExport10 ||
      nextProps.summaryExport11 ||
      nextProps.summaryExport12 ||
      nextProps.summaryExport13
    ) {
      const params = {
        columnGroups: true,
        allColumns: true,
        fileName: `ExecSummaryData(${this.props.exportFilename})`,
      };
      params.processCellCallback = function (params) {
        if (
          params.column.colDef.field === "ty_total_sales" ||
          params.column.colDef.field === "ly_total_sales" ||
          params.column.colDef.field === "ty_total_margin" ||
          params.column.colDef.field === "ly_total_margin"
        ) {
          return valueFormatterwithDolla(params);
        } else if (
          params.column.colDef.field === "ty_total_units" ||
          params.column.colDef.field === "ly_total_units"
        ) {
          return valueFormatterwithoutDolla(params);
        } else if (
          params.column.colDef.field === "ly_gm" ||
          params.column.colDef.field === "ty_gm"
        ) {
          return valueGMFormatterHandler(params);
        } else if (
          params.column.colDef.field === "var_quantity" ||
          params.column.colDef.field === "var_total_revenue" ||
          params.column.colDef.field === "var_total_margin"
        ) {
          return varPerHandler(params);
        } else {
          return params.value;
        }
      };
      this.props.summaryExporthandler();
      this.gridApi.exportDataAsCsv(params);
    }

    if (nextProps.refreshRows) {
      this.props.refreshRowsHandler();
      this.gridApi.refreshCells({ columns: ["refSkuOptions"], force: true });
      this.gridApi.refreshCells({ columns: ["reference_sku"], force: true });
    }
    if (nextProps.refreshTable) {
      this.props.refreshTableHandler();
      var newItem = this.props.tableData.data[
        this.props.tableData.data.length - 1
      ];
      this.gridApi.refreshCells({ force: true });
      // this.gridApi.updateRowData({ add: [newItem] });
    }
    if (nextProps.refreshLYTYRows) {
      this.props.refreshLYTYRowsHandler(false);
      this.gridApi && this.gridApi.refreshCells({ columns: ["ly_adgroup"], force: true });
    }
    // if (nextProps.plannedOfferChanged) {
    //   let rowNode = this.gridApi.getRowNode(nextProps.rowIndex);

    //   this.props.plannedOfferHandler("");
    //   this.gridApi.refreshCells({ rowNodes: [rowNode], force: true });
    // }
    if (nextProps.tyEventChanged) {
      let rowNode = this.gridApi.getRowNode(nextProps.tyRowIndex);

      this.props.tyOfferdropdownChange("");
      this.gridApi.refreshCells({ rowNodes: [rowNode], force: true });
    }
    if (nextProps.saveClicked) {
      let rowNode = this.gridApi.getRowNode(nextProps.rowIndex);

      this.props.rowRefreshHandler([], "");
      this.gridApi.refreshCells({ rowNodes: [rowNode], force: true });
    }
    if (nextProps.actionClicked) {
      let rowNode = this.gridApi.getRowNode(nextProps.rowIndex);

      this.props.rowRefreshHandler([], "");
      this.gridApi.refreshCells({ rowNodes: [rowNode], force: true });
    }
    if (nextProps.calculationChanged) {
      let rowNode = this.gridApi.getRowNode(nextProps.calcRowIndex);

      this.props.updateCalculationChanged("");
      this.gridApi.refreshCells({ rowNodes: [rowNode], force: true });
    }

    if (nextProps.rowRefresh) {
      if (nextProps.rowIndex) {
        let rowNode = this.gridApi.getRowNode(nextProps.rowIndex);

        this.props.rowRefreshHandler("");
        this.gridApi.refreshCells({ rowNodes: [rowNode], force: true });
      } else {
        this.gridApi.refreshCells({ force: true });
      }
    }
    // if (nextProps.updateFilter) {
    //   if(this.gridApi && this.gridApi.gridOptionsWrapper.gridOptions.rowData.length){

    //     this.props.updateFilterHandler();

    //     var sportsFilterComponent = this.gridApi.getFilterInstance("adgroupName");
    //     sportsFilterComponent.setModel({
    //       type: "contains",
    //       filter: "14"
    //     });
    //     this.gridApi.onFilterChanged();
    //   }

    // }
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.showZpesCol &&
      this.props.tableData &&
      this.props.tableData.data &&
      this.props.tableData.data[0]
    ) {
      this.props.zpesColHandler();

      let historicalDM =
        this.props.tableData &&
          this.props.tableData.data &&
          this.props.tableData.data[0] &&
          this.props.tableData.data[0].campaign_type === "completed_dm"
          ? true
          : false;

      let colKeys = [
        "actual_offer",
        "total_actual_units",
        "total_adjusted_units",
        "total_actual_sales",
        "total_adjusted_sales",
        "total_actual_margin",
        "total_adjusted_margin",
        "actual_gm",
        "adjusted_gm",
        "actual_offer_1",
        "total_actual_units_1",
        "total_adjusted_units_1",
        "total_actual_sales_1",
        "total_adjusted_sales_1",
        "total_actual_margin_1",
        "total_adjusted_margin_1",
        "actual_gm_1",
        "adjusted_gm_1",
      ];
      this.gridColumnApi.setColumnsVisible(
        colKeys,
        historicalDM //!this.props.setColStatus
      );

      this.gridColumnApi.setColumnsVisible(
        ["zpes_offer", "zpes_offer_1"],
        this.props.zpesColStatus
      );
    }
    if ((prevProps.plannedOfferChanged != this.props.plannedOfferChanged) &&  this.props.plannedOfferChanged) {
      let rowNode = this.gridApi.getRowNode(this.props.rowIndex);
      // let tempData = this.gridApi.get

      this.props.plannedOfferHandler("");
      this.gridApi.refreshCells({ rowNodes: [rowNode], force: true });
      // this.gridApi.setRowData(this.props.tableData.data)
    }
  }
  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    if (this.props.autoSizeCol) {
      params.api.sizeColumnsToFit();
    }
    if(this.props.hasOwnProperty("onGridReady")){
      this.props.onGridReady(params)
    }
    this.props.getGridAPI && this.props.getGridAPI(params.api);
  };

  onCellValueChanged = (params) => {
    this.props.editableRowsHandler(params.data);
  };
  render() {
    return (
      <div
        id={this.props.parentId ? this.props.parentId : ""}
        style={
          this.props.parentStyle
            ? this.props.parentStyle
            : {
              height: "calc(100vh - 280px)",
              marginTop: "5px",
            }
        }
        className={
          this.props.parentClass
            ? this.props.parentClass
            : "ag-theme-balham card cardStyles"
        }
      >
        <AgGridReact
          floatingFilter={this.props.disableFilter ? false : true}
          suppressFilterButton={this.props.disableFilter ? false : true}
          suppressColumnMoveAnimation={true}
          columnDefs={this.props.tableData.header}
          defaultColDef={this.props.tableData.defaultColDef}
          rowData={this.props.tableData.data}
          pagination={this.props.pagination ? true : false}
          onGridReady={this.onGridReady}
          onCellValueChanged={this.onCellValueChanged}
          overlayNoRowsTemplate={
            this.props.overlayNoRowsTemplate
              ? this.props.overlayNoRowsTemplate
              : null
          }
          //onFirstDataRendered={this.props.thisVar ? this.onFirstDataRendered.bind(this.props.thisVar) : null}
          animateRows={this.props.animateRows ? true : false}
          groupDefaultExpanded={this.props.expand ? true : false}
          autoGroupColumnDef={
            this.props.autoGroupColDef ? this.props.autoGroupColDef : null
          }
          suppressAggFuncInHeader={
            this.props.supress ? this.props.supress : null
          }
          overlayLoadingTemplate={
            this.props.overlayNoRowsTemplate
              ? this.props.overlayNoRowsTemplate
              : null
          }
          columnGroups={
            this.props.columnGroups ? this.props.columnGroups : null
          }
          isRowSelectable={
            this.props.isRowSelectable ? this.props.isRowSelectable : false
          }
          suppressRowClickSelection={
            this.props.suppressRowClickSelection ? true : false
          }
          rowSelection={
            this.props.rowSelection ? this.props.rowSelection : null
          }
          onSelectionChanged={() => {
            if (this.props.selectOfferRow) {
              var selectedRow = this.gridApi.getSelectedRows();
              this.props.selectOfferRow(selectedRow);
            }
          }}
          rowClassRules={
            this.props.rowClassRules ? this.props.rowClassRules : null
          }
          getRowStyle={this.props.getRowStyle ? this.props.getRowStyle : null}
          frameworkComponents={
            this.props.frameworkComponents
              ? this.props.frameworkComponents
              : null
          }
          context={this.props.context ? this.props.context : null}
          rowClass={this.props.rowClass ? this.props.rowClass : null}
          onCellClicked={(event) => {
            if (this.props.onCellClicked) {
              this.props.onCellClicked(event);
            }
          }}
          suppressClipboardPaste={true}
          enableBrowserTooltips={true}
          suppressDragLeaveHidesColumns={true}
          paginationPageSize={this.props.paginationPageSize}
          cacheBlockSize={this.props.paginationPageSize}
          rowModelType={this.props.rowModelType ? this.props.rowModelType : 'clientSide'}
        />
      </div>
    );
  }
}
