import axios from "../../utils/axios/index";
import { config } from "../../utils/config";
import {
  GET_USERS_LIST,
  LOADER,
  GET_ERRORS,
  CREATE_USER_DATA,
  USER_STATUS_UPDATE_DATA
} from "./types";

export const getUsersList = reqObject => dispatch => {
  dispatch({
    type: LOADER,
    payload: true
  });
  axios
    .get(`${config.baseUrl}/user/usersDetails`)
    .then(res => {
      dispatch({
        type: GET_USERS_LIST,
        payload: res.data.data
      });
      dispatch({
        type: LOADER,
        payload: false
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err
      });
    });
};

export const editUser = reqObject => async dispatch => {
  dispatch({
    type: LOADER,
    payload: true
  });
  const response = await axios.post(
    `${config.baseUrl}/user/userDataCreateEdit`,
    reqObject
  );

  dispatch(
    {
      type: CREATE_USER_DATA,
      payload: response.data
    },
    {
      type: LOADER,
      payload: false
    }
  );
  return response.data;
};

export const activateOrDisableUser = reqObject => async dispatch => {
  dispatch({
    type: LOADER,
    payload: true
  });
  const response = await axios.post(
    `${config.baseUrl}/user/userStatusUpdate`,
    reqObject
  );

  dispatch(
    {
      type: USER_STATUS_UPDATE_DATA,
      payload: response.data
    },
    {
      type: LOADER,
      payload: false
    }
  );
  return response.data;
};

export const merchIdList = () => async dispatch => {
  const response = await axios.get(`${config.baseUrl}/user/merchIdList`);

  return response.data;
};

export const userCommentsProducts = reqObject => async dispatch => {
  const response = await axios.post(
    `${config.baseUrl}/user/userCommentsProducts`,
    reqObject
  );
  return response.data;
};

export const usersLogReport = reqObject => async dispatch => {
  const response = await axios.post(
    `${config.baseUrl}/user/usersLogReport`,
    reqObject
  );

  return response.data;
};


export const loginAllAccess = reqObject => async dispatch => {
  dispatch({
    type: LOADER,
    payload: true
  });
  const response = await axios.post(
    `${config.baseUrl}/user/login_all_access`,
    reqObject
  )
    .then((resp) => {
      return resp.data;
    })
    .catch((err) => {
      return [];
    });

  dispatch(
    {
      type: LOADER,
      payload: false
    }
  );
  return response;
};