import React, { Component } from "react";
import "./errorMsgStyle.css";

export class maintenancePageComponent extends Component {
  render() {
    return (
      <div className="maintenance-body">
        <p id="msgError" onClick={() => this.props.history.push("/promotion")}>
          Back to Home
        </p>
        <div class="animation">
          <div class="one spin-one"></div>
          <div class="two spin-two"></div>
          <div class="three spin-one"></div>
        </div>
        <div>
          <p className="maintenance-text">
            Sorry for the inconvenience but we&rsquo;re executing batch process
            maintenance at the moment. Please head
            <span
              className="hyperlink-styling maintenance-text"
              onClick={() => this.props.history.push("/promotion")}
            >
              &nbsp;Back to Promotions&nbsp;
            </span>
            after a few hours!
          </p>
          <p className="maintenance-text">&mdash; Joann Promo$mart Team</p>
        </div>
      </div>
    );
  }
}

export default maintenancePageComponent;
