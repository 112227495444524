import React from "react";
import { Button } from 'antd';
import { CalculatorOutlined } from '@ant-design/icons';

const PromoActionRenderer = props => {

  return (
    <>
      <div className="action-button">
        <Button
          type="link"
          title="Calculate reg sales price"
          onClick={() => {
            props.data.getRegSalesPrices(props.data, props.node.id);
            // this.submitGA("Click", "Clicked_EditAdgroupButton");
          }}
          // disabled={!EVENT_MASTER_USERS.includes(localStorage.getItem("role"))}
          icon={<CalculatorOutlined />}
        >
        </Button>
      </div>
    </>
  )
}

export default PromoActionRenderer;
