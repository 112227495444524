import React, { Component } from "react";
import {
  getUsersList,
  activateOrDisableUser,
  loginAllAccess
} from "../../../../redux/actions/userAction";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import "./usersStyling.css";
import ColumnDef from "../../../tables/colDefs/usersListColDef";
import DataTable from "../../../tables";
import UserChildMsgRenderer from "./UserChildMsgRenderer";
import PageLoader from "../../../../utils/Loader";
import CreateUser from "./CreateUser";
import AlertDanger from "../../../../utils/AlertMessage/alertDanger";
import AlertSuccess from "../../../../utils/AlertMessage/alertSuccess";
import _ from "lodash";

export class ManageUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      frameworkComponents: {
        childMessageRenderer: UserChildMsgRenderer
      },
      showViewUser: true,
      editUserData: null,
      activatedOrDisabled: null,
      tableDataLoader: true
    };
  }
  componentDidMount() {
    this.props.getUsersList();
  }

  componentWillReceiveProps(props) {
    if (
      props.usersList &&
      Object.keys(props.usersList).length &&
      this.state.tableDataLoader
    ) {
      this.setState({ tableDataLoader: false });
    }
  }
  //used to switch view between create new user and view users' list
  switchUserView = selectedView => {
    if (selectedView === "plus") {
      this.setState({
        showViewUser: false
      });
    } else {
      this.props.getUsersList();
      this.setState({
        showViewUser: true,
        editUserData: null
      });
    }
  };

  editUser = val => {
    this.setState({ editUserData: val, showViewUser: false });
  };

  loginAllAccessHandler = async (val) => {
    let res = await this.props.loginAllAccess({email: val.email});
    if(res.status){
      window.location.href = res.redirect_url;
      return;
    } else {
      this.setState({ activatedOrDisabled: res });
      setTimeout(() => {
        this.setState({ activatedOrDisabled: null });
      }, 2500);
    }
  }

  activateOrDisableUser = async val => {
    let response = await this.props.activateOrDisableUser(val);
    this.setState({ activatedOrDisabled: response });
    this.props.getUsersList();
    setTimeout(() => {
      this.setState({ activatedOrDisabled: null });
    }, 2500);
  };

  render() {
    let updatedUserList = this.props.usersList.map(item => {
      item.editUser = this.editUser;
      item.activateOrDisableUser = this.activateOrDisableUser;
      item.loginAllAccessHandler = this.loginAllAccessHandler;
      return item;
    });
    let tableData = {
      header: ColumnDef.colDefForUsersList,
      defaultColDef: ColumnDef.defaultColDef,
      data: updatedUserList
    };
    return (
      <div
        className="card cardStyles childCompStyling"
        style={{ paddingBottom: "2%" }}
      >
        <div className="alertDivs">
          {_.size(this.state.activatedOrDisabled) &&
          this.state.activatedOrDisabled.status ? (
            <AlertSuccess
              message={this.state.activatedOrDisabled.msg}
              show={this.state.activatedOrDisabled.status}
            />
          ) : _.size(this.state.activatedOrDisabled) &&
            !this.state.activatedOrDisabled.status ? (
            <AlertDanger
              message={this.state.activatedOrDisabled.msg}
              show={this.state.activatedOrDisabled.status}
            />
          ) : null}
        </div>
        <div className="createClusterDivForSummary">
          <h4 className="promoHeading mb-0">Manage Users</h4>
          <div className="w-100 px-5 text-right">
            {this.state.showViewUser ? (
              <button
                id="btn-add-user"
                className="btn btn-primary"
                onClick={() => {
                  this.switchUserView("plus");
                }}
              >
                <i
                  className="fa fa-plus userViewChangeIcon"
                  title="Create user"
                />
              </button>
            ) : (
              <button
                id="btn-user-table"
                className="btn btn-primary"
                onClick={() => {
                  this.switchUserView("view");
                }}
              >
                <i
                  className="fa fa-table userViewChangeIcon"
                  title="View user list"
                />
              </button>
            )}
          </div>
          <PageLoader loader={this.state.tableDataLoader}>
            {this.state.showViewUser ? (
              <div
                className=" card cardStyles stylingForCompareCard"
                style={{ padding: "0.5%", marginTop: "3.5%" }}
              >
                <div className="card" style={{ width: "98%", height: "100%" }}>
                  <DataTable
                    parentId="manageuser-myGrid"
                    parentStyle={{
                      height: "calc(100vh - 300px)",
                      width: "100%"
                    }}
                    parentClass="ag-theme-balham"
                    tableData={tableData}
                    frameworkComponents={this.state.frameworkComponents}
                    // autoSizeCol={true} //col messed up if uncommented
                  />
                </div>
              </div>
            ) : (
              <CreateUser editUserData={this.state.editUserData} />
            )}
          </PageLoader>
        </div>
      </div>
    );
  }
}

ManageUser.propTypes = {
  getUsersList: PropTypes.func.isRequired,
  activateOrDisableUser: PropTypes.func.isRequired,
  loginAllAccess: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  usersList: state.userDataReducer.usersList,
  loader: state.userDataReducer.loader,
});

export default connect(mapStateToProps, {
  getUsersList,
  activateOrDisableUser,
  loginAllAccess
})(withRouter(ManageUser));

