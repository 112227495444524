import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  loginUser,
  forgotPassword,
  checkUserExistenceNSendOTP
} from "../../redux/actions/loginAction";
import PageLoader from "../../utils/Loader";
import { alertTimer } from "../../utils/alertTimer";
import AlertSuccess from "../../utils/AlertMessage/alertSuccess";
import AlertDanger from "../../utils/AlertMessage/alertDanger";
import "../../App.css";
import _ from "lodash";

export class Login extends Component {
  constructor(props) {
    super();
    this.state = {
      username: "",
      password: "",
      errors: null,
      loginActive: true,
      otpSent: false,
      newPassword: "",
      confirmPassword: "",
      otp: "",
      newPasswordErrors: "",
      passwordUpdated: false,
      passwordError: "",
      showLoader: false
    };
    this.onSubmit = this.onSubmit.bind(this);
  }
  componentWillMount(){
    this.props.history.push("/promotion");
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.isAuthenticated) {
      this.props.history.push("/promotion");
    }
    if (nextProps.errors && nextProps.errors !== this.props.errors) {
      if (!nextProps.errors.errors.error) {
        this.setState({ errors: "something went wrong" });
      }
      else if (Object.keys(nextProps.errors.errors.error).length) {
        this.setState({ errors: nextProps.errors.errors.error });
      } else {
        this.setState({ errors: nextProps.errors.errors.message });
      }
    }
  }

  onSubmit = e => {
    e.preventDefault();

    localStorage.setItem("startDate", "null");
    localStorage.setItem("endDate", "null");
    localStorage.setItem("quarter", "null");
    localStorage.setItem("fiscalMonth", "null");
    localStorage.setItem("campaignId", "null");
    localStorage.setItem("campaignName", "null");
    localStorage.setItem("customerGrp", "null");
    localStorage.setItem("businessDesc", "null");
    localStorage.setItem("shop", "null");

    this.props.loginUser({
      email: this.state.username,
      password: this.state.password
    });

    if (this.props.isAuthenticated) {
      this.props.history.push("/promotion");
    } else {
    }
  };
  onChangeEmail = e => {
    this.setState({
      username: e.target.value
    });
  };
  onChangePassword = e => {
    this.setState({
      password: e.target.value.trim()
    });
  };
  forgotPasswordHandler = async e => {
    e.preventDefault();
    this.setState({ showLoader: true})
    if (this.state.otpSent) {
      if (this.state.newPassword === this.state.confirmPassword) {
        let response = await this.props.forgotPassword({
          email: this.state.username,
          new_password: this.state.newPassword,
          otp: this.state.otp
        });
        if (response.status) {
          this.setState({
            passwordUpdated: true,
            otpSent: false,
            loginActive: true,
            newPassword: "",
            confirmPassword: "",
            otp: "",
            newPasswordErrors: "",
            otpError: ""
          });

          setTimeout(() => {
            this.setState({ passwordUpdated: false });
          }, alertTimer);
        } else {
          this.setState({ passwordError: response.msg });
          setTimeout(() => {
            this.setState({ passwordError: "" });
          }, alertTimer);
          //this.setState({ otpError: `${res.msg}. Please try again.` });
        }
      } else {
        this.setState({ newPasswordErrors: "Passwords do not match!" });
      }
    } else {
      let res = await this.props.checkUserExistenceNSendOTP({
        email: this.state.username
      });
      if (res.status) {
        this.setState({ otpSent: true });
      } else {
        // this.setState({ otpSent: true });
        this.setState({ otpError: `${res.msg}. Please try again.` });
      }
    }
    this.setState({ showLoader: false})
  };
  render() {
    const { errors } = this.state;
    if (this.props.isAuthenticated) {
      this.props.history.push("/promotion");
    }

    return (
      <>
        <div className="alertDivs">
          {this.state.passwordUpdated ? (
            <AlertSuccess
              message="Password updated successfully."
              show={this.state.passwordUpdated}
            />
          ) : null}
          {!_.isEmpty(this.state.passwordError) ? (
            <AlertDanger
              message={this.state.passwordError}
              show={this.state.passwordError.length}
            />
          ) : null}
        </div>
        <div
          className="d-md-flex h-md-100 align-items-center"
          style={{ background: "white", overflow: "hidden" }}
        >
          <div
            id="leftImgdiv"
            className="col-md-5 p-0 bg-indigo h-md-100"
            style={{ backgroundImage: "url(img/bg.jpg)" }}
          >
            <div className="divleftContent">
              <ul className="nav justify-content navTopLeft">
                <li className="nav-item">
                  <img
                    id="logoImgLeft"
                    src="./img/Logo - IA.png"
                    alt="Logo of the company"
                  />
                </li>
              </ul>
              <div className="text-white d-md-flex subWrapper">
                <div className="logoareaLeftText " style={{ width: "100%" }}>
                  <h5 className="promoTextLeft" style={{ color: "white" }}>
                    Promo$mart Enterprise
                  </h5>
                </div>
              </div>
            </div>
          </div>
          <div id="divRight" className="col-md-7 h-100 loginarea mt-5">
            <PageLoader loader={this.props.loader || this.state.showLoader}>
              <div className="container d-flex h-100">
                <div
                  id="signinform"
                  className="card row my-0 align-self-center"
                >
                  <form
                    className={
                      this.state.loginActive
                        ? "formWidth d-block"
                        : "formWidth d-none"
                    }
                    onSubmit={e => this.onSubmit(e)}
                  >
                    <div className="text-center ">
                      <img
                        id="imgStylingJoann"
                        src="../img/Tenant-Rep_logos_JOANN.png"
                        aria-hidden="true"
                        alt="Logo of the company"
                      />
                    </div>
                    <div className="form-group">
                      <input
                        name="email"
                        type="email"
                        className="form-control inputBorder"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        placeholder="E-mail"
                        value={this.state.username}
                        required
                        onChange={e => this.onChangeEmail(e)}
                      />
                    </div>
                    <div className="form-group">
                      <input
                        name="password"
                        type="password"
                        className="form-control inputBorder"
                        id="password"
                        value={this.state.password}
                        placeholder="Password"
                        required
                        onChange={e => this.onChangePassword(e)}
                      />
                      <div className="row">
                        <div className="col-md-6">
                          {" "}
                          <span className="loginErrors">{errors}</span>
                        </div>
                        <div className="col-md-6">
                          {" "}
                          <span id="forgtpas">
                            <small
                              className="d-block"
                              onClick={() =>
                                this.setState({ loginActive: false })
                              }
                            >
                              <p
                                className="text-cyan pt-2"
                                style={{ fontSize: "0.9rem" }}
                              >
                                Forgot your password?
                              </p>
                            </small>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div id="btndiv2">
                      <button
                        id="appLoginBtn"
                        type="submit"
                        className="btn btn-primary btn-square shadow-sm btnsignin"
                      >
                        Sign in
                      </button>
                    </div>
                  </form>

                  <form
                    className={
                      this.state.loginActive
                        ? "formWidth d-none"
                        : "formWidth d-block"
                    }
                    onSubmit={e => this.forgotPasswordHandler(e)}
                  >
                    <div className="text-center ">
                      <img
                        id="imgStylingJoann"
                        src="../img/Tenant-Rep_logos_JOANN.png"
                        aria-hidden="true"
                        alt="Logo of the company"
                      />
                    </div>
                    {this.state.otpSent ? null : (
                      <div className="form-group">
                        <input
                          name="email"
                          type="email"
                          className="form-control inputBorder"
                          id="exampleInputEmail"
                          aria-describedby="emailHelp"
                          placeholder="E-mail"
                          value={this.state.username}
                          required
                          onChange={e => this.onChangeEmail(e)}
                        />
                        <span className="forgot-passwrd-errors">
                          {this.state.otpError}
                        </span>
                      </div>
                    )}
                    {this.state.otpSent ? (
                      <>
                        <div className="form-group">
                          <p className="text-center pt-3">
                            <span className="user-email-font">
                              OTP successfully sent to &nbsp;
                            </span>
                            {this.state.username}
                          </p>
                        </div>
                        <div className="form-group row">
                          <label for="otp-field" className="col-5">
                            OTP :
                          </label>
                          <div className="col-7 right-arrow-hide">
                            <input
                              name="OTP"
                              type="number"
                              className="form-control inputBorder mt-0"
                              id="otp-field"
                              //aria-describedby="emailHelp"
                              placeholder="Enter OTP"
                              value={this.state.otp}
                              required
                              onChange={e =>
                                this.setState({ otp: e.target.value.trim() })
                              }
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label for="otp-field" className="col-5">
                            New Password :
                          </label>
                          <div className="col-7">
                            <input
                              name="password"
                              type="password"
                              className="form-control inputBorder mt-0"
                              id="new-password"
                              value={this.state.newPassword}
                              placeholder="New Password"
                              required
                              onChange={e =>
                                this.setState({
                                  newPassword: e.target.value.trim()
                                })
                              }
                            />
                            <span className="forgot-passwrd-errors">
                              {this.state.newPasswordErrors}
                            </span>
                          </div>
                        </div>
                        <div className="form-group row">
                          <label for="otp-field" className="col-5">
                            Confirm Password :
                          </label>
                          <div className="col-7">
                            <input
                              name="password"
                              type="password"
                              className="form-control inputBorder mt-0"
                              id="confirm-password"
                              value={this.state.confirmPassword}
                              placeholder="Confirm Password"
                              required
                              onChange={e =>
                                this.setState({
                                  confirmPassword: e.target.value.trim()
                                })
                              }
                            />
                            <span className="forgot-passwrd-errors">
                              {this.state.newPasswordErrors}
                            </span>
                          </div>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                    <div
                      id="btndiv2"
                      // style={{ textAlign: "center" }}
                    >
                      <div className="row">
                        <div className="col-6">
                          <button
                            id="appLoginBtn"
                            type="submit"
                            className="btn btn-primary btn-square shadow-sm btnsignin"
                          >
                            {this.state.otpSent
                              ? "Change Password"
                              : "Send OTP"}
                          </button>
                        </div>
                        <div className="col-6 text-right">
                          <p
                            className="text-cyan mt-4"
                            style={{ fontSize: "0.9rem" }}
                            onClick={() =>
                              this.setState({
                                otpSent: false,
                                loginActive: true
                              })
                            }
                          >
                            <i className="fa fa-arrow-left" />
                            &nbsp;Back to Login
                          </p>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div
                className="footer-copyright py-3"
                style={{
                  color: "gray",
                  textAlign: "right",
                  position: "fixed",
                  bottom: "0px",
                  right: "12px"
                }}
              >
                ©copyright@impactanalytics2019
              </div>
            </PageLoader>
          </div>
        </div>
      </>
    );
  }
}

// export default Login;
Login.propTypes = {
  loginUser: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  userData: state.userLoginDetails.userData,
  isAuthenticated: state.userLoginDetails.isAuthenticated,
  loader: state.userLoginDetails.loader,
  errors: state.errors
});

export default connect(mapStateToProps, {
  loginUser,
  checkUserExistenceNSendOTP,
  forgotPassword
})(withRouter(Login));
