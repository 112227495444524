import _ from "lodash";

export const AtypeOfferValidator = (values, regPrices, isRegCalculate = false) => {
  const {
    plannedAmount,
    adgroupName
  } = values;
  const {
    regLow,
    regHigh
  } = regPrices;
  let result = {
    isInvalid: false,
    invalidationMsg: ''
  }
  if (plannedAmount < 0) {
    result['isInvalid'] = true;
    result['invalidationMsg'] = `Please enter minimum value for Ad group ${adgroupName}`;

    return result;
  }
  if (!isRegCalculate && (parseFloat(plannedAmount) >= parseFloat(regLow))) {
    result['isInvalid'] = true;
    result['invalidationMsg'] = `Planned offer amount should be less than  ${regLow} for Ad group ${adgroupName}`;

    return result;
  }

  return result;
}

export const PtypeOfferValidator = (values, regPrices, isRegCalculate = false) => {
  const {
    plannedAmount,
    adgroupName
  } = values;
  const {
    regLow,
    regHigh
  } = regPrices;
  let result = {
    isInvalid: false,
    invalidationMsg: ''
  }

  if (plannedAmount <= 0 || plannedAmount > 99) {
    result['isInvalid'] = true;
    result['invalidationMsg'] = `Please enter value between 1-99 for Ad group ${adgroupName}`;

    return result;
  }

  if (plannedAmount % 1 !== 0) {
    result['isInvalid'] = true;
    result['invalidationMsg'] = `Entered fields cannot contain decimals for Ad group ${adgroupName}`;

    return result;
  }

  return result;

}

export const FtypeOfferValidator = (values, regPrices, isRegCalculate = false) => {
  const {
    plannedAmount,
    fixedPriceQty,
    adgroupName
  } = values;
  const {
    regLow,
    regHigh
  } = regPrices;
  let result = {
    isInvalid: false,
    invalidationMsg: ''
  }

  if (plannedAmount <= 0) {
    result['isInvalid'] = true;
    result['invalidationMsg'] = `Please enter minimum value for Ad group ${adgroupName}`;

    return result;
  }

  if (fixedPriceQty < 1) {
    result['isInvalid'] = true;
    result['invalidationMsg'] = `Please enter minimum fixed price quantity for Ad group ${adgroupName}`;

    return result;
  }

  if (fixedPriceQty % 1 !== 0) {
    result['isInvalid'] = true;
    result['invalidationMsg'] = `Fixed price quantity field cannot contain decimals for Ad group ${adgroupName}`;

    return result;
  }

  // if (!isRegCalculate && (parseFloat(plannedAmount) >= parseFloat(regLow))) {
  //   result['isInvalid'] = true;
  //   result['invalidationMsg'] = `Planned offer amount should be less than  ${regLow} for Ad group ${adgroupName}`;

  //   return result;
  // }

  return result;

}

export const GtypeOfferValidator = (values, regPrices, isRegCalculate = false) => {
  const {
    minQuantity,
    bonusBuy,
    buyMinVal,
    getMaxVal,
    adgroupName
  } = values;
  console.log(values, 'values')
  const {
    regLow,
    regHigh
  } = regPrices;
  let result = {
    isInvalid: false,
    invalidationMsg: ''
  }

  if (minQuantity <= 0 || bonusBuy <= 0 ||  parseFloat(buyMinVal) <= 0 || parseFloat(getMaxVal) <= 0 ) {
    result['isInvalid'] = true;
    result['invalidationMsg'] = `Please enter minimum value for Ad group ${adgroupName}`;

    return result;
  }

  if (minQuantity > 99 || bonusBuy > 99) {
    result['isInvalid'] = true;
    result['invalidationMsg'] = `Please enter value 1 - 99 for Ad group ${adgroupName}`;

    return result;
  }

  if (minQuantity % 1 !== 0 || bonusBuy % 1 !== 0) {
    result['isInvalid'] = true;
    result['invalidationMsg'] = `Min Qty and Bonus buy fields cannot contain decimals for Ad group ${adgroupName}`;

    return result;
  }

  if (!isRegCalculate && ((parseFloat(buyMinVal) < regLow || parseFloat(buyMinVal) > regHigh) ||  (parseFloat(getMaxVal) < regLow || parseFloat(getMaxVal) > regHigh))) {
    result['isInvalid'] = true;
    result['invalidationMsg'] = `Buy $ Min or Get $ Max should be between ${regLow} - ${regHigh} for Ad group ${adgroupName}`;

    return result;
  }

  return result;

}

export const BtypeOfferValidator = (values, regPrices, isRegCalculate = false) => {
  const {
    plannedAmount,
    minQuantity,
    bonusBuy,
    adgroupName
  } = values;
  const {
    regLow,
    regHigh
  } = regPrices;
  let result = {
    isInvalid: false,
    invalidationMsg: ''
  }

  if (minQuantity <= 0 || bonusBuy <= 0 || plannedAmount <= 0 || minQuantity > 99 || bonusBuy > 99 || plannedAmount > 99) {
    result['isInvalid'] = true;
    result['invalidationMsg'] = `Please enter value between 1 - 99 for Ad group ${adgroupName}`;

    return result;
  }

  if (minQuantity % 1 !== 0 || bonusBuy % 1 !== 0 || plannedAmount % 1 !== 0) {
    result['isInvalid'] = true;
    result['invalidationMsg'] = `Planned Amount, Min Qty and Bonus buy fields cannot contain decimals for Ad group ${adgroupName}`;

    return result;
  }

  return result;

}

export const CtypeOfferValidator = (values, regPrices, isRegCalculate = false) => {
  const {
    plannedAmount,
    minQuantity,
    bonusBuy,
    buyMinVal,
    getMaxVal,
    adgroupName
  } = values;
  const {
    regLow,
    regHigh
  } = regPrices;
  let result = {
    isInvalid: false,
    invalidationMsg: ''
  }

  if (minQuantity <= 0 || bonusBuy <= 0 || plannedAmount <= 0 || ( parseFloat(buyMinVal) <= 0) || ( parseFloat(getMaxVal) <= 0)) {
    result['isInvalid'] = true;
    result['invalidationMsg'] = `Please enter minimum value for Ad group ${adgroupName}`;

    return result;
  }

  if (minQuantity % 1 !== 0 || bonusBuy % 1 !== 0) {
    result['isInvalid'] = true;
    result['invalidationMsg'] = `Min Qty and Bonus buy fields cannot contain decimals for Ad group ${adgroupName}`;

    return result;
  }

  if (!isRegCalculate) {

    if (parseFloat(plannedAmount) >= parseFloat(regLow)) {
      result['isInvalid'] = true;
      result['invalidationMsg'] = `Planned Offer amount should be lesser than  ${regLow} for Ad group ${adgroupName}`;
    }
    if ( (parseFloat(buyMinVal) < regLow || parseFloat(buyMinVal) > regHigh) || (parseFloat(getMaxVal) < regLow || parseFloat(getMaxVal) > regHigh)) {
      result['isInvalid'] = true;
      result['invalidationMsg'] = `Buy $ Min or Get $ Max should be between ${regLow} - ${regHigh} for Ad group ${adgroupName}`;
    }

    return result;
  }

  return result;

}

export const DtypeOfferValidator = (values, regPrices, isRegCalculate = false) => {
  const {
    plannedAmount,
    minQuantity,
    adgroupName
  } = values;
  const {
    regLow,
    regHigh
  } = regPrices;
  let result = {
    isInvalid: false,
    invalidationMsg: ''
  }

  if (minQuantity <= 0 || plannedAmount <= 0 || minQuantity > 99 || plannedAmount > 99) {
    result['isInvalid'] = true;
    result['invalidationMsg'] = `Please enter value between 1 - 99 for Ad group ${adgroupName}`;

    return result;
  }

  if (minQuantity % 1 !== 0 || plannedAmount % 1 !== 0) {
    result['isInvalid'] = true;
    result['invalidationMsg'] = `Planned Amount or Min Qty fields cannot contain decimals for Ad group ${adgroupName}`;

    return result;
  }

  return result;

}
