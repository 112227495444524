import React, { Component } from "react";
import Promotion from "./components/Content/Promotions/Promo Calendar/PromoCalendar";
import ExecutiveSummary from "./components/Content/Promotions/Promo Summary/PromoSummary";
import DayLevelOfferView from "./components/Content/Promotions/DayLevelOffer/DayLevelOffer";
import store from "./redux/store/store";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Login from "./components/Login/login";
import ComparePromotion from "./components/Content/Promotions/PromoCompare/ComparePromotionNew";
import PromotionMain from "./components/Content/Promotions/PromotionMain";
import ManageUsers from "./components/Content/Promotions/Users/ManageUser";
import Header from "./components/Header/header";
import Breadcrumbs from "./components/Breadcrumb/PromoContainer";
import SideBarMini from "./components/SideBarMini/SideBarMini";
import PrivateRoute from "./utils/privateRoute";
import ErrRoute from "./utils/ErrRoute";
import ErrorMsg from "./utils/errorPages/errorMsgComponent";
import MaintenancePageComponent from "./utils/errorPages/maintenancePageComponent";
import CreateAdGroup from "./components/Content/Promotions/Adgroup/AdGroupTab";
import CreateOffer from "./components/Content/Promotions/Adgroup/CreateOffer";
import CreateCoupon from "./components/Content/Promotions/Coupons/CreateCouponsMain";
import EditCoupon from "./components/Content/Promotions/Coupons/EditCoupon";
import ReferenceSku from "./components/Content/Promotions/ReferenceSku/ReferenceSku";
import AdgroupMaster from "./components/Content/Promotions/AdgroupMaster/AdgroupMasterRoot";
import EventMaster from "./components/Content/Promotions/EventMaster/EventMaster";
import EditEvent from "./components/Content/Promotions/EventMaster/EditEvent";
import PromoApproval from "./components/Content/Promotions/PromoApproval/promoApproval";
import MojoSupport from "./components/Support/index";
import SessionTimedOutModal from "../src/utils/AlertMessage/sessionTimedOut";
import CookieModal from "../src/utils/AlertMessage/CookieAlert";
import ReportContainer from "./components/Content/Promotions/Reports/ReportContainer";
import "ag-grid-enterprise";
// import "ag-grid-community/dist/styles/ag-grid.css";
// import "ag-grid-community/dist/styles/ag-theme-blue.css";

import { LicenseManager } from "ag-grid-enterprise";
// import EditAdGroup from "./components/Content/Promotions/Adgroup/EditAdGroup";
import UploadOffers from "./components/Content/Promotions/Promo Pipeline/UploadOffersModal";
import EditAdGroup from "./components/Content/Promotions/AdgroupMaster/EditAdgroup";

LicenseManager.setLicenseKey(
  "Evaluation_License_Not_For_Production_3_February_2020__MTU4MDY4ODAwMDAwMA==b68c41efad3ff4943ee91918798205e7"
);
// append the route path to privateRouteVar
let privateRouteVar =
  "/(promotion|validation|shop|executivesummary|dayleveloffer|comparepromotion|historicperformane|createpromotion|eda|createadgroup|manageUsers|createcoupon|editcoupon|editadgroup|referenceSku|sessiontimedout|adgroupMaster|ticketGenerate|uploadOffers|eventMaster|createEvent|editEvent|viewEvent|createoffer|promoApproval|reports)";

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <Router>
          <Switch>
            <Route exact path="/" component={Login} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/error" component={ErrorMsg} />
            <Route exact path="/cookiedisabled" component={CookieModal} />
            <Route
              exact
              path="/maintenance"
              component={MaintenancePageComponent}
            />
            <Route
              exact
              path="/sessiontimedout"
              component={SessionTimedOutModal}
            />
            <div className="wrapper">
              <div id="content">
                <Route path={privateRouteVar} component={Header} />
                <PrivateRoute path={privateRouteVar} component={Breadcrumbs} />
                <div id="subcontent">
                  <PrivateRoute exact path="/promotion" component={Promotion} />
                  <PrivateRoute
                    exact
                    path="/executivesummary"
                    component={ExecutiveSummary}
                  />
                  <PrivateRoute
                    exact
                    path="/dayleveloffer"
                    component={DayLevelOfferView}
                  />
                  <PrivateRoute
                    exact
                    path="/comparepromotion"
                    component={ComparePromotion}
                  />
                  <PrivateRoute
                    exact
                    path="/createpromotion"
                    component={PromotionMain}
                  />
                  <PrivateRoute
                    exact
                    path="/createadgroup"
                    component={CreateAdGroup}
                  />

                  <PrivateRoute
                    exact
                    path="/createoffer"
                    component={CreateOffer}
                  />

                  <PrivateRoute
                    exact
                    path="/manageusers"
                    component={ManageUsers}
                  />

                  <PrivateRoute
                    exact
                    path="/createcoupon"
                    component={CreateCoupon}
                  />

                  <PrivateRoute
                    exact
                    path="/editcoupon"
                    component={EditCoupon}
                  />

                  <PrivateRoute
                    exact
                    path="/editadgroup"
                    component={EditAdGroup}
                  />

                  <PrivateRoute
                    exact
                    path="/referenceSku"
                    component={ReferenceSku}
                  />

                  <PrivateRoute
                    exact
                    path="/adgroupMaster"
                    component={AdgroupMaster}
                  />

                  <PrivateRoute
                    exact
                    path="/eventMaster"
                    component={EventMaster}
                  />

                  <PrivateRoute
                    exact
                    path="/createEvent"
                    component={EditEvent}
                  />

                  <PrivateRoute
                    exact
                    path="/editEvent"
                    component={EditEvent}
                  />

                  <PrivateRoute
                    exact
                    path="/viewEvent"
                    component={EditEvent}
                  />

                  <PrivateRoute
                    exact
                    path="/promoApproval"
                    component={PromoApproval}
                  />

                   <PrivateRoute
                    exact
                    path="/reports"
                    component={ReportContainer}
                  />

                  <PrivateRoute
                    exact
                    path="/ticketGenerate"
                    component={MojoSupport}
                  />

                  <PrivateRoute
                    exact
                    path="/uploadOffers"
                    component={UploadOffers}
                  />
                </div>
                <Route path={privateRouteVar} component={SideBarMini} />
                <ErrRoute component={ErrorMsg} />
              </div>
            </div>
          </Switch>
        </Router>
      </Provider>
    );
  }
}

export default App;
