import React from "react";
import Modal from "react-awesome-modal";
import PageLoader from "../../../../utils/Loader";
import { deleteAdgroup } from "../../../../redux/actions/adgroup";
import DataTable from "../../../tables";
import ColumnDef from "../../../tables/colDefs/PipelineColDefs";
import AlertSuccess from "../../../../utils/AlertMessage/alertSuccess";
import _ from "lodash";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import DeleteConfirmationModal from "../Adgroup/DeleteConfirmationModal";
import moment from "moment";
import { AdgroupMasterGA } from '../../../../utils/GA/GAUtils';
import ApprovalStatusRenderer from '../Promo Pipeline/ApprovalStatusRenderer';
import { alertTimer } from "../../../../utils/alertTimer";
import { isIt } from "../../../../utils/getRoleName";

class DeleteAdgroup extends React.Component {
  state = {
    selectedRowsToDelete: null,
    showLoader: false,
    showConfirmAlert: false,
    isSuccess: false,
    successMessage: "",
    deleteErrorTableData : {},
  };

  deleteAdgroupFromThisDm = async () => {
    var campaignId = [],
      adgroupId = [],
      promoId=[],
      eventId=[];

    this.setState({
      showLoader: true,
      deleteErrorTableData : _.cloneDeep({}),
    });
    this.state.selectedRowsToDelete.map(rowData => {
      promoId.push(rowData.promo_id);
      adgroupId.push(rowData.adgroup_id);
      campaignId.push(rowData.campaign_id);
      eventId.push(rowData.event_id);
    });
    // return;
    
    let response = await this.props.deleteAdgroup({
      adgroupId: adgroupId,
      campaignId: campaignId[0],
      promoId: promoId,
      eventId : eventId
      // promoStartDate: ""
    });

    let foundPromoApprovalRes = _.filter(response.data, { inPromoApproval: true });
    let duplicateArppovalRecord = _.filter(response.data, { status: false });

    if (response.success) {

      this.setState({
        isSuccess: true,
        successMessage: `Offers deactivated successfully`,
        showLoader: false
      })

      setTimeout(() => {
        this.setState({
          isSuccess: false,
          successMessage: ''
        });
      this.props.updatePipelineData(adgroupId, "delete");
      this.props.closeDeleteModal();
      }, alertTimer);
     
    } else if(response.status) {
      if(foundPromoApprovalRes && foundPromoApprovalRes.length > 0 && foundPromoApprovalRes[0].inPromoApproval) {
        this.setState({
          isSuccess: true,
          successMessage: "Promo offer/s has been submitted for approval",
          showLoader: false
        })
  
        setTimeout(() => {
          this.setState({
            isSuccess: false,
            successMessage: ''
          });
        if(!(duplicateArppovalRecord && duplicateArppovalRecord.length > 0)){
          this.props.closeDeleteModal();
          this.setState({showLoader:false})
        }
        }, alertTimer);
      }
      
      if(duplicateArppovalRecord && duplicateArppovalRecord.length > 0) {
          this.setState({errorFromPromoApproval : true})
          let errorTableData = duplicateArppovalRecord.map(error => {
          return {
            adgroup_name: error.adgroup_name,
            event_detail: error.ty_event,
            offer_type : error.offer_type,
            offer_value : error.offer_title,
            validationErrors : error.validationErrors,
            status : error.status,
          };
      });
      let tableData = {
        header: ColumnDef.deactivateApprovalErrorData,
        defaultColDef: ColumnDef.defaultColDef,
        data : errorTableData
      }
      this.setState({ deleteErrorTableData: _.cloneDeep(tableData), showLoader : false });
      }

    }else {
      this.props.history.push("/promotion");
    }
  };

  showConfirmAlert = e => {
    this.setState({ showConfirmAlert: !this.state.showConfirmAlert });
  };

  deleteAllDmSave = async () => {
    var adgroupId = [],
    adgroupId = [],
    promoId=[],
      campaignStartDate = [];

    this.setState({
      showLoader: true
    });

    this.state.selectedRowsToDelete.map(rowData => {
      // campaignStartDate.push(rowData.campaign_start_date);
      // adgroupId.push(rowData.promo_id);
      promoId.push(rowData.promo_id);
      adgroupId.push(rowData.adgroup_id);
      // adgroupId.push(rowData.adgroup_id);
    });

    let response = await this.props.deleteAdgroup({
      promoId: promoId,
      adgroupId: adgroupId,
      campaignId: null
      // promoStartDate: moment(campaignStartDate[0]).format("YYYY-MM-DD")
    });

    if (response.status) {
      this.props.updatePipelineData(adgroupId,"delete");
      this.setState({
        showLoader: false
      });
      this.showConfirmAlert();
    } else {
      this.props.history.push("/promotion");
    }
  };

  onDeleteSelectionChanged = rowCount => {
    this.setState({ selectedRowsToDelete: rowCount });
  };
  submitGA(action,label){
    AdgroupMasterGA({ action, label});
  }
  render() {
    let tableData = {
      header: ColumnDef.deleteHeader,
      defaultColDef: ColumnDef.deleteDefaultColDef,
      data: this.props.tableData
    };
    return (
      <Modal
        visible={true}
        width="90%"
        height="85%"
        effect="fadeInDown"
        onClickAway={() => this.props.closeDeleteModal()}
      >
        <PageLoader loader={this.state.showLoader}>
        <div className="alertDivs">
              {this.state.isSuccess &&
                <AlertSuccess
                  message={this.state.successMessage}
                  show={this.state.isSuccess}
                />
              }
        </div>
          <div className="closeicon">
            <span style={{ cursor: "pointer" }}>
              <i
                className="fa fa-times"
                aria-hidden="true"
                onClick={() => this.props.closeDeleteModal()}
              />
            </span>
            <h5 className="promoNameText text-center">Deactivate Offers</h5>
          </div>

          <div className="row mx-5">
            <div className="col-md-12">
              <div className="row" style={{ marginLeft: "12px" }}>
                <div className="col-md-4">
                  <span className="promoNameText row">
                    Promotion Name :{" "}
                    <p className="promonameResult">
                    {`${moment(this.props.campaignStartDate).format("MM/DD/YYYY")} - ${moment(this.props.campaignEndDate).format("MM/DD/YYYY")}`}
                    </p>
                  </span>
                </div>
                <div className="col-md-3">
                  <span
                    className="promoNameText row"
                    style={{ paddingLeft: "2%" }}
                  >
                    Start Date : {" "}
                    <p className="promonameResult">
                      {this.props.campaignStartDate}
                    </p>
                  </span>
                </div>
                <div className="col-md-3">
                  <span
                    className="promoNameText row"
                    style={{ paddingLeft: "2%" }}
                  >
                    End Date : {" "}
                    <p className="promonameResult">
                      {this.props.campaignEndDate}
                    </p>
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="delete-ag-grid-cont">
            <DataTable
              tableData={tableData}
              parentId="delete-ag-grid"
              parentStyle={{
                height : "65%",
                width: "100%",
                position: "relative",
              }}
              parentClass="ag-theme-balham"
              overlayNoRowsTemplate='<span class="pipeline-span">No Data to show</span>'
              animateRows={true}
              autoSizeCol={true}
              rowSelection="multiple"
              selectOfferRow={this.onDeleteSelectionChanged}
            />
          </div>

          <div className="text-center mt-2">
            <button
              className="btn btn-outline-primary promo3Buttons"
              disabled={ (_.size(this.state.selectedRowsToDelete) && !isIt()) ? false : true}
              onClick={() => {
                this.deleteAdgroupFromThisDm();
                this.submitGA("Click", "Clicked_DeleteFromCurrent");
              }}
            >
              Deactivate Offers
            </button>
            {/* <button
              className="btn btn-outline-primary ml-3 promo3Buttons"
              disabled={_.size(this.state.selectedRowsToDelete) ? false : true}
              onClick={() => this.showConfirmAlert()}
            >
              Delete From All DM
            </button> */}
          </div>

          {
            this.state.deleteErrorTableData.data && this.state.deleteErrorTableData.data.length > 0 &&
            <>
            <p className="errorMessage" >Following offers cannot be deactivated</p>
            <div className="copyOffertableContainer">
            <DataTable
              tableData={this.state.deleteErrorTableData}
              parentId="myGridForCopyPromoErrorDetails"
              // parentId="delete-ag-grid"
              parentStyle={{
                height : "20vh",
                width: "100%",
                position: "relative",
              }}
              frameworkComponents={{
                approvalStatusRenderer: ApprovalStatusRenderer
            }}
              animateRows={true}
              autoSizeCol={true}
            />
            </div>
            </>
          }

        </PageLoader>

        {this.state.showConfirmAlert ? (
          <DeleteConfirmationModal
            showConfirmAlert={this.showConfirmAlert}
            deleteAllDmSave={this.deleteAllDmSave}
          />
        ) : null}
      </Modal>
    );
  }
}

DeleteAdgroup.propTypes = {
  deleteAdgroup: PropTypes.func.isRequired
};
const mapStateToProps = state => ({});
export default connect(
  mapStateToProps,
  { deleteAdgroup }
)(withRouter(DeleteAdgroup));
