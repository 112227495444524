import React, { useState } from "react";
import "./EventMasterStyling.css";

const EventNameRenderer = (props) => {

  const handleClick = () => {
    props.data.viewEvent(props.data);
  }

  return (
    <>
      <p className="eventName">
        {props.data.g_eid}
        {props.data.error_field && <i
          className="fa fa-exclamation-triangle"
          style={{ color: "orange", margin: "auto" }}
          aria-hidden="true"
          title="Campaign dates shifted"
        />}
      </p>
    </>
  )
}

export default EventNameRenderer;