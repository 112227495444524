import {
  SIMULATION_DATA,
  LOADER,
  SAVE_PROMO_POST_SIMULATION_DATA,
  CLEAR_SIMULATION_DATA,
  CLEAR_SAVE_SIMULATION_DATA,
  OFFER_DATA,
  GET_OFFER_DATA,
  DELETE_ADGROUP_DATA,
  SAVE_COMMENT
} from "../actions/types";
const initialState = {
  simulationData: [],
  saveSimulationData: [],
  offerData: [],
  deleteOfferData: [],
  getOfferData: [],
  saveCommentData: [],
  loader: false
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SIMULATION_DATA:
      return {
        ...state,
        simulationData: action.payload
      };

    case SAVE_PROMO_POST_SIMULATION_DATA:
      return {
        ...state,
        saveSimulationData: action.payload
      };
    case LOADER:
      return {
        ...state,
        loader: action.payload
      };
    case CLEAR_SIMULATION_DATA:
      return {
        simulationData: {}
      };

    case CLEAR_SAVE_SIMULATION_DATA:
      return {
        saveSimulationData: {}
      };

    case OFFER_DATA:
      return {
        ...state,
        offerData: action.payload
      };

    case DELETE_ADGROUP_DATA:
      return {
        deleteOfferData: action.payload
      };

    case GET_OFFER_DATA:
      return {
        ...state,
        getOfferData: action.payload
      };

    case SAVE_COMMENT:
      return {
        ...state,
        saveCommentData: action.payload
      };

    default:
      return state;
  }
}
