import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import PropTypes from "prop-types";
import { Card, Accordion, Row, Col } from 'react-bootstrap';
import { InputNumber, Typography, Divider, Select, Input, Space, Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
const { Title, Text } = Typography;

// const {
//   // MERCHANDISE_HIERARCHY_LIST,

//   // MERCH_CAT_OPTIONS,
//   // CATEGORY_OPTIONS,
//   // SHOP_DESCRIPTION_OPTIONS,
//   // AREA_OPTIONS,
//   // BUSINESS_OPTIONS,

//   // CUSTOMERGROUP_OPTIONS,
//   VENDOR_OPTIONS,
//   ATTRIBUTE_COLOR_OPTIONS,
//   ATTRIBUTE_FABRIC_OPTIONS,
//   ATTRIBUTE_SIZE_OPTIONS,
//   ATTRIBUTE_THEME_OPTIONS,
//   PRODUCT_BRAND_OPTIONS
// } = require('../utils/adgroup-constants');

class ExcludeAttributes extends Component {

  constructor(props) {
    super(props);
    
    this.state = {
      rppInputName: '',
      rppItemList: [],
      counter: 0
    };
  }
  
  componentDidMount() {
    const { selectedRppSpfc_EXC_ATTR } = this.props.stateObject;
    if (!_.isEmpty(selectedRppSpfc_EXC_ATTR)) {
      this.setState({ rppItemList: selectedRppSpfc_EXC_ATTR });
    }
  }
  marketingDisabled() {
    let role = localStorage.getItem('role')
    if(['3'].indexOf(role) !== -1) {
      return true;
    }
    return false;
  }
  // componentWillReceiveProps(nextProps) {
  //   if (nextProps.stateObject !== this.props.stateObject) {
  //     // console.log('Difference')
  //     this.setState({ counter: this.state.counter +1 });
  //   }
  // }

  onRppInputChange = (event) => {
    this.setState({ rppInputName: event });
  };
  
  addItem = (e) => {
    e.preventDefault();
    const oldList = this.state.rppItemList;
    const oldItemName = this.state.rppInputName;
    if(!_.isNumber(oldItemName)) {
      return;
    }
    this.setState({ 
      rppItemList: [...oldList, oldItemName],
      rppInputName: ''
    });
  };

  
  render() {
    const {
      selectedCustomerGroup_EXC_ATTR,
      selectedBusiness_EXC_ATTR,
      selectedArea_EXC_ATTR,
      selectedShopDesc_EXC_ATTR,
      selectedCategory_EXC_ATTR,
      selectedMerchCat_EXC_ATTR,

      selectedCharCollection_EXC_ATTR,
      selectedVendor_EXC_ATTR,
      selectedArticleStatus_EXC_ATTR,
      selectedRppMinValue_EXC_ATTR,
      selectedRppMaxValue_EXC_ATTR,
      selectedRppSpfc_EXC_ATTR,
      selectedAttrColor_EXC_ATTR,
      selectedAttrFabric_EXC_ATTR,
      selectedAttrSize_EXC_ATTR,
      selectedAttributeTheme_EXC_ATTR,
      selectedProductBrand_EXC_ATTR,

      availableCustomerGroupOptions_EXC_ATTR,
      availableBusinessOptions_EXC_ATTR,
      availableAreaOptions_EXC_ATTR,
      availableShopDescOptions_EXC_ATTR,
      availableCategoryOptions_EXC_ATTR,
      availableMerchCatOptions_EXC_ATTR,
      collectionOptions ,
      vendorOptions ,
      articleStatusOptions ,
      productBrandOptions ,
      attributeColorOptions ,
      attributeFabricOptions ,
      attributeSizeOptions ,
      attributeThemeOptions,
    } = this.props.stateObject;

    const {
      //  collectionOptions, articleStatusOptions, 
    handleSelectionChange_EXC_ATTR } = this.props;
    const { rppInputName, rppItemList } = this.state;

    return (
      <Card>
        <Card.Body style={{ padding: '0px 0px 0px 0px'}}>
          <Card.Body>
            <Row>
              <Col>
                <Title level={4}>
                  <Text strong>Hierarchy</Text>
                </Title>
              </Col>
            </Row>
            <Row style={{ marginTop: '5px', marginBottom: '15px'}}>
              <Col lg>
                <p className="summaryPText">Customer Group</p>
                <Select
                  placeholder="Select Customer Group"
                  value={selectedCustomerGroup_EXC_ATTR}
                  options={availableCustomerGroupOptions_EXC_ATTR}
                  onChange={(v) => handleSelectionChange_EXC_ATTR(v,'selectedCustomerGroup_EXC_ATTR')}
                  style={{ width: '100%'}}
                  mode="multiple"
                  allowClear={false}
                  showSearch={true}
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                    option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  disabled = {this.marketingDisabled()}
                />
              </Col>
              <Col lg>
                <p className="summaryPText">Business</p>
                {/* {availableBusinessOptions_EXC_ATTR.filter(obj=> !obj["disabled"]).length} -{availableBusinessOptions_EXC_ATTR.filter(obj=> obj["disabled"]).length} */}
                <Select
                  placeholder="Select Business"
                  value={selectedBusiness_EXC_ATTR}
                  options={availableBusinessOptions_EXC_ATTR}
                  onChange={(v) => handleSelectionChange_EXC_ATTR(v,'selectedBusiness_EXC_ATTR')}
                  style={{ width: '100%'}}
                  mode="multiple"
                  allowClear={false}
                  showSearch={true}
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                    option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  disabled = {this.marketingDisabled()}
                />
              </Col>
              {/* <Col lg>
                <p className="summaryPText">Area</p>
                <Select
                  placeholder="Select Area"
                  value={selectedArea_EXC_ATTR}
                  options={availableAreaOptions_EXC_ATTR}
                  onChange={(v) => handleSelectionChange_EXC_ATTR(v,'selectedArea_EXC_ATTR')}
                  style={{ width: '100%'}}
                  mode="multiple"
                  allowClear={false}
                />
              </Col> */}
              <Col lg>
                <p className="summaryPText">Shop Description</p>
                {/* {availableShopDescOptions_EXC_ATTR.filter(obj=> !obj["disabled"]).length} -{availableShopDescOptions_EXC_ATTR.filter(obj=> obj["disabled"]).length} */}
                <Select
                  placeholder="Select Shop Description"
                  value={selectedShopDesc_EXC_ATTR}
                  options={availableShopDescOptions_EXC_ATTR}
                  onChange={(v) => handleSelectionChange_EXC_ATTR(v,'selectedShopDesc_EXC_ATTR')}
                  style={{ width: '100%'}}
                  mode="multiple"
                  allowClear={false}
                  showSearch={true}
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                    option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  disabled = {this.marketingDisabled()}
                />
                {/* {JSON.stringify(availableShopDescOptions_EXC_ATTR.filter(obj=> !obj["disabled"]).map(obj=> obj.value))} */}
              </Col>
              <Col lg>
                <p className="summaryPText">Category</p>
                {/* {availableCategoryOptions_EXC_ATTR.filter(obj=> !obj["disabled"]).length} -{availableCategoryOptions_EXC_ATTR.filter(obj=> obj["disabled"]).length} */}
                <Select
                  placeholder="Select Category"
                  value={selectedCategory_EXC_ATTR}
                  options={availableCategoryOptions_EXC_ATTR}
                  onChange={(v) => handleSelectionChange_EXC_ATTR(v,'selectedCategory_EXC_ATTR')}
                  style={{ width: '100%'}}
                  mode="multiple"
                  allowClear={false}
                  showSearch={true}
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                    option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  disabled = {this.marketingDisabled()}
                />
                {/* {JSON.stringify(availableCategoryOptions_EXC_ATTR.filter(obj=> !obj["disabled"]).map(obj=> obj.value))} */}
              </Col>
            </Row>

            <Row style={{ marginTop: '5px', marginBottom: '0px'}}>
              <Col lg>
                <p className="summaryPText">Merchandise Category</p>
                {/* {availableMerchCatOptions_EXC_ATTR.filter(obj=> !obj["disabled"]).length} -{availableMerchCatOptions_EXC_ATTR.filter(obj=> obj["disabled"]).length} */}
                <Select
                  placeholder="Select Merchandise Category"
                  value={selectedMerchCat_EXC_ATTR}
                  options={availableMerchCatOptions_EXC_ATTR}
                  onChange={(v) => handleSelectionChange_EXC_ATTR(v,'selectedMerchCat_EXC_ATTR')}
                  style={{ width: '100%'}}
                  mode="multiple"
                  allowClear={false}
                  showSearch={true}
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                    option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  disabled = {this.marketingDisabled()}
                />
              </Col>
              <Col lg />
              <Col lg />
              <Col lg />
            </Row>

            <Divider style={{ marginTop: '15px', marginBottom: '10px'}}/>

            <Row>
              <Col>
                <Title level={4}>
                  <Text strong>Attributes</Text>
                </Title>
              </Col>
            </Row>
            <Row style={{ marginTop: '5px', marginBottom: '15px'}}>
              <Col lg>
                <p className="summaryPText">Collection</p>
                <Select
                  placeholder="Select Collection"
                  value={selectedCharCollection_EXC_ATTR}
                  options={collectionOptions}
                  onChange={(v) => handleSelectionChange_EXC_ATTR(v,'selectedCharCollection_EXC_ATTR')}
                  style={{ width: '100%'}}
                  mode="multiple"
                  allowClear={false}
                  showSearch={true}
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                    option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  disabled = {this.marketingDisabled()}
                />
              </Col>
              <Col lg>
                <p className="summaryPText">Vendor</p>
                <Select
                  placeholder="Select Vendor"
                  value={selectedVendor_EXC_ATTR}
                  options={vendorOptions}
                  onChange={(v) => handleSelectionChange_EXC_ATTR(v,'selectedVendor_EXC_ATTR')}
                  style={{ width: '100%'}}
                  mode="multiple"
                  allowClear={false}
                  showSearch={true}
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    // option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                    // option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  disabled = {this.marketingDisabled()}
                />
              </Col>
              <Col lg>
                <p className="summaryPText">Article Status</p>
                <Select
                  placeholder="Select Article Status"
                  value={selectedArticleStatus_EXC_ATTR}
                  options={articleStatusOptions}
                  onChange={(v) => handleSelectionChange_EXC_ATTR(v,'selectedArticleStatus_EXC_ATTR')}
                  style={{ width: '100%'}}
                  mode="multiple"
                  allowClear={false}
                  showSearch={true}
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                    option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  disabled = {this.marketingDisabled()}
                />
              </Col>
              <Col lg>
                <p className="summaryPText">Product Brand</p>
                  <Select
                    placeholder="Select Product Brand"
                    value={selectedProductBrand_EXC_ATTR}
                    options={productBrandOptions}
                    onChange={(v) => handleSelectionChange_EXC_ATTR(v,'selectedProductBrand_EXC_ATTR')}
                    style={{ width: '100%'}}
                    mode="multiple"
                    allowClear={false}
                    showSearch={true}
                    filterOption={(input, option) =>
                      option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                      option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    disabled = {this.marketingDisabled()}
                  />
              </Col>
              {/* <Col lg>
                <p className="summaryPText">Retail Price Point</p>
                <Text className="summaryPText">Min </Text>
                <InputNumber
                  placeholder="Min"
                  style={{ width: '35%'}}
                  value={selectedRppMinValue_EXC_ATTR}
                  min={0}
                  max={100000000}
                  step={0.1}
                  addonBefore={`$`}
                  // formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  // parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                  onChange={(v, y) => handleSelectionChange_EXC_ATTR(v,'selectedRppMinValue_EXC_ATTR')}
                  keyboard
                />
                <Text className="summaryPText" style={{ marginLeft: '10px'}}>Max </Text>
                <InputNumber
                  placeholder="Max"
                  style={{ width: '35%'}}
                  value={selectedRppMaxValue_EXC_ATTR}
                  min={0}
                  max={100000000}
                  step={0.1}
                  addonBefore={`$`}
                  // formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  // parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                  onChange={(v, y) => handleSelectionChange_EXC_ATTR(v,'selectedRppMaxValue_EXC_ATTR')}
                  keyboard
                />
              </Col> */}
            </Row>

            <Row style={{ marginTop: '15px', marginBottom: '15px'}}>
              <Col lg>
                <p className="summaryPText">Attribute Color</p>
                <Select
                  placeholder="Select Attribute Color"
                  value={selectedAttrColor_EXC_ATTR}
                  options={attributeColorOptions}
                  onChange={(v) => handleSelectionChange_EXC_ATTR(v,'selectedAttrColor_EXC_ATTR')}
                  style={{ width: '100%'}}
                  mode="multiple"
                  allowClear={false}
                  showSearch={true}
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                    option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  disabled = {this.marketingDisabled()}
                />
              </Col>
              <Col lg>
                <p className="summaryPText">Attribute Fabric</p>
                <Select
                  placeholder="Select Attribute Fabric"
                  value={selectedAttrFabric_EXC_ATTR}
                  options={attributeFabricOptions}
                  onChange={(v) => handleSelectionChange_EXC_ATTR(v,'selectedAttrFabric_EXC_ATTR')}
                  style={{ width: '100%'}}
                  mode="multiple"
                  allowClear={false}
                  showSearch={true}
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                    option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  disabled = {this.marketingDisabled()}
                />
              </Col>
              <Col lg>
                <p className="summaryPText">Attribute Size</p>
                <Select
                  placeholder="Select Attribute Size"
                  value={selectedAttrSize_EXC_ATTR}
                  options={attributeSizeOptions}
                  onChange={(v) => handleSelectionChange_EXC_ATTR(v,'selectedAttrSize_EXC_ATTR')}
                  style={{ width: '100%'}}
                  mode="multiple"
                  allowClear={false}
                  showSearch={true}
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                    option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  disabled = {this.marketingDisabled()}
                />
              </Col>
              <Col lg>
                <p className="summaryPText">Attribute Theme</p>
                <Select
                  placeholder="Select Attribute Theme"
                  value={selectedAttributeTheme_EXC_ATTR}
                  options={attributeThemeOptions}
                  onChange={(v) => handleSelectionChange_EXC_ATTR(v,'selectedAttributeTheme_EXC_ATTR')}
                  style={{ width: '100%'}}
                  mode="multiple"
                  allowClear={false}
                  showSearch={true}
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                    option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  disabled = {this.marketingDisabled()}
                />
              </Col>
            </Row>
            <Row>
              <Col lg>
                <p className="summaryPText">Retail Price Point(Specific)</p>
                <Select
                  style={{
                    width: 300,
                  }}
                  placeholder="Add Retail Price Point"
                  mode="multiple"
                  allowClear={false}
                  dropdownRender={(menu) => (
                    <>
                      {menu}
                      <Divider
                        style={{
                          margin: '8px 0',
                        }}
                      />
                      <Space
                        style={{
                          padding: '0 8px 4px',
                        }}
                      >
                        {/* <Input
                          placeholder="Please enter retail price"
                          // ref={inputRef}
                          value={rppInputName}
                          onChange={this.onRppInputChange}
                        /> */}
                        <InputNumber
                          placeholder="Please enter retail price point"
                          value={rppInputName}
                          min={0}
                          max={100000000}
                          step={0.1}
                          addonBefore={`$`}
                          // formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                          // parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                          onChange={this.onRppInputChange}
                          keyboard
                        />
                        <Button type="text" icon={<PlusOutlined />} onClick={this.addItem}>
                          Add price
                        </Button>
                      </Space>
                    </>
                  )}
                  options={rppItemList.map((item) => ({
                    label: item,
                    value: item,
                  }))}
                  onChange={(v) => handleSelectionChange_EXC_ATTR(v,'selectedRppSpfc_EXC_ATTR')}
                  value={selectedRppSpfc_EXC_ATTR}
                  disabled = {this.marketingDisabled()}
                />
              </Col>
              <Col lg/>
              <Col lg/>
              <Col lg/>
            </Row>

          </Card.Body>
        </Card.Body>
      </Card>
    );
  }
}

ExcludeAttributes.propTypes = {
  collectionOptions: PropTypes.array.isRequired,
  articleStatusOptions: PropTypes.array.isRequired,
  handleSelectionChange_EXC_ATTR: PropTypes.func.isRequired,
  stateObject: PropTypes.object.isRequired
};

export default connect(null, {
})(ExcludeAttributes);
