import React, { Component } from "react";
import NameWithFields from "../../../../utils/OfferTypes";
import _ from "lodash";

export class PipelineEventRenderer extends Component {
  state = { data: "", valChanged: false };

  refresh(params) {
    this.setState({ data: "" });
    return true;
  }
  onChangeHandler = (val, fieldName) => {
    let parsedVal = parseFloat(val.target.value);
    let type = this.props.data.offer_type ? this.props.data.offer_type : this.props.data.ty_offer_type;
    if (fieldName === "ty_planned_amount" || fieldName === "offer_value") {
      if ((type === "P" || type === "B" || type === "D") && parsedVal > 99)
        return;
      else if (
        (type === "F" || type === "A" || type === "C") &&
        parsedVal > 99999
      )
        return;
    } else if (fieldName === "fixed_price_quantity") {
      if (parsedVal > 99) return;
    } else if (fieldName === "min_quantity" || fieldName === "ty_min_qty") {
      if (parsedVal > 99) return;
    } else if (fieldName === "bonus_by_quantity" || fieldName === "ty_bonus_pay") {
      if (parsedVal > 99) return;
    } else if (fieldName === "ty_buy_min_value") {
      if (parsedVal > 99999) return;
    } else if (fieldName === "ty_get_max_value") {
      if (parsedVal > 99999) return;
    }
  

    this.props.data[fieldName] = parsedVal || parsedVal === 0 ? parsedVal : "";
    this.props.data.isRowSaved = false;
    this.setState({ data: "", valChanged: true });
  };

  render() {
    let disableHandler = val => {
      if (this.props.data.ty_offer_type) {
        if (
          NameWithFields.nameWithFields[val] &&
          NameWithFields.nameWithFields[val].includes(this.props.colDef.field)
        ) {
          return false;
        }
      } else {
        if (
          NameWithFields.nameWithFieldsEditOffer[val] &&
          NameWithFields.nameWithFieldsEditOffer[val].includes(this.props.colDef.field)
        ) {
          return false;
        }
      }
      this.props.data[this.props.colDef.field] = 0
      return true;
    };
    return (
      <div className="pipeline-adgroup-add">
        <input
          type="number"
          value={
            // this.props.data[this.props.colDef.field] === 0 &&
            //   !this.state.valChanged
            //   ? null
            //   : 
              this.props.data[this.props.colDef.field]
          }
          disabled={
            _.isEmpty(this.props.data.offer_type ? this.props.data.offer_type : this.props.data.ty_offer_type) || (this.props.data.offer_type ? false : this.props.data.title)
              ? true
              : disableHandler(this.props.data.offer_type ? this.props.data.offer_type : this.props.data.ty_offer_type)
          }
          className="reference-sku-input mt-0 ml-2 LY-adgrp-addition"
          placeholder="Enter Value"
          onChange={e => this.onChangeHandler(e, this.props.colDef.field)}
          onWheel={(e) => e.target.blur()}
        />
      </div>
    );
  }
}

export default PipelineEventRenderer;
