import axios from "../../utils/axios/index";
import { config } from "../../utils/config";
import {
  SIMULATION_DATA,
  CLEAR_SIMULATION_DATA,
  SAVE_PROMO_POST_SIMULATION_DATA,
  CLEAR_SAVE_SIMULATION_DATA,
  GET_ERRORS,
  LOADER,
  OFFER_DATA,
  GET_OFFER_DATA,
  SAVE_COMMENT
} from "./types";

export const getSimulationData = reqObject => async dispatch => {
  dispatch({
    type: LOADER,
    payload: true
  });
  dispatch({
    type: CLEAR_SIMULATION_DATA
  });
  let response = axios
    .post(`${config.baseUrl}/promo/simulation`, reqObject)
    .then(res => {
      if (res.data.status) {
        dispatch({
          type: SIMULATION_DATA,
          payload: res.data
        });
        dispatch({
          type: LOADER,
          payload: false
        });
      } else {
        let payload = {
          message: res.data.msg
        };

        dispatch({
          type: GET_ERRORS,
          payload: payload
        });
        dispatch({
          type: LOADER,
          payload: false
        });
      }
      return res.data;
    })
    .catch(err => {
      dispatch({
        type: SIMULATION_DATA,
        payload: err
      });
      return err;
    });
  return response;
};

export const saveSimulationData = reqObject => dispatch => {
  dispatch({
    type: LOADER,
    payload: true
  });
  dispatch({
    type: CLEAR_SAVE_SIMULATION_DATA
  });
  axios
    .post(`${config.baseUrl}/promo/save`, reqObject)
    .then(res => {
      if (res.data.status) {
        dispatch({
          type: SAVE_PROMO_POST_SIMULATION_DATA,
          payload: res.data
        });
        dispatch({
          type: LOADER,
          payload: false
        });
      } else {
        dispatch({
          type: GET_ERRORS,
          payload: res.data.msg
        });
      }
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err
      });
    });
};

export const saveAdgroup = reqObject => async dispatch => {
  dispatch({
    type: LOADER,
    payload: true
  });
  let res = await axios
    .post(`${config.baseUrl}/promo/simulation`, reqObject)
    .then(res => {
      dispatch({
        type: OFFER_DATA,
        payload: res.data.data
      });
      dispatch({
        type: LOADER,
        payload: false
      });
      return res.data;
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err
      });
      return err;
    });
  return res;
};

export const getAdgroupOffer = reqObject => async dispatch => {
  dispatch({
    type: LOADER,
    payload: true
  });
  let response = await axios
    .post(`${config.baseUrl}/promo/details`, reqObject)
    .then(res => {
      dispatch({
        type: GET_OFFER_DATA,
        payload: res.data.data
      });
      dispatch({
        type: LOADER,
        payload: false
      });
      return res.data.data;
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err
      });
      return err;
    });
  return response;
};

export const saveComment = reqObject => dispatch => {
  dispatch({
    type: LOADER,
    payload: true
  });
  axios
    .post(`${config.baseUrl}/user/userCommentsNoSimulation`, reqObject)
    .then(res => {
      dispatch({
        type: SAVE_COMMENT,
        payload: res.data.data
      });
      dispatch({
        type: LOADER,
        payload: false
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err
      });
    });
};

export const offerBuilderApi = reqObject => async dispatch => {
  const response = await axios.post(
    `${config.baseUrl}/promo/offerBuilder`,
    reqObject
  );

  return response.data;
};
