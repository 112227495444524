import {
  RESET_DAYLEVELOFFER_DATA,
  LOADER,
  DAYLEVELOFFER_BUYER,
  DAYLEVELOFFER_SHOP,
  DAYLEVELOFFER_ADGROUP,
  TYPED_DAYLEVELOFFER_ADGROUP,
  DAYLEVELOFFER_FISCDATA,
  SET_MAPPED_LYTY,
  SET_SAVED_VIEWS_NAMES,
  SET_BUYER_LIST,
  SET_SHOP_LIST,
  SET_ADGROUP_LIST,
  SET_LYTYROW_DATA,
  SET_DLOFFER_TABLE_DEF,
  SET_DLO_TABLE_DATA,
  SET_MAPLYTY_DLOFFER_TABLE_DEF,
  SAVED_VIEW_STATUS,
  SHOW_ADGROUP_ALERT,
  SET_ADDITIONAL_METRICS,
  DAYLEVELOFFER_ADGROUP_RANGE_WISE,
  TYPED_DAYLEVELOFFER_ADGROUP_RANGE_WISE,
  RESET_DAYLEVELOFFER_ADGROUP_RANGE_WISE
} from "../actions/types";
import { keyBy } from "lodash";
import {
  dayLevelColumnMappingObj,
  dayLevelFixedColumns,
  OFFER_COMPARISON,
  DOLLAR_TYPE,
} from "../actions/constants";

const initialState = {
  daylevelTable: {},
  dayLevelOfferBuyer: [],
  dayLevelOfferShopData: [],
  dayLevelOfferAdgroupData: [],
  adgroupDataRangeWise : [],
  loader: false,
  mappedLyTy: {},
  savedViewNames: [],
  selectedBuyers: {
    buyerSelectedValue: [],
    buyerListForFilter: [],
  },
  selectedShop: {
    shopSelectedValue: [],
    shopListForFilter: [],
  },
  selectedAdgroups: {
    adgroupSelectedValue: [],
    adgroupListForFilter: [],
  },
  selectedAdditionalMetrics: {
    moreGroupSelectedValue: [],
    moreGroupListForFilter: [],
  },
  LYTYrowData: [],
  savedViewStatus: {},
  adgroupRangeExceededAlert: false,
  fiscalData:[]
};

const foramtData = data => {
  const tempData = data.data.map(item => {
    return {
       value : item.adgroup_id,
       label : item.adgroup_name,
       adgroup_desc : item.adgroup_desc
    }
  })
  return tempData
}

const setOfferTableData = (
  daylevelTable,
  mappedLyTy,
  responseData,
  fromMapModal
) => {
  let daylevelofferTable = daylevelTable;
  let response = responseData;
  let updateMappedData = mappedLyTy;
if(responseData.length >  0 ){
  if (!fromMapModal && !responseData.length) {
    // let uniqueDates = Object.keys(daylevelofferTable);
    let uniqueDates = responseData.map(obj=> obj.tydate);
    for (const key in dayLevelColumnMappingObj) {
      mappedLyTy.forEach((adgroup) => {
        uniqueDates.forEach((date) => {
          daylevelofferTable[date][
            `${adgroup["ty_adgroup"]}_TY${dayLevelColumnMappingObj[key]}`
          ] = null;
          daylevelofferTable[date][
            `${adgroup["ly_adgroup"]}_LY${dayLevelColumnMappingObj[key]}`
          ] = null;
          daylevelofferTable[date]["DM"] = null;
        });
      });
    }
  }

  response.forEach((adgroupDateObj) => {
    let uniqDate = "";

    let adGroupObjKeys = Object.keys(adgroupDateObj);
    let { isTyData } = adgroupDateObj;
    uniqDate = `${adgroupDateObj.tydate}`;
    // console.log("Unique Data",uniqDate,adgroupDateObj.tydate,adgroupDateObj.lydate)
    if(daylevelofferTable[uniqDate]){
      adGroupObjKeys.forEach((key) => {
        if (dayLevelFixedColumns.includes(key)) {
          if (!daylevelofferTable[uniqDate][dayLevelColumnMappingObj[key]]) {
            if (key === "dm_name") {
              if (isTyData) {
                daylevelofferTable[uniqDate][dayLevelColumnMappingObj[key]] =
                  adgroupDateObj[key];
              }
            } else {
              daylevelofferTable[uniqDate][dayLevelColumnMappingObj[key]] =
                adgroupDateObj[key];
            }
          } else if (key === "dm_name" && isTyData) {
            const tempData = `${
              daylevelofferTable[uniqDate][dayLevelColumnMappingObj[key]]
            } / ${adgroupDateObj[key]}`;
            const uniqDMs = [...new Set(tempData.split(" / "))];
            daylevelofferTable[uniqDate][dayLevelColumnMappingObj[key]] =
              uniqDMs.join(" / ");
          }
        } else {
          isTyData
            ? (daylevelofferTable[uniqDate][
                `${adgroupDateObj["adgroup_name"]}_TY${dayLevelColumnMappingObj[key]}`
              ] = adgroupDateObj[key])
            : (daylevelofferTable[uniqDate][
                `${adgroupDateObj["adgroup_name"]}_LY${dayLevelColumnMappingObj[key]}`
              ] = adgroupDateObj[key]);
        }
  
        // daylevelTable optimisation implementation
        daylevelofferTable[uniqDate]["TyDate"] = adgroupDateObj.tydate;
        daylevelofferTable[uniqDate]["LyDate"] = adgroupDateObj.lydate;
    
    });
  }
  });

  const uniqueKeyFn = (item) => `${item.ty_adgroup}`;
  let tyDataObj =
    updateMappedData && updateMappedData.length > 0
      ? keyBy(updateMappedData, uniqueKeyFn)
      : {};
  Object.values(daylevelofferTable).forEach((row) => {
    for (const adgroup in tyDataObj) {
      let offerComparison = "";
      let tyofferPerc = row[`${tyDataObj[adgroup]["ty_adgroup"]}_TYMajorOffer`]
        ? row[`${tyDataObj[adgroup]["ty_adgroup"]}_TYMajorOffer`]
        : null;
      let lyofferPerc = row[`${tyDataObj[adgroup]["ly_adgroup"]}_LYMajorOffer`]
        ? row[`${tyDataObj[adgroup]["ly_adgroup"]}_LYMajorOffer`]
        : null;
      if (
        row[`${tyDataObj[adgroup]["ly_adgroup"]}_LYOffer`] ===
        row[`${tyDataObj[adgroup]["ty_adgroup"]}_TYOffer`]
      ) {
        offerComparison = "Same";
      } else {
        offerComparison = calculateOfferComparison(tyofferPerc, lyofferPerc);
      }

      row[`${tyDataObj[adgroup]["ly_adgroup"]}_offerComparison`] =
        offerComparison;
    }
  });
  let result = {}
  response.forEach((dayLevelObj)=>{
    if(daylevelofferTable[`${dayLevelObj.tydate}`]){
      result[`${dayLevelObj.tydate}`] = daylevelofferTable[`${dayLevelObj.tydate}`]
    }
  })
  return result;
  // return daylevelofferTable;
}else{
  return {}
}
  
};
const calculateOfferComparison = (tyofferValue, lyofferValue) => {
  const { SAME, NET_NEW, NON_COMP, DEEPER, SHALLOWER } = OFFER_COMPARISON;

  let tyoffer = Math.abs(tyofferValue)
  let lyoffer = Math.abs(lyofferValue)
  if (tyoffer === lyoffer) {
    return SAME;
  } else if (!tyoffer && lyoffer) {
    return NON_COMP;
  } else if (!lyoffer && tyoffer) {
    return NET_NEW;
  } else {
    return lyoffer < tyoffer ? DEEPER : SHALLOWER;
  }
};
const constructTableDef = (daylevelTable, args) => {
  let tableDataObj = daylevelTable;
  for (const uniqDate in tableDataObj) {
    for (const key in dayLevelColumnMappingObj) {
      args.length &&
        args.forEach((group) => {
          tableDataObj[uniqDate][
            `${group.ly_adgroup}_LY${dayLevelColumnMappingObj[key]}`
          ] = null;
          tableDataObj[uniqDate][
            `${group.ty_adgroup}_TY${dayLevelColumnMappingObj[key]}`
          ] = null;
        });
    }
  }

  return tableDataObj;
};
const constructFiscalTableDef = (args, LYTYrowData) => {
  let tableDataObj = {};

  args.length &&
    args.map((date) => {
      tableDataObj[date.calendar_date] = {
        TyDate: date.calendar_date,
        LyDate: date.lydate,
        Day: date.weekday,
        FW: date.fiscal_week,
        FM: date.fiscal_month,
        FQ: date.fiscal_quarter,
      };
    });

  if (LYTYrowData.length) {
    tableDataObj = updateNewLYTYOfferTable(tableDataObj, LYTYrowData);
  }
  return tableDataObj;
};
const updateNewLYTYOfferTable = (daylevelTable, updateMappedData) => {
  let daylevelofferTable = daylevelTable;

  for (const uniqDate in daylevelofferTable) {
    for (const key in dayLevelColumnMappingObj) {
      updateMappedData.forEach((group) => {
        daylevelofferTable[uniqDate][
          `${group.ly_adgroup}_LY${dayLevelColumnMappingObj[key]}`
        ] = null;
        daylevelofferTable[uniqDate][
          `${group.ty_adgroup}_TY${dayLevelColumnMappingObj[key]}`
        ] = null;
      });
    }
  }
  return daylevelofferTable;
};

export default function (state = initialState, action) {
  switch (action.type) {
    case DAYLEVELOFFER_BUYER:
      return {
        ...state,
        dayLevelOfferBuyer: action.payload,
      };
    case DAYLEVELOFFER_SHOP:
      return {
        ...state,
        dayLevelOfferShopData: action.payload,
      };
    case DAYLEVELOFFER_ADGROUP:
      return {
        ...state,
        dayLevelOfferAdgroupData: action.payload,
      };
      case DAYLEVELOFFER_ADGROUP_RANGE_WISE : 
      return {
        ...state,
        adgroupDataRangeWise : foramtData(action.payload),
      };
      case TYPED_DAYLEVELOFFER_ADGROUP_RANGE_WISE : 
      return {
        ...state,
        adgroupDataRangeWise : [...state.adgroupDataRangeWise, ...action.payload],
      };
      // case RESET_DAYLEVELOFFER_ADGROUP_RANGE_WISE : 
      // return {
      //   ...state,
      //   adgroupDataRangeWise : action.payload,
      // }
    case TYPED_DAYLEVELOFFER_ADGROUP: 
      return {
        ...state,
        dayLevelOfferAdgroupData: [...state.dayLevelOfferAdgroupData , ...action.payload],
      }
    case DAYLEVELOFFER_FISCDATA:
      return {
        ...state,
        daylevelTable: constructFiscalTableDef(
          action.payload,
          state.LYTYrowData
        ),
        fiscalData:[...action.payload]
      };
    case RESET_DAYLEVELOFFER_DATA:
      return {
        ...state,
        mappedLyTy: {},
        selectedBuyers: {
          buyerSelectedValue: [],
          buyerListForFilter: [],
        },
        selectedShop: {
          shopSelectedValue: [],
          shopListForFilter: [],
        },
        selectedAdgroups: {
          adgroupSelectedValue: [],
          adgroupListForFilter: [],
        },
        selectedAdditionalMetrics: {
          moreGroupSelectedValue: [],
          moreGroupListForFilter: [],
        },
        LYTYrowData: [],
        daylevelTable: {},
        fiscalData:[]
      };

    case SET_MAPPED_LYTY:
      return {
        ...state,
        mappedLyTy: action.payload,
      };
    case SET_SAVED_VIEWS_NAMES:
      return {
        ...state,
        savedViewNames: action.payload,
      };
    case SET_BUYER_LIST:
      return {
        ...state,
        selectedBuyers: action.payload,
      };
    case SET_SHOP_LIST:
      return {
        ...state,
        selectedShop: action.payload,
      };
    case SET_ADGROUP_LIST:
      return {
        ...state,
        selectedAdgroups: action.payload,
      };
    case SET_ADDITIONAL_METRICS:
      return {
        ...state,
        selectedAdditionalMetrics: action.payload,
      };
    case SET_LYTYROW_DATA:
      return {
        ...state,
        LYTYrowData: action.payload,
      };
    case SET_DLOFFER_TABLE_DEF:
      return {
        ...state,
        daylevelTable: constructTableDef(state.daylevelTable, action.payload),
      };
    case SET_DLO_TABLE_DATA:
      return {
        ...state,
        daylevelTable: setOfferTableData(
          state.daylevelTable,
          state.LYTYrowData,
          action.payload,
          action.fromMapModal
        ),
      };
    case SET_MAPLYTY_DLOFFER_TABLE_DEF:
      return {
        ...state,
        daylevelTable: updateNewLYTYOfferTable(
          state.daylevelTable,
          action.payload
        ),
      };
    case SAVED_VIEW_STATUS:
      return {
        ...state,
        savedViewStatus: action.payload,
      };
    case SHOW_ADGROUP_ALERT:
      return {
        ...state,
        adgroupRangeExceededAlert: action.payload,
      };
    case LOADER:
      return {
        ...state,
        loader: action.payload,
      };
    default:
      return state;
  }
}
