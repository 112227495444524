const MERCHANDISE_HIERARCHY_LIST = [
  {
      "MerchantCategory": "187035",
      "MerchantCategoryDesc": "Zippers",
      "Category": "1870",
      "CategoryDesc": "Zippers",
      "Shop": "L5670",
      "ShopDesc": "Sewing Construction",
      "Business": "L3110",
      "BusinessDesc": "Basic Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "160515",
      "MerchantCategoryDesc": "Netting & Tulle BTY",
      "Category": "1605",
      "CategoryDesc": "Nettings & Tulle",
      "Shop": "L5700",
      "ShopDesc": "Special Occasion",
      "Business": "L3100",
      "BusinessDesc": "Fabric Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "149340",
      "MerchantCategoryDesc": "Felt BTY",
      "Category": "1493",
      "CategoryDesc": "Specialty Fleece",
      "Shop": "L5693",
      "ShopDesc": "Fleece Shoppe",
      "Business": "L3100",
      "BusinessDesc": "Fabric Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "178825",
      "MerchantCategoryDesc": "Interfacing BTY",
      "Category": "1788",
      "CategoryDesc": "Interfacings",
      "Shop": "L5670",
      "ShopDesc": "Sewing Construction",
      "Business": "L3110",
      "BusinessDesc": "Basic Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "156509",
      "MerchantCategoryDesc": "Classic Sportswear",
      "Category": "1565",
      "CategoryDesc": "Sportswear",
      "Shop": "L5695",
      "ShopDesc": "Fashion Apparel Fabrics",
      "Business": "L3100",
      "BusinessDesc": "Fabric Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "178320",
      "MerchantCategoryDesc": "Burlap",
      "Category": "1783",
      "CategoryDesc": "Muslin/Burlap",
      "Shop": "L5710",
      "ShopDesc": "Cotton Shop",
      "Business": "L3100",
      "BusinessDesc": "Fabric Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "177080",
      "MerchantCategoryDesc": "Symph Broadcloth",
      "Category": "1770",
      "CategoryDesc": "Cotton Solids",
      "Shop": "L5710",
      "ShopDesc": "Cotton Shop",
      "Business": "L3100",
      "BusinessDesc": "Fabric Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "170055",
      "MerchantCategoryDesc": "Misc Prints",
      "Category": "1700",
      "CategoryDesc": "Cotton Prints",
      "Shop": "L5710",
      "ShopDesc": "Cotton Shop",
      "Business": "L3100",
      "BusinessDesc": "Fabric Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "178301",
      "MerchantCategoryDesc": "Muslin BTY",
      "Category": "1783",
      "CategoryDesc": "Muslin/Burlap",
      "Shop": "L5710",
      "ShopDesc": "Cotton Shop",
      "Business": "L3100",
      "BusinessDesc": "Fabric Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "100520",
      "MerchantCategoryDesc": "Utility",
      "Category": "1005",
      "CategoryDesc": "Vinyl/Utility",
      "Shop": "L5761",
      "ShopDesc": "Home Dec Fabric",
      "Business": "L3110",
      "BusinessDesc": "Basic Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "153501",
      "MerchantCategoryDesc": "Knit Solids",
      "Category": "1535",
      "CategoryDesc": "Knits",
      "Shop": "L5695",
      "ShopDesc": "Fashion Apparel Fabrics",
      "Business": "L3100",
      "BusinessDesc": "Fabric Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "151002",
      "MerchantCategoryDesc": "Linings",
      "Category": "1510",
      "CategoryDesc": "Linings",
      "Shop": "L5695",
      "ShopDesc": "Fashion Apparel Fabrics",
      "Business": "L3100",
      "BusinessDesc": "Fabric Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "213022",
      "MerchantCategoryDesc": "Elastic EA",
      "Category": "2130",
      "CategoryDesc": "Sewing Notions",
      "Shop": "L5670",
      "ShopDesc": "Sewing Construction",
      "Business": "L3110",
      "BusinessDesc": "Basic Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "106215",
      "MerchantCategoryDesc": "HD Trims BTY",
      "Category": "1062",
      "CategoryDesc": "HD Trims",
      "Shop": "L5766",
      "ShopDesc": "Trims",
      "Business": "L3110",
      "BusinessDesc": "Basic Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "106210",
      "MerchantCategoryDesc": "HD Trims EA",
      "Category": "1062",
      "CategoryDesc": "HD Trims",
      "Shop": "L5766",
      "ShopDesc": "Trims",
      "Business": "L3110",
      "BusinessDesc": "Basic Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "107222",
      "MerchantCategoryDesc": "Notions BTY",
      "Category": "1072",
      "CategoryDesc": "Home Decor Hardware",
      "Shop": "L5761",
      "ShopDesc": "Home Dec Fabric",
      "Business": "L3110",
      "BusinessDesc": "Basic Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "213018",
      "MerchantCategoryDesc": "Boning & Belting BTY",
      "Category": "2130",
      "CategoryDesc": "Sewing Notions",
      "Shop": "L5670",
      "ShopDesc": "Sewing Construction",
      "Business": "L3110",
      "BusinessDesc": "Basic Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "219550",
      "MerchantCategoryDesc": "Quilting Tools",
      "Category": "2195",
      "CategoryDesc": "Quilting Notions",
      "Shop": "L5670",
      "ShopDesc": "Sewing Construction",
      "Business": "L3110",
      "BusinessDesc": "Basic Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "107225",
      "MerchantCategoryDesc": "Drapery Notions Rods",
      "Category": "1072",
      "CategoryDesc": "Home Decor Hardware",
      "Shop": "L5761",
      "ShopDesc": "Home Dec Fabric",
      "Business": "L3110",
      "BusinessDesc": "Basic Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "170002",
      "MerchantCategoryDesc": "Keepsake",
      "Category": "1700",
      "CategoryDesc": "Cotton Prints",
      "Shop": "L5710",
      "ShopDesc": "Cotton Shop",
      "Business": "L3100",
      "BusinessDesc": "Fabric Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "179010",
      "MerchantCategoryDesc": "Pillow Forms",
      "Category": "1790",
      "CategoryDesc": "Pillow Forms",
      "Shop": "L5776",
      "ShopDesc": "Foam/Fiber",
      "Business": "L3110",
      "BusinessDesc": "Basic Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "179210",
      "MerchantCategoryDesc": "Stuffing",
      "Category": "1792",
      "CategoryDesc": "Stuffing",
      "Shop": "L5776",
      "ShopDesc": "Foam/Fiber",
      "Business": "L3110",
      "BusinessDesc": "Basic Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "179421",
      "MerchantCategoryDesc": "Bulk Batting Ecomm",
      "Category": "1794",
      "CategoryDesc": "Batting",
      "Shop": "L5776",
      "ShopDesc": "Foam/Fiber",
      "Business": "L3110",
      "BusinessDesc": "Basic Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "179410",
      "MerchantCategoryDesc": "Packaged Batting",
      "Category": "1794",
      "CategoryDesc": "Batting",
      "Shop": "L5776",
      "ShopDesc": "Foam/Fiber",
      "Business": "L3110",
      "BusinessDesc": "Basic Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "179610",
      "MerchantCategoryDesc": "Packaged Foam",
      "Category": "1796",
      "CategoryDesc": "Foam",
      "Shop": "L5776",
      "ShopDesc": "Foam/Fiber",
      "Business": "L3110",
      "BusinessDesc": "Basic Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "213004",
      "MerchantCategoryDesc": "Stablz/Intfc/Adhesiv",
      "Category": "2130",
      "CategoryDesc": "Sewing Notions",
      "Shop": "L5670",
      "ShopDesc": "Sewing Construction",
      "Business": "L3110",
      "BusinessDesc": "Basic Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "144010",
      "MerchantCategoryDesc": "Magazines",
      "Category": "1440",
      "CategoryDesc": "Publications",
      "Shop": "L5649",
      "ShopDesc": "Front End & Impulse",
      "Business": "L3510",
      "BusinessDesc": "Home",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "310801",
      "MerchantCategoryDesc": "Punches & Cutting",
      "Category": "3108",
      "CategoryDesc": "Papercrafting Tools",
      "Shop": "L5655",
      "ShopDesc": "Papercrafting Supplies",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "310406",
      "MerchantCategoryDesc": "Embellishments",
      "Category": "3104",
      "CategoryDesc": "Embellishments",
      "Shop": "L5655",
      "ShopDesc": "Papercrafting Supplies",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "193025",
      "MerchantCategoryDesc": "Tapes & Braids",
      "Category": "1930",
      "CategoryDesc": "Tapes & Braids",
      "Shop": "L5670",
      "ShopDesc": "Sewing Construction",
      "Business": "L3110",
      "BusinessDesc": "Basic Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "329035",
      "MerchantCategoryDesc": "DMC 6 Strand Floss",
      "Category": "3290",
      "CategoryDesc": "Stitch & Accessories",
      "Shop": "L5660",
      "ShopDesc": "Needle Arts",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "213015",
      "MerchantCategoryDesc": "Fasteners & Belting",
      "Category": "2130",
      "CategoryDesc": "Sewing Notions",
      "Shop": "L5670",
      "ShopDesc": "Sewing Construction",
      "Business": "L3110",
      "BusinessDesc": "Basic Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "213030",
      "MerchantCategoryDesc": "Machine Accessories",
      "Category": "2130",
      "CategoryDesc": "Sewing Notions",
      "Shop": "L5670",
      "ShopDesc": "Sewing Construction",
      "Business": "L3110",
      "BusinessDesc": "Basic Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "197231",
      "MerchantCategoryDesc": "Felt",
      "Category": "1972",
      "CategoryDesc": "Kids POP",
      "Shop": "L5675",
      "ShopDesc": "Kids Crafts",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "197230",
      "MerchantCategoryDesc": "Stems/Poms/Eyes",
      "Category": "1972",
      "CategoryDesc": "Kids POP",
      "Shop": "L5675",
      "ShopDesc": "Kids Crafts",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "213044",
      "MerchantCategoryDesc": "Clothing Care",
      "Category": "2130",
      "CategoryDesc": "Sewing Notions",
      "Shop": "L5670",
      "ShopDesc": "Sewing Construction",
      "Business": "L3110",
      "BusinessDesc": "Basic Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "213020",
      "MerchantCategoryDesc": "Undergarment Care",
      "Category": "2130",
      "CategoryDesc": "Sewing Notions",
      "Shop": "L5670",
      "ShopDesc": "Sewing Construction",
      "Business": "L3110",
      "BusinessDesc": "Basic Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "213042",
      "MerchantCategoryDesc": "Sewing Tools",
      "Category": "2130",
      "CategoryDesc": "Sewing Notions",
      "Shop": "L5670",
      "ShopDesc": "Sewing Construction",
      "Business": "L3110",
      "BusinessDesc": "Basic Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "213031",
      "MerchantCategoryDesc": "Pins & Needles",
      "Category": "2130",
      "CategoryDesc": "Sewing Notions",
      "Shop": "L5670",
      "ShopDesc": "Sewing Construction",
      "Business": "L3110",
      "BusinessDesc": "Basic Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "213003",
      "MerchantCategoryDesc": "Patches",
      "Category": "2130",
      "CategoryDesc": "Sewing Notions",
      "Shop": "L5670",
      "ShopDesc": "Sewing Construction",
      "Business": "L3110",
      "BusinessDesc": "Basic Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "183002",
      "MerchantCategoryDesc": "Coats Clark Thread",
      "Category": "1830",
      "CategoryDesc": "Thread",
      "Shop": "L5670",
      "ShopDesc": "Sewing Construction",
      "Business": "L3110",
      "BusinessDesc": "Basic Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "213016",
      "MerchantCategoryDesc": "Velcro EA",
      "Category": "2130",
      "CategoryDesc": "Sewing Notions",
      "Shop": "L5670",
      "ShopDesc": "Sewing Construction",
      "Business": "L3110",
      "BusinessDesc": "Basic Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "310409",
      "MerchantCategoryDesc": "Writing",
      "Category": "3104",
      "CategoryDesc": "Embellishments",
      "Shop": "L5655",
      "ShopDesc": "Papercrafting Supplies",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "310407",
      "MerchantCategoryDesc": "Stickers",
      "Category": "3104",
      "CategoryDesc": "Embellishments",
      "Shop": "L5655",
      "ShopDesc": "Papercrafting Supplies",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "325094",
      "MerchantCategoryDesc": "Stamp Pads",
      "Category": "3250",
      "CategoryDesc": "Stamping",
      "Shop": "L5655",
      "ShopDesc": "Papercrafting Supplies",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "325005",
      "MerchantCategoryDesc": "Embos & Stamp Access",
      "Category": "3250",
      "CategoryDesc": "Stamping",
      "Shop": "L5655",
      "ShopDesc": "Papercrafting Supplies",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "303001",
      "MerchantCategoryDesc": "Bags & Tissues",
      "Category": "3030",
      "CategoryDesc": "Everyday",
      "Shop": "L5649",
      "ShopDesc": "Front End & Impulse",
      "Business": "L3510",
      "BusinessDesc": "Home",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "405505",
      "MerchantCategoryDesc": "Fruit Chewy",
      "Category": "4055",
      "CategoryDesc": "Snacks & Candy",
      "Shop": "L5649",
      "ShopDesc": "Front End & Impulse",
      "Business": "L3510",
      "BusinessDesc": "Home",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "100508",
      "MerchantCategoryDesc": "Clear Vinyl",
      "Category": "1005",
      "CategoryDesc": "Vinyl/Utility",
      "Shop": "L5761",
      "ShopDesc": "Home Dec Fabric",
      "Business": "L3110",
      "BusinessDesc": "Basic Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "183060",
      "MerchantCategoryDesc": "Sulky/Ecomm",
      "Category": "1830",
      "CategoryDesc": "Thread",
      "Shop": "L5670",
      "ShopDesc": "Sewing Construction",
      "Business": "L3110",
      "BusinessDesc": "Basic Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "211510",
      "MerchantCategoryDesc": "Basic Buttons",
      "Category": "2115",
      "CategoryDesc": "Buttons",
      "Shop": "L5670",
      "ShopDesc": "Sewing Construction",
      "Business": "L3110",
      "BusinessDesc": "Basic Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "183020",
      "MerchantCategoryDesc": "Guterman Thread",
      "Category": "1830",
      "CategoryDesc": "Thread",
      "Shop": "L5670",
      "ShopDesc": "Sewing Construction",
      "Business": "L3110",
      "BusinessDesc": "Basic Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "311025",
      "MerchantCategoryDesc": "Glue Gun Sticks",
      "Category": "3110",
      "CategoryDesc": "Glue & Adhesives",
      "Shop": "L5640",
      "ShopDesc": "Craft Components",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "311045",
      "MerchantCategoryDesc": "Crafting Glue",
      "Category": "3110",
      "CategoryDesc": "Glue & Adhesives",
      "Shop": "L5640",
      "ShopDesc": "Craft Components",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "311030",
      "MerchantCategoryDesc": "Industrial Glue",
      "Category": "3110",
      "CategoryDesc": "Glue & Adhesives",
      "Shop": "L5640",
      "ShopDesc": "Craft Components",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "197228",
      "MerchantCategoryDesc": "Feathers/Boas/Fur",
      "Category": "1972",
      "CategoryDesc": "Kids POP",
      "Shop": "L5675",
      "ShopDesc": "Kids Crafts",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "311540",
      "MerchantCategoryDesc": "Magnets/Tools/Other",
      "Category": "3115",
      "CategoryDesc": "Hobby",
      "Shop": "L5675",
      "ShopDesc": "Kids Crafts",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "315603",
      "MerchantCategoryDesc": "Wire-Bases",
      "Category": "3156",
      "CategoryDesc": "Jewelry Making Supplies",
      "Shop": "L5343",
      "ShopDesc": "Jewelry",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "317202",
      "MerchantCategoryDesc": "Meltable CandyWafers",
      "Category": "3172",
      "CategoryDesc": "Food",
      "Shop": "L5642",
      "ShopDesc": "Foodcrafting Shop",
      "Business": "L3510",
      "BusinessDesc": "Home",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "317203",
      "MerchantCategoryDesc": "Decorations",
      "Category": "3172",
      "CategoryDesc": "Food",
      "Shop": "L5642",
      "ShopDesc": "Foodcrafting Shop",
      "Business": "L3510",
      "BusinessDesc": "Home",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "300505",
      "MerchantCategoryDesc": "Stencils",
      "Category": "3005",
      "CategoryDesc": "Paint & Finishes",
      "Shop": "L5645",
      "ShopDesc": "Decorative Painting",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "432510",
      "MerchantCategoryDesc": "Misc/Not Assigned",
      "Category": "4325",
      "CategoryDesc": "Craft - Misc",
      "Shop": "L5605",
      "ShopDesc": "Traditional Craft - Misc",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "194525",
      "MerchantCategoryDesc": "Floral Foam",
      "Category": "1945",
      "CategoryDesc": "Styrofoam",
      "Shop": "L5640",
      "ShopDesc": "Craft Components",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "311525",
      "MerchantCategoryDesc": "LthrMacameAudltCraft",
      "Category": "3115",
      "CategoryDesc": "Hobby",
      "Shop": "L5675",
      "ShopDesc": "Kids Crafts",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "300501",
      "MerchantCategoryDesc": "Craft Paint",
      "Category": "3005",
      "CategoryDesc": "Paint & Finishes",
      "Shop": "L5645",
      "ShopDesc": "Decorative Painting",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "300502",
      "MerchantCategoryDesc": "Brushes/Accessories",
      "Category": "3005",
      "CategoryDesc": "Paint & Finishes",
      "Shop": "L5645",
      "ShopDesc": "Decorative Painting",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "300112",
      "MerchantCategoryDesc": "Non Wood Surfaces",
      "Category": "3001",
      "CategoryDesc": "Other Surfaces",
      "Shop": "L5645",
      "ShopDesc": "Decorative Painting",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "144020",
      "MerchantCategoryDesc": "Consignment Books",
      "Category": "1440",
      "CategoryDesc": "Publications",
      "Shop": "L5649",
      "ShopDesc": "Front End & Impulse",
      "Business": "L3510",
      "BusinessDesc": "Home",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "169505",
      "MerchantCategoryDesc": "Quilt Patterns",
      "Category": "1695",
      "CategoryDesc": "Quilt Patterns",
      "Shop": "L5730",
      "ShopDesc": "Patterns",
      "Business": "L3100",
      "BusinessDesc": "Fabric Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "106610",
      "MerchantCategoryDesc": "Trims EA",
      "Category": "1066",
      "CategoryDesc": "Apparel Trims",
      "Shop": "L5766",
      "ShopDesc": "Trims",
      "Business": "L3110",
      "BusinessDesc": "Basic Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "160530",
      "MerchantCategoryDesc": "Netting & Tulle EA",
      "Category": "1605",
      "CategoryDesc": "Nettings & Tulle",
      "Shop": "L5700",
      "ShopDesc": "Special Occasion",
      "Business": "L3100",
      "BusinessDesc": "Fabric Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "170405",
      "MerchantCategoryDesc": "Precuts/Bundles",
      "Category": "1704",
      "CategoryDesc": "Cotton Packaged",
      "Shop": "L5710",
      "ShopDesc": "Cotton Shop",
      "Business": "L3100",
      "BusinessDesc": "Fabric Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "177090",
      "MerchantCategoryDesc": "Cotton Solids EA",
      "Category": "1770",
      "CategoryDesc": "Cotton Solids",
      "Shop": "L5710",
      "ShopDesc": "Cotton Shop",
      "Business": "L3100",
      "BusinessDesc": "Fabric Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "178317",
      "MerchantCategoryDesc": "Muslin Pkg",
      "Category": "1783",
      "CategoryDesc": "Muslin/Burlap",
      "Shop": "L5710",
      "ShopDesc": "Cotton Shop",
      "Business": "L3100",
      "BusinessDesc": "Fabric Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "178828",
      "MerchantCategoryDesc": "Interfacings EA",
      "Category": "1788",
      "CategoryDesc": "Interfacings",
      "Shop": "L5670",
      "ShopDesc": "Sewing Construction",
      "Business": "L3110",
      "BusinessDesc": "Basic Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "180025",
      "MerchantCategoryDesc": "Scissors",
      "Category": "1800",
      "CategoryDesc": "Cutting Implements",
      "Shop": "L5670",
      "ShopDesc": "Sewing Construction",
      "Business": "L3110",
      "BusinessDesc": "Basic Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "180064",
      "MerchantCategoryDesc": "Rotary Cutters",
      "Category": "1800",
      "CategoryDesc": "Cutting Implements",
      "Shop": "L5670",
      "ShopDesc": "Sewing Construction",
      "Business": "L3110",
      "BusinessDesc": "Basic Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "144005",
      "MerchantCategoryDesc": "Non-Consign Books",
      "Category": "1440",
      "CategoryDesc": "Publications",
      "Shop": "L5649",
      "ShopDesc": "Front End & Impulse",
      "Business": "L3510",
      "BusinessDesc": "Home",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "196007",
      "MerchantCategoryDesc": "Textile Components",
      "Category": "1960",
      "CategoryDesc": "Components & Bases",
      "Shop": "L5780",
      "ShopDesc": "Craft Textiles",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "196001",
      "MerchantCategoryDesc": "Appliques",
      "Category": "1960",
      "CategoryDesc": "Components & Bases",
      "Shop": "L5780",
      "ShopDesc": "Craft Textiles",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "196006",
      "MerchantCategoryDesc": "Letters and Numbers",
      "Category": "1960",
      "CategoryDesc": "Components & Bases",
      "Shop": "L5780",
      "ShopDesc": "Craft Textiles",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "195201",
      "MerchantCategoryDesc": "Paint",
      "Category": "1952",
      "CategoryDesc": "Textile Colorants",
      "Shop": "L5780",
      "ShopDesc": "Craft Textiles",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "195202",
      "MerchantCategoryDesc": "Dyes and Kits",
      "Category": "1952",
      "CategoryDesc": "Textile Colorants",
      "Shop": "L5780",
      "ShopDesc": "Craft Textiles",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "195204",
      "MerchantCategoryDesc": "Markers",
      "Category": "1952",
      "CategoryDesc": "Textile Colorants",
      "Shop": "L5780",
      "ShopDesc": "Craft Textiles",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "207533",
      "MerchantCategoryDesc": "Ribbon BTS",
      "Category": "2075",
      "CategoryDesc": "Basic Core Ribbon",
      "Shop": "L5680",
      "ShopDesc": "Ribbon",
      "Business": "L3510",
      "BusinessDesc": "Home",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "213043",
      "MerchantCategoryDesc": "Iron Accessories",
      "Category": "2130",
      "CategoryDesc": "Sewing Notions",
      "Shop": "L5670",
      "ShopDesc": "Sewing Construction",
      "Business": "L3110",
      "BusinessDesc": "Basic Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "315601",
      "MerchantCategoryDesc": "Findings",
      "Category": "3156",
      "CategoryDesc": "Jewelry Making Supplies",
      "Shop": "L5343",
      "ShopDesc": "Jewelry",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "327232",
      "MerchantCategoryDesc": "Accessories",
      "Category": "3272",
      "CategoryDesc": "Yarn Tools",
      "Shop": "L5660",
      "ShopDesc": "Needle Arts",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "327230",
      "MerchantCategoryDesc": "Needle and Hooks",
      "Category": "3272",
      "CategoryDesc": "Yarn Tools",
      "Shop": "L5660",
      "ShopDesc": "Needle Arts",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "329041",
      "MerchantCategoryDesc": "Stitch Bases&Access",
      "Category": "3290",
      "CategoryDesc": "Stitch & Accessories",
      "Shop": "L5660",
      "ShopDesc": "Needle Arts",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "329040",
      "MerchantCategoryDesc": "Floss Other",
      "Category": "3290",
      "CategoryDesc": "Stitch & Accessories",
      "Shop": "L5660",
      "ShopDesc": "Needle Arts",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "404020",
      "MerchantCategoryDesc": "Spring/Summer Socks",
      "Category": "4040",
      "CategoryDesc": "Seasonal Socks",
      "Shop": "L5649",
      "ShopDesc": "Front End & Impulse",
      "Business": "L3510",
      "BusinessDesc": "Home",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "149803",
      "MerchantCategoryDesc": "College BTY",
      "Category": "1498",
      "CategoryDesc": "College",
      "Shop": "L5784",
      "ShopDesc": "Team",
      "Business": "L3110",
      "BusinessDesc": "Basic Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "100257",
      "MerchantCategoryDesc": "Novelty",
      "Category": "1002",
      "CategoryDesc": "ROT POG",
      "Shop": "L5761",
      "ShopDesc": "Home Dec Fabric",
      "Business": "L3110",
      "BusinessDesc": "Basic Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "187507",
      "MerchantCategoryDesc": "Crafting Organizers",
      "Category": "1875",
      "CategoryDesc": "Craft Storage",
      "Shop": "L5664",
      "ShopDesc": "Storage",
      "Business": "L3510",
      "BusinessDesc": "Home",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "188003",
      "MerchantCategoryDesc": "Plastic Totes&Contrs",
      "Category": "1880",
      "CategoryDesc": "General Storage",
      "Shop": "L5664",
      "ShopDesc": "Storage",
      "Business": "L3510",
      "BusinessDesc": "Home",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "188530",
      "MerchantCategoryDesc": "Sewing Organizers",
      "Category": "1885",
      "CategoryDesc": "Sewing Storage",
      "Shop": "L5664",
      "ShopDesc": "Storage",
      "Business": "L3510",
      "BusinessDesc": "Home",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "180085",
      "MerchantCategoryDesc": "Mats & Rulers",
      "Category": "1800",
      "CategoryDesc": "Cutting Implements",
      "Shop": "L5670",
      "ShopDesc": "Sewing Construction",
      "Business": "L3110",
      "BusinessDesc": "Basic Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "175006",
      "MerchantCategoryDesc": "Nursery Fleece Flnl",
      "Category": "1750",
      "CategoryDesc": "Nursery",
      "Shop": "L5715",
      "ShopDesc": "Warm Shop",
      "Business": "L3100",
      "BusinessDesc": "Fabric Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "175003",
      "MerchantCategoryDesc": "Nursery CottnSwaddle",
      "Category": "1750",
      "CategoryDesc": "Nursery",
      "Shop": "L5715",
      "ShopDesc": "Warm Shop",
      "Business": "L3100",
      "BusinessDesc": "Fabric Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "175004",
      "MerchantCategoryDesc": "Nursery Panel",
      "Category": "1750",
      "CategoryDesc": "Nursery",
      "Shop": "L5715",
      "ShopDesc": "Warm Shop",
      "Business": "L3100",
      "BusinessDesc": "Fabric Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "391525",
      "MerchantCategoryDesc": "Spring/Summer Impuls",
      "Category": "3915",
      "CategoryDesc": "Seasonal Impulse",
      "Shop": "L5649",
      "ShopDesc": "Front End & Impulse",
      "Business": "L3510",
      "BusinessDesc": "Home",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "300018",
      "MerchantCategoryDesc": "Turnings/Dowels",
      "Category": "3000",
      "CategoryDesc": "Wood Crafting",
      "Shop": "L5645",
      "ShopDesc": "Decorative Painting",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "197171",
      "MerchantCategoryDesc": "Art Activities",
      "Category": "1971",
      "CategoryDesc": "Craft Activities",
      "Shop": "L5675",
      "ShopDesc": "Kids Crafts",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "197229",
      "MerchantCategoryDesc": "Glitter/Sequin/Gems",
      "Category": "1972",
      "CategoryDesc": "Kids POP",
      "Shop": "L5675",
      "ShopDesc": "Kids Crafts",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "194520",
      "MerchantCategoryDesc": "White Foam",
      "Category": "1945",
      "CategoryDesc": "Styrofoam",
      "Shop": "L5640",
      "ShopDesc": "Craft Components",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "197110",
      "MerchantCategoryDesc": "Kids Kits",
      "Category": "1971",
      "CategoryDesc": "Craft Activities",
      "Shop": "L5675",
      "ShopDesc": "Kids Crafts",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "197173",
      "MerchantCategoryDesc": "Crayola",
      "Category": "1971",
      "CategoryDesc": "Craft Activities",
      "Shop": "L5675",
      "ShopDesc": "Kids Crafts",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "197226",
      "MerchantCategoryDesc": "Pony Beads & Lacing",
      "Category": "1972",
      "CategoryDesc": "Kids POP",
      "Shop": "L5675",
      "ShopDesc": "Kids Crafts",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "325006",
      "MerchantCategoryDesc": "Open Stock Stamps",
      "Category": "3250",
      "CategoryDesc": "Stamping",
      "Shop": "L5655",
      "ShopDesc": "Papercrafting Supplies",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "197225",
      "MerchantCategoryDesc": "Kids Craft Foam",
      "Category": "1972",
      "CategoryDesc": "Kids POP",
      "Shop": "L5675",
      "ShopDesc": "Kids Crafts",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "140220",
      "MerchantCategoryDesc": "Wall Frames",
      "Category": "1402",
      "CategoryDesc": "Frames",
      "Shop": "L5746",
      "ShopDesc": "Frames",
      "Business": "L3510",
      "BusinessDesc": "Home",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "176001",
      "MerchantCategoryDesc": "Cotton Canvas",
      "Category": "1760",
      "CategoryDesc": "Cotton Canvas",
      "Shop": "L5761",
      "ShopDesc": "Home Dec Fabric",
      "Business": "L3110",
      "BusinessDesc": "Basic Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "310501",
      "MerchantCategoryDesc": "Albums",
      "Category": "3105",
      "CategoryDesc": "Papercrafting Storage",
      "Shop": "L5655",
      "ShopDesc": "Papercrafting Supplies",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "213021",
      "MerchantCategoryDesc": "Elastic BTY",
      "Category": "2130",
      "CategoryDesc": "Sewing Notions",
      "Shop": "L5670",
      "ShopDesc": "Sewing Construction",
      "Business": "L3110",
      "BusinessDesc": "Basic Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "310410",
      "MerchantCategoryDesc": "Adhesives",
      "Category": "3104",
      "CategoryDesc": "Embellishments",
      "Shop": "L5655",
      "ShopDesc": "Papercrafting Supplies",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "310001",
      "MerchantCategoryDesc": "Paper Stacks",
      "Category": "3100",
      "CategoryDesc": "Paper",
      "Shop": "L5655",
      "ShopDesc": "Papercrafting Supplies",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "315402",
      "MerchantCategoryDesc": "Packaged Beads",
      "Category": "3154",
      "CategoryDesc": "Beads",
      "Shop": "L5343",
      "ShopDesc": "Jewelry",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "315604",
      "MerchantCategoryDesc": "Tools",
      "Category": "3156",
      "CategoryDesc": "Jewelry Making Supplies",
      "Shop": "L5343",
      "ShopDesc": "Jewelry",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "315807",
      "MerchantCategoryDesc": "Pendants & Charms",
      "Category": "3158",
      "CategoryDesc": "Jewelry Components",
      "Shop": "L5343",
      "ShopDesc": "Jewelry",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "117001",
      "MerchantCategoryDesc": "Naturals",
      "Category": "1170",
      "CategoryDesc": "Basic Floral Components",
      "Shop": "L5818",
      "ShopDesc": "Basic Floral",
      "Business": "L3500",
      "BusinessDesc": "Seasonal",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "327021",
      "MerchantCategoryDesc": "Spin Coats Natural",
      "Category": "3270",
      "CategoryDesc": "Natural Yarn",
      "Shop": "L5660",
      "ShopDesc": "Needle Arts",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "327111",
      "MerchantCategoryDesc": "Spin Coats Cotton",
      "Category": "3271",
      "CategoryDesc": "Craft Cotton Yarn",
      "Shop": "L5660",
      "ShopDesc": "Needle Arts",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "327022",
      "MerchantCategoryDesc": "LB Natural",
      "Category": "3270",
      "CategoryDesc": "Natural Yarn",
      "Shop": "L5660",
      "ShopDesc": "Needle Arts",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "326525",
      "MerchantCategoryDesc": "Spin Simply Soft",
      "Category": "3265",
      "CategoryDesc": "Classic Yarn",
      "Shop": "L5660",
      "ShopDesc": "Needle Arts",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "326520",
      "MerchantCategoryDesc": "Spin Coats All Other",
      "Category": "3265",
      "CategoryDesc": "Classic Yarn",
      "Shop": "L5660",
      "ShopDesc": "Needle Arts",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "329020",
      "MerchantCategoryDesc": "Stitch Kits",
      "Category": "3290",
      "CategoryDesc": "Stitch & Accessories",
      "Shop": "L5660",
      "ShopDesc": "Needle Arts",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "164010",
      "MerchantCategoryDesc": "Simplicity",
      "Category": "1640",
      "CategoryDesc": "Simplicity Patterns",
      "Shop": "L5730",
      "ShopDesc": "Patterns",
      "Business": "L3100",
      "BusinessDesc": "Fabric Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "170402",
      "MerchantCategoryDesc": "Fabric Quarters",
      "Category": "1704",
      "CategoryDesc": "Cotton Packaged",
      "Shop": "L5710",
      "ShopDesc": "Cotton Shop",
      "Business": "L3100",
      "BusinessDesc": "Fabric Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "177505",
      "MerchantCategoryDesc": "Comfy Cozy",
      "Category": "1775",
      "CategoryDesc": "Flannel",
      "Shop": "L5715",
      "ShopDesc": "Warm Shop",
      "Business": "L3100",
      "BusinessDesc": "Fabric Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "310003",
      "MerchantCategoryDesc": "Value Pack Paper",
      "Category": "3100",
      "CategoryDesc": "Paper",
      "Shop": "L5655",
      "ShopDesc": "Papercrafting Supplies",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "182525",
      "MerchantCategoryDesc": "Furniture&Dress Form",
      "Category": "1825",
      "CategoryDesc": "Sewing Room Basics",
      "Shop": "L5670",
      "ShopDesc": "Sewing Construction",
      "Business": "L3110",
      "BusinessDesc": "Basic Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "317201",
      "MerchantCategoryDesc": "Icing Fondnt&Ingredt",
      "Category": "3172",
      "CategoryDesc": "Food",
      "Shop": "L5642",
      "ShopDesc": "Foodcrafting Shop",
      "Business": "L3510",
      "BusinessDesc": "Home",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "153003",
      "MerchantCategoryDesc": "Cotton",
      "Category": "1530",
      "CategoryDesc": "Specialty Cotton & Linens",
      "Shop": "L5695",
      "ShopDesc": "Fashion Apparel Fabrics",
      "Business": "L3100",
      "BusinessDesc": "Fabric Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "153010",
      "MerchantCategoryDesc": "Shirtings",
      "Category": "1530",
      "CategoryDesc": "Specialty Cotton & Linens",
      "Shop": "L5695",
      "ShopDesc": "Fashion Apparel Fabrics",
      "Business": "L3100",
      "BusinessDesc": "Fabric Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "156501",
      "MerchantCategoryDesc": "Denim",
      "Category": "1565",
      "CategoryDesc": "Sportswear",
      "Shop": "L5695",
      "ShopDesc": "Fashion Apparel Fabrics",
      "Business": "L3100",
      "BusinessDesc": "Fabric Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "160105",
      "MerchantCategoryDesc": "Let's Pretend",
      "Category": "1601",
      "CategoryDesc": "Costuming",
      "Shop": "L5700",
      "ShopDesc": "Special Occasion",
      "Business": "L3100",
      "BusinessDesc": "Fabric Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "153005",
      "MerchantCategoryDesc": "Linen",
      "Category": "1530",
      "CategoryDesc": "Specialty Cotton & Linens",
      "Shop": "L5695",
      "ShopDesc": "Fashion Apparel Fabrics",
      "Business": "L3100",
      "BusinessDesc": "Fabric Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "150018",
      "MerchantCategoryDesc": "Silky Prints",
      "Category": "1500",
      "CategoryDesc": "Silkies",
      "Shop": "L5695",
      "ShopDesc": "Fashion Apparel Fabrics",
      "Business": "L3100",
      "BusinessDesc": "Fabric Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "189025",
      "MerchantCategoryDesc": "Baskets",
      "Category": "1890",
      "CategoryDesc": "Home Storage",
      "Shop": "L5664",
      "ShopDesc": "Storage",
      "Business": "L3510",
      "BusinessDesc": "Home",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "300503",
      "MerchantCategoryDesc": "Home Decor/Chalk",
      "Category": "3005",
      "CategoryDesc": "Paint & Finishes",
      "Shop": "L5645",
      "ShopDesc": "Decorative Painting",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "174501",
      "MerchantCategoryDesc": "Patriotic Prints BTY",
      "Category": "1745",
      "CategoryDesc": "Patriotic",
      "Shop": "L5705",
      "ShopDesc": "Holiday",
      "Business": "L3100",
      "BusinessDesc": "Fabric Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "302013",
      "MerchantCategoryDesc": "Paper/Pads",
      "Category": "3020",
      "CategoryDesc": "Fine Art Surfaces",
      "Shop": "L5646",
      "ShopDesc": "Fine Art Supplies",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "100201",
      "MerchantCategoryDesc": "Cotton Linen Blend",
      "Category": "1002",
      "CategoryDesc": "ROT POG",
      "Shop": "L5761",
      "ShopDesc": "Home Dec Fabric",
      "Business": "L3110",
      "BusinessDesc": "Basic Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "100260",
      "MerchantCategoryDesc": "Jacquards",
      "Category": "1002",
      "CategoryDesc": "ROT POG",
      "Shop": "L5761",
      "ShopDesc": "Home Dec Fabric",
      "Business": "L3110",
      "BusinessDesc": "Basic Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "177075",
      "MerchantCategoryDesc": "Better Solid",
      "Category": "1770",
      "CategoryDesc": "Cotton Solids",
      "Shop": "L5710",
      "ShopDesc": "Cotton Shop",
      "Business": "L3100",
      "BusinessDesc": "Fabric Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "170302",
      "MerchantCategoryDesc": "Double Faced Quilts",
      "Category": "1703",
      "CategoryDesc": "Cotton Premium Prints",
      "Shop": "L5710",
      "ShopDesc": "Cotton Shop",
      "Business": "L3100",
      "BusinessDesc": "Fabric Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "311302",
      "MerchantCategoryDesc": "Cricut Tools &Blades",
      "Category": "3113",
      "CategoryDesc": "Cricut Accessories",
      "Shop": "L5650",
      "ShopDesc": "Craft Technology",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "326534",
      "MerchantCategoryDesc": "LB All Other",
      "Category": "3265",
      "CategoryDesc": "Classic Yarn",
      "Shop": "L5660",
      "ShopDesc": "Needle Arts",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "106605",
      "MerchantCategoryDesc": "Value Trims EA",
      "Category": "1066",
      "CategoryDesc": "Apparel Trims",
      "Shop": "L5766",
      "ShopDesc": "Trims",
      "Business": "L3110",
      "BusinessDesc": "Basic Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "207544",
      "MerchantCategoryDesc": "Decorative Ribbon",
      "Category": "2075",
      "CategoryDesc": "Basic Core Ribbon",
      "Shop": "L5680",
      "ShopDesc": "Ribbon",
      "Business": "L3510",
      "BusinessDesc": "Home",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "109275",
      "MerchantCategoryDesc": "Outdoor Print",
      "Category": "1092",
      "CategoryDesc": "Outdoor",
      "Shop": "L5761",
      "ShopDesc": "Home Dec Fabric",
      "Business": "L3110",
      "BusinessDesc": "Basic Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "170005",
      "MerchantCategoryDesc": "Novelty Prints",
      "Category": "1700",
      "CategoryDesc": "Cotton Prints",
      "Shop": "L5710",
      "ShopDesc": "Cotton Shop",
      "Business": "L3100",
      "BusinessDesc": "Fabric Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "302112",
      "MerchantCategoryDesc": "Fine Art Markers",
      "Category": "3021",
      "CategoryDesc": "Fine Art Illustration",
      "Shop": "L5646",
      "ShopDesc": "Fine Art Supplies",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "303025",
      "MerchantCategoryDesc": "Impulse/Trend",
      "Category": "3030",
      "CategoryDesc": "Everyday",
      "Shop": "L5649",
      "ShopDesc": "Front End & Impulse",
      "Business": "L3510",
      "BusinessDesc": "Home",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "179420",
      "MerchantCategoryDesc": "Batting BTY",
      "Category": "1794",
      "CategoryDesc": "Batting",
      "Shop": "L5776",
      "ShopDesc": "Foam/Fiber",
      "Business": "L3110",
      "BusinessDesc": "Basic Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "197170",
      "MerchantCategoryDesc": "Surfaces",
      "Category": "1971",
      "CategoryDesc": "Craft Activities",
      "Shop": "L5675",
      "ShopDesc": "Kids Crafts",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "100518",
      "MerchantCategoryDesc": "Multipurpose Vinyl",
      "Category": "1005",
      "CategoryDesc": "Vinyl/Utility",
      "Shop": "L5761",
      "ShopDesc": "Home Dec Fabric",
      "Business": "L3110",
      "BusinessDesc": "Basic Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "150002",
      "MerchantCategoryDesc": "Silky Solids",
      "Category": "1500",
      "CategoryDesc": "Silkies",
      "Shop": "L5695",
      "ShopDesc": "Fashion Apparel Fabrics",
      "Business": "L3100",
      "BusinessDesc": "Fabric Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "213017",
      "MerchantCategoryDesc": "Velcro BTY",
      "Category": "2130",
      "CategoryDesc": "Sewing Notions",
      "Shop": "L5670",
      "ShopDesc": "Sewing Construction",
      "Business": "L3110",
      "BusinessDesc": "Basic Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "220020",
      "MerchantCategoryDesc": "Brother",
      "Category": "2200",
      "CategoryDesc": "Sewing Machines",
      "Shop": "L5672",
      "ShopDesc": "Sewing Tech",
      "Business": "L3110",
      "BusinessDesc": "Basic Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "106660",
      "MerchantCategoryDesc": "Trims BTY",
      "Category": "1066",
      "CategoryDesc": "Apparel Trims",
      "Shop": "L5766",
      "ShopDesc": "Trims",
      "Business": "L3110",
      "BusinessDesc": "Basic Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "187510",
      "MerchantCategoryDesc": "Super Satchels",
      "Category": "1875",
      "CategoryDesc": "Craft Storage",
      "Shop": "L5664",
      "ShopDesc": "Storage",
      "Business": "L3510",
      "BusinessDesc": "Home",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "165510",
      "MerchantCategoryDesc": "Vogue",
      "Category": "1655",
      "CategoryDesc": "Vogue Patterns",
      "Shop": "L5730",
      "ShopDesc": "Patterns",
      "Business": "L3100",
      "BusinessDesc": "Fabric Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "207530",
      "MerchantCategoryDesc": "Craft Ribbon",
      "Category": "2075",
      "CategoryDesc": "Basic Core Ribbon",
      "Shop": "L5680",
      "ShopDesc": "Ribbon",
      "Business": "L3510",
      "BusinessDesc": "Home",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "207534",
      "MerchantCategoryDesc": "Ribbon Fancies",
      "Category": "2075",
      "CategoryDesc": "Basic Core Ribbon",
      "Shop": "L5680",
      "ShopDesc": "Ribbon",
      "Business": "L3510",
      "BusinessDesc": "Home",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "170545",
      "MerchantCategoryDesc": "Christmas Prnt/Woven",
      "Category": "1705",
      "CategoryDesc": "Christmas",
      "Shop": "L5705",
      "ShopDesc": "Holiday",
      "Business": "L3100",
      "BusinessDesc": "Fabric Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "207532",
      "MerchantCategoryDesc": "Ribbon BTY",
      "Category": "2075",
      "CategoryDesc": "Basic Core Ribbon",
      "Shop": "L5680",
      "ShopDesc": "Ribbon",
      "Business": "L3510",
      "BusinessDesc": "Home",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "150510",
      "MerchantCategoryDesc": "Lic Christmas BTY",
      "Category": "1505",
      "CategoryDesc": "Seasonal",
      "Shop": "L5785",
      "ShopDesc": "Licensed Shop",
      "Business": "L3110",
      "BusinessDesc": "Basic Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "326602",
      "MerchantCategoryDesc": "Coats Super Saver",
      "Category": "3266",
      "CategoryDesc": "Value Yarn",
      "Shop": "L5660",
      "ShopDesc": "Needle Arts",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "176010",
      "MerchantCategoryDesc": "Duck Cotton Canvas",
      "Category": "1760",
      "CategoryDesc": "Cotton Canvas",
      "Shop": "L5761",
      "ShopDesc": "Home Dec Fabric",
      "Business": "L3110",
      "BusinessDesc": "Basic Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "177546",
      "MerchantCategoryDesc": "Super Snuggle",
      "Category": "1775",
      "CategoryDesc": "Flannel",
      "Shop": "L5715",
      "ShopDesc": "Warm Shop",
      "Business": "L3100",
      "BusinessDesc": "Fabric Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "219556",
      "MerchantCategoryDesc": "Quilting Rulers",
      "Category": "2195",
      "CategoryDesc": "Quilting Notions",
      "Shop": "L5670",
      "ShopDesc": "Sewing Construction",
      "Business": "L3110",
      "BusinessDesc": "Basic Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "126760",
      "MerchantCategoryDesc": "Floral Supplies",
      "Category": "1267",
      "CategoryDesc": "ArrangingSupplie&Acc",
      "Shop": "L5818",
      "ShopDesc": "Basic Floral",
      "Business": "L3500",
      "BusinessDesc": "Seasonal",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "116240",
      "MerchantCategoryDesc": "Holiday Accessories",
      "Category": "1162",
      "CategoryDesc": "Holiday Floral Accessories",
      "Shop": "L5817",
      "ShopDesc": "Holiday Floral",
      "Business": "L3500",
      "BusinessDesc": "Seasonal",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "140260",
      "MerchantCategoryDesc": "Shadowboxes",
      "Category": "1402",
      "CategoryDesc": "Frames",
      "Shop": "L5746",
      "ShopDesc": "Frames",
      "Business": "L3510",
      "BusinessDesc": "Home",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "170006",
      "MerchantCategoryDesc": "Novelty PNL",
      "Category": "1700",
      "CategoryDesc": "Cotton Prints",
      "Shop": "L5710",
      "ShopDesc": "Cotton Shop",
      "Business": "L3100",
      "BusinessDesc": "Fabric Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "107235",
      "MerchantCategoryDesc": "Peel & Stick",
      "Category": "1072",
      "CategoryDesc": "Home Decor Hardware",
      "Shop": "L5761",
      "ShopDesc": "Home Dec Fabric",
      "Business": "L3110",
      "BusinessDesc": "Basic Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "197140",
      "MerchantCategoryDesc": "Perler Beads",
      "Category": "1971",
      "CategoryDesc": "Craft Activities",
      "Shop": "L5675",
      "ShopDesc": "Kids Crafts",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "197312",
      "MerchantCategoryDesc": "Kids Impulse",
      "Category": "1973",
      "CategoryDesc": "Toy / Impulse",
      "Shop": "L5675",
      "ShopDesc": "Kids Crafts",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "197112",
      "MerchantCategoryDesc": "Puzzles",
      "Category": "1971",
      "CategoryDesc": "Craft Activities",
      "Shop": "L5675",
      "ShopDesc": "Kids Crafts",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "189535",
      "MerchantCategoryDesc": "Table Lighting",
      "Category": "1895",
      "CategoryDesc": "Lighting",
      "Shop": "L5672",
      "ShopDesc": "Sewing Tech",
      "Business": "L3110",
      "BusinessDesc": "Basic Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "170365",
      "MerchantCategoryDesc": "Batiks",
      "Category": "1703",
      "CategoryDesc": "Cotton Premium Prints",
      "Shop": "L5710",
      "ShopDesc": "Cotton Shop",
      "Business": "L3100",
      "BusinessDesc": "Fabric Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "196003",
      "MerchantCategoryDesc": "Wearable Accessories",
      "Category": "1960",
      "CategoryDesc": "Components & Bases",
      "Shop": "L5780",
      "ShopDesc": "Craft Textiles",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "156520",
      "MerchantCategoryDesc": "Suede/Leather/Cordry",
      "Category": "1565",
      "CategoryDesc": "Sportswear",
      "Shop": "L5695",
      "ShopDesc": "Fashion Apparel Fabrics",
      "Business": "L3100",
      "BusinessDesc": "Fabric Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "300510",
      "MerchantCategoryDesc": "Spray Paint",
      "Category": "3005",
      "CategoryDesc": "Paint & Finishes",
      "Shop": "L5645",
      "ShopDesc": "Decorative Painting",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "302012",
      "MerchantCategoryDesc": "Artist Canvas",
      "Category": "3020",
      "CategoryDesc": "Fine Art Surfaces",
      "Shop": "L5646",
      "ShopDesc": "Fine Art Supplies",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "149703",
      "MerchantCategoryDesc": "MLB BTY",
      "Category": "1497",
      "CategoryDesc": "MLB",
      "Shop": "L5784",
      "ShopDesc": "Team",
      "Business": "L3110",
      "BusinessDesc": "Basic Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "126790",
      "MerchantCategoryDesc": "Containers",
      "Category": "1267",
      "CategoryDesc": "ArrangingSupplie&Acc",
      "Shop": "L5818",
      "ShopDesc": "Basic Floral",
      "Business": "L3500",
      "BusinessDesc": "Seasonal",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "310009",
      "MerchantCategoryDesc": "O/S Paper",
      "Category": "3100",
      "CategoryDesc": "Paper",
      "Shop": "L5655",
      "ShopDesc": "Papercrafting Supplies",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "160705",
      "MerchantCategoryDesc": "Satin",
      "Category": "1607",
      "CategoryDesc": "Casa Collection",
      "Shop": "L5700",
      "ShopDesc": "Special Occasion",
      "Business": "L3100",
      "BusinessDesc": "Fabric Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "300008",
      "MerchantCategoryDesc": "Crates/Cubes",
      "Category": "3000",
      "CategoryDesc": "Wood Crafting",
      "Shop": "L5645",
      "ShopDesc": "Decorative Painting",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "310010",
      "MerchantCategoryDesc": "Specialty Paper",
      "Category": "3100",
      "CategoryDesc": "Paper",
      "Shop": "L5655",
      "ShopDesc": "Papercrafting Supplies",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "302212",
      "MerchantCategoryDesc": "Fine Art Brushes",
      "Category": "3022",
      "CategoryDesc": "Fine Art Painting",
      "Shop": "L5646",
      "ShopDesc": "Fine Art Supplies",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "207550",
      "MerchantCategoryDesc": "Basic Ribbon OOL",
      "Category": "2075",
      "CategoryDesc": "Basic Core Ribbon",
      "Shop": "L5680",
      "ShopDesc": "Ribbon",
      "Business": "L3510",
      "BusinessDesc": "Home",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "195203",
      "MerchantCategoryDesc": "Tools",
      "Category": "1952",
      "CategoryDesc": "Textile Colorants",
      "Shop": "L5780",
      "ShopDesc": "Craft Textiles",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "310605",
      "MerchantCategoryDesc": "Tim Holtz",
      "Category": "3106",
      "CategoryDesc": "Papercrafting Designers",
      "Shop": "L5655",
      "ShopDesc": "Papercrafting Supplies",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "315401",
      "MerchantCategoryDesc": "Strung Beads",
      "Category": "3154",
      "CategoryDesc": "Beads",
      "Shop": "L5343",
      "ShopDesc": "Jewelry",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "187504",
      "MerchantCategoryDesc": "Paper Craft Storage",
      "Category": "1875",
      "CategoryDesc": "Craft Storage",
      "Shop": "L5664",
      "ShopDesc": "Storage",
      "Business": "L3510",
      "BusinessDesc": "Home",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "311650",
      "MerchantCategoryDesc": "Crafting Tape",
      "Category": "3116",
      "CategoryDesc": "Tape & Mailing",
      "Shop": "L5640",
      "ShopDesc": "Craft Components",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "300006",
      "MerchantCategoryDesc": "Letters/Numbers",
      "Category": "3000",
      "CategoryDesc": "Wood Crafting",
      "Shop": "L5645",
      "ShopDesc": "Decorative Painting",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "317003",
      "MerchantCategoryDesc": "Fall/Halloween Food",
      "Category": "3170",
      "CategoryDesc": "Seasonal Food",
      "Shop": "L5642",
      "ShopDesc": "Foodcrafting Shop",
      "Business": "L3510",
      "BusinessDesc": "Home",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "300050",
      "MerchantCategoryDesc": "Tools",
      "Category": "3000",
      "CategoryDesc": "Wood Crafting",
      "Shop": "L5645",
      "ShopDesc": "Decorative Painting",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "327110",
      "MerchantCategoryDesc": "Spin Sugar N Cream",
      "Category": "3271",
      "CategoryDesc": "Craft Cotton Yarn",
      "Shop": "L5660",
      "ShopDesc": "Needle Arts",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "326812",
      "MerchantCategoryDesc": "Spin Coats Baby",
      "Category": "3268",
      "CategoryDesc": "Baby Yarn",
      "Shop": "L5660",
      "ShopDesc": "Needle Arts",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "327020",
      "MerchantCategoryDesc": "LB Wool Ease TQ",
      "Category": "3270",
      "CategoryDesc": "Natural Yarn",
      "Shop": "L5660",
      "ShopDesc": "Needle Arts",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "302011",
      "MerchantCategoryDesc": "Value Canvas",
      "Category": "3020",
      "CategoryDesc": "Fine Art Surfaces",
      "Shop": "L5646",
      "ShopDesc": "Fine Art Supplies",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "211545",
      "MerchantCategoryDesc": "Fashion Buttons",
      "Category": "2115",
      "CategoryDesc": "Buttons",
      "Shop": "L5670",
      "ShopDesc": "Sewing Construction",
      "Business": "L3110",
      "BusinessDesc": "Basic Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "149603",
      "MerchantCategoryDesc": "NFL BTY",
      "Category": "1496",
      "CategoryDesc": "NFL",
      "Shop": "L5784",
      "ShopDesc": "Team",
      "Business": "L3110",
      "BusinessDesc": "Basic Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "310302",
      "MerchantCategoryDesc": "Card Crafting",
      "Category": "3103",
      "CategoryDesc": "Stationery & Party",
      "Shop": "L5655",
      "ShopDesc": "Papercrafting Supplies",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "107260",
      "MerchantCategoryDesc": "Home Decor Notions",
      "Category": "1072",
      "CategoryDesc": "Home Decor Hardware",
      "Shop": "L5761",
      "ShopDesc": "Home Dec Fabric",
      "Business": "L3110",
      "BusinessDesc": "Basic Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "160110",
      "MerchantCategoryDesc": "Halloween",
      "Category": "1601",
      "CategoryDesc": "Costuming",
      "Shop": "L5700",
      "ShopDesc": "Special Occasion",
      "Business": "L3100",
      "BusinessDesc": "Fabric Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "109280",
      "MerchantCategoryDesc": "Outdoor Solid",
      "Category": "1092",
      "CategoryDesc": "Outdoor",
      "Shop": "L5761",
      "ShopDesc": "Home Dec Fabric",
      "Business": "L3110",
      "BusinessDesc": "Basic Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "220014",
      "MerchantCategoryDesc": "Singer",
      "Category": "2200",
      "CategoryDesc": "Sewing Machines",
      "Shop": "L5672",
      "ShopDesc": "Sewing Tech",
      "Business": "L3110",
      "BusinessDesc": "Basic Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "189015",
      "MerchantCategoryDesc": "Decorative Boxes",
      "Category": "1890",
      "CategoryDesc": "Home Storage",
      "Shop": "L5664",
      "ShopDesc": "Storage",
      "Business": "L3510",
      "BusinessDesc": "Home",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "179620",
      "MerchantCategoryDesc": "Foam BTY",
      "Category": "1796",
      "CategoryDesc": "Foam",
      "Shop": "L5776",
      "ShopDesc": "Foam/Fiber",
      "Business": "L3110",
      "BusinessDesc": "Basic Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "405525",
      "MerchantCategoryDesc": "Chocolate",
      "Category": "4055",
      "CategoryDesc": "Snacks & Candy",
      "Shop": "L5649",
      "ShopDesc": "Front End & Impulse",
      "Business": "L3510",
      "BusinessDesc": "Home",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "207553",
      "MerchantCategoryDesc": "Concept Shop Ribbon",
      "Category": "2075",
      "CategoryDesc": "Basic Core Ribbon",
      "Shop": "L5680",
      "ShopDesc": "Ribbon",
      "Business": "L3510",
      "BusinessDesc": "Home",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "140240",
      "MerchantCategoryDesc": "Tabletop Frames",
      "Category": "1402",
      "CategoryDesc": "Frames",
      "Shop": "L5746",
      "ShopDesc": "Frames",
      "Business": "L3510",
      "BusinessDesc": "Home",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "140280",
      "MerchantCategoryDesc": "Framing Accessories",
      "Category": "1402",
      "CategoryDesc": "Frames",
      "Shop": "L5746",
      "ShopDesc": "Frames",
      "Business": "L3510",
      "BusinessDesc": "Home",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "302111",
      "MerchantCategoryDesc": "Fine Art Drawing",
      "Category": "3021",
      "CategoryDesc": "Fine Art Illustration",
      "Shop": "L5646",
      "ShopDesc": "Fine Art Supplies",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "302211",
      "MerchantCategoryDesc": "Fine Art Paint",
      "Category": "3022",
      "CategoryDesc": "Fine Art Painting",
      "Shop": "L5646",
      "ShopDesc": "Fine Art Supplies",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "160720",
      "MerchantCategoryDesc": "Lace/Crepe/Chiffon",
      "Category": "1607",
      "CategoryDesc": "Casa Collection",
      "Shop": "L5700",
      "ShopDesc": "Special Occasion",
      "Business": "L3100",
      "BusinessDesc": "Fabric Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "189545",
      "MerchantCategoryDesc": "Magnified Lghtng&Acc",
      "Category": "1895",
      "CategoryDesc": "Lighting",
      "Shop": "L5672",
      "ShopDesc": "Sewing Tech",
      "Business": "L3110",
      "BusinessDesc": "Basic Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "315605",
      "MerchantCategoryDesc": "Storage",
      "Category": "3156",
      "CategoryDesc": "Jewelry Making Supplies",
      "Shop": "L5343",
      "ShopDesc": "Jewelry",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "300520",
      "MerchantCategoryDesc": "Paint Markers",
      "Category": "3005",
      "CategoryDesc": "Paint & Finishes",
      "Shop": "L5645",
      "ShopDesc": "Decorative Painting",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "172001",
      "MerchantCategoryDesc": "Valentine Prints BTY",
      "Category": "1720",
      "CategoryDesc": "Valentines",
      "Shop": "L5705",
      "ShopDesc": "Holiday",
      "Business": "L3100",
      "BusinessDesc": "Fabric Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "326532",
      "MerchantCategoryDesc": "LB Hometown",
      "Category": "3265",
      "CategoryDesc": "Classic Yarn",
      "Shop": "L5660",
      "ShopDesc": "Needle Arts",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "103640",
      "MerchantCategoryDesc": "Home Dec Fabric EA",
      "Category": "1036",
      "CategoryDesc": "Home Dec Fabric EA",
      "Shop": "L5761",
      "ShopDesc": "Home Dec Fabric",
      "Business": "L3110",
      "BusinessDesc": "Basic Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "300007",
      "MerchantCategoryDesc": "Unfinished Wood",
      "Category": "3000",
      "CategoryDesc": "Wood Crafting",
      "Shop": "L5645",
      "ShopDesc": "Decorative Painting",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "164510",
      "MerchantCategoryDesc": "McCalls",
      "Category": "1645",
      "CategoryDesc": "McCalls Patterns",
      "Shop": "L5730",
      "ShopDesc": "Patterns",
      "Business": "L3100",
      "BusinessDesc": "Fabric Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "164035",
      "MerchantCategoryDesc": "Dropped Patterns",
      "Category": "1640",
      "CategoryDesc": "Simplicity Patterns",
      "Shop": "L5730",
      "ShopDesc": "Patterns",
      "Business": "L3100",
      "BusinessDesc": "Fabric Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "164025",
      "MerchantCategoryDesc": "New Look",
      "Category": "1640",
      "CategoryDesc": "Simplicity Patterns",
      "Shop": "L5730",
      "ShopDesc": "Patterns",
      "Business": "L3100",
      "BusinessDesc": "Fabric Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "165010",
      "MerchantCategoryDesc": "Butterick",
      "Category": "1650",
      "CategoryDesc": "Butterick Patterns",
      "Shop": "L5730",
      "ShopDesc": "Patterns",
      "Business": "L3100",
      "BusinessDesc": "Fabric Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "302014",
      "MerchantCategoryDesc": "Easles/Storage",
      "Category": "3020",
      "CategoryDesc": "Fine Art Surfaces",
      "Shop": "L5646",
      "ShopDesc": "Fine Art Supplies",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "148001",
      "MerchantCategoryDesc": "Blizzard Solids",
      "Category": "1480",
      "CategoryDesc": "Blizzard Fleece",
      "Shop": "L5693",
      "ShopDesc": "Fleece Shoppe",
      "Business": "L3100",
      "BusinessDesc": "Fabric Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "149101",
      "MerchantCategoryDesc": "License WARM",
      "Category": "1491",
      "CategoryDesc": "Fleece",
      "Shop": "L5785",
      "ShopDesc": "Licensed Shop",
      "Business": "L3110",
      "BusinessDesc": "Basic Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "148719",
      "MerchantCategoryDesc": "License MISC BTY",
      "Category": "1487",
      "CategoryDesc": "Cotton",
      "Shop": "L5785",
      "ShopDesc": "Licensed Shop",
      "Business": "L3110",
      "BusinessDesc": "Basic Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "100285",
      "MerchantCategoryDesc": "Ltwght Fbrcs & Shr",
      "Category": "1002",
      "CategoryDesc": "ROT POG",
      "Shop": "L5761",
      "ShopDesc": "Home Dec Fabric",
      "Business": "L3110",
      "BusinessDesc": "Basic Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "220102",
      "MerchantCategoryDesc": "Iron & Steamers",
      "Category": "2201",
      "CategoryDesc": "Sewing Supplies",
      "Shop": "L5672",
      "ShopDesc": "Sewing Tech",
      "Business": "L3110",
      "BusinessDesc": "Basic Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "149360",
      "MerchantCategoryDesc": "Fur",
      "Category": "1493",
      "CategoryDesc": "Specialty Fleece",
      "Shop": "L5693",
      "ShopDesc": "Fleece Shoppe",
      "Business": "L3100",
      "BusinessDesc": "Fabric Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "170315",
      "MerchantCategoryDesc": "Premium Prints",
      "Category": "1703",
      "CategoryDesc": "Cotton Premium Prints",
      "Shop": "L5710",
      "ShopDesc": "Cotton Shop",
      "Business": "L3100",
      "BusinessDesc": "Fabric Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "326605",
      "MerchantCategoryDesc": "Coats SuperSaver Jmb",
      "Category": "3266",
      "CategoryDesc": "Value Yarn",
      "Shop": "L5660",
      "ShopDesc": "Needle Arts",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "100255",
      "MerchantCategoryDesc": "Chenille",
      "Category": "1002",
      "CategoryDesc": "ROT POG",
      "Shop": "L5761",
      "ShopDesc": "Home Dec Fabric",
      "Business": "L3110",
      "BusinessDesc": "Basic Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "107230",
      "MerchantCategoryDesc": "Home Fabric Care",
      "Category": "1072",
      "CategoryDesc": "Home Decor Hardware",
      "Shop": "L5761",
      "ShopDesc": "Home Dec Fabric",
      "Business": "L3110",
      "BusinessDesc": "Basic Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "175009",
      "MerchantCategoryDesc": "Terry",
      "Category": "1750",
      "CategoryDesc": "Nursery",
      "Shop": "L5715",
      "ShopDesc": "Warm Shop",
      "Business": "L3100",
      "BusinessDesc": "Fabric Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "140230",
      "MerchantCategoryDesc": "Poster Frames",
      "Category": "1402",
      "CategoryDesc": "Frames",
      "Shop": "L5746",
      "ShopDesc": "Frames",
      "Business": "L3510",
      "BusinessDesc": "Home",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "411920",
      "MerchantCategoryDesc": "Flameless",
      "Category": "4119",
      "CategoryDesc": "Candles",
      "Shop": "L5649",
      "ShopDesc": "Front End & Impulse",
      "Business": "L3510",
      "BusinessDesc": "Home",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "411910",
      "MerchantCategoryDesc": "Decorative",
      "Category": "4119",
      "CategoryDesc": "Candles",
      "Shop": "L5649",
      "ShopDesc": "Front End & Impulse",
      "Business": "L3510",
      "BusinessDesc": "Home",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "188002",
      "MerchantCategoryDesc": "Carts & Drawers",
      "Category": "1880",
      "CategoryDesc": "General Storage",
      "Shop": "L5664",
      "ShopDesc": "Storage",
      "Business": "L3510",
      "BusinessDesc": "Home",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "196002",
      "MerchantCategoryDesc": "Bandanas",
      "Category": "1960",
      "CategoryDesc": "Components & Bases",
      "Shop": "L5780",
      "ShopDesc": "Craft Textiles",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "321103",
      "MerchantCategoryDesc": "Other Craft Technolo",
      "Category": "3211",
      "CategoryDesc": "Other Tech",
      "Shop": "L5650",
      "ShopDesc": "Craft Technology",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "310604",
      "MerchantCategoryDesc": "Other Designers",
      "Category": "3106",
      "CategoryDesc": "Papercrafting Designers",
      "Shop": "L5655",
      "ShopDesc": "Papercrafting Supplies",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "340515",
      "MerchantCategoryDesc": "Advertising",
      "Category": "3405",
      "CategoryDesc": "Other",
      "Shop": "L5830",
      "ShopDesc": "L5830 Other",
      "Business": "L3020",
      "BusinessDesc": "Other Stuff",
      "CustomerGroup": "L24",
      "CustomerGroupDesc": "Other Stuff"
  },
  {
      "MerchantCategory": "126770",
      "MerchantCategoryDesc": "Wreath Accessories",
      "Category": "1267",
      "CategoryDesc": "ArrangingSupplie&Acc",
      "Shop": "L5818",
      "ShopDesc": "Basic Floral",
      "Business": "L3500",
      "BusinessDesc": "Seasonal",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "126740",
      "MerchantCategoryDesc": "Tools",
      "Category": "1267",
      "CategoryDesc": "ArrangingSupplie&Acc",
      "Shop": "L5818",
      "ShopDesc": "Basic Floral",
      "Business": "L3500",
      "BusinessDesc": "Seasonal",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "177030",
      "MerchantCategoryDesc": "Wide Solids",
      "Category": "1770",
      "CategoryDesc": "Cotton Solids",
      "Shop": "L5710",
      "ShopDesc": "Cotton Shop",
      "Business": "L3100",
      "BusinessDesc": "Fabric Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "311655",
      "MerchantCategoryDesc": "Mailing & Pkg Supply",
      "Category": "3116",
      "CategoryDesc": "Tape & Mailing",
      "Shop": "L5640",
      "ShopDesc": "Craft Components",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "100505",
      "MerchantCategoryDesc": "Weather Resist Vinyl",
      "Category": "1005",
      "CategoryDesc": "Vinyl/Utility",
      "Shop": "L5761",
      "ShopDesc": "Home Dec Fabric",
      "Business": "L3110",
      "BusinessDesc": "Basic Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "160045",
      "MerchantCategoryDesc": "Metallic Velv Brocde",
      "Category": "1600",
      "CategoryDesc": "Special Occasion Collections",
      "Shop": "L5700",
      "ShopDesc": "Special Occasion",
      "Business": "L3100",
      "BusinessDesc": "Fabric Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "411925",
      "MerchantCategoryDesc": "Accessories",
      "Category": "4119",
      "CategoryDesc": "Candles",
      "Shop": "L5649",
      "ShopDesc": "Front End & Impulse",
      "Business": "L3510",
      "BusinessDesc": "Home",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "340555",
      "MerchantCategoryDesc": "SpecialOrd Deposit",
      "Category": "3405",
      "CategoryDesc": "Other",
      "Shop": "L5830",
      "ShopDesc": "L5830 Other",
      "Business": "L3020",
      "BusinessDesc": "Other Stuff",
      "CustomerGroup": "L24",
      "CustomerGroupDesc": "Other Stuff"
  },
  {
      "MerchantCategory": "310901",
      "MerchantCategoryDesc": "Season Papercrafting",
      "Category": "3109",
      "CategoryDesc": "Seasonal Paper Crafting",
      "Shop": "L5655",
      "ShopDesc": "Papercrafting Supplies",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "311020",
      "MerchantCategoryDesc": "Glue Guns",
      "Category": "3110",
      "CategoryDesc": "Glue & Adhesives",
      "Shop": "L5640",
      "ShopDesc": "Craft Components",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "107270",
      "MerchantCategoryDesc": "Home Decor Tools",
      "Category": "1072",
      "CategoryDesc": "Home Decor Hardware",
      "Shop": "L5761",
      "ShopDesc": "Home Dec Fabric",
      "Business": "L3110",
      "BusinessDesc": "Basic Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "117005",
      "MerchantCategoryDesc": "Greenery",
      "Category": "1170",
      "CategoryDesc": "Basic Floral Components",
      "Shop": "L5818",
      "ShopDesc": "Basic Floral",
      "Business": "L3500",
      "BusinessDesc": "Seasonal",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "160725",
      "MerchantCategoryDesc": "Casa Fabrics",
      "Category": "1607",
      "CategoryDesc": "Casa Collection",
      "Shop": "L5700",
      "ShopDesc": "Special Occasion",
      "Business": "L3100",
      "BusinessDesc": "Fabric Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "142786",
      "MerchantCategoryDesc": "Svcs:Damaged/Mism",
      "Category": "1427",
      "CategoryDesc": "Custom Framing",
      "Shop": "L5751",
      "ShopDesc": "Custom Framing",
      "Business": "L3400",
      "BusinessDesc": "Custom Businesses",
      "CustomerGroup": "L28",
      "CustomerGroupDesc": "Frames/Services"
  },
  {
      "MerchantCategory": "196027",
      "MerchantCategoryDesc": "SeasonalCraftTextile",
      "Category": "1960",
      "CategoryDesc": "Components & Bases",
      "Shop": "L5780",
      "ShopDesc": "Craft Textiles",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "315809",
      "MerchantCategoryDesc": "Hair & Accessories",
      "Category": "3158",
      "CategoryDesc": "Jewelry Components",
      "Shop": "L5343",
      "ShopDesc": "Jewelry",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "117004",
      "MerchantCategoryDesc": "Basic Stems",
      "Category": "1170",
      "CategoryDesc": "Basic Floral Components",
      "Shop": "L5818",
      "ShopDesc": "Basic Floral",
      "Business": "L3500",
      "BusinessDesc": "Seasonal",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "326604",
      "MerchantCategoryDesc": "LB Pound of Love",
      "Category": "3266",
      "CategoryDesc": "Value Yarn",
      "Shop": "L5660",
      "ShopDesc": "Needle Arts",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "326601",
      "MerchantCategoryDesc": "Spin One Pound",
      "Category": "3266",
      "CategoryDesc": "Value Yarn",
      "Shop": "L5660",
      "ShopDesc": "Needle Arts",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "341512",
      "MerchantCategoryDesc": "Parties",
      "Category": "3415",
      "CategoryDesc": "Education",
      "Shop": "L5750",
      "ShopDesc": "Education Programs",
      "Business": "L3400",
      "BusinessDesc": "Custom Businesses",
      "CustomerGroup": "L28",
      "CustomerGroupDesc": "Frames/Services"
  },
  {
      "MerchantCategory": "341519",
      "MerchantCategoryDesc": "Kids Sew-Quilt",
      "Category": "3415",
      "CategoryDesc": "Education",
      "Shop": "L5750",
      "ShopDesc": "Education Programs",
      "Business": "L3400",
      "BusinessDesc": "Custom Businesses",
      "CustomerGroup": "L28",
      "CustomerGroupDesc": "Frames/Services"
  },
  {
      "MerchantCategory": "341513",
      "MerchantCategoryDesc": "Quilting",
      "Category": "3415",
      "CategoryDesc": "Education",
      "Shop": "L5750",
      "ShopDesc": "Education Programs",
      "Business": "L3400",
      "BusinessDesc": "Custom Businesses",
      "CustomerGroup": "L28",
      "CustomerGroupDesc": "Frames/Services"
  },
  {
      "MerchantCategory": "326811",
      "MerchantCategoryDesc": "LB Baby",
      "Category": "3268",
      "CategoryDesc": "Baby Yarn",
      "Shop": "L5660",
      "ShopDesc": "Needle Arts",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "341518",
      "MerchantCategoryDesc": "Kids Craft",
      "Category": "3415",
      "CategoryDesc": "Education",
      "Shop": "L5750",
      "ShopDesc": "Education Programs",
      "Business": "L3400",
      "BusinessDesc": "Custom Businesses",
      "CustomerGroup": "L28",
      "CustomerGroupDesc": "Frames/Services"
  },
  {
      "MerchantCategory": "341506",
      "MerchantCategoryDesc": "General Craft",
      "Category": "3415",
      "CategoryDesc": "Education",
      "Shop": "L5750",
      "ShopDesc": "Education Programs",
      "Business": "L3400",
      "BusinessDesc": "Custom Businesses",
      "CustomerGroup": "L28",
      "CustomerGroupDesc": "Frames/Services"
  },
  {
      "MerchantCategory": "341516",
      "MerchantCategoryDesc": "Foodcrafting",
      "Category": "3415",
      "CategoryDesc": "Education",
      "Shop": "L5750",
      "ShopDesc": "Education Programs",
      "Business": "L3400",
      "BusinessDesc": "Custom Businesses",
      "CustomerGroup": "L28",
      "CustomerGroupDesc": "Frames/Services"
  },
  {
      "MerchantCategory": "341515",
      "MerchantCategoryDesc": "Events",
      "Category": "3415",
      "CategoryDesc": "Education",
      "Shop": "L5750",
      "ShopDesc": "Education Programs",
      "Business": "L3400",
      "BusinessDesc": "Custom Businesses",
      "CustomerGroup": "L28",
      "CustomerGroupDesc": "Frames/Services"
  },
  {
      "MerchantCategory": "169010",
      "MerchantCategoryDesc": "Fashion Fusion",
      "Category": "1690",
      "CategoryDesc": "Fashion Fusion",
      "Shop": "L5730",
      "ShopDesc": "Patterns",
      "Business": "L3100",
      "BusinessDesc": "Fabric Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "164050",
      "MerchantCategoryDesc": "Burda",
      "Category": "1640",
      "CategoryDesc": "Simplicity Patterns",
      "Shop": "L5730",
      "ShopDesc": "Patterns",
      "Business": "L3100",
      "BusinessDesc": "Fabric Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "341514",
      "MerchantCategoryDesc": "Sewing",
      "Category": "3415",
      "CategoryDesc": "Education",
      "Shop": "L5750",
      "ShopDesc": "Education Programs",
      "Business": "L3400",
      "BusinessDesc": "Custom Businesses",
      "CustomerGroup": "L28",
      "CustomerGroupDesc": "Frames/Services"
  },
  {
      "MerchantCategory": "155501",
      "MerchantCategoryDesc": "Athleisure",
      "Category": "1555",
      "CategoryDesc": "Athletic Wear",
      "Shop": "L5695",
      "ShopDesc": "Fashion Apparel Fabrics",
      "Business": "L3100",
      "BusinessDesc": "Fabric Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "207552",
      "MerchantCategoryDesc": "Value Organdy",
      "Category": "2075",
      "CategoryDesc": "Basic Core Ribbon",
      "Shop": "L5680",
      "ShopDesc": "Ribbon",
      "Business": "L3510",
      "BusinessDesc": "Home",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "189550",
      "MerchantCategoryDesc": "Floor Lighting",
      "Category": "1895",
      "CategoryDesc": "Lighting",
      "Shop": "L5672",
      "ShopDesc": "Sewing Tech",
      "Business": "L3110",
      "BusinessDesc": "Basic Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "189540",
      "MerchantCategoryDesc": "Other Lighting",
      "Category": "1895",
      "CategoryDesc": "Lighting",
      "Shop": "L5672",
      "ShopDesc": "Sewing Tech",
      "Business": "L3110",
      "BusinessDesc": "Basic Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "197213",
      "MerchantCategoryDesc": "POP Art Supplies",
      "Category": "1972",
      "CategoryDesc": "Kids POP",
      "Shop": "L5675",
      "ShopDesc": "Kids Crafts",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "142780",
      "MerchantCategoryDesc": "SVCS:CustomFraming",
      "Category": "1427",
      "CategoryDesc": "Custom Framing",
      "Shop": "L5751",
      "ShopDesc": "Custom Framing",
      "Business": "L3400",
      "BusinessDesc": "Custom Businesses",
      "CustomerGroup": "L28",
      "CustomerGroupDesc": "Frames/Services"
  },
  {
      "MerchantCategory": "149320",
      "MerchantCategoryDesc": "Sherpa",
      "Category": "1493",
      "CategoryDesc": "Specialty Fleece",
      "Shop": "L5693",
      "ShopDesc": "Fleece Shoppe",
      "Business": "L3100",
      "BusinessDesc": "Fabric Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "403515",
      "MerchantCategoryDesc": "Halloween/Fall Candy",
      "Category": "4035",
      "CategoryDesc": "Seasonal Candy",
      "Shop": "L5649",
      "ShopDesc": "Front End & Impulse",
      "Business": "L3510",
      "BusinessDesc": "Home",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "100263",
      "MerchantCategoryDesc": "Tweed",
      "Category": "1002",
      "CategoryDesc": "ROT POG",
      "Shop": "L5761",
      "ShopDesc": "Home Dec Fabric",
      "Business": "L3110",
      "BusinessDesc": "Basic Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "100290",
      "MerchantCategoryDesc": "Drpy Lngs & Blkout",
      "Category": "1002",
      "CategoryDesc": "ROT POG",
      "Shop": "L5761",
      "ShopDesc": "Home Dec Fabric",
      "Business": "L3110",
      "BusinessDesc": "Basic Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "431010",
      "MerchantCategoryDesc": "Misc/Not Assigned",
      "Category": "4310",
      "CategoryDesc": "Fabric - Misc",
      "Shop": "L5585",
      "ShopDesc": "Fabric - Misc",
      "Business": "L3110",
      "BusinessDesc": "Basic Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "100258",
      "MerchantCategoryDesc": "Suede",
      "Category": "1002",
      "CategoryDesc": "ROT POG",
      "Shop": "L5761",
      "ShopDesc": "Home Dec Fabric",
      "Business": "L3110",
      "BusinessDesc": "Basic Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "196004",
      "MerchantCategoryDesc": "Canvas and Bases",
      "Category": "1960",
      "CategoryDesc": "Components & Bases",
      "Shop": "L5780",
      "ShopDesc": "Craft Textiles",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "180090",
      "MerchantCategoryDesc": "Fashion Cutting",
      "Category": "1800",
      "CategoryDesc": "Cutting Implements",
      "Shop": "L5670",
      "ShopDesc": "Sewing Construction",
      "Business": "L3110",
      "BusinessDesc": "Basic Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "177605",
      "MerchantCategoryDesc": "Specialty Flannel",
      "Category": "1776",
      "CategoryDesc": "Fashion Flannel",
      "Shop": "L5715",
      "ShopDesc": "Warm Shop",
      "Business": "L3100",
      "BusinessDesc": "Fabric Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "195101",
      "MerchantCategoryDesc": "Short-Sleeve Tees",
      "Category": "1951",
      "CategoryDesc": "Apparel",
      "Shop": "L5780",
      "ShopDesc": "Craft Textiles",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "315805",
      "MerchantCategoryDesc": "Finished Jewelry",
      "Category": "3158",
      "CategoryDesc": "Jewelry Components",
      "Shop": "L5343",
      "ShopDesc": "Jewelry",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "311303",
      "MerchantCategoryDesc": "Cricut Mats",
      "Category": "3113",
      "CategoryDesc": "Cricut Accessories",
      "Shop": "L5650",
      "ShopDesc": "Craft Technology",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "170025",
      "MerchantCategoryDesc": "Wide Prints",
      "Category": "1700",
      "CategoryDesc": "Cotton Prints",
      "Shop": "L5710",
      "ShopDesc": "Cotton Shop",
      "Business": "L3100",
      "BusinessDesc": "Fabric Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "317001",
      "MerchantCategoryDesc": "Spring/Summer Food",
      "Category": "3170",
      "CategoryDesc": "Seasonal Food",
      "Shop": "L5642",
      "ShopDesc": "Foodcrafting Shop",
      "Business": "L3510",
      "BusinessDesc": "Home",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "197033",
      "MerchantCategoryDesc": "Kids Summer",
      "Category": "1970",
      "CategoryDesc": "Kids Seasonal",
      "Shop": "L5675",
      "ShopDesc": "Kids Crafts",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "311304",
      "MerchantCategoryDesc": "Other Cricut Supplie",
      "Category": "3113",
      "CategoryDesc": "Cricut Accessories",
      "Shop": "L5650",
      "ShopDesc": "Craft Technology",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "100262",
      "MerchantCategoryDesc": "Velvet",
      "Category": "1002",
      "CategoryDesc": "ROT POG",
      "Shop": "L5761",
      "ShopDesc": "Home Dec Fabric",
      "Business": "L3110",
      "BusinessDesc": "Basic Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "405530",
      "MerchantCategoryDesc": "Salty",
      "Category": "4055",
      "CategoryDesc": "Snacks & Candy",
      "Shop": "L5649",
      "ShopDesc": "Front End & Impulse",
      "Business": "L3510",
      "BusinessDesc": "Home",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "148204",
      "MerchantCategoryDesc": "AP Plush Solids",
      "Category": "1482",
      "CategoryDesc": "AP Plush Fleece",
      "Shop": "L5693",
      "ShopDesc": "Fleece Shoppe",
      "Business": "L3100",
      "BusinessDesc": "Fabric Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "220101",
      "MerchantCategoryDesc": "Sewing Machine Acc",
      "Category": "2201",
      "CategoryDesc": "Sewing Supplies",
      "Shop": "L5672",
      "ShopDesc": "Sewing Tech",
      "Business": "L3110",
      "BusinessDesc": "Basic Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "149350",
      "MerchantCategoryDesc": "Fleece EA",
      "Category": "1493",
      "CategoryDesc": "Specialty Fleece",
      "Shop": "L5693",
      "ShopDesc": "Fleece Shoppe",
      "Business": "L3100",
      "BusinessDesc": "Fabric Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "171501",
      "MerchantCategoryDesc": "Fall Prints BTY",
      "Category": "1715",
      "CategoryDesc": "Harvest",
      "Shop": "L5705",
      "ShopDesc": "Holiday",
      "Business": "L3100",
      "BusinessDesc": "Fabric Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "396015",
      "MerchantCategoryDesc": "Fall Ribbon",
      "Category": "3960",
      "CategoryDesc": "Seasonal Ribbon",
      "Shop": "L5680",
      "ShopDesc": "Ribbon",
      "Business": "L3510",
      "BusinessDesc": "Home",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "115115",
      "MerchantCategoryDesc": "Spring Arrangements",
      "Category": "1151",
      "CategoryDesc": "Spring Floral Finished",
      "Shop": "L5815",
      "ShopDesc": "Spring/Summer Floral",
      "Business": "L3500",
      "BusinessDesc": "Seasonal",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "326810",
      "MerchantCategoryDesc": "Spin Baby Blanket",
      "Category": "3268",
      "CategoryDesc": "Baby Yarn",
      "Shop": "L5660",
      "ShopDesc": "Needle Arts",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "148711",
      "MerchantCategoryDesc": "Juvenile",
      "Category": "1487",
      "CategoryDesc": "Cotton",
      "Shop": "L5785",
      "ShopDesc": "Licensed Shop",
      "Business": "L3110",
      "BusinessDesc": "Basic Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "148717",
      "MerchantCategoryDesc": "Pixar",
      "Category": "1487",
      "CategoryDesc": "Cotton",
      "Shop": "L5785",
      "ShopDesc": "Licensed Shop",
      "Business": "L3110",
      "BusinessDesc": "Basic Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "173001",
      "MerchantCategoryDesc": "Easter Prints BTY",
      "Category": "1730",
      "CategoryDesc": "Easter",
      "Shop": "L5705",
      "ShopDesc": "Holiday",
      "Business": "L3100",
      "BusinessDesc": "Fabric Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "148713",
      "MerchantCategoryDesc": "Marvel/DC Comics",
      "Category": "1487",
      "CategoryDesc": "Cotton",
      "Shop": "L5785",
      "ShopDesc": "Licensed Shop",
      "Business": "L3110",
      "BusinessDesc": "Basic Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "115270",
      "MerchantCategoryDesc": "Spring Containers",
      "Category": "1152",
      "CategoryDesc": "Spring Floral Accessories",
      "Shop": "L5815",
      "ShopDesc": "Spring/Summer Floral",
      "Business": "L3500",
      "BusinessDesc": "Seasonal",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "396010",
      "MerchantCategoryDesc": "Spring Ribbon",
      "Category": "3960",
      "CategoryDesc": "Seasonal Ribbon",
      "Shop": "L5680",
      "ShopDesc": "Ribbon",
      "Business": "L3510",
      "BusinessDesc": "Home",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "160120",
      "MerchantCategoryDesc": "Performing",
      "Category": "1601",
      "CategoryDesc": "Costuming",
      "Shop": "L5700",
      "ShopDesc": "Special Occasion",
      "Business": "L3100",
      "BusinessDesc": "Fabric Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "315606",
      "MerchantCategoryDesc": "Resin",
      "Category": "3156",
      "CategoryDesc": "Jewelry Making Supplies",
      "Shop": "L5343",
      "ShopDesc": "Jewelry",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "170403",
      "MerchantCategoryDesc": "1 & 2 YD Precuts",
      "Category": "1704",
      "CategoryDesc": "Cotton Packaged",
      "Shop": "L5710",
      "ShopDesc": "Cotton Shop",
      "Business": "L3100",
      "BusinessDesc": "Fabric Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "310402",
      "MerchantCategoryDesc": "Value",
      "Category": "3104",
      "CategoryDesc": "Embellishments",
      "Shop": "L5655",
      "ShopDesc": "Papercrafting Supplies",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "396016",
      "MerchantCategoryDesc": "Halloween Ribbon",
      "Category": "3960",
      "CategoryDesc": "Seasonal Ribbon",
      "Shop": "L5680",
      "ShopDesc": "Ribbon",
      "Business": "L3510",
      "BusinessDesc": "Home",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "148703",
      "MerchantCategoryDesc": "Minnie/Mickey",
      "Category": "1487",
      "CategoryDesc": "Cotton",
      "Shop": "L5785",
      "ShopDesc": "Licensed Shop",
      "Business": "L3110",
      "BusinessDesc": "Basic Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "195105",
      "MerchantCategoryDesc": "Sweatshirts",
      "Category": "1951",
      "CategoryDesc": "Apparel",
      "Shop": "L5780",
      "ShopDesc": "Craft Textiles",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "111202",
      "MerchantCategoryDesc": "VFF Solid",
      "Category": "1112",
      "CategoryDesc": "Value Flat Fold",
      "Shop": "L5761",
      "ShopDesc": "Home Dec Fabric",
      "Business": "L3110",
      "BusinessDesc": "Basic Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "396030",
      "MerchantCategoryDesc": "Summer Ribbon",
      "Category": "3960",
      "CategoryDesc": "Seasonal Ribbon",
      "Shop": "L5680",
      "ShopDesc": "Ribbon",
      "Business": "L3510",
      "BusinessDesc": "Home",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "149007",
      "MerchantCategoryDesc": "Lightweight Fleece",
      "Category": "1490",
      "CategoryDesc": "Plush",
      "Shop": "L5693",
      "ShopDesc": "Fleece Shoppe",
      "Business": "L3100",
      "BusinessDesc": "Fabric Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "311101",
      "MerchantCategoryDesc": "Cricut Cutting",
      "Category": "3111",
      "CategoryDesc": "Cricut Machines",
      "Shop": "L5650",
      "ShopDesc": "Craft Technology",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "175008",
      "MerchantCategoryDesc": "Nursery Misc",
      "Category": "1750",
      "CategoryDesc": "Nursery",
      "Shop": "L5715",
      "ShopDesc": "Warm Shop",
      "Business": "L3100",
      "BusinessDesc": "Fabric Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "340565",
      "MerchantCategoryDesc": "Supplies-Non Merch",
      "Category": "3405",
      "CategoryDesc": "Other",
      "Shop": "L5830",
      "ShopDesc": "L5830 Other",
      "Business": "L3020",
      "BusinessDesc": "Other Stuff",
      "CustomerGroup": "L24",
      "CustomerGroupDesc": "Other Stuff"
  },
  {
      "MerchantCategory": "160015",
      "MerchantCategoryDesc": "Sew Sweet",
      "Category": "1600",
      "CategoryDesc": "Special Occasion Collections",
      "Shop": "L5700",
      "ShopDesc": "Special Occasion",
      "Business": "L3100",
      "BusinessDesc": "Fabric Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "148705",
      "MerchantCategoryDesc": "Star Wars/Mando",
      "Category": "1487",
      "CategoryDesc": "Cotton",
      "Shop": "L5785",
      "ShopDesc": "Licensed Shop",
      "Business": "L3110",
      "BusinessDesc": "Basic Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "326531",
      "MerchantCategoryDesc": "Coats With Love",
      "Category": "3265",
      "CategoryDesc": "Classic Yarn",
      "Shop": "L5660",
      "ShopDesc": "Needle Arts",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "303028",
      "MerchantCategoryDesc": "Tech",
      "Category": "3030",
      "CategoryDesc": "Everyday",
      "Shop": "L5649",
      "ShopDesc": "Front End & Impulse",
      "Business": "L3510",
      "BusinessDesc": "Home",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "100264",
      "MerchantCategoryDesc": "Stain Resistant",
      "Category": "1002",
      "CategoryDesc": "ROT POG",
      "Shop": "L5761",
      "ShopDesc": "Home Dec Fabric",
      "Business": "L3110",
      "BusinessDesc": "Basic Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "197317",
      "MerchantCategoryDesc": "Games",
      "Category": "1973",
      "CategoryDesc": "Toy / Impulse",
      "Shop": "L5675",
      "ShopDesc": "Kids Crafts",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "150525",
      "MerchantCategoryDesc": "Lic Halloween BTY",
      "Category": "1505",
      "CategoryDesc": "Seasonal",
      "Shop": "L5785",
      "ShopDesc": "Licensed Shop",
      "Business": "L3110",
      "BusinessDesc": "Basic Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "149903",
      "MerchantCategoryDesc": "NBA BTY",
      "Category": "1499",
      "CategoryDesc": "NBA",
      "Shop": "L5784",
      "ShopDesc": "Team",
      "Business": "L3110",
      "BusinessDesc": "Basic Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "164515",
      "MerchantCategoryDesc": "Kwik Sew",
      "Category": "1645",
      "CategoryDesc": "McCalls Patterns",
      "Shop": "L5730",
      "ShopDesc": "Patterns",
      "Business": "L3100",
      "BusinessDesc": "Fabric Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "149505",
      "MerchantCategoryDesc": "License PKG",
      "Category": "1495",
      "CategoryDesc": "License PKG",
      "Shop": "L5785",
      "ShopDesc": "Licensed Shop",
      "Business": "L3110",
      "BusinessDesc": "Basic Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "115260",
      "MerchantCategoryDesc": "Spring Accessories",
      "Category": "1152",
      "CategoryDesc": "Spring Floral Accessories",
      "Shop": "L5815",
      "ShopDesc": "Spring/Summer Floral",
      "Business": "L3500",
      "BusinessDesc": "Seasonal",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "197032",
      "MerchantCategoryDesc": "Kids Christmas",
      "Category": "1970",
      "CategoryDesc": "Kids Seasonal",
      "Shop": "L5675",
      "ShopDesc": "Kids Crafts",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "195106",
      "MerchantCategoryDesc": "Other Apparel",
      "Category": "1951",
      "CategoryDesc": "Apparel",
      "Shop": "L5780",
      "ShopDesc": "Craft Textiles",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "172501",
      "MerchantCategoryDesc": "St Pat's Prints BTY",
      "Category": "1725",
      "CategoryDesc": "St Patricks",
      "Shop": "L5705",
      "ShopDesc": "Holiday",
      "Business": "L3100",
      "BusinessDesc": "Fabric Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "147510",
      "MerchantCategoryDesc": "Team Pkgd",
      "Category": "1475",
      "CategoryDesc": "Team Pkgd",
      "Shop": "L5784",
      "ShopDesc": "Team",
      "Business": "L3110",
      "BusinessDesc": "Basic Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "160005",
      "MerchantCategoryDesc": "Bridal Collections",
      "Category": "1600",
      "CategoryDesc": "Special Occasion Collections",
      "Shop": "L5700",
      "ShopDesc": "Special Occasion",
      "Business": "L3100",
      "BusinessDesc": "Fabric Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "433510",
      "MerchantCategoryDesc": "Misc/ Not Assigned",
      "Category": "4335",
      "CategoryDesc": "Seasonal / Floral - Misc",
      "Shop": "L5615",
      "ShopDesc": "Seasonal / Floral - Misc",
      "Business": "L3500",
      "BusinessDesc": "Seasonal",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "188520",
      "MerchantCategoryDesc": "Sewing Baskets",
      "Category": "1885",
      "CategoryDesc": "Sewing Storage",
      "Shop": "L5664",
      "ShopDesc": "Storage",
      "Business": "L3510",
      "BusinessDesc": "Home",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "341517",
      "MerchantCategoryDesc": "Yarn",
      "Category": "3415",
      "CategoryDesc": "Education",
      "Shop": "L5750",
      "ShopDesc": "Education Programs",
      "Business": "L3400",
      "BusinessDesc": "Custom Businesses",
      "CustomerGroup": "L28",
      "CustomerGroupDesc": "Frames/Services"
  },
  {
      "MerchantCategory": "341523",
      "MerchantCategoryDesc": "Socials",
      "Category": "3415",
      "CategoryDesc": "Education",
      "Shop": "L5750",
      "ShopDesc": "Education Programs",
      "Business": "L3400",
      "BusinessDesc": "Custom Businesses",
      "CustomerGroup": "L28",
      "CustomerGroupDesc": "Frames/Services"
  },
  {
      "MerchantCategory": "410205",
      "MerchantCategoryDesc": "Decor",
      "Category": "4102",
      "CategoryDesc": "Home Decor",
      "Shop": "L5746",
      "ShopDesc": "Frames",
      "Business": "L3510",
      "BusinessDesc": "Home",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "310503",
      "MerchantCategoryDesc": "Boxes",
      "Category": "3105",
      "CategoryDesc": "Papercrafting Storage",
      "Shop": "L5655",
      "ShopDesc": "Papercrafting Supplies",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "310303",
      "MerchantCategoryDesc": "Party",
      "Category": "3103",
      "CategoryDesc": "Stationery & Party",
      "Shop": "L5655",
      "ShopDesc": "Papercrafting Supplies",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "220025",
      "MerchantCategoryDesc": "Janome",
      "Category": "2200",
      "CategoryDesc": "Sewing Machines",
      "Shop": "L5672",
      "ShopDesc": "Sewing Tech",
      "Business": "L3110",
      "BusinessDesc": "Basic Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "391550",
      "MerchantCategoryDesc": "Apparel &Accessories",
      "Category": "3915",
      "CategoryDesc": "Seasonal Impulse",
      "Shop": "L5649",
      "ShopDesc": "Front End & Impulse",
      "Business": "L3510",
      "BusinessDesc": "Home",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "396013",
      "MerchantCategoryDesc": "St Pat Ribbon",
      "Category": "3960",
      "CategoryDesc": "Seasonal Ribbon",
      "Shop": "L5680",
      "ShopDesc": "Ribbon",
      "Business": "L3510",
      "BusinessDesc": "Home",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "321101",
      "MerchantCategoryDesc": "Silhouette",
      "Category": "3211",
      "CategoryDesc": "Other Tech",
      "Shop": "L5650",
      "ShopDesc": "Craft Technology",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "148704",
      "MerchantCategoryDesc": "Disney Prncs Villian",
      "Category": "1487",
      "CategoryDesc": "Cotton",
      "Shop": "L5785",
      "ShopDesc": "Licensed Shop",
      "Business": "L3110",
      "BusinessDesc": "Basic Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "340575",
      "MerchantCategoryDesc": "Project Sheets",
      "Category": "3405",
      "CategoryDesc": "Other",
      "Shop": "L5830",
      "ShopDesc": "L5830 Other",
      "Business": "L3020",
      "BusinessDesc": "Other Stuff",
      "CustomerGroup": "L24",
      "CustomerGroupDesc": "Other Stuff"
  },
  {
      "MerchantCategory": "341520",
      "MerchantCategoryDesc": "Kids Yarn",
      "Category": "3415",
      "CategoryDesc": "Education",
      "Shop": "L5750",
      "ShopDesc": "Education Programs",
      "Business": "L3400",
      "BusinessDesc": "Custom Businesses",
      "CustomerGroup": "L28",
      "CustomerGroupDesc": "Frames/Services"
  },
  {
      "MerchantCategory": "303002",
      "MerchantCategoryDesc": "Drinkware",
      "Category": "3030",
      "CategoryDesc": "Everyday",
      "Shop": "L5649",
      "ShopDesc": "Front End & Impulse",
      "Business": "L3510",
      "BusinessDesc": "Home",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "177508",
      "MerchantCategoryDesc": "Flannel EA",
      "Category": "1775",
      "CategoryDesc": "Flannel",
      "Shop": "L5715",
      "ShopDesc": "Warm Shop",
      "Business": "L3100",
      "BusinessDesc": "Fabric Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "311201",
      "MerchantCategoryDesc": "Cricut Iron On",
      "Category": "3112",
      "CategoryDesc": "Cricut Cutting Material",
      "Shop": "L5650",
      "ShopDesc": "Craft Technology",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "341509",
      "MerchantCategoryDesc": "Kids Foodcrafting",
      "Category": "3415",
      "CategoryDesc": "Education",
      "Shop": "L5750",
      "ShopDesc": "Education Programs",
      "Business": "L3400",
      "BusinessDesc": "Custom Businesses",
      "CustomerGroup": "L28",
      "CustomerGroupDesc": "Frames/Services"
  },
  {
      "MerchantCategory": "111220",
      "MerchantCategoryDesc": "VFF Vinyl",
      "Category": "1112",
      "CategoryDesc": "Value Flat Fold",
      "Shop": "L5761",
      "ShopDesc": "Home Dec Fabric",
      "Business": "L3110",
      "BusinessDesc": "Basic Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "403505",
      "MerchantCategoryDesc": "Spring/Summer Candy",
      "Category": "4035",
      "CategoryDesc": "Seasonal Candy",
      "Shop": "L5649",
      "ShopDesc": "Front End & Impulse",
      "Business": "L3510",
      "BusinessDesc": "Home",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "153502",
      "MerchantCategoryDesc": "Knit Prints",
      "Category": "1535",
      "CategoryDesc": "Knits",
      "Shop": "L5695",
      "ShopDesc": "Fashion Apparel Fabrics",
      "Business": "L3100",
      "BusinessDesc": "Fabric Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "326710",
      "MerchantCategoryDesc": "Spin Blanket",
      "Category": "3267",
      "CategoryDesc": "Blanket Yarn",
      "Shop": "L5660",
      "ShopDesc": "Needle Arts",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "396005",
      "MerchantCategoryDesc": "Christmas Ribbon",
      "Category": "3960",
      "CategoryDesc": "Seasonal Ribbon",
      "Shop": "L5680",
      "ShopDesc": "Ribbon",
      "Business": "L3510",
      "BusinessDesc": "Home",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "303020",
      "MerchantCategoryDesc": "Go Green Bag",
      "Category": "3030",
      "CategoryDesc": "Everyday",
      "Shop": "L5649",
      "ShopDesc": "Front End & Impulse",
      "Business": "L3510",
      "BusinessDesc": "Home",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "310606",
      "MerchantCategoryDesc": "Heidi Swapp",
      "Category": "3106",
      "CategoryDesc": "Papercrafting Designers",
      "Shop": "L5655",
      "ShopDesc": "Papercrafting Supplies",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "394625",
      "MerchantCategoryDesc": "Autumn Serveware",
      "Category": "3946",
      "CategoryDesc": "Autumn Entertaining",
      "Shop": "L5795",
      "ShopDesc": "Autumn Decor",
      "Business": "L3500",
      "BusinessDesc": "Seasonal",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "122814",
      "MerchantCategoryDesc": "Fall Wreaths",
      "Category": "1228",
      "CategoryDesc": "Fall Floral Finished",
      "Shop": "L5816",
      "ShopDesc": "Fall Floral",
      "Business": "L3500",
      "BusinessDesc": "Seasonal",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "149203",
      "MerchantCategoryDesc": "NHL BTY",
      "Category": "1492",
      "CategoryDesc": "NHL",
      "Shop": "L5784",
      "ShopDesc": "Team",
      "Business": "L3110",
      "BusinessDesc": "Basic Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "300111",
      "MerchantCategoryDesc": "Trend Surfaces",
      "Category": "3001",
      "CategoryDesc": "Other Surfaces",
      "Shop": "L5645",
      "ShopDesc": "Decorative Painting",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "148004",
      "MerchantCategoryDesc": "Blizzard Prints",
      "Category": "1480",
      "CategoryDesc": "Blizzard Fleece",
      "Shop": "L5693",
      "ShopDesc": "Fleece Shoppe",
      "Business": "L3100",
      "BusinessDesc": "Fabric Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "115006",
      "MerchantCategoryDesc": "Spring Stems",
      "Category": "1150",
      "CategoryDesc": "Spring Floral Components",
      "Shop": "L5815",
      "ShopDesc": "Spring/Summer Floral",
      "Business": "L3500",
      "BusinessDesc": "Seasonal",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "115107",
      "MerchantCategoryDesc": "Spring Garlands",
      "Category": "1151",
      "CategoryDesc": "Spring Floral Finished",
      "Shop": "L5815",
      "ShopDesc": "Spring/Summer Floral",
      "Business": "L3500",
      "BusinessDesc": "Seasonal",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "115114",
      "MerchantCategoryDesc": "Spring Wreaths",
      "Category": "1151",
      "CategoryDesc": "Spring Floral Finished",
      "Shop": "L5815",
      "ShopDesc": "Spring/Summer Floral",
      "Business": "L3500",
      "BusinessDesc": "Seasonal",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "115002",
      "MerchantCategoryDesc": "Spring Bushes",
      "Category": "1150",
      "CategoryDesc": "Spring Floral Components",
      "Shop": "L5815",
      "ShopDesc": "Spring/Summer Floral",
      "Business": "L3500",
      "BusinessDesc": "Seasonal",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "317004",
      "MerchantCategoryDesc": "Holiday Food",
      "Category": "3170",
      "CategoryDesc": "Seasonal Food",
      "Shop": "L5642",
      "ShopDesc": "Foodcrafting Shop",
      "Business": "L3510",
      "BusinessDesc": "Home",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "152030",
      "MerchantCategoryDesc": "Everyday Kids",
      "Category": "1520",
      "CategoryDesc": "Juvenile Apparel",
      "Shop": "L5695",
      "ShopDesc": "Fashion Apparel Fabrics",
      "Business": "L3100",
      "BusinessDesc": "Fabric Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "311204",
      "MerchantCategoryDesc": "OthrCricutCutMateral",
      "Category": "3112",
      "CategoryDesc": "Cricut Cutting Material",
      "Shop": "L5650",
      "ShopDesc": "Craft Technology",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "401580",
      "MerchantCategoryDesc": "Spring Wall Decor",
      "Category": "4015",
      "CategoryDesc": "Spring Decor",
      "Shop": "L5804",
      "ShopDesc": "Spring Decor",
      "Business": "L3500",
      "BusinessDesc": "Seasonal",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "401720",
      "MerchantCategoryDesc": "Spring Garden Décor",
      "Category": "4017",
      "CategoryDesc": "Spring Outdoor",
      "Shop": "L5804",
      "ShopDesc": "Spring Decor",
      "Business": "L3500",
      "BusinessDesc": "Seasonal",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "197030",
      "MerchantCategoryDesc": "Kids Spring Holiday",
      "Category": "1970",
      "CategoryDesc": "Kids Seasonal",
      "Shop": "L5675",
      "ShopDesc": "Kids Crafts",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "401585",
      "MerchantCategoryDesc": "Spring Tabletop Dec",
      "Category": "4015",
      "CategoryDesc": "Spring Decor",
      "Shop": "L5804",
      "ShopDesc": "Spring Decor",
      "Business": "L3500",
      "BusinessDesc": "Seasonal",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "327023",
      "MerchantCategoryDesc": "PL Natural",
      "Category": "3270",
      "CategoryDesc": "Natural Yarn",
      "Shop": "L5660",
      "ShopDesc": "Needle Arts",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "100265",
      "MerchantCategoryDesc": "Upholstery Panel",
      "Category": "1002",
      "CategoryDesc": "ROT POG",
      "Shop": "L5761",
      "ShopDesc": "Home Dec Fabric",
      "Business": "L3110",
      "BusinessDesc": "Basic Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "407025",
      "MerchantCategoryDesc": "Summer Serveware",
      "Category": "4070",
      "CategoryDesc": "Summer Entertaining",
      "Shop": "L5805",
      "ShopDesc": "Summer Decor",
      "Business": "L3500",
      "BusinessDesc": "Seasonal",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "394145",
      "MerchantCategoryDesc": "Halloween Towels",
      "Category": "3941",
      "CategoryDesc": "Halloween Entertaining",
      "Shop": "L5790",
      "ShopDesc": "Halloween Decor",
      "Business": "L3500",
      "BusinessDesc": "Seasonal",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "394045",
      "MerchantCategoryDesc": "Halloween Textiles",
      "Category": "3940",
      "CategoryDesc": "Halloween Decor",
      "Shop": "L5790",
      "ShopDesc": "Halloween Decor",
      "Business": "L3500",
      "BusinessDesc": "Seasonal",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "410245",
      "MerchantCategoryDesc": "Textiles",
      "Category": "4102",
      "CategoryDesc": "Home Decor",
      "Shop": "L5746",
      "ShopDesc": "Frames",
      "Business": "L3510",
      "BusinessDesc": "Home",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "311202",
      "MerchantCategoryDesc": "CricutVinyl&TrnsTape",
      "Category": "3112",
      "CategoryDesc": "Cricut Cutting Material",
      "Shop": "L5650",
      "ShopDesc": "Craft Technology",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "394555",
      "MerchantCategoryDesc": "Autumn Pumpkins",
      "Category": "3945",
      "CategoryDesc": "Autumn Decor",
      "Shop": "L5795",
      "ShopDesc": "Autumn Decor",
      "Business": "L3500",
      "BusinessDesc": "Seasonal",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "111201",
      "MerchantCategoryDesc": "VFF Print",
      "Category": "1112",
      "CategoryDesc": "Value Flat Fold",
      "Shop": "L5761",
      "ShopDesc": "Home Dec Fabric",
      "Business": "L3110",
      "BusinessDesc": "Basic Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "196540",
      "MerchantCategoryDesc": "Fall/Halloween Craft",
      "Category": "1965",
      "CategoryDesc": "Seasonal Craft",
      "Shop": "L5645",
      "ShopDesc": "Decorative Painting",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "326813",
      "MerchantCategoryDesc": "PL Baby",
      "Category": "3268",
      "CategoryDesc": "Baby Yarn",
      "Shop": "L5660",
      "ShopDesc": "Needle Arts",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "326535",
      "MerchantCategoryDesc": "PL All Other",
      "Category": "3265",
      "CategoryDesc": "Classic Yarn",
      "Shop": "L5660",
      "ShopDesc": "Needle Arts",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "117009",
      "MerchantCategoryDesc": "Floral Accessories",
      "Category": "1170",
      "CategoryDesc": "Basic Floral Components",
      "Shop": "L5818",
      "ShopDesc": "Basic Floral",
      "Business": "L3500",
      "BusinessDesc": "Seasonal",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "411930",
      "MerchantCategoryDesc": "Home Fragrance",
      "Category": "4119",
      "CategoryDesc": "Candles",
      "Shop": "L5649",
      "ShopDesc": "Front End & Impulse",
      "Business": "L3510",
      "BusinessDesc": "Home",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "148710",
      "MerchantCategoryDesc": "Gaming",
      "Category": "1487",
      "CategoryDesc": "Cotton",
      "Shop": "L5785",
      "ShopDesc": "Licensed Shop",
      "Business": "L3110",
      "BusinessDesc": "Basic Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "174805",
      "MerchantCategoryDesc": "Holiday PreCuts",
      "Category": "1748",
      "CategoryDesc": "Packaged Holiday",
      "Shop": "L5705",
      "ShopDesc": "Holiday",
      "Business": "L3100",
      "BusinessDesc": "Fabric Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "207545",
      "MerchantCategoryDesc": "Save the Date",
      "Category": "2075",
      "CategoryDesc": "Basic Core Ribbon",
      "Shop": "L5680",
      "ShopDesc": "Ribbon",
      "Business": "L3510",
      "BusinessDesc": "Home",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "411905",
      "MerchantCategoryDesc": "Jars",
      "Category": "4119",
      "CategoryDesc": "Candles",
      "Shop": "L5649",
      "ShopDesc": "Front End & Impulse",
      "Business": "L3510",
      "BusinessDesc": "Home",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "196550",
      "MerchantCategoryDesc": "Holiday Craft",
      "Category": "1965",
      "CategoryDesc": "Seasonal Craft",
      "Shop": "L5645",
      "ShopDesc": "Decorative Painting",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "395550",
      "MerchantCategoryDesc": "Holiday Dec Storage",
      "Category": "3955",
      "CategoryDesc": "Holiday Decor",
      "Shop": "L5800",
      "ShopDesc": "Holiday Decor",
      "Business": "L3500",
      "BusinessDesc": "Seasonal",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "148815",
      "MerchantCategoryDesc": "48IN NST",
      "Category": "1488",
      "CategoryDesc": "No Sew Throws",
      "Shop": "L5693",
      "ShopDesc": "Fleece Shoppe",
      "Business": "L3100",
      "BusinessDesc": "Fabric Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "188510",
      "MerchantCategoryDesc": "Rolling Totes",
      "Category": "1885",
      "CategoryDesc": "Sewing Storage",
      "Shop": "L5664",
      "ShopDesc": "Storage",
      "Business": "L3510",
      "BusinessDesc": "Home",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "188015",
      "MerchantCategoryDesc": "Holiday Storage",
      "Category": "1880",
      "CategoryDesc": "General Storage",
      "Shop": "L5664",
      "ShopDesc": "Storage",
      "Business": "L3510",
      "BusinessDesc": "Home",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "411935",
      "MerchantCategoryDesc": "Spring Candles",
      "Category": "4119",
      "CategoryDesc": "Candles",
      "Shop": "L5649",
      "ShopDesc": "Front End & Impulse",
      "Business": "L3510",
      "BusinessDesc": "Home",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "197313",
      "MerchantCategoryDesc": "Beanies",
      "Category": "1973",
      "CategoryDesc": "Toy / Impulse",
      "Shop": "L5675",
      "ShopDesc": "Kids Crafts",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "396011",
      "MerchantCategoryDesc": "Valentine's Ribbon",
      "Category": "3960",
      "CategoryDesc": "Seasonal Ribbon",
      "Shop": "L5680",
      "ShopDesc": "Ribbon",
      "Business": "L3510",
      "BusinessDesc": "Home",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "393007",
      "MerchantCategoryDesc": "St Pats Décor",
      "Category": "3930",
      "CategoryDesc": "St. Patrick's Day",
      "Shop": "L5788",
      "ShopDesc": "Mini Seasons",
      "Business": "L3500",
      "BusinessDesc": "Seasonal",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "401780",
      "MerchantCategoryDesc": "SpringPlanters&Stand",
      "Category": "4017",
      "CategoryDesc": "Spring Outdoor",
      "Shop": "L5804",
      "ShopDesc": "Spring Decor",
      "Business": "L3500",
      "BusinessDesc": "Seasonal",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "401760",
      "MerchantCategoryDesc": "Spring Coco Liners",
      "Category": "4017",
      "CategoryDesc": "Spring Outdoor",
      "Shop": "L5804",
      "ShopDesc": "Spring Decor",
      "Business": "L3500",
      "BusinessDesc": "Seasonal",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "401545",
      "MerchantCategoryDesc": "Spring Textiles",
      "Category": "4015",
      "CategoryDesc": "Spring Decor",
      "Shop": "L5804",
      "ShopDesc": "Spring Decor",
      "Business": "L3500",
      "BusinessDesc": "Seasonal",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "401645",
      "MerchantCategoryDesc": "Spring Towels",
      "Category": "4016",
      "CategoryDesc": "Spring Entertaining",
      "Shop": "L5804",
      "ShopDesc": "Spring Decor",
      "Business": "L3500",
      "BusinessDesc": "Seasonal",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "403035",
      "MerchantCategoryDesc": "Summer Trend",
      "Category": "4030",
      "CategoryDesc": "Summer Decor",
      "Shop": "L5805",
      "ShopDesc": "Summer Decor",
      "Business": "L3500",
      "BusinessDesc": "Seasonal",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "148817",
      "MerchantCategoryDesc": "72IN NST",
      "Category": "1488",
      "CategoryDesc": "No Sew Throws",
      "Shop": "L5693",
      "ShopDesc": "Fleece Shoppe",
      "Business": "L3100",
      "BusinessDesc": "Fabric Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "160115",
      "MerchantCategoryDesc": "Cosplay",
      "Category": "1601",
      "CategoryDesc": "Costuming",
      "Shop": "L5700",
      "ShopDesc": "Special Occasion",
      "Business": "L3100",
      "BusinessDesc": "Fabric Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "149001",
      "MerchantCategoryDesc": "Luxe Fleece",
      "Category": "1490",
      "CategoryDesc": "Plush",
      "Shop": "L5693",
      "ShopDesc": "Fleece Shoppe",
      "Business": "L3100",
      "BusinessDesc": "Fabric Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "310101",
      "MerchantCategoryDesc": "Happy Planner Access",
      "Category": "3101",
      "CategoryDesc": "Journaling",
      "Shop": "L5655",
      "ShopDesc": "Papercrafting Supplies",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "150500",
      "MerchantCategoryDesc": "Lic Spring Holdy BTY",
      "Category": "1505",
      "CategoryDesc": "Seasonal",
      "Shop": "L5785",
      "ShopDesc": "Licensed Shop",
      "Business": "L3110",
      "BusinessDesc": "Basic Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "195104",
      "MerchantCategoryDesc": "Long-Sleeve Tees",
      "Category": "1951",
      "CategoryDesc": "Apparel",
      "Shop": "L5780",
      "ShopDesc": "Craft Textiles",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "409020",
      "MerchantCategoryDesc": "Summer Out Textiles",
      "Category": "4090",
      "CategoryDesc": "Summer Outdoor",
      "Shop": "L5805",
      "ShopDesc": "Summer Decor",
      "Business": "L3500",
      "BusinessDesc": "Seasonal",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "408050",
      "MerchantCategoryDesc": "Summer American Flag",
      "Category": "4080",
      "CategoryDesc": "Americana",
      "Shop": "L5788",
      "ShopDesc": "Mini Seasons",
      "Business": "L3500",
      "BusinessDesc": "Seasonal",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "403010",
      "MerchantCategoryDesc": "Summer Wall Dcor",
      "Category": "4030",
      "CategoryDesc": "Summer Decor",
      "Shop": "L5805",
      "ShopDesc": "Summer Decor",
      "Business": "L3500",
      "BusinessDesc": "Seasonal",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "403005",
      "MerchantCategoryDesc": "Summer Tabletop",
      "Category": "4030",
      "CategoryDesc": "Summer Decor",
      "Shop": "L5805",
      "ShopDesc": "Summer Decor",
      "Business": "L3500",
      "BusinessDesc": "Seasonal",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "408065",
      "MerchantCategoryDesc": "Summer Parade & Dec",
      "Category": "4080",
      "CategoryDesc": "Americana",
      "Shop": "L5788",
      "ShopDesc": "Mini Seasons",
      "Business": "L3500",
      "BusinessDesc": "Seasonal",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "148701",
      "MerchantCategoryDesc": "Disney Classic",
      "Category": "1487",
      "CategoryDesc": "Cotton",
      "Shop": "L5785",
      "ShopDesc": "Licensed Shop",
      "Business": "L3110",
      "BusinessDesc": "Basic Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "148718",
      "MerchantCategoryDesc": "Peanuts",
      "Category": "1487",
      "CategoryDesc": "Cotton",
      "Shop": "L5785",
      "ShopDesc": "Licensed Shop",
      "Business": "L3110",
      "BusinessDesc": "Basic Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "148720",
      "MerchantCategoryDesc": "License MISC PNL",
      "Category": "1487",
      "CategoryDesc": "Cotton",
      "Shop": "L5785",
      "ShopDesc": "Licensed Shop",
      "Business": "L3110",
      "BusinessDesc": "Basic Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "115502",
      "MerchantCategoryDesc": "Fall Bushes",
      "Category": "1155",
      "CategoryDesc": "Fall Floral Components",
      "Shop": "L5816",
      "ShopDesc": "Fall Floral",
      "Business": "L3500",
      "BusinessDesc": "Seasonal",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "115506",
      "MerchantCategoryDesc": "Fall Stems",
      "Category": "1155",
      "CategoryDesc": "Fall Floral Components",
      "Shop": "L5816",
      "ShopDesc": "Fall Floral",
      "Business": "L3500",
      "BusinessDesc": "Seasonal",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "116002",
      "MerchantCategoryDesc": "Holiday Bushes",
      "Category": "1160",
      "CategoryDesc": "Holiday Floral Components",
      "Shop": "L5817",
      "ShopDesc": "Holiday Floral",
      "Business": "L3500",
      "BusinessDesc": "Seasonal",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "398035",
      "MerchantCategoryDesc": "Holiday Trim Lightng",
      "Category": "3980",
      "CategoryDesc": "Holiday Tree Decor",
      "Shop": "L5800",
      "ShopDesc": "Holiday Decor",
      "Business": "L3500",
      "BusinessDesc": "Seasonal",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "398030",
      "MerchantCategoryDesc": "Holiday Boxed Orn",
      "Category": "3980",
      "CategoryDesc": "Holiday Tree Decor",
      "Shop": "L5800",
      "ShopDesc": "Holiday Decor",
      "Business": "L3500",
      "BusinessDesc": "Seasonal",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "395515",
      "MerchantCategoryDesc": "Holiday Tabletop",
      "Category": "3955",
      "CategoryDesc": "Holiday Decor",
      "Shop": "L5800",
      "ShopDesc": "Holiday Decor",
      "Business": "L3500",
      "BusinessDesc": "Seasonal",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "394707",
      "MerchantCategoryDesc": "Autumn Outdoor Decor",
      "Category": "3947",
      "CategoryDesc": "Autumn Outdoor",
      "Shop": "L5795",
      "ShopDesc": "Autumn Decor",
      "Business": "L3500",
      "BusinessDesc": "Seasonal",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "394507",
      "MerchantCategoryDesc": "Autumn Wall Decor",
      "Category": "3945",
      "CategoryDesc": "Autumn Decor",
      "Shop": "L5795",
      "ShopDesc": "Autumn Decor",
      "Business": "L3500",
      "BusinessDesc": "Seasonal",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "394125",
      "MerchantCategoryDesc": "Halloween Serveware",
      "Category": "3941",
      "CategoryDesc": "Halloween Entertaining",
      "Shop": "L5790",
      "ShopDesc": "Halloween Decor",
      "Business": "L3500",
      "BusinessDesc": "Seasonal",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "394007",
      "MerchantCategoryDesc": "Halloween Wall Decor",
      "Category": "3940",
      "CategoryDesc": "Halloween Decor",
      "Shop": "L5790",
      "ShopDesc": "Halloween Decor",
      "Business": "L3500",
      "BusinessDesc": "Seasonal",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "394006",
      "MerchantCategoryDesc": "Halloween Tbltop",
      "Category": "3940",
      "CategoryDesc": "Halloween Decor",
      "Shop": "L5790",
      "ShopDesc": "Halloween Decor",
      "Business": "L3500",
      "BusinessDesc": "Seasonal",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "170030",
      "MerchantCategoryDesc": "Quilter's Showcase",
      "Category": "1700",
      "CategoryDesc": "Cotton Prints",
      "Shop": "L5710",
      "ShopDesc": "Cotton Shop",
      "Business": "L3100",
      "BusinessDesc": "Fabric Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "148714",
      "MerchantCategoryDesc": "NBXMAS/Horror",
      "Category": "1487",
      "CategoryDesc": "Cotton",
      "Shop": "L5785",
      "ShopDesc": "Licensed Shop",
      "Business": "L3110",
      "BusinessDesc": "Basic Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "398020",
      "MerchantCategoryDesc": "Holiday OpenStockOrn",
      "Category": "3980",
      "CategoryDesc": "Holiday Tree Decor",
      "Shop": "L5800",
      "ShopDesc": "Holiday Decor",
      "Business": "L3500",
      "BusinessDesc": "Seasonal",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "148716",
      "MerchantCategoryDesc": "Harry Potter",
      "Category": "1487",
      "CategoryDesc": "Cotton",
      "Shop": "L5785",
      "ShopDesc": "Licensed Shop",
      "Business": "L3110",
      "BusinessDesc": "Basic Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "116114",
      "MerchantCategoryDesc": "Holiday Wreaths",
      "Category": "1161",
      "CategoryDesc": "Holiday Floral Finished",
      "Shop": "L5817",
      "ShopDesc": "Holiday Floral",
      "Business": "L3500",
      "BusinessDesc": "Seasonal",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "116107",
      "MerchantCategoryDesc": "Holiday Garlands",
      "Category": "1161",
      "CategoryDesc": "Holiday Floral Finished",
      "Shop": "L5817",
      "ShopDesc": "Holiday Floral",
      "Business": "L3500",
      "BusinessDesc": "Seasonal",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "116115",
      "MerchantCategoryDesc": "Holiday Arrangements",
      "Category": "1161",
      "CategoryDesc": "Holiday Floral Finished",
      "Shop": "L5817",
      "ShopDesc": "Holiday Floral",
      "Business": "L3500",
      "BusinessDesc": "Seasonal",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "116001",
      "MerchantCategoryDesc": "Holiday PVC",
      "Category": "1160",
      "CategoryDesc": "Holiday Floral Components",
      "Shop": "L5817",
      "ShopDesc": "Holiday Floral",
      "Business": "L3500",
      "BusinessDesc": "Seasonal",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "116003",
      "MerchantCategoryDesc": "Holiday Drieds",
      "Category": "1160",
      "CategoryDesc": "Holiday Floral Components",
      "Shop": "L5817",
      "ShopDesc": "Holiday Floral",
      "Business": "L3500",
      "BusinessDesc": "Seasonal",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "116006",
      "MerchantCategoryDesc": "Holiday Stems",
      "Category": "1160",
      "CategoryDesc": "Holiday Floral Components",
      "Shop": "L5817",
      "ShopDesc": "Holiday Floral",
      "Business": "L3500",
      "BusinessDesc": "Seasonal",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "116250",
      "MerchantCategoryDesc": "Holiday Containers",
      "Category": "1162",
      "CategoryDesc": "Holiday Floral Accessories",
      "Shop": "L5817",
      "ShopDesc": "Holiday Floral",
      "Business": "L3500",
      "BusinessDesc": "Seasonal",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "410240",
      "MerchantCategoryDesc": "Throws",
      "Category": "4102",
      "CategoryDesc": "Home Decor",
      "Shop": "L5746",
      "ShopDesc": "Frames",
      "Business": "L3510",
      "BusinessDesc": "Home",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "311580",
      "MerchantCategoryDesc": "Hobby Wellness",
      "Category": "3115",
      "CategoryDesc": "Hobby",
      "Shop": "L5675",
      "ShopDesc": "Kids Crafts",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "148220",
      "MerchantCategoryDesc": "AP Plush Prints",
      "Category": "1482",
      "CategoryDesc": "AP Plush Fleece",
      "Shop": "L5693",
      "ShopDesc": "Fleece Shoppe",
      "Business": "L3100",
      "BusinessDesc": "Fabric Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "398005",
      "MerchantCategoryDesc": "Holiday StockngSkrts",
      "Category": "3980",
      "CategoryDesc": "Holiday Tree Decor",
      "Shop": "L5800",
      "ShopDesc": "Holiday Decor",
      "Business": "L3500",
      "BusinessDesc": "Seasonal",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "399515",
      "MerchantCategoryDesc": "Holiday Tins",
      "Category": "3995",
      "CategoryDesc": "Holiday Entertaining",
      "Shop": "L5800",
      "ShopDesc": "Holiday Decor",
      "Business": "L3500",
      "BusinessDesc": "Seasonal",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "398010",
      "MerchantCategoryDesc": "HolidayToppersGarlnd",
      "Category": "3980",
      "CategoryDesc": "Holiday Tree Decor",
      "Shop": "L5800",
      "ShopDesc": "Holiday Decor",
      "Business": "L3500",
      "BusinessDesc": "Seasonal",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "395540",
      "MerchantCategoryDesc": "Holiday Wall Décor",
      "Category": "3955",
      "CategoryDesc": "Holiday Decor",
      "Shop": "L5800",
      "ShopDesc": "Holiday Decor",
      "Business": "L3500",
      "BusinessDesc": "Seasonal",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "395545",
      "MerchantCategoryDesc": "Holiday Trend",
      "Category": "3955",
      "CategoryDesc": "Holiday Decor",
      "Shop": "L5800",
      "ShopDesc": "Holiday Decor",
      "Business": "L3500",
      "BusinessDesc": "Seasonal",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "399545",
      "MerchantCategoryDesc": "Holiday Towels",
      "Category": "3995",
      "CategoryDesc": "Holiday Entertaining",
      "Shop": "L5800",
      "ShopDesc": "Holiday Decor",
      "Business": "L3500",
      "BusinessDesc": "Seasonal",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "396530",
      "MerchantCategoryDesc": "Holiday Mats & Flags",
      "Category": "3965",
      "CategoryDesc": "Holiday Outdoor",
      "Shop": "L5800",
      "ShopDesc": "Holiday Decor",
      "Business": "L3500",
      "BusinessDesc": "Seasonal",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "396520",
      "MerchantCategoryDesc": "Holiday Out Decor",
      "Category": "3965",
      "CategoryDesc": "Holiday Outdoor",
      "Shop": "L5800",
      "ShopDesc": "Holiday Decor",
      "Business": "L3500",
      "BusinessDesc": "Seasonal",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "398040",
      "MerchantCategoryDesc": "Holiday Trees",
      "Category": "3980",
      "CategoryDesc": "Holiday Tree Decor",
      "Shop": "L5800",
      "ShopDesc": "Holiday Decor",
      "Business": "L3500",
      "BusinessDesc": "Seasonal",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "399005",
      "MerchantCategoryDesc": "Holiday Giftbags",
      "Category": "3990",
      "CategoryDesc": "Holiday Giftwrap",
      "Shop": "L5800",
      "ShopDesc": "Holiday Decor",
      "Business": "L3500",
      "BusinessDesc": "Seasonal",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "395520",
      "MerchantCategoryDesc": "Holiday Textiles",
      "Category": "3955",
      "CategoryDesc": "Holiday Decor",
      "Shop": "L5800",
      "ShopDesc": "Holiday Decor",
      "Business": "L3500",
      "BusinessDesc": "Seasonal",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "391540",
      "MerchantCategoryDesc": "Holiday Impulse",
      "Category": "3915",
      "CategoryDesc": "Seasonal Impulse",
      "Shop": "L5649",
      "ShopDesc": "Front End & Impulse",
      "Business": "L3510",
      "BusinessDesc": "Home",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "149004",
      "MerchantCategoryDesc": "Sew Lush",
      "Category": "1490",
      "CategoryDesc": "Plush",
      "Shop": "L5693",
      "ShopDesc": "Fleece Shoppe",
      "Business": "L3100",
      "BusinessDesc": "Fabric Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "310103",
      "MerchantCategoryDesc": "Other Journal",
      "Category": "3101",
      "CategoryDesc": "Journaling",
      "Shop": "L5655",
      "ShopDesc": "Papercrafting Supplies",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "189005",
      "MerchantCategoryDesc": "Soft Storage",
      "Category": "1890",
      "CategoryDesc": "Home Storage",
      "Shop": "L5664",
      "ShopDesc": "Storage",
      "Business": "L3510",
      "BusinessDesc": "Home",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "392507",
      "MerchantCategoryDesc": "Val Décor",
      "Category": "3925",
      "CategoryDesc": "Valentine's Day",
      "Shop": "L5788",
      "ShopDesc": "Mini Seasons",
      "Business": "L3500",
      "BusinessDesc": "Seasonal",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "392515",
      "MerchantCategoryDesc": "Val Entertaining",
      "Category": "3925",
      "CategoryDesc": "Valentine's Day",
      "Shop": "L5788",
      "ShopDesc": "Mini Seasons",
      "Business": "L3500",
      "BusinessDesc": "Seasonal",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "393015",
      "MerchantCategoryDesc": "St Pats Entertaining",
      "Category": "3930",
      "CategoryDesc": "St. Patrick's Day",
      "Shop": "L5788",
      "ShopDesc": "Mini Seasons",
      "Business": "L3500",
      "BusinessDesc": "Seasonal",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "392510",
      "MerchantCategoryDesc": "Val Outdoor",
      "Category": "3925",
      "CategoryDesc": "Valentine's Day",
      "Shop": "L5788",
      "ShopDesc": "Mini Seasons",
      "Business": "L3500",
      "BusinessDesc": "Seasonal",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "393512",
      "MerchantCategoryDesc": "Easter Basket Décor",
      "Category": "3935",
      "CategoryDesc": "Easter/Spring",
      "Shop": "L5788",
      "ShopDesc": "Mini Seasons",
      "Business": "L3500",
      "BusinessDesc": "Seasonal",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "393515",
      "MerchantCategoryDesc": "Easter Entertaining",
      "Category": "3935",
      "CategoryDesc": "Easter/Spring",
      "Shop": "L5788",
      "ShopDesc": "Mini Seasons",
      "Business": "L3500",
      "BusinessDesc": "Seasonal",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "401710",
      "MerchantCategoryDesc": "Spring Equipment",
      "Category": "4017",
      "CategoryDesc": "Spring Outdoor",
      "Shop": "L5804",
      "ShopDesc": "Spring Decor",
      "Business": "L3500",
      "BusinessDesc": "Seasonal",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "393507",
      "MerchantCategoryDesc": "Easter Décor",
      "Category": "3935",
      "CategoryDesc": "Easter/Spring",
      "Shop": "L5788",
      "ShopDesc": "Mini Seasons",
      "Business": "L3500",
      "BusinessDesc": "Seasonal",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "122960",
      "MerchantCategoryDesc": "Fall Accessories",
      "Category": "1229",
      "CategoryDesc": "Fall Floral Accessories",
      "Shop": "L5816",
      "ShopDesc": "Fall Floral",
      "Business": "L3500",
      "BusinessDesc": "Seasonal",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "401765",
      "MerchantCategoryDesc": "Spring Mats & Flags",
      "Category": "4017",
      "CategoryDesc": "Spring Outdoor",
      "Shop": "L5804",
      "ShopDesc": "Spring Decor",
      "Business": "L3500",
      "BusinessDesc": "Seasonal",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "401625",
      "MerchantCategoryDesc": "Spring Serveware",
      "Category": "4016",
      "CategoryDesc": "Spring Entertaining",
      "Shop": "L5804",
      "ShopDesc": "Spring Decor",
      "Business": "L3500",
      "BusinessDesc": "Seasonal",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "401715",
      "MerchantCategoryDesc": "Spring Stakes&Trelis",
      "Category": "4017",
      "CategoryDesc": "Spring Outdoor",
      "Shop": "L5804",
      "ShopDesc": "Spring Decor",
      "Business": "L3500",
      "BusinessDesc": "Seasonal",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "160010",
      "MerchantCategoryDesc": "Fashion Collections",
      "Category": "1600",
      "CategoryDesc": "Special Occasion Collections",
      "Shop": "L5700",
      "ShopDesc": "Special Occasion",
      "Business": "L3100",
      "BusinessDesc": "Fabric Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "404510",
      "MerchantCategoryDesc": "Beverage - Non Carb",
      "Category": "4045",
      "CategoryDesc": "Beverage",
      "Shop": "L5649",
      "ShopDesc": "Front End & Impulse",
      "Business": "L3510",
      "BusinessDesc": "Home",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "149002",
      "MerchantCategoryDesc": "Soft and Minky",
      "Category": "1490",
      "CategoryDesc": "Plush",
      "Shop": "L5693",
      "ShopDesc": "Fleece Shoppe",
      "Business": "L3100",
      "BusinessDesc": "Fabric Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "326530",
      "MerchantCategoryDesc": "LB Cake",
      "Category": "3265",
      "CategoryDesc": "Classic Yarn",
      "Shop": "L5660",
      "ShopDesc": "Needle Arts",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "148712",
      "MerchantCategoryDesc": "TV/Movies",
      "Category": "1487",
      "CategoryDesc": "Cotton",
      "Shop": "L5785",
      "ShopDesc": "Licensed Shop",
      "Business": "L3110",
      "BusinessDesc": "Basic Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "409025",
      "MerchantCategoryDesc": "Summer Out Lighting",
      "Category": "4090",
      "CategoryDesc": "Summer Outdoor",
      "Shop": "L5805",
      "ShopDesc": "Summer Decor",
      "Business": "L3500",
      "BusinessDesc": "Seasonal",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "409005",
      "MerchantCategoryDesc": "Summer Out Décor",
      "Category": "4090",
      "CategoryDesc": "Summer Outdoor",
      "Shop": "L5805",
      "ShopDesc": "Summer Decor",
      "Business": "L3500",
      "BusinessDesc": "Seasonal",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "403020",
      "MerchantCategoryDesc": "Summer Textiles",
      "Category": "4030",
      "CategoryDesc": "Summer Decor",
      "Shop": "L5805",
      "ShopDesc": "Summer Decor",
      "Business": "L3500",
      "BusinessDesc": "Seasonal",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "403025",
      "MerchantCategoryDesc": "Summer Lighting",
      "Category": "4030",
      "CategoryDesc": "Summer Decor",
      "Shop": "L5805",
      "ShopDesc": "Summer Decor",
      "Business": "L3500",
      "BusinessDesc": "Seasonal",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "409010",
      "MerchantCategoryDesc": "Summer Mats & Flags",
      "Category": "4090",
      "CategoryDesc": "Summer Outdoor",
      "Shop": "L5805",
      "ShopDesc": "Summer Decor",
      "Business": "L3500",
      "BusinessDesc": "Seasonal",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "407020",
      "MerchantCategoryDesc": "Summer Towels",
      "Category": "4070",
      "CategoryDesc": "Summer Entertaining",
      "Shop": "L5805",
      "ShopDesc": "Summer Decor",
      "Business": "L3500",
      "BusinessDesc": "Seasonal",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "116614",
      "MerchantCategoryDesc": "Summer Wreaths",
      "Category": "1166",
      "CategoryDesc": "Summer Floral Finished",
      "Shop": "L5815",
      "ShopDesc": "Spring/Summer Floral",
      "Business": "L3500",
      "BusinessDesc": "Seasonal",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "170515",
      "MerchantCategoryDesc": "Christmas PNL",
      "Category": "1705",
      "CategoryDesc": "Christmas",
      "Shop": "L5705",
      "ShopDesc": "Holiday",
      "Business": "L3100",
      "BusinessDesc": "Fabric Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "114720",
      "MerchantCategoryDesc": "Beauty",
      "Category": "1147",
      "CategoryDesc": "Bath/Body Care",
      "Shop": "L5649",
      "ShopDesc": "Front End & Impulse",
      "Business": "L3510",
      "BusinessDesc": "Home",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "144015",
      "MerchantCategoryDesc": "Calendars",
      "Category": "1440",
      "CategoryDesc": "Publications",
      "Shop": "L5649",
      "ShopDesc": "Front End & Impulse",
      "Business": "L3510",
      "BusinessDesc": "Home",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "341610",
      "MerchantCategoryDesc": "Custom Shop",
      "Category": "3416",
      "CategoryDesc": "Custom Shop",
      "Shop": "L5750",
      "ShopDesc": "Education Programs",
      "Business": "L3400",
      "BusinessDesc": "Custom Businesses",
      "CustomerGroup": "L28",
      "CustomerGroupDesc": "Frames/Services"
  },
  {
      "MerchantCategory": "341005",
      "MerchantCategoryDesc": "Gift Cards",
      "Category": "3410",
      "CategoryDesc": "Gift Cards",
      "Shop": "L5750",
      "ShopDesc": "Education Programs",
      "Business": "L3400",
      "BusinessDesc": "Custom Businesses",
      "CustomerGroup": "L28",
      "CustomerGroupDesc": "Frames/Services"
  },
  {
      "MerchantCategory": "171001",
      "MerchantCategoryDesc": "Halloween Prints BTY",
      "Category": "1710",
      "CategoryDesc": "Halloween",
      "Shop": "L5705",
      "ShopDesc": "Holiday",
      "Business": "L3100",
      "BusinessDesc": "Fabric Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "311103",
      "MerchantCategoryDesc": "Other Cricut Tech",
      "Category": "3111",
      "CategoryDesc": "Cricut Machines",
      "Shop": "L5650",
      "ShopDesc": "Craft Technology",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "177607",
      "MerchantCategoryDesc": "Wide Flannel",
      "Category": "1776",
      "CategoryDesc": "Fashion Flannel",
      "Shop": "L5715",
      "ShopDesc": "Warm Shop",
      "Business": "L3100",
      "BusinessDesc": "Fabric Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "311102",
      "MerchantCategoryDesc": "Cricut Heat Press",
      "Category": "3111",
      "CategoryDesc": "Cricut Machines",
      "Shop": "L5650",
      "ShopDesc": "Craft Technology",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "341505",
      "MerchantCategoryDesc": "Classroom Supply",
      "Category": "3415",
      "CategoryDesc": "Education",
      "Shop": "L5750",
      "ShopDesc": "Education Programs",
      "Business": "L3400",
      "BusinessDesc": "Custom Businesses",
      "CustomerGroup": "L28",
      "CustomerGroupDesc": "Frames/Services"
  },
  {
      "MerchantCategory": "433010",
      "MerchantCategoryDesc": "Misc/Not Assigned",
      "Category": "4330",
      "CategoryDesc": "Services/Misc",
      "Shop": "L5610",
      "ShopDesc": "Education-Misc",
      "Business": "L3400",
      "BusinessDesc": "Custom Businesses",
      "CustomerGroup": "L28",
      "CustomerGroupDesc": "Frames/Services"
  },
  {
      "MerchantCategory": "114710",
      "MerchantCategoryDesc": "Soap",
      "Category": "1147",
      "CategoryDesc": "Bath/Body Care",
      "Shop": "L5649",
      "ShopDesc": "Front End & Impulse",
      "Business": "L3510",
      "BusinessDesc": "Home",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "441001",
      "MerchantCategoryDesc": "BOSS ea",
      "Category": "4410",
      "CategoryDesc": "Boss",
      "Shop": "L5410",
      "ShopDesc": "Boss",
      "Business": "L3410",
      "BusinessDesc": "Boss",
      "CustomerGroup": "L28",
      "CustomerGroupDesc": "Frames/Services"
  },
  {
      "MerchantCategory": "116770",
      "MerchantCategoryDesc": "Summer Containers",
      "Category": "1167",
      "CategoryDesc": "Summer Floral Accessories",
      "Shop": "L5815",
      "ShopDesc": "Spring/Summer Floral",
      "Business": "L3500",
      "BusinessDesc": "Seasonal",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "310102",
      "MerchantCategoryDesc": "HP Planners",
      "Category": "3101",
      "CategoryDesc": "Journaling",
      "Shop": "L5655",
      "ShopDesc": "Papercrafting Supplies",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "404505",
      "MerchantCategoryDesc": "Beverage",
      "Category": "4045",
      "CategoryDesc": "Beverage",
      "Shop": "L5649",
      "ShopDesc": "Front End & Impulse",
      "Business": "L3510",
      "BusinessDesc": "Home",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "340560",
      "MerchantCategoryDesc": "Supplies-Merchandise",
      "Category": "3405",
      "CategoryDesc": "Other",
      "Shop": "L5830",
      "ShopDesc": "L5830 Other",
      "Business": "L3020",
      "BusinessDesc": "Other Stuff",
      "CustomerGroup": "L24",
      "CustomerGroupDesc": "Other Stuff"
  },
  {
      "MerchantCategory": "163505",
      "MerchantCategoryDesc": "Custom & Luxe",
      "Category": "1635",
      "CategoryDesc": "Custom",
      "Shop": "L5700",
      "ShopDesc": "Special Occasion",
      "Business": "L3100",
      "BusinessDesc": "Fabric Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "315902",
      "MerchantCategoryDesc": "Halloween Jewelry",
      "Category": "3159",
      "CategoryDesc": "Seasonal Jewelry",
      "Shop": "L5343",
      "ShopDesc": "Jewelry",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "189030",
      "MerchantCategoryDesc": "Misc Home Storage",
      "Category": "1890",
      "CategoryDesc": "Home Storage",
      "Shop": "L5664",
      "ShopDesc": "Storage",
      "Business": "L3510",
      "BusinessDesc": "Home",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "152005",
      "MerchantCategoryDesc": "Seasonal Kids",
      "Category": "1520",
      "CategoryDesc": "Juvenile Apparel",
      "Shop": "L5695",
      "ShopDesc": "Fashion Apparel Fabrics",
      "Business": "L3100",
      "BusinessDesc": "Fabric Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "220021",
      "MerchantCategoryDesc": "OtherSewingMachBrand",
      "Category": "2200",
      "CategoryDesc": "Sewing Machines",
      "Shop": "L5672",
      "ShopDesc": "Sewing Tech",
      "Business": "L3110",
      "BusinessDesc": "Basic Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "315903",
      "MerchantCategoryDesc": "Holiday Jewelry",
      "Category": "3159",
      "CategoryDesc": "Seasonal Jewelry",
      "Shop": "L5343",
      "ShopDesc": "Jewelry",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "174601",
      "MerchantCategoryDesc": "Other Holiday",
      "Category": "1746",
      "CategoryDesc": "Other Holiday",
      "Shop": "L5705",
      "ShopDesc": "Holiday",
      "Business": "L3100",
      "BusinessDesc": "Fabric Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "150520",
      "MerchantCategoryDesc": "Lic Christmas Pkgd",
      "Category": "1505",
      "CategoryDesc": "Seasonal",
      "Shop": "L5785",
      "ShopDesc": "Licensed Shop",
      "Business": "L3110",
      "BusinessDesc": "Basic Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "177065",
      "MerchantCategoryDesc": "Other Solids",
      "Category": "1770",
      "CategoryDesc": "Cotton Solids",
      "Shop": "L5710",
      "ShopDesc": "Cotton Shop",
      "Business": "L3100",
      "BusinessDesc": "Fabric Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "404035",
      "MerchantCategoryDesc": "Holiday Socks",
      "Category": "4040",
      "CategoryDesc": "Seasonal Socks",
      "Shop": "L5649",
      "ShopDesc": "Front End & Impulse",
      "Business": "L3510",
      "BusinessDesc": "Home",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "411950",
      "MerchantCategoryDesc": "Holiday Candles",
      "Category": "4119",
      "CategoryDesc": "Candles",
      "Shop": "L5649",
      "ShopDesc": "Front End & Impulse",
      "Business": "L3510",
      "BusinessDesc": "Home",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "399025",
      "MerchantCategoryDesc": "Holiday Wrap Accs",
      "Category": "3990",
      "CategoryDesc": "Holiday Giftwrap",
      "Shop": "L5800",
      "ShopDesc": "Holiday Decor",
      "Business": "L3500",
      "BusinessDesc": "Seasonal",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "326910",
      "MerchantCategoryDesc": "Spin Coats Loop",
      "Category": "3269",
      "CategoryDesc": "Loop Yarn",
      "Shop": "L5660",
      "ShopDesc": "Needle Arts",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "326911",
      "MerchantCategoryDesc": "LB Loop",
      "Category": "3269",
      "CategoryDesc": "Loop Yarn",
      "Shop": "L5660",
      "ShopDesc": "Needle Arts",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "342020",
      "MerchantCategoryDesc": "Rentals",
      "Category": "3420",
      "CategoryDesc": "Services",
      "Shop": "L5750",
      "ShopDesc": "Education Programs",
      "Business": "L3400",
      "BusinessDesc": "Custom Businesses",
      "CustomerGroup": "L28",
      "CustomerGroupDesc": "Frames/Services"
  },
  {
      "MerchantCategory": "168005",
      "MerchantCategoryDesc": "Non Consign Patterns",
      "Category": "1680",
      "CategoryDesc": "Non Consignment Patterns",
      "Shop": "L5730",
      "ShopDesc": "Patterns",
      "Business": "L3100",
      "BusinessDesc": "Fabric Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "147511",
      "MerchantCategoryDesc": "Team Panels",
      "Category": "1475",
      "CategoryDesc": "Team Pkgd",
      "Shop": "L5784",
      "ShopDesc": "Team",
      "Business": "L3110",
      "BusinessDesc": "Basic Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "160035",
      "MerchantCategoryDesc": "Special Occasion EA",
      "Category": "1600",
      "CategoryDesc": "Special Occasion Collections",
      "Shop": "L5700",
      "ShopDesc": "Special Occasion",
      "Business": "L3100",
      "BusinessDesc": "Fabric Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "393010",
      "MerchantCategoryDesc": "St Pats Outdoor",
      "Category": "3930",
      "CategoryDesc": "St. Patrick's Day",
      "Shop": "L5788",
      "ShopDesc": "Mini Seasons",
      "Business": "L3500",
      "BusinessDesc": "Seasonal",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "116509",
      "MerchantCategoryDesc": "Summer Picks",
      "Category": "1165",
      "CategoryDesc": "Summer Floral Components",
      "Shop": "L5815",
      "ShopDesc": "Spring/Summer Floral",
      "Business": "L3500",
      "BusinessDesc": "Seasonal",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "393510",
      "MerchantCategoryDesc": "Easter Outdoor",
      "Category": "3935",
      "CategoryDesc": "Easter/Spring",
      "Shop": "L5788",
      "ShopDesc": "Mini Seasons",
      "Business": "L3500",
      "BusinessDesc": "Seasonal",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "116502",
      "MerchantCategoryDesc": "Summer Bushes",
      "Category": "1165",
      "CategoryDesc": "Summer Floral Components",
      "Shop": "L5815",
      "ShopDesc": "Spring/Summer Floral",
      "Business": "L3500",
      "BusinessDesc": "Seasonal",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "116760",
      "MerchantCategoryDesc": "Summer Accessories",
      "Category": "1167",
      "CategoryDesc": "Summer Floral Accessories",
      "Shop": "L5815",
      "ShopDesc": "Spring/Summer Floral",
      "Business": "L3500",
      "BusinessDesc": "Seasonal",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "196560",
      "MerchantCategoryDesc": "Spring Craft",
      "Category": "1965",
      "CategoryDesc": "Seasonal Craft",
      "Shop": "L5645",
      "ShopDesc": "Decorative Painting",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "196570",
      "MerchantCategoryDesc": "Summer Craft",
      "Category": "1965",
      "CategoryDesc": "Seasonal Craft",
      "Shop": "L5645",
      "ShopDesc": "Decorative Painting",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "106665",
      "MerchantCategoryDesc": "Cosplay BTY",
      "Category": "1066",
      "CategoryDesc": "Apparel Trims",
      "Shop": "L5766",
      "ShopDesc": "Trims",
      "Business": "L3110",
      "BusinessDesc": "Basic Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "106615",
      "MerchantCategoryDesc": "Cosplay EA",
      "Category": "1066",
      "CategoryDesc": "Apparel Trims",
      "Shop": "L5766",
      "ShopDesc": "Trims",
      "Business": "L3110",
      "BusinessDesc": "Basic Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "157520",
      "MerchantCategoryDesc": "Fashion Apparel EA",
      "Category": "1575",
      "CategoryDesc": "Fashion Apparel Collections",
      "Shop": "L5695",
      "ShopDesc": "Fashion Apparel Fabrics",
      "Business": "L3100",
      "BusinessDesc": "Fabric Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "116607",
      "MerchantCategoryDesc": "Summer Garlands",
      "Category": "1166",
      "CategoryDesc": "Summer Floral Finished",
      "Shop": "L5815",
      "ShopDesc": "Spring/Summer Floral",
      "Business": "L3500",
      "BusinessDesc": "Seasonal",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "116506",
      "MerchantCategoryDesc": "Summer Stems",
      "Category": "1165",
      "CategoryDesc": "Summer Floral Components",
      "Shop": "L5815",
      "ShopDesc": "Spring/Summer Floral",
      "Business": "L3500",
      "BusinessDesc": "Seasonal",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "170007",
      "MerchantCategoryDesc": "Diversity/ Inclusion",
      "Category": "1700",
      "CategoryDesc": "Cotton Prints",
      "Shop": "L5710",
      "ShopDesc": "Cotton Shop",
      "Business": "L3100",
      "BusinessDesc": "Fabric Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "311570",
      "MerchantCategoryDesc": "Hobby Machines",
      "Category": "3115",
      "CategoryDesc": "Hobby",
      "Shop": "L5675",
      "ShopDesc": "Kids Crafts",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "197316",
      "MerchantCategoryDesc": "Lego",
      "Category": "1973",
      "CategoryDesc": "Toy / Impulse",
      "Shop": "L5675",
      "ShopDesc": "Kids Crafts",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "394055",
      "MerchantCategoryDesc": "Halloween Pmpk Crvbl",
      "Category": "3940",
      "CategoryDesc": "Halloween Decor",
      "Shop": "L5790",
      "ShopDesc": "Halloween Decor",
      "Business": "L3500",
      "BusinessDesc": "Seasonal",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "391530",
      "MerchantCategoryDesc": "Fall/Halloween Impls",
      "Category": "3915",
      "CategoryDesc": "Seasonal Impulse",
      "Shop": "L5649",
      "ShopDesc": "Front End & Impulse",
      "Business": "L3510",
      "BusinessDesc": "Home",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "179625",
      "MerchantCategoryDesc": "EVA Foam",
      "Category": "1796",
      "CategoryDesc": "Foam",
      "Shop": "L5776",
      "ShopDesc": "Foam/Fiber",
      "Business": "L3110",
      "BusinessDesc": "Basic Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "326603",
      "MerchantCategoryDesc": "PL Value",
      "Category": "3266",
      "CategoryDesc": "Value Yarn",
      "Shop": "L5660",
      "ShopDesc": "Needle Arts",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "326533",
      "MerchantCategoryDesc": "LB Basic Stitch",
      "Category": "3265",
      "CategoryDesc": "Classic Yarn",
      "Shop": "L5660",
      "ShopDesc": "Needle Arts",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "149003",
      "MerchantCategoryDesc": "Pure Plush",
      "Category": "1490",
      "CategoryDesc": "Plush",
      "Shop": "L5693",
      "ShopDesc": "Fleece Shoppe",
      "Business": "L3100",
      "BusinessDesc": "Fabric Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "300113",
      "MerchantCategoryDesc": "Diamond Dotz Kits",
      "Category": "3001",
      "CategoryDesc": "Other Surfaces",
      "Shop": "L5645",
      "ShopDesc": "Decorative Painting",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "197172",
      "MerchantCategoryDesc": "Diamond Dots",
      "Category": "1971",
      "CategoryDesc": "Craft Activities",
      "Shop": "L5675",
      "ShopDesc": "Kids Crafts",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "300114",
      "MerchantCategoryDesc": "DiamondDotz OS & Acc",
      "Category": "3001",
      "CategoryDesc": "Other Surfaces",
      "Shop": "L5645",
      "ShopDesc": "Decorative Painting",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "213041",
      "MerchantCategoryDesc": "Bulk Notions",
      "Category": "2130",
      "CategoryDesc": "Sewing Notions",
      "Shop": "L5670",
      "ShopDesc": "Sewing Construction",
      "Business": "L3110",
      "BusinessDesc": "Basic Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "303030",
      "MerchantCategoryDesc": "Licensed Hardgoods",
      "Category": "3030",
      "CategoryDesc": "Everyday",
      "Shop": "L5649",
      "ShopDesc": "Front End & Impulse",
      "Business": "L3510",
      "BusinessDesc": "Home",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "177015",
      "MerchantCategoryDesc": "Sew Classics",
      "Category": "1770",
      "CategoryDesc": "Cotton Solids",
      "Shop": "L5710",
      "ShopDesc": "Cotton Shop",
      "Business": "L3100",
      "BusinessDesc": "Fabric Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "115009",
      "MerchantCategoryDesc": "Spring Picks",
      "Category": "1150",
      "CategoryDesc": "Spring Floral Components",
      "Shop": "L5815",
      "ShopDesc": "Spring/Summer Floral",
      "Business": "L3500",
      "BusinessDesc": "Seasonal",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "311301",
      "MerchantCategoryDesc": "Cricut Blanks",
      "Category": "3113",
      "CategoryDesc": "Cricut Accessories",
      "Shop": "L5650",
      "ShopDesc": "Craft Technology",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "394060",
      "MerchantCategoryDesc": "Halloween Trend",
      "Category": "3940",
      "CategoryDesc": "Halloween Decor",
      "Shop": "L5790",
      "ShopDesc": "Halloween Decor",
      "Business": "L3500",
      "BusinessDesc": "Seasonal",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "117008",
      "MerchantCategoryDesc": "Basic Bushes",
      "Category": "1170",
      "CategoryDesc": "Basic Floral Components",
      "Shop": "L5818",
      "ShopDesc": "Basic Floral",
      "Business": "L3500",
      "BusinessDesc": "Seasonal",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "148715",
      "MerchantCategoryDesc": "Music",
      "Category": "1487",
      "CategoryDesc": "Cotton",
      "Shop": "L5785",
      "ShopDesc": "Licensed Shop",
      "Business": "L3110",
      "BusinessDesc": "Basic Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "122815",
      "MerchantCategoryDesc": "Fall Arrangements",
      "Category": "1228",
      "CategoryDesc": "Fall Floral Finished",
      "Shop": "L5816",
      "ShopDesc": "Fall Floral",
      "Business": "L3500",
      "BusinessDesc": "Seasonal",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "311203",
      "MerchantCategoryDesc": "Cricut SmartMaterial",
      "Category": "3112",
      "CategoryDesc": "Cricut Cutting Material",
      "Shop": "L5650",
      "ShopDesc": "Craft Technology",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "148301",
      "MerchantCategoryDesc": "State Pride Cotton",
      "Category": "1483",
      "CategoryDesc": "State Pride",
      "Shop": "L5784",
      "ShopDesc": "Team",
      "Business": "L3110",
      "BusinessDesc": "Basic Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "326712",
      "MerchantCategoryDesc": "PL Blanket",
      "Category": "3267",
      "CategoryDesc": "Blanket Yarn",
      "Shop": "L5660",
      "ShopDesc": "Needle Arts",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "326711",
      "MerchantCategoryDesc": "LB Blanket",
      "Category": "3267",
      "CategoryDesc": "Blanket Yarn",
      "Shop": "L5660",
      "ShopDesc": "Needle Arts",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "197031",
      "MerchantCategoryDesc": "Kids Halloween",
      "Category": "1970",
      "CategoryDesc": "Kids Seasonal",
      "Shop": "L5675",
      "ShopDesc": "Kids Crafts",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "123002",
      "MerchantCategoryDesc": "Halloween Components",
      "Category": "1230",
      "CategoryDesc": "Halloween Floral",
      "Shop": "L5816",
      "ShopDesc": "Fall Floral",
      "Business": "L3500",
      "BusinessDesc": "Seasonal",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "123001",
      "MerchantCategoryDesc": "Halloween Finished",
      "Category": "1230",
      "CategoryDesc": "Halloween Floral",
      "Shop": "L5816",
      "ShopDesc": "Fall Floral",
      "Business": "L3500",
      "BusinessDesc": "Seasonal",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "150530",
      "MerchantCategoryDesc": "Lic Halloween Pkgd",
      "Category": "1505",
      "CategoryDesc": "Seasonal",
      "Shop": "L5785",
      "ShopDesc": "Licensed Shop",
      "Business": "L3110",
      "BusinessDesc": "Basic Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "188008",
      "MerchantCategoryDesc": "Plastic Weave Bins",
      "Category": "1880",
      "CategoryDesc": "General Storage",
      "Shop": "L5664",
      "ShopDesc": "Storage",
      "Business": "L3510",
      "BusinessDesc": "Home",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "411945",
      "MerchantCategoryDesc": "Fall/Hallow Candles",
      "Category": "4119",
      "CategoryDesc": "Candles",
      "Shop": "L5649",
      "ShopDesc": "Front End & Impulse",
      "Business": "L3510",
      "BusinessDesc": "Home",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "441002",
      "MerchantCategoryDesc": "BOSS yd",
      "Category": "4410",
      "CategoryDesc": "Boss",
      "Shop": "L5410",
      "ShopDesc": "Boss",
      "Business": "L3410",
      "BusinessDesc": "Boss",
      "CustomerGroup": "L28",
      "CustomerGroupDesc": "Frames/Services"
  },
  {
      "MerchantCategory": "114702",
      "MerchantCategoryDesc": "Antibac/Wipes",
      "Category": "1147",
      "CategoryDesc": "Bath/Body Care",
      "Shop": "L5649",
      "ShopDesc": "Front End & Impulse",
      "Business": "L3510",
      "BusinessDesc": "Home",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "188009",
      "MerchantCategoryDesc": "Kids Storage",
      "Category": "1880",
      "CategoryDesc": "General Storage",
      "Shop": "L5664",
      "ShopDesc": "Storage",
      "Business": "L3510",
      "BusinessDesc": "Home",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "114770",
      "MerchantCategoryDesc": "Lotion",
      "Category": "1147",
      "CategoryDesc": "Bath/Body Care",
      "Shop": "L5649",
      "ShopDesc": "Front End & Impulse",
      "Business": "L3510",
      "BusinessDesc": "Home",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "403525",
      "MerchantCategoryDesc": "Holiday Candy",
      "Category": "4035",
      "CategoryDesc": "Seasonal Candy",
      "Shop": "L5649",
      "ShopDesc": "Front End & Impulse",
      "Business": "L3510",
      "BusinessDesc": "Home",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "321001",
      "MerchantCategoryDesc": "Siser Vinyl&Supplies",
      "Category": "3210",
      "CategoryDesc": "Siser",
      "Shop": "L5650",
      "ShopDesc": "Craft Technology",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "394057",
      "MerchantCategoryDesc": "Halloween Lighting",
      "Category": "3940",
      "CategoryDesc": "Halloween Decor",
      "Shop": "L5790",
      "ShopDesc": "Halloween Decor",
      "Business": "L3500",
      "BusinessDesc": "Seasonal",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "116009",
      "MerchantCategoryDesc": "Holiday Picks",
      "Category": "1160",
      "CategoryDesc": "Holiday Floral Components",
      "Shop": "L5817",
      "ShopDesc": "Holiday Floral",
      "Business": "L3500",
      "BusinessDesc": "Seasonal",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "140250",
      "MerchantCategoryDesc": "Hudson 43",
      "Category": "1402",
      "CategoryDesc": "Frames",
      "Shop": "L5746",
      "ShopDesc": "Frames",
      "Business": "L3510",
      "BusinessDesc": "Home",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "399015",
      "MerchantCategoryDesc": "Holiday Giftwrap",
      "Category": "3990",
      "CategoryDesc": "Holiday Giftwrap",
      "Shop": "L5800",
      "ShopDesc": "Holiday Decor",
      "Business": "L3500",
      "BusinessDesc": "Seasonal",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "399525",
      "MerchantCategoryDesc": "Holiday Serveware",
      "Category": "3995",
      "CategoryDesc": "Holiday Entertaining",
      "Shop": "L5800",
      "ShopDesc": "Holiday Decor",
      "Business": "L3500",
      "BusinessDesc": "Seasonal",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "175005",
      "MerchantCategoryDesc": "Nursery EA",
      "Category": "1750",
      "CategoryDesc": "Nursery",
      "Shop": "L5715",
      "ShopDesc": "Warm Shop",
      "Business": "L3100",
      "BusinessDesc": "Fabric Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "399520",
      "MerchantCategoryDesc": "Holiday Mugs",
      "Category": "3995",
      "CategoryDesc": "Holiday Entertaining",
      "Shop": "L5800",
      "ShopDesc": "Holiday Decor",
      "Business": "L3500",
      "BusinessDesc": "Seasonal",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "197227",
      "MerchantCategoryDesc": "You Can Do It",
      "Category": "1972",
      "CategoryDesc": "Kids POP",
      "Shop": "L5675",
      "ShopDesc": "Kids Crafts",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "148808",
      "MerchantCategoryDesc": "MIGI NST",
      "Category": "1488",
      "CategoryDesc": "No Sew Throws",
      "Shop": "L5693",
      "ShopDesc": "Fleece Shoppe",
      "Business": "L3100",
      "BusinessDesc": "Fabric Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "410210",
      "MerchantCategoryDesc": "Entertaining",
      "Category": "4102",
      "CategoryDesc": "Home Decor",
      "Shop": "L5746",
      "ShopDesc": "Frames",
      "Business": "L3510",
      "BusinessDesc": "Home",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "115503",
      "MerchantCategoryDesc": "Fall Drieds",
      "Category": "1155",
      "CategoryDesc": "Fall Floral Components",
      "Shop": "L5816",
      "ShopDesc": "Fall Floral",
      "Business": "L3500",
      "BusinessDesc": "Seasonal",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "158510",
      "MerchantCategoryDesc": "Specialty",
      "Category": "1585",
      "CategoryDesc": "Off Price Fabric",
      "Shop": "L5695",
      "ShopDesc": "Fashion Apparel Fabrics",
      "Business": "L3100",
      "BusinessDesc": "Fabric Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "170303",
      "MerchantCategoryDesc": "Premium Solids",
      "Category": "1703",
      "CategoryDesc": "Cotton Premium Prints",
      "Shop": "L5710",
      "ShopDesc": "Cotton Shop",
      "Business": "L3100",
      "BusinessDesc": "Fabric Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "122807",
      "MerchantCategoryDesc": "Fall Garlands",
      "Category": "1228",
      "CategoryDesc": "Fall Floral Finished",
      "Shop": "L5816",
      "ShopDesc": "Fall Floral",
      "Business": "L3500",
      "BusinessDesc": "Seasonal",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "115509",
      "MerchantCategoryDesc": "Fall Picks",
      "Category": "1155",
      "CategoryDesc": "Fall Floral Components",
      "Shop": "L5816",
      "ShopDesc": "Fall Floral",
      "Business": "L3500",
      "BusinessDesc": "Seasonal",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "394207",
      "MerchantCategoryDesc": "Hallown Outdr Dcor",
      "Category": "3942",
      "CategoryDesc": "Halloween Outdoor",
      "Shop": "L5790",
      "ShopDesc": "Halloween Decor",
      "Business": "L3500",
      "BusinessDesc": "Seasonal",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "394050",
      "MerchantCategoryDesc": "Halloween Pumpkins",
      "Category": "3940",
      "CategoryDesc": "Halloween Decor",
      "Shop": "L5790",
      "ShopDesc": "Halloween Decor",
      "Business": "L3500",
      "BusinessDesc": "Seasonal",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "394265",
      "MerchantCategoryDesc": "Hallown Mats & Flags",
      "Category": "3942",
      "CategoryDesc": "Halloween Outdoor",
      "Shop": "L5790",
      "ShopDesc": "Halloween Decor",
      "Business": "L3500",
      "BusinessDesc": "Seasonal",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "394110",
      "MerchantCategoryDesc": "Halloween Mugs",
      "Category": "3941",
      "CategoryDesc": "Halloween Entertaining",
      "Shop": "L5790",
      "ShopDesc": "Halloween Decor",
      "Business": "L3500",
      "BusinessDesc": "Seasonal",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "394211",
      "MerchantCategoryDesc": "Hallwn Outdr Electrc",
      "Category": "3942",
      "CategoryDesc": "Halloween Outdoor",
      "Shop": "L5790",
      "ShopDesc": "Halloween Decor",
      "Business": "L3500",
      "BusinessDesc": "Seasonal",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "394765",
      "MerchantCategoryDesc": "Autumn Mats & Flags",
      "Category": "3947",
      "CategoryDesc": "Autumn Outdoor",
      "Shop": "L5795",
      "ShopDesc": "Autumn Decor",
      "Business": "L3500",
      "BusinessDesc": "Seasonal",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "394545",
      "MerchantCategoryDesc": "Autumn Textiles",
      "Category": "3945",
      "CategoryDesc": "Autumn Decor",
      "Shop": "L5795",
      "ShopDesc": "Autumn Decor",
      "Business": "L3500",
      "BusinessDesc": "Seasonal",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "404030",
      "MerchantCategoryDesc": "Fall/Halloween Socks",
      "Category": "4040",
      "CategoryDesc": "Seasonal Socks",
      "Shop": "L5649",
      "ShopDesc": "Front End & Impulse",
      "Business": "L3510",
      "BusinessDesc": "Home",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "394506",
      "MerchantCategoryDesc": "Autumn Tbltop",
      "Category": "3945",
      "CategoryDesc": "Autumn Decor",
      "Shop": "L5795",
      "ShopDesc": "Autumn Decor",
      "Business": "L3500",
      "BusinessDesc": "Seasonal",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "394610",
      "MerchantCategoryDesc": "Autumn Mugs",
      "Category": "3946",
      "CategoryDesc": "Autumn Entertaining",
      "Shop": "L5795",
      "ShopDesc": "Autumn Decor",
      "Business": "L3500",
      "BusinessDesc": "Seasonal",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "394645",
      "MerchantCategoryDesc": "Autumn Towels",
      "Category": "3946",
      "CategoryDesc": "Autumn Entertaining",
      "Shop": "L5795",
      "ShopDesc": "Autumn Decor",
      "Business": "L3500",
      "BusinessDesc": "Seasonal",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "327112",
      "MerchantCategoryDesc": "LB Cotton",
      "Category": "3271",
      "CategoryDesc": "Craft Cotton Yarn",
      "Shop": "L5660",
      "ShopDesc": "Needle Arts",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "321102",
      "MerchantCategoryDesc": "Oracal",
      "Category": "3211",
      "CategoryDesc": "Other Tech",
      "Shop": "L5650",
      "ShopDesc": "Craft Technology",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "148302",
      "MerchantCategoryDesc": "State Pride EA",
      "Category": "1483",
      "CategoryDesc": "State Pride",
      "Shop": "L5784",
      "ShopDesc": "Team",
      "Business": "L3110",
      "BusinessDesc": "Basic Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "410215",
      "MerchantCategoryDesc": "Lighting",
      "Category": "4102",
      "CategoryDesc": "Home Decor",
      "Shop": "L5746",
      "ShopDesc": "Frames",
      "Business": "L3510",
      "BusinessDesc": "Home",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "315901",
      "MerchantCategoryDesc": "Spring/Summr Jewelry",
      "Category": "3159",
      "CategoryDesc": "Seasonal Jewelry",
      "Shop": "L5343",
      "ShopDesc": "Jewelry",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "401610",
      "MerchantCategoryDesc": "Spring Mugs",
      "Category": "4016",
      "CategoryDesc": "Spring Entertaining",
      "Shop": "L5804",
      "ShopDesc": "Spring Decor",
      "Business": "L3500",
      "BusinessDesc": "Seasonal",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "117006",
      "MerchantCategoryDesc": "Basic Wreaths and Ga",
      "Category": "1170",
      "CategoryDesc": "Basic Floral Components",
      "Shop": "L5818",
      "ShopDesc": "Basic Floral",
      "Business": "L3500",
      "BusinessDesc": "Seasonal",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "396012",
      "MerchantCategoryDesc": "Easter Ribbon",
      "Category": "3960",
      "CategoryDesc": "Seasonal Ribbon",
      "Shop": "L5680",
      "ShopDesc": "Ribbon",
      "Business": "L3510",
      "BusinessDesc": "Home",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "116615",
      "MerchantCategoryDesc": "Summer Arrangements",
      "Category": "1166",
      "CategoryDesc": "Summer Floral Finished",
      "Shop": "L5815",
      "ShopDesc": "Spring/Summer Floral",
      "Business": "L3500",
      "BusinessDesc": "Seasonal",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  },
  {
      "MerchantCategory": "197034",
      "MerchantCategoryDesc": "Kids Easter",
      "Category": "1970",
      "CategoryDesc": "Kids Seasonal",
      "Shop": "L5675",
      "ShopDesc": "Kids Crafts",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "326606",
      "MerchantCategoryDesc": "PL Pound Plus",
      "Category": "3266",
      "CategoryDesc": "Value Yarn",
      "Shop": "L5660",
      "ShopDesc": "Needle Arts",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "321002",
      "MerchantCategoryDesc": "Siser Machines",
      "Category": "3210",
      "CategoryDesc": "Siser",
      "Shop": "L5650",
      "ShopDesc": "Craft Technology",
      "Business": "L3300",
      "BusinessDesc": "Craft",
      "CustomerGroup": "L22",
      "CustomerGroupDesc": "Crafts"
  },
  {
      "MerchantCategory": "220201",
      "MerchantCategoryDesc": "DITTO MACHINES",
      "Category": "2202",
      "CategoryDesc": "DITTO",
      "Shop": "L5672",
      "ShopDesc": "Sewing Tech",
      "Business": "L3110",
      "BusinessDesc": "Basic Sewing",
      "CustomerGroup": "L21",
      "CustomerGroupDesc": "Sewing"
  },
  {
      "MerchantCategory": "303050",
      "MerchantCategoryDesc": "Beauty",
      "Category": "3030",
      "CategoryDesc": "Everyday",
      "Shop": "L5649",
      "ShopDesc": "Front End & Impulse",
      "Business": "L3510",
      "BusinessDesc": "Home",
      "CustomerGroup": "L23",
      "CustomerGroupDesc": "Home/Seasonal"
  }
]

module.exports = {
  MERCHANDISE_HIERARCHY_LIST
}