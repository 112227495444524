import React from "react";
import  {Tooltip}  from 'antd';

const ApprovalStatusRenderer = props => {
  return (
    <>
    {props.data.status == false ? 
              <div className="upload-fail">
                <span>Failed</span>
            <Tooltip title = {props.data.validationErrors}>
              <i
                className="fa fa-info-circle"
                // title="Failed"
                style={{
                  color: "red",
                  paddingLeft: "3px"
                }}
              />
            </Tooltip>
          </div>
 : null }
    </>
     )
};

export default ApprovalStatusRenderer;