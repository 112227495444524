import React from "react";
import _ from "lodash";

const ReferenceSkuDetails = ({ infoRefSkuData }) => {
  return (
    <>
      <div className="row px-4 py-5 mx-auto" style={{ width: "80%" }}>
        <div className="col-6">
          <p>
            <span className="promoNameText">SKU: &nbsp;&nbsp;</span>
            {_.isEmpty(infoRefSkuData.data)
              ? "-"
              : infoRefSkuData.data[0].unique_id1}
          </p>
        </div>

        <div className="col-6">
          <p>
            <span className="promoNameText">SKU Description: &nbsp;</span>
            {!_.isEmpty(infoRefSkuData.data) &&
            !_.isEmpty(infoRefSkuData.data[0].description)
              ? infoRefSkuData.data[0].description 
              : "-"}
          </p>
        </div>

        <div className="col-6">
          <p>
            <span className="promoNameText">Retial Price: &nbsp;&nbsp;</span>
            {!_.isEmpty(infoRefSkuData.data) && infoRefSkuData.data[0].price > 0
              ? infoRefSkuData.data[0].price
              : "-"}
          </p>
        </div>

        <div className="col-6">
          <p>
            <span className="promoNameText">Cost: &nbsp;&nbsp;</span>
            {!_.isEmpty(infoRefSkuData.data) && infoRefSkuData.data[0].cost > 0
              ? infoRefSkuData.data[0].cost
              : "-"}
          </p>
        </div>
      </div>
    </>
  );
};

export default ReferenceSkuDetails;
