const SUPER_USER = "Super User"
const MERCHANT = "Merchant"
const MARKETING = "Marketing"
const ADMIN = "Admin"
const EXECUTIVE = "Executive"
const DI = "Data Integrity"
const IT = "IT"

const isSuperUser = ()=>{
  let roleName = localStorage.getItem("role_name");
  return roleName === SUPER_USER
 }
const isMerchant = ()=>{
  let roleName = localStorage.getItem("role_name");
  return roleName === MERCHANT
 }
const isMarketing = ()=>{
  let roleName = localStorage.getItem("role_name");
  return roleName === MARKETING
 }
const isAdmin = ()=>{
  let roleName = localStorage.getItem("role_name");
  return roleName === ADMIN
 }
const isExecutive = ()=>{
  let roleName = localStorage.getItem("role_name");
  return roleName === EXECUTIVE
 }
const isDi = ()=>{
  let roleName = localStorage.getItem("role_name");
  return roleName === DI
 }
const isIt = ()=>{
  let roleName = localStorage.getItem("role_name");
  return roleName === IT
 }


 export {
   isSuperUser,
   isMerchant,
   isMarketing,
   isAdmin,
   isExecutive,
   isDi,
   isIt
 }