import React, { Component } from "react";
import classNames from "classnames";
import { connect } from "react-redux";
import { Bar } from "react-chartjs-2";
import { withRouter } from "react-router-dom";
import moment from "moment";
import { CSVLink } from "react-csv";
import excelHeaders from "./excelHeadersForCompare";
import _ from "lodash";
import { emptyValueFormatter } from "../../../../utils/valueFormatters.js";
import numeral from "numeral";
import { PromotionCalendarGA } from '../../../../utils/GA/GAUtils';

var timePeriod;

export class CompareGraphData extends Component {
  constructor(props) {
    super(props);
    this.csvLink1 = React.createRef();
    this.csvLink2 = React.createRef();
    this.csvLink3 = React.createRef();
    this.csvLinkWeek1 = React.createRef();
    this.csvLinkWeek2 = React.createRef();
    this.csvLinkWeek3 = React.createRef();

    this.state = {
      selectedView: "Daily",
      chartDataFirst: {},
      chartDataSecond: {},
      chartDataThird: {},
      chartOptions: {
        plugins: {
          datalabels: {
            display: false
          },
          labels: false
        },
        tooltips: {
          mode: "nearest",
          callbacks: {
            title: function([tooltipItems], data) {
              let dataArr = data.datasets[tooltipItems.datasetIndex],
                indexNum = _.findIndex(
                  dataArr.data,
                  item => item == tooltipItems.yLabel // do not change to ===, bcs types are different
                ),
                lyDateHandler = _.isEmpty(dataArr.lyDates[indexNum])
                  ? "       -"
                  : dataArr.lyDates[indexNum];

              return (
                "TYDate: " + tooltipItems.xLabel + "\nLYDate: " + lyDateHandler
              );
            },

            beforeLabel: function(tooltipItem, data) {
              return (
                data.datasets[tooltipItem.datasetIndex].label +
                ": " +
                emptyValueFormatter(tooltipItem.yLabel)
              );
            },
            label: function(tooltipItem) {
              return;
            }
          },
          tooltipFontStyle: "bold"
        },
        scales: {
          xAxes: [
            {
              gridLines: {
                display: true,
                drawBorder: true,
                color: "#f8f8f8"
              },
              ticks: {
                fontSize: 10,
                opacity: 0.7,
                display: true
              },
              scaleLabel: {
                display: true,
                labelString: "Days"
              }
            }
          ],
          yAxes: [
            {
              gridLines: {
                display: true,
                drawBorder: true,
                color: "#f8f8f8"
              },
              ticks: {
                fontSize: 10,
                beginAtZero: true,
                userCallback: function(value, index, values) {
                  // Convert the number to a string and splite the string every 3 charaters from the end
                  value = value.toString();
                  value = value.split(/(?=(?:...)*$)/);
                  value = value.join(",");
                  return value;
                }
              },
              scaleLabel: {
                display: true,
                labelString: "Units"
              }
            }
          ]
        }
      }
    };
  }

  componentDidUpdate(oldProps, oldState) {
    if (this.props.view !== oldProps.view) {
      this.setState({
        chartOptions: {
          ...this.state.chartOptions.tooltips,
          scales: {
            xAxes: [
              {
                ...this.state.chartOptions.scales.xAxes.gridLines,
                ...this.state.chartOptions.scales.xAxes.ticks,
                ...this.state.chartOptions.scales.xAxesscaleLabel
              }
            ],
            yAxes: [
              {
                ...this.state.chartOptions.scales.yAxes.gridLines,
                ...this.state.chartOptions.scales.yAxes.ticks,
                scaleLabel: {
                  display: true,
                  labelString: this.props.view
                }
              }
            ]
          }
        }
      });
    }

    if (
      this.props.compareGraphData &&
      _.size(this.props.compareGraphData.data) === 4
    ) {
      if (
        this.props.compareGraphData &&
        (this.props.compareGraphData !== oldProps.compareGraphData ||
          oldState.selectedView !== this.state.selectedView ||
          this.props.view !== oldProps.view)
      ) {
        if (this.state.selectedView === "Daily")
          this.getChartdata(
            this.props.compareGraphData.data[0].dayLevelDataCurrentYear,
            this.props.compareGraphData.data[1].dayLevelDataLastYear,
            "First"
          );
        else
          this.getChartdata(
            this.props.compareGraphData.data[0].weelLevelDataCurrentYear,
            this.props.compareGraphData.data[1].weelLevelDataLastYear,
            "First"
          );
      }
      if (
        this.props.compareGraphData &&
        (this.props.compareGraphData !== oldProps.compareGraphData ||
          oldState.selectedView !== this.state.selectedView ||
          this.props.view !== oldProps.view)
      ) {
        if (this.state.selectedView === "Daily")
          this.getChartdata(
            this.props.compareGraphData.data[2].dayLevelDataCurrentYear,
            this.props.compareGraphData.data[3].dayLevelDataLastYear,
            "Second"
          );
        else
          this.getChartdata(
            this.props.compareGraphData.data[2].weelLevelDataCurrentYear,
            this.props.compareGraphData.data[3].weelLevelDataLastYear,
            "Second"
          );
      }
    } else {
      if (
        this.props.compareGraphData &&
        (this.props.compareGraphData !== oldProps.compareGraphData ||
          oldState.selectedView !== this.state.selectedView ||
          this.props.view !== oldProps.view)
      ) {
        if (this.state.selectedView === "Daily")
          this.getChartdata(
            this.props.compareGraphData.data[0].dayLevelDataCurrentYear,
            this.props.compareGraphData.data[1].dayLevelDataLastYear,
            "First"
          );
        else
          this.getChartdata(
            this.props.compareGraphData.data[0].weelLevelDataCurrentYear,
            this.props.compareGraphData.data[1].weelLevelDataLastYear,
            "First"
          );
      }
      if (
        this.props.compareGraphData &&
        (this.props.compareGraphData !== oldProps.compareGraphData ||
          oldState.selectedView !== this.state.selectedView ||
          this.props.view !== oldProps.view)
      ) {
        if (this.state.selectedView === "Daily")
          this.getChartdata(
            this.props.compareGraphData.data[2].dayLevelDataCurrentYear,
            this.props.compareGraphData.data[3].dayLevelDataLastYear,
            "Second"
          );
        else
          this.getChartdata(
            this.props.compareGraphData.data[2].weelLevelDataCurrentYear,
            this.props.compareGraphData.data[3].weelLevelDataLastYear,
            "Second"
          );
      }

      if (
        this.props.compareGraphData &&
        (this.props.compareGraphData !== oldProps.compareGraphData ||
          oldState.selectedView !== this.state.selectedView ||
          this.props.view !== oldProps.view)
      ) {
        if (this.state.selectedView === "Daily")
          this.getChartdata(
            this.props.compareGraphData.data[4].dayLevelDataCurrentYear,
            this.props.compareGraphData.data[5].dayLevelDataLastYear,
            "Third"
          );
        else
          this.getChartdata(
            this.props.compareGraphData.data[4].weelLevelDataCurrentYear,
            this.props.compareGraphData.data[5].weelLevelDataLastYear,
            "Third"
          );
      }
    }
    timePeriod = this.props.timePeriodArray;
  }

  getGraphData = array => {
    let data = [],
      tempLabel = [];
    array.forEach(ele => {
      if (this.state.selectedView === "Weekly") {
        tempLabel.push("W-" + ele.week_no);
      } else {
        tempLabel.push(moment(ele.transdate_dt).format("MM-DD-YY"));
      }
      if (this.props.view === "Units") {
        data.push(ele["total_quantity"]);
      } else if (this.props.view === "Sales") {
        data.push(ele["total_revenue"]);
      } else {
        data.push(ele["total_margin"]);
      }
    });
    
    return { data: data, tempLabel: tempLabel };
  };

  getChartdata = (TYVal, LYVal, type) => {
    let TYObj = this.getGraphData(TYVal);
    let LYObj = this.getGraphData(LYVal);
    let data = TYObj.data,
      LYData = LYObj.data,
      tempLabel = TYObj.tempLabel,
      LYTempLabel = LYObj.tempLabel;

    if (type === "First") {
      this.setState({
        chartDataFirst: {
          labels: tempLabel,
          datasets: [
            {
              label: `TY${this.props.view}`,
              backgroundColor: "rgb(45,105,235)",
              borderColor: "rgb(45,105,235)",
              borderWidth: 1,
              hoverBackgroundColor: "rgb(211,211,211)",
              hoverBorderColor: "rgb(211,211,211)",
              lyDates: LYTempLabel,
              data
            },
            // {
            //   label: `LY${this.props.view}`,
            //   backgroundColor: "rgb(255, 165, 0)",
            //   borderColor: "rgb(255, 165, 0)",
            //   borderWidth: 1,
            //   hoverBackgroundColor: "rgb(211,211,211)",
            //   hoverBorderColor: "rgb(211,211,211)",
            //   lyDates: LYTempLabel,
            //   data: LYData
            // }
          ]
        }
      });
    } else if (type === "Second") {
      this.setState({
        chartDataSecond: {
          labels: tempLabel,
          datasets: [
            {
              label: `TY${this.props.view}`,
              backgroundColor: "rgb(45,105,235)",
              borderColor: "rgb(45,105,235)",
              borderWidth: 1,
              hoverBackgroundColor: "rgb(211,211,211)",
              hoverBorderColor: "rgb(211,211,211)",
              lyDates: LYTempLabel,
              data
            },
            // {
            //   label: `LY${this.props.view}`,
            //   backgroundColor: "rgb(255, 165, 0)",
            //   borderColor: "rgb(255, 165, 0)",
            //   borderWidth: 1,
            //   hoverBackgroundColor: "rgb(211,211,211)",
            //   hoverBorderColor: "rgb(211,211,211)",
            //   lyDates: LYTempLabel,
            //   data: LYData
            // }
          ]
        }
      });
    } else {
      this.setState({
        chartDataThird: {
          labels: tempLabel,
          datasets: [
            {
              label: `TY${this.props.view}`,
              backgroundColor: "rgb(45,105,235)",
              borderColor: "rgb(45,105,235)",
              borderWidth: 1,
              hoverBackgroundColor: "rgb(211,211,211)",
              hoverBorderColor: "rgb(211,211,211)",
              lyDates: LYTempLabel,
              data
            },
            // {
            //   label: `LY${this.props.view}`,
            //   backgroundColor: "rgb(255, 165, 0)",
            //   borderColor: "rgb(255, 165, 0)",
            //   borderWidth: 1,
            //   hoverBackgroundColor: "rgb(211,211,211)",
            //   hoverBorderColor: "rgb(211,211,211)",
            //   lyDates: LYTempLabel,
            //   data: LYData
            // }
          ]
        }
      });
    }
  };

  toggleDailyWeekly = val => {
    let TempVal = val === "Daily" ? "Days" : "Week";
    if (this.state.selectedView !== val) {
      this.setState({
        selectedView: val,
        chartOptions: {
          ...this.state.chartOptions.tooltips,
          scales: {
            xAxes: [
              {
                ...this.state.chartOptions.scales.xAxes.gridLines,
                ...this.state.chartOptions.scales.xAxes.ticks,
                scaleLabel: {
                  display: true,
                  labelString: TempVal
                }
              }
            ],
            yAxes: [
              {
                ...this.state.chartOptions.scales.yAxes.gridLines,
                ...this.state.chartOptions.scales.yAxes.ticks,
                ...this.state.chartOptions.scales.yAxes.scaleLabel
              }
            ]
          }
        }
      });
    }
    this.submitGA("Click", "Switched_Compare_DailyWeeklyTabs");
  };
  downloadCsv = () => {
    this.csvLink1.current.link.click();
    this.csvLink2.current.link.click();

    if (
      this.props.compareGraphData &&
      _.size(this.props.compareGraphData.data) === 6
    ) {
      this.csvLink3.current.link.click();
    }
  };
  downloadCsvWeek = () => {
    this.csvLinkWeek1.current.link.click();
    this.csvLinkWeek2.current.link.click();

    if (
      this.props.compareGraphData &&
      _.size(this.props.compareGraphData.data) === 6
    ) {
      this.csvLinkWeek3.current.link.click();
    }
  };
  csvFuctionCall = (name, csvLink, data, headers) => {
    let dataClone = _.cloneDeep(data);
    _.map(dataClone, item => {
      item.total_margin = numeral(item.total_margin).format("0,0");
      item.total_quantity = numeral(item.total_quantity).format("0,0");
      item.total_revenue = numeral(item.total_revenue).format("0,0");
      return item;
    })
    
    return (
      <CSVLink
        data={dataClone}
        headers={headers}
        filename={`${name}.csv`}
        className="hidden"
        ref={csvLink}
      />
    );
  };
  submitGA(action,label){
    PromotionCalendarGA({ action, label});
  }

  render() {
    var graphContainer = [];
    var graphDataDailyFirst = [];
    var graphDataDailySecond = [];
    var graphDataDailyThird = [];

    var graphDataWeekly1 = [];
    var graphDataWeekly2 = [];
    var graphDataWeekly3 = [];

    if (
      this.props.compareGraphData &&
      _.size(this.props.compareGraphData.data) > 0
    ) {
      graphDataDailyFirst = this.props.compareGraphData.data[0]
        .dayLevelDataCurrentYear;
      graphDataDailySecond = this.props.compareGraphData.data[2]
        .dayLevelDataCurrentYear;

      graphDataWeekly1 = this.props.compareGraphData.data[0]
        .weelLevelDataCurrentYear;
      graphDataWeekly2 = this.props.compareGraphData.data[2]
        .weelLevelDataCurrentYear;

      if (_.size(this.props.compareGraphData.data) === 6) {
        graphDataDailyThird = this.props.compareGraphData.data[4]
          .dayLevelDataCurrentYear;
        graphDataWeekly3 = this.props.compareGraphData.data[4]
          .weelLevelDataCurrentYear;
      }
    }

    if (
      this.props.compareGraphData &&
      _.size(this.props.compareGraphData.data) === 4 &&
      _.size(timePeriod) === 2
    ) {
      graphContainer.push(
        <div className="row">
          <div className="col-md-6 compareGraphStyle">
            <div className="card quantityCharts">
              <div className="row">
                <div className="col-md-3">
                  <p className="promoCal">AdGroup</p>
                  <p className="compareGraphText">
                    {this.props.compareGraphData &&
                    this.props.compareGraphData.data[0]
                      .dayLevelDataCurrentYear[0]
                      ? this.props.compareGraphData.data[0]
                          .dayLevelDataCurrentYear[0].adgroup
                      : ""}
                  </p>
                </div>
                <div className="col-md-6">
                  <p className="promoCal">Time Period</p>
                  <p className="compareGraphText">
                    {timePeriod ? timePeriod[0].timePeriod : ""}
                  </p>
                </div>
                <div className="col-md-3">
                  <p className="promoCal">Offer</p>
                  <p className="compareGraphText">
                    {this.props.compareGraphData &&
                    this.props.compareGraphData.data[0]
                      .dayLevelDataCurrentYear[0]
                      ? this.props.compareGraphData.data[0]
                          .dayLevelDataCurrentYear[0].offer
                      : ""}
                  </p>
                </div>
              </div>
              <div className="chartDiv1">
                {
                  <Bar
                    data={this.state.chartDataFirst}
                    options={this.state.chartOptions}
                  />
                }
              </div>
            </div>
          </div>
          <div className="col-md-6 compareGraphStyle">
            <div className="card quantityCharts">
              <div className="row">
                <div className="col-md-3">
                  <p className="promoCal">AdGroup</p>
                  <p className="compareGraphText">
                    {this.props.compareGraphData &&
                    this.props.compareGraphData.data[2]
                      .dayLevelDataCurrentYear[0]
                      ? this.props.compareGraphData.data[2]
                          .dayLevelDataCurrentYear[0].adgroup
                      : ""}
                  </p>
                </div>
                <div className="col-md-6">
                  <p className="promoCal">Time Period</p>
                  <p className="compareGraphText">
                    {timePeriod ? timePeriod[1].timePeriod : ""}
                  </p>
                </div>
                <div className="col-md-3">
                  <p className="promoCal">Offer</p>
                  <p className="compareGraphText">
                    {this.props.compareGraphData &&
                    this.props.compareGraphData.data[2]
                      .dayLevelDataCurrentYear[0]
                      ? this.props.compareGraphData.data[2]
                          .dayLevelDataCurrentYear[0].offer
                      : ""}
                  </p>
                </div>
              </div>
              <div>
                <Bar
                  data={this.state.chartDataSecond}
                  options={this.state.chartOptions}
                />
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      graphContainer.push(
        <div className="row">
          <div className="col-md-4 compareGraphStyle">
            <div className="card quantityCharts">
              <div className="row">
                <div className="col-md-3">
                  <p className="promoCal">AdGroup</p>
                  <p className="compareGraphText">
                    {this.props.compareGraphData &&
                    this.props.compareGraphData.data[0]
                      .dayLevelDataCurrentYear[0]
                      ? this.props.compareGraphData.data[0]
                          .dayLevelDataCurrentYear[0].adgroup
                      : ""}
                  </p>
                </div>
                <div className="col-md-6">
                  <p className="promoCal">Time Period</p>
                  <p className="compareGraphText">
                    {timePeriod ? timePeriod[0].timePeriod : ""}
                  </p>
                </div>
                <div className="col-md-3">
                  <p className="promoCal">Offer</p>
                  <p className="compareGraphText">
                    {this.props.compareGraphData &&
                    this.props.compareGraphData.data[0]
                      .dayLevelDataCurrentYear[0]
                      ? this.props.compareGraphData.data[0]
                          .dayLevelDataCurrentYear[0].offer
                      : ""}
                  </p>
                </div>
              </div>
              <div className="chartDiv1">
                {
                  <Bar
                    data={this.state.chartDataFirst}
                    options={this.state.chartOptions}
                  />
                }
              </div>
            </div>
          </div>
          <div className="col-md-4 compareGraphStyle">
            <div className="card quantityCharts">
              <div className="row">
                <div className="col-md-3">
                  <p className="promoCal">AdGroup</p>
                  <p className="compareGraphText">
                    {this.props.compareGraphData &&
                    this.props.compareGraphData.data[2]
                      .dayLevelDataCurrentYear[0]
                      ? this.props.compareGraphData.data[2]
                          .dayLevelDataCurrentYear[0].adgroup
                      : ""}
                  </p>
                </div>
                <div className="col-md-6">
                  <p className="promoCal">Time Period</p>
                  <p className="compareGraphText">
                    {timePeriod ? timePeriod[1].timePeriod : ""}
                  </p>
                </div>
                <div className="col-md-3">
                  <p className="promoCal">Offer</p>
                  <p className="compareGraphText">
                    {this.props.compareGraphData &&
                    this.props.compareGraphData.data[2]
                      .dayLevelDataCurrentYear[0]
                      ? this.props.compareGraphData.data[2]
                          .dayLevelDataCurrentYear[0].offer
                      : ""}
                  </p>
                </div>
              </div>
              <div>
                <Bar
                  data={this.state.chartDataSecond}
                  options={this.state.chartOptions}
                />
              </div>
            </div>
          </div>
          <div className="col-md-4 compareGraphStyle">
            <div className="card quantityCharts">
              <div className="row">
                <div className="col-md-3">
                  <p className="promoCal">AdGroup</p>
                  <p className="compareGraphText">
                    {this.props.compareGraphData &&
                    _.size(this.props.compareGraphData.data) === 6 &&
                    this.props.compareGraphData.data[4]
                      .dayLevelDataCurrentYear[0]
                      ? this.props.compareGraphData.data[4]
                          .dayLevelDataCurrentYear[0].adgroup
                      : ""}
                  </p>
                </div>
                <div className="col-md-6">
                  <p className="promoCal">Time Period</p>
                  <p className="compareGraphText">
                    {" "}
                    {timePeriod !== undefined && _.size(timePeriod) === 3
                      ? timePeriod[2].timePeriod
                      : ""}{" "}
                  </p>
                </div>
                <div className="col-md-3">
                  <p className="promoCal">Offer</p>
                  <p className="compareGraphText">
                    {this.props.compareGraphData &&
                    _.size(this.props.compareGraphData.data) === 6 &&
                    this.props.compareGraphData.data[4]
                      .dayLevelDataCurrentYear[0]
                      ? this.props.compareGraphData.data[4]
                          .dayLevelDataCurrentYear[0].offer
                      : ""}
                  </p>
                </div>
              </div>
              <div>
                <Bar
                  data={this.state.chartDataThird}
                  options={this.state.chartOptions}
                />
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div>
        <div className="quantityTab">
          <div className="row compareGraph">
            <div
              className="col-md-7 BtnGroupRow"
              style={{ textAlign: "right" }}
            >
              <div id="promoCal2" className="divInn btn-group ">
                <button
                  type="button"
                  className={classNames({
                    active: this.state.selectedView === "Daily",
                    " btn": true,
                    "btn-outline-primary": true,
                    btnCalendr: true,
                    calPromo: true
                  })}
                  onClick={() => this.toggleDailyWeekly("Daily")}
                >
                  Daily
                </button>
                <button
                  type="button"
                  className={classNames({
                    active: this.state.selectedView === "Weekly",
                    " btn": true,
                    "btn-outline-primary": true,
                    btnCalendr: true,
                    calPromo: true
                  })}
                  onClick={() => this.toggleDailyWeekly("Weekly")}
                >
                  Weekly
                </button>
              </div>
            </div>

            <div className="col-md-5" style={{ textAlign: "right" }}>
              {this.csvFuctionCall(
                "DailyGraphData1",
                this.csvLink1,
                graphDataDailyFirst,
                excelHeaders.headersDaily
              )}
              {this.csvFuctionCall(
                "DailyGraphData2",
                this.csvLink2,
                graphDataDailySecond,
                excelHeaders.headersDaily
              )}
              {this.csvFuctionCall(
                "DailyGraphData3",
                this.csvLink3,
                graphDataDailyThird,
                excelHeaders.headersDaily
              )}
              {this.state.selectedView === "Weekly" ? (
                <div>
                  {this.csvFuctionCall(
                    "WeeklyGraphData1",
                    this.csvLinkWeek1,
                    graphDataWeekly1,
                    excelHeaders.headersWeekly
                  )}
                  {this.csvFuctionCall(
                    "WeeklyGraphData2",
                    this.csvLinkWeek2,
                    graphDataWeekly2,
                    excelHeaders.headersWeekly
                  )}
                  {this.csvFuctionCall(
                    "WeeklyGraphData3",
                    this.csvLinkWeek3,
                    graphDataWeekly3,
                    excelHeaders.headersWeekly
                  )}
                </div>
              ) : null}
              {/* <button
                className="btn btn-primary downloadbtn"
                onClick={() =>
                  this.state.selectedView === "Daily"
                    ? this.downloadCsv()
                    : this.downloadCsvWeek()
                }
              >
                <i className="fa fa-download" aria-hidden="true" />
              </button> */}
            </div>
          </div>
          <div>{graphContainer}</div>
        </div>
      </div>
    );
  }
}

CompareGraphData.propTypes = {};

const mapStateToProps = state => ({
  loader: state.validationDatas.loader
});

export default connect(mapStateToProps, {})(withRouter(CompareGraphData));
