import { Button } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import {getAdgroupOffer} from "../../../../redux/actions/SimulationAction";
import DataTable from "../../../tables/index.js";
import ColumnDef from "../../../tables/colDefs/AddOffersFromLYColDef.js";
import _ from "lodash";
import Modal from "react-awesome-modal";
import PipelineDropdownRenderer from "../Promo Pipeline/PipelineDropdownRenderer.js";
import PipelineInputRenderer from "../Promo Pipeline/PipelineInputRenderer.js";
import PipelineEventRenderer from "../Promo Pipeline/PipelineInputRenderer.js";
import NumericEditor from "../Promo Pipeline/NumericEditor.js";
import PromoActionRenderer from "../Promo Pipeline/PromoActionRenderer.js";
import AlertDanger from "../../../../utils/AlertMessage/alertDanger.js";
import offerTypeOptions from "../../../../utils/OfferTypes.js";
import PageLoader from "../../../../utils/Loader.js";
import {PromotionCalendarGA} from '../../../../utils/GA/GAUtils.js';
import {
  AtypeOfferValidator,
  PtypeOfferValidator,
  FtypeOfferValidator,
  GtypeOfferValidator,
  BtypeOfferValidator,
  CtypeOfferValidator,
  DtypeOfferValidator
} from "../../../../utils/offerValueValidators";
import moment from "moment";

import { saveAdgroupFromIntermediate, fetchRegSalesPrices, fetchRegPrices, fetchPromoBuildStatus } from "../../../../redux/actions/adgroup";
import { usersLogReport } from "../../../../redux/actions/userAction.js";
import AlertSuccess from "../../../../utils/AlertMessage/alertSuccess.js";
import { PromoCompareGA } from "../../../../utils/GA/GAUtils.js";
import { SIGNSHELL_OPTIONS, UAS_OPTIONS, PROMO_EVENT_TYPE_OPTIONS } from "../Promo Pipeline/constants.js";
import { alertTimer } from "../../../../utils/alertTimer.js";
import { getSignShellOptions } from "../../../../redux/actions/signShellAction.js";
import { isIt } from "../../../../utils/getRoleName.js";
import {pipelineGetTyEvents} from "../../../../redux/actions/calendarAction.js";

let tableData = [];
  class PromoApprovalEditModal extends Component {

    constructor(props) {
      super(props);
      this.state = {
    showModal: this.props.showEditModal,
    diffDays: 0,
    // showEditOffersFromLYModal : false,
    // pipelineIntermediateData : null,
    editOffers :  true,
    promoEdit : false,
    editableAdgroups: [],
    disableSaveBtn: true,
    eventTypeOptions: [],
    offerTypeOptions: [],
    selectedAdgroupId: [],
    selectedAdgroupName: [],
    selectedRowData : [],
    adgroupValuesEmptyError: false,
    plannedOfferChanged: false,
    integerValidationAlert: false,
    addOfferForAdgroupError: false,
    cannotSimulateAlert: false,
    rowIndex: "",
    minQuantityAlert: false,
    showLoader: true,
    somethingWrongAlert: false,
    addPipelineAdGroupRow: [],
    saveBeforeSimulate: false,
    duplicateValueError: false,
    resMessage: "",
    tyEventChanged: false,
    tyRowIndex: "",
    isSuccess: false,
    successMessage: "",
    offerFixedTypeAlert: false,
    offerFixedErrorMessage: "",
    addOffersLyErrorsData : {},
    isFailure: false,
    failureMessage: "" ,
    SIGNSHELL_OPTIONS_NEW2 : [] 
      }
    }

    promoTypeFormatter = (value) => {
      let promoTypeSet = JSON.parse(localStorage.getItem("promo_type"));
      if (value) {
        // return promoTypeSet ? promoTypeSet[value] : value;
        return value;
      }
    };
    componentDidMount = async () => {

      // //calculate diffDays

      // let startDate = new Date(this.props.selectedGridDataForEdit.data.start_date);
      // let endDate = new Date(this.props.selectedGridDataForEdit.data.end_date);
      // let diffTime = Math.abs(startDate.getTime() - endDate.getTime()) + 1;
      // let diffDays = diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      // console.log("🚀 ~ componentDidMount= ~ diffDays:", diffDays)
      // this.setState({ diffDays: diffDays });
  
      let tempData = await this.props.getSignShellOptions();
      const singShellArray = tempData.signShell;
      const formattedData = singShellArray.map(item => ({
        value : item.value ,
        label : item.label
      }))
      this.setState({SIGNSHELL_OPTIONS_NEW2 : formattedData}, ()=> {
        console.log(this.state.SIGNSHELL_OPTIONS_NEW2)
      })
      console.log("Temp data is above")
      let campaignID = this.props.selectedGridDataForEdit.data.campaign_id;
  
      this.setState({
        showLoader: true,
      });
      var sampleTableData = [], tyEventsData1 = [];

        let approvalId = [this.props.selectedGridDataForEdit.data.id];
        let promoId = [];
        if(this.props.selectedGridDataForEdit.data.promo_id){
          promoId.push(this.props.selectedGridDataForEdit.data.promo_id)
        }
        let detailsRes = await this.props.getAdgroupOffer({
          approvalIds: approvalId,
          endDate: "",
          promoIds: promoId.length ? promoId : [],
          startDate: "",
        });
        this.setState({selectedRowData : detailsRes});
        
      const reqObj = { 
        campaign_id: campaignID
      }
  
      const promoBuildDetails = await this.props.fetchPromoBuildStatus(reqObj);

      let tyEventsRes = await this.props.pipelineGetTyEvents({
        campaign_id: parseInt(this.props.selectedGridDataForEdit.data.campaign_id),
      });

      if (detailsRes && tyEventsRes.status) {
        // this.setState({
        //   tyEventsData: tyEventsRes.data,
        //   sampleTableData: detailsRes,
        // });
        tyEventsData1 = tyEventsRes.data;
        sampleTableData = detailsRes;
      }
       sampleTableData = sampleTableData.map((item) => {
        // Create a copy of the item
        let newItem = { ...item };
        
        // Update the key from fixedpricequantity to fixed_price_quantity
        newItem.fixed_price_quantity = newItem.fixedpricequantity;
        newItem.min_quantity = newItem.minquantity
        newItem.bonus_by_quantity = newItem.bonusbyquantity
        delete newItem.fixedpricequantity;
        delete newItem.minquantity
        delete newItem.bonusbyquantity
      
        return newItem;
      });

      var tyEventsData = [];
  
      if (tyEventsData1) {
        tyEventsData1 && tyEventsData1.data && tyEventsData1.data.length && 
        tyEventsData1.data.map((tyEvents, index) => {
          let eventLabel = this.promoTypeFormatter(tyEvents.event_type);
          let labelValue =
            tyEvents.type +
            "-" +
            eventLabel +
            "-" +
            moment(tyEvents.start_date).format("MM/DD/YYYY") +
            "-" +
            moment(tyEvents.end_date).format("MM/DD/YYYY");
            let labelName =
            tyEvents.type +
            "-" +
            eventLabel +
            "-" +
            moment(tyEvents.start_date).format("MM/DD/YYYY") +
            "-" +
            moment(tyEvents.end_date).format("MM/DD/YYYY") + "-" + tyEvents.description;

  
          tyEventsData.push({
            value: labelValue,
            label: labelName,
            event_type: tyEvents.event_type,
            eventId: tyEvents.event_id,
            start_date: moment(tyEvents.start_date).format("YYYY-MM-DD"),
            end_date: moment(tyEvents.end_date).format("YYYY-MM-DD"),
            eventTypeOptions: index + 1,
            type: tyEvents.type,
            promo_sign_shell: tyEvents.sign_shell,
            g_eid: tyEvents.g_eid
          });
        });

  
        let dataArr = sampleTableData.map((item) => {
          console.log(item, 'iteeemmmmmm')
          let selectedEvent = "";
          if (this.state.editOffers) {
            let selectedEventObj = tyEventsData.filter(ele => ele.eventId == item.event_id);
            selectedEvent = selectedEventObj.length > 0 ? selectedEventObj[0].value : "";
          }
          item.eventTypeOptions = tyEventsData;
          item.selected_ty_event = selectedEvent;
          item.offerTypeOptions = offerTypeOptions.newAdgroupOfferTypes;
          // item.signShellOptions = SIGNSHELL_OPTIONS;
          // item.signShellOptions = SIGNSHELL_OPTIONS_NEW;
          item.signShellOptions = this.state.SIGNSHELL_OPTIONS_NEW2;
          item.uasOptions = UAS_OPTIONS;
          item.promoEventTypeOptions = PROMO_EVENT_TYPE_OPTIONS;
          item.plannedOfferHandler = this.plannedOfferHandler;
          item.tyOfferdropdownChange = this.tyOfferdropdownChange;
          item.promo_build_status = this.state.editOffers ? item.promo_build_status : promoBuildDetails[0] ? promoBuildDetails[0].promo_build_status : 'P';
          item.getRegSalesPrices = this.getRegSalesPrices;
          item.isModify = this.state.editOffers;
          // item.promo_type = this.state.editOffers ? selectedEvent : null;
          return item;
        });
        console.log("🚀 ~ dataArr ~ dataArr:", dataArr)
  
        tableData = {
          header: ColumnDef.header(this.state.editOffers),
          defaultColDef: ColumnDef.defaultColDef,
          data: dataArr,
        };  
        this.setState({ showLoader: false, tableData: dataArr });
  
        this.setState({
          eventTypeOptions: tyEventsData,
          offerTypeOptions: offerTypeOptions.newAdgroupOfferTypes,
        });
      }
    };
  
    getRegSalesPrices = (rowData, nodeIndex) => {
      let findError = _.find([rowData], (item) => {
        let eventType = item.ty_event_type || item.promo_type
        let offerType = item.ty_offer_type || item.offer_type
        let minQuantity = item.ty_min_qty || item.ty_min_qty == 0 ? item.ty_min_qty :item.min_quantity
        let bonusPay = item.ty_bonus_pay || item.ty_bonus_pay == 0 ? item.ty_bonus_pay : item.bonus_by_quantity
        let plannedAmount = item.ty_planned_amount || item.ty_planned_amount == 0 ? item.ty_planned_amount : item.offer_value
        let fixedPriceQuantity = item.fixed_price_quantity
        let adgroupName = item.name || item.adgroup_name
  
        if (_.isEmpty(eventType)) {
          this.setState({ eventTypeMandatory: true });
          setTimeout(() => {
            this.setState({ eventTypeMandatory: false });
          }, alertTimer);
          return item;
        }
  
        if(offerType === "A") {
          const values = {
            plannedAmount: plannedAmount,
            adgroupName: adgroupName
          }
    
          const promoRegPrices = {
            regLow : null,
            regHigh: null
          }
    
          const offerValueValidationRes = AtypeOfferValidator(values, promoRegPrices, true);
    
          if(offerValueValidationRes.isInvalid){
            this.setState({
              isFailure: offerValueValidationRes.isInvalid,
              failureMessage: offerValueValidationRes.invalidationMsg
            });
    
            setTimeout(() => {
              this.setState({ isFailure: false, failureMessage: "" });
            }, alertTimer);
            return;
          }
        }
    
        if(offerType === "P") {
          const values = {
            plannedAmount: plannedAmount,
            adgroupName: adgroupName
          }
    
          const promoRegPrices = {
            regLow : null,
            regHigh: null
          }
    
          const offerValueValidationRes = PtypeOfferValidator(values, promoRegPrices, true);
    
          if(offerValueValidationRes.isInvalid){
            this.setState({
              isFailure: offerValueValidationRes.isInvalid,
              failureMessage: offerValueValidationRes.invalidationMsg
            });
    
            setTimeout(() => {
              this.setState({ isFailure: false, failureMessage: "" });
            }, alertTimer);
            return;
          }
        }
    
        if(offerType === "F") {
          const values = {
            plannedAmount: plannedAmount,
            fixedPriceQty: fixedPriceQuantity,
            adgroupName: adgroupName
          }
    
          const promoRegPrices = {
            regLow : null,
            regHigh: null
          }
    
          const offerValueValidationRes = FtypeOfferValidator(values, promoRegPrices, true);
    
          if(offerValueValidationRes.isInvalid){
            this.setState({
              isFailure: offerValueValidationRes.isInvalid,
              failureMessage: offerValueValidationRes.invalidationMsg
            });
    
            setTimeout(() => {
              this.setState({ isFailure: false, failureMessage: "" });
            }, alertTimer);
            return;
          }
        }
    
        if(offerType === "G") {
          const values = {
            minQuantity: minQuantity, 
            bonusBuy: bonusPay, 
            buyMinVal: item.ty_buy_min_value, 
            getMaxVal: item.ty_get_max_value,
            adgroupName: adgroupName
          }
    
          const promoRegPrices = {
            regLow : null,
            regHigh: null
          }
    
          const offerValueValidationRes = GtypeOfferValidator(values, promoRegPrices, true);
    
          if(offerValueValidationRes.isInvalid){
            this.setState({
              isFailure: offerValueValidationRes.isInvalid,
              failureMessage: offerValueValidationRes.invalidationMsg
            });
    
            setTimeout(() => {
              this.setState({ isFailure: false, failureMessage: "" });
            }, alertTimer);
            return;
          }
        }
    
        if(offerType === "B") {
          const values = {
            minQuantity: minQuantity, 
            bonusBuy: bonusPay, 
            plannedAmount: plannedAmount,
            adgroupName: adgroupName
          }
    
          const promoRegPrices = {
            regLow : null,
            regHigh: null
          }
    
          const offerValueValidationRes = BtypeOfferValidator(values, promoRegPrices, true);
    
          if(offerValueValidationRes.isInvalid){
            this.setState({
              isFailure: offerValueValidationRes.isInvalid,
              failureMessage: offerValueValidationRes.invalidationMsg
            });
    
            setTimeout(() => {
              this.setState({ isFailure: false, failureMessage: "" });
            }, alertTimer);
            return;
          }
        }
    
        if(offerType === "C") {
          const values = {
            plannedAmount: plannedAmount,
            minQuantity: minQuantity, 
            bonusBuy: bonusPay, 
            buyMinVal: item.ty_buy_min_value, 
            getMaxVal: item.ty_get_max_value,
            adgroupName: adgroupName
          }
          const promoRegPrices = {
            regLow : null,
            regHigh: null
          }
          const offerValueValidationRes = CtypeOfferValidator(values, promoRegPrices, true);
    
          if(offerValueValidationRes.isInvalid){
            this.setState({
              isFailure: offerValueValidationRes.isInvalid,
              failureMessage: offerValueValidationRes.invalidationMsg
            });
    
            setTimeout(() => {
              this.setState({ isFailure: false, failureMessage: "" });
            }, alertTimer);
            return;
          }
        }
    
        if(offerType === "D") {
          const values = {
            minQuantity: minQuantity, 
            plannedAmount: plannedAmount,
            adgroupName: adgroupName
          }
          const promoRegPrices = {
            regLow : null,
            regHigh: null
          }
          const offerValueValidationRes = DtypeOfferValidator(values, promoRegPrices, true);
    
          if(offerValueValidationRes.isInvalid){
            this.setState({
              isFailure: offerValueValidationRes.isInvalid,
              failureMessage: offerValueValidationRes.invalidationMsg
            });
    
            setTimeout(() => {
              this.setState({ isFailure: false, failureMessage: "" });
            }, alertTimer);
            return;
          }
        }
      });
  
      console.log("*************findError", findError)
      if (_.isEmpty(findError)) {
        // if (data.ty_offer_type === "F" && data.fixed_price_quantity > 1) {
        //   data.ty_offer_type = "nForM";
        // }
  
        let offerType = rowData.ty_offer_type || rowData.offer_type;
        let minQuantity = rowData.ty_min_qty || rowData.min_quantity
        let bonusPay = rowData.ty_bonus_pay || rowData.bonus_by_quantity
        let plannedAmount = rowData.ty_planned_amount || rowData.offer_value
        let fixedPriceQuantity = rowData.fixed_price_quantity
  
        const reqObj = {
          eventId: rowData.eventId || rowData.event_id,
          adgroup_id: rowData.adgroup_id,
          offerType: offerType,
          minQuantity: minQuantity,
          bonusPay: bonusPay,
          plannedAmount: plannedAmount,
          fixedPriceQuantity: fixedPriceQuantity
        }
  
        this.setState({ showLoader: true });
  
        this.props.fetchRegSalesPrices(reqObj).then(res => {
          const result = res.data;
          let tempData = tableData.data;
          tempData.forEach(item => {
            if (item.promo_id) {
              if (item.promo_id == rowData.promo_id) {
                item.regLow = result[0].regLow;
                item.regHigh = result[0].regHigh
                item.saleLow = result[0].saleLow
                item.saleHigh = result[0].saleHigh
              }
            } else {
              if (item.adgroup_id == rowData.adgroup_id && item.eventId == rowData.eventId) {
                item.regLow = result[0].regLow;
                item.regHigh = result[0].regHigh
                item.saleLow = result[0].saleLow
                item.saleHigh = result[0].saleHigh
              }
            }
          });
          tableData.data = tempData;
          this.tyOfferdropdownChange(nodeIndex);
          this.setState({ showLoader: false });
        })
      }
    }
  
    onSelectionChanged = (rowValue) => {
      let addPipelineAdGroupRow = [];
  
      var selectedAdgroupId = [],
        selectedAdgroupName = [];
  
      selectedAdgroupId = _.map(rowValue, (item) => {
        selectedAdgroupName.push({
          adgroup_id: item.adgroup_id,
          adgroup_name: item.adgroup_name,
          ly_start_date: item.ly_start_date,
          ly_end_date: item.ly_end_date,
          ly_event_type: item.ly_event_type,
          ly_offer: item.ly_offer,
        });
        return item.adgroup_id;
      });
  
      _.map(rowValue, (row) => {
        row.isRowSaved = false;
        row.start_date = moment(row.start_date).format("YYYY-MM-DD")
        row.end_date = moment(row.end_date).format("YYYY-MM-DD")
        addPipelineAdGroupRow.push(row);
      });
  
      if (addPipelineAdGroupRow.length) {
        this.setState({
          addPipelineAdGroupRow,
          selectedAdgroupId,
          selectedAdgroupName,
          disableSaveBtn: false,
        });
      } else {
        this.setState({
          disableSaveBtn: true,
        });
      }
    };
    editableRowsHandler = (cellValue) => {
      let { editableAdgroups } = this.state;
      editableAdgroups.push(cellValue);
      this.setState({ editableAdgroups });
    };
  
    isnumberInRange = (value, minValue, maxValue) => {
      if(value >= minValue && value <= maxValue )
        return true;
  
      return false;
    }
  
    addAdgroupToPipeline = async () => {
      let { addPipelineAdGroupRow } = this.state;
      let context = this
  
      this.setState({
        addOffersLyErrorsData: _.cloneDeep({}),
        showLoader: true
      })
      // GA
      if (context.state.editOffers) {
        this.submitGA("Click", "Clicked_EditOffer_SaveButton");
      } else {
        const totalSelectedAdgroups = _.size(addPipelineAdGroupRow);
        this.submitGA("Click", "Clicked_AddOffersFromLY_SaveButton", totalSelectedAdgroups);
      }
      let foundRes = _.filter(addPipelineAdGroupRow, function (o) {
        let flagEditOffers = context.state.editOffers ? o.end_date : o.ty_end_date
  
        return !(moment((flagEditOffers)).isSameOrAfter(moment(), 'day'))
      });
  
      let responseMessage = [];
  
      if (foundRes && foundRes.length) {
        addPipelineAdGroupRow.forEach((responseData) => {
          let flagEditOffers = context.state.editOffers ? responseData.end_date : responseData.ty_end_date
          if (!(moment(flagEditOffers).isSameOrAfter(moment(), 'day'))) {
            responseMessage.push((responseData.adgroup_name || responseData.name) + " whose Event Type " + (flagEditOffers));
          }
        });
  
        if (responseMessage && responseMessage.length) {
  
          this.setState({
  
            notFutureEvent: true,
            resFutureMessage: responseMessage,
          });
  
          setTimeout(() => {
            this.setState({
              notFutureEvent: false,
            });
          }, alertTimer);
        }
      }
  
      const regReqObj = addPipelineAdGroupRow.map(row => {
        return ({ 
          adgroup_id: row.adgroup_id,
          eventId: row.eventId || row.event_id})
      });
  
      const regPricesRes = await this.props.fetchRegPrices(regReqObj);
  
      const regpricesObj = {};
  
      regPricesRes.forEach(ele => {
        let eventId = ele.eventId || ele.event_id;
        const promoKey = `${eventId}_${ele.adgroup_id}`;
        regpricesObj[promoKey] = ele;
      })
  
      this.setState({
        showLoader: false
      })
  
      // let findError = _.find(addPipelineAdGroupRow, (item) => {
        let findError = addPipelineAdGroupRow.map((item) => {
        let eventType = item.ty_event_type || item.promo_type
        let offerType = item.ty_offer_type || item.offer_type
        let minQuantity = item.ty_min_qty || item.ty_min_qty == 0 ? item.ty_min_qty : item.min_quantity
        let bonusPay = item.ty_bonus_pay || item.ty_bonus_pay == 0 ? item.ty_bonus_pay : item.bonus_by_quantity
        let plannedAmount = item.ty_planned_amount || item.ty_planned_amount == 0 ? item.ty_planned_amount : item.offer_value
        let fixedPriceQuantity = item.fixed_price_quantity;
        let buyMinVal = item.ty_buy_min_value;
        let getMaxVal = item.ty_get_max_value;
        let eventId = item.eventId || item.event_id;
        let adgroupName = item.adgroup_name;
  
        const promoKey = `${eventId}_${item.adgroup_id}`
        const promoRegPrices = regpricesObj[promoKey];
  
        if (_.isEmpty(eventType)) {
          this.setState({ eventTypeMandatory: true });
          setTimeout(() => {
            this.setState({ eventTypeMandatory: false });
          }, alertTimer);
          return item;
        }
        if(offerType === "A") {
          const values = {
            plannedAmount: plannedAmount,
            adgroupName: item.name || item.adgroup_name
          }
          const offerValueValidationRes = AtypeOfferValidator(values, promoRegPrices);
    
          if(offerValueValidationRes.isInvalid){
            this.setState({
              isFailure: offerValueValidationRes.isInvalid,
              failureMessage: offerValueValidationRes.invalidationMsg
            });
    
            setTimeout(() => {
              this.setState({ isFailure: false, failureMessage: "" });
            }, alertTimer);
            return item;
          }
        }
    
        if(offerType === "P") {
          const values = {
            plannedAmount: plannedAmount,
            adgroupName: item.name || item.adgroup_name
          }
          const offerValueValidationRes = PtypeOfferValidator(values, promoRegPrices);
    
          if(offerValueValidationRes.isInvalid){
          this.setState({ isFailure: offerValueValidationRes.isInvalid, failureMessage: offerValueValidationRes.invalidationMsg });
          setTimeout(() => {
            this.setState({ isFailure: false, failureMessage: "" });
          }, alertTimer);
          return item;
        }
      }
    
        if(offerType === "F") {
          const values = {
            plannedAmount: plannedAmount,
            fixedPriceQty: fixedPriceQuantity,
            adgroupName: item.name || item.adgroup_name
          }
          const offerValueValidationRes = FtypeOfferValidator(values, promoRegPrices);
    
          if(offerValueValidationRes.isInvalid){
            this.setState({
              isFailure: offerValueValidationRes.isInvalid,
              failureMessage: offerValueValidationRes.invalidationMsg
            });
    
            setTimeout(() => {
              this.setState({ isFailure: false, failureMessage: "" });
            }, alertTimer);
            return item;
          }
        }
    
        if(offerType === "G") {
          const values = {
            minQuantity: minQuantity, 
            bonusBuy: bonusPay, 
            buyMinVal: buyMinVal, 
            getMaxVal: getMaxVal,
            adgroupName: item.name || item.adgroup_name
          }
          const offerValueValidationRes = GtypeOfferValidator(values, promoRegPrices);
    
          if(offerValueValidationRes.isInvalid){
            this.setState({
              isFailure: offerValueValidationRes.isInvalid,
              failureMessage: offerValueValidationRes.invalidationMsg
            });
    
            setTimeout(() => {
              this.setState({ isFailure: false, failureMessage: "" });
            }, alertTimer);
            return item;
          }
        }
    
        if(offerType === "B") {
          const values = {
            minQuantity: minQuantity, 
            bonusBuy: bonusPay, 
            plannedAmount: plannedAmount,
            adgroupName: item.name || item.adgroup_name
          }
          const offerValueValidationRes = BtypeOfferValidator(values, promoRegPrices);
    
          if(offerValueValidationRes.isInvalid){
            this.setState({
              isFailure: offerValueValidationRes.isInvalid,
              failureMessage: offerValueValidationRes.invalidationMsg
            });
    
            setTimeout(() => {
              this.setState({ isFailure: false, failureMessage: "" });
            }, alertTimer);
            return item;
          }
        }
    
        if(offerType === "C") {
          const values = {
            plannedAmount: plannedAmount,
            minQuantity: minQuantity, 
            bonusBuy: bonusPay, 
            buyMinVal: buyMinVal, 
            getMaxVal: getMaxVal,
            adgroupName: item.name || item.adgroup_name
          }
          const offerValueValidationRes = CtypeOfferValidator(values, promoRegPrices);
    
          if(offerValueValidationRes.isInvalid){
            this.setState({
              isFailure: offerValueValidationRes.isInvalid,
              failureMessage: offerValueValidationRes.invalidationMsg
            });
    
            setTimeout(() => {
              this.setState({ isFailure: false, failureMessage: "" });
            }, alertTimer);
            return item;
          }
        }
    
        if(offerType === "D") {
          const values = {
            minQuantity: minQuantity, 
            plannedAmount: plannedAmount,
            adgroupName: item.name || item.adgroup_name
          }
          const offerValueValidationRes = DtypeOfferValidator(values, promoRegPrices);
    
          if(offerValueValidationRes.isInvalid){
            this.setState({
              isFailure: offerValueValidationRes.isInvalid,
              failureMessage: offerValueValidationRes.invalidationMsg
            });
    
            setTimeout(() => {
              this.setState({ isFailure: false, failureMessage: "" });
            }, alertTimer);
            return item;
          }
        }
      });
  
      const filteredfindError = findError.filter(element => element !== undefined);
  
      if (_.isEmpty(filteredfindError) && !foundRes.length) {
        let cumulativeOffersArray = [];
        let cumulativeOffersString = ''
  
        this.setState({
          showLoader: true,
        });
        addPipelineAdGroupRow.forEach((item) => {
          if (this.state.editOffers) {
            if (item.offer_type === "F" && item.fixed_price_quantity > 1) {
              item.offer_type = "nForM";
            }
  
            cumulativeOffersString =
              item.adgroup_id +
              item.promo_type +
              item.start_date +
              item.end_date +
              item.offer_type +
              item.offer_value +
              item.fixed_price_quantity +
              item.min_quantity +
              item.bonus_by_quantity +
              item.ty_buy_min_value +
              item.ty_get_max_value +
              item.type
          } else {
            if (item.ty_offer_type === "F" && item.fixed_price_quantity > 1) {
              item.ty_offer_type = "nForM";
            }
  
            cumulativeOffersString =
              item.adgroup_id +
              item.ty_event_type +
              item.ty_start_date +
              item.ty_end_date +
              item.ty_offer_type +
              item.ty_planned_amount +
              item.fixed_price_quantity +
              item.ty_min_qty +
              item.ty_bonus_pay +
              item.ty_buy_min_value +
              item.ty_get_max_value +
              item.type;
          }
          item.eventId = item.eventId || item.event_id
          cumulativeOffersArray.push(cumulativeOffersString);
        });
  
        let duplicates =
          _.uniq(cumulativeOffersArray).length !== cumulativeOffersArray.length;
  
        if (duplicates) {
          this.setState({
            showLoader: false,
            duplicateValueError: true,
          });
  
          setTimeout(() => {
            this.setState({
              duplicateValueError: false,
            });
          }, alertTimer);
        } else {

          let futureData = this.state.addPipelineAdGroupRow[0];
          console.log("🚀 ~ //findError ~ futureData:", futureData);
          let currentData = this.state.selectedRowData[0];
          console.log("🚀 ~ //findError ~ currentData:", currentData)

          let currentDataString = currentData.type + '_' +
          currentData.promo_sign_shell + '_' + 
          currentData.promo_event_type + '_' +
          currentData.uas +'_' +
          (currentData.ty_buy_min_value || currentData.ty_buy_min_value==0 ? currentData.ty_buy_min_value : 0) + '_' +
          (currentData.ty_get_max_value || currentData.ty_get_max_value==0 ? currentData.ty_get_max_value : 0) + '_' +
          currentData.promo_type + '_' +
          currentData.offer_type + '_' +
          (currentData.fixedpricequantity || currentData.fixedpricequantity==0 ? currentData.fixedpricequantity : 0) + '_' +
          (currentData.minQuantity || currentData.minQuantity==0 ? currentData.minQuantity : 0) + '_' +
          (currentData.bonusbyquantity || currentData.bonusbyquantity==0 ? currentData.bonusbyquantity : 0) + '_' +
          (currentData.offer_value || currentData.offer_value==0 ? currentData.offer_value : 0);


          let futureDataString = (futureData.type) + '_' +
          (futureData.promo_sign_shell) + '_' + 
          futureData.promo_event_type + '_' +
          futureData.uas + '_' +
          (futureData.ty_buy_min_value || futureData.ty_buy_min_value==0 ? futureData.ty_buy_min_value : 0) +'_' +
          (futureData.ty_get_max_value || futureData.ty_get_max_value==0 ? futureData.ty_get_max_value : 0) +'_' +
          futureData.promo_type +'_' +
          futureData.offer_type + '_' +
          (futureData.fixed_price_quantity || futureData.fixed_price_quantity==0 ? futureData.fixed_price_quantity : 0) +'_' +
          (futureData.min_quantity || futureData.min_quantity==0 ? futureData.min_quantity : 0) +'_'+
          (futureData.bonus_by_quantity || futureData.bonus_by_quantity==0 ? futureData.bonus_by_quantity : 0) +'_' +
          (futureData.offer_value || futureData.offer_value==0 ? futureData.offer_value : 0);

           console.log('futureDataString',futureDataString);
           console.log('currentDataString',currentDataString);

          let modify = currentDataString != futureDataString;
    
          console.log("modify",modify);


          if(!modify && localStorage.getItem("role")==2) {
            this.setState({
              isFailure: true,
              failureMessage: 'No changes have been made'
            });
  
         setTimeout(()=>{
          this.setState({
            isFailure : false,
            failureMessage : ''
          })
         },alertTimer)
  
         this.setState({
            showLoader: false,
          });
  
          return ;
        }

          this.props.usersLogReport({
            screen: "modify_from_promoApproval",
            action: "save",
            userId: localStorage.getItem("email"),
          });
  
          let res = await this.props.saveAdgroupFromIntermediate({
            multiplePromos: addPipelineAdGroupRow,
            campaignId : this.props.selectedGridDataForEdit.data.campaign_id
          });
          console.log("🚀 ~ //findError ~ res:", res)
          // if (res.status === false) {
          //   this.setState({ showLoader: false, somethingWrongAlert: true, resMessage: "Something went wrong!" });
          //   setTimeout(() => {
          //     this.setState({
          //       somethingWrongAlert: false
          //     });
          //   }, 4500);
          //   return;
          // }
  
          let foundPromoApprovalRes = _.filter(res, { inPromoApproval: true });
  
          if(foundPromoApprovalRes && foundPromoApprovalRes.length > 0) {
            this.setState({
              isSuccess: true,
              successMessage: "Promo offer/s has been submitted for approval",
              showLoader: false,
            });
  
            setTimeout(() => {
              this.setState({
                isSuccess: false,
                successMessage: ''
              });
              
              // if (this.state.editOffers) {
              //   this.props.closeAdgroupModal(true);
              // }
              this.onSelectionChanged([])
            }, alertTimer);
          } else {
          // setTimeout(() => {
            // this.props.updatePipelineData([], "intermediate", this.state.editOffers ? "editOffers" : "");
          // }, alertTimer);
  
          let foundRes = _.filter(res, { status: false });
          console.log("🚀 ~ //findError ~ foundRes:", foundRes)
          let foundSuccessRes = _.filter(res, { status: true });
          console.log("🚀 ~ //findError ~ foundSuccessRes:", foundSuccessRes)
  
  
          if(foundSuccessRes.length > 0) {
            this.setState({
              isSuccess: true,
              successMessage: `Offer Modified Successfully`,
              showLoader: false,
            });
  
            setTimeout(() => {
              this.setState({
                isSuccess: false,
                successMessage: ''
              });
              this.props.onSuccessfulEdit();
            }, alertTimer);
          }
          let responseMessage = [];

          if(foundRes && foundRes.length>0){
            this.setState({
              isFailure: true,
              failureMessage: `${res[0].msg}`,
              showLoader: false,
            });
  
            setTimeout(() => {
              this.setState({
                isFailure: false,
                failureMessage: ''
              });
              this.props.onSuccessfulEdit();
            }, alertTimer);
          }
  
          // if (foundRes && foundRes.length>0) {
          //   console.log("error")
          //   let errorTableData = foundRes.map(error => {
          //     return {
          //       adgroup_name: error.data.adgroupName,
          //       event_detail: error.data.ty_event
          //     };
          // });
  
          //   let tableData = {
          //     header: ColumnDef.addOfferFromLYErrorsData,
          //     defaultColDef: ColumnDef.defaultColDef,
          //     data : errorTableData
          //   }
  
          //   this.setState({ addOffersLyErrorsData: _.cloneDeep(tableData) });
  
          // }
  
          if (res[0].status) {
            addPipelineAdGroupRow.map((item) => {
              item.isRowSaved = true;
              return item;
            });
            this.setState({
              showLoader: false,
              adgroupAddedAlert: true,
              // addPipelineAdGroupRow: [],
              disableSaveBtn : true ,
            });
            setTimeout(() => {
              this.setState({
                adgroupAddedAlert: false,
              });
              this.onSelectionChanged([])
            }, alertTimer);
          }
          this.setState({
            showLoader: false,
          });
        }


        }
      }
    };
  
    // simulateHandler = () => {
    //   let { addPipelineAdGroupRow } = this.state;
  
    //   let foundRes = _.filter(addPipelineAdGroupRow, function (o) { return !(moment(o.ty_start_date).isAfter(moment())) });
  
    //   let responseMessage = [];
  
    //   if (foundRes && foundRes.length) {
    //     addPipelineAdGroupRow.forEach((responseData) => {
    //       if (!(moment(responseData.ty_start_date).isAfter(moment()))) {
    //         responseMessage.push(responseData.adgroup_name + " whose Event Type " + responseData.ty_start_date);
    //       }
    //     });
  
    //     if (responseMessage && responseMessage.length) {
  
    //       this.setState({
  
    //         notFutureEvent: true,
    //         resFutureMessage: responseMessage,
    //       });
  
    //       setTimeout(() => {
    //         this.setState({
    //           notFutureEvent: false,
    //         });
    //       }, alertTimer);
    //     }
    //   }
    //   let findError = _.find(addPipelineAdGroupRow, (item) => {
    //     let fieldName = offerTypeOptions.fieldValidation[item.ty_offer_type];
    //     if (fieldName) {
    //       let findEmptyFields = _.every(fieldName, (field) => {
    //         return item[field] > 0 && !item.isRowSaved;
    //       });
  
    //       if (findEmptyFields && !foundRes.length) {
    //         this.setState({
    //           saveBeforeSimulate: true,
    //         });
    //         // setTimeout(() => {
    //         //   this.setState({
    //         //     saveBeforeSimulate: false
    //         //   });
    //         // }, 2500);
    //         return item;
    //       }
    //     }
    //   });
    //   if (_.isEmpty(findError) && !foundRes.length) {
    //     this.goToSimulateHandler();
    //   }
    // };
    // goToSimulateHandler = () => {
    //   if (this.state.eventTypeOptions.length) {
    //     localStorage.setItem("promoIdsForUrl", null);
    //     localStorage.setItem("adgroupIdsForUrl", this.state.selectedAdgroupId);
    //     localStorage.setItem(
    //       "adgroupNamesForUrl",
    //       JSON.stringify(this.state.selectedAdgroupName)
    //     );
    //     this.props.history.push(`/createpromotion?pipelineInt=true`);
    //   } else {
    //     this.setState({
    //       cannotSimulateAlert: true,
    //     });
  
    //     setTimeout(() => {
    //       this.setState({
    //         cannotSimulateAlert: false,
    //       });
    //     }, alertTimer);
    //   }
    // };
    plannedOfferHandler = (index) => {
      this.setState({
        plannedOfferChanged: !this.state.plannedOfferChanged,
        rowIndex: index,
      });
    };
  
    tyOfferdropdownChange = (index) => {
      this.setState({
        tyEventChanged: !this.state.tyEventChanged,
        tyRowIndex: index,
      });
    };
    submitGA(action, label, value) {
      PromotionCalendarGA({ action, label, value });
    }


    render() {
      return (
        <>
          <Modal
            visible={true}
            width="90%"
            height="86%"
            effect="fadeInDown"
            onClickAway={() => this.props.editCloseModal()}
          >
            <PageLoader loader={this.state.showLoader}>
              <div className="alertDivs">
                {this.state.adgroupValuesEmptyError ? (
                  <AlertDanger
                    message="Please enter all the fields for selected Adgroup"
                    show={this.state.adgroupValuesEmptyError}
                  />
                ) : null}
                {this.state.addOfferForAdgroupError ? (
                  <AlertDanger
                    message="Please select offer for selected Adgroup"
                    show={this.state.addOfferForAdgroupError}
                  />
                ) : null}
                {this.state.minQuantityAlert ? (
                  <AlertDanger
                    message="Please enter minimum value"
                    show={this.state.minQuantityAlert}
                  />
                ) : null}
                {this.state.eventTypeMandatory ? (
                  <AlertDanger
                    message="Please select event type"
                    show={this.state.eventTypeMandatory}
                  />
                ) : null}
                {this.state.notFutureEvent ? (
                  <AlertDanger
                    message={`The Adroup ${this.state.resFutureMessage.toString()} is of a past date,  
                    Please try again with different Event type`}
                    show={this.state.notFutureEvent}
                  />
                ) : null}
                {this.state.offerFixedTypeAlert? (
                <AlertDanger
                    message={this.state.offerFixedErrorMessage}
                    show={this.state.offerFixedTypeAlert}
                  />
                  ): null}
                {this.state.integerValidationAlert ? (
                  <AlertDanger
                    message="Entered fields cannot contain decimals"
                    show={this.state.integerValidationAlert}
                  />
                ) : null}
                {this.state.cannotSimulateAlert ? (
                  <AlertDanger
                    message="No events are mapped to this campaign"
                    show={this.state.cannotSimulateAlert}
                  />
                ) : null}
                {this.state.somethingWrongAlert ? (
                  <AlertDanger
                    message={this.state.resMessage.toString()}
                    show={this.state.somethingWrongAlert}
                  />
                ) : null}
                {this.state.adgroupAddedAlert ? (
                  <AlertSuccess
                    message={this.state.editOffers ? "Offer(s) modified successfully" : "Offer(s) added successfully"}
                    show={this.state.adgroupAddedAlert}
                  />
                ) : null}
  
                {this.state.isSuccess ? (
                  <AlertSuccess
                    message={this.state.successMessage}
                    show={this.state.isSuccess}
                  />
                ) : null}
  
                {this.state.duplicateValueError ? (
                  <AlertDanger
                    message="Duplicate values entered"
                    show={this.state.duplicateValueError}
                  />
                ) : null}
                  {this.state.isFailure ? (
                  <AlertDanger
                    message={this.state.failureMessage}
                    show={this.state.isFailure}
                  />
                ) : null}
              </div>
              <div className="closeicon">
                <span style={{ cursor: "pointer" }}>
                  <i
                    className="fa fa-times"
                    aria-hidden="true"
                    onClick={() => this.props.editCloseModal()}
                  />
                </span>
              </div>
              <h4 className="promoHeading mb-4">
                Modify Offer
              </h4>
              <div className="row pipelineCopydiv" style={{ marginTop: "-1%" }}>
                <div className="col-md-10">
                  <div className="row" style={{ marginLeft: "7%" }}>
                    <div className="col-md-4">
                      <span className="promoNameText row">
                        Promotion Name:
                        <p className="promonameResult">
                          {`${moment(this.props.selectedGridDataForEdit.data.campaign_start_date).format("MM/DD/YYYY")} - ${moment(this.props.selectedGridDataForEdit.data.campaign_end_date).format("MM/DD/YYYY")}`}
                        </p>
                      </span>
                    </div>
                    <div className="col-md-3">
                      <span
                        className="promoNameText row"
                        style={{ paddingLeft: "2%" }}
                      >
                        Start Date:
                        <p className="promonameResult">
                          {moment(this.props.selectedGridDataForEdit.data.campaign_start_date).format("MM/DD/YYYY")}
                        </p>
                      </span>
                    </div>
                    <div className="col-md-3">
                      <span
                        className="promoNameText row"
                        style={{ paddingLeft: "2%" }}
                      >
                        End Date:
                        <p className="promonameResult">
                          {moment(this.props.selectedGridDataForEdit.data.campaign_end_date).format("MM/DD/YYYY")}
                        </p>
                      </span>
                    </div>
                    <div className="col-md-2">
                      <span
                        className="promoNameText row"
                        style={{ paddingLeft: "2%" }}
                      >
                        # of days:
                        <p className="promonameResult">{this.state.diffDays}</p>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div
                style={{ margin: "1% 1% 0% 1%", height: this.state.addOffersLyErrorsData.data ? "50%" : "80%", }}
                className="add-adgroup-pipeline"
              >
                <DataTable
                  parentId="myGridForPipeline"
                  parentStyle={{
                    // height: this.state.addOffersLyErrorsData.data ? "95%" : "95%",
                    height : "75%",
                    width: "100%",
                    position: "relative",
                  }}
                  parentClass="ag-theme-balham"
                  tableData={tableData}
                  rowClassRules={this.state.rowClassRules}
                  frameworkComponents={{
                    pipelineDropdownRenderer: PipelineDropdownRenderer,
                    pipelineInputRenderer: PipelineInputRenderer,
                    numericEditor: NumericEditor,
                    promoActionRenderer: PromoActionRenderer
                  }}
                  rowSelection="multiple"
                  suppressRowClickSelection={true}
                  selectOfferRow={this.onSelectionChanged}
                  overlayNoRowsTemplate={"No LY events mapped to this campaign"}
                  editableRowsHandler={this.editableRowsHandler}
                  plannedOfferChanged={this.state.plannedOfferChanged}
                  tyEventChanged={this.state.tyEventChanged}
                  plannedOfferHandler={this.plannedOfferHandler}
                  tyOfferdropdownChange={this.tyOfferdropdownChange}
                  rowIndex={this.state.rowIndex}
                  tyRowIndex={this.state.tyRowIndex}
                //   pipelineTableExport={this.state.pipelineTableExport}
                //   pipelineTableExportHandler={this.pipelineTableExportHandler}
                />
             <div className="text-center mt-2">
                <button
                  className="btn btn-outline-primary promo3Buttons"
                  disabled={this.state.disableSaveBtn || isIt()}
                  onClick={() => {
                    this.addAdgroupToPipeline();
                  }}
                >
                  Save
                </button>
  
                {/* {!this.state.editOffers ? (
                  <button
                    className="btn btn-outline-primary promo3Buttons ml-2"
                    disabled={this.state.disableSaveBtn}
                    onClick={() => this.simulateHandler()}
                  >
                    Simulate
                  </button>
                ) : (
                  ""
                )} */}
              </div>
  
              </div>
  
              {
                this.state.addOffersLyErrorsData.data && this.state.addOffersLyErrorsData.data.length>0 &&
                <>
                  <p className="errorMessage" style={{marginTop:"-5%"}}>Following offers cannot be created</p>
                  <div className="copyOffertableContainer">
                    <DataTable
                      parentId="myGridForCopyPromoErrorDetails"
                      tableData={this.state.addOffersLyErrorsData}
                      parentStyle={{
                        height: "20vh",
                        width: "100%",
                        position: "relative",
                      }}
                      autoSizeCol={true}
                    />
                  </div>
                </>
              }
  
              
            </PageLoader>
          </Modal>
          {this.state.saveBeforeSimulate ? (
            <Modal
              visible={true}
              width="400px"
              height="200px"
              effect="fadeInDown"
            >
              <h5 className="delete-confirmatn-modal text-left mt-3">
                There are some unsaved changes. Do you wish to continue?
              </h5>
              <div className="text-center delete-btns">
                <button
                  className="btn btn-outline-secondary"
                  onClick={() => {
                    this.setState({ saveBeforeSimulate: false });
                  }}
                >
                  No
                </button>
                <button
                  className="btn btn-outline-secondary ml-3"
                  onClick={() => this.goToSimulateHandler()}
                >
                  Yes
                </button>
              </div>
            </Modal>
          ) : null}
        </>
      );
    }
  }
  
  export default connect(null, {
    saveAdgroupFromIntermediate,
    usersLogReport,
    fetchRegSalesPrices,
    fetchRegPrices,
    fetchPromoBuildStatus ,
    getSignShellOptions,
    getAdgroupOffer,
    pipelineGetTyEvents
  })(PromoApprovalEditModal);
