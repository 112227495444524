import axios from "../../utils/axios/index";
import { config } from "../../utils/config";
import _ from "lodash";
import { LOADER} from "../actions/types";

export const getSignShellOptions = payload => async dispatch => {
  dispatch({
    type : LOADER , 
    payload : false
  })

  const response = await axios
  .get(`${config.baseUrl}/promo/signShellValues`,payload)
  .then(res => {
    dispatch({
      type : LOADER ,
      payload : false
    })
    return res.data
  })
  .catch(err => {
    dispatch({
      type : LOADER , 
      payload : false
    })
    return err
  })
  return response
} 