import React, { Component } from "react";

export class BackToCalendar extends Component {
  render() {
    return (
      <div 
        className="row col-md-3"
        onClick={this.props.onClickHandler || null}
      >
        <i
          className="fa fa-angle-double-left double-left-icon"
          onClick={() => this.props.history.push("/promotion")}
          aria-hidden="true"
        ></i>
        <p id="backToCalLink" onClick={() => this.props.history.push("/promotion")}>
          Back to Calendar
        </p>
      </div>
    );
  }
}

export default BackToCalendar;
