import {
  LOADER,
  SET_EVENT_MASTER_DATA,
  SET_EVENT_DETAILS,
  GET_CAMPAIGNS_DATA,
  SET_LYEVENT_DATA,
  SET_VEHICLE_DATA,
  SET_PROMO_GROUP_DATA
} from "../actions/types";

import _ from "lodash";

const initialState = {
  loader: true,
  eventMasterData: [],
  eventDetails: {},
  campaignData: [],
  campaignOptions: [],
  vehicleData: [],
  lyEventOptions: [],
  promoGroupOptions: [],
  lyeventData: []
};

// const VEHICLE_CONSTANTS = ['DM', 'SPC-D', 'SPC-P', 'UAS', 'TMD', 'SB', 'SS-P', 'GO', 'SB', 'PD', 'SS', 'SS-D'];
const VEHICLE_CONSTANTS = ['CLEAR','DM','GO','SPC','SPC-D','SPC-P','SS','SS-D','SS-P','UAS'];

const formatCampaignOptions = (campaigndata) => {
  const formattedData = campaigndata.map(data => {
    return {
      value: data.campaign_id,
      // label: data.campaign_name
      label: `${data.campaign_start_date} - ${data.campaign_end_date}`
    }
  });

  return formattedData;
}

const formatVehicleData = vehicleData => {
  const tempData = VEHICLE_CONSTANTS;

  const formattedData = vehicleData.map(data => {
    _.pull(tempData, data.vehicle)
    if (data.vehicle == "DM") {
      return {
        value: data.vehicle,
        label: data.vehicle,
        disabled: true
      }
    }
    return {
      value: data.vehicle,
      label: data.vehicle
    }
  });

  if (tempData.length > 0) {
    tempData.forEach(vehicle => {
      const obj = vehicle == "DM" ?
        {
          value: vehicle,
          label: vehicle,
          disabled: true
        }
        : {
          value: vehicle,
          label: vehicle
        }
      formattedData.push(obj);
    })
  }

  return formattedData;
}

const formatLyEventOptions = lyeventData => {
  const formattedData = lyeventData.map(data => {
    return {
      value: data.ly_event,// data.event_id
      label: data.ly_event
    }
  });

  return formattedData;

}

const formatPromoGroupOptions = promoGroupData => {
  const formattedData = promoGroupData.map(data => {
    return {
      value: data.id,
      label: data.description
    }
  });

  return formattedData;

}

export default function (state = initialState, action) {
  switch (action.type) {

    case LOADER:
      return {
        ...state,
        loader: action.payload
      };
    case SET_EVENT_MASTER_DATA:
      return {
        ...state,
        eventMasterData: action.payload
      };
    case SET_EVENT_DETAILS:
      return {
        ...state,
        eventDetails: action.payload
      };
    case GET_CAMPAIGNS_DATA:
      return {
        ...state,
        campaignData: action.payload,
        campaignOptions: formatCampaignOptions(action.payload)
      }
    case SET_VEHICLE_DATA:
      return {
        ...state,
        vehicleData: formatVehicleData(action.payload),
      }
    case SET_LYEVENT_DATA:
      return {
        ...state,
        lyeventData: action.payload,
        lyEventOptions: formatLyEventOptions(action.payload),
      }
    case SET_PROMO_GROUP_DATA:
      return {
        ...state,
        promoGroupOptions: formatPromoGroupOptions(action.payload),
      }
    default:
      return state;
  }
}
