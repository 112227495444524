
export const dayLevelColumnMappingObj = {
    adgroup_id: 'AdGroupId',
    adgroup_name: 'AdGroupName',
    adgroup_desc: 'Adgroup Description',
    start_date: 'StartDate',
    end_date: 'EndDate',
    tydate: 'Date',
    transdate : 'TyDate',
    weekday : 'Day',
    event_type : 'EventType',
    offers : 'Offer',
    offer_type : 'OfferType',
    units : 'Units',
    sales : 'Sales ($)',
    margin : 'Margin ($)',
    fisc_week : 'FW',
    fisc_month : 'FM',
    fisc_quarter : 'FQ',
    merch_id : 'Buyer',
    shop_desc : 'ShopDesc',
    dm_name : 'DM',
    aur : 'AUR',
    gm : 'GM%',
    final_perc_offer: 'MajorOffer',
    lydate : 'LyDate',
    isTyData: 'IsTyData'
  };
  
  export const dayLevelFixedColumns = [
    'adgroup_id',
    'adgroup_name',
    'transdate',
    'fisc_week',
    'fisc_month',
    'fisc_quarter',
    'dm_name',
    'weekday'
  ];

  export const OFFER_COMPARISON = {
    SAME: "Same",
    DEEPER: "Deeper",
    SHALLOWER: "Shallower",
    NET_NEW: "Net New",
    NON_COMP: "Non Comp",
  };

  export const DOLLAR_TYPE = ['F','A'];