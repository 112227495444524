import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "./sidebarMini.css";

class SideBarMini extends Component {
  constructor(props) {
    super(props);
    this.state = {
      class: "",
      display: "none",
      showMinipopup: true,
      showClose: true,
      hideArrow: false
    };
  }
  onHover = () => {
    this.setState({
      class: "active",
      display: "inline-block"
    });
  };

  onLeaveHover = () => {
    this.setState({
      class: "",
      display: ""
    });
  };

  render() {
    const userRole = localStorage.getItem('role');
    const allowPromoApproval = userRole && userRole  == 2? true : true;

    return (
      <div>
        <div className="wrapper2">
          <nav
            id="sidebarMini"
            className={this.state.class}
            onMouseEnter={e => this.onHover(e)}
            onMouseLeave={e => this.onLeaveHover(e)}
          >
            <div className="sidebar-header" />

            <ul className="list-unstyled components sideBarList2">
              <li
                className={
                  this.props.location.pathname === "/promotion" ? "active" : ""
                }
                style={{ marginTop: "17px" }}
              >
                <a href="/promotion">
                  <i
                    className="fa fa-calendar icon-styling"
                    aria-hidden="true"
                  />
                  <span className=""> Calendar </span>
                </a>
              </li>
              <li
                className={
                  this.props.location.pathname === "/dayleveloffer" ? "active" : ""
                }
              >
                <a href="/dayleveloffer">
                  {/* <a> */}
                  <i
                    className="fa fa-percent icon-styling"
                    aria-hidden="true"
                  />
                  <span className=""> Day Level Offer </span>
                </a>
              </li>
              <li
                className={
                  this.props.location.pathname === "/ComparePromotion"
                    ? "active"
                    : ""
                }
              // style={{ pointerEvents: "none" }}
              >
                <a href="/ComparePromotion">
                {/* <a> */}
                  <i
                    className="fa fa-balance-scale icon-styling"
                    aria-hidden="true"
                  />
                  <span className=""> Compare </span>
                </a>
              </li>
              <li
                className={
                  this.props.location.pathname === "/executivesummary"
                    ? "active"
                    : ""
                }
                onClick={() => {
                  localStorage.setItem("campaignDetailsForSummary", "");
                  localStorage.setItem("campStartDateForSummary", "");
                  localStorage.setItem("campEndDateForSummary", "");
                }}
              // style={{ pointerEvents: "none" }}
              >
                <a href="/executivesummary">
                {/* <a> */}
                  <i
                    className="fa fa-newspaper-o icon-styling"
                    aria-hidden="true"
                  />
                  <span className=""> Summary </span>
                </a>
              </li>

              <li
                className={
                  this.props.location.pathname === "/referenceSku"
                    ? "active"
                    : ""
                }
              // style={{ pointerEvents: "none" }}
              >
                <a href="/referenceSku">
                {/* <a> */}
                  <i className="fa fa-cube icon-styling" aria-hidden="true" />
                  <span className=""> Reference SKU </span>
                </a>
              </li>

              <li
                className={
                  this.props.location.pathname === "/adgroupMaster"
                    ? "active"
                    : ""
                }
              >
                <a href="/adgroupMaster">
                  <img
                    src="../img/adgroupMaster.svg"
                    className="sidebar-icon-styling"
                    aria-hidden="true"
                  />
                  <span className="ml-3"> Adgroup Master </span>
                </a>
              </li>

              <li
                className={
                  this.props.location.pathname === "/eventMaster"
                    ? "active"
                    : ""
                }
              >
                <a href="/eventMaster">
                  <img
                    src="../img/eventMaster.svg"
                    className="sidebar-icon-styling"
                    aria-hidden="true"
                  />
                  <span className="ml-3"> Event Master </span>
                </a>
              </li>

              {allowPromoApproval? <li
                className={
                  this.props.location.pathname === "/promoApproval"
                    ? "active"
                    : ""
                }
              >
                <a href="/promoApproval">
                  <img
                    src="../img/promoApproval.svg"
                    className="sidebar-icon-styling"
                    aria-hidden="true"
                  />
                  <span className="ml-3"> Promo Approval </span>
                </a>
              </li> : null}

           
            
           <li
                className={
                  this.props.location.pathname === "/reports"
                    ? "active"
                    : ""
                }
              >
                <a href="/reports">
                  <img
                    src="../img/reports.svg"
                    className="sidebar-icon-styling"
                    aria-hidden="true"
                  />
                  <span className="ml-3">Reports</span>
                </a>
              </li>

           



              <li
                className={
                  this.props.location.pathname === "/ticketGenerate"
                    ? "active"
                    : ""
                }
                style={{ marginBottom: "15px" }}
              >
                <a href="/ticketGenerate">
                {/* <a> */}
                  <i className="fa fa-question-circle-o icon-styling" aria-hidden="true" />
                  <span className=""> Raise Ticket </span>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    );
  }
}

export default withRouter(SideBarMini);
