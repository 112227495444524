import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Modal from "react-awesome-modal";
import { updatePromoApprovalData } from "../../../../redux/actions/promoApprovalAction";

import { Button, Input } from "antd";
const { TextArea } = Input;


const PromoApprovalNotesModal = (props) => {
  const data = props.promoApprovalData.find((item) => {
    return item.id === props.selectedNotesId.id;
  });
  
  const [notes, setNotes] = useState(data ? data.note_mar : "");
  const [isDisabled, setIsDisabled] = useState(true)

  useEffect(() => {
    if(localStorage.getItem("role") == "3" && props.agGridProps.colDef.field == "note_mar"){
      setIsDisabled(false)
    }
    if(localStorage.getItem("role") == "6" && props.agGridProps.colDef.field == "note_di"){
      setIsDisabled(false);
    }
  }, [])

  const handleInputChange = (e) => {
    setNotes(e.target.value);
  };

  const handleNotesUpdate = (e) => {
    props.selectedNotesId.handleNotesUpdate(notes, props.selectedNotesId);
    props.updatePromoApprovalData({
      id: props.selectedNotesId.id,
      notes: notes
    })
    props.notesCloseModal();
  };

  return (
    <div>
      <Modal
        visible={props.showModal}
        width="500px"
        height={"200px"}
        effect="fadeInUp"
        onClickAway={() => props.notesCloseModal()}
      >
        <div style={{ padding: "20px" }}>
          <h5 style={{ marginLeft: "10px", marginBottom: "10px" }}>Marketing notes</h5>
          <TextArea
            rows={3}
            cols={10}
            value={notes}
            onChange={handleInputChange}
            maxLength={100} 
            placeholder="Write your notes here..."
            disabled={isDisabled}
            style={{fontWeight : "bold"}}
          />
          {!isDisabled ? (
            <Button type="primary" 
            onClick={() =>handleNotesUpdate()}
            style={{ marginLeft: "180px", alignItems: "center", marginTop: "15px"}}
            disabled={isDisabled}
            >
              Save
            </Button>
          ) : null }
          
        </div>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    promoApprovalData: state.promoApproval.promoApprovalData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updatePromoApprovalData: (updatedData) => dispatch(updatePromoApprovalData(updatedData))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PromoApprovalNotesModal);