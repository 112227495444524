import axios from "../../utils/axios/index";
import { config } from "../../utils/config";
import {
  LOADER,
  EDIT_REFERENCE_SKU,
  SIMILAR_SKU_PRODUCTLIST,
  SAVE_REFERENCE_SKU,
  BULK_UPLOAD_SKU
} from "./types";

export const editReferenceSku = reqObject => async dispatch => {
  // dispatch({
  //   type: LOADER,
  //   payload: true
  // });
  const response = await axios.post(
    `${config.baseUrl}/product/editReferenceSku`,
    reqObject
  );

  dispatch(
    {
      type: EDIT_REFERENCE_SKU,
      payload: response.data
    },
    {
      type: LOADER,
      payload: false
    }
  );
  return response.data;
};

export const infoReferenceSku = skuId => async dispatch => {
  // dispatch({
  //   type: LOADER,
  //   payload: true
  // });
  const response = await axios.get(`${config.baseUrl}/adgroup/${skuId}/sku`);

  dispatch(
    {
      type: SAVE_REFERENCE_SKU,
      payload: response.data
    },
    {
      type: LOADER,
      payload: false
    }
  );
  return response.data;
};

export const similarSkuProductsList = reqObject => async dispatch => {
  const response = await axios.post(
    `${config.baseUrl}/product/similarSkuProductsList`,
    reqObject
  );

  dispatch(
    {
      type: SIMILAR_SKU_PRODUCTLIST,
      payload: response.data
    },
    {
      type: LOADER,
      payload: false
    }
  );
  return response.data;
};

export const bulkRefSkuUpload = reqObject => async dispatch => {
  dispatch({
    type: LOADER,
    payload: true
  });

  const response = await axios
    .post(`${config.baseUrl}/product/upload`, reqObject, {
      headers: { "Content-Type": "multipart/form-data" }
    })
    .then(response => {
      dispatch(
        {
          type: BULK_UPLOAD_SKU,
          payload: response.data
        },
        {
          type: LOADER,
          payload: false
        }
      );
      return response;
    })

    .catch(error => {
      return { data: "", status: false };
    });
  return response.data;
};
