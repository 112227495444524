import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import MultiSelectAll from "../EventMaster/MultiSelectDropdown";
import { STATUS_OPTIONS } from "./constants";
import { Button } from 'antd';

import {
  getBuyerDetails,
  getCampaignDetails
} from "../../../../redux/actions/promoApprovalAction";

import _ from "lodash";
import moment from "moment";
import axiosInstance from "../../../../utils/axios";
import { config } from "../../../../utils/config";

class FilterPromoApproval extends Component {

  constructor(props) {
    super(props);
    this.state = {
      showLoader: false,
      isSuccess: false,
      successMessage: '',
      isFailure: false,
      failureMessage: "",
      buyerOptions: [],
      selectedBuyer: [],
      campaignOptions: [],
      selectedDm: [],
      selectedStatus: [],
      selectedFilters: {}
    }
  }

  async componentDidMount() {
    const buyerData = await this.props.getBuyerDetails();

    const tempbuyerOptions = buyerData.buyerDetails.map(buyer => {
      return {
        value: buyer.buyer_id,
        label: buyer.buyer_id
      }
    })

    const camapigndata = await this.props.getCampaignDetails();

    const tempcampaignOptions = camapigndata.campaigns.map(campaign => {
      return {
        value: campaign.campaign_id,
        // label: campaign.campaign_name
        label: `${moment(campaign.campaign_start_date).format("MM/DD/YYYY")} - ${moment(campaign.campaign_end_date).format("MM/DD/YYYY")}`
      }
    })

    this.setState({
      buyerOptions: tempbuyerOptions.length > 0 ? [
        {
          label: "Select all",
          value: "all"
        },
        ...tempbuyerOptions] : [],
      campaignOptions: tempcampaignOptions.length > 0 ? [
        {
          label: "Select all",
          value: "all"
        },
        ...tempcampaignOptions] : [],

    })
  }

  handleFilterSelectionChange = (value, key) => {
    const tempSelectedfilters = _.cloneDeep(this.state.selectedFilters);
    tempSelectedfilters[key] = value;

    this.setState({
      selectedFilters: _.cloneDeep(tempSelectedfilters)
    })

  }

  isSelectAllSelected = (data) => {
    return data.some(item => item.value === "all");
  }

  handleFilter = async(index) => {
    const reqObj = {};
    let incrementNumber = (index || 1);
    // this.props.handleFilter(this.state.selectedFilters)
    if(localStorage.getItem("run-simulation")){
      const response = await axiosInstance.post(
        `${config.baseUrl}/dev/run-simulation`,
        // 'https://promosmart-joann-opt.iaproducts.ai/promo/load_promo_historical',
        {"limit":1000,"user_id":1}
      );
  
      if(response.data.message === "Offer Script sucessfully executed"){
        console.log(response.data) 
        incrementNumber++;
        setTimeout(() => {
          console.log("RecursiveApi Called",incrementNumber)
          this.handleFilter(incrementNumber)
        }, 60000);
      }
      // console.log(`${config.baseUrl}/promo/load_promo_historical`)
      // const response = await axiosInstance.post(
      //   // `${config.baseUrl}/promo/load_promo_historical`,
      //   `${config.baseUrl}/promo/verify_load_process`,
      //   // 'https://promosmart-joann-opt.iaproducts.ai/promo/load_promo_historical',
      //   {"limit":1000,"user_id":1}
      // );
      // console.log(response.data) 

      // if(response.data.message === "All campaigns completed"){
      //   console.log("All Compaings completed")
      // }else{
      //   incrementNumber++;
      //   setTimeout(() => {
      //     console.log("RecursiveApi Called",incrementNumber)
      //     this.handleFilter(incrementNumber)
      //   }, 60000);
      // }
    }else{
      this.props.handleFilterButtonClick(this.state.selectedFilters)
    }
    

  }

  handleCancel = () => {

    this.setState({
      selectedFilters: {}
    })

    this.props.handleReset();
  }

  render() {
    return (
      <div className="filterContainer">
        <div className="filterFieldContainer">
          <div className="filterField" >
            <p className="dropdownLabel">Buyer Id</p>
            <MultiSelectAll
              placeholder="Select Buyer Id"
              options={[...this.state.buyerOptions]}
              value={this.state.selectedFilters.buyerId ? this.state.selectedFilters.buyerId : []}
              onchange={this.handleFilterSelectionChange}
              keyValue={'buyerId'}
            />
          </div>
          <div className="filterField" >
            <p className="dropdownLabel">Campaign</p>
            <MultiSelectAll
              className="filterField"
              placeholder="Select Campaign"
              options={[...this.state.campaignOptions]}
              value={this.state.selectedFilters.campaignIds ? this.state.selectedFilters.campaignIds : []}
              onchange={this.handleFilterSelectionChange}
              keyValue={'campaignIds'}
            /> </div>
          <div className="filterField" >
            <p className="dropdownLabel">Status</p>
            <MultiSelectAll
              className="filterField"
              placeholder="Select Status"
              options={STATUS_OPTIONS}
              value={this.state.selectedFilters.status ? this.state.selectedFilters.status : []}
              onchange={this.handleFilterSelectionChange}
              keyValue={'status'}
            />
          </div>
        </div>
        <div className="filterBtnContainer">
          <Button
            type="primary"
            disabled={!(this.state.selectedFilters.buyerId && this.state.selectedFilters.buyerId.length > 0)}
            onClick={this.handleFilter}
          >
            Filter
          </Button>
          <Button
            // type="primary"
            onClick={this.handleCancel}
          >
            Reset
          </Button>
        </div>
      </div>
    )
  }
};

// export default FilterPromoApproval;

const mapStateToProps = (state) => {
  return {
    // loader: state.eventMaster.loader,
    // deactivatePromoData: state.eventMaster.deactivatePromoData,
  };
};

export default connect(
  mapStateToProps,
  {
    getBuyerDetails,
    getCampaignDetails
  }
)(FilterPromoApproval);