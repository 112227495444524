// Create materialized view to fetch this
// const MERCHCAT_ID_TO_DESC_MAPPING = {
// 	"100201": "Cotton Linen Blend",
// 	"100255": "Chenille",
// 	"100257": "Novelty",
// 	"100258": "Suede/Hides",
// 	"100260": "Jacquards",
// 	"100262": "Velvet",
// 	"100263": "Tweed",
// 	"100264": "Stain Resistant",
// 	"100265": "Upholstery Panel",
// 	"100290": "Window Treatments",
// 	"100505": "Marine Vinyl",
// 	"100508": "Clear Vinyl",
// 	"100518": "Multipurpose Vinyl",
// 	"100520": "Utility",
// 	"103640": "Home Dec Fabric EA",
// 	"106210": "HD Trims EA",
// 	"106215": "HD Trims BTY",
// 	"106605": "Value Trims/EA",
// 	"106610": "Trims EA",
// 	"106615": "Cosplay EA",
// 	"106660": "Trims BTY",
// 	"106665": "Cosplay BTY",
// 	"107222": "Notions By The Yard",
// 	"107225": "DRAPERY NOTIONS RODS",
// 	"107230": "Home Fabric Care",
// 	"107235": "Peel & Stick",
// 	"107260": "Home DTcor Notions",
// 	"107270": "Home DTcor Tools",
// 	"109275": "Outdoor Print",
// 	"109280": "Outdoor Non-Branded",
// 	"111201": "VFF Print",
// 	"111202": "VFF Solid",
// 	"111220": "VFF Vinyl",
// 	"114702": "Wedding OOL",
// 	"114710": "Soap",
// 	"114720": "Beauty",
// 	"114770": "Wedding Liquidation",
// 	"115002": "Spring Bushes",
// 	"115003": "Spring Drieds",
// 	"115006": "Spring Stems",
// 	"115009": "Spring Picks",
// 	"115107": "Spring Garlands",
// 	"115114": "Spring Wreaths",
// 	"115115": "Spring Arrangements",
// 	"115260": "Spring g Accessories",
// 	"115270": "Spring Containers",
// 	"115502": "Fall Bushes",
// 	"115503": "Fall Drieds",
// 	"115506": "Fall Stems & Berries",
// 	"115509": "Fall Picks",
// 	"116001": "Holiday PVC",
// 	"116002": "Holiday Bushes",
// 	"116003": "Holiday Forms Drieds",
// 	"116006": "Holiday Stems",
// 	"116009": "Holiday Picks",
// 	"116107": "Holiday Garlands",
// 	"116114": "Holiday Wreaths",
// 	"116115": "Holiday Arrangements",
// 	"116240": "Holiday Accessories",
// 	"116250": "Holiday Containers",
// 	"116502": "Summer Bushes",
// 	"116506": "Summer Stems",
// 	"116509": "Summer Picks",
// 	"116607": "Summer Garlands",
// 	"116614": "Summer Wreaths",
// 	"116615": "Summer Arrangements",
// 	"116760": "Summer Accessories",
// 	"116770": "Summer Containers",
// 	"117001": "Naturals",
// 	"117002": "Basic PVC",
// 	"117004": "Basic Stems",
// 	"117005": "Greenery Accessories",
// 	"117006": "Basic Wreaths",
// 	"117007": "Basic Garlands",
// 	"117008": "Basic Bushes",
// 	"117009": "Floral Accessories",
// 	"122807": "Fall Garlands",
// 	"122814": "Fall Wreaths",
// 	"122815": "Fall Arrangements",
// 	"122960": "Fall Accessories",
// 	"122980": "Fall Containers",
// 	"123001": "Halloween Finished",
// 	"123002": "Halloween Components",
// 	"126740": "Tools",
// 	"126750": "Filler",
// 	"126760": "Floral Supplies",
// 	"126770": "Wreath Accessories",
// 	"126790": "Containers",
// 	"140220": "Wall Frames",
// 	"140225": "Collage Frames",
// 	"140230": "Poster/Multi Purpose",
// 	"140235": "Gallery Packs",
// 	"140240": "Tabletop Frames",
// 	"140250": "LA Collection",
// 	"140260": "Shadowboxes",
// 	"140280": "Framing Accessories",
// 	"140301": "Tabletop Frames",
// 	"140401": "Shadow Box",
// 	"140501": "Frame Accessories",
// 	"142740": "Cust Frame Access",
// 	"142750": "CF Merchandise",
// 	"142762": "Quick Sale",
// 	"142780": "SVCS:CustomFraming",
// 	"142786": "Svcs:Damaged/Mism",
// 	"142810": "Everyday Art",
// 	"142820": "Mirrors",
// 	"142830": "Seasonal Prints",
// 	"142840": "Licensed Prints",
// 	"144005": "Non-Consign Books",
// 	"144010": "Magazines",
// 	"144015": "Calendars",
// 	"144020": "Consignment Books",
// 	"147510": "Team Pkgd",
// 	"147511": "Team Panels",
// 	"148001": "Blizzard Solids",
// 	"148004": "Blizzard Prints",
// 	"148204": "Blizzard Flc Prints",
// 	"148220": "AP Plush Prints",
// 	"148301": "State Pride Cotton",
// 	"148701": "Pop Culture Cotton",
// 	"148703": "Minnie/Mickey",
// 	"148704": "Juvenile Cotton",
// 	"148705": "StarW/HPotter/DC Ctn",
// 	"148710": "Pop Culture Ctn",
// 	"148711": "Juvenile Ctn",
// 	"148717": "Pixar",
// 	"148718": "Peanuts",
// 	"148719": "Lic Misc BTY",
// 	"148720": "Lic Misc Pnl",
// 	"148808": "MIGI NST",
// 	"148815": "48IN NST",
// 	"148817": "72IN NST",
// 	"148819": "Pre-Cut Kits",
// 	"149001": "Luxe Fleece",
// 	"149002": "Soft and Minky",
// 	"149003": "Pure Plush",
// 	"149004": "Sew Lush",
// 	"149007": "Specialty Fleece",
// 	"149010": "Sherpa",
// 	"149012": "Felt BTY",
// 	"149014": "Fleece EA",
// 	"149016": "Fur",
// 	"149101": "License WARM",
// 	"149102": "Pop Culture Flc/Flnl",
// 	"149103": "Juvenile Fleece",
// 	"149104": "StarW/HPotter/DC Flc",
// 	"149105": "Pop Culture Fleece",
// 	"149203": "NHL BTY",
// 	"149320": "Wool/Fashion Felt",
// 	"149340": "Felt BTY",
// 	"149350": "Fleece EA",
// 	"149360": "Fur",
// 	"149505": "License PKG",
// 	"149603": "NFL BTY",
// 	"149703": "MLB Cotton",
// 	"149803": "College Cotton",
// 	"149903": "NBA Cotton",
// 	"150002": "Silky Solids",
// 	"150007": "Fashion Apparel PNL",
// 	"150018": "Silky Prints",
// 	"150500": "Lic Spring Holdy BTY",
// 	"150510": "Lic Christmas BTY",
// 	"150520": "Lic Christmas Pkgd",
// 	"150525": "Lic Halloween BTY",
// 	"150530": "Halloween Pkgd",
// 	"151002": "Linings",
// 	"152005": "Seasonal Kids",
// 	"152030": "Everyday Kids",
// 	"153003": "Cotton",
// 	"153005": "Linen & Linen Look",
// 	"153010": "SHIRTINGS",
// 	"153501": "Knit Solids",
// 	"153502": "Knit Prints",
// 	"155501": "Athleisure",
// 	"156501": "Denim",
// 	"156509": "Classic Sportswear",
// 	"156520": "Suede/Leather/Cordry",
// 	"157505": "Fashion Apparel Col",
// 	"157520": "Fashion Apparel EA",
// 	"158510": "Specialty",
// 	"160005": "Bridal Collections",
// 	"160010": "Fashion Collections",
// 	"160015": "Sew Sweet",
// 	"160035": "Special Occasion EA",
// 	"160040": "Special Occasion PNL",
// 	"160045": "METALLIC VELVET BROC",
// 	"160105": "Let's Pretend",
// 	"160110": "Halloween",
// 	"160115": "Cosplay",
// 	"160120": "Performing",
// 	"160515": "TULLE BTY",
// 	"160520": "Spclty Net&Tulle BTY",
// 	"160530": "Netting & Tulle EA",
// 	"160705": "Satin",
// 	"160720": "Lace/Crepe/Chiffon",
// 	"160725": "Casa Fabrics",
// 	"163505": "Custom & Luxe",
// 	"164010": "Simplicity",
// 	"164025": "New Look",
// 	"164035": "Dropped Patterns",
// 	"164050": "Burda",
// 	"164510": "McCalls",
// 	"164515": "Kwik Sew",
// 	"165010": "Butterick",
// 	"165510": "Vogue",
// 	"168005": "Non Consign Patterns",
// 	"169010": "Fashion Fusion",
// 	"169505": "Quilt Patterns",
// 	"170002": "Keepsake BTY",
// 	"170003": "Keepsake Precuts",
// 	"170005": "Novelty Prints",
// 	"170006": "Novelty PNL",
// 	"170025": "Wide Prints",
// 	"170030": "Quilter's Showcase",
// 	"170055": "Misc Prints",
// 	"170101": "Novelty BTY",
// 	"170102": "Novelty Panels",
// 	"170103": "Novelty Precuts",
// 	"170201": "Qltrs Shwcse BTY",
// 	"170202": "Qltrs Shwcse Precuts",
// 	"170302": "Double Faced Quilts",
// 	"170315": "Premium Prints",
// 	"170316": "Premium Precuts",
// 	"170365": "Batiks",
// 	"170402": "Fabric Quarters",
// 	"170403": "1/2 YD Precuts",
// 	"170405": "Qult Cotton PrCt/Bnd",
// 	"170515": "Christmas PNL",
// 	"170545": "Christmas Prnt/Woven",
// 	"170550": "Christmas Precuts",
// 	"170601": "Batik BTY",
// 	"170602": "Batik Precuts",
// 	"170701": "Wide Print BTY",
// 	"170702": "Prem Wide Print BTY",
// 	"170703": "Wide Solid BTY",
// 	"170704": "Dble Face Quilt BTY",
// 	"170705": "Qlt Backing Precuts",
// 	"171001": "Halloween Prints BTY",
// 	"171002": "Halloween Precuts",
// 	"171501": "Harvest Prints BTY",
// 	"171502": "Fall Precuts",
// 	"172001": "Valentines Day BTY",
// 	"172002": "St Patricks Day BTY",
// 	"172003": "Easter BTY",
// 	"172004": "Spring Seas Precuts",
// 	"172501": "St Pat's Prints BTY",
// 	"173001": "Easter Prints BTY",
// 	"174501": "Patriotic Prints BTY",
// 	"174502": "Patriotic Precuts",
// 	"175003": "Nursery Cotton",
// 	"175004": "Nursery Panel",
// 	"175005": "Nursery Each",
// 	"175006": "Nursery Fleece Flnl",
// 	"175008": "Nursery Misc",
// 	"175009": "Terry",
// 	"176001": "Cotton Canvas",
// 	"176002": "Home Dec Fabric EA",
// 	"176010": "Duck Cotton Canvas",
// 	"177015": "Sew Classics BTY",
// 	"177030": "Wide Solids",
// 	"177065": "Specialty Solids BTY",
// 	"177075": "Kona BTY",
// 	"177080": "Symphony Brdclth BTY",
// 	"177090": "Solid Precuts",
// 	"177505": "Comfy Cozy",
// 	"177508": "Packaged Flannel",
// 	"177546": "Super Snuggle",
// 	"177605": "Terry",
// 	"177607": "Wide Flannel",
// 	"177609": "Warm Eaches",
// 	"178301": "Muslin BTY",
// 	"178317": "Muslin Precuts",
// 	"178825": "Interfacing BTY",
// 	"178828": "Interfacings EA",
// 	"179010": "Pillow Forms",
// 	"179210": "Stuffing",
// 	"179410": "Packaged Batting",
// 	"179420": "Batting BTY",
// 	"179421": "Bulk Batting Ecomm",
// 	"179610": "Packaged Foam",
// 	"179620": "Foam BTY",
// 	"179625": "EVA Foam",
// 	"180025": "Scissors",
// 	"180064": "Rotary Cutters",
// 	"180085": "Mats & Rulers",
// 	"180090": "Fashion Cutting",
// 	"182525": "Furniture&Dress Form",
// 	"183002": "Coats Clark Thread",
// 	"183020": "Guterman Thread",
// 	"183060": "Sulky/Ecomm",
// 	"187035": "Zippers",
// 	"187504": "Paper Craft Storage",
// 	"187507": "Crafting Organizers",
// 	"187510": "Super Satchels",
// 	"187513": "Rolling Totes",
// 	"187516": "Sewing Baskets",
// 	"187519": "Sewing Organizers",
// 	"188002": "Plastic Storage",
// 	"188003": "Plastic Totes&Contrs",
// 	"188015": "Holiday Storage",
// 	"188510": "Rolling Totes",
// 	"188520": "Sewing Baskets",
// 	"188530": "Sewing Organizers",
// 	"189005": "Soft Storage",
// 	"189015": "Decorative Boxes",
// 	"189025": "Baskets",
// 	"189535": "Table Lighting",
// 	"189540": "Other Lighting",
// 	"189545": "Magnified Lghtng&Acc",
// 	"189550": "Floor Lighting",
// 	"193025": "Tapes & Braids",
// 	"194520": "White Styrofoam",
// 	"194525": "Floral Foam",
// 	"195101": "Tees",
// 	"195104": "Long-Sleeve Tees",
// 	"195105": "Sweatshirts-LS Tees",
// 	"195106": "Other Apparel",
// 	"195201": "Paint",
// 	"195202": "Dyes and Kits",
// 	"195203": "Tools",
// 	"195204": "Markers",
// 	"196001": "Appliques",
// 	"196002": "Embellishments",
// 	"196003": "Wearable Accessories",
// 	"196004": "Canvas and Bases",
// 	"196006": "Letters and Numbers",
// 	"196007": "Textile Components",
// 	"196027": "SeasonalCraftTextile",
// 	"196540": "Fall/Halloween Craft",
// 	"196550": "Holiday Craft",
// 	"196560": "Spring Craft",
// 	"196570": "Summer Craft",
// 	"197030": "Kids Val/St. Pats",
// 	"197031": "Kids Halloween",
// 	"197032": "Kids Christmas",
// 	"197033": "Kids Summer",
// 	"197034": "Kids Easter",
// 	"197110": "Kids Kits",
// 	"197112": "Puzzles",
// 	"197140": "Perler Beads",
// 	"197170": "Surfaces",
// 	"197171": "Art Activities",
// 	"197172": "Diamond Dots & Acces",
// 	"197173": "Crayola",
// 	"197174": "Doll & Model Making",
// 	"197213": "POP Art Supplies",
// 	"197225": "Kids Craft Foam",
// 	"197226": "Pony Beads & Lacing",
// 	"197228": "Feathers/Boas/Fur",
// 	"197229": "Glitter/Sequin/Gems",
// 	"197230": "Stems/Poms/Eyes",
// 	"197231": "Felt",
// 	"197312": "Kids Impulse & Games",
// 	"197313": "Plush",
// 	"197316": "Lego",
// 	"197317": "Games",
// 	"207530": "Craft Ribbon",
// 	"207532": "Ribbon BTY",
// 	"207533": "Ribbon BTS",
// 	"207534": "Ribbon Fancies",
// 	"207544": "Decorative Ribbon",
// 	"207545": "Save the Date",
// 	"207546": "Satin",
// 	"207547": "Grosgrain",
// 	"207548": "Novelty",
// 	"207549": "Specialty",
// 	"207550": "Basic Ribbon OOL",
// 	"207552": "Value Organdy",
// 	"207553": "Concept Shop Ribbon",
// 	"211510": "Basic Buttons",
// 	"211545": "Fashion Buttons",
// 	"213003": "Patches",
// 	"213004": "Stablz/Intfc/Adhesiv",
// 	"213015": "Fasteners & Belting",
// 	"213016": "Velcro EA",
// 	"213017": "Velcro BTY",
// 	"213018": "Boning & Belting BTY",
// 	"213020": "Undergarment Care",
// 	"213021": "Elastic BTY",
// 	"213022": "Elastic EA",
// 	"213030": "Machine Accessories",
// 	"213031": "Pins & Needles",
// 	"213041": "Bulk Notions",
// 	"213042": "Sewing Tools",
// 	"213043": "Other Notions",
// 	"213044": "Clothing Care",
// 	"219550": "Quilting Tools",
// 	"219556": "Quilting Rulers",
// 	"220014": "Singer / Viking",
// 	"220020": "Brother Machines",
// 	"220021": "OtherSewingMachBrand",
// 	"220025": "Sewing Machine Acc",
// 	"220101": "Sewing Machine Acc",
// 	"220102": "Iron & Steamers",
// 	"220103": "Other Sewing Tech",
// 	"220201": "DITTO MACHINES",
// 	"220202": "DITTO ACCESSORIES",
// 	"225005": "Sew Tech Warranties",
// 	"225010": "Craft Tech Warrantie",
// 	"300006": "Letters/Numbers",
// 	"300007": "Unfinished Wood",
// 	"300008": "Crates/Cubes",
// 	"300012": "Non Wood Surfaces",
// 	"300018": "Turnings/Dowels",
// 	"300050": "Tools",
// 	"300111": "Trend Surfaces",
// 	"300112": "Non Wood Surfaces",
// 	"300501": "Craft Paint",
// 	"300502": "Brushes & Stencils",
// 	"300503": "Home Decor/Chalk",
// 	"300505": "Stencils",
// 	"300510": "Spray Paint",
// 	"300520": "Paint Markers",
// 	"302011": "Value Canvas",
// 	"302012": "Artist Canvas",
// 	"302013": "Paper/Pads",
// 	"302014": "Easles/Storage",
// 	"302111": "Fine Art Drawing",
// 	"302112": "Fine Art Markers",
// 	"302211": "Fine Art Paint",
// 	"302212": "Fine Art Brushes",
// 	"303001": "Gifting Accessories",
// 	"303002": "Hydration",
// 	"303020": "Reusable Totes",
// 	"303025": "Impulse/Trend",
// 	"303028": "Tech",
// 	"303030": "Licensed Hardgoods",
// 	"303050": "Beauty",
// 	"303055": "Soap/Lotion/Antibac",
// 	"303060": "Throws",
// 	"310001": "Paper Stacks",
// 	"310003": "Value Pack Paper",
// 	"310009": "Print and Cardstock",
// 	"310010": "Specialty Paper",
// 	"310101": "Happy Planner Access",
// 	"310102": "HP Planners",
// 	"310103": "Other Journal",
// 	"310302": "Card Crafting",
// 	"310303": "Tools & Adhesives",
// 	"310304": "Photo Albums & Boxes",
// 	"310305": "Writing",
// 	"310402": "Value",
// 	"310406": "Embellishments",
// 	"310407": "Stickers",
// 	"310409": "Writing",
// 	"310410": "Adhesives",
// 	"310501": "Albums",
// 	"310503": "Boxes",
// 	"310604": "Other Designers",
// 	"310605": "Tim Holtz",
// 	"310606": "Heidi Swapp",
// 	"310801": "Punches & Cutting",
// 	"310901": "Season Papercrafting",
// 	"311020": "Glue Guns & Sticks",
// 	"311025": "Glue Gun Sticks",
// 	"311030": "Industrial Glue",
// 	"311045": "Crafting Glue",
// 	"311050": "Tape & Supplies",
// 	"311101": "Cricut Cutting",
// 	"311102": "Cricut Heat Press",
// 	"311103": "Other Cricut Tech",
// 	"311201": "Cricut Iron On",
// 	"311202": "CricutVinyl&TrnsTape",
// 	"311203": "Cricut SmartMaterial",
// 	"311204": "OthrCricutCutMateral",
// 	"311301": "Cricut Blanks",
// 	"311302": "Cricut Tools &Blades",
// 	"311303": "Cricut Mats",
// 	"311304": "Other Cricut Supplie",
// 	"311525": "LthrMacameAudltCraft",
// 	"311540": "Magnets/Tools/Other",
// 	"311570": "Construction Other",
// 	"311580": "Hobby",
// 	"311650": "Crafting Tape",
// 	"311655": "Mailing & Pkg Supply",
// 	"315401": "Strung Beads",
// 	"315402": "Packaged Beads",
// 	"315601": "Findings",
// 	"315603": "Wire-Bases",
// 	"315604": "Tools",
// 	"315605": "Storage",
// 	"315606": "Resin",
// 	"315805": "Finished Jewelry",
// 	"315807": "Pendants & Charms",
// 	"315809": "Hair & Accessories",
// 	"315901": "Spring/Summr Jewelry",
// 	"315902": "Halloween Jewelry",
// 	"315903": "Holiday Jewelry",
// 	"317001": "Spring/Summer Food",
// 	"317003": "Fall/Halloween Food",
// 	"317004": "Holiday Food",
// 	"317201": "Icing Fondnt&Ingredt",
// 	"317202": "Meltable CandyWafers",
// 	"317203": "Decorations",
// 	"317601": "Foodcraft Supplies",
// 	"317602": "Tools",
// 	"317603": "Molds",
// 	"317604": "Bakeware",
// 	"321001": "Siser Vinyl&Supplies",
// 	"321002": "Siser Machines",
// 	"321101": "Silhouette",
// 	"321102": "Oracal",
// 	"321103": "Other Craft Technolo",
// 	"321104": "Hobby Machines",
// 	"323001": "Clay Bricks",
// 	"323004": "Mediums/Tools",
// 	"323005": "Resin",
// 	"325005": "Embos & Stamp Access",
// 	"325006": "Open Stock Stamps",
// 	"325007": "Clear,Cling & Die",
// 	"325094": "Stamp Pads",
// 	"326520": "Spin Coats All Other",
// 	"326525": "Spin Simply Soft",
// 	"326530": "Lion Classic",
// 	"326531": "RH Classic",
// 	"326533": "LB Basic Stitch",
// 	"326534": "LB All Other",
// 	"326535": "PL All Other",
// 	"326536": "Premium Brands",
// 	"326601": "Spin One Pound",
// 	"326602": "Red Heart Value",
// 	"326603": "Value Private Label",
// 	"326604": "Value Other",
// 	"326605": "Coats SuperSaver Jmb",
// 	"326606": "PL Pound Plus",
// 	"326710": "Spin Blanket",
// 	"326711": "LB Blanket",
// 	"326712": "PL Blanket",
// 	"326810": "Spin Baby Blanket",
// 	"326811": "LB Baby",
// 	"326812": "Spin Coats Baby",
// 	"326813": "PL Baby",
// 	"326910": "Spin Coats Loop",
// 	"326911": "LB Loop",
// 	"327020": "Natural Fiber",
// 	"327021": "Spin Coats Natural",
// 	"327022": "Sock Yarn",
// 	"327023": "Specialty Other",
// 	"327110": "Spin Sugar N Cream",
// 	"327111": "Spin Craft Utility",
// 	"327115": "Macrame/Utility Yarn",
// 	"327230": "Needle and Hooks",
// 	"327232": "Accessories",
// 	"329020": "Stitch Kits",
// 	"329035": "Floss",
// 	"329040": "Floss Other",
// 	"329041": "Stitch Notions & Acc",
// 	"340515": "Advertising",
// 	"340525": "Donations",
// 	"340530": "Fixture Sales",
// 	"340535": "HOF-EA",
// 	"340545": "Services",
// 	"340550": "Ship/Handling Fee",
// 	"340555": "SpecialOrd Deposit",
// 	"340560": "Supplies-Merchandise",
// 	"340565": "Supplies-Non Merch",
// 	"340570": "Training",
// 	"340575": "Project Sheets",
// 	"340580": "EP and BW Purge Art",
// 	"341005": "Gift Cards",
// 	"341010": "GC Mall- Closed Loop",
// 	"341015": "GC Mall - Open Loop",
// 	"341020": "Virtual Gift Card",
// 	"341505": "Classroom Supply",
// 	"341506": "General Craft",
// 	"341508": "Jewelry",
// 	"341509": "Kids Foodcrafting",
// 	"341512": "Parties",
// 	"341513": "Quilting",
// 	"341514": "Sewing",
// 	"341515": "Events",
// 	"341516": "Foodcrafting",
// 	"341517": "Yarn",
// 	"341518": "Kids Craft",
// 	"341519": "Kids Sew-Quilt",
// 	"341520": "Kids Yarn",
// 	"341523": "Socials",
// 	"341610": "Custom Shop",
// 	"342020": "Rentals",
// 	"391525": "Spring/Summer Impuls",
// 	"391530": "Fall/Halloween Impls",
// 	"391540": "Holiday Impulse",
// 	"391550": "Apparel &Accessories",
// 	"392507": "Val DTcor",
// 	"392510": "Val Outdoor",
// 	"392515": "Val Entertaining",
// 	"393007": "St Pats DTcor",
// 	"393010": "St Pats Outdoor",
// 	"393015": "St Pats Entertaining",
// 	"393507": "Easter DTcor",
// 	"393510": "Easter Outdoor",
// 	"393512": "Easter Basket Décor",
// 	"393515": "Easter Entertaining",
// 	"394006": "Halloween Tbltop",
// 	"394007": "Halloween Wall Decor",
// 	"394045": "Halloween Textiles",
// 	"394050": "Halloween Pumpkins",
// 	"394055": "Halloween Pmpk Crvbl",
// 	"394057": "Halloween Lighting",
// 	"394060": "Halloween Trend",
// 	"394110": "Halloween Mugs",
// 	"394125": "Halloween Serveware",
// 	"394145": "Halloween Towels",
// 	"394207": "Hallown Outdr Dcor",
// 	"394211": "Hallwn Outdr Electrc",
// 	"394224": "Halloween Bones",
// 	"394265": "Hallown Mats & Flags",
// 	"394506": "Autumn Tbltop",
// 	"394507": "Autumn Wall Decor",
// 	"394545": "Autumn Textiles",
// 	"394555": "Autumn Pumpkins",
// 	"394610": "Autumn Mugs",
// 	"394625": "Autumn Serveware",
// 	"394645": "Autumn Towels",
// 	"394707": "Autumn Outdoor Decor",
// 	"394765": "Autumn Mats & Flags",
// 	"395515": "Holiday Tabletop",
// 	"395520": "Xmas Textiles",
// 	"395540": "Xmas Wall DTcor",
// 	"395545": "Xmas Décor Other",
// 	"395550": "Holiday Dec Storage",
// 	"396005": "Christmas Ribbon",
// 	"396006": "Spring&Summer Ribbon",
// 	"396007": "Fall&Hallow Ribbon",
// 	"396010": "Spring Ribbon",
// 	"396011": "Valentine's Ribbon",
// 	"396012": "Easter Ribbon",
// 	"396013": "St Pat Ribbon",
// 	"396015": "Fall Ribbon",
// 	"396016": "Halloween Ribbon",
// 	"396030": "Summer Ribbon",
// 	"396520": "Holiday Out Decor",
// 	"396530": "Holiday Mats & Flags",
// 	"398005": "Holiday StockngSkrts",
// 	"398010": "Xmas Toppers Garland",
// 	"398020": "Xmas Open Stock Orn",
// 	"398030": "Holiday Boxed Orn",
// 	"398035": "Holiday Trim Lightng",
// 	"398040": "Holiday Trees",
// 	"399005": "Xmas Giftbags",
// 	"399015": "Xmas Giftwrap",
// 	"399025": "Xmas Wrap Accessorie",
// 	"399515": "Xmas Tins",
// 	"399520": "Holiday Mugs",
// 	"399525": "Holiday Serveware",
// 	"399545": "Holiday Towels",
// 	"401545": "Spring Textiles",
// 	"401580": "Spring Wall Decor",
// 	"401585": "Spring Tabletop Dec",
// 	"401590": "Spring Trend",
// 	"401610": "Spring Mugs",
// 	"401625": "Spring Serveware",
// 	"401645": "Spring Towels",
// 	"401710": "Spring Equipment",
// 	"401715": "Spring Stakes&Trelis",
// 	"401720": "Spring Statuary",
// 	"401760": "Spring Coco Liners",
// 	"401765": "Spring Mats & Flags",
// 	"401780": "SpringPlanters&Stand",
// 	"403005": "Summer Tabletop",
// 	"403010": "Summer Wall Decor",
// 	"403020": "Summer Textiles",
// 	"403035": "Summer Trend",
// 	"403505": "Valentine Candy",
// 	"403515": "Halloween/Fall Candy",
// 	"403525": "Holiday Candy",
// 	"404020": "Spring/Summer Socks",
// 	"404030": "Fall/Halloween Socks",
// 	"404035": "Holiday Socks",
// 	"404510": "Beverage - Non Carb",
// 	"405500": "Beverage",
// 	"405505": "Fruit Chewy",
// 	"405525": "Chocolate",
// 	"405530": "Salty",
// 	"405535": "Gum, Mint & Misc",
// 	"407005": "Summer Mugs",
// 	"407020": "Summer Towels",
// 	"407025": "Summer Serveware",
// 	"408050": "Summer American Flag",
// 	"408065": "Summer Parade & Dec",
// 	"409005": "Summer Out Décor",
// 	"409010": "Summer Mats & Flags",
// 	"409020": "Summer Out Textiles",
// 	"409025": "Summer Out Lighting",
// 	"410205": "Decor",
// 	"410240": "Throws",
// 	"410245": "Textiles",
// 	"411905": "Jars",
// 	"411910": "TLight/Taper/Votive",
// 	"411915": "Pillars",
// 	"411920": "LED Candles",
// 	"411925": "Accessories",
// 	"411930": "Home Fragrance",
// 	"411935": "Spring Candles",
// 	"411940": "Halloween Candles",
// 	"411950": "Holiday Candles",
// 	"412005": "Spring/Summer Candle",
// 	"412010": "Fall/Halloween Candl",
// 	"412015": "Holiday Candles",
// 	"430505": "Div/Catg SKU",
// 	"431010": "Misc/Not Assigned",
// 	"432510": "Misc/Not Assigned",
// 	"433010": "Misc/Not Assigned",
// 	"433510": "Misc/ Not Assigned",
// 	"434810": "Misc/ Not Assigned",
// 	"441001": "BOSS ea",
// 	"441002": "BOSS yd",
// 	"441003": "BOSS pnl",
// 	"442001": "CREATIVE BUG EA",
// 	"460010": "Fabany_Customizer EA",
// 	"460020": "Fabany_Customizer YD"
// }

// const CATEGORY_ID_TO_DESC_MAPPING = {
// 	"1002":	"ROT POG",
// 	"1005":	"Vinyl/Utility",
// 	"1005":	"Vinyl/Utility Fabric",
// 	"1036":	"Custom Cut",
// 	"1036":	"Home Dec Fabric EA",
// 	"1062":	"HD Trims",
// 	"1066":	"Apparel Trims",
// 	"1072":	"Home Decor Hardware",
// 	"1092":	"Indoor/Outdoor Fabric",
// 	"1092":	"Outdoor",
// 	"1112":	"Value Flat Fold",
// 	"1147":	"Bath/Body Care",
// 	"1147":	"Bridal",
// 	"1150":	"Spring Floral Components",
// 	"1151":	"Spring Floral Finished",
// 	"1152":	"Spring Floral Accessories",
// 	"1155":	"Fall Floral Components",
// 	"1160":	"Holiday Floral Components",
// 	"1161":	"Holiday Floral Finished",
// 	"1162":	"Holiday Floral Accessories",
// 	"1165":	"Summer Floral Components",
// 	"1166":	"Summer Floral Finished",
// 	"1167":	"Summer Floral Accessories",
// 	"1170":	"Basic Floral Components",
// 	"1228":	"Fall Floral Finished",
// 	"1229":	"Fall Floral Accessories",
// 	"1230":	"Halloween Floral",
// 	"1267":	"ArrangingSupplie&Acc",
// 	"1267":	"Arranging Supplies",
// 	"1402":	"Frames",
// 	"1402":	"Wall Frames",
// 	"1403":	"Tabletop Frames",
// 	"1404":	"Shadow Box",
// 	"1405":	"Frame Accessories",
// 	"1427":	"Custom Framing",
// 	"1428":	"Custom Framing Merchandise",
// 	"1440":	"Non-Consign Books",
// 	"1440":	"Publications",
// 	"1475":	"Misc Team Pkg",
// 	"1475":	"Team Pkgd",
// 	"1480":	"Blizzard Fleece",
// 	"1482":	"AP Plush Fleece",
// 	"1482":	"Fleece Prints",
// 	"1483":	"State Pride",
// 	"1487":	"Cotton",
// 	"1487":	"Juvenile Character",
// 	"1488":	"Fleece Packaged",
// 	"1488":	"No Sew Throws",
// 	"1490":	"Fashion Fleece",
// 	"1490":	"Plush",
// 	"1490":	"Specialty Fleece",
// 	"1491":	"Disney Character",
// 	"1491":	"Fleece",
// 	"1492":	"NHL",
// 	"1493":	"Felt",
// 	"1493":	"Specialty Fleece",
// 	"1495":	"License PKG",
// 	"1496":	"NFL",
// 	"1497":	"MLB",
// 	"1498":	"College",
// 	"1499":	"NBA",
// 	"1500":	"Silkies",
// 	"1505":	"Seasonal",
// 	"1510":	"Linings",
// 	"1520":	"Juvenile Apparel",
// 	"1530":	"Specialty Cotton & Linens",
// 	"1535":	"Knits",
// 	"1555":	"Athletic Wear",
// 	"1565":	"Sportswear",
// 	"1575":	"Fashion Apparel Collections",
// 	"1585":	"Specialty",
// 	"1600":	"Special Occasion Collections",
// 	"1601":	"Costuming",
// 	"1605":	"Nettings",
// 	"1605":	"Nettings & Tulle",
// 	"1607":	"Casa Collection",
// 	"1635":	"Custom",
// 	"1640":	"Simplicity Patterns",
// 	"1645":	"McCalls Patterns",
// 	"1650":	"Butterick Patterns",
// 	"1655":	"Vogue Patterns",
// 	"1680":	"Non Consignment Patterns",
// 	"1690":	"Fashion Fusion",
// 	"1695":	"Quilt Patterns",
// 	"1700":	"Cotton Prints",
// 	"1700":	"Keepsake Cotton",
// 	"1701":	"Novelty Cotton",
// 	"1702":	"Qltrs Shwcse Cotton",
// 	"1703":	"Cotton Premium Prints",
// 	"1703":	"Premium Cotton",
// 	"1704":	"Cotton Packaged",
// 	"1705":	"Christmas",
// 	"1705":	"Christmas Cotton",
// 	"1706":	"Batiks",
// 	"1707":	"Quilt Backing",
// 	"1710":	"Halloween",
// 	"1710":	"Halloween Cotton",
// 	"1715":	"Fall Cotton",
// 	"1715":	"Harvest",
// 	"1720":	"Spring Seas Cotton",
// 	"1720":	"Valentines",
// 	"1725":	"St Patricks",
// 	"1730":	"Easter",
// 	"1745":	"Patriotic",
// 	"1745":	"Patriotic Cotton",
// 	"1750":	"Nursery",
// 	"1760":	"Cotton Canvas",
// 	"1770":	"Cotton Solids",
// 	"1775":	"Flannel",
// 	"1776":	"Fashion Flannel",
// 	"1776":	"Other Flannel",
// 	"1776":	"Warm Fashion",
// 	"1783":	"Muslin",
// 	"1783":	"Muslin/Burlap",
// 	"1788":	"Interfacings",
// 	"1790":	"Pillow Forms",
// 	"1792":	"Stuffing",
// 	"1794":	"Batting",
// 	"1796":	"Foam",
// 	"1800":	"Cutting Implements",
// 	"1825":	"Sewing Room Basics",
// 	"1830":	"Thread",
// 	"1870":	"Zippers",
// 	"1875":	"Craft Storage",
// 	"1880":	"Basic Storage",
// 	"1880":	"General Storage",
// 	"1885":	"Decorative Storage",
// 	"1885":	"Sewing Storage",
// 	"1890":	"Home Storage",
// 	"1890":	"Soft Storage",
// 	"1895":	"Lighting",
// 	"1930":	"Tapes & Braids",
// 	"1945":	"Styrofoam",
// 	"1951":	"Apparel",
// 	"1951":	"Bases",
// 	"1952":	"Fabric Paint",
// 	"1952":	"Textile Colorants",
// 	"1960":	"Components & Bases",
// 	"1960":	"Textile Components",
// 	"1965":	"Seasonal Craft",
// 	"1970":	"Kids Seasonal",
// 	"1971":	"Craft Activities",
// 	"1972":	"Kids Art Supplies",
// 	"1972":	"Kids POP",
// 	"1973":	"Toy / Impulse",
// 	"2075":	"Basic Core Ribbon",
// 	"2115":	"Buttons",
// 	"2130":	"Sewing Notions",
// 	"2195":	"Quilting Notions",
// 	"2200":	"Sewing Machines",
// 	"2201":	"Sewing Machine Acc",
// 	"2201":	"Sewing Supplies",
// 	"2202":	"DITTO",
// 	"2250":	"Warranties",
// 	"3000":	"Surfaces",
// 	"3000":	"Wood Crafting",
// 	"3001":	"Other Surfaces",
// 	"3005":	"Paint & Finishes",
// 	"3020":	"Fine Art Surfaces",
// 	"3021":	"Fine Art Illustration",
// 	"3022":	"Fine Art Painting",
// 	"3030":	"Everyday",
// 	"3030":	"Impulse",
// 	"3100":	"Paper",
// 	"3101":	"Journaling",
// 	"3101":	"Planners & Journals",
// 	"3103":	"Stationery & Party",
// 	"3103":	"Stationery & Tools",
// 	"3104":	"Embellishments",
// 	"3105":	"Papercrafting Storage",
// 	"3106":	"Papercrafting Designers",
// 	"3108":	"Papercrafting Tools",
// 	"3108":	"Tools",
// 	"3109":	"Seasonal Paper Crafting",
// 	"3110":	"Glue & Adhesives",
// 	"3110":	"Glue & Tape",
// 	"3111":	"Cricut Machines",
// 	"3112":	"Cricut Cutting Material",
// 	"3113":	"Cricut Accessories",
// 	"3115":	"Construction",
// 	"3115":	"Hobby",
// 	"3116":	"Tape & Mailing",
// 	"3154":	"Beads",
// 	"3156":	"Jewelry Making Supplies",
// 	"3158":	"Finished Jewelry",
// 	"3158":	"Jewelry Components",
// 	"3159":	"Seasonal Jewelry",
// 	"3170":	"Seasonal",
// 	"3170":	"Seasonal Food",
// 	"3172":	"Food",
// 	"3176":	"Bakeware Tools & Supplies",
// 	"3176":	"Bakeware, Tools & Supplies",
// 	"3210":	"Siser",
// 	"3211":	"Other Tech",
// 	"3230":	"Clay",
// 	"3230":	"Clay Sculpt & Resin",
// 	"3230":	"Clay, Sculpt & Resin",
// 	"3250":	"Stamping",
// 	"3265":	"Classic Yarn",
// 	"3266":	"Value Yarn",
// 	"3267":	"Blanket Yarn",
// 	"3268":	"Baby Yarn",
// 	"3269":	"Loop Yarn",
// 	"3270":	"Natural Yarn",
// 	"3270":	"Specialty Yarn",
// 	"3271":	"Craft Cotton Yarn",
// 	"3271":	"Craft / Utility Yarn",
// 	"3271":	"Crochet Yarn",
// 	"3272":	"Yarn Tools",
// 	"3290":	"Stitch & Accessories",
// 	"3290":	"Stitch Kits",
// 	"3405":	"Other",
// 	"3410":	"Gift Cards",
// 	"3415":	"Education",
// 	"3416":	"Custom Shop",
// 	"3420":	"Services",
// 	"3915":	"Seasonal Impulse",
// 	"3925":	"Valentine's Day",
// 	"3930":	"St. Patrick's Day",
// 	"3935":	"Easter/Spring",
// 	"3940":	"Halloween Decor",
// 	"3940":	"Halloween Indoor",
// 	"3941":	"Halloween Entertaining",
// 	"3942":	"Halloween Outdoor",
// 	"3945":	"Autumn Decor",
// 	"3945":	"Autumn Indoor",
// 	"3946":	"Autumn Entertaining",
// 	"3947":	"Autumn Outdoor",
// 	"3955":	"Christmas Decor",
// 	"3955":	"Holiday Decor",
// 	"3955":	"Holiday Indoor",
// 	"3960":	"Seasonal Ribbon",
// 	"3965":	"Holiday Outdoor",
// 	"3980":	"Christmas Trim-A-Tree",
// 	"3980":	"Holiday Tree Decor",
// 	"3990":	"Christmas Giftwrap",
// 	"3990":	"Holiday Giftwrap",
// 	"3995":	"Christmas Entertaining",
// 	"3995":	"Holiday Entertaining",
// 	"4015":	"Spring Decor",
// 	"4015":	"Spring Indoor",
// 	"4016":	"Spring Entertaining",
// 	"4017":	"Spring Outdoor",
// 	"4030":	"Summer Decor",
// 	"4030":	"Summer Indoor",
// 	"4035":	"Seasonal Candy",
// 	"4040":	"Seasonal Socks",
// 	"4045":	"Beverage",
// 	"4055":	"Food & Beverage",
// 	"4055":	"Snacks & Candy",
// 	"4070":	"Summer Entertaining",
// 	"4080":	"Americana",
// 	"4090":	"Summer Outdoor",
// 	"4102":	"Decor",
// 	"4102":	"Home Decor",
// 	"4119":	"Candles",
// 	"4119":	"Unscented",
// 	"4120":	"Accessories",
// 	"4120":	"Seasonal Candles",
// 	"4305":	"Models - Misc",
// 	"4310":	"Fabric - Misc",
// 	"4325":	"Craft - Misc",
// 	"4330":	"Services/Misc",
// 	"4335":	"Seasonal / Floral - Misc",
// 	"4348":	"Home / Everyday - Misc",
// 	"4410":	"Boss",
// 	"4420":	"Creative Bug",
// 	"4600":	"Fabany"
// }

const { ATTRIBUTE_FABRIC_OPTIONS } = require("./additionalMappings"); 

const MERCH_CAT_OPTIONS = [
	{
			"value": "340570",
			"label": "340570 - Training"
	},
	{
			"value": "187035",
			"label": "187035 - Zippers"
	},
	{
			"value": "160515",
			"label": "160515 - Netting & Tulle BTY"
	},
	{
			"value": "149340",
			"label": "149340 - Felt BTY"
	},
	{
			"value": "178825",
			"label": "178825 - Interfacing BTY"
	},
	{
			"value": "156509",
			"label": "156509 - Classic Sportswear"
	},
	{
			"value": "178320",
			"label": "178320 - Burlap"
	},
	{
			"value": "177080",
			"label": "177080 - Symph Broadcloth"
	},
	{
			"value": "183020",
			"label": "183020 - Guterman Thread"
	},
	{
			"value": "170055",
			"label": "170055 - Misc Prints"
	},
	{
			"value": "178301",
			"label": "178301 - Muslin BTY"
	},
	{
			"value": "100520",
			"label": "100520 - Utility"
	},
	{
			"value": "180085",
			"label": "180085 - Mats & Rulers"
	},
	{
			"value": "153501",
			"label": "153501 - Knit Solids"
	},
	{
			"value": "151002",
			"label": "151002 - Linings"
	},
	{
			"value": "213022",
			"label": "213022 - Elastic EA"
	},
	{
			"value": "219556",
			"label": "219556 - Quilting Rulers"
	},
	{
			"value": "106215",
			"label": "106215 - HD Trims BTY"
	},
	{
			"value": "106210",
			"label": "106210 - HD Trims EA"
	},
	{
			"value": "107222",
			"label": "107222 - Notions BTY"
	},
	{
			"value": "213018",
			"label": "213018 - Boning & Belting BTY"
	},
	{
			"value": "219550",
			"label": "219550 - Quilting Tools"
	},
	{
			"value": "107225",
			"label": "107225 - Drapery Notions Rods"
	},
	{
			"value": "170002",
			"label": "170002 - Keepsake"
	},
	{
			"value": "179010",
			"label": "179010 - Pillow Forms"
	},
	{
			"value": "179210",
			"label": "179210 - Stuffing"
	},
	{
			"value": "179421",
			"label": "179421 - Bulk Batting Ecomm"
	},
	{
			"value": "179410",
			"label": "179410 - Packaged Batting"
	},
	{
			"value": "179610",
			"label": "179610 - Packaged Foam"
	},
	{
			"value": "213004",
			"label": "213004 - Stablz/Intfc/Adhesiv"
	},
	{
			"value": "144010",
			"label": "144010 - Magazines"
	},
	{
			"value": "189015",
			"label": "189015 - Decorative Boxes"
	},
	{
			"value": "310501",
			"label": "310501 - Albums"
	},
	{
			"value": "310801",
			"label": "310801 - Punches & Cutting"
	},
	{
			"value": "310406",
			"label": "310406 - Embellishments"
	},
	{
			"value": "193025",
			"label": "193025 - Tapes & Braids"
	},
	{
			"value": "329035",
			"label": "329035 - DMC 6 Strand Floss"
	},
	{
			"value": "213015",
			"label": "213015 - Fasteners & Belting"
	},
	{
			"value": "213030",
			"label": "213030 - Machine Accessories"
	},
	{
			"value": "197231",
			"label": "197231 - Felt"
	},
	{
			"value": "197230",
			"label": "197230 - Stems/Poms/Eyes"
	},
	{
			"value": "213044",
			"label": "213044 - Clothing Care"
	},
	{
			"value": "213020",
			"label": "213020 - Undergarment Care"
	},
	{
			"value": "213042",
			"label": "213042 - Sewing Tools"
	},
	{
			"value": "213031",
			"label": "213031 - Pins & Needles"
	},
	{
			"value": "213003",
			"label": "213003 - Patches"
	},
	{
			"value": "329041",
			"label": "329041 - Stitch Bases&Access"
	},
	{
			"value": "183002",
			"label": "183002 - Coats Clark Thread"
	},
	{
			"value": "213016",
			"label": "213016 - Velcro EA"
	},
	{
			"value": "325007",
			"label": "325007 - Clear,Cling & Die"
	},
	{
			"value": "310409",
			"label": "310409 - Writing"
	},
	{
			"value": "310410",
			"label": "310410 - Adhesives"
	},
	{
			"value": "310003",
			"label": "310003 - Value Pack Paper"
	},
	{
			"value": "310001",
			"label": "310001 - Paper Stacks"
	},
	{
			"value": "310407",
			"label": "310407 - Stickers"
	},
	{
			"value": "325006",
			"label": "325006 - Open Stock Stamps"
	},
	{
			"value": "325094",
			"label": "325094 - Stamp Pads"
	},
	{
			"value": "325005",
			"label": "325005 - Embos & Stamp Access"
	},
	{
			"value": "303001",
			"label": "303001 - Bags & Tissues"
	},
	{
			"value": "405525",
			"label": "405525 - Chocolate"
	},
	{
			"value": "405505",
			"label": "405505 - Fruit Chewy"
	},
	{
			"value": "183060",
			"label": "183060 - Sulky/Ecomm"
	},
	{
			"value": "100508",
			"label": "100508 - Clear Vinyl"
	},
	{
			"value": "211510",
			"label": "211510 - Basic Buttons"
	},
	{
			"value": "189545",
			"label": "189545 - Magnified Lghtng&Acc"
	},
	{
			"value": "311045",
			"label": "311045 - Crafting Glue"
	},
	{
			"value": "311020",
			"label": "311020 - Glue Guns"
	},
	{
			"value": "311025",
			"label": "311025 - Glue Gun Sticks"
	},
	{
			"value": "315604",
			"label": "315604 - Tools"
	},
	{
			"value": "196007",
			"label": "196007 - Textile Components"
	},
	{
			"value": "311030",
			"label": "311030 - Industrial Glue"
	},
	{
			"value": "197228",
			"label": "197228 - Feathers/Boas/Fur"
	},
	{
			"value": "311540",
			"label": "311540 - Magnets/Tools/Other"
	},
	{
			"value": "315603",
			"label": "315603 - Wire-Bases"
	},
	{
			"value": "317202",
			"label": "317202 - Meltable CandyWafers"
	},
	{
			"value": "317001",
			"label": "317001 - Spring/Summer Food"
	},
	{
			"value": "317603",
			"label": "317603 - Molds"
	},
	{
			"value": "317604",
			"label": "317604 - Bakeware"
	},
	{
			"value": "317203",
			"label": "317203 - Decorations"
	},
	{
			"value": "317601",
			"label": "317601 - Foodcraft Supplies"
	},
	{
			"value": "317602",
			"label": "317602 - Tools"
	},
	{
			"value": "300505",
			"label": "300505 - Stencils"
	},
	{
			"value": "323004",
			"label": "323004 - Mediums/Tools"
	},
	{
			"value": "323001",
			"label": "323001 - Clay Bricks"
	},
	{
			"value": "432510",
			"label": "432510 - Misc/Not Assigned"
	},
	{
			"value": "194525",
			"label": "194525 - Floral Foam"
	},
	{
			"value": "194520",
			"label": "194520 - White Foam"
	},
	{
			"value": "197112",
			"label": "197112 - Puzzles"
	},
	{
			"value": "311525",
			"label": "311525 - LthrMacameAudltCraft"
	},
	{
			"value": "197110",
			"label": "197110 - Kids Kits"
	},
	{
			"value": "197171",
			"label": "197171 - Art Activities"
	},
	{
			"value": "197173",
			"label": "197173 - Crayola"
	},
	{
			"value": "300502",
			"label": "300502 - Brushes/Accessories"
	},
	{
			"value": "300501",
			"label": "300501 - Craft Paint"
	},
	{
			"value": "300503",
			"label": "300503 - Home Decor/Chalk"
	},
	{
			"value": "300112",
			"label": "300112 - Non Wood Surfaces"
	},
	{
			"value": "144020",
			"label": "144020 - Consignment Books"
	},
	{
			"value": "169505",
			"label": "169505 - Quilt Patterns"
	},
	{
			"value": "106610",
			"label": "106610 - Trims EA"
	},
	{
			"value": "160530",
			"label": "160530 - Netting & Tulle EA"
	},
	{
			"value": "170405",
			"label": "170405 - Precuts/Bundles"
	},
	{
			"value": "177090",
			"label": "177090 - Cotton Solids EA"
	},
	{
			"value": "178317",
			"label": "178317 - Muslin Pkg"
	},
	{
			"value": "178828",
			"label": "178828 - Interfacings EA"
	},
	{
			"value": "180025",
			"label": "180025 - Scissors"
	},
	{
			"value": "180064",
			"label": "180064 - Rotary Cutters"
	},
	{
			"value": "144005",
			"label": "144005 - Non-Consign Books"
	},
	{
			"value": "196001",
			"label": "196001 - Appliques"
	},
	{
			"value": "196006",
			"label": "196006 - Letters and Numbers"
	},
	{
			"value": "195201",
			"label": "195201 - Paint"
	},
	{
			"value": "195202",
			"label": "195202 - Dyes and Kits"
	},
	{
			"value": "195203",
			"label": "195203 - Tools"
	},
	{
			"value": "195204",
			"label": "195204 - Markers"
	},
	{
			"value": "207533",
			"label": "207533 - Ribbon BTS"
	},
	{
			"value": "213043",
			"label": "213043 - Iron Accessories"
	},
	{
			"value": "220101",
			"label": "220101 - Sewing Machine Acc"
	},
	{
			"value": "315601",
			"label": "315601 - Findings"
	},
	{
			"value": "315807",
			"label": "315807 - Pendants & Charms"
	},
	{
			"value": "302212",
			"label": "302212 - Fine Art Brushes"
	},
	{
			"value": "327232",
			"label": "327232 - Accessories"
	},
	{
			"value": "327230",
			"label": "327230 - Needle and Hooks"
	},
	{
			"value": "329040",
			"label": "329040 - Floss Other"
	},
	{
			"value": "404020",
			"label": "404020 - Spring/Summer Socks"
	},
	{
			"value": "149803",
			"label": "149803 - College BTY"
	},
	{
			"value": "100257",
			"label": "100257 - Novelty"
	},
	{
			"value": "187507",
			"label": "187507 - Crafting Organizers"
	},
	{
			"value": "188003",
			"label": "188003 - Plastic Totes&Contrs"
	},
	{
			"value": "188530",
			"label": "188530 - Sewing Organizers"
	},
	{
			"value": "175006",
			"label": "175006 - Nursery Fleece Flnl"
	},
	{
			"value": "175003",
			"label": "175003 - Nursery CottnSwaddle"
	},
	{
			"value": "175004",
			"label": "175004 - Nursery Panel"
	},
	{
			"value": "174805",
			"label": "174805 - Holiday PreCuts"
	},
	{
			"value": "153010",
			"label": "153010 - Shirtings"
	},
	{
			"value": "391525",
			"label": "391525 - Spring/Summer Impuls"
	},
	{
			"value": "300018",
			"label": "300018 - Turnings/Dowels"
	},
	{
			"value": "197229",
			"label": "197229 - Glitter/Sequin/Gems"
	},
	{
			"value": "197170",
			"label": "197170 - Surfaces"
	},
	{
			"value": "197226",
			"label": "197226 - Pony Beads & Lacing"
	},
	{
			"value": "197225",
			"label": "197225 - Kids Craft Foam"
	},
	{
			"value": "144015",
			"label": "144015 - Calendars"
	},
	{
			"value": "140220",
			"label": "140220 - Wall Frames"
	},
	{
			"value": "303020",
			"label": "303020 - Go Green Bag"
	},
	{
			"value": "176001",
			"label": "176001 - Cotton Canvas"
	},
	{
			"value": "213021",
			"label": "213021 - Elastic BTY"
	},
	{
			"value": "315402",
			"label": "315402 - Packaged Beads"
	},
	{
			"value": "315605",
			"label": "315605 - Storage"
	},
	{
			"value": "315606",
			"label": "315606 - Resin"
	},
	{
			"value": "117001",
			"label": "117001 - Naturals"
	},
	{
			"value": "327021",
			"label": "327021 - Spin Coats Natural"
	},
	{
			"value": "327111",
			"label": "327111 - Spin Coats Cotton"
	},
	{
			"value": "326602",
			"label": "326602 - Coats Super Saver"
	},
	{
			"value": "327022",
			"label": "327022 - LB Natural"
	},
	{
			"value": "326525",
			"label": "326525 - Spin Simply Soft"
	},
	{
			"value": "326520",
			"label": "326520 - Spin Coats All Other"
	},
	{
			"value": "326812",
			"label": "326812 - Spin Coats Baby"
	},
	{
			"value": "329020",
			"label": "329020 - Stitch Kits"
	},
	{
			"value": "197312",
			"label": "197312 - Kids Impulse"
	},
	{
			"value": "340515",
			"label": "340515 - Advertising"
	},
	{
			"value": "303025",
			"label": "303025 - Impulse/Trend"
	},
	{
			"value": "153502",
			"label": "153502 - Knit Prints"
	},
	{
			"value": "164010",
			"label": "164010 - Simplicity"
	},
	{
			"value": "340565",
			"label": "340565 - Supplies-Non Merch"
	},
	{
			"value": "302211",
			"label": "302211 - Fine Art Paint"
	},
	{
			"value": "302111",
			"label": "302111 - Fine Art Drawing"
	},
	{
			"value": "170402",
			"label": "170402 - Fabric Quarters"
	},
	{
			"value": "326534",
			"label": "326534 - LB All Other"
	},
	{
			"value": "177505",
			"label": "177505 - Comfy Cozy"
	},
	{
			"value": "182525",
			"label": "182525 - Furniture&Dress Form"
	},
	{
			"value": "317201",
			"label": "317201 - Icing Fondnt&Ingredt"
	},
	{
			"value": "317004",
			"label": "317004 - Holiday Food"
	},
	{
			"value": "170005",
			"label": "170005 - Novelty Prints"
	},
	{
			"value": "153003",
			"label": "153003 - Cotton"
	},
	{
			"value": "156501",
			"label": "156501 - Denim"
	},
	{
			"value": "160105",
			"label": "160105 - Let's Pretend"
	},
	{
			"value": "160045",
			"label": "160045 - Metallic Velv Brocde"
	},
	{
			"value": "153005",
			"label": "153005 - Linen"
	},
	{
			"value": "401720",
			"label": "401720 - Spring Garden DÃ©cor"
	},
	{
			"value": "126760",
			"label": "126760 - Floral Supplies"
	},
	{
			"value": "150018",
			"label": "150018 - Silky Prints"
	},
	{
			"value": "189025",
			"label": "189025 - Baskets"
	},
	{
			"value": "340550",
			"label": "340550 - Ship/Handling Fee"
	},
	{
			"value": "403505",
			"label": "403505 - Spring/Summer Candy"
	},
	{
			"value": "170302",
			"label": "170302 - Double Faced Quilts"
	},
	{
			"value": "174501",
			"label": "174501 - Patriotic Prints BTY"
	},
	{
			"value": "302013",
			"label": "302013 - Paper/Pads"
	},
	{
			"value": "100201",
			"label": "100201 - Cotton Linen Blend"
	},
	{
			"value": "100260",
			"label": "100260 - Jacquards"
	},
	{
			"value": "177075",
			"label": "177075 - Better Solid"
	},
	{
			"value": "311302",
			"label": "311302 - Cricut Tools &Blades"
	},
	{
			"value": "106605",
			"label": "106605 - Value Trims EA"
	},
	{
			"value": "207544",
			"label": "207544 - Decorative Ribbon"
	},
	{
			"value": "109275",
			"label": "109275 - Outdoor Print"
	},
	{
			"value": "197033",
			"label": "197033 - Kids Summer"
	},
	{
			"value": "302112",
			"label": "302112 - Fine Art Markers"
	},
	{
			"value": "179420",
			"label": "179420 - Batting BTY"
	},
	{
			"value": "100518",
			"label": "100518 - Multipurpose Vinyl"
	},
	{
			"value": "150002",
			"label": "150002 - Silky Solids"
	},
	{
			"value": "189540",
			"label": "189540 - Other Lighting"
	},
	{
			"value": "213017",
			"label": "213017 - Velcro BTY"
	},
	{
			"value": "220102",
			"label": "220102 - Iron & Steamers"
	},
	{
			"value": "220020",
			"label": "220020 - Brother"
	},
	{
			"value": "195101",
			"label": "195101 - Short-Sleeve Tees"
	},
	{
			"value": "106660",
			"label": "106660 - Trims BTY"
	},
	{
			"value": "311304",
			"label": "311304 - Other Cricut Supplie"
	},
	{
			"value": "187510",
			"label": "187510 - Super Satchels"
	},
	{
			"value": "165510",
			"label": "165510 - Vogue"
	},
	{
			"value": "207530",
			"label": "207530 - Craft Ribbon"
	},
	{
			"value": "207534",
			"label": "207534 - Ribbon Fancies"
	},
	{
			"value": "170545",
			"label": "170545 - Christmas Prnt/Woven"
	},
	{
			"value": "207532",
			"label": "207532 - Ribbon BTY"
	},
	{
			"value": "150510",
			"label": "150510 - Lic Christmas BTY"
	},
	{
			"value": "176010",
			"label": "176010 - Duck Cotton Canvas"
	},
	{
			"value": "177546",
			"label": "177546 - Super Snuggle"
	},
	{
			"value": "116240",
			"label": "116240 - Holiday Accessories"
	},
	{
			"value": "140260",
			"label": "140260 - Shadowboxes"
	},
	{
			"value": "189535",
			"label": "189535 - Table Lighting"
	},
	{
			"value": "170006",
			"label": "170006 - Novelty PNL"
	},
	{
			"value": "107235",
			"label": "107235 - Peel & Stick"
	},
	{
			"value": "207553",
			"label": "207553 - Concept Shop Ribbon"
	},
	{
			"value": "197140",
			"label": "197140 - Perler Beads"
	},
	{
			"value": "170365",
			"label": "170365 - Batiks"
	},
	{
			"value": "196003",
			"label": "196003 - Wearable Accessories"
	},
	{
			"value": "160705",
			"label": "160705 - Satin"
	},
	{
			"value": "156520",
			"label": "156520 - Suede/Leather/Cordry"
	},
	{
			"value": "300510",
			"label": "300510 - Spray Paint"
	},
	{
			"value": "302012",
			"label": "302012 - Artist Canvas"
	},
	{
			"value": "149703",
			"label": "149703 - MLB BTY"
	},
	{
			"value": "126790",
			"label": "126790 - Containers"
	},
	{
			"value": "220014",
			"label": "220014 - Singer"
	},
	{
			"value": "310009",
			"label": "310009 - O/S Paper"
	},
	{
			"value": "171001",
			"label": "171001 - Halloween Prints BTY"
	},
	{
			"value": "300008",
			"label": "300008 - Crates/Cubes"
	},
	{
			"value": "310010",
			"label": "310010 - Specialty Paper"
	},
	{
			"value": "207550",
			"label": "207550 - Basic Ribbon OOL"
	},
	{
			"value": "310605",
			"label": "310605 - Tim Holtz"
	},
	{
			"value": "310402",
			"label": "310402 - Value"
	},
	{
			"value": "315401",
			"label": "315401 - Strung Beads"
	},
	{
			"value": "187504",
			"label": "187504 - Paper Craft Storage"
	},
	{
			"value": "403515",
			"label": "403515 - Halloween/Fall Candy"
	},
	{
			"value": "311650",
			"label": "311650 - Crafting Tape"
	},
	{
			"value": "315809",
			"label": "315809 - Hair & Accessories"
	},
	{
			"value": "300006",
			"label": "300006 - Letters/Numbers"
	},
	{
			"value": "140250",
			"label": "140250 - Hudson 43"
	},
	{
			"value": "317003",
			"label": "317003 - Fall/Halloween Food"
	},
	{
			"value": "100255",
			"label": "100255 - Chenille"
	},
	{
			"value": "300050",
			"label": "300050 - Tools"
	},
	{
			"value": "300007",
			"label": "300007 - Unfinished Wood"
	},
	{
			"value": "327110",
			"label": "327110 - Spin Sugar N Cream"
	},
	{
			"value": "327020",
			"label": "327020 - LB Wool Ease TQ"
	},
	{
			"value": "302011",
			"label": "302011 - Value Canvas"
	},
	{
			"value": "100264",
			"label": "100264 - Stain Resistant"
	},
	{
			"value": "211545",
			"label": "211545 - Fashion Buttons"
	},
	{
			"value": "149603",
			"label": "149603 - NFL BTY"
	},
	{
			"value": "310302",
			"label": "310302 - Card Crafting"
	},
	{
			"value": "107260",
			"label": "107260 - Home Decor Notions"
	},
	{
			"value": "160110",
			"label": "160110 - Halloween"
	},
	{
			"value": "310901",
			"label": "310901 - Season Papercrafting"
	},
	{
			"value": "109280",
			"label": "109280 - Outdoor Solid"
	},
	{
			"value": "179620",
			"label": "179620 - Foam BTY"
	},
	{
			"value": "169010",
			"label": "169010 - Fashion Fusion"
	},
	{
			"value": "164510",
			"label": "164510 - McCalls"
	},
	{
			"value": "326604",
			"label": "326604 - LB Pound of Love"
	},
	{
			"value": "140240",
			"label": "140240 - Tabletop Frames"
	},
	{
			"value": "140280",
			"label": "140280 - Framing Accessories"
	},
	{
			"value": "160720",
			"label": "160720 - Lace/Crepe/Chiffon"
	},
	{
			"value": "321103",
			"label": "321103 - Other Craft Technolo"
	},
	{
			"value": "197213",
			"label": "197213 - POP Art Supplies"
	},
	{
			"value": "188002",
			"label": "188002 - Carts & Drawers"
	},
	{
			"value": "340545",
			"label": "340545 - Services"
	},
	{
			"value": "300520",
			"label": "300520 - Paint Markers"
	},
	{
			"value": "431010",
			"label": "431010 - Misc/Not Assigned"
	},
	{
			"value": "172001",
			"label": "172001 - Valentine Prints BTY"
	},
	{
			"value": "196002",
			"label": "196002 - Bandanas"
	},
	{
			"value": "326532",
			"label": "326532 - LB Hometown"
	},
	{
			"value": "411920",
			"label": "411920 - Flameless"
	},
	{
			"value": "103640",
			"label": "103640 - Home Dec Fabric EA"
	},
	{
			"value": "410205",
			"label": "410205 - Decor"
	},
	{
			"value": "196027",
			"label": "196027 - SeasonalCraftTextile"
	},
	{
			"value": "164035",
			"label": "164035 - Dropped Patterns"
	},
	{
			"value": "164025",
			"label": "164025 - New Look"
	},
	{
			"value": "311101",
			"label": "311101 - Cricut Cutting"
	},
	{
			"value": "165010",
			"label": "165010 - Butterick"
	},
	{
			"value": "302014",
			"label": "302014 - Easles/Storage"
	},
	{
			"value": "148001",
			"label": "148001 - Blizzard Solids"
	},
	{
			"value": "149101",
			"label": "149101 - License WARM"
	},
	{
			"value": "148719",
			"label": "148719 - License MISC BTY"
	},
	{
			"value": "341505",
			"label": "341505 - Classroom Supply"
	},
	{
			"value": "117004",
			"label": "117004 - Basic Stems"
	},
	{
			"value": "170315",
			"label": "170315 - Premium Prints"
	},
	{
			"value": "100285",
			"label": "100285 - Ltwght Fbrcs & Shr"
	},
	{
			"value": "149360",
			"label": "149360 - Fur"
	},
	{
			"value": "326605",
			"label": "326605 - Coats SuperSaver Jmb"
	},
	{
			"value": "107230",
			"label": "107230 - Home Fabric Care"
	},
	{
			"value": "175009",
			"label": "175009 - Terry"
	},
	{
			"value": "140230",
			"label": "140230 - Poster Frames"
	},
	{
			"value": "411910",
			"label": "411910 - Decorative"
	},
	{
			"value": "411950",
			"label": "411950 - Holiday Candles"
	},
	{
			"value": "310604",
			"label": "310604 - Other Designers"
	},
	{
			"value": "126770",
			"label": "126770 - Wreath Accessories"
	},
	{
			"value": "126740",
			"label": "126740 - Tools"
	},
	{
			"value": "177030",
			"label": "177030 - Wide Solids"
	},
	{
			"value": "311655",
			"label": "311655 - Mailing & Pkg Supply"
	},
	{
			"value": "100505",
			"label": "100505 - Weather Resist Vinyl"
	},
	{
			"value": "164050",
			"label": "164050 - Burda"
	},
	{
			"value": "340530",
			"label": "340530 - Fixture Sales"
	},
	{
			"value": "411925",
			"label": "411925 - Accessories"
	},
	{
			"value": "142780",
			"label": "142780 - SVCS:CustomFraming"
	},
	{
			"value": "340555",
			"label": "340555 - SpecialOrd Deposit"
	},
	{
			"value": "403525",
			"label": "403525 - Holiday Candy"
	},
	{
			"value": "107270",
			"label": "107270 - Home Decor Tools"
	},
	{
			"value": "117005",
			"label": "117005 - Greenery"
	},
	{
			"value": "160725",
			"label": "160725 - Casa Fabrics"
	},
	{
			"value": "142786",
			"label": "142786 - Svcs:Damaged/Mism"
	},
	{
			"value": "189550",
			"label": "189550 - Floor Lighting"
	},
	{
			"value": "326601",
			"label": "326601 - Spin One Pound"
	},
	{
			"value": "341512",
			"label": "341512 - Parties"
	},
	{
			"value": "341519",
			"label": "341519 - Kids Sew-Quilt"
	},
	{
			"value": "341513",
			"label": "341513 - Quilting"
	},
	{
			"value": "433010",
			"label": "433010 - Misc/Not Assigned"
	},
	{
			"value": "434810",
			"label": "434810 - Misc/ Not Assigned"
	},
	{
			"value": "433510",
			"label": "433510 - Misc/ Not Assigned"
	},
	{
			"value": "326811",
			"label": "326811 - LB Baby"
	},
	{
			"value": "341005",
			"label": "341005 - Gift Cards"
	},
	{
			"value": "341518",
			"label": "341518 - Kids Craft"
	},
	{
			"value": "341506",
			"label": "341506 - General Craft"
	},
	{
			"value": "341516",
			"label": "341516 - Foodcrafting"
	},
	{
			"value": "341515",
			"label": "341515 - Events"
	},
	{
			"value": "341514",
			"label": "341514 - Sewing"
	},
	{
			"value": "155501",
			"label": "155501 - Athleisure"
	},
	{
			"value": "430505",
			"label": "430505 - Div/Catg SKU"
	},
	{
			"value": "207552",
			"label": "207552 - Value Organdy"
	},
	{
			"value": "142762",
			"label": "142762 - Quick Sale"
	},
	{
			"value": "303030",
			"label": "303030 - Licensed Hardgoods"
	},
	{
			"value": "149320",
			"label": "149320 - Sherpa"
	},
	{
			"value": "310503",
			"label": "310503 - Boxes"
	},
	{
			"value": "148004",
			"label": "148004 - Blizzard Prints"
	},
	{
			"value": "405535",
			"label": "405535 - Gum, Mint & Misc"
	},
	{
			"value": "100263",
			"label": "100263 - Tweed"
	},
	{
			"value": "100290",
			"label": "100290 - Drpy Lngs & Blkout"
	},
	{
			"value": "100258",
			"label": "100258 - Suede"
	},
	{
			"value": "196004",
			"label": "196004 - Canvas and Bases"
	},
	{
			"value": "122960",
			"label": "122960 - Fall Accessories"
	},
	{
			"value": "327023",
			"label": "327023 - PL Natural"
	},
	{
			"value": "180090",
			"label": "180090 - Fashion Cutting"
	},
	{
			"value": "315805",
			"label": "315805 - Finished Jewelry"
	},
	{
			"value": "177605",
			"label": "177605 - Specialty Flannel"
	},
	{
			"value": "170403",
			"label": "170403 - 1 & 2 YD Precuts"
	},
	{
			"value": "311303",
			"label": "311303 - Cricut Mats"
	},
	{
			"value": "170025",
			"label": "170025 - Wide Prints"
	},
	{
			"value": "401710",
			"label": "401710 - Spring Equipment"
	},
	{
			"value": "411930",
			"label": "411930 - Home Fragrance"
	},
	{
			"value": "142740",
			"label": "142740 - Cust Frame Access"
	},
	{
			"value": "100262",
			"label": "100262 - Velvet"
	},
	{
			"value": "394625",
			"label": "394625 - Autumn Serveware"
	},
	{
			"value": "394125",
			"label": "394125 - Halloween Serveware"
	},
	{
			"value": "405530",
			"label": "405530 - Salty"
	},
	{
			"value": "395515",
			"label": "395515 - Holiday Tabletop"
	},
	{
			"value": "399515",
			"label": "399515 - Holiday Tins"
	},
	{
			"value": "188520",
			"label": "188520 - Sewing Baskets"
	},
	{
			"value": "195106",
			"label": "195106 - Other Apparel"
	},
	{
			"value": "148204",
			"label": "148204 - AP Plush Solids"
	},
	{
			"value": "409005",
			"label": "409005 - Summer Out DÃ©cor"
	},
	{
			"value": "403005",
			"label": "403005 - Summer Tabletop"
	},
	{
			"value": "394707",
			"label": "394707 - Autumn Outdoor Decor"
	},
	{
			"value": "394507",
			"label": "394507 - Autumn Wall Decor"
	},
	{
			"value": "340525",
			"label": "340525 - Donations"
	},
	{
			"value": "149350",
			"label": "149350 - Fleece EA"
	},
	{
			"value": "171501",
			"label": "171501 - Fall Prints BTY"
	},
	{
			"value": "396015",
			"label": "396015 - Fall Ribbon"
	},
	{
			"value": "115115",
			"label": "115115 - Spring Arrangements"
	},
	{
			"value": "160010",
			"label": "160010 - Fashion Collections"
	},
	{
			"value": "326810",
			"label": "326810 - Spin Baby Blanket"
	},
	{
			"value": "197317",
			"label": "197317 - Games"
	},
	{
			"value": "340580",
			"label": "340580 - EP and BW Purge Art"
	},
	{
			"value": "341509",
			"label": "341509 - Kids Foodcrafting"
	},
	{
			"value": "326530",
			"label": "326530 - LB Cake"
	},
	{
			"value": "326531",
			"label": "326531 - Coats With Love"
	},
	{
			"value": "326535",
			"label": "326535 - PL All Other"
	},
	{
			"value": "395520",
			"label": "395520 - Holiday Textiles"
	},
	{
			"value": "396005",
			"label": "396005 - Christmas Ribbon"
	},
	{
			"value": "398005",
			"label": "398005 - Holiday StockngSkrts"
	},
	{
			"value": "398010",
			"label": "398010 - HolidayToppersGarlnd"
	},
	{
			"value": "398020",
			"label": "398020 - Holiday OpenStockOrn"
	},
	{
			"value": "398030",
			"label": "398030 - Holiday Boxed Orn"
	},
	{
			"value": "399005",
			"label": "399005 - Holiday Giftbags"
	},
	{
			"value": "403020",
			"label": "403020 - Summer Textiles"
	},
	{
			"value": "341517",
			"label": "341517 - Yarn"
	},
	{
			"value": "114702",
			"label": "114702 - Antibac/Wipes"
	},
	{
			"value": "114770",
			"label": "114770 - Lotion"
	},
	{
			"value": "115002",
			"label": "115002 - Spring Bushes"
	},
	{
			"value": "115006",
			"label": "115006 - Spring Stems"
	},
	{
			"value": "115502",
			"label": "115502 - Fall Bushes"
	},
	{
			"value": "115506",
			"label": "115506 - Fall Stems"
	},
	{
			"value": "116001",
			"label": "116001 - Holiday PVC"
	},
	{
			"value": "116002",
			"label": "116002 - Holiday Bushes"
	},
	{
			"value": "116003",
			"label": "116003 - Holiday Drieds"
	},
	{
			"value": "116006",
			"label": "116006 - Holiday Stems"
	},
	{
			"value": "116009",
			"label": "116009 - Holiday Picks"
	},
	{
			"value": "403035",
			"label": "403035 - Summer Trend"
	},
	{
			"value": "148815",
			"label": "148815 - 48IN NST"
	},
	{
			"value": "148817",
			"label": "148817 - 72IN NST"
	},
	{
			"value": "396010",
			"label": "396010 - Spring Ribbon"
	},
	{
			"value": "164515",
			"label": "164515 - Kwik Sew"
	},
	{
			"value": "394545",
			"label": "394545 - Autumn Textiles"
	},
	{
			"value": "175005",
			"label": "175005 - Nursery EA"
	},
	{
			"value": "404505",
			"label": "404505 - Beverage"
	},
	{
			"value": "394045",
			"label": "394045 - Halloween Textiles"
	},
	{
			"value": "195105",
			"label": "195105 - Sweatshirts"
	},
	{
			"value": "197030",
			"label": "197030 - Kids Spring Holiday"
	},
	{
			"value": "213041",
			"label": "213041 - Bulk Notions"
	},
	{
			"value": "111201",
			"label": "111201 - VFF Print"
	},
	{
			"value": "111202",
			"label": "111202 - VFF Solid"
	},
	{
			"value": "111220",
			"label": "111220 - VFF Vinyl"
	},
	{
			"value": "170030",
			"label": "170030 - Quilter's Showcase"
	},
	{
			"value": "152005",
			"label": "152005 - Seasonal Kids"
	},
	{
			"value": "152030",
			"label": "152030 - Everyday Kids"
	},
	{
			"value": "157505",
			"label": "157505 - Fashion Apparel Col"
	},
	{
			"value": "160005",
			"label": "160005 - Bridal Collections"
	},
	{
			"value": "160015",
			"label": "160015 - Sew Sweet"
	},
	{
			"value": "172501",
			"label": "172501 - St Pat's Prints BTY"
	},
	{
			"value": "173001",
			"label": "173001 - Easter Prints BTY"
	},
	{
			"value": "177015",
			"label": "177015 - Sew Classics"
	},
	{
			"value": "177607",
			"label": "177607 - Wide Flannel"
	},
	{
			"value": "150007",
			"label": "150007 - Fashion Apparel PNL"
	},
	{
			"value": "170515",
			"label": "170515 - Christmas PNL"
	},
	{
			"value": "340560",
			"label": "340560 - Supplies-Merchandise"
	},
	{
			"value": "148703",
			"label": "148703 - Minnie/Mickey"
	},
	{
			"value": "148711",
			"label": "148711 - Juvenile"
	},
	{
			"value": "148717",
			"label": "148717 - Pixar"
	},
	{
			"value": "401580",
			"label": "401580 - Spring Wall Decor"
	},
	{
			"value": "148713",
			"label": "148713 - Marvel/DC Comics"
	},
	{
			"value": "115270",
			"label": "115270 - Spring Containers"
	},
	{
			"value": "160120",
			"label": "160120 - Performing"
	},
	{
			"value": "396016",
			"label": "396016 - Halloween Ribbon"
	},
	{
			"value": "310303",
			"label": "310303 - Party"
	},
	{
			"value": "323005",
			"label": "323005 - Resin"
	},
	{
			"value": "303002",
			"label": "303002 - Drinkware"
	},
	{
			"value": "114710",
			"label": "114710 - Soap"
	},
	{
			"value": "396030",
			"label": "396030 - Summer Ribbon"
	},
	{
			"value": "149007",
			"label": "149007 - Lightweight Fleece"
	},
	{
			"value": "175008",
			"label": "175008 - Nursery Misc"
	},
	{
			"value": "148705",
			"label": "148705 - Star Wars/Mando"
	},
	{
			"value": "303028",
			"label": "303028 - Tech"
	},
	{
			"value": "340575",
			"label": "340575 - Project Sheets"
	},
	{
			"value": "150525",
			"label": "150525 - Lic Halloween BTY"
	},
	{
			"value": "149903",
			"label": "149903 - NBA BTY"
	},
	{
			"value": "197031",
			"label": "197031 - Kids Halloween"
	},
	{
			"value": "149505",
			"label": "149505 - License PKG"
	},
	{
			"value": "157520",
			"label": "157520 - Fashion Apparel EA"
	},
	{
			"value": "392510",
			"label": "392510 - Val Outdoor"
	},
	{
			"value": "393010",
			"label": "393010 - St Pats Outdoor"
	},
	{
			"value": "393510",
			"label": "393510 - Easter Outdoor"
	},
	{
			"value": "399545",
			"label": "399545 - Holiday Towels"
	},
	{
			"value": "115260",
			"label": "115260 - Spring Accessories"
	},
	{
			"value": "197032",
			"label": "197032 - Kids Christmas"
	},
	{
			"value": "341010",
			"label": "341010 - GC Mall- Closed Loop"
	},
	{
			"value": "341015",
			"label": "341015 - GC Mall - Open Loop"
	},
	{
			"value": "147510",
			"label": "147510 - Team Pkgd"
	},
	{
			"value": "341523",
			"label": "341523 - Socials"
	},
	{
			"value": "220025",
			"label": "220025 - Janome"
	},
	{
			"value": "391550",
			"label": "391550 - Apparel &Accessories"
	},
	{
			"value": "311202",
			"label": "311202 - CricutVinyl&TrnsTape"
	},
	{
			"value": "396013",
			"label": "396013 - St Pat Ribbon"
	},
	{
			"value": "321101",
			"label": "321101 - Silhouette"
	},
	{
			"value": "148704",
			"label": "148704 - Disney Prncs Villian"
	},
	{
			"value": "177508",
			"label": "177508 - Flannel EA"
	},
	{
			"value": "341520",
			"label": "341520 - Kids Yarn"
	},
	{
			"value": "142750",
			"label": "142750 - CF Merchandise"
	},
	{
			"value": "311201",
			"label": "311201 - Cricut Iron On"
	},
	{
			"value": "341020",
			"label": "341020 - Virtual Gift Card"
	},
	{
			"value": "326710",
			"label": "326710 - Spin Blanket"
	},
	{
			"value": "300111",
			"label": "300111 - Trend Surfaces"
	},
	{
			"value": "142810",
			"label": "142810 - Everyday Art"
	},
	{
			"value": "142820",
			"label": "142820 - Mirrors"
	},
	{
			"value": "142830",
			"label": "142830 - Seasonal Prints"
	},
	{
			"value": "142840",
			"label": "142840 - Licensed Prints"
	},
	{
			"value": "168005",
			"label": "168005 - Non Consign Patterns"
	},
	{
			"value": "310606",
			"label": "310606 - Heidi Swapp"
	},
	{
			"value": "122814",
			"label": "122814 - Fall Wreaths"
	},
	{
			"value": "148714",
			"label": "148714 - NBXMAS/Horror"
	},
	{
			"value": "149203",
			"label": "149203 - NHL BTY"
	},
	{
			"value": "148712",
			"label": "148712 - TV/Movies"
	},
	{
			"value": "395545",
			"label": "395545 - Holiday Trend"
	},
	{
			"value": "188015",
			"label": "188015 - Holiday Storage"
	},
	{
			"value": "195104",
			"label": "195104 - Long-Sleeve Tees"
	},
	{
			"value": "311204",
			"label": "311204 - OthrCricutCutMateral"
	},
	{
			"value": "395540",
			"label": "395540 - Holiday Wall DÃ©cor"
	},
	{
			"value": "115107",
			"label": "115107 - Spring Garlands"
	},
	{
			"value": "115114",
			"label": "115114 - Spring Wreaths"
	},
	{
			"value": "401585",
			"label": "401585 - Spring Tabletop Dec"
	},
	{
			"value": "100265",
			"label": "100265 - Upholstery Panel"
	},
	{
			"value": "407025",
			"label": "407025 - Summer Serveware"
	},
	{
			"value": "394145",
			"label": "394145 - Halloween Towels"
	},
	{
			"value": "122980",
			"label": "122980 - Fall Containers"
	},
	{
			"value": "122807",
			"label": "122807 - Fall Garlands"
	},
	{
			"value": "410245",
			"label": "410245 - Textiles"
	},
	{
			"value": "394555",
			"label": "394555 - Autumn Pumpkins"
	},
	{
			"value": "207545",
			"label": "207545 - Save the Date"
	},
	{
			"value": "398035",
			"label": "398035 - Holiday Trim Lightng"
	},
	{
			"value": "398040",
			"label": "398040 - Holiday Trees"
	},
	{
			"value": "399025",
			"label": "399025 - Holiday Wrap Accs"
	},
	{
			"value": "411940",
			"label": "411940 - Halloween Candles"
	},
	{
			"value": "196540",
			"label": "196540 - Fall/Halloween Craft"
	},
	{
			"value": "196550",
			"label": "196550 - Holiday Craft"
	},
	{
			"value": "326813",
			"label": "326813 - PL Baby"
	},
	{
			"value": "117009",
			"label": "117009 - Floral Accessories"
	},
	{
			"value": "148710",
			"label": "148710 - Gaming"
	},
	{
			"value": "391530",
			"label": "391530 - Fall/Halloween Impls"
	},
	{
			"value": "411905",
			"label": "411905 - Jars"
	},
	{
			"value": "326603",
			"label": "326603 - PL Value"
	},
	{
			"value": "395550",
			"label": "395550 - Holiday Dec Storage"
	},
	{
			"value": "188008",
			"label": "188008 - Plastic Weave Bins"
	},
	{
			"value": "188510",
			"label": "188510 - Rolling Totes"
	},
	{
			"value": "411935",
			"label": "411935 - Spring Candles"
	},
	{
			"value": "197313",
			"label": "197313 - Beanies"
	},
	{
			"value": "396011",
			"label": "396011 - Valentine's Ribbon"
	},
	{
			"value": "196560",
			"label": "196560 - Spring Craft"
	},
	{
			"value": "393007",
			"label": "393007 - St Pats DÃ©cor"
	},
	{
			"value": "401780",
			"label": "401780 - SpringPlanters&Stand"
	},
	{
			"value": "401760",
			"label": "401760 - Spring Coco Liners"
	},
	{
			"value": "401545",
			"label": "401545 - Spring Textiles"
	},
	{
			"value": "401645",
			"label": "401645 - Spring Towels"
	},
	{
			"value": "160115",
			"label": "160115 - Cosplay"
	},
	{
			"value": "149001",
			"label": "149001 - Luxe Fleece"
	},
	{
			"value": "310101",
			"label": "310101 - Happy Planner Access"
	},
	{
			"value": "150500",
			"label": "150500 - Lic Spring Holdy BTY"
	},
	{
			"value": "409020",
			"label": "409020 - Summer Out Textiles"
	},
	{
			"value": "408050",
			"label": "408050 - Summer American Flag"
	},
	{
			"value": "403010",
			"label": "403010 - Summer Wall Dcor"
	},
	{
			"value": "408065",
			"label": "408065 - Summer Parade & Dec"
	},
	{
			"value": "148701",
			"label": "148701 - Disney Classic"
	},
	{
			"value": "148718",
			"label": "148718 - Peanuts"
	},
	{
			"value": "148720",
			"label": "148720 - License MISC PNL"
	},
	{
			"value": "122815",
			"label": "122815 - Fall Arrangements"
	},
	{
			"value": "394007",
			"label": "394007 - Halloween Wall Decor"
	},
	{
			"value": "394006",
			"label": "394006 - Halloween Tbltop"
	},
	{
			"value": "148716",
			"label": "148716 - Harry Potter"
	},
	{
			"value": "116114",
			"label": "116114 - Holiday Wreaths"
	},
	{
			"value": "116107",
			"label": "116107 - Holiday Garlands"
	},
	{
			"value": "116115",
			"label": "116115 - Holiday Arrangements"
	},
	{
			"value": "116250",
			"label": "116250 - Holiday Containers"
	},
	{
			"value": "410240",
			"label": "410240 - Throws"
	},
	{
			"value": "392507",
			"label": "392507 - Val DÃ©cor"
	},
	{
			"value": "393507",
			"label": "393507 - Easter DÃ©cor"
	},
	{
			"value": "394506",
			"label": "394506 - Autumn Tbltop"
	},
	{
			"value": "399015",
			"label": "399015 - Holiday Giftwrap"
	},
	{
			"value": "403025",
			"label": "403025 - Summer Lighting"
	},
	{
			"value": "311580",
			"label": "311580 - Hobby Wellness"
	},
	{
			"value": "148220",
			"label": "148220 - AP Plush Prints"
	},
	{
			"value": "411945",
			"label": "411945 - Fall/Hallow Candles"
	},
	{
			"value": "396520",
			"label": "396520 - Holiday Out Decor"
	},
	{
			"value": "396530",
			"label": "396530 - Holiday Mats & Flags"
	},
	{
			"value": "391540",
			"label": "391540 - Holiday Impulse"
	},
	{
			"value": "149004",
			"label": "149004 - Sew Lush"
	},
	{
			"value": "310103",
			"label": "310103 - Other Journal"
	},
	{
			"value": "189005",
			"label": "189005 - Soft Storage"
	},
	{
			"value": "396012",
			"label": "396012 - Easter Ribbon"
	},
	{
			"value": "392515",
			"label": "392515 - Val Entertaining"
	},
	{
			"value": "393015",
			"label": "393015 - St Pats Entertaining"
	},
	{
			"value": "393512",
			"label": "393512 - Easter Basket DÃ©cor"
	},
	{
			"value": "393515",
			"label": "393515 - Easter Entertaining"
	},
	{
			"value": "401765",
			"label": "401765 - Spring Mats & Flags"
	},
	{
			"value": "401625",
			"label": "401625 - Spring Serveware"
	},
	{
			"value": "401715",
			"label": "401715 - Spring Stakes&Trelis"
	},
	{
			"value": "404510",
			"label": "404510 - Beverage - Non Carb"
	},
	{
			"value": "149002",
			"label": "149002 - Soft and Minky"
	},
	{
			"value": "148808",
			"label": "148808 - MIGI NST"
	},
	{
			"value": "409025",
			"label": "409025 - Summer Out Lighting"
	},
	{
			"value": "409010",
			"label": "409010 - Summer Mats & Flags"
	},
	{
			"value": "407020",
			"label": "407020 - Summer Towels"
	},
	{
			"value": "116614",
			"label": "116614 - Summer Wreaths"
	},
	{
			"value": "114720",
			"label": "114720 - Beauty"
	},
	{
			"value": "160035",
			"label": "160035 - Special Occasion EA"
	},
	{
			"value": "160040",
			"label": "160040 - Special Occasion PNL"
	},
	{
			"value": "341610",
			"label": "341610 - Custom Shop"
	},
	{
			"value": "311103",
			"label": "311103 - Other Cricut Tech"
	},
	{
			"value": "115003",
			"label": "115003 - Spring Drieds"
	},
	{
			"value": "115009",
			"label": "115009 - Spring Picks"
	},
	{
			"value": "311102",
			"label": "311102 - Cricut Heat Press"
	},
	{
			"value": "310102",
			"label": "310102 - HP Planners"
	},
	{
			"value": "147511",
			"label": "147511 - Team Panels"
	},
	{
			"value": "441001",
			"label": "441001 - BOSS ea"
	},
	{
			"value": "441002",
			"label": "441002 - BOSS yd"
	},
	{
			"value": "441003",
			"label": "441003 - BOSS pnl"
	},
	{
			"value": "442001",
			"label": "442001 - CREATIVE BUG EA"
	},
	{
			"value": "116770",
			"label": "116770 - Summer Containers"
	},
	{
			"value": "115503",
			"label": "115503 - Fall Drieds"
	},
	{
			"value": "115509",
			"label": "115509 - Fall Picks"
	},
	{
			"value": "404030",
			"label": "404030 - Fall/Halloween Socks"
	},
	{
			"value": "315903",
			"label": "315903 - Holiday Jewelry"
	},
	{
			"value": "163505",
			"label": "163505 - Custom & Luxe"
	},
	{
			"value": "315902",
			"label": "315902 - Halloween Jewelry"
	},
	{
			"value": "311570",
			"label": "311570 - Hobby Machines"
	},
	{
			"value": "189030",
			"label": "189030 - Misc Home Storage"
	},
	{
			"value": "220021",
			"label": "220021 - OtherSewingMachBrand"
	},
	{
			"value": "407005",
			"label": "407005 - Summer Mugs"
	},
	{
			"value": "150530",
			"label": "150530 - Lic Halloween Pkgd"
	},
	{
			"value": "404035",
			"label": "404035 - Holiday Socks"
	},
	{
			"value": "174601",
			"label": "174601 - Other Holiday"
	},
	{
			"value": "150520",
			"label": "150520 - Lic Christmas Pkgd"
	},
	{
			"value": "177065",
			"label": "177065 - Other Solids"
	},
	{
			"value": "326910",
			"label": "326910 - Spin Coats Loop"
	},
	{
			"value": "326911",
			"label": "326911 - LB Loop"
	},
	{
			"value": "342020",
			"label": "342020 - Rentals"
	},
	{
			"value": "106615",
			"label": "106615 - Cosplay EA"
	},
	{
			"value": "399525",
			"label": "399525 - Holiday Serveware"
	},
	{
			"value": "116615",
			"label": "116615 - Summer Arrangements"
	},
	{
			"value": "116760",
			"label": "116760 - Summer Accessories"
	},
	{
			"value": "116506",
			"label": "116506 - Summer Stems"
	},
	{
			"value": "116502",
			"label": "116502 - Summer Bushes"
	},
	{
			"value": "116509",
			"label": "116509 - Summer Picks"
	},
	{
			"value": "196570",
			"label": "196570 - Summer Craft"
	},
	{
			"value": "197034",
			"label": "197034 - Kids Easter"
	},
	{
			"value": "106665",
			"label": "106665 - Cosplay BTY"
	},
	{
			"value": "116607",
			"label": "116607 - Summer Garlands"
	},
	{
			"value": "170007",
			"label": "170007 - Diversity/ Inclusion"
	},
	{
			"value": "197316",
			"label": "197316 - Lego"
	},
	{
			"value": "394055",
			"label": "394055 - Halloween Pmpk Crvbl"
	},
	{
			"value": "394050",
			"label": "394050 - Halloween Pumpkins"
	},
	{
			"value": "394207",
			"label": "394207 - Hallown Outdr Dcor"
	},
	{
			"value": "394060",
			"label": "394060 - Halloween Trend"
	},
	{
			"value": "179625",
			"label": "179625 - EVA Foam"
	},
	{
			"value": "326533",
			"label": "326533 - LB Basic Stitch"
	},
	{
			"value": "117008",
			"label": "117008 - Basic Bushes"
	},
	{
			"value": "149003",
			"label": "149003 - Pure Plush"
	},
	{
			"value": "300113",
			"label": "300113 - Diamond Dotz Kits"
	},
	{
			"value": "197172",
			"label": "197172 - Diamond Dots"
	},
	{
			"value": "300114",
			"label": "300114 - DiamondDotz OS & Acc"
	},
	{
			"value": "315901",
			"label": "315901 - Spring/Summr Jewelry"
	},
	{
			"value": "311301",
			"label": "311301 - Cricut Blanks"
	},
	{
			"value": "401590",
			"label": "401590 - Spring Trend"
	},
	{
			"value": "148715",
			"label": "148715 - Music"
	},
	{
			"value": "188009",
			"label": "188009 - Kids Storage"
	},
	{
			"value": "225005",
			"label": "225005 - Sew Tech Warranties"
	},
	{
			"value": "311203",
			"label": "311203 - Cricut SmartMaterial"
	},
	{
			"value": "148301",
			"label": "148301 - State Pride Cotton"
	},
	{
			"value": "394765",
			"label": "394765 - Autumn Mats & Flags"
	},
	{
			"value": "394645",
			"label": "394645 - Autumn Towels"
	},
	{
			"value": "326712",
			"label": "326712 - PL Blanket"
	},
	{
			"value": "394057",
			"label": "394057 - Halloween Lighting"
	},
	{
			"value": "394211",
			"label": "394211 - Hallwn Outdr Electrc"
	},
	{
			"value": "326711",
			"label": "326711 - LB Blanket"
	},
	{
			"value": "394265",
			"label": "394265 - Hallown Mats & Flags"
	},
	{
			"value": "123001",
			"label": "123001 - Halloween Finished"
	},
	{
			"value": "123002",
			"label": "123002 - Halloween Components"
	},
	{
			"value": "399520",
			"label": "399520 - Holiday Mugs"
	},
	{
			"value": "394610",
			"label": "394610 - Autumn Mugs"
	},
	{
			"value": "394110",
			"label": "394110 - Halloween Mugs"
	},
	{
			"value": "401610",
			"label": "401610 - Spring Mugs"
	},
	{
			"value": "148302",
			"label": "148302 - State Pride EA"
	},
	{
			"value": "225010",
			"label": "225010 - Craft Tech Warrantie"
	},
	{
			"value": "321001",
			"label": "321001 - Siser Vinyl&Supplies"
	},
	{
			"value": "401595",
			"label": "401595 - Spring Bedding"
	},
	{
			"value": "403030",
			"label": "403030 - Summer Bedding"
	},
	{
			"value": "410210",
			"label": "410210 - Entertaining"
	},
	{
			"value": "410215",
			"label": "410215 - Lighting"
	},
	{
			"value": "443001",
			"label": "443001 - Patterns EA"
	},
	{
			"value": "443002",
			"label": "443002 - Subscriptions EA"
	},
	{
			"value": "443003",
			"label": "443003 - Physical Items EA"
	},
	{
			"value": "158510",
			"label": "158510 - Specialty"
	},
	{
			"value": "197227",
			"label": "197227 - You Can Do It"
	},
	{
			"value": "170303",
			"label": "170303 - Premium Solids"
	},
	{
			"value": "327112",
			"label": "327112 - LB Cotton"
	},
	{
			"value": "321102",
			"label": "321102 - Oracal"
	},
	{
			"value": "220103",
			"label": "220103 - Other Sewing Tech"
	},
	{
			"value": "220201",
			"label": "220201 - DITTO MACHINES"
	},
	{
			"value": "220202",
			"label": "220202 - DITTO ACCESSORIES"
	},
	{
			"value": "321002",
			"label": "321002 - Siser Machines"
	},
	{
			"value": "117006",
			"label": "117006 - Basic Wreaths and Ga"
	},
	{
			"value": "460010",
			"label": "460010 - Fabany_Customizer EA"
	},
	{
			"value": "326606",
			"label": "326606 - PL Pound Plus"
	},
	{
			"value": "303050",
			"label": "303050 - Beauty"
	},
	{
			"value": "126750",
			"label": "126750 - Filler"
	}
]

const CATEGORY_OPTIONS = [
	{
			"value": "3405",
			"label": "3405 - Other"
	},
	{
			"value": "1870",
			"label": "1870 - Zippers"
	},
	{
			"value": "1605",
			"label": "1605 - Nettings & Tulle"
	},
	{
			"value": "1493",
			"label": "1493 - Specialty Fleece"
	},
	{
			"value": "1788",
			"label": "1788 - Interfacings"
	},
	{
			"value": "1565",
			"label": "1565 - Sportswear"
	},
	{
			"value": "1783",
			"label": "1783 - Muslin/Burlap"
	},
	{
			"value": "1770",
			"label": "1770 - Cotton Solids"
	},
	{
			"value": "1830",
			"label": "1830 - Thread"
	},
	{
			"value": "1700",
			"label": "1700 - Cotton Prints"
	},
	{
			"value": "1005",
			"label": "1005 - Vinyl/Utility"
	},
	{
			"value": "1800",
			"label": "1800 - Cutting Implements"
	},
	{
			"value": "1535",
			"label": "1535 - Knits"
	},
	{
			"value": "1510",
			"label": "1510 - Linings"
	},
	{
			"value": "2130",
			"label": "2130 - Sewing Notions"
	},
	{
			"value": "2195",
			"label": "2195 - Quilting Notions"
	},
	{
			"value": "1062",
			"label": "1062 - HD Trims"
	},
	{
			"value": "1072",
			"label": "1072 - Home Decor Hardware"
	},
	{
			"value": "1790",
			"label": "1790 - Pillow Forms"
	},
	{
			"value": "1792",
			"label": "1792 - Stuffing"
	},
	{
			"value": "1794",
			"label": "1794 - Batting"
	},
	{
			"value": "1796",
			"label": "1796 - Foam"
	},
	{
			"value": "1440",
			"label": "1440 - Publications"
	},
	{
			"value": "1890",
			"label": "1890 - Home Storage"
	},
	{
			"value": "3105",
			"label": "3105 - Papercrafting Storage"
	},
	{
			"value": "3108",
			"label": "3108 - Papercrafting Tools"
	},
	{
			"value": "3104",
			"label": "3104 - Embellishments"
	},
	{
			"value": "1930",
			"label": "1930 - Tapes & Braids"
	},
	{
			"value": "3290",
			"label": "3290 - Stitch & Accessories"
	},
	{
			"value": "1972",
			"label": "1972 - Kids POP"
	},
	{
			"value": "3250",
			"label": "3250 - Stamping"
	},
	{
			"value": "3100",
			"label": "3100 - Paper"
	},
	{
			"value": "3030",
			"label": "3030 - Everyday"
	},
	{
			"value": "4055",
			"label": "4055 - Snacks & Candy"
	},
	{
			"value": "2115",
			"label": "2115 - Buttons"
	},
	{
			"value": "1895",
			"label": "1895 - Lighting"
	},
	{
			"value": "3110",
			"label": "3110 - Glue & Adhesives"
	},
	{
			"value": "3156",
			"label": "3156 - Jewelry Making Supplies"
	},
	{
			"value": "1960",
			"label": "1960 - Components & Bases"
	},
	{
			"value": "3115",
			"label": "3115 - Hobby"
	},
	{
			"value": "3172",
			"label": "3172 - Food"
	},
	{
			"value": "3170",
			"label": "3170 - Seasonal Food"
	},
	{
			"value": "3176",
			"label": "3176 - Bakeware, Tools & Supplies"
	},
	{
			"value": "3005",
			"label": "3005 - Paint & Finishes"
	},
	{
			"value": "3230",
			"label": "3230 - Clay, Sculpt & Resin"
	},
	{
			"value": "4325",
			"label": "4325 - Craft - Misc"
	},
	{
			"value": "1945",
			"label": "1945 - Styrofoam"
	},
	{
			"value": "1971",
			"label": "1971 - Craft Activities"
	},
	{
			"value": "3001",
			"label": "3001 - Other Surfaces"
	},
	{
			"value": "1695",
			"label": "1695 - Quilt Patterns"
	},
	{
			"value": "1066",
			"label": "1066 - Apparel Trims"
	},
	{
			"value": "1704",
			"label": "1704 - Cotton Packaged"
	},
	{
			"value": "1952",
			"label": "1952 - Textile Colorants"
	},
	{
			"value": "2075",
			"label": "2075 - Basic Core Ribbon"
	},
	{
			"value": "2201",
			"label": "2201 - Sewing Supplies"
	},
	{
			"value": "3158",
			"label": "3158 - Jewelry Components"
	},
	{
			"value": "3022",
			"label": "3022 - Fine Art Painting"
	},
	{
			"value": "3272",
			"label": "3272 - Yarn Tools"
	},
	{
			"value": "4040",
			"label": "4040 - Seasonal Socks"
	},
	{
			"value": "1498",
			"label": "1498 - College"
	},
	{
			"value": "1002",
			"label": "1002 - ROT POG"
	},
	{
			"value": "1875",
			"label": "1875 - Craft Storage"
	},
	{
			"value": "1880",
			"label": "1880 - General Storage"
	},
	{
			"value": "1885",
			"label": "1885 - Sewing Storage"
	},
	{
			"value": "1750",
			"label": "1750 - Nursery"
	},
	{
			"value": "1748",
			"label": "1748 - Packaged Holiday"
	},
	{
			"value": "1530",
			"label": "1530 - Specialty Cotton & Linens"
	},
	{
			"value": "3915",
			"label": "3915 - Seasonal Impulse"
	},
	{
			"value": "3000",
			"label": "3000 - Wood Crafting"
	},
	{
			"value": "1402",
			"label": "1402 - Frames"
	},
	{
			"value": "1760",
			"label": "1760 - Cotton Canvas"
	},
	{
			"value": "3154",
			"label": "3154 - Beads"
	},
	{
			"value": "1170",
			"label": "1170 - Basic Floral Components"
	},
	{
			"value": "3270",
			"label": "3270 - Natural Yarn"
	},
	{
			"value": "3271",
			"label": "3271 - Craft Cotton Yarn"
	},
	{
			"value": "3266",
			"label": "3266 - Value Yarn"
	},
	{
			"value": "3265",
			"label": "3265 - Classic Yarn"
	},
	{
			"value": "3268",
			"label": "3268 - Baby Yarn"
	},
	{
			"value": "1973",
			"label": "1973 - Toy / Impulse"
	},
	{
			"value": "1640",
			"label": "1640 - Simplicity Patterns"
	},
	{
			"value": "3021",
			"label": "3021 - Fine Art Illustration"
	},
	{
			"value": "1775",
			"label": "1775 - Flannel"
	},
	{
			"value": "1825",
			"label": "1825 - Sewing Room Basics"
	},
	{
			"value": "1601",
			"label": "1601 - Costuming"
	},
	{
			"value": "1600",
			"label": "1600 - Special Occasion Collections"
	},
	{
			"value": "4017",
			"label": "4017 - Spring Outdoor"
	},
	{
			"value": "1267",
			"label": "1267 - ArrangingSupplie&Acc"
	},
	{
			"value": "1500",
			"label": "1500 - Silkies"
	},
	{
			"value": "4035",
			"label": "4035 - Seasonal Candy"
	},
	{
			"value": "1703",
			"label": "1703 - Cotton Premium Prints"
	},
	{
			"value": "1745",
			"label": "1745 - Patriotic"
	},
	{
			"value": "3020",
			"label": "3020 - Fine Art Surfaces"
	},
	{
			"value": "3113",
			"label": "3113 - Cricut Accessories"
	},
	{
			"value": "1092",
			"label": "1092 - Outdoor"
	},
	{
			"value": "1970",
			"label": "1970 - Kids Seasonal"
	},
	{
			"value": "2200",
			"label": "2200 - Sewing Machines"
	},
	{
			"value": "1951",
			"label": "1951 - Apparel"
	},
	{
			"value": "1655",
			"label": "1655 - Vogue Patterns"
	},
	{
			"value": "1705",
			"label": "1705 - Christmas"
	},
	{
			"value": "1505",
			"label": "1505 - Seasonal"
	},
	{
			"value": "1162",
			"label": "1162 - Holiday Floral Accessories"
	},
	{
			"value": "1607",
			"label": "1607 - Casa Collection"
	},
	{
			"value": "1497",
			"label": "1497 - MLB"
	},
	{
			"value": "1710",
			"label": "1710 - Halloween"
	},
	{
			"value": "3106",
			"label": "3106 - Papercrafting Designers"
	},
	{
			"value": "3116",
			"label": "3116 - Tape & Mailing"
	},
	{
			"value": "1496",
			"label": "1496 - NFL"
	},
	{
			"value": "3103",
			"label": "3103 - Stationery & Party"
	},
	{
			"value": "3109",
			"label": "3109 - Seasonal Paper Crafting"
	},
	{
			"value": "1690",
			"label": "1690 - Fashion Fusion"
	},
	{
			"value": "1645",
			"label": "1645 - McCalls Patterns"
	},
	{
			"value": "3211",
			"label": "3211 - Other Tech"
	},
	{
			"value": "4310",
			"label": "4310 - Fabric - Misc"
	},
	{
			"value": "1720",
			"label": "1720 - Valentines"
	},
	{
			"value": "4119",
			"label": "4119 - Candles"
	},
	{
			"value": "1036",
			"label": "1036 - Home Dec Fabric EA"
	},
	{
			"value": "4102",
			"label": "4102 - Home Decor"
	},
	{
			"value": "3111",
			"label": "3111 - Cricut Machines"
	},
	{
			"value": "1650",
			"label": "1650 - Butterick Patterns"
	},
	{
			"value": "1480",
			"label": "1480 - Blizzard Fleece"
	},
	{
			"value": "1491",
			"label": "1491 - Fleece"
	},
	{
			"value": "1487",
			"label": "1487 - Cotton"
	},
	{
			"value": "3415",
			"label": "3415 - Education"
	},
	{
			"value": "1427",
			"label": "1427 - Custom Framing"
	},
	{
			"value": "4330",
			"label": "4330 - Services/Misc"
	},
	{
			"value": "4348",
			"label": "4348 - Home / Everyday - Misc"
	},
	{
			"value": "4335",
			"label": "4335 - Seasonal / Floral - Misc"
	},
	{
			"value": "3410",
			"label": "3410 - Gift Cards"
	},
	{
			"value": "1555",
			"label": "1555 - Athletic Wear"
	},
	{
			"value": "4305",
			"label": "4305 - Models - Misc"
	},
	{
			"value": "1229",
			"label": "1229 - Fall Floral Accessories"
	},
	{
			"value": "1776",
			"label": "1776 - Fashion Flannel"
	},
	{
			"value": "3946",
			"label": "3946 - Autumn Entertaining"
	},
	{
			"value": "3941",
			"label": "3941 - Halloween Entertaining"
	},
	{
			"value": "3955",
			"label": "3955 - Holiday Decor"
	},
	{
			"value": "3995",
			"label": "3995 - Holiday Entertaining"
	},
	{
			"value": "1482",
			"label": "1482 - AP Plush Fleece"
	},
	{
			"value": "4090",
			"label": "4090 - Summer Outdoor"
	},
	{
			"value": "4030",
			"label": "4030 - Summer Decor"
	},
	{
			"value": "3947",
			"label": "3947 - Autumn Outdoor"
	},
	{
			"value": "3945",
			"label": "3945 - Autumn Decor"
	},
	{
			"value": "1715",
			"label": "1715 - Harvest"
	},
	{
			"value": "3960",
			"label": "3960 - Seasonal Ribbon"
	},
	{
			"value": "1151",
			"label": "1151 - Spring Floral Finished"
	},
	{
			"value": "3980",
			"label": "3980 - Holiday Tree Decor"
	},
	{
			"value": "3990",
			"label": "3990 - Holiday Giftwrap"
	},
	{
			"value": "1147",
			"label": "1147 - Bath/Body Care"
	},
	{
			"value": "1150",
			"label": "1150 - Spring Floral Components"
	},
	{
			"value": "1155",
			"label": "1155 - Fall Floral Components"
	},
	{
			"value": "1160",
			"label": "1160 - Holiday Floral Components"
	},
	{
			"value": "1488",
			"label": "1488 - No Sew Throws"
	},
	{
			"value": "4045",
			"label": "4045 - Beverage"
	},
	{
			"value": "3940",
			"label": "3940 - Halloween Decor"
	},
	{
			"value": "1112",
			"label": "1112 - Value Flat Fold"
	},
	{
			"value": "1520",
			"label": "1520 - Juvenile Apparel"
	},
	{
			"value": "1575",
			"label": "1575 - Fashion Apparel Collections"
	},
	{
			"value": "1725",
			"label": "1725 - St Patricks"
	},
	{
			"value": "1730",
			"label": "1730 - Easter"
	},
	{
			"value": "4015",
			"label": "4015 - Spring Decor"
	},
	{
			"value": "1152",
			"label": "1152 - Spring Floral Accessories"
	},
	{
			"value": "1490",
			"label": "1490 - Plush"
	},
	{
			"value": "1499",
			"label": "1499 - NBA"
	},
	{
			"value": "1495",
			"label": "1495 - License PKG"
	},
	{
			"value": "3925",
			"label": "3925 - Valentine's Day"
	},
	{
			"value": "3930",
			"label": "3930 - St. Patrick's Day"
	},
	{
			"value": "3935",
			"label": "3935 - Easter/Spring"
	},
	{
			"value": "1475",
			"label": "1475 - Team Pkgd"
	},
	{
			"value": "3112",
			"label": "3112 - Cricut Cutting Material"
	},
	{
			"value": "3267",
			"label": "3267 - Blanket Yarn"
	},
	{
			"value": "1428",
			"label": "1428 - Custom Framing Merchandise"
	},
	{
			"value": "1680",
			"label": "1680 - Non Consignment Patterns"
	},
	{
			"value": "1228",
			"label": "1228 - Fall Floral Finished"
	},
	{
			"value": "1492",
			"label": "1492 - NHL"
	},
	{
			"value": "4070",
			"label": "4070 - Summer Entertaining"
	},
	{
			"value": "1965",
			"label": "1965 - Seasonal Craft"
	},
	{
			"value": "4016",
			"label": "4016 - Spring Entertaining"
	},
	{
			"value": "3101",
			"label": "3101 - Journaling"
	},
	{
			"value": "4080",
			"label": "4080 - Americana"
	},
	{
			"value": "1161",
			"label": "1161 - Holiday Floral Finished"
	},
	{
			"value": "3965",
			"label": "3965 - Holiday Outdoor"
	},
	{
			"value": "1166",
			"label": "1166 - Summer Floral Finished"
	},
	{
			"value": "3416",
			"label": "3416 - Custom Shop"
	},
	{
			"value": "4410",
			"label": "4410 - Boss"
	},
	{
			"value": "4420",
			"label": "4420 - Creative Bug"
	},
	{
			"value": "1167",
			"label": "1167 - Summer Floral Accessories"
	},
	{
			"value": "3159",
			"label": "3159 - Seasonal Jewelry"
	},
	{
			"value": "1635",
			"label": "1635 - Custom"
	},
	{
			"value": "1746",
			"label": "1746 - Other Holiday"
	},
	{
			"value": "3269",
			"label": "3269 - Loop Yarn"
	},
	{
			"value": "3420",
			"label": "3420 - Services"
	},
	{
			"value": "1165",
			"label": "1165 - Summer Floral Components"
	},
	{
			"value": "3942",
			"label": "3942 - Halloween Outdoor"
	},
	{
			"value": "2250",
			"label": "2250 - Warranties"
	},
	{
			"value": "1483",
			"label": "1483 - State Pride"
	},
	{
			"value": "1230",
			"label": "1230 - Halloween Floral"
	},
	{
			"value": "3210",
			"label": "3210 - Siser"
	},
	{
			"value": "4430",
			"label": "4430 - Special Projects"
	},
	{
			"value": "1585",
			"label": "1585 - Off Price Fabric"
	},
	{
			"value": "2202",
			"label": "2202 - DITTO"
	},
	{
			"value": "4600",
			"label": "4600 - Fabany"
	}
]

const SHOP_DESCRIPTION_OPTIONS = [
  {
    "value": "L5830",
    "label": "L5830 - L5830 Other"
  },
  {
    "value": "L5670",
    "label": "L5670 - Sewing Construction"
  },
  {
    "value": "L5700",
    "label": "L5700 - Special Occasion"
  },
  {
    "value": "L5693",
    "label": "L5693 - Fleece Shoppe"
  },
  {
    "value": "L5695",
    "label": "L5695 - Fashion Apparel Fabrics"
  },
  {
    "value": "L5710",
    "label": "L5710 - Cotton Shop"
  },
  {
    "value": "L5761",
    "label": "L5761 - Home Dec Fabric"
  },
  {
    "value": "L5766",
    "label": "L5766 - Trims"
  },
  {
    "value": "L5776",
    "label": "L5776 - Foam/Fiber"
  },
  {
    "value": "L5649",
    "label": "L5649 - Front End & Impulse"
  },
  {
    "value": "L5664",
    "label": "L5664 - Storage"
  },
  {
    "value": "L5655",
    "label": "L5655 - Papercrafting Supplies"
  },
  {
    "value": "L5660",
    "label": "L5660 - Needle Arts"
  },
  {
    "value": "L5675",
    "label": "L5675 - Kids Crafts"
  },
  {
    "value": "L5672",
    "label": "L5672 - Sewing Tech"
  },
  {
    "value": "L5640",
    "label": "L5640 - Craft Components"
  },
  {
    "value": "L5343",
    "label": "L5343 - Jewelry"
  },
  {
    "value": "L5780",
    "label": "L5780 - Craft Textiles"
  },
  {
    "value": "L5642",
    "label": "L5642 - Foodcrafting Shop"
  },
  {
    "value": "L5645",
    "label": "L5645 - Decorative Painting"
  },
  {
    "value": "L5605",
    "label": "L5605 - Traditional Craft - Misc"
  },
  {
    "value": "L5730",
    "label": "L5730 - Patterns"
  },
  {
    "value": "L5680",
    "label": "L5680 - Ribbon"
  },
  {
    "value": "L5646",
    "label": "L5646 - Fine Art Supplies"
  },
  {
    "value": "L5784",
    "label": "L5784 - Team"
  },
  {
    "value": "L5715",
    "label": "L5715 - Warm Shop"
  },
  {
    "value": "L5705",
    "label": "L5705 - Holiday"
  },
  {
    "value": "L5746",
    "label": "L5746 - Frames"
  },
  {
    "value": "L5818",
    "label": "L5818 - Basic Floral"
  },
  {
    "value": "L5804",
    "label": "L5804 - Spring Decor"
  },
  {
    "value": "L5650",
    "label": "L5650 - Craft Technology"
  },
  {
    "value": "L5785",
    "label": "L5785 - Licensed Shop"
  },
  {
    "value": "L5817",
    "label": "L5817 - Holiday Floral"
  },
  {
    "value": "L5585",
    "label": "L5585 - Fabric - Misc"
  },
  {
    "value": "L5750",
    "label": "L5750 - Education Programs"
  },
  {
    "value": "L5751",
    "label": "L5751 - Custom Framing"
  },
  {
    "value": "L5610",
    "label": "L5610 - Education-Misc"
  },
  {
    "value": "L5626",
    "label": "L5626 - Home / Everyday - Misc"
  },
  {
    "value": "L5615",
    "label": "L5615 - Seasonal / Floral - Misc"
  },
  {
    "value": "L5816",
    "label": "L5816 - Fall Floral"
  },
  {
    "value": "L5795",
    "label": "L5795 - Autumn Decor"
  },
  {
    "value": "L5790",
    "label": "L5790 - Halloween Decor"
  },
  {
    "value": "L5800",
    "label": "L5800 - Holiday Decor"
  },
  {
    "value": "L5805",
    "label": "L5805 - Summer Decor"
  },
  {
    "value": "L5815",
    "label": "L5815 - Spring/Summer Floral"
  },
  {
    "value": "L5788",
    "label": "L5788 - Mini Seasons"
  },
  {
    "value": "L5410",
    "label": "L5410 - Boss"
  },
  {
    "value": "L5420",
    "label": "L5420 - Creative Bug"
  },
  {
    "value": "L5430",
    "label": "L5430 - Special Projects"
  },
  {
    "value": "L5460",
    "label": "L5460 - Fabany"
  }
 ]

const AREA_OPTIONS = [
	{ "value": "L404", "label": "L404" },
	{ "value": "L406", "label": "L406" },
	{ "value": "L412", "label": "L412" },
	{ "value": "L413", "label": "L413" },
	{ "value": "L415", "label": "L415" },
	{ "value": "L416", "label": "L416" },
	{ "value": "L424", "label": "L424" },
	{ "value": "L429", "label": "L429" },
	{ "value": "L431", "label": "L431" },
	{ "value": "L432", "label": "L432" },
	{ "value": "L436", "label": "L436" },
	{ "value": "L442", "label": "L442" },
	{ "value": "L443", "label": "L443" },
	{ "value": "L445", "label": "L445" },
	{ "value": "L446", "label": "L446" },
	{ "value": "L448", "label": "L448" },
	{ "value": "L462", "label": "L462" },
	{ "value": "L468", "label": "L468" },
	{ "value": "L476", "label": "L476" },
	{ "value": "L477", "label": "L477" },
	{ "value": "L487", "label": "L487" },
	{ "value": "L489", "label": "L489" },
	{ "value": "L492", "label": "L492" }
]

const BUSINESS_OPTIONS = [
	{
		"value": "L3110",
		"label": "L3110 - Basic Sewing"
	},
	{
		"value": "L3100",
		"label": "L3100 - Fabric Sewing"
	},
	{
		"value": "L3510",
		"label": "L3510 - Home"
	},
	{
		"value": "L3300",
		"label": "L3300 - Craft"
	},
	{
		"value": "L3500",
		"label": "L3500 - Seasonal"
	},
	{
		"value": "L3020",
		"label": "L3020 - Other Stuff"
	},
	{
		"value": "L3400",
		"label": "L3400 - Custom Businesses"
	},
	{
		"value": "L3410",
		"label": "L3410 - Boss"
	},
	{
		"value": "L3420",
		"label": "L3420 - Creative Bug"
	},
	{
		"value": "L3560",
		"label": "L3560 - Fabany"
	},
	{
		"value": "L4300",
		"label": "L4300 - Special Projects"
	}
 ]


// L3100
// L4300
// L3450
const CUSTOMERGROUP_OPTIONS = [
		{
			"value": "L21",
			"label": "L21 - Sewing"
		},
		{
			"value": "L22",
			"label": "L22 - Crafts"
		},
		{
			"value": "L23",
			"label": "L23 - Home/Seasonal"
		},
		{
			"value": "L24",
			"label": "L24 - Other Stuff"
		},
		{
			"value": "L28",
			"label": "L28 - Frames/Services"
		}
]

// select p.vendor_id, v.vendor_code,p.vendor_name , concat(v.vendor_code , ' - ', p.vendor_name)  from products p join vendors as v on
// p.vendor_id = v.id
// group by p.vendor_id ,v.vendor_code, p.vendor_name 
// order by vendor_code;
const VENDOR_OPTIONS = [
	{
			"value": 339,
			"label": "104341 - TIMELESS TREASURES FABRICS OF SOHO"
	},
	{
			"value": 967,
			"label": "104354 - MARY ELLEN PRODUCTS"
	},
	{
			"value": 131,
			"label": "104388 - NIPKOW & KOBELT INC"
	},
	{
			"value": 736,
			"label": "104411 - NOVTEX"
	},
	{
			"value": 304,
			"label": "104413 - BERWICK OFFRAY LLC"
	},
	{
			"value": 962,
			"label": "104414 - CORELLE BRANDS"
	},
	{
			"value": 763,
			"label": "104426 - P/KAUFMANN"
	},
	{
			"value": 56,
			"label": "104430 - LAROSE INDUSTRIES LLC DBA"
	},
	{
			"value": 487,
			"label": "104444 - PERLER"
	},
	{
			"value": 141,
			"label": "104449 - PLAID ENTERPRISES"
	},
	{
			"value": 682,
			"label": "104454 - PLUS MARK LLC"
	},
	{
			"value": 639,
			"label": "104469 - RAMCO ARTS INC"
	},
	{
			"value": 963,
			"label": "104484 - RHODE ISLAND TEXTILE"
	},
	{
			"value": 303,
			"label": "104488 - THE ROBERT ALLEN GROUP"
	},
	{
			"value": 38,
			"label": "104489 - ROBERT KAUFMAN CO INC"
	},
	{
			"value": 457,
			"label": "104492 - ROCKLAND INDUSTRIES INC"
	},
	{
			"value": 320,
			"label": "104507 - SANTEE PRINT WORKS"
	},
	{
			"value": 769,
			"label": "104545 - SPRADLING INTERNATIONAL INC"
	},
	{
			"value": 773,
			"label": "104561 - SWAVELLE/MILL CREEK FABRICS"
	},
	{
			"value": 417,
			"label": "104565 - SULKY OF AMERICA"
	},
	{
			"value": 245,
			"label": "104566 - ADVANTUS CORP / SULYN INDUSTRIES"
	},
	{
			"value": 620,
			"label": "104567 - COATS & CLARK SALES - SUSAN BATES"
	},
	{
			"value": 791,
			"label": "104590 - THERM O WEB INC"
	},
	{
			"value": 146,
			"label": "104594 - 3M COMPANY"
	},
	{
			"value": 307,
			"label": "104603 - TRIMTEX COMPANY INC"
	},
	{
			"value": 228,
			"label": "104614 - VELCRO USA INC"
	},
	{
			"value": 326,
			"label": "104616 - VIP FABRICS / CRANSTON"
	},
	{
			"value": 571,
			"label": "104623 - WALNUT HOLLOW"
	},
	{
			"value": 216,
			"label": "104629 - WARM PRODUCTS INC"
	},
	{
			"value": 562,
			"label": "104633 - WESTRIM CRAFTS"
	},
	{
			"value": 97,
			"label": "104640 - WM WRIGHT CO"
	},
	{
			"value": 106,
			"label": "104645 - ADVANTUS CORP"
	},
	{
			"value": 156,
			"label": "104652 - AIRTEX INDUSTRIES INC"
	},
	{
			"value": 301,
			"label": "104653 - AISIN USA INC"
	},
	{
			"value": 548,
			"label": "104657 - ADAMS MANUFACTURING"
	},
	{
			"value": 2300,
			"label": "104658 - ADHESIVE TECHNOLOGIES"
	},
	{
			"value": 757,
			"label": "104678 - THE ROBERT ALLEN GROUP"
	},
	{
			"value": 756,
			"label": "104692 - BARROW INDUSTRIES INC"
	},
	{
			"value": 256,
			"label": "104693 - BARTSON FABRICS INC"
	},
	{
			"value": 431,
			"label": "104695 - BAUM TEXTILE MILLS INC"
	},
	{
			"value": 312,
			"label": "104710 - BERWICK OFFRAY LLC"
	},
	{
			"value": 732,
			"label": "104719 - BERWICK OFFRAY LLC DBA"
	},
	{
			"value": 634,
			"label": "104747 - CANDAMAR DESIGNS INC"
	},
	{
			"value": 1066,
			"label": "104753 - CAROLACE EMBROIDERY"
	},
	{
			"value": 627,
			"label": "104758 - CHARLES CRAFT"
	},
	{
			"value": 85,
			"label": "104767 - COATS & CLARK"
	},
	{
			"value": 917,
			"label": "104783 - CRAFTWOOD DIV OF CINDOCO WOOD"
	},
	{
			"value": 607,
			"label": "104798 - DMC CORPORATION"
	},
	{
			"value": 641,
			"label": "104805 - DAL-CRAFT INC"
	},
	{
			"value": 183,
			"label": "104807 - DECOART INC"
	},
	{
			"value": 14,
			"label": "104808 - DARICE INC"
	},
	{
			"value": 523,
			"label": "104816 - DESIGN WORKS CRAFTS"
	},
	{
			"value": 486,
			"label": "104817 - DIMENSIONS CRAFTS LLC"
	},
	{
			"value": 3,
			"label": "104825 - PRYM CONSUMER USA INC"
	},
	{
			"value": 398282,
			"label": "104827 - PAISLEY CRAFTS LLC DBA I LOVE TO CR"
	},
	{
			"value": 263,
			"label": "104861 - FABRIC TRADITIONS"
	},
	{
			"value": 462,
			"label": "104867 - FABRI-QUILT INC"
	},
	{
			"value": 241328,
			"label": "104868 - FAIRFIELD PROCESSING CORP"
	},
	{
			"value": 589,
			"label": "104874 - CONSUMER PRODUCTS ENTERPRISE"
	},
	{
			"value": 43,
			"label": "104875 - FIBRE CRAFT LLC"
	},
	{
			"value": 6,
			"label": "104877 - FLORACRAFT CORPORATION"
	},
	{
			"value": 482,
			"label": "104878 - FISKARS BRAND INC"
	},
	{
			"value": 2423,
			"label": "104888 - FOSS MANUFACTURING COMPANY\"\"DONOTUSE"
	},
	{
			"value": 868,
			"label": "104906 - GINGHER INC"
	},
	{
			"value": 959,
			"label": "104915 - GUTERMANN OF AMERICA"
	},
	{
			"value": 965,
			"label": "104930 - HTCW INC"
	},
	{
			"value": 118288,
			"label": "104940 - TIMELESS TREASURES FABRIC"
	},
	{
			"value": 365,
			"label": "104944 - HIRSCHBERG SCHUTZ & CO"
	},
	{
			"value": 642,
			"label": "104949 - KAHOOT PRODUCTS INC"
	},
	{
			"value": 966,
			"label": "104970 - JHB INTERNATIONAL"
	},
	{
			"value": 471,
			"label": "104974 - JAMES THOMPSON"
	},
	{
			"value": 531,
			"label": "104977 - SPECTRUM CRAFTS"
	},
	{
			"value": 1097,
			"label": "105003 - NOVA WILDCAT DRAPERY HARDWARE LLC"
	},
	{
			"value": 625,
			"label": "105014 - KREINIK MFG"
	},
	{
			"value": 271,
			"label": "105032 - LEISURE ARTS INC"
	},
	{
			"value": 82,
			"label": "105043 - LION BRAND YARN CO"
	},
	{
			"value": 194,
			"label": "105044 - HENKEL CORPORATION"
	},
	{
			"value": 95,
			"label": "105045 - LOEW-CORNELL"
	},
	{
			"value": 1038,
			"label": "105046 - LOGANTEX INC"
	},
	{
			"value": 796,
			"label": "105661 - UNIQUE QUALITY FABRICS"
	},
	{
			"value": 1096,
			"label": "105728 - TRI VANTAGE LLC"
	},
	{
			"value": 761,
			"label": "105740 - RICHLOOM FABRICS"
	},
	{
			"value": 138,
			"label": "106340 - SPRINGS CREATIVE PRODUCTS GROUP"
	},
	{
			"value": 204,
			"label": "106383 - GRAND & BENEDICTS INC"
	},
	{
			"value": 759,
			"label": "106517 - KITTRICH CORPORATION"
	},
	{
			"value": 27,
			"label": "106541 - GENERAL FABRICS COMPANY"
	},
	{
			"value": 467,
			"label": "106548 - FABRIC COUNTRY"
	},
	{
			"value": 570,
			"label": "106636 - KELLY'S CRAFTS"
	},
	{
			"value": 472,
			"label": "106659 - TEMPO DRAPERY & FABRICS"
	},
	{
			"value": 188,
			"label": "107790 - SIGNATURE MARKETING & MFG"
	},
	{
			"value": 615,
			"label": "108061 - GRANDE IMPRESSIONS LTD"
	},
	{
			"value": 117,
			"label": "108153 - WILTON INDUSTRIES"
	},
	{
			"value": 960,
			"label": "108487 - AMERICAN & EFIRD LLC"
	},
	{
			"value": 163,
			"label": "108569 - ELMERS PRODUCTS INC"
	},
	{
			"value": 465,
			"label": "108871 - FELDMAN COMPANY INC"
	},
	{
			"value": 497,
			"label": "108934 - ZUCKER FEATHER PRODUCTS"
	},
	{
			"value": 631,
			"label": "109420 - GAY BOWLES SALES INC"
	},
	{
			"value": 81,
			"label": "109434 - SPINRITE CORP"
	},
	{
			"value": 825,
			"label": "109938 - RED RIVER VINE COMPANY"
	},
	{
			"value": 328,
			"label": "170007 - ALEXANDER HENRY"
	},
	{
			"value": 137,
			"label": "170026 - ZZ-HUDSON"
	},
	{
			"value": 8,
			"label": "170121 - FOURSTAR GROUP"
	},
	{
			"value": 196,
			"label": "170178 - JUNE TAILOR"
	},
	{
			"value": 476,
			"label": "170193 - FOSS PERFORMANCE MATERIALS LLC"
	},
	{
			"value": 777,
			"label": "170272 - PCT VINYL"
	},
	{
			"value": 2437,
			"label": "170368 - VENUS INDUSTRIES, LLC"
	},
	{
			"value": 768,
			"label": "170374 - WAVERLY"
	},
	{
			"value": 449,
			"label": "170435 - AMERICAN OAK PRESERVING CO INC"
	},
	{
			"value": 822,
			"label": "170459 - IJK LIMITED"
	},
	{
			"value": 4,
			"label": "170537 - C Y HUNG CO LTD"
	},
	{
			"value": 178,
			"label": "170564 - AMACO-AMERICAN ART CLAY CO"
	},
	{
			"value": 222,
			"label": "170566 - RUPERT GIBBON AND SPIDER INC"
	},
	{
			"value": 167,
			"label": "170571 - VIGOR INTERNATIONAL (HK) LTD"
	},
	{
			"value": 640,
			"label": "170601 - DANIEL ENTERPRISES"
	},
	{
			"value": 537,
			"label": "170608 - MCG TEXTILES"
	},
	{
			"value": 197,
			"label": "170731 - ECLECTIC PRODUCTS LLC"
	},
	{
			"value": 305,
			"label": "170762 - LEATHER FACTORY"
	},
	{
			"value": 811,
			"label": "170767 - HG GLOBAL LLC"
	},
	{
			"value": 977,
			"label": "170770 - PAULA JEAN CREATIONS"
	},
	{
			"value": 632,
			"label": "170776 - COLONIAL PATTERNS"
	},
	{
			"value": 729,
			"label": "170804 - YASUTOMO INC"
	},
	{
			"value": 723,
			"label": "170821 - UNION UNDERWEAR COMPANY INC"
	},
	{
			"value": 899,
			"label": "170824 - SAKURA OF AMERICA"
	},
	{
			"value": 968,
			"label": "170895 - MUNDIAL"
	},
	{
			"value": 412,
			"label": "170898 - COLE & ASHCROFT LP"
	},
	{
			"value": 784,
			"label": "170910 - WISCONSIN LIGHTING"
	},
	{
			"value": 971,
			"label": "170943 - YLI CORPORATION"
	},
	{
			"value": 25,
			"label": "170948 - STERILITE CORPORATION"
	},
	{
			"value": 961,
			"label": "170952 - AC MARKETECH"
	},
	{
			"value": 495,
			"label": "170968 - CRAFTY PRODUCTIONS"
	},
	{
			"value": 599,
			"label": "171001 - SHERWIN WILLIAMS - KRYLON"
	},
	{
			"value": 980,
			"label": "171003 - BERROCO INC"
	},
	{
			"value": 235,
			"label": "171021 - CLOVER NEEDLECRAFT INC"
	},
	{
			"value": 18,
			"label": "171030 - PEPPERELL BRAIDING"
	},
	{
			"value": 484,
			"label": "171035 - CRAYOLA LLC"
	},
	{
			"value": 1082,
			"label": "171036 - WOOL NOVELTY"
	},
	{
			"value": 532,
			"label": "171048 - ROYAL CONSUMER PRODUCTS LLC"
	},
	{
			"value": 375,
			"label": "171060 - UCHIDA OF AMERICA"
	},
	{
			"value": 199,
			"label": "171095 - ALLARY CORP"
	},
	{
			"value": 974,
			"label": "171097 - BLUE FEATHER PRODUCTS"
	},
	{
			"value": 35,
			"label": "171112 - DAVID TEXTILES INC"
	},
	{
			"value": 31,
			"label": "171190 - MALDEN INTERNATIONAL DESIGNS"
	},
	{
			"value": 24,
			"label": "171255 - NOTIONS MARKETING"
	},
	{
			"value": 823,
			"label": "171273 - NEW EAST TRADING CO LTD"
	},
	{
			"value": 775,
			"label": "171277 - BRAEMORE"
	},
	{
			"value": 212,
			"label": "171284 - UNIVERSAL CANDLE COMPANY LTD"
	},
	{
			"value": 704,
			"label": "171306 - GEMMY INDUSTRIES (HK) LIMITED"
	},
	{
			"value": 525,
			"label": "171308 - NSI INTERNATIONAL INC"
	},
	{
			"value": 800,
			"label": "171324 - GLOBAL BEST INDUSTRIAL LTD"
	},
	{
			"value": 744,
			"label": "171341 - LITTLE YELLOW BICYCLE"
	},
	{
			"value": 551,
			"label": "171357 - MASTER MAGNETICS INC"
	},
	{
			"value": 558,
			"label": "171358 - THE BEADERY C/O TONER PLASTICS"
	},
	{
			"value": 698,
			"label": "171375 - ALLSTATE FLORAL INC"
	},
	{
			"value": 369,
			"label": "171387 - TONER PLASTICS INC"
	},
	{
			"value": 21,
			"label": "171390 - ARTBIN BY FLAMBEAU"
	},
	{
			"value": 975,
			"label": "171432 - MAGIC AMERICAN"
	},
	{
			"value": 247,
			"label": "171436 - PLASTEEL CORP"
	},
	{
			"value": 1118,
			"label": "171450 - MRS GROSSMAN'S"
	},
	{
			"value": 616,
			"label": "171461 - BREWSTER WALLPAPER CORPORATION"
	},
	{
			"value": 355,
			"label": "171463 - GHI INC"
	},
	{
			"value": 2089,
			"label": "171476 - MSRF INC"
	},
	{
			"value": 70,
			"label": "171486 - EK SUCCESS"
	},
	{
			"value": 92,
			"label": "171505 - HENTON INTERNATIONAL LTD"
	},
	{
			"value": 103,
			"label": "171546 - PANACEA PRODUCTS CORPORATION"
	},
	{
			"value": 113,
			"label": "171558 - CRICUT INC"
	},
	{
			"value": 32,
			"label": "171559 - BP INDUSTRIES INC"
	},
	{
			"value": 1078,
			"label": "171567 - BURNES HOME ACCENTS"
	},
	{
			"value": 519,
			"label": "171568 - DRYBRANCH / SPORT DESIGN INC"
	},
	{
			"value": 239,
			"label": "171572 - MACPHERSONS"
	},
	{
			"value": 2411,
			"label": "171598 - TOMRIC PLASTICS, INC./MAKE'N MOLD"
	},
	{
			"value": 518,
			"label": "171643 - PAPER MAGIC GROUP INC"
	},
	{
			"value": 249,
			"label": "171648 - LARSON & JUHL"
	},
	{
			"value": 418,
			"label": "171650 - INKADINKADO"
	},
	{
			"value": 350,
			"label": "171663 - JESSE JAMES & CO INC"
	},
	{
			"value": 2421,
			"label": "171670 - BONFIT AMERICA, INC."
	},
	{
			"value": 93,
			"label": "171679 - CREATIVE DESIGN LTD"
	},
	{
			"value": 684,
			"label": "171703 - PACKAGING SOURCE"
	},
	{
			"value": 36,
			"label": "171705 - FABRIC EDITIONS INC"
	},
	{
			"value": 439,
			"label": "171760 - TRIPAR INTERNATIONAL INC"
	},
	{
			"value": 283,
			"label": "171784 - SUPPLYONE CLEVELAND INC"
	},
	{
			"value": 788,
			"label": "171799 - PORTFOLIO"
	},
	{
			"value": 506,
			"label": "171860 - GRAFIX ARTS"
	},
	{
			"value": 898,
			"label": "171872 - CANSON INC"
	},
	{
			"value": 583,
			"label": "171881 - LEARNING CURVE TOYS"
	},
	{
			"value": 149,
			"label": "171887 - SAM PIEVAC COMPANY"
	},
	{
			"value": 1001,
			"label": "171890 - HERO ARTS"
	},
	{
			"value": 66,
			"label": "171906 - KLUTZ INC"
	},
	{
			"value": 224,
			"label": "171916 - PIONEER PHOTO ALBUMS"
	},
	{
			"value": 797,
			"label": "171962 - CRESTMONT FABRICS LTD"
	},
	{
			"value": 762,
			"label": "171980 - SHIELD INDUSTRIES INC"
	},
	{
			"value": 58,
			"label": "172028 - COLORBOK LLC"
	},
	{
			"value": 158,
			"label": "172078 - POLYFORM PRODUCTS COMPANY"
	},
	{
			"value": 998,
			"label": "172101 - STAMPENDOUS INC"
	},
	{
			"value": 745,
			"label": "172116 - TRANSFERMAGIC.COM"
	},
	{
			"value": 221,
			"label": "172119 - HOT OFF THE PRESS INC"
	},
	{
			"value": 29,
			"label": "172141 - NEW VIEW GIFTS & ACCESSORIES LTD"
	},
	{
			"value": 2071,
			"label": "172154 - FRANKFORD CANDY COMPANY"
	},
	{
			"value": 630,
			"label": "172158 - SAVAGE UNIVERSAL CORP"
	},
	{
			"value": 2087,
			"label": "172160 - TOOTSIE ROLL INDUSTRIES"
	},
	{
			"value": 41,
			"label": "172196 - BUFFALO GAMES LLC"
	},
	{
			"value": 726,
			"label": "172239 - MOMENTA INC"
	},
	{
			"value": 651,
			"label": "172244 - EDER FLAG MFG CO"
	},
	{
			"value": 1024,
			"label": "172252 - PAPER ADVENTURES"
	},
	{
			"value": 904,
			"label": "172262 - AMERICAN TOMBOW INC"
	},
	{
			"value": 479,
			"label": "172264 - NATIONAL NONWOVENS INC"
	},
	{
			"value": 1041,
			"label": "172278 - FABRIC MERCHANTS INC"
	},
	{
			"value": 705,
			"label": "172279 - BOTANIC INDUSTRIAL CO LTD"
	},
	{
			"value": 643,
			"label": "172289 - CREATIVE VISION DESIGN"
	},
	{
			"value": 351,
			"label": "172290 - COUSIN CORPORATION OF AMERICA"
	},
	{
			"value": 364,
			"label": "172298 - HALCRAFT"
	},
	{
			"value": 215,
			"label": "172310 - ELAN INNOVATION LLC"
	},
	{
			"value": 145,
			"label": "172333 - FPC CORPORATION"
	},
	{
			"value": 827,
			"label": "172417 - MIRACLE COATINGS"
	},
	{
			"value": 711,
			"label": "172429 - LEADER LIGHT LTD"
	},
	{
			"value": 64,
			"label": "172485 - A W FABER CASTELL USA INC"
	},
	{
			"value": 409,
			"label": "172492 - CARDINAL BRANDS"
	},
	{
			"value": 2134,
			"label": "172497 - FERRARA PAN CANDY CO"
	},
	{
			"value": 856,
			"label": "172523 - TRIDENT TRADING INC"
	},
	{
			"value": 404,
			"label": "172581 - CROP IN STYLE"
	},
	{
			"value": 437,
			"label": "172587 - MCS INDUSTRIES"
	},
	{
			"value": 383,
			"label": "172593 - IRIS USA INC"
	},
	{
			"value": 151,
			"label": "172596 - OTTLITE TECHNOLOGIES INC"
	},
	{
			"value": 786,
			"label": "172597 - KENNEY"
	},
	{
			"value": 969,
			"label": "172598 - SPECIALTY DISTRIBUTORS"
	},
	{
			"value": 905,
			"label": "172649 - YALEY ENTERPRISES"
	},
	{
			"value": 273,
			"label": "172651 - THE PARTY ANIMAL INC"
	},
	{
			"value": 372,
			"label": "172654 - BEADERS PARADISE"
	},
	{
			"value": 332,
			"label": "172668 - TEXTILE CREATIONS INC"
	},
	{
			"value": 1104,
			"label": "172672 - WINTER WOODS INC"
	},
	{
			"value": 542,
			"label": "172679 - FASHION ANGELS ENTERPRISES"
	},
	{
			"value": 349,
			"label": "172685 - RANGER INDUSTRIES"
	},
	{
			"value": 832,
			"label": "172689 - US SHELL INC"
	},
	{
			"value": 982,
			"label": "172691 - SAILOR"
	},
	{
			"value": 420,
			"label": "172699 - SIMPLICITY CREATIVE GROUP"
	},
	{
			"value": 421,
			"label": "172700 - BUTTERICK-GREEN PEPPER PATTERNS"
	},
	{
			"value": 428,
			"label": "172701 - BUTTERICK/VOGUE MAGAZINE"
	},
	{
			"value": 758,
			"label": "172730 - ELRENE HOME FASHIONS"
	},
	{
			"value": 382,
			"label": "172738 - PRYM CONSUMER USA INC"
	},
	{
			"value": 72,
			"label": "172745 - DDI DARICE DIRECT IMPORTS"
	},
	{
			"value": 604,
			"label": "172750 - FIBRE CRAFT MATERIALS CORP"
	},
	{
			"value": 821,
			"label": "172791 - CERAMO COMPANY INC"
	},
	{
			"value": 419,
			"label": "172804 - MCCALL PATTERN COMPANY"
	},
	{
			"value": 423,
			"label": "172805 - SIMPLICITY CREATIVE GROUP"
	},
	{
			"value": 422,
			"label": "172806 - BUTTERICK"
	},
	{
			"value": 662,
			"label": "172807 - WEST BROADWAY DISTRIBUTION SERVICES"
	},
	{
			"value": 86,
			"label": "172811 - CHI-WING RATTAN FACTORY"
	},
	{
			"value": 171,
			"label": "172816 - CARSON OPTICAL"
	},
	{
			"value": 882,
			"label": "172821 - ADVANTUS CORP"
	},
	{
			"value": 978,
			"label": "172824 - AVERY DENNISON"
	},
	{
			"value": 2420,
			"label": "172833 - SURE FIT, INC."
	},
	{
			"value": 102,
			"label": "172886 - DESIGNS FOR ALL SEASONS LTD"
	},
	{
			"value": 790,
			"label": "172891 - SOURCE GLOBAL ENTERPRISES"
	},
	{
			"value": 924,
			"label": "172898 - SPLASH OF COLOR LLC"
	},
	{
			"value": 191,
			"label": "172900 - ACTIVA PRODUCTS INC"
	},
	{
			"value": 1020,
			"label": "173025 - ZEBRA PEN CORPORATION"
	},
	{
			"value": 995,
			"label": "173066 - SANDYLION INC"
	},
	{
			"value": 644,
			"label": "173082 - ENVIRONMENTAL TECHNOLOGY INC"
	},
	{
			"value": 125,
			"label": "173105 - MIDWEST DESIGN IMPORTS INC"
	},
	{
			"value": 660,
			"label": "173106 - JOHN WOLF DIVISION OF RICHLOOM FABR"
	},
	{
			"value": 550,
			"label": "173240 - ARTSKILLS"
	},
	{
			"value": 425,
			"label": "173252 - SIMPLICITY CREATIVE GROUP"
	},
	{
			"value": 893,
			"label": "173280 - FIRST DATA CORP"
	},
	{
			"value": 385,
			"label": "173290 - HOME PRODUCTS INTERNATIONAL"
	},
	{
			"value": 40,
			"label": "173357 - PALITEX"
	},
	{
			"value": 77,
			"label": "173380 - K & M INTERNATIONAL"
	},
	{
			"value": 42,
			"label": "173410 - AMERICAN CRAFTS"
	},
	{
			"value": 218,
			"label": "173445 - LIFE OF THE PARTY"
	},
	{
			"value": 626,
			"label": "173490 - PULSAR"
	},
	{
			"value": 864,
			"label": "173535 - ESSELTE CORPORATION"
	},
	{
			"value": 1044,
			"label": "173552 - PATTON PICTURE COMPANY"
	},
	{
			"value": 2433,
			"label": "173565 - THE UPHOLSTERY STUDIO, INC."
	},
	{
			"value": 174,
			"label": "173642 - HOFFMASTER GROUP INC DBA CREATIVE"
	},
	{
			"value": 433,
			"label": "173645 - JAFTEX CORP"
	},
	{
			"value": 665,
			"label": "173670 - WEST BROADWAY DISTRIBUTION SERVICES"
	},
	{
			"value": 89,
			"label": "173671 - WEST BROADWAY DISTRIBUTION SERVICES"
	},
	{
			"value": 20,
			"label": "173681 - TRI COASTAL DESIGN"
	},
	{
			"value": 430,
			"label": "173700 - NEO WONDERWAY INC"
	},
	{
			"value": 668,
			"label": "173776 - MEREDITH CORPORATION"
	},
	{
			"value": 460,
			"label": "173785 - WEST BROADWAY DISTRIBUTION SERVICES"
	},
	{
			"value": 331,
			"label": "173810 - BAUM TEXTILE MILLS"
	},
	{
			"value": 1101,
			"label": "173811 - R E D INTERNATIONAL TEXTILES INC"
	},
	{
			"value": 1112,
			"label": "173821 - BLOOMER CANDY COMPANY"
	},
	{
			"value": 1226,
			"label": "173824 - HOSLEY CHINA INDUSTRIES LIMITED"
	},
	{
			"value": 909,
			"label": "173825 - WOODLINE WORKS CORP"
	},
	{
			"value": 184,
			"label": "173841 - STAEDTLER-MARS LTD"
	},
	{
			"value": 666,
			"label": "173855 - ANDREWS MCMEEL PUBLISHING"
	},
	{
			"value": 1091,
			"label": "173890 - INGRAM"
	},
	{
			"value": 735,
			"label": "173902 - ME AND MY BIG IDEAS"
	},
	{
			"value": 922,
			"label": "173945 - MCCALL WALLIES CONSIGNMENTS"
	},
	{
			"value": 450,
			"label": "173961 - INLITEN LLC"
	},
	{
			"value": 121,
			"label": "173996 - LI & FUNG (TRADING) LIMITED"
	},
	{
			"value": 552,
			"label": "174000 - SANFORD"
	},
	{
			"value": 881,
			"label": "174006 - WOODA CORP LTD"
	},
	{
			"value": 874,
			"label": "174007 - COSMA TEXTILE CORP LTD"
	},
	{
			"value": 755,
			"label": "174047 - MITCHELL FABRICS LLC"
	},
	{
			"value": 318,
			"label": "174055 - THE FELDMAN COMPANY INC"
	},
	{
			"value": 875,
			"label": "174057 - J AND J FELDMAN"
	},
	{
			"value": 132,
			"label": "174070 - JAS CUSTOM FRAMING PROJECTS"
	},
	{
			"value": 783,
			"label": "174077 - SANI-LINE SALES"
	},
	{
			"value": 401,
			"label": "174100 - ALLIANCE SALES & MARKETING"
	},
	{
			"value": 5,
			"label": "174140 - OTTLITE TECHNOLOGIES INC"
	},
	{
			"value": 246,
			"label": "174146 - MY MIND'S EYE"
	},
	{
			"value": 244675,
			"label": "174162 - JOC INTERNATIONAL LIMITED"
	},
	{
			"value": 1120,
			"label": "174163 - EVERLAST TEXTILE CO LTD"
	},
	{
			"value": 876,
			"label": "174164 - J AND J CORP"
	},
	{
			"value": 213,
			"label": "174165 - JAS CUSTOM FRAMING QUICK SALES"
	},
	{
			"value": 427,
			"label": "174215 - MCCALL SEE & SEW"
	},
	{
			"value": 920,
			"label": "174270 - ART SUPPLY ENTERPRISES INC"
	},
	{
			"value": 441,
			"label": "174310 - NEW VIEW GIFTS AND ACCESSORIES"
	},
	{
			"value": 238,
			"label": "174346 - TSUKINEKO"
	},
	{
			"value": 434,
			"label": "174385 - LJ TEXTILES LTD"
	},
	{
			"value": 810,
			"label": "174425 - LCG SALES INC"
	},
	{
			"value": 648,
			"label": "174426 - STAR CANDLE COMPANY"
	},
	{
			"value": 503,
			"label": "174430 - UNIQUE TREASURES LTD"
	},
	{
			"value": 1107,
			"label": "174460 - DOMISTYLE INC"
	},
	{
			"value": 2426,
			"label": "174500 - NUVELLE CRAFTS, LLC"
	},
	{
			"value": 2416,
			"label": "174526 - MAYFLOWER DISTRIBUTING COMPANY, INC"
	},
	{
			"value": 1114,
			"label": "174528 - AMSCAN INC"
	},
	{
			"value": 654,
			"label": "174561 - SEASONS (HK) LTD"
	},
	{
			"value": 299,
			"label": "174618 - MCCALL PATTERN COMPANY"
	},
	{
			"value": 837,
			"label": "174650 - SWEETWORKS CONFECTIONS LLC"
	},
	{
			"value": 371,
			"label": "174665 - ATLANTIC PACIFIC GROUP LLC"
	},
	{
			"value": 4472,
			"label": "174690 - COLORBOK - AMERICAN CRAFTS"
	},
	{
			"value": 191347,
			"label": "174710 - BUFFALO BATTING C/O FIBRIX LLC"
	},
	{
			"value": 69,
			"label": "174740 - JAKKS SALES CORP"
	},
	{
			"value": 408,
			"label": "174790 - APOTHECARY PRODUCTS"
	},
	{
			"value": 7,
			"label": "174812 - INTERNATIONAL ART ENT CO LTD"
	},
	{
			"value": 62,
			"label": "174815 - MASTERPIECES PUZZLE COMPANY"
	},
	{
			"value": 4497,
			"label": "174842 - DIXON TICONDEROGA (STRATHMORE)"
	},
	{
			"value": 712,
			"label": "174845 - KREBS INTERNATIONAL CO LTD"
	},
	{
			"value": 883,
			"label": "174862 - YOUNGDO VELVET CO LTD"
	},
	{
			"value": 953,
			"label": "174867 - C&D VISIONARY"
	},
	{
			"value": 826,
			"label": "174875 - MIDWEST DESIGN IMPORTS INC"
	},
	{
			"value": 4608,
			"label": "174907 - BUMIRANG CORPORATION"
	},
	{
			"value": 738,
			"label": "174915 - PHOENIX BRANDS LLC"
	},
	{
			"value": 1005,
			"label": "174960 - K & COMPANY LLC"
	},
	{
			"value": 76,
			"label": "174980 - ACTION PRODUCTS INTERNATIONAL INC"
	},
	{
			"value": 134,
			"label": "174990 - PELLON CONSUMER PRODUCTS/PCP GROUP"
	},
	{
			"value": 669,
			"label": "174991 - TRENDS INTERNATIONAL"
	},
	{
			"value": 161,
			"label": "175015 - THE GORILLA GLUE COMPANY"
	},
	{
			"value": 964,
			"label": "175061 - AMERICAN INTERNATIONAL INDUSTRIES"
	},
	{
			"value": 596,
			"label": "175075 - CLARENCE J VENNE LLC DBA CRAFTY DAB"
	},
	{
			"value": 217,
			"label": "175080 - ROWENTA/GROUPE SEB"
	},
	{
			"value": 1007,
			"label": "175095 - 3L CORPORATION"
	},
	{
			"value": 1015,
			"label": "175100 - KAREN FOSTER DESIGN INC"
	},
	{
			"value": 748,
			"label": "175115 - FUN-KINS LIMITED"
	},
	{
			"value": 142,
			"label": "175120 - GOLD CREST LLC/MIGHTY BRIGHT"
	},
	{
			"value": 198324,
			"label": "175130 - INSTANT BRANDS"
	},
	{
			"value": 1027,
			"label": "175131 - BAZZILL BASICS PAPER"
	},
	{
			"value": 891,
			"label": "175150 - JAS EDUCATION PROGRAM"
	},
	{
			"value": 284,
			"label": "175160 - PANACEA PRODUCTS CORPORATION"
	},
	{
			"value": 1049,
			"label": "175171 - ANCHOR HOCKING LLC"
	},
	{
			"value": 733,
			"label": "175180 - DEVAL PRODUCTS"
	},
	{
			"value": 586,
			"label": "175200 - HORIZON GROUP USA INC"
	},
	{
			"value": 39,
			"label": "175211 - SPRINGS CREATIVE PRODUCTS GROUP LLC"
	},
	{
			"value": 1034,
			"label": "175216 - STONE HARBOR"
	},
	{
			"value": 873,
			"label": "175225 - NIPKOW & KOBELT INC"
	},
	{
			"value": 261,
			"label": "175235 - FABRIQUE INNOVATIONS INC DBA"
	},
	{
			"value": 73,
			"label": "175255 - ROYAL BRUSH MANUFACTURING INC"
	},
	{
			"value": 664,
			"label": "175296 - WORKMAN PUBLISHING"
	},
	{
			"value": 87,
			"label": "175300 - PARRAGON INC"
	},
	{
			"value": 1661,
			"label": "175305 - SPINRITE CORP."
	},
	{
			"value": 1040,
			"label": "175320 - J KELLY'S EXPORTS"
	},
	{
			"value": 166,
			"label": "175326 - DYNO LLC"
	},
	{
			"value": 226,
			"label": "175340 - COLART AMERICAS"
	},
	{
			"value": 618,
			"label": "175365 - SAFARI LTD"
	},
	{
			"value": 612,
			"label": "175400 - NKOK INC"
	},
	{
			"value": 598,
			"label": "175405 - GIDDY UP LLC"
	},
	{
			"value": 792,
			"label": "175410 - QUALFAB INDUSTRIES INC"
	},
	{
			"value": 429,
			"label": "175435 - MERCHANDISING RESOURCES INC"
	},
	{
			"value": 47,
			"label": "175475 - BENDON INC"
	},
	{
			"value": 110,
			"label": "175481 - DECORWARE INC"
	},
	{
			"value": 2010,
			"label": "175482 - DECORWARE INC"
	},
	{
			"value": 979,
			"label": "175521 - CALCULATED INDUSTRIES"
	},
	{
			"value": 578,
			"label": "175525 - FLIPSIDE PRODUCTS INC"
	},
	{
			"value": 587,
			"label": "175535 - M & M GLOBAL"
	},
	{
			"value": 243,
			"label": "175545 - DIGITALCUSTOM GROUP INC"
	},
	{
			"value": 335,
			"label": "175550 - RICHLOOM FAR EAST TRADING CO LTD"
	},
	{
			"value": 424,
			"label": "175555 - VOGUE"
	},
	{
			"value": 1098,
			"label": "175560 - HOME DECOR INTERNATIONAL LLC"
	},
	{
			"value": 366,
			"label": "175570 - SAUNDERS MIDWEST LLC"
	},
	{
			"value": 690,
			"label": "175590 - UCP INTERNATIONAL CO LTD"
	},
	{
			"value": 834,
			"label": "175600 - OREGON HOLIDAY PRODUCTS"
	},
	{
			"value": 734,
			"label": "175610 - FABRIC.COM INC"
	},
	{
			"value": 859,
			"label": "175670 - ELLISON EDUCATIONAL EQUIPMENT INC"
	},
	{
			"value": 1065,
			"label": "175675 - ARDWYN BINDING PRODUCTS CO INC"
	},
	{
			"value": 2298,
			"label": "175676 - SVP SEWING BRANDS LLC"
	},
	{
			"value": 359,
			"label": "175680 - DPG USA INC"
	},
	{
			"value": 1056,
			"label": "175685 - COCA COLA REFRESHMENTS USA INC"
	},
	{
			"value": 470,
			"label": "175695 - PK LIFESTYLES"
	},
	{
			"value": 889,
			"label": "175705 - SOMERSET GROUP LTD"
	},
	{
			"value": 888,
			"label": "175707 - STERNO HOME"
	},
	{
			"value": 107,
			"label": "175715 - WILLOWBROOK"
	},
	{
			"value": 884,
			"label": "175740 - J OKADA TRADING CO LTD"
	},
	{
			"value": 728,
			"label": "175745 - GERBER CHILDRENSWEAR LLC"
	},
	{
			"value": 74,
			"label": "175746 - SKULLDUGGERY INC"
	},
	{
			"value": 502,
			"label": "175748 - SCHOOL ZONE PUBLISHING COMPANY INC"
	},
	{
			"value": 751,
			"label": "175750 - CRYPTON SUPER FABRICS"
	},
	{
			"value": 30,
			"label": "175760 - ENCHANTE ACCESSORIES INC"
	},
	{
			"value": 670,
			"label": "175775 - ACCO BRANDS USA LLC"
	},
	{
			"value": 480,
			"label": "175796 - NEW IMAGE GROUP INC"
	},
	{
			"value": 498,
			"label": "175800 - NEW IMAGE GROUP IMPORT"
	},
	{
			"value": 990,
			"label": "175810 - BUZZY INC"
	},
	{
			"value": 824,
			"label": "175835 - TETERS FLORAL PRODUCTS"
	},
	{
			"value": 629,
			"label": "175866 - PEPPERELL CRAFTS OF NINGBO"
	},
	{
			"value": 88,
			"label": "175875 - AMERICAN NEWS COMPANY LLC"
	},
	{
			"value": 464,
			"label": "175880 - SCHOTT TEXTILES INC"
	},
	{
			"value": 373,
			"label": "175890 - PLANO MOLDING COMPANY/CREATIVE OPTI"
	},
	{
			"value": 96,
			"label": "175895 - ACHIM IMPORTING CO INC"
	},
	{
			"value": 919,
			"label": "175896 - CANVAS CORP LLC"
	},
	{
			"value": 75,
			"label": "175906 - DUNECRAFT"
	},
	{
			"value": 801,
			"label": "175910 - FOUR SEASONS FLOWERS"
	},
	{
			"value": 154,
			"label": "175916 - BROTHER INTERNATIONAL CORPORATION"
	},
	{
			"value": 391,
			"label": "175917 - ARROW COMPANIES LLC"
	},
	{
			"value": 983,
			"label": "175919 - ELECTRIC QUILT COMPANY"
	},
	{
			"value": 157,
			"label": "175920 - LOZIER CORP"
	},
	{
			"value": 143,
			"label": "175921 - PREMIER STORE FIXTURES INC"
	},
	{
			"value": 227,
			"label": "175925 - CORMARK INC"
	},
	{
			"value": 285,
			"label": "175930 - STAPLES NATIONAL ADVANTAGE"
	},
	{
			"value": 298,
			"label": "175931 - UNEQ INC"
	},
	{
			"value": 653,
			"label": "175936 - CREATIVE HOME LTD"
	},
	{
			"value": 294,
			"label": "175937 - SIMPLICITY PATTERN CO INC"
	},
	{
			"value": 22,
			"label": "175948 - EVERYTHING MARY LLC"
	},
	{
			"value": 288,
			"label": "175951 - WEYERHAEUSER PAPER COMPANY"
	},
	{
			"value": 100,
			"label": "175952 - ACME UNITED CORPORATION"
	},
	{
			"value": 254,
			"label": "175961 - MAY FUNG PLASTIC FACTORY (HK) LIMIT"
	},
	{
			"value": 112,
			"label": "175967 - VALLABH METAL INC"
	},
	{
			"value": 295,
			"label": "175972 - HUAIAN FULLYA INTERNATIONAL"
	},
	{
			"value": 448,
			"label": "175973 - EXMART INTERNATIONAL PVT LTD"
	},
	{
			"value": 83,
			"label": "175976 - PREMIER YARNS"
	},
	{
			"value": 1067,
			"label": "175985 - JACOBSON CAPITAL SERVICES INC"
	},
	{
			"value": 819,
			"label": "175994 - PEAKTOP GROUP LIMITED"
	},
	{
			"value": 652,
			"label": "175995 - PROMAX MANUFACTURING CO LTD"
	},
	{
			"value": 173,
			"label": "175997 - DESIGNCO"
	},
	{
			"value": 34,
			"label": "175999 - TASIA INTERNATIONAL CO LTD"
	},
	{
			"value": 90,
			"label": "176008 - SINO AGRO ENTERPRISE GUANGDONG"
	},
	{
			"value": 816,
			"label": "176009 - TAIXING CORPORATION"
	},
	{
			"value": 1084,
			"label": "176010 - EASY STREET CRAFTS"
	},
	{
			"value": 99,
			"label": "176017 - SHENYANG LARGE CIRCLE ARTS & CRAFTS"
	},
	{
			"value": 993,
			"label": "176022 - PALM FIBRE (INDIA) PRIVATE LIMITED"
	},
	{
			"value": 828,
			"label": "176040 - H & P SALES INC"
	},
	{
			"value": 252,
			"label": "176043 - AMAN EXPORTS"
	},
	{
			"value": 314,
			"label": "176047 - SHANGHAI SHENHONG TOPWIN IMP & EXP"
	},
	{
			"value": 309,
			"label": "176060 - NOVELTY HANDICRAFTS CO LTD"
	},
	{
			"value": 896,
			"label": "176066 - TAIZHOU INTERNATIONAL TRADE"
	},
	{
			"value": 28,
			"label": "176075 - SANMAONET ENTERPRISE CO LTD"
	},
	{
			"value": 286,
			"label": "176078 - MODERN STORE FIXTURES CO INC"
	},
	{
			"value": 336,
			"label": "176088 - CHANGSHU WINWAY TEXTILE CO LTD"
	},
	{
			"value": 702,
			"label": "176090 - EVERSTAR MERCHANDISE CO LIMITED"
	},
	{
			"value": 91,
			"label": "176091 - CHINA NATIONAL ARTS & CRAFTS IMP &"
	},
	{
			"value": 323,
			"label": "176094 - SHAZHOU TEXTILE PRINTING AND DYEING"
	},
	{
			"value": 700,
			"label": "176114 - ART FAMOUS LIMITED"
	},
	{
			"value": 308,
			"label": "176117 - TRION INDUSTRIES INC"
	},
	{
			"value": 14522,
			"label": "176118 - ANHUI QIAOXING TECHNOLOGY DEVELOPME"
	},
	{
			"value": 1030,
			"label": "176119 - CHANGZHOU CITY HENGFENG WEAVE"
	},
	{
			"value": 1035,
			"label": "176121 - SHAOXING HORSLEY IMPORT"
	},
	{
			"value": 26,
			"label": "176130 - METRO GROUP (INDUSTRIAL) LTD"
	},
	{
			"value": 319,
			"label": "176144 - SJ CORPORATION"
	},
	{
			"value": 985,
			"label": "176145 - JIN EIN TRADING COMPANY"
	},
	{
			"value": 341,
			"label": "176149 - SYNTHESIS HOME TEXTILES PRIVATE LTD"
	},
	{
			"value": 798,
			"label": "176150 - ELEGANT HOME LTD"
	},
	{
			"value": 37,
			"label": "176153 - SHAOXING FEIYA PRINTING AND DYEING"
	},
	{
			"value": 635,
			"label": "176162 - AUTHENTIC KNITTING BOARD"
	},
	{
			"value": 463,
			"label": "176164 - WUJIANG FANFEI IM/EX CO LTD"
	},
	{
			"value": 118,
			"label": "176165 - VISTAR CORPORATION"
	},
	{
			"value": 1294,
			"label": "176166 - MOREX RIBBON"
	},
	{
			"value": 242,
			"label": "176167 - TACONY CORPORATION"
	},
	{
			"value": 185,
			"label": "176186 - FANCYTHAT!"
	},
	{
			"value": 282,
			"label": "176200 - GRANVILLE ASSOCIATES"
	},
	{
			"value": 609,
			"label": "176202 - GRAND-BONANZA ENTERPRISE INC"
	},
	{
			"value": 122,
			"label": "176203 - HOME DECOR IMPORTS INC"
	},
	{
			"value": 165,
			"label": "176204 - WIN HANG ENTERPRISE LTD"
	},
	{
			"value": 280,
			"label": "176208 - OFFICE DEPOT INC"
	},
	{
			"value": 1086,
			"label": "176210 - WIDGET PRODUCTS INC"
	},
	{
			"value": 270,
			"label": "176218 - EUGENE TEXTILES (2003) INC"
	},
	{
			"value": 663,
			"label": "176226 - THE LANG COMPANIES INC"
	},
	{
			"value": 98,
			"label": "176228 - SUNCOAST BOTANICALS INC"
	},
	{
			"value": 179,
			"label": "176252 - UNOVO LLC DBA OLISO"
	},
	{
			"value": 94,
			"label": "176260 - ALL COURTESY INTERNATIONAL LIMITED"
	},
	{
			"value": 493,
			"label": "176265 - RUST-OLEUM CORPORATION"
	},
	{
			"value": 673421,
			"label": "176268 - NOTIONS MARKETING CORP (ECOM)"
	},
	{
			"value": 443,
			"label": "176276 - MASTERPIECE ART GALLERY"
	},
	{
			"value": 124,
			"label": "176277 - ELEGANT GIFTS CORPORATION"
	},
	{
			"value": 863,
			"label": "176279 - SPELLBINDERS PAPER ARTS LLC"
	},
	{
			"value": 105,
			"label": "176280 - BIRD BRAIN"
	},
	{
			"value": 628,
			"label": "176281 - HANDY HANDS INC"
	},
	{
			"value": 1102,
			"label": "176282 - MYERS INDUSTRIES INC"
	},
	{
			"value": 287,
			"label": "176291 - POLLOCK PAPER DISTRIBUTORS"
	},
	{
			"value": 9,
			"label": "176292 - JAS MAIL"
	},
	{
			"value": 956,
			"label": "176295 - ONTEL PRODUCTS CORP"
	},
	{
			"value": 930,
			"label": "176296 - TELEBRANDS CORP"
	},
	{
			"value": 932,
			"label": "176300 - IDEAVILLAGE PRODUCTS CORP"
	},
	{
			"value": 2,
			"label": "176307 - ACCUQUILT LLC"
	},
	{
			"value": 880,
			"label": "176311 - SHAOXING MINYING TRADING CO LTD"
	},
	{
			"value": 1708,
			"label": "176325 - TIME FACTORY PUBLISHING"
	},
	{
			"value": 776,
			"label": "176328 - WUJIANG FOREIGN TRADE CORP (GROUP)"
	},
	{
			"value": 120,
			"label": "176329 - THE PHILADELPHIA GROUP"
	},
	{
			"value": 296,
			"label": "176330 - SOLAR IMAGING LLC"
	},
	{
			"value": 835,
			"label": "176331 - NEW PHASE DEVELOPMENT LLC"
	},
	{
			"value": 325,
			"label": "176333 - SHANGHAI SHENDA IMP & EXP CO LTD"
	},
	{
			"value": 885,
			"label": "176335 - JIANGSU ROMROL GROUP OUTDOOR"
	},
	{
			"value": 207,
			"label": "176336 - FASTENERS FOR RETAIL INC"
	},
	{
			"value": 92343,
			"label": "176345 - SHAOXING KEQIAO YUZHOU TEXTILECOLTD"
	},
	{
			"value": 743,
			"label": "176349 - GRADUATE PLASTICS INC"
	},
	{
			"value": 878,
			"label": "176352 - TAIXING TONGJI FOREIGN TRADE CO LTD"
	},
	{
			"value": 306,
			"label": "176365 - FABRICUT INC"
	},
	{
			"value": 237,
			"label": "176366 - HOUSEHOLD ESSENTIALS LLC"
	},
	{
			"value": 293,
			"label": "176367 - DARICE INC"
	},
	{
			"value": 949,
			"label": "176371 - ALLSTAR PRODUCTS GROUP"
	},
	{
			"value": 92455,
			"label": "176372 - SHURTAPE TECHNOLOGIES LLC"
	},
	{
			"value": 23,
			"label": "176376 - ENCHANTE ACCESSORIES INC"
	},
	{
			"value": 458,
			"label": "176382 - HAILAN GUOYUE TRADING CO LTD"
	},
	{
			"value": 128,
			"label": "176383 - MARTHA STEWART CRAFTS"
	},
	{
			"value": 59,
			"label": "176385 - TOYSMITH"
	},
	{
			"value": 65,
			"label": "176387 - MELISSA & DOUG LLC"
	},
	{
			"value": 51,
			"label": "176388 - ALEX BRANDS"
	},
	{
			"value": 10,
			"label": "176403 - BOC DISTRIBUTION INC"
	},
	{
			"value": 877,
			"label": "176404 - SHAOXING ADOR IMPORT &"
	},
	{
			"value": 937,
			"label": "176410 - PACIFIC LEGWEAR"
	},
	{
			"value": 566,
			"label": "176432 - GOGO TOYS CO LTD"
	},
	{
			"value": 778,
			"label": "176436 - WAXMAN CONSUMER PRODUCTS GROUP INC"
	},
	{
			"value": 475,
			"label": "176438 - ZHEJIANG SHAOXING AGELESS TRADE"
	},
	{
			"value": 787,
			"label": "176446 - CIL TEXTILES PVT LTD"
	},
	{
			"value": 236,
			"label": "176447 - VIRA INSIGHT LLC"
	},
	{
			"value": 624,
			"label": "176455 - FAIRWAY NEEDLECRAFT"
	},
	{
			"value": 1016,
			"label": "176475 - ARTIFACTS INDIA"
	},
	{
			"value": 559,
			"label": "176478 - BE AMAZING TOYS"
	},
	{
			"value": 363,
			"label": "176482 - VINTAJ NATURAL BRASS CO"
	},
	{
			"value": 1013,
			"label": "176485 - CRE8DIRECT (NINGBO) CO LTD"
	},
	{
			"value": 600,
			"label": "176491 - CARSON DELLOSA"
	},
	{
			"value": 814,
			"label": "176492 - QUALITY GROWERS FLORAL COMPANY INC"
	},
	{
			"value": 79,
			"label": "176493 - HACHETTE BOOK GROUP"
	},
	{
			"value": 842,
			"label": "176495 - NIRMAL DESIGNS PRIVATE LIMITED"
	},
	{
			"value": 601,
			"label": "176499 - CARIOCA"
	},
	{
			"value": 208,
			"label": "176501 - CHECKOLITE INTERNATIONAL INC"
	},
	{
			"value": 649,
			"label": "176516 - ANIMAL ADVENTURE LLC"
	},
	{
			"value": 338,
			"label": "176521 - WESTMINSTER FIBERS LIFESTYLE"
	},
	{
			"value": 114,
			"label": "176523 - CREATIVE CO OP INC"
	},
	{
			"value": 214,
			"label": "176526 - SOUTHERN IMPERIAL INC"
	},
	{
			"value": 1031,
			"label": "176531 - ZHANGJIAGANG YINTIAN TRADING CO LTD"
	},
	{
			"value": 170,
			"label": "176532 - SYNDICATE SYSTEMS INC"
	},
	{
			"value": 870,
			"label": "176545 - LIFESTYLE CRAFTS"
	},
	{
			"value": 1014,
			"label": "176546 - GCD STUDIOS"
	},
	{
			"value": 686,
			"label": "176556 - ENVIROSCENT INC"
	},
	{
			"value": 808,
			"label": "176557 - GIFTWARES COMPANY INC"
	},
	{
			"value": 1006,
			"label": "176558 - ANNA GRIFFIN INC"
	},
	{
			"value": 805,
			"label": "176574 - LIBBEY GLASS INC"
	},
	{
			"value": 911,
			"label": "176575 - HOME KREATION BY KK INC"
	},
	{
			"value": 687,
			"label": "176577 - CRESCENT ENTERPRISE CO LTD"
	},
	{
			"value": 703,
			"label": "176578 - PANS CRAFT & DESIGN FACTORY"
	},
	{
			"value": 111,
			"label": "176579 - MINHOU MINXING WEAVING CO LTD"
	},
	{
			"value": 258,
			"label": "176580 - SULLIVANS USA INC"
	},
	{
			"value": 820,
			"label": "176585 - CURTIS WAGNER PLASTICS CORP"
	},
	{
			"value": 1092,
			"label": "176587 - LOLLY CO LTD"
	},
	{
			"value": 33,
			"label": "176620 - NEW VIEW GIFTS AND ACCESSORIES"
	},
	{
			"value": 384,
			"label": "176630 - QINGDAO LIBANG KINGTONE TRADE CO LT"
	},
	{
			"value": 78,
			"label": "176640 - POOF SLINKY LLC"
	},
	{
			"value": 1070,
			"label": "176645 - RUBBERMAID CONSUMER PRODUCTS"
	},
	{
			"value": 461,
			"label": "176650 - DAVID TEXTILES INC"
	},
	{
			"value": 1026,
			"label": "176655 - THREE DESIGNING WOMEN"
	},
	{
			"value": 1119,
			"label": "176657 - GARVEN LLC"
	},
	{
			"value": 182,
			"label": "176660 - LORETTA LEE LIMITED"
	},
	{
			"value": 1072,
			"label": "176665 - CERTIFIED INTERNATIONAL CORP"
	},
	{
			"value": 230,
			"label": "176671 - DAYLIGHT LIGHTING COMPANY"
	},
	{
			"value": 725,
			"label": "176672 - ABG ACCESSORIES INC"
	},
	{
			"value": 333,
			"label": "176686 - SEASONS TEX"
	},
	{
			"value": 509,
			"label": "176689 - PLAYMONSTER LLC"
	},
	{
			"value": 206,
			"label": "176695 - VERILUX INC"
	},
	{
			"value": 481,
			"label": "176701 - PACON CORPORATION - STRATHMORE ARTI"
	},
	{
			"value": 289,
			"label": "176703 - JOSHEN PAPER & PACKAGING"
	},
	{
			"value": 541,
			"label": "176710 - STEPHEN JOSEPH"
	},
	{
			"value": 1009,
			"label": "176715 - SWAGGERDOODLE LLC"
	},
	{
			"value": 1004,
			"label": "176725 - PAPER HOUSE PRODUCTIONS INC"
	},
	{
			"value": 706,
			"label": "176730 - SEASONS SPECIAL CO LTD"
	},
	{
			"value": 921,
			"label": "176735 - IDEASTREAM CONSUMER PRODUCTS LLC"
	},
	{
			"value": 799,
			"label": "176743 - UNIVIC FLORAL COMPANY LIMITED"
	},
	{
			"value": 866,
			"label": "176747 - WE R LLC"
	},
	{
			"value": 127,
			"label": "176755 - HAMPTON ART INC"
	},
	{
			"value": 1010,
			"label": "176756 - REGIONAL SUPPLY"
	},
	{
			"value": 16,
			"label": "176760 - SILVER CREEK LEATHER CO LLC"
	},
	{
			"value": 219,
			"label": "176765 - MCCALL KWIK SEW"
	},
	{
			"value": 955,
			"label": "176770 - SPARK INNOVATORS CORP"
	},
	{
			"value": 337,
			"label": "176771 - AL-KARAM TEXTILE MILLS (PVT) LTD"
	},
	{
			"value": 1039,
			"label": "176774 - SHAOXING XINYUE TRADE CO LTD"
	},
	{
			"value": 718,
			"label": "176775 - MARK RICHARDS ENTERPRISES INC"
	},
	{
			"value": 516,
			"label": "176776 - SAVVI"
	},
	{
			"value": 843,
			"label": "176786 - TERVIS"
	},
	{
			"value": 850,
			"label": "176795 - WELCOME HOME BRANDS"
	},
	{
			"value": 894,
			"label": "176796 - BLACKHAWK NETWORK INC"
	},
	{
			"value": 569,
			"label": "176811 - CROREY CREATIONS INC"
	},
	{
			"value": 123,
			"label": "176833 - GOLDEN SUN HANDICRAFT MANUFACTURER"
	},
	{
			"value": 1085,
			"label": "176840 - HIGH HOPE AGLORY LIMITED"
	},
	{
			"value": 367,
			"label": "176841 - SILVER ONE INTERNATIONAL"
	},
	{
			"value": 753,
			"label": "176860 - VISION FABRICS"
	},
	{
			"value": 1455,
			"label": "176865 - THE TIN BOX COMPANY"
	},
	{
			"value": 1099,
			"label": "176888 - RTC FABRICS CORP"
	},
	{
			"value": 240,
			"label": "176902 - GOLDEN ACE INDUSTRIAL CO LTD"
	},
	{
			"value": 595,
			"label": "176912 - INNOVATIVE DESIGNS"
	},
	{
			"value": 135,
			"label": "176915 - JANOME AMERICA INC"
	},
	{
			"value": 846,
			"label": "176929 - LORANN OILS"
	},
	{
			"value": 779,
			"label": "176930 - YORK WALLCOVERINGS"
	},
	{
			"value": 1028,
			"label": "176937 - MCCALL PATTERN COMPANY"
	},
	{
			"value": 225,
			"label": "176940 - GLUE DOTS ADHESIVES"
	},
	{
			"value": 765,
			"label": "176946 - SHAOXING GUANGYUAN TEXTILE CO LTD"
	},
	{
			"value": 605,
			"label": "176952 - JUST FOR LAUGHS"
	},
	{
			"value": 116,
			"label": "176972 - HER ACCESSORIES"
	},
	{
			"value": 4499,
			"label": "176973 - LF CENTENNIAL PTE LTD"
	},
	{
			"value": 540,
			"label": "176980 - CREATIVE TEACHING PRESS"
	},
	{
			"value": 926,
			"label": "176999 - TAIZHOU YONGSHUN IMP & EXP CO LTD"
	},
	{
			"value": 1077,
			"label": "177006 - DHANUSH INTERNATIONAL"
	},
	{
			"value": 297,
			"label": "177016 - NEW TIMES CREATION CO LTD"
	},
	{
			"value": 478,
			"label": "177018 - SHAOXING ROBB IMP & EXP CO LTD"
	},
	{
			"value": 681,
			"label": "177024 - ZHEJIANG TONGFENG ARTS & CRAFTS"
	},
	{
			"value": 368,
			"label": "177030 - SOFT FLEX COMPANY"
	},
	{
			"value": 416,
			"label": "177050 - LUCKY STAR ENTERPRISE & CO LTD"
	},
	{
			"value": 1090,
			"label": "177053 - KOHINOOR CARPET"
	},
	{
			"value": 1022,
			"label": "177062 - PRIMA MARKETING INC"
	},
	{
			"value": 67,
			"label": "177066 - MERCHSOURCE LLC"
	},
	{
			"value": 4478,
			"label": "177070 - CLOUD9 FABRICS INC"
	},
	{
			"value": 594,
			"label": "177071 - FUJIAN SAILLIA LIGHT INTL GROUP CO"
	},
	{
			"value": 845,
			"label": "177074 - KIMBER CAKEWARE"
	},
	{
			"value": 4498,
			"label": "177076 - SHAOXING XINZEZHOU IMP & EXP CO LTD"
	},
	{
			"value": 176,
			"label": "177077 - WIN MART INTERNATIONAL DEVELOPMENT"
	},
	{
			"value": 647,
			"label": "177081 - ORIENTAL CRAFT INDUSTRIES CO LTD"
	},
	{
			"value": 50,
			"label": "177093 - THE ORB FACTORY"
	},
	{
			"value": 1105,
			"label": "177099 - LOVE COOKING COMPANY"
	},
	{
			"value": 564,
			"label": "177111 - WORLDWIDE DYNASTY INC DBA H2W"
	},
	{
			"value": 584,
			"label": "177125 - FUZHOU PROSPEROUS YEAR I/E CO LTD"
	},
	{
			"value": 65583,
			"label": "177129 - SPRING FILL INDUSTRIES LLC"
	},
	{
			"value": 1108,
			"label": "177142 - SINO GIFTS CO LTD"
	},
	{
			"value": 879,
			"label": "177162 - SUZHOU LEJING KNITTING CO LTD"
	},
	{
			"value": 109,
			"label": "177166 - CKK HOME DECOR"
	},
	{
			"value": 633,
			"label": "177170 - CORNERSTONE PRODUCTS"
	},
	{
			"value": 508,
			"label": "177171 - PUBLICATIONS INTERNATIONAL LTD"
	},
	{
			"value": 52,
			"label": "177172 - SMART TOYS & GAMES INC"
	},
	{
			"value": 871,
			"label": "177177 - ZINK IMAGING INC"
	},
	{
			"value": 851,
			"label": "177178 - FOCUS PRODUCTS GROUP INTERNATIONAL"
	},
	{
			"value": 1018,
			"label": "177186 - ROYAL ACME CORPORATION"
	},
	{
			"value": 741,
			"label": "177203 - KARIWALA INDUSTRIES LIMITED"
	},
	{
			"value": 872,
			"label": "177205 - AVATREX LLC"
	},
	{
			"value": 951,
			"label": "177220 - MASONWAYS INDESTRUCTIBLE PLASTICS L"
	},
	{
			"value": 290,
			"label": "177230 - THE SUPPLY SOURCE LLC"
	},
	{
			"value": 1094,
			"label": "177233 - LUMI CO"
	},
	{
			"value": 1076,
			"label": "177254 - PADDYWAX"
	},
	{
			"value": 264,
			"label": "177255 - TEAM BEANS LLC"
	},
	{
			"value": 1088,
			"label": "177256 - RICHLOOM HOME FASHIONS"
	},
	{
			"value": 343,
			"label": "177260 - BEADSMITH"
	},
	{
			"value": 910,
			"label": "177262 - SPARROW INNOVATIONS INC"
	},
	{
			"value": 101,
			"label": "177263 - SPRING VALLEY FLORAL"
	},
	{
			"value": 1023,
			"label": "177266 - ARC CRAFTS"
	},
	{
			"value": 406,
			"label": "177268 - CLEARSNAP HOLDING INC"
	},
	{
			"value": 1050,
			"label": "177275 - DIAMOND TECH INTERNATIONAL"
	},
	{
			"value": 41611,
			"label": "177279 - THE GERSON COMPANIES"
	},
	{
			"value": 699,
			"label": "177280 - EMPIRE CANDLE CO LLC"
	},
	{
			"value": 80,
			"label": "177291 - PREMIER YARNS"
	},
	{
			"value": 867,
			"label": "177292 - SILHOUETTE AMERICA"
	},
	{
			"value": 833,
			"label": "177295 - THE GERSON COMPANY"
	},
	{
			"value": 444,
			"label": "177296 - INNOVATIVE CREATIONS INC"
	},
	{
			"value": 719,
			"label": "177300 - SEW EASY INDUSTRIES (DBA: SEI)"
	},
	{
			"value": 637,
			"label": "177301 - JACK DEMPSEY ASSOC LLC"
	},
	{
			"value": 772,
			"label": "177305 - SOLARTEX CORPORATION"
	},
	{
			"value": 680,
			"label": "177309 - GARVEN LLC"
	},
	{
			"value": 501,
			"label": "177312 - TEACHER CREATED RESOURCES"
	},
	{
			"value": 356,
			"label": "177316 - DM MERCHANDISING INC"
	},
	{
			"value": 4615,
			"label": "177318 - ZHANGJIAGANG FREE TRADE ZONE"
	},
	{
			"value": 948,
			"label": "177320 - COSMOPAK USA LLC"
	},
	{
			"value": 693,
			"label": "177323 - COUNTRY LINE (INTERNATIONAL) LTD"
	},
	{
			"value": 692,
			"label": "177324 - J&J SEASONAL COMPANY LTD"
	},
	{
			"value": 311,
			"label": "177326 - LONG RICH INDUSTRIAL LIMITED"
	},
	{
			"value": 766,
			"label": "177328 - KENNEY MANUFACTURING COMPANY"
	},
	{
			"value": 1100,
			"label": "177329 - SATORI HOME LIMITED"
	},
	{
			"value": 19,
			"label": "177337 - BEADALON"
	},
	{
			"value": 563,
			"label": "177339 - JOYFUL LANDS CRAFT CO LTD"
	},
	{
			"value": 327,
			"label": "177340 - TEXTILE CREATIONS INC"
	},
	{
			"value": 322,
			"label": "177346 - TAIHAN TEXTILE (SHANGHAI) CO LTD"
	},
	{
			"value": 1079,
			"label": "177347 - RONGHAO INTERNATIONAL CO LIMITED"
	},
	{
			"value": 908,
			"label": "177349 - M-D BUILDING PRODUCTS"
	},
	{
			"value": 860,
			"label": "177350 - CRAFTWELL INC"
	},
	{
			"value": 1109,
			"label": "177351 - WILSON ENTERPRISES INC"
	},
	{
			"value": 119,
			"label": "177353 - EVERYTHING LEGWEAR LLC"
	},
	{
			"value": 426,
			"label": "177354 - THE MCCALL PATTERN COMPANY"
	},
	{
			"value": 533,
			"label": "177356 - ANN WILLIAMS GROUP LLC"
	},
	{
			"value": 348,
			"label": "177360 - WOLTER CORP DBA IMPRESSART"
	},
	{
			"value": 976,
			"label": "177365 - ZAFAR PROJECTS INC"
	},
	{
			"value": 774,
			"label": "177374 - CREATIVE POINTE DESIGNS LLC"
	},
	{
			"value": 220,
			"label": "177375 - TEST RITE INTERNATIONAL CO LTD"
	},
	{
			"value": 46,
			"label": "177380 - UNIVERSITY GAMES CORPORATION"
	},
	{
			"value": 2156,
			"label": "177384 - HIGH POINT DESIGN LLC"
	},
	{
			"value": 275,
			"label": "177386 - INFINITY PRODUCT GROUP"
	},
	{
			"value": 1103,
			"label": "177387 - NAPA LLC"
	},
	{
			"value": 474,
			"label": "177388 - CTEX HOMETEXTILE CO LTD"
	},
	{
			"value": 248,
			"label": "177389 - HOLSTER BRANDS"
	},
	{
			"value": 848,
			"label": "177395 - NORTHLAND ALUMINUM PRODUCTS INC"
	},
	{
			"value": 1011,
			"label": "177396 - BLITSY INC"
	},
	{
			"value": 148,
			"label": "177404 - SMARTEK USA INC"
	},
	{
			"value": 853,
			"label": "177410 - CORE HOME"
	},
	{
			"value": 855,
			"label": "177415 - AUNTIE ANNE'S LLC"
	},
	{
			"value": 619,
			"label": "177416 - FISHER-PRICE INC"
	},
	{
			"value": 590,
			"label": "177417 - WOOKY ENTERTAINMENT INC"
	},
	{
			"value": 345,
			"label": "177418 - LESILU PRODUCTIONS INC"
	},
	{
			"value": 347,
			"label": "177420 - A CLASSIC TIME WATCH COMPANY INC"
	},
	{
			"value": 55,
			"label": "177422 - MATTEL SALES CORPORATION"
	},
	{
			"value": 892,
			"label": "177425 - SYMPOZ INC DBA CRAFTSY"
	},
	{
			"value": 374,
			"label": "177436 - CONAIR FAR EAST LTD"
	},
	{
			"value": 929,
			"label": "177445 - CONCEPT 4 LIMITED"
	},
	{
			"value": 1062,
			"label": "177446 - ZHEJIANG WADOU CREATIVE ART CO LTD"
	},
	{
			"value": 862,
			"label": "177447 - FUJIFILM NORTH AMERICA CORPORATION"
	},
	{
			"value": 164,
			"label": "177448 - FLORACRAFT CORPORATION"
	},
	{
			"value": 49,
			"label": "177449 - HASBRO INC"
	},
	{
			"value": 720,
			"label": "177455 - HYBRID PROMOTIONS LLC"
	},
	{
			"value": 411,
			"label": "177461 - GREAT WISELY DEVELOPMENT CO LTD"
	},
	{
			"value": 715,
			"label": "177462 - FANTASIA ACCESSORIES LTD"
	},
	{
			"value": 815,
			"label": "177470 - UNIVERSAL SELECT CRAFT CO LTD"
	},
	{
			"value": 1161,
			"label": "177472 - HILOS IRIS SA DE CV"
	},
	{
			"value": 440,
			"label": "177474 - MCS FAR EAST INC"
	},
	{
			"value": 771,
			"label": "177476 - COVINGTON FABRIC AND DESIGN LLC"
	},
	{
			"value": 358,
			"label": "177481 - STYLE ACCESSORIES INC"
	},
	{
			"value": 886,
			"label": "177488 - CANDLE WARMERS ETC"
	},
	{
			"value": 524,
			"label": "177511 - MGA ENTERTAINMENT - LITTLE TIKES"
	},
	{
			"value": 1019,
			"label": "177512 - DCWV ACQUISITION CORPORATION"
	},
	{
			"value": 645,
			"label": "177513 - SUNTOP TEXTILE (HK) CO LIMITED"
	},
	{
			"value": 104,
			"label": "177516 - TX USA CORPORATION"
	},
	{
			"value": 203,
			"label": "177520 - MAINSTREAM INTERNATIONAL INC"
	},
	{
			"value": 1074,
			"label": "177535 - METCOLORS LLC"
	},
	{
			"value": 1021,
			"label": "177542 - TERESA COLLINS LLC"
	},
	{
			"value": 361,
			"label": "177545 - GOLDEN SEAL ENTERPRISE LIMITED"
	},
	{
			"value": 844,
			"label": "177559 - MASTRAD INC"
	},
	{
			"value": 477,
			"label": "177560 - SHAOXING ENYI TEXTILE CO LTD"
	},
	{
			"value": 582,
			"label": "177562 - PLUS CORPORATION OF AMERICA"
	},
	{
			"value": 557,
			"label": "177565 - IMPERIAL TOY"
	},
	{
			"value": 1037,
			"label": "177570 - TOP KNIT CO LTD"
	},
	{
			"value": 520,
			"label": "177571 - TARA TOY CORP"
	},
	{
			"value": 468,
			"label": "177572 - UNION FABRICS PRIVATE LTD"
	},
	{
			"value": 527,
			"label": "177573 - DARICE DIRECT IMPORTS - J GARNER"
	},
	{
			"value": 507,
			"label": "177574 - ADVANTUS CORP/SULYN"
	},
	{
			"value": 902,
			"label": "177577 - SPARROW INNOVATIONS INC"
	},
	{
			"value": 402,
			"label": "177580 - DYNO SEASONAL SOLUTIONS"
	},
	{
			"value": 1075,
			"label": "177585 - ADVANCE SMART INTERNATIONAL COMPANY"
	},
	{
			"value": 553,
			"label": "177595 - HAMPTON ART INC"
	},
	{
			"value": 281,
			"label": "177596 - LOZIER STORE FIXTURES LLC"
	},
	{
			"value": 847,
			"label": "177600 - SISTERS' GOURMET INC"
	},
	{
			"value": 829,
			"label": "177602 - LAWN AND GARDEN INTERNATIONAL"
	},
	{
			"value": 1259,
			"label": "177614 - PLUS-PLUS USA LLC"
	},
	{
			"value": 63,
			"label": "177620 - EPOCH EVERLASTING PLAY LLC"
	},
	{
			"value": 1073,
			"label": "177625 - SEVEN APPAREL GROUP"
	},
	{
			"value": 4449,
			"label": "177631 - DIXON TICONDEROGA COMPANY (PACON)"
	},
	{
			"value": 241,
			"label": "177635 - CANON USA INC"
	},
	{
			"value": 84,
			"label": "177640 - SPINRITE CORP - PRIVATE LABEL"
	},
	{
			"value": 915,
			"label": "177645 - MPI MARKETING INC"
	},
	{
			"value": 396,
			"label": "177646 - TRIPLE-T DESIGNS INC DBA TOTALLY-TI"
	},
	{
			"value": 115,
			"label": "177651 - CC INTERNATIONAL LLC"
	},
	{
			"value": 377,
			"label": "177656 - BATES METAL PRODUCTS INC"
	},
	{
			"value": 838,
			"label": "177661 - LIFETIME BRANDS INC"
	},
	{
			"value": 1093,
			"label": "177667 - ORIGIN RED LIMITED"
	},
	{
			"value": 153,
			"label": "177668 - FIBRIX LLC"
	},
	{
			"value": 989,
			"label": "177672 - COMMEND (HK) LIMITED"
	},
	{
			"value": 730,
			"label": "177673 - NEWON CO LTD"
	},
	{
			"value": 485,
			"label": "177674 - TY INC"
	},
	{
			"value": 593,
			"label": "177675 - COLORFORMS BRAND LLC"
	},
	{
			"value": 659,
			"label": "177676 - KANTI FLOOR FURNISHERS"
	},
	{
			"value": 522,
			"label": "177680 - FASCINATIONS INC"
	},
	{
			"value": 71,
			"label": "177681 - LICENSE 2 PLAY TOYS LLC"
	},
	{
			"value": 1063,
			"label": "177690 - FATHEAD LLC"
	},
	{
			"value": 996,
			"label": "177691 - GLOBAL IDEAL UK LIMITED"
	},
	{
			"value": 795,
			"label": "177695 - WOO JIN CORP"
	},
	{
			"value": 276,
			"label": "177705 - LITTLE EARTH PRODUCTIONS INC"
	},
	{
			"value": 1071,
			"label": "177706 - SCOTT KENNEDY FINE ART LTD"
	},
	{
			"value": 914,
			"label": "177707 - HOUSEWORKS LTD"
	},
	{
			"value": 623,
			"label": "177710 - READERLINK DISTRIBUTION SERVICES"
	},
	{
			"value": 1087,
			"label": "177716 - ANCIENT GRAFFITI INC"
	},
	{
			"value": 841,
			"label": "177717 - ZAK DESIGNS"
	},
	{
			"value": 1117,
			"label": "177720 - ONE BELLA CASA"
	},
	{
			"value": 4464,
			"label": "177722 - GILDAN USA INC"
	},
	{
			"value": 129,
			"label": "177727 - WEBSTER FINE ART LTD DBA"
	},
	{
			"value": 211,
			"label": "177731 - P GRAHAM DUNN"
	},
	{
			"value": 1003,
			"label": "177740 - TALKING TABLES INC"
	},
	{
			"value": 806,
			"label": "177755 - SINCERE CREATES & MANUFACTURES LTD"
	},
	{
			"value": 997,
			"label": "177760 - GARTNER STUDIOS"
	},
	{
			"value": 970,
			"label": "177761 - FALCON SAFETY PRODUCTS INC"
	},
	{
			"value": 999,
			"label": "177765 - URBAN HOUSE DESIGN"
	},
	{
			"value": 210,
			"label": "177768 - SHANGHAI LIFETEX INDUSTRY CO LTD"
	},
	{
			"value": 638,
			"label": "177771 - PENGUIN & FISH LLC"
	},
	{
			"value": 130,
			"label": "177772 - JAFTEX CORPORATION STYLEMAKER"
	},
	{
			"value": 944,
			"label": "177774 - BYTECH NY"
	},
	{
			"value": 575,
			"label": "177776 - CARDINAL INDUSTRIES INC"
	},
	{
			"value": 159,
			"label": "177780 - ENVIRONMENTAL TECHNOLOGY INC"
	},
	{
			"value": 754,
			"label": "177791 - SKYLINE FURNITURE MFG"
	},
	{
			"value": 535,
			"label": "177801 - MADE4U STUDIO"
	},
	{
			"value": 579,
			"label": "177803 - ART 101 USA LTD"
	},
	{
			"value": 432,
			"label": "177804 - A E NATHAN CO INC"
	},
	{
			"value": 646,
			"label": "177820 - CAFFCO INTERNATIONAL LTD"
	},
	{
			"value": 545,
			"label": "177825 - WOWWEE USA INC"
	},
	{
			"value": 934,
			"label": "177830 - MERKURY INNOVATIONS LLC"
	},
	{
			"value": 1017,
			"label": "177831 - TEMKIN INTERNATIONAL INC"
	},
	{
			"value": 721,
			"label": "177832 - FREEZE"
	},
	{
			"value": 250,
			"label": "177833 - ZHEJIANG KATA TECHNOLOGY CO LTD"
	},
	{
			"value": 943,
			"label": "177835 - CS INTERNATIONAL (HK) TOYS LIMITED"
	},
	{
			"value": 1068,
			"label": "177840 - WORLD FOREST CO LTD"
	},
	{
			"value": 560,
			"label": "177841 - JUST PLAY LLC"
	},
	{
			"value": 534,
			"label": "177842 - TECH 4 KIDS INC"
	},
	{
			"value": 57,
			"label": "177843 - THE MAYA GROUP INC"
	},
	{
			"value": 869,
			"label": "177845 - C&A MARKETING"
	},
	{
			"value": 2143,
			"label": "177846 - CROWNJEWLZ LLC"
	},
	{
			"value": 242726,
			"label": "177847 - HEZE MAXWELL WOODEN PRODUCTS CO LTD"
	},
	{
			"value": 606,
			"label": "177852 - FANTASMA TOYS INC"
	},
	{
			"value": 928,
			"label": "177855 - MADE FOR RETAIL INC"
	},
	{
			"value": 1064,
			"label": "177865 - KW TEXTILE INC"
	},
	{
			"value": 927,
			"label": "177873 - ZHUJI SICHUANG TRADING CO LTD"
	},
	{
			"value": 1095,
			"label": "177875 - ACCESSORY INNOVATIONS LLC"
	},
	{
			"value": 233,
			"label": "177880 - BOSVILLE LTD"
	},
	{
			"value": 342,
			"label": "177883 - FROM THE HEART ENTERPRISES INC"
	},
	{
			"value": 292,
			"label": "177885 - DC08 - PFSWEB INC"
	},
	{
			"value": 1089,
			"label": "177886 - BIG MOUTH INC"
	},
	{
			"value": 442,
			"label": "177887 - MOHAWK CARPET DISTRIBUTION INC"
	},
	{
			"value": 549,
			"label": "177890 - TAILOR MADE PRODUCTS INC"
	},
	{
			"value": 1069,
			"label": "177896 - WEAVE GOT MAILLE"
	},
	{
			"value": 1111,
			"label": "177901 - EVOLUTIONEYES INC"
	},
	{
			"value": 836,
			"label": "177902 - TRUDEAU CORPORATION"
	},
	{
			"value": 1081,
			"label": "177905 - THIN AIR BRANDS LLC"
	},
	{
			"value": 200,
			"label": "177906 - PRO TAPES & SPECIALTIES INC"
	},
	{
			"value": 716,
			"label": "177916 - NAKOMA PRODUCTS LLC"
	},
	{
			"value": 330,
			"label": "177920 - TEXPA LLC"
	},
	{
			"value": 354,
			"label": "177931 - AKA MYSTIQUE ACCESSORIES LLC"
	},
	{
			"value": 1255,
			"label": "177941 - SWIFT RESPONSE LLC"
	},
	{
			"value": 410,
			"label": "177942 - DESIGNA TECH (HONG KONG) COMPANY LT"
	},
	{
			"value": 505,
			"label": "177943 - PRESSMAN TOY DBA GOLIATH"
	},
	{
			"value": 268,
			"label": "177945 - RICO INDUSTRIES INC"
	},
	{
			"value": 378,
			"label": "177950 - POPPIN INC"
	},
	{
			"value": 147,
			"label": "177955 - CAFFCO INTERNATIONAL LTD"
	},
	{
			"value": 17,
			"label": "177957 - AURIENT INTERNATIONAL CORP"
	},
	{
			"value": 707,
			"label": "177958 - ILLUME HOLDING COMPANY LLC"
	},
	{
			"value": 807,
			"label": "177960 - SIERRA CASCADE FOREST PRODUCTS"
	},
	{
			"value": 511,
			"label": "177961 - SUPER IMPULSE USA LLC"
	},
	{
			"value": 731,
			"label": "177966 - ASIAN CRAFT INC"
	},
	{
			"value": 92183,
			"label": "177967 - CRAFTLAND INC"
	},
	{
			"value": 126,
			"label": "177970 - LONG KING PRINTING HK COMPANY LTD"
	},
	{
			"value": 265,
			"label": "177975 - WINCRAFT INCORPORATED"
	},
	{
			"value": 454,
			"label": "177976 - SRI RAMLAKSHMAN FABS"
	},
	{
			"value": 466,
			"label": "177980 - RILEY BLAKE DESIGNS"
	},
	{
			"value": 671,
			"label": "177985 - RILEY BLAKE DESIGNS"
	},
	{
			"value": 238615,
			"label": "177995 - MILLCRAFT PAPERDONOTUSE"
	},
	{
			"value": 445,
			"label": "178000 - BP INDUSTRIES INC"
	},
	{
			"value": 60,
			"label": "178006 - CHINA SURPLUS INTERNATIONAL LIMITED"
	},
	{
			"value": 576,
			"label": "178007 - BALL BOUNCE AND SPORT INC"
	},
	{
			"value": 53,
			"label": "178010 - MAKE IT REAL LLC"
	},
	{
			"value": 181,
			"label": "178011 - GUANGZHOU YOUTH ARTS AND CRAFT"
	},
	{
			"value": 675,
			"label": "178020 - KREBS INTERNATIONAL CO LTD"
	},
	{
			"value": 4609,
			"label": "178025 - JEWELRY MADE BY ME LTD"
	},
	{
			"value": 386,
			"label": "178030 - CREATIVE HOME LTD"
	},
	{
			"value": 229,
			"label": "178040 - SUGRU INC"
	},
	{
			"value": 1033,
			"label": "178050 - TEXRAY INDUSTRIAL CO LTD"
	},
	{
			"value": 500,
			"label": "178060 - BASIC FUN"
	},
	{
			"value": 510,
			"label": "178063 - WECOOL TOYS INC"
	},
	{
			"value": 750,
			"label": "178071 - FUN KINGDOM INDUSTRIES CO LTD"
	},
	{
			"value": 1025,
			"label": "178081 - WORTHINGTON CYLINDERS"
	},
	{
			"value": 12,
			"label": "178082 - HALCRAFT USA INC"
	},
	{
			"value": 913,
			"label": "178086 - LIFTLINE CAPITAL LLC DBA STENCIL EA"
	},
	{
			"value": 602,
			"label": "178087 - CHAMELEON ART PRODUCTS INC"
	},
	{
			"value": 187,
			"label": "178088 - MILLENNIUM GIFTS LIMITED"
	},
	{
			"value": 13,
			"label": "178092 - QUEEN CRAFTS LIMITED"
	},
	{
			"value": 376,
			"label": "178101 - SPECTRUM"
	},
	{
			"value": 935,
			"label": "178104 - ALL IN THE CARDS"
	},
	{
			"value": 685,
			"label": "178108 - SIENNA INTERNATIONAL (HONG KONG)"
	},
	{
			"value": 622,
			"label": "178110 - INQBRANDS INC"
	},
	{
			"value": 515,
			"label": "178115 - PAPER MAGIC GROUP (HONG KONG)"
	},
	{
			"value": 45,
			"label": "178120 - SPIN MASTER INC"
	},
	{
			"value": 661,
			"label": "178126 - TRENDS INTERNATIONAL LLC"
	},
	{
			"value": 942,
			"label": "178129 - TMD HOLDINGS LLC"
	},
	{
			"value": 393,
			"label": "178135 - LADY JAYNE LTD"
	},
	{
			"value": 617,
			"label": "178145 - LASER PEGS VENTURES LLC"
	},
	{
			"value": 269,
			"label": "178150 - THE BOELTER COMPANIES"
	},
	{
			"value": 251,
			"label": "178160 - GOLDEN CITY GLOBAL LIMITED"
	},
	{
			"value": 397,
			"label": "178165 - F&M TOOL AND PLASTICS INC"
	},
	{
			"value": 991,
			"label": "178166 - DIRECT LINK SOURCING LLC"
	},
	{
			"value": 177,
			"label": "178167 - STUDIO ELUCEO LTD"
	},
	{
			"value": 1012,
			"label": "178171 - CARDINAL DIRECT COMPANY"
	},
	{
			"value": 742,
			"label": "178180 - HANDY ART INC"
	},
	{
			"value": 5530,
			"label": "178181 - SUNNY DAYS ENTERTAINMENT LLC"
	},
	{
			"value": 554,
			"label": "178186 - YOYO LIP GLOSS INC"
	},
	{
			"value": 512,
			"label": "178190 - BRMB INC DBA BRICTEK"
	},
	{
			"value": 972,
			"label": "178195 - STUDIO DESIGNS INC"
	},
	{
			"value": 931,
			"label": "178196 - 2 GIRLS ACCESSORIES INC"
	},
	{
			"value": 689,
			"label": "178200 - MIDWEST DESIGN IMPORTS INC"
	},
	{
			"value": 4473,
			"label": "178201 - KIKKERLAND DESIGN INC"
	},
	{
			"value": 257,
			"label": "178202 - ST LOUIS CRAFTS INC"
	},
	{
			"value": 1000,
			"label": "178204 - WIDE VISTA GROUP LTD"
	},
	{
			"value": 574,
			"label": "178205 - BEVERLY HILLS TEDDY BEAR COMPANY"
	},
	{
			"value": 253,
			"label": "178211 - IMPACT DESIGN LLC"
	},
	{
			"value": 1052,
			"label": "178225 - GOURMET HOME PRODUCTS LLC"
	},
	{
			"value": 580,
			"label": "178227 - ZURU LLC"
	},
	{
			"value": 1008,
			"label": "178230 - AMERICAN CRAFTS LC"
	},
	{
			"value": 198,
			"label": "178231 - L & S BRANDS LLC"
	},
	{
			"value": 581,
			"label": "178233 - WATCH-US INC"
	},
	{
			"value": 300,
			"label": "178240 - WOODA CORP LTD"
	},
	{
			"value": 483,
			"label": "178241 - EDUCATORS RESOURCE INC"
	},
	{
			"value": 68,
			"label": "178242 - K'NEX LIMITED PARTNERSHIP GROUP"
	},
	{
			"value": 380,
			"label": "178250 - PUNCH STUDIO LLC"
	},
	{
			"value": 697,
			"label": "178257 - H&H ASIA LTD"
	},
	{
			"value": 903,
			"label": "178260 - JEFF MCWILLIAMS DESIGNS LLC"
	},
	{
			"value": 923,
			"label": "178261 - JEFF MCWILLIAMS DESIGNS"
	},
	{
			"value": 907,
			"label": "178265 - K&K INTERIORS INC"
	},
	{
			"value": 852,
			"label": "178266 - CHOCOLATE DELIVERY SYSTEMS INC"
	},
	{
			"value": 379,
			"label": "178268 - SARAHS CRAFTS"
	},
	{
			"value": 150,
			"label": "178270 - CREATIVEBUG INTERNAL SALES"
	},
	{
			"value": 695,
			"label": "178271 - VALYRIA LLC DBA TRANSPAC"
	},
	{
			"value": 490,
			"label": "178272 - PLAYTEK LLC"
	},
	{
			"value": 316,
			"label": "178274 - GUL AHMED TEXTILE MILLS LTD"
	},
	{
			"value": 636,
			"label": "178275 - LION BRAND YARN COMPANY"
	},
	{
			"value": 1384,
			"label": "178277 - HONEY CAN DO INTERNATIONAL LLC"
	},
	{
			"value": 193,
			"label": "178282 - CONAIR CORPORATION"
	},
	{
			"value": 803,
			"label": "178283 - SIBAFLOR NATURAL DECORATIONS"
	},
	{
			"value": 677,
			"label": "178284 - GANZ USA LLC"
	},
	{
			"value": 459,
			"label": "178285 - NISHAT CHUNIAN LTD"
	},
	{
			"value": 561,
			"label": "178286 - SCHLEICH USA INC"
	},
	{
			"value": 722,
			"label": "178290 - NINGBO SHENGTONG FASHION"
	},
	{
			"value": 1106,
			"label": "178292 - HEARTHMARK LLC DBA JARDEN HOME"
	},
	{
			"value": 950,
			"label": "178297 - 360 ELECTRICAL"
	},
	{
			"value": 169,
			"label": "178300 - RAM COIR MILLS"
	},
	{
			"value": 912,
			"label": "178301 - DIAMOND STAR CORPORATION"
	},
	{
			"value": 613,
			"label": "178303 - NINGBO SRS INTERNATIONAL TRADING CO"
	},
	{
			"value": 2394,
			"label": "178304 - POP SOCKETS LLC"
	},
	{
			"value": 266,
			"label": "178305 - THE NORTHWEST COMPANY"
	},
	{
			"value": 839,
			"label": "178315 - CAKE CRAFT LLC"
	},
	{
			"value": 746,
			"label": "178320 - EASTER UNLIMITED/FUN WORLD"
	},
	{
			"value": 4485,
			"label": "178330 - PROFESSOR PUZZLE LTD"
	},
	{
			"value": 172,
			"label": "178335 - MADE MODERN LLC"
	},
	{
			"value": 504,
			"label": "178337 - BRANDABLE INC"
	},
	{
			"value": 244357,
			"label": "178338 - RELIABLE CORPORATION (ECOM)"
	},
	{
			"value": 274374,
			"label": "178339 - RIMPORTS LLC"
	},
	{
			"value": 556,
			"label": "178352 - OUTSET MEDIA CORPORATION"
	},
	{
			"value": 539,
			"label": "178355 - NORTHERN LIGHTS"
	},
	{
			"value": 676,
			"label": "178360 - HALLMARK MARKETING COMPANY LLC"
	},
	{
			"value": 180,
			"label": "178361 - JUKI AMERICA INC"
	},
	{
			"value": 152,
			"label": "178366 - GRACEWOOD MANAGEMENT"
	},
	{
			"value": 1115,
			"label": "178370 - SHANGHAI JETLINK CRAFTS INC"
	},
	{
			"value": 243842,
			"label": "178371 - NINGBO XINTENG NEEDLE CO LTD"
	},
	{
			"value": 499,
			"label": "178375 - HONGKONG SIMPLE ELEMENT GLOBAL LIMI"
	},
	{
			"value": 543,
			"label": "178380 - UKIDZ LLC"
	},
	{
			"value": 310,
			"label": "178381 - HUANGYAN FOREVER ARTS & CRAFTS FACT"
	},
	{
			"value": 492,
			"label": "178390 - AZTEC IMPORTS INC"
	},
	{
			"value": 517,
			"label": "178391 - ROUND 2 LLC"
	},
	{
			"value": 514,
			"label": "178395 - CRAFT A BREW"
	},
	{
			"value": 530,
			"label": "178397 - WHITMAN PUBLISHING LLC"
	},
	{
			"value": 491,
			"label": "178401 - INNOVATION FIRST LABS INC"
	},
	{
			"value": 526,
			"label": "178405 - ESTES INDUSTRIES LLC"
	},
	{
			"value": 1002,
			"label": "178406 - LILY & VAL LLC"
	},
	{
			"value": 592,
			"label": "178411 - BSM ENTERPRISE LTD"
	},
	{
			"value": 202,
			"label": "178412 - SINGSONG INTERNATIONAL TRADE CO LIM"
	},
	{
			"value": 895,
			"label": "178413 - THE SHERWIN-WILLIAMS COMPANY"
	},
	{
			"value": 262,
			"label": "178414 - PRIME BRANDS GROUP INC"
	},
	{
			"value": 691,
			"label": "178417 - EXPRESSIVE DESIGN GROUP ASIA LTD"
	},
	{
			"value": 813,
			"label": "178420 - SHANDONG EXCEL LIGHT INDUSTRIAL"
	},
	{
			"value": 496,
			"label": "178421 - TAIZHOU HONFONT IMPORT & EXPORT CO"
	},
	{
			"value": 346,
			"label": "178425 - CAROLE ACCESSORIES INC"
	},
	{
			"value": 610,
			"label": "178428 - JOINT MERCHANT LLC"
	},
	{
			"value": 389,
			"label": "178429 - YU SHAN"
	},
	{
			"value": 392,
			"label": "178435 - KETER CANADA"
	},
	{
			"value": 400,
			"label": "178445 - REALLY USEFUL PRODUCTS LTD"
	},
	{
			"value": 175,
			"label": "178448 - ZEST GARDEN INC"
	},
	{
			"value": 398,
			"label": "178449 - DEFLECTO LLC"
	},
	{
			"value": 259,
			"label": "178450 - YOU THE FAN"
	},
	{
			"value": 353,
			"label": "178451 - SHANGHAI YIMEI ARTS & CRAFTS CO LTD"
	},
	{
			"value": 804,
			"label": "178452 - LIAONING JIEYUE IMPORT AND EXPORT C"
	},
	{
			"value": 764,
			"label": "178453 - P/K LIFESTYLES"
	},
	{
			"value": 918,
			"label": "178455 - GLOWFORGE INC"
	},
	{
			"value": 160,
			"label": "178460 - PACIFIC ISLAND CREATIONS CO LTD"
	},
	{
			"value": 658,
			"label": "178465 - SHARE-WELL ZHANG PING INDUSTRIAL CO"
	},
	{
			"value": 817,
			"label": "178470 - YONG HENG DA HANDWORKS LIMITED"
	},
	{
			"value": 473,
			"label": "178476 - ADVANCED DIGITAL TEXTILES"
	},
	{
			"value": 436,
			"label": "178477 - VALLEY FORGE FABRICS INC"
	},
	{
			"value": 231,
			"label": "178478 - FLEMING INTERNATIONAL LTD"
	},
	{
			"value": 313,
			"label": "178480 - YAMA RIBBONS AND BOWS CO LTD"
	},
	{
			"value": 780,
			"label": "178485 - HANGZHOU HELMTEX MILLS LTD"
	},
	{
			"value": 591,
			"label": "178491 - SKYROCKET LLC"
	},
	{
			"value": 277,
			"label": "178492 - FUNKO LLC"
	},
	{
			"value": 565,
			"label": "178493 - FLYCATCHER INC"
	},
	{
			"value": 547,
			"label": "178495 - JMW SALES INC"
	},
	{
			"value": 568,
			"label": "178496 - OZWEST INC"
	},
	{
			"value": 274,
			"label": "178497 - KOLDER INC"
	},
	{
			"value": 782,
			"label": "178499 - COVINGTON FABRIC & DESIGN LLC"
	},
	{
			"value": 317,
			"label": "178500 - YUNUS TEXTILE MILL PVT LTD"
	},
	{
			"value": 438,
			"label": "178505 - FUZHOU UNIQUE HOME DECOR CO LTD"
	},
	{
			"value": 360,
			"label": "178506 - JOHN BEAD CORPORATION LIMITED"
	},
	{
			"value": 267,
			"label": "178507 - WORTHY PROMOTIONAL PRODUCTS"
	},
	{
			"value": 513,
			"label": "178510 - NINGBO GOLDENTIME IMPORT & EXPORT C"
	},
	{
			"value": 59747,
			"label": "178511 - INAM ZARI & MOON ZARI INDUSTRIES"
	},
	{
			"value": 201,
			"label": "178515 - MINHOU FUBON ARTS & CRAFTS CO LTD"
	},
	{
			"value": 673541,
			"label": "178516 - WESTEX-INNOVATIVE (ECOM)"
	},
	{
			"value": 455,
			"label": "178520 - GA EXPORT (THAILAND) CO LTD"
	},
	{
			"value": 657,
			"label": "178525 - ALPS INDUSTRIES LIMITED (CMT)"
	},
	{
			"value": 15334,
			"label": "178526 - SANTOKI LLC"
	},
	{
			"value": 321,
			"label": "178530 - MN TEXTILES PVT LTD"
	},
	{
			"value": 717,
			"label": "178535 - ALOR STYLE CORP"
	},
	{
			"value": 260,
			"label": "178537 - FBF ORIGINALS"
	},
	{
			"value": 61,
			"label": "178540 - MADE4U STUDIO LLC"
	},
	{
			"value": 727,
			"label": "178545 - ICON EYEWEAR INC"
	},
	{
			"value": 234,
			"label": "178550 - IERA LIVING DESIGNS LLP"
	},
	{
			"value": 344,
			"label": "178555 - JOHN BEAD CORPORATION LIMITED"
	},
	{
			"value": 1055,
			"label": "178565 - E-FILLIATE INC"
	},
	{
			"value": 413,
			"label": "178566 - SWISSCO LLC"
	},
	{
			"value": 447,
			"label": "178567 - HOME ESSENTIALS AND BEYOND"
	},
	{
			"value": 139,
			"label": "178570 - YORK (ASIA) LIMITED"
	},
	{
			"value": 1032,
			"label": "178575 - ARTEX APPARELS"
	},
	{
			"value": 1036,
			"label": "178576 - FABTRENDS USA CORP"
	},
	{
			"value": 939,
			"label": "178577 - WILD & WOLF INC"
	},
	{
			"value": 189,
			"label": "178580 - LUCKY TEXTILE MILLS LIMITED"
	},
	{
			"value": 933,
			"label": "178587 - BROS PRODUCTS COMPANY LIMITED"
	},
	{
			"value": 4409,
			"label": "178596 - DAN DEE INTERNATIONAL LLC"
	},
	{
			"value": 538,
			"label": "178600 - KESSLER CORPORATION"
	},
	{
			"value": 488,
			"label": "178601 - WEVEEL LLC"
	},
	{
			"value": 577,
			"label": "178602 - UCC DISTRIBUTING INC"
	},
	{
			"value": 54,
			"label": "178603 - LEGO SYSTEMS INC"
	},
	{
			"value": 546,
			"label": "178604 - BUZZ BEE TOYS (HK) CO LIMITED"
	},
	{
			"value": 4483,
			"label": "178606 - ORLY SHOE CORP"
	},
	{
			"value": 760,
			"label": "178610 - SHANGHAI ALLTEX TECHNOLOGY CO LTD"
	},
	{
			"value": 887,
			"label": "178611 - JACMAX INDUSTRIES LLC"
	},
	{
			"value": 572,
			"label": "178612 - SCHYLLING INC"
	},
	{
			"value": 4408,
			"label": "178627 - DAN-DEE INTERNATIONAL LLC"
	},
	{
			"value": 494,
			"label": "178629 - REVELL USA LLC"
	},
	{
			"value": 793,
			"label": "178635 - BARROW INDUSTRIES INC"
	},
	{
			"value": 940,
			"label": "178636 - THE COOKWARE COMPANY (USA) LLC"
	},
	{
			"value": 667,
			"label": "178640 - NATIONAL 4-H COUNCIL"
	},
	{
			"value": 767,
			"label": "178645 - DCM TEKSTIL KONFEKSIYON SAN VE TIC"
	},
	{
			"value": 621,
			"label": "178646 - ORMO ITHALAT VE IHRACAT AS"
	},
	{
			"value": 362,
			"label": "178650 - CHEONG IN"
	},
	{
			"value": 672,
			"label": "178653 - PLUSMARK/AMERICAN GREETINGS"
	},
	{
			"value": 255,
			"label": "178655 - PNY TECHNOLOGIES INC"
	},
	{
			"value": 4479,
			"label": "178656 - PETS FIRST"
	},
	{
			"value": 890,
			"label": "178660 - FOX RUN USA"
	},
	{
			"value": 2417,
			"label": "178665 - DENIK LLC"
	},
	{
			"value": 781,
			"label": "178670 - GM FABRICS PVT LTD"
	},
	{
			"value": 2387,
			"label": "178680 - PREMIER PRINTS INC (ECOM)"
	},
	{
			"value": 452,
			"label": "178681 - WILLOW GROUP LTD"
	},
	{
			"value": 2395,
			"label": "178682 - HONEY CAN DO INTERNATIONAL (ECOM)"
	},
	{
			"value": 1029,
			"label": "178683 - THE FELDMAN COMPANY INC (ECOM)"
	},
	{
			"value": 906,
			"label": "178684 - RUBBERMAID INCORPORATED"
	},
	{
			"value": 701,
			"label": "178685 - SYMBOL GIFT INC"
	},
	{
			"value": 680989,
			"label": "178686 - TEMPO PRODUCTS (ECOM)"
	},
	{
			"value": 952,
			"label": "178690 - PELICAN BAY"
	},
	{
			"value": 614,
			"label": "178692 - ALBERT + HAZEL LLC"
	},
	{
			"value": 973,
			"label": "178695 - STUDIO DESIGNS (ECOM)"
	},
	{
			"value": 840,
			"label": "178696 - HAMILTON BEACH BRANDS"
	},
	{
			"value": 528,
			"label": "178697 - NEEDLEART WORLD (HONG KONG) LTD"
	},
	{
			"value": 752,
			"label": "178700 - YORK WALLCOVERINGS (ECOM)"
	},
	{
			"value": 677821,
			"label": "178701 - S LICHTENBERG & CO INC (ECOM)"
	},
	{
			"value": 794,
			"label": "178705 - ZENITH TEXTILES (A UNIT OF ZENITH"
	},
	{
			"value": 446,
			"label": "178706 - THE HILLMAN GROUP INC"
	},
	{
			"value": 857,
			"label": "178721 - DISTRIVALTO USA INC"
	},
	{
			"value": 381,
			"label": "178722 - NINGBO SHUYANG WRAPPING CO LTD"
	},
	{
			"value": 849,
			"label": "178723 - DURABLE PACKAGING INTERNATIONAL"
	},
	{
			"value": 858,
			"label": "178725 - SELECT BRANDS INC"
	},
	{
			"value": 957,
			"label": "178730 - CALIFORNIA FRUIT EXCHANGE LLC"
	},
	{
			"value": 2321,
			"label": "178735 - LOGO BRANDS INC"
	},
	{
			"value": 2320,
			"label": "178737 - THE SPORTS VAULT CORP"
	},
	{
			"value": 2328,
			"label": "178738 - ZHUHAI ZE YUAN CRAFT FLORAL"
	},
	{
			"value": 209,
			"label": "178740 - SHANGHAI UEE ZEE ADHESIVE PRODUCT C"
	},
	{
			"value": 2322,
			"label": "178745 - PKWY LLC"
	},
	{
			"value": 2324,
			"label": "178746 - HERITAGE EXPORTS"
	},
	{
			"value": 770,
			"label": "178747 - GLOBAL TEXTILE ALLIANCE"
	},
	{
			"value": 812,
			"label": "178748 - XIAMEN BOSDA ENTERPRISES"
	},
	{
			"value": 2314,
			"label": "178750 - STUDIO ELUCEO LTD"
	},
	{
			"value": 2337,
			"label": "178751 - LONG RICH INDUSTRIAL LTD"
	},
	{
			"value": 818,
			"label": "178760 - GUANGDONG YIBOXUAN CERAMICS CO LTD"
	},
	{
			"value": 2368,
			"label": "178765 - TODAYS TREASURES INC"
	},
	{
			"value": 2364,
			"label": "178766 - COUNTRY LINE (INTERNATIONAL) LTD"
	},
	{
			"value": 2312,
			"label": "178767 - SHANGHAI LIFETEX INDUSTRY CO LTD"
	},
	{
			"value": 2361,
			"label": "178768 - SEASONS (HK) LTD"
	},
	{
			"value": 2326,
			"label": "178770 - DESIGNS FOR ALL SEASONS LTD"
	},
	{
			"value": 2335,
			"label": "178775 - PEAKTOP GROUP LIMITED"
	},
	{
			"value": 2343,
			"label": "178777 - LUCKY STAR ENTERPRISE AND CO LTD"
	},
	{
			"value": 2356,
			"label": "178778 - FUJIAN SAILLIA LIGHT INTL GROUP CO"
	},
	{
			"value": 2375,
			"label": "178779 - TAIZHOU INTERNATIONAL TRADE CORPORA"
	},
	{
			"value": 2323,
			"label": "178780 - NEW TIMES CREATION CO LTD"
	},
	{
			"value": 2303,
			"label": "178781 - LIBRA PACIFIC CO LTD"
	},
	{
			"value": 370,
			"label": "178783 - JAMESTOWN CONTAINER CORPORATION"
	},
	{
			"value": 2372,
			"label": "178784 - YIWU HOBO IMP&EXP CO LTD"
	},
	{
			"value": 4415,
			"label": "178785 - NEARLY NATURAL LLC (ECOM)"
	},
	{
			"value": 2307,
			"label": "178786 - GUANGZHOU YOUTH ARTS AND CRAFTS CO"
	},
	{
			"value": 4500,
			"label": "178788 - INTERNATIONAL PAPER"
	},
	{
			"value": 2333,
			"label": "178790 - GLOBAL BEST INDUSTRIAL LTD"
	},
	{
			"value": 2299,
			"label": "178791 - WIN HANG ENTERPRISE LIMITED"
	},
	{
			"value": 2385,
			"label": "178792 - JOYFUL LANDS CRAFT CO LTD"
	},
	{
			"value": 2353,
			"label": "178793 - LONG KING PRINTING HK COMPANY LIMIT"
	},
	{
			"value": 2310,
			"label": "178794 - ZHEJIANG KATA TECHNOLOGY CO LTD"
	},
	{
			"value": 2384,
			"label": "178795 - COMMEND (H.K.) LIMITED"
	},
	{
			"value": 2311,
			"label": "178796 - ALL COURTESY INTERNATIONAL LIMITED"
	},
	{
			"value": 205,
			"label": "178797 - EDI TEST VENDOR 1 WHSE"
	},
	{
			"value": 190,
			"label": "178798 - EDI TEST VENDOR 2 WHSE"
	},
	{
			"value": 469,
			"label": "178799 - EDI TEST VENDOR FABRIC"
	},
	{
			"value": 136,
			"label": "178800 - EDI TEST VENDOR STORE"
	},
	{
			"value": 22223,
			"label": "178801 - SUNSTAR INDUSTRIES"
	},
	{
			"value": 2350,
			"label": "178802 - UNIQUE TREASURES LTD"
	},
	{
			"value": 2396,
			"label": "178803 - ASTAGE GLOBAL INC"
	},
	{
			"value": 2381,
			"label": "178804 - MODERN MARKETING CONCEPTS INC"
	},
	{
			"value": 2342,
			"label": "178805 - NINGBO FEIHONG STATIONERY LIMITED C"
	},
	{
			"value": 861,
			"label": "178806 - ENVIRONMENTAL BUSINESS PRODUCTS LTD"
	},
	{
			"value": 2380,
			"label": "178807 - LIFEWORKS TECHNOLOGY GROUP LLC"
	},
	{
			"value": 2382,
			"label": "178808 - SHANGHAI AIMI PET PRODUCTS CO LTD"
	},
	{
			"value": 2340,
			"label": "178809 - QINGDAO LIBANG KINGTONE TRADE CO LT"
	},
	{
			"value": 2332,
			"label": "178810 - RONG FA LI (SUN LI FUNG)"
	},
	{
			"value": 4494,
			"label": "178811 - JIANGSU PHOENIX ART MATERIALS"
	},
	{
			"value": 958,
			"label": "178812 - ENERCO GROUP INC"
	},
	{
			"value": 4811,
			"label": "178813 - WEALTH CONCEPT LTD"
	},
	{
			"value": 2297,
			"label": "178814 - CRE8 DIRECT (NINGBO) CO LTD"
	},
	{
			"value": 242327,
			"label": "178815 - VIITION (ASIA) LIMITED"
	},
	{
			"value": 2336,
			"label": "178817 - SHIENQ HUONG ENTERPRISE CO LTD"
	},
	{
			"value": 2348,
			"label": "178818 - INNOVATIVE CREATIONS INC"
	},
	{
			"value": 2309,
			"label": "178822 - MINHOU MINXING WEAVING CO LTD"
	},
	{
			"value": 147537,
			"label": "178823 - ABS INTERNATIONAL INC"
	},
	{
			"value": 2330,
			"label": "178824 - DECO DE TREND"
	},
	{
			"value": 865,
			"label": "178825 - SCULPTO APS"
	},
	{
			"value": 2360,
			"label": "178826 - SARA ROSE INTERNATIONAL INC"
	},
	{
			"value": 2305,
			"label": "178827 - ELITE COMFORT SOLUTIONS INC"
	},
	{
			"value": 2401,
			"label": "178829 - KINGART (ECOM)"
	},
	{
			"value": 278,
			"label": "178830 - UNCANNY BRANDS LLC"
	},
	{
			"value": 2378,
			"label": "178831 - EVKM TECHNOLOGIES LLC"
	},
	{
			"value": 2304,
			"label": "178832 - JONDER INDUSTRIES LTD"
	},
	{
			"value": 2359,
			"label": "178833 - NINGBO SRS INTERNATIONAL TRADING CO"
	},
	{
			"value": 2352,
			"label": "178834 - MJ HOLDINGS"
	},
	{
			"value": 2306,
			"label": "178835 - LORETTA LEE LTD"
	},
	{
			"value": 2366,
			"label": "178836 - ZHEJIANG TONG FENG ARTS & CRAFTS CO"
	},
	{
			"value": 2379,
			"label": "178837 - ZHUJI SICHUANG TRADING CO LTD"
	},
	{
			"value": 2329,
			"label": "178838 - ELEGANT HOME LTD"
	},
	{
			"value": 2315,
			"label": "178839 - BOSVILLE LTD"
	},
	{
			"value": 4436,
			"label": "178840 - VIETNAM HOUSEWARES CORPORATION"
	},
	{
			"value": 2371,
			"label": "178841 - SEASONS SPECIAL CO LTD"
	},
	{
			"value": 2346,
			"label": "178842 - HYPERCAP TRADING COMPANY"
	},
	{
			"value": 2325,
			"label": "178843 - ORIENTAL CRAFT IND CO LTD"
	},
	{
			"value": 2331,
			"label": "178844 - UNIVIC FLORAL COMPANY LIMITED"
	},
	{
			"value": 2339,
			"label": "178846 - EDGE ACCESSORIES LTD"
	},
	{
			"value": 37365,
			"label": "178848 - DWELL & DECOR OUTDOOR LLC"
	},
	{
			"value": 2313,
			"label": "178849 - MILLENNIUM GIFTS LIMITED"
	},
	{
			"value": 2344,
			"label": "178851 - NATURE CRAFT COMPANY LIMITED"
	},
	{
			"value": 155618,
			"label": "178852 - PIK PAK INDUSTRIES"
	},
	{
			"value": 4407,
			"label": "178853 - ELLISON EDUCATIONAL EQUIPMENT INC"
	},
	{
			"value": 2391,
			"label": "178854 - EVERYTHING LEGWEAR HOLDINGS LLC"
	},
	{
			"value": 2334,
			"label": "178855 - LAWN AND GARDEN INTERNATIONAL LIMIT"
	},
	{
			"value": 2377,
			"label": "178856 - ZIBO YADONG I/E TRADE CO LTD"
	},
	{
			"value": 2317,
			"label": "178858 - PEGASUS SPORTS LLC"
	},
	{
			"value": 2354,
			"label": "178859 - IMAGIMAKE"
	},
	{
			"value": 2373,
			"label": "178860 - NEWON CO LTD"
	},
	{
			"value": 2327,
			"label": "178861 - PROMAX MANUFACTURING CO LTD"
	},
	{
			"value": 2355,
			"label": "178862 - NINGBO MERRYART GLOW-TECH CO LTD"
	},
	{
			"value": 2370,
			"label": "178863 - ART FAMOUS LIMITED"
	},
	{
			"value": 2316,
			"label": "178864 - MAY FUNG PLASTIC FACTORY (HK) LIMIT"
	},
	{
			"value": 2302,
			"label": "178865 - CHINA NATIONAL ARTS & CRAFTS"
	},
	{
			"value": 4432,
			"label": "178866 - ZIBO ZHAOHAI LIGHT INDUSTRIAL"
	},
	{
			"value": 4435,
			"label": "178867 - GUANGZHOU XY PAPER CO LTD"
	},
	{
			"value": 2399,
			"label": "178868 - R.R. INTERNATIONAL"
	},
	{
			"value": 2318,
			"label": "178870 - GREAT AMERICAN PRODUCTS"
	},
	{
			"value": 2319,
			"label": "178877 - AMINCO"
	},
	{
			"value": 2376,
			"label": "178878 - ZHEJIANG IVY CRAFTS DESIGN CO LTD"
	},
	{
			"value": 2301,
			"label": "178880 - SHENYANG LARGE CIRCLE ARTS & CRAFTS"
	},
	{
			"value": 2338,
			"label": "178882 - BANARAS BEADS LIMITED"
	},
	{
			"value": 13366,
			"label": "178883 - FAR EASTERN HANDICRAFT JSC-VIETNAM"
	},
	{
			"value": 4454,
			"label": "178884 - SIMPLAY3 (ECOM)"
	},
	{
			"value": 4413,
			"label": "178885 - DECORATIVE TRIMMINGS LLC"
	},
	{
			"value": 2402,
			"label": "178888 - DML MARKETING GROUP LTD"
	},
	{
			"value": 2403,
			"label": "178889 - PARAMOUNT HOME COLLECTIONS"
	},
	{
			"value": 11425,
			"label": "178890 - THUMBS UP"
	},
	{
			"value": 4410,
			"label": "178891 - HUAIAN FULLYA INTERNATIONAL"
	},
	{
			"value": 2397,
			"label": "178892 - AB EXPORTS"
	},
	{
			"value": 2390,
			"label": "178893 - OPEN ROAD BRANDS LLC"
	},
	{
			"value": 2392,
			"label": "178894 - PEBBLES"
	},
	{
			"value": 2393,
			"label": "178895 - BERNINA OF AMERICA LLC"
	},
	{
			"value": 4426,
			"label": "178899 - DESIGN IMPORTS (ECOM)"
	},
	{
			"value": 2419,
			"label": "178900 - TRIMCRAFT"
	},
	{
			"value": 673048,
			"label": "178901 - HIGH INTENCITY (DO NOT USE)"
	},
	{
			"value": 2404,
			"label": "178905 - FAIRTON ASIA LIMITED"
	},
	{
			"value": 4437,
			"label": "178906 - ZHANGZHOU TOP BRITE INDUSTRY TRADE"
	},
	{
			"value": 4445,
			"label": "178907 - ACHAL AMIT & CO"
	},
	{
			"value": 4440,
			"label": "178911 - FRIDAY PATTERN COMPANY"
	},
	{
			"value": 4429,
			"label": "178912 - OXO"
	},
	{
			"value": 4488,
			"label": "178915 - SHAOXING MUYUN TRADING CO LTD"
	},
	{
			"value": 4406,
			"label": "178916 - RATAN PAPERS PVT LTD"
	},
	{
			"value": 4414,
			"label": "178917 - DIVA EXPORTS"
	},
	{
			"value": 2408,
			"label": "178918 - NEW LEAF SERVICE CONTRACTS, LLC"
	},
	{
			"value": 4441,
			"label": "178919 - CASHMERETTE LLC"
	},
	{
			"value": 4411,
			"label": "178921 - NINGBO WINLEAD ORNAMENT CO LTD"
	},
	{
			"value": 2400,
			"label": "178925 - SILVER BUFFALO LLC"
	},
	{
			"value": 4477,
			"label": "178926 - CORNELL OVERSEAS"
	},
	{
			"value": 4428,
			"label": "178927 - VISTA PARTNERS INC (ECOM)"
	},
	{
			"value": 4416,
			"label": "178928 - EVERBEST (QINGDAO) COMPANY"
	},
	{
			"value": 4448,
			"label": "178929 - AF EXPORTS"
	},
	{
			"value": 4607,
			"label": "178930 - LTM"
	},
	{
			"value": 4442,
			"label": "178931 - JAYANITA EXPORT PVT LTD"
	},
	{
			"value": 4451,
			"label": "178933 - PANACHE (INDIA) INTERNATIONAL"
	},
	{
			"value": 674046,
			"label": "178934 - DESIGNCO PRIVATE LIMITED"
	},
	{
			"value": 4476,
			"label": "178935 - RUCHICAZ OVERSEAS"
	},
	{
			"value": 401588,
			"label": "178936 - MAINPEX INTERNATIONAL CORP CO LTD"
	},
	{
			"value": 220374,
			"label": "178939 - BIOWORLD MERCHANDISING"
	},
	{
			"value": 4453,
			"label": "178940 - VIVSUN EXPORTS"
	},
	{
			"value": 4412,
			"label": "178941 - CAMELOT"
	},
	{
			"value": 4444,
			"label": "178942 - SHIV SHAKTI EXPORTS"
	},
	{
			"value": 4446,
			"label": "178943 - BASHA ACCESSORIES LLC"
	},
	{
			"value": 4434,
			"label": "178945 - SCENTSIBLE LLC DBA POO-POURRI"
	},
	{
			"value": 96144,
			"label": "178947 - TEX.ATHENEA, S.L."
	},
	{
			"value": 4484,
			"label": "178950 - DECOART (PRIVATE LABEL)"
	},
	{
			"value": 4443,
			"label": "178951 - CHAMPO"
	},
	{
			"value": 4501,
			"label": "178953 - BOSTON WAREHOUSE"
	},
	{
			"value": 4460,
			"label": "178954 - PLANET EARTH EYEWEAR LLC"
	},
	{
			"value": 4438,
			"label": "178955 - CHOON'S DESIGN LLC"
	},
	{
			"value": 4430,
			"label": "178960 - BAKER'S STO N' GO"
	},
	{
			"value": 4427,
			"label": "178961 - ROBERT BOSCH TOOL CORPORATION"
	},
	{
			"value": 4431,
			"label": "178966 - KING ART"
	},
	{
			"value": 4470,
			"label": "178967 - RADIUM CREATION LIMITED"
	},
	{
			"value": 4433,
			"label": "178970 - CHURCH & DWIGHT CO INC"
	},
	{
			"value": 4452,
			"label": "178975 - STALWART HOMESTYLES"
	},
	{
			"value": 4469,
			"label": "178980 - NATRAJ HOME FURNISHINGS PVT LTD"
	},
	{
			"value": 4450,
			"label": "178981 - SEASONS TEX (HARDLINE)"
	},
	{
			"value": 4459,
			"label": "178985 - MILKMEN DESIGN LLC"
	},
	{
			"value": 4457,
			"label": "178986 - MAKERSTOCK"
	},
	{
			"value": 4461,
			"label": "178987 - NURGE"
	},
	{
			"value": 4455,
			"label": "178990 - WOODLAND"
	},
	{
			"value": 4456,
			"label": "178995 - BKS"
	},
	{
			"value": 4466,
			"label": "178996 - EXMART INTERNATIONAL"
	},
	{
			"value": 4462,
			"label": "178997 - RAM COIR"
	},
	{
			"value": 4474,
			"label": "178998 - FISTAS"
	},
	{
			"value": 4467,
			"label": "178999 - RR INDUSTRIES"
	},
	{
			"value": 4468,
			"label": "179000 - ALPINE PLUS"
	},
	{
			"value": 4465,
			"label": "179007 - KAVSET EXPORTS"
	},
	{
			"value": 682629,
			"label": "179010 - JAYANITA EXPORTS PVT. LTD."
	},
	{
			"value": 7491,
			"label": "179011 - SOURCING SOLUTIONS INC (ECOM)"
	},
	{
			"value": 4486,
			"label": "179020 - SNOW WHITE WOOLLEN MILLS PVT LTD"
	},
	{
			"value": 244506,
			"label": "179030 - PK LIFESTYLES (ECOM)"
	},
	{
			"value": 244674,
			"label": "179031 - P/KAUFMANN (ECOM)"
	},
	{
			"value": 244669,
			"label": "179032 - BRAEMORE (ECOM)"
	},
	{
			"value": 4480,
			"label": "179035 - GLOSTER LIMITED"
	},
	{
			"value": 4487,
			"label": "179040 - MACPHERSONS (ECOM)"
	},
	{
			"value": 4481,
			"label": "179045 - PEACH TREE"
	},
	{
			"value": 4482,
			"label": "179046 - JA-RU"
	},
	{
			"value": 4490,
			"label": "179047 - NINGBO FUTURE"
	},
	{
			"value": 4495,
			"label": "179048 - GOSSI INC"
	},
	{
			"value": 4493,
			"label": "179049 - BIC CORPORATION"
	},
	{
			"value": 4508,
			"label": "179050 - THE CRESCENT TEXTILE MILLS LTD"
	},
	{
			"value": 4489,
			"label": "179056 - PLAID ENTERPRISES INC"
	},
	{
			"value": 4507,
			"label": "179058 - TCG"
	},
	{
			"value": 26805,
			"label": "179060 - HIGH STANDARD PRODUCTS"
	},
	{
			"value": 213760,
			"label": "179065 - SPINRITE CORP (ECOM)"
	},
	{
			"value": 15337,
			"label": "179070 - BEST ACCESSORY GROUP LLC"
	},
	{
			"value": 32943,
			"label": "179080 - AROMA BAY CANDLES CO"
	},
	{
			"value": 4492,
			"label": "179081 - SOUTHERN TELECOM INC"
	},
	{
			"value": 4491,
			"label": "179082 - MARCELLE GROUP LLC"
	},
	{
			"value": 4505,
			"label": "179086 - GCE INTERNATIONAL INC"
	},
	{
			"value": 4613,
			"label": "179095 - SFT INC"
	},
	{
			"value": 4503,
			"label": "179100 - VALUE VINYLS INC"
	},
	{
			"value": 4506,
			"label": "179106 - CAROLE ACCESSORIES"
	},
	{
			"value": 688548,
			"label": "179110 - KALTEX AMERICA INC"
	},
	{
			"value": 4502,
			"label": "179111 - ACCELERATE360 LLC"
	},
	{
			"value": 4504,
			"label": "179112 - KALTEX"
	},
	{
			"value": 38401,
			"label": "179116 - SIGMA DISTRIBUTORS (ECOM)"
	},
	{
			"value": 5529,
			"label": "179117 - ART SUPPLY ENTERPRISES"
	},
	{
			"value": 4601,
			"label": "179121 - DO A DOT ART"
	},
	{
			"value": 5533,
			"label": "179123 - BUNTBOX GMBH"
	},
	{
			"value": 4610,
			"label": "179126 - SVP WORLDWIDE LLC"
	},
	{
			"value": 4604,
			"label": "179132 - M2 FABRICS"
	},
	{
			"value": 4606,
			"label": "179135 - THE WIKKI STIX CO"
	},
	{
			"value": 397403,
			"label": "179140 - MEKOTEX PVT LIMITED"
	},
	{
			"value": 210096,
			"label": "179145 - MAD BEAUTY USA LLC"
	},
	{
			"value": 32006,
			"label": "179146 - ADURO PRODUCTS LLC"
	},
	{
			"value": 36348,
			"label": "179147 - PPNC PLAYBALLS"
	},
	{
			"value": 43474,
			"label": "179150 - JIANGSHANG (CAMBODIA) SPORT &"
	},
	{
			"value": 5531,
			"label": "179155 - VALUE MAX PRODUCTS"
	},
	{
			"value": 37906,
			"label": "179160 - MISSION PETS INC"
	},
	{
			"value": 14619,
			"label": "179161 - PHOENIX STATIONERY VIETNAM CO LTD"
	},
	{
			"value": 7492,
			"label": "179165 - SUZHOU CUIGEN TEXTILE TECHNOLOGY CO"
	},
	{
			"value": 9464,
			"label": "179170 - PACHA SOAP COMPANY"
	},
	{
			"value": 40366,
			"label": "179175 - GWEN STUDIOS"
	},
	{
			"value": 37907,
			"label": "179177 - WUXI JINMAO LIMITED COMPANY"
	},
	{
			"value": 50761,
			"label": "179178 - MOHAWK HOME (ECOM)"
	},
	{
			"value": 15338,
			"label": "179181 - DIAMOND ART CLUB"
	},
	{
			"value": 25084,
			"label": "179186 - GWEN STUDIOS LLC"
	},
	{
			"value": 19245,
			"label": "179190 - NEMESIS USA LTD"
	},
	{
			"value": 21477,
			"label": "179191 - SKYTEX MEXICO S.A. DE C.V."
	},
	{
			"value": 38896,
			"label": "179195 - NATIONAL CHRISTMAS PRODUCTS (ECOM)"
	},
	{
			"value": 37908,
			"label": "179200 - SMITHERS OASIS COMPANY (ECOM)"
	},
	{
			"value": 21478,
			"label": "179205 - DWECK DISTRIBUTION"
	},
	{
			"value": 25086,
			"label": "179206 - BORGO DE' MEDICI SRL"
	},
	{
			"value": 25085,
			"label": "179211 - ANKER PLAY PRODUCTS LLC"
	},
	{
			"value": 25087,
			"label": "179215 - FOTORAMA"
	},
	{
			"value": 26807,
			"label": "179216 - SOLID ROOTS"
	},
	{
			"value": 40365,
			"label": "179220 - INKED BY DANI"
	},
	{
			"value": 26802,
			"label": "179221 - ECONOMY POLYMERS AND CHEMICALS"
	},
	{
			"value": 26806,
			"label": "179223 - SPIN-BALLS LLC"
	},
	{
			"value": 28553,
			"label": "179224 - JAKAB SOLUTIONS INC"
	},
	{
			"value": 26803,
			"label": "179231 - GOOD LINK PARTNERS INC"
	},
	{
			"value": 26804,
			"label": "179232 - R-PAC CORP"
	},
	{
			"value": 59849,
			"label": "179233 - ANKER PLAY"
	},
	{
			"value": 32944,
			"label": "179235 - NITE IZE INC"
	},
	{
			"value": 32005,
			"label": "179236 - STICKERS NORTHWEST"
	},
	{
			"value": 32004,
			"label": "179237 - CHRONICLE BOOKS"
	},
	{
			"value": 30269,
			"label": "179240 - NINGBO MELAN FOREIGN TRADE"
	},
	{
			"value": 35858,
			"label": "179241 - SHANGHAI FUNGFUTURE CO LTD"
	},
	{
			"value": 35857,
			"label": "179243 - MRE LIMITED INC"
	},
	{
			"value": 71191,
			"label": "179245 - ORCHARD YARN & THREAD CO INC (ECOM)"
	},
	{
			"value": 100909,
			"label": "179250 - HANES INDUSTRIES"
	},
	{
			"value": 38399,
			"label": "179255 - ANKYO DEVELOPMENT LTD"
	},
	{
			"value": 128804,
			"label": "179256 - LA RIBBONS AND CRAFTS INC"
	},
	{
			"value": 63679,
			"label": "179260 - CROWN INTERNATIONAL TEXTILES"
	},
	{
			"value": 37377,
			"label": "179270 - DIVA EXPORTS PVT LTD (HARDLINE)"
	},
	{
			"value": 674051,
			"label": "179275 - ORLY SHOE CORP"
	},
	{
			"value": 144954,
			"label": "179282 - HONEY CAN DO"
	},
	{
			"value": 38895,
			"label": "179283 - BUG BITE THING INC"
	},
	{
			"value": 38400,
			"label": "179284 - BRAND PARTNERS GROUP"
	},
	{
			"value": 40873,
			"label": "179285 - SUNYIN (HK) HOLDING LIMITED"
	},
	{
			"value": 43473,
			"label": "179286 - GEAR AID INC"
	},
	{
			"value": 231037,
			"label": "179291 - PRYM CONSUMER USA INC (ECOM)"
	},
	{
			"value": 40872,
			"label": "179292 - AR WORKSHOP"
	},
	{
			"value": 44797,
			"label": "179293 - OLFA U.S.A. INC"
	},
	{
			"value": 232533,
			"label": "179295 - HUIZHOU FESTOON CRAFT CO LTD"
	},
	{
			"value": 40367,
			"label": "179296 - SAKAR INTERNATIONAL INC"
	},
	{
			"value": 686654,
			"label": "179301 - PAISLEY CRAFTS LLC"
	},
	{
			"value": 43456,
			"label": "179305 - RESINVENTURES INC"
	},
	{
			"value": 43472,
			"label": "179310 - FANTASY FILES LLC"
	},
	{
			"value": 44823,
			"label": "179316 - STREAMLINE IMPORTING INC"
	},
	{
			"value": 43458,
			"label": "179317 - PLAYTEK LLC"
	},
	{
			"value": 50763,
			"label": "179320 - CHINA MANUFACTURING SOLUTION LTD"
	},
	{
			"value": 54992,
			"label": "179325 - CHROMA INC"
	},
	{
			"value": 136262,
			"label": "179327 - GOURMET HOME PRODUCTS LLC"
	},
	{
			"value": 54991,
			"label": "179328 - MOSS DECO ORM LTD STI"
	},
	{
			"value": 59851,
			"label": "179329 - WELL PLAYED TOYS DIV GENER8 LLC"
	},
	{
			"value": 242417,
			"label": "179331 - WOBBLEWORKS INC"
	},
	{
			"value": 674623,
			"label": "179335 - ZIP TOP, INC"
	},
	{
			"value": 50760,
			"label": "179336 - VENTURE PRODUCTS LLC"
	},
	{
			"value": 56704,
			"label": "179340 - ROOM COPENHAGEN INC"
	},
	{
			"value": 70806,
			"label": "179341 - HERO ARTS RUBBER STAMPS INC (ECOM)"
	},
	{
			"value": 195900,
			"label": "179345 - THE GRACE COMPANY (ECOM)"
	},
	{
			"value": 56705,
			"label": "179350 - SATIN FINE FOODS INC"
	},
	{
			"value": 59850,
			"label": "179360 - CEACO"
	},
	{
			"value": 92262,
			"label": "179365 - MENDERES TEKSTIL SAN.VE. TIC. A.S."
	},
	{
			"value": 242728,
			"label": "179371 - STUDIO DESIGNS INC"
	},
	{
			"value": 220524,
			"label": "179375 - UNOVO LLC DBA OLISO (ECOM)"
	},
	{
			"value": 116504,
			"label": "179377 - SAWGRASS"
	},
	{
			"value": 245023,
			"label": "179378 - BRIGHTECH PLUS (ECOM)"
	},
	{
			"value": 110730,
			"label": "179379 - PRATT INC"
	},
	{
			"value": 241699,
			"label": "179380 - POWER SALES & ADVERTISING (ECOM)"
	},
	{
			"value": 64113,
			"label": "179381 - IRIS USA INC"
	},
	{
			"value": 69682,
			"label": "179383 - JET HOLDINGS HK LIMITED"
	},
	{
			"value": 88175,
			"label": "179384 - SCENT THEORY PRODUCTS LLC"
	},
	{
			"value": 129049,
			"label": "179386 - LEISURE ARTS INC"
	},
	{
			"value": 241056,
			"label": "179390 - THE DAYLIGHT COMPANY LLC (ECOM)"
	},
	{
			"value": 519620,
			"label": "179391 - WARM PRODUCTS INC (ECOM)"
	},
	{
			"value": 161802,
			"label": "179393 - DESIGN GROUP AMERICAS (ECOM)-PATTER"
	},
	{
			"value": 231337,
			"label": "179394 - THE LANG COMPANIES INC (ECOM)"
	},
	{
			"value": 203278,
			"label": "179395 - CASA COLLECTIVE LIMITED"
	},
	{
			"value": 677784,
			"label": "179400 - ROYAL CONSUMER INFORMATION"
	},
	{
			"value": 153601,
			"label": "179401 - SUNBELT POWER EQUIPMENT LLC (ECOM)"
	},
	{
			"value": 103551,
			"label": "179405 - KUNSHAN JUN YUAN ARTS & CRAFTS"
	},
	{
			"value": 231148,
			"label": "179406 - AWESOME SUPPLY CHAIN LIMITED"
	},
	{
			"value": 179711,
			"label": "179412 - CHINATEX ORIENTAL USA INC"
	},
	{
			"value": 101215,
			"label": "179415 - CANAN IPLIKCILIK SAN VE TIC A.S."
	},
	{
			"value": 138625,
			"label": "179420 - TRI D'ART INTERNATIONAL LIMITED"
	},
	{
			"value": 118359,
			"label": "179422 - SMITHERS OASIS"
	},
	{
			"value": 160322,
			"label": "179423 - TESEO S.P.A"
	},
	{
			"value": 673013,
			"label": "179425 - PEPSICOLA BEVERAGE SALES LLC"
	},
	{
			"value": 220837,
			"label": "179426 - CREATIVE DESIGNS DEPOT (ECOM)"
	},
	{
			"value": 192152,
			"label": "179427 - ITP AS"
	},
	{
			"value": 109128,
			"label": "179430 - COOKIES UNITED LLC"
	},
	{
			"value": 120865,
			"label": "179431 - PROFIT CULTURAL & CREATIVE"
	},
	{
			"value": 204840,
			"label": "179432 - THE LANG COMPANIES INC (CONSIGNMENT"
	},
	{
			"value": 152947,
			"label": "179434 - SENSIO INC"
	},
	{
			"value": 672875,
			"label": "179435 - VALLEY FORGE FLAG LLC"
	},
	{
			"value": 142886,
			"label": "179440 - SISER NORTH AMERICA"
	},
	{
			"value": 192378,
			"label": "179446 - BUYHIVE TECHNOLOGIES INC"
	},
	{
			"value": 180598,
			"label": "179447 - MADISON HOME INTERNATIONAL LLC"
	},
	{
			"value": 241054,
			"label": "179450 - FORBITEX USA LLC (ECOM)"
	},
	{
			"value": 163284,
			"label": "179455 - CCA AND B LLC DBA THE LUMISTELLA CO"
	},
	{
			"value": 190318,
			"label": "179460 - VOTUM ENTERPRISES LLC"
	},
	{
			"value": 242782,
			"label": "179461 - 24 HOUR CRAFTS LLC (ECOM)"
	},
	{
			"value": 223914,
			"label": "179462 - WILSON"
	},
	{
			"value": 189359,
			"label": "179466 - GOLDSTAR IC VE DIS TIC LTD"
	},
	{
			"value": 199808,
			"label": "179471 - STRATA PRODUCTS LTD"
	},
	{
			"value": 243063,
			"label": "179472 - ARTEZA ART SUPPLY"
	},
	{
			"value": 243240,
			"label": "179473 - ARTEZA ART SUPPLIES (ECOM)"
	},
	{
			"value": 195818,
			"label": "179475 - HURSAN HAVLU URETIM SAN VE TIC AS"
	},
	{
			"value": 226714,
			"label": "179480 - SKYTEX MEXICO S.A. DE C.V."
	},
	{
			"value": 198423,
			"label": "179485 - NINGBO GENERAL UNION CO LTD"
	},
	{
			"value": 233481,
			"label": "179490 - JOANN DEAD STOCK"
	},
	{
			"value": 199303,
			"label": "179495 - BUCKEYE CORRUGATED INC"
	},
	{
			"value": 243126,
			"label": "179500 - TAIZHOU SUNUP TECH CO LTD"
	},
	{
			"value": 226697,
			"label": "179505 - GINNIS COLLECTIONS"
	},
	{
			"value": 530634,
			"label": "179506 - NATURAL ESSENTIALS INC"
	},
	{
			"value": 244338,
			"label": "179510 - CHINATEX ORIENTAL U.S.A. INC (ECOM)"
	},
	{
			"value": 233480,
			"label": "179520 - LIBERTY FABRICS LTD"
	},
	{
			"value": 241906,
			"label": "179521 - LCO DESTINY LLC DBA TIMELESS FRAMES"
	},
	{
			"value": 674168,
			"label": "179525 - DIAMOND ART CLUB (ECOM)"
	},
	{
			"value": 242386,
			"label": "179530 - PARIS CORPORATION OF NJ"
	},
	{
			"value": 243796,
			"label": "179532 - PROGRESSIVE INTERNATIONAL CORP"
	},
	{
			"value": 273029,
			"label": "179533 - NINGBO MERRY-HOME IMP&EXP CO LTD"
	},
	{
			"value": 242777,
			"label": "179535 - DESIGN CLIQUE INC"
	},
	{
			"value": 242395,
			"label": "179540 - E11EVEN LLC"
	},
	{
			"value": 243545,
			"label": "179541 - HANGZHOU PROSTAR ENTERPRISES LTD"
	},
	{
			"value": 242566,
			"label": "179542 - NOVELTY INC"
	},
	{
			"value": 244072,
			"label": "179546 - FIESTA CRAFTS LTD"
	},
	{
			"value": 244311,
			"label": "179547 - PT GLORY OFFSET PRESS"
	},
	{
			"value": 243064,
			"label": "179548 - CHARTPAK INC"
	},
	{
			"value": 243399,
			"label": "179549 - NINGBO ARISTE INTERNATIONAL CO LTD"
	},
	{
			"value": 243245,
			"label": "179550 - XIAMEN HANKA HOME INTERNATIONAL"
	},
	{
			"value": 681734,
			"label": "179552 - CATAN GLOBAL IMPORT LTD"
	},
	{
			"value": 316606,
			"label": "179553 - FELLERS INC"
	},
	{
			"value": 243787,
			"label": "179555 - EKO HALI PAZ SAN VE TIC LTD STI"
	},
	{
			"value": 243540,
			"label": "179556 - SHENZHEN RIZEE CULTURAL CREATIVITY"
	},
	{
			"value": 574410,
			"label": "179557 - ALL THE RAGES INC (ECOM)"
	},
	{
			"value": 245467,
			"label": "179558 - REDFIELD GROUP CO LTD"
	},
	{
			"value": 293582,
			"label": "179559 - ZEPHYR SOLUTIONS LLC"
	},
	{
			"value": 610944,
			"label": "179560 - SUNYIN (HK) HOLDING LIMITED"
	},
	{
			"value": 243400,
			"label": "179565 - ARTMATE CO LTD"
	},
	{
			"value": 244582,
			"label": "179566 - ANEST IWATA-MEDEA INC"
	},
	{
			"value": 261436,
			"label": "179567 - OMG HOME DECO LIMITED"
	},
	{
			"value": 409182,
			"label": "179568 - SAFAVIEH (ECOM)"
	},
	{
			"value": 315553,
			"label": "179569 - NORTH AMERICAN PLASTICS & CHEMICALS"
	},
	{
			"value": 545316,
			"label": "179570 - TIMELESS FRAMES (ECOM)"
	},
	{
			"value": 243735,
			"label": "179571 - BEIFA GROUP CO LTD"
	},
	{
			"value": 254588,
			"label": "179575 - SEASONAL VISIONS INTERNATIONAL LTD"
	},
	{
			"value": 346760,
			"label": "179576 - ILLUMAX CHINA LIMITED"
	},
	{
			"value": 366233,
			"label": "179577 - XANADU INDUSTRIAL LIMITED"
	},
	{
			"value": 672909,
			"label": "179578 - TUFKO INTERNATIONAL"
	},
	{
			"value": 261427,
			"label": "179580 - FAR CHAMPION INTERNATIONAL LIMITED"
	},
	{
			"value": 671036,
			"label": "179590 - KENNEDY INTERNATIONAL (ECOM)"
	},
	{
			"value": 519170,
			"label": "179601 - CUPIXEL INC"
	},
	{
			"value": 586897,
			"label": "179604 - TIMELESS FRAMES AND DECOR"
	},
	{
			"value": 671709,
			"label": "179605 - COVINGTON FABRIC (ECOM)"
	},
	{
			"value": 520736,
			"label": "179606 - BREWER SEWING (ECOM)"
	},
	{
			"value": 492079,
			"label": "179607 - EGO INTERNATIONAL CO LTD"
	},
	{
			"value": 671814,
			"label": "179610 - PRETTY WOMAN USA LLC"
	},
	{
			"value": 444075,
			"label": "179611 - ACELLORIES INC"
	},
	{
			"value": 671842,
			"label": "179612 - SPECTRALLA INC"
	},
	{
			"value": 435979,
			"label": "179613 - JUST FOR LAUGHS"
	},
	{
			"value": 553384,
			"label": "179614 - ENTERTAINMENT EARTH INC"
	},
	{
			"value": 673468,
			"label": "179615 - GORDON COMPANIES INC (ECOM)"
	},
	{
			"value": 472709,
			"label": "179621 - WIN MAKERS (NINGBO) INTERNATIONAL"
	},
	{
			"value": 671815,
			"label": "179625 - PALADONE PRODUCTS"
	},
	{
			"value": 577191,
			"label": "179631 - PADDYWAX LLC"
	},
	{
			"value": 672229,
			"label": "179632 - SILHOUETTE AMERICA INC. (ECOM)"
	},
	{
			"value": 615919,
			"label": "179640 - DAYLIGHT COMPANY LLC"
	},
	{
			"value": 675925,
			"label": "179645 - 3DOODLDER (ECOM)"
	},
	{
			"value": 672448,
			"label": "179650 - IBG LLC (ECOM)"
	},
	{
			"value": 675134,
			"label": "179655 - BIELLA MANIFATTURE TESSILI SRL"
	},
	{
			"value": 673565,
			"label": "179656 - MARTCO EXPORT PRIVATE LIMITED"
	},
	{
			"value": 674882,
			"label": "179657 - DONGGUAN TEAM SUN CRAFTS CO LTD"
	},
	{
			"value": 674704,
			"label": "179660 - IRIS USA, INC. (ECOM)"
	},
	{
			"value": 674044,
			"label": "179661 - UNCANNY BRANDS (ECOM)"
	},
	{
			"value": 674018,
			"label": "179662 - JOHN BEAD CORP. (ECOM)"
	},
	{
			"value": 674045,
			"label": "179665 - RANGE KLEEN (ECOM)"
	},
	{
			"value": 678858,
			"label": "179670 - KENT DISPLAYS"
	},
	{
			"value": 673396,
			"label": "179672 - ART GALLERY FABRICS"
	},
	{
			"value": 676108,
			"label": "179675 - INFINITY HOLDINGS LLC"
	},
	{
			"value": 673673,
			"label": "179676 - HIGH INTENCITY"
	},
	{
			"value": 680918,
			"label": "179677 - AMERICAN TOMBOW, INC (ECOM)"
	},
	{
			"value": 682972,
			"label": "179680 - SNDZ ASIA CORP."
	},
	{
			"value": 673464,
			"label": "179681 - ACE GIFT & CRAFT (NINGBO) CO LTD"
	},
	{
			"value": 681764,
			"label": "179682 - PAPER HOUSE PRODUCTIONS (ECOM)"
	},
	{
			"value": 677088,
			"label": "179683 - OPEN ROAD BRANDS LLC"
	},
	{
			"value": 676707,
			"label": "179684 - IJB PRODUCTS LLC"
	},
	{
			"value": 677153,
			"label": "179685 - LILLIAN ROSE (ECOM)"
	},
	{
			"value": 673496,
			"label": "179690 - NEXT LEVEL APPAREL"
	},
	{
			"value": 691533,
			"label": "179695 - BEADSMITH (ECOM)"
	},
	{
			"value": 677818,
			"label": "179696 - MANUAL WOODWORKERS AND WEAVERS (ECO"
	},
	{
			"value": 678930,
			"label": "179700 - LALTITUDE, LC"
	},
	{
			"value": 676491,
			"label": "179705 - ASTAGE GLOBAL INC."
	},
	{
			"value": 680690,
			"label": "179710 - LUMATEX INDIA PVT LTD"
	},
	{
			"value": 683227,
			"label": "179711 - CAOXIAN LUYI GUANGFA ART"
	},
	{
			"value": 684225,
			"label": "179712 - M&P EXPORT MANAGMENT CORP (ECOM)"
	},
	{
			"value": 678415,
			"label": "179715 - RPAC INTERNATIONAL CORP"
	},
	{
			"value": 680014,
			"label": "179720 - HOTRONIX LLC"
	},
	{
			"value": 680756,
			"label": "179725 - M&P EXPORT MANAGEMENT CORPORATION"
	},
	{
			"value": 680447,
			"label": "179735 - PINCH PROVISIONS"
	},
	{
			"value": 680683,
			"label": "179736 - CHAHAT EXPORT"
	},
	{
			"value": 687184,
			"label": "179737 - THE NES GROUP"
	},
	{
			"value": 683646,
			"label": "179738 - CC INTERNATIONAL LLC (ECOM)"
	},
	{
			"value": 682576,
			"label": "179739 - VILLAGE LIGHTING CO (ECOM)"
	},
	{
			"value": 682981,
			"label": "179742 - GWEN STUDIOS (ECOM)"
	},
	{
			"value": 683149,
			"label": "179743 - MR. CHRISTMAS, LLC (ECOM)"
	},
	{
			"value": 683617,
			"label": "179745 - MASTERPIECES PUZZLE COMPANY (ECOM)"
	},
	{
			"value": 683037,
			"label": "179746 - ROOT CANDLES (ECOM)"
	},
	{
			"value": 682601,
			"label": "179748 - SHANGHAI MORE DESIGNERS TRADING"
	},
	{
			"value": 681712,
			"label": "179751 - CREATIVE KIDS FAR EAST INC."
	},
	{
			"value": 688853,
			"label": "179752 - ANNA GRIFFIN INC. (ECOM)"
	},
	{
			"value": 682925,
			"label": "179755 - NINGBO MAJINA CRAFTS CO., LTD"
	},
	{
			"value": 684260,
			"label": "179756 - DESIGN WORKS CRAFT INC (ECOM)"
	},
	{
			"value": 683014,
			"label": "179757 - CRAFT EXPRESS US, LLC (ECOM)"
	},
	{
			"value": 683035,
			"label": "179758 - SANTA'S WORKSHOP INC (ECOM)"
	},
	{
			"value": 683023,
			"label": "179765 - VIDRIOS SAN MIGUEL S.L"
	},
	{
			"value": 692645,
			"label": "179775 - ART SUPPLY ENTERPRISES"
	},
	{
			"value": 683843,
			"label": "179776 - ROYAL TALENS NORTH AMERICA, INC"
	},
	{
			"value": 684379,
			"label": "179778 - HARMONY PAPER COMPANY LLC"
	},
	{
			"value": 684431,
			"label": "179780 - I2POLY, INC."
	},
	{
			"value": 683473,
			"label": "179785 - TEXPRO CO., LIMITED"
	},
	{
			"value": 691474,
			"label": "179796 - SMARTEK USA INC. (ECOM)"
	},
	{
			"value": 691920,
			"label": "179797 - OCEANSTAR DESIGN GROUP INC. (ECOM)"
	},
	{
			"value": 688161,
			"label": "179810 - NEWCO JODITO LLC"
	},
	{
			"value": 691590,
			"label": "179845 - UCP INTERNATIONAL CO., LTD."
	},
	{
			"value": 691904,
			"label": "179851 - JEWELRY MADE BY ME, LLC"
	},
	{
			"value": 761640,
			"label": "179890 - CRICUT CONSIGN"
	}
]

const ATTRIBUTE_COLOR_OPTIONS = [
	// {
	// 		"value": "`",
	// 		"label": "`"
	// },
	{
			"value": "0",
			"label": "0"
	},
	{
			"value": "00",
			"label": "00"
	},
	{
			"value": "01",
			"label": "01"
	},
	{
			"value": "0434N NEON TIGERLILY",
			"label": "0434N NEON TIGERLILY"
	},
	{
			"value": "1",
			"label": "1"
	},
	{
			"value": "10",
			"label": "10"
	},
	{
			"value": "100",
			"label": "100"
	},
	{
			"value": "10100.44316",
			"label": "10100.44316"
	},
	{
			"value": "104",
			"label": "104"
	},
	{
			"value": "-1080",
			"label": "-1080"
	},
	{
			"value": "10 FT",
			"label": "10 FT"
	},
	{
			"value": "11",
			"label": "11"
	},
	{
			"value": "11-0105",
			"label": "11-0105"
	},
	{
			"value": "110CT",
			"label": "110CT"
	},
	{
			"value": "-1152",
			"label": "-1152"
	},
	{
			"value": "12",
			"label": "12"
	},
	{
			"value": "128",
			"label": "128"
	},
	{
			"value": "12CT",
			"label": "12CT"
	},
	{
			"value": "12 FT",
			"label": "12 FT"
	},
	{
			"value": "13",
			"label": "13"
	},
	{
			"value": "134",
			"label": "134"
	},
	{
			"value": "1384",
			"label": "1384"
	},
	{
			"value": "14",
			"label": "14"
	},
	{
			"value": "14.99",
			"label": "14.99"
	},
	{
			"value": "14.99 HALLOWEEN WALL",
			"label": "14.99 HALLOWEEN WALL"
	},
	{
			"value": "14 FT",
			"label": "14 FT"
	},
	{
			"value": "15",
			"label": "15"
	},
	{
			"value": "16",
			"label": "16"
	},
	{
			"value": "16.99GARLAND",
			"label": "16.99GARLAND"
	},
	{
			"value": "16 FT",
			"label": "16 FT"
	},
	{
			"value": "16FT",
			"label": "16FT"
	},
	{
			"value": "17",
			"label": "17"
	},
	{
			"value": "172",
			"label": "172"
	},
	{
			"value": "1794",
			"label": "1794"
	},
	{
			"value": "1798",
			"label": "1798"
	},
	{
			"value": "-18",
			"label": "-18"
	},
	{
			"value": "18",
			"label": "18"
	},
	{
			"value": "1805.4",
			"label": "1805.4"
	},
	{
			"value": "18-4535",
			"label": "18-4535"
	},
	{
			"value": "18710459",
			"label": "18710459"
	},
	{
			"value": "19",
			"label": "19"
	},
	{
			"value": "19-0915",
			"label": "19-0915"
	},
	{
			"value": "19-3632",
			"label": "19-3632"
	},
	{
			"value": "19-5420",
			"label": "19-5420"
	},
	{
			"value": "1.99",
			"label": "1.99"
	},
	{
			"value": "19.99",
			"label": "19.99"
	},
	{
			"value": "2",
			"label": "2"
	},
	{
			"value": "20",
			"label": "20"
	},
	{
			"value": "204",
			"label": "204"
	},
	{
			"value": "2094",
			"label": "2094"
	},
	{
			"value": "21",
			"label": "21"
	},
	{
			"value": "2142",
			"label": "2142"
	},
	{
			"value": "22",
			"label": "22"
	},
	{
			"value": "221",
			"label": "221"
	},
	{
			"value": "23",
			"label": "23"
	},
	{
			"value": "24",
			"label": "24"
	},
	{
			"value": "-248",
			"label": "-248"
	},
	{
			"value": "24.99",
			"label": "24.99"
	},
	{
			"value": "24CT",
			"label": "24CT"
	},
	{
			"value": "24 MAJ",
			"label": "24 MAJ"
	},
	{
			"value": "25",
			"label": "25"
	},
	{
			"value": "25CT",
			"label": "25CT"
	},
	{
			"value": "26",
			"label": "26"
	},
	{
			"value": "27",
			"label": "27"
	},
	{
			"value": "27 (SHARE)",
			"label": "27 (SHARE)"
	},
	{
			"value": "28",
			"label": "28"
	},
	{
			"value": "2856",
			"label": "2856"
	},
	{
			"value": "29",
			"label": "29"
	},
	{
			"value": "2.99",
			"label": "2.99"
	},
	{
			"value": "29.99",
			"label": "29.99"
	},
	{
			"value": "29.99GARLAND",
			"label": "29.99GARLAND"
	},
	{
			"value": "2.99 FOAM",
			"label": "2.99 FOAM"
	},
	{
			"value": "2 COLOR MULTI",
			"label": "2 COLOR MULTI"
	},
	{
			"value": "2 FT",
			"label": "2 FT"
	},
	{
			"value": "2ND CURATION",
			"label": "2ND CURATION"
	},
	{
			"value": "3",
			"label": "3"
	},
	{
			"value": "30",
			"label": "30"
	},
	{
			"value": "3000",
			"label": "3000"
	},
	{
			"value": "3029.4",
			"label": "3029.4"
	},
	{
			"value": "3061",
			"label": "3061"
	},
	{
			"value": "31",
			"label": "31"
	},
	{
			"value": "32",
			"label": "32"
	},
	{
			"value": "33",
			"label": "33"
	},
	{
			"value": "34",
			"label": "34"
	},
	{
			"value": "3492",
			"label": "3492"
	},
	{
			"value": "35",
			"label": "35"
	},
	{
			"value": "356",
			"label": "356"
	},
	{
			"value": "36",
			"label": "36"
	},
	{
			"value": "36CT",
			"label": "36CT"
	},
	{
			"value": "37",
			"label": "37"
	},
	{
			"value": "38",
			"label": "38"
	},
	{
			"value": "3815",
			"label": "3815"
	},
	{
			"value": "3846",
			"label": "3846"
	},
	{
			"value": "39",
			"label": "39"
	},
	{
			"value": "39.99",
			"label": "39.99"
	},
	{
			"value": "3 COLOR MULTI",
			"label": "3 COLOR MULTI"
	},
	{
			"value": "3CT",
			"label": "3CT"
	},
	{
			"value": "3D",
			"label": "3D"
	},
	{
			"value": "3 FT",
			"label": "3 FT"
	},
	{
			"value": "3RD CURATION",
			"label": "3RD CURATION"
	},
	{
			"value": "3X 1143304",
			"label": "3X 1143304"
	},
	{
			"value": "3X 1143353",
			"label": "3X 1143353"
	},
	{
			"value": "3X 1143403",
			"label": "3X 1143403"
	},
	{
			"value": "4",
			"label": "4"
	},
	{
			"value": "40",
			"label": "40"
	},
	{
			"value": "41",
			"label": "41"
	},
	{
			"value": "4152",
			"label": "4152"
	},
	{
			"value": "42",
			"label": "42"
	},
	{
			"value": "421N BRIGHT SUN YELL",
			"label": "421N BRIGHT SUN YELL"
	},
	{
			"value": "4284",
			"label": "4284"
	},
	{
			"value": "4292.5",
			"label": "4292.5"
	},
	{
			"value": "43",
			"label": "43"
	},
	{
			"value": "432",
			"label": "432"
	},
	{
			"value": "4320",
			"label": "4320"
	},
	{
			"value": "44",
			"label": "44"
	},
	{
			"value": "4494",
			"label": "4494"
	},
	{
			"value": "45",
			"label": "45"
	},
	{
			"value": "-4596",
			"label": "-4596"
	},
	{
			"value": "4.5 FT",
			"label": "4.5 FT"
	},
	{
			"value": "46",
			"label": "46"
	},
	{
			"value": "475",
			"label": "475"
	},
	{
			"value": "4962",
			"label": "4962"
	},
	{
			"value": "4.99",
			"label": "4.99"
	},
	{
			"value": "49.99",
			"label": "49.99"
	},
	{
			"value": "49.99GARLAND",
			"label": "49.99GARLAND"
	},
	{
			"value": "4 FT",
			"label": "4 FT"
	},
	{
			"value": "4TH CURATION",
			"label": "4TH CURATION"
	},
	{
			"value": "5",
			"label": "5"
	},
	{
			"value": "50",
			"label": "50"
	},
	{
			"value": "505",
			"label": "505"
	},
	{
			"value": "5050.221582",
			"label": "5050.221582"
	},
	{
			"value": "52",
			"label": "52"
	},
	{
			"value": "54",
			"label": "54"
	},
	{
			"value": "544",
			"label": "544"
	},
	{
			"value": "-576",
			"label": "-576"
	},
	{
			"value": "5 FT",
			"label": "5 FT"
	},
	{
			"value": "5TH CURATION",
			"label": "5TH CURATION"
	},
	{
			"value": "6",
			"label": "6"
	},
	{
			"value": "60",
			"label": "60"
	},
	{
			"value": "65",
			"label": "65"
	},
	{
			"value": "6.5 FT",
			"label": "6.5 FT"
	},
	{
			"value": "68",
			"label": "68"
	},
	{
			"value": "6CT",
			"label": "6CT"
	},
	{
			"value": "6 FT",
			"label": "6 FT"
	},
	{
			"value": "7",
			"label": "7"
	},
	{
			"value": "70",
			"label": "70"
	},
	{
			"value": "75CT",
			"label": "75CT"
	},
	{
			"value": "7.5 FT",
			"label": "7.5 FT"
	},
	{
			"value": "76",
			"label": "76"
	},
	{
			"value": "-763",
			"label": "-763"
	},
	{
			"value": "-764",
			"label": "-764"
	},
	{
			"value": "7.99 FOAM",
			"label": "7.99 FOAM"
	},
	{
			"value": "7 FT",
			"label": "7 FT"
	},
	{
			"value": "8",
			"label": "8"
	},
	{
			"value": "80",
			"label": "80"
	},
	{
			"value": "810 PLUM SHADOWS",
			"label": "810 PLUM SHADOWS"
	},
	{
			"value": "811 TEABERRIES",
			"label": "811 TEABERRIES"
	},
	{
			"value": "828",
			"label": "828"
	},
	{
			"value": "845 BLUE CLOUDS",
			"label": "845 BLUE CLOUDS"
	},
	{
			"value": "864",
			"label": "864"
	},
	{
			"value": "865 BABY PASTELS",
			"label": "865 BABY PASTELS"
	},
	{
			"value": "8CT",
			"label": "8CT"
	},
	{
			"value": "8 FT",
			"label": "8 FT"
	},
	{
			"value": "9",
			"label": "9"
	},
	{
			"value": "902.7",
			"label": "902.7"
	},
	{
			"value": "91",
			"label": "91"
	},
	{
			"value": "93",
			"label": "93"
	},
	{
			"value": "96",
			"label": "96"
	},
	{
			"value": "9768334",
			"label": "9768334"
	},
	{
			"value": "9768482",
			"label": "9768482"
	},
	{
			"value": "9768524",
			"label": "9768524"
	},
	{
			"value": "9768565",
			"label": "9768565"
	},
	{
			"value": "9768706",
			"label": "9768706"
	},
	{
			"value": "9.99",
			"label": "9.99"
	},
	{
			"value": "9.99GARLAND",
			"label": "9.99GARLAND"
	},
	{
			"value": "9CT",
			"label": "9CT"
	},
	{
			"value": "9 FT",
			"label": "9 FT"
	},
	{
			"value": "A (3-4-5-6-7-8)",
			"label": "A (3-4-5-6-7-8)"
	},
	{
			"value": "A (8-10-12-14-16)",
			"label": "A (8-10-12-14-16)"
	},
	{
			"value": "AA (10-12-14-16-18)",
			"label": "AA (10-12-14-16-18)"
	},
	{
			"value": "AA (34-36-38-40-42)",
			"label": "AA (34-36-38-40-42)"
	},
	{
			"value": "A (ALL SIZES)",
			"label": "A (ALL SIZES)"
	},
	{
			"value": "AA (S-M-L)",
			"label": "AA (S-M-L)"
	},
	{
			"value": "AB",
			"label": "AB"
	},
	{
			"value": "ABALONE",
			"label": "ABALONE"
	},
	{
			"value": "ABOLONE",
			"label": "ABOLONE"
	},
	{
			"value": "ABSTRACT",
			"label": "ABSTRACT"
	},
	{
			"value": "ABSTRACT SEAS",
			"label": "ABSTRACT SEAS"
	},
	{
			"value": "ACC",
			"label": "ACC"
	},
	{
			"value": "ACCESSORIES",
			"label": "ACCESSORIES"
	},
	{
			"value": "ACORN",
			"label": "ACORN"
	},
	{
			"value": "ACORN/BRY",
			"label": "ACORN/BRY"
	},
	{
			"value": "ADOBE",
			"label": "ADOBE"
	},
	{
			"value": "ADORNCONTAINERS",
			"label": "ADORNCONTAINERS"
	},
	{
			"value": "ADORNED",
			"label": "ADORNED"
	},
	{
			"value": "ADORNEDACCESSORIES",
			"label": "ADORNEDACCESSORIES"
	},
	{
			"value": "ADORNEDARRANGEMENTS",
			"label": "ADORNEDARRANGEMENTS"
	},
	{
			"value": "ADORNEDBUSHES",
			"label": "ADORNEDBUSHES"
	},
	{
			"value": "ADORNEDPICKS",
			"label": "ADORNEDPICKS"
	},
	{
			"value": "ADULT",
			"label": "ADULT"
	},
	{
			"value": "ADULT ONE SIZE",
			"label": "ADULT ONE SIZE"
	},
	{
			"value": "ADULT PANEL 1",
			"label": "ADULT PANEL 1"
	},
	{
			"value": "ADULT PANEL 2",
			"label": "ADULT PANEL 2"
	},
	{
			"value": "ADULT PANEL 3",
			"label": "ADULT PANEL 3"
	},
	{
			"value": "AEGEAN",
			"label": "AEGEAN"
	},
	{
			"value": "AGED GOLD",
			"label": "AGED GOLD"
	},
	{
			"value": "ALABASTER",
			"label": "ALABASTER"
	},
	{
			"value": "ALL ON GRID AND PP1",
			"label": "ALL ON GRID AND PP1"
	},
	{
			"value": "ALLOY",
			"label": "ALLOY"
	},
	{
			"value": "ALMOND",
			"label": "ALMOND"
	},
	{
			"value": "ALMOND PINK",
			"label": "ALMOND PINK"
	},
	{
			"value": "ALMOST MAUVE",
			"label": "ALMOST MAUVE"
	},
	{
			"value": "ALOE",
			"label": "ALOE"
	},
	{
			"value": "ALOHA ORCHARD",
			"label": "ALOHA ORCHARD"
	},
	{
			"value": "ALPACA",
			"label": "ALPACA"
	},
	{
			"value": "ALSO IN EC9 & LOWBOY",
			"label": "ALSO IN EC9 & LOWBOY"
	},
	{
			"value": "ALSO IN GIFTWRAP PAN",
			"label": "ALSO IN GIFTWRAP PAN"
	},
	{
			"value": "ALSO IN QUEING",
			"label": "ALSO IN QUEING"
	},
	{
			"value": "ALSO ON EC5 EC800609",
			"label": "ALSO ON EC5 EC800609"
	},
	{
			"value": "ALSO ON EC685601",
			"label": "ALSO ON EC685601"
	},
	{
			"value": "ALSO ON EC 8",
			"label": "ALSO ON EC 8"
	},
	{
			"value": "ALSO ON ORNAMENT EC",
			"label": "ALSO ON ORNAMENT EC"
	},
	{
			"value": "ALSO ON PP  6",
			"label": "ALSO ON PP  6"
	},
	{
			"value": "ALSO ON PP6 SA685601",
			"label": "ALSO ON PP6 SA685601"
	},
	{
			"value": "ALSO ON PP 8",
			"label": "ALSO ON PP 8"
	},
	{
			"value": "ALSO ON PP9",
			"label": "ALSO ON PP9"
	},
	{
			"value": "ALSO ON PP9 LIGHTING",
			"label": "ALSO ON PP9 LIGHTING"
	},
	{
			"value": "ALUMINUM",
			"label": "ALUMINUM"
	},
	{
			"value": "AMBER",
			"label": "AMBER"
	},
	{
			"value": "AMER ENTERTAINING",
			"label": "AMER ENTERTAINING"
	},
	{
			"value": "AMERICANA",
			"label": "AMERICANA"
	},
	{
			"value": "AMERICANA BARN STARS",
			"label": "AMERICANA BARN STARS"
	},
	{
			"value": "AMERICANA CUSHIONS",
			"label": "AMERICANA CUSHIONS"
	},
	{
			"value": "AMERICANA FOS",
			"label": "AMERICANA FOS"
	},
	{
			"value": "AMERICANA LIGHTING",
			"label": "AMERICANA LIGHTING"
	},
	{
			"value": "AMERICANA PILLOWS",
			"label": "AMERICANA PILLOWS"
	},
	{
			"value": "AMER IND TBLE SETNG",
			"label": "AMER IND TBLE SETNG"
	},
	{
			"value": "AMER OUTD ENTERTAIN",
			"label": "AMER OUTD ENTERTAIN"
	},
	{
			"value": "AMETHYST",
			"label": "AMETHYST"
	},
	{
			"value": "AMNER",
			"label": "AMNER"
	},
	{
			"value": "ANCHOR",
			"label": "ANCHOR"
	},
	{
			"value": "ANGEL",
			"label": "ANGEL"
	},
	{
			"value": "ANGEL FOOD",
			"label": "ANGEL FOOD"
	},
	{
			"value": "ANIIMAL",
			"label": "ANIIMAL"
	},
	{
			"value": "ANIMAL",
			"label": "ANIMAL"
	},
	{
			"value": "ANIMAL/INSECT",
			"label": "ANIMAL/INSECT"
	},
	{
			"value": "ANIMALS",
			"label": "ANIMALS"
	},
	{
			"value": "ANIMALS?",
			"label": "ANIMALS?"
	},
	{
			"value": "ANTELOPE",
			"label": "ANTELOPE"
	},
	{
			"value": "ANT. GOLD",
			"label": "ANT. GOLD"
	},
	{
			"value": "ANT.GOLD",
			"label": "ANT.GOLD"
	},
	{
			"value": "ANTHRACITE",
			"label": "ANTHRACITE"
	},
	{
			"value": "ANTIQUE",
			"label": "ANTIQUE"
	},
	{
			"value": "ANTIQUE CHERRY",
			"label": "ANTIQUE CHERRY"
	},
	{
			"value": "ANTIQUE GOLD",
			"label": "ANTIQUE GOLD"
	},
	{
			"value": "ANTIQUE GOLD BRONZE",
			"label": "ANTIQUE GOLD BRONZE"
	},
	{
			"value": "ANTIQUE JADE",
			"label": "ANTIQUE JADE"
	},
	{
			"value": "ANTIQUE RED",
			"label": "ANTIQUE RED"
	},
	{
			"value": "ANTISP",
			"label": "ANTISP"
	},
	{
			"value": "ANT.SILVER",
			"label": "ANT.SILVER"
	},
	{
			"value": "APPLE",
			"label": "APPLE"
	},
	{
			"value": "APPLE GREEN",
			"label": "APPLE GREEN"
	},
	{
			"value": "APPL/E/VAN",
			"label": "APPL/E/VAN"
	},
	{
			"value": "APR",
			"label": "APR"
	},
	{
			"value": "APRICOT",
			"label": "APRICOT"
	},
	{
			"value": "APRICOT ORANGE",
			"label": "APRICOT ORANGE"
	},
	{
			"value": "APRON SET",
			"label": "APRON SET"
	},
	{
			"value": "AQUA",
			"label": "AQUA"
	},
	{
			"value": "AQUA BLUE",
			"label": "AQUA BLUE"
	},
	{
			"value": "AQUA MARINE",
			"label": "AQUA MARINE"
	},
	{
			"value": "AQUAMARINE",
			"label": "AQUAMARINE"
	},
	{
			"value": "AQUAMATINE",
			"label": "AQUAMATINE"
	},
	{
			"value": "AQUA TINT",
			"label": "AQUA TINT"
	},
	{
			"value": "ARCTIC",
			"label": "ARCTIC"
	},
	{
			"value": "ARCTIC WHITE",
			"label": "ARCTIC WHITE"
	},
	{
			"value": "ARRANGEMENTS",
			"label": "ARRANGEMENTS"
	},
	{
			"value": "ARROYO",
			"label": "ARROYO"
	},
	{
			"value": "ARTICHOKE",
			"label": "ARTICHOKE"
	},
	{
			"value": "ASH",
			"label": "ASH"
	},
	{
			"value": "ASH GREY",
			"label": "ASH GREY"
	},
	{
			"value": "A (S - L / S - XL)",
			"label": "A (S - L / S - XL)"
	},
	{
			"value": "A (S-M-L)",
			"label": "A (S-M-L)"
	},
	{
			"value": "ASPEN GOLD",
			"label": "ASPEN GOLD"
	},
	{
			"value": "ASPHALT",
			"label": "ASPHALT"
	},
	{
			"value": "ASSORTED",
			"label": "ASSORTED"
	},
	{
			"value": "ASST",
			"label": "ASST"
	},
	{
			"value": "AST",
			"label": "AST"
	},
	{
			"value": "ASTD",
			"label": "ASTD"
	},
	{
			"value": "ATLANTIC",
			"label": "ATLANTIC"
	},
	{
			"value": "ATLANTIC DEEP",
			"label": "ATLANTIC DEEP"
	},
	{
			"value": "ATLANTIS",
			"label": "ATLANTIS"
	},
	{
			"value": "ATOM RED",
			"label": "ATOM RED"
	},
	{
			"value": "AUBERGINE",
			"label": "AUBERGINE"
	},
	{
			"value": "AUBURN",
			"label": "AUBURN"
	},
	{
			"value": "AUG",
			"label": "AUG"
	},
	{
			"value": "AUTUMN",
			"label": "AUTUMN"
	},
	{
			"value": "AUTUMN BERRY",
			"label": "AUTUMN BERRY"
	},
	{
			"value": "AUTUMN GLAZE",
			"label": "AUTUMN GLAZE"
	},
	{
			"value": "AVENTURE",
			"label": "AVENTURE"
	},
	{
			"value": "AVOCADO",
			"label": "AVOCADO"
	},
	{
			"value": "AW",
			"label": "AW"
	},
	{
			"value": "A (XS-S-M-L-XL-XXL)",
			"label": "A (XS-S-M-L-XL-XXL)"
	},
	{
			"value": "AZALEA",
			"label": "AZALEA"
	},
	{
			"value": "AZTEC",
			"label": "AZTEC"
	},
	{
			"value": "AZTEC?",
			"label": "AZTEC?"
	},
	{
			"value": "AZURE",
			"label": "AZURE"
	},
	{
			"value": "B",
			"label": "B"
	},
	{
			"value": "B00",
			"label": "B00"
	},
	{
			"value": "BABY",
			"label": "BABY"
	},
	{
			"value": "BABY BLUE",
			"label": "BABY BLUE"
	},
	{
			"value": "BABY BOY HUES",
			"label": "BABY BOY HUES"
	},
	{
			"value": "BABY GIRL HUES",
			"label": "BABY GIRL HUES"
	},
	{
			"value": "BABY PINK",
			"label": "BABY PINK"
	},
	{
			"value": "BACBAC",
			"label": "BACBAC"
	},
	{
			"value": "BAGS",
			"label": "BAGS"
	},
	{
			"value": "BAHAMA BLUE",
			"label": "BAHAMA BLUE"
	},
	{
			"value": "BAKEWARE",
			"label": "BAKEWARE"
	},
	{
			"value": "BAKING",
			"label": "BAKING"
	},
	{
			"value": "BAKING CUPS",
			"label": "BAKING CUPS"
	},
	{
			"value": "BAKING MAT",
			"label": "BAKING MAT"
	},
	{
			"value": "BAKING SHEET",
			"label": "BAKING SHEET"
	},
	{
			"value": "BALCK",
			"label": "BALCK"
	},
	{
			"value": "BALLERINA",
			"label": "BALLERINA"
	},
	{
			"value": "BALLET SLIPPER",
			"label": "BALLET SLIPPER"
	},
	{
			"value": "BALSAM",
			"label": "BALSAM"
	},
	{
			"value": "BALTIC",
			"label": "BALTIC"
	},
	{
			"value": "BAMBOO",
			"label": "BAMBOO"
	},
	{
			"value": "BANANA",
			"label": "BANANA"
	},
	{
			"value": "BARBERRY RED",
			"label": "BARBERRY RED"
	},
	{
			"value": "BARK",
			"label": "BARK"
	},
	{
			"value": "BARLEY",
			"label": "BARLEY"
	},
	{
			"value": "BARN RED",
			"label": "BARN RED"
	},
	{
			"value": "BAROQUE",
			"label": "BAROQUE"
	},
	{
			"value": "BASEBALL",
			"label": "BASEBALL"
	},
	{
			"value": "BASES",
			"label": "BASES"
	},
	{
			"value": "BASIL",
			"label": "BASIL"
	},
	{
			"value": "BASIL-SAND",
			"label": "BASIL-SAND"
	},
	{
			"value": "BASKETS & LINERS",
			"label": "BASKETS & LINERS"
	},
	{
			"value": "BAT",
			"label": "BAT"
	},
	{
			"value": "BATIK",
			"label": "BATIK"
	},
	{
			"value": "BATTING",
			"label": "BATTING"
	},
	{
			"value": "BAYBERRY",
			"label": "BAYBERRY"
	},
	{
			"value": "BAY LEAF",
			"label": "BAY LEAF"
	},
	{
			"value": "BAYLEAF",
			"label": "BAYLEAF"
	},
	{
			"value": "BAYSIDE",
			"label": "BAYSIDE"
	},
	{
			"value": "BB (20W-22W-24W-26W-",
			"label": "BB (20W-22W-24W-26W-"
	},
	{
			"value": "BB (44-46-48-50-52)",
			"label": "BB (44-46-48-50-52)"
	},
	{
			"value": "BB (XS-S-M-L-XL)",
			"label": "BB (XS-S-M-L-XL)"
	},
	{
			"value": "BEACH",
			"label": "BEACH"
	},
	{
			"value": "BEACH BABE",
			"label": "BEACH BABE"
	},
	{
			"value": "BEACHGLASS",
			"label": "BEACHGLASS"
	},
	{
			"value": "BEADED/BRY",
			"label": "BEADED/BRY"
	},
	{
			"value": "BEADING",
			"label": "BEADING"
	},
	{
			"value": "BEAUTY",
			"label": "BEAUTY"
	},
	{
			"value": "BEDROOM",
			"label": "BEDROOM"
	},
	{
			"value": "BEESWAX",
			"label": "BEESWAX"
	},
	{
			"value": "BEIGE",
			"label": "BEIGE"
	},
	{
			"value": "BEIGE DK",
			"label": "BEIGE DK"
	},
	{
			"value": "BEIGE DP",
			"label": "BEIGE DP"
	},
	{
			"value": "BEIGE LT",
			"label": "BEIGE LT"
	},
	{
			"value": "BEIGE MD",
			"label": "BEIGE MD"
	},
	{
			"value": "BEIGE/TAN",
			"label": "BEIGE/TAN"
	},
	{
			"value": "BELLS",
			"label": "BELLS"
	},
	{
			"value": "BELUE",
			"label": "BELUE"
	},
	{
			"value": "BERRY",
			"label": "BERRY"
	},
	{
			"value": "BEV NAP",
			"label": "BEV NAP"
	},
	{
			"value": "BGE",
			"label": "BGE"
	},
	{
			"value": "BHOPAL",
			"label": "BHOPAL"
	},
	{
			"value": "BIEGE",
			"label": "BIEGE"
	},
	{
			"value": "BIGELOW SUNSET",
			"label": "BIGELOW SUNSET"
	},
	{
			"value": "BIKING",
			"label": "BIKING"
	},
	{
			"value": "BING CHERRY",
			"label": "BING CHERRY"
	},
	{
			"value": "BIOLET",
			"label": "BIOLET"
	},
	{
			"value": "BIRCH",
			"label": "BIRCH"
	},
	{
			"value": "BIRD",
			"label": "BIRD"
	},
	{
			"value": "BIRD/BRY",
			"label": "BIRD/BRY"
	},
	{
			"value": "BIRDHOUSES & FEEDERS",
			"label": "BIRDHOUSES & FEEDERS"
	},
	{
			"value": "BIRDS",
			"label": "BIRDS"
	},
	{
			"value": "BIRTHDAY",
			"label": "BIRTHDAY"
	},
	{
			"value": "BISCOTTI",
			"label": "BISCOTTI"
	},
	{
			"value": "BISCUIT",
			"label": "BISCUIT"
	},
	{
			"value": "BISON",
			"label": "BISON"
	},
	{
			"value": "BISQUE",
			"label": "BISQUE"
	},
	{
			"value": "BKWHRDGRN",
			"label": "BKWHRDGRN"
	},
	{
			"value": "BLACK",
			"label": "BLACK"
	},
	{
			"value": "BLACK.",
			"label": "BLACK."
	},
	{
			"value": "BLACK AND GRAY",
			"label": "BLACK AND GRAY"
	},
	{
			"value": "BLACK AND RED",
			"label": "BLACK AND RED"
	},
	{
			"value": "BLACK AND WHITE",
			"label": "BLACK AND WHITE"
	},
	{
			"value": "BLACK BERRY",
			"label": "BLACK BERRY"
	},
	{
			"value": "BLACKBERRY",
			"label": "BLACKBERRY"
	},
	{
			"value": "BLACKBERRY WINE",
			"label": "BLACKBERRY WINE"
	},
	{
			"value": "BLACKBIRD",
			"label": "BLACKBIRD"
	},
	{
			"value": "BLACK/BLACK",
			"label": "BLACK/BLACK"
	},
	{
			"value": "BLACK/BRONZE",
			"label": "BLACK/BRONZE"
	},
	{
			"value": "BLACK BROWN",
			"label": "BLACK BROWN"
	},
	{
			"value": "BLACK CARD",
			"label": "BLACK CARD"
	},
	{
			"value": "BLACK CHERRY",
			"label": "BLACK CHERRY"
	},
	{
			"value": "BLACKCOFFEE",
			"label": "BLACKCOFFEE"
	},
	{
			"value": "BLACK/CREAM",
			"label": "BLACK/CREAM"
	},
	{
			"value": "BLACK DIAMOND",
			"label": "BLACK DIAMOND"
	},
	{
			"value": "BLACK DOT",
			"label": "BLACK DOT"
	},
	{
			"value": "BLACKENED COPPER",
			"label": "BLACKENED COPPER"
	},
	{
			"value": "BLACKEYED SUSAN",
			"label": "BLACKEYED SUSAN"
	},
	{
			"value": "BLACK/GOLD",
			"label": "BLACK/GOLD"
	},
	{
			"value": "BLACK IRIS",
			"label": "BLACK IRIS"
	},
	{
			"value": "BLACK MULTI",
			"label": "BLACK MULTI"
	},
	{
			"value": "BLACK ON GOLD GEO",
			"label": "BLACK ON GOLD GEO"
	},
	{
			"value": "BLACK ON MINT",
			"label": "BLACK ON MINT"
	},
	{
			"value": "BLACK ON PINK AND BL",
			"label": "BLACK ON PINK AND BL"
	},
	{
			"value": "BLACK ON PINK HEARTS",
			"label": "BLACK ON PINK HEARTS"
	},
	{
			"value": "BLACK ON PURPLE",
			"label": "BLACK ON PURPLE"
	},
	{
			"value": "BLACK ON RED GINGHAM",
			"label": "BLACK ON RED GINGHAM"
	},
	{
			"value": "BLACK ON SILVER LACE",
			"label": "BLACK ON SILVER LACE"
	},
	{
			"value": "BLACK ON TAN PAWS",
			"label": "BLACK ON TAN PAWS"
	},
	{
			"value": "BLACK ON WHITE",
			"label": "BLACK ON WHITE"
	},
	{
			"value": "BLACK ON YELLOW STAR",
			"label": "BLACK ON YELLOW STAR"
	},
	{
			"value": "BLACK/ORANGE",
			"label": "BLACK/ORANGE"
	},
	{
			"value": "BLACKPEPPER",
			"label": "BLACKPEPPER"
	},
	{
			"value": "BLACK/PURPLE",
			"label": "BLACK/PURPLE"
	},
	{
			"value": "BLACK/RED",
			"label": "BLACK/RED"
	},
	{
			"value": "BLACK/RED/SILVER",
			"label": "BLACK/RED/SILVER"
	},
	{
			"value": "BLACK SAND",
			"label": "BLACK SAND"
	},
	{
			"value": "BLACK SILVER",
			"label": "BLACK SILVER"
	},
	{
			"value": "BLACK/SILVER",
			"label": "BLACK/SILVER"
	},
	{
			"value": "BLACK&WHITE",
			"label": "BLACK&WHITE"
	},
	{
			"value": "BLACK/WHITE",
			"label": "BLACK/WHITE"
	},
	{
			"value": "BLACK/WHITE/SILVER",
			"label": "BLACK/WHITE/SILVER"
	},
	{
			"value": "BLACK/YELLOW",
			"label": "BLACK/YELLOW"
	},
	{
			"value": "BLACK ZEBRA",
			"label": "BLACK ZEBRA"
	},
	{
			"value": "BLAZIN BLUE",
			"label": "BLAZIN BLUE"
	},
	{
			"value": "BLCK",
			"label": "BLCK"
	},
	{
			"value": "BLEACHED WHITE",
			"label": "BLEACHED WHITE"
	},
	{
			"value": "BLE DK",
			"label": "BLE DK"
	},
	{
			"value": "BLENDER",
			"label": "BLENDER"
	},
	{
			"value": "BLEU",
			"label": "BLEU"
	},
	{
			"value": "BL/GRAY",
			"label": "BL/GRAY"
	},
	{
			"value": "BLIE",
			"label": "BLIE"
	},
	{
			"value": "BLK",
			"label": "BLK"
	},
	{
			"value": "BLK/BRWN",
			"label": "BLK/BRWN"
	},
	{
			"value": "BLK EYE SUS DAISY FP",
			"label": "BLK EYE SUS DAISY FP"
	},
	{
			"value": "BLK/GREY/VAN",
			"label": "BLK/GREY/VAN"
	},
	{
			"value": "BLK/ORNG",
			"label": "BLK/ORNG"
	},
	{
			"value": "BLK/PINK",
			"label": "BLK/PINK"
	},
	{
			"value": "BLK/WHT",
			"label": "BLK/WHT"
	},
	{
			"value": "BLONDE",
			"label": "BLONDE"
	},
	{
			"value": "BLOSSOM",
			"label": "BLOSSOM"
	},
	{
			"value": "BLU",
			"label": "BLU"
	},
	{
			"value": "BLUE",
			"label": "BLUE"
	},
	{
			"value": "BLUE AND WHITE",
			"label": "BLUE AND WHITE"
	},
	{
			"value": "BLUEBELL",
			"label": "BLUEBELL"
	},
	{
			"value": "BLUEBERRY",
			"label": "BLUEBERRY"
	},
	{
			"value": "BLUEBIRD",
			"label": "BLUEBIRD"
	},
	{
			"value": "BLUE/BRONZE",
			"label": "BLUE/BRONZE"
	},
	{
			"value": "BLUE/BROWN",
			"label": "BLUE/BROWN"
	},
	{
			"value": "BLUEBUSHES",
			"label": "BLUEBUSHES"
	},
	{
			"value": "BLUE/CHOCOLATE",
			"label": "BLUE/CHOCOLATE"
	},
	{
			"value": "BLUE CITRUS",
			"label": "BLUE CITRUS"
	},
	{
			"value": "BLUE CORAL",
			"label": "BLUE CORAL"
	},
	{
			"value": "BLUE/CREAM",
			"label": "BLUE/CREAM"
	},
	{
			"value": "BLUE DARK",
			"label": "BLUE DARK"
	},
	{
			"value": "BLUE  DK",
			"label": "BLUE  DK"
	},
	{
			"value": "BLUE D K",
			"label": "BLUE D K"
	},
	{
			"value": "BLUE DK",
			"label": "BLUE DK"
	},
	{
			"value": "BLUE GEO",
			"label": "BLUE GEO"
	},
	{
			"value": "BLUE & GOLD",
			"label": "BLUE & GOLD"
	},
	{
			"value": "BLUE/GOLD",
			"label": "BLUE/GOLD"
	},
	{
			"value": "BLUEGRASS",
			"label": "BLUEGRASS"
	},
	{
			"value": "BLUE GRAY",
			"label": "BLUE GRAY"
	},
	{
			"value": "BLUE GREEN",
			"label": "BLUE GREEN"
	},
	{
			"value": "BLUE-GREEN",
			"label": "BLUE-GREEN"
	},
	{
			"value": "BLUE/GREEN",
			"label": "BLUE/GREEN"
	},
	{
			"value": "BLUEJAY",
			"label": "BLUEJAY"
	},
	{
			"value": "BLUE JEAN",
			"label": "BLUE JEAN"
	},
	{
			"value": "BLUE JEWEL",
			"label": "BLUE JEWEL"
	},
	{
			"value": "BLUE KLT",
			"label": "BLUE KLT"
	},
	{
			"value": "BLUE LIGHT",
			"label": "BLUE LIGHT"
	},
	{
			"value": "BLUE LT",
			"label": "BLUE LT"
	},
	{
			"value": "BLUE LY",
			"label": "BLUE LY"
	},
	{
			"value": "BLUEMARINE",
			"label": "BLUEMARINE"
	},
	{
			"value": "BLUE MD",
			"label": "BLUE MD"
	},
	{
			"value": "BLUE MED",
			"label": "BLUE MED"
	},
	{
			"value": "BLUE MIX",
			"label": "BLUE MIX"
	},
	{
			"value": "BLUE MULTI",
			"label": "BLUE MULTI"
	},
	{
			"value": "BLUE/PINK",
			"label": "BLUE/PINK"
	},
	{
			"value": "BLUEPRINT",
			"label": "BLUEPRINT"
	},
	{
			"value": "BLUE RADIANCE",
			"label": "BLUE RADIANCE"
	},
	{
			"value": "BLUE/RED",
			"label": "BLUE/RED"
	},
	{
			"value": "BLUE RIBBON",
			"label": "BLUE RIBBON"
	},
	{
			"value": "BLUE ROYAL",
			"label": "BLUE ROYAL"
	},
	{
			"value": "BLUE SEWING NOTIONS",
			"label": "BLUE SEWING NOTIONS"
	},
	{
			"value": "BLUE, SILVER",
			"label": "BLUE, SILVER"
	},
	{
			"value": "BLUE/SILVER",
			"label": "BLUE/SILVER"
	},
	{
			"value": "BLUE SKY",
			"label": "BLUE SKY"
	},
	{
			"value": "BLUESKY",
			"label": "BLUESKY"
	},
	{
			"value": "BLUES/NEUTRALS",
			"label": "BLUES/NEUTRALS"
	},
	{
			"value": "BLUE SPRUCE",
			"label": "BLUE SPRUCE"
	},
	{
			"value": "BLUE STONE",
			"label": "BLUE STONE"
	},
	{
			"value": "BLUE STRIPE",
			"label": "BLUE STRIPE"
	},
	{
			"value": "BLUES/VAN",
			"label": "BLUES/VAN"
	},
	{
			"value": "BLUE/TAN",
			"label": "BLUE/TAN"
	},
	{
			"value": "BLUE/WHITE",
			"label": "BLUE/WHITE"
	},
	{
			"value": "BLUE & WHT",
			"label": "BLUE & WHT"
	},
	{
			"value": "BLUE ZIRCON",
			"label": "BLUE ZIRCON"
	},
	{
			"value": "BLUSH",
			"label": "BLUSH"
	},
	{
			"value": "BLUSH/GREY",
			"label": "BLUSH/GREY"
	},
	{
			"value": "BLUSH PINK",
			"label": "BLUSH PINK"
	},
	{
			"value": "BLUT DK",
			"label": "BLUT DK"
	},
	{
			"value": "BOLLYWOOD",
			"label": "BOLLYWOOD"
	},
	{
			"value": "BONE",
			"label": "BONE"
	},
	{
			"value": "BONES PANEL 1",
			"label": "BONES PANEL 1"
	},
	{
			"value": "BONES PANEL 2",
			"label": "BONES PANEL 2"
	},
	{
			"value": "BONES PANEL 3",
			"label": "BONES PANEL 3"
	},
	{
			"value": "BONFIRE",
			"label": "BONFIRE"
	},
	{
			"value": "BOOK",
			"label": "BOOK"
	},
	{
			"value": "BOOKMARK",
			"label": "BOOKMARK"
	},
	{
			"value": "BORDEAUX",
			"label": "BORDEAUX"
	},
	{
			"value": "BOTANICAL",
			"label": "BOTANICAL"
	},
	{
			"value": "BOTANICAL GARDEN",
			"label": "BOTANICAL GARDEN"
	},
	{
			"value": "BOTANICAL GREEN",
			"label": "BOTANICAL GREEN"
	},
	{
			"value": "BOTTLE",
			"label": "BOTTLE"
	},
	{
			"value": "BOUDOIR",
			"label": "BOUDOIR"
	},
	{
			"value": "BOUQUET",
			"label": "BOUQUET"
	},
	{
			"value": "BOW",
			"label": "BOW"
	},
	{
			"value": "BOWL",
			"label": "BOWL"
	},
	{
			"value": "BOWN",
			"label": "BOWN"
	},
	{
			"value": "BOWS",
			"label": "BOWS"
	},
	{
			"value": "B PEACH",
			"label": "B PEACH"
	},
	{
			"value": "BRANCH",
			"label": "BRANCH"
	},
	{
			"value": "BRANDY",
			"label": "BRANDY"
	},
	{
			"value": "BRASS",
			"label": "BRASS"
	},
	{
			"value": "BRG",
			"label": "BRG"
	},
	{
			"value": "BRIAR",
			"label": "BRIAR"
	},
	{
			"value": "BRICK",
			"label": "BRICK"
	},
	{
			"value": "BRIDAL",
			"label": "BRIDAL"
	},
	{
			"value": "BRIDAL IVORY",
			"label": "BRIDAL IVORY"
	},
	{
			"value": "BRIDAL PANEL 1",
			"label": "BRIDAL PANEL 1"
	},
	{
			"value": "BRIDAL PANEL 2",
			"label": "BRIDAL PANEL 2"
	},
	{
			"value": "BRIGHT",
			"label": "BRIGHT"
	},
	{
			"value": "BRIGHT BLUE",
			"label": "BRIGHT BLUE"
	},
	{
			"value": "BRIGHT GREEN",
			"label": "BRIGHT GREEN"
	},
	{
			"value": "BRIGHT ORANGE",
			"label": "BRIGHT ORANGE"
	},
	{
			"value": "BRIGHT PINK",
			"label": "BRIGHT PINK"
	},
	{
			"value": "BRIGHT RED",
			"label": "BRIGHT RED"
	},
	{
			"value": "BRIGHTS",
			"label": "BRIGHTS"
	},
	{
			"value": "BRIGHT WHITE",
			"label": "BRIGHT WHITE"
	},
	{
			"value": "BRIGHTWHITE/GOLD",
			"label": "BRIGHTWHITE/GOLD"
	},
	{
			"value": "BRIGHT YELLOW",
			"label": "BRIGHT YELLOW"
	},
	{
			"value": "BRILLIANT WHITE",
			"label": "BRILLIANT WHITE"
	},
	{
			"value": "BRISTOL",
			"label": "BRISTOL"
	},
	{
			"value": "BRITE MULTI",
			"label": "BRITE MULTI"
	},
	{
			"value": "BRN",
			"label": "BRN"
	},
	{
			"value": "BRN MXD",
			"label": "BRN MXD"
	},
	{
			"value": "BRONZE",
			"label": "BRONZE"
	},
	{
			"value": "BRONZE/BLUE",
			"label": "BRONZE/BLUE"
	},
	{
			"value": "BRONZE/GREEN",
			"label": "BRONZE/GREEN"
	},
	{
			"value": "BRONZE/WHITE",
			"label": "BRONZE/WHITE"
	},
	{
			"value": "BROOK",
			"label": "BROOK"
	},
	{
			"value": "BROWM",
			"label": "BROWM"
	},
	{
			"value": "BROWN",
			"label": "BROWN"
	},
	{
			"value": "BROWN AND IVORY",
			"label": "BROWN AND IVORY"
	},
	{
			"value": "BROWN DK",
			"label": "BROWN DK"
	},
	{
			"value": "BROWN/GREEN",
			"label": "BROWN/GREEN"
	},
	{
			"value": "BROWN/GREEN/GOLD",
			"label": "BROWN/GREEN/GOLD"
	},
	{
			"value": "BROWNIE",
			"label": "BROWNIE"
	},
	{
			"value": "BROWN IVORY",
			"label": "BROWN IVORY"
	},
	{
			"value": "BROWN LT",
			"label": "BROWN LT"
	},
	{
			"value": "BROWN MD",
			"label": "BROWN MD"
	},
	{
			"value": "BROWN/NAT",
			"label": "BROWN/NAT"
	},
	{
			"value": "BROWNS",
			"label": "BROWNS"
	},
	{
			"value": "BROWNS/TANS",
			"label": "BROWNS/TANS"
	},
	{
			"value": "BROWNSTONE",
			"label": "BROWNSTONE"
	},
	{
			"value": "BROWN/TAN",
			"label": "BROWN/TAN"
	},
	{
			"value": "BROWN/WHITE",
			"label": "BROWN/WHITE"
	},
	{
			"value": "BROZE",
			"label": "BROZE"
	},
	{
			"value": "BRT WHITE",
			"label": "BRT WHITE"
	},
	{
			"value": "BRWN/SPA BLUE",
			"label": "BRWN/SPA BLUE"
	},
	{
			"value": "BRY/ORG",
			"label": "BRY/ORG"
	},
	{
			"value": "BTB",
			"label": "BTB"
	},
	{
			"value": "BT. ORANGE",
			"label": "BT. ORANGE"
	},
	{
			"value": "BTY",
			"label": "BTY"
	},
	{
			"value": "BUCKET",
			"label": "BUCKET"
	},
	{
			"value": "BUE",
			"label": "BUE"
	},
	{
			"value": "BUE LT",
			"label": "BUE LT"
	},
	{
			"value": "BUFF",
			"label": "BUFF"
	},
	{
			"value": "BUFFALO CHECK",
			"label": "BUFFALO CHECK"
	},
	{
			"value": "BULK JAR",
			"label": "BULK JAR"
	},
	{
			"value": "BULK POUCH",
			"label": "BULK POUCH"
	},
	{
			"value": "BUNNY",
			"label": "BUNNY"
	},
	{
			"value": "BURC",
			"label": "BURC"
	},
	{
			"value": "BURDUNDY",
			"label": "BURDUNDY"
	},
	{
			"value": "BURG",
			"label": "BURG"
	},
	{
			"value": "BURGANDY",
			"label": "BURGANDY"
	},
	{
			"value": "BURGANDY/BRONZE",
			"label": "BURGANDY/BRONZE"
	},
	{
			"value": "BURGANDY/WHITE",
			"label": "BURGANDY/WHITE"
	},
	{
			"value": "B URG/BLACK",
			"label": "B URG/BLACK"
	},
	{
			"value": "BURG/BRN/BRY",
			"label": "BURG/BRN/BRY"
	},
	{
			"value": "BURGUNDAY",
			"label": "BURGUNDAY"
	},
	{
			"value": "BURGUNDY",
			"label": "BURGUNDY"
	},
	{
			"value": "BURGUNDY BRONZE",
			"label": "BURGUNDY BRONZE"
	},
	{
			"value": "BURLAP",
			"label": "BURLAP"
	},
	{
			"value": "BURNISHED",
			"label": "BURNISHED"
	},
	{
			"value": "BURNISHED BLACK",
			"label": "BURNISHED BLACK"
	},
	{
			"value": "BURNISHED LILAC",
			"label": "BURNISHED LILAC"
	},
	{
			"value": "BURNOUT",
			"label": "BURNOUT"
	},
	{
			"value": "BURNT ORANGE",
			"label": "BURNT ORANGE"
	},
	{
			"value": "BURNT SIENNA",
			"label": "BURNT SIENNA"
	},
	{
			"value": "BUTTER",
			"label": "BUTTER"
	},
	{
			"value": "BUTTERCUP",
			"label": "BUTTERCUP"
	},
	{
			"value": "BUTTERFLY",
			"label": "BUTTERFLY"
	},
	{
			"value": "BUTTERNUT",
			"label": "BUTTERNUT"
	},
	{
			"value": "BUTTERSCOTCH",
			"label": "BUTTERSCOTCH"
	},
	{
			"value": "B&W",
			"label": "B&W"
	},
	{
			"value": "C00",
			"label": "C00"
	},
	{
			"value": "CABANA",
			"label": "CABANA"
	},
	{
			"value": "CABERNET",
			"label": "CABERNET"
	},
	{
			"value": "CACTUS",
			"label": "CACTUS"
	},
	{
			"value": "CADET",
			"label": "CADET"
	},
	{
			"value": "CAFE",
			"label": "CAFE"
	},
	{
			"value": "CAKE",
			"label": "CAKE"
	},
	{
			"value": "CAKE BOARDS",
			"label": "CAKE BOARDS"
	},
	{
			"value": "CAKE/CUPCAKE",
			"label": "CAKE/CUPCAKE"
	},
	{
			"value": "CAKE PLATES",
			"label": "CAKE PLATES"
	},
	{
			"value": "CAKE POP",
			"label": "CAKE POP"
	},
	{
			"value": "CAKE POPS",
			"label": "CAKE POPS"
	},
	{
			"value": "CAKE SPARKLES",
			"label": "CAKE SPARKLES"
	},
	{
			"value": "CALICO",
			"label": "CALICO"
	},
	{
			"value": "CALLISTE GREEN",
			"label": "CALLISTE GREEN"
	},
	{
			"value": "CAMEL",
			"label": "CAMEL"
	},
	{
			"value": "CAMEO",
			"label": "CAMEO"
	},
	{
			"value": "CAMEO BROWN",
			"label": "CAMEO BROWN"
	},
	{
			"value": "CAMO",
			"label": "CAMO"
	},
	{
			"value": "CAMOMILE",
			"label": "CAMOMILE"
	},
	{
			"value": "CAMPFIRE",
			"label": "CAMPFIRE"
	},
	{
			"value": "CANARY",
			"label": "CANARY"
	},
	{
			"value": "CAN BE REORDERED AS",
			"label": "CAN BE REORDERED AS"
	},
	{
			"value": "CANDLEHOLDER",
			"label": "CANDLEHOLDER"
	},
	{
			"value": "CANDY",
			"label": "CANDY"
	},
	{
			"value": "CANDY CORN",
			"label": "CANDY CORN"
	},
	{
			"value": "CANDY MAKIHG",
			"label": "CANDY MAKIHG"
	},
	{
			"value": "CANDY MELT COLOR",
			"label": "CANDY MELT COLOR"
	},
	{
			"value": "CANDY MELTS",
			"label": "CANDY MELTS"
	},
	{
			"value": "CANE",
			"label": "CANE"
	},
	{
			"value": "CANVAS",
			"label": "CANVAS"
	},
	{
			"value": "CANYON",
			"label": "CANYON"
	},
	{
			"value": "CAPER",
			"label": "CAPER"
	},
	{
			"value": "CAPPUCCINO",
			"label": "CAPPUCCINO"
	},
	{
			"value": "CAPRI",
			"label": "CAPRI"
	},
	{
			"value": "CARAMEL",
			"label": "CARAMEL"
	},
	{
			"value": "CARAMEL APPLE",
			"label": "CARAMEL APPLE"
	},
	{
			"value": "CARBON",
			"label": "CARBON"
	},
	{
			"value": "CARDAMON",
			"label": "CARDAMON"
	},
	{
			"value": "CARDINAL",
			"label": "CARDINAL"
	},
	{
			"value": "CARDINALRD",
			"label": "CARDINALRD"
	},
	{
			"value": "CARDINAL RED",
			"label": "CARDINAL RED"
	},
	{
			"value": "CARGO",
			"label": "CARGO"
	},
	{
			"value": "CARIBBEAN",
			"label": "CARIBBEAN"
	},
	{
			"value": "CARIBE",
			"label": "CARIBE"
	},
	{
			"value": "CARNELIAN",
			"label": "CARNELIAN"
	},
	{
			"value": "CARRIBEAN",
			"label": "CARRIBEAN"
	},
	{
			"value": "CARROT",
			"label": "CARROT"
	},
	{
			"value": "CASHEW",
			"label": "CASHEW"
	},
	{
			"value": "CASHMERE BLUE",
			"label": "CASHMERE BLUE"
	},
	{
			"value": "CASTLEROCK",
			"label": "CASTLEROCK"
	},
	{
			"value": "CAT",
			"label": "CAT"
	},
	{
			"value": "CATS",
			"label": "CATS"
	},
	{
			"value": "CATTAIL",
			"label": "CATTAIL"
	},
	{
			"value": "CAVE",
			"label": "CAVE"
	},
	{
			"value": "CAVIAR",
			"label": "CAVIAR"
	},
	{
			"value": "CAVIER",
			"label": "CAVIER"
	},
	{
			"value": "CAVITY BAR",
			"label": "CAVITY BAR"
	},
	{
			"value": "CAYANNE",
			"label": "CAYANNE"
	},
	{
			"value": "CAYENNE",
			"label": "CAYENNE"
	},
	{
			"value": "CBB PANEL",
			"label": "CBB PANEL"
	},
	{
			"value": "CBB PANEL + PANEL A",
			"label": "CBB PANEL + PANEL A"
	},
	{
			"value": "CBC AND PANEL A",
			"label": "CBC AND PANEL A"
	},
	{
			"value": "CBC PANEL",
			"label": "CBC PANEL"
	},
	{
			"value": "CBC PANEL + PANEL A",
			"label": "CBC PANEL + PANEL A"
	},
	{
			"value": "CB PANEL",
			"label": "CB PANEL"
	},
	{
			"value": "CC BOOK",
			"label": "CC BOOK"
	},
	{
			"value": "CC BUTTON CLOSE",
			"label": "CC BUTTON CLOSE"
	},
	{
			"value": "CC FLIP TOP",
			"label": "CC FLIP TOP"
	},
	{
			"value": "CC HEADER",
			"label": "CC HEADER"
	},
	{
			"value": "CC MINI FLIP",
			"label": "CC MINI FLIP"
	},
	{
			"value": "CC OSO STORAGE",
			"label": "CC OSO STORAGE"
	},
	{
			"value": "CC OVAL",
			"label": "CC OVAL"
	},
	{
			"value": "CC PANEL 4",
			"label": "CC PANEL 4"
	},
	{
			"value": "CC PANEL 5",
			"label": "CC PANEL 5"
	},
	{
			"value": "CC ROUND",
			"label": "CC ROUND"
	},
	{
			"value": "CC SQUARE",
			"label": "CC SQUARE"
	},
	{
			"value": "CC WINDOW",
			"label": "CC WINDOW"
	},
	{
			"value": "CC WINE",
			"label": "CC WINE"
	},
	{
			"value": "CEDAR",
			"label": "CEDAR"
	},
	{
			"value": "CELADON",
			"label": "CELADON"
	},
	{
			"value": "CELEBRATION",
			"label": "CELEBRATION"
	},
	{
			"value": "CELERY",
			"label": "CELERY"
	},
	{
			"value": "CELESTIAL",
			"label": "CELESTIAL"
	},
	{
			"value": "CELESTIAL BLACK",
			"label": "CELESTIAL BLACK"
	},
	{
			"value": "CELESTIAL BLUE",
			"label": "CELESTIAL BLUE"
	},
	{
			"value": "CELLOPHANE",
			"label": "CELLOPHANE"
	},
	{
			"value": "CEMENT",
			"label": "CEMENT"
	},
	{
			"value": "CERAMIC",
			"label": "CERAMIC"
	},
	{
			"value": "CERAMIC/RESIN",
			"label": "CERAMIC/RESIN"
	},
	{
			"value": "CERAMICS",
			"label": "CERAMICS"
	},
	{
			"value": "CERULEAN",
			"label": "CERULEAN"
	},
	{
			"value": "CERULEN",
			"label": "CERULEN"
	},
	{
			"value": "CEYLON",
			"label": "CEYLON"
	},
	{
			"value": "CHALK",
			"label": "CHALK"
	},
	{
			"value": "CHALKY MINT",
			"label": "CHALKY MINT"
	},
	{
			"value": "CHAMBR",
			"label": "CHAMBR"
	},
	{
			"value": "CHAMBRAY",
			"label": "CHAMBRAY"
	},
	{
			"value": "CHAMBRAY HEATHER",
			"label": "CHAMBRAY HEATHER"
	},
	{
			"value": "CHAMOI",
			"label": "CHAMOI"
	},
	{
			"value": "CHAMOIS",
			"label": "CHAMOIS"
	},
	{
			"value": "CHAMOMILE",
			"label": "CHAMOMILE"
	},
	{
			"value": "CHAMP",
			"label": "CHAMP"
	},
	{
			"value": "CHAMPAGNE",
			"label": "CHAMPAGNE"
	},
	{
			"value": "CHAMPAGNEBUSHES",
			"label": "CHAMPAGNEBUSHES"
	},
	{
			"value": "CHAMPAGNE GOLD",
			"label": "CHAMPAGNE GOLD"
	},
	{
			"value": "CHARCHOL",
			"label": "CHARCHOL"
	},
	{
			"value": "CHARCOAL",
			"label": "CHARCOAL"
	},
	{
			"value": "CHARCOAL GREY",
			"label": "CHARCOAL GREY"
	},
	{
			"value": "CHARTREUSE",
			"label": "CHARTREUSE"
	},
	{
			"value": "CHECKERBOAD",
			"label": "CHECKERBOAD"
	},
	{
			"value": "CHERRY",
			"label": "CHERRY"
	},
	{
			"value": "CHERRY RED",
			"label": "CHERRY RED"
	},
	{
			"value": "CHESTNUT",
			"label": "CHESTNUT"
	},
	{
			"value": "CHIANTI",
			"label": "CHIANTI"
	},
	{
			"value": "CHICK",
			"label": "CHICK"
	},
	{
			"value": "CHIFFON",
			"label": "CHIFFON"
	},
	{
			"value": "CHILD",
			"label": "CHILD"
	},
	{
			"value": "CHILD / GIRL",
			"label": "CHILD / GIRL"
	},
	{
			"value": "CHILDRENS COSTUME PA",
			"label": "CHILDRENS COSTUME PA"
	},
	{
			"value": "CHILDRENS PANEL 1",
			"label": "CHILDRENS PANEL 1"
	},
	{
			"value": "CHILDRENS PANEL 2",
			"label": "CHILDRENS PANEL 2"
	},
	{
			"value": "CHILDRENS PANEL 3",
			"label": "CHILDRENS PANEL 3"
	},
	{
			"value": "CHILE",
			"label": "CHILE"
	},
	{
			"value": "CHILI",
			"label": "CHILI"
	},
	{
			"value": "CHILI/MAHAGONY/GLDN",
			"label": "CHILI/MAHAGONY/GLDN"
	},
	{
			"value": "CHILI/MAHAGONY/NAT &",
			"label": "CHILI/MAHAGONY/NAT &"
	},
	{
			"value": "CHILI OIL",
			"label": "CHILI OIL"
	},
	{
			"value": "CHILI/OLIVE/NAT & RE",
			"label": "CHILI/OLIVE/NAT & RE"
	},
	{
			"value": "CHINA",
			"label": "CHINA"
	},
	{
			"value": "CHINA BLUE",
			"label": "CHINA BLUE"
	},
	{
			"value": "CHINA ROSE",
			"label": "CHINA ROSE"
	},
	{
			"value": "CHINO",
			"label": "CHINO"
	},
	{
			"value": "CHOC BROWN",
			"label": "CHOC BROWN"
	},
	{
			"value": "CHOCOLATE",
			"label": "CHOCOLATE"
	},
	{
			"value": "CHOCOLATE/AVOCADO",
			"label": "CHOCOLATE/AVOCADO"
	},
	{
			"value": "CHOCOLATE BROWN",
			"label": "CHOCOLATE BROWN"
	},
	{
			"value": "CHOCOLATE CHIP",
			"label": "CHOCOLATE CHIP"
	},
	{
			"value": "CHOCOLATE FOUNTAIN",
			"label": "CHOCOLATE FOUNTAIN"
	},
	{
			"value": "CHOCOLATE/MIST",
			"label": "CHOCOLATE/MIST"
	},
	{
			"value": "CHOCOLATESUNRISE",
			"label": "CHOCOLATESUNRISE"
	},
	{
			"value": "CHOCOLATE TERRACOTTA",
			"label": "CHOCOLATE TERRACOTTA"
	},
	{
			"value": "CHONA BROWN",
			"label": "CHONA BROWN"
	},
	{
			"value": "CHROME",
			"label": "CHROME"
	},
	{
			"value": "CHUTNEY",
			"label": "CHUTNEY"
	},
	{
			"value": "CIDER",
			"label": "CIDER"
	},
	{
			"value": "CINDER",
			"label": "CINDER"
	},
	{
			"value": "CINDERELLA BLUE",
			"label": "CINDERELLA BLUE"
	},
	{
			"value": "CINNABAR",
			"label": "CINNABAR"
	},
	{
			"value": "CINNAMON",
			"label": "CINNAMON"
	},
	{
			"value": "CITRINE",
			"label": "CITRINE"
	},
	{
			"value": "CITRONELLA",
			"label": "CITRONELLA"
	},
	{
			"value": "CITRUS",
			"label": "CITRUS"
	},
	{
			"value": "CJ49",
			"label": "CJ49"
	},
	{
			"value": "CLARET",
			"label": "CLARET"
	},
	{
			"value": "CLARSP",
			"label": "CLARSP"
	},
	{
			"value": "CLASSIC",
			"label": "CLASSIC"
	},
	{
			"value": "CLAY",
			"label": "CLAY"
	},
	{
			"value": "CLAY POT",
			"label": "CLAY POT"
	},
	{
			"value": "CLEAN",
			"label": "CLEAN"
	},
	{
			"value": "CLEAR",
			"label": "CLEAR"
	},
	{
			"value": "CLEAR ACRYLIC",
			"label": "CLEAR ACRYLIC"
	},
	{
			"value": "CLEAR GLITTER",
			"label": "CLEAR GLITTER"
	},
	{
			"value": "CLEAR IRIDESCENT",
			"label": "CLEAR IRIDESCENT"
	},
	{
			"value": "CLEAR RUBBER",
			"label": "CLEAR RUBBER"
	},
	{
			"value": "CLEARWATER",
			"label": "CLEARWATER"
	},
	{
			"value": "CLEARWATER BLUE",
			"label": "CLEARWATER BLUE"
	},
	{
			"value": "CLEAR W/ BLACK",
			"label": "CLEAR W/ BLACK"
	},
	{
			"value": "CLEAR W WOOD",
			"label": "CLEAR W WOOD"
	},
	{
			"value": "CLEMENTINE",
			"label": "CLEMENTINE"
	},
	{
			"value": "CLIP",
			"label": "CLIP"
	},
	{
			"value": "CLIP STRIP",
			"label": "CLIP STRIP"
	},
	{
			"value": "CLOTHESPIN",
			"label": "CLOTHESPIN"
	},
	{
			"value": "CLOUD",
			"label": "CLOUD"
	},
	{
			"value": "CLOVER",
			"label": "CLOVER"
	},
	{
			"value": "CLOVER BERRY",
			"label": "CLOVER BERRY"
	},
	{
			"value": "COAL",
			"label": "COAL"
	},
	{
			"value": "COASTAL",
			"label": "COASTAL"
	},
	{
			"value": "COBALT",
			"label": "COBALT"
	},
	{
			"value": "COBALT BLUE",
			"label": "COBALT BLUE"
	},
	{
			"value": "COBAL T SAPPHIRE",
			"label": "COBAL T SAPPHIRE"
	},
	{
			"value": "COBBLESTONE",
			"label": "COBBLESTONE"
	},
	{
			"value": "COCKTAIL",
			"label": "COCKTAIL"
	},
	{
			"value": "COCOA",
			"label": "COCOA"
	},
	{
			"value": "COCOA BROWN",
			"label": "COCOA BROWN"
	},
	{
			"value": "COCOA DK",
			"label": "COCOA DK"
	},
	{
			"value": "COCOA DP",
			"label": "COCOA DP"
	},
	{
			"value": "COCOA MD",
			"label": "COCOA MD"
	},
	{
			"value": "COCONUT",
			"label": "COCONUT"
	},
	{
			"value": "COCONUT MILK",
			"label": "COCONUT MILK"
	},
	{
			"value": "COCOSP",
			"label": "COCOSP"
	},
	{
			"value": "COFFEE",
			"label": "COFFEE"
	},
	{
			"value": "COFFEE BEAN",
			"label": "COFFEE BEAN"
	},
	{
			"value": "COFFEEBEAN",
			"label": "COFFEEBEAN"
	},
	{
			"value": "COFFFEE",
			"label": "COFFFEE"
	},
	{
			"value": "COFFSP",
			"label": "COFFSP"
	},
	{
			"value": "COGNAC",
			"label": "COGNAC"
	},
	{
			"value": "COIN",
			"label": "COIN"
	},
	{
			"value": "COIR",
			"label": "COIR"
	},
	{
			"value": "COLOR DUST",
			"label": "COLOR DUST"
	},
	{
			"value": "COLOR FLAME",
			"label": "COLOR FLAME"
	},
	{
			"value": "COLOR REMOVER",
			"label": "COLOR REMOVER"
	},
	{
			"value": "COLOR RIGHT",
			"label": "COLOR RIGHT"
	},
	{
			"value": "COLOR SPRAY",
			"label": "COLOR SPRAY"
	},
	{
			"value": "COMFORT GRIP",
			"label": "COMFORT GRIP"
	},
	{
			"value": "CONFET",
			"label": "CONFET"
	},
	{
			"value": "CONFETTI",
			"label": "CONFETTI"
	},
	{
			"value": "CONTAINER",
			"label": "CONTAINER"
	},
	{
			"value": "CONVERSATIONAL",
			"label": "CONVERSATIONAL"
	},
	{
			"value": "COOKIE",
			"label": "COOKIE"
	},
	{
			"value": "COOKIE CUTTERS",
			"label": "COOKIE CUTTERS"
	},
	{
			"value": "COOKIE KIT",
			"label": "COOKIE KIT"
	},
	{
			"value": "COOKIE MIX",
			"label": "COOKIE MIX"
	},
	{
			"value": "COOKIE MIXES",
			"label": "COOKIE MIXES"
	},
	{
			"value": "COOL",
			"label": "COOL"
	},
	{
			"value": "COOL BLUE",
			"label": "COOL BLUE"
	},
	{
			"value": "COOL-BLUE",
			"label": "COOL-BLUE"
	},
	{
			"value": "COOLBLUE",
			"label": "COOLBLUE"
	},
	{
			"value": "COOL BLUES MIX",
			"label": "COOL BLUES MIX"
	},
	{
			"value": "COOLING GRID",
			"label": "COOLING GRID"
	},
	{
			"value": "COOL MULTI",
			"label": "COOL MULTI"
	},
	{
			"value": "COOL-PURPLE",
			"label": "COOL-PURPLE"
	},
	{
			"value": "COPEN",
			"label": "COPEN"
	},
	{
			"value": "COPENHAGEN",
			"label": "COPENHAGEN"
	},
	{
			"value": "COPPER",
			"label": "COPPER"
	},
	{
			"value": "COPPER/ BRASS",
			"label": "COPPER/ BRASS"
	},
	{
			"value": "CORAL",
			"label": "CORAL"
	},
	{
			"value": "CORAL/PEACH",
			"label": "CORAL/PEACH"
	},
	{
			"value": "CORAL REEF",
			"label": "CORAL REEF"
	},
	{
			"value": "CORDOVAN",
			"label": "CORDOVAN"
	},
	{
			"value": "CORIAN",
			"label": "CORIAN"
	},
	{
			"value": "CORN",
			"label": "CORN"
	},
	{
			"value": "CORNFL",
			"label": "CORNFL"
	},
	{
			"value": "CORNFLOWER",
			"label": "CORNFLOWER"
	},
	{
			"value": "CORN SILK",
			"label": "CORN SILK"
	},
	{
			"value": "CORNSILK",
			"label": "CORNSILK"
	},
	{
			"value": "CORNSTALK",
			"label": "CORNSTALK"
	},
	{
			"value": "CORNUCOPIA",
			"label": "CORNUCOPIA"
	},
	{
			"value": "CORTAL",
			"label": "CORTAL"
	},
	{
			"value": "COSMOS PANEL",
			"label": "COSMOS PANEL"
	},
	{
			"value": "COTTON CANDY",
			"label": "COTTON CANDY"
	},
	{
			"value": "COTTONWOOD",
			"label": "COTTONWOOD"
	},
	{
			"value": "COUNTRY",
			"label": "COUNTRY"
	},
	{
			"value": "COUNTRY ROSE",
			"label": "COUNTRY ROSE"
	},
	{
			"value": "CPPR",
			"label": "CPPR"
	},
	{
			"value": "CPR",
			"label": "CPR"
	},
	{
			"value": "CRANBERRY",
			"label": "CRANBERRY"
	},
	{
			"value": "CREAM",
			"label": "CREAM"
	},
	{
			"value": "CREAM AZURE",
			"label": "CREAM AZURE"
	},
	{
			"value": "CREAM/BRONZE",
			"label": "CREAM/BRONZE"
	},
	{
			"value": "CREAMBRONZE",
			"label": "CREAMBRONZE"
	},
	{
			"value": "CREAM MULTI",
			"label": "CREAM MULTI"
	},
	{
			"value": "CREMA",
			"label": "CREMA"
	},
	{
			"value": "CREME",
			"label": "CREME"
	},
	{
			"value": "CRESS",
			"label": "CRESS"
	},
	{
			"value": "CRIMSON",
			"label": "CRIMSON"
	},
	{
			"value": "CRITTER",
			"label": "CRITTER"
	},
	{
			"value": "CRM",
			"label": "CRM"
	},
	{
			"value": "CRM/ PNK",
			"label": "CRM/ PNK"
	},
	{
			"value": "CROWN",
			"label": "CROWN"
	},
	{
			"value": "CRUNCH",
			"label": "CRUNCH"
	},
	{
			"value": "CRYSTAL",
			"label": "CRYSTAL"
	},
	{
			"value": "CRYSTAL AB",
			"label": "CRYSTAL AB"
	},
	{
			"value": "CTY19",
			"label": "CTY19"
	},
	{
			"value": "CTY31",
			"label": "CTY31"
	},
	{
			"value": "CUP",
			"label": "CUP"
	},
	{
			"value": "CUPCAKE",
			"label": "CUPCAKE"
	},
	{
			"value": "CUPCAKE KIT",
			"label": "CUPCAKE KIT"
	},
	{
			"value": "CUPCAKE TOPPERS",
			"label": "CUPCAKE TOPPERS"
	},
	{
			"value": "CUPCAKE WRAP",
			"label": "CUPCAKE WRAP"
	},
	{
			"value": "CUPID",
			"label": "CUPID"
	},
	{
			"value": "CURATED DECOR P1",
			"label": "CURATED DECOR P1"
	},
	{
			"value": "CURATED DECOR P2",
			"label": "CURATED DECOR P2"
	},
	{
			"value": "CURATED DECOR P4",
			"label": "CURATED DECOR P4"
	},
	{
			"value": "CURATED DECOR P5",
			"label": "CURATED DECOR P5"
	},
	{
			"value": "CURATION",
			"label": "CURATION"
	},
	{
			"value": "CURRANT",
			"label": "CURRANT"
	},
	{
			"value": "CURRY",
			"label": "CURRY"
	},
	{
			"value": "CURTAIN",
			"label": "CURTAIN"
	},
	{
			"value": "CUSTARD",
			"label": "CUSTARD"
	},
	{
			"value": "CUTLERY",
			"label": "CUTLERY"
	},
	{
			"value": "CUTTER/MAT",
			"label": "CUTTER/MAT"
	},
	{
			"value": "CUTTING CENTER",
			"label": "CUTTING CENTER"
	},
	{
			"value": "CUTWORKS",
			"label": "CUTWORKS"
	},
	{
			"value": "CYAN",
			"label": "CYAN"
	},
	{
			"value": "CYPRUS",
			"label": "CYPRUS"
	},
	{
			"value": "D00",
			"label": "D00"
	},
	{
			"value": "D5 (4-6-8-10-12)",
			"label": "D5 (4-6-8-10-12)"
	},
	{
			"value": "DAFFODIL",
			"label": "DAFFODIL"
	},
	{
			"value": "DAFFODIL YELLOW",
			"label": "DAFFODIL YELLOW"
	},
	{
			"value": "DAHLIA",
			"label": "DAHLIA"
	},
	{
			"value": "DAHLIA PURPLE",
			"label": "DAHLIA PURPLE"
	},
	{
			"value": "DAISY",
			"label": "DAISY"
	},
	{
			"value": "DAMASK",
			"label": "DAMASK"
	},
	{
			"value": "DANDELION",
			"label": "DANDELION"
	},
	{
			"value": "DANUBE",
			"label": "DANUBE"
	},
	{
			"value": "DAQUIRI",
			"label": "DAQUIRI"
	},
	{
			"value": "DARK",
			"label": "DARK"
	},
	{
			"value": "DARK BLUE",
			"label": "DARK BLUE"
	},
	{
			"value": "DARK BROWN",
			"label": "DARK BROWN"
	},
	{
			"value": "DARK CHOCOLATE",
			"label": "DARK CHOCOLATE"
	},
	{
			"value": "DARK GOLD",
			"label": "DARK GOLD"
	},
	{
			"value": "DARK GRAY",
			"label": "DARK GRAY"
	},
	{
			"value": "DARK GREEN",
			"label": "DARK GREEN"
	},
	{
			"value": "DARK GREY",
			"label": "DARK GREY"
	},
	{
			"value": "DARK HEATHER",
			"label": "DARK HEATHER"
	},
	{
			"value": "DARK HEATHER GREY",
			"label": "DARK HEATHER GREY"
	},
	{
			"value": "DARK ORANGE",
			"label": "DARK ORANGE"
	},
	{
			"value": "DARK PINK",
			"label": "DARK PINK"
	},
	{
			"value": "DARK PURPLE",
			"label": "DARK PURPLE"
	},
	{
			"value": "DARK RED",
			"label": "DARK RED"
	},
	{
			"value": "DARK YELLOW",
			"label": "DARK YELLOW"
	},
	{
			"value": "DAWN",
			"label": "DAWN"
	},
	{
			"value": "DAWN PINK",
			"label": "DAWN PINK"
	},
	{
			"value": "DAY BREAK",
			"label": "DAY BREAK"
	},
	{
			"value": "DAY LILY",
			"label": "DAY LILY"
	},
	{
			"value": "DAZZLING BLUE",
			"label": "DAZZLING BLUE"
	},
	{
			"value": "D BATH P1",
			"label": "D BATH P1"
	},
	{
			"value": "DEC",
			"label": "DEC"
	},
	{
			"value": "DECAL",
			"label": "DECAL"
	},
	{
			"value": "DECO",
			"label": "DECO"
	},
	{
			"value": "DECORATING",
			"label": "DECORATING"
	},
	{
			"value": "DECORATING BAGS",
			"label": "DECORATING BAGS"
	},
	{
			"value": "DECORATOR ICING",
			"label": "DECORATOR ICING"
	},
	{
			"value": "DECORATOR PREFERRED",
			"label": "DECORATOR PREFERRED"
	},
	{
			"value": "DECO SUGAR",
			"label": "DECO SUGAR"
	},
	{
			"value": "DEEP",
			"label": "DEEP"
	},
	{
			"value": "DEEP ATLANTIC",
			"label": "DEEP ATLANTIC"
	},
	{
			"value": "DEEP LICHEN GREEN",
			"label": "DEEP LICHEN GREEN"
	},
	{
			"value": "DEEP MAHOGANY",
			"label": "DEEP MAHOGANY"
	},
	{
			"value": "DEEP ORCHID",
			"label": "DEEP ORCHID"
	},
	{
			"value": "DEEP RED",
			"label": "DEEP RED"
	},
	{
			"value": "DEEP SEA",
			"label": "DEEP SEA"
	},
	{
			"value": "DEEP TURQUOISE",
			"label": "DEEP TURQUOISE"
	},
	{
			"value": "DEMON",
			"label": "DEMON"
	},
	{
			"value": "DENIM",
			"label": "DENIM"
	},
	{
			"value": "DENIM BLUE",
			"label": "DENIM BLUE"
	},
	{
			"value": "DENIM HEATHER",
			"label": "DENIM HEATHER"
	},
	{
			"value": "D ENT P1",
			"label": "D ENT P1"
	},
	{
			"value": "D ENT P4",
			"label": "D ENT P4"
	},
	{
			"value": "DESERT",
			"label": "DESERT"
	},
	{
			"value": "DESERT BEIGE",
			"label": "DESERT BEIGE"
	},
	{
			"value": "DESERT FLOWER",
			"label": "DESERT FLOWER"
	},
	{
			"value": "DESERT SAND",
			"label": "DESERT SAND"
	},
	{
			"value": "DEW",
			"label": "DEW"
	},
	{
			"value": "D FUCHSIA",
			"label": "D FUCHSIA"
	},
	{
			"value": "DIAMOND",
			"label": "DIAMOND"
	},
	{
			"value": "DIJON",
			"label": "DIJON"
	},
	{
			"value": "DINNER PLATE",
			"label": "DINNER PLATE"
	},
	{
			"value": "DIRECTOIRE BLUE",
			"label": "DIRECTOIRE BLUE"
	},
	{
			"value": "DISPLAY",
			"label": "DISPLAY"
	},
	{
			"value": "DISPOSABLE",
			"label": "DISPOSABLE"
	},
	{
			"value": "DIVIDED PLATE",
			"label": "DIVIDED PLATE"
	},
	{
			"value": "DIY",
			"label": "DIY"
	},
	{
			"value": "DIYACCESSORIES",
			"label": "DIYACCESSORIES"
	},
	{
			"value": "DIYBUSHES",
			"label": "DIYBUSHES"
	},
	{
			"value": "DIYCONTAINERS",
			"label": "DIYCONTAINERS"
	},
	{
			"value": "DIYPICKS",
			"label": "DIYPICKS"
	},
	{
			"value": "DK BLUE",
			"label": "DK BLUE"
	},
	{
			"value": "DK. BLUE",
			"label": "DK. BLUE"
	},
	{
			"value": "DK BROWN",
			"label": "DK BROWN"
	},
	{
			"value": "DK. BROWN",
			"label": "DK. BROWN"
	},
	{
			"value": "DK BRWN",
			"label": "DK BRWN"
	},
	{
			"value": "DK CORAL",
			"label": "DK CORAL"
	},
	{
			"value": "DK GRAY",
			"label": "DK GRAY"
	},
	{
			"value": "DK GREEN",
			"label": "DK GREEN"
	},
	{
			"value": "DK GREY",
			"label": "DK GREY"
	},
	{
			"value": "DK. GREY",
			"label": "DK. GREY"
	},
	{
			"value": "DK GRN",
			"label": "DK GRN"
	},
	{
			"value": "DKHTHRGREY",
			"label": "DKHTHRGREY"
	},
	{
			"value": "DK ORANGE",
			"label": "DK ORANGE"
	},
	{
			"value": "DK. ORANGE",
			"label": "DK. ORANGE"
	},
	{
			"value": "DK PINK",
			"label": "DK PINK"
	},
	{
			"value": "DK. PUR",
			"label": "DK. PUR"
	},
	{
			"value": "DK PURPLE",
			"label": "DK PURPLE"
	},
	{
			"value": "DK. PURPLE",
			"label": "DK. PURPLE"
	},
	{
			"value": "DK RED",
			"label": "DK RED"
	},
	{
			"value": "DK TEAL",
			"label": "DK TEAL"
	},
	{
			"value": "DK YELLOW",
			"label": "DK YELLOW"
	},
	{
			"value": "DOCKSIDE BROWN",
			"label": "DOCKSIDE BROWN"
	},
	{
			"value": "DOCUMENT",
			"label": "DOCUMENT"
	},
	{
			"value": "DOE",
			"label": "DOE"
	},
	{
			"value": "DOG",
			"label": "DOG"
	},
	{
			"value": "DOG/CAT",
			"label": "DOG/CAT"
	},
	{
			"value": "DOGS",
			"label": "DOGS"
	},
	{
			"value": "DOGWOOD",
			"label": "DOGWOOD"
	},
	{
			"value": "DOILIES",
			"label": "DOILIES"
	},
	{
			"value": "DOMINO",
			"label": "DOMINO"
	},
	{
			"value": "DONUT",
			"label": "DONUT"
	},
	{
			"value": "DOT",
			"label": "DOT"
	},
	{
			"value": "DOT MATRIX",
			"label": "DOT MATRIX"
	},
	{
			"value": "DOTS",
			"label": "DOTS"
	},
	{
			"value": "DOVE",
			"label": "DOVE"
	},
	{
			"value": "DRACULA",
			"label": "DRACULA"
	},
	{
			"value": "DRAGON",
			"label": "DRAGON"
	},
	{
			"value": "DRAGONFLY",
			"label": "DRAGONFLY"
	},
	{
			"value": "DREAM",
			"label": "DREAM"
	},
	{
			"value": "DREAMLAND",
			"label": "DREAMLAND"
	},
	{
			"value": "DRESS BLUES",
			"label": "DRESS BLUES"
	},
	{
			"value": "DRIFTWOOD",
			"label": "DRIFTWOOD"
	},
	{
			"value": "DRINKWARE",
			"label": "DRINKWARE"
	},
	{
			"value": "DRK GREEN",
			"label": "DRK GREEN"
	},
	{
			"value": "DRK GREY",
			"label": "DRK GREY"
	},
	{
			"value": "DUAL LOCATION OF VTC",
			"label": "DUAL LOCATION OF VTC"
	},
	{
			"value": "DUAL LOC EC14 52 SIT",
			"label": "DUAL LOC EC14 52 SIT"
	},
	{
			"value": "DUAL LOC EC650602",
			"label": "DUAL LOC EC650602"
	},
	{
			"value": "DUAL LOC EC650603",
			"label": "DUAL LOC EC650603"
	},
	{
			"value": "DUAL LOC EC650604",
			"label": "DUAL LOC EC650604"
	},
	{
			"value": "DUAL LOC EC650605",
			"label": "DUAL LOC EC650605"
	},
	{
			"value": "DUAL LOC EC650606",
			"label": "DUAL LOC EC650606"
	},
	{
			"value": "DUAL LOC EC650607",
			"label": "DUAL LOC EC650607"
	},
	{
			"value": "DUAL LOC EC650608",
			"label": "DUAL LOC EC650608"
	},
	{
			"value": "DUAL LOC EC650609",
			"label": "DUAL LOC EC650609"
	},
	{
			"value": "DUAL LOC EC650610",
			"label": "DUAL LOC EC650610"
	},
	{
			"value": "DUAL LOC EC650611",
			"label": "DUAL LOC EC650611"
	},
	{
			"value": "DUAL LOC EC650612",
			"label": "DUAL LOC EC650612"
	},
	{
			"value": "DUAL LOC EC650613",
			"label": "DUAL LOC EC650613"
	},
	{
			"value": "DUAL LOC EC650614",
			"label": "DUAL LOC EC650614"
	},
	{
			"value": "DUAL LOC EC650615",
			"label": "DUAL LOC EC650615"
	},
	{
			"value": "DUAL LOC EC650616",
			"label": "DUAL LOC EC650616"
	},
	{
			"value": "DUAL LOC EC650617",
			"label": "DUAL LOC EC650617"
	},
	{
			"value": "DUAL LOC EC650618",
			"label": "DUAL LOC EC650618"
	},
	{
			"value": "DUAL LOC EC650619",
			"label": "DUAL LOC EC650619"
	},
	{
			"value": "DUAL LOC EC650620",
			"label": "DUAL LOC EC650620"
	},
	{
			"value": "DUAL LOC EC650621",
			"label": "DUAL LOC EC650621"
	},
	{
			"value": "DUAL LOC EC650622",
			"label": "DUAL LOC EC650622"
	},
	{
			"value": "DUAL LOC EC660603",
			"label": "DUAL LOC EC660603"
	},
	{
			"value": " DUAL LOC EC685601,",
			"label": " DUAL LOC EC685601,"
	},
	{
			"value": "DUAL LOC EC685601",
			"label": "DUAL LOC EC685601"
	},
	{
			"value": "DUAL LOC EC800601",
			"label": "DUAL LOC EC800601"
	},
	{
			"value": "DUAL LOC GRIDFA00",
			"label": "DUAL LOC GRIDFA00"
	},
	{
			"value": "DUAL LOC ON EC685603",
			"label": "DUAL LOC ON EC685603"
	},
	{
			"value": "DUAL LOC ON EC685604",
			"label": "DUAL LOC ON EC685604"
	},
	{
			"value": "DUAL LOC OPP01",
			"label": "DUAL LOC OPP01"
	},
	{
			"value": "DUAL LOC PP10 - SET",
			"label": "DUAL LOC PP10 - SET"
	},
	{
			"value": "DUAL LOC SA685609",
			"label": "DUAL LOC SA685609"
	},
	{
			"value": "DUAL LOC SA800601",
			"label": "DUAL LOC SA800601"
	},
	{
			"value": "DUCK",
			"label": "DUCK"
	},
	{
			"value": "DUCK EGG",
			"label": "DUCK EGG"
	},
	{
			"value": "DUNE",
			"label": "DUNE"
	},
	{
			"value": "DURASHARP",
			"label": "DURASHARP"
	},
	{
			"value": "DUSK",
			"label": "DUSK"
	},
	{
			"value": "DUSKY AMETHYST",
			"label": "DUSKY AMETHYST"
	},
	{
			"value": "DUSTY BLUE",
			"label": "DUSTY BLUE"
	},
	{
			"value": "D WALL DECOR P1",
			"label": "D WALL DECOR P1"
	},
	{
			"value": "D WALL DECOR P4",
			"label": "D WALL DECOR P4"
	},
	{
			"value": "E00",
			"label": "E00"
	},
	{
			"value": "EARLY LIGHTING",
			"label": "EARLY LIGHTING"
	},
	{
			"value": "EARTH",
			"label": "EARTH"
	},
	{
			"value": "EARTH BROWN",
			"label": "EARTH BROWN"
	},
	{
			"value": "EARTHEN",
			"label": "EARTHEN"
	},
	{
			"value": "EARTH SP",
			"label": "EARTH SP"
	},
	{
			"value": "EASTER",
			"label": "EASTER"
	},
	{
			"value": "EASTER ACCESSORY",
			"label": "EASTER ACCESSORY"
	},
	{
			"value": "EASTER BUNNIES",
			"label": "EASTER BUNNIES"
	},
	{
			"value": "EASTER CURATED",
			"label": "EASTER CURATED"
	},
	{
			"value": "EASTER ENTERTAINING",
			"label": "EASTER ENTERTAINING"
	},
	{
			"value": "EASTER FLORAL",
			"label": "EASTER FLORAL"
	},
	{
			"value": "EASTER HOUSE",
			"label": "EASTER HOUSE"
	},
	{
			"value": "EASTER LITTLES",
			"label": "EASTER LITTLES"
	},
	{
			"value": "EASTER PICK",
			"label": "EASTER PICK"
	},
	{
			"value": "EBONY",
			"label": "EBONY"
	},
	{
			"value": "EBONY (GREEN/TAN)",
			"label": "EBONY (GREEN/TAN)"
	},
	{
			"value": "EC 1",
			"label": "EC 1"
	},
	{
			"value": "EC1",
			"label": "EC1"
	},
	{
			"value": "EC 10",
			"label": "EC 10"
	},
	{
			"value": "EC10",
			"label": "EC10"
	},
	{
			"value": "EC11",
			"label": "EC11"
	},
	{
			"value": "EC12",
			"label": "EC12"
	},
	{
			"value": "EC1 EC800601",
			"label": "EC1 EC800601"
	},
	{
			"value": "EC 2",
			"label": "EC 2"
	},
	{
			"value": "EC2",
			"label": "EC2"
	},
	{
			"value": "EC2 EC800602",
			"label": "EC2 EC800602"
	},
	{
			"value": "EC3",
			"label": "EC3"
	},
	{
			"value": "EC3 EC800607",
			"label": "EC3 EC800607"
	},
	{
			"value": "EC4",
			"label": "EC4"
	},
	{
			"value": "EC4 EC660603",
			"label": "EC4 EC660603"
	},
	{
			"value": "EC4 EC660603/ DUAL L",
			"label": "EC4 EC660603/ DUAL L"
	},
	{
			"value": "EC4 EC660603 FW12",
			"label": "EC4 EC660603 FW12"
	},
	{
			"value": "EC 5",
			"label": "EC 5"
	},
	{
			"value": "EC5",
			"label": "EC5"
	},
	{
			"value": "EC5 EC800609",
			"label": "EC5 EC800609"
	},
	{
			"value": "EC5 & INLINE",
			"label": "EC5 & INLINE"
	},
	{
			"value": "EC 6",
			"label": "EC 6"
	},
	{
			"value": "EC6",
			"label": "EC6"
	},
	{
			"value": "EC660603",
			"label": "EC660603"
	},
	{
			"value": "EC685601",
			"label": "EC685601"
	},
	{
			"value": "EC685603",
			"label": "EC685603"
	},
	{
			"value": "EC685603 DUAL LOC",
			"label": "EC685603 DUAL LOC"
	},
	{
			"value": "EC685609",
			"label": "EC685609"
	},
	{
			"value": "EC6 EC810601 SIDEWAL",
			"label": "EC6 EC810601 SIDEWAL"
	},
	{
			"value": "EC 7",
			"label": "EC 7"
	},
	{
			"value": "EC7",
			"label": "EC7"
	},
	{
			"value": "EC7 EC810601",
			"label": "EC7 EC810601"
	},
	{
			"value": "EC7 & INLINE",
			"label": "EC7 & INLINE"
	},
	{
			"value": "EC 8",
			"label": "EC 8"
	},
	{
			"value": "EC8",
			"label": "EC8"
	},
	{
			"value": "EC800601",
			"label": "EC800601"
	},
	{
			"value": "EC800602",
			"label": "EC800602"
	},
	{
			"value": "EC800607",
			"label": "EC800607"
	},
	{
			"value": "EC800607 DUAL LOC",
			"label": "EC800607 DUAL LOC"
	},
	{
			"value": "EC800609",
			"label": "EC800609"
	},
	{
			"value": "EC810601",
			"label": "EC810601"
	},
	{
			"value": "EC8 EC685609",
			"label": "EC8 EC685609"
	},
	{
			"value": "EC8 & INLINE",
			"label": "EC8 & INLINE"
	},
	{
			"value": "EC8 & INLINE (LATE S",
			"label": "EC8 & INLINE (LATE S"
	},
	{
			"value": "EC 9",
			"label": "EC 9"
	},
	{
			"value": "EC9",
			"label": "EC9"
	},
	{
			"value": "EC9 EC685603",
			"label": "EC9 EC685603"
	},
	{
			"value": "EC9 EC6856L",
			"label": "EC9 EC6856L"
	},
	{
			"value": "EC BBQ",
			"label": "EC BBQ"
	},
	{
			"value": "EC BIRDHOUSES",
			"label": "EC BIRDHOUSES"
	},
	{
			"value": "EC BURPEE",
			"label": "EC BURPEE"
	},
	{
			"value": "ECC660603",
			"label": "ECC660603"
	},
	{
			"value": "EC DIY",
			"label": "EC DIY"
	},
	{
			"value": "EC EMBROIDERY WD",
			"label": "EC EMBROIDERY WD"
	},
	{
			"value": "EC GROW KITS",
			"label": "EC GROW KITS"
	},
	{
			"value": "EC HANNUKAH",
			"label": "EC HANNUKAH"
	},
	{
			"value": "EC HH BATH",
			"label": "EC HH BATH"
	},
	{
			"value": "EC HOLIDAY CANDLES",
			"label": "EC HOLIDAY CANDLES"
	},
	{
			"value": "EC JINGLE BELLS",
			"label": "EC JINGLE BELLS"
	},
	{
			"value": "EC KRAFT BAG",
			"label": "EC KRAFT BAG"
	},
	{
			"value": "ECLIPSE",
			"label": "ECLIPSE"
	},
	{
			"value": "EC LOF",
			"label": "EC LOF"
	},
	{
			"value": "EC LOF FLAGS",
			"label": "EC LOF FLAGS"
	},
	{
			"value": "EC MUSHROOM",
			"label": "EC MUSHROOM"
	},
	{
			"value": "ECOM: REACHED OUT",
			"label": "ECOM: REACHED OUT"
	},
	{
			"value": "EC ORNAMENT TREE",
			"label": "EC ORNAMENT TREE"
	},
	{
			"value": "EC OUTDOOR LIGHTING",
			"label": "EC OUTDOOR LIGHTING"
	},
	{
			"value": "EC OUTDOOR ST PATS",
			"label": "EC OUTDOOR ST PATS"
	},
	{
			"value": "EC OUTDOOR VAL",
			"label": "EC OUTDOOR VAL"
	},
	{
			"value": "EC PET DÉCOR",
			"label": "EC PET DÉCOR"
	},
	{
			"value": "EC PICNIC BLANKETS",
			"label": "EC PICNIC BLANKETS"
	},
	{
			"value": "EC PILLOW COVER",
			"label": "EC PILLOW COVER"
	},
	{
			"value": "EC PILLOWS",
			"label": "EC PILLOWS"
	},
	{
			"value": "EC RELIGIOUS",
			"label": "EC RELIGIOUS"
	},
	{
			"value": "EC RIBBON",
			"label": "EC RIBBON"
	},
	{
			"value": "ECRU",
			"label": "ECRU"
	},
	{
			"value": "EC SOLAR LIGHTS",
			"label": "EC SOLAR LIGHTS"
	},
	{
			"value": "EC STATE",
			"label": "EC STATE"
	},
	{
			"value": "EC ST PATS",
			"label": "EC ST PATS"
	},
	{
			"value": "EC VAL",
			"label": "EC VAL"
	},
	{
			"value": "EDEN",
			"label": "EDEN"
	},
	{
			"value": "EDIBLE GLITTER",
			"label": "EDIBLE GLITTER"
	},
	{
			"value": "EGG",
			"label": "EGG"
	},
	{
			"value": "EGGNOG",
			"label": "EGGNOG"
	},
	{
			"value": "EGGPLANT",
			"label": "EGGPLANT"
	},
	{
			"value": "EGGSHELL",
			"label": "EGGSHELL"
	},
	{
			"value": "EHITE",
			"label": "EHITE"
	},
	{
			"value": "ELEPHANT",
			"label": "ELEPHANT"
	},
	{
			"value": "ELMWOOD",
			"label": "ELMWOOD"
	},
	{
			"value": "EMB.",
			"label": "EMB."
	},
	{
			"value": "EMBER",
			"label": "EMBER"
	},
	{
			"value": "EMBOSSED",
			"label": "EMBOSSED"
	},
	{
			"value": "EMBROIDERY",
			"label": "EMBROIDERY"
	},
	{
			"value": "EMERALD",
			"label": "EMERALD"
	},
	{
			"value": "EMOJI",
			"label": "EMOJI"
	},
	{
			"value": "EMPIRE",
			"label": "EMPIRE"
	},
	{
			"value": "END CAP",
			"label": "END CAP"
	},
	{
			"value": "ENDCAP",
			"label": "ENDCAP"
	},
	{
			"value": "ENDIVE",
			"label": "ENDIVE"
	},
	{
			"value": "ENSIGN BLUE",
			"label": "ENSIGN BLUE"
	},
	{
			"value": "ENTERTAINING",
			"label": "ENTERTAINING"
	},
	{
			"value": "ENTERTAINING PANEL",
			"label": "ENTERTAINING PANEL"
	},
	{
			"value": "EPS",
			"label": "EPS"
	},
	{
			"value": "ERINITE",
			"label": "ERINITE"
	},
	{
			"value": "ESPRESO",
			"label": "ESPRESO"
	},
	{
			"value": "ESPRESSO",
			"label": "ESPRESSO"
	},
	{
			"value": "ETCHED",
			"label": "ETCHED"
	},
	{
			"value": "EUCALYPTUS",
			"label": "EUCALYPTUS"
	},
	{
			"value": "EVENING BLUE",
			"label": "EVENING BLUE"
	},
	{
			"value": "EVERDAY WORDS",
			"label": "EVERDAY WORDS"
	},
	{
			"value": "EVERGREEN",
			"label": "EVERGREEN"
	},
	{
			"value": "EVERYDAY",
			"label": "EVERYDAY"
	},
	{
			"value": "EVERYDAY INLINE",
			"label": "EVERYDAY INLINE"
	},
	{
			"value": "EVERYDAY WORDS",
			"label": "EVERYDAY WORDS"
	},
	{
			"value": "EXPRESSO",
			"label": "EXPRESSO"
	},
	{
			"value": "EYE",
			"label": "EYE"
	},
	{
			"value": "EYEBALLS",
			"label": "EYEBALLS"
	},
	{
			"value": "EYE CANDY",
			"label": "EYE CANDY"
	},
	{
			"value": "F00",
			"label": "F00"
	},
	{
			"value": "F 2ND CURATION",
			"label": "F 2ND CURATION"
	},
	{
			"value": "F 3RD CURATION",
			"label": "F 3RD CURATION"
	},
	{
			"value": "FABRIC",
			"label": "FABRIC"
	},
	{
			"value": "FAIRY",
			"label": "FAIRY"
	},
	{
			"value": "FALL",
			"label": "FALL"
	},
	{
			"value": "FARM",
			"label": "FARM"
	},
	{
			"value": "FASHION",
			"label": "FASHION"
	},
	{
			"value": "FASHION PANEL",
			"label": "FASHION PANEL"
	},
	{
			"value": "FAWN",
			"label": "FAWN"
	},
	{
			"value": "FEATHER",
			"label": "FEATHER"
	},
	{
			"value": "FEATURE FIXTURE",
			"label": "FEATURE FIXTURE"
	},
	{
			"value": "FEATURE FIXTURE?",
			"label": "FEATURE FIXTURE?"
	},
	{
			"value": "FEATURE FIXUTRE",
			"label": "FEATURE FIXUTRE"
	},
	{
			"value": "F EC APPLE PICKING",
			"label": "F EC APPLE PICKING"
	},
	{
			"value": "F EC BONFIRE",
			"label": "F EC BONFIRE"
	},
	{
			"value": "F EC GLAM PUMPKINS",
			"label": "F EC GLAM PUMPKINS"
	},
	{
			"value": "FEDERAL",
			"label": "FEDERAL"
	},
	{
			"value": "FELT",
			"label": "FELT"
	},
	{
			"value": "FELT KIT",
			"label": "FELT KIT"
	},
	{
			"value": "FENNEL",
			"label": "FENNEL"
	},
	{
			"value": "F ENTERTAINING 1",
			"label": "F ENTERTAINING 1"
	},
	{
			"value": "F ENTERTAINING 2",
			"label": "F ENTERTAINING 2"
	},
	{
			"value": "FERN",
			"label": "FERN"
	},
	{
			"value": "FERN GREEN",
			"label": "FERN GREEN"
	},
	{
			"value": "FF1",
			"label": "FF1"
	},
	{
			"value": "FF1 FALL",
			"label": "FF1 FALL"
	},
	{
			"value": "FF1 HALLO",
			"label": "FF1 HALLO"
	},
	{
			"value": "FF1 S1",
			"label": "FF1 S1"
	},
	{
			"value": "FF1 S2",
			"label": "FF1 S2"
	},
	{
			"value": "FF1 S3",
			"label": "FF1 S3"
	},
	{
			"value": "FF1-SIDE 1",
			"label": "FF1-SIDE 1"
	},
	{
			"value": "FF1-SIDE2",
			"label": "FF1-SIDE2"
	},
	{
			"value": "FF1-SIDE 3",
			"label": "FF1-SIDE 3"
	},
	{
			"value": "FF2",
			"label": "FF2"
	},
	{
			"value": "FF2 FALL",
			"label": "FF2 FALL"
	},
	{
			"value": "FF2 HALLO",
			"label": "FF2 HALLO"
	},
	{
			"value": "FF2 S1",
			"label": "FF2 S1"
	},
	{
			"value": "FF2 S2",
			"label": "FF2 S2"
	},
	{
			"value": "FF2 S3",
			"label": "FF2 S3"
	},
	{
			"value": "FF2-SIDE 1",
			"label": "FF2-SIDE 1"
	},
	{
			"value": "FF2-SIDE2",
			"label": "FF2-SIDE2"
	},
	{
			"value": "FF2-SIDE 3",
			"label": "FF2-SIDE 3"
	},
	{
			"value": "FF3",
			"label": "FF3"
	},
	{
			"value": "FF33",
			"label": "FF33"
	},
	{
			"value": "FF3 BAMBOO ENT",
			"label": "FF3 BAMBOO ENT"
	},
	{
			"value": "FF3 COLLECT OSO",
			"label": "FF3 COLLECT OSO"
	},
	{
			"value": "FF3 FALL",
			"label": "FF3 FALL"
	},
	{
			"value": "FF3 HALLO",
			"label": "FF3 HALLO"
	},
	{
			"value": "FF3 S1",
			"label": "FF3 S1"
	},
	{
			"value": "FF3 S2",
			"label": "FF3 S2"
	},
	{
			"value": "FF3 S3",
			"label": "FF3 S3"
	},
	{
			"value": "FF3-SIDE 1",
			"label": "FF3-SIDE 1"
	},
	{
			"value": "FF3-SIDE2",
			"label": "FF3-SIDE2"
	},
	{
			"value": "FF3-SIDE 3",
			"label": "FF3-SIDE 3"
	},
	{
			"value": "F FALL GENERIC",
			"label": "F FALL GENERIC"
	},
	{
			"value": "FF AM BARN STR PLNTR",
			"label": "FF AM BARN STR PLNTR"
	},
	{
			"value": "FF AM CADDY",
			"label": "FF AM CADDY"
	},
	{
			"value": "FF AM LED STAR",
			"label": "FF AM LED STAR"
	},
	{
			"value": "FF AM STAR LIGHT",
			"label": "FF AM STAR LIGHT"
	},
	{
			"value": "FF AM TOWELS",
			"label": "FF AM TOWELS"
	},
	{
			"value": "FF AM WOOD BANNER",
			"label": "FF AM WOOD BANNER"
	},
	{
			"value": "FF AM WOOD CHIP",
			"label": "FF AM WOOD CHIP"
	},
	{
			"value": "FF AM WOOD LANTERN",
			"label": "FF AM WOOD LANTERN"
	},
	{
			"value": "FF AM WORD BLOCKS",
			"label": "FF AM WORD BLOCKS"
	},
	{
			"value": "FF BABY",
			"label": "FF BABY"
	},
	{
			"value": "FF BEACH",
			"label": "FF BEACH"
	},
	{
			"value": "F FF1 MANGO WOOD",
			"label": "F FF1 MANGO WOOD"
	},
	{
			"value": "F FF1 MUGS",
			"label": "F FF1 MUGS"
	},
	{
			"value": "F FF1 TOWELS",
			"label": "F FF1 TOWELS"
	},
	{
			"value": "F FF2 ENTERTAINING",
			"label": "F FF2 ENTERTAINING"
	},
	{
			"value": "F FF2 FABRIC PUMPKIN",
			"label": "F FF2 FABRIC PUMPKIN"
	},
	{
			"value": "F FF2 TABLESCAPEING",
			"label": "F FF2 TABLESCAPEING"
	},
	{
			"value": "F FF2 TABLESCAPING",
			"label": "F FF2 TABLESCAPING"
	},
	{
			"value": "F FF3 GLASS PUMPKINS",
			"label": "F FF3 GLASS PUMPKINS"
	},
	{
			"value": "F FF3 LEATHER",
			"label": "F FF3 LEATHER"
	},
	{
			"value": "F FF3 THROW",
			"label": "F FF3 THROW"
	},
	{
			"value": "F FF5",
			"label": "F FF5"
	},
	{
			"value": "FF FAMILY",
			"label": "FF FAMILY"
	},
	{
			"value": "FF FOOD",
			"label": "FF FOOD"
	},
	{
			"value": "FF FURRIES",
			"label": "FF FURRIES"
	},
	{
			"value": "FF HEADER",
			"label": "FF HEADER"
	},
	{
			"value": "FF HOBBY",
			"label": "FF HOBBY"
	},
	{
			"value": "FF HOOK",
			"label": "FF HOOK"
	},
	{
			"value": "F FLAGS 1",
			"label": "F FLAGS 1"
	},
	{
			"value": "F FLAGS 2",
			"label": "F FLAGS 2"
	},
	{
			"value": "FF MUSIC",
			"label": "FF MUSIC"
	},
	{
			"value": "FF NOVELTY",
			"label": "FF NOVELTY"
	},
	{
			"value": "F FOS",
			"label": "F FOS"
	},
	{
			"value": "F FOS1",
			"label": "F FOS1"
	},
	{
			"value": "F FOS 2",
			"label": "F FOS 2"
	},
	{
			"value": "F FOS2",
			"label": "F FOS2"
	},
	{
			"value": "F FOS3",
			"label": "F FOS3"
	},
	{
			"value": "F FOS4",
			"label": "F FOS4"
	},
	{
			"value": "F FOS5",
			"label": "F FOS5"
	},
	{
			"value": "F FOS6",
			"label": "F FOS6"
	},
	{
			"value": "F FOS 7",
			"label": "F FOS 7"
	},
	{
			"value": "F FOS7",
			"label": "F FOS7"
	},
	{
			"value": "FF PET",
			"label": "FF PET"
	},
	{
			"value": "FF TRAVEL",
			"label": "FF TRAVEL"
	},
	{
			"value": "FF WOOD BANNER",
			"label": "FF WOOD BANNER"
	},
	{
			"value": "FH PAN 1",
			"label": "FH PAN 1"
	},
	{
			"value": "FH PANEL 1",
			"label": "FH PANEL 1"
	},
	{
			"value": "FIBER",
			"label": "FIBER"
	},
	{
			"value": "FIENNA",
			"label": "FIENNA"
	},
	{
			"value": "FIESTA",
			"label": "FIESTA"
	},
	{
			"value": "FIG",
			"label": "FIG"
	},
	{
			"value": "FILBERT",
			"label": "FILBERT"
	},
	{
			"value": "FINDING",
			"label": "FINDING"
	},
	{
			"value": "F INLINE",
			"label": "F INLINE"
	},
	{
			"value": "FIREWORK",
			"label": "FIREWORK"
	},
	{
			"value": "FIRST BLUSH",
			"label": "FIRST BLUSH"
	},
	{
			"value": "FIXATIVE",
			"label": "FIXATIVE"
	},
	{
			"value": "FIXTURE",
			"label": "FIXTURE"
	},
	{
			"value": "FLAME",
			"label": "FLAME"
	},
	{
			"value": "FLAMINGO",
			"label": "FLAMINGO"
	},
	{
			"value": "FLAMINGO PINK",
			"label": "FLAMINGO PINK"
	},
	{
			"value": "FLARE",
			"label": "FLARE"
	},
	{
			"value": "FLATIRON",
			"label": "FLATIRON"
	},
	{
			"value": "FLAVOR",
			"label": "FLAVOR"
	},
	{
			"value": "FLAX",
			"label": "FLAX"
	},
	{
			"value": "F LIGHTING",
			"label": "F LIGHTING"
	},
	{
			"value": "FLINT",
			"label": "FLINT"
	},
	{
			"value": "FLIP FLOPS",
			"label": "FLIP FLOPS"
	},
	{
			"value": "FLOBLUE",
			"label": "FLOBLUE"
	},
	{
			"value": "FLOCKED",
			"label": "FLOCKED"
	},
	{
			"value": "FLORAL",
			"label": "FLORAL"
	},
	{
			"value": "FLORAL?",
			"label": "FLORAL?"
	},
	{
			"value": "FLORAL BOUQUET",
			"label": "FLORAL BOUQUET"
	},
	{
			"value": "FLORAL PANEL 2",
			"label": "FLORAL PANEL 2"
	},
	{
			"value": "FLOURSCENT",
			"label": "FLOURSCENT"
	},
	{
			"value": "F LOWBOY",
			"label": "F LOWBOY"
	},
	{
			"value": "FLOWER",
			"label": "FLOWER"
	},
	{
			"value": "FLOWERS",
			"label": "FLOWERS"
	},
	{
			"value": "FLR37",
			"label": "FLR37"
	},
	{
			"value": "FLUTED",
			"label": "FLUTED"
	},
	{
			"value": "F MAIN CURATION",
			"label": "F MAIN CURATION"
	},
	{
			"value": "F MATS",
			"label": "F MATS"
	},
	{
			"value": "F MATS 2",
			"label": "F MATS 2"
	},
	{
			"value": "FOAM",
			"label": "FOAM"
	},
	{
			"value": "FOG",
			"label": "FOG"
	},
	{
			"value": "FOG MIST",
			"label": "FOG MIST"
	},
	{
			"value": "FOIL",
			"label": "FOIL"
	},
	{
			"value": "FOLIAGE",
			"label": "FOLIAGE"
	},
	{
			"value": "FONDANT",
			"label": "FONDANT"
	},
	{
			"value": "FOOD",
			"label": "FOOD"
	},
	{
			"value": "FOOD/BEVERAGE",
			"label": "FOOD/BEVERAGE"
	},
	{
			"value": "FOOD COLORING",
			"label": "FOOD COLORING"
	},
	{
			"value": "FOOLS GOLD",
			"label": "FOOLS GOLD"
	},
	{
			"value": "FOOTBALL",
			"label": "FOOTBALL"
	},
	{
			"value": "FOREST GREEN",
			"label": "FOREST GREEN"
	},
	{
			"value": "FOS",
			"label": "FOS"
	},
	{
			"value": "FOS 1 FALL",
			"label": "FOS 1 FALL"
	},
	{
			"value": "FOS 1 HALLO",
			"label": "FOS 1 HALLO"
	},
	{
			"value": "FOS 2 FALL",
			"label": "FOS 2 FALL"
	},
	{
			"value": "FOS 2 HALLO",
			"label": "FOS 2 HALLO"
	},
	{
			"value": "FOS 3 FALL",
			"label": "FOS 3 FALL"
	},
	{
			"value": "FOS 3 HALLO",
			"label": "FOS 3 HALLO"
	},
	{
			"value": "FOS 4 FALL",
			"label": "FOS 4 FALL"
	},
	{
			"value": "FOS 4 HALLO",
			"label": "FOS 4 HALLO"
	},
	{
			"value": "FOS 5 FALL",
			"label": "FOS 5 FALL"
	},
	{
			"value": "FOS 5 HALLO",
			"label": "FOS 5 HALLO"
	},
	{
			"value": "FOS 6 FALL",
			"label": "FOS 6 FALL"
	},
	{
			"value": "FOS 6 HALLO",
			"label": "FOS 6 HALLO"
	},
	{
			"value": "FOS 7 FALL",
			"label": "FOS 7 FALL"
	},
	{
			"value": "FOS 7 HALLO",
			"label": "FOS 7 HALLO"
	},
	{
			"value": "FOS;  ALSO ON PP6 SA",
			"label": "FOS;  ALSO ON PP6 SA"
	},
	{
			"value": "FOS HALLO 6",
			"label": "FOS HALLO 6"
	},
	{
			"value": "FOS P1",
			"label": "FOS P1"
	},
	{
			"value": "FOS P2",
			"label": "FOS P2"
	},
	{
			"value": "FOS P3",
			"label": "FOS P3"
	},
	{
			"value": "FOS P4",
			"label": "FOS P4"
	},
	{
			"value": "FOS P5",
			"label": "FOS P5"
	},
	{
			"value": "FOS P6",
			"label": "FOS P6"
	},
	{
			"value": "FOS P7",
			"label": "FOS P7"
	},
	{
			"value": "FOS PANEL 1",
			"label": "FOS PANEL 1"
	},
	{
			"value": "FOS PANEL 3",
			"label": "FOS PANEL 3"
	},
	{
			"value": "FOS PANEL 4",
			"label": "FOS PANEL 4"
	},
	{
			"value": "FOS TREE FOREST",
			"label": "FOS TREE FOREST"
	},
	{
			"value": "FOXHUNT",
			"label": "FOXHUNT"
	},
	{
			"value": "F PET",
			"label": "F PET"
	},
	{
			"value": "F PP GEL CLINGS",
			"label": "F PP GEL CLINGS"
	},
	{
			"value": "F PUMPKINS 1",
			"label": "F PUMPKINS 1"
	},
	{
			"value": "F PUMPKINS 2",
			"label": "F PUMPKINS 2"
	},
	{
			"value": "F PUMPKINS 3",
			"label": "F PUMPKINS 3"
	},
	{
			"value": "F PUMPKINS 4",
			"label": "F PUMPKINS 4"
	},
	{
			"value": "FRAGRANT LILAC",
			"label": "FRAGRANT LILAC"
	},
	{
			"value": "FRED'S",
			"label": "FRED'S"
	},
	{
			"value": "FREEDOM BLUE",
			"label": "FREEDOM BLUE"
	},
	{
			"value": "FREESIA",
			"label": "FREESIA"
	},
	{
			"value": "FRENCH BLUE II",
			"label": "FRENCH BLUE II"
	},
	{
			"value": "FRENCH ROAST",
			"label": "FRENCH ROAST"
	},
	{
			"value": "FRENCH VANILLA",
			"label": "FRENCH VANILLA"
	},
	{
			"value": "FRESCO",
			"label": "FRESCO"
	},
	{
			"value": "FRESH IVORY",
			"label": "FRESH IVORY"
	},
	{
			"value": "FRIENDS & FAMILY",
			"label": "FRIENDS & FAMILY"
	},
	{
			"value": "FROST",
			"label": "FROST"
	},
	{
			"value": "FROSTED",
			"label": "FROSTED"
	},
	{
			"value": "FROST GRAY",
			"label": "FROST GRAY"
	},
	{
			"value": "FROZEN",
			"label": "FROZEN"
	},
	{
			"value": "FRUIT CAKE",
			"label": "FRUIT CAKE"
	},
	{
			"value": "FRUIT PUNCH",
			"label": "FRUIT PUNCH"
	},
	{
			"value": "FSHN GRLNDS PAN 1",
			"label": "FSHN GRLNDS PAN 1"
	},
	{
			"value": "F STK SCARECROWS",
			"label": "F STK SCARECROWS"
	},
	{
			"value": "FUCHSIA",
			"label": "FUCHSIA"
	},
	{
			"value": "FUCHSIA LIGHT ROSE",
			"label": "FUCHSIA LIGHT ROSE"
	},
	{
			"value": "FUCHSIA PINK",
			"label": "FUCHSIA PINK"
	},
	{
			"value": "FUDGE",
			"label": "FUDGE"
	},
	{
			"value": "FUNKINS PANEL 1",
			"label": "FUNKINS PANEL 1"
	},
	{
			"value": "FUNKINS PANEL 2",
			"label": "FUNKINS PANEL 2"
	},
	{
			"value": "FUSCHIA",
			"label": "FUSCHIA"
	},
	{
			"value": "FUSCHIA PURPLE",
			"label": "FUSCHIA PURPLE"
	},
	{
			"value": "FUSHCIA",
			"label": "FUSHCIA"
	},
	{
			"value": "FUSHIA",
			"label": "FUSHIA"
	},
	{
			"value": "FW 3 GARDEN",
			"label": "FW 3 GARDEN"
	},
	{
			"value": "F WALL DECOR 1",
			"label": "F WALL DECOR 1"
	},
	{
			"value": "F WALL DECOR 2",
			"label": "F WALL DECOR 2"
	},
	{
			"value": "F WALL DECOR 3",
			"label": "F WALL DECOR 3"
	},
	{
			"value": "F WALL DECOR 4",
			"label": "F WALL DECOR 4"
	},
	{
			"value": "G",
			"label": "G"
	},
	{
			"value": "G00",
			"label": "G00"
	},
	{
			"value": "GADGET",
			"label": "GADGET"
	},
	{
			"value": "GADGETS",
			"label": "GADGETS"
	},
	{
			"value": "GALVANIZED",
			"label": "GALVANIZED"
	},
	{
			"value": "GARDEN",
			"label": "GARDEN"
	},
	{
			"value": "GARDEN UTILITY",
			"label": "GARDEN UTILITY"
	},
	{
			"value": "GARENT",
			"label": "GARENT"
	},
	{
			"value": "GARLAND",
			"label": "GARLAND"
	},
	{
			"value": "GARLANDS",
			"label": "GARLANDS"
	},
	{
			"value": "GARNET",
			"label": "GARNET"
	},
	{
			"value": "GASLIGHT",
			"label": "GASLIGHT"
	},
	{
			"value": "GAUZE",
			"label": "GAUZE"
	},
	{
			"value": "GAY",
			"label": "GAY"
	},
	{
			"value": "G BIRDHOUSE P3",
			"label": "G BIRDHOUSE P3"
	},
	{
			"value": "GEEN LT",
			"label": "GEEN LT"
	},
	{
			"value": "GEL",
			"label": "GEL"
	},
	{
			"value": "GEL CLING",
			"label": "GEL CLING"
	},
	{
			"value": "GEM",
			"label": "GEM"
	},
	{
			"value": "GEO",
			"label": "GEO"
	},
	{
			"value": "GEOMETRIC",
			"label": "GEOMETRIC"
	},
	{
			"value": "GEORGIA PEACH",
			"label": "GEORGIA PEACH"
	},
	{
			"value": "GERANIUM",
			"label": "GERANIUM"
	},
	{
			"value": "G GARDEN CURATED SB",
			"label": "G GARDEN CURATED SB"
	},
	{
			"value": "GHIRARDELLI",
			"label": "GHIRARDELLI"
	},
	{
			"value": "GHOST",
			"label": "GHOST"
	},
	{
			"value": "GIFT CARD",
			"label": "GIFT CARD"
	},
	{
			"value": "GIFT CARD TIN",
			"label": "GIFT CARD TIN"
	},
	{
			"value": "GILDED",
			"label": "GILDED"
	},
	{
			"value": "GILDED CHOCOLATE",
			"label": "GILDED CHOCOLATE"
	},
	{
			"value": "GILT",
			"label": "GILT"
	},
	{
			"value": "GINGER",
			"label": "GINGER"
	},
	{
			"value": "GINGERBREAD",
			"label": "GINGERBREAD"
	},
	{
			"value": "GIRL",
			"label": "GIRL"
	},
	{
			"value": "GIRL / BOY",
			"label": "GIRL / BOY"
	},
	{
			"value": "GIRL / GIRL PLUS",
			"label": "GIRL / GIRL PLUS"
	},
	{
			"value": "GLACIER",
			"label": "GLACIER"
	},
	{
			"value": "GLASS",
			"label": "GLASS"
	},
	{
			"value": "GLASS BULBS",
			"label": "GLASS BULBS"
	},
	{
			"value": "GLD",
			"label": "GLD"
	},
	{
			"value": "GLDPANELACCESSORIES",
			"label": "GLDPANELACCESSORIES"
	},
	{
			"value": "G LIGHTING",
			"label": "G LIGHTING"
	},
	{
			"value": "GLITTER",
			"label": "GLITTER"
	},
	{
			"value": "GLITTER-BLACK",
			"label": "GLITTER-BLACK"
	},
	{
			"value": "GLITTER-BLUE",
			"label": "GLITTER-BLUE"
	},
	{
			"value": "GLITTERBUG BROCADE P",
			"label": "GLITTERBUG BROCADE P"
	},
	{
			"value": "GLITTERBUG BROCADE W",
			"label": "GLITTERBUG BROCADE W"
	},
	{
			"value": "GLITTERBUG FOIL DOT",
			"label": "GLITTERBUG FOIL DOT"
	},
	{
			"value": "GLITTERBUG LACE WHIT",
			"label": "GLITTERBUG LACE WHIT"
	},
	{
			"value": "GLITTERBUG OMBRE",
			"label": "GLITTERBUG OMBRE"
	},
	{
			"value": "GLITTERBUG OMBRE MES",
			"label": "GLITTERBUG OMBRE MES"
	},
	{
			"value": "GLITTERBUG ORGANZA W",
			"label": "GLITTERBUG ORGANZA W"
	},
	{
			"value": "GLITTERBUG ORGZA W J",
			"label": "GLITTERBUG ORGZA W J"
	},
	{
			"value": "GLITTERBUG PANNE W F",
			"label": "GLITTERBUG PANNE W F"
	},
	{
			"value": "GLITTERBUG SATIN W I",
			"label": "GLITTERBUG SATIN W I"
	},
	{
			"value": "GLITTER-GOLD",
			"label": "GLITTER-GOLD"
	},
	{
			"value": "GLITTER-GREEN",
			"label": "GLITTER-GREEN"
	},
	{
			"value": "GLITTER-ORANGE",
			"label": "GLITTER-ORANGE"
	},
	{
			"value": "GLITTER-PINK",
			"label": "GLITTER-PINK"
	},
	{
			"value": "GLITTER-PURPLE",
			"label": "GLITTER-PURPLE"
	},
	{
			"value": "GLITTER-RED",
			"label": "GLITTER-RED"
	},
	{
			"value": "GLITTER-SILVER",
			"label": "GLITTER-SILVER"
	},
	{
			"value": "GLITTER VIOLET",
			"label": "GLITTER VIOLET"
	},
	{
			"value": "GLITTER-YELLOW",
			"label": "GLITTER-YELLOW"
	},
	{
			"value": "GLITZ",
			"label": "GLITZ"
	},
	{
			"value": "GLOW",
			"label": "GLOW"
	},
	{
			"value": "GLOW BLUE",
			"label": "GLOW BLUE"
	},
	{
			"value": "GLOW-BLUE",
			"label": "GLOW-BLUE"
	},
	{
			"value": "G LOWBOY",
			"label": "G LOWBOY"
	},
	{
			"value": "GLOW IN THE DARK",
			"label": "GLOW IN THE DARK"
	},
	{
			"value": "GLOW-NATURAL",
			"label": "GLOW-NATURAL"
	},
	{
			"value": "GLOW-PINK",
			"label": "GLOW-PINK"
	},
	{
			"value": "GLUE",
			"label": "GLUE"
	},
	{
			"value": "G MATS & FLAGS P1",
			"label": "G MATS & FLAGS P1"
	},
	{
			"value": "G MATS & FLAGS P2",
			"label": "G MATS & FLAGS P2"
	},
	{
			"value": "G MATS & FLAGS P3",
			"label": "G MATS & FLAGS P3"
	},
	{
			"value": "GNOME",
			"label": "GNOME"
	},
	{
			"value": "GOLC",
			"label": "GOLC"
	},
	{
			"value": "GOLD",
			"label": "GOLD"
	},
	{
			"value": "GOLD AND PLUM",
			"label": "GOLD AND PLUM"
	},
	{
			"value": "GOLD/AQUA",
			"label": "GOLD/AQUA"
	},
	{
			"value": "GOLD/BLACK",
			"label": "GOLD/BLACK"
	},
	{
			"value": "GOLD/BURG",
			"label": "GOLD/BURG"
	},
	{
			"value": "GOLD/BURGDY",
			"label": "GOLD/BURGDY"
	},
	{
			"value": "GOLD/ COPPER",
			"label": "GOLD/ COPPER"
	},
	{
			"value": "GOLD/EGGNOG",
			"label": "GOLD/EGGNOG"
	},
	{
			"value": "GOLDEN",
			"label": "GOLDEN"
	},
	{
			"value": "GOLDEN HAZE",
			"label": "GOLDEN HAZE"
	},
	{
			"value": "GOLDENROD",
			"label": "GOLDENROD"
	},
	{
			"value": "GOLDEN YELLOW",
			"label": "GOLDEN YELLOW"
	},
	{
			"value": "GOLDFISH ORANGE",
			"label": "GOLDFISH ORANGE"
	},
	{
			"value": "GOLD HOLO",
			"label": "GOLD HOLO"
	},
	{
			"value": "GOLD/IVORY",
			"label": "GOLD/IVORY"
	},
	{
			"value": "GOLD ON NAVY BLUE",
			"label": "GOLD ON NAVY BLUE"
	},
	{
			"value": "GOLD ON PINK",
			"label": "GOLD ON PINK"
	},
	{
			"value": "GOLD ON RED",
			"label": "GOLD ON RED"
	},
	{
			"value": "GOLD ON WHITE",
			"label": "GOLD ON WHITE"
	},
	{
			"value": "GOLD PLATED",
			"label": "GOLD PLATED"
	},
	{
			"value": "GOLD/PURPLE",
			"label": "GOLD/PURPLE"
	},
	{
			"value": "GOLD/RED",
			"label": "GOLD/RED"
	},
	{
			"value": "GOLD/SILVER",
			"label": "GOLD/SILVER"
	},
	{
			"value": "GOLDSP",
			"label": "GOLDSP"
	},
	{
			"value": "GOLD TONES",
			"label": "GOLD TONES"
	},
	{
			"value": "GOLD/WHITE",
			"label": "GOLD/WHITE"
	},
	{
			"value": "GOLID",
			"label": "GOLID"
	},
	{
			"value": "GOOD NATURE PANEL 1",
			"label": "GOOD NATURE PANEL 1"
	},
	{
			"value": "GOOD NATURE PANEL 2",
			"label": "GOOD NATURE PANEL 2"
	},
	{
			"value": "GOOD NATURE PANEL 3",
			"label": "GOOD NATURE PANEL 3"
	},
	{
			"value": "GRAFFITI",
			"label": "GRAFFITI"
	},
	{
			"value": "GRAIN",
			"label": "GRAIN"
	},
	{
			"value": "GRANITA",
			"label": "GRANITA"
	},
	{
			"value": "GRANNY SMITH",
			"label": "GRANNY SMITH"
	},
	{
			"value": "GRAPE",
			"label": "GRAPE"
	},
	{
			"value": "GRAPEVINE",
			"label": "GRAPEVINE"
	},
	{
			"value": "GRAPHITE",
			"label": "GRAPHITE"
	},
	{
			"value": "GRASS",
			"label": "GRASS"
	},
	{
			"value": "GRASS GREEN",
			"label": "GRASS GREEN"
	},
	{
			"value": "GRAY",
			"label": "GRAY"
	},
	{
			"value": "GRAY AND IVORY",
			"label": "GRAY AND IVORY"
	},
	{
			"value": "GRAY/BLACK",
			"label": "GRAY/BLACK"
	},
	{
			"value": "GRAY DAWN",
			"label": "GRAY DAWN"
	},
	{
			"value": "GRAY DK",
			"label": "GRAY DK"
	},
	{
			"value": "GRAY DL",
			"label": "GRAY DL"
	},
	{
			"value": "GRAY HEATHER",
			"label": "GRAY HEATHER"
	},
	{
			"value": "GRAY LILAC",
			"label": "GRAY LILAC"
	},
	{
			"value": "GRAY LT",
			"label": "GRAY LT"
	},
	{
			"value": "GRAY VIOLET",
			"label": "GRAY VIOLET"
	},
	{
			"value": "GRE",
			"label": "GRE"
	},
	{
			"value": "GREEB DK",
			"label": "GREEB DK"
	},
	{
			"value": "GREEEN",
			"label": "GREEEN"
	},
	{
			"value": "GREEENRY",
			"label": "GREEENRY"
	},
	{
			"value": "GREEK DK",
			"label": "GREEK DK"
	},
	{
			"value": "GREE LT",
			"label": "GREE LT"
	},
	{
			"value": "GREEM",
			"label": "GREEM"
	},
	{
			"value": "GREEN",
			"label": "GREEN"
	},
	{
			"value": "GREEN AND GOLD",
			"label": "GREEN AND GOLD"
	},
	{
			"value": "GREEN AND RED",
			"label": "GREEN AND RED"
	},
	{
			"value": "GREEN/BLACK",
			"label": "GREEN/BLACK"
	},
	{
			"value": "GREEN/BLUE",
			"label": "GREEN/BLUE"
	},
	{
			"value": "GREEN/BLUE/WHITE",
			"label": "GREEN/BLUE/WHITE"
	},
	{
			"value": "GREEN/BRN",
			"label": "GREEN/BRN"
	},
	{
			"value": "GREEN/BRONZE",
			"label": "GREEN/BRONZE"
	},
	{
			"value": "GREEN/BROWN",
			"label": "GREEN/BROWN"
	},
	{
			"value": "GREEN, BROWN, WHITE",
			"label": "GREEN, BROWN, WHITE"
	},
	{
			"value": "GREEN/CREAM",
			"label": "GREEN/CREAM"
	},
	{
			"value": "GREEN DARK",
			"label": "GREEN DARK"
	},
	{
			"value": "GREEN  DK",
			"label": "GREEN  DK"
	},
	{
			"value": "GREEN DK",
			"label": "GREEN DK"
	},
	{
			"value": "GREEN DOT",
			"label": "GREEN DOT"
	},
	{
			"value": "GREENER PASTURES",
			"label": "GREENER PASTURES"
	},
	{
			"value": "GREENERY",
			"label": "GREENERY"
	},
	{
			"value": "GREENERYBUSHES",
			"label": "GREENERYBUSHES"
	},
	{
			"value": "GREEN FLORAL",
			"label": "GREEN FLORAL"
	},
	{
			"value": "GREENGAGE",
			"label": "GREENGAGE"
	},
	{
			"value": "GREEN/GOLD",
			"label": "GREEN/GOLD"
	},
	{
			"value": "GREEN LIGHT",
			"label": "GREEN LIGHT"
	},
	{
			"value": "GREEN LINEN",
			"label": "GREEN LINEN"
	},
	{
			"value": "GREEN LT",
			"label": "GREEN LT"
	},
	{
			"value": "GREEN LT.",
			"label": "GREEN LT."
	},
	{
			"value": "GREENLT",
			"label": "GREENLT"
	},
	{
			"value": "GREEN LY",
			"label": "GREEN LY"
	},
	{
			"value": "GREEN MD",
			"label": "GREEN MD"
	},
	{
			"value": "GREEN  MULTI",
			"label": "GREEN  MULTI"
	},
	{
			"value": "GREEN TEA",
			"label": "GREEN TEA"
	},
	{
			"value": "GREEN TINT",
			"label": "GREEN TINT"
	},
	{
			"value": "GREEN/WHITE",
			"label": "GREEN/WHITE"
	},
	{
			"value": "GREN",
			"label": "GREN"
	},
	{
			"value": "GREN DK",
			"label": "GREN DK"
	},
	{
			"value": "GREY",
			"label": "GREY"
	},
	{
			"value": "GREY/BLACK",
			"label": "GREY/BLACK"
	},
	{
			"value": "GREY/BRONZE",
			"label": "GREY/BRONZE"
	},
	{
			"value": "GREYBUSHES",
			"label": "GREYBUSHES"
	},
	{
			"value": "GREY DK",
			"label": "GREY DK"
	},
	{
			"value": "GREY FLORAL",
			"label": "GREY FLORAL"
	},
	{
			"value": "GREY HEATHER",
			"label": "GREY HEATHER"
	},
	{
			"value": "GREY LEAVES",
			"label": "GREY LEAVES"
	},
	{
			"value": "GREY  LT",
			"label": "GREY  LT"
	},
	{
			"value": "GREY LT",
			"label": "GREY LT"
	},
	{
			"value": "GREYS",
			"label": "GREYS"
	},
	{
			"value": "GREY TONES",
			"label": "GREY TONES"
	},
	{
			"value": "GREY VIOLET",
			"label": "GREY VIOLET"
	},
	{
			"value": "GREY/WHITE",
			"label": "GREY/WHITE"
	},
	{
			"value": "GRID",
			"label": "GRID"
	},
	{
			"value": "GRIDDLE",
			"label": "GRIDDLE"
	},
	{
			"value": "GRIDFA00",
			"label": "GRIDFA00"
	},
	{
			"value": "GRIDFA00 DUAL LOC",
			"label": "GRIDFA00 DUAL LOC"
	},
	{
			"value": "GRID FW12-22",
			"label": "GRID FW12-22"
	},
	{
			"value": "GRID & INLINE LATE",
			"label": "GRID & INLINE LATE"
	},
	{
			"value": "GRID ONLY",
			"label": "GRID ONLY"
	},
	{
			"value": "GRN",
			"label": "GRN"
	},
	{
			"value": "GRN/BRN",
			"label": "GRN/BRN"
	},
	{
			"value": "GRN YLW",
			"label": "GRN YLW"
	},
	{
			"value": "GROTTO",
			"label": "GROTTO"
	},
	{
			"value": "GROW BAGS",
			"label": "GROW BAGS"
	},
	{
			"value": "GRY",
			"label": "GRY"
	},
	{
			"value": "G STATUARY",
			"label": "G STATUARY"
	},
	{
			"value": "GUAVA",
			"label": "GUAVA"
	},
	{
			"value": "GUEST NAP",
			"label": "GUEST NAP"
	},
	{
			"value": "GUMBALLS",
			"label": "GUMBALLS"
	},
	{
			"value": "GUMMIES",
			"label": "GUMMIES"
	},
	{
			"value": "GUMMY",
			"label": "GUMMY"
	},
	{
			"value": "GUN METAL",
			"label": "GUN METAL"
	},
	{
			"value": "GUNMETAL",
			"label": "GUNMETAL"
	},
	{
			"value": "G YARD STAKES P1",
			"label": "G YARD STAKES P1"
	},
	{
			"value": "H 2ND CURATION",
			"label": "H 2ND CURATION"
	},
	{
			"value": "H 3RD CURATION",
			"label": "H 3RD CURATION"
	},
	{
			"value": "H5 (6-8-10-12-14)",
			"label": "H5 (6-8-10-12-14)"
	},
	{
			"value": "HALLO FASHION PUMPKI",
			"label": "HALLO FASHION PUMPKI"
	},
	{
			"value": "HALLOWEEN",
			"label": "HALLOWEEN"
	},
	{
			"value": "HALLOWEEN COSTUMES",
			"label": "HALLOWEEN COSTUMES"
	},
	{
			"value": "HALLOWEEN VALLEY 1",
			"label": "HALLOWEEN VALLEY 1"
	},
	{
			"value": "HALLOWEEN VALLEY 2",
			"label": "HALLOWEEN VALLEY 2"
	},
	{
			"value": "HALLOWEEN VALLEY 3",
			"label": "HALLOWEEN VALLEY 3"
	},
	{
			"value": "HALLOWEEN VALLEY 4",
			"label": "HALLOWEEN VALLEY 4"
	},
	{
			"value": "HALLOWEEN VALLEY 5",
			"label": "HALLOWEEN VALLEY 5"
	},
	{
			"value": "HALLOWEEN VALLEY 6",
			"label": "HALLOWEEN VALLEY 6"
	},
	{
			"value": "HALLOWEEN VALLEY 7",
			"label": "HALLOWEEN VALLEY 7"
	},
	{
			"value": "HALOGEN",
			"label": "HALOGEN"
	},
	{
			"value": "HANUKKAH",
			"label": "HANUKKAH"
	},
	{
			"value": "HARBOR BLUE",
			"label": "HARBOR BLUE"
	},
	{
			"value": "HARBOR MIST",
			"label": "HARBOR MIST"
	},
	{
			"value": "HARVEST",
			"label": "HARVEST"
	},
	{
			"value": "HARVEST GOLD",
			"label": "HARVEST GOLD"
	},
	{
			"value": "HAUTE RED",
			"label": "HAUTE RED"
	},
	{
			"value": "HAWAII",
			"label": "HAWAII"
	},
	{
			"value": "HAY",
			"label": "HAY"
	},
	{
			"value": "HAZE",
			"label": "HAZE"
	},
	{
			"value": "HAZELNUT",
			"label": "HAZELNUT"
	},
	{
			"value": "H BONES",
			"label": "H BONES"
	},
	{
			"value": "H DAY OF THE DEAD",
			"label": "H DAY OF THE DEAD"
	},
	{
			"value": "HEADER",
			"label": "HEADER"
	},
	{
			"value": "HEART",
			"label": "HEART"
	},
	{
			"value": "HEARTS",
			"label": "HEARTS"
	},
	{
			"value": "HEART SHAPE",
			"label": "HEART SHAPE"
	},
	{
			"value": "HEATHER",
			"label": "HEATHER"
	},
	{
			"value": "HEATHER BLUE",
			"label": "HEATHER BLUE"
	},
	{
			"value": "HEATHERED",
			"label": "HEATHERED"
	},
	{
			"value": "HEATHER GRAY",
			"label": "HEATHER GRAY"
	},
	{
			"value": "HEATHER GREEN",
			"label": "HEATHER GREEN"
	},
	{
			"value": "HEATHER NAVY",
			"label": "HEATHER NAVY"
	},
	{
			"value": "HEATHER PURPLE",
			"label": "HEATHER PURPLE"
	},
	{
			"value": "HEATHER RED",
			"label": "HEATHER RED"
	},
	{
			"value": "HEATHER ROSE",
			"label": "HEATHER ROSE"
	},
	{
			"value": "HEATHER ROYAL",
			"label": "HEATHER ROYAL"
	},
	{
			"value": "HEATHER SAPPHIRE",
			"label": "HEATHER SAPPHIRE"
	},
	{
			"value": "HEAVY DUTY",
			"label": "HEAVY DUTY"
	},
	{
			"value": "H EC BOWLS BAGS",
			"label": "H EC BOWLS BAGS"
	},
	{
			"value": "H EC DAY OF THE DEAD",
			"label": "H EC DAY OF THE DEAD"
	},
	{
			"value": "H EC ENTERTAINING",
			"label": "H EC ENTERTAINING"
	},
	{
			"value": "H EC IRIDESCENT DECO",
			"label": "H EC IRIDESCENT DECO"
	},
	{
			"value": "HEDGEHOG",
			"label": "HEDGEHOG"
	},
	{
			"value": "HEIRLOOM TONES",
			"label": "HEIRLOOM TONES"
	},
	{
			"value": "HELICONIA",
			"label": "HELICONIA"
	},
	{
			"value": "HEMP",
			"label": "HEMP"
	},
	{
			"value": "HENNA",
			"label": "HENNA"
	},
	{
			"value": "H ENTERTAINING1",
			"label": "H ENTERTAINING1"
	},
	{
			"value": "H ENTERTAINING2",
			"label": "H ENTERTAINING2"
	},
	{
			"value": "H ENTERTAINING3",
			"label": "H ENTERTAINING3"
	},
	{
			"value": "HERB",
			"label": "HERB"
	},
	{
			"value": "HERBAL",
			"label": "HERBAL"
	},
	{
			"value": "HERB GARDEN",
			"label": "HERB GARDEN"
	},
	{
			"value": "HERB STAKE",
			"label": "HERB STAKE"
	},
	{
			"value": "HERITAGE GOLD",
			"label": "HERITAGE GOLD"
	},
	{
			"value": "HERO",
			"label": "HERO"
	},
	{
			"value": "HEXAGON",
			"label": "HEXAGON"
	},
	{
			"value": "H FABRIC",
			"label": "H FABRIC"
	},
	{
			"value": "H FF1",
			"label": "H FF1"
	},
	{
			"value": "H FF1 BAGGED PUMPKIN",
			"label": "H FF1 BAGGED PUMPKIN"
	},
	{
			"value": "H FF1 KEY ICON",
			"label": "H FF1 KEY ICON"
	},
	{
			"value": "H FF1 TOWELS",
			"label": "H FF1 TOWELS"
	},
	{
			"value": "H FF2",
			"label": "H FF2"
	},
	{
			"value": "H FF2 DECANTERS",
			"label": "H FF2 DECANTERS"
	},
	{
			"value": "H FF2 WALL DECOR",
			"label": "H FF2 WALL DECOR"
	},
	{
			"value": "H FF3",
			"label": "H FF3"
	},
	{
			"value": "H FF3 CRITTERS",
			"label": "H FF3 CRITTERS"
	},
	{
			"value": "H FF3 SOLAR STAKES",
			"label": "H FF3 SOLAR STAKES"
	},
	{
			"value": "H FF3 YARD STAKES",
			"label": "H FF3 YARD STAKES"
	},
	{
			"value": "H FOS",
			"label": "H FOS"
	},
	{
			"value": "H FOS1",
			"label": "H FOS1"
	},
	{
			"value": "H FOS2",
			"label": "H FOS2"
	},
	{
			"value": "H FOS3",
			"label": "H FOS3"
	},
	{
			"value": "H FOS4",
			"label": "H FOS4"
	},
	{
			"value": "H FOS5",
			"label": "H FOS5"
	},
	{
			"value": "H FOS6",
			"label": "H FOS6"
	},
	{
			"value": "H FOS7",
			"label": "H FOS7"
	},
	{
			"value": "H GARLANDS & WREATHS",
			"label": "H GARLANDS & WREATHS"
	},
	{
			"value": "H HALLOWEEN GENERIC",
			"label": "H HALLOWEEN GENERIC"
	},
	{
			"value": "HH BASKETS",
			"label": "HH BASKETS"
	},
	{
			"value": "HH DEC BX",
			"label": "HH DEC BX"
	},
	{
			"value": "HH DÉCOR",
			"label": "HH DÉCOR"
	},
	{
			"value": "HH FF",
			"label": "HH FF"
	},
	{
			"value": "HH FOS",
			"label": "HH FOS"
	},
	{
			"value": "HH GARLAND",
			"label": "HH GARLAND"
	},
	{
			"value": "HH GIFTBAGS",
			"label": "HH GIFTBAGS"
	},
	{
			"value": "HH HEADER",
			"label": "HH HEADER"
	},
	{
			"value": "HH INLINE ENTERTAIN",
			"label": "HH INLINE ENTERTAIN"
	},
	{
			"value": "HH INLINE PET",
			"label": "HH INLINE PET"
	},
	{
			"value": "HH MAT/FLAG",
			"label": "HH MAT/FLAG"
	},
	{
			"value": "HH OSO",
			"label": "HH OSO"
	},
	{
			"value": "HH OSO STORAGE",
			"label": "HH OSO STORAGE"
	},
	{
			"value": "HH OVAL",
			"label": "HH OVAL"
	},
	{
			"value": "HH PET",
			"label": "HH PET"
	},
	{
			"value": "HH QUAD",
			"label": "HH QUAD"
	},
	{
			"value": "HH SHATTER",
			"label": "HH SHATTER"
	},
	{
			"value": "HH STOCKING/TREE",
			"label": "HH STOCKING/TREE"
	},
	{
			"value": "HH TEXTILE",
			"label": "HH TEXTILE"
	},
	{
			"value": "HH TEXTILES",
			"label": "HH TEXTILES"
	},
	{
			"value": "HH TREE DECOR",
			"label": "HH TREE DECOR"
	},
	{
			"value": "HH WRAP",
			"label": "HH WRAP"
	},
	{
			"value": "HH WRAP ACCESSORIES",
			"label": "HH WRAP ACCESSORIES"
	},
	{
			"value": "HIBISCUS RED",
			"label": "HIBISCUS RED"
	},
	{
			"value": "HICKORY",
			"label": "HICKORY"
	},
	{
			"value": "HIGH RISK RED",
			"label": "HIGH RISK RED"
	},
	{
			"value": "H INLINE",
			"label": "H INLINE"
	},
	{
			"value": "HISTORIC BRASS",
			"label": "HISTORIC BRASS"
	},
	{
			"value": "H JEEPERS CREEPERS1",
			"label": "H JEEPERS CREEPERS1"
	},
	{
			"value": "H JEEPERS CREEPERS2",
			"label": "H JEEPERS CREEPERS2"
	},
	{
			"value": "H JEEPERS CREEPERS3",
			"label": "H JEEPERS CREEPERS3"
	},
	{
			"value": "H LIGHTING",
			"label": "H LIGHTING"
	},
	{
			"value": "H LOWBOY",
			"label": "H LOWBOY"
	},
	{
			"value": "H MAIN CURATION",
			"label": "H MAIN CURATION"
	},
	{
			"value": "H MATS & FLAGS",
			"label": "H MATS & FLAGS"
	},
	{
			"value": "HM DEC BX",
			"label": "HM DEC BX"
	},
	{
			"value": "HM INLINE DECOR",
			"label": "HM INLINE DECOR"
	},
	{
			"value": "HM OSO",
			"label": "HM OSO"
	},
	{
			"value": "HM SHATTER",
			"label": "HM SHATTER"
	},
	{
			"value": "HOBBY/TRAVEL",
			"label": "HOBBY/TRAVEL"
	},
	{
			"value": "HOL GIFT BOW AST4",
			"label": "HOL GIFT BOW AST4"
	},
	{
			"value": "HOLIDAY GREEN",
			"label": "HOLIDAY GREEN"
	},
	{
			"value": "HOLOGRAPHIC",
			"label": "HOLOGRAPHIC"
	},
	{
			"value": "HOME",
			"label": "HOME"
	},
	{
			"value": "HOME DEC",
			"label": "HOME DEC"
	},
	{
			"value": "HOMESTEAD",
			"label": "HOMESTEAD"
	},
	{
			"value": "HOMESTEADACCESSORIES",
			"label": "HOMESTEADACCESSORIES"
	},
	{
			"value": "HOMESTEADARRANGEMENT",
			"label": "HOMESTEADARRANGEMENT"
	},
	{
			"value": "HOMESTEADBUSHES",
			"label": "HOMESTEADBUSHES"
	},
	{
			"value": "HOMESTEADCONTAINERS",
			"label": "HOMESTEADCONTAINERS"
	},
	{
			"value": "HOMESTEADPICKS",
			"label": "HOMESTEADPICKS"
	},
	{
			"value": "HONEY",
			"label": "HONEY"
	},
	{
			"value": "HONEY DEW",
			"label": "HONEY DEW"
	},
	{
			"value": "HONEYDEW",
			"label": "HONEYDEW"
	},
	{
			"value": "H OOL",
			"label": "H OOL"
	},
	{
			"value": "HORIZON",
			"label": "HORIZON"
	},
	{
			"value": "H ORNAMENTS",
			"label": "H ORNAMENTS"
	},
	{
			"value": "HOT PETUNIA",
			"label": "HOT PETUNIA"
	},
	{
			"value": "HOT PINK",
			"label": "HOT PINK"
	},
	{
			"value": "HOT SAND",
			"label": "HOT SAND"
	},
	{
			"value": "HOUSE",
			"label": "HOUSE"
	},
	{
			"value": "H OUTDOOR",
			"label": "H OUTDOOR"
	},
	{
			"value": "H PICKS STEMS",
			"label": "H PICKS STEMS"
	},
	{
			"value": "H PP CARVING TOOL",
			"label": "H PP CARVING TOOL"
	},
	{
			"value": "H PP DAY OF THE DEAD",
			"label": "H PP DAY OF THE DEAD"
	},
	{
			"value": "H PP GEL CLINGS",
			"label": "H PP GEL CLINGS"
	},
	{
			"value": "H PP SOLAR STAKES",
			"label": "H PP SOLAR STAKES"
	},
	{
			"value": "H PUMPKINS",
			"label": "H PUMPKINS"
	},
	{
			"value": "H SDWK XL PUMPKIN &",
			"label": "H SDWK XL PUMPKIN &"
	},
	{
			"value": "H SDWK XL SPIDER",
			"label": "H SDWK XL SPIDER"
	},
	{
			"value": "H SDWK XL TOMBSTONE",
			"label": "H SDWK XL TOMBSTONE"
	},
	{
			"value": "H SKULLS",
			"label": "H SKULLS"
	},
	{
			"value": "H STK PILLOWS",
			"label": "H STK PILLOWS"
	},
	{
			"value": "H TAILS & NOSES",
			"label": "H TAILS & NOSES"
	},
	{
			"value": "HTHR DARK GREY",
			"label": "HTHR DARK GREY"
	},
	{
			"value": "HTHR DK GREY",
			"label": "HTHR DK GREY"
	},
	{
			"value": "HTHR FOREST GREEN",
			"label": "HTHR FOREST GREEN"
	},
	{
			"value": "HTHR IRISH GREEN",
			"label": "HTHR IRISH GREEN"
	},
	{
			"value": "HTHR MILITARY GREEN",
			"label": "HTHR MILITARY GREEN"
	},
	{
			"value": "HT PINK",
			"label": "HT PINK"
	},
	{
			"value": "HUGS/KISSES",
			"label": "HUGS/KISSES"
	},
	{
			"value": "HUNTER",
			"label": "HUNTER"
	},
	{
			"value": "HUNTER GREEN",
			"label": "HUNTER GREEN"
	},
	{
			"value": "H WALL DECOR1",
			"label": "H WALL DECOR1"
	},
	{
			"value": "H WALL DECOR2",
			"label": "H WALL DECOR2"
	},
	{
			"value": "H WALL DECOR3",
			"label": "H WALL DECOR3"
	},
	{
			"value": "HWPAR",
			"label": "HWPAR"
	},
	{
			"value": "H WREATHS",
			"label": "H WREATHS"
	},
	{
			"value": "HYACINTH",
			"label": "HYACINTH"
	},
	{
			"value": "HYCACINTH",
			"label": "HYCACINTH"
	},
	{
			"value": "HYDRANGEA",
			"label": "HYDRANGEA"
	},
	{
			"value": "ICE",
			"label": "ICE"
	},
	{
			"value": "ICE TRAY",
			"label": "ICE TRAY"
	},
	{
			"value": "ICICLE",
			"label": "ICICLE"
	},
	{
			"value": "ICING",
			"label": "ICING"
	},
	{
			"value": "ICING DEC",
			"label": "ICING DEC"
	},
	{
			"value": "ICON",
			"label": "ICON"
	},
	{
			"value": "ICY BLUE",
			"label": "ICY BLUE"
	},
	{
			"value": "ICY BLUE/IVORY",
			"label": "ICY BLUE/IVORY"
	},
	{
			"value": "ICY BLUE/SILVER",
			"label": "ICY BLUE/SILVER"
	},
	{
			"value": "IDAHO POTATO",
			"label": "IDAHO POTATO"
	},
	{
			"value": "IGNORE",
			"label": "IGNORE"
	},
	{
			"value": "ILLUSION",
			"label": "ILLUSION"
	},
	{
			"value": "ILLUSION BLUE",
			"label": "ILLUSION BLUE"
	},
	{
			"value": "INCA GOLD",
			"label": "INCA GOLD"
	},
	{
			"value": "INDG",
			"label": "INDG"
	},
	{
			"value": "INDGDENIM",
			"label": "INDGDENIM"
	},
	{
			"value": "INDIGO",
			"label": "INDIGO"
	},
	{
			"value": "INDUSTRIAL",
			"label": "INDUSTRIAL"
	},
	{
			"value": "INFANT PANEL",
			"label": "INFANT PANEL"
	},
	{
			"value": "INK",
			"label": "INK"
	},
	{
			"value": "IN LINE",
			"label": "IN LINE"
	},
	{
			"value": "INLINE",
			"label": "INLINE"
	},
	{
			"value": "INLINE EASTER",
			"label": "INLINE EASTER"
	},
	{
			"value": "INLINE & GRID",
			"label": "INLINE & GRID"
	},
	{
			"value": "IN LINE PICKS",
			"label": "IN LINE PICKS"
	},
	{
			"value": "INLINE ST PATS",
			"label": "INLINE ST PATS"
	},
	{
			"value": "INLINE VAL",
			"label": "INLINE VAL"
	},
	{
			"value": "IN LINE WREATHS",
			"label": "IN LINE WREATHS"
	},
	{
			"value": "INSECT",
			"label": "INSECT"
	},
	{
			"value": "INTENSE VELVET",
			"label": "INTENSE VELVET"
	},
	{
			"value": "IRDESCENT",
			"label": "IRDESCENT"
	},
	{
			"value": "IRIDESCENT",
			"label": "IRIDESCENT"
	},
	{
			"value": "IRIDISENT",
			"label": "IRIDISENT"
	},
	{
			"value": "IRIS",
			"label": "IRIS"
	},
	{
			"value": "IRISH GREEN",
			"label": "IRISH GREEN"
	},
	{
			"value": "IRON",
			"label": "IRON"
	},
	{
			"value": "IRRDESCENT",
			"label": "IRRDESCENT"
	},
	{
			"value": "IRREDESENT",
			"label": "IRREDESENT"
	},
	{
			"value": "ISLAND",
			"label": "ISLAND"
	},
	{
			"value": "ISLAND BLUE",
			"label": "ISLAND BLUE"
	},
	{
			"value": "ITALIAN BLACK",
			"label": "ITALIAN BLACK"
	},
	{
			"value": "IVORRY",
			"label": "IVORRY"
	},
	{
			"value": "IVORY",
			"label": "IVORY"
	},
	{
			"value": "IVORY/GOLD",
			"label": "IVORY/GOLD"
	},
	{
			"value": "IVORY RASPBERRY",
			"label": "IVORY RASPBERRY"
	},
	{
			"value": "IVY",
			"label": "IVY"
	},
	{
			"value": "JADE",
			"label": "JADE"
	},
	{
			"value": "JAN",
			"label": "JAN"
	},
	{
			"value": "JASMINE",
			"label": "JASMINE"
	},
	{
			"value": "JAVA",
			"label": "JAVA"
	},
	{
			"value": "JEANS BLUE",
			"label": "JEANS BLUE"
	},
	{
			"value": "JERSEY",
			"label": "JERSEY"
	},
	{
			"value": "JET",
			"label": "JET"
	},
	{
			"value": "JETER RED",
			"label": "JETER RED"
	},
	{
			"value": "JET SILVER NIGHT",
			"label": "JET SILVER NIGHT"
	},
	{
			"value": "JEWEL",
			"label": "JEWEL"
	},
	{
			"value": "JIMMIES",
			"label": "JIMMIES"
	},
	{
			"value": "JOLLY GREEN",
			"label": "JOLLY GREEN"
	},
	{
			"value": "JONQUIL",
			"label": "JONQUIL"
	},
	{
			"value": "JUBILEE",
			"label": "JUBILEE"
	},
	{
			"value": "JUL",
			"label": "JUL"
	},
	{
			"value": "JULIP",
			"label": "JULIP"
	},
	{
			"value": "JULY",
			"label": "JULY"
	},
	{
			"value": "JUMBO",
			"label": "JUMBO"
	},
	{
			"value": "JUN",
			"label": "JUN"
	},
	{
			"value": "JUNE BUG",
			"label": "JUNE BUG"
	},
	{
			"value": "JUNGLE",
			"label": "JUNGLE"
	},
	{
			"value": "JUNGLE GOLD",
			"label": "JUNGLE GOLD"
	},
	{
			"value": "JUNIOR",
			"label": "JUNIOR"
	},
	{
			"value": "JUNIPER",
			"label": "JUNIPER"
	},
	{
			"value": "JUTE",
			"label": "JUTE"
	},
	{
			"value": "JUVENILE",
			"label": "JUVENILE"
	},
	{
			"value": "K5 (8-10-12-14-16)",
			"label": "K5 (8-10-12-14-16)"
	},
	{
			"value": "KAHLUA",
			"label": "KAHLUA"
	},
	{
			"value": "KATCHUP",
			"label": "KATCHUP"
	},
	{
			"value": "KELLY",
			"label": "KELLY"
	},
	{
			"value": "KELLY GREEN",
			"label": "KELLY GREEN"
	},
	{
			"value": "KENTUCKY",
			"label": "KENTUCKY"
	},
	{
			"value": "KERRY GREEN",
			"label": "KERRY GREEN"
	},
	{
			"value": "KEYBOARD",
			"label": "KEYBOARD"
	},
	{
			"value": "KEYLIME",
			"label": "KEYLIME"
	},
	{
			"value": "KHAKI",
			"label": "KHAKI"
	},
	{
			"value": "KIDS/SPORTS",
			"label": "KIDS/SPORTS"
	},
	{
			"value": "KIT",
			"label": "KIT"
	},
	{
			"value": "KITCH",
			"label": "KITCH"
	},
	{
			"value": "KITCHEN",
			"label": "KITCHEN"
	},
	{
			"value": "KIWI",
			"label": "KIWI"
	},
	{
			"value": "KOHL",
			"label": "KOHL"
	},
	{
			"value": "KOI",
			"label": "KOI"
	},
	{
			"value": "KONA",
			"label": "KONA"
	},
	{
			"value": "KRAFT",
			"label": "KRAFT"
	},
	{
			"value": "KRAFT/GOLD",
			"label": "KRAFT/GOLD"
	},
	{
			"value": "LABEL",
			"label": "LABEL"
	},
	{
			"value": "LACE",
			"label": "LACE"
	},
	{
			"value": "LACQUE",
			"label": "LACQUE"
	},
	{
			"value": "LACQUER",
			"label": "LACQUER"
	},
	{
			"value": "LACQUER - EBONY",
			"label": "LACQUER - EBONY"
	},
	{
			"value": "LACQUER-EBONY",
			"label": "LACQUER-EBONY"
	},
	{
			"value": "LAGOON",
			"label": "LAGOON"
	},
	{
			"value": "LAGUNA",
			"label": "LAGUNA"
	},
	{
			"value": "LAKE",
			"label": "LAKE"
	},
	{
			"value": "LAKESIDE",
			"label": "LAKESIDE"
	},
	{
			"value": "LAPIS",
			"label": "LAPIS"
	},
	{
			"value": "LARKSP",
			"label": "LARKSP"
	},
	{
			"value": "LATTE",
			"label": "LATTE"
	},
	{
			"value": "LAUREL",
			"label": "LAUREL"
	},
	{
			"value": "LAVA",
			"label": "LAVA"
	},
	{
			"value": "LAVENDER",
			"label": "LAVENDER"
	},
	{
			"value": "LAVENDER BLUE",
			"label": "LAVENDER BLUE"
	},
	{
			"value": "LAVENDER FOG",
			"label": "LAVENDER FOG"
	},
	{
			"value": "LAVENDER TINT",
			"label": "LAVENDER TINT"
	},
	{
			"value": "LAVENDER TWIST",
			"label": "LAVENDER TWIST"
	},
	{
			"value": "LAYERS",
			"label": "LAYERS"
	},
	{
			"value": "LEAF",
			"label": "LEAF"
	},
	{
			"value": "LEAF GREEN",
			"label": "LEAF GREEN"
	},
	{
			"value": "LEATHER",
			"label": "LEATHER"
	},
	{
			"value": "LED MOTION PANEL 8",
			"label": "LED MOTION PANEL 8"
	},
	{
			"value": "LEEK",
			"label": "LEEK"
	},
	{
			"value": "LEMON",
			"label": "LEMON"
	},
	{
			"value": "LEMONADE",
			"label": "LEMONADE"
	},
	{
			"value": "LEMON DROP",
			"label": "LEMON DROP"
	},
	{
			"value": "LEMONGRASS",
			"label": "LEMONGRASS"
	},
	{
			"value": "LEMON MERINGUE",
			"label": "LEMON MERINGUE"
	},
	{
			"value": "LEMON YELLOW",
			"label": "LEMON YELLOW"
	},
	{
			"value": "LEOPARD",
			"label": "LEOPARD"
	},
	{
			"value": "LETTERS AND NUMBERS",
			"label": "LETTERS AND NUMBERS"
	},
	{
			"value": "LETTUCE",
			"label": "LETTUCE"
	},
	{
			"value": "LGITH SIAM",
			"label": "LGITH SIAM"
	},
	{
			"value": "LIBERTY",
			"label": "LIBERTY"
	},
	{
			"value": "LICENSED",
			"label": "LICENSED"
	},
	{
			"value": "LICHEN",
			"label": "LICHEN"
	},
	{
			"value": "LICORICE",
			"label": "LICORICE"
	},
	{
			"value": "LIGHT",
			"label": "LIGHT"
	},
	{
			"value": "LIGHT BLUE",
			"label": "LIGHT BLUE"
	},
	{
			"value": "LIGHT BROWN",
			"label": "LIGHT BROWN"
	},
	{
			"value": "LIGHT COLORADO TOPAZ",
			"label": "LIGHT COLORADO TOPAZ"
	},
	{
			"value": "LIGHT CORAL",
			"label": "LIGHT CORAL"
	},
	{
			"value": "LIGHT CREAM",
			"label": "LIGHT CREAM"
	},
	{
			"value": "LIGHT GRAY",
			"label": "LIGHT GRAY"
	},
	{
			"value": "LIGHT GREEN",
			"label": "LIGHT GREEN"
	},
	{
			"value": "LIGHT GREY",
			"label": "LIGHT GREY"
	},
	{
			"value": "LIGHTING",
			"label": "LIGHTING"
	},
	{
			"value": "LIGHT IRIS",
			"label": "LIGHT IRIS"
	},
	{
			"value": "LIGHT ORANGE",
			"label": "LIGHT ORANGE"
	},
	{
			"value": "LIGHT PINK",
			"label": "LIGHT PINK"
	},
	{
			"value": "LIGHT PURPLE",
			"label": "LIGHT PURPLE"
	},
	{
			"value": "LIGHT RED",
			"label": "LIGHT RED"
	},
	{
			"value": "LIGHT SAPPHIRE",
			"label": "LIGHT SAPPHIRE"
	},
	{
			"value": "LIGHT SIAM",
			"label": "LIGHT SIAM"
	},
	{
			"value": "LIGHT SOUND",
			"label": "LIGHT SOUND"
	},
	{
			"value": "LIGHT YELLOW",
			"label": "LIGHT YELLOW"
	},
	{
			"value": "LILAC",
			"label": "LILAC"
	},
	{
			"value": "LILAC HYDRA PANEL",
			"label": "LILAC HYDRA PANEL"
	},
	{
			"value": "LILY",
			"label": "LILY"
	},
	{
			"value": "LIME",
			"label": "LIME"
	},
	{
			"value": "LIMEADE",
			"label": "LIMEADE"
	},
	{
			"value": "LIME GREEN",
			"label": "LIME GREEN"
	},
	{
			"value": "LIME NEON",
			"label": "LIME NEON"
	},
	{
			"value": "LINED",
			"label": "LINED"
	},
	{
			"value": "LINEN",
			"label": "LINEN"
	},
	{
			"value": "LIONESS",
			"label": "LIONESS"
	},
	{
			"value": "LIPSTICK",
			"label": "LIPSTICK"
	},
	{
			"value": "LITTLE BOY BLUE",
			"label": "LITTLE BOY BLUE"
	},
	{
			"value": "LITTLES",
			"label": "LITTLES"
	},
	{
			"value": "LIVING ROOM",
			"label": "LIVING ROOM"
	},
	{
			"value": "LIZARD",
			"label": "LIZARD"
	},
	{
			"value": "LJC91053",
			"label": "LJC91053"
	},
	{
			"value": "LJN549",
			"label": "LJN549"
	},
	{
			"value": "LND OF FREE STCK OUT",
			"label": "LND OF FREE STCK OUT"
	},
	{
			"value": "LOAF",
			"label": "LOAF"
	},
	{
			"value": "LOAF KIT",
			"label": "LOAF KIT"
	},
	{
			"value": "LOD05",
			"label": "LOD05"
	},
	{
			"value": "LOD09",
			"label": "LOD09"
	},
	{
			"value": "LOD22",
			"label": "LOD22"
	},
	{
			"value": "LODEN",
			"label": "LODEN"
	},
	{
			"value": "LOLLIPOPS",
			"label": "LOLLIPOPS"
	},
	{
			"value": "LOTUS",
			"label": "LOTUS"
	},
	{
			"value": "LOTUS PINK",
			"label": "LOTUS PINK"
	},
	{
			"value": "LOWBOY",
			"label": "LOWBOY"
	},
	{
			"value": "LOWBOY ONLY",
			"label": "LOWBOY ONLY"
	},
	{
			"value": "LOWBOY OR OOL",
			"label": "LOWBOY OR OOL"
	},
	{
			"value": "LOX",
			"label": "LOX"
	},
	{
			"value": "LT  BLUE",
			"label": "LT  BLUE"
	},
	{
			"value": "LT BLUE",
			"label": "LT BLUE"
	},
	{
			"value": "LT. BLUE",
			"label": "LT. BLUE"
	},
	{
			"value": "LT BROWN",
			"label": "LT BROWN"
	},
	{
			"value": "LT. BROWN",
			"label": "LT. BROWN"
	},
	{
			"value": "LT CORAL",
			"label": "LT CORAL"
	},
	{
			"value": "LT GRAY",
			"label": "LT GRAY"
	},
	{
			"value": "LT GREEN",
			"label": "LT GREEN"
	},
	{
			"value": "LT. GREEN",
			"label": "LT. GREEN"
	},
	{
			"value": "LT GREEN/WHITE",
			"label": "LT GREEN/WHITE"
	},
	{
			"value": "LT GREY",
			"label": "LT GREY"
	},
	{
			"value": "LT.GREY HEATHER",
			"label": "LT.GREY HEATHER"
	},
	{
			"value": "LT ORANGE",
			"label": "LT ORANGE"
	},
	{
			"value": "LT PINK",
			"label": "LT PINK"
	},
	{
			"value": "LT. PINK",
			"label": "LT. PINK"
	},
	{
			"value": "LT PINK/GOLD",
			"label": "LT PINK/GOLD"
	},
	{
			"value": "LT PURPLE",
			"label": "LT PURPLE"
	},
	{
			"value": "LT ROYAL",
			"label": "LT ROYAL"
	},
	{
			"value": "LT TEAL",
			"label": "LT TEAL"
	},
	{
			"value": "LT TURQ",
			"label": "LT TURQ"
	},
	{
			"value": "LT YELLOW",
			"label": "LT YELLOW"
	},
	{
			"value": "LUE",
			"label": "LUE"
	},
	{
			"value": "LUNA",
			"label": "LUNA"
	},
	{
			"value": "LUNAR ROCK",
			"label": "LUNAR ROCK"
	},
	{
			"value": "LUNCH NAP",
			"label": "LUNCH NAP"
	},
	{
			"value": "LUNCH PLATE",
			"label": "LUNCH PLATE"
	},
	{
			"value": "LVS/CATTAIL/PINECONE",
			"label": "LVS/CATTAIL/PINECONE"
	},
	{
			"value": "LVS/FRUIT/BRY",
			"label": "LVS/FRUIT/BRY"
	},
	{
			"value": "M1 (10-12-14-16-18)",
			"label": "M1 (10-12-14-16-18)"
	},
	{
			"value": "MACADAMIA",
			"label": "MACADAMIA"
	},
	{
			"value": "MADERIA",
			"label": "MADERIA"
	},
	{
			"value": "MAGENTA",
			"label": "MAGENTA"
	},
	{
			"value": "MAGNOLIA",
			"label": "MAGNOLIA"
	},
	{
			"value": "MAHAGONY/GLDN/RED &",
			"label": "MAHAGONY/GLDN/RED &"
	},
	{
			"value": "MAHOGA",
			"label": "MAHOGA"
	},
	{
			"value": "MAHOGANY",
			"label": "MAHOGANY"
	},
	{
			"value": "MAILBOX",
			"label": "MAILBOX"
	},
	{
			"value": "MAIN CURATION",
			"label": "MAIN CURATION"
	},
	{
			"value": "MAIZE",
			"label": "MAIZE"
	},
	{
			"value": "MALLARD",
			"label": "MALLARD"
	},
	{
			"value": "MANDARIN",
			"label": "MANDARIN"
	},
	{
			"value": "MANGO",
			"label": "MANGO"
	},
	{
			"value": "MANSION",
			"label": "MANSION"
	},
	{
			"value": "MAPLE",
			"label": "MAPLE"
	},
	{
			"value": "MAR",
			"label": "MAR"
	},
	{
			"value": "MARBLE",
			"label": "MARBLE"
	},
	{
			"value": "MARDI GRAS",
			"label": "MARDI GRAS"
	},
	{
			"value": "MARIGOLD",
			"label": "MARIGOLD"
	},
	{
			"value": "MARINARA",
			"label": "MARINARA"
	},
	{
			"value": "MARINE",
			"label": "MARINE"
	},
	{
			"value": "MARINE BLUE",
			"label": "MARINE BLUE"
	},
	{
			"value": "MARITIME",
			"label": "MARITIME"
	},
	{
			"value": "MARITIME GREEN",
			"label": "MARITIME GREEN"
	},
	{
			"value": "MAROON",
			"label": "MAROON"
	},
	{
			"value": "MAROON MIST",
			"label": "MAROON MIST"
	},
	{
			"value": "MARSH",
			"label": "MARSH"
	},
	{
			"value": "MARSHMALLOW",
			"label": "MARSHMALLOW"
	},
	{
			"value": "MARSHMELLOW",
			"label": "MARSHMELLOW"
	},
	{
			"value": "MATS AND FLAGS 1",
			"label": "MATS AND FLAGS 1"
	},
	{
			"value": "MATS AND FLAGS 2",
			"label": "MATS AND FLAGS 2"
	},
	{
			"value": "MATS & FLAGS",
			"label": "MATS & FLAGS"
	},
	{
			"value": "MAUNTAINSIDE",
			"label": "MAUNTAINSIDE"
	},
	{
			"value": "MAUVE",
			"label": "MAUVE"
	},
	{
			"value": "MAUVE WOOD",
			"label": "MAUVE WOOD"
	},
	{
			"value": "MAY",
			"label": "MAY"
	},
	{
			"value": "MAZARINE BLUE",
			"label": "MAZARINE BLUE"
	},
	{
			"value": "MD BLUE",
			"label": "MD BLUE"
	},
	{
			"value": "MD GREEN",
			"label": "MD GREEN"
	},
	{
			"value": "MD PINK",
			"label": "MD PINK"
	},
	{
			"value": "MD PURPLE",
			"label": "MD PURPLE"
	},
	{
			"value": "MEADOW",
			"label": "MEADOW"
	},
	{
			"value": "MED BLUE",
			"label": "MED BLUE"
	},
	{
			"value": "MED CORAL",
			"label": "MED CORAL"
	},
	{
			"value": "MED GRAY",
			"label": "MED GRAY"
	},
	{
			"value": "MED GREEN",
			"label": "MED GREEN"
	},
	{
			"value": "MED GREY HEATHER",
			"label": "MED GREY HEATHER"
	},
	{
			"value": "MEDIEVAL",
			"label": "MEDIEVAL"
	},
	{
			"value": "MEDIEVAL BLUE",
			"label": "MEDIEVAL BLUE"
	},
	{
			"value": "MEDITERANEAN CHOCOLA",
			"label": "MEDITERANEAN CHOCOLA"
	},
	{
			"value": "MEDIUM",
			"label": "MEDIUM"
	},
	{
			"value": "MEDIUM BEIGE",
			"label": "MEDIUM BEIGE"
	},
	{
			"value": "MEDIUM BLUE",
			"label": "MEDIUM BLUE"
	},
	{
			"value": "MED ORANGE",
			"label": "MED ORANGE"
	},
	{
			"value": "MED PINK",
			"label": "MED PINK"
	},
	{
			"value": "MED PURPLE",
			"label": "MED PURPLE"
	},
	{
			"value": "MED RED",
			"label": "MED RED"
	},
	{
			"value": "MED TEAL",
			"label": "MED TEAL"
	},
	{
			"value": "MED YELLOW",
			"label": "MED YELLOW"
	},
	{
			"value": "MELBA",
			"label": "MELBA"
	},
	{
			"value": "MELON",
			"label": "MELON"
	},
	{
			"value": "MELTING CHOCOLATE",
			"label": "MELTING CHOCOLATE"
	},
	{
			"value": "MELTING POT",
			"label": "MELTING POT"
	},
	{
			"value": "MEN",
			"label": "MEN"
	},
	{
			"value": "MEN / BOY",
			"label": "MEN / BOY"
	},
	{
			"value": "MEN/BOY",
			"label": "MEN/BOY"
	},
	{
			"value": "MERCHANDISED IN HOUS",
			"label": "MERCHANDISED IN HOUS"
	},
	{
			"value": "MERLOT",
			"label": "MERLOT"
	},
	{
			"value": "MERLOT-AZURE",
			"label": "MERLOT-AZURE"
	},
	{
			"value": "MERMAID",
			"label": "MERMAID"
	},
	{
			"value": "METAL",
			"label": "METAL"
	},
	{
			"value": "METALLIC",
			"label": "METALLIC"
	},
	{
			"value": "METALLIC-GOLD",
			"label": "METALLIC-GOLD"
	},
	{
			"value": "METALLIC MULTI",
			"label": "METALLIC MULTI"
	},
	{
			"value": "METALLIC-PEARL",
			"label": "METALLIC-PEARL"
	},
	{
			"value": "METALLIC-SILVER",
			"label": "METALLIC-SILVER"
	},
	{
			"value": "MICKEY",
			"label": "MICKEY"
	},
	{
			"value": "MICRO CHIP",
			"label": "MICRO CHIP"
	},
	{
			"value": "MICRO CHIP GREY",
			"label": "MICRO CHIP GREY"
	},
	{
			"value": "MIDAS",
			"label": "MIDAS"
	},
	{
			"value": "MIDNIGHT",
			"label": "MIDNIGHT"
	},
	{
			"value": "MIDNIGHT NAVY",
			"label": "MIDNIGHT NAVY"
	},
	{
			"value": "MILITARY GREEN",
			"label": "MILITARY GREEN"
	},
	{
			"value": "MIMOSA",
			"label": "MIMOSA"
	},
	{
			"value": "MINERAL",
			"label": "MINERAL"
	},
	{
			"value": "MINERAL GOLD",
			"label": "MINERAL GOLD"
	},
	{
			"value": "MINERAL YELLOW",
			"label": "MINERAL YELLOW"
	},
	{
			"value": "MINI",
			"label": "MINI"
	},
	{
			"value": "MINIATURE BLUE",
			"label": "MINIATURE BLUE"
	},
	{
			"value": "MINI LOAF",
			"label": "MINI LOAF"
	},
	{
			"value": "MINK",
			"label": "MINK"
	},
	{
			"value": "MINT",
			"label": "MINT"
	},
	{
			"value": "MINT GREEN",
			"label": "MINT GREEN"
	},
	{
			"value": "MINT JULEP",
			"label": "MINT JULEP"
	},
	{
			"value": "MINT JULIP",
			"label": "MINT JULIP"
	},
	{
			"value": "MIRROR",
			"label": "MIRROR"
	},
	{
			"value": "MIRROR GLAZE",
			"label": "MIRROR GLAZE"
	},
	{
			"value": "MISC",
			"label": "MISC"
	},
	{
			"value": "MISSES",
			"label": "MISSES"
	},
	{
			"value": "MISSES/ PLUS",
			"label": "MISSES/ PLUS"
	},
	{
			"value": "MISSES / PLUS SIZE",
			"label": "MISSES / PLUS SIZE"
	},
	{
			"value": "MIST",
			"label": "MIST"
	},
	{
			"value": "MISTY SPRUCE",
			"label": "MISTY SPRUCE"
	},
	{
			"value": "MIX",
			"label": "MIX"
	},
	{
			"value": "MIXED",
			"label": "MIXED"
	},
	{
			"value": "MIXED METAL",
			"label": "MIXED METAL"
	},
	{
			"value": "MIXES",
			"label": "MIXES"
	},
	{
			"value": "MLT",
			"label": "MLT"
	},
	{
			"value": "MLTYGREEN",
			"label": "MLTYGREEN"
	},
	{
			"value": "MOCHA",
			"label": "MOCHA"
	},
	{
			"value": "MODERN",
			"label": "MODERN"
	},
	{
			"value": "MODERN FLORAL",
			"label": "MODERN FLORAL"
	},
	{
			"value": "MOLASSES",
			"label": "MOLASSES"
	},
	{
			"value": "MOLDS",
			"label": "MOLDS"
	},
	{
			"value": "MOLTEN LAVA",
			"label": "MOLTEN LAVA"
	},
	{
			"value": "MOONGLOW",
			"label": "MOONGLOW"
	},
	{
			"value": "MOONLIGHT BLUE",
			"label": "MOONLIGHT BLUE"
	},
	{
			"value": "MOONLIGHT JADE",
			"label": "MOONLIGHT JADE"
	},
	{
			"value": "MOOSE",
			"label": "MOOSE"
	},
	{
			"value": "MOQ 3500",
			"label": "MOQ 3500"
	},
	{
			"value": "MOQ 4000",
			"label": "MOQ 4000"
	},
	{
			"value": "MOSAIC",
			"label": "MOSAIC"
	},
	{
			"value": "MOSS",
			"label": "MOSS"
	},
	{
			"value": "MOSS GREEN",
			"label": "MOSS GREEN"
	},
	{
			"value": "MOUNTAIN",
			"label": "MOUNTAIN"
	},
	{
			"value": "MOUSE",
			"label": "MOUSE"
	},
	{
			"value": "MTL30",
			"label": "MTL30"
	},
	{
			"value": "MUFFIN",
			"label": "MUFFIN"
	},
	{
			"value": "MULBERRY",
			"label": "MULBERRY"
	},
	{
			"value": "MULIT",
			"label": "MULIT"
	},
	{
			"value": "MULTA",
			"label": "MULTA"
	},
	{
			"value": "MULTI",
			"label": "MULTI"
	},
	{
			"value": "MULTI-BABY BLUE HUES",
			"label": "MULTI-BABY BLUE HUES"
	},
	{
			"value": "MULTI-BABY BOY HUES",
			"label": "MULTI-BABY BOY HUES"
	},
	{
			"value": "MULTI-BABY GIRL HUES",
			"label": "MULTI-BABY GIRL HUES"
	},
	{
			"value": "MULTI-BLACK/RED",
			"label": "MULTI-BLACK/RED"
	},
	{
			"value": "MULTI-BLACK/WHITE",
			"label": "MULTI-BLACK/WHITE"
	},
	{
			"value": "MULTI-BLACK/YELLOW",
			"label": "MULTI-BLACK/YELLOW"
	},
	{
			"value": "MULTI-BLUE/GREYS",
			"label": "MULTI-BLUE/GREYS"
	},
	{
			"value": "MULTI-BLUE HUES",
			"label": "MULTI-BLUE HUES"
	},
	{
			"value": "MULTI/BLUES",
			"label": "MULTI/BLUES"
	},
	{
			"value": "MULTI-BRIGHTS",
			"label": "MULTI-BRIGHTS"
	},
	{
			"value": "MULTI-BROWN HUES",
			"label": "MULTI-BROWN HUES"
	},
	{
			"value": "MULTI-BROWNS",
			"label": "MULTI-BROWNS"
	},
	{
			"value": "MULTI-BROWNS/TANS",
			"label": "MULTI-BROWNS/TANS"
	},
	{
			"value": "MULTI-BROWN/TAN HUES",
			"label": "MULTI-BROWN/TAN HUES"
	},
	{
			"value": "MULTI COLOR",
			"label": "MULTI COLOR"
	},
	{
			"value": "MULTICOLOR",
			"label": "MULTICOLOR"
	},
	{
			"value": "MULTICOLORED",
			"label": "MULTICOLORED"
	},
	{
			"value": "MULTI-COUNTRY COLOR",
			"label": "MULTI-COUNTRY COLOR"
	},
	{
			"value": "MULTI-COUNTRY COLORS",
			"label": "MULTI-COUNTRY COLORS"
	},
	{
			"value": "MULTI-DESERT HUES",
			"label": "MULTI-DESERT HUES"
	},
	{
			"value": "MULTI-FALL COLORS",
			"label": "MULTI-FALL COLORS"
	},
	{
			"value": "MULTI GLITTER",
			"label": "MULTI GLITTER"
	},
	{
			"value": "MULTI-GREY HUES",
			"label": "MULTI-GREY HUES"
	},
	{
			"value": "MULTII",
			"label": "MULTII"
	},
	{
			"value": "MULTI INF INK",
			"label": "MULTI INF INK"
	},
	{
			"value": "MULTI-IVY/GREEN HUES",
			"label": "MULTI-IVY/GREEN HUES"
	},
	{
			"value": "MULTI-NEUTRALS",
			"label": "MULTI-NEUTRALS"
	},
	{
			"value": "MULTI-OCEAN HUES",
			"label": "MULTI-OCEAN HUES"
	},
	{
			"value": "MULTI-PASTEL HUES",
			"label": "MULTI-PASTEL HUES"
	},
	{
			"value": "MULTI-PASTELS",
			"label": "MULTI-PASTELS"
	},
	{
			"value": "MULTI-PINK/GREYS",
			"label": "MULTI-PINK/GREYS"
	},
	{
			"value": "MULTI-PINK HUES",
			"label": "MULTI-PINK HUES"
	},
	{
			"value": "MULTI-PINK/PURPLES",
			"label": "MULTI-PINK/PURPLES"
	},
	{
			"value": "MULTI-PINKS",
			"label": "MULTI-PINKS"
	},
	{
			"value": "MULTI-PINKS/GREYS",
			"label": "MULTI-PINKS/GREYS"
	},
	{
			"value": "MULTI-PINKS/PURPLES",
			"label": "MULTI-PINKS/PURPLES"
	},
	{
			"value": "MULTI-PURPLE HUES",
			"label": "MULTI-PURPLE HUES"
	},
	{
			"value": "MULTI-REDDISH BROWN",
			"label": "MULTI-REDDISH BROWN"
	},
	{
			"value": "MULTI-RED/GREEN",
			"label": "MULTI-RED/GREEN"
	},
	{
			"value": "MULTI-RED HUES",
			"label": "MULTI-RED HUES"
	},
	{
			"value": "MULTI-RED/WHITE/PINK",
			"label": "MULTI-RED/WHITE/PINK"
	},
	{
			"value": "MULTI SAMPLER",
			"label": "MULTI SAMPLER"
	},
	{
			"value": "MULTI-SAND COLORS",
			"label": "MULTI-SAND COLORS"
	},
	{
			"value": "MULTI TRANSLUCENT",
			"label": "MULTI TRANSLUCENT"
	},
	{
			"value": "MULTI-TURQUOISE HUES",
			"label": "MULTI-TURQUOISE HUES"
	},
	{
			"value": "MULTI/YELLOW",
			"label": "MULTI/YELLOW"
	},
	{
			"value": "MULTI-YELLOW/BLACK",
			"label": "MULTI-YELLOW/BLACK"
	},
	{
			"value": "MULTU",
			"label": "MULTU"
	},
	{
			"value": "MUM",
			"label": "MUM"
	},
	{
			"value": "MUSHROOM",
			"label": "MUSHROOM"
	},
	{
			"value": "MUSHROON",
			"label": "MUSHROON"
	},
	{
			"value": "MUSLIN",
			"label": "MUSLIN"
	},
	{
			"value": "MUST",
			"label": "MUST"
	},
	{
			"value": "MUSTACHE",
			"label": "MUSTACHE"
	},
	{
			"value": "MUSTARD",
			"label": "MUSTARD"
	},
	{
			"value": "MUTI",
			"label": "MUTI"
	},
	{
			"value": "MUTLI",
			"label": "MUTLI"
	},
	{
			"value": "MXD",
			"label": "MXD"
	},
	{
			"value": "MYSTIC PURPLE",
			"label": "MYSTIC PURPLE"
	},
	{
			"value": "N/A",
			"label": "N/A"
	},
	{
			"value": "NAT BLUE",
			"label": "NAT BLUE"
	},
	{
			"value": "NATPANELACCESSORIES",
			"label": "NATPANELACCESSORIES"
	},
	{
			"value": "NATRUAL",
			"label": "NATRUAL"
	},
	{
			"value": "NATURAL",
			"label": "NATURAL"
	},
	{
			"value": "NATURAL/BLACK",
			"label": "NATURAL/BLACK"
	},
	{
			"value": "NATURAL/GLDN",
			"label": "NATURAL/GLDN"
	},
	{
			"value": "NATURAL/GREY",
			"label": "NATURAL/GREY"
	},
	{
			"value": "NATURAL/MAHAGONY",
			"label": "NATURAL/MAHAGONY"
	},
	{
			"value": "NATURALPICKS",
			"label": "NATURALPICKS"
	},
	{
			"value": "NATURAL  WASH",
			"label": "NATURAL  WASH"
	},
	{
			"value": "NATURAL WASH",
			"label": "NATURAL WASH"
	},
	{
			"value": "NATURAL WIILOW",
			"label": "NATURAL WIILOW"
	},
	{
			"value": "NAUTICAL",
			"label": "NAUTICAL"
	},
	{
			"value": "NAUTICAL NOEL",
			"label": "NAUTICAL NOEL"
	},
	{
			"value": "NAUTICAL/WILDERNESS",
			"label": "NAUTICAL/WILDERNESS"
	},
	{
			"value": "NAUTRAL",
			"label": "NAUTRAL"
	},
	{
			"value": "NAVY",
			"label": "NAVY"
	},
	{
			"value": "NAVY BLUE",
			"label": "NAVY BLUE"
	},
	{
			"value": "NAVY FLORAL",
			"label": "NAVY FLORAL"
	},
	{
			"value": "NAVY MIST",
			"label": "NAVY MIST"
	},
	{
			"value": "NAVY SILO",
			"label": "NAVY SILO"
	},
	{
			"value": "NAVYSP",
			"label": "NAVYSP"
	},
	{
			"value": "NECTAR",
			"label": "NECTAR"
	},
	{
			"value": "NEEDS CHANGED TO PIN",
			"label": "NEEDS CHANGED TO PIN"
	},
	{
			"value": "NEON",
			"label": "NEON"
	},
	{
			"value": "NEON BLUE",
			"label": "NEON BLUE"
	},
	{
			"value": "NEON GREEN",
			"label": "NEON GREEN"
	},
	{
			"value": "NEON ORANGE",
			"label": "NEON ORANGE"
	},
	{
			"value": "NEON PINK",
			"label": "NEON PINK"
	},
	{
			"value": "NEON RED",
			"label": "NEON RED"
	},
	{
			"value": "NEON YELLO",
			"label": "NEON YELLO"
	},
	{
			"value": "NEON YELLOW",
			"label": "NEON YELLOW"
	},
	{
			"value": "NEPTUNE",
			"label": "NEPTUNE"
	},
	{
			"value": "NEST",
			"label": "NEST"
	},
	{
			"value": "NESTED",
			"label": "NESTED"
	},
	{
			"value": "NEUTRAL",
			"label": "NEUTRAL"
	},
	{
			"value": "NEUTRAL MIX",
			"label": "NEUTRAL MIX"
	},
	{
			"value": "NEUTRAL MULTI",
			"label": "NEUTRAL MULTI"
	},
	{
			"value": "NEUTRAL PK",
			"label": "NEUTRAL PK"
	},
	{
			"value": "NEUTRALS",
			"label": "NEUTRALS"
	},
	{
			"value": "NEW",
			"label": "NEW"
	},
	{
			"value": "NICKEL",
			"label": "NICKEL"
	},
	{
			"value": "NICKLE",
			"label": "NICKLE"
	},
	{
			"value": "NIGHT",
			"label": "NIGHT"
	},
	{
			"value": "NIGHTFALL",
			"label": "NIGHTFALL"
	},
	{
			"value": "NILE",
			"label": "NILE"
	},
	{
			"value": "NILE GREEN",
			"label": "NILE GREEN"
	},
	{
			"value": "NL13",
			"label": "NL13"
	},
	{
			"value": "NL BIN",
			"label": "NL BIN"
	},
	{
			"value": "NL DEC BX",
			"label": "NL DEC BX"
	},
	{
			"value": "NL FF",
			"label": "NL FF"
	},
	{
			"value": "NL FOS",
			"label": "NL FOS"
	},
	{
			"value": "NL GARLAND",
			"label": "NL GARLAND"
	},
	{
			"value": "NL HEADER",
			"label": "NL HEADER"
	},
	{
			"value": "NL INLINE BASKETS",
			"label": "NL INLINE BASKETS"
	},
	{
			"value": "NL INLINE ENTERTAIN",
			"label": "NL INLINE ENTERTAIN"
	},
	{
			"value": "NL INLINE TEXTILE",
			"label": "NL INLINE TEXTILE"
	},
	{
			"value": "NL MAT/FLAG",
			"label": "NL MAT/FLAG"
	},
	{
			"value": "NL OSO",
			"label": "NL OSO"
	},
	{
			"value": "NL PET",
			"label": "NL PET"
	},
	{
			"value": "NL QUAD",
			"label": "NL QUAD"
	},
	{
			"value": "NL SHATTER",
			"label": "NL SHATTER"
	},
	{
			"value": "NL SQUARE",
			"label": "NL SQUARE"
	},
	{
			"value": "NL STOCKING/SKIRT",
			"label": "NL STOCKING/SKIRT"
	},
	{
			"value": "NL TEXTILE",
			"label": "NL TEXTILE"
	},
	{
			"value": "NN OSO",
			"label": "NN OSO"
	},
	{
			"value": "NN QUAD",
			"label": "NN QUAD"
	},
	{
			"value": "NOIR",
			"label": "NOIR"
	},
	{
			"value": "NONPAREILS",
			"label": "NONPAREILS"
	},
	{
			"value": "NORMANDY",
			"label": "NORMANDY"
	},
	{
			"value": "NOT ASSIGNED",
			"label": "NOT ASSIGNED"
	},
	{
			"value": "NOUGAT",
			"label": "NOUGAT"
	},
	{
			"value": "NOV",
			"label": "NOV"
	},
	{
			"value": "NOVELLE PEACH",
			"label": "NOVELLE PEACH"
	},
	{
			"value": "NOVELL PEACH",
			"label": "NOVELL PEACH"
	},
	{
			"value": "NOVELTY",
			"label": "NOVELTY"
	},
	{
			"value": "NO WORDS",
			"label": "NO WORDS"
	},
	{
			"value": "NUDE",
			"label": "NUDE"
	},
	{
			"value": "NUGGET",
			"label": "NUGGET"
	},
	{
			"value": "NUGREY",
			"label": "NUGREY"
	},
	{
			"value": "NURSERY",
			"label": "NURSERY"
	},
	{
			"value": "NUTCRACK ANGEL SANTA",
			"label": "NUTCRACK ANGEL SANTA"
	},
	{
			"value": "NUTMEG",
			"label": "NUTMEG"
	},
	{
			"value": "NVY",
			"label": "NVY"
	},
	{
			"value": "OAK",
			"label": "OAK"
	},
	{
			"value": "OASIS",
			"label": "OASIS"
	},
	{
			"value": "OAT",
			"label": "OAT"
	},
	{
			"value": "OATMEAL",
			"label": "OATMEAL"
	},
	{
			"value": "OATMEAL HEATHER",
			"label": "OATMEAL HEATHER"
	},
	{
			"value": "OBLONG",
			"label": "OBLONG"
	},
	{
			"value": "OCEAN",
			"label": "OCEAN"
	},
	{
			"value": "OCEAN BLUE",
			"label": "OCEAN BLUE"
	},
	{
			"value": "OCEAN DEPTHS",
			"label": "OCEAN DEPTHS"
	},
	{
			"value": "OCHRE",
			"label": "OCHRE"
	},
	{
			"value": "OCT",
			"label": "OCT"
	},
	{
			"value": "OFF WHITE",
			"label": "OFF WHITE"
	},
	{
			"value": "OFF-WHITE",
			"label": "OFF-WHITE"
	},
	{
			"value": "OFFWHITE",
			"label": "OFFWHITE"
	},
	{
			"value": "OIL SLICK",
			"label": "OIL SLICK"
	},
	{
			"value": "OLD GOLD",
			"label": "OLD GOLD"
	},
	{
			"value": "OLFA KNIFE",
			"label": "OLFA KNIFE"
	},
	{
			"value": "OLIVE",
			"label": "OLIVE"
	},
	{
			"value": "OLIVE DRAB",
			"label": "OLIVE DRAB"
	},
	{
			"value": "OLIVEGROVE",
			"label": "OLIVEGROVE"
	},
	{
			"value": "OLIVEWOOD",
			"label": "OLIVEWOOD"
	},
	{
			"value": "OMBRE",
			"label": "OMBRE"
	},
	{
			"value": "OMPHALODES BLUE",
			"label": "OMPHALODES BLUE"
	},
	{
			"value": "ONE SIZE CRAFT",
			"label": "ONE SIZE CRAFT"
	},
	{
			"value": "ONLINE",
			"label": "ONLINE"
	},
	{
			"value": "ONLINE ONLY",
			"label": "ONLINE ONLY"
	},
	{
			"value": "ONYX",
			"label": "ONYX"
	},
	{
			"value": "ONYX LILAC",
			"label": "ONYX LILAC"
	},
	{
			"value": "ONYXS",
			"label": "ONYXS"
	},
	{
			"value": "ONYZ",
			"label": "ONYZ"
	},
	{
			"value": "OOL",
			"label": "OOL"
	},
	{
			"value": "OOL HEADER",
			"label": "OOL HEADER"
	},
	{
			"value": "OPAL",
			"label": "OPAL"
	},
	{
			"value": "OPAQUE",
			"label": "OPAQUE"
	},
	{
			"value": "OPEN ROAD",
			"label": "OPEN ROAD"
	},
	{
			"value": "OPTIC",
			"label": "OPTIC"
	},
	{
			"value": "ORANGE",
			"label": "ORANGE"
	},
	{
			"value": "ORANGE'",
			"label": "ORANGE'"
	},
	{
			"value": "ORANGE/BLACK",
			"label": "ORANGE/BLACK"
	},
	{
			"value": "ORANGE/BLK",
			"label": "ORANGE/BLK"
	},
	{
			"value": "ORANGE CARD",
			"label": "ORANGE CARD"
	},
	{
			"value": "ORANGE/CORAL",
			"label": "ORANGE/CORAL"
	},
	{
			"value": "ORANGE DK",
			"label": "ORANGE DK"
	},
	{
			"value": "ORANGE LT",
			"label": "ORANGE LT"
	},
	{
			"value": "ORANGE MATTE",
			"label": "ORANGE MATTE"
	},
	{
			"value": "ORANGE-WHITE",
			"label": "ORANGE-WHITE"
	},
	{
			"value": "ORCHARD",
			"label": "ORCHARD"
	},
	{
			"value": "ORCHID",
			"label": "ORCHID"
	},
	{
			"value": "ORCHID MIST",
			"label": "ORCHID MIST"
	},
	{
			"value": "ORCHID PINK",
			"label": "ORCHID PINK"
	},
	{
			"value": "OREGANO",
			"label": "OREGANO"
	},
	{
			"value": "ORG",
			"label": "ORG"
	},
	{
			"value": "ORGANGE",
			"label": "ORGANGE"
	},
	{
			"value": "ORG GRN RED",
			"label": "ORG GRN RED"
	},
	{
			"value": "ORNG",
			"label": "ORNG"
	},
	{
			"value": "ORN/GRN",
			"label": "ORN/GRN"
	},
	{
			"value": "ORO",
			"label": "ORO"
	},
	{
			"value": "OS (ONE SIZE)",
			"label": "OS (ONE SIZE)"
	},
	{
			"value": "OUTDOOR",
			"label": "OUTDOOR"
	},
	{
			"value": "OUTDOOR TEXTILES AND",
			"label": "OUTDOOR TEXTILES AND"
	},
	{
			"value": "OUTDOOR TEXTILES & E",
			"label": "OUTDOOR TEXTILES & E"
	},
	{
			"value": "OUT OF LINE",
			"label": "OUT OF LINE"
	},
	{
			"value": "OVERSIZED",
			"label": "OVERSIZED"
	},
	{
			"value": "OWL",
			"label": "OWL"
	},
	{
			"value": "OWLS",
			"label": "OWLS"
	},
	{
			"value": "OXFORD GREY",
			"label": "OXFORD GREY"
	},
	{
			"value": "OYSTER",
			"label": "OYSTER"
	},
	{
			"value": "P5 (12-14-16-18-20)",
			"label": "P5 (12-14-16-18-20)"
	},
	{
			"value": "PACIFIC",
			"label": "PACIFIC"
	},
	{
			"value": "PACIFIC BLUE",
			"label": "PACIFIC BLUE"
	},
	{
			"value": "PACKET PROTECTOR",
			"label": "PACKET PROTECTOR"
	},
	{
			"value": "PAINT",
			"label": "PAINT"
	},
	{
			"value": "PAISLEY",
			"label": "PAISLEY"
	},
	{
			"value": "PAISLEY A",
			"label": "PAISLEY A"
	},
	{
			"value": "PAISLEY B",
			"label": "PAISLEY B"
	},
	{
			"value": "PALE ALE",
			"label": "PALE ALE"
	},
	{
			"value": "PALE BLUE",
			"label": "PALE BLUE"
	},
	{
			"value": "PALE GOLD",
			"label": "PALE GOLD"
	},
	{
			"value": "PALEGOLD",
			"label": "PALEGOLD"
	},
	{
			"value": "PALE PEACH",
			"label": "PALE PEACH"
	},
	{
			"value": "PALE YELLOW",
			"label": "PALE YELLOW"
	},
	{
			"value": "PALM",
			"label": "PALM"
	},
	{
			"value": "PALM GREEN",
			"label": "PALM GREEN"
	},
	{
			"value": "PANEL",
			"label": "PANEL"
	},
	{
			"value": "PANEL 1",
			"label": "PANEL 1"
	},
	{
			"value": "PANEL 13",
			"label": "PANEL 13"
	},
	{
			"value": "PANEL 14",
			"label": "PANEL 14"
	},
	{
			"value": "PANEL 2",
			"label": "PANEL 2"
	},
	{
			"value": "PANEL 3",
			"label": "PANEL 3"
	},
	{
			"value": "PANEL 4",
			"label": "PANEL 4"
	},
	{
			"value": "PANEL 5",
			"label": "PANEL 5"
	},
	{
			"value": "PANEL 6",
			"label": "PANEL 6"
	},
	{
			"value": "PANEL 7",
			"label": "PANEL 7"
	},
	{
			"value": "PANEL A",
			"label": "PANEL A"
	},
	{
			"value": "PANEL B",
			"label": "PANEL B"
	},
	{
			"value": "PANEL C",
			"label": "PANEL C"
	},
	{
			"value": "PANTHER",
			"label": "PANTHER"
	},
	{
			"value": "PAPAYA",
			"label": "PAPAYA"
	},
	{
			"value": "PAPER",
			"label": "PAPER"
	},
	{
			"value": "PAPER CUPS",
			"label": "PAPER CUPS"
	},
	{
			"value": "PAPER MACHE",
			"label": "PAPER MACHE"
	},
	{
			"value": "PAPER PEVA PANEL",
			"label": "PAPER PEVA PANEL"
	},
	{
			"value": "PAPER WHITE",
			"label": "PAPER WHITE"
	},
	{
			"value": "PAPRIKA",
			"label": "PAPRIKA"
	},
	{
			"value": "PAPYRUS",
			"label": "PAPYRUS"
	},
	{
			"value": "PARADISE SHINE",
			"label": "PARADISE SHINE"
	},
	{
			"value": "PARAKEET",
			"label": "PARAKEET"
	},
	{
			"value": "PARCHEMENT",
			"label": "PARCHEMENT"
	},
	{
			"value": "PARCHM",
			"label": "PARCHM"
	},
	{
			"value": "PARCHMENT",
			"label": "PARCHMENT"
	},
	{
			"value": "PARFAIT",
			"label": "PARFAIT"
	},
	{
			"value": "PARFAIT PINK",
			"label": "PARFAIT PINK"
	},
	{
			"value": "PARROT",
			"label": "PARROT"
	},
	{
			"value": "PARTY",
			"label": "PARTY"
	},
	{
			"value": "PARTY HAT",
			"label": "PARTY HAT"
	},
	{
			"value": "PASHMINA",
			"label": "PASHMINA"
	},
	{
			"value": "PASSION",
			"label": "PASSION"
	},
	{
			"value": "PASTEL",
			"label": "PASTEL"
	},
	{
			"value": "PASTEL PINK",
			"label": "PASTEL PINK"
	},
	{
			"value": "PASTELS",
			"label": "PASTELS"
	},
	{
			"value": "PASTEL YELLOW",
			"label": "PASTEL YELLOW"
	},
	{
			"value": "PATINA",
			"label": "PATINA"
	},
	{
			"value": "PATRIOT",
			"label": "PATRIOT"
	},
	{
			"value": "PATRIOT BLUE",
			"label": "PATRIOT BLUE"
	},
	{
			"value": "PATRIOTIC",
			"label": "PATRIOTIC"
	},
	{
			"value": "PATTERN",
			"label": "PATTERN"
	},
	{
			"value": "PAW PATROL",
			"label": "PAW PATROL"
	},
	{
			"value": "PDQ",
			"label": "PDQ"
	},
	{
			"value": "PEACH",
			"label": "PEACH"
	},
	{
			"value": "PEACH BLUSH",
			"label": "PEACH BLUSH"
	},
	{
			"value": "PEACH MELBA",
			"label": "PEACH MELBA"
	},
	{
			"value": "PEACH SKIN",
			"label": "PEACH SKIN"
	},
	{
			"value": "PEACHSKIN",
			"label": "PEACHSKIN"
	},
	{
			"value": "PEACHY KEEN PANEL 1",
			"label": "PEACHY KEEN PANEL 1"
	},
	{
			"value": "PEACHY KEEN PANEL 2",
			"label": "PEACHY KEEN PANEL 2"
	},
	{
			"value": "PEACHY PEACH",
			"label": "PEACHY PEACH"
	},
	{
			"value": "PEACOAT",
			"label": "PEACOAT"
	},
	{
			"value": "PEACOCK",
			"label": "PEACOCK"
	},
	{
			"value": "PEACOCK GREEN",
			"label": "PEACOCK GREEN"
	},
	{
			"value": "PEANUT BUTTER",
			"label": "PEANUT BUTTER"
	},
	{
			"value": "PEAR",
			"label": "PEAR"
	},
	{
			"value": "PEARL",
			"label": "PEARL"
	},
	{
			"value": "PEARL DUST",
			"label": "PEARL DUST"
	},
	{
			"value": "PEARL GREY",
			"label": "PEARL GREY"
	},
	{
			"value": "PEARLIZED",
			"label": "PEARLIZED"
	},
	{
			"value": "PEARLIZED SUGAR",
			"label": "PEARLIZED SUGAR"
	},
	{
			"value": "PEARL WHITE",
			"label": "PEARL WHITE"
	},
	{
			"value": "PEAT",
			"label": "PEAT"
	},
	{
			"value": "PECAN",
			"label": "PECAN"
	},
	{
			"value": "PEEPS",
			"label": "PEEPS"
	},
	{
			"value": "PENCIL",
			"label": "PENCIL"
	},
	{
			"value": "PENNY",
			"label": "PENNY"
	},
	{
			"value": "PEONY",
			"label": "PEONY"
	},
	{
			"value": "PEPPER",
			"label": "PEPPER"
	},
	{
			"value": "PEPPERCORN",
			"label": "PEPPERCORN"
	},
	{
			"value": "PERAL",
			"label": "PERAL"
	},
	{
			"value": "PERIDOT",
			"label": "PERIDOT"
	},
	{
			"value": "PERIWINKLE",
			"label": "PERIWINKLE"
	},
	{
			"value": "PERRI",
			"label": "PERRI"
	},
	{
			"value": "PERSIAN",
			"label": "PERSIAN"
	},
	{
			"value": "PERSIAN RED",
			"label": "PERSIAN RED"
	},
	{
			"value": "PERSIMMON",
			"label": "PERSIMMON"
	},
	{
			"value": "PESTO",
			"label": "PESTO"
	},
	{
			"value": "PETAL",
			"label": "PETAL"
	},
	{
			"value": "PETAL PINK",
			"label": "PETAL PINK"
	},
	{
			"value": "PET PANEL 1",
			"label": "PET PANEL 1"
	},
	{
			"value": "PET PANEL 2",
			"label": "PET PANEL 2"
	},
	{
			"value": "PETUNIA PURPLE",
			"label": "PETUNIA PURPLE"
	},
	{
			"value": "PETWER",
			"label": "PETWER"
	},
	{
			"value": "PEWTER",
			"label": "PEWTER"
	},
	{
			"value": "PFD",
			"label": "PFD"
	},
	{
			"value": "PICKS",
			"label": "PICKS"
	},
	{
			"value": "PIE",
			"label": "PIE"
	},
	{
			"value": "PIG",
			"label": "PIG"
	},
	{
			"value": "PILLAR",
			"label": "PILLAR"
	},
	{
			"value": "PILLARS/PEGS",
			"label": "PILLARS/PEGS"
	},
	{
			"value": "PILLERS/PEGS",
			"label": "PILLERS/PEGS"
	},
	{
			"value": "PILLOW",
			"label": "PILLOW"
	},
	{
			"value": "PIMENTO",
			"label": "PIMENTO"
	},
	{
			"value": "PINA",
			"label": "PINA"
	},
	{
			"value": "PINEAPPLE",
			"label": "PINEAPPLE"
	},
	{
			"value": "PINK",
			"label": "PINK"
	},
	{
			"value": "PINK AND GOLD",
			"label": "PINK AND GOLD"
	},
	{
			"value": "PINK/AQUA",
			"label": "PINK/AQUA"
	},
	{
			"value": "PINK/BLUE",
			"label": "PINK/BLUE"
	},
	{
			"value": "PINKBUSHES",
			"label": "PINKBUSHES"
	},
	{
			"value": "PINK CHEVRON",
			"label": "PINK CHEVRON"
	},
	{
			"value": "PINK/CHOCOLATE",
			"label": "PINK/CHOCOLATE"
	},
	{
			"value": "PINK/CREAM",
			"label": "PINK/CREAM"
	},
	{
			"value": "PINK DAMASK",
			"label": "PINK DAMASK"
	},
	{
			"value": "PINK DARK",
			"label": "PINK DARK"
	},
	{
			"value": "PINK  DK",
			"label": "PINK  DK"
	},
	{
			"value": "PINK DK",
			"label": "PINK DK"
	},
	{
			"value": "PINK DOT",
			"label": "PINK DOT"
	},
	{
			"value": "PINK DR",
			"label": "PINK DR"
	},
	{
			"value": "PINK GLITTER",
			"label": "PINK GLITTER"
	},
	{
			"value": "PINK GREEN",
			"label": "PINK GREEN"
	},
	{
			"value": "PINK LIGHT",
			"label": "PINK LIGHT"
	},
	{
			"value": "PINK  LT",
			"label": "PINK  LT"
	},
	{
			"value": "PINK LT",
			"label": "PINK LT"
	},
	{
			"value": "PINK MIST",
			"label": "PINK MIST"
	},
	{
			"value": "PINK PEACOCK",
			"label": "PINK PEACOCK"
	},
	{
			"value": "PINK PEARL",
			"label": "PINK PEARL"
	},
	{
			"value": "PINK POWER PANEL 1",
			"label": "PINK POWER PANEL 1"
	},
	{
			"value": "PINK POWER PANEL 2",
			"label": "PINK POWER PANEL 2"
	},
	{
			"value": "PINK POWER PANEL 3",
			"label": "PINK POWER PANEL 3"
	},
	{
			"value": "PINK/PURPLE",
			"label": "PINK/PURPLE"
	},
	{
			"value": "PINKS/GREYS",
			"label": "PINKS/GREYS"
	},
	{
			"value": "PINKS/NEUTRALS",
			"label": "PINKS/NEUTRALS"
	},
	{
			"value": "PINKS/PURPLES",
			"label": "PINKS/PURPLES"
	},
	{
			"value": "PINK TINT",
			"label": "PINK TINT"
	},
	{
			"value": "PINK TONES",
			"label": "PINK TONES"
	},
	{
			"value": "PINK / WHITE",
			"label": "PINK / WHITE"
	},
	{
			"value": "PINK/WHITE",
			"label": "PINK/WHITE"
	},
	{
			"value": "PINL",
			"label": "PINL"
	},
	{
			"value": "PINNK LT",
			"label": "PINNK LT"
	},
	{
			"value": "PISTACHIO",
			"label": "PISTACHIO"
	},
	{
			"value": "PISTACHIO GANACHE",
			"label": "PISTACHIO GANACHE"
	},
	{
			"value": "PITCH",
			"label": "PITCH"
	},
	{
			"value": "PITCHER",
			"label": "PITCHER"
	},
	{
			"value": "PIXIE DUST",
			"label": "PIXIE DUST"
	},
	{
			"value": "PIZZA",
			"label": "PIZZA"
	},
	{
			"value": "PLACEHOLDER",
			"label": "PLACEHOLDER"
	},
	{
			"value": "PLACE & TIME",
			"label": "PLACE & TIME"
	},
	{
			"value": "PLACID BLU",
			"label": "PLACID BLU"
	},
	{
			"value": "PLACID BLUE",
			"label": "PLACID BLUE"
	},
	{
			"value": "PLAID",
			"label": "PLAID"
	},
	{
			"value": "PLANTATION",
			"label": "PLANTATION"
	},
	{
			"value": "PLASTIC",
			"label": "PLASTIC"
	},
	{
			"value": "PLATE",
			"label": "PLATE"
	},
	{
			"value": "PLATINUM",
			"label": "PLATINUM"
	},
	{
			"value": "PLATTER",
			"label": "PLATTER"
	},
	{
			"value": "PLAYPEN",
			"label": "PLAYPEN"
	},
	{
			"value": "PLUM",
			"label": "PLUM"
	},
	{
			"value": "PLUM KITTEN",
			"label": "PLUM KITTEN"
	},
	{
			"value": "PLUM PURPLE",
			"label": "PLUM PURPLE"
	},
	{
			"value": "PLUMROSE",
			"label": "PLUMROSE"
	},
	{
			"value": "PLUMWINE",
			"label": "PLUMWINE"
	},
	{
			"value": "PLUSH",
			"label": "PLUSH"
	},
	{
			"value": "PLUS SIZE",
			"label": "PLUS SIZE"
	},
	{
			"value": "PNK",
			"label": "PNK"
	},
	{
			"value": "PNK DK",
			"label": "PNK DK"
	},
	{
			"value": "PNK LT",
			"label": "PNK LT"
	},
	{
			"value": "POINSETTIA",
			"label": "POINSETTIA"
	},
	{
			"value": "POLAR",
			"label": "POLAR"
	},
	{
			"value": "POLKA DOT",
			"label": "POLKA DOT"
	},
	{
			"value": "POLKA DOTS",
			"label": "POLKA DOTS"
	},
	{
			"value": "POLO",
			"label": "POLO"
	},
	{
			"value": "POLYRATTAN",
			"label": "POLYRATTAN"
	},
	{
			"value": "POM",
			"label": "POM"
	},
	{
			"value": "POMEGRANATE",
			"label": "POMEGRANATE"
	},
	{
			"value": "POMEGRANITE",
			"label": "POMEGRANITE"
	},
	{
			"value": "POMPEII",
			"label": "POMPEII"
	},
	{
			"value": "PONDEROSA",
			"label": "PONDEROSA"
	},
	{
			"value": "PONGEE",
			"label": "PONGEE"
	},
	{
			"value": "POOL",
			"label": "POOL"
	},
	{
			"value": "POOLSIDE",
			"label": "POOLSIDE"
	},
	{
			"value": "POPCORN",
			"label": "POPCORN"
	},
	{
			"value": "POPPY",
			"label": "POPPY"
	},
	{
			"value": "PORCELAIN",
			"label": "PORCELAIN"
	},
	{
			"value": "PORCH PATIO WS",
			"label": "PORCH PATIO WS"
	},
	{
			"value": "PORCINI",
			"label": "PORCINI"
	},
	{
			"value": "PORT",
			"label": "PORT"
	},
	{
			"value": "PORT ROYALE",
			"label": "PORT ROYALE"
	},
	{
			"value": "POSABLE",
			"label": "POSABLE"
	},
	{
			"value": "POSSIBLE RB PCN",
			"label": "POSSIBLE RB PCN"
	},
	{
			"value": "POTTERY",
			"label": "POTTERY"
	},
	{
			"value": "POTTING SOIL",
			"label": "POTTING SOIL"
	},
	{
			"value": "POUCH",
			"label": "POUCH"
	},
	{
			"value": "POUCH WITH TIPS",
			"label": "POUCH WITH TIPS"
	},
	{
			"value": "POWDER",
			"label": "POWDER"
	},
	{
			"value": "POWDER BLUE",
			"label": "POWDER BLUE"
	},
	{
			"value": "POWDER PINK",
			"label": "POWDER PINK"
	},
	{
			"value": "POWER ARM",
			"label": "POWER ARM"
	},
	{
			"value": "POWER PANEL",
			"label": "POWER PANEL"
	},
	{
			"value": "POWERPANEL",
			"label": "POWERPANEL"
	},
	{
			"value": "PP 1",
			"label": "PP 1"
	},
	{
			"value": "PP1",
			"label": "PP1"
	},
	{
			"value": "PP 10",
			"label": "PP 10"
	},
	{
			"value": "PP10",
			"label": "PP10"
	},
	{
			"value": "PP11",
			"label": "PP11"
	},
	{
			"value": "PP12",
			"label": "PP12"
	},
	{
			"value": "PP1 SA800601",
			"label": "PP1 SA800601"
	},
	{
			"value": "PP 2",
			"label": "PP 2"
	},
	{
			"value": "PP2",
			"label": "PP2"
	},
	{
			"value": "PP 3",
			"label": "PP 3"
	},
	{
			"value": "PP3",
			"label": "PP3"
	},
	{
			"value": "PP3 SA800607",
			"label": "PP3 SA800607"
	},
	{
			"value": "PP 4",
			"label": "PP 4"
	},
	{
			"value": "PP4",
			"label": "PP4"
	},
	{
			"value": "PP4 SA660603",
			"label": "PP4 SA660603"
	},
	{
			"value": "PP4 SA660603 FW12",
			"label": "PP4 SA660603 FW12"
	},
	{
			"value": "PP 5",
			"label": "PP 5"
	},
	{
			"value": "PP5",
			"label": "PP5"
	},
	{
			"value": "PP5 SA800609",
			"label": "PP5 SA800609"
	},
	{
			"value": "PP 6",
			"label": "PP 6"
	},
	{
			"value": "PP6",
			"label": "PP6"
	},
	{
			"value": "PP 7",
			"label": "PP 7"
	},
	{
			"value": "PP7",
			"label": "PP7"
	},
	{
			"value": "PP7 & INLINE (LATE S",
			"label": "PP7 & INLINE (LATE S"
	},
	{
			"value": "PP7 SA810601",
			"label": "PP7 SA810601"
	},
	{
			"value": "PP 8",
			"label": "PP 8"
	},
	{
			"value": "PP8",
			"label": "PP8"
	},
	{
			"value": "PP8 & INLINE (EARLY",
			"label": "PP8 & INLINE (EARLY"
	},
	{
			"value": "PP8 & INLINE (LATE S",
			"label": "PP8 & INLINE (LATE S"
	},
	{
			"value": "PP9",
			"label": "PP9"
	},
	{
			"value": "PP GEL CLING",
			"label": "PP GEL CLING"
	},
	{
			"value": "PP GEL CLINGS",
			"label": "PP GEL CLINGS"
	},
	{
			"value": "PP MUSHROOM",
			"label": "PP MUSHROOM"
	},
	{
			"value": "PP PET EASTER",
			"label": "PP PET EASTER"
	},
	{
			"value": "PP PET VAL",
			"label": "PP PET VAL"
	},
	{
			"value": "PP SANTA HATS",
			"label": "PP SANTA HATS"
	},
	{
			"value": "PP SCENTICIALS",
			"label": "PP SCENTICIALS"
	},
	{
			"value": "PP SEASONAL OUTDOOR",
			"label": "PP SEASONAL OUTDOOR"
	},
	{
			"value": "PP ST PATS",
			"label": "PP ST PATS"
	},
	{
			"value": "PP TOWELS",
			"label": "PP TOWELS"
	},
	{
			"value": "PP VAL",
			"label": "PP VAL"
	},
	{
			"value": "PRALINE",
			"label": "PRALINE"
	},
	{
			"value": "PRIMARY",
			"label": "PRIMARY"
	},
	{
			"value": "PRIMARY PK",
			"label": "PRIMARY PK"
	},
	{
			"value": "PRIMATIVEACCESSORIES",
			"label": "PRIMATIVEACCESSORIES"
	},
	{
			"value": "PRIMATIVEBUSHES",
			"label": "PRIMATIVEBUSHES"
	},
	{
			"value": "PRIMATIVEPICKS",
			"label": "PRIMATIVEPICKS"
	},
	{
			"value": "PRIMAVERA",
			"label": "PRIMAVERA"
	},
	{
			"value": "PRIMCONTAINERS",
			"label": "PRIMCONTAINERS"
	},
	{
			"value": "PRIMITIVE",
			"label": "PRIMITIVE"
	},
	{
			"value": "PRIMITIVEARRANGEMENT",
			"label": "PRIMITIVEARRANGEMENT"
	},
	{
			"value": "PRIMROSE",
			"label": "PRIMROSE"
	},
	{
			"value": "PRIMROSE W IRIDESCE",
			"label": "PRIMROSE W IRIDESCE"
	},
	{
			"value": "PRINCESS",
			"label": "PRINCESS"
	},
	{
			"value": "PRINT",
			"label": "PRINT"
	},
	{
			"value": "PRINT ONLY",
			"label": "PRINT ONLY"
	},
	{
			"value": "PRMITIVE",
			"label": "PRMITIVE"
	},
	{
			"value": "PRNT",
			"label": "PRNT"
	},
	{
			"value": "PROMO WRAP CELLO",
			"label": "PROMO WRAP CELLO"
	},
	{
			"value": "PROMO WRAP FOIL",
			"label": "PROMO WRAP FOIL"
	},
	{
			"value": "PROMO WRAP FUN",
			"label": "PROMO WRAP FUN"
	},
	{
			"value": "PROMO WRAP HEADER",
			"label": "PROMO WRAP HEADER"
	},
	{
			"value": "PROMO WRAP KIDS",
			"label": "PROMO WRAP KIDS"
	},
	{
			"value": "PROMO WRAP KRAFT",
			"label": "PROMO WRAP KRAFT"
	},
	{
			"value": "PROMO WRAP LICENSED",
			"label": "PROMO WRAP LICENSED"
	},
	{
			"value": "PROMO WRAP OTHER",
			"label": "PROMO WRAP OTHER"
	},
	{
			"value": "PROMO WRAP PET",
			"label": "PROMO WRAP PET"
	},
	{
			"value": "PROMO WRAP REVERSABL",
			"label": "PROMO WRAP REVERSABL"
	},
	{
			"value": "PROMO WRAP TAG/OTHER",
			"label": "PROMO WRAP TAG/OTHER"
	},
	{
			"value": "PROMO WRAP THEMED",
			"label": "PROMO WRAP THEMED"
	},
	{
			"value": "PROMO WRAP TRADITION",
			"label": "PROMO WRAP TRADITION"
	},
	{
			"value": "PROMO WREATH TOWER",
			"label": "PROMO WREATH TOWER"
	},
	{
			"value": "PROVENCAL",
			"label": "PROVENCAL"
	},
	{
			"value": "PROVINCIAL",
			"label": "PROVINCIAL"
	},
	{
			"value": "PRUPLE DK",
			"label": "PRUPLE DK"
	},
	{
			"value": "PUFFY POLES",
			"label": "PUFFY POLES"
	},
	{
			"value": "PUMPKIN",
			"label": "PUMPKIN"
	},
	{
			"value": "PUMPKIN & LEAVES",
			"label": "PUMPKIN & LEAVES"
	},
	{
			"value": "PUMPKINS",
			"label": "PUMPKINS"
	},
	{
			"value": "PUNCH",
			"label": "PUNCH"
	},
	{
			"value": "PUR",
			"label": "PUR"
	},
	{
			"value": "PUREED PUMPKIN",
			"label": "PUREED PUMPKIN"
	},
	{
			"value": "PURLE",
			"label": "PURLE"
	},
	{
			"value": "PURPL",
			"label": "PURPL"
	},
	{
			"value": "PURPLE",
			"label": "PURPLE"
	},
	{
			"value": "PURPLE/BLACK",
			"label": "PURPLE/BLACK"
	},
	{
			"value": "PURPLE DARK",
			"label": "PURPLE DARK"
	},
	{
			"value": "PURPLE DK",
			"label": "PURPLE DK"
	},
	{
			"value": "PURPLE FLORAL",
			"label": "PURPLE FLORAL"
	},
	{
			"value": "PURPLE/GREEN",
			"label": "PURPLE/GREEN"
	},
	{
			"value": "PURPLE LIGHT",
			"label": "PURPLE LIGHT"
	},
	{
			"value": "PURPLE LT",
			"label": "PURPLE LT"
	},
	{
			"value": "PURPLE LT.",
			"label": "PURPLE LT."
	},
	{
			"value": "PURPLE LY",
			"label": "PURPLE LY"
	},
	{
			"value": "PURPLE MIX",
			"label": "PURPLE MIX"
	},
	{
			"value": "PURPLE PASSION",
			"label": "PURPLE PASSION"
	},
	{
			"value": "PURPLE/PINK",
			"label": "PURPLE/PINK"
	},
	{
			"value": "PURPLR",
			"label": "PURPLR"
	},
	{
			"value": "PURPPLE",
			"label": "PURPPLE"
	},
	{
			"value": "PUR/ WHT",
			"label": "PUR/ WHT"
	},
	{
			"value": "PUT",
			"label": "PUT"
	},
	{
			"value": "PUTTY",
			"label": "PUTTY"
	},
	{
			"value": "QUARRY",
			"label": "QUARRY"
	},
	{
			"value": "QUARTZ",
			"label": "QUARTZ"
	},
	{
			"value": "QUEPP201-202",
			"label": "QUEPP201-202"
	},
	{
			"value": "QUEUE HOLIDAY CANDLE",
			"label": "QUEUE HOLIDAY CANDLE"
	},
	{
			"value": "QUILTING SET",
			"label": "QUILTING SET"
	},
	{
			"value": "R5 (14-16-18-20-22)",
			"label": "R5 (14-16-18-20-22)"
	},
	{
			"value": "RABBIT",
			"label": "RABBIT"
	},
	{
			"value": "RACING RED",
			"label": "RACING RED"
	},
	{
			"value": "RADIANCE",
			"label": "RADIANCE"
	},
	{
			"value": "RAFFIA",
			"label": "RAFFIA"
	},
	{
			"value": "RAGLAN",
			"label": "RAGLAN"
	},
	{
			"value": "RAINBOW",
			"label": "RAINBOW"
	},
	{
			"value": "RAINFOREST",
			"label": "RAINFOREST"
	},
	{
			"value": "RAISIN",
			"label": "RAISIN"
	},
	{
			"value": "RASPBERRY",
			"label": "RASPBERRY"
	},
	{
			"value": "RATAN",
			"label": "RATAN"
	},
	{
			"value": "RATTAN",
			"label": "RATTAN"
	},
	{
			"value": "RAVEN",
			"label": "RAVEN"
	},
	{
			"value": "RD",
			"label": "RD"
	},
	{
			"value": "RE",
			"label": "RE"
	},
	{
			"value": "READY ROLLED",
			"label": "READY ROLLED"
	},
	{
			"value": "RECTANGLE",
			"label": "RECTANGLE"
	},
	{
			"value": "RED",
			"label": "RED"
	},
	{
			"value": "RED/BLACK",
			"label": "RED/BLACK"
	},
	{
			"value": "RED/BLACK/WHITE",
			"label": "RED/BLACK/WHITE"
	},
	{
			"value": "RED/BLK",
			"label": "RED/BLK"
	},
	{
			"value": "RED/BLUE",
			"label": "RED/BLUE"
	},
	{
			"value": "RED/BRONZE",
			"label": "RED/BRONZE"
	},
	{
			"value": "RED/BROWN",
			"label": "RED/BROWN"
	},
	{
			"value": "RED/BURGUNDY",
			"label": "RED/BURGUNDY"
	},
	{
			"value": "REDBUSHES",
			"label": "REDBUSHES"
	},
	{
			"value": "RED CARDINAL",
			"label": "RED CARDINAL"
	},
	{
			"value": "RED DK",
			"label": "RED DK"
	},
	{
			"value": "RED/GOLD",
			"label": "RED/GOLD"
	},
	{
			"value": "RED GREEN",
			"label": "RED GREEN"
	},
	{
			"value": "RED/GREEN",
			"label": "RED/GREEN"
	},
	{
			"value": "RED/GREEN/GOLD",
			"label": "RED/GREEN/GOLD"
	},
	{
			"value": "RED GREEN WHITE",
			"label": "RED GREEN WHITE"
	},
	{
			"value": "RED/GRN",
			"label": "RED/GRN"
	},
	{
			"value": "RED/GRN/BLU",
			"label": "RED/GRN/BLU"
	},
	{
			"value": "RED/GRN/TAUPE",
			"label": "RED/GRN/TAUPE"
	},
	{
			"value": "RED/GRN/WHT",
			"label": "RED/GRN/WHT"
	},
	{
			"value": "RED LT",
			"label": "RED LT"
	},
	{
			"value": "RED ORANGE",
			"label": "RED ORANGE"
	},
	{
			"value": "REDPANELACCESSORIES",
			"label": "REDPANELACCESSORIES"
	},
	{
			"value": "RED PINK",
			"label": "RED PINK"
	},
	{
			"value": "RED/PINK HEART PATTE",
			"label": "RED/PINK HEART PATTE"
	},
	{
			"value": "RED ROSE",
			"label": "RED ROSE"
	},
	{
			"value": "RED STRIPE",
			"label": "RED STRIPE"
	},
	{
			"value": "RED TONE",
			"label": "RED TONE"
	},
	{
			"value": "RED TONES",
			"label": "RED TONES"
	},
	{
			"value": "RED WHITE",
			"label": "RED WHITE"
	},
	{
			"value": "RED/WHITE",
			"label": "RED/WHITE"
	},
	{
			"value": "RED/WHITE/BLUE",
			"label": "RED/WHITE/BLUE"
	},
	{
			"value": "RED/WHT",
			"label": "RED/WHT"
	},
	{
			"value": "REDWOOD",
			"label": "REDWOOD"
	},
	{
			"value": "REED",
			"label": "REED"
	},
	{
			"value": "REF!",
			"label": "REF!"
	},
	{
			"value": "RESIN",
			"label": "RESIN"
	},
	{
			"value": "REUSABLE",
			"label": "REUSABLE"
	},
	{
			"value": "REVIVAL PANEL 2",
			"label": "REVIVAL PANEL 2"
	},
	{
			"value": "REVIVL PANEL 1",
			"label": "REVIVL PANEL 1"
	},
	{
			"value": "REVIVL PANEL 3",
			"label": "REVIVL PANEL 3"
	},
	{
			"value": "RHITE",
			"label": "RHITE"
	},
	{
			"value": "RHUBARB",
			"label": "RHUBARB"
	},
	{
			"value": "RIBBON POPS",
			"label": "RIBBON POPS"
	},
	{
			"value": "RIBBON RED",
			"label": "RIBBON RED"
	},
	{
			"value": "RIVER",
			"label": "RIVER"
	},
	{
			"value": "RIVER BLUE",
			"label": "RIVER BLUE"
	},
	{
			"value": "ROBIN",
			"label": "ROBIN"
	},
	{
			"value": "ROBIN'S EGG",
			"label": "ROBIN'S EGG"
	},
	{
			"value": "ROBINS EGG",
			"label": "ROBINS EGG"
	},
	{
			"value": "ROBINS EGG BLUE",
			"label": "ROBINS EGG BLUE"
	},
	{
			"value": "ROCK GEMS",
			"label": "ROCK GEMS"
	},
	{
			"value": "ROOSTER",
			"label": "ROOSTER"
	},
	{
			"value": "RO PANSINO",
			"label": "RO PANSINO"
	},
	{
			"value": "ROPE",
			"label": "ROPE"
	},
	{
			"value": "ROSE",
			"label": "ROSE"
	},
	{
			"value": "ROSE GOLD",
			"label": "ROSE GOLD"
	},
	{
			"value": "ROSEGOLD",
			"label": "ROSEGOLD"
	},
	{
			"value": "ROSE QUARTZ",
			"label": "ROSE QUARTZ"
	},
	{
			"value": "ROSETTE",
			"label": "ROSETTE"
	},
	{
			"value": "ROSEWATER",
			"label": "ROSEWATER"
	},
	{
			"value": "ROTARY SET",
			"label": "ROTARY SET"
	},
	{
			"value": "ROTATING SET",
			"label": "ROTATING SET"
	},
	{
			"value": "ROUGE",
			"label": "ROUGE"
	},
	{
			"value": "ROUND",
			"label": "ROUND"
	},
	{
			"value": "ROYAL",
			"label": "ROYAL"
	},
	{
			"value": "ROYAL BLUE",
			"label": "ROYAL BLUE"
	},
	{
			"value": "ROYAL ICING",
			"label": "ROYAL ICING"
	},
	{
			"value": "ROYAL MIST",
			"label": "ROYAL MIST"
	},
	{
			"value": "ROYAL PURPLE",
			"label": "ROYAL PURPLE"
	},
	{
			"value": "ROYGBIV PANEL 1",
			"label": "ROYGBIV PANEL 1"
	},
	{
			"value": "ROYGBIV PANEL 2",
			"label": "ROYGBIV PANEL 2"
	},
	{
			"value": "RUBY",
			"label": "RUBY"
	},
	{
			"value": "RUFFLE",
			"label": "RUFFLE"
	},
	{
			"value": "RULER SET",
			"label": "RULER SET"
	},
	{
			"value": "RUM RAISIN",
			"label": "RUM RAISIN"
	},
	{
			"value": "RUSSET",
			"label": "RUSSET"
	},
	{
			"value": "RUST",
			"label": "RUST"
	},
	{
			"value": "RUSTIC",
			"label": "RUSTIC"
	},
	{
			"value": "RUSTICA/POLYOXF",
			"label": "RUSTICA/POLYOXF"
	},
	{
			"value": "RYE",
			"label": "RYE"
	},
	{
			"value": "SA660603",
			"label": "SA660603"
	},
	{
			"value": "SA685603",
			"label": "SA685603"
	},
	{
			"value": "SA685609",
			"label": "SA685609"
	},
	{
			"value": "SA8001607",
			"label": "SA8001607"
	},
	{
			"value": "SA800601",
			"label": "SA800601"
	},
	{
			"value": "SA800602",
			"label": "SA800602"
	},
	{
			"value": "SA800603",
			"label": "SA800603"
	},
	{
			"value": "SA800607",
			"label": "SA800607"
	},
	{
			"value": "SA800609",
			"label": "SA800609"
	},
	{
			"value": "SABLE",
			"label": "SABLE"
	},
	{
			"value": "SACHET PINK",
			"label": "SACHET PINK"
	},
	{
			"value": "SADDLE",
			"label": "SADDLE"
	},
	{
			"value": "SAFARI",
			"label": "SAFARI"
	},
	{
			"value": "SAFETY GREEN",
			"label": "SAFETY GREEN"
	},
	{
			"value": "SAFETY ORANGE",
			"label": "SAFETY ORANGE"
	},
	{
			"value": "SAFETY PINK",
			"label": "SAFETY PINK"
	},
	{
			"value": "SAFFRON",
			"label": "SAFFRON"
	},
	{
			"value": "SAGE",
			"label": "SAGE"
	},
	{
			"value": "SAGE- ALMOND",
			"label": "SAGE- ALMOND"
	},
	{
			"value": "SAGE-ALMOND",
			"label": "SAGE-ALMOND"
	},
	{
			"value": "SAGE/TAN",
			"label": "SAGE/TAN"
	},
	{
			"value": "SAHARA",
			"label": "SAHARA"
	},
	{
			"value": "SAIL",
			"label": "SAIL"
	},
	{
			"value": "SAILOR",
			"label": "SAILOR"
	},
	{
			"value": "SALMON",
			"label": "SALMON"
	},
	{
			"value": "SALSA",
			"label": "SALSA"
	},
	{
			"value": "SALT",
			"label": "SALT"
	},
	{
			"value": "SALT/PEPPER",
			"label": "SALT/PEPPER"
	},
	{
			"value": "SAMBA",
			"label": "SAMBA"
	},
	{
			"value": "SANCTUARY",
			"label": "SANCTUARY"
	},
	{
			"value": "SAND",
			"label": "SAND"
	},
	{
			"value": "SANDALWOOD",
			"label": "SANDALWOOD"
	},
	{
			"value": "SAND DOLLAR",
			"label": "SAND DOLLAR"
	},
	{
			"value": "SAND DRIFT",
			"label": "SAND DRIFT"
	},
	{
			"value": "SAND DUNE",
			"label": "SAND DUNE"
	},
	{
			"value": "SANDED OAK",
			"label": "SANDED OAK"
	},
	{
			"value": "SANDLEWOOD",
			"label": "SANDLEWOOD"
	},
	{
			"value": "SAND PAIL",
			"label": "SAND PAIL"
	},
	{
			"value": "SAND STONE",
			"label": "SAND STONE"
	},
	{
			"value": "SANDSTONE",
			"label": "SANDSTONE"
	},
	{
			"value": "SANGRIA",
			"label": "SANGRIA"
	},
	{
			"value": "SANSTONE",
			"label": "SANSTONE"
	},
	{
			"value": "SAP",
			"label": "SAP"
	},
	{
			"value": "SAPPHIRE",
			"label": "SAPPHIRE"
	},
	{
			"value": "SAPPHIRE BLUE",
			"label": "SAPPHIRE BLUE"
	},
	{
			"value": "SATIN BALTIC",
			"label": "SATIN BALTIC"
	},
	{
			"value": "SATIN NICKEL",
			"label": "SATIN NICKEL"
	},
	{
			"value": "SC",
			"label": "SC"
	},
	{
			"value": "SCARECROW",
			"label": "SCARECROW"
	},
	{
			"value": "SCARLET",
			"label": "SCARLET"
	},
	{
			"value": "SCARLETT",
			"label": "SCARLETT"
	},
	{
			"value": "SCROLL",
			"label": "SCROLL"
	},
	{
			"value": "SDWK",
			"label": "SDWK"
	},
	{
			"value": "SDWK BASKETS",
			"label": "SDWK BASKETS"
	},
	{
			"value": "SDWK COCO LINERS",
			"label": "SDWK COCO LINERS"
	},
	{
			"value": "SDWK FASHION LINERS",
			"label": "SDWK FASHION LINERS"
	},
	{
			"value": "SDWK SHEP HOOKS",
			"label": "SDWK SHEP HOOKS"
	},
	{
			"value": "SEA",
			"label": "SEA"
	},
	{
			"value": "SEA BREEZE",
			"label": "SEA BREEZE"
	},
	{
			"value": "SEABREEZE",
			"label": "SEABREEZE"
	},
	{
			"value": "SEAFOAM",
			"label": "SEAFOAM"
	},
	{
			"value": "SEA GLASS",
			"label": "SEA GLASS"
	},
	{
			"value": "SEAGLASS",
			"label": "SEAGLASS"
	},
	{
			"value": "SEAGRASS",
			"label": "SEAGRASS"
	},
	{
			"value": "SEAL BROWN",
			"label": "SEAL BROWN"
	},
	{
			"value": "SEAMIST",
			"label": "SEAMIST"
	},
	{
			"value": "SEAPEARL",
			"label": "SEAPEARL"
	},
	{
			"value": "SEAPORT",
			"label": "SEAPORT"
	},
	{
			"value": "SEAPORT ENTERTAINING",
			"label": "SEAPORT ENTERTAINING"
	},
	{
			"value": "SEAPORT FLAGS & MATS",
			"label": "SEAPORT FLAGS & MATS"
	},
	{
			"value": "SEAPORT FOS",
			"label": "SEAPORT FOS"
	},
	{
			"value": "SEAPORT LIGHTING",
			"label": "SEAPORT LIGHTING"
	},
	{
			"value": "SEAPORT PILLOWS",
			"label": "SEAPORT PILLOWS"
	},
	{
			"value": "SEASCAPE",
			"label": "SEASCAPE"
	},
	{
			"value": "SEASHELLS",
			"label": "SEASHELLS"
	},
	{
			"value": "SEASIDE",
			"label": "SEASIDE"
	},
	{
			"value": "SEASONAL",
			"label": "SEASONAL"
	},
	{
			"value": "SEASONAL EC",
			"label": "SEASONAL EC"
	},
	{
			"value": "SEASONAL PAD",
			"label": "SEASONAL PAD"
	},
	{
			"value": "SEASONAL WORDS",
			"label": "SEASONAL WORDS"
	},
	{
			"value": "SEASPRAY",
			"label": "SEASPRAY"
	},
	{
			"value": "SEAWEED",
			"label": "SEAWEED"
	},
	{
			"value": "SEDONA",
			"label": "SEDONA"
	},
	{
			"value": "SEMOLINA",
			"label": "SEMOLINA"
	},
	{
			"value": "SENTIMENT",
			"label": "SENTIMENT"
	},
	{
			"value": "SEP",
			"label": "SEP"
	},
	{
			"value": "SEPIA",
			"label": "SEPIA"
	},
	{
			"value": "SEPT",
			"label": "SEPT"
	},
	{
			"value": "SEQUIN",
			"label": "SEQUIN"
	},
	{
			"value": "SEQUIN/EMB.",
			"label": "SEQUIN/EMB."
	},
	{
			"value": "SEQUINS",
			"label": "SEQUINS"
	},
	{
			"value": "SERENITY",
			"label": "SERENITY"
	},
	{
			"value": "SESAME",
			"label": "SESAME"
	},
	{
			"value": "SET",
			"label": "SET"
	},
	{
			"value": "SETS IN CHRISTMAS WH",
			"label": "SETS IN CHRISTMAS WH"
	},
	{
			"value": "SETS IN WINTER SOLST",
			"label": "SETS IN WINTER SOLST"
	},
	{
			"value": "SETS WITH HOUSES",
			"label": "SETS WITH HOUSES"
	},
	{
			"value": "SEWING SET",
			"label": "SEWING SET"
	},
	{
			"value": "SHADOW",
			"label": "SHADOW"
	},
	{
			"value": "SHAMROCK GREEN",
			"label": "SHAMROCK GREEN"
	},
	{
			"value": "SHAPED",
			"label": "SHAPED"
	},
	{
			"value": "SHEEP",
			"label": "SHEEP"
	},
	{
			"value": "SHEER",
			"label": "SHEER"
	},
	{
			"value": "SHEET",
			"label": "SHEET"
	},
	{
			"value": "SHELL",
			"label": "SHELL"
	},
	{
			"value": "SHELL PINK",
			"label": "SHELL PINK"
	},
	{
			"value": "SHEP HOOKS",
			"label": "SHEP HOOKS"
	},
	{
			"value": "SHERBERT",
			"label": "SHERBERT"
	},
	{
			"value": "SHERWOOD",
			"label": "SHERWOOD"
	},
	{
			"value": "SHIFTING SAND",
			"label": "SHIFTING SAND"
	},
	{
			"value": "SHIMMER DUST",
			"label": "SHIMMER DUST"
	},
	{
			"value": "SHIRAZ",
			"label": "SHIRAZ"
	},
	{
			"value": "SHITAKE",
			"label": "SHITAKE"
	},
	{
			"value": "SHOCKING PINK",
			"label": "SHOCKING PINK"
	},
	{
			"value": "SHORELINE",
			"label": "SHORELINE"
	},
	{
			"value": "SHORT",
			"label": "SHORT"
	},
	{
			"value": "SHOT GLASS",
			"label": "SHOT GLASS"
	},
	{
			"value": "SIAM",
			"label": "SIAM"
	},
	{
			"value": "SIDE 2 - EK PUNCHES",
			"label": "SIDE 2 - EK PUNCHES"
	},
	{
			"value": "SIDEWALK SALE BINS",
			"label": "SIDEWALK SALE BINS"
	},
	{
			"value": "SIENA",
			"label": "SIENA"
	},
	{
			"value": "SIENNA",
			"label": "SIENNA"
	},
	{
			"value": "SIGNAL FLAG RED",
			"label": "SIGNAL FLAG RED"
	},
	{
			"value": "SIGNATURE",
			"label": "SIGNATURE"
	},
	{
			"value": "SIG PANEL 1",
			"label": "SIG PANEL 1"
	},
	{
			"value": "SIG PANEL 1 ORNG",
			"label": "SIG PANEL 1 ORNG"
	},
	{
			"value": "SIG PANEL 2 CREAM",
			"label": "SIG PANEL 2 CREAM"
	},
	{
			"value": "SILICONE",
			"label": "SILICONE"
	},
	{
			"value": "SILK",
			"label": "SILK"
	},
	{
			"value": "SILT",
			"label": "SILT"
	},
	{
			"value": "SILVER",
			"label": "SILVER"
	},
	{
			"value": "SILVER/BLACK",
			"label": "SILVER/BLACK"
	},
	{
			"value": "SILVER/BLUE",
			"label": "SILVER/BLUE"
	},
	{
			"value": "SILVER/ COPPER",
			"label": "SILVER/ COPPER"
	},
	{
			"value": "SILVER/GOLD",
			"label": "SILVER/GOLD"
	},
	{
			"value": "SILVER HOLO",
			"label": "SILVER HOLO"
	},
	{
			"value": "SILVER LINING",
			"label": "SILVER LINING"
	},
	{
			"value": "SILVER LINNING",
			"label": "SILVER LINNING"
	},
	{
			"value": "SILVER SAGE",
			"label": "SILVER SAGE"
	},
	{
			"value": "SILVER TONES",
			"label": "SILVER TONES"
	},
	{
			"value": "SILVING LINING",
			"label": "SILVING LINING"
	},
	{
			"value": "SINGER",
			"label": "SINGER"
	},
	{
			"value": "SINGLE",
			"label": "SINGLE"
	},
	{
			"value": "SISAL",
			"label": "SISAL"
	},
	{
			"value": "SIVLER",
			"label": "SIVLER"
	},
	{
			"value": "SKELETON",
			"label": "SKELETON"
	},
	{
			"value": "SKIN",
			"label": "SKIN"
	},
	{
			"value": "SKIPPER",
			"label": "SKIPPER"
	},
	{
			"value": "SKULL",
			"label": "SKULL"
	},
	{
			"value": "SKULLS/SEANCE",
			"label": "SKULLS/SEANCE"
	},
	{
			"value": "SKY",
			"label": "SKY"
	},
	{
			"value": "SKY BLUE",
			"label": "SKY BLUE"
	},
	{
			"value": "SLATE",
			"label": "SLATE"
	},
	{
			"value": "SLIPPER PINK",
			"label": "SLIPPER PINK"
	},
	{
			"value": "SL PANEL 1",
			"label": "SL PANEL 1"
	},
	{
			"value": "SL PANEL 2",
			"label": "SL PANEL 2"
	},
	{
			"value": "SLV",
			"label": "SLV"
	},
	{
			"value": "SLV GLD",
			"label": "SLV GLD"
	},
	{
			"value": "SLVRPANELACCESSORIE",
			"label": "SLVRPANELACCESSORIE"
	},
	{
			"value": "SMILEY",
			"label": "SMILEY"
	},
	{
			"value": "SMOKE",
			"label": "SMOKE"
	},
	{
			"value": "SMOKY AMETHYST",
			"label": "SMOKY AMETHYST"
	},
	{
			"value": "SNAP DRAGON",
			"label": "SNAP DRAGON"
	},
	{
			"value": "SNAP PINK",
			"label": "SNAP PINK"
	},
	{
			"value": "SNOW",
			"label": "SNOW"
	},
	{
			"value": "SNOW LEOPARD",
			"label": "SNOW LEOPARD"
	},
	{
			"value": "SNOW WHITE",
			"label": "SNOW WHITE"
	},
	{
			"value": "SOAPSTONE",
			"label": "SOAPSTONE"
	},
	{
			"value": "SOCCER",
			"label": "SOCCER"
	},
	{
			"value": "SODA ASH",
			"label": "SODA ASH"
	},
	{
			"value": "SODALITE",
			"label": "SODALITE"
	},
	{
			"value": "SODALITE BLUE",
			"label": "SODALITE BLUE"
	},
	{
			"value": "SOLID",
			"label": "SOLID"
	},
	{
			"value": "SORBET",
			"label": "SORBET"
	},
	{
			"value": "SOUR APPLE",
			"label": "SOUR APPLE"
	},
	{
			"value": "SOUTHWEST",
			"label": "SOUTHWEST"
	},
	{
			"value": "SPA",
			"label": "SPA"
	},
	{
			"value": "SPARKLE DUST",
			"label": "SPARKLE DUST"
	},
	{
			"value": "SPARKLE GEL",
			"label": "SPARKLE GEL"
	},
	{
			"value": "SPARKLER",
			"label": "SPARKLER"
	},
	{
			"value": "SPARKLERS",
			"label": "SPARKLERS"
	},
	{
			"value": "SPCIE",
			"label": "SPCIE"
	},
	{
			"value": "SPECIALTY",
			"label": "SPECIALTY"
	},
	{
			"value": "SPICE",
			"label": "SPICE"
	},
	{
			"value": "SPIDER",
			"label": "SPIDER"
	},
	{
			"value": "SPIDERS",
			"label": "SPIDERS"
	},
	{
			"value": "SPORT",
			"label": "SPORT"
	},
	{
			"value": "SPORT GREY",
			"label": "SPORT GREY"
	},
	{
			"value": "SPOT",
			"label": "SPOT"
	},
	{
			"value": "SPRAY",
			"label": "SPRAY"
	},
	{
			"value": "SPRAY GREEN",
			"label": "SPRAY GREEN"
	},
	{
			"value": "SPRING",
			"label": "SPRING"
	},
	{
			"value": "SPRING DÉCOR",
			"label": "SPRING DÉCOR"
	},
	{
			"value": "SPRINGFORM",
			"label": "SPRINGFORM"
	},
	{
			"value": "SPRING GARDEN",
			"label": "SPRING GARDEN"
	},
	{
			"value": "SPRING GREEN",
			"label": "SPRING GREEN"
	},
	{
			"value": "SPRING PET",
			"label": "SPRING PET"
	},
	{
			"value": "SPRING PK",
			"label": "SPRING PK"
	},
	{
			"value": "SPRING/PRIMARY",
			"label": "SPRING/PRIMARY"
	},
	{
			"value": "SPRING RAIN",
			"label": "SPRING RAIN"
	},
	{
			"value": "SPRINGTIME",
			"label": "SPRINGTIME"
	},
	{
			"value": "SPRINGTOME",
			"label": "SPRINGTOME"
	},
	{
			"value": "SPRINKLES",
			"label": "SPRINKLES"
	},
	{
			"value": "SPROUT",
			"label": "SPROUT"
	},
	{
			"value": "SPUN",
			"label": "SPUN"
	},
	{
			"value": "SQUARE",
			"label": "SQUARE"
	},
	{
			"value": "SQUARES",
			"label": "SQUARES"
	},
	{
			"value": "SSFF1A00",
			"label": "SSFF1A00"
	},
	{
			"value": "SS PANEL 1",
			"label": "SS PANEL 1"
	},
	{
			"value": "STACK OUT",
			"label": "STACK OUT"
	},
	{
			"value": "STACKOUT",
			"label": "STACKOUT"
	},
	{
			"value": "STAINLESS",
			"label": "STAINLESS"
	},
	{
			"value": "STAINLESS STEEL",
			"label": "STAINLESS STEEL"
	},
	{
			"value": "STALLION",
			"label": "STALLION"
	},
	{
			"value": "STAND",
			"label": "STAND"
	},
	{
			"value": "STANDARD",
			"label": "STANDARD"
	},
	{
			"value": "STAR",
			"label": "STAR"
	},
	{
			"value": "STARFISH",
			"label": "STARFISH"
	},
	{
			"value": "STARLIGHT BLUE",
			"label": "STARLIGHT BLUE"
	},
	{
			"value": "STAR WARS",
			"label": "STAR WARS"
	},
	{
			"value": "STATUARY",
			"label": "STATUARY"
	},
	{
			"value": "STATUARY PLANTER",
			"label": "STATUARY PLANTER"
	},
	{
			"value": "STBW",
			"label": "STBW"
	},
	{
			"value": "STEEL",
			"label": "STEEL"
	},
	{
			"value": "STEM",
			"label": "STEM"
	},
	{
			"value": "STENCIL",
			"label": "STENCIL"
	},
	{
			"value": "STERLING",
			"label": "STERLING"
	},
	{
			"value": "STK $159.99 CUSHIONS",
			"label": "STK $159.99 CUSHIONS"
	},
	{
			"value": "STK AMERICANA",
			"label": "STK AMERICANA"
	},
	{
			"value": "STK EASTER",
			"label": "STK EASTER"
	},
	{
			"value": "STK HH INFLATE",
			"label": "STK HH INFLATE"
	},
	{
			"value": "STK HH PATHWAY",
			"label": "STK HH PATHWAY"
	},
	{
			"value": "STK HH PORCH",
			"label": "STK HH PORCH"
	},
	{
			"value": "STK LOF",
			"label": "STK LOF"
	},
	{
			"value": "STK PET",
			"label": "STK PET"
	},
	{
			"value": "STK ST PATS",
			"label": "STK ST PATS"
	},
	{
			"value": "STK UMBRELLA",
			"label": "STK UMBRELLA"
	},
	{
			"value": "STK URBANAG GROW KIT",
			"label": "STK URBANAG GROW KIT"
	},
	{
			"value": "STK VAL",
			"label": "STK VAL"
	},
	{
			"value": "STOCKING",
			"label": "STOCKING"
	},
	{
			"value": "STOCKING HOLDER",
			"label": "STOCKING HOLDER"
	},
	{
			"value": "STONE",
			"label": "STONE"
	},
	{
			"value": "STONEWARE",
			"label": "STONEWARE"
	},
	{
			"value": "STONE WASHED BLUE",
			"label": "STONE WASHED BLUE"
	},
	{
			"value": "STORAGE",
			"label": "STORAGE"
	},
	{
			"value": "STORAGE BOX",
			"label": "STORAGE BOX"
	},
	{
			"value": "STORM",
			"label": "STORM"
	},
	{
			"value": "ST PATS",
			"label": "ST PATS"
	},
	{
			"value": "STRAW",
			"label": "STRAW"
	},
	{
			"value": "STRAWBERRY",
			"label": "STRAWBERRY"
	},
	{
			"value": "STRIPE",
			"label": "STRIPE"
	},
	{
			"value": "STRIPES",
			"label": "STRIPES"
	},
	{
			"value": "STRONG BLUE",
			"label": "STRONG BLUE"
	},
	{
			"value": "STUCCO",
			"label": "STUCCO"
	},
	{
			"value": "STUD",
			"label": "STUD"
	},
	{
			"value": "STYRO",
			"label": "STYRO"
	},
	{
			"value": "STYROFOAM",
			"label": "STYROFOAM"
	},
	{
			"value": "SUCCULENT",
			"label": "SUCCULENT"
	},
	{
			"value": "SUEDE",
			"label": "SUEDE"
	},
	{
			"value": "SUGAR ALMOND",
			"label": "SUGAR ALMOND"
	},
	{
			"value": "SUGARCANE",
			"label": "SUGARCANE"
	},
	{
			"value": "SUGAR/CREAMER",
			"label": "SUGAR/CREAMER"
	},
	{
			"value": "SUGAR CRYSTALS",
			"label": "SUGAR CRYSTALS"
	},
	{
			"value": "SUGARED ALMOND",
			"label": "SUGARED ALMOND"
	},
	{
			"value": "SUGAR PEARLS",
			"label": "SUGAR PEARLS"
	},
	{
			"value": "SUGAR PLUM",
			"label": "SUGAR PLUM"
	},
	{
			"value": "SULFUR GREEN",
			"label": "SULFUR GREEN"
	},
	{
			"value": "SUMMER",
			"label": "SUMMER"
	},
	{
			"value": "SUMMER LITTLES",
			"label": "SUMMER LITTLES"
	},
	{
			"value": "SUNBLU",
			"label": "SUNBLU"
	},
	{
			"value": "SUNBLUE",
			"label": "SUNBLUE"
	},
	{
			"value": "SUNBURST",
			"label": "SUNBURST"
	},
	{
			"value": "SUNDANCE",
			"label": "SUNDANCE"
	},
	{
			"value": "SUNFLOWER YELLOW",
			"label": "SUNFLOWER YELLOW"
	},
	{
			"value": "SUNNY YELLOW",
			"label": "SUNNY YELLOW"
	},
	{
			"value": "SUNSET",
			"label": "SUNSET"
	},
	{
			"value": "SUNSHINE",
			"label": "SUNSHINE"
	},
	{
			"value": "SUNSHINE ORANGE",
			"label": "SUNSHINE ORANGE"
	},
	{
			"value": "SUNSHINE YELLOW",
			"label": "SUNSHINE YELLOW"
	},
	{
			"value": "SUPER PINK",
			"label": "SUPER PINK"
	},
	{
			"value": "SURF",
			"label": "SURF"
	},
	{
			"value": "SURFSIDE",
			"label": "SURFSIDE"
	},
	{
			"value": "SURF THE WEB",
			"label": "SURF THE WEB"
	},
	{
			"value": "SWAN",
			"label": "SWAN"
	},
	{
			"value": "SWEET PEA",
			"label": "SWEET PEA"
	},
	{
			"value": "SWEET TOOTH FAIRY",
			"label": "SWEET TOOTH FAIRY"
	},
	{
			"value": "SYCAMORE",
			"label": "SYCAMORE"
	},
	{
			"value": "TABASCO",
			"label": "TABASCO"
	},
	{
			"value": "TABLECLOTH",
			"label": "TABLECLOTH"
	},
	{
			"value": "TABLE DECOR",
			"label": "TABLE DECOR"
	},
	{
			"value": "TABLETOP",
			"label": "TABLETOP"
	},
	{
			"value": "TACKLE BOX",
			"label": "TACKLE BOX"
	},
	{
			"value": "TAFFY",
			"label": "TAFFY"
	},
	{
			"value": "TALL",
			"label": "TALL"
	},
	{
			"value": "TALL AND SHORT",
			"label": "TALL AND SHORT"
	},
	{
			"value": "TAN",
			"label": "TAN"
	},
	{
			"value": "TAN BROWN",
			"label": "TAN BROWN"
	},
	{
			"value": "TANGERINE",
			"label": "TANGERINE"
	},
	{
			"value": "TANGO RED",
			"label": "TANGO RED"
	},
	{
			"value": "TANS/NEUTRALS",
			"label": "TANS/NEUTRALS"
	},
	{
			"value": "TANZANITE AMETHYST",
			"label": "TANZANITE AMETHYST"
	},
	{
			"value": "TAPA",
			"label": "TAPA"
	},
	{
			"value": "TARRAGON",
			"label": "TARRAGON"
	},
	{
			"value": "TART",
			"label": "TART"
	},
	{
			"value": "TAUPE",
			"label": "TAUPE"
	},
	{
			"value": "TAUPE RASPBERRY",
			"label": "TAUPE RASPBERRY"
	},
	{
			"value": "TAWNY PORT",
			"label": "TAWNY PORT"
	},
	{
			"value": "TBD",
			"label": "TBD"
	},
	{
			"value": "TEA",
			"label": "TEA"
	},
	{
			"value": "TEAK",
			"label": "TEAK"
	},
	{
			"value": "TEAL",
			"label": "TEAL"
	},
	{
			"value": "TEA LAEF",
			"label": "TEA LAEF"
	},
	{
			"value": "TEAL BLACK",
			"label": "TEAL BLACK"
	},
	{
			"value": "TEAL DK",
			"label": "TEAL DK"
	},
	{
			"value": "TEAL LT",
			"label": "TEAL LT"
	},
	{
			"value": "TEA/RED",
			"label": "TEA/RED"
	},
	{
			"value": "TEA STAIN",
			"label": "TEA STAIN"
	},
	{
			"value": "TEASTAIN",
			"label": "TEASTAIN"
	},
	{
			"value": "TERRA",
			"label": "TERRA"
	},
	{
			"value": "TERRAC",
			"label": "TERRAC"
	},
	{
			"value": "TERRA COTTA",
			"label": "TERRA COTTA"
	},
	{
			"value": "TERRACOTTA",
			"label": "TERRACOTTA"
	},
	{
			"value": "TERRACOTTA RED",
			"label": "TERRACOTTA RED"
	},
	{
			"value": "TERRACOTTA SLATE",
			"label": "TERRACOTTA SLATE"
	},
	{
			"value": "TERRAIN",
			"label": "TERRAIN"
	},
	{
			"value": "TESTER",
			"label": "TESTER"
	},
	{
			"value": "TEXTILES",
			"label": "TEXTILES"
	},
	{
			"value": "TEXTURE",
			"label": "TEXTURE"
	},
	{
			"value": "THERMOMETER",
			"label": "THERMOMETER"
	},
	{
			"value": "THISTLE",
			"label": "THISTLE"
	},
	{
			"value": "THROW OOL",
			"label": "THROW OOL"
	},
	{
			"value": "THYME",
			"label": "THYME"
	},
	{
			"value": "TIDEWATER",
			"label": "TIDEWATER"
	},
	{
			"value": "TIE DYE",
			"label": "TIE DYE"
	},
	{
			"value": "TIGER'S EYE",
			"label": "TIGER'S EYE"
	},
	{
			"value": "TILE",
			"label": "TILE"
	},
	{
			"value": "TILE BLUE",
			"label": "TILE BLUE"
	},
	{
			"value": "TINSEL",
			"label": "TINSEL"
	},
	{
			"value": "TINSEL PANEL",
			"label": "TINSEL PANEL"
	},
	{
			"value": "TIPS",
			"label": "TIPS"
	},
	{
			"value": "TITANIUM",
			"label": "TITANIUM"
	},
	{
			"value": "TNACCESSORIES",
			"label": "TNACCESSORIES"
	},
	{
			"value": "TNCONTAINERS",
			"label": "TNCONTAINERS"
	},
	{
			"value": "TOAST",
			"label": "TOAST"
	},
	{
			"value": "TOASTED ALMOND",
			"label": "TOASTED ALMOND"
	},
	{
			"value": "TOBACC",
			"label": "TOBACC"
	},
	{
			"value": "TOBACCO",
			"label": "TOBACCO"
	},
	{
			"value": "TOBACCO/WHITE",
			"label": "TOBACCO/WHITE"
	},
	{
			"value": "TODDLER",
			"label": "TODDLER"
	},
	{
			"value": "TOFFEE",
			"label": "TOFFEE"
	},
	{
			"value": "TOMATO",
			"label": "TOMATO"
	},
	{
			"value": "TOMATO RED",
			"label": "TOMATO RED"
	},
	{
			"value": "TOOL",
			"label": "TOOL"
	},
	{
			"value": "TOOLS",
			"label": "TOOLS"
	},
	{
			"value": "TOPAZ",
			"label": "TOPAZ"
	},
	{
			"value": "TORTOISE",
			"label": "TORTOISE"
	},
	{
			"value": "TOTAL ECLIPSE",
			"label": "TOTAL ECLIPSE"
	},
	{
			"value": "TOTALECLIPSE",
			"label": "TOTALECLIPSE"
	},
	{
			"value": "TOTAL ECLISPE",
			"label": "TOTAL ECLISPE"
	},
	{
			"value": "TOWEL PANEL",
			"label": "TOWEL PANEL"
	},
	{
			"value": "TOWER",
			"label": "TOWER"
	},
	{
			"value": "TOY",
			"label": "TOY"
	},
	{
			"value": "TPOAZ",
			"label": "TPOAZ"
	},
	{
			"value": "TRADITIONAL",
			"label": "TRADITIONAL"
	},
	{
			"value": "TRADITIONAL BIRD",
			"label": "TRADITIONAL BIRD"
	},
	{
			"value": "TRADITIONAL FLORAL",
			"label": "TRADITIONAL FLORAL"
	},
	{
			"value": "TRADITIONS FP 1",
			"label": "TRADITIONS FP 1"
	},
	{
			"value": "TRADITIONS FP 2",
			"label": "TRADITIONS FP 2"
	},
	{
			"value": "TRADITIONS FP 3",
			"label": "TRADITIONS FP 3"
	},
	{
			"value": "TRANSFER TAPE",
			"label": "TRANSFER TAPE"
	},
	{
			"value": "TRANSLUCENT",
			"label": "TRANSLUCENT"
	},
	{
			"value": "TRANSLUCENT MULTI",
			"label": "TRANSLUCENT MULTI"
	},
	{
			"value": "TRAVEL",
			"label": "TRAVEL"
	},
	{
			"value": "TRAVERTINE",
			"label": "TRAVERTINE"
	},
	{
			"value": "TRAY",
			"label": "TRAY"
	},
	{
			"value": "TREASURE",
			"label": "TREASURE"
	},
	{
			"value": "TREAT",
			"label": "TREAT"
	},
	{
			"value": "TREAT BAGS",
			"label": "TREAT BAGS"
	},
	{
			"value": "TREAT BOX",
			"label": "TREAT BOX"
	},
	{
			"value": "TREAT BOXES",
			"label": "TREAT BOXES"
	},
	{
			"value": "TREAT KIT",
			"label": "TREAT KIT"
	},
	{
			"value": "TREAT KITS",
			"label": "TREAT KITS"
	},
	{
			"value": "TREAT SET",
			"label": "TREAT SET"
	},
	{
			"value": "TREAT STICKS",
			"label": "TREAT STICKS"
	},
	{
			"value": "TREE SKIRT",
			"label": "TREE SKIRT"
	},
	{
			"value": "TRI",
			"label": "TRI"
	},
	{
			"value": "TROPIC",
			"label": "TROPIC"
	},
	{
			"value": "TROPICAL GREEN",
			"label": "TROPICAL GREEN"
	},
	{
			"value": "TROPICAL PRINT",
			"label": "TROPICAL PRINT"
	},
	{
			"value": "TROPICAL YELLOW",
			"label": "TROPICAL YELLOW"
	},
	{
			"value": "TRUE PINK",
			"label": "TRUE PINK"
	},
	{
			"value": "TRUFFLE",
			"label": "TRUFFLE"
	},
	{
			"value": "TT PANEL 1",
			"label": "TT PANEL 1"
	},
	{
			"value": "TT PANEL 2",
			"label": "TT PANEL 2"
	},
	{
			"value": "TUBE",
			"label": "TUBE"
	},
	{
			"value": "TUBING",
			"label": "TUBING"
	},
	{
			"value": "TULIP",
			"label": "TULIP"
	},
	{
			"value": "TURKEY",
			"label": "TURKEY"
	},
	{
			"value": "TURQ",
			"label": "TURQ"
	},
	{
			"value": "TURQOISE",
			"label": "TURQOISE"
	},
	{
			"value": "TURQUIOSE",
			"label": "TURQUIOSE"
	},
	{
			"value": "TURQUISE",
			"label": "TURQUISE"
	},
	{
			"value": "TURQUOIS",
			"label": "TURQUOIS"
	},
	{
			"value": "TURQUOISE",
			"label": "TURQUOISE"
	},
	{
			"value": "TURQUOISE CHEVRON",
			"label": "TURQUOISE CHEVRON"
	},
	{
			"value": "TURQUOISE DAMASK",
			"label": "TURQUOISE DAMASK"
	},
	{
			"value": "TURQUOISE DOT",
			"label": "TURQUOISE DOT"
	},
	{
			"value": "TUSCAN",
			"label": "TUSCAN"
	},
	{
			"value": "TUSCANY",
			"label": "TUSCANY"
	},
	{
			"value": "TUSK",
			"label": "TUSK"
	},
	{
			"value": "TUSSAH",
			"label": "TUSSAH"
	},
	{
			"value": "TUTU",
			"label": "TUTU"
	},
	{
			"value": "TUXEDO",
			"label": "TUXEDO"
	},
	{
			"value": "TWEED",
			"label": "TWEED"
	},
	{
			"value": "TWEEN",
			"label": "TWEEN"
	},
	{
			"value": "TWELFTHNIGHTBUSHES",
			"label": "TWELFTHNIGHTBUSHES"
	},
	{
			"value": "TWELFTHNIGHTPICKS",
			"label": "TWELFTHNIGHTPICKS"
	},
	{
			"value": "TWELVE NIGHT",
			"label": "TWELVE NIGHT"
	},
	{
			"value": "TWELVENIGHTPICKS",
			"label": "TWELVENIGHTPICKS"
	},
	{
			"value": "TWIG",
			"label": "TWIG"
	},
	{
			"value": "TWILIGHT",
			"label": "TWILIGHT"
	},
	{
			"value": "TWINE",
			"label": "TWINE"
	},
	{
			"value": "U5 (16-18-20-22-24)",
			"label": "U5 (16-18-20-22-24)"
	},
	{
			"value": "ULTIMATE GRAY",
			"label": "ULTIMATE GRAY"
	},
	{
			"value": "ULTRAVIOLET",
			"label": "ULTRAVIOLET"
	},
	{
			"value": "UMBER",
			"label": "UMBER"
	},
	{
			"value": "UNDEFINED",
			"label": "UNDEFINED"
	},
	{
			"value": "UNISEX",
			"label": "UNISEX"
	},
	{
			"value": "UNLINED",
			"label": "UNLINED"
	},
	{
			"value": "UNSCENTED",
			"label": "UNSCENTED"
	},
	{
			"value": "VAL",
			"label": "VAL"
	},
	{
			"value": "VALENTINE",
			"label": "VALENTINE"
	},
	{
			"value": "VALLEY",
			"label": "VALLEY"
	},
	{
			"value": "VALLEY PANEL 1",
			"label": "VALLEY PANEL 1"
	},
	{
			"value": "VALLEY PANEL 10",
			"label": "VALLEY PANEL 10"
	},
	{
			"value": "VALLEY PANEL 11",
			"label": "VALLEY PANEL 11"
	},
	{
			"value": "VALLEY PANEL 12",
			"label": "VALLEY PANEL 12"
	},
	{
			"value": "VALLEY PANEL 13",
			"label": "VALLEY PANEL 13"
	},
	{
			"value": "VALLEY PANEL 2",
			"label": "VALLEY PANEL 2"
	},
	{
			"value": "VALLEY PANEL 3",
			"label": "VALLEY PANEL 3"
	},
	{
			"value": "VALLEY PANEL 4",
			"label": "VALLEY PANEL 4"
	},
	{
			"value": "VALLEY PANEL 5",
			"label": "VALLEY PANEL 5"
	},
	{
			"value": "VALLEY PANEL 6",
			"label": "VALLEY PANEL 6"
	},
	{
			"value": "VALLEY PANEL 7",
			"label": "VALLEY PANEL 7"
	},
	{
			"value": "VALLEY PANEL 8",
			"label": "VALLEY PANEL 8"
	},
	{
			"value": "VALLEY PANEL 9",
			"label": "VALLEY PANEL 9"
	},
	{
			"value": "VANILLA",
			"label": "VANILLA"
	},
	{
			"value": "VANILLA ICE",
			"label": "VANILLA ICE"
	},
	{
			"value": "VAPOR",
			"label": "VAPOR"
	},
	{
			"value": "VARIOUS",
			"label": "VARIOUS"
	},
	{
			"value": "VELVET BLACK",
			"label": "VELVET BLACK"
	},
	{
			"value": "VELVET YELLOW",
			"label": "VELVET YELLOW"
	},
	{
			"value": "VERDE",
			"label": "VERDE"
	},
	{
			"value": "VERIDIAN",
			"label": "VERIDIAN"
	},
	{
			"value": "VERMILLION",
			"label": "VERMILLION"
	},
	{
			"value": "VERT (UPON APPROVAL)",
			"label": "VERT (UPON APPROVAL)"
	},
	{
			"value": "VERY BERRY",
			"label": "VERY BERRY"
	},
	{
			"value": "VIIOLET",
			"label": "VIIOLET"
	},
	{
			"value": "VIN01",
			"label": "VIN01"
	},
	{
			"value": "VIN03",
			"label": "VIN03"
	},
	{
			"value": "VIN14",
			"label": "VIN14"
	},
	{
			"value": "VIN21",
			"label": "VIN21"
	},
	{
			"value": "VIN30",
			"label": "VIN30"
	},
	{
			"value": "VIN31",
			"label": "VIN31"
	},
	{
			"value": "VIN32",
			"label": "VIN32"
	},
	{
			"value": "VIN38",
			"label": "VIN38"
	},
	{
			"value": "VINEYARD",
			"label": "VINEYARD"
	},
	{
			"value": "VINTAGE",
			"label": "VINTAGE"
	},
	{
			"value": "VINTAGE BLUE",
			"label": "VINTAGE BLUE"
	},
	{
			"value": "VINTAGE RED",
			"label": "VINTAGE RED"
	},
	{
			"value": "VINYL",
			"label": "VINYL"
	},
	{
			"value": "VIOLET",
			"label": "VIOLET"
	},
	{
			"value": "VM BIN",
			"label": "VM BIN"
	},
	{
			"value": "VM DEC BX",
			"label": "VM DEC BX"
	},
	{
			"value": "VM DECOR STOCK/SKIRT",
			"label": "VM DECOR STOCK/SKIRT"
	},
	{
			"value": "VM ENT/PAPER",
			"label": "VM ENT/PAPER"
	},
	{
			"value": "VM FLIP TOP",
			"label": "VM FLIP TOP"
	},
	{
			"value": "VM GARLAND",
			"label": "VM GARLAND"
	},
	{
			"value": "VM GIFTBAGS",
			"label": "VM GIFTBAGS"
	},
	{
			"value": "VM HEADER",
			"label": "VM HEADER"
	},
	{
			"value": "VM INLINE DÉCOR",
			"label": "VM INLINE DÉCOR"
	},
	{
			"value": "VM INLINE MUG",
			"label": "VM INLINE MUG"
	},
	{
			"value": "VM MAT/FLAG",
			"label": "VM MAT/FLAG"
	},
	{
			"value": "VM OSO",
			"label": "VM OSO"
	},
	{
			"value": "VM OUTDOOR DÉCOR",
			"label": "VM OUTDOOR DÉCOR"
	},
	{
			"value": "VM PET",
			"label": "VM PET"
	},
	{
			"value": "VM QUAD",
			"label": "VM QUAD"
	},
	{
			"value": "VM SHATTER",
			"label": "VM SHATTER"
	},
	{
			"value": "VM TIN",
			"label": "VM TIN"
	},
	{
			"value": "VM TOWEL",
			"label": "VM TOWEL"
	},
	{
			"value": "VM TREE DÉCOR",
			"label": "VM TREE DÉCOR"
	},
	{
			"value": "VM WRAP",
			"label": "VM WRAP"
	},
	{
			"value": "VM WRAP ACCESSORIES",
			"label": "VM WRAP ACCESSORIES"
	},
	{
			"value": "VOLCANO",
			"label": "VOLCANO"
	},
	{
			"value": "W2 (20W-22W-24W-26W-",
			"label": "W2 (20W-22W-24W-26W-"
	},
	{
			"value": "W3 (30W-32W-34W-36W-",
			"label": "W3 (30W-32W-34W-36W-"
	},
	{
			"value": "WALL DÉCOR",
			"label": "WALL DÉCOR"
	},
	{
			"value": "WALNUT",
			"label": "WALNUT"
	},
	{
			"value": "WARM",
			"label": "WARM"
	},
	{
			"value": "WARM MULTI",
			"label": "WARM MULTI"
	},
	{
			"value": "WARM-PINK",
			"label": "WARM-PINK"
	},
	{
			"value": "WARM-RED",
			"label": "WARM-RED"
	},
	{
			"value": "WASABI",
			"label": "WASABI"
	},
	{
			"value": "WATER",
			"label": "WATER"
	},
	{
			"value": "WATERFALL",
			"label": "WATERFALL"
	},
	{
			"value": "WATER FEATURES",
			"label": "WATER FEATURES"
	},
	{
			"value": "WATER HYCINTH",
			"label": "WATER HYCINTH"
	},
	{
			"value": "WATERLEAF",
			"label": "WATERLEAF"
	},
	{
			"value": "WATERMELON",
			"label": "WATERMELON"
	},
	{
			"value": "WATERSCAPE",
			"label": "WATERSCAPE"
	},
	{
			"value": "WCARRANGEMENTS",
			"label": "WCARRANGEMENTS"
	},
	{
			"value": "WCCONTAINERS",
			"label": "WCCONTAINERS"
	},
	{
			"value": "WEBS/SPIDERS",
			"label": "WEBS/SPIDERS"
	},
	{
			"value": "WEDGWOOD",
			"label": "WEDGWOOD"
	},
	{
			"value": "WEEPY",
			"label": "WEEPY"
	},
	{
			"value": "WELCOME",
			"label": "WELCOME"
	},
	{
			"value": "WET SAND",
			"label": "WET SAND"
	},
	{
			"value": "WF",
			"label": "WF"
	},
	{
			"value": "WFC",
			"label": "WFC"
	},
	{
			"value": "WHEAT",
			"label": "WHEAT"
	},
	{
			"value": "WHIE",
			"label": "WHIE"
	},
	{
			"value": "WHIITE",
			"label": "WHIITE"
	},
	{
			"value": "WHIMSEY",
			"label": "WHIMSEY"
	},
	{
			"value": "WHIMSY WORKSHOP",
			"label": "WHIMSY WORKSHOP"
	},
	{
			"value": "WHIPPED ICING",
			"label": "WHIPPED ICING"
	},
	{
			"value": "WHISKEY",
			"label": "WHISKEY"
	},
	{
			"value": "WHISPER",
			"label": "WHISPER"
	},
	{
			"value": "WHISPERING BLUE",
			"label": "WHISPERING BLUE"
	},
	{
			"value": "WHITE",
			"label": "WHITE"
	},
	{
			"value": "WHITE AND GOLD",
			"label": "WHITE AND GOLD"
	},
	{
			"value": "WHITE/BLACK",
			"label": "WHITE/BLACK"
	},
	{
			"value": "WHITE/BLK",
			"label": "WHITE/BLK"
	},
	{
			"value": "WHITE-BLUE",
			"label": "WHITE-BLUE"
	},
	{
			"value": "WHITEBUSHES",
			"label": "WHITEBUSHES"
	},
	{
			"value": "WHITE CARD",
			"label": "WHITE CARD"
	},
	{
			"value": "WHITE CHRISTMAS",
			"label": "WHITE CHRISTMAS"
	},
	{
			"value": "WHITECHRISTMASACCESS",
			"label": "WHITECHRISTMASACCESS"
	},
	{
			"value": "WHITECHRISTMASBUSHES",
			"label": "WHITECHRISTMASBUSHES"
	},
	{
			"value": "WHITECHRISTMASPICKS",
			"label": "WHITECHRISTMASPICKS"
	},
	{
			"value": "WHITE/CREAM/BROWN",
			"label": "WHITE/CREAM/BROWN"
	},
	{
			"value": "WHITE/IVORY",
			"label": "WHITE/IVORY"
	},
	{
			"value": "WHITE/KRAFT",
			"label": "WHITE/KRAFT"
	},
	{
			"value": "WHITE/MULTI",
			"label": "WHITE/MULTI"
	},
	{
			"value": "WHITE/RED",
			"label": "WHITE/RED"
	},
	{
			"value": "WHITES",
			"label": "WHITES"
	},
	{
			"value": "WHITE SAND",
			"label": "WHITE SAND"
	},
	{
			"value": "WHITE/SILVER",
			"label": "WHITE/SILVER"
	},
	{
			"value": "WHITE WASH",
			"label": "WHITE WASH"
	},
	{
			"value": "WHITE W IRIDESCENT",
			"label": "WHITE W IRIDESCENT"
	},
	{
			"value": "WHOOPIE PIE",
			"label": "WHOOPIE PIE"
	},
	{
			"value": "WHT",
			"label": "WHT"
	},
	{
			"value": "WHT/BLACK",
			"label": "WHT/BLACK"
	},
	{
			"value": "WHTE",
			"label": "WHTE"
	},
	{
			"value": "WHTIE",
			"label": "WHTIE"
	},
	{
			"value": "WHTPANELACCESSORIES",
			"label": "WHTPANELACCESSORIES"
	},
	{
			"value": "WICKER",
			"label": "WICKER"
	},
	{
			"value": "WIDE",
			"label": "WIDE"
	},
	{
			"value": "WILDERNESS",
			"label": "WILDERNESS"
	},
	{
			"value": "WILDFLOWER",
			"label": "WILDFLOWER"
	},
	{
			"value": "WILD IRIS",
			"label": "WILD IRIS"
	},
	{
			"value": "WILD RASPBERRY",
			"label": "WILD RASPBERRY"
	},
	{
			"value": "WILL BE ON GRID FW29",
			"label": "WILL BE ON GRID FW29"
	},
	{
			"value": "WILLIAMBURG",
			"label": "WILLIAMBURG"
	},
	{
			"value": "WILLOW",
			"label": "WILLOW"
	},
	{
			"value": "WILOW",
			"label": "WILOW"
	},
	{
			"value": "WINDCHIMES",
			"label": "WINDCHIMES"
	},
	{
			"value": "WINDOW PANE",
			"label": "WINDOW PANE"
	},
	{
			"value": "WINE",
			"label": "WINE"
	},
	{
			"value": "WINTER",
			"label": "WINTER"
	},
	{
			"value": "WINTER WHITE",
			"label": "WINTER WHITE"
	},
	{
			"value": "WIRE",
			"label": "WIRE"
	},
	{
			"value": "WITCH",
			"label": "WITCH"
	},
	{
			"value": "WITCH/BATS/CATS",
			"label": "WITCH/BATS/CATS"
	},
	{
			"value": "WIVORY",
			"label": "WIVORY"
	},
	{
			"value": "WJOTE",
			"label": "WJOTE"
	},
	{
			"value": "WL DEC BX",
			"label": "WL DEC BX"
	},
	{
			"value": "WL DECOR",
			"label": "WL DECOR"
	},
	{
			"value": "WL GARLAND",
			"label": "WL GARLAND"
	},
	{
			"value": "WL GIFTBAGS",
			"label": "WL GIFTBAGS"
	},
	{
			"value": "WL HEADER",
			"label": "WL HEADER"
	},
	{
			"value": "WL INLINE ENTERTAIN",
			"label": "WL INLINE ENTERTAIN"
	},
	{
			"value": "WL MUG",
			"label": "WL MUG"
	},
	{
			"value": "WL OSO",
			"label": "WL OSO"
	},
	{
			"value": "WL QUAD",
			"label": "WL QUAD"
	},
	{
			"value": "WL TIN",
			"label": "WL TIN"
	},
	{
			"value": "WL TOWEL",
			"label": "WL TOWEL"
	},
	{
			"value": "WOOD",
			"label": "WOOD"
	},
	{
			"value": "WOOD BROWN",
			"label": "WOOD BROWN"
	},
	{
			"value": "WOOD CHIP",
			"label": "WOOD CHIP"
	},
	{
			"value": "WOODCHIP",
			"label": "WOODCHIP"
	},
	{
			"value": "WOOD-CHUNKY",
			"label": "WOOD-CHUNKY"
	},
	{
			"value": "WOOD KIT",
			"label": "WOOD KIT"
	},
	{
			"value": "WOODLAND",
			"label": "WOODLAND"
	},
	{
			"value": "WOOD-LASER",
			"label": "WOOD-LASER"
	},
	{
			"value": "WOODRO",
			"label": "WOODRO"
	},
	{
			"value": "WOODROSE",
			"label": "WOODROSE"
	},
	{
			"value": "WOOD WICK PANEL 7",
			"label": "WOOD WICK PANEL 7"
	},
	{
			"value": "WORD",
			"label": "WORD"
	},
	{
			"value": "WORDING",
			"label": "WORDING"
	},
	{
			"value": "WORDS",
			"label": "WORDS"
	},
	{
			"value": "WORLD",
			"label": "WORLD"
	},
	{
			"value": "WREATH BUILDING",
			"label": "WREATH BUILDING"
	},
	{
			"value": "WREATHS",
			"label": "WREATHS"
	},
	{
			"value": "WREN",
			"label": "WREN"
	},
	{
			"value": "WRITING",
			"label": "WRITING"
	},
	{
			"value": "XMAS GREEN",
			"label": "XMAS GREEN"
	},
	{
			"value": "XMAS RED",
			"label": "XMAS RED"
	},
	{
			"value": "XXX",
			"label": "XXX"
	},
	{
			"value": "Y5 (18-20-22-24-26)",
			"label": "Y5 (18-20-22-24-26)"
	},
	{
			"value": "YALE",
			"label": "YALE"
	},
	{
			"value": "YARD STAKES",
			"label": "YARD STAKES"
	},
	{
			"value": "YEL",
			"label": "YEL"
	},
	{
			"value": "YELLLOW",
			"label": "YELLLOW"
	},
	{
			"value": "YELLO DK",
			"label": "YELLO DK"
	},
	{
			"value": "YELLOW",
			"label": "YELLOW"
	},
	{
			"value": "YELLOW/BLACK",
			"label": "YELLOW/BLACK"
	},
	{
			"value": "YELLOW DARK",
			"label": "YELLOW DARK"
	},
	{
			"value": "YELLOW DK",
			"label": "YELLOW DK"
	},
	{
			"value": "YELLOW/DK GREEN",
			"label": "YELLOW/DK GREEN"
	},
	{
			"value": "YELLOW/GOLD",
			"label": "YELLOW/GOLD"
	},
	{
			"value": "YELLOW LIGHT",
			"label": "YELLOW LIGHT"
	},
	{
			"value": "YELLOW LT",
			"label": "YELLOW LT"
	},
	{
			"value": "YELLOW NEON",
			"label": "YELLOW NEON"
	},
	{
			"value": "YELLOW PURPLE",
			"label": "YELLOW PURPLE"
	},
	{
			"value": "YELLOWS",
			"label": "YELLOWS"
	},
	{
			"value": "YELLOW TINT",
			"label": "YELLOW TINT"
	},
	{
			"value": "YELOW",
			"label": "YELOW"
	},
	{
			"value": "YLW",
			"label": "YLW"
	},
	{
			"value": "YLW BRN",
			"label": "YLW BRN"
	},
	{
			"value": "YLW BRY",
			"label": "YLW BRY"
	},
	{
			"value": "YLW/MXD",
			"label": "YLW/MXD"
	},
	{
			"value": "YUKON GOLD",
			"label": "YUKON GOLD"
	},
	{
			"value": "ZEBRA",
			"label": "ZEBRA"
	},
	{
			"value": "ZEN",
			"label": "ZEN"
	},
	{
			"value": "ZEPHYR",
			"label": "ZEPHYR"
	},
	{
			"value": "ZINC",
			"label": "ZINC"
	},
	{
			"value": "ZINNIA DAHLIA PANEL",
			"label": "ZINNIA DAHLIA PANEL"
	}
]

const ATTRIBUTE_SIZE_OPTIONS = [
	// {
	// 		"value": ".",
	// 		"label": "."
	// },
	{
			"value": "0",
			"label": "0"
	},
	{
			"value": "001",
			"label": "001"
	},
	{
			"value": "002",
			"label": "002"
	},
	{
			"value": "003",
			"label": "003"
	},
	{
			"value": "004",
			"label": "004"
	},
	{
			"value": "005",
			"label": "005"
	},
	{
			"value": "006",
			"label": "006"
	},
	{
			"value": "0.0625",
			"label": "0.0625"
	},
	{
			"value": "007",
			"label": "007"
	},
	{
			"value": "0070896143808",
			"label": "0070896143808"
	},
	{
			"value": "008",
			"label": "008"
	},
	{
			"value": "009",
			"label": "009"
	},
	{
			"value": "010",
			"label": "010"
	},
	{
			"value": "012",
			"label": "012"
	},
	{
			"value": "0.125",
			"label": "0.125"
	},
	{
			"value": "0.12IN X 4 YDS",
			"label": "0.12IN X 4 YDS"
	},
	{
			"value": "013",
			"label": "013"
	},
	{
			"value": "014",
			"label": "014"
	},
	{
			"value": "015",
			"label": "015"
	},
	{
			"value": "018",
			"label": "018"
	},
	{
			"value": "0.1875IN",
			"label": "0.1875IN"
	},
	{
			"value": "0.18 FL OZ PER TUBE",
			"label": "0.18 FL OZ PER TUBE"
	},
	{
			"value": "019",
			"label": "019"
	},
	{
			"value": ".01 OZ",
			"label": ".01 OZ"
	},
	{
			"value": "0.1OZ",
			"label": "0.1OZ"
	},
	{
			"value": ".02",
			"label": ".02"
	},
	{
			"value": "021",
			"label": "021"
	},
	{
			"value": "0.23",
			"label": "0.23"
	},
	{
			"value": "024",
			"label": "024"
	},
	{
			"value": "0.24IN X 4 YDS",
			"label": "0.24IN X 4 YDS"
	},
	{
			"value": "0.25",
			"label": "0.25"
	},
	{
			"value": "0.25IN",
			"label": "0.25IN"
	},
	{
			"value": "0.25 INCH EACH",
			"label": "0.25 INCH EACH"
	},
	{
			"value": "0.25MM",
			"label": "0.25MM"
	},
	{
			"value": "026",
			"label": "026"
	},
	{
			"value": "027",
			"label": "027"
	},
	{
			"value": "028",
			"label": "028"
	},
	{
			"value": "0.28IN X 4 YDS",
			"label": "0.28IN X 4 YDS"
	},
	{
			"value": "0.28 OZ",
			"label": "0.28 OZ"
	},
	{
			"value": "029",
			"label": "029"
	},
	{
			"value": "0.2 X 5.1 X 8.75",
			"label": "0.2 X 5.1 X 8.75"
	},
	{
			"value": "0.30",
			"label": "0.30"
	},
	{
			"value": "0.3125",
			"label": "0.3125"
	},
	{
			"value": "0.31IN X 4 YDS",
			"label": "0.31IN X 4 YDS"
	},
	{
			"value": "032",
			"label": "032"
	},
	{
			"value": "0.34",
			"label": "0.34"
	},
	{
			"value": "034",
			"label": "034"
	},
	{
			"value": "0.35IN X 4 YDS",
			"label": "0.35IN X 4 YDS"
	},
	{
			"value": "036",
			"label": "036"
	},
	{
			"value": "0.375",
			"label": "0.375"
	},
	{
			"value": "0.375IN",
			"label": "0.375IN"
	},
	{
			"value": "0.375   W X 72   L",
			"label": "0.375   W X 72   L"
	},
	{
			"value": ".038",
			"label": ".038"
	},
	{
			"value": "0.39IN X 4 YDS",
			"label": "0.39IN X 4 YDS"
	},
	{
			"value": "0-3 MONTH",
			"label": "0-3 MONTH"
	},
	{
			"value": ".03 OZ",
			"label": ".03 OZ"
	},
	{
			"value": "043",
			"label": "043"
	},
	{
			"value": "0.43IN X 4 YDS",
			"label": "0.43IN X 4 YDS"
	},
	{
			"value": "0.46",
			"label": "0.46"
	},
	{
			"value": "0.47IN X 4 YDS",
			"label": "0.47IN X 4 YDS"
	},
	{
			"value": ".04OZ",
			"label": ".04OZ"
	},
	{
			"value": ".05",
			"label": ".05"
	},
	{
			"value": "0.5",
			"label": "0.5"
	},
	{
			"value": "0.51IN X 4 YDS",
			"label": "0.51IN X 4 YDS"
	},
	{
			"value": "056",
			"label": "056"
	},
	{
			"value": "0.58",
			"label": "0.58"
	},
	{
			"value": "059",
			"label": "059"
	},
	{
			"value": "0.5IN",
			"label": "0.5IN"
	},
	{
			"value": ".05 OZ",
			"label": ".05 OZ"
	},
	{
			"value": ".05OZ",
			"label": ".05OZ"
	},
	{
			"value": "0.5 OZ / 3PK",
			"label": "0.5 OZ / 3PK"
	},
	{
			"value": "0.5  X3YDS",
			"label": "0.5  X3YDS"
	},
	{
			"value": "0.60 OZ",
			"label": "0.60 OZ"
	},
	{
			"value": "061",
			"label": "061"
	},
	{
			"value": "0.625",
			"label": "0.625"
	},
	{
			"value": "0.625IN",
			"label": "0.625IN"
	},
	{
			"value": "0.62 FL OZ",
			"label": "0.62 FL OZ"
	},
	{
			"value": "0.62''X11''X9.25''",
			"label": "0.62''X11''X9.25''"
	},
	{
			"value": ".063",
			"label": ".063"
	},
	{
			"value": "0.63",
			"label": "0.63"
	},
	{
			"value": "0.64OZ",
			"label": "0.64OZ"
	},
	{
			"value": "065",
			"label": "065"
	},
	{
			"value": " 0-6, 6-12 & 12 - 18",
			"label": " 0-6, 6-12 & 12 - 18"
	},
	{
			"value": "0-6M",
			"label": "0-6M"
	},
	{
			"value": "0 - 6 MONTHS",
			"label": "0 - 6 MONTHS"
	},
	{
			"value": ".06 OZ",
			"label": ".06 OZ"
	},
	{
			"value": "073",
			"label": "073"
	},
	{
			"value": "0.75",
			"label": "0.75"
	},
	{
			"value": "075",
			"label": "075"
	},
	{
			"value": "0.75IN",
			"label": "0.75IN"
	},
	{
			"value": "0.75 INCH",
			"label": "0.75 INCH"
	},
	{
			"value": ".075 OZ",
			"label": ".075 OZ"
	},
	{
			"value": "0.75   X 2 YARDS",
			"label": "0.75   X 2 YARDS"
	},
	{
			"value": "0.75   X 3.5   X 24",
			"label": "0.75   X 3.5   X 24"
	},
	{
			"value": "0.75   X 5.5   X 24",
			"label": "0.75   X 5.5   X 24"
	},
	{
			"value": "0.75   X 8 YARD",
			"label": "0.75   X 8 YARD"
	},
	{
			"value": "0.7MM TIP",
			"label": "0.7MM TIP"
	},
	{
			"value": "0.85",
			"label": "0.85"
	},
	{
			"value": "0.875",
			"label": "0.875"
	},
	{
			"value": "0.875IN",
			"label": "0.875IN"
	},
	{
			"value": "0.88",
			"label": "0.88"
	},
	{
			"value": "0.905IN X 6YD",
			"label": "0.905IN X 6YD"
	},
	{
			"value": "0.94 IN X 60.1 YD",
			"label": "0.94 IN X 60.1 YD"
	},
	{
			"value": "099",
			"label": "099"
	},
	{
			"value": "0.9 FL. OZ.",
			"label": "0.9 FL. OZ."
	},
	{
			"value": "0.9 OZ.",
			"label": "0.9 OZ."
	},
	{
			"value": "0B1",
			"label": "0B1"
	},
	{
			"value": "0B3",
			"label": "0B3"
	},
	{
			"value": "0B7",
			"label": "0B7"
	},
	{
			"value": "1",
			"label": "1"
	},
	{
			"value": "1\"\"",
			"label": "1\"\""
	},
	{
			"value": "10",
			"label": "10"
	},
	{
			"value": "10\"\"",
			"label": "10\"\""
	},
	{
			"value": "10''",
			"label": "10''"
	},
	{
			"value": "100",
			"label": "100"
	},
	{
			"value": "1000",
			"label": "1000"
	},
	{
			"value": "10000",
			"label": "10000"
	},
	{
			"value": "100000",
			"label": "100000"
	},
	{
			"value": "1000 CT BAG",
			"label": "1000 CT BAG"
	},
	{
			"value": "1000GR",
			"label": "1000GR"
	},
	{
			"value": "1000 ML",
			"label": "1000 ML"
	},
	{
			"value": "10.00 X 4.75",
			"label": "10.00 X 4.75"
	},
	{
			"value": "10.00 X 5.43",
			"label": "10.00 X 5.43"
	},
	{
			"value": "10.00 X 7.25",
			"label": "10.00 X 7.25"
	},
	{
			"value": "10.00 X 7.50",
			"label": "10.00 X 7.50"
	},
	{
			"value": "10.00 X 8.00",
			"label": "10.00 X 8.00"
	},
	{
			"value": "1002",
			"label": "1002"
	},
	{
			"value": "10032",
			"label": "10032"
	},
	{
			"value": "10.03 X 8.66",
			"label": "10.03 X 8.66"
	},
	{
			"value": "10044",
			"label": "10044"
	},
	{
			"value": "10.04IN",
			"label": "10.04IN"
	},
	{
			"value": "10.04 X 3.34",
			"label": "10.04 X 3.34"
	},
	{
			"value": "10.05 OZ",
			"label": "10.05 OZ"
	},
	{
			"value": "10.06 X 4.75",
			"label": "10.06 X 4.75"
	},
	{
			"value": "1008",
			"label": "1008"
	},
	{
			"value": "10080",
			"label": "10080"
	},
	{
			"value": "100CT",
			"label": "100CT"
	},
	{
			"value": "100 FOOT",
			"label": "100 FOOT"
	},
	{
			"value": "100GM",
			"label": "100GM"
	},
	{
			"value": "100 GR",
			"label": "100 GR"
	},
	{
			"value": "100GR",
			"label": "100GR"
	},
	{
			"value": "100 GRAM",
			"label": "100 GRAM"
	},
	{
			"value": "100GRAMS",
			"label": "100GRAMS"
	},
	{
			"value": "100IN",
			"label": "100IN"
	},
	{
			"value": "100LB",
			"label": "100LB"
	},
	{
			"value": "100M",
			"label": "100M"
	},
	{
			"value": "100ML",
			"label": "100ML"
	},
	{
			"value": "100MM",
			"label": "100MM"
	},
	{
			"value": "1.00OZ",
			"label": "1.00OZ"
	},
	{
			"value": "100 PC",
			"label": "100 PC"
	},
	{
			"value": "100PC",
			"label": "100PC"
	},
	{
			"value": "100PK",
			"label": "100PK"
	},
	{
			"value": "100 X 110",
			"label": "100 X 110"
	},
	{
			"value": "100   X 110",
			"label": "100   X 110"
	},
	{
			"value": "100  X116",
			"label": "100  X116"
	},
	{
			"value": "100  X118",
			"label": "100  X118"
	},
	{
			"value": "100 X 14",
			"label": "100 X 14"
	},
	{
			"value": "100 X 14 X 27",
			"label": "100 X 14 X 27"
	},
	{
			"value": "100 X 15 X 21",
			"label": "100 X 15 X 21"
	},
	{
			"value": "100 X 16",
			"label": "100 X 16"
	},
	{
			"value": "100 X 18",
			"label": "100 X 18"
	},
	{
			"value": "100 X 19.5",
			"label": "100 X 19.5"
	},
	{
			"value": "100 X 24 LP X 12.5 S",
			"label": "100 X 24 LP X 12.5 S"
	},
	{
			"value": "100 X 24 LP X 18.5 C",
			"label": "100 X 24 LP X 18.5 C"
	},
	{
			"value": "100   X 54",
			"label": "100   X 54"
	},
	{
			"value": "100   X 63",
			"label": "100   X 63"
	},
	{
			"value": "100   X 72",
			"label": "100   X 72"
	},
	{
			"value": "100   X 84",
			"label": "100   X 84"
	},
	{
			"value": "100   X 96",
			"label": "100   X 96"
	},
	{
			"value": "100X98",
			"label": "100X98"
	},
	{
			"value": "100 YARD SPOOL",
			"label": "100 YARD SPOOL"
	},
	{
			"value": "100 YD",
			"label": "100 YD"
	},
	{
			"value": "100YD",
			"label": "100YD"
	},
	{
			"value": "100YDS",
			"label": "100YDS"
	},
	{
			"value": "10-12",
			"label": "10-12"
	},
	{
			"value": "10-13",
			"label": "10-13"
	},
	{
			"value": "10-14",
			"label": "10-14"
	},
	{
			"value": " 10,18",
			"label": " 10,18"
	},
	{
			"value": "101CT",
			"label": "101CT"
	},
	{
			"value": "10.1 OZ",
			"label": "10.1 OZ"
	},
	{
			"value": "1020",
			"label": "1020"
	},
	{
			"value": "1.023IN X 6YD",
			"label": "1.023IN X 6YD"
	},
	{
			"value": "1024",
			"label": "1024"
	},
	{
			"value": "10.24IN",
			"label": "10.24IN"
	},
	{
			"value": "1.024IN X 6YD",
			"label": "1.024IN X 6YD"
	},
	{
			"value": "10.24 X 3.54 X 1.38",
			"label": "10.24 X 3.54 X 1.38"
	},
	{
			"value": "10.25",
			"label": "10.25"
	},
	{
			"value": "10.25 IN",
			"label": "10.25 IN"
	},
	{
			"value": "10.25OZ",
			"label": "10.25OZ"
	},
	{
			"value": "10.25''X10.75''X2''",
			"label": "10.25''X10.75''X2''"
	},
	{
			"value": "10.25X12.25",
			"label": "10.25X12.25"
	},
	{
			"value": "10.25X13.5",
			"label": "10.25X13.5"
	},
	{
			"value": "10.25 X 2.25",
			"label": "10.25 X 2.25"
	},
	{
			"value": "10.25X27X.075",
			"label": "10.25X27X.075"
	},
	{
			"value": "10.25 X 3.25 X 9.625",
			"label": "10.25 X 3.25 X 9.625"
	},
	{
			"value": "10.25 X .375 X 10.25",
			"label": "10.25 X .375 X 10.25"
	},
	{
			"value": "10.25 X 3 X 13.5",
			"label": "10.25 X 3 X 13.5"
	},
	{
			"value": "10.25   X 3   X 3.7",
			"label": "10.25   X 3   X 3.7"
	},
	{
			"value": "10.25X5.3X2.5",
			"label": "10.25X5.3X2.5"
	},
	{
			"value": "10.25 X 5.5 X .25",
			"label": "10.25 X 5.5 X .25"
	},
	{
			"value": "10.25 X 7.50",
			"label": "10.25 X 7.50"
	},
	{
			"value": "10.25 X 7.5 X 7.125",
			"label": "10.25 X 7.5 X 7.125"
	},
	{
			"value": "10.25''X7''X1.13''",
			"label": "10.25''X7''X1.13''"
	},
	{
			"value": "10.25  X8.75   X 2.",
			"label": "10.25  X8.75   X 2."
	},
	{
			"value": "10.25  X8.75   X 3",
			"label": "10.25  X8.75   X 3"
	},
	{
			"value": "10.2''LX8.3''WX12.2'",
			"label": "10.2''LX8.3''WX12.2'"
	},
	{
			"value": "10.2OZ",
			"label": "10.2OZ"
	},
	{
			"value": "102  X121",
			"label": "102  X121"
	},
	{
			"value": "102 X 18",
			"label": "102 X 18"
	},
	{
			"value": "10.2X20.2CM(WITHOUT",
			"label": "10.2X20.2CM(WITHOUT"
	},
	{
			"value": "102   X 30",
			"label": "102   X 30"
	},
	{
			"value": "102 X 60",
			"label": "102 X 60"
	},
	{
			"value": "10.2X6.8X5.9",
			"label": "10.2X6.8X5.9"
	},
	{
			"value": "102 X 84",
			"label": "102 X 84"
	},
	{
			"value": "103",
			"label": "103"
	},
	{
			"value": "1032",
			"label": "1032"
	},
	{
			"value": "10.375 X 10.375 X 7.",
			"label": "10.375 X 10.375 X 7."
	},
	{
			"value": "10.37 X 4.62",
			"label": "10.37 X 4.62"
	},
	{
			"value": "10.37 X 7.00",
			"label": "10.37 X 7.00"
	},
	{
			"value": "10.3OZ",
			"label": "10.3OZ"
	},
	{
			"value": "10.40 X 10.20",
			"label": "10.40 X 10.20"
	},
	{
			"value": "1044",
			"label": "1044"
	},
	{
			"value": "104 X 18",
			"label": "104 X 18"
	},
	{
			"value": "104 X 84",
			"label": "104 X 84"
	},
	{
			"value": "10.5",
			"label": "10.5"
	},
	{
			"value": "105",
			"label": "105"
	},
	{
			"value": "1050",
			"label": "1050"
	},
	{
			"value": "10500",
			"label": "10500"
	},
	{
			"value": "10.50 X 4.62",
			"label": "10.50 X 4.62"
	},
	{
			"value": "10.50 X 5.50",
			"label": "10.50 X 5.50"
	},
	{
			"value": "10.50 X 6.50",
			"label": "10.50 X 6.50"
	},
	{
			"value": "10.50 X 7.37",
			"label": "10.50 X 7.37"
	},
	{
			"value": "10.50 X 7.50",
			"label": "10.50 X 7.50"
	},
	{
			"value": "10.50 X 7.62",
			"label": "10.50 X 7.62"
	},
	{
			"value": "10.50 X 7.75",
			"label": "10.50 X 7.75"
	},
	{
			"value": "10.50 X 8.00",
			"label": "10.50 X 8.00"
	},
	{
			"value": "10.50 X 8.25",
			"label": "10.50 X 8.25"
	},
	{
			"value": "(10.5-12.5-14.5-16.5",
			"label": "(10.5-12.5-14.5-16.5"
	},
	{
			"value": "10.54 OZ",
			"label": "10.54 OZ"
	},
	{
			"value": "10.55 X 4.62",
			"label": "10.55 X 4.62"
	},
	{
			"value": "10.55 X 7.75",
			"label": "10.55 X 7.75"
	},
	{
			"value": "10560",
			"label": "10560"
	},
	{
			"value": "10.58 OZ",
			"label": "10.58 OZ"
	},
	{
			"value": "10.5 IN",
			"label": "10.5 IN"
	},
	{
			"value": "10.5IN",
			"label": "10.5IN"
	},
	{
			"value": "10.5 INCH",
			"label": "10.5 INCH"
	},
	{
			"value": "1.05 OZ",
			"label": "1.05 OZ"
	},
	{
			"value": "10.5 OZ",
			"label": "10.5 OZ"
	},
	{
			"value": "10.5OZ/300GM",
			"label": "10.5OZ/300GM"
	},
	{
			"value": "10.5 OZ/ 300 GRAMS",
			"label": "10.5 OZ/ 300 GRAMS"
	},
	{
			"value": "10.5  X12",
			"label": "10.5  X12"
	},
	{
			"value": "10.5X12.5X6",
			"label": "10.5X12.5X6"
	},
	{
			"value": "10.5X1X7.875",
			"label": "10.5X1X7.875"
	},
	{
			"value": "10.5X35.88X1.88",
			"label": "10.5X35.88X1.88"
	},
	{
			"value": "10.5 X 3.5 X 7.75",
			"label": "10.5 X 3.5 X 7.75"
	},
	{
			"value": "10.5   X 3 X 3.75",
			"label": "10.5   X 3 X 3.75"
	},
	{
			"value": "10.5X4.25X20",
			"label": "10.5X4.25X20"
	},
	{
			"value": "10.5   X 5.5   X 4.",
			"label": "10.5   X 5.5   X 4."
	},
	{
			"value": "10.5 X 7.25",
			"label": "10.5 X 7.25"
	},
	{
			"value": "10.5''X7.25''",
			"label": "10.5''X7.25''"
	},
	{
			"value": "10.5 X 7.25 X 29.5",
			"label": "10.5 X 7.25 X 29.5"
	},
	{
			"value": "10.5X7.5",
			"label": "10.5X7.5"
	},
	{
			"value": "10.5 X 7.9",
			"label": "10.5 X 7.9"
	},
	{
			"value": "10.5   X 8",
			"label": "10.5   X 8"
	},
	{
			"value": "10.5 X 9",
			"label": "10.5 X 9"
	},
	{
			"value": " 10,6",
			"label": " 10,6"
	},
	{
			"value": "1.06",
			"label": "1.06"
	},
	{
			"value": "106",
			"label": "106"
	},
	{
			"value": "1060",
			"label": "1060"
	},
	{
			"value": "10.625 X 7.25",
			"label": "10.625 X 7.25"
	},
	{
			"value": "10.625 X 7.50",
			"label": "10.625 X 7.50"
	},
	{
			"value": "10.62   X 4.33   X",
			"label": "10.62   X 4.33   X"
	},
	{
			"value": "10.62 X 4.625",
			"label": "10.62 X 4.625"
	},
	{
			"value": "10.62 X 7.37",
			"label": "10.62 X 7.37"
	},
	{
			"value": "10.62 X 7.50",
			"label": "10.62 X 7.50"
	},
	{
			"value": "10.62 X 7.62",
			"label": "10.62 X 7.62"
	},
	{
			"value": "10.63X10.63X13",
			"label": "10.63X10.63X13"
	},
	{
			"value": "10.63 X 3.38 X .75",
			"label": "10.63 X 3.38 X .75"
	},
	{
			"value": "1064",
			"label": "1064"
	},
	{
			"value": "10656",
			"label": "10656"
	},
	{
			"value": "10.6IN",
			"label": "10.6IN"
	},
	{
			"value": "1.06OZ",
			"label": "1.06OZ"
	},
	{
			"value": "10.6 OZ",
			"label": "10.6 OZ"
	},
	{
			"value": "106 X 15",
			"label": "106 X 15"
	},
	{
			"value": "10.6X6.6X5.9",
			"label": "10.6X6.6X5.9"
	},
	{
			"value": "10.6 X 7.87 X 0.78",
			"label": "10.6 X 7.87 X 0.78"
	},
	{
			"value": "10.6   X 8.3   X 4.",
			"label": "10.6   X 8.3   X 4."
	},
	{
			"value": "106X94",
			"label": "106X94"
	},
	{
			"value": "106  X94",
			"label": "106  X94"
	},
	{
			"value": "1070",
			"label": "1070"
	},
	{
			"value": "10.75",
			"label": "10.75"
	},
	{
			"value": "10.75IN",
			"label": "10.75IN"
	},
	{
			"value": "10.75 OZ",
			"label": "10.75 OZ"
	},
	{
			"value": "10.75 X 10.75 X 9 IN",
			"label": "10.75 X 10.75 X 9 IN"
	},
	{
			"value": "10.75X14",
			"label": "10.75X14"
	},
	{
			"value": "10.75 X 1.75",
			"label": "10.75 X 1.75"
	},
	{
			"value": "10.75 X 2.75 X 8.5",
			"label": "10.75 X 2.75 X 8.5"
	},
	{
			"value": "10.75 X 5.87",
			"label": "10.75 X 5.87"
	},
	{
			"value": "10.75X6.5X1.75",
			"label": "10.75X6.5X1.75"
	},
	{
			"value": "10.75 X 6.62",
			"label": "10.75 X 6.62"
	},
	{
			"value": "10.75X7.25X8.5",
			"label": "10.75X7.25X8.5"
	},
	{
			"value": "10.75 X 7.375 X 1.75",
			"label": "10.75 X 7.375 X 1.75"
	},
	{
			"value": "10.75 X 7.50",
			"label": "10.75 X 7.50"
	},
	{
			"value": "10.75X7.75",
			"label": "10.75X7.75"
	},
	{
			"value": "10.75 X 7.87",
			"label": "10.75 X 7.87"
	},
	{
			"value": "10.75 X 9.12",
			"label": "10.75 X 9.12"
	},
	{
			"value": "10.75 X 9.87",
			"label": "10.75 X 9.87"
	},
	{
			"value": "10.78 OZ",
			"label": "10.78 OZ"
	},
	{
			"value": "10.7X7.75",
			"label": "10.7X7.75"
	},
	{
			"value": "108",
			"label": "108"
	},
	{
			"value": "108''",
			"label": "108''"
	},
	{
			"value": "1080",
			"label": "1080"
	},
	{
			"value": "10800",
			"label": "10800"
	},
	{
			"value": "10.81 X 10.25",
			"label": "10.81 X 10.25"
	},
	{
			"value": "10.81 X 5.50",
			"label": "10.81 X 5.50"
	},
	{
			"value": "10.81 X 8.37",
			"label": "10.81 X 8.37"
	},
	{
			"value": "10.81 X 8.75",
			"label": "10.81 X 8.75"
	},
	{
			"value": "10.83 X 8.67",
			"label": "10.83 X 8.67"
	},
	{
			"value": "108.5 CUBIC INCHES",
			"label": "108.5 CUBIC INCHES"
	},
	{
			"value": " 10,8,6",
			"label": " 10,8,6"
	},
	{
			"value": "10.87''X10''X2.38''",
			"label": "10.87''X10''X2.38''"
	},
	{
			"value": "10.87 X 5.80",
			"label": "10.87 X 5.80"
	},
	{
			"value": "10.87 X 5.87",
			"label": "10.87 X 5.87"
	},
	{
			"value": "10.87 X 6.00",
			"label": "10.87 X 6.00"
	},
	{
			"value": "10.87 X 7.00",
			"label": "10.87 X 7.00"
	},
	{
			"value": "10.87 X 7.62",
			"label": "10.87 X 7.62"
	},
	{
			"value": "10.88 X 5.88",
			"label": "10.88 X 5.88"
	},
	{
			"value": "10.88X8.5X.13",
			"label": "10.88X8.5X.13"
	},
	{
			"value": "108IN",
			"label": "108IN"
	},
	{
			"value": "108 INCH (EXTENDED)",
			"label": "108 INCH (EXTENDED)"
	},
	{
			"value": "10.8 OZ",
			"label": "10.8 OZ"
	},
	{
			"value": "108 X 110",
			"label": "108 X 110"
	},
	{
			"value": "108 X 84",
			"label": "108 X 84"
	},
	{
			"value": "108 X 96",
			"label": "108 X 96"
	},
	{
			"value": "10.90 X 5.74",
			"label": "10.90 X 5.74"
	},
	{
			"value": "10.94 X 8.5",
			"label": "10.94 X 8.5"
	},
	{
			"value": "10.94 X 8.50",
			"label": "10.94 X 8.50"
	},
	{
			"value": "1.09 OZ",
			"label": "1.09 OZ"
	},
	{
			"value": "10.9 OZ",
			"label": "10.9 OZ"
	},
	{
			"value": "10.9 X 4 X 1.4H",
			"label": "10.9 X 4 X 1.4H"
	},
	{
			"value": "10 COUNT",
			"label": "10 COUNT"
	},
	{
			"value": "10CT",
			"label": "10CT"
	},
	{
			"value": "10 DRAWER",
			"label": "10 DRAWER"
	},
	{
			"value": "10 DRAWER ROLLING",
			"label": "10 DRAWER ROLLING"
	},
	{
			"value": "10 GAL",
			"label": "10 GAL"
	},
	{
			"value": "10 GALLON",
			"label": "10 GALLON"
	},
	{
			"value": "10  H X 7.5  W",
			"label": "10  H X 7.5  W"
	},
	{
			"value": "10 IN",
			"label": "10 IN"
	},
	{
			"value": "10IN",
			"label": "10IN"
	},
	{
			"value": "10 IN 25 CT",
			"label": "10 IN 25 CT"
	},
	{
			"value": "10IN 2PK",
			"label": "10IN 2PK"
	},
	{
			"value": "10 INCH",
			"label": "10 INCH"
	},
	{
			"value": "10 IN SZ10",
			"label": "10 IN SZ10"
	},
	{
			"value": "10 IN SZ10.5",
			"label": "10 IN SZ10.5"
	},
	{
			"value": "10 IN SZ5",
			"label": "10 IN SZ5"
	},
	{
			"value": "10 IN SZ6",
			"label": "10 IN SZ6"
	},
	{
			"value": "10 IN SZ7",
			"label": "10 IN SZ7"
	},
	{
			"value": "10 IN SZ8",
			"label": "10 IN SZ8"
	},
	{
			"value": "10 IN SZ9",
			"label": "10 IN SZ9"
	},
	{
			"value": "10INX1IN",
			"label": "10INX1IN"
	},
	{
			"value": "10L",
			"label": "10L"
	},
	{
			"value": "10 LB",
			"label": "10 LB"
	},
	{
			"value": "10LB",
			"label": "10LB"
	},
	{
			"value": "10 LITER",
			"label": "10 LITER"
	},
	{
			"value": "10  L X 25.5  W X 1",
			"label": "10  L X 25.5  W X 1"
	},
	{
			"value": "10  LX3.5  WX4  H",
			"label": "10  LX3.5  WX4  H"
	},
	{
			"value": "10M",
			"label": "10M"
	},
	{
			"value": "10 ML",
			"label": "10 ML"
	},
	{
			"value": "10ML",
			"label": "10ML"
	},
	{
			"value": "10MM",
			"label": "10MM"
	},
	{
			"value": "10MM DIAMETER, 60.9",
			"label": "10MM DIAMETER, 60.9"
	},
	{
			"value": "10MM DIAM. X 24   L",
			"label": "10MM DIAM. X 24   L"
	},
	{
			"value": "10MM X 3FT",
			"label": "10MM X 3FT"
	},
	{
			"value": "10 OUNCE CAN",
			"label": "10 OUNCE CAN"
	},
	{
			"value": "10 OZ",
			"label": "10 OZ"
	},
	{
			"value": "10 OZ.",
			"label": "10 OZ."
	},
	{
			"value": "10OZ",
			"label": "10OZ"
	},
	{
			"value": "1.0OZ",
			"label": "1.0OZ"
	},
	{
			"value": "10 OZ/283 GRAMS",
			"label": "10 OZ/283 GRAMS"
	},
	{
			"value": "10OZ/284 GRAMS",
			"label": "10OZ/284 GRAMS"
	},
	{
			"value": "10OZ X 96  X9YDS",
			"label": "10OZ X 96  X9YDS"
	},
	{
			"value": "10 PAIR",
			"label": "10 PAIR"
	},
	{
			"value": "10 PC",
			"label": "10 PC"
	},
	{
			"value": "10PC",
			"label": "10PC"
	},
	{
			"value": "10 PCS - 18  X21",
			"label": "10 PCS - 18  X21"
	},
	{
			"value": "10PK",
			"label": "10PK"
	},
	{
			"value": "10   ROUND",
			"label": "10   ROUND"
	},
	{
			"value": "10 SCALLOP",
			"label": "10 SCALLOP"
	},
	{
			"value": " 10 STICK, 1/2 OZ. E",
			"label": " 10 STICK, 1/2 OZ. E"
	},
	{
			"value": "10  W X 12.25  H X",
			"label": "10  W X 12.25  H X"
	},
	{
			"value": "10X0.125X13",
			"label": "10X0.125X13"
	},
	{
			"value": "10' X 0.75",
			"label": "10' X 0.75"
	},
	{
			"value": "10 X 10",
			"label": "10 X 10"
	},
	{
			"value": "10X10",
			"label": "10X10"
	},
	{
			"value": "10    X 10",
			"label": "10    X 10"
	},
	{
			"value": "10   X 10",
			"label": "10   X 10"
	},
	{
			"value": "10  X10",
			"label": "10  X10"
	},
	{
			"value": "10''X10''",
			"label": "10''X10''"
	},
	{
			"value": "10X10 MAT 5X5",
			"label": "10X10 MAT 5X5"
	},
	{
			"value": "10   X10 ROUND",
			"label": "10   X10 ROUND"
	},
	{
			"value": "10X10X10.25",
			"label": "10X10X10.25"
	},
	{
			"value": "10''X10''X2''",
			"label": "10''X10''X2''"
	},
	{
			"value": "10X10X3.5",
			"label": "10X10X3.5"
	},
	{
			"value": "10 X 10 X 6.75 IN",
			"label": "10 X 10 X 6.75 IN"
	},
	{
			"value": "10 X 11",
			"label": "10 X 11"
	},
	{
			"value": "10   X 11",
			"label": "10   X 11"
	},
	{
			"value": "10X11.5",
			"label": "10X11.5"
	},
	{
			"value": "10X13",
			"label": "10X13"
	},
	{
			"value": "10''X13''",
			"label": "10''X13''"
	},
	{
			"value": "10X13 2PK",
			"label": "10X13 2PK"
	},
	{
			"value": "10X13 BLACK FRAME",
			"label": "10X13 BLACK FRAME"
	},
	{
			"value": "10   X 13   X 2",
			"label": "10   X 13   X 2"
	},
	{
			"value": "10X14",
			"label": "10X14"
	},
	{
			"value": "10X15",
			"label": "10X15"
	},
	{
			"value": "10   X 15",
			"label": "10   X 15"
	},
	{
			"value": "10X16",
			"label": "10X16"
	},
	{
			"value": "10X1.75X10",
			"label": "10X1.75X10"
	},
	{
			"value": "10X1.7X12",
			"label": "10X1.7X12"
	},
	{
			"value": "10X18",
			"label": "10X18"
	},
	{
			"value": "10X1.875X10",
			"label": "10X1.875X10"
	},
	{
			"value": "10X18X.75",
			"label": "10X18X.75"
	},
	{
			"value": "10X19",
			"label": "10X19"
	},
	{
			"value": "10X2",
			"label": "10X2"
	},
	{
			"value": "10X20",
			"label": "10X20"
	},
	{
			"value": "10X20 TO 3 4X6 COLLA",
			"label": "10X20 TO 3 4X6 COLLA"
	},
	{
			"value": "10X22",
			"label": "10X22"
	},
	{
			"value": "10X24",
			"label": "10X24"
	},
	{
			"value": "10X2X10",
			"label": "10X2X10"
	},
	{
			"value": "10X3.5",
			"label": "10X3.5"
	},
	{
			"value": "10 X 3.5 X 06",
			"label": "10 X 3.5 X 06"
	},
	{
			"value": "10 X 3.5 X 1.5",
			"label": "10 X 3.5 X 1.5"
	},
	{
			"value": "10X3.5X1.5",
			"label": "10X3.5X1.5"
	},
	{
			"value": "10X3.5X16",
			"label": "10X3.5X16"
	},
	{
			"value": "10 X 36",
			"label": "10 X 36"
	},
	{
			"value": "10X3X7",
			"label": "10X3X7"
	},
	{
			"value": "10 X 4",
			"label": "10 X 4"
	},
	{
			"value": "10X5",
			"label": "10X5"
	},
	{
			"value": "10 X 5.25",
			"label": "10 X 5.25"
	},
	{
			"value": "10X5.75",
			"label": "10X5.75"
	},
	{
			"value": "10X5X7",
			"label": "10X5X7"
	},
	{
			"value": "10X5X8.5",
			"label": "10X5X8.5"
	},
	{
			"value": "10X5X9",
			"label": "10X5X9"
	},
	{
			"value": "10   X 5 YARDS",
			"label": "10   X 5 YARDS"
	},
	{
			"value": "10X6",
			"label": "10X6"
	},
	{
			"value": "10   X 6",
			"label": "10   X 6"
	},
	{
			"value": "10 X 6-1/2 X 1-1/4 I",
			"label": "10 X 6-1/2 X 1-1/4 I"
	},
	{
			"value": "10X.625X10",
			"label": "10X.625X10"
	},
	{
			"value": "10''X6''X4''",
			"label": "10''X6''X4''"
	},
	{
			"value": "10X6X6.5",
			"label": "10X6X6.5"
	},
	{
			"value": "10 X 7",
			"label": "10 X 7"
	},
	{
			"value": "10X7",
			"label": "10X7"
	},
	{
			"value": "10 X 75ML TUBE SET",
			"label": "10 X 75ML TUBE SET"
	},
	{
			"value": "10   X 7.75   X 4.2",
			"label": "10   X 7.75   X 4.2"
	},
	{
			"value": "10 X 8",
			"label": "10 X 8"
	},
	{
			"value": "10X8",
			"label": "10X8"
	},
	{
			"value": "10X8.5X.10",
			"label": "10X8.5X.10"
	},
	{
			"value": "10X8.75X3",
			"label": "10X8.75X3"
	},
	{
			"value": "10   X 8.75   X 3",
			"label": "10   X 8.75   X 3"
	},
	{
			"value": "10  X8.75  X3",
			"label": "10  X8.75  X3"
	},
	{
			"value": "10   X 8.75   X 3.5",
			"label": "10   X 8.75   X 3.5"
	},
	{
			"value": "10  X8.8  X3",
			"label": "10  X8.8  X3"
	},
	{
			"value": "10X8X5",
			"label": "10X8X5"
	},
	{
			"value": "10X9",
			"label": "10X9"
	},
	{
			"value": "10   X 9   X .07",
			"label": "10   X 9   X .07"
	},
	{
			"value": "10 X 9 X 2",
			"label": "10 X 9 X 2"
	},
	{
			"value": "10 X 9 X 2.5",
			"label": "10 X 9 X 2.5"
	},
	{
			"value": "10 X 9 X 8",
			"label": "10 X 9 X 8"
	},
	{
			"value": "10 YARD",
			"label": "10 YARD"
	},
	{
			"value": "10 YARDS PER SPOOL",
			"label": "10 YARDS PER SPOOL"
	},
	{
			"value": "10YD",
			"label": "10YD"
	},
	{
			"value": "11",
			"label": "11"
	},
	{
			"value": "11\"\"",
			"label": "11\"\""
	},
	{
			"value": "110",
			"label": "110"
	},
	{
			"value": "110\"\"",
			"label": "110\"\""
	},
	{
			"value": "1100",
			"label": "1100"
	},
	{
			"value": "11000",
			"label": "11000"
	},
	{
			"value": "110000",
			"label": "110000"
	},
	{
			"value": "11.00 X 10.00",
			"label": "11.00 X 10.00"
	},
	{
			"value": "11.00 X 14.00",
			"label": "11.00 X 14.00"
	},
	{
			"value": "11.00 X 15.00",
			"label": "11.00 X 15.00"
	},
	{
			"value": "11.00 X 6.00",
			"label": "11.00 X 6.00"
	},
	{
			"value": "11.00 X 6.25",
			"label": "11.00 X 6.25"
	},
	{
			"value": "11.00 X 7.25",
			"label": "11.00 X 7.25"
	},
	{
			"value": "11.00 X 7.50 X .75",
			"label": "11.00 X 7.50 X .75"
	},
	{
			"value": "11.00 X 7.75",
			"label": "11.00 X 7.75"
	},
	{
			"value": "11.00 X 8.30",
			"label": "11.00 X 8.30"
	},
	{
			"value": "11.00 X 8.50",
			"label": "11.00 X 8.50"
	},
	{
			"value": "11.00 X 8.70",
			"label": "11.00 X 8.70"
	},
	{
			"value": "11.00 X 9.00",
			"label": "11.00 X 9.00"
	},
	{
			"value": "1100 YDS",
			"label": "1100 YDS"
	},
	{
			"value": "11.02''   9.45''   7",
			"label": "11.02''   9.45''   7"
	},
	{
			"value": "1.102IN X 6YD",
			"label": "1.102IN X 6YD"
	},
	{
			"value": "11.02 OZ",
			"label": "11.02 OZ"
	},
	{
			"value": "11.02 X 10.82",
			"label": "11.02 X 10.82"
	},
	{
			"value": "11.02 X 3.94 X 1.38",
			"label": "11.02 X 3.94 X 1.38"
	},
	{
			"value": "11040",
			"label": "11040"
	},
	{
			"value": "1108",
			"label": "1108"
	},
	{
			"value": "110V/120V",
			"label": "110V/120V"
	},
	{
			"value": "110   X 110",
			"label": "110   X 110"
	},
	{
			"value": "110 X 15",
			"label": "110 X 15"
	},
	{
			"value": "110 X 84",
			"label": "110 X 84"
	},
	{
			"value": "1-11/16",
			"label": "1-11/16"
	},
	{
			"value": "11 1/16 X 9 7/8 X 21",
			"label": "11 1/16 X 9 7/8 X 21"
	},
	{
			"value": "11/12",
			"label": "11/12"
	},
	{
			"value": "1 1/16",
			"label": "1 1/16"
	},
	{
			"value": "1-1/16",
			"label": "1-1/16"
	},
	{
			"value": "11-16",
			"label": "11-16"
	},
	{
			"value": "11/16",
			"label": "11/16"
	},
	{
			"value": "11/16\"\"",
			"label": "11/16\"\""
	},
	{
			"value": "11/16 IN.",
			"label": "11/16 IN."
	},
	{
			"value": "1.11 OZ",
			"label": "1.11 OZ"
	},
	{
			"value": "11.1 QUART",
			"label": "11.1 QUART"
	},
	{
			"value": "1  1/2",
			"label": "1  1/2"
	},
	{
			"value": "1 1/2",
			"label": "1 1/2"
	},
	{
			"value": "1 1/2\"\"",
			"label": "1 1/2\"\""
	},
	{
			"value": "1-1/2",
			"label": "1-1/2"
	},
	{
			"value": "11.2",
			"label": "11.2"
	},
	{
			"value": "112",
			"label": "112"
	},
	{
			"value": "1 1/2   (38MM)",
			"label": "1 1/2   (38MM)"
	},
	{
			"value": "1 1/2 (38MM)",
			"label": "1 1/2 (38MM)"
	},
	{
			"value": "1 1/2(38MM)",
			"label": "1 1/2(38MM)"
	},
	{
			"value": "11/2     (38MM)",
			"label": "11/2     (38MM)"
	},
	{
			"value": "1.125",
			"label": "1.125"
	},
	{
			"value": "1.125IN",
			"label": "1.125IN"
	},
	{
			"value": "11.25   L X 1.625",
			"label": "11.25   L X 1.625"
	},
	{
			"value": "1.125 OZ",
			"label": "1.125 OZ"
	},
	{
			"value": "1.125OZ",
			"label": "1.125OZ"
	},
	{
			"value": "11.25X0.5X15.75",
			"label": "11.25X0.5X15.75"
	},
	{
			"value": "11.25 X 10.00",
			"label": "11.25 X 10.00"
	},
	{
			"value": "11.25''X11.25''X2''",
			"label": "11.25''X11.25''X2''"
	},
	{
			"value": "11.25X12.25",
			"label": "11.25X12.25"
	},
	{
			"value": "11.26 OZ",
			"label": "11.26 OZ"
	},
	{
			"value": "1 1/2 IN.",
			"label": "1 1/2 IN."
	},
	{
			"value": "1 1/2 INCH",
			"label": "1 1/2 INCH"
	},
	{
			"value": "1 1/2IN X 50YD",
			"label": "1 1/2IN X 50YD"
	},
	{
			"value": "1 1/2   X 10 YDS",
			"label": "1 1/2   X 10 YDS"
	},
	{
			"value": "1 1/2   X 10YDS",
			"label": "1 1/2   X 10YDS"
	},
	{
			"value": "1 1/2  X10YDS",
			"label": "1 1/2  X10YDS"
	},
	{
			"value": "1 1/2   X 12YDS",
			"label": "1 1/2   X 12YDS"
	},
	{
			"value": "1-1/2   X 1.5 YDS",
			"label": "1-1/2   X 1.5 YDS"
	},
	{
			"value": "1 1/2   X 25YD",
			"label": "1 1/2   X 25YD"
	},
	{
			"value": "1-1/2   X 2 YDS",
			"label": "1-1/2   X 2 YDS"
	},
	{
			"value": "1-1/2   X 3 YARDS",
			"label": "1-1/2   X 3 YARDS"
	},
	{
			"value": "1 1/2   X  6YDS",
			"label": "1 1/2   X  6YDS"
	},
	{
			"value": "1 1/2  X 6YDS",
			"label": "1 1/2  X 6YDS"
	},
	{
			"value": "11.2X7.3X15.2",
			"label": "11.2X7.3X15.2"
	},
	{
			"value": "11.2   X 7.87   X 5",
			"label": "11.2   X 7.87   X 5"
	},
	{
			"value": "11.2X7.87X5.57",
			"label": "11.2X7.87X5.57"
	},
	{
			"value": "112 X 84",
			"label": "112 X 84"
	},
	{
			"value": "11-3/4 X 9-1/4 X 2-1",
			"label": "11-3/4 X 9-1/4 X 2-1"
	},
	{
			"value": "11-3/4 X 9-1/4 X 4-3",
			"label": "11-3/4 X 9-1/4 X 4-3"
	},
	{
			"value": "11.375X13.375",
			"label": "11.375X13.375"
	},
	{
			"value": "11.37 X 3.50",
			"label": "11.37 X 3.50"
	},
	{
			"value": "11.37 X 8.46",
			"label": "11.37 X 8.46"
	},
	{
			"value": "11.38''X2.25''",
			"label": "11.38''X2.25''"
	},
	{
			"value": "11..38''X7.5''",
			"label": "11..38''X7.5''"
	},
	{
			"value": "11.38''X7.5''",
			"label": "11.38''X7.5''"
	},
	{
			"value": "113 GR",
			"label": "113 GR"
	},
	{
			"value": "1.13OZ",
			"label": "1.13OZ"
	},
	{
			"value": "11.3 OZ",
			"label": "11.3 OZ"
	},
	{
			"value": "1  1/4",
			"label": "1  1/4"
	},
	{
			"value": "1 1/4",
			"label": "1 1/4"
	},
	{
			"value": "1 1/4\"\"",
			"label": "1 1/4\"\""
	},
	{
			"value": "1-1/4",
			"label": "1-1/4"
	},
	{
			"value": "11.4",
			"label": "11.4"
	},
	{
			"value": "114",
			"label": "114"
	},
	{
			"value": "11.41 X 10.23",
			"label": "11.41 X 10.23"
	},
	{
			"value": "1 1/4   ( 32 MM )",
			"label": "1 1/4   ( 32 MM )"
	},
	{
			"value": "1 1/4   ( 32MM )",
			"label": "1 1/4   ( 32MM )"
	},
	{
			"value": "1 1/4   (32MM)",
			"label": "1 1/4   (32MM)"
	},
	{
			"value": "1 1/4 (32MM)",
			"label": "1 1/4 (32MM)"
	},
	{
			"value": "114702",
			"label": "114702"
	},
	{
			"value": "11.49 X 9.72",
			"label": "11.49 X 9.72"
	},
	{
			"value": "11.49 X 9.75",
			"label": "11.49 X 9.75"
	},
	{
			"value": "1-1/4IN",
			"label": "1-1/4IN"
	},
	{
			"value": "11.4IN",
			"label": "11.4IN"
	},
	{
			"value": "1 1/4 IN (32 MM)",
			"label": "1 1/4 IN (32 MM)"
	},
	{
			"value": "1 1/4 (IN) X 2 1/4 (",
			"label": "1 1/4 (IN) X 2 1/4 ("
	},
	{
			"value": "1-1/4 IN X 3 YDS",
			"label": "1-1/4 IN X 3 YDS"
	},
	{
			"value": "1 1/4IN X 50YD",
			"label": "1 1/4IN X 50YD"
	},
	{
			"value": "11.4''LX3.5''WX3.3''",
			"label": "11.4''LX3.5''WX3.3''"
	},
	{
			"value": "1.14 OZ",
			"label": "1.14 OZ"
	},
	{
			"value": "11.4 OZ",
			"label": "11.4 OZ"
	},
	{
			"value": "1 1/4   X 10YDS",
			"label": "1 1/4   X 10YDS"
	},
	{
			"value": "11.4X14.4",
			"label": "11.4X14.4"
	},
	{
			"value": "1-1/4   X 2 YARDS",
			"label": "1-1/4   X 2 YARDS"
	},
	{
			"value": "1-1/4   X 2 YDS",
			"label": "1-1/4   X 2 YDS"
	},
	{
			"value": "1 1/4   X 3FT",
			"label": "1 1/4   X 3FT"
	},
	{
			"value": "1-1/4   X 4 FT",
			"label": "1-1/4   X 4 FT"
	},
	{
			"value": "1 1/4   X 6FT",
			"label": "1 1/4   X 6FT"
	},
	{
			"value": "1 1/4  X 6YDS",
			"label": "1 1/4  X 6YDS"
	},
	{
			"value": "1 1/4   X 9FT",
			"label": "1 1/4   X 9FT"
	},
	{
			"value": "11.5",
			"label": "11.5"
	},
	{
			"value": "115",
			"label": "115"
	},
	{
			"value": "1150",
			"label": "1150"
	},
	{
			"value": "11.50 X 10.10",
			"label": "11.50 X 10.10"
	},
	{
			"value": "11.50 X 10.12",
			"label": "11.50 X 10.12"
	},
	{
			"value": "11.50 X 10.20",
			"label": "11.50 X 10.20"
	},
	{
			"value": "11.50 X 10.25",
			"label": "11.50 X 10.25"
	},
	{
			"value": "11.50 X 9.87",
			"label": "11.50 X 9.87"
	},
	{
			"value": "1-15/16",
			"label": "1-15/16"
	},
	{
			"value": "11.5   DIA X 10 H",
			"label": "11.5   DIA X 10 H"
	},
	{
			"value": "11.5 IN",
			"label": "11.5 IN"
	},
	{
			"value": "11.5IN",
			"label": "11.5IN"
	},
	{
			"value": "11.5 IN X 7 IN X 4.7",
			"label": "11.5 IN X 7 IN X 4.7"
	},
	{
			"value": "11.5   L X 2.5   W",
			"label": "11.5   L X 2.5   W"
	},
	{
			"value": "1.15 OZ",
			"label": "1.15 OZ"
	},
	{
			"value": "11.5 OZ",
			"label": "11.5 OZ"
	},
	{
			"value": "115 PIECES",
			"label": "115 PIECES"
	},
	{
			"value": "11.5   X 10",
			"label": "11.5   X 10"
	},
	{
			"value": "11.5''X10.5''",
			"label": "11.5''X10.5''"
	},
	{
			"value": "11.5X10.5",
			"label": "11.5X10.5"
	},
	{
			"value": "11.5X11.5",
			"label": "11.5X11.5"
	},
	{
			"value": "11.5 X11.5 X 10",
			"label": "11.5 X11.5 X 10"
	},
	{
			"value": "11.5 X14",
			"label": "11.5 X14"
	},
	{
			"value": "11.5X4.4",
			"label": "11.5X4.4"
	},
	{
			"value": "11.5   X 4.5   X 4.",
			"label": "11.5   X 4.5   X 4."
	},
	{
			"value": "11.5X5",
			"label": "11.5X5"
	},
	{
			"value": "11.5 X 5.5 X .75",
			"label": "11.5 X 5.5 X .75"
	},
	{
			"value": "11.5X6X4",
			"label": "11.5X6X4"
	},
	{
			"value": "11.5''X7.5''",
			"label": "11.5''X7.5''"
	},
	{
			"value": "11.5''X7.5''X0.25''",
			"label": "11.5''X7.5''X0.25''"
	},
	{
			"value": "11.5''X7.5''X1.5''",
			"label": "11.5''X7.5''X1.5''"
	},
	{
			"value": "11.5 X 7 X 16 INCH",
			"label": "11.5 X 7 X 16 INCH"
	},
	{
			"value": "11.5X8.25X5.875",
			"label": "11.5X8.25X5.875"
	},
	{
			"value": "11.5X8.47X5.12",
			"label": "11.5X8.47X5.12"
	},
	{
			"value": "1/16",
			"label": "1/16"
	},
	{
			"value": "1160",
			"label": "1160"
	},
	{
			"value": "11.61 X 8.86 X 7.48",
			"label": "11.61 X 8.86 X 7.48"
	},
	{
			"value": "11.625 X 11.625 X 8.",
			"label": "11.625 X 11.625 X 8."
	},
	{
			"value": "11.62 X 10.12",
			"label": "11.62 X 10.12"
	},
	{
			"value": "11/64  X350YDS",
			"label": "11/64  X350YDS"
	},
	{
			"value": "11.65   X 1.5   X 1",
			"label": "11.65   X 1.5   X 1"
	},
	{
			"value": "11664",
			"label": "11664"
	},
	{
			"value": "1/16IN",
			"label": "1/16IN"
	},
	{
			"value": "11.6IN",
			"label": "11.6IN"
	},
	{
			"value": "1.16 OZ",
			"label": "1.16 OZ"
	},
	{
			"value": "1/16   X 4 YARDS",
			"label": "1/16   X 4 YARDS"
	},
	{
			"value": "116 X 84",
			"label": "116 X 84"
	},
	{
			"value": "1170",
			"label": "1170"
	},
	{
			"value": "11.73 X 8.66",
			"label": "11.73 X 8.66"
	},
	{
			"value": "11.75IN",
			"label": "11.75IN"
	},
	{
			"value": "11.75''X11.25''X2''",
			"label": "11.75''X11.25''X2''"
	},
	{
			"value": "11.75X 13.125 X 4",
			"label": "11.75X 13.125 X 4"
	},
	{
			"value": "11.75   X 7",
			"label": "11.75   X 7"
	},
	{
			"value": "11.75X8.125X5.5",
			"label": "11.75X8.125X5.5"
	},
	{
			"value": "11.75 X 8.25",
			"label": "11.75 X 8.25"
	},
	{
			"value": "11.75   X 8.25   X",
			"label": "11.75   X 8.25   X"
	},
	{
			"value": "11.75 X 8.50",
			"label": "11.75 X 8.50"
	},
	{
			"value": "11.75 X 9.5 X .75",
			"label": "11.75 X 9.5 X .75"
	},
	{
			"value": "1176",
			"label": "1176"
	},
	{
			"value": "1  1/8",
			"label": "1  1/8"
	},
	{
			"value": "1 1/8",
			"label": "1 1/8"
	},
	{
			"value": "1 1/8\"\"",
			"label": "1 1/8\"\""
	},
	{
			"value": "1-1/8",
			"label": "1-1/8"
	},
	{
			"value": "11.8",
			"label": "11.8"
	},
	{
			"value": "11.8\"\"",
			"label": "11.8\"\""
	},
	{
			"value": "118",
			"label": "118"
	},
	{
			"value": "11808",
			"label": "11808"
	},
	{
			"value": "11.81 X 11.53",
			"label": "11.81 X 11.53"
	},
	{
			"value": "11.81X 6.3X 2.36",
			"label": "11.81X 6.3X 2.36"
	},
	{
			"value": "1 1/8   (22MM)",
			"label": "1 1/8   (22MM)"
	},
	{
			"value": "1 1/8   (28MM)",
			"label": "1 1/8   (28MM)"
	},
	{
			"value": "1 1/8 (28MM)",
			"label": "1 1/8 (28MM)"
	},
	{
			"value": "11.82 IN",
			"label": "11.82 IN"
	},
	{
			"value": "11832",
			"label": "11832"
	},
	{
			"value": "11.85 OZ",
			"label": "11.85 OZ"
	},
	{
			"value": "11.875X7.875X9.5",
			"label": "11.875X7.875X9.5"
	},
	{
			"value": "11.87X 9.87X 2.87",
			"label": "11.87X 9.87X 2.87"
	},
	{
			"value": "1 1/8   DIA.  36-66",
			"label": "1 1/8   DIA.  36-66"
	},
	{
			"value": "1.18 FLUID OUNCE",
			"label": "1.18 FLUID OUNCE"
	},
	{
			"value": "1 1/8 IN.",
			"label": "1 1/8 IN."
	},
	{
			"value": "11.8 IN",
			"label": "11.8 IN"
	},
	{
			"value": "11.8IN",
			"label": "11.8IN"
	},
	{
			"value": "1 1/8 IN (27 MM)",
			"label": "1 1/8 IN (27 MM)"
	},
	{
			"value": "11.8  LENGTH X3.9",
			"label": "11.8  LENGTH X3.9"
	},
	{
			"value": "118ML",
			"label": "118ML"
	},
	{
			"value": "1 1/8   X 10YDS",
			"label": "1 1/8   X 10YDS"
	},
	{
			"value": "11.8X1.2X23.8",
			"label": "11.8X1.2X23.8"
	},
	{
			"value": "1 1/8   X 12YDS",
			"label": "1 1/8   X 12YDS"
	},
	{
			"value": "118 X 15",
			"label": "118 X 15"
	},
	{
			"value": "11.8 X 15.10 X 6",
			"label": "11.8 X 15.10 X 6"
	},
	{
			"value": "11.8X15.16",
			"label": "11.8X15.16"
	},
	{
			"value": "118 X 19.5",
			"label": "118 X 19.5"
	},
	{
			"value": "11.8   X 2   X .39",
			"label": "11.8   X 2   X .39"
	},
	{
			"value": "1 1/8   X 3FT",
			"label": "1 1/8   X 3FT"
	},
	{
			"value": "1 1/8  X 6YDS",
			"label": "1 1/8  X 6YDS"
	},
	{
			"value": "11904",
			"label": "11904"
	},
	{
			"value": "1192",
			"label": "1192"
	},
	{
			"value": "11.97",
			"label": "11.97"
	},
	{
			"value": "11.9IN",
			"label": "11.9IN"
	},
	{
			"value": "1.19 OZ.",
			"label": "1.19 OZ."
	},
	{
			"value": "11.9 OZ",
			"label": "11.9 OZ"
	},
	{
			"value": "11''H 11-1/2''W",
			"label": "11''H 11-1/2''W"
	},
	{
			"value": "11  H X 7  WX 7  D",
			"label": "11  H X 7  WX 7  D"
	},
	{
			"value": "11 IN",
			"label": "11 IN"
	},
	{
			"value": "11IN",
			"label": "11IN"
	},
	{
			"value": "11 INCH",
			"label": "11 INCH"
	},
	{
			"value": "11''LX10.2''WX16.5''",
			"label": "11''LX10.2''WX16.5''"
	},
	{
			"value": "11  L X 30.25  W X",
			"label": "11  L X 30.25  W X"
	},
	{
			"value": "11MM",
			"label": "11MM"
	},
	{
			"value": "11/O",
			"label": "11/O"
	},
	{
			"value": "1.1 OZ",
			"label": "1.1 OZ"
	},
	{
			"value": "11 OZ",
			"label": "11 OZ"
	},
	{
			"value": "11 OZ.",
			"label": "11 OZ."
	},
	{
			"value": "11OZ",
			"label": "11OZ"
	},
	{
			"value": "1.1OZ",
			"label": "1.1OZ"
	},
	{
			"value": "11  OZ",
			"label": "11  OZ"
	},
	{
			"value": "11 PAIR",
			"label": "11 PAIR"
	},
	{
			"value": "11 PIECES",
			"label": "11 PIECES"
	},
	{
			"value": "11   PLATE",
			"label": "11   PLATE"
	},
	{
			"value": "1.1 X 0.5 X 0.8",
			"label": "1.1 X 0.5 X 0.8"
	},
	{
			"value": "11X11",
			"label": "11X11"
	},
	{
			"value": "11   X 11",
			"label": "11   X 11"
	},
	{
			"value": "11 X 11 X 14",
			"label": "11 X 11 X 14"
	},
	{
			"value": "11 X 12",
			"label": "11 X 12"
	},
	{
			"value": "11X12.6IN",
			"label": "11X12.6IN"
	},
	{
			"value": "11X13",
			"label": "11X13"
	},
	{
			"value": "11X13X2",
			"label": "11X13X2"
	},
	{
			"value": "11 X 14",
			"label": "11 X 14"
	},
	{
			"value": "11 X14",
			"label": "11 X14"
	},
	{
			"value": "11X14",
			"label": "11X14"
	},
	{
			"value": "11   X 14",
			"label": "11   X 14"
	},
	{
			"value": "11  X14",
			"label": "11  X14"
	},
	{
			"value": "11X14 2PK",
			"label": "11X14 2PK"
	},
	{
			"value": "11X14 BLACK",
			"label": "11X14 BLACK"
	},
	{
			"value": "11 X 14 CLOSED",
			"label": "11 X 14 CLOSED"
	},
	{
			"value": "11X14 MAT 8X10",
			"label": "11X14 MAT 8X10"
	},
	{
			"value": "11X14 MAT TO 8X10",
			"label": "11X14 MAT TO 8X10"
	},
	{
			"value": "11 X 14 SINGLE PACK",
			"label": "11 X 14 SINGLE PACK"
	},
	{
			"value": "11X14 TO 2 5X7 COLLA",
			"label": "11X14 TO 2 5X7 COLLA"
	},
	{
			"value": "11X15",
			"label": "11X15"
	},
	{
			"value": "11  X15",
			"label": "11  X15"
	},
	{
			"value": "11X15.5",
			"label": "11X15.5"
	},
	{
			"value": "11X1.5X14",
			"label": "11X1.5X14"
	},
	{
			"value": "11X16",
			"label": "11X16"
	},
	{
			"value": "11 X 16.5",
			"label": "11 X 16.5"
	},
	{
			"value": "11X17",
			"label": "11X17"
	},
	{
			"value": "11X17X2",
			"label": "11X17X2"
	},
	{
			"value": "11X18",
			"label": "11X18"
	},
	{
			"value": "11X23",
			"label": "11X23"
	},
	{
			"value": "11X25X2",
			"label": "11X25X2"
	},
	{
			"value": "11 X 2 X 4.2",
			"label": "11 X 2 X 4.2"
	},
	{
			"value": "11X3.5",
			"label": "11X3.5"
	},
	{
			"value": "11X4.5",
			"label": "11X4.5"
	},
	{
			"value": "11   X 4.5   X 7.75",
			"label": "11   X 4.5   X 7.75"
	},
	{
			"value": "11X4.7X16",
			"label": "11X4.7X16"
	},
	{
			"value": "11 X 4 X 2",
			"label": "11 X 4 X 2"
	},
	{
			"value": "11X5",
			"label": "11X5"
	},
	{
			"value": "11X5.5X4",
			"label": "11X5.5X4"
	},
	{
			"value": "11X6X10",
			"label": "11X6X10"
	},
	{
			"value": "11X7",
			"label": "11X7"
	},
	{
			"value": "11   X 7.87   X 4.3",
			"label": "11   X 7.87   X 4.3"
	},
	{
			"value": "11   X 7.87   X 7.2",
			"label": "11   X 7.87   X 7.2"
	},
	{
			"value": "11X8",
			"label": "11X8"
	},
	{
			"value": "11X9.5",
			"label": "11X9.5"
	},
	{
			"value": "11YD",
			"label": "11YD"
	},
	{
			"value": "-12",
			"label": "-12"
	},
	{
			"value": "1.2",
			"label": "1.2"
	},
	{
			"value": "1/2",
			"label": "1/2"
	},
	{
			"value": "1/2\"\"",
			"label": "1/2\"\""
	},
	{
			"value": "12",
			"label": "12"
	},
	{
			"value": "12\"\"",
			"label": "12\"\""
	},
	{
			"value": "12'",
			"label": "12'"
	},
	{
			"value": "120",
			"label": "120"
	},
	{
			"value": "1200",
			"label": "1200"
	},
	{
			"value": "12000",
			"label": "12000"
	},
	{
			"value": " 1,200/COLOR",
			"label": " 1,200/COLOR"
	},
	{
			"value": "1200/COLOR",
			"label": "1200/COLOR"
	},
	{
			"value": "1200PCS",
			"label": "1200PCS"
	},
	{
			"value": "1200PCS/COLOR",
			"label": "1200PCS/COLOR"
	},
	{
			"value": "1200/STYLE",
			"label": "1200/STYLE"
	},
	{
			"value": "1200/TYLE",
			"label": "1200/TYLE"
	},
	{
			"value": "12.00X12.00X0.25",
			"label": "12.00X12.00X0.25"
	},
	{
			"value": "12.00 X 6.25",
			"label": "12.00 X 6.25"
	},
	{
			"value": "12.00 X 6.50",
			"label": "12.00 X 6.50"
	},
	{
			"value": "12.00 X 8.75",
			"label": "12.00 X 8.75"
	},
	{
			"value": "1200 YDS",
			"label": "1200 YDS"
	},
	{
			"value": "1208",
			"label": "1208"
	},
	{
			"value": "12.08L X 14.98W X 5.",
			"label": "12.08L X 14.98W X 5."
	},
	{
			"value": "120FT",
			"label": "120FT"
	},
	{
			"value": "120GM",
			"label": "120GM"
	},
	{
			"value": "120GR",
			"label": "120GR"
	},
	{
			"value": "120MM",
			"label": "120MM"
	},
	{
			"value": "120 PC",
			"label": "120 PC"
	},
	{
			"value": "120 PIECES",
			"label": "120 PIECES"
	},
	{
			"value": "120X120",
			"label": "120X120"
	},
	{
			"value": "120   X 120",
			"label": "120   X 120"
	},
	{
			"value": "120  X121",
			"label": "120  X121"
	},
	{
			"value": "120 X 124",
			"label": "120 X 124"
	},
	{
			"value": "120 X 84",
			"label": "120 X 84"
	},
	{
			"value": "12108",
			"label": "12108"
	},
	{
			"value": "1/2   (12MM)",
			"label": "1/2   (12MM)"
	},
	{
			"value": "1/2 (12MM)",
			"label": "1/2 (12MM)"
	},
	{
			"value": "12.12 X 4.62",
			"label": "12.12 X 4.62"
	},
	{
			"value": "12-14",
			"label": "12-14"
	},
	{
			"value": "(12-14-16)",
			"label": "(12-14-16)"
	},
	{
			"value": "12.15QT",
			"label": "12.15QT"
	},
	{
			"value": " 12,16",
			"label": " 12,16"
	},
	{
			"value": "12 - 18 MONTHS",
			"label": "12 - 18 MONTHS"
	},
	{
			"value": "-12192",
			"label": "-12192"
	},
	{
			"value": "12 1OZ BARS",
			"label": "12 1OZ BARS"
	},
	{
			"value": "12.1 QT",
			"label": "12.1 QT"
	},
	{
			"value": "12.1QT",
			"label": "12.1QT"
	},
	{
			"value": "12-20",
			"label": "12-20"
	},
	{
			"value": "1220",
			"label": "1220"
	},
	{
			"value": "12.21 IN",
			"label": "12.21 IN"
	},
	{
			"value": "12-22",
			"label": "12-22"
	},
	{
			"value": "12.25",
			"label": "12.25"
	},
	{
			"value": "12.25-13IN IN LENGTH",
			"label": "12.25-13IN IN LENGTH"
	},
	{
			"value": "12.25IN",
			"label": "12.25IN"
	},
	{
			"value": "12.25 LONG",
			"label": "12.25 LONG"
	},
	{
			"value": "12.25 OZ",
			"label": "12.25 OZ"
	},
	{
			"value": "12.25 X 10.00",
			"label": "12.25 X 10.00"
	},
	{
			"value": "12.25 X 10.37",
			"label": "12.25 X 10.37"
	},
	{
			"value": "12.25   X 1.5   X .",
			"label": "12.25   X 1.5   X ."
	},
	{
			"value": "12.25X1.5X12.25",
			"label": "12.25X1.5X12.25"
	},
	{
			"value": "12.25X1X13.375",
			"label": "12.25X1X13.375"
	},
	{
			"value": "12.25   X 3",
			"label": "12.25   X 3"
	},
	{
			"value": "12.25 X 3.75 X 13.25",
			"label": "12.25 X 3.75 X 13.25"
	},
	{
			"value": "12.25 X 4.5 X 0.25 I",
			"label": "12.25 X 4.5 X 0.25 I"
	},
	{
			"value": "12.25 X 4.75",
			"label": "12.25 X 4.75"
	},
	{
			"value": "12.25 X 8.75",
			"label": "12.25 X 8.75"
	},
	{
			"value": "12.25X9X8.5",
			"label": "12.25X9X8.5"
	},
	{
			"value": "1/2 28-48   DRAPERY",
			"label": "1/2 28-48   DRAPERY"
	},
	{
			"value": "12.2 IN",
			"label": "12.2 IN"
	},
	{
			"value": "12.2 OUNCE",
			"label": "12.2 OUNCE"
	},
	{
			"value": "12.2 OZ",
			"label": "12.2 OZ"
	},
	{
			"value": "12.2 X 11.4",
			"label": "12.2 X 11.4"
	},
	{
			"value": "12.2X11.61X21.06",
			"label": "12.2X11.61X21.06"
	},
	{
			"value": "12.2   X 5.5   X 5.",
			"label": "12.2   X 5.5   X 5."
	},
	{
			"value": "122 X 84",
			"label": "122 X 84"
	},
	{
			"value": "(1-2-3)",
			"label": "(1-2-3)"
	},
	{
			"value": "1-2/3",
			"label": "1-2/3"
	},
	{
			"value": "12.30 X 9.00",
			"label": "12.30 X 9.00"
	},
	{
			"value": "12.31 X 12.31 X .4",
			"label": "12.31 X 12.31 X .4"
	},
	{
			"value": "12/32",
			"label": "12/32"
	},
	{
			"value": "123.5IN X 7.25IN X 6",
			"label": "123.5IN X 7.25IN X 6"
	},
	{
			"value": "12.37 X 8.75",
			"label": "12.37 X 8.75"
	},
	{
			"value": "12.38 X 4.875 X 1.75",
			"label": "12.38 X 4.875 X 1.75"
	},
	{
			"value": "12.38X4.875X1.75",
			"label": "12.38X4.875X1.75"
	},
	{
			"value": "1.23 OZ",
			"label": "1.23 OZ"
	},
	{
			"value": "12.3 OZ",
			"label": "12.3 OZ"
	},
	{
			"value": "124\"\"",
			"label": "124\"\""
	},
	{
			"value": "1240",
			"label": "1240"
	},
	{
			"value": "12.40 X 8.87",
			"label": "12.40 X 8.87"
	},
	{
			"value": "1244",
			"label": "1244"
	},
	{
			"value": "12486",
			"label": "12486"
	},
	{
			"value": "1/2  48-84 DRAPERY R",
			"label": "1/2  48-84 DRAPERY R"
	},
	{
			"value": "12.48X5.12X13.19",
			"label": "12.48X5.12X13.19"
	},
	{
			"value": "1249",
			"label": "1249"
	},
	{
			"value": "12.4X8.1X5.5",
			"label": "12.4X8.1X5.5"
	},
	{
			"value": "12.4X8.1X6.5",
			"label": "12.4X8.1X6.5"
	},
	{
			"value": "124 X 84",
			"label": "124 X 84"
	},
	{
			"value": ".125",
			"label": ".125"
	},
	{
			"value": "1.25",
			"label": "1.25"
	},
	{
			"value": "12.5",
			"label": "12.5"
	},
	{
			"value": "125",
			"label": "125"
	},
	{
			"value": "1250",
			"label": "1250"
	},
	{
			"value": "12500",
			"label": "12500"
	},
	{
			"value": "12.50 X 10.75",
			"label": "12.50 X 10.75"
	},
	{
			"value": "12.50 X 8.50",
			"label": "12.50 X 8.50"
	},
	{
			"value": "12.50 X 9.00",
			"label": "12.50 X 9.00"
	},
	{
			"value": "12.50 X 9.25",
			"label": "12.50 X 9.25"
	},
	{
			"value": "12.50 X 9.50",
			"label": "12.50 X 9.50"
	},
	{
			"value": "1.25 FL OZ",
			"label": "1.25 FL OZ"
	},
	{
			"value": "1.25 IN",
			"label": "1.25 IN"
	},
	{
			"value": "1.25IN",
			"label": "1.25IN"
	},
	{
			"value": "12.5IN",
			"label": "12.5IN"
	},
	{
			"value": "1.25 INCH",
			"label": "1.25 INCH"
	},
	{
			"value": "12.5 INCHES",
			"label": "12.5 INCHES"
	},
	{
			"value": "1.25 INCH X 3FT",
			"label": "1.25 INCH X 3FT"
	},
	{
			"value": "1.25INCH X 3FT",
			"label": "1.25INCH X 3FT"
	},
	{
			"value": "1.25 INCH X 4FT",
			"label": "1.25 INCH X 4FT"
	},
	{
			"value": "125 ML",
			"label": "125 ML"
	},
	{
			"value": "1    (25MM)",
			"label": "1    (25MM)"
	},
	{
			"value": "1   (25MM)",
			"label": "1   (25MM)"
	},
	{
			"value": "1  (25MM)",
			"label": "1  (25MM)"
	},
	{
			"value": "1 (25MM)",
			"label": "1 (25MM)"
	},
	{
			"value": "1(25MM)",
			"label": "1(25MM)"
	},
	{
			"value": "1.25 OZ",
			"label": "1.25 OZ"
	},
	{
			"value": "1.25OZ",
			"label": "1.25OZ"
	},
	{
			"value": "12.5 OZ",
			"label": "12.5 OZ"
	},
	{
			"value": "12.5 X 10.5 X 2.25",
			"label": "12.5 X 10.5 X 2.25"
	},
	{
			"value": "1.25''X11.25''X7''",
			"label": "1.25''X11.25''X7''"
	},
	{
			"value": "12.5X12.5",
			"label": "12.5X12.5"
	},
	{
			"value": "12.5 X 12.5 X 24",
			"label": "12.5 X 12.5 X 24"
	},
	{
			"value": "12.5 X 14.25 X 18.25",
			"label": "12.5 X 14.25 X 18.25"
	},
	{
			"value": "1.25 X .2 X 8.5",
			"label": "1.25 X .2 X 8.5"
	},
	{
			"value": "1.25  X3.75",
			"label": "1.25  X3.75"
	},
	{
			"value": "1.25   X 3 YARDS",
			"label": "1.25   X 3 YARDS"
	},
	{
			"value": ".125   X 4   X 10.5",
			"label": ".125   X 4   X 10.5"
	},
	{
			"value": ".125   X 4   X 14",
			"label": ".125   X 4   X 14"
	},
	{
			"value": "1.25   X 4   X 5",
			"label": "1.25   X 4   X 5"
	},
	{
			"value": ".125   X 5   X 14",
			"label": ".125   X 5   X 14"
	},
	{
			"value": "12.5X6.25X8.75",
			"label": "12.5X6.25X8.75"
	},
	{
			"value": "12.5 X 6.5 X 7.75",
			"label": "12.5 X 6.5 X 7.75"
	},
	{
			"value": "12.5 X 6 X 1.5",
			"label": "12.5 X 6 X 1.5"
	},
	{
			"value": "12.5 X 6 X 2.875",
			"label": "12.5 X 6 X 2.875"
	},
	{
			"value": "1.25   X 6YD",
			"label": "1.25   X 6YD"
	},
	{
			"value": "1.25   X 6YDS",
			"label": "1.25   X 6YDS"
	},
	{
			"value": ".125   X 7.75   X 1",
			"label": ".125   X 7.75   X 1"
	},
	{
			"value": "12.5 X 8.25 X 4.75 I",
			"label": "12.5 X 8.25 X 4.75 I"
	},
	{
			"value": ".125   X 8.25   X 8",
			"label": ".125   X 8.25   X 8"
	},
	{
			"value": ".125   X 8.5   X 8.",
			"label": ".125   X 8.5   X 8."
	},
	{
			"value": "12.5X8X10.63",
			"label": "12.5X8X10.63"
	},
	{
			"value": "12.5 X 9.25 X 3",
			"label": "12.5 X 9.25 X 3"
	},
	{
			"value": "12.5X9.75X4.5",
			"label": "12.5X9.75X4.5"
	},
	{
			"value": "125 YD",
			"label": "125 YD"
	},
	{
			"value": "125 YDS",
			"label": "125 YDS"
	},
	{
			"value": "1260",
			"label": "1260"
	},
	{
			"value": "12600",
			"label": "12600"
	},
	{
			"value": "12.61",
			"label": "12.61"
	},
	{
			"value": "12.62 X 10.12",
			"label": "12.62 X 10.12"
	},
	{
			"value": "12.62 X 9.00",
			"label": "12.62 X 9.00"
	},
	{
			"value": "12.62 X 9.87",
			"label": "12.62 X 9.87"
	},
	{
			"value": "12.63 X 2.83 X 13.50",
			"label": "12.63 X 2.83 X 13.50"
	},
	{
			"value": "12.63X3.5X11.4",
			"label": "12.63X3.5X11.4"
	},
	{
			"value": "12.65 X 10.13",
			"label": "12.65 X 10.13"
	},
	{
			"value": "12.65 X 9.87",
			"label": "12.65 X 9.87"
	},
	{
			"value": "12.68QT",
			"label": "12.68QT"
	},
	{
			"value": "12.6IN",
			"label": "12.6IN"
	},
	{
			"value": "1.26 OZ",
			"label": "1.26 OZ"
	},
	{
			"value": "12.6 X 1.54 X .12",
			"label": "12.6 X 1.54 X .12"
	},
	{
			"value": "12.6 X 1.77 X .59",
			"label": "12.6 X 1.77 X .59"
	},
	{
			"value": "1272",
			"label": "1272"
	},
	{
			"value": "-12720",
			"label": "-12720"
	},
	{
			"value": "12.75 OZ",
			"label": "12.75 OZ"
	},
	{
			"value": "12.75 X 10.12",
			"label": "12.75 X 10.12"
	},
	{
			"value": "12.75 X 12.75 X 7.5",
			"label": "12.75 X 12.75 X 7.5"
	},
	{
			"value": "12.75 X 12.75 X 9 IN",
			"label": "12.75 X 12.75 X 9 IN"
	},
	{
			"value": "12.75 X 4.37 X 4.15",
			"label": "12.75 X 4.37 X 4.15"
	},
	{
			"value": "12.75 X 4.5",
			"label": "12.75 X 4.5"
	},
	{
			"value": "12.78 X 4 X 3.38",
			"label": "12.78 X 4 X 3.38"
	},
	{
			"value": "1.27 OZ",
			"label": "1.27 OZ"
	},
	{
			"value": "12.7OZ",
			"label": "12.7OZ"
	},
	{
			"value": "12.7QT",
			"label": "12.7QT"
	},
	{
			"value": "12.80 11.42H",
			"label": "12.80 11.42H"
	},
	{
			"value": "1286",
			"label": "1286"
	},
	{
			"value": "12.87 X 14.37",
			"label": "12.87 X 14.37"
	},
	{
			"value": "12.87 X 2.5 X 15",
			"label": "12.87 X 2.5 X 15"
	},
	{
			"value": "12.87 X 8.00",
			"label": "12.87 X 8.00"
	},
	{
			"value": "128GR",
			"label": "128GR"
	},
	{
			"value": "12.8 INCH",
			"label": "12.8 INCH"
	},
	{
			"value": "12.8 X 8.27 X 8.27 I",
			"label": "12.8 X 8.27 X 8.27 I"
	},
	{
			"value": "128 X 84",
			"label": "128 X 84"
	},
	{
			"value": "1296",
			"label": "1296"
	},
	{
			"value": "12960",
			"label": "12960"
	},
	{
			"value": "12.9 X 6.69",
			"label": "12.9 X 6.69"
	},
	{
			"value": "12   AND 5",
			"label": "12   AND 5"
	},
	{
			"value": "12 BOTTLES -4OZ EACH",
			"label": "12 BOTTLES -4OZ EACH"
	},
	{
			"value": "12 CT",
			"label": "12 CT"
	},
	{
			"value": "12CT",
			"label": "12CT"
	},
	{
			"value": "12 DRAWER",
			"label": "12 DRAWER"
	},
	{
			"value": "12FT",
			"label": "12FT"
	},
	{
			"value": "12G",
			"label": "12G"
	},
	{
			"value": "12 GALLON",
			"label": "12 GALLON"
	},
	{
			"value": "12 HOOKS IN PACKAGE",
			"label": "12 HOOKS IN PACKAGE"
	},
	{
			"value": "1/2 IN",
			"label": "1/2 IN"
	},
	{
			"value": "1/2 IN.",
			"label": "1/2 IN."
	},
	{
			"value": "12 IN",
			"label": "12 IN"
	},
	{
			"value": "12IN",
			"label": "12IN"
	},
	{
			"value": "1/2IN",
			"label": "1/2IN"
	},
	{
			"value": "12IN 2PK",
			"label": "12IN 2PK"
	},
	{
			"value": "12 INCH",
			"label": "12 INCH"
	},
	{
			"value": "12 INCH DIAMETER",
			"label": "12 INCH DIAMETER"
	},
	{
			"value": "12 INCHES",
			"label": "12 INCHES"
	},
	{
			"value": "12 INCHES IN HEIGHT",
			"label": "12 INCHES IN HEIGHT"
	},
	{
			"value": "12 INCH IN HEIGHT",
			"label": "12 INCH IN HEIGHT"
	},
	{
			"value": "12IN H",
			"label": "12IN H"
	},
	{
			"value": "12INX12IN",
			"label": "12INX12IN"
	},
	{
			"value": "1/2 IN X 1/2 IN 64 S",
			"label": "1/2 IN X 1/2 IN 64 S"
	},
	{
			"value": "1/2IN X 144YD",
			"label": "1/2IN X 144YD"
	},
	{
			"value": "12INX16IN",
			"label": "12INX16IN"
	},
	{
			"value": "12IN X 18IN GRID",
			"label": "12IN X 18IN GRID"
	},
	{
			"value": "12INX1IN",
			"label": "12INX1IN"
	},
	{
			"value": "1/2 IN X 250 IN",
			"label": "1/2 IN X 250 IN"
	},
	{
			"value": "12INX36IN",
			"label": "12INX36IN"
	},
	{
			"value": "12INX4IN",
			"label": "12INX4IN"
	},
	{
			"value": "12INX5IN",
			"label": "12INX5IN"
	},
	{
			"value": "12 IN X 6.5 IN X 2.2",
			"label": "12 IN X 6.5 IN X 2.2"
	},
	{
			"value": "12 IN X 7.16 IN X 4.",
			"label": "12 IN X 7.16 IN X 4."
	},
	{
			"value": "1.2 LB",
			"label": "1.2 LB"
	},
	{
			"value": "1/2 MED",
			"label": "1/2 MED"
	},
	{
			"value": "12MM",
			"label": "12MM"
	},
	{
			"value": "12 MM 30 PCS",
			"label": "12 MM 30 PCS"
	},
	{
			"value": "12 MONTH",
			"label": "12 MONTH"
	},
	{
			"value": "12 MONTHS",
			"label": "12 MONTHS"
	},
	{
			"value": "12 OUNCES",
			"label": "12 OUNCES"
	},
	{
			"value": "1.2 OZ",
			"label": "1.2 OZ"
	},
	{
			"value": "12 OZ",
			"label": "12 OZ"
	},
	{
			"value": "12 OZ.",
			"label": "12 OZ."
	},
	{
			"value": "12OZ",
			"label": "12OZ"
	},
	{
			"value": "1.2OZ",
			"label": "1.2OZ"
	},
	{
			"value": "12OZ/340G",
			"label": "12OZ/340G"
	},
	{
			"value": "12 OZ/340 GRAMS",
			"label": "12 OZ/340 GRAMS"
	},
	{
			"value": "12 OZ CAN",
			"label": "12 OZ CAN"
	},
	{
			"value": "12OZ CAN",
			"label": "12OZ CAN"
	},
	{
			"value": "12 PAIR",
			"label": "12 PAIR"
	},
	{
			"value": "12 PC",
			"label": "12 PC"
	},
	{
			"value": "12PC",
			"label": "12PC"
	},
	{
			"value": "12PC/1.5IN",
			"label": "12PC/1.5IN"
	},
	{
			"value": "12PC/2IN",
			"label": "12PC/2IN"
	},
	{
			"value": "1/2 PINT",
			"label": "1/2 PINT"
	},
	{
			"value": "12 PK",
			"label": "12 PK"
	},
	{
			"value": "12PK",
			"label": "12PK"
	},
	{
			"value": "12 QUART",
			"label": "12 QUART"
	},
	{
			"value": "12 ROLL",
			"label": "12 ROLL"
	},
	{
			"value": "12   ROUND",
			"label": "12   ROUND"
	},
	{
			"value": "12 STRIPS PER CLIP",
			"label": "12 STRIPS PER CLIP"
	},
	{
			"value": "12 TRIMS PER CLIP ST",
			"label": "12 TRIMS PER CLIP ST"
	},
	{
			"value": "12  X0.5",
			"label": "12  X0.5"
	},
	{
			"value": "12X 10",
			"label": "12X 10"
	},
	{
			"value": "12X10",
			"label": "12X10"
	},
	{
			"value": "12   X 10'",
			"label": "12   X 10'"
	},
	{
			"value": "12  X10",
			"label": "12  X10"
	},
	{
			"value": "12 X 10 X 30.75",
			"label": "12 X 10 X 30.75"
	},
	{
			"value": "1/2   X 10YDS",
			"label": "1/2   X 10YDS"
	},
	{
			"value": "1/2  X10YDS",
			"label": "1/2  X10YDS"
	},
	{
			"value": "12X11",
			"label": "12X11"
	},
	{
			"value": "12X118ML",
			"label": "12X118ML"
	},
	{
			"value": "12X11X2",
			"label": "12X11X2"
	},
	{
			"value": "12 X 12",
			"label": "12 X 12"
	},
	{
			"value": "12X12",
			"label": "12X12"
	},
	{
			"value": "12   X 12",
			"label": "12   X 12"
	},
	{
			"value": "12X12 COMBO",
			"label": "12X12 COMBO"
	},
	{
			"value": "12X12 PAPER",
			"label": "12X12 PAPER"
	},
	{
			"value": "12 X12 ROUND",
			"label": "12 X12 ROUND"
	},
	{
			"value": "12X12 SPECIALTY PAPE",
			"label": "12X12 SPECIALTY PAPE"
	},
	{
			"value": "12X12X1",
			"label": "12X12X1"
	},
	{
			"value": "12X12X2",
			"label": "12X12X2"
	},
	{
			"value": "12 X 12 X .25",
			"label": "12 X 12 X .25"
	},
	{
			"value": "12X14",
			"label": "12X14"
	},
	{
			"value": "12X14.75X10IN",
			"label": "12X14.75X10IN"
	},
	{
			"value": "12X15",
			"label": "12X15"
	},
	{
			"value": "12   X 15",
			"label": "12   X 15"
	},
	{
			"value": "12X15.375X1",
			"label": "12X15.375X1"
	},
	{
			"value": "12X1.57X16",
			"label": "12X1.57X16"
	},
	{
			"value": "12X1.5X12",
			"label": "12X1.5X12"
	},
	{
			"value": "12X1.5X16",
			"label": "12X1.5X16"
	},
	{
			"value": "12X16",
			"label": "12X16"
	},
	{
			"value": "12   X 16",
			"label": "12   X 16"
	},
	{
			"value": "12X16.5X1",
			"label": "12X16.5X1"
	},
	{
			"value": "12X17",
			"label": "12X17"
	},
	{
			"value": "12X1.75X12",
			"label": "12X1.75X12"
	},
	{
			"value": "12 X 18",
			"label": "12 X 18"
	},
	{
			"value": "12X18",
			"label": "12X18"
	},
	{
			"value": "12   X 18",
			"label": "12   X 18"
	},
	{
			"value": "12X2",
			"label": "12X2"
	},
	{
			"value": "12X20",
			"label": "12X20"
	},
	{
			"value": "12  X20",
			"label": "12  X20"
	},
	{
			"value": "1/2   X 20 YARDS",
			"label": "1/2   X 20 YARDS"
	},
	{
			"value": "12X22",
			"label": "12X22"
	},
	{
			"value": "12   X 22",
			"label": "12   X 22"
	},
	{
			"value": "12X2.2X31.9",
			"label": "12X2.2X31.9"
	},
	{
			"value": "12 X 24",
			"label": "12 X 24"
	},
	{
			"value": "12X24",
			"label": "12X24"
	},
	{
			"value": "12   X 24",
			"label": "12   X 24"
	},
	{
			"value": "1/2X24X72",
			"label": "1/2X24X72"
	},
	{
			"value": "1/2   X 2-5/8",
			"label": "1/2   X 2-5/8"
	},
	{
			"value": "12X3",
			"label": "12X3"
	},
	{
			"value": "12 X 3.25 X 9.875",
			"label": "12 X 3.25 X 9.875"
	},
	{
			"value": "12X3.5",
			"label": "12X3.5"
	},
	{
			"value": "12X36",
			"label": "12X36"
	},
	{
			"value": "12' X 36'",
			"label": "12' X 36'"
	},
	{
			"value": "12X.375X15.75",
			"label": "12X.375X15.75"
	},
	{
			"value": "1/2   X 3FT",
			"label": "1/2   X 3FT"
	},
	{
			"value": "1/2   X 3 YARDS",
			"label": "1/2   X 3 YARDS"
	},
	{
			"value": "12   X 4'",
			"label": "12   X 4'"
	},
	{
			"value": "12X47.75",
			"label": "12X47.75"
	},
	{
			"value": "12X48",
			"label": "12X48"
	},
	{
			"value": "12X4.92",
			"label": "12X4.92"
	},
	{
			"value": "12X4X12.5",
			"label": "12X4X12.5"
	},
	{
			"value": "12X5",
			"label": "12X5"
	},
	{
			"value": "1.2 X 5.2 X 8.7",
			"label": "1.2 X 5.2 X 8.7"
	},
	{
			"value": "1.2 X 5.8 X 8.5",
			"label": "1.2 X 5.8 X 8.5"
	},
	{
			"value": "12 X 5 X 5",
			"label": "12 X 5 X 5"
	},
	{
			"value": "12X6",
			"label": "12X6"
	},
	{
			"value": "12   X 6'",
			"label": "12   X 6'"
	},
	{
			"value": "12   X 60",
			"label": "12   X 60"
	},
	{
			"value": "12   X60",
			"label": "12   X60"
	},
	{
			"value": "1/2 X 6FT",
			"label": "1/2 X 6FT"
	},
	{
			"value": "12X6X9",
			"label": "12X6X9"
	},
	{
			"value": "12 X 7 X 4",
			"label": "12 X 7 X 4"
	},
	{
			"value": "12X7X7",
			"label": "12X7X7"
	},
	{
			"value": "12 X 7 X 8",
			"label": "12 X 7 X 8"
	},
	{
			"value": "12X8",
			"label": "12X8"
	},
	{
			"value": "12  X 8.5  X .06",
			"label": "12  X 8.5  X .06"
	},
	{
			"value": "12  X 8.5  X.06",
			"label": "12  X 8.5  X.06"
	},
	{
			"value": "12  X8.5  X.06",
			"label": "12  X8.5  X.06"
	},
	{
			"value": "1/2  X 8YDS",
			"label": "1/2  X 8YDS"
	},
	{
			"value": "12X9",
			"label": "12X9"
	},
	{
			"value": "12X9.13X6",
			"label": "12X9.13X6"
	},
	{
			"value": "12X 9.63",
			"label": "12X 9.63"
	},
	{
			"value": "1/2   X 9FT",
			"label": "1/2   X 9FT"
	},
	{
			"value": "12 YARDS",
			"label": "12 YARDS"
	},
	{
			"value": "12YD",
			"label": "12YD"
	},
	{
			"value": "12YDS",
			"label": "12YDS"
	},
	{
			"value": "13",
			"label": "13"
	},
	{
			"value": "13\"\"",
			"label": "13\"\""
	},
	{
			"value": "130",
			"label": "130"
	},
	{
			"value": "1300",
			"label": "1300"
	},
	{
			"value": "13000",
			"label": "13000"
	},
	{
			"value": "13.00 X 3.25",
			"label": "13.00 X 3.25"
	},
	{
			"value": "13.00 X 7..25",
			"label": "13.00 X 7..25"
	},
	{
			"value": "13.00 X 9.50",
			"label": "13.00 X 9.50"
	},
	{
			"value": "130 X 110",
			"label": "130 X 110"
	},
	{
			"value": "13.1",
			"label": "13.1"
	},
	{
			"value": "13.10 X 3.30",
			"label": "13.10 X 3.30"
	},
	{
			"value": "13.125X1.25X13.125",
			"label": "13.125X1.25X13.125"
	},
	{
			"value": "1 3/16\"\"",
			"label": "1 3/16\"\""
	},
	{
			"value": "1-3/16",
			"label": "1-3/16"
	},
	{
			"value": "13/16",
			"label": "13/16"
	},
	{
			"value": "13/16\"\"",
			"label": "13/16\"\""
	},
	{
			"value": "1 3/16 (30MM)",
			"label": "1 3/16 (30MM)"
	},
	{
			"value": "1 3/16 IN.",
			"label": "1 3/16 IN."
	},
	{
			"value": "13/16 IN.",
			"label": "13/16 IN."
	},
	{
			"value": "1/32",
			"label": "1/32"
	},
	{
			"value": "132",
			"label": "132"
	},
	{
			"value": "13.25",
			"label": "13.25"
	},
	{
			"value": "13250",
			"label": "13250"
	},
	{
			"value": "13.25X10.25",
			"label": "13.25X10.25"
	},
	{
			"value": "13.25X10.25X2.25",
			"label": "13.25X10.25X2.25"
	},
	{
			"value": "13.25 X 11.00",
			"label": "13.25 X 11.00"
	},
	{
			"value": "13.25X13.75X1",
			"label": "13.25X13.75X1"
	},
	{
			"value": "13.25 X .75 X 12.5",
			"label": "13.25 X .75 X 12.5"
	},
	{
			"value": "13.25   X 7.75   X",
			"label": "13.25   X 7.75   X"
	},
	{
			"value": "13.25X9.25",
			"label": "13.25X9.25"
	},
	{
			"value": "13.25  X 9.75  X 50",
			"label": "13.25  X 9.75  X 50"
	},
	{
			"value": "1.32 OZ",
			"label": "1.32 OZ"
	},
	{
			"value": "13.2 OZ",
			"label": "13.2 OZ"
	},
	{
			"value": "1330",
			"label": "1330"
	},
	{
			"value": "13.375 X 12.25 X 9.6",
			"label": "13.375 X 12.25 X 9.6"
	},
	{
			"value": "13.375 X 19 INCHES",
			"label": "13.375 X 19 INCHES"
	},
	{
			"value": "13.375X1X15",
			"label": "13.375X1X15"
	},
	{
			"value": "13.375X3.74X19.5",
			"label": "13.375X3.74X19.5"
	},
	{
			"value": "13.375X9.5X7.125",
			"label": "13.375X9.5X7.125"
	},
	{
			"value": "13.37 X 3.50",
			"label": "13.37 X 3.50"
	},
	{
			"value": "1.338IN X 4YD",
			"label": "1.338IN X 4YD"
	},
	{
			"value": "1.338IN X 5YD",
			"label": "1.338IN X 5YD"
	},
	{
			"value": "13.38X10X7",
			"label": "13.38X10X7"
	},
	{
			"value": "1.339IN X 5YD",
			"label": "1.339IN X 5YD"
	},
	{
			"value": "13.39X2.76X26",
			"label": "13.39X2.76X26"
	},
	{
			"value": "1 3/4",
			"label": "1 3/4"
	},
	{
			"value": "1 3/4\"\"",
			"label": "1 3/4\"\""
	},
	{
			"value": "1-3/4",
			"label": "1-3/4"
	},
	{
			"value": "1 3/4   (44MM)",
			"label": "1 3/4   (44MM)"
	},
	{
			"value": "1 3/4 (44MM)",
			"label": "1 3/4 (44MM)"
	},
	{
			"value": "13/4 (44MM)",
			"label": "13/4 (44MM)"
	},
	{
			"value": "1 3/4 IN.",
			"label": "1 3/4 IN."
	},
	{
			"value": "13.4 IN",
			"label": "13.4 IN"
	},
	{
			"value": "1 3/4 IN (44MM)",
			"label": "1 3/4 IN (44MM)"
	},
	{
			"value": "1 3/4 INCH",
			"label": "1 3/4 INCH"
	},
	{
			"value": "1 3/4IN X 50YD",
			"label": "1 3/4IN X 50YD"
	},
	{
			"value": "13.4 OZ",
			"label": "13.4 OZ"
	},
	{
			"value": "13.4X1.97X13.4",
			"label": "13.4X1.97X13.4"
	},
	{
			"value": "1-3/4  X 2 YDS",
			"label": "1-3/4  X 2 YDS"
	},
	{
			"value": "1 3/4   X 3FT",
			"label": "1 3/4   X 3FT"
	},
	{
			"value": "1 3/4  X3FT",
			"label": "1 3/4  X3FT"
	},
	{
			"value": "1.34 X 7.8 X 11.46",
			"label": "1.34 X 7.8 X 11.46"
	},
	{
			"value": "1 3/4   X  8YDS",
			"label": "1 3/4   X  8YDS"
	},
	{
			"value": "13.5",
			"label": "13.5"
	},
	{
			"value": "135",
			"label": "135"
	},
	{
			"value": "13.50",
			"label": "13.50"
	},
	{
			"value": "1350",
			"label": "1350"
	},
	{
			"value": "13500",
			"label": "13500"
	},
	{
			"value": "13.50 X 10.75",
			"label": "13.50 X 10.75"
	},
	{
			"value": "13.5IN",
			"label": "13.5IN"
	},
	{
			"value": "13.5 INCH",
			"label": "13.5 INCH"
	},
	{
			"value": "1.35OZ",
			"label": "1.35OZ"
	},
	{
			"value": "13.5 OZ",
			"label": "13.5 OZ"
	},
	{
			"value": "13.5OZ JAR",
			"label": "13.5OZ JAR"
	},
	{
			"value": "13.5  X11.5  X2",
			"label": "13.5  X11.5  X2"
	},
	{
			"value": "13.5   X 12.75   X",
			"label": "13.5   X 12.75   X"
	},
	{
			"value": "13.5   X 13.5   X 1",
			"label": "13.5   X 13.5   X 1"
	},
	{
			"value": "13.5 X 13.5X 11 IN",
			"label": "13.5 X 13.5X 11 IN"
	},
	{
			"value": "13.5 X 13.5 X 18.5",
			"label": "13.5 X 13.5 X 18.5"
	},
	{
			"value": "13.5  X13.5  X2",
			"label": "13.5  X13.5  X2"
	},
	{
			"value": "13.5 X 19",
			"label": "13.5 X 19"
	},
	{
			"value": "13.5X1X30.75",
			"label": "13.5X1X30.75"
	},
	{
			"value": "13.5X7.5X10.75",
			"label": "13.5X7.5X10.75"
	},
	{
			"value": "13.5   X 9.75   X 5",
			"label": "13.5   X 9.75   X 5"
	},
	{
			"value": "13632",
			"label": "13632"
	},
	{
			"value": "13644",
			"label": "13644"
	},
	{
			"value": "1368",
			"label": "1368"
	},
	{
			"value": "13.6 INCH",
			"label": "13.6 INCH"
	},
	{
			"value": "1.36 OZ",
			"label": "1.36 OZ"
	},
	{
			"value": "136 X 84",
			"label": "136 X 84"
	},
	{
			"value": "136 X 96",
			"label": "136 X 96"
	},
	{
			"value": "137",
			"label": "137"
	},
	{
			"value": "13700",
			"label": "13700"
	},
	{
			"value": "137.16",
			"label": "137.16"
	},
	{
			"value": "1.375",
			"label": "1.375"
	},
	{
			"value": "1375",
			"label": "1375"
	},
	{
			"value": "1.375IN",
			"label": "1.375IN"
	},
	{
			"value": "1.375 INCH",
			"label": "1.375 INCH"
	},
	{
			"value": "13.75  X0.25  X10.2",
			"label": "13.75  X0.25  X10.2"
	},
	{
			"value": "13.75  X0.25  X19.6",
			"label": "13.75  X0.25  X19.6"
	},
	{
			"value": "13.75X10.65X10",
			"label": "13.75X10.65X10"
	},
	{
			"value": "13.75 X 10.75",
			"label": "13.75 X 10.75"
	},
	{
			"value": "13.75X10.75X5.625",
			"label": "13.75X10.75X5.625"
	},
	{
			"value": "13.75X12.75X3.75",
			"label": "13.75X12.75X3.75"
	},
	{
			"value": "13.75X26",
			"label": "13.75X26"
	},
	{
			"value": "13.75 X 5.25",
			"label": "13.75 X 5.25"
	},
	{
			"value": "13.75X6.875X14",
			"label": "13.75X6.875X14"
	},
	{
			"value": "1.375   X 6YD",
			"label": "1.375   X 6YD"
	},
	{
			"value": "13.75 X 7.37",
			"label": "13.75 X 7.37"
	},
	{
			"value": "13.78 X 7.5 X 9.25",
			"label": "13.78 X 7.5 X 9.25"
	},
	{
			"value": "13.7IN*8",
			"label": "13.7IN*8"
	},
	{
			"value": "1.37 OZ",
			"label": "1.37 OZ"
	},
	{
			"value": "1-3/8",
			"label": "1-3/8"
	},
	{
			"value": "1-3/8''",
			"label": "1-3/8''"
	},
	{
			"value": "1.38",
			"label": "1.38"
	},
	{
			"value": "1 3/8   (34MM)",
			"label": "1 3/8   (34MM)"
	},
	{
			"value": "1 3/8  (34MM)",
			"label": "1 3/8  (34MM)"
	},
	{
			"value": "1 3/8 (34MM)",
			"label": "1 3/8 (34MM)"
	},
	{
			"value": "13.875   X 5.875",
			"label": "13.875   X 5.875"
	},
	{
			"value": "1 3/8 IN.",
			"label": "1 3/8 IN."
	},
	{
			"value": "13.8IN",
			"label": "13.8IN"
	},
	{
			"value": "1.38 OZ",
			"label": "1.38 OZ"
	},
	{
			"value": "13.8 OZ",
			"label": "13.8 OZ"
	},
	{
			"value": "138 X 110",
			"label": "138 X 110"
	},
	{
			"value": "13.8X14.2",
			"label": "13.8X14.2"
	},
	{
			"value": "1-3/8   X 2 YARDS",
			"label": "1-3/8   X 2 YARDS"
	},
	{
			"value": "1 3/8   X 3FT",
			"label": "1 3/8   X 3FT"
	},
	{
			"value": "138 X 96",
			"label": "138 X 96"
	},
	{
			"value": "1392",
			"label": "1392"
	},
	{
			"value": "13920",
			"label": "13920"
	},
	{
			"value": "13936",
			"label": "13936"
	},
	{
			"value": "1.39 OZ",
			"label": "1.39 OZ"
	},
	{
			"value": "13CT",
			"label": "13CT"
	},
	{
			"value": "13   EACH",
			"label": "13   EACH"
	},
	{
			"value": "13FT",
			"label": "13FT"
	},
	{
			"value": "13 IN",
			"label": "13 IN"
	},
	{
			"value": "13IN",
			"label": "13IN"
	},
	{
			"value": "13 INCH",
			"label": "13 INCH"
	},
	{
			"value": "13MM",
			"label": "13MM"
	},
	{
			"value": ".13 OZ",
			"label": ".13 OZ"
	},
	{
			"value": "1.3 OZ",
			"label": "1.3 OZ"
	},
	{
			"value": "1.3 OZ.",
			"label": "1.3 OZ."
	},
	{
			"value": "13 OZ",
			"label": "13 OZ"
	},
	{
			"value": "13OZ",
			"label": "13OZ"
	},
	{
			"value": "13 OZ JAR CANDLE",
			"label": "13 OZ JAR CANDLE"
	},
	{
			"value": "13PC",
			"label": "13PC"
	},
	{
			"value": "13 PIECES",
			"label": "13 PIECES"
	},
	{
			"value": "13 X 10 X 2",
			"label": "13 X 10 X 2"
	},
	{
			"value": "13   X 10   X 2",
			"label": "13   X 10   X 2"
	},
	{
			"value": "13X11",
			"label": "13X11"
	},
	{
			"value": "13X1.25X25",
			"label": "13X1.25X25"
	},
	{
			"value": "13X13",
			"label": "13X13"
	},
	{
			"value": "13 X 13 X 13 IN",
			"label": "13 X 13 X 13 IN"
	},
	{
			"value": "13 X 13 X 17.7",
			"label": "13 X 13 X 17.7"
	},
	{
			"value": "13   X 17",
			"label": "13   X 17"
	},
	{
			"value": "13X19",
			"label": "13X19"
	},
	{
			"value": "13X3.2X4.3",
			"label": "13X3.2X4.3"
	},
	{
			"value": "13X4X13",
			"label": "13X4X13"
	},
	{
			"value": "13X5",
			"label": "13X5"
	},
	{
			"value": "13X5X14.5",
			"label": "13X5X14.5"
	},
	{
			"value": "13 X 5 X 4.5",
			"label": "13 X 5 X 4.5"
	},
	{
			"value": "13 X 5 X 7",
			"label": "13 X 5 X 7"
	},
	{
			"value": "13X6",
			"label": "13X6"
	},
	{
			"value": "13 X 6 X 5.625",
			"label": "13 X 6 X 5.625"
	},
	{
			"value": "13X6X8",
			"label": "13X6X8"
	},
	{
			"value": "13   X 7   X 7",
			"label": "13   X 7   X 7"
	},
	{
			"value": "13 X 8.5",
			"label": "13 X 8.5"
	},
	{
			"value": "13X9",
			"label": "13X9"
	},
	{
			"value": "13X9.5",
			"label": "13X9.5"
	},
	{
			"value": "13 X 9 X 2",
			"label": "13 X 9 X 2"
	},
	{
			"value": "13 X 9 X 20",
			"label": "13 X 9 X 20"
	},
	{
			"value": "13 X 9 X 2 IN",
			"label": "13 X 9 X 2 IN"
	},
	{
			"value": "1/4",
			"label": "1/4"
	},
	{
			"value": "1/4\"\"",
			"label": "1/4\"\""
	},
	{
			"value": "14",
			"label": "14"
	},
	{
			"value": "14\"\"",
			"label": "14\"\""
	},
	{
			"value": "140",
			"label": "140"
	},
	{
			"value": "1400",
			"label": "1400"
	},
	{
			"value": "14000",
			"label": "14000"
	},
	{
			"value": "14.00 X 11.75",
			"label": "14.00 X 11.75"
	},
	{
			"value": "14.00 X 14.00",
			"label": "14.00 X 14.00"
	},
	{
			"value": "14.00 X 7.25",
			"label": "14.00 X 7.25"
	},
	{
			"value": "14040",
			"label": "14040"
	},
	{
			"value": "140 GR",
			"label": "140 GR"
	},
	{
			"value": "140GR",
			"label": "140GR"
	},
	{
			"value": "140 X 110",
			"label": "140 X 110"
	},
	{
			"value": "14.0 X 11.4 X 6.0 IN",
			"label": "14.0 X 11.4 X 6.0 IN"
	},
	{
			"value": "14.0 X 14.0 X 11.0 I",
			"label": "14.0 X 14.0 X 11.0 I"
	},
	{
			"value": "14.1",
			"label": "14.1"
	},
	{
			"value": "14.125 X 13.625 X 3",
			"label": "14.125 X 13.625 X 3"
	},
	{
			"value": "14.125 X 3.375 X 24",
			"label": "14.125 X 3.375 X 24"
	},
	{
			"value": "14.125 X 9 X 2",
			"label": "14.125 X 9 X 2"
	},
	{
			"value": "14.125 X 9 X  X 2",
			"label": "14.125 X 9 X  X 2"
	},
	{
			"value": "141.5X14",
			"label": "141.5X14"
	},
	{
			"value": "(14-16-18)",
			"label": "(14-16-18)"
	},
	{
			"value": "14.17",
			"label": "14.17"
	},
	{
			"value": "1.417IN X 5YD",
			"label": "1.417IN X 5YD"
	},
	{
			"value": "14 1/8 X 14 1/8",
			"label": "14 1/8 X 14 1/8"
	},
	{
			"value": "141G",
			"label": "141G"
	},
	{
			"value": "141GR",
			"label": "141GR"
	},
	{
			"value": "1.41 IN X 45 YD",
			"label": "1.41 IN X 45 YD"
	},
	{
			"value": "1.41 OZ",
			"label": "1.41 OZ"
	},
	{
			"value": "14.1 OZ",
			"label": "14.1 OZ"
	},
	{
			"value": "14.25  H X 8.25  W",
			"label": "14.25  H X 8.25  W"
	},
	{
			"value": "14.25X14.25",
			"label": "14.25X14.25"
	},
	{
			"value": "14.25X15X35",
			"label": "14.25X15X35"
	},
	{
			"value": "142GR",
			"label": "142GR"
	},
	{
			"value": "14.2 IN",
			"label": "14.2 IN"
	},
	{
			"value": "14.2IN",
			"label": "14.2IN"
	},
	{
			"value": "14.2''LX14.2''WX4.7'",
			"label": "14.2''LX14.2''WX4.7'"
	},
	{
			"value": "14.2 OZ",
			"label": "14.2 OZ"
	},
	{
			"value": "14.2   X 10.3   X 1",
			"label": "14.2   X 10.3   X 1"
	},
	{
			"value": "142 X 84",
			"label": "142 X 84"
	},
	{
			"value": "14.30 X 12.70",
			"label": "14.30 X 12.70"
	},
	{
			"value": "14.375 X 6.25 X 17.8",
			"label": "14.375 X 6.25 X 17.8"
	},
	{
			"value": "14.375 X 6 X 8.75",
			"label": "14.375 X 6 X 8.75"
	},
	{
			"value": "14.37 X 10.25",
			"label": "14.37 X 10.25"
	},
	{
			"value": "14.37 X 12.87",
			"label": "14.37 X 12.87"
	},
	{
			"value": "14.3 OZ",
			"label": "14.3 OZ"
	},
	{
			"value": "144",
			"label": "144"
	},
	{
			"value": "1440",
			"label": "1440"
	},
	{
			"value": "14400",
			"label": "14400"
	},
	{
			"value": "14475453",
			"label": "14475453"
	},
	{
			"value": "14475461",
			"label": "14475461"
	},
	{
			"value": "14475479",
			"label": "14475479"
	},
	{
			"value": "14475487",
			"label": "14475487"
	},
	{
			"value": "14475495",
			"label": "14475495"
	},
	{
			"value": "14475503",
			"label": "14475503"
	},
	{
			"value": "14475511",
			"label": "14475511"
	},
	{
			"value": "14475529",
			"label": "14475529"
	},
	{
			"value": "14475537",
			"label": "14475537"
	},
	{
			"value": "14475545",
			"label": "14475545"
	},
	{
			"value": "14475552",
			"label": "14475552"
	},
	{
			"value": "14475560",
			"label": "14475560"
	},
	{
			"value": "14475578",
			"label": "14475578"
	},
	{
			"value": "14475586",
			"label": "14475586"
	},
	{
			"value": "14475594",
			"label": "14475594"
	},
	{
			"value": "14475602",
			"label": "14475602"
	},
	{
			"value": "14475610",
			"label": "14475610"
	},
	{
			"value": "14475628",
			"label": "14475628"
	},
	{
			"value": "14475636",
			"label": "14475636"
	},
	{
			"value": "14475644",
			"label": "14475644"
	},
	{
			"value": "14475651",
			"label": "14475651"
	},
	{
			"value": "14475669",
			"label": "14475669"
	},
	{
			"value": "14475677",
			"label": "14475677"
	},
	{
			"value": "14475685",
			"label": "14475685"
	},
	{
			"value": "14475693",
			"label": "14475693"
	},
	{
			"value": "14475701",
			"label": "14475701"
	},
	{
			"value": "14475719",
			"label": "14475719"
	},
	{
			"value": "14475727",
			"label": "14475727"
	},
	{
			"value": "14475735",
			"label": "14475735"
	},
	{
			"value": "14475743",
			"label": "14475743"
	},
	{
			"value": "14475750",
			"label": "14475750"
	},
	{
			"value": "14475768",
			"label": "14475768"
	},
	{
			"value": "14475776",
			"label": "14475776"
	},
	{
			"value": "14475784",
			"label": "14475784"
	},
	{
			"value": "14475792",
			"label": "14475792"
	},
	{
			"value": "14475800",
			"label": "14475800"
	},
	{
			"value": "14475818",
			"label": "14475818"
	},
	{
			"value": "14475826",
			"label": "14475826"
	},
	{
			"value": "14475834",
			"label": "14475834"
	},
	{
			"value": "14475842",
			"label": "14475842"
	},
	{
			"value": "14475859",
			"label": "14475859"
	},
	{
			"value": "14475867",
			"label": "14475867"
	},
	{
			"value": "14475875",
			"label": "14475875"
	},
	{
			"value": "14475883",
			"label": "14475883"
	},
	{
			"value": "14475891",
			"label": "14475891"
	},
	{
			"value": "14475909",
			"label": "14475909"
	},
	{
			"value": "14475917",
			"label": "14475917"
	},
	{
			"value": "14475925",
			"label": "14475925"
	},
	{
			"value": "14475933",
			"label": "14475933"
	},
	{
			"value": "14475941",
			"label": "14475941"
	},
	{
			"value": "14475958",
			"label": "14475958"
	},
	{
			"value": "14475966",
			"label": "14475966"
	},
	{
			"value": "14475974",
			"label": "14475974"
	},
	{
			"value": "14475982",
			"label": "14475982"
	},
	{
			"value": "14475990",
			"label": "14475990"
	},
	{
			"value": "14476006",
			"label": "14476006"
	},
	{
			"value": "14476014",
			"label": "14476014"
	},
	{
			"value": "14476022",
			"label": "14476022"
	},
	{
			"value": "14476030",
			"label": "14476030"
	},
	{
			"value": "14476048",
			"label": "14476048"
	},
	{
			"value": "14476055",
			"label": "14476055"
	},
	{
			"value": "14476063",
			"label": "14476063"
	},
	{
			"value": "14476071",
			"label": "14476071"
	},
	{
			"value": "14476089",
			"label": "14476089"
	},
	{
			"value": "14476097",
			"label": "14476097"
	},
	{
			"value": "14476105",
			"label": "14476105"
	},
	{
			"value": "14476113",
			"label": "14476113"
	},
	{
			"value": "14476121",
			"label": "14476121"
	},
	{
			"value": "14476139",
			"label": "14476139"
	},
	{
			"value": "14476147",
			"label": "14476147"
	},
	{
			"value": "14476154",
			"label": "14476154"
	},
	{
			"value": "14476162",
			"label": "14476162"
	},
	{
			"value": "14476170",
			"label": "14476170"
	},
	{
			"value": "14476188",
			"label": "14476188"
	},
	{
			"value": "14476196",
			"label": "14476196"
	},
	{
			"value": "14476204",
			"label": "14476204"
	},
	{
			"value": "14476212",
			"label": "14476212"
	},
	{
			"value": "14476220",
			"label": "14476220"
	},
	{
			"value": "14476238",
			"label": "14476238"
	},
	{
			"value": "14476246",
			"label": "14476246"
	},
	{
			"value": "14476253",
			"label": "14476253"
	},
	{
			"value": "14476261",
			"label": "14476261"
	},
	{
			"value": "14476279",
			"label": "14476279"
	},
	{
			"value": "14476287",
			"label": "14476287"
	},
	{
			"value": "14476295",
			"label": "14476295"
	},
	{
			"value": "14476303",
			"label": "14476303"
	},
	{
			"value": "14476311",
			"label": "14476311"
	},
	{
			"value": "14476329",
			"label": "14476329"
	},
	{
			"value": "14476337",
			"label": "14476337"
	},
	{
			"value": "14476345",
			"label": "14476345"
	},
	{
			"value": "14476352",
			"label": "14476352"
	},
	{
			"value": "14476360",
			"label": "14476360"
	},
	{
			"value": "14476378",
			"label": "14476378"
	},
	{
			"value": "14476386",
			"label": "14476386"
	},
	{
			"value": "14476394",
			"label": "14476394"
	},
	{
			"value": "14476402",
			"label": "14476402"
	},
	{
			"value": "14476410",
			"label": "14476410"
	},
	{
			"value": "14476428",
			"label": "14476428"
	},
	{
			"value": "14476436",
			"label": "14476436"
	},
	{
			"value": "14476444",
			"label": "14476444"
	},
	{
			"value": "14476451",
			"label": "14476451"
	},
	{
			"value": "14476469",
			"label": "14476469"
	},
	{
			"value": "14476477",
			"label": "14476477"
	},
	{
			"value": "14476485",
			"label": "14476485"
	},
	{
			"value": "14476493",
			"label": "14476493"
	},
	{
			"value": "14476501",
			"label": "14476501"
	},
	{
			"value": "14476519",
			"label": "14476519"
	},
	{
			"value": "14476527",
			"label": "14476527"
	},
	{
			"value": "14476535",
			"label": "14476535"
	},
	{
			"value": "14476543",
			"label": "14476543"
	},
	{
			"value": "14476550",
			"label": "14476550"
	},
	{
			"value": "14476568",
			"label": "14476568"
	},
	{
			"value": "14476576",
			"label": "14476576"
	},
	{
			"value": "14476584",
			"label": "14476584"
	},
	{
			"value": "14476592",
			"label": "14476592"
	},
	{
			"value": "14476600",
			"label": "14476600"
	},
	{
			"value": "14476618",
			"label": "14476618"
	},
	{
			"value": "14476626",
			"label": "14476626"
	},
	{
			"value": "14476634",
			"label": "14476634"
	},
	{
			"value": "14476642",
			"label": "14476642"
	},
	{
			"value": "14476659",
			"label": "14476659"
	},
	{
			"value": "14476667",
			"label": "14476667"
	},
	{
			"value": "14476675",
			"label": "14476675"
	},
	{
			"value": "14476683",
			"label": "14476683"
	},
	{
			"value": "14476691",
			"label": "14476691"
	},
	{
			"value": "14476709",
			"label": "14476709"
	},
	{
			"value": "14476717",
			"label": "14476717"
	},
	{
			"value": "14476725",
			"label": "14476725"
	},
	{
			"value": "14476733",
			"label": "14476733"
	},
	{
			"value": "14476741",
			"label": "14476741"
	},
	{
			"value": "14476758",
			"label": "14476758"
	},
	{
			"value": "14476766",
			"label": "14476766"
	},
	{
			"value": "14476774",
			"label": "14476774"
	},
	{
			"value": "14476782",
			"label": "14476782"
	},
	{
			"value": "14476790",
			"label": "14476790"
	},
	{
			"value": "14476808",
			"label": "14476808"
	},
	{
			"value": "14476816",
			"label": "14476816"
	},
	{
			"value": "14476824",
			"label": "14476824"
	},
	{
			"value": "14476832",
			"label": "14476832"
	},
	{
			"value": "14476840",
			"label": "14476840"
	},
	{
			"value": "14476857",
			"label": "14476857"
	},
	{
			"value": "14476865",
			"label": "14476865"
	},
	{
			"value": "14476873",
			"label": "14476873"
	},
	{
			"value": "14476881",
			"label": "14476881"
	},
	{
			"value": "14476899",
			"label": "14476899"
	},
	{
			"value": "14476907",
			"label": "14476907"
	},
	{
			"value": "14476915",
			"label": "14476915"
	},
	{
			"value": "14476923",
			"label": "14476923"
	},
	{
			"value": "14476931",
			"label": "14476931"
	},
	{
			"value": "14476949",
			"label": "14476949"
	},
	{
			"value": "14476956",
			"label": "14476956"
	},
	{
			"value": "14476964",
			"label": "14476964"
	},
	{
			"value": "14476972",
			"label": "14476972"
	},
	{
			"value": "14476980",
			"label": "14476980"
	},
	{
			"value": "14476998",
			"label": "14476998"
	},
	{
			"value": "14477004",
			"label": "14477004"
	},
	{
			"value": "14477012",
			"label": "14477012"
	},
	{
			"value": "14477020",
			"label": "14477020"
	},
	{
			"value": "14477038",
			"label": "14477038"
	},
	{
			"value": "14477046",
			"label": "14477046"
	},
	{
			"value": "14477053",
			"label": "14477053"
	},
	{
			"value": "14477061",
			"label": "14477061"
	},
	{
			"value": "14477079",
			"label": "14477079"
	},
	{
			"value": "14477087",
			"label": "14477087"
	},
	{
			"value": "14477095",
			"label": "14477095"
	},
	{
			"value": "14477103",
			"label": "14477103"
	},
	{
			"value": "14477111",
			"label": "14477111"
	},
	{
			"value": "14477129",
			"label": "14477129"
	},
	{
			"value": "14477137",
			"label": "14477137"
	},
	{
			"value": "14477145",
			"label": "14477145"
	},
	{
			"value": "14477152",
			"label": "14477152"
	},
	{
			"value": "14477160",
			"label": "14477160"
	},
	{
			"value": "14477178",
			"label": "14477178"
	},
	{
			"value": "14477186",
			"label": "14477186"
	},
	{
			"value": "14477194",
			"label": "14477194"
	},
	{
			"value": "14477202",
			"label": "14477202"
	},
	{
			"value": "14477210",
			"label": "14477210"
	},
	{
			"value": "14477228",
			"label": "14477228"
	},
	{
			"value": "14477236",
			"label": "14477236"
	},
	{
			"value": "14477244",
			"label": "14477244"
	},
	{
			"value": "14477251",
			"label": "14477251"
	},
	{
			"value": "14477269",
			"label": "14477269"
	},
	{
			"value": "14477277",
			"label": "14477277"
	},
	{
			"value": "14477285",
			"label": "14477285"
	},
	{
			"value": "14477293",
			"label": "14477293"
	},
	{
			"value": "14477301",
			"label": "14477301"
	},
	{
			"value": "14477319",
			"label": "14477319"
	},
	{
			"value": "14477327",
			"label": "14477327"
	},
	{
			"value": "14477335",
			"label": "14477335"
	},
	{
			"value": "14477343",
			"label": "14477343"
	},
	{
			"value": "14477350",
			"label": "14477350"
	},
	{
			"value": "14477368",
			"label": "14477368"
	},
	{
			"value": "14477376",
			"label": "14477376"
	},
	{
			"value": "14477384",
			"label": "14477384"
	},
	{
			"value": "14477392",
			"label": "14477392"
	},
	{
			"value": "14477400",
			"label": "14477400"
	},
	{
			"value": "14477418",
			"label": "14477418"
	},
	{
			"value": "14477426",
			"label": "14477426"
	},
	{
			"value": "14477434",
			"label": "14477434"
	},
	{
			"value": "14477442",
			"label": "14477442"
	},
	{
			"value": "14477459",
			"label": "14477459"
	},
	{
			"value": "14477467",
			"label": "14477467"
	},
	{
			"value": "14477475",
			"label": "14477475"
	},
	{
			"value": "14477483",
			"label": "14477483"
	},
	{
			"value": "14477491",
			"label": "14477491"
	},
	{
			"value": "14477509",
			"label": "14477509"
	},
	{
			"value": "14477517",
			"label": "14477517"
	},
	{
			"value": "14477525",
			"label": "14477525"
	},
	{
			"value": "14477533",
			"label": "14477533"
	},
	{
			"value": "14477541",
			"label": "14477541"
	},
	{
			"value": "14477558",
			"label": "14477558"
	},
	{
			"value": "14477566",
			"label": "14477566"
	},
	{
			"value": "14477574",
			"label": "14477574"
	},
	{
			"value": "14477582",
			"label": "14477582"
	},
	{
			"value": "14477590",
			"label": "14477590"
	},
	{
			"value": "14477608",
			"label": "14477608"
	},
	{
			"value": "14477616",
			"label": "14477616"
	},
	{
			"value": "14477624",
			"label": "14477624"
	},
	{
			"value": "14477632",
			"label": "14477632"
	},
	{
			"value": "14477640",
			"label": "14477640"
	},
	{
			"value": "14477657",
			"label": "14477657"
	},
	{
			"value": "14477665",
			"label": "14477665"
	},
	{
			"value": "14477673",
			"label": "14477673"
	},
	{
			"value": "14477681",
			"label": "14477681"
	},
	{
			"value": "14477699",
			"label": "14477699"
	},
	{
			"value": "14477707",
			"label": "14477707"
	},
	{
			"value": "14477715",
			"label": "14477715"
	},
	{
			"value": "14477723",
			"label": "14477723"
	},
	{
			"value": "14477731",
			"label": "14477731"
	},
	{
			"value": "14477749",
			"label": "14477749"
	},
	{
			"value": "14477756",
			"label": "14477756"
	},
	{
			"value": "14477764",
			"label": "14477764"
	},
	{
			"value": "14477772",
			"label": "14477772"
	},
	{
			"value": "14477780",
			"label": "14477780"
	},
	{
			"value": "14477798",
			"label": "14477798"
	},
	{
			"value": "14477806",
			"label": "14477806"
	},
	{
			"value": "14477814",
			"label": "14477814"
	},
	{
			"value": "14477822",
			"label": "14477822"
	},
	{
			"value": "14477830",
			"label": "14477830"
	},
	{
			"value": "14477848",
			"label": "14477848"
	},
	{
			"value": "14477855",
			"label": "14477855"
	},
	{
			"value": "14477863",
			"label": "14477863"
	},
	{
			"value": "14477871",
			"label": "14477871"
	},
	{
			"value": "14477889",
			"label": "14477889"
	},
	{
			"value": "14477897",
			"label": "14477897"
	},
	{
			"value": "14477905",
			"label": "14477905"
	},
	{
			"value": "14477913",
			"label": "14477913"
	},
	{
			"value": "14477921",
			"label": "14477921"
	},
	{
			"value": "14477939",
			"label": "14477939"
	},
	{
			"value": "14477947",
			"label": "14477947"
	},
	{
			"value": "14477954",
			"label": "14477954"
	},
	{
			"value": "14477962",
			"label": "14477962"
	},
	{
			"value": "14477970",
			"label": "14477970"
	},
	{
			"value": "14477988",
			"label": "14477988"
	},
	{
			"value": "14477996",
			"label": "14477996"
	},
	{
			"value": "144.78",
			"label": "144.78"
	},
	{
			"value": "14478002",
			"label": "14478002"
	},
	{
			"value": "14478010",
			"label": "14478010"
	},
	{
			"value": "14478028",
			"label": "14478028"
	},
	{
			"value": "14478036",
			"label": "14478036"
	},
	{
			"value": "14478044",
			"label": "14478044"
	},
	{
			"value": "14478051",
			"label": "14478051"
	},
	{
			"value": "14478069",
			"label": "14478069"
	},
	{
			"value": "14478077",
			"label": "14478077"
	},
	{
			"value": "14478085",
			"label": "14478085"
	},
	{
			"value": "14478093",
			"label": "14478093"
	},
	{
			"value": "14478101",
			"label": "14478101"
	},
	{
			"value": "14478119",
			"label": "14478119"
	},
	{
			"value": "14478127",
			"label": "14478127"
	},
	{
			"value": "14478135",
			"label": "14478135"
	},
	{
			"value": "14478143",
			"label": "14478143"
	},
	{
			"value": "14478150",
			"label": "14478150"
	},
	{
			"value": "14478168",
			"label": "14478168"
	},
	{
			"value": "14478176",
			"label": "14478176"
	},
	{
			"value": "14478184",
			"label": "14478184"
	},
	{
			"value": "14478192",
			"label": "14478192"
	},
	{
			"value": "14478200",
			"label": "14478200"
	},
	{
			"value": "14478218",
			"label": "14478218"
	},
	{
			"value": "14478226",
			"label": "14478226"
	},
	{
			"value": "14478234",
			"label": "14478234"
	},
	{
			"value": "14478242",
			"label": "14478242"
	},
	{
			"value": "14478259",
			"label": "14478259"
	},
	{
			"value": "14478267",
			"label": "14478267"
	},
	{
			"value": "14478275",
			"label": "14478275"
	},
	{
			"value": "14478283",
			"label": "14478283"
	},
	{
			"value": "14478291",
			"label": "14478291"
	},
	{
			"value": "14478309",
			"label": "14478309"
	},
	{
			"value": "14478317",
			"label": "14478317"
	},
	{
			"value": "14478325",
			"label": "14478325"
	},
	{
			"value": "14478333",
			"label": "14478333"
	},
	{
			"value": "14478341",
			"label": "14478341"
	},
	{
			"value": "14478358",
			"label": "14478358"
	},
	{
			"value": "14478366",
			"label": "14478366"
	},
	{
			"value": "14478374",
			"label": "14478374"
	},
	{
			"value": "14478382",
			"label": "14478382"
	},
	{
			"value": "14478390",
			"label": "14478390"
	},
	{
			"value": "14478408",
			"label": "14478408"
	},
	{
			"value": "14478416",
			"label": "14478416"
	},
	{
			"value": "14478424",
			"label": "14478424"
	},
	{
			"value": "14478432",
			"label": "14478432"
	},
	{
			"value": "14478440",
			"label": "14478440"
	},
	{
			"value": "14478457",
			"label": "14478457"
	},
	{
			"value": "14478465",
			"label": "14478465"
	},
	{
			"value": "14478473",
			"label": "14478473"
	},
	{
			"value": "14478481",
			"label": "14478481"
	},
	{
			"value": "14478499",
			"label": "14478499"
	},
	{
			"value": "14478507",
			"label": "14478507"
	},
	{
			"value": "14478515",
			"label": "14478515"
	},
	{
			"value": "14478523",
			"label": "14478523"
	},
	{
			"value": "14478531",
			"label": "14478531"
	},
	{
			"value": "14478549",
			"label": "14478549"
	},
	{
			"value": "14478556",
			"label": "14478556"
	},
	{
			"value": "14478564",
			"label": "14478564"
	},
	{
			"value": "14478572",
			"label": "14478572"
	},
	{
			"value": "14478580",
			"label": "14478580"
	},
	{
			"value": "14478598",
			"label": "14478598"
	},
	{
			"value": "14478606",
			"label": "14478606"
	},
	{
			"value": "14478614",
			"label": "14478614"
	},
	{
			"value": "14478622",
			"label": "14478622"
	},
	{
			"value": "14478630",
			"label": "14478630"
	},
	{
			"value": "14478648",
			"label": "14478648"
	},
	{
			"value": "14478655",
			"label": "14478655"
	},
	{
			"value": "14478663",
			"label": "14478663"
	},
	{
			"value": "14478671",
			"label": "14478671"
	},
	{
			"value": "14478689",
			"label": "14478689"
	},
	{
			"value": "14478697",
			"label": "14478697"
	},
	{
			"value": "14478705",
			"label": "14478705"
	},
	{
			"value": "14478713",
			"label": "14478713"
	},
	{
			"value": "14478721",
			"label": "14478721"
	},
	{
			"value": "14478739",
			"label": "14478739"
	},
	{
			"value": "14478747",
			"label": "14478747"
	},
	{
			"value": "14478754",
			"label": "14478754"
	},
	{
			"value": "14478762",
			"label": "14478762"
	},
	{
			"value": "14478770",
			"label": "14478770"
	},
	{
			"value": "14478788",
			"label": "14478788"
	},
	{
			"value": "14478796",
			"label": "14478796"
	},
	{
			"value": "14478804",
			"label": "14478804"
	},
	{
			"value": "14478812",
			"label": "14478812"
	},
	{
			"value": "14478820",
			"label": "14478820"
	},
	{
			"value": "14478838",
			"label": "14478838"
	},
	{
			"value": "14478846",
			"label": "14478846"
	},
	{
			"value": "14478853",
			"label": "14478853"
	},
	{
			"value": "14478861",
			"label": "14478861"
	},
	{
			"value": "14478879",
			"label": "14478879"
	},
	{
			"value": "14478887",
			"label": "14478887"
	},
	{
			"value": "14478895",
			"label": "14478895"
	},
	{
			"value": "14478903",
			"label": "14478903"
	},
	{
			"value": "14478911",
			"label": "14478911"
	},
	{
			"value": "14478929",
			"label": "14478929"
	},
	{
			"value": "14478937",
			"label": "14478937"
	},
	{
			"value": "14478945",
			"label": "14478945"
	},
	{
			"value": "14478952",
			"label": "14478952"
	},
	{
			"value": "14478960",
			"label": "14478960"
	},
	{
			"value": "14478978",
			"label": "14478978"
	},
	{
			"value": "14478986",
			"label": "14478986"
	},
	{
			"value": "14478994",
			"label": "14478994"
	},
	{
			"value": "14479000",
			"label": "14479000"
	},
	{
			"value": "14479018",
			"label": "14479018"
	},
	{
			"value": "14479026",
			"label": "14479026"
	},
	{
			"value": "14479034",
			"label": "14479034"
	},
	{
			"value": "14479042",
			"label": "14479042"
	},
	{
			"value": "14479059",
			"label": "14479059"
	},
	{
			"value": "14479067",
			"label": "14479067"
	},
	{
			"value": "14479075",
			"label": "14479075"
	},
	{
			"value": "14479083",
			"label": "14479083"
	},
	{
			"value": "14479091",
			"label": "14479091"
	},
	{
			"value": "14479109",
			"label": "14479109"
	},
	{
			"value": "14479117",
			"label": "14479117"
	},
	{
			"value": "14479125",
			"label": "14479125"
	},
	{
			"value": "14479133",
			"label": "14479133"
	},
	{
			"value": "14479141",
			"label": "14479141"
	},
	{
			"value": "14479158",
			"label": "14479158"
	},
	{
			"value": "14479166",
			"label": "14479166"
	},
	{
			"value": "14479174",
			"label": "14479174"
	},
	{
			"value": "14479182",
			"label": "14479182"
	},
	{
			"value": "14479190",
			"label": "14479190"
	},
	{
			"value": "14479208",
			"label": "14479208"
	},
	{
			"value": "14479216",
			"label": "14479216"
	},
	{
			"value": "14479224",
			"label": "14479224"
	},
	{
			"value": "14479232",
			"label": "14479232"
	},
	{
			"value": "14479240",
			"label": "14479240"
	},
	{
			"value": "14479257",
			"label": "14479257"
	},
	{
			"value": "14479265",
			"label": "14479265"
	},
	{
			"value": "14479273",
			"label": "14479273"
	},
	{
			"value": "14479281",
			"label": "14479281"
	},
	{
			"value": "14479299",
			"label": "14479299"
	},
	{
			"value": "14479307",
			"label": "14479307"
	},
	{
			"value": "14479315",
			"label": "14479315"
	},
	{
			"value": "14479323",
			"label": "14479323"
	},
	{
			"value": "14479331",
			"label": "14479331"
	},
	{
			"value": "14479349",
			"label": "14479349"
	},
	{
			"value": "14479356",
			"label": "14479356"
	},
	{
			"value": "14479364",
			"label": "14479364"
	},
	{
			"value": "14479372",
			"label": "14479372"
	},
	{
			"value": "14479380",
			"label": "14479380"
	},
	{
			"value": "14479398",
			"label": "14479398"
	},
	{
			"value": "14479406",
			"label": "14479406"
	},
	{
			"value": "14479414",
			"label": "14479414"
	},
	{
			"value": "14479422",
			"label": "14479422"
	},
	{
			"value": "14479430",
			"label": "14479430"
	},
	{
			"value": "14479448",
			"label": "14479448"
	},
	{
			"value": "14479455",
			"label": "14479455"
	},
	{
			"value": "14479463",
			"label": "14479463"
	},
	{
			"value": "14479471",
			"label": "14479471"
	},
	{
			"value": "14479489",
			"label": "14479489"
	},
	{
			"value": "14479497",
			"label": "14479497"
	},
	{
			"value": "14479505",
			"label": "14479505"
	},
	{
			"value": "14479513",
			"label": "14479513"
	},
	{
			"value": "14479521",
			"label": "14479521"
	},
	{
			"value": "14479539",
			"label": "14479539"
	},
	{
			"value": "14479547",
			"label": "14479547"
	},
	{
			"value": "14479554",
			"label": "14479554"
	},
	{
			"value": "14479562",
			"label": "14479562"
	},
	{
			"value": "14479570",
			"label": "14479570"
	},
	{
			"value": "14479588",
			"label": "14479588"
	},
	{
			"value": "14479596",
			"label": "14479596"
	},
	{
			"value": "14479604",
			"label": "14479604"
	},
	{
			"value": "14479612",
			"label": "14479612"
	},
	{
			"value": "14479620",
			"label": "14479620"
	},
	{
			"value": "14479638",
			"label": "14479638"
	},
	{
			"value": "14479646",
			"label": "14479646"
	},
	{
			"value": "14479653",
			"label": "14479653"
	},
	{
			"value": "14479661",
			"label": "14479661"
	},
	{
			"value": "14479679",
			"label": "14479679"
	},
	{
			"value": "14479687",
			"label": "14479687"
	},
	{
			"value": "14479695",
			"label": "14479695"
	},
	{
			"value": "14479703",
			"label": "14479703"
	},
	{
			"value": "14479711",
			"label": "14479711"
	},
	{
			"value": "14479729",
			"label": "14479729"
	},
	{
			"value": "14479737",
			"label": "14479737"
	},
	{
			"value": "14479745",
			"label": "14479745"
	},
	{
			"value": "14479752",
			"label": "14479752"
	},
	{
			"value": "14479760",
			"label": "14479760"
	},
	{
			"value": "14479778",
			"label": "14479778"
	},
	{
			"value": "14479786",
			"label": "14479786"
	},
	{
			"value": "14479794",
			"label": "14479794"
	},
	{
			"value": "14479802",
			"label": "14479802"
	},
	{
			"value": "14479810",
			"label": "14479810"
	},
	{
			"value": "14479828",
			"label": "14479828"
	},
	{
			"value": "14479836",
			"label": "14479836"
	},
	{
			"value": "14479844",
			"label": "14479844"
	},
	{
			"value": "14479851",
			"label": "14479851"
	},
	{
			"value": "14479869",
			"label": "14479869"
	},
	{
			"value": "14479877",
			"label": "14479877"
	},
	{
			"value": "14479885",
			"label": "14479885"
	},
	{
			"value": "14479893",
			"label": "14479893"
	},
	{
			"value": "14479901",
			"label": "14479901"
	},
	{
			"value": "14479919",
			"label": "14479919"
	},
	{
			"value": "14479927",
			"label": "14479927"
	},
	{
			"value": "14479935",
			"label": "14479935"
	},
	{
			"value": "14479943",
			"label": "14479943"
	},
	{
			"value": "14479950",
			"label": "14479950"
	},
	{
			"value": "14479968",
			"label": "14479968"
	},
	{
			"value": "14479976",
			"label": "14479976"
	},
	{
			"value": "14479984",
			"label": "14479984"
	},
	{
			"value": "14479992",
			"label": "14479992"
	},
	{
			"value": "14480008",
			"label": "14480008"
	},
	{
			"value": "14480016",
			"label": "14480016"
	},
	{
			"value": "14480024",
			"label": "14480024"
	},
	{
			"value": "14480032",
			"label": "14480032"
	},
	{
			"value": "14480040",
			"label": "14480040"
	},
	{
			"value": "14480057",
			"label": "14480057"
	},
	{
			"value": "14480065",
			"label": "14480065"
	},
	{
			"value": "14480073",
			"label": "14480073"
	},
	{
			"value": "14480081",
			"label": "14480081"
	},
	{
			"value": "14480099",
			"label": "14480099"
	},
	{
			"value": "14480107",
			"label": "14480107"
	},
	{
			"value": "14480115",
			"label": "14480115"
	},
	{
			"value": "14480123",
			"label": "14480123"
	},
	{
			"value": "14480131",
			"label": "14480131"
	},
	{
			"value": "14480149",
			"label": "14480149"
	},
	{
			"value": "14480156",
			"label": "14480156"
	},
	{
			"value": "14480164",
			"label": "14480164"
	},
	{
			"value": "14480172",
			"label": "14480172"
	},
	{
			"value": "14480180",
			"label": "14480180"
	},
	{
			"value": "14480198",
			"label": "14480198"
	},
	{
			"value": "14480206",
			"label": "14480206"
	},
	{
			"value": "14480214",
			"label": "14480214"
	},
	{
			"value": "14480222",
			"label": "14480222"
	},
	{
			"value": "14480230",
			"label": "14480230"
	},
	{
			"value": "14480248",
			"label": "14480248"
	},
	{
			"value": "14480255",
			"label": "14480255"
	},
	{
			"value": "14480263",
			"label": "14480263"
	},
	{
			"value": "14480271",
			"label": "14480271"
	},
	{
			"value": "14480289",
			"label": "14480289"
	},
	{
			"value": "14480297",
			"label": "14480297"
	},
	{
			"value": "14480305",
			"label": "14480305"
	},
	{
			"value": "14480313",
			"label": "14480313"
	},
	{
			"value": "14480321",
			"label": "14480321"
	},
	{
			"value": "14480339",
			"label": "14480339"
	},
	{
			"value": "14480347",
			"label": "14480347"
	},
	{
			"value": "14480354",
			"label": "14480354"
	},
	{
			"value": "14480362",
			"label": "14480362"
	},
	{
			"value": "14480370",
			"label": "14480370"
	},
	{
			"value": "14480388",
			"label": "14480388"
	},
	{
			"value": "14480396",
			"label": "14480396"
	},
	{
			"value": "14480404",
			"label": "14480404"
	},
	{
			"value": "14480412",
			"label": "14480412"
	},
	{
			"value": "14480420",
			"label": "14480420"
	},
	{
			"value": "14480438",
			"label": "14480438"
	},
	{
			"value": "14480446",
			"label": "14480446"
	},
	{
			"value": "14480453",
			"label": "14480453"
	},
	{
			"value": "14480461",
			"label": "14480461"
	},
	{
			"value": "14480479",
			"label": "14480479"
	},
	{
			"value": "14480487",
			"label": "14480487"
	},
	{
			"value": "14480495",
			"label": "14480495"
	},
	{
			"value": "14480503",
			"label": "14480503"
	},
	{
			"value": "14480511",
			"label": "14480511"
	},
	{
			"value": "14480529",
			"label": "14480529"
	},
	{
			"value": "14480537",
			"label": "14480537"
	},
	{
			"value": "14480545",
			"label": "14480545"
	},
	{
			"value": "14480552",
			"label": "14480552"
	},
	{
			"value": "14480560",
			"label": "14480560"
	},
	{
			"value": "14480578",
			"label": "14480578"
	},
	{
			"value": "14480586",
			"label": "14480586"
	},
	{
			"value": "14480594",
			"label": "14480594"
	},
	{
			"value": "14480602",
			"label": "14480602"
	},
	{
			"value": "14480610",
			"label": "14480610"
	},
	{
			"value": "14480628",
			"label": "14480628"
	},
	{
			"value": "14480636",
			"label": "14480636"
	},
	{
			"value": "14480644",
			"label": "14480644"
	},
	{
			"value": "14480651",
			"label": "14480651"
	},
	{
			"value": "14480669",
			"label": "14480669"
	},
	{
			"value": "14480677",
			"label": "14480677"
	},
	{
			"value": "14480685",
			"label": "14480685"
	},
	{
			"value": "14480693",
			"label": "14480693"
	},
	{
			"value": "14480701",
			"label": "14480701"
	},
	{
			"value": "14480719",
			"label": "14480719"
	},
	{
			"value": "14480727",
			"label": "14480727"
	},
	{
			"value": "14480735",
			"label": "14480735"
	},
	{
			"value": "14480743",
			"label": "14480743"
	},
	{
			"value": "14480750",
			"label": "14480750"
	},
	{
			"value": "14480768",
			"label": "14480768"
	},
	{
			"value": "14480776",
			"label": "14480776"
	},
	{
			"value": "14480784",
			"label": "14480784"
	},
	{
			"value": "14480792",
			"label": "14480792"
	},
	{
			"value": "14480800",
			"label": "14480800"
	},
	{
			"value": "14480818",
			"label": "14480818"
	},
	{
			"value": "14480826",
			"label": "14480826"
	},
	{
			"value": "14480834",
			"label": "14480834"
	},
	{
			"value": "14480842",
			"label": "14480842"
	},
	{
			"value": "14480859",
			"label": "14480859"
	},
	{
			"value": "14480867",
			"label": "14480867"
	},
	{
			"value": "14480875",
			"label": "14480875"
	},
	{
			"value": "14480883",
			"label": "14480883"
	},
	{
			"value": "14480891",
			"label": "14480891"
	},
	{
			"value": "14480909",
			"label": "14480909"
	},
	{
			"value": "14480917",
			"label": "14480917"
	},
	{
			"value": "14480925",
			"label": "14480925"
	},
	{
			"value": "14480933",
			"label": "14480933"
	},
	{
			"value": "14480941",
			"label": "14480941"
	},
	{
			"value": "14480958",
			"label": "14480958"
	},
	{
			"value": "14480966",
			"label": "14480966"
	},
	{
			"value": "14480974",
			"label": "14480974"
	},
	{
			"value": "14480982",
			"label": "14480982"
	},
	{
			"value": "14480990",
			"label": "14480990"
	},
	{
			"value": "14481006",
			"label": "14481006"
	},
	{
			"value": "14481014",
			"label": "14481014"
	},
	{
			"value": "14481022",
			"label": "14481022"
	},
	{
			"value": "14481030",
			"label": "14481030"
	},
	{
			"value": "14481048",
			"label": "14481048"
	},
	{
			"value": "14481055",
			"label": "14481055"
	},
	{
			"value": "14481063",
			"label": "14481063"
	},
	{
			"value": "14481071",
			"label": "14481071"
	},
	{
			"value": "14481089",
			"label": "14481089"
	},
	{
			"value": "14481097",
			"label": "14481097"
	},
	{
			"value": "14481105",
			"label": "14481105"
	},
	{
			"value": "14481113",
			"label": "14481113"
	},
	{
			"value": "14481121",
			"label": "14481121"
	},
	{
			"value": "14481139",
			"label": "14481139"
	},
	{
			"value": "14481147",
			"label": "14481147"
	},
	{
			"value": "14481154",
			"label": "14481154"
	},
	{
			"value": "14481162",
			"label": "14481162"
	},
	{
			"value": "14481170",
			"label": "14481170"
	},
	{
			"value": "14481188",
			"label": "14481188"
	},
	{
			"value": "14481196",
			"label": "14481196"
	},
	{
			"value": "14481204",
			"label": "14481204"
	},
	{
			"value": "14481212",
			"label": "14481212"
	},
	{
			"value": "14481220",
			"label": "14481220"
	},
	{
			"value": "14481238",
			"label": "14481238"
	},
	{
			"value": "14481246",
			"label": "14481246"
	},
	{
			"value": "14481253",
			"label": "14481253"
	},
	{
			"value": "14481261",
			"label": "14481261"
	},
	{
			"value": "14481279",
			"label": "14481279"
	},
	{
			"value": "14481287",
			"label": "14481287"
	},
	{
			"value": "14481295",
			"label": "14481295"
	},
	{
			"value": "14481303",
			"label": "14481303"
	},
	{
			"value": "14481311",
			"label": "14481311"
	},
	{
			"value": "14481329",
			"label": "14481329"
	},
	{
			"value": "14481337",
			"label": "14481337"
	},
	{
			"value": "14481345",
			"label": "14481345"
	},
	{
			"value": "14481352",
			"label": "14481352"
	},
	{
			"value": "14481360",
			"label": "14481360"
	},
	{
			"value": "14481378",
			"label": "14481378"
	},
	{
			"value": "14481386",
			"label": "14481386"
	},
	{
			"value": "14481394",
			"label": "14481394"
	},
	{
			"value": "14481402",
			"label": "14481402"
	},
	{
			"value": "14481410",
			"label": "14481410"
	},
	{
			"value": "14481428",
			"label": "14481428"
	},
	{
			"value": "14481436",
			"label": "14481436"
	},
	{
			"value": "14481444",
			"label": "14481444"
	},
	{
			"value": "14481451",
			"label": "14481451"
	},
	{
			"value": "14481469",
			"label": "14481469"
	},
	{
			"value": "14481477",
			"label": "14481477"
	},
	{
			"value": "14481485",
			"label": "14481485"
	},
	{
			"value": "14481493",
			"label": "14481493"
	},
	{
			"value": "14481501",
			"label": "14481501"
	},
	{
			"value": "14481519",
			"label": "14481519"
	},
	{
			"value": "14481527",
			"label": "14481527"
	},
	{
			"value": "14481535",
			"label": "14481535"
	},
	{
			"value": "14481543",
			"label": "14481543"
	},
	{
			"value": "14481550",
			"label": "14481550"
	},
	{
			"value": "14481568",
			"label": "14481568"
	},
	{
			"value": "14481576",
			"label": "14481576"
	},
	{
			"value": "14481584",
			"label": "14481584"
	},
	{
			"value": "14481592",
			"label": "14481592"
	},
	{
			"value": "14481600",
			"label": "14481600"
	},
	{
			"value": "14481618",
			"label": "14481618"
	},
	{
			"value": "14481626",
			"label": "14481626"
	},
	{
			"value": "14481634",
			"label": "14481634"
	},
	{
			"value": "14481642",
			"label": "14481642"
	},
	{
			"value": "14481659",
			"label": "14481659"
	},
	{
			"value": "14481667",
			"label": "14481667"
	},
	{
			"value": "14481675",
			"label": "14481675"
	},
	{
			"value": "14481683",
			"label": "14481683"
	},
	{
			"value": "14481691",
			"label": "14481691"
	},
	{
			"value": "14481709",
			"label": "14481709"
	},
	{
			"value": "14481717",
			"label": "14481717"
	},
	{
			"value": "14481725",
			"label": "14481725"
	},
	{
			"value": "14481733",
			"label": "14481733"
	},
	{
			"value": "14481741",
			"label": "14481741"
	},
	{
			"value": "14481758",
			"label": "14481758"
	},
	{
			"value": "14481766",
			"label": "14481766"
	},
	{
			"value": "14481774",
			"label": "14481774"
	},
	{
			"value": "14481782",
			"label": "14481782"
	},
	{
			"value": "14481790",
			"label": "14481790"
	},
	{
			"value": "14481808",
			"label": "14481808"
	},
	{
			"value": "14481816",
			"label": "14481816"
	},
	{
			"value": "14481824",
			"label": "14481824"
	},
	{
			"value": "14481832",
			"label": "14481832"
	},
	{
			"value": "14481840",
			"label": "14481840"
	},
	{
			"value": "14481857",
			"label": "14481857"
	},
	{
			"value": "14481865",
			"label": "14481865"
	},
	{
			"value": "14481873",
			"label": "14481873"
	},
	{
			"value": "14481881",
			"label": "14481881"
	},
	{
			"value": "14481899",
			"label": "14481899"
	},
	{
			"value": "14481907",
			"label": "14481907"
	},
	{
			"value": "14481915",
			"label": "14481915"
	},
	{
			"value": "14481923",
			"label": "14481923"
	},
	{
			"value": "14481931",
			"label": "14481931"
	},
	{
			"value": "14481949",
			"label": "14481949"
	},
	{
			"value": "14481956",
			"label": "14481956"
	},
	{
			"value": "14481964",
			"label": "14481964"
	},
	{
			"value": "14481972",
			"label": "14481972"
	},
	{
			"value": "14481980",
			"label": "14481980"
	},
	{
			"value": "14481998",
			"label": "14481998"
	},
	{
			"value": "14482004",
			"label": "14482004"
	},
	{
			"value": "14482012",
			"label": "14482012"
	},
	{
			"value": "14482020",
			"label": "14482020"
	},
	{
			"value": "14482038",
			"label": "14482038"
	},
	{
			"value": "14482046",
			"label": "14482046"
	},
	{
			"value": "14482053",
			"label": "14482053"
	},
	{
			"value": "14482061",
			"label": "14482061"
	},
	{
			"value": "14482079",
			"label": "14482079"
	},
	{
			"value": "14482087",
			"label": "14482087"
	},
	{
			"value": "14482095",
			"label": "14482095"
	},
	{
			"value": "14482103",
			"label": "14482103"
	},
	{
			"value": "14482111",
			"label": "14482111"
	},
	{
			"value": "14482129",
			"label": "14482129"
	},
	{
			"value": "14482137",
			"label": "14482137"
	},
	{
			"value": "14482145",
			"label": "14482145"
	},
	{
			"value": "14482152",
			"label": "14482152"
	},
	{
			"value": "14482160",
			"label": "14482160"
	},
	{
			"value": "14482178",
			"label": "14482178"
	},
	{
			"value": "14482186",
			"label": "14482186"
	},
	{
			"value": "14482194",
			"label": "14482194"
	},
	{
			"value": "14482202",
			"label": "14482202"
	},
	{
			"value": "14482236",
			"label": "14482236"
	},
	{
			"value": "14482244",
			"label": "14482244"
	},
	{
			"value": "14482251",
			"label": "14482251"
	},
	{
			"value": "14482269",
			"label": "14482269"
	},
	{
			"value": "14482277",
			"label": "14482277"
	},
	{
			"value": "14482285",
			"label": "14482285"
	},
	{
			"value": "14482293",
			"label": "14482293"
	},
	{
			"value": "14482301",
			"label": "14482301"
	},
	{
			"value": "14482319",
			"label": "14482319"
	},
	{
			"value": "14482327",
			"label": "14482327"
	},
	{
			"value": "14482335",
			"label": "14482335"
	},
	{
			"value": "14482343",
			"label": "14482343"
	},
	{
			"value": "14482350",
			"label": "14482350"
	},
	{
			"value": "14482368",
			"label": "14482368"
	},
	{
			"value": "14482376",
			"label": "14482376"
	},
	{
			"value": "14482384",
			"label": "14482384"
	},
	{
			"value": "14482392",
			"label": "14482392"
	},
	{
			"value": "14482400",
			"label": "14482400"
	},
	{
			"value": "14482418",
			"label": "14482418"
	},
	{
			"value": "14482426",
			"label": "14482426"
	},
	{
			"value": "14482434",
			"label": "14482434"
	},
	{
			"value": "14482442",
			"label": "14482442"
	},
	{
			"value": "14482459",
			"label": "14482459"
	},
	{
			"value": "14482467",
			"label": "14482467"
	},
	{
			"value": "14482475",
			"label": "14482475"
	},
	{
			"value": "14482483",
			"label": "14482483"
	},
	{
			"value": "14482491",
			"label": "14482491"
	},
	{
			"value": "14482509",
			"label": "14482509"
	},
	{
			"value": "14482517",
			"label": "14482517"
	},
	{
			"value": "14482525",
			"label": "14482525"
	},
	{
			"value": "14482533",
			"label": "14482533"
	},
	{
			"value": "14482541",
			"label": "14482541"
	},
	{
			"value": "14482558",
			"label": "14482558"
	},
	{
			"value": "14482566",
			"label": "14482566"
	},
	{
			"value": "14482574",
			"label": "14482574"
	},
	{
			"value": "14482582",
			"label": "14482582"
	},
	{
			"value": "14482590",
			"label": "14482590"
	},
	{
			"value": "14482608",
			"label": "14482608"
	},
	{
			"value": "14482616",
			"label": "14482616"
	},
	{
			"value": "14482624",
			"label": "14482624"
	},
	{
			"value": "14482632",
			"label": "14482632"
	},
	{
			"value": "14482640",
			"label": "14482640"
	},
	{
			"value": "14482657",
			"label": "14482657"
	},
	{
			"value": "14482665",
			"label": "14482665"
	},
	{
			"value": "14482673",
			"label": "14482673"
	},
	{
			"value": "14482681",
			"label": "14482681"
	},
	{
			"value": "14482699",
			"label": "14482699"
	},
	{
			"value": "14482707",
			"label": "14482707"
	},
	{
			"value": "14482715",
			"label": "14482715"
	},
	{
			"value": "14482723",
			"label": "14482723"
	},
	{
			"value": "14482731",
			"label": "14482731"
	},
	{
			"value": "14482749",
			"label": "14482749"
	},
	{
			"value": "14482756",
			"label": "14482756"
	},
	{
			"value": "14482764",
			"label": "14482764"
	},
	{
			"value": "14482772",
			"label": "14482772"
	},
	{
			"value": "14482780",
			"label": "14482780"
	},
	{
			"value": "14482798",
			"label": "14482798"
	},
	{
			"value": "14482806",
			"label": "14482806"
	},
	{
			"value": "14482814",
			"label": "14482814"
	},
	{
			"value": "14482822",
			"label": "14482822"
	},
	{
			"value": "14482830",
			"label": "14482830"
	},
	{
			"value": "14482848",
			"label": "14482848"
	},
	{
			"value": "14482855",
			"label": "14482855"
	},
	{
			"value": "14482863",
			"label": "14482863"
	},
	{
			"value": "14482871",
			"label": "14482871"
	},
	{
			"value": "14482889",
			"label": "14482889"
	},
	{
			"value": "14482897",
			"label": "14482897"
	},
	{
			"value": "14482905",
			"label": "14482905"
	},
	{
			"value": "14482913",
			"label": "14482913"
	},
	{
			"value": "14482921",
			"label": "14482921"
	},
	{
			"value": "14482939",
			"label": "14482939"
	},
	{
			"value": "14482947",
			"label": "14482947"
	},
	{
			"value": "14482954",
			"label": "14482954"
	},
	{
			"value": "14482962",
			"label": "14482962"
	},
	{
			"value": "14482970",
			"label": "14482970"
	},
	{
			"value": "14482988",
			"label": "14482988"
	},
	{
			"value": "14482996",
			"label": "14482996"
	},
	{
			"value": "14483002",
			"label": "14483002"
	},
	{
			"value": "14483010",
			"label": "14483010"
	},
	{
			"value": "14483028",
			"label": "14483028"
	},
	{
			"value": "14483036",
			"label": "14483036"
	},
	{
			"value": "14483044",
			"label": "14483044"
	},
	{
			"value": "14483051",
			"label": "14483051"
	},
	{
			"value": "14483069",
			"label": "14483069"
	},
	{
			"value": "14483077",
			"label": "14483077"
	},
	{
			"value": "14483085",
			"label": "14483085"
	},
	{
			"value": "14483093",
			"label": "14483093"
	},
	{
			"value": "14483101",
			"label": "14483101"
	},
	{
			"value": "14483119",
			"label": "14483119"
	},
	{
			"value": "14483127",
			"label": "14483127"
	},
	{
			"value": "14483135",
			"label": "14483135"
	},
	{
			"value": "14483143",
			"label": "14483143"
	},
	{
			"value": "14483150",
			"label": "14483150"
	},
	{
			"value": "14483168",
			"label": "14483168"
	},
	{
			"value": "14483176",
			"label": "14483176"
	},
	{
			"value": "14483184",
			"label": "14483184"
	},
	{
			"value": "14483192",
			"label": "14483192"
	},
	{
			"value": "14483200",
			"label": "14483200"
	},
	{
			"value": "14483218",
			"label": "14483218"
	},
	{
			"value": "14483226",
			"label": "14483226"
	},
	{
			"value": "14483234",
			"label": "14483234"
	},
	{
			"value": "14483242",
			"label": "14483242"
	},
	{
			"value": "14483259",
			"label": "14483259"
	},
	{
			"value": "14483267",
			"label": "14483267"
	},
	{
			"value": "14483275",
			"label": "14483275"
	},
	{
			"value": "14483283",
			"label": "14483283"
	},
	{
			"value": "14483291",
			"label": "14483291"
	},
	{
			"value": "14483309",
			"label": "14483309"
	},
	{
			"value": "14483317",
			"label": "14483317"
	},
	{
			"value": "14483325",
			"label": "14483325"
	},
	{
			"value": "14483333",
			"label": "14483333"
	},
	{
			"value": "14483341",
			"label": "14483341"
	},
	{
			"value": "14483358",
			"label": "14483358"
	},
	{
			"value": "14483366",
			"label": "14483366"
	},
	{
			"value": "14483374",
			"label": "14483374"
	},
	{
			"value": "14483382",
			"label": "14483382"
	},
	{
			"value": "14483390",
			"label": "14483390"
	},
	{
			"value": "14483408",
			"label": "14483408"
	},
	{
			"value": "14483416",
			"label": "14483416"
	},
	{
			"value": "14483424",
			"label": "14483424"
	},
	{
			"value": "14483432",
			"label": "14483432"
	},
	{
			"value": "14483440",
			"label": "14483440"
	},
	{
			"value": "14483457",
			"label": "14483457"
	},
	{
			"value": "14483465",
			"label": "14483465"
	},
	{
			"value": "14483473",
			"label": "14483473"
	},
	{
			"value": "14483481",
			"label": "14483481"
	},
	{
			"value": "14483499",
			"label": "14483499"
	},
	{
			"value": "14483507",
			"label": "14483507"
	},
	{
			"value": "14483515",
			"label": "14483515"
	},
	{
			"value": "14483523",
			"label": "14483523"
	},
	{
			"value": "14483531",
			"label": "14483531"
	},
	{
			"value": "14483549",
			"label": "14483549"
	},
	{
			"value": "14483556",
			"label": "14483556"
	},
	{
			"value": "14483564",
			"label": "14483564"
	},
	{
			"value": "14483572",
			"label": "14483572"
	},
	{
			"value": "14483580",
			"label": "14483580"
	},
	{
			"value": "14483598",
			"label": "14483598"
	},
	{
			"value": "14483606",
			"label": "14483606"
	},
	{
			"value": "14483614",
			"label": "14483614"
	},
	{
			"value": "14483622",
			"label": "14483622"
	},
	{
			"value": "14483630",
			"label": "14483630"
	},
	{
			"value": "14483648",
			"label": "14483648"
	},
	{
			"value": "14483655",
			"label": "14483655"
	},
	{
			"value": "14483663",
			"label": "14483663"
	},
	{
			"value": "14483671",
			"label": "14483671"
	},
	{
			"value": "14483689",
			"label": "14483689"
	},
	{
			"value": "14483697",
			"label": "14483697"
	},
	{
			"value": "14483705",
			"label": "14483705"
	},
	{
			"value": "14483713",
			"label": "14483713"
	},
	{
			"value": "14483721",
			"label": "14483721"
	},
	{
			"value": "14483739",
			"label": "14483739"
	},
	{
			"value": "14483747",
			"label": "14483747"
	},
	{
			"value": "14483754",
			"label": "14483754"
	},
	{
			"value": "14483762",
			"label": "14483762"
	},
	{
			"value": "14483770",
			"label": "14483770"
	},
	{
			"value": "14483788",
			"label": "14483788"
	},
	{
			"value": "14483796",
			"label": "14483796"
	},
	{
			"value": "14483804",
			"label": "14483804"
	},
	{
			"value": "14483812",
			"label": "14483812"
	},
	{
			"value": "14483820",
			"label": "14483820"
	},
	{
			"value": "14483838",
			"label": "14483838"
	},
	{
			"value": "14483846",
			"label": "14483846"
	},
	{
			"value": "14483853",
			"label": "14483853"
	},
	{
			"value": "14483861",
			"label": "14483861"
	},
	{
			"value": "14483879",
			"label": "14483879"
	},
	{
			"value": "14483887",
			"label": "14483887"
	},
	{
			"value": "14483895",
			"label": "14483895"
	},
	{
			"value": "14483903",
			"label": "14483903"
	},
	{
			"value": "14483911",
			"label": "14483911"
	},
	{
			"value": "14483929",
			"label": "14483929"
	},
	{
			"value": "14483937",
			"label": "14483937"
	},
	{
			"value": "14483945",
			"label": "14483945"
	},
	{
			"value": "14483952",
			"label": "14483952"
	},
	{
			"value": "14483960",
			"label": "14483960"
	},
	{
			"value": "14483978",
			"label": "14483978"
	},
	{
			"value": "14483986",
			"label": "14483986"
	},
	{
			"value": "14483994",
			"label": "14483994"
	},
	{
			"value": "14484000",
			"label": "14484000"
	},
	{
			"value": "14484018",
			"label": "14484018"
	},
	{
			"value": "14484026",
			"label": "14484026"
	},
	{
			"value": "14484034",
			"label": "14484034"
	},
	{
			"value": "14484042",
			"label": "14484042"
	},
	{
			"value": "14484059",
			"label": "14484059"
	},
	{
			"value": "14484067",
			"label": "14484067"
	},
	{
			"value": "14484075",
			"label": "14484075"
	},
	{
			"value": "14484083",
			"label": "14484083"
	},
	{
			"value": "14484091",
			"label": "14484091"
	},
	{
			"value": "14484109",
			"label": "14484109"
	},
	{
			"value": "14484117",
			"label": "14484117"
	},
	{
			"value": "14484125",
			"label": "14484125"
	},
	{
			"value": "14484133",
			"label": "14484133"
	},
	{
			"value": "14484141",
			"label": "14484141"
	},
	{
			"value": "14484158",
			"label": "14484158"
	},
	{
			"value": "14484166",
			"label": "14484166"
	},
	{
			"value": "14484174",
			"label": "14484174"
	},
	{
			"value": "14484182",
			"label": "14484182"
	},
	{
			"value": "14484190",
			"label": "14484190"
	},
	{
			"value": "14484208",
			"label": "14484208"
	},
	{
			"value": "14484216",
			"label": "14484216"
	},
	{
			"value": "14484224",
			"label": "14484224"
	},
	{
			"value": "14484232",
			"label": "14484232"
	},
	{
			"value": "14484240",
			"label": "14484240"
	},
	{
			"value": "14484265",
			"label": "14484265"
	},
	{
			"value": "14484273",
			"label": "14484273"
	},
	{
			"value": "14484281",
			"label": "14484281"
	},
	{
			"value": "14484299",
			"label": "14484299"
	},
	{
			"value": "14484307",
			"label": "14484307"
	},
	{
			"value": "14484315",
			"label": "14484315"
	},
	{
			"value": "14484323",
			"label": "14484323"
	},
	{
			"value": "14484331",
			"label": "14484331"
	},
	{
			"value": "14484349",
			"label": "14484349"
	},
	{
			"value": "14484364",
			"label": "14484364"
	},
	{
			"value": "14484372",
			"label": "14484372"
	},
	{
			"value": "14484414",
			"label": "14484414"
	},
	{
			"value": "14484430",
			"label": "14484430"
	},
	{
			"value": "14484448",
			"label": "14484448"
	},
	{
			"value": "14484455",
			"label": "14484455"
	},
	{
			"value": "14484463",
			"label": "14484463"
	},
	{
			"value": "14484471",
			"label": "14484471"
	},
	{
			"value": "14484489",
			"label": "14484489"
	},
	{
			"value": "14484505",
			"label": "14484505"
	},
	{
			"value": "14484513",
			"label": "14484513"
	},
	{
			"value": "14484521",
			"label": "14484521"
	},
	{
			"value": "14484539",
			"label": "14484539"
	},
	{
			"value": "14484547",
			"label": "14484547"
	},
	{
			"value": "14484554",
			"label": "14484554"
	},
	{
			"value": "14484562",
			"label": "14484562"
	},
	{
			"value": "14484570",
			"label": "14484570"
	},
	{
			"value": "14484588",
			"label": "14484588"
	},
	{
			"value": "14484596",
			"label": "14484596"
	},
	{
			"value": "14484604",
			"label": "14484604"
	},
	{
			"value": "14484612",
			"label": "14484612"
	},
	{
			"value": "14484620",
			"label": "14484620"
	},
	{
			"value": "14484638",
			"label": "14484638"
	},
	{
			"value": "14484646",
			"label": "14484646"
	},
	{
			"value": "14484653",
			"label": "14484653"
	},
	{
			"value": "14484661",
			"label": "14484661"
	},
	{
			"value": "14484679",
			"label": "14484679"
	},
	{
			"value": "14484687",
			"label": "14484687"
	},
	{
			"value": "14484695",
			"label": "14484695"
	},
	{
			"value": "14484703",
			"label": "14484703"
	},
	{
			"value": "14484711",
			"label": "14484711"
	},
	{
			"value": "14484729",
			"label": "14484729"
	},
	{
			"value": "14484737",
			"label": "14484737"
	},
	{
			"value": "14484745",
			"label": "14484745"
	},
	{
			"value": "14484752",
			"label": "14484752"
	},
	{
			"value": "14484760",
			"label": "14484760"
	},
	{
			"value": "14484778",
			"label": "14484778"
	},
	{
			"value": "14484786",
			"label": "14484786"
	},
	{
			"value": "14484794",
			"label": "14484794"
	},
	{
			"value": "14484802",
			"label": "14484802"
	},
	{
			"value": "14484810",
			"label": "14484810"
	},
	{
			"value": "14484828",
			"label": "14484828"
	},
	{
			"value": "14484836",
			"label": "14484836"
	},
	{
			"value": "14484844",
			"label": "14484844"
	},
	{
			"value": "14484851",
			"label": "14484851"
	},
	{
			"value": "14484869",
			"label": "14484869"
	},
	{
			"value": "14484877",
			"label": "14484877"
	},
	{
			"value": "14484885",
			"label": "14484885"
	},
	{
			"value": "14484893",
			"label": "14484893"
	},
	{
			"value": "14484901",
			"label": "14484901"
	},
	{
			"value": "14484919",
			"label": "14484919"
	},
	{
			"value": "14484927",
			"label": "14484927"
	},
	{
			"value": "14484935",
			"label": "14484935"
	},
	{
			"value": "14484943",
			"label": "14484943"
	},
	{
			"value": "14484950",
			"label": "14484950"
	},
	{
			"value": "14484968",
			"label": "14484968"
	},
	{
			"value": "14484976",
			"label": "14484976"
	},
	{
			"value": "14484984",
			"label": "14484984"
	},
	{
			"value": "14484992",
			"label": "14484992"
	},
	{
			"value": "14485007",
			"label": "14485007"
	},
	{
			"value": "14485015",
			"label": "14485015"
	},
	{
			"value": "14485023",
			"label": "14485023"
	},
	{
			"value": "14485031",
			"label": "14485031"
	},
	{
			"value": "14485049",
			"label": "14485049"
	},
	{
			"value": "14485056",
			"label": "14485056"
	},
	{
			"value": "14485064",
			"label": "14485064"
	},
	{
			"value": "14485072",
			"label": "14485072"
	},
	{
			"value": "14485080",
			"label": "14485080"
	},
	{
			"value": "14485098",
			"label": "14485098"
	},
	{
			"value": "14485106",
			"label": "14485106"
	},
	{
			"value": "14485114",
			"label": "14485114"
	},
	{
			"value": "14485122",
			"label": "14485122"
	},
	{
			"value": "14485130",
			"label": "14485130"
	},
	{
			"value": "14485148",
			"label": "14485148"
	},
	{
			"value": "14485155",
			"label": "14485155"
	},
	{
			"value": "14485163",
			"label": "14485163"
	},
	{
			"value": "14485171",
			"label": "14485171"
	},
	{
			"value": "14485189",
			"label": "14485189"
	},
	{
			"value": "14485197",
			"label": "14485197"
	},
	{
			"value": "14485205",
			"label": "14485205"
	},
	{
			"value": "14485213",
			"label": "14485213"
	},
	{
			"value": "14485221",
			"label": "14485221"
	},
	{
			"value": "14485239",
			"label": "14485239"
	},
	{
			"value": "14485247",
			"label": "14485247"
	},
	{
			"value": "14485254",
			"label": "14485254"
	},
	{
			"value": "14485262",
			"label": "14485262"
	},
	{
			"value": "14485270",
			"label": "14485270"
	},
	{
			"value": "14485288",
			"label": "14485288"
	},
	{
			"value": "14485296",
			"label": "14485296"
	},
	{
			"value": "14485304",
			"label": "14485304"
	},
	{
			"value": "14485312",
			"label": "14485312"
	},
	{
			"value": "14485320",
			"label": "14485320"
	},
	{
			"value": "14485338",
			"label": "14485338"
	},
	{
			"value": "14485346",
			"label": "14485346"
	},
	{
			"value": "14485353",
			"label": "14485353"
	},
	{
			"value": "14485361",
			"label": "14485361"
	},
	{
			"value": "14485379",
			"label": "14485379"
	},
	{
			"value": "14485387",
			"label": "14485387"
	},
	{
			"value": "14485395",
			"label": "14485395"
	},
	{
			"value": "14485403",
			"label": "14485403"
	},
	{
			"value": "14485411",
			"label": "14485411"
	},
	{
			"value": "14485429",
			"label": "14485429"
	},
	{
			"value": "14485437",
			"label": "14485437"
	},
	{
			"value": "14485445",
			"label": "14485445"
	},
	{
			"value": "14485452",
			"label": "14485452"
	},
	{
			"value": "14485460",
			"label": "14485460"
	},
	{
			"value": "14485478",
			"label": "14485478"
	},
	{
			"value": "14485486",
			"label": "14485486"
	},
	{
			"value": "14485494",
			"label": "14485494"
	},
	{
			"value": "14485502",
			"label": "14485502"
	},
	{
			"value": "14485510",
			"label": "14485510"
	},
	{
			"value": "14485528",
			"label": "14485528"
	},
	{
			"value": "14485536",
			"label": "14485536"
	},
	{
			"value": "14485544",
			"label": "14485544"
	},
	{
			"value": "14485551",
			"label": "14485551"
	},
	{
			"value": "14485569",
			"label": "14485569"
	},
	{
			"value": "14485577",
			"label": "14485577"
	},
	{
			"value": "14485585",
			"label": "14485585"
	},
	{
			"value": "14485593",
			"label": "14485593"
	},
	{
			"value": "14485601",
			"label": "14485601"
	},
	{
			"value": "14485619",
			"label": "14485619"
	},
	{
			"value": "14485627",
			"label": "14485627"
	},
	{
			"value": "14485635",
			"label": "14485635"
	},
	{
			"value": "14485643",
			"label": "14485643"
	},
	{
			"value": "14485650",
			"label": "14485650"
	},
	{
			"value": "14485668",
			"label": "14485668"
	},
	{
			"value": "14485676",
			"label": "14485676"
	},
	{
			"value": "14485684",
			"label": "14485684"
	},
	{
			"value": "14485692",
			"label": "14485692"
	},
	{
			"value": "14485700",
			"label": "14485700"
	},
	{
			"value": "14485718",
			"label": "14485718"
	},
	{
			"value": "14485726",
			"label": "14485726"
	},
	{
			"value": "14485734",
			"label": "14485734"
	},
	{
			"value": "14485742",
			"label": "14485742"
	},
	{
			"value": "14485759",
			"label": "14485759"
	},
	{
			"value": "14485767",
			"label": "14485767"
	},
	{
			"value": "14485775",
			"label": "14485775"
	},
	{
			"value": "14485783",
			"label": "14485783"
	},
	{
			"value": "14485791",
			"label": "14485791"
	},
	{
			"value": "14485809",
			"label": "14485809"
	},
	{
			"value": "14485817",
			"label": "14485817"
	},
	{
			"value": "14485825",
			"label": "14485825"
	},
	{
			"value": "14485833",
			"label": "14485833"
	},
	{
			"value": "14485841",
			"label": "14485841"
	},
	{
			"value": "14485858",
			"label": "14485858"
	},
	{
			"value": "14485866",
			"label": "14485866"
	},
	{
			"value": "14485874",
			"label": "14485874"
	},
	{
			"value": "14485882",
			"label": "14485882"
	},
	{
			"value": "14485890",
			"label": "14485890"
	},
	{
			"value": "14485908",
			"label": "14485908"
	},
	{
			"value": "14485916",
			"label": "14485916"
	},
	{
			"value": "14485924",
			"label": "14485924"
	},
	{
			"value": "14485932",
			"label": "14485932"
	},
	{
			"value": "14485940",
			"label": "14485940"
	},
	{
			"value": "14485957",
			"label": "14485957"
	},
	{
			"value": "14485965",
			"label": "14485965"
	},
	{
			"value": "14485973",
			"label": "14485973"
	},
	{
			"value": "14485981",
			"label": "14485981"
	},
	{
			"value": "14485999",
			"label": "14485999"
	},
	{
			"value": "14486005",
			"label": "14486005"
	},
	{
			"value": "14486013",
			"label": "14486013"
	},
	{
			"value": "14486021",
			"label": "14486021"
	},
	{
			"value": "14486039",
			"label": "14486039"
	},
	{
			"value": "14486047",
			"label": "14486047"
	},
	{
			"value": "14486054",
			"label": "14486054"
	},
	{
			"value": "14486062",
			"label": "14486062"
	},
	{
			"value": "14486070",
			"label": "14486070"
	},
	{
			"value": "14486088",
			"label": "14486088"
	},
	{
			"value": "14486096",
			"label": "14486096"
	},
	{
			"value": "14486104",
			"label": "14486104"
	},
	{
			"value": "14486112",
			"label": "14486112"
	},
	{
			"value": "14486120",
			"label": "14486120"
	},
	{
			"value": "14486138",
			"label": "14486138"
	},
	{
			"value": "14486146",
			"label": "14486146"
	},
	{
			"value": "14486153",
			"label": "14486153"
	},
	{
			"value": "14486161",
			"label": "14486161"
	},
	{
			"value": "14486179",
			"label": "14486179"
	},
	{
			"value": "14486187",
			"label": "14486187"
	},
	{
			"value": "14486195",
			"label": "14486195"
	},
	{
			"value": "14486203",
			"label": "14486203"
	},
	{
			"value": "14486211",
			"label": "14486211"
	},
	{
			"value": "14486229",
			"label": "14486229"
	},
	{
			"value": "14486237",
			"label": "14486237"
	},
	{
			"value": "14486245",
			"label": "14486245"
	},
	{
			"value": "14486252",
			"label": "14486252"
	},
	{
			"value": "14486260",
			"label": "14486260"
	},
	{
			"value": "14486278",
			"label": "14486278"
	},
	{
			"value": "14486286",
			"label": "14486286"
	},
	{
			"value": "14486294",
			"label": "14486294"
	},
	{
			"value": "14486302",
			"label": "14486302"
	},
	{
			"value": "14486310",
			"label": "14486310"
	},
	{
			"value": "14486328",
			"label": "14486328"
	},
	{
			"value": "14486336",
			"label": "14486336"
	},
	{
			"value": "14486344",
			"label": "14486344"
	},
	{
			"value": "14486351",
			"label": "14486351"
	},
	{
			"value": "14486369",
			"label": "14486369"
	},
	{
			"value": "14486377",
			"label": "14486377"
	},
	{
			"value": "14486385",
			"label": "14486385"
	},
	{
			"value": "14486393",
			"label": "14486393"
	},
	{
			"value": "14486401",
			"label": "14486401"
	},
	{
			"value": "14486419",
			"label": "14486419"
	},
	{
			"value": "14486427",
			"label": "14486427"
	},
	{
			"value": "14486435",
			"label": "14486435"
	},
	{
			"value": "14486443",
			"label": "14486443"
	},
	{
			"value": "14486450",
			"label": "14486450"
	},
	{
			"value": "14486468",
			"label": "14486468"
	},
	{
			"value": "14486476",
			"label": "14486476"
	},
	{
			"value": "14486484",
			"label": "14486484"
	},
	{
			"value": "14486492",
			"label": "14486492"
	},
	{
			"value": "14486500",
			"label": "14486500"
	},
	{
			"value": "14486518",
			"label": "14486518"
	},
	{
			"value": "14486526",
			"label": "14486526"
	},
	{
			"value": "14486534",
			"label": "14486534"
	},
	{
			"value": "14486542",
			"label": "14486542"
	},
	{
			"value": "14486559",
			"label": "14486559"
	},
	{
			"value": "14486567",
			"label": "14486567"
	},
	{
			"value": "14486575",
			"label": "14486575"
	},
	{
			"value": "14486583",
			"label": "14486583"
	},
	{
			"value": "14486591",
			"label": "14486591"
	},
	{
			"value": "14486609",
			"label": "14486609"
	},
	{
			"value": "14486617",
			"label": "14486617"
	},
	{
			"value": "14486625",
			"label": "14486625"
	},
	{
			"value": "14486633",
			"label": "14486633"
	},
	{
			"value": "14486641",
			"label": "14486641"
	},
	{
			"value": "14486658",
			"label": "14486658"
	},
	{
			"value": "14486666",
			"label": "14486666"
	},
	{
			"value": "14486674",
			"label": "14486674"
	},
	{
			"value": "14486682",
			"label": "14486682"
	},
	{
			"value": "14486690",
			"label": "14486690"
	},
	{
			"value": "14486708",
			"label": "14486708"
	},
	{
			"value": "14486716",
			"label": "14486716"
	},
	{
			"value": "14486724",
			"label": "14486724"
	},
	{
			"value": "14486732",
			"label": "14486732"
	},
	{
			"value": "14486740",
			"label": "14486740"
	},
	{
			"value": "14486757",
			"label": "14486757"
	},
	{
			"value": "14486765",
			"label": "14486765"
	},
	{
			"value": "14486773",
			"label": "14486773"
	},
	{
			"value": "14486781",
			"label": "14486781"
	},
	{
			"value": "14486799",
			"label": "14486799"
	},
	{
			"value": "14486807",
			"label": "14486807"
	},
	{
			"value": "14486815",
			"label": "14486815"
	},
	{
			"value": "14486823",
			"label": "14486823"
	},
	{
			"value": "14486831",
			"label": "14486831"
	},
	{
			"value": "14486849",
			"label": "14486849"
	},
	{
			"value": "14486856",
			"label": "14486856"
	},
	{
			"value": "14486864",
			"label": "14486864"
	},
	{
			"value": "14486872",
			"label": "14486872"
	},
	{
			"value": "14486880",
			"label": "14486880"
	},
	{
			"value": "14486898",
			"label": "14486898"
	},
	{
			"value": "14486906",
			"label": "14486906"
	},
	{
			"value": "14486914",
			"label": "14486914"
	},
	{
			"value": "14486922",
			"label": "14486922"
	},
	{
			"value": "14486930",
			"label": "14486930"
	},
	{
			"value": "14486948",
			"label": "14486948"
	},
	{
			"value": "14486955",
			"label": "14486955"
	},
	{
			"value": "14486963",
			"label": "14486963"
	},
	{
			"value": "14486971",
			"label": "14486971"
	},
	{
			"value": "14486989",
			"label": "14486989"
	},
	{
			"value": "14486997",
			"label": "14486997"
	},
	{
			"value": "14487003",
			"label": "14487003"
	},
	{
			"value": "14487011",
			"label": "14487011"
	},
	{
			"value": "14487029",
			"label": "14487029"
	},
	{
			"value": "14487037",
			"label": "14487037"
	},
	{
			"value": "14487045",
			"label": "14487045"
	},
	{
			"value": "14487052",
			"label": "14487052"
	},
	{
			"value": "14487060",
			"label": "14487060"
	},
	{
			"value": "14487078",
			"label": "14487078"
	},
	{
			"value": "14487086",
			"label": "14487086"
	},
	{
			"value": "14487094",
			"label": "14487094"
	},
	{
			"value": "14487102",
			"label": "14487102"
	},
	{
			"value": "14487110",
			"label": "14487110"
	},
	{
			"value": "14487128",
			"label": "14487128"
	},
	{
			"value": "14487136",
			"label": "14487136"
	},
	{
			"value": "14487144",
			"label": "14487144"
	},
	{
			"value": "14487151",
			"label": "14487151"
	},
	{
			"value": "14487169",
			"label": "14487169"
	},
	{
			"value": "14487177",
			"label": "14487177"
	},
	{
			"value": "14487185",
			"label": "14487185"
	},
	{
			"value": "14487193",
			"label": "14487193"
	},
	{
			"value": "14487201",
			"label": "14487201"
	},
	{
			"value": "14487219",
			"label": "14487219"
	},
	{
			"value": "14487227",
			"label": "14487227"
	},
	{
			"value": "14487235",
			"label": "14487235"
	},
	{
			"value": "14487243",
			"label": "14487243"
	},
	{
			"value": "14487250",
			"label": "14487250"
	},
	{
			"value": "14487268",
			"label": "14487268"
	},
	{
			"value": "14487276",
			"label": "14487276"
	},
	{
			"value": "14487284",
			"label": "14487284"
	},
	{
			"value": "14487292",
			"label": "14487292"
	},
	{
			"value": "14487300",
			"label": "14487300"
	},
	{
			"value": "14487318",
			"label": "14487318"
	},
	{
			"value": "14487326",
			"label": "14487326"
	},
	{
			"value": "14487334",
			"label": "14487334"
	},
	{
			"value": "14487342",
			"label": "14487342"
	},
	{
			"value": "14487359",
			"label": "14487359"
	},
	{
			"value": "14487367",
			"label": "14487367"
	},
	{
			"value": "14487375",
			"label": "14487375"
	},
	{
			"value": "14487383",
			"label": "14487383"
	},
	{
			"value": "14487391",
			"label": "14487391"
	},
	{
			"value": "14487409",
			"label": "14487409"
	},
	{
			"value": "14487417",
			"label": "14487417"
	},
	{
			"value": "14487425",
			"label": "14487425"
	},
	{
			"value": "14487433",
			"label": "14487433"
	},
	{
			"value": "14487441",
			"label": "14487441"
	},
	{
			"value": "14487458",
			"label": "14487458"
	},
	{
			"value": "14487466",
			"label": "14487466"
	},
	{
			"value": "14487474",
			"label": "14487474"
	},
	{
			"value": "14487482",
			"label": "14487482"
	},
	{
			"value": "14487490",
			"label": "14487490"
	},
	{
			"value": "14487508",
			"label": "14487508"
	},
	{
			"value": "14487516",
			"label": "14487516"
	},
	{
			"value": "14487524",
			"label": "14487524"
	},
	{
			"value": "14487532",
			"label": "14487532"
	},
	{
			"value": "14487540",
			"label": "14487540"
	},
	{
			"value": "14487557",
			"label": "14487557"
	},
	{
			"value": "14487565",
			"label": "14487565"
	},
	{
			"value": "14487573",
			"label": "14487573"
	},
	{
			"value": "14487581",
			"label": "14487581"
	},
	{
			"value": "14487599",
			"label": "14487599"
	},
	{
			"value": "14487607",
			"label": "14487607"
	},
	{
			"value": "14487615",
			"label": "14487615"
	},
	{
			"value": "14487623",
			"label": "14487623"
	},
	{
			"value": "14487649",
			"label": "14487649"
	},
	{
			"value": "14487656",
			"label": "14487656"
	},
	{
			"value": "14487664",
			"label": "14487664"
	},
	{
			"value": "14487672",
			"label": "14487672"
	},
	{
			"value": "14487680",
			"label": "14487680"
	},
	{
			"value": "14487698",
			"label": "14487698"
	},
	{
			"value": "14487706",
			"label": "14487706"
	},
	{
			"value": "14487714",
			"label": "14487714"
	},
	{
			"value": "14487722",
			"label": "14487722"
	},
	{
			"value": "14487730",
			"label": "14487730"
	},
	{
			"value": "14487748",
			"label": "14487748"
	},
	{
			"value": "14487755",
			"label": "14487755"
	},
	{
			"value": "14487763",
			"label": "14487763"
	},
	{
			"value": "14487771",
			"label": "14487771"
	},
	{
			"value": "14487789",
			"label": "14487789"
	},
	{
			"value": "14487797",
			"label": "14487797"
	},
	{
			"value": "14487805",
			"label": "14487805"
	},
	{
			"value": "14487813",
			"label": "14487813"
	},
	{
			"value": "14487821",
			"label": "14487821"
	},
	{
			"value": "14487839",
			"label": "14487839"
	},
	{
			"value": "14487847",
			"label": "14487847"
	},
	{
			"value": "14487854",
			"label": "14487854"
	},
	{
			"value": "14487862",
			"label": "14487862"
	},
	{
			"value": "14487870",
			"label": "14487870"
	},
	{
			"value": "14487888",
			"label": "14487888"
	},
	{
			"value": "14487896",
			"label": "14487896"
	},
	{
			"value": "14487904",
			"label": "14487904"
	},
	{
			"value": "14487912",
			"label": "14487912"
	},
	{
			"value": "14487920",
			"label": "14487920"
	},
	{
			"value": "14487938",
			"label": "14487938"
	},
	{
			"value": "14487946",
			"label": "14487946"
	},
	{
			"value": "14487953",
			"label": "14487953"
	},
	{
			"value": "14487961",
			"label": "14487961"
	},
	{
			"value": "14487979",
			"label": "14487979"
	},
	{
			"value": "14487987",
			"label": "14487987"
	},
	{
			"value": "14487995",
			"label": "14487995"
	},
	{
			"value": "14488001",
			"label": "14488001"
	},
	{
			"value": "14488019",
			"label": "14488019"
	},
	{
			"value": "14488027",
			"label": "14488027"
	},
	{
			"value": "14488035",
			"label": "14488035"
	},
	{
			"value": "14488043",
			"label": "14488043"
	},
	{
			"value": "14488050",
			"label": "14488050"
	},
	{
			"value": "14488068",
			"label": "14488068"
	},
	{
			"value": "14488076",
			"label": "14488076"
	},
	{
			"value": "14488084",
			"label": "14488084"
	},
	{
			"value": "14488092",
			"label": "14488092"
	},
	{
			"value": "14488100",
			"label": "14488100"
	},
	{
			"value": "14488118",
			"label": "14488118"
	},
	{
			"value": "14488126",
			"label": "14488126"
	},
	{
			"value": "14488134",
			"label": "14488134"
	},
	{
			"value": "14488142",
			"label": "14488142"
	},
	{
			"value": "14488159",
			"label": "14488159"
	},
	{
			"value": "14488167",
			"label": "14488167"
	},
	{
			"value": "14488175",
			"label": "14488175"
	},
	{
			"value": "14488183",
			"label": "14488183"
	},
	{
			"value": "14488191",
			"label": "14488191"
	},
	{
			"value": "14488209",
			"label": "14488209"
	},
	{
			"value": "14488217",
			"label": "14488217"
	},
	{
			"value": "14488225",
			"label": "14488225"
	},
	{
			"value": "14488233",
			"label": "14488233"
	},
	{
			"value": "14488241",
			"label": "14488241"
	},
	{
			"value": "14488258",
			"label": "14488258"
	},
	{
			"value": "14488266",
			"label": "14488266"
	},
	{
			"value": "14488274",
			"label": "14488274"
	},
	{
			"value": "14488282",
			"label": "14488282"
	},
	{
			"value": "14488290",
			"label": "14488290"
	},
	{
			"value": "14488308",
			"label": "14488308"
	},
	{
			"value": "14488316",
			"label": "14488316"
	},
	{
			"value": "14488324",
			"label": "14488324"
	},
	{
			"value": "14488332",
			"label": "14488332"
	},
	{
			"value": "14488340",
			"label": "14488340"
	},
	{
			"value": "14488357",
			"label": "14488357"
	},
	{
			"value": "14488365",
			"label": "14488365"
	},
	{
			"value": "14488373",
			"label": "14488373"
	},
	{
			"value": "14488381",
			"label": "14488381"
	},
	{
			"value": "14488399",
			"label": "14488399"
	},
	{
			"value": "14488407",
			"label": "14488407"
	},
	{
			"value": "14488415",
			"label": "14488415"
	},
	{
			"value": "14488423",
			"label": "14488423"
	},
	{
			"value": "14488431",
			"label": "14488431"
	},
	{
			"value": "14488449",
			"label": "14488449"
	},
	{
			"value": "14488456",
			"label": "14488456"
	},
	{
			"value": "14488464",
			"label": "14488464"
	},
	{
			"value": "14488472",
			"label": "14488472"
	},
	{
			"value": "14488480",
			"label": "14488480"
	},
	{
			"value": "14488498",
			"label": "14488498"
	},
	{
			"value": "14488506",
			"label": "14488506"
	},
	{
			"value": "14488514",
			"label": "14488514"
	},
	{
			"value": "14488522",
			"label": "14488522"
	},
	{
			"value": "14488530",
			"label": "14488530"
	},
	{
			"value": "14488548",
			"label": "14488548"
	},
	{
			"value": "14488555",
			"label": "14488555"
	},
	{
			"value": "14488563",
			"label": "14488563"
	},
	{
			"value": "14488571",
			"label": "14488571"
	},
	{
			"value": "14488589",
			"label": "14488589"
	},
	{
			"value": "14488597",
			"label": "14488597"
	},
	{
			"value": "14488605",
			"label": "14488605"
	},
	{
			"value": "14488613",
			"label": "14488613"
	},
	{
			"value": "14488621",
			"label": "14488621"
	},
	{
			"value": "14488639",
			"label": "14488639"
	},
	{
			"value": "14488647",
			"label": "14488647"
	},
	{
			"value": "14488654",
			"label": "14488654"
	},
	{
			"value": "14488662",
			"label": "14488662"
	},
	{
			"value": "14488670",
			"label": "14488670"
	},
	{
			"value": "14488688",
			"label": "14488688"
	},
	{
			"value": "14488696",
			"label": "14488696"
	},
	{
			"value": "14488704",
			"label": "14488704"
	},
	{
			"value": "14488712",
			"label": "14488712"
	},
	{
			"value": "14488720",
			"label": "14488720"
	},
	{
			"value": "14488738",
			"label": "14488738"
	},
	{
			"value": "14488746",
			"label": "14488746"
	},
	{
			"value": "144 CT",
			"label": "144 CT"
	},
	{
			"value": "1.44 OZ",
			"label": "1.44 OZ"
	},
	{
			"value": "144 X 110",
			"label": "144 X 110"
	},
	{
			"value": "144 X 84",
			"label": "144 X 84"
	},
	{
			"value": "144 X 96",
			"label": "144 X 96"
	},
	{
			"value": "14.5",
			"label": "14.5"
	},
	{
			"value": "145",
			"label": "145"
	},
	{
			"value": "1450",
			"label": "1450"
	},
	{
			"value": "14.50 X 10.12",
			"label": "14.50 X 10.12"
	},
	{
			"value": "14.50 X 10.25",
			"label": "14.50 X 10.25"
	},
	{
			"value": "14.50 X 6.50",
			"label": "14.50 X 6.50"
	},
	{
			"value": "14517163",
			"label": "14517163"
	},
	{
			"value": "14517171",
			"label": "14517171"
	},
	{
			"value": "14517189",
			"label": "14517189"
	},
	{
			"value": "14517197",
			"label": "14517197"
	},
	{
			"value": "14517205",
			"label": "14517205"
	},
	{
			"value": "14517213",
			"label": "14517213"
	},
	{
			"value": "-14544",
			"label": "-14544"
	},
	{
			"value": "1456",
			"label": "1456"
	},
	{
			"value": "14.56   X 14.96   X",
			"label": "14.56   X 14.96   X"
	},
	{
			"value": "14.56 X 14.96 X .11",
			"label": "14.56 X 14.96 X .11"
	},
	{
			"value": "14.57   X 10.63   X",
			"label": "14.57   X 10.63   X"
	},
	{
			"value": "14.57X22.95",
			"label": "14.57X22.95"
	},
	{
			"value": "14 5/8 X 11 5/8",
			"label": "14 5/8 X 11 5/8"
	},
	{
			"value": "14 5/8 X 12 5/8",
			"label": "14 5/8 X 12 5/8"
	},
	{
			"value": "14 5/8 X 14 5/8",
			"label": "14 5/8 X 14 5/8"
	},
	{
			"value": "14.5 IN",
			"label": "14.5 IN"
	},
	{
			"value": "14.5IN",
			"label": "14.5IN"
	},
	{
			"value": "1.45 OZ",
			"label": "1.45 OZ"
	},
	{
			"value": "14.5 OZ",
			"label": "14.5 OZ"
	},
	{
			"value": "14.5OZ / 410GM",
			"label": "14.5OZ / 410GM"
	},
	{
			"value": "14.5QT",
			"label": "14.5QT"
	},
	{
			"value": "14.5   X 11.3  X 6.",
			"label": "14.5   X 11.3  X 6."
	},
	{
			"value": "14.5   X 11.5   X 5",
			"label": "14.5   X 11.5   X 5"
	},
	{
			"value": "14.5X12X2.5",
			"label": "14.5X12X2.5"
	},
	{
			"value": "14.5X20",
			"label": "14.5X20"
	},
	{
			"value": "14.5   X 2.44   X 0",
			"label": "14.5   X 2.44   X 0"
	},
	{
			"value": "14.5 X 2 X 11",
			"label": "14.5 X 2 X 11"
	},
	{
			"value": "1464",
			"label": "1464"
	},
	{
			"value": "1470",
			"label": "1470"
	},
	{
			"value": "14712",
			"label": "14712"
	},
	{
			"value": "14-7/16 X 10-5/8 X 2",
			"label": "14-7/16 X 10-5/8 X 2"
	},
	{
			"value": "147.32",
			"label": "147.32"
	},
	{
			"value": "14.75 OZ",
			"label": "14.75 OZ"
	},
	{
			"value": "14.75 X 12.87",
			"label": "14.75 X 12.87"
	},
	{
			"value": "14.75X2.5X10",
			"label": "14.75X2.5X10"
	},
	{
			"value": "14.76",
			"label": "14.76"
	},
	{
			"value": "1476",
			"label": "1476"
	},
	{
			"value": "14772",
			"label": "14772"
	},
	{
			"value": "14784",
			"label": "14784"
	},
	{
			"value": " 14,8",
			"label": " 14,8"
	},
	{
			"value": "14800",
			"label": "14800"
	},
	{
			"value": "14.875   X 12.5   X",
			"label": "14.875   X 12.5   X"
	},
	{
			"value": "14.875X6.875X10.5",
			"label": "14.875X6.875X10.5"
	},
	{
			"value": "14.88",
			"label": "14.88"
	},
	{
			"value": "1.48 OZ",
			"label": "1.48 OZ"
	},
	{
			"value": "1 - 48   SHROUD",
			"label": "1 - 48   SHROUD"
	},
	{
			"value": "149",
			"label": "149"
	},
	{
			"value": "14.96 11.81H",
			"label": "14.96 11.81H"
	},
	{
			"value": "14.99",
			"label": "14.99"
	},
	{
			"value": "1.49 OZ",
			"label": "1.49 OZ"
	},
	{
			"value": "14   CORD",
			"label": "14   CORD"
	},
	{
			"value": "14G",
			"label": "14G"
	},
	{
			"value": "14  H X 10.5  W X 7",
			"label": "14  H X 10.5  W X 7"
	},
	{
			"value": "14  H X 7.25  W X 9",
			"label": "14  H X 7.25  W X 9"
	},
	{
			"value": "1/4 IN",
			"label": "1/4 IN"
	},
	{
			"value": "14 IN",
			"label": "14 IN"
	},
	{
			"value": "14IN",
			"label": "14IN"
	},
	{
			"value": "14IN *12",
			"label": "14IN *12"
	},
	{
			"value": "14IN*8",
			"label": "14IN*8"
	},
	{
			"value": "14 INCH",
			"label": "14 INCH"
	},
	{
			"value": "14 INCH SIZE 15",
			"label": "14 INCH SIZE 15"
	},
	{
			"value": "14 INCH SIZE 6",
			"label": "14 INCH SIZE 6"
	},
	{
			"value": "14 INCH SIZE 8",
			"label": "14 INCH SIZE 8"
	},
	{
			"value": "14IN H",
			"label": "14IN H"
	},
	{
			"value": "14 IN X 7.75 IN X 2.",
			"label": "14 IN X 7.75 IN X 2."
	},
	{
			"value": "14ML",
			"label": "14ML"
	},
	{
			"value": "14MM",
			"label": "14MM"
	},
	{
			"value": "14 OUNCES",
			"label": "14 OUNCES"
	},
	{
			"value": "14-OUNCES",
			"label": "14-OUNCES"
	},
	{
			"value": ".14 OZ",
			"label": ".14 OZ"
	},
	{
			"value": ".14 OZ.",
			"label": ".14 OZ."
	},
	{
			"value": "1.4 OZ",
			"label": "1.4 OZ"
	},
	{
			"value": "14 OZ",
			"label": "14 OZ"
	},
	{
			"value": "14 OZ.",
			"label": "14 OZ."
	},
	{
			"value": "14OZ",
			"label": "14OZ"
	},
	{
			"value": "1.4. OZ",
			"label": "1.4. OZ"
	},
	{
			"value": "1.4OZ",
			"label": "1.4OZ"
	},
	{
			"value": "14OZ/397G",
			"label": "14OZ/397G"
	},
	{
			"value": "14OZ / 397GRAMS",
			"label": "14OZ / 397GRAMS"
	},
	{
			"value": "1.4OZ/40G",
			"label": "1.4OZ/40G"
	},
	{
			"value": "14OZ JAR",
			"label": "14OZ JAR"
	},
	{
			"value": "14 OZ JAR CANDLE",
			"label": "14 OZ JAR CANDLE"
	},
	{
			"value": "14 PACK",
			"label": "14 PACK"
	},
	{
			"value": "14 PACK-1/2 INCH",
			"label": "14 PACK-1/2 INCH"
	},
	{
			"value": "14 PC",
			"label": "14 PC"
	},
	{
			"value": "14   ROUND",
			"label": "14   ROUND"
	},
	{
			"value": "14 STICK",
			"label": "14 STICK"
	},
	{
			"value": "14  W X 84  L",
			"label": "14  W X 84  L"
	},
	{
			"value": "14X0.875X12",
			"label": "14X0.875X12"
	},
	{
			"value": "14 X 10",
			"label": "14 X 10"
	},
	{
			"value": "14   X 10",
			"label": "14   X 10"
	},
	{
			"value": "14X10.3X6.5",
			"label": "14X10.3X6.5"
	},
	{
			"value": "14X10.4X9.6",
			"label": "14X10.4X9.6"
	},
	{
			"value": "14 X 10 X 18 INCH",
			"label": "14 X 10 X 18 INCH"
	},
	{
			"value": "14X11",
			"label": "14X11"
	},
	{
			"value": "14X11.5",
			"label": "14X11.5"
	},
	{
			"value": "14X11.50",
			"label": "14X11.50"
	},
	{
			"value": "14   X 11.5   X 5.3",
			"label": "14   X 11.5   X 5.3"
	},
	{
			"value": "14X11X4.5",
			"label": "14X11X4.5"
	},
	{
			"value": "14X11X7.75",
			"label": "14X11X7.75"
	},
	{
			"value": "14 X  12.25 X 10",
			"label": "14 X  12.25 X 10"
	},
	{
			"value": "1/4   X 12FT",
			"label": "1/4   X 12FT"
	},
	{
			"value": "14 X 13.4",
			"label": "14 X 13.4"
	},
	{
			"value": "14 X 13.5",
			"label": "14 X 13.5"
	},
	{
			"value": "14X14",
			"label": "14X14"
	},
	{
			"value": "14   X 14",
			"label": "14   X 14"
	},
	{
			"value": "14 X 14.75",
			"label": "14 X 14.75"
	},
	{
			"value": "14X14X1",
			"label": "14X14X1"
	},
	{
			"value": "14   X 14   X 1",
			"label": "14   X 14   X 1"
	},
	{
			"value": "14X14X2",
			"label": "14X14X2"
	},
	{
			"value": "14X14X3",
			"label": "14X14X3"
	},
	{
			"value": "14X14X4.75",
			"label": "14X14X4.75"
	},
	{
			"value": "14 X 17",
			"label": "14 X 17"
	},
	{
			"value": "14X17",
			"label": "14X17"
	},
	{
			"value": "14X18",
			"label": "14X18"
	},
	{
			"value": "14   X 18",
			"label": "14   X 18"
	},
	{
			"value": "14X2",
			"label": "14X2"
	},
	{
			"value": "14X20",
			"label": "14X20"
	},
	{
			"value": "14   X 20",
			"label": "14   X 20"
	},
	{
			"value": "14  X20  X3.25",
			"label": "14  X20  X3.25"
	},
	{
			"value": "14   X 20   X 3.5",
			"label": "14   X 20   X 3.5"
	},
	{
			"value": "14  X20  X3.5",
			"label": "14  X20  X3.5"
	},
	{
			"value": "1/4   X 20 YDS",
			"label": "1/4   X 20 YDS"
	},
	{
			"value": "14X22",
			"label": "14X22"
	},
	{
			"value": "14   X 22",
			"label": "14   X 22"
	},
	{
			"value": "14X.25X14",
			"label": "14X.25X14"
	},
	{
			"value": "14 X 2.5 X 48.28",
			"label": "14 X 2.5 X 48.28"
	},
	{
			"value": "14X26.5",
			"label": "14X26.5"
	},
	{
			"value": "14X28",
			"label": "14X28"
	},
	{
			"value": "14   X 28",
			"label": "14   X 28"
	},
	{
			"value": "1/4 X 288",
			"label": "1/4 X 288"
	},
	{
			"value": "1/4   X 288 YDS SPO",
			"label": "1/4   X 288 YDS SPO"
	},
	{
			"value": "14X2X12",
			"label": "14X2X12"
	},
	{
			"value": "14 X 2 X 14",
			"label": "14 X 2 X 14"
	},
	{
			"value": "1/4   X 2 YARDS",
			"label": "1/4   X 2 YARDS"
	},
	{
			"value": "14X32",
			"label": "14X32"
	},
	{
			"value": "1/4   X 3 YARDS",
			"label": "1/4   X 3 YARDS"
	},
	{
			"value": "1/4   X 40 YARDS",
			"label": "1/4   X 40 YARDS"
	},
	{
			"value": "14 X 4.75",
			"label": "14 X 4.75"
	},
	{
			"value": "14X4X11",
			"label": "14X4X11"
	},
	{
			"value": "14X4X15",
			"label": "14X4X15"
	},
	{
			"value": "1/4   X 4 YARDS",
			"label": "1/4   X 4 YARDS"
	},
	{
			"value": "14X6",
			"label": "14X6"
	},
	{
			"value": "14 X 7.25",
			"label": "14 X 7.25"
	},
	{
			"value": "14X7.5X10",
			"label": "14X7.5X10"
	},
	{
			"value": "14 X 7.5 X 58",
			"label": "14 X 7.5 X 58"
	},
	{
			"value": "14X8",
			"label": "14X8"
	},
	{
			"value": "14 X 9 X 8",
			"label": "14 X 9 X 8"
	},
	{
			"value": "14 Z",
			"label": "14 Z"
	},
	{
			"value": "1.5",
			"label": "1.5"
	},
	{
			"value": "1.5\"\"",
			"label": "1.5\"\""
	},
	{
			"value": "15",
			"label": "15"
	},
	{
			"value": "15\"\"",
			"label": "15\"\""
	},
	{
			"value": "15'",
			"label": "15'"
	},
	{
			"value": "150",
			"label": "150"
	},
	{
			"value": "1500",
			"label": "1500"
	},
	{
			"value": "15000",
			"label": "15000"
	},
	{
			"value": "15.00 X 12.00",
			"label": "15.00 X 12.00"
	},
	{
			"value": "150CT",
			"label": "150CT"
	},
	{
			"value": "150GM",
			"label": "150GM"
	},
	{
			"value": "150 GR",
			"label": "150 GR"
	},
	{
			"value": "150GR",
			"label": "150GR"
	},
	{
			"value": "150PC",
			"label": "150PC"
	},
	{
			"value": "150   X 110",
			"label": "150   X 110"
	},
	{
			"value": "150 X 14",
			"label": "150 X 14"
	},
	{
			"value": "150 X 14 X 63",
			"label": "150 X 14 X 63"
	},
	{
			"value": "150 X 15 X 21",
			"label": "150 X 15 X 21"
	},
	{
			"value": "150 X 16",
			"label": "150 X 16"
	},
	{
			"value": "150 X 18",
			"label": "150 X 18"
	},
	{
			"value": "150   X 54",
			"label": "150   X 54"
	},
	{
			"value": "150   X 63",
			"label": "150   X 63"
	},
	{
			"value": "150   X 72",
			"label": "150   X 72"
	},
	{
			"value": "150   X 84",
			"label": "150   X 84"
	},
	{
			"value": "150   X 96",
			"label": "150   X 96"
	},
	{
			"value": "150 YD",
			"label": "150 YD"
	},
	{
			"value": "150 YDS",
			"label": "150 YDS"
	},
	{
			"value": "15.10 X 11.8 X 6.0",
			"label": "15.10 X 11.8 X 6.0"
	},
	{
			"value": "15-1/2 X 10-3/4 X 3/",
			"label": "15-1/2 X 10-3/4 X 3/"
	},
	{
			"value": "1 5/16\"\"",
			"label": "1 5/16\"\""
	},
	{
			"value": "1-5/16",
			"label": "1-5/16"
	},
	{
			"value": "15/16",
			"label": "15/16"
	},
	{
			"value": "15/16\"\"",
			"label": "15/16\"\""
	},
	{
			"value": "15/16 IN.",
			"label": "15/16 IN."
	},
	{
			"value": "152.4",
			"label": "152.4"
	},
	{
			"value": "15.25143.5",
			"label": "15.25143.5"
	},
	{
			"value": "15.25X10.25",
			"label": "15.25X10.25"
	},
	{
			"value": "15.25 X 10.69 X 6.25",
			"label": "15.25 X 10.69 X 6.25"
	},
	{
			"value": "15.25X13X17.5",
			"label": "15.25X13X17.5"
	},
	{
			"value": "15.25 X 14.12",
			"label": "15.25 X 14.12"
	},
	{
			"value": "15.25 X 14 X 2",
			"label": "15.25 X 14 X 2"
	},
	{
			"value": "15.25 X 14 X 3.5",
			"label": "15.25 X 14 X 3.5"
	},
	{
			"value": "15.25 X 14 X 6.25",
			"label": "15.25 X 14 X 6.25"
	},
	{
			"value": "15.25   X 15.25   X",
			"label": "15.25   X 15.25   X"
	},
	{
			"value": "15.25 X 3.25 X 14.37",
			"label": "15.25 X 3.25 X 14.37"
	},
	{
			"value": "15.25 X 7.75",
			"label": "15.25 X 7.75"
	},
	{
			"value": "15.25 X 8 6.26 INCHE",
			"label": "15.25 X 8 6.26 INCHE"
	},
	{
			"value": "15.25 X 8 X 6.25",
			"label": "15.25 X 8 X 6.25"
	},
	{
			"value": "15.25 X 8 X6.25",
			"label": "15.25 X 8 X6.25"
	},
	{
			"value": "15 3/4 X 19 3/4",
			"label": "15 3/4 X 19 3/4"
	},
	{
			"value": "15.35",
			"label": "15.35"
	},
	{
			"value": "1.535IN X 5YD",
			"label": "1.535IN X 5YD"
	},
	{
			"value": "15.35X1.26X15.35",
			"label": "15.35X1.26X15.35"
	},
	{
			"value": "1536",
			"label": "1536"
	},
	{
			"value": "1.53 OZ",
			"label": "1.53 OZ"
	},
	{
			"value": "154.4",
			"label": "154.4"
	},
	{
			"value": "1.54 OZ",
			"label": "1.54 OZ"
	},
	{
			"value": "15.4 X 10.4 X 2.8",
			"label": "15.4 X 10.4 X 2.8"
	},
	{
			"value": "15.5",
			"label": "15.5"
	},
	{
			"value": "1550",
			"label": "1550"
	},
	{
			"value": "15.55X1.57X16.54",
			"label": "15.55X1.57X16.54"
	},
	{
			"value": "15.57 OZ",
			"label": "15.57 OZ"
	},
	{
			"value": "1.5, 5/8, 3/8",
			"label": "1.5, 5/8, 3/8"
	},
	{
			"value": "15 5/8 X 13 5/8",
			"label": "15 5/8 X 13 5/8"
	},
	{
			"value": "1559",
			"label": "1559"
	},
	{
			"value": "15.5IN",
			"label": "15.5IN"
	},
	{
			"value": "1.55 OZ",
			"label": "1.55 OZ"
	},
	{
			"value": "1.55OZ",
			"label": "1.55OZ"
	},
	{
			"value": "15.5 OZ",
			"label": "15.5 OZ"
	},
	{
			"value": "15.5OZ",
			"label": "15.5OZ"
	},
	{
			"value": "15.5X12.5X18.5",
			"label": "15.5X12.5X18.5"
	},
	{
			"value": "15.5 X 17 X 9.75",
			"label": "15.5 X 17 X 9.75"
	},
	{
			"value": "15.5X25",
			"label": "15.5X25"
	},
	{
			"value": "15.5X39",
			"label": "15.5X39"
	},
	{
			"value": "15.5X41.5",
			"label": "15.5X41.5"
	},
	{
			"value": "15.5X6.75X3.75IN",
			"label": "15.5X6.75X3.75IN"
	},
	{
			"value": "15.5X9.5",
			"label": "15.5X9.5"
	},
	{
			"value": "156",
			"label": "156"
	},
	{
			"value": "1560",
			"label": "1560"
	},
	{
			"value": "1570",
			"label": "1570"
	},
	{
			"value": "1.575",
			"label": "1.575"
	},
	{
			"value": "15.75  H X 11.75  W",
			"label": "15.75  H X 11.75  W"
	},
	{
			"value": "15.75 INCH",
			"label": "15.75 INCH"
	},
	{
			"value": "15.75 INCHES IN HEIG",
			"label": "15.75 INCHES IN HEIG"
	},
	{
			"value": "15.75X1.375X5.375",
			"label": "15.75X1.375X5.375"
	},
	{
			"value": "15.75X15.75X4.125",
			"label": "15.75X15.75X4.125"
	},
	{
			"value": "15.75X15.75X4.13",
			"label": "15.75X15.75X4.13"
	},
	{
			"value": "15.75 X 19.75",
			"label": "15.75 X 19.75"
	},
	{
			"value": "15.75   X 19.75",
			"label": "15.75   X 19.75"
	},
	{
			"value": "15.75 X .1 X 22.5",
			"label": "15.75 X .1 X 22.5"
	},
	{
			"value": "15.75X23.6",
			"label": "15.75X23.6"
	},
	{
			"value": "15.75 X 7.09 X 2.36",
			"label": "15.75 X 7.09 X 2.36"
	},
	{
			"value": "15.75 X 7.75",
			"label": "15.75 X 7.75"
	},
	{
			"value": "15.75X7.875X14.125",
			"label": "15.75X7.875X14.125"
	},
	{
			"value": "15.75X9X5.5",
			"label": "15.75X9X5.5"
	},
	{
			"value": "15.76",
			"label": "15.76"
	},
	{
			"value": "15 7/8 X 17 7/8",
			"label": "15 7/8 X 17 7/8"
	},
	{
			"value": "1 5/8",
			"label": "1 5/8"
	},
	{
			"value": "1 5/8\"\"",
			"label": "1 5/8\"\""
	},
	{
			"value": "1-5/8",
			"label": "1-5/8"
	},
	{
			"value": "1 5/8   (41MM)",
			"label": "1 5/8   (41MM)"
	},
	{
			"value": "1 5/8 (41MM)",
			"label": "1 5/8 (41MM)"
	},
	{
			"value": "1-5/8   X 1 YARD",
			"label": "1-5/8   X 1 YARD"
	},
	{
			"value": "1-5/8   X 2 YARDS",
			"label": "1-5/8   X 2 YARDS"
	},
	{
			"value": "1 5/8   X 3FT",
			"label": "1 5/8   X 3FT"
	},
	{
			"value": "1 5/8   X 6YDS",
			"label": "1 5/8   X 6YDS"
	},
	{
			"value": "1 5/8  X 6YDS",
			"label": "1 5/8  X 6YDS"
	},
	{
			"value": "15.9 INCH",
			"label": "15.9 INCH"
	},
	{
			"value": "15CT",
			"label": "15CT"
	},
	{
			"value": ".15\"\"DIA X 2.6\"\"H",
			"label": ".15\"\"DIA X 2.6\"\"H"
	},
	{
			"value": "15FT",
			"label": "15FT"
	},
	{
			"value": "15G",
			"label": "15G"
	},
	{
			"value": "15 GALLON",
			"label": "15 GALLON"
	},
	{
			"value": "1.5 IN",
			"label": "1.5 IN"
	},
	{
			"value": "15 IN",
			"label": "15 IN"
	},
	{
			"value": "15IN",
			"label": "15IN"
	},
	{
			"value": "1.5IN",
			"label": "1.5IN"
	},
	{
			"value": "1.5 IN 12 FT",
			"label": "1.5 IN 12 FT"
	},
	{
			"value": "15IN *18",
			"label": "15IN *18"
	},
	{
			"value": "1.5 INCH",
			"label": "1.5 INCH"
	},
	{
			"value": "15 INCH",
			"label": "15 INCH"
	},
	{
			"value": "15 INCHES IN HEIGHT",
			"label": "15 INCHES IN HEIGHT"
	},
	{
			"value": "1.5INCH X 3FT",
			"label": "1.5INCH X 3FT"
	},
	{
			"value": "1.5 INCH X 6 YARDS",
			"label": "1.5 INCH X 6 YARDS"
	},
	{
			"value": "15 IN X 10 FT",
			"label": "15 IN X 10 FT"
	},
	{
			"value": "1.5 IN X 10 YARD",
			"label": "1.5 IN X 10 YARD"
	},
	{
			"value": "1.5 IN X 12 FT",
			"label": "1.5 IN X 12 FT"
	},
	{
			"value": "1.5 IN. X 12 FT.",
			"label": "1.5 IN. X 12 FT."
	},
	{
			"value": "1.5IN X 12FT",
			"label": "1.5IN X 12FT"
	},
	{
			"value": "15INX4IN",
			"label": "15INX4IN"
	},
	{
			"value": "1.5 IN X 9 FT",
			"label": "1.5 IN X 9 FT"
	},
	{
			"value": "1.5 IN. X 9 FT.",
			"label": "1.5 IN. X 9 FT."
	},
	{
			"value": "1.5 LB",
			"label": "1.5 LB"
	},
	{
			"value": "15MM",
			"label": "15MM"
	},
	{
			"value": "15MM 30 PCS",
			"label": "15MM 30 PCS"
	},
	{
			"value": "15MM DIAMETER, 60.9",
			"label": "15MM DIAMETER, 60.9"
	},
	{
			"value": "15MM DIAM. X 24   L",
			"label": "15MM DIAM. X 24   L"
	},
	{
			"value": "15MMX10M",
			"label": "15MMX10M"
	},
	{
			"value": "15 MM X 8 M",
			"label": "15 MM X 8 M"
	},
	{
			"value": ".15 OZ",
			"label": ".15 OZ"
	},
	{
			"value": ".15OZ",
			"label": ".15OZ"
	},
	{
			"value": "1.5 OZ",
			"label": "1.5 OZ"
	},
	{
			"value": "1.5 OZ.",
			"label": "1.5 OZ."
	},
	{
			"value": "15 OZ",
			"label": "15 OZ"
	},
	{
			"value": "15OZ",
			"label": "15OZ"
	},
	{
			"value": "1.5OZ",
			"label": "1.5OZ"
	},
	{
			"value": "15PC",
			"label": "15PC"
	},
	{
			"value": "15 STICK",
			"label": "15 STICK"
	},
	{
			"value": "15 STICKS",
			"label": "15 STICKS"
	},
	{
			"value": "15   X 10'",
			"label": "15   X 10'"
	},
	{
			"value": "15 X 10.75 X  4.875",
			"label": "15 X 10.75 X  4.875"
	},
	{
			"value": "15 X 10.75 X 4.875",
			"label": "15 X 10.75 X 4.875"
	},
	{
			"value": "15X10X7",
			"label": "15X10X7"
	},
	{
			"value": "15X10X7.75",
			"label": "15X10X7.75"
	},
	{
			"value": "1.5   X 10YDS",
			"label": "1.5   X 10YDS"
	},
	{
			"value": "1.5  X10YDS",
			"label": "1.5  X10YDS"
	},
	{
			"value": "15X11.5",
			"label": "15X11.5"
	},
	{
			"value": "1.5   X 12'",
			"label": "1.5   X 12'"
	},
	{
			"value": "1.5  X12'",
			"label": "1.5  X12'"
	},
	{
			"value": "15X13",
			"label": "15X13"
	},
	{
			"value": "15 X 14.5 X 3.5",
			"label": "15 X 14.5 X 3.5"
	},
	{
			"value": "15X1.4X15",
			"label": "15X1.4X15"
	},
	{
			"value": "15 X 14 X 3.5",
			"label": "15 X 14 X 3.5"
	},
	{
			"value": "15 X 15",
			"label": "15 X 15"
	},
	{
			"value": "15X15",
			"label": "15X15"
	},
	{
			"value": "1.5 X 1.5 X 1.5",
			"label": "1.5 X 1.5 X 1.5"
	},
	{
			"value": "15   X 15   X 15.1",
			"label": "15   X 15   X 15.1"
	},
	{
			"value": "15X17X1",
			"label": "15X17X1"
	},
	{
			"value": "15   X 17   X 1",
			"label": "15   X 17   X 1"
	},
	{
			"value": "15X17X2",
			"label": "15X17X2"
	},
	{
			"value": "15X17X3",
			"label": "15X17X3"
	},
	{
			"value": "15   X 18",
			"label": "15   X 18"
	},
	{
			"value": "15X18X.625",
			"label": "15X18X.625"
	},
	{
			"value": "15X19",
			"label": "15X19"
	},
	{
			"value": "15X1X15",
			"label": "15X1X15"
	},
	{
			"value": "1.5 X 2",
			"label": "1.5 X 2"
	},
	{
			"value": "1.5  X 2",
			"label": "1.5  X 2"
	},
	{
			"value": "15 X 20",
			"label": "15 X 20"
	},
	{
			"value": "15X24",
			"label": "15X24"
	},
	{
			"value": "15X2.5X2",
			"label": "15X2.5X2"
	},
	{
			"value": "15X30",
			"label": "15X30"
	},
	{
			"value": "1.5   X 3FT",
			"label": "1.5   X 3FT"
	},
	{
			"value": "15X4",
			"label": "15X4"
	},
	{
			"value": "15X42",
			"label": "15X42"
	},
	{
			"value": "1.5 X 4 X 8.6",
			"label": "1.5 X 4 X 8.6"
	},
	{
			"value": "15X5",
			"label": "15X5"
	},
	{
			"value": "15X5IN",
			"label": "15X5IN"
	},
	{
			"value": "1.5' X 5YD",
			"label": "1.5' X 5YD"
	},
	{
			"value": "1.5   X 5YDS",
			"label": "1.5   X 5YDS"
	},
	{
			"value": "15X6.5",
			"label": "15X6.5"
	},
	{
			"value": "1.5   X 6YD",
			"label": "1.5   X 6YD"
	},
	{
			"value": "15X7",
			"label": "15X7"
	},
	{
			"value": "15X7.5X5",
			"label": "15X7.5X5"
	},
	{
			"value": "15X8.5X11",
			"label": "15X8.5X11"
	},
	{
			"value": "1.5 X 9'",
			"label": "1.5 X 9'"
	},
	{
			"value": "1.5   X 9'",
			"label": "1.5   X 9'"
	},
	{
			"value": "1.5  X9'",
			"label": "1.5  X9'"
	},
	{
			"value": "15 X 9.25 X 9",
			"label": "15 X 9.25 X 9"
	},
	{
			"value": "1.5   X 9FT",
			"label": "1.5   X 9FT"
	},
	{
			"value": "15YD",
			"label": "15YD"
	},
	{
			"value": "15YDS",
			"label": "15YDS"
	},
	{
			"value": "1.6",
			"label": "1.6"
	},
	{
			"value": "16",
			"label": "16"
	},
	{
			"value": "16\"\"",
			"label": "16\"\""
	},
	{
			"value": "160",
			"label": "160"
	},
	{
			"value": "1600",
			"label": "1600"
	},
	{
			"value": "16000",
			"label": "16000"
	},
	{
			"value": "160000",
			"label": "160000"
	},
	{
			"value": "16.00 X 10.50",
			"label": "16.00 X 10.50"
	},
	{
			"value": "16.00 X 12.75",
			"label": "16.00 X 12.75"
	},
	{
			"value": "16128",
			"label": "16128"
	},
	{
			"value": "16 1/4 X 16 1/4",
			"label": "16 1/4 X 16 1/4"
	},
	{
			"value": "16/17",
			"label": "16/17"
	},
	{
			"value": "16.1''LX16.1''WX23.6",
			"label": "16.1''LX16.1''WX23.6"
	},
	{
			"value": "1.61 OZ",
			"label": "1.61 OZ"
	},
	{
			"value": "16.1 X 16.1 X 5.1",
			"label": "16.1 X 16.1 X 5.1"
	},
	{
			"value": "1.625",
			"label": "1.625"
	},
	{
			"value": "1.625IN",
			"label": "1.625IN"
	},
	{
			"value": "16.25 X 11.38",
			"label": "16.25 X 11.38"
	},
	{
			"value": "16.25 X 15.12",
			"label": "16.25 X 15.12"
	},
	{
			"value": "16.25X16.25",
			"label": "16.25X16.25"
	},
	{
			"value": "16.25X18",
			"label": "16.25X18"
	},
	{
			"value": "16.25 X 1 X 16.5",
			"label": "16.25 X 1 X 16.5"
	},
	{
			"value": "16.25X2.25X6",
			"label": "16.25X2.25X6"
	},
	{
			"value": "1.625 X 2 X 0.25 INC",
			"label": "1.625 X 2 X 0.25 INC"
	},
	{
			"value": "1.625   X 6YD",
			"label": "1.625   X 6YD"
	},
	{
			"value": "16.25 X 9.5 X 9.25",
			"label": "16.25 X 9.5 X 9.25"
	},
	{
			"value": "1626",
			"label": "1626"
	},
	{
			"value": "16-28",
			"label": "16-28"
	},
	{
			"value": "16/28",
			"label": "16/28"
	},
	{
			"value": "1.62 OZ",
			"label": "1.62 OZ"
	},
	{
			"value": "16.2 OZ",
			"label": "16.2 OZ"
	},
	{
			"value": "16.30 X 17 X 64",
			"label": "16.30 X 17 X 64"
	},
	{
			"value": "16.33 X 8.27",
			"label": "16.33 X 8.27"
	},
	{
			"value": "16 3/4 X14 3/4",
			"label": "16 3/4 X14 3/4"
	},
	{
			"value": "1.63 OZ",
			"label": "1.63 OZ"
	},
	{
			"value": "16.5",
			"label": "16.5"
	},
	{
			"value": "165",
			"label": "165"
	},
	{
			"value": "1650",
			"label": "1650"
	},
	{
			"value": "16512",
			"label": "16512"
	},
	{
			"value": "16.54 X 11.84 X 7.36",
			"label": "16.54 X 11.84 X 7.36"
	},
	{
			"value": "16.55 OZ",
			"label": "16.55 OZ"
	},
	{
			"value": "1656",
			"label": "1656"
	},
	{
			"value": "16 5/8 X 19 5/8",
			"label": "16 5/8 X 19 5/8"
	},
	{
			"value": "16.5 IN",
			"label": "16.5 IN"
	},
	{
			"value": "16.5IN",
			"label": "16.5IN"
	},
	{
			"value": "16.5OZ",
			"label": "16.5OZ"
	},
	{
			"value": "16.5X10.25",
			"label": "16.5X10.25"
	},
	{
			"value": "16.5X11.5X6.25",
			"label": "16.5X11.5X6.25"
	},
	{
			"value": "16.5X11X4",
			"label": "16.5X11X4"
	},
	{
			"value": "16.5 X 13.1875 X 2.3",
			"label": "16.5 X 13.1875 X 2.3"
	},
	{
			"value": "16.5 X 16.5 X 7.25",
			"label": "16.5 X 16.5 X 7.25"
	},
	{
			"value": "16.5 X 8.3 X 11.8",
			"label": "16.5 X 8.3 X 11.8"
	},
	{
			"value": "16.625 X 11.875 X 2.",
			"label": "16.625 X 11.875 X 2."
	},
	{
			"value": " 16,6,4",
			"label": " 16,6,4"
	},
	{
			"value": "16.66   X 13.41   X",
			"label": "16.66   X 13.41   X"
	},
	{
			"value": "1668",
			"label": "1668"
	},
	{
			"value": "1.66 OZ",
			"label": "1.66 OZ"
	},
	{
			"value": "16.6 X  1.5 X 1.5",
			"label": "16.6 X  1.5 X 1.5"
	},
	{
			"value": "167",
			"label": "167"
	},
	{
			"value": "1670",
			"label": "1670"
	},
	{
			"value": "16.75",
			"label": "16.75"
	},
	{
			"value": "16.75   DIAMETER X",
			"label": "16.75   DIAMETER X"
	},
	{
			"value": "16.75 X 4.75",
			"label": "16.75 X 4.75"
	},
	{
			"value": "16800",
			"label": "16800"
	},
	{
			"value": "16.8X11.8",
			"label": "16.8X11.8"
	},
	{
			"value": "16.9",
			"label": "16.9"
	},
	{
			"value": "1690",
			"label": "1690"
	},
	{
			"value": "16980",
			"label": "16980"
	},
	{
			"value": "16.9 FL OZ",
			"label": "16.9 FL OZ"
	},
	{
			"value": "16.9 IN",
			"label": "16.9 IN"
	},
	{
			"value": "1.69 OZ",
			"label": "1.69 OZ"
	},
	{
			"value": "16.9X3.2X16.9",
			"label": "16.9X3.2X16.9"
	},
	{
			"value": "16 COMPARTMENT",
			"label": "16 COMPARTMENT"
	},
	{
			"value": "16CT",
			"label": "16CT"
	},
	{
			"value": "16 FL OZ",
			"label": "16 FL OZ"
	},
	{
			"value": "16 FOOT HANK",
			"label": "16 FOOT HANK"
	},
	{
			"value": "16FT",
			"label": "16FT"
	},
	{
			"value": "16 FT HANK",
			"label": "16 FT HANK"
	},
	{
			"value": "16G",
			"label": "16G"
	},
	{
			"value": "16 IN",
			"label": "16 IN"
	},
	{
			"value": "16IN",
			"label": "16IN"
	},
	{
			"value": "1 6IN",
			"label": "1 6IN"
	},
	{
			"value": "16 INCH",
			"label": "16 INCH"
	},
	{
			"value": "16 INCH IN HEIGHT",
			"label": "16 INCH IN HEIGHT"
	},
	{
			"value": "16 INCH SIZE 0",
			"label": "16 INCH SIZE 0"
	},
	{
			"value": "16 INCH SIZE 1",
			"label": "16 INCH SIZE 1"
	},
	{
			"value": "16 INCH SIZE 2",
			"label": "16 INCH SIZE 2"
	},
	{
			"value": "16 INCH SIZE 3",
			"label": "16 INCH SIZE 3"
	},
	{
			"value": "16 INCH X 18 INCH",
			"label": "16 INCH X 18 INCH"
	},
	{
			"value": "16 IN X 8.75 IN X 3",
			"label": "16 IN X 8.75 IN X 3"
	},
	{
			"value": "16-MAR",
			"label": "16-MAR"
	},
	{
			"value": "16MM",
			"label": "16MM"
	},
	{
			"value": "16 OUNCES",
			"label": "16 OUNCES"
	},
	{
			"value": "1.6 OZ",
			"label": "1.6 OZ"
	},
	{
			"value": "16 OZ",
			"label": "16 OZ"
	},
	{
			"value": "16 OZ.",
			"label": "16 OZ."
	},
	{
			"value": "16OZ",
			"label": "16OZ"
	},
	{
			"value": "16PC/1IN",
			"label": "16PC/1IN"
	},
	{
			"value": "16 PIECES",
			"label": "16 PIECES"
	},
	{
			"value": "16   ROUND",
			"label": "16   ROUND"
	},
	{
			"value": "16W(16-18-20)",
			"label": "16W(16-18-20)"
	},
	{
			"value": "16X0X16",
			"label": "16X0X16"
	},
	{
			"value": "16X10",
			"label": "16X10"
	},
	{
			"value": "16X10.5X17.5",
			"label": "16X10.5X17.5"
	},
	{
			"value": "16   X 10   X 8",
			"label": "16   X 10   X 8"
	},
	{
			"value": "16X11.5",
			"label": "16X11.5"
	},
	{
			"value": "16X12",
			"label": "16X12"
	},
	{
			"value": "16X1.25X16",
			"label": "16X1.25X16"
	},
	{
			"value": "16X12X1",
			"label": "16X12X1"
	},
	{
			"value": "16 X 13-1/8 X 3-1/8",
			"label": "16 X 13-1/8 X 3-1/8"
	},
	{
			"value": "16X14",
			"label": "16X14"
	},
	{
			"value": "16X1.5X16",
			"label": "16X1.5X16"
	},
	{
			"value": "16 X 16",
			"label": "16 X 16"
	},
	{
			"value": "16X16",
			"label": "16X16"
	},
	{
			"value": "16   X 16",
			"label": "16   X 16"
	},
	{
			"value": "16X16X1",
			"label": "16X16X1"
	},
	{
			"value": "1.6X1.6X1",
			"label": "1.6X1.6X1"
	},
	{
			"value": "16 X 16 X 20 INCHES",
			"label": "16 X 16 X 20 INCHES"
	},
	{
			"value": "16X16X22",
			"label": "16X16X22"
	},
	{
			"value": "16X1X15",
			"label": "16X1X15"
	},
	{
			"value": "16 X 20",
			"label": "16 X 20"
	},
	{
			"value": "16X 20",
			"label": "16X 20"
	},
	{
			"value": "16X20",
			"label": "16X20"
	},
	{
			"value": "16   X 20",
			"label": "16   X 20"
	},
	{
			"value": "16X20 3",
			"label": "16X20 3"
	},
	{
			"value": "16X20 FLOAT",
			"label": "16X20 FLOAT"
	},
	{
			"value": "16X20 MAT 11X14",
			"label": "16X20 MAT 11X14"
	},
	{
			"value": "16X20 MAT TO 11X14",
			"label": "16X20 MAT TO 11X14"
	},
	{
			"value": "16X20X1.75",
			"label": "16X20X1.75"
	},
	{
			"value": "16X24",
			"label": "16X24"
	},
	{
			"value": "16   X 24",
			"label": "16   X 24"
	},
	{
			"value": "16X24 INCH",
			"label": "16X24 INCH"
	},
	{
			"value": "16 X 2.5 X 13",
			"label": "16 X 2.5 X 13"
	},
	{
			"value": "16X32",
			"label": "16X32"
	},
	{
			"value": "16X3.25X3.25",
			"label": "16X3.25X3.25"
	},
	{
			"value": "16X36",
			"label": "16X36"
	},
	{
			"value": "16X38",
			"label": "16X38"
	},
	{
			"value": "16X3X13",
			"label": "16X3X13"
	},
	{
			"value": "16X3X16",
			"label": "16X3X16"
	},
	{
			"value": "16 X 3 X 20.5",
			"label": "16 X 3 X 20.5"
	},
	{
			"value": "16X4",
			"label": "16X4"
	},
	{
			"value": "16X4.5",
			"label": "16X4.5"
	},
	{
			"value": "16X4X13",
			"label": "16X4X13"
	},
	{
			"value": "16X6X23",
			"label": "16X6X23"
	},
	{
			"value": "16 X 6 X 3",
			"label": "16 X 6 X 3"
	},
	{
			"value": "16X6X6.3",
			"label": "16X6X6.3"
	},
	{
			"value": "16X7",
			"label": "16X7"
	},
	{
			"value": "16X7.25X2",
			"label": "16X7.25X2"
	},
	{
			"value": "16X9.5X16.5",
			"label": "16X9.5X16.5"
	},
	{
			"value": "17",
			"label": "17"
	},
	{
			"value": "1700",
			"label": "1700"
	},
	{
			"value": "17000",
			"label": "17000"
	},
	{
			"value": "17.00 X 22.00",
			"label": "17.00 X 22.00"
	},
	{
			"value": "1702",
			"label": "1702"
	},
	{
			"value": "170 CU IN",
			"label": "170 CU IN"
	},
	{
			"value": "170FT",
			"label": "170FT"
	},
	{
			"value": "17.0 X 17.0 X 9.0 IN",
			"label": "17.0 X 17.0 X 9.0 IN"
	},
	{
			"value": "1 7/16",
			"label": "1 7/16"
	},
	{
			"value": "1 7/16   X 25YD",
			"label": "1 7/16   X 25YD"
	},
	{
			"value": "17.17 X .83 X 3.46",
			"label": "17.17 X .83 X 3.46"
	},
	{
			"value": "17 1/8 X 13 1/8",
			"label": "17 1/8 X 13 1/8"
	},
	{
			"value": "1.71 LB",
			"label": "1.71 LB"
	},
	{
			"value": "1720",
			"label": "1720"
	},
	{
			"value": "1725",
			"label": "1725"
	},
	{
			"value": "17.25IN",
			"label": "17.25IN"
	},
	{
			"value": "17.25X10.75X16.5",
			"label": "17.25X10.75X16.5"
	},
	{
			"value": "17.25X11.5",
			"label": "17.25X11.5"
	},
	{
			"value": "17.25 X 13.125 X 7",
			"label": "17.25 X 13.125 X 7"
	},
	{
			"value": "17.25 X 17.75 X 3.88",
			"label": "17.25 X 17.75 X 3.88"
	},
	{
			"value": "17.25 X 8.50",
			"label": "17.25 X 8.50"
	},
	{
			"value": "1.72OZ",
			"label": "1.72OZ"
	},
	{
			"value": "173",
			"label": "173"
	},
	{
			"value": "17472",
			"label": "17472"
	},
	{
			"value": " 1,74 OZ",
			"label": " 1,74 OZ"
	},
	{
			"value": "1.74 OZ",
			"label": "1.74 OZ"
	},
	{
			"value": "1.75",
			"label": "1.75"
	},
	{
			"value": "17.5",
			"label": "17.5"
	},
	{
			"value": "175",
			"label": "175"
	},
	{
			"value": "1750",
			"label": "1750"
	},
	{
			"value": "1752",
			"label": "1752"
	},
	{
			"value": "17 5/8 X 17 5/8",
			"label": "17 5/8 X 17 5/8"
	},
	{
			"value": "1.75IN",
			"label": "1.75IN"
	},
	{
			"value": "1.75 INCH",
			"label": "1.75 INCH"
	},
	{
			"value": "17.5IN(H)",
			"label": "17.5IN(H)"
	},
	{
			"value": "1.75 OZ",
			"label": "1.75 OZ"
	},
	{
			"value": "1.75 OZ.",
			"label": "1.75 OZ."
	},
	{
			"value": "1.75OZ",
			"label": "1.75OZ"
	},
	{
			"value": "17.5 OZ",
			"label": "17.5 OZ"
	},
	{
			"value": "1.75OZ/50G",
			"label": "1.75OZ/50G"
	},
	{
			"value": "1.75OZ/50GM",
			"label": "1.75OZ/50GM"
	},
	{
			"value": "1.75 OZ/50 GRAMS",
			"label": "1.75 OZ/50 GRAMS"
	},
	{
			"value": "1.75OZ / 50 GRAMS",
			"label": "1.75OZ / 50 GRAMS"
	},
	{
			"value": "17.5X11.6X5.5X",
			"label": "17.5X11.6X5.5X"
	},
	{
			"value": "17.5X11.6X9.6",
			"label": "17.5X11.6X9.6"
	},
	{
			"value": "17.5X17.5",
			"label": "17.5X17.5"
	},
	{
			"value": "1.75 X 1.75 X 0.5 IN",
			"label": "1.75 X 1.75 X 0.5 IN"
	},
	{
			"value": "1.75 X 1.75 X 0.75 I",
			"label": "1.75 X 1.75 X 0.75 I"
	},
	{
			"value": "17.5 X 17.5 X 17",
			"label": "17.5 X 17.5 X 17"
	},
	{
			"value": "1.75 X 2.5",
			"label": "1.75 X 2.5"
	},
	{
			"value": "1.75X2.75",
			"label": "1.75X2.75"
	},
	{
			"value": "1.75 X 4.75",
			"label": "1.75 X 4.75"
	},
	{
			"value": "17.5X4X.3",
			"label": "17.5X4X.3"
	},
	{
			"value": "1.75   X 6YD",
			"label": "1.75   X 6YD"
	},
	{
			"value": "17.5X7.3X5.5",
			"label": "17.5X7.3X5.5"
	},
	{
			"value": "17.5 X 9.25 X 12.75",
			"label": "17.5 X 9.25 X 12.75"
	},
	{
			"value": "17.63 OUNCES",
			"label": "17.63 OUNCES"
	},
	{
			"value": "1764",
			"label": "1764"
	},
	{
			"value": "1.76 OZ",
			"label": "1.76 OZ"
	},
	{
			"value": "1.76OZ",
			"label": "1.76OZ"
	},
	{
			"value": "17.71",
			"label": "17.71"
	},
	{
			"value": "17712",
			"label": "17712"
	},
	{
			"value": "17.71   X 29.52",
			"label": "17.71   X 29.52"
	},
	{
			"value": "17.72  X 13.89 X 6.1",
			"label": "17.72  X 13.89 X 6.1"
	},
	{
			"value": "17.72X24",
			"label": "17.72X24"
	},
	{
			"value": "17.72X26.18",
			"label": "17.72X26.18"
	},
	{
			"value": "17.75   X 17.75   X",
			"label": "17.75   X 17.75   X"
	},
	{
			"value": "1776",
			"label": "1776"
	},
	{
			"value": "1.77 OZ",
			"label": "1.77 OZ"
	},
	{
			"value": "1.77OZ",
			"label": "1.77OZ"
	},
	{
			"value": "17.7 OZ",
			"label": "17.7 OZ"
	},
	{
			"value": "1 7/8\"\"",
			"label": "1 7/8\"\""
	},
	{
			"value": "1-7/8",
			"label": "1-7/8"
	},
	{
			"value": "1786",
			"label": "1786"
	},
	{
			"value": "1 7/8   X 1",
			"label": "1 7/8   X 1"
	},
	{
			"value": "1 7/8   X 10YDS",
			"label": "1 7/8   X 10YDS"
	},
	{
			"value": "1 7/8   X 3FT",
			"label": "1 7/8   X 3FT"
	},
	{
			"value": "17900",
			"label": "17900"
	},
	{
			"value": "1.79 LB",
			"label": "1.79 LB"
	},
	{
			"value": "1.79 OZ",
			"label": "1.79 OZ"
	},
	{
			"value": "17.9QT",
			"label": "17.9QT"
	},
	{
			"value": "17 GALLON",
			"label": "17 GALLON"
	},
	{
			"value": "17 IN",
			"label": "17 IN"
	},
	{
			"value": "17IN",
			"label": "17IN"
	},
	{
			"value": "17 INCH",
			"label": "17 INCH"
	},
	{
			"value": "17IN H",
			"label": "17IN H"
	},
	{
			"value": "17K XBRD",
			"label": "17K XBRD"
	},
	{
			"value": "1.7 LB",
			"label": "1.7 LB"
	},
	{
			"value": ".17 OZ",
			"label": ".17 OZ"
	},
	{
			"value": "1.7 OZ",
			"label": "1.7 OZ"
	},
	{
			"value": "1.7 OZ.",
			"label": "1.7 OZ."
	},
	{
			"value": "17 OZ",
			"label": "17 OZ"
	},
	{
			"value": "17 OZ.",
			"label": "17 OZ."
	},
	{
			"value": "1.7OZ",
			"label": "1.7OZ"
	},
	{
			"value": "17 POINT 5 INCH",
			"label": "17 POINT 5 INCH"
	},
	{
			"value": "17   WIDTH X 35 YDS",
			"label": "17   WIDTH X 35 YDS"
	},
	{
			"value": "17  WX21  L",
			"label": "17  WX21  L"
	},
	{
			"value": "17 X 10.20 X 26",
			"label": "17 X 10.20 X 26"
	},
	{
			"value": "17X11X2",
			"label": "17X11X2"
	},
	{
			"value": "17X1.25X21",
			"label": "17X1.25X21"
	},
	{
			"value": "17 X 15",
			"label": "17 X 15"
	},
	{
			"value": "17 X 15.75 X 17",
			"label": "17 X 15.75 X 17"
	},
	{
			"value": "17X15X1",
			"label": "17X15X1"
	},
	{
			"value": "17X15X2",
			"label": "17X15X2"
	},
	{
			"value": "17X1.5X23",
			"label": "17X1.5X23"
	},
	{
			"value": "17X15X3",
			"label": "17X15X3"
	},
	{
			"value": "17X17",
			"label": "17X17"
	},
	{
			"value": "17 X 17 X 9 INCH",
			"label": "17 X 17 X 9 INCH"
	},
	{
			"value": "17   X 1 YD PACKAGE",
			"label": "17   X 1 YD PACKAGE"
	},
	{
			"value": "17 X 22 INCHES",
			"label": "17 X 22 INCHES"
	},
	{
			"value": "17 X 23",
			"label": "17 X 23"
	},
	{
			"value": "17X23",
			"label": "17X23"
	},
	{
			"value": "17X24",
			"label": "17X24"
	},
	{
			"value": "17   X 28",
			"label": "17   X 28"
	},
	{
			"value": "17X29.5",
			"label": "17X29.5"
	},
	{
			"value": "17   X 54   PACKAGE",
			"label": "17   X 54   PACKAGE"
	},
	{
			"value": "17   X 8   X 1",
			"label": "17   X 8   X 1"
	},
	{
			"value": ".18",
			"label": ".18"
	},
	{
			"value": "1 8\"\"",
			"label": "1 8\"\""
	},
	{
			"value": "1/8",
			"label": "1/8"
	},
	{
			"value": "1/8\"\"",
			"label": "1/8\"\""
	},
	{
			"value": "18",
			"label": "18"
	},
	{
			"value": "180",
			"label": "180"
	},
	{
			"value": "1800",
			"label": "1800"
	},
	{
			"value": "18000",
			"label": "18000"
	},
	{
			"value": "1800 YDS",
			"label": "1800 YDS"
	},
	{
			"value": "180GR",
			"label": "180GR"
	},
	{
			"value": "180IN",
			"label": "180IN"
	},
	{
			"value": "180MM",
			"label": "180MM"
	},
	{
			"value": "1812",
			"label": "1812"
	},
	{
			"value": "18.12 IN",
			"label": "18.12 IN"
	},
	{
			"value": "18/19",
			"label": "18/19"
	},
	{
			"value": "18192",
			"label": "18192"
	},
	{
			"value": "18-19CM",
			"label": "18-19CM"
	},
	{
			"value": "18.1 IN",
			"label": "18.1 IN"
	},
	{
			"value": "1.81   X  25 YD",
			"label": "1.81   X  25 YD"
	},
	{
			"value": "(18-20-22)",
			"label": "(18-20-22)"
	},
	{
			"value": "1824",
			"label": "1824"
	},
	{
			"value": "18.25",
			"label": "18.25"
	},
	{
			"value": "18.25X25.5",
			"label": "18.25X25.5"
	},
	{
			"value": "18300",
			"label": "18300"
	},
	{
			"value": "1838",
			"label": "1838"
	},
	{
			"value": "1.83 OZ",
			"label": "1.83 OZ"
	},
	{
			"value": "1840",
			"label": "1840"
	},
	{
			"value": "-1848",
			"label": "-1848"
	},
	{
			"value": "184 GRAMS",
			"label": "184 GRAMS"
	},
	{
			"value": "1.84 OZ",
			"label": "1.84 OZ"
	},
	{
			"value": "18.4X18.4",
			"label": "18.4X18.4"
	},
	{
			"value": "18.5",
			"label": "18.5"
	},
	{
			"value": "1850",
			"label": "1850"
	},
	{
			"value": "18 5/8 X 18 5/8",
			"label": "18 5/8 X 18 5/8"
	},
	{
			"value": "18.5 IN",
			"label": "18.5 IN"
	},
	{
			"value": "18.5IN",
			"label": "18.5IN"
	},
	{
			"value": "1.85 OZ",
			"label": "1.85 OZ"
	},
	{
			"value": "18 5 OZ",
			"label": "18 5 OZ"
	},
	{
			"value": "18.5 OZ.",
			"label": "18.5 OZ."
	},
	{
			"value": "1.85. OZ",
			"label": "1.85. OZ"
	},
	{
			"value": "18.5X12.25X2.75",
			"label": "18.5X12.25X2.75"
	},
	{
			"value": "18.5X15.5X2",
			"label": "18.5X15.5X2"
	},
	{
			"value": "18.5X.5X23.5",
			"label": "18.5X.5X23.5"
	},
	{
			"value": "18.64 X 9.75",
			"label": "18.64 X 9.75"
	},
	{
			"value": "1.86 OZ",
			"label": "1.86 OZ"
	},
	{
			"value": "1872",
			"label": "1872"
	},
	{
			"value": ".1875",
			"label": ".1875"
	},
	{
			"value": "1.875",
			"label": "1.875"
	},
	{
			"value": ".1875   X 1.25   X",
			"label": ".1875   X 1.25   X"
	},
	{
			"value": ".1875   X 1.75   X",
			"label": ".1875   X 1.75   X"
	},
	{
			"value": ".1875   X 1   X 1",
			"label": ".1875   X 1   X 1"
	},
	{
			"value": ".1875   X 2   X 2.5",
			"label": ".1875   X 2   X 2.5"
	},
	{
			"value": ".1875   X 2   X 2.7",
			"label": ".1875   X 2   X 2.7"
	},
	{
			"value": ".1875   X 5   X 7",
			"label": ".1875   X 5   X 7"
	},
	{
			"value": "1.875   X 6YD",
			"label": "1.875   X 6YD"
	},
	{
			"value": ".1875   X 7.93   X",
			"label": ".1875   X 7.93   X"
	},
	{
			"value": ".1875   X 8   X 10",
			"label": ".1875   X 8   X 10"
	},
	{
			"value": "1.875 X 8YDS",
			"label": "1.875 X 8YDS"
	},
	{
			"value": "1.87OZ",
			"label": "1.87OZ"
	},
	{
			"value": ".188",
			"label": ".188"
	},
	{
			"value": "1.88IN X 10 YD.",
			"label": "1.88IN X 10 YD."
	},
	{
			"value": "1.88 OZ",
			"label": "1.88 OZ"
	},
	{
			"value": "18.8 OZ",
			"label": "18.8 OZ"
	},
	{
			"value": "1.88   WIDTH",
			"label": "1.88   WIDTH"
	},
	{
			"value": "1.88   X 10'",
			"label": "1.88   X 10'"
	},
	{
			"value": "1.88   X 10 YD",
			"label": "1.88   X 10 YD"
	},
	{
			"value": "1.88   X 10 YD.",
			"label": "1.88   X 10 YD."
	},
	{
			"value": "1.88   X 10YD",
			"label": "1.88   X 10YD"
	},
	{
			"value": "1.88   X 10YD.",
			"label": "1.88   X 10YD."
	},
	{
			"value": "1.88  X 10YD.",
			"label": "1.88  X 10YD."
	},
	{
			"value": "1.88  X10YD",
			"label": "1.88  X10YD"
	},
	{
			"value": "1.88   X 12 YD.",
			"label": "1.88   X 12 YD."
	},
	{
			"value": "1.88   X 15 YD.",
			"label": "1.88   X 15 YD."
	},
	{
			"value": "1.88   X 180",
			"label": "1.88   X 180"
	},
	{
			"value": "1.88   X  20 YD.",
			"label": "1.88   X  20 YD."
	},
	{
			"value": "1.88   X 20 YD.",
			"label": "1.88   X 20 YD."
	},
	{
			"value": "1.88 X 3.125",
			"label": "1.88 X 3.125"
	},
	{
			"value": "1.88   X 35 YD.",
			"label": "1.88   X 35 YD."
	},
	{
			"value": "1.88   X 5'",
			"label": "1.88   X 5'"
	},
	{
			"value": "1.88   X 5 YD",
			"label": "1.88   X 5 YD"
	},
	{
			"value": "1.88   X 5 YD.",
			"label": "1.88   X 5 YD."
	},
	{
			"value": "1.88   X 8 YD.",
			"label": "1.88   X 8 YD."
	},
	{
			"value": "1.88  X 8YD.",
			"label": "1.88  X 8YD."
	},
	{
			"value": "1.88   X 96",
			"label": "1.88   X 96"
	},
	{
			"value": "1.88   X 9 YD.",
			"label": "1.88   X 9 YD."
	},
	{
			"value": "18.90 X 17.70",
			"label": "18.90 X 17.70"
	},
	{
			"value": "18.9 X 10.25 X 10.25",
			"label": "18.9 X 10.25 X 10.25"
	},
	{
			"value": "18.9X1.57X37.4",
			"label": "18.9X1.57X37.4"
	},
	{
			"value": "18 CT",
			"label": "18 CT"
	},
	{
			"value": "18CT",
			"label": "18CT"
	},
	{
			"value": "18FTX2IN",
			"label": "18FTX2IN"
	},
	{
			"value": "18G",
			"label": "18G"
	},
	{
			"value": "18 GALLON",
			"label": "18 GALLON"
	},
	{
			"value": "1/8 IN",
			"label": "1/8 IN"
	},
	{
			"value": "18 IN",
			"label": "18 IN"
	},
	{
			"value": "18IN",
			"label": "18IN"
	},
	{
			"value": "18IN 2PK",
			"label": "18IN 2PK"
	},
	{
			"value": "18 INCH",
			"label": "18 INCH"
	},
	{
			"value": "18 INCH BY 21 INCH",
			"label": "18 INCH BY 21 INCH"
	},
	{
			"value": "18 INCHES",
			"label": "18 INCHES"
	},
	{
			"value": "18 INCHES IN HEIGHT",
			"label": "18 INCHES IN HEIGHT"
	},
	{
			"value": "18INX12IN",
			"label": "18INX12IN"
	},
	{
			"value": "1/8IN X 8YD",
			"label": "1/8IN X 8YD"
	},
	{
			"value": "1.8 LB",
			"label": "1.8 LB"
	},
	{
			"value": "18MM",
			"label": "18MM"
	},
	{
			"value": "18MM,28MM",
			"label": "18MM,28MM"
	},
	{
			"value": "18 MONTH",
			"label": "18 MONTH"
	},
	{
			"value": "18 MONTHS",
			"label": "18 MONTHS"
	},
	{
			"value": "18 OUNCES",
			"label": "18 OUNCES"
	},
	{
			"value": ".18 OZ",
			"label": ".18 OZ"
	},
	{
			"value": ".18OZ",
			"label": ".18OZ"
	},
	{
			"value": "1.8 OZ",
			"label": "1.8 OZ"
	},
	{
			"value": "18 OZ",
			"label": "18 OZ"
	},
	{
			"value": "18 OZ.",
			"label": "18 OZ."
	},
	{
			"value": "18OZ",
			"label": "18OZ"
	},
	{
			"value": "1.8OZ/50G",
			"label": "1.8OZ/50G"
	},
	{
			"value": "18 PC",
			"label": "18 PC"
	},
	{
			"value": "18 PIECES",
			"label": "18 PIECES"
	},
	{
			"value": "18 SPOOL",
			"label": "18 SPOOL"
	},
	{
			"value": "1 8TH INCH",
			"label": "1 8TH INCH"
	},
	{
			"value": "1/8   TO 1/2",
			"label": "1/8   TO 1/2"
	},
	{
			"value": "1/8   TO 1/2   MULT",
			"label": "1/8   TO 1/2   MULT"
	},
	{
			"value": "1/8  X10YDS",
			"label": "1/8  X10YDS"
	},
	{
			"value": "18 X 11.5 X 9.25",
			"label": "18 X 11.5 X 9.25"
	},
	{
			"value": "18X11X17",
			"label": "18X11X17"
	},
	{
			"value": "18X11X17IN",
			"label": "18X11X17IN"
	},
	{
			"value": "18   X 11   X 8",
			"label": "18   X 11   X 8"
	},
	{
			"value": "18 X 12",
			"label": "18 X 12"
	},
	{
			"value": "18X12",
			"label": "18X12"
	},
	{
			"value": "18   X 12",
			"label": "18   X 12"
	},
	{
			"value": "18X12.5",
			"label": "18X12.5"
	},
	{
			"value": "18X12.5X0.15",
			"label": "18X12.5X0.15"
	},
	{
			"value": "18X1.25X14",
			"label": "18X1.25X14"
	},
	{
			"value": "18X1.25X18",
			"label": "18X1.25X18"
	},
	{
			"value": "1/8   X 12FT",
			"label": "1/8   X 12FT"
	},
	{
			"value": "18 X 12 X 2",
			"label": "18 X 12 X 2"
	},
	{
			"value": "18 X 12 X 6 IN",
			"label": "18 X 12 X 6 IN"
	},
	{
			"value": "18   X 12   X 9.5",
			"label": "18   X 12   X 9.5"
	},
	{
			"value": "18 X 12 X 9 INCH",
			"label": "18 X 12 X 9 INCH"
	},
	{
			"value": "18X13.5X0.75",
			"label": "18X13.5X0.75"
	},
	{
			"value": "18 X 13.75 X 3.25",
			"label": "18 X 13.75 X 3.25"
	},
	{
			"value": "18 X 13 X 30. 5",
			"label": "18 X 13 X 30. 5"
	},
	{
			"value": "18 X 14 X 11.75",
			"label": "18 X 14 X 11.75"
	},
	{
			"value": "18X1.5X4.75",
			"label": "18X1.5X4.75"
	},
	{
			"value": "18X16X3",
			"label": "18X16X3"
	},
	{
			"value": "18X18",
			"label": "18X18"
	},
	{
			"value": "18   X 18",
			"label": "18   X 18"
	},
	{
			"value": "18  X18",
			"label": "18  X18"
	},
	{
			"value": "18''X18''",
			"label": "18''X18''"
	},
	{
			"value": "18X18X1",
			"label": "18X18X1"
	},
	{
			"value": "18   X 18   X 1",
			"label": "18   X 18   X 1"
	},
	{
			"value": "18 X 18 X 12",
			"label": "18 X 18 X 12"
	},
	{
			"value": "18X18X2",
			"label": "18X18X2"
	},
	{
			"value": "18   X 18   X 2",
			"label": "18   X 18   X 2"
	},
	{
			"value": "18   X 18   X 3",
			"label": "18   X 18   X 3"
	},
	{
			"value": "18X18X4",
			"label": "18X18X4"
	},
	{
			"value": "18   X 18   X 4",
			"label": "18   X 18   X 4"
	},
	{
			"value": "1/8   X 1 YARD",
			"label": "1/8   X 1 YARD"
	},
	{
			"value": "18   X 20'",
			"label": "18   X 20'"
	},
	{
			"value": "18X20X1",
			"label": "18X20X1"
	},
	{
			"value": "18X20X2",
			"label": "18X20X2"
	},
	{
			"value": "18X20X3",
			"label": "18X20X3"
	},
	{
			"value": "18 X 21",
			"label": "18 X 21"
	},
	{
			"value": "18X21",
			"label": "18X21"
	},
	{
			"value": "18   X 21",
			"label": "18   X 21"
	},
	{
			"value": "18  X21",
			"label": "18  X21"
	},
	{
			"value": "18'' X 21''",
			"label": "18'' X 21''"
	},
	{
			"value": "18''X21''",
			"label": "18''X21''"
	},
	{
			"value": "18 X 21 IN",
			"label": "18 X 21 IN"
	},
	{
			"value": "18 X 21IN",
			"label": "18 X 21IN"
	},
	{
			"value": "18''X22''",
			"label": "18''X22''"
	},
	{
			"value": "18X22.5 INCH",
			"label": "18X22.5 INCH"
	},
	{
			"value": "18X23",
			"label": "18X23"
	},
	{
			"value": "18 X 24",
			"label": "18 X 24"
	},
	{
			"value": "18X24",
			"label": "18X24"
	},
	{
			"value": "1/8  X24'",
			"label": "1/8  X24'"
	},
	{
			"value": "18   X 24",
			"label": "18   X 24"
	},
	{
			"value": "18   X 24'",
			"label": "18   X 24'"
	},
	{
			"value": "18   X 27",
			"label": "18   X 27"
	},
	{
			"value": "18  X28",
			"label": "18  X28"
	},
	{
			"value": "18  X2  X5YDS",
			"label": "18  X2  X5YDS"
	},
	{
			"value": "1/8   X 2 YARDS",
			"label": "1/8   X 2 YARDS"
	},
	{
			"value": "18X30",
			"label": "18X30"
	},
	{
			"value": "18X36",
			"label": "18X36"
	},
	{
			"value": "18  X36  X1",
			"label": "18  X36  X1"
	},
	{
			"value": "18 X 39",
			"label": "18 X 39"
	},
	{
			"value": "1/8   X 3FT",
			"label": "1/8   X 3FT"
	},
	{
			"value": "18   X 4'",
			"label": "18   X 4'"
	},
	{
			"value": "18   X 42",
			"label": "18   X 42"
	},
	{
			"value": "1/8   X 432 YDS SPO",
			"label": "1/8   X 432 YDS SPO"
	},
	{
			"value": "18X4.4",
			"label": "18X4.4"
	},
	{
			"value": "18X4.5X18",
			"label": "18X4.5X18"
	},
	{
			"value": "1/8   X 4 YARDS",
			"label": "1/8   X 4 YARDS"
	},
	{
			"value": "18X5",
			"label": "18X5"
	},
	{
			"value": "18   X 5YDS X 2",
			"label": "18   X 5YDS X 2"
	},
	{
			"value": "18X60",
			"label": "18X60"
	},
	{
			"value": "18X75",
			"label": "18X75"
	},
	{
			"value": "18X.75X5.75",
			"label": "18X.75X5.75"
	},
	{
			"value": "18   X 8'",
			"label": "18   X 8'"
	},
	{
			"value": "1.8   X 9YD",
			"label": "1.8   X 9YD"
	},
	{
			"value": "19",
			"label": "19"
	},
	{
			"value": "1900",
			"label": "1900"
	},
	{
			"value": "19000",
			"label": "19000"
	},
	{
			"value": "19.00 X 10.50",
			"label": "19.00 X 10.50"
	},
	{
			"value": "19.00 X 9.25",
			"label": "19.00 X 9.25"
	},
	{
			"value": "-1908",
			"label": "-1908"
	},
	{
			"value": "1908",
			"label": "1908"
	},
	{
			"value": "19 12 X 19 1/2",
			"label": "19 12 X 19 1/2"
	},
	{
			"value": "1 9/16\"\"",
			"label": "1 9/16\"\""
	},
	{
			"value": "1 9/16 IN.",
			"label": "1 9/16 IN."
	},
	{
			"value": "192",
			"label": "192"
	},
	{
			"value": "19.25",
			"label": "19.25"
	},
	{
			"value": "19.25 X 11.87",
			"label": "19.25 X 11.87"
	},
	{
			"value": "19.25 X 8.75 X .5",
			"label": "19.25 X 8.75 X .5"
	},
	{
			"value": "1929",
			"label": "1929"
	},
	{
			"value": "19 3/4 X 19 3/4",
			"label": "19 3/4 X 19 3/4"
	},
	{
			"value": "19.36 OZ",
			"label": "19.36 OZ"
	},
	{
			"value": "1.93 OZ",
			"label": "1.93 OZ"
	},
	{
			"value": "1.94OZ",
			"label": "1.94OZ"
	},
	{
			"value": "1950",
			"label": "1950"
	},
	{
			"value": "1956",
			"label": "1956"
	},
	{
			"value": "19.5 IN",
			"label": "19.5 IN"
	},
	{
			"value": "19.5IN",
			"label": "19.5IN"
	},
	{
			"value": "19.5 INCH",
			"label": "19.5 INCH"
	},
	{
			"value": "19.5  X14.25",
			"label": "19.5  X14.25"
	},
	{
			"value": "19.5 X 15.5 X 10.5",
			"label": "19.5 X 15.5 X 10.5"
	},
	{
			"value": "1968",
			"label": "1968"
	},
	{
			"value": "19.69X15.35X13.78",
			"label": "19.69X15.35X13.78"
	},
	{
			"value": "19.69 X 7.87 X 2.36",
			"label": "19.69 X 7.87 X 2.36"
	},
	{
			"value": "19.6X12.2X9.45",
			"label": "19.6X12.2X9.45"
	},
	{
			"value": "19.7",
			"label": "19.7"
	},
	{
			"value": "1970",
			"label": "1970"
	},
	{
			"value": "19 7/8 X 25 7/8",
			"label": "19 7/8 X 25 7/8"
	},
	{
			"value": "19.7 IN",
			"label": "19.7 IN"
	},
	{
			"value": "1.97 OZ",
			"label": "1.97 OZ"
	},
	{
			"value": "1980",
			"label": "1980"
	},
	{
			"value": "19.88X14.37X2.86",
			"label": "19.88X14.37X2.86"
	},
	{
			"value": "198G",
			"label": "198G"
	},
	{
			"value": "198 GR",
			"label": "198 GR"
	},
	{
			"value": "198GR",
			"label": "198GR"
	},
	{
			"value": "198 GRAMS",
			"label": "198 GRAMS"
	},
	{
			"value": "19.92X1.18X14.57",
			"label": "19.92X1.18X14.57"
	},
	{
			"value": "19.99",
			"label": "19.99"
	},
	{
			"value": "19CT",
			"label": "19CT"
	},
	{
			"value": "1.9 FL. OZ.",
			"label": "1.9 FL. OZ."
	},
	{
			"value": "19G",
			"label": "19G"
	},
	{
			"value": "19 IN",
			"label": "19 IN"
	},
	{
			"value": "19IN",
			"label": "19IN"
	},
	{
			"value": "19 INCH",
			"label": "19 INCH"
	},
	{
			"value": "19 INCHES IN HEIGHT",
			"label": "19 INCHES IN HEIGHT"
	},
	{
			"value": "19 INCH IN HEIGHT",
			"label": "19 INCH IN HEIGHT"
	},
	{
			"value": "19 IN H",
			"label": "19 IN H"
	},
	{
			"value": "19 IN X 20 YD",
			"label": "19 IN X 20 YD"
	},
	{
			"value": "19MM",
			"label": "19MM"
	},
	{
			"value": "1.9 OZ",
			"label": "1.9 OZ"
	},
	{
			"value": "19 OZ",
			"label": "19 OZ"
	},
	{
			"value": "19OZ",
			"label": "19OZ"
	},
	{
			"value": "1.9OZ",
			"label": "1.9OZ"
	},
	{
			"value": "19 QUART",
			"label": "19 QUART"
	},
	{
			"value": "19X11X16",
			"label": "19X11X16"
	},
	{
			"value": "19X1.25X19",
			"label": "19X1.25X19"
	},
	{
			"value": "19X12X14",
			"label": "19X12X14"
	},
	{
			"value": "19X12X14IN",
			"label": "19X12X14IN"
	},
	{
			"value": "19 X 13 X 3.5",
			"label": "19 X 13 X 3.5"
	},
	{
			"value": "19X14",
			"label": "19X14"
	},
	{
			"value": "19X15X6",
			"label": "19X15X6"
	},
	{
			"value": "19   X 1 YD PACKAGE",
			"label": "19   X 1 YD PACKAGE"
	},
	{
			"value": " 19 X 24 OPEN, 13 X",
			"label": " 19 X 24 OPEN, 13 X"
	},
	{
			"value": "19 X 25",
			"label": "19 X 25"
	},
	{
			"value": "19   X 25",
			"label": "19   X 25"
	},
	{
			"value": "19   X 28",
			"label": "19   X 28"
	},
	{
			"value": "1.9 X 3.6 X 20.7",
			"label": "1.9 X 3.6 X 20.7"
	},
	{
			"value": "1.9 X 3.7 X 16.7",
			"label": "1.9 X 3.7 X 16.7"
	},
	{
			"value": "1.9 X 4.2 X 16.7",
			"label": "1.9 X 4.2 X 16.7"
	},
	{
			"value": "19   X 8 YDS",
			"label": "19   X 8 YDS"
	},
	{
			"value": "1 ACCENT",
			"label": "1 ACCENT"
	},
	{
			"value": "1 AND 1.50 IN. X 12",
			"label": "1 AND 1.50 IN. X 12"
	},
	{
			"value": "1 BAG - 1.76OZ",
			"label": "1 BAG - 1.76OZ"
	},
	{
			"value": "1 BLOCK",
			"label": "1 BLOCK"
	},
	{
			"value": "1 BOTTLE - 2 FL OZ",
			"label": "1 BOTTLE - 2 FL OZ"
	},
	{
			"value": "1 BOTTLE - 4OZ",
			"label": "1 BOTTLE - 4OZ"
	},
	{
			"value": "1 COUNT",
			"label": "1 COUNT"
	},
	{
			"value": "1 CT",
			"label": "1 CT"
	},
	{
			"value": "1CT",
			"label": "1CT"
	},
	{
			"value": "1 EA",
			"label": "1 EA"
	},
	{
			"value": "1EA",
			"label": "1EA"
	},
	{
			"value": "1 EACH",
			"label": "1 EACH"
	},
	{
			"value": "1 FL OZ",
			"label": "1 FL OZ"
	},
	{
			"value": "1 FL. OZ.",
			"label": "1 FL. OZ."
	},
	{
			"value": "1G",
			"label": "1G"
	},
	{
			"value": "1 GALLON",
			"label": "1 GALLON"
	},
	{
			"value": "1HALF",
			"label": "1HALF"
	},
	{
			"value": "1HALF INCH",
			"label": "1HALF INCH"
	},
	{
			"value": "1 HALF X 12FT",
			"label": "1 HALF X 12FT"
	},
	{
			"value": "1HALF X 12 FT",
			"label": "1HALF X 12 FT"
	},
	{
			"value": "1HALF X 12FT",
			"label": "1HALF X 12FT"
	},
	{
			"value": "1 IN",
			"label": "1 IN"
	},
	{
			"value": "1 IN.",
			"label": "1 IN."
	},
	{
			"value": "1IN",
			"label": "1IN"
	},
	{
			"value": "1IN (25 MM)",
			"label": "1IN (25 MM)"
	},
	{
			"value": "1 INCH",
			"label": "1 INCH"
	},
	{
			"value": "1 INCH W X 5.5 INCH",
			"label": "1 INCH W X 5.5 INCH"
	},
	{
			"value": "1 INCH X 12 YARDS",
			"label": "1 INCH X 12 YARDS"
	},
	{
			"value": "1 INCH X 3FT",
			"label": "1 INCH X 3FT"
	},
	{
			"value": "1 INCH X 4FT",
			"label": "1 INCH X 4FT"
	},
	{
			"value": "1 INTE",
			"label": "1 INTE"
	},
	{
			"value": "1IN X 3 IN",
			"label": "1IN X 3 IN"
	},
	{
			"value": "1 IN X 3 YDS",
			"label": "1 IN X 3 YDS"
	},
	{
			"value": "1 IN X 4 FT ROLL",
			"label": "1 IN X 4 FT ROLL"
	},
	{
			"value": "1IN X 50YD",
			"label": "1IN X 50YD"
	},
	{
			"value": "1IN X 5YDS",
			"label": "1IN X 5YDS"
	},
	{
			"value": "1IN X 60 IN",
			"label": "1IN X 60 IN"
	},
	{
			"value": "1 IN X 700IN",
			"label": "1 IN X 700IN"
	},
	{
			"value": "1 LB",
			"label": "1 LB"
	},
	{
			"value": "1 LB.",
			"label": "1 LB."
	},
	{
			"value": "1LB",
			"label": "1LB"
	},
	{
			"value": "1LB, 1.5 AND 2LB OP",
			"label": "1LB, 1.5 AND 2LB OP"
	},
	{
			"value": "1 LITER",
			"label": "1 LITER"
	},
	{
			"value": "1  L X 5W   X 5H",
			"label": "1  L X 5W   X 5H"
	},
	{
			"value": "1  L X 5   X 5   SQ",
			"label": "1  L X 5   X 5   SQ"
	},
	{
			"value": "1  L X 8 W X 8  H R",
			"label": "1  L X 8 W X 8  H R"
	},
	{
			"value": "1 MM",
			"label": "1 MM"
	},
	{
			"value": "1MR ULTFN",
			"label": "1MR ULTFN"
	},
	{
			"value": "1M XFN",
			"label": "1M XFN"
	},
	{
			"value": "1 OZ",
			"label": "1 OZ"
	},
	{
			"value": "1 OZ.",
			"label": "1 OZ."
	},
	{
			"value": ".1OZ",
			"label": ".1OZ"
	},
	{
			"value": "1OZ",
			"label": "1OZ"
	},
	{
			"value": "1 OZ TUBE",
			"label": "1 OZ TUBE"
	},
	{
			"value": "1 OZ TUBES (3 ASSORT",
			"label": "1 OZ TUBES (3 ASSORT"
	},
	{
			"value": "1 PACK",
			"label": "1 PACK"
	},
	{
			"value": "1 PAIR",
			"label": "1 PAIR"
	},
	{
			"value": "1PC",
			"label": "1PC"
	},
	{
			"value": "1 PCS - 36  X42",
			"label": "1 PCS - 36  X42"
	},
	{
			"value": "1 PIECE",
			"label": "1 PIECE"
	},
	{
			"value": "1 PIECES",
			"label": "1 PIECES"
	},
	{
			"value": "1PK",
			"label": "1PK"
	},
	{
			"value": "1 POUND",
			"label": "1 POUND"
	},
	{
			"value": "1 QT",
			"label": "1 QT"
	},
	{
			"value": "1 SET",
			"label": "1 SET"
	},
	{
			"value": "1ST CURATION",
			"label": "1ST CURATION"
	},
	{
			"value": "1 - SUPERFINE",
			"label": "1 - SUPERFINE"
	},
	{
			"value": "1   W X 4   L X .5",
			"label": "1   W X 4   L X .5"
	},
	{
			"value": "1   X 10 YD.",
			"label": "1   X 10 YD."
	},
	{
			"value": "1   X 10YDS",
			"label": "1   X 10YDS"
	},
	{
			"value": "1  X10YDS",
			"label": "1  X10YDS"
	},
	{
			"value": "1   X 12YDS",
			"label": "1   X 12YDS"
	},
	{
			"value": "1  X15'",
			"label": "1  X15'"
	},
	{
			"value": "1X15X17",
			"label": "1X15X17"
	},
	{
			"value": "1   X 15YDS",
			"label": "1   X 15YDS"
	},
	{
			"value": "1 X 1 X 0.5 INCH 0.2",
			"label": "1 X 1 X 0.5 INCH 0.2"
	},
	{
			"value": "1 X .1 X 4.75",
			"label": "1 X .1 X 4.75"
	},
	{
			"value": "1X24X72",
			"label": "1X24X72"
	},
	{
			"value": "1X26X10",
			"label": "1X26X10"
	},
	{
			"value": "1 X 2 X 4.5",
			"label": "1 X 2 X 4.5"
	},
	{
			"value": "1 X 2 X 5",
			"label": "1 X 2 X 5"
	},
	{
			"value": "1   X 2 YARDS",
			"label": "1   X 2 YARDS"
	},
	{
			"value": "1   X 3FT",
			"label": "1   X 3FT"
	},
	{
			"value": "1   X 3 YARDS",
			"label": "1   X 3 YARDS"
	},
	{
			"value": "1   X 4",
			"label": "1   X 4"
	},
	{
			"value": "1 X 4.75 X 5.75",
			"label": "1 X 4.75 X 5.75"
	},
	{
			"value": "1 X 5.4 X 9.8",
			"label": "1 X 5.4 X 9.8"
	},
	{
			"value": "1   X 60",
			"label": "1   X 60"
	},
	{
			"value": "1   X 6FT",
			"label": "1   X 6FT"
	},
	{
			"value": "1   X 6YD",
			"label": "1   X 6YD"
	},
	{
			"value": "1   X 6YDS",
			"label": "1   X 6YDS"
	},
	{
			"value": "1 X 8 X .25",
			"label": "1 X 8 X .25"
	},
	{
			"value": "1 YD",
			"label": "1 YD"
	},
	{
			"value": "1YD",
			"label": "1YD"
	},
	{
			"value": "2",
			"label": "2"
	},
	{
			"value": "2\"\"",
			"label": "2\"\""
	},
	{
			"value": "2'",
			"label": "2'"
	},
	{
			"value": "20",
			"label": "20"
	},
	{
			"value": "20\"\"",
			"label": "20\"\""
	},
	{
			"value": "200",
			"label": "200"
	},
	{
			"value": "2000",
			"label": "2000"
	},
	{
			"value": "20000",
			"label": "20000"
	},
	{
			"value": "20.00 X 13.80",
			"label": "20.00 X 13.80"
	},
	{
			"value": "20.00 X 16.00",
			"label": "20.00 X 16.00"
	},
	{
			"value": "20.00 X 17.00",
			"label": "20.00 X 17.00"
	},
	{
			"value": "20.00 X 20.00",
			"label": "20.00 X 20.00"
	},
	{
			"value": "2004",
			"label": "2004"
	},
	{
			"value": "200CT",
			"label": "200CT"
	},
	{
			"value": "200FT",
			"label": "200FT"
	},
	{
			"value": "200G",
			"label": "200G"
	},
	{
			"value": "200GR",
			"label": "200GR"
	},
	{
			"value": "200 GRAMS / 7OZ",
			"label": "200 GRAMS / 7OZ"
	},
	{
			"value": "2.00L X 11.75H IN",
			"label": "2.00L X 11.75H IN"
	},
	{
			"value": "200ML",
			"label": "200ML"
	},
	{
			"value": "200 MM",
			"label": "200 MM"
	},
	{
			"value": "200MM",
			"label": "200MM"
	},
	{
			"value": "2.00 OZ",
			"label": "2.00 OZ"
	},
	{
			"value": "200SERIES 11X14",
			"label": "200SERIES 11X14"
	},
	{
			"value": "200SERIES 11X15",
			"label": "200SERIES 11X15"
	},
	{
			"value": "200SERIES 5X8",
			"label": "200SERIES 5X8"
	},
	{
			"value": "200SERIES 8X11",
			"label": "200SERIES 8X11"
	},
	{
			"value": "200SERIES 9X12",
			"label": "200SERIES 9X12"
	},
	{
			"value": "200   X 110",
			"label": "200   X 110"
	},
	{
			"value": "200 X 15 X 21",
			"label": "200 X 15 X 21"
	},
	{
			"value": "2.00 X 1.75",
			"label": "2.00 X 1.75"
	},
	{
			"value": "200 X 18",
			"label": "200 X 18"
	},
	{
			"value": "200   X 63",
			"label": "200   X 63"
	},
	{
			"value": "200   X 84",
			"label": "200   X 84"
	},
	{
			"value": "200   X 96",
			"label": "200   X 96"
	},
	{
			"value": "200 YDS",
			"label": "200 YDS"
	},
	{
			"value": "20 1/2 X 20 1/2",
			"label": "20 1/2 X 20 1/2"
	},
	{
			"value": "-2016",
			"label": "-2016"
	},
	{
			"value": "2016",
			"label": "2016"
	},
	{
			"value": "2.01 OZ",
			"label": "2.01 OZ"
	},
	{
			"value": "20.1QT",
			"label": "20.1QT"
	},
	{
			"value": "20   1 YD PACKAGE",
			"label": "20   1 YD PACKAGE"
	},
	{
			"value": "202",
			"label": "202"
	},
	{
			"value": "20-22IN",
			"label": "20-22IN"
	},
	{
			"value": "20.25 X 17.25",
			"label": "20.25 X 17.25"
	},
	{
			"value": "20 - 2.5   X 42",
			"label": "20 - 2.5   X 42"
	},
	{
			"value": "20.29QT",
			"label": "20.29QT"
	},
	{
			"value": "2.02 OZ",
			"label": "2.02 OZ"
	},
	{
			"value": "20.2X0.8X8.6",
			"label": "20.2X0.8X8.6"
	},
	{
			"value": "20300",
			"label": "20300"
	},
	{
			"value": "20.37 X 20.25",
			"label": "20.37 X 20.25"
	},
	{
			"value": "203MM",
			"label": "203MM"
	},
	{
			"value": "2.047IN X 4YD",
			"label": "2.047IN X 4YD"
	},
	{
			"value": "20.5",
			"label": "20.5"
	},
	{
			"value": "2050",
			"label": "2050"
	},
	{
			"value": "20 5/8 X 17 5/8",
			"label": "20 5/8 X 17 5/8"
	},
	{
			"value": "20 5/8 X 20 5/8",
			"label": "20 5/8 X 20 5/8"
	},
	{
			"value": "205FT",
			"label": "205FT"
	},
	{
			"value": "20.5 IN",
			"label": "20.5 IN"
	},
	{
			"value": "20.5IN",
			"label": "20.5IN"
	},
	{
			"value": "20.5X8",
			"label": "20.5X8"
	},
	{
			"value": "20.67X5.12X18.11",
			"label": "20.67X5.12X18.11"
	},
	{
			"value": "20.75   X 16   X 13",
			"label": "20.75   X 16   X 13"
	},
	{
			"value": "20.75 X 2.00",
			"label": "20.75 X 2.00"
	},
	{
			"value": "20.75X20.5X4.25",
			"label": "20.75X20.5X4.25"
	},
	{
			"value": "-2076",
			"label": "-2076"
	},
	{
			"value": "2.07 OZ",
			"label": "2.07 OZ"
	},
	{
			"value": "20.875X8.125X1.9",
			"label": "20.875X8.125X1.9"
	},
	{
			"value": "20.8X14.3X.25",
			"label": "20.8X14.3X.25"
	},
	{
			"value": "20 CM",
			"label": "20 CM"
	},
	{
			"value": "20CT",
			"label": "20CT"
	},
	{
			"value": "2.0 FL OZ",
			"label": "2.0 FL OZ"
	},
	{
			"value": "2.0 FL. OZ.",
			"label": "2.0 FL. OZ."
	},
	{
			"value": "20G",
			"label": "20G"
	},
	{
			"value": "20 GALLON",
			"label": "20 GALLON"
	},
	{
			"value": "20GM",
			"label": "20GM"
	},
	{
			"value": "2.0 IN",
			"label": "2.0 IN"
	},
	{
			"value": "20 IN",
			"label": "20 IN"
	},
	{
			"value": "20IN",
			"label": "20IN"
	},
	{
			"value": "20 INCH",
			"label": "20 INCH"
	},
	{
			"value": "20LB",
			"label": "20LB"
	},
	{
			"value": "20LB-100LB",
			"label": "20LB-100LB"
	},
	{
			"value": "20 LITER",
			"label": "20 LITER"
	},
	{
			"value": "20   L X 19   WIDE",
			"label": "20   L X 19   WIDE"
	},
	{
			"value": "20MM",
			"label": "20MM"
	},
	{
			"value": "20-OUNCE",
			"label": "20-OUNCE"
	},
	{
			"value": "20-OUNCES",
			"label": "20-OUNCES"
	},
	{
			"value": "20 OZ",
			"label": "20 OZ"
	},
	{
			"value": "20 OZ.",
			"label": "20 OZ."
	},
	{
			"value": "20OZ",
			"label": "20OZ"
	},
	{
			"value": "20 PC",
			"label": "20 PC"
	},
	{
			"value": "20PC",
			"label": "20PC"
	},
	{
			"value": "20 PCS - 10  X10",
			"label": "20 PCS - 10  X10"
	},
	{
			"value": "20 PCS - 2.5  X3.75",
			"label": "20 PCS - 2.5  X3.75"
	},
	{
			"value": "20   WIDE",
			"label": "20   WIDE"
	},
	{
			"value": "20   WIDTH X 30 YDS",
			"label": "20   WIDTH X 30 YDS"
	},
	{
			"value": "20   WIDTH X 35YDS",
			"label": "20   WIDTH X 35YDS"
	},
	{
			"value": "20 WIPES - 7   X 5.",
			"label": "20 WIPES - 7   X 5."
	},
	{
			"value": "20X0.85X20",
			"label": "20X0.85X20"
	},
	{
			"value": "20X0.98X16.06",
			"label": "20X0.98X16.06"
	},
	{
			"value": "20X10",
			"label": "20X10"
	},
	{
			"value": "20   X 10 YARDS BOL",
			"label": "20   X 10 YARDS BOL"
	},
	{
			"value": "20   X 10 YDS",
			"label": "20   X 10 YDS"
	},
	{
			"value": "20  X110",
			"label": "20  X110"
	},
	{
			"value": "20 X 12",
			"label": "20 X 12"
	},
	{
			"value": "20X12",
			"label": "20X12"
	},
	{
			"value": "20   X 12   X 2",
			"label": "20   X 12   X 2"
	},
	{
			"value": "20   X 12 YARDS BOL",
			"label": "20   X 12 YARDS BOL"
	},
	{
			"value": "20 X 13.5 X 9.25",
			"label": "20 X 13.5 X 9.25"
	},
	{
			"value": "20 X 14",
			"label": "20 X 14"
	},
	{
			"value": "20 X 14 X 4",
			"label": "20 X 14 X 4"
	},
	{
			"value": "20 X 15",
			"label": "20 X 15"
	},
	{
			"value": "20X15",
			"label": "20X15"
	},
	{
			"value": "20X16",
			"label": "20X16"
	},
	{
			"value": "20   X 16",
			"label": "20   X 16"
	},
	{
			"value": "20X1.75X20",
			"label": "20X1.75X20"
	},
	{
			"value": "20X19",
			"label": "20X19"
	},
	{
			"value": "20X1X10.5",
			"label": "20X1X10.5"
	},
	{
			"value": "20X1X7",
			"label": "20X1X7"
	},
	{
			"value": "20   X 1 YD FOLDED",
			"label": "20   X 1 YD FOLDED"
	},
	{
			"value": "20   X 1 YD PACKAGE",
			"label": "20   X 1 YD PACKAGE"
	},
	{
			"value": "20 X 20",
			"label": "20 X 20"
	},
	{
			"value": "20X20",
			"label": "20X20"
	},
	{
			"value": "20   X 20",
			"label": "20   X 20"
	},
	{
			"value": "20X20 INCH",
			"label": "20X20 INCH"
	},
	{
			"value": "20X20 MATTED TO 12X1",
			"label": "20X20 MATTED TO 12X1"
	},
	{
			"value": "20   X 20 YDS",
			"label": "20   X 20 YDS"
	},
	{
			"value": "20   X 20 YDS BOLT",
			"label": "20   X 20 YDS BOLT"
	},
	{
			"value": "20X24",
			"label": "20X24"
	},
	{
			"value": "20 X 25",
			"label": "20 X 25"
	},
	{
			"value": "20X26",
			"label": "20X26"
	},
	{
			"value": "20  X26",
			"label": "20  X26"
	},
	{
			"value": "20X28",
			"label": "20X28"
	},
	{
			"value": "20   X 28   QUEEN S",
			"label": "20   X 28   QUEEN S"
	},
	{
			"value": "20   X 2 YD PACKAGE",
			"label": "20   X 2 YD PACKAGE"
	},
	{
			"value": "20   X 2 YD ROLLED",
			"label": "20   X 2 YD ROLLED"
	},
	{
			"value": "20 X 30",
			"label": "20 X 30"
	},
	{
			"value": "20X30",
			"label": "20X30"
	},
	{
			"value": "20   X 30 YD BOLT",
			"label": "20   X 30 YD BOLT"
	},
	{
			"value": "20X3.25X10.5",
			"label": "20X3.25X10.5"
	},
	{
			"value": "20X34",
			"label": "20X34"
	},
	{
			"value": "20X35",
			"label": "20X35"
	},
	{
			"value": "20   X 35 YDS BOLT",
			"label": "20   X 35 YDS BOLT"
	},
	{
			"value": "20  X36",
			"label": "20  X36"
	},
	{
			"value": "20   X 4'",
			"label": "20   X 4'"
	},
	{
			"value": "20' X 4'",
			"label": "20' X 4'"
	},
	{
			"value": "20   X 40 YD BOLT",
			"label": "20   X 40 YD BOLT"
	},
	{
			"value": "20   X 40YDS",
			"label": "20   X 40YDS"
	},
	{
			"value": "20   X 40 YDS BOLT",
			"label": "20   X 40 YDS BOLT"
	},
	{
			"value": "20   X 54   PACKAGE",
			"label": "20   X 54   PACKAGE"
	},
	{
			"value": "20   X 6",
			"label": "20   X 6"
	},
	{
			"value": "20   X 6 YARDS BOLT",
			"label": "20   X 6 YARDS BOLT"
	},
	{
			"value": "20X8",
			"label": "20X8"
	},
	{
			"value": "20   X 8   X .5",
			"label": "20   X 8   X .5"
	},
	{
			"value": "20  X96",
			"label": "20  X96"
	},
	{
			"value": "20YD",
			"label": "20YD"
	},
	{
			"value": "20YDS",
			"label": "20YDS"
	},
	{
			"value": "2.1",
			"label": "2.1"
	},
	{
			"value": "21",
			"label": "21"
	},
	{
			"value": "21\"\"",
			"label": "21\"\""
	},
	{
			"value": "2100",
			"label": "2100"
	},
	{
			"value": "21000",
			"label": "21000"
	},
	{
			"value": "2.10 OZ",
			"label": "2.10 OZ"
	},
	{
			"value": "2-11/16''",
			"label": "2-11/16''"
	},
	{
			"value": "2 11/16 INCHES",
			"label": "2 11/16 INCHES"
	},
	{
			"value": "21 1/2 X 21 1/2",
			"label": "21 1/2 X 21 1/2"
	},
	{
			"value": "21.13QT",
			"label": "21.13QT"
	},
	{
			"value": "21.16 OZ",
			"label": "21.16 OZ"
	},
	{
			"value": "2.11 OZ",
			"label": "2.11 OZ"
	},
	{
			"value": "21.1 OZ",
			"label": "21.1 OZ"
	},
	{
			"value": "2.11QT",
			"label": "2.11QT"
	},
	{
			"value": "2 1/2",
			"label": "2 1/2"
	},
	{
			"value": "2 1/2\"\"",
			"label": "2 1/2\"\""
	},
	{
			"value": "2 1/2''",
			"label": "2 1/2''"
	},
	{
			"value": "2-1/2",
			"label": "2-1/2"
	},
	{
			"value": "21/22",
			"label": "21/22"
	},
	{
			"value": "2.125",
			"label": "2.125"
	},
	{
			"value": "21.25IN",
			"label": "21.25IN"
	},
	{
			"value": "2.125 X 2.125",
			"label": "2.125 X 2.125"
	},
	{
			"value": "2 1/2   (65MM)",
			"label": "2 1/2   (65MM)"
	},
	{
			"value": "2 1/2 (65MM)",
			"label": "2 1/2 (65MM)"
	},
	{
			"value": "2 1/2 IN.",
			"label": "2 1/2 IN."
	},
	{
			"value": "2 1/2 IN (50MM)",
			"label": "2 1/2 IN (50MM)"
	},
	{
			"value": "2 1/2IN X 50YD",
			"label": "2 1/2IN X 50YD"
	},
	{
			"value": "2.12 OZ",
			"label": "2.12 OZ"
	},
	{
			"value": "2 1/2   X 25YD",
			"label": "2 1/2   X 25YD"
	},
	{
			"value": "2 1/2   X 3FT",
			"label": "2 1/2   X 3FT"
	},
	{
			"value": "2 1/2   X3FT",
			"label": "2 1/2   X3FT"
	},
	{
			"value": "2 1/2 X 5YDS",
			"label": "2 1/2 X 5YDS"
	},
	{
			"value": "2 1/2     X 5YDS",
			"label": "2 1/2     X 5YDS"
	},
	{
			"value": "2 1/2    X 5YDS",
			"label": "2 1/2    X 5YDS"
	},
	{
			"value": "2 1/2   X 5YDS",
			"label": "2 1/2   X 5YDS"
	},
	{
			"value": "2-1/2 YD",
			"label": "2-1/2 YD"
	},
	{
			"value": "21 3/4 X 27 3/4",
			"label": "21 3/4 X 27 3/4"
	},
	{
			"value": "21 3/8 X 25 3/8",
			"label": "21 3/8 X 25 3/8"
	},
	{
			"value": "2  1/4",
			"label": "2  1/4"
	},
	{
			"value": "2 1/4",
			"label": "2 1/4"
	},
	{
			"value": "2 1/4\"\"",
			"label": "2 1/4\"\""
	},
	{
			"value": "2-1/4",
			"label": "2-1/4"
	},
	{
			"value": "21-40",
			"label": "21-40"
	},
	{
			"value": "2 1/4   (57MM)",
			"label": "2 1/4   (57MM)"
	},
	{
			"value": "2 1/4 IN",
			"label": "2 1/4 IN"
	},
	{
			"value": "2 1/4 IN.",
			"label": "2 1/4 IN."
	},
	{
			"value": "2 1/4 (IN) X 1 1/4 (",
			"label": "2 1/4 (IN) X 1 1/4 ("
	},
	{
			"value": "2 1/4 (IN) X 2 1/4 (",
			"label": "2 1/4 (IN) X 2 1/4 ("
	},
	{
			"value": "2 1/4 (IN) X 3 1/2 (",
			"label": "2 1/4 (IN) X 3 1/2 ("
	},
	{
			"value": "2 1/4   X 10YDS",
			"label": "2 1/4   X 10YDS"
	},
	{
			"value": "2 1/4   X 12'",
			"label": "2 1/4   X 12'"
	},
	{
			"value": "2 1/4  X 6YDS",
			"label": "2 1/4  X 6YDS"
	},
	{
			"value": "21.5",
			"label": "21.5"
	},
	{
			"value": "2150",
			"label": "2150"
	},
	{
			"value": "21.50 X 8.62",
			"label": "21.50 X 8.62"
	},
	{
			"value": "21.5IN",
			"label": "21.5IN"
	},
	{
			"value": "21.5 QT",
			"label": "21.5 QT"
	},
	{
			"value": "2.15   X 3",
			"label": "2.15   X 3"
	},
	{
			"value": "2.15   X 3.62",
			"label": "2.15   X 3.62"
	},
	{
			"value": "21.5 X 4.5 X 2",
			"label": "21.5 X 4.5 X 2"
	},
	{
			"value": "21.5 X .5 X 38",
			"label": "21.5 X .5 X 38"
	},
	{
			"value": "2160",
			"label": "2160"
	},
	{
			"value": "21600",
			"label": "21600"
	},
	{
			"value": "21.65X1.7X11.8",
			"label": "21.65X1.7X11.8"
	},
	{
			"value": "2166",
			"label": "2166"
	},
	{
			"value": "2167",
			"label": "2167"
	},
	{
			"value": "2172",
			"label": "2172"
	},
	{
			"value": "21.75 X 28",
			"label": "21.75 X 28"
	},
	{
			"value": "21 7/8 X 16 5/8",
			"label": "21 7/8 X 16 5/8"
	},
	{
			"value": "2.17 OZ",
			"label": "2.17 OZ"
	},
	{
			"value": "21.7 X 15 X 47",
			"label": "21.7 X 15 X 47"
	},
	{
			"value": "2 1/8",
			"label": "2 1/8"
	},
	{
			"value": "2 1/8\"\"",
			"label": "2 1/8\"\""
	},
	{
			"value": "2-1/8",
			"label": "2-1/8"
	},
	{
			"value": "2184",
			"label": "2184"
	},
	{
			"value": "21.85X20.87",
			"label": "21.85X20.87"
	},
	{
			"value": "2 1/8 IN",
			"label": "2 1/8 IN"
	},
	{
			"value": "2 1/8   X 3FT",
			"label": "2 1/8   X 3FT"
	},
	{
			"value": "2 1/8  X 6YDS",
			"label": "2 1/8  X 6YDS"
	},
	{
			"value": "2.19 X 5.50 X 7.50",
			"label": "2.19 X 5.50 X 7.50"
	},
	{
			"value": "21CT",
			"label": "21CT"
	},
	{
			"value": "21 FT HANK",
			"label": "21 FT HANK"
	},
	{
			"value": "21G",
			"label": "21G"
	},
	{
			"value": "21 IN",
			"label": "21 IN"
	},
	{
			"value": "21IN",
			"label": "21IN"
	},
	{
			"value": "21 INCH",
			"label": "21 INCH"
	},
	{
			"value": "21 LOOPS X 11-3/4",
			"label": "21 LOOPS X 11-3/4"
	},
	{
			"value": "21 OUNCE",
			"label": "21 OUNCE"
	},
	{
			"value": "2.1 OZ",
			"label": "2.1 OZ"
	},
	{
			"value": "21 OZ",
			"label": "21 OZ"
	},
	{
			"value": "2.1OZ",
			"label": "2.1OZ"
	},
	{
			"value": "2.1 OZ/ 60GR",
			"label": "2.1 OZ/ 60GR"
	},
	{
			"value": "21 PIECES",
			"label": "21 PIECES"
	},
	{
			"value": "21 POINT 5 INCH",
			"label": "21 POINT 5 INCH"
	},
	{
			"value": "2.1 QUART",
			"label": "2.1 QUART"
	},
	{
			"value": "2.1 X 1.1 X 8",
			"label": "2.1 X 1.1 X 8"
	},
	{
			"value": "21X12",
			"label": "21X12"
	},
	{
			"value": "21   X 21",
			"label": "21   X 21"
	},
	{
			"value": "2.1X2.1X1.6",
			"label": "2.1X2.1X1.6"
	},
	{
			"value": "2.1 X 2.1 X 2.1",
			"label": "2.1 X 2.1 X 2.1"
	},
	{
			"value": "21X29X4",
			"label": "21X29X4"
	},
	{
			"value": "21X33",
			"label": "21X33"
	},
	{
			"value": "21X37",
			"label": "21X37"
	},
	{
			"value": "21X48",
			"label": "21X48"
	},
	{
			"value": "2.1YD",
			"label": "2.1YD"
	},
	{
			"value": "2.2",
			"label": "2.2"
	},
	{
			"value": "22",
			"label": "22"
	},
	{
			"value": "220",
			"label": "220"
	},
	{
			"value": "2200",
			"label": "2200"
	},
	{
			"value": "22000",
			"label": "22000"
	},
	{
			"value": "22.00 X 13.40",
			"label": "22.00 X 13.40"
	},
	{
			"value": "22.00 X 17.00",
			"label": "22.00 X 17.00"
	},
	{
			"value": "22.00 X 18.50",
			"label": "22.00 X 18.50"
	},
	{
			"value": "22.00 X 2.50",
			"label": "22.00 X 2.50"
	},
	{
			"value": "22.05   X 15.36   X",
			"label": "22.05   X 15.36   X"
	},
	{
			"value": "220GR",
			"label": "220GR"
	},
	{
			"value": " 22,10",
			"label": " 22,10"
	},
	{
			"value": "22 1/2 X 22 1/2",
			"label": "22 1/2 X 22 1/2"
	},
	{
			"value": "22.25 X 18.00",
			"label": "22.25 X 18.00"
	},
	{
			"value": "22-26MM",
			"label": "22-26MM"
	},
	{
			"value": "2 2/34 INCHES",
			"label": "2 2/34 INCHES"
	},
	{
			"value": "22.375X34",
			"label": "22.375X34"
	},
	{
			"value": "2.23 PB",
			"label": "2.23 PB"
	},
	{
			"value": "224",
			"label": "224"
	},
	{
			"value": "2240",
			"label": "2240"
	},
	{
			"value": "22496",
			"label": "22496"
	},
	{
			"value": ".225",
			"label": ".225"
	},
	{
			"value": "2.25",
			"label": "2.25"
	},
	{
			"value": "22.5",
			"label": "22.5"
	},
	{
			"value": "225",
			"label": "225"
	},
	{
			"value": "2250",
			"label": "2250"
	},
	{
			"value": "2.25IN",
			"label": "2.25IN"
	},
	{
			"value": "2.25 INCH X 3 FT",
			"label": "2.25 INCH X 3 FT"
	},
	{
			"value": "2.25 INCH X 3FT",
			"label": "2.25 INCH X 3FT"
	},
	{
			"value": "2.25 INCH X 6 YARDS",
			"label": "2.25 INCH X 6 YARDS"
	},
	{
			"value": "22.5IN(H)",
			"label": "22.5IN(H)"
	},
	{
			"value": ".2.25 OZ",
			"label": ".2.25 OZ"
	},
	{
			"value": "2.25 OZ",
			"label": "2.25 OZ"
	},
	{
			"value": "2.25OZ",
			"label": "2.25OZ"
	},
	{
			"value": "2.25OZ.",
			"label": "2.25OZ."
	},
	{
			"value": "2.25   X 10 YDS",
			"label": "2.25   X 10 YDS"
	},
	{
			"value": "2.25  X15'",
			"label": "2.25  X15'"
	},
	{
			"value": "2.25   X 1.5   X 3.",
			"label": "2.25   X 1.5   X 3."
	},
	{
			"value": "2.25 X1.625",
			"label": "2.25 X1.625"
	},
	{
			"value": "2.25 X 2.25",
			"label": "2.25 X 2.25"
	},
	{
			"value": "2.25X2.25X10.25",
			"label": "2.25X2.25X10.25"
	},
	{
			"value": "2.25 X 2.75",
			"label": "2.25 X 2.75"
	},
	{
			"value": "2.25 X 3",
			"label": "2.25 X 3"
	},
	{
			"value": "2.25 X .56 X 1.31",
			"label": "2.25 X .56 X 1.31"
	},
	{
			"value": "2.25   X 6 YD",
			"label": "2.25   X 6 YD"
	},
	{
			"value": "2.25  X9'",
			"label": "2.25  X9'"
	},
	{
			"value": "225 YARDS",
			"label": "225 YARDS"
	},
	{
			"value": "225 YD",
			"label": "225 YD"
	},
	{
			"value": "225 YDS",
			"label": "225 YDS"
	},
	{
			"value": "22.65 OZ",
			"label": "22.65 OZ"
	},
	{
			"value": "2-2/7",
			"label": "2-2/7"
	},
	{
			"value": "227GR",
			"label": "227GR"
	},
	{
			"value": "22.7 OZ",
			"label": "22.7 OZ"
	},
	{
			"value": "22.8",
			"label": "22.8"
	},
	{
			"value": "2.283IN X 4YD",
			"label": "2.283IN X 4YD"
	},
	{
			"value": "22.89 OZ",
			"label": "22.89 OZ"
	},
	{
			"value": "22.8IN",
			"label": "22.8IN"
	},
	{
			"value": "22   CORD",
			"label": "22   CORD"
	},
	{
			"value": "22FT",
			"label": "22FT"
	},
	{
			"value": "22G",
			"label": "22G"
	},
	{
			"value": "22 IN",
			"label": "22 IN"
	},
	{
			"value": "22IN",
			"label": "22IN"
	},
	{
			"value": "22 INCH",
			"label": "22 INCH"
	},
	{
			"value": "22IN X 28 IN",
			"label": "22IN X 28 IN"
	},
	{
			"value": "2.2 LB",
			"label": "2.2 LB"
	},
	{
			"value": "2.2LB",
			"label": "2.2LB"
	},
	{
			"value": "22ML",
			"label": "22ML"
	},
	{
			"value": "22MM",
			"label": "22MM"
	},
	{
			"value": "2.2 OZ",
			"label": "2.2 OZ"
	},
	{
			"value": "22 OZ",
			"label": "22 OZ"
	},
	{
			"value": "22OZ",
			"label": "22OZ"
	},
	{
			"value": "2.2QT",
			"label": "2.2QT"
	},
	{
			"value": "22W(22-24-26)",
			"label": "22W(22-24-26)"
	},
	{
			"value": "22X10",
			"label": "22X10"
	},
	{
			"value": "22X1.25X22",
			"label": "22X1.25X22"
	},
	{
			"value": "22X14",
			"label": "22X14"
	},
	{
			"value": "22 X 15.5 X 9.25",
			"label": "22 X 15.5 X 9.25"
	},
	{
			"value": "22  X15  X5.5",
			"label": "22  X15  X5.5"
	},
	{
			"value": "22X16 OD",
			"label": "22X16 OD"
	},
	{
			"value": "22   X 20 YDS",
			"label": "22   X 20 YDS"
	},
	{
			"value": "22 X 22",
			"label": "22 X 22"
	},
	{
			"value": "22X22",
			"label": "22X22"
	},
	{
			"value": "22   X 22",
			"label": "22   X 22"
	},
	{
			"value": "22X22X1",
			"label": "22X22X1"
	},
	{
			"value": "22   X 22   X 1",
			"label": "22   X 22   X 1"
	},
	{
			"value": "22X22X2",
			"label": "22X22X2"
	},
	{
			"value": "22   X 22   X 2",
			"label": "22   X 22   X 2"
	},
	{
			"value": "22X22X4",
			"label": "22X22X4"
	},
	{
			"value": "22   X 22   X 4",
			"label": "22   X 22   X 4"
	},
	{
			"value": "2.2X2.2X6.3",
			"label": "2.2X2.2X6.3"
	},
	{
			"value": "22 X 26",
			"label": "22 X 26"
	},
	{
			"value": "22X26",
			"label": "22X26"
	},
	{
			"value": "22X28",
			"label": "22X28"
	},
	{
			"value": "22   X 28",
			"label": "22   X 28"
	},
	{
			"value": "22  X28",
			"label": "22  X28"
	},
	{
			"value": "22X2X15",
			"label": "22X2X15"
	},
	{
			"value": "2.2   X 3",
			"label": "2.2   X 3"
	},
	{
			"value": "22 X 30",
			"label": "22 X 30"
	},
	{
			"value": "22X30",
			"label": "22X30"
	},
	{
			"value": "22   X 30",
			"label": "22   X 30"
	},
	{
			"value": "22X36",
			"label": "22X36"
	},
	{
			"value": "22X36X4",
			"label": "22X36X4"
	},
	{
			"value": "23",
			"label": "23"
	},
	{
			"value": "230",
			"label": "230"
	},
	{
			"value": "2300",
			"label": "2300"
	},
	{
			"value": "23000",
			"label": "23000"
	},
	{
			"value": "23.00 X 17.00",
			"label": "23.00 X 17.00"
	},
	{
			"value": "230Z",
			"label": "230Z"
	},
	{
			"value": "23.26 OZ.",
			"label": "23.26 OZ."
	},
	{
			"value": "2.32 OZ",
			"label": "2.32 OZ"
	},
	{
			"value": "2 3/4",
			"label": "2 3/4"
	},
	{
			"value": "2 3/4\"\"",
			"label": "2 3/4\"\""
	},
	{
			"value": "2-3/4",
			"label": "2-3/4"
	},
	{
			"value": "(2-3-4-5)",
			"label": "(2-3-4-5)"
	},
	{
			"value": "23.4 OZ",
			"label": "23.4 OZ"
	},
	{
			"value": "2 3/4   X 25YD",
			"label": "2 3/4   X 25YD"
	},
	{
			"value": "2 3/4  X 6YDS",
			"label": "2 3/4  X 6YDS"
	},
	{
			"value": "23.5",
			"label": "23.5"
	},
	{
			"value": "23520",
			"label": "23520"
	},
	{
			"value": "23540",
			"label": "23540"
	},
	{
			"value": "23.5IN",
			"label": "23.5IN"
	},
	{
			"value": "23.5  (W) X 60.5  (",
			"label": "23.5  (W) X 60.5  ("
	},
	{
			"value": "23.5 X 11.2 X 16.2",
			"label": "23.5 X 11.2 X 16.2"
	},
	{
			"value": "23.5X1.5X28.8",
			"label": "23.5X1.5X28.8"
	},
	{
			"value": "23.60 X 18.90",
			"label": "23.60 X 18.90"
	},
	{
			"value": "2.362IN X 4YD",
			"label": "2.362IN X 4YD"
	},
	{
			"value": "23.62X11.81",
			"label": "23.62X11.81"
	},
	{
			"value": "23.62X1.97X37.8",
			"label": "23.62X1.97X37.8"
	},
	{
			"value": "23.62X8.5X2.36",
			"label": "23.62X8.5X2.36"
	},
	{
			"value": "2.363IN X 4YD",
			"label": "2.363IN X 4YD"
	},
	{
			"value": "23.6 IN",
			"label": "23.6 IN"
	},
	{
			"value": "23.6IN",
			"label": "23.6IN"
	},
	{
			"value": "2.36 OZ",
			"label": "2.36 OZ"
	},
	{
			"value": "2.375",
			"label": "2.375"
	},
	{
			"value": "2375",
			"label": "2375"
	},
	{
			"value": "2.375 OZ",
			"label": "2.375 OZ"
	},
	{
			"value": "2.375OZ",
			"label": "2.375OZ"
	},
	{
			"value": "23.75X11.75",
			"label": "23.75X11.75"
	},
	{
			"value": "2.375X2.375X6",
			"label": "2.375X2.375X6"
	},
	{
			"value": "2.375 X 3.75",
			"label": "2.375 X 3.75"
	},
	{
			"value": "23.75X5.5X1.75",
			"label": "23.75X5.5X1.75"
	},
	{
			"value": "23 7/8 X 19 7/8",
			"label": "23 7/8 X 19 7/8"
	},
	{
			"value": "2.37OZ",
			"label": "2.37OZ"
	},
	{
			"value": "2.37X4.12",
			"label": "2.37X4.12"
	},
	{
			"value": "2 3/8",
			"label": "2 3/8"
	},
	{
			"value": "2 3/8\"\"",
			"label": "2 3/8\"\""
	},
	{
			"value": "2-3/8",
			"label": "2-3/8"
	},
	{
			"value": "2380",
			"label": "2380"
	},
	{
			"value": "23.812  X13",
			"label": "23.812  X13"
	},
	{
			"value": "23.812   X 18",
			"label": "23.812   X 18"
	},
	{
			"value": "23.812  X18",
			"label": "23.812  X18"
	},
	{
			"value": "23.812   X 5",
			"label": "23.812   X 5"
	},
	{
			"value": "2-3/8  H X 7  W",
			"label": "2-3/8  H X 7  W"
	},
	{
			"value": "2 3/8  X 6YDS",
			"label": "2 3/8  X 6YDS"
	},
	{
			"value": "23CT",
			"label": "23CT"
	},
	{
			"value": "23 IN",
			"label": "23 IN"
	},
	{
			"value": "23IN",
			"label": "23IN"
	},
	{
			"value": "23 INCH",
			"label": "23 INCH"
	},
	{
			"value": "23INCH",
			"label": "23INCH"
	},
	{
			"value": "23 LONG",
			"label": "23 LONG"
	},
	{
			"value": "2.3 OZ",
			"label": "2.3 OZ"
	},
	{
			"value": "2/3 OZ",
			"label": "2/3 OZ"
	},
	{
			"value": "23 OZ",
			"label": "23 OZ"
	},
	{
			"value": "23OZ",
			"label": "23OZ"
	},
	{
			"value": "23X23",
			"label": "23X23"
	},
	{
			"value": "23X23 OD",
			"label": "23X23 OD"
	},
	{
			"value": "2.3 X 2.3 X 2.3",
			"label": "2.3 X 2.3 X 2.3"
	},
	{
			"value": "23X31",
			"label": "23X31"
	},
	{
			"value": "23X35",
			"label": "23X35"
	},
	{
			"value": "23 X 42",
			"label": "23 X 42"
	},
	{
			"value": "23X70",
			"label": "23X70"
	},
	{
			"value": "23 X 72",
			"label": "23 X 72"
	},
	{
			"value": "23   X 8   X 10.5",
			"label": "23   X 8   X 10.5"
	},
	{
			"value": "24",
			"label": "24"
	},
	{
			"value": "240",
			"label": "240"
	},
	{
			"value": "2400",
			"label": "2400"
	},
	{
			"value": "24000",
			"label": "24000"
	},
	{
			"value": "24.01 OZ",
			"label": "24.01 OZ"
	},
	{
			"value": "24 1/2 X 20 1/2",
			"label": "24 1/2 X 20 1/2"
	},
	{
			"value": "244",
			"label": "244"
	},
	{
			"value": "24-40",
			"label": "24-40"
	},
	{
			"value": "24.4 INCH",
			"label": "24.4 INCH"
	},
	{
			"value": "24.5",
			"label": "24.5"
	},
	{
			"value": "2456",
			"label": "2456"
	},
	{
			"value": "24576",
			"label": "24576"
	},
	{
			"value": "24 5/8 X 13 7/8",
			"label": "24 5/8 X 13 7/8"
	},
	{
			"value": "24 5/8   X 17 5/8",
			"label": "24 5/8   X 17 5/8"
	},
	{
			"value": "24.5IN",
			"label": "24.5IN"
	},
	{
			"value": "24.5  OZ",
			"label": "24.5  OZ"
	},
	{
			"value": "24.5 X 10.5 X 5",
			"label": "24.5 X 10.5 X 5"
	},
	{
			"value": "24.5   X 17.25   X",
			"label": "24.5   X 17.25   X"
	},
	{
			"value": "24.75 OZ",
			"label": "24.75 OZ"
	},
	{
			"value": "24.75 OZ.",
			"label": "24.75 OZ."
	},
	{
			"value": "24 7/8 X 14 7/8",
			"label": "24 7/8 X 14 7/8"
	},
	{
			"value": "2.47 OZ",
			"label": "2.47 OZ"
	},
	{
			"value": "2484",
			"label": "2484"
	},
	{
			"value": "2.48IN X 4YD",
			"label": "2.48IN X 4YD"
	},
	{
			"value": "24.8X1.5X24.8",
			"label": "24.8X1.5X24.8"
	},
	{
			"value": "24 CT",
			"label": "24 CT"
	},
	{
			"value": "24CT",
			"label": "24CT"
	},
	{
			"value": "24G",
			"label": "24G"
	},
	{
			"value": "24 HALF BARS",
			"label": "24 HALF BARS"
	},
	{
			"value": "24 IN",
			"label": "24 IN"
	},
	{
			"value": "24IN",
			"label": "24IN"
	},
	{
			"value": "24 INCH",
			"label": "24 INCH"
	},
	{
			"value": "24IN H",
			"label": "24IN H"
	},
	{
			"value": "24IN X 40IN, 10MM T",
			"label": "24IN X 40IN, 10MM T"
	},
	{
			"value": "24IN X 40IN, 2MM TH",
			"label": "24IN X 40IN, 2MM TH"
	},
	{
			"value": "24IN X 40IN, 5MM TH",
			"label": "24IN X 40IN, 5MM TH"
	},
	{
			"value": "24  L X 30  W X 68",
			"label": "24  L X 30  W X 68"
	},
	{
			"value": "24MM",
			"label": "24MM"
	},
	{
			"value": "24 MONTHS",
			"label": "24 MONTHS"
	},
	{
			"value": "2.4 OZ",
			"label": "2.4 OZ"
	},
	{
			"value": "24 OZ",
			"label": "24 OZ"
	},
	{
			"value": "24 OZ.",
			"label": "24 OZ."
	},
	{
			"value": "24OZ",
			"label": "24OZ"
	},
	{
			"value": "2.4OZ",
			"label": "2.4OZ"
	},
	{
			"value": "24 PC",
			"label": "24 PC"
	},
	{
			"value": "24PK",
			"label": "24PK"
	},
	{
			"value": "24 X 11 X 8",
			"label": "24 X 11 X 8"
	},
	{
			"value": "24X12",
			"label": "24X12"
	},
	{
			"value": "24X1.25X24",
			"label": "24X1.25X24"
	},
	{
			"value": "24X12X.88",
			"label": "24X12X.88"
	},
	{
			"value": "24X14X1",
			"label": "24X14X1"
	},
	{
			"value": "24X1.5X24",
			"label": "24X1.5X24"
	},
	{
			"value": "24   X 16",
			"label": "24   X 16"
	},
	{
			"value": "24X1.625X24",
			"label": "24X1.625X24"
	},
	{
			"value": "24 X 18",
			"label": "24 X 18"
	},
	{
			"value": "24  X18",
			"label": "24  X18"
	},
	{
			"value": "24   X 18   X 12.75",
			"label": "24   X 18   X 12.75"
	},
	{
			"value": "24X1X24",
			"label": "24X1X24"
	},
	{
			"value": "24  X1  X8YDS",
			"label": "24  X1  X8YDS"
	},
	{
			"value": "24 X 20",
			"label": "24 X 20"
	},
	{
			"value": "24X24",
			"label": "24X24"
	},
	{
			"value": "24   X 24",
			"label": "24   X 24"
	},
	{
			"value": "24  X24",
			"label": "24  X24"
	},
	{
			"value": "2.4 X 2.4 X 11.22",
			"label": "2.4 X 2.4 X 11.22"
	},
	{
			"value": "24   X 24   X 3",
			"label": "24   X 24   X 3"
	},
	{
			"value": "2.4X2.4X3.3",
			"label": "2.4X2.4X3.3"
	},
	{
			"value": "24   X 27",
			"label": "24   X 27"
	},
	{
			"value": "24  X27",
			"label": "24  X27"
	},
	{
			"value": "24X2X15",
			"label": "24X2X15"
	},
	{
			"value": "24X2X6",
			"label": "24X2X6"
	},
	{
			"value": "24   X 3",
			"label": "24   X 3"
	},
	{
			"value": "24X30",
			"label": "24X30"
	},
	{
			"value": "24X32",
			"label": "24X32"
	},
	{
			"value": "24   X 32",
			"label": "24   X 32"
	},
	{
			"value": "24 X36",
			"label": "24 X36"
	},
	{
			"value": "24X36",
			"label": "24X36"
	},
	{
			"value": "24  X36",
			"label": "24  X36"
	},
	{
			"value": "24X36 3",
			"label": "24X36 3"
	},
	{
			"value": "24   X 36   FLAG; 7",
			"label": "24   X 36   FLAG; 7"
	},
	{
			"value": "24X36 INCH",
			"label": "24X36 INCH"
	},
	{
			"value": "24X36X4",
			"label": "24X36X4"
	},
	{
			"value": "24   X 40   X 10MM",
			"label": "24   X 40   X 10MM"
	},
	{
			"value": "24   X 40   X 2MM",
			"label": "24   X 40   X 2MM"
	},
	{
			"value": "24   X 40   X 5MM",
			"label": "24   X 40   X 5MM"
	},
	{
			"value": "24 X 4.75 X 3.5",
			"label": "24 X 4.75 X 3.5"
	},
	{
			"value": "2.4 X 5.25",
			"label": "2.4 X 5.25"
	},
	{
			"value": "24   X 57",
			"label": "24   X 57"
	},
	{
			"value": "24X58",
			"label": "24X58"
	},
	{
			"value": "24X6X6",
			"label": "24X6X6"
	},
	{
			"value": "24   X 72   X 1",
			"label": "24   X 72   X 1"
	},
	{
			"value": "24X8",
			"label": "24X8"
	},
	{
			"value": "24   X 8YDS X 1",
			"label": "24   X 8YDS X 1"
	},
	{
			"value": "24YD",
			"label": "24YD"
	},
	{
			"value": ".25",
			"label": ".25"
	},
	{
			"value": "2.5",
			"label": "2.5"
	},
	{
			"value": "2.5\"\"",
			"label": "2.5\"\""
	},
	{
			"value": "25",
			"label": "25"
	},
	{
			"value": "25\"\"",
			"label": "25\"\""
	},
	{
			"value": "250",
			"label": "250"
	},
	{
			"value": "2500",
			"label": "2500"
	},
	{
			"value": "25000",
			"label": "25000"
	},
	{
			"value": "250G",
			"label": "250G"
	},
	{
			"value": "250GR",
			"label": "250GR"
	},
	{
			"value": "250 ML",
			"label": "250 ML"
	},
	{
			"value": "250ML",
			"label": "250ML"
	},
	{
			"value": "2   (50MM)",
			"label": "2   (50MM)"
	},
	{
			"value": "2 (50MM)",
			"label": "2 (50MM)"
	},
	{
			"value": "250MM",
			"label": "250MM"
	},
	{
			"value": "2.50 OZ BONUS SIZE T",
			"label": "2.50 OZ BONUS SIZE T"
	},
	{
			"value": "2.50 X 1.25 X .25",
			"label": "2.50 X 1.25 X .25"
	},
	{
			"value": "250 X 14",
			"label": "250 X 14"
	},
	{
			"value": "250 X 18",
			"label": "250 X 18"
	},
	{
			"value": "2.50 X 1.88 X .25",
			"label": "2.50 X 1.88 X .25"
	},
	{
			"value": "250 YD",
			"label": "250 YD"
	},
	{
			"value": "250 YDS",
			"label": "250 YDS"
	},
	{
			"value": "250YDS",
			"label": "250YDS"
	},
	{
			"value": "2-5/16",
			"label": "2-5/16"
	},
	{
			"value": "25 1/8 X 13 1/8",
			"label": "25 1/8 X 13 1/8"
	},
	{
			"value": "251 YDS",
			"label": "251 YDS"
	},
	{
			"value": "25.2",
			"label": "25.2"
	},
	{
			"value": "252 YDS",
			"label": "252 YDS"
	},
	{
			"value": "253 YDS",
			"label": "253 YDS"
	},
	{
			"value": "2544",
			"label": "2544"
	},
	{
			"value": "2.54 OZ",
			"label": "2.54 OZ"
	},
	{
			"value": "2.54OZ",
			"label": "2.54OZ"
	},
	{
			"value": "25.5",
			"label": "25.5"
	},
	{
			"value": "255",
			"label": "255"
	},
	{
			"value": "25.5 OZ",
			"label": "25.5 OZ"
	},
	{
			"value": "2560",
			"label": "2560"
	},
	{
			"value": "2562",
			"label": "2562"
	},
	{
			"value": "2568",
			"label": "2568"
	},
	{
			"value": "25.6X0.8X25.6",
			"label": "25.6X0.8X25.6"
	},
	{
			"value": "25.6X25.6",
			"label": "25.6X25.6"
	},
	{
			"value": "2-5/8",
			"label": "2-5/8"
	},
	{
			"value": "2-5/8''",
			"label": "2-5/8''"
	},
	{
			"value": "2590",
			"label": "2590"
	},
	{
			"value": "2592",
			"label": "2592"
	},
	{
			"value": ".25   AND .5",
			"label": ".25   AND .5"
	},
	{
			"value": "2.5\"\" BOWS",
			"label": "2.5\"\" BOWS"
	},
	{
			"value": "25 CANDLE",
			"label": "25 CANDLE"
	},
	{
			"value": "25CT",
			"label": "25CT"
	},
	{
			"value": "25 FOOT HANK",
			"label": "25 FOOT HANK"
	},
	{
			"value": "25 FT",
			"label": "25 FT"
	},
	{
			"value": "25 FT HANK",
			"label": "25 FT HANK"
	},
	{
			"value": "25G",
			"label": "25G"
	},
	{
			"value": ".25IN",
			"label": ".25IN"
	},
	{
			"value": "25 IN",
			"label": "25 IN"
	},
	{
			"value": "25IN",
			"label": "25IN"
	},
	{
			"value": "2.5IN",
			"label": "2.5IN"
	},
	{
			"value": "2.5 IN 9 FT WIRED ED",
			"label": "2.5 IN 9 FT WIRED ED"
	},
	{
			"value": "2.5 INCH",
			"label": "2.5 INCH"
	},
	{
			"value": "25 INCH",
			"label": "25 INCH"
	},
	{
			"value": "25 INCHES IN HEIGHT",
			"label": "25 INCHES IN HEIGHT"
	},
	{
			"value": "25 INCH IN HEIGHT",
			"label": "25 INCH IN HEIGHT"
	},
	{
			"value": "2.5 INCH X 3 FT",
			"label": "2.5 INCH X 3 FT"
	},
	{
			"value": "2.5 INCH X 3 YARDS",
			"label": "2.5 INCH X 3 YARDS"
	},
	{
			"value": "2.5 INCH X 6 YARDS",
			"label": "2.5 INCH X 6 YARDS"
	},
	{
			"value": "2.5 IN X 10 YARD",
			"label": "2.5 IN X 10 YARD"
	},
	{
			"value": "2.5 IN X 12 FT",
			"label": "2.5 IN X 12 FT"
	},
	{
			"value": "2.5 IN. X 12 FT.",
			"label": "2.5 IN. X 12 FT."
	},
	{
			"value": "2.5IN X 12FT",
			"label": "2.5IN X 12FT"
	},
	{
			"value": "2.5 IN. X 9 FT.",
			"label": "2.5 IN. X 9 FT."
	},
	{
			"value": "2.5 IN. X 9 FT. WIRE",
			"label": "2.5 IN. X 9 FT. WIRE"
	},
	{
			"value": "2.5 IN X 9 FT WIRED",
			"label": "2.5 IN X 9 FT WIRED"
	},
	{
			"value": "25 LB",
			"label": "25 LB"
	},
	{
			"value": "25 LB.",
			"label": "25 LB."
	},
	{
			"value": ".25  L X 4.75  W X",
			"label": ".25  L X 4.75  W X"
	},
	{
			"value": "25M",
			"label": "25M"
	},
	{
			"value": "25MM",
			"label": "25MM"
	},
	{
			"value": "25MM US50",
			"label": "25MM US50"
	},
	{
			"value": "25 MM X 10 M",
			"label": "25 MM X 10 M"
	},
	{
			"value": "25 MM X 5 M",
			"label": "25 MM X 5 M"
	},
	{
			"value": ".25 OZ",
			"label": ".25 OZ"
	},
	{
			"value": ".25OZ",
			"label": ".25OZ"
	},
	{
			"value": "2.5 OZ",
			"label": "2.5 OZ"
	},
	{
			"value": "2.5 OZ.",
			"label": "2.5 OZ."
	},
	{
			"value": "25 OZ",
			"label": "25 OZ"
	},
	{
			"value": "25OZ",
			"label": "25OZ"
	},
	{
			"value": "2.5OZ",
			"label": "2.5OZ"
	},
	{
			"value": "2.5 OZ/ 2.1 OZ",
			"label": "2.5 OZ/ 2.1 OZ"
	},
	{
			"value": "2.5 OZ/70 GR",
			"label": "2.5 OZ/70 GR"
	},
	{
			"value": "2.5 OZ / 70 GRAMS",
			"label": "2.5 OZ / 70 GRAMS"
	},
	{
			"value": "2.5 OZ WITH 25% BONU",
			"label": "2.5 OZ WITH 25% BONU"
	},
	{
			"value": "25PC",
			"label": "25PC"
	},
	{
			"value": "25 POUNDS",
			"label": "25 POUNDS"
	},
	{
			"value": "2.5 X .13 X 6",
			"label": "2.5 X .13 X 6"
	},
	{
			"value": "2.5  X15'",
			"label": "2.5  X15'"
	},
	{
			"value": "2.5 X 1.75 X 1.25",
			"label": "2.5 X 1.75 X 1.25"
	},
	{
			"value": "2.5X2",
			"label": "2.5X2"
	},
	{
			"value": "2.5X20X0.75",
			"label": "2.5X20X0.75"
	},
	{
			"value": "2.5 X 2.4 X 2",
			"label": "2.5 X 2.4 X 2"
	},
	{
			"value": "2.5 X 2.5",
			"label": "2.5 X 2.5"
	},
	{
			"value": "2.5X2.5",
			"label": "2.5X2.5"
	},
	{
			"value": "2.5 X .25 X 2.5",
			"label": "2.5 X .25 X 2.5"
	},
	{
			"value": "2.5X2.5X2.75",
			"label": "2.5X2.5X2.75"
	},
	{
			"value": "2.5X2.5X5.5",
			"label": "2.5X2.5X5.5"
	},
	{
			"value": "2.5 X 2.5 X 7",
			"label": "2.5 X 2.5 X 7"
	},
	{
			"value": "2.5 X 2.5 X 7.5",
			"label": "2.5 X 2.5 X 7.5"
	},
	{
			"value": "25X29",
			"label": "25X29"
	},
	{
			"value": "2.5 X 3",
			"label": "2.5 X 3"
	},
	{
			"value": "25X30",
			"label": "25X30"
	},
	{
			"value": "2.5X3.3X3.3",
			"label": "2.5X3.3X3.3"
	},
	{
			"value": "2.5   X 3.5",
			"label": "2.5   X 3.5"
	},
	{
			"value": "2.5X3.5",
			"label": "2.5X3.5"
	},
	{
			"value": "25   X 38",
			"label": "25   X 38"
	},
	{
			"value": "2.5  X43",
			"label": "2.5  X43"
	},
	{
			"value": "2.5   X 44",
			"label": "2.5   X 44"
	},
	{
			"value": "2.5  X44",
			"label": "2.5  X44"
	},
	{
			"value": "25 X 49 X 3.5",
			"label": "25 X 49 X 3.5"
	},
	{
			"value": "25 X 5.12 X 6.38",
			"label": "25 X 5.12 X 6.38"
	},
	{
			"value": ".25   X 5.375   X 8",
			"label": ".25   X 5.375   X 8"
	},
	{
			"value": "2.5X5X2.5",
			"label": "2.5X5X2.5"
	},
	{
			"value": "2.5  X5YDS",
			"label": "2.5  X5YDS"
	},
	{
			"value": "2.5   X 6YD",
			"label": "2.5   X 6YD"
	},
	{
			"value": "2.5 X .75 X 6",
			"label": "2.5 X .75 X 6"
	},
	{
			"value": "2.5  X9'",
			"label": "2.5  X9'"
	},
	{
			"value": "2.5 YARDS",
			"label": "2.5 YARDS"
	},
	{
			"value": "25 YARDS PER SPOOL",
			"label": "25 YARDS PER SPOOL"
	},
	{
			"value": "25YD",
			"label": "25YD"
	},
	{
			"value": "2.5YD",
			"label": "2.5YD"
	},
	{
			"value": "2.6\"\"",
			"label": "2.6\"\""
	},
	{
			"value": "26",
			"label": "26"
	},
	{
			"value": "260",
			"label": "260"
	},
	{
			"value": "2600",
			"label": "2600"
	},
	{
			"value": "26000",
			"label": "26000"
	},
	{
			"value": "26.25X38.25",
			"label": "26.25X38.25"
	},
	{
			"value": "26.26IN",
			"label": "26.26IN"
	},
	{
			"value": "2640",
			"label": "2640"
	},
	{
			"value": "26439",
			"label": "26439"
	},
	{
			"value": "26.45 OUNCE",
			"label": "26.45 OUNCE"
	},
	{
			"value": "2.64 OZ",
			"label": "2.64 OZ"
	},
	{
			"value": "26.5",
			"label": "26.5"
	},
	{
			"value": "26.5IN",
			"label": "26.5IN"
	},
	{
			"value": "2.65 OZ",
			"label": "2.65 OZ"
	},
	{
			"value": "26.5 OZ.",
			"label": "26.5 OZ."
	},
	{
			"value": "2.65 X 5.1 X 8.1",
			"label": "2.65 X 5.1 X 8.1"
	},
	{
			"value": "26.5 X 7.5",
			"label": "26.5 X 7.5"
	},
	{
			"value": "266",
			"label": "266"
	},
	{
			"value": "26.62X3.15X23.62",
			"label": "26.62X3.15X23.62"
	},
	{
			"value": "267",
			"label": "267"
	},
	{
			"value": "2.68 OZ",
			"label": "2.68 OZ"
	},
	{
			"value": "26G",
			"label": "26G"
	},
	{
			"value": "26 IN",
			"label": "26 IN"
	},
	{
			"value": "26IN",
			"label": "26IN"
	},
	{
			"value": "26 INCH",
			"label": "26 INCH"
	},
	{
			"value": "26INCH",
			"label": "26INCH"
	},
	{
			"value": "26  INCH",
			"label": "26  INCH"
	},
	{
			"value": "2.6 OZ",
			"label": "2.6 OZ"
	},
	{
			"value": "26 OZ",
			"label": "26 OZ"
	},
	{
			"value": "2.6OZ",
			"label": "2.6OZ"
	},
	{
			"value": "26 PC",
			"label": "26 PC"
	},
	{
			"value": "26QT",
			"label": "26QT"
	},
	{
			"value": "26 TULLE SPOOLS",
			"label": "26 TULLE SPOOLS"
	},
	{
			"value": "26  WX33  L",
			"label": "26  WX33  L"
	},
	{
			"value": "2.6 X 1.12 X 4.1",
			"label": "2.6 X 1.12 X 4.1"
	},
	{
			"value": "2.6 X 1.5 X 4.7",
			"label": "2.6 X 1.5 X 4.7"
	},
	{
			"value": "26X1.75X26",
			"label": "26X1.75X26"
	},
	{
			"value": "26X25",
			"label": "26X25"
	},
	{
			"value": "26X26",
			"label": "26X26"
	},
	{
			"value": "26   X 26",
			"label": "26   X 26"
	},
	{
			"value": "26  X26",
			"label": "26  X26"
	},
	{
			"value": "26   X 35",
			"label": "26   X 35"
	},
	{
			"value": "2.6 X 3.55",
			"label": "2.6 X 3.55"
	},
	{
			"value": "26X38",
			"label": "26X38"
	},
	{
			"value": "26  X54",
			"label": "26  X54"
	},
	{
			"value": "26X9",
			"label": "26X9"
	},
	{
			"value": "27",
			"label": "27"
	},
	{
			"value": "27''",
			"label": "27''"
	},
	{
			"value": "270",
			"label": "270"
	},
	{
			"value": "2700",
			"label": "2700"
	},
	{
			"value": "27000",
			"label": "27000"
	},
	{
			"value": "27 1/2 X 23 1/2",
			"label": "27 1/2 X 23 1/2"
	},
	{
			"value": "27 3/ 4 X 21 3/4",
			"label": "27 3/ 4 X 21 3/4"
	},
	{
			"value": "2736",
			"label": "2736"
	},
	{
			"value": "2.75",
			"label": "2.75"
	},
	{
			"value": "27.58 OZ",
			"label": "27.58 OZ"
	},
	{
			"value": "2.75 IN",
			"label": "2.75 IN"
	},
	{
			"value": "2.75IN",
			"label": "2.75IN"
	},
	{
			"value": "2.75 INCH",
			"label": "2.75 INCH"
	},
	{
			"value": "2.75 LB",
			"label": "2.75 LB"
	},
	{
			"value": "2.75L X 1.88W X 4.0L",
			"label": "2.75L X 1.88W X 4.0L"
	},
	{
			"value": "2.75 OZ",
			"label": "2.75 OZ"
	},
	{
			"value": "2.75 OZ.",
			"label": "2.75 OZ."
	},
	{
			"value": "27.5 OZ",
			"label": "27.5 OZ"
	},
	{
			"value": "2.75  W X 2.75  D X",
			"label": "2.75  W X 2.75  D X"
	},
	{
			"value": "2.75   X 10.5   X 1",
			"label": "2.75   X 10.5   X 1"
	},
	{
			"value": "2.75 X 11.5 X 13",
			"label": "2.75 X 11.5 X 13"
	},
	{
			"value": "2.75  X25 YDS",
			"label": "2.75  X25 YDS"
	},
	{
			"value": "2.75X2.75X11",
			"label": "2.75X2.75X11"
	},
	{
			"value": "2.75X2.75X11.024",
			"label": "2.75X2.75X11.024"
	},
	{
			"value": "2.75 X 2.75 X 5.5",
			"label": "2.75 X 2.75 X 5.5"
	},
	{
			"value": "2.75X2.75X7.5",
			"label": "2.75X2.75X7.5"
	},
	{
			"value": "2.75 X 3.75 .25",
			"label": "2.75 X 3.75 .25"
	},
	{
			"value": "2.75 X 3.75 X .25",
			"label": "2.75 X 3.75 X .25"
	},
	{
			"value": "2.75 X 3.75 X.25",
			"label": "2.75 X 3.75 X.25"
	},
	{
			"value": "2.75 X 5",
			"label": "2.75 X 5"
	},
	{
			"value": "2.75X.50X8.0",
			"label": "2.75X.50X8.0"
	},
	{
			"value": "2.75X.50X8.00",
			"label": "2.75X.50X8.00"
	},
	{
			"value": "2.75X5.25",
			"label": "2.75X5.25"
	},
	{
			"value": "2.75X.5X1",
			"label": "2.75X.5X1"
	},
	{
			"value": "2.75 X 6",
			"label": "2.75 X 6"
	},
	{
			"value": "2.75 X .75 X 6.25",
			"label": "2.75 X .75 X 6.25"
	},
	{
			"value": "2.75X8.0X.50",
			"label": "2.75X8.0X.50"
	},
	{
			"value": "2.75   X 9",
			"label": "2.75   X 9"
	},
	{
			"value": "2.76",
			"label": "2.76"
	},
	{
			"value": "276",
			"label": "276"
	},
	{
			"value": "27.6IN",
			"label": "27.6IN"
	},
	{
			"value": "27.6 OZ",
			"label": "27.6 OZ"
	},
	{
			"value": "2.76X2.76X12.6",
			"label": "2.76X2.76X12.6"
	},
	{
			"value": "27984",
			"label": "27984"
	},
	{
			"value": "27 IN",
			"label": "27 IN"
	},
	{
			"value": "27IN",
			"label": "27IN"
	},
	{
			"value": "27 INCH",
			"label": "27 INCH"
	},
	{
			"value": "27MM",
			"label": "27MM"
	},
	{
			"value": "2.7 OZ",
			"label": "2.7 OZ"
	},
	{
			"value": "27 OZ.",
			"label": "27 OZ."
	},
	{
			"value": "2.7OZ/75G",
			"label": "2.7OZ/75G"
	},
	{
			"value": "27   X 10YDS X 1",
			"label": "27   X 10YDS X 1"
	},
	{
			"value": "27  X16YDS",
			"label": "27  X16YDS"
	},
	{
			"value": "27X27",
			"label": "27X27"
	},
	{
			"value": "2.7   X 2.7",
			"label": "2.7   X 2.7"
	},
	{
			"value": "27   X 27",
			"label": "27   X 27"
	},
	{
			"value": "2.7X2.7X3.7",
			"label": "2.7X2.7X3.7"
	},
	{
			"value": "27X40",
			"label": "27X40"
	},
	{
			"value": "27X40 COLLAGE",
			"label": "27X40 COLLAGE"
	},
	{
			"value": "27X40 MATTED TO 20X2",
			"label": "27X40 MATTED TO 20X2"
	},
	{
			"value": "2.7 X 4.15 X 10.45",
			"label": "2.7 X 4.15 X 10.45"
	},
	{
			"value": "27 X 42",
			"label": "27 X 42"
	},
	{
			"value": "27   X 5YDS X 2",
			"label": "27   X 5YDS X 2"
	},
	{
			"value": "27 X 64",
			"label": "27 X 64"
	},
	{
			"value": "27   X 76   X 2",
			"label": "27   X 76   X 2"
	},
	{
			"value": "28",
			"label": "28"
	},
	{
			"value": "28'",
			"label": "28'"
	},
	{
			"value": "28''",
			"label": "28''"
	},
	{
			"value": "2800",
			"label": "2800"
	},
	{
			"value": "28000",
			"label": "28000"
	},
	{
			"value": "280G",
			"label": "280G"
	},
	{
			"value": "280 GR",
			"label": "280 GR"
	},
	{
			"value": "280GR",
			"label": "280GR"
	},
	{
			"value": "2.8125X2.8125X6.1875",
			"label": "2.8125X2.8125X6.1875"
	},
	{
			"value": "28 1/2 X 28 1/2",
			"label": "28 1/2 X 28 1/2"
	},
	{
			"value": "28-28",
			"label": "28-28"
	},
	{
			"value": "2.8 2.8 5",
			"label": "2.8 2.8 5"
	},
	{
			"value": "2.82 OZ",
			"label": "2.82 OZ"
	},
	{
			"value": "2.82OZ",
			"label": "2.82OZ"
	},
	{
			"value": "28.2 OZ",
			"label": "28.2 OZ"
	},
	{
			"value": "28 3/4 X 28 3/4",
			"label": "28 3/4 X 28 3/4"
	},
	{
			"value": "28.35 OZ",
			"label": "28.35 OZ"
	},
	{
			"value": "283 GR",
			"label": "283 GR"
	},
	{
			"value": "283GR",
			"label": "283GR"
	},
	{
			"value": "2.83 OZ",
			"label": "2.83 OZ"
	},
	{
			"value": "2.83OZ",
			"label": "2.83OZ"
	},
	{
			"value": "2.83 X2.83X3.23",
			"label": "2.83 X2.83X3.23"
	},
	{
			"value": "28-48",
			"label": "28-48"
	},
	{
			"value": "28-48   LENGTH  3/4",
			"label": "28-48   LENGTH  3/4"
	},
	{
			"value": "285",
			"label": "285"
	},
	{
			"value": "2850",
			"label": "2850"
	},
	{
			"value": "28.54 OZ",
			"label": "28.54 OZ"
	},
	{
			"value": "2856",
			"label": "2856"
	},
	{
			"value": "28.5IN",
			"label": "28.5IN"
	},
	{
			"value": "28.5X18.5X1",
			"label": "28.5X18.5X1"
	},
	{
			"value": "28.5X1X18.5",
			"label": "28.5X1X18.5"
	},
	{
			"value": "2.875 OZ",
			"label": "2.875 OZ"
	},
	{
			"value": "28.75 X 16.25 X 49.5",
			"label": "28.75 X 16.25 X 49.5"
	},
	{
			"value": "2.875   X 6YD",
			"label": "2.875   X 6YD"
	},
	{
			"value": "2880",
			"label": "2880"
	},
	{
			"value": "28992",
			"label": "28992"
	},
	{
			"value": ".28CF",
			"label": ".28CF"
	},
	{
			"value": "28G",
			"label": "28G"
	},
	{
			"value": "28GR",
			"label": "28GR"
	},
	{
			"value": "28 IN",
			"label": "28 IN"
	},
	{
			"value": "28IN",
			"label": "28IN"
	},
	{
			"value": "28 INCH",
			"label": "28 INCH"
	},
	{
			"value": "28INCH",
			"label": "28INCH"
	},
	{
			"value": "28MM",
			"label": "28MM"
	},
	{
			"value": ".28 OZ",
			"label": ".28 OZ"
	},
	{
			"value": ".28OZ",
			"label": ".28OZ"
	},
	{
			"value": "2.8 OZ",
			"label": "2.8 OZ"
	},
	{
			"value": "28 OZ",
			"label": "28 OZ"
	},
	{
			"value": "28 OZ.",
			"label": "28 OZ."
	},
	{
			"value": "28OZ",
			"label": "28OZ"
	},
	{
			"value": "2.8OZ",
			"label": "2.8OZ"
	},
	{
			"value": "28 PC",
			"label": "28 PC"
	},
	{
			"value": "28 PCS",
			"label": "28 PCS"
	},
	{
			"value": "28 PCS.",
			"label": "28 PCS."
	},
	{
			"value": "28W(28-30-32)",
			"label": "28W(28-30-32)"
	},
	{
			"value": "28X0.75X28",
			"label": "28X0.75X28"
	},
	{
			"value": "28X28",
			"label": "28X28"
	},
	{
			"value": "28   X 28",
			"label": "28   X 28"
	},
	{
			"value": "28X2X14",
			"label": "28X2X14"
	},
	{
			"value": "28  X30",
			"label": "28  X30"
	},
	{
			"value": "28 X 34",
			"label": "28 X 34"
	},
	{
			"value": "28 X34",
			"label": "28 X34"
	},
	{
			"value": "28X34",
			"label": "28X34"
	},
	{
			"value": "29",
			"label": "29"
	},
	{
			"value": "2900",
			"label": "2900"
	},
	{
			"value": "2916",
			"label": "2916"
	},
	{
			"value": "2.94 X 0.42 X 7.03",
			"label": "2.94 X 0.42 X 7.03"
	},
	{
			"value": "29.5",
			"label": "29.5"
	},
	{
			"value": "29.53X20.67",
			"label": "29.53X20.67"
	},
	{
			"value": "29.5 IN",
			"label": "29.5 IN"
	},
	{
			"value": "29.5IN",
			"label": "29.5IN"
	},
	{
			"value": "29.5 X 11.5 X 6",
			"label": "29.5 X 11.5 X 6"
	},
	{
			"value": "2.95 X 1.26 X 7.07",
			"label": "2.95 X 1.26 X 7.07"
	},
	{
			"value": "2.95 X 2.95 X 2.95",
			"label": "2.95 X 2.95 X 2.95"
	},
	{
			"value": "2.95   X 3.45",
			"label": "2.95   X 3.45"
	},
	{
			"value": "29.5 X 9.1 X 32.1",
			"label": "29.5 X 9.1 X 32.1"
	},
	{
			"value": "2.96OZ",
			"label": "2.96OZ"
	},
	{
			"value": "2976",
			"label": "2976"
	},
	{
			"value": "2.98OZ",
			"label": "2.98OZ"
	},
	{
			"value": "29.94",
			"label": "29.94"
	},
	{
			"value": "29.99",
			"label": "29.99"
	},
	{
			"value": "29 IN",
			"label": "29 IN"
	},
	{
			"value": "29IN",
			"label": "29IN"
	},
	{
			"value": "29 INCH",
			"label": "29 INCH"
	},
	{
			"value": "29L",
			"label": "29L"
	},
	{
			"value": "2.9 OZ",
			"label": "2.9 OZ"
	},
	{
			"value": "29 OZ",
			"label": "29 OZ"
	},
	{
			"value": "29 OZ.",
			"label": "29 OZ."
	},
	{
			"value": "29WX4DX49H",
			"label": "29WX4DX49H"
	},
	{
			"value": "29WX4DX58H",
			"label": "29WX4DX58H"
	},
	{
			"value": "29WX4DX63H",
			"label": "29WX4DX63H"
	},
	{
			"value": "29WX4DX79H",
			"label": "29WX4DX79H"
	},
	{
			"value": "2.9X2.16",
			"label": "2.9X2.16"
	},
	{
			"value": "29   X 29",
			"label": "29   X 29"
	},
	{
			"value": "2.9 X 5.9",
			"label": "2.9 X 5.9"
	},
	{
			"value": "29 X 64",
			"label": "29 X 64"
	},
	{
			"value": "2 BAG - 1.76OZ",
			"label": "2 BAG - 1.76OZ"
	},
	{
			"value": "2 BAGS - 2.25OZ",
			"label": "2 BAGS - 2.25OZ"
	},
	{
			"value": "2 BOTTLES - 2.75OZ",
			"label": "2 BOTTLES - 2.75OZ"
	},
	{
			"value": "2 BULBS",
			"label": "2 BULBS"
	},
	{
			"value": "2CF",
			"label": "2CF"
	},
	{
			"value": "2 CT",
			"label": "2 CT"
	},
	{
			"value": "2CT",
			"label": "2CT"
	},
	{
			"value": "2 - FINE",
			"label": "2 - FINE"
	},
	{
			"value": "2 FL OZ",
			"label": "2 FL OZ"
	},
	{
			"value": "2FTX3FT",
			"label": "2FTX3FT"
	},
	{
			"value": "2G",
			"label": "2G"
	},
	{
			"value": "2 HALF",
			"label": "2 HALF"
	},
	{
			"value": "2HALF",
			"label": "2HALF"
	},
	{
			"value": "2 HALF X 12FT",
			"label": "2 HALF X 12FT"
	},
	{
			"value": "2HALF X  12FT",
			"label": "2HALF X  12FT"
	},
	{
			"value": "2HALF X 12 FT",
			"label": "2HALF X 12 FT"
	},
	{
			"value": "2 IN",
			"label": "2 IN"
	},
	{
			"value": "2IN",
			"label": "2IN"
	},
	{
			"value": "2 IN (50MM)",
			"label": "2 IN (50MM)"
	},
	{
			"value": "2 INCH",
			"label": "2 INCH"
	},
	{
			"value": "2 INCH X 12 YARDS",
			"label": "2 INCH X 12 YARDS"
	},
	{
			"value": "2 INCH X 3FT",
			"label": "2 INCH X 3FT"
	},
	{
			"value": "2 INCH X 3 YARDS",
			"label": "2 INCH X 3 YARDS"
	},
	{
			"value": "2 INCH X 6 YARDS",
			"label": "2 INCH X 6 YARDS"
	},
	{
			"value": "2 IN W 2.5 IN H",
			"label": "2 IN W 2.5 IN H"
	},
	{
			"value": "2 IN X 216 IN",
			"label": "2 IN X 216 IN"
	},
	{
			"value": "2 IN. X 4 IN.",
			"label": "2 IN. X 4 IN."
	},
	{
			"value": "2IN X 50YD",
			"label": "2IN X 50YD"
	},
	{
			"value": "2-JAN",
			"label": "2-JAN"
	},
	{
			"value": "2L",
			"label": "2L"
	},
	{
			"value": "2 LB",
			"label": "2 LB"
	},
	{
			"value": "2LB",
			"label": "2LB"
	},
	{
			"value": "2M",
			"label": "2M"
	},
	{
			"value": "2MM",
			"label": "2MM"
	},
	{
			"value": "2ND CURATION",
			"label": "2ND CURATION"
	},
	{
			"value": ".2 OZ",
			"label": ".2 OZ"
	},
	{
			"value": "2 OZ",
			"label": "2 OZ"
	},
	{
			"value": "2 OZ.",
			"label": "2 OZ."
	},
	{
			"value": "2. OZ",
			"label": "2. OZ"
	},
	{
			"value": "2OZ",
			"label": "2OZ"
	},
	{
			"value": " 2 OZ, BLISTER CARD",
			"label": " 2 OZ, BLISTER CARD"
	},
	{
			"value": "2 OZ KIT",
			"label": "2 OZ KIT"
	},
	{
			"value": "2 PACK",
			"label": "2 PACK"
	},
	{
			"value": "2 PC",
			"label": "2 PC"
	},
	{
			"value": "2PC",
			"label": "2PC"
	},
	{
			"value": "2PC/3.25IN",
			"label": "2PC/3.25IN"
	},
	{
			"value": "2PC/4IN",
			"label": "2PC/4IN"
	},
	{
			"value": "2PC/6INX3IN",
			"label": "2PC/6INX3IN"
	},
	{
			"value": "2 PC BOARDING",
			"label": "2 PC BOARDING"
	},
	{
			"value": "2 PIECE",
			"label": "2 PIECE"
	},
	{
			"value": "2 PIECES",
			"label": "2 PIECES"
	},
	{
			"value": "2 PK",
			"label": "2 PK"
	},
	{
			"value": "2PK",
			"label": "2PK"
	},
	{
			"value": "2PK 10IN TAPER",
			"label": "2PK 10IN TAPER"
	},
	{
			"value": "2PK 12IN TAPER",
			"label": "2PK 12IN TAPER"
	},
	{
			"value": " 2 SM, 1 MD, 1 LG, 1",
			"label": " 2 SM, 1 MD, 1 LG, 1"
	},
	{
			"value": " 2 SM, 1 MED, 1 LG,",
			"label": " 2 SM, 1 MED, 1 LG,"
	},
	{
			"value": "2 STRANDS 4.5\"\" EACH",
			"label": "2 STRANDS 4.5\"\" EACH"
	},
	{
			"value": "2 STRANDS 5 1/2\"\"",
			"label": "2 STRANDS 5 1/2\"\""
	},
	{
			"value": "2 STRANDS 5\"\" EACH",
			"label": "2 STRANDS 5\"\" EACH"
	},
	{
			"value": "2 STRANDS 6 3/8\"\"",
			"label": "2 STRANDS 6 3/8\"\""
	},
	{
			"value": "2T",
			"label": "2T"
	},
	{
			"value": "2   X 1",
			"label": "2   X 1"
	},
	{
			"value": "2X1",
			"label": "2X1"
	},
	{
			"value": "2   X 10YDS",
			"label": "2   X 10YDS"
	},
	{
			"value": "2  X12'",
			"label": "2  X12'"
	},
	{
			"value": "2 X 12.5",
			"label": "2 X 12.5"
	},
	{
			"value": "2   X 12   X 12",
			"label": "2   X 12   X 12"
	},
	{
			"value": "2   X 12   X 12   2",
			"label": "2   X 12   X 12   2"
	},
	{
			"value": "2   X 12YDS",
			"label": "2   X 12YDS"
	},
	{
			"value": "2  X15'",
			"label": "2  X15'"
	},
	{
			"value": "2 X 15 X 17",
			"label": "2 X 15 X 17"
	},
	{
			"value": "2X15X17",
			"label": "2X15X17"
	},
	{
			"value": "2   X 15YDS",
			"label": "2   X 15YDS"
	},
	{
			"value": "2X16\"\"",
			"label": "2X16\"\""
	},
	{
			"value": "2X16X16",
			"label": "2X16X16"
	},
	{
			"value": "2 X .1 X 3.75",
			"label": "2 X .1 X 3.75"
	},
	{
			"value": "2X2",
			"label": "2X2"
	},
	{
			"value": "2X22X22",
			"label": "2X22X22"
	},
	{
			"value": "2X24",
			"label": "2X24"
	},
	{
			"value": "2   X 24   X 24",
			"label": "2   X 24   X 24"
	},
	{
			"value": "2   X 24   X 24   2",
			"label": "2   X 24   X 24   2"
	},
	{
			"value": "2X24X72",
			"label": "2X24X72"
	},
	{
			"value": "2X2X1",
			"label": "2X2X1"
	},
	{
			"value": "2 X 2 X 22.5",
			"label": "2 X 2 X 22.5"
	},
	{
			"value": "2   X 2 YARDS",
			"label": "2   X 2 YARDS"
	},
	{
			"value": "2  X 2 YDS",
			"label": "2  X 2 YDS"
	},
	{
			"value": "2X3",
			"label": "2X3"
	},
	{
			"value": "2   X 3FT",
			"label": "2   X 3FT"
	},
	{
			"value": "2   X 3 YARDS",
			"label": "2   X 3 YARDS"
	},
	{
			"value": "2X4",
			"label": "2X4"
	},
	{
			"value": "2 X 4.50 X 2",
			"label": "2 X 4.50 X 2"
	},
	{
			"value": "2   X 4YDS",
			"label": "2   X 4YDS"
	},
	{
			"value": "2     X 5YDS",
			"label": "2     X 5YDS"
	},
	{
			"value": "2    X 5YDS",
			"label": "2    X 5YDS"
	},
	{
			"value": "2  X6",
			"label": "2  X6"
	},
	{
			"value": "2X6",
			"label": "2X6"
	},
	{
			"value": "2   X 6FT",
			"label": "2   X 6FT"
	},
	{
			"value": "2   X 6YD",
			"label": "2   X 6YD"
	},
	{
			"value": "2   X 6YDS",
			"label": "2   X 6YDS"
	},
	{
			"value": "2  X 6YDS",
			"label": "2  X 6YDS"
	},
	{
			"value": "2X8",
			"label": "2X8"
	},
	{
			"value": "2 X 9.6 X 2 X .61",
			"label": "2 X 9.6 X 2 X .61"
	},
	{
			"value": "2 X 9 X 2.25",
			"label": "2 X 9 X 2.25"
	},
	{
			"value": "2XL",
			"label": "2XL"
	},
	{
			"value": "2X LARGE",
			"label": "2X LARGE"
	},
	{
			"value": "2XLARGE",
			"label": "2XLARGE"
	},
	{
			"value": "2 YARD PRECUT",
			"label": "2 YARD PRECUT"
	},
	{
			"value": "2 YARDS",
			"label": "2 YARDS"
	},
	{
			"value": "2 YD",
			"label": "2 YD"
	},
	{
			"value": "2YD",
			"label": "2YD"
	},
	{
			"value": "2 YDS",
			"label": "2 YDS"
	},
	{
			"value": "3",
			"label": "3"
	},
	{
			"value": "3\"\"",
			"label": "3\"\""
	},
	{
			"value": "30",
			"label": "30"
	},
	{
			"value": "300",
			"label": "300"
	},
	{
			"value": "3000",
			"label": "3000"
	},
	{
			"value": "30000",
			"label": "30000"
	},
	{
			"value": "3000 YDS",
			"label": "3000 YDS"
	},
	{
			"value": "300CT",
			"label": "300CT"
	},
	{
			"value": "300 CT BAG",
			"label": "300 CT BAG"
	},
	{
			"value": "300 GR",
			"label": "300 GR"
	},
	{
			"value": "300GR",
			"label": "300GR"
	},
	{
			"value": "300 GRAMS",
			"label": "300 GRAMS"
	},
	{
			"value": "300ML",
			"label": "300ML"
	},
	{
			"value": "300 PC",
			"label": "300 PC"
	},
	{
			"value": "300PC",
			"label": "300PC"
	},
	{
			"value": "300SERIES 11X14",
			"label": "300SERIES 11X14"
	},
	{
			"value": "300SERIES 14X17",
			"label": "300SERIES 14X17"
	},
	{
			"value": "300SERIES 18X24",
			"label": "300SERIES 18X24"
	},
	{
			"value": "300SERIES 9X12",
			"label": "300SERIES 9X12"
	},
	{
			"value": "-3024",
			"label": "-3024"
	},
	{
			"value": "30.25 X 13.75 X 5 5/",
			"label": "30.25 X 13.75 X 5 5/"
	},
	{
			"value": "3028",
			"label": "3028"
	},
	{
			"value": "3.02 OZ",
			"label": "3.02 OZ"
	},
	{
			"value": "30-40",
			"label": "30-40"
	},
	{
			"value": "30.4 OZ",
			"label": "30.4 OZ"
	},
	{
			"value": "30.5",
			"label": "30.5"
	},
	{
			"value": "30-50",
			"label": "30-50"
	},
	{
			"value": "30.5IN",
			"label": "30.5IN"
	},
	{
			"value": "30   CORD",
			"label": "30   CORD"
	},
	{
			"value": "30 COUNT",
			"label": "30 COUNT"
	},
	{
			"value": "30CT",
			"label": "30CT"
	},
	{
			"value": "30FT",
			"label": "30FT"
	},
	{
			"value": "30 GM",
			"label": "30 GM"
	},
	{
			"value": "30 IN",
			"label": "30 IN"
	},
	{
			"value": "30IN",
			"label": "30IN"
	},
	{
			"value": "30 INCH",
			"label": "30 INCH"
	},
	{
			"value": "30 IN H",
			"label": "30 IN H"
	},
	{
			"value": "30IN X 15SQ FT",
			"label": "30IN X 15SQ FT"
	},
	{
			"value": "30IN X 25SQ FT",
			"label": "30IN X 25SQ FT"
	},
	{
			"value": "30IN X 40SQ FT",
			"label": "30IN X 40SQ FT"
	},
	{
			"value": "30LB",
			"label": "30LB"
	},
	{
			"value": "30 LITER",
			"label": "30 LITER"
	},
	{
			"value": "3.0L X 5.0W X 5.5H",
			"label": "3.0L X 5.0W X 5.5H"
	},
	{
			"value": "30M",
			"label": "30M"
	},
	{
			"value": "30MM",
			"label": "30MM"
	},
	{
			"value": "30MM DIAMETER, 60.9",
			"label": "30MM DIAMETER, 60.9"
	},
	{
			"value": "30MM DIAM. X 24   L",
			"label": "30MM DIAM. X 24   L"
	},
	{
			"value": "30MM X10M",
			"label": "30MM X10M"
	},
	{
			"value": "30MMX10M",
			"label": "30MMX10M"
	},
	{
			"value": "3.0 OZ",
			"label": "3.0 OZ"
	},
	{
			"value": "30 OZ",
			"label": "30 OZ"
	},
	{
			"value": "30 OZ.",
			"label": "30 OZ."
	},
	{
			"value": "30OZ",
			"label": "30OZ"
	},
	{
			"value": "3.0OZ",
			"label": "3.0OZ"
	},
	{
			"value": "30 PC",
			"label": "30 PC"
	},
	{
			"value": "30 PC.",
			"label": "30 PC."
	},
	{
			"value": "30 PCS",
			"label": "30 PCS"
	},
	{
			"value": "30 PCS.",
			"label": "30 PCS."
	},
	{
			"value": "30 SPOOL",
			"label": "30 SPOOL"
	},
	{
			"value": "30 SPOOLS",
			"label": "30 SPOOLS"
	},
	{
			"value": "30X0.75X12",
			"label": "30X0.75X12"
	},
	{
			"value": "30   X 10",
			"label": "30   X 10"
	},
	{
			"value": "30   X 10'",
			"label": "30   X 10'"
	},
	{
			"value": "30\"\" X 10FT",
			"label": "30\"\" X 10FT"
	},
	{
			"value": "30 X 13 X 11.25 IN",
			"label": "30 X 13 X 11.25 IN"
	},
	{
			"value": "30 X 13 X 13.25 IN",
			"label": "30 X 13 X 13.25 IN"
	},
	{
			"value": "30 X 13 X 13 IN",
			"label": "30 X 13 X 13 IN"
	},
	{
			"value": "30 X 13 X 14 IN",
			"label": "30 X 13 X 14 IN"
	},
	{
			"value": "30 X 13 X 15 IN",
			"label": "30 X 13 X 15 IN"
	},
	{
			"value": "30 X 15",
			"label": "30 X 15"
	},
	{
			"value": "30 X  16 MP",
			"label": "30 X  16 MP"
	},
	{
			"value": "30 X 18",
			"label": "30 X 18"
	},
	{
			"value": "30   X 18'",
			"label": "30   X 18'"
	},
	{
			"value": "30X20",
			"label": "30X20"
	},
	{
			"value": "30   X 20'",
			"label": "30   X 20'"
	},
	{
			"value": "30X22",
			"label": "30X22"
	},
	{
			"value": "30X2.375X30",
			"label": "30X2.375X30"
	},
	{
			"value": "30   X 25'",
			"label": "30   X 25'"
	},
	{
			"value": "30X30",
			"label": "30X30"
	},
	{
			"value": "30   X 30",
			"label": "30   X 30"
	},
	{
			"value": "30  X30",
			"label": "30  X30"
	},
	{
			"value": "30 X 36",
			"label": "30 X 36"
	},
	{
			"value": "30X40",
			"label": "30X40"
	},
	{
			"value": "30X4.375X24.5",
			"label": "30X4.375X24.5"
	},
	{
			"value": "30 X 5",
			"label": "30 X 5"
	},
	{
			"value": "30   X 5'",
			"label": "30   X 5'"
	},
	{
			"value": "30  X5'",
			"label": "30  X5'"
	},
	{
			"value": "30X5.5X30",
			"label": "30X5.5X30"
	},
	{
			"value": "30X84",
			"label": "30X84"
	},
	{
			"value": "30 YARDS",
			"label": "30 YARDS"
	},
	{
			"value": "30 YD",
			"label": "30 YD"
	},
	{
			"value": "30YD",
			"label": "30YD"
	},
	{
			"value": "30YDS",
			"label": "30YDS"
	},
	{
			"value": "31",
			"label": "31"
	},
	{
			"value": "3/10",
			"label": "3/10"
	},
	{
			"value": "3100",
			"label": "3100"
	},
	{
			"value": "31000",
			"label": "31000"
	},
	{
			"value": "31 1/2 X 31 1/2",
			"label": "31 1/2 X 31 1/2"
	},
	{
			"value": "311 GRAMS",
			"label": "311 GRAMS"
	},
	{
			"value": "3-1/2",
			"label": "3-1/2"
	},
	{
			"value": "31.25",
			"label": "31.25"
	},
	{
			"value": ".3125IN",
			"label": ".3125IN"
	},
	{
			"value": "3.125IN",
			"label": "3.125IN"
	},
	{
			"value": "3.125 IN W 5.25 IN H",
			"label": "3.125 IN W 5.25 IN H"
	},
	{
			"value": "3.125X3.125X3.875",
			"label": "3.125X3.125X3.875"
	},
	{
			"value": "3.125X3.125X5.125",
			"label": "3.125X3.125X5.125"
	},
	{
			"value": "3.125X3.125X6.25",
			"label": "3.125X3.125X6.25"
	},
	{
			"value": "3.125   X 6YD",
			"label": "3.125   X 6YD"
	},
	{
			"value": "3-1/2''H 2''W",
			"label": "3-1/2''H 2''W"
	},
	{
			"value": "3 1/2   X 12YDS",
			"label": "3 1/2   X 12YDS"
	},
	{
			"value": "3.12 X 2.12",
			"label": "3.12 X 2.12"
	},
	{
			"value": "3 1/4",
			"label": "3 1/4"
	},
	{
			"value": "3 1/4\"\"",
			"label": "3 1/4\"\""
	},
	{
			"value": "3-1/4",
			"label": "3-1/4"
	},
	{
			"value": "3-1/4  H 2-5/8  W",
			"label": "3-1/4  H 2-5/8  W"
	},
	{
			"value": "3-1/4''H 9''W",
			"label": "3-1/4''H 9''W"
	},
	{
			"value": "3 1/4 IN.",
			"label": "3 1/4 IN."
	},
	{
			"value": "3 1/4 (IN) X 3 1/2 (",
			"label": "3 1/4 (IN) X 3 1/2 ("
	},
	{
			"value": "3.14 X 3.14 X 3.14",
			"label": "3.14 X 3.14 X 3.14"
	},
	{
			"value": "3.15",
			"label": "3.15"
	},
	{
			"value": "31.5",
			"label": "31.5"
	},
	{
			"value": "31.52 IN",
			"label": "31.52 IN"
	},
	{
			"value": "31.5 IN",
			"label": "31.5 IN"
	},
	{
			"value": "31.5IN",
			"label": "31.5IN"
	},
	{
			"value": "31.5 OZ",
			"label": "31.5 OZ"
	},
	{
			"value": "31.5  (W) X 57.5  (",
			"label": "31.5  (W) X 57.5  ("
	},
	{
			"value": "31.5 X 4 X 2",
			"label": "31.5 X 4 X 2"
	},
	{
			"value": "3/16",
			"label": "3/16"
	},
	{
			"value": "3/16\"\"",
			"label": "3/16\"\""
	},
	{
			"value": "3/16   (6MM)",
			"label": "3/16   (6MM)"
	},
	{
			"value": "3/16 IN",
			"label": "3/16 IN"
	},
	{
			"value": "3/16  X100'",
			"label": "3/16  X100'"
	},
	{
			"value": "3/16 X 100YDS",
			"label": "3/16 X 100YDS"
	},
	{
			"value": "3/16  X100YDS",
			"label": "3/16  X100YDS"
	},
	{
			"value": "3/16 X 350YDS",
			"label": "3/16 X 350YDS"
	},
	{
			"value": "3/16   X 6FT",
			"label": "3/16   X 6FT"
	},
	{
			"value": "3.17 OZ",
			"label": "3.17 OZ"
	},
	{
			"value": "3.18 OZ",
			"label": "3.18 OZ"
	},
	{
			"value": "31 IN",
			"label": "31 IN"
	},
	{
			"value": "31IN",
			"label": "31IN"
	},
	{
			"value": "31 INCH",
			"label": "31 INCH"
	},
	{
			"value": "3.1 OZ",
			"label": "3.1 OZ"
	},
	{
			"value": "3.1 OZ.",
			"label": "3.1 OZ."
	},
	{
			"value": "3.1OZ",
			"label": "3.1OZ"
	},
	{
			"value": "31 X 17.5 X 26.5",
			"label": "31 X 17.5 X 26.5"
	},
	{
			"value": "32",
			"label": "32"
	},
	{
			"value": "320",
			"label": "320"
	},
	{
			"value": "3200",
			"label": "3200"
	},
	{
			"value": "32000",
			"label": "32000"
	},
	{
			"value": "32.25 OZ",
			"label": "32.25 OZ"
	},
	{
			"value": "3.2 2.6 6",
			"label": "3.2 2.6 6"
	},
	{
			"value": "(32-34-36)",
			"label": "(32-34-36)"
	},
	{
			"value": "324",
			"label": "324"
	},
	{
			"value": "-3240",
			"label": "-3240"
	},
	{
			"value": "3240",
			"label": "3240"
	},
	{
			"value": "3.24 OZ",
			"label": "3.24 OZ"
	},
	{
			"value": "3.25",
			"label": "3.25"
	},
	{
			"value": "32.5",
			"label": "32.5"
	},
	{
			"value": "325",
			"label": "325"
	},
	{
			"value": "3.25IN",
			"label": "3.25IN"
	},
	{
			"value": "32.5IN",
			"label": "32.5IN"
	},
	{
			"value": ".325 OZ",
			"label": ".325 OZ"
	},
	{
			"value": "3.25 OZ",
			"label": "3.25 OZ"
	},
	{
			"value": "3.25OZ",
			"label": "3.25OZ"
	},
	{
			"value": "3.25  W X 3.25  D X",
			"label": "3.25  W X 3.25  D X"
	},
	{
			"value": "3.25 X 0.375 X 3.75",
			"label": "3.25 X 0.375 X 3.75"
	},
	{
			"value": "3.25 X 1.125 X 3.25",
			"label": "3.25 X 1.125 X 3.25"
	},
	{
			"value": "3.25 X 1.125  X 3.25",
			"label": "3.25 X 1.125  X 3.25"
	},
	{
			"value": "3.25 X 1.133 3.25",
			"label": "3.25 X 1.133 3.25"
	},
	{
			"value": "3.25 X 1.175 X 3.25",
			"label": "3.25 X 1.175 X 3.25"
	},
	{
			"value": "3.25 X 1.3125 X 3.25",
			"label": "3.25 X 1.3125 X 3.25"
	},
	{
			"value": "3.25 X 1.31 X 3.25",
			"label": "3.25 X 1.31 X 3.25"
	},
	{
			"value": "3.25 X 1.375 X 3.25",
			"label": "3.25 X 1.375 X 3.25"
	},
	{
			"value": "3.25 X 1.375  X 3.25",
			"label": "3.25 X 1.375  X 3.25"
	},
	{
			"value": "3.25 X 1.50 3.25",
			"label": "3.25 X 1.50 3.25"
	},
	{
			"value": "3.25 X .1.50 X 3.25",
			"label": "3.25 X .1.50 X 3.25"
	},
	{
			"value": "3.25 X 1.50 X 3.25",
			"label": "3.25 X 1.50 X 3.25"
	},
	{
			"value": "3.25 X1.50  X  3.25",
			"label": "3.25 X1.50  X  3.25"
	},
	{
			"value": "3.25 X  1.5 X 3.25",
			"label": "3.25 X  1.5 X 3.25"
	},
	{
			"value": "3.25 X 1.5 X 3.25",
			"label": "3.25 X 1.5 X 3.25"
	},
	{
			"value": "3.25 X1.5 X 3.25",
			"label": "3.25 X1.5 X 3.25"
	},
	{
			"value": "3.25 X  1.5  X 3.25",
			"label": "3.25 X  1.5  X 3.25"
	},
	{
			"value": "3.25 X .1.5  X 3.25",
			"label": "3.25 X .1.5  X 3.25"
	},
	{
			"value": "3.25 X 1.5  X 3.25",
			"label": "3.25 X 1.5  X 3.25"
	},
	{
			"value": "3.25 X 1.625 X 3.25",
			"label": "3.25 X 1.625 X 3.25"
	},
	{
			"value": "3.25 X 1.625  X 3.25",
			"label": "3.25 X 1.625  X 3.25"
	},
	{
			"value": "3.25 X 1.75 X 3.25",
			"label": "3.25 X 1.75 X 3.25"
	},
	{
			"value": "3.25 X  1.75  X 3.25",
			"label": "3.25 X  1.75  X 3.25"
	},
	{
			"value": "3.25 X 2.00 X 3.25",
			"label": "3.25 X 2.00 X 3.25"
	},
	{
			"value": "3.25 X  .2.25 X 3.25",
			"label": "3.25 X  .2.25 X 3.25"
	},
	{
			"value": "3.25 X 2.25 X 3.25",
			"label": "3.25 X 2.25 X 3.25"
	},
	{
			"value": "3.25 X  2.375 X 3.25",
			"label": "3.25 X  2.375 X 3.25"
	},
	{
			"value": "3.25 X 2.375 X 3.25",
			"label": "3.25 X 2.375 X 3.25"
	},
	{
			"value": "3.25 X 2.50 X 3.25",
			"label": "3.25 X 2.50 X 3.25"
	},
	{
			"value": "3.25 X 2.5 3.25",
			"label": "3.25 X 2.5 3.25"
	},
	{
			"value": "3.25 X .25 X 3.25",
			"label": "3.25 X .25 X 3.25"
	},
	{
			"value": "3.25 X 2.5 X 3.25",
			"label": "3.25 X 2.5 X 3.25"
	},
	{
			"value": "3.25 X  2.5  X 3.25",
			"label": "3.25 X  2.5  X 3.25"
	},
	{
			"value": "3.25 X 2.5  X 3.25",
			"label": "3.25 X 2.5  X 3.25"
	},
	{
			"value": "3.25 X .3125 X 3.25",
			"label": "3.25 X .3125 X 3.25"
	},
	{
			"value": "3.25 X 3.25 X 8",
			"label": "3.25 X 3.25 X 8"
	},
	{
			"value": "3.25  X3.75",
			"label": "3.25  X3.75"
	},
	{
			"value": "3.25 X ..375 X 3.25",
			"label": "3.25 X ..375 X 3.25"
	},
	{
			"value": "3.25 X .375 X 3.25",
			"label": "3.25 X .375 X 3.25"
	},
	{
			"value": "3.25 X  .375  X 3.25",
			"label": "3.25 X  .375  X 3.25"
	},
	{
			"value": "3.25 X 3 X 3.25",
			"label": "3.25 X 3 X 3.25"
	},
	{
			"value": "3.25 X  3  X 3.25",
			"label": "3.25 X  3  X 3.25"
	},
	{
			"value": "3.25   X 3   X 3.25",
			"label": "3.25   X 3   X 3.25"
	},
	{
			"value": "3.25 X 4.5 INCHES",
			"label": "3.25 X 4.5 INCHES"
	},
	{
			"value": "3.25   X 4.5   X .1",
			"label": "3.25   X 4.5   X .1"
	},
	{
			"value": "3.25 X .50 X 3.25",
			"label": "3.25 X .50 X 3.25"
	},
	{
			"value": "3.25 X .50  X 3.25",
			"label": "3.25 X .50  X 3.25"
	},
	{
			"value": "3.25X5.625",
			"label": "3.25X5.625"
	},
	{
			"value": "3.25 X .5625 X 3.25",
			"label": "3.25 X .5625 X 3.25"
	},
	{
			"value": "3.25 X .5/8  X 3.25",
			"label": "3.25 X .5/8  X 3.25"
	},
	{
			"value": "3.25 X .5 X 3.25",
			"label": "3.25 X .5 X 3.25"
	},
	{
			"value": "3.25 X  .625 X 3.25",
			"label": "3.25 X  .625 X 3.25"
	},
	{
			"value": "3.25 X .625 X 3.25",
			"label": "3.25 X .625 X 3.25"
	},
	{
			"value": "3.25 X..625 X 3.25",
			"label": "3.25 X..625 X 3.25"
	},
	{
			"value": "3.25 X.625 X 3.25",
			"label": "3.25 X.625 X 3.25"
	},
	{
			"value": "3.25  X .625 X 3.25",
			"label": "3.25  X .625 X 3.25"
	},
	{
			"value": "3.25 X  .625  X 3.25",
			"label": "3.25 X  .625  X 3.25"
	},
	{
			"value": "3.25 X .625  X 3.25",
			"label": "3.25 X .625  X 3.25"
	},
	{
			"value": "3.25 X .75 X 3.25",
			"label": "3.25 X .75 X 3.25"
	},
	{
			"value": "3.25 X .75  X 3.25",
			"label": "3.25 X .75  X 3.25"
	},
	{
			"value": "3.25 X .75 X 5",
			"label": "3.25 X .75 X 5"
	},
	{
			"value": "3.25X7X4.75",
			"label": "3.25X7X4.75"
	},
	{
			"value": "3.25 X .825  X 3.25",
			"label": "3.25 X .825  X 3.25"
	},
	{
			"value": "3.25 X  .875 X 3.25",
			"label": "3.25 X  .875 X 3.25"
	},
	{
			"value": "3.25 X  .875X 3.25",
			"label": "3.25 X  .875X 3.25"
	},
	{
			"value": "3.25 X .875 X 3.25",
			"label": "3.25 X .875 X 3.25"
	},
	{
			"value": "3.25 X.875 X 3.25",
			"label": "3.25 X.875 X 3.25"
	},
	{
			"value": "3.25 X .875  X 3.25",
			"label": "3.25 X .875  X 3.25"
	},
	{
			"value": "3.25 X .88 X 3.25",
			"label": "3.25 X .88 X 3.25"
	},
	{
			"value": "325 YD",
			"label": "325 YD"
	},
	{
			"value": "325 YDS",
			"label": "325 YDS"
	},
	{
			"value": "3264",
			"label": "3264"
	},
	{
			"value": "32.75IN",
			"label": "32.75IN"
	},
	{
			"value": "3.27 OZ",
			"label": "3.27 OZ"
	},
	{
			"value": "3.27 X 3.27 X 4.92",
			"label": "3.27 X 3.27 X 4.92"
	},
	{
			"value": "3.27 X 3.27 X 7.01",
			"label": "3.27 X 3.27 X 7.01"
	},
	{
			"value": "3288",
			"label": "3288"
	},
	{
			"value": "32.98",
			"label": "32.98"
	},
	{
			"value": "3.29 OZ",
			"label": "3.29 OZ"
	},
	{
			"value": "32CT",
			"label": "32CT"
	},
	{
			"value": "32 IN",
			"label": "32 IN"
	},
	{
			"value": "32IN",
			"label": "32IN"
	},
	{
			"value": "32 INCH",
			"label": "32 INCH"
	},
	{
			"value": "32INCH",
			"label": "32INCH"
	},
	{
			"value": "32 INCHES",
			"label": "32 INCHES"
	},
	{
			"value": "32 INCH SIZE 0",
			"label": "32 INCH SIZE 0"
	},
	{
			"value": "32 INCH SIZE 1",
			"label": "32 INCH SIZE 1"
	},
	{
			"value": "32 INCH SIZE 10",
			"label": "32 INCH SIZE 10"
	},
	{
			"value": "32 INCH SIZE 11",
			"label": "32 INCH SIZE 11"
	},
	{
			"value": "32 INCH SIZE 15",
			"label": "32 INCH SIZE 15"
	},
	{
			"value": "32 INCH SIZE 2",
			"label": "32 INCH SIZE 2"
	},
	{
			"value": "32 INCH SIZE 3",
			"label": "32 INCH SIZE 3"
	},
	{
			"value": "32 INCH SIZE 4",
			"label": "32 INCH SIZE 4"
	},
	{
			"value": "32 INCH SIZE 5",
			"label": "32 INCH SIZE 5"
	},
	{
			"value": "32 INCH SIZE 6",
			"label": "32 INCH SIZE 6"
	},
	{
			"value": "32   L X 40   W",
			"label": "32   L X 40   W"
	},
	{
			"value": "32-OUNCES",
			"label": "32-OUNCES"
	},
	{
			"value": ".32 OZ",
			"label": ".32 OZ"
	},
	{
			"value": "3.2 OZ",
			"label": "3.2 OZ"
	},
	{
			"value": "32 OZ",
			"label": "32 OZ"
	},
	{
			"value": "32OZ",
			"label": "32OZ"
	},
	{
			"value": "3.2OZ",
			"label": "3.2OZ"
	},
	{
			"value": "32PC",
			"label": "32PC"
	},
	{
			"value": "32 QT",
			"label": "32 QT"
	},
	{
			"value": "32 SPOOLS 14  X14",
			"label": "32 SPOOLS 14  X14"
	},
	{
			"value": "32 TULLE SPOOLS",
			"label": "32 TULLE SPOOLS"
	},
	{
			"value": "32 X 15",
			"label": "32 X 15"
	},
	{
			"value": "32 X 18",
			"label": "32 X 18"
	},
	{
			"value": "32X32",
			"label": "32X32"
	},
	{
			"value": "3.3\"\"",
			"label": "3.3\"\""
	},
	{
			"value": "33",
			"label": "33"
	},
	{
			"value": "330",
			"label": "330"
	},
	{
			"value": "3300",
			"label": "3300"
	},
	{
			"value": "33000",
			"label": "33000"
	},
	{
			"value": "3312",
			"label": "3312"
	},
	{
			"value": "3 3/16",
			"label": "3 3/16"
	},
	{
			"value": "333.44 OZ",
			"label": "333.44 OZ"
	},
	{
			"value": "3.33 OZ",
			"label": "3.33 OZ"
	},
	{
			"value": "33.3OZ",
			"label": "33.3OZ"
	},
	{
			"value": "3.33 X 3.33 X 3.34",
			"label": "3.33 X 3.33 X 3.34"
	},
	{
			"value": "3348",
			"label": "3348"
	},
	{
			"value": "3-3/4  H   2-1/8  W",
			"label": "3-3/4  H   2-1/8  W"
	},
	{
			"value": "3 3/4   LONG",
			"label": "3 3/4   LONG"
	},
	{
			"value": "3 3/4   X 2 1/8",
			"label": "3 3/4   X 2 1/8"
	},
	{
			"value": "33.5",
			"label": "33.5"
	},
	{
			"value": "3350",
			"label": "3350"
	},
	{
			"value": "33.5IN",
			"label": "33.5IN"
	},
	{
			"value": "3.35 OZ",
			"label": "3.35 OZ"
	},
	{
			"value": "33.5 OZ",
			"label": "33.5 OZ"
	},
	{
			"value": "3.35OZ/95G",
			"label": "3.35OZ/95G"
	},
	{
			"value": "3.35X3.35X8.86",
			"label": "3.35X3.35X8.86"
	},
	{
			"value": "3.375",
			"label": "3.375"
	},
	{
			"value": "3.375 OZ",
			"label": "3.375 OZ"
	},
	{
			"value": "3.375OZ",
			"label": "3.375OZ"
	},
	{
			"value": "3.375X17X1.375",
			"label": "3.375X17X1.375"
	},
	{
			"value": "3.37OZ",
			"label": "3.37OZ"
	},
	{
			"value": "3.37 X 3.50",
			"label": "3.37 X 3.50"
	},
	{
			"value": "3384",
			"label": "3384"
	},
	{
			"value": "33.8 FL OZ",
			"label": "33.8 FL OZ"
	},
	{
			"value": "3.38 OUNCE",
			"label": "3.38 OUNCE"
	},
	{
			"value": ".338 OZ",
			"label": ".338 OZ"
	},
	{
			"value": "3.38 OZ",
			"label": "3.38 OZ"
	},
	{
			"value": "3.38 OZ BOTTLE",
			"label": "3.38 OZ BOTTLE"
	},
	{
			"value": "3.38X9.5X11.5",
			"label": "3.38X9.5X11.5"
	},
	{
			"value": "3.39 OZ",
			"label": "3.39 OZ"
	},
	{
			"value": "33 IN",
			"label": "33 IN"
	},
	{
			"value": "33IN",
			"label": "33IN"
	},
	{
			"value": "33 INCH",
			"label": "33 INCH"
	},
	{
			"value": ".33 OZ",
			"label": ".33 OZ"
	},
	{
			"value": ".33OZ",
			"label": ".33OZ"
	},
	{
			"value": "3.3 OZ",
			"label": "3.3 OZ"
	},
	{
			"value": "33 SPOOL",
			"label": "33 SPOOL"
	},
	{
			"value": "33WX4DX49H",
			"label": "33WX4DX49H"
	},
	{
			"value": "33WX4DX59H",
			"label": "33WX4DX59H"
	},
	{
			"value": "33WX4DX64H",
			"label": "33WX4DX64H"
	},
	{
			"value": "33WX4DX79H",
			"label": "33WX4DX79H"
	},
	{
			"value": "33WX6DX59H",
			"label": "33WX6DX59H"
	},
	{
			"value": "33WX6DX64H",
			"label": "33WX6DX64H"
	},
	{
			"value": "33WX6DX82H",
			"label": "33WX6DX82H"
	},
	{
			"value": "3.3 X 1.1 X 6.7",
			"label": "3.3 X 1.1 X 6.7"
	},
	{
			"value": "3.3X3.3X3.4",
			"label": "3.3X3.3X3.4"
	},
	{
			"value": "3.3X3.3X4.3",
			"label": "3.3X3.3X4.3"
	},
	{
			"value": "3.3X3.3X4.5",
			"label": "3.3X3.3X4.5"
	},
	{
			"value": "3.3X3.3X5.5",
			"label": "3.3X3.3X5.5"
	},
	{
			"value": "3.3  X3.9  X15MM",
			"label": "3.3  X3.9  X15MM"
	},
	{
			"value": "3/4",
			"label": "3/4"
	},
	{
			"value": "3/4\"\"",
			"label": "3/4\"\""
	},
	{
			"value": "34",
			"label": "34"
	},
	{
			"value": "340",
			"label": "340"
	},
	{
			"value": "3400",
			"label": "3400"
	},
	{
			"value": "340 GR",
			"label": "340 GR"
	},
	{
			"value": "340GR",
			"label": "340GR"
	},
	{
			"value": "34 1/2",
			"label": "34 1/2"
	},
	{
			"value": "3/4   (19MM)",
			"label": "3/4   (19MM)"
	},
	{
			"value": "3/4 (19MM)",
			"label": "3/4 (19MM)"
	},
	{
			"value": "34.5IN",
			"label": "34.5IN"
	},
	{
			"value": "3.45 OZ",
			"label": "3.45 OZ"
	},
	{
			"value": "34.5X0.25X12.75",
			"label": "34.5X0.25X12.75"
	},
	{
			"value": " 3,4,6",
			"label": " 3,4,6"
	},
	{
			"value": "3 - 48   PAPER COLO",
			"label": "3 - 48   PAPER COLO"
	},
	{
			"value": "34.98",
			"label": "34.98"
	},
	{
			"value": "3.49 OZ",
			"label": "3.49 OZ"
	},
	{
			"value": "3/4   DIAMETER  84-",
			"label": "3/4   DIAMETER  84-"
	},
	{
			"value": "34G",
			"label": "34G"
	},
	{
			"value": "3/4 IN.",
			"label": "3/4 IN."
	},
	{
			"value": "34 IN",
			"label": "34 IN"
	},
	{
			"value": "34IN",
			"label": "34IN"
	},
	{
			"value": "3/4 INCH",
			"label": "3/4 INCH"
	},
	{
			"value": "34 INCH",
			"label": "34 INCH"
	},
	{
			"value": "3/4IN X 144YD",
			"label": "3/4IN X 144YD"
	},
	{
			"value": "3/4 IN. X 24 IN.",
			"label": "3/4 IN. X 24 IN."
	},
	{
			"value": "3/4 IN X 3 IN",
			"label": "3/4 IN X 3 IN"
	},
	{
			"value": "3/4IN X 650 IN",
			"label": "3/4IN X 650 IN"
	},
	{
			"value": "34MM",
			"label": "34MM"
	},
	{
			"value": "3.4 OZ",
			"label": "3.4 OZ"
	},
	{
			"value": "34 OZ",
			"label": "34 OZ"
	},
	{
			"value": "3.4OZ",
			"label": "3.4OZ"
	},
	{
			"value": "3.4 OZ/ 95 GRAMS",
			"label": "3.4 OZ/ 95 GRAMS"
	},
	{
			"value": "3.4 X 1.22 X 4.7",
			"label": "3.4 X 1.22 X 4.7"
	},
	{
			"value": "3.4 X 1.2 X 4.7",
			"label": "3.4 X 1.2 X 4.7"
	},
	{
			"value": "3/4   X 12YDS",
			"label": "3/4   X 12YDS"
	},
	{
			"value": "34X17",
			"label": "34X17"
	},
	{
			"value": "34 X 18",
			"label": "34 X 18"
	},
	{
			"value": "34 X 19.5",
			"label": "34 X 19.5"
	},
	{
			"value": "3.4 X 1.9 X 1.3",
			"label": "3.4 X 1.9 X 1.3"
	},
	{
			"value": "3/4   X 1 YARD",
			"label": "3/4   X 1 YARD"
	},
	{
			"value": "3/4   X 25'",
			"label": "3/4   X 25'"
	},
	{
			"value": "3/4   X 25YD",
			"label": "3/4   X 25YD"
	},
	{
			"value": "3/4    X 350 IN.C",
			"label": "3/4    X 350 IN.C"
	},
	{
			"value": "34 X 36",
			"label": "34 X 36"
	},
	{
			"value": "3/4   X 36 YD",
			"label": "3/4   X 36 YD"
	},
	{
			"value": "3/4   X 3FT",
			"label": "3/4   X 3FT"
	},
	{
			"value": "34X45",
			"label": "34X45"
	},
	{
			"value": "3/4   X 4FT",
			"label": "3/4   X 4FT"
	},
	{
			"value": "3/4  X 600",
			"label": "3/4  X 600"
	},
	{
			"value": "3/4  X600",
			"label": "3/4  X600"
	},
	{
			"value": "3/4 X 650 IN",
			"label": "3/4 X 650 IN"
	},
	{
			"value": "3.4 X 6.875",
			"label": "3.4 X 6.875"
	},
	{
			"value": "3.4  X 6YDS",
			"label": "3.4  X 6YDS"
	},
	{
			"value": "34   X 77",
			"label": "34   X 77"
	},
	{
			"value": "34  X77X1",
			"label": "34  X77X1"
	},
	{
			"value": "3/4   X 900",
			"label": "3/4   X 900"
	},
	{
			"value": "34YDS",
			"label": "34YDS"
	},
	{
			"value": ".35",
			"label": ".35"
	},
	{
			"value": "3-5",
			"label": "3-5"
	},
	{
			"value": "3.5",
			"label": "3.5"
	},
	{
			"value": "3.5\"\"",
			"label": "3.5\"\""
	},
	{
			"value": "35",
			"label": "35"
	},
	{
			"value": "35'",
			"label": "35'"
	},
	{
			"value": "350",
			"label": "350"
	},
	{
			"value": "3500",
			"label": "3500"
	},
	{
			"value": "35000",
			"label": "35000"
	},
	{
			"value": "3504",
			"label": "3504"
	},
	{
			"value": "350CT",
			"label": "350CT"
	},
	{
			"value": "3.50 X 3.25",
			"label": "3.50 X 3.25"
	},
	{
			"value": "3.50 X 3.37",
			"label": "3.50 X 3.37"
	},
	{
			"value": "3.50 X 3.375",
			"label": "3.50 X 3.375"
	},
	{
			"value": "3.50X4.56X2.25",
			"label": "3.50X4.56X2.25"
	},
	{
			"value": "3.50 X 5.19",
			"label": "3.50 X 5.19"
	},
	{
			"value": "3.50 X 6.25",
			"label": "3.50 X 6.25"
	},
	{
			"value": "350 YARDS",
			"label": "350 YARDS"
	},
	{
			"value": "350 YD",
			"label": "350 YD"
	},
	{
			"value": "350 YDS",
			"label": "350 YDS"
	},
	{
			"value": "3.5/100GM",
			"label": "3.5/100GM"
	},
	{
			"value": "3.5/100 GRAMS",
			"label": "3.5/100 GRAMS"
	},
	{
			"value": "3516",
			"label": "3516"
	},
	{
			"value": "3.52 OZ",
			"label": "3.52 OZ"
	},
	{
			"value": "3.53",
			"label": "3.53"
	},
	{
			"value": "3.5 3.5 4.3",
			"label": "3.5 3.5 4.3"
	},
	{
			"value": "3.53 OZ",
			"label": "3.53 OZ"
	},
	{
			"value": "3.53 OZ.",
			"label": "3.53 OZ."
	},
	{
			"value": "3.54 X 1.73 X .19",
			"label": "3.54 X 1.73 X .19"
	},
	{
			"value": "3.54 X 2.6 X .8",
			"label": "3.54 X 2.6 X .8"
	},
	{
			"value": "35.5",
			"label": "35.5"
	},
	{
			"value": "35.5 X 13 .5 X 35.8",
			"label": "35.5 X 13 .5 X 35.8"
	},
	{
			"value": "3.55   X 2.6",
			"label": "3.55   X 2.6"
	},
	{
			"value": "3.55   X 5.9",
			"label": "3.55   X 5.9"
	},
	{
			"value": "3.562 X .625 X 5.5",
			"label": "3.562 X .625 X 5.5"
	},
	{
			"value": "3.568 OZ",
			"label": "3.568 OZ"
	},
	{
			"value": "356MM",
			"label": "356MM"
	},
	{
			"value": "3 5 .75",
			"label": "3 5 .75"
	},
	{
			"value": "35.75X10.5X1.875",
			"label": "35.75X10.5X1.875"
	},
	{
			"value": "3-5/8",
			"label": "3-5/8"
	},
	{
			"value": "3.58 X 3.38",
			"label": "3.58 X 3.38"
	},
	{
			"value": "3.58X4.88",
			"label": "3.58X4.88"
	},
	{
			"value": "3590",
			"label": "3590"
	},
	{
			"value": "3.5G/100G",
			"label": "3.5G/100G"
	},
	{
			"value": "3.5 IN",
			"label": "3.5 IN"
	},
	{
			"value": "35IN",
			"label": "35IN"
	},
	{
			"value": "3.5IN",
			"label": "3.5IN"
	},
	{
			"value": "3.5 INCH",
			"label": "3.5 INCH"
	},
	{
			"value": "3.5 INCH X 3FT",
			"label": "3.5 INCH X 3FT"
	},
	{
			"value": "3,5 IZ",
			"label": "3,5 IZ"
	},
	{
			"value": "35 LITER",
			"label": "35 LITER"
	},
	{
			"value": "35MM",
			"label": "35MM"
	},
	{
			"value": ".35 OZ",
			"label": ".35 OZ"
	},
	{
			"value": ".35OZ",
			"label": ".35OZ"
	},
	{
			"value": "3.5 OZ",
			"label": "3.5 OZ"
	},
	{
			"value": "3.5 OZ.",
			"label": "3.5 OZ."
	},
	{
			"value": "3.5OZ",
			"label": "3.5OZ"
	},
	{
			"value": "35OZ/1000G",
			"label": "35OZ/1000G"
	},
	{
			"value": "35OZ/100G",
			"label": "35OZ/100G"
	},
	{
			"value": "3.5OZ/100G",
			"label": "3.5OZ/100G"
	},
	{
			"value": "3.5 OZ/100GM",
			"label": "3.5 OZ/100GM"
	},
	{
			"value": "3.5 OZ/100 GRAMS",
			"label": "3.5 OZ/100 GRAMS"
	},
	{
			"value": "3.5OZ/100 GRAMS",
			"label": "3.5OZ/100 GRAMS"
	},
	{
			"value": "35PC",
			"label": "35PC"
	},
	{
			"value": "35 QT",
			"label": "35 QT"
	},
	{
			"value": "35 STICKS",
			"label": "35 STICKS"
	},
	{
			"value": "3.5  W X 3.5  D X 3",
			"label": "3.5  W X 3.5  D X 3"
	},
	{
			"value": "3.5X0.19X6.5",
			"label": "3.5X0.19X6.5"
	},
	{
			"value": "3.5X1.5X5",
			"label": "3.5X1.5X5"
	},
	{
			"value": "3.5 X 1.625",
			"label": "3.5 X 1.625"
	},
	{
			"value": "3.5  X 1.625",
			"label": "3.5  X 1.625"
	},
	{
			"value": "3.5 X 1.75 X .15",
			"label": "3.5 X 1.75 X .15"
	},
	{
			"value": "3.5 X 1.75 X .45",
			"label": "3.5 X 1.75 X .45"
	},
	{
			"value": "3.5X2.2",
			"label": "3.5X2.2"
	},
	{
			"value": "3.5 X 2.25",
			"label": "3.5 X 2.25"
	},
	{
			"value": "3.5   X 2.25   X 2.",
			"label": "3.5   X 2.25   X 2."
	},
	{
			"value": "3.5 X 2.3 X .6",
			"label": "3.5 X 2.3 X .6"
	},
	{
			"value": "3.5X2.5",
			"label": "3.5X2.5"
	},
	{
			"value": "3.5 X 2.5 INCHES",
			"label": "3.5 X 2.5 INCHES"
	},
	{
			"value": "3.5X2.5X1.5",
			"label": "3.5X2.5X1.5"
	},
	{
			"value": "3.5 X 2.5 X 3.25",
			"label": "3.5 X 2.5 X 3.25"
	},
	{
			"value": "3.5 X 2.625 X 0.2",
			"label": "3.5 X 2.625 X 0.2"
	},
	{
			"value": "3.5   X 2.7",
			"label": "3.5   X 2.7"
	},
	{
			"value": "3.5 X 2.875",
			"label": "3.5 X 2.875"
	},
	{
			"value": "3.5    X3",
			"label": "3.5    X3"
	},
	{
			"value": "35X35",
			"label": "35X35"
	},
	{
			"value": "3.5   X 3.5",
			"label": "3.5   X 3.5"
	},
	{
			"value": "3.5X3.5",
			"label": "3.5X3.5"
	},
	{
			"value": "3.5   X 3.5   X 13",
			"label": "3.5   X 3.5   X 13"
	},
	{
			"value": "3.5 X 3.5 X 2.7",
			"label": "3.5 X 3.5 X 2.7"
	},
	{
			"value": "3.5 X 3.5 X 3.5",
			"label": "3.5 X 3.5 X 3.5"
	},
	{
			"value": "3.5 X 3.5 X 4.13",
			"label": "3.5 X 3.5 X 4.13"
	},
	{
			"value": "35 X 36",
			"label": "35 X 36"
	},
	{
			"value": "3.5   X 4.097",
			"label": "3.5   X 4.097"
	},
	{
			"value": "3.5   X 4.18",
			"label": "3.5   X 4.18"
	},
	{
			"value": "3.5X4.25",
			"label": "3.5X4.25"
	},
	{
			"value": "3.5   X 4.26",
			"label": "3.5   X 4.26"
	},
	{
			"value": "3.5   X 4.36",
			"label": "3.5   X 4.36"
	},
	{
			"value": "3.5 X 4.5",
			"label": "3.5 X 4.5"
	},
	{
			"value": "3.5 X 4.50 X .09",
			"label": "3.5 X 4.50 X .09"
	},
	{
			"value": "3.5 X 4.75",
			"label": "3.5 X 4.75"
	},
	{
			"value": "3.5X5",
			"label": "3.5X5"
	},
	{
			"value": "3.5 X 5.19",
			"label": "3.5 X 5.19"
	},
	{
			"value": "3.5 X 5.5",
			"label": "3.5 X 5.5"
	},
	{
			"value": "3.5  X5.5",
			"label": "3.5  X5.5"
	},
	{
			"value": "3.5X5.75",
			"label": "3.5X5.75"
	},
	{
			"value": "3.5X5 BLACK",
			"label": "3.5X5 BLACK"
	},
	{
			"value": "3.5X5 ESPRESSO",
			"label": "3.5X5 ESPRESSO"
	},
	{
			"value": "3.5 X 6",
			"label": "3.5 X 6"
	},
	{
			"value": "3.5   X 6",
			"label": "3.5   X 6"
	},
	{
			"value": "3.5X6.0",
			"label": "3.5X6.0"
	},
	{
			"value": "3.5 X 6.5 X 0.25 INC",
			"label": "3.5 X 6.5 X 0.25 INC"
	},
	{
			"value": "35 X 6 X 0.01",
			"label": "35 X 6 X 0.01"
	},
	{
			"value": "3.5   X 6YD",
			"label": "3.5   X 6YD"
	},
	{
			"value": "3.5X7",
			"label": "3.5X7"
	},
	{
			"value": "35X70 WITH CLIPS",
			"label": "35X70 WITH CLIPS"
	},
	{
			"value": "3.5 X .75 X 4.5",
			"label": "3.5 X .75 X 4.5"
	},
	{
			"value": "3.5   X 9.5",
			"label": "3.5   X 9.5"
	},
	{
			"value": "3-6",
			"label": "3-6"
	},
	{
			"value": "36",
			"label": "36"
	},
	{
			"value": "360",
			"label": "360"
	},
	{
			"value": "3600",
			"label": "3600"
	},
	{
			"value": "36000",
			"label": "36000"
	},
	{
			"value": "360IN",
			"label": "360IN"
	},
	{
			"value": "3.62 X 3.25",
			"label": "3.62 X 3.25"
	},
	{
			"value": "3.62X4.94X0.38",
			"label": "3.62X4.94X0.38"
	},
	{
			"value": "364",
			"label": "364"
	},
	{
			"value": "3648",
			"label": "3648"
	},
	{
			"value": "36.5IN",
			"label": "36.5IN"
	},
	{
			"value": "36.6",
			"label": "36.6"
	},
	{
			"value": "36-63",
			"label": "36-63"
	},
	{
			"value": "36-66",
			"label": "36-66"
	},
	{
			"value": "3666",
			"label": "3666"
	},
	{
			"value": "36-66 LENGTH",
			"label": "36-66 LENGTH"
	},
	{
			"value": "3-6, 6-9, 9-12",
			"label": "3-6, 6-9, 9-12"
	},
	{
			"value": "3.67",
			"label": "3.67"
	},
	{
			"value": "3.6875 X 6",
			"label": "3.6875 X 6"
	},
	{
			"value": "36CT",
			"label": "36CT"
	},
	{
			"value": "36 EACH",
			"label": "36 EACH"
	},
	{
			"value": "36 IN",
			"label": "36 IN"
	},
	{
			"value": "36IN",
			"label": "36IN"
	},
	{
			"value": "36 INCH",
			"label": "36 INCH"
	},
	{
			"value": "36INCH",
			"label": "36INCH"
	},
	{
			"value": "3-6M",
			"label": "3-6M"
	},
	{
			"value": "3-6 MONTH",
			"label": "3-6 MONTH"
	},
	{
			"value": "3.6 OZ",
			"label": "3.6 OZ"
	},
	{
			"value": "3.6 OZ.",
			"label": "3.6 OZ."
	},
	{
			"value": "36 PC",
			"label": "36 PC"
	},
	{
			"value": "36X10",
			"label": "36X10"
	},
	{
			"value": "36  X 10 YDS",
			"label": "36  X 10 YDS"
	},
	{
			"value": "36  X10YDS",
			"label": "36  X10YDS"
	},
	{
			"value": "36X1.25X6",
			"label": "36X1.25X6"
	},
	{
			"value": "36 X 15",
			"label": "36 X 15"
	},
	{
			"value": "36 X 16",
			"label": "36 X 16"
	},
	{
			"value": "36 X 18",
			"label": "36 X 18"
	},
	{
			"value": "3.6   X 1.9   X 3.6",
			"label": "3.6   X 1.9   X 3.6"
	},
	{
			"value": "36X22X2",
			"label": "36X22X2"
	},
	{
			"value": "36   X 22   X 2",
			"label": "36   X 22   X 2"
	},
	{
			"value": "36X22X4",
			"label": "36X22X4"
	},
	{
			"value": "36   X 22   X 4",
			"label": "36   X 22   X 4"
	},
	{
			"value": "36X24",
			"label": "36X24"
	},
	{
			"value": "36   X 24",
			"label": "36   X 24"
	},
	{
			"value": "3.6   X 2.7",
			"label": "3.6   X 2.7"
	},
	{
			"value": "36 X 36",
			"label": "36 X 36"
	},
	{
			"value": "3.6 X 3.6 X 3",
			"label": "3.6 X 3.6 X 3"
	},
	{
			"value": "3.6X3.6X4.5",
			"label": "3.6X3.6X4.5"
	},
	{
			"value": "3.6X3.6X6.9",
			"label": "3.6X3.6X6.9"
	},
	{
			"value": "36   X 42",
			"label": "36   X 42"
	},
	{
			"value": "36   X 45",
			"label": "36   X 45"
	},
	{
			"value": "36X46",
			"label": "36X46"
	},
	{
			"value": "36X48",
			"label": "36X48"
	},
	{
			"value": "36 X 50 INCHES",
			"label": "36 X 50 INCHES"
	},
	{
			"value": "36X6",
			"label": "36X6"
	},
	{
			"value": "36X60",
			"label": "36X60"
	},
	{
			"value": "36   X 60",
			"label": "36   X 60"
	},
	{
			"value": "3.6X7.5X.125",
			"label": "3.6X7.5X.125"
	},
	{
			"value": "3.6X7.5X.25",
			"label": "3.6X7.5X.25"
	},
	{
			"value": "36YD",
			"label": "36YD"
	},
	{
			"value": "37",
			"label": "37"
	},
	{
			"value": "3700",
			"label": "3700"
	},
	{
			"value": "37000",
			"label": "37000"
	},
	{
			"value": "3.72 OZ",
			"label": "3.72 OZ"
	},
	{
			"value": "3.74 X 1.13 X 4.94",
			"label": "3.74 X 1.13 X 4.94"
	},
	{
			"value": "3.74 X 3.63 X 3.93",
			"label": "3.74 X 3.63 X 3.93"
	},
	{
			"value": "3.74 X 3.74 X 3.93",
			"label": "3.74 X 3.74 X 3.93"
	},
	{
			"value": ".375",
			"label": ".375"
	},
	{
			"value": "3.75",
			"label": "3.75"
	},
	{
			"value": "375",
			"label": "375"
	},
	{
			"value": "3750",
			"label": "3750"
	},
	{
			"value": " .375, .625, .875, 1",
			"label": " .375, .625, .875, 1"
	},
	{
			"value": " .375, .625 AND .875",
			"label": " .375, .625 AND .875"
	},
	{
			"value": "3.75IN",
			"label": "3.75IN"
	},
	{
			"value": ".375 IN X 12 FT",
			"label": ".375 IN X 12 FT"
	},
	{
			"value": ".375 IN. X 12 FT.",
			"label": ".375 IN. X 12 FT."
	},
	{
			"value": "3.75 OZ",
			"label": "3.75 OZ"
	},
	{
			"value": "3.75OZ",
			"label": "3.75OZ"
	},
	{
			"value": "3.75  W X 3.75  D X",
			"label": "3.75  W X 3.75  D X"
	},
	{
			"value": "3.75 X 10.5 X .125",
			"label": "3.75 X 10.5 X .125"
	},
	{
			"value": "3.75 X .125 X 4",
			"label": "3.75 X .125 X 4"
	},
	{
			"value": "3.75 X 1.75 X .25",
			"label": "3.75 X 1.75 X .25"
	},
	{
			"value": "3.75 X 2.50",
			"label": "3.75 X 2.50"
	},
	{
			"value": "3.75 X 2.50 X .25",
			"label": "3.75 X 2.50 X .25"
	},
	{
			"value": "3.75 X 2.5 X .25",
			"label": "3.75 X 2.5 X .25"
	},
	{
			"value": "3.75 X .25 X 4",
			"label": "3.75 X .25 X 4"
	},
	{
			"value": "3.75 X 2.5 X 7.25",
			"label": "3.75 X 2.5 X 7.25"
	},
	{
			"value": "3.75   X 2.75",
			"label": "3.75   X 2.75"
	},
	{
			"value": "3.75 X 2.75 X .25",
			"label": "3.75 X 2.75 X .25"
	},
	{
			"value": "3.75X3.75 OPENING",
			"label": "3.75X3.75 OPENING"
	},
	{
			"value": "3.75 X 3.75 X 3.5",
			"label": "3.75 X 3.75 X 3.5"
	},
	{
			"value": "3.75 X 3.75 X 4",
			"label": "3.75 X 3.75 X 4"
	},
	{
			"value": "3.75X4",
			"label": "3.75X4"
	},
	{
			"value": "3.75   X 4.75   X .",
			"label": "3.75   X 4.75   X ."
	},
	{
			"value": "3.75   X 4.75   X 1",
			"label": "3.75   X 4.75   X 1"
	},
	{
			"value": "3.75   X 4   X .5",
			"label": "3.75   X 4   X .5"
	},
	{
			"value": "3.75   X 5   X .5",
			"label": "3.75   X 5   X .5"
	},
	{
			"value": ".375   X 6FT",
			"label": ".375   X 6FT"
	},
	{
			"value": ".375   X 6YD",
			"label": ".375   X 6YD"
	},
	{
			"value": ".375   X 6YDS",
			"label": ".375   X 6YDS"
	},
	{
			"value": "3.75 X 8.5 X 1.75",
			"label": "3.75 X 8.5 X 1.75"
	},
	{
			"value": "375 YD",
			"label": "375 YD"
	},
	{
			"value": "375 YDS",
			"label": "375 YDS"
	},
	{
			"value": "3768",
			"label": "3768"
	},
	{
			"value": "3.76 X 0.78 X 3.88",
			"label": "3.76 X 0.78 X 3.88"
	},
	{
			"value": "3780",
			"label": "3780"
	},
	{
			"value": "3.78 OZ",
			"label": "3.78 OZ"
	},
	{
			"value": "3.79 X 0.77 X 4.91",
			"label": "3.79 X 0.77 X 4.91"
	},
	{
			"value": "37 IN",
			"label": "37 IN"
	},
	{
			"value": "37IN",
			"label": "37IN"
	},
	{
			"value": "37 INCH",
			"label": "37 INCH"
	},
	{
			"value": "37ML",
			"label": "37ML"
	},
	{
			"value": ".37 OZ",
			"label": ".37 OZ"
	},
	{
			"value": "3.7 OZ",
			"label": "3.7 OZ"
	},
	{
			"value": "3.7 OZ.",
			"label": "3.7 OZ."
	},
	{
			"value": "3.7   X 1.35",
			"label": "3.7   X 1.35"
	},
	{
			"value": "3 8\"\"",
			"label": "3 8\"\""
	},
	{
			"value": "3/8",
			"label": "3/8"
	},
	{
			"value": "3/8\"\"",
			"label": "3/8\"\""
	},
	{
			"value": "38",
			"label": "38"
	},
	{
			"value": "3800",
			"label": "3800"
	},
	{
			"value": "38000",
			"label": "38000"
	},
	{
			"value": "3804",
			"label": "3804"
	},
	{
			"value": "3/81/8   X 2 YARDS",
			"label": "3/81/8   X 2 YARDS"
	},
	{
			"value": "3/8 250YDS",
			"label": "3/8 250YDS"
	},
	{
			"value": "3.8 2.8 5.4",
			"label": "3.8 2.8 5.4"
	},
	{
			"value": "(38-34-36)",
			"label": "(38-34-36)"
	},
	{
			"value": "3840",
			"label": "3840"
	},
	{
			"value": "(38-40-42)",
			"label": "(38-40-42)"
	},
	{
			"value": "38.5IN",
			"label": "38.5IN"
	},
	{
			"value": "38.5  (W) X 28  (H)",
			"label": "38.5  (W) X 28  (H)"
	},
	{
			"value": "3.875",
			"label": "3.875"
	},
	{
			"value": "3.875   X 4.875",
			"label": "3.875   X 4.875"
	},
	{
			"value": "3888",
			"label": "3888"
	},
	{
			"value": "3.88   X 2.88",
			"label": "3.88   X 2.88"
	},
	{
			"value": "3.88   X 4.375",
			"label": "3.88   X 4.375"
	},
	{
			"value": "3.88 X 4.88",
			"label": "3.88 X 4.88"
	},
	{
			"value": "3/8   (9MM)",
			"label": "3/8   (9MM)"
	},
	{
			"value": "3/8 (9MM)",
			"label": "3/8 (9MM)"
	},
	{
			"value": "3/8 IN",
			"label": "3/8 IN"
	},
	{
			"value": "3/8 IN.",
			"label": "3/8 IN."
	},
	{
			"value": "38IN",
			"label": "38IN"
	},
	{
			"value": "3/8IN",
			"label": "3/8IN"
	},
	{
			"value": "38 INCH",
			"label": "38 INCH"
	},
	{
			"value": "3/8IN X 144YD",
			"label": "3/8IN X 144YD"
	},
	{
			"value": "38MM",
			"label": "38MM"
	},
	{
			"value": ".38 OZ",
			"label": ".38 OZ"
	},
	{
			"value": "3.8 OZ",
			"label": "3.8 OZ"
	},
	{
			"value": "3.8 OZ.",
			"label": "3.8 OZ."
	},
	{
			"value": "38 PC",
			"label": "38 PC"
	},
	{
			"value": "3 8TH",
			"label": "3 8TH"
	},
	{
			"value": "3 8TH INCH",
			"label": "3 8TH INCH"
	},
	{
			"value": "3 8TH X 9 FT",
			"label": "3 8TH X 9 FT"
	},
	{
			"value": "3 8TH X 9FT",
			"label": "3 8TH X 9FT"
	},
	{
			"value": "3/8TH X 9FT",
			"label": "3/8TH X 9FT"
	},
	{
			"value": "38 X 12",
			"label": "38 X 12"
	},
	{
			"value": "3/8  X12'",
			"label": "3/8  X12'"
	},
	{
			"value": "3/8   X 12YDS",
			"label": "3/8   X 12YDS"
	},
	{
			"value": "3/8   X 144 YDS SPO",
			"label": "3/8   X 144 YDS SPO"
	},
	{
			"value": "38 X 15",
			"label": "38 X 15"
	},
	{
			"value": "3/8  X15'",
			"label": "3/8  X15'"
	},
	{
			"value": "38 X 16",
			"label": "38 X 16"
	},
	{
			"value": "38 X  16 MP",
			"label": "38 X  16 MP"
	},
	{
			"value": "38 X 18",
			"label": "38 X 18"
	},
	{
			"value": "3/8  X18'",
			"label": "3/8  X18'"
	},
	{
			"value": "38 X 19.5",
			"label": "38 X 19.5"
	},
	{
			"value": "3/8   X 1 YARD",
			"label": "3/8   X 1 YARD"
	},
	{
			"value": "3/8   X 20 YDS",
			"label": "3/8   X 20 YDS"
	},
	{
			"value": "3/8  X20YDS",
			"label": "3/8  X20YDS"
	},
	{
			"value": "3/8  X250YDS",
			"label": "3/8  X250YDS"
	},
	{
			"value": "3/8   X 2 YARDS",
			"label": "3/8   X 2 YARDS"
	},
	{
			"value": "38X32",
			"label": "38X32"
	},
	{
			"value": "3/8   X 3FT",
			"label": "3/8   X 3FT"
	},
	{
			"value": "3/8   X 4FT",
			"label": "3/8   X 4FT"
	},
	{
			"value": "3/8   X 6FT",
			"label": "3/8   X 6FT"
	},
	{
			"value": "3/8   X6FT",
			"label": "3/8   X6FT"
	},
	{
			"value": "3/8  X9'",
			"label": "3/8  X9'"
	},
	{
			"value": "3.9",
			"label": "3.9"
	},
	{
			"value": "3.9\"\"",
			"label": "3.9\"\""
	},
	{
			"value": "39",
			"label": "39"
	},
	{
			"value": "390",
			"label": "390"
	},
	{
			"value": "3900",
			"label": "3900"
	},
	{
			"value": "3.93",
			"label": "3.93"
	},
	{
			"value": "3.937",
			"label": "3.937"
	},
	{
			"value": "3.93 X 2.95 X 1.18",
			"label": "3.93 X 2.95 X 1.18"
	},
	{
			"value": "3.94",
			"label": "3.94"
	},
	{
			"value": "3.94X5.25X0.50",
			"label": "3.94X5.25X0.50"
	},
	{
			"value": "395",
			"label": "395"
	},
	{
			"value": "396",
			"label": "396"
	},
	{
			"value": "396GR",
			"label": "396GR"
	},
	{
			"value": "397.63X0X0",
			"label": "397.63X0X0"
	},
	{
			"value": "3.99",
			"label": "3.99"
	},
	{
			"value": "399",
			"label": "399"
	},
	{
			"value": "39IN",
			"label": "39IN"
	},
	{
			"value": "39 INCH",
			"label": "39 INCH"
	},
	{
			"value": "39INCH",
			"label": "39INCH"
	},
	{
			"value": "39 INCHES",
			"label": "39 INCHES"
	},
	{
			"value": ".39 OZ",
			"label": ".39 OZ"
	},
	{
			"value": "3.9 OZ",
			"label": "3.9 OZ"
	},
	{
			"value": "3.9X3.9X2.8",
			"label": "3.9X3.9X2.8"
	},
	{
			"value": "3.9X3X.5",
			"label": "3.9X3X.5"
	},
	{
			"value": "39 X 64",
			"label": "39 X 64"
	},
	{
			"value": "3 AND HALF X 5",
			"label": "3 AND HALF X 5"
	},
	{
			"value": "3 BAG - 1.76OZ",
			"label": "3 BAG - 1.76OZ"
	},
	{
			"value": "3 BOTTLES - 4OZ",
			"label": "3 BOTTLES - 4OZ"
	},
	{
			"value": "3 BOTTLES - 4OZ EACH",
			"label": "3 BOTTLES - 4OZ EACH"
	},
	{
			"value": "3 BOTTLES - .66OZ",
			"label": "3 BOTTLES - .66OZ"
	},
	{
			"value": "3CT",
			"label": "3CT"
	},
	{
			"value": "3D",
			"label": "3D"
	},
	{
			"value": "3 DRAWER",
			"label": "3 DRAWER"
	},
	{
			"value": "3 DYE PACKETS -.13OZ",
			"label": "3 DYE PACKETS -.13OZ"
	},
	{
			"value": "3 FT",
			"label": "3 FT"
	},
	{
			"value": "3FT",
			"label": "3FT"
	},
	{
			"value": "3G",
			"label": "3G"
	},
	{
			"value": "3GRAMS",
			"label": "3GRAMS"
	},
	{
			"value": "3  H X 2.25   DIAME",
			"label": "3  H X 2.25   DIAME"
	},
	{
			"value": "3 IN",
			"label": "3 IN"
	},
	{
			"value": "3IN",
			"label": "3IN"
	},
	{
			"value": "3 IN 1",
			"label": "3 IN 1"
	},
	{
			"value": "3 IN 1 TOOL",
			"label": "3 IN 1 TOOL"
	},
	{
			"value": "3 INCH",
			"label": "3 INCH"
	},
	{
			"value": "3-INCH",
			"label": "3-INCH"
	},
	{
			"value": "3INCH",
			"label": "3INCH"
	},
	{
			"value": "3 INCHES",
			"label": "3 INCHES"
	},
	{
			"value": "3 INCH X 3FT",
			"label": "3 INCH X 3FT"
	},
	{
			"value": "3 INCH X 6 YARDS",
			"label": "3 INCH X 6 YARDS"
	},
	{
			"value": "3 IN X 10 YARD",
			"label": "3 IN X 10 YARD"
	},
	{
			"value": "3IN X 10YD",
			"label": "3IN X 10YD"
	},
	{
			"value": "3 IN X 12 IN",
			"label": "3 IN X 12 IN"
	},
	{
			"value": "3 IN X 216 IN",
			"label": "3 IN X 216 IN"
	},
	{
			"value": "3INX3IN",
			"label": "3INX3IN"
	},
	{
			"value": "3 IN X 4 IN 10 CT",
			"label": "3 IN X 4 IN 10 CT"
	},
	{
			"value": "3 IN X 4 IN 12 CT",
			"label": "3 IN X 4 IN 12 CT"
	},
	{
			"value": "3IN X 50YD",
			"label": "3IN X 50YD"
	},
	{
			"value": "3INX7IN",
			"label": "3INX7IN"
	},
	{
			"value": "3 LB TUB",
			"label": "3 LB TUB"
	},
	{
			"value": "3 - LIGHT",
			"label": "3 - LIGHT"
	},
	{
			"value": "3L X 5W X 5.5 H",
			"label": "3L X 5W X 5.5 H"
	},
	{
			"value": "3L X 5W X 5.5H",
			"label": "3L X 5W X 5.5H"
	},
	{
			"value": "3M FINE",
			"label": "3M FINE"
	},
	{
			"value": "3MM",
			"label": "3MM"
	},
	{
			"value": "3 OP 4X6",
			"label": "3 OP 4X6"
	},
	{
			"value": ".3 OZ",
			"label": ".3 OZ"
	},
	{
			"value": "3 OZ",
			"label": "3 OZ"
	},
	{
			"value": "3 OZ.",
			"label": "3 OZ."
	},
	{
			"value": "3. OZ",
			"label": "3. OZ"
	},
	{
			"value": "3OZ",
			"label": "3OZ"
	},
	{
			"value": "3OZ/85G",
			"label": "3OZ/85G"
	},
	{
			"value": "3 OZ/ 85 GRAMS",
			"label": "3 OZ/ 85 GRAMS"
	},
	{
			"value": "3OZ X 48  X60YDS",
			"label": "3OZ X 48  X60YDS"
	},
	{
			"value": "3 PACK",
			"label": "3 PACK"
	},
	{
			"value": "3 PAIR",
			"label": "3 PAIR"
	},
	{
			"value": "3 PC",
			"label": "3 PC"
	},
	{
			"value": "3PC",
			"label": "3PC"
	},
	{
			"value": "3PC/1.5IN",
			"label": "3PC/1.5IN"
	},
	{
			"value": "3PC (4.5L + 6.6L + 1",
			"label": "3PC (4.5L + 6.6L + 1"
	},
	{
			"value": "3 PCS IN A PACK/1 IN",
			"label": "3 PCS IN A PACK/1 IN"
	},
	{
			"value": "3 PCS ON A CLIP STRI",
			"label": "3 PCS ON A CLIP STRI"
	},
	{
			"value": "3 PIECE",
			"label": "3 PIECE"
	},
	{
			"value": "3 PIECES",
			"label": "3 PIECES"
	},
	{
			"value": "3 PK",
			"label": "3 PK"
	},
	{
			"value": "3PK",
			"label": "3PK"
	},
	{
			"value": "3 PKS PER CLIP STRIP",
			"label": "3 PKS PER CLIP STRIP"
	},
	{
			"value": "3RD CURATION",
			"label": "3RD CURATION"
	},
	{
			"value": "3   REPEAT",
			"label": "3   REPEAT"
	},
	{
			"value": "3 S/M AND 2 L/XL",
			"label": "3 S/M AND 2 L/XL"
	},
	{
			"value": "3T",
			"label": "3T"
	},
	{
			"value": "3   TALL",
			"label": "3   TALL"
	},
	{
			"value": "3 TIER",
			"label": "3 TIER"
	},
	{
			"value": "3 UP 4X6",
			"label": "3 UP 4X6"
	},
	{
			"value": "3   W X 36   LL",
			"label": "3   W X 36   LL"
	},
	{
			"value": "3X10.5",
			"label": "3X10.5"
	},
	{
			"value": "3   X 10 YDS",
			"label": "3   X 10 YDS"
	},
	{
			"value": "3  X12",
			"label": "3  X12"
	},
	{
			"value": "3 X .125 X 7",
			"label": "3 X .125 X 7"
	},
	{
			"value": "3 X .13 X 7",
			"label": "3 X .13 X 7"
	},
	{
			"value": "3 X 1.5",
			"label": "3 X 1.5"
	},
	{
			"value": "3  X15'",
			"label": "3  X15'"
	},
	{
			"value": "3X15X17",
			"label": "3X15X17"
	},
	{
			"value": "3 X 1.5 X 5.5",
			"label": "3 X 1.5 X 5.5"
	},
	{
			"value": "3  X 1.5 YDS",
			"label": "3  X 1.5 YDS"
	},
	{
			"value": "3X22X22",
			"label": "3X22X22"
	},
	{
			"value": "3X24X36",
			"label": "3X24X36"
	},
	{
			"value": "3X24X72",
			"label": "3X24X72"
	},
	{
			"value": "3 X 2-5/8",
			"label": "3 X 2-5/8"
	},
	{
			"value": "3 X 2.75 X 6.25",
			"label": "3 X 2.75 X 6.25"
	},
	{
			"value": "3 X 2 X 6",
			"label": "3 X 2 X 6"
	},
	{
			"value": "3 X 3",
			"label": "3 X 3"
	},
	{
			"value": "3   X 3",
			"label": "3   X 3"
	},
	{
			"value": "3  X3",
			"label": "3  X3"
	},
	{
			"value": "3X3",
			"label": "3X3"
	},
	{
			"value": "3   X 3.5",
			"label": "3   X 3.5"
	},
	{
			"value": "3X36X.01",
			"label": "3X36X.01"
	},
	{
			"value": "3 X 3 7.5",
			"label": "3 X 3 7.5"
	},
	{
			"value": "3  X 3.75  X .5",
			"label": "3  X 3.75  X .5"
	},
	{
			"value": "3X3X10",
			"label": "3X3X10"
	},
	{
			"value": "3   X 3   X 10.5",
			"label": "3   X 3   X 10.5"
	},
	{
			"value": "3X3X2.6",
			"label": "3X3X2.6"
	},
	{
			"value": "3X3X3.2",
			"label": "3X3X3.2"
	},
	{
			"value": "3 X 3 X .4",
			"label": "3 X 3 X .4"
	},
	{
			"value": "3 X 3 X 4",
			"label": "3 X 3 X 4"
	},
	{
			"value": "3 X 4",
			"label": "3 X 4"
	},
	{
			"value": "3 X4",
			"label": "3 X4"
	},
	{
			"value": "3X4",
			"label": "3X4"
	},
	{
			"value": "3 X 4.5",
			"label": "3 X 4.5"
	},
	{
			"value": "3X4IN",
			"label": "3X4IN"
	},
	{
			"value": "3X4 PILLAR",
			"label": "3X4 PILLAR"
	},
	{
			"value": "3 X 4 X 3",
			"label": "3 X 4 X 3"
	},
	{
			"value": "3  X 4 X .5",
			"label": "3  X 4 X .5"
	},
	{
			"value": "3 X 5",
			"label": "3 X 5"
	},
	{
			"value": "3 X5",
			"label": "3 X5"
	},
	{
			"value": "3X5",
			"label": "3X5"
	},
	{
			"value": "3    X 5.25",
			"label": "3    X 5.25"
	},
	{
			"value": "3 X 5.5",
			"label": "3 X 5.5"
	},
	{
			"value": "3   X 5.5",
			"label": "3   X 5.5"
	},
	{
			"value": "3 X 5.75",
			"label": "3 X 5.75"
	},
	{
			"value": "3   X 5   X .5",
			"label": "3   X 5   X .5"
	},
	{
			"value": "3 X 6",
			"label": "3 X 6"
	},
	{
			"value": "3X6",
			"label": "3X6"
	},
	{
			"value": "3 X 6.125",
			"label": "3 X 6.125"
	},
	{
			"value": "3 X 6.25 X 2",
			"label": "3 X 6.25 X 2"
	},
	{
			"value": "3 X 6.3",
			"label": "3 X 6.3"
	},
	{
			"value": "3X6IN",
			"label": "3X6IN"
	},
	{
			"value": "3X6MM",
			"label": "3X6MM"
	},
	{
			"value": "3X6 PILLAR",
			"label": "3X6 PILLAR"
	},
	{
			"value": "3 X .6 X 5.7",
			"label": "3 X .6 X 5.7"
	},
	{
			"value": "3 X 6 X 8.5",
			"label": "3 X 6 X 8.5"
	},
	{
			"value": "3   X 6YD",
			"label": "3   X 6YD"
	},
	{
			"value": "3   X 6YDS",
			"label": "3   X 6YDS"
	},
	{
			"value": "3X7",
			"label": "3X7"
	},
	{
			"value": "3 X 8",
			"label": "3 X 8"
	},
	{
			"value": "3X8",
			"label": "3X8"
	},
	{
			"value": "3 X .8 X 5.1",
			"label": "3 X .8 X 5.1"
	},
	{
			"value": "3X9",
			"label": "3X9"
	},
	{
			"value": "3XL",
			"label": "3XL"
	},
	{
			"value": "3X LARGE",
			"label": "3X LARGE"
	},
	{
			"value": "3X-LARGE",
			"label": "3X-LARGE"
	},
	{
			"value": "3XLARGE",
			"label": "3XLARGE"
	},
	{
			"value": "3 YARDS",
			"label": "3 YARDS"
	},
	{
			"value": "3YD",
			"label": "3YD"
	},
	{
			"value": "3 YDS",
			"label": "3 YDS"
	},
	{
			"value": "3YDS",
			"label": "3YDS"
	},
	{
			"value": "4",
			"label": "4"
	},
	{
			"value": "4\"\"",
			"label": "4\"\""
	},
	{
			"value": "4'",
			"label": "4'"
	},
	{
			"value": "40",
			"label": "40"
	},
	{
			"value": "400",
			"label": "400"
	},
	{
			"value": "4000",
			"label": "4000"
	},
	{
			"value": "40000",
			"label": "40000"
	},
	{
			"value": "400FT",
			"label": "400FT"
	},
	{
			"value": "400G",
			"label": "400G"
	},
	{
			"value": "400GR",
			"label": "400GR"
	},
	{
			"value": "400 PC",
			"label": "400 PC"
	},
	{
			"value": "400SERIES 11X14",
			"label": "400SERIES 11X14"
	},
	{
			"value": "400SERIES 18X24",
			"label": "400SERIES 18X24"
	},
	{
			"value": "400SERIES 5X8",
			"label": "400SERIES 5X8"
	},
	{
			"value": "400SERIES 6X8",
			"label": "400SERIES 6X8"
	},
	{
			"value": "400SERIES 9X12",
			"label": "400SERIES 9X12"
	},
	{
			"value": "4.00 X 7.50",
			"label": "4.00 X 7.50"
	},
	{
			"value": "400 YDS",
			"label": "400 YDS"
	},
	{
			"value": "400YDS",
			"label": "400YDS"
	},
	{
			"value": "40 1/2 X 28 1/2",
			"label": "40 1/2 X 28 1/2"
	},
	{
			"value": "4-0.2FL OZ",
			"label": "4-0.2FL OZ"
	},
	{
			"value": "4.02 OZ",
			"label": "4.02 OZ"
	},
	{
			"value": "4.02 X 2.56 X 9.06",
			"label": "4.02 X 2.56 X 9.06"
	},
	{
			"value": "4032",
			"label": "4032"
	},
	{
			"value": "40 3/4 X 28 3/4",
			"label": "40 3/4 X 28 3/4"
	},
	{
			"value": "-4056",
			"label": "-4056"
	},
	{
			"value": "40.5X28",
			"label": "40.5X28"
	},
	{
			"value": "40 COUNT",
			"label": "40 COUNT"
	},
	{
			"value": "40CT",
			"label": "40CT"
	},
	{
			"value": "4.0 IN",
			"label": "4.0 IN"
	},
	{
			"value": "40 IN",
			"label": "40 IN"
	},
	{
			"value": "40IN",
			"label": "40IN"
	},
	{
			"value": "40 INCH",
			"label": "40 INCH"
	},
	{
			"value": "40 INCHES TALL",
			"label": "40 INCHES TALL"
	},
	{
			"value": "40IN X 70SQ FT",
			"label": "40IN X 70SQ FT"
	},
	{
			"value": "40MM",
			"label": "40MM"
	},
	{
			"value": "40MM 50MM",
			"label": "40MM 50MM"
	},
	{
			"value": "4.0 OZ",
			"label": "4.0 OZ"
	},
	{
			"value": "40OZ",
			"label": "40OZ"
	},
	{
			"value": "40 QUART",
			"label": "40 QUART"
	},
	{
			"value": "40 SPOOL",
			"label": "40 SPOOL"
	},
	{
			"value": "40   X 110",
			"label": "40   X 110"
	},
	{
			"value": "40 X 16",
			"label": "40 X 16"
	},
	{
			"value": "40 X 18",
			"label": "40 X 18"
	},
	{
			"value": "40 X 19.5",
			"label": "40 X 19.5"
	},
	{
			"value": "40X20",
			"label": "40X20"
	},
	{
			"value": "40   X 25'",
			"label": "40   X 25'"
	},
	{
			"value": "40X40",
			"label": "40X40"
	},
	{
			"value": "40  X40'",
			"label": "40  X40'"
	},
	{
			"value": "40X48X67",
			"label": "40X48X67"
	},
	{
			"value": "40X72",
			"label": "40X72"
	},
	{
			"value": "40   X 84",
			"label": "40   X 84"
	},
	{
			"value": "40   X 96",
			"label": "40   X 96"
	},
	{
			"value": "41",
			"label": "41"
	},
	{
			"value": "4-10",
			"label": "4-10"
	},
	{
			"value": "4100",
			"label": "4100"
	},
	{
			"value": "41 1/2 X 29 1/2",
			"label": "41 1/2 X 29 1/2"
	},
	{
			"value": "4-1/2",
			"label": "4-1/2"
	},
	{
			"value": "4.125 X 5.625 X 1.5",
			"label": "4.125 X 5.625 X 1.5"
	},
	{
			"value": "4-1/2  H   3-1/2  W",
			"label": "4-1/2  H   3-1/2  W"
	},
	{
			"value": "4-1/2''H 3-1/2''W",
			"label": "4-1/2''H 3-1/2''W"
	},
	{
			"value": "4-1/2''H 3-1/3''W",
			"label": "4-1/2''H 3-1/3''W"
	},
	{
			"value": "4.12 OZ",
			"label": "4.12 OZ"
	},
	{
			"value": "4 1/2   X 3 1/4",
			"label": "4 1/2   X 3 1/4"
	},
	{
			"value": "4 - 12 X 5",
			"label": "4 - 12 X 5"
	},
	{
			"value": "4 1/2  X 6YDS",
			"label": "4 1/2  X 6YDS"
	},
	{
			"value": "4.13 OZ",
			"label": "4.13 OZ"
	},
	{
			"value": "4.13 X 1.50 X 4.13",
			"label": "4.13 X 1.50 X 4.13"
	},
	{
			"value": "4.13 X 2.5  X 4.13",
			"label": "4.13 X 2.5  X 4.13"
	},
	{
			"value": "4.13  X 3.15  X 6.7",
			"label": "4.13  X 3.15  X 6.7"
	},
	{
			"value": "4.13 X 3 X 4.13",
			"label": "4.13 X 3 X 4.13"
	},
	{
			"value": "4.13X4.50X0.75",
			"label": "4.13X4.50X0.75"
	},
	{
			"value": "4.13 X 4 X 4.13",
			"label": "4.13 X 4 X 4.13"
	},
	{
			"value": "4.13 X 4X 4.13",
			"label": "4.13 X 4X 4.13"
	},
	{
			"value": "4 1/4\"\"",
			"label": "4 1/4\"\""
	},
	{
			"value": "4-1/4''H 2-3/8''W",
			"label": "4-1/4''H 2-3/8''W"
	},
	{
			"value": "4.14 OZ",
			"label": "4.14 OZ"
	},
	{
			"value": "41.5   X 12 YD",
			"label": "41.5   X 12 YD"
	},
	{
			"value": "4164",
			"label": "4164"
	},
	{
			"value": "4170",
			"label": "4170"
	},
	{
			"value": "41-72",
			"label": "41-72"
	},
	{
			"value": "4176",
			"label": "4176"
	},
	{
			"value": "4-1/8",
			"label": "4-1/8"
	},
	{
			"value": "4188",
			"label": "4188"
	},
	{
			"value": "4-1/8  H X 3-3/4  W",
			"label": "4-1/8  H X 3-3/4  W"
	},
	{
			"value": "4.18 OZ",
			"label": "4.18 OZ"
	},
	{
			"value": "41IN",
			"label": "41IN"
	},
	{
			"value": "4.1 OZ",
			"label": "4.1 OZ"
	},
	{
			"value": "41OZ",
			"label": "41OZ"
	},
	{
			"value": "4.1OZ",
			"label": "4.1OZ"
	},
	{
			"value": "41 QUARTS",
			"label": "41 QUARTS"
	},
	{
			"value": "4.1   X 2.29",
			"label": "4.1   X 2.29"
	},
	{
			"value": "41 X 72",
			"label": "41 X 72"
	},
	{
			"value": ".42",
			"label": ".42"
	},
	{
			"value": "42",
			"label": "42"
	},
	{
			"value": "42''",
			"label": "42''"
	},
	{
			"value": "4200",
			"label": "4200"
	},
	{
			"value": "-4224",
			"label": "-4224"
	},
	{
			"value": "4225",
			"label": "4225"
	},
	{
			"value": "42258",
			"label": "42258"
	},
	{
			"value": "4.23 OZ",
			"label": "4.23 OZ"
	},
	{
			"value": "4.23OZ",
			"label": "4.23OZ"
	},
	{
			"value": "42/43",
			"label": "42/43"
	},
	{
			"value": "4.25",
			"label": "4.25"
	},
	{
			"value": "425",
			"label": "425"
	},
	{
			"value": "42.5GR",
			"label": "42.5GR"
	},
	{
			"value": "4.25    H",
			"label": "4.25    H"
	},
	{
			"value": "4.25 IN",
			"label": "4.25 IN"
	},
	{
			"value": "4.25 IN.",
			"label": "4.25 IN."
	},
	{
			"value": "4.25IN",
			"label": "4.25IN"
	},
	{
			"value": "42.5IN",
			"label": "42.5IN"
	},
	{
			"value": "4.25 INCH",
			"label": "4.25 INCH"
	},
	{
			"value": "4.25 INCHES",
			"label": "4.25 INCHES"
	},
	{
			"value": "4.25 IN W 5.88 IN H",
			"label": "4.25 IN W 5.88 IN H"
	},
	{
			"value": "4.25 OZ",
			"label": "4.25 OZ"
	},
	{
			"value": "4.25OZ",
			"label": "4.25OZ"
	},
	{
			"value": "4.25 QUARTS",
			"label": "4.25 QUARTS"
	},
	{
			"value": "4.25  W X 4.25  D X",
			"label": "4.25  W X 4.25  D X"
	},
	{
			"value": "4.25X10.25X.125",
			"label": "4.25X10.25X.125"
	},
	{
			"value": "4.25X10.25X.25",
			"label": "4.25X10.25X.25"
	},
	{
			"value": "4.25 X 10.5",
			"label": "4.25 X 10.5"
	},
	{
			"value": "4.25 X 2.5",
			"label": "4.25 X 2.5"
	},
	{
			"value": "4.25  X 2.5  X 0.75",
			"label": "4.25  X 2.5  X 0.75"
	},
	{
			"value": "4.25X2X16.75",
			"label": "4.25X2X16.75"
	},
	{
			"value": "4.25X3.5",
			"label": "4.25X3.5"
	},
	{
			"value": "4.25 X 3.5 X 7.5",
			"label": "4.25 X 3.5 X 7.5"
	},
	{
			"value": "4.25 X 3.75 X .625",
			"label": "4.25 X 3.75 X .625"
	},
	{
			"value": "4.25X4.25",
			"label": "4.25X4.25"
	},
	{
			"value": "4.25 X 4.25 X 3",
			"label": "4.25 X 4.25 X 3"
	},
	{
			"value": "4.25X4.25X3.375",
			"label": "4.25X4.25X3.375"
	},
	{
			"value": "4.25X6.25X8",
			"label": "4.25X6.25X8"
	},
	{
			"value": "4.25X 6.5",
			"label": "4.25X 6.5"
	},
	{
			"value": "4.25 X 6 X 4.25",
			"label": "4.25 X 6 X 4.25"
	},
	{
			"value": "4.25X.75X3.5",
			"label": "4.25X.75X3.5"
	},
	{
			"value": "4.25   X 8.25   X 3",
			"label": "4.25   X 8.25   X 3"
	},
	{
			"value": "4.25 X 8.5",
			"label": "4.25 X 8.5"
	},
	{
			"value": "4.26 OZ",
			"label": "4.26 OZ"
	},
	{
			"value": "42-72",
			"label": "42-72"
	},
	{
			"value": "4281",
			"label": "4281"
	},
	{
			"value": "42 IN",
			"label": "42 IN"
	},
	{
			"value": "42IN",
			"label": "42IN"
	},
	{
			"value": "42 INCH",
			"label": "42 INCH"
	},
	{
			"value": ".42 OZ",
			"label": ".42 OZ"
	},
	{
			"value": ".42 OZ.",
			"label": ".42 OZ."
	},
	{
			"value": ".42OZ",
			"label": ".42OZ"
	},
	{
			"value": "4.2 OZ",
			"label": "4.2 OZ"
	},
	{
			"value": "42OZ",
			"label": "42OZ"
	},
	{
			"value": "4.2 OZ/120 GR",
			"label": "4.2 OZ/120 GR"
	},
	{
			"value": "42 PIECES",
			"label": "42 PIECES"
	},
	{
			"value": "42 X 12",
			"label": "42 X 12"
	},
	{
			"value": "42 X 14",
			"label": "42 X 14"
	},
	{
			"value": "42 X  16 MP",
			"label": "42 X  16 MP"
	},
	{
			"value": "42 X 18",
			"label": "42 X 18"
	},
	{
			"value": "42 X 19.5",
			"label": "42 X 19.5"
	},
	{
			"value": "42 X 24",
			"label": "42 X 24"
	},
	{
			"value": "4.2X4.2X1",
			"label": "4.2X4.2X1"
	},
	{
			"value": "42\"\"X72\"\"  12LBS",
			"label": "42\"\"X72\"\"  12LBS"
	},
	{
			"value": "4.2 X 8.8 X 1.2",
			"label": "4.2 X 8.8 X 1.2"
	},
	{
			"value": "42X9",
			"label": "42X9"
	},
	{
			"value": "4.3\"\"",
			"label": "4.3\"\""
	},
	{
			"value": "43",
			"label": "43"
	},
	{
			"value": "43''",
			"label": "43''"
	},
	{
			"value": "4300",
			"label": "4300"
	},
	{
			"value": "43 1/2 X 31 1/2",
			"label": "43 1/2 X 31 1/2"
	},
	{
			"value": "4.31X7.25X0.75",
			"label": "4.31X7.25X0.75"
	},
	{
			"value": "4/32",
			"label": "4/32"
	},
	{
			"value": "432",
			"label": "432"
	},
	{
			"value": "4320",
			"label": "4320"
	},
	{
			"value": "4/32   - 12 YARDS",
			"label": "4/32   - 12 YARDS"
	},
	{
			"value": "4 3.2 4.5",
			"label": "4 3.2 4.5"
	},
	{
			"value": "4.33 X 3.94 X 12.01",
			"label": "4.33 X 3.94 X 12.01"
	},
	{
			"value": "4.33X4.33X5.91",
			"label": "4.33X4.33X5.91"
	},
	{
			"value": "4 3/4",
			"label": "4 3/4"
	},
	{
			"value": "4-3/4",
			"label": "4-3/4"
	},
	{
			"value": "43/44",
			"label": "43/44"
	},
	{
			"value": "4 3/4  X 6YDS",
			"label": "4 3/4  X 6YDS"
	},
	{
			"value": "4.3 5.5 9",
			"label": "4.3 5.5 9"
	},
	{
			"value": "4.35X0.5X5",
			"label": "4.35X0.5X5"
	},
	{
			"value": "43.6",
			"label": "43.6"
	},
	{
			"value": "4.375X5.125X6.75",
			"label": "4.375X5.125X6.75"
	},
	{
			"value": ".438",
			"label": ".438"
	},
	{
			"value": "438",
			"label": "438"
	},
	{
			"value": "4 3/8 X 2.25 X 3",
			"label": "4 3/8 X 2.25 X 3"
	},
	{
			"value": "4395",
			"label": "4395"
	},
	{
			"value": "43 GREEN",
			"label": "43 GREEN"
	},
	{
			"value": "43 IVORY",
			"label": "43 IVORY"
	},
	{
			"value": "43 OZ",
			"label": "43 OZ"
	},
	{
			"value": "43 RED",
			"label": "43 RED"
	},
	{
			"value": "4.3X1.8X9.9",
			"label": "4.3X1.8X9.9"
	},
	{
			"value": "4.3 X 2.6 X 4.7",
			"label": "4.3 X 2.6 X 4.7"
	},
	{
			"value": "4.3   X 7.5",
			"label": "4.3   X 7.5"
	},
	{
			"value": "4.3 X 7 X 4.1",
			"label": "4.3 X 7 X 4.1"
	},
	{
			"value": "4.4\"\"",
			"label": "4.4\"\""
	},
	{
			"value": "44",
			"label": "44"
	},
	{
			"value": "44''",
			"label": "44''"
	},
	{
			"value": "440",
			"label": "440"
	},
	{
			"value": "4400",
			"label": "4400"
	},
	{
			"value": "44020",
			"label": "44020"
	},
	{
			"value": "44289",
			"label": "44289"
	},
	{
			"value": "-444",
			"label": "-444"
	},
	{
			"value": "4440",
			"label": "4440"
	},
	{
			"value": "44411",
			"label": "44411"
	},
	{
			"value": "44413",
			"label": "44413"
	},
	{
			"value": "44/45",
			"label": "44/45"
	},
	{
			"value": "(44-46-48)",
			"label": "(44-46-48)"
	},
	{
			"value": "445",
			"label": "445"
	},
	{
			"value": "44.5   X 6 YDS",
			"label": "44.5   X 6 YDS"
	},
	{
			"value": "-4464",
			"label": "-4464"
	},
	{
			"value": "44661",
			"label": "44661"
	},
	{
			"value": "448",
			"label": "448"
	},
	{
			"value": "44815",
			"label": "44815"
	},
	{
			"value": "44CT",
			"label": "44CT"
	},
	{
			"value": "44IN",
			"label": "44IN"
	},
	{
			"value": "44L",
			"label": "44L"
	},
	{
			"value": "44MM",
			"label": "44MM"
	},
	{
			"value": ".44 OZ",
			"label": ".44 OZ"
	},
	{
			"value": "4.4 OZ",
			"label": "4.4 OZ"
	},
	{
			"value": "4.4OZ",
			"label": "4.4OZ"
	},
	{
			"value": "4.4 OZ/125 GRAMS",
			"label": "4.4 OZ/125 GRAMS"
	},
	{
			"value": "44 X 12",
			"label": "44 X 12"
	},
	{
			"value": "44 X 15",
			"label": "44 X 15"
	},
	{
			"value": "44 X 18",
			"label": "44 X 18"
	},
	{
			"value": "4-4X6",
			"label": "4-4X6"
	},
	{
			"value": "4.5",
			"label": "4.5"
	},
	{
			"value": "4.5\"\"",
			"label": "4.5\"\""
	},
	{
			"value": "45",
			"label": "45"
	},
	{
			"value": "45''",
			"label": "45''"
	},
	{
			"value": "450",
			"label": "450"
	},
	{
			"value": "4500",
			"label": "4500"
	},
	{
			"value": "4.50 X 2.00",
			"label": "4.50 X 2.00"
	},
	{
			"value": "4.50 X 22 X 12",
			"label": "4.50 X 22 X 12"
	},
	{
			"value": "4.50 X 3.50 X 2.25",
			"label": "4.50 X 3.50 X 2.25"
	},
	{
			"value": "4.50 X 3.75",
			"label": "4.50 X 3.75"
	},
	{
			"value": "4.50X6.50",
			"label": "4.50X6.50"
	},
	{
			"value": "4.50X9.00X0.13",
			"label": "4.50X9.00X0.13"
	},
	{
			"value": "4.51 OZ",
			"label": "4.51 OZ"
	},
	{
			"value": "4.53",
			"label": "4.53"
	},
	{
			"value": "4.53 IN",
			"label": "4.53 IN"
	},
	{
			"value": "4.53   X 3.94   X .",
			"label": "4.53   X 3.94   X ."
	},
	{
			"value": "454GR",
			"label": "454GR"
	},
	{
			"value": "(4-5-6)",
			"label": "(4-5-6)"
	},
	{
			"value": "4572",
			"label": "4572"
	},
	{
			"value": "4584",
			"label": "4584"
	},
	{
			"value": "4.58 OZ",
			"label": "4.58 OZ"
	},
	{
			"value": "4.592   X 2.27",
			"label": "4.592   X 2.27"
	},
	{
			"value": "4596",
			"label": "4596"
	},
	{
			"value": "45 FT",
			"label": "45 FT"
	},
	{
			"value": "45GR",
			"label": "45GR"
	},
	{
			"value": "45 HANUKKAH",
			"label": "45 HANUKKAH"
	},
	{
			"value": "4.5 IN",
			"label": "4.5 IN"
	},
	{
			"value": "45IN",
			"label": "45IN"
	},
	{
			"value": "4.5IN",
			"label": "4.5IN"
	},
	{
			"value": "4.5IN*2",
			"label": "4.5IN*2"
	},
	{
			"value": "4.5 INCH",
			"label": "4.5 INCH"
	},
	{
			"value": "4.5 IN W 3.5 IN H",
			"label": "4.5 IN W 3.5 IN H"
	},
	{
			"value": "4.5 IN X 1.6 IN X 0.",
			"label": "4.5 IN X 1.6 IN X 0."
	},
	{
			"value": "4.5 IN X 4.5 IN X 6",
			"label": "4.5 IN X 4.5 IN X 6"
	},
	{
			"value": "45L",
			"label": "45L"
	},
	{
			"value": "4.5LB",
			"label": "4.5LB"
	},
	{
			"value": "45 METALLIC",
			"label": "45 METALLIC"
	},
	{
			"value": "45 MM",
			"label": "45 MM"
	},
	{
			"value": "45MM",
			"label": "45MM"
	},
	{
			"value": "45MM AND 4",
			"label": "45MM AND 4"
	},
	{
			"value": "4.5 OZ",
			"label": "4.5 OZ"
	},
	{
			"value": "4.5 OZ.",
			"label": "4.5 OZ."
	},
	{
			"value": "4.5OZ",
			"label": "4.5OZ"
	},
	{
			"value": "45PC",
			"label": "45PC"
	},
	{
			"value": "45 SET",
			"label": "45 SET"
	},
	{
			"value": "4.5   WIDTH",
			"label": "4.5   WIDTH"
	},
	{
			"value": "45   WIDTH X 25 YDS",
			"label": "45   WIDTH X 25 YDS"
	},
	{
			"value": "4.5  W X 1.25  D X",
			"label": "4.5  W X 1.25  D X"
	},
	{
			"value": "4.5  WX1.25  DX9  H",
			"label": "4.5  WX1.25  DX9  H"
	},
	{
			"value": "4.5   W X 6   D X 1",
			"label": "4.5   W X 6   D X 1"
	},
	{
			"value": "4.5  W X 9.25  H",
			"label": "4.5  W X 9.25  H"
	},
	{
			"value": "4.5   X 10",
			"label": "4.5   X 10"
	},
	{
			"value": "4.5   X 10.875",
			"label": "4.5   X 10.875"
	},
	{
			"value": "45  X10YD",
			"label": "45  X10YD"
	},
	{
			"value": "45   X 10 YDS BOLT",
			"label": "45   X 10 YDS BOLT"
	},
	{
			"value": "4.5   X 11.125",
			"label": "4.5   X 11.125"
	},
	{
			"value": "4.5   X 1.25   X .2",
			"label": "4.5   X 1.25   X .2"
	},
	{
			"value": "45   X 12 YARDS BOL",
			"label": "45   X 12 YARDS BOL"
	},
	{
			"value": "4.5 X 14",
			"label": "4.5 X 14"
	},
	{
			"value": "4.5 X 14 X 1.25",
			"label": "4.5 X 14 X 1.25"
	},
	{
			"value": "4.5 X 1.4 X 7.2",
			"label": "4.5 X 1.4 X 7.2"
	},
	{
			"value": "45   X 15 YDS BOLT",
			"label": "45   X 15 YDS BOLT"
	},
	{
			"value": "4.5 X 1 X 7.5",
			"label": "4.5 X 1 X 7.5"
	},
	{
			"value": "45 X 1 YARD",
			"label": "45 X 1 YARD"
	},
	{
			"value": "45\"\" X 1YD",
			"label": "45\"\" X 1YD"
	},
	{
			"value": "4.5X2",
			"label": "4.5X2"
	},
	{
			"value": "45  X25 YDS",
			"label": "45  X25 YDS"
	},
	{
			"value": "4.5 X 2.75 X 0.25 IN",
			"label": "4.5 X 2.75 X 0.25 IN"
	},
	{
			"value": "4.5X2.8X2.8",
			"label": "4.5X2.8X2.8"
	},
	{
			"value": "45   X 30 YARDS",
			"label": "45   X 30 YARDS"
	},
	{
			"value": "4.5 X 3.25 X 0.25 IN",
			"label": "4.5 X 3.25 X 0.25 IN"
	},
	{
			"value": "4.5X3.5X0.75",
			"label": "4.5X3.5X0.75"
	},
	{
			"value": "4.5X3.5X1.375",
			"label": "4.5X3.5X1.375"
	},
	{
			"value": "4.5 X 3.5 X 2.25",
			"label": "4.5 X 3.5 X 2.25"
	},
	{
			"value": "4.5   X 3.5   X .75",
			"label": "4.5   X 3.5   X .75"
	},
	{
			"value": "4.5   X 4",
			"label": "4.5   X 4"
	},
	{
			"value": "4.5X4",
			"label": "4.5X4"
	},
	{
			"value": "4.5X4.5",
			"label": "4.5X4.5"
	},
	{
			"value": "4.5X4.5X10.5",
			"label": "4.5X4.5X10.5"
	},
	{
			"value": "45   X 45YDS",
			"label": "45   X 45YDS"
	},
	{
			"value": "4.5 X5",
			"label": "4.5 X5"
	},
	{
			"value": "45  X50YDS",
			"label": "45  X50YDS"
	},
	{
			"value": "4.5X 5.5",
			"label": "4.5X 5.5"
	},
	{
			"value": "4.5X5.5X10",
			"label": "4.5X5.5X10"
	},
	{
			"value": "4.5X5.5X11",
			"label": "4.5X5.5X11"
	},
	{
			"value": "4.5 X 5 X 9.5",
			"label": "4.5 X 5 X 9.5"
	},
	{
			"value": "45  X5YDS",
			"label": "45  X5YDS"
	},
	{
			"value": "4.5 X6",
			"label": "4.5 X6"
	},
	{
			"value": "4.5   X 6",
			"label": "4.5   X 6"
	},
	{
			"value": "45 X 60",
			"label": "45 X 60"
	},
	{
			"value": "45   X 60",
			"label": "45   X 60"
	},
	{
			"value": "4.5 X 6 X 4  H",
			"label": "4.5 X 6 X 4  H"
	},
	{
			"value": "4.5   X 6YD",
			"label": "4.5   X 6YD"
	},
	{
			"value": "4.5X7",
			"label": "4.5X7"
	},
	{
			"value": "4.5X7.5",
			"label": "4.5X7.5"
	},
	{
			"value": "4.5 X 7.75 EMBELLISH",
			"label": "4.5 X 7.75 EMBELLISH"
	},
	{
			"value": "4.5 X 8",
			"label": "4.5 X 8"
	},
	{
			"value": "4.5   X 8.5",
			"label": "4.5   X 8.5"
	},
	{
			"value": "4.5X8.5X.25",
			"label": "4.5X8.5X.25"
	},
	{
			"value": " 4,6",
			"label": " 4,6"
	},
	{
			"value": "4-6",
			"label": "4-6"
	},
	{
			"value": "46",
			"label": "46"
	},
	{
			"value": "460",
			"label": "460"
	},
	{
			"value": "4600",
			"label": "4600"
	},
	{
			"value": "4608",
			"label": "4608"
	},
	{
			"value": "46 1/2 X 34 1/2",
			"label": "46 1/2 X 34 1/2"
	},
	{
			"value": "4.625 X 14 X 1.25",
			"label": "4.625 X 14 X 1.25"
	},
	{
			"value": "4.625 X 6.25",
			"label": "4.625 X 6.25"
	},
	{
			"value": "46 3/4 X 34 3/4",
			"label": "46 3/4 X 34 3/4"
	},
	{
			"value": "4.6 4.6 8.5",
			"label": "4.6 4.6 8.5"
	},
	{
			"value": "4.67 OZ",
			"label": "4.67 OZ"
	},
	{
			"value": "468",
			"label": "468"
	},
	{
			"value": " 4,6,8,10",
			"label": " 4,6,8,10"
	},
	{
			"value": "46 IN",
			"label": "46 IN"
	},
	{
			"value": "46IN",
			"label": "46IN"
	},
	{
			"value": "46 MM X 5 M",
			"label": "46 MM X 5 M"
	},
	{
			"value": ".46 OZ",
			"label": ".46 OZ"
	},
	{
			"value": "46 X 18",
			"label": "46 X 18"
	},
	{
			"value": "46 X 23.5 X 1",
			"label": "46 X 23.5 X 1"
	},
	{
			"value": "4.7",
			"label": "4.7"
	},
	{
			"value": "47",
			"label": "47"
	},
	{
			"value": "4700",
			"label": "4700"
	},
	{
			"value": "4.72 X 1.99 X 6.91",
			"label": "4.72 X 1.99 X 6.91"
	},
	{
			"value": "473",
			"label": "473"
	},
	{
			"value": "4.73 IN",
			"label": "4.73 IN"
	},
	{
			"value": "4.7 4.7 8.7",
			"label": "4.7 4.7 8.7"
	},
	{
			"value": "47/48",
			"label": "47/48"
	},
	{
			"value": "4.75",
			"label": "4.75"
	},
	{
			"value": "4.75\"\"",
			"label": "4.75\"\""
	},
	{
			"value": "4.75IN",
			"label": "4.75IN"
	},
	{
			"value": "4.75   L X 3   W X",
			"label": "4.75   L X 3   W X"
	},
	{
			"value": "4.75QT",
			"label": "4.75QT"
	},
	{
			"value": "4.75X1.5X18",
			"label": "4.75X1.5X18"
	},
	{
			"value": "4.75X1.75X12.75",
			"label": "4.75X1.75X12.75"
	},
	{
			"value": "4.75 X 2.25",
			"label": "4.75 X 2.25"
	},
	{
			"value": "4.75X2.75X9",
			"label": "4.75X2.75X9"
	},
	{
			"value": "4.75 X 4.75",
			"label": "4.75 X 4.75"
	},
	{
			"value": "4.75 X 4.75 SWATCH",
			"label": "4.75 X 4.75 SWATCH"
	},
	{
			"value": "4.75   X 5.5   X 4.",
			"label": "4.75   X 5.5   X 4."
	},
	{
			"value": "4.75 X 5 X 11.5",
			"label": "4.75 X 5 X 11.5"
	},
	{
			"value": "4.75   X 6YD",
			"label": "4.75   X 6YD"
	},
	{
			"value": "4.75 YARDS",
			"label": "4.75 YARDS"
	},
	{
			"value": "4.75 YD",
			"label": "4.75 YD"
	},
	{
			"value": "4.76 OZ",
			"label": "4.76 OZ"
	},
	{
			"value": "47.75 X 15 X 24.38",
			"label": "47.75 X 15 X 24.38"
	},
	{
			"value": "4780",
			"label": "4780"
	},
	{
			"value": "47.875X10.125X1.9",
			"label": "47.875X10.125X1.9"
	},
	{
			"value": "4 7/8 X 4 3/4 X 1 3/",
			"label": "4 7/8 X 4 3/4 X 1 3/"
	},
	{
			"value": "4.7 IN",
			"label": "4.7 IN"
	},
	{
			"value": "47 IN",
			"label": "47 IN"
	},
	{
			"value": "4.7 OZ",
			"label": "4.7 OZ"
	},
	{
			"value": "4.7QT",
			"label": "4.7QT"
	},
	{
			"value": "47X12X2",
			"label": "47X12X2"
	},
	{
			"value": "47 X 42",
			"label": "47 X 42"
	},
	{
			"value": "4.7X4.7X7.9",
			"label": "4.7X4.7X7.9"
	},
	{
			"value": "4.7  X7",
			"label": "4.7  X7"
	},
	{
			"value": "48",
			"label": "48"
	},
	{
			"value": "48\"\"",
			"label": "48\"\""
	},
	{
			"value": "480",
			"label": "480"
	},
	{
			"value": "4800",
			"label": "4800"
	},
	{
			"value": "48000",
			"label": "48000"
	},
	{
			"value": "48 1/2 X 24 1/2",
			"label": "48 1/2 X 24 1/2"
	},
	{
			"value": "4.81 X 5.5",
			"label": "4.81 X 5.5"
	},
	{
			"value": "4.81 X 5.50",
			"label": "4.81 X 5.50"
	},
	{
			"value": "48-24",
			"label": "48-24"
	},
	{
			"value": "4824",
			"label": "4824"
	},
	{
			"value": "4.8 2.8 3.2",
			"label": "4.8 2.8 3.2"
	},
	{
			"value": "48 3/4 X 24 3/4",
			"label": "48 3/4 X 24 3/4"
	},
	{
			"value": "48 40 48",
			"label": "48 40 48"
	},
	{
			"value": "4.8  4.8  X6.9",
			"label": "4.8  4.8  X6.9"
	},
	{
			"value": "4.8  4.8  X7.7",
			"label": "4.8  4.8  X7.7"
	},
	{
			"value": "4.85X4.85",
			"label": "4.85X4.85"
	},
	{
			"value": "48 60",
			"label": "48 60"
	},
	{
			"value": "48-75",
			"label": "48-75"
	},
	{
			"value": "4.875 X 3.375",
			"label": "4.875 X 3.375"
	},
	{
			"value": "4.87 OZ",
			"label": "4.87 OZ"
	},
	{
			"value": "48-84",
			"label": "48-84"
	},
	{
			"value": "48-86",
			"label": "48-86"
	},
	{
			"value": "4896",
			"label": "4896"
	},
	{
			"value": "48 CT",
			"label": "48 CT"
	},
	{
			"value": "48CT",
			"label": "48CT"
	},
	{
			"value": "48 IN",
			"label": "48 IN"
	},
	{
			"value": "48IN",
			"label": "48IN"
	},
	{
			"value": "48 INCH",
			"label": "48 INCH"
	},
	{
			"value": "48IN X 60IN",
			"label": "48IN X 60IN"
	},
	{
			"value": ".48 OZ",
			"label": ".48 OZ"
	},
	{
			"value": "4.8 OZ",
			"label": "4.8 OZ"
	},
	{
			"value": "4.8 OZ.",
			"label": "4.8 OZ."
	},
	{
			"value": "48OZ",
			"label": "48OZ"
	},
	{
			"value": "4.8OZ",
			"label": "4.8OZ"
	},
	{
			"value": "48 PC",
			"label": "48 PC"
	},
	{
			"value": "48 PCS",
			"label": "48 PCS"
	},
	{
			"value": "48 ROLL",
			"label": "48 ROLL"
	},
	{
			"value": "48\"\" X 10YDS",
			"label": "48\"\" X 10YDS"
	},
	{
			"value": "48 X 110",
			"label": "48 X 110"
	},
	{
			"value": "4.8  X11.4X.13",
			"label": "4.8  X11.4X.13"
	},
	{
			"value": "48 X 12",
			"label": "48 X 12"
	},
	{
			"value": "48 X 14",
			"label": "48 X 14"
	},
	{
			"value": "48 X 15",
			"label": "48 X 15"
	},
	{
			"value": "48 X 16LP X 36CASCAD",
			"label": "48 X 16LP X 36CASCAD"
	},
	{
			"value": "48 X 18",
			"label": "48 X 18"
	},
	{
			"value": "48   X 18 YARDS",
			"label": "48   X 18 YARDS"
	},
	{
			"value": "48X20",
			"label": "48X20"
	},
	{
			"value": "48   X 22 YARDS",
			"label": "48   X 22 YARDS"
	},
	{
			"value": "48   X 25 YDS BOLT",
			"label": "48   X 25 YDS BOLT"
	},
	{
			"value": "48   X 30 YARDS",
			"label": "48   X 30 YARDS"
	},
	{
			"value": "4.8X4.8X5",
			"label": "4.8X4.8X5"
	},
	{
			"value": "48X60",
			"label": "48X60"
	},
	{
			"value": "48   X 60",
			"label": "48   X 60"
	},
	{
			"value": "48  X60",
			"label": "48  X60"
	},
	{
			"value": "48X60IN",
			"label": "48X60IN"
	},
	{
			"value": "48 X 84",
			"label": "48 X 84"
	},
	{
			"value": "48 X 96",
			"label": "48 X 96"
	},
	{
			"value": "4.8X9X2",
			"label": "4.8X9X2"
	},
	{
			"value": "49",
			"label": "49"
	},
	{
			"value": "4.90 X 3.75",
			"label": "4.90 X 3.75"
	},
	{
			"value": "49 1/2 X 25 1/2",
			"label": "49 1/2 X 25 1/2"
	},
	{
			"value": "49 1/2 X 37 1/2",
			"label": "49 1/2 X 37 1/2"
	},
	{
			"value": "492",
			"label": "492"
	},
	{
			"value": "4.92X4.13",
			"label": "4.92X4.13"
	},
	{
			"value": "4.92  X 6.5  X 6.5",
			"label": "4.92  X 6.5  X 6.5"
	},
	{
			"value": "4.96 X 2.95",
			"label": "4.96 X 2.95"
	},
	{
			"value": "4.96 X 2.96",
			"label": "4.96 X 2.96"
	},
	{
			"value": "4.99",
			"label": "4.99"
	},
	{
			"value": "49968",
			"label": "49968"
	},
	{
			"value": "49 IN",
			"label": "49 IN"
	},
	{
			"value": "49IN",
			"label": "49IN"
	},
	{
			"value": "4.9 OZ",
			"label": "4.9 OZ"
	},
	{
			"value": "4.9OZ",
			"label": "4.9OZ"
	},
	{
			"value": "4.9 X 2.1 X 3",
			"label": "4.9 X 2.1 X 3"
	},
	{
			"value": "4.9 X 4.6 X 5.6",
			"label": "4.9 X 4.6 X 5.6"
	},
	{
			"value": "4 CELL",
			"label": "4 CELL"
	},
	{
			"value": "4 CORD WRAPS",
			"label": "4 CORD WRAPS"
	},
	{
			"value": "4CT",
			"label": "4CT"
	},
	{
			"value": "4 EACH - QUEEN DISPL",
			"label": "4 EACH - QUEEN DISPL"
	},
	{
			"value": "4 FL OZ",
			"label": "4 FL OZ"
	},
	{
			"value": "4FT",
			"label": "4FT"
	},
	{
			"value": "4G",
			"label": "4G"
	},
	{
			"value": "4  H X 3.5  W X 5",
			"label": "4  H X 3.5  W X 5"
	},
	{
			"value": "4 IN",
			"label": "4 IN"
	},
	{
			"value": "4 IN.",
			"label": "4 IN."
	},
	{
			"value": "4IN",
			"label": "4IN"
	},
	{
			"value": "4IN (10CM)",
			"label": "4IN (10CM)"
	},
	{
			"value": "4 IN 1 MULTI",
			"label": "4 IN 1 MULTI"
	},
	{
			"value": "4IN *2",
			"label": "4IN *2"
	},
	{
			"value": "4 INCH",
			"label": "4 INCH"
	},
	{
			"value": "4 INCHES",
			"label": "4 INCHES"
	},
	{
			"value": "4 INCH X 3FT",
			"label": "4 INCH X 3FT"
	},
	{
			"value": "4 INCH X 3 YARDS",
			"label": "4 INCH X 3 YARDS"
	},
	{
			"value": "4 INCH X 6 YARDS",
			"label": "4 INCH X 6 YARDS"
	},
	{
			"value": "4INX10IN",
			"label": "4INX10IN"
	},
	{
			"value": "4INX12IN",
			"label": "4INX12IN"
	},
	{
			"value": "4INX3IN",
			"label": "4INX3IN"
	},
	{
			"value": "4IN X 3YD",
			"label": "4IN X 3YD"
	},
	{
			"value": "4 INX4IN",
			"label": "4 INX4IN"
	},
	{
			"value": "4 IN X 6 IN 8 CT",
			"label": "4 IN X 6 IN 8 CT"
	},
	{
			"value": "4IN X 6YD",
			"label": "4IN X 6YD"
	},
	{
			"value": "4INX8IN",
			"label": "4INX8IN"
	},
	{
			"value": "4LB",
			"label": "4LB"
	},
	{
			"value": "4 - MEDIUM",
			"label": "4 - MEDIUM"
	},
	{
			"value": "4 MM",
			"label": "4 MM"
	},
	{
			"value": "4MM",
			"label": "4MM"
	},
	{
			"value": "4MM X 9FT",
			"label": "4MM X 9FT"
	},
	{
			"value": ".4 OZ",
			"label": ".4 OZ"
	},
	{
			"value": "4 OZ",
			"label": "4 OZ"
	},
	{
			"value": "4 OZ.",
			"label": "4 OZ."
	},
	{
			"value": "4. OZ",
			"label": "4. OZ"
	},
	{
			"value": "4OZ",
			"label": "4OZ"
	},
	{
			"value": "4OZ 113 GM",
			"label": "4OZ 113 GM"
	},
	{
			"value": "4 OZ BOTTLE",
			"label": "4 OZ BOTTLE"
	},
	{
			"value": "4 OZ. BOTTLE",
			"label": "4 OZ. BOTTLE"
	},
	{
			"value": "4 OZ POUCH",
			"label": "4 OZ POUCH"
	},
	{
			"value": "4 PACK",
			"label": "4 PACK"
	},
	{
			"value": "4 PACK 0.18 FL. OZ.",
			"label": "4 PACK 0.18 FL. OZ."
	},
	{
			"value": "4 PAIR",
			"label": "4 PAIR"
	},
	{
			"value": "4 PC",
			"label": "4 PC"
	},
	{
			"value": "4PC",
			"label": "4PC"
	},
	{
			"value": "4PC-4OZ",
			"label": "4PC-4OZ"
	},
	{
			"value": "4 PIECE",
			"label": "4 PIECE"
	},
	{
			"value": "4 PIECES",
			"label": "4 PIECES"
	},
	{
			"value": "4 PK",
			"label": "4 PK"
	},
	{
			"value": "4PK",
			"label": "4PK"
	},
	{
			"value": "4PK 3X5",
			"label": "4PK 3X5"
	},
	{
			"value": "4 PNK",
			"label": "4 PNK"
	},
	{
			"value": "4 QT",
			"label": "4 QT"
	},
	{
			"value": "4 QUART",
			"label": "4 QUART"
	},
	{
			"value": "4 REPLACEMENT",
			"label": "4 REPLACEMENT"
	},
	{
			"value": "4 STRANDS 6\"\" EACH",
			"label": "4 STRANDS 6\"\" EACH"
	},
	{
			"value": "4T",
			"label": "4T"
	},
	{
			"value": "4TH CURATION",
			"label": "4TH CURATION"
	},
	{
			"value": "4 TIER",
			"label": "4 TIER"
	},
	{
			"value": "4 WHEEL",
			"label": "4 WHEEL"
	},
	{
			"value": "4X0.4X3.75",
			"label": "4X0.4X3.75"
	},
	{
			"value": "4 X 0.6 X 5.25",
			"label": "4 X 0.6 X 5.25"
	},
	{
			"value": "4 X 10",
			"label": "4 X 10"
	},
	{
			"value": "4X10",
			"label": "4X10"
	},
	{
			"value": "4X1.25X6",
			"label": "4X1.25X6"
	},
	{
			"value": "4   X 12.75   X 3.2",
			"label": "4   X 12.75   X 3.2"
	},
	{
			"value": "4 X 1.2 X 7.5",
			"label": "4 X 1.2 X 7.5"
	},
	{
			"value": "4X1.5",
			"label": "4X1.5"
	},
	{
			"value": "4'X19",
			"label": "4'X19"
	},
	{
			"value": "4'X19'",
			"label": "4'X19'"
	},
	{
			"value": "4   X 2.26",
			"label": "4   X 2.26"
	},
	{
			"value": "4 X 22 X 22",
			"label": "4 X 22 X 22"
	},
	{
			"value": "4X22X22",
			"label": "4X22X22"
	},
	{
			"value": "4X24X72",
			"label": "4X24X72"
	},
	{
			"value": "4 X 2 5/8 X 6 1/4",
			"label": "4 X 2 5/8 X 6 1/4"
	},
	{
			"value": "4 X 2.5 X 0.8",
			"label": "4 X 2.5 X 0.8"
	},
	{
			"value": "4 X 2.75",
			"label": "4 X 2.75"
	},
	{
			"value": "4X2.75X9.5",
			"label": "4X2.75X9.5"
	},
	{
			"value": "4 X 27 X 1.5",
			"label": "4 X 27 X 1.5"
	},
	{
			"value": "4X3",
			"label": "4X3"
	},
	{
			"value": "4X3.25X1.5",
			"label": "4X3.25X1.5"
	},
	{
			"value": "4 X 3.25 X 8.5",
			"label": "4 X 3.25 X 8.5"
	},
	{
			"value": "4X3.3X3.3",
			"label": "4X3.3X3.3"
	},
	{
			"value": "4    X3.5",
			"label": "4    X3.5"
	},
	{
			"value": "4 X 3.50 X 2.25",
			"label": "4 X 3.50 X 2.25"
	},
	{
			"value": "4 X 3.5 X 0.9",
			"label": "4 X 3.5 X 0.9"
	},
	{
			"value": "4 X 3.5 X 2.25",
			"label": "4 X 3.5 X 2.25"
	},
	{
			"value": "4    X 3YDS",
			"label": "4    X 3YDS"
	},
	{
			"value": "4   X 3  YDS",
			"label": "4   X 3  YDS"
	},
	{
			"value": "4   X 3YDS",
			"label": "4   X 3YDS"
	},
	{
			"value": "4  X3YDS",
			"label": "4  X3YDS"
	},
	{
			"value": "4 X 4",
			"label": "4 X 4"
	},
	{
			"value": "4   X 4",
			"label": "4   X 4"
	},
	{
			"value": "4X4",
			"label": "4X4"
	},
	{
			"value": "4 X 4.25",
			"label": "4 X 4.25"
	},
	{
			"value": "4X4,4X6,5X7",
			"label": "4X4,4X6,5X7"
	},
	{
			"value": " 4X4, 4X6, 6X8, 5X7,",
			"label": " 4X4, 4X6, 6X8, 5X7,"
	},
	{
			"value": "4 X  4.5",
			"label": "4 X  4.5"
	},
	{
			"value": "4 X 4.5",
			"label": "4 X 4.5"
	},
	{
			"value": "4    X4.5",
			"label": "4    X4.5"
	},
	{
			"value": "4   X 4.5",
			"label": "4   X 4.5"
	},
	{
			"value": "4X4.5X3.5",
			"label": "4X4.5X3.5"
	},
	{
			"value": "4X4.5X4.5",
			"label": "4X4.5X4.5"
	},
	{
			"value": "4X4X11.75",
			"label": "4X4X11.75"
	},
	{
			"value": "4X4X2",
			"label": "4X4X2"
	},
	{
			"value": "4 X 4 X .25",
			"label": "4 X 4 X .25"
	},
	{
			"value": "4   X 4   X 2.5",
			"label": "4   X 4   X 2.5"
	},
	{
			"value": "4 X 4 X 3.5",
			"label": "4 X 4 X 3.5"
	},
	{
			"value": "4 X 4 X 4",
			"label": "4 X 4 X 4"
	},
	{
			"value": "4   X 4   X 4",
			"label": "4   X 4   X 4"
	},
	{
			"value": "4X4X4",
			"label": "4X4X4"
	},
	{
			"value": "4X4X5",
			"label": "4X4X5"
	},
	{
			"value": "4X4X6",
			"label": "4X4X6"
	},
	{
			"value": "4X4X6.5",
			"label": "4X4X6.5"
	},
	{
			"value": "4X4X.75",
			"label": "4X4X.75"
	},
	{
			"value": "4X4X7.87",
			"label": "4X4X7.87"
	},
	{
			"value": "4 X 4 X 8",
			"label": "4 X 4 X 8"
	},
	{
			"value": "4X4X8",
			"label": "4X4X8"
	},
	{
			"value": "4   X 5",
			"label": "4   X 5"
	},
	{
			"value": "4X5",
			"label": "4X5"
	},
	{
			"value": "4 X 5.375",
			"label": "4 X 5.375"
	},
	{
			"value": "4 X 5 X 2 INCHES",
			"label": "4 X 5 X 2 INCHES"
	},
	{
			"value": "4    X  5YDS",
			"label": "4    X  5YDS"
	},
	{
			"value": "4    X 5YDS",
			"label": "4    X 5YDS"
	},
	{
			"value": "4   X 5YDS",
			"label": "4   X 5YDS"
	},
	{
			"value": "4  X5YDS",
			"label": "4  X5YDS"
	},
	{
			"value": "4 X 6",
			"label": "4 X 6"
	},
	{
			"value": "4   X 6",
			"label": "4   X 6"
	},
	{
			"value": "4X6",
			"label": "4X6"
	},
	{
			"value": "4 X 6.25",
			"label": "4 X 6.25"
	},
	{
			"value": "4X6 2TONE STEP FILLE",
			"label": "4X6 2TONE STEP FILLE"
	},
	{
			"value": "4X6 2-UP",
			"label": "4X6 2-UP"
	},
	{
			"value": "4X6 3-UP",
			"label": "4X6 3-UP"
	},
	{
			"value": "4X6 4 PK",
			"label": "4X6 4 PK"
	},
	{
			"value": "4X6 BLACK",
			"label": "4X6 BLACK"
	},
	{
			"value": "4X6 BRAG",
			"label": "4X6 BRAG"
	},
	{
			"value": "4X6 BRONZE 2TONE",
			"label": "4X6 BRONZE 2TONE"
	},
	{
			"value": "4X6 ESPRESSO",
			"label": "4X6 ESPRESSO"
	},
	{
			"value": "4X6IN",
			"label": "4X6IN"
	},
	{
			"value": "4X6 OPENING",
			"label": "4X6 OPENING"
	},
	{
			"value": "4X6 PEWTER BEAD",
			"label": "4X6 PEWTER BEAD"
	},
	{
			"value": "4X6 SILVER ORNATE SP",
			"label": "4X6 SILVER ORNATE SP"
	},
	{
			"value": "4X6X7",
			"label": "4X6X7"
	},
	{
			"value": "4   X 6YD",
			"label": "4   X 6YD"
	},
	{
			"value": "4   X 6YDS",
			"label": "4   X 6YDS"
	},
	{
			"value": "4  X 6YDS",
			"label": "4  X 6YDS"
	},
	{
			"value": "4X7",
			"label": "4X7"
	},
	{
			"value": "4   X 7.5   X 9.5",
			"label": "4   X 7.5   X 9.5"
	},
	{
			"value": "4 X 8",
			"label": "4 X 8"
	},
	{
			"value": "4X8",
			"label": "4X8"
	},
	{
			"value": "4 X 8.25",
			"label": "4 X 8.25"
	},
	{
			"value": "4   X 8YDS",
			"label": "4   X 8YDS"
	},
	{
			"value": "4 X 9",
			"label": "4 X 9"
	},
	{
			"value": "4  X 9",
			"label": "4  X 9"
	},
	{
			"value": "4X9",
			"label": "4X9"
	},
	{
			"value": "4X-LARGE",
			"label": "4X-LARGE"
	},
	{
			"value": "4 YARDS",
			"label": "4 YARDS"
	},
	{
			"value": "4YD",
			"label": "4YD"
	},
	{
			"value": "4YDS",
			"label": "4YDS"
	},
	{
			"value": ".5",
			"label": ".5"
	},
	{
			"value": "5",
			"label": "5"
	},
	{
			"value": "5\"\"",
			"label": "5\"\""
	},
	{
			"value": ".50",
			"label": ".50"
	},
	{
			"value": "50",
			"label": "50"
	},
	{
			"value": "500",
			"label": "500"
	},
	{
			"value": "5000",
			"label": "5000"
	},
	{
			"value": "50000",
			"label": "50000"
	},
	{
			"value": "5004",
			"label": "5004"
	},
	{
			"value": "500 CT BAG",
			"label": "500 CT BAG"
	},
	{
			"value": "500M",
			"label": "500M"
	},
	{
			"value": "5.00 X 2.25",
			"label": "5.00 X 2.25"
	},
	{
			"value": "5.00 X 3.00",
			"label": "5.00 X 3.00"
	},
	{
			"value": "5.00 X 3.50",
			"label": "5.00 X 3.50"
	},
	{
			"value": "5.00 X 3.62",
			"label": "5.00 X 3.62"
	},
	{
			"value": "5.00 X 4.00",
			"label": "5.00 X 4.00"
	},
	{
			"value": "500 YDS",
			"label": "500 YDS"
	},
	{
			"value": "5016",
			"label": "5016"
	},
	{
			"value": "5.05 OZ",
			"label": "5.05 OZ"
	},
	{
			"value": "508",
			"label": "508"
	},
	{
			"value": "50.83 X 15 X 72.6875",
			"label": "50.83 X 15 X 72.6875"
	},
	{
			"value": "50-84",
			"label": "50-84"
	},
	{
			"value": "50-86",
			"label": "50-86"
	},
	{
			"value": "50 CT",
			"label": "50 CT"
	},
	{
			"value": "50CT",
			"label": "50CT"
	},
	{
			"value": "50 FOOT",
			"label": "50 FOOT"
	},
	{
			"value": "50FT",
			"label": "50FT"
	},
	{
			"value": "50G",
			"label": "50G"
	},
	{
			"value": "50GR",
			"label": "50GR"
	},
	{
			"value": "5.0 IN",
			"label": "5.0 IN"
	},
	{
			"value": "50IN X 60IN",
			"label": "50IN X 60IN"
	},
	{
			"value": "50LB",
			"label": "50LB"
	},
	{
			"value": "50 ML",
			"label": "50 ML"
	},
	{
			"value": "50MM",
			"label": "50MM"
	},
	{
			"value": ".50 OUNCE",
			"label": ".50 OUNCE"
	},
	{
			"value": "50 OUNCES",
			"label": "50 OUNCES"
	},
	{
			"value": ".50 OZ",
			"label": ".50 OZ"
	},
	{
			"value": "5.0 OZ",
			"label": "5.0 OZ"
	},
	{
			"value": "50 PC",
			"label": "50 PC"
	},
	{
			"value": "50PC",
			"label": "50PC"
	},
	{
			"value": "50 PIECES OF FAT QUA",
			"label": "50 PIECES OF FAT QUA"
	},
	{
			"value": "50PK",
			"label": "50PK"
	},
	{
			"value": "50 TEALITES",
			"label": "50 TEALITES"
	},
	{
			"value": "50 X 110",
			"label": "50 X 110"
	},
	{
			"value": "50   X 110",
			"label": "50   X 110"
	},
	{
			"value": "50 X 14",
			"label": "50 X 14"
	},
	{
			"value": "50 X 15",
			"label": "50 X 15"
	},
	{
			"value": "50   X 18",
			"label": "50   X 18"
	},
	{
			"value": "50   X 30",
			"label": "50   X 30"
	},
	{
			"value": "50X40",
			"label": "50X40"
	},
	{
			"value": "50 X 42",
			"label": "50 X 42"
	},
	{
			"value": "50X60",
			"label": "50X60"
	},
	{
			"value": "50X60 IN.",
			"label": "50X60 IN."
	},
	{
			"value": "50   X 63",
			"label": "50   X 63"
	},
	{
			"value": "50 X 64",
			"label": "50 X 64"
	},
	{
			"value": "50X70",
			"label": "50X70"
	},
	{
			"value": "50X70IN+5INX2",
			"label": "50X70IN+5INX2"
	},
	{
			"value": "50 X 84",
			"label": "50 X 84"
	},
	{
			"value": "50   X 84",
			"label": "50   X 84"
	},
	{
			"value": "50   X 96",
			"label": "50   X 96"
	},
	{
			"value": "50  X96",
			"label": "50  X96"
	},
	{
			"value": "50YD",
			"label": "50YD"
	},
	{
			"value": "50 YDS",
			"label": "50 YDS"
	},
	{
			"value": "5.1",
			"label": "5.1"
	},
	{
			"value": "5.1\"\"",
			"label": "5.1\"\""
	},
	{
			"value": "51",
			"label": "51"
	},
	{
			"value": "510",
			"label": "510"
	},
	{
			"value": "5.10 X 2.50",
			"label": "5.10 X 2.50"
	},
	{
			"value": "51 1/2 X 27 1/2",
			"label": "51 1/2 X 27 1/2"
	},
	{
			"value": "5.11 X 3.4 X 3.94",
			"label": "5.11 X 3.4 X 3.94"
	},
	{
			"value": "5 1/2\"\"",
			"label": "5 1/2\"\""
	},
	{
			"value": "5.12",
			"label": "5.12"
	},
	{
			"value": "5-1/2  H   4  W",
			"label": "5-1/2  H   4  W"
	},
	{
			"value": "5.12 IN",
			"label": "5.12 IN"
	},
	{
			"value": "5 1/2 (IN) X 8 1/2 (",
			"label": "5 1/2 (IN) X 8 1/2 ("
	},
	{
			"value": "5.12 X 1.87",
			"label": "5.12 X 1.87"
	},
	{
			"value": "5.12 X 3.75",
			"label": "5.12 X 3.75"
	},
	{
			"value": "5.12 X5.12 X7.87",
			"label": "5.12 X5.12 X7.87"
	},
	{
			"value": "5 1/4",
			"label": "5 1/4"
	},
	{
			"value": "5-1/4  H   1  W",
			"label": "5-1/4  H   1  W"
	},
	{
			"value": "5-1/4  H X 3-1/2  W",
			"label": "5-1/4  H X 3-1/2  W"
	},
	{
			"value": "51500",
			"label": "51500"
	},
	{
			"value": "5.1 5.5 5.3",
			"label": "5.1 5.5 5.3"
	},
	{
			"value": "5/16",
			"label": "5/16"
	},
	{
			"value": "5/16\"\" (8MM)",
			"label": "5/16\"\" (8MM)"
	},
	{
			"value": "5/16 IN",
			"label": "5/16 IN"
	},
	{
			"value": "5/16 IN.",
			"label": "5/16 IN."
	},
	{
			"value": "5-1/8",
			"label": "5-1/8"
	},
	{
			"value": "5184",
			"label": "5184"
	},
	{
			"value": "51.92",
			"label": "51.92"
	},
	{
			"value": "-5196",
			"label": "-5196"
	},
	{
			"value": "5.1 OZ",
			"label": "5.1 OZ"
	},
	{
			"value": "5.1OZ",
			"label": "5.1OZ"
	},
	{
			"value": "5.1 X 2.55 X 0.78",
			"label": "5.1 X 2.55 X 0.78"
	},
	{
			"value": "5.1   X 3.1   X 2.7",
			"label": "5.1   X 3.1   X 2.7"
	},
	{
			"value": "5.1   X 3.9",
			"label": "5.1   X 3.9"
	},
	{
			"value": "52",
			"label": "52"
	},
	{
			"value": "5200",
			"label": "5200"
	},
	{
			"value": "5.23 X 2.50",
			"label": "5.23 X 2.50"
	},
	{
			"value": "5.24 X 1.92",
			"label": "5.24 X 1.92"
	},
	{
			"value": "5.25",
			"label": "5.25"
	},
	{
			"value": "5.25  IN",
			"label": "5.25  IN"
	},
	{
			"value": "5.25OZ",
			"label": "5.25OZ"
	},
	{
			"value": "5.25 QT",
			"label": "5.25 QT"
	},
	{
			"value": "5.25 X 2.00",
			"label": "5.25 X 2.00"
	},
	{
			"value": "5.25X.25X6.5",
			"label": "5.25X.25X6.5"
	},
	{
			"value": "5.25X4.5X1.375",
			"label": "5.25X4.5X1.375"
	},
	{
			"value": "5.25 X 5.25 X 1.875",
			"label": "5.25 X 5.25 X 1.875"
	},
	{
			"value": "5.25   X 5.25   X 9",
			"label": "5.25   X 5.25   X 9"
	},
	{
			"value": "5.25 X 5.25 X 9.5",
			"label": "5.25 X 5.25 X 9.5"
	},
	{
			"value": "5.25 X 6.75 X 5.50",
			"label": "5.25 X 6.75 X 5.50"
	},
	{
			"value": "5.25 X 6 INCHES",
			"label": "5.25 X 6 INCHES"
	},
	{
			"value": "5.25  X7.25",
			"label": "5.25  X7.25"
	},
	{
			"value": "5.25   X 7.5   X 2.",
			"label": "5.25   X 7.5   X 2."
	},
	{
			"value": "5.25  X8.25",
			"label": "5.25  X8.25"
	},
	{
			"value": "5.25X8.44",
			"label": "5.25X8.44"
	},
	{
			"value": "5.25X8.5",
			"label": "5.25X8.5"
	},
	{
			"value": "5.25X8.50",
			"label": "5.25X8.50"
	},
	{
			"value": "-528",
			"label": "-528"
	},
	{
			"value": "52.8",
			"label": "52.8"
	},
	{
			"value": "5.28 OZ",
			"label": "5.28 OZ"
	},
	{
			"value": "5.29 OZ",
			"label": "5.29 OZ"
	},
	{
			"value": "52CT GIFT TAGS",
			"label": "52CT GIFT TAGS"
	},
	{
			"value": "5.2G",
			"label": "5.2G"
	},
	{
			"value": "52IN",
			"label": "52IN"
	},
	{
			"value": "5.2IN",
			"label": "5.2IN"
	},
	{
			"value": "52 LITER",
			"label": "52 LITER"
	},
	{
			"value": ".52 OZ",
			"label": ".52 OZ"
	},
	{
			"value": "5.2 OZ",
			"label": "5.2 OZ"
	},
	{
			"value": "52 PIECE SET",
			"label": "52 PIECE SET"
	},
	{
			"value": "52   SQUARE",
			"label": "52   SQUARE"
	},
	{
			"value": "5.2 X 0.5 X 8.7",
			"label": "5.2 X 0.5 X 8.7"
	},
	{
			"value": "52 X 18",
			"label": "52 X 18"
	},
	{
			"value": "5.2 X .2 X 7.4",
			"label": "5.2 X .2 X 7.4"
	},
	{
			"value": "5.3\"\"",
			"label": "5.3\"\""
	},
	{
			"value": "53",
			"label": "53"
	},
	{
			"value": "5300",
			"label": "5300"
	},
	{
			"value": "53000",
			"label": "53000"
	},
	{
			"value": "5 3.1 3.3",
			"label": "5 3.1 3.3"
	},
	{
			"value": "5.31 OZ",
			"label": "5.31 OZ"
	},
	{
			"value": "5.32 OZ",
			"label": "5.32 OZ"
	},
	{
			"value": "5.3 4.5 8.6",
			"label": "5.3 4.5 8.6"
	},
	{
			"value": "5.3 4.5 9.1",
			"label": "5.3 4.5 9.1"
	},
	{
			"value": "5.34 OZ/160 G",
			"label": "5.34 OZ/160 G"
	},
	{
			"value": "5 34X45",
			"label": "5 34X45"
	},
	{
			"value": "53/54",
			"label": "53/54"
	},
	{
			"value": "53.58",
			"label": "53.58"
	},
	{
			"value": "53.5IN",
			"label": "53.5IN"
	},
	{
			"value": "5.375 X 5.375 X 1.69",
			"label": "5.375 X 5.375 X 1.69"
	},
	{
			"value": "5.375 X 5.5 X 0.75",
			"label": "5.375 X 5.5 X 0.75"
	},
	{
			"value": "5.375   X 8.5",
			"label": "5.375   X 8.5"
	},
	{
			"value": "-5376",
			"label": "-5376"
	},
	{
			"value": "5376",
			"label": "5376"
	},
	{
			"value": "5.38X5.25X0.50",
			"label": "5.38X5.25X0.50"
	},
	{
			"value": "5.38 X 8.388",
			"label": "5.38 X 8.388"
	},
	{
			"value": "5.3 IN",
			"label": "5.3 IN"
	},
	{
			"value": "53 IN",
			"label": "53 IN"
	},
	{
			"value": "53IN",
			"label": "53IN"
	},
	{
			"value": "53L",
			"label": "53L"
	},
	{
			"value": ".53 OZ",
			"label": ".53 OZ"
	},
	{
			"value": ".53OZ",
			"label": ".53OZ"
	},
	{
			"value": "5.3 OZ",
			"label": "5.3 OZ"
	},
	{
			"value": "5.3OZ",
			"label": "5.3OZ"
	},
	{
			"value": "5.3OZ/150G",
			"label": "5.3OZ/150G"
	},
	{
			"value": "5.3 OZ/ 150 GRAMS",
			"label": "5.3 OZ/ 150 GRAMS"
	},
	{
			"value": "54",
			"label": "54"
	},
	{
			"value": "540",
			"label": "540"
	},
	{
			"value": "5400",
			"label": "5400"
	},
	{
			"value": "54.3",
			"label": "54.3"
	},
	{
			"value": "543",
			"label": "543"
	},
	{
			"value": "54.5",
			"label": "54.5"
	},
	{
			"value": "54.50",
			"label": "54.50"
	},
	{
			"value": "54/55",
			"label": "54/55"
	},
	{
			"value": "54/56",
			"label": "54/56"
	},
	{
			"value": "54   BASE 51   EMB",
			"label": "54   BASE 51   EMB"
	},
	{
			"value": "54IN",
			"label": "54IN"
	},
	{
			"value": ".54 OZ",
			"label": ".54 OZ"
	},
	{
			"value": "5.4 OZ",
			"label": "5.4 OZ"
	},
	{
			"value": "5.4 OZ.",
			"label": "5.4 OZ."
	},
	{
			"value": "54OZ",
			"label": "54OZ"
	},
	{
			"value": "54   WIDTH",
			"label": "54   WIDTH"
	},
	{
			"value": "54 X 108",
			"label": "54 X 108"
	},
	{
			"value": "54   X 108",
			"label": "54   X 108"
	},
	{
			"value": "54 X 16",
			"label": "54 X 16"
	},
	{
			"value": "54 X 18",
			"label": "54 X 18"
	},
	{
			"value": "54 X 42",
			"label": "54 X 42"
	},
	{
			"value": "54 X 48",
			"label": "54 X 48"
	},
	{
			"value": "5.4   X5.4   X 10",
			"label": "5.4   X5.4   X 10"
	},
	{
			"value": "54 X 84",
			"label": "54 X 84"
	},
	{
			"value": "54 X 96",
			"label": "54 X 96"
	},
	{
			"value": "54   X 9YDS",
			"label": "54   X 9YDS"
	},
	{
			"value": "5.5",
			"label": "5.5"
	},
	{
			"value": "5.5\"\"",
			"label": "5.5\"\""
	},
	{
			"value": "55",
			"label": "55"
	},
	{
			"value": "55\"\"",
			"label": "55\"\""
	},
	{
			"value": "550",
			"label": "550"
	},
	{
			"value": "5500",
			"label": "5500"
	},
	{
			"value": "55000",
			"label": "55000"
	},
	{
			"value": "5502",
			"label": "5502"
	},
	{
			"value": "5.50 X 1.75 X .25",
			"label": "5.50 X 1.75 X .25"
	},
	{
			"value": "5.50 X 1.88 X .25",
			"label": "5.50 X 1.88 X .25"
	},
	{
			"value": "5.50 X 3.37",
			"label": "5.50 X 3.37"
	},
	{
			"value": "5.50X6.50X1.13",
			"label": "5.50X6.50X1.13"
	},
	{
			"value": "5.52",
			"label": "5.52"
	},
	{
			"value": "5.52 IN",
			"label": "5.52 IN"
	},
	{
			"value": "5.52 X 0.38 X 7.5",
			"label": "5.52 X 0.38 X 7.5"
	},
	{
			"value": "5532",
			"label": "5532"
	},
	{
			"value": "55.5",
			"label": "55.5"
	},
	{
			"value": "5.55 X 12.125 X 5.75",
			"label": "5.55 X 12.125 X 5.75"
	},
	{
			"value": "5568",
			"label": "5568"
	},
	{
			"value": "5.58 OZ",
			"label": "5.58 OZ"
	},
	{
			"value": "55.9",
			"label": "55.9"
	},
	{
			"value": "55   BASE 53   EMB",
			"label": "55   BASE 53   EMB"
	},
	{
			"value": "5.5   DIAMETER",
			"label": "5.5   DIAMETER"
	},
	{
			"value": "5.5 FEET",
			"label": "5.5 FEET"
	},
	{
			"value": "5.5G",
			"label": "5.5G"
	},
	{
			"value": "5.5  H X 3.25  W X",
			"label": "5.5  H X 3.25  W X"
	},
	{
			"value": "5.5 IN",
			"label": "5.5 IN"
	},
	{
			"value": "5.5 IN.",
			"label": "5.5 IN."
	},
	{
			"value": "55IN",
			"label": "55IN"
	},
	{
			"value": "5.5IN",
			"label": "5.5IN"
	},
	{
			"value": "5.5 INCH",
			"label": "5.5 INCH"
	},
	{
			"value": "5.5 INCHES",
			"label": "5.5 INCHES"
	},
	{
			"value": "5.5-INCH X 9.25-INCH",
			"label": "5.5-INCH X 9.25-INCH"
	},
	{
			"value": "5.5LB",
			"label": "5.5LB"
	},
	{
			"value": "55 LITER",
			"label": "55 LITER"
	},
	{
			"value": "55 ML",
			"label": "55 ML"
	},
	{
			"value": ".55 OZ",
			"label": ".55 OZ"
	},
	{
			"value": "5.5 OZ",
			"label": "5.5 OZ"
	},
	{
			"value": "55 PC",
			"label": "55 PC"
	},
	{
			"value": "5.5 QUART",
			"label": "5.5 QUART"
	},
	{
			"value": "5.5X1.1875X12",
			"label": "5.5X1.1875X12"
	},
	{
			"value": "5.5 X 11 X 0.5",
			"label": "5.5 X 11 X 0.5"
	},
	{
			"value": "5.5 X 11 X 7",
			"label": "5.5 X 11 X 7"
	},
	{
			"value": "5.5 X 13  X 2",
			"label": "5.5 X 13  X 2"
	},
	{
			"value": "5.5X15.75X.75",
			"label": "5.5X15.75X.75"
	},
	{
			"value": "5.5 X 1.75 X .25",
			"label": "5.5 X 1.75 X .25"
	},
	{
			"value": "5.5X1X6.5",
			"label": "5.5X1X6.5"
	},
	{
			"value": "5.5   X 1   X 7.25",
			"label": "5.5   X 1   X 7.25"
	},
	{
			"value": "5.5 X .1 X 8.25",
			"label": "5.5 X .1 X 8.25"
	},
	{
			"value": "5.5X2.2X5.5",
			"label": "5.5X2.2X5.5"
	},
	{
			"value": "5.5X23.75X.75",
			"label": "5.5X23.75X.75"
	},
	{
			"value": "5.5X.25X15",
			"label": "5.5X.25X15"
	},
	{
			"value": "5.5X.25X5.5",
			"label": "5.5X.25X5.5"
	},
	{
			"value": "5.5 X 2 X .50",
			"label": "5.5 X 2 X .50"
	},
	{
			"value": "5.5X30.5",
			"label": "5.5X30.5"
	},
	{
			"value": "5.5X3.5",
			"label": "5.5X3.5"
	},
	{
			"value": "5.5 X 3.88",
			"label": "5.5 X 3.88"
	},
	{
			"value": "5.5 X 3 X 0.25 INCH",
			"label": "5.5 X 3 X 0.25 INCH"
	},
	{
			"value": "5.5   X 4.7   X 11",
			"label": "5.5   X 4.7   X 11"
	},
	{
			"value": "5.5 X 4.7 X 8.9",
			"label": "5.5 X 4.7 X 8.9"
	},
	{
			"value": "5.5 X 4 X 0.25 INCH",
			"label": "5.5 X 4 X 0.25 INCH"
	},
	{
			"value": "5.5 X 4 X 4.5, 18 O",
			"label": "5.5 X 4 X 4.5, 18 O"
	},
	{
			"value": "5.5X4X9",
			"label": "5.5X4X9"
	},
	{
			"value": "5.5X5.5",
			"label": "5.5X5.5"
	},
	{
			"value": "5.5X5.5X10",
			"label": "5.5X5.5X10"
	},
	{
			"value": "5.5X5.5X11.5",
			"label": "5.5X5.5X11.5"
	},
	{
			"value": "5.5X5.5X12",
			"label": "5.5X5.5X12"
	},
	{
			"value": "5.5 X 5.5 X 18.25",
			"label": "5.5 X 5.5 X 18.25"
	},
	{
			"value": "5.5X5.5X8.3",
			"label": "5.5X5.5X8.3"
	},
	{
			"value": "5.5X5X5.5",
			"label": "5.5X5X5.5"
	},
	{
			"value": "5.5   X 6",
			"label": "5.5   X 6"
	},
	{
			"value": "5.5X6",
			"label": "5.5X6"
	},
	{
			"value": "5.5 X 6.5",
			"label": "5.5 X 6.5"
	},
	{
			"value": "5.5 X6.5",
			"label": "5.5 X6.5"
	},
	{
			"value": "5.5X6.5",
			"label": "5.5X6.5"
	},
	{
			"value": "5.5X6.75X6.75",
			"label": "5.5X6.75X6.75"
	},
	{
			"value": "5.5   X 7.0",
			"label": "5.5   X 7.0"
	},
	{
			"value": "5.5 X 8.5",
			"label": "5.5 X 8.5"
	},
	{
			"value": "5.5X8.5",
			"label": "5.5X8.5"
	},
	{
			"value": "5.5 X 8.5 X 2.5",
			"label": "5.5 X 8.5 X 2.5"
	},
	{
			"value": "5.5   X 8.5   X .25",
			"label": "5.5   X 8.5   X .25"
	},
	{
			"value": "56",
			"label": "56"
	},
	{
			"value": "5600",
			"label": "5600"
	},
	{
			"value": "5.625 X 3.1875 X 1.9",
			"label": "5.625 X 3.1875 X 1.9"
	},
	{
			"value": "565",
			"label": "565"
	},
	{
			"value": "56/57",
			"label": "56/57"
	},
	{
			"value": "56.7",
			"label": "56.7"
	},
	{
			"value": "569",
			"label": "569"
	},
	{
			"value": "56   BASE 54   EMB",
			"label": "56   BASE 54   EMB"
	},
	{
			"value": "5-6IN",
			"label": "5-6IN"
	},
	{
			"value": ".56 OZ",
			"label": ".56 OZ"
	},
	{
			"value": "5.6 OZ",
			"label": "5.6 OZ"
	},
	{
			"value": "56 QT",
			"label": "56 QT"
	},
	{
			"value": "56 QUART",
			"label": "56 QUART"
	},
	{
			"value": "56 UNITS EACH 1.75OZ",
			"label": "56 UNITS EACH 1.75OZ"
	},
	{
			"value": "56   WIDE",
			"label": "56   WIDE"
	},
	{
			"value": "5.6X3.9X12",
			"label": "5.6X3.9X12"
	},
	{
			"value": "57",
			"label": "57"
	},
	{
			"value": "57''",
			"label": "57''"
	},
	{
			"value": "5700",
			"label": "5700"
	},
	{
			"value": "-5712",
			"label": "-5712"
	},
	{
			"value": "5.74 OZ / 162.8 G",
			"label": "5.74 OZ / 162.8 G"
	},
	{
			"value": "5.75",
			"label": "5.75"
	},
	{
			"value": "575",
			"label": "575"
	},
	{
			"value": "57/58",
			"label": "57/58"
	},
	{
			"value": "5.75 IN",
			"label": "5.75 IN"
	},
	{
			"value": "5.75IN",
			"label": "5.75IN"
	},
	{
			"value": "5.75 INCH",
			"label": "5.75 INCH"
	},
	{
			"value": "5.75 OZ",
			"label": "5.75 OZ"
	},
	{
			"value": "5.75X3",
			"label": "5.75X3"
	},
	{
			"value": "5.75X3.5X1.25",
			"label": "5.75X3.5X1.25"
	},
	{
			"value": "5.75X4.75X2.6",
			"label": "5.75X4.75X2.6"
	},
	{
			"value": "5.75   X 5.75   SHE",
			"label": "5.75   X 5.75   SHE"
	},
	{
			"value": "5.75 X 5.75 X 3.25 I",
			"label": "5.75 X 5.75 X 3.25 I"
	},
	{
			"value": "5.75X5.75X6.5",
			"label": "5.75X5.75X6.5"
	},
	{
			"value": "5.75X.75X10",
			"label": "5.75X.75X10"
	},
	{
			"value": "5.75X7.75X6.5",
			"label": "5.75X7.75X6.5"
	},
	{
			"value": "5.75   X 7   X 5.75",
			"label": "5.75   X 7   X 5.75"
	},
	{
			"value": "5.75  X8.25",
			"label": "5.75  X8.25"
	},
	{
			"value": "5.75   X 8.75   SHE",
			"label": "5.75   X 8.75   SHE"
	},
	{
			"value": "576",
			"label": "576"
	},
	{
			"value": "5760",
			"label": "5760"
	},
	{
			"value": "5 7 .75",
			"label": "5 7 .75"
	},
	{
			"value": "5.77   X 4.81   X 0",
			"label": "5.77   X 4.81   X 0"
	},
	{
			"value": "5.79   X 2.89",
			"label": "5.79   X 2.89"
	},
	{
			"value": "57IN",
			"label": "57IN"
	},
	{
			"value": "5.7 INCH",
			"label": "5.7 INCH"
	},
	{
			"value": "5.7''LX5.7''WX9.4''H",
			"label": "5.7''LX5.7''WX9.4''H"
	},
	{
			"value": "57MM",
			"label": "57MM"
	},
	{
			"value": "5.7 QT",
			"label": "5.7 QT"
	},
	{
			"value": "5.7 X .5 X .5",
			"label": "5.7 X .5 X .5"
	},
	{
			"value": "5.7 X .9 X 7",
			"label": "5.7 X .9 X 7"
	},
	{
			"value": "5 8\"\"",
			"label": "5 8\"\""
	},
	{
			"value": "5-8",
			"label": "5-8"
	},
	{
			"value": "5/8",
			"label": "5/8"
	},
	{
			"value": "5/8\"\"",
			"label": "5/8\"\""
	},
	{
			"value": "58",
			"label": "58"
	},
	{
			"value": "580",
			"label": "580"
	},
	{
			"value": "5/8   (16MM)",
			"label": "5/8   (16MM)"
	},
	{
			"value": "5/8  (16MM)",
			"label": "5/8  (16MM)"
	},
	{
			"value": "5/8 (16MM)",
			"label": "5/8 (16MM)"
	},
	{
			"value": "58/59",
			"label": "58/59"
	},
	{
			"value": "58/60",
			"label": "58/60"
	},
	{
			"value": "5.86 OZ / 166 G",
			"label": "5.86 OZ / 166 G"
	},
	{
			"value": " 5.875 X 3.5 X 1,312",
			"label": " 5.875 X 3.5 X 1,312"
	},
	{
			"value": "5.88 X 7.5",
			"label": "5.88 X 7.5"
	},
	{
			"value": "5.88 X 7.50",
			"label": "5.88 X 7.50"
	},
	{
			"value": "5.89 OZ",
			"label": "5.89 OZ"
	},
	{
			"value": " 5  , 8   AND SNIP",
			"label": " 5  , 8   AND SNIP"
	},
	{
			"value": "58   BASE 55   EMB",
			"label": "58   BASE 55   EMB"
	},
	{
			"value": "5/8 IN",
			"label": "5/8 IN"
	},
	{
			"value": "5/8 IN.",
			"label": "5/8 IN."
	},
	{
			"value": "58 IN",
			"label": "58 IN"
	},
	{
			"value": "5/8 INCH",
			"label": "5/8 INCH"
	},
	{
			"value": "5/8 IN X 12 FT",
			"label": "5/8 IN X 12 FT"
	},
	{
			"value": ".58 OZ",
			"label": ".58 OZ"
	},
	{
			"value": ".58OZ",
			"label": ".58OZ"
	},
	{
			"value": "5.8 OZ",
			"label": "5.8 OZ"
	},
	{
			"value": "5.8 QT",
			"label": "5.8 QT"
	},
	{
			"value": "5.8QT",
			"label": "5.8QT"
	},
	{
			"value": "5 8TH",
			"label": "5 8TH"
	},
	{
			"value": "5 8TH INCH",
			"label": "5 8TH INCH"
	},
	{
			"value": "5 8TH X 9 FT",
			"label": "5 8TH X 9 FT"
	},
	{
			"value": "5 8TH X 9FT",
			"label": "5 8TH X 9FT"
	},
	{
			"value": "5/8TH X 9FT",
			"label": "5/8TH X 9FT"
	},
	{
			"value": "5/8   X 10 YDS",
			"label": "5/8   X 10 YDS"
	},
	{
			"value": "58 X 12",
			"label": "58 X 12"
	},
	{
			"value": "5/8   X 12FT",
			"label": "5/8   X 12FT"
	},
	{
			"value": "5/8   X 12YDS",
			"label": "5/8   X 12YDS"
	},
	{
			"value": "58X13",
			"label": "58X13"
	},
	{
			"value": "5/8   X 15 YDS",
			"label": "5/8   X 15 YDS"
	},
	{
			"value": "58 X 18",
			"label": "58 X 18"
	},
	{
			"value": "5/8  X18'",
			"label": "5/8  X18'"
	},
	{
			"value": "5/8   X 20 YDS",
			"label": "5/8   X 20 YDS"
	},
	{
			"value": "5/8  X20YDS",
			"label": "5/8  X20YDS"
	},
	{
			"value": "5/8   X 3FT",
			"label": "5/8   X 3FT"
	},
	{
			"value": "5.8X5.8X6.5",
			"label": "5.8X5.8X6.5"
	},
	{
			"value": "5/8   X 9'",
			"label": "5/8   X 9'"
	},
	{
			"value": "5/8   X9'",
			"label": "5/8   X9'"
	},
	{
			"value": "5/8  X9'",
			"label": "5/8  X9'"
	},
	{
			"value": "5.9\"\"",
			"label": "5.9\"\""
	},
	{
			"value": "59",
			"label": "59"
	},
	{
			"value": "5.90 X 5.70",
			"label": "5.90 X 5.70"
	},
	{
			"value": "5.91",
			"label": "5.91"
	},
	{
			"value": "5.91 IN",
			"label": "5.91 IN"
	},
	{
			"value": "5.91 INCH",
			"label": "5.91 INCH"
	},
	{
			"value": "5.91  X 3.74  EA",
			"label": "5.91  X 3.74  EA"
	},
	{
			"value": "5.92 OZ",
			"label": "5.92 OZ"
	},
	{
			"value": "5.9 4.5 7.5",
			"label": "5.9 4.5 7.5"
	},
	{
			"value": "59.5",
			"label": "59.5"
	},
	{
			"value": "59-60",
			"label": "59-60"
	},
	{
			"value": "59/60",
			"label": "59/60"
	},
	{
			"value": "5976",
			"label": "5976"
	},
	{
			"value": "59.8",
			"label": "59.8"
	},
	{
			"value": "59/90",
			"label": "59/90"
	},
	{
			"value": "5.9 IN",
			"label": "5.9 IN"
	},
	{
			"value": "5.9IN *4",
			"label": "5.9IN *4"
	},
	{
			"value": "5.9IN X 4.5IN X 7.5I",
			"label": "5.9IN X 4.5IN X 7.5I"
	},
	{
			"value": "59X22",
			"label": "59X22"
	},
	{
			"value": "5.9X5.9X9.8",
			"label": "5.9X5.9X9.8"
	},
	{
			"value": "5.9 X 8.26 X 12.59",
			"label": "5.9 X 8.26 X 12.59"
	},
	{
			"value": "5 BOTTLES - 4OZ EACH",
			"label": "5 BOTTLES - 4OZ EACH"
	},
	{
			"value": "5 - BULKY",
			"label": "5 - BULKY"
	},
	{
			"value": "5 CT",
			"label": "5 CT"
	},
	{
			"value": "5CT",
			"label": "5CT"
	},
	{
			"value": "5-DRAWER",
			"label": "5-DRAWER"
	},
	{
			"value": "5FT",
			"label": "5FT"
	},
	{
			"value": "5G",
			"label": "5G"
	},
	{
			"value": "5 GRAM",
			"label": "5 GRAM"
	},
	{
			"value": "5GRAMS",
			"label": "5GRAMS"
	},
	{
			"value": "5 GREEN",
			"label": "5 GREEN"
	},
	{
			"value": "5HALF",
			"label": "5HALF"
	},
	{
			"value": "5HALF IN",
			"label": "5HALF IN"
	},
	{
			"value": "5 IN",
			"label": "5 IN"
	},
	{
			"value": ".5IN",
			"label": ".5IN"
	},
	{
			"value": "5IN",
			"label": "5IN"
	},
	{
			"value": "5IN, 7IN, 8IN",
			"label": "5IN, 7IN, 8IN"
	},
	{
			"value": ".5 INCH",
			"label": ".5 INCH"
	},
	{
			"value": "5 INCH",
			"label": "5 INCH"
	},
	{
			"value": "5 INCHES",
			"label": "5 INCHES"
	},
	{
			"value": "5 INCH X 3 FT",
			"label": "5 INCH X 3 FT"
	},
	{
			"value": ".5INCH X 4FT",
			"label": ".5INCH X 4FT"
	},
	{
			"value": "5IN H",
			"label": "5IN H"
	},
	{
			"value": "5INX5IN",
			"label": "5INX5IN"
	},
	{
			"value": "5IN X 8IN",
			"label": "5IN X 8IN"
	},
	{
			"value": "5 IVORY",
			"label": "5 IVORY"
	},
	{
			"value": "5 LB.",
			"label": "5 LB."
	},
	{
			"value": "5LB",
			"label": "5LB"
	},
	{
			"value": "5LB BOX",
			"label": "5LB BOX"
	},
	{
			"value": "5 LITER",
			"label": "5 LITER"
	},
	{
			"value": "5M",
			"label": "5M"
	},
	{
			"value": ".5MM",
			"label": ".5MM"
	},
	{
			"value": "5MM",
			"label": "5MM"
	},
	{
			"value": "5M MED",
			"label": "5M MED"
	},
	{
			"value": "5 MM X 5 M",
			"label": "5 MM X 5 M"
	},
	{
			"value": "5 MM X 5M",
			"label": "5 MM X 5M"
	},
	{
			"value": "5MM X 9 FT",
			"label": "5MM X 9 FT"
	},
	{
			"value": "5MM X 9FT",
			"label": "5MM X 9FT"
	},
	{
			"value": ".5 OZ",
			"label": ".5 OZ"
	},
	{
			"value": "5 OZ",
			"label": "5 OZ"
	},
	{
			"value": "5 OZ.",
			"label": "5 OZ."
	},
	{
			"value": ".5OZ",
			"label": ".5OZ"
	},
	{
			"value": "5OZ",
			"label": "5OZ"
	},
	{
			"value": "5 OZ/ 140 GRAMS",
			"label": "5 OZ/ 140 GRAMS"
	},
	{
			"value": "5OZ/142G",
			"label": "5OZ/142G"
	},
	{
			"value": "5OZ/ 142 GRAMS",
			"label": "5OZ/ 142 GRAMS"
	},
	{
			"value": ".5OZ 3/PKG",
			"label": ".5OZ 3/PKG"
	},
	{
			"value": "5PC",
			"label": "5PC"
	},
	{
			"value": "5PC BUNDLE",
			"label": "5PC BUNDLE"
	},
	{
			"value": "5 PCS",
			"label": "5 PCS"
	},
	{
			"value": "5 PCS - 18  X21",
			"label": "5 PCS - 18  X21"
	},
	{
			"value": "5 PCS IN A PACK/1 IN",
			"label": "5 PCS IN A PACK/1 IN"
	},
	{
			"value": "5 PIECES",
			"label": "5 PIECES"
	},
	{
			"value": "5 PIECES FQ BUNDLE",
			"label": "5 PIECES FQ BUNDLE"
	},
	{
			"value": "5PK",
			"label": "5PK"
	},
	{
			"value": "5 QT",
			"label": "5 QT"
	},
	{
			"value": "5 RED",
			"label": "5 RED"
	},
	{
			"value": "5 SET",
			"label": "5 SET"
	},
	{
			"value": "5 SHEET",
			"label": "5 SHEET"
	},
	{
			"value": "5TH CURATION",
			"label": "5TH CURATION"
	},
	{
			"value": "5 WATTS",
			"label": "5 WATTS"
	},
	{
			"value": "5  W X 5  D X 13  H",
			"label": "5  W X 5  D X 13  H"
	},
	{
			"value": "5   W X 8   L X .05",
			"label": "5   W X 8   L X .05"
	},
	{
			"value": "5 X 10",
			"label": "5 X 10"
	},
	{
			"value": "5X11",
			"label": "5X11"
	},
	{
			"value": "5 X 1.1 X 8",
			"label": "5 X 1.1 X 8"
	},
	{
			"value": "5X12 MATTED TO 4 WAL",
			"label": "5X12 MATTED TO 4 WAL"
	},
	{
			"value": "5X13",
			"label": "5X13"
	},
	{
			"value": "5   X 14",
			"label": "5   X 14"
	},
	{
			"value": "5X14",
			"label": "5X14"
	},
	{
			"value": "5  X15",
			"label": "5  X15"
	},
	{
			"value": "5X16",
			"label": "5X16"
	},
	{
			"value": "5X1X7",
			"label": "5X1X7"
	},
	{
			"value": "5X1X7.5",
			"label": "5X1X7.5"
	},
	{
			"value": "5X2",
			"label": "5X2"
	},
	{
			"value": "5X24X36",
			"label": "5X24X36"
	},
	{
			"value": "5X2.5",
			"label": "5X2.5"
	},
	{
			"value": "5X2.5X2.5",
			"label": "5X2.5X2.5"
	},
	{
			"value": "5 X 2.75 X 7",
			"label": "5 X 2.75 X 7"
	},
	{
			"value": "5X3",
			"label": "5X3"
	},
	{
			"value": "5 X 3.5 INCHES",
			"label": "5 X 3.5 INCHES"
	},
	{
			"value": "5 X 3.5 X 3.85   H",
			"label": "5 X 3.5 X 3.85   H"
	},
	{
			"value": "5X3.87",
			"label": "5X3.87"
	},
	{
			"value": "5X4",
			"label": "5X4"
	},
	{
			"value": "5    X4.25",
			"label": "5    X4.25"
	},
	{
			"value": "5 X 4 X 4.4   H",
			"label": "5 X 4 X 4.4   H"
	},
	{
			"value": "5 X 5",
			"label": "5 X 5"
	},
	{
			"value": "5   X 5",
			"label": "5   X 5"
	},
	{
			"value": "5X5",
			"label": "5X5"
	},
	{
			"value": "5X5 BLACK",
			"label": "5X5 BLACK"
	},
	{
			"value": "5 X 5 X 12",
			"label": "5 X 5 X 12"
	},
	{
			"value": "5 X 5 X 15",
			"label": "5 X 5 X 15"
	},
	{
			"value": "5 X 5 X 17",
			"label": "5 X 5 X 17"
	},
	{
			"value": "5X5X4.5",
			"label": "5X5X4.5"
	},
	{
			"value": "5X5X5",
			"label": "5X5X5"
	},
	{
			"value": "5X5X6",
			"label": "5X5X6"
	},
	{
			"value": "5 X 5 X .625",
			"label": "5 X 5 X .625"
	},
	{
			"value": "5X5X8",
			"label": "5X5X8"
	},
	{
			"value": "5   X 5   X 8.75",
			"label": "5   X 5   X 8.75"
	},
	{
			"value": "5X5X9.4",
			"label": "5X5X9.4"
	},
	{
			"value": "5   X 6",
			"label": "5   X 6"
	},
	{
			"value": "5X6",
			"label": "5X6"
	},
	{
			"value": "5X6.75",
			"label": "5X6.75"
	},
	{
			"value": "5X6 SCONCE",
			"label": "5X6 SCONCE"
	},
	{
			"value": "5  X 6YDS",
			"label": "5  X 6YDS"
	},
	{
			"value": "5 X 7",
			"label": "5 X 7"
	},
	{
			"value": "5   X 7",
			"label": "5   X 7"
	},
	{
			"value": "5  X7",
			"label": "5  X7"
	},
	{
			"value": "5X7",
			"label": "5X7"
	},
	{
			"value": "5X7 2TONE STEP FILLE",
			"label": "5X7 2TONE STEP FILLE"
	},
	{
			"value": "5X7 3 PK",
			"label": "5X7 3 PK"
	},
	{
			"value": "5 X 7.5",
			"label": "5 X 7.5"
	},
	{
			"value": "5X7 BLACK",
			"label": "5X7 BLACK"
	},
	{
			"value": "5X7 BRONZE 2TONE",
			"label": "5X7 BRONZE 2TONE"
	},
	{
			"value": "5X7 ESPRESSO",
			"label": "5X7 ESPRESSO"
	},
	{
			"value": "5X7 IN",
			"label": "5X7 IN"
	},
	{
			"value": "5X7 MATT BLACK",
			"label": "5X7 MATT BLACK"
	},
	{
			"value": "5X7 OPENING",
			"label": "5X7 OPENING"
	},
	{
			"value": "5X7 PEWTER BEAD",
			"label": "5X7 PEWTER BEAD"
	},
	{
			"value": "5X7 SILVER ORNATE SP",
			"label": "5X7 SILVER ORNATE SP"
	},
	{
			"value": "5   X 7   X 1.5",
			"label": "5   X 7   X 1.5"
	},
	{
			"value": "5   X 7   X1.5",
			"label": "5   X 7   X1.5"
	},
	{
			"value": "5 X 8",
			"label": "5 X 8"
	},
	{
			"value": "5   X 8",
			"label": "5   X 8"
	},
	{
			"value": "5X8",
			"label": "5X8"
	},
	{
			"value": "5X8.5",
			"label": "5X8.5"
	},
	{
			"value": "5   X 8   X .0625",
			"label": "5   X 8   X .0625"
	},
	{
			"value": "5  X8  X1.5",
			"label": "5  X8  X1.5"
	},
	{
			"value": "5X9",
			"label": "5X9"
	},
	{
			"value": "5YD",
			"label": "5YD"
	},
	{
			"value": "6",
			"label": "6"
	},
	{
			"value": "6\"\"",
			"label": "6\"\""
	},
	{
			"value": "60",
			"label": "60"
	},
	{
			"value": "-600",
			"label": "-600"
	},
	{
			"value": "600",
			"label": "600"
	},
	{
			"value": "6000",
			"label": "6000"
	},
	{
			"value": "60000",
			"label": "60000"
	},
	{
			"value": "6000 YARDS",
			"label": "6000 YARDS"
	},
	{
			"value": "600 CT BAG",
			"label": "600 CT BAG"
	},
	{
			"value": "600G",
			"label": "600G"
	},
	{
			"value": "600GR",
			"label": "600GR"
	},
	{
			"value": "600 PC",
			"label": "600 PC"
	},
	{
			"value": "6.00X13.25X0.13",
			"label": "6.00X13.25X0.13"
	},
	{
			"value": "6.00 X 8.00",
			"label": "6.00 X 8.00"
	},
	{
			"value": "600 YARDS",
			"label": "600 YARDS"
	},
	{
			"value": "6.01 X 0.58 X 9.7",
			"label": "6.01 X 0.58 X 9.7"
	},
	{
			"value": "604",
			"label": "604"
	},
	{
			"value": "606",
			"label": "606"
	},
	{
			"value": "60/62",
			"label": "60/62"
	},
	{
			"value": "6.06 OZ",
			"label": "6.06 OZ"
	},
	{
			"value": "60-72",
			"label": "60-72"
	},
	{
			"value": "608",
			"label": "608"
	},
	{
			"value": "6084",
			"label": "6084"
	},
	{
			"value": "60 CT",
			"label": "60 CT"
	},
	{
			"value": "60CT",
			"label": "60CT"
	},
	{
			"value": "60CT CHANGEMAKERS",
			"label": "60CT CHANGEMAKERS"
	},
	{
			"value": "6.0  H X 2.5  W",
			"label": "6.0  H X 2.5  W"
	},
	{
			"value": "60 IN",
			"label": "60 IN"
	},
	{
			"value": "60IN",
			"label": "60IN"
	},
	{
			"value": "60 INCH",
			"label": "60 INCH"
	},
	{
			"value": "60 INCHES",
			"label": "60 INCHES"
	},
	{
			"value": "60 INCH ROLL",
			"label": "60 INCH ROLL"
	},
	{
			"value": "60 MM",
			"label": "60 MM"
	},
	{
			"value": "60MM",
			"label": "60MM"
	},
	{
			"value": ".60 OZ",
			"label": ".60 OZ"
	},
	{
			"value": "60 QUART",
			"label": "60 QUART"
	},
	{
			"value": "60 SPOOL",
			"label": "60 SPOOL"
	},
	{
			"value": "60X10",
			"label": "60X10"
	},
	{
			"value": "60   X 110",
			"label": "60   X 110"
	},
	{
			"value": "60 X 12",
			"label": "60 X 12"
	},
	{
			"value": "60  X16.5 YDS",
			"label": "60  X16.5 YDS"
	},
	{
			"value": "60 X 18LP X 42CASCAD",
			"label": "60 X 18LP X 42CASCAD"
	},
	{
			"value": "60   X 1 YD PACKAGE",
			"label": "60   X 1 YD PACKAGE"
	},
	{
			"value": "60  X22",
			"label": "60  X22"
	},
	{
			"value": "60 X 42",
			"label": "60 X 42"
	},
	{
			"value": "60 X 48",
			"label": "60 X 48"
	},
	{
			"value": "60X48",
			"label": "60X48"
	},
	{
			"value": "60 X 64",
			"label": "60 X 64"
	},
	{
			"value": "60X70",
			"label": "60X70"
	},
	{
			"value": "60X84",
			"label": "60X84"
	},
	{
			"value": "60   X 84",
			"label": "60   X 84"
	},
	{
			"value": "60  X84",
			"label": "60  X84"
	},
	{
			"value": "60 X 96",
			"label": "60 X 96"
	},
	{
			"value": "60   X 96",
			"label": "60   X 96"
	},
	{
			"value": "60 YARDS",
			"label": "60 YARDS"
	},
	{
			"value": "60 YDS",
			"label": "60 YDS"
	},
	{
			"value": "6.1\"\"",
			"label": "6.1\"\""
	},
	{
			"value": "61",
			"label": "61"
	},
	{
			"value": "6.10 X6.10 X10.04",
			"label": "6.10 X6.10 X10.04"
	},
	{
			"value": "6.10 X6.10 X8.07",
			"label": "6.10 X6.10 X8.07"
	},
	{
			"value": "6.11 OZ / 173.3 G",
			"label": "6.11 OZ / 173.3 G"
	},
	{
			"value": "6.125 X 1.625 X 5.25",
			"label": "6.125 X 1.625 X 5.25"
	},
	{
			"value": "6.125 X 3 X 0.375 IN",
			"label": "6.125 X 3 X 0.375 IN"
	},
	{
			"value": "6.125 X 4.125 X .25",
			"label": "6.125 X 4.125 X .25"
	},
	{
			"value": "6.125 X 6.75 X 1.75",
			"label": "6.125 X 6.75 X 1.75"
	},
	{
			"value": "6 - 12 MONTHS",
			"label": "6 - 12 MONTHS"
	},
	{
			"value": "6-1/2  X10  X2",
			"label": "6-1/2  X10  X2"
	},
	{
			"value": "6-1/2   X 6-1/2   X",
			"label": "6-1/2   X 6-1/2   X"
	},
	{
			"value": ".613 OZ",
			"label": ".613 OZ"
	},
	{
			"value": "6.13QT",
			"label": "6.13QT"
	},
	{
			"value": "6 1/4",
			"label": "6 1/4"
	},
	{
			"value": "6140",
			"label": "6140"
	},
	{
			"value": "6.17OZ",
			"label": "6.17OZ"
	},
	{
			"value": "6185003",
			"label": "6185003"
	},
	{
			"value": "6 - 18   X 21",
			"label": "6 - 18   X 21"
	},
	{
			"value": "-6192",
			"label": "-6192"
	},
	{
			"value": "6.1IN X 8.4IN X 2.2I",
			"label": "6.1IN X 8.4IN X 2.2I"
	},
	{
			"value": "6.1 QT",
			"label": "6.1 QT"
	},
	{
			"value": "61WX8DX59H",
			"label": "61WX8DX59H"
	},
	{
			"value": "62",
			"label": "62"
	},
	{
			"value": "6200",
			"label": "6200"
	},
	{
			"value": "62.1X14.7",
			"label": "62.1X14.7"
	},
	{
			"value": "624",
			"label": "624"
	},
	{
			"value": "6240",
			"label": "6240"
	},
	{
			"value": ".625",
			"label": ".625"
	},
	{
			"value": "6250",
			"label": "6250"
	},
	{
			"value": " .625, .875 AND 1.00",
			"label": " .625, .875 AND 1.00"
	},
	{
			"value": " .625, .875 AND 1 IN",
			"label": " .625, .875 AND 1 IN"
	},
	{
			"value": "6.25IN",
			"label": "6.25IN"
	},
	{
			"value": ".625 INCH",
			"label": ".625 INCH"
	},
	{
			"value": ".625 INCH X 6FT",
			"label": ".625 INCH X 6FT"
	},
	{
			"value": ".625 INCH X 6 YARDS",
			"label": ".625 INCH X 6 YARDS"
	},
	{
			"value": ".625 IN X 12 FT",
			"label": ".625 IN X 12 FT"
	},
	{
			"value": ".625 IN. X 12 FT.",
			"label": ".625 IN. X 12 FT."
	},
	{
			"value": ".625 IN X 15 FT",
			"label": ".625 IN X 15 FT"
	},
	{
			"value": "6.25 OZ",
			"label": "6.25 OZ"
	},
	{
			"value": "6.25OZ",
			"label": "6.25OZ"
	},
	{
			"value": "6.25X11.75",
			"label": "6.25X11.75"
	},
	{
			"value": "6.25X13.25X.25",
			"label": "6.25X13.25X.25"
	},
	{
			"value": "6.25X1X8",
			"label": "6.25X1X8"
	},
	{
			"value": "6.25X3.625X10.375",
			"label": "6.25X3.625X10.375"
	},
	{
			"value": "6.25 X 4.50",
			"label": "6.25 X 4.50"
	},
	{
			"value": "6.25 X 5.12",
			"label": "6.25 X 5.12"
	},
	{
			"value": "6.25 X 5.5 X 1.25",
			"label": "6.25 X 5.5 X 1.25"
	},
	{
			"value": "6.25 X 5.5 X 2.125",
			"label": "6.25 X 5.5 X 2.125"
	},
	{
			"value": "6.25X6.25",
			"label": "6.25X6.25"
	},
	{
			"value": "6.25X6.25X12.5",
			"label": "6.25X6.25X12.5"
	},
	{
			"value": ".625   X 6YD",
			"label": ".625   X 6YD"
	},
	{
			"value": "6.25 X 7 X 0.95",
			"label": "6.25 X 7 X 0.95"
	},
	{
			"value": "6.25X8",
			"label": "6.25X8"
	},
	{
			"value": "6.25X8.38",
			"label": "6.25X8.38"
	},
	{
			"value": "6.25X9.25X.25",
			"label": "6.25X9.25X.25"
	},
	{
			"value": ".629 X .393   X 1.3",
			"label": ".629 X .393   X 1.3"
	},
	{
			"value": "62IN",
			"label": "62IN"
	},
	{
			"value": "6.2 OZ",
			"label": "6.2 OZ"
	},
	{
			"value": "62QT",
			"label": "62QT"
	},
	{
			"value": "62 X 14",
			"label": "62 X 14"
	},
	{
			"value": "62 X 18",
			"label": "62 X 18"
	},
	{
			"value": "62 X 19.5",
			"label": "62 X 19.5"
	},
	{
			"value": "6.2 X 4.39 X 4.29",
			"label": "6.2 X 4.39 X 4.29"
	},
	{
			"value": "6.3",
			"label": "6.3"
	},
	{
			"value": "6.3\"\"",
			"label": "6.3\"\""
	},
	{
			"value": "63",
			"label": "63"
	},
	{
			"value": "6300",
			"label": "6300"
	},
	{
			"value": "6.30''   5.91''   4.",
			"label": "6.30''   5.91''   4."
	},
	{
			"value": "6.30X3.90",
			"label": "6.30X3.90"
	},
	{
			"value": "6.31",
			"label": "6.31"
	},
	{
			"value": "6/32",
			"label": "6/32"
	},
	{
			"value": "632",
			"label": "632"
	},
	{
			"value": "6/32   - 12 YARDS",
			"label": "6/32   - 12 YARDS"
	},
	{
			"value": "633",
			"label": "633"
	},
	{
			"value": "6.34 OZ",
			"label": "6.34 OZ"
	},
	{
			"value": "6.35MM",
			"label": "6.35MM"
	},
	{
			"value": "6.35 OZ/ 180GRAMS",
			"label": "6.35 OZ/ 180GRAMS"
	},
	{
			"value": "6.375 X 3.5 X 0.25 I",
			"label": "6.375 X 3.5 X 0.25 I"
	},
	{
			"value": "6.3IN",
			"label": "6.3IN"
	},
	{
			"value": "6.3 INCH",
			"label": "6.3 INCH"
	},
	{
			"value": ".63 OZ",
			"label": ".63 OZ"
	},
	{
			"value": "6.3 OZ",
			"label": "6.3 OZ"
	},
	{
			"value": "6.3 QUART",
			"label": "6.3 QUART"
	},
	{
			"value": "6.3 X 5.10 X 3.90",
			"label": "6.3 X 5.10 X 3.90"
	},
	{
			"value": "6.3 X 6.3 X 13.4",
			"label": "6.3 X 6.3 X 13.4"
	},
	{
			"value": "64",
			"label": "64"
	},
	{
			"value": "6400",
			"label": "6400"
	},
	{
			"value": "6 4.2 4",
			"label": "6 4.2 4"
	},
	{
			"value": "6.44 X 6.35 X 5.94",
			"label": "6.44 X 6.35 X 5.94"
	},
	{
			"value": "-648",
			"label": "-648"
	},
	{
			"value": "648",
			"label": "648"
	},
	{
			"value": "64IN",
			"label": "64IN"
	},
	{
			"value": "6.4 IN *2",
			"label": "6.4 IN *2"
	},
	{
			"value": ".64 OZ",
			"label": ".64 OZ"
	},
	{
			"value": "6.4 OZ",
			"label": "6.4 OZ"
	},
	{
			"value": "64 OZ",
			"label": "64 OZ"
	},
	{
			"value": "64 QT",
			"label": "64 QT"
	},
	{
			"value": "64 QUART",
			"label": "64 QUART"
	},
	{
			"value": "64 X 12",
			"label": "64 X 12"
	},
	{
			"value": "64 X 15",
			"label": "64 X 15"
	},
	{
			"value": "64 X 18",
			"label": "64 X 18"
	},
	{
			"value": "64 X 60",
			"label": "64 X 60"
	},
	{
			"value": "64 X 84",
			"label": "64 X 84"
	},
	{
			"value": "64 YD",
			"label": "64 YD"
	},
	{
			"value": "6.5",
			"label": "6.5"
	},
	{
			"value": "6.5\"\"",
			"label": "6.5\"\""
	},
	{
			"value": "65",
			"label": "65"
	},
	{
			"value": "650",
			"label": "650"
	},
	{
			"value": "6500",
			"label": "6500"
	},
	{
			"value": "65000",
			"label": "65000"
	},
	{
			"value": "6.50X5.52X1.58",
			"label": "6.50X5.52X1.58"
	},
	{
			"value": "6.51 OZ / 184.6 G",
			"label": "6.51 OZ / 184.6 G"
	},
	{
			"value": "6538",
			"label": "6538"
	},
	{
			"value": "6 5.4 8",
			"label": "6 5.4 8"
	},
	{
			"value": "6.54 X 6.54 X 5.25 I",
			"label": "6.54 X 6.54 X 5.25 I"
	},
	{
			"value": "6 5 7.7",
			"label": "6 5 7.7"
	},
	{
			"value": "6.58 X 8.59",
			"label": "6.58 X 8.59"
	},
	{
			"value": "6.5 AND 12.5",
			"label": "6.5 AND 12.5"
	},
	{
			"value": "6.5 IN",
			"label": "6.5 IN"
	},
	{
			"value": "6.5IN",
			"label": "6.5IN"
	},
	{
			"value": "6.5IN *2",
			"label": "6.5IN *2"
	},
	{
			"value": "6.5 OZ",
			"label": "6.5 OZ"
	},
	{
			"value": "6.5OZ",
			"label": "6.5OZ"
	},
	{
			"value": "6.5QT (5 PACK BUNDLE",
			"label": "6.5QT (5 PACK BUNDLE"
	},
	{
			"value": "6.5X12.5",
			"label": "6.5X12.5"
	},
	{
			"value": "6.5 X .1 X 6.75",
			"label": "6.5 X .1 X 6.75"
	},
	{
			"value": "6.5X24.5",
			"label": "6.5X24.5"
	},
	{
			"value": "6.5 X 2.75 X 0.25 IN",
			"label": "6.5 X 2.75 X 0.25 IN"
	},
	{
			"value": "6.5 X 3.125 X 0.2",
			"label": "6.5 X 3.125 X 0.2"
	},
	{
			"value": "6.5 X 3.25 X 11",
			"label": "6.5 X 3.25 X 11"
	},
	{
			"value": "6.5 X 4.25 X 0.25 IN",
			"label": "6.5 X 4.25 X 0.25 IN"
	},
	{
			"value": "6.5X5",
			"label": "6.5X5"
	},
	{
			"value": "6.5 X 5.0 X 3.75",
			"label": "6.5 X 5.0 X 3.75"
	},
	{
			"value": "6.5 X 5.25 X 1.25",
			"label": "6.5 X 5.25 X 1.25"
	},
	{
			"value": "6.5   X .5   X 13",
			"label": "6.5   X .5   X 13"
	},
	{
			"value": "6.5   X 5   X 3.75",
			"label": "6.5   X 5   X 3.75"
	},
	{
			"value": "6.5   X 6.1   X 1.2",
			"label": "6.5   X 6.1   X 1.2"
	},
	{
			"value": "6.5X6.5",
			"label": "6.5X6.5"
	},
	{
			"value": "6.5X6.5X11.8",
			"label": "6.5X6.5X11.8"
	},
	{
			"value": "6.5X6.5X12",
			"label": "6.5X6.5X12"
	},
	{
			"value": "6.5 X 6.5 X3",
			"label": "6.5 X 6.5 X3"
	},
	{
			"value": "6.5X6.5X7",
			"label": "6.5X6.5X7"
	},
	{
			"value": "6.5   X 6.5   X 8.5",
			"label": "6.5   X 6.5   X 8.5"
	},
	{
			"value": "6.5X6.5X8.5",
			"label": "6.5X6.5X8.5"
	},
	{
			"value": "6.5X7",
			"label": "6.5X7"
	},
	{
			"value": "6.5 X 7.1 X .7",
			"label": "6.5 X 7.1 X .7"
	},
	{
			"value": "6.5 X 7 X 0.5",
			"label": "6.5 X 7 X 0.5"
	},
	{
			"value": "6.5 X 8.5 X 3",
			"label": "6.5 X 8.5 X 3"
	},
	{
			"value": "6.5X8X2.5",
			"label": "6.5X8X2.5"
	},
	{
			"value": "6.5X9",
			"label": "6.5X9"
	},
	{
			"value": "6.6\"\"",
			"label": "6.6\"\""
	},
	{
			"value": "66",
			"label": "66"
	},
	{
			"value": "66'",
			"label": "66'"
	},
	{
			"value": "6.60 X 11.60 X 6.10",
			"label": "6.60 X 11.60 X 6.10"
	},
	{
			"value": "66-120",
			"label": "66-120"
	},
	{
			"value": "661G",
			"label": "661G"
	},
	{
			"value": "6.65 X 6.4 X 4.65",
			"label": "6.65 X 6.4 X 4.65"
	},
	{
			"value": "668",
			"label": "668"
	},
	{
			"value": "6.69X12.99X14.96",
			"label": "6.69X12.99X14.96"
	},
	{
			"value": "66 IN",
			"label": "66 IN"
	},
	{
			"value": "66IN",
			"label": "66IN"
	},
	{
			"value": "66 INCH",
			"label": "66 INCH"
	},
	{
			"value": "6.6 OZ",
			"label": "6.6 OZ"
	},
	{
			"value": "66 PIECES",
			"label": "66 PIECES"
	},
	{
			"value": "66 X 18",
			"label": "66 X 18"
	},
	{
			"value": "6-7",
			"label": "6-7"
	},
	{
			"value": "6.7",
			"label": "6.7"
	},
	{
			"value": "6.7\"\"",
			"label": "6.7\"\""
	},
	{
			"value": "6/7",
			"label": "6/7"
	},
	{
			"value": "6700",
			"label": "6700"
	},
	{
			"value": "672",
			"label": "672"
	},
	{
			"value": "673",
			"label": "673"
	},
	{
			"value": "674",
			"label": "674"
	},
	{
			"value": "6742",
			"label": "6742"
	},
	{
			"value": "6.75",
			"label": "6.75"
	},
	{
			"value": "6750",
			"label": "6750"
	},
	{
			"value": "6.75IN",
			"label": "6.75IN"
	},
	{
			"value": "6.75 INCH",
			"label": "6.75 INCH"
	},
	{
			"value": "6.75OZ",
			"label": "6.75OZ"
	},
	{
			"value": "6.75X1X9.75",
			"label": "6.75X1X9.75"
	},
	{
			"value": "6.75 X 4.50 X 3",
			"label": "6.75 X 4.50 X 3"
	},
	{
			"value": "6.75   X5.625",
			"label": "6.75   X5.625"
	},
	{
			"value": "6.75X6.5",
			"label": "6.75X6.5"
	},
	{
			"value": "6.75 X 6.5 X 0.25 IN",
			"label": "6.75 X 6.5 X 0.25 IN"
	},
	{
			"value": "6.75   X 6.75   X 6",
			"label": "6.75   X 6.75   X 6"
	},
	{
			"value": "6.75 X 8.5 X 0.5 INC",
			"label": "6.75 X 8.5 X 0.5 INC"
	},
	{
			"value": "6.75 X 8.75 X 2.75",
			"label": "6.75 X 8.75 X 2.75"
	},
	{
			"value": "(6-7-8)",
			"label": "(6-7-8)"
	},
	{
			"value": "6.7 IN",
			"label": "6.7 IN"
	},
	{
			"value": "6.7 OZ",
			"label": "6.7 OZ"
	},
	{
			"value": "67 PIECE SET",
			"label": "67 PIECE SET"
	},
	{
			"value": "67WX8DX59H",
			"label": "67WX8DX59H"
	},
	{
			"value": "67 X14.3 X 6.10",
			"label": "67 X14.3 X 6.10"
	},
	{
			"value": "6.7X4.72X3.94",
			"label": "6.7X4.72X3.94"
	},
	{
			"value": "6.7X6.4X.125",
			"label": "6.7X6.4X.125"
	},
	{
			"value": "6.7X6.7X14.2",
			"label": "6.7X6.7X14.2"
	},
	{
			"value": " 6,8",
			"label": " 6,8"
	},
	{
			"value": "6-8",
			"label": "6-8"
	},
	{
			"value": "6.8\"\"",
			"label": "6.8\"\""
	},
	{
			"value": "6800",
			"label": "6800"
	},
	{
			"value": "68000",
			"label": "68000"
	},
	{
			"value": "(6-8-10)",
			"label": "(6-8-10)"
	},
	{
			"value": "(6-8-10-12)",
			"label": "(6-8-10-12)"
	},
	{
			"value": "6816",
			"label": "6816"
	},
	{
			"value": "6840",
			"label": "6840"
	},
	{
			"value": "6.84 OZ",
			"label": "6.84 OZ"
	},
	{
			"value": "6-8.5",
			"label": "6-8.5"
	},
	{
			"value": "6.875  X11",
			"label": "6.875  X11"
	},
	{
			"value": "6.87 X 5.12",
			"label": "6.87 X 5.12"
	},
	{
			"value": "6888",
			"label": "6888"
	},
	{
			"value": "6.88 OZ",
			"label": "6.88 OZ"
	},
	{
			"value": ".68 OZ",
			"label": ".68 OZ"
	},
	{
			"value": "6.8 OZ",
			"label": "6.8 OZ"
	},
	{
			"value": "6.8   X 14.3",
			"label": "6.8   X 14.3"
	},
	{
			"value": "68 X 16",
			"label": "68 X 16"
	},
	{
			"value": "68X36",
			"label": "68X36"
	},
	{
			"value": "68 X 60",
			"label": "68 X 60"
	},
	{
			"value": "6.8X6.8X8",
			"label": "6.8X6.8X8"
	},
	{
			"value": "68 X 84",
			"label": "68 X 84"
	},
	{
			"value": "6.9",
			"label": "6.9"
	},
	{
			"value": "6.9\"\"",
			"label": "6.9\"\""
	},
	{
			"value": "69600",
			"label": "69600"
	},
	{
			"value": "6-9 MO",
			"label": "6-9 MO"
	},
	{
			"value": "6- 9 MONTH",
			"label": "6- 9 MONTH"
	},
	{
			"value": "6-9 MONTH",
			"label": "6-9 MONTH"
	},
	{
			"value": "6-9 MONTHS",
			"label": "6-9 MONTHS"
	},
	{
			"value": "6.9 OZ",
			"label": "6.9 OZ"
	},
	{
			"value": "6 CELL",
			"label": "6 CELL"
	},
	{
			"value": "6 COUNT",
			"label": "6 COUNT"
	},
	{
			"value": "6CT",
			"label": "6CT"
	},
	{
			"value": "6 DRAWER CART",
			"label": "6 DRAWER CART"
	},
	{
			"value": "6DR MED LOCKING CHES",
			"label": "6DR MED LOCKING CHES"
	},
	{
			"value": "6 FEET",
			"label": "6 FEET"
	},
	{
			"value": "6 FL. OZ",
			"label": "6 FL. OZ"
	},
	{
			"value": "6 FL. OZ.",
			"label": "6 FL. OZ."
	},
	{
			"value": "6-FOOT LONG STRING L",
			"label": "6-FOOT LONG STRING L"
	},
	{
			"value": "6FT",
			"label": "6FT"
	},
	{
			"value": "6G",
			"label": "6G"
	},
	{
			"value": "6GM",
			"label": "6GM"
	},
	{
			"value": "6   H X 2.5 W",
			"label": "6   H X 2.5 W"
	},
	{
			"value": "6'H X 2.5  W",
			"label": "6'H X 2.5  W"
	},
	{
			"value": "6   H X 2.5   W",
			"label": "6   H X 2.5   W"
	},
	{
			"value": "6   H X2.5   W",
			"label": "6   H X2.5   W"
	},
	{
			"value": "6  H X  2.5  W",
			"label": "6  H X  2.5  W"
	},
	{
			"value": "6  H X 2.5   W",
			"label": "6  H X 2.5   W"
	},
	{
			"value": "6  H X 2.5  W",
			"label": "6  H X 2.5  W"
	},
	{
			"value": "6  H X 2.5W",
			"label": "6  H X 2.5W"
	},
	{
			"value": "6  HX2.W",
			"label": "6  HX2.W"
	},
	{
			"value": "6  H X 6-1/2  W",
			"label": "6  H X 6-1/2  W"
	},
	{
			"value": "6  H X X2.5 W",
			"label": "6  H X X2.5 W"
	},
	{
			"value": "6 IN",
			"label": "6 IN"
	},
	{
			"value": "6 IN.",
			"label": "6 IN."
	},
	{
			"value": "6IN",
			"label": "6IN"
	},
	{
			"value": "6 IN *2",
			"label": "6 IN *2"
	},
	{
			"value": "6IN *2",
			"label": "6IN *2"
	},
	{
			"value": "6IN 2PK",
			"label": "6IN 2PK"
	},
	{
			"value": "6 INCH",
			"label": "6 INCH"
	},
	{
			"value": "6INCH",
			"label": "6INCH"
	},
	{
			"value": "6 INCH DIAMETER",
			"label": "6 INCH DIAMETER"
	},
	{
			"value": "6 INCH EA",
			"label": "6 INCH EA"
	},
	{
			"value": "6 INCHES",
			"label": "6 INCHES"
	},
	{
			"value": "6 INCH SIZE 0",
			"label": "6 INCH SIZE 0"
	},
	{
			"value": "6 INCH SIZE 1",
			"label": "6 INCH SIZE 1"
	},
	{
			"value": "6 INCH SIZE 10",
			"label": "6 INCH SIZE 10"
	},
	{
			"value": "6 INCH SIZE 10.5",
			"label": "6 INCH SIZE 10.5"
	},
	{
			"value": "6 INCH SIZE 2",
			"label": "6 INCH SIZE 2"
	},
	{
			"value": "6 INCH SIZE 3",
			"label": "6 INCH SIZE 3"
	},
	{
			"value": "6 INCH SIZE 5",
			"label": "6 INCH SIZE 5"
	},
	{
			"value": "6 INCH SIZE 6",
			"label": "6 INCH SIZE 6"
	},
	{
			"value": "6 INCH SIZE 7",
			"label": "6 INCH SIZE 7"
	},
	{
			"value": "6 INCH SIZE 9",
			"label": "6 INCH SIZE 9"
	},
	{
			"value": "6 INCH X 15 YARDS",
			"label": "6 INCH X 15 YARDS"
	},
	{
			"value": "6 INCH X 5 YARDS",
			"label": "6 INCH X 5 YARDS"
	},
	{
			"value": "6 INCH X 8 YARDS",
			"label": "6 INCH X 8 YARDS"
	},
	{
			"value": "6 IN X 10 YD",
			"label": "6 IN X 10 YD"
	},
	{
			"value": "6INX12IN",
			"label": "6INX12IN"
	},
	{
			"value": "6INX1/2IN",
			"label": "6INX1/2IN"
	},
	{
			"value": "6 IN X 150 YD",
			"label": "6 IN X 150 YD"
	},
	{
			"value": "6 IN X 15 YD",
			"label": "6 IN X 15 YD"
	},
	{
			"value": "6INX1IN",
			"label": "6INX1IN"
	},
	{
			"value": "6 IN X 25 YD",
			"label": "6 IN X 25 YD"
	},
	{
			"value": "6 IN X 5 YD",
			"label": "6 IN X 5 YD"
	},
	{
			"value": "6 IN X 8IN",
			"label": "6 IN X 8IN"
	},
	{
			"value": "6 IN X 8 YD",
			"label": "6 IN X 8 YD"
	},
	{
			"value": "6   LONG",
			"label": "6   LONG"
	},
	{
			"value": "6MM",
			"label": "6MM"
	},
	{
			"value": "6MM X 6FT",
			"label": "6MM X 6FT"
	},
	{
			"value": "6 OP 4X6 COLLAGE",
			"label": "6 OP 4X6 COLLAGE"
	},
	{
			"value": ".6 OZ",
			"label": ".6 OZ"
	},
	{
			"value": "6 OZ",
			"label": "6 OZ"
	},
	{
			"value": "6 OZ.",
			"label": "6 OZ."
	},
	{
			"value": "6OZ",
			"label": "6OZ"
	},
	{
			"value": "6OZ/170G",
			"label": "6OZ/170G"
	},
	{
			"value": "6 OZ BONUS",
			"label": "6 OZ BONUS"
	},
	{
			"value": "6 OZ BONUS BOTTLE",
			"label": "6 OZ BONUS BOTTLE"
	},
	{
			"value": "6OZ X96  X15YDS",
			"label": "6OZ X96  X15YDS"
	},
	{
			"value": "6 PACK",
			"label": "6 PACK"
	},
	{
			"value": "6-PACK",
			"label": "6-PACK"
	},
	{
			"value": "6 PACK COUNTER DISPL",
			"label": "6 PACK COUNTER DISPL"
	},
	{
			"value": "6 PAIR",
			"label": "6 PAIR"
	},
	{
			"value": "6 PC",
			"label": "6 PC"
	},
	{
			"value": "6PC",
			"label": "6PC"
	},
	{
			"value": "6PC/2.5IN",
			"label": "6PC/2.5IN"
	},
	{
			"value": "6PC/3IN",
			"label": "6PC/3IN"
	},
	{
			"value": "6PC/3INX2IN",
			"label": "6PC/3INX2IN"
	},
	{
			"value": "6PC/4INX1IN",
			"label": "6PC/4INX1IN"
	},
	{
			"value": "6 PIECES",
			"label": "6 PIECES"
	},
	{
			"value": "6 PIECE SET",
			"label": "6 PIECE SET"
	},
	{
			"value": "6 PK",
			"label": "6 PK"
	},
	{
			"value": "6PK",
			"label": "6PK"
	},
	{
			"value": "6 SPOOL PACK",
			"label": "6 SPOOL PACK"
	},
	{
			"value": "6 - SUPER BULKY",
			"label": "6 - SUPER BULKY"
	},
	{
			"value": "6  W X 4  H",
			"label": "6  W X 4  H"
	},
	{
			"value": "6  W X 6  D X 10  H",
			"label": "6  W X 6  D X 10  H"
	},
	{
			"value": "6 X 0.5 X 9",
			"label": "6 X 0.5 X 9"
	},
	{
			"value": "6X10",
			"label": "6X10"
	},
	{
			"value": "6  X10.375",
			"label": "6  X10.375"
	},
	{
			"value": "6   X 10 YARDS",
			"label": "6   X 10 YARDS"
	},
	{
			"value": "6X10YD",
			"label": "6X10YD"
	},
	{
			"value": "6   X 10 YDS",
			"label": "6   X 10 YDS"
	},
	{
			"value": "6   X 10YDS",
			"label": "6   X 10YDS"
	},
	{
			"value": "6  X10 YDS",
			"label": "6  X10 YDS"
	},
	{
			"value": "6X11.5X2",
			"label": "6X11.5X2"
	},
	{
			"value": "6X118ML",
			"label": "6X118ML"
	},
	{
			"value": "6 X 12",
			"label": "6 X 12"
	},
	{
			"value": "6X12",
			"label": "6X12"
	},
	{
			"value": "6 X 12.5 X 3.5",
			"label": "6 X 12.5 X 3.5"
	},
	{
			"value": "6X12X.5",
			"label": "6X12X.5"
	},
	{
			"value": "6X15",
			"label": "6X15"
	},
	{
			"value": "6X150YD",
			"label": "6X150YD"
	},
	{
			"value": "6X1.5X6",
			"label": "6X1.5X6"
	},
	{
			"value": "6X15YD",
			"label": "6X15YD"
	},
	{
			"value": "6   X 15 YDS",
			"label": "6   X 15 YDS"
	},
	{
			"value": "6  X15YDS",
			"label": "6  X15YDS"
	},
	{
			"value": "6   X 18",
			"label": "6   X 18"
	},
	{
			"value": "6X18",
			"label": "6X18"
	},
	{
			"value": "6 X 1 X 1",
			"label": "6 X 1 X 1"
	},
	{
			"value": "6 X 1 X 8.5",
			"label": "6 X 1 X 8.5"
	},
	{
			"value": "6  X2",
			"label": "6  X2"
	},
	{
			"value": "6X2",
			"label": "6X2"
	},
	{
			"value": "6   X 20",
			"label": "6   X 20"
	},
	{
			"value": "6X24",
			"label": "6X24"
	},
	{
			"value": "6X24 COMBO",
			"label": "6X24 COMBO"
	},
	{
			"value": "6   X 2.5",
			"label": "6   X 2.5"
	},
	{
			"value": "6   X2.5",
			"label": "6   X2.5"
	},
	{
			"value": "6  X2.5",
			"label": "6  X2.5"
	},
	{
			"value": "6.   X 2.5",
			"label": "6.   X 2.5"
	},
	{
			"value": "6X.25X9",
			"label": "6X.25X9"
	},
	{
			"value": "6   X 25 YARDS",
			"label": "6   X 25 YARDS"
	},
	{
			"value": "6X25YD",
			"label": "6X25YD"
	},
	{
			"value": "6  X25YDS",
			"label": "6  X25YDS"
	},
	{
			"value": "6X2X6",
			"label": "6X2X6"
	},
	{
			"value": "6X3",
			"label": "6X3"
	},
	{
			"value": "6 X 3.25 X 0.25 INCH",
			"label": "6 X 3.25 X 0.25 INCH"
	},
	{
			"value": "6 X 3.5 X 9",
			"label": "6 X 3.5 X 9"
	},
	{
			"value": "6X36",
			"label": "6X36"
	},
	{
			"value": "6  X3.84  X3.81",
			"label": "6  X3.84  X3.81"
	},
	{
			"value": "6    X 3YDS",
			"label": "6    X 3YDS"
	},
	{
			"value": "6X4",
			"label": "6X4"
	},
	{
			"value": "6X4.25X3.75",
			"label": "6X4.25X3.75"
	},
	{
			"value": "6X 4.5",
			"label": "6X 4.5"
	},
	{
			"value": "6 X 4.5 X.75",
			"label": "6 X 4.5 X.75"
	},
	{
			"value": "6X5",
			"label": "6X5"
	},
	{
			"value": "6  X5-1/2  X1-1/4",
			"label": "6  X5-1/2  X1-1/4"
	},
	{
			"value": "6 X 5.4 X 0.5 INCH 0",
			"label": "6 X 5.4 X 0.5 INCH 0"
	},
	{
			"value": "6   X 5.5",
			"label": "6   X 5.5"
	},
	{
			"value": "6  X5  X1",
			"label": "6  X5  X1"
	},
	{
			"value": "6   X 5 YARDS",
			"label": "6   X 5 YARDS"
	},
	{
			"value": "6 X 5 YDS",
			"label": "6 X 5 YDS"
	},
	{
			"value": "6   X 5 YDS",
			"label": "6   X 5 YDS"
	},
	{
			"value": "6  X5 YDS",
			"label": "6  X5 YDS"
	},
	{
			"value": "6  X5YDS",
			"label": "6  X5YDS"
	},
	{
			"value": "6 X 6",
			"label": "6 X 6"
	},
	{
			"value": "6   X 6",
			"label": "6   X 6"
	},
	{
			"value": "6X6",
			"label": "6X6"
	},
	{
			"value": "6 X 6.5",
			"label": "6 X 6.5"
	},
	{
			"value": "6   X 6.5",
			"label": "6   X 6.5"
	},
	{
			"value": "6X6, 6X10",
			"label": "6X6, 6X10"
	},
	{
			"value": "6X6 ASSORTMENT",
			"label": "6X6 ASSORTMENT"
	},
	{
			"value": "6   X 6   X 1",
			"label": "6   X 6   X 1"
	},
	{
			"value": "6X6X10",
			"label": "6X6X10"
	},
	{
			"value": "6 X 6 X 12",
			"label": "6 X 6 X 12"
	},
	{
			"value": "6X6X12",
			"label": "6X6X12"
	},
	{
			"value": "6 X 6 X 14",
			"label": "6 X 6 X 14"
	},
	{
			"value": "6X6X1.5",
			"label": "6X6X1.5"
	},
	{
			"value": "6X6X15",
			"label": "6X6X15"
	},
	{
			"value": "6 X 6 X 1.80",
			"label": "6 X 6 X 1.80"
	},
	{
			"value": "6 X 6 X 2",
			"label": "6 X 6 X 2"
	},
	{
			"value": "6   X 6   X 2.25",
			"label": "6   X 6   X 2.25"
	},
	{
			"value": "6   X 6   X 4",
			"label": "6   X 6   X 4"
	},
	{
			"value": "6X7.5",
			"label": "6X7.5"
	},
	{
			"value": "6X7.5IN",
			"label": "6X7.5IN"
	},
	{
			"value": "6X7.5X2 INCHES",
			"label": "6X7.5X2 INCHES"
	},
	{
			"value": "6X.75X6",
			"label": "6X.75X6"
	},
	{
			"value": "6   X 7   X .07",
			"label": "6   X 7   X .07"
	},
	{
			"value": "6  X 7  X 2.75",
			"label": "6  X 7  X 2.75"
	},
	{
			"value": "6 X 8",
			"label": "6 X 8"
	},
	{
			"value": "6   X 8",
			"label": "6   X 8"
	},
	{
			"value": "6X8",
			"label": "6X8"
	},
	{
			"value": "6X8.5",
			"label": "6X8.5"
	},
	{
			"value": "6 X 8.50 X 2",
			"label": "6 X 8.50 X 2"
	},
	{
			"value": "6 X 8.5 X .75",
			"label": "6 X 8.5 X .75"
	},
	{
			"value": "6X8X.2",
			"label": "6X8X.2"
	},
	{
			"value": "6X.8X6",
			"label": "6X.8X6"
	},
	{
			"value": "6   X 8 YARDS",
			"label": "6   X 8 YARDS"
	},
	{
			"value": "6   X 8 YDS",
			"label": "6   X 8 YDS"
	},
	{
			"value": "6  X8 YDS",
			"label": "6  X8 YDS"
	},
	{
			"value": "6  X8YDS",
			"label": "6  X8YDS"
	},
	{
			"value": "6 X 9",
			"label": "6 X 9"
	},
	{
			"value": "6   X 9",
			"label": "6   X 9"
	},
	{
			"value": "6X9",
			"label": "6X9"
	},
	{
			"value": "6 X 9.5",
			"label": "6 X 9.5"
	},
	{
			"value": "6    X 9.5",
			"label": "6    X 9.5"
	},
	{
			"value": "6X9MM",
			"label": "6X9MM"
	},
	{
			"value": "6 X 9 X 2",
			"label": "6 X 9 X 2"
	},
	{
			"value": "6X9X.3",
			"label": "6X9X.3"
	},
	{
			"value": "6 YARDS",
			"label": "6 YARDS"
	},
	{
			"value": "6 YARDS PER SPOOL",
			"label": "6 YARDS PER SPOOL"
	},
	{
			"value": "6YD",
			"label": "6YD"
	},
	{
			"value": "7",
			"label": "7"
	},
	{
			"value": "7 \"\"",
			"label": "7 \"\""
	},
	{
			"value": "7\"\"",
			"label": "7\"\""
	},
	{
			"value": "70",
			"label": "70"
	},
	{
			"value": "700",
			"label": "700"
	},
	{
			"value": "7000",
			"label": "7000"
	},
	{
			"value": "70000",
			"label": "70000"
	},
	{
			"value": "700 PC",
			"label": "700 PC"
	},
	{
			"value": "7.00 X 4.60",
			"label": "7.00 X 4.60"
	},
	{
			"value": "7056",
			"label": "7056"
	},
	{
			"value": "7.05 OZ",
			"label": "7.05 OZ"
	},
	{
			"value": "7.05OZ",
			"label": "7.05OZ"
	},
	{
			"value": "7.06 OZ",
			"label": "7.06 OZ"
	},
	{
			"value": "708",
			"label": "708"
	},
	{
			"value": "7.08 X 2.75",
			"label": "7.08 X 2.75"
	},
	{
			"value": "7.08 X 8.36 X 0.77",
			"label": "7.08 X 8.36 X 0.77"
	},
	{
			"value": "7.08   X 8.46   X .",
			"label": "7.08   X 8.46   X ."
	},
	{
			"value": "7.08 X 8.46 X .55",
			"label": "7.08 X 8.46 X .55"
	},
	{
			"value": "7.09 IN",
			"label": "7.09 IN"
	},
	{
			"value": "7.09IN",
			"label": "7.09IN"
	},
	{
			"value": "70 GRAMS",
			"label": "70 GRAMS"
	},
	{
			"value": "70IN",
			"label": "70IN"
	},
	{
			"value": ".70 OZ",
			"label": ".70 OZ"
	},
	{
			"value": "70 X 18",
			"label": "70 X 18"
	},
	{
			"value": "70   X 22",
			"label": "70   X 22"
	},
	{
			"value": "70 X 24",
			"label": "70 X 24"
	},
	{
			"value": "70 X 84",
			"label": "70 X 84"
	},
	{
			"value": "7.1\"\"",
			"label": "7.1\"\""
	},
	{
			"value": "7100",
			"label": "7100"
	},
	{
			"value": "7 1/2\"\"",
			"label": "7 1/2\"\""
	},
	{
			"value": "7-1/2''H 4-1/2''W",
			"label": "7-1/2''H 4-1/2''W"
	},
	{
			"value": "7/16",
			"label": "7/16"
	},
	{
			"value": "7/16\"\"",
			"label": "7/16\"\""
	},
	{
			"value": "7/16 IN",
			"label": "7/16 IN"
	},
	{
			"value": "7.16   X 3.66   X 6",
			"label": "7.16   X 3.66   X 6"
	},
	{
			"value": "71CT",
			"label": "71CT"
	},
	{
			"value": "7.1 INCH",
			"label": "7.1 INCH"
	},
	{
			"value": "7.1''LX6.3''WX9.8''H",
			"label": "7.1''LX6.3''WX9.8''H"
	},
	{
			"value": "7.1 OZ",
			"label": "7.1 OZ"
	},
	{
			"value": "7.1QT",
			"label": "7.1QT"
	},
	{
			"value": "7.1   X 5.1   X 3.5",
			"label": "7.1   X 5.1   X 3.5"
	},
	{
			"value": "7.2\"\"",
			"label": "7.2\"\""
	},
	{
			"value": "72",
			"label": "72"
	},
	{
			"value": "720",
			"label": "720"
	},
	{
			"value": "7200",
			"label": "7200"
	},
	{
			"value": "7211931",
			"label": "7211931"
	},
	{
			"value": "7.25",
			"label": "7.25"
	},
	{
			"value": "7.2 5.3 4.8",
			"label": "7.2 5.3 4.8"
	},
	{
			"value": "7.25 IN",
			"label": "7.25 IN"
	},
	{
			"value": "7.25OZ",
			"label": "7.25OZ"
	},
	{
			"value": "7.25 X 2.75 X 13.5",
			"label": "7.25 X 2.75 X 13.5"
	},
	{
			"value": "7.25 X 2.75 X 7",
			"label": "7.25 X 2.75 X 7"
	},
	{
			"value": "7.25X4.5X8.5",
			"label": "7.25X4.5X8.5"
	},
	{
			"value": "7.25 X 4.62",
			"label": "7.25 X 4.62"
	},
	{
			"value": "7.25 X 5.00",
			"label": "7.25 X 5.00"
	},
	{
			"value": "7.25 X 6 + 6 STICK",
			"label": "7.25 X 6 + 6 STICK"
	},
	{
			"value": "7.25X6X7.25",
			"label": "7.25X6X7.25"
	},
	{
			"value": "7.28 INCH",
			"label": "7.28 INCH"
	},
	{
			"value": "72 COUNT",
			"label": "72 COUNT"
	},
	{
			"value": "72CT",
			"label": "72CT"
	},
	{
			"value": "72 IN",
			"label": "72 IN"
	},
	{
			"value": "72IN",
			"label": "72IN"
	},
	{
			"value": "72 INCHES",
			"label": "72 INCHES"
	},
	{
			"value": "72IN X 60IN",
			"label": "72IN X 60IN"
	},
	{
			"value": "7.2OZ",
			"label": "7.2OZ"
	},
	{
			"value": "72 PC",
			"label": "72 PC"
	},
	{
			"value": "7.2   X 10.3   X 1",
			"label": "7.2   X 10.3   X 1"
	},
	{
			"value": "72 X 14",
			"label": "72 X 14"
	},
	{
			"value": "72 X 15",
			"label": "72 X 15"
	},
	{
			"value": "72 X 16",
			"label": "72 X 16"
	},
	{
			"value": "72 X 18",
			"label": "72 X 18"
	},
	{
			"value": "72X24",
			"label": "72X24"
	},
	{
			"value": "72   X 24   X 0.5",
			"label": "72   X 24   X 0.5"
	},
	{
			"value": "72   X 24   X 1",
			"label": "72   X 24   X 1"
	},
	{
			"value": "72X24X2",
			"label": "72X24X2"
	},
	{
			"value": "72   X 24   X 2",
			"label": "72   X 24   X 2"
	},
	{
			"value": "72X24X3",
			"label": "72X24X3"
	},
	{
			"value": "72X24X4",
			"label": "72X24X4"
	},
	{
			"value": "72X24X.5",
			"label": "72X24X.5"
	},
	{
			"value": "72X24X5",
			"label": "72X24X5"
	},
	{
			"value": "72  X27X16.25",
			"label": "72  X27X16.25"
	},
	{
			"value": "72   X 44",
			"label": "72   X 44"
	},
	{
			"value": "72   X 72",
			"label": "72   X 72"
	},
	{
			"value": "72 X 84",
			"label": "72 X 84"
	},
	{
			"value": "72   X 84",
			"label": "72   X 84"
	},
	{
			"value": "72 X 90",
			"label": "72 X 90"
	},
	{
			"value": "72   X 90",
			"label": "72   X 90"
	},
	{
			"value": "72   X 90   2PK.",
			"label": "72   X 90   2PK."
	},
	{
			"value": "7.2   X 9.65   X 2",
			"label": "7.2   X 9.65   X 2"
	},
	{
			"value": "72YD",
			"label": "72YD"
	},
	{
			"value": "7.3\"\"",
			"label": "7.3\"\""
	},
	{
			"value": "7300",
			"label": "7300"
	},
	{
			"value": "732",
			"label": "732"
	},
	{
			"value": "7.325 X .125 X 9.12",
			"label": "7.325 X .125 X 9.12"
	},
	{
			"value": "733",
			"label": "733"
	},
	{
			"value": "7.37 X 4.12",
			"label": "7.37 X 4.12"
	},
	{
			"value": "7.37 X 5.00",
			"label": "7.37 X 5.00"
	},
	{
			"value": "7.37 X 5.62",
			"label": "7.37 X 5.62"
	},
	{
			"value": "-7380",
			"label": "-7380"
	},
	{
			"value": "7.3 IN",
			"label": "7.3 IN"
	},
	{
			"value": "73 OZ",
			"label": "73 OZ"
	},
	{
			"value": "7.3   X 1.9   X 3.6",
			"label": "7.3   X 1.9   X 3.6"
	},
	{
			"value": "7.4",
			"label": "7.4"
	},
	{
			"value": "7.4\"\"",
			"label": "7.4\"\""
	},
	{
			"value": "740",
			"label": "740"
	},
	{
			"value": "74000",
			"label": "74000"
	},
	{
			"value": "7.44 OZ",
			"label": "7.44 OZ"
	},
	{
			"value": "7.45 X 5.62",
			"label": "7.45 X 5.62"
	},
	{
			"value": "7.45 X.62",
			"label": "7.45 X.62"
	},
	{
			"value": "746",
			"label": "746"
	},
	{
			"value": "7484",
			"label": "7484"
	},
	{
			"value": "7488",
			"label": "7488"
	},
	{
			"value": "7.48''H 3.15''W",
			"label": "7.48''H 3.15''W"
	},
	{
			"value": "7.48 X 3.62 X .74",
			"label": "7.48 X 3.62 X .74"
	},
	{
			"value": "7.49 IN",
			"label": "7.49 IN"
	},
	{
			"value": "7.4IN *2",
			"label": "7.4IN *2"
	},
	{
			"value": ".74 OZ",
			"label": ".74 OZ"
	},
	{
			"value": "7.4 OZ",
			"label": "7.4 OZ"
	},
	{
			"value": "74 X 16",
			"label": "74 X 16"
	},
	{
			"value": "74 X 18",
			"label": "74 X 18"
	},
	{
			"value": "74 X 24",
			"label": "74 X 24"
	},
	{
			"value": "74 X 84",
			"label": "74 X 84"
	},
	{
			"value": ".75",
			"label": ".75"
	},
	{
			"value": "7.5",
			"label": "7.5"
	},
	{
			"value": "7.5\"\"",
			"label": "7.5\"\""
	},
	{
			"value": "75",
			"label": "75"
	},
	{
			"value": "750",
			"label": "750"
	},
	{
			"value": "7500",
			"label": "7500"
	},
	{
			"value": "75000",
			"label": "75000"
	},
	{
			"value": "7.50 X 1.88 X .25",
			"label": "7.50 X 1.88 X .25"
	},
	{
			"value": "7.50' X 2.88' X .50",
			"label": "7.50' X 2.88' X .50"
	},
	{
			"value": "7.50 X 3.75",
			"label": "7.50 X 3.75"
	},
	{
			"value": "7.50 X 4.00",
			"label": "7.50 X 4.00"
	},
	{
			"value": "7.50 X 5.50",
			"label": "7.50 X 5.50"
	},
	{
			"value": "7.50 X 5.60",
			"label": "7.50 X 5.60"
	},
	{
			"value": "7.51 OZ",
			"label": "7.51 OZ"
	},
	{
			"value": "7584",
			"label": "7584"
	},
	{
			"value": "75CT",
			"label": "75CT"
	},
	{
			"value": "75 GR",
			"label": "75 GR"
	},
	{
			"value": ".75 IN",
			"label": ".75 IN"
	},
	{
			"value": ".75IN",
			"label": ".75IN"
	},
	{
			"value": "7.5 IN",
			"label": "7.5 IN"
	},
	{
			"value": "7.5IN",
			"label": "7.5IN"
	},
	{
			"value": ".75 INCH",
			"label": ".75 INCH"
	},
	{
			"value": "7.5 INCH",
			"label": "7.5 INCH"
	},
	{
			"value": "7.5INCH",
			"label": "7.5INCH"
	},
	{
			"value": ".75 INCH X 3FT",
			"label": ".75 INCH X 3FT"
	},
	{
			"value": ".75 INCH X 6 YARDS",
			"label": ".75 INCH X 6 YARDS"
	},
	{
			"value": "75LB",
			"label": "75LB"
	},
	{
			"value": "75ML",
			"label": "75ML"
	},
	{
			"value": ".75 OZ",
			"label": ".75 OZ"
	},
	{
			"value": ".75OZ",
			"label": ".75OZ"
	},
	{
			"value": "7.5 OZ",
			"label": "7.5 OZ"
	},
	{
			"value": "7.5 OZ.",
			"label": "7.5 OZ."
	},
	{
			"value": "7.5OZ",
			"label": "7.5OZ"
	},
	{
			"value": "75 PIECES",
			"label": "75 PIECES"
	},
	{
			"value": "75 STICKERS PER PACK",
			"label": "75 STICKERS PER PACK"
	},
	{
			"value": "7.5X10",
			"label": "7.5X10"
	},
	{
			"value": "7.5 X 10.5 X .125",
			"label": "7.5 X 10.5 X .125"
	},
	{
			"value": "7.5X11",
			"label": "7.5X11"
	},
	{
			"value": "75   X 110",
			"label": "75   X 110"
	},
	{
			"value": "7.5X11.5",
			"label": "7.5X11.5"
	},
	{
			"value": ".75   X 15'",
			"label": ".75   X 15'"
	},
	{
			"value": "7.5 X 1.5 X 0.5",
			"label": "7.5 X 1.5 X 0.5"
	},
	{
			"value": ".75   X 15 YD",
			"label": ".75   X 15 YD"
	},
	{
			"value": ".75   X 15 YD.",
			"label": ".75   X 15 YD."
	},
	{
			"value": "7.5 X 1.75 X .75",
			"label": "7.5 X 1.75 X .75"
	},
	{
			"value": "75  X18",
			"label": "75  X18"
	},
	{
			"value": ".75   X 180",
			"label": ".75   X 180"
	},
	{
			"value": "7.5 X 20",
			"label": "7.5 X 20"
	},
	{
			"value": ".75   X 200",
			"label": ".75   X 200"
	},
	{
			"value": ".75   X 25YD",
			"label": ".75   X 25YD"
	},
	{
			"value": "7.5 X 2.75 X 7.25",
			"label": "7.5 X 2.75 X 7.25"
	},
	{
			"value": "7.5 X 3.5 X 1",
			"label": "7.5 X 3.5 X 1"
	},
	{
			"value": "7.5 X 3.5 X 1.02",
			"label": "7.5 X 3.5 X 1.02"
	},
	{
			"value": ".75   X 3.5   X 16",
			"label": ".75   X 3.5   X 16"
	},
	{
			"value": "7.5 X 3.5 X 6.5",
			"label": "7.5 X 3.5 X 6.5"
	},
	{
			"value": "7.5 X 3 X 7",
			"label": "7.5 X 3 X 7"
	},
	{
			"value": "7.5X4.5",
			"label": "7.5X4.5"
	},
	{
			"value": "7.5X4.75X.25",
			"label": "7.5X4.75X.25"
	},
	{
			"value": "7.5X4.75X.6",
			"label": "7.5X4.75X.6"
	},
	{
			"value": ".75 X 4FT",
			"label": ".75 X 4FT"
	},
	{
			"value": ".75 X 4 X 7.75",
			"label": ".75 X 4 X 7.75"
	},
	{
			"value": ".75X.5",
			"label": ".75X.5"
	},
	{
			"value": "7.5X5.5",
			"label": "7.5X5.5"
	},
	{
			"value": ".75   X 5.5   X   1",
			"label": ".75   X 5.5   X   1"
	},
	{
			"value": ".75   X 5 YD",
			"label": ".75   X 5 YD"
	},
	{
			"value": ".75   X 5 YD.",
			"label": ".75   X 5 YD."
	},
	{
			"value": ".75 X 6.0 X 9.0",
			"label": ".75 X 6.0 X 9.0"
	},
	{
			"value": "7.5   X 6.5",
			"label": "7.5   X 6.5"
	},
	{
			"value": "7.5 X 6 X .25",
			"label": "7.5 X 6 X .25"
	},
	{
			"value": ".75 X 6 X 9",
			"label": ".75 X 6 X 9"
	},
	{
			"value": "7.5 X 7.5 X 10",
			"label": "7.5 X 7.5 X 10"
	},
	{
			"value": "7.5X7.5X21",
			"label": "7.5X7.5X21"
	},
	{
			"value": ".75X.75X2.5",
			"label": ".75X.75X2.5"
	},
	{
			"value": "75   X 84",
			"label": "75   X 84"
	},
	{
			"value": "7.5   X 9",
			"label": "7.5   X 9"
	},
	{
			"value": ".75   X 96",
			"label": ".75   X 96"
	},
	{
			"value": "75   X 96",
			"label": "75   X 96"
	},
	{
			"value": "7.6\"\"",
			"label": "7.6\"\""
	},
	{
			"value": "76",
			"label": "76"
	},
	{
			"value": "7600",
			"label": "7600"
	},
	{
			"value": "76000",
			"label": "76000"
	},
	{
			"value": "7.625 X 3.5 X 6.625",
			"label": "7.625 X 3.5 X 6.625"
	},
	{
			"value": "763",
			"label": "763"
	},
	{
			"value": "766",
			"label": "766"
	},
	{
			"value": "7.68 OZ",
			"label": "7.68 OZ"
	},
	{
			"value": "7.68  X2.95  X4.07'",
			"label": "7.68  X2.95  X4.07'"
	},
	{
			"value": "76IN",
			"label": "76IN"
	},
	{
			"value": "76MM",
			"label": "76MM"
	},
	{
			"value": "76 PC",
			"label": "76 PC"
	},
	{
			"value": "76 X 15",
			"label": "76 X 15"
	},
	{
			"value": "76 X 18LP X 42CASCAD",
			"label": "76 X 18LP X 42CASCAD"
	},
	{
			"value": "76 X 60",
			"label": "76 X 60"
	},
	{
			"value": "7.6X7X7",
			"label": "7.6X7X7"
	},
	{
			"value": "76 X 84",
			"label": "76 X 84"
	},
	{
			"value": "76 X 96",
			"label": "76 X 96"
	},
	{
			"value": "7.7\"\"",
			"label": "7.7\"\""
	},
	{
			"value": "77",
			"label": "77"
	},
	{
			"value": "7728",
			"label": "7728"
	},
	{
			"value": "774",
			"label": "774"
	},
	{
			"value": "7.74 OZ",
			"label": "7.74 OZ"
	},
	{
			"value": "7.75",
			"label": "7.75"
	},
	{
			"value": "7.75 IN X 5 IN X 2.7",
			"label": "7.75 IN X 5 IN X 2.7"
	},
	{
			"value": "7.75   X 10.50",
			"label": "7.75   X 10.50"
	},
	{
			"value": "7.75X10.75",
			"label": "7.75X10.75"
	},
	{
			"value": "7.75   X 10.75   X",
			"label": "7.75   X 10.75   X"
	},
	{
			"value": "7.75  X10.75  X.75",
			"label": "7.75  X10.75  X.75"
	},
	{
			"value": "7.75X12",
			"label": "7.75X12"
	},
	{
			"value": "7.75 X 2.50",
			"label": "7.75 X 2.50"
	},
	{
			"value": "7.75 X .25 X 10.75",
			"label": "7.75 X .25 X 10.75"
	},
	{
			"value": "7.75X2.5X7.375",
			"label": "7.75X2.5X7.375"
	},
	{
			"value": "7.75 X 3.5",
			"label": "7.75 X 3.5"
	},
	{
			"value": "7.75 X3.5",
			"label": "7.75 X3.5"
	},
	{
			"value": "7.75 X 4.50",
			"label": "7.75 X 4.50"
	},
	{
			"value": "7.75X6.25X0.15",
			"label": "7.75X6.25X0.15"
	},
	{
			"value": "7.75 X 9.75",
			"label": "7.75 X 9.75"
	},
	{
			"value": "-7776",
			"label": "-7776"
	},
	{
			"value": "7.78   X 4.40   .63",
			"label": "7.78   X 4.40   .63"
	},
	{
			"value": "7.78   X 4.40   X .",
			"label": "7.78   X 4.40   X ."
	},
	{
			"value": "7.78   X 4.4   X .6",
			"label": "7.78   X 4.4   X .6"
	},
	{
			"value": "7-7/8 X 7-7/8 X 1-7/",
			"label": "7-7/8 X 7-7/8 X 1-7/"
	},
	{
			"value": "779",
			"label": "779"
	},
	{
			"value": "7.7 OZ",
			"label": "7.7 OZ"
	},
	{
			"value": "7 8\"\"",
			"label": "7 8\"\""
	},
	{
			"value": "7.8\"\"",
			"label": "7.8\"\""
	},
	{
			"value": "7/8",
			"label": "7/8"
	},
	{
			"value": "7/8\"\"",
			"label": "7/8\"\""
	},
	{
			"value": "78",
			"label": "78"
	},
	{
			"value": "780",
			"label": "780"
	},
	{
			"value": "7800",
			"label": "7800"
	},
	{
			"value": "(7-8-10-12-14)",
			"label": "(7-8-10-12-14)"
	},
	{
			"value": "7/8   (22MM)",
			"label": "7/8   (22MM)"
	},
	{
			"value": "7/8 (22MM)",
			"label": "7/8 (22MM)"
	},
	{
			"value": "7/8(22MM)",
			"label": "7/8(22MM)"
	},
	{
			"value": "7.82 X 11.5 X 1.35",
			"label": "7.82 X 11.5 X 1.35"
	},
	{
			"value": "7.87 5.12 0.39",
			"label": "7.87 5.12 0.39"
	},
	{
			"value": "7.87 X 10.5",
			"label": "7.87 X 10.5"
	},
	{
			"value": "7.87 X 5.50",
			"label": "7.87 X 5.50"
	},
	{
			"value": "7.87 X 5.51",
			"label": "7.87 X 5.51"
	},
	{
			"value": "7.88",
			"label": "7.88"
	},
	{
			"value": "7.88 IN",
			"label": "7.88 IN"
	},
	{
			"value": "7.88IN",
			"label": "7.88IN"
	},
	{
			"value": "78FT",
			"label": "78FT"
	},
	{
			"value": "7/8 IN.",
			"label": "7/8 IN."
	},
	{
			"value": "78IN",
			"label": "78IN"
	},
	{
			"value": "7.8IN",
			"label": "7.8IN"
	},
	{
			"value": "7/8IN",
			"label": "7/8IN"
	},
	{
			"value": "7/8 IN X 12 FT",
			"label": "7/8 IN X 12 FT"
	},
	{
			"value": "7/8 IN X 7/8 IN   12",
			"label": "7/8 IN X 7/8 IN   12"
	},
	{
			"value": "7.8 OZ",
			"label": "7.8 OZ"
	},
	{
			"value": "7 8TH",
			"label": "7 8TH"
	},
	{
			"value": "7 8TH INCH",
			"label": "7 8TH INCH"
	},
	{
			"value": "7 8TH X 9 FT",
			"label": "7 8TH X 9 FT"
	},
	{
			"value": "7 8TH X 9FT",
			"label": "7 8TH X 9FT"
	},
	{
			"value": "7/8TH X 9FT",
			"label": "7/8TH X 9FT"
	},
	{
			"value": "7/8  X12'",
			"label": "7/8  X12'"
	},
	{
			"value": "78 X 15",
			"label": "78 X 15"
	},
	{
			"value": "7/8  X15YDS",
			"label": "7/8  X15YDS"
	},
	{
			"value": "78 X 18",
			"label": "78 X 18"
	},
	{
			"value": "7/8  X18'",
			"label": "7/8  X18'"
	},
	{
			"value": "78 X 19.5",
			"label": "78 X 19.5"
	},
	{
			"value": "7/8   X 20 YDS",
			"label": "7/8   X 20 YDS"
	},
	{
			"value": "7/8  X20YDS",
			"label": "7/8  X20YDS"
	},
	{
			"value": "7/8   X 3FT",
			"label": "7/8   X 3FT"
	},
	{
			"value": "78 X 42",
			"label": "78 X 42"
	},
	{
			"value": "78 X 48",
			"label": "78 X 48"
	},
	{
			"value": "78 X 60",
			"label": "78 X 60"
	},
	{
			"value": "7/8   X 6FT",
			"label": "7/8   X 6FT"
	},
	{
			"value": "78 X 84",
			"label": "78 X 84"
	},
	{
			"value": "7/8   X 8YDS",
			"label": "7/8   X 8YDS"
	},
	{
			"value": "7/8  X9'",
			"label": "7/8  X9'"
	},
	{
			"value": "7.9",
			"label": "7.9"
	},
	{
			"value": "7900",
			"label": "7900"
	},
	{
			"value": "7.90 H 5.40 W",
			"label": "7.90 H 5.40 W"
	},
	{
			"value": "792",
			"label": "792"
	},
	{
			"value": "7920",
			"label": "7920"
	},
	{
			"value": "794",
			"label": "794"
	},
	{
			"value": "7.94 OZ",
			"label": "7.94 OZ"
	},
	{
			"value": "7 9 .75",
			"label": "7 9 .75"
	},
	{
			"value": "7.99   X 4.33   X 2",
			"label": "7.99   X 4.33   X 2"
	},
	{
			"value": "7.9 IN",
			"label": "7.9 IN"
	},
	{
			"value": "7.9IN",
			"label": "7.9IN"
	},
	{
			"value": "7.9 INCH",
			"label": "7.9 INCH"
	},
	{
			"value": "..79 OZ",
			"label": "..79 OZ"
	},
	{
			"value": "7.9 OZ",
			"label": "7.9 OZ"
	},
	{
			"value": "7.9OZ/226G",
			"label": "7.9OZ/226G"
	},
	{
			"value": "79WX8DX59H",
			"label": "79WX8DX59H"
	},
	{
			"value": "7.9X2X7.9",
			"label": "7.9X2X7.9"
	},
	{
			"value": "7.9 X 3.4 X 2",
			"label": "7.9 X 3.4 X 2"
	},
	{
			"value": "7.9   X 3.4   X 2",
			"label": "7.9   X 3.4   X 2"
	},
	{
			"value": "7.9X3.5X18.1",
			"label": "7.9X3.5X18.1"
	},
	{
			"value": "7 BOTTLES - 4OZ EACH",
			"label": "7 BOTTLES - 4OZ EACH"
	},
	{
			"value": "7 CT",
			"label": "7 CT"
	},
	{
			"value": "7 CU IN",
			"label": "7 CU IN"
	},
	{
			"value": "7 CU INCHES",
			"label": "7 CU INCHES"
	},
	{
			"value": "7 FORMAL",
			"label": "7 FORMAL"
	},
	{
			"value": "7  H X 2-1/4  W",
			"label": "7  H X 2-1/4  W"
	},
	{
			"value": "7  H X 8.25  W X 7",
			"label": "7  H X 8.25  W X 7"
	},
	{
			"value": "7 IN",
			"label": "7 IN"
	},
	{
			"value": "7IN",
			"label": "7IN"
	},
	{
			"value": "7 INCH",
			"label": "7 INCH"
	},
	{
			"value": "7 INCHES",
			"label": "7 INCHES"
	},
	{
			"value": "7 INCH ROUND",
			"label": "7 INCH ROUND"
	},
	{
			"value": "7IN H",
			"label": "7IN H"
	},
	{
			"value": "7 IN HIGH",
			"label": "7 IN HIGH"
	},
	{
			"value": "7INX1IN",
			"label": "7INX1IN"
	},
	{
			"value": "7INX3IN",
			"label": "7INX3IN"
	},
	{
			"value": "7 IN X 8FT",
			"label": "7 IN X 8FT"
	},
	{
			"value": "7 - JUMBO",
			"label": "7 - JUMBO"
	},
	{
			"value": "7   L X 3   W X 9",
			"label": "7   L X 3   W X 9"
	},
	{
			"value": "7MM",
			"label": "7MM"
	},
	{
			"value": "7MM X 3FT",
			"label": "7MM X 3FT"
	},
	{
			"value": "7 OUNCES",
			"label": "7 OUNCES"
	},
	{
			"value": ".7 OZ",
			"label": ".7 OZ"
	},
	{
			"value": "7 OZ",
			"label": "7 OZ"
	},
	{
			"value": "7 OZ.",
			"label": "7 OZ."
	},
	{
			"value": ".7OZ",
			"label": ".7OZ"
	},
	{
			"value": "7OZ",
			"label": "7OZ"
	},
	{
			"value": "7 OZ/200G",
			"label": "7 OZ/200G"
	},
	{
			"value": "7OZ/200G",
			"label": "7OZ/200G"
	},
	{
			"value": "7 OZ/ 200 GRAMS",
			"label": "7 OZ/ 200 GRAMS"
	},
	{
			"value": "7OZ/ 2OO GRAMS",
			"label": "7OZ/ 2OO GRAMS"
	},
	{
			"value": "7 OZS",
			"label": "7 OZS"
	},
	{
			"value": "7PC",
			"label": "7PC"
	},
	{
			"value": "7 PIECES",
			"label": "7 PIECES"
	},
	{
			"value": "7'' ROUND",
			"label": "7'' ROUND"
	},
	{
			"value": "7   X 10",
			"label": "7   X 10"
	},
	{
			"value": "7  X10",
			"label": "7  X10"
	},
	{
			"value": "7X10",
			"label": "7X10"
	},
	{
			"value": "7   X 12",
			"label": "7   X 12"
	},
	{
			"value": "7X12",
			"label": "7X12"
	},
	{
			"value": "7 X 12 X 0.40",
			"label": "7 X 12 X 0.40"
	},
	{
			"value": "7   X 1.4   X .4",
			"label": "7   X 1.4   X .4"
	},
	{
			"value": "7  X15",
			"label": "7  X15"
	},
	{
			"value": "7X2",
			"label": "7X2"
	},
	{
			"value": "7X20",
			"label": "7X20"
	},
	{
			"value": "7 X 20 X 2",
			"label": "7 X 20 X 2"
	},
	{
			"value": "7X22.5X27.75",
			"label": "7X22.5X27.75"
	},
	{
			"value": "7X24X1",
			"label": "7X24X1"
	},
	{
			"value": "7X24X2",
			"label": "7X24X2"
	},
	{
			"value": "7X2.5X9.5",
			"label": "7X2.5X9.5"
	},
	{
			"value": "7X2X10",
			"label": "7X2X10"
	},
	{
			"value": "7X3",
			"label": "7X3"
	},
	{
			"value": "7X.34X10",
			"label": "7X.34X10"
	},
	{
			"value": "7 X 3.625 X 0.5 INCH",
			"label": "7 X 3.625 X 0.5 INCH"
	},
	{
			"value": "7 X 3 X 10",
			"label": "7 X 3 X 10"
	},
	{
			"value": "7X3X3.5",
			"label": "7X3X3.5"
	},
	{
			"value": "7 X 3 X 9.5",
			"label": "7 X 3 X 9.5"
	},
	{
			"value": "7   X 4",
			"label": "7   X 4"
	},
	{
			"value": "7X4.5",
			"label": "7X4.5"
	},
	{
			"value": "7X4X6.5",
			"label": "7X4X6.5"
	},
	{
			"value": "7   X 5",
			"label": "7   X 5"
	},
	{
			"value": "7X5",
			"label": "7X5"
	},
	{
			"value": "7 X 5.5 INCH",
			"label": "7 X 5.5 INCH"
	},
	{
			"value": "7  X5  X1-1/2",
			"label": "7  X5  X1-1/2"
	},
	{
			"value": "7X5X3.5",
			"label": "7X5X3.5"
	},
	{
			"value": "7 X .5 X 9.5",
			"label": "7 X .5 X 9.5"
	},
	{
			"value": "7X6",
			"label": "7X6"
	},
	{
			"value": "7X6.5",
			"label": "7X6.5"
	},
	{
			"value": "7 X 6.5IN",
			"label": "7 X 6.5IN"
	},
	{
			"value": "7 X 6 X 15",
			"label": "7 X 6 X 15"
	},
	{
			"value": " 7X6X9,25",
			"label": " 7X6X9,25"
	},
	{
			"value": "7  X 6YDS",
			"label": "7  X 6YDS"
	},
	{
			"value": "7X7",
			"label": "7X7"
	},
	{
			"value": "7 X 7.5 X 11.25",
			"label": "7 X 7.5 X 11.25"
	},
	{
			"value": "7 X 7 X 10",
			"label": "7 X 7 X 10"
	},
	{
			"value": "7 X 7 X 12",
			"label": "7 X 7 X 12"
	},
	{
			"value": "7 X 7 X 6",
			"label": "7 X 7 X 6"
	},
	{
			"value": "7X7X6.125",
			"label": "7X7X6.125"
	},
	{
			"value": "7X7X7",
			"label": "7X7X7"
	},
	{
			"value": "7   X 8.5",
			"label": "7   X 8.5"
	},
	{
			"value": "7X8.5",
			"label": "7X8.5"
	},
	{
			"value": "7X8.5X1",
			"label": "7X8.5X1"
	},
	{
			"value": "7 X 8 X 2",
			"label": "7 X 8 X 2"
	},
	{
			"value": "7X 8 X 2",
			"label": "7X 8 X 2"
	},
	{
			"value": "7   X 9",
			"label": "7   X 9"
	},
	{
			"value": "7  X9",
			"label": "7  X9"
	},
	{
			"value": "7X9",
			"label": "7X9"
	},
	{
			"value": "7 X 9.5 X 1.5",
			"label": "7 X 9.5 X 1.5"
	},
	{
			"value": "7X9IN",
			"label": "7X9IN"
	},
	{
			"value": "7X9 TO 5X7",
			"label": "7X9 TO 5X7"
	},
	{
			"value": "7 X 9 X 5",
			"label": "7 X 9 X 5"
	},
	{
			"value": "7YD",
			"label": "7YD"
	},
	{
			"value": ".8",
			"label": ".8"
	},
	{
			"value": "8",
			"label": "8"
	},
	{
			"value": "8\"\"",
			"label": "8\"\""
	},
	{
			"value": "8'",
			"label": "8'"
	},
	{
			"value": "80",
			"label": "80"
	},
	{
			"value": "800",
			"label": "800"
	},
	{
			"value": "8000",
			"label": "8000"
	},
	{
			"value": "8.00 X 5.26",
			"label": "8.00 X 5.26"
	},
	{
			"value": "8.00 X 5.62",
			"label": "8.00 X 5.62"
	},
	{
			"value": "8.00 X 6.00",
			"label": "8.00 X 6.00"
	},
	{
			"value": "8016",
			"label": "8016"
	},
	{
			"value": "8.03 X 6.00",
			"label": "8.03 X 6.00"
	},
	{
			"value": "8.03 X 8.02 X 1.89",
			"label": "8.03 X 8.02 X 1.89"
	},
	{
			"value": "805219302071",
			"label": "805219302071"
	},
	{
			"value": "8.07   X 9.84   X .",
			"label": "8.07   X 9.84   X ."
	},
	{
			"value": "80GM",
			"label": "80GM"
	},
	{
			"value": "80IN",
			"label": "80IN"
	},
	{
			"value": "80MM",
			"label": "80MM"
	},
	{
			"value": "80 PC",
			"label": "80 PC"
	},
	{
			"value": "80 QUART",
			"label": "80 QUART"
	},
	{
			"value": "80 X 110",
			"label": "80 X 110"
	},
	{
			"value": "80   X 110",
			"label": "80   X 110"
	},
	{
			"value": "8.0X6.25X15-26.5",
			"label": "8.0X6.25X15-26.5"
	},
	{
			"value": "80 X 72",
			"label": "80 X 72"
	},
	{
			"value": "80 X 96",
			"label": "80 X 96"
	},
	{
			"value": "81",
			"label": "81"
	},
	{
			"value": " 8,10",
			"label": " 8,10"
	},
	{
			"value": "8-10",
			"label": "8-10"
	},
	{
			"value": "8100",
			"label": "8100"
	},
	{
			"value": " 8,12,16",
			"label": " 8,12,16"
	},
	{
			"value": "8-1/2 X 1-1/2 IN",
			"label": "8-1/2 X 1-1/2 IN"
	},
	{
			"value": "8.12 X 3.12",
			"label": "8.12 X 3.12"
	},
	{
			"value": "-816",
			"label": "-816"
	},
	{
			"value": "8160",
			"label": "8160"
	},
	{
			"value": "8.1 INCH",
			"label": "8.1 INCH"
	},
	{
			"value": "81   X 96",
			"label": "81   X 96"
	},
	{
			"value": "8.2\"\"",
			"label": "8.2\"\""
	},
	{
			"value": "8200",
			"label": "8200"
	},
	{
			"value": "8208",
			"label": "8208"
	},
	{
			"value": "8.20 X 5.00",
			"label": "8.20 X 5.00"
	},
	{
			"value": "8.25",
			"label": "8.25"
	},
	{
			"value": "8.25IN X 5IN X 4IN",
			"label": "8.25IN X 5IN X 4IN"
	},
	{
			"value": "8.25 OZ",
			"label": "8.25 OZ"
	},
	{
			"value": "8.25X0.5X26",
			"label": "8.25X0.5X26"
	},
	{
			"value": "8.25X0.5X40",
			"label": "8.25X0.5X40"
	},
	{
			"value": "8.25   X 10",
			"label": "8.25   X 10"
	},
	{
			"value": "8.25X11",
			"label": "8.25X11"
	},
	{
			"value": "8.25   X 11.5",
			"label": "8.25   X 11.5"
	},
	{
			"value": "8.25X1.75",
			"label": "8.25X1.75"
	},
	{
			"value": "8.25 X 4.375 X 1.25",
			"label": "8.25 X 4.375 X 1.25"
	},
	{
			"value": "8.25 X 5.00",
			"label": "8.25 X 5.00"
	},
	{
			"value": "8.25 X 5.25",
			"label": "8.25 X 5.25"
	},
	{
			"value": "8.25  X5.5",
			"label": "8.25  X5.5"
	},
	{
			"value": "8.25 X 5.5 X 4.75",
			"label": "8.25 X 5.5 X 4.75"
	},
	{
			"value": "8.25 X 6.12",
			"label": "8.25 X 6.12"
	},
	{
			"value": "8.25   X 6.5   X 2",
			"label": "8.25   X 6.5   X 2"
	},
	{
			"value": "8.25 X 7.00",
			"label": "8.25 X 7.00"
	},
	{
			"value": "8.27X4.72X3.35",
			"label": "8.27X4.72X3.35"
	},
	{
			"value": "8.27X7.87X1.57",
			"label": "8.27X7.87X1.57"
	},
	{
			"value": "8298184",
			"label": "8298184"
	},
	{
			"value": "82FT",
			"label": "82FT"
	},
	{
			"value": "8.2 IN X 4.9 IN X 3.",
			"label": "8.2 IN X 4.9 IN X 3."
	},
	{
			"value": "82 X 110",
			"label": "82 X 110"
	},
	{
			"value": "82 X 15",
			"label": "82 X 15"
	},
	{
			"value": "82 X 18",
			"label": "82 X 18"
	},
	{
			"value": "82 X 24",
			"label": "82 X 24"
	},
	{
			"value": "82X90",
			"label": "82X90"
	},
	{
			"value": "82  X90",
			"label": "82  X90"
	},
	{
			"value": "8/32",
			"label": "8/32"
	},
	{
			"value": "832",
			"label": "832"
	},
	{
			"value": "8/32   - 12 YARDS",
			"label": "8/32   - 12 YARDS"
	},
	{
			"value": "833",
			"label": "833"
	},
	{
			"value": "834",
			"label": "834"
	},
	{
			"value": "-8352",
			"label": "-8352"
	},
	{
			"value": "8.375 X .125 X 11",
			"label": "8.375 X .125 X 11"
	},
	{
			"value": "8.375X6.25",
			"label": "8.375X6.25"
	},
	{
			"value": "8.375X.875X11.875",
			"label": "8.375X.875X11.875"
	},
	{
			"value": "8.37 X 5.00",
			"label": "8.37 X 5.00"
	},
	{
			"value": "8.37 X 6.12",
			"label": "8.37 X 6.12"
	},
	{
			"value": ".83 OZ",
			"label": ".83 OZ"
	},
	{
			"value": "83WX8DX59H",
			"label": "83WX8DX59H"
	},
	{
			"value": "8.3 X 2.3 X .9",
			"label": "8.3 X 2.3 X .9"
	},
	{
			"value": "8.3   X 3.0   X 2.0",
			"label": "8.3   X 3.0   X 2.0"
	},
	{
			"value": "84",
			"label": "84"
	},
	{
			"value": "840",
			"label": "840"
	},
	{
			"value": "8400",
			"label": "8400"
	},
	{
			"value": "840 CASE MIN",
			"label": "840 CASE MIN"
	},
	{
			"value": "84-150",
			"label": "84-150"
	},
	{
			"value": "843",
			"label": "843"
	},
	{
			"value": "8.43 X 1.52 X 8.98",
			"label": "8.43 X 1.52 X 8.98"
	},
	{
			"value": "8.43 X 6.69 X 2.56",
			"label": "8.43 X 6.69 X 2.56"
	},
	{
			"value": "8441.10.0000",
			"label": "8441.10.0000"
	},
	{
			"value": "8443",
			"label": "8443"
	},
	{
			"value": "8.44X5.25",
			"label": "8.44X5.25"
	},
	{
			"value": "8.45 X 6.70 X 2.60",
			"label": "8.45 X 6.70 X 2.60"
	},
	{
			"value": "84IN",
			"label": "84IN"
	},
	{
			"value": ".84 OZ",
			"label": ".84 OZ"
	},
	{
			"value": "8.4 OZ",
			"label": "8.4 OZ"
	},
	{
			"value": "84 X 110",
			"label": "84 X 110"
	},
	{
			"value": "8.4X14X1.25",
			"label": "8.4X14X1.25"
	},
	{
			"value": "8.4X14X2",
			"label": "8.4X14X2"
	},
	{
			"value": "84 X 18",
			"label": "84 X 18"
	},
	{
			"value": "84 X 84",
			"label": "84 X 84"
	},
	{
			"value": "84 X 96",
			"label": "84 X 96"
	},
	{
			"value": "8.5",
			"label": "8.5"
	},
	{
			"value": "850",
			"label": "850"
	},
	{
			"value": "8500",
			"label": "8500"
	},
	{
			"value": "85000",
			"label": "85000"
	},
	{
			"value": "8.50  X10",
			"label": "8.50  X10"
	},
	{
			"value": "8.50 X 4.25",
			"label": "8.50 X 4.25"
	},
	{
			"value": "8.50 X 4.75",
			"label": "8.50 X 4.75"
	},
	{
			"value": "8.50 X 5.50",
			"label": "8.50 X 5.50"
	},
	{
			"value": "8.50 X 5.62",
			"label": "8.50 X 5.62"
	},
	{
			"value": "8.50 X 5.75",
			"label": "8.50 X 5.75"
	},
	{
			"value": "8.50 X 8.75 X .03",
			"label": "8.50 X 8.75 X .03"
	},
	{
			"value": "8.50 X 8.75 X.03",
			"label": "8.50 X 8.75 X.03"
	},
	{
			"value": "8.50 X8.75 X.03",
			"label": "8.50 X8.75 X.03"
	},
	{
			"value": "8.51 OZ",
			"label": "8.51 OZ"
	},
	{
			"value": "8.51 X 1.7 X 8.8",
			"label": "8.51 X 1.7 X 8.8"
	},
	{
			"value": "8.51 X 2.03 X 10.94",
			"label": "8.51 X 2.03 X 10.94"
	},
	{
			"value": "852",
			"label": "852"
	},
	{
			"value": "-8544",
			"label": "-8544"
	},
	{
			"value": "8.57 OZ",
			"label": "8.57 OZ"
	},
	{
			"value": "8.5 8.5 7.5;1.09LB",
			"label": "8.5 8.5 7.5;1.09LB"
	},
	{
			"value": "85 CU IN",
			"label": "85 CU IN"
	},
	{
			"value": "8.5 FLUID OUNCE",
			"label": "8.5 FLUID OUNCE"
	},
	{
			"value": "85 GR",
			"label": "85 GR"
	},
	{
			"value": "85GR",
			"label": "85GR"
	},
	{
			"value": "85GRAMS/100GRAMS",
			"label": "85GRAMS/100GRAMS"
	},
	{
			"value": "8.5 IN",
			"label": "8.5 IN"
	},
	{
			"value": "8.5IN",
			"label": "8.5IN"
	},
	{
			"value": "8.5 INCH",
			"label": "8.5 INCH"
	},
	{
			"value": "85  L X 1.5   X 1.5",
			"label": "85  L X 1.5   X 1.5"
	},
	{
			"value": ".85 OZ",
			"label": ".85 OZ"
	},
	{
			"value": ".85OZ",
			"label": ".85OZ"
	},
	{
			"value": "8.5 OZ",
			"label": "8.5 OZ"
	},
	{
			"value": "8.5OZ/241G",
			"label": "8.5OZ/241G"
	},
	{
			"value": "8.5 X 11",
			"label": "8.5 X 11"
	},
	{
			"value": "8.5   X 11",
			"label": "8.5   X 11"
	},
	{
			"value": "8.5  X11",
			"label": "8.5  X11"
	},
	{
			"value": "8.5X11",
			"label": "8.5X11"
	},
	{
			"value": "8.5 X11 SHEETS",
			"label": "8.5 X11 SHEETS"
	},
	{
			"value": "8.5X11X..2",
			"label": "8.5X11X..2"
	},
	{
			"value": "8.5X11X.2",
			"label": "8.5X11X.2"
	},
	{
			"value": "8.5X11X..8",
			"label": "8.5X11X..8"
	},
	{
			"value": "8.5 X 12",
			"label": "8.5 X 12"
	},
	{
			"value": "8.5 X 12 X 2",
			"label": "8.5 X 12 X 2"
	},
	{
			"value": "8.5X13",
			"label": "8.5X13"
	},
	{
			"value": "8.5X2.11",
			"label": "8.5X2.11"
	},
	{
			"value": "8.5X23X.75",
			"label": "8.5X23X.75"
	},
	{
			"value": "8.5 X 2.5 X 5.5",
			"label": "8.5 X 2.5 X 5.5"
	},
	{
			"value": "8.5X2X11",
			"label": "8.5X2X11"
	},
	{
			"value": "8.5 X 3.5 X 7",
			"label": "8.5 X 3.5 X 7"
	},
	{
			"value": "8.5   X 3.7   X 0.4",
			"label": "8.5   X 3.7   X 0.4"
	},
	{
			"value": "8.5  X39",
			"label": "8.5  X39"
	},
	{
			"value": "8.5 X 3.95 X 0.78",
			"label": "8.5 X 3.95 X 0.78"
	},
	{
			"value": "8.5 X 4.25",
			"label": "8.5 X 4.25"
	},
	{
			"value": "8.5X4.5",
			"label": "8.5X4.5"
	},
	{
			"value": "8.5X5",
			"label": "8.5X5"
	},
	{
			"value": "8.5X5.25",
			"label": "8.5X5.25"
	},
	{
			"value": "8.5X5.3",
			"label": "8.5X5.3"
	},
	{
			"value": "8.5X 5.5",
			"label": "8.5X 5.5"
	},
	{
			"value": "8.5 X 5.5 INCHES",
			"label": "8.5 X 5.5 INCHES"
	},
	{
			"value": "8.5 X 6.5",
			"label": "8.5 X 6.5"
	},
	{
			"value": "8.5 X 6.5 X 4.5",
			"label": "8.5 X 6.5 X 4.5"
	},
	{
			"value": "8.5X7.25X11.25",
			"label": "8.5X7.25X11.25"
	},
	{
			"value": "8.5 X 8.25 X 1",
			"label": "8.5 X 8.25 X 1"
	},
	{
			"value": "8.5 X 8.75 X .03",
			"label": "8.5 X 8.75 X .03"
	},
	{
			"value": "8.5 X 8.75X.03",
			"label": "8.5 X 8.75X.03"
	},
	{
			"value": "8.5 X8.75 X.03",
			"label": "8.5 X8.75 X.03"
	},
	{
			"value": "8.5X9.5",
			"label": "8.5X9.5"
	},
	{
			"value": "8.5X9.5X22",
			"label": "8.5X9.5X22"
	},
	{
			"value": " 8,6",
			"label": " 8,6"
	},
	{
			"value": "8.6\"\"",
			"label": "8.6\"\""
	},
	{
			"value": "860",
			"label": "860"
	},
	{
			"value": "8600",
			"label": "8600"
	},
	{
			"value": "8.625 X 5.125",
			"label": "8.625 X 5.125"
	},
	{
			"value": "8.625 X 6.125",
			"label": "8.625 X 6.125"
	},
	{
			"value": "8.625 X 8.625 X 6.62",
			"label": "8.625 X 8.625 X 6.62"
	},
	{
			"value": "8.62 X 2.12",
			"label": "8.62 X 2.12"
	},
	{
			"value": "8.62 X 4.90",
			"label": "8.62 X 4.90"
	},
	{
			"value": "8.62 X 5.12",
			"label": "8.62 X 5.12"
	},
	{
			"value": "8.62 X 5.62",
			"label": "8.62 X 5.62"
	},
	{
			"value": "8.62 X 5.75",
			"label": "8.62 X 5.75"
	},
	{
			"value": "8.63X8.75X4.38",
			"label": "8.63X8.75X4.38"
	},
	{
			"value": " 8,6,4",
			"label": " 8,6,4"
	},
	{
			"value": "8.65   X 10",
			"label": "8.65   X 10"
	},
	{
			"value": "866",
			"label": "866"
	},
	{
			"value": "8.66'' 8.66'' 7.09''",
			"label": "8.66'' 8.66'' 7.09''"
	},
	{
			"value": "8.67 IN",
			"label": "8.67 IN"
	},
	{
			"value": "8.67 IN/BOX",
			"label": "8.67 IN/BOX"
	},
	{
			"value": "8.6 OZ",
			"label": "8.6 OZ"
	},
	{
			"value": "86 X 15",
			"label": "86 X 15"
	},
	{
			"value": "86 X 72",
			"label": "86 X 72"
	},
	{
			"value": "86 X 84",
			"label": "86 X 84"
	},
	{
			"value": "86   X 86",
			"label": "86   X 86"
	},
	{
			"value": "86 X 96",
			"label": "86 X 96"
	},
	{
			"value": ".87",
			"label": ".87"
	},
	{
			"value": "87",
			"label": "87"
	},
	{
			"value": "8700",
			"label": "8700"
	},
	{
			"value": "8.70 X 5.70",
			"label": "8.70 X 5.70"
	},
	{
			"value": ".875",
			"label": ".875"
	},
	{
			"value": "8.75",
			"label": "8.75"
	},
	{
			"value": "8.75 IN",
			"label": "8.75 IN"
	},
	{
			"value": ".875 INCH",
			"label": ".875 INCH"
	},
	{
			"value": ".875 IN X 12 FT",
			"label": ".875 IN X 12 FT"
	},
	{
			"value": ".875 IN. X 12 FT.",
			"label": ".875 IN. X 12 FT."
	},
	{
			"value": ".875 IN X 15 FT",
			"label": ".875 IN X 15 FT"
	},
	{
			"value": ".875 IN. X 15 FT.",
			"label": ".875 IN. X 15 FT."
	},
	{
			"value": "8.75OZ",
			"label": "8.75OZ"
	},
	{
			"value": ".875   X 10YDS",
			"label": ".875   X 10YDS"
	},
	{
			"value": "8.75X11.75",
			"label": "8.75X11.75"
	},
	{
			"value": "8.75 X 12",
			"label": "8.75 X 12"
	},
	{
			"value": ".875 X 12 FT",
			"label": ".875 X 12 FT"
	},
	{
			"value": "8.75 X 3.25",
			"label": "8.75 X 3.25"
	},
	{
			"value": "8.75X4.625X5.75",
			"label": "8.75X4.625X5.75"
	},
	{
			"value": "8.75 X 5.55",
			"label": "8.75 X 5.55"
	},
	{
			"value": "8.75X5X4.5",
			"label": "8.75X5X4.5"
	},
	{
			"value": "8.75   X 7.5   X 3.",
			"label": "8.75   X 7.5   X 3."
	},
	{
			"value": "8.75 X 7 INCH BOOK",
			"label": "8.75 X 7 INCH BOOK"
	},
	{
			"value": "8.75   X 8.75   X 4",
			"label": "8.75   X 8.75   X 4"
	},
	{
			"value": "8.75 X 9.688",
			"label": "8.75 X 9.688"
	},
	{
			"value": "876",
			"label": "876"
	},
	{
			"value": "8760",
			"label": "8760"
	},
	{
			"value": "8.78 X 5.00",
			"label": "8.78 X 5.00"
	},
	{
			"value": ".87 OZ",
			"label": ".87 OZ"
	},
	{
			"value": ".87OZ",
			"label": ".87OZ"
	},
	{
			"value": "87 YARDS",
			"label": "87 YARDS"
	},
	{
			"value": "8.7 YD SKEIN",
			"label": "8.7 YD SKEIN"
	},
	{
			"value": "880",
			"label": "880"
	},
	{
			"value": "8800",
			"label": "8800"
	},
	{
			"value": "880 STICKERS PER PAC",
			"label": "880 STICKERS PER PAC"
	},
	{
			"value": "8.8125 X 6.375",
			"label": "8.8125 X 6.375"
	},
	{
			"value": "886",
			"label": "886"
	},
	{
			"value": "8.86IN",
			"label": "8.86IN"
	},
	{
			"value": "8.86   X 4.45   X 1",
			"label": "8.86   X 4.45   X 1"
	},
	{
			"value": "8.86 X 4.73 X 1.34",
			"label": "8.86 X 4.73 X 1.34"
	},
	{
			"value": "8.87 OZ",
			"label": "8.87 OZ"
	},
	{
			"value": "8.87 X 2.62",
			"label": "8.87 X 2.62"
	},
	{
			"value": "8.87 X 5.37",
			"label": "8.87 X 5.37"
	},
	{
			"value": "8880",
			"label": "8880"
	},
	{
			"value": "8.88   X 13.10   X",
			"label": "8.88   X 13.10   X"
	},
	{
			"value": "8.88' X 5.50' X 0.86",
			"label": "8.88' X 5.50' X 0.86"
	},
	{
			"value": ".88OZ",
			"label": ".88OZ"
	},
	{
			"value": "8.8 OZ",
			"label": "8.8 OZ"
	},
	{
			"value": "8.8 OZ.",
			"label": "8.8 OZ."
	},
	{
			"value": "8.8OZ/241G",
			"label": "8.8OZ/241G"
	},
	{
			"value": "8.8OZ/250G",
			"label": "8.8OZ/250G"
	},
	{
			"value": "8.8OZ/250GM",
			"label": "8.8OZ/250GM"
	},
	{
			"value": "88  X114",
			"label": "88  X114"
	},
	{
			"value": "88 X 18",
			"label": "88 X 18"
	},
	{
			"value": "8.8 X 3.2 X 1  H",
			"label": "8.8 X 3.2 X 1  H"
	},
	{
			"value": "88 X 84",
			"label": "88 X 84"
	},
	{
			"value": "88X94",
			"label": "88X94"
	},
	{
			"value": "88  X94",
			"label": "88  X94"
	},
	{
			"value": "8.90 X 5.90",
			"label": "8.90 X 5.90"
	},
	{
			"value": "8928",
			"label": "8928"
	},
	{
			"value": "8.9375  W X 9.375",
			"label": "8.9375  W X 9.375"
	},
	{
			"value": "-8952",
			"label": "-8952"
	},
	{
			"value": "8.95 OZ",
			"label": "8.95 OZ"
	},
	{
			"value": "8.97 X 4.76",
			"label": "8.97 X 4.76"
	},
	{
			"value": "899",
			"label": "899"
	},
	{
			"value": "8.9 OZ",
			"label": "8.9 OZ"
	},
	{
			"value": "8 AND 5",
			"label": "8 AND 5"
	},
	{
			"value": "8   AND 5",
			"label": "8   AND 5"
	},
	{
			"value": "8 AND 8",
			"label": "8 AND 8"
	},
	{
			"value": "8   AND 8",
			"label": "8   AND 8"
	},
	{
			"value": "8 AND A HALF X 11",
			"label": "8 AND A HALF X 11"
	},
	{
			"value": "8 AND HALF 11",
			"label": "8 AND HALF 11"
	},
	{
			"value": "8 AND HALF 11 2PK",
			"label": "8 AND HALF 11 2PK"
	},
	{
			"value": "8 AND HALF X 11",
			"label": "8 AND HALF X 11"
	},
	{
			"value": "8 AND HALF X 14",
			"label": "8 AND HALF X 14"
	},
	{
			"value": "8CT",
			"label": "8CT"
	},
	{
			"value": "8CT / 16OZ",
			"label": "8CT / 16OZ"
	},
	{
			"value": "8 FL OZ",
			"label": "8 FL OZ"
	},
	{
			"value": "8FT",
			"label": "8FT"
	},
	{
			"value": "8  H X 4.5  W X 4.7",
			"label": "8  H X 4.5  W X 4.7"
	},
	{
			"value": "8  H X 6.5  W X 6.7",
			"label": "8  H X 6.5  W X 6.7"
	},
	{
			"value": "8IIN",
			"label": "8IIN"
	},
	{
			"value": "8 IN",
			"label": "8 IN"
	},
	{
			"value": "8 IN.",
			"label": "8 IN."
	},
	{
			"value": "8IN",
			"label": "8IN"
	},
	{
			"value": "8 INCH",
			"label": "8 INCH"
	},
	{
			"value": "8 INCH AND 5 INCH",
			"label": "8 INCH AND 5 INCH"
	},
	{
			"value": "8 INCH X 15 INCH",
			"label": "8 INCH X 15 INCH"
	},
	{
			"value": "8INX4IN",
			"label": "8INX4IN"
	},
	{
			"value": "8INX8IN",
			"label": "8INX8IN"
	},
	{
			"value": "8K BRD",
			"label": "8K BRD"
	},
	{
			"value": "8-MAR",
			"label": "8-MAR"
	},
	{
			"value": "8MM",
			"label": "8MM"
	},
	{
			"value": "8 OUNCES",
			"label": "8 OUNCES"
	},
	{
			"value": ".8 OZ",
			"label": ".8 OZ"
	},
	{
			"value": "8 OZ",
			"label": "8 OZ"
	},
	{
			"value": "8 OZ.",
			"label": "8 OZ."
	},
	{
			"value": ".8OZ",
			"label": ".8OZ"
	},
	{
			"value": "8  OZ",
			"label": "8  OZ"
	},
	{
			"value": "8. OZ",
			"label": "8. OZ"
	},
	{
			"value": "8OZ",
			"label": "8OZ"
	},
	{
			"value": "8OZ.",
			"label": "8OZ."
	},
	{
			"value": "8 OZ BOTTLE",
			"label": "8 OZ BOTTLE"
	},
	{
			"value": "8OZ JAR",
			"label": "8OZ JAR"
	},
	{
			"value": "8OZ X96  X11 YDS DO",
			"label": "8OZ X96  X11 YDS DO"
	},
	{
			"value": "8 PACK",
			"label": "8 PACK"
	},
	{
			"value": "8 PC",
			"label": "8 PC"
	},
	{
			"value": "8PC",
			"label": "8PC"
	},
	{
			"value": "8 PIECES",
			"label": "8 PIECES"
	},
	{
			"value": "8PK",
			"label": "8PK"
	},
	{
			"value": "8 POT",
			"label": "8 POT"
	},
	{
			"value": "8 ROLLS",
			"label": "8 ROLLS"
	},
	{
			"value": "8 SHEET",
			"label": "8 SHEET"
	},
	{
			"value": "8 STRANDS 14\"\" EACH",
			"label": "8 STRANDS 14\"\" EACH"
	},
	{
			"value": "8 X 10",
			"label": "8 X 10"
	},
	{
			"value": "8   X 10",
			"label": "8   X 10"
	},
	{
			"value": "8  X10",
			"label": "8  X10"
	},
	{
			"value": "8X10",
			"label": "8X10"
	},
	{
			"value": "8X10 2PK",
			"label": "8X10 2PK"
	},
	{
			"value": "8X10 2TONE STEP FILL",
			"label": "8X10 2TONE STEP FILL"
	},
	{
			"value": "8X10.75X.25",
			"label": "8X10.75X.25"
	},
	{
			"value": "8   X 10.875",
			"label": "8   X 10.875"
	},
	{
			"value": "8X10 BLACK",
			"label": "8X10 BLACK"
	},
	{
			"value": "8X10 BLACK BEAD",
			"label": "8X10 BLACK BEAD"
	},
	{
			"value": "8X10 BRONZE 2TONE",
			"label": "8X10 BRONZE 2TONE"
	},
	{
			"value": "8X10 ESPRESSO",
			"label": "8X10 ESPRESSO"
	},
	{
			"value": "8X10 MATTED TO 5X7",
			"label": "8X10 MATTED TO 5X7"
	},
	{
			"value": "8X10 MAT TO 5X7",
			"label": "8X10 MAT TO 5X7"
	},
	{
			"value": "8X10 OPENING",
			"label": "8X10 OPENING"
	},
	{
			"value": "8X10 PEWTER BEAD",
			"label": "8X10 PEWTER BEAD"
	},
	{
			"value": "8X10 SILVER ORNATE S",
			"label": "8X10 SILVER ORNATE S"
	},
	{
			"value": "8X10 TO 5X7",
			"label": "8X10 TO 5X7"
	},
	{
			"value": "8  X10  X1-1/2",
			"label": "8  X10  X1-1/2"
	},
	{
			"value": "8 X 10 X 12",
			"label": "8 X 10 X 12"
	},
	{
			"value": "8 X 10 X 14",
			"label": "8 X 10 X 14"
	},
	{
			"value": "8   X 10   X 14",
			"label": "8   X 10   X 14"
	},
	{
			"value": "8   X 10   X14",
			"label": "8   X 10   X14"
	},
	{
			"value": "8X10X.2",
			"label": "8X10X.2"
	},
	{
			"value": "8 X 11",
			"label": "8 X 11"
	},
	{
			"value": "8  X11",
			"label": "8  X11"
	},
	{
			"value": "8X11",
			"label": "8X11"
	},
	{
			"value": "8   X 11.5",
			"label": "8   X 11.5"
	},
	{
			"value": "8X11 PAD SET",
			"label": "8X11 PAD SET"
	},
	{
			"value": "8X12",
			"label": "8X12"
	},
	{
			"value": "8X12IN",
			"label": "8X12IN"
	},
	{
			"value": "8 X 13 X 2.75",
			"label": "8 X 13 X 2.75"
	},
	{
			"value": "8X15",
			"label": "8X15"
	},
	{
			"value": "8 X 1.5 X 6.73",
			"label": "8 X 1.5 X 6.73"
	},
	{
			"value": "8 X 1.5 X 7",
			"label": "8 X 1.5 X 7"
	},
	{
			"value": "8X16",
			"label": "8X16"
	},
	{
			"value": "8X17",
			"label": "8X17"
	},
	{
			"value": "8X1.75X20",
			"label": "8X1.75X20"
	},
	{
			"value": "8X18",
			"label": "8X18"
	},
	{
			"value": "8X18 2.5",
			"label": "8X18 2.5"
	},
	{
			"value": "8X1X10",
			"label": "8X1X10"
	},
	{
			"value": "8X1X15.75",
			"label": "8X1X15.75"
	},
	{
			"value": "8   X 1YD",
			"label": "8   X 1YD"
	},
	{
			"value": "8X2",
			"label": "8X2"
	},
	{
			"value": "8X20",
			"label": "8X20"
	},
	{
			"value": "8X24",
			"label": "8X24"
	},
	{
			"value": "8X2.5X.75",
			"label": "8X2.5X.75"
	},
	{
			"value": "8 X 2.75",
			"label": "8 X 2.75"
	},
	{
			"value": "8X2X10",
			"label": "8X2X10"
	},
	{
			"value": "8X2X42.25",
			"label": "8X2X42.25"
	},
	{
			"value": "8X2X8",
			"label": "8X2X8"
	},
	{
			"value": "8X3",
			"label": "8X3"
	},
	{
			"value": "8X3.25X6.5",
			"label": "8X3.25X6.5"
	},
	{
			"value": "8X3.5",
			"label": "8X3.5"
	},
	{
			"value": "8 X 3.5 X 1",
			"label": "8 X 3.5 X 1"
	},
	{
			"value": "8 X 3.75 X 2.375 IN",
			"label": "8 X 3.75 X 2.375 IN"
	},
	{
			"value": "8 X 3 X 0.25",
			"label": "8 X 3 X 0.25"
	},
	{
			"value": "8 X 3 X 16.5",
			"label": "8 X 3 X 16.5"
	},
	{
			"value": "8X4",
			"label": "8X4"
	},
	{
			"value": "8X4.125X12",
			"label": "8X4.125X12"
	},
	{
			"value": "8X4.75X14.125",
			"label": "8X4.75X14.125"
	},
	{
			"value": "8   X 4   X 2.5",
			"label": "8   X 4   X 2.5"
	},
	{
			"value": "8X4X3",
			"label": "8X4X3"
	},
	{
			"value": "8X5",
			"label": "8X5"
	},
	{
			"value": "8X5.4X14",
			"label": "8X5.4X14"
	},
	{
			"value": "8X5.5X12.5",
			"label": "8X5.5X12.5"
	},
	{
			"value": "8X5.5X2",
			"label": "8X5.5X2"
	},
	{
			"value": "8X5X0.05",
			"label": "8X5X0.05"
	},
	{
			"value": "8 X 5 X 15 INCH",
			"label": "8 X 5 X 15 INCH"
	},
	{
			"value": "8X5X7",
			"label": "8X5X7"
	},
	{
			"value": "8X6",
			"label": "8X6"
	},
	{
			"value": "8  X6.25",
			"label": "8  X6.25"
	},
	{
			"value": "8X6.25",
			"label": "8X6.25"
	},
	{
			"value": "8X6.25X.69",
			"label": "8X6.25X.69"
	},
	{
			"value": "8 X 6.5 X .07INCHES",
			"label": "8 X 6.5 X .07INCHES"
	},
	{
			"value": "8X6.5X11.75",
			"label": "8X6.5X11.75"
	},
	{
			"value": "8X6X0.5",
			"label": "8X6X0.5"
	},
	{
			"value": "8X7.5",
			"label": "8X7.5"
	},
	{
			"value": "8 X 75ML",
			"label": "8 X 75ML"
	},
	{
			"value": "8 X .75 X 12.25",
			"label": "8 X .75 X 12.25"
	},
	{
			"value": "8X7X.03",
			"label": "8X7X.03"
	},
	{
			"value": "8 X 8",
			"label": "8 X 8"
	},
	{
			"value": "8   X 8",
			"label": "8   X 8"
	},
	{
			"value": "8  X8",
			"label": "8  X8"
	},
	{
			"value": "8X8",
			"label": "8X8"
	},
	{
			"value": "8   X 8.5",
			"label": "8   X 8.5"
	},
	{
			"value": "8 X 8.75",
			"label": "8 X 8.75"
	},
	{
			"value": "8X8 TO 4X4",
			"label": "8X8 TO 4X4"
	},
	{
			"value": "8   X 8   X .07",
			"label": "8   X 8   X .07"
	},
	{
			"value": ".8X.8X1.5",
			"label": ".8X.8X1.5"
	},
	{
			"value": "8 X 8 X 16",
			"label": "8 X 8 X 16"
	},
	{
			"value": "8X8X2",
			"label": "8X8X2"
	},
	{
			"value": "8   X 8   X 2.25",
			"label": "8   X 8   X 2.25"
	},
	{
			"value": "8  X8  X2.25",
			"label": "8  X8  X2.25"
	},
	{
			"value": "8 X 8 X 4 IN",
			"label": "8 X 8 X 4 IN"
	},
	{
			"value": "8 X 8 X 4 INCH",
			"label": "8 X 8 X 4 INCH"
	},
	{
			"value": "8X8X6.5",
			"label": "8X8X6.5"
	},
	{
			"value": "8 X 9",
			"label": "8 X 9"
	},
	{
			"value": "8   X 9",
			"label": "8   X 9"
	},
	{
			"value": "8X9",
			"label": "8X9"
	},
	{
			"value": "8X9.5X2 INCHES",
			"label": "8X9.5X2 INCHES"
	},
	{
			"value": "8 YARD BOLT",
			"label": "8 YARD BOLT"
	},
	{
			"value": "8YD",
			"label": "8YD"
	},
	{
			"value": "8YDS",
			"label": "8YDS"
	},
	{
			"value": "8Z",
			"label": "8Z"
	},
	{
			"value": ".9",
			"label": ".9"
	},
	{
			"value": "9",
			"label": "9"
	},
	{
			"value": "9\"\"",
			"label": "9\"\""
	},
	{
			"value": "90",
			"label": "90"
	},
	{
			"value": "90\"\"",
			"label": "90\"\""
	},
	{
			"value": "900",
			"label": "900"
	},
	{
			"value": "9000",
			"label": "9000"
	},
	{
			"value": "9.00   X 10.63",
			"label": "9.00   X 10.63"
	},
	{
			"value": "9.00 X 3.15",
			"label": "9.00 X 3.15"
	},
	{
			"value": "9.00 X 3.25",
			"label": "9.00 X 3.25"
	},
	{
			"value": "9.00 X 4.70",
			"label": "9.00 X 4.70"
	},
	{
			"value": "9.00 X 4.75",
			"label": "9.00 X 4.75"
	},
	{
			"value": "9.00 X 4.78",
			"label": "9.00 X 4.78"
	},
	{
			"value": "9.00 X 4.80",
			"label": "9.00 X 4.80"
	},
	{
			"value": "9.00 X 4.87",
			"label": "9.00 X 4.87"
	},
	{
			"value": "9.00 X 5.25",
			"label": "9.00 X 5.25"
	},
	{
			"value": "9.00X 5.50",
			"label": "9.00X 5.50"
	},
	{
			"value": "9.00 X 6.00",
			"label": "9.00 X 6.00"
	},
	{
			"value": "9.00 X 6.12",
			"label": "9.00 X 6.12"
	},
	{
			"value": "9.00 X 6.25",
			"label": "9.00 X 6.25"
	},
	{
			"value": "9.00 X 9.00",
			"label": "9.00 X 9.00"
	},
	{
			"value": "-9012",
			"label": "-9012"
	},
	{
			"value": "90-130",
			"label": "90-130"
	},
	{
			"value": "904",
			"label": "904"
	},
	{
			"value": "9.05   X 11   X 2",
			"label": "9.05   X 11   X 2"
	},
	{
			"value": "9.05 X 3.78",
			"label": "9.05 X 3.78"
	},
	{
			"value": "9.05 X 4.13",
			"label": "9.05 X 4.13"
	},
	{
			"value": "9.05 X 4.75",
			"label": "9.05 X 4.75"
	},
	{
			"value": "9.05 X4.75",
			"label": "9.05 X4.75"
	},
	{
			"value": "9.05 X 4.76",
			"label": "9.05 X 4.76"
	},
	{
			"value": "9.05 X 4.84",
			"label": "9.05 X 4.84"
	},
	{
			"value": "9.05 X 4.87",
			"label": "9.05 X 4.87"
	},
	{
			"value": "9.06",
			"label": "9.06"
	},
	{
			"value": "9.06''   6.30''   7.",
			"label": "9.06''   6.30''   7."
	},
	{
			"value": ".906IN X 6YD",
			"label": ".906IN X 6YD"
	},
	{
			"value": "9.08 OZ",
			"label": "9.08 OZ"
	},
	{
			"value": "9.09 X 4..76",
			"label": "9.09 X 4..76"
	},
	{
			"value": "9.09 X 4.76",
			"label": "9.09 X 4.76"
	},
	{
			"value": "90GR",
			"label": "90GR"
	},
	{
			"value": "90 LITER",
			"label": "90 LITER"
	},
	{
			"value": ".90 OZ",
			"label": ".90 OZ"
	},
	{
			"value": "90 PC",
			"label": "90 PC"
	},
	{
			"value": "90   ROUND",
			"label": "90   ROUND"
	},
	{
			"value": "90   SQUARE",
			"label": "90   SQUARE"
	},
	{
			"value": "9.0   X 10.5",
			"label": "9.0   X 10.5"
	},
	{
			"value": "90 X 108",
			"label": "90 X 108"
	},
	{
			"value": "90X108",
			"label": "90X108"
	},
	{
			"value": "90   X 108",
			"label": "90   X 108"
	},
	{
			"value": "90   X 20 YARDS",
			"label": "90   X 20 YARDS"
	},
	{
			"value": "90  X20YD",
			"label": "90  X20YD"
	},
	{
			"value": "90   X 20 YDS",
			"label": "90   X 20 YDS"
	},
	{
			"value": "90  X20 YDS",
			"label": "90  X20 YDS"
	},
	{
			"value": "90  X20YDS",
			"label": "90  X20YDS"
	},
	{
			"value": "90X25YDS",
			"label": "90X25YDS"
	},
	{
			"value": "90   X30 YARDS",
			"label": "90   X30 YARDS"
	},
	{
			"value": "90\"\" X 40 YD ROLL",
			"label": "90\"\" X 40 YD ROLL"
	},
	{
			"value": "9.0 X 6.3 X 4.0 IN",
			"label": "9.0 X 6.3 X 4.0 IN"
	},
	{
			"value": "9.0 X 6.3 X 4.0 INCH",
			"label": "9.0 X 6.3 X 4.0 INCH"
	},
	{
			"value": "90   X 6 YARDS",
			"label": "90   X 6 YARDS"
	},
	{
			"value": "90\"\" X 6YDS",
			"label": "90\"\" X 6YDS"
	},
	{
			"value": "90 X 84",
			"label": "90 X 84"
	},
	{
			"value": "9.0   X 8.5",
			"label": "9.0   X 8.5"
	},
	{
			"value": "90 X 96",
			"label": "90 X 96"
	},
	{
			"value": "9.10 X 4.87",
			"label": "9.10 X 4.87"
	},
	{
			"value": "9-11",
			"label": "9-11"
	},
	{
			"value": "911",
			"label": "911"
	},
	{
			"value": "912",
			"label": "912"
	},
	{
			"value": "9120",
			"label": "9120"
	},
	{
			"value": "9.125 X 1.25",
			"label": "9.125 X 1.25"
	},
	{
			"value": "9.125   X 5   X 1.3",
			"label": "9.125   X 5   X 1.3"
	},
	{
			"value": "9 - 12 MO",
			"label": "9 - 12 MO"
	},
	{
			"value": "9-12 MO",
			"label": "9-12 MO"
	},
	{
			"value": "9-12 MONTHS",
			"label": "9-12 MONTHS"
	},
	{
			"value": "9-12 MOTHS",
			"label": "9-12 MOTHS"
	},
	{
			"value": "9.12 X 4.75",
			"label": "9.12 X 4.75"
	},
	{
			"value": "9.12 X 5.06",
			"label": "9.12 X 5.06"
	},
	{
			"value": "9.12 X 5.25",
			"label": "9.12 X 5.25"
	},
	{
			"value": "9-13",
			"label": "9-13"
	},
	{
			"value": "913",
			"label": "913"
	},
	{
			"value": "9144",
			"label": "9144"
	},
	{
			"value": "9/16",
			"label": "9/16"
	},
	{
			"value": "9/16\"\"",
			"label": "9/16\"\""
	},
	{
			"value": "9/16   ( 13MM )",
			"label": "9/16   ( 13MM )"
	},
	{
			"value": "9/16IN",
			"label": "9/16IN"
	},
	{
			"value": "9.1IN",
			"label": "9.1IN"
	},
	{
			"value": "9.1''LX7.9''WX10.2''",
			"label": "9.1''LX7.9''WX10.2''"
	},
	{
			"value": "9.1''LX9.1''WX2.4''H",
			"label": "9.1''LX9.1''WX2.4''H"
	},
	{
			"value": ".91 OZ",
			"label": ".91 OZ"
	},
	{
			"value": "9.20 X 3.40 X .80",
			"label": "9.20 X 3.40 X .80"
	},
	{
			"value": "9.20 X 5.70",
			"label": "9.20 X 5.70"
	},
	{
			"value": "9 (229MM)",
			"label": "9 (229MM)"
	},
	{
			"value": "9.25 X 10.5 X 4.5",
			"label": "9.25 X 10.5 X 4.5"
	},
	{
			"value": "9.25 X 11.25",
			"label": "9.25 X 11.25"
	},
	{
			"value": "9.25 X 4.75",
			"label": "9.25 X 4.75"
	},
	{
			"value": "9.25 X 4.87",
			"label": "9.25 X 4.87"
	},
	{
			"value": "9.25 X 5.25",
			"label": "9.25 X 5.25"
	},
	{
			"value": "9.25X5.25",
			"label": "9.25X5.25"
	},
	{
			"value": "9.25 X 6.25",
			"label": "9.25 X 6.25"
	},
	{
			"value": "9.25 X 6.50",
			"label": "9.25 X 6.50"
	},
	{
			"value": "9.25 X 7.00",
			"label": "9.25 X 7.00"
	},
	{
			"value": "9.25 X 7.12",
			"label": "9.25 X 7.12"
	},
	{
			"value": "9.25X7.5",
			"label": "9.25X7.5"
	},
	{
			"value": "9.25 X 8.00",
			"label": "9.25 X 8.00"
	},
	{
			"value": "9.25X9.5X1.25",
			"label": "9.25X9.5X1.25"
	},
	{
			"value": "9.25X9.5X2",
			"label": "9.25X9.5X2"
	},
	{
			"value": "9264",
			"label": "9264"
	},
	{
			"value": "9.29X13",
			"label": "9.29X13"
	},
	{
			"value": ".92 OZ",
			"label": ".92 OZ"
	},
	{
			"value": ".92OZ",
			"label": ".92OZ"
	},
	{
			"value": "9.2 OZ",
			"label": "9.2 OZ"
	},
	{
			"value": "9.2 X 3.4 X 0.80",
			"label": "9.2 X 3.4 X 0.80"
	},
	{
			"value": "92 X 84",
			"label": "92 X 84"
	},
	{
			"value": "92 X 96",
			"label": "92 X 96"
	},
	{
			"value": "930",
			"label": "930"
	},
	{
			"value": "9300",
			"label": "9300"
	},
	{
			"value": "9.30 X 4.80",
			"label": "9.30 X 4.80"
	},
	{
			"value": "9.30 X 7.00",
			"label": "9.30 X 7.00"
	},
	{
			"value": "9312",
			"label": "9312"
	},
	{
			"value": "9.35 X 8.00",
			"label": "9.35 X 8.00"
	},
	{
			"value": "9.36IN",
			"label": "9.36IN"
	},
	{
			"value": "9.370 X 7.37",
			"label": "9.370 X 7.37"
	},
	{
			"value": "9.375 X 6 X 3.5",
			"label": "9.375 X 6 X 3.5"
	},
	{
			"value": "9.37 X 5.00",
			"label": "9.37 X 5.00"
	},
	{
			"value": "9.37X6.25X5.5",
			"label": "9.37X6.25X5.5"
	},
	{
			"value": "9.37 X 7.37",
			"label": "9.37 X 7.37"
	},
	{
			"value": "9.3 IN",
			"label": "9.3 IN"
	},
	{
			"value": ".93 OZ",
			"label": ".93 OZ"
	},
	{
			"value": "9.3 OZ",
			"label": "9.3 OZ"
	},
	{
			"value": "9.4",
			"label": "9.4"
	},
	{
			"value": "9.4\"\"",
			"label": "9.4\"\""
	},
	{
			"value": "9400",
			"label": "9400"
	},
	{
			"value": "9.41 X 0.52 X 13.26",
			"label": "9.41 X 0.52 X 13.26"
	},
	{
			"value": "9.44 X 0.51 X 13.23",
			"label": "9.44 X 0.51 X 13.23"
	},
	{
			"value": "9.44 X 4.720 X .90",
			"label": "9.44 X 4.720 X .90"
	},
	{
			"value": "9.44 X 7.08",
			"label": "9.44 X 7.08"
	},
	{
			"value": "9.44 X 8.66",
			"label": "9.44 X 8.66"
	},
	{
			"value": "9.45",
			"label": "9.45"
	},
	{
			"value": "9.45X9.45X11.02",
			"label": "9.45X9.45X11.02"
	},
	{
			"value": "9.46 IN",
			"label": "9.46 IN"
	},
	{
			"value": "94IN X 20YD",
			"label": "94IN X 20YD"
	},
	{
			"value": "94INX20YD",
			"label": "94INX20YD"
	},
	{
			"value": ".94 IN X 60 YDS",
			"label": ".94 IN X 60 YDS"
	},
	{
			"value": "9.4 OZ",
			"label": "9.4 OZ"
	},
	{
			"value": ".94 X 20YRDS",
			"label": ".94 X 20YRDS"
	},
	{
			"value": ".94   X 30 YD.",
			"label": ".94   X 30 YD."
	},
	{
			"value": ".94   X 5'",
			"label": ".94   X 5'"
	},
	{
			"value": "94 X 96",
			"label": "94 X 96"
	},
	{
			"value": "9.5",
			"label": "9.5"
	},
	{
			"value": "950",
			"label": "950"
	},
	{
			"value": "9500",
			"label": "9500"
	},
	{
			"value": "9.50 X 4.50",
			"label": "9.50 X 4.50"
	},
	{
			"value": "9.50 X 4.87",
			"label": "9.50 X 4.87"
	},
	{
			"value": "9.50 X 7.50",
			"label": "9.50 X 7.50"
	},
	{
			"value": "9-5/8 X 1-3/16 IN",
			"label": "9-5/8 X 1-3/16 IN"
	},
	{
			"value": "95G",
			"label": "95G"
	},
	{
			"value": "9.5  H X 6.5  W X 6",
			"label": "9.5  H X 6.5  W X 6"
	},
	{
			"value": "9.5 H X 7.5 W X 4 D",
			"label": "9.5 H X 7.5 W X 4 D"
	},
	{
			"value": "9.5IN",
			"label": "9.5IN"
	},
	{
			"value": "9.5 INCH",
			"label": "9.5 INCH"
	},
	{
			"value": "9.5 OZ",
			"label": "9.5 OZ"
	},
	{
			"value": "9.5 X 10.25 X .5",
			"label": "9.5 X 10.25 X .5"
	},
	{
			"value": "9.5   X 14.5",
			"label": "9.5   X 14.5"
	},
	{
			"value": "9.5X2.5",
			"label": "9.5X2.5"
	},
	{
			"value": "9.5X2.75X11",
			"label": "9.5X2.75X11"
	},
	{
			"value": "9.5 X 3.25",
			"label": "9.5 X 3.25"
	},
	{
			"value": "9.5   X 3.6   X .04",
			"label": "9.5   X 3.6   X .04"
	},
	{
			"value": "9.5   X 3.6   X 1.2",
			"label": "9.5   X 3.6   X 1.2"
	},
	{
			"value": "9.5X.375X15.5",
			"label": "9.5X.375X15.5"
	},
	{
			"value": "9.5X3X12.75",
			"label": "9.5X3X12.75"
	},
	{
			"value": "9.5X5.5",
			"label": "9.5X5.5"
	},
	{
			"value": "9.5X5X3.5",
			"label": "9.5X5X3.5"
	},
	{
			"value": "9.5X6X11.75",
			"label": "9.5X6X11.75"
	},
	{
			"value": "9.5X7",
			"label": "9.5X7"
	},
	{
			"value": "9.5   X 7.0   X 1.5",
			"label": "9.5   X 7.0   X 1.5"
	},
	{
			"value": "9.5 X 7 X 1.5",
			"label": "9.5 X 7 X 1.5"
	},
	{
			"value": "9.5 X 7 X1.5",
			"label": "9.5 X 7 X1.5"
	},
	{
			"value": "9.5   X 7   X 1.5",
			"label": "9.5   X 7   X 1.5"
	},
	{
			"value": "9.5   X 7   X 1.50",
			"label": "9.5   X 7   X 1.50"
	},
	{
			"value": "9.5 X 7 X 2.25",
			"label": "9.5 X 7 X 2.25"
	},
	{
			"value": "9.5 X 8 X 0.38",
			"label": "9.5 X 8 X 0.38"
	},
	{
			"value": "9.5 X 9.0 X 5.25",
			"label": "9.5 X 9.0 X 5.25"
	},
	{
			"value": "9.5X9.5",
			"label": "9.5X9.5"
	},
	{
			"value": "9.5 X 9.5 X 0.5",
			"label": "9.5 X 9.5 X 0.5"
	},
	{
			"value": "9.5X9.5X25",
			"label": "9.5X9.5X25"
	},
	{
			"value": "9.5 X 9.5 X 5.5",
			"label": "9.5 X 9.5 X 5.5"
	},
	{
			"value": "9.5   X 9   X 5.25",
			"label": "9.5   X 9   X 5.25"
	},
	{
			"value": "96",
			"label": "96"
	},
	{
			"value": "96\"\"",
			"label": "96\"\""
	},
	{
			"value": "960",
			"label": "960"
	},
	{
			"value": "9600",
			"label": "9600"
	},
	{
			"value": "9.60 X 5.50",
			"label": "9.60 X 5.50"
	},
	{
			"value": "9.62 X 5.50",
			"label": "9.62 X 5.50"
	},
	{
			"value": "9.62 X 6",
			"label": "9.62 X 6"
	},
	{
			"value": "9.62 X 8.00",
			"label": "9.62 X 8.00"
	},
	{
			"value": "9.62 X9.14",
			"label": "9.62 X9.14"
	},
	{
			"value": "9672",
			"label": "9672"
	},
	{
			"value": "9.6875 X 8.75 X 4.43",
			"label": "9.6875 X 8.75 X 4.43"
	},
	{
			"value": "9.688 X 9.937",
			"label": "9.688 X 9.937"
	},
	{
			"value": "969",
			"label": "969"
	},
	{
			"value": "9.6 OZ",
			"label": "9.6 OZ"
	},
	{
			"value": "96 X 110",
			"label": "96 X 110"
	},
	{
			"value": "96  X20 YDS",
			"label": "96  X20 YDS"
	},
	{
			"value": "96   X 24",
			"label": "96   X 24"
	},
	{
			"value": "96  X25YDS",
			"label": "96  X25YDS"
	},
	{
			"value": "96   X 30 YARDS",
			"label": "96   X 30 YARDS"
	},
	{
			"value": "96  X30 YDS",
			"label": "96  X30 YDS"
	},
	{
			"value": "9.6X6.5",
			"label": "9.6X6.5"
	},
	{
			"value": "9.6X7.5",
			"label": "9.6X7.5"
	},
	{
			"value": "96 X 96",
			"label": "96 X 96"
	},
	{
			"value": "970",
			"label": "970"
	},
	{
			"value": "9700",
			"label": "9700"
	},
	{
			"value": "9.70 X 8.62",
			"label": "9.70 X 8.62"
	},
	{
			"value": "9.72 X 8.26",
			"label": "9.72 X 8.26"
	},
	{
			"value": "9.75IN",
			"label": "9.75IN"
	},
	{
			"value": "9.75X1.75X24",
			"label": "9.75X1.75X24"
	},
	{
			"value": "9.75X24X0.75",
			"label": "9.75X24X0.75"
	},
	{
			"value": "9.75 X 2.75",
			"label": "9.75 X 2.75"
	},
	{
			"value": "9.75 X 3.25",
			"label": "9.75 X 3.25"
	},
	{
			"value": "9.75 X 4.25 X 10",
			"label": "9.75 X 4.25 X 10"
	},
	{
			"value": "9.75 X 5.00",
			"label": "9.75 X 5.00"
	},
	{
			"value": "9.75 X 5.62",
			"label": "9.75 X 5.62"
	},
	{
			"value": "9.75 X 6.25",
			"label": "9.75 X 6.25"
	},
	{
			"value": "9.75 X 6.50",
			"label": "9.75 X 6.50"
	},
	{
			"value": "9.75 X 6.75",
			"label": "9.75 X 6.75"
	},
	{
			"value": "9.75   X 7.5",
			"label": "9.75   X 7.5"
	},
	{
			"value": "9.75 X 7.75",
			"label": "9.75 X 7.75"
	},
	{
			"value": "9.75 X 8.50",
			"label": "9.75 X 8.50"
	},
	{
			"value": "9.75 X 8.55",
			"label": "9.75 X 8.55"
	},
	{
			"value": "9.75 X 8.62",
			"label": "9.75 X 8.62"
	},
	{
			"value": "9.75 X 8.75",
			"label": "9.75 X 8.75"
	},
	{
			"value": "9.75 X 9.62",
			"label": "9.75 X 9.62"
	},
	{
			"value": "9792",
			"label": "9792"
	},
	{
			"value": "9.7 IN",
			"label": "9.7 IN"
	},
	{
			"value": ".97 OZ",
			"label": ".97 OZ"
	},
	{
			"value": "9.7 OZ",
			"label": "9.7 OZ"
	},
	{
			"value": "9.7OZ",
			"label": "9.7OZ"
	},
	{
			"value": "9.7X3X25",
			"label": "9.7X3X25"
	},
	{
			"value": "9.7 X 6.25",
			"label": "9.7 X 6.25"
	},
	{
			"value": "9.8\"\"",
			"label": "9.8\"\""
	},
	{
			"value": "9804",
			"label": "9804"
	},
	{
			"value": "9.80 X 8.37",
			"label": "9.80 X 8.37"
	},
	{
			"value": "9.842",
			"label": "9.842"
	},
	{
			"value": "9.84 X 7.48",
			"label": "9.84 X 7.48"
	},
	{
			"value": "9.84 X 8.26",
			"label": "9.84 X 8.26"
	},
	{
			"value": "9.84 X 8.66",
			"label": "9.84 X 8.66"
	},
	{
			"value": "9.84X8.74X5.71",
			"label": "9.84X8.74X5.71"
	},
	{
			"value": "9.85 IN",
			"label": "9.85 IN"
	},
	{
			"value": "9.875 X 3.1875 X 3.4",
			"label": "9.875 X 3.1875 X 3.4"
	},
	{
			"value": "9.87 X 3.00",
			"label": "9.87 X 3.00"
	},
	{
			"value": "9.87 X 5.87",
			"label": "9.87 X 5.87"
	},
	{
			"value": "9.87 X 7.50",
			"label": "9.87 X 7.50"
	},
	{
			"value": "9.87 X 8.37",
			"label": "9.87 X 8.37"
	},
	{
			"value": "9.87 X 9.00",
			"label": "9.87 X 9.00"
	},
	{
			"value": "9.8 IN",
			"label": "9.8 IN"
	},
	{
			"value": "9.8 INCHES IN DIAMET",
			"label": "9.8 INCHES IN DIAMET"
	},
	{
			"value": "9.8X2.25X9.8",
			"label": "9.8X2.25X9.8"
	},
	{
			"value": "9.8   X 2 X .47  H",
			"label": "9.8   X 2 X .47  H"
	},
	{
			"value": "9.8 X 8 X 8.6",
			"label": "9.8 X 8 X 8.6"
	},
	{
			"value": "98 X 96",
			"label": "98 X 96"
	},
	{
			"value": "9.8 X 9.8 X 3.2",
			"label": "9.8 X 9.8 X 3.2"
	},
	{
			"value": ".98YD",
			"label": ".98YD"
	},
	{
			"value": "99",
			"label": "99"
	},
	{
			"value": "9.9375X9.6875X4.8125",
			"label": "9.9375X9.6875X4.8125"
	},
	{
			"value": "-996",
			"label": "-996"
	},
	{
			"value": "9.96 X1.96 X 0.59",
			"label": "9.96 X1.96 X 0.59"
	},
	{
			"value": "9.99",
			"label": "9.99"
	},
	{
			"value": "9.9IN",
			"label": "9.9IN"
	},
	{
			"value": ".99 OZ",
			"label": ".99 OZ"
	},
	{
			"value": "9.9 OZ",
			"label": "9.9 OZ"
	},
	{
			"value": "9FT",
			"label": "9FT"
	},
	{
			"value": "9   H X 8.75   W X",
			"label": "9   H X 8.75   W X"
	},
	{
			"value": "9 IN",
			"label": "9 IN"
	},
	{
			"value": "9IN",
			"label": "9IN"
	},
	{
			"value": "9 INCH",
			"label": "9 INCH"
	},
	{
			"value": "9 INCHES IN HEIGHT",
			"label": "9 INCHES IN HEIGHT"
	},
	{
			"value": "9 INCH X 12 INCH SHE",
			"label": "9 INCH X 12 INCH SHE"
	},
	{
			"value": "9IN H",
			"label": "9IN H"
	},
	{
			"value": "9INX1IN",
			"label": "9INX1IN"
	},
	{
			"value": "9 IN X 3 IN",
			"label": "9 IN X 3 IN"
	},
	{
			"value": "9INX4IN",
			"label": "9INX4IN"
	},
	{
			"value": "9 IN X 50 YD",
			"label": "9 IN X 50 YD"
	},
	{
			"value": "9IN X 5.1IN X 3IN",
			"label": "9IN X 5.1IN X 3IN"
	},
	{
			"value": "9 IN X 5.25 IN X 4.2",
			"label": "9 IN X 5.25 IN X 4.2"
	},
	{
			"value": "9 LED",
			"label": "9 LED"
	},
	{
			"value": "9MM",
			"label": "9MM"
	},
	{
			"value": "9 MONTHS",
			"label": "9 MONTHS"
	},
	{
			"value": "9 OZ",
			"label": "9 OZ"
	},
	{
			"value": "9OZ",
			"label": "9OZ"
	},
	{
			"value": "9 PAIR",
			"label": "9 PAIR"
	},
	{
			"value": "9PC",
			"label": "9PC"
	},
	{
			"value": "9 PK",
			"label": "9 PK"
	},
	{
			"value": "9PK",
			"label": "9PK"
	},
	{
			"value": "9   PLATE",
			"label": "9   PLATE"
	},
	{
			"value": "9 TEALIGHT",
			"label": "9 TEALIGHT"
	},
	{
			"value": "9X0.625X9",
			"label": "9X0.625X9"
	},
	{
			"value": "9 X 10.5",
			"label": "9 X 10.5"
	},
	{
			"value": "9 X 10 X 2.5",
			"label": "9 X 10 X 2.5"
	},
	{
			"value": "9 X 11",
			"label": "9 X 11"
	},
	{
			"value": "9   X 11",
			"label": "9   X 11"
	},
	{
			"value": "9   X 11   X .1",
			"label": "9   X 11   X .1"
	},
	{
			"value": "9   X 11   X .2",
			"label": "9   X 11   X .2"
	},
	{
			"value": "9   X 11   X 2",
			"label": "9   X 11   X 2"
	},
	{
			"value": "9   X 11   X .5",
			"label": "9   X 11   X .5"
	},
	{
			"value": "9 X 12",
			"label": "9 X 12"
	},
	{
			"value": "9 X12",
			"label": "9 X12"
	},
	{
			"value": "9   X  12",
			"label": "9   X  12"
	},
	{
			"value": "9   X 12",
			"label": "9   X 12"
	},
	{
			"value": "9  X 12",
			"label": "9  X 12"
	},
	{
			"value": "9  X12",
			"label": "9  X12"
	},
	{
			"value": "9X12",
			"label": "9X12"
	},
	{
			"value": "9 X 12 INCH",
			"label": "9 X 12 INCH"
	},
	{
			"value": "9X12 INCH",
			"label": "9X12 INCH"
	},
	{
			"value": "9X13",
			"label": "9X13"
	},
	{
			"value": "9   X 14",
			"label": "9   X 14"
	},
	{
			"value": "9X1.5",
			"label": "9X1.5"
	},
	{
			"value": "9X1.5X10.5",
			"label": "9X1.5X10.5"
	},
	{
			"value": "9 X 1.5 X 6.75",
			"label": "9 X 1.5 X 6.75"
	},
	{
			"value": "9   X 20",
			"label": "9   X 20"
	},
	{
			"value": "9X20",
			"label": "9X20"
	},
	{
			"value": "9 X 2.25 X .50",
			"label": "9 X 2.25 X .50"
	},
	{
			"value": "9X2.5",
			"label": "9X2.5"
	},
	{
			"value": "9 X 2.75",
			"label": "9 X 2.75"
	},
	{
			"value": "9 X2.75",
			"label": "9 X2.75"
	},
	{
			"value": "9   X 2.75",
			"label": "9   X 2.75"
	},
	{
			"value": "9X2.75",
			"label": "9X2.75"
	},
	{
			"value": "9X2X6",
			"label": "9X2X6"
	},
	{
			"value": "9 X 2 X 6.25",
			"label": "9 X 2 X 6.25"
	},
	{
			"value": "9 X 3",
			"label": "9 X 3"
	},
	{
			"value": "9X3",
			"label": "9X3"
	},
	{
			"value": "9   X 3.5   X 7.5",
			"label": "9   X 3.5   X 7.5"
	},
	{
			"value": "9X3X13",
			"label": "9X3X13"
	},
	{
			"value": "9 X 4 X 15",
			"label": "9 X 4 X 15"
	},
	{
			"value": "9X5",
			"label": "9X5"
	},
	{
			"value": "9   X 50 YARDS",
			"label": "9   X 50 YARDS"
	},
	{
			"value": "9 X 5.5 X 7.5",
			"label": "9 X 5.5 X 7.5"
	},
	{
			"value": "9 X 5.75 X 5.5",
			"label": "9 X 5.75 X 5.5"
	},
	{
			"value": "9X 6",
			"label": "9X 6"
	},
	{
			"value": "9X6",
			"label": "9X6"
	},
	{
			"value": "9X6.5",
			"label": "9X6.5"
	},
	{
			"value": "9X6.5X8.875",
			"label": "9X6.5X8.875"
	},
	{
			"value": "9X7.5",
			"label": "9X7.5"
	},
	{
			"value": "9X7X5",
			"label": "9X7X5"
	},
	{
			"value": "9X8",
			"label": "9X8"
	},
	{
			"value": "9X8.5X2 INCHES",
			"label": "9X8.5X2 INCHES"
	},
	{
			"value": "9X8X2.5",
			"label": "9X8X2.5"
	},
	{
			"value": "9 X 9",
			"label": "9 X 9"
	},
	{
			"value": "9   X 9",
			"label": "9   X 9"
	},
	{
			"value": "9X9",
			"label": "9X9"
	},
	{
			"value": "9X9.5",
			"label": "9X9.5"
	},
	{
			"value": "9X9 BLACK",
			"label": "9X9 BLACK"
	},
	{
			"value": "9   X 9   X .07",
			"label": "9   X 9   X .07"
	},
	{
			"value": "9 X 9 X 10",
			"label": "9 X 9 X 10"
	},
	{
			"value": "9 X 9 X 15",
			"label": "9 X 9 X 15"
	},
	{
			"value": "9 X 9 X 2",
			"label": "9 X 9 X 2"
	},
	{
			"value": "9   X 9   X 4.25",
			"label": "9   X 9   X 4.25"
	},
	{
			"value": "9X9X5",
			"label": "9X9X5"
	},
	{
			"value": "9X9X6",
			"label": "9X9X6"
	},
	{
			"value": "9X9X9",
			"label": "9X9X9"
	},
	{
			"value": "9 X X2.75",
			"label": "9 X X2.75"
	},
	{
			"value": "9YD",
			"label": "9YD"
	},
	{
			"value": "A",
			"label": "A"
	},
	{
			"value": "A00",
			"label": "A00"
	},
	{
			"value": "A00/A01",
			"label": "A00/A01"
	},
	{
			"value": "A00 - COASTAL",
			"label": "A00 - COASTAL"
	},
	{
			"value": "A01",
			"label": "A01"
	},
	{
			"value": "A02",
			"label": "A02"
	},
	{
			"value": "A03",
			"label": "A03"
	},
	{
			"value": "A04",
			"label": "A04"
	},
	{
			"value": "A05",
			"label": "A05"
	},
	{
			"value": "A06",
			"label": "A06"
	},
	{
			"value": "A07",
			"label": "A07"
	},
	{
			"value": "A08",
			"label": "A08"
	},
	{
			"value": "A09",
			"label": "A09"
	},
	{
			"value": "A (10-12-14-16-18)",
			"label": "A (10-12-14-16-18)"
	},
	{
			"value": "A (10-12-14-16-18-20",
			"label": "A (10-12-14-16-18-20"
	},
	{
			"value": "A (1/2-1-2-3-4)",
			"label": "A (1/2-1-2-3-4)"
	},
	{
			"value": "A (1/2-1-2-3-4-5-6-7",
			"label": "A (1/2-1-2-3-4-5-6-7"
	},
	{
			"value": "A19",
			"label": "A19"
	},
	{
			"value": "A (1X-5X)",
			"label": "A (1X-5X)"
	},
	{
			"value": "A (1XL-5XL)",
			"label": "A (1XL-5XL)"
	},
	{
			"value": "A2",
			"label": "A2"
	},
	{
			"value": "A (2-3-4-5-6)",
			"label": "A (2-3-4-5-6)"
	},
	{
			"value": "A (2-3-4-5-6-6X)",
			"label": "A (2-3-4-5-6-6X)"
	},
	{
			"value": "A (30A-44G)",
			"label": "A (30A-44G)"
	},
	{
			"value": "A (3-4-5-6-7-8)",
			"label": "A (3-4-5-6-7-8)"
	},
	{
			"value": "A (3-4-5-6-7-8-10-12",
			"label": "A (3-4-5-6-7-8-10-12"
	},
	{
			"value": "A (3-4-5-6-7-8-9-10-",
			"label": "A (3-4-5-6-7-8-9-10-"
	},
	{
			"value": "A (3-8 / 6-16)",
			"label": "A (3-8 / 6-16)"
	},
	{
			"value": "A (3-8 / S-XL)",
			"label": "A (3-8 / S-XL)"
	},
	{
			"value": "A (3 - 8 /XS-XL)",
			"label": "A (3 - 8 /XS-XL)"
	},
	{
			"value": "A (4-6-8-10-12-14-16",
			"label": "A (4-6-8-10-12-14-16"
	},
	{
			"value": "A5(6-8-10-12-14)",
			"label": "A5(6-8-10-12-14)"
	},
	{
			"value": "A5-M",
			"label": "A5-M"
	},
	{
			"value": "A (6-8-10)",
			"label": "A (6-8-10)"
	},
	{
			"value": "A (6-8-10-12-14-16)",
			"label": "A (6-8-10-12-14-16)"
	},
	{
			"value": "A (6-8-10-12-14-16-1",
			"label": "A (6-8-10-12-14-16-1"
	},
	{
			"value": "A (7-8-10-12-14)",
			"label": "A (7-8-10-12-14)"
	},
	{
			"value": "A (7-8-10-12-14-16)",
			"label": "A (7-8-10-12-14-16)"
	},
	{
			"value": "A (7-8-9-10-12-14)",
			"label": "A (7-8-9-10-12-14)"
	},
	{
			"value": "A (8-10-12-14-16)",
			"label": "A (8-10-12-14-16)"
	},
	{
			"value": "A (8-10-12-14-16-18)",
			"label": "A (8-10-12-14-16-18)"
	},
	{
			"value": "A (8-10-12-14-16-18-",
			"label": "A (8-10-12-14-16-18-"
	},
	{
			"value": "AA",
			"label": "AA"
	},
	{
			"value": "AA (10-12-14-16-18)",
			"label": "AA (10-12-14-16-18)"
	},
	{
			"value": "AA (1/2-1-2)",
			"label": "AA (1/2-1-2)"
	},
	{
			"value": "AA (1/2-1-2-3)",
			"label": "AA (1/2-1-2-3)"
	},
	{
			"value": "AA (34-36-38-40-42)",
			"label": "AA (34-36-38-40-42)"
	},
	{
			"value": "AA (3-4-5-6-7-8)",
			"label": "AA (3-4-5-6-7-8)"
	},
	{
			"value": "AA (38-40-42-44)",
			"label": "AA (38-40-42-44)"
	},
	{
			"value": "AA (38-40-42-44-46)",
			"label": "AA (38-40-42-44-46)"
	},
	{
			"value": "AA (6-8-10-12)",
			"label": "AA (6-8-10-12)"
	},
	{
			"value": "AA(6-8-10-12)",
			"label": "AA(6-8-10-12)"
	},
	{
			"value": "AA (8-10-12-14-16)",
			"label": "AA (8-10-12-14-16)"
	},
	{
			"value": "AAA",
			"label": "AAA"
	},
	{
			"value": "A(A-B-C-D-E-F-G-H-I-",
			"label": "A(A-B-C-D-E-F-G-H-I-"
	},
	{
			"value": "A (ALL SIZES)",
			"label": "A (ALL SIZES)"
	},
	{
			"value": "A (ALL SIZES IN ONE)",
			"label": "A (ALL SIZES IN ONE)"
	},
	{
			"value": "A(ALL SIZES IN ONE)",
			"label": "A(ALL SIZES IN ONE)"
	},
	{
			"value": "AA (S-M-L)",
			"label": "AA (S-M-L)"
	},
	{
			"value": "AA (S-M-L-XL)",
			"label": "AA (S-M-L-XL)"
	},
	{
			"value": "AAX(4-6-8-10)",
			"label": "AAX(4-6-8-10)"
	},
	{
			"value": "AA (XS-S-M)",
			"label": "AA (XS-S-M)"
	},
	{
			"value": "AA (XS-S-M-L-XL)",
			"label": "AA (XS-S-M-L-XL)"
	},
	{
			"value": "AA (XXS-XS-S-M)",
			"label": "AA (XXS-XS-S-M)"
	},
	{
			"value": "ACCESSORIES",
			"label": "ACCESSORIES"
	},
	{
			"value": "ADULT",
			"label": "ADULT"
	},
	{
			"value": "AFTERNOON",
			"label": "AFTERNOON"
	},
	{
			"value": "A (NB-S-M-L)",
			"label": "A (NB-S-M-L)"
	},
	{
			"value": "APR",
			"label": "APR"
	},
	{
			"value": "APR-00",
			"label": "APR-00"
	},
	{
			"value": "APRIL",
			"label": "APRIL"
	},
	{
			"value": "ARROW",
			"label": "ARROW"
	},
	{
			"value": "A (S - L / 1XL - 5XL",
			"label": "A (S - L / 1XL - 5XL"
	},
	{
			"value": "A (S - L / S - L)",
			"label": "A (S - L / S - L)"
	},
	{
			"value": "A (S - L / S - XL)",
			"label": "A (S - L / S - XL)"
	},
	{
			"value": "A (S - L / XS - XL)",
			"label": "A (S - L / XS - XL)"
	},
	{
			"value": "A (S-M-L)",
			"label": "A (S-M-L)"
	},
	{
			"value": "A (S-M-L/S-M-L)",
			"label": "A (S-M-L/S-M-L)"
	},
	{
			"value": "A (S-M-L/S-M-L-XL)",
			"label": "A (S-M-L/S-M-L-XL)"
	},
	{
			"value": "A (S-M-L-XL)",
			"label": "A (S-M-L-XL)"
	},
	{
			"value": "A (S-M-L-XL-XXL)",
			"label": "A (S-M-L-XL-XXL)"
	},
	{
			"value": "A (S-M-L-XL-XXL-XXXL",
			"label": "A (S-M-L-XL-XXL-XXXL"
	},
	{
			"value": "A(S-M-L-XL-XXL-XXXL)",
			"label": "A(S-M-L-XL-XXL-XXXL)"
	},
	{
			"value": "ASRT",
			"label": "ASRT"
	},
	{
			"value": "ASSORT",
			"label": "ASSORT"
	},
	{
			"value": "ASSORTED",
			"label": "ASSORTED"
	},
	{
			"value": "ASST",
			"label": "ASST"
	},
	{
			"value": "ASST WIDTHS & LENGTH",
			"label": "ASST WIDTHS & LENGTH"
	},
	{
			"value": "ASTD",
			"label": "ASTD"
	},
	{
			"value": "A (S - XL / S - XL)",
			"label": "A (S - XL / S - XL)"
	},
	{
			"value": "AUG",
			"label": "AUG"
	},
	{
			"value": "AUG-00",
			"label": "AUG-00"
	},
	{
			"value": "AUTMND00",
			"label": "AUTMND00"
	},
	{
			"value": "AX5(4-6-8-10-12)",
			"label": "AX5(4-6-8-10-12)"
	},
	{
			"value": "A (XS-L / S-XL)",
			"label": "A (XS-L / S-XL)"
	},
	{
			"value": "A (XS - L / XS - XL)",
			"label": "A (XS - L / XS - XL)"
	},
	{
			"value": "A (XS-L/XS-XL)",
			"label": "A (XS-L/XS-XL)"
	},
	{
			"value": "A (XS-S-M-L)",
			"label": "A (XS-S-M-L)"
	},
	{
			"value": "A (XS-S-M-L-XL)",
			"label": "A (XS-S-M-L-XL)"
	},
	{
			"value": "A (XS-S-M-LXL)",
			"label": "A (XS-S-M-LXL)"
	},
	{
			"value": "A (XS-S-M-L-XL-XXL)",
			"label": "A (XS-S-M-L-XL-XXL)"
	},
	{
			"value": "A (XS-S-M-L / XS-S-M",
			"label": "A (XS-S-M-L / XS-S-M"
	},
	{
			"value": "A (XS - XL / S - XL)",
			"label": "A (XS - XL / S - XL)"
	},
	{
			"value": "A (XS - XL / XS - XL",
			"label": "A (XS - XL / XS - XL"
	},
	{
			"value": "A (XXS-XS-S)",
			"label": "A (XXS-XS-S)"
	},
	{
			"value": "A (XXS-XS-S-M)",
			"label": "A (XXS-XS-S-M)"
	},
	{
			"value": "A (XXS-XS-S-M-L)",
			"label": "A (XXS-XS-S-M-L)"
	},
	{
			"value": "A (XXS-XS-S-M-L-XL)",
			"label": "A (XXS-XS-S-M-L-XL)"
	},
	{
			"value": "A (XXS-XS-S-M-L-XL-X",
			"label": "A (XXS-XS-S-M-L-XL-X"
	},
	{
			"value": "B00",
			"label": "B00"
	},
	{
			"value": "B00/B01",
			"label": "B00/B01"
	},
	{
			"value": "B00 LAST YEAR",
			"label": "B00 LAST YEAR"
	},
	{
			"value": "B01",
			"label": "B01"
	},
	{
			"value": "B02",
			"label": "B02"
	},
	{
			"value": "B03",
			"label": "B03"
	},
	{
			"value": "B04",
			"label": "B04"
	},
	{
			"value": "B05",
			"label": "B05"
	},
	{
			"value": "B06",
			"label": "B06"
	},
	{
			"value": "B10",
			"label": "B10"
	},
	{
			"value": "B13",
			"label": "B13"
	},
	{
			"value": "B15",
			"label": "B15"
	},
	{
			"value": "B1G3 BLK FRI",
			"label": "B1G3 BLK FRI"
	},
	{
			"value": "B5(8-10-12-14-16)",
			"label": "B5(8-10-12-14-16)"
	},
	{
			"value": "BAG",
			"label": "BAG"
	},
	{
			"value": "BAGS",
			"label": "BAGS"
	},
	{
			"value": "BAG TAGS",
			"label": "BAG TAGS"
	},
	{
			"value": "BALLS",
			"label": "BALLS"
	},
	{
			"value": "BASE DECK",
			"label": "BASE DECK"
	},
	{
			"value": "BASEDECK",
			"label": "BASEDECK"
	},
	{
			"value": "BASIC",
			"label": "BASIC"
	},
	{
			"value": "BASICS SET",
			"label": "BASICS SET"
	},
	{
			"value": "BASICS TUBE 24CT SET",
			"label": "BASICS TUBE 24CT SET"
	},
	{
			"value": "BASIC TREES",
			"label": "BASIC TREES"
	},
	{
			"value": "BASIC WD",
			"label": "BASIC WD"
	},
	{
			"value": "BASKET",
			"label": "BASKET"
	},
	{
			"value": "BASKETS",
			"label": "BASKETS"
	},
	{
			"value": "BASKETS & CRATES",
			"label": "BASKETS & CRATES"
	},
	{
			"value": "BASKETS/CRATES",
			"label": "BASKETS/CRATES"
	},
	{
			"value": "BASKETS & LINERS",
			"label": "BASKETS & LINERS"
	},
	{
			"value": "BASKETWEAVE",
			"label": "BASKETWEAVE"
	},
	{
			"value": "BATH",
			"label": "BATH"
	},
	{
			"value": "BB (18W-26W)",
			"label": "BB (18W-26W)"
	},
	{
			"value": "BB (1X-5X)",
			"label": "BB (1X-5X)"
	},
	{
			"value": "BB (20W-22W-24W-26W-",
			"label": "BB (20W-22W-24W-26W-"
	},
	{
			"value": "BB (20W-28W)",
			"label": "BB (20W-28W)"
	},
	{
			"value": "BB (2-3-4)",
			"label": "BB (2-3-4)"
	},
	{
			"value": "BB (3-4-5-6)",
			"label": "BB (3-4-5-6)"
	},
	{
			"value": "BB (44-46-48-50-52)",
			"label": "BB (44-46-48-50-52)"
	},
	{
			"value": "BB (4-5-6-7-8)",
			"label": "BB (4-5-6-7-8)"
	},
	{
			"value": "BB (46-48-50-52)",
			"label": "BB (46-48-50-52)"
	},
	{
			"value": "BB (48-50-52-54-56)",
			"label": "BB (48-50-52-54-56)"
	},
	{
			"value": "BB(8-10-12-14)",
			"label": "BB(8-10-12-14)"
	},
	{
			"value": "BB (8 1/2 - 16 1/2)",
			"label": "BB (8 1/2 - 16 1/2)"
	},
	{
			"value": "BB (L-XL)",
			"label": "BB (L-XL)"
	},
	{
			"value": "BB (L-XL-XXL)",
			"label": "BB (L-XL-XXL)"
	},
	{
			"value": "BB (S-M-L-XL)",
			"label": "BB (S-M-L-XL)"
	},
	{
			"value": "BB (XL-XXL-XXXL)",
			"label": "BB (XL-XXL-XXXL)"
	},
	{
			"value": "BB (XS-S-M-L-XL)",
			"label": "BB (XS-S-M-L-XL)"
	},
	{
			"value": "BCA",
			"label": "BCA"
	},
	{
			"value": "BEADED",
			"label": "BEADED"
	},
	{
			"value": "BEADS",
			"label": "BEADS"
	},
	{
			"value": "BEDDING",
			"label": "BEDDING"
	},
	{
			"value": "BIG",
			"label": "BIG"
	},
	{
			"value": "BIRDHOUSES & FEEDERS",
			"label": "BIRDHOUSES & FEEDERS"
	},
	{
			"value": "BISCUIT",
			"label": "BISCUIT"
	},
	{
			"value": "BIZZO BEAD",
			"label": "BIZZO BEAD"
	},
	{
			"value": "BLACK",
			"label": "BLACK"
	},
	{
			"value": "BLACK FRIDAY",
			"label": "BLACK FRIDAY"
	},
	{
			"value": "BLDG. BLOCKS",
			"label": "BLDG. BLOCKS"
	},
	{
			"value": "BLUE",
			"label": "BLUE"
	},
	{
			"value": "BLUE DK",
			"label": "BLUE DK"
	},
	{
			"value": "BN",
			"label": "BN"
	},
	{
			"value": "BOLD",
			"label": "BOLD"
	},
	{
			"value": "BOOK",
			"label": "BOOK"
	},
	{
			"value": "BOOK BIN",
			"label": "BOOK BIN"
	},
	{
			"value": "BOW",
			"label": "BOW"
	},
	{
			"value": "BOW PACK",
			"label": "BOW PACK"
	},
	{
			"value": "BOX",
			"label": "BOX"
	},
	{
			"value": "BOXED JINGLE BELLS",
			"label": "BOXED JINGLE BELLS"
	},
	{
			"value": "BOX SET 1",
			"label": "BOX SET 1"
	},
	{
			"value": "BOYS",
			"label": "BOYS"
	},
	{
			"value": "BRANSON",
			"label": "BRANSON"
	},
	{
			"value": "BROCADE",
			"label": "BROCADE"
	},
	{
			"value": "BROWN SUGAR",
			"label": "BROWN SUGAR"
	},
	{
			"value": "BULK TISSUE PAPER",
			"label": "BULK TISSUE PAPER"
	},
	{
			"value": "BUNNY",
			"label": "BUNNY"
	},
	{
			"value": "BURNOUT",
			"label": "BURNOUT"
	},
	{
			"value": "BUSINESS CARD",
			"label": "BUSINESS CARD"
	},
	{
			"value": "C00",
			"label": "C00"
	},
	{
			"value": "C00/01",
			"label": "C00/01"
	},
	{
			"value": "C01",
			"label": "C01"
	},
	{
			"value": "C02",
			"label": "C02"
	},
	{
			"value": "C03",
			"label": "C03"
	},
	{
			"value": "C06",
			"label": "C06"
	},
	{
			"value": "C(10-12-14)",
			"label": "C(10-12-14)"
	},
	{
			"value": "C5(10-12-14-16-18)",
			"label": "C5(10-12-14-16-18)"
	},
	{
			"value": "CAA(1/2-1-2-3-4)",
			"label": "CAA(1/2-1-2-3-4)"
	},
	{
			"value": "CADDY",
			"label": "CADDY"
	},
	{
			"value": "CAKE",
			"label": "CAKE"
	},
	{
			"value": "CANDY",
			"label": "CANDY"
	},
	{
			"value": "CANDY SHAPE",
			"label": "CANDY SHAPE"
	},
	{
			"value": "CARDED",
			"label": "CARDED"
	},
	{
			"value": "CARDS",
			"label": "CARDS"
	},
	{
			"value": "CARROT",
			"label": "CARROT"
	},
	{
			"value": "CASE",
			"label": "CASE"
	},
	{
			"value": "CB(1-2-3)",
			"label": "CB(1-2-3)"
	},
	{
			"value": "CC(2-3-4)",
			"label": "CC(2-3-4)"
	},
	{
			"value": "CCB(1-2-3-4)",
			"label": "CCB(1-2-3-4)"
	},
	{
			"value": "CCE(3-4-5-6)",
			"label": "CCE(3-4-5-6)"
	},
	{
			"value": "CDD(2-3-4-5)",
			"label": "CDD(2-3-4-5)"
	},
	{
			"value": "CDEF",
			"label": "CDEF"
	},
	{
			"value": "CE(3-4-5)",
			"label": "CE(3-4-5)"
	},
	{
			"value": "CELLOPHANE",
			"label": "CELLOPHANE"
	},
	{
			"value": "CF(4-5-6)",
			"label": "CF(4-5-6)"
	},
	{
			"value": "CH(7-8-10)",
			"label": "CH(7-8-10)"
	},
	{
			"value": "CHH(7-8-10-12)",
			"label": "CHH(7-8-10-12)"
	},
	{
			"value": "CHICK",
			"label": "CHICK"
	},
	{
			"value": "CHILD",
			"label": "CHILD"
	},
	{
			"value": "CHISEL",
			"label": "CHISEL"
	},
	{
			"value": "CHJ(7-8-10-12-14)",
			"label": "CHJ(7-8-10-12-14)"
	},
	{
			"value": "CHJ(7-8-9-10-12-14)",
			"label": "CHJ(7-8-9-10-12-14)"
	},
	{
			"value": "CHOCOLATE",
			"label": "CHOCOLATE"
	},
	{
			"value": "CJ(10-12-14)",
			"label": "CJ(10-12-14)"
	},
	{
			"value": "CL (6-7-8)",
			"label": "CL (6-7-8)"
	},
	{
			"value": "CL(6-7-8)",
			"label": "CL(6-7-8)"
	},
	{
			"value": "CLASSIC",
			"label": "CLASSIC"
	},
	{
			"value": "CLIP",
			"label": "CLIP"
	},
	{
			"value": "COASTAL - A",
			"label": "COASTAL - A"
	},
	{
			"value": "COASTAL - C",
			"label": "COASTAL - C"
	},
	{
			"value": "COIR MAT",
			"label": "COIR MAT"
	},
	{
			"value": "COIR MATS",
			"label": "COIR MATS"
	},
	{
			"value": "COLORLINE 19X25",
			"label": "COLORLINE 19X25"
	},
	{
			"value": ".COM ALSO",
			"label": ".COM ALSO"
	},
	{
			"value": "COMBINATION",
			"label": "COMBINATION"
	},
	{
			"value": "COMFORT GRIP",
			"label": "COMFORT GRIP"
	},
	{
			"value": "COMMODITY 9X12",
			"label": "COMMODITY 9X12"
	},
	{
			"value": "CONFETTI",
			"label": "CONFETTI"
	},
	{
			"value": "CONTAINERS",
			"label": "CONTAINERS"
	},
	{
			"value": "CONTAINS 6 PDQ TRAYS",
			"label": "CONTAINS 6 PDQ TRAYS"
	},
	{
			"value": "COO",
			"label": "COO"
	},
	{
			"value": "COOLING GRID",
			"label": "COOLING GRID"
	},
	{
			"value": "CORAL",
			"label": "CORAL"
	},
	{
			"value": "CORD",
			"label": "CORD"
	},
	{
			"value": "CORDED",
			"label": "CORDED"
	},
	{
			"value": "CORDING",
			"label": "CORDING"
	},
	{
			"value": "COTTON 2.5\"\"",
			"label": "COTTON 2.5\"\""
	},
	{
			"value": "CRATES",
			"label": "CRATES"
	},
	{
			"value": "CREAM",
			"label": "CREAM"
	},
	{
			"value": "CRIB",
			"label": "CRIB"
	},
	{
			"value": "CRUNCH",
			"label": "CRUNCH"
	},
	{
			"value": "CRUSHED",
			"label": "CRUSHED"
	},
	{
			"value": "CS(12-14-16)",
			"label": "CS(12-14-16)"
	},
	{
			"value": "CUPCAKE",
			"label": "CUPCAKE"
	},
	{
			"value": "CURATION 2",
			"label": "CURATION 2"
	},
	{
			"value": "CURTAINS RUGS",
			"label": "CURTAINS RUGS"
	},
	{
			"value": "CUSHIONS",
			"label": "CUSHIONS"
	},
	{
			"value": "CUTTER",
			"label": "CUTTER"
	},
	{
			"value": "CX(XSM-SML)",
			"label": "CX(XSM-SML)"
	},
	{
			"value": "CZ(MED-LRG-XLG)",
			"label": "CZ(MED-LRG-XLG)"
	},
	{
			"value": "D",
			"label": "D"
	},
	{
			"value": "D00",
			"label": "D00"
	},
	{
			"value": "D00/D01/D02",
			"label": "D00/D01/D02"
	},
	{
			"value": "D01",
			"label": "D01"
	},
	{
			"value": "D (12-14-16)",
			"label": "D (12-14-16)"
	},
	{
			"value": "D14.96   X  H7.48/ D",
			"label": "D14.96   X  H7.48/ D"
	},
	{
			"value": "D5(12-14-16-18-20)",
			"label": "D5(12-14-16-18-20)"
	},
	{
			"value": "D5 (4-6-8-10-12)",
			"label": "D5 (4-6-8-10-12)"
	},
	{
			"value": "DD(12-14-16-18)",
			"label": "DD(12-14-16-18)"
	},
	{
			"value": "DD (4-6-8-10)",
			"label": "DD (4-6-8-10)"
	},
	{
			"value": "DEC",
			"label": "DEC"
	},
	{
			"value": "DEC-00",
			"label": "DEC-00"
	},
	{
			"value": "DEC BOXES",
			"label": "DEC BOXES"
	},
	{
			"value": "DECO 10",
			"label": "DECO 10"
	},
	{
			"value": "DECO 21",
			"label": "DECO 21"
	},
	{
			"value": "DECO 5.5",
			"label": "DECO 5.5"
	},
	{
			"value": "DECOR",
			"label": "DECOR"
	},
	{
			"value": "DECORATING",
			"label": "DECORATING"
	},
	{
			"value": "DIA-10  XHT-12",
			"label": "DIA-10  XHT-12"
	},
	{
			"value": "DIA-9  XHT-6",
			"label": "DIA-9  XHT-6"
	},
	{
			"value": "DIAMOND",
			"label": "DIAMOND"
	},
	{
			"value": "DISPLAY",
			"label": "DISPLAY"
	},
	{
			"value": "DISTRESSED",
			"label": "DISTRESSED"
	},
	{
			"value": "DOG",
			"label": "DOG"
	},
	{
			"value": "DOLL CLOTHES",
			"label": "DOLL CLOTHES"
	},
	{
			"value": "DOT",
			"label": "DOT"
	},
	{
			"value": "DOT.COM",
			"label": "DOT.COM"
	},
	{
			"value": "DOT MATRIX",
			"label": "DOT MATRIX"
	},
	{
			"value": "DOUBLE BRUSHED",
			"label": "DOUBLE BRUSHED"
	},
	{
			"value": "DOUBLE SANDED",
			"label": "DOUBLE SANDED"
	},
	{
			"value": "DRESS",
			"label": "DRESS"
	},
	{
			"value": "DUAL LOC EC7",
			"label": "DUAL LOC EC7"
	},
	{
			"value": "DUCCO",
			"label": "DUCCO"
	},
	{
			"value": "E00",
			"label": "E00"
	},
	{
			"value": "E01",
			"label": "E01"
	},
	{
			"value": "E5(14-16-18-20-22)",
			"label": "E5(14-16-18-20-22)"
	},
	{
			"value": "EA",
			"label": "EA"
	},
	{
			"value": "EA.",
			"label": "EA."
	},
	{
			"value": "EACH",
			"label": "EACH"
	},
	{
			"value": "EACH 1 X 1 X 0.5 INC",
			"label": "EACH 1 X 1 X 0.5 INC"
	},
	{
			"value": "EACHES",
			"label": "EACHES"
	},
	{
			"value": "EACHS",
			"label": "EACHS"
	},
	{
			"value": "EACH SKEIN IS 3.5 O",
			"label": "EACH SKEIN IS 3.5 O"
	},
	{
			"value": "EACH SKEIN WEIGHS 1.",
			"label": "EACH SKEIN WEIGHS 1."
	},
	{
			"value": "EACH SKEIN WEIGHS 3",
			"label": "EACH SKEIN WEIGHS 3"
	},
	{
			"value": "EACH SKEIN WEIGHS 3.",
			"label": "EACH SKEIN WEIGHS 3."
	},
	{
			"value": "EACH SKEIN WEIGHS 5",
			"label": "EACH SKEIN WEIGHS 5"
	},
	{
			"value": "EACH SKEIN WEIGHS 5.",
			"label": "EACH SKEIN WEIGHS 5."
	},
	{
			"value": "EACH SKEIN WEIGHS 8.",
			"label": "EACH SKEIN WEIGHS 8."
	},
	{
			"value": "EARLY GARLANDS",
			"label": "EARLY GARLANDS"
	},
	{
			"value": "EARLY LIGHTING",
			"label": "EARLY LIGHTING"
	},
	{
			"value": "EASTER BUNNIES",
			"label": "EASTER BUNNIES"
	},
	{
			"value": "EASTER CURATED",
			"label": "EASTER CURATED"
	},
	{
			"value": "EASTER ENTERTAINING",
			"label": "EASTER ENTERTAINING"
	},
	{
			"value": "EC800607",
			"label": "EC800607"
	},
	{
			"value": "EC AMERICANA",
			"label": "EC AMERICANA"
	},
	{
			"value": "EC BBQ",
			"label": "EC BBQ"
	},
	{
			"value": "EC BRIGHTS",
			"label": "EC BRIGHTS"
	},
	{
			"value": "EC CRACKERS",
			"label": "EC CRACKERS"
	},
	{
			"value": "EC CRAFT YOUR GIFT",
			"label": "EC CRAFT YOUR GIFT"
	},
	{
			"value": "EC DAISY",
			"label": "EC DAISY"
	},
	{
			"value": "EC EASTER ORN TREE",
			"label": "EC EASTER ORN TREE"
	},
	{
			"value": "EC EASTER OUTDOOR",
			"label": "EC EASTER OUTDOOR"
	},
	{
			"value": "EC EASTER PET",
			"label": "EC EASTER PET"
	},
	{
			"value": "EC ENTERTAINING",
			"label": "EC ENTERTAINING"
	},
	{
			"value": "EC FIGURAL LIGHTING",
			"label": "EC FIGURAL LIGHTING"
	},
	{
			"value": "EC GLASS ORNAMENTS",
			"label": "EC GLASS ORNAMENTS"
	},
	{
			"value": "EC GRADUATION",
			"label": "EC GRADUATION"
	},
	{
			"value": "EC GROW KITS",
			"label": "EC GROW KITS"
	},
	{
			"value": "EC HANDMADE FELT",
			"label": "EC HANDMADE FELT"
	},
	{
			"value": "EC HANNUKAH",
			"label": "EC HANNUKAH"
	},
	{
			"value": "EC JINGLE BELLS",
			"label": "EC JINGLE BELLS"
	},
	{
			"value": "EC KNIT STOCKING",
			"label": "EC KNIT STOCKING"
	},
	{
			"value": "EC KRAFT BAGS",
			"label": "EC KRAFT BAGS"
	},
	{
			"value": "EC LOF",
			"label": "EC LOF"
	},
	{
			"value": "EC LOF FLAGS",
			"label": "EC LOF FLAGS"
	},
	{
			"value": "EC MINI YARD STAKES",
			"label": "EC MINI YARD STAKES"
	},
	{
			"value": "EC MUGS & TOWELS",
			"label": "EC MUGS & TOWELS"
	},
	{
			"value": "EC MUSHROOM",
			"label": "EC MUSHROOM"
	},
	{
			"value": "ECOM",
			"label": "ECOM"
	},
	{
			"value": "EC OUTDOOR GAMES",
			"label": "EC OUTDOOR GAMES"
	},
	{
			"value": "EC OUTDOOR LIGHTING",
			"label": "EC OUTDOOR LIGHTING"
	},
	{
			"value": "EC PAPER",
			"label": "EC PAPER"
	},
	{
			"value": "EC PET",
			"label": "EC PET"
	},
	{
			"value": "EC PICNIC BLANKETS",
			"label": "EC PICNIC BLANKETS"
	},
	{
			"value": "EC RELIGIOUS",
			"label": "EC RELIGIOUS"
	},
	{
			"value": "EC RELIGON",
			"label": "EC RELIGON"
	},
	{
			"value": "EC SOLAR LIGHTS",
			"label": "EC SOLAR LIGHTS"
	},
	{
			"value": "EC SPRAYERS PLANTERS",
			"label": "EC SPRAYERS PLANTERS"
	},
	{
			"value": "EC STATE",
			"label": "EC STATE"
	},
	{
			"value": "EC ST PATS",
			"label": "EC ST PATS"
	},
	{
			"value": "EC ST PATS OUTDOOR",
			"label": "EC ST PATS OUTDOOR"
	},
	{
			"value": "EC STRAWBERRY",
			"label": "EC STRAWBERRY"
	},
	{
			"value": "EC SUN SHADES",
			"label": "EC SUN SHADES"
	},
	{
			"value": "EC TIKI",
			"label": "EC TIKI"
	},
	{
			"value": "EC VAL DÉCOR",
			"label": "EC VAL DÉCOR"
	},
	{
			"value": "EC VAL OUTDOOR",
			"label": "EC VAL OUTDOOR"
	},
	{
			"value": "EC VAL TINSEL",
			"label": "EC VAL TINSEL"
	},
	{
			"value": "EE (14-16-18-20)",
			"label": "EE (14-16-18-20)"
	},
	{
			"value": "EE(14-16-18-20)",
			"label": "EE(14-16-18-20)"
	},
	{
			"value": "EGG",
			"label": "EGG"
	},
	{
			"value": "EMBOSSED",
			"label": "EMBOSSED"
	},
	{
			"value": "EMBROIDERY",
			"label": "EMBROIDERY"
	},
	{
			"value": "ENDCAP",
			"label": "ENDCAP"
	},
	{
			"value": "ENTERTAINING",
			"label": "ENTERTAINING"
	},
	{
			"value": "ENTERTIANING",
			"label": "ENTERTIANING"
	},
	{
			"value": "EXCLUDE",
			"label": "EXCLUDE"
	},
	{
			"value": "EXT QUE PROMO WRAP",
			"label": "EXT QUE PROMO WRAP"
	},
	{
			"value": "EXTRA FINE",
			"label": "EXTRA FINE"
	},
	{
			"value": "EXTRA LARGE",
			"label": "EXTRA LARGE"
	},
	{
			"value": "F00",
			"label": "F00"
	},
	{
			"value": "F01",
			"label": "F01"
	},
	{
			"value": "F(16-18-20)",
			"label": "F(16-18-20)"
	},
	{
			"value": "F 2ND CURATION",
			"label": "F 2ND CURATION"
	},
	{
			"value": "F5(16-18-20-22-24)",
			"label": "F5(16-18-20-22-24)"
	},
	{
			"value": "F5 (18W-20W-22W-24W-",
			"label": "F5 (18W-20W-22W-24W-"
	},
	{
			"value": "FABRIC BUNDLE",
			"label": "FABRIC BUNDLE"
	},
	{
			"value": "FABRIC CHARM",
			"label": "FABRIC CHARM"
	},
	{
			"value": "FABRIC ROLL",
			"label": "FABRIC ROLL"
	},
	{
			"value": "FAN BOOK",
			"label": "FAN BOOK"
	},
	{
			"value": "FANCIES",
			"label": "FANCIES"
	},
	{
			"value": "FASHION TREES",
			"label": "FASHION TREES"
	},
	{
			"value": "F DEC BOXES",
			"label": "F DEC BOXES"
	},
	{
			"value": "FEB",
			"label": "FEB"
	},
	{
			"value": "FEB-00",
			"label": "FEB-00"
	},
	{
			"value": "F EC APPLE PICKING",
			"label": "F EC APPLE PICKING"
	},
	{
			"value": "F EC GLAM PUMPKINS",
			"label": "F EC GLAM PUMPKINS"
	},
	{
			"value": "F EC SUNFLOWER",
			"label": "F EC SUNFLOWER"
	},
	{
			"value": "F EC THANKSGIVING",
			"label": "F EC THANKSGIVING"
	},
	{
			"value": "F ENTERTAINING",
			"label": "F ENTERTAINING"
	},
	{
			"value": "FF(16-18-20-22)",
			"label": "FF(16-18-20-22)"
	},
	{
			"value": "FF (18W-20W-22W-24W)",
			"label": "FF (18W-20W-22W-24W)"
	},
	{
			"value": "FF 2",
			"label": "FF 2"
	},
	{
			"value": "FF2",
			"label": "FF2"
	},
	{
			"value": "FF 3",
			"label": "FF 3"
	},
	{
			"value": "FF BAMBOO ENT",
			"label": "FF BAMBOO ENT"
	},
	{
			"value": "FF BEVERAGE STAKES",
			"label": "FF BEVERAGE STAKES"
	},
	{
			"value": "FF BEV STAKES",
			"label": "FF BEV STAKES"
	},
	{
			"value": "FF BUNNIES 1",
			"label": "FF BUNNIES 1"
	},
	{
			"value": "FF BUNNIES 2",
			"label": "FF BUNNIES 2"
	},
	{
			"value": "FF CITRO CANDLES",
			"label": "FF CITRO CANDLES"
	},
	{
			"value": "FF DYE KITS",
			"label": "FF DYE KITS"
	},
	{
			"value": "F FF BAGGED PUMPKINS",
			"label": "F FF BAGGED PUMPKINS"
	},
	{
			"value": "F FF BRAIDED PUMPKIN",
			"label": "F FF BRAIDED PUMPKIN"
	},
	{
			"value": "F FF CERAMIC PUMPKIN",
			"label": "F FF CERAMIC PUMPKIN"
	},
	{
			"value": "F FF CROCHET PUMPKIN",
			"label": "F FF CROCHET PUMPKIN"
	},
	{
			"value": "F FF GLASS PUMPKINS",
			"label": "F FF GLASS PUMPKINS"
	},
	{
			"value": "FF FIGURAL TT",
			"label": "FF FIGURAL TT"
	},
	{
			"value": "FF FLAGS",
			"label": "FF FLAGS"
	},
	{
			"value": "F FF LANTERNS",
			"label": "F FF LANTERNS"
	},
	{
			"value": "F FF LUMBAR PILLOWS",
			"label": "F FF LUMBAR PILLOWS"
	},
	{
			"value": "F FF MELAMINE",
			"label": "F FF MELAMINE"
	},
	{
			"value": "F FF MUGS",
			"label": "F FF MUGS"
	},
	{
			"value": "F FF PAPER ENTERTAIN",
			"label": "F FF PAPER ENTERTAIN"
	},
	{
			"value": "F FF THROWS",
			"label": "F FF THROWS"
	},
	{
			"value": "F FF TOWELS",
			"label": "F FF TOWELS"
	},
	{
			"value": "F FF WOOD ENTERTAIN",
			"label": "F FF WOOD ENTERTAIN"
	},
	{
			"value": "FF GAMES",
			"label": "FF GAMES"
	},
	{
			"value": "FF GLASS EGGS",
			"label": "FF GLASS EGGS"
	},
	{
			"value": "FF GROW KITS",
			"label": "FF GROW KITS"
	},
	{
			"value": "FF HH GIFTBAGS",
			"label": "FF HH GIFTBAGS"
	},
	{
			"value": "FF HH MUGS",
			"label": "FF HH MUGS"
	},
	{
			"value": "FF HH STOCKINGS",
			"label": "FF HH STOCKINGS"
	},
	{
			"value": "FF HH TABLETOP TREES",
			"label": "FF HH TABLETOP TREES"
	},
	{
			"value": "FF HH TINS",
			"label": "FF HH TINS"
	},
	{
			"value": "FF HH WRAP ACCS",
			"label": "FF HH WRAP ACCS"
	},
	{
			"value": "FF KITCHEN TEXTILES",
			"label": "FF KITCHEN TEXTILES"
	},
	{
			"value": "FF LANTERNS",
			"label": "FF LANTERNS"
	},
	{
			"value": "FF LANTERNS METAL",
			"label": "FF LANTERNS METAL"
	},
	{
			"value": "FF LANTERNS RATTAN",
			"label": "FF LANTERNS RATTAN"
	},
	{
			"value": "FF LIGHTING",
			"label": "FF LIGHTING"
	},
	{
			"value": "F FLOATING",
			"label": "F FLOATING"
	},
	{
			"value": "FF LUMBAR PILLOWS",
			"label": "FF LUMBAR PILLOWS"
	},
	{
			"value": "FF MELAMINE",
			"label": "FF MELAMINE"
	},
	{
			"value": "FF  MUGS",
			"label": "FF  MUGS"
	},
	{
			"value": "FF MUGS",
			"label": "FF MUGS"
	},
	{
			"value": "FF NL GIFTBAGS",
			"label": "FF NL GIFTBAGS"
	},
	{
			"value": "FF NL MUGS",
			"label": "FF NL MUGS"
	},
	{
			"value": "FF NL STOCKING",
			"label": "FF NL STOCKING"
	},
	{
			"value": "FF NL TINS",
			"label": "FF NL TINS"
	},
	{
			"value": "FF NL WRAP ACCS",
			"label": "FF NL WRAP ACCS"
	},
	{
			"value": "F FOS",
			"label": "F FOS"
	},
	{
			"value": "FF PAPER ENT",
			"label": "FF PAPER ENT"
	},
	{
			"value": "FF PEVA",
			"label": "FF PEVA"
	},
	{
			"value": "FF PILLOWS",
			"label": "FF PILLOWS"
	},
	{
			"value": "FF PLANTERS",
			"label": "FF PLANTERS"
	},
	{
			"value": "FF PLASTIC EGGS",
			"label": "FF PLASTIC EGGS"
	},
	{
			"value": "FF SERVEWARE",
			"label": "FF SERVEWARE"
	},
	{
			"value": "FF STAINED GLASS TT",
			"label": "FF STAINED GLASS TT"
	},
	{
			"value": "FF TABLETOP",
			"label": "FF TABLETOP"
	},
	{
			"value": "FF TOWELS",
			"label": "FF TOWELS"
	},
	{
			"value": "FF WALL DECOR",
			"label": "FF WALL DECOR"
	},
	{
			"value": "FF WOOD ENT",
			"label": "FF WOOD ENT"
	},
	{
			"value": "FF WORD BLOCKS",
			"label": "FF WORD BLOCKS"
	},
	{
			"value": "FF WORDBLOCKS",
			"label": "FF WORDBLOCKS"
	},
	{
			"value": "FG (16W-18W-20W-22W-",
			"label": "FG (16W-18W-20W-22W-"
	},
	{
			"value": "FH (28W-30W-32W-34W-",
			"label": "FH (28W-30W-32W-34W-"
	},
	{
			"value": "FIGUAL TT",
			"label": "FIGUAL TT"
	},
	{
			"value": "FIGUARL TT",
			"label": "FIGUARL TT"
	},
	{
			"value": "FIGURAL OUTDOOR",
			"label": "FIGURAL OUTDOOR"
	},
	{
			"value": "FIGURAL TT",
			"label": "FIGURAL TT"
	},
	{
			"value": "FIGURAL WD",
			"label": "FIGURAL WD"
	},
	{
			"value": "FILE BIN",
			"label": "FILE BIN"
	},
	{
			"value": "FINE",
			"label": "FINE"
	},
	{
			"value": "FIRELIGHT",
			"label": "FIRELIGHT"
	},
	{
			"value": "FIRGUAL TT",
			"label": "FIRGUAL TT"
	},
	{
			"value": "FIXTURE",
			"label": "FIXTURE"
	},
	{
			"value": "FLAG",
			"label": "FLAG"
	},
	{
			"value": "F LIGHTING",
			"label": "F LIGHTING"
	},
	{
			"value": "FLOATING",
			"label": "FLOATING"
	},
	{
			"value": "FLOCK",
			"label": "FLOCK"
	},
	{
			"value": "FLOCKED",
			"label": "FLOCKED"
	},
	{
			"value": "FLORAL",
			"label": "FLORAL"
	},
	{
			"value": "F LOWBOY",
			"label": "F LOWBOY"
	},
	{
			"value": "F MATS",
			"label": "F MATS"
	},
	{
			"value": "FOAM 11X14",
			"label": "FOAM 11X14"
	},
	{
			"value": "FOIL",
			"label": "FOIL"
	},
	{
			"value": "FOS",
			"label": "FOS"
	},
	{
			"value": "FOS 3",
			"label": "FOS 3"
	},
	{
			"value": "FOS 7",
			"label": "FOS 7"
	},
	{
			"value": "FOUNDATION 11X14",
			"label": "FOUNDATION 11X14"
	},
	{
			"value": "FOUNDATION 14X17",
			"label": "FOUNDATION 14X17"
	},
	{
			"value": "FOUNDATION 9X12",
			"label": "FOUNDATION 9X12"
	},
	{
			"value": "FOUR 3G TUBES",
			"label": "FOUR 3G TUBES"
	},
	{
			"value": "F PAPER ENTERTAINING",
			"label": "F PAPER ENTERTAINING"
	},
	{
			"value": "F PP APPLE PICK TWL",
			"label": "F PP APPLE PICK TWL"
	},
	{
			"value": "F PP GEL CLINGS",
			"label": "F PP GEL CLINGS"
	},
	{
			"value": "F PP MF FLAG",
			"label": "F PP MF FLAG"
	},
	{
			"value": "F PP ST FLAG",
			"label": "F PP ST FLAG"
	},
	{
			"value": "F PP SUNFLOWER",
			"label": "F PP SUNFLOWER"
	},
	{
			"value": "F PP TGTHR TIME FLAG",
			"label": "F PP TGTHR TIME FLAG"
	},
	{
			"value": "F PP THNKSGVNG TOWEL",
			"label": "F PP THNKSGVNG TOWEL"
	},
	{
			"value": "F PUMPKINS",
			"label": "F PUMPKINS"
	},
	{
			"value": "FRIENDS & FAMILY OSO",
			"label": "FRIENDS & FAMILY OSO"
	},
	{
			"value": "F SDWK PORCH SITTERS",
			"label": "F SDWK PORCH SITTERS"
	},
	{
			"value": "F SDWK SCARECROWS",
			"label": "F SDWK SCARECROWS"
	},
	{
			"value": "F SDWK YARD STAKES",
			"label": "F SDWK YARD STAKES"
	},
	{
			"value": "F TEXTILES",
			"label": "F TEXTILES"
	},
	{
			"value": "FULL",
			"label": "FULL"
	},
	{
			"value": "FULL FIGRE",
			"label": "FULL FIGRE"
	},
	{
			"value": "FUNCATIONAL TT",
			"label": "FUNCATIONAL TT"
	},
	{
			"value": "FUNCTIONAL GARDENING",
			"label": "FUNCTIONAL GARDENING"
	},
	{
			"value": "FUNCTIONAL TT",
			"label": "FUNCTIONAL TT"
	},
	{
			"value": "FUNCTIONAL WD",
			"label": "FUNCTIONAL WD"
	},
	{
			"value": "FW(18-20-22)",
			"label": "FW(18-20-22)"
	},
	{
			"value": "FW38",
			"label": "FW38"
	},
	{
			"value": "FW39",
			"label": "FW39"
	},
	{
			"value": "F WALL DÉCOR",
			"label": "F WALL DÉCOR"
	},
	{
			"value": "G00",
			"label": "G00"
	},
	{
			"value": "G00/01",
			"label": "G00/01"
	},
	{
			"value": "G00/G01",
			"label": "G00/G01"
	},
	{
			"value": "G5 (26W-28W-30W-32W-",
			"label": "G5 (26W-28W-30W-32W-"
	},
	{
			"value": "GARDEN CURATED",
			"label": "GARDEN CURATED"
	},
	{
			"value": "GARDEN UTILITY",
			"label": "GARDEN UTILITY"
	},
	{
			"value": "GARLANDS",
			"label": "GARLANDS"
	},
	{
			"value": "GAZING BALLS",
			"label": "GAZING BALLS"
	},
	{
			"value": "GEL CLINGS",
			"label": "GEL CLINGS"
	},
	{
			"value": "GG (26W-28W-30W-32W)",
			"label": "GG (26W-28W-30W-32W)"
	},
	{
			"value": "GHJK",
			"label": "GHJK"
	},
	{
			"value": "GIFTBAGS",
			"label": "GIFTBAGS"
	},
	{
			"value": "GIFT BOW ASST",
			"label": "GIFT BOW ASST"
	},
	{
			"value": "GIFTWRAP & ACCS",
			"label": "GIFTWRAP & ACCS"
	},
	{
			"value": "GIRL(7-8-10-12-14)",
			"label": "GIRL(7-8-10-12-14)"
	},
	{
			"value": "GLITTER",
			"label": "GLITTER"
	},
	{
			"value": "GN 10",
			"label": "GN 10"
	},
	{
			"value": "GN 21",
			"label": "GN 21"
	},
	{
			"value": "GN 5.5",
			"label": "GN 5.5"
	},
	{
			"value": "GRASS",
			"label": "GRASS"
	},
	{
			"value": "GRAY",
			"label": "GRAY"
	},
	{
			"value": "GRAY LT",
			"label": "GRAY LT"
	},
	{
			"value": "GREEN",
			"label": "GREEN"
	},
	{
			"value": "GROUP A",
			"label": "GROUP A"
	},
	{
			"value": "GROUP B",
			"label": "GROUP B"
	},
	{
			"value": "GROW BAGS",
			"label": "GROW BAGS"
	},
	{
			"value": "GUN/STICKS 4X.28",
			"label": "GUN/STICKS 4X.28"
	},
	{
			"value": "H00",
			"label": "H00"
	},
	{
			"value": "H 2ND CURATION",
			"label": "H 2ND CURATION"
	},
	{
			"value": "H 3RD CURATION",
			"label": "H 3RD CURATION"
	},
	{
			"value": "H5 (6-8-10-12-14)",
			"label": "H5 (6-8-10-12-14)"
	},
	{
			"value": "H5(6-8-10-12-14)",
			"label": "H5(6-8-10-12-14)"
	},
	{
			"value": "HALF GALLON",
			"label": "HALF GALLON"
	},
	{
			"value": "HALF INCH X 20 YARDS",
			"label": "HALF INCH X 20 YARDS"
	},
	{
			"value": "HANGING",
			"label": "HANGING"
	},
	{
			"value": "HANK",
			"label": "HANK"
	},
	{
			"value": "HANUKKAH",
			"label": "HANUKKAH"
	},
	{
			"value": "HAT",
			"label": "HAT"
	},
	{
			"value": "HAT 20X20IN +SCARF 4",
			"label": "HAT 20X20IN +SCARF 4"
	},
	{
			"value": "H BONES",
			"label": "H BONES"
	},
	{
			"value": "H DEC BOXES",
			"label": "H DEC BOXES"
	},
	{
			"value": "HEADER",
			"label": "HEADER"
	},
	{
			"value": "HEART",
			"label": "HEART"
	},
	{
			"value": "HEARTS",
			"label": "HEARTS"
	},
	{
			"value": "H EC COSTUME ACCESS",
			"label": "H EC COSTUME ACCESS"
	},
	{
			"value": "H EC DAY OF THE DEAD",
			"label": "H EC DAY OF THE DEAD"
	},
	{
			"value": "H EC GARLANDS",
			"label": "H EC GARLANDS"
	},
	{
			"value": "H EC INFLATABLES",
			"label": "H EC INFLATABLES"
	},
	{
			"value": "H EC OUTDOOR DÉCOR",
			"label": "H EC OUTDOOR DÉCOR"
	},
	{
			"value": "H EC PHOTOBOOTH",
			"label": "H EC PHOTOBOOTH"
	},
	{
			"value": "H EC SPELLBOUND",
			"label": "H EC SPELLBOUND"
	},
	{
			"value": "H ENTERTAINING",
			"label": "H ENTERTAINING"
	},
	{
			"value": "H FABRIC",
			"label": "H FABRIC"
	},
	{
			"value": "H FF BAGGED PUMPKINS",
			"label": "H FF BAGGED PUMPKINS"
	},
	{
			"value": "H FF BUCKETS",
			"label": "H FF BUCKETS"
	},
	{
			"value": "H FF ENTERTAINING",
			"label": "H FF ENTERTAINING"
	},
	{
			"value": "H FF FLAGS & STAKES",
			"label": "H FF FLAGS & STAKES"
	},
	{
			"value": "H FF KEY ICONS",
			"label": "H FF KEY ICONS"
	},
	{
			"value": "H FF LED SKULLS",
			"label": "H FF LED SKULLS"
	},
	{
			"value": "H FF LUMBAR PILLOWS",
			"label": "H FF LUMBAR PILLOWS"
	},
	{
			"value": "H FF MELAMINE",
			"label": "H FF MELAMINE"
	},
	{
			"value": "H FF MUGS",
			"label": "H FF MUGS"
	},
	{
			"value": "H FF PAPER ENTERTAIN",
			"label": "H FF PAPER ENTERTAIN"
	},
	{
			"value": "H FF STRING LIGHTS",
			"label": "H FF STRING LIGHTS"
	},
	{
			"value": "H FF TOWELS",
			"label": "H FF TOWELS"
	},
	{
			"value": "H FF WORDBLOCKS",
			"label": "H FF WORDBLOCKS"
	},
	{
			"value": "H FOS",
			"label": "H FOS"
	},
	{
			"value": "HH (3-4-5-6)",
			"label": "HH (3-4-5-6)"
	},
	{
			"value": "HH (6-8-10-12)",
			"label": "HH (6-8-10-12)"
	},
	{
			"value": "HIGH SHINE",
			"label": "HIGH SHINE"
	},
	{
			"value": "HIMA HIMALAYAN",
			"label": "HIMA HIMALAYAN"
	},
	{
			"value": "H LIGHTING",
			"label": "H LIGHTING"
	},
	{
			"value": "H LIGHTING2",
			"label": "H LIGHTING2"
	},
	{
			"value": "H LOWBOY",
			"label": "H LOWBOY"
	},
	{
			"value": "H LOWBOY2",
			"label": "H LOWBOY2"
	},
	{
			"value": "H MATS",
			"label": "H MATS"
	},
	{
			"value": "HOLDBACK PAIR",
			"label": "HOLDBACK PAIR"
	},
	{
			"value": "HOLIDAY CRAFT",
			"label": "HOLIDAY CRAFT"
	},
	{
			"value": "HOLOGRAPHIC",
			"label": "HOLOGRAPHIC"
	},
	{
			"value": "HOME DECOR",
			"label": "HOME DECOR"
	},
	{
			"value": "HOOKS",
			"label": "HOOKS"
	},
	{
			"value": "H ORNAMENTS",
			"label": "H ORNAMENTS"
	},
	{
			"value": "HOUSE",
			"label": "HOUSE"
	},
	{
			"value": "H PAPER ENTERTAINING",
			"label": "H PAPER ENTERTAINING"
	},
	{
			"value": "H PARTY",
			"label": "H PARTY"
	},
	{
			"value": "H PET",
			"label": "H PET"
	},
	{
			"value": "H PP DAY OF THE DEAD",
			"label": "H PP DAY OF THE DEAD"
	},
	{
			"value": "H PP FLAGS",
			"label": "H PP FLAGS"
	},
	{
			"value": "H PP FLAGS FOS",
			"label": "H PP FLAGS FOS"
	},
	{
			"value": "H PP GEL CLING LARGE",
			"label": "H PP GEL CLING LARGE"
	},
	{
			"value": "H PP GEL CLINGS",
			"label": "H PP GEL CLINGS"
	},
	{
			"value": "H PP PHOTOBOOTH",
			"label": "H PP PHOTOBOOTH"
	},
	{
			"value": "H PP SPELLBOUND",
			"label": "H PP SPELLBOUND"
	},
	{
			"value": "H PUMPKINS",
			"label": "H PUMPKINS"
	},
	{
			"value": "H SDWK CRVBL PUMPKIN",
			"label": "H SDWK CRVBL PUMPKIN"
	},
	{
			"value": "H SDWK PILLOWS",
			"label": "H SDWK PILLOWS"
	},
	{
			"value": "H SDWK PORCH SITTERS",
			"label": "H SDWK PORCH SITTERS"
	},
	{
			"value": "H SDWK SCARECROWS",
			"label": "H SDWK SCARECROWS"
	},
	{
			"value": "H SDWK YARD STAKES",
			"label": "H SDWK YARD STAKES"
	},
	{
			"value": "H SKULLS",
			"label": "H SKULLS"
	},
	{
			"value": "H WALL DÉCOR",
			"label": "H WALL DÉCOR"
	},
	{
			"value": "I00",
			"label": "I00"
	},
	{
			"value": "ICE",
			"label": "ICE"
	},
	{
			"value": "ICE BUCKET",
			"label": "ICE BUCKET"
	},
	{
			"value": "ICE CUBES",
			"label": "ICE CUBES"
	},
	{
			"value": "IGNORE",
			"label": "IGNORE"
	},
	{
			"value": "INDOOR GARDENING",
			"label": "INDOOR GARDENING"
	},
	{
			"value": "INFANT",
			"label": "INFANT"
	},
	{
			"value": "INFANT SLIPPERS 18CT",
			"label": "INFANT SLIPPERS 18CT"
	},
	{
			"value": "INFE0005/INFEW005",
			"label": "INFE0005/INFEW005"
	},
	{
			"value": "INFE0007/INFEW007",
			"label": "INFE0007/INFEW007"
	},
	{
			"value": "INFEW001",
			"label": "INFEW001"
	},
	{
			"value": "INFLATABLES",
			"label": "INFLATABLES"
	},
	{
			"value": "INLINE",
			"label": "INLINE"
	},
	{
			"value": "INLINE MUGS",
			"label": "INLINE MUGS"
	},
	{
			"value": "INLINE TOWELS",
			"label": "INLINE TOWELS"
	},
	{
			"value": "IRIDESCENT",
			"label": "IRIDESCENT"
	},
	{
			"value": "J00",
			"label": "J00"
	},
	{
			"value": "JACKET / COAT",
			"label": "JACKET / COAT"
	},
	{
			"value": "JAN",
			"label": "JAN"
	},
	{
			"value": "JAR SET 1",
			"label": "JAR SET 1"
	},
	{
			"value": "JEWEL",
			"label": "JEWEL"
	},
	{
			"value": "JIMMIES",
			"label": "JIMMIES"
	},
	{
			"value": "JINGLE BELLS",
			"label": "JINGLE BELLS"
	},
	{
			"value": "JUL",
			"label": "JUL"
	},
	{
			"value": "JUMBO",
			"label": "JUMBO"
	},
	{
			"value": "JUN",
			"label": "JUN"
	},
	{
			"value": "JUN-00",
			"label": "JUN-00"
	},
	{
			"value": "K5 (7-8-10-12-14)",
			"label": "K5 (7-8-10-12-14)"
	},
	{
			"value": "K5 (8-10-12-14-16)",
			"label": "K5 (8-10-12-14-16)"
	},
	{
			"value": "KID(3-4  5-6  7-8)",
			"label": "KID(3-4  5-6  7-8)"
	},
	{
			"value": "KIDS",
			"label": "KIDS"
	},
	{
			"value": "KING",
			"label": "KING"
	},
	{
			"value": "KING - 104  X86",
			"label": "KING - 104  X86"
	},
	{
			"value": "KING SIZE",
			"label": "KING SIZE"
	},
	{
			"value": "KIT",
			"label": "KIT"
	},
	{
			"value": "KIT-122PC",
			"label": "KIT-122PC"
	},
	{
			"value": "KIT-68PC",
			"label": "KIT-68PC"
	},
	{
			"value": "KITCHEN TEXTILES",
			"label": "KITCHEN TEXTILES"
	},
	{
			"value": "KITE",
			"label": "KITE"
	},
	{
			"value": "KK(26W-28W-30W-32W)",
			"label": "KK(26W-28W-30W-32W)"
	},
	{
			"value": "KK (8-10-12-14)",
			"label": "KK (8-10-12-14)"
	},
	{
			"value": "KRAFT BAGS",
			"label": "KRAFT BAGS"
	},
	{
			"value": "KRAFT WRAP",
			"label": "KRAFT WRAP"
	},
	{
			"value": "L",
			"label": "L"
	},
	{
			"value": "L- 4.10 X W- 4.5 X H",
			"label": "L- 4.10 X W- 4.5 X H"
	},
	{
			"value": "L- 4.85 X W- 4.85 X",
			"label": "L- 4.85 X W- 4.85 X"
	},
	{
			"value": "L- 5.75 X W- 5.75 X",
			"label": "L- 5.75 X W- 5.75 X"
	},
	{
			"value": "LACE",
			"label": "LACE"
	},
	{
			"value": "LANTERNS",
			"label": "LANTERNS"
	},
	{
			"value": "LAP DESK",
			"label": "LAP DESK"
	},
	{
			"value": "LAQUER",
			"label": "LAQUER"
	},
	{
			"value": "LARGE",
			"label": "LARGE"
	},
	{
			"value": "LARGE CUP",
			"label": "LARGE CUP"
	},
	{
			"value": "LED",
			"label": "LED"
	},
	{
			"value": "LED BOW",
			"label": "LED BOW"
	},
	{
			"value": "LEMON",
			"label": "LEMON"
	},
	{
			"value": "LETTER SIZE",
			"label": "LETTER SIZE"
	},
	{
			"value": "LG",
			"label": "LG"
	},
	{
			"value": "LICENSED WRAP",
			"label": "LICENSED WRAP"
	},
	{
			"value": "LIGHTING",
			"label": "LIGHTING"
	},
	{
			"value": "LINED",
			"label": "LINED"
	},
	{
			"value": "LINES",
			"label": "LINES"
	},
	{
			"value": "LIPS",
			"label": "LIPS"
	},
	{
			"value": "LIQUID",
			"label": "LIQUID"
	},
	{
			"value": "LIVING ROOM",
			"label": "LIVING ROOM"
	},
	{
			"value": "LLAMA",
			"label": "LLAMA"
	},
	{
			"value": "LMNP",
			"label": "LMNP"
	},
	{
			"value": "LN",
			"label": "LN"
	},
	{
			"value": "LOW BOY",
			"label": "LOW BOY"
	},
	{
			"value": "LOWBOY",
			"label": "LOWBOY"
	},
	{
			"value": "LRG(16-18)",
			"label": "LRG(16-18)"
	},
	{
			"value": "LUNAR NEW YEAR",
			"label": "LUNAR NEW YEAR"
	},
	{
			"value": "LUSTER",
			"label": "LUSTER"
	},
	{
			"value": "L/XL",
			"label": "L/XL"
	},
	{
			"value": "M",
			"label": "M"
	},
	{
			"value": "M1 (10-12-14-16-18)",
			"label": "M1 (10-12-14-16-18)"
	},
	{
			"value": "MAIN CURATION",
			"label": "MAIN CURATION"
	},
	{
			"value": "MAR",
			"label": "MAR"
	},
	{
			"value": "MARCH",
			"label": "MARCH"
	},
	{
			"value": "MASON  36-66 LENGTH",
			"label": "MASON  36-66 LENGTH"
	},
	{
			"value": "MASON 66-120 LENGTH",
			"label": "MASON 66-120 LENGTH"
	},
	{
			"value": "MASON  HOLDBACK 1 1",
			"label": "MASON  HOLDBACK 1 1"
	},
	{
			"value": "MAT",
			"label": "MAT"
	},
	{
			"value": "MATRIX",
			"label": "MATRIX"
	},
	{
			"value": "MATS & FLAGS",
			"label": "MATS & FLAGS"
	},
	{
			"value": "MAY",
			"label": "MAY"
	},
	{
			"value": "MCHSUK",
			"label": "MCHSUK"
	},
	{
			"value": "MD",
			"label": "MD"
	},
	{
			"value": "MED",
			"label": "MED"
	},
	{
			"value": "MED(12-14)",
			"label": "MED(12-14)"
	},
	{
			"value": "MEDIUM",
			"label": "MEDIUM"
	},
	{
			"value": "MEDIUML",
			"label": "MEDIUML"
	},
	{
			"value": "MEDIUM LARGE",
			"label": "MEDIUM LARGE"
	},
	{
			"value": "MEDIUMS SET",
			"label": "MEDIUMS SET"
	},
	{
			"value": "MEGA",
			"label": "MEGA"
	},
	{
			"value": "MELAMINE",
			"label": "MELAMINE"
	},
	{
			"value": "MEN",
			"label": "MEN"
	},
	{
			"value": "MEN-M",
			"label": "MEN-M"
	},
	{
			"value": "MERRY & BRIGHT 1",
			"label": "MERRY & BRIGHT 1"
	},
	{
			"value": "MERRY & BRIGHT 2",
			"label": "MERRY & BRIGHT 2"
	},
	{
			"value": "METALLIC",
			"label": "METALLIC"
	},
	{
			"value": "MINI",
			"label": "MINI"
	},
	{
			"value": "MINI-4X.28",
			"label": "MINI-4X.28"
	},
	{
			"value": "MISC",
			"label": "MISC"
	},
	{
			"value": "MISS",
			"label": "MISS"
	},
	{
			"value": "MIS(SML-MED-LRG-XLG)",
			"label": "MIS(SML-MED-LRG-XLG)"
	},
	{
			"value": "MIS(XSM-SML-MED-LRG-",
			"label": "MIS(XSM-SML-MED-LRG-"
	},
	{
			"value": "MIX",
			"label": "MIX"
	},
	{
			"value": "MIXED",
			"label": "MIXED"
	},
	{
			"value": "MIXTURE",
			"label": "MIXTURE"
	},
	{
			"value": "M/L",
			"label": "M/L"
	},
	{
			"value": "MQQ(46-48-50-52)",
			"label": "MQQ(46-48-50-52)"
	},
	{
			"value": "MR4650.2.BE",
			"label": "MR4650.2.BE"
	},
	{
			"value": "MR4650.2.BK",
			"label": "MR4650.2.BK"
	},
	{
			"value": "MR4650.2.BL",
			"label": "MR4650.2.BL"
	},
	{
			"value": "MR4650.G4204",
			"label": "MR4650.G4204"
	},
	{
			"value": "MR4650.H1",
			"label": "MR4650.H1"
	},
	{
			"value": "MR4650.WC16",
			"label": "MR4650.WC16"
	},
	{
			"value": "MR4660.2.BE",
			"label": "MR4660.2.BE"
	},
	{
			"value": "MR4660.2.BK",
			"label": "MR4660.2.BK"
	},
	{
			"value": "MR4680.2.BE",
			"label": "MR4680.2.BE"
	},
	{
			"value": "MR4680.2.BK",
			"label": "MR4680.2.BK"
	},
	{
			"value": "MR4680.2.BL",
			"label": "MR4680.2.BL"
	},
	{
			"value": "MR4680.G4204",
			"label": "MR4680.G4204"
	},
	{
			"value": "MR4680.H1",
			"label": "MR4680.H1"
	},
	{
			"value": "MR4680.WC16",
			"label": "MR4680.WC16"
	},
	{
			"value": "MR4681.2.BE",
			"label": "MR4681.2.BE"
	},
	{
			"value": "MR4681.2.BK",
			"label": "MR4681.2.BK"
	},
	{
			"value": "MR4681.2.BL",
			"label": "MR4681.2.BL"
	},
	{
			"value": "MR4681.G4204",
			"label": "MR4681.G4204"
	},
	{
			"value": "MR4681.H1",
			"label": "MR4681.H1"
	},
	{
			"value": "MR4682.2.BE",
			"label": "MR4682.2.BE"
	},
	{
			"value": "MR4682.2.BK",
			"label": "MR4682.2.BK"
	},
	{
			"value": "MR4682.2.BL",
			"label": "MR4682.2.BL"
	},
	{
			"value": "MR4685 + MR4678 + MR",
			"label": "MR4685 + MR4678 + MR"
	},
	{
			"value": "MR4685 + MR4686.G420",
			"label": "MR4685 + MR4686.G420"
	},
	{
			"value": "MR4685 + MR4686.H1",
			"label": "MR4685 + MR4686.H1"
	},
	{
			"value": "MR4685 + MR4686.WC16",
			"label": "MR4685 + MR4686.WC16"
	},
	{
			"value": "MUGS",
			"label": "MUGS"
	},
	{
			"value": "MULTI",
			"label": "MULTI"
	},
	{
			"value": "MULTI 1/8   TO 1/2",
			"label": "MULTI 1/8   TO 1/2"
	},
	{
			"value": "MULTI PACK",
			"label": "MULTI PACK"
	},
	{
			"value": "MULTIPLE",
			"label": "MULTIPLE"
	},
	{
			"value": "MULTIPLE - 13 3/4\"\"",
			"label": "MULTIPLE - 13 3/4\"\""
	},
	{
			"value": "MULTIPLE SIZES",
			"label": "MULTIPLE SIZES"
	},
	{
			"value": "MUMBAI",
			"label": "MUMBAI"
	},
	{
			"value": "MUU(34-36-38-40)",
			"label": "MUU(34-36-38-40)"
	},
	{
			"value": "MWW(38-40-42-44)",
			"label": "MWW(38-40-42-44)"
	},
	{
			"value": "MXX(40-42-44-46)",
			"label": "MXX(40-42-44-46)"
	},
	{
			"value": "N/A",
			"label": "N/A"
	},
	{
			"value": "NAME?",
			"label": "NAME?"
	},
	{
			"value": "NAPKINS",
			"label": "NAPKINS"
	},
	{
			"value": "NB",
			"label": "NB"
	},
	{
			"value": "NEEDLE PUNCH",
			"label": "NEEDLE PUNCH"
	},
	{
			"value": "NEON",
			"label": "NEON"
	},
	{
			"value": "NEST",
			"label": "NEST"
	},
	{
			"value": "NONE",
			"label": "NONE"
	},
	{
			"value": "NON FUNCTIONAL",
			"label": "NON FUNCTIONAL"
	},
	{
			"value": "NONPAREILS",
			"label": "NONPAREILS"
	},
	{
			"value": "NOSTALGIA",
			"label": "NOSTALGIA"
	},
	{
			"value": "NOT ASSIGNED",
			"label": "NOT ASSIGNED"
	},
	{
			"value": "NOV",
			"label": "NOV"
	},
	{
			"value": "NOV-00",
			"label": "NOV-00"
	},
	{
			"value": "NO WORDS",
			"label": "NO WORDS"
	},
	{
			"value": "NUTCRACKERS",
			"label": "NUTCRACKERS"
	},
	{
			"value": "NVV(30-32-34-36)",
			"label": "NVV(30-32-34-36)"
	},
	{
			"value": "NYY(36-39-42-44)",
			"label": "NYY(36-39-42-44)"
	},
	{
			"value": "OCT",
			"label": "OCT"
	},
	{
			"value": "OCT-00",
			"label": "OCT-00"
	},
	{
			"value": "OIL SLICK",
			"label": "OIL SLICK"
	},
	{
			"value": "OLONG",
			"label": "OLONG"
	},
	{
			"value": "OMBRE",
			"label": "OMBRE"
	},
	{
			"value": "ONE",
			"label": "ONE"
	},
	{
			"value": "ONE FIGURE",
			"label": "ONE FIGURE"
	},
	{
			"value": "ONE SHROUD",
			"label": "ONE SHROUD"
	},
	{
			"value": "ONE SIZE",
			"label": "ONE SIZE"
	},
	{
			"value": "ONE TENT CARD",
			"label": "ONE TENT CARD"
	},
	{
			"value": "ONLINE",
			"label": "ONLINE"
	},
	{
			"value": "ONLINE ONLY",
			"label": "ONLINE ONLY"
	},
	{
			"value": "ONLINE ONLY TREES",
			"label": "ONLINE ONLY TREES"
	},
	{
			"value": "OOL ONLY- NOT INLINE",
			"label": "OOL ONLY- NOT INLINE"
	},
	{
			"value": "OOL SHATTER PROOF",
			"label": "OOL SHATTER PROOF"
	},
	{
			"value": "OS",
			"label": "OS"
	},
	{
			"value": "OSFM",
			"label": "OSFM"
	},
	{
			"value": "OS (ONE SIZE)",
			"label": "OS (ONE SIZE)"
	},
	{
			"value": "OSZ",
			"label": "OSZ"
	},
	{
			"value": "OSZ (SMALL-MEDIUM-LA",
			"label": "OSZ (SMALL-MEDIUM-LA"
	},
	{
			"value": "OSZ (XSMALL-SMALL-ME",
			"label": "OSZ (XSMALL-SMALL-ME"
	},
	{
			"value": "OSZ (XS-S-M-L-XL-XXL",
			"label": "OSZ (XS-S-M-L-XL-XXL"
	},
	{
			"value": "OTHER CRAFT",
			"label": "OTHER CRAFT"
	},
	{
			"value": "OUTDOOR",
			"label": "OUTDOOR"
	},
	{
			"value": "OUTDOOR CUSHIONS",
			"label": "OUTDOOR CUSHIONS"
	},
	{
			"value": "OUTDOOR PILLOWS",
			"label": "OUTDOOR PILLOWS"
	},
	{
			"value": "OUTLET WARMER",
			"label": "OUTLET WARMER"
	},
	{
			"value": "OUTLET WARMERS",
			"label": "OUTLET WARMERS"
	},
	{
			"value": "P5 (12-14-16-18-20)",
			"label": "P5 (12-14-16-18-20)"
	},
	{
			"value": "PACK",
			"label": "PACK"
	},
	{
			"value": "PACK NOT APPROVED",
			"label": "PACK NOT APPROVED"
	},
	{
			"value": "PACK OF 16 SKEINS",
			"label": "PACK OF 16 SKEINS"
	},
	{
			"value": "PACK OF 4 NOZZLES",
			"label": "PACK OF 4 NOZZLES"
	},
	{
			"value": "PAD",
			"label": "PAD"
	},
	{
			"value": "PADDLE",
			"label": "PADDLE"
	},
	{
			"value": "PAPER",
			"label": "PAPER"
	},
	{
			"value": "PAPER ENTERTAINING",
			"label": "PAPER ENTERTAINING"
	},
	{
			"value": "PATHWAY LIGHT",
			"label": "PATHWAY LIGHT"
	},
	{
			"value": "PATHWAY LIGHTS",
			"label": "PATHWAY LIGHTS"
	},
	{
			"value": "PDQ",
			"label": "PDQ"
	},
	{
			"value": "PDQ-1",
			"label": "PDQ-1"
	},
	{
			"value": "PDQ ASSORTMENT DISPL",
			"label": "PDQ ASSORTMENT DISPL"
	},
	{
			"value": "PDQ BOX",
			"label": "PDQ BOX"
	},
	{
			"value": "PDQ PRICE WITH 64 SE",
			"label": "PDQ PRICE WITH 64 SE"
	},
	{
			"value": "PEARL DUST",
			"label": "PEARL DUST"
	},
	{
			"value": "PEARLIZED",
			"label": "PEARLIZED"
	},
	{
			"value": "PEARLS",
			"label": "PEARLS"
	},
	{
			"value": "PET",
			"label": "PET"
	},
	{
			"value": "PETITE",
			"label": "PETITE"
	},
	{
			"value": "PEVA",
			"label": "PEVA"
	},
	{
			"value": "PICK ONE OF THESE",
			"label": "PICK ONE OF THESE"
	},
	{
			"value": "PICKS",
			"label": "PICKS"
	},
	{
			"value": "PIE",
			"label": "PIE"
	},
	{
			"value": "PILLOWS",
			"label": "PILLOWS"
	},
	{
			"value": "PILLOWS & THROWS",
			"label": "PILLOWS & THROWS"
	},
	{
			"value": "PIN CUSHION",
			"label": "PIN CUSHION"
	},
	{
			"value": "PINK",
			"label": "PINK"
	},
	{
			"value": "PINT",
			"label": "PINT"
	},
	{
			"value": "PK OF 36 SKEINS",
			"label": "PK OF 36 SKEINS"
	},
	{
			"value": "PK OF 6 PRISM PEARL",
			"label": "PK OF 6 PRISM PEARL"
	},
	{
			"value": "PLACEHOLDER",
			"label": "PLACEHOLDER"
	},
	{
			"value": "PLACEMAT",
			"label": "PLACEMAT"
	},
	{
			"value": "PLANTERS",
			"label": "PLANTERS"
	},
	{
			"value": "PLANTERS TT",
			"label": "PLANTERS TT"
	},
	{
			"value": "PLANT STANDS",
			"label": "PLANT STANDS"
	},
	{
			"value": "PLASTIC EGG",
			"label": "PLASTIC EGG"
	},
	{
			"value": "PLATES",
			"label": "PLATES"
	},
	{
			"value": "PLEATED",
			"label": "PLEATED"
	},
	{
			"value": "PLEATHER ACCENT",
			"label": "PLEATHER ACCENT"
	},
	{
			"value": "PLS(10 1/2-12 1/2-14",
			"label": "PLS(10 1/2-12 1/2-14"
	},
	{
			"value": "PORCH BANNERS",
			"label": "PORCH BANNERS"
	},
	{
			"value": "PORCH DECOR",
			"label": "PORCH DECOR"
	},
	{
			"value": "PORCH PATIO",
			"label": "PORCH PATIO"
	},
	{
			"value": "PORCH SITTER",
			"label": "PORCH SITTER"
	},
	{
			"value": "PORCH SITTERS",
			"label": "PORCH SITTERS"
	},
	{
			"value": "POTHOLDER",
			"label": "POTHOLDER"
	},
	{
			"value": "POT HOLDER 6.875 X 6",
			"label": "POT HOLDER 6.875 X 6"
	},
	{
			"value": "POURED",
			"label": "POURED"
	},
	{
			"value": "POWDER COATED",
			"label": "POWDER COATED"
	},
	{
			"value": "POWER PANEL",
			"label": "POWER PANEL"
	},
	{
			"value": "PP AMERICANA",
			"label": "PP AMERICANA"
	},
	{
			"value": "PP CHEAP OSO",
			"label": "PP CHEAP OSO"
	},
	{
			"value": "PP DAISY",
			"label": "PP DAISY"
	},
	{
			"value": "PP DTE FLAGS",
			"label": "PP DTE FLAGS"
	},
	{
			"value": "PP EAS FLAGS",
			"label": "PP EAS FLAGS"
	},
	{
			"value": "PP EASTER GEL CLINGS",
			"label": "PP EASTER GEL CLINGS"
	},
	{
			"value": "PP EASTER PET",
			"label": "PP EASTER PET"
	},
	{
			"value": "PP GEL CLINGS",
			"label": "PP GEL CLINGS"
	},
	{
			"value": "PP HAVEN FLAGS",
			"label": "PP HAVEN FLAGS"
	},
	{
			"value": "PP IFB FLAGS",
			"label": "PP IFB FLAGS"
	},
	{
			"value": "PP LICENSED",
			"label": "PP LICENSED"
	},
	{
			"value": "PP MUSHROOM",
			"label": "PP MUSHROOM"
	},
	{
			"value": "PP RICE LIGHTS",
			"label": "PP RICE LIGHTS"
	},
	{
			"value": "PP SCENTICLES",
			"label": "PP SCENTICLES"
	},
	{
			"value": "PP SL FLAGS",
			"label": "PP SL FLAGS"
	},
	{
			"value": "PP ST PATS",
			"label": "PP ST PATS"
	},
	{
			"value": "PP ST PATS PET",
			"label": "PP ST PATS PET"
	},
	{
			"value": "PP STRAWBERRY",
			"label": "PP STRAWBERRY"
	},
	{
			"value": "PP TOWELS",
			"label": "PP TOWELS"
	},
	{
			"value": "PP VAL",
			"label": "PP VAL"
	},
	{
			"value": "PP VAL PET",
			"label": "PP VAL PET"
	},
	{
			"value": "PP VAL TINSEL",
			"label": "PP VAL TINSEL"
	},
	{
			"value": "PRE",
			"label": "PRE"
	},
	{
			"value": "PRETZEL",
			"label": "PRETZEL"
	},
	{
			"value": "PREVIEW",
			"label": "PREVIEW"
	},
	{
			"value": "PRINTED",
			"label": "PRINTED"
	},
	{
			"value": "PRINTED SATIN",
			"label": "PRINTED SATIN"
	},
	{
			"value": "PUFFIES",
			"label": "PUFFIES"
	},
	{
			"value": "PURSUIT",
			"label": "PURSUIT"
	},
	{
			"value": "PVC",
			"label": "PVC"
	},
	{
			"value": "QTR INCH X 20 YARDS",
			"label": "QTR INCH X 20 YARDS"
	},
	{
			"value": "QUART",
			"label": "QUART"
	},
	{
			"value": "QUEEN",
			"label": "QUEEN"
	},
	{
			"value": "QUEEN - 86  X86",
			"label": "QUEEN - 86  X86"
	},
	{
			"value": "QUILTING",
			"label": "QUILTING"
	},
	{
			"value": "R5 (14-16-18-20-22)",
			"label": "R5 (14-16-18-20-22)"
	},
	{
			"value": "R5(14-16-18-20-22)",
			"label": "R5(14-16-18-20-22)"
	},
	{
			"value": "RAINBOW",
			"label": "RAINBOW"
	},
	{
			"value": "RAISED",
			"label": "RAISED"
	},
	{
			"value": "RECTANGULAR BIN",
			"label": "RECTANGULAR BIN"
	},
	{
			"value": "REF!",
			"label": "REF!"
	},
	{
			"value": "REGULAR",
			"label": "REGULAR"
	},
	{
			"value": "REGULAR MOUTH",
			"label": "REGULAR MOUTH"
	},
	{
			"value": "RELIGIOUS OSO",
			"label": "RELIGIOUS OSO"
	},
	{
			"value": "RIBBED",
			"label": "RIBBED"
	},
	{
			"value": "RIBBIT",
			"label": "RIBBIT"
	},
	{
			"value": "RIBBON PACK",
			"label": "RIBBON PACK"
	},
	{
			"value": "ROLL 12X24",
			"label": "ROLL 12X24"
	},
	{
			"value": "ROLL 18X24",
			"label": "ROLL 18X24"
	},
	{
			"value": "ROLL 24X48",
			"label": "ROLL 24X48"
	},
	{
			"value": "ROLL PAPER",
			"label": "ROLL PAPER"
	},
	{
			"value": "ROOSTER PLANTER NAVY",
			"label": "ROOSTER PLANTER NAVY"
	},
	{
			"value": "ROOSTER PLANTER WHIT",
			"label": "ROOSTER PLANTER WHIT"
	},
	{
			"value": "RR (14-16-18-20)",
			"label": "RR (14-16-18-20)"
	},
	{
			"value": "RR (18W-20W-22W-24W",
			"label": "RR (18W-20W-22W-24W"
	},
	{
			"value": "RR(18W-20W-22W-24W)",
			"label": "RR(18W-20W-22W-24W)"
	},
	{
			"value": "RUBBERIZED",
			"label": "RUBBERIZED"
	},
	{
			"value": "RUBBER MAT",
			"label": "RUBBER MAT"
	},
	{
			"value": "RUBBER MATS",
			"label": "RUBBER MATS"
	},
	{
			"value": "RUFFLE",
			"label": "RUFFLE"
	},
	{
			"value": "RUGS",
			"label": "RUGS"
	},
	{
			"value": "S",
			"label": "S"
	},
	{
			"value": " S=2, M=1, L=1, XL=1",
			"label": " S=2, M=1, L=1, XL=1"
	},
	{
			"value": "SA800601",
			"label": "SA800601"
	},
	{
			"value": "SAMPLER",
			"label": "SAMPLER"
	},
	{
			"value": "SANTAS/ANGELS",
			"label": "SANTAS/ANGELS"
	},
	{
			"value": "SASSY",
			"label": "SASSY"
	},
	{
			"value": "SCENT FOOD",
			"label": "SCENT FOOD"
	},
	{
			"value": "SCENTSICLES",
			"label": "SCENTSICLES"
	},
	{
			"value": "SC SCENTE",
			"label": "SC SCENTE"
	},
	{
			"value": "SDWK2",
			"label": "SDWK2"
	},
	{
			"value": "SDWK 39.99 PILLOWS",
			"label": "SDWK 39.99 PILLOWS"
	},
	{
			"value": "SDWK COCO LINERS",
			"label": "SDWK COCO LINERS"
	},
	{
			"value": "SDWK OUTDOOR PILLOWS",
			"label": "SDWK OUTDOOR PILLOWS"
	},
	{
			"value": "SDWK PLANTERS",
			"label": "SDWK PLANTERS"
	},
	{
			"value": "SDWK PORCH SITTERS",
			"label": "SDWK PORCH SITTERS"
	},
	{
			"value": "SDWK SHEP HOOKS",
			"label": "SDWK SHEP HOOKS"
	},
	{
			"value": "SEP",
			"label": "SEP"
	},
	{
			"value": "SEPT",
			"label": "SEPT"
	},
	{
			"value": "SEQUIN",
			"label": "SEQUIN"
	},
	{
			"value": "SEQUINS",
			"label": "SEQUINS"
	},
	{
			"value": "SERVEWARE",
			"label": "SERVEWARE"
	},
	{
			"value": "SET",
			"label": "SET"
	},
	{
			"value": "SET 3",
			"label": "SET 3"
	},
	{
			"value": "SEWING ROLLING TOTE",
			"label": "SEWING ROLLING TOTE"
	},
	{
			"value": "SEWING TOTE",
			"label": "SEWING TOTE"
	},
	{
			"value": "SHAMROCK",
			"label": "SHAMROCK"
	},
	{
			"value": "SHAPED",
			"label": "SHAPED"
	},
	{
			"value": "SHATTERPROOF",
			"label": "SHATTERPROOF"
	},
	{
			"value": "SHEET",
			"label": "SHEET"
	},
	{
			"value": "SHELF TRAY",
			"label": "SHELF TRAY"
	},
	{
			"value": "SHEPHERD HOOKS",
			"label": "SHEPHERD HOOKS"
	},
	{
			"value": "SHIMMER",
			"label": "SHIMMER"
	},
	{
			"value": "SHUTTLE",
			"label": "SHUTTLE"
	},
	{
			"value": "SIDE 1 - PUNCHES",
			"label": "SIDE 1 - PUNCHES"
	},
	{
			"value": "SIDE1 -PUNCHES/HOLID",
			"label": "SIDE1 -PUNCHES/HOLID"
	},
	{
			"value": "SILK 1.5\"\"",
			"label": "SILK 1.5\"\""
	},
	{
			"value": "SINGLE",
			"label": "SINGLE"
	},
	{
			"value": "SISAL TREES",
			"label": "SISAL TREES"
	},
	{
			"value": "SIX -18   X 18   BL",
			"label": "SIX -18   X 18   BL"
	},
	{
			"value": "SIZE 15",
			"label": "SIZE 15"
	},
	{
			"value": "SIZE 6",
			"label": "SIZE 6"
	},
	{
			"value": "SIZE 8",
			"label": "SIZE 8"
	},
	{
			"value": "SIZE E",
			"label": "SIZE E"
	},
	{
			"value": "SIZE F",
			"label": "SIZE F"
	},
	{
			"value": "SIZE G",
			"label": "SIZE G"
	},
	{
			"value": "SIZE H",
			"label": "SIZE H"
	},
	{
			"value": "SIZE I",
			"label": "SIZE I"
	},
	{
			"value": "SIZE J",
			"label": "SIZE J"
	},
	{
			"value": "SIZE K",
			"label": "SIZE K"
	},
	{
			"value": "SIZE N",
			"label": "SIZE N"
	},
	{
			"value": "SKEIN",
			"label": "SKEIN"
	},
	{
			"value": "SKINNY",
			"label": "SKINNY"
	},
	{
			"value": "SKIRT / PANTS",
			"label": "SKIRT / PANTS"
	},
	{
			"value": "SLEEPWEAR",
			"label": "SLEEPWEAR"
	},
	{
			"value": "S M",
			"label": "S M"
	},
	{
			"value": "S/M",
			"label": "S/M"
	},
	{
			"value": "SM",
			"label": "SM"
	},
	{
			"value": "S/M=3 & L/XL=2",
			"label": "S/M=3 & L/XL=2"
	},
	{
			"value": "SMAILL",
			"label": "SMAILL"
	},
	{
			"value": "SMALL",
			"label": "SMALL"
	},
	{
			"value": "SMALL CLEAR BIN",
			"label": "SMALL CLEAR BIN"
	},
	{
			"value": "SMALL DISH",
			"label": "SMALL DISH"
	},
	{
			"value": "SMALL MEDIUM",
			"label": "SMALL MEDIUM"
	},
	{
			"value": " SMALL, MEDIUM, LARG",
			"label": " SMALL, MEDIUM, LARG"
	},
	{
			"value": "SMALL TREES",
			"label": "SMALL TREES"
	},
	{
			"value": "SMALL WIRE",
			"label": "SMALL WIRE"
	},
	{
			"value": "SMALL WIRE HOOK",
			"label": "SMALL WIRE HOOK"
	},
	{
			"value": "SMART PERM VINYL",
			"label": "SMART PERM VINYL"
	},
	{
			"value": "SML(8-10)",
			"label": "SML(8-10)"
	},
	{
			"value": "SML-MED-LRG",
			"label": "SML-MED-LRG"
	},
	{
			"value": "SNACK PANEL",
			"label": "SNACK PANEL"
	},
	{
			"value": "SOFT BINS",
			"label": "SOFT BINS"
	},
	{
			"value": "SOLID",
			"label": "SOLID"
	},
	{
			"value": "SOLID SHATTERPROOF",
			"label": "SOLID SHATTERPROOF"
	},
	{
			"value": "SPARKLER",
			"label": "SPARKLER"
	},
	{
			"value": "SPATULA",
			"label": "SPATULA"
	},
	{
			"value": "SPLIT LARGE X-LARGE",
			"label": "SPLIT LARGE X-LARGE"
	},
	{
			"value": "SPLIT SMALL MEDIUM",
			"label": "SPLIT SMALL MEDIUM"
	},
	{
			"value": "SPOOKY",
			"label": "SPOOKY"
	},
	{
			"value": "SPOON",
			"label": "SPOON"
	},
	{
			"value": "SPOONULA",
			"label": "SPOONULA"
	},
	{
			"value": "SPORTSWEAR",
			"label": "SPORTSWEAR"
	},
	{
			"value": "SQUISH 10 INCH",
			"label": "SQUISH 10 INCH"
	},
	{
			"value": "SQUISH 14 INCH",
			"label": "SQUISH 14 INCH"
	},
	{
			"value": "SQUISH MINI",
			"label": "SQUISH MINI"
	},
	{
			"value": "SS 1 1/4   CLIP RIN",
			"label": "SS 1 1/4   CLIP RIN"
	},
	{
			"value": "SS 1 1/8   DIA 66-1",
			"label": "SS 1 1/8   DIA 66-1"
	},
	{
			"value": "SS 3/4   48-84   DR",
			"label": "SS 3/4   48-84   DR"
	},
	{
			"value": "SS 3/4  DIA. 28-48",
			"label": "SS 3/4  DIA. 28-48"
	},
	{
			"value": "SS 3/4   DIA. 48-84",
			"label": "SS 3/4   DIA. 48-84"
	},
	{
			"value": "SS 3/4  DIA.  84-12",
			"label": "SS 3/4  DIA.  84-12"
	},
	{
			"value": "SS 3/4   LIBRARY HO",
			"label": "SS 3/4   LIBRARY HO"
	},
	{
			"value": "SS 5/8DIA. 28-48",
			"label": "SS 5/8DIA. 28-48"
	},
	{
			"value": "SS 5/8  DIA. 48-84",
			"label": "SS 5/8  DIA. 48-84"
	},
	{
			"value": "SS 5/8  DIA.  84-12",
			"label": "SS 5/8  DIA.  84-12"
	},
	{
			"value": "SS HOLDBACK",
			"label": "SS HOLDBACK"
	},
	{
			"value": "STAMP",
			"label": "STAMP"
	},
	{
			"value": " STANDARD 1  , 8.5",
			"label": " STANDARD 1  , 8.5"
	},
	{
			"value": "STAR",
			"label": "STAR"
	},
	{
			"value": "STATUARY",
			"label": "STATUARY"
	},
	{
			"value": "STICKS",
			"label": "STICKS"
	},
	{
			"value": "STIRRERS",
			"label": "STIRRERS"
	},
	{
			"value": "STK7",
			"label": "STK7"
	},
	{
			"value": "STK AMERICANA",
			"label": "STK AMERICANA"
	},
	{
			"value": "STK BLK FRI TREES",
			"label": "STK BLK FRI TREES"
	},
	{
			"value": "STK EASTER",
			"label": "STK EASTER"
	},
	{
			"value": "STK EASTER BASKETS",
			"label": "STK EASTER BASKETS"
	},
	{
			"value": "STK ENTERTAINING",
			"label": "STK ENTERTAINING"
	},
	{
			"value": "STK LOF",
			"label": "STK LOF"
	},
	{
			"value": "STK OUTDOOR RUGS",
			"label": "STK OUTDOOR RUGS"
	},
	{
			"value": "STK PET",
			"label": "STK PET"
	},
	{
			"value": "STK PORCH",
			"label": "STK PORCH"
	},
	{
			"value": "STK ST PATS",
			"label": "STK ST PATS"
	},
	{
			"value": "STK TABLETOP",
			"label": "STK TABLETOP"
	},
	{
			"value": "STK UMBRELLA",
			"label": "STK UMBRELLA"
	},
	{
			"value": "STK UMBRELLAS/TIKI T",
			"label": "STK UMBRELLAS/TIKI T"
	},
	{
			"value": "STK VAL",
			"label": "STK VAL"
	},
	{
			"value": "STK VINTAGE",
			"label": "STK VINTAGE"
	},
	{
			"value": "STOCKING HOLDERS",
			"label": "STOCKING HOLDERS"
	},
	{
			"value": "STOCKINGS",
			"label": "STOCKINGS"
	},
	{
			"value": "STRAWBERRY",
			"label": "STRAWBERRY"
	},
	{
			"value": "STRAWS",
			"label": "STRAWS"
	},
	{
			"value": "STRING LIGHT",
			"label": "STRING LIGHT"
	},
	{
			"value": "STRING LIGHTS",
			"label": "STRING LIGHTS"
	},
	{
			"value": "STRIPE",
			"label": "STRIPE"
	},
	{
			"value": "STUFFED CRAFT",
			"label": "STUFFED CRAFT"
	},
	{
			"value": "SUGAR",
			"label": "SUGAR"
	},
	{
			"value": "SUGAR PEARLS",
			"label": "SUGAR PEARLS"
	},
	{
			"value": "SUPER",
			"label": "SUPER"
	},
	{
			"value": "SUSSEX  36-66 1 1/8",
			"label": "SUSSEX  36-66 1 1/8"
	},
	{
			"value": "SUSSEX 66-120 1 1/8",
			"label": "SUSSEX 66-120 1 1/8"
	},
	{
			"value": "SUSSEX HOLDBACK FOR",
			"label": "SUSSEX HOLDBACK FOR"
	},
	{
			"value": "SWISS DOT",
			"label": "SWISS DOT"
	},
	{
			"value": "TABLETOP TREES",
			"label": "TABLETOP TREES"
	},
	{
			"value": "TAPERS",
			"label": "TAPERS"
	},
	{
			"value": "TBD",
			"label": "TBD"
	},
	{
			"value": "TEALIGHTS",
			"label": "TEALIGHTS"
	},
	{
			"value": "TEIN 19X25",
			"label": "TEIN 19X25"
	},
	{
			"value": "TESTER",
			"label": "TESTER"
	},
	{
			"value": "TEX 50",
			"label": "TEX 50"
	},
	{
			"value": "TEXTILES",
			"label": "TEXTILES"
	},
	{
			"value": "TEXTURED",
			"label": "TEXTURED"
	},
	{
			"value": "THEMED GIFTBAGS",
			"label": "THEMED GIFTBAGS"
	},
	{
			"value": "THEMED OSO",
			"label": "THEMED OSO"
	},
	{
			"value": "THEMED SHATTERPROOF",
			"label": "THEMED SHATTERPROOF"
	},
	{
			"value": "THEME GIFTWRAP",
			"label": "THEME GIFTWRAP"
	},
	{
			"value": "THROWS",
			"label": "THROWS"
	},
	{
			"value": "TIE DYE",
			"label": "TIE DYE"
	},
	{
			"value": "TILE 12X12",
			"label": "TILE 12X12"
	},
	{
			"value": "TILE 14X14",
			"label": "TILE 14X14"
	},
	{
			"value": "TILE 4X4",
			"label": "TILE 4X4"
	},
	{
			"value": "TILE 6X6",
			"label": "TILE 6X6"
	},
	{
			"value": "TINS",
			"label": "TINS"
	},
	{
			"value": "TINSEL",
			"label": "TINSEL"
	},
	{
			"value": "TIN STEEL",
			"label": "TIN STEEL"
	},
	{
			"value": "TISSUE",
			"label": "TISSUE"
	},
	{
			"value": "TODDLER",
			"label": "TODDLER"
	},
	{
			"value": "TOP / VEST",
			"label": "TOP / VEST"
	},
	{
			"value": "TORY",
			"label": "TORY"
	},
	{
			"value": "TOWELS",
			"label": "TOWELS"
	},
	{
			"value": "TOWERA00",
			"label": "TOWERA00"
	},
	{
			"value": "TRAY RECT",
			"label": "TRAY RECT"
	},
	{
			"value": "TRAY ROUND",
			"label": "TRAY ROUND"
	},
	{
			"value": "TREAT",
			"label": "TREAT"
	},
	{
			"value": "TREE COLLARS",
			"label": "TREE COLLARS"
	},
	{
			"value": "TREE SKIRTS",
			"label": "TREE SKIRTS"
	},
	{
			"value": "TREE TOPPER",
			"label": "TREE TOPPER"
	},
	{
			"value": "TREE TOPPER BOW",
			"label": "TREE TOPPER BOW"
	},
	{
			"value": "TRICOLOR",
			"label": "TRICOLOR"
	},
	{
			"value": "TRIM UPGRADE",
			"label": "TRIM UPGRADE"
	},
	{
			"value": "TUB",
			"label": "TUB"
	},
	{
			"value": "TWIN",
			"label": "TWIN"
	},
	{
			"value": "TWIN - 62  X86",
			"label": "TWIN - 62  X86"
	},
	{
			"value": "TWIN 76  X88",
			"label": "TWIN 76  X88"
	},
	{
			"value": "TWIN XL",
			"label": "TWIN XL"
	},
	{
			"value": "U5 (16-18-20-22-24)",
			"label": "U5 (16-18-20-22-24)"
	},
	{
			"value": "ULTRA",
			"label": "ULTRA"
	},
	{
			"value": "UNSC",
			"label": "UNSC"
	},
	{
			"value": "UU (16-18-20-22)",
			"label": "UU (16-18-20-22)"
	},
	{
			"value": "VAL/ST PATS PORCH",
			"label": "VAL/ST PATS PORCH"
	},
	{
			"value": "VALUE",
			"label": "VALUE"
	},
	{
			"value": "VALUE PACK",
			"label": "VALUE PACK"
	},
	{
			"value": "VALUE PACK OF (3) 1",
			"label": "VALUE PACK OF (3) 1"
	},
	{
			"value": "VANILLA",
			"label": "VANILLA"
	},
	{
			"value": "VARIES",
			"label": "VARIES"
	},
	{
			"value": "VARIOUS",
			"label": "VARIOUS"
	},
	{
			"value": "VELTEX",
			"label": "VELTEX"
	},
	{
			"value": "VELVET",
			"label": "VELVET"
	},
	{
			"value": "VELVET 3/8\"\"",
			"label": "VELVET 3/8\"\""
	},
	{
			"value": "VINTAGE",
			"label": "VINTAGE"
	},
	{
			"value": "VOTIVE",
			"label": "VOTIVE"
	},
	{
			"value": "VO VOTIVE",
			"label": "VO VOTIVE"
	},
	{
			"value": "W2 (20W-22W-24W-26W-",
			"label": "W2 (20W-22W-24W-26W-"
	},
	{
			"value": "W3 (30W-32W-34W-36W-",
			"label": "W3 (30W-32W-34W-36W-"
	},
	{
			"value": "WALL DÉCOR",
			"label": "WALL DÉCOR"
	},
	{
			"value": "WALLET SIZE",
			"label": "WALLET SIZE"
	},
	{
			"value": "WAND",
			"label": "WAND"
	},
	{
			"value": "WARMERS",
			"label": "WARMERS"
	},
	{
			"value": "WAX MELT",
			"label": "WAX MELT"
	},
	{
			"value": "WD BASIC",
			"label": "WD BASIC"
	},
	{
			"value": "WD FIGURAL",
			"label": "WD FIGURAL"
	},
	{
			"value": "WD FUNCTIONAL",
			"label": "WD FUNCTIONAL"
	},
	{
			"value": "WHITE",
			"label": "WHITE"
	},
	{
			"value": "WHITE  TEA",
			"label": "WHITE  TEA"
	},
	{
			"value": "WINDCHIMES",
			"label": "WINDCHIMES"
	},
	{
			"value": "WINE",
			"label": "WINE"
	},
	{
			"value": "WISH",
			"label": "WISH"
	},
	{
			"value": "WMN(XXL-1X-2X-3X-4X-",
			"label": "WMN(XXL-1X-2X-3X-4X-"
	},
	{
			"value": "WORD BLOCK",
			"label": "WORD BLOCK"
	},
	{
			"value": "WORD BLOCKS",
			"label": "WORD BLOCKS"
	},
	{
			"value": "WORDS ONLY",
			"label": "WORDS ONLY"
	},
	{
			"value": "WRAP ACCESSORIES",
			"label": "WRAP ACCESSORIES"
	},
	{
			"value": "X46 3-UP",
			"label": "X46 3-UP"
	},
	{
			"value": "XL",
			"label": "XL"
	},
	{
			"value": "XL 11X14",
			"label": "XL 11X14"
	},
	{
			"value": "XL 14X17",
			"label": "XL 14X17"
	},
	{
			"value": "XL 18X24",
			"label": "XL 18X24"
	},
	{
			"value": "XL 5X8",
			"label": "XL 5X8"
	},
	{
			"value": "XL 7X10",
			"label": "XL 7X10"
	},
	{
			"value": "XL 9X12",
			"label": "XL 9X12"
	},
	{
			"value": "X LARGE",
			"label": "X LARGE"
	},
	{
			"value": "X-LARGE",
			"label": "X-LARGE"
	},
	{
			"value": "XLARGE",
			"label": "XLARGE"
	},
	{
			"value": "XLG(20-22)",
			"label": "XLG(20-22)"
	},
	{
			"value": "XLG-XXL-XXXL",
			"label": "XLG-XXL-XXXL"
	},
	{
			"value": "XL ORNAMENTS",
			"label": "XL ORNAMENTS"
	},
	{
			"value": "XM(SML-MED-LRG)",
			"label": "XM(SML-MED-LRG)"
	},
	{
			"value": "XN(XLG-XXL-XXXL)",
			"label": "XN(XLG-XXL-XXXL)"
	},
	{
			"value": "XS",
			"label": "XS"
	},
	{
			"value": "XSM(4-6)",
			"label": "XSM(4-6)"
	},
	{
			"value": "X-SMALL",
			"label": "X-SMALL"
	},
	{
			"value": "XSMALL",
			"label": "XSMALL"
	},
	{
			"value": "XXL",
			"label": "XXL"
	},
	{
			"value": "XXL(50-52)",
			"label": "XXL(50-52)"
	},
	{
			"value": "XXS",
			"label": "XXS"
	},
	{
			"value": "XXX",
			"label": "XXX"
	},
	{
			"value": "XY(SML-MED-LRG)",
			"label": "XY(SML-MED-LRG)"
	},
	{
			"value": "XZ(XLG-XXL)",
			"label": "XZ(XLG-XXL)"
	},
	{
			"value": "Y5 (18-20-22-24-26)",
			"label": "Y5 (18-20-22-24-26)"
	},
	{
			"value": "Y5(18-20-22-24-26)",
			"label": "Y5(18-20-22-24-26)"
	},
	{
			"value": "YA5(NBN-SML-MED-LRG-",
			"label": "YA5(NBN-SML-MED-LRG-"
	},
	{
			"value": "YARDS",
			"label": "YARDS"
	},
	{
			"value": "YARD STAKES",
			"label": "YARD STAKES"
	},
	{
			"value": "YELLOW",
			"label": "YELLOW"
	},
	{
			"value": "Y(XSM-SML-MED)",
			"label": "Y(XSM-SML-MED)"
	},
	{
			"value": "Z(LRG-XLG)",
			"label": "Z(LRG-XLG)"
	},
	{
			"value": "ZZ(LRG-XLG-XXL)",
			"label": "ZZ(LRG-XLG-XXL)"
	}
]

const ATTRIBUTE_THEME_OPTIONS = [
	// {
	// 		"value": "-",
	// 		"label": "-"
	// },
	{
			"value": "$159.99 CUSHIONS",
			"label": "$159.99 CUSHIONS"
	},
	{
			"value": "$59.99 CUSHIONS",
			"label": "$59.99 CUSHIONS"
	},
	{
			"value": "$7.99 MINI WREATHS",
			"label": "$7.99 MINI WREATHS"
	},
	{
			"value": "$9.99 MINI WREATHS",
			"label": "$9.99 MINI WREATHS"
	},
	{
			"value": "$ WALL DÉCOR",
			"label": "$ WALL DÉCOR"
	},
	{
			"value": "0",
			"label": "0"
	},
	{
			"value": "0.026875408",
			"label": "0.026875408"
	},
	{
			"value": "0.02807464",
			"label": "0.02807464"
	},
	{
			"value": "-0.113717986",
			"label": "-0.113717986"
	},
	{
			"value": "0.11677289",
			"label": "0.11677289"
	},
	{
			"value": "-0.117009132",
			"label": "-0.117009132"
	},
	{
			"value": "0.13692286",
			"label": "0.13692286"
	},
	{
			"value": "0.187671233",
			"label": "0.187671233"
	},
	{
			"value": "0.194953233",
			"label": "0.194953233"
	},
	{
			"value": "-0.196541787",
			"label": "-0.196541787"
	},
	{
			"value": "-0.315323996",
			"label": "-0.315323996"
	},
	{
			"value": "0.352765957",
			"label": "0.352765957"
	},
	{
			"value": "0.353880765",
			"label": "0.353880765"
	},
	{
			"value": "0.354340071",
			"label": "0.354340071"
	},
	{
			"value": "0.361852861",
			"label": "0.361852861"
	},
	{
			"value": "0.364414281",
			"label": "0.364414281"
	},
	{
			"value": "0.407515528",
			"label": "0.407515528"
	},
	{
			"value": "0.470424306",
			"label": "0.470424306"
	},
	{
			"value": "0.483646114",
			"label": "0.483646114"
	},
	{
			"value": "-0.527690892",
			"label": "-0.527690892"
	},
	{
			"value": "0.542116631",
			"label": "0.542116631"
	},
	{
			"value": "0.55018019",
			"label": "0.55018019"
	},
	{
			"value": "-0.571506849",
			"label": "-0.571506849"
	},
	{
			"value": "-0.573059361",
			"label": "-0.573059361"
	},
	{
			"value": "0.577816733",
			"label": "0.577816733"
	},
	{
			"value": "0.687486856",
			"label": "0.687486856"
	},
	{
			"value": "-0.772458172",
			"label": "-0.772458172"
	},
	{
			"value": "0.841547918",
			"label": "0.841547918"
	},
	{
			"value": "0.862125341",
			"label": "0.862125341"
	},
	{
			"value": "1",
			"label": "1"
	},
	{
			"value": "  10,000",
			"label": "  10,000"
	},
	{
			"value": "1000CT BEAD BAG",
			"label": "1000CT BEAD BAG"
	},
	{
			"value": "1000FT MACRAME CORD",
			"label": "1000FT MACRAME CORD"
	},
	{
			"value": "1000PC PUZZLE",
			"label": "1000PC PUZZLE"
	},
	{
			"value": "100% COTTON",
			"label": "100% COTTON"
	},
	{
			"value": "100CT EURO",
			"label": "100CT EURO"
	},
	{
			"value": "100CT STRING",
			"label": "100CT STRING"
	},
	{
			"value": "100FT MACRAME CORD",
			"label": "100FT MACRAME CORD"
	},
	{
			"value": "100 MM",
			"label": "100 MM"
	},
	{
			"value": "100MM 9PCS",
			"label": "100MM 9PCS"
	},
	{
			"value": "100MM CMAS BALL",
			"label": "100MM CMAS BALL"
	},
	{
			"value": "100MM SHATTERPROOF",
			"label": "100MM SHATTERPROOF"
	},
	{
			"value": "100MM WATERGLOBE",
			"label": "100MM WATERGLOBE"
	},
	{
			"value": "100PC PUZZLE",
			"label": "100PC PUZZLE"
	},
	{
			"value": "100PK TEALIGHT",
			"label": "100PK TEALIGHT"
	},
	{
			"value": "100YD MACRAME CORD",
			"label": "100YD MACRAME CORD"
	},
	{
			"value": "10-12 COUNT",
			"label": "10-12 COUNT"
	},
	{
			"value": "108IN SOLID",
			"label": "108IN SOLID"
	},
	{
			"value": "10HALF DECO",
			"label": "10HALF DECO"
	},
	{
			"value": "10 INCH",
			"label": "10 INCH"
	},
	{
			"value": "10INCH",
			"label": "10INCH"
	},
	{
			"value": "10 INCH DECO",
			"label": "10 INCH DECO"
	},
	{
			"value": "10IN DECO",
			"label": "10IN DECO"
	},
	{
			"value": "10IN DECO MESH",
			"label": "10IN DECO MESH"
	},
	{
			"value": "10 IN NUTCRACKER",
			"label": "10 IN NUTCRACKER"
	},
	{
			"value": "10MM BELL",
			"label": "10MM BELL"
	},
	{
			"value": "10MM POM",
			"label": "10MM POM"
	},
	{
			"value": "10OZ",
			"label": "10OZ"
	},
	{
			"value": "10 OZ GOLD CANDLE",
			"label": "10 OZ GOLD CANDLE"
	},
	{
			"value": "10 OZ GREEN CANDLE",
			"label": "10 OZ GREEN CANDLE"
	},
	{
			"value": "10 OZ IVORY CANDLE",
			"label": "10 OZ IVORY CANDLE"
	},
	{
			"value": "10 OZ JAR",
			"label": "10 OZ JAR"
	},
	{
			"value": "10 OZ RED CANDLE",
			"label": "10 OZ RED CANDLE"
	},
	{
			"value": "10 PACK",
			"label": "10 PACK"
	},
	{
			"value": "10PC 40MM TUBES",
			"label": "10PC 40MM TUBES"
	},
	{
			"value": "10PK 10IN TAPER",
			"label": "10PK 10IN TAPER"
	},
	{
			"value": "10PK TEALIGHT",
			"label": "10PK TEALIGHT"
	},
	{
			"value": "10\"\" PLATE",
			"label": "10\"\" PLATE"
	},
	{
			"value": "10\"\" SQUARES",
			"label": "10\"\" SQUARES"
	},
	{
			"value": "  12,000",
			"label": "  12,000"
	},
	{
			"value": "120MM 3PCS",
			"label": "120MM 3PCS"
	},
	{
			"value": "120MM SHATTERPROOF",
			"label": "120MM SHATTERPROOF"
	},
	{
			"value": "12INCH",
			"label": "12INCH"
	},
	{
			"value": "12IN TRIANGLE",
			"label": "12IN TRIANGLE"
	},
	{
			"value": "12OZ",
			"label": "12OZ"
	},
	{
			"value": "12OZ CANDLE",
			"label": "12OZ CANDLE"
	},
	{
			"value": "12OZ FARMHOUSE",
			"label": "12OZ FARMHOUSE"
	},
	{
			"value": "12OZ FARMHOUSE TIN",
			"label": "12OZ FARMHOUSE TIN"
	},
	{
			"value": "12OZ JAR",
			"label": "12OZ JAR"
	},
	{
			"value": "12OZ WOOD WICK",
			"label": "12OZ WOOD WICK"
	},
	{
			"value": "12PK TEA LIGHTS",
			"label": "12PK TEA LIGHTS"
	},
	{
			"value": "12TH NIGHT",
			"label": "12TH NIGHT"
	},
	{
			"value": "12X10 EP",
			"label": "12X10 EP"
	},
	{
			"value": "12X10 EP2",
			"label": "12X10 EP2"
	},
	{
			"value": "12X10 EP3",
			"label": "12X10 EP3"
	},
	{
			"value": "12X10 EP BUNDLE",
			"label": "12X10 EP BUNDLE"
	},
	{
			"value": "12X15 LONG PILE",
			"label": "12X15 LONG PILE"
	},
	{
			"value": "12X18 FANCI",
			"label": "12X18 FANCI"
	},
	{
			"value": "12X18 FOAM PACK",
			"label": "12X18 FOAM PACK"
	},
	{
			"value": "12 X 18 FOAM SHEETS",
			"label": "12 X 18 FOAM SHEETS"
	},
	{
			"value": "13.5 OZ JAR",
			"label": "13.5 OZ JAR"
	},
	{
			"value": "13OZ AROMATHERAPY",
			"label": "13OZ AROMATHERAPY"
	},
	{
			"value": "13OZ JAR",
			"label": "13OZ JAR"
	},
	{
			"value": "14IN LED LANTERN",
			"label": "14IN LED LANTERN"
	},
	{
			"value": "14 IN WITH CANDLE",
			"label": "14 IN WITH CANDLE"
	},
	{
			"value": "14OZ",
			"label": "14OZ"
	},
	{
			"value": "14OZ CANDLE",
			"label": "14OZ CANDLE"
	},
	{
			"value": "14OZ CORE",
			"label": "14OZ CORE"
	},
	{
			"value": "14 OZ JAR",
			"label": "14 OZ JAR"
	},
	{
			"value": "14OZ JAR",
			"label": "14OZ JAR"
	},
	{
			"value": "14 OZ JAR CANDLE",
			"label": "14 OZ JAR CANDLE"
	},
	{
			"value": "14OZ Q3",
			"label": "14OZ Q3"
	},
	{
			"value": "14OZ SINGLE JAR",
			"label": "14OZ SINGLE JAR"
	},
	{
			"value": "14OZ TRIPLE JAR",
			"label": "14OZ TRIPLE JAR"
	},
	{
			"value": "  15,000",
			"label": "  15,000"
	},
	{
			"value": "1500PC PUZZLE",
			"label": "1500PC PUZZLE"
	},
	{
			"value": "150 MM",
			"label": "150 MM"
	},
	{
			"value": "150MM SHATTERPROOF",
			"label": "150MM SHATTERPROOF"
	},
	{
			"value": "150MM WATERGLOBE",
			"label": "150MM WATERGLOBE"
	},
	{
			"value": "1.5IN BELL",
			"label": "1.5IN BELL"
	},
	{
			"value": "1.5 INCH",
			"label": "1.5 INCH"
	},
	{
			"value": "15 IN NUTCRACKER",
			"label": "15 IN NUTCRACKER"
	},
	{
			"value": "1.5IN POM",
			"label": "1.5IN POM"
	},
	{
			"value": "15\"\" NUTCRACKER",
			"label": "15\"\" NUTCRACKER"
	},
	{
			"value": "15OZ CANDLE",
			"label": "15OZ CANDLE"
	},
	{
			"value": "15OZ JAR",
			"label": "15OZ JAR"
	},
	{
			"value": "1.5OZ JAR",
			"label": "1.5OZ JAR"
	},
	{
			"value": "1.5 POM",
			"label": "1.5 POM"
	},
	{
			"value": "162FT MACRAME CORD",
			"label": "162FT MACRAME CORD"
	},
	{
			"value": "1687961",
			"label": "1687961"
	},
	{
			"value": "16MM BELL",
			"label": "16MM BELL"
	},
	{
			"value": "16OZ",
			"label": "16OZ"
	},
	{
			"value": "16OZ JAR",
			"label": "16OZ JAR"
	},
	{
			"value": "16OZ PLASTIC",
			"label": "16OZ PLASTIC"
	},
	{
			"value": "17IN LED LANTERN",
			"label": "17IN LED LANTERN"
	},
	{
			"value": "17OZ STAINLESS",
			"label": "17OZ STAINLESS"
	},
	{
			"value": "18OZ CORE",
			"label": "18OZ CORE"
	},
	{
			"value": "18OZ Q3",
			"label": "18OZ Q3"
	},
	{
			"value": "18OZ SINGLE JAR",
			"label": "18OZ SINGLE JAR"
	},
	{
			"value": "18OZ STAINLESS",
			"label": "18OZ STAINLESS"
	},
	{
			"value": "18OZ TRIPLE JAR",
			"label": "18OZ TRIPLE JAR"
	},
	{
			"value": "18OZ VALUE JAR",
			"label": "18OZ VALUE JAR"
	},
	{
			"value": "1/8TH INCH",
			"label": "1/8TH INCH"
	},
	{
			"value": "18X18 PILLOW",
			"label": "18X18 PILLOW"
	},
	{
			"value": "1930S",
			"label": "1930S"
	},
	{
			"value": "19OZ WOOD WICK",
			"label": "19OZ WOOD WICK"
	},
	{
			"value": "1   BUFFALO",
			"label": "1   BUFFALO"
	},
	{
			"value": "1IN BELL",
			"label": "1IN BELL"
	},
	{
			"value": "1IN POM",
			"label": "1IN POM"
	},
	{
			"value": "1PK CRYSTALS",
			"label": "1PK CRYSTALS"
	},
	{
			"value": "1YD PRECUT",
			"label": "1YD PRECUT"
	},
	{
			"value": "2000PC PUZZLE",
			"label": "2000PC PUZZLE"
	},
	{
			"value": "200 MM",
			"label": "200 MM"
	},
	{
			"value": "201012",
			"label": "201012"
	},
	{
			"value": "201026",
			"label": "201026"
	},
	{
			"value": "2014 STRANDS",
			"label": "2014 STRANDS"
	},
	{
			"value": "20CT LED",
			"label": "20CT LED"
	},
	{
			"value": "20MM BELL",
			"label": "20MM BELL"
	},
	{
			"value": "20OZ",
			"label": "20OZ"
	},
	{
			"value": "20OZ STAINLESS",
			"label": "20OZ STAINLESS"
	},
	{
			"value": "20 OZ TUMBLER",
			"label": "20 OZ TUMBLER"
	},
	{
			"value": "2.180228137",
			"label": "2.180228137"
	},
	{
			"value": "21 INCH",
			"label": "21 INCH"
	},
	{
			"value": "21 INCH DECO",
			"label": "21 INCH DECO"
	},
	{
			"value": "21IN DECO",
			"label": "21IN DECO"
	},
	{
			"value": "21IN DECO MESH",
			"label": "21IN DECO MESH"
	},
	{
			"value": "22MM BELL",
			"label": "22MM BELL"
	},
	{
			"value": "22OZ PLASTIC",
			"label": "22OZ PLASTIC"
	},
	{
			"value": "23OZ CANDLE",
			"label": "23OZ CANDLE"
	},
	{
			"value": "23OZ JAR",
			"label": "23OZ JAR"
	},
	{
			"value": "24-25 COUNT",
			"label": "24-25 COUNT"
	},
	{
			"value": "24 COUNT WALL CALEND",
			"label": "24 COUNT WALL CALEND"
	},
	{
			"value": "24\"\" NUTCRACKER",
			"label": "24\"\" NUTCRACKER"
	},
	{
			"value": "24OZ PLASTIC",
			"label": "24OZ PLASTIC"
	},
	{
			"value": "24PK LED TEALIGHT",
			"label": "24PK LED TEALIGHT"
	},
	{
			"value": "24PK TEALIGHT",
			"label": "24PK TEALIGHT"
	},
	{
			"value": "24 X 24 PILLOW",
			"label": "24 X 24 PILLOW"
	},
	{
			"value": "24X24 PILLOW",
			"label": "24X24 PILLOW"
	},
	{
			"value": "24 X 24 PILLOW IL",
			"label": "24 X 24 PILLOW IL"
	},
	{
			"value": "250FT MACRAME CORD",
			"label": "250FT MACRAME CORD"
	},
	{
			"value": "2.5 INCH",
			"label": "2.5 INCH"
	},
	{
			"value": ".25IN POM",
			"label": ".25IN POM"
	},
	{
			"value": "25MM MINI TUBE",
			"label": "25MM MINI TUBE"
	},
	{
			"value": "25MM POM",
			"label": "25MM POM"
	},
	{
			"value": "25\"\" NUTCRACKER",
			"label": "25\"\" NUTCRACKER"
	},
	{
			"value": "25OZ 3 WICK",
			"label": "25OZ 3 WICK"
	},
	{
			"value": "2.5OZ CANDLE",
			"label": "2.5OZ CANDLE"
	},
	{
			"value": "25OZ STAINLESS",
			"label": "25OZ STAINLESS"
	},
	{
			"value": "2.5\"\" STRIPS",
			"label": "2.5\"\" STRIPS"
	},
	{
			"value": "26\"\" SANTA",
			"label": "26\"\" SANTA"
	},
	{
			"value": "28PC 80MM",
			"label": "28PC 80MM"
	},
	{
			"value": "2.99 FOAMIE",
			"label": "2.99 FOAMIE"
	},
	{
			"value": "2.99 WOOD FRAME",
			"label": "2.99 WOOD FRAME"
	},
	{
			"value": "29 IN WOOD",
			"label": "29 IN WOOD"
	},
	{
			"value": "2   BUFFALO PLAID",
			"label": "2   BUFFALO PLAID"
	},
	{
			"value": "2 FT TREE",
			"label": "2 FT TREE"
	},
	{
			"value": "2FT TREE",
			"label": "2FT TREE"
	},
	{
			"value": "2FT TREES",
			"label": "2FT TREES"
	},
	{
			"value": "2 IN 1",
			"label": "2 IN 1"
	},
	{
			"value": "2IN POM",
			"label": "2IN POM"
	},
	{
			"value": "2-OZ GLITTER",
			"label": "2-OZ GLITTER"
	},
	{
			"value": "2-OZ GLITTER CRAFT",
			"label": "2-OZ GLITTER CRAFT"
	},
	{
			"value": "2-OZ GLITTER JUMBO",
			"label": "2-OZ GLITTER JUMBO"
	},
	{
			"value": "2OZ GLITTER PARTY",
			"label": "2OZ GLITTER PARTY"
	},
	{
			"value": "2-OZ GLITTER XFINE",
			"label": "2-OZ GLITTER XFINE"
	},
	{
			"value": "2 PACK",
			"label": "2 PACK"
	},
	{
			"value": "2 PACK WELCOME LAMP",
			"label": "2 PACK WELCOME LAMP"
	},
	{
			"value": "2PC 2X3 MOTION FLAME",
			"label": "2PC 2X3 MOTION FLAME"
	},
	{
			"value": "2PC  TAPER",
			"label": "2PC  TAPER"
	},
	{
			"value": "2PC TREE",
			"label": "2PC TREE"
	},
	{
			"value": "2PK 10IN TAPER",
			"label": "2PK 10IN TAPER"
	},
	{
			"value": "2PK 12IN TAPER",
			"label": "2PK 12IN TAPER"
	},
	{
			"value": "2PK 12IN TAPERS",
			"label": "2PK 12IN TAPERS"
	},
	{
			"value": "2PK BOW",
			"label": "2PK BOW"
	},
	{
			"value": "2PK COZY SOCKS",
			"label": "2PK COZY SOCKS"
	},
	{
			"value": "2PK CRYSTALS",
			"label": "2PK CRYSTALS"
	},
	{
			"value": "2PK LED TAPER",
			"label": "2PK LED TAPER"
	},
	{
			"value": "2 PK LED VOTIVE",
			"label": "2 PK LED VOTIVE"
	},
	{
			"value": "2PK LED VOTIVE",
			"label": "2PK LED VOTIVE"
	},
	{
			"value": "2PK PILLAR SET",
			"label": "2PK PILLAR SET"
	},
	{
			"value": "2PK TAPER",
			"label": "2PK TAPER"
	},
	{
			"value": "2 PK TOWEL",
			"label": "2 PK TOWEL"
	},
	{
			"value": "2X30 TRIM",
			"label": "2X30 TRIM"
	},
	{
			"value": "2X6 MOTION FLAME",
			"label": "2X6 MOTION FLAME"
	},
	{
			"value": "2X8 MOTION FLAME",
			"label": "2X8 MOTION FLAME"
	},
	{
			"value": "2YD PRE CUT",
			"label": "2YD PRE CUT"
	},
	{
			"value": "2YD PRECUT",
			"label": "2YD PRECUT"
	},
	{
			"value": "300FT MACRAME CORD",
			"label": "300FT MACRAME CORD"
	},
	{
			"value": "300PC PUZZLE",
			"label": "300PC PUZZLE"
	},
	{
			"value": "30 IN PLUSH",
			"label": "30 IN PLUSH"
	},
	{
			"value": "30 MM",
			"label": "30 MM"
	},
	{
			"value": "30MM",
			"label": "30MM"
	},
	{
			"value": "30MM 48PCS",
			"label": "30MM 48PCS"
	},
	{
			"value": "30MM BOXED BELLS",
			"label": "30MM BOXED BELLS"
	},
	{
			"value": "30MM POM",
			"label": "30MM POM"
	},
	{
			"value": "30MM SHATTERPROOF",
			"label": "30MM SHATTERPROOF"
	},
	{
			"value": "30OZ",
			"label": "30OZ"
	},
	{
			"value": "30OZ STAINLESS",
			"label": "30OZ STAINLESS"
	},
	{
			"value": "30X15: FOIL HOLOGRAM",
			"label": "30X15: FOIL HOLOGRAM"
	},
	{
			"value": "30 X 20 FOIL",
			"label": "30 X 20 FOIL"
	},
	{
			"value": "30 X 30 KRAFT",
			"label": "30 X 30 KRAFT"
	},
	{
			"value": "30X30: KRAFT",
			"label": "30X30: KRAFT"
	},
	{
			"value": "30X45: CHEERFUL",
			"label": "30X45: CHEERFUL"
	},
	{
			"value": "30 X 45 CLASSIC",
			"label": "30 X 45 CLASSIC"
	},
	{
			"value": "30X45 THEMED",
			"label": "30X45 THEMED"
	},
	{
			"value": "35CT STRING",
			"label": "35CT STRING"
	},
	{
			"value": "36OZ",
			"label": "36OZ"
	},
	{
			"value": "36X36 FELT",
			"label": "36X36 FELT"
	},
	{
			"value": "38MM POM",
			"label": "38MM POM"
	},
	{
			"value": "3/8TH INCH",
			"label": "3/8TH INCH"
	},
	{
			"value": "3 BUGS IN A RUG",
			"label": "3 BUGS IN A RUG"
	},
	{
			"value": "3 COUNT GIFTBAG",
			"label": "3 COUNT GIFTBAG"
	},
	{
			"value": "3D",
			"label": "3D"
	},
	{
			"value": "3D APPLIQUE",
			"label": "3D APPLIQUE"
	},
	{
			"value": "3D KITS",
			"label": "3D KITS"
	},
	{
			"value": "3D + PEEL",
			"label": "3D + PEEL"
	},
	{
			"value": "3D + POSTER",
			"label": "3D + POSTER"
	},
	{
			"value": "3D PUZZLE",
			"label": "3D PUZZLE"
	},
	{
			"value": "3D WALL DECOR",
			"label": "3D WALL DECOR"
	},
	{
			"value": "3D WORD WALL DECOR",
			"label": "3D WORD WALL DECOR"
	},
	{
			"value": "3FT GRN PINE",
			"label": "3FT GRN PINE"
	},
	{
			"value": "3 FT TREE",
			"label": "3 FT TREE"
	},
	{
			"value": "3FT TREE",
			"label": "3FT TREE"
	},
	{
			"value": "3FT TREES",
			"label": "3FT TREES"
	},
	{
			"value": "3IN POM",
			"label": "3IN POM"
	},
	{
			"value": "3L",
			"label": "3L"
	},
	{
			"value": "3M",
			"label": "3M"
	},
	{
			"value": "3MM POM",
			"label": "3MM POM"
	},
	{
			"value": "3OZ JAR",
			"label": "3OZ JAR"
	},
	{
			"value": "3 PACK PUMPKINS",
			"label": "3 PACK PUMPKINS"
	},
	{
			"value": "3 PC",
			"label": "3 PC"
	},
	{
			"value": "3PC",
			"label": "3PC"
	},
	{
			"value": "3PC 120MM",
			"label": "3PC 120MM"
	},
	{
			"value": "3PC JINGLE BELL",
			"label": "3PC JINGLE BELL"
	},
	{
			"value": "3PC MOTION FLAME",
			"label": "3PC MOTION FLAME"
	},
	{
			"value": "3PC SET",
			"label": "3PC SET"
	},
	{
			"value": "3PC TREE",
			"label": "3PC TREE"
	},
	{
			"value": "3 PIECE SET",
			"label": "3 PIECE SET"
	},
	{
			"value": "3 PILLAR",
			"label": "3 PILLAR"
	},
	{
			"value": "3 PK ANISE CANDLE",
			"label": "3 PK ANISE CANDLE"
	},
	{
			"value": "3PK LED PILLAR",
			"label": "3PK LED PILLAR"
	},
	{
			"value": "3PK PILLAR SET",
			"label": "3PK PILLAR SET"
	},
	{
			"value": "3 PK PUMPKIN CANDLE",
			"label": "3 PK PUMPKIN CANDLE"
	},
	{
			"value": "3PK SACHET",
			"label": "3PK SACHET"
	},
	{
			"value": "3 PK SACHET CANDLE",
			"label": "3 PK SACHET CANDLE"
	},
	{
			"value": "3 PK SPICE CANDLE",
			"label": "3 PK SPICE CANDLE"
	},
	{
			"value": "3PK TOWEL",
			"label": "3PK TOWEL"
	},
	{
			"value": "3 STACK PUMPKINS",
			"label": "3 STACK PUMPKINS"
	},
	{
			"value": "3 TIER METAL",
			"label": "3 TIER METAL"
	},
	{
			"value": "3 TIER PLANT STAND",
			"label": "3 TIER PLANT STAND"
	},
	{
			"value": "3 TIER PLASTIC",
			"label": "3 TIER PLASTIC"
	},
	{
			"value": "3 TIER ROUND",
			"label": "3 TIER ROUND"
	},
	{
			"value": "3 WICK",
			"label": "3 WICK"
	},
	{
			"value": "3WICK",
			"label": "3WICK"
	},
	{
			"value": "3 WICK CANDLE",
			"label": "3 WICK CANDLE"
	},
	{
			"value": "3X3 CANDLE",
			"label": "3X3 CANDLE"
	},
	{
			"value": "3 X 4 BASIC CANDLE",
			"label": "3 X 4 BASIC CANDLE"
	},
	{
			"value": "3X4 EMBOSSED PILLAR",
			"label": "3X4 EMBOSSED PILLAR"
	},
	{
			"value": "3X4 EMOSSED PILLAR",
			"label": "3X4 EMOSSED PILLAR"
	},
	{
			"value": "3X4 INCLUSION",
			"label": "3X4 INCLUSION"
	},
	{
			"value": "3X4 INCLUSION PILLAR",
			"label": "3X4 INCLUSION PILLAR"
	},
	{
			"value": "3X4 LED PILLAR",
			"label": "3X4 LED PILLAR"
	},
	{
			"value": "3X4 MOTION FLAME",
			"label": "3X4 MOTION FLAME"
	},
	{
			"value": "3X4 PILLAR",
			"label": "3X4 PILLAR"
	},
	{
			"value": "3X4 SCENTED PILLAR",
			"label": "3X4 SCENTED PILLAR"
	},
	{
			"value": "3X4 SPIRAL PILLAR",
			"label": "3X4 SPIRAL PILLAR"
	},
	{
			"value": "3X4 X CANDY CANDLE",
			"label": "3X4 X CANDY CANDLE"
	},
	{
			"value": "3X4 X LED CANDLE",
			"label": "3X4 X LED CANDLE"
	},
	{
			"value": "3X4 X SPIDER CANDLE",
			"label": "3X4 X SPIDER CANDLE"
	},
	{
			"value": "3X5 METAL LANTERN",
			"label": "3X5 METAL LANTERN"
	},
	{
			"value": "3 X 6 BASIC CANDLE",
			"label": "3 X 6 BASIC CANDLE"
	},
	{
			"value": "3X6 BASIC PILLAR",
			"label": "3X6 BASIC PILLAR"
	},
	{
			"value": "3X6 EMBOSSEDD PILLAR",
			"label": "3X6 EMBOSSEDD PILLAR"
	},
	{
			"value": "3X6 EMBOSSED PILLAR",
			"label": "3X6 EMBOSSED PILLAR"
	},
	{
			"value": "3X6 INCLUSION",
			"label": "3X6 INCLUSION"
	},
	{
			"value": "3X6 INCLUSION PILLAR",
			"label": "3X6 INCLUSION PILLAR"
	},
	{
			"value": "3X6 LED PILLAR",
			"label": "3X6 LED PILLAR"
	},
	{
			"value": "3X6 MOTION FLAME",
			"label": "3X6 MOTION FLAME"
	},
	{
			"value": "3X6 PILLAR",
			"label": "3X6 PILLAR"
	},
	{
			"value": "3X6 SCENTED PILLAR",
			"label": "3X6 SCENTED PILLAR"
	},
	{
			"value": "3X6 SPIRAL PILLAR",
			"label": "3X6 SPIRAL PILLAR"
	},
	{
			"value": "3X6 X CANDY CANDLE",
			"label": "3X6 X CANDY CANDLE"
	},
	{
			"value": "3X6 X LED CANDLE",
			"label": "3X6 X LED CANDLE"
	},
	{
			"value": "3X6 X SPIDER CANDLE",
			"label": "3X6 X SPIDER CANDLE"
	},
	{
			"value": "3X7 METAL LANTERN",
			"label": "3X7 METAL LANTERN"
	},
	{
			"value": "3X8 INCLUSION PILLAR",
			"label": "3X8 INCLUSION PILLAR"
	},
	{
			"value": "3X8 MOTION FLAME",
			"label": "3X8 MOTION FLAME"
	},
	{
			"value": "3YD PRECUT",
			"label": "3YD PRECUT"
	},
	{
			"value": "400FT MACRAME CORD",
			"label": "400FT MACRAME CORD"
	},
	{
			"value": "400PC PUZZLE",
			"label": "400PC PUZZLE"
	},
	{
			"value": "400 SERIES ARTAGAIN",
			"label": "400 SERIES ARTAGAIN"
	},
	{
			"value": "40MM 10PCS",
			"label": "40MM 10PCS"
	},
	{
			"value": "40MM SHATTERPROOF",
			"label": "40MM SHATTERPROOF"
	},
	{
			"value": "40 MULTI CMAS BALL",
			"label": "40 MULTI CMAS BALL"
	},
	{
			"value": "40 X 50 LICENSE",
			"label": "40 X 50 LICENSE"
	},
	{
			"value": "40X50: LICENSED",
			"label": "40X50: LICENSED"
	},
	{
			"value": "40X75 THEMED",
			"label": "40X75 THEMED"
	},
	{
			"value": "40 X 75 TRADITIONAL",
			"label": "40 X 75 TRADITIONAL"
	},
	{
			"value": "40X90: CHEERFUL",
			"label": "40X90: CHEERFUL"
	},
	{
			"value": "42PC FIGURAL",
			"label": "42PC FIGURAL"
	},
	{
			"value": "42PC MINI 20MM",
			"label": "42PC MINI 20MM"
	},
	{
			"value": "43",
			"label": "43"
	},
	{
			"value": "45   DIGITAL",
			"label": "45   DIGITAL"
	},
	{
			"value": "48",
			"label": "48"
	},
	{
			"value": "48 40 48",
			"label": "48 40 48"
	},
	{
			"value": "48-50 COUNT",
			"label": "48-50 COUNT"
	},
	{
			"value": "48 IN NST",
			"label": "48 IN NST"
	},
	{
			"value": "48PC 30MM",
			"label": "48PC 30MM"
	},
	{
			"value": "4.99 KIT",
			"label": "4.99 KIT"
	},
	{
			"value": "4 FT TREE",
			"label": "4 FT TREE"
	},
	{
			"value": "4FT TREE",
			"label": "4FT TREE"
	},
	{
			"value": "4FT TREES",
			"label": "4FT TREES"
	},
	{
			"value": "4 INCH",
			"label": "4 INCH"
	},
	{
			"value": "4INCH",
			"label": "4INCH"
	},
	{
			"value": "4 MINI TREE",
			"label": "4 MINI TREE"
	},
	{
			"value": "4 ORNAMENT TREE",
			"label": "4 ORNAMENT TREE"
	},
	{
			"value": "4-OZ GLITTER CRAFT",
			"label": "4-OZ GLITTER CRAFT"
	},
	{
			"value": "4OZ GLITTER GLUE",
			"label": "4OZ GLITTER GLUE"
	},
	{
			"value": "4 PACK MASK CANDLE",
			"label": "4 PACK MASK CANDLE"
	},
	{
			"value": "4PC BOXED BELLS",
			"label": "4PC BOXED BELLS"
	},
	{
			"value": "4PK 10IN TAPER",
			"label": "4PK 10IN TAPER"
	},
	{
			"value": "4PK LED PILLAR",
			"label": "4PK LED PILLAR"
	},
	{
			"value": "4PK LED VOTIVE",
			"label": "4PK LED VOTIVE"
	},
	{
			"value": "4 PK NIGHT CANDLE",
			"label": "4 PK NIGHT CANDLE"
	},
	{
			"value": "4PK TAPER",
			"label": "4PK TAPER"
	},
	{
			"value": "4PKTAPER",
			"label": "4PKTAPER"
	},
	{
			"value": "4PK VOTIVE",
			"label": "4PK VOTIVE"
	},
	{
			"value": "4PK VOTIVS",
			"label": "4PK VOTIVS"
	},
	{
			"value": "4X10 SCENTED PILLAR",
			"label": "4X10 SCENTED PILLAR"
	},
	{
			"value": "4X4 BASIC PILLAR",
			"label": "4X4 BASIC PILLAR"
	},
	{
			"value": "4X4 CANDLE",
			"label": "4X4 CANDLE"
	},
	{
			"value": "4X6 OUT RUG",
			"label": "4X6 OUT RUG"
	},
	{
			"value": "4X6 SCENTED PILLAR",
			"label": "4X6 SCENTED PILLAR"
	},
	{
			"value": "4X6 SPIRAL PILLAR",
			"label": "4X6 SPIRAL PILLAR"
	},
	{
			"value": "4X8 BASIC PILLAR",
			"label": "4X8 BASIC PILLAR"
	},
	{
			"value": "4X8 CANDLE",
			"label": "4X8 CANDLE"
	},
	{
			"value": "4X8 INCLUSION",
			"label": "4X8 INCLUSION"
	},
	{
			"value": "4X8 INCLUSION PILLAR",
			"label": "4X8 INCLUSION PILLAR"
	},
	{
			"value": "4X8 PILLAR",
			"label": "4X8 PILLAR"
	},
	{
			"value": "4X8 SCENTED PILLAR",
			"label": "4X8 SCENTED PILLAR"
	},
	{
			"value": "500PC PUZZLE",
			"label": "500PC PUZZLE"
	},
	{
			"value": "505 BASTING GLUE",
			"label": "505 BASTING GLUE"
	},
	{
			"value": "50CT EURO",
			"label": "50CT EURO"
	},
	{
			"value": "50CT LED",
			"label": "50CT LED"
	},
	{
			"value": "50CT STRING",
			"label": "50CT STRING"
	},
	{
			"value": "50FT MACRAME CORD",
			"label": "50FT MACRAME CORD"
	},
	{
			"value": "50MM",
			"label": "50MM"
	},
	{
			"value": "50MM BOXED BELLS",
			"label": "50MM BOXED BELLS"
	},
	{
			"value": "50PC 60MM",
			"label": "50PC 60MM"
	},
	{
			"value": "50PK TEALIGHT",
			"label": "50PK TEALIGHT"
	},
	{
			"value": "50X60",
			"label": "50X60"
	},
	{
			"value": "52 COUNT GIFT TAGS",
			"label": "52 COUNT GIFT TAGS"
	},
	{
			"value": "52CT GIFT TAGS",
			"label": "52CT GIFT TAGS"
	},
	{
			"value": "550PC PUZZLE",
			"label": "550PC PUZZLE"
	},
	{
			"value": "56PACK",
			"label": "56PACK"
	},
	{
			"value": "57MM 50PCS",
			"label": "57MM 50PCS"
	},
	{
			"value": "57MM SHATTERPROOF",
			"label": "57MM SHATTERPROOF"
	},
	{
			"value": "58",
			"label": "58"
	},
	{
			"value": "5/8TH INCH",
			"label": "5/8TH INCH"
	},
	{
			"value": "5 AND UNDER",
			"label": "5 AND UNDER"
	},
	{
			"value": "5FT",
			"label": "5FT"
	},
	{
			"value": "5HAFL DECO",
			"label": "5HAFL DECO"
	},
	{
			"value": "5HALF DECO",
			"label": "5HALF DECO"
	},
	{
			"value": ".5IN BELL",
			"label": ".5IN BELL"
	},
	{
			"value": "5 INCH DECO",
			"label": "5 INCH DECO"
	},
	{
			"value": "5IN DECO",
			"label": "5IN DECO"
	},
	{
			"value": "5IN DECO MESH",
			"label": "5IN DECO MESH"
	},
	{
			"value": "5 IN ONE CONSTRUCTIO",
			"label": "5 IN ONE CONSTRUCTIO"
	},
	{
			"value": ".5IN POM",
			"label": ".5IN POM"
	},
	{
			"value": "5IN SNOWFLAKE CANDLE",
			"label": "5IN SNOWFLAKE CANDLE"
	},
	{
			"value": "5MM POM",
			"label": "5MM POM"
	},
	{
			"value": "5OZ AROMATHERAPY JAR",
			"label": "5OZ AROMATHERAPY JAR"
	},
	{
			"value": "5OZ AROMATHERAPY SOY",
			"label": "5OZ AROMATHERAPY SOY"
	},
	{
			"value": "5OZ AROMATHERAPY TIN",
			"label": "5OZ AROMATHERAPY TIN"
	},
	{
			"value": "5OZ JAR",
			"label": "5OZ JAR"
	},
	{
			"value": "5OZ WOOD WICK",
			"label": "5OZ WOOD WICK"
	},
	{
			"value": "5 PACK",
			"label": "5 PACK"
	},
	{
			"value": "5PK CRYSTALS",
			"label": "5PK CRYSTALS"
	},
	{
			"value": "5\"\" SQUARES",
			"label": "5\"\" SQUARES"
	},
	{
			"value": "5TH AVENUE",
			"label": "5TH AVENUE"
	},
	{
			"value": "5 TIER PLANT STAND",
			"label": "5 TIER PLANT STAND"
	},
	{
			"value": "5X5 BASIC PILLAR",
			"label": "5X5 BASIC PILLAR"
	},
	{
			"value": "5X7 OUT RUG",
			"label": "5X7 OUT RUG"
	},
	{
			"value": "  6,000",
			"label": "  6,000"
	},
	{
			"value": "6000CT BEAD BAG",
			"label": "6000CT BEAD BAG"
	},
	{
			"value": "60 MM",
			"label": "60 MM"
	},
	{
			"value": "60MM SHATTERPROOF",
			"label": "60MM SHATTERPROOF"
	},
	{
			"value": "62FT MACRAME CORD",
			"label": "62FT MACRAME CORD"
	},
	{
			"value": "6.5OZ JAR",
			"label": "6.5OZ JAR"
	},
	{
			"value": "6FT TREES",
			"label": "6FT TREES"
	},
	{
			"value": "6INCH",
			"label": "6INCH"
	},
	{
			"value": "6 IN GREEN CANDLE",
			"label": "6 IN GREEN CANDLE"
	},
	{
			"value": "6IN NUMBERS",
			"label": "6IN NUMBERS"
	},
	{
			"value": "6 IN RED CANDLE",
			"label": "6 IN RED CANDLE"
	},
	{
			"value": "6IN TREE CANDLE",
			"label": "6IN TREE CANDLE"
	},
	{
			"value": "6 MINI TREE",
			"label": "6 MINI TREE"
	},
	{
			"value": "6MM BELL",
			"label": "6MM BELL"
	},
	{
			"value": "6 ORNAMENT TREE",
			"label": "6 ORNAMENT TREE"
	},
	{
			"value": "6 OZ AEROSOL CANDLE",
			"label": "6 OZ AEROSOL CANDLE"
	},
	{
			"value": "6 OZ CANDLE CANDLE",
			"label": "6 OZ CANDLE CANDLE"
	},
	{
			"value": "6 OZ COTTON TWILL",
			"label": "6 OZ COTTON TWILL"
	},
	{
			"value": ".6OZ CRAFT TUBE",
			"label": ".6OZ CRAFT TUBE"
	},
	{
			"value": "6 OZ ORNG CANDLE",
			"label": "6 OZ ORNG CANDLE"
	},
	{
			"value": "6 OZ PINK CANDLE",
			"label": "6 OZ PINK CANDLE"
	},
	{
			"value": "6 OZ SPRAY",
			"label": "6 OZ SPRAY"
	},
	{
			"value": "6 OZ TEAL CANDLE",
			"label": "6 OZ TEAL CANDLE"
	},
	{
			"value": "6 OZ WHITE CANDLE",
			"label": "6 OZ WHITE CANDLE"
	},
	{
			"value": "6PC 80MM TUBES",
			"label": "6PC 80MM TUBES"
	},
	{
			"value": "6 PK BIRD",
			"label": "6 PK BIRD"
	},
	{
			"value": "6PK GLASS VOTIVE",
			"label": "6PK GLASS VOTIVE"
	},
	{
			"value": "6PK LED TEALIGHT",
			"label": "6PK LED TEALIGHT"
	},
	{
			"value": "6PK LED VOTIVE",
			"label": "6PK LED VOTIVE"
	},
	{
			"value": "6PK TEALIGHTS",
			"label": "6PK TEALIGHTS"
	},
	{
			"value": "6X4 PILLAR",
			"label": "6X4 PILLAR"
	},
	{
			"value": "6X4 SCENTED CANDLE",
			"label": "6X4 SCENTED CANDLE"
	},
	{
			"value": "6X4 SCENTED PILLAR",
			"label": "6X4 SCENTED PILLAR"
	},
	{
			"value": "6X5 SCENTED PILLAR",
			"label": "6X5 SCENTED PILLAR"
	},
	{
			"value": "6X7 EP",
			"label": "6X7 EP"
	},
	{
			"value": "6X7 EP2",
			"label": "6X7 EP2"
	},
	{
			"value": "6X8 MOTION FLAME",
			"label": "6X8 MOTION FLAME"
	},
	{
			"value": "6X9 FOAM PACK",
			"label": "6X9 FOAM PACK"
	},
	{
			"value": "70CT LED",
			"label": "70CT LED"
	},
	{
			"value": "70CT STRING",
			"label": "70CT STRING"
	},
	{
			"value": "70 MM",
			"label": "70 MM"
	},
	{
			"value": "70MM",
			"label": "70MM"
	},
	{
			"value": "70MM JINGLE BELL",
			"label": "70MM JINGLE BELL"
	},
	{
			"value": "70MM SHATTERPROOF",
			"label": "70MM SHATTERPROOF"
	},
	{
			"value": "72 IN NST",
			"label": "72 IN NST"
	},
	{
			"value": "72 PCBOXED SHATTERPR",
			"label": "72 PCBOXED SHATTERPR"
	},
	{
			"value": "7.47965812",
			"label": "7.47965812"
	},
	{
			"value": "750FT MACRAME CORD",
			"label": "750FT MACRAME CORD"
	},
	{
			"value": "750PC PUZZLE",
			"label": "750PC PUZZLE"
	},
	{
			"value": "75FT MACRAME CORD",
			"label": "75FT MACRAME CORD"
	},
	{
			"value": "7.5FT TREES",
			"label": "7.5FT TREES"
	},
	{
			"value": ".75IN BELL",
			"label": ".75IN BELL"
	},
	{
			"value": ".75IN POM",
			"label": ".75IN POM"
	},
	{
			"value": "7.5\"\" NUTCRACKER",
			"label": "7.5\"\" NUTCRACKER"
	},
	{
			"value": "7/8TH INCH",
			"label": "7/8TH INCH"
	},
	{
			"value": "7.907629428",
			"label": "7.907629428"
	},
	{
			"value": "7.99 FOAMIE",
			"label": "7.99 FOAMIE"
	},
	{
			"value": "7 FT FLOCKED",
			"label": "7 FT FLOCKED"
	},
	{
			"value": "7FT GRN PINE",
			"label": "7FT GRN PINE"
	},
	{
			"value": "7FT TREES",
			"label": "7FT TREES"
	},
	{
			"value": "7 GYPSIES",
			"label": "7 GYPSIES"
	},
	{
			"value": "7IN LED LANTERN",
			"label": "7IN LED LANTERN"
	},
	{
			"value": "7MM POM",
			"label": "7MM POM"
	},
	{
			"value": "7OZ CANDLE",
			"label": "7OZ CANDLE"
	},
	{
			"value": "7 OZ JAR CANDLE",
			"label": "7 OZ JAR CANDLE"
	},
	{
			"value": "7OZ SOLID",
			"label": "7OZ SOLID"
	},
	{
			"value": "7 OZ TIN",
			"label": "7 OZ TIN"
	},
	{
			"value": "7OZ TIN CANDLE",
			"label": "7OZ TIN CANDLE"
	},
	{
			"value": "8.011188811",
			"label": "8.011188811"
	},
	{
			"value": "80/20 BLEND",
			"label": "80/20 BLEND"
	},
	{
			"value": "80MM 28PCS",
			"label": "80MM 28PCS"
	},
	{
			"value": "80MM 6PCS",
			"label": "80MM 6PCS"
	},
	{
			"value": "80MM SHATTERPROOF",
			"label": "80MM SHATTERPROOF"
	},
	{
			"value": "8 0Z JAR",
			"label": "8 0Z JAR"
	},
	{
			"value": "8235582",
			"label": "8235582"
	},
	{
			"value": "8.5 X 11 FOAM SHEETS",
			"label": "8.5 X 11 FOAM SHEETS"
	},
	{
			"value": "8INCH",
			"label": "8INCH"
	},
	{
			"value": "8IN CHUNKY",
			"label": "8IN CHUNKY"
	},
	{
			"value": "8 MINI TREE",
			"label": "8 MINI TREE"
	},
	{
			"value": "8 ORNAMENT TREE",
			"label": "8 ORNAMENT TREE"
	},
	{
			"value": "8OZ",
			"label": "8OZ"
	},
	{
			"value": "8-OZ GLITTER",
			"label": "8-OZ GLITTER"
	},
	{
			"value": "8-OZ GLITTER CRAFT",
			"label": "8-OZ GLITTER CRAFT"
	},
	{
			"value": "8OZ JAR",
			"label": "8OZ JAR"
	},
	{
			"value": "8OZ WOODWICK",
			"label": "8OZ WOODWICK"
	},
	{
			"value": "8 PC TREE",
			"label": "8 PC TREE"
	},
	{
			"value": "8PK LED TEALIGHT",
			"label": "8PK LED TEALIGHT"
	},
	{
			"value": "8PK PILLAR SET",
			"label": "8PK PILLAR SET"
	},
	{
			"value": "9.9MM POM",
			"label": "9.9MM POM"
	},
	{
			"value": "9 FT GRN PINE",
			"label": "9 FT GRN PINE"
	},
	{
			"value": "9IN LED LANTERN",
			"label": "9IN LED LANTERN"
	},
	{
			"value": "9 IN NUTCRACKER",
			"label": "9 IN NUTCRACKER"
	},
	{
			"value": "9IN TREE CANDLE",
			"label": "9IN TREE CANDLE"
	},
	{
			"value": "9\"\" NUTCRACKER",
			"label": "9\"\" NUTCRACKER"
	},
	{
			"value": "9OZ CANDLE",
			"label": "9OZ CANDLE"
	},
	{
			"value": "9PC 100MM",
			"label": "9PC 100MM"
	},
	{
			"value": "9PK LED TEALIGHTS",
			"label": "9PK LED TEALIGHTS"
	},
	{
			"value": "9 PK PK CANDLE",
			"label": "9 PK PK CANDLE"
	},
	{
			"value": "9PK TEALIGHT",
			"label": "9PK TEALIGHT"
	},
	{
			"value": "9PK TEALIGHTS",
			"label": "9PK TEALIGHTS"
	},
	{
			"value": "9X12",
			"label": "9X12"
	},
	{
			"value": "9X12 FANCI",
			"label": "9X12 FANCI"
	},
	{
			"value": "9X12 FOAM PACK",
			"label": "9X12 FOAM PACK"
	},
	{
			"value": "9X12 LONG PILE",
			"label": "9X12 LONG PILE"
	},
	{
			"value": "9X12 PRINTED",
			"label": "9X12 PRINTED"
	},
	{
			"value": "9X12 ULTRA SOFT",
			"label": "9X12 ULTRA SOFT"
	},
	{
			"value": "9X9 EP2",
			"label": "9X9 EP2"
	},
	{
			"value": "9X9 EP3",
			"label": "9X9 EP3"
	},
	{
			"value": "9X9 EP BUNDLE",
			"label": "9X9 EP BUNDLE"
	},
	{
			"value": "A00",
			"label": "A00"
	},
	{
			"value": "ABC GIRL",
			"label": "ABC GIRL"
	},
	{
			"value": "ABC OWL FLNL",
			"label": "ABC OWL FLNL"
	},
	{
			"value": "ABRAMS PUBLISHING",
			"label": "ABRAMS PUBLISHING"
	},
	{
			"value": "ABSTRACT",
			"label": "ABSTRACT"
	},
	{
			"value": "ABSTRACT ARTISTRY",
			"label": "ABSTRACT ARTISTRY"
	},
	{
			"value": "ABSTRACT BLENDER",
			"label": "ABSTRACT BLENDER"
	},
	{
			"value": "ABSTRACT/SWIRL",
			"label": "ABSTRACT/SWIRL"
	},
	{
			"value": "ACCENT",
			"label": "ACCENT"
	},
	{
			"value": "ACCESSORIE",
			"label": "ACCESSORIE"
	},
	{
			"value": "ACCESSORIES",
			"label": "ACCESSORIES"
	},
	{
			"value": "ACCESSORY",
			"label": "ACCESSORY"
	},
	{
			"value": "ACCESSORY KIT",
			"label": "ACCESSORY KIT"
	},
	{
			"value": "ACCESSORY PACK",
			"label": "ACCESSORY PACK"
	},
	{
			"value": "ACCESSORY POUCH",
			"label": "ACCESSORY POUCH"
	},
	{
			"value": "ACCESSORY VALUE PACK",
			"label": "ACCESSORY VALUE PACK"
	},
	{
			"value": "ACCUCUT",
			"label": "ACCUCUT"
	},
	{
			"value": "ACCUQUILT",
			"label": "ACCUQUILT"
	},
	{
			"value": "A CHRISTMAS STORY",
			"label": "A CHRISTMAS STORY"
	},
	{
			"value": "ACITIVITY KITS",
			"label": "ACITIVITY KITS"
	},
	{
			"value": "ACKFELD",
			"label": "ACKFELD"
	},
	{
			"value": "ACME",
			"label": "ACME"
	},
	{
			"value": "ACORN",
			"label": "ACORN"
	},
	{
			"value": "ACORN CONES IN ACETA",
			"label": "ACORN CONES IN ACETA"
	},
	{
			"value": "ACORN/PINECONE",
			"label": "ACORN/PINECONE"
	},
	{
			"value": "ACORNS",
			"label": "ACORNS"
	},
	{
			"value": "ACORNS & LEAVES",
			"label": "ACORNS & LEAVES"
	},
	{
			"value": "ACOUSTICAL",
			"label": "ACOUSTICAL"
	},
	{
			"value": "ACRYLIC",
			"label": "ACRYLIC"
	},
	{
			"value": "ACRYLIC BEADS",
			"label": "ACRYLIC BEADS"
	},
	{
			"value": "ACRYLIC BIN",
			"label": "ACRYLIC BIN"
	},
	{
			"value": "ACRYLIC DEER",
			"label": "ACRYLIC DEER"
	},
	{
			"value": "ACRYLIC STAR",
			"label": "ACRYLIC STAR"
	},
	{
			"value": "ACRYLIC STOCKING",
			"label": "ACRYLIC STOCKING"
	},
	{
			"value": "ACRYLIC TREE",
			"label": "ACRYLIC TREE"
	},
	{
			"value": "ACRYLIC VALUE BEADS",
			"label": "ACRYLIC VALUE BEADS"
	},
	{
			"value": "ACTIVA",
			"label": "ACTIVA"
	},
	{
			"value": "ACTIVITY",
			"label": "ACTIVITY"
	},
	{
			"value": "ACTIVITY BUCKET",
			"label": "ACTIVITY BUCKET"
	},
	{
			"value": "ACTIVITY KIT",
			"label": "ACTIVITY KIT"
	},
	{
			"value": "ACTIVITY KITS",
			"label": "ACTIVITY KITS"
	},
	{
			"value": "ACTIVITY PAD",
			"label": "ACTIVITY PAD"
	},
	{
			"value": "ACUBA",
			"label": "ACUBA"
	},
	{
			"value": "ADAPTER",
			"label": "ADAPTER"
	},
	{
			"value": "ADAPTERS",
			"label": "ADAPTERS"
	},
	{
			"value": "ADD-IN",
			"label": "ADD-IN"
	},
	{
			"value": "ADHESIVE",
			"label": "ADHESIVE"
	},
	{
			"value": "ADHESIVE FOIL",
			"label": "ADHESIVE FOIL"
	},
	{
			"value": "ADHESIVE JEWELS",
			"label": "ADHESIVE JEWELS"
	},
	{
			"value": "ADHESIVE RIBBON",
			"label": "ADHESIVE RIBBON"
	},
	{
			"value": "ADHESIVES",
			"label": "ADHESIVES"
	},
	{
			"value": "ADJUSTABLE CLASP",
			"label": "ADJUSTABLE CLASP"
	},
	{
			"value": "ADORN",
			"label": "ADORN"
	},
	{
			"value": "AD-TECH",
			"label": "AD-TECH"
	},
	{
			"value": "ADULT",
			"label": "ADULT"
	},
	{
			"value": "ADULT APRON",
			"label": "ADULT APRON"
	},
	{
			"value": "ADULT BEACH",
			"label": "ADULT BEACH"
	},
	{
			"value": "ADULT CAROLER",
			"label": "ADULT CAROLER"
	},
	{
			"value": "ADULTCOLOR",
			"label": "ADULTCOLOR"
	},
	{
			"value": "ADULT COLOR BOOKS",
			"label": "ADULT COLOR BOOKS"
	},
	{
			"value": "ADULT COLORING",
			"label": "ADULT COLORING"
	},
	{
			"value": "ADULT COLORING BOOKS",
			"label": "ADULT COLORING BOOKS"
	},
	{
			"value": "ADULT GLOVE",
			"label": "ADULT GLOVE"
	},
	{
			"value": "ADULT HAT",
			"label": "ADULT HAT"
	},
	{
			"value": "ADULT HEADBAND",
			"label": "ADULT HEADBAND"
	},
	{
			"value": "ADULT MASK",
			"label": "ADULT MASK"
	},
	{
			"value": "ADULT SHOES",
			"label": "ADULT SHOES"
	},
	{
			"value": "ADULT TUTU",
			"label": "ADULT TUTU"
	},
	{
			"value": "ADVANTUS",
			"label": "ADVANTUS"
	},
	{
			"value": "ADVENT CALENDAR",
			"label": "ADVENT CALENDAR"
	},
	{
			"value": "ADVENT CALENDAR DRAW",
			"label": "ADVENT CALENDAR DRAW"
	},
	{
			"value": "ADVENTURE PATCH",
			"label": "ADVENTURE PATCH"
	},
	{
			"value": "ADVENTURE PILLOW",
			"label": "ADVENTURE PILLOW"
	},
	{
			"value": "ADVENTURE TOWEL",
			"label": "ADVENTURE TOWEL"
	},
	{
			"value": "ADVENTUR TIME",
			"label": "ADVENTUR TIME"
	},
	{
			"value": "A E NATHAN",
			"label": "A E NATHAN"
	},
	{
			"value": "AEROSOL",
			"label": "AEROSOL"
	},
	{
			"value": "A FASCINATOR",
			"label": "A FASCINATOR"
	},
	{
			"value": "AFFLUENT",
			"label": "AFFLUENT"
	},
	{
			"value": "AFFLUENT NICHE",
			"label": "AFFLUENT NICHE"
	},
	{
			"value": "AFRICA LAVENDER",
			"label": "AFRICA LAVENDER"
	},
	{
			"value": "AFRICAN AMERICAN",
			"label": "AFRICAN AMERICAN"
	},
	{
			"value": "AFRICAN AMERICAN NIC",
			"label": "AFRICAN AMERICAN NIC"
	},
	{
			"value": "AFRICAN DAISY",
			"label": "AFRICAN DAISY"
	},
	{
			"value": "AFRICAN TWIG",
			"label": "AFRICAN TWIG"
	},
	{
			"value": "AFRIC LAVENDER",
			"label": "AFRIC LAVENDER"
	},
	{
			"value": "AGAPANTHUS",
			"label": "AGAPANTHUS"
	},
	{
			"value": "AGATE",
			"label": "AGATE"
	},
	{
			"value": "AGLAONEMA",
			"label": "AGLAONEMA"
	},
	{
			"value": "AIR",
			"label": "AIR"
	},
	{
			"value": "AIR 2",
			"label": "AIR 2"
	},
	{
			"value": "AIR 2 BNDL TWILIGHT",
			"label": "AIR 2 BNDL TWILIGHT"
	},
	{
			"value": "AIR 2 BNDL WILD ROSE",
			"label": "AIR 2 BNDL WILD ROSE"
	},
	{
			"value": "AIR 2 BUNDLE",
			"label": "AIR 2 BUNDLE"
	},
	{
			"value": "AIR 2 LILAC BNDL",
			"label": "AIR 2 LILAC BNDL"
	},
	{
			"value": "AIR BUNDLE",
			"label": "AIR BUNDLE"
	},
	{
			"value": "AIR LITE",
			"label": "AIR LITE"
	},
	{
			"value": "AIRPLANE",
			"label": "AIRPLANE"
	},
	{
			"value": "AIR PLANT",
			"label": "AIR PLANT"
	},
	{
			"value": "AIRPLANT",
			"label": "AIRPLANT"
	},
	{
			"value": "ALABAMA",
			"label": "ALABAMA"
	},
	{
			"value": "ALBUM",
			"label": "ALBUM"
	},
	{
			"value": "ALBUMS",
			"label": "ALBUMS"
	},
	{
			"value": "ALEXANDER HENRY",
			"label": "ALEXANDER HENRY"
	},
	{
			"value": "ALEX TOYS",
			"label": "ALEX TOYS"
	},
	{
			"value": "ALLARY",
			"label": "ALLARY"
	},
	{
			"value": "ALLARY IMPORTS",
			"label": "ALLARY IMPORTS"
	},
	{
			"value": "ALLIUM",
			"label": "ALLIUM"
	},
	{
			"value": "ALL OCCASION",
			"label": "ALL OCCASION"
	},
	{
			"value": "ALL OVER",
			"label": "ALL OVER"
	},
	{
			"value": "ALLOVER",
			"label": "ALLOVER"
	},
	{
			"value": "ALL PURPOSE",
			"label": "ALL PURPOSE"
	},
	{
			"value": "ALL THAT GLITTERS",
			"label": "ALL THAT GLITTERS"
	},
	{
			"value": "ALOVA",
			"label": "ALOVA"
	},
	{
			"value": "ALOVA SOLIDS",
			"label": "ALOVA SOLIDS"
	},
	{
			"value": "ALPACA",
			"label": "ALPACA"
	},
	{
			"value": "ALPHA",
			"label": "ALPHA"
	},
	{
			"value": "ALPHA BEADS",
			"label": "ALPHA BEADS"
	},
	{
			"value": "ALPHABET",
			"label": "ALPHABET"
	},
	{
			"value": "ALPHABET APPLIQUES",
			"label": "ALPHABET APPLIQUES"
	},
	{
			"value": "ALPHABET EDUCATIONAL",
			"label": "ALPHABET EDUCATIONAL"
	},
	{
			"value": "ALPHABET GAME",
			"label": "ALPHABET GAME"
	},
	{
			"value": "ALPHABET & NUMBERS",
			"label": "ALPHABET & NUMBERS"
	},
	{
			"value": "ALPHABETS & NUMBERS",
			"label": "ALPHABETS & NUMBERS"
	},
	{
			"value": "ALPHABET STAMP SET",
			"label": "ALPHABET STAMP SET"
	},
	{
			"value": "ALSO ON EC#2",
			"label": "ALSO ON EC#2"
	},
	{
			"value": "ALSTROEMERIA",
			"label": "ALSTROEMERIA"
	},
	{
			"value": "ALUMINUM",
			"label": "ALUMINUM"
	},
	{
			"value": "ALUMINUM BAKEWARE",
			"label": "ALUMINUM BAKEWARE"
	},
	{
			"value": "ALUMINUM SHEET",
			"label": "ALUMINUM SHEET"
	},
	{
			"value": "AMACO",
			"label": "AMACO"
	},
	{
			"value": "AMAMTHERANTHIS",
			"label": "AMAMTHERANTHIS"
	},
	{
			"value": "AMANDA BLU",
			"label": "AMANDA BLU"
	},
	{
			"value": "AMARANTH",
			"label": "AMARANTH"
	},
	{
			"value": "AMARANTHUS",
			"label": "AMARANTHUS"
	},
	{
			"value": "AMARYLLIS",
			"label": "AMARYLLIS"
	},
	{
			"value": "AMARYS",
			"label": "AMARYS"
	},
	{
			"value": "AMARYS WRTH",
			"label": "AMARYS WRTH"
	},
	{
			"value": "AMATE STUDIOS",
			"label": "AMATE STUDIOS"
	},
	{
			"value": "AMAV KITS",
			"label": "AMAV KITS"
	},
	{
			"value": "AMERICANA",
			"label": "AMERICANA"
	},
	{
			"value": "AMERICANA BOWS",
			"label": "AMERICANA BOWS"
	},
	{
			"value": "AMERICANA BURLAP",
			"label": "AMERICANA BURLAP"
	},
	{
			"value": "AMERICANA DECO",
			"label": "AMERICANA DECO"
	},
	{
			"value": "AMERICANA FLORAL",
			"label": "AMERICANA FLORAL"
	},
	{
			"value": "AMERICANA RIBBON",
			"label": "AMERICANA RIBBON"
	},
	{
			"value": "AMERICANA SPOOLS",
			"label": "AMERICANA SPOOLS"
	},
	{
			"value": "AMERICANA SUMMER",
			"label": "AMERICANA SUMMER"
	},
	{
			"value": "AMERICAN CLASSICS CO",
			"label": "AMERICAN CLASSICS CO"
	},
	{
			"value": "AMERICAN CRAFTS",
			"label": "AMERICAN CRAFTS"
	},
	{
			"value": "AMERICAN DREAM",
			"label": "AMERICAN DREAM"
	},
	{
			"value": "AMERICAN & EFIRD",
			"label": "AMERICAN & EFIRD"
	},
	{
			"value": "AMERICAN FLAG",
			"label": "AMERICAN FLAG"
	},
	{
			"value": "AMERICAN GIRL",
			"label": "AMERICAN GIRL"
	},
	{
			"value": "AMERICAN GIRL ACCS",
			"label": "AMERICAN GIRL ACCS"
	},
	{
			"value": "AMERICAN TOMBOW",
			"label": "AMERICAN TOMBOW"
	},
	{
			"value": "AMERICAN TRADITIONAL",
			"label": "AMERICAN TRADITIONAL"
	},
	{
			"value": "AMERI-FOAM INC",
			"label": "AMERI-FOAM INC"
	},
	{
			"value": "AMER PAINT PDQ",
			"label": "AMER PAINT PDQ"
	},
	{
			"value": "AMER TULLE SPOOLS",
			"label": "AMER TULLE SPOOLS"
	},
	{
			"value": "AMINALS",
			"label": "AMINALS"
	},
	{
			"value": "AMMEE'S BABIES",
			"label": "AMMEE'S BABIES"
	},
	{
			"value": "AM PAINTER",
			"label": "AM PAINTER"
	},
	{
			"value": "AMPHIBIANS/REPTILES",
			"label": "AMPHIBIANS/REPTILES"
	},
	{
			"value": "AMSCAN",
			"label": "AMSCAN"
	},
	{
			"value": "ANCHOR",
			"label": "ANCHOR"
	},
	{
			"value": "ANCHORS",
			"label": "ANCHORS"
	},
	{
			"value": "ANCHORS BLUE",
			"label": "ANCHORS BLUE"
	},
	{
			"value": "AND FLOWERS",
			"label": "AND FLOWERS"
	},
	{
			"value": "AND STRIPES",
			"label": "AND STRIPES"
	},
	{
			"value": "ANEMONE",
			"label": "ANEMONE"
	},
	{
			"value": "ANENOME",
			"label": "ANENOME"
	},
	{
			"value": "ANGEL",
			"label": "ANGEL"
	},
	{
			"value": "ANGEL COLLECTION",
			"label": "ANGEL COLLECTION"
	},
	{
			"value": "ANGEL DECOR",
			"label": "ANGEL DECOR"
	},
	{
			"value": "ANGEL EARRINGS KIT",
			"label": "ANGEL EARRINGS KIT"
	},
	{
			"value": "ANGEL GRID",
			"label": "ANGEL GRID"
	},
	{
			"value": "ANGEL HEARTS",
			"label": "ANGEL HEARTS"
	},
	{
			"value": "ANGELS",
			"label": "ANGELS"
	},
	{
			"value": "ANGEL TABLETOP DECOR",
			"label": "ANGEL TABLETOP DECOR"
	},
	{
			"value": "ANGEL TREE TOPPER",
			"label": "ANGEL TREE TOPPER"
	},
	{
			"value": "ANGLE SHADER",
			"label": "ANGLE SHADER"
	},
	{
			"value": "ANIAML",
			"label": "ANIAML"
	},
	{
			"value": "ANIMAL",
			"label": "ANIMAL"
	},
	{
			"value": "ANIMAL BAKING CUPS",
			"label": "ANIMAL BAKING CUPS"
	},
	{
			"value": "ANIMAL - BEES",
			"label": "ANIMAL - BEES"
	},
	{
			"value": "ANIMAL / CELESTIAL",
			"label": "ANIMAL / CELESTIAL"
	},
	{
			"value": "ANIMAL EYES",
			"label": "ANIMAL EYES"
	},
	{
			"value": "ANIMAL HAT",
			"label": "ANIMAL HAT"
	},
	{
			"value": "ANIMAL ICON",
			"label": "ANIMAL ICON"
	},
	{
			"value": "ANIMAL/INSECT",
			"label": "ANIMAL/INSECT"
	},
	{
			"value": "ANIMAL MOLDS",
			"label": "ANIMAL MOLDS"
	},
	{
			"value": "ANIMAL NOSE",
			"label": "ANIMAL NOSE"
	},
	{
			"value": "ANIMAL PANS",
			"label": "ANIMAL PANS"
	},
	{
			"value": "ANIMAL PILLOW",
			"label": "ANIMAL PILLOW"
	},
	{
			"value": "ANIMAL PRINT",
			"label": "ANIMAL PRINT"
	},
	{
			"value": "ANIMAL PRINTS",
			"label": "ANIMAL PRINTS"
	},
	{
			"value": "ANIMALS",
			"label": "ANIMALS"
	},
	{
			"value": "ANIMAL SCALE",
			"label": "ANIMAL SCALE"
	},
	{
			"value": "ANIMALS/CREATURES",
			"label": "ANIMALS/CREATURES"
	},
	{
			"value": " ANIMAL ,SKIN",
			"label": " ANIMAL ,SKIN"
	},
	{
			"value": "ANIMAL SKIN",
			"label": "ANIMAL SKIN"
	},
	{
			"value": "ANIMAL SKINS",
			"label": "ANIMAL SKINS"
	},
	{
			"value": "ANIMALS SKINS",
			"label": "ANIMALS SKINS"
	},
	{
			"value": "ANIMAL TREATS",
			"label": "ANIMAL TREATS"
	},
	{
			"value": "ANIMAL TUBES",
			"label": "ANIMAL TUBES"
	},
	{
			"value": "ANIMAL WALL DECOR",
			"label": "ANIMAL WALL DECOR"
	},
	{
			"value": "ANIMATED ANIMAL",
			"label": "ANIMATED ANIMAL"
	},
	{
			"value": "ANIMATED SKELETON",
			"label": "ANIMATED SKELETON"
	},
	{
			"value": "ANIMATION",
			"label": "ANIMATION"
	},
	{
			"value": "ANIME",
			"label": "ANIME"
	},
	{
			"value": "ANIME GAMER",
			"label": "ANIME GAMER"
	},
	{
			"value": "ANIMIALS",
			"label": "ANIMIALS"
	},
	{
			"value": "ANJWECA03",
			"label": "ANJWECA03"
	},
	{
			"value": "ANKLET",
			"label": "ANKLET"
	},
	{
			"value": "ANTA BAG",
			"label": "ANTA BAG"
	},
	{
			"value": "ANTHURIUM",
			"label": "ANTHURIUM"
	},
	{
			"value": "ANTIBAC",
			"label": "ANTIBAC"
	},
	{
			"value": "ANTIBAC SPRAY",
			"label": "ANTIBAC SPRAY"
	},
	{
			"value": "ANTI FATIGUE MAT",
			"label": "ANTI FATIGUE MAT"
	},
	{
			"value": "ANTIPILL",
			"label": "ANTIPILL"
	},
	{
			"value": "ANTI PILL FLC PRINTS",
			"label": "ANTI PILL FLC PRINTS"
	},
	{
			"value": "ANTI-PILL FLEECE",
			"label": "ANTI-PILL FLEECE"
	},
	{
			"value": "ANTI PILL FLEECE PRI",
			"label": "ANTI PILL FLEECE PRI"
	},
	{
			"value": "ANTIQUES",
			"label": "ANTIQUES"
	},
	{
			"value": "ANTIQUITY",
			"label": "ANTIQUITY"
	},
	{
			"value": "ANTLER",
			"label": "ANTLER"
	},
	{
			"value": "ANW/PAPER COMPANY",
			"label": "ANW/PAPER COMPANY"
	},
	{
			"value": "APAGANTHUS",
			"label": "APAGANTHUS"
	},
	{
			"value": "APOTHECARY",
			"label": "APOTHECARY"
	},
	{
			"value": "APPAREL",
			"label": "APPAREL"
	},
	{
			"value": "APPAREL BOTTOMWEIGHT",
			"label": "APPAREL BOTTOMWEIGHT"
	},
	{
			"value": "APPAREL FASHION",
			"label": "APPAREL FASHION"
	},
	{
			"value": "APPAREL INTERFACING",
			"label": "APPAREL INTERFACING"
	},
	{
			"value": "APPAREL INTRFC",
			"label": "APPAREL INTRFC"
	},
	{
			"value": "APPETIZER PLATE",
			"label": "APPETIZER PLATE"
	},
	{
			"value": "APPLE",
			"label": "APPLE"
	},
	{
			"value": "APPLE BASKET",
			"label": "APPLE BASKET"
	},
	{
			"value": "APPLE BRY MAPLE LEAF",
			"label": "APPLE BRY MAPLE LEAF"
	},
	{
			"value": "APPLE HARVEST PINE",
			"label": "APPLE HARVEST PINE"
	},
	{
			"value": "APPLE LEAVES",
			"label": "APPLE LEAVES"
	},
	{
			"value": "APPLE/PEAR/BERRIES",
			"label": "APPLE/PEAR/BERRIES"
	},
	{
			"value": "APPLE PICKING",
			"label": "APPLE PICKING"
	},
	{
			"value": "APPLIQUE",
			"label": "APPLIQUE"
	},
	{
			"value": "APPLIQUE BOOKLET",
			"label": "APPLIQUE BOOKLET"
	},
	{
			"value": "APR",
			"label": "APR"
	},
	{
			"value": "APRIL",
			"label": "APRIL"
	},
	{
			"value": "APRILFY15RTV",
			"label": "APRILFY15RTV"
	},
	{
			"value": "APRON",
			"label": "APRON"
	},
	{
			"value": "AQUA GEMS",
			"label": "AQUA GEMS"
	},
	{
			"value": "AQUA SKY",
			"label": "AQUA SKY"
	},
	{
			"value": "AQUASTONE GROUP",
			"label": "AQUASTONE GROUP"
	},
	{
			"value": "AQUATIC ANIMALS",
			"label": "AQUATIC ANIMALS"
	},
	{
			"value": "ARBOR",
			"label": "ARBOR"
	},
	{
			"value": "ARCH",
			"label": "ARCH"
	},
	{
			"value": "ARCH BLISTER KIT",
			"label": "ARCH BLISTER KIT"
	},
	{
			"value": "ARCHITECTURE",
			"label": "ARCHITECTURE"
	},
	{
			"value": "ARCTIC FROST",
			"label": "ARCTIC FROST"
	},
	{
			"value": "ARCTIC VINYL",
			"label": "ARCTIC VINYL"
	},
	{
			"value": "ARECA PALM",
			"label": "ARECA PALM"
	},
	{
			"value": "ARGYLE PINK FLNL",
			"label": "ARGYLE PINK FLNL"
	},
	{
			"value": "ARIZONA",
			"label": "ARIZONA"
	},
	{
			"value": "ARKANSAS",
			"label": "ARKANSAS"
	},
	{
			"value": "ARKNASAS",
			"label": "ARKNASAS"
	},
	{
			"value": "ARMADA",
			"label": "ARMADA"
	},
	{
			"value": "ARMADILLO ART & CRAF",
			"label": "ARMADILLO ART & CRAF"
	},
	{
			"value": "ARMOUR PRODUCTS",
			"label": "ARMOUR PRODUCTS"
	},
	{
			"value": "ARMY",
			"label": "ARMY"
	},
	{
			"value": "AROMA ROOM SPRAY",
			"label": "AROMA ROOM SPRAY"
	},
	{
			"value": "AROMA WAX MELT",
			"label": "AROMA WAX MELT"
	},
	{
			"value": "AROUND THE BLOCK",
			"label": "AROUND THE BLOCK"
	},
	{
			"value": "AROUND THE WORLD",
			"label": "AROUND THE WORLD"
	},
	{
			"value": "A ROW",
			"label": "A ROW"
	},
	{
			"value": "ARPILFY16RTV",
			"label": "ARPILFY16RTV"
	},
	{
			"value": "ARRANGEMENT",
			"label": "ARRANGEMENT"
	},
	{
			"value": "ARRANGEMENTS",
			"label": "ARRANGEMENTS"
	},
	{
			"value": "ARRANING SUPPLIES",
			"label": "ARRANING SUPPLIES"
	},
	{
			"value": "ARRNG",
			"label": "ARRNG"
	},
	{
			"value": "ARRNG IN POT",
			"label": "ARRNG IN POT"
	},
	{
			"value": "ARROW",
			"label": "ARROW"
	},
	{
			"value": "ARROW LRG",
			"label": "ARROW LRG"
	},
	{
			"value": "ARROW POT",
			"label": "ARROW POT"
	},
	{
			"value": "ARROWS",
			"label": "ARROWS"
	},
	{
			"value": "ART",
			"label": "ART"
	},
	{
			"value": "ART BIN",
			"label": "ART BIN"
	},
	{
			"value": "ART BY NUMBERS",
			"label": "ART BY NUMBERS"
	},
	{
			"value": "ART CLAY",
			"label": "ART CLAY"
	},
	{
			"value": "ART DECO",
			"label": "ART DECO"
	},
	{
			"value": "ART & DRAWING",
			"label": "ART & DRAWING"
	},
	{
			"value": "ART GLASS",
			"label": "ART GLASS"
	},
	{
			"value": "ARTIC FROST",
			"label": "ARTIC FROST"
	},
	{
			"value": "ARTICHOKE",
			"label": "ARTICHOKE"
	},
	{
			"value": "ART IMPRESSIONS",
			"label": "ART IMPRESSIONS"
	},
	{
			"value": "ART INSTITUTE GLITTE",
			"label": "ART INSTITUTE GLITTE"
	},
	{
			"value": "ARTIST",
			"label": "ARTIST"
	},
	{
			"value": "ARTIST CANVAS",
			"label": "ARTIST CANVAS"
	},
	{
			"value": "ARTIST COLLECTIONS",
			"label": "ARTIST COLLECTIONS"
	},
	{
			"value": "ARTIST INITIATIVE",
			"label": "ARTIST INITIATIVE"
	},
	{
			"value": "ART JOURNAL",
			"label": "ART JOURNAL"
	},
	{
			"value": "ARTOGRAPH",
			"label": "ARTOGRAPH"
	},
	{
			"value": "ART QUILTS",
			"label": "ART QUILTS"
	},
	{
			"value": "ART SET",
			"label": "ART SET"
	},
	{
			"value": "ART SMOCK",
			"label": "ART SMOCK"
	},
	{
			"value": "ART SUPPLIES",
			"label": "ART SUPPLIES"
	},
	{
			"value": "ART TO HEART",
			"label": "ART TO HEART"
	},
	{
			"value": "ART TT",
			"label": "ART TT"
	},
	{
			"value": "ASIAN",
			"label": "ASIAN"
	},
	{
			"value": "ASIAN FUSION",
			"label": "ASIAN FUSION"
	},
	{
			"value": "ASIAN INSPIRED",
			"label": "ASIAN INSPIRED"
	},
	{
			"value": "ASIAN INSPIRED-AA",
			"label": "ASIAN INSPIRED-AA"
	},
	{
			"value": "ASIAN INSPIRED-BB",
			"label": "ASIAN INSPIRED-BB"
	},
	{
			"value": "ASIAN INSPIRED-CC",
			"label": "ASIAN INSPIRED-CC"
	},
	{
			"value": "ASIAN INSPIRED-EE",
			"label": "ASIAN INSPIRED-EE"
	},
	{
			"value": "ASIAN INSPIRED-GG",
			"label": "ASIAN INSPIRED-GG"
	},
	{
			"value": "ASIAN TEA",
			"label": "ASIAN TEA"
	},
	{
			"value": "ASPARAGUS",
			"label": "ASPARAGUS"
	},
	{
			"value": "ASPARAGUS FERN",
			"label": "ASPARAGUS FERN"
	},
	{
			"value": "ASSORTED",
			"label": "ASSORTED"
	},
	{
			"value": "ASSORTED BOY",
			"label": "ASSORTED BOY"
	},
	{
			"value": "ASSORTED GIRL",
			"label": "ASSORTED GIRL"
	},
	{
			"value": "ASSORTED SILKY PRINT",
			"label": "ASSORTED SILKY PRINT"
	},
	{
			"value": "ASSORTMENT",
			"label": "ASSORTMENT"
	},
	{
			"value": "ASST",
			"label": "ASST"
	},
	{
			"value": "AST COTTON POLY",
			"label": "AST COTTON POLY"
	},
	{
			"value": "AST DENIM",
			"label": "AST DENIM"
	},
	{
			"value": "ASTER",
			"label": "ASTER"
	},
	{
			"value": "ASTER MUM",
			"label": "ASTER MUM"
	},
	{
			"value": "ASTILBE",
			"label": "ASTILBE"
	},
	{
			"value": "ASTRONAUT",
			"label": "ASTRONAUT"
	},
	{
			"value": "ASY",
			"label": "ASY"
	},
	{
			"value": "ATHLEISURE",
			"label": "ATHLEISURE"
	},
	{
			"value": "ATHLETIC WEAR",
			"label": "ATHLETIC WEAR"
	},
	{
			"value": "AUBURN",
			"label": "AUBURN"
	},
	{
			"value": "AUDUBON BIRDS",
			"label": "AUDUBON BIRDS"
	},
	{
			"value": "AUG",
			"label": "AUG"
	},
	{
			"value": "AUGFY14 RTV",
			"label": "AUGFY14 RTV"
	},
	{
			"value": "AUGUST RTV",
			"label": "AUGUST RTV"
	},
	{
			"value": "AUNT MARTHA'S",
			"label": "AUNT MARTHA'S"
	},
	{
			"value": "AUNT MARTHAS",
			"label": "AUNT MARTHAS"
	},
	{
			"value": "AURORA PILLAR",
			"label": "AURORA PILLAR"
	},
	{
			"value": "AURORA PILLAR SET",
			"label": "AURORA PILLAR SET"
	},
	{
			"value": "AURORA TAPER SET",
			"label": "AURORA TAPER SET"
	},
	{
			"value": "AUTHENTIC COUNTRY",
			"label": "AUTHENTIC COUNTRY"
	},
	{
			"value": "AUTHENTIQUE PAPER",
			"label": "AUTHENTIQUE PAPER"
	},
	{
			"value": "AUTOPRESS",
			"label": "AUTOPRESS"
	},
	{
			"value": "AUTSUN - HEADER",
			"label": "AUTSUN - HEADER"
	},
	{
			"value": "AUTUMN",
			"label": "AUTUMN"
	},
	{
			"value": "AUTUMN BURLAP",
			"label": "AUTUMN BURLAP"
	},
	{
			"value": "AUTUMN CRISP",
			"label": "AUTUMN CRISP"
	},
	{
			"value": "AUTUMN EQUINOX",
			"label": "AUTUMN EQUINOX"
	},
	{
			"value": "AUTUMN LEAVES",
			"label": "AUTUMN LEAVES"
	},
	{
			"value": "AVERY",
			"label": "AVERY"
	},
	{
			"value": "AWARD PAD",
			"label": "AWARD PAD"
	},
	{
			"value": "AWARDS",
			"label": "AWARDS"
	},
	{
			"value": "AWESOME TOWEL",
			"label": "AWESOME TOWEL"
	},
	{
			"value": "AWHILE WD",
			"label": "AWHILE WD"
	},
	{
			"value": "AWL",
			"label": "AWL"
	},
	{
			"value": "AWNING",
			"label": "AWNING"
	},
	{
			"value": "AZALEA",
			"label": "AZALEA"
	},
	{
			"value": "AZTEC",
			"label": "AZTEC"
	},
	{
			"value": "AZTEC/SOUTHWEST",
			"label": "AZTEC/SOUTHWEST"
	},
	{
			"value": "AZTEC/SW",
			"label": "AZTEC/SW"
	},
	{
			"value": "AZURE",
			"label": "AZURE"
	},
	{
			"value": "AZURE COLLECTION",
			"label": "AZURE COLLECTION"
	},
	{
			"value": "BABIES & TODDLERS",
			"label": "BABIES & TODDLERS"
	},
	{
			"value": "BABTXA00",
			"label": "BABTXA00"
	},
	{
			"value": "BABY",
			"label": "BABY"
	},
	{
			"value": "BABY ACCESSORIES",
			"label": "BABY ACCESSORIES"
	},
	{
			"value": "BABY ANIMALS",
			"label": "BABY ANIMALS"
	},
	{
			"value": "BABY APPLIQUE",
			"label": "BABY APPLIQUE"
	},
	{
			"value": "BABY BEAR TOILE BLUE",
			"label": "BABY BEAR TOILE BLUE"
	},
	{
			"value": "BABY BEAR TOILE PINK",
			"label": "BABY BEAR TOILE PINK"
	},
	{
			"value": "BABY BOY",
			"label": "BABY BOY"
	},
	{
			"value": "BABY BREATH",
			"label": "BABY BREATH"
	},
	{
			"value": "BABYBREATH",
			"label": "BABYBREATH"
	},
	{
			"value": "BABY FURRIES",
			"label": "BABY FURRIES"
	},
	{
			"value": "BABY GIRL",
			"label": "BABY GIRL"
	},
	{
			"value": "BABY HAIR",
			"label": "BABY HAIR"
	},
	{
			"value": "BABY IRON ON",
			"label": "BABY IRON ON"
	},
	{
			"value": "BABY/KIDS",
			"label": "BABY/KIDS"
	},
	{
			"value": "BABY MISC",
			"label": "BABY MISC"
	},
	{
			"value": "BABY RICK RACK",
			"label": "BABY RICK RACK"
	},
	{
			"value": "BABYS",
			"label": "BABYS"
	},
	{
			"value": "BABYS BREATH",
			"label": "BABYS BREATH"
	},
	{
			"value": "BABYSBREATH",
			"label": "BABYSBREATH"
	},
	{
			"value": "BABYVILLE",
			"label": "BABYVILLE"
	},
	{
			"value": "BABYVILLE BOUTIQUE",
			"label": "BABYVILLE BOUTIQUE"
	},
	{
			"value": "BABY WALL DECOR",
			"label": "BABY WALL DECOR"
	},
	{
			"value": "BABY YODA",
			"label": "BABY YODA"
	},
	{
			"value": "BACKDROP",
			"label": "BACKDROP"
	},
	{
			"value": "BACKGROUNDS & BORDER",
			"label": "BACKGROUNDS & BORDER"
	},
	{
			"value": "BACKPACK",
			"label": "BACKPACK"
	},
	{
			"value": "BACK TO CAMPUS",
			"label": "BACK TO CAMPUS"
	},
	{
			"value": "BACK TO COLLEGE",
			"label": "BACK TO COLLEGE"
	},
	{
			"value": "BACK TO SCHOOL",
			"label": "BACK TO SCHOOL"
	},
	{
			"value": "BACK TREE",
			"label": "BACK TREE"
	},
	{
			"value": "BADGER AIR-BRUSH COM",
			"label": "BADGER AIR-BRUSH COM"
	},
	{
			"value": "BAD TO THE BONE",
			"label": "BAD TO THE BONE"
	},
	{
			"value": "BAG",
			"label": "BAG"
	},
	{
			"value": "BAG BLANK",
			"label": "BAG BLANK"
	},
	{
			"value": "BAGGED ACCESSORIES",
			"label": "BAGGED ACCESSORIES"
	},
	{
			"value": "BAGGED LETTERS",
			"label": "BAGGED LETTERS"
	},
	{
			"value": "BAGGED LVS",
			"label": "BAGGED LVS"
	},
	{
			"value": "BAGGED PETALS",
			"label": "BAGGED PETALS"
	},
	{
			"value": "BAGGED PUMPKINS",
			"label": "BAGGED PUMPKINS"
	},
	{
			"value": "BAGS",
			"label": "BAGS"
	},
	{
			"value": "BAG WORKS",
			"label": "BAG WORKS"
	},
	{
			"value": "BAIL HEAD PINS",
			"label": "BAIL HEAD PINS"
	},
	{
			"value": "BAIL HOOKS",
			"label": "BAIL HOOKS"
	},
	{
			"value": "BAIL MOUNTS",
			"label": "BAIL MOUNTS"
	},
	{
			"value": "BAIL PINS",
			"label": "BAIL PINS"
	},
	{
			"value": "BAILS",
			"label": "BAILS"
	},
	{
			"value": "BAKEWARE",
			"label": "BAKEWARE"
	},
	{
			"value": "BAKING CHIPS",
			"label": "BAKING CHIPS"
	},
	{
			"value": "BAKING CHOCOLATE",
			"label": "BAKING CHOCOLATE"
	},
	{
			"value": "BAKING COCOA",
			"label": "BAKING COCOA"
	},
	{
			"value": "BAKING CUPS",
			"label": "BAKING CUPS"
	},
	{
			"value": "BAKING TOOLS",
			"label": "BAKING TOOLS"
	},
	{
			"value": "BAKING UTENSIL",
			"label": "BAKING UTENSIL"
	},
	{
			"value": "BAL",
			"label": "BAL"
	},
	{
			"value": "BALANCE NST FOR FY13",
			"label": "BALANCE NST FOR FY13"
	},
	{
			"value": "BALANCE NST OF FY13",
			"label": "BALANCE NST OF FY13"
	},
	{
			"value": "BALE",
			"label": "BALE"
	},
	{
			"value": "BALL",
			"label": "BALL"
	},
	{
			"value": "BALL BERRIES PINE",
			"label": "BALL BERRIES PINE"
	},
	{
			"value": "BALL CHAIN",
			"label": "BALL CHAIN"
	},
	{
			"value": "BALLERINA",
			"label": "BALLERINA"
	},
	{
			"value": "BALL GARLAND",
			"label": "BALL GARLAND"
	},
	{
			"value": "BALL MUM",
			"label": "BALL MUM"
	},
	{
			"value": "BALLOON",
			"label": "BALLOON"
	},
	{
			"value": "BALLOON ARCH",
			"label": "BALLOON ARCH"
	},
	{
			"value": "BALLOONS",
			"label": "BALLOONS"
	},
	{
			"value": "BALL PEAR BERRY",
			"label": "BALL PEAR BERRY"
	},
	{
			"value": "BALL SET",
			"label": "BALL SET"
	},
	{
			"value": "BAMBOO",
			"label": "BAMBOO"
	},
	{
			"value": "BAMBOO BASKET",
			"label": "BAMBOO BASKET"
	},
	{
			"value": "BAMBOO DOWEL",
			"label": "BAMBOO DOWEL"
	},
	{
			"value": "BAMBOO ENTERTAINING",
			"label": "BAMBOO ENTERTAINING"
	},
	{
			"value": "BAMBOO FRAME",
			"label": "BAMBOO FRAME"
	},
	{
			"value": "BAMBOO LID",
			"label": "BAMBOO LID"
	},
	{
			"value": "BAMBOO LVS",
			"label": "BAMBOO LVS"
	},
	{
			"value": "BAMBOO SERVER",
			"label": "BAMBOO SERVER"
	},
	{
			"value": "BAMBOO VASE",
			"label": "BAMBOO VASE"
	},
	{
			"value": "BANANA LVS",
			"label": "BANANA LVS"
	},
	{
			"value": "BAND",
			"label": "BAND"
	},
	{
			"value": "BANDAGES",
			"label": "BANDAGES"
	},
	{
			"value": "BANDANA",
			"label": "BANDANA"
	},
	{
			"value": "BANDANA LXL",
			"label": "BANDANA LXL"
	},
	{
			"value": "BANDANA SMMED",
			"label": "BANDANA SMMED"
	},
	{
			"value": "BANDANA TRAY",
			"label": "BANDANA TRAY"
	},
	{
			"value": "BANDANNA",
			"label": "BANDANNA"
	},
	{
			"value": "BANGLE",
			"label": "BANGLE"
	},
	{
			"value": "BANKSIA",
			"label": "BANKSIA"
	},
	{
			"value": "BANNER",
			"label": "BANNER"
	},
	{
			"value": "BANSKIA",
			"label": "BANSKIA"
	},
	{
			"value": "BAR",
			"label": "BAR"
	},
	{
			"value": "BARBQUE TOWEL",
			"label": "BARBQUE TOWEL"
	},
	{
			"value": "BAR CHAIN",
			"label": "BAR CHAIN"
	},
	{
			"value": "BAR CHAIN TWISTED",
			"label": "BAR CHAIN TWISTED"
	},
	{
			"value": "BARELY THERE",
			"label": "BARELY THERE"
	},
	{
			"value": "BARETTE",
			"label": "BARETTE"
	},
	{
			"value": "BARLEY",
			"label": "BARLEY"
	},
	{
			"value": "BARN STAR",
			"label": "BARN STAR"
	},
	{
			"value": "BARREL CLASPS",
			"label": "BARREL CLASPS"
	},
	{
			"value": "BARRETTE",
			"label": "BARRETTE"
	},
	{
			"value": "BARRETTES",
			"label": "BARRETTES"
	},
	{
			"value": "BARROW",
			"label": "BARROW"
	},
	{
			"value": "BASAN",
			"label": "BASAN"
	},
	{
			"value": "BASE",
			"label": "BASE"
	},
	{
			"value": "BASEBALL",
			"label": "BASEBALL"
	},
	{
			"value": "BASEBALL CAP",
			"label": "BASEBALL CAP"
	},
	{
			"value": "BASE BRACELET",
			"label": "BASE BRACELET"
	},
	{
			"value": "BASES",
			"label": "BASES"
	},
	{
			"value": "BASIC",
			"label": "BASIC"
	},
	{
			"value": "BASIC 2 PACK",
			"label": "BASIC 2 PACK"
	},
	{
			"value": "BASIC 6 PACK",
			"label": "BASIC 6 PACK"
	},
	{
			"value": "BASIC BEAUTY",
			"label": "BASIC BEAUTY"
	},
	{
			"value": "BASIC BIRDHOUSE",
			"label": "BASIC BIRDHOUSE"
	},
	{
			"value": "BASIC BOOKS",
			"label": "BASIC BOOKS"
	},
	{
			"value": "BASICC00",
			"label": "BASICC00"
	},
	{
			"value": "BASIC CANDY POG",
			"label": "BASIC CANDY POG"
	},
	{
			"value": "BASICD00",
			"label": "BASICD00"
	},
	{
			"value": "BASIC DENIM",
			"label": "BASIC DENIM"
	},
	{
			"value": "BASICE00",
			"label": "BASICE00"
	},
	{
			"value": "BASIC ENTERTAINING",
			"label": "BASIC ENTERTAINING"
	},
	{
			"value": "BASIC FRONT END CAND",
			"label": "BASIC FRONT END CAND"
	},
	{
			"value": "BASIC GREY",
			"label": "BASIC GREY"
	},
	{
			"value": "BASIC LIGHTS",
			"label": "BASIC LIGHTS"
	},
	{
			"value": "BASIC & POM BOOKS",
			"label": "BASIC & POM BOOKS"
	},
	{
			"value": "BASIC POT",
			"label": "BASIC POT"
	},
	{
			"value": "BASICS",
			"label": "BASICS"
	},
	{
			"value": "BASIC STEM",
			"label": "BASIC STEM"
	},
	{
			"value": "BASIC STITCHERY",
			"label": "BASIC STITCHERY"
	},
	{
			"value": "BASIC WD",
			"label": "BASIC WD"
	},
	{
			"value": "BASKET",
			"label": "BASKET"
	},
	{
			"value": "BASKET ACCESSORY",
			"label": "BASKET ACCESSORY"
	},
	{
			"value": "BASKET CONE",
			"label": "BASKET CONE"
	},
	{
			"value": "BASKET HANGER",
			"label": "BASKET HANGER"
	},
	{
			"value": "BASKET HANGING",
			"label": "BASKET HANGING"
	},
	{
			"value": "BASKET HEADE",
			"label": "BASKET HEADE"
	},
	{
			"value": "BASKET HEADER",
			"label": "BASKET HEADER"
	},
	{
			"value": "BASKET LG",
			"label": "BASKET LG"
	},
	{
			"value": "BASKET LRG",
			"label": "BASKET LRG"
	},
	{
			"value": "BASKET METAL",
			"label": "BASKET METAL"
	},
	{
			"value": "BASKET MINI HANGING",
			"label": "BASKET MINI HANGING"
	},
	{
			"value": "BASKET NATRL FABRIC",
			"label": "BASKET NATRL FABRIC"
	},
	{
			"value": "BASKET NOVELTY",
			"label": "BASKET NOVELTY"
	},
	{
			"value": "BASKET PLANTER",
			"label": "BASKET PLANTER"
	},
	{
			"value": "BASKET PLANTER FIG",
			"label": "BASKET PLANTER FIG"
	},
	{
			"value": "BASKET PLANTER TBL",
			"label": "BASKET PLANTER TBL"
	},
	{
			"value": "BASKET PLANTER TT",
			"label": "BASKET PLANTER TT"
	},
	{
			"value": "BASKET PLANTER WALL",
			"label": "BASKET PLANTER WALL"
	},
	{
			"value": "BASKET PLANTER WIN",
			"label": "BASKET PLANTER WIN"
	},
	{
			"value": "BASKET PLANTER WN",
			"label": "BASKET PLANTER WN"
	},
	{
			"value": "BASKETS",
			"label": "BASKETS"
	},
	{
			"value": "BASKET SM",
			"label": "BASKET SM"
	},
	{
			"value": "BASKET TRADITIONAL",
			"label": "BASKET TRADITIONAL"
	},
	{
			"value": "BASKET TRAY",
			"label": "BASKET TRAY"
	},
	{
			"value": "BASKET WD",
			"label": "BASKET WD"
	},
	{
			"value": "BASKETWEAVE",
			"label": "BASKETWEAVE"
	},
	{
			"value": "BASKETWEAVE/RATTAN",
			"label": "BASKETWEAVE/RATTAN"
	},
	{
			"value": "BASSET DOORSTPPER",
			"label": "BASSET DOORSTPPER"
	},
	{
			"value": "BAT",
			"label": "BAT"
	},
	{
			"value": "BATE & TAYLOR",
			"label": "BATE & TAYLOR"
	},
	{
			"value": "BATH",
			"label": "BATH"
	},
	{
			"value": "BATH AND BEAUTY BASE",
			"label": "BATH AND BEAUTY BASE"
	},
	{
			"value": "BATH AND BEAUTY KIT",
			"label": "BATH AND BEAUTY KIT"
	},
	{
			"value": "BATH AND BEAUTY MOLD",
			"label": "BATH AND BEAUTY MOLD"
	},
	{
			"value": "BATH AND BEAUTY OIL",
			"label": "BATH AND BEAUTY OIL"
	},
	{
			"value": "BATHBOMB",
			"label": "BATHBOMB"
	},
	{
			"value": "BATH BOMB KIT",
			"label": "BATH BOMB KIT"
	},
	{
			"value": "BATH MAT",
			"label": "BATH MAT"
	},
	{
			"value": "BATH MOLD",
			"label": "BATH MOLD"
	},
	{
			"value": "BATH RUG",
			"label": "BATH RUG"
	},
	{
			"value": "BATH TOWEL",
			"label": "BATH TOWEL"
	},
	{
			"value": "BATIK",
			"label": "BATIK"
	},
	{
			"value": "BATIKS",
			"label": "BATIKS"
	},
	{
			"value": "BATMAN",
			"label": "BATMAN"
	},
	{
			"value": "BAT ORN",
			"label": "BAT ORN"
	},
	{
			"value": "BATS",
			"label": "BATS"
	},
	{
			"value": "BATTER",
			"label": "BATTER"
	},
	{
			"value": "BATTERY",
			"label": "BATTERY"
	},
	{
			"value": "BATTING",
			"label": "BATTING"
	},
	{
			"value": "BATTING BY THE YARD",
			"label": "BATTING BY THE YARD"
	},
	{
			"value": "BAUBLE",
			"label": "BAUBLE"
	},
	{
			"value": "BAUBLES",
			"label": "BAUBLES"
	},
	{
			"value": "BAUBLES BALLS",
			"label": "BAUBLES BALLS"
	},
	{
			"value": "BAY LEAF",
			"label": "BAY LEAF"
	},
	{
			"value": "BAY LEAVES",
			"label": "BAY LEAVES"
	},
	{
			"value": "BAYLOR",
			"label": "BAYLOR"
	},
	{
			"value": "BAZZILL",
			"label": "BAZZILL"
	},
	{
			"value": "BBQ",
			"label": "BBQ"
	},
	{
			"value": "B BRASS LAMP CANDLE",
			"label": "B BRASS LAMP CANDLE"
	},
	{
			"value": "BBYRN",
			"label": "BBYRN"
	},
	{
			"value": "BBYRND00",
			"label": "BBYRND00"
	},
	{
			"value": "BCA",
			"label": "BCA"
	},
	{
			"value": "BCSEADOO",
			"label": "BCSEADOO"
	},
	{
			"value": "BDLAC",
			"label": "BDLAC"
	},
	{
			"value": "BDLACA00",
			"label": "BDLACA00"
	},
	{
			"value": "BDLACB00",
			"label": "BDLACB00"
	},
	{
			"value": "BEACH",
			"label": "BEACH"
	},
	{
			"value": "BEACH BABE",
			"label": "BEACH BABE"
	},
	{
			"value": "BEACH BAG",
			"label": "BEACH BAG"
	},
	{
			"value": "BEACH ENT",
			"label": "BEACH ENT"
	},
	{
			"value": "BEACH & SUMMER",
			"label": "BEACH & SUMMER"
	},
	{
			"value": "BEACH TOWEL",
			"label": "BEACH TOWEL"
	},
	{
			"value": "BEACH TOYS",
			"label": "BEACH TOYS"
	},
	{
			"value": "BEACON",
			"label": "BEACON"
	},
	{
			"value": "BEAD",
			"label": "BEAD"
	},
	{
			"value": "BEADALON",
			"label": "BEADALON"
	},
	{
			"value": "BEAD BOARD",
			"label": "BEAD BOARD"
	},
	{
			"value": "BEAD BOX",
			"label": "BEAD BOX"
	},
	{
			"value": "BEAD BRY",
			"label": "BEAD BRY"
	},
	{
			"value": "BEAD BUCKET",
			"label": "BEAD BUCKET"
	},
	{
			"value": "BEAD CAPS",
			"label": "BEAD CAPS"
	},
	{
			"value": "BEAD CONES",
			"label": "BEAD CONES"
	},
	{
			"value": "BEAD CRIMPS",
			"label": "BEAD CRIMPS"
	},
	{
			"value": "BEADED",
			"label": "BEADED"
	},
	{
			"value": "BEADED ANTLER",
			"label": "BEADED ANTLER"
	},
	{
			"value": "BEADED CHAIN",
			"label": "BEADED CHAIN"
	},
	{
			"value": "BEADED CONE",
			"label": "BEADED CONE"
	},
	{
			"value": "BEADED CONE TREE RED",
			"label": "BEADED CONE TREE RED"
	},
	{
			"value": "BEADED CONE TREE SIL",
			"label": "BEADED CONE TREE SIL"
	},
	{
			"value": "BEADED CONE TREE WHI",
			"label": "BEADED CONE TREE WHI"
	},
	{
			"value": "BEADED FLORAL",
			"label": "BEADED FLORAL"
	},
	{
			"value": "BEADED FRINGE",
			"label": "BEADED FRINGE"
	},
	{
			"value": "BEADED MESH",
			"label": "BEADED MESH"
	},
	{
			"value": "BEADERS PARADISE",
			"label": "BEADERS PARADISE"
	},
	{
			"value": "BEADERY",
			"label": "BEADERY"
	},
	{
			"value": "BEAD GARLAND",
			"label": "BEAD GARLAND"
	},
	{
			"value": "BEADING",
			"label": "BEADING"
	},
	{
			"value": "BEADING WIRE",
			"label": "BEADING WIRE"
	},
	{
			"value": "BEAD/JEWEL",
			"label": "BEAD/JEWEL"
	},
	{
			"value": "BEAD KIT",
			"label": "BEAD KIT"
	},
	{
			"value": "BEAD MAT",
			"label": "BEAD MAT"
	},
	{
			"value": "BEAD PINS",
			"label": "BEAD PINS"
	},
	{
			"value": "BEAD POM",
			"label": "BEAD POM"
	},
	{
			"value": "BEADS",
			"label": "BEADS"
	},
	{
			"value": "BEADSLIDE",
			"label": "BEADSLIDE"
	},
	{
			"value": "BEADSMITH",
			"label": "BEADSMITH"
	},
	{
			"value": "BEAD SPINNER",
			"label": "BEAD SPINNER"
	},
	{
			"value": "BEAD STORAGE",
			"label": "BEAD STORAGE"
	},
	{
			"value": "BEAD STRANDS",
			"label": "BEAD STRANDS"
	},
	{
			"value": "BEAD TIPS",
			"label": "BEAD TIPS"
	},
	{
			"value": "BEAD TRAY",
			"label": "BEAD TRAY"
	},
	{
			"value": "BEAD TUBES",
			"label": "BEAD TUBES"
	},
	{
			"value": "BEAD WORK",
			"label": "BEAD WORK"
	},
	{
			"value": "BEAN BAG FILLER",
			"label": "BEAN BAG FILLER"
	},
	{
			"value": "BEANIE",
			"label": "BEANIE"
	},
	{
			"value": "BEAR",
			"label": "BEAR"
	},
	{
			"value": "BEARS",
			"label": "BEARS"
	},
	{
			"value": "BEAR THREAD",
			"label": "BEAR THREAD"
	},
	{
			"value": "BEAUTY",
			"label": "BEAUTY"
	},
	{
			"value": "BEAUTY KIT",
			"label": "BEAUTY KIT"
	},
	{
			"value": "BED",
			"label": "BED"
	},
	{
			"value": "BEDDING",
			"label": "BEDDING"
	},
	{
			"value": "BED-FOAM",
			"label": "BED-FOAM"
	},
	{
			"value": "BEDIUM FERN",
			"label": "BEDIUM FERN"
	},
	{
			"value": "BEE",
			"label": "BEE"
	},
	{
			"value": "BEECH LEAVES",
			"label": "BEECH LEAVES"
	},
	{
			"value": "BEEF JERKY",
			"label": "BEEF JERKY"
	},
	{
			"value": "BEES",
			"label": "BEES"
	},
	{
			"value": "BEETLE",
			"label": "BEETLE"
	},
	{
			"value": "BEGINNING CURSIVE HA",
			"label": "BEGINNING CURSIVE HA"
	},
	{
			"value": "BEGONIA",
			"label": "BEGONIA"
	},
	{
			"value": "BEIGE DOILY",
			"label": "BEIGE DOILY"
	},
	{
			"value": "BELL",
			"label": "BELL"
	},
	{
			"value": "BELLA BLVD",
			"label": "BELLA BLVD"
	},
	{
			"value": "BELLE MAISON",
			"label": "BELLE MAISON"
	},
	{
			"value": "BELL FLOWER",
			"label": "BELL FLOWER"
	},
	{
			"value": "BELL GARLAND",
			"label": "BELL GARLAND"
	},
	{
			"value": "BELL PNCN",
			"label": "BELL PNCN"
	},
	{
			"value": "BELLS",
			"label": "BELLS"
	},
	{
			"value": "BELL SHAPD JNGL BELL",
			"label": "BELL SHAPD JNGL BELL"
	},
	{
			"value": "BELL SNOWFLAKE",
			"label": "BELL SNOWFLAKE"
	},
	{
			"value": "BELL TREE",
			"label": "BELL TREE"
	},
	{
			"value": "BELT",
			"label": "BELT"
	},
	{
			"value": "BELT BTY",
			"label": "BELT BTY"
	},
	{
			"value": "BELTING",
			"label": "BELTING"
	},
	{
			"value": "BENCH",
			"label": "BENCH"
	},
	{
			"value": "BENT",
			"label": "BENT"
	},
	{
			"value": "BENT ERGONOMIC",
			"label": "BENT ERGONOMIC"
	},
	{
			"value": "BENT LEFT",
			"label": "BENT LEFT"
	},
	{
			"value": "BENT LEFT HANDED",
			"label": "BENT LEFT HANDED"
	},
	{
			"value": "BERRIES",
			"label": "BERRIES"
	},
	{
			"value": "BERRIES FOLIAGE",
			"label": "BERRIES FOLIAGE"
	},
	{
			"value": "BERRIES LVS",
			"label": "BERRIES LVS"
	},
	{
			"value": "BERRY",
			"label": "BERRY"
	},
	{
			"value": "BERRY/BALL/PINE",
			"label": "BERRY/BALL/PINE"
	},
	{
			"value": "BERRY CR",
			"label": "BERRY CR"
	},
	{
			"value": "BERRY HEART",
			"label": "BERRY HEART"
	},
	{
			"value": "BERRY IN ACETATE BOX",
			"label": "BERRY IN ACETATE BOX"
	},
	{
			"value": "BERRY PICKING",
			"label": "BERRY PICKING"
	},
	{
			"value": "BERRY PK",
			"label": "BERRY PK"
	},
	{
			"value": "BERRY ROPING",
			"label": "BERRY ROPING"
	},
	{
			"value": "BERRY SHOVEL",
			"label": "BERRY SHOVEL"
	},
	{
			"value": "BERRY VINES",
			"label": "BERRY VINES"
	},
	{
			"value": "BERRY WREATH",
			"label": "BERRY WREATH"
	},
	{
			"value": "BERWICK",
			"label": "BERWICK"
	},
	{
			"value": "BERY",
			"label": "BERY"
	},
	{
			"value": "BEST CREATION",
			"label": "BEST CREATION"
	},
	{
			"value": "BEST VALUE",
			"label": "BEST VALUE"
	},
	{
			"value": "BEVERAGE",
			"label": "BEVERAGE"
	},
	{
			"value": "BEVERAGE BAG",
			"label": "BEVERAGE BAG"
	},
	{
			"value": "BEVERAGE BUCKET",
			"label": "BEVERAGE BUCKET"
	},
	{
			"value": "BEVERAGE DISP",
			"label": "BEVERAGE DISP"
	},
	{
			"value": "BEVERAGE NAPKIN",
			"label": "BEVERAGE NAPKIN"
	},
	{
			"value": "BEVERAGES",
			"label": "BEVERAGES"
	},
	{
			"value": "BEVERAGE STAKE",
			"label": "BEVERAGE STAKE"
	},
	{
			"value": "BEVERAGE STAKES",
			"label": "BEVERAGE STAKES"
	},
	{
			"value": "BEVERAGE TUB",
			"label": "BEVERAGE TUB"
	},
	{
			"value": "BEVERGE STAKE",
			"label": "BEVERGE STAKE"
	},
	{
			"value": "BEV NAPKIN",
			"label": "BEV NAPKIN"
	},
	{
			"value": "BE WILD",
			"label": "BE WILD"
	},
	{
			"value": "BEZEL",
			"label": "BEZEL"
	},
	{
			"value": "BHM",
			"label": "BHM"
	},
	{
			"value": "BIB",
			"label": "BIB"
	},
	{
			"value": "BIBS",
			"label": "BIBS"
	},
	{
			"value": "BIC",
			"label": "BIC"
	},
	{
			"value": "BICONE",
			"label": "BICONE"
	},
	{
			"value": "BICYCLE BAG",
			"label": "BICYCLE BAG"
	},
	{
			"value": "BIGBC",
			"label": "BIGBC"
	},
	{
			"value": "BIGBC1",
			"label": "BIGBC1"
	},
	{
			"value": "BIGBCA",
			"label": "BIGBCA"
	},
	{
			"value": "BIGBCA00 CHANEL",
			"label": "BIGBCA00 CHANEL"
	},
	{
			"value": "BIKE",
			"label": "BIKE"
	},
	{
			"value": "BIKE BAG",
			"label": "BIKE BAG"
	},
	{
			"value": "BIKE PLANTER",
			"label": "BIKE PLANTER"
	},
	{
			"value": "BIKES",
			"label": "BIKES"
	},
	{
			"value": "BILLY BUTTON",
			"label": "BILLY BUTTON"
	},
	{
			"value": "BINDING",
			"label": "BINDING"
	},
	{
			"value": "BINDING CORDING",
			"label": "BINDING CORDING"
	},
	{
			"value": "BIN ROUND",
			"label": "BIN ROUND"
	},
	{
			"value": "BIN SQUARE",
			"label": "BIN SQUARE"
	},
	{
			"value": "BIRCH",
			"label": "BIRCH"
	},
	{
			"value": "BIRCH LEAVES",
			"label": "BIRCH LEAVES"
	},
	{
			"value": "BIRCH LOGS",
			"label": "BIRCH LOGS"
	},
	{
			"value": "BIRCH PILLAR",
			"label": "BIRCH PILLAR"
	},
	{
			"value": "BIRD",
			"label": "BIRD"
	},
	{
			"value": "BIRD BATH",
			"label": "BIRD BATH"
	},
	{
			"value": "BIRDBATH",
			"label": "BIRDBATH"
	},
	{
			"value": "BIRD BRY",
			"label": "BIRD BRY"
	},
	{
			"value": "BIRDCAGE",
			"label": "BIRDCAGE"
	},
	{
			"value": "BIRD CAGE AND KEY",
			"label": "BIRD CAGE AND KEY"
	},
	{
			"value": "BIRD DECOR",
			"label": "BIRD DECOR"
	},
	{
			"value": "BIRDFEEDER",
			"label": "BIRDFEEDER"
	},
	{
			"value": "BIRDFEEDER CERAMIC",
			"label": "BIRDFEEDER CERAMIC"
	},
	{
			"value": "BIRDFEEDER GLASS",
			"label": "BIRDFEEDER GLASS"
	},
	{
			"value": "BIRDFEEDER METAL",
			"label": "BIRDFEEDER METAL"
	},
	{
			"value": "BIRDFEEDER TEA",
			"label": "BIRDFEEDER TEA"
	},
	{
			"value": "BIRDFEEDER WOOD",
			"label": "BIRDFEEDER WOOD"
	},
	{
			"value": "BIRD FLORAL",
			"label": "BIRD FLORAL"
	},
	{
			"value": "BIRD HOUSE",
			"label": "BIRD HOUSE"
	},
	{
			"value": "BIRDHOUSE",
			"label": "BIRDHOUSE"
	},
	{
			"value": "BIRDHOUSE AMERICANA",
			"label": "BIRDHOUSE AMERICANA"
	},
	{
			"value": "BIRDHOUSE ANIMAL",
			"label": "BIRDHOUSE ANIMAL"
	},
	{
			"value": "BIRDHOUSE BUILDING",
			"label": "BIRDHOUSE BUILDING"
	},
	{
			"value": "BIRDHOUSE CRAFTING",
			"label": "BIRDHOUSE CRAFTING"
	},
	{
			"value": "BIRDHOUSE FOOD",
			"label": "BIRDHOUSE FOOD"
	},
	{
			"value": "BIRDHOUSE FOOD TRUCK",
			"label": "BIRDHOUSE FOOD TRUCK"
	},
	{
			"value": "BIRDHOUSE GARDEN",
			"label": "BIRDHOUSE GARDEN"
	},
	{
			"value": "BIRDHOUSE HOUSE",
			"label": "BIRDHOUSE HOUSE"
	},
	{
			"value": "BIRDHOUSE MISC",
			"label": "BIRDHOUSE MISC"
	},
	{
			"value": "BIRDHOUSES",
			"label": "BIRDHOUSES"
	},
	{
			"value": "BIRDHOUSE SEWING",
			"label": "BIRDHOUSE SEWING"
	},
	{
			"value": "BIRDHOUSES & FEEDERS",
			"label": "BIRDHOUSES & FEEDERS"
	},
	{
			"value": "BIRDHOUSE SUMMER",
			"label": "BIRDHOUSE SUMMER"
	},
	{
			"value": "BIRDHOUSE TEA",
			"label": "BIRDHOUSE TEA"
	},
	{
			"value": "BIRDHOUSE TREND",
			"label": "BIRDHOUSE TREND"
	},
	{
			"value": "BIRDHOUSE WHIMSY",
			"label": "BIRDHOUSE WHIMSY"
	},
	{
			"value": "BIRD LANTERN",
			"label": "BIRD LANTERN"
	},
	{
			"value": "BIRD NEST",
			"label": "BIRD NEST"
	},
	{
			"value": "BIRDNEST",
			"label": "BIRDNEST"
	},
	{
			"value": "BIRD OF PARADISE",
			"label": "BIRD OF PARADISE"
	},
	{
			"value": "BIRDS",
			"label": "BIRDS"
	},
	{
			"value": "BIRDS/BUTTERFLIES",
			"label": "BIRDS/BUTTERFLIES"
	},
	{
			"value": "BIRDS & FEATHERS",
			"label": "BIRDS & FEATHERS"
	},
	{
			"value": "BIRDS/FEATHERS",
			"label": "BIRDS/FEATHERS"
	},
	{
			"value": "BIRDS & OWLS",
			"label": "BIRDS & OWLS"
	},
	{
			"value": "BIRD WD",
			"label": "BIRD WD"
	},
	{
			"value": "BIRTHDAY",
			"label": "BIRTHDAY"
	},
	{
			"value": "BISCOTTI",
			"label": "BISCOTTI"
	},
	{
			"value": "BISTRO TABLE",
			"label": "BISTRO TABLE"
	},
	{
			"value": "BITTER SWEET",
			"label": "BITTER SWEET"
	},
	{
			"value": "BITTERSWEET",
			"label": "BITTERSWEET"
	},
	{
			"value": "BIZO KNIT",
			"label": "BIZO KNIT"
	},
	{
			"value": "B KIND",
			"label": "B KIND"
	},
	{
			"value": "BKSFF",
			"label": "BKSFF"
	},
	{
			"value": "BKSFFA00",
			"label": "BKSFFA00"
	},
	{
			"value": "BLACK",
			"label": "BLACK"
	},
	{
			"value": "BLACKBERRY",
			"label": "BLACKBERRY"
	},
	{
			"value": "BLACKBOARD",
			"label": "BLACKBOARD"
	},
	{
			"value": "BLACK DOG",
			"label": "BLACK DOG"
	},
	{
			"value": "BLACK EYED SUSAN",
			"label": "BLACK EYED SUSAN"
	},
	{
			"value": "BLACKEYED SUSAN",
			"label": "BLACKEYED SUSAN"
	},
	{
			"value": "BLACK EYE SUSAN",
			"label": "BLACK EYE SUSAN"
	},
	{
			"value": "BLACK FRIDAY",
			"label": "BLACK FRIDAY"
	},
	{
			"value": "BLACK HISTORY MONTH",
			"label": "BLACK HISTORY MONTH"
	},
	{
			"value": "BLACK LANTERN",
			"label": "BLACK LANTERN"
	},
	{
			"value": "BLACKOUT",
			"label": "BLACKOUT"
	},
	{
			"value": "BLACK PANTHER",
			"label": "BLACK PANTHER"
	},
	{
			"value": "BLACK PLACEMAT",
			"label": "BLACK PLACEMAT"
	},
	{
			"value": "BLACK PURSE",
			"label": "BLACK PURSE"
	},
	{
			"value": "BLACK SHOE",
			"label": "BLACK SHOE"
	},
	{
			"value": "BLACK SILVER PUMPKIN",
			"label": "BLACK SILVER PUMPKIN"
	},
	{
			"value": "BLACK THROW",
			"label": "BLACK THROW"
	},
	{
			"value": "BLACKWATCH PLAID",
			"label": "BLACKWATCH PLAID"
	},
	{
			"value": "BLADE",
			"label": "BLADE"
	},
	{
			"value": "BLADES",
			"label": "BLADES"
	},
	{
			"value": "BLANKET",
			"label": "BLANKET"
	},
	{
			"value": "BLANKET BINDING",
			"label": "BLANKET BINDING"
	},
	{
			"value": "BLANKET SET",
			"label": "BLANKET SET"
	},
	{
			"value": "BLEACHED",
			"label": "BLEACHED"
	},
	{
			"value": "BLEEDING HEART",
			"label": "BLEEDING HEART"
	},
	{
			"value": "BLEND",
			"label": "BLEND"
	},
	{
			"value": "BLENDER",
			"label": "BLENDER"
	},
	{
			"value": "BLIND BAG",
			"label": "BLIND BAG"
	},
	{
			"value": "BLINDSTITCH",
			"label": "BLINDSTITCH"
	},
	{
			"value": "BLING IT ON",
			"label": "BLING IT ON"
	},
	{
			"value": "BLIZZARD",
			"label": "BLIZZARD"
	},
	{
			"value": "BLIZZARD FLC PRINTS",
			"label": "BLIZZARD FLC PRINTS"
	},
	{
			"value": "BLIZZARD FLEECE PRIN",
			"label": "BLIZZARD FLEECE PRIN"
	},
	{
			"value": "BLK/OUT",
			"label": "BLK/OUT"
	},
	{
			"value": "BLK/WHT",
			"label": "BLK/WHT"
	},
	{
			"value": "BLOCK",
			"label": "BLOCK"
	},
	{
			"value": "BLOCK PAD",
			"label": "BLOCK PAD"
	},
	{
			"value": "BLOCK RUG",
			"label": "BLOCK RUG"
	},
	{
			"value": "BLOODY MARY",
			"label": "BLOODY MARY"
	},
	{
			"value": "BLOOMSBURY INSPIRED",
			"label": "BLOOMSBURY INSPIRED"
	},
	{
			"value": "BLOOM SPOTLIGHT",
			"label": "BLOOM SPOTLIGHT"
	},
	{
			"value": "BLOSSOM",
			"label": "BLOSSOM"
	},
	{
			"value": "BLOSSOMS",
			"label": "BLOSSOMS"
	},
	{
			"value": "BLUE",
			"label": "BLUE"
	},
	{
			"value": "BLUE AND WHITE",
			"label": "BLUE AND WHITE"
	},
	{
			"value": "BLUE DK",
			"label": "BLUE DK"
	},
	{
			"value": "BLUE FEATHER",
			"label": "BLUE FEATHER"
	},
	{
			"value": "BLUEGRASS PUBLISHING",
			"label": "BLUEGRASS PUBLISHING"
	},
	{
			"value": "BLUE GREEN GLASS",
			"label": "BLUE GREEN GLASS"
	},
	{
			"value": "BLUE HILLS STUDIO",
			"label": "BLUE HILLS STUDIO"
	},
	{
			"value": "BLUE LIGHT GLASSES",
			"label": "BLUE LIGHT GLASSES"
	},
	{
			"value": "BLUE LT",
			"label": "BLUE LT"
	},
	{
			"value": "BLUE MAKER BNDL",
			"label": "BLUE MAKER BNDL"
	},
	{
			"value": "BLUE MOON",
			"label": "BLUE MOON"
	},
	{
			"value": "BLUE MOON BEADS",
			"label": "BLUE MOON BEADS"
	},
	{
			"value": "BLUE RED FLORAL",
			"label": "BLUE RED FLORAL"
	},
	{
			"value": "BLUE SNOWMAN",
			"label": "BLUE SNOWMAN"
	},
	{
			"value": "BLUE TEXTURED",
			"label": "BLUE TEXTURED"
	},
	{
			"value": "BLUE THROW",
			"label": "BLUE THROW"
	},
	{
			"value": "BLUE & WHITE",
			"label": "BLUE & WHITE"
	},
	{
			"value": "BLUE YELLOW",
			"label": "BLUE YELLOW"
	},
	{
			"value": "BLU/GRY",
			"label": "BLU/GRY"
	},
	{
			"value": "BLUME",
			"label": "BLUME"
	},
	{
			"value": "BLUMENTHAL LANSING",
			"label": "BLUMENTHAL LANSING"
	},
	{
			"value": "BLUNT",
			"label": "BLUNT"
	},
	{
			"value": "BLU TAN",
			"label": "BLU TAN"
	},
	{
			"value": "BLU/WHT",
			"label": "BLU/WHT"
	},
	{
			"value": "BMB NEW STRANDS FY20",
			"label": "BMB NEW STRANDS FY20"
	},
	{
			"value": "BM FINDINGS",
			"label": "BM FINDINGS"
	},
	{
			"value": "BOA CHANDELLE",
			"label": "BOA CHANDELLE"
	},
	{
			"value": "BOA MARABOU",
			"label": "BOA MARABOU"
	},
	{
			"value": "BOARD",
			"label": "BOARD"
	},
	{
			"value": "BOARD BOOK",
			"label": "BOARD BOOK"
	},
	{
			"value": "BOARD BOOKS",
			"label": "BOARD BOOKS"
	},
	{
			"value": "BOARD GAME",
			"label": "BOARD GAME"
	},
	{
			"value": "BOAS",
			"label": "BOAS"
	},
	{
			"value": "BOBBIN",
			"label": "BOBBIN"
	},
	{
			"value": "BOBBINS",
			"label": "BOBBINS"
	},
	{
			"value": "BOBBIN STORAGE",
			"label": "BOBBIN STORAGE"
	},
	{
			"value": "BOBBLE",
			"label": "BOBBLE"
	},
	{
			"value": "BOB ROSS",
			"label": "BOB ROSS"
	},
	{
			"value": "BO BUNNY",
			"label": "BO BUNNY"
	},
	{
			"value": "BOC",
			"label": "BOC"
	},
	{
			"value": "BODRE",
			"label": "BODRE"
	},
	{
			"value": "BODY ART",
			"label": "BODY ART"
	},
	{
			"value": "BODYSUIT",
			"label": "BODYSUIT"
	},
	{
			"value": "BODY TAPE",
			"label": "BODY TAPE"
	},
	{
			"value": "BOHEIMIAN",
			"label": "BOHEIMIAN"
	},
	{
			"value": "BOHEMIAN",
			"label": "BOHEMIAN"
	},
	{
			"value": "BOHEMIAN PICNIC",
			"label": "BOHEMIAN PICNIC"
	},
	{
			"value": "BOHIN",
			"label": "BOHIN"
	},
	{
			"value": "BOHO",
			"label": "BOHO"
	},
	{
			"value": "BOHO BIRD BULLETIN B",
			"label": "BOHO BIRD BULLETIN B"
	},
	{
			"value": "BOHO BIRD NOTE PAD",
			"label": "BOHO BIRD NOTE PAD"
	},
	{
			"value": "BOHO BIRD STICKERS",
			"label": "BOHO BIRD STICKERS"
	},
	{
			"value": "BOHO STYLE",
			"label": "BOHO STYLE"
	},
	{
			"value": "BOLD & BRIGHT",
			"label": "BOLD & BRIGHT"
	},
	{
			"value": "BONBON",
			"label": "BONBON"
	},
	{
			"value": "BONDING",
			"label": "BONDING"
	},
	{
			"value": "BONE",
			"label": "BONE"
	},
	{
			"value": "BONE ANIMAL CLASSIC",
			"label": "BONE ANIMAL CLASSIC"
	},
	{
			"value": "BONE ANIMAL FASHION",
			"label": "BONE ANIMAL FASHION"
	},
	{
			"value": "BONE ANML CLSSC LED",
			"label": "BONE ANML CLSSC LED"
	},
	{
			"value": "BONE ANML FASH LED",
			"label": "BONE ANML FASH LED"
	},
	{
			"value": "BONE CANDELABRA",
			"label": "BONE CANDELABRA"
	},
	{
			"value": "BONES",
			"label": "BONES"
	},
	{
			"value": "BONE SKELETON CLSSC",
			"label": "BONE SKELETON CLSSC"
	},
	{
			"value": "BONE SKELETON FASHN",
			"label": "BONE SKELETON FASHN"
	},
	{
			"value": "BONE SKLTN CLSC",
			"label": "BONE SKLTN CLSC"
	},
	{
			"value": "BONE SKLTN CLSC LED",
			"label": "BONE SKLTN CLSC LED"
	},
	{
			"value": "BONE SKLTN FASH",
			"label": "BONE SKLTN FASH"
	},
	{
			"value": "BONFIRE",
			"label": "BONFIRE"
	},
	{
			"value": "BONING BTY",
			"label": "BONING BTY"
	},
	{
			"value": "BON VOYAGE",
			"label": "BON VOYAGE"
	},
	{
			"value": "BOO!",
			"label": "BOO!"
	},
	{
			"value": "BOOK",
			"label": "BOOK"
	},
	{
			"value": "BOOK ART",
			"label": "BOOK ART"
	},
	{
			"value": "BOOK BIN",
			"label": "BOOK BIN"
	},
	{
			"value": "BOOKENDS",
			"label": "BOOKENDS"
	},
	{
			"value": "BOOKMARK",
			"label": "BOOKMARK"
	},
	{
			"value": "BOOKMARKS",
			"label": "BOOKMARKS"
	},
	{
			"value": "BOOKPLATES",
			"label": "BOOKPLATES"
	},
	{
			"value": "BOOKS",
			"label": "BOOKS"
	},
	{
			"value": "BOOK SHELF",
			"label": "BOOK SHELF"
	},
	{
			"value": "BOOT",
			"label": "BOOT"
	},
	{
			"value": "BOOT CUFF",
			"label": "BOOT CUFF"
	},
	{
			"value": "BOOTS",
			"label": "BOOTS"
	},
	{
			"value": "BORDER ROLL",
			"label": "BORDER ROLL"
	},
	{
			"value": "BORDERS & BACKGROUND",
			"label": "BORDERS & BACKGROUND"
	},
	{
			"value": "BORDER TRIMS",
			"label": "BORDER TRIMS"
	},
	{
			"value": "BOSAL",
			"label": "BOSAL"
	},
	{
			"value": "BOS BRUINS",
			"label": "BOS BRUINS"
	},
	{
			"value": "BOSILICIUM AND GERMA",
			"label": "BOSILICIUM AND GERMA"
	},
	{
			"value": "BOSTON FERN",
			"label": "BOSTON FERN"
	},
	{
			"value": "BOTANICAL",
			"label": "BOTANICAL"
	},
	{
			"value": "BOTANICAL GARDENS",
			"label": "BOTANICAL GARDENS"
	},
	{
			"value": "BOTANICAL LEAVES",
			"label": "BOTANICAL LEAVES"
	},
	{
			"value": "BOTANICALS",
			"label": "BOTANICALS"
	},
	{
			"value": "BOTTLE",
			"label": "BOTTLE"
	},
	{
			"value": "BOTTLE CAP",
			"label": "BOTTLE CAP"
	},
	{
			"value": "BOTTLE CAP INC",
			"label": "BOTTLE CAP INC"
	},
	{
			"value": "BOTTLE CAP WALL DECO",
			"label": "BOTTLE CAP WALL DECO"
	},
	{
			"value": "BOTTLE LABEL",
			"label": "BOTTLE LABEL"
	},
	{
			"value": "BOTTLE OPENER",
			"label": "BOTTLE OPENER"
	},
	{
			"value": "BOTTLE RACK",
			"label": "BOTTLE RACK"
	},
	{
			"value": "BOTTLES",
			"label": "BOTTLES"
	},
	{
			"value": "BOTTLE STOPPER",
			"label": "BOTTLE STOPPER"
	},
	{
			"value": "BOUCLE",
			"label": "BOUCLE"
	},
	{
			"value": "BOUCLE SUITING",
			"label": "BOUCLE SUITING"
	},
	{
			"value": "BOUGAINVILLEA",
			"label": "BOUGAINVILLEA"
	},
	{
			"value": "BOUGAINVILLEEA",
			"label": "BOUGAINVILLEEA"
	},
	{
			"value": "BOUGANVILLA",
			"label": "BOUGANVILLA"
	},
	{
			"value": "BOUNCY & WIND SPINNE",
			"label": "BOUNCY & WIND SPINNE"
	},
	{
			"value": "BOUQUET",
			"label": "BOUQUET"
	},
	{
			"value": "BOUQUETS",
			"label": "BOUQUETS"
	},
	{
			"value": "BOUTINERE",
			"label": "BOUTINERE"
	},
	{
			"value": "BOUTONNIERE",
			"label": "BOUTONNIERE"
	},
	{
			"value": "BOUTQA00",
			"label": "BOUTQA00"
	},
	{
			"value": "BOUTQB00",
			"label": "BOUTQB00"
	},
	{
			"value": "BOW",
			"label": "BOW"
	},
	{
			"value": "BOW BAG",
			"label": "BOW BAG"
	},
	{
			"value": "BOW L",
			"label": "BOW L"
	},
	{
			"value": "BOWL",
			"label": "BOWL"
	},
	{
			"value": "BOWL 4PK",
			"label": "BOWL 4PK"
	},
	{
			"value": "BOWL BASKET",
			"label": "BOWL BASKET"
	},
	{
			"value": "BOWL FILLER",
			"label": "BOWL FILLER"
	},
	{
			"value": "BOWL LRG",
			"label": "BOWL LRG"
	},
	{
			"value": "BOWL SML",
			"label": "BOWL SML"
	},
	{
			"value": "BOWL TIN",
			"label": "BOWL TIN"
	},
	{
			"value": "BOW PACKS",
			"label": "BOW PACKS"
	},
	{
			"value": "BOW PANEL",
			"label": "BOW PANEL"
	},
	{
			"value": " BOW, RIBBON, TAG SE",
			"label": " BOW, RIBBON, TAG SE"
	},
	{
			"value": "BOW S",
			"label": "BOW S"
	},
	{
			"value": "BOWS",
			"label": "BOWS"
	},
	{
			"value": "BOWTIE",
			"label": "BOWTIE"
	},
	{
			"value": "BOWTIES",
			"label": "BOWTIES"
	},
	{
			"value": "BOX",
			"label": "BOX"
	},
	{
			"value": "BOX CALENDAR",
			"label": "BOX CALENDAR"
	},
	{
			"value": "BOX CHAIN",
			"label": "BOX CHAIN"
	},
	{
			"value": "BOXED",
			"label": "BOXED"
	},
	{
			"value": "BOXED CRITTER",
			"label": "BOXED CRITTER"
	},
	{
			"value": "BOXED ORNAMENTS",
			"label": "BOXED ORNAMENTS"
	},
	{
			"value": "BOXED PUMPKIN",
			"label": "BOXED PUMPKIN"
	},
	{
			"value": "BOXED PUMPKINS",
			"label": "BOXED PUMPKINS"
	},
	{
			"value": "BOXED SETS",
			"label": "BOXED SETS"
	},
	{
			"value": "BOXES",
			"label": "BOXES"
	},
	{
			"value": "BOX OF CARDS",
			"label": "BOX OF CARDS"
	},
	{
			"value": "BOXWOOD",
			"label": "BOXWOOD"
	},
	{
			"value": "BOY",
			"label": "BOY"
	},
	{
			"value": "BOYE",
			"label": "BOYE"
	},
	{
			"value": "BOY MISC",
			"label": "BOY MISC"
	},
	{
			"value": "BOYS",
			"label": "BOYS"
	},
	{
			"value": "BRACELET",
			"label": "BRACELET"
	},
	{
			"value": "BRACELET DISPLAY",
			"label": "BRACELET DISPLAY"
	},
	{
			"value": "BRACELET KIT",
			"label": "BRACELET KIT"
	},
	{
			"value": "BRACELET LEATHER",
			"label": "BRACELET LEATHER"
	},
	{
			"value": "BRACELET METAL",
			"label": "BRACELET METAL"
	},
	{
			"value": "BRACELETS",
			"label": "BRACELETS"
	},
	{
			"value": "BRACKET",
			"label": "BRACKET"
	},
	{
			"value": "BRACKETS",
			"label": "BRACKETS"
	},
	{
			"value": "BRAID",
			"label": "BRAID"
	},
	{
			"value": "BRAIDED PUMPKIN",
			"label": "BRAIDED PUMPKIN"
	},
	{
			"value": "BRAIDED WREATH BASE",
			"label": "BRAIDED WREATH BASE"
	},
	{
			"value": "BRAID ELASTIC",
			"label": "BRAID ELASTIC"
	},
	{
			"value": "BRAID METALLIC",
			"label": "BRAID METALLIC"
	},
	{
			"value": "BRAIN",
			"label": "BRAIN"
	},
	{
			"value": "BRANCH",
			"label": "BRANCH"
	},
	{
			"value": "BRANCHES",
			"label": "BRANCHES"
	},
	{
			"value": "BRANCH GRN TURQ",
			"label": "BRANCH GRN TURQ"
	},
	{
			"value": "BRANCH SNOWFLAKE",
			"label": "BRANCH SNOWFLAKE"
	},
	{
			"value": "BRANCH STAR",
			"label": "BRANCH STAR"
	},
	{
			"value": "BRANCH TREE",
			"label": "BRANCH TREE"
	},
	{
			"value": "BRANDABLE",
			"label": "BRANDABLE"
	},
	{
			"value": "BR ARRANGEMENT",
			"label": "BR ARRANGEMENT"
	},
	{
			"value": "BREAST CANCER",
			"label": "BREAST CANCER"
	},
	{
			"value": "BREAST CANCER AWARE",
			"label": "BREAST CANCER AWARE"
	},
	{
			"value": "BRECKLING PRESS",
			"label": "BRECKLING PRESS"
	},
	{
			"value": "BRIARPATCH",
			"label": "BRIARPATCH"
	},
	{
			"value": "BRICK",
			"label": "BRICK"
	},
	{
			"value": "BRIDAL",
			"label": "BRIDAL"
	},
	{
			"value": "BRIDAL APPLIQUE",
			"label": "BRIDAL APPLIQUE"
	},
	{
			"value": "BRIDAL BOOKS",
			"label": "BRIDAL BOOKS"
	},
	{
			"value": "BRIDAL INSPIRATIONS",
			"label": "BRIDAL INSPIRATIONS"
	},
	{
			"value": "BRIDAL POG",
			"label": "BRIDAL POG"
	},
	{
			"value": "BRIDGE",
			"label": "BRIDGE"
	},
	{
			"value": "BRIGHT AND BOLD",
			"label": "BRIGHT AND BOLD"
	},
	{
			"value": "BRIGHT METAL",
			"label": "BRIGHT METAL"
	},
	{
			"value": "BRIGHTPAD",
			"label": "BRIGHTPAD"
	},
	{
			"value": "BRIGHTPAD GO",
			"label": "BRIGHTPAD GO"
	},
	{
			"value": "BRIGHT SUMMER",
			"label": "BRIGHT SUMMER"
	},
	{
			"value": "BRIGHT WALL DECOR",
			"label": "BRIGHT WALL DECOR"
	},
	{
			"value": "BRILLIANT BRIGHTS",
			"label": "BRILLIANT BRIGHTS"
	},
	{
			"value": "BRISTLE FAN",
			"label": "BRISTLE FAN"
	},
	{
			"value": "BRITISH PUPS",
			"label": "BRITISH PUPS"
	},
	{
			"value": "BROACH",
			"label": "BROACH"
	},
	{
			"value": "BROCADES/JACQ/SARIS",
			"label": "BROCADES/JACQ/SARIS"
	},
	{
			"value": "BROKEN GLASS",
			"label": "BROKEN GLASS"
	},
	{
			"value": "BROKEN MIRROR",
			"label": "BROKEN MIRROR"
	},
	{
			"value": "BRONZE",
			"label": "BRONZE"
	},
	{
			"value": "BROOCH",
			"label": "BROOCH"
	},
	{
			"value": "BROOM",
			"label": "BROOM"
	},
	{
			"value": "BRT SHADER",
			"label": "BRT SHADER"
	},
	{
			"value": "BRT SNAP",
			"label": "BRT SNAP"
	},
	{
			"value": "BRUSH",
			"label": "BRUSH"
	},
	{
			"value": "BRUSH 350",
			"label": "BRUSH 350"
	},
	{
			"value": "BRUSHED PERM VINYL",
			"label": "BRUSHED PERM VINYL"
	},
	{
			"value": "BRUSHES",
			"label": "BRUSHES"
	},
	{
			"value": "BRUSH FRINGE",
			"label": "BRUSH FRINGE"
	},
	{
			"value": "BRUSH PEN",
			"label": "BRUSH PEN"
	},
	{
			"value": "BRY",
			"label": "BRY"
	},
	{
			"value": "BRY ACORN",
			"label": "BRY ACORN"
	},
	{
			"value": "BRY/ACORN MXD GARLAN",
			"label": "BRY/ACORN MXD GARLAN"
	},
	{
			"value": "BRY ACRN PMPKN PICK",
			"label": "BRY ACRN PMPKN PICK"
	},
	{
			"value": "BRY BASKET",
			"label": "BRY BASKET"
	},
	{
			"value": "BRY BUSH",
			"label": "BRY BUSH"
	},
	{
			"value": "BRY CANDLE RING",
			"label": "BRY CANDLE RING"
	},
	{
			"value": "BRY CANE",
			"label": "BRY CANE"
	},
	{
			"value": "BRY GRASS",
			"label": "BRY GRASS"
	},
	{
			"value": "BRYGRASS BSH",
			"label": "BRYGRASS BSH"
	},
	{
			"value": "BRY GRASS TREE",
			"label": "BRY GRASS TREE"
	},
	{
			"value": "BRY HEATHER BSH",
			"label": "BRY HEATHER BSH"
	},
	{
			"value": "BRY PINECONE",
			"label": "BRY PINECONE"
	},
	{
			"value": "BRY PNCN",
			"label": "BRY PNCN"
	},
	{
			"value": "BRY SPRY",
			"label": "BRY SPRY"
	},
	{
			"value": "BRY STAR",
			"label": "BRY STAR"
	},
	{
			"value": "BRY WRTH",
			"label": "BRY WRTH"
	},
	{
			"value": "BSKT 12 IVR",
			"label": "BSKT 12 IVR"
	},
	{
			"value": "BSKT 14 IVR",
			"label": "BSKT 14 IVR"
	},
	{
			"value": "BTC",
			"label": "BTC"
	},
	{
			"value": "BTC 2012",
			"label": "BTC 2012"
	},
	{
			"value": "BTN",
			"label": "BTN"
	},
	{
			"value": "BTRIM01",
			"label": "BTRIM01"
	},
	{
			"value": "BTRIMA00",
			"label": "BTRIMA00"
	},
	{
			"value": "BTRIMA01",
			"label": "BTRIMA01"
	},
	{
			"value": "BTRIMB00",
			"label": "BTRIMB00"
	},
	{
			"value": "BTRIMC00",
			"label": "BTRIMC00"
	},
	{
			"value": "BTRIMD00",
			"label": "BTRIMD00"
	},
	{
			"value": "BTRIME00",
			"label": "BTRIME00"
	},
	{
			"value": "BTRIMF00",
			"label": "BTRIMF00"
	},
	{
			"value": "BTS",
			"label": "BTS"
	},
	{
			"value": "BTS BTC",
			"label": "BTS BTC"
	},
	{
			"value": "BTTND",
			"label": "BTTND"
	},
	{
			"value": "BTY CORD",
			"label": "BTY CORD"
	},
	{
			"value": "BTY DRAPERY",
			"label": "BTY DRAPERY"
	},
	{
			"value": "BTY PIPING",
			"label": "BTY PIPING"
	},
	{
			"value": "BTY ROPE",
			"label": "BTY ROPE"
	},
	{
			"value": "BTY SNAP",
			"label": "BTY SNAP"
	},
	{
			"value": "BTY TAPE",
			"label": "BTY TAPE"
	},
	{
			"value": "BTY WEBBING",
			"label": "BTY WEBBING"
	},
	{
			"value": "BTY ZIPPER",
			"label": "BTY ZIPPER"
	},
	{
			"value": "BUBBLE",
			"label": "BUBBLE"
	},
	{
			"value": "BUCILLA",
			"label": "BUCILLA"
	},
	{
			"value": "BUCKET",
			"label": "BUCKET"
	},
	{
			"value": "BUCKET HANDLE",
			"label": "BUCKET HANDLE"
	},
	{
			"value": "BUCKET HAT",
			"label": "BUCKET HAT"
	},
	{
			"value": "BUCKETS",
			"label": "BUCKETS"
	},
	{
			"value": "BUCKET TIN",
			"label": "BUCKET TIN"
	},
	{
			"value": "BUCKLE CLASP",
			"label": "BUCKLE CLASP"
	},
	{
			"value": "BUCKLES",
			"label": "BUCKLES"
	},
	{
			"value": "BUCKRAM",
			"label": "BUCKRAM"
	},
	{
			"value": "BUDDHA",
			"label": "BUDDHA"
	},
	{
			"value": "BUD EVA",
			"label": "BUD EVA"
	},
	{
			"value": "BUD HEATHER",
			"label": "BUD HEATHER"
	},
	{
			"value": "BUD VASE",
			"label": "BUD VASE"
	},
	{
			"value": "BUD VASE PLANTER",
			"label": "BUD VASE PLANTER"
	},
	{
			"value": "BUFFALO CHECK",
			"label": "BUFFALO CHECK"
	},
	{
			"value": "BUFFALO RIBBON",
			"label": "BUFFALO RIBBON"
	},
	{
			"value": "BUG",
			"label": "BUG"
	},
	{
			"value": "BUG BITE THING",
			"label": "BUG BITE THING"
	},
	{
			"value": "BUG CATCHER",
			"label": "BUG CATCHER"
	},
	{
			"value": "BUG CATCHING",
			"label": "BUG CATCHING"
	},
	{
			"value": "BUGGY",
			"label": "BUGGY"
	},
	{
			"value": "BUG/INSET",
			"label": "BUG/INSET"
	},
	{
			"value": "BUGLE BEADS",
			"label": "BUGLE BEADS"
	},
	{
			"value": "BUGS",
			"label": "BUGS"
	},
	{
			"value": "BUGS & INSECTS",
			"label": "BUGS & INSECTS"
	},
	{
			"value": "BUILD A BEAR",
			"label": "BUILD A BEAR"
	},
	{
			"value": "BUILD A TREE",
			"label": "BUILD A TREE"
	},
	{
			"value": "BULB",
			"label": "BULB"
	},
	{
			"value": "BULB BLUE",
			"label": "BULB BLUE"
	},
	{
			"value": "BULB GREEN",
			"label": "BULB GREEN"
	},
	{
			"value": "BULB GREY",
			"label": "BULB GREY"
	},
	{
			"value": "BULBS",
			"label": "BULBS"
	},
	{
			"value": "BULB STRING LIGHTS",
			"label": "BULB STRING LIGHTS"
	},
	{
			"value": "BULK",
			"label": "BULK"
	},
	{
			"value": "BULK STEM",
			"label": "BULK STEM"
	},
	{
			"value": "BULL DENIM",
			"label": "BULL DENIM"
	},
	{
			"value": "BULLDOG",
			"label": "BULLDOG"
	},
	{
			"value": "BULLETIN BOARDS",
			"label": "BULLETIN BOARDS"
	},
	{
			"value": "BULLETIN BRD SET",
			"label": "BULLETIN BRD SET"
	},
	{
			"value": "BULLION FRINGE",
			"label": "BULLION FRINGE"
	},
	{
			"value": "BUMBAG",
			"label": "BUMBAG"
	},
	{
			"value": "BUMBLE",
			"label": "BUMBLE"
	},
	{
			"value": "BUMPER",
			"label": "BUMPER"
	},
	{
			"value": "BUNDLE",
			"label": "BUNDLE"
	},
	{
			"value": "BUNNIES",
			"label": "BUNNIES"
	},
	{
			"value": "BUNNY",
			"label": "BUNNY"
	},
	{
			"value": "BUNNY CERAMIC",
			"label": "BUNNY CERAMIC"
	},
	{
			"value": "BUNNY CRITTER",
			"label": "BUNNY CRITTER"
	},
	{
			"value": "BUNNY DECOR",
			"label": "BUNNY DECOR"
	},
	{
			"value": "BUNNY EAR PLUSH",
			"label": "BUNNY EAR PLUSH"
	},
	{
			"value": "BUNNY METAL",
			"label": "BUNNY METAL"
	},
	{
			"value": "BUNNY MOSS",
			"label": "BUNNY MOSS"
	},
	{
			"value": "BUNNY RATTAN",
			"label": "BUNNY RATTAN"
	},
	{
			"value": "BUNNYTAIL",
			"label": "BUNNYTAIL"
	},
	{
			"value": "BUNTING",
			"label": "BUNTING"
	},
	{
			"value": "BURALP ROLL",
			"label": "BURALP ROLL"
	},
	{
			"value": "BURGUNDY",
			"label": "BURGUNDY"
	},
	{
			"value": "BURGUNDY AND ORANGE",
			"label": "BURGUNDY AND ORANGE"
	},
	{
			"value": "BURLAP",
			"label": "BURLAP"
	},
	{
			"value": "BURLAP BAG",
			"label": "BURLAP BAG"
	},
	{
			"value": "BURLAP FLAG",
			"label": "BURLAP FLAG"
	},
	{
			"value": "BURLAP LINER",
			"label": "BURLAP LINER"
	},
	{
			"value": "BURLAP ROLL",
			"label": "BURLAP ROLL"
	},
	{
			"value": "BURLAP STOCKING",
			"label": "BURLAP STOCKING"
	},
	{
			"value": "BURLAP WALL DECOR",
			"label": "BURLAP WALL DECOR"
	},
	{
			"value": "BURLAP WERATH",
			"label": "BURLAP WERATH"
	},
	{
			"value": "BURLAP WREATH",
			"label": "BURLAP WREATH"
	},
	{
			"value": "BURNISHED",
			"label": "BURNISHED"
	},
	{
			"value": "BURNOUT",
			"label": "BURNOUT"
	},
	{
			"value": "BURST MIRRIOR",
			"label": "BURST MIRRIOR"
	},
	{
			"value": "BUSH",
			"label": "BUSH"
	},
	{
			"value": "BUSHES",
			"label": "BUSHES"
	},
	{
			"value": "BUSY KIDS",
			"label": "BUSY KIDS"
	},
	{
			"value": "BUSY KIDS CAMP",
			"label": "BUSY KIDS CAMP"
	},
	{
			"value": "BUTCHER PAPER",
			"label": "BUTCHER PAPER"
	},
	{
			"value": "BUTTERCREAM",
			"label": "BUTTERCREAM"
	},
	{
			"value": "BUTTERCUP",
			"label": "BUTTERCUP"
	},
	{
			"value": "BUTTERFILIES",
			"label": "BUTTERFILIES"
	},
	{
			"value": "BUTTERFLIES",
			"label": "BUTTERFLIES"
	},
	{
			"value": "BUTTERFLY",
			"label": "BUTTERFLY"
	},
	{
			"value": "BUTTERFLY & DRAGON",
			"label": "BUTTERFLY & DRAGON"
	},
	{
			"value": "BUTTERFLY & DRAGONFL",
			"label": "BUTTERFLY & DRAGONFL"
	},
	{
			"value": "BUTTERFLY FLIGHT",
			"label": "BUTTERFLY FLIGHT"
	},
	{
			"value": "BUTTERFLY GARDEN",
			"label": "BUTTERFLY GARDEN"
	},
	{
			"value": "BUTTERFLY LIME",
			"label": "BUTTERFLY LIME"
	},
	{
			"value": "BUTTERLFY",
			"label": "BUTTERLFY"
	},
	{
			"value": "BUTTON",
			"label": "BUTTON"
	},
	{
			"value": "BUTTON COVER",
			"label": "BUTTON COVER"
	},
	{
			"value": "BUTTON FLIPTOP",
			"label": "BUTTON FLIPTOP"
	},
	{
			"value": "BUTTON JAR",
			"label": "BUTTON JAR"
	},
	{
			"value": "BUTTON MUM",
			"label": "BUTTON MUM"
	},
	{
			"value": "BUTTONPRESS ACCESSOR",
			"label": "BUTTONPRESS ACCESSOR"
	},
	{
			"value": "BUTTONS",
			"label": "BUTTONS"
	},
	{
			"value": "BUTTONS GALORE",
			"label": "BUTTONS GALORE"
	},
	{
			"value": "BUTTON STUDS",
			"label": "BUTTON STUDS"
	},
	{
			"value": "BW LEAF",
			"label": "BW LEAF"
	},
	{
			"value": "BW LEAVES",
			"label": "BW LEAVES"
	},
	{
			"value": "BYU",
			"label": "BYU"
	},
	{
			"value": "CABANA STRIPE",
			"label": "CABANA STRIPE"
	},
	{
			"value": "CABANNA STRIPES",
			"label": "CABANNA STRIPES"
	},
	{
			"value": "CABBAGE",
			"label": "CABBAGE"
	},
	{
			"value": "CABBAGE ROSE",
			"label": "CABBAGE ROSE"
	},
	{
			"value": "CABBEGE ROSE",
			"label": "CABBEGE ROSE"
	},
	{
			"value": "CABIN",
			"label": "CABIN"
	},
	{
			"value": "CABIN SOCKS",
			"label": "CABIN SOCKS"
	},
	{
			"value": "CABLE BALL CHAIN",
			"label": "CABLE BALL CHAIN"
	},
	{
			"value": "CABLE BOOT",
			"label": "CABLE BOOT"
	},
	{
			"value": "CABLE CHAIN",
			"label": "CABLE CHAIN"
	},
	{
			"value": "CABLE CHAIN FLAT",
			"label": "CABLE CHAIN FLAT"
	},
	{
			"value": "CABLE CHAIN HAMMERED",
			"label": "CABLE CHAIN HAMMERED"
	},
	{
			"value": "CABOCHONS",
			"label": "CABOCHONS"
	},
	{
			"value": "CACTUS",
			"label": "CACTUS"
	},
	{
			"value": "CACTUS FEEDER",
			"label": "CACTUS FEEDER"
	},
	{
			"value": "CADDY",
			"label": "CADDY"
	},
	{
			"value": "CAGE",
			"label": "CAGE"
	},
	{
			"value": "CAKE",
			"label": "CAKE"
	},
	{
			"value": "CAKE ACCESSORIES",
			"label": "CAKE ACCESSORIES"
	},
	{
			"value": "CAKE BOARD",
			"label": "CAKE BOARD"
	},
	{
			"value": "CAKE BOARDS",
			"label": "CAKE BOARDS"
	},
	{
			"value": "CAKE BOXES",
			"label": "CAKE BOXES"
	},
	{
			"value": "CAKE CRAFT",
			"label": "CAKE CRAFT"
	},
	{
			"value": "CAKE LEVELER",
			"label": "CAKE LEVELER"
	},
	{
			"value": "CAKE PAINT",
			"label": "CAKE PAINT"
	},
	{
			"value": "CAKE PEDESTAL",
			"label": "CAKE PEDESTAL"
	},
	{
			"value": "CAKE PLATE",
			"label": "CAKE PLATE"
	},
	{
			"value": "CAKE SERVER",
			"label": "CAKE SERVER"
	},
	{
			"value": "CAKE SPARKLES",
			"label": "CAKE SPARKLES"
	},
	{
			"value": "CAKE STAND",
			"label": "CAKE STAND"
	},
	{
			"value": "CAKE TOPPER",
			"label": "CAKE TOPPER"
	},
	{
			"value": "CALCULATED INDUSTRIE",
			"label": "CALCULATED INDUSTRIE"
	},
	{
			"value": "CALENDAR",
			"label": "CALENDAR"
	},
	{
			"value": "CALENDAR DISPLAY",
			"label": "CALENDAR DISPLAY"
	},
	{
			"value": "CALENDARS",
			"label": "CALENDARS"
	},
	{
			"value": "CALICO CRITTERS",
			"label": "CALICO CRITTERS"
	},
	{
			"value": "CALLA",
			"label": "CALLA"
	},
	{
			"value": "CALLA LILLY",
			"label": "CALLA LILLY"
	},
	{
			"value": "CALLA LILY",
			"label": "CALLA LILY"
	},
	{
			"value": "CALLEN",
			"label": "CALLEN"
	},
	{
			"value": "CALLIGRAPHER",
			"label": "CALLIGRAPHER"
	},
	{
			"value": "CALLIGRAPHY",
			"label": "CALLIGRAPHY"
	},
	{
			"value": "CALLIOPSIS",
			"label": "CALLIOPSIS"
	},
	{
			"value": "CAMEL",
			"label": "CAMEL"
	},
	{
			"value": "CAMELLIA",
			"label": "CAMELLIA"
	},
	{
			"value": "CAMEO",
			"label": "CAMEO"
	},
	{
			"value": "CAMEO 3",
			"label": "CAMEO 3"
	},
	{
			"value": "CAMEO 4",
			"label": "CAMEO 4"
	},
	{
			"value": "CAMEO 4 PLUS",
			"label": "CAMEO 4 PLUS"
	},
	{
			"value": "CAMEO 4 PRO",
			"label": "CAMEO 4 PRO"
	},
	{
			"value": "CAMEO LACE TULLE",
			"label": "CAMEO LACE TULLE"
	},
	{
			"value": "CAMERA",
			"label": "CAMERA"
	},
	{
			"value": "CAMILLA",
			"label": "CAMILLA"
	},
	{
			"value": "CAMO",
			"label": "CAMO"
	},
	{
			"value": "CAMOFLAUGE",
			"label": "CAMOFLAUGE"
	},
	{
			"value": "CAMO FLEECE",
			"label": "CAMO FLEECE"
	},
	{
			"value": "CAMOUFLAGE",
			"label": "CAMOUFLAGE"
	},
	{
			"value": "CAMP ANN",
			"label": "CAMP ANN"
	},
	{
			"value": "CAMPANULA",
			"label": "CAMPANULA"
	},
	{
			"value": "CAMPBELLS",
			"label": "CAMPBELLS"
	},
	{
			"value": "CAMPING",
			"label": "CAMPING"
	},
	{
			"value": "CAMP MUG",
			"label": "CAMP MUG"
	},
	{
			"value": "CANDELABRA",
			"label": "CANDELABRA"
	},
	{
			"value": "CANDLE",
			"label": "CANDLE"
	},
	{
			"value": "CANDLE ACCESSORIES",
			"label": "CANDLE ACCESSORIES"
	},
	{
			"value": "CANDLE CADDY",
			"label": "CANDLE CADDY"
	},
	{
			"value": "CANDLE COLORANT",
			"label": "CANDLE COLORANT"
	},
	{
			"value": "CANDLE CONTAINER",
			"label": "CANDLE CONTAINER"
	},
	{
			"value": "CANDLE FIGURAL",
			"label": "CANDLE FIGURAL"
	},
	{
			"value": "CANDLE FRAGRANCE",
			"label": "CANDLE FRAGRANCE"
	},
	{
			"value": "CANDLE GLASS",
			"label": "CANDLE GLASS"
	},
	{
			"value": "CANDLE HOLDER",
			"label": "CANDLE HOLDER"
	},
	{
			"value": "CANDLEHOLDER",
			"label": "CANDLEHOLDER"
	},
	{
			"value": "CANDLE JAR",
			"label": "CANDLE JAR"
	},
	{
			"value": "CANDLE LAMP",
			"label": "CANDLE LAMP"
	},
	{
			"value": "CANDLE LED",
			"label": "CANDLE LED"
	},
	{
			"value": "CANDLE MAKING",
			"label": "CANDLE MAKING"
	},
	{
			"value": "CANDLE MOLD",
			"label": "CANDLE MOLD"
	},
	{
			"value": "CANDLE PILLAR",
			"label": "CANDLE PILLAR"
	},
	{
			"value": "CANDLE RING",
			"label": "CANDLE RING"
	},
	{
			"value": "CANDLERING",
			"label": "CANDLERING"
	},
	{
			"value": "CANDLES",
			"label": "CANDLES"
	},
	{
			"value": "CANDLE SLEEVE",
			"label": "CANDLE SLEEVE"
	},
	{
			"value": "CANDLESTICK",
			"label": "CANDLESTICK"
	},
	{
			"value": "CANDLE STICKS",
			"label": "CANDLE STICKS"
	},
	{
			"value": "CANDLE TAPER",
			"label": "CANDLE TAPER"
	},
	{
			"value": "CANDLE TEA LIGHT",
			"label": "CANDLE TEA LIGHT"
	},
	{
			"value": "CANDLE TEALIGHT",
			"label": "CANDLE TEALIGHT"
	},
	{
			"value": "CANDLE TIN",
			"label": "CANDLE TIN"
	},
	{
			"value": "CANDLE VASE",
			"label": "CANDLE VASE"
	},
	{
			"value": "CANDLE WAX",
			"label": "CANDLE WAX"
	},
	{
			"value": "CANDLE WICK",
			"label": "CANDLE WICK"
	},
	{
			"value": "CANDY",
			"label": "CANDY"
	},
	{
			"value": "CANDY ACCESSORIES",
			"label": "CANDY ACCESSORIES"
	},
	{
			"value": "CANDY BOWL",
			"label": "CANDY BOWL"
	},
	{
			"value": "CANDY BOWL ANIMATION",
			"label": "CANDY BOWL ANIMATION"
	},
	{
			"value": "CANDY CANE",
			"label": "CANDY CANE"
	},
	{
			"value": "CANDY CANES",
			"label": "CANDY CANES"
	},
	{
			"value": "CANDY CORN",
			"label": "CANDY CORN"
	},
	{
			"value": "CANDY DECORATION",
			"label": "CANDY DECORATION"
	},
	{
			"value": "CANDY DISH",
			"label": "CANDY DISH"
	},
	{
			"value": "CANDY DOT TULLE",
			"label": "CANDY DOT TULLE"
	},
	{
			"value": "CANDYLEAF",
			"label": "CANDYLEAF"
	},
	{
			"value": "CANDY MAKING",
			"label": "CANDY MAKING"
	},
	{
			"value": "CANDY MELT",
			"label": "CANDY MELT"
	},
	{
			"value": "CANDY MELTS",
			"label": "CANDY MELTS"
	},
	{
			"value": "CANDY MOLD",
			"label": "CANDY MOLD"
	},
	{
			"value": "CANDY MOLDS",
			"label": "CANDY MOLDS"
	},
	{
			"value": "CAN HOLDER",
			"label": "CAN HOLDER"
	},
	{
			"value": "CANISTER",
			"label": "CANISTER"
	},
	{
			"value": "CANNISTER",
			"label": "CANNISTER"
	},
	{
			"value": "CANSON",
			"label": "CANSON"
	},
	{
			"value": "CANVAS",
			"label": "CANVAS"
	},
	{
			"value": "CANVAS 4X6",
			"label": "CANVAS 4X6"
	},
	{
			"value": "CANVAS 8X8",
			"label": "CANVAS 8X8"
	},
	{
			"value": "CANVAS BANNER",
			"label": "CANVAS BANNER"
	},
	{
			"value": "CANVAS CORP",
			"label": "CANVAS CORP"
	},
	{
			"value": "CANVAS PATCH",
			"label": "CANVAS PATCH"
	},
	{
			"value": "CANVAS TOTE",
			"label": "CANVAS TOTE"
	},
	{
			"value": "CANVAS WALL DECOR",
			"label": "CANVAS WALL DECOR"
	},
	{
			"value": "CANVAS WALL DÉCOR",
			"label": "CANVAS WALL DÉCOR"
	},
	{
			"value": "CANVAS WALL HANGING",
			"label": "CANVAS WALL HANGING"
	},
	{
			"value": "CANVSA00",
			"label": "CANVSA00"
	},
	{
			"value": "CAPE",
			"label": "CAPE"
	},
	{
			"value": "CAPE ADULT",
			"label": "CAPE ADULT"
	},
	{
			"value": "CAPES",
			"label": "CAPES"
	},
	{
			"value": "CAPIZ BALL",
			"label": "CAPIZ BALL"
	},
	{
			"value": "CAPIZ BASKET",
			"label": "CAPIZ BASKET"
	},
	{
			"value": "CAPIZ GARLAND",
			"label": "CAPIZ GARLAND"
	},
	{
			"value": "CAR",
			"label": "CAR"
	},
	{
			"value": "CARABINER",
			"label": "CARABINER"
	},
	{
			"value": "CARABINERS",
			"label": "CARABINERS"
	},
	{
			"value": "CARAMELS",
			"label": "CARAMELS"
	},
	{
			"value": "CARBATION",
			"label": "CARBATION"
	},
	{
			"value": "CAR CRUSHER FLNL",
			"label": "CAR CRUSHER FLNL"
	},
	{
			"value": "CARD",
			"label": "CARD"
	},
	{
			"value": "CARDED FLOWERS",
			"label": "CARDED FLOWERS"
	},
	{
			"value": "CARDED ROSE",
			"label": "CARDED ROSE"
	},
	{
			"value": "CARD GAME",
			"label": "CARD GAME"
	},
	{
			"value": "CARD HOLDER",
			"label": "CARD HOLDER"
	},
	{
			"value": "CARDINAL",
			"label": "CARDINAL"
	},
	{
			"value": "CARDMAKING",
			"label": "CARDMAKING"
	},
	{
			"value": "CARDS",
			"label": "CARDS"
	},
	{
			"value": "CARDS PDQ",
			"label": "CARDS PDQ"
	},
	{
			"value": "CARDSTOCK",
			"label": "CARDSTOCK"
	},
	{
			"value": "CARE",
			"label": "CARE"
	},
	{
			"value": "CAREER",
			"label": "CAREER"
	},
	{
			"value": "CARIBBEAN",
			"label": "CARIBBEAN"
	},
	{
			"value": "CARMEL APPLE",
			"label": "CARMEL APPLE"
	},
	{
			"value": "CARNATION",
			"label": "CARNATION"
	},
	{
			"value": "CARNATION BUSH",
			"label": "CARNATION BUSH"
	},
	{
			"value": "CARNIVAL",
			"label": "CARNIVAL"
	},
	{
			"value": "CAROLER",
			"label": "CAROLER"
	},
	{
			"value": "CAROLINA",
			"label": "CAROLINA"
	},
	{
			"value": "CAROLINA MANUFACTURI",
			"label": "CAROLINA MANUFACTURI"
	},
	{
			"value": "CARON",
			"label": "CARON"
	},
	{
			"value": "CARON/BOND AMERICA",
			"label": "CARON/BOND AMERICA"
	},
	{
			"value": "CARPE DIEM",
			"label": "CARPE DIEM"
	},
	{
			"value": "CARPET SAVER",
			"label": "CARPET SAVER"
	},
	{
			"value": "CARRIER",
			"label": "CARRIER"
	},
	{
			"value": "CARROT",
			"label": "CARROT"
	},
	{
			"value": "CARROT DECOR",
			"label": "CARROT DECOR"
	},
	{
			"value": "CARROT ORNAMENT",
			"label": "CARROT ORNAMENT"
	},
	{
			"value": "CARROTS",
			"label": "CARROTS"
	},
	{
			"value": "CARRY",
			"label": "CARRY"
	},
	{
			"value": "CARS",
			"label": "CARS"
	},
	{
			"value": "CARSON OPTICAL",
			"label": "CARSON OPTICAL"
	},
	{
			"value": "CART",
			"label": "CART"
	},
	{
			"value": "CART ACCESSORY",
			"label": "CART ACCESSORY"
	},
	{
			"value": "CARTRIDGE",
			"label": "CARTRIDGE"
	},
	{
			"value": "CARVABLE PUMPKIN",
			"label": "CARVABLE PUMPKIN"
	},
	{
			"value": "CARVING KIT",
			"label": "CARVING KIT"
	},
	{
			"value": "CARVING TOOL",
			"label": "CARVING TOOL"
	},
	{
			"value": "CASA",
			"label": "CASA"
	},
	{
			"value": "CASA EMBELLISH EVENT",
			"label": "CASA EMBELLISH EVENT"
	},
	{
			"value": "CASA EMBELLISH FALL",
			"label": "CASA EMBELLISH FALL"
	},
	{
			"value": "CASA NOVELTY",
			"label": "CASA NOVELTY"
	},
	{
			"value": "CASA SOLID",
			"label": "CASA SOLID"
	},
	{
			"value": "CASE",
			"label": "CASE"
	},
	{
			"value": "CASPIA",
			"label": "CASPIA"
	},
	{
			"value": "CASSEROLE TOTE",
			"label": "CASSEROLE TOTE"
	},
	{
			"value": "CASSETTES",
			"label": "CASSETTES"
	},
	{
			"value": "CASUAL ELEGANCE",
			"label": "CASUAL ELEGANCE"
	},
	{
			"value": "CASUAL HOME",
			"label": "CASUAL HOME"
	},
	{
			"value": "CAT",
			"label": "CAT"
	},
	{
			"value": "CAT /DOG",
			"label": "CAT /DOG"
	},
	{
			"value": "CAT DOG",
			"label": "CAT DOG"
	},
	{
			"value": "CAT/ DOG",
			"label": "CAT/ DOG"
	},
	{
			"value": "CAT/DOG",
			"label": "CAT/DOG"
	},
	{
			"value": "CAT IN THE HAT",
			"label": "CAT IN THE HAT"
	},
	{
			"value": "CAT ORN",
			"label": "CAT ORN"
	},
	{
			"value": "CATS",
			"label": "CATS"
	},
	{
			"value": "CATTAIL",
			"label": "CATTAIL"
	},
	{
			"value": "CAT TOY",
			"label": "CAT TOY"
	},
	{
			"value": "CAULDRON",
			"label": "CAULDRON"
	},
	{
			"value": "CAUSE",
			"label": "CAUSE"
	},
	{
			"value": "CAVIAR",
			"label": "CAVIAR"
	},
	{
			"value": "C&B CRAFTS",
			"label": "C&B CRAFTS"
	},
	{
			"value": "C CANDLE PDQ CANDLE",
			"label": "C CANDLE PDQ CANDLE"
	},
	{
			"value": "CC DRY FIBER",
			"label": "CC DRY FIBER"
	},
	{
			"value": "C CELL UV CANDLE",
			"label": "C CELL UV CANDLE"
	},
	{
			"value": "CDCFTA00",
			"label": "CDCFTA00"
	},
	{
			"value": "CDCFTB00",
			"label": "CDCFTB00"
	},
	{
			"value": "CEDAR",
			"label": "CEDAR"
	},
	{
			"value": "CEDAR CANYON TEXTILE",
			"label": "CEDAR CANYON TEXTILE"
	},
	{
			"value": "CEDAR CREEK QUILT DE",
			"label": "CEDAR CREEK QUILT DE"
	},
	{
			"value": "CELEBRATE FAMILY",
			"label": "CELEBRATE FAMILY"
	},
	{
			"value": "CELEBRATE SANTA",
			"label": "CELEBRATE SANTA"
	},
	{
			"value": "CELEBRATION",
			"label": "CELEBRATION"
	},
	{
			"value": "CELERY LEAVES",
			"label": "CELERY LEAVES"
	},
	{
			"value": "CELESTIAL",
			"label": "CELESTIAL"
	},
	{
			"value": "CELESTIAL/GALAXY",
			"label": "CELESTIAL/GALAXY"
	},
	{
			"value": "CELTIC",
			"label": "CELTIC"
	},
	{
			"value": "CEMENT",
			"label": "CEMENT"
	},
	{
			"value": "CEMENT CANDLE",
			"label": "CEMENT CANDLE"
	},
	{
			"value": "CEMENT CANDLE HOLDER",
			"label": "CEMENT CANDLE HOLDER"
	},
	{
			"value": "CENTERPIECE",
			"label": "CENTERPIECE"
	},
	{
			"value": "CERAMIC",
			"label": "CERAMIC"
	},
	{
			"value": "CERAMIC ANIMAL",
			"label": "CERAMIC ANIMAL"
	},
	{
			"value": "CERAMIC BIRDFEEDER",
			"label": "CERAMIC BIRDFEEDER"
	},
	{
			"value": "CERAMIC BIRDHOUSE",
			"label": "CERAMIC BIRDHOUSE"
	},
	{
			"value": "CERAMIC CANDLE",
			"label": "CERAMIC CANDLE"
	},
	{
			"value": "CERAMIC ENTERTAINING",
			"label": "CERAMIC ENTERTAINING"
	},
	{
			"value": "CERAMIC LANTERN",
			"label": "CERAMIC LANTERN"
	},
	{
			"value": "CERAMIC LIGHTS",
			"label": "CERAMIC LIGHTS"
	},
	{
			"value": "CERAMIC PITCHER",
			"label": "CERAMIC PITCHER"
	},
	{
			"value": "CERAMIC PLANTER",
			"label": "CERAMIC PLANTER"
	},
	{
			"value": "CERAMIC PLATE",
			"label": "CERAMIC PLATE"
	},
	{
			"value": "CERAMIC POT",
			"label": "CERAMIC POT"
	},
	{
			"value": "CERAMIC PUMPKIN",
			"label": "CERAMIC PUMPKIN"
	},
	{
			"value": "CERAMICS",
			"label": "CERAMICS"
	},
	{
			"value": "CERAMIC TREE",
			"label": "CERAMIC TREE"
	},
	{
			"value": "CERAMIC VASE",
			"label": "CERAMIC VASE"
	},
	{
			"value": "CERAMIC WALL DECOR",
			"label": "CERAMIC WALL DECOR"
	},
	{
			"value": "CERAMIC WAVY VASE",
			"label": "CERAMIC WAVY VASE"
	},
	{
			"value": "CERAMIC WHT",
			"label": "CERAMIC WHT"
	},
	{
			"value": "CERAMICWHT L",
			"label": "CERAMICWHT L"
	},
	{
			"value": "CERAMIC WINDCHIME",
			"label": "CERAMIC WINDCHIME"
	},
	{
			"value": "CERTIFIED MAIL",
			"label": "CERTIFIED MAIL"
	},
	{
			"value": "C FASCINATOR",
			"label": "C FASCINATOR"
	},
	{
			"value": "CFGFTB00",
			"label": "CFGFTB00"
	},
	{
			"value": "CFGFTC01",
			"label": "CFGFTC01"
	},
	{
			"value": "C-GULL",
			"label": "C-GULL"
	},
	{
			"value": "CHAIN",
			"label": "CHAIN"
	},
	{
			"value": "CHAINETTE FRINGE",
			"label": "CHAINETTE FRINGE"
	},
	{
			"value": "CHAINLINK",
			"label": "CHAINLINK"
	},
	{
			"value": "CHAINS",
			"label": "CHAINS"
	},
	{
			"value": "CHAIN STATION",
			"label": "CHAIN STATION"
	},
	{
			"value": "CHAIR",
			"label": "CHAIR"
	},
	{
			"value": "CHAIR NOTIONS",
			"label": "CHAIR NOTIONS"
	},
	{
			"value": "CHAIRS",
			"label": "CHAIRS"
	},
	{
			"value": "CHAIR TIE",
			"label": "CHAIR TIE"
	},
	{
			"value": "CHALK",
			"label": "CHALK"
	},
	{
			"value": "CHALK ART",
			"label": "CHALK ART"
	},
	{
			"value": "CHALKBOARD",
			"label": "CHALKBOARD"
	},
	{
			"value": "CHALKBOARDS",
			"label": "CHALKBOARDS"
	},
	{
			"value": "CHALKBOARD WALL DECO",
			"label": "CHALKBOARD WALL DECO"
	},
	{
			"value": "CHALK WALL DECOR",
			"label": "CHALK WALL DECOR"
	},
	{
			"value": "CHAMOIS",
			"label": "CHAMOIS"
	},
	{
			"value": "CHAMPAGNE",
			"label": "CHAMPAGNE"
	},
	{
			"value": "CHAMPAGNE GLASS",
			"label": "CHAMPAGNE GLASS"
	},
	{
			"value": "CHANDELIER",
			"label": "CHANDELIER"
	},
	{
			"value": "CHANNEL STRIP",
			"label": "CHANNEL STRIP"
	},
	{
			"value": "CHANNEL STRIPS",
			"label": "CHANNEL STRIPS"
	},
	{
			"value": "CHARACTER",
			"label": "CHARACTER"
	},
	{
			"value": "CHARACTER COTTON",
			"label": "CHARACTER COTTON"
	},
	{
			"value": "CHARACTER FBS",
			"label": "CHARACTER FBS"
	},
	{
			"value": "CHARACTER FINISHED",
			"label": "CHARACTER FINISHED"
	},
	{
			"value": "CHARACTER FLANNEL",
			"label": "CHARACTER FLANNEL"
	},
	{
			"value": "CHARACTER FLEECE",
			"label": "CHARACTER FLEECE"
	},
	{
			"value": "CHARACTER HALLOWEEN",
			"label": "CHARACTER HALLOWEEN"
	},
	{
			"value": "CHARACTER LICENSE",
			"label": "CHARACTER LICENSE"
	},
	{
			"value": "CHARACTER MINKY",
			"label": "CHARACTER MINKY"
	},
	{
			"value": "CHARACTER RIBBON",
			"label": "CHARACTER RIBBON"
	},
	{
			"value": "CHARACTER TRUESUEDE",
			"label": "CHARACTER TRUESUEDE"
	},
	{
			"value": "CHAR FOAM STICKER",
			"label": "CHAR FOAM STICKER"
	},
	{
			"value": "CHARGER",
			"label": "CHARGER"
	},
	{
			"value": "CHARGERS",
			"label": "CHARGERS"
	},
	{
			"value": "CHARGING CABLE",
			"label": "CHARGING CABLE"
	},
	{
			"value": "CHARGING PAD",
			"label": "CHARGING PAD"
	},
	{
			"value": "CHARM",
			"label": "CHARM"
	},
	{
			"value": "CHARMED",
			"label": "CHARMED"
	},
	{
			"value": "CHARMEUSE",
			"label": "CHARMEUSE"
	},
	{
			"value": "CHARM HOLDER",
			"label": "CHARM HOLDER"
	},
	{
			"value": "CHARMS",
			"label": "CHARMS"
	},
	{
			"value": "CHARTPAK",
			"label": "CHARTPAK"
	},
	{
			"value": "CHART TABLET",
			"label": "CHART TABLET"
	},
	{
			"value": "CHAR ULTRA CUDDLE",
			"label": "CHAR ULTRA CUDDLE"
	},
	{
			"value": "CHASE",
			"label": "CHASE"
	},
	{
			"value": "CHEAP OSO",
			"label": "CHEAP OSO"
	},
	{
			"value": "CHECK",
			"label": "CHECK"
	},
	{
			"value": "CHECKER",
			"label": "CHECKER"
	},
	{
			"value": "CHECKERS",
			"label": "CHECKERS"
	},
	{
			"value": "CHECKLIST",
			"label": "CHECKLIST"
	},
	{
			"value": "CHECK & PLAID",
			"label": "CHECK & PLAID"
	},
	{
			"value": "CHECK PLAID",
			"label": "CHECK PLAID"
	},
	{
			"value": "CHECKS",
			"label": "CHECKS"
	},
	{
			"value": "CHECKS & PLAIDS",
			"label": "CHECKS & PLAIDS"
	},
	{
			"value": "CHEER BOWS",
			"label": "CHEER BOWS"
	},
	{
			"value": "CHEESE BOARD",
			"label": "CHEESE BOARD"
	},
	{
			"value": "CHEESE CLOTH",
			"label": "CHEESE CLOTH"
	},
	{
			"value": "CHEESECLOTH",
			"label": "CHEESECLOTH"
	},
	{
			"value": "CHEETAH",
			"label": "CHEETAH"
	},
	{
			"value": "CHEETAH PRINT",
			"label": "CHEETAH PRINT"
	},
	{
			"value": "CHEETAH TOWEL",
			"label": "CHEETAH TOWEL"
	},
	{
			"value": "CHEF HAT",
			"label": "CHEF HAT"
	},
	{
			"value": "CHEMICAL",
			"label": "CHEMICAL"
	},
	{
			"value": "CHENILLE",
			"label": "CHENILLE"
	},
	{
			"value": "CHERRY",
			"label": "CHERRY"
	},
	{
			"value": "CHERRY BLOSS",
			"label": "CHERRY BLOSS"
	},
	{
			"value": "CHERRY BLOSSOM",
			"label": "CHERRY BLOSSOM"
	},
	{
			"value": "CHERRY BLOSSOOM",
			"label": "CHERRY BLOSSOOM"
	},
	{
			"value": "CHERRY LEAVES",
			"label": "CHERRY LEAVES"
	},
	{
			"value": "CHESHIRE",
			"label": "CHESHIRE"
	},
	{
			"value": "CHEVRON",
			"label": "CHEVRON"
	},
	{
			"value": "CHEVRONS",
			"label": "CHEVRONS"
	},
	{
			"value": "CHEVRON TULLE",
			"label": "CHEVRON TULLE"
	},
	{
			"value": "CHIAOGOO",
			"label": "CHIAOGOO"
	},
	{
			"value": "CHI BLACKHAWKS",
			"label": "CHI BLACKHAWKS"
	},
	{
			"value": "CHICAGO METALLIC",
			"label": "CHICAGO METALLIC"
	},
	{
			"value": "CHICK",
			"label": "CHICK"
	},
	{
			"value": "CHICKADEE",
			"label": "CHICKADEE"
	},
	{
			"value": "CHICK DECOR",
			"label": "CHICK DECOR"
	},
	{
			"value": "CHICKECN",
			"label": "CHICKECN"
	},
	{
			"value": "CHICKEN STITCHIN",
			"label": "CHICKEN STITCHIN"
	},
	{
			"value": "CHICKS",
			"label": "CHICKS"
	},
	{
			"value": "CHI CUBS",
			"label": "CHI CUBS"
	},
	{
			"value": "CHIFFON",
			"label": "CHIFFON"
	},
	{
			"value": "CHIFFON CREPE",
			"label": "CHIFFON CREPE"
	},
	{
			"value": "CHILD APRON",
			"label": "CHILD APRON"
	},
	{
			"value": "CHILD CAROLER",
			"label": "CHILD CAROLER"
	},
	{
			"value": "CHILD HAT",
			"label": "CHILD HAT"
	},
	{
			"value": "CHILD HEADBAND",
			"label": "CHILD HEADBAND"
	},
	{
			"value": "CHILD MASK",
			"label": "CHILD MASK"
	},
	{
			"value": "CHILDREN",
			"label": "CHILDREN"
	},
	{
			"value": "CHILDREN'S ACTIVITY",
			"label": "CHILDREN'S ACTIVITY"
	},
	{
			"value": "CHILD TUTU",
			"label": "CHILD TUTU"
	},
	{
			"value": "CHILL",
			"label": "CHILL"
	},
	{
			"value": "CHILL TOWEL",
			"label": "CHILL TOWEL"
	},
	{
			"value": "CHINESE LANTERN",
			"label": "CHINESE LANTERN"
	},
	{
			"value": "CHINESE NEW YEAR",
			"label": "CHINESE NEW YEAR"
	},
	{
			"value": "CHINOISERIE HOLIDAY",
			"label": "CHINOISERIE HOLIDAY"
	},
	{
			"value": "CHIPBOARD",
			"label": "CHIPBOARD"
	},
	{
			"value": "CHIP BOWL",
			"label": "CHIP BOWL"
	},
	{
			"value": "CHIP DIP TRAY",
			"label": "CHIP DIP TRAY"
	},
	{
			"value": "CHIPS",
			"label": "CHIPS"
	},
	{
			"value": "CHISEL BLNDER",
			"label": "CHISEL BLNDER"
	},
	{
			"value": "CHOCOLATE",
			"label": "CHOCOLATE"
	},
	{
			"value": "CHOC PIECES",
			"label": "CHOC PIECES"
	},
	{
			"value": "CHOO CHOO TRAIN",
			"label": "CHOO CHOO TRAIN"
	},
	{
			"value": "CHRISTMAS",
			"label": "CHRISTMAS"
	},
	{
			"value": "CHRISTMAS CANDY CANE",
			"label": "CHRISTMAS CANDY CANE"
	},
	{
			"value": "CHRISTMAS CHARACTER",
			"label": "CHRISTMAS CHARACTER"
	},
	{
			"value": "CHRISTMAS CHEER",
			"label": "CHRISTMAS CHEER"
	},
	{
			"value": "CHRISTMAS CLIP STRIP",
			"label": "CHRISTMAS CLIP STRIP"
	},
	{
			"value": "CHRISTMAS CONFECTION",
			"label": "CHRISTMAS CONFECTION"
	},
	{
			"value": "CHRISTMAS DECOR",
			"label": "CHRISTMAS DECOR"
	},
	{
			"value": "CHRISTMAS KRAFT",
			"label": "CHRISTMAS KRAFT"
	},
	{
			"value": "CHRISTMAS LIGHT TIES",
			"label": "CHRISTMAS LIGHT TIES"
	},
	{
			"value": "CHRISTMAS MEMORIES",
			"label": "CHRISTMAS MEMORIES"
	},
	{
			"value": "CHRISTMAS MEMORIES B",
			"label": "CHRISTMAS MEMORIES B"
	},
	{
			"value": "CHRISTMAS MEMORIES E",
			"label": "CHRISTMAS MEMORIES E"
	},
	{
			"value": "CHRISTMAS MEMORIES W",
			"label": "CHRISTMAS MEMORIES W"
	},
	{
			"value": "CHRISTMAS PAPER MACH",
			"label": "CHRISTMAS PAPER MACH"
	},
	{
			"value": "CHRISTMAS TRADITIONS",
			"label": "CHRISTMAS TRADITIONS"
	},
	{
			"value": "CHRISTMAS TREE",
			"label": "CHRISTMAS TREE"
	},
	{
			"value": "CHRISTMAS TREES",
			"label": "CHRISTMAS TREES"
	},
	{
			"value": "CHRISTMAS VACATION",
			"label": "CHRISTMAS VACATION"
	},
	{
			"value": "CHRISTMAS WHIMSY",
			"label": "CHRISTMAS WHIMSY"
	},
	{
			"value": "CHRONICLE BOOKS",
			"label": "CHRONICLE BOOKS"
	},
	{
			"value": "CHUBBY NUTCRACKER",
			"label": "CHUBBY NUTCRACKER"
	},
	{
			"value": "CHUGGA",
			"label": "CHUGGA"
	},
	{
			"value": "CHUNKY GLITTER",
			"label": "CHUNKY GLITTER"
	},
	{
			"value": "CHUNKY GLITTER TULLE",
			"label": "CHUNKY GLITTER TULLE"
	},
	{
			"value": "CHUNKY WOOD",
			"label": "CHUNKY WOOD"
	},
	{
			"value": "CIMICIFUGA",
			"label": "CIMICIFUGA"
	},
	{
			"value": "CINCINNATI",
			"label": "CINCINNATI"
	},
	{
			"value": "CINDUS",
			"label": "CINDUS"
	},
	{
			"value": "CINNAMON",
			"label": "CINNAMON"
	},
	{
			"value": "CINN PINECONES",
			"label": "CINN PINECONES"
	},
	{
			"value": "CINN STICKS",
			"label": "CINN STICKS"
	},
	{
			"value": "CIRCLE",
			"label": "CIRCLE"
	},
	{
			"value": "CIRCLE LINKS",
			"label": "CIRCLE LINKS"
	},
	{
			"value": "CIRCLES",
			"label": "CIRCLES"
	},
	{
			"value": "CITRONELLA BLK",
			"label": "CITRONELLA BLK"
	},
	{
			"value": "CITRONELLA CANDLE",
			"label": "CITRONELLA CANDLE"
	},
	{
			"value": "CITRONELLA PAIL",
			"label": "CITRONELLA PAIL"
	},
	{
			"value": "CITRUS",
			"label": "CITRUS"
	},
	{
			"value": "CITY",
			"label": "CITY"
	},
	{
			"value": "CK MEDIA",
			"label": "CK MEDIA"
	},
	{
			"value": "CLASP",
			"label": "CLASP"
	},
	{
			"value": "CLASP BUCKLE",
			"label": "CLASP BUCKLE"
	},
	{
			"value": "CLASSIC",
			"label": "CLASSIC"
	},
	{
			"value": "CLASSIC 9X12",
			"label": "CLASSIC 9X12"
	},
	{
			"value": "CLASSICAL CHRISTMAS",
			"label": "CLASSICAL CHRISTMAS"
	},
	{
			"value": "CLASSIC AMERICANA",
			"label": "CLASSIC AMERICANA"
	},
	{
			"value": "CLASSIC DISNEY",
			"label": "CLASSIC DISNEY"
	},
	{
			"value": "CLASSIC HALLOWEEN RI",
			"label": "CLASSIC HALLOWEEN RI"
	},
	{
			"value": "CLASSIC QUILTS",
			"label": "CLASSIC QUILTS"
	},
	{
			"value": "CLASS IN BOX",
			"label": "CLASS IN BOX"
	},
	{
			"value": "CLASS PUBLICATIONS",
			"label": "CLASS PUBLICATIONS"
	},
	{
			"value": "CLAY BOOKS",
			"label": "CLAY BOOKS"
	},
	{
			"value": "CLAY JEWELRY",
			"label": "CLAY JEWELRY"
	},
	{
			"value": "CLAYS",
			"label": "CLAYS"
	},
	{
			"value": "CLAYSC00",
			"label": "CLAYSC00"
	},
	{
			"value": "CLAYZEES",
			"label": "CLAYZEES"
	},
	{
			"value": "CLEANING",
			"label": "CLEANING"
	},
	{
			"value": "CLEANING GLOVES",
			"label": "CLEANING GLOVES"
	},
	{
			"value": "CLEANING TRAY SET",
			"label": "CLEANING TRAY SET"
	},
	{
			"value": "CLEAR GLASS",
			"label": "CLEAR GLASS"
	},
	{
			"value": "CLEAR LID TIN",
			"label": "CLEAR LID TIN"
	},
	{
			"value": "CLEAR LIGHTS",
			"label": "CLEAR LIGHTS"
	},
	{
			"value": "CLEAR NET LIGHTS",
			"label": "CLEAR NET LIGHTS"
	},
	{
			"value": "CLEAR SCRAPS",
			"label": "CLEAR SCRAPS"
	},
	{
			"value": "CLEAR SEQUINS",
			"label": "CLEAR SEQUINS"
	},
	{
			"value": "CLEARSNAP",
			"label": "CLEARSNAP"
	},
	{
			"value": "CLEAR TOP",
			"label": "CLEAR TOP"
	},
	{
			"value": "CLEAR VINYL",
			"label": "CLEAR VINYL"
	},
	{
			"value": "CLEAR ZIP",
			"label": "CLEAR ZIP"
	},
	{
			"value": "CLE CAVS",
			"label": "CLE CAVS"
	},
	{
			"value": "CLEMANTIS",
			"label": "CLEMANTIS"
	},
	{
			"value": "CLEMATIS",
			"label": "CLEMATIS"
	},
	{
			"value": "CLEMSON",
			"label": "CLEMSON"
	},
	{
			"value": "C-LINE",
			"label": "C-LINE"
	},
	{
			"value": "CLING",
			"label": "CLING"
	},
	{
			"value": "CLIP",
			"label": "CLIP"
	},
	{
			"value": "CLIP BOARD",
			"label": "CLIP BOARD"
	},
	{
			"value": "CLIPBOARD",
			"label": "CLIPBOARD"
	},
	{
			"value": "CLIP DOT",
			"label": "CLIP DOT"
	},
	{
			"value": "CLIP EEZ",
			"label": "CLIP EEZ"
	},
	{
			"value": "CLIP ON EARRINGS",
			"label": "CLIP ON EARRINGS"
	},
	{
			"value": "CLIP ON LIGHT",
			"label": "CLIP ON LIGHT"
	},
	{
			"value": "CLIP ON MAG",
			"label": "CLIP ON MAG"
	},
	{
			"value": "CLIP RING",
			"label": "CLIP RING"
	},
	{
			"value": "CLIPS",
			"label": "CLIPS"
	},
	{
			"value": "CLIP STRIP",
			"label": "CLIP STRIP"
	},
	{
			"value": "CLIPSTRIP",
			"label": "CLIPSTRIP"
	},
	{
			"value": "CLIPSTRIP COSPLAY",
			"label": "CLIPSTRIP COSPLAY"
	},
	{
			"value": "CLIPSTRIP HOLIDAY",
			"label": "CLIPSTRIP HOLIDAY"
	},
	{
			"value": "CLOCHE",
			"label": "CLOCHE"
	},
	{
			"value": "CLOCK",
			"label": "CLOCK"
	},
	{
			"value": "CLOGS",
			"label": "CLOGS"
	},
	{
			"value": "CLOSED RINGS",
			"label": "CLOSED RINGS"
	},
	{
			"value": "CLOSURE",
			"label": "CLOSURE"
	},
	{
			"value": "CLOTH ENTERTAINING",
			"label": "CLOTH ENTERTAINING"
	},
	{
			"value": "CLOTHES",
			"label": "CLOTHES"
	},
	{
			"value": "CLOTHESPIN",
			"label": "CLOTHESPIN"
	},
	{
			"value": "CLOTH/PLASTIC STORAG",
			"label": "CLOTH/PLASTIC STORAG"
	},
	{
			"value": "CLOTH STORAGE",
			"label": "CLOTH STORAGE"
	},
	{
			"value": "CLOUD",
			"label": "CLOUD"
	},
	{
			"value": "CLOUDS",
			"label": "CLOUDS"
	},
	{
			"value": "CLOVER",
			"label": "CLOVER"
	},
	{
			"value": "CLUNY",
			"label": "CLUNY"
	},
	{
			"value": "CLUTCH",
			"label": "CLUTCH"
	},
	{
			"value": "CM DESIGNS",
			"label": "CM DESIGNS"
	},
	{
			"value": "CNDIMNT BOWLS",
			"label": "CNDIMNT BOWLS"
	},
	{
			"value": "CNVAS",
			"label": "CNVAS"
	},
	{
			"value": "CNVASB00",
			"label": "CNVASB00"
	},
	{
			"value": "CNVASD00",
			"label": "CNVASD00"
	},
	{
			"value": "COASTAL",
			"label": "COASTAL"
	},
	{
			"value": "COASTER",
			"label": "COASTER"
	},
	{
			"value": "COASTER BLANK",
			"label": "COASTER BLANK"
	},
	{
			"value": "COASTERS",
			"label": "COASTERS"
	},
	{
			"value": "COASTER SET",
			"label": "COASTER SET"
	},
	{
			"value": "COASTERS SET 4",
			"label": "COASTERS SET 4"
	},
	{
			"value": "COATS & CLARK",
			"label": "COATS & CLARK"
	},
	{
			"value": "COATS  CROCHET & FLO",
			"label": "COATS  CROCHET & FLO"
	},
	{
			"value": "COATS: CROCHET & FLO",
			"label": "COATS: CROCHET & FLO"
	},
	{
			"value": "COATS CROCHET & FLOS",
			"label": "COATS CROCHET & FLOS"
	},
	{
			"value": "COATS: THREAD & ZIPP",
			"label": "COATS: THREAD & ZIPP"
	},
	{
			"value": "COATS  YARN",
			"label": "COATS  YARN"
	},
	{
			"value": "COATS YARN",
			"label": "COATS YARN"
	},
	{
			"value": "COATS: YARN",
			"label": "COATS: YARN"
	},
	{
			"value": "COCKTAIL",
			"label": "COCKTAIL"
	},
	{
			"value": "COCKTAIL SHAKER",
			"label": "COCKTAIL SHAKER"
	},
	{
			"value": "COCKTAL",
			"label": "COCKTAL"
	},
	{
			"value": "COCO LINER",
			"label": "COCO LINER"
	},
	{
			"value": "COCO LINERS",
			"label": "COCO LINERS"
	},
	{
			"value": "COCONUT PALM",
			"label": "COCONUT PALM"
	},
	{
			"value": "COCO PLANTER",
			"label": "COCO PLANTER"
	},
	{
			"value": "COFFEE",
			"label": "COFFEE"
	},
	{
			"value": "COFFEE GROUND",
			"label": "COFFEE GROUND"
	},
	{
			"value": "COFFEE MUG",
			"label": "COFFEE MUG"
	},
	{
			"value": "COFFIN",
			"label": "COFFIN"
	},
	{
			"value": "COFFINS",
			"label": "COFFINS"
	},
	{
			"value": "COGAN",
			"label": "COGAN"
	},
	{
			"value": "COILED BERRY",
			"label": "COILED BERRY"
	},
	{
			"value": "COILED GARLAND",
			"label": "COILED GARLAND"
	},
	{
			"value": "COIL GARLAND",
			"label": "COIL GARLAND"
	},
	{
			"value": "COIN BANK",
			"label": "COIN BANK"
	},
	{
			"value": "COIN COLLECTING",
			"label": "COIN COLLECTING"
	},
	{
			"value": "COIR",
			"label": "COIR"
	},
	{
			"value": "COIR FIGURAL",
			"label": "COIR FIGURAL"
	},
	{
			"value": "COIR KITCHY",
			"label": "COIR KITCHY"
	},
	{
			"value": "COIR MAT",
			"label": "COIR MAT"
	},
	{
			"value": "COIR MAT FIGURAL",
			"label": "COIR MAT FIGURAL"
	},
	{
			"value": "COIR MATS",
			"label": "COIR MATS"
	},
	{
			"value": "COIR MAT STANDARD",
			"label": "COIR MAT STANDARD"
	},
	{
			"value": "COIR MAT XL",
			"label": "COIR MAT XL"
	},
	{
			"value": "COIR MAT X LARGE",
			"label": "COIR MAT X LARGE"
	},
	{
			"value": "COIR STANDARD",
			"label": "COIR STANDARD"
	},
	{
			"value": "COIR XL OVERSIZED",
			"label": "COIR XL OVERSIZED"
	},
	{
			"value": "COL",
			"label": "COL"
	},
	{
			"value": "COLD CUP",
			"label": "COLD CUP"
	},
	{
			"value": "COLD WEATHER AC",
			"label": "COLD WEATHER AC"
	},
	{
			"value": "COLD WEATHER ACCESS",
			"label": "COLD WEATHER ACCESS"
	},
	{
			"value": "COLEUS",
			"label": "COLEUS"
	},
	{
			"value": "COLEUS BRANCH",
			"label": "COLEUS BRANCH"
	},
	{
			"value": "COLLAGE",
			"label": "COLLAGE"
	},
	{
			"value": "COLLAPSIBLE",
			"label": "COLLAPSIBLE"
	},
	{
			"value": "COLLAPSIBLE PLANT ST",
			"label": "COLLAPSIBLE PLANT ST"
	},
	{
			"value": "COLLAR",
			"label": "COLLAR"
	},
	{
			"value": "COLLAR/LEASH",
			"label": "COLLAR/LEASH"
	},
	{
			"value": "COLLAR LXL",
			"label": "COLLAR LXL"
	},
	{
			"value": "COLLAR SM",
			"label": "COLLAR SM"
	},
	{
			"value": "COLLAR SMMED",
			"label": "COLLAR SMMED"
	},
	{
			"value": "COLLECTIBLES POG",
			"label": "COLLECTIBLES POG"
	},
	{
			"value": "COLLECTION 1",
			"label": "COLLECTION 1"
	},
	{
			"value": "COLLEGE CANVAS",
			"label": "COLLEGE CANVAS"
	},
	{
			"value": "COLLEGE COTTON",
			"label": "COLLEGE COTTON"
	},
	{
			"value": "COLLEGE CTN",
			"label": "COLLEGE CTN"
	},
	{
			"value": "COLLEGE FLANNEL",
			"label": "COLLEGE FLANNEL"
	},
	{
			"value": "COLLEGE FLC",
			"label": "COLLEGE FLC"
	},
	{
			"value": "COLLEGE FLEECE",
			"label": "COLLEGE FLEECE"
	},
	{
			"value": "COLLEGE KNIT",
			"label": "COLLEGE KNIT"
	},
	{
			"value": "COLLEGE PANEL",
			"label": "COLLEGE PANEL"
	},
	{
			"value": "COLLEGE PKGD",
			"label": "COLLEGE PKGD"
	},
	{
			"value": "COLLEGE PNL",
			"label": "COLLEGE PNL"
	},
	{
			"value": "COLLEGE VINYL",
			"label": "COLLEGE VINYL"
	},
	{
			"value": "COLLEGIATE THROWS",
			"label": "COLLEGIATE THROWS"
	},
	{
			"value": "COLONIAL NEEDLE",
			"label": "COLONIAL NEEDLE"
	},
	{
			"value": "COLOR ACCENTS",
			"label": "COLOR ACCENTS"
	},
	{
			"value": "COLOR ACTIVITY PAD",
			"label": "COLOR ACTIVITY PAD"
	},
	{
			"value": "COLOR AND ERASE",
			"label": "COLOR AND ERASE"
	},
	{
			"value": "COLORANT",
			"label": "COLORANT"
	},
	{
			"value": "COLOR BLANKS DY0",
			"label": "COLOR BLANKS DY0"
	},
	{
			"value": "COLORBOK",
			"label": "COLORBOK"
	},
	{
			"value": "COLOR CHANGE",
			"label": "COLOR CHANGE"
	},
	{
			"value": "COLOR CHANGING VINYL",
			"label": "COLOR CHANGING VINYL"
	},
	{
			"value": "COLOR DENIM",
			"label": "COLOR DENIM"
	},
	{
			"value": "COLORED FUR",
			"label": "COLORED FUR"
	},
	{
			"value": "COLORED PENCILS",
			"label": "COLORED PENCILS"
	},
	{
			"value": "COLORED RUFFLE",
			"label": "COLORED RUFFLE"
	},
	{
			"value": "COLORED SUGAR",
			"label": "COLORED SUGAR"
	},
	{
			"value": "COLORFIL",
			"label": "COLORFIL"
	},
	{
			"value": "COLORFIN",
			"label": "COLORFIN"
	},
	{
			"value": "COLORFORMS",
			"label": "COLORFORMS"
	},
	{
			"value": "COLORFUL LEAVES",
			"label": "COLORFUL LEAVES"
	},
	{
			"value": "COLORFUL SQUARES",
			"label": "COLORFUL SQUARES"
	},
	{
			"value": "COLORING",
			"label": "COLORING"
	},
	{
			"value": "COLORING BOOK",
			"label": "COLORING BOOK"
	},
	{
			"value": "COLORING BOOKS",
			"label": "COLORING BOOKS"
	},
	{
			"value": "COLORING KIT",
			"label": "COLORING KIT"
	},
	{
			"value": "COLORING WRAP",
			"label": "COLORING WRAP"
	},
	{
			"value": "COLOR LIGHTS",
			"label": "COLOR LIGHTS"
	},
	{
			"value": "COLOR MIST",
			"label": "COLOR MIST"
	},
	{
			"value": "COLOR PENCIL",
			"label": "COLOR PENCIL"
	},
	{
			"value": "COLORPENCIL ART KIT",
			"label": "COLORPENCIL ART KIT"
	},
	{
			"value": "COLORS",
			"label": "COLORS"
	},
	{
			"value": "COLORSHOT",
			"label": "COLORSHOT"
	},
	{
			"value": "COLORS SHAPES MORE",
			"label": "COLORS SHAPES MORE"
	},
	{
			"value": "COLOR STICKER BOOK",
			"label": "COLOR STICKER BOOK"
	},
	{
			"value": "COLORUPS",
			"label": "COLORUPS"
	},
	{
			"value": "COLOR WONDER",
			"label": "COLOR WONDER"
	},
	{
			"value": "COLOR WONDER MINI",
			"label": "COLOR WONDER MINI"
	},
	{
			"value": "COME TOGETHER",
			"label": "COME TOGETHER"
	},
	{
			"value": "COMFORT GRIP",
			"label": "COMFORT GRIP"
	},
	{
			"value": "COMFORT & JOY",
			"label": "COMFORT & JOY"
	},
	{
			"value": "COMFORT JOY",
			"label": "COMFORT JOY"
	},
	{
			"value": "COMFORTLOFT",
			"label": "COMFORTLOFT"
	},
	{
			"value": "COMMAND",
			"label": "COMMAND"
	},
	{
			"value": "COMMODITY",
			"label": "COMMODITY"
	},
	{
			"value": "COMPONENTS",
			"label": "COMPONENTS"
	},
	{
			"value": "COMPOSIMOLD",
			"label": "COMPOSIMOLD"
	},
	{
			"value": "COMPOSITION BOOK",
			"label": "COMPOSITION BOOK"
	},
	{
			"value": "COMPOUND",
			"label": "COMPOUND"
	},
	{
			"value": "COMPOUND KIT",
			"label": "COMPOUND KIT"
	},
	{
			"value": "COMPUTERIZED",
			"label": "COMPUTERIZED"
	},
	{
			"value": "CONCH TT",
			"label": "CONCH TT"
	},
	{
			"value": "CONE",
			"label": "CONE"
	},
	{
			"value": "CONEFLOWER",
			"label": "CONEFLOWER"
	},
	{
			"value": "CONEFLR",
			"label": "CONEFLR"
	},
	{
			"value": "CONE TREE",
			"label": "CONE TREE"
	},
	{
			"value": "CONFETTI",
			"label": "CONFETTI"
	},
	{
			"value": "CONFETTI DOT",
			"label": "CONFETTI DOT"
	},
	{
			"value": "CONFETTI MINIS",
			"label": "CONFETTI MINIS"
	},
	{
			"value": "CONFETTI NET",
			"label": "CONFETTI NET"
	},
	{
			"value": "CONNECTOR",
			"label": "CONNECTOR"
	},
	{
			"value": "CONNECTORS",
			"label": "CONNECTORS"
	},
	{
			"value": "CONSO",
			"label": "CONSO"
	},
	{
			"value": "CONST BEADS",
			"label": "CONST BEADS"
	},
	{
			"value": "CONST BELLS",
			"label": "CONST BELLS"
	},
	{
			"value": "CONST CONFETTI",
			"label": "CONST CONFETTI"
	},
	{
			"value": "CONST CRAFT STICKS",
			"label": "CONST CRAFT STICKS"
	},
	{
			"value": "CONST EYES",
			"label": "CONST EYES"
	},
	{
			"value": "CONST FELT",
			"label": "CONST FELT"
	},
	{
			"value": "CONST FOAM PEBBLES",
			"label": "CONST FOAM PEBBLES"
	},
	{
			"value": "CONST GLASS ORN",
			"label": "CONST GLASS ORN"
	},
	{
			"value": "CONST GLITTER",
			"label": "CONST GLITTER"
	},
	{
			"value": "CONST GOOGLE EYE",
			"label": "CONST GOOGLE EYE"
	},
	{
			"value": "CONST KIT",
			"label": "CONST KIT"
	},
	{
			"value": "CONST MESH",
			"label": "CONST MESH"
	},
	{
			"value": "CONST. MISC",
			"label": "CONST. MISC"
	},
	{
			"value": "CONST PET ORNAMENTS",
			"label": "CONST PET ORNAMENTS"
	},
	{
			"value": "CONST POMS",
			"label": "CONST POMS"
	},
	{
			"value": "CONSTRUCTION",
			"label": "CONSTRUCTION"
	},
	{
			"value": "CONSTRUCTION PAPER",
			"label": "CONSTRUCTION PAPER"
	},
	{
			"value": "CONSTRUCTION TRUCKS",
			"label": "CONSTRUCTION TRUCKS"
	},
	{
			"value": "CONST SEQUIN",
			"label": "CONST SEQUIN"
	},
	{
			"value": "CONST STEM",
			"label": "CONST STEM"
	},
	{
			"value": "CONST TWINE",
			"label": "CONST TWINE"
	},
	{
			"value": "CONTACT PAPER",
			"label": "CONTACT PAPER"
	},
	{
			"value": "CONTAINER",
			"label": "CONTAINER"
	},
	{
			"value": "CONTAINERS",
			"label": "CONTAINERS"
	},
	{
			"value": "CONTAINER WOOD",
			"label": "CONTAINER WOOD"
	},
	{
			"value": "CONTEMPORARY",
			"label": "CONTEMPORARY"
	},
	{
			"value": "CONTOURED SOFT GRIP",
			"label": "CONTOURED SOFT GRIP"
	},
	{
			"value": "CONVERSATIONAL",
			"label": "CONVERSATIONAL"
	},
	{
			"value": "CONVERTER",
			"label": "CONVERTER"
	},
	{
			"value": "CONV MATT TOPPER",
			"label": "CONV MATT TOPPER"
	},
	{
			"value": "COOIE SHEET",
			"label": "COOIE SHEET"
	},
	{
			"value": "COOK BOOKS",
			"label": "COOK BOOKS"
	},
	{
			"value": "COOKIE",
			"label": "COOKIE"
	},
	{
			"value": "COOKIE ACCESSORIES",
			"label": "COOKIE ACCESSORIES"
	},
	{
			"value": "COOKIE CANISTER",
			"label": "COOKIE CANISTER"
	},
	{
			"value": "COOKIE CANISTER HEAD",
			"label": "COOKIE CANISTER HEAD"
	},
	{
			"value": "COOKIE CUTTER",
			"label": "COOKIE CUTTER"
	},
	{
			"value": "COOKIE CUTTERS",
			"label": "COOKIE CUTTERS"
	},
	{
			"value": "COOKIE DECORATIVE",
			"label": "COOKIE DECORATIVE"
	},
	{
			"value": "COOKIE JAR",
			"label": "COOKIE JAR"
	},
	{
			"value": "COOKIE PANS",
			"label": "COOKIE PANS"
	},
	{
			"value": "COOKIE PLATTER",
			"label": "COOKIE PLATTER"
	},
	{
			"value": "COOKIE PLATTER TIN",
			"label": "COOKIE PLATTER TIN"
	},
	{
			"value": "COOKIES",
			"label": "COOKIES"
	},
	{
			"value": "COOKIE SHEET",
			"label": "COOKIE SHEET"
	},
	{
			"value": "COOKIE STAMP",
			"label": "COOKIE STAMP"
	},
	{
			"value": "COOKIE TIN",
			"label": "COOKIE TIN"
	},
	{
			"value": "COOKIE TIN HEADER",
			"label": "COOKIE TIN HEADER"
	},
	{
			"value": "COOKIE TRAY",
			"label": "COOKIE TRAY"
	},
	{
			"value": "COOKIE TRY",
			"label": "COOKIE TRY"
	},
	{
			"value": "COOKING",
			"label": "COOKING"
	},
	{
			"value": "COOKING/ENTERTAIN BK",
			"label": "COOKING/ENTERTAIN BK"
	},
	{
			"value": "COOKING/ENTERTAINING",
			"label": "COOKING/ENTERTAINING"
	},
	{
			"value": "COOL BEANS",
			"label": "COOL BEANS"
	},
	{
			"value": "COOL COTTAGE",
			"label": "COOL COTTAGE"
	},
	{
			"value": "COOLER",
			"label": "COOLER"
	},
	{
			"value": "COPCO",
			"label": "COPCO"
	},
	{
			"value": "COPIC MARKER",
			"label": "COPIC MARKER"
	},
	{
			"value": "COPPER",
			"label": "COPPER"
	},
	{
			"value": "COPPER WIRE",
			"label": "COPPER WIRE"
	},
	{
			"value": "CORAL",
			"label": "CORAL"
	},
	{
			"value": "CORAL BASKET",
			"label": "CORAL BASKET"
	},
	{
			"value": "CORAL TOWEL",
			"label": "CORAL TOWEL"
	},
	{
			"value": "CORAL WD",
			"label": "CORAL WD"
	},
	{
			"value": "CORD",
			"label": "CORD"
	},
	{
			"value": "CORDED PIPING",
			"label": "CORDED PIPING"
	},
	{
			"value": "CORD ELASTIC",
			"label": "CORD ELASTIC"
	},
	{
			"value": "CORD ENDS",
			"label": "CORD ENDS"
	},
	{
			"value": "CORDING",
			"label": "CORDING"
	},
	{
			"value": "CORDING ELASTIC",
			"label": "CORDING ELASTIC"
	},
	{
			"value": "CORD METALLIC",
			"label": "CORD METALLIC"
	},
	{
			"value": "CORDS",
			"label": "CORDS"
	},
	{
			"value": "CORDS FINDINGS SET",
			"label": "CORDS FINDINGS SET"
	},
	{
			"value": "CORDUROY",
			"label": "CORDUROY"
	},
	{
			"value": "CORDUROY 18 CRM",
			"label": "CORDUROY 18 CRM"
	},
	{
			"value": "CORDUROY 18 NVY",
			"label": "CORDUROY 18 NVY"
	},
	{
			"value": "CORDUROY NOVELTY",
			"label": "CORDUROY NOVELTY"
	},
	{
			"value": "CORDUROY PRINTS",
			"label": "CORDUROY PRINTS"
	},
	{
			"value": "CORDUROY PRINTS & EM",
			"label": "CORDUROY PRINTS & EM"
	},
	{
			"value": "CORDYLING",
			"label": "CORDYLING"
	},
	{
			"value": "CORE",
			"label": "CORE"
	},
	{
			"value": "CORE COLLECTION",
			"label": "CORE COLLECTION"
	},
	{
			"value": "CORE LA MODE",
			"label": "CORE LA MODE"
	},
	{
			"value": "COREOPSIS",
			"label": "COREOPSIS"
	},
	{
			"value": "CORK",
			"label": "CORK"
	},
	{
			"value": "CORK BOARDS",
			"label": "CORK BOARDS"
	},
	{
			"value": "CORK LIGHT",
			"label": "CORK LIGHT"
	},
	{
			"value": "CORN",
			"label": "CORN"
	},
	{
			"value": "CORNEL",
			"label": "CORNEL"
	},
	{
			"value": "CORNER CIRCLE",
			"label": "CORNER CIRCLE"
	},
	{
			"value": "CORNER SCALLOP",
			"label": "CORNER SCALLOP"
	},
	{
			"value": "CORN FLOWER",
			"label": "CORN FLOWER"
	},
	{
			"value": "CORNFLOWER",
			"label": "CORNFLOWER"
	},
	{
			"value": "CORN FLR",
			"label": "CORN FLR"
	},
	{
			"value": "CORNFLR",
			"label": "CORNFLR"
	},
	{
			"value": "CORN HUSK",
			"label": "CORN HUSK"
	},
	{
			"value": "CORNUCOPIA",
			"label": "CORNUCOPIA"
	},
	{
			"value": "CORNUS",
			"label": "CORNUS"
	},
	{
			"value": "CORRUGATE",
			"label": "CORRUGATE"
	},
	{
			"value": "CORSAGE",
			"label": "CORSAGE"
	},
	{
			"value": "CORSET",
			"label": "CORSET"
	},
	{
			"value": "COSETTE",
			"label": "COSETTE"
	},
	{
			"value": "COSMETIC BAG",
			"label": "COSMETIC BAG"
	},
	{
			"value": "COSMIC",
			"label": "COSMIC"
	},
	{
			"value": "COSMO",
			"label": "COSMO"
	},
	{
			"value": "COSMO CRICKET",
			"label": "COSMO CRICKET"
	},
	{
			"value": "COSMOPOLITAN",
			"label": "COSMOPOLITAN"
	},
	{
			"value": "COSMOS",
			"label": "COSMOS"
	},
	{
			"value": "COSMOS PTTD",
			"label": "COSMOS PTTD"
	},
	{
			"value": "COSPLAY",
			"label": "COSPLAY"
	},
	{
			"value": "COSTUME",
			"label": "COSTUME"
	},
	{
			"value": "COSTUME ADULT",
			"label": "COSTUME ADULT"
	},
	{
			"value": "COSTUME CHILD",
			"label": "COSTUME CHILD"
	},
	{
			"value": "COSTUME FUR",
			"label": "COSTUME FUR"
	},
	{
			"value": "COSTUME SET",
			"label": "COSTUME SET"
	},
	{
			"value": "COSTUME SUEDE",
			"label": "COSTUME SUEDE"
	},
	{
			"value": "COSTUME SUEDECLOTH",
			"label": "COSTUME SUEDECLOTH"
	},
	{
			"value": "COTSWOLD",
			"label": "COTSWOLD"
	},
	{
			"value": "COTSWOLD INDUSTRIES",
			"label": "COTSWOLD INDUSTRIES"
	},
	{
			"value": "COTTAGE",
			"label": "COTTAGE"
	},
	{
			"value": "COTTAGE-A",
			"label": "COTTAGE-A"
	},
	{
			"value": "COTTAGE-B",
			"label": "COTTAGE-B"
	},
	{
			"value": "COTTAGECORE",
			"label": "COTTAGECORE"
	},
	{
			"value": "COTTAGE MILLS",
			"label": "COTTAGE MILLS"
	},
	{
			"value": "COTTON",
			"label": "COTTON"
	},
	{
			"value": "COTTON D1",
			"label": "COTTON D1"
	},
	{
			"value": "COTTOND2",
			"label": "COTTOND2"
	},
	{
			"value": "COTTON FEEL",
			"label": "COTTON FEEL"
	},
	{
			"value": "COTTON LAWN",
			"label": "COTTON LAWN"
	},
	{
			"value": "COTTON LINEN BLEND",
			"label": "COTTON LINEN BLEND"
	},
	{
			"value": "COTTON MODAL",
			"label": "COTTON MODAL"
	},
	{
			"value": "COTTON OPP BUY",
			"label": "COTTON OPP BUY"
	},
	{
			"value": "COTTON POD",
			"label": "COTTON POD"
	},
	{
			"value": "COTTON SHEETS",
			"label": "COTTON SHEETS"
	},
	{
			"value": "COTTON TENCEL",
			"label": "COTTON TENCEL"
	},
	{
			"value": "COTTON VOILE",
			"label": "COTTON VOILE"
	},
	{
			"value": "COTTON WD BRY",
			"label": "COTTON WD BRY"
	},
	{
			"value": "COTTONWOOD",
			"label": "COTTONWOOD"
	},
	{
			"value": "COUCH CADDY",
			"label": "COUCH CADDY"
	},
	{
			"value": "COUNTDOWN",
			"label": "COUNTDOWN"
	},
	{
			"value": "COUNT DOWN CALENDAR",
			"label": "COUNT DOWN CALENDAR"
	},
	{
			"value": "COUNTDOWN CALENDAR",
			"label": "COUNTDOWN CALENDAR"
	},
	{
			"value": "COUNTER COVERS",
			"label": "COUNTER COVERS"
	},
	{
			"value": "COUNTING",
			"label": "COUNTING"
	},
	{
			"value": "COUNTING GAME",
			"label": "COUNTING GAME"
	},
	{
			"value": "COUNTRY",
			"label": "COUNTRY"
	},
	{
			"value": "COUNTRY AMERICANA",
			"label": "COUNTRY AMERICANA"
	},
	{
			"value": "COUNTRY CHRISTMAS",
			"label": "COUNTRY CHRISTMAS"
	},
	{
			"value": "COUNTRY CHRISTMAS BA",
			"label": "COUNTRY CHRISTMAS BA"
	},
	{
			"value": "COUNTRY CHRISTMAS GL",
			"label": "COUNTRY CHRISTMAS GL"
	},
	{
			"value": "COUNTRY CHRISTMAS NA",
			"label": "COUNTRY CHRISTMAS NA"
	},
	{
			"value": "COUNTRY CLUB CLASSIC",
			"label": "COUNTRY CLUB CLASSIC"
	},
	{
			"value": "COUNTRY GRID",
			"label": "COUNTRY GRID"
	},
	{
			"value": "COUNTY FAIR ROOSTER",
			"label": "COUNTY FAIR ROOSTER"
	},
	{
			"value": "COUNTY FAIR VINTAGE",
			"label": "COUNTY FAIR VINTAGE"
	},
	{
			"value": "COUPLER",
			"label": "COUPLER"
	},
	{
			"value": "COUPLERS",
			"label": "COUPLERS"
	},
	{
			"value": "COUSIN",
			"label": "COUSIN"
	},
	{
			"value": "COVER",
			"label": "COVER"
	},
	{
			"value": "COVER BTN",
			"label": "COVER BTN"
	},
	{
			"value": "COVERED ITEMS",
			"label": "COVERED ITEMS"
	},
	{
			"value": "COVER STITCH",
			"label": "COVER STITCH"
	},
	{
			"value": "COWBOY",
			"label": "COWBOY"
	},
	{
			"value": "COWGIRL PRINCESS",
			"label": "COWGIRL PRINCESS"
	},
	{
			"value": "COZY",
			"label": "COZY"
	},
	{
			"value": "COZY FLANNEL",
			"label": "COZY FLANNEL"
	},
	{
			"value": "COZY HOME",
			"label": "COZY HOME"
	},
	{
			"value": "COZY PILLOW",
			"label": "COZY PILLOW"
	},
	{
			"value": "COZY SOCKS",
			"label": "COZY SOCKS"
	},
	{
			"value": "CQ PRODUCTS",
			"label": "CQ PRODUCTS"
	},
	{
			"value": "CRAB",
			"label": "CRAB"
	},
	{
			"value": "CRACKERS",
			"label": "CRACKERS"
	},
	{
			"value": "CRACKLE",
			"label": "CRACKLE"
	},
	{
			"value": "CRACKLE GLAZE",
			"label": "CRACKLE GLAZE"
	},
	{
			"value": "CRAFT",
			"label": "CRAFT"
	},
	{
			"value": "CRAFT BAG",
			"label": "CRAFT BAG"
	},
	{
			"value": "CRAFT BAG EC",
			"label": "CRAFT BAG EC"
	},
	{
			"value": "CRAFT BAGS",
			"label": "CRAFT BAGS"
	},
	{
			"value": "CRAFT COLLECTION",
			"label": "CRAFT COLLECTION"
	},
	{
			"value": "CRAFTERNOON",
			"label": "CRAFTERNOON"
	},
	{
			"value": "CRAFTER'S COMPANION",
			"label": "CRAFTER'S COMPANION"
	},
	{
			"value": "CRAFTER'S EDITION",
			"label": "CRAFTER'S EDITION"
	},
	{
			"value": "CRAFTERS PICK",
			"label": "CRAFTERS PICK"
	},
	{
			"value": "CRAFTER'S PRIDE",
			"label": "CRAFTER'S PRIDE"
	},
	{
			"value": "CRAFTERS WORKSHOP",
			"label": "CRAFTERS WORKSHOP"
	},
	{
			"value": "CRAFT HOME DEC INTRF",
			"label": "CRAFT HOME DEC INTRF"
	},
	{
			"value": "CRAFTING TOOLS",
			"label": "CRAFTING TOOLS"
	},
	{
			"value": "CRAFTMATES",
			"label": "CRAFTMATES"
	},
	{
			"value": "CRAFT NOODLES",
			"label": "CRAFT NOODLES"
	},
	{
			"value": "CRAFT ROOM",
			"label": "CRAFT ROOM"
	},
	{
			"value": "CRAFTS",
			"label": "CRAFTS"
	},
	{
			"value": "CRAFT STICKS",
			"label": "CRAFT STICKS"
	},
	{
			"value": "CRAFT STOCKING",
			"label": "CRAFT STOCKING"
	},
	{
			"value": "CRAFT TULLE SPOOL",
			"label": "CRAFT TULLE SPOOL"
	},
	{
			"value": "CRAFTWELL",
			"label": "CRAFTWELL"
	},
	{
			"value": "CRAFTY",
			"label": "CRAFTY"
	},
	{
			"value": "CRAFTY SECRETS",
			"label": "CRAFTY SECRETS"
	},
	{
			"value": "CRANE",
			"label": "CRANE"
	},
	{
			"value": "CRATE",
			"label": "CRATE"
	},
	{
			"value": "CRATE PAPER",
			"label": "CRATE PAPER"
	},
	{
			"value": "CRATES",
			"label": "CRATES"
	},
	{
			"value": "CRAYOLA",
			"label": "CRAYOLA"
	},
	{
			"value": "CRAYOLA BRUSHES",
			"label": "CRAYOLA BRUSHES"
	},
	{
			"value": "CRAYOLA CHALK",
			"label": "CRAYOLA CHALK"
	},
	{
			"value": "CRAYOLA CRAYON",
			"label": "CRAYOLA CRAYON"
	},
	{
			"value": "CRAYOLA DRY ERASE",
			"label": "CRAYOLA DRY ERASE"
	},
	{
			"value": "CRAYOLA GLUE",
			"label": "CRAYOLA GLUE"
	},
	{
			"value": "CRAYOLA KIT",
			"label": "CRAYOLA KIT"
	},
	{
			"value": "CRAYOLA MARKERS",
			"label": "CRAYOLA MARKERS"
	},
	{
			"value": "CRAYOLA PAINT",
			"label": "CRAYOLA PAINT"
	},
	{
			"value": "CRAYOLA PAPER PAD",
			"label": "CRAYOLA PAPER PAD"
	},
	{
			"value": "CRAYOLA PENCILS",
			"label": "CRAYOLA PENCILS"
	},
	{
			"value": "CRAYOLA SCISSORS",
			"label": "CRAYOLA SCISSORS"
	},
	{
			"value": "CRAYONS",
			"label": "CRAYONS"
	},
	{
			"value": "CRAZY FOR DAISIES",
			"label": "CRAZY FOR DAISIES"
	},
	{
			"value": "CRAZY MESH",
			"label": "CRAZY MESH"
	},
	{
			"value": "CREAM",
			"label": "CREAM"
	},
	{
			"value": "CREAMER",
			"label": "CREAMER"
	},
	{
			"value": "CREAM PLACEMAT",
			"label": "CREAM PLACEMAT"
	},
	{
			"value": "CREAM ROSE",
			"label": "CREAM ROSE"
	},
	{
			"value": "CREAM THROW",
			"label": "CREAM THROW"
	},
	{
			"value": "CREATING KEEPSAKES",
			"label": "CREATING KEEPSAKES"
	},
	{
			"value": "CREATIVE CHARMS",
			"label": "CREATIVE CHARMS"
	},
	{
			"value": "CREATIVE EXPRESSIONS",
			"label": "CREATIVE EXPRESSIONS"
	},
	{
			"value": "CREATIVE IMAGINATION",
			"label": "CREATIVE IMAGINATION"
	},
	{
			"value": "CREATIVE IMPRESSIONS",
			"label": "CREATIVE IMPRESSIONS"
	},
	{
			"value": "CREATIVE OPTIONS",
			"label": "CREATIVE OPTIONS"
	},
	{
			"value": "CREATIVITY-4.99",
			"label": "CREATIVITY-4.99"
	},
	{
			"value": "CREATIVITY FOR KIDS",
			"label": "CREATIVITY FOR KIDS"
	},
	{
			"value": "CREATURE",
			"label": "CREATURE"
	},
	{
			"value": "CREEPY FABRIC",
			"label": "CREEPY FABRIC"
	},
	{
			"value": "CREPE",
			"label": "CREPE"
	},
	{
			"value": "CREPE BACK SATIN",
			"label": "CREPE BACK SATIN"
	},
	{
			"value": "CREPE-BACK-SATIN",
			"label": "CREPE-BACK-SATIN"
	},
	{
			"value": "CREPON SHEER",
			"label": "CREPON SHEER"
	},
	{
			"value": "CREW",
			"label": "CREW"
	},
	{
			"value": "CREW SOCKS",
			"label": "CREW SOCKS"
	},
	{
			"value": "CR - HEADER",
			"label": "CR - HEADER"
	},
	{
			"value": "CRICUT IRON ON",
			"label": "CRICUT IRON ON"
	},
	{
			"value": "CRIMP BEADS",
			"label": "CRIMP BEADS"
	},
	{
			"value": "CRIMP BELL END",
			"label": "CRIMP BELL END"
	},
	{
			"value": "CRIMP COVERS",
			"label": "CRIMP COVERS"
	},
	{
			"value": "CRIMPER",
			"label": "CRIMPER"
	},
	{
			"value": "CRIMP TUBES",
			"label": "CRIMP TUBES"
	},
	{
			"value": "CRINKLE",
			"label": "CRINKLE"
	},
	{
			"value": "CRINKLE KNIT",
			"label": "CRINKLE KNIT"
	},
	{
			"value": "CRINOLINE",
			"label": "CRINOLINE"
	},
	{
			"value": "CRITTER",
			"label": "CRITTER"
	},
	{
			"value": "CRITTER CLIP",
			"label": "CRITTER CLIP"
	},
	{
			"value": "CRITTER MUG",
			"label": "CRITTER MUG"
	},
	{
			"value": "CRITTERS",
			"label": "CRITTERS"
	},
	{
			"value": "CRITTER WREATH",
			"label": "CRITTER WREATH"
	},
	{
			"value": "CRMC VASE",
			"label": "CRMC VASE"
	},
	{
			"value": "CROCHET",
			"label": "CROCHET"
	},
	{
			"value": "CROCHET BOOKLET",
			"label": "CROCHET BOOKLET"
	},
	{
			"value": "CROCODILE CREEK",
			"label": "CROCODILE CREEK"
	},
	{
			"value": "CROCUS",
			"label": "CROCUS"
	},
	{
			"value": "CRONTON",
			"label": "CRONTON"
	},
	{
			"value": "CROP IN STYLE",
			"label": "CROP IN STYLE"
	},
	{
			"value": "CROREY CREATIONS",
			"label": "CROREY CREATIONS"
	},
	{
			"value": "CROSS",
			"label": "CROSS"
	},
	{
			"value": "CROSS STITCH",
			"label": "CROSS STITCH"
	},
	{
			"value": "CROSS STITCH AFGHAN",
			"label": "CROSS STITCH AFGHAN"
	},
	{
			"value": "CROSS STITCH BASES",
			"label": "CROSS STITCH BASES"
	},
	{
			"value": "CROSS STITCH BIB",
			"label": "CROSS STITCH BIB"
	},
	{
			"value": "CROSS STITCH BOOKLET",
			"label": "CROSS STITCH BOOKLET"
	},
	{
			"value": "CROSS STITCH BOOKS",
			"label": "CROSS STITCH BOOKS"
	},
	{
			"value": "CROSS STITCH QUILT",
			"label": "CROSS STITCH QUILT"
	},
	{
			"value": "CROSS STITCH SCARF",
			"label": "CROSS STITCH SCARF"
	},
	{
			"value": "CROSS STITCH TABLE",
			"label": "CROSS STITCH TABLE"
	},
	{
			"value": "CROSS STITCH TREND",
			"label": "CROSS STITCH TREND"
	},
	{
			"value": "CROTON",
			"label": "CROTON"
	},
	{
			"value": "CROW",
			"label": "CROW"
	},
	{
			"value": "CROWN",
			"label": "CROWN"
	},
	{
			"value": "CROWNS SILVER",
			"label": "CROWNS SILVER"
	},
	{
			"value": "CRUSH CHIFFON",
			"label": "CRUSH CHIFFON"
	},
	{
			"value": "CRUSHED TULLE",
			"label": "CRUSHED TULLE"
	},
	{
			"value": "CRYPTON",
			"label": "CRYPTON"
	},
	{
			"value": "CRYRN",
			"label": "CRYRN"
	},
	{
			"value": "CRYRNB00",
			"label": "CRYRNB00"
	},
	{
			"value": "CRYSTAL CANDLE",
			"label": "CRYSTAL CANDLE"
	},
	{
			"value": "CRYSTALIZED FLORA",
			"label": "CRYSTALIZED FLORA"
	},
	{
			"value": "CRYSTAL KITS",
			"label": "CRYSTAL KITS"
	},
	{
			"value": "CRYSTAL PDQ",
			"label": "CRYSTAL PDQ"
	},
	{
			"value": "CRYSTALS",
			"label": "CRYSTALS"
	},
	{
			"value": "CRYSTALS-PENDANTS",
			"label": "CRYSTALS-PENDANTS"
	},
	{
			"value": "CRYSTAL STRANDS",
			"label": "CRYSTAL STRANDS"
	},
	{
			"value": "CSAFL",
			"label": "CSAFL"
	},
	{
			"value": "CSURF",
			"label": "CSURF"
	},
	{
			"value": "C-THRU",
			"label": "C-THRU"
	},
	{
			"value": "C&T PUBLISHING",
			"label": "C&T PUBLISHING"
	},
	{
			"value": "CTRY",
			"label": "CTRY"
	},
	{
			"value": "CUB BAG",
			"label": "CUB BAG"
	},
	{
			"value": "CUBE",
			"label": "CUBE"
	},
	{
			"value": "CUB GIFT BAG",
			"label": "CUB GIFT BAG"
	},
	{
			"value": "CUDDLEKINS",
			"label": "CUDDLEKINS"
	},
	{
			"value": "CULTURAL PRIDE",
			"label": "CULTURAL PRIDE"
	},
	{
			"value": "CUP",
			"label": "CUP"
	},
	{
			"value": "CUPCAKE",
			"label": "CUPCAKE"
	},
	{
			"value": "CUPCAKE BOXES",
			"label": "CUPCAKE BOXES"
	},
	{
			"value": "CUPCAKE CANDIES",
			"label": "CUPCAKE CANDIES"
	},
	{
			"value": "CUPCAKE CREATIONS",
			"label": "CUPCAKE CREATIONS"
	},
	{
			"value": "CUPCAKE DEC KIT",
			"label": "CUPCAKE DEC KIT"
	},
	{
			"value": "CUPCAKE DECORATIVE",
			"label": "CUPCAKE DECORATIVE"
	},
	{
			"value": "CUPCAKE DISPLAY",
			"label": "CUPCAKE DISPLAY"
	},
	{
			"value": "CUPCAKES",
			"label": "CUPCAKES"
	},
	{
			"value": "CUPCAKE SCRIBBLES",
			"label": "CUPCAKE SCRIBBLES"
	},
	{
			"value": "CUPCAKE WRAPS",
			"label": "CUPCAKE WRAPS"
	},
	{
			"value": "CUP CHAIN",
			"label": "CUP CHAIN"
	},
	{
			"value": "CUPS",
			"label": "CUPS"
	},
	{
			"value": "CURB CHAIN",
			"label": "CURB CHAIN"
	},
	{
			"value": "CURB CHAIN HAMMERED",
			"label": "CURB CHAIN HAMMERED"
	},
	{
			"value": "CURIOUS GEORGE",
			"label": "CURIOUS GEORGE"
	},
	{
			"value": "CURL BOWS",
			"label": "CURL BOWS"
	},
	{
			"value": "CURLING RIBBON",
			"label": "CURLING RIBBON"
	},
	{
			"value": "CURLY WILLOW",
			"label": "CURLY WILLOW"
	},
	{
			"value": "CURTAIN GREY",
			"label": "CURTAIN GREY"
	},
	{
			"value": "CURTAIN RED",
			"label": "CURTAIN RED"
	},
	{
			"value": "CURTAIN ROD",
			"label": "CURTAIN ROD"
	},
	{
			"value": "CURTAINS GREY",
			"label": "CURTAINS GREY"
	},
	{
			"value": "CURTAINS IVORY",
			"label": "CURTAINS IVORY"
	},
	{
			"value": "CURTAINS NAVY",
			"label": "CURTAINS NAVY"
	},
	{
			"value": "CURTAINS RED",
			"label": "CURTAINS RED"
	},
	{
			"value": "CURTAINS WHITE",
			"label": "CURTAINS WHITE"
	},
	{
			"value": "CURTAIN TAUPE",
			"label": "CURTAIN TAUPE"
	},
	{
			"value": "CURVED",
			"label": "CURVED"
	},
	{
			"value": "CURVY STRIPE",
			"label": "CURVY STRIPE"
	},
	{
			"value": "CUSHION",
			"label": "CUSHION"
	},
	{
			"value": "CUSHION BENCH",
			"label": "CUSHION BENCH"
	},
	{
			"value": "CUSHION DEEP SEAT",
			"label": "CUSHION DEEP SEAT"
	},
	{
			"value": "CUSHION HIGH BACK",
			"label": "CUSHION HIGH BACK"
	},
	{
			"value": "CUSHIONS",
			"label": "CUSHIONS"
	},
	{
			"value": "CUSHION SEAT PAD",
			"label": "CUSHION SEAT PAD"
	},
	{
			"value": "CUSHION SOCKS",
			"label": "CUSHION SOCKS"
	},
	{
			"value": "CUSHION WRAP POLY",
			"label": "CUSHION WRAP POLY"
	},
	{
			"value": "CUTE ANIMALS",
			"label": "CUTE ANIMALS"
	},
	{
			"value": "CUT FRINGE",
			"label": "CUT FRINGE"
	},
	{
			"value": "CUTOUT LANTERN",
			"label": "CUTOUT LANTERN"
	},
	{
			"value": "CUT OUTS",
			"label": "CUT OUTS"
	},
	{
			"value": "CUTOUT TRAY",
			"label": "CUTOUT TRAY"
	},
	{
			"value": "CUTTER",
			"label": "CUTTER"
	},
	{
			"value": "CUTTER TOOLS",
			"label": "CUTTER TOOLS"
	},
	{
			"value": "CUTTING BOARD",
			"label": "CUTTING BOARD"
	},
	{
			"value": "CUTTING INSERT",
			"label": "CUTTING INSERT"
	},
	{
			"value": "CUTTING MAT",
			"label": "CUTTING MAT"
	},
	{
			"value": "CUTTING MATERIAL",
			"label": "CUTTING MATERIAL"
	},
	{
			"value": "CUTTING TOOLS",
			"label": "CUTTING TOOLS"
	},
	{
			"value": "CUTTLEBUG",
			"label": "CUTTLEBUG"
	},
	{
			"value": "CVC",
			"label": "CVC"
	},
	{
			"value": "CYCAS",
			"label": "CYCAS"
	},
	{
			"value": "CYCLMEN",
			"label": "CYCLMEN"
	},
	{
			"value": "CYMBIDIUM",
			"label": "CYMBIDIUM"
	},
	{
			"value": "CYPRESS",
			"label": "CYPRESS"
	},
	{
			"value": "CYPRUS",
			"label": "CYPRUS"
	},
	{
			"value": "CZECH",
			"label": "CZECH"
	},
	{
			"value": "DAB'N STIC",
			"label": "DAB'N STIC"
	},
	{
			"value": "DABN STIC",
			"label": "DABN STIC"
	},
	{
			"value": "DAFFODIL",
			"label": "DAFFODIL"
	},
	{
			"value": "DAFFOIL",
			"label": "DAFFOIL"
	},
	{
			"value": "DAHLIA",
			"label": "DAHLIA"
	},
	{
			"value": "DAHLIA-FREEZIA-BERRY",
			"label": "DAHLIA-FREEZIA-BERRY"
	},
	{
			"value": "DAHLIA HYDRA",
			"label": "DAHLIA HYDRA"
	},
	{
			"value": "DAHLIA MUM",
			"label": "DAHLIA MUM"
	},
	{
			"value": "DAHLIA RANUNCULUS DA",
			"label": "DAHLIA RANUNCULUS DA"
	},
	{
			"value": "DAHLIA STM",
			"label": "DAHLIA STM"
	},
	{
			"value": "DAHLIA Y",
			"label": "DAHLIA Y"
	},
	{
			"value": "DAISIES",
			"label": "DAISIES"
	},
	{
			"value": "DAISY",
			"label": "DAISY"
	},
	{
			"value": "DAISY BSH",
			"label": "DAISY BSH"
	},
	{
			"value": "DAISY CHAIN",
			"label": "DAISY CHAIN"
	},
	{
			"value": "DAISY COSMOS",
			"label": "DAISY COSMOS"
	},
	{
			"value": "DAISY D'S",
			"label": "DAISY D'S"
	},
	{
			"value": "DAISY DS",
			"label": "DAISY DS"
	},
	{
			"value": "DALLAS COWBOYS",
			"label": "DALLAS COWBOYS"
	},
	{
			"value": "DALMATION",
			"label": "DALMATION"
	},
	{
			"value": "DAMASK",
			"label": "DAMASK"
	},
	{
			"value": "DANCE",
			"label": "DANCE"
	},
	{
			"value": "DANCER",
			"label": "DANCER"
	},
	{
			"value": "DANCE WEAR",
			"label": "DANCE WEAR"
	},
	{
			"value": "DANCEWEAR",
			"label": "DANCEWEAR"
	},
	{
			"value": "DANDELION",
			"label": "DANDELION"
	},
	{
			"value": "DANGLE",
			"label": "DANGLE"
	},
	{
			"value": "DANGLER",
			"label": "DANGLER"
	},
	{
			"value": "DANIEL TIGER",
			"label": "DANIEL TIGER"
	},
	{
			"value": "DAP",
			"label": "DAP"
	},
	{
			"value": "DARICE",
			"label": "DARICE"
	},
	{
			"value": "DARK GRAY",
			"label": "DARK GRAY"
	},
	{
			"value": "DARK GREY",
			"label": "DARK GREY"
	},
	{
			"value": "DASHBOARD",
			"label": "DASHBOARD"
	},
	{
			"value": "DATES",
			"label": "DATES"
	},
	{
			"value": "DAYLIGHT",
			"label": "DAYLIGHT"
	},
	{
			"value": "DAY OF DEAD",
			"label": "DAY OF DEAD"
	},
	{
			"value": "DAY OF THE DEAD",
			"label": "DAY OF THE DEAD"
	},
	{
			"value": "DAY TIME DRAMA",
			"label": "DAY TIME DRAMA"
	},
	{
			"value": "DAZZLED",
			"label": "DAZZLED"
	},
	{
			"value": "DB 7FT",
			"label": "DB 7FT"
	},
	{
			"value": "DB PLUSH",
			"label": "DB PLUSH"
	},
	{
			"value": "DB POTTED",
			"label": "DB POTTED"
	},
	{
			"value": "DB SHATTERPROOF",
			"label": "DB SHATTERPROOF"
	},
	{
			"value": "DB TREE",
			"label": "DB TREE"
	},
	{
			"value": "DCC",
			"label": "DCC"
	},
	{
			"value": "DC COMICS",
			"label": "DC COMICS"
	},
	{
			"value": "DCPNTA00",
			"label": "DCPNTA00"
	},
	{
			"value": "DCPNTB00",
			"label": "DCPNTB00"
	},
	{
			"value": "DEBBIE MUMM",
			"label": "DEBBIE MUMM"
	},
	{
			"value": "DEC",
			"label": "DEC"
	},
	{
			"value": "DECAL POT",
			"label": "DECAL POT"
	},
	{
			"value": "DECALS",
			"label": "DECALS"
	},
	{
			"value": "DECANTER",
			"label": "DECANTER"
	},
	{
			"value": "DEC BOX BOOK",
			"label": "DEC BOX BOOK"
	},
	{
			"value": "DEC BOXES",
			"label": "DEC BOXES"
	},
	{
			"value": "DEC BOX FLIPTOP",
			"label": "DEC BOX FLIPTOP"
	},
	{
			"value": "DEC BOX OVAL",
			"label": "DEC BOX OVAL"
	},
	{
			"value": "DEC BOX ROUND",
			"label": "DEC BOX ROUND"
	},
	{
			"value": "DEC BOX STEAMER",
			"label": "DEC BOX STEAMER"
	},
	{
			"value": "DECEMBER FY13 RTV",
			"label": "DECEMBER FY13 RTV"
	},
	{
			"value": "DECEMBER RTV",
			"label": "DECEMBER RTV"
	},
	{
			"value": "DEC FLEECE",
			"label": "DEC FLEECE"
	},
	{
			"value": "DECFY14RTV",
			"label": "DECFY14RTV"
	},
	{
			"value": "DECFY15RTV",
			"label": "DECFY15RTV"
	},
	{
			"value": "DECK PLANTER",
			"label": "DECK PLANTER"
	},
	{
			"value": "DECK THE HALLS",
			"label": "DECK THE HALLS"
	},
	{
			"value": "DECO",
			"label": "DECO"
	},
	{
			"value": "DECO ART",
			"label": "DECO ART"
	},
	{
			"value": "DECO COLOR",
			"label": "DECO COLOR"
	},
	{
			"value": "DECO MESH",
			"label": "DECO MESH"
	},
	{
			"value": "DECOR",
			"label": "DECOR"
	},
	{
			"value": "DÉCOR",
			"label": "DÉCOR"
	},
	{
			"value": "DECOR ACCENTS",
			"label": "DECOR ACCENTS"
	},
	{
			"value": "DECOR ADVENTURE",
			"label": "DECOR ADVENTURE"
	},
	{
			"value": "DECORATE YOUR OWN",
			"label": "DECORATE YOUR OWN"
	},
	{
			"value": "DECORATING ACCESSORI",
			"label": "DECORATING ACCESSORI"
	},
	{
			"value": "DECORATING ACCESSORY",
			"label": "DECORATING ACCESSORY"
	},
	{
			"value": "DECORATING BAGS",
			"label": "DECORATING BAGS"
	},
	{
			"value": "DECORATING KIT",
			"label": "DECORATING KIT"
	},
	{
			"value": "DECORATING SETS",
			"label": "DECORATING SETS"
	},
	{
			"value": "DECORATING TIPS",
			"label": "DECORATING TIPS"
	},
	{
			"value": "DECORATING TOOLS",
			"label": "DECORATING TOOLS"
	},
	{
			"value": "DECORATION RABBIT",
			"label": "DECORATION RABBIT"
	},
	{
			"value": "DECORATIVE",
			"label": "DECORATIVE"
	},
	{
			"value": "DECORATIVE BROCADES",
			"label": "DECORATIVE BROCADES"
	},
	{
			"value": "DECORATIVE CANDLES",
			"label": "DECORATIVE CANDLES"
	},
	{
			"value": "DECORATIVE LIGHT",
			"label": "DECORATIVE LIGHT"
	},
	{
			"value": "DECORATIVE PAINTING",
			"label": "DECORATIVE PAINTING"
	},
	{
			"value": "DECORATIVE SET",
			"label": "DECORATIVE SET"
	},
	{
			"value": "DECOR KIT",
			"label": "DECOR KIT"
	},
	{
			"value": "DECORTATING SETS",
			"label": "DECORTATING SETS"
	},
	{
			"value": "DECO- SINGLE MARKERS",
			"label": "DECO- SINGLE MARKERS"
	},
	{
			"value": "DECOSTONE",
			"label": "DECOSTONE"
	},
	{
			"value": "DEC STORAGE BOXES",
			"label": "DEC STORAGE BOXES"
	},
	{
			"value": "DEEP ROMANTIC REDS R",
			"label": "DEEP ROMANTIC REDS R"
	},
	{
			"value": "DEER",
			"label": "DEER"
	},
	{
			"value": "DEER BRY PINE",
			"label": "DEER BRY PINE"
	},
	{
			"value": "DEERSKIN",
			"label": "DEERSKIN"
	},
	{
			"value": "DELP",
			"label": "DELP"
	},
	{
			"value": "DELPHINIUM",
			"label": "DELPHINIUM"
	},
	{
			"value": "DELPHINUM",
			"label": "DELPHINUM"
	},
	{
			"value": "DELTA",
			"label": "DELTA"
	},
	{
			"value": "DELUXE PAPER",
			"label": "DELUXE PAPER"
	},
	{
			"value": "DEMO",
			"label": "DEMO"
	},
	{
			"value": "DEMOCRATIC",
			"label": "DEMOCRATIC"
	},
	{
			"value": "DENDROBIUM",
			"label": "DENDROBIUM"
	},
	{
			"value": "DENIM",
			"label": "DENIM"
	},
	{
			"value": "DENIM APP FASHION",
			"label": "DENIM APP FASHION"
	},
	{
			"value": "DENIM LOOK KNIT",
			"label": "DENIM LOOK KNIT"
	},
	{
			"value": "DENIM LUMBAR",
			"label": "DENIM LUMBAR"
	},
	{
			"value": "DENIM PATCH",
			"label": "DENIM PATCH"
	},
	{
			"value": "DENIMS",
			"label": "DENIMS"
	},
	{
			"value": "DER COLOURSOFT",
			"label": "DER COLOURSOFT"
	},
	{
			"value": "DESERT AURA",
			"label": "DESERT AURA"
	},
	{
			"value": "DESERT FLOWER",
			"label": "DESERT FLOWER"
	},
	{
			"value": "DESIGN",
			"label": "DESIGN"
	},
	{
			"value": "DESIGNER/COUTURE SEW",
			"label": "DESIGNER/COUTURE SEW"
	},
	{
			"value": "DESIGN MASTER",
			"label": "DESIGN MASTER"
	},
	{
			"value": "DESIGN ORIGINALS",
			"label": "DESIGN ORIGINALS"
	},
	{
			"value": "DESIGN+PRINT ADDRESS",
			"label": "DESIGN+PRINT ADDRESS"
	},
	{
			"value": "DESIGN+PRINT CHINESE",
			"label": "DESIGN+PRINT CHINESE"
	},
	{
			"value": "DESIGN+PRINT COOKIE",
			"label": "DESIGN+PRINT COOKIE"
	},
	{
			"value": "DESIGN+PRINT FILE LA",
			"label": "DESIGN+PRINT FILE LA"
	},
	{
			"value": "DESIGN+PRINT FLORAL",
			"label": "DESIGN+PRINT FLORAL"
	},
	{
			"value": "DESIGN+PRINT FORTUNE",
			"label": "DESIGN+PRINT FORTUNE"
	},
	{
			"value": "DESIGN+PRINT GIFT CA",
			"label": "DESIGN+PRINT GIFT CA"
	},
	{
			"value": "DESIGN+PRINT LC ALPH",
			"label": "DESIGN+PRINT LC ALPH"
	},
	{
			"value": "DESIGN+PRINT MD PILL",
			"label": "DESIGN+PRINT MD PILL"
	},
	{
			"value": "DESIGN+PRINT MD SHIP",
			"label": "DESIGN+PRINT MD SHIP"
	},
	{
			"value": "DESIGN+PRINT MEDIUM",
			"label": "DESIGN+PRINT MEDIUM"
	},
	{
			"value": "DESIGN+PRINT NUMBERS",
			"label": "DESIGN+PRINT NUMBERS"
	},
	{
			"value": "DESIGN+PRINT OVAL",
			"label": "DESIGN+PRINT OVAL"
	},
	{
			"value": "DESIGN+PRINT PARTY H",
			"label": "DESIGN+PRINT PARTY H"
	},
	{
			"value": "DESIGN+PRINT PLACE C",
			"label": "DESIGN+PRINT PLACE C"
	},
	{
			"value": "DESIGN+PRINT POPCORN",
			"label": "DESIGN+PRINT POPCORN"
	},
	{
			"value": "DESIGN+PRINT SMALL C",
			"label": "DESIGN+PRINT SMALL C"
	},
	{
			"value": "DESIGN+PRINT SMALL S",
			"label": "DESIGN+PRINT SMALL S"
	},
	{
			"value": "DESIGN+PRINT TAKE OU",
			"label": "DESIGN+PRINT TAKE OU"
	},
	{
			"value": "DESK BUDDIES",
			"label": "DESK BUDDIES"
	},
	{
			"value": "DESKTOP ORGANIZER",
			"label": "DESKTOP ORGANIZER"
	},
	{
			"value": "DESSERT",
			"label": "DESSERT"
	},
	{
			"value": "DESSERT CUPS",
			"label": "DESSERT CUPS"
	},
	{
			"value": "DESSERT DECORATOR",
			"label": "DESSERT DECORATOR"
	},
	{
			"value": "DET REDWINGS",
			"label": "DET REDWINGS"
	},
	{
			"value": "DEVAL PRODUCTS",
			"label": "DEVAL PRODUCTS"
	},
	{
			"value": "DEWDROP ROSE",
			"label": "DEWDROP ROSE"
	},
	{
			"value": "DEW DROPS",
			"label": "DEW DROPS"
	},
	{
			"value": "DHALIA",
			"label": "DHALIA"
	},
	{
			"value": "DHARD85",
			"label": "DHARD85"
	},
	{
			"value": "DHARDD00",
			"label": "DHARDD00"
	},
	{
			"value": "DHARDE00",
			"label": "DHARDE00"
	},
	{
			"value": "DIAMOND",
			"label": "DIAMOND"
	},
	{
			"value": "DIAMOND DOTZ KIT",
			"label": "DIAMOND DOTZ KIT"
	},
	{
			"value": "DIAMOND HTV",
			"label": "DIAMOND HTV"
	},
	{
			"value": "DIAMOND PAINTING",
			"label": "DIAMOND PAINTING"
	},
	{
			"value": "DIAMONDS",
			"label": "DIAMONDS"
	},
	{
			"value": "DIAMOND TECH",
			"label": "DIAMOND TECH"
	},
	{
			"value": "DIAMOND TECH CRAFTS",
			"label": "DIAMOND TECH CRAFTS"
	},
	{
			"value": "DIANTHUS",
			"label": "DIANTHUS"
	},
	{
			"value": "DIAPER CLOTH",
			"label": "DIAPER CLOTH"
	},
	{
			"value": "DIARY OF A WIMPY KID",
			"label": "DIARY OF A WIMPY KID"
	},
	{
			"value": "DICE GAME",
			"label": "DICE GAME"
	},
	{
			"value": "DIE CUT",
			"label": "DIE CUT"
	},
	{
			"value": "DIECUTS WITH A VIEW",
			"label": "DIECUTS WITH A VIEW"
	},
	{
			"value": "DIEFFENBACHIA",
			"label": "DIEFFENBACHIA"
	},
	{
			"value": "DIFFUSER",
			"label": "DIFFUSER"
	},
	{
			"value": "DIH TOWEL",
			"label": "DIH TOWEL"
	},
	{
			"value": "DILL",
			"label": "DILL"
	},
	{
			"value": "DIMENSION CANDLE",
			"label": "DIMENSION CANDLE"
	},
	{
			"value": "DIMENSIONS",
			"label": "DIMENSIONS"
	},
	{
			"value": "DIM OUT",
			"label": "DIM OUT"
	},
	{
			"value": "DINNER NAPKIN",
			"label": "DINNER NAPKIN"
	},
	{
			"value": "DINNER PLATE",
			"label": "DINNER PLATE"
	},
	{
			"value": "DINNERWARE",
			"label": "DINNERWARE"
	},
	{
			"value": "DINO",
			"label": "DINO"
	},
	{
			"value": "DINO ROAR",
			"label": "DINO ROAR"
	},
	{
			"value": "DINOS",
			"label": "DINOS"
	},
	{
			"value": "DINOSAUR",
			"label": "DINOSAUR"
	},
	{
			"value": "DINOSAURS",
			"label": "DINOSAURS"
	},
	{
			"value": "DINOSAURS FLNL",
			"label": "DINOSAURS FLNL"
	},
	{
			"value": "DINOSUAR",
			"label": "DINOSUAR"
	},
	{
			"value": "DIP BOWL",
			"label": "DIP BOWL"
	},
	{
			"value": "DIPPING TOOLS",
			"label": "DIPPING TOOLS"
	},
	{
			"value": "DISC",
			"label": "DISC"
	},
	{
			"value": "DISCARD ARTICLE",
			"label": "DISCARD ARTICLE"
	},
	{
			"value": "DISCONTINUED PURGE",
			"label": "DISCONTINUED PURGE"
	},
	{
			"value": "DISCONTINUE PURGE",
			"label": "DISCONTINUE PURGE"
	},
	{
			"value": "DISC PLANNER",
			"label": "DISC PLANNER"
	},
	{
			"value": "DISCS",
			"label": "DISCS"
	},
	{
			"value": "DISH",
			"label": "DISH"
	},
	{
			"value": "DISHES",
			"label": "DISHES"
	},
	{
			"value": "DISHES TOWEL",
			"label": "DISHES TOWEL"
	},
	{
			"value": "DISH TRAY",
			"label": "DISH TRAY"
	},
	{
			"value": "DISHWASHER POUCH",
			"label": "DISHWASHER POUCH"
	},
	{
			"value": "DISK LINK CHAIN",
			"label": "DISK LINK CHAIN"
	},
	{
			"value": "DISNEY",
			"label": "DISNEY"
	},
	{
			"value": "DISNEY BOUTIQUE",
			"label": "DISNEY BOUTIQUE"
	},
	{
			"value": "DISNEY CARS",
			"label": "DISNEY CARS"
	},
	{
			"value": "DISNEY CLASSIC",
			"label": "DISNEY CLASSIC"
	},
	{
			"value": "DISNEY PRINCESS",
			"label": "DISNEY PRINCESS"
	},
	{
			"value": "DISNEY VILLAINS",
			"label": "DISNEY VILLAINS"
	},
	{
			"value": "DISNEY VILLIAN",
			"label": "DISNEY VILLIAN"
	},
	{
			"value": "DISNEY VILLIANS",
			"label": "DISNEY VILLIANS"
	},
	{
			"value": "DISPENSER",
			"label": "DISPENSER"
	},
	{
			"value": "DISPLAY",
			"label": "DISPLAY"
	},
	{
			"value": "DISPLAY BOX",
			"label": "DISPLAY BOX"
	},
	{
			"value": "DISPLAY CASE",
			"label": "DISPLAY CASE"
	},
	{
			"value": "DISPOSABLE",
			"label": "DISPOSABLE"
	},
	{
			"value": "DISTRESSED",
			"label": "DISTRESSED"
	},
	{
			"value": "DITSY",
			"label": "DITSY"
	},
	{
			"value": "DITSY FLORAL",
			"label": "DITSY FLORAL"
	},
	{
			"value": "DITSY/PACKED FLORAL",
			"label": "DITSY/PACKED FLORAL"
	},
	{
			"value": "DITZY FLORAL",
			"label": "DITZY FLORAL"
	},
	{
			"value": "DIVERSE MRS. CLAUS",
			"label": "DIVERSE MRS. CLAUS"
	},
	{
			"value": "DIVERSE SANTA",
			"label": "DIVERSE SANTA"
	},
	{
			"value": "DIVIDED",
			"label": "DIVIDED"
	},
	{
			"value": "DIVIDERS",
			"label": "DIVIDERS"
	},
	{
			"value": "DIYBANGLES",
			"label": "DIYBANGLES"
	},
	{
			"value": "DIY BOXES",
			"label": "DIY BOXES"
	},
	{
			"value": "DIYRIBBON",
			"label": "DIYRIBBON"
	},
	{
			"value": "DIY SHEP CONNECTOR",
			"label": "DIY SHEP CONNECTOR"
	},
	{
			"value": "DIY SHEPHERD BASE",
			"label": "DIY SHEPHERD BASE"
	},
	{
			"value": "DIY SHEPHERD HOOK",
			"label": "DIY SHEPHERD HOOK"
	},
	{
			"value": "DIY SHEPHERD TOPPER",
			"label": "DIY SHEPHERD TOPPER"
	},
	{
			"value": "DIY SHEP HOOK POLE",
			"label": "DIY SHEP HOOK POLE"
	},
	{
			"value": "DK BLU",
			"label": "DK BLU"
	},
	{
			"value": "DK GRY",
			"label": "DK GRY"
	},
	{
			"value": "DMC",
			"label": "DMC"
	},
	{
			"value": "DMC CONE FLOSS",
			"label": "DMC CONE FLOSS"
	},
	{
			"value": "DMD",
			"label": "DMD"
	},
	{
			"value": "DO-A-DOT",
			"label": "DO-A-DOT"
	},
	{
			"value": "DO A DOT BOOK",
			"label": "DO A DOT BOOK"
	},
	{
			"value": "DOC MCSTUFFINS",
			"label": "DOC MCSTUFFINS"
	},
	{
			"value": "DOCTOR WHO",
			"label": "DOCTOR WHO"
	},
	{
			"value": "DOCUMENT",
			"label": "DOCUMENT"
	},
	{
			"value": "DOG",
			"label": "DOG"
	},
	{
			"value": "DOG BED",
			"label": "DOG BED"
	},
	{
			"value": "DOG BEDS",
			"label": "DOG BEDS"
	},
	{
			"value": "DOG BOWL",
			"label": "DOG BOWL"
	},
	{
			"value": "DOG/CAT",
			"label": "DOG/CAT"
	},
	{
			"value": "DOG & CAT OTHER",
			"label": "DOG & CAT OTHER"
	},
	{
			"value": "DOG FACES",
			"label": "DOG FACES"
	},
	{
			"value": "DOG FACES TONAL",
			"label": "DOG FACES TONAL"
	},
	{
			"value": "DOGS",
			"label": "DOGS"
	},
	{
			"value": "DOGS TOWEL",
			"label": "DOGS TOWEL"
	},
	{
			"value": "DOG TOY",
			"label": "DOG TOY"
	},
	{
			"value": "DOGWOOD",
			"label": "DOGWOOD"
	},
	{
			"value": "DOILIES",
			"label": "DOILIES"
	},
	{
			"value": "DOLL",
			"label": "DOLL"
	},
	{
			"value": "DOLLAR FRAME",
			"label": "DOLLAR FRAME"
	},
	{
			"value": "DOLLARS WD",
			"label": "DOLLARS WD"
	},
	{
			"value": "DOLL CLOTHES",
			"label": "DOLL CLOTHES"
	},
	{
			"value": "DOLL EYES",
			"label": "DOLL EYES"
	},
	{
			"value": "DOLL HAIR",
			"label": "DOLL HAIR"
	},
	{
			"value": "DOLL HATS",
			"label": "DOLL HATS"
	},
	{
			"value": "DOLLMAKE ACCESSORIES",
			"label": "DOLLMAKE ACCESSORIES"
	},
	{
			"value": "DOLL MAKING",
			"label": "DOLL MAKING"
	},
	{
			"value": "DOLL NOSE",
			"label": "DOLL NOSE"
	},
	{
			"value": "DOLL PENDANT",
			"label": "DOLL PENDANT"
	},
	{
			"value": "DOLLS",
			"label": "DOLLS"
	},
	{
			"value": "DOLL STAND",
			"label": "DOLL STAND"
	},
	{
			"value": "DOLLS/TEDDY BEARS",
			"label": "DOLLS/TEDDY BEARS"
	},
	{
			"value": "DOLOMITE",
			"label": "DOLOMITE"
	},
	{
			"value": "DONUT",
			"label": "DONUT"
	},
	{
			"value": "DOODLE",
			"label": "DOODLE"
	},
	{
			"value": "DOODLEBUG",
			"label": "DOODLEBUG"
	},
	{
			"value": "DOODLES",
			"label": "DOODLES"
	},
	{
			"value": "DOODLES 2",
			"label": "DOODLES 2"
	},
	{
			"value": "DOODLES 3",
			"label": "DOODLES 3"
	},
	{
			"value": "DOODLES HALLOWEEN",
			"label": "DOODLES HALLOWEEN"
	},
	{
			"value": "DOODLES OPPORTUNITY",
			"label": "DOODLES OPPORTUNITY"
	},
	{
			"value": "DOODLES SOFIA",
			"label": "DOODLES SOFIA"
	},
	{
			"value": "DOODLES TEXTURE",
			"label": "DOODLES TEXTURE"
	},
	{
			"value": "DOODLES WINTER",
			"label": "DOODLES WINTER"
	},
	{
			"value": "DOOR",
			"label": "DOOR"
	},
	{
			"value": "DOOR ACCESSORIES",
			"label": "DOOR ACCESSORIES"
	},
	{
			"value": "DOOR BUSTER",
			"label": "DOOR BUSTER"
	},
	{
			"value": "DOORBUSTER",
			"label": "DOORBUSTER"
	},
	{
			"value": "DOORBUSTER PLUSH",
			"label": "DOORBUSTER PLUSH"
	},
	{
			"value": "DOORBUSTER TREE",
			"label": "DOORBUSTER TREE"
	},
	{
			"value": "DOORBUST FLANNEL",
			"label": "DOORBUST FLANNEL"
	},
	{
			"value": "DOOR DECOR",
			"label": "DOOR DECOR"
	},
	{
			"value": "DOOR FABRIC",
			"label": "DOOR FABRIC"
	},
	{
			"value": "DOOR GREETER",
			"label": "DOOR GREETER"
	},
	{
			"value": "DOOR HANGER",
			"label": "DOOR HANGER"
	},
	{
			"value": "DOORHANGER",
			"label": "DOORHANGER"
	},
	{
			"value": "DOOR HANGING",
			"label": "DOOR HANGING"
	},
	{
			"value": "DOORKNOB",
			"label": "DOORKNOB"
	},
	{
			"value": "DOOR KNOB HANGER",
			"label": "DOOR KNOB HANGER"
	},
	{
			"value": "DOORKNOB HANGER",
			"label": "DOORKNOB HANGER"
	},
	{
			"value": "DOORKNOB PILLOW",
			"label": "DOORKNOB PILLOW"
	},
	{
			"value": "DOOR KNOCKER",
			"label": "DOOR KNOCKER"
	},
	{
			"value": "DOOR MAT",
			"label": "DOOR MAT"
	},
	{
			"value": "DOORMAT",
			"label": "DOORMAT"
	},
	{
			"value": "DOOR PANEL",
			"label": "DOOR PANEL"
	},
	{
			"value": "DOOR PILLOW",
			"label": "DOOR PILLOW"
	},
	{
			"value": "DOOR STOP",
			"label": "DOOR STOP"
	},
	{
			"value": "DOOR STOPPER",
			"label": "DOOR STOPPER"
	},
	{
			"value": "DOOR STPPR",
			"label": "DOOR STPPR"
	},
	{
			"value": "DOOR TAG",
			"label": "DOOR TAG"
	},
	{
			"value": "DORA",
			"label": "DORA"
	},
	{
			"value": "DORM CANDLES",
			"label": "DORM CANDLES"
	},
	{
			"value": "DORM LIGHTING",
			"label": "DORM LIGHTING"
	},
	{
			"value": "DOT",
			"label": "DOT"
	},
	{
			"value": "DOT OMBRE",
			"label": "DOT OMBRE"
	},
	{
			"value": "DOTS",
			"label": "DOTS"
	},
	{
			"value": "DOTS & SPOTS",
			"label": "DOTS & SPOTS"
	},
	{
			"value": "DOT SWIRL",
			"label": "DOT SWIRL"
	},
	{
			"value": "DOTTED SUCCLENT",
			"label": "DOTTED SUCCLENT"
	},
	{
			"value": "DOT TO DOTS",
			"label": "DOT TO DOTS"
	},
	{
			"value": "DOTTY DAISY",
			"label": "DOTTY DAISY"
	},
	{
			"value": "DOTTY JUNGLE",
			"label": "DOTTY JUNGLE"
	},
	{
			"value": "DOTTY MONKEY BLUE FL",
			"label": "DOTTY MONKEY BLUE FL"
	},
	{
			"value": "DOTTY MONKEY PINK FL",
			"label": "DOTTY MONKEY PINK FL"
	},
	{
			"value": "DOUBLE BALL CHAIN",
			"label": "DOUBLE BALL CHAIN"
	},
	{
			"value": "DOUBLE DEEP",
			"label": "DOUBLE DEEP"
	},
	{
			"value": "DOUBLE FOLD",
			"label": "DOUBLE FOLD"
	},
	{
			"value": "DOUBLE LOOP",
			"label": "DOUBLE LOOP"
	},
	{
			"value": "DOUGH BOWL",
			"label": "DOUGH BOWL"
	},
	{
			"value": "DOUGLAS FIR CEDAR BE",
			"label": "DOUGLAS FIR CEDAR BE"
	},
	{
			"value": "DOVE",
			"label": "DOVE"
	},
	{
			"value": "DOVER",
			"label": "DOVER"
	},
	{
			"value": "DRACAENA",
			"label": "DRACAENA"
	},
	{
			"value": "DRAGON",
			"label": "DRAGON"
	},
	{
			"value": "DRAGONFLY",
			"label": "DRAGONFLY"
	},
	{
			"value": "DRANGONFLY",
			"label": "DRANGONFLY"
	},
	{
			"value": "DRAPERY",
			"label": "DRAPERY"
	},
	{
			"value": "DRAPERY WEIGHTS",
			"label": "DRAPERY WEIGHTS"
	},
	{
			"value": "DRAWING",
			"label": "DRAWING"
	},
	{
			"value": "DRAWING PAD 40 SHEET",
			"label": "DRAWING PAD 40 SHEET"
	},
	{
			"value": "DRAWING PAPER PADS",
			"label": "DRAWING PAPER PADS"
	},
	{
			"value": "DREAMLAND",
			"label": "DREAMLAND"
	},
	{
			"value": "DREAM STREET",
			"label": "DREAM STREET"
	},
	{
			"value": "DREIDEL",
			"label": "DREIDEL"
	},
	{
			"value": "DRESS",
			"label": "DRESS"
	},
	{
			"value": "DRESS UP",
			"label": "DRESS UP"
	},
	{
			"value": "DRG PUBLICATIONS",
			"label": "DRG PUBLICATIONS"
	},
	{
			"value": "DRIED",
			"label": "DRIED"
	},
	{
			"value": "DRIED01",
			"label": "DRIED01"
	},
	{
			"value": "DRIEDC00",
			"label": "DRIEDC00"
	},
	{
			"value": "DRIEDS",
			"label": "DRIEDS"
	},
	{
			"value": "DRILL",
			"label": "DRILL"
	},
	{
			"value": "DRINK",
			"label": "DRINK"
	},
	{
			"value": "DRINK DISPENSER",
			"label": "DRINK DISPENSER"
	},
	{
			"value": "DRINKING",
			"label": "DRINKING"
	},
	{
			"value": "DRINK MIXER",
			"label": "DRINK MIXER"
	},
	{
			"value": "DRINKS FLNL",
			"label": "DRINKS FLNL"
	},
	{
			"value": "DRINK STIRS",
			"label": "DRINK STIRS"
	},
	{
			"value": "DRINK TOWEL",
			"label": "DRINK TOWEL"
	},
	{
			"value": "DRINKWARE",
			"label": "DRINKWARE"
	},
	{
			"value": "DRITZ",
			"label": "DRITZ"
	},
	{
			"value": "DRIZZLE POUCH",
			"label": "DRIZZLE POUCH"
	},
	{
			"value": "DROP",
			"label": "DROP"
	},
	{
			"value": "DR SEUSS",
			"label": "DR SEUSS"
	},
	{
			"value": "DR. SEUSS",
			"label": "DR. SEUSS"
	},
	{
			"value": "DRUM",
			"label": "DRUM"
	},
	{
			"value": "DRUZY",
			"label": "DRUZY"
	},
	{
			"value": "DR. WHO",
			"label": "DR. WHO"
	},
	{
			"value": "DRY ERASE",
			"label": "DRY ERASE"
	},
	{
			"value": "DRY ERASE ACCESSORIE",
			"label": "DRY ERASE ACCESSORIE"
	},
	{
			"value": "DRYING MAT",
			"label": "DRYING MAT"
	},
	{
			"value": "DRYING RACK",
			"label": "DRYING RACK"
	},
	{
			"value": "DRY TOWEL",
			"label": "DRY TOWEL"
	},
	{
			"value": "DS QUILTS COLLECTION",
			"label": "DS QUILTS COLLECTION"
	},
	{
			"value": "DSY PANSY",
			"label": "DSY PANSY"
	},
	{
			"value": "DSY TULIP",
			"label": "DSY TULIP"
	},
	{
			"value": "DTD CORRUGATE",
			"label": "DTD CORRUGATE"
	},
	{
			"value": "DUAL BRUSH 10PC",
			"label": "DUAL BRUSH 10PC"
	},
	{
			"value": "DUAL JINGLE BELL",
			"label": "DUAL JINGLE BELL"
	},
	{
			"value": "DUCK",
			"label": "DUCK"
	},
	{
			"value": "DUCK DYNASTY",
			"label": "DUCK DYNASTY"
	},
	{
			"value": "DUCK EDITION",
			"label": "DUCK EDITION"
	},
	{
			"value": "DUCK TAPE",
			"label": "DUCK TAPE"
	},
	{
			"value": "DUNCAN",
			"label": "DUNCAN"
	},
	{
			"value": "DUNE CRAFT",
			"label": "DUNE CRAFT"
	},
	{
			"value": "DUNROVEN HOUSE",
			"label": "DUNROVEN HOUSE"
	},
	{
			"value": "DURACELL",
			"label": "DURACELL"
	},
	{
			"value": "DURO",
			"label": "DURO"
	},
	{
			"value": "DUST/GLITTER",
			"label": "DUST/GLITTER"
	},
	{
			"value": "DUSTY",
			"label": "DUSTY"
	},
	{
			"value": "DUSTY MILLER",
			"label": "DUSTY MILLER"
	},
	{
			"value": "DUSTY TONE RIBBON",
			"label": "DUSTY TONE RIBBON"
	},
	{
			"value": "D WALL",
			"label": "D WALL"
	},
	{
			"value": "DYEING",
			"label": "DYEING"
	},
	{
			"value": "DYE KIT",
			"label": "DYE KIT"
	},
	{
			"value": "DYERS CLOTH",
			"label": "DYERS CLOTH"
	},
	{
			"value": "DYES & KITS",
			"label": "DYES & KITS"
	},
	{
			"value": "DYE TOOLS",
			"label": "DYE TOOLS"
	},
	{
			"value": "DYLON- POWDER DYE",
			"label": "DYLON- POWDER DYE"
	},
	{
			"value": "EACH",
			"label": "EACH"
	},
	{
			"value": "EACH DAY",
			"label": "EACH DAY"
	},
	{
			"value": "EAGLE",
			"label": "EAGLE"
	},
	{
			"value": "EAGLES",
			"label": "EAGLES"
	},
	{
			"value": "EARBUDS",
			"label": "EARBUDS"
	},
	{
			"value": "EAR CUFFS",
			"label": "EAR CUFFS"
	},
	{
			"value": "EAR HOOPS",
			"label": "EAR HOOPS"
	},
	{
			"value": "EARLY CHILDHOOD",
			"label": "EARLY CHILDHOOD"
	},
	{
			"value": "EARLY SELLER",
			"label": "EARLY SELLER"
	},
	{
			"value": "EARMUFF",
			"label": "EARMUFF"
	},
	{
			"value": "EAR POSTS",
			"label": "EAR POSTS"
	},
	{
			"value": "EARRING",
			"label": "EARRING"
	},
	{
			"value": "EARRING BACKS",
			"label": "EARRING BACKS"
	},
	{
			"value": "EARRING CARDS",
			"label": "EARRING CARDS"
	},
	{
			"value": "EARRING COMPONENTS",
			"label": "EARRING COMPONENTS"
	},
	{
			"value": "EARRING CONNECTORS",
			"label": "EARRING CONNECTORS"
	},
	{
			"value": "EARRING HOOPS",
			"label": "EARRING HOOPS"
	},
	{
			"value": "EARRING POSTS",
			"label": "EARRING POSTS"
	},
	{
			"value": "EARRINGS",
			"label": "EARRINGS"
	},
	{
			"value": "EARRINGS KIT",
			"label": "EARRINGS KIT"
	},
	{
			"value": "EARRINGS KITS",
			"label": "EARRINGS KITS"
	},
	{
			"value": "EAR TAIL SET ADULT",
			"label": "EAR TAIL SET ADULT"
	},
	{
			"value": "EAR TAIL SET CHILD",
			"label": "EAR TAIL SET CHILD"
	},
	{
			"value": "EARTH CHILD",
			"label": "EARTH CHILD"
	},
	{
			"value": "EAR WARMER",
			"label": "EAR WARMER"
	},
	{
			"value": "EAR WIRE KIT",
			"label": "EAR WIRE KIT"
	},
	{
			"value": "EAR WIRES",
			"label": "EAR WIRES"
	},
	{
			"value": "EAS",
			"label": "EAS"
	},
	{
			"value": "EASEL",
			"label": "EASEL"
	},
	{
			"value": "EASELS",
			"label": "EASELS"
	},
	{
			"value": "EAST CANVAS",
			"label": "EAST CANVAS"
	},
	{
			"value": "EAST COLORUP",
			"label": "EAST COLORUP"
	},
	{
			"value": "EAST CONST",
			"label": "EAST CONST"
	},
	{
			"value": "EAST CRAFT KIT",
			"label": "EAST CRAFT KIT"
	},
	{
			"value": "EAST EGGS",
			"label": "EAST EGGS"
	},
	{
			"value": "EASTER",
			"label": "EASTER"
	},
	{
			"value": "EASTER ACCESSORY",
			"label": "EASTER ACCESSORY"
	},
	{
			"value": "EASTER BOW",
			"label": "EASTER BOW"
	},
	{
			"value": "EASTER CANDLE",
			"label": "EASTER CANDLE"
	},
	{
			"value": "EASTER CANDY",
			"label": "EASTER CANDY"
	},
	{
			"value": "EASTER CLIP STRIP",
			"label": "EASTER CLIP STRIP"
	},
	{
			"value": "EASTER EGG",
			"label": "EASTER EGG"
	},
	{
			"value": "EASTER GRASS",
			"label": "EASTER GRASS"
	},
	{
			"value": "EASTER LILY",
			"label": "EASTER LILY"
	},
	{
			"value": "EASTER LITTLES",
			"label": "EASTER LITTLES"
	},
	{
			"value": "EASTER PAPER MACHE",
			"label": "EASTER PAPER MACHE"
	},
	{
			"value": "EASTER RIBBON",
			"label": "EASTER RIBBON"
	},
	{
			"value": "EASTER SOCKS",
			"label": "EASTER SOCKS"
	},
	{
			"value": "EASTER TULLE",
			"label": "EASTER TULLE"
	},
	{
			"value": "EAST EYES",
			"label": "EAST EYES"
	},
	{
			"value": "EAST FOAM BASE",
			"label": "EAST FOAM BASE"
	},
	{
			"value": "EAST FOAMBASE",
			"label": "EAST FOAMBASE"
	},
	{
			"value": "EAST FOAM PLATTER",
			"label": "EAST FOAM PLATTER"
	},
	{
			"value": "EAST IMPULSE",
			"label": "EAST IMPULSE"
	},
	{
			"value": "EAST KIT",
			"label": "EAST KIT"
	},
	{
			"value": "EAST MELTY BEAD",
			"label": "EAST MELTY BEAD"
	},
	{
			"value": "EAST MESH",
			"label": "EAST MESH"
	},
	{
			"value": "EAST MIBI",
			"label": "EAST MIBI"
	},
	{
			"value": "EAST PAINTBOOK",
			"label": "EAST PAINTBOOK"
	},
	{
			"value": "EAST PLASTER",
			"label": "EAST PLASTER"
	},
	{
			"value": "EAST POM",
			"label": "EAST POM"
	},
	{
			"value": "EAST SEQUIN",
			"label": "EAST SEQUIN"
	},
	{
			"value": "EAST STEM",
			"label": "EAST STEM"
	},
	{
			"value": "EAST STICKER",
			"label": "EAST STICKER"
	},
	{
			"value": "EAST STICKERBOOK",
			"label": "EAST STICKERBOOK"
	},
	{
			"value": "EAST SUNCATCHER",
			"label": "EAST SUNCATCHER"
	},
	{
			"value": "EAST TASSEL",
			"label": "EAST TASSEL"
	},
	{
			"value": "EAST TWINE",
			"label": "EAST TWINE"
	},
	{
			"value": "EAST VELVET POSTER",
			"label": "EAST VELVET POSTER"
	},
	{
			"value": "EAST WOOD DOLL",
			"label": "EAST WOOD DOLL"
	},
	{
			"value": "EAST WOOD MASK",
			"label": "EAST WOOD MASK"
	},
	{
			"value": "EAST WOOD PADDLE",
			"label": "EAST WOOD PADDLE"
	},
	{
			"value": "EAST WOOD PAINT",
			"label": "EAST WOOD PAINT"
	},
	{
			"value": "EASY CARE SHIRTING",
			"label": "EASY CARE SHIRTING"
	},
	{
			"value": "EASYPATTERN HTV",
			"label": "EASYPATTERN HTV"
	},
	{
			"value": "EASYPRESS MAT",
			"label": "EASYPRESS MAT"
	},
	{
			"value": "EASYPSV",
			"label": "EASYPSV"
	},
	{
			"value": "EASYPUFF HTV",
			"label": "EASYPUFF HTV"
	},
	{
			"value": "EASY STREET",
			"label": "EASY STREET"
	},
	{
			"value": "EASY WASH",
			"label": "EASY WASH"
	},
	{
			"value": "EASY WASH & CARE",
			"label": "EASY WASH & CARE"
	},
	{
			"value": "EASY WASH CARE",
			"label": "EASY WASH CARE"
	},
	{
			"value": "EASYWEED GLOW HTV",
			"label": "EASYWEED GLOW HTV"
	},
	{
			"value": "EASYWEED HTV",
			"label": "EASYWEED HTV"
	},
	{
			"value": "EC",
			"label": "EC"
	},
	{
			"value": "EC1 NUTCRACKERS",
			"label": "EC1 NUTCRACKERS"
	},
	{
			"value": "EC 2",
			"label": "EC 2"
	},
	{
			"value": "EC 4",
			"label": "EC 4"
	},
	{
			"value": "EC 4- LADIES GARDENI",
			"label": "EC 4- LADIES GARDENI"
	},
	{
			"value": "EC4 WOBBLER DOORMATS",
			"label": "EC4 WOBBLER DOORMATS"
	},
	{
			"value": "EC5",
			"label": "EC5"
	},
	{
			"value": "EC640601",
			"label": "EC640601"
	},
	{
			"value": "EC640604",
			"label": "EC640604"
	},
	{
			"value": "EC640607",
			"label": "EC640607"
	},
	{
			"value": "EC645602",
			"label": "EC645602"
	},
	{
			"value": "EC645603",
			"label": "EC645603"
	},
	{
			"value": "EC645604",
			"label": "EC645604"
	},
	{
			"value": "EC655",
			"label": "EC655"
	},
	{
			"value": "EC655602",
			"label": "EC655602"
	},
	{
			"value": "EC655602 ",
			"label": "EC655602 "
	},
	{
			"value": "EC655605",
			"label": "EC655605"
	},
	{
			"value": "EC660007",
			"label": "EC660007"
	},
	{
			"value": "EC660602",
			"label": "EC660602"
	},
	{
			"value": "EC675006",
			"label": "EC675006"
	},
	{
			"value": "EC675605",
			"label": "EC675605"
	},
	{
			"value": "EC675609",
			"label": "EC675609"
	},
	{
			"value": "EC735602",
			"label": "EC735602"
	},
	{
			"value": "EC735603",
			"label": "EC735603"
	},
	{
			"value": "EC745608",
			"label": "EC745608"
	},
	{
			"value": "EC 8",
			"label": "EC 8"
	},
	{
			"value": "EC800",
			"label": "EC800"
	},
	{
			"value": "EC800603",
			"label": "EC800603"
	},
	{
			"value": "EC800605",
			"label": "EC800605"
	},
	{
			"value": "EC800608",
			"label": "EC800608"
	},
	{
			"value": "EC800609",
			"label": "EC800609"
	},
	{
			"value": "EC800610",
			"label": "EC800610"
	},
	{
			"value": "EC815602",
			"label": "EC815602"
	},
	{
			"value": "EC815606",
			"label": "EC815606"
	},
	{
			"value": "EC815608",
			"label": "EC815608"
	},
	{
			"value": "EC815609",
			"label": "EC815609"
	},
	{
			"value": "EC815610",
			"label": "EC815610"
	},
	{
			"value": "EC 8 PAISLEY FLOWER",
			"label": "EC 8 PAISLEY FLOWER"
	},
	{
			"value": "EC 9 QUILTED TOTE",
			"label": "EC 9 QUILTED TOTE"
	},
	{
			"value": "EC BOWLING",
			"label": "EC BOWLING"
	},
	{
			"value": "ECHO PARK PAPER",
			"label": "ECHO PARK PAPER"
	},
	{
			"value": "ECLECTIC",
			"label": "ECLECTIC"
	},
	{
			"value": "ECL - HEADER",
			"label": "ECL - HEADER"
	},
	{
			"value": "EC MOSAIC BALL & STA",
			"label": "EC MOSAIC BALL & STA"
	},
	{
			"value": "ECO APPETIZER PLATE",
			"label": "ECO APPETIZER PLATE"
	},
	{
			"value": "ECO BOWL",
			"label": "ECO BOWL"
	},
	{
			"value": "ECO CUP",
			"label": "ECO CUP"
	},
	{
			"value": "ECO DINNER PLATE",
			"label": "ECO DINNER PLATE"
	},
	{
			"value": "ECO LUNCH PLATE",
			"label": "ECO LUNCH PLATE"
	},
	{
			"value": "ECO POT",
			"label": "ECO POT"
	},
	{
			"value": "ECO POTS",
			"label": "ECO POTS"
	},
	{
			"value": "ECOPP003",
			"label": "ECOPP003"
	},
	{
			"value": "ECOPP004",
			"label": "ECOPP004"
	},
	{
			"value": "ECOPP006",
			"label": "ECOPP006"
	},
	{
			"value": "ECOPP007",
			"label": "ECOPP007"
	},
	{
			"value": "ECOPP008",
			"label": "ECOPP008"
	},
	{
			"value": "ECO SAUCER",
			"label": "ECO SAUCER"
	},
	{
			"value": "EC PLUSH",
			"label": "EC PLUSH"
	},
	{
			"value": "EDDIE BAUER",
			"label": "EDDIE BAUER"
	},
	{
			"value": "EDIBLE DECORATION",
			"label": "EDIBLE DECORATION"
	},
	{
			"value": "EDISON BULB LANTERN",
			"label": "EDISON BULB LANTERN"
	},
	{
			"value": "EDISON BULB STAKE",
			"label": "EDISON BULB STAKE"
	},
	{
			"value": "EDMUNDS",
			"label": "EDMUNDS"
	},
	{
			"value": "EDU ACTIVITY BOOK",
			"label": "EDU ACTIVITY BOOK"
	},
	{
			"value": "EDUCATION",
			"label": "EDUCATION"
	},
	{
			"value": "EDUCATIONAL",
			"label": "EDUCATIONAL"
	},
	{
			"value": "EEKS & SHRIEKS",
			"label": "EEKS & SHRIEKS"
	},
	{
			"value": "E END END CANDLE",
			"label": "E END END CANDLE"
	},
	{
			"value": "EGG",
			"label": "EGG"
	},
	{
			"value": "EGG ACCESSORIES",
			"label": "EGG ACCESSORIES"
	},
	{
			"value": "EGG DECOR",
			"label": "EGG DECOR"
	},
	{
			"value": "EGG DYE",
			"label": "EGG DYE"
	},
	{
			"value": "EGG DYE KIT",
			"label": "EGG DYE KIT"
	},
	{
			"value": "EGG DYE KITS",
			"label": "EGG DYE KITS"
	},
	{
			"value": "EGG GLASS",
			"label": "EGG GLASS"
	},
	{
			"value": "EGG HOLDER",
			"label": "EGG HOLDER"
	},
	{
			"value": "EGG HUNT",
			"label": "EGG HUNT"
	},
	{
			"value": "EGG METAL",
			"label": "EGG METAL"
	},
	{
			"value": "EGG MOSS",
			"label": "EGG MOSS"
	},
	{
			"value": "EGG NEST",
			"label": "EGG NEST"
	},
	{
			"value": "EGGS",
			"label": "EGGS"
	},
	{
			"value": "EGGS BOXED",
			"label": "EGGS BOXED"
	},
	{
			"value": "EGGS DECORATIVE",
			"label": "EGGS DECORATIVE"
	},
	{
			"value": "EGGS PLASTIC",
			"label": "EGGS PLASTIC"
	},
	{
			"value": "EGGS PLASTIC MULTI",
			"label": "EGGS PLASTIC MULTI"
	},
	{
			"value": "EGGS PLASTIC NESTED",
			"label": "EGGS PLASTIC NESTED"
	},
	{
			"value": "EGG STAND",
			"label": "EGG STAND"
	},
	{
			"value": "EGG WREATH",
			"label": "EGG WREATH"
	},
	{
			"value": "EK SUCCESS",
			"label": "EK SUCCESS"
	},
	{
			"value": "EK SUCCESS AMERICAN",
			"label": "EK SUCCESS AMERICAN"
	},
	{
			"value": "EK TOOLS",
			"label": "EK TOOLS"
	},
	{
			"value": "ELASTIC",
			"label": "ELASTIC"
	},
	{
			"value": "ELASTIC CORD",
			"label": "ELASTIC CORD"
	},
	{
			"value": "ELASTIC EA",
			"label": "ELASTIC EA"
	},
	{
			"value": "ELASTIC MEDALLION",
			"label": "ELASTIC MEDALLION"
	},
	{
			"value": "ELASTIC THREAD",
			"label": "ELASTIC THREAD"
	},
	{
			"value": "ELASTIC YD",
			"label": "ELASTIC YD"
	},
	{
			"value": "ELECTRIC HTV",
			"label": "ELECTRIC HTV"
	},
	{
			"value": "ELECTRIC PARADISE",
			"label": "ELECTRIC PARADISE"
	},
	{
			"value": "ELEPHANT",
			"label": "ELEPHANT"
	},
	{
			"value": "ELEPHANTS",
			"label": "ELEPHANTS"
	},
	{
			"value": "ELF",
			"label": "ELF"
	},
	{
			"value": "ELF HAT",
			"label": "ELF HAT"
	},
	{
			"value": "ELF ON THE SHELF",
			"label": "ELF ON THE SHELF"
	},
	{
			"value": "ELF TABLETOP DECOR",
			"label": "ELF TABLETOP DECOR"
	},
	{
			"value": "ELITE STRANDS",
			"label": "ELITE STRANDS"
	},
	{
			"value": "ELLEN DEGENERES",
			"label": "ELLEN DEGENERES"
	},
	{
			"value": "ELLEN MEDLOCK",
			"label": "ELLEN MEDLOCK"
	},
	{
			"value": "ELMERS",
			"label": "ELMERS"
	},
	{
			"value": "ELMERS/X-ACTO",
			"label": "ELMERS/X-ACTO"
	},
	{
			"value": "ELMERS/XACTO",
			"label": "ELMERS/XACTO"
	},
	{
			"value": "ELNGTD CABLE CHAIN",
			"label": "ELNGTD CABLE CHAIN"
	},
	{
			"value": "ELVIS",
			"label": "ELVIS"
	},
	{
			"value": "EMBELLISH",
			"label": "EMBELLISH"
	},
	{
			"value": "EMBELLISHED KNIT",
			"label": "EMBELLISHED KNIT"
	},
	{
			"value": "EMBELLISHING TOOLS",
			"label": "EMBELLISHING TOOLS"
	},
	{
			"value": "EMBELLISHMENT",
			"label": "EMBELLISHMENT"
	},
	{
			"value": "EMBELLISHMENT QUILTS",
			"label": "EMBELLISHMENT QUILTS"
	},
	{
			"value": "EMBELLISHMENTS",
			"label": "EMBELLISHMENTS"
	},
	{
			"value": "EMBLSA00",
			"label": "EMBLSA00"
	},
	{
			"value": "EMBLSB00",
			"label": "EMBLSB00"
	},
	{
			"value": "EMB ORGANZA",
			"label": "EMB ORGANZA"
	},
	{
			"value": "EMBOSSED",
			"label": "EMBOSSED"
	},
	{
			"value": "EMBOSSED CON",
			"label": "EMBOSSED CON"
	},
	{
			"value": "EMBOSSING",
			"label": "EMBOSSING"
	},
	{
			"value": "EMB PILLOW",
			"label": "EMB PILLOW"
	},
	{
			"value": "EMBR BEE",
			"label": "EMBR BEE"
	},
	{
			"value": "EMBROIDERED",
			"label": "EMBROIDERED"
	},
	{
			"value": "EMBROIDERED DENIM",
			"label": "EMBROIDERED DENIM"
	},
	{
			"value": "EMBROIDERED HOOP",
			"label": "EMBROIDERED HOOP"
	},
	{
			"value": "EMBROIDERED LINEN",
			"label": "EMBROIDERED LINEN"
	},
	{
			"value": "EMBROIDERY",
			"label": "EMBROIDERY"
	},
	{
			"value": "EMBROIDERY LABELS",
			"label": "EMBROIDERY LABELS"
	},
	{
			"value": "EMBROIDERY PILLOW",
			"label": "EMBROIDERY PILLOW"
	},
	{
			"value": "EMBROIDERY/QUILTING",
			"label": "EMBROIDERY/QUILTING"
	},
	{
			"value": "EMBROIDERY QUILTS",
			"label": "EMBROIDERY QUILTS"
	},
	{
			"value": "EMBROIDERY STABILIZE",
			"label": "EMBROIDERY STABILIZE"
	},
	{
			"value": "EMBROIDERY TREND",
			"label": "EMBROIDERY TREND"
	},
	{
			"value": "EMBROID INTRFC",
			"label": "EMBROID INTRFC"
	},
	{
			"value": "EMB WHT",
			"label": "EMB WHT"
	},
	{
			"value": "EM NEEDLES",
			"label": "EM NEEDLES"
	},
	{
			"value": "EMOJI",
			"label": "EMOJI"
	},
	{
			"value": "EMPTY",
			"label": "EMPTY"
	},
	{
			"value": "EMPTY PDQ",
			"label": "EMPTY PDQ"
	},
	{
			"value": "EMPTY PLSTC BOTTLE",
			"label": "EMPTY PLSTC BOTTLE"
	},
	{
			"value": "ENAMEL PIN",
			"label": "ENAMEL PIN"
	},
	{
			"value": "ENAMEL STAKE",
			"label": "ENAMEL STAKE"
	},
	{
			"value": "END BARS",
			"label": "END BARS"
	},
	{
			"value": "END CAP",
			"label": "END CAP"
	},
	{
			"value": "ENDCAP014",
			"label": "ENDCAP014"
	},
	{
			"value": "ENDCP001",
			"label": "ENDCP001"
	},
	{
			"value": "ENDCP049 MERMAID",
			"label": "ENDCP049 MERMAID"
	},
	{
			"value": "ENDCP055 TREND",
			"label": "ENDCP055 TREND"
	},
	{
			"value": "ENDLESS SUMMER",
			"label": "ENDLESS SUMMER"
	},
	{
			"value": "ENGRAVING ART KIT",
			"label": "ENGRAVING ART KIT"
	},
	{
			"value": "ENJOY TODAY",
			"label": "ENJOY TODAY"
	},
	{
			"value": "ENJOY WB",
			"label": "ENJOY WB"
	},
	{
			"value": "ENT DÉCOR",
			"label": "ENT DÉCOR"
	},
	{
			"value": "ENTERTAINING",
			"label": "ENTERTAINING"
	},
	{
			"value": "ENTHIC",
			"label": "ENTHIC"
	},
	{
			"value": "ENTICING AROMAS",
			"label": "ENTICING AROMAS"
	},
	{
			"value": "ENVELOPE PACK",
			"label": "ENVELOPE PACK"
	},
	{
			"value": "ENVELOPES",
			"label": "ENVELOPES"
	},
	{
			"value": "ENVIRONMENTAL",
			"label": "ENVIRONMENTAL"
	},
	{
			"value": "ENVIRONMENTAL ASP",
			"label": "ENVIRONMENTAL ASP"
	},
	{
			"value": "EPIPHANY CRAFTS",
			"label": "EPIPHANY CRAFTS"
	},
	{
			"value": "ERGONOMIC",
			"label": "ERGONOMIC"
	},
	{
			"value": "ERGONOMIC SPRING",
			"label": "ERGONOMIC SPRING"
	},
	{
			"value": "ESSENTIAL OIL",
			"label": "ESSENTIAL OIL"
	},
	{
			"value": "ESSENTIAL OILS",
			"label": "ESSENTIAL OILS"
	},
	{
			"value": "ESSENTIAL SUCCULENT",
			"label": "ESSENTIAL SUCCULENT"
	},
	{
			"value": "ET",
			"label": "ET"
	},
	{
			"value": "ETHNIC",
			"label": "ETHNIC"
	},
	{
			"value": "ETHNIC PRINTS",
			"label": "ETHNIC PRINTS"
	},
	{
			"value": "EUC",
			"label": "EUC"
	},
	{
			"value": "EUCAL",
			"label": "EUCAL"
	},
	{
			"value": "EUCALAN",
			"label": "EUCALAN"
	},
	{
			"value": "EUCALYPT",
			"label": "EUCALYPT"
	},
	{
			"value": "EUCALYPTSUS",
			"label": "EUCALYPTSUS"
	},
	{
			"value": "EUCALYPTUS",
			"label": "EUCALYPTUS"
	},
	{
			"value": "EUCALYPTUS LVS",
			"label": "EUCALYPTUS LVS"
	},
	{
			"value": "EUCALYPUTS",
			"label": "EUCALYPUTS"
	},
	{
			"value": "EUCALYTPUS",
			"label": "EUCALYTPUS"
	},
	{
			"value": "EUC LEAF",
			"label": "EUC LEAF"
	},
	{
			"value": "EUC LVS",
			"label": "EUC LVS"
	},
	{
			"value": "EUCLYPTUS",
			"label": "EUCLYPTUS"
	},
	{
			"value": "EUCULYPTUS",
			"label": "EUCULYPTUS"
	},
	{
			"value": "EUPHORBIA",
			"label": "EUPHORBIA"
	},
	{
			"value": "EUROGRAPHICS",
			"label": "EUROGRAPHICS"
	},
	{
			"value": "EURO-NOTIONS",
			"label": "EURO-NOTIONS"
	},
	{
			"value": "EUSTOMA",
			"label": "EUSTOMA"
	},
	{
			"value": "EVA",
			"label": "EVA"
	},
	{
			"value": "EVA ROSE",
			"label": "EVA ROSE"
	},
	{
			"value": "EVENING COLLECTION",
			"label": "EVENING COLLECTION"
	},
	{
			"value": "EVENING JEWEL",
			"label": "EVENING JEWEL"
	},
	{
			"value": "EVER TOWEL",
			"label": "EVER TOWEL"
	},
	{
			"value": "EVERYDAY",
			"label": "EVERYDAY"
	},
	{
			"value": "EVERYDAY ANTIBAC",
			"label": "EVERYDAY ANTIBAC"
	},
	{
			"value": "EVERYDAY IO BULK",
			"label": "EVERYDAY IO BULK"
	},
	{
			"value": "EVERYDAY IRON ON",
			"label": "EVERYDAY IRON ON"
	},
	{
			"value": "EVERYDAY SENTIMENT",
			"label": "EVERYDAY SENTIMENT"
	},
	{
			"value": "EVERYDAY SOCKS",
			"label": "EVERYDAY SOCKS"
	},
	{
			"value": "EVNT1",
			"label": "EVNT1"
	},
	{
			"value": "EVRYDY NO SENTIMENT",
			"label": "EVRYDY NO SENTIMENT"
	},
	{
			"value": "EVRYDY SENTIMENT",
			"label": "EVRYDY SENTIMENT"
	},
	{
			"value": "EW DOUBLE FOLD",
			"label": "EW DOUBLE FOLD"
	},
	{
			"value": "EXCEL",
			"label": "EXCEL"
	},
	{
			"value": "EXPLORE",
			"label": "EXPLORE"
	},
	{
			"value": "EXPLORE 3",
			"label": "EXPLORE 3"
	},
	{
			"value": "EXT CORD",
			"label": "EXT CORD"
	},
	{
			"value": "EXT. CORD",
			"label": "EXT. CORD"
	},
	{
			"value": "EXTENDED AISLE",
			"label": "EXTENDED AISLE"
	},
	{
			"value": "EXTENDER",
			"label": "EXTENDER"
	},
	{
			"value": "EXTENSION PACK",
			"label": "EXTENSION PACK"
	},
	{
			"value": "EXTENSION TABLE",
			"label": "EXTENSION TABLE"
	},
	{
			"value": "EXTRA LOFT",
			"label": "EXTRA LOFT"
	},
	{
			"value": "EYE",
			"label": "EYE"
	},
	{
			"value": "EYEBALL",
			"label": "EYEBALL"
	},
	{
			"value": "EYEBALLS",
			"label": "EYEBALLS"
	},
	{
			"value": "EYED SUSAN BSH",
			"label": "EYED SUSAN BSH"
	},
	{
			"value": "EYE GLASS CONNECTORS",
			"label": "EYE GLASS CONNECTORS"
	},
	{
			"value": "EYELASH",
			"label": "EYELASH"
	},
	{
			"value": "EYE LASHES",
			"label": "EYE LASHES"
	},
	{
			"value": "EYELASHES",
			"label": "EYELASHES"
	},
	{
			"value": "EYELET",
			"label": "EYELET"
	},
	{
			"value": "EYELET OUTLET",
			"label": "EYELET OUTLET"
	},
	{
			"value": "EYE MASK",
			"label": "EYE MASK"
	},
	{
			"value": "EYE PATCH",
			"label": "EYE PATCH"
	},
	{
			"value": "EYE PINS",
			"label": "EYE PINS"
	},
	{
			"value": "EYES",
			"label": "EYES"
	},
	{
			"value": "EYES NON ADHESIVE",
			"label": "EYES NON ADHESIVE"
	},
	{
			"value": "EYES SEW ON",
			"label": "EYES SEW ON"
	},
	{
			"value": "EYES STICKY BACK",
			"label": "EYES STICKY BACK"
	},
	{
			"value": "FABER-CASTELL",
			"label": "FABER-CASTELL"
	},
	{
			"value": "FABPT05",
			"label": "FABPT05"
	},
	{
			"value": "FABPTA00",
			"label": "FABPTA00"
	},
	{
			"value": "FABPTB00",
			"label": "FABPTB00"
	},
	{
			"value": "FABPTC00",
			"label": "FABPTC00"
	},
	{
			"value": "FABPTD00",
			"label": "FABPTD00"
	},
	{
			"value": "FABPTE00",
			"label": "FABPTE00"
	},
	{
			"value": "FABPTF00",
			"label": "FABPTF00"
	},
	{
			"value": "FABRICA00",
			"label": "FABRICA00"
	},
	{
			"value": "FABRIC BANNER",
			"label": "FABRIC BANNER"
	},
	{
			"value": "FABRIC BIRD",
			"label": "FABRIC BIRD"
	},
	{
			"value": "FABRIC BUNDLE",
			"label": "FABRIC BUNDLE"
	},
	{
			"value": "FABRIC CARE",
			"label": "FABRIC CARE"
	},
	{
			"value": "FABRIC CENTRAL GARDE",
			"label": "FABRIC CENTRAL GARDE"
	},
	{
			"value": "FABRIC CENTRAL KALIN",
			"label": "FABRIC CENTRAL KALIN"
	},
	{
			"value": "FABRIC CENTRAL MODER",
			"label": "FABRIC CENTRAL MODER"
	},
	{
			"value": "FABRIC CENTRAL SHADO",
			"label": "FABRIC CENTRAL SHADO"
	},
	{
			"value": "FABRIC CENTRAL TWIST",
			"label": "FABRIC CENTRAL TWIST"
	},
	{
			"value": "FABRIC CLIP",
			"label": "FABRIC CLIP"
	},
	{
			"value": "FABRIC DRAWER",
			"label": "FABRIC DRAWER"
	},
	{
			"value": "FABRIC EDITIONS LLC",
			"label": "FABRIC EDITIONS LLC"
	},
	{
			"value": "FABRIC FLAG",
			"label": "FABRIC FLAG"
	},
	{
			"value": "FABRIC MARKER",
			"label": "FABRIC MARKER"
	},
	{
			"value": "FABRIC NAPKIN",
			"label": "FABRIC NAPKIN"
	},
	{
			"value": "FABRIC PLACLEMAT",
			"label": "FABRIC PLACLEMAT"
	},
	{
			"value": "FABRIC PUMPKIN",
			"label": "FABRIC PUMPKIN"
	},
	{
			"value": "FABRIC QUARTER",
			"label": "FABRIC QUARTER"
	},
	{
			"value": "FABRIC SHADES & CORN",
			"label": "FABRIC SHADES & CORN"
	},
	{
			"value": "FABRIC STORAGE",
			"label": "FABRIC STORAGE"
	},
	{
			"value": "FABRIC TABLECLOTH",
			"label": "FABRIC TABLECLOTH"
	},
	{
			"value": "FACE",
			"label": "FACE"
	},
	{
			"value": "FACE PAINT",
			"label": "FACE PAINT"
	},
	{
			"value": "FACES",
			"label": "FACES"
	},
	{
			"value": "FACE STICKER",
			"label": "FACE STICKER"
	},
	{
			"value": "FACETED BEADS",
			"label": "FACETED BEADS"
	},
	{
			"value": "FACINATOR",
			"label": "FACINATOR"
	},
	{
			"value": "FAD BULLETIN BRD PP",
			"label": "FAD BULLETIN BRD PP"
	},
	{
			"value": "FA HOME DECOR",
			"label": "FA HOME DECOR"
	},
	{
			"value": "FAIR AND TARTAN",
			"label": "FAIR AND TARTAN"
	},
	{
			"value": "FAIRFIELD",
			"label": "FAIRFIELD"
	},
	{
			"value": "FAIRIES",
			"label": "FAIRIES"
	},
	{
			"value": "FAIRISLE COVERLET",
			"label": "FAIRISLE COVERLET"
	},
	{
			"value": "FAIRWAY",
			"label": "FAIRWAY"
	},
	{
			"value": "FAIRY",
			"label": "FAIRY"
	},
	{
			"value": "FAIRY GARDEN",
			"label": "FAIRY GARDEN"
	},
	{
			"value": "FAITH",
			"label": "FAITH"
	},
	{
			"value": "FALL",
			"label": "FALL"
	},
	{
			"value": "FALL 3 WICK",
			"label": "FALL 3 WICK"
	},
	{
			"value": "FALL BANNER",
			"label": "FALL BANNER"
	},
	{
			"value": "FALL BOW",
			"label": "FALL BOW"
	},
	{
			"value": "FALL BUFFALO",
			"label": "FALL BUFFALO"
	},
	{
			"value": "FALL BURLAP",
			"label": "FALL BURLAP"
	},
	{
			"value": "FALL CANVAS",
			"label": "FALL CANVAS"
	},
	{
			"value": "FALL COLORUP",
			"label": "FALL COLORUP"
	},
	{
			"value": "FALL CONATINERS",
			"label": "FALL CONATINERS"
	},
	{
			"value": "FALL CONTAINER",
			"label": "FALL CONTAINER"
	},
	{
			"value": "FALL COUNTRY RIBBON",
			"label": "FALL COUNTRY RIBBON"
	},
	{
			"value": "FALL CRAFT KIT",
			"label": "FALL CRAFT KIT"
	},
	{
			"value": "FALL DECO MESH",
			"label": "FALL DECO MESH"
	},
	{
			"value": "FALL FASHION",
			"label": "FALL FASHION"
	},
	{
			"value": "FALL FASHION KNITS",
			"label": "FALL FASHION KNITS"
	},
	{
			"value": "FALL FASHION RIBBON",
			"label": "FALL FASHION RIBBON"
	},
	{
			"value": "FALL FIGURAL",
			"label": "FALL FIGURAL"
	},
	{
			"value": "FALL FOAM PLATTER",
			"label": "FALL FOAM PLATTER"
	},
	{
			"value": "FALL FOAM SHAPE",
			"label": "FALL FOAM SHAPE"
	},
	{
			"value": "FALL FOAMSHAPE",
			"label": "FALL FOAMSHAPE"
	},
	{
			"value": "FALL FUZZY POSTER",
			"label": "FALL FUZZY POSTER"
	},
	{
			"value": "FALLFY15OPPBUY",
			"label": "FALLFY15OPPBUY"
	},
	{
			"value": "FALLFY15PERFORMANCEO",
			"label": "FALLFY15PERFORMANCEO"
	},
	{
			"value": "FALL FY16 OPP BUY",
			"label": "FALL FY16 OPP BUY"
	},
	{
			"value": "FALL GARLAND",
			"label": "FALL GARLAND"
	},
	{
			"value": "FALL GEL CLING",
			"label": "FALL GEL CLING"
	},
	{
			"value": "FALL/HARVEST HOME DE",
			"label": "FALL/HARVEST HOME DE"
	},
	{
			"value": "FALL INCLUSION",
			"label": "FALL INCLUSION"
	},
	{
			"value": "FALL INDOOR",
			"label": "FALL INDOOR"
	},
	{
			"value": "FALLING SNOW",
			"label": "FALLING SNOW"
	},
	{
			"value": "FALL JAR",
			"label": "FALL JAR"
	},
	{
			"value": "FALL JEWELRY",
			"label": "FALL JEWELRY"
	},
	{
			"value": "FALL JUVENILE APPARE",
			"label": "FALL JUVENILE APPARE"
	},
	{
			"value": "FALL KIT",
			"label": "FALL KIT"
	},
	{
			"value": "FALL LED",
			"label": "FALL LED"
	},
	{
			"value": "FALL MELTY BEAD",
			"label": "FALL MELTY BEAD"
	},
	{
			"value": "FALL MESH",
			"label": "FALL MESH"
	},
	{
			"value": "FALL METALLICS",
			"label": "FALL METALLICS"
	},
	{
			"value": "FALL MIBI",
			"label": "FALL MIBI"
	},
	{
			"value": "FALL MOTIFS",
			"label": "FALL MOTIFS"
	},
	{
			"value": "FALL NOVELTY JAR",
			"label": "FALL NOVELTY JAR"
	},
	{
			"value": "FALL PAINTBOOK",
			"label": "FALL PAINTBOOK"
	},
	{
			"value": "FALL PAPER",
			"label": "FALL PAPER"
	},
	{
			"value": "FALL PILLAR",
			"label": "FALL PILLAR"
	},
	{
			"value": "FALL PLASTER",
			"label": "FALL PLASTER"
	},
	{
			"value": "FALL POM",
			"label": "FALL POM"
	},
	{
			"value": "FALL POM POM",
			"label": "FALL POM POM"
	},
	{
			"value": "FALL RIBBON",
			"label": "FALL RIBBON"
	},
	{
			"value": "FALL SC",
			"label": "FALL SC"
	},
	{
			"value": "FALL SCRATCHART",
			"label": "FALL SCRATCHART"
	},
	{
			"value": "FALL SHRINK",
			"label": "FALL SHRINK"
	},
	{
			"value": "FALL STICKER",
			"label": "FALL STICKER"
	},
	{
			"value": "FALL STICKERBOOK",
			"label": "FALL STICKERBOOK"
	},
	{
			"value": "FALL SUITINGS",
			"label": "FALL SUITINGS"
	},
	{
			"value": "FALL TAFFETA",
			"label": "FALL TAFFETA"
	},
	{
			"value": "FALL TAPER",
			"label": "FALL TAPER"
	},
	{
			"value": "FALL TEALIGHT",
			"label": "FALL TEALIGHT"
	},
	{
			"value": "FALL WAX MELT",
			"label": "FALL WAX MELT"
	},
	{
			"value": "FALL WOOD",
			"label": "FALL WOOD"
	},
	{
			"value": "FALL WOOD DOLL",
			"label": "FALL WOOD DOLL"
	},
	{
			"value": "FALL WOODEN WICK",
			"label": "FALL WOODEN WICK"
	},
	{
			"value": "FALL WOOD MASK",
			"label": "FALL WOOD MASK"
	},
	{
			"value": "FALL WOOD PADDLE",
			"label": "FALL WOOD PADDLE"
	},
	{
			"value": "FALL WOOD PAINT",
			"label": "FALL WOOD PAINT"
	},
	{
			"value": "FALL WOOD PLATTER",
			"label": "FALL WOOD PLATTER"
	},
	{
			"value": "FALL WOOD SHAPE",
			"label": "FALL WOOD SHAPE"
	},
	{
			"value": "FALL WOOD STAND",
			"label": "FALL WOOD STAND"
	},
	{
			"value": "FALL WOOD STRING",
			"label": "FALL WOOD STRING"
	},
	{
			"value": "FAMILY",
			"label": "FAMILY"
	},
	{
			"value": "FAMILY STORY",
			"label": "FAMILY STORY"
	},
	{
			"value": "FAMILY WALL DECOR",
			"label": "FAMILY WALL DECOR"
	},
	{
			"value": "FAMILY WD",
			"label": "FAMILY WD"
	},
	{
			"value": "FAMOUS MAKER",
			"label": "FAMOUS MAKER"
	},
	{
			"value": "FAMOUS MAKER FY14",
			"label": "FAMOUS MAKER FY14"
	},
	{
			"value": "FAMOUS MAKER FY15 FW",
			"label": "FAMOUS MAKER FY15 FW"
	},
	{
			"value": "FAN",
			"label": "FAN"
	},
	{
			"value": "FANCY",
			"label": "FANCY"
	},
	{
			"value": "FANCY FINDINGS",
			"label": "FANCY FINDINGS"
	},
	{
			"value": "FANCY PANTS",
			"label": "FANCY PANTS"
	},
	{
			"value": "FANCY THAT",
			"label": "FANCY THAT"
	},
	{
			"value": "FAN FLAG",
			"label": "FAN FLAG"
	},
	{
			"value": "FANNY PACK",
			"label": "FANNY PACK"
	},
	{
			"value": "FAN PALM",
			"label": "FAN PALM"
	},
	{
			"value": "FANS",
			"label": "FANS"
	},
	{
			"value": "FANTASY",
			"label": "FANTASY"
	},
	{
			"value": "FANUNCULUS",
			"label": "FANUNCULUS"
	},
	{
			"value": "FARM",
			"label": "FARM"
	},
	{
			"value": "FARM ANIMALS",
			"label": "FARM ANIMALS"
	},
	{
			"value": "FARM BOX",
			"label": "FARM BOX"
	},
	{
			"value": "FARMHOUSE",
			"label": "FARMHOUSE"
	},
	{
			"value": "FARMHOUSE COTTON",
			"label": "FARMHOUSE COTTON"
	},
	{
			"value": "FARM/RURAL ANIMALS",
			"label": "FARM/RURAL ANIMALS"
	},
	{
			"value": "FARM/RURAL/ANIMALS",
			"label": "FARM/RURAL/ANIMALS"
	},
	{
			"value": "FARMSTEAD",
			"label": "FARMSTEAD"
	},
	{
			"value": "FARM STICKER",
			"label": "FARM STICKER"
	},
	{
			"value": "FASCINATOR",
			"label": "FASCINATOR"
	},
	{
			"value": "FASHION",
			"label": "FASHION"
	},
	{
			"value": "FASHION  ASSORTMENT",
			"label": "FASHION  ASSORTMENT"
	},
	{
			"value": "FASHION BASKETS",
			"label": "FASHION BASKETS"
	},
	{
			"value": "FASHION BEADS",
			"label": "FASHION BEADS"
	},
	{
			"value": "FASHION BEAUTY",
			"label": "FASHION BEAUTY"
	},
	{
			"value": "FASHION BLUE PANEL",
			"label": "FASHION BLUE PANEL"
	},
	{
			"value": "FASHION BOTTOMWEIGHT",
			"label": "FASHION BOTTOMWEIGHT"
	},
	{
			"value": "FASHION BOW",
			"label": "FASHION BOW"
	},
	{
			"value": "FASHION BTTMWEIGHTS",
			"label": "FASHION BTTMWEIGHTS"
	},
	{
			"value": "FASHION CHAIN",
			"label": "FASHION CHAIN"
	},
	{
			"value": "FASHION COATS & JACK",
			"label": "FASHION COATS & JACK"
	},
	{
			"value": "FASHION COLOR RICH",
			"label": "FASHION COLOR RICH"
	},
	{
			"value": "FASHION CONSTRUCTION",
			"label": "FASHION CONSTRUCTION"
	},
	{
			"value": "FASHION CORD",
			"label": "FASHION CORD"
	},
	{
			"value": "FASHION CORDORY",
			"label": "FASHION CORDORY"
	},
	{
			"value": "FASHION DENIM",
			"label": "FASHION DENIM"
	},
	{
			"value": "FASHION DESERT",
			"label": "FASHION DESERT"
	},
	{
			"value": "FASHION DESIGN KIT",
			"label": "FASHION DESIGN KIT"
	},
	{
			"value": "FASHION FABRIC",
			"label": "FASHION FABRIC"
	},
	{
			"value": "FASHION FARMHOUSE",
			"label": "FASHION FARMHOUSE"
	},
	{
			"value": "FASHION FEMININE",
			"label": "FASHION FEMININE"
	},
	{
			"value": "FASHION FEMINITY",
			"label": "FASHION FEMINITY"
	},
	{
			"value": "FASHION FLANNEL",
			"label": "FASHION FLANNEL"
	},
	{
			"value": "FASHION FLEECE",
			"label": "FASHION FLEECE"
	},
	{
			"value": "FASHION FLORAL",
			"label": "FASHION FLORAL"
	},
	{
			"value": "FASHION GARLAND",
			"label": "FASHION GARLAND"
	},
	{
			"value": "FASHION GREENERY PA",
			"label": "FASHION GREENERY PA"
	},
	{
			"value": "FASHION GREENERY RIB",
			"label": "FASHION GREENERY RIB"
	},
	{
			"value": "FASHION HOUSE",
			"label": "FASHION HOUSE"
	},
	{
			"value": "FASHION/JEWELRY",
			"label": "FASHION/JEWELRY"
	},
	{
			"value": "FASHION JEWELRY PIEC",
			"label": "FASHION JEWELRY PIEC"
	},
	{
			"value": "FASHION KNITS",
			"label": "FASHION KNITS"
	},
	{
			"value": "FASHION LAVENDAR PAN",
			"label": "FASHION LAVENDAR PAN"
	},
	{
			"value": "FASHION NFL FLANNEL",
			"label": "FASHION NFL FLANNEL"
	},
	{
			"value": "FASHION NOTIONS",
			"label": "FASHION NOTIONS"
	},
	{
			"value": "FASHION PEACH PANEL-",
			"label": "FASHION PEACH PANEL-"
	},
	{
			"value": "FASHION PRINTS",
			"label": "FASHION PRINTS"
	},
	{
			"value": "FASHION RIBBON",
			"label": "FASHION RIBBON"
	},
	{
			"value": "FASHION ROTARY",
			"label": "FASHION ROTARY"
	},
	{
			"value": "FASHION SCISSORS",
			"label": "FASHION SCISSORS"
	},
	{
			"value": "FASHION SHADES OF PI",
			"label": "FASHION SHADES OF PI"
	},
	{
			"value": "FASHION SHIRTING",
			"label": "FASHION SHIRTING"
	},
	{
			"value": "FASHION SHIRTINGS",
			"label": "FASHION SHIRTINGS"
	},
	{
			"value": "FASHION STR SATEEN",
			"label": "FASHION STR SATEEN"
	},
	{
			"value": "FASHION SUEDE",
			"label": "FASHION SUEDE"
	},
	{
			"value": "FASHION SUITING",
			"label": "FASHION SUITING"
	},
	{
			"value": "FASHION TOPS & BOTTO",
			"label": "FASHION TOPS & BOTTO"
	},
	{
			"value": "FASHION TWILL",
			"label": "FASHION TWILL"
	},
	{
			"value": "FASHION VOYAGE",
			"label": "FASHION VOYAGE"
	},
	{
			"value": "FASHION WILDONE",
			"label": "FASHION WILDONE"
	},
	{
			"value": "FASHION WINTRY",
			"label": "FASHION WINTRY"
	},
	{
			"value": "FASTENER",
			"label": "FASTENER"
	},
	{
			"value": "FAUX FEATHER",
			"label": "FAUX FEATHER"
	},
	{
			"value": "FAUX LEATHER",
			"label": "FAUX LEATHER"
	},
	{
			"value": "FAUX SUEDE & FAUX LE",
			"label": "FAUX SUEDE & FAUX LE"
	},
	{
			"value": "FAVOR",
			"label": "FAVOR"
	},
	{
			"value": "FAVORITE FINDING",
			"label": "FAVORITE FINDING"
	},
	{
			"value": "FAVORITE FINDINGS",
			"label": "FAVORITE FINDINGS"
	},
	{
			"value": "FC",
			"label": "FC"
	},
	{
			"value": "FE610604",
			"label": "FE610604"
	},
	{
			"value": "FEATH",
			"label": "FEATH"
	},
	{
			"value": "FEATHB00",
			"label": "FEATHB00"
	},
	{
			"value": "FEATHC00",
			"label": "FEATHC00"
	},
	{
			"value": "FEATHER",
			"label": "FEATHER"
	},
	{
			"value": "FEATHER ACCESSORIES",
			"label": "FEATHER ACCESSORIES"
	},
	{
			"value": "FEATHERED LEAVES",
			"label": "FEATHERED LEAVES"
	},
	{
			"value": "FEATHER-FIL",
			"label": "FEATHER-FIL"
	},
	{
			"value": "FEATHER JEWELRY",
			"label": "FEATHER JEWELRY"
	},
	{
			"value": "FEATHERLOFT",
			"label": "FEATHERLOFT"
	},
	{
			"value": "FEATHERS",
			"label": "FEATHERS"
	},
	{
			"value": "FEATHER STEM",
			"label": "FEATHER STEM"
	},
	{
			"value": "FEATHER WREATH",
			"label": "FEATHER WREATH"
	},
	{
			"value": "FEB",
			"label": "FEB"
	},
	{
			"value": "FEB FY14 RTV",
			"label": "FEB FY14 RTV"
	},
	{
			"value": "FEBFY15RTV",
			"label": "FEBFY15RTV"
	},
	{
			"value": "FEBFY16RTV",
			"label": "FEBFY16RTV"
	},
	{
			"value": "FEBRUARY",
			"label": "FEBRUARY"
	},
	{
			"value": "FEDERAL PROCESS",
			"label": "FEDERAL PROCESS"
	},
	{
			"value": "FEEDING",
			"label": "FEEDING"
	},
	{
			"value": "FEEDING STATION",
			"label": "FEEDING STATION"
	},
	{
			"value": "FELIZ NAVIDAD",
			"label": "FELIZ NAVIDAD"
	},
	{
			"value": "FELT",
			"label": "FELT"
	},
	{
			"value": "FELT ABC/123",
			"label": "FELT ABC/123"
	},
	{
			"value": "FELT BASKET",
			"label": "FELT BASKET"
	},
	{
			"value": "FELTING",
			"label": "FELTING"
	},
	{
			"value": "FELT LETTERS",
			"label": "FELT LETTERS"
	},
	{
			"value": "FELT PUMPKIN",
			"label": "FELT PUMPKIN"
	},
	{
			"value": "FELT SHEET",
			"label": "FELT SHEET"
	},
	{
			"value": "FELT STARS",
			"label": "FELT STARS"
	},
	{
			"value": "FELT STICKER",
			"label": "FELT STICKER"
	},
	{
			"value": "FEMININE",
			"label": "FEMININE"
	},
	{
			"value": "FEMME",
			"label": "FEMME"
	},
	{
			"value": "FEMME TECH",
			"label": "FEMME TECH"
	},
	{
			"value": "FENCE",
			"label": "FENCE"
	},
	{
			"value": "FENCE DECOR",
			"label": "FENCE DECOR"
	},
	{
			"value": "FERN",
			"label": "FERN"
	},
	{
			"value": "FERN AND HERBS",
			"label": "FERN AND HERBS"
	},
	{
			"value": "FERN ART PMPKN",
			"label": "FERN ART PMPKN"
	},
	{
			"value": "FERN LVS",
			"label": "FERN LVS"
	},
	{
			"value": "FERNS",
			"label": "FERNS"
	},
	{
			"value": "FF",
			"label": "FF"
	},
	{
			"value": "FF1 MUGS",
			"label": "FF1 MUGS"
	},
	{
			"value": "FF1 STOCKINGS",
			"label": "FF1 STOCKINGS"
	},
	{
			"value": "FF1 TOWELS",
			"label": "FF1 TOWELS"
	},
	{
			"value": "FF2",
			"label": "FF2"
	},
	{
			"value": "FF2 MINI PILLOW",
			"label": "FF2 MINI PILLOW"
	},
	{
			"value": "FF2 SPINNERS",
			"label": "FF2 SPINNERS"
	},
	{
			"value": "FF2 WALL DECOR",
			"label": "FF2 WALL DECOR"
	},
	{
			"value": "FF3 56 PACK",
			"label": "FF3 56 PACK"
	},
	{
			"value": "FF3 CANDLE",
			"label": "FF3 CANDLE"
	},
	{
			"value": "FF3 LANTERN",
			"label": "FF3 LANTERN"
	},
	{
			"value": "FF BABY OSO",
			"label": "FF BABY OSO"
	},
	{
			"value": "FF BEACH",
			"label": "FF BEACH"
	},
	{
			"value": "FF BEACH OSO",
			"label": "FF BEACH OSO"
	},
	{
			"value": "FF DIVERSITY OSO",
			"label": "FF DIVERSITY OSO"
	},
	{
			"value": "FF FAMILY/WEDDING",
			"label": "FF FAMILY/WEDDING"
	},
	{
			"value": "FF FELT OSO",
			"label": "FF FELT OSO"
	},
	{
			"value": "FF FOOD/DRINK OSO",
			"label": "FF FOOD/DRINK OSO"
	},
	{
			"value": "FF FUR BABY OSO",
			"label": "FF FUR BABY OSO"
	},
	{
			"value": "FF FUR MAMA OSO",
			"label": "FF FUR MAMA OSO"
	},
	{
			"value": "FF FUR PET OSO",
			"label": "FF FUR PET OSO"
	},
	{
			"value": "FF HOBBY OSO",
			"label": "FF HOBBY OSO"
	},
	{
			"value": "FF NOVELTY OSO",
			"label": "FF NOVELTY OSO"
	},
	{
			"value": "FF PASTEL",
			"label": "FF PASTEL"
	},
	{
			"value": "FF PET OSO",
			"label": "FF PET OSO"
	},
	{
			"value": "FF SISAL",
			"label": "FF SISAL"
	},
	{
			"value": "FF SPORTS/MUSIC",
			"label": "FF SPORTS/MUSIC"
	},
	{
			"value": "FF SPORTS/MUSIC OSO",
			"label": "FF SPORTS/MUSIC OSO"
	},
	{
			"value": "FF TRAVEL/OCCUP OSO",
			"label": "FF TRAVEL/OCCUP OSO"
	},
	{
			"value": "FF WOOD ANIMAL OSO",
			"label": "FF WOOD ANIMAL OSO"
	},
	{
			"value": "FF WOOD ANIMALS OSO",
			"label": "FF WOOD ANIMALS OSO"
	},
	{
			"value": "FF ZODIAC OSO",
			"label": "FF ZODIAC OSO"
	},
	{
			"value": "FG ACCESSORIES",
			"label": "FG ACCESSORIES"
	},
	{
			"value": "FG ACCESSORY",
			"label": "FG ACCESSORY"
	},
	{
			"value": "FG ANIMAL",
			"label": "FG ANIMAL"
	},
	{
			"value": "FG ARCH",
			"label": "FG ARCH"
	},
	{
			"value": "FG BENCH",
			"label": "FG BENCH"
	},
	{
			"value": "FG BIRD",
			"label": "FG BIRD"
	},
	{
			"value": "FG BIRD HOUSE",
			"label": "FG BIRD HOUSE"
	},
	{
			"value": "FG BRIDGE",
			"label": "FG BRIDGE"
	},
	{
			"value": "FG CHAIR",
			"label": "FG CHAIR"
	},
	{
			"value": "FG CONTAINER",
			"label": "FG CONTAINER"
	},
	{
			"value": "FG DOOR",
			"label": "FG DOOR"
	},
	{
			"value": "FG DOORWAY",
			"label": "FG DOORWAY"
	},
	{
			"value": "FG FAIRY",
			"label": "FG FAIRY"
	},
	{
			"value": "FG FENCE",
			"label": "FG FENCE"
	},
	{
			"value": "FG FLOWER",
			"label": "FG FLOWER"
	},
	{
			"value": "FG FOOD",
			"label": "FG FOOD"
	},
	{
			"value": "FG GARDEN ACCESSORY",
			"label": "FG GARDEN ACCESSORY"
	},
	{
			"value": "FG GATE",
			"label": "FG GATE"
	},
	{
			"value": "FG GNOME",
			"label": "FG GNOME"
	},
	{
			"value": "FG HOUSE",
			"label": "FG HOUSE"
	},
	{
			"value": "FG LAMP POST",
			"label": "FG LAMP POST"
	},
	{
			"value": "FG LANTERN",
			"label": "FG LANTERN"
	},
	{
			"value": "FG MUSHROOM",
			"label": "FG MUSHROOM"
	},
	{
			"value": "FG PATH",
			"label": "FG PATH"
	},
	{
			"value": "FG PICK",
			"label": "FG PICK"
	},
	{
			"value": "FG PINWHEEL",
			"label": "FG PINWHEEL"
	},
	{
			"value": "FG PLANT",
			"label": "FG PLANT"
	},
	{
			"value": "FG SEATING",
			"label": "FG SEATING"
	},
	{
			"value": "FG SIGN",
			"label": "FG SIGN"
	},
	{
			"value": "FG STAKE",
			"label": "FG STAKE"
	},
	{
			"value": "FG STEPPING STONE",
			"label": "FG STEPPING STONE"
	},
	{
			"value": "FG SWING",
			"label": "FG SWING"
	},
	{
			"value": "FG TABLE",
			"label": "FG TABLE"
	},
	{
			"value": "FG TERRARIUM",
			"label": "FG TERRARIUM"
	},
	{
			"value": "FG TOMBSTONE",
			"label": "FG TOMBSTONE"
	},
	{
			"value": "FG TRELLIS",
			"label": "FG TRELLIS"
	},
	{
			"value": "FG VEHICLE",
			"label": "FG VEHICLE"
	},
	{
			"value": "FG WINDOW",
			"label": "FG WINDOW"
	},
	{
			"value": "FIBER",
			"label": "FIBER"
	},
	{
			"value": "FIBERA01",
			"label": "FIBERA01"
	},
	{
			"value": "FIBERB01",
			"label": "FIBERB01"
	},
	{
			"value": "FIBERC00",
			"label": "FIBERC00"
	},
	{
			"value": "FIBERC01",
			"label": "FIBERC01"
	},
	{
			"value": "FIBRE CRAFT",
			"label": "FIBRE CRAFT"
	},
	{
			"value": "FICUS",
			"label": "FICUS"
	},
	{
			"value": "FIDDLE",
			"label": "FIDDLE"
	},
	{
			"value": "FIDDLE LEAF",
			"label": "FIDDLE LEAF"
	},
	{
			"value": "FIDDLE LEAVES",
			"label": "FIDDLE LEAVES"
	},
	{
			"value": "FIGARO CHAIN",
			"label": "FIGARO CHAIN"
	},
	{
			"value": "FIGUAL TT",
			"label": "FIGUAL TT"
	},
	{
			"value": "FIGUARL TT",
			"label": "FIGUARL TT"
	},
	{
			"value": "FIGURAL",
			"label": "FIGURAL"
	},
	{
			"value": "FIGURAL CANDLE",
			"label": "FIGURAL CANDLE"
	},
	{
			"value": "FIGURAL MUG",
			"label": "FIGURAL MUG"
	},
	{
			"value": "FIGURAL OUTDOOR",
			"label": "FIGURAL OUTDOOR"
	},
	{
			"value": "FIGURAL PORCH SITTER",
			"label": "FIGURAL PORCH SITTER"
	},
	{
			"value": "FIGURAL STOCKING",
			"label": "FIGURAL STOCKING"
	},
	{
			"value": "FIGURAL TT",
			"label": "FIGURAL TT"
	},
	{
			"value": "FIGURAL WALL DECOR",
			"label": "FIGURAL WALL DECOR"
	},
	{
			"value": "FIGURAL WD",
			"label": "FIGURAL WD"
	},
	{
			"value": "FIGURE 8 CHAIN",
			"label": "FIGURE 8 CHAIN"
	},
	{
			"value": "FIGURE CANDLE",
			"label": "FIGURE CANDLE"
	},
	{
			"value": "FIGURINE",
			"label": "FIGURINE"
	},
	{
			"value": "FIGURINES",
			"label": "FIGURINES"
	},
	{
			"value": "FILBERT",
			"label": "FILBERT"
	},
	{
			"value": "FILE BIN",
			"label": "FILE BIN"
	},
	{
			"value": "FILLER",
			"label": "FILLER"
	},
	{
			"value": "FILLER BALLS",
			"label": "FILLER BALLS"
	},
	{
			"value": "FILLER BEADS",
			"label": "FILLER BEADS"
	},
	{
			"value": "FILLER EGG",
			"label": "FILLER EGG"
	},
	{
			"value": "FILLER PAPER",
			"label": "FILLER PAPER"
	},
	{
			"value": "FINDING",
			"label": "FINDING"
	},
	{
			"value": "FINDING CAPS",
			"label": "FINDING CAPS"
	},
	{
			"value": "FINDING DORY",
			"label": "FINDING DORY"
	},
	{
			"value": "FINDING KIT",
			"label": "FINDING KIT"
	},
	{
			"value": "FINDINGS",
			"label": "FINDINGS"
	},
	{
			"value": "FINE ART POG",
			"label": "FINE ART POG"
	},
	{
			"value": "FINE ARTS",
			"label": "FINE ARTS"
	},
	{
			"value": "FINE ART SUPPLIES",
			"label": "FINE ART SUPPLIES"
	},
	{
			"value": "FINELINE APPLICATORS",
			"label": "FINELINE APPLICATORS"
	},
	{
			"value": "FINGER LOOP",
			"label": "FINGER LOOP"
	},
	{
			"value": "FINGER TOOL",
			"label": "FINGER TOOL"
	},
	{
			"value": " FINISHED JEWELRY, S",
			"label": " FINISHED JEWELRY, S"
	},
	{
			"value": "FINISHED SAMPLE",
			"label": "FINISHED SAMPLE"
	},
	{
			"value": "FIRE ENGINE",
			"label": "FIRE ENGINE"
	},
	{
			"value": "FIRELIGHT",
			"label": "FIRELIGHT"
	},
	{
			"value": "FIRELIGHT OSO",
			"label": "FIRELIGHT OSO"
	},
	{
			"value": "FIRELINE WIRE",
			"label": "FIRELINE WIRE"
	},
	{
			"value": "FIRE PIT",
			"label": "FIRE PIT"
	},
	{
			"value": "FIREWORKS",
			"label": "FIREWORKS"
	},
	{
			"value": "FIREWORKS TOWEL",
			"label": "FIREWORKS TOWEL"
	},
	{
			"value": "FIRGUAL TT",
			"label": "FIRGUAL TT"
	},
	{
			"value": "FIRST GRADE EDUCATIO",
			"label": "FIRST GRADE EDUCATIO"
	},
	{
			"value": "FIRST SNOW",
			"label": "FIRST SNOW"
	},
	{
			"value": "FIRST SNOW OSO",
			"label": "FIRST SNOW OSO"
	},
	{
			"value": "FISH",
			"label": "FISH"
	},
	{
			"value": "FISHBONE CHAIN",
			"label": "FISHBONE CHAIN"
	},
	{
			"value": "FISH HOOK EAR WIRES",
			"label": "FISH HOOK EAR WIRES"
	},
	{
			"value": "FISH HOOK EYE WIRES",
			"label": "FISH HOOK EYE WIRES"
	},
	{
			"value": "FISHING",
			"label": "FISHING"
	},
	{
			"value": "FISKARS",
			"label": "FISKARS"
	},
	{
			"value": "FITTING/ALTRTNS/PATT",
			"label": "FITTING/ALTRTNS/PATT"
	},
	{
			"value": "FITTONIA LEAVES",
			"label": "FITTONIA LEAVES"
	},
	{
			"value": "FIX-A-STITCH",
			"label": "FIX-A-STITCH"
	},
	{
			"value": "FIXTURE",
			"label": "FIXTURE"
	},
	{
			"value": "FLACC",
			"label": "FLACC"
	},
	{
			"value": "FLACCC00",
			"label": "FLACCC00"
	},
	{
			"value": "FL ACCESSORY",
			"label": "FL ACCESSORY"
	},
	{
			"value": "FLAG",
			"label": "FLAG"
	},
	{
			"value": "FLAG AND POLE",
			"label": "FLAG AND POLE"
	},
	{
			"value": "FLAG FIGURAL",
			"label": "FLAG FIGURAL"
	},
	{
			"value": "FLAGGED",
			"label": "FLAGGED"
	},
	{
			"value": "FLAG HEADER",
			"label": "FLAG HEADER"
	},
	{
			"value": "FLAG HOLDER",
			"label": "FLAG HOLDER"
	},
	{
			"value": "FLAG HOLDERS",
			"label": "FLAG HOLDERS"
	},
	{
			"value": "FLAG LEMONS",
			"label": "FLAG LEMONS"
	},
	{
			"value": "FLAGS",
			"label": "FLAGS"
	},
	{
			"value": "FLAG SINGLE",
			"label": "FLAG SINGLE"
	},
	{
			"value": "FLAG SINGLE XLARGE",
			"label": "FLAG SINGLE XLARGE"
	},
	{
			"value": "FLAG W HOLDER",
			"label": "FLAG W HOLDER"
	},
	{
			"value": "FLAIR",
			"label": "FLAIR"
	},
	{
			"value": "FLAMINGO",
			"label": "FLAMINGO"
	},
	{
			"value": "FLAMINGOS",
			"label": "FLAMINGOS"
	},
	{
			"value": "FLAMINGOS & TROPICAL",
			"label": "FLAMINGOS & TROPICAL"
	},
	{
			"value": "FLAMINGO TOWEL",
			"label": "FLAMINGO TOWEL"
	},
	{
			"value": "FLANNEL",
			"label": "FLANNEL"
	},
	{
			"value": "FLANNEL BACK SATIN",
			"label": "FLANNEL BACK SATIN"
	},
	{
			"value": "FLANNEL PRINTS - BOY",
			"label": "FLANNEL PRINTS - BOY"
	},
	{
			"value": "FLANNEL PRINTS - CAT",
			"label": "FLANNEL PRINTS - CAT"
	},
	{
			"value": "FLANNEL PRINTS - DOG",
			"label": "FLANNEL PRINTS - DOG"
	},
	{
			"value": "FLANNEL PRINTS - GEO",
			"label": "FLANNEL PRINTS - GEO"
	},
	{
			"value": "FLANNEL PRINTS - GIR",
			"label": "FLANNEL PRINTS - GIR"
	},
	{
			"value": "FLANNEL SHIRTING",
			"label": "FLANNEL SHIRTING"
	},
	{
			"value": "FLASHCARDS",
			"label": "FLASHCARDS"
	},
	{
			"value": "FLASHLIGHT",
			"label": "FLASHLIGHT"
	},
	{
			"value": "FLASK",
			"label": "FLASK"
	},
	{
			"value": "FLATBACK",
			"label": "FLATBACK"
	},
	{
			"value": "FLATBACK BEADS",
			"label": "FLATBACK BEADS"
	},
	{
			"value": "FLAT BASKET",
			"label": "FLAT BASKET"
	},
	{
			"value": "FLATWARE HOLDER",
			"label": "FLATWARE HOLDER"
	},
	{
			"value": "FLATWEAVE GRY",
			"label": "FLATWEAVE GRY"
	},
	{
			"value": "FLAVORS",
			"label": "FLAVORS"
	},
	{
			"value": "FLAX",
			"label": "FLAX"
	},
	{
			"value": "FL CONTAINER",
			"label": "FL CONTAINER"
	},
	{
			"value": "FLEECE",
			"label": "FLEECE"
	},
	{
			"value": "FLEECE - ANIT-PILL",
			"label": "FLEECE - ANIT-PILL"
	},
	{
			"value": "FLEECE - ANTI-PILL",
			"label": "FLEECE - ANTI-PILL"
	},
	{
			"value": "FLEECE ANTI-PILL",
			"label": "FLEECE ANTI-PILL"
	},
	{
			"value": "FLEECE BINDING",
			"label": "FLEECE BINDING"
	},
	{
			"value": "FLEECE - BLIZZARD",
			"label": "FLEECE - BLIZZARD"
	},
	{
			"value": "FLEECE BOOKS",
			"label": "FLEECE BOOKS"
	},
	{
			"value": "FLEECE ESSENTIALS",
			"label": "FLEECE ESSENTIALS"
	},
	{
			"value": "FLEECE FLANNEL",
			"label": "FLEECE FLANNEL"
	},
	{
			"value": "FLEECE PRINTS",
			"label": "FLEECE PRINTS"
	},
	{
			"value": "FLEECE PROGRAM",
			"label": "FLEECE PROGRAM"
	},
	{
			"value": "FLEECE SOLID FOR FAS",
			"label": "FLEECE SOLID FOR FAS"
	},
	{
			"value": "FLEXI-LACE HEM TAPE",
			"label": "FLEXI-LACE HEM TAPE"
	},
	{
			"value": "FL FIGURINE",
			"label": "FL FIGURINE"
	},
	{
			"value": "FL FURNITURE",
			"label": "FL FURNITURE"
	},
	{
			"value": "FLG BRY WRTH",
			"label": "FLG BRY WRTH"
	},
	{
			"value": "FL HOUSE",
			"label": "FL HOUSE"
	},
	{
			"value": "FLICKER CANDLE",
			"label": "FLICKER CANDLE"
	},
	{
			"value": "FLIP FLOP EMBELLISHM",
			"label": "FLIP FLOP EMBELLISHM"
	},
	{
			"value": "FLIP LIGHT",
			"label": "FLIP LIGHT"
	},
	{
			"value": "FLIPSIDE",
			"label": "FLIPSIDE"
	},
	{
			"value": "FLIP TOP",
			"label": "FLIP TOP"
	},
	{
			"value": "FL LED TREE",
			"label": "FL LED TREE"
	},
	{
			"value": "FLLFV",
			"label": "FLLFV"
	},
	{
			"value": "FLLFVB00",
			"label": "FLLFVB00"
	},
	{
			"value": "FLOAT",
			"label": "FLOAT"
	},
	{
			"value": "FLOATING TEALIGHT",
			"label": "FLOATING TEALIGHT"
	},
	{
			"value": "FLOCKED",
			"label": "FLOCKED"
	},
	{
			"value": "FLOCKED POM BSH",
			"label": "FLOCKED POM BSH"
	},
	{
			"value": "FLOOR",
			"label": "FLOOR"
	},
	{
			"value": "FLOOR LAMP",
			"label": "FLOOR LAMP"
	},
	{
			"value": "FLOOR MAT",
			"label": "FLOOR MAT"
	},
	{
			"value": "FLOOR PLANT",
			"label": "FLOOR PLANT"
	},
	{
			"value": "FLOORPLANT",
			"label": "FLOORPLANT"
	},
	{
			"value": "FLOOR PLANTER",
			"label": "FLOOR PLANTER"
	},
	{
			"value": "FLOOR WITH MAG",
			"label": "FLOOR WITH MAG"
	},
	{
			"value": "FLOPPY",
			"label": "FLOPPY"
	},
	{
			"value": "FLORACRAFT",
			"label": "FLORACRAFT"
	},
	{
			"value": "FLORAL",
			"label": "FLORAL"
	},
	{
			"value": "FLORAL-",
			"label": "FLORAL-"
	},
	{
			"value": "FLORAL-AA",
			"label": "FLORAL-AA"
	},
	{
			"value": "FLORAL BASKET",
			"label": "FLORAL BASKET"
	},
	{
			"value": "FLORAL BLENDER",
			"label": "FLORAL BLENDER"
	},
	{
			"value": "FLORAL & BOTANICAL",
			"label": "FLORAL & BOTANICAL"
	},
	{
			"value": "FLORAL CROWN",
			"label": "FLORAL CROWN"
	},
	{
			"value": "FLORAL-DD",
			"label": "FLORAL-DD"
	},
	{
			"value": "FLORAL - DITSY/PACK",
			"label": "FLORAL - DITSY/PACK"
	},
	{
			"value": "FLORAL EXPLOSION",
			"label": "FLORAL EXPLOSION"
	},
	{
			"value": "FLORAL / FOLIAGE",
			"label": "FLORAL / FOLIAGE"
	},
	{
			"value": "FLORAL/FOLIAGE",
			"label": "FLORAL/FOLIAGE"
	},
	{
			"value": "FLORAL-GG",
			"label": "FLORAL-GG"
	},
	{
			"value": "FLORAL HEARTS",
			"label": "FLORAL HEARTS"
	},
	{
			"value": "FLORAL-L",
			"label": "FLORAL-L"
	},
	{
			"value": "FLORAL METAL WALL",
			"label": "FLORAL METAL WALL"
	},
	{
			"value": "FLORAL-N",
			"label": "FLORAL-N"
	},
	{
			"value": "FLORAL-O",
			"label": "FLORAL-O"
	},
	{
			"value": "FLORAL-P",
			"label": "FLORAL-P"
	},
	{
			"value": "FLORAL PICK",
			"label": "FLORAL PICK"
	},
	{
			"value": "FLORAL PRINT",
			"label": "FLORAL PRINT"
	},
	{
			"value": "FLORAL-Q",
			"label": "FLORAL-Q"
	},
	{
			"value": "FLORAL-R",
			"label": "FLORAL-R"
	},
	{
			"value": "FLORAL-S",
			"label": "FLORAL-S"
	},
	{
			"value": "FLORALS",
			"label": "FLORALS"
	},
	{
			"value": "FLORAL SCRIBBLES",
			"label": "FLORAL SCRIBBLES"
	},
	{
			"value": "FLORAL STEM",
			"label": "FLORAL STEM"
	},
	{
			"value": "FLORAL TAPER",
			"label": "FLORAL TAPER"
	},
	{
			"value": "FLORAL TILE",
			"label": "FLORAL TILE"
	},
	{
			"value": "FLORAL-U",
			"label": "FLORAL-U"
	},
	{
			"value": "FLORAL VASES",
			"label": "FLORAL VASES"
	},
	{
			"value": "FLORAL VINE",
			"label": "FLORAL VINE"
	},
	{
			"value": "FLORAL VINES",
			"label": "FLORAL VINES"
	},
	{
			"value": "FLORAL-X",
			"label": "FLORAL-X"
	},
	{
			"value": "FLORAL-Y",
			"label": "FLORAL-Y"
	},
	{
			"value": "FLORAL-Z",
			"label": "FLORAL-Z"
	},
	{
			"value": "FLORENTINE",
			"label": "FLORENTINE"
	},
	{
			"value": "FLORIDA",
			"label": "FLORIDA"
	},
	{
			"value": "FLORIDA ST",
			"label": "FLORIDA ST"
	},
	{
			"value": "FLORIDA STATE",
			"label": "FLORIDA STATE"
	},
	{
			"value": "FLOSSC00",
			"label": "FLOSSC00"
	},
	{
			"value": "FLOURISH",
			"label": "FLOURISH"
	},
	{
			"value": "FLOUR SACK",
			"label": "FLOUR SACK"
	},
	{
			"value": "FLOUR SACK TOWEL",
			"label": "FLOUR SACK TOWEL"
	},
	{
			"value": "FLOWER",
			"label": "FLOWER"
	},
	{
			"value": "FLOWER BIRDHOUSE",
			"label": "FLOWER BIRDHOUSE"
	},
	{
			"value": "FLOWER CHAIN",
			"label": "FLOWER CHAIN"
	},
	{
			"value": "FLOWER CLIP",
			"label": "FLOWER CLIP"
	},
	{
			"value": "FLOWERING GARLAND",
			"label": "FLOWERING GARLAND"
	},
	{
			"value": "FLOWERING STEMS",
			"label": "FLOWERING STEMS"
	},
	{
			"value": "FLOWER MESH",
			"label": "FLOWER MESH"
	},
	{
			"value": "FLOWERS",
			"label": "FLOWERS"
	},
	{
			"value": "FLOWERS AND BIRDS",
			"label": "FLOWERS AND BIRDS"
	},
	{
			"value": "FLOWER SOFT",
			"label": "FLOWER SOFT"
	},
	{
			"value": "FLOWER TRELLIS",
			"label": "FLOWER TRELLIS"
	},
	{
			"value": "FLOWER WRTH",
			"label": "FLOWER WRTH"
	},
	{
			"value": "FLOW TOWEL",
			"label": "FLOW TOWEL"
	},
	{
			"value": "FL PICK",
			"label": "FL PICK"
	},
	{
			"value": "FL PUMPKIN",
			"label": "FL PUMPKIN"
	},
	{
			"value": "FLR BRY PINECONE",
			"label": "FLR BRY PINECONE"
	},
	{
			"value": "FLRL EXPLOSION",
			"label": "FLRL EXPLOSION"
	},
	{
			"value": "FLR PLNT",
			"label": "FLR PLNT"
	},
	{
			"value": "FL SIGN",
			"label": "FL SIGN"
	},
	{
			"value": "FL TREE",
			"label": "FL TREE"
	},
	{
			"value": "FLT SHADER",
			"label": "FLT SHADER"
	},
	{
			"value": "FLT SNAP",
			"label": "FLT SNAP"
	},
	{
			"value": "FLT WASH",
			"label": "FLT WASH"
	},
	{
			"value": "FLWR CR",
			"label": "FLWR CR"
	},
	{
			"value": "FLY AWAY",
			"label": "FLY AWAY"
	},
	{
			"value": "FM CHALLIS",
			"label": "FM CHALLIS"
	},
	{
			"value": "FM DENIM",
			"label": "FM DENIM"
	},
	{
			"value": "FM EYELET",
			"label": "FM EYELET"
	},
	{
			"value": "FM KIDS",
			"label": "FM KIDS"
	},
	{
			"value": "FM LACE",
			"label": "FM LACE"
	},
	{
			"value": "FMLGYB00",
			"label": "FMLGYB00"
	},
	{
			"value": "FM PRINT CORDUROY",
			"label": "FM PRINT CORDUROY"
	},
	{
			"value": "FM SCUBA",
			"label": "FM SCUBA"
	},
	{
			"value": "FM SOLID KNITS",
			"label": "FM SOLID KNITS"
	},
	{
			"value": "FM STRIPE KNITS",
			"label": "FM STRIPE KNITS"
	},
	{
			"value": "FNART",
			"label": "FNART"
	},
	{
			"value": "FNARTE00",
			"label": "FNARTE00"
	},
	{
			"value": "FNARTG00",
			"label": "FNARTG00"
	},
	{
			"value": "FOAM",
			"label": "FOAM"
	},
	{
			"value": "FOAM 3D KIT",
			"label": "FOAM 3D KIT"
	},
	{
			"value": "FOAM ALPHA STACKS",
			"label": "FOAM ALPHA STACKS"
	},
	{
			"value": "FOAM BASE",
			"label": "FOAM BASE"
	},
	{
			"value": "FOAM BASES",
			"label": "FOAM BASES"
	},
	{
			"value": "FOAM BOARD",
			"label": "FOAM BOARD"
	},
	{
			"value": "FOAM CROWN",
			"label": "FOAM CROWN"
	},
	{
			"value": "FOAM CUBE",
			"label": "FOAM CUBE"
	},
	{
			"value": "FOAM DRESS UP",
			"label": "FOAM DRESS UP"
	},
	{
			"value": "FOAM FIXTURE",
			"label": "FOAM FIXTURE"
	},
	{
			"value": "FOAM FRAME",
			"label": "FOAM FRAME"
	},
	{
			"value": "FOAM GLASSES",
			"label": "FOAM GLASSES"
	},
	{
			"value": "FOAM GLUE",
			"label": "FOAM GLUE"
	},
	{
			"value": "FOAMIES",
			"label": "FOAMIES"
	},
	{
			"value": "FOAMING SOAP",
			"label": "FOAMING SOAP"
	},
	{
			"value": "FOAMOLOGY",
			"label": "FOAMOLOGY"
	},
	{
			"value": "FOAM PLATTER",
			"label": "FOAM PLATTER"
	},
	{
			"value": "FOAM ROLL",
			"label": "FOAM ROLL"
	},
	{
			"value": "FOAM ROLLS",
			"label": "FOAM ROLLS"
	},
	{
			"value": "FOAM STACK",
			"label": "FOAM STACK"
	},
	{
			"value": "FOAM STICKERS",
			"label": "FOAM STICKERS"
	},
	{
			"value": "FOAM VALUE PACKS",
			"label": "FOAM VALUE PACKS"
	},
	{
			"value": "FOAM VISOR",
			"label": "FOAM VISOR"
	},
	{
			"value": "FOAM WALL DECOR",
			"label": "FOAM WALL DECOR"
	},
	{
			"value": "FOG",
			"label": "FOG"
	},
	{
			"value": "FOIL",
			"label": "FOIL"
	},
	{
			"value": "FOILAGE",
			"label": "FOILAGE"
	},
	{
			"value": "FOIL ART KIT",
			"label": "FOIL ART KIT"
	},
	{
			"value": "FOIL CHIFFON",
			"label": "FOIL CHIFFON"
	},
	{
			"value": "FOIL CUPS",
			"label": "FOIL CUPS"
	},
	{
			"value": "FOIL CUTOUT SET",
			"label": "FOIL CUTOUT SET"
	},
	{
			"value": "FOIL DOT",
			"label": "FOIL DOT"
	},
	{
			"value": "FOIL DOT TULLE",
			"label": "FOIL DOT TULLE"
	},
	{
			"value": "FOILED DOT TULLE",
			"label": "FOILED DOT TULLE"
	},
	{
			"value": "FOIL GARLAND",
			"label": "FOIL GARLAND"
	},
	{
			"value": "FOIL IRON ON",
			"label": "FOIL IRON ON"
	},
	{
			"value": "FOIL MESH",
			"label": "FOIL MESH"
	},
	{
			"value": "FOIL ORNAMENT SET",
			"label": "FOIL ORNAMENT SET"
	},
	{
			"value": "FOIL QUILL",
			"label": "FOIL QUILL"
	},
	{
			"value": "FOIL REMV VINYL",
			"label": "FOIL REMV VINYL"
	},
	{
			"value": "FOIL WRAP",
			"label": "FOIL WRAP"
	},
	{
			"value": "FOLDING CART",
			"label": "FOLDING CART"
	},
	{
			"value": "FOLIAGE",
			"label": "FOLIAGE"
	},
	{
			"value": "FOLIAGE/PLANT LIFE",
			"label": "FOLIAGE/PLANT LIFE"
	},
	{
			"value": "FOLK",
			"label": "FOLK"
	},
	{
			"value": "FOLK WRAP",
			"label": "FOLK WRAP"
	},
	{
			"value": "FONDANT",
			"label": "FONDANT"
	},
	{
			"value": "FONDANT MOLD",
			"label": "FONDANT MOLD"
	},
	{
			"value": "FONDANT MOLDS",
			"label": "FONDANT MOLDS"
	},
	{
			"value": "FONDANT TOOL",
			"label": "FONDANT TOOL"
	},
	{
			"value": "FONDANT TOOLS",
			"label": "FONDANT TOOLS"
	},
	{
			"value": "FOOD",
			"label": "FOOD"
	},
	{
			"value": "FOOD/BEVERAGE",
			"label": "FOOD/BEVERAGE"
	},
	{
			"value": "FOOD COLORING",
			"label": "FOOD COLORING"
	},
	{
			"value": "FOOD & DRINK",
			"label": "FOOD & DRINK"
	},
	{
			"value": "FOOD/DRINK",
			"label": "FOOD/DRINK"
	},
	{
			"value": "FOOD PREP",
			"label": "FOOD PREP"
	},
	{
			"value": "FOOTBALL",
			"label": "FOOTBALL"
	},
	{
			"value": "FOOTBALL NST",
			"label": "FOOTBALL NST"
	},
	{
			"value": "FOOTED SANTA",
			"label": "FOOTED SANTA"
	},
	{
			"value": "FORD",
			"label": "FORD"
	},
	{
			"value": "FOREST",
			"label": "FOREST"
	},
	{
			"value": "FOREST WD",
			"label": "FOREST WD"
	},
	{
			"value": "FOREVER YOUNG",
			"label": "FOREVER YOUNG"
	},
	{
			"value": "FORGET ME NOT",
			"label": "FORGET ME NOT"
	},
	{
			"value": "FORMAT",
			"label": "FORMAT"
	},
	{
			"value": "FORREST GOLD",
			"label": "FORREST GOLD"
	},
	{
			"value": "FORSYHIA",
			"label": "FORSYHIA"
	},
	{
			"value": "FORSYTHIA",
			"label": "FORSYTHIA"
	},
	{
			"value": "FORTHSIA",
			"label": "FORTHSIA"
	},
	{
			"value": "FORYSTHIA",
			"label": "FORYSTHIA"
	},
	{
			"value": "FOS",
			"label": "FOS"
	},
	{
			"value": "FOS BANNER",
			"label": "FOS BANNER"
	},
	{
			"value": "FOS BASKET",
			"label": "FOS BASKET"
	},
	{
			"value": "FOS BELL GARLAND",
			"label": "FOS BELL GARLAND"
	},
	{
			"value": "FOS CANDLE",
			"label": "FOS CANDLE"
	},
	{
			"value": "FOS CAR DECOR",
			"label": "FOS CAR DECOR"
	},
	{
			"value": "FOS CLOCHE",
			"label": "FOS CLOCHE"
	},
	{
			"value": "FOS CRATE",
			"label": "FOS CRATE"
	},
	{
			"value": "FOS FELT TREES",
			"label": "FOS FELT TREES"
	},
	{
			"value": "FOS GARLAND",
			"label": "FOS GARLAND"
	},
	{
			"value": "FOS LANTERN",
			"label": "FOS LANTERN"
	},
	{
			"value": "FOS LED CANDLE",
			"label": "FOS LED CANDLE"
	},
	{
			"value": "FOS LED LANTERN",
			"label": "FOS LED LANTERN"
	},
	{
			"value": "FOS LED TREE",
			"label": "FOS LED TREE"
	},
	{
			"value": "FOS LUMBAR PILLOW",
			"label": "FOS LUMBAR PILLOW"
	},
	{
			"value": "FOS MAILBOX",
			"label": "FOS MAILBOX"
	},
	{
			"value": "FOS NUTCRACKER",
			"label": "FOS NUTCRACKER"
	},
	{
			"value": "FOS ORNAMENT",
			"label": "FOS ORNAMENT"
	},
	{
			"value": "FOS PILLAR",
			"label": "FOS PILLAR"
	},
	{
			"value": "FOS PILLOW",
			"label": "FOS PILLOW"
	},
	{
			"value": "FOS PUMPKIN",
			"label": "FOS PUMPKIN"
	},
	{
			"value": "FOS REINDEER",
			"label": "FOS REINDEER"
	},
	{
			"value": "FOS RUG",
			"label": "FOS RUG"
	},
	{
			"value": "FOSSIL FERN",
			"label": "FOSSIL FERN"
	},
	{
			"value": "FOS STOCKING HOLDER",
			"label": "FOS STOCKING HOLDER"
	},
	{
			"value": "FOS STRING LIGHTS",
			"label": "FOS STRING LIGHTS"
	},
	{
			"value": "FOS TABLE DECOR",
			"label": "FOS TABLE DECOR"
	},
	{
			"value": "FOS THROW",
			"label": "FOS THROW"
	},
	{
			"value": "FOS TRAY",
			"label": "FOS TRAY"
	},
	{
			"value": "FOS TREE",
			"label": "FOS TREE"
	},
	{
			"value": "FOS WALL",
			"label": "FOS WALL"
	},
	{
			"value": "FOS WALL DECOR",
			"label": "FOS WALL DECOR"
	},
	{
			"value": "FOS WORD BLOCK",
			"label": "FOS WORD BLOCK"
	},
	{
			"value": "FOS WORD DECOR",
			"label": "FOS WORD DECOR"
	},
	{
			"value": "FOS WREATH",
			"label": "FOS WREATH"
	},
	{
			"value": "FOTOMATR",
			"label": "FOTOMATR"
	},
	{
			"value": "FOUNDATION",
			"label": "FOUNDATION"
	},
	{
			"value": "FOUNDRY CANDLES",
			"label": "FOUNDRY CANDLES"
	},
	{
			"value": "FOUNTAIN",
			"label": "FOUNTAIN"
	},
	{
			"value": "FOUNTAIN BOWS",
			"label": "FOUNTAIN BOWS"
	},
	{
			"value": "FOX",
			"label": "FOX"
	},
	{
			"value": "FOXS",
			"label": "FOXS"
	},
	{
			"value": "FOXTAIL",
			"label": "FOXTAIL"
	},
	{
			"value": "FPC",
			"label": "FPC"
	},
	{
			"value": "FR",
			"label": "FR"
	},
	{
			"value": "FRAGRANCED JAR CANDL",
			"label": "FRAGRANCED JAR CANDL"
	},
	{
			"value": "FRAME",
			"label": "FRAME"
	},
	{
			"value": "FRAMED SEASHELLS",
			"label": "FRAMED SEASHELLS"
	},
	{
			"value": "FRAMED TT",
			"label": "FRAMED TT"
	},
	{
			"value": "FRAMES",
			"label": "FRAMES"
	},
	{
			"value": "FRAME WREATH",
			"label": "FRAME WREATH"
	},
	{
			"value": "FRANK ORN",
			"label": "FRANK ORN"
	},
	{
			"value": "FREEDOM",
			"label": "FREEDOM"
	},
	{
			"value": "FREESIA",
			"label": "FREESIA"
	},
	{
			"value": "FREE TOWEL",
			"label": "FREE TOWEL"
	},
	{
			"value": "FREEZIA",
			"label": "FREEZIA"
	},
	{
			"value": "FRENCH",
			"label": "FRENCH"
	},
	{
			"value": "FRENCH FANTASY",
			"label": "FRENCH FANTASY"
	},
	{
			"value": "FRENCH FARMHOUSE",
			"label": "FRENCH FARMHOUSE"
	},
	{
			"value": "FRENCH TERRY",
			"label": "FRENCH TERRY"
	},
	{
			"value": "FRENCH TERRY TOWEL",
			"label": "FRENCH TERRY TOWEL"
	},
	{
			"value": "FRENCH TERRY TOWELS",
			"label": "FRENCH TERRY TOWELS"
	},
	{
			"value": "FRENCH TWILL",
			"label": "FRENCH TWILL"
	},
	{
			"value": "FRENCH WIRE",
			"label": "FRENCH WIRE"
	},
	{
			"value": "FRESH MELON SLICE",
			"label": "FRESH MELON SLICE"
	},
	{
			"value": "FRIENDLY STIFF 12X18",
			"label": "FRIENDLY STIFF 12X18"
	},
	{
			"value": "FRIENDLY STIFF 9X12",
			"label": "FRIENDLY STIFF 9X12"
	},
	{
			"value": "FRIENDS",
			"label": "FRIENDS"
	},
	{
			"value": "FRIENDS AND FAMILY",
			"label": "FRIENDS AND FAMILY"
	},
	{
			"value": "FRIENDSHIP",
			"label": "FRIENDSHIP"
	},
	{
			"value": "FRINGE",
			"label": "FRINGE"
	},
	{
			"value": "FRINGE BANNER",
			"label": "FRINGE BANNER"
	},
	{
			"value": "FRINGE BASKET",
			"label": "FRINGE BASKET"
	},
	{
			"value": "FRINGE BEADED",
			"label": "FRINGE BEADED"
	},
	{
			"value": "FRINGE MIRROR",
			"label": "FRINGE MIRROR"
	},
	{
			"value": "FRINGE PILLOW",
			"label": "FRINGE PILLOW"
	},
	{
			"value": "FRINGE VASE",
			"label": "FRINGE VASE"
	},
	{
			"value": "FRINGE WHT",
			"label": "FRINGE WHT"
	},
	{
			"value": "FRI STIFF GLTR 9X12",
			"label": "FRI STIFF GLTR 9X12"
	},
	{
			"value": "FRI STIFF STKY 9X12",
			"label": "FRI STIFF STKY 9X12"
	},
	{
			"value": "FRITILLARIA",
			"label": "FRITILLARIA"
	},
	{
			"value": "FROG",
			"label": "FROG"
	},
	{
			"value": "FROGS",
			"label": "FROGS"
	},
	{
			"value": "FRONT END BASIC CAND",
			"label": "FRONT END BASIC CAND"
	},
	{
			"value": "FRONT END CANDY",
			"label": "FRONT END CANDY"
	},
	{
			"value": "FROSTED",
			"label": "FROSTED"
	},
	{
			"value": "FROSTED GARDEN",
			"label": "FROSTED GARDEN"
	},
	{
			"value": "FROSTED PERM VINYL",
			"label": "FROSTED PERM VINYL"
	},
	{
			"value": "FROSTED TREE",
			"label": "FROSTED TREE"
	},
	{
			"value": "FROSTED VINYL",
			"label": "FROSTED VINYL"
	},
	{
			"value": "FROSTY",
			"label": "FROSTY"
	},
	{
			"value": "FROZEN",
			"label": "FROZEN"
	},
	{
			"value": "FROZEN FASHION",
			"label": "FROZEN FASHION"
	},
	{
			"value": "FRUIT",
			"label": "FRUIT"
	},
	{
			"value": "FRUIT AND BERRY",
			"label": "FRUIT AND BERRY"
	},
	{
			"value": "FRUIT BERRIES PINE",
			"label": "FRUIT BERRIES PINE"
	},
	{
			"value": "FRUIT/BERRY",
			"label": "FRUIT/BERRY"
	},
	{
			"value": "FRUIT CHEWY",
			"label": "FRUIT CHEWY"
	},
	{
			"value": "FRUIT INFUSER",
			"label": "FRUIT INFUSER"
	},
	{
			"value": "FRUIT PUNCH",
			"label": "FRUIT PUNCH"
	},
	{
			"value": "FRUITS",
			"label": "FRUITS"
	},
	{
			"value": "FRUIT SWAG",
			"label": "FRUIT SWAG"
	},
	{
			"value": "FSHFL",
			"label": "FSHFL"
	},
	{
			"value": "FSHFLA00",
			"label": "FSHFLA00"
	},
	{
			"value": "FSHFLB00",
			"label": "FSHFLB00"
	},
	{
			"value": "FSHFLD00",
			"label": "FSHFLD00"
	},
	{
			"value": "FSHFLG00",
			"label": "FSHFLG00"
	},
	{
			"value": "FTHR BIRD",
			"label": "FTHR BIRD"
	},
	{
			"value": "FTHR BUTTERFLY",
			"label": "FTHR BUTTERFLY"
	},
	{
			"value": "FTHR STM",
			"label": "FTHR STM"
	},
	{
			"value": "FTHR WRTH",
			"label": "FTHR WRTH"
	},
	{
			"value": "FUEL",
			"label": "FUEL"
	},
	{
			"value": "FUKURO",
			"label": "FUKURO"
	},
	{
			"value": "FULL BASKET",
			"label": "FULL BASKET"
	},
	{
			"value": "FUN",
			"label": "FUN"
	},
	{
			"value": "FUNCATIONAL TT",
			"label": "FUNCATIONAL TT"
	},
	{
			"value": "FUN CHARACTERS WRAP",
			"label": "FUN CHARACTERS WRAP"
	},
	{
			"value": "FUNCTIONAL",
			"label": "FUNCTIONAL"
	},
	{
			"value": "FUNCTIONAL TT",
			"label": "FUNCTIONAL TT"
	},
	{
			"value": "FUNCTIONAL WALL",
			"label": "FUNCTIONAL WALL"
	},
	{
			"value": "FUNCTIONAL WALL DECO",
			"label": "FUNCTIONAL WALL DECO"
	},
	{
			"value": "FUNCTIONAL WD",
			"label": "FUNCTIONAL WD"
	},
	{
			"value": "FUN FLORAL",
			"label": "FUN FLORAL"
	},
	{
			"value": "FUN FOOD",
			"label": "FUN FOOD"
	},
	{
			"value": "FUN HOUSE",
			"label": "FUN HOUSE"
	},
	{
			"value": "FUNKIN",
			"label": "FUNKIN"
	},
	{
			"value": "FUNKIN ACCESSORY",
			"label": "FUNKIN ACCESSORY"
	},
	{
			"value": "FUNKO",
			"label": "FUNKO"
	},
	{
			"value": "FUNKY FALL",
			"label": "FUNKY FALL"
	},
	{
			"value": "FUNKY HEARTS",
			"label": "FUNKY HEARTS"
	},
	{
			"value": "FUNNY FACE STICKER B",
			"label": "FUNNY FACE STICKER B"
	},
	{
			"value": "FUNTASTIC Q",
			"label": "FUNTASTIC Q"
	},
	{
			"value": "FUN W COLORING WRAP",
			"label": "FUN W COLORING WRAP"
	},
	{
			"value": "FUR",
			"label": "FUR"
	},
	{
			"value": "FUR BABY",
			"label": "FUR BABY"
	},
	{
			"value": "FURNITURE",
			"label": "FURNITURE"
	},
	{
			"value": "FUR PDQ",
			"label": "FUR PDQ"
	},
	{
			"value": "FURRIES",
			"label": "FURRIES"
	},
	{
			"value": "FUR ROPE",
			"label": "FUR ROPE"
	},
	{
			"value": "FURRY",
			"label": "FURRY"
	},
	{
			"value": "FURRY ANIMAL",
			"label": "FURRY ANIMAL"
	},
	{
			"value": "FURRY ANIMALS",
			"label": "FURRY ANIMALS"
	},
	{
			"value": "FURRY ORNAMENT",
			"label": "FURRY ORNAMENT"
	},
	{
			"value": "FUR WITH LIP",
			"label": "FUR WITH LIP"
	},
	{
			"value": "FUR WITH NO LIP",
			"label": "FUR WITH NO LIP"
	},
	{
			"value": "FUSCIA",
			"label": "FUSCIA"
	},
	{
			"value": "FUSE WEBS & ADHESIVE",
			"label": "FUSE WEBS & ADHESIVE"
	},
	{
			"value": "FUSIBLE",
			"label": "FUSIBLE"
	},
	{
			"value": "FUSIBLE WEB",
			"label": "FUSIBLE WEB"
	},
	{
			"value": "FUSIBLE WEB & ADHESI",
			"label": "FUSIBLE WEB & ADHESI"
	},
	{
			"value": "FUSIBLE WEBS & ADHES",
			"label": "FUSIBLE WEBS & ADHES"
	},
	{
			"value": "FUZE IT KIT",
			"label": "FUZE IT KIT"
	},
	{
			"value": "FUZZOODLES",
			"label": "FUZZOODLES"
	},
	{
			"value": "FW10-26 WYLA APP",
			"label": "FW10-26 WYLA APP"
	},
	{
			"value": "FW14 ARMOATHERAPY",
			"label": "FW14 ARMOATHERAPY"
	},
	{
			"value": "FW22 BASKETS",
			"label": "FW22 BASKETS"
	},
	{
			"value": "FW22 CANDY",
			"label": "FW22 CANDY"
	},
	{
			"value": "FW25 RTV",
			"label": "FW25 RTV"
	},
	{
			"value": "FW27 CHEER BOW BLING",
			"label": "FW27 CHEER BOW BLING"
	},
	{
			"value": "FW27 CHEER BOWS",
			"label": "FW27 CHEER BOWS"
	},
	{
			"value": "FW27 HALCR BANDANNAS",
			"label": "FW27 HALCR BANDANNAS"
	},
	{
			"value": "FW27- HALLOWEEN CT",
			"label": "FW27- HALLOWEEN CT"
	},
	{
			"value": "FW27 HALLOWN APP IOS",
			"label": "FW27 HALLOWN APP IOS"
	},
	{
			"value": " FW 31, FW37",
			"label": " FW 31, FW37"
	},
	{
			"value": "FW31 HALLOWN APP IOS",
			"label": "FW31 HALLOWN APP IOS"
	},
	{
			"value": "FW31 SUPRHRO ACC",
			"label": "FW31 SUPRHRO ACC"
	},
	{
			"value": "FW34",
			"label": "FW34"
	},
	{
			"value": "FW34-13 DISNEY APP",
			"label": "FW34-13 DISNEY APP"
	},
	{
			"value": "FW34-13 SUPRHRO APP",
			"label": "FW34-13 SUPRHRO APP"
	},
	{
			"value": "FW35 KNITS OPP BUY",
			"label": "FW35 KNITS OPP BUY"
	},
	{
			"value": "FW36-13 COUSIN IOS",
			"label": "FW36-13 COUSIN IOS"
	},
	{
			"value": "FW36-13 SNARKY APP",
			"label": "FW36-13 SNARKY APP"
	},
	{
			"value": "FW37",
			"label": "FW37"
	},
	{
			"value": "FW39",
			"label": "FW39"
	},
	{
			"value": "FW40-14 DENIM APP",
			"label": "FW40-14 DENIM APP"
	},
	{
			"value": "FW40-14 PATCHES",
			"label": "FW40-14 PATCHES"
	},
	{
			"value": "FW40 CANDY EXT",
			"label": "FW40 CANDY EXT"
	},
	{
			"value": "FW40 HLDY PLSTC STRG",
			"label": "FW40 HLDY PLSTC STRG"
	},
	{
			"value": "FW40 HOLIDAY APP",
			"label": "FW40 HOLIDAY APP"
	},
	{
			"value": "FW40 NEW CANDY",
			"label": "FW40 NEW CANDY"
	},
	{
			"value": "FW 47-2",
			"label": "FW 47-2"
	},
	{
			"value": "FW 48-7",
			"label": "FW 48-7"
	},
	{
			"value": "FW48-7",
			"label": "FW48-7"
	},
	{
			"value": "FW5 CRICUT EC/PP",
			"label": "FW5 CRICUT EC/PP"
	},
	{
			"value": "FW5 CRICUT POG",
			"label": "FW5 CRICUT POG"
	},
	{
			"value": "FW6-26 MONOGRM",
			"label": "FW6-26 MONOGRM"
	},
	{
			"value": "FW6-30 MOM IOS",
			"label": "FW6-30 MOM IOS"
	},
	{
			"value": "FW6-30 TODDLER IOS",
			"label": "FW6-30 TODDLER IOS"
	},
	{
			"value": "FW7 KIDS CAMP",
			"label": "FW7 KIDS CAMP"
	},
	{
			"value": "F&W PUBLICATIONS",
			"label": "F&W PUBLICATIONS"
	},
	{
			"value": "FY10 BRIDAL POG",
			"label": "FY10 BRIDAL POG"
	},
	{
			"value": "FY10 BSKT ACCSSRS",
			"label": "FY10 BSKT ACCSSRS"
	},
	{
			"value": "FY10 Q2 BASKETS",
			"label": "FY10 Q2 BASKETS"
	},
	{
			"value": "FY10 Q3 ASOTV",
			"label": "FY10 Q3 ASOTV"
	},
	{
			"value": "FY11 BRIDAL POG",
			"label": "FY11 BRIDAL POG"
	},
	{
			"value": "FY11 BSKT ACCSSRS",
			"label": "FY11 BSKT ACCSSRS"
	},
	{
			"value": "FY11 Q2 ASOTB",
			"label": "FY11 Q2 ASOTB"
	},
	{
			"value": "FY11 Q2 BASKETS",
			"label": "FY11 Q2 BASKETS"
	},
	{
			"value": "FY11 Q3 COCA COLA",
			"label": "FY11 Q3 COCA COLA"
	},
	{
			"value": "FY12 BSKT ACCSSRS",
			"label": "FY12 BSKT ACCSSRS"
	},
	{
			"value": "FY13 -100SKUS",
			"label": "FY13 -100SKUS"
	},
	{
			"value": "FY13-100SKUS",
			"label": "FY13-100SKUS"
	},
	{
			"value": "FY13-FLEECE 100 ARTI",
			"label": "FY13-FLEECE 100 ARTI"
	},
	{
			"value": "FY13 GIFTABLE",
			"label": "FY13 GIFTABLE"
	},
	{
			"value": "FY14 FAMOUS MAKER",
			"label": "FY14 FAMOUS MAKER"
	},
	{
			"value": "FY14 PERFORMANCE SPE",
			"label": "FY14 PERFORMANCE SPE"
	},
	{
			"value": "FY14 Q2 BASKETS",
			"label": "FY14 Q2 BASKETS"
	},
	{
			"value": "FY14 Q3 ASOTV",
			"label": "FY14 Q3 ASOTV"
	},
	{
			"value": "FY14 Q3 BASKETS",
			"label": "FY14 Q3 BASKETS"
	},
	{
			"value": "FY14 Q3 FASH CANDY",
			"label": "FY14 Q3 FASH CANDY"
	},
	{
			"value": "FY15 COCA COLA",
			"label": "FY15 COCA COLA"
	},
	{
			"value": "FY15 GIFTABLE",
			"label": "FY15 GIFTABLE"
	},
	{
			"value": "FY15 SNUGGLE FLANNEL",
			"label": "FY15 SNUGGLE FLANNEL"
	},
	{
			"value": "FY16 DEC OPP BUY",
			"label": "FY16 DEC OPP BUY"
	},
	{
			"value": "FY16 FASHION BSKT",
			"label": "FY16 FASHION BSKT"
	},
	{
			"value": "FY16 Q2 BASKETS",
			"label": "FY16 Q2 BASKETS"
	},
	{
			"value": "FY17 FASHION BSKT",
			"label": "FY17 FASHION BSKT"
	},
	{
			"value": "FY17 MOTHER'S DAY",
			"label": "FY17 MOTHER'S DAY"
	},
	{
			"value": "FY17 Q2 ASOTV",
			"label": "FY17 Q2 ASOTV"
	},
	{
			"value": "FY17 Q3 ASOTV",
			"label": "FY17 Q3 ASOTV"
	},
	{
			"value": "FY17 Q3 FASH CANDY",
			"label": "FY17 Q3 FASH CANDY"
	},
	{
			"value": "FY17 Q4 ASOTV",
			"label": "FY17 Q4 ASOTV"
	},
	{
			"value": "FY17 Q4 FASH CANDY",
			"label": "FY17 Q4 FASH CANDY"
	},
	{
			"value": "FY18 BSKT ACCSSRS",
			"label": "FY18 BSKT ACCSSRS"
	},
	{
			"value": "FY18 FW14 FASH BSKT",
			"label": "FY18 FW14 FASH BSKT"
	},
	{
			"value": "FY18 Q1 ASOTV",
			"label": "FY18 Q1 ASOTV"
	},
	{
			"value": "FY18 Q2 BASKETS",
			"label": "FY18 Q2 BASKETS"
	},
	{
			"value": "FY21 FW36",
			"label": "FY21 FW36"
	},
	{
			"value": "FY21 Q2 PWP",
			"label": "FY21 Q2 PWP"
	},
	{
			"value": "FY23 Q2 WELLNESS",
			"label": "FY23 Q2 WELLNESS"
	},
	{
			"value": "FY23 Q4 BEAUTY",
			"label": "FY23 Q4 BEAUTY"
	},
	{
			"value": "FY23 Q4 WELLNESS",
			"label": "FY23 Q4 WELLNESS"
	},
	{
			"value": "FY24 HAL - HEADER",
			"label": "FY24 HAL - HEADER"
	},
	{
			"value": "FY24 PRIDE HEADER",
			"label": "FY24 PRIDE HEADER"
	},
	{
			"value": "FY24Q2 RIT PDQ",
			"label": "FY24Q2 RIT PDQ"
	},
	{
			"value": "FY4 BSKT ACCSSRS",
			"label": "FY4 BSKT ACCSSRS"
	},
	{
			"value": "FY6 BSKT ACCSSRS",
			"label": "FY6 BSKT ACCSSRS"
	},
	{
			"value": "FY8 Q1 COCA COLA",
			"label": "FY8 Q1 COCA COLA"
	},
	{
			"value": "FY8 Q3 COCA COLA",
			"label": "FY8 Q3 COCA COLA"
	},
	{
			"value": "FY8 Q4 BASKETS",
			"label": "FY8 Q4 BASKETS"
	},
	{
			"value": "FY9 BSKT ACCSSRS",
			"label": "FY9 BSKT ACCSSRS"
	},
	{
			"value": "FY9 Q4 BASKETS",
			"label": "FY9 Q4 BASKETS"
	},
	{
			"value": "FZPPR",
			"label": "FZPPR"
	},
	{
			"value": "FZPPRA00",
			"label": "FZPPRA00"
	},
	{
			"value": "GADGETS",
			"label": "GADGETS"
	},
	{
			"value": "GALAXY",
			"label": "GALAXY"
	},
	{
			"value": "GALLERY",
			"label": "GALLERY"
	},
	{
			"value": "GALLOON",
			"label": "GALLOON"
	},
	{
			"value": "GALVANIZED",
			"label": "GALVANIZED"
	},
	{
			"value": "GALVANIZED WORD BLOC",
			"label": "GALVANIZED WORD BLOC"
	},
	{
			"value": "GAMBLING",
			"label": "GAMBLING"
	},
	{
			"value": "GAMBLING & GAMING",
			"label": "GAMBLING & GAMING"
	},
	{
			"value": "GAME",
			"label": "GAME"
	},
	{
			"value": "GAME OF THRONES",
			"label": "GAME OF THRONES"
	},
	{
			"value": "GAMES",
			"label": "GAMES"
	},
	{
			"value": "GAMING",
			"label": "GAMING"
	},
	{
			"value": "GANTZ LETTERS",
			"label": "GANTZ LETTERS"
	},
	{
			"value": "GANTZ NAMES",
			"label": "GANTZ NAMES"
	},
	{
			"value": "GANZ OSO",
			"label": "GANZ OSO"
	},
	{
			"value": "GARBO",
			"label": "GARBO"
	},
	{
			"value": "GARDEN",
			"label": "GARDEN"
	},
	{
			"value": "GARDEN APRON",
			"label": "GARDEN APRON"
	},
	{
			"value": "GARDEN BIRDHOUSE",
			"label": "GARDEN BIRDHOUSE"
	},
	{
			"value": "GARDEN DECOR",
			"label": "GARDEN DECOR"
	},
	{
			"value": "GARDEN EQUIP & DÉCOR",
			"label": "GARDEN EQUIP & DÉCOR"
	},
	{
			"value": "GARDEN GLOVES",
			"label": "GARDEN GLOVES"
	},
	{
			"value": "GARDEN HAT",
			"label": "GARDEN HAT"
	},
	{
			"value": "GARDENIA",
			"label": "GARDENIA"
	},
	{
			"value": "GARDENING",
			"label": "GARDENING"
	},
	{
			"value": "GARDENING ACCESSORIE",
			"label": "GARDENING ACCESSORIE"
	},
	{
			"value": "GARDENING APRON",
			"label": "GARDENING APRON"
	},
	{
			"value": "GARDENING GLOVES",
			"label": "GARDENING GLOVES"
	},
	{
			"value": "GARDENING HAT",
			"label": "GARDENING HAT"
	},
	{
			"value": "GARDENING SHOES",
			"label": "GARDENING SHOES"
	},
	{
			"value": "GARDENING TOOLS",
			"label": "GARDENING TOOLS"
	},
	{
			"value": "GARDEN PARTY",
			"label": "GARDEN PARTY"
	},
	{
			"value": "GARDEN PATCH",
			"label": "GARDEN PATCH"
	},
	{
			"value": "GARDEN PLAY",
			"label": "GARDEN PLAY"
	},
	{
			"value": "GARDEN POWER PANEL",
			"label": "GARDEN POWER PANEL"
	},
	{
			"value": "GARDEN STAKE",
			"label": "GARDEN STAKE"
	},
	{
			"value": "GARDEN STONE",
			"label": "GARDEN STONE"
	},
	{
			"value": "GARDEN STONES",
			"label": "GARDEN STONES"
	},
	{
			"value": "GARDEN TOOLS",
			"label": "GARDEN TOOLS"
	},
	{
			"value": "GARDEN TOTE",
			"label": "GARDEN TOTE"
	},
	{
			"value": "GARDEN UTILITY",
			"label": "GARDEN UTILITY"
	},
	{
			"value": "GARLAND",
			"label": "GARLAND"
	},
	{
			"value": "GARLAND BANNER",
			"label": "GARLAND BANNER"
	},
	{
			"value": "GARLAND POM",
			"label": "GARLAND POM"
	},
	{
			"value": "GARLANDS",
			"label": "GARLANDS"
	},
	{
			"value": "GARLANDS FELT",
			"label": "GARLANDS FELT"
	},
	{
			"value": "GATE",
			"label": "GATE"
	},
	{
			"value": "GAUZE",
			"label": "GAUZE"
	},
	{
			"value": "GAZEBO",
			"label": "GAZEBO"
	},
	{
			"value": "GAZING BALL",
			"label": "GAZING BALL"
	},
	{
			"value": "GAZING BALL STAND",
			"label": "GAZING BALL STAND"
	},
	{
			"value": "GB ACCESSORY",
			"label": "GB ACCESSORY"
	},
	{
			"value": "GB PACKERS",
			"label": "GB PACKERS"
	},
	{
			"value": "GCD STUDIOS",
			"label": "GCD STUDIOS"
	},
	{
			"value": "GEL",
			"label": "GEL"
	},
	{
			"value": "GEL BURNER",
			"label": "GEL BURNER"
	},
	{
			"value": "GEL CLING",
			"label": "GEL CLING"
	},
	{
			"value": "GEL/CLING",
			"label": "GEL/CLING"
	},
	{
			"value": "GEL CLING LARGE",
			"label": "GEL CLING LARGE"
	},
	{
			"value": "GEL CLINGS",
			"label": "GEL CLINGS"
	},
	{
			"value": "GELLY ROLL",
			"label": "GELLY ROLL"
	},
	{
			"value": "GEL TUBE",
			"label": "GEL TUBE"
	},
	{
			"value": "GEM",
			"label": "GEM"
	},
	{
			"value": "GEMCRAFT",
			"label": "GEMCRAFT"
	},
	{
			"value": "GEM HLWWOOD",
			"label": "GEM HLWWOOD"
	},
	{
			"value": "GEMS",
			"label": "GEMS"
	},
	{
			"value": "GEM STICKER",
			"label": "GEM STICKER"
	},
	{
			"value": "GEM TUB",
			"label": "GEM TUB"
	},
	{
			"value": "GENERAL",
			"label": "GENERAL"
	},
	{
			"value": "GENERAL CRAFT",
			"label": "GENERAL CRAFT"
	},
	{
			"value": "GENERAL HOME DÉCOR",
			"label": "GENERAL HOME DÉCOR"
	},
	{
			"value": "GENERAL NEEDLE CRAFT",
			"label": "GENERAL NEEDLE CRAFT"
	},
	{
			"value": "GENERAL PENCIL",
			"label": "GENERAL PENCIL"
	},
	{
			"value": "GENERAL TEAM",
			"label": "GENERAL TEAM"
	},
	{
			"value": "GENERATIONS",
			"label": "GENERATIONS"
	},
	{
			"value": "GENEVA",
			"label": "GENEVA"
	},
	{
			"value": "GENEVIEVE GORDER",
			"label": "GENEVIEVE GORDER"
	},
	{
			"value": "GENUINE LEATHER",
			"label": "GENUINE LEATHER"
	},
	{
			"value": "GEO",
			"label": "GEO"
	},
	{
			"value": " GEO, DOTS, STRIPES",
			"label": " GEO, DOTS, STRIPES"
	},
	{
			"value": "GEO, DOTS, STRIPES",
			"label": "GEO, DOTS, STRIPES"
	},
	{
			"value": "GEO/DOT/STRIPE",
			"label": "GEO/DOT/STRIPE"
	},
	{
			"value": "GEOMETIRC",
			"label": "GEOMETIRC"
	},
	{
			"value": "GEOMETRIC",
			"label": "GEOMETRIC"
	},
	{
			"value": "GEOMETRIC/DOTS",
			"label": "GEOMETRIC/DOTS"
	},
	{
			"value": "GEOMETRIC PATTERN",
			"label": "GEOMETRIC PATTERN"
	},
	{
			"value": "GEOMETRIC PLANTER",
			"label": "GEOMETRIC PLANTER"
	},
	{
			"value": "GEOMETRIC PRINTS",
			"label": "GEOMETRIC PRINTS"
	},
	{
			"value": "GEOMETRICS",
			"label": "GEOMETRICS"
	},
	{
			"value": "GEOMETRIC STRIPES",
			"label": "GEOMETRIC STRIPES"
	},
	{
			"value": "GEO/PLAID",
			"label": "GEO/PLAID"
	},
	{
			"value": "GEORGETTE",
			"label": "GEORGETTE"
	},
	{
			"value": "GEORGIA",
			"label": "GEORGIA"
	},
	{
			"value": "GEO SUCCULENT",
			"label": "GEO SUCCULENT"
	},
	{
			"value": "GEO TOWEL",
			"label": "GEO TOWEL"
	},
	{
			"value": "GERANIUM",
			"label": "GERANIUM"
	},
	{
			"value": "GERBER",
			"label": "GERBER"
	},
	{
			"value": "GERBERA",
			"label": "GERBERA"
	},
	{
			"value": "GERBERA DAISY",
			"label": "GERBERA DAISY"
	},
	{
			"value": "GERBER DAISY",
			"label": "GERBER DAISY"
	},
	{
			"value": "GERMANIVY",
			"label": "GERMANIVY"
	},
	{
			"value": "GERTIE",
			"label": "GERTIE"
	},
	{
			"value": "GERTIE COLLECTION",
			"label": "GERTIE COLLECTION"
	},
	{
			"value": "GESSO",
			"label": "GESSO"
	},
	{
			"value": "GHOST",
			"label": "GHOST"
	},
	{
			"value": "GHOST ORN",
			"label": "GHOST ORN"
	},
	{
			"value": "GHOSTS",
			"label": "GHOSTS"
	},
	{
			"value": "GIANT BAG",
			"label": "GIANT BAG"
	},
	{
			"value": "GIANT CHECKERS",
			"label": "GIANT CHECKERS"
	},
	{
			"value": "GIANT DOMINOES",
			"label": "GIANT DOMINOES"
	},
	{
			"value": "GIANT FLOOR PUZZLE",
			"label": "GIANT FLOOR PUZZLE"
	},
	{
			"value": "GIDDY UP",
			"label": "GIDDY UP"
	},
	{
			"value": "GIFT",
			"label": "GIFT"
	},
	{
			"value": "GIFTABLE",
			"label": "GIFTABLE"
	},
	{
			"value": "GIFT BAG",
			"label": "GIFT BAG"
	},
	{
			"value": "GIFTBAG",
			"label": "GIFTBAG"
	},
	{
			"value": "GIFT BOW ASST",
			"label": "GIFT BOW ASST"
	},
	{
			"value": "GIFT BOX",
			"label": "GIFT BOX"
	},
	{
			"value": "GIFT BOX AND BALL",
			"label": "GIFT BOX AND BALL"
	},
	{
			"value": "GIFT BOXES",
			"label": "GIFT BOXES"
	},
	{
			"value": "GIFT CARD HOLDER",
			"label": "GIFT CARD HOLDER"
	},
	{
			"value": "GIFT CARD TIN",
			"label": "GIFT CARD TIN"
	},
	{
			"value": "GIFT CARD TINS",
			"label": "GIFT CARD TINS"
	},
	{
			"value": "GIFT CORD",
			"label": "GIFT CORD"
	},
	{
			"value": "GIFTS",
			"label": "GIFTS"
	},
	{
			"value": "GIFT SET",
			"label": "GIFT SET"
	},
	{
			"value": "GIFT TAG",
			"label": "GIFT TAG"
	},
	{
			"value": "GIFT TAGS",
			"label": "GIFT TAGS"
	},
	{
			"value": "GIFT WRAP",
			"label": "GIFT WRAP"
	},
	{
			"value": "GIFTWRAP",
			"label": "GIFTWRAP"
	},
	{
			"value": "GILDED AGE",
			"label": "GILDED AGE"
	},
	{
			"value": "GIMP",
			"label": "GIMP"
	},
	{
			"value": "GINGER",
			"label": "GINGER"
	},
	{
			"value": "GINGERBREAD",
			"label": "GINGERBREAD"
	},
	{
			"value": "GINGERBREAD ICING",
			"label": "GINGERBREAD ICING"
	},
	{
			"value": "GINGERBREAD KIT",
			"label": "GINGERBREAD KIT"
	},
	{
			"value": "GINGERBREAD MEN",
			"label": "GINGERBREAD MEN"
	},
	{
			"value": "GINGHAM",
			"label": "GINGHAM"
	},
	{
			"value": "GINGHER",
			"label": "GINGHER"
	},
	{
			"value": "GINGKO",
			"label": "GINGKO"
	},
	{
			"value": "GINKGO",
			"label": "GINKGO"
	},
	{
			"value": "GINKGO LVS",
			"label": "GINKGO LVS"
	},
	{
			"value": "GINKO",
			"label": "GINKO"
	},
	{
			"value": "GINKO LEAVES",
			"label": "GINKO LEAVES"
	},
	{
			"value": "GIRAFFE",
			"label": "GIRAFFE"
	},
	{
			"value": "GIRL",
			"label": "GIRL"
	},
	{
			"value": "GIRL/BOY/SPORT",
			"label": "GIRL/BOY/SPORT"
	},
	{
			"value": "GIRL/BOY/SPORTS",
			"label": "GIRL/BOY/SPORTS"
	},
	{
			"value": "GIRL MISC",
			"label": "GIRL MISC"
	},
	{
			"value": "GIRL POWER",
			"label": "GIRL POWER"
	},
	{
			"value": "GIRL POWER PATCHES",
			"label": "GIRL POWER PATCHES"
	},
	{
			"value": "GIRLS",
			"label": "GIRLS"
	},
	{
			"value": "GIRL SCOUTS",
			"label": "GIRL SCOUTS"
	},
	{
			"value": "GIRL SILHOUETTE",
			"label": "GIRL SILHOUETTE"
	},
	{
			"value": "GIRLY HEDGEHOGS",
			"label": "GIRLY HEDGEHOGS"
	},
	{
			"value": "G&K CRAFT",
			"label": "G&K CRAFT"
	},
	{
			"value": "GLADIOLUS",
			"label": "GLADIOLUS"
	},
	{
			"value": "GLAD TIDINGS",
			"label": "GLAD TIDINGS"
	},
	{
			"value": "GLAM ART",
			"label": "GLAM ART"
	},
	{
			"value": "GLAM HOME",
			"label": "GLAM HOME"
	},
	{
			"value": "GLAMOUR GEMS",
			"label": "GLAMOUR GEMS"
	},
	{
			"value": "GLAMOURWEEN",
			"label": "GLAMOURWEEN"
	},
	{
			"value": "GLASS",
			"label": "GLASS"
	},
	{
			"value": "GLASS BEAD COMBO",
			"label": "GLASS BEAD COMBO"
	},
	{
			"value": "GLASS BEADS",
			"label": "GLASS BEADS"
	},
	{
			"value": "GLASS BOTTLE",
			"label": "GLASS BOTTLE"
	},
	{
			"value": "GLASS BOXED",
			"label": "GLASS BOXED"
	},
	{
			"value": "GLASS BOXED ORNAMENT",
			"label": "GLASS BOXED ORNAMENT"
	},
	{
			"value": "GLASS BULBS",
			"label": "GLASS BULBS"
	},
	{
			"value": "GLASS CANDLE",
			"label": "GLASS CANDLE"
	},
	{
			"value": "GLASS CONTAINER",
			"label": "GLASS CONTAINER"
	},
	{
			"value": "GLASSES",
			"label": "GLASSES"
	},
	{
			"value": "GLASSES CASE",
			"label": "GLASSES CASE"
	},
	{
			"value": "GLASS FEEDER",
			"label": "GLASS FEEDER"
	},
	{
			"value": "GLASS FILLABLE ORNAM",
			"label": "GLASS FILLABLE ORNAM"
	},
	{
			"value": "GLASS GLOBE",
			"label": "GLASS GLOBE"
	},
	{
			"value": "GLASS HURRICANE",
			"label": "GLASS HURRICANE"
	},
	{
			"value": "GLASS LANTERN",
			"label": "GLASS LANTERN"
	},
	{
			"value": "GLASS LED HURRICANE",
			"label": "GLASS LED HURRICANE"
	},
	{
			"value": "GLASS PILLAR HOLDER",
			"label": "GLASS PILLAR HOLDER"
	},
	{
			"value": "GLASS POG",
			"label": "GLASS POG"
	},
	{
			"value": "GLASS PUMPKIN",
			"label": "GLASS PUMPKIN"
	},
	{
			"value": "GLASS TAPER HOLDER",
			"label": "GLASS TAPER HOLDER"
	},
	{
			"value": "GLASS TREE",
			"label": "GLASS TREE"
	},
	{
			"value": "GLASS VASE",
			"label": "GLASS VASE"
	},
	{
			"value": "GLASS VINE PNK FLNL",
			"label": "GLASS VINE PNK FLNL"
	},
	{
			"value": "GLASS WALL DECOR",
			"label": "GLASS WALL DECOR"
	},
	{
			"value": "GLASS WALL DÉCOR",
			"label": "GLASS WALL DÉCOR"
	},
	{
			"value": "GLASSWARE",
			"label": "GLASSWARE"
	},
	{
			"value": "GLASS WD",
			"label": "GLASS WD"
	},
	{
			"value": "GLAZE",
			"label": "GLAZE"
	},
	{
			"value": "GLEN CHECK",
			"label": "GLEN CHECK"
	},
	{
			"value": "GLEN CHECK PLAID",
			"label": "GLEN CHECK PLAID"
	},
	{
			"value": "GLIMMER TULLE",
			"label": "GLIMMER TULLE"
	},
	{
			"value": "GLISTENING PINES",
			"label": "GLISTENING PINES"
	},
	{
			"value": "GLITTER",
			"label": "GLITTER"
	},
	{
			"value": "GLITTER 9X12",
			"label": "GLITTER 9X12"
	},
	{
			"value": "GLITTER BOW",
			"label": "GLITTER BOW"
	},
	{
			"value": "GLITTERBUG",
			"label": "GLITTERBUG"
	},
	{
			"value": "GLITTERBUG TRIM",
			"label": "GLITTERBUG TRIM"
	},
	{
			"value": "GLITTER BULB",
			"label": "GLITTER BULB"
	},
	{
			"value": "GLITTER-CHUNKY",
			"label": "GLITTER-CHUNKY"
	},
	{
			"value": "GLITTER CIRCLES TULL",
			"label": "GLITTER CIRCLES TULL"
	},
	{
			"value": "GLITTER DOTS",
			"label": "GLITTER DOTS"
	},
	{
			"value": "GLITTER DOT TULLE",
			"label": "GLITTER DOT TULLE"
	},
	{
			"value": "GLITTER FLAKES",
			"label": "GLITTER FLAKES"
	},
	{
			"value": "GLITTER FOAM 9X12",
			"label": "GLITTER FOAM 9X12"
	},
	{
			"value": "GLITTER GG",
			"label": "GLITTER GG"
	},
	{
			"value": "GLITTER GLOW OSO",
			"label": "GLITTER GLOW OSO"
	},
	{
			"value": "GLITTER GLUE",
			"label": "GLITTER GLUE"
	},
	{
			"value": "GLITTER GLUE PEN",
			"label": "GLITTER GLUE PEN"
	},
	{
			"value": "GLITTER GLUE STORAGE",
			"label": "GLITTER GLUE STORAGE"
	},
	{
			"value": "GLITTER HTV",
			"label": "GLITTER HTV"
	},
	{
			"value": "GLITTER INITIAL ORNA",
			"label": "GLITTER INITIAL ORNA"
	},
	{
			"value": "GLITTER IRON ON",
			"label": "GLITTER IRON ON"
	},
	{
			"value": "GLITTER KIT",
			"label": "GLITTER KIT"
	},
	{
			"value": "GLITTER KNIT",
			"label": "GLITTER KNIT"
	},
	{
			"value": "GLITTER LEAF",
			"label": "GLITTER LEAF"
	},
	{
			"value": "GLITTER MESH",
			"label": "GLITTER MESH"
	},
	{
			"value": "GLITTER PACK",
			"label": "GLITTER PACK"
	},
	{
			"value": "GLITTER PERM VINYL",
			"label": "GLITTER PERM VINYL"
	},
	{
			"value": "GLITTER PSV",
			"label": "GLITTER PSV"
	},
	{
			"value": "GLITTER-SET",
			"label": "GLITTER-SET"
	},
	{
			"value": "GLITTER SHAKER",
			"label": "GLITTER SHAKER"
	},
	{
			"value": "GLITTER SHAKERS",
			"label": "GLITTER SHAKERS"
	},
	{
			"value": "GLITTER SOLID",
			"label": "GLITTER SOLID"
	},
	{
			"value": "GLITTER SPRAY",
			"label": "GLITTER SPRAY"
	},
	{
			"value": "GLITTER STICKERS",
			"label": "GLITTER STICKERS"
	},
	{
			"value": "GLITTER SUPPLY",
			"label": "GLITTER SUPPLY"
	},
	{
			"value": "GLITTER TAPER",
			"label": "GLITTER TAPER"
	},
	{
			"value": "GLITTER TULLE",
			"label": "GLITTER TULLE"
	},
	{
			"value": "GLITTER YORYU",
			"label": "GLITTER YORYU"
	},
	{
			"value": "GLITZ DENIM",
			"label": "GLITZ DENIM"
	},
	{
			"value": "GLITZ DESIGN",
			"label": "GLITZ DESIGN"
	},
	{
			"value": "GLITZ GIFTBAG",
			"label": "GLITZ GIFTBAG"
	},
	{
			"value": "GLITZ KNIT",
			"label": "GLITZ KNIT"
	},
	{
			"value": "GLITZ WRAP",
			"label": "GLITZ WRAP"
	},
	{
			"value": "GLOBAL ART",
			"label": "GLOBAL ART"
	},
	{
			"value": "GLOBAL HANGING",
			"label": "GLOBAL HANGING"
	},
	{
			"value": "GLOBAL RHYTHMS",
			"label": "GLOBAL RHYTHMS"
	},
	{
			"value": "GLOBAL VOYAGER",
			"label": "GLOBAL VOYAGER"
	},
	{
			"value": "GLOBE",
			"label": "GLOBE"
	},
	{
			"value": "GLOVES",
			"label": "GLOVES"
	},
	{
			"value": "GLOW",
			"label": "GLOW"
	},
	{
			"value": "GLOWFORGE COMPACT FI",
			"label": "GLOWFORGE COMPACT FI"
	},
	{
			"value": "GLOWFORGE MATERIAL",
			"label": "GLOWFORGE MATERIAL"
	},
	{
			"value": "GLOWFORGE PLUS",
			"label": "GLOWFORGE PLUS"
	},
	{
			"value": "GLOWFORGE PRO",
			"label": "GLOWFORGE PRO"
	},
	{
			"value": "GLOW IN DARK",
			"label": "GLOW IN DARK"
	},
	{
			"value": "GLOW IN THE DARK",
			"label": "GLOW IN THE DARK"
	},
	{
			"value": "GLOW KIT",
			"label": "GLOW KIT"
	},
	{
			"value": "GLTR EUC LEAVES",
			"label": "GLTR EUC LEAVES"
	},
	{
			"value": "GLTR GRASS",
			"label": "GLTR GRASS"
	},
	{
			"value": "GLTR LEAF",
			"label": "GLTR LEAF"
	},
	{
			"value": "GLTR PINECONE",
			"label": "GLTR PINECONE"
	},
	{
			"value": "GLUE",
			"label": "GLUE"
	},
	{
			"value": "GLUE ARTS",
			"label": "GLUE ARTS"
	},
	{
			"value": "GLUE DOTS",
			"label": "GLUE DOTS"
	},
	{
			"value": "GLUE PEN",
			"label": "GLUE PEN"
	},
	{
			"value": "GLUES",
			"label": "GLUES"
	},
	{
			"value": "GLUESB00",
			"label": "GLUESB00"
	},
	{
			"value": "GLUESD00",
			"label": "GLUESD00"
	},
	{
			"value": "GLUE TAPE",
			"label": "GLUE TAPE"
	},
	{
			"value": "GNOME",
			"label": "GNOME"
	},
	{
			"value": "GNOMES",
			"label": "GNOMES"
	},
	{
			"value": "GO BANANAS",
			"label": "GO BANANAS"
	},
	{
			"value": "GOGO TOYS",
			"label": "GOGO TOYS"
	},
	{
			"value": "GO GREEN",
			"label": "GO GREEN"
	},
	{
			"value": "GOLD",
			"label": "GOLD"
	},
	{
			"value": "GOLD BOWL",
			"label": "GOLD BOWL"
	},
	{
			"value": "GOLD CANDELABRA",
			"label": "GOLD CANDELABRA"
	},
	{
			"value": "GOLD EDGE THYME",
			"label": "GOLD EDGE THYME"
	},
	{
			"value": "GOLDEN TAKLON",
			"label": "GOLDEN TAKLON"
	},
	{
			"value": "GOLD PRINCESS",
			"label": "GOLD PRINCESS"
	},
	{
			"value": "GOLDRUSH",
			"label": "GOLDRUSH"
	},
	{
			"value": "GOLD STARFISH",
			"label": "GOLD STARFISH"
	},
	{
			"value": "GOLFER",
			"label": "GOLFER"
	},
	{
			"value": "GOMPHERENA",
			"label": "GOMPHERENA"
	},
	{
			"value": "GOMPHRENA",
			"label": "GOMPHRENA"
	},
	{
			"value": "GOMPHRENA BSH",
			"label": "GOMPHRENA BSH"
	},
	{
			"value": "GOOD DINOSAUR",
			"label": "GOOD DINOSAUR"
	},
	{
			"value": "GOOD HUMOR",
			"label": "GOOD HUMOR"
	},
	{
			"value": "GOOD MORNING AMERICA",
			"label": "GOOD MORNING AMERICA"
	},
	{
			"value": "GOOGLY EYES",
			"label": "GOOGLY EYES"
	},
	{
			"value": "GOO GONE",
			"label": "GOO GONE"
	},
	{
			"value": "GORDS",
			"label": "GORDS"
	},
	{
			"value": "GOUACHE",
			"label": "GOUACHE"
	},
	{
			"value": "GOURD",
			"label": "GOURD"
	},
	{
			"value": "GRACE COMPANY",
			"label": "GRACE COMPANY"
	},
	{
			"value": "GRADE SPECIFIC CONCE",
			"label": "GRADE SPECIFIC CONCE"
	},
	{
			"value": "GRADUATION",
			"label": "GRADUATION"
	},
	{
			"value": "GRAFIX",
			"label": "GRAFIX"
	},
	{
			"value": "GRAINER",
			"label": "GRAINER"
	},
	{
			"value": "GRANNY TOWEL",
			"label": "GRANNY TOWEL"
	},
	{
			"value": "GRANT STUDIOS",
			"label": "GRANT STUDIOS"
	},
	{
			"value": "GRAPE",
			"label": "GRAPE"
	},
	{
			"value": "GRAPE IVY",
			"label": "GRAPE IVY"
	},
	{
			"value": "GRAPE LEAF HANGING B",
			"label": "GRAPE LEAF HANGING B"
	},
	{
			"value": "GRAPE LVS",
			"label": "GRAPE LVS"
	},
	{
			"value": "GRAPE/LVS",
			"label": "GRAPE/LVS"
	},
	{
			"value": "GRAPE LVS BERRY",
			"label": "GRAPE LVS BERRY"
	},
	{
			"value": "GRAPEVINE",
			"label": "GRAPEVINE"
	},
	{
			"value": "GRAPEVINE BELL",
			"label": "GRAPEVINE BELL"
	},
	{
			"value": "GRAPEVINE FILLER",
			"label": "GRAPEVINE FILLER"
	},
	{
			"value": "GRAPEVINE PUMPKIN",
			"label": "GRAPEVINE PUMPKIN"
	},
	{
			"value": "GRAPEVINE WALL DECOR",
			"label": "GRAPEVINE WALL DECOR"
	},
	{
			"value": "GRAPEVINE WREATH",
			"label": "GRAPEVINE WREATH"
	},
	{
			"value": "GRAPHIC 45",
			"label": "GRAPHIC 45"
	},
	{
			"value": "GRAPHIC IMPRESSIONS",
			"label": "GRAPHIC IMPRESSIONS"
	},
	{
			"value": "GRAPHITE",
			"label": "GRAPHITE"
	},
	{
			"value": "GRASS",
			"label": "GRASS"
	},
	{
			"value": "GRASS HOPPER",
			"label": "GRASS HOPPER"
	},
	{
			"value": "GRASS POT",
			"label": "GRASS POT"
	},
	{
			"value": "GRASS SORGHUM BSH",
			"label": "GRASS SORGHUM BSH"
	},
	{
			"value": "GRASS WREATH",
			"label": "GRASS WREATH"
	},
	{
			"value": "GRASS WRTH",
			"label": "GRASS WRTH"
	},
	{
			"value": "GRATEFUL PILLOW",
			"label": "GRATEFUL PILLOW"
	},
	{
			"value": "GRAVEYARD",
			"label": "GRAVEYARD"
	},
	{
			"value": "GRAVY BOAT",
			"label": "GRAVY BOAT"
	},
	{
			"value": "GRAY",
			"label": "GRAY"
	},
	{
			"value": "GRAY/ORANGE",
			"label": "GRAY/ORANGE"
	},
	{
			"value": "GREEK LETTER",
			"label": "GREEK LETTER"
	},
	{
			"value": "GREEN",
			"label": "GREEN"
	},
	{
			"value": "GREEN01",
			"label": "GREEN01"
	},
	{
			"value": "GREEN AND GRAY",
			"label": "GREEN AND GRAY"
	},
	{
			"value": "GREEN AND GREY",
			"label": "GREEN AND GREY"
	},
	{
			"value": "GREEND00",
			"label": "GREEND00"
	},
	{
			"value": "GREEN DK",
			"label": "GREEN DK"
	},
	{
			"value": "GREEN EARTH",
			"label": "GREEN EARTH"
	},
	{
			"value": "GREEN EGGS AND HAM",
			"label": "GREEN EGGS AND HAM"
	},
	{
			"value": "GREENER TAPE/ SCOTCH",
			"label": "GREENER TAPE/ SCOTCH"
	},
	{
			"value": "GREENERY",
			"label": "GREENERY"
	},
	{
			"value": "GREENERY ARRANGEMENT",
			"label": "GREENERY ARRANGEMENT"
	},
	{
			"value": "GREENERY BSKT",
			"label": "GREENERY BSKT"
	},
	{
			"value": "GREENERY BUSH",
			"label": "GREENERY BUSH"
	},
	{
			"value": "GREENERY CANDLERING",
			"label": "GREENERY CANDLERING"
	},
	{
			"value": "GREENERY GARLAND",
			"label": "GREENERY GARLAND"
	},
	{
			"value": "GREENERY RIBBON",
			"label": "GREENERY RIBBON"
	},
	{
			"value": "GREENERY WREATH",
			"label": "GREENERY WREATH"
	},
	{
			"value": "GREEN FLOG",
			"label": "GREEN FLOG"
	},
	{
			"value": "GREEN FRINGE",
			"label": "GREEN FRINGE"
	},
	{
			"value": "GREEN HOUSE",
			"label": "GREEN HOUSE"
	},
	{
			"value": "GREENHOUSE",
			"label": "GREENHOUSE"
	},
	{
			"value": "GREEN LEAF",
			"label": "GREEN LEAF"
	},
	{
			"value": "GREEN LEAVES",
			"label": "GREEN LEAVES"
	},
	{
			"value": "GREEN LT",
			"label": "GREEN LT"
	},
	{
			"value": "GREEN SCREEN",
			"label": "GREEN SCREEN"
	},
	{
			"value": "GREEN START",
			"label": "GREEN START"
	},
	{
			"value": "GREEN THROW",
			"label": "GREEN THROW"
	},
	{
			"value": "GREEN WHITE",
			"label": "GREEN WHITE"
	},
	{
			"value": "GREETINGS",
			"label": "GREETINGS"
	},
	{
			"value": "GREVILLEA",
			"label": "GREVILLEA"
	},
	{
			"value": "GREY THROW",
			"label": "GREY THROW"
	},
	{
			"value": "GREY TREND FALL RIBB",
			"label": "GREY TREND FALL RIBB"
	},
	{
			"value": "GRID PLAID",
			"label": "GRID PLAID"
	},
	{
			"value": "GRIDS/MATS/RACKS",
			"label": "GRIDS/MATS/RACKS"
	},
	{
			"value": "GRIDWORK",
			"label": "GRIDWORK"
	},
	{
			"value": "GRINCH",
			"label": "GRINCH"
	},
	{
			"value": "GRIP MAT",
			"label": "GRIP MAT"
	},
	{
			"value": "GRIP STOP",
			"label": "GRIP STOP"
	},
	{
			"value": "GRISWOLD",
			"label": "GRISWOLD"
	},
	{
			"value": "GRIZZLY",
			"label": "GRIZZLY"
	},
	{
			"value": "GRLND",
			"label": "GRLND"
	},
	{
			"value": "GRMT BLU",
			"label": "GRMT BLU"
	},
	{
			"value": "GRMT GRY",
			"label": "GRMT GRY"
	},
	{
			"value": "GRMT WHT",
			"label": "GRMT WHT"
	},
	{
			"value": "GRNRY ARRG",
			"label": "GRNRY ARRG"
	},
	{
			"value": "GRNRY IN POT",
			"label": "GRNRY IN POT"
	},
	{
			"value": "GROMMETS",
			"label": "GROMMETS"
	},
	{
			"value": "GROSGRAIN",
			"label": "GROSGRAIN"
	},
	{
			"value": "GROUP KITS",
			"label": "GROUP KITS"
	},
	{
			"value": "GROUP OF HEARTS",
			"label": "GROUP OF HEARTS"
	},
	{
			"value": "GROW BAG",
			"label": "GROW BAG"
	},
	{
			"value": "GROW BAG 10G",
			"label": "GROW BAG 10G"
	},
	{
			"value": "GROW BAG 1G",
			"label": "GROW BAG 1G"
	},
	{
			"value": "GROW BAG 2G",
			"label": "GROW BAG 2G"
	},
	{
			"value": "GROW BAG .5G",
			"label": "GROW BAG .5G"
	},
	{
			"value": "GROW BAG 5G",
			"label": "GROW BAG 5G"
	},
	{
			"value": "GROW BAGS",
			"label": "GROW BAGS"
	},
	{
			"value": "GROW KIT",
			"label": "GROW KIT"
	},
	{
			"value": "GROW KIT FLOWER",
			"label": "GROW KIT FLOWER"
	},
	{
			"value": "GROW KIT HERB",
			"label": "GROW KIT HERB"
	},
	{
			"value": "GROW KIT PLANT",
			"label": "GROW KIT PLANT"
	},
	{
			"value": "GROW KIT RECEIPE",
			"label": "GROW KIT RECEIPE"
	},
	{
			"value": "GROW KITS",
			"label": "GROW KITS"
	},
	{
			"value": "GROW KIT VEGGIE",
			"label": "GROW KIT VEGGIE"
	},
	{
			"value": "GRPVNE BROOM",
			"label": "GRPVNE BROOM"
	},
	{
			"value": "GRPVNE BSKT",
			"label": "GRPVNE BSKT"
	},
	{
			"value": "GRPVNE WALL DECOR",
			"label": "GRPVNE WALL DECOR"
	},
	{
			"value": "GRY 10 S",
			"label": "GRY 10 S"
	},
	{
			"value": "GRY 12 L",
			"label": "GRY 12 L"
	},
	{
			"value": "GRY/BLU",
			"label": "GRY/BLU"
	},
	{
			"value": "GRY/IVR",
			"label": "GRY/IVR"
	},
	{
			"value": "GRY TWEED",
			"label": "GRY TWEED"
	},
	{
			"value": "GRY/WHT",
			"label": "GRY/WHT"
	},
	{
			"value": "GRY WOVEN",
			"label": "GRY WOVEN"
	},
	{
			"value": "GUARDSTAMP ID",
			"label": "GUARDSTAMP ID"
	},
	{
			"value": "GUEST NAPKIN",
			"label": "GUEST NAPKIN"
	},
	{
			"value": "GUEST TOWEL",
			"label": "GUEST TOWEL"
	},
	{
			"value": "GUESTTOWEL",
			"label": "GUESTTOWEL"
	},
	{
			"value": "GUIDED JOURNAL",
			"label": "GUIDED JOURNAL"
	},
	{
			"value": "GUITARS",
			"label": "GUITARS"
	},
	{
			"value": "GUM",
			"label": "GUM"
	},
	{
			"value": "GUMBALL",
			"label": "GUMBALL"
	},
	{
			"value": "GUM MINT MISC",
			"label": "GUM MINT MISC"
	},
	{
			"value": "GUMMY",
			"label": "GUMMY"
	},
	{
			"value": "GUN",
			"label": "GUN"
	},
	{
			"value": "GUTERMANN",
			"label": "GUTERMANN"
	},
	{
			"value": "GYPSO",
			"label": "GYPSO"
	},
	{
			"value": "GYPSOPHILA",
			"label": "GYPSOPHILA"
	},
	{
			"value": "GYPSOPHILIA",
			"label": "GYPSOPHILIA"
	},
	{
			"value": "GYPSOPHILIA PAPER",
			"label": "GYPSOPHILIA PAPER"
	},
	{
			"value": "GYPSY GRASS",
			"label": "GYPSY GRASS"
	},
	{
			"value": "GYPSYLAND",
			"label": "GYPSYLAND"
	},
	{
			"value": "GYPSY QUILTER",
			"label": "GYPSY QUILTER"
	},
	{
			"value": "HABA",
			"label": "HABA"
	},
	{
			"value": "HACCI",
			"label": "HACCI"
	},
	{
			"value": "HAIR",
			"label": "HAIR"
	},
	{
			"value": "HAIR ACC",
			"label": "HAIR ACC"
	},
	{
			"value": "HAIR ACCESSORIES",
			"label": "HAIR ACCESSORIES"
	},
	{
			"value": "HAIR BARRETTES",
			"label": "HAIR BARRETTES"
	},
	{
			"value": "HAIR BOW",
			"label": "HAIR BOW"
	},
	{
			"value": "HAIRBOW",
			"label": "HAIRBOW"
	},
	{
			"value": "HAIR CLAW",
			"label": "HAIR CLAW"
	},
	{
			"value": "HAIR CLIP",
			"label": "HAIR CLIP"
	},
	{
			"value": "HAIRCLIP",
			"label": "HAIRCLIP"
	},
	{
			"value": "HAIR CLIP KIT",
			"label": "HAIR CLIP KIT"
	},
	{
			"value": "HAIR CLIPS",
			"label": "HAIR CLIPS"
	},
	{
			"value": "HAIR COIL",
			"label": "HAIR COIL"
	},
	{
			"value": "HAIR COMB",
			"label": "HAIR COMB"
	},
	{
			"value": "HAIRCOMB",
			"label": "HAIRCOMB"
	},
	{
			"value": "HAIR/JEWELRY",
			"label": "HAIR/JEWELRY"
	},
	{
			"value": "HAIR KIT",
			"label": "HAIR KIT"
	},
	{
			"value": "HAIR PIECE",
			"label": "HAIR PIECE"
	},
	{
			"value": "HAIR PIN",
			"label": "HAIR PIN"
	},
	{
			"value": "HAIR PIN PLANT STAND",
			"label": "HAIR PIN PLANT STAND"
	},
	{
			"value": "HAIR PINS",
			"label": "HAIR PINS"
	},
	{
			"value": "HAIR RIBBON",
			"label": "HAIR RIBBON"
	},
	{
			"value": "HAIR TIES",
			"label": "HAIR TIES"
	},
	{
			"value": "HALAENOPSIS",
			"label": "HALAENOPSIS"
	},
	{
			"value": "HALF BALL",
			"label": "HALF BALL"
	},
	{
			"value": "HALF CUP",
			"label": "HALF CUP"
	},
	{
			"value": "HALL0WEEN PAPER MACH",
			"label": "HALL0WEEN PAPER MACH"
	},
	{
			"value": "HALL BURLAP",
			"label": "HALL BURLAP"
	},
	{
			"value": "HALLMARK",
			"label": "HALLMARK"
	},
	{
			"value": "HALLO EASYPATTERN",
			"label": "HALLO EASYPATTERN"
	},
	{
			"value": "HALLOWEEN",
			"label": "HALLOWEEN"
	},
	{
			"value": "HALLOWEEN 3 WICK",
			"label": "HALLOWEEN 3 WICK"
	},
	{
			"value": "HALLOWEEN BOW",
			"label": "HALLOWEEN BOW"
	},
	{
			"value": "HALLOWEEN BURLAP",
			"label": "HALLOWEEN BURLAP"
	},
	{
			"value": "HALLOWEEN CLASSICS",
			"label": "HALLOWEEN CLASSICS"
	},
	{
			"value": "HALLOWEEN CLIP STRIP",
			"label": "HALLOWEEN CLIP STRIP"
	},
	{
			"value": "HALLOWEEN COSTUMES",
			"label": "HALLOWEEN COSTUMES"
	},
	{
			"value": "HALLOWEEN ENDCAP",
			"label": "HALLOWEEN ENDCAP"
	},
	{
			"value": "HALLOWEEN FIGURAL",
			"label": "HALLOWEEN FIGURAL"
	},
	{
			"value": "HALLOWEEN GAMES",
			"label": "HALLOWEEN GAMES"
	},
	{
			"value": "HALLOWEEN GEL CLING",
			"label": "HALLOWEEN GEL CLING"
	},
	{
			"value": "HALLOWEEN ICONS",
			"label": "HALLOWEEN ICONS"
	},
	{
			"value": "HALLOWEEN JAR",
			"label": "HALLOWEEN JAR"
	},
	{
			"value": "HALLOWEEN LED",
			"label": "HALLOWEEN LED"
	},
	{
			"value": "HALLOWEEN LED GLOW",
			"label": "HALLOWEEN LED GLOW"
	},
	{
			"value": "HALLOWEEN PILLAR",
			"label": "HALLOWEEN PILLAR"
	},
	{
			"value": "HALLOWEEN POWER PANE",
			"label": "HALLOWEEN POWER PANE"
	},
	{
			"value": "HALLOWEEN RIBBON",
			"label": "HALLOWEEN RIBBON"
	},
	{
			"value": "HALLOWEEN SPIRIT",
			"label": "HALLOWEEN SPIRIT"
	},
	{
			"value": "HALLOWEEN SPOOLS",
			"label": "HALLOWEEN SPOOLS"
	},
	{
			"value": "HALLOWEEN TAPER",
			"label": "HALLOWEEN TAPER"
	},
	{
			"value": "HALLOWEEN TEALIGHT",
			"label": "HALLOWEEN TEALIGHT"
	},
	{
			"value": "HALLOWEEN TRIMS",
			"label": "HALLOWEEN TRIMS"
	},
	{
			"value": "HALLOWEEN TULLE",
			"label": "HALLOWEEN TULLE"
	},
	{
			"value": "HALLS",
			"label": "HALLS"
	},
	{
			"value": "HALSTEAD",
			"label": "HALSTEAD"
	},
	{
			"value": "HAMMER",
			"label": "HAMMER"
	},
	{
			"value": "HAMMERED",
			"label": "HAMMERED"
	},
	{
			"value": "HAMMER SATIN",
			"label": "HAMMER SATIN"
	},
	{
			"value": "HAMPTON ART",
			"label": "HAMPTON ART"
	},
	{
			"value": "HAMSA",
			"label": "HAMSA"
	},
	{
			"value": "HAMSTERS",
			"label": "HAMSTERS"
	},
	{
			"value": "HAND",
			"label": "HAND"
	},
	{
			"value": "HANDBAG",
			"label": "HANDBAG"
	},
	{
			"value": "HANDLE CRATE",
			"label": "HANDLE CRATE"
	},
	{
			"value": "HANDLE POT",
			"label": "HANDLE POT"
	},
	{
			"value": "HANDMADE FELT OSO",
			"label": "HANDMADE FELT OSO"
	},
	{
			"value": "HANDMADE HOLIDAY",
			"label": "HANDMADE HOLIDAY"
	},
	{
			"value": "HAND-PAINTED",
			"label": "HAND-PAINTED"
	},
	{
			"value": "HAND SOAP",
			"label": "HAND SOAP"
	},
	{
			"value": "HAND TOWEL",
			"label": "HAND TOWEL"
	},
	{
			"value": "HANDTOWEL",
			"label": "HANDTOWEL"
	},
	{
			"value": "HAND WARMER",
			"label": "HAND WARMER"
	},
	{
			"value": "HANDY ART",
			"label": "HANDY ART"
	},
	{
			"value": "HANDY HANDS",
			"label": "HANDY HANDS"
	},
	{
			"value": "HANGER",
			"label": "HANGER"
	},
	{
			"value": "HANGERS",
			"label": "HANGERS"
	},
	{
			"value": "HANGING",
			"label": "HANGING"
	},
	{
			"value": "HANGING BASKET",
			"label": "HANGING BASKET"
	},
	{
			"value": "HANGING BUSH",
			"label": "HANGING BUSH"
	},
	{
			"value": "HANGING CHANDELIER",
			"label": "HANGING CHANDELIER"
	},
	{
			"value": "HANGING CREATURE",
			"label": "HANGING CREATURE"
	},
	{
			"value": "HANGING DECOR",
			"label": "HANGING DECOR"
	},
	{
			"value": "HANGING DISPLAY",
			"label": "HANGING DISPLAY"
	},
	{
			"value": "HANGING LANTERN",
			"label": "HANGING LANTERN"
	},
	{
			"value": "HANGING LEAVES",
			"label": "HANGING LEAVES"
	},
	{
			"value": "HANGING LIGHT",
			"label": "HANGING LIGHT"
	},
	{
			"value": "HANGING ORGANIZER",
			"label": "HANGING ORGANIZER"
	},
	{
			"value": "HANGING PLANTER",
			"label": "HANGING PLANTER"
	},
	{
			"value": "HANGING SKULL",
			"label": "HANGING SKULL"
	},
	{
			"value": "HANGING WREATH",
			"label": "HANGING WREATH"
	},
	{
			"value": "HANG TAGS",
			"label": "HANG TAGS"
	},
	{
			"value": "HANNAH MONTANA",
			"label": "HANNAH MONTANA"
	},
	{
			"value": "HANNUKAH",
			"label": "HANNUKAH"
	},
	{
			"value": "HANUKKAH",
			"label": "HANUKKAH"
	},
	{
			"value": "HANUKKAH WRAP",
			"label": "HANUKKAH WRAP"
	},
	{
			"value": "HAPPY FLORAL",
			"label": "HAPPY FLORAL"
	},
	{
			"value": "HAPPY FLOWERS",
			"label": "HAPPY FLOWERS"
	},
	{
			"value": "HAPPY FROG",
			"label": "HAPPY FROG"
	},
	{
			"value": "HAPPY GO LUCKY",
			"label": "HAPPY GO LUCKY"
	},
	{
			"value": "HAPPY HAUNTING",
			"label": "HAPPY HAUNTING"
	},
	{
			"value": "HARD CANDY",
			"label": "HARD CANDY"
	},
	{
			"value": "HARNESS",
			"label": "HARNESS"
	},
	{
			"value": "HARNESS LXL",
			"label": "HARNESS LXL"
	},
	{
			"value": "HARNESS SMMED",
			"label": "HARNESS SMMED"
	},
	{
			"value": "HARRY POTTER",
			"label": "HARRY POTTER"
	},
	{
			"value": "HARVEST",
			"label": "HARVEST"
	},
	{
			"value": "HARVEST BOUQUET",
			"label": "HARVEST BOUQUET"
	},
	{
			"value": "HARVEST CLIP STRIP",
			"label": "HARVEST CLIP STRIP"
	},
	{
			"value": "HARVEST CLIPSTRIP",
			"label": "HARVEST CLIPSTRIP"
	},
	{
			"value": "HAT",
			"label": "HAT"
	},
	{
			"value": "HAT ADULT",
			"label": "HAT ADULT"
	},
	{
			"value": "HAT BLANK",
			"label": "HAT BLANK"
	},
	{
			"value": "HAT CHILD",
			"label": "HAT CHILD"
	},
	{
			"value": "HATCHIMALS",
			"label": "HATCHIMALS"
	},
	{
			"value": "HAT HEADBAND",
			"label": "HAT HEADBAND"
	},
	{
			"value": "HAT LG",
			"label": "HAT LG"
	},
	{
			"value": "HAT LXL",
			"label": "HAT LXL"
	},
	{
			"value": "HAT ORN",
			"label": "HAT ORN"
	},
	{
			"value": "HAT PINS",
			"label": "HAT PINS"
	},
	{
			"value": "HAT PRESS",
			"label": "HAT PRESS"
	},
	{
			"value": "HATS",
			"label": "HATS"
	},
	{
			"value": "HAT SMMED",
			"label": "HAT SMMED"
	},
	{
			"value": "HAT STEM",
			"label": "HAT STEM"
	},
	{
			"value": "HAUNTED HOLLOWS",
			"label": "HAUNTED HOLLOWS"
	},
	{
			"value": "HAUNTED HOUSE",
			"label": "HAUNTED HOUSE"
	},
	{
			"value": "HAVEL'S",
			"label": "HAVEL'S"
	},
	{
			"value": "HD",
			"label": "HD"
	},
	{
			"value": "HDNOT",
			"label": "HDNOT"
	},
	{
			"value": "HDNOTC00",
			"label": "HDNOTC00"
	},
	{
			"value": "HDNOTD00",
			"label": "HDNOTD00"
	},
	{
			"value": "HDNOTE00",
			"label": "HDNOTE00"
	},
	{
			"value": "HDSTOOTH",
			"label": "HDSTOOTH"
	},
	{
			"value": "HDTRMB00",
			"label": "HDTRMB00"
	},
	{
			"value": "HDTRMD00",
			"label": "HDTRMD00"
	},
	{
			"value": "HEAD",
			"label": "HEAD"
	},
	{
			"value": "HEADBAND",
			"label": "HEADBAND"
	},
	{
			"value": "HEADBAND 1/4 INCH",
			"label": "HEADBAND 1/4 INCH"
	},
	{
			"value": "HEADBAND 1 5/8 INCH",
			"label": "HEADBAND 1 5/8 INCH"
	},
	{
			"value": "HEADBAND- 1 INCH",
			"label": "HEADBAND- 1 INCH"
	},
	{
			"value": "HEADBAND- 5/8 INCH",
			"label": "HEADBAND- 5/8 INCH"
	},
	{
			"value": "HEADBAND ADULT",
			"label": "HEADBAND ADULT"
	},
	{
			"value": "HEADBAND CHILD",
			"label": "HEADBAND CHILD"
	},
	{
			"value": "HEADBAND HARD",
			"label": "HEADBAND HARD"
	},
	{
			"value": "HEADBAND INFANT",
			"label": "HEADBAND INFANT"
	},
	{
			"value": "HEADBANDS",
			"label": "HEADBANDS"
	},
	{
			"value": "HEADBAND SOFT",
			"label": "HEADBAND SOFT"
	},
	{
			"value": "HEADER",
			"label": "HEADER"
	},
	{
			"value": "HEADER 12SKUS",
			"label": "HEADER 12SKUS"
	},
	{
			"value": "HEADER CRITTER",
			"label": "HEADER CRITTER"
	},
	{
			"value": "HEADER-DTE B",
			"label": "HEADER-DTE B"
	},
	{
			"value": "HEADER WORD BLOCK",
			"label": "HEADER WORD BLOCK"
	},
	{
			"value": "HEADLINER",
			"label": "HEADLINER"
	},
	{
			"value": "HEAD PINS",
			"label": "HEAD PINS"
	},
	{
			"value": "HEADPINS AND EYE PIN",
			"label": "HEADPINS AND EYE PIN"
	},
	{
			"value": "HEADWARE",
			"label": "HEADWARE"
	},
	{
			"value": "HEADWEAR",
			"label": "HEADWEAR"
	},
	{
			"value": "HEART",
			"label": "HEART"
	},
	{
			"value": "HEART CHAIN",
			"label": "HEART CHAIN"
	},
	{
			"value": "HEARTFELT CREATIONS",
			"label": "HEARTFELT CREATIONS"
	},
	{
			"value": "HEARTLAND",
			"label": "HEARTLAND"
	},
	{
			"value": "HEARTS",
			"label": "HEARTS"
	},
	{
			"value": "HEART WRTH",
			"label": "HEART WRTH"
	},
	{
			"value": "HEATHER",
			"label": "HEATHER"
	},
	{
			"value": "HEATHER EVA",
			"label": "HEATHER EVA"
	},
	{
			"value": "HEAT N BOND",
			"label": "HEAT N BOND"
	},
	{
			"value": "HEAT PRESS BATTING",
			"label": "HEAT PRESS BATTING"
	},
	{
			"value": "HEAT TAPE",
			"label": "HEAT TAPE"
	},
	{
			"value": "HEAT TRANSFER",
			"label": "HEAT TRANSFER"
	},
	{
			"value": "HEAVY DUTY",
			"label": "HEAVY DUTY"
	},
	{
			"value": "HEAVY WEIGHT POLY",
			"label": "HEAVY WEIGHT POLY"
	},
	{
			"value": "HEDGEHOG",
			"label": "HEDGEHOG"
	},
	{
			"value": "HEDGEHOGS",
			"label": "HEDGEHOGS"
	},
	{
			"value": "HEIDI GRACE",
			"label": "HEIDI GRACE"
	},
	{
			"value": "HEIRLOOM",
			"label": "HEIRLOOM"
	},
	{
			"value": "HEIRLOOM QUILTING",
			"label": "HEIRLOOM QUILTING"
	},
	{
			"value": "HELICIA",
			"label": "HELICIA"
	},
	{
			"value": "HELICONA",
			"label": "HELICONA"
	},
	{
			"value": "HELICONIA",
			"label": "HELICONIA"
	},
	{
			"value": "HELICONIA BAMBOO",
			"label": "HELICONIA BAMBOO"
	},
	{
			"value": "HELIX",
			"label": "HELIX"
	},
	{
			"value": "HELLEBORE",
			"label": "HELLEBORE"
	},
	{
			"value": "HELLO KITTY",
			"label": "HELLO KITTY"
	},
	{
			"value": "HELLO KITTY IMPULSE",
			"label": "HELLO KITTY IMPULSE"
	},
	{
			"value": "HELMAR",
			"label": "HELMAR"
	},
	{
			"value": "HEM FACING",
			"label": "HEM FACING"
	},
	{
			"value": "HEMLOCK",
			"label": "HEMLOCK"
	},
	{
			"value": "HEMP",
			"label": "HEMP"
	},
	{
			"value": "HEMPTIQUE",
			"label": "HEMPTIQUE"
	},
	{
			"value": "HEN",
			"label": "HEN"
	},
	{
			"value": "HENKEL",
			"label": "HENKEL"
	},
	{
			"value": "HENNA",
			"label": "HENNA"
	},
	{
			"value": "HERB",
			"label": "HERB"
	},
	{
			"value": "HERB MARKER",
			"label": "HERB MARKER"
	},
	{
			"value": "HERBS",
			"label": "HERBS"
	},
	{
			"value": "HERE COMES SANTA",
			"label": "HERE COMES SANTA"
	},
	{
			"value": "HERITAGE FLORALS",
			"label": "HERITAGE FLORALS"
	},
	{
			"value": "HERO ARTS",
			"label": "HERO ARTS"
	},
	{
			"value": "HERRINGBONE",
			"label": "HERRINGBONE"
	},
	{
			"value": "HERRINGBONE  CADDY",
			"label": "HERRINGBONE  CADDY"
	},
	{
			"value": "HEXAGON SENTIMENTS",
			"label": "HEXAGON SENTIMENTS"
	},
	{
			"value": "HFTH",
			"label": "HFTH"
	},
	{
			"value": "HH",
			"label": "HH"
	},
	{
			"value": "HIBISCUS",
			"label": "HIBISCUS"
	},
	{
			"value": "HIBISCUS FROG FLNL",
			"label": "HIBISCUS FROG FLNL"
	},
	{
			"value": "HIDDEN GEMS",
			"label": "HIDDEN GEMS"
	},
	{
			"value": "HIDDEN PICTURES",
			"label": "HIDDEN PICTURES"
	},
	{
			"value": "HIGH DEN FOAM 2 PACK",
			"label": "HIGH DEN FOAM 2 PACK"
	},
	{
			"value": "HIGH DENSITY FOAM",
			"label": "HIGH DENSITY FOAM"
	},
	{
			"value": "HIGH HAMPTONS",
			"label": "HIGH HAMPTONS"
	},
	{
			"value": "HIGHLIGHTERS",
			"label": "HIGHLIGHTERS"
	},
	{
			"value": "HIGH LOFT",
			"label": "HIGH LOFT"
	},
	{
			"value": "HIGH PILE SOLIDS",
			"label": "HIGH PILE SOLIDS"
	},
	{
			"value": "HIGH PILE TIPPED",
			"label": "HIGH PILE TIPPED"
	},
	{
			"value": "HILL FLOWERS",
			"label": "HILL FLOWERS"
	},
	{
			"value": "HI LOFT BATTING",
			"label": "HI LOFT BATTING"
	},
	{
			"value": "HIP CLIPS",
			"label": "HIP CLIPS"
	},
	{
			"value": "HISPANIC HERITAGE",
			"label": "HISPANIC HERITAGE"
	},
	{
			"value": "HISTORICAL",
			"label": "HISTORICAL"
	},
	{
			"value": "HISTORICAL COSTUME",
			"label": "HISTORICAL COSTUME"
	},
	{
			"value": "HISTORIC COSTUME",
			"label": "HISTORIC COSTUME"
	},
	{
			"value": "HLDR L",
			"label": "HLDR L"
	},
	{
			"value": "HLDR S",
			"label": "HLDR S"
	},
	{
			"value": "HLLWN MULTI PACK",
			"label": "HLLWN MULTI PACK"
	},
	{
			"value": "H LVS AND PINECONE",
			"label": "H LVS AND PINECONE"
	},
	{
			"value": "HLWN BANNER",
			"label": "HLWN BANNER"
	},
	{
			"value": "HLWN BENDON",
			"label": "HLWN BENDON"
	},
	{
			"value": "HLWN CANVAS",
			"label": "HLWN CANVAS"
	},
	{
			"value": "HLWN COLORUP",
			"label": "HLWN COLORUP"
	},
	{
			"value": "HLWN CRAFT KIT",
			"label": "HLWN CRAFT KIT"
	},
	{
			"value": "HLWN EYES",
			"label": "HLWN EYES"
	},
	{
			"value": "HLWN FEATHERS",
			"label": "HLWN FEATHERS"
	},
	{
			"value": "HLWN FELT SHAPE",
			"label": "HLWN FELT SHAPE"
	},
	{
			"value": "HLWN FOAM PLATTER",
			"label": "HLWN FOAM PLATTER"
	},
	{
			"value": "HLWN FOAM SHAPE",
			"label": "HLWN FOAM SHAPE"
	},
	{
			"value": "HLWN FOAMSHAPE",
			"label": "HLWN FOAMSHAPE"
	},
	{
			"value": "HLWN FUR",
			"label": "HLWN FUR"
	},
	{
			"value": "HLWN FUZZY POSTER",
			"label": "HLWN FUZZY POSTER"
	},
	{
			"value": "HLWN GARLAND",
			"label": "HLWN GARLAND"
	},
	{
			"value": "HLWN GEM",
			"label": "HLWN GEM"
	},
	{
			"value": "HLWN GLITTER",
			"label": "HLWN GLITTER"
	},
	{
			"value": "HLWN GLOW",
			"label": "HLWN GLOW"
	},
	{
			"value": "HLWN IMPULSE",
			"label": "HLWN IMPULSE"
	},
	{
			"value": "HLWN KIT",
			"label": "HLWN KIT"
	},
	{
			"value": "HLWN MASK",
			"label": "HLWN MASK"
	},
	{
			"value": "HLWN MELTY BEAD",
			"label": "HLWN MELTY BEAD"
	},
	{
			"value": "HLWN MESH",
			"label": "HLWN MESH"
	},
	{
			"value": "HLWN MIBI",
			"label": "HLWN MIBI"
	},
	{
			"value": "HLWN PAINT",
			"label": "HLWN PAINT"
	},
	{
			"value": "HLWN PAINTBOOK",
			"label": "HLWN PAINTBOOK"
	},
	{
			"value": "HLWN PAPER",
			"label": "HLWN PAPER"
	},
	{
			"value": "HLWN PLASTER",
			"label": "HLWN PLASTER"
	},
	{
			"value": "HLWN POM",
			"label": "HLWN POM"
	},
	{
			"value": "HLWN POM POM",
			"label": "HLWN POM POM"
	},
	{
			"value": "HLWN SC",
			"label": "HLWN SC"
	},
	{
			"value": "HLWN SCRATCH",
			"label": "HLWN SCRATCH"
	},
	{
			"value": "HLWN SCRATCH ART",
			"label": "HLWN SCRATCH ART"
	},
	{
			"value": "HLWN SEQUINS",
			"label": "HLWN SEQUINS"
	},
	{
			"value": "HLWN SHRINK",
			"label": "HLWN SHRINK"
	},
	{
			"value": "HLWN SPONGES",
			"label": "HLWN SPONGES"
	},
	{
			"value": "HLWN STENCILS",
			"label": "HLWN STENCILS"
	},
	{
			"value": "HLWN STICKER",
			"label": "HLWN STICKER"
	},
	{
			"value": "HLWN STICKERBOOK",
			"label": "HLWN STICKERBOOK"
	},
	{
			"value": "HLWN TATTOO",
			"label": "HLWN TATTOO"
	},
	{
			"value": "HLWN WOOD",
			"label": "HLWN WOOD"
	},
	{
			"value": "HLWN WOOD CUT",
			"label": "HLWN WOOD CUT"
	},
	{
			"value": "HLWN WOOD DOLL",
			"label": "HLWN WOOD DOLL"
	},
	{
			"value": "HLWN WOOD GEM",
			"label": "HLWN WOOD GEM"
	},
	{
			"value": "HLWN WOOD MASK",
			"label": "HLWN WOOD MASK"
	},
	{
			"value": "HLWN WOOD ORNA",
			"label": "HLWN WOOD ORNA"
	},
	{
			"value": "HLWN WOOD PADDLE",
			"label": "HLWN WOOD PADDLE"
	},
	{
			"value": "HLWN WOOD PAINT",
			"label": "HLWN WOOD PAINT"
	},
	{
			"value": "HLWN WOOD SHAPE",
			"label": "HLWN WOOD SHAPE"
	},
	{
			"value": "HLWN WOOD SHAPRE",
			"label": "HLWN WOOD SHAPRE"
	},
	{
			"value": "HLWN WOOD STAND",
			"label": "HLWN WOOD STAND"
	},
	{
			"value": "HLWN WOOD STRING",
			"label": "HLWN WOOD STRING"
	},
	{
			"value": "HLY GLTR",
			"label": "HLY GLTR"
	},
	{
			"value": "H-MAGNOLIA LEAVES WH",
			"label": "H-MAGNOLIA LEAVES WH"
	},
	{
			"value": "HMDEC71",
			"label": "HMDEC71"
	},
	{
			"value": "HMDECG01",
			"label": "HMDECG01"
	},
	{
			"value": "HOBBY",
			"label": "HOBBY"
	},
	{
			"value": "HOBBY/TRAVEL",
			"label": "HOBBY/TRAVEL"
	},
	{
			"value": "HOBO BAG",
			"label": "HOBO BAG"
	},
	{
			"value": "HOCKEY",
			"label": "HOCKEY"
	},
	{
			"value": "HOFMANN ORIGINALS",
			"label": "HOFMANN ORIGINALS"
	},
	{
			"value": "HOLBV",
			"label": "HOLBV"
	},
	{
			"value": "HOLBVG00",
			"label": "HOLBVG00"
	},
	{
			"value": "HOLDBACK",
			"label": "HOLDBACK"
	},
	{
			"value": "HOLDER",
			"label": "HOLDER"
	},
	{
			"value": "HOLFLG00",
			"label": "HOLFLG00"
	},
	{
			"value": "HOLIDAY",
			"label": "HOLIDAY"
	},
	{
			"value": "HOLIDAY APRON KIT",
			"label": "HOLIDAY APRON KIT"
	},
	{
			"value": "HOLIDAY BATH",
			"label": "HOLIDAY BATH"
	},
	{
			"value": "HOLIDAY CHARM",
			"label": "HOLIDAY CHARM"
	},
	{
			"value": "HOLIDAY CLSSIC WRAP",
			"label": "HOLIDAY CLSSIC WRAP"
	},
	{
			"value": "HOLIDAY CRAFT",
			"label": "HOLIDAY CRAFT"
	},
	{
			"value": "HOLIDAY DRESSING",
			"label": "HOLIDAY DRESSING"
	},
	{
			"value": "HOLIDAY FESTIVAL",
			"label": "HOLIDAY FESTIVAL"
	},
	{
			"value": "HOLIDAY FOOD CRAFTIN",
			"label": "HOLIDAY FOOD CRAFTIN"
	},
	{
			"value": "HOLIDAY GIFTS",
			"label": "HOLIDAY GIFTS"
	},
	{
			"value": "HOLIDAY ICONS",
			"label": "HOLIDAY ICONS"
	},
	{
			"value": "HOLIDAY IMPULSE",
			"label": "HOLIDAY IMPULSE"
	},
	{
			"value": "HOLIDAY INSPIRATIONS",
			"label": "HOLIDAY INSPIRATIONS"
	},
	{
			"value": "HOLIDAY MAGIC",
			"label": "HOLIDAY MAGIC"
	},
	{
			"value": "HOLIDAY MAGIC OSO",
			"label": "HOLIDAY MAGIC OSO"
	},
	{
			"value": "HOLIDAY MOLDED SOAP",
			"label": "HOLIDAY MOLDED SOAP"
	},
	{
			"value": "HOLIDAY PINE",
			"label": "HOLIDAY PINE"
	},
	{
			"value": "HOLIDAY PINE OSO",
			"label": "HOLIDAY PINE OSO"
	},
	{
			"value": "HOLIDAY PLUSH",
			"label": "HOLIDAY PLUSH"
	},
	{
			"value": "HOLIDAY QUILTING",
			"label": "HOLIDAY QUILTING"
	},
	{
			"value": "HOLIDAY READERS",
			"label": "HOLIDAY READERS"
	},
	{
			"value": "HOLIDAYS",
			"label": "HOLIDAYS"
	},
	{
			"value": "HOLIDAY SOCKS",
			"label": "HOLIDAY SOCKS"
	},
	{
			"value": "HOLIDAY SPIRIT",
			"label": "HOLIDAY SPIRIT"
	},
	{
			"value": "HOLIDAY SUNGLASSES",
			"label": "HOLIDAY SUNGLASSES"
	},
	{
			"value": "HOLIDAY TAPER",
			"label": "HOLIDAY TAPER"
	},
	{
			"value": "HOLIDAY THROWS",
			"label": "HOLIDAY THROWS"
	},
	{
			"value": "HOLIDAY TRANSPARENT",
			"label": "HOLIDAY TRANSPARENT"
	},
	{
			"value": "HOLIDAY TULLE",
			"label": "HOLIDAY TULLE"
	},
	{
			"value": "HOLIDAY WINE",
			"label": "HOLIDAY WINE"
	},
	{
			"value": "HOLI MULTI PACK",
			"label": "HOLI MULTI PACK"
	},
	{
			"value": "HOLLY",
			"label": "HOLLY"
	},
	{
			"value": "HOLLY & BERRY",
			"label": "HOLLY & BERRY"
	},
	{
			"value": "HOLLY BERRY",
			"label": "HOLLY BERRY"
	},
	{
			"value": "HOLLY HOCK",
			"label": "HOLLY HOCK"
	},
	{
			"value": "HOLLY LEAVES",
			"label": "HOLLY LEAVES"
	},
	{
			"value": "HOLLY LVS",
			"label": "HOLLY LVS"
	},
	{
			"value": "HOLLY LVS AND BELL",
			"label": "HOLLY LVS AND BELL"
	},
	{
			"value": "HOLLY LVS & BELL",
			"label": "HOLLY LVS & BELL"
	},
	{
			"value": "HOLLY LVS BERRY",
			"label": "HOLLY LVS BERRY"
	},
	{
			"value": "HOLLYWOOD ICONS",
			"label": "HOLLYWOOD ICONS"
	},
	{
			"value": "HOLOGRAM BOOT",
			"label": "HOLOGRAM BOOT"
	},
	{
			"value": "HOLOGRAM TIE DYE",
			"label": "HOLOGRAM TIE DYE"
	},
	{
			"value": "HOLOGRAPHIC",
			"label": "HOLOGRAPHIC"
	},
	{
			"value": "HOLOGRAPHIC HTV",
			"label": "HOLOGRAPHIC HTV"
	},
	{
			"value": "HOLO IRON ON",
			"label": "HOLO IRON ON"
	},
	{
			"value": "HOLO PERM VINYL",
			"label": "HOLO PERM VINYL"
	},
	{
			"value": "HOLO REMV VINYL",
			"label": "HOLO REMV VINYL"
	},
	{
			"value": "HOL TULLE SPOOLS",
			"label": "HOL TULLE SPOOLS"
	},
	{
			"value": "HOM DEC",
			"label": "HOM DEC"
	},
	{
			"value": "HOME",
			"label": "HOME"
	},
	{
			"value": "HOMEBODY",
			"label": "HOMEBODY"
	},
	{
			"value": "HOME COMFORT/STYLE",
			"label": "HOME COMFORT/STYLE"
	},
	{
			"value": "HOME DEC",
			"label": "HOME DEC"
	},
	{
			"value": "HOME DEC BOOKS",
			"label": "HOME DEC BOOKS"
	},
	{
			"value": "HOME DECOR",
			"label": "HOME DECOR"
	},
	{
			"value": "HOME DÉCOR",
			"label": "HOME DÉCOR"
	},
	{
			"value": "HOME DECORATING",
			"label": "HOME DECORATING"
	},
	{
			"value": "HOME DECORATING-CR",
			"label": "HOME DECORATING-CR"
	},
	{
			"value": "HOME DECORATING-NO",
			"label": "HOME DECORATING-NO"
	},
	{
			"value": "HOME DECORATING-SC",
			"label": "HOME DECORATING-SC"
	},
	{
			"value": "HOME DECORATING-SW",
			"label": "HOME DECORATING-SW"
	},
	{
			"value": "HOME ELEGANCE",
			"label": "HOME ELEGANCE"
	},
	{
			"value": "HOME FOR HOLIDAYS",
			"label": "HOME FOR HOLIDAYS"
	},
	{
			"value": "HOME FOR THE HOLIDAY",
			"label": "HOME FOR THE HOLIDAY"
	},
	{
			"value": "HOME SPUN GIFTBAG",
			"label": "HOME SPUN GIFTBAG"
	},
	{
			"value": "HOME SPUN WRAP",
			"label": "HOME SPUN WRAP"
	},
	{
			"value": "HOMESTEAD",
			"label": "HOMESTEAD"
	},
	{
			"value": "HOME TOWEL",
			"label": "HOME TOWEL"
	},
	{
			"value": "HOME WB",
			"label": "HOME WB"
	},
	{
			"value": "HOME WD",
			"label": "HOME WD"
	},
	{
			"value": "HONEYCOMB",
			"label": "HONEYCOMB"
	},
	{
			"value": "HONEYCOMB BERRY",
			"label": "HONEYCOMB BERRY"
	},
	{
			"value": "HONEY SALE",
			"label": "HONEY SALE"
	},
	{
			"value": "HOODIE",
			"label": "HOODIE"
	},
	{
			"value": "HOODIES",
			"label": "HOODIES"
	},
	{
			"value": "HOOK",
			"label": "HOOK"
	},
	{
			"value": "HOOK CLASPS",
			"label": "HOOK CLASPS"
	},
	{
			"value": "HOOK & EYE",
			"label": "HOOK & EYE"
	},
	{
			"value": "HOOK & LOOP EA",
			"label": "HOOK & LOOP EA"
	},
	{
			"value": "HOOK MOUNTAIN",
			"label": "HOOK MOUNTAIN"
	},
	{
			"value": "HOOKS",
			"label": "HOOKS"
	},
	{
			"value": "HOOK TEEPEE",
			"label": "HOOK TEEPEE"
	},
	{
			"value": "HOOK WD",
			"label": "HOOK WD"
	},
	{
			"value": "HOOK WIRES",
			"label": "HOOK WIRES"
	},
	{
			"value": "HOOP",
			"label": "HOOP"
	},
	{
			"value": "HOPS",
			"label": "HOPS"
	},
	{
			"value": "HORIZONTAL BAG",
			"label": "HORIZONTAL BAG"
	},
	{
			"value": "HORN",
			"label": "HORN"
	},
	{
			"value": "HORROR",
			"label": "HORROR"
	},
	{
			"value": "HORSE",
			"label": "HORSE"
	},
	{
			"value": "HORSES",
			"label": "HORSES"
	},
	{
			"value": "HOSTA",
			"label": "HOSTA"
	},
	{
			"value": "HOSTA LEAF",
			"label": "HOSTA LEAF"
	},
	{
			"value": "HOT OFF THE PRESS",
			"label": "HOT OFF THE PRESS"
	},
	{
			"value": "HOT TOWEL",
			"label": "HOT TOWEL"
	},
	{
			"value": "HOU ASTROS",
			"label": "HOU ASTROS"
	},
	{
			"value": "HOUNDSTOOTH",
			"label": "HOUNDSTOOTH"
	},
	{
			"value": "HOUR GLASS",
			"label": "HOUR GLASS"
	},
	{
			"value": "HOUSE",
			"label": "HOUSE"
	},
	{
			"value": "HOUSE ACCESSORIES",
			"label": "HOUSE ACCESSORIES"
	},
	{
			"value": "HOUSE ACCESSORY",
			"label": "HOUSE ACCESSORY"
	},
	{
			"value": "HOUSECOAT",
			"label": "HOUSECOAT"
	},
	{
			"value": "HOUSE-MOUSE",
			"label": "HOUSE-MOUSE"
	},
	{
			"value": "HOUSE-MOUSE DESIGNS",
			"label": "HOUSE-MOUSE DESIGNS"
	},
	{
			"value": "HOUSES",
			"label": "HOUSES"
	},
	{
			"value": "HOWS IT GROWING",
			"label": "HOWS IT GROWING"
	},
	{
			"value": "HOW TO SHEET",
			"label": "HOW TO SHEET"
	},
	{
			"value": "HOYA LEAF",
			"label": "HOYA LEAF"
	},
	{
			"value": "HOYA LEAVES",
			"label": "HOYA LEAVES"
	},
	{
			"value": "H PINE",
			"label": "H PINE"
	},
	{
			"value": "H PINE AND POIN",
			"label": "H PINE AND POIN"
	},
	{
			"value": "H POIN AND BERRY",
			"label": "H POIN AND BERRY"
	},
	{
			"value": "H SLED",
			"label": "H SLED"
	},
	{
			"value": "H SLEIGH",
			"label": "H SLEIGH"
	},
	{
			"value": "HUCKLEBERRY",
			"label": "HUCKLEBERRY"
	},
	{
			"value": "HUDSON 43",
			"label": "HUDSON 43"
	},
	{
			"value": "HUDSON 44",
			"label": "HUDSON 44"
	},
	{
			"value": "HUDSON 45",
			"label": "HUDSON 45"
	},
	{
			"value": "HUDSON 46",
			"label": "HUDSON 46"
	},
	{
			"value": "HUDSON 47",
			"label": "HUDSON 47"
	},
	{
			"value": "HUMMINGBIRD",
			"label": "HUMMINGBIRD"
	},
	{
			"value": "HUMMINGBIRD COLLECTI",
			"label": "HUMMINGBIRD COLLECTI"
	},
	{
			"value": "HUNT & GATHER",
			"label": "HUNT & GATHER"
	},
	{
			"value": "HURRICANE",
			"label": "HURRICANE"
	},
	{
			"value": "HURRICANE HOLDER",
			"label": "HURRICANE HOLDER"
	},
	{
			"value": "HUSK",
			"label": "HUSK"
	},
	{
			"value": "HUSKY",
			"label": "HUSKY"
	},
	{
			"value": "HVY ELASTIC",
			"label": "HVY ELASTIC"
	},
	{
			"value": "HYACINTH",
			"label": "HYACINTH"
	},
	{
			"value": "HYBRID",
			"label": "HYBRID"
	},
	{
			"value": "HYD/EUC IN POT",
			"label": "HYD/EUC IN POT"
	},
	{
			"value": "HYDR",
			"label": "HYDR"
	},
	{
			"value": "HYDRA",
			"label": "HYDRA"
	},
	{
			"value": "HYDRA APPLE",
			"label": "HYDRA APPLE"
	},
	{
			"value": "HYDRA ARTICHOKE",
			"label": "HYDRA ARTICHOKE"
	},
	{
			"value": "HYDRA BERRY",
			"label": "HYDRA BERRY"
	},
	{
			"value": "HYDRA DAISY APPLE",
			"label": "HYDRA DAISY APPLE"
	},
	{
			"value": "HYDRAGENA",
			"label": "HYDRAGENA"
	},
	{
			"value": "HYDRANGEA",
			"label": "HYDRANGEA"
	},
	{
			"value": "HYDRANGEA BERRIES",
			"label": "HYDRANGEA BERRIES"
	},
	{
			"value": "HYDRANGEA RANUNCULUS",
			"label": "HYDRANGEA RANUNCULUS"
	},
	{
			"value": "HYDRANGEA ROSE ZINNI",
			"label": "HYDRANGEA ROSE ZINNI"
	},
	{
			"value": "HYDRATION",
			"label": "HYDRATION"
	},
	{
			"value": "HYDRG BRY",
			"label": "HYDRG BRY"
	},
	{
			"value": "HYGGE HOLIDAY",
			"label": "HYGGE HOLIDAY"
	},
	{
			"value": "HYGLOSS",
			"label": "HYGLOSS"
	},
	{
			"value": "ICE",
			"label": "ICE"
	},
	{
			"value": "ICE BUCKET",
			"label": "ICE BUCKET"
	},
	{
			"value": "ICE BUCKET TABLE",
			"label": "ICE BUCKET TABLE"
	},
	{
			"value": "ICE CREAM",
			"label": "ICE CREAM"
	},
	{
			"value": "ICE CREAM FC",
			"label": "ICE CREAM FC"
	},
	{
			"value": "ICED TREE",
			"label": "ICED TREE"
	},
	{
			"value": "ICE PRINCESS",
			"label": "ICE PRINCESS"
	},
	{
			"value": "ICICLE",
			"label": "ICICLE"
	},
	{
			"value": "ICICLE LIGHT",
			"label": "ICICLE LIGHT"
	},
	{
			"value": "ICICLE LIGHTS",
			"label": "ICICLE LIGHTS"
	},
	{
			"value": "ICING",
			"label": "ICING"
	},
	{
			"value": "ICING COLOR",
			"label": "ICING COLOR"
	},
	{
			"value": "ICING DEC",
			"label": "ICING DEC"
	},
	{
			"value": "ICING DECORATIONS",
			"label": "ICING DECORATIONS"
	},
	{
			"value": "ICING DECORATIVE",
			"label": "ICING DECORATIVE"
	},
	{
			"value": "ICING DECS",
			"label": "ICING DECS"
	},
	{
			"value": "ICING POUCH",
			"label": "ICING POUCH"
	},
	{
			"value": "ICING TOOLS",
			"label": "ICING TOOLS"
	},
	{
			"value": "ICON",
			"label": "ICON"
	},
	{
			"value": "ICONIC BRANDS",
			"label": "ICONIC BRANDS"
	},
	{
			"value": "ICON LIGHTS",
			"label": "ICON LIGHTS"
	},
	{
			"value": "ICON ORNAMENT",
			"label": "ICON ORNAMENT"
	},
	{
			"value": "ICONS",
			"label": "ICONS"
	},
	{
			"value": "ICON STRING LIGHTS",
			"label": "ICON STRING LIGHTS"
	},
	{
			"value": "ICON WALL DECOR",
			"label": "ICON WALL DECOR"
	},
	{
			"value": "ICON WINDCHIME",
			"label": "ICON WINDCHIME"
	},
	{
			"value": "ICY BRANCH",
			"label": "ICY BRANCH"
	},
	{
			"value": "IDEASA00",
			"label": "IDEASA00"
	},
	{
			"value": "IDEASB00",
			"label": "IDEASB00"
	},
	{
			"value": "IDEASC00",
			"label": "IDEASC00"
	},
	{
			"value": "IDYE POLY- PWD DISLV",
			"label": "IDYE POLY- PWD DISLV"
	},
	{
			"value": "IGNORE",
			"label": "IGNORE"
	},
	{
			"value": "IGUANA",
			"label": "IGUANA"
	},
	{
			"value": "IKAT",
			"label": "IKAT"
	},
	{
			"value": "I LOVE SHOPPING",
			"label": "I LOVE SHOPPING"
	},
	{
			"value": "I LOVE TO CREATE",
			"label": "I LOVE TO CREATE"
	},
	{
			"value": "ILTC- MARKERS",
			"label": "ILTC- MARKERS"
	},
	{
			"value": "IMAGE INK",
			"label": "IMAGE INK"
	},
	{
			"value": "IMAGINARY FRIENDS",
			"label": "IMAGINARY FRIENDS"
	},
	{
			"value": "IMAGINARY PLAY",
			"label": "IMAGINARY PLAY"
	},
	{
			"value": "IMAGINE INK",
			"label": "IMAGINE INK"
	},
	{
			"value": "IMAGINE INKE",
			"label": "IMAGINE INKE"
	},
	{
			"value": "IMAGINISCE",
			"label": "IMAGINISCE"
	},
	{
			"value": "IMPATIENTS",
			"label": "IMPATIENTS"
	},
	{
			"value": "IMPORT HLWN FC",
			"label": "IMPORT HLWN FC"
	},
	{
			"value": "IMPRESSART",
			"label": "IMPRESSART"
	},
	{
			"value": "IMPULSE",
			"label": "IMPULSE"
	},
	{
			"value": "IN BLOOM",
			"label": "IN BLOOM"
	},
	{
			"value": "INCENSE CONE",
			"label": "INCENSE CONE"
	},
	{
			"value": "INCENSE HOLDER",
			"label": "INCENSE HOLDER"
	},
	{
			"value": "INCENSE STICK",
			"label": "INCENSE STICK"
	},
	{
			"value": "INCENTIVE CHART",
			"label": "INCENTIVE CHART"
	},
	{
			"value": "INCENTIVE PAD",
			"label": "INCENTIVE PAD"
	},
	{
			"value": "INCENTIVE STICKERS",
			"label": "INCENTIVE STICKERS"
	},
	{
			"value": "INCLUSION",
			"label": "INCLUSION"
	},
	{
			"value": "INCLUSION PILLAR",
			"label": "INCLUSION PILLAR"
	},
	{
			"value": "INCLUSIONS",
			"label": "INCLUSIONS"
	},
	{
			"value": "INDEX CARD",
			"label": "INDEX CARD"
	},
	{
			"value": "INDIA BATIK",
			"label": "INDIA BATIK"
	},
	{
			"value": "INDIA COLLECTION",
			"label": "INDIA COLLECTION"
	},
	{
			"value": "INDIO",
			"label": "INDIO"
	},
	{
			"value": "INDO BATIK",
			"label": "INDO BATIK"
	},
	{
			"value": "INDOOR",
			"label": "INDOOR"
	},
	{
			"value": "INDOOR/OUTDOOR",
			"label": "INDOOR/OUTDOOR"
	},
	{
			"value": "INDOOR PILLOW",
			"label": "INDOOR PILLOW"
	},
	{
			"value": "INDOOR POTTERY",
			"label": "INDOOR POTTERY"
	},
	{
			"value": "INDYGO JUNCTION",
			"label": "INDYGO JUNCTION"
	},
	{
			"value": "INFANT TUTU",
			"label": "INFANT TUTU"
	},
	{
			"value": "INFE0001",
			"label": "INFE0001"
	},
	{
			"value": "INF INK PEN / MARKER",
			"label": "INF INK PEN / MARKER"
	},
	{
			"value": "INFLATABLE",
			"label": "INFLATABLE"
	},
	{
			"value": "INFLATABLES",
			"label": "INFLATABLES"
	},
	{
			"value": "IN FULL BLOOM",
			"label": "IN FULL BLOOM"
	},
	{
			"value": "INFUSIBLE INK",
			"label": "INFUSIBLE INK"
	},
	{
			"value": "INGLOW PILLAR",
			"label": "INGLOW PILLAR"
	},
	{
			"value": "INGREDIENTS",
			"label": "INGREDIENTS"
	},
	{
			"value": "INITIAL",
			"label": "INITIAL"
	},
	{
			"value": "INITIAL MUG",
			"label": "INITIAL MUG"
	},
	{
			"value": "INKADINKADO",
			"label": "INKADINKADO"
	},
	{
			"value": "INKTENSE",
			"label": "INKTENSE"
	},
	{
			"value": "IN LINE",
			"label": "IN LINE"
	},
	{
			"value": "IN LINE CONTAINER",
			"label": "IN LINE CONTAINER"
	},
	{
			"value": "INLINE (GALV)",
			"label": "INLINE (GALV)"
	},
	{
			"value": "INLINE GARLAND",
			"label": "INLINE GARLAND"
	},
	{
			"value": "INLINE OSO",
			"label": "INLINE OSO"
	},
	{
			"value": "INLINE TOWEL",
			"label": "INLINE TOWEL"
	},
	{
			"value": "INLINE (WORDS PANEL)",
			"label": "INLINE (WORDS PANEL)"
	},
	{
			"value": "INNOVATIVE SOLUTIONS",
			"label": "INNOVATIVE SOLUTIONS"
	},
	{
			"value": "IN SEARCH OF ADVENTU",
			"label": "IN SEARCH OF ADVENTU"
	},
	{
			"value": "INSECT",
			"label": "INSECT"
	},
	{
			"value": "INSECT LORE",
			"label": "INSECT LORE"
	},
	{
			"value": "INSECTS",
			"label": "INSECTS"
	},
	{
			"value": "INSECTS/BUGS",
			"label": "INSECTS/BUGS"
	},
	{
			"value": "INSERT CARD",
			"label": "INSERT CARD"
	},
	{
			"value": "INSERT CARD MAT",
			"label": "INSERT CARD MAT"
	},
	{
			"value": "INSPIRATION",
			"label": "INSPIRATION"
	},
	{
			"value": "INSPIRATIONAL",
			"label": "INSPIRATIONAL"
	},
	{
			"value": "INSPIRATIONAL BIRDHO",
			"label": "INSPIRATIONAL BIRDHO"
	},
	{
			"value": "INSPIRATION/GIFT",
			"label": "INSPIRATION/GIFT"
	},
	{
			"value": "INSTRUMENT",
			"label": "INSTRUMENT"
	},
	{
			"value": "INSULATE",
			"label": "INSULATE"
	},
	{
			"value": "INSUL-BRIGHT",
			"label": "INSUL-BRIGHT"
	},
	{
			"value": "INTER",
			"label": "INTER"
	},
	{
			"value": "INTER17",
			"label": "INTER17"
	},
	{
			"value": "INTERF00",
			"label": "INTERF00"
	},
	{
			"value": "INTERFACING",
			"label": "INTERFACING"
	},
	{
			"value": "INTERLOCK",
			"label": "INTERLOCK"
	},
	{
			"value": "INTERMEDIATE CURSIVE",
			"label": "INTERMEDIATE CURSIVE"
	},
	{
			"value": "INTERWEAVE PRESS",
			"label": "INTERWEAVE PRESS"
	},
	{
			"value": "INTO THE BLUE",
			"label": "INTO THE BLUE"
	},
	{
			"value": "IOWA",
			"label": "IOWA"
	},
	{
			"value": "IRIDESCENT",
			"label": "IRIDESCENT"
	},
	{
			"value": "IRIDESCENT VASE",
			"label": "IRIDESCENT VASE"
	},
	{
			"value": "IRIS",
			"label": "IRIS"
	},
	{
			"value": "IRISH",
			"label": "IRISH"
	},
	{
			"value": "IRISH COFFEE",
			"label": "IRISH COFFEE"
	},
	{
			"value": "IRON",
			"label": "IRON"
	},
	{
			"value": "IRON BASKET",
			"label": "IRON BASKET"
	},
	{
			"value": "IRON BOWL",
			"label": "IRON BOWL"
	},
	{
			"value": "IRON CANDLE HOLDER",
			"label": "IRON CANDLE HOLDER"
	},
	{
			"value": "IRONING BOARD COVER",
			"label": "IRONING BOARD COVER"
	},
	{
			"value": "IRON LVS BOWL",
			"label": "IRON LVS BOWL"
	},
	{
			"value": "IRON MEND PTCH",
			"label": "IRON MEND PTCH"
	},
	{
			"value": "IRON ON",
			"label": "IRON ON"
	},
	{
			"value": "IRON ON DESIGN",
			"label": "IRON ON DESIGN"
	},
	{
			"value": "IRON ON DESIGNS",
			"label": "IRON ON DESIGNS"
	},
	{
			"value": "IRON-ON HEM TAPE",
			"label": "IRON-ON HEM TAPE"
	},
	{
			"value": "IRON ON LETTR TRSFR",
			"label": "IRON ON LETTR TRSFR"
	},
	{
			"value": "IRON ON PTCH",
			"label": "IRON ON PTCH"
	},
	{
			"value": "IRON ON STONES",
			"label": "IRON ON STONES"
	},
	{
			"value": "IRON ON TRANSFER",
			"label": "IRON ON TRANSFER"
	},
	{
			"value": "IRON ON TRANSFR DSGN",
			"label": "IRON ON TRANSFR DSGN"
	},
	{
			"value": "IRON ON TRNFSR SHEET",
			"label": "IRON ON TRNFSR SHEET"
	},
	{
			"value": "IRON PAD",
			"label": "IRON PAD"
	},
	{
			"value": "IRON TOOL",
			"label": "IRON TOOL"
	},
	{
			"value": "IRON TRNSFR SHEET",
			"label": "IRON TRNSFR SHEET"
	},
	{
			"value": "IRON VASE",
			"label": "IRON VASE"
	},
	{
			"value": "IRON VELCRO",
			"label": "IRON VELCRO"
	},
	{
			"value": "IRONWOOD",
			"label": "IRONWOOD"
	},
	{
			"value": "IS SWEET",
			"label": "IS SWEET"
	},
	{
			"value": "IT'S A CELEBRATION!",
			"label": "IT'S A CELEBRATION!"
	},
	{
			"value": "ITY",
			"label": "ITY"
	},
	{
			"value": "IVORY PILLOW",
			"label": "IVORY PILLOW"
	},
	{
			"value": "IVORY RUFFLE",
			"label": "IVORY RUFFLE"
	},
	{
			"value": "IVY",
			"label": "IVY"
	},
	{
			"value": "IVY FERN",
			"label": "IVY FERN"
	},
	{
			"value": "JACK DEMPSEY",
			"label": "JACK DEMPSEY"
	},
	{
			"value": "JACKET",
			"label": "JACKET"
	},
	{
			"value": "JACKET / COAT",
			"label": "JACKET / COAT"
	},
	{
			"value": "JACKET/COAT",
			"label": "JACKET/COAT"
	},
	{
			"value": "JACK O LANTERN",
			"label": "JACK O LANTERN"
	},
	{
			"value": "JACOBEAN",
			"label": "JACOBEAN"
	},
	{
			"value": "JACQUARD",
			"label": "JACQUARD"
	},
	{
			"value": "JACQUARD-FABRIC PENS",
			"label": "JACQUARD-FABRIC PENS"
	},
	{
			"value": "JACQUARD KNIT",
			"label": "JACQUARD KNIT"
	},
	{
			"value": "JACQUARD-PAINT PACK",
			"label": "JACQUARD-PAINT PACK"
	},
	{
			"value": "JACQUARD PRODUCTS",
			"label": "JACQUARD PRODUCTS"
	},
	{
			"value": "JACQUARDS",
			"label": "JACQUARDS"
	},
	{
			"value": "JACQUARD- TIE DYE KT",
			"label": "JACQUARD- TIE DYE KT"
	},
	{
			"value": "JACQUARD WHT",
			"label": "JACQUARD WHT"
	},
	{
			"value": "JAD MAPLE",
			"label": "JAD MAPLE"
	},
	{
			"value": "JAMES THOMPSON",
			"label": "JAMES THOMPSON"
	},
	{
			"value": "JAN",
			"label": "JAN"
	},
	{
			"value": "JANETBASKET",
			"label": "JANETBASKET"
	},
	{
			"value": "JANLYNN",
			"label": "JANLYNN"
	},
	{
			"value": "JANOME",
			"label": "JANOME"
	},
	{
			"value": "JANUARY",
			"label": "JANUARY"
	},
	{
			"value": "JAPANESE MAPLE LV",
			"label": "JAPANESE MAPLE LV"
	},
	{
			"value": "JAQUARD",
			"label": "JAQUARD"
	},
	{
			"value": "JAR",
			"label": "JAR"
	},
	{
			"value": "JAR CANDLE",
			"label": "JAR CANDLE"
	},
	{
			"value": "JARDINIERE",
			"label": "JARDINIERE"
	},
	{
			"value": "JARS",
			"label": "JARS"
	},
	{
			"value": "JARS AND ACCESSORIES",
			"label": "JARS AND ACCESSORIES"
	},
	{
			"value": "JASMINE",
			"label": "JASMINE"
	},
	{
			"value": "JASMINE AND PATCHOUL",
			"label": "JASMINE AND PATCHOUL"
	},
	{
			"value": "JASMINE & PATCHOULI",
			"label": "JASMINE & PATCHOULI"
	},
	{
			"value": "JAWS",
			"label": "JAWS"
	},
	{
			"value": "JEANNETTE CREWS",
			"label": "JEANNETTE CREWS"
	},
	{
			"value": "JEDEFA00",
			"label": "JEDEFA00"
	},
	{
			"value": "JEGGING",
			"label": "JEGGING"
	},
	{
			"value": "JELLY",
			"label": "JELLY"
	},
	{
			"value": "JELLY BEAN",
			"label": "JELLY BEAN"
	},
	{
			"value": "JELLY BEANS",
			"label": "JELLY BEANS"
	},
	{
			"value": "JELLY CREW",
			"label": "JELLY CREW"
	},
	{
			"value": "JENNI BOWLIN STUDIO",
			"label": "JENNI BOWLIN STUDIO"
	},
	{
			"value": "JEREMIAH JUNCTION",
			"label": "JEREMIAH JUNCTION"
	},
	{
			"value": "JERI KELLY",
			"label": "JERI KELLY"
	},
	{
			"value": "JERSEY",
			"label": "JERSEY"
	},
	{
			"value": "JESSE JAMES",
			"label": "JESSE JAMES"
	},
	{
			"value": "JET SET",
			"label": "JET SET"
	},
	{
			"value": "JEWEL",
			"label": "JEWEL"
	},
	{
			"value": "JEWELA00",
			"label": "JEWELA00"
	},
	{
			"value": "JEWELB00",
			"label": "JEWELB00"
	},
	{
			"value": "JEWELC00",
			"label": "JEWELC00"
	},
	{
			"value": "JEWELE00",
			"label": "JEWELE00"
	},
	{
			"value": "JEWEL HEIST",
			"label": "JEWEL HEIST"
	},
	{
			"value": "JEWELRY",
			"label": "JEWELRY"
	},
	{
			"value": "JEWELRY & ACCESSORIE",
			"label": "JEWELRY & ACCESSORIE"
	},
	{
			"value": "JEWELRY BASICS",
			"label": "JEWELRY BASICS"
	},
	{
			"value": "JEWELRY & BEADING ED",
			"label": "JEWELRY & BEADING ED"
	},
	{
			"value": "JEWELRY BOOKS",
			"label": "JEWELRY BOOKS"
	},
	{
			"value": "JEWELRY CLASPS",
			"label": "JEWELRY CLASPS"
	},
	{
			"value": "JEWELRY CLEANER",
			"label": "JEWELRY CLEANER"
	},
	{
			"value": "JEWELRY KIT",
			"label": "JEWELRY KIT"
	},
	{
			"value": "JEWELRY NEEDLE",
			"label": "JEWELRY NEEDLE"
	},
	{
			"value": "JEWELRY STRAND",
			"label": "JEWELRY STRAND"
	},
	{
			"value": "JEWELS",
			"label": "JEWELS"
	},
	{
			"value": "JEWEL TONE RIBBON",
			"label": "JEWEL TONE RIBBON"
	},
	{
			"value": "JEWELY NEEDLES",
			"label": "JEWELY NEEDLES"
	},
	{
			"value": "JHB",
			"label": "JHB"
	},
	{
			"value": "JILLIBEAN SOUP",
			"label": "JILLIBEAN SOUP"
	},
	{
			"value": "JIMMIES",
			"label": "JIMMIES"
	},
	{
			"value": "JINGLE BELL",
			"label": "JINGLE BELL"
	},
	{
			"value": "JINGLE BELL GARLAND",
			"label": "JINGLE BELL GARLAND"
	},
	{
			"value": "JINGLE BELL HANGER",
			"label": "JINGLE BELL HANGER"
	},
	{
			"value": "JINGLE BELLS",
			"label": "JINGLE BELLS"
	},
	{
			"value": "JINGLE BELL SHAPED",
			"label": "JINGLE BELL SHAPED"
	},
	{
			"value": "JINGLE BELL WREATH",
			"label": "JINGLE BELL WREATH"
	},
	{
			"value": "JNGL BELL CASCADING",
			"label": "JNGL BELL CASCADING"
	},
	{
			"value": "JOANN.COM",
			"label": "JOANN.COM"
	},
	{
			"value": "JOANN.COM VALUE PACK",
			"label": "JOANN.COM VALUE PACK"
	},
	{
			"value": "JOANN ROLL",
			"label": "JOANN ROLL"
	},
	{
			"value": "JO-ANN STORES",
			"label": "JO-ANN STORES"
	},
	{
			"value": "JOHN DEERE",
			"label": "JOHN DEERE"
	},
	{
			"value": "JOLEES",
			"label": "JOLEES"
	},
	{
			"value": "JOURNAL",
			"label": "JOURNAL"
	},
	{
			"value": "JOY",
			"label": "JOY"
	},
	{
			"value": "JOY ADHESIVE",
			"label": "JOY ADHESIVE"
	},
	{
			"value": "JOY CUTTING MATERIAL",
			"label": "JOY CUTTING MATERIAL"
	},
	{
			"value": "JOY INF INK",
			"label": "JOY INF INK"
	},
	{
			"value": "JOY INF INK PEN/MARK",
			"label": "JOY INF INK PEN/MARK"
	},
	{
			"value": "JOY INSERT CARD",
			"label": "JOY INSERT CARD"
	},
	{
			"value": "JOY INSIGNIA",
			"label": "JOY INSIGNIA"
	},
	{
			"value": "JOY IRON ON",
			"label": "JOY IRON ON"
	},
	{
			"value": "JOY PEN / MARKER",
			"label": "JOY PEN / MARKER"
	},
	{
			"value": "JOY PERM VINYL",
			"label": "JOY PERM VINYL"
	},
	{
			"value": "JOY REMV VINYL",
			"label": "JOY REMV VINYL"
	},
	{
			"value": "JOY SMART VINYL",
			"label": "JOY SMART VINYL"
	},
	{
			"value": "JOY SMRT STENCIL",
			"label": "JOY SMRT STENCIL"
	},
	{
			"value": "JOY TRANSFER TAPE",
			"label": "JOY TRANSFER TAPE"
	},
	{
			"value": "JR CUB",
			"label": "JR CUB"
	},
	{
			"value": "JR CUB BAG",
			"label": "JR CUB BAG"
	},
	{
			"value": "J.R. EDWARDS",
			"label": "J.R. EDWARDS"
	},
	{
			"value": "JTABCA00",
			"label": "JTABCA00"
	},
	{
			"value": "JTDEFA00",
			"label": "JTDEFA00"
	},
	{
			"value": "JT - HEADER",
			"label": "JT - HEADER"
	},
	{
			"value": "JTWR1A00",
			"label": "JTWR1A00"
	},
	{
			"value": "JTWR2C00",
			"label": "JTWR2C00"
	},
	{
			"value": "JUDIKINS",
			"label": "JUDIKINS"
	},
	{
			"value": "JUG",
			"label": "JUG"
	},
	{
			"value": "JUICY BLACK CHERRIES",
			"label": "JUICY BLACK CHERRIES"
	},
	{
			"value": "JUICY BLACK CHERRY",
			"label": "JUICY BLACK CHERRY"
	},
	{
			"value": "JUICY COUTURE",
			"label": "JUICY COUTURE"
	},
	{
			"value": "JUL",
			"label": "JUL"
	},
	{
			"value": "JULIET CUTTING MACH",
			"label": "JULIET CUTTING MACH"
	},
	{
			"value": "JULY",
			"label": "JULY"
	},
	{
			"value": "JULY FY14 RTV",
			"label": "JULY FY14 RTV"
	},
	{
			"value": "JULYFY15RTV",
			"label": "JULYFY15RTV"
	},
	{
			"value": "JULYFY16RTV",
			"label": "JULYFY16RTV"
	},
	{
			"value": "JULY RTV",
			"label": "JULY RTV"
	},
	{
			"value": "JUMBO",
			"label": "JUMBO"
	},
	{
			"value": "JUMBO BAG",
			"label": "JUMBO BAG"
	},
	{
			"value": "JUMBO BELL",
			"label": "JUMBO BELL"
	},
	{
			"value": "JUMBO COLOR PAD",
			"label": "JUMBO COLOR PAD"
	},
	{
			"value": "JUMBO FINE CUT",
			"label": "JUMBO FINE CUT"
	},
	{
			"value": "JUMBO GIFT BAG",
			"label": "JUMBO GIFT BAG"
	},
	{
			"value": "JUMBO GLOSS",
			"label": "JUMBO GLOSS"
	},
	{
			"value": "JUMBO LED TEALIGHT",
			"label": "JUMBO LED TEALIGHT"
	},
	{
			"value": "JUMBO RICK RACK",
			"label": "JUMBO RICK RACK"
	},
	{
			"value": "JUMP RING",
			"label": "JUMP RING"
	},
	{
			"value": "JUMP RING KIT",
			"label": "JUMP RING KIT"
	},
	{
			"value": "JUMP RINGS",
			"label": "JUMP RINGS"
	},
	{
			"value": "JUMPSUIT",
			"label": "JUMPSUIT"
	},
	{
			"value": "JUN",
			"label": "JUN"
	},
	{
			"value": "JUNE",
			"label": "JUNE"
	},
	{
			"value": "JUNE FY14 RTV",
			"label": "JUNE FY14 RTV"
	},
	{
			"value": "JUNEFY15RTV",
			"label": "JUNEFY15RTV"
	},
	{
			"value": "JUNEFY16RTV",
			"label": "JUNEFY16RTV"
	},
	{
			"value": "JUNE TAILOR",
			"label": "JUNE TAILOR"
	},
	{
			"value": "JUNGLE",
			"label": "JUNGLE"
	},
	{
			"value": "JUNGLE BOYS",
			"label": "JUNGLE BOYS"
	},
	{
			"value": "JUNGLE CONTAINER",
			"label": "JUNGLE CONTAINER"
	},
	{
			"value": "JUSTRITE",
			"label": "JUSTRITE"
	},
	{
			"value": "JUTE",
			"label": "JUTE"
	},
	{
			"value": "JUTE ROPE",
			"label": "JUTE ROPE"
	},
	{
			"value": "JUVENILE",
			"label": "JUVENILE"
	},
	{
			"value": "JUVENILE APPAREL",
			"label": "JUVENILE APPAREL"
	},
	{
			"value": "JUVIE GIFTBAG",
			"label": "JUVIE GIFTBAG"
	},
	{
			"value": "JUVIE WRAP",
			"label": "JUVIE WRAP"
	},
	{
			"value": "JWBOUA00",
			"label": "JWBOUA00"
	},
	{
			"value": "K1C2",
			"label": "K1C2"
	},
	{
			"value": "K9 CAKERY",
			"label": "K9 CAKERY"
	},
	{
			"value": "KAHOOT",
			"label": "KAHOOT"
	},
	{
			"value": "KAISERCRAFT",
			"label": "KAISERCRAFT"
	},
	{
			"value": "KALEIDOSCOPE COLLECT",
			"label": "KALEIDOSCOPE COLLECT"
	},
	{
			"value": "KALEIDOSCOPE KIT",
			"label": "KALEIDOSCOPE KIT"
	},
	{
			"value": "KALINI",
			"label": "KALINI"
	},
	{
			"value": "KALMBACH",
			"label": "KALMBACH"
	},
	{
			"value": "KANBAN CRAFTS",
			"label": "KANBAN CRAFTS"
	},
	{
			"value": "KANDI CORP",
			"label": "KANDI CORP"
	},
	{
			"value": "KANSAS",
			"label": "KANSAS"
	},
	{
			"value": "KANSAS CITY STAR",
			"label": "KANSAS CITY STAR"
	},
	{
			"value": "KANSAS ST",
			"label": "KANSAS ST"
	},
	{
			"value": "KAPOK",
			"label": "KAPOK"
	},
	{
			"value": "KAPOW!",
			"label": "KAPOW!"
	},
	{
			"value": "KAREN FOSTER",
			"label": "KAREN FOSTER"
	},
	{
			"value": "KAREN KAY",
			"label": "KAREN KAY"
	},
	{
			"value": "KATHY DAVIS",
			"label": "KATHY DAVIS"
	},
	{
			"value": "K BELL",
			"label": "K BELL"
	},
	{
			"value": "K&COMPANY",
			"label": "K&COMPANY"
	},
	{
			"value": "KDARTB00",
			"label": "KDARTB00"
	},
	{
			"value": "KDEDUC00",
			"label": "KDEDUC00"
	},
	{
			"value": "KDEDUD00",
			"label": "KDEDUD00"
	},
	{
			"value": "KDFOMA00",
			"label": "KDFOMA00"
	},
	{
			"value": "KDKIT",
			"label": "KDKIT"
	},
	{
			"value": "KDKITC00",
			"label": "KDKITC00"
	},
	{
			"value": "KEEPSAKE CALICO HOLI",
			"label": "KEEPSAKE CALICO HOLI"
	},
	{
			"value": "KEG RIBBON",
			"label": "KEG RIBBON"
	},
	{
			"value": "KELLY RIPA",
			"label": "KELLY RIPA"
	},
	{
			"value": "KEL-TOY INC",
			"label": "KEL-TOY INC"
	},
	{
			"value": "KEY",
			"label": "KEY"
	},
	{
			"value": "KEYBOARD",
			"label": "KEYBOARD"
	},
	{
			"value": "KEYCHAIN",
			"label": "KEYCHAIN"
	},
	{
			"value": "KEYCHAINS",
			"label": "KEYCHAINS"
	},
	{
			"value": "KEY DOOR OPENER",
			"label": "KEY DOOR OPENER"
	},
	{
			"value": "KEY PILLOW",
			"label": "KEY PILLOW"
	},
	{
			"value": "KEY PILLOWS",
			"label": "KEY PILLOWS"
	},
	{
			"value": "KEY RING",
			"label": "KEY RING"
	},
	{
			"value": "KEYRING",
			"label": "KEYRING"
	},
	{
			"value": "KEY RINGS",
			"label": "KEY RINGS"
	},
	{
			"value": "KEYS",
			"label": "KEYS"
	},
	{
			"value": "KEYS BLUE",
			"label": "KEYS BLUE"
	},
	{
			"value": "KEY WALL",
			"label": "KEY WALL"
	},
	{
			"value": "KID CTRY",
			"label": "KID CTRY"
	},
	{
			"value": "KIDNEY EAR WIRES",
			"label": "KIDNEY EAR WIRES"
	},
	{
			"value": "KIDS",
			"label": "KIDS"
	},
	{
			"value": "KIDS ACTIVIT",
			"label": "KIDS ACTIVIT"
	},
	{
			"value": "KIDS ACTIVITES",
			"label": "KIDS ACTIVITES"
	},
	{
			"value": "KIDS ACTIVITIES",
			"label": "KIDS ACTIVITIES"
	},
	{
			"value": "KIDS ACTIVITY",
			"label": "KIDS ACTIVITY"
	},
	{
			"value": "KIDS ACTIVITY GAME",
			"label": "KIDS ACTIVITY GAME"
	},
	{
			"value": "KIDS ART ACTIVITY",
			"label": "KIDS ART ACTIVITY"
	},
	{
			"value": "KIDS BATH",
			"label": "KIDS BATH"
	},
	{
			"value": "KIDS BEACH",
			"label": "KIDS BEACH"
	},
	{
			"value": "KIDS BEADING/JEWELRY",
			"label": "KIDS BEADING/JEWELRY"
	},
	{
			"value": "KIDS BUG",
			"label": "KIDS BUG"
	},
	{
			"value": "KIDS CAMP",
			"label": "KIDS CAMP"
	},
	{
			"value": "KIDS CRAFT BOOKS",
			"label": "KIDS CRAFT BOOKS"
	},
	{
			"value": "KIDS CRAFT H FIXTURE",
			"label": "KIDS CRAFT H FIXTURE"
	},
	{
			"value": "KIDS CRAFT KIT",
			"label": "KIDS CRAFT KIT"
	},
	{
			"value": "KIDS CRAFTS",
			"label": "KIDS CRAFTS"
	},
	{
			"value": "KIDS CROCHET",
			"label": "KIDS CROCHET"
	},
	{
			"value": "KIDS EDUCATION",
			"label": "KIDS EDUCATION"
	},
	{
			"value": "KIDS EMBROIDERY",
			"label": "KIDS EMBROIDERY"
	},
	{
			"value": "KIDS ENTERTAINING",
			"label": "KIDS ENTERTAINING"
	},
	{
			"value": "KIDS FAIRY",
			"label": "KIDS FAIRY"
	},
	{
			"value": "KIDS FARM",
			"label": "KIDS FARM"
	},
	{
			"value": "KIDS GARDEN",
			"label": "KIDS GARDEN"
	},
	{
			"value": "KIDS GARDENING",
			"label": "KIDS GARDENING"
	},
	{
			"value": "KIDS GREEN",
			"label": "KIDS GREEN"
	},
	{
			"value": "KIDS KIT",
			"label": "KIDS KIT"
	},
	{
			"value": "KIDS KNITTING",
			"label": "KIDS KNITTING"
	},
	{
			"value": "KIDS PAINT",
			"label": "KIDS PAINT"
	},
	{
			"value": "KIDS PINK",
			"label": "KIDS PINK"
	},
	{
			"value": "KID/SPORTS",
			"label": "KID/SPORTS"
	},
	{
			"value": "KIDS PURPLE",
			"label": "KIDS PURPLE"
	},
	{
			"value": "KIDS PUZZLE",
			"label": "KIDS PUZZLE"
	},
	{
			"value": "KIDS QUILTING",
			"label": "KIDS QUILTING"
	},
	{
			"value": "KIDS RAIN GEAR",
			"label": "KIDS RAIN GEAR"
	},
	{
			"value": "KIDS SAFARI",
			"label": "KIDS SAFARI"
	},
	{
			"value": "KIDS SCHOOL",
			"label": "KIDS SCHOOL"
	},
	{
			"value": "KIDS SEWING",
			"label": "KIDS SEWING"
	},
	{
			"value": "KIDS/SPORTS",
			"label": "KIDS/SPORTS"
	},
	{
			"value": "KIDS SUMMER",
			"label": "KIDS SUMMER"
	},
	{
			"value": "KIDS WATERMELON",
			"label": "KIDS WATERMELON"
	},
	{
			"value": "KIDS WRAP",
			"label": "KIDS WRAP"
	},
	{
			"value": "KIDZLABS STEM KIT",
			"label": "KIDZLABS STEM KIT"
	},
	{
			"value": "KI MEMORIES",
			"label": "KI MEMORIES"
	},
	{
			"value": "KINDERGARTEN",
			"label": "KINDERGARTEN"
	},
	{
			"value": "KINDERGARTEN EDUCATI",
			"label": "KINDERGARTEN EDUCATI"
	},
	{
			"value": "KINETIC STAKE",
			"label": "KINETIC STAKE"
	},
	{
			"value": "KING SHAM",
			"label": "KING SHAM"
	},
	{
			"value": "KISSING BALL",
			"label": "KISSING BALL"
	},
	{
			"value": "KIT",
			"label": "KIT"
	},
	{
			"value": "KITCHEN",
			"label": "KITCHEN"
	},
	{
			"value": "KITCHEN AID",
			"label": "KITCHEN AID"
	},
	{
			"value": "KITCHEN DÉCOR",
			"label": "KITCHEN DÉCOR"
	},
	{
			"value": "KITCHEN MACHINES",
			"label": "KITCHEN MACHINES"
	},
	{
			"value": "KITCHEN TEXTILES",
			"label": "KITCHEN TEXTILES"
	},
	{
			"value": "KITCHEN TOWEL",
			"label": "KITCHEN TOWEL"
	},
	{
			"value": "KITCHENWARE",
			"label": "KITCHENWARE"
	},
	{
			"value": "KITE",
			"label": "KITE"
	},
	{
			"value": "KITS",
			"label": "KITS"
	},
	{
			"value": "KLINGS",
			"label": "KLINGS"
	},
	{
			"value": "KLUTZ",
			"label": "KLUTZ"
	},
	{
			"value": "KNEE HIGH",
			"label": "KNEE HIGH"
	},
	{
			"value": "KNEELER",
			"label": "KNEELER"
	},
	{
			"value": "KNEELING PAD",
			"label": "KNEELING PAD"
	},
	{
			"value": "KNIFE",
			"label": "KNIFE"
	},
	{
			"value": "KNIT",
			"label": "KNIT"
	},
	{
			"value": "KNIT APPAREL",
			"label": "KNIT APPAREL"
	},
	{
			"value": "KNIT & CORCHET ED",
			"label": "KNIT & CORCHET ED"
	},
	{
			"value": "KNIT & CROCHET ED",
			"label": "KNIT & CROCHET ED"
	},
	{
			"value": "KNIT DENIM",
			"label": "KNIT DENIM"
	},
	{
			"value": "KNIT ELASTIC",
			"label": "KNIT ELASTIC"
	},
	{
			"value": "KNIT HAPPY",
			"label": "KNIT HAPPY"
	},
	{
			"value": "KNIT HAT",
			"label": "KNIT HAT"
	},
	{
			"value": "KNIT OPPORTUNITY BUY",
			"label": "KNIT OPPORTUNITY BUY"
	},
	{
			"value": "KNIT PRINT",
			"label": "KNIT PRINT"
	},
	{
			"value": "KNITS",
			"label": "KNITS"
	},
	{
			"value": "KNIT SOLID",
			"label": "KNIT SOLID"
	},
	{
			"value": "KNIT STOCKING",
			"label": "KNIT STOCKING"
	},
	{
			"value": "KNIT STRIPE",
			"label": "KNIT STRIPE"
	},
	{
			"value": "KNITTING",
			"label": "KNITTING"
	},
	{
			"value": "KNITTING AT KNOON DE",
			"label": "KNITTING AT KNOON DE"
	},
	{
			"value": "KNITTING BOOKLET",
			"label": "KNITTING BOOKLET"
	},
	{
			"value": "KNITTING HUMOR/STORY",
			"label": "KNITTING HUMOR/STORY"
	},
	{
			"value": "KNITTING KORNER",
			"label": "KNITTING KORNER"
	},
	{
			"value": "KNITTING SOFTWARE",
			"label": "KNITTING SOFTWARE"
	},
	{
			"value": "KNIT TREE SKIRT",
			"label": "KNIT TREE SKIRT"
	},
	{
			"value": "KNITWHITS",
			"label": "KNITWHITS"
	},
	{
			"value": "KNIT YOUTH",
			"label": "KNIT YOUTH"
	},
	{
			"value": "KNOBS",
			"label": "KNOBS"
	},
	{
			"value": "KNOTS 18 IVR",
			"label": "KNOTS 18 IVR"
	},
	{
			"value": "KNOTTING CORD",
			"label": "KNOTTING CORD"
	},
	{
			"value": "KOALA",
			"label": "KOALA"
	},
	{
			"value": "KONA",
			"label": "KONA"
	},
	{
			"value": "KOOZIE",
			"label": "KOOZIE"
	},
	{
			"value": "KRAFT",
			"label": "KRAFT"
	},
	{
			"value": "KRAFT BAG BUNDLE",
			"label": "KRAFT BAG BUNDLE"
	},
	{
			"value": "KRAFT BAGS",
			"label": "KRAFT BAGS"
	},
	{
			"value": "KRAFT BOARD",
			"label": "KRAFT BOARD"
	},
	{
			"value": "KRAFT HEADER",
			"label": "KRAFT HEADER"
	},
	{
			"value": "KRAFT WRAP",
			"label": "KRAFT WRAP"
	},
	{
			"value": "KREINIK",
			"label": "KREINIK"
	},
	{
			"value": "KRYLON",
			"label": "KRYLON"
	},
	{
			"value": "KT",
			"label": "KT"
	},
	{
			"value": "KTCHEN TOWEL",
			"label": "KTCHEN TOWEL"
	},
	{
			"value": "KUMIHIMO",
			"label": "KUMIHIMO"
	},
	{
			"value": "KUNIN / FOSS",
			"label": "KUNIN / FOSS"
	},
	{
			"value": "KWANZA",
			"label": "KWANZA"
	},
	{
			"value": "KWANZAA",
			"label": "KWANZAA"
	},
	{
			"value": "LABELS",
			"label": "LABELS"
	},
	{
			"value": "LABLANCHE",
			"label": "LABLANCHE"
	},
	{
			"value": "LABTERN",
			"label": "LABTERN"
	},
	{
			"value": "LACE",
			"label": "LACE"
	},
	{
			"value": "LACE CENTERPIECE",
			"label": "LACE CENTERPIECE"
	},
	{
			"value": "LACE DOOR PANEL",
			"label": "LACE DOOR PANEL"
	},
	{
			"value": "LACE ENTERTAINING",
			"label": "LACE ENTERTAINING"
	},
	{
			"value": "LACE MANTEL PIECE",
			"label": "LACE MANTEL PIECE"
	},
	{
			"value": "LACE TABLECLOTH",
			"label": "LACE TABLECLOTH"
	},
	{
			"value": "LACE TABLERUNNER",
			"label": "LACE TABLERUNNER"
	},
	{
			"value": "LACE TABLE RUNNERS",
			"label": "LACE TABLE RUNNERS"
	},
	{
			"value": "LACE VINYL",
			"label": "LACE VINYL"
	},
	{
			"value": "LACING",
			"label": "LACING"
	},
	{
			"value": "LACIS",
			"label": "LACIS"
	},
	{
			"value": "LA COLLECTION VALUE",
			"label": "LA COLLECTION VALUE"
	},
	{
			"value": "LACQUERED WIRE",
			"label": "LACQUERED WIRE"
	},
	{
			"value": "LADDER",
			"label": "LADDER"
	},
	{
			"value": "LADIES",
			"label": "LADIES"
	},
	{
			"value": "LADIES GARDEN",
			"label": "LADIES GARDEN"
	},
	{
			"value": "LADIES GARDENING",
			"label": "LADIES GARDENING"
	},
	{
			"value": "LADIES UTILITY",
			"label": "LADIES UTILITY"
	},
	{
			"value": "LADY BUG",
			"label": "LADY BUG"
	},
	{
			"value": "LADYBUG",
			"label": "LADYBUG"
	},
	{
			"value": "LADY BUGS",
			"label": "LADY BUGS"
	},
	{
			"value": "LADYBUGS",
			"label": "LADYBUGS"
	},
	{
			"value": "LADYBUGS FLNL",
			"label": "LADYBUGS FLNL"
	},
	{
			"value": "LAGURUS",
			"label": "LAGURUS"
	},
	{
			"value": "LAKE CITY CRAFT",
			"label": "LAKE CITY CRAFT"
	},
	{
			"value": "LAKE TIME",
			"label": "LAKE TIME"
	},
	{
			"value": "LA LA LAND CRAFTS",
			"label": "LA LA LAND CRAFTS"
	},
	{
			"value": "LAMB",
			"label": "LAMB"
	},
	{
			"value": "LAMBS EAR",
			"label": "LAMBS EAR"
	},
	{
			"value": "LAMDEF01",
			"label": "LAMDEF01"
	},
	{
			"value": "LAMDF01",
			"label": "LAMDF01"
	},
	{
			"value": "LAMINATING",
			"label": "LAMINATING"
	},
	{
			"value": "LAMODE",
			"label": "LAMODE"
	},
	{
			"value": "LAMP",
			"label": "LAMP"
	},
	{
			"value": "LAMP SHADE",
			"label": "LAMP SHADE"
	},
	{
			"value": "LAMPS/SHADES",
			"label": "LAMPS/SHADES"
	},
	{
			"value": "LAMP WORK",
			"label": "LAMP WORK"
	},
	{
			"value": "LANDMARK",
			"label": "LANDMARK"
	},
	{
			"value": "LAND OF WHIMZIE BLOO",
			"label": "LAND OF WHIMZIE BLOO"
	},
	{
			"value": "LAND OF WHIMZIE SAFA",
			"label": "LAND OF WHIMZIE SAFA"
	},
	{
			"value": "LANDSCAPE",
			"label": "LANDSCAPE"
	},
	{
			"value": "LANTEN",
			"label": "LANTEN"
	},
	{
			"value": "LANTERN",
			"label": "LANTERN"
	},
	{
			"value": "LANTERN BLACK",
			"label": "LANTERN BLACK"
	},
	{
			"value": "LANTERN BRONZE",
			"label": "LANTERN BRONZE"
	},
	{
			"value": "LANTERN FLOWER",
			"label": "LANTERN FLOWER"
	},
	{
			"value": "LANTERN FLR",
			"label": "LANTERN FLR"
	},
	{
			"value": "LANTERN PILLAR",
			"label": "LANTERN PILLAR"
	},
	{
			"value": "LANTERNS",
			"label": "LANTERNS"
	},
	{
			"value": "LANTERN SILVER",
			"label": "LANTERN SILVER"
	},
	{
			"value": "LANTERN SOLAR GLASS",
			"label": "LANTERN SOLAR GLASS"
	},
	{
			"value": "LANTERN SOLAR METAL",
			"label": "LANTERN SOLAR METAL"
	},
	{
			"value": "LANTERN WHITE",
			"label": "LANTERN WHITE"
	},
	{
			"value": "LANTERN WITH CANDLE",
			"label": "LANTERN WITH CANDLE"
	},
	{
			"value": "LANYARD HOOKS",
			"label": "LANYARD HOOKS"
	},
	{
			"value": "LAP DESK",
			"label": "LAP DESK"
	},
	{
			"value": "LAPEL",
			"label": "LAPEL"
	},
	{
			"value": "LA PETITE",
			"label": "LA PETITE"
	},
	{
			"value": "LAP QUILT",
			"label": "LAP QUILT"
	},
	{
			"value": "LARGE",
			"label": "LARGE"
	},
	{
			"value": "LARGE ARROW",
			"label": "LARGE ARROW"
	},
	{
			"value": "LARGE BAG",
			"label": "LARGE BAG"
	},
	{
			"value": "LARGE BAGS",
			"label": "LARGE BAGS"
	},
	{
			"value": "LARGE BANNER",
			"label": "LARGE BANNER"
	},
	{
			"value": "LARGE BOW",
			"label": "LARGE BOW"
	},
	{
			"value": "LARGE CRATE",
			"label": "LARGE CRATE"
	},
	{
			"value": "LARGE CUT OUT",
			"label": "LARGE CUT OUT"
	},
	{
			"value": "LARGE DECOR",
			"label": "LARGE DECOR"
	},
	{
			"value": "LARGE DIFFUSER",
			"label": "LARGE DIFFUSER"
	},
	{
			"value": "LARGE GIFT BAG",
			"label": "LARGE GIFT BAG"
	},
	{
			"value": "LARGE PINWHEEL",
			"label": "LARGE PINWHEEL"
	},
	{
			"value": "LARGE PUMPKIN",
			"label": "LARGE PUMPKIN"
	},
	{
			"value": "LARGE SCALE",
			"label": "LARGE SCALE"
	},
	{
			"value": "LARGE SCALE PRINTS",
			"label": "LARGE SCALE PRINTS"
	},
	{
			"value": "LARGE STAKE",
			"label": "LARGE STAKE"
	},
	{
			"value": "LARGE WALL DECOR",
			"label": "LARGE WALL DECOR"
	},
	{
			"value": "LARKSPUR",
			"label": "LARKSPUR"
	},
	{
			"value": "LASER CRATE",
			"label": "LASER CRATE"
	},
	{
			"value": "LASER WOOD",
			"label": "LASER WOOD"
	},
	{
			"value": "LAST CHANCE",
			"label": "LAST CHANCE"
	},
	{
			"value": "LASTING IMPRESSIONS",
			"label": "LASTING IMPRESSIONS"
	},
	{
			"value": "LATCH HOOK",
			"label": "LATCH HOOK"
	},
	{
			"value": "LATCHHOOK",
			"label": "LATCHHOOK"
	},
	{
			"value": "LATIN",
			"label": "LATIN"
	},
	{
			"value": "LATTE MUG",
			"label": "LATTE MUG"
	},
	{
			"value": "LATTICE",
			"label": "LATTICE"
	},
	{
			"value": "LAUGUING MONKEY",
			"label": "LAUGUING MONKEY"
	},
	{
			"value": "LAUNDRY BAG",
			"label": "LAUNDRY BAG"
	},
	{
			"value": "LAUR DIY",
			"label": "LAUR DIY"
	},
	{
			"value": "LAUR DIY FASHION",
			"label": "LAUR DIY FASHION"
	},
	{
			"value": "LAUREL BRY SPRY",
			"label": "LAUREL BRY SPRY"
	},
	{
			"value": "LAUREL BURCH",
			"label": "LAUREL BURCH"
	},
	{
			"value": "LAUREL BUSH BRY ASST",
			"label": "LAUREL BUSH BRY ASST"
	},
	{
			"value": "LAUREL/GRAPE/IVY MAP",
			"label": "LAUREL/GRAPE/IVY MAP"
	},
	{
			"value": "LAVA",
			"label": "LAVA"
	},
	{
			"value": "LA VALUE POG",
			"label": "LA VALUE POG"
	},
	{
			"value": "LA VALUE WOOD",
			"label": "LA VALUE WOOD"
	},
	{
			"value": "LA VALUE WOOD POG",
			"label": "LA VALUE WOOD POG"
	},
	{
			"value": "LAVENDAR",
			"label": "LAVENDAR"
	},
	{
			"value": "LAVENDER",
			"label": "LAVENDER"
	},
	{
			"value": "LAVENDER AND CEDARWO",
			"label": "LAVENDER AND CEDARWO"
	},
	{
			"value": "LAWN",
			"label": "LAWN"
	},
	{
			"value": "LAYERING",
			"label": "LAYERING"
	},
	{
			"value": "LAYING PLUSH",
			"label": "LAYING PLUSH"
	},
	{
			"value": "LC AMERICAN",
			"label": "LC AMERICAN"
	},
	{
			"value": "LEADER PAPER PRODUCT",
			"label": "LEADER PAPER PRODUCT"
	},
	{
			"value": "LEAF",
			"label": "LEAF"
	},
	{
			"value": "LEAF AND PINECONE",
			"label": "LEAF AND PINECONE"
	},
	{
			"value": "LEAF BALL",
			"label": "LEAF BALL"
	},
	{
			"value": "LEAF CHAIN",
			"label": "LEAF CHAIN"
	},
	{
			"value": "LEAF CONTAINER",
			"label": "LEAF CONTAINER"
	},
	{
			"value": "LEAF FRAME",
			"label": "LEAF FRAME"
	},
	{
			"value": "LEAF HOOK",
			"label": "LEAF HOOK"
	},
	{
			"value": "LEAF PICK",
			"label": "LEAF PICK"
	},
	{
			"value": "LEAF PILLOW",
			"label": "LEAF PILLOW"
	},
	{
			"value": "LEAF PLATE",
			"label": "LEAF PLATE"
	},
	{
			"value": "LEAF PMPKN",
			"label": "LEAF PMPKN"
	},
	{
			"value": "LEAFS",
			"label": "LEAFS"
	},
	{
			"value": "LEAF TRAY",
			"label": "LEAF TRAY"
	},
	{
			"value": "LEAF WD",
			"label": "LEAF WD"
	},
	{
			"value": "LEARNING ABCS",
			"label": "LEARNING ABCS"
	},
	{
			"value": "LEARNING CHART",
			"label": "LEARNING CHART"
	},
	{
			"value": "LEARNING NUMBERS",
			"label": "LEARNING NUMBERS"
	},
	{
			"value": "LEARNING SUPPLIES",
			"label": "LEARNING SUPPLIES"
	},
	{
			"value": "LEARN TO CROCHET",
			"label": "LEARN TO CROCHET"
	},
	{
			"value": "LEARN TO KNIT",
			"label": "LEARN TO KNIT"
	},
	{
			"value": "LEARN TO QUILT",
			"label": "LEARN TO QUILT"
	},
	{
			"value": "LEASH COLLAR",
			"label": "LEASH COLLAR"
	},
	{
			"value": "LEATHER",
			"label": "LEATHER"
	},
	{
			"value": "LEATHER ACCESSORIES",
			"label": "LEATHER ACCESSORIES"
	},
	{
			"value": "LEATHER BRACELET",
			"label": "LEATHER BRACELET"
	},
	{
			"value": "LEATHER BRACELET TOW",
			"label": "LEATHER BRACELET TOW"
	},
	{
			"value": "LEATHER BRACLET TOWE",
			"label": "LEATHER BRACLET TOWE"
	},
	{
			"value": "LEATHER COMPONENTS",
			"label": "LEATHER COMPONENTS"
	},
	{
			"value": "LEATHER FACTORY",
			"label": "LEATHER FACTORY"
	},
	{
			"value": "LEATHER FERN",
			"label": "LEATHER FERN"
	},
	{
			"value": "LEATHER GRY",
			"label": "LEATHER GRY"
	},
	{
			"value": "LEATHER JEWELRY TOWE",
			"label": "LEATHER JEWELRY TOWE"
	},
	{
			"value": "LEATHER JINGLE BELL",
			"label": "LEATHER JINGLE BELL"
	},
	{
			"value": "LEATHER LOOK",
			"label": "LEATHER LOOK"
	},
	{
			"value": "LEATHER PATCH",
			"label": "LEATHER PATCH"
	},
	{
			"value": "LEATHER PILLOW",
			"label": "LEATHER PILLOW"
	},
	{
			"value": "LEATHER PUMPKIN",
			"label": "LEATHER PUMPKIN"
	},
	{
			"value": "LEATHER TOOL",
			"label": "LEATHER TOOL"
	},
	{
			"value": "LEATHER TOOLS",
			"label": "LEATHER TOOLS"
	},
	{
			"value": "LEATHER WD",
			"label": "LEATHER WD"
	},
	{
			"value": "LEAVE",
			"label": "LEAVE"
	},
	{
			"value": "LEAVES",
			"label": "LEAVES"
	},
	{
			"value": "LEAVES TOWEL",
			"label": "LEAVES TOWEL"
	},
	{
			"value": "LEAVES/VINES",
			"label": "LEAVES/VINES"
	},
	{
			"value": "LEAVES WD",
			"label": "LEAVES WD"
	},
	{
			"value": "LED",
			"label": "LED"
	},
	{
			"value": "LED BOTTLE",
			"label": "LED BOTTLE"
	},
	{
			"value": "LED BOW",
			"label": "LED BOW"
	},
	{
			"value": "LED BRANCHES",
			"label": "LED BRANCHES"
	},
	{
			"value": "LED BROOM",
			"label": "LED BROOM"
	},
	{
			"value": "LED CANDLE",
			"label": "LED CANDLE"
	},
	{
			"value": "LED CANDLES",
			"label": "LED CANDLES"
	},
	{
			"value": "LED CLEAR",
			"label": "LED CLEAR"
	},
	{
			"value": "LED COLOR",
			"label": "LED COLOR"
	},
	{
			"value": "LED DECOR",
			"label": "LED DECOR"
	},
	{
			"value": "LED DÉCOR",
			"label": "LED DÉCOR"
	},
	{
			"value": "LED DECORATIVE",
			"label": "LED DECORATIVE"
	},
	{
			"value": "LED GARLAND",
			"label": "LED GARLAND"
	},
	{
			"value": "LED GEL CLING",
			"label": "LED GEL CLING"
	},
	{
			"value": "LED GLOBE",
			"label": "LED GLOBE"
	},
	{
			"value": "LED HOUSE",
			"label": "LED HOUSE"
	},
	{
			"value": "LED HURRICANE",
			"label": "LED HURRICANE"
	},
	{
			"value": "LED KEY LANTERN",
			"label": "LED KEY LANTERN"
	},
	{
			"value": "LED LANTERN",
			"label": "LED LANTERN"
	},
	{
			"value": "LED LIGHT",
			"label": "LED LIGHT"
	},
	{
			"value": "LED LIGHTS",
			"label": "LED LIGHTS"
	},
	{
			"value": "LED LIGHTS FIGURAL",
			"label": "LED LIGHTS FIGURAL"
	},
	{
			"value": "LED NOVELTY",
			"label": "LED NOVELTY"
	},
	{
			"value": "LED PILLAR",
			"label": "LED PILLAR"
	},
	{
			"value": "LED PILLAR CANDLE",
			"label": "LED PILLAR CANDLE"
	},
	{
			"value": "LED PINEAPPLE",
			"label": "LED PINEAPPLE"
	},
	{
			"value": "LED PORCH DÉCOR",
			"label": "LED PORCH DÉCOR"
	},
	{
			"value": "LED POTION BOTTLE",
			"label": "LED POTION BOTTLE"
	},
	{
			"value": "LED PUMPKIN",
			"label": "LED PUMPKIN"
	},
	{
			"value": "LED SIGN",
			"label": "LED SIGN"
	},
	{
			"value": "LED SKULL",
			"label": "LED SKULL"
	},
	{
			"value": "LED STAR",
			"label": "LED STAR"
	},
	{
			"value": "LED TABLE DECOR",
			"label": "LED TABLE DECOR"
	},
	{
			"value": "LED TABLE DÉCOR",
			"label": "LED TABLE DÉCOR"
	},
	{
			"value": "LED TEALIGHT",
			"label": "LED TEALIGHT"
	},
	{
			"value": "LED TIKI",
			"label": "LED TIKI"
	},
	{
			"value": "LED TREE",
			"label": "LED TREE"
	},
	{
			"value": "LED TREES",
			"label": "LED TREES"
	},
	{
			"value": "LED TT DÉCOR",
			"label": "LED TT DÉCOR"
	},
	{
			"value": "LED TUTU",
			"label": "LED TUTU"
	},
	{
			"value": "LED UNDER GLASS",
			"label": "LED UNDER GLASS"
	},
	{
			"value": "LED VOTIVE",
			"label": "LED VOTIVE"
	},
	{
			"value": "LED WALL DECOR",
			"label": "LED WALL DECOR"
	},
	{
			"value": "LED WALL DÉCOR",
			"label": "LED WALL DÉCOR"
	},
	{
			"value": "LED WB",
			"label": "LED WB"
	},
	{
			"value": "LED WD DÉCOR",
			"label": "LED WD DÉCOR"
	},
	{
			"value": "LED WINGS",
			"label": "LED WINGS"
	},
	{
			"value": "LED WOOD HOUSE",
			"label": "LED WOOD HOUSE"
	},
	{
			"value": "LED WORD BLOCK",
			"label": "LED WORD BLOCK"
	},
	{
			"value": "LED WREATH",
			"label": "LED WREATH"
	},
	{
			"value": "LED YARD STAKE",
			"label": "LED YARD STAKE"
	},
	{
			"value": "LEGACY STUDIO",
			"label": "LEGACY STUDIO"
	},
	{
			"value": "LEGO BRICKHEADZ",
			"label": "LEGO BRICKHEADZ"
	},
	{
			"value": "LEGO CITY",
			"label": "LEGO CITY"
	},
	{
			"value": "LEGO CLASSIC",
			"label": "LEGO CLASSIC"
	},
	{
			"value": "LEGO CREATOR",
			"label": "LEGO CREATOR"
	},
	{
			"value": "LEGO DISNEY PRINCESS",
			"label": "LEGO DISNEY PRINCESS"
	},
	{
			"value": "LEGO DOTS",
			"label": "LEGO DOTS"
	},
	{
			"value": "LEGO DUPLO",
			"label": "LEGO DUPLO"
	},
	{
			"value": "LEGO FRIENDS",
			"label": "LEGO FRIENDS"
	},
	{
			"value": "LEGO HARRY POTTER",
			"label": "LEGO HARRY POTTER"
	},
	{
			"value": "LEGO MINECRAFT",
			"label": "LEGO MINECRAFT"
	},
	{
			"value": "LEGO MINIFIGURES",
			"label": "LEGO MINIFIGURES"
	},
	{
			"value": "LEGO PUZZLE",
			"label": "LEGO PUZZLE"
	},
	{
			"value": "LEGO RECRUITMENT BAG",
			"label": "LEGO RECRUITMENT BAG"
	},
	{
			"value": "LEGO SPEED CHAMPIONS",
			"label": "LEGO SPEED CHAMPIONS"
	},
	{
			"value": "LEGO STAR WARS",
			"label": "LEGO STAR WARS"
	},
	{
			"value": "LEGO SUPER HEROES",
			"label": "LEGO SUPER HEROES"
	},
	{
			"value": "LEGO TECHNIC",
			"label": "LEGO TECHNIC"
	},
	{
			"value": "LEGS LG",
			"label": "LEGS LG"
	},
	{
			"value": "LEGS SM",
			"label": "LEGS SM"
	},
	{
			"value": "LEISURE ARTS",
			"label": "LEISURE ARTS"
	},
	{
			"value": "LEMON",
			"label": "LEMON"
	},
	{
			"value": "LEMONGRASS AND CORIA",
			"label": "LEMONGRASS AND CORIA"
	},
	{
			"value": "LEMON LEAF",
			"label": "LEMON LEAF"
	},
	{
			"value": "LEMON LIME",
			"label": "LEMON LIME"
	},
	{
			"value": "LEMONS",
			"label": "LEMONS"
	},
	{
			"value": "LEMONS TOWEL",
			"label": "LEMONS TOWEL"
	},
	{
			"value": "LEMUR",
			"label": "LEMUR"
	},
	{
			"value": "LENTICULAR PRINT",
			"label": "LENTICULAR PRINT"
	},
	{
			"value": "LEOP0ARD",
			"label": "LEOP0ARD"
	},
	{
			"value": "LEOPARD",
			"label": "LEOPARD"
	},
	{
			"value": "LEOTARD",
			"label": "LEOTARD"
	},
	{
			"value": "LE PEN",
			"label": "LE PEN"
	},
	{
			"value": "LETRASET",
			"label": "LETRASET"
	},
	{
			"value": "LETTER APPLIQUE",
			"label": "LETTER APPLIQUE"
	},
	{
			"value": "LETTER FOAM STICKER",
			"label": "LETTER FOAM STICKER"
	},
	{
			"value": "LETTERS",
			"label": "LETTERS"
	},
	{
			"value": "LETTERS & NUMBERS",
			"label": "LETTERS & NUMBERS"
	},
	{
			"value": "LETTER TILE",
			"label": "LETTER TILE"
	},
	{
			"value": "LETTRA00",
			"label": "LETTRA00"
	},
	{
			"value": "LETTRB00",
			"label": "LETTRB00"
	},
	{
			"value": "LEVEL 2 NON FICTION",
			"label": "LEVEL 2 NON FICTION"
	},
	{
			"value": "LEVER BACK",
			"label": "LEVER BACK"
	},
	{
			"value": "LEVERBACK EARRINGS",
			"label": "LEVERBACK EARRINGS"
	},
	{
			"value": "LEVER BACK EAR WIRES",
			"label": "LEVER BACK EAR WIRES"
	},
	{
			"value": "LFBTN",
			"label": "LFBTN"
	},
	{
			"value": "LFBTNA01",
			"label": "LFBTNA01"
	},
	{
			"value": "LG CONTAINER",
			"label": "LG CONTAINER"
	},
	{
			"value": "LG FLORAL",
			"label": "LG FLORAL"
	},
	{
			"value": "LG FLOWER",
			"label": "LG FLOWER"
	},
	{
			"value": "LG SEQUIN",
			"label": "LG SEQUIN"
	},
	{
			"value": "LG SWIRL FUR",
			"label": "LG SWIRL FUR"
	},
	{
			"value": "LIBBY BOWL",
			"label": "LIBBY BOWL"
	},
	{
			"value": "LIBBY COOKBOOK",
			"label": "LIBBY COOKBOOK"
	},
	{
			"value": "LIBBY OVAL",
			"label": "LIBBY OVAL"
	},
	{
			"value": "LIBBY PIE PLATES",
			"label": "LIBBY PIE PLATES"
	},
	{
			"value": "LIBBY RAMEKINS",
			"label": "LIBBY RAMEKINS"
	},
	{
			"value": "LIC. $1 ACTIVITIES",
			"label": "LIC. $1 ACTIVITIES"
	},
	{
			"value": "LICENSE",
			"label": "LICENSE"
	},
	{
			"value": "LICENSE COOKIECUTTER",
			"label": "LICENSE COOKIECUTTER"
	},
	{
			"value": "LICENSED",
			"label": "LICENSED"
	},
	{
			"value": "LICENSED ARCHBLISTER",
			"label": "LICENSED ARCHBLISTER"
	},
	{
			"value": "LICENSED BAKING CUPS",
			"label": "LICENSED BAKING CUPS"
	},
	{
			"value": "LICENSED BEAD BAG",
			"label": "LICENSED BEAD BAG"
	},
	{
			"value": "LICENSED BEAD BUCKET",
			"label": "LICENSED BEAD BUCKET"
	},
	{
			"value": "LICENSED BULAP",
			"label": "LICENSED BULAP"
	},
	{
			"value": "LICENSED CALENDAR",
			"label": "LICENSED CALENDAR"
	},
	{
			"value": "LICENSED CANDLE",
			"label": "LICENSED CANDLE"
	},
	{
			"value": "LICENSED COTTON",
			"label": "LICENSED COTTON"
	},
	{
			"value": "LICENSED DECORATION",
			"label": "LICENSED DECORATION"
	},
	{
			"value": "LICENSED DELUXE KIT",
			"label": "LICENSED DELUXE KIT"
	},
	{
			"value": "LICENSED EASTER",
			"label": "LICENSED EASTER"
	},
	{
			"value": "LICENSED FELT",
			"label": "LICENSED FELT"
	},
	{
			"value": "LICENSED FLEECE",
			"label": "LICENSED FLEECE"
	},
	{
			"value": "LICENSED GIFTBAG",
			"label": "LICENSED GIFTBAG"
	},
	{
			"value": "LICENSED HALL",
			"label": "LICENSED HALL"
	},
	{
			"value": "LICENSED KNIT",
			"label": "LICENSED KNIT"
	},
	{
			"value": "LICENSED MISC",
			"label": "LICENSED MISC"
	},
	{
			"value": "LICENSED MOCK SMOCK",
			"label": "LICENSED MOCK SMOCK"
	},
	{
			"value": "LICENSED PATRIOTIC",
			"label": "LICENSED PATRIOTIC"
	},
	{
			"value": "LICENSED PATTERNS",
			"label": "LICENSED PATTERNS"
	},
	{
			"value": "LICENSED PILLOW",
			"label": "LICENSED PILLOW"
	},
	{
			"value": "LICENSED PRECUT",
			"label": "LICENSED PRECUT"
	},
	{
			"value": "LICENSED PRIDE",
			"label": "LICENSED PRIDE"
	},
	{
			"value": "LICENSED SPORTS",
			"label": "LICENSED SPORTS"
	},
	{
			"value": "LICENSED SPRINKLES",
			"label": "LICENSED SPRINKLES"
	},
	{
			"value": "LICENSED STPAT",
			"label": "LICENSED STPAT"
	},
	{
			"value": "LICENSED SWADDLE",
			"label": "LICENSED SWADDLE"
	},
	{
			"value": "LICENSED TAGS",
			"label": "LICENSED TAGS"
	},
	{
			"value": "LICENSED TEE",
			"label": "LICENSED TEE"
	},
	{
			"value": "LICENSED THROWS",
			"label": "LICENSED THROWS"
	},
	{
			"value": "LICENSED VDAY",
			"label": "LICENSED VDAY"
	},
	{
			"value": "LICENSED WRAP",
			"label": "LICENSED WRAP"
	},
	{
			"value": "LICENSED XMAS",
			"label": "LICENSED XMAS"
	},
	{
			"value": "LICENSE KNIT",
			"label": "LICENSE KNIT"
	},
	{
			"value": "LICENSE PANS",
			"label": "LICENSE PANS"
	},
	{
			"value": "LICENSE T-SHIRTS",
			"label": "LICENSE T-SHIRTS"
	},
	{
			"value": "LICENSE WRAP",
			"label": "LICENSE WRAP"
	},
	{
			"value": "LICORICE",
			"label": "LICORICE"
	},
	{
			"value": "LID",
			"label": "LID"
	},
	{
			"value": "LIDS",
			"label": "LIDS"
	},
	{
			"value": "LIFE OF THE PARTY",
			"label": "LIFE OF THE PARTY"
	},
	{
			"value": "LIFESTYLE",
			"label": "LIFESTYLE"
	},
	{
			"value": "LIFESTYLE DECORATING",
			"label": "LIFESTYLE DECORATING"
	},
	{
			"value": "LIFE STYLES",
			"label": "LIFE STYLES"
	},
	{
			"value": "LIFE TOWEL",
			"label": "LIFE TOWEL"
	},
	{
			"value": "LIGHT",
			"label": "LIGHT"
	},
	{
			"value": "LIGHT 10CT",
			"label": "LIGHT 10CT"
	},
	{
			"value": "LIGHTC01",
			"label": "LIGHTC01"
	},
	{
			"value": "LIGHTED",
			"label": "LIGHTED"
	},
	{
			"value": "LIGHTED ACCESSORIES",
			"label": "LIGHTED ACCESSORIES"
	},
	{
			"value": "LIGHTED BOTTLE",
			"label": "LIGHTED BOTTLE"
	},
	{
			"value": "LIGHTED BRANCH",
			"label": "LIGHTED BRANCH"
	},
	{
			"value": "LIGHTED BRANCHES",
			"label": "LIGHTED BRANCHES"
	},
	{
			"value": "LIGHTED CYLINDER",
			"label": "LIGHTED CYLINDER"
	},
	{
			"value": "LIGHTED DECOR",
			"label": "LIGHTED DECOR"
	},
	{
			"value": "LIGHTED JAR",
			"label": "LIGHTED JAR"
	},
	{
			"value": "LIGHTED MAGNIFIER",
			"label": "LIGHTED MAGNIFIER"
	},
	{
			"value": "LIGHTED OVAL",
			"label": "LIGHTED OVAL"
	},
	{
			"value": "LIGHTED SCULPTURE",
			"label": "LIGHTED SCULPTURE"
	},
	{
			"value": "LIGHTED SIGN",
			"label": "LIGHTED SIGN"
	},
	{
			"value": "LIGHTED VASE",
			"label": "LIGHTED VASE"
	},
	{
			"value": "LIGHTED WOOD",
			"label": "LIGHTED WOOD"
	},
	{
			"value": "LIGHTER",
			"label": "LIGHTER"
	},
	{
			"value": "LIGHT GREY",
			"label": "LIGHT GREY"
	},
	{
			"value": "LIGHT HOLDER",
			"label": "LIGHT HOLDER"
	},
	{
			"value": "LIGHTING",
			"label": "LIGHTING"
	},
	{
			"value": "LIGHTING BASIC",
			"label": "LIGHTING BASIC"
	},
	{
			"value": "LIGHTING NOVELTY",
			"label": "LIGHTING NOVELTY"
	},
	{
			"value": "LIGHTS",
			"label": "LIGHTS"
	},
	{
			"value": "LIGHTS 25CT",
			"label": "LIGHTS 25CT"
	},
	{
			"value": "LIGHT SET",
			"label": "LIGHT SET"
	},
	{
			"value": "LIGHTSHOW",
			"label": "LIGHTSHOW"
	},
	{
			"value": "LIGHT STRIP",
			"label": "LIGHT STRIP"
	},
	{
			"value": "LIGHT UP",
			"label": "LIGHT UP"
	},
	{
			"value": "LIGHT UP OSO",
			"label": "LIGHT UP OSO"
	},
	{
			"value": "LIGHTWEIGHT",
			"label": "LIGHTWEIGHT"
	},
	{
			"value": "LIGHT WEIGHT POLY",
			"label": "LIGHT WEIGHT POLY"
	},
	{
			"value": "LIGHTWEIGHT POTTERY",
			"label": "LIGHTWEIGHT POTTERY"
	},
	{
			"value": "LILAC",
			"label": "LILAC"
	},
	{
			"value": "LILAC AIR 2 CUT BNDL",
			"label": "LILAC AIR 2 CUT BNDL"
	},
	{
			"value": "LIL DAVIS",
			"label": "LIL DAVIS"
	},
	{
			"value": "LILO & STITCH",
			"label": "LILO & STITCH"
	},
	{
			"value": "LIL PANDA",
			"label": "LIL PANDA"
	},
	{
			"value": "LIL POP!",
			"label": "LIL POP!"
	},
	{
			"value": "LILY",
			"label": "LILY"
	},
	{
			"value": "LILY ASHBURY",
			"label": "LILY ASHBURY"
	},
	{
			"value": "LILY-COSMO",
			"label": "LILY-COSMO"
	},
	{
			"value": "LILY OF VALLEY",
			"label": "LILY OF VALLEY"
	},
	{
			"value": "LILYPAD FROG",
			"label": "LILYPAD FROG"
	},
	{
			"value": "LIME",
			"label": "LIME"
	},
	{
			"value": "LIME GLASS FLNL",
			"label": "LIME GLASS FLNL"
	},
	{
			"value": "LINDOR SHIPPER",
			"label": "LINDOR SHIPPER"
	},
	{
			"value": "LINEAR",
			"label": "LINEAR"
	},
	{
			"value": "LINED BASKET",
			"label": "LINED BASKET"
	},
	{
			"value": "LINED BASKETS",
			"label": "LINED BASKETS"
	},
	{
			"value": "LINEN",
			"label": "LINEN"
	},
	{
			"value": "LINEN BANNER",
			"label": "LINEN BANNER"
	},
	{
			"value": "LINEN COTTON",
			"label": "LINEN COTTON"
	},
	{
			"value": "LINEN / LINEN LOOKS",
			"label": "LINEN / LINEN LOOKS"
	},
	{
			"value": "LINEN LOOK",
			"label": "LINEN LOOK"
	},
	{
			"value": "LINEN LOOK SOLID",
			"label": "LINEN LOOK SOLID"
	},
	{
			"value": "LINEN NAPKIN",
			"label": "LINEN NAPKIN"
	},
	{
			"value": "LINEN P AND E",
			"label": "LINEN P AND E"
	},
	{
			"value": "LINEN RAYON",
			"label": "LINEN RAYON"
	},
	{
			"value": "LINEN RIBBON",
			"label": "LINEN RIBBON"
	},
	{
			"value": "LINEN STOCKING",
			"label": "LINEN STOCKING"
	},
	{
			"value": "LINEN TOWEL",
			"label": "LINEN TOWEL"
	},
	{
			"value": "LINER",
			"label": "LINER"
	},
	{
			"value": "LINES",
			"label": "LINES"
	},
	{
			"value": "LININGS",
			"label": "LININGS"
	},
	{
			"value": "LINK",
			"label": "LINK"
	},
	{
			"value": "LION",
			"label": "LION"
	},
	{
			"value": "LION BRAND",
			"label": "LION BRAND"
	},
	{
			"value": "LIP BALM ACCESSORIES",
			"label": "LIP BALM ACCESSORIES"
	},
	{
			"value": "LIP BALM FLAVORS",
			"label": "LIP BALM FLAVORS"
	},
	{
			"value": "LIP BALM KIT",
			"label": "LIP BALM KIT"
	},
	{
			"value": "LIP CORD",
			"label": "LIP CORD"
	},
	{
			"value": "LIPS",
			"label": "LIPS"
	},
	{
			"value": "LIQUID",
			"label": "LIQUID"
	},
	{
			"value": "LIQUID DYE",
			"label": "LIQUID DYE"
	},
	{
			"value": "LIQUID WOVEN",
			"label": "LIQUID WOVEN"
	},
	{
			"value": "LISETTE",
			"label": "LISETTE"
	},
	{
			"value": "LISIANTHUS",
			"label": "LISIANTHUS"
	},
	{
			"value": "LITE TREE",
			"label": "LITE TREE"
	},
	{
			"value": "LITTLE BIRDS",
			"label": "LITTLE BIRDS"
	},
	{
			"value": "LITTLE ELF CUTTER",
			"label": "LITTLE ELF CUTTER"
	},
	{
			"value": "LITTLE LISETTE",
			"label": "LITTLE LISETTE"
	},
	{
			"value": "LITTLE MAKERS",
			"label": "LITTLE MAKERS"
	},
	{
			"value": "LITTLE MERMAID",
			"label": "LITTLE MERMAID"
	},
	{
			"value": "LITTLE OWLS",
			"label": "LITTLE OWLS"
	},
	{
			"value": "LITTLE PUPPIES FLNL",
			"label": "LITTLE PUPPIES FLNL"
	},
	{
			"value": "LITTLES",
			"label": "LITTLES"
	},
	{
			"value": "LITTLETON",
			"label": "LITTLETON"
	},
	{
			"value": "LITTLE YARN BOOKS",
			"label": "LITTLE YARN BOOKS"
	},
	{
			"value": "LIVE PLANT",
			"label": "LIVE PLANT"
	},
	{
			"value": "LIVING ROOM",
			"label": "LIVING ROOM"
	},
	{
			"value": "LLAMA",
			"label": "LLAMA"
	},
	{
			"value": "LLAMAS",
			"label": "LLAMAS"
	},
	{
			"value": "L LED BRASS CANDLE",
			"label": "L LED BRASS CANDLE"
	},
	{
			"value": "LOAF",
			"label": "LOAF"
	},
	{
			"value": "LOAF PAN",
			"label": "LOAF PAN"
	},
	{
			"value": "LOBSTER CLASP",
			"label": "LOBSTER CLASP"
	},
	{
			"value": "LOBSTER CLASPS",
			"label": "LOBSTER CLASPS"
	},
	{
			"value": "LOCKET",
			"label": "LOCKET"
	},
	{
			"value": "LODGE",
			"label": "LODGE"
	},
	{
			"value": "LOEW-CORNELL",
			"label": "LOEW-CORNELL"
	},
	{
			"value": "LOG",
			"label": "LOG"
	},
	{
			"value": "LOLLIPOP",
			"label": "LOLLIPOP"
	},
	{
			"value": "LOLLIPOP BAGS",
			"label": "LOLLIPOP BAGS"
	},
	{
			"value": "LOLLIPOP BOX",
			"label": "LOLLIPOP BOX"
	},
	{
			"value": "LOLLIPOP MOLDS",
			"label": "LOLLIPOP MOLDS"
	},
	{
			"value": "LOLLIPOP PICKS",
			"label": "LOLLIPOP PICKS"
	},
	{
			"value": "LOLLIPOP STAND",
			"label": "LOLLIPOP STAND"
	},
	{
			"value": "LOLLIPOP STANDS",
			"label": "LOLLIPOP STANDS"
	},
	{
			"value": "LOLLIPOP STICKS",
			"label": "LOLLIPOP STICKS"
	},
	{
			"value": "LONG AND SHORT",
			"label": "LONG AND SHORT"
	},
	{
			"value": "LONG AND SHORT CHAIN",
			"label": "LONG AND SHORT CHAIN"
	},
	{
			"value": "LONG LUMBAR",
			"label": "LONG LUMBAR"
	},
	{
			"value": "LONG NEEDLE",
			"label": "LONG NEEDLE"
	},
	{
			"value": "LONG WORD BLOCK",
			"label": "LONG WORD BLOCK"
	},
	{
			"value": "LOOM",
			"label": "LOOM"
	},
	{
			"value": "LOOM KIT",
			"label": "LOOM KIT"
	},
	{
			"value": "LOOM KNITTING",
			"label": "LOOM KNITTING"
	},
	{
			"value": "LOOM RUG",
			"label": "LOOM RUG"
	},
	{
			"value": "LOOP COMFORT GRIP",
			"label": "LOOP COMFORT GRIP"
	},
	{
			"value": "LOOP FILIGREES",
			"label": "LOOP FILIGREES"
	},
	{
			"value": "LOOP FRINGE",
			"label": "LOOP FRINGE"
	},
	{
			"value": "LOOP PLANNER",
			"label": "LOOP PLANNER"
	},
	{
			"value": "LOOSE FLORAL",
			"label": "LOOSE FLORAL"
	},
	{
			"value": "LORANN OILS",
			"label": "LORANN OILS"
	},
	{
			"value": "LOTION",
			"label": "LOTION"
	},
	{
			"value": "LOTS O LIZARDS",
			"label": "LOTS O LIZARDS"
	},
	{
			"value": "LOTUS",
			"label": "LOTUS"
	},
	{
			"value": "LOTUS POD",
			"label": "LOTUS POD"
	},
	{
			"value": "LOTUS POD BSKT",
			"label": "LOTUS POD BSKT"
	},
	{
			"value": "LOUNGELETICS",
			"label": "LOUNGELETICS"
	},
	{
			"value": "LOUNGEWEAR",
			"label": "LOUNGEWEAR"
	},
	{
			"value": "LOVE",
			"label": "LOVE"
	},
	{
			"value": "LOVE COFFEE",
			"label": "LOVE COFFEE"
	},
	{
			"value": "LOVE MONKEY",
			"label": "LOVE MONKEY"
	},
	{
			"value": "LOVE & ROMANCE",
			"label": "LOVE & ROMANCE"
	},
	{
			"value": "LOW BOY",
			"label": "LOW BOY"
	},
	{
			"value": "LOW CUT",
			"label": "LOW CUT"
	},
	{
			"value": "LOWCUT",
			"label": "LOWCUT"
	},
	{
			"value": "LOW LOFT",
			"label": "LOW LOFT"
	},
	{
			"value": "LRG LED TREE",
			"label": "LRG LED TREE"
	},
	{
			"value": "L SIZE",
			"label": "L SIZE"
	},
	{
			"value": "LT BLU",
			"label": "LT BLU"
	},
	{
			"value": "LT BLUE PRINCESS",
			"label": "LT BLUE PRINCESS"
	},
	{
			"value": "LT GRY",
			"label": "LT GRY"
	},
	{
			"value": "LTWGHT FBRCS & SHRS",
			"label": "LTWGHT FBRCS & SHRS"
	},
	{
			"value": "LUAU",
			"label": "LUAU"
	},
	{
			"value": "LUCHEON NAPKIN",
			"label": "LUCHEON NAPKIN"
	},
	{
			"value": "LUGGAGE",
			"label": "LUGGAGE"
	},
	{
			"value": "LUGGAGE JINGLE BELL",
			"label": "LUGGAGE JINGLE BELL"
	},
	{
			"value": "LUGGAGE TAG",
			"label": "LUGGAGE TAG"
	},
	{
			"value": "LUGGAGE TAGS",
			"label": "LUGGAGE TAGS"
	},
	{
			"value": "LUMBAR",
			"label": "LUMBAR"
	},
	{
			"value": "LUMBAR FAMILY",
			"label": "LUMBAR FAMILY"
	},
	{
			"value": "LUMBAR HOME",
			"label": "LUMBAR HOME"
	},
	{
			"value": "LUMBAR PILLO",
			"label": "LUMBAR PILLO"
	},
	{
			"value": "LUMBAR PILLOW",
			"label": "LUMBAR PILLOW"
	},
	{
			"value": "LUMINARA",
			"label": "LUMINARA"
	},
	{
			"value": "LUMINARY BAG",
			"label": "LUMINARY BAG"
	},
	{
			"value": "LUNARIA",
			"label": "LUNARIA"
	},
	{
			"value": "LUNCH BAG",
			"label": "LUNCH BAG"
	},
	{
			"value": "LUNCHEON NAPKIN",
			"label": "LUNCHEON NAPKIN"
	},
	{
			"value": "LUNCH NAPKIN",
			"label": "LUNCH NAPKIN"
	},
	{
			"value": "LUNCH PLATE",
			"label": "LUNCH PLATE"
	},
	{
			"value": "LUREX",
			"label": "LUREX"
	},
	{
			"value": "LUXE COTTON SOLIDS",
			"label": "LUXE COTTON SOLIDS"
	},
	{
			"value": "LUXE DESIGN",
			"label": "LUXE DESIGN"
	},
	{
			"value": "LUXURY FOAM",
			"label": "LUXURY FOAM"
	},
	{
			"value": "LVS",
			"label": "LVS"
	},
	{
			"value": "LVS PUMPKIN",
			"label": "LVS PUMPKIN"
	},
	{
			"value": "MACARONI KIT",
			"label": "MACARONI KIT"
	},
	{
			"value": "MACHINE",
			"label": "MACHINE"
	},
	{
			"value": "MACHINE BLADE",
			"label": "MACHINE BLADE"
	},
	{
			"value": "MACHINE EMBROIDERY",
			"label": "MACHINE EMBROIDERY"
	},
	{
			"value": "MACHINE TIP",
			"label": "MACHINE TIP"
	},
	{
			"value": "MACHINE TOTE",
			"label": "MACHINE TOTE"
	},
	{
			"value": "MACKINAC MOON",
			"label": "MACKINAC MOON"
	},
	{
			"value": "MACMILLAN PUBLISHERS",
			"label": "MACMILLAN PUBLISHERS"
	},
	{
			"value": "MACNOLIA STM",
			"label": "MACNOLIA STM"
	},
	{
			"value": "MACRAME",
			"label": "MACRAME"
	},
	{
			"value": "MACRAME BOARD",
			"label": "MACRAME BOARD"
	},
	{
			"value": "MACRAME OWL",
			"label": "MACRAME OWL"
	},
	{
			"value": "MACRAME WALL HANG",
			"label": "MACRAME WALL HANG"
	},
	{
			"value": "MACREME CORD",
			"label": "MACREME CORD"
	},
	{
			"value": "MADE IN USA",
			"label": "MADE IN USA"
	},
	{
			"value": "MADE WITH LOVE",
			"label": "MADE WITH LOVE"
	},
	{
			"value": "MADRAS",
			"label": "MADRAS"
	},
	{
			"value": "MAGAZINE",
			"label": "MAGAZINE"
	},
	{
			"value": "MAGENTA",
			"label": "MAGENTA"
	},
	{
			"value": "MAGIC ART SCRATCHERS",
			"label": "MAGIC ART SCRATCHERS"
	},
	{
			"value": "MAGIC BALL",
			"label": "MAGIC BALL"
	},
	{
			"value": "MAGIC KIT",
			"label": "MAGIC KIT"
	},
	{
			"value": "MAGIC OF CHRISTMAS",
			"label": "MAGIC OF CHRISTMAS"
	},
	{
			"value": "MAGIC TAPE",
			"label": "MAGIC TAPE"
	},
	{
			"value": "MAGNET",
			"label": "MAGNET"
	},
	{
			"value": "MAGNETIC",
			"label": "MAGNETIC"
	},
	{
			"value": "MAGNETIC CLASP",
			"label": "MAGNETIC CLASP"
	},
	{
			"value": "MAGNETIC CLASPS",
			"label": "MAGNETIC CLASPS"
	},
	{
			"value": "MAGNETIC POETRY",
			"label": "MAGNETIC POETRY"
	},
	{
			"value": "MAGNETS",
			"label": "MAGNETS"
	},
	{
			"value": "MAGNIFIER",
			"label": "MAGNIFIER"
	},
	{
			"value": "MAGNOLIA",
			"label": "MAGNOLIA"
	},
	{
			"value": "MAGNOLIA LEAVES",
			"label": "MAGNOLIA LEAVES"
	},
	{
			"value": "MAGNOLIA LVS",
			"label": "MAGNOLIA LVS"
	},
	{
			"value": "MAGNOLIA LVS CONE",
			"label": "MAGNOLIA LVS CONE"
	},
	{
			"value": "MAGNOLIA SPRY",
			"label": "MAGNOLIA SPRY"
	},
	{
			"value": "MAGNOLIA STM",
			"label": "MAGNOLIA STM"
	},
	{
			"value": "MAGNUM MAGNETICS",
			"label": "MAGNUM MAGNETICS"
	},
	{
			"value": "MAGNUM MAGNETICS COR",
			"label": "MAGNUM MAGNETICS COR"
	},
	{
			"value": "MAIA",
			"label": "MAIA"
	},
	{
			"value": "MAIDENHAIR",
			"label": "MAIDENHAIR"
	},
	{
			"value": "MAIL BOX",
			"label": "MAIL BOX"
	},
	{
			"value": "MAILBOX",
			"label": "MAILBOX"
	},
	{
			"value": "MAIN",
			"label": "MAIN"
	},
	{
			"value": "MAJESTIC JEWELS",
			"label": "MAJESTIC JEWELS"
	},
	{
			"value": "MAKE IT &  BAKE IT",
			"label": "MAKE IT &  BAKE IT"
	},
	{
			"value": "MAKE IT BAKE IT",
			"label": "MAKE IT BAKE IT"
	},
	{
			"value": "MAKER",
			"label": "MAKER"
	},
	{
			"value": "MAKER 3",
			"label": "MAKER 3"
	},
	{
			"value": "MAKER 3 CUTTING BNDL",
			"label": "MAKER 3 CUTTING BNDL"
	},
	{
			"value": "MAKER 3 SEWING BNDL",
			"label": "MAKER 3 SEWING BNDL"
	},
	{
			"value": "MAKER BUNDLE",
			"label": "MAKER BUNDLE"
	},
	{
			"value": "MAKERS",
			"label": "MAKERS"
	},
	{
			"value": "MAKER'S HOLIDAY",
			"label": "MAKER'S HOLIDAY"
	},
	{
			"value": "MAKER WREATH",
			"label": "MAKER WREATH"
	},
	{
			"value": "MAKEUP",
			"label": "MAKEUP"
	},
	{
			"value": "MAKE UP BAG",
			"label": "MAKE UP BAG"
	},
	{
			"value": "MAKEUP KIT",
			"label": "MAKEUP KIT"
	},
	{
			"value": "MAKE UP MIRROR",
			"label": "MAKE UP MIRROR"
	},
	{
			"value": "MAKEUP SET",
			"label": "MAKEUP SET"
	},
	{
			"value": "MAKING MEMORIES",
			"label": "MAKING MEMORIES"
	},
	{
			"value": "MAKIN'S USA",
			"label": "MAKIN'S USA"
	},
	{
			"value": "MALPLES LEAVES",
			"label": "MALPLES LEAVES"
	},
	{
			"value": "MAMA",
			"label": "MAMA"
	},
	{
			"value": "MANDALORIAN",
			"label": "MANDALORIAN"
	},
	{
			"value": "MANDREL",
			"label": "MANDREL"
	},
	{
			"value": "MANGO WOOD",
			"label": "MANGO WOOD"
	},
	{
			"value": "MANOR GARDEN",
			"label": "MANOR GARDEN"
	},
	{
			"value": "MAN STUFF",
			"label": "MAN STUFF"
	},
	{
			"value": "MANTEL SWAG",
			"label": "MANTEL SWAG"
	},
	{
			"value": "MANTLE SCARF",
			"label": "MANTLE SCARF"
	},
	{
			"value": "MAP",
			"label": "MAP"
	},
	{
			"value": "MAPLE",
			"label": "MAPLE"
	},
	{
			"value": "MAPLE BRY BSH",
			"label": "MAPLE BRY BSH"
	},
	{
			"value": "MAPLE LEAF",
			"label": "MAPLE LEAF"
	},
	{
			"value": "MAPLE LEAVES",
			"label": "MAPLE LEAVES"
	},
	{
			"value": "MAPLE LVS",
			"label": "MAPLE LVS"
	},
	{
			"value": "MAPLE PECAN PINE",
			"label": "MAPLE PECAN PINE"
	},
	{
			"value": "MAP TOWEL",
			"label": "MAP TOWEL"
	},
	{
			"value": "MAR",
			"label": "MAR"
	},
	{
			"value": "MARATHON",
			"label": "MARATHON"
	},
	{
			"value": "MARBLE",
			"label": "MARBLE"
	},
	{
			"value": "MARCH",
			"label": "MARCH"
	},
	{
			"value": "MARCHFY15RTV",
			"label": "MARCHFY15RTV"
	},
	{
			"value": "MAR FY14 RTV",
			"label": "MAR FY14 RTV"
	},
	{
			"value": "MARFY16RTV",
			"label": "MARFY16RTV"
	},
	{
			"value": "MARGARINTUM",
			"label": "MARGARINTUM"
	},
	{
			"value": "MARIGOLD",
			"label": "MARIGOLD"
	},
	{
			"value": "MARINA WINDOW BOX",
			"label": "MARINA WINDOW BOX"
	},
	{
			"value": "MARINE",
			"label": "MARINE"
	},
	{
			"value": "MARINE VINYL",
			"label": "MARINE VINYL"
	},
	{
			"value": "MARITIME CLUB",
			"label": "MARITIME CLUB"
	},
	{
			"value": "MARKER",
			"label": "MARKER"
	},
	{
			"value": "MARKERS",
			"label": "MARKERS"
	},
	{
			"value": "MARKING TAPE",
			"label": "MARKING TAPE"
	},
	{
			"value": "MARKING TOOL",
			"label": "MARKING TOOL"
	},
	{
			"value": "MARK RICHARDS",
			"label": "MARK RICHARDS"
	},
	{
			"value": "MARLED",
			"label": "MARLED"
	},
	{
			"value": "MARQUEE LIGHT",
			"label": "MARQUEE LIGHT"
	},
	{
			"value": "MARTELLI",
			"label": "MARTELLI"
	},
	{
			"value": "MARTHA STEWART",
			"label": "MARTHA STEWART"
	},
	{
			"value": "MARTHB00",
			"label": "MARTHB00"
	},
	{
			"value": "MARTI MICHELL",
			"label": "MARTI MICHELL"
	},
	{
			"value": "MARTIN/ F. WEBER",
			"label": "MARTIN/ F. WEBER"
	},
	{
			"value": "MARTINGALE & COMPANY",
			"label": "MARTINGALE & COMPANY"
	},
	{
			"value": "MARTINI",
			"label": "MARTINI"
	},
	{
			"value": "MARTIN UNIVERSAL",
			"label": "MARTIN UNIVERSAL"
	},
	{
			"value": "MARVEL",
			"label": "MARVEL"
	},
	{
			"value": "MARVEL RETRO COMICS",
			"label": "MARVEL RETRO COMICS"
	},
	{
			"value": "MARVY- FABRC MRKR PK",
			"label": "MARVY- FABRC MRKR PK"
	},
	{
			"value": "MARVY- FABRIC MRKERS",
			"label": "MARVY- FABRIC MRKERS"
	},
	{
			"value": "MARY ELLEN PRODUCTS",
			"label": "MARY ELLEN PRODUCTS"
	},
	{
			"value": "MARY ENGELBREIT CALE",
			"label": "MARY ENGELBREIT CALE"
	},
	{
			"value": "MARY MAXIM",
			"label": "MARY MAXIM"
	},
	{
			"value": "MASCULINE",
			"label": "MASCULINE"
	},
	{
			"value": "MASK",
			"label": "MASK"
	},
	{
			"value": "MASK ADULT",
			"label": "MASK ADULT"
	},
	{
			"value": "MASKS",
			"label": "MASKS"
	},
	{
			"value": "MASON JAR",
			"label": "MASON JAR"
	},
	{
			"value": "MASON JARS DAY/WEEK",
			"label": "MASON JARS DAY/WEEK"
	},
	{
			"value": "MASQUERADE MASK",
			"label": "MASQUERADE MASK"
	},
	{
			"value": "MASTERING ADDITION",
			"label": "MASTERING ADDITION"
	},
	{
			"value": "MASTERING MENTAL CAL",
			"label": "MASTERING MENTAL CAL"
	},
	{
			"value": "MASTERING MULTIPLICA",
			"label": "MASTERING MULTIPLICA"
	},
	{
			"value": "MASTERING SUBTRACTIO",
			"label": "MASTERING SUBTRACTIO"
	},
	{
			"value": "MASTERPIECES",
			"label": "MASTERPIECES"
	},
	{
			"value": "MAT",
			"label": "MAT"
	},
	{
			"value": "MAT BOARD",
			"label": "MAT BOARD"
	},
	{
			"value": "MATELASSE",
			"label": "MATELASSE"
	},
	{
			"value": "MATH LANG GAMES",
			"label": "MATH LANG GAMES"
	},
	{
			"value": "MAT OTHER",
			"label": "MAT OTHER"
	},
	{
			"value": "MATS",
			"label": "MATS"
	},
	{
			"value": "MAT SPECIALTY",
			"label": "MAT SPECIALTY"
	},
	{
			"value": "MATTE",
			"label": "MATTE"
	},
	{
			"value": "MATTE JERSEY",
			"label": "MATTE JERSEY"
	},
	{
			"value": "MATTE NET",
			"label": "MATTE NET"
	},
	{
			"value": "MATTE POMPOM TULLE",
			"label": "MATTE POMPOM TULLE"
	},
	{
			"value": "MATTE TULLE",
			"label": "MATTE TULLE"
	},
	{
			"value": "MATTRESS TICKING",
			"label": "MATTRESS TICKING"
	},
	{
			"value": "MAY",
			"label": "MAY"
	},
	{
			"value": "MAY ARTS",
			"label": "MAY ARTS"
	},
	{
			"value": "MAY FY14 RTV",
			"label": "MAY FY14 RTV"
	},
	{
			"value": "MAYFY15RTV",
			"label": "MAYFY15RTV"
	},
	{
			"value": "MAYFY16RTV",
			"label": "MAYFY16RTV"
	},
	{
			"value": "MAZES",
			"label": "MAZES"
	},
	{
			"value": "MBI",
			"label": "MBI"
	},
	{
			"value": "MB TOWELS",
			"label": "MB TOWELS"
	},
	{
			"value": "MC",
			"label": "MC"
	},
	{
			"value": "MC CALL",
			"label": "MC CALL"
	},
	{
			"value": "MC GILL",
			"label": "MC GILL"
	},
	{
			"value": "M C G TEXTILES",
			"label": "M C G TEXTILES"
	},
	{
			"value": "MDSE COMPONENT",
			"label": "MDSE COMPONENT"
	},
	{
			"value": "MEADOW BSH",
			"label": "MEADOW BSH"
	},
	{
			"value": "MEADOW HEATHER",
			"label": "MEADOW HEATHER"
	},
	{
			"value": "MEASURING CUPS",
			"label": "MEASURING CUPS"
	},
	{
			"value": "MEASURING TL",
			"label": "MEASURING TL"
	},
	{
			"value": "MECHANICAL",
			"label": "MECHANICAL"
	},
	{
			"value": "MEDALLION",
			"label": "MEDALLION"
	},
	{
			"value": "MED. BOX KIT",
			"label": "MED. BOX KIT"
	},
	{
			"value": "MED HANDMADE",
			"label": "MED HANDMADE"
	},
	{
			"value": "MEDIUM",
			"label": "MEDIUM"
	},
	{
			"value": "MEDIUM BOX CRAFT KIT",
			"label": "MEDIUM BOX CRAFT KIT"
	},
	{
			"value": "MEDIUM BOX KIDS CRAF",
			"label": "MEDIUM BOX KIDS CRAF"
	},
	{
			"value": "MEDIUM BOX KIT",
			"label": "MEDIUM BOX KIT"
	},
	{
			"value": "MEDIUM CRITTER",
			"label": "MEDIUM CRITTER"
	},
	{
			"value": "MEDIUM DECOR",
			"label": "MEDIUM DECOR"
	},
	{
			"value": "MEDIUM-MEDIUM",
			"label": "MEDIUM-MEDIUM"
	},
	{
			"value": "MEDIUM-OIL",
			"label": "MEDIUM-OIL"
	},
	{
			"value": "MEDIUM PUMPKIN",
			"label": "MEDIUM PUMPKIN"
	},
	{
			"value": "MEDIUM SET",
			"label": "MEDIUM SET"
	},
	{
			"value": "MEDIUM-VARNISH",
			"label": "MEDIUM-VARNISH"
	},
	{
			"value": "MEDIUM WALL DECOR",
			"label": "MEDIUM WALL DECOR"
	},
	{
			"value": "MED LED TREE",
			"label": "MED LED TREE"
	},
	{
			"value": "MED RICK RACK",
			"label": "MED RICK RACK"
	},
	{
			"value": "MEGA",
			"label": "MEGA"
	},
	{
			"value": "MEGA RIBBON",
			"label": "MEGA RIBBON"
	},
	{
			"value": "MEGA ROLL",
			"label": "MEGA ROLL"
	},
	{
			"value": "MEHNDI KIT",
			"label": "MEHNDI KIT"
	},
	{
			"value": "MELAMINE",
			"label": "MELAMINE"
	},
	{
			"value": "MELAMINE APP PLATE",
			"label": "MELAMINE APP PLATE"
	},
	{
			"value": "MELAMINE BOWL",
			"label": "MELAMINE BOWL"
	},
	{
			"value": "MELAMINE DINNER PLAT",
			"label": "MELAMINE DINNER PLAT"
	},
	{
			"value": "MELAMINE SERVEWARE",
			"label": "MELAMINE SERVEWARE"
	},
	{
			"value": "MELAMINE SET",
			"label": "MELAMINE SET"
	},
	{
			"value": "MELISSA & DOUG",
			"label": "MELISSA & DOUG"
	},
	{
			"value": "MELISSA FRANCES",
			"label": "MELISSA FRANCES"
	},
	{
			"value": "MELT",
			"label": "MELT"
	},
	{
			"value": "MELTING BOWLS",
			"label": "MELTING BOWLS"
	},
	{
			"value": "MELTING PLATE",
			"label": "MELTING PLATE"
	},
	{
			"value": "MELTS",
			"label": "MELTS"
	},
	{
			"value": "MEM FOAM MATT TOPPER",
			"label": "MEM FOAM MATT TOPPER"
	},
	{
			"value": "MEMO BOARD",
			"label": "MEMO BOARD"
	},
	{
			"value": "MEMORIES",
			"label": "MEMORIES"
	},
	{
			"value": "MEMORY",
			"label": "MEMORY"
	},
	{
			"value": "MEMORY FOAM",
			"label": "MEMORY FOAM"
	},
	{
			"value": "MEMORY GAME",
			"label": "MEMORY GAME"
	},
	{
			"value": "MEMRY",
			"label": "MEMRY"
	},
	{
			"value": "ME & MY BIG IDEAS",
			"label": "ME & MY BIG IDEAS"
	},
	{
			"value": "MENORAH",
			"label": "MENORAH"
	},
	{
			"value": "MENTAL HEALTH AWARE",
			"label": "MENTAL HEALTH AWARE"
	},
	{
			"value": "MEPXY MARKERS",
			"label": "MEPXY MARKERS"
	},
	{
			"value": "MERCURY GLASS",
			"label": "MERCURY GLASS"
	},
	{
			"value": "MERINGUE",
			"label": "MERINGUE"
	},
	{
			"value": "MERINGUE POWDER",
			"label": "MERINGUE POWDER"
	},
	{
			"value": "MERMAID",
			"label": "MERMAID"
	},
	{
			"value": "MERMAID FOIL",
			"label": "MERMAID FOIL"
	},
	{
			"value": "MERMAID PRINCESS",
			"label": "MERMAID PRINCESS"
	},
	{
			"value": "MERMAID SCALES",
			"label": "MERMAID SCALES"
	},
	{
			"value": "MERRY AND BRIGHT",
			"label": "MERRY AND BRIGHT"
	},
	{
			"value": "MERRY AND BRIGHT EC",
			"label": "MERRY AND BRIGHT EC"
	},
	{
			"value": "MERRY & BRIGHT",
			"label": "MERRY & BRIGHT"
	},
	{
			"value": "MERRY FESTIVE",
			"label": "MERRY FESTIVE"
	},
	{
			"value": "MERRY MODERN OSO",
			"label": "MERRY MODERN OSO"
	},
	{
			"value": "ME SALTY",
			"label": "ME SALTY"
	},
	{
			"value": "MESH",
			"label": "MESH"
	},
	{
			"value": "MESH IRON ON",
			"label": "MESH IRON ON"
	},
	{
			"value": "METAL",
			"label": "METAL"
	},
	{
			"value": "METAL ANIMAL",
			"label": "METAL ANIMAL"
	},
	{
			"value": "METAL BASKET",
			"label": "METAL BASKET"
	},
	{
			"value": "METAL BEADS",
			"label": "METAL BEADS"
	},
	{
			"value": "METAL BEZEL",
			"label": "METAL BEZEL"
	},
	{
			"value": "METAL BIRDFEEDER",
			"label": "METAL BIRDFEEDER"
	},
	{
			"value": "METAL BIRDHOUSE",
			"label": "METAL BIRDHOUSE"
	},
	{
			"value": "METAL CLASPS",
			"label": "METAL CLASPS"
	},
	{
			"value": "METAL CONTAINER",
			"label": "METAL CONTAINER"
	},
	{
			"value": "METAL ENTERTAINING",
			"label": "METAL ENTERTAINING"
	},
	{
			"value": "METAL FARM ANIMAL",
			"label": "METAL FARM ANIMAL"
	},
	{
			"value": "METAL FIGURE",
			"label": "METAL FIGURE"
	},
	{
			"value": "METAL GLITTER ANIMAL",
			"label": "METAL GLITTER ANIMAL"
	},
	{
			"value": "METAL GRID",
			"label": "METAL GRID"
	},
	{
			"value": "METAL HOOK",
			"label": "METAL HOOK"
	},
	{
			"value": "METAL HOOP",
			"label": "METAL HOOP"
	},
	{
			"value": "METAL HTV",
			"label": "METAL HTV"
	},
	{
			"value": "METAL JEWELRY",
			"label": "METAL JEWELRY"
	},
	{
			"value": "METAL LANTERN",
			"label": "METAL LANTERN"
	},
	{
			"value": "METAL LEAF",
			"label": "METAL LEAF"
	},
	{
			"value": "METALLIC",
			"label": "METALLIC"
	},
	{
			"value": "METALLIC CHIFFON",
			"label": "METALLIC CHIFFON"
	},
	{
			"value": "METALLIC ELASTIC",
			"label": "METALLIC ELASTIC"
	},
	{
			"value": "METALLIC FALL RIBBON",
			"label": "METALLIC FALL RIBBON"
	},
	{
			"value": "METALLIC FUR",
			"label": "METALLIC FUR"
	},
	{
			"value": "METALLIC NETTINGS",
			"label": "METALLIC NETTINGS"
	},
	{
			"value": "METALLIC PUMPKIN",
			"label": "METALLIC PUMPKIN"
	},
	{
			"value": "METALLICS",
			"label": "METALLICS"
	},
	{
			"value": "METALLIC STUD",
			"label": "METALLIC STUD"
	},
	{
			"value": "METALLIC TAPER 4PK",
			"label": "METALLIC TAPER 4PK"
	},
	{
			"value": "METALLIC TREE",
			"label": "METALLIC TREE"
	},
	{
			"value": "METALLIC VINYL",
			"label": "METALLIC VINYL"
	},
	{
			"value": "METAL LINED BEADS",
			"label": "METAL LINED BEADS"
	},
	{
			"value": "METAL PUNCH",
			"label": "METAL PUNCH"
	},
	{
			"value": "METAL RAKE",
			"label": "METAL RAKE"
	},
	{
			"value": "METAL RINGS",
			"label": "METAL RINGS"
	},
	{
			"value": "METAL STAMP",
			"label": "METAL STAMP"
	},
	{
			"value": "METAL STATUARY",
			"label": "METAL STATUARY"
	},
	{
			"value": "METAL TABLE PS",
			"label": "METAL TABLE PS"
	},
	{
			"value": "METAL TABLETOP",
			"label": "METAL TABLETOP"
	},
	{
			"value": "METAL TRAY",
			"label": "METAL TRAY"
	},
	{
			"value": "METAL TREE DISPLAY",
			"label": "METAL TREE DISPLAY"
	},
	{
			"value": "METAL TUMBLER",
			"label": "METAL TUMBLER"
	},
	{
			"value": "METAL WALL DECOR",
			"label": "METAL WALL DECOR"
	},
	{
			"value": "METAL WALL DÉCOR",
			"label": "METAL WALL DÉCOR"
	},
	{
			"value": "METAL WALL DISPLAY",
			"label": "METAL WALL DISPLAY"
	},
	{
			"value": "METAL WALL HANGING",
			"label": "METAL WALL HANGING"
	},
	{
			"value": "METAL WD",
			"label": "METAL WD"
	},
	{
			"value": "METAL WINDCHIME",
			"label": "METAL WINDCHIME"
	},
	{
			"value": "METTALIC TRIM",
			"label": "METTALIC TRIM"
	},
	{
			"value": "MHA",
			"label": "MHA"
	},
	{
			"value": "MIBI",
			"label": "MIBI"
	},
	{
			"value": "MICADOR JR",
			"label": "MICADOR JR"
	},
	{
			"value": "MICA SHAMROCK",
			"label": "MICA SHAMROCK"
	},
	{
			"value": "MICHIGAN",
			"label": "MICHIGAN"
	},
	{
			"value": "MICHIGAN ST",
			"label": "MICHIGAN ST"
	},
	{
			"value": "MICHIGAN STATE",
			"label": "MICHIGAN STATE"
	},
	{
			"value": "MICKEY",
			"label": "MICKEY"
	},
	{
			"value": "MICKEY AND FRIENDS",
			"label": "MICKEY AND FRIENDS"
	},
	{
			"value": "MICKEY AND MINNIE",
			"label": "MICKEY AND MINNIE"
	},
	{
			"value": "MICKEY & MINNIE",
			"label": "MICKEY & MINNIE"
	},
	{
			"value": "MICKEY MOUSE",
			"label": "MICKEY MOUSE"
	},
	{
			"value": "MICRO",
			"label": "MICRO"
	},
	{
			"value": "MICRO BACKED SHERPA",
			"label": "MICRO BACKED SHERPA"
	},
	{
			"value": "MICROFIBER CLOTH",
			"label": "MICROFIBER CLOTH"
	},
	{
			"value": "MICRO FLORAL",
			"label": "MICRO FLORAL"
	},
	{
			"value": "MICRON",
			"label": "MICRON"
	},
	{
			"value": "MICRO PRINT",
			"label": "MICRO PRINT"
	},
	{
			"value": "MICRO PRINTS",
			"label": "MICRO PRINTS"
	},
	{
			"value": "MICROSUEDE",
			"label": "MICROSUEDE"
	},
	{
			"value": "MICROSUEDE SOLIDS",
			"label": "MICROSUEDE SOLIDS"
	},
	{
			"value": "MICROUSUEDE",
			"label": "MICROUSUEDE"
	},
	{
			"value": "MIDNIGHT HEARTS",
			"label": "MIDNIGHT HEARTS"
	},
	{
			"value": "MIDNIGHT JEWELS",
			"label": "MIDNIGHT JEWELS"
	},
	{
			"value": "MID WEIGHT POLY",
			"label": "MID WEIGHT POLY"
	},
	{
			"value": "MIDWEST DESIGN",
			"label": "MIDWEST DESIGN"
	},
	{
			"value": "MIDWEST PRODUCTS",
			"label": "MIDWEST PRODUCTS"
	},
	{
			"value": "MIGHTY BRIGHT",
			"label": "MIGHTY BRIGHT"
	},
	{
			"value": "MILESTONE",
			"label": "MILESTONE"
	},
	{
			"value": "MILITARY",
			"label": "MILITARY"
	},
	{
			"value": "MILITARY HAT",
			"label": "MILITARY HAT"
	},
	{
			"value": "MILK",
			"label": "MILK"
	},
	{
			"value": "MILK COOKIE SET",
			"label": "MILK COOKIE SET"
	},
	{
			"value": "MILLER",
			"label": "MILLER"
	},
	{
			"value": "MILLET",
			"label": "MILLET"
	},
	{
			"value": "MILL HILL",
			"label": "MILL HILL"
	},
	{
			"value": "MILLINERY NETTING",
			"label": "MILLINERY NETTING"
	},
	{
			"value": "MILTARY",
			"label": "MILTARY"
	},
	{
			"value": "MINI",
			"label": "MINI"
	},
	{
			"value": "MINI APPLE",
			"label": "MINI APPLE"
	},
	{
			"value": "MINI BAG KEYCHAIN",
			"label": "MINI BAG KEYCHAIN"
	},
	{
			"value": "MINI BAKING CUPS",
			"label": "MINI BAKING CUPS"
	},
	{
			"value": "MINI BEADS",
			"label": "MINI BEADS"
	},
	{
			"value": "MINI BERRIES PAPER",
			"label": "MINI BERRIES PAPER"
	},
	{
			"value": "MINI BLOSSOM",
			"label": "MINI BLOSSOM"
	},
	{
			"value": "MINI BOW",
			"label": "MINI BOW"
	},
	{
			"value": "MINI BOX SET",
			"label": "MINI BOX SET"
	},
	{
			"value": "MINI BULLETIN BD SET",
			"label": "MINI BULLETIN BD SET"
	},
	{
			"value": "MINI CALENDAR",
			"label": "MINI CALENDAR"
	},
	{
			"value": "MINI CHECK",
			"label": "MINI CHECK"
	},
	{
			"value": "MINI CHIME",
			"label": "MINI CHIME"
	},
	{
			"value": "MINI CORD",
			"label": "MINI CORD"
	},
	{
			"value": "MINI EP RASPBERRY",
			"label": "MINI EP RASPBERRY"
	},
	{
			"value": "MINI EP ZEN BLUE",
			"label": "MINI EP ZEN BLUE"
	},
	{
			"value": "MINI FASHION BOW",
			"label": "MINI FASHION BOW"
	},
	{
			"value": "MINI FLAG W HOLDER",
			"label": "MINI FLAG W HOLDER"
	},
	{
			"value": "MINI FLIP TOP",
			"label": "MINI FLIP TOP"
	},
	{
			"value": "MINI FLOWER",
			"label": "MINI FLOWER"
	},
	{
			"value": "MINI FLOWERS",
			"label": "MINI FLOWERS"
	},
	{
			"value": "MINI GARLAND",
			"label": "MINI GARLAND"
	},
	{
			"value": "MINI GERANIUM",
			"label": "MINI GERANIUM"
	},
	{
			"value": "MINI GROUP KIT",
			"label": "MINI GROUP KIT"
	},
	{
			"value": "MINI HOUSE",
			"label": "MINI HOUSE"
	},
	{
			"value": "MINI IRON",
			"label": "MINI IRON"
	},
	{
			"value": "MINI JAR",
			"label": "MINI JAR"
	},
	{
			"value": "MINI KINETIC",
			"label": "MINI KINETIC"
	},
	{
			"value": "MINI KIT",
			"label": "MINI KIT"
	},
	{
			"value": "MINI LEAVES",
			"label": "MINI LEAVES"
	},
	{
			"value": "MINIMALIST",
			"label": "MINIMALIST"
	},
	{
			"value": "MINI MICRO BEAD FILL",
			"label": "MINI MICRO BEAD FILL"
	},
	{
			"value": "MINION",
			"label": "MINION"
	},
	{
			"value": "MINIONS",
			"label": "MINIONS"
	},
	{
			"value": "MINI ORN",
			"label": "MINI ORN"
	},
	{
			"value": "MINI ORNAMENT",
			"label": "MINI ORNAMENT"
	},
	{
			"value": "MINI ORNAMENT PANEL",
			"label": "MINI ORNAMENT PANEL"
	},
	{
			"value": "MINI ORNAMENTS",
			"label": "MINI ORNAMENTS"
	},
	{
			"value": "MINI POPPY",
			"label": "MINI POPPY"
	},
	{
			"value": "MINI POTS",
			"label": "MINI POTS"
	},
	{
			"value": "MINI PUMPKIN",
			"label": "MINI PUMPKIN"
	},
	{
			"value": "MINI QUILT",
			"label": "MINI QUILT"
	},
	{
			"value": "MINI QUILTS",
			"label": "MINI QUILTS"
	},
	{
			"value": "MINI SIGN",
			"label": "MINI SIGN"
	},
	{
			"value": "MINI SKELETON",
			"label": "MINI SKELETON"
	},
	{
			"value": "MINI STAKE",
			"label": "MINI STAKE"
	},
	{
			"value": "MINI STOCKING",
			"label": "MINI STOCKING"
	},
	{
			"value": "MINI TABLE",
			"label": "MINI TABLE"
	},
	{
			"value": "MINI TABLE DÉCOR",
			"label": "MINI TABLE DÉCOR"
	},
	{
			"value": "MINI TINSEL",
			"label": "MINI TINSEL"
	},
	{
			"value": "MINI TOMBSTONE",
			"label": "MINI TOMBSTONE"
	},
	{
			"value": "MINI TREE",
			"label": "MINI TREE"
	},
	{
			"value": "MINI TREE SKIRT",
			"label": "MINI TREE SKIRT"
	},
	{
			"value": "MINI TREE TOPPER",
			"label": "MINI TREE TOPPER"
	},
	{
			"value": "MINI TRIM A TREE",
			"label": "MINI TRIM A TREE"
	},
	{
			"value": "MINI WALL DECOR",
			"label": "MINI WALL DECOR"
	},
	{
			"value": "MINI WISTERIA",
			"label": "MINI WISTERIA"
	},
	{
			"value": "MINI WREATH",
			"label": "MINI WREATH"
	},
	{
			"value": "MINI WREATHS",
			"label": "MINI WREATHS"
	},
	{
			"value": "MINI WRTH",
			"label": "MINI WRTH"
	},
	{
			"value": "MINI YARD STAKES",
			"label": "MINI YARD STAKES"
	},
	{
			"value": "MINNIE",
			"label": "MINNIE"
	},
	{
			"value": "MINNIE/MICKEY",
			"label": "MINNIE/MICKEY"
	},
	{
			"value": "MINNIE MOUSE",
			"label": "MINNIE MOUSE"
	},
	{
			"value": "MINT",
			"label": "MINT"
	},
	{
			"value": "MINTS",
			"label": "MINTS"
	},
	{
			"value": "MIRACLE COATINGS",
			"label": "MIRACLE COATINGS"
	},
	{
			"value": "MIRROR",
			"label": "MIRROR"
	},
	{
			"value": "MIRRORS",
			"label": "MIRRORS"
	},
	{
			"value": "MIRROR WD",
			"label": "MIRROR WD"
	},
	{
			"value": "MISC",
			"label": "MISC"
	},
	{
			"value": "MISC.",
			"label": "MISC."
	},
	{
			"value": "MISC CANVAS",
			"label": "MISC CANVAS"
	},
	{
			"value": "MISC. COMPONENTS",
			"label": "MISC. COMPONENTS"
	},
	{
			"value": "MISC. CRAFTING",
			"label": "MISC. CRAFTING"
	},
	{
			"value": "MISC DISCARD",
			"label": "MISC DISCARD"
	},
	{
			"value": "MISC. FELT",
			"label": "MISC. FELT"
	},
	{
			"value": "MISC. GLITTER",
			"label": "MISC. GLITTER"
	},
	{
			"value": "MISC JOANN.COM",
			"label": "MISC JOANN.COM"
	},
	{
			"value": "MISC. KITS",
			"label": "MISC. KITS"
	},
	{
			"value": "MISCL TOLL",
			"label": "MISCL TOLL"
	},
	{
			"value": "MISCL TOOL",
			"label": "MISCL TOOL"
	},
	{
			"value": "MISC MARKETING",
			"label": "MISC MARKETING"
	},
	{
			"value": "MISC MATERIAL",
			"label": "MISC MATERIAL"
	},
	{
			"value": "MISC MATERIALS",
			"label": "MISC MATERIALS"
	},
	{
			"value": "MISC PERM VINYL",
			"label": "MISC PERM VINYL"
	},
	{
			"value": "MISC PURSE",
			"label": "MISC PURSE"
	},
	{
			"value": "MISC REMV VINYL",
			"label": "MISC REMV VINYL"
	},
	{
			"value": "MISC SINGER/VIKING",
			"label": "MISC SINGER/VIKING"
	},
	{
			"value": "MISC TOOL",
			"label": "MISC TOOL"
	},
	{
			"value": "MISC VINYL",
			"label": "MISC VINYL"
	},
	{
			"value": "MISSISSIPPI ST",
			"label": "MISSISSIPPI ST"
	},
	{
			"value": "MISSOURI",
			"label": "MISSOURI"
	},
	{
			"value": "MISTLETOE",
			"label": "MISTLETOE"
	},
	{
			"value": "MITTENS",
			"label": "MITTENS"
	},
	{
			"value": "MIX",
			"label": "MIX"
	},
	{
			"value": "MIXED",
			"label": "MIXED"
	},
	{
			"value": "MIXED FLORAL",
			"label": "MIXED FLORAL"
	},
	{
			"value": "MIXED LEAVES",
			"label": "MIXED LEAVES"
	},
	{
			"value": "MIXED-MATERIAL",
			"label": "MIXED-MATERIAL"
	},
	{
			"value": "MIXED MEDIA",
			"label": "MIXED MEDIA"
	},
	{
			"value": "MIXED MEDIA/ALTERED",
			"label": "MIXED MEDIA/ALTERED"
	},
	{
			"value": "MIXED PINE TREE",
			"label": "MIXED PINE TREE"
	},
	{
			"value": "MIXED STYLES",
			"label": "MIXED STYLES"
	},
	{
			"value": "MIXED SYLES",
			"label": "MIXED SYLES"
	},
	{
			"value": "MIXES",
			"label": "MIXES"
	},
	{
			"value": "MIXING CUP",
			"label": "MIXING CUP"
	},
	{
			"value": "MIXING STICK",
			"label": "MIXING STICK"
	},
	{
			"value": "MIXMAT - HEADER",
			"label": "MIXMAT - HEADER"
	},
	{
			"value": "MIYUKI",
			"label": "MIYUKI"
	},
	{
			"value": "MLB",
			"label": "MLB"
	},
	{
			"value": "MLB CORAL FLEECE",
			"label": "MLB CORAL FLEECE"
	},
	{
			"value": "MLB COTTON",
			"label": "MLB COTTON"
	},
	{
			"value": "MLB CTN",
			"label": "MLB CTN"
	},
	{
			"value": "MLB FLANNEL",
			"label": "MLB FLANNEL"
	},
	{
			"value": "MLB FLC",
			"label": "MLB FLC"
	},
	{
			"value": "MLB FLEECE",
			"label": "MLB FLEECE"
	},
	{
			"value": "MLB LICENSED PRODUCT",
			"label": "MLB LICENSED PRODUCT"
	},
	{
			"value": "MLB PKGD",
			"label": "MLB PKGD"
	},
	{
			"value": "MLB SWEATSHIRT FLEEC",
			"label": "MLB SWEATSHIRT FLEEC"
	},
	{
			"value": "MLB ULTRA CUDDLE",
			"label": "MLB ULTRA CUDDLE"
	},
	{
			"value": "MLS CTN",
			"label": "MLS CTN"
	},
	{
			"value": "MLS FLC",
			"label": "MLS FLC"
	},
	{
			"value": "MM 80/20 WOOL BLEND",
			"label": "MM 80/20 WOOL BLEND"
	},
	{
			"value": "M MIRAGE ME CANDLE",
			"label": "M MIRAGE ME CANDLE"
	},
	{
			"value": "MM PKGD BATTING",
			"label": "MM PKGD BATTING"
	},
	{
			"value": "MM QUILT LITE",
			"label": "MM QUILT LITE"
	},
	{
			"value": "MODE INTERNATIONAL",
			"label": "MODE INTERNATIONAL"
	},
	{
			"value": "MODEL ACCESSORIES",
			"label": "MODEL ACCESSORIES"
	},
	{
			"value": "MODEL BUILD BRUSHES",
			"label": "MODEL BUILD BRUSHES"
	},
	{
			"value": "MODEL BUILD CEMENT",
			"label": "MODEL BUILD CEMENT"
	},
	{
			"value": "MODEL BUILDING KIT",
			"label": "MODEL BUILDING KIT"
	},
	{
			"value": "MODEL BUILDING PAINT",
			"label": "MODEL BUILDING PAINT"
	},
	{
			"value": "MODEL CARS",
			"label": "MODEL CARS"
	},
	{
			"value": "MODELING CLAY",
			"label": "MODELING CLAY"
	},
	{
			"value": "MODEL MAGIC",
			"label": "MODEL MAGIC"
	},
	{
			"value": "MODERN",
			"label": "MODERN"
	},
	{
			"value": "MODERN-A",
			"label": "MODERN-A"
	},
	{
			"value": "MODERN-B",
			"label": "MODERN-B"
	},
	{
			"value": "MODERN BOHEMIAN",
			"label": "MODERN BOHEMIAN"
	},
	{
			"value": "MODERN-C",
			"label": "MODERN-C"
	},
	{
			"value": "MODERN-D",
			"label": "MODERN-D"
	},
	{
			"value": "MODERN FLORAL",
			"label": "MODERN FLORAL"
	},
	{
			"value": "MODERN FOLK",
			"label": "MODERN FOLK"
	},
	{
			"value": "MODERN GEO",
			"label": "MODERN GEO"
	},
	{
			"value": "MODERN LUXE BI",
			"label": "MODERN LUXE BI"
	},
	{
			"value": "MODERN MOSAIC",
			"label": "MODERN MOSAIC"
	},
	{
			"value": "MODERN QUILTS",
			"label": "MODERN QUILTS"
	},
	{
			"value": "MODERN-V",
			"label": "MODERN-V"
	},
	{
			"value": "MODERN-X",
			"label": "MODERN-X"
	},
	{
			"value": "MODERN-Y",
			"label": "MODERN-Y"
	},
	{
			"value": "MOLD",
			"label": "MOLD"
	},
	{
			"value": "MOLDED SOAP",
			"label": "MOLDED SOAP"
	},
	{
			"value": "MOLDPRESS ACCESSORIE",
			"label": "MOLDPRESS ACCESSORIE"
	},
	{
			"value": "MOLDS",
			"label": "MOLDS"
	},
	{
			"value": "MOLESKIN",
			"label": "MOLESKIN"
	},
	{
			"value": "MOLESKIN SOLIDS",
			"label": "MOLESKIN SOLIDS"
	},
	{
			"value": "MOMENT TOWEL",
			"label": "MOMENT TOWEL"
	},
	{
			"value": "MOMENT WORDBLOCK",
			"label": "MOMENT WORDBLOCK"
	},
	{
			"value": "MONESTERA LEAF",
			"label": "MONESTERA LEAF"
	},
	{
			"value": "MONESTERA LEAVES",
			"label": "MONESTERA LEAVES"
	},
	{
			"value": "MONESTERA WREATH",
			"label": "MONESTERA WREATH"
	},
	{
			"value": "MONESTERRA LEAF",
			"label": "MONESTERRA LEAF"
	},
	{
			"value": "MONKEY",
			"label": "MONKEY"
	},
	{
			"value": "MONKSCLOTH",
			"label": "MONKSCLOTH"
	},
	{
			"value": "MONO",
			"label": "MONO"
	},
	{
			"value": "MONOGRAM",
			"label": "MONOGRAM"
	},
	{
			"value": "MONOGRAM BANNER",
			"label": "MONOGRAM BANNER"
	},
	{
			"value": "MONOGRAM FASHION",
			"label": "MONOGRAM FASHION"
	},
	{
			"value": "MONOGRAM ORNAMENTS",
			"label": "MONOGRAM ORNAMENTS"
	},
	{
			"value": "MONSTER",
			"label": "MONSTER"
	},
	{
			"value": "MONSTERA",
			"label": "MONSTERA"
	},
	{
			"value": "MONSTER HIGH",
			"label": "MONSTER HIGH"
	},
	{
			"value": "MONSTER LEGS",
			"label": "MONSTER LEGS"
	},
	{
			"value": "MONSTERS",
			"label": "MONSTERS"
	},
	{
			"value": "MONSTER SHREDS",
			"label": "MONSTER SHREDS"
	},
	{
			"value": "MONTROSE COLORS",
			"label": "MONTROSE COLORS"
	},
	{
			"value": "MOOD INDIGO",
			"label": "MOOD INDIGO"
	},
	{
			"value": "MOON",
			"label": "MOON"
	},
	{
			"value": "MOON PHASES",
			"label": "MOON PHASES"
	},
	{
			"value": "MOON STARS",
			"label": "MOON STARS"
	},
	{
			"value": "MOORE PUSH PIN",
			"label": "MOORE PUSH PIN"
	},
	{
			"value": "MOOSE",
			"label": "MOOSE"
	},
	{
			"value": "MORE IS MORE",
			"label": "MORE IS MORE"
	},
	{
			"value": "MORGAN PRODUCTS",
			"label": "MORGAN PRODUCTS"
	},
	{
			"value": "MORNING DEW DROPS",
			"label": "MORNING DEW DROPS"
	},
	{
			"value": "MORNING GLORY",
			"label": "MORNING GLORY"
	},
	{
			"value": "MOSAIC MERCANTILE",
			"label": "MOSAIC MERCANTILE"
	},
	{
			"value": "MOSAIC STONE",
			"label": "MOSAIC STONE"
	},
	{
			"value": "MOSCOW MULE",
			"label": "MOSCOW MULE"
	},
	{
			"value": "MOSS",
			"label": "MOSS"
	},
	{
			"value": "MOSS BALL",
			"label": "MOSS BALL"
	},
	{
			"value": "MOSS/BARK",
			"label": "MOSS/BARK"
	},
	{
			"value": "MOSS COCO",
			"label": "MOSS COCO"
	},
	{
			"value": "MOSS LINER",
			"label": "MOSS LINER"
	},
	{
			"value": "MOTH",
			"label": "MOTH"
	},
	{
			"value": "MOTIF",
			"label": "MOTIF"
	},
	{
			"value": "MOTIF NETTING",
			"label": "MOTIF NETTING"
	},
	{
			"value": "MOTIFS",
			"label": "MOTIFS"
	},
	{
			"value": "MOTORCYCLE",
			"label": "MOTORCYCLE"
	},
	{
			"value": "MOTORCYCLES",
			"label": "MOTORCYCLES"
	},
	{
			"value": "MOUNTAIN",
			"label": "MOUNTAIN"
	},
	{
			"value": "MOUNTAIN MIST BATTIN",
			"label": "MOUNTAIN MIST BATTIN"
	},
	{
			"value": "MOUNTAIN MIST FIBER",
			"label": "MOUNTAIN MIST FIBER"
	},
	{
			"value": "MOUSE",
			"label": "MOUSE"
	},
	{
			"value": "MOUSTACHE",
			"label": "MOUSTACHE"
	},
	{
			"value": "MOUSTACHES",
			"label": "MOUSTACHES"
	},
	{
			"value": "MOXXIE",
			"label": "MOXXIE"
	},
	{
			"value": "MPI",
			"label": "MPI"
	},
	{
			"value": "MPL",
			"label": "MPL"
	},
	{
			"value": "MPL BRY GRLND",
			"label": "MPL BRY GRLND"
	},
	{
			"value": "MRS. CLAUS",
			"label": "MRS. CLAUS"
	},
	{
			"value": "MRS GROSSMAN",
			"label": "MRS GROSSMAN"
	},
	{
			"value": "MSE",
			"label": "MSE"
	},
	{
			"value": "M SIZE",
			"label": "M SIZE"
	},
	{
			"value": "MUFFIN PAN",
			"label": "MUFFIN PAN"
	},
	{
			"value": "MUG",
			"label": "MUG"
	},
	{
			"value": "MUG 2PK",
			"label": "MUG 2PK"
	},
	{
			"value": "MUG 4PK",
			"label": "MUG 4PK"
	},
	{
			"value": "MUG BLANK",
			"label": "MUG BLANK"
	},
	{
			"value": "MUG OVERSIZED",
			"label": "MUG OVERSIZED"
	},
	{
			"value": "MUGPRESS",
			"label": "MUGPRESS"
	},
	{
			"value": "MUGPRESS INF INK",
			"label": "MUGPRESS INF INK"
	},
	{
			"value": "MUGS",
			"label": "MUGS"
	},
	{
			"value": "MUG SET",
			"label": "MUG SET"
	},
	{
			"value": "MUG SPECIALTY",
			"label": "MUG SPECIALTY"
	},
	{
			"value": "MUG STANDARD",
			"label": "MUG STANDARD"
	},
	{
			"value": "MUITI",
			"label": "MUITI"
	},
	{
			"value": "MULTI",
			"label": "MULTI"
	},
	{
			"value": "MULTI BEADS",
			"label": "MULTI BEADS"
	},
	{
			"value": "MULTI BELL",
			"label": "MULTI BELL"
	},
	{
			"value": "MULTI CLASPS",
			"label": "MULTI CLASPS"
	},
	{
			"value": "MULTI COLOR SKINS",
			"label": "MULTI COLOR SKINS"
	},
	{
			"value": "MULTICRAFT IMPORTS",
			"label": "MULTICRAFT IMPORTS"
	},
	{
			"value": "MULTI FLORAL",
			"label": "MULTI FLORAL"
	},
	{
			"value": "MULTI LENGTH",
			"label": "MULTI LENGTH"
	},
	{
			"value": "MULTI LOOPS",
			"label": "MULTI LOOPS"
	},
	{
			"value": "MULTI PACK",
			"label": "MULTI PACK"
	},
	{
			"value": "MULTI PACK POM",
			"label": "MULTI PACK POM"
	},
	{
			"value": "MULTI PK TOTE",
			"label": "MULTI PK TOTE"
	},
	{
			"value": "MULTIPLICATION",
			"label": "MULTIPLICATION"
	},
	{
			"value": "MULTIPURPOSE",
			"label": "MULTIPURPOSE"
	},
	{
			"value": "MULTI SECTION",
			"label": "MULTI SECTION"
	},
	{
			"value": "MULTI-STRAND",
			"label": "MULTI-STRAND"
	},
	{
			"value": "MULTI STRIPE",
			"label": "MULTI STRIPE"
	},
	{
			"value": "MULTI TIER PLASTIC",
			"label": "MULTI TIER PLASTIC"
	},
	{
			"value": "MUM",
			"label": "MUM"
	},
	{
			"value": "MUM BSH",
			"label": "MUM BSH"
	},
	{
			"value": "MUM BUSH IN POT",
			"label": "MUM BUSH IN POT"
	},
	{
			"value": "MUM/CHINESE LANTERN",
			"label": "MUM/CHINESE LANTERN"
	},
	{
			"value": "MUM DAHLIA",
			"label": "MUM DAHLIA"
	},
	{
			"value": "MUMMIES",
			"label": "MUMMIES"
	},
	{
			"value": "MUMMY",
			"label": "MUMMY"
	},
	{
			"value": "MUM PEAR ARTICHOKE",
			"label": "MUM PEAR ARTICHOKE"
	},
	{
			"value": "MUMS",
			"label": "MUMS"
	},
	{
			"value": "MUNDIAL",
			"label": "MUNDIAL"
	},
	{
			"value": "MUSHROOM",
			"label": "MUSHROOM"
	},
	{
			"value": "MUSHROOMS",
			"label": "MUSHROOMS"
	},
	{
			"value": "MUSIC",
			"label": "MUSIC"
	},
	{
			"value": "MUSICAL",
			"label": "MUSICAL"
	},
	{
			"value": "MUSICAL & INSTRUMENT",
			"label": "MUSICAL & INSTRUMENT"
	},
	{
			"value": "MUSICAL STARS",
			"label": "MUSICAL STARS"
	},
	{
			"value": "MUSIC BOX",
			"label": "MUSIC BOX"
	},
	{
			"value": "MUSIC/HOBBIES/ARTS &",
			"label": "MUSIC/HOBBIES/ARTS &"
	},
	{
			"value": "MUSIC/HOBBY/ART/CRAF",
			"label": "MUSIC/HOBBY/ART/CRAF"
	},
	{
			"value": "MUSTACHE",
			"label": "MUSTACHE"
	},
	{
			"value": "MXD",
			"label": "MXD"
	},
	{
			"value": "MXD BRY",
			"label": "MXD BRY"
	},
	{
			"value": "MXD CANDLE RING",
			"label": "MXD CANDLE RING"
	},
	{
			"value": "MXD DAISY BRY BSH",
			"label": "MXD DAISY BRY BSH"
	},
	{
			"value": "MXD FOLIAGE",
			"label": "MXD FOLIAGE"
	},
	{
			"value": "MXD GRASS BSH",
			"label": "MXD GRASS BSH"
	},
	{
			"value": "MXD LVS/BRY IN BASKE",
			"label": "MXD LVS/BRY IN BASKE"
	},
	{
			"value": "MXD MUM GRASS BSH",
			"label": "MXD MUM GRASS BSH"
	},
	{
			"value": "MXD MUM/WHEAT IN PMP",
			"label": "MXD MUM/WHEAT IN PMP"
	},
	{
			"value": "MY FAVORITE COLORS",
			"label": "MY FAVORITE COLORS"
	},
	{
			"value": "MY FAVORITE THINGS",
			"label": "MY FAVORITE THINGS"
	},
	{
			"value": "MYLAR VINYL",
			"label": "MYLAR VINYL"
	},
	{
			"value": "MY LITTLE PONY",
			"label": "MY LITTLE PONY"
	},
	{
			"value": "MY LITTLE SHOEBOX",
			"label": "MY LITTLE SHOEBOX"
	},
	{
			"value": "MY MINDS EYE",
			"label": "MY MINDS EYE"
	},
	{
			"value": "MYSTEREAL",
			"label": "MYSTEREAL"
	},
	{
			"value": "MYSTICAL BERRY WOODS",
			"label": "MYSTICAL BERRY WOODS"
	},
	{
			"value": "MYSTIC HALLOWEEN RIB",
			"label": "MYSTIC HALLOWEEN RIB"
	},
	{
			"value": "MYSTIC RIBBON",
			"label": "MYSTIC RIBBON"
	},
	{
			"value": "MYSTIQUE",
			"label": "MYSTIQUE"
	},
	{
			"value": "MYTHICAL & FANTASY",
			"label": "MYTHICAL & FANTASY"
	},
	{
			"value": "N/A",
			"label": "N/A"
	},
	{
			"value": "NAIL",
			"label": "NAIL"
	},
	{
			"value": "NAILHEAD",
			"label": "NAILHEAD"
	},
	{
			"value": "NAILS",
			"label": "NAILS"
	},
	{
			"value": "NAIVE",
			"label": "NAIVE"
	},
	{
			"value": "NAME CARD",
			"label": "NAME CARD"
	},
	{
			"value": "NAMEPLATE",
			"label": "NAMEPLATE"
	},
	{
			"value": "NAME TAG",
			"label": "NAME TAG"
	},
	{
			"value": "NAME TENT",
			"label": "NAME TENT"
	},
	{
			"value": "NANDINA",
			"label": "NANDINA"
	},
	{
			"value": "NANDINA BRY",
			"label": "NANDINA BRY"
	},
	{
			"value": "NANTUCKET",
			"label": "NANTUCKET"
	},
	{
			"value": "NAPA",
			"label": "NAPA"
	},
	{
			"value": "NAPKIN",
			"label": "NAPKIN"
	},
	{
			"value": "NAPKIN 16CT",
			"label": "NAPKIN 16CT"
	},
	{
			"value": "NAPKIN 20CT",
			"label": "NAPKIN 20CT"
	},
	{
			"value": "NAPKIN BEVERAGE",
			"label": "NAPKIN BEVERAGE"
	},
	{
			"value": "NAPKIN GUEST",
			"label": "NAPKIN GUEST"
	},
	{
			"value": "NAPKIN HOLDER",
			"label": "NAPKIN HOLDER"
	},
	{
			"value": "NAPKIN LUNCH",
			"label": "NAPKIN LUNCH"
	},
	{
			"value": "NAPKIN RING",
			"label": "NAPKIN RING"
	},
	{
			"value": "NAPKIN RING SET",
			"label": "NAPKIN RING SET"
	},
	{
			"value": "NAPKINS",
			"label": "NAPKINS"
	},
	{
			"value": "NAPKIN SET",
			"label": "NAPKIN SET"
	},
	{
			"value": "NARCISSUS",
			"label": "NARCISSUS"
	},
	{
			"value": "NAT 10 S",
			"label": "NAT 10 S"
	},
	{
			"value": "NAT 12 L",
			"label": "NAT 12 L"
	},
	{
			"value": "NAT GEO STEM KIT",
			"label": "NAT GEO STEM KIT"
	},
	{
			"value": "NAT HOUSE",
			"label": "NAT HOUSE"
	},
	{
			"value": "NATIONWIDE CHILD",
			"label": "NATIONWIDE CHILD"
	},
	{
			"value": "NATIVITY",
			"label": "NATIVITY"
	},
	{
			"value": "NAT PMPKN",
			"label": "NAT PMPKN"
	},
	{
			"value": "NATUICAL",
			"label": "NATUICAL"
	},
	{
			"value": "NATURAL",
			"label": "NATURAL"
	},
	{
			"value": "NATURAL BUNNY",
			"label": "NATURAL BUNNY"
	},
	{
			"value": "NATURAL COMFORTS",
			"label": "NATURAL COMFORTS"
	},
	{
			"value": "NATURAL ELEMENTS",
			"label": "NATURAL ELEMENTS"
	},
	{
			"value": "NATURAL INSTINCTS",
			"label": "NATURAL INSTINCTS"
	},
	{
			"value": "NATURAL MUSLIN",
			"label": "NATURAL MUSLIN"
	},
	{
			"value": "NATURALS",
			"label": "NATURALS"
	},
	{
			"value": "NATURALS ACCESSORIES",
			"label": "NATURALS ACCESSORIES"
	},
	{
			"value": "NATURAL TEXTURE",
			"label": "NATURAL TEXTURE"
	},
	{
			"value": "NATURAL WONDERS",
			"label": "NATURAL WONDERS"
	},
	{
			"value": "NATURE",
			"label": "NATURE"
	},
	{
			"value": "NATURE CRAFTS FOR HM",
			"label": "NATURE CRAFTS FOR HM"
	},
	{
			"value": "NATURE'S BEAUTY",
			"label": "NATURE'S BEAUTY"
	},
	{
			"value": "NATURES COLLECTION",
			"label": "NATURES COLLECTION"
	},
	{
			"value": "NATURES & OUTDOORS",
			"label": "NATURES & OUTDOORS"
	},
	{
			"value": "NAT WALL",
			"label": "NAT WALL"
	},
	{
			"value": "NAT/WHT",
			"label": "NAT/WHT"
	},
	{
			"value": "NAUCTICAL",
			"label": "NAUCTICAL"
	},
	{
			"value": "NAUTICAL",
			"label": "NAUTICAL"
	},
	{
			"value": "NAUTICAL NOEL OSO",
			"label": "NAUTICAL NOEL OSO"
	},
	{
			"value": "NAUTICAL OSO",
			"label": "NAUTICAL OSO"
	},
	{
			"value": "NAUTICAL/WILDERNESS",
			"label": "NAUTICAL/WILDERNESS"
	},
	{
			"value": "NAVAHO",
			"label": "NAVAHO"
	},
	{
			"value": "NAVY",
			"label": "NAVY"
	},
	{
			"value": "NAVY AND ORANGE RIBB",
			"label": "NAVY AND ORANGE RIBB"
	},
	{
			"value": "NBA",
			"label": "NBA"
	},
	{
			"value": "NBA COTTON",
			"label": "NBA COTTON"
	},
	{
			"value": "NBA CTN",
			"label": "NBA CTN"
	},
	{
			"value": "NBA FLC",
			"label": "NBA FLC"
	},
	{
			"value": "NBA FLEECE",
			"label": "NBA FLEECE"
	},
	{
			"value": "NBA LICENSED PRODUCT",
			"label": "NBA LICENSED PRODUCT"
	},
	{
			"value": "NBA PKGD",
			"label": "NBA PKGD"
	},
	{
			"value": "NBC",
			"label": "NBC"
	},
	{
			"value": "NCAA",
			"label": "NCAA"
	},
	{
			"value": "NCAA LICENSED PRODUC",
			"label": "NCAA LICENSED PRODUC"
	},
	{
			"value": "NDL GUIDE",
			"label": "NDL GUIDE"
	},
	{
			"value": "NEBRASKA",
			"label": "NEBRASKA"
	},
	{
			"value": "NECK/EAR SET",
			"label": "NECK/EAR SET"
	},
	{
			"value": "NECKLACE",
			"label": "NECKLACE"
	},
	{
			"value": "NECKLACE DISPLAY",
			"label": "NECKLACE DISPLAY"
	},
	{
			"value": "NECKLACE-EARRINGS",
			"label": "NECKLACE-EARRINGS"
	},
	{
			"value": "NECKLACE FINDINGS KI",
			"label": "NECKLACE FINDINGS KI"
	},
	{
			"value": "NECKLACE KIT",
			"label": "NECKLACE KIT"
	},
	{
			"value": "NECKLACE SET",
			"label": "NECKLACE SET"
	},
	{
			"value": "NECKTIE",
			"label": "NECKTIE"
	},
	{
			"value": "NEEDLE",
			"label": "NEEDLE"
	},
	{
			"value": "NEEDLE ART",
			"label": "NEEDLE ART"
	},
	{
			"value": "NEEDLEARTS",
			"label": "NEEDLEARTS"
	},
	{
			"value": "NEEDLE ARTS BOOKS",
			"label": "NEEDLE ARTS BOOKS"
	},
	{
			"value": "NEEDLE CRAFTS",
			"label": "NEEDLE CRAFTS"
	},
	{
			"value": "NEEDLE FELTING BOOKL",
			"label": "NEEDLE FELTING BOOKL"
	},
	{
			"value": "NEEDLEPINE AND HOLLY",
			"label": "NEEDLEPINE AND HOLLY"
	},
	{
			"value": "NEEDLEPOINT",
			"label": "NEEDLEPOINT"
	},
	{
			"value": "NEEDLEPOINT KIT",
			"label": "NEEDLEPOINT KIT"
	},
	{
			"value": "NEEDLEPOINT SCARF",
			"label": "NEEDLEPOINT SCARF"
	},
	{
			"value": "NEEDLEPOINT TREND",
			"label": "NEEDLEPOINT TREND"
	},
	{
			"value": "NEEDLES",
			"label": "NEEDLES"
	},
	{
			"value": "NEEDLEWORK BOOKLET",
			"label": "NEEDLEWORK BOOKLET"
	},
	{
			"value": "NEON",
			"label": "NEON"
	},
	{
			"value": "NEON ASSORTMENT",
			"label": "NEON ASSORTMENT"
	},
	{
			"value": "NEON BOWS",
			"label": "NEON BOWS"
	},
	{
			"value": "NEON ULTRA CUDDLE",
			"label": "NEON ULTRA CUDDLE"
	},
	{
			"value": "NEOPRENE",
			"label": "NEOPRENE"
	},
	{
			"value": "NEOPRENE/SCUBA",
			"label": "NEOPRENE/SCUBA"
	},
	{
			"value": "NE PATRIOTS",
			"label": "NE PATRIOTS"
	},
	{
			"value": "NEPHTHYTIS",
			"label": "NEPHTHYTIS"
	},
	{
			"value": "NERINE",
			"label": "NERINE"
	},
	{
			"value": "NEST",
			"label": "NEST"
	},
	{
			"value": "NESTED PLANT STAND",
			"label": "NESTED PLANT STAND"
	},
	{
			"value": "NETTING",
			"label": "NETTING"
	},
	{
			"value": "NETTING & SCREENING",
			"label": "NETTING & SCREENING"
	},
	{
			"value": "NEUTRAL",
			"label": "NEUTRAL"
	},
	{
			"value": "NEW",
			"label": "NEW"
	},
	{
			"value": "NEW ADVANCED PRODUCT",
			"label": "NEW ADVANCED PRODUCT"
	},
	{
			"value": "NEW BASIC",
			"label": "NEW BASIC"
	},
	{
			"value": "NEW BIRD NEST GRASS",
			"label": "NEW BIRD NEST GRASS"
	},
	{
			"value": "NEW FY20",
			"label": "NEW FY20"
	},
	{
			"value": "NEW HEIRLOOM",
			"label": "NEW HEIRLOOM"
	},
	{
			"value": "NEW HERLOOM",
			"label": "NEW HERLOOM"
	},
	{
			"value": "NEW POG ITEMS",
			"label": "NEW POG ITEMS"
	},
	{
			"value": "NEW TRADITIONS",
			"label": "NEW TRADITIONS"
	},
	{
			"value": "NEW TRADITIONS OSO",
			"label": "NEW TRADITIONS OSO"
	},
	{
			"value": "NEXT LEVEL",
			"label": "NEXT LEVEL"
	},
	{
			"value": "NFL",
			"label": "NFL"
	},
	{
			"value": "NFL BURLAP",
			"label": "NFL BURLAP"
	},
	{
			"value": "NFL COTTON",
			"label": "NFL COTTON"
	},
	{
			"value": "NFL CTN",
			"label": "NFL CTN"
	},
	{
			"value": "NFL CUDDLE FLEECE",
			"label": "NFL CUDDLE FLEECE"
	},
	{
			"value": "NFL DUCK CANVAS",
			"label": "NFL DUCK CANVAS"
	},
	{
			"value": "NFL FLANNEL",
			"label": "NFL FLANNEL"
	},
	{
			"value": "NFL FLC",
			"label": "NFL FLC"
	},
	{
			"value": "NFL FLEECE",
			"label": "NFL FLEECE"
	},
	{
			"value": "NFL LICENSED PRODUCT",
			"label": "NFL LICENSED PRODUCT"
	},
	{
			"value": "NFL PANEL",
			"label": "NFL PANEL"
	},
	{
			"value": "NFL PKGD",
			"label": "NFL PKGD"
	},
	{
			"value": "NFL SHERPA",
			"label": "NFL SHERPA"
	},
	{
			"value": "NHL",
			"label": "NHL"
	},
	{
			"value": "NHL COTTON",
			"label": "NHL COTTON"
	},
	{
			"value": "NHL CTN",
			"label": "NHL CTN"
	},
	{
			"value": "NHL FLANNEL",
			"label": "NHL FLANNEL"
	},
	{
			"value": "NHL FLC",
			"label": "NHL FLC"
	},
	{
			"value": "NHL FLEECE",
			"label": "NHL FLEECE"
	},
	{
			"value": "NHL LICENSED PRODUCT",
			"label": "NHL LICENSED PRODUCT"
	},
	{
			"value": "NHL PKGD",
			"label": "NHL PKGD"
	},
	{
			"value": "NICE LIST OSO",
			"label": "NICE LIST OSO"
	},
	{
			"value": "NICHE",
			"label": "NICHE"
	},
	{
			"value": "NICKEL",
			"label": "NICKEL"
	},
	{
			"value": "NICKELODEON",
			"label": "NICKELODEON"
	},
	{
			"value": "NICOLE MILLER",
			"label": "NICOLE MILLER"
	},
	{
			"value": "NIGHTMARE BEFORE CMA",
			"label": "NIGHTMARE BEFORE CMA"
	},
	{
			"value": "NIKE CLOSE OUT",
			"label": "NIKE CLOSE OUT"
	},
	{
			"value": "NIKKI SIVILS",
			"label": "NIKKI SIVILS"
	},
	{
			"value": "NIMBLE THIMBLE",
			"label": "NIMBLE THIMBLE"
	},
	{
			"value": "NINTENDO",
			"label": "NINTENDO"
	},
	{
			"value": "NKOK",
			"label": "NKOK"
	},
	{
			"value": "NOBLE",
			"label": "NOBLE"
	},
	{
			"value": "NOEL",
			"label": "NOEL"
	},
	{
			"value": "NOEL COLLECTION",
			"label": "NOEL COLLECTION"
	},
	{
			"value": "NOMIS YARN",
			"label": "NOMIS YARN"
	},
	{
			"value": "NONE",
			"label": "NONE"
	},
	{
			"value": "NON FLORAL",
			"label": "NON FLORAL"
	},
	{
			"value": "NON PAREILS",
			"label": "NON PAREILS"
	},
	{
			"value": "NONROLL ELASTIC",
			"label": "NONROLL ELASTIC"
	},
	{
			"value": "NON SEASONAL",
			"label": "NON SEASONAL"
	},
	{
			"value": "NON SENTIMENT",
			"label": "NON SENTIMENT"
	},
	{
			"value": "NONSTICK BAKEWARE",
			"label": "NONSTICK BAKEWARE"
	},
	{
			"value": "NORCON INDUSTRIES",
			"label": "NORCON INDUSTRIES"
	},
	{
			"value": "NORDIC FRIENDS",
			"label": "NORDIC FRIENDS"
	},
	{
			"value": "NORDIC NOEL",
			"label": "NORDIC NOEL"
	},
	{
			"value": "NORDIC PRINCESS",
			"label": "NORDIC PRINCESS"
	},
	{
			"value": "NORTHERN LIGHTS",
			"label": "NORTHERN LIGHTS"
	},
	{
			"value": "NORTH LIGHT",
			"label": "NORTH LIGHT"
	},
	{
			"value": "NORTHWOODS",
			"label": "NORTHWOODS"
	},
	{
			"value": "NORWALL",
			"label": "NORWALL"
	},
	{
			"value": "NO SEW",
			"label": "NO SEW"
	},
	{
			"value": "NO SEW FLEECE HAT &",
			"label": "NO SEW FLEECE HAT &"
	},
	{
			"value": "NO SHOW",
			"label": "NO SHOW"
	},
	{
			"value": "NOSTALGIA",
			"label": "NOSTALGIA"
	},
	{
			"value": "NOSTALGIA OSO",
			"label": "NOSTALGIA OSO"
	},
	{
			"value": "NOSTALGIC",
			"label": "NOSTALGIC"
	},
	{
			"value": "NOT ASSIGNED",
			"label": "NOT ASSIGNED"
	},
	{
			"value": "NOTEBOOK",
			"label": "NOTEBOOK"
	},
	{
			"value": "NOTECARDS",
			"label": "NOTECARDS"
	},
	{
			"value": "NOTE NOOK",
			"label": "NOTE NOOK"
	},
	{
			"value": "NOTEPAD",
			"label": "NOTEPAD"
	},
	{
			"value": "NOTEPADS",
			"label": "NOTEPADS"
	},
	{
			"value": "NOTION BOOKS",
			"label": "NOTION BOOKS"
	},
	{
			"value": "NOTIONS",
			"label": "NOTIONS"
	},
	{
			"value": "NOTIONS BOOKS",
			"label": "NOTIONS BOOKS"
	},
	{
			"value": "NOTIONS SOURCED",
			"label": "NOTIONS SOURCED"
	},
	{
			"value": "NOTIONS/TRIMS/EMBSH",
			"label": "NOTIONS/TRIMS/EMBSH"
	},
	{
			"value": "NOUVEAU NOEL",
			"label": "NOUVEAU NOEL"
	},
	{
			"value": "NOUVELLES IMAGES",
			"label": "NOUVELLES IMAGES"
	},
	{
			"value": "NOV",
			"label": "NOV"
	},
	{
			"value": "NOV-CHALKBOARD",
			"label": "NOV-CHALKBOARD"
	},
	{
			"value": "NOV-COLOR SHIFT",
			"label": "NOV-COLOR SHIFT"
	},
	{
			"value": "NOVELTY",
			"label": "NOVELTY"
	},
	{
			"value": "NOVELTY - ANIMAL",
			"label": "NOVELTY - ANIMAL"
	},
	{
			"value": "NOVELTY - BEE",
			"label": "NOVELTY - BEE"
	},
	{
			"value": "NOVELTY - BEES",
			"label": "NOVELTY - BEES"
	},
	{
			"value": "NOVELTY BIRDHOUSE",
			"label": "NOVELTY BIRDHOUSE"
	},
	{
			"value": "NOVELTY - BUTTERFLY",
			"label": "NOVELTY - BUTTERFLY"
	},
	{
			"value": "NOVELTY CANDLE",
			"label": "NOVELTY CANDLE"
	},
	{
			"value": "NOVELTY CANDLES",
			"label": "NOVELTY CANDLES"
	},
	{
			"value": "NOVELTY CAR",
			"label": "NOVELTY CAR"
	},
	{
			"value": "NOVELTY - DOG",
			"label": "NOVELTY - DOG"
	},
	{
			"value": "NOVELTY - DRAGONFLY",
			"label": "NOVELTY - DRAGONFLY"
	},
	{
			"value": "NOVELTY - FOOD",
			"label": "NOVELTY - FOOD"
	},
	{
			"value": "NOVELTY - FRUIT",
			"label": "NOVELTY - FRUIT"
	},
	{
			"value": "NOVELTY GLASSES",
			"label": "NOVELTY GLASSES"
	},
	{
			"value": "NOVELTY - ICONS",
			"label": "NOVELTY - ICONS"
	},
	{
			"value": "NOVELTY JAR",
			"label": "NOVELTY JAR"
	},
	{
			"value": "NOVELTY JARS",
			"label": "NOVELTY JARS"
	},
	{
			"value": "NOVELTY LIGHTING",
			"label": "NOVELTY LIGHTING"
	},
	{
			"value": "NOVELTY LIGHTS",
			"label": "NOVELTY LIGHTS"
	},
	{
			"value": "NOVELTY - MISC",
			"label": "NOVELTY - MISC"
	},
	{
			"value": "NOVELTY - NAUTICAL",
			"label": "NOVELTY - NAUTICAL"
	},
	{
			"value": "NOVELTY PILLARS",
			"label": "NOVELTY PILLARS"
	},
	{
			"value": "NOVELTY PLANT STAND",
			"label": "NOVELTY PLANT STAND"
	},
	{
			"value": "NOVELTY PONY",
			"label": "NOVELTY PONY"
	},
	{
			"value": "NOVELTY STATUARY",
			"label": "NOVELTY STATUARY"
	},
	{
			"value": "NOVELTY STEM",
			"label": "NOVELTY STEM"
	},
	{
			"value": "NOVELTY TAPER",
			"label": "NOVELTY TAPER"
	},
	{
			"value": "NOVELTY TEALIGHTS",
			"label": "NOVELTY TEALIGHTS"
	},
	{
			"value": "NOVELTY VINYL",
			"label": "NOVELTY VINYL"
	},
	{
			"value": "NOVELTY WALL DECOR",
			"label": "NOVELTY WALL DECOR"
	},
	{
			"value": "NOVELTY WALL DÉCOR",
			"label": "NOVELTY WALL DÉCOR"
	},
	{
			"value": "NOVEMBER RTV",
			"label": "NOVEMBER RTV"
	},
	{
			"value": "NOV-ENAMEL",
			"label": "NOV-ENAMEL"
	},
	{
			"value": "NOVETLY",
			"label": "NOVETLY"
	},
	{
			"value": "NOVFY14 RTV",
			"label": "NOVFY14 RTV"
	},
	{
			"value": "NOV-GLITTER",
			"label": "NOV-GLITTER"
	},
	{
			"value": "NOV-GLOSS",
			"label": "NOV-GLOSS"
	},
	{
			"value": "NOV-GLOW",
			"label": "NOV-GLOW"
	},
	{
			"value": "NOV-METALLIC",
			"label": "NOV-METALLIC"
	},
	{
			"value": "NOV-PICKLING",
			"label": "NOV-PICKLING"
	},
	{
			"value": "NOV-SNOW",
			"label": "NOV-SNOW"
	},
	{
			"value": "NSI INTERNATIONAL",
			"label": "NSI INTERNATIONAL"
	},
	{
			"value": "NUBBY 20.30 IVR",
			"label": "NUBBY 20.30 IVR"
	},
	{
			"value": "NUFOAM",
			"label": "NUFOAM"
	},
	{
			"value": "NUFOAM 6PK",
			"label": "NUFOAM 6PK"
	},
	{
			"value": "NUMBER APPLIQUE",
			"label": "NUMBER APPLIQUE"
	},
	{
			"value": "NUMBERS",
			"label": "NUMBERS"
	},
	{
			"value": "NUMBER STAMP SET",
			"label": "NUMBER STAMP SET"
	},
	{
			"value": "NUMERAL",
			"label": "NUMERAL"
	},
	{
			"value": "NURSERY",
			"label": "NURSERY"
	},
	{
			"value": "NURSREY",
			"label": "NURSREY"
	},
	{
			"value": "NUTCCRACKER NATIONAL",
			"label": "NUTCCRACKER NATIONAL"
	},
	{
			"value": "NUTCRACKER",
			"label": "NUTCRACKER"
	},
	{
			"value": "NUTCRACKER DRUMMER",
			"label": "NUTCRACKER DRUMMER"
	},
	{
			"value": "NUTCRACKER GLITTER",
			"label": "NUTCRACKER GLITTER"
	},
	{
			"value": "NUTCRACKER HOBBY",
			"label": "NUTCRACKER HOBBY"
	},
	{
			"value": "NUTCRACKER KING",
			"label": "NUTCRACKER KING"
	},
	{
			"value": "NUTCRACKER MUSICAL",
			"label": "NUTCRACKER MUSICAL"
	},
	{
			"value": "NUTCRACKER NATIONALI",
			"label": "NUTCRACKER NATIONALI"
	},
	{
			"value": "NUTCRACKER PROFESSIO",
			"label": "NUTCRACKER PROFESSIO"
	},
	{
			"value": "NUTCRACKER SANTA",
			"label": "NUTCRACKER SANTA"
	},
	{
			"value": "NUTCRACKER SEQUIN",
			"label": "NUTCRACKER SEQUIN"
	},
	{
			"value": "NUTCRACKER SHOPPER",
			"label": "NUTCRACKER SHOPPER"
	},
	{
			"value": "NUTCRACKER SNOWMAN",
			"label": "NUTCRACKER SNOWMAN"
	},
	{
			"value": "NUTCRACKER SOLDIER",
			"label": "NUTCRACKER SOLDIER"
	},
	{
			"value": "NUTCRACKER SOLIDER",
			"label": "NUTCRACKER SOLIDER"
	},
	{
			"value": "NUTCRACKER STACKED",
			"label": "NUTCRACKER STACKED"
	},
	{
			"value": "NUTCRACKER WOODSMAN",
			"label": "NUTCRACKER WOODSMAN"
	},
	{
			"value": "NUTCUP",
			"label": "NUTCUP"
	},
	{
			"value": "NUT CUPS",
			"label": "NUT CUPS"
	},
	{
			"value": "NVELTY",
			"label": "NVELTY"
	},
	{
			"value": "NVY BACKGROUND",
			"label": "NVY BACKGROUND"
	},
	{
			"value": "NVYWHT STRIPE",
			"label": "NVYWHT STRIPE"
	},
	{
			"value": "NWALL",
			"label": "NWALL"
	},
	{
			"value": "NWALLA00",
			"label": "NWALLA00"
	},
	{
			"value": "NWALLD00",
			"label": "NWALLD00"
	},
	{
			"value": "NYLON",
			"label": "NYLON"
	},
	{
			"value": "NYLON NET",
			"label": "NYLON NET"
	},
	{
			"value": "NYLON THREAD",
			"label": "NYLON THREAD"
	},
	{
			"value": "N.Y. YARNS",
			"label": "N.Y. YARNS"
	},
	{
			"value": "NY YARNS",
			"label": "NY YARNS"
	},
	{
			"value": "OAK",
			"label": "OAK"
	},
	{
			"value": "OAK BERRY WREATH",
			"label": "OAK BERRY WREATH"
	},
	{
			"value": "OAKLAND RAIDERS",
			"label": "OAKLAND RAIDERS"
	},
	{
			"value": "OAK LEAF",
			"label": "OAK LEAF"
	},
	{
			"value": "OAK LEAF WALL DÉCOR",
			"label": "OAK LEAF WALL DÉCOR"
	},
	{
			"value": "OAK LEAVES",
			"label": "OAK LEAVES"
	},
	{
			"value": "OAK LVS",
			"label": "OAK LVS"
	},
	{
			"value": "OAK MPL GRLND",
			"label": "OAK MPL GRLND"
	},
	{
			"value": "OASIS",
			"label": "OASIS"
	},
	{
			"value": "OAT",
			"label": "OAT"
	},
	{
			"value": "OAT LEAVES",
			"label": "OAT LEAVES"
	},
	{
			"value": "OCCASIONS",
			"label": "OCCASIONS"
	},
	{
			"value": "OCCUPATION",
			"label": "OCCUPATION"
	},
	{
			"value": "OCCUPATION COSTUME",
			"label": "OCCUPATION COSTUME"
	},
	{
			"value": "OCEAN",
			"label": "OCEAN"
	},
	{
			"value": "OCT",
			"label": "OCT"
	},
	{
			"value": "OCTFY14 RTV",
			"label": "OCTFY14 RTV"
	},
	{
			"value": "OCTFY15RTV",
			"label": "OCTFY15RTV"
	},
	{
			"value": "OCTOBER AFTERNOON",
			"label": "OCTOBER AFTERNOON"
	},
	{
			"value": "OCTOBER RTV",
			"label": "OCTOBER RTV"
	},
	{
			"value": "OCTOPUS",
			"label": "OCTOPUS"
	},
	{
			"value": "ODFABA00",
			"label": "ODFABA00"
	},
	{
			"value": "OFFICE",
			"label": "OFFICE"
	},
	{
			"value": "OFFICE ACCESORIES",
			"label": "OFFICE ACCESORIES"
	},
	{
			"value": "OFFRAY",
			"label": "OFFRAY"
	},
	{
			"value": "OFF SET",
			"label": "OFF SET"
	},
	{
			"value": "OHIO ST",
			"label": "OHIO ST"
	},
	{
			"value": "OHIO STATE",
			"label": "OHIO STATE"
	},
	{
			"value": "OH THE PLACES YOU'LL",
			"label": "OH THE PLACES YOU'LL"
	},
	{
			"value": "OIL",
			"label": "OIL"
	},
	{
			"value": "OIL CLOTH",
			"label": "OIL CLOTH"
	},
	{
			"value": "OIL LAMP",
			"label": "OIL LAMP"
	},
	{
			"value": "OIL PASTELS",
			"label": "OIL PASTELS"
	},
	{
			"value": "OIL SLICK",
			"label": "OIL SLICK"
	},
	{
			"value": "OIL SLICK BONES",
			"label": "OIL SLICK BONES"
	},
	{
			"value": "OKLAHOMA",
			"label": "OKLAHOMA"
	},
	{
			"value": "OKLAHOMA ST",
			"label": "OKLAHOMA ST"
	},
	{
			"value": "OLFA",
			"label": "OLFA"
	},
	{
			"value": "OLIVE",
			"label": "OLIVE"
	},
	{
			"value": "OLIVE LEAF",
			"label": "OLIVE LEAF"
	},
	{
			"value": "OLIVE LEAVES",
			"label": "OLIVE LEAVES"
	},
	{
			"value": "OLIVES IN ACETATE BO",
			"label": "OLIVES IN ACETATE BO"
	},
	{
			"value": "OMBRE",
			"label": "OMBRE"
	},
	{
			"value": "OMBRE ANIMAL",
			"label": "OMBRE ANIMAL"
	},
	{
			"value": "OMBRE NAT",
			"label": "OMBRE NAT"
	},
	{
			"value": "OMBRE PILLOW",
			"label": "OMBRE PILLOW"
	},
	{
			"value": "OMBRE PLAID",
			"label": "OMBRE PLAID"
	},
	{
			"value": "OMBRE TAPER",
			"label": "OMBRE TAPER"
	},
	{
			"value": "OMBRE VASE",
			"label": "OMBRE VASE"
	},
	{
			"value": "ONE DIRECTION",
			"label": "ONE DIRECTION"
	},
	{
			"value": "ONESIES",
			"label": "ONESIES"
	},
	{
			"value": "ONE SIZE",
			"label": "ONE SIZE"
	},
	{
			"value": "ONION",
			"label": "ONION"
	},
	{
			"value": "ONION FLOWER",
			"label": "ONION FLOWER"
	},
	{
			"value": "ONION GRASS",
			"label": "ONION GRASS"
	},
	{
			"value": "ONION SKIN",
			"label": "ONION SKIN"
	},
	{
			"value": "ONLINE",
			"label": "ONLINE"
	},
	{
			"value": "ONLINE_ACTIVE",
			"label": "ONLINE_ACTIVE"
	},
	{
			"value": "ONLINE ONLY",
			"label": "ONLINE ONLY"
	},
	{
			"value": "ON STAND",
			"label": "ON STAND"
	},
	{
			"value": "ON THE FARM",
			"label": "ON THE FARM"
	},
	{
			"value": "OPEN ROAD",
			"label": "OPEN ROAD"
	},
	{
			"value": "OPEN TT LANTERN",
			"label": "OPEN TT LANTERN"
	},
	{
			"value": "OPEN WEAVE",
			"label": "OPEN WEAVE"
	},
	{
			"value": "OPPBUYKNITFW13",
			"label": "OPPBUYKNITFW13"
	},
	{
			"value": "OPPBUYKNITFW18",
			"label": "OPPBUYKNITFW18"
	},
	{
			"value": "OPPORTUNITY BUY",
			"label": "OPPORTUNITY BUY"
	},
	{
			"value": "OPPORTUNITY SUEDE",
			"label": "OPPORTUNITY SUEDE"
	},
	{
			"value": "OPPOSITES",
			"label": "OPPOSITES"
	},
	{
			"value": "OPTIMISM",
			"label": "OPTIMISM"
	},
	{
			"value": "ORACAL 651 PERM",
			"label": "ORACAL 651 PERM"
	},
	{
			"value": "ORACAL 851 SPARKLE",
			"label": "ORACAL 851 SPARKLE"
	},
	{
			"value": "ORACAL PERM VINYL",
			"label": "ORACAL PERM VINYL"
	},
	{
			"value": "ORACAL TRANSFER TAPE",
			"label": "ORACAL TRANSFER TAPE"
	},
	{
			"value": "ORANAMENTS",
			"label": "ORANAMENTS"
	},
	{
			"value": "ORANGE",
			"label": "ORANGE"
	},
	{
			"value": "ORANGE AND BROWN RIB",
			"label": "ORANGE AND BROWN RIB"
	},
	{
			"value": "ORANGE PUMPKIN",
			"label": "ORANGE PUMPKIN"
	},
	{
			"value": "ORB FACTORY",
			"label": "ORB FACTORY"
	},
	{
			"value": "ORCHID",
			"label": "ORCHID"
	},
	{
			"value": "ORCHID ARRANGEMENT",
			"label": "ORCHID ARRANGEMENT"
	},
	{
			"value": "ORCHID BLOOM",
			"label": "ORCHID BLOOM"
	},
	{
			"value": "ORCHID CHAIN",
			"label": "ORCHID CHAIN"
	},
	{
			"value": "ORCHID POT",
			"label": "ORCHID POT"
	},
	{
			"value": "OREGON",
			"label": "OREGON"
	},
	{
			"value": "ORGANIC COTTON",
			"label": "ORGANIC COTTON"
	},
	{
			"value": "ORGANIC COTTON BATTI",
			"label": "ORGANIC COTTON BATTI"
	},
	{
			"value": "ORGANIC ELEMENTS",
			"label": "ORGANIC ELEMENTS"
	},
	{
			"value": "ORGANICS",
			"label": "ORGANICS"
	},
	{
			"value": "ORGANIZATION",
			"label": "ORGANIZATION"
	},
	{
			"value": "ORGANIZATIONAL TRAY",
			"label": "ORGANIZATIONAL TRAY"
	},
	{
			"value": "ORGANIZER",
			"label": "ORGANIZER"
	},
	{
			"value": "ORGANIZERS POG",
			"label": "ORGANIZERS POG"
	},
	{
			"value": "ORGANZA",
			"label": "ORGANZA"
	},
	{
			"value": "ORGANZA BAG",
			"label": "ORGANZA BAG"
	},
	{
			"value": "ORGANZA POUCHES",
			"label": "ORGANZA POUCHES"
	},
	{
			"value": "ORIGAMI",
			"label": "ORIGAMI"
	},
	{
			"value": "ORIGAMI ACTIVITY",
			"label": "ORIGAMI ACTIVITY"
	},
	{
			"value": "ORIGAMI KIT",
			"label": "ORIGAMI KIT"
	},
	{
			"value": "ORIGNAL PRICE",
			"label": "ORIGNAL PRICE"
	},
	{
			"value": "ORNAMENT",
			"label": "ORNAMENT"
	},
	{
			"value": "ORNAMENT GLASS BOX",
			"label": "ORNAMENT GLASS BOX"
	},
	{
			"value": "ORNAMENT HOOK",
			"label": "ORNAMENT HOOK"
	},
	{
			"value": "ORNAMENT HOOKS",
			"label": "ORNAMENT HOOKS"
	},
	{
			"value": "ORNAMENT KIT",
			"label": "ORNAMENT KIT"
	},
	{
			"value": "ORNAMENT OPEN STOCK",
			"label": "ORNAMENT OPEN STOCK"
	},
	{
			"value": "ORNAMENTS",
			"label": "ORNAMENTS"
	},
	{
			"value": "ORNAMENT SET",
			"label": "ORNAMENT SET"
	},
	{
			"value": "ORNAMENT STORAGE",
			"label": "ORNAMENT STORAGE"
	},
	{
			"value": "ORNAMENT TREE",
			"label": "ORNAMENT TREE"
	},
	{
			"value": "ORNAMENT TUBE",
			"label": "ORNAMENT TUBE"
	},
	{
			"value": "ORNATE",
			"label": "ORNATE"
	},
	{
			"value": "ORNITHIGALIUM",
			"label": "ORNITHIGALIUM"
	},
	{
			"value": "ORNITHOGALUM",
			"label": "ORNITHOGALUM"
	},
	{
			"value": "ORNITHOGLUM",
			"label": "ORNITHOGLUM"
	},
	{
			"value": "OSMANTHUS",
			"label": "OSMANTHUS"
	},
	{
			"value": "OSNABURG",
			"label": "OSNABURG"
	},
	{
			"value": "OSO",
			"label": "OSO"
	},
	{
			"value": "OSO BABY",
			"label": "OSO BABY"
	},
	{
			"value": "OSO BEACH",
			"label": "OSO BEACH"
	},
	{
			"value": "OSO BIRD",
			"label": "OSO BIRD"
	},
	{
			"value": "OSO CHALK",
			"label": "OSO CHALK"
	},
	{
			"value": "OSO FAMILY",
			"label": "OSO FAMILY"
	},
	{
			"value": "OSO FOOD",
			"label": "OSO FOOD"
	},
	{
			"value": "OSO FRAME",
			"label": "OSO FRAME"
	},
	{
			"value": "OSO FURRY",
			"label": "OSO FURRY"
	},
	{
			"value": "OSO GLASS",
			"label": "OSO GLASS"
	},
	{
			"value": "OSO HOBBY",
			"label": "OSO HOBBY"
	},
	{
			"value": "OSO HOLIDAY",
			"label": "OSO HOLIDAY"
	},
	{
			"value": "OSO MONKEY",
			"label": "OSO MONKEY"
	},
	{
			"value": "OSO NATURAL",
			"label": "OSO NATURAL"
	},
	{
			"value": "OSO OCCUPATION",
			"label": "OSO OCCUPATION"
	},
	{
			"value": "OSO PET",
			"label": "OSO PET"
	},
	{
			"value": "OSO PICK",
			"label": "OSO PICK"
	},
	{
			"value": "OSO PLAID",
			"label": "OSO PLAID"
	},
	{
			"value": "OSO SPORT",
			"label": "OSO SPORT"
	},
	{
			"value": "OSO STAR",
			"label": "OSO STAR"
	},
	{
			"value": "OSO TRADITIONAL",
			"label": "OSO TRADITIONAL"
	},
	{
			"value": "OSO TRAVEL",
			"label": "OSO TRAVEL"
	},
	{
			"value": "OSO WEDDING",
			"label": "OSO WEDDING"
	},
	{
			"value": "OSPPRC00",
			"label": "OSPPRC00"
	},
	{
			"value": "OTHER",
			"label": "OTHER"
	},
	{
			"value": "OTHER COTTON",
			"label": "OTHER COTTON"
	},
	{
			"value": "OTHER CRAFT",
			"label": "OTHER CRAFT"
	},
	{
			"value": "OTHER DENIM",
			"label": "OTHER DENIM"
	},
	{
			"value": "OTK",
			"label": "OTK"
	},
	{
			"value": "OUR CRAFT LOUNGE",
			"label": "OUR CRAFT LOUNGE"
	},
	{
			"value": "OUTDOOR",
			"label": "OUTDOOR"
	},
	{
			"value": "OUTDOOR 3PK",
			"label": "OUTDOOR 3PK"
	},
	{
			"value": "OUTDOOR 4 IN A ROW",
			"label": "OUTDOOR 4 IN A ROW"
	},
	{
			"value": "OUTDOOR BOCCE BALL",
			"label": "OUTDOOR BOCCE BALL"
	},
	{
			"value": "OUTDOOR CHECKERS",
			"label": "OUTDOOR CHECKERS"
	},
	{
			"value": "OUTDOOR DÉCOR",
			"label": "OUTDOOR DÉCOR"
	},
	{
			"value": "OUTDOOR FURNITURE",
			"label": "OUTDOOR FURNITURE"
	},
	{
			"value": "OUTDOOR GAMES",
			"label": "OUTDOOR GAMES"
	},
	{
			"value": "OUTDOOR KIT",
			"label": "OUTDOOR KIT"
	},
	{
			"value": "OUTDOOR ORNAMENT",
			"label": "OUTDOOR ORNAMENT"
	},
	{
			"value": "OUTDOOR ORNAMENTS",
			"label": "OUTDOOR ORNAMENTS"
	},
	{
			"value": "OUTDOOR PILLAR",
			"label": "OUTDOOR PILLAR"
	},
	{
			"value": "OUTDOOR PILLOW",
			"label": "OUTDOOR PILLOW"
	},
	{
			"value": "OUTDOOR PILLOWS",
			"label": "OUTDOOR PILLOWS"
	},
	{
			"value": "OUTDOOR RING TOSS",
			"label": "OUTDOOR RING TOSS"
	},
	{
			"value": "OUTDOOR RUG",
			"label": "OUTDOOR RUG"
	},
	{
			"value": "OUTDOOR RUG 4X6",
			"label": "OUTDOOR RUG 4X6"
	},
	{
			"value": "OUTDOOR RUG 5X7",
			"label": "OUTDOOR RUG 5X7"
	},
	{
			"value": "OUTDOOR RUG 8X10",
			"label": "OUTDOOR RUG 8X10"
	},
	{
			"value": "OUTDOORS",
			"label": "OUTDOORS"
	},
	{
			"value": "OUTDOORS & MORE",
			"label": "OUTDOORS & MORE"
	},
	{
			"value": "OUTDOOR TEXTILES",
			"label": "OUTDOOR TEXTILES"
	},
	{
			"value": "OUTDOOR WORD GAME",
			"label": "OUTDOOR WORD GAME"
	},
	{
			"value": "OUTDOOR YARD DICE",
			"label": "OUTDOOR YARD DICE"
	},
	{
			"value": "OUTERSPACE",
			"label": "OUTERSPACE"
	},
	{
			"value": "OUTERWEAR",
			"label": "OUTERWEAR"
	},
	{
			"value": "OUTLAW COSTUME",
			"label": "OUTLAW COSTUME"
	},
	{
			"value": "OVAL",
			"label": "OVAL"
	},
	{
			"value": "OVAL LIDDED",
			"label": "OVAL LIDDED"
	},
	{
			"value": "OVAL MOP",
			"label": "OVAL MOP"
	},
	{
			"value": "OVAL PLATE",
			"label": "OVAL PLATE"
	},
	{
			"value": "OVAL PLATTER",
			"label": "OVAL PLATTER"
	},
	{
			"value": "OVAL POT",
			"label": "OVAL POT"
	},
	{
			"value": "OVAL SEASHELL",
			"label": "OVAL SEASHELL"
	},
	{
			"value": "OVEN MIT",
			"label": "OVEN MIT"
	},
	{
			"value": "OVENMIT",
			"label": "OVENMIT"
	},
	{
			"value": "OVEN MITT",
			"label": "OVEN MITT"
	},
	{
			"value": "OVERLOCK",
			"label": "OVERLOCK"
	},
	{
			"value": "OVERSIZED PUMPKIN",
			"label": "OVERSIZED PUMPKIN"
	},
	{
			"value": "OVERSIZED SKULL",
			"label": "OVERSIZED SKULL"
	},
	{
			"value": "OVERSIZED SPIDER",
			"label": "OVERSIZED SPIDER"
	},
	{
			"value": "OVERSIZED STATUARY",
			"label": "OVERSIZED STATUARY"
	},
	{
			"value": "OVERSIZED TOMBSTONE",
			"label": "OVERSIZED TOMBSTONE"
	},
	{
			"value": "OWL",
			"label": "OWL"
	},
	{
			"value": "OWL/BIRD",
			"label": "OWL/BIRD"
	},
	{
			"value": "OWL BULLETIN BOARD B",
			"label": "OWL BULLETIN BOARD B"
	},
	{
			"value": "OWL NOTE PAD",
			"label": "OWL NOTE PAD"
	},
	{
			"value": "OWL ORN",
			"label": "OWL ORN"
	},
	{
			"value": "OWLS",
			"label": "OWLS"
	},
	{
			"value": "OWLS RED",
			"label": "OWLS RED"
	},
	{
			"value": "OWL STICKERS",
			"label": "OWL STICKERS"
	},
	{
			"value": "OZ",
			"label": "OZ"
	},
	{
			"value": "OZ TUMBLER",
			"label": "OZ TUMBLER"
	},
	{
			"value": "PACK",
			"label": "PACK"
	},
	{
			"value": "PACK 9X12",
			"label": "PACK 9X12"
	},
	{
			"value": "PACKAGED BEADS",
			"label": "PACKAGED BEADS"
	},
	{
			"value": "PACKAGED FELT",
			"label": "PACKAGED FELT"
	},
	{
			"value": "PACKAGED THROW",
			"label": "PACKAGED THROW"
	},
	{
			"value": "PACKAGING ACCESSORIE",
			"label": "PACKAGING ACCESSORIE"
	},
	{
			"value": "PACKED FLORAL",
			"label": "PACKED FLORAL"
	},
	{
			"value": "PACK UP",
			"label": "PACK UP"
	},
	{
			"value": "PAD",
			"label": "PAD"
	},
	{
			"value": "PADS",
			"label": "PADS"
	},
	{
			"value": "PAGE PROTECTORS",
			"label": "PAGE PROTECTORS"
	},
	{
			"value": "PAID",
			"label": "PAID"
	},
	{
			"value": "PAIL",
			"label": "PAIL"
	},
	{
			"value": "PAINT",
			"label": "PAINT"
	},
	{
			"value": "PAINT-ACRYLIC",
			"label": "PAINT-ACRYLIC"
	},
	{
			"value": "PAINTBOOK",
			"label": "PAINTBOOK"
	},
	{
			"value": "PAINT BRUSHES",
			"label": "PAINT BRUSHES"
	},
	{
			"value": "PAINT BY NUMBER",
			"label": "PAINT BY NUMBER"
	},
	{
			"value": "PAINT BY NUMBER LG",
			"label": "PAINT BY NUMBER LG"
	},
	{
			"value": "PAINT BY NUMBER MED",
			"label": "PAINT BY NUMBER MED"
	},
	{
			"value": "PAINT BY NUMBERS",
			"label": "PAINT BY NUMBERS"
	},
	{
			"value": "PAINTED",
			"label": "PAINTED"
	},
	{
			"value": "PAINTERS PYRAMID",
			"label": "PAINTERS PYRAMID"
	},
	{
			"value": "PAINTING",
			"label": "PAINTING"
	},
	{
			"value": "PAINTING & COLORING",
			"label": "PAINTING & COLORING"
	},
	{
			"value": "PAINTING EDUCATION",
			"label": "PAINTING EDUCATION"
	},
	{
			"value": "PAINTING KIT",
			"label": "PAINTING KIT"
	},
	{
			"value": "PAINT MARKERS",
			"label": "PAINT MARKERS"
	},
	{
			"value": "PAINT POSTERS",
			"label": "PAINT POSTERS"
	},
	{
			"value": "PAINT POTS",
			"label": "PAINT POTS"
	},
	{
			"value": "PAINT POUR",
			"label": "PAINT POUR"
	},
	{
			"value": "PAINTS BOOKS",
			"label": "PAINTS BOOKS"
	},
	{
			"value": "PAINT-SET ACRYLIC",
			"label": "PAINT-SET ACRYLIC"
	},
	{
			"value": "PAINT-SET SHINE",
			"label": "PAINT-SET SHINE"
	},
	{
			"value": "PAINT-SHINE",
			"label": "PAINT-SHINE"
	},
	{
			"value": "PAISLEY",
			"label": "PAISLEY"
	},
	{
			"value": "PALLET",
			"label": "PALLET"
	},
	{
			"value": "PALM",
			"label": "PALM"
	},
	{
			"value": "PALM BOWL",
			"label": "PALM BOWL"
	},
	{
			"value": "PALMER",
			"label": "PALMER"
	},
	{
			"value": "PALM LEAVES",
			"label": "PALM LEAVES"
	},
	{
			"value": "PALMS TOWEL",
			"label": "PALMS TOWEL"
	},
	{
			"value": "PALM TEALIGHT",
			"label": "PALM TEALIGHT"
	},
	{
			"value": "PALM TOWEL",
			"label": "PALM TOWEL"
	},
	{
			"value": "PAMPAS",
			"label": "PAMPAS"
	},
	{
			"value": "PAMPAS GRASS",
			"label": "PAMPAS GRASS"
	},
	{
			"value": "PAMPAS GRS",
			"label": "PAMPAS GRS"
	},
	{
			"value": "PAN",
			"label": "PAN"
	},
	{
			"value": "PANACEA",
			"label": "PANACEA"
	},
	{
			"value": "PANDA",
			"label": "PANDA"
	},
	{
			"value": "PANEL",
			"label": "PANEL"
	},
	{
			"value": "PANEL 1",
			"label": "PANEL 1"
	},
	{
			"value": "PANEL 12",
			"label": "PANEL 12"
	},
	{
			"value": "PANEL 2",
			"label": "PANEL 2"
	},
	{
			"value": "PANEL 3",
			"label": "PANEL 3"
	},
	{
			"value": "PANEL 4",
			"label": "PANEL 4"
	},
	{
			"value": "PANEL 4/5",
			"label": "PANEL 4/5"
	},
	{
			"value": "PANEL 6",
			"label": "PANEL 6"
	},
	{
			"value": "PANEL 7",
			"label": "PANEL 7"
	},
	{
			"value": "PANEL 8",
			"label": "PANEL 8"
	},
	{
			"value": "PANEL KIT",
			"label": "PANEL KIT"
	},
	{
			"value": "PANNE",
			"label": "PANNE"
	},
	{
			"value": "PANS",
			"label": "PANS"
	},
	{
			"value": "PANSIES",
			"label": "PANSIES"
	},
	{
			"value": "PANSY",
			"label": "PANSY"
	},
	{
			"value": "PANSY BASKET",
			"label": "PANSY BASKET"
	},
	{
			"value": "PANTERRA",
			"label": "PANTERRA"
	},
	{
			"value": "PANTS",
			"label": "PANTS"
	},
	{
			"value": "PAPER",
			"label": "PAPER"
	},
	{
			"value": "PAPER AIRPLANES",
			"label": "PAPER AIRPLANES"
	},
	{
			"value": "PAPER BAGS",
			"label": "PAPER BAGS"
	},
	{
			"value": "PAPER BASES",
			"label": "PAPER BASES"
	},
	{
			"value": "PAPER COMPANY",
			"label": "PAPER COMPANY"
	},
	{
			"value": "PAPER CONE",
			"label": "PAPER CONE"
	},
	{
			"value": "PAPER CORD",
			"label": "PAPER CORD"
	},
	{
			"value": "PAPER CRAFT",
			"label": "PAPER CRAFT"
	},
	{
			"value": "PAPERCRAFTING",
			"label": "PAPERCRAFTING"
	},
	{
			"value": "PAPER CROWN",
			"label": "PAPER CROWN"
	},
	{
			"value": "PAPER CUP",
			"label": "PAPER CUP"
	},
	{
			"value": "PAPER CUPS",
			"label": "PAPER CUPS"
	},
	{
			"value": "PAPER ENTERTAINING",
			"label": "PAPER ENTERTAINING"
	},
	{
			"value": "PAPER FLOWER",
			"label": "PAPER FLOWER"
	},
	{
			"value": "PAPER HOUSE",
			"label": "PAPER HOUSE"
	},
	{
			"value": "PAPER INLINE",
			"label": "PAPER INLINE"
	},
	{
			"value": "PAPER LED HOUSE",
			"label": "PAPER LED HOUSE"
	},
	{
			"value": "PAPER LOFT",
			"label": "PAPER LOFT"
	},
	{
			"value": "PAPER MACHE",
			"label": "PAPER MACHE"
	},
	{
			"value": "PAPER NAPKIN",
			"label": "PAPER NAPKIN"
	},
	{
			"value": "PAPER OSO",
			"label": "PAPER OSO"
	},
	{
			"value": "PAPER PAD",
			"label": "PAPER PAD"
	},
	{
			"value": "PAPER PADS",
			"label": "PAPER PADS"
	},
	{
			"value": "PAPER PAD TOWER",
			"label": "PAPER PAD TOWER"
	},
	{
			"value": "PAPER PIECING",
			"label": "PAPER PIECING"
	},
	{
			"value": "PAPER PLATE",
			"label": "PAPER PLATE"
	},
	{
			"value": "PAPER PLATES",
			"label": "PAPER PLATES"
	},
	{
			"value": "PAPER RABBITE",
			"label": "PAPER RABBITE"
	},
	{
			"value": "PAPER ROLL",
			"label": "PAPER ROLL"
	},
	{
			"value": "PAPER SHRED",
			"label": "PAPER SHRED"
	},
	{
			"value": "PAPER STAR",
			"label": "PAPER STAR"
	},
	{
			"value": "PAPER STRAWS",
			"label": "PAPER STRAWS"
	},
	{
			"value": "PAPERWARE",
			"label": "PAPERWARE"
	},
	{
			"value": "PARACORD",
			"label": "PARACORD"
	},
	{
			"value": "PARADE",
			"label": "PARADE"
	},
	{
			"value": "PARADE AMERICANA",
			"label": "PARADE AMERICANA"
	},
	{
			"value": "PARADISE EXOTIC SHAW",
			"label": "PARADISE EXOTIC SHAW"
	},
	{
			"value": "PARCHMENT",
			"label": "PARCHMENT"
	},
	{
			"value": "PARELLEL CABLE CHAIN",
			"label": "PARELLEL CABLE CHAIN"
	},
	{
			"value": "PARIS",
			"label": "PARIS"
	},
	{
			"value": "PARK",
			"label": "PARK"
	},
	{
			"value": "PARK LANE",
			"label": "PARK LANE"
	},
	{
			"value": "PARROT",
			"label": "PARROT"
	},
	{
			"value": "PARROT PLATE",
			"label": "PARROT PLATE"
	},
	{
			"value": "PARTIES & SOCIALS ED",
			"label": "PARTIES & SOCIALS ED"
	},
	{
			"value": "PARTY",
			"label": "PARTY"
	},
	{
			"value": "PARTY CUP",
			"label": "PARTY CUP"
	},
	{
			"value": "PARTY DECOR",
			"label": "PARTY DECOR"
	},
	{
			"value": "PARTY GLASSES",
			"label": "PARTY GLASSES"
	},
	{
			"value": "PARTY SUPPLIES",
			"label": "PARTY SUPPLIES"
	},
	{
			"value": "PARTYWARE",
			"label": "PARTYWARE"
	},
	{
			"value": "PASTEL PERFECTION",
			"label": "PASTEL PERFECTION"
	},
	{
			"value": "PATCH",
			"label": "PATCH"
	},
	{
			"value": "PATCH PRODUCTS",
			"label": "PATCH PRODUCTS"
	},
	{
			"value": "PATCHWORK",
			"label": "PATCHWORK"
	},
	{
			"value": "PATCHWORK BOOKLET",
			"label": "PATCHWORK BOOKLET"
	},
	{
			"value": "PATCHWORK FLANNEL CO",
			"label": "PATCHWORK FLANNEL CO"
	},
	{
			"value": "PATCHWORK LUMBAR",
			"label": "PATCHWORK LUMBAR"
	},
	{
			"value": "PATCHWORK PLAID",
			"label": "PATCHWORK PLAID"
	},
	{
			"value": "PATHWAY",
			"label": "PATHWAY"
	},
	{
			"value": "PATHWAY LIGHT",
			"label": "PATHWAY LIGHT"
	},
	{
			"value": "PATHWAY LIGHTS",
			"label": "PATHWAY LIGHTS"
	},
	{
			"value": "PATHWAY ROCK",
			"label": "PATHWAY ROCK"
	},
	{
			"value": "PATINA SOLUTION",
			"label": "PATINA SOLUTION"
	},
	{
			"value": "PATIO",
			"label": "PATIO"
	},
	{
			"value": "PATIO UMBRELLA",
			"label": "PATIO UMBRELLA"
	},
	{
			"value": "PATRIOITC",
			"label": "PATRIOITC"
	},
	{
			"value": "PATRIOTIC",
			"label": "PATRIOTIC"
	},
	{
			"value": "PATRIOTIC ACCESSORIE",
			"label": "PATRIOTIC ACCESSORIE"
	},
	{
			"value": "PATRIOTIC BLESS THIS",
			"label": "PATRIOTIC BLESS THIS"
	},
	{
			"value": "PATRIOTIC BOW",
			"label": "PATRIOTIC BOW"
	},
	{
			"value": "PATRIOTIC ENTERTAINI",
			"label": "PATRIOTIC ENTERTAINI"
	},
	{
			"value": "PATRIOTIC FABRIC",
			"label": "PATRIOTIC FABRIC"
	},
	{
			"value": "PATRIOTIC FARM",
			"label": "PATRIOTIC FARM"
	},
	{
			"value": "PATRIOTIC GRAPEVINE",
			"label": "PATRIOTIC GRAPEVINE"
	},
	{
			"value": "PATRIOTIC NOSTALGIA",
			"label": "PATRIOTIC NOSTALGIA"
	},
	{
			"value": "PATRIOTIC PAISLEY",
			"label": "PATRIOTIC PAISLEY"
	},
	{
			"value": "PATRIOTIC PP 1",
			"label": "PATRIOTIC PP 1"
	},
	{
			"value": "PATRIOTIC STARS",
			"label": "PATRIOTIC STARS"
	},
	{
			"value": "PATRIOTIC VINTAGE",
			"label": "PATRIOTIC VINTAGE"
	},
	{
			"value": "PATTERENED",
			"label": "PATTERENED"
	},
	{
			"value": "PATTERN",
			"label": "PATTERN"
	},
	{
			"value": "PATTERNED",
			"label": "PATTERNED"
	},
	{
			"value": "PATTERNED FOIL",
			"label": "PATTERNED FOIL"
	},
	{
			"value": "PATTERNED HEARTS",
			"label": "PATTERNED HEARTS"
	},
	{
			"value": "PATTERN IRON ON",
			"label": "PATTERN IRON ON"
	},
	{
			"value": "PATTERN PERM VINYL",
			"label": "PATTERN PERM VINYL"
	},
	{
			"value": "PATTERN REMV VINYL",
			"label": "PATTERN REMV VINYL"
	},
	{
			"value": "PATTERNS",
			"label": "PATTERNS"
	},
	{
			"value": "PATTERN TISSUE",
			"label": "PATTERN TISSUE"
	},
	{
			"value": "PATTERN TOWEL",
			"label": "PATTERN TOWEL"
	},
	{
			"value": "PATTERN VINYL",
			"label": "PATTERN VINYL"
	},
	{
			"value": "PAUL FRANK",
			"label": "PAUL FRANK"
	},
	{
			"value": "PAW",
			"label": "PAW"
	},
	{
			"value": "PAW PATROL",
			"label": "PAW PATROL"
	},
	{
			"value": "PAW PRINT",
			"label": "PAW PRINT"
	},
	{
			"value": "PAW PRINT/BONES",
			"label": "PAW PRINT/BONES"
	},
	{
			"value": "PAW PRINTS",
			"label": "PAW PRINTS"
	},
	{
			"value": "PAWPRINTS",
			"label": "PAWPRINTS"
	},
	{
			"value": "PAZZLES",
			"label": "PAZZLES"
	},
	{
			"value": "PC 3SKUS",
			"label": "PC 3SKUS"
	},
	{
			"value": "PCIK ASST",
			"label": "PCIK ASST"
	},
	{
			"value": "PCS 12STYLES",
			"label": "PCS 12STYLES"
	},
	{
			"value": "PCS 3SKUS",
			"label": "PCS 3SKUS"
	},
	{
			"value": "PCS 3STYLES",
			"label": "PCS 3STYLES"
	},
	{
			"value": "PCS 4STYLES",
			"label": "PCS 4STYLES"
	},
	{
			"value": "PCS 5STYLES",
			"label": "PCS 5STYLES"
	},
	{
			"value": "PCS 6STYLES",
			"label": "PCS 6STYLES"
	},
	{
			"value": "PCS 9STYLES",
			"label": "PCS 9STYLES"
	},
	{
			"value": "PDQ",
			"label": "PDQ"
	},
	{
			"value": "PDQ SHIPPER",
			"label": "PDQ SHIPPER"
	},
	{
			"value": "PEACE",
			"label": "PEACE"
	},
	{
			"value": "PEACE FLORAL FLNL",
			"label": "PEACE FLORAL FLNL"
	},
	{
			"value": "PEACE / LOVE",
			"label": "PEACE / LOVE"
	},
	{
			"value": "PEACE /LOVE",
			"label": "PEACE /LOVE"
	},
	{
			"value": "PEACE LOVE",
			"label": "PEACE LOVE"
	},
	{
			"value": "PEACE/ LOVE",
			"label": "PEACE/ LOVE"
	},
	{
			"value": "PEACE/LOVE",
			"label": "PEACE/LOVE"
	},
	{
			"value": "PEACE MONKEY",
			"label": "PEACE MONKEY"
	},
	{
			"value": "PEACE SCRIBBLE",
			"label": "PEACE SCRIBBLE"
	},
	{
			"value": "PEACE SIGN",
			"label": "PEACE SIGN"
	},
	{
			"value": "PEACE SKULL",
			"label": "PEACE SKULL"
	},
	{
			"value": "PEACH",
			"label": "PEACH"
	},
	{
			"value": "PEACH BLOSSOM",
			"label": "PEACH BLOSSOM"
	},
	{
			"value": "PEACH BLOSSOM LVS",
			"label": "PEACH BLOSSOM LVS"
	},
	{
			"value": "PEACHSKIN",
			"label": "PEACHSKIN"
	},
	{
			"value": "PEACHY KEEN",
			"label": "PEACHY KEEN"
	},
	{
			"value": "PEACOCK",
			"label": "PEACOCK"
	},
	{
			"value": "PEACOCK AND LEAVES",
			"label": "PEACOCK AND LEAVES"
	},
	{
			"value": "PEACOCK ELEGANCE",
			"label": "PEACOCK ELEGANCE"
	},
	{
			"value": "PEANUTS",
			"label": "PEANUTS"
	},
	{
			"value": "PEAR",
			"label": "PEAR"
	},
	{
			"value": "PEARL",
			"label": "PEARL"
	},
	{
			"value": "PEARL BEADS",
			"label": "PEARL BEADS"
	},
	{
			"value": "PEARL GRASS",
			"label": "PEARL GRASS"
	},
	{
			"value": "PEARLIZED",
			"label": "PEARLIZED"
	},
	{
			"value": "PEARL PERM VINYL",
			"label": "PEARL PERM VINYL"
	},
	{
			"value": "PEARLS",
			"label": "PEARLS"
	},
	{
			"value": "PEBBLE",
			"label": "PEBBLE"
	},
	{
			"value": "PEBBLES",
			"label": "PEBBLES"
	},
	{
			"value": "PEBBLES INC.",
			"label": "PEBBLES INC."
	},
	{
			"value": "PEDESTAL",
			"label": "PEDESTAL"
	},
	{
			"value": "PEEL N STICK",
			"label": "PEEL N STICK"
	},
	{
			"value": "PEEL STICK",
			"label": "PEEL STICK"
	},
	{
			"value": "PEEP PLUSH",
			"label": "PEEP PLUSH"
	},
	{
			"value": "PEEPS",
			"label": "PEEPS"
	},
	{
			"value": "PEGBOARD",
			"label": "PEGBOARD"
	},
	{
			"value": "PELLON",
			"label": "PELLON"
	},
	{
			"value": "PELLON 50/50 BLEND",
			"label": "PELLON 50/50 BLEND"
	},
	{
			"value": "PELLON ALL NATURAL",
			"label": "PELLON ALL NATURAL"
	},
	{
			"value": "PELLON NATURAL",
			"label": "PELLON NATURAL"
	},
	{
			"value": "PELLON PRODUCT GUIDE",
			"label": "PELLON PRODUCT GUIDE"
	},
	{
			"value": "PELLON WHITE",
			"label": "PELLON WHITE"
	},
	{
			"value": "PELLON WOOL BLEND",
			"label": "PELLON WOOL BLEND"
	},
	{
			"value": "PEN",
			"label": "PEN"
	},
	{
			"value": "PENCIL",
			"label": "PENCIL"
	},
	{
			"value": "PENCILS",
			"label": "PENCILS"
	},
	{
			"value": "PENCIL TIN",
			"label": "PENCIL TIN"
	},
	{
			"value": "PENDANT",
			"label": "PENDANT"
	},
	{
			"value": "PENDANTS",
			"label": "PENDANTS"
	},
	{
			"value": "PENGUIN",
			"label": "PENGUIN"
	},
	{
			"value": "PENGUINS",
			"label": "PENGUINS"
	},
	{
			"value": "PEN HOLDER",
			"label": "PEN HOLDER"
	},
	{
			"value": "PEN / MARKER",
			"label": "PEN / MARKER"
	},
	{
			"value": "PENN ST",
			"label": "PENN ST"
	},
	{
			"value": "PENNY BLACK",
			"label": "PENNY BLACK"
	},
	{
			"value": "PENS",
			"label": "PENS"
	},
	{
			"value": "PENTEL",
			"label": "PENTEL"
	},
	{
			"value": "PEONIES",
			"label": "PEONIES"
	},
	{
			"value": "PEONY",
			"label": "PEONY"
	},
	{
			"value": "PEONY DAHLIA HYDRANG",
			"label": "PEONY DAHLIA HYDRANG"
	},
	{
			"value": "PEONY DAISY",
			"label": "PEONY DAISY"
	},
	{
			"value": "PEONY HYDRA",
			"label": "PEONY HYDRA"
	},
	{
			"value": "PEONY HYDRANGEA BERR",
			"label": "PEONY HYDRANGEA BERR"
	},
	{
			"value": "PEONY HYDRANGEA ROSE",
			"label": "PEONY HYDRANGEA ROSE"
	},
	{
			"value": "PEONY ROES",
			"label": "PEONY ROES"
	},
	{
			"value": "PEONY ZINNA",
			"label": "PEONY ZINNA"
	},
	{
			"value": "PEPPA THE PIG",
			"label": "PEPPA THE PIG"
	},
	{
			"value": "PEPPER",
			"label": "PEPPER"
	},
	{
			"value": "PEPPER BERRY",
			"label": "PEPPER BERRY"
	},
	{
			"value": "PEPPERBERRY",
			"label": "PEPPERBERRY"
	},
	{
			"value": "PEPPER BRY",
			"label": "PEPPER BRY"
	},
	{
			"value": "PEPPERELL",
			"label": "PEPPERELL"
	},
	{
			"value": "PEPPERMINT",
			"label": "PEPPERMINT"
	},
	{
			"value": "PEPPERMINTS",
			"label": "PEPPERMINTS"
	},
	{
			"value": "PEPPERMINT TWIST",
			"label": "PEPPERMINT TWIST"
	},
	{
			"value": "PEPPER SET",
			"label": "PEPPER SET"
	},
	{
			"value": "PERCH",
			"label": "PERCH"
	},
	{
			"value": "PERFECT LOFT FIBER",
			"label": "PERFECT LOFT FIBER"
	},
	{
			"value": "PERFECTLY PLAID",
			"label": "PERFECTLY PLAID"
	},
	{
			"value": "PERFECT RESULTS",
			"label": "PERFECT RESULTS"
	},
	{
			"value": "PERFORATED",
			"label": "PERFORATED"
	},
	{
			"value": "PERFORMANCE",
			"label": "PERFORMANCE"
	},
	{
			"value": "PERFORMANCE TEST",
			"label": "PERFORMANCE TEST"
	},
	{
			"value": "PERILLA",
			"label": "PERILLA"
	},
	{
			"value": "PERLER",
			"label": "PERLER"
	},
	{
			"value": "PERLER ACCESSORY",
			"label": "PERLER ACCESSORY"
	},
	{
			"value": "PERLER BEADS",
			"label": "PERLER BEADS"
	},
	{
			"value": "PERLER KIT",
			"label": "PERLER KIT"
	},
	{
			"value": "PERLER LICENSED KIT",
			"label": "PERLER LICENSED KIT"
	},
	{
			"value": "PERLER PATTERNS",
			"label": "PERLER PATTERNS"
	},
	{
			"value": "PERLER PEG BOARDS",
			"label": "PERLER PEG BOARDS"
	},
	{
			"value": "PERLER STORAGE",
			"label": "PERLER STORAGE"
	},
	{
			"value": "PERM BULK ROLL",
			"label": "PERM BULK ROLL"
	},
	{
			"value": "PERM VINYL",
			"label": "PERM VINYL"
	},
	{
			"value": "PERUVIAN",
			"label": "PERUVIAN"
	},
	{
			"value": "PET",
			"label": "PET"
	},
	{
			"value": "PET ACCESSORIES",
			"label": "PET ACCESSORIES"
	},
	{
			"value": "PET ACCESSORY",
			"label": "PET ACCESSORY"
	},
	{
			"value": "PET ACCESSORY SET",
			"label": "PET ACCESSORY SET"
	},
	{
			"value": "PET ACCESSORY TUTU",
			"label": "PET ACCESSORY TUTU"
	},
	{
			"value": "PET ACCSSRY HEADWEAR",
			"label": "PET ACCSSRY HEADWEAR"
	},
	{
			"value": "PETALOO",
			"label": "PETALOO"
	},
	{
			"value": "PETALS",
			"label": "PETALS"
	},
	{
			"value": "PET BED",
			"label": "PET BED"
	},
	{
			"value": "PET CLOTHING",
			"label": "PET CLOTHING"
	},
	{
			"value": "PET COLLAR",
			"label": "PET COLLAR"
	},
	{
			"value": "PET COSTUME",
			"label": "PET COSTUME"
	},
	{
			"value": "PET COSTUME FASHION",
			"label": "PET COSTUME FASHION"
	},
	{
			"value": "PET COSTUME HARNESS",
			"label": "PET COSTUME HARNESS"
	},
	{
			"value": "PET COSTUME HAT",
			"label": "PET COSTUME HAT"
	},
	{
			"value": "PET COSTUME HEADPC",
			"label": "PET COSTUME HEADPC"
	},
	{
			"value": "PET COSTUME RIDING",
			"label": "PET COSTUME RIDING"
	},
	{
			"value": "PET COSTUMES",
			"label": "PET COSTUMES"
	},
	{
			"value": "PET COSTUME TRAD",
			"label": "PET COSTUME TRAD"
	},
	{
			"value": "PET COSTUME WALKING",
			"label": "PET COSTUME WALKING"
	},
	{
			"value": "PET EAT",
			"label": "PET EAT"
	},
	{
			"value": "PET EC",
			"label": "PET EC"
	},
	{
			"value": "PET HALLOWEEN COLLAR",
			"label": "PET HALLOWEEN COLLAR"
	},
	{
			"value": "PETITE",
			"label": "PETITE"
	},
	{
			"value": "PETITE BAG",
			"label": "PETITE BAG"
	},
	{
			"value": "PET LUAU",
			"label": "PET LUAU"
	},
	{
			"value": "PET LUAU EC",
			"label": "PET LUAU EC"
	},
	{
			"value": "PETS",
			"label": "PETS"
	},
	{
			"value": "PET SHIRT",
			"label": "PET SHIRT"
	},
	{
			"value": "PET SLEEP",
			"label": "PET SLEEP"
	},
	{
			"value": "PET SUMMER",
			"label": "PET SUMMER"
	},
	{
			"value": "PET SWEATER",
			"label": "PET SWEATER"
	},
	{
			"value": "PETS WRAP",
			"label": "PETS WRAP"
	},
	{
			"value": "PETTICOAT NET",
			"label": "PETTICOAT NET"
	},
	{
			"value": "PET TOYS",
			"label": "PET TOYS"
	},
	{
			"value": "PETUNIA",
			"label": "PETUNIA"
	},
	{
			"value": "PETUNIA SHOVEL",
			"label": "PETUNIA SHOVEL"
	},
	{
			"value": "PETUNIA TULIP",
			"label": "PETUNIA TULIP"
	},
	{
			"value": "PET WALK",
			"label": "PET WALK"
	},
	{
			"value": "PET WRAP",
			"label": "PET WRAP"
	},
	{
			"value": "PEVA",
			"label": "PEVA"
	},
	{
			"value": "PEVA 52 X 90",
			"label": "PEVA 52 X 90"
	},
	{
			"value": "PEVA 52X90",
			"label": "PEVA 52X90"
	},
	{
			"value": "PEVA 52X90 RECTANGLE",
			"label": "PEVA 52X90 RECTANGLE"
	},
	{
			"value": "PEVA 60",
			"label": "PEVA 60"
	},
	{
			"value": "PEVA 60 ROUND",
			"label": "PEVA 60 ROUND"
	},
	{
			"value": "PEVA RECTANGLE",
			"label": "PEVA RECTANGLE"
	},
	{
			"value": "PEVA ROUND",
			"label": "PEVA ROUND"
	},
	{
			"value": "PEVA TABLECLOTH",
			"label": "PEVA TABLECLOTH"
	},
	{
			"value": "PHALAENOPSIS",
			"label": "PHALAENOPSIS"
	},
	{
			"value": "PHALENOPSIS",
			"label": "PHALENOPSIS"
	},
	{
			"value": "PHAL. LEAF",
			"label": "PHAL. LEAF"
	},
	{
			"value": "PHEASANT",
			"label": "PHEASANT"
	},
	{
			"value": "PHIL FLYERS",
			"label": "PHIL FLYERS"
	},
	{
			"value": "PHILO",
			"label": "PHILO"
	},
	{
			"value": "PHONE",
			"label": "PHONE"
	},
	{
			"value": "PHONE ACCESSORY",
			"label": "PHONE ACCESSORY"
	},
	{
			"value": "PHONE MOUNT",
			"label": "PHONE MOUNT"
	},
	{
			"value": "PHOTO",
			"label": "PHOTO"
	},
	{
			"value": "PHOTO BACKDROP",
			"label": "PHOTO BACKDROP"
	},
	{
			"value": "PHOTO CARDS",
			"label": "PHOTO CARDS"
	},
	{
			"value": "PHOTO CORNER",
			"label": "PHOTO CORNER"
	},
	{
			"value": "PHOTO FRAME",
			"label": "PHOTO FRAME"
	},
	{
			"value": "PHOTO FRAME STOCKING",
			"label": "PHOTO FRAME STOCKING"
	},
	{
			"value": "PHOTO FROG",
			"label": "PHOTO FROG"
	},
	{
			"value": "PHOTO PROPS",
			"label": "PHOTO PROPS"
	},
	{
			"value": "PHOTO REAL WRAP",
			"label": "PHOTO REAL WRAP"
	},
	{
			"value": "PHOTO WRAP",
			"label": "PHOTO WRAP"
	},
	{
			"value": "PHRASES & QUOTES",
			"label": "PHRASES & QUOTES"
	},
	{
			"value": "PIC FRAME",
			"label": "PIC FRAME"
	},
	{
			"value": "PICK",
			"label": "PICK"
	},
	{
			"value": "PICK ASST/2",
			"label": "PICK ASST/2"
	},
	{
			"value": "PICK ASST/3",
			"label": "PICK ASST/3"
	},
	{
			"value": "PICK ASST/4",
			"label": "PICK ASST/4"
	},
	{
			"value": "PICK ASTD3",
			"label": "PICK ASTD3"
	},
	{
			"value": "PICK ASTD4",
			"label": "PICK ASTD4"
	},
	{
			"value": "PICKS",
			"label": "PICKS"
	},
	{
			"value": "PICK/STAKE",
			"label": "PICK/STAKE"
	},
	{
			"value": "PICK UP STICKS!",
			"label": "PICK UP STICKS!"
	},
	{
			"value": "PICNIC BAGS",
			"label": "PICNIC BAGS"
	},
	{
			"value": "PICNIC BLANKET",
			"label": "PICNIC BLANKET"
	},
	{
			"value": "PICTURE KEEPER",
			"label": "PICTURE KEEPER"
	},
	{
			"value": "PIE BOXES",
			"label": "PIE BOXES"
	},
	{
			"value": "PIE COOKIE",
			"label": "PIE COOKIE"
	},
	{
			"value": "PIE PLATE",
			"label": "PIE PLATE"
	},
	{
			"value": "PIE TOOLS",
			"label": "PIE TOOLS"
	},
	{
			"value": "PIG",
			"label": "PIG"
	},
	{
			"value": "PIGGY TALES",
			"label": "PIGGY TALES"
	},
	{
			"value": "PIGMENT",
			"label": "PIGMENT"
	},
	{
			"value": "PILGRIMS",
			"label": "PILGRIMS"
	},
	{
			"value": "PILLAR",
			"label": "PILLAR"
	},
	{
			"value": "PILLAR CANDLE",
			"label": "PILLAR CANDLE"
	},
	{
			"value": "PILLAR CANDLES",
			"label": "PILLAR CANDLES"
	},
	{
			"value": "PILLAR HOLDER",
			"label": "PILLAR HOLDER"
	},
	{
			"value": "PILLARS",
			"label": "PILLARS"
	},
	{
			"value": "PILLOW",
			"label": "PILLOW"
	},
	{
			"value": "PILLOW 18 GRY",
			"label": "PILLOW 18 GRY"
	},
	{
			"value": "PILLOW 18 IVR",
			"label": "PILLOW 18 IVR"
	},
	{
			"value": "PILLOW 18X18",
			"label": "PILLOW 18X18"
	},
	{
			"value": "PILLOW 18X18 IN OUT",
			"label": "PILLOW 18X18 IN OUT"
	},
	{
			"value": "PILLOW 18X18 OUTDOOR",
			"label": "PILLOW 18X18 OUTDOOR"
	},
	{
			"value": "PILLOW 24 GRY",
			"label": "PILLOW 24 GRY"
	},
	{
			"value": "PILLOW 24 IVR",
			"label": "PILLOW 24 IVR"
	},
	{
			"value": "PILLOW 24X24",
			"label": "PILLOW 24X24"
	},
	{
			"value": "PILLOW BLANK",
			"label": "PILLOW BLANK"
	},
	{
			"value": "PILLOW COVER",
			"label": "PILLOW COVER"
	},
	{
			"value": "PILLOW DOOR HANGER",
			"label": "PILLOW DOOR HANGER"
	},
	{
			"value": "PILLOW DOUBLE SIDED",
			"label": "PILLOW DOUBLE SIDED"
	},
	{
			"value": "PILLOW FIGURAL",
			"label": "PILLOW FIGURAL"
	},
	{
			"value": "PILLOW LUMBAR",
			"label": "PILLOW LUMBAR"
	},
	{
			"value": "PILLOW LUMBAR IN OUT",
			"label": "PILLOW LUMBAR IN OUT"
	},
	{
			"value": "PILLOW LUMBAR XL",
			"label": "PILLOW LUMBAR XL"
	},
	{
			"value": "PILLOW MINI",
			"label": "PILLOW MINI"
	},
	{
			"value": "PILLOW MIXER PRINT",
			"label": "PILLOW MIXER PRINT"
	},
	{
			"value": "PILLOW OUTDOOR",
			"label": "PILLOW OUTDOOR"
	},
	{
			"value": "PILLOW OVERSIZED",
			"label": "PILLOW OVERSIZED"
	},
	{
			"value": "PILLOW PACKS",
			"label": "PILLOW PACKS"
	},
	{
			"value": "PILLOW ROUND",
			"label": "PILLOW ROUND"
	},
	{
			"value": "PILLOWS",
			"label": "PILLOWS"
	},
	{
			"value": "PILLOW SHELL",
			"label": "PILLOW SHELL"
	},
	{
			"value": "PILLOW TEAL 1",
			"label": "PILLOW TEAL 1"
	},
	{
			"value": "PILLOW TEAL 2",
			"label": "PILLOW TEAL 2"
	},
	{
			"value": "PILLOW & THROW SET",
			"label": "PILLOW & THROW SET"
	},
	{
			"value": "PILLOW THROW SET",
			"label": "PILLOW THROW SET"
	},
	{
			"value": "PILLOW TICKING",
			"label": "PILLOW TICKING"
	},
	{
			"value": "PILLOW YELLOW",
			"label": "PILLOW YELLOW"
	},
	{
			"value": "PILOT PEN",
			"label": "PILOT PEN"
	},
	{
			"value": "PIN",
			"label": "PIN"
	},
	{
			"value": "PIN BACKS",
			"label": "PIN BACKS"
	},
	{
			"value": "PIN BAR",
			"label": "PIN BAR"
	},
	{
			"value": "PIN CUSHION",
			"label": "PIN CUSHION"
	},
	{
			"value": "PINCUSHION",
			"label": "PINCUSHION"
	},
	{
			"value": "PINE",
			"label": "PINE"
	},
	{
			"value": "PINE AND BRY",
			"label": "PINE AND BRY"
	},
	{
			"value": "PINE AND ORNAMENT",
			"label": "PINE AND ORNAMENT"
	},
	{
			"value": "PINE AND PINECONE",
			"label": "PINE AND PINECONE"
	},
	{
			"value": "PINEAPPLE",
			"label": "PINEAPPLE"
	},
	{
			"value": "PINEAPPLE BLACK",
			"label": "PINEAPPLE BLACK"
	},
	{
			"value": "PINEAPPLE GOLD",
			"label": "PINEAPPLE GOLD"
	},
	{
			"value": "PINEAPPLE TT",
			"label": "PINEAPPLE TT"
	},
	{
			"value": "PINEAPPLE WD",
			"label": "PINEAPPLE WD"
	},
	{
			"value": "PINEAPPLE WHITE",
			"label": "PINEAPPLE WHITE"
	},
	{
			"value": "PINEAPPLE YELLOW",
			"label": "PINEAPPLE YELLOW"
	},
	{
			"value": "PINE BAL BERRIES",
			"label": "PINE BAL BERRIES"
	},
	{
			"value": "PINE BALL",
			"label": "PINE BALL"
	},
	{
			"value": "PINE/BERRY",
			"label": "PINE/BERRY"
	},
	{
			"value": "PINE CAR ACCESSORIES",
			"label": "PINE CAR ACCESSORIES"
	},
	{
			"value": "PINE CAR MODEL KIT",
			"label": "PINE CAR MODEL KIT"
	},
	{
			"value": "PINE CONE",
			"label": "PINE CONE"
	},
	{
			"value": "PINECONE",
			"label": "PINECONE"
	},
	{
			"value": "PINECONE AND BALL",
			"label": "PINECONE AND BALL"
	},
	{
			"value": "PINECONE AND STAR",
			"label": "PINECONE AND STAR"
	},
	{
			"value": "PINECONE BASKET",
			"label": "PINECONE BASKET"
	},
	{
			"value": "PINECONE HANGER",
			"label": "PINECONE HANGER"
	},
	{
			"value": "PINECONE LADDER",
			"label": "PINECONE LADDER"
	},
	{
			"value": "PINECONE PRESS",
			"label": "PINECONE PRESS"
	},
	{
			"value": "PINECONES",
			"label": "PINECONES"
	},
	{
			"value": "PINECORN MAPLE",
			"label": "PINECORN MAPLE"
	},
	{
			"value": "PINE MAGNOLIA BERRY",
			"label": "PINE MAGNOLIA BERRY"
	},
	{
			"value": "PINE NEEDLE",
			"label": "PINE NEEDLE"
	},
	{
			"value": "PINE PEAR BRY BALL",
			"label": "PINE PEAR BRY BALL"
	},
	{
			"value": "PINE PINECONE",
			"label": "PINE PINECONE"
	},
	{
			"value": "PINE PINECONE BERRIE",
			"label": "PINE PINECONE BERRIE"
	},
	{
			"value": "PINE SNOW",
			"label": "PINE SNOW"
	},
	{
			"value": "PINE TREE",
			"label": "PINE TREE"
	},
	{
			"value": "PINK",
			"label": "PINK"
	},
	{
			"value": "PINK CHEER",
			"label": "PINK CHEER"
	},
	{
			"value": "PINK CHEETAH",
			"label": "PINK CHEETAH"
	},
	{
			"value": "PINK CORAL",
			"label": "PINK CORAL"
	},
	{
			"value": "PINK DK",
			"label": "PINK DK"
	},
	{
			"value": "PINK LT",
			"label": "PINK LT"
	},
	{
			"value": "PINK PAISLEE",
			"label": "PINK PAISLEE"
	},
	{
			"value": "PINK PRINCESS",
			"label": "PINK PRINCESS"
	},
	{
			"value": "PINK/RED",
			"label": "PINK/RED"
	},
	{
			"value": "PINK RIBBON",
			"label": "PINK RIBBON"
	},
	{
			"value": "PINK RIBBON   NAT",
			"label": "PINK RIBBON   NAT"
	},
	{
			"value": "PINS",
			"label": "PINS"
	},
	{
			"value": "PINSTRIPE",
			"label": "PINSTRIPE"
	},
	{
			"value": "PINSTRIP PILLOW",
			"label": "PINSTRIP PILLOW"
	},
	{
			"value": "PINTUCK",
			"label": "PINTUCK"
	},
	{
			"value": "PINTY PLUS",
			"label": "PINTY PLUS"
	},
	{
			"value": "PINTY PLUS HOME",
			"label": "PINTY PLUS HOME"
	},
	{
			"value": "PINTY PLUS MINI",
			"label": "PINTY PLUS MINI"
	},
	{
			"value": "PIN UP",
			"label": "PIN UP"
	},
	{
			"value": "PINWHEEL",
			"label": "PINWHEEL"
	},
	{
			"value": "PINWHEELS",
			"label": "PINWHEELS"
	},
	{
			"value": "PIONEER",
			"label": "PIONEER"
	},
	{
			"value": "PIPS",
			"label": "PIPS"
	},
	{
			"value": "PIQUE",
			"label": "PIQUE"
	},
	{
			"value": "PIQUE KNIT",
			"label": "PIQUE KNIT"
	},
	{
			"value": "PIRATE",
			"label": "PIRATE"
	},
	{
			"value": "PIRATE COSTUME",
			"label": "PIRATE COSTUME"
	},
	{
			"value": "PITCHER",
			"label": "PITCHER"
	},
	{
			"value": "PITTOSPORUM",
			"label": "PITTOSPORUM"
	},
	{
			"value": "PITT PENGUINS",
			"label": "PITT PENGUINS"
	},
	{
			"value": "PITT STEELERS",
			"label": "PITT STEELERS"
	},
	{
			"value": "PIXAR",
			"label": "PIXAR"
	},
	{
			"value": "PIXEL",
			"label": "PIXEL"
	},
	{
			"value": "PIZZA",
			"label": "PIZZA"
	},
	{
			"value": "PLACEMAT",
			"label": "PLACEMAT"
	},
	{
			"value": "PLACEMAT SET",
			"label": "PLACEMAT SET"
	},
	{
			"value": "PLAID",
			"label": "PLAID"
	},
	{
			"value": "PLAID/CHECK",
			"label": "PLAID/CHECK"
	},
	{
			"value": "PLAID CRAFT",
			"label": "PLAID CRAFT"
	},
	{
			"value": "PLAIDITUDE",
			"label": "PLAIDITUDE"
	},
	{
			"value": "PLAIDITUDES",
			"label": "PLAIDITUDES"
	},
	{
			"value": "PLAIDITUDES II",
			"label": "PLAIDITUDES II"
	},
	{
			"value": "PLAID PILLOW",
			"label": "PLAID PILLOW"
	},
	{
			"value": "PLAID POINSETTIA",
			"label": "PLAID POINSETTIA"
	},
	{
			"value": "PLAIDS",
			"label": "PLAIDS"
	},
	{
			"value": "PLAIDS & CHECKS",
			"label": "PLAIDS & CHECKS"
	},
	{
			"value": "PLAID/SUITING",
			"label": "PLAID/SUITING"
	},
	{
			"value": "PLAID THROW",
			"label": "PLAID THROW"
	},
	{
			"value": "PLAIDTUDES",
			"label": "PLAIDTUDES"
	},
	{
			"value": "PLAIN",
			"label": "PLAIN"
	},
	{
			"value": "PLAIN BOA",
			"label": "PLAIN BOA"
	},
	{
			"value": "PLAIN CRAFTS",
			"label": "PLAIN CRAFTS"
	},
	{
			"value": "PLAIN FOAM 12X18",
			"label": "PLAIN FOAM 12X18"
	},
	{
			"value": "PLAIN FOAM 9X12",
			"label": "PLAIN FOAM 9X12"
	},
	{
			"value": "PLANES",
			"label": "PLANES"
	},
	{
			"value": "PLANET",
			"label": "PLANET"
	},
	{
			"value": "PLANETS",
			"label": "PLANETS"
	},
	{
			"value": "PLANNER",
			"label": "PLANNER"
	},
	{
			"value": "PLANNER COMPANION",
			"label": "PLANNER COMPANION"
	},
	{
			"value": "PLANT",
			"label": "PLANT"
	},
	{
			"value": "PLANT CADDY",
			"label": "PLANT CADDY"
	},
	{
			"value": "PLANTER",
			"label": "PLANTER"
	},
	{
			"value": "PLANTER BASIC",
			"label": "PLANTER BASIC"
	},
	{
			"value": "PLANTER BASKET",
			"label": "PLANTER BASKET"
	},
	{
			"value": "PLANTER FIGURAL",
			"label": "PLANTER FIGURAL"
	},
	{
			"value": "PLANTER FLOOR",
			"label": "PLANTER FLOOR"
	},
	{
			"value": "PLANTER HANGING",
			"label": "PLANTER HANGING"
	},
	{
			"value": "PLANTER HOOK",
			"label": "PLANTER HOOK"
	},
	{
			"value": "PLANTER LADDER",
			"label": "PLANTER LADDER"
	},
	{
			"value": "PLANTER LRG",
			"label": "PLANTER LRG"
	},
	{
			"value": "PLANTER MIX & MATCH",
			"label": "PLANTER MIX & MATCH"
	},
	{
			"value": "PLANTER OVERSIZED",
			"label": "PLANTER OVERSIZED"
	},
	{
			"value": "PLANTER PLASTIC",
			"label": "PLANTER PLASTIC"
	},
	{
			"value": "PLANTER PLASTIC FOOT",
			"label": "PLANTER PLASTIC FOOT"
	},
	{
			"value": "PLANTER PORCH",
			"label": "PLANTER PORCH"
	},
	{
			"value": "PLANTER PORCH METAL",
			"label": "PLANTER PORCH METAL"
	},
	{
			"value": "PLANTERS",
			"label": "PLANTERS"
	},
	{
			"value": "PLANTER SAUCER",
			"label": "PLANTER SAUCER"
	},
	{
			"value": "PLANTER SML",
			"label": "PLANTER SML"
	},
	{
			"value": "PLANTER STAND",
			"label": "PLANTER STAND"
	},
	{
			"value": "PLANTER STATUARY",
			"label": "PLANTER STATUARY"
	},
	{
			"value": "PLANTER TABLETOP",
			"label": "PLANTER TABLETOP"
	},
	{
			"value": "PLANTER WALL",
			"label": "PLANTER WALL"
	},
	{
			"value": "PLANTER WALL POCKET",
			"label": "PLANTER WALL POCKET"
	},
	{
			"value": "PLANTER WINDOW",
			"label": "PLANTER WINDOW"
	},
	{
			"value": "PLANT HOLDER",
			"label": "PLANT HOLDER"
	},
	{
			"value": "PLANT HOOK",
			"label": "PLANT HOOK"
	},
	{
			"value": "PLANT MARKER",
			"label": "PLANT MARKER"
	},
	{
			"value": "PLANTS",
			"label": "PLANTS"
	},
	{
			"value": "PLANT SPRAYER",
			"label": "PLANT SPRAYER"
	},
	{
			"value": "PLANT STAND",
			"label": "PLANT STAND"
	},
	{
			"value": "PLANT STAND ROLLING",
			"label": "PLANT STAND ROLLING"
	},
	{
			"value": "PLANT STANDS",
			"label": "PLANT STANDS"
	},
	{
			"value": "PLANT STAND TABLE",
			"label": "PLANT STAND TABLE"
	},
	{
			"value": "PLANT STAND TIERED",
			"label": "PLANT STAND TIERED"
	},
	{
			"value": "PLAQUE",
			"label": "PLAQUE"
	},
	{
			"value": "PLASTER",
			"label": "PLASTER"
	},
	{
			"value": "PLASTER FIGURE",
			"label": "PLASTER FIGURE"
	},
	{
			"value": "PLASTER NATIVITY",
			"label": "PLASTER NATIVITY"
	},
	{
			"value": "PLASTER ORNAMENT",
			"label": "PLASTER ORNAMENT"
	},
	{
			"value": "PLASTER PAINTING KIT",
			"label": "PLASTER PAINTING KIT"
	},
	{
			"value": "PLASTER SHAPE",
			"label": "PLASTER SHAPE"
	},
	{
			"value": "PLASTER VILLAGE",
			"label": "PLASTER VILLAGE"
	},
	{
			"value": "PLASTIC",
			"label": "PLASTIC"
	},
	{
			"value": "PLASTIC BEADS",
			"label": "PLASTIC BEADS"
	},
	{
			"value": "PLASTIC BOTTLES",
			"label": "PLASTIC BOTTLES"
	},
	{
			"value": "PLASTIC CARPET RUN",
			"label": "PLASTIC CARPET RUN"
	},
	{
			"value": "PLASTIC DRAWER",
			"label": "PLASTIC DRAWER"
	},
	{
			"value": "PLASTIC EGG",
			"label": "PLASTIC EGG"
	},
	{
			"value": "PLASTIC LEAVES",
			"label": "PLASTIC LEAVES"
	},
	{
			"value": "PLASTIC LVS",
			"label": "PLASTIC LVS"
	},
	{
			"value": "PLASTIC POG",
			"label": "PLASTIC POG"
	},
	{
			"value": "PLASTIC POUCH",
			"label": "PLASTIC POUCH"
	},
	{
			"value": "PLASTIC SHELF",
			"label": "PLASTIC SHELF"
	},
	{
			"value": "PLASTIC SHELL",
			"label": "PLASTIC SHELL"
	},
	{
			"value": "PLASTIC STORAGE",
			"label": "PLASTIC STORAGE"
	},
	{
			"value": "PLASTIC TOTE",
			"label": "PLASTIC TOTE"
	},
	{
			"value": "PLASTIC TOTES",
			"label": "PLASTIC TOTES"
	},
	{
			"value": "PLATE",
			"label": "PLATE"
	},
	{
			"value": "PLATE 11IN",
			"label": "PLATE 11IN"
	},
	{
			"value": "PLATE 8CT",
			"label": "PLATE 8CT"
	},
	{
			"value": "PLATE 9IN",
			"label": "PLATE 9IN"
	},
	{
			"value": "PLATE APPETIZER",
			"label": "PLATE APPETIZER"
	},
	{
			"value": "PLATE APPETIZER 4PK",
			"label": "PLATE APPETIZER 4PK"
	},
	{
			"value": "PLATE DECOR",
			"label": "PLATE DECOR"
	},
	{
			"value": "PLATE DINNER",
			"label": "PLATE DINNER"
	},
	{
			"value": "PLATE DINNER 4PK",
			"label": "PLATE DINNER 4PK"
	},
	{
			"value": "PLATE FIGURAL",
			"label": "PLATE FIGURAL"
	},
	{
			"value": "PLATE FIGURAL 4PK",
			"label": "PLATE FIGURAL 4PK"
	},
	{
			"value": "PLATE HEADER",
			"label": "PLATE HEADER"
	},
	{
			"value": "PLATE LUNCH",
			"label": "PLATE LUNCH"
	},
	{
			"value": "PLATE OVAL",
			"label": "PLATE OVAL"
	},
	{
			"value": "PLATE PK4",
			"label": "PLATE PK4"
	},
	{
			"value": "PLATE RECTANGLE",
			"label": "PLATE RECTANGLE"
	},
	{
			"value": "PLATE S/4",
			"label": "PLATE S/4"
	},
	{
			"value": "PLATFORM",
			"label": "PLATFORM"
	},
	{
			"value": "PLATTER",
			"label": "PLATTER"
	},
	{
			"value": "PLATTER 8CT",
			"label": "PLATTER 8CT"
	},
	{
			"value": "PLATTER TIN",
			"label": "PLATTER TIN"
	},
	{
			"value": "PLAY DRAW & CREATE",
			"label": "PLAY DRAW & CREATE"
	},
	{
			"value": "PLAY DRAW CREATE",
			"label": "PLAY DRAW CREATE"
	},
	{
			"value": "PLAYER",
			"label": "PLAYER"
	},
	{
			"value": "PLAYMAT",
			"label": "PLAYMAT"
	},
	{
			"value": "PLAY PACK",
			"label": "PLAY PACK"
	},
	{
			"value": "PLAYPACK",
			"label": "PLAYPACK"
	},
	{
			"value": "PLCANB00",
			"label": "PLCANB00"
	},
	{
			"value": "PLEASE TOWEL",
			"label": "PLEASE TOWEL"
	},
	{
			"value": "PLEATED",
			"label": "PLEATED"
	},
	{
			"value": "PLEATS",
			"label": "PLEATS"
	},
	{
			"value": "PLIERS",
			"label": "PLIERS"
	},
	{
			"value": "PLST CONE DOLL",
			"label": "PLST CONE DOLL"
	},
	{
			"value": "PLTSPLLRS",
			"label": "PLTSPLLRS"
	},
	{
			"value": "PLUG IN",
			"label": "PLUG IN"
	},
	{
			"value": "PLUG IN WARMER",
			"label": "PLUG IN WARMER"
	},
	{
			"value": "PLUM BLOSSOM",
			"label": "PLUM BLOSSOM"
	},
	{
			"value": "PLUME",
			"label": "PLUME"
	},
	{
			"value": "PLUME GRASS",
			"label": "PLUME GRASS"
	},
	{
			"value": "PLUSATILLA",
			"label": "PLUSATILLA"
	},
	{
			"value": "PLUSH",
			"label": "PLUSH"
	},
	{
			"value": "PLUSH BASKET",
			"label": "PLUSH BASKET"
	},
	{
			"value": "PLUSH BUNNY",
			"label": "PLUSH BUNNY"
	},
	{
			"value": "PLUSH CHENILLE",
			"label": "PLUSH CHENILLE"
	},
	{
			"value": "PLUSH CLIP",
			"label": "PLUSH CLIP"
	},
	{
			"value": "PLUSHCRAFT",
			"label": "PLUSHCRAFT"
	},
	{
			"value": "PLUSH KIT",
			"label": "PLUSH KIT"
	},
	{
			"value": "PLUSH LAMB",
			"label": "PLUSH LAMB"
	},
	{
			"value": "PLUSH PUPPY",
			"label": "PLUSH PUPPY"
	},
	{
			"value": "PMPKN/BRY IN PMPKN",
			"label": "PMPKN/BRY IN PMPKN"
	},
	{
			"value": "PMPKN/GOURD/BRY IN A",
			"label": "PMPKN/GOURD/BRY IN A"
	},
	{
			"value": "PNCE PNE",
			"label": "PNCE PNE"
	},
	{
			"value": "PNCN",
			"label": "PNCN"
	},
	{
			"value": "PNCN BRY",
			"label": "PNCN BRY"
	},
	{
			"value": "POA",
			"label": "POA"
	},
	{
			"value": "POC CALENDAR",
			"label": "POC CALENDAR"
	},
	{
			"value": "POCKET GUIDE BOOKS",
			"label": "POCKET GUIDE BOOKS"
	},
	{
			"value": "POCKET PLANNER",
			"label": "POCKET PLANNER"
	},
	{
			"value": "POCKET POT",
			"label": "POCKET POT"
	},
	{
			"value": "POD",
			"label": "POD"
	},
	{
			"value": "PODS",
			"label": "PODS"
	},
	{
			"value": "POIN",
			"label": "POIN"
	},
	{
			"value": "POIN HYDRA",
			"label": "POIN HYDRA"
	},
	{
			"value": "POIN PINE BERRY PINE",
			"label": "POIN PINE BERRY PINE"
	},
	{
			"value": "POINSETTA",
			"label": "POINSETTA"
	},
	{
			"value": "POINSETTIA",
			"label": "POINSETTIA"
	},
	{
			"value": "POINSETTIA/BERRY",
			"label": "POINSETTIA/BERRY"
	},
	{
			"value": "POINSETTIA CLIP",
			"label": "POINSETTIA CLIP"
	},
	{
			"value": "POINSETTIA PEONY HYD",
			"label": "POINSETTIA PEONY HYD"
	},
	{
			"value": "POINSETTIAS",
			"label": "POINSETTIAS"
	},
	{
			"value": "POINT D'ESPRIT",
			"label": "POINT D'ESPRIT"
	},
	{
			"value": "POINTSETTIA",
			"label": "POINTSETTIA"
	},
	{
			"value": "POKEMON",
			"label": "POKEMON"
	},
	{
			"value": "POLAR BEAR",
			"label": "POLAR BEAR"
	},
	{
			"value": "POLAR EXPRES",
			"label": "POLAR EXPRES"
	},
	{
			"value": "POLAR MAGIC BLUE",
			"label": "POLAR MAGIC BLUE"
	},
	{
			"value": "POLAR MAGIC WHITE/SI",
			"label": "POLAR MAGIC WHITE/SI"
	},
	{
			"value": "POLAROID",
			"label": "POLAROID"
	},
	{
			"value": "POLISHING",
			"label": "POLISHING"
	},
	{
			"value": "POLITICAL",
			"label": "POLITICAL"
	},
	{
			"value": "POLKA DOT",
			"label": "POLKA DOT"
	},
	{
			"value": "POLY",
			"label": "POLY"
	},
	{
			"value": "POLYBRAID",
			"label": "POLYBRAID"
	},
	{
			"value": "POLY COTTON",
			"label": "POLY COTTON"
	},
	{
			"value": "POLYESTER",
			"label": "POLYESTER"
	},
	{
			"value": "POLY-FIL",
			"label": "POLY-FIL"
	},
	{
			"value": "POLYFORM",
			"label": "POLYFORM"
	},
	{
			"value": "POLY FRINGE",
			"label": "POLY FRINGE"
	},
	{
			"value": "POLY SHANTUNG",
			"label": "POLY SHANTUNG"
	},
	{
			"value": "POM",
			"label": "POM"
	},
	{
			"value": "POME",
			"label": "POME"
	},
	{
			"value": "POMEGRANATE",
			"label": "POMEGRANATE"
	},
	{
			"value": "POMEGRANATE LEAVES",
			"label": "POMEGRANATE LEAVES"
	},
	{
			"value": "POMEGRANTE",
			"label": "POMEGRANTE"
	},
	{
			"value": "POM POM",
			"label": "POM POM"
	},
	{
			"value": "POMPOM",
			"label": "POMPOM"
	},
	{
			"value": "POM POM KIT",
			"label": "POM POM KIT"
	},
	{
			"value": "POM POM RIBBON",
			"label": "POM POM RIBBON"
	},
	{
			"value": "POMS",
			"label": "POMS"
	},
	{
			"value": "PONDEROSA",
			"label": "PONDEROSA"
	},
	{
			"value": "PONYB",
			"label": "PONYB"
	},
	{
			"value": "PONY BEAD",
			"label": "PONY BEAD"
	},
	{
			"value": "PONY BEAD BOOKS",
			"label": "PONY BEAD BOOKS"
	},
	{
			"value": "PONY BEAD KIT",
			"label": "PONY BEAD KIT"
	},
	{
			"value": "PONYBEAD LACING",
			"label": "PONYBEAD LACING"
	},
	{
			"value": "PONY SCARF",
			"label": "PONY SCARF"
	},
	{
			"value": "PONY TAIL",
			"label": "PONY TAIL"
	},
	{
			"value": "PONY TAIL ELASTIC",
			"label": "PONY TAIL ELASTIC"
	},
	{
			"value": "PONY TAIL- FUR POM",
			"label": "PONY TAIL- FUR POM"
	},
	{
			"value": "POOF-SLINKY",
			"label": "POOF-SLINKY"
	},
	{
			"value": "POP!",
			"label": "POP!"
	},
	{
			"value": "POP BUBBLES",
			"label": "POP BUBBLES"
	},
	{
			"value": "POP! BUBBLES",
			"label": "POP! BUBBLES"
	},
	{
			"value": "POP CAN",
			"label": "POP CAN"
	},
	{
			"value": "POP! CHALK",
			"label": "POP! CHALK"
	},
	{
			"value": "POP HOME",
			"label": "POP HOME"
	},
	{
			"value": "POP! HOME",
			"label": "POP! HOME"
	},
	{
			"value": "POPPERS",
			"label": "POPPERS"
	},
	{
			"value": "POPPERS CS",
			"label": "POPPERS CS"
	},
	{
			"value": "POP POP",
			"label": "POP POP"
	},
	{
			"value": "POPPY",
			"label": "POPPY"
	},
	{
			"value": "POPPY CHERRY BLOSSOM",
			"label": "POPPY CHERRY BLOSSOM"
	},
	{
			"value": "POPPY DAISY PEONY",
			"label": "POPPY DAISY PEONY"
	},
	{
			"value": "POPSICLE",
			"label": "POPSICLE"
	},
	{
			"value": "POPSICLES",
			"label": "POPSICLES"
	},
	{
			"value": "POP SOCKETS",
			"label": "POP SOCKETS"
	},
	{
			"value": "PORCH",
			"label": "PORCH"
	},
	{
			"value": "PORCH BANNER",
			"label": "PORCH BANNER"
	},
	{
			"value": "PORCH DECOR",
			"label": "PORCH DECOR"
	},
	{
			"value": "PORCH DÉCOR",
			"label": "PORCH DÉCOR"
	},
	{
			"value": "PORCH LANTERN",
			"label": "PORCH LANTERN"
	},
	{
			"value": "PORCH LANTERNS",
			"label": "PORCH LANTERNS"
	},
	{
			"value": "PORCH PLANTER",
			"label": "PORCH PLANTER"
	},
	{
			"value": "PORCH PUMPKIN",
			"label": "PORCH PUMPKIN"
	},
	{
			"value": "PORCH RULES",
			"label": "PORCH RULES"
	},
	{
			"value": "PORCH SIGN",
			"label": "PORCH SIGN"
	},
	{
			"value": "PORCH SITTER",
			"label": "PORCH SITTER"
	},
	{
			"value": "PORCH SITTER LADDER",
			"label": "PORCH SITTER LADDER"
	},
	{
			"value": "PORCH SITTER LED",
			"label": "PORCH SITTER LED"
	},
	{
			"value": "PORCH SITTER XL",
			"label": "PORCH SITTER XL"
	},
	{
			"value": "PORCH SQUATTER",
			"label": "PORCH SQUATTER"
	},
	{
			"value": "PORCH SQUATTER 2",
			"label": "PORCH SQUATTER 2"
	},
	{
			"value": "PORCH WALL DECOR",
			"label": "PORCH WALL DECOR"
	},
	{
			"value": "PORCH WALL DÉCOR",
			"label": "PORCH WALL DÉCOR"
	},
	{
			"value": "PORCH WD",
			"label": "PORCH WD"
	},
	{
			"value": "PORTRAIT 2",
			"label": "PORTRAIT 2"
	},
	{
			"value": "PORTRAIT 3",
			"label": "PORTRAIT 3"
	},
	{
			"value": "PORTRAIT COLLAGE NEW",
			"label": "PORTRAIT COLLAGE NEW"
	},
	{
			"value": "POST",
			"label": "POST"
	},
	{
			"value": "POSTCARD",
			"label": "POSTCARD"
	},
	{
			"value": "POSTCARD PACK",
			"label": "POSTCARD PACK"
	},
	{
			"value": "POSTER",
			"label": "POSTER"
	},
	{
			"value": "POSTER BOARD",
			"label": "POSTER BOARD"
	},
	{
			"value": "POSTERBOARD LETTERS",
			"label": "POSTERBOARD LETTERS"
	},
	{
			"value": "POSTER MAKING",
			"label": "POSTER MAKING"
	},
	{
			"value": "POSTER MARKER",
			"label": "POSTER MARKER"
	},
	{
			"value": "POSTER POG",
			"label": "POSTER POG"
	},
	{
			"value": "POSTER POG FW28",
			"label": "POSTER POG FW28"
	},
	{
			"value": "POT",
			"label": "POT"
	},
	{
			"value": "POTATO HEAD",
			"label": "POTATO HEAD"
	},
	{
			"value": "POTATO LEAF",
			"label": "POTATO LEAF"
	},
	{
			"value": "POT COVER",
			"label": "POT COVER"
	},
	{
			"value": "POT HOLDER",
			"label": "POT HOLDER"
	},
	{
			"value": "POTHOLDER",
			"label": "POTHOLDER"
	},
	{
			"value": "POTHOLDERS",
			"label": "POTHOLDERS"
	},
	{
			"value": "POTHOLDER SET",
			"label": "POTHOLDER SET"
	},
	{
			"value": "POTHOS",
			"label": "POTHOS"
	},
	{
			"value": "POTION BOTTLE",
			"label": "POTION BOTTLE"
	},
	{
			"value": "POTION KIT",
			"label": "POTION KIT"
	},
	{
			"value": "POTIONS",
			"label": "POTIONS"
	},
	{
			"value": "POT MITT",
			"label": "POT MITT"
	},
	{
			"value": "POT PERCHER",
			"label": "POT PERCHER"
	},
	{
			"value": "POT PICK",
			"label": "POT PICK"
	},
	{
			"value": "POTPOURRI",
			"label": "POTPOURRI"
	},
	{
			"value": "POTS",
			"label": "POTS"
	},
	{
			"value": "POTTED",
			"label": "POTTED"
	},
	{
			"value": "POTTED PLANT",
			"label": "POTTED PLANT"
	},
	{
			"value": "POTTED POIN",
			"label": "POTTED POIN"
	},
	{
			"value": "POTTING BENCH",
			"label": "POTTING BENCH"
	},
	{
			"value": "POTTING SOIL",
			"label": "POTTING SOIL"
	},
	{
			"value": "POUCH",
			"label": "POUCH"
	},
	{
			"value": "POUF",
			"label": "POUF"
	},
	{
			"value": "POUND OF BUTTONS",
			"label": "POUND OF BUTTONS"
	},
	{
			"value": "POWDERED SUGAR",
			"label": "POWDERED SUGAR"
	},
	{
			"value": "POWDER-SHINE",
			"label": "POWDER-SHINE"
	},
	{
			"value": "POWER MESH",
			"label": "POWER MESH"
	},
	{
			"value": "POWER PANEL",
			"label": "POWER PANEL"
	},
	{
			"value": "POWER STRETCH",
			"label": "POWER STRETCH"
	},
	{
			"value": "PP",
			"label": "PP"
	},
	{
			"value": "PP1 PT ACCESSORIES",
			"label": "PP1 PT ACCESSORIES"
	},
	{
			"value": "PP5 PATHWAY LIGHTING",
			"label": "PP5 PATHWAY LIGHTING"
	},
	{
			"value": "PP 6",
			"label": "PP 6"
	},
	{
			"value": "PP6 CORK RICE LIGHT",
			"label": "PP6 CORK RICE LIGHT"
	},
	{
			"value": "PP 9",
			"label": "PP 9"
	},
	{
			"value": "PP  9 BRT METAL COLL",
			"label": "PP  9 BRT METAL COLL"
	},
	{
			"value": "PPK",
			"label": "PPK"
	},
	{
			"value": "P POWER POW CANDLE",
			"label": "P POWER POW CANDLE"
	},
	{
			"value": "PPRALB00",
			"label": "PPRALB00"
	},
	{
			"value": "P PR FRAGRANT CANDLE",
			"label": "P PR FRAGRANT CANDLE"
	},
	{
			"value": "PRCT UPHSTRY VNYL",
			"label": "PRCT UPHSTRY VNYL"
	},
	{
			"value": "PRECUT OUTDOOR",
			"label": "PRECUT OUTDOOR"
	},
	{
			"value": "PRECUT PATTERNS",
			"label": "PRECUT PATTERNS"
	},
	{
			"value": "PRE-CUTS",
			"label": "PRE-CUTS"
	},
	{
			"value": "PRECUT VINYL",
			"label": "PRECUT VINYL"
	},
	{
			"value": "PRELIT TREE",
			"label": "PRELIT TREE"
	},
	{
			"value": "PREMIER",
			"label": "PREMIER"
	},
	{
			"value": "PREMIER YARNS",
			"label": "PREMIER YARNS"
	},
	{
			"value": "PREMIUM",
			"label": "PREMIUM"
	},
	{
			"value": "PREMIUM 12X18",
			"label": "PREMIUM 12X18"
	},
	{
			"value": "PREMIUM 9X12",
			"label": "PREMIUM 9X12"
	},
	{
			"value": "PREMIUM FELT",
			"label": "PREMIUM FELT"
	},
	{
			"value": "PREMIUM GIFT WRAP",
			"label": "PREMIUM GIFT WRAP"
	},
	{
			"value": "PREMIUM NOVELTY",
			"label": "PREMIUM NOVELTY"
	},
	{
			"value": "PREMIUM QUILT PRINTS",
			"label": "PREMIUM QUILT PRINTS"
	},
	{
			"value": "PREMIUM WIDE",
			"label": "PREMIUM WIDE"
	},
	{
			"value": "PREMIUM WRAP",
			"label": "PREMIUM WRAP"
	},
	{
			"value": "PREM NLTY-A",
			"label": "PREM NLTY-A"
	},
	{
			"value": "PREM NLTY-B",
			"label": "PREM NLTY-B"
	},
	{
			"value": "PREM NLTY-C",
			"label": "PREM NLTY-C"
	},
	{
			"value": "PREM NLTY-D",
			"label": "PREM NLTY-D"
	},
	{
			"value": "PREM NLTY-E",
			"label": "PREM NLTY-E"
	},
	{
			"value": "PREM NLTY-F",
			"label": "PREM NLTY-F"
	},
	{
			"value": "PREM ORGANIC COTTON",
			"label": "PREM ORGANIC COTTON"
	},
	{
			"value": "PREMQA02",
			"label": "PREMQA02"
	},
	{
			"value": "PREPACK",
			"label": "PREPACK"
	},
	{
			"value": "PREPACKS",
			"label": "PREPACKS"
	},
	{
			"value": "PRESCHOOL ACTIVITY W",
			"label": "PRESCHOOL ACTIVITY W"
	},
	{
			"value": "PRESCHOOL EDUCATIONA",
			"label": "PRESCHOOL EDUCATIONA"
	},
	{
			"value": "PRESENTS",
			"label": "PRESENTS"
	},
	{
			"value": "PRESERVED LEAVES",
			"label": "PRESERVED LEAVES"
	},
	{
			"value": "PRES-ON",
			"label": "PRES-ON"
	},
	{
			"value": "PRESSED PAPER",
			"label": "PRESSED PAPER"
	},
	{
			"value": "PRESSER FOOT",
			"label": "PRESSER FOOT"
	},
	{
			"value": "PRESTO 9X12",
			"label": "PRESTO 9X12"
	},
	{
			"value": "PRESTO FELT",
			"label": "PRESTO FELT"
	},
	{
			"value": "PRETEND",
			"label": "PRETEND"
	},
	{
			"value": "PRETTY PRINCESS KITS",
			"label": "PRETTY PRINCESS KITS"
	},
	{
			"value": "PRETZEL BAG",
			"label": "PRETZEL BAG"
	},
	{
			"value": "PRETZEL KIT",
			"label": "PRETZEL KIT"
	},
	{
			"value": "PRETZELS",
			"label": "PRETZELS"
	},
	{
			"value": "PRICESS SOFIA",
			"label": "PRICESS SOFIA"
	},
	{
			"value": "PRICE TAGS",
			"label": "PRICE TAGS"
	},
	{
			"value": "PRIDE",
			"label": "PRIDE"
	},
	{
			"value": "PRIDE DECO",
			"label": "PRIDE DECO"
	},
	{
			"value": "PRIDE RIBBON",
			"label": "PRIDE RIBBON"
	},
	{
			"value": "PRIDE SASH",
			"label": "PRIDE SASH"
	},
	{
			"value": "PRIINT",
			"label": "PRIINT"
	},
	{
			"value": "PRIMA FLOWERS",
			"label": "PRIMA FLOWERS"
	},
	{
			"value": "PRIMARY",
			"label": "PRIMARY"
	},
	{
			"value": "PRIMARY  ASSORTMENT",
			"label": "PRIMARY  ASSORTMENT"
	},
	{
			"value": "PRINCESS",
			"label": "PRINCESS"
	},
	{
			"value": "PRINCESS COSTUME",
			"label": "PRINCESS COSTUME"
	},
	{
			"value": "PRINCESSES",
			"label": "PRINCESSES"
	},
	{
			"value": "PRINCESS IN BLOOM",
			"label": "PRINCESS IN BLOOM"
	},
	{
			"value": "PRINCESS KEYS",
			"label": "PRINCESS KEYS"
	},
	{
			"value": "PRINCESS SOFIA",
			"label": "PRINCESS SOFIA"
	},
	{
			"value": "PRINCESS- TINK",
			"label": "PRINCESS- TINK"
	},
	{
			"value": "PRINCESS-TINK",
			"label": "PRINCESS-TINK"
	},
	{
			"value": "PRINCESS & VILLAINS",
			"label": "PRINCESS & VILLAINS"
	},
	{
			"value": "PRINT",
			"label": "PRINT"
	},
	{
			"value": "PRINT 9X12",
			"label": "PRINT 9X12"
	},
	{
			"value": "PRINTABLE FABRIC",
			"label": "PRINTABLE FABRIC"
	},
	{
			"value": "PRINTABLE PAPER",
			"label": "PRINTABLE PAPER"
	},
	{
			"value": "PRINT BLIZZARD FOR A",
			"label": "PRINT BLIZZARD FOR A"
	},
	{
			"value": "PRINT BLIZZARD FOR B",
			"label": "PRINT BLIZZARD FOR B"
	},
	{
			"value": "PRINTED",
			"label": "PRINTED"
	},
	{
			"value": "PRINTED BULAP",
			"label": "PRINTED BULAP"
	},
	{
			"value": "PRINTED CONSTRUCTION",
			"label": "PRINTED CONSTRUCTION"
	},
	{
			"value": "PRINTED DENIM",
			"label": "PRINTED DENIM"
	},
	{
			"value": "PRINTED GEO",
			"label": "PRINTED GEO"
	},
	{
			"value": "PRINTED INTERLOCK",
			"label": "PRINTED INTERLOCK"
	},
	{
			"value": "PRINTED LINEN",
			"label": "PRINTED LINEN"
	},
	{
			"value": "PRINTED LINER",
			"label": "PRINTED LINER"
	},
	{
			"value": "PRINTED PLAID",
			"label": "PRINTED PLAID"
	},
	{
			"value": "PRINTED POLYESTER MA",
			"label": "PRINTED POLYESTER MA"
	},
	{
			"value": "PRINTED SEA LIFE",
			"label": "PRINTED SEA LIFE"
	},
	{
			"value": "PRINTED SPANDEX",
			"label": "PRINTED SPANDEX"
	},
	{
			"value": "PRINTED STRIPE",
			"label": "PRINTED STRIPE"
	},
	{
			"value": "PRINTED STRIPES",
			"label": "PRINTED STRIPES"
	},
	{
			"value": "PRINTED TULLE",
			"label": "PRINTED TULLE"
	},
	{
			"value": "PRINT FOIL",
			"label": "PRINT FOIL"
	},
	{
			"value": "PRINT PILLOW",
			"label": "PRINT PILLOW"
	},
	{
			"value": "PRINT TOWEL",
			"label": "PRINT TOWEL"
	},
	{
			"value": "PRINT WD",
			"label": "PRINT WD"
	},
	{
			"value": "PRISMACOLOR",
			"label": "PRISMACOLOR"
	},
	{
			"value": "PRISMACOLOR SET",
			"label": "PRISMACOLOR SET"
	},
	{
			"value": "PRITNED KRAFT WRAP",
			"label": "PRITNED KRAFT WRAP"
	},
	{
			"value": "PRO-ART",
			"label": "PRO-ART"
	},
	{
			"value": "PROART",
			"label": "PROART"
	},
	{
			"value": "PRODUCT PERFORMERS",
			"label": "PRODUCT PERFORMERS"
	},
	{
			"value": "PROFESSION",
			"label": "PROFESSION"
	},
	{
			"value": "PROJECT FLEECE",
			"label": "PROJECT FLEECE"
	},
	{
			"value": "PROJECT FOAM",
			"label": "PROJECT FOAM"
	},
	{
			"value": "PROJECT LIFE",
			"label": "PROJECT LIFE"
	},
	{
			"value": "PROJECTOR",
			"label": "PROJECTOR"
	},
	{
			"value": "PROJECT PLAN IT",
			"label": "PROJECT PLAN IT"
	},
	{
			"value": "PROJECT RUNWAY",
			"label": "PROJECT RUNWAY"
	},
	{
			"value": "PROMO",
			"label": "PROMO"
	},
	{
			"value": "PROMO FLANNEL",
			"label": "PROMO FLANNEL"
	},
	{
			"value": "PROMO FLEECE",
			"label": "PROMO FLEECE"
	},
	{
			"value": "PROMOFLEECE",
			"label": "PROMOFLEECE"
	},
	{
			"value": "PROMO RIBBON",
			"label": "PROMO RIBBON"
	},
	{
			"value": "PROMO WRAP",
			"label": "PROMO WRAP"
	},
	{
			"value": "PROMO WREATH",
			"label": "PROMO WREATH"
	},
	{
			"value": "PRONG",
			"label": "PRONG"
	},
	{
			"value": "PRO SERIES",
			"label": "PRO SERIES"
	},
	{
			"value": "PROTEA",
			"label": "PROTEA"
	},
	{
			"value": "PROTECTIVE FLANNEL",
			"label": "PROTECTIVE FLANNEL"
	},
	{
			"value": "PROVO CRAFT",
			"label": "PROVO CRAFT"
	},
	{
			"value": "PRSHNA00",
			"label": "PRSHNA00"
	},
	{
			"value": "PRYM CONSUMER USA",
			"label": "PRYM CONSUMER USA"
	},
	{
			"value": "PTBAS",
			"label": "PTBAS"
	},
	{
			"value": "PTBASA00",
			"label": "PTBASA00"
	},
	{
			"value": "PTBASB00",
			"label": "PTBASB00"
	},
	{
			"value": "PTFIN",
			"label": "PTFIN"
	},
	{
			"value": "PTFINA00",
			"label": "PTFINA00"
	},
	{
			"value": "PTFINB00",
			"label": "PTFINB00"
	},
	{
			"value": "PTFINC00",
			"label": "PTFINC00"
	},
	{
			"value": "PTFINE00",
			"label": "PTFINE00"
	},
	{
			"value": "PTFINE01",
			"label": "PTFINE01"
	},
	{
			"value": "PTFINE02",
			"label": "PTFINE02"
	},
	{
			"value": "PTFINE03",
			"label": "PTFINE03"
	},
	{
			"value": "P TOUCH PRINTER",
			"label": "P TOUCH PRINTER"
	},
	{
			"value": "P TOUCH TAPE AND RBN",
			"label": "P TOUCH TAPE AND RBN"
	},
	{
			"value": "PTWR2A00",
			"label": "PTWR2A00"
	},
	{
			"value": "PTWR3",
			"label": "PTWR3"
	},
	{
			"value": "PTWR3A00",
			"label": "PTWR3A00"
	},
	{
			"value": "PTWR4A00",
			"label": "PTWR4A00"
	},
	{
			"value": "PU COATED",
			"label": "PU COATED"
	},
	{
			"value": "PUFFER",
			"label": "PUFFER"
	},
	{
			"value": "PUFFER COAT",
			"label": "PUFFER COAT"
	},
	{
			"value": "PUGS",
			"label": "PUGS"
	},
	{
			"value": "PUKPKIN",
			"label": "PUKPKIN"
	},
	{
			"value": "PULL TAG",
			"label": "PULL TAG"
	},
	{
			"value": "PUMPKIM",
			"label": "PUMPKIM"
	},
	{
			"value": "PUMPKIN",
			"label": "PUMPKIN"
	},
	{
			"value": "PUMPKIN ACCESSORY",
			"label": "PUMPKIN ACCESSORY"
	},
	{
			"value": "PUMPKIN / ACORN",
			"label": "PUMPKIN / ACORN"
	},
	{
			"value": "PUMPKIN BASIC",
			"label": "PUMPKIN BASIC"
	},
	{
			"value": "PUMPKIN BERRIES",
			"label": "PUMPKIN BERRIES"
	},
	{
			"value": "PUMPKIN/BERRY",
			"label": "PUMPKIN/BERRY"
	},
	{
			"value": "PUMPKIN BONES",
			"label": "PUMPKIN BONES"
	},
	{
			"value": "PUMPKIN BRAIDED",
			"label": "PUMPKIN BRAIDED"
	},
	{
			"value": "PUMPKIN-BRY-MAPLE LV",
			"label": "PUMPKIN-BRY-MAPLE LV"
	},
	{
			"value": "PUMPKIN BURLAP",
			"label": "PUMPKIN BURLAP"
	},
	{
			"value": "PUMPKIN CARVEABLE",
			"label": "PUMPKIN CARVEABLE"
	},
	{
			"value": "PUMPKIN CERAMIC",
			"label": "PUMPKIN CERAMIC"
	},
	{
			"value": "PUMPKIN COZY",
			"label": "PUMPKIN COZY"
	},
	{
			"value": "PUMPKIN FABRIC",
			"label": "PUMPKIN FABRIC"
	},
	{
			"value": "PUMPKIN FASHION",
			"label": "PUMPKIN FASHION"
	},
	{
			"value": "PUMPKIN FELT",
			"label": "PUMPKIN FELT"
	},
	{
			"value": "PUMPKIN FOAM",
			"label": "PUMPKIN FOAM"
	},
	{
			"value": "PUMPKIN GLAM",
			"label": "PUMPKIN GLAM"
	},
	{
			"value": "PUMPKIN GLASS",
			"label": "PUMPKIN GLASS"
	},
	{
			"value": "PUMPKIN GLASS LED",
			"label": "PUMPKIN GLASS LED"
	},
	{
			"value": "PUMPKIN GLITTER",
			"label": "PUMPKIN GLITTER"
	},
	{
			"value": "PUMPKIN GRASS",
			"label": "PUMPKIN GRASS"
	},
	{
			"value": "PUMPKIN HOLDER",
			"label": "PUMPKIN HOLDER"
	},
	{
			"value": "PUMPKIN ICON",
			"label": "PUMPKIN ICON"
	},
	{
			"value": "PUMPKIN JACK O LANT",
			"label": "PUMPKIN JACK O LANT"
	},
	{
			"value": "PUMPKIN JACKOLANTERN",
			"label": "PUMPKIN JACKOLANTERN"
	},
	{
			"value": "PUMPKIN LEATHER",
			"label": "PUMPKIN LEATHER"
	},
	{
			"value": "PUMPKIN LED",
			"label": "PUMPKIN LED"
	},
	{
			"value": "PUMPKIN LIGHT",
			"label": "PUMPKIN LIGHT"
	},
	{
			"value": "PUMPKIN LVS",
			"label": "PUMPKIN LVS"
	},
	{
			"value": "PUMPKIN LVS PNCN",
			"label": "PUMPKIN LVS PNCN"
	},
	{
			"value": "PUMPKIN MAPLE",
			"label": "PUMPKIN MAPLE"
	},
	{
			"value": "PUMPKIN MARBLE",
			"label": "PUMPKIN MARBLE"
	},
	{
			"value": "PUMPKIN NATURAL",
			"label": "PUMPKIN NATURAL"
	},
	{
			"value": "PUMPKIN NATURAL LED",
			"label": "PUMPKIN NATURAL LED"
	},
	{
			"value": "PUMPKIN NOVELTY",
			"label": "PUMPKIN NOVELTY"
	},
	{
			"value": "PUMPKIN ORN",
			"label": "PUMPKIN ORN"
	},
	{
			"value": "PUMPKIN PATTERNED",
			"label": "PUMPKIN PATTERNED"
	},
	{
			"value": "PUMPKIN PORCH",
			"label": "PUMPKIN PORCH"
	},
	{
			"value": "PUMPKIN PORCH STACK",
			"label": "PUMPKIN PORCH STACK"
	},
	{
			"value": "PUMPKIN PRINT",
			"label": "PUMPKIN PRINT"
	},
	{
			"value": " PUMPKIN, RIBBON, GR",
			"label": " PUMPKIN, RIBBON, GR"
	},
	{
			"value": "PUMPKINS",
			"label": "PUMPKINS"
	},
	{
			"value": "PUMPKINS BOXED",
			"label": "PUMPKINS BOXED"
	},
	{
			"value": "PUMPKIN SCARECROW",
			"label": "PUMPKIN SCARECROW"
	},
	{
			"value": "PUMPKIN SCENE",
			"label": "PUMPKIN SCENE"
	},
	{
			"value": "PUMPKIN SENTIMENT",
			"label": "PUMPKIN SENTIMENT"
	},
	{
			"value": "PUMPKINS & FLOWERS",
			"label": "PUMPKINS & FLOWERS"
	},
	{
			"value": "PUMPKINS & GOURDS",
			"label": "PUMPKINS & GOURDS"
	},
	{
			"value": "PUMPKIN SNFLW",
			"label": "PUMPKIN SNFLW"
	},
	{
			"value": "PUMPKIN SOLID",
			"label": "PUMPKIN SOLID"
	},
	{
			"value": "PUMPKIN STACKED",
			"label": "PUMPKIN STACKED"
	},
	{
			"value": "PUMPKIN STACKED LED",
			"label": "PUMPKIN STACKED LED"
	},
	{
			"value": "PUMPKIN STAND",
			"label": "PUMPKIN STAND"
	},
	{
			"value": "PUMPKIN STRIPES",
			"label": "PUMPKIN STRIPES"
	},
	{
			"value": "PUMPKIN SUNFLOWER",
			"label": "PUMPKIN SUNFLOWER"
	},
	{
			"value": "PUMPKIN SWEATER",
			"label": "PUMPKIN SWEATER"
	},
	{
			"value": "PUMPKIN TABLE DECOR",
			"label": "PUMPKIN TABLE DECOR"
	},
	{
			"value": "PUMPKIN TINSEL",
			"label": "PUMPKIN TINSEL"
	},
	{
			"value": "PUMPKIN VELVET",
			"label": "PUMPKIN VELVET"
	},
	{
			"value": "PUMPKIN WIRE",
			"label": "PUMPKIN WIRE"
	},
	{
			"value": "PUMPKIN WOOD",
			"label": "PUMPKIN WOOD"
	},
	{
			"value": "PUMPKIN WOODCHIP",
			"label": "PUMPKIN WOODCHIP"
	},
	{
			"value": "PUMPKIN WORD BLOCK",
			"label": "PUMPKIN WORD BLOCK"
	},
	{
			"value": "PUNCH",
			"label": "PUNCH"
	},
	{
			"value": "PUNCH BUNCH",
			"label": "PUNCH BUNCH"
	},
	{
			"value": "PUNCHNEEDLE",
			"label": "PUNCHNEEDLE"
	},
	{
			"value": "PUNCHOUT",
			"label": "PUNCHOUT"
	},
	{
			"value": "PUNCHOUT LETTERS",
			"label": "PUNCHOUT LETTERS"
	},
	{
			"value": "PUNK",
			"label": "PUNK"
	},
	{
			"value": "PUNS",
			"label": "PUNS"
	},
	{
			"value": "PUP",
			"label": "PUP"
	},
	{
			"value": "PUP GIFT BAG",
			"label": "PUP GIFT BAG"
	},
	{
			"value": "PUPMKIN",
			"label": "PUPMKIN"
	},
	{
			"value": "PURPLE",
			"label": "PURPLE"
	},
	{
			"value": "PURPLE AND GREEN HAL",
			"label": "PURPLE AND GREEN HAL"
	},
	{
			"value": "PURPLE AND GREEN RIB",
			"label": "PURPLE AND GREEN RIB"
	},
	{
			"value": "PURPLE COWS",
			"label": "PURPLE COWS"
	},
	{
			"value": "PURPLE DK",
			"label": "PURPLE DK"
	},
	{
			"value": "PURPLE LT",
			"label": "PURPLE LT"
	},
	{
			"value": "PURRFECT CAT FLNL",
			"label": "PURRFECT CAT FLNL"
	},
	{
			"value": "PURSE",
			"label": "PURSE"
	},
	{
			"value": "PURSE BUCKLE",
			"label": "PURSE BUCKLE"
	},
	{
			"value": "PURSE CLASP",
			"label": "PURSE CLASP"
	},
	{
			"value": "PURSE FEET",
			"label": "PURSE FEET"
	},
	{
			"value": "PURSE FRAME",
			"label": "PURSE FRAME"
	},
	{
			"value": "PURSE GROMMETT",
			"label": "PURSE GROMMETT"
	},
	{
			"value": "PURSE HANDLE",
			"label": "PURSE HANDLE"
	},
	{
			"value": "PURSE RING",
			"label": "PURSE RING"
	},
	{
			"value": "PURSE SNAP",
			"label": "PURSE SNAP"
	},
	{
			"value": "PURSE STRAP",
			"label": "PURSE STRAP"
	},
	{
			"value": "PURSE TAB",
			"label": "PURSE TAB"
	},
	{
			"value": "PUSH MOLD",
			"label": "PUSH MOLD"
	},
	{
			"value": "PUSSY WILLOW",
			"label": "PUSSY WILLOW"
	},
	{
			"value": "PUSSYWILLOW",
			"label": "PUSSYWILLOW"
	},
	{
			"value": "PUTKA",
			"label": "PUTKA"
	},
	{
			"value": "PUTTY",
			"label": "PUTTY"
	},
	{
			"value": "PUZZLE",
			"label": "PUZZLE"
	},
	{
			"value": "PUZZLE ACCESSORY",
			"label": "PUZZLE ACCESSORY"
	},
	{
			"value": "PUZZLED",
			"label": "PUZZLED"
	},
	{
			"value": "PUZZLE PAIRS",
			"label": "PUZZLE PAIRS"
	},
	{
			"value": "PUZZLES",
			"label": "PUZZLES"
	},
	{
			"value": "PVC",
			"label": "PVC"
	},
	{
			"value": "PVC BAG",
			"label": "PVC BAG"
	},
	{
			"value": "PVC GARLAND",
			"label": "PVC GARLAND"
	},
	{
			"value": "PVC LIGHT",
			"label": "PVC LIGHT"
	},
	{
			"value": "PVC NOVELTY",
			"label": "PVC NOVELTY"
	},
	{
			"value": "PVC PINE",
			"label": "PVC PINE"
	},
	{
			"value": "PVC PROMO",
			"label": "PVC PROMO"
	},
	{
			"value": "PVC TEARDROP",
			"label": "PVC TEARDROP"
	},
	{
			"value": "PVC TREE",
			"label": "PVC TREE"
	},
	{
			"value": "PVC WREATH",
			"label": "PVC WREATH"
	},
	{
			"value": "PWP TOTE BAG",
			"label": "PWP TOTE BAG"
	},
	{
			"value": "PYTHOS",
			"label": "PYTHOS"
	},
	{
			"value": "Q2 OOL 1",
			"label": "Q2 OOL 1"
	},
	{
			"value": "Q4 QUICK PICKS",
			"label": "Q4 QUICK PICKS"
	},
	{
			"value": "Q4 SNUGGLE",
			"label": "Q4 SNUGGLE"
	},
	{
			"value": "QBOM",
			"label": "QBOM"
	},
	{
			"value": "QNOTN",
			"label": "QNOTN"
	},
	{
			"value": "QNOTNA00",
			"label": "QNOTNA00"
	},
	{
			"value": "QNOTND00",
			"label": "QNOTND00"
	},
	{
			"value": "QNOTND01",
			"label": "QNOTND01"
	},
	{
			"value": "Q'S",
			"label": "Q'S"
	},
	{
			"value": "QTPKGE00",
			"label": "QTPKGE00"
	},
	{
			"value": "QTPKGE01",
			"label": "QTPKGE01"
	},
	{
			"value": "QUALITY GROWERS",
			"label": "QUALITY GROWERS"
	},
	{
			"value": "QUALITY TIME",
			"label": "QUALITY TIME"
	},
	{
			"value": "QUAYSIDE PUBLISHING",
			"label": "QUAYSIDE PUBLISHING"
	},
	{
			"value": "QUEEN ANN",
			"label": "QUEEN ANN"
	},
	{
			"value": "QUEEN ANNE",
			"label": "QUEEN ANNE"
	},
	{
			"value": "QUEEN ANNE LACE",
			"label": "QUEEN ANNE LACE"
	},
	{
			"value": "QUEEN ANNES LACE",
			"label": "QUEEN ANNES LACE"
	},
	{
			"value": "QUEEN ANN LACE",
			"label": "QUEEN ANN LACE"
	},
	{
			"value": "QUEEN ANNS LACE",
			"label": "QUEEN ANNS LACE"
	},
	{
			"value": "QUEEN BAG",
			"label": "QUEEN BAG"
	},
	{
			"value": "QUEEN BEE FLNL",
			"label": "QUEEN BEE FLNL"
	},
	{
			"value": "QUEEN & CO",
			"label": "QUEEN & CO"
	},
	{
			"value": "QUEEN GIFT BAG",
			"label": "QUEEN GIFT BAG"
	},
	{
			"value": "QUICK DRY",
			"label": "QUICK DRY"
	},
	{
			"value": "QUICK/EASY",
			"label": "QUICK/EASY"
	},
	{
			"value": "QUICKPICKS HYDRATION",
			"label": "QUICKPICKS HYDRATION"
	},
	{
			"value": "QUICK QUILTING PROJ",
			"label": "QUICK QUILTING PROJ"
	},
	{
			"value": "QUICK QUOTES",
			"label": "QUICK QUOTES"
	},
	{
			"value": "QUICKUTZ",
			"label": "QUICKUTZ"
	},
	{
			"value": "QUILLED CREATIONS",
			"label": "QUILLED CREATIONS"
	},
	{
			"value": "QUILLING",
			"label": "QUILLING"
	},
	{
			"value": "QUILT BINDING",
			"label": "QUILT BINDING"
	},
	{
			"value": "QUILT BLOCK BOOKLET",
			"label": "QUILT BLOCK BOOKLET"
	},
	{
			"value": "QUILT BLOCK OF THE M",
			"label": "QUILT BLOCK OF THE M"
	},
	{
			"value": "QUILTBLOCK OF THE MO",
			"label": "QUILTBLOCK OF THE MO"
	},
	{
			"value": "QUILT BLOCKS",
			"label": "QUILT BLOCKS"
	},
	{
			"value": "QUILT BOOKLET",
			"label": "QUILT BOOKLET"
	},
	{
			"value": "QUILTED",
			"label": "QUILTED"
	},
	{
			"value": "QUILTERS CMAS",
			"label": "QUILTERS CMAS"
	},
	{
			"value": "QUILTER'S QUARTERS B",
			"label": "QUILTER'S QUARTERS B"
	},
	{
			"value": "QUILT IN A DAY",
			"label": "QUILT IN A DAY"
	},
	{
			"value": "QUILTING",
			"label": "QUILTING"
	},
	{
			"value": "QUILTING/APPLIQUE",
			"label": "QUILTING/APPLIQUE"
	},
	{
			"value": "QUILTING BOOKLET",
			"label": "QUILTING BOOKLET"
	},
	{
			"value": "QUILTING EDUCATION",
			"label": "QUILTING EDUCATION"
	},
	{
			"value": "QUILTING/EMBROIDERY",
			"label": "QUILTING/EMBROIDERY"
	},
	{
			"value": "QUILTING FABRICS",
			"label": "QUILTING FABRICS"
	},
	{
			"value": "QUILTING FOR BABY",
			"label": "QUILTING FOR BABY"
	},
	{
			"value": "QUILTING/PATCHWORK",
			"label": "QUILTING/PATCHWORK"
	},
	{
			"value": "QUILT INTRFC",
			"label": "QUILT INTRFC"
	},
	{
			"value": "QUILT KIT",
			"label": "QUILT KIT"
	},
	{
			"value": "QUILT MAGIC",
			"label": "QUILT MAGIC"
	},
	{
			"value": "QUILT NOTIONS BOOKS",
			"label": "QUILT NOTIONS BOOKS"
	},
	{
			"value": "QUILT PROJECTS",
			"label": "QUILT PROJECTS"
	},
	{
			"value": "QUILTSILLUSTRATED",
			"label": "QUILTSILLUSTRATED"
	},
	{
			"value": "QUILT STORY",
			"label": "QUILT STORY"
	},
	{
			"value": "QUILT TECHNIQUES",
			"label": "QUILT TECHNIQUES"
	},
	{
			"value": "QUINCE",
			"label": "QUINCE"
	},
	{
			"value": "QUINCEANERA",
			"label": "QUINCEANERA"
	},
	{
			"value": "QUINCE BLOSSOM",
			"label": "QUINCE BLOSSOM"
	},
	{
			"value": "QUOTE",
			"label": "QUOTE"
	},
	{
			"value": "RABBIT",
			"label": "RABBIT"
	},
	{
			"value": "RACCOON",
			"label": "RACCOON"
	},
	{
			"value": "RACHEL'S OF GREENFIE",
			"label": "RACHEL'S OF GREENFIE"
	},
	{
			"value": "RACK",
			"label": "RACK"
	},
	{
			"value": "RACOON",
			"label": "RACOON"
	},
	{
			"value": "RAFFIA",
			"label": "RAFFIA"
	},
	{
			"value": "RAGLAN",
			"label": "RAGLAN"
	},
	{
			"value": "RAINBOW",
			"label": "RAINBOW"
	},
	{
			"value": "RAINBOW GRASS",
			"label": "RAINBOW GRASS"
	},
	{
			"value": "RAINBOW LOOM",
			"label": "RAINBOW LOOM"
	},
	{
			"value": "RAINBOWS",
			"label": "RAINBOWS"
	},
	{
			"value": "RAINCATCHER",
			"label": "RAINCATCHER"
	},
	{
			"value": "RAINCOAT",
			"label": "RAINCOAT"
	},
	{
			"value": "RAINDROP ROSE",
			"label": "RAINDROP ROSE"
	},
	{
			"value": "RAINFOREST TRAY",
			"label": "RAINFOREST TRAY"
	},
	{
			"value": "RAIN GAUGE",
			"label": "RAIN GAUGE"
	},
	{
			"value": "RAINY DAY",
			"label": "RAINY DAY"
	},
	{
			"value": "RAMADAN",
			"label": "RAMADAN"
	},
	{
			"value": "RANDOM HOUSE",
			"label": "RANDOM HOUSE"
	},
	{
			"value": "RANGER",
			"label": "RANGER"
	},
	{
			"value": "RANNUCULOUS",
			"label": "RANNUCULOUS"
	},
	{
			"value": "RANNUCULUS",
			"label": "RANNUCULUS"
	},
	{
			"value": "RANNUNCULUS",
			"label": "RANNUNCULUS"
	},
	{
			"value": "RANUCULUS",
			"label": "RANUCULUS"
	},
	{
			"value": "RANUNCULAS",
			"label": "RANUNCULAS"
	},
	{
			"value": "RANUNCULUS",
			"label": "RANUNCULUS"
	},
	{
			"value": "RANUNCULUS PEAR SWAG",
			"label": "RANUNCULUS PEAR SWAG"
	},
	{
			"value": "RAT",
			"label": "RAT"
	},
	{
			"value": "RATTAIL",
			"label": "RATTAIL"
	},
	{
			"value": "RATTAN",
			"label": "RATTAN"
	},
	{
			"value": "RATTAN BALLS",
			"label": "RATTAN BALLS"
	},
	{
			"value": "RATTAN BASKET",
			"label": "RATTAN BASKET"
	},
	{
			"value": "RATTAN CONTAINER",
			"label": "RATTAN CONTAINER"
	},
	{
			"value": "RATTLESNAKE",
			"label": "RATTLESNAKE"
	},
	{
			"value": "RAYON",
			"label": "RAYON"
	},
	{
			"value": "RAYON COTTON",
			"label": "RAYON COTTON"
	},
	{
			"value": "RAYON SPANDEX",
			"label": "RAYON SPANDEX"
	},
	{
			"value": "RBG",
			"label": "RBG"
	},
	{
			"value": "RBLUXA00",
			"label": "RBLUXA00"
	},
	{
			"value": "READER",
			"label": "READER"
	},
	{
			"value": "READERS",
			"label": "READERS"
	},
	{
			"value": "READING",
			"label": "READING"
	},
	{
			"value": "READING GAME",
			"label": "READING GAME"
	},
	{
			"value": "REAL TOUCH",
			"label": "REAL TOUCH"
	},
	{
			"value": "REAL TREE",
			"label": "REAL TREE"
	},
	{
			"value": "REALTREE",
			"label": "REALTREE"
	},
	{
			"value": "REBUY",
			"label": "REBUY"
	},
	{
			"value": "RECIPE",
			"label": "RECIPE"
	},
	{
			"value": "RECIPE RIGHT",
			"label": "RECIPE RIGHT"
	},
	{
			"value": "RECTANGLE",
			"label": "RECTANGLE"
	},
	{
			"value": "RECTANGLE BIN",
			"label": "RECTANGLE BIN"
	},
	{
			"value": "RECTANGLE TIN",
			"label": "RECTANGLE TIN"
	},
	{
			"value": "RECTANGLE TRAY",
			"label": "RECTANGLE TRAY"
	},
	{
			"value": "RECTANGLE TRAY TIN",
			"label": "RECTANGLE TRAY TIN"
	},
	{
			"value": "RECYCLABLE",
			"label": "RECYCLABLE"
	},
	{
			"value": "RED",
			"label": "RED"
	},
	{
			"value": "RED AND GOLD",
			"label": "RED AND GOLD"
	},
	{
			"value": "RED AND GOLD EC",
			"label": "RED AND GOLD EC"
	},
	{
			"value": "RED AND GOLD TOWELS",
			"label": "RED AND GOLD TOWELS"
	},
	{
			"value": "RED BERRY",
			"label": "RED BERRY"
	},
	{
			"value": "RED/BLACK WRAP",
			"label": "RED/BLACK WRAP"
	},
	{
			"value": "RED RUG",
			"label": "RED RUG"
	},
	{
			"value": "RED SHOE",
			"label": "RED SHOE"
	},
	{
			"value": "RED TAG",
			"label": "RED TAG"
	},
	{
			"value": "RED TAG KNITS",
			"label": "RED TAG KNITS"
	},
	{
			"value": "RED TAG LACE",
			"label": "RED TAG LACE"
	},
	{
			"value": "RED TAG SHIRT",
			"label": "RED TAG SHIRT"
	},
	{
			"value": "RED TAG SILKIES",
			"label": "RED TAG SILKIES"
	},
	{
			"value": "RED TAG SO",
			"label": "RED TAG SO"
	},
	{
			"value": "RED TAG SUIT",
			"label": "RED TAG SUIT"
	},
	{
			"value": "RED TAG VALUE",
			"label": "RED TAG VALUE"
	},
	{
			"value": "RED TRUCK",
			"label": "RED TRUCK"
	},
	{
			"value": "REED",
			"label": "REED"
	},
	{
			"value": "REED DIFFUSER",
			"label": "REED DIFFUSER"
	},
	{
			"value": "REED DIFFUSERS",
			"label": "REED DIFFUSERS"
	},
	{
			"value": "REED POT",
			"label": "REED POT"
	},
	{
			"value": "REEL",
			"label": "REEL"
	},
	{
			"value": "REEVES",
			"label": "REEVES"
	},
	{
			"value": "REF!",
			"label": "REF!"
	},
	{
			"value": "REF 1270388",
			"label": "REF 1270388"
	},
	{
			"value": "REF 1378421",
			"label": "REF 1378421"
	},
	{
			"value": "REF 1378439",
			"label": "REF 1378439"
	},
	{
			"value": "REF 1378470",
			"label": "REF 1378470"
	},
	{
			"value": "REF 1378496",
			"label": "REF 1378496"
	},
	{
			"value": "REF 1378538",
			"label": "REF 1378538"
	},
	{
			"value": "REF 1799329",
			"label": "REF 1799329"
	},
	{
			"value": "REF 2163327",
			"label": "REF 2163327"
	},
	{
			"value": "REF 2163434",
			"label": "REF 2163434"
	},
	{
			"value": "REF 403774",
			"label": "REF 403774"
	},
	{
			"value": "REF 5805742",
			"label": "REF 5805742"
	},
	{
			"value": "REF 5806419",
			"label": "REF 5806419"
	},
	{
			"value": "REF 5806708",
			"label": "REF 5806708"
	},
	{
			"value": "REF 5806799",
			"label": "REF 5806799"
	},
	{
			"value": "REF 5806930",
			"label": "REF 5806930"
	},
	{
			"value": "REF 6658660",
			"label": "REF 6658660"
	},
	{
			"value": "REF 6659031",
			"label": "REF 6659031"
	},
	{
			"value": "REF 6659668",
			"label": "REF 6659668"
	},
	{
			"value": "REF 6659734",
			"label": "REF 6659734"
	},
	{
			"value": "REF 6662381",
			"label": "REF 6662381"
	},
	{
			"value": "REF 7248685",
			"label": "REF 7248685"
	},
	{
			"value": "REF 7911704",
			"label": "REF 7911704"
	},
	{
			"value": "REF 7911795",
			"label": "REF 7911795"
	},
	{
			"value": "REF 7911829",
			"label": "REF 7911829"
	},
	{
			"value": "REF 7911878",
			"label": "REF 7911878"
	},
	{
			"value": "REF 7912041",
			"label": "REF 7912041"
	},
	{
			"value": "REF 8035149",
			"label": "REF 8035149"
	},
	{
			"value": "REFLECTIVE",
			"label": "REFLECTIVE"
	},
	{
			"value": "REFLECTIVE MATERIAL",
			"label": "REFLECTIVE MATERIAL"
	},
	{
			"value": "REG CHOC BARS",
			"label": "REG CHOC BARS"
	},
	{
			"value": "REGULAR DENSITY FOAM",
			"label": "REGULAR DENSITY FOAM"
	},
	{
			"value": "REGULAR MOUTH",
			"label": "REGULAR MOUTH"
	},
	{
			"value": "REINDEER",
			"label": "REINDEER"
	},
	{
			"value": "REINDEER PILLOW",
			"label": "REINDEER PILLOW"
	},
	{
			"value": "REINDEER TABLE TOP D",
			"label": "REINDEER TABLE TOP D"
	},
	{
			"value": "RELAX PILLOW",
			"label": "RELAX PILLOW"
	},
	{
			"value": "RELAX WB",
			"label": "RELAX WB"
	},
	{
			"value": "RELAX WD",
			"label": "RELAX WD"
	},
	{
			"value": "RELIABLE CORPORATION",
			"label": "RELIABLE CORPORATION"
	},
	{
			"value": "RELIGIOUS",
			"label": "RELIGIOUS"
	},
	{
			"value": "RELIGIOUS CARDS",
			"label": "RELIGIOUS CARDS"
	},
	{
			"value": "RELIGIOUS OSO",
			"label": "RELIGIOUS OSO"
	},
	{
			"value": "RELNC",
			"label": "RELNC"
	},
	{
			"value": "RELNC01",
			"label": "RELNC01"
	},
	{
			"value": "RELNCC00",
			"label": "RELNCC00"
	},
	{
			"value": "RELNCC01",
			"label": "RELNCC01"
	},
	{
			"value": "RELNCE00",
			"label": "RELNCE00"
	},
	{
			"value": "REMEMBRANCE",
			"label": "REMEMBRANCE"
	},
	{
			"value": "REMINISCE",
			"label": "REMINISCE"
	},
	{
			"value": "REMOTE CONTROL",
			"label": "REMOTE CONTROL"
	},
	{
			"value": "REMV BULK ROLL",
			"label": "REMV BULK ROLL"
	},
	{
			"value": "REMV VINYL",
			"label": "REMV VINYL"
	},
	{
			"value": "REPAIR KIT",
			"label": "REPAIR KIT"
	},
	{
			"value": "REPOSITIONABLE",
			"label": "REPOSITIONABLE"
	},
	{
			"value": "REPTILE",
			"label": "REPTILE"
	},
	{
			"value": "REPUBLICAN",
			"label": "REPUBLICAN"
	},
	{
			"value": "RESCUE BOTS",
			"label": "RESCUE BOTS"
	},
	{
			"value": "RESEALABLE BAGS",
			"label": "RESEALABLE BAGS"
	},
	{
			"value": "RESIN",
			"label": "RESIN"
	},
	{
			"value": "RESIN ANGEL",
			"label": "RESIN ANGEL"
	},
	{
			"value": "RESIN BIRDFEEDER",
			"label": "RESIN BIRDFEEDER"
	},
	{
			"value": "RESIN BIRDHOUSE",
			"label": "RESIN BIRDHOUSE"
	},
	{
			"value": "RESIN ICING",
			"label": "RESIN ICING"
	},
	{
			"value": "RESIN NOVELTY BH",
			"label": "RESIN NOVELTY BH"
	},
	{
			"value": "RESIN TUBE",
			"label": "RESIN TUBE"
	},
	{
			"value": "RESORT HOME",
			"label": "RESORT HOME"
	},
	{
			"value": "RETREAT PILLOW",
			"label": "RETREAT PILLOW"
	},
	{
			"value": "RETRO TREE",
			"label": "RETRO TREE"
	},
	{
			"value": "REUSABLE BAG",
			"label": "REUSABLE BAG"
	},
	{
			"value": "REUSABLE CLOTH",
			"label": "REUSABLE CLOTH"
	},
	{
			"value": "REUSABLE STICKER PAD",
			"label": "REUSABLE STICKER PAD"
	},
	{
			"value": "REVERSIBLE",
			"label": "REVERSIBLE"
	},
	{
			"value": "REVERSIBLE SEQUIN",
			"label": "REVERSIBLE SEQUIN"
	},
	{
			"value": "REVERSIBLE WRAP",
			"label": "REVERSIBLE WRAP"
	},
	{
			"value": "REVIVAL",
			"label": "REVIVAL"
	},
	{
			"value": "REXLACE",
			"label": "REXLACE"
	},
	{
			"value": "RHINESTONE",
			"label": "RHINESTONE"
	},
	{
			"value": "RHINESTONE IRON ON",
			"label": "RHINESTONE IRON ON"
	},
	{
			"value": "RHINESTONES",
			"label": "RHINESTONES"
	},
	{
			"value": "RIBBED KNITS",
			"label": "RIBBED KNITS"
	},
	{
			"value": "RIBBED POT",
			"label": "RIBBED POT"
	},
	{
			"value": "RIBBON",
			"label": "RIBBON"
	},
	{
			"value": "RIBBON CART",
			"label": "RIBBON CART"
	},
	{
			"value": "RIBBON CHARMS",
			"label": "RIBBON CHARMS"
	},
	{
			"value": "RIBBON EMBROIDERY",
			"label": "RIBBON EMBROIDERY"
	},
	{
			"value": "RIBBON ENDS",
			"label": "RIBBON ENDS"
	},
	{
			"value": "RIBBON PACKS",
			"label": "RIBBON PACKS"
	},
	{
			"value": "RIBBON PARTY",
			"label": "RIBBON PARTY"
	},
	{
			"value": "RIB KNIT",
			"label": "RIB KNIT"
	},
	{
			"value": "RIB KNIT CUFF",
			"label": "RIB KNIT CUFF"
	},
	{
			"value": "RIB KNIT STRETCH",
			"label": "RIB KNIT STRETCH"
	},
	{
			"value": "RICE LIGHT",
			"label": "RICE LIGHT"
	},
	{
			"value": "RICE LIGHT JAR",
			"label": "RICE LIGHT JAR"
	},
	{
			"value": "RICE LIGHTS",
			"label": "RICE LIGHTS"
	},
	{
			"value": "RICE LIGHTS BASIC",
			"label": "RICE LIGHTS BASIC"
	},
	{
			"value": "RICE LIGHTS ICON",
			"label": "RICE LIGHTS ICON"
	},
	{
			"value": "RICE LIGHTS NOVELTY",
			"label": "RICE LIGHTS NOVELTY"
	},
	{
			"value": "RICK RAC",
			"label": "RICK RAC"
	},
	{
			"value": "RICK RACK",
			"label": "RICK RACK"
	},
	{
			"value": "RIDGED TAPER",
			"label": "RIDGED TAPER"
	},
	{
			"value": "RILEY & COMPANY",
			"label": "RILEY & COMPANY"
	},
	{
			"value": "RIM CONTAINER",
			"label": "RIM CONTAINER"
	},
	{
			"value": "RIME",
			"label": "RIME"
	},
	{
			"value": "RING",
			"label": "RING"
	},
	{
			"value": "RING BASE",
			"label": "RING BASE"
	},
	{
			"value": "RING DISPLAY",
			"label": "RING DISPLAY"
	},
	{
			"value": "RINGS",
			"label": "RINGS"
	},
	{
			"value": "RING SIZER",
			"label": "RING SIZER"
	},
	{
			"value": "RING WITH LOOPS",
			"label": "RING WITH LOOPS"
	},
	{
			"value": "RIP STOP",
			"label": "RIP STOP"
	},
	{
			"value": "RIP STOP VINYL",
			"label": "RIP STOP VINYL"
	},
	{
			"value": "RISER",
			"label": "RISER"
	},
	{
			"value": "RISING STAR",
			"label": "RISING STAR"
	},
	{
			"value": "RIT DYE",
			"label": "RIT DYE"
	},
	{
			"value": "RIT KIT",
			"label": "RIT KIT"
	},
	{
			"value": "RIT- LIQUID DYE",
			"label": "RIT- LIQUID DYE"
	},
	{
			"value": "RIT- POWDER DYE",
			"label": "RIT- POWDER DYE"
	},
	{
			"value": "RIVET",
			"label": "RIVET"
	},
	{
			"value": "RND CANISTER",
			"label": "RND CANISTER"
	},
	{
			"value": "RND CANISTER WINDOW",
			"label": "RND CANISTER WINDOW"
	},
	{
			"value": "RND COOKIE TIN",
			"label": "RND COOKIE TIN"
	},
	{
			"value": "RND COOKIE TIN WINDW",
			"label": "RND COOKIE TIN WINDW"
	},
	{
			"value": "RND TIN",
			"label": "RND TIN"
	},
	{
			"value": "RND TIN WINDOW",
			"label": "RND TIN WINDOW"
	},
	{
			"value": "ROADWAY",
			"label": "ROADWAY"
	},
	{
			"value": "ROAR  BABY JUNGLE",
			"label": "ROAR  BABY JUNGLE"
	},
	{
			"value": "ROARRR GRAY",
			"label": "ROARRR GRAY"
	},
	{
			"value": "ROBERT KAUFMAN",
			"label": "ROBERT KAUFMAN"
	},
	{
			"value": "ROBINS NEST",
			"label": "ROBINS NEST"
	},
	{
			"value": "ROBISON-ANTON",
			"label": "ROBISON-ANTON"
	},
	{
			"value": "ROBOT",
			"label": "ROBOT"
	},
	{
			"value": "ROBOTIC KITS",
			"label": "ROBOTIC KITS"
	},
	{
			"value": "ROCK",
			"label": "ROCK"
	},
	{
			"value": "ROCK BANDS",
			"label": "ROCK BANDS"
	},
	{
			"value": "ROCKETRY SUPPLIES",
			"label": "ROCKETRY SUPPLIES"
	},
	{
			"value": "ROCKLAND",
			"label": "ROCKLAND"
	},
	{
			"value": "ROCK PAINTING KIT",
			"label": "ROCK PAINTING KIT"
	},
	{
			"value": "ROCKS",
			"label": "ROCKS"
	},
	{
			"value": "ROD",
			"label": "ROD"
	},
	{
			"value": "RODEO",
			"label": "RODEO"
	},
	{
			"value": "RODEO COLLECTION",
			"label": "RODEO COLLECTION"
	},
	{
			"value": "RODS",
			"label": "RODS"
	},
	{
			"value": "ROLLERSKATE",
			"label": "ROLLERSKATE"
	},
	{
			"value": "ROLLIING PIN",
			"label": "ROLLIING PIN"
	},
	{
			"value": "ROLLING",
			"label": "ROLLING"
	},
	{
			"value": "ROLLING PIN",
			"label": "ROLLING PIN"
	},
	{
			"value": "ROLLING PLANTER",
			"label": "ROLLING PLANTER"
	},
	{
			"value": "ROLLING PLANT STAND",
			"label": "ROLLING PLANT STAND"
	},
	{
			"value": "ROLLING TOTE",
			"label": "ROLLING TOTE"
	},
	{
			"value": "ROMANCE",
			"label": "ROMANCE"
	},
	{
			"value": "ROMEO CUTTING MACH",
			"label": "ROMEO CUTTING MACH"
	},
	{
			"value": "ROMPER INFANT",
			"label": "ROMPER INFANT"
	},
	{
			"value": "ROOM SPRAY",
			"label": "ROOM SPRAY"
	},
	{
			"value": "ROOSTER",
			"label": "ROOSTER"
	},
	{
			"value": "ROOSTERS",
			"label": "ROOSTERS"
	},
	{
			"value": "ROPE",
			"label": "ROPE"
	},
	{
			"value": "ROPE CHAIN",
			"label": "ROPE CHAIN"
	},
	{
			"value": "ROPE CHAIN FRENCH",
			"label": "ROPE CHAIN FRENCH"
	},
	{
			"value": "ROPE TOY",
			"label": "ROPE TOY"
	},
	{
			"value": "ROPING",
			"label": "ROPING"
	},
	{
			"value": "ROSANNA PANSINO",
			"label": "ROSANNA PANSINO"
	},
	{
			"value": "ROSARY",
			"label": "ROSARY"
	},
	{
			"value": "ROSARY FINDINGS KIT",
			"label": "ROSARY FINDINGS KIT"
	},
	{
			"value": "ROSE",
			"label": "ROSE"
	},
	{
			"value": "ROSE BASKET",
			"label": "ROSE BASKET"
	},
	{
			"value": "ROSE BERRIES",
			"label": "ROSE BERRIES"
	},
	{
			"value": "ROSE EP BUNDLE",
			"label": "ROSE EP BUNDLE"
	},
	{
			"value": "ROSE GOLD",
			"label": "ROSE GOLD"
	},
	{
			"value": "ROSE GOLD PAPER",
			"label": "ROSE GOLD PAPER"
	},
	{
			"value": "ROSE HEART",
			"label": "ROSE HEART"
	},
	{
			"value": "ROSEHIP",
			"label": "ROSEHIP"
	},
	{
			"value": "ROSE HYDRA",
			"label": "ROSE HYDRA"
	},
	{
			"value": "ROSE HYDRANGEA BERRI",
			"label": "ROSE HYDRANGEA BERRI"
	},
	{
			"value": "ROSE HYDRA ZINNA",
			"label": "ROSE HYDRA ZINNA"
	},
	{
			"value": "ROSE-LVS",
			"label": "ROSE-LVS"
	},
	{
			"value": "ROSE MAKER BUNDLE",
			"label": "ROSE MAKER BUNDLE"
	},
	{
			"value": "ROSEMARY",
			"label": "ROSEMARY"
	},
	{
			"value": "ROSE RANUNCULUS DAIS",
			"label": "ROSE RANUNCULUS DAIS"
	},
	{
			"value": "ROSES",
			"label": "ROSES"
	},
	{
			"value": "ROSETTE",
			"label": "ROSETTE"
	},
	{
			"value": "ROTARY CUTTER",
			"label": "ROTARY CUTTER"
	},
	{
			"value": "ROUND",
			"label": "ROUND"
	},
	{
			"value": "ROUND BOWL",
			"label": "ROUND BOWL"
	},
	{
			"value": "ROUND BOX",
			"label": "ROUND BOX"
	},
	{
			"value": "ROUND CANISTER",
			"label": "ROUND CANISTER"
	},
	{
			"value": "ROUND COOKIE TIN",
			"label": "ROUND COOKIE TIN"
	},
	{
			"value": "ROUND GREEN",
			"label": "ROUND GREEN"
	},
	{
			"value": "ROUND LACE",
			"label": "ROUND LACE"
	},
	{
			"value": "ROUND LANTERN",
			"label": "ROUND LANTERN"
	},
	{
			"value": "ROUND RATTAN",
			"label": "ROUND RATTAN"
	},
	{
			"value": "ROUND TALL TIN",
			"label": "ROUND TALL TIN"
	},
	{
			"value": "ROUND TIN",
			"label": "ROUND TIN"
	},
	{
			"value": "ROUND TIN 3PK",
			"label": "ROUND TIN 3PK"
	},
	{
			"value": "ROUND TIN WINDOW",
			"label": "ROUND TIN WINDOW"
	},
	{
			"value": "ROUND TRAY",
			"label": "ROUND TRAY"
	},
	{
			"value": "ROUND WALL DECOR",
			"label": "ROUND WALL DECOR"
	},
	{
			"value": "ROUTE WB",
			"label": "ROUTE WB"
	},
	{
			"value": "ROYAL",
			"label": "ROYAL"
	},
	{
			"value": "ROYAL BRUSH",
			"label": "ROYAL BRUSH"
	},
	{
			"value": "ROYAL LACE",
			"label": "ROYAL LACE"
	},
	{
			"value": "ROYAL SILK FIBER",
			"label": "ROYAL SILK FIBER"
	},
	{
			"value": "RPS",
			"label": "RPS"
	},
	{
			"value": "R & S DESIGNS INC",
			"label": "R & S DESIGNS INC"
	},
	{
			"value": "RUBBER",
			"label": "RUBBER"
	},
	{
			"value": "RUBBER BAND",
			"label": "RUBBER BAND"
	},
	{
			"value": "RUBBER DOOR MAT",
			"label": "RUBBER DOOR MAT"
	},
	{
			"value": "RUBBER MAT",
			"label": "RUBBER MAT"
	},
	{
			"value": "RUBBER MAT FIGURAL",
			"label": "RUBBER MAT FIGURAL"
	},
	{
			"value": "RUBBER MATS",
			"label": "RUBBER MATS"
	},
	{
			"value": "RUBBER PLNT",
			"label": "RUBBER PLNT"
	},
	{
			"value": "RUBBER SHEETING",
			"label": "RUBBER SHEETING"
	},
	{
			"value": "RUBON",
			"label": "RUBON"
	},
	{
			"value": "RUCHED FUR",
			"label": "RUCHED FUR"
	},
	{
			"value": "RUDBECKIA",
			"label": "RUDBECKIA"
	},
	{
			"value": "RUDOLPH",
			"label": "RUDOLPH"
	},
	{
			"value": "RUFFLE",
			"label": "RUFFLE"
	},
	{
			"value": "RUFFLE KNIT",
			"label": "RUFFLE KNIT"
	},
	{
			"value": "RUFFLE LACE",
			"label": "RUFFLE LACE"
	},
	{
			"value": "RUG",
			"label": "RUG"
	},
	{
			"value": "RUG 2X3",
			"label": "RUG 2X3"
	},
	{
			"value": "RUG MAKING",
			"label": "RUG MAKING"
	},
	{
			"value": "RUG WOVEN",
			"label": "RUG WOVEN"
	},
	{
			"value": "RULDOPLH",
			"label": "RULDOPLH"
	},
	{
			"value": "RULER",
			"label": "RULER"
	},
	{
			"value": "RUND TIN WINDOW",
			"label": "RUND TIN WINDOW"
	},
	{
			"value": "RUNNE",
			"label": "RUNNE"
	},
	{
			"value": "RUNNER",
			"label": "RUNNER"
	},
	{
			"value": "RURAL",
			"label": "RURAL"
	},
	{
			"value": "RUSCUS",
			"label": "RUSCUS"
	},
	{
			"value": "RUSTIC",
			"label": "RUSTIC"
	},
	{
			"value": "RUSTIC CRACKLE GLAZE",
			"label": "RUSTIC CRACKLE GLAZE"
	},
	{
			"value": "RUSTIC HALLOWEEN RIB",
			"label": "RUSTIC HALLOWEEN RIB"
	},
	{
			"value": "RUSTIC LED PILLAR",
			"label": "RUSTIC LED PILLAR"
	},
	{
			"value": "RUSTIC LODGE",
			"label": "RUSTIC LODGE"
	},
	{
			"value": "RUSTIC NEUTRALS RIBB",
			"label": "RUSTIC NEUTRALS RIBB"
	},
	{
			"value": "RUSTIC RETREAT",
			"label": "RUSTIC RETREAT"
	},
	{
			"value": "RUSTIC ROOSTER",
			"label": "RUSTIC ROOSTER"
	},
	{
			"value": "RUST-OLEUM",
			"label": "RUST-OLEUM"
	},
	{
			"value": "RUSTOLEUM",
			"label": "RUSTOLEUM"
	},
	{
			"value": "RUSTY PICKLE",
			"label": "RUSTY PICKLE"
	},
	{
			"value": "RWB GARLAND",
			"label": "RWB GARLAND"
	},
	{
			"value": "RWB TUTU",
			"label": "RWB TUTU"
	},
	{
			"value": "RYLAND PETERS & SMAL",
			"label": "RYLAND PETERS & SMAL"
	},
	{
			"value": "SA645602",
			"label": "SA645602"
	},
	{
			"value": "SA645605",
			"label": "SA645605"
	},
	{
			"value": "SA655606",
			"label": "SA655606"
	},
	{
			"value": "SA660602",
			"label": "SA660602"
	},
	{
			"value": "SA800601",
			"label": "SA800601"
	},
	{
			"value": "SACHET",
			"label": "SACHET"
	},
	{
			"value": "SACHET BAG",
			"label": "SACHET BAG"
	},
	{
			"value": "SACHETS",
			"label": "SACHETS"
	},
	{
			"value": "SADDLE",
			"label": "SADDLE"
	},
	{
			"value": "SAFARI",
			"label": "SAFARI"
	},
	{
			"value": "SAFARI ANIMALS",
			"label": "SAFARI ANIMALS"
	},
	{
			"value": "SAFARI OPEN STOCK",
			"label": "SAFARI OPEN STOCK"
	},
	{
			"value": "SAFARI TOOBS",
			"label": "SAFARI TOOBS"
	},
	{
			"value": "SAFETY PIN",
			"label": "SAFETY PIN"
	},
	{
			"value": "SAFETY PINBACK",
			"label": "SAFETY PINBACK"
	},
	{
			"value": "SAFETY PINS",
			"label": "SAFETY PINS"
	},
	{
			"value": "SAKAKI",
			"label": "SAKAKI"
	},
	{
			"value": "SAKURA",
			"label": "SAKURA"
	},
	{
			"value": "SAKURA GIRLS",
			"label": "SAKURA GIRLS"
	},
	{
			"value": "SALAD BOWL",
			"label": "SALAD BOWL"
	},
	{
			"value": "SALAD PLATE",
			"label": "SALAD PLATE"
	},
	{
			"value": "SALAL LEAF",
			"label": "SALAL LEAF"
	},
	{
			"value": "SALT AND PEPPER",
			"label": "SALT AND PEPPER"
	},
	{
			"value": "SALT N PEPPER",
			"label": "SALT N PEPPER"
	},
	{
			"value": "SALT & PEPPER",
			"label": "SALT & PEPPER"
	},
	{
			"value": "SALT PEPPER",
			"label": "SALT PEPPER"
	},
	{
			"value": "SALTY",
			"label": "SALTY"
	},
	{
			"value": "SALUTATIONS",
			"label": "SALUTATIONS"
	},
	{
			"value": "SAMPLER HTV",
			"label": "SAMPLER HTV"
	},
	{
			"value": "SAND",
			"label": "SAND"
	},
	{
			"value": "SAND ACC",
			"label": "SAND ACC"
	},
	{
			"value": "SANFORD",
			"label": "SANFORD"
	},
	{
			"value": "SANTA",
			"label": "SANTA"
	},
	{
			"value": "SANTA BAG",
			"label": "SANTA BAG"
	},
	{
			"value": "SANTA BASE",
			"label": "SANTA BASE"
	},
	{
			"value": "SANTA CONE",
			"label": "SANTA CONE"
	},
	{
			"value": "SANTA HAT",
			"label": "SANTA HAT"
	},
	{
			"value": "SANTA HATS",
			"label": "SANTA HATS"
	},
	{
			"value": "SANTA KING",
			"label": "SANTA KING"
	},
	{
			"value": "SANTA SACK",
			"label": "SANTA SACK"
	},
	{
			"value": "SANTA SACKS",
			"label": "SANTA SACKS"
	},
	{
			"value": "SANTA SNOWMAN",
			"label": "SANTA SNOWMAN"
	},
	{
			"value": "SANTA SUIT",
			"label": "SANTA SUIT"
	},
	{
			"value": "SANTA WRAP",
			"label": "SANTA WRAP"
	},
	{
			"value": "SARASA",
			"label": "SARASA"
	},
	{
			"value": "SASH",
			"label": "SASH"
	},
	{
			"value": "SASSAFRAS LASS",
			"label": "SASSAFRAS LASS"
	},
	{
			"value": "SATEEN",
			"label": "SATEEN"
	},
	{
			"value": "SATEEN SOLID",
			"label": "SATEEN SOLID"
	},
	{
			"value": "SATIN",
			"label": "SATIN"
	},
	{
			"value": "SATIN SILK",
			"label": "SATIN SILK"
	},
	{
			"value": "SATURN",
			"label": "SATURN"
	},
	{
			"value": "SAUCE",
			"label": "SAUCE"
	},
	{
			"value": "SAUCER",
			"label": "SAUCER"
	},
	{
			"value": "SAVY SAVOR",
			"label": "SAVY SAVOR"
	},
	{
			"value": "SAY CHEESE",
			"label": "SAY CHEESE"
	},
	{
			"value": "SAYINGS",
			"label": "SAYINGS"
	},
	{
			"value": "SAY IT LOUD",
			"label": "SAY IT LOUD"
	},
	{
			"value": "SAY WHAT",
			"label": "SAY WHAT"
	},
	{
			"value": "SBD",
			"label": "SBD"
	},
	{
			"value": "SCABIOSA",
			"label": "SCABIOSA"
	},
	{
			"value": "SCALES",
			"label": "SCALES"
	},
	{
			"value": "SCALLOP",
			"label": "SCALLOP"
	},
	{
			"value": "SCALLOP BASKET",
			"label": "SCALLOP BASKET"
	},
	{
			"value": "SCALLOPED",
			"label": "SCALLOPED"
	},
	{
			"value": "SCALLOP FRAME",
			"label": "SCALLOP FRAME"
	},
	{
			"value": "SCALLOP FRINGE",
			"label": "SCALLOP FRINGE"
	},
	{
			"value": "SCANDIMAS",
			"label": "SCANDIMAS"
	},
	{
			"value": "SCAN N CUT",
			"label": "SCAN N CUT"
	},
	{
			"value": "SCAN N CUT ACCESSORY",
			"label": "SCAN N CUT ACCESSORY"
	},
	{
			"value": "SCARECORW",
			"label": "SCARECORW"
	},
	{
			"value": "SCARECROW",
			"label": "SCARECROW"
	},
	{
			"value": "SCARF",
			"label": "SCARF"
	},
	{
			"value": "SCARF PP",
			"label": "SCARF PP"
	},
	{
			"value": "SCARF TOWER",
			"label": "SCARF TOWER"
	},
	{
			"value": "SCARY COSTUME",
			"label": "SCARY COSTUME"
	},
	{
			"value": "SCATTERS",
			"label": "SCATTERS"
	},
	{
			"value": "SC BOTTOMWEIGHTS",
			"label": "SC BOTTOMWEIGHTS"
	},
	{
			"value": "SC COTTON",
			"label": "SC COTTON"
	},
	{
			"value": "SCENE",
			"label": "SCENE"
	},
	{
			"value": "SCENES",
			"label": "SCENES"
	},
	{
			"value": "SCENIC",
			"label": "SCENIC"
	},
	{
			"value": "SCENT BLOCK",
			"label": "SCENT BLOCK"
	},
	{
			"value": "SCENTED",
			"label": "SCENTED"
	},
	{
			"value": "SCENTED PILLAR",
			"label": "SCENTED PILLAR"
	},
	{
			"value": "SCENTED PINECONE",
			"label": "SCENTED PINECONE"
	},
	{
			"value": "SCENTED PINECONES",
			"label": "SCENTED PINECONES"
	},
	{
			"value": "SCENTED STICKERS",
			"label": "SCENTED STICKERS"
	},
	{
			"value": "SCENTICLES",
			"label": "SCENTICLES"
	},
	{
			"value": "SCENTSCILES",
			"label": "SCENTSCILES"
	},
	{
			"value": "SCENTSICLE",
			"label": "SCENTSICLE"
	},
	{
			"value": "SCENTSICLES",
			"label": "SCENTSICLES"
	},
	{
			"value": "SCHITTS CREEK",
			"label": "SCHITTS CREEK"
	},
	{
			"value": "SCHOOL",
			"label": "SCHOOL"
	},
	{
			"value": "SCHOOL IS FUN",
			"label": "SCHOOL IS FUN"
	},
	{
			"value": "SCHOOL SUPPLIES",
			"label": "SCHOOL SUPPLIES"
	},
	{
			"value": "SCHOOL YARD",
			"label": "SCHOOL YARD"
	},
	{
			"value": "SCIENCE",
			"label": "SCIENCE"
	},
	{
			"value": "SCIENCE KIT",
			"label": "SCIENCE KIT"
	},
	{
			"value": "SCISSORS",
			"label": "SCISSORS"
	},
	{
			"value": "SC KNITS",
			"label": "SC KNITS"
	},
	{
			"value": "SC LINEN",
			"label": "SC LINEN"
	},
	{
			"value": "SCONCE",
			"label": "SCONCE"
	},
	{
			"value": "SCOOTER",
			"label": "SCOOTER"
	},
	{
			"value": "SCOR-IT",
			"label": "SCOR-IT"
	},
	{
			"value": "SCOTTIE",
			"label": "SCOTTIE"
	},
	{
			"value": "SCP",
			"label": "SCP"
	},
	{
			"value": "SCRAPBOOK 101",
			"label": "SCRAPBOOK 101"
	},
	{
			"value": "SCRAPBOOK GENERATION",
			"label": "SCRAPBOOK GENERATION"
	},
	{
			"value": "SCRAPBOOKING POG STO",
			"label": "SCRAPBOOKING POG STO"
	},
	{
			"value": "SCRAPBOOKING STORAGE",
			"label": "SCRAPBOOKING STORAGE"
	},
	{
			"value": "SCRAPERFECT",
			"label": "SCRAPERFECT"
	},
	{
			"value": "SCRAPPIN SPORTS",
			"label": "SCRAPPIN SPORTS"
	},
	{
			"value": "SCRAPPIN' SPORTS",
			"label": "SCRAPPIN' SPORTS"
	},
	{
			"value": "SCRAP QUILTING",
			"label": "SCRAP QUILTING"
	},
	{
			"value": "SCRAP QUILTS",
			"label": "SCRAP QUILTS"
	},
	{
			"value": "SCRATCH ART",
			"label": "SCRATCH ART"
	},
	{
			"value": "SCRATCH-ART",
			"label": "SCRATCH-ART"
	},
	{
			"value": "SCRATCH KIT",
			"label": "SCRATCH KIT"
	},
	{
			"value": "SCREEN",
			"label": "SCREEN"
	},
	{
			"value": "SCREEN PROTECTORS",
			"label": "SCREEN PROTECTORS"
	},
	{
			"value": "SCREW",
			"label": "SCREW"
	},
	{
			"value": "SCRIBB-FABRIC PNT PK",
			"label": "SCRIBB-FABRIC PNT PK"
	},
	{
			"value": "SCRIBB- FBRIC PNT PK",
			"label": "SCRIBB- FBRIC PNT PK"
	},
	{
			"value": "SCRIBBLE SCRUBBY",
			"label": "SCRIBBLE SCRUBBY"
	},
	{
			"value": "SCRIBBLES- STAMP SET",
			"label": "SCRIBBLES- STAMP SET"
	},
	{
			"value": "SCRIBB-PN FBC MLT PK",
			"label": "SCRIBB-PN FBC MLT PK"
	},
	{
			"value": "SCRIBB-SNGLE FBRC PT",
			"label": "SCRIBB-SNGLE FBRC PT"
	},
	{
			"value": "SCRIB- PNTBRUSH KIT",
			"label": "SCRIB- PNTBRUSH KIT"
	},
	{
			"value": "SCRIPT",
			"label": "SCRIPT"
	},
	{
			"value": "SCRIPT PALLET WALL D",
			"label": "SCRIPT PALLET WALL D"
	},
	{
			"value": "SCRIPT WALL DECOR",
			"label": "SCRIPT WALL DECOR"
	},
	{
			"value": "SCRIPT WORD DECOR",
			"label": "SCRIPT WORD DECOR"
	},
	{
			"value": "SCROLL",
			"label": "SCROLL"
	},
	{
			"value": "SCROLLS",
			"label": "SCROLLS"
	},
	{
			"value": "SCROLL WORK",
			"label": "SCROLL WORK"
	},
	{
			"value": "SCROLLWORK",
			"label": "SCROLLWORK"
	},
	{
			"value": "SCRUBBIE MESH",
			"label": "SCRUBBIE MESH"
	},
	{
			"value": "SCRUBBY",
			"label": "SCRUBBY"
	},
	{
			"value": "SCRUNCHIE",
			"label": "SCRUNCHIE"
	},
	{
			"value": "SCRUNCHIES",
			"label": "SCRUNCHIES"
	},
	{
			"value": "SC SHIRTING",
			"label": "SC SHIRTING"
	},
	{
			"value": "SC SPECIALTY COTTON",
			"label": "SC SPECIALTY COTTON"
	},
	{
			"value": "SC SUEDE",
			"label": "SC SUEDE"
	},
	{
			"value": "SCUBA",
			"label": "SCUBA"
	},
	{
			"value": "SCULPTED CANDLE",
			"label": "SCULPTED CANDLE"
	},
	{
			"value": "SCULPTO",
			"label": "SCULPTO"
	},
	{
			"value": "SCULPTURAL CANDLES",
			"label": "SCULPTURAL CANDLES"
	},
	{
			"value": "SEA ANIMALS",
			"label": "SEA ANIMALS"
	},
	{
			"value": "SEA CREATURES",
			"label": "SEA CREATURES"
	},
	{
			"value": "SEA GLASS",
			"label": "SEA GLASS"
	},
	{
			"value": "SEAGRASS STEM",
			"label": "SEAGRASS STEM"
	},
	{
			"value": "SEALED WITH A STAR",
			"label": "SEALED WITH A STAR"
	},
	{
			"value": "SEA LIFE",
			"label": "SEA LIFE"
	},
	{
			"value": "SEAM RIPPER",
			"label": "SEAM RIPPER"
	},
	{
			"value": "SEANCE",
			"label": "SEANCE"
	},
	{
			"value": "SÉANCE - ICONS",
			"label": "SÉANCE - ICONS"
	},
	{
			"value": "SÉANCE - MOONS",
			"label": "SÉANCE - MOONS"
	},
	{
			"value": "SÉANCE - TAROT CARDS",
			"label": "SÉANCE - TAROT CARDS"
	},
	{
			"value": "SEARCH PRESS",
			"label": "SEARCH PRESS"
	},
	{
			"value": "SEA SEAHAWKS",
			"label": "SEA SEAHAWKS"
	},
	{
			"value": "SEA SHELL",
			"label": "SEA SHELL"
	},
	{
			"value": "SEASHELL",
			"label": "SEASHELL"
	},
	{
			"value": "SEASIDE",
			"label": "SEASIDE"
	},
	{
			"value": "SEASNA00",
			"label": "SEASNA00"
	},
	{
			"value": "SEASONAL",
			"label": "SEASONAL"
	},
	{
			"value": "SEASONAL BAKING CUP",
			"label": "SEASONAL BAKING CUP"
	},
	{
			"value": "SEASONAL BAKING CUPS",
			"label": "SEASONAL BAKING CUPS"
	},
	{
			"value": "SEASONAL CANDY",
			"label": "SEASONAL CANDY"
	},
	{
			"value": "SEASONAL CANDY MELTS",
			"label": "SEASONAL CANDY MELTS"
	},
	{
			"value": "SEASONAL/CELEBRATION",
			"label": "SEASONAL/CELEBRATION"
	},
	{
			"value": "SEASONAL COOKIE KIT",
			"label": "SEASONAL COOKIE KIT"
	},
	{
			"value": "SEASONAL CUP",
			"label": "SEASONAL CUP"
	},
	{
			"value": "SEASONAL DECORATION",
			"label": "SEASONAL DECORATION"
	},
	{
			"value": "SEASONAL MOLD",
			"label": "SEASONAL MOLD"
	},
	{
			"value": "SEASONAL MOLDS",
			"label": "SEASONAL MOLDS"
	},
	{
			"value": "SEASONAL PAN",
			"label": "SEASONAL PAN"
	},
	{
			"value": "SEASONAL SPRINKLES",
			"label": "SEASONAL SPRINKLES"
	},
	{
			"value": "SEASONAL UNASSEMBLED",
			"label": "SEASONAL UNASSEMBLED"
	},
	{
			"value": "SEASONS GREETING OSO",
			"label": "SEASONS GREETING OSO"
	},
	{
			"value": "SEASONS GREETINGS",
			"label": "SEASONS GREETINGS"
	},
	{
			"value": "SEA SPARKLE",
			"label": "SEA SPARKLE"
	},
	{
			"value": "SEAT CUSHION",
			"label": "SEAT CUSHION"
	},
	{
			"value": "SEATING",
			"label": "SEATING"
	},
	{
			"value": "SEA TOWEL",
			"label": "SEA TOWEL"
	},
	{
			"value": "SEATTLE SEAHAWKS",
			"label": "SEATTLE SEAHAWKS"
	},
	{
			"value": "SEA WD",
			"label": "SEA WD"
	},
	{
			"value": "SECONDARY THEME PILL",
			"label": "SECONDARY THEME PILL"
	},
	{
			"value": "SECONDARY THEME THRO",
			"label": "SECONDARY THEME THRO"
	},
	{
			"value": "SECOND GRADE EDUCATI",
			"label": "SECOND GRADE EDUCATI"
	},
	{
			"value": "SEDUM",
			"label": "SEDUM"
	},
	{
			"value": "SEED BEAD KIT",
			"label": "SEED BEAD KIT"
	},
	{
			"value": "SEED BEADS",
			"label": "SEED BEADS"
	},
	{
			"value": "SEED BRY",
			"label": "SEED BRY"
	},
	{
			"value": "SEED ORGANIZER",
			"label": "SEED ORGANIZER"
	},
	{
			"value": "SEED PACKET",
			"label": "SEED PACKET"
	},
	{
			"value": "SEED PROTECTOR",
			"label": "SEED PROTECTOR"
	},
	{
			"value": "SEED STARTER",
			"label": "SEED STARTER"
	},
	{
			"value": "SEED STONES",
			"label": "SEED STONES"
	},
	{
			"value": "SEERSUCKER",
			"label": "SEERSUCKER"
	},
	{
			"value": "SEE THRU",
			"label": "SEE THRU"
	},
	{
			"value": "SEI",
			"label": "SEI"
	},
	{
			"value": "SEI KIT",
			"label": "SEI KIT"
	},
	{
			"value": "SEINFELD",
			"label": "SEINFELD"
	},
	{
			"value": "SELF HEALING",
			"label": "SELF HEALING"
	},
	{
			"value": "SELF HEALING MAT",
			"label": "SELF HEALING MAT"
	},
	{
			"value": "SEMI",
			"label": "SEMI"
	},
	{
			"value": "SEMI-PRECIOUS",
			"label": "SEMI-PRECIOUS"
	},
	{
			"value": "SENIOR",
			"label": "SENIOR"
	},
	{
			"value": "SENIOR BAG",
			"label": "SENIOR BAG"
	},
	{
			"value": "SENIOR GIFT BAG",
			"label": "SENIOR GIFT BAG"
	},
	{
			"value": "SENPPA00",
			"label": "SENPPA00"
	},
	{
			"value": "SENRB",
			"label": "SENRB"
	},
	{
			"value": "SENRBA00",
			"label": "SENRBA00"
	},
	{
			"value": "SENSORY BIN",
			"label": "SENSORY BIN"
	},
	{
			"value": "SENTIMENT CANDLE",
			"label": "SENTIMENT CANDLE"
	},
	{
			"value": "SENTIMENT PUMPKIN",
			"label": "SENTIMENT PUMPKIN"
	},
	{
			"value": "SEP",
			"label": "SEP"
	},
	{
			"value": "SEPARATOR PLATE",
			"label": "SEPARATOR PLATE"
	},
	{
			"value": "SEPARATOR PLATES",
			"label": "SEPARATOR PLATES"
	},
	{
			"value": "SEPTEMBER RTV",
			"label": "SEPTEMBER RTV"
	},
	{
			"value": "SEPTFY14 RTV",
			"label": "SEPTFY14 RTV"
	},
	{
			"value": "SEPTFY15RTV",
			"label": "SEPTFY15RTV"
	},
	{
			"value": "SEQUENCING GAME",
			"label": "SEQUENCING GAME"
	},
	{
			"value": "SEQUIN",
			"label": "SEQUIN"
	},
	{
			"value": "SEQUIN ACC",
			"label": "SEQUIN ACC"
	},
	{
			"value": "SEQUIN BOOT",
			"label": "SEQUIN BOOT"
	},
	{
			"value": "SEQUIN BOW",
			"label": "SEQUIN BOW"
	},
	{
			"value": "SEQUIN CUP",
			"label": "SEQUIN CUP"
	},
	{
			"value": "SEQUIN MESH",
			"label": "SEQUIN MESH"
	},
	{
			"value": "SEQUIN NOVELTY",
			"label": "SEQUIN NOVELTY"
	},
	{
			"value": "SEQUIN PACK",
			"label": "SEQUIN PACK"
	},
	{
			"value": "SEQUINS",
			"label": "SEQUINS"
	},
	{
			"value": "SEQUIN SHEET",
			"label": "SEQUIN SHEET"
	},
	{
			"value": "SEQUIN TIE",
			"label": "SEQUIN TIE"
	},
	{
			"value": "SEQUIN TULLE",
			"label": "SEQUIN TULLE"
	},
	{
			"value": "SERENDIPITY PUZZLE C",
			"label": "SERENDIPITY PUZZLE C"
	},
	{
			"value": "SERGER",
			"label": "SERGER"
	},
	{
			"value": "SERGER/COVER STITCH",
			"label": "SERGER/COVER STITCH"
	},
	{
			"value": "SERIF INC",
			"label": "SERIF INC"
	},
	{
			"value": "SERVE BOWL",
			"label": "SERVE BOWL"
	},
	{
			"value": "SERVER",
			"label": "SERVER"
	},
	{
			"value": "SERVER FORK",
			"label": "SERVER FORK"
	},
	{
			"value": "SERVEWARE",
			"label": "SERVEWARE"
	},
	{
			"value": "SERVING",
			"label": "SERVING"
	},
	{
			"value": "SERVING BOWL",
			"label": "SERVING BOWL"
	},
	{
			"value": "SERVING CHIP & DIP",
			"label": "SERVING CHIP & DIP"
	},
	{
			"value": "SERVING DISPLAY",
			"label": "SERVING DISPLAY"
	},
	{
			"value": "SERVING TRAY",
			"label": "SERVING TRAY"
	},
	{
			"value": "SET",
			"label": "SET"
	},
	{
			"value": "SET 5 SET W CANDLE",
			"label": "SET 5 SET W CANDLE"
	},
	{
			"value": "SET BLU",
			"label": "SET BLU"
	},
	{
			"value": "SET GRY",
			"label": "SET GRY"
	},
	{
			"value": "SET IVRY",
			"label": "SET IVRY"
	},
	{
			"value": "SETS",
			"label": "SETS"
	},
	{
			"value": "SET WHT",
			"label": "SET WHT"
	},
	{
			"value": "SEW AND STUFF",
			"label": "SEW AND STUFF"
	},
	{
			"value": "SEW CLASSIC CORDUROY",
			"label": "SEW CLASSIC CORDUROY"
	},
	{
			"value": "SEW CLASSIC KNITS",
			"label": "SEW CLASSIC KNITS"
	},
	{
			"value": "SEW CLASSIC LINEN",
			"label": "SEW CLASSIC LINEN"
	},
	{
			"value": "SEW CLASSICS KNITS",
			"label": "SEW CLASSICS KNITS"
	},
	{
			"value": "SEW CLASSIC SLKY SLD",
			"label": "SEW CLASSIC SLKY SLD"
	},
	{
			"value": "SEW CLASSIC SOLID",
			"label": "SEW CLASSIC SOLID"
	},
	{
			"value": "SEW CLASSICS SILKY S",
			"label": "SEW CLASSICS SILKY S"
	},
	{
			"value": "SEW CLASSIC SUEDE",
			"label": "SEW CLASSIC SUEDE"
	},
	{
			"value": "SEW CLASSIC SUITINGS",
			"label": "SEW CLASSIC SUITINGS"
	},
	{
			"value": "SEWING",
			"label": "SEWING"
	},
	{
			"value": "SEWING ACCESSORIES",
			"label": "SEWING ACCESSORIES"
	},
	{
			"value": "SEWING-APPAREL",
			"label": "SEWING-APPAREL"
	},
	{
			"value": "SEWING BASKET",
			"label": "SEWING BASKET"
	},
	{
			"value": "SEWING BOOKLET",
			"label": "SEWING BOOKLET"
	},
	{
			"value": "SEWING & CRAFTING",
			"label": "SEWING & CRAFTING"
	},
	{
			"value": "SEWING CRAFTING",
			"label": "SEWING CRAFTING"
	},
	{
			"value": "SEWING/CRAFTING",
			"label": "SEWING/CRAFTING"
	},
	{
			"value": "SEWING EDUCATION",
			"label": "SEWING EDUCATION"
	},
	{
			"value": "SEWING-FABRICS",
			"label": "SEWING-FABRICS"
	},
	{
			"value": "SEWING KIT",
			"label": "SEWING KIT"
	},
	{
			"value": "SEWING MACH BOOKS",
			"label": "SEWING MACH BOOKS"
	},
	{
			"value": "SEWING MACHINE",
			"label": "SEWING MACHINE"
	},
	{
			"value": "SEWING MACHINE QLTS",
			"label": "SEWING MACHINE QLTS"
	},
	{
			"value": "SEWING MACHINE REFRN",
			"label": "SEWING MACHINE REFRN"
	},
	{
			"value": "SEWING MAT",
			"label": "SEWING MAT"
	},
	{
			"value": " SEWING, QUILTING, E",
			"label": " SEWING, QUILTING, E"
	},
	{
			"value": "SEWING TECHNIQUES",
			"label": "SEWING TECHNIQUES"
	},
	{
			"value": "SEWING TL",
			"label": "SEWING TL"
	},
	{
			"value": "SEWING TOOLS",
			"label": "SEWING TOOLS"
	},
	{
			"value": "SEW PRINT",
			"label": "SEW PRINT"
	},
	{
			"value": "SEW SWEET",
			"label": "SEW SWEET"
	},
	{
			"value": "SEW SWEET THEME TBA",
			"label": "SEW SWEET THEME TBA"
	},
	{
			"value": "SEW VELCRO",
			"label": "SEW VELCRO"
	},
	{
			"value": "SF 49ERS",
			"label": "SF 49ERS"
	},
	{
			"value": "SFY15 ATHLETIC OPP B",
			"label": "SFY15 ATHLETIC OPP B"
	},
	{
			"value": "SFY15 DANCE OPP BUY",
			"label": "SFY15 DANCE OPP BUY"
	},
	{
			"value": "SFY15 SWIM PRINT OPP",
			"label": "SFY15 SWIM PRINT OPP"
	},
	{
			"value": "SFY15 SWIM SOLID OPP",
			"label": "SFY15 SWIM SOLID OPP"
	},
	{
			"value": "SG500 PRINTER",
			"label": "SG500 PRINTER"
	},
	{
			"value": "SHABBY GREEN DOOR",
			"label": "SHABBY GREEN DOOR"
	},
	{
			"value": "SHADER",
			"label": "SHADER"
	},
	{
			"value": "SHADES OF GREEN",
			"label": "SHADES OF GREEN"
	},
	{
			"value": "SHADOW BOX",
			"label": "SHADOW BOX"
	},
	{
			"value": "SHADOWBOX",
			"label": "SHADOWBOX"
	},
	{
			"value": "SHADOWBOX POG FW28",
			"label": "SHADOWBOX POG FW28"
	},
	{
			"value": "SHADOW PLAY",
			"label": "SHADOW PLAY"
	},
	{
			"value": "SHADOW STRIPE",
			"label": "SHADOW STRIPE"
	},
	{
			"value": "SHAGGY",
			"label": "SHAGGY"
	},
	{
			"value": "SHAG MICROFIBER",
			"label": "SHAG MICROFIBER"
	},
	{
			"value": "SHAKER",
			"label": "SHAKER"
	},
	{
			"value": "SHAKERBLOCK",
			"label": "SHAKERBLOCK"
	},
	{
			"value": "SHAKER BOX",
			"label": "SHAKER BOX"
	},
	{
			"value": "SHAKER WORD BLOCK",
			"label": "SHAKER WORD BLOCK"
	},
	{
			"value": "SHAKEY",
			"label": "SHAKEY"
	},
	{
			"value": "SHAKEY WINE",
			"label": "SHAKEY WINE"
	},
	{
			"value": "SHAKING CREATURE",
			"label": "SHAKING CREATURE"
	},
	{
			"value": "SHAKING CREATURES",
			"label": "SHAKING CREATURES"
	},
	{
			"value": "SHALLON",
			"label": "SHALLON"
	},
	{
			"value": "SHAM 26X20",
			"label": "SHAM 26X20"
	},
	{
			"value": "SHAMROCK",
			"label": "SHAMROCK"
	},
	{
			"value": "SHAPE",
			"label": "SHAPE"
	},
	{
			"value": "SHAPE CANDLE",
			"label": "SHAPE CANDLE"
	},
	{
			"value": "SHAPED BAKEWARE",
			"label": "SHAPED BAKEWARE"
	},
	{
			"value": "SHAPED BIRDHOUSE",
			"label": "SHAPED BIRDHOUSE"
	},
	{
			"value": "SHAPED CANDLE",
			"label": "SHAPED CANDLE"
	},
	{
			"value": "SHAPE FOAM STICKER",
			"label": "SHAPE FOAM STICKER"
	},
	{
			"value": "SHAPE MOLD",
			"label": "SHAPE MOLD"
	},
	{
			"value": "SHAPE MOLDS",
			"label": "SHAPE MOLDS"
	},
	{
			"value": "SHAPE N AMAZE",
			"label": "SHAPE N AMAZE"
	},
	{
			"value": "SHAPE PAN",
			"label": "SHAPE PAN"
	},
	{
			"value": "SHAPES",
			"label": "SHAPES"
	},
	{
			"value": "SHAPES MIXES",
			"label": "SHAPES MIXES"
	},
	{
			"value": "SHAPE WALL DECOR",
			"label": "SHAPE WALL DECOR"
	},
	{
			"value": "SHARK",
			"label": "SHARK"
	},
	{
			"value": "SHARON",
			"label": "SHARON"
	},
	{
			"value": "SHATTERED GLASS",
			"label": "SHATTERED GLASS"
	},
	{
			"value": "SHATTERPROOF",
			"label": "SHATTERPROOF"
	},
	{
			"value": "SHEEP",
			"label": "SHEEP"
	},
	{
			"value": "SHEER",
			"label": "SHEER"
	},
	{
			"value": "SHEER GRY",
			"label": "SHEER GRY"
	},
	{
			"value": "SHEER NAT",
			"label": "SHEER NAT"
	},
	{
			"value": "SHEERS",
			"label": "SHEERS"
	},
	{
			"value": "SHEERS / LACES",
			"label": "SHEERS / LACES"
	},
	{
			"value": "SHEER TN",
			"label": "SHEER TN"
	},
	{
			"value": "SHEETS",
			"label": "SHEETS"
	},
	{
			"value": "SHEETS PUNCH",
			"label": "SHEETS PUNCH"
	},
	{
			"value": "SHELF",
			"label": "SHELF"
	},
	{
			"value": "SHELF SITTER",
			"label": "SHELF SITTER"
	},
	{
			"value": "SHELL",
			"label": "SHELL"
	},
	{
			"value": "SHELL CONTAINER",
			"label": "SHELL CONTAINER"
	},
	{
			"value": "SHELL FRAME",
			"label": "SHELL FRAME"
	},
	{
			"value": "SHELLS",
			"label": "SHELLS"
	},
	{
			"value": "SHELL WD",
			"label": "SHELL WD"
	},
	{
			"value": "SHEPHERD HOOK",
			"label": "SHEPHERD HOOK"
	},
	{
			"value": "SHEPHERD HOOKS",
			"label": "SHEPHERD HOOKS"
	},
	{
			"value": "SHERPA",
			"label": "SHERPA"
	},
	{
			"value": "SHERPA BACKEDFLANNEL",
			"label": "SHERPA BACKEDFLANNEL"
	},
	{
			"value": "SHERPA BOOT",
			"label": "SHERPA BOOT"
	},
	{
			"value": "SHERPA PRINT",
			"label": "SHERPA PRINT"
	},
	{
			"value": "SHERPA SOCKS",
			"label": "SHERPA SOCKS"
	},
	{
			"value": "SHERPA SOLID",
			"label": "SHERPA SOLID"
	},
	{
			"value": "SHIBORI",
			"label": "SHIBORI"
	},
	{
			"value": "SHIBORI/TIEDYE/BATIK",
			"label": "SHIBORI/TIEDYE/BATIK"
	},
	{
			"value": "SHIMMERING PRODUCTS",
			"label": "SHIMMERING PRODUCTS"
	},
	{
			"value": "SHIMMER PERM VINYL",
			"label": "SHIMMER PERM VINYL"
	},
	{
			"value": "SHINE",
			"label": "SHINE"
	},
	{
			"value": "SHINY",
			"label": "SHINY"
	},
	{
			"value": "SHINY TULLE",
			"label": "SHINY TULLE"
	},
	{
			"value": "SHIPPERS",
			"label": "SHIPPERS"
	},
	{
			"value": "SHIRNK ART",
			"label": "SHIRNK ART"
	},
	{
			"value": "SHIRT",
			"label": "SHIRT"
	},
	{
			"value": "SHIRTING",
			"label": "SHIRTING"
	},
	{
			"value": "SHIRTINGS",
			"label": "SHIRTINGS"
	},
	{
			"value": "SHIVERS & SLITHERS",
			"label": "SHIVERS & SLITHERS"
	},
	{
			"value": "SHOE",
			"label": "SHOE"
	},
	{
			"value": "SHOE APPLIQUES",
			"label": "SHOE APPLIQUES"
	},
	{
			"value": "SHOE CHAIN",
			"label": "SHOE CHAIN"
	},
	{
			"value": "SHOE CHARMS",
			"label": "SHOE CHARMS"
	},
	{
			"value": "SHOE CLIPS",
			"label": "SHOE CLIPS"
	},
	{
			"value": "SHOE LACE",
			"label": "SHOE LACE"
	},
	{
			"value": "SHOE LACES",
			"label": "SHOE LACES"
	},
	{
			"value": "SHOE LACE TAG",
			"label": "SHOE LACE TAG"
	},
	{
			"value": "SHOES",
			"label": "SHOES"
	},
	{
			"value": "S-HOOK",
			"label": "S-HOOK"
	},
	{
			"value": "SHOPKINS",
			"label": "SHOPKINS"
	},
	{
			"value": "SHOPPING CART COVER",
			"label": "SHOPPING CART COVER"
	},
	{
			"value": "SHORT METAL PLANTER",
			"label": "SHORT METAL PLANTER"
	},
	{
			"value": "SHORT STEM",
			"label": "SHORT STEM"
	},
	{
			"value": "SHOTBOX ACCESSORIES",
			"label": "SHOTBOX ACCESSORIES"
	},
	{
			"value": "SHOT TOP",
			"label": "SHOT TOP"
	},
	{
			"value": "SHOVEL",
			"label": "SHOVEL"
	},
	{
			"value": "SHOWER CURTAIN",
			"label": "SHOWER CURTAIN"
	},
	{
			"value": "SHPF GARLAND",
			"label": "SHPF GARLAND"
	},
	{
			"value": "SHREDDED FOAM",
			"label": "SHREDDED FOAM"
	},
	{
			"value": "SHRINKY DINK",
			"label": "SHRINKY DINK"
	},
	{
			"value": "SH SPRING 1",
			"label": "SH SPRING 1"
	},
	{
			"value": "SH SPRING 2",
			"label": "SH SPRING 2"
	},
	{
			"value": "SHURTECH",
			"label": "SHURTECH"
	},
	{
			"value": "SIDE 1",
			"label": "SIDE 1"
	},
	{
			"value": "SIDE 3",
			"label": "SIDE 3"
	},
	{
			"value": "SIDE TABLE",
			"label": "SIDE TABLE"
	},
	{
			"value": "SIDING HOOKS",
			"label": "SIDING HOOKS"
	},
	{
			"value": "SIGHT WORDS",
			"label": "SIGHT WORDS"
	},
	{
			"value": "SIGN",
			"label": "SIGN"
	},
	{
			"value": "SILICONE",
			"label": "SILICONE"
	},
	{
			"value": "SILICONE CUBE LETTER",
			"label": "SILICONE CUBE LETTER"
	},
	{
			"value": "SILK",
			"label": "SILK"
	},
	{
			"value": "SILKIES",
			"label": "SILKIES"
	},
	{
			"value": "SILKIES OPP BUY",
			"label": "SILKIES OPP BUY"
	},
	{
			"value": "SILK PRINT",
			"label": "SILK PRINT"
	},
	{
			"value": "SILK / SILK LOOKS",
			"label": "SILK / SILK LOOKS"
	},
	{
			"value": "SILK VELVET",
			"label": "SILK VELVET"
	},
	{
			"value": "SILKY",
			"label": "SILKY"
	},
	{
			"value": "SILKY CHARMEUSE",
			"label": "SILKY CHARMEUSE"
	},
	{
			"value": "SILKY CHIFFON",
			"label": "SILKY CHIFFON"
	},
	{
			"value": "SILKY CORD",
			"label": "SILKY CORD"
	},
	{
			"value": "SILKY PEACHSKIN",
			"label": "SILKY PEACHSKIN"
	},
	{
			"value": "SILKY PRINTS",
			"label": "SILKY PRINTS"
	},
	{
			"value": "SILKY SOLID",
			"label": "SILKY SOLID"
	},
	{
			"value": "SILKY SOLIDS",
			"label": "SILKY SOLIDS"
	},
	{
			"value": "SILLY PUTTY",
			"label": "SILLY PUTTY"
	},
	{
			"value": "SILVER",
			"label": "SILVER"
	},
	{
			"value": "SILVER BELL",
			"label": "SILVER BELL"
	},
	{
			"value": "SILVER CREEK",
			"label": "SILVER CREEK"
	},
	{
			"value": "SILVER DOLLAR",
			"label": "SILVER DOLLAR"
	},
	{
			"value": "SILVER DOLLAR WHEAT",
			"label": "SILVER DOLLAR WHEAT"
	},
	{
			"value": "SILVERDOLLER",
			"label": "SILVERDOLLER"
	},
	{
			"value": "SILVERTEX",
			"label": "SILVERTEX"
	},
	{
			"value": "SIMILAX",
			"label": "SIMILAX"
	},
	{
			"value": "SIMPATICO",
			"label": "SIMPATICO"
	},
	{
			"value": "SIMPLE CHARM",
			"label": "SIMPLE CHARM"
	},
	{
			"value": "SIMPLE LIFE",
			"label": "SIMPLE LIFE"
	},
	{
			"value": "SIMPLE LUXURIES",
			"label": "SIMPLE LUXURIES"
	},
	{
			"value": "SIMPLE LUXURIES COLL",
			"label": "SIMPLE LUXURIES COLL"
	},
	{
			"value": "SIMPLE LUXURY SFY14",
			"label": "SIMPLE LUXURY SFY14"
	},
	{
			"value": "SIMPLE THINGS",
			"label": "SIMPLE THINGS"
	},
	{
			"value": "SIMPLICITY PATTERNS",
			"label": "SIMPLICITY PATTERNS"
	},
	{
			"value": "SIMPLY ART",
			"label": "SIMPLY ART"
	},
	{
			"value": "SIMPLY SILKY PRINTS",
			"label": "SIMPLY SILKY PRINTS"
	},
	{
			"value": "SIMPTA00",
			"label": "SIMPTA00"
	},
	{
			"value": "SINGER",
			"label": "SINGER"
	},
	{
			"value": "SINGLE FLORAL",
			"label": "SINGLE FLORAL"
	},
	{
			"value": "SINGLE FOLD",
			"label": "SINGLE FOLD"
	},
	{
			"value": "SINGLE HOOK",
			"label": "SINGLE HOOK"
	},
	{
			"value": "SINGLE PLANT STAND",
			"label": "SINGLE PLANT STAND"
	},
	{
			"value": "SINGLE SHEET",
			"label": "SINGLE SHEET"
	},
	{
			"value": "SINGLE TOTE",
			"label": "SINGLE TOTE"
	},
	{
			"value": "SINO JACKIA LEAF",
			"label": "SINO JACKIA LEAF"
	},
	{
			"value": "SINO LEAF",
			"label": "SINO LEAF"
	},
	{
			"value": "SISAL",
			"label": "SISAL"
	},
	{
			"value": "SISAL BOTTLE",
			"label": "SISAL BOTTLE"
	},
	{
			"value": "SISAL BUNNY",
			"label": "SISAL BUNNY"
	},
	{
			"value": "SISAL TREE",
			"label": "SISAL TREE"
	},
	{
			"value": "SISAL TREE BOXED SET",
			"label": "SISAL TREE BOXED SET"
	},
	{
			"value": "SISAL TREES",
			"label": "SISAL TREES"
	},
	{
			"value": "SISER APP TAPE",
			"label": "SISER APP TAPE"
	},
	{
			"value": "SISER HEAT PRESS",
			"label": "SISER HEAT PRESS"
	},
	{
			"value": "SISER MEGA ROLL",
			"label": "SISER MEGA ROLL"
	},
	{
			"value": "SISER NOTIONS",
			"label": "SISER NOTIONS"
	},
	{
			"value": "SISOR",
			"label": "SISOR"
	},
	{
			"value": "SISOR02",
			"label": "SISOR02"
	},
	{
			"value": "SISOR03",
			"label": "SISOR03"
	},
	{
			"value": "SISORB03",
			"label": "SISORB03"
	},
	{
			"value": "SITTER",
			"label": "SITTER"
	},
	{
			"value": "SITTING BIRD",
			"label": "SITTING BIRD"
	},
	{
			"value": "SITTING DECOR",
			"label": "SITTING DECOR"
	},
	{
			"value": "SITTING SANTA",
			"label": "SITTING SANTA"
	},
	{
			"value": "SITTING SNOWMAN",
			"label": "SITTING SNOWMAN"
	},
	{
			"value": "SIZZIX",
			"label": "SIZZIX"
	},
	{
			"value": "SKATEBOARDS",
			"label": "SKATEBOARDS"
	},
	{
			"value": "SKATING",
			"label": "SKATING"
	},
	{
			"value": "SKELETON",
			"label": "SKELETON"
	},
	{
			"value": "SKELETON ORN",
			"label": "SKELETON ORN"
	},
	{
			"value": "SKELETONS",
			"label": "SKELETONS"
	},
	{
			"value": "SKELTON",
			"label": "SKELTON"
	},
	{
			"value": "SKETCH ART KIT",
			"label": "SKETCH ART KIT"
	},
	{
			"value": "SKETCH BOOK",
			"label": "SKETCH BOOK"
	},
	{
			"value": "SKETCHBOOK",
			"label": "SKETCHBOOK"
	},
	{
			"value": "SKETCH PAPER",
			"label": "SKETCH PAPER"
	},
	{
			"value": "SKIES",
			"label": "SKIES"
	},
	{
			"value": "SKIMMIA",
			"label": "SKIMMIA"
	},
	{
			"value": "SKIN",
			"label": "SKIN"
	},
	{
			"value": "SKINNY TUMBLER",
			"label": "SKINNY TUMBLER"
	},
	{
			"value": "SKINS",
			"label": "SKINS"
	},
	{
			"value": "SKIN TONES",
			"label": "SKIN TONES"
	},
	{
			"value": "SKIRT / PANTS",
			"label": "SKIRT / PANTS"
	},
	{
			"value": "SKITS",
			"label": "SKITS"
	},
	{
			"value": "SKITSA00",
			"label": "SKITSA00"
	},
	{
			"value": "SKITTLES",
			"label": "SKITTLES"
	},
	{
			"value": "SK MERCHANDISING",
			"label": "SK MERCHANDISING"
	},
	{
			"value": "SKNITS",
			"label": "SKNITS"
	},
	{
			"value": "SKULL",
			"label": "SKULL"
	},
	{
			"value": "SKULLDUGGERY",
			"label": "SKULLDUGGERY"
	},
	{
			"value": "SKULLS",
			"label": "SKULLS"
	},
	{
			"value": "SKULL TABLE DECOR",
			"label": "SKULL TABLE DECOR"
	},
	{
			"value": "SLAT CONTAINER",
			"label": "SLAT CONTAINER"
	},
	{
			"value": "SLATE",
			"label": "SLATE"
	},
	{
			"value": "SLATE TAG",
			"label": "SLATE TAG"
	},
	{
			"value": "SLATE WALL DECOR",
			"label": "SLATE WALL DECOR"
	},
	{
			"value": "SLATE WALL HANGING",
			"label": "SLATE WALL HANGING"
	},
	{
			"value": "SLED",
			"label": "SLED"
	},
	{
			"value": "SLED BASKET",
			"label": "SLED BASKET"
	},
	{
			"value": "SLEEPING BEAR PRESS",
			"label": "SLEEPING BEAR PRESS"
	},
	{
			"value": "SLEEPWEAR",
			"label": "SLEEPWEAR"
	},
	{
			"value": "SLEIGH",
			"label": "SLEIGH"
	},
	{
			"value": "SLIDER",
			"label": "SLIDER"
	},
	{
			"value": "SLIDER CLASP",
			"label": "SLIDER CLASP"
	},
	{
			"value": "SLIME",
			"label": "SLIME"
	},
	{
			"value": "SLIME BOOKS",
			"label": "SLIME BOOKS"
	},
	{
			"value": "SLIME EC",
			"label": "SLIME EC"
	},
	{
			"value": "SLIME EC/GLUE POG",
			"label": "SLIME EC/GLUE POG"
	},
	{
			"value": "SLIME EC/STYRO POG",
			"label": "SLIME EC/STYRO POG"
	},
	{
			"value": "SLIMLINE",
			"label": "SLIMLINE"
	},
	{
			"value": "SLIMLINE - V",
			"label": "SLIMLINE - V"
	},
	{
			"value": "SLIMLINE - Z",
			"label": "SLIMLINE - Z"
	},
	{
			"value": "SLIM TREE",
			"label": "SLIM TREE"
	},
	{
			"value": "SLING CHAIR",
			"label": "SLING CHAIR"
	},
	{
			"value": "SLINKY",
			"label": "SLINKY"
	},
	{
			"value": "SLIP ON",
			"label": "SLIP ON"
	},
	{
			"value": "SLIPPERS",
			"label": "SLIPPERS"
	},
	{
			"value": "SLIPPER SOCKS",
			"label": "SLIPPER SOCKS"
	},
	{
			"value": "SLIVER CLASP",
			"label": "SLIVER CLASP"
	},
	{
			"value": "SLOTH",
			"label": "SLOTH"
	},
	{
			"value": "SLUB",
			"label": "SLUB"
	},
	{
			"value": "SLUB KNIT",
			"label": "SLUB KNIT"
	},
	{
			"value": "SLUB STRIPE",
			"label": "SLUB STRIPE"
	},
	{
			"value": "SLUMBER PARTY",
			"label": "SLUMBER PARTY"
	},
	{
			"value": "SLV 12 SET",
			"label": "SLV 12 SET"
	},
	{
			"value": "SM ACCESSORY",
			"label": "SM ACCESSORY"
	},
	{
			"value": "SMAILL PUMPKIN",
			"label": "SMAILL PUMPKIN"
	},
	{
			"value": "SMALL",
			"label": "SMALL"
	},
	{
			"value": "SMALL ARROW",
			"label": "SMALL ARROW"
	},
	{
			"value": "SMALL BAGS",
			"label": "SMALL BAGS"
	},
	{
			"value": "SMALL CANDLES",
			"label": "SMALL CANDLES"
	},
	{
			"value": "SMALL CRITTER",
			"label": "SMALL CRITTER"
	},
	{
			"value": "SMALL CUT OUT",
			"label": "SMALL CUT OUT"
	},
	{
			"value": "SMALL DECOR",
			"label": "SMALL DECOR"
	},
	{
			"value": "SMALL DIFFUSER",
			"label": "SMALL DIFFUSER"
	},
	{
			"value": "SMALL JAR CANDLE",
			"label": "SMALL JAR CANDLE"
	},
	{
			"value": "SMALL PINWHEEL",
			"label": "SMALL PINWHEEL"
	},
	{
			"value": "SMALL PLAID",
			"label": "SMALL PLAID"
	},
	{
			"value": "SMALL PUMPKIN",
			"label": "SMALL PUMPKIN"
	},
	{
			"value": "SMALL STAKE",
			"label": "SMALL STAKE"
	},
	{
			"value": "SMALL TOTE",
			"label": "SMALL TOTE"
	},
	{
			"value": "SMALL WALL DECOR",
			"label": "SMALL WALL DECOR"
	},
	{
			"value": "SMART GLTR IRON ON",
			"label": "SMART GLTR IRON ON"
	},
	{
			"value": "SMART HOLO IRON ON",
			"label": "SMART HOLO IRON ON"
	},
	{
			"value": "SMART IRON ON",
			"label": "SMART IRON ON"
	},
	{
			"value": "SMARTLAB",
			"label": "SMARTLAB"
	},
	{
			"value": "SMART PERM VINYL",
			"label": "SMART PERM VINYL"
	},
	{
			"value": "SMART REMV VINYL",
			"label": "SMART REMV VINYL"
	},
	{
			"value": "SMART STENCIL",
			"label": "SMART STENCIL"
	},
	{
			"value": "SMART STICKER PAPER",
			"label": "SMART STICKER PAPER"
	},
	{
			"value": "SMART WRITABLE",
			"label": "SMART WRITABLE"
	},
	{
			"value": "SM BELT",
			"label": "SM BELT"
	},
	{
			"value": "SM BULB",
			"label": "SM BULB"
	},
	{
			"value": "SM CONTAINER",
			"label": "SM CONTAINER"
	},
	{
			"value": "SM FLORAL",
			"label": "SM FLORAL"
	},
	{
			"value": "SM FLOWER",
			"label": "SM FLOWER"
	},
	{
			"value": "SM FOOT",
			"label": "SM FOOT"
	},
	{
			"value": "SMILEY",
			"label": "SMILEY"
	},
	{
			"value": "SMITHSONIAN STEM KIT",
			"label": "SMITHSONIAN STEM KIT"
	},
	{
			"value": "SML HANDMADE",
			"label": "SML HANDMADE"
	},
	{
			"value": "SML LED TREE",
			"label": "SML LED TREE"
	},
	{
			"value": "SM / MED FLORAL",
			"label": "SM / MED FLORAL"
	},
	{
			"value": "SM NEEDLE",
			"label": "SM NEEDLE"
	},
	{
			"value": "SMOCKING",
			"label": "SMOCKING"
	},
	{
			"value": "SMOKE",
			"label": "SMOKE"
	},
	{
			"value": "SMOKE BUSH",
			"label": "SMOKE BUSH"
	},
	{
			"value": "SMOKE STEM",
			"label": "SMOKE STEM"
	},
	{
			"value": "SMOOTHFOAM",
			"label": "SMOOTHFOAM"
	},
	{
			"value": "SMOOTH LED PILLAR",
			"label": "SMOOTH LED PILLAR"
	},
	{
			"value": "SMORES",
			"label": "SMORES"
	},
	{
			"value": "SM REPAIR",
			"label": "SM REPAIR"
	},
	{
			"value": "SNAIL",
			"label": "SNAIL"
	},
	{
			"value": "SNAILS",
			"label": "SNAILS"
	},
	{
			"value": "SNAKE",
			"label": "SNAKE"
	},
	{
			"value": "SNAKE CHAIN",
			"label": "SNAKE CHAIN"
	},
	{
			"value": "SNAKE PLANT",
			"label": "SNAKE PLANT"
	},
	{
			"value": "SNAKE SKIN",
			"label": "SNAKE SKIN"
	},
	{
			"value": "SNAP",
			"label": "SNAP"
	},
	{
			"value": "SNAP BAR",
			"label": "SNAP BAR"
	},
	{
			"value": "SNAPCLIPS",
			"label": "SNAPCLIPS"
	},
	{
			"value": "SNAPDRAGON",
			"label": "SNAPDRAGON"
	},
	{
			"value": "SNAPS",
			"label": "SNAPS"
	},
	{
			"value": "SNAPWARE",
			"label": "SNAPWARE"
	},
	{
			"value": "SNL",
			"label": "SNL"
	},
	{
			"value": "SNOOPY",
			"label": "SNOOPY"
	},
	{
			"value": "SNOW",
			"label": "SNOW"
	},
	{
			"value": "SNOWBALL",
			"label": "SNOWBALL"
	},
	{
			"value": "SNOWFLAKE",
			"label": "SNOWFLAKE"
	},
	{
			"value": "SNOWFLAKE BELL",
			"label": "SNOWFLAKE BELL"
	},
	{
			"value": "SNOWFLAKE CANDLE",
			"label": "SNOWFLAKE CANDLE"
	},
	{
			"value": "SNOWFLAKE PILLOW",
			"label": "SNOWFLAKE PILLOW"
	},
	{
			"value": "SNOWFLAKE PINECONE",
			"label": "SNOWFLAKE PINECONE"
	},
	{
			"value": "SNOWFLAKES",
			"label": "SNOWFLAKES"
	},
	{
			"value": "SNOWMAN",
			"label": "SNOWMAN"
	},
	{
			"value": "SNOWMAN CONES",
			"label": "SNOWMAN CONES"
	},
	{
			"value": "SNOWMAN SCENE",
			"label": "SNOWMAN SCENE"
	},
	{
			"value": "SNOWMAN SHAPED BOX",
			"label": "SNOWMAN SHAPED BOX"
	},
	{
			"value": "SNOWMAN TABLETOP DEC",
			"label": "SNOWMAN TABLETOP DEC"
	},
	{
			"value": "SNOWMEN",
			"label": "SNOWMEN"
	},
	{
			"value": "SNOWY HYDRANGEA",
			"label": "SNOWY HYDRANGEA"
	},
	{
			"value": "SNOWY MESH",
			"label": "SNOWY MESH"
	},
	{
			"value": "SNOWY ROSE",
			"label": "SNOWY ROSE"
	},
	{
			"value": "SNS WAVERLY",
			"label": "SNS WAVERLY"
	},
	{
			"value": "SNUGGLE FLANNEL",
			"label": "SNUGGLE FLANNEL"
	},
	{
			"value": "SNUGGLE FLANNLE",
			"label": "SNUGGLE FLANNLE"
	},
	{
			"value": "SOAP",
			"label": "SOAP"
	},
	{
			"value": "SOAP ACCESSORIES",
			"label": "SOAP ACCESSORIES"
	},
	{
			"value": "SOAP BASE",
			"label": "SOAP BASE"
	},
	{
			"value": "SOAP COLORANT",
			"label": "SOAP COLORANT"
	},
	{
			"value": "SOAP CONTAINER",
			"label": "SOAP CONTAINER"
	},
	{
			"value": "SOAP FRAGRANCE",
			"label": "SOAP FRAGRANCE"
	},
	{
			"value": "SOAP HERBS",
			"label": "SOAP HERBS"
	},
	{
			"value": "SOAP MAKING",
			"label": "SOAP MAKING"
	},
	{
			"value": "SOAP MAKING KIT",
			"label": "SOAP MAKING KIT"
	},
	{
			"value": "SOAP MOLD",
			"label": "SOAP MOLD"
	},
	{
			"value": "SOAP PUMP",
			"label": "SOAP PUMP"
	},
	{
			"value": "SOAP TRAY",
			"label": "SOAP TRAY"
	},
	{
			"value": "SOCCER",
			"label": "SOCCER"
	},
	{
			"value": "SOCCER BALLS",
			"label": "SOCCER BALLS"
	},
	{
			"value": "SOCK",
			"label": "SOCK"
	},
	{
			"value": "SOCK MONKEY",
			"label": "SOCK MONKEY"
	},
	{
			"value": "SOCKS",
			"label": "SOCKS"
	},
	{
			"value": "SOCK SET",
			"label": "SOCK SET"
	},
	{
			"value": "SO CUTE",
			"label": "SO CUTE"
	},
	{
			"value": "SOFT",
			"label": "SOFT"
	},
	{
			"value": "SOFT AND BRIGHT",
			"label": "SOFT AND BRIGHT"
	},
	{
			"value": "SOFT AND COMFY",
			"label": "SOFT AND COMFY"
	},
	{
			"value": "SOFTBALLS",
			"label": "SOFTBALLS"
	},
	{
			"value": "SOFT BIN",
			"label": "SOFT BIN"
	},
	{
			"value": "SOFT CANDY",
			"label": "SOFT CANDY"
	},
	{
			"value": "SOFT & COMFY",
			"label": "SOFT & COMFY"
	},
	{
			"value": "SOFT& COMFY STRIPE",
			"label": "SOFT& COMFY STRIPE"
	},
	{
			"value": "SOFT FLOWERS",
			"label": "SOFT FLOWERS"
	},
	{
			"value": "SOFT & FLUFFY",
			"label": "SOFT & FLUFFY"
	},
	{
			"value": "SOFT&FLUFFY",
			"label": "SOFT&FLUFFY"
	},
	{
			"value": "SOFT FURNISHINGS/UPH",
			"label": "SOFT FURNISHINGS/UPH"
	},
	{
			"value": "SOFT N CRAFTY",
			"label": "SOFT N CRAFTY"
	},
	{
			"value": "SOFT N EASY HEM TAPE",
			"label": "SOFT N EASY HEM TAPE"
	},
	{
			"value": "SOFT PAINT",
			"label": "SOFT PAINT"
	},
	{
			"value": "SOFT ROSES",
			"label": "SOFT ROSES"
	},
	{
			"value": "SOFT SPOKEN",
			"label": "SOFT SPOKEN"
	},
	{
			"value": "SOFT SUEDE LACE",
			"label": "SOFT SUEDE LACE"
	},
	{
			"value": "SOFT SUPPORT FOAM",
			"label": "SOFT SUPPORT FOAM"
	},
	{
			"value": "SOILID",
			"label": "SOILID"
	},
	{
			"value": "SOLA",
			"label": "SOLA"
	},
	{
			"value": "SOLA FLOWERS",
			"label": "SOLA FLOWERS"
	},
	{
			"value": "SOLAR",
			"label": "SOLAR"
	},
	{
			"value": "SOLAR HANGING LANTER",
			"label": "SOLAR HANGING LANTER"
	},
	{
			"value": "SOLARIUM PILLOW",
			"label": "SOLARIUM PILLOW"
	},
	{
			"value": "SOLAR LANTERN",
			"label": "SOLAR LANTERN"
	},
	{
			"value": "SOLAR LIGHT",
			"label": "SOLAR LIGHT"
	},
	{
			"value": "SOLAR LIGHTS",
			"label": "SOLAR LIGHTS"
	},
	{
			"value": "SOLAR STAKE",
			"label": "SOLAR STAKE"
	},
	{
			"value": "SOLAR STAKE LIGHTS",
			"label": "SOLAR STAKE LIGHTS"
	},
	{
			"value": "SOLAR YARD STAKE",
			"label": "SOLAR YARD STAKE"
	},
	{
			"value": "SOLID",
			"label": "SOLID"
	},
	{
			"value": "SOLID 7OZ",
			"label": "SOLID 7OZ"
	},
	{
			"value": "SOLID BLENDER",
			"label": "SOLID BLENDER"
	},
	{
			"value": "SOLID COLOR",
			"label": "SOLID COLOR"
	},
	{
			"value": "SOLID FOIL",
			"label": "SOLID FOIL"
	},
	{
			"value": "SOLID KNIT OPP BUY",
			"label": "SOLID KNIT OPP BUY"
	},
	{
			"value": "SOLID KRAFT WRAP",
			"label": "SOLID KRAFT WRAP"
	},
	{
			"value": "SOLID LINEN",
			"label": "SOLID LINEN"
	},
	{
			"value": "SOLID MED PURPLE",
			"label": "SOLID MED PURPLE"
	},
	{
			"value": "SOLID NAVY",
			"label": "SOLID NAVY"
	},
	{
			"value": "SOLIDS",
			"label": "SOLIDS"
	},
	{
			"value": "SOLID SATEEN",
			"label": "SOLID SATEEN"
	},
	{
			"value": "SOLID SHATTERPROOF",
			"label": "SOLID SHATTERPROOF"
	},
	{
			"value": "SOLID SHINE",
			"label": "SOLID SHINE"
	},
	{
			"value": "SOLID TISSUE",
			"label": "SOLID TISSUE"
	},
	{
			"value": "SOLID TONALS",
			"label": "SOLID TONALS"
	},
	{
			"value": "SOLID TWILL",
			"label": "SOLID TWILL"
	},
	{
			"value": "SOLID WHT",
			"label": "SOLID WHT"
	},
	{
			"value": "SOLSTICE",
			"label": "SOLSTICE"
	},
	{
			"value": "SOMERSET DESIGNS",
			"label": "SOMERSET DESIGNS"
	},
	{
			"value": "SOUP MUG",
			"label": "SOUP MUG"
	},
	{
			"value": "SOUTHWEST",
			"label": "SOUTHWEST"
	},
	{
			"value": "SOUTHWEST FALL FY15",
			"label": "SOUTHWEST FALL FY15"
	},
	{
			"value": "SOUTHWEST NICHE",
			"label": "SOUTHWEST NICHE"
	},
	{
			"value": "SP 19",
			"label": "SP 19"
	},
	{
			"value": "SPACE",
			"label": "SPACE"
	},
	{
			"value": "SPACED PLAID",
			"label": "SPACED PLAID"
	},
	{
			"value": "SPACE & GALAXY",
			"label": "SPACE & GALAXY"
	},
	{
			"value": "SPACE ICONS",
			"label": "SPACE ICONS"
	},
	{
			"value": "SPACER",
			"label": "SPACER"
	},
	{
			"value": "SPACER BARS",
			"label": "SPACER BARS"
	},
	{
			"value": "SPACER BEADS",
			"label": "SPACER BEADS"
	},
	{
			"value": "SPACESHIP",
			"label": "SPACESHIP"
	},
	{
			"value": "SPA JAR",
			"label": "SPA JAR"
	},
	{
			"value": "SPANDEX KNITS",
			"label": "SPANDEX KNITS"
	},
	{
			"value": "SPARKLE",
			"label": "SPARKLE"
	},
	{
			"value": "SPARKLE BOOT",
			"label": "SPARKLE BOOT"
	},
	{
			"value": "SPARKLE MESH",
			"label": "SPARKLE MESH"
	},
	{
			"value": "SPARKLING SUGAR",
			"label": "SPARKLING SUGAR"
	},
	{
			"value": "SPARKLING SUGARS",
			"label": "SPARKLING SUGARS"
	},
	{
			"value": "SPATHIPHYLLUM",
			"label": "SPATHIPHYLLUM"
	},
	{
			"value": "SPATULA",
			"label": "SPATULA"
	},
	{
			"value": "SPATULAS",
			"label": "SPATULAS"
	},
	{
			"value": "SPEAKER",
			"label": "SPEAKER"
	},
	{
			"value": "SPEAKER CLOTH",
			"label": "SPEAKER CLOTH"
	},
	{
			"value": "SPEC",
			"label": "SPEC"
	},
	{
			"value": "SPECIAL ELASTIC",
			"label": "SPECIAL ELASTIC"
	},
	{
			"value": "SPECIAL OCCASION",
			"label": "SPECIAL OCCASION"
	},
	{
			"value": "SPECIALTY",
			"label": "SPECIALTY"
	},
	{
			"value": "SPECIALTY FOOD",
			"label": "SPECIALTY FOOD"
	},
	{
			"value": "SPECIALTY KNIT",
			"label": "SPECIALTY KNIT"
	},
	{
			"value": "SPECIALTY MESH",
			"label": "SPECIALTY MESH"
	},
	{
			"value": "SPECIALTY TULLE",
			"label": "SPECIALTY TULLE"
	},
	{
			"value": "SPECIALTY VINYL",
			"label": "SPECIALTY VINYL"
	},
	{
			"value": "SPECTRUM",
			"label": "SPECTRUM"
	},
	{
			"value": "SPECTRUM NOIR",
			"label": "SPECTRUM NOIR"
	},
	{
			"value": "SPEEDBALL ART PRODUC",
			"label": "SPEEDBALL ART PRODUC"
	},
	{
			"value": "SPELLBINDERS",
			"label": "SPELLBINDERS"
	},
	{
			"value": "SPELLBOOK",
			"label": "SPELLBOOK"
	},
	{
			"value": "SPELLBOUND",
			"label": "SPELLBOUND"
	},
	{
			"value": "SP FY 19",
			"label": "SP FY 19"
	},
	{
			"value": "SP FY19",
			"label": "SP FY19"
	},
	{
			"value": "SPFY19",
			"label": "SPFY19"
	},
	{
			"value": "SP FY20",
			"label": "SP FY20"
	},
	{
			"value": "SP FY21",
			"label": "SP FY21"
	},
	{
			"value": "SP FY24",
			"label": "SP FY24"
	},
	{
			"value": "SP FY25",
			"label": "SP FY25"
	},
	{
			"value": "SP FY26",
			"label": "SP FY26"
	},
	{
			"value": "SP FY27",
			"label": "SP FY27"
	},
	{
			"value": "SP FY28",
			"label": "SP FY28"
	},
	{
			"value": "SP FY29",
			"label": "SP FY29"
	},
	{
			"value": "SP FY30",
			"label": "SP FY30"
	},
	{
			"value": "SP FY31",
			"label": "SP FY31"
	},
	{
			"value": "SP FY32",
			"label": "SP FY32"
	},
	{
			"value": "SP FY33",
			"label": "SP FY33"
	},
	{
			"value": "SP FY34",
			"label": "SP FY34"
	},
	{
			"value": "SP FY35",
			"label": "SP FY35"
	},
	{
			"value": "SP FY36",
			"label": "SP FY36"
	},
	{
			"value": "SP FY37",
			"label": "SP FY37"
	},
	{
			"value": "SP FY38",
			"label": "SP FY38"
	},
	{
			"value": "SP FY39",
			"label": "SP FY39"
	},
	{
			"value": "SP FY40",
			"label": "SP FY40"
	},
	{
			"value": "SP FY41",
			"label": "SP FY41"
	},
	{
			"value": "SP FY42",
			"label": "SP FY42"
	},
	{
			"value": "SPHERE",
			"label": "SPHERE"
	},
	{
			"value": "SPHERE CONTAINER",
			"label": "SPHERE CONTAINER"
	},
	{
			"value": "SPIDER",
			"label": "SPIDER"
	},
	{
			"value": "SPIDER MAN",
			"label": "SPIDER MAN"
	},
	{
			"value": "SPIDERMAN",
			"label": "SPIDERMAN"
	},
	{
			"value": "SPIDERMAN- MARVEL HE",
			"label": "SPIDERMAN- MARVEL HE"
	},
	{
			"value": "SPIDERMAN-MARVEL HER",
			"label": "SPIDERMAN-MARVEL HER"
	},
	{
			"value": "SPIDER ORN",
			"label": "SPIDER ORN"
	},
	{
			"value": "SPIDERS",
			"label": "SPIDERS"
	},
	{
			"value": "SPIDER WART",
			"label": "SPIDER WART"
	},
	{
			"value": "SPIDER / WEB",
			"label": "SPIDER / WEB"
	},
	{
			"value": "SPIDER WEB",
			"label": "SPIDER WEB"
	},
	{
			"value": "SPIDERWEB",
			"label": "SPIDERWEB"
	},
	{
			"value": "SPIDER WEB LIGHTS",
			"label": "SPIDER WEB LIGHTS"
	},
	{
			"value": "SPIDERWORT",
			"label": "SPIDERWORT"
	},
	{
			"value": "SPIKE",
			"label": "SPIKE"
	},
	{
			"value": "SPIN IT ACCESSORIES",
			"label": "SPIN IT ACCESSORIES"
	},
	{
			"value": "SPINNER",
			"label": "SPINNER"
	},
	{
			"value": "SPINRITE",
			"label": "SPINRITE"
	},
	{
			"value": "SPINRITE BOOKS",
			"label": "SPINRITE BOOKS"
	},
	{
			"value": "SPINRITE (NON-YARN)",
			"label": "SPINRITE (NON-YARN)"
	},
	{
			"value": "SPIRAL",
			"label": "SPIRAL"
	},
	{
			"value": "SPIRAL CALENDAR",
			"label": "SPIRAL CALENDAR"
	},
	{
			"value": "SPIRAL RIBBON",
			"label": "SPIRAL RIBBON"
	},
	{
			"value": "SPIRAL TREE",
			"label": "SPIRAL TREE"
	},
	{
			"value": "SPIRIT GIFTBAG",
			"label": "SPIRIT GIFTBAG"
	},
	{
			"value": "SPIROGRAPH KIT",
			"label": "SPIROGRAPH KIT"
	},
	{
			"value": "SPLIT BUNDLE",
			"label": "SPLIT BUNDLE"
	},
	{
			"value": "SPLIT RINGS",
			"label": "SPLIT RINGS"
	},
	{
			"value": "SPONGEBOB",
			"label": "SPONGEBOB"
	},
	{
			"value": "SPOOKY BOOK",
			"label": "SPOOKY BOOK"
	},
	{
			"value": "SPOOKY HOUSE",
			"label": "SPOOKY HOUSE"
	},
	{
			"value": "SPOON REST",
			"label": "SPOON REST"
	},
	{
			"value": "SPOONS",
			"label": "SPOONS"
	},
	{
			"value": "SPOON SET",
			"label": "SPOON SET"
	},
	{
			"value": "SPOONULAS",
			"label": "SPOONULAS"
	},
	{
			"value": "SPORT",
			"label": "SPORT"
	},
	{
			"value": "SPORTFLEX IRON ON",
			"label": "SPORTFLEX IRON ON"
	},
	{
			"value": "SPORT NYLON",
			"label": "SPORT NYLON"
	},
	{
			"value": "SPORTS",
			"label": "SPORTS"
	},
	{
			"value": "SPORTS BLOCK GRN",
			"label": "SPORTS BLOCK GRN"
	},
	{
			"value": "SPORTS/GAMES",
			"label": "SPORTS/GAMES"
	},
	{
			"value": "SPORTS/GAMES/ACTIVE",
			"label": "SPORTS/GAMES/ACTIVE"
	},
	{
			"value": "SPORTS MISC",
			"label": "SPORTS MISC"
	},
	{
			"value": "SPORTSWEAR",
			"label": "SPORTSWEAR"
	},
	{
			"value": "SPORTSWEAR ENGLISH A",
			"label": "SPORTSWEAR ENGLISH A"
	},
	{
			"value": "SPORTSWEAR INDIAN SU",
			"label": "SPORTSWEAR INDIAN SU"
	},
	{
			"value": "SPOTTED LEAF",
			"label": "SPOTTED LEAF"
	},
	{
			"value": "SPOTTER",
			"label": "SPOTTER"
	},
	{
			"value": "SPR 19",
			"label": "SPR 19"
	},
	{
			"value": "SPRAY",
			"label": "SPRAY"
	},
	{
			"value": "SPRAYA00",
			"label": "SPRAYA00"
	},
	{
			"value": "SPRAYER",
			"label": "SPRAYER"
	},
	{
			"value": "SPRAYERS",
			"label": "SPRAYERS"
	},
	{
			"value": "SPREADER",
			"label": "SPREADER"
	},
	{
			"value": "SPREADER SET",
			"label": "SPREADER SET"
	},
	{
			"value": "SPRFV",
			"label": "SPRFV"
	},
	{
			"value": "SPRFVD00",
			"label": "SPRFVD00"
	},
	{
			"value": "SPRFVF00",
			"label": "SPRFVF00"
	},
	{
			"value": "SPRING",
			"label": "SPRING"
	},
	{
			"value": "SPRING BIKE",
			"label": "SPRING BIKE"
	},
	{
			"value": "SPRING BLING HEADER",
			"label": "SPRING BLING HEADER"
	},
	{
			"value": "SPRING BURLAP",
			"label": "SPRING BURLAP"
	},
	{
			"value": "SPRING CLASPS",
			"label": "SPRING CLASPS"
	},
	{
			"value": "SPRING DECO",
			"label": "SPRING DECO"
	},
	{
			"value": "SPRING END CAP",
			"label": "SPRING END CAP"
	},
	{
			"value": "SPRING END CAPS",
			"label": "SPRING END CAPS"
	},
	{
			"value": "SPRING FASHION",
			"label": "SPRING FASHION"
	},
	{
			"value": "SPRINGFIELD ACCS",
			"label": "SPRINGFIELD ACCS"
	},
	{
			"value": "SPRING FY15 BOTTOMWE",
			"label": "SPRING FY15 BOTTOMWE"
	},
	{
			"value": "SPRING FY15 COTTON",
			"label": "SPRING FY15 COTTON"
	},
	{
			"value": "SPRING FY15 KNITS",
			"label": "SPRING FY15 KNITS"
	},
	{
			"value": "SPRING FY15 LINEN",
			"label": "SPRING FY15 LINEN"
	},
	{
			"value": "SPRINGFY15 OPPORTUNI",
			"label": "SPRINGFY15 OPPORTUNI"
	},
	{
			"value": "SPRING JUVENILE APPA",
			"label": "SPRING JUVENILE APPA"
	},
	{
			"value": "SPRING NO SENTIMENT",
			"label": "SPRING NO SENTIMENT"
	},
	{
			"value": "SPRING NO SHOWS",
			"label": "SPRING NO SHOWS"
	},
	{
			"value": "SPRINGS CREATIVE",
			"label": "SPRINGS CREATIVE"
	},
	{
			"value": "SPRING SHEEN",
			"label": "SPRING SHEEN"
	},
	{
			"value": "SPRING SPOOLS",
			"label": "SPRING SPOOLS"
	},
	{
			"value": "SPRING SUMMER",
			"label": "SPRING SUMMER"
	},
	{
			"value": "SPRINKLES",
			"label": "SPRINKLES"
	},
	{
			"value": "SPRINKLES + SUGARS",
			"label": "SPRINKLES + SUGARS"
	},
	{
			"value": "SPRUCE",
			"label": "SPRUCE"
	},
	{
			"value": "SP SHAKERS",
			"label": "SP SHAKERS"
	},
	{
			"value": "SQ101A01",
			"label": "SQ101A01"
	},
	{
			"value": "SQ CANISTER",
			"label": "SQ CANISTER"
	},
	{
			"value": "SQ COOKIE TIN",
			"label": "SQ COOKIE TIN"
	},
	{
			"value": "SQ COOKIE TIN WINDOW",
			"label": "SQ COOKIE TIN WINDOW"
	},
	{
			"value": "SQ PILLOW",
			"label": "SQ PILLOW"
	},
	{
			"value": "SQR COOKIE TIN",
			"label": "SQR COOKIE TIN"
	},
	{
			"value": "SQ TIN 3PK",
			"label": "SQ TIN 3PK"
	},
	{
			"value": "SQ TIN WINDOW",
			"label": "SQ TIN WINDOW"
	},
	{
			"value": "SQUARE",
			"label": "SQUARE"
	},
	{
			"value": "SQUARE IVRIVR",
			"label": "SQUARE IVRIVR"
	},
	{
			"value": "SQUARE LTBLULTB",
			"label": "SQUARE LTBLULTB"
	},
	{
			"value": "SQUARE LTGRYLTG",
			"label": "SQUARE LTGRYLTG"
	},
	{
			"value": "SQUARE PLATE",
			"label": "SQUARE PLATE"
	},
	{
			"value": "SQUARE PLATTER",
			"label": "SQUARE PLATTER"
	},
	{
			"value": "SQUARES",
			"label": "SQUARES"
	},
	{
			"value": "SQUARES PLAID",
			"label": "SQUARES PLAID"
	},
	{
			"value": "SQUARE TIN",
			"label": "SQUARE TIN"
	},
	{
			"value": "SQUARE WALL DECOR",
			"label": "SQUARE WALL DECOR"
	},
	{
			"value": "SQUARE WHTWH",
			"label": "SQUARE WHTWH"
	},
	{
			"value": "SQUIRREL",
			"label": "SQUIRREL"
	},
	{
			"value": "SRAYS",
			"label": "SRAYS"
	},
	{
			"value": "SS",
			"label": "SS"
	},
	{
			"value": "S SIZE",
			"label": "S SIZE"
	},
	{
			"value": "SSMCNA00",
			"label": "SSMCNA00"
	},
	{
			"value": "SSMCNE00",
			"label": "SSMCNE00"
	},
	{
			"value": "STABILIZER",
			"label": "STABILIZER"
	},
	{
			"value": "STACK",
			"label": "STACK"
	},
	{
			"value": "STACKED NUTCRACKER",
			"label": "STACKED NUTCRACKER"
	},
	{
			"value": "STACKED PUMPKIN",
			"label": "STACKED PUMPKIN"
	},
	{
			"value": "STACK OUT",
			"label": "STACK OUT"
	},
	{
			"value": "STACKOUT13 AMERICANA",
			"label": "STACKOUT13 AMERICANA"
	},
	{
			"value": "STAEDTLER",
			"label": "STAEDTLER"
	},
	{
			"value": "STAG",
			"label": "STAG"
	},
	{
			"value": "STAGHORN",
			"label": "STAGHORN"
	},
	{
			"value": "STAIN",
			"label": "STAIN"
	},
	{
			"value": "STAINLESS",
			"label": "STAINLESS"
	},
	{
			"value": "STAIN RESISTANT",
			"label": "STAIN RESISTANT"
	},
	{
			"value": "STAKE",
			"label": "STAKE"
	},
	{
			"value": "STAKE BIRD BATH",
			"label": "STAKE BIRD BATH"
	},
	{
			"value": "STAKE BIRDHOUSE",
			"label": "STAKE BIRDHOUSE"
	},
	{
			"value": "STAKE BLACK",
			"label": "STAKE BLACK"
	},
	{
			"value": "STAKE DECORATIVE",
			"label": "STAKE DECORATIVE"
	},
	{
			"value": "STAKE KINETIC",
			"label": "STAKE KINETIC"
	},
	{
			"value": "STAKE KINETIC NVLTY",
			"label": "STAKE KINETIC NVLTY"
	},
	{
			"value": "STAKE KINETIC TRAD",
			"label": "STAKE KINETIC TRAD"
	},
	{
			"value": "STAKE LIGHTING",
			"label": "STAKE LIGHTING"
	},
	{
			"value": "STAKE NOVELTY",
			"label": "STAKE NOVELTY"
	},
	{
			"value": "STAKE PLANTER",
			"label": "STAKE PLANTER"
	},
	{
			"value": "STAKE RAIN GAUGE",
			"label": "STAKE RAIN GAUGE"
	},
	{
			"value": "STAKES",
			"label": "STAKES"
	},
	{
			"value": "STAKE SILVER",
			"label": "STAKE SILVER"
	},
	{
			"value": "STAKE SOLAR",
			"label": "STAKE SOLAR"
	},
	{
			"value": "STAKE SOLAR NOVELTY",
			"label": "STAKE SOLAR NOVELTY"
	},
	{
			"value": "STAKE SOLAR TRAD",
			"label": "STAKE SOLAR TRAD"
	},
	{
			"value": "STAKE TEMP GAUGE",
			"label": "STAKE TEMP GAUGE"
	},
	{
			"value": "STAKE WHITE",
			"label": "STAKE WHITE"
	},
	{
			"value": "STAMP",
			"label": "STAMP"
	},
	{
			"value": "STAMPA00",
			"label": "STAMPA00"
	},
	{
			"value": "STAMPABILITIES",
			"label": "STAMPABILITIES"
	},
	{
			"value": "STAMPB00",
			"label": "STAMPB00"
	},
	{
			"value": "STAMP BASE",
			"label": "STAMP BASE"
	},
	{
			"value": "STAMP BLOCK",
			"label": "STAMP BLOCK"
	},
	{
			"value": "STAMP BRACELET",
			"label": "STAMP BRACELET"
	},
	{
			"value": "STAMP CUTTERS",
			"label": "STAMP CUTTERS"
	},
	{
			"value": "STAMP ENAMEL",
			"label": "STAMP ENAMEL"
	},
	{
			"value": "STAMPENDOUS",
			"label": "STAMPENDOUS"
	},
	{
			"value": "STAMPERS ANONYMOUS",
			"label": "STAMPERS ANONYMOUS"
	},
	{
			"value": "STAMP GUIDE",
			"label": "STAMP GUIDE"
	},
	{
			"value": "STAMP HOLE PUNCH",
			"label": "STAMP HOLE PUNCH"
	},
	{
			"value": "STAMPING BELLA",
			"label": "STAMPING BELLA"
	},
	{
			"value": "STAMPING SCRAPPING",
			"label": "STAMPING SCRAPPING"
	},
	{
			"value": "STAMP JIG",
			"label": "STAMP JIG"
	},
	{
			"value": "STAMPS",
			"label": "STAMPS"
	},
	{
			"value": "STAMP TAPE",
			"label": "STAMP TAPE"
	},
	{
			"value": "STANARD BAKING CUPS",
			"label": "STANARD BAKING CUPS"
	},
	{
			"value": "STAND",
			"label": "STAND"
	},
	{
			"value": "STANDARD",
			"label": "STANDARD"
	},
	{
			"value": "STANDARD BAKING CUPS",
			"label": "STANDARD BAKING CUPS"
	},
	{
			"value": "STANDARD POT",
			"label": "STANDARD POT"
	},
	{
			"value": "STANDARD SHAM",
			"label": "STANDARD SHAM"
	},
	{
			"value": "STAND BAND",
			"label": "STAND BAND"
	},
	{
			"value": "STAND HEADER",
			"label": "STAND HEADER"
	},
	{
			"value": "STANDING",
			"label": "STANDING"
	},
	{
			"value": "STANDING BIRD",
			"label": "STANDING BIRD"
	},
	{
			"value": "STANDING CRANE",
			"label": "STANDING CRANE"
	},
	{
			"value": "STANDING FEEDER",
			"label": "STANDING FEEDER"
	},
	{
			"value": "STANDING SANTA",
			"label": "STANDING SANTA"
	},
	{
			"value": "STANDING SNOWMAN",
			"label": "STANDING SNOWMAN"
	},
	{
			"value": "STANDS",
			"label": "STANDS"
	},
	{
			"value": "STAR",
			"label": "STAR"
	},
	{
			"value": "STARBURST",
			"label": "STARBURST"
	},
	{
			"value": "STARBURST STEM",
			"label": "STARBURST STEM"
	},
	{
			"value": "STAR CANDLE",
			"label": "STAR CANDLE"
	},
	{
			"value": "STAR COLLECTION",
			"label": "STAR COLLECTION"
	},
	{
			"value": "STARFISH",
			"label": "STARFISH"
	},
	{
			"value": "STARFISH GARLAND",
			"label": "STARFISH GARLAND"
	},
	{
			"value": "STARFISH PLATTER",
			"label": "STARFISH PLATTER"
	},
	{
			"value": "STARFISH WD",
			"label": "STARFISH WD"
	},
	{
			"value": "STAR GRASS",
			"label": "STAR GRASS"
	},
	{
			"value": "STAR HEADBAND",
			"label": "STAR HEADBAND"
	},
	{
			"value": "STAR PICK",
			"label": "STAR PICK"
	},
	{
			"value": "STAR PINECONE",
			"label": "STAR PINECONE"
	},
	{
			"value": "STAR PRODUCTS",
			"label": "STAR PRODUCTS"
	},
	{
			"value": "STARRY NIGHT",
			"label": "STARRY NIGHT"
	},
	{
			"value": "STARRY NIGHT OSO",
			"label": "STARRY NIGHT OSO"
	},
	{
			"value": "STARS",
			"label": "STARS"
	},
	{
			"value": "STARS & HEARTS",
			"label": "STARS & HEARTS"
	},
	{
			"value": "STARSS",
			"label": "STARSS"
	},
	{
			"value": "STAR STEM",
			"label": "STAR STEM"
	},
	{
			"value": "STAR TABLETOP DECOR",
			"label": "STAR TABLETOP DECOR"
	},
	{
			"value": "STAR TOWEL",
			"label": "STAR TOWEL"
	},
	{
			"value": "STAR TREK",
			"label": "STAR TREK"
	},
	{
			"value": "STARTS",
			"label": "STARTS"
	},
	{
			"value": "STAR WARS",
			"label": "STAR WARS"
	},
	{
			"value": "STAR WREATH",
			"label": "STAR WREATH"
	},
	{
			"value": "STATE",
			"label": "STATE"
	},
	{
			"value": "STATEMENT",
			"label": "STATEMENT"
	},
	{
			"value": "STATICE",
			"label": "STATICE"
	},
	{
			"value": "STATIONERY",
			"label": "STATIONERY"
	},
	{
			"value": "STATUARIES",
			"label": "STATUARIES"
	},
	{
			"value": "STATUARY",
			"label": "STATUARY"
	},
	{
			"value": "STATUARY BIRDBATH",
			"label": "STATUARY BIRDBATH"
	},
	{
			"value": "STATUARY BIRDFEEDER",
			"label": "STATUARY BIRDFEEDER"
	},
	{
			"value": "STATUARY SINGLE",
			"label": "STATUARY SINGLE"
	},
	{
			"value": "STATUARY SOLAR",
			"label": "STATUARY SOLAR"
	},
	{
			"value": "STATUE",
			"label": "STATUE"
	},
	{
			"value": "STAY WILD",
			"label": "STAY WILD"
	},
	{
			"value": "ST COUNTDOWN",
			"label": "ST COUNTDOWN"
	},
	{
			"value": "STEAMER",
			"label": "STEAMER"
	},
	{
			"value": "STEAMPUNK",
			"label": "STEAMPUNK"
	},
	{
			"value": "STEAM STATION",
			"label": "STEAM STATION"
	},
	{
			"value": "STEEL POLE",
			"label": "STEEL POLE"
	},
	{
			"value": "STEM",
			"label": "STEM"
	},
	{
			"value": "STEM KIT LAB",
			"label": "STEM KIT LAB"
	},
	{
			"value": "STEM KIT ROBOT",
			"label": "STEM KIT ROBOT"
	},
	{
			"value": "STEM PROJECTION KIT",
			"label": "STEM PROJECTION KIT"
	},
	{
			"value": "STEMS",
			"label": "STEMS"
	},
	{
			"value": "STENCIL",
			"label": "STENCIL"
	},
	{
			"value": "STENCILS",
			"label": "STENCILS"
	},
	{
			"value": "STENCIL SPRAY",
			"label": "STENCIL SPRAY"
	},
	{
			"value": "STEN SOURCE",
			"label": "STEN SOURCE"
	},
	{
			"value": "STEPHANOTIS",
			"label": "STEPHANOTIS"
	},
	{
			"value": "STEPPING STONE",
			"label": "STEPPING STONE"
	},
	{
			"value": "STEPPING STONE KIT",
			"label": "STEPPING STONE KIT"
	},
	{
			"value": "STEPPING STONES",
			"label": "STEPPING STONES"
	},
	{
			"value": "STERLING PUBLISHING",
			"label": "STERLING PUBLISHING"
	},
	{
			"value": "STEWART/SUPERIOR",
			"label": "STEWART/SUPERIOR"
	},
	{
			"value": "STICK",
			"label": "STICK"
	},
	{
			"value": "STICKER",
			"label": "STICKER"
	},
	{
			"value": "STICKER BOOK",
			"label": "STICKER BOOK"
	},
	{
			"value": "STICKERBOOK",
			"label": "STICKERBOOK"
	},
	{
			"value": "STICKER GUIDE",
			"label": "STICKER GUIDE"
	},
	{
			"value": "STICKER PAPER",
			"label": "STICKER PAPER"
	},
	{
			"value": "STICKER PUFFY",
			"label": "STICKER PUFFY"
	},
	{
			"value": "STICKERS",
			"label": "STICKERS"
	},
	{
			"value": "STICKERS FELT",
			"label": "STICKERS FELT"
	},
	{
			"value": "STICKERS OCEAN",
			"label": "STICKERS OCEAN"
	},
	{
			"value": "STICKO",
			"label": "STICKO"
	},
	{
			"value": "STICKY BACK",
			"label": "STICKY BACK"
	},
	{
			"value": "STICKY BACK YD",
			"label": "STICKY BACK YD"
	},
	{
			"value": "STICKY FOAM 9X12",
			"label": "STICKY FOAM 9X12"
	},
	{
			"value": "STICKY NOTES",
			"label": "STICKY NOTES"
	},
	{
			"value": "STICKY THUMB",
			"label": "STICKY THUMB"
	},
	{
			"value": "STITCH 24 CRM",
			"label": "STITCH 24 CRM"
	},
	{
			"value": "STITCH A PHOTO",
			"label": "STITCH A PHOTO"
	},
	{
			"value": "STITCHES",
			"label": "STITCHES"
	},
	{
			"value": "STITCH KIT",
			"label": "STITCH KIT"
	},
	{
			"value": "STK05",
			"label": "STK05"
	},
	{
			"value": "STK21",
			"label": "STK21"
	},
	{
			"value": "STK26",
			"label": "STK26"
	},
	{
			"value": "STK9",
			"label": "STK9"
	},
	{
			"value": "STKUPB00",
			"label": "STKUPB00"
	},
	{
			"value": "STKUPC00",
			"label": "STKUPC00"
	},
	{
			"value": "STKUPD00",
			"label": "STKUPD00"
	},
	{
			"value": "ST LED LANTERN",
			"label": "ST LED LANTERN"
	},
	{
			"value": "ST LED TREE",
			"label": "ST LED TREE"
	},
	{
			"value": "STM",
			"label": "STM"
	},
	{
			"value": "STNCLB00",
			"label": "STNCLB00"
	},
	{
			"value": "STOCKING",
			"label": "STOCKING"
	},
	{
			"value": "STOCKING HOLDER",
			"label": "STOCKING HOLDER"
	},
	{
			"value": "STOCKING PIN",
			"label": "STOCKING PIN"
	},
	{
			"value": "STOCKINGS",
			"label": "STOCKINGS"
	},
	{
			"value": "STONE",
			"label": "STONE"
	},
	{
			"value": "STONE AGE",
			"label": "STONE AGE"
	},
	{
			"value": "STONE AGE ENDCAP",
			"label": "STONE AGE ENDCAP"
	},
	{
			"value": "STONE BEADS",
			"label": "STONE BEADS"
	},
	{
			"value": "STONEHILL",
			"label": "STONEHILL"
	},
	{
			"value": "STONES",
			"label": "STONES"
	},
	{
			"value": "STONE SETTING BASE",
			"label": "STONE SETTING BASE"
	},
	{
			"value": "STONE SETTING COMP",
			"label": "STONE SETTING COMP"
	},
	{
			"value": "STONEWARE",
			"label": "STONEWARE"
	},
	{
			"value": "STONEWARE STATUARY",
			"label": "STONEWARE STATUARY"
	},
	{
			"value": "STONEY CREEK",
			"label": "STONEY CREEK"
	},
	{
			"value": "STOOL",
			"label": "STOOL"
	},
	{
			"value": "STOOL CONTAINER",
			"label": "STOOL CONTAINER"
	},
	{
			"value": "STOPPER CAT",
			"label": "STOPPER CAT"
	},
	{
			"value": "STOPPER DOG",
			"label": "STOPPER DOG"
	},
	{
			"value": "STORAGE",
			"label": "STORAGE"
	},
	{
			"value": "STORAGE & ORGANIZER",
			"label": "STORAGE & ORGANIZER"
	},
	{
			"value": "STOREY PUBLISHING",
			"label": "STOREY PUBLISHING"
	},
	{
			"value": "STORY BOOK",
			"label": "STORY BOOK"
	},
	{
			"value": "STORYBOOK",
			"label": "STORYBOOK"
	},
	{
			"value": "STORYBOOK COSTUME",
			"label": "STORYBOOK COSTUME"
	},
	{
			"value": "STORY LOCKETS",
			"label": "STORY LOCKETS"
	},
	{
			"value": "ST PAT BELL",
			"label": "ST PAT BELL"
	},
	{
			"value": "ST PAT FOAM BASE",
			"label": "ST PAT FOAM BASE"
	},
	{
			"value": "ST PAT FOAMBASE",
			"label": "ST PAT FOAMBASE"
	},
	{
			"value": "ST PAT GLUE",
			"label": "ST PAT GLUE"
	},
	{
			"value": "ST PAT KIT",
			"label": "ST PAT KIT"
	},
	{
			"value": "ST PAT MELTY BEAD",
			"label": "ST PAT MELTY BEAD"
	},
	{
			"value": "ST PAT MESH",
			"label": "ST PAT MESH"
	},
	{
			"value": "ST PAT POM",
			"label": "ST PAT POM"
	},
	{
			"value": "ST PAT POSTER",
			"label": "ST PAT POSTER"
	},
	{
			"value": "ST PATS",
			"label": "ST PATS"
	},
	{
			"value": "ST PAT SEQUIN",
			"label": "ST PAT SEQUIN"
	},
	{
			"value": "ST PATS RIBBON",
			"label": "ST PATS RIBBON"
	},
	{
			"value": "ST PAT STEM",
			"label": "ST PAT STEM"
	},
	{
			"value": "ST PAT STICKER",
			"label": "ST PAT STICKER"
	},
	{
			"value": "ST PAT STICKERBOOK",
			"label": "ST PAT STICKERBOOK"
	},
	{
			"value": "ST PAT STICKERS",
			"label": "ST PAT STICKERS"
	},
	{
			"value": "ST PAT SUNCATCHER",
			"label": "ST PAT SUNCATCHER"
	},
	{
			"value": "ST PAT TASSEL",
			"label": "ST PAT TASSEL"
	},
	{
			"value": "ST PAT TWINE",
			"label": "ST PAT TWINE"
	},
	{
			"value": "ST PATTYS DAY",
			"label": "ST PATTYS DAY"
	},
	{
			"value": "ST PATTYS DAY QUEUE",
			"label": "ST PATTYS DAY QUEUE"
	},
	{
			"value": "ST PATTYS QUEUE PANE",
			"label": "ST PATTYS QUEUE PANE"
	},
	{
			"value": "ST PAT VELVET POSTER",
			"label": "ST PAT VELVET POSTER"
	},
	{
			"value": "ST PAT WOOD DOLL",
			"label": "ST PAT WOOD DOLL"
	},
	{
			"value": "ST PAT WOOD MASK",
			"label": "ST PAT WOOD MASK"
	},
	{
			"value": "ST PAT WOOD PADDLE",
			"label": "ST PAT WOOD PADDLE"
	},
	{
			"value": "ST PAT WOOD PAINT KT",
			"label": "ST PAT WOOD PAINT KT"
	},
	{
			"value": "ST PRIDE COTTON",
			"label": "ST PRIDE COTTON"
	},
	{
			"value": "ST PRIDE PKGD",
			"label": "ST PRIDE PKGD"
	},
	{
			"value": "STRAIGHT",
			"label": "STRAIGHT"
	},
	{
			"value": "STRAIGHT EASY ACTION",
			"label": "STRAIGHT EASY ACTION"
	},
	{
			"value": "STRAND",
			"label": "STRAND"
	},
	{
			"value": "STRAND FAMILIES",
			"label": "STRAND FAMILIES"
	},
	{
			"value": "STRATEGY GAME",
			"label": "STRATEGY GAME"
	},
	{
			"value": "STRAW",
			"label": "STRAW"
	},
	{
			"value": "STRAW BALE",
			"label": "STRAW BALE"
	},
	{
			"value": "STRAWBALE",
			"label": "STRAWBALE"
	},
	{
			"value": "STRAWBERRY",
			"label": "STRAWBERRY"
	},
	{
			"value": "STRAWFLOWER",
			"label": "STRAWFLOWER"
	},
	{
			"value": "STRAW HAT",
			"label": "STRAW HAT"
	},
	{
			"value": "STRAWS",
			"label": "STRAWS"
	},
	{
			"value": "STREAMERS",
			"label": "STREAMERS"
	},
	{
			"value": "STREET LAMPS",
			"label": "STREET LAMPS"
	},
	{
			"value": "ST REINDEER",
			"label": "ST REINDEER"
	},
	{
			"value": "STRESS BALL",
			"label": "STRESS BALL"
	},
	{
			"value": "STRETCH",
			"label": "STRETCH"
	},
	{
			"value": "STRETCH CHARMEUSE",
			"label": "STRETCH CHARMEUSE"
	},
	{
			"value": "STRETCH CHIFFON",
			"label": "STRETCH CHIFFON"
	},
	{
			"value": "STRETCH CORD",
			"label": "STRETCH CORD"
	},
	{
			"value": "STRETCH CREPE",
			"label": "STRETCH CREPE"
	},
	{
			"value": "STRETCH HTV",
			"label": "STRETCH HTV"
	},
	{
			"value": "STRETCH LACE",
			"label": "STRETCH LACE"
	},
	{
			"value": "STRETCH MAGIC",
			"label": "STRETCH MAGIC"
	},
	{
			"value": "STRETCH RING CONNECT",
			"label": "STRETCH RING CONNECT"
	},
	{
			"value": "STRETCHRITE",
			"label": "STRETCHRITE"
	},
	{
			"value": "STRETCH SANDED CHIFF",
			"label": "STRETCH SANDED CHIFF"
	},
	{
			"value": "STRETCH SATEEN",
			"label": "STRETCH SATEEN"
	},
	{
			"value": "STRETCH SATIN",
			"label": "STRETCH SATIN"
	},
	{
			"value": "STRETCH TULLE",
			"label": "STRETCH TULLE"
	},
	{
			"value": "STRETCH TWILL",
			"label": "STRETCH TWILL"
	},
	{
			"value": "STRIATED STRIPE",
			"label": "STRIATED STRIPE"
	},
	{
			"value": "STRING",
			"label": "STRING"
	},
	{
			"value": "STRING ART",
			"label": "STRING ART"
	},
	{
			"value": "STRING LIGH",
			"label": "STRING LIGH"
	},
	{
			"value": "STRING LIGHS",
			"label": "STRING LIGHS"
	},
	{
			"value": "STRING LIGHT",
			"label": "STRING LIGHT"
	},
	{
			"value": "STRING LIGHT NOVELTY",
			"label": "STRING LIGHT NOVELTY"
	},
	{
			"value": "STRING LIGHTS",
			"label": "STRING LIGHTS"
	},
	{
			"value": "STRING LIGHTS BASIC",
			"label": "STRING LIGHTS BASIC"
	},
	{
			"value": "STRING LIGHTS EDISON",
			"label": "STRING LIGHTS EDISON"
	},
	{
			"value": "STRING LIGHTS NOVLTY",
			"label": "STRING LIGHTS NOVLTY"
	},
	{
			"value": "STRIP",
			"label": "STRIP"
	},
	{
			"value": "STRIPE",
			"label": "STRIPE"
	},
	{
			"value": "STRIPE 18 BLU",
			"label": "STRIPE 18 BLU"
	},
	{
			"value": "STRIPE 18 GRY",
			"label": "STRIPE 18 GRY"
	},
	{
			"value": "STRIPE FLORAL",
			"label": "STRIPE FLORAL"
	},
	{
			"value": "STRIPE GRY",
			"label": "STRIPE GRY"
	},
	{
			"value": "STRIPE LINEN",
			"label": "STRIPE LINEN"
	},
	{
			"value": "STRIPE NVY",
			"label": "STRIPE NVY"
	},
	{
			"value": "STRIPES",
			"label": "STRIPES"
	},
	{
			"value": "STRIPE TOWEL",
			"label": "STRIPE TOWEL"
	},
	{
			"value": "STRIPE TURKISH",
			"label": "STRIPE TURKISH"
	},
	{
			"value": "STRIPFLOCK HTV",
			"label": "STRIPFLOCK HTV"
	},
	{
			"value": "STRIP NAT",
			"label": "STRIP NAT"
	},
	{
			"value": "STRUNG",
			"label": "STRUNG"
	},
	{
			"value": "STRUNG BEAD",
			"label": "STRUNG BEAD"
	},
	{
			"value": "STRUNG BEADS",
			"label": "STRUNG BEADS"
	},
	{
			"value": "ST TABLE DECOR",
			"label": "ST TABLE DECOR"
	},
	{
			"value": "ST TREE",
			"label": "ST TREE"
	},
	{
			"value": "STUDENT KITS",
			"label": "STUDENT KITS"
	},
	{
			"value": "STUDIO CALICO",
			"label": "STUDIO CALICO"
	},
	{
			"value": "STUDIO KIT",
			"label": "STUDIO KIT"
	},
	{
			"value": "STUDIO KITS",
			"label": "STUDIO KITS"
	},
	{
			"value": "STUDS",
			"label": "STUDS"
	},
	{
			"value": "STUFFED CRAFT",
			"label": "STUFFED CRAFT"
	},
	{
			"value": "STUFFING BEAD",
			"label": "STUFFING BEAD"
	},
	{
			"value": "STYLE IN AN INSTANT",
			"label": "STYLE IN AN INSTANT"
	},
	{
			"value": "STYRF",
			"label": "STYRF"
	},
	{
			"value": "STYRFA00",
			"label": "STYRFA00"
	},
	{
			"value": "STYROFOAM",
			"label": "STYROFOAM"
	},
	{
			"value": "STYRO/PMPKN",
			"label": "STYRO/PMPKN"
	},
	{
			"value": "SUBLIMATION ESYSUBLI",
			"label": "SUBLIMATION ESYSUBLI"
	},
	{
			"value": "SUBLIMATION INK",
			"label": "SUBLIMATION INK"
	},
	{
			"value": "SUBLIMATION MARKERS",
			"label": "SUBLIMATION MARKERS"
	},
	{
			"value": "SUBLIMATION PAPER",
			"label": "SUBLIMATION PAPER"
	},
	{
			"value": "SUBLIME STITCHING",
			"label": "SUBLIME STITCHING"
	},
	{
			"value": "SUCCULENT",
			"label": "SUCCULENT"
	},
	{
			"value": "SUCCULENTS",
			"label": "SUCCULENTS"
	},
	{
			"value": "SUCCULENT WREATH",
			"label": "SUCCULENT WREATH"
	},
	{
			"value": "SUCTION CUP",
			"label": "SUCTION CUP"
	},
	{
			"value": "SUCTION CUPS",
			"label": "SUCTION CUPS"
	},
	{
			"value": "SUEDE",
			"label": "SUEDE"
	},
	{
			"value": "SUEDE FRINGE",
			"label": "SUEDE FRINGE"
	},
	{
			"value": "SUEDES",
			"label": "SUEDES"
	},
	{
			"value": "SUE SPARGO",
			"label": "SUE SPARGO"
	},
	{
			"value": "SUGARBELLE",
			"label": "SUGARBELLE"
	},
	{
			"value": "SUGAR CONE",
			"label": "SUGAR CONE"
	},
	{
			"value": "SUGARLOAF",
			"label": "SUGARLOAF"
	},
	{
			"value": "SUGAR PEARLS",
			"label": "SUGAR PEARLS"
	},
	{
			"value": "SUGAR PLUM",
			"label": "SUGAR PLUM"
	},
	{
			"value": "SUGARPLUM OSO",
			"label": "SUGARPLUM OSO"
	},
	{
			"value": "SUGAR SHEET",
			"label": "SUGAR SHEET"
	},
	{
			"value": "SUGAR SHEETS",
			"label": "SUGAR SHEETS"
	},
	{
			"value": "SUITCASE",
			"label": "SUITCASE"
	},
	{
			"value": "SUITINGS OPP BUY",
			"label": "SUITINGS OPP BUY"
	},
	{
			"value": "SULFITE PAPER",
			"label": "SULFITE PAPER"
	},
	{
			"value": "SULKY",
			"label": "SULKY"
	},
	{
			"value": "SULLIVANS",
			"label": "SULLIVANS"
	},
	{
			"value": "SULYN",
			"label": "SULYN"
	},
	{
			"value": "SUMMER",
			"label": "SUMMER"
	},
	{
			"value": "SUMMER 3 WICK",
			"label": "SUMMER 3 WICK"
	},
	{
			"value": "SUMMER DECO",
			"label": "SUMMER DECO"
	},
	{
			"value": "SUMMER FLORAL",
			"label": "SUMMER FLORAL"
	},
	{
			"value": "SUMMER HOUSE",
			"label": "SUMMER HOUSE"
	},
	{
			"value": "SUMMER INDOOR POTTER",
			"label": "SUMMER INDOOR POTTER"
	},
	{
			"value": "SUMMER LIVING",
			"label": "SUMMER LIVING"
	},
	{
			"value": "SUN",
			"label": "SUN"
	},
	{
			"value": "SUN/ANIMAL",
			"label": "SUN/ANIMAL"
	},
	{
			"value": "SUNBONNET SUE",
			"label": "SUNBONNET SUE"
	},
	{
			"value": "SUNCATCHER",
			"label": "SUNCATCHER"
	},
	{
			"value": "SUN FLOWER",
			"label": "SUN FLOWER"
	},
	{
			"value": "SUNFLOWER",
			"label": "SUNFLOWER"
	},
	{
			"value": "SUNFLOWER BSH",
			"label": "SUNFLOWER BSH"
	},
	{
			"value": "SUNFLOWER HYDRA",
			"label": "SUNFLOWER HYDRA"
	},
	{
			"value": "SUNFLOWER/PUMPKIN",
			"label": "SUNFLOWER/PUMPKIN"
	},
	{
			"value": "SUNFLOWERS",
			"label": "SUNFLOWERS"
	},
	{
			"value": "SUNFLOWER STEM",
			"label": "SUNFLOWER STEM"
	},
	{
			"value": "SUNFLOWER WREATH",
			"label": "SUNFLOWER WREATH"
	},
	{
			"value": "SUNFLR",
			"label": "SUNFLR"
	},
	{
			"value": "SUNFLR/FOXTAIL/GRASS",
			"label": "SUNFLR/FOXTAIL/GRASS"
	},
	{
			"value": "SUNFLR/FOXTAIL IN PI",
			"label": "SUNFLR/FOXTAIL IN PI"
	},
	{
			"value": "SUNFLR MUM",
			"label": "SUNFLR MUM"
	},
	{
			"value": "SUNFLR TOPIARY",
			"label": "SUNFLR TOPIARY"
	},
	{
			"value": "SUNFLR/WHEAT IN POT",
			"label": "SUNFLR/WHEAT IN POT"
	},
	{
			"value": "SUNFLW",
			"label": "SUNFLW"
	},
	{
			"value": "SUNFLWR",
			"label": "SUNFLWR"
	},
	{
			"value": "SUNGLASSES",
			"label": "SUNGLASSES"
	},
	{
			"value": "SUNHAT",
			"label": "SUNHAT"
	},
	{
			"value": "SUNNY AND BRIGHT",
			"label": "SUNNY AND BRIGHT"
	},
	{
			"value": "SUNNY & BRIGHT",
			"label": "SUNNY & BRIGHT"
	},
	{
			"value": "SUNNY DAZE",
			"label": "SUNNY DAZE"
	},
	{
			"value": "SUN SHADES",
			"label": "SUN SHADES"
	},
	{
			"value": "SUNSHINE",
			"label": "SUNSHINE"
	},
	{
			"value": "SUN TOWEL",
			"label": "SUN TOWEL"
	},
	{
			"value": "SUN WASHED COLLECTIO",
			"label": "SUN WASHED COLLECTIO"
	},
	{
			"value": "SUNWASHED MEADOW",
			"label": "SUNWASHED MEADOW"
	},
	{
			"value": "SUPERGIRL",
			"label": "SUPERGIRL"
	},
	{
			"value": "SUPER GLUE",
			"label": "SUPER GLUE"
	},
	{
			"value": "SUPER HERO FASHION",
			"label": "SUPER HERO FASHION"
	},
	{
			"value": "SUPERIOR THREAD",
			"label": "SUPERIOR THREAD"
	},
	{
			"value": "SUPERMAN",
			"label": "SUPERMAN"
	},
	{
			"value": "SUPERMAN AW",
			"label": "SUPERMAN AW"
	},
	{
			"value": "SUPERSHINE",
			"label": "SUPERSHINE"
	},
	{
			"value": "SUPER SOFT",
			"label": "SUPER SOFT"
	},
	{
			"value": "SUPER STACKER",
			"label": "SUPER STACKER"
	},
	{
			"value": "SUPPLIES NON MERCH",
			"label": "SUPPLIES NON MERCH"
	},
	{
			"value": "SURFACE",
			"label": "SURFACE"
	},
	{
			"value": "SURFACE DESIGN",
			"label": "SURFACE DESIGN"
	},
	{
			"value": "SURFACE INTEREST",
			"label": "SURFACE INTEREST"
	},
	{
			"value": "SURFACE PROTECTION",
			"label": "SURFACE PROTECTION"
	},
	{
			"value": "SURVIVAL FABRICS",
			"label": "SURVIVAL FABRICS"
	},
	{
			"value": "SUSAN BATES",
			"label": "SUSAN BATES"
	},
	{
			"value": "SUSAN WINGET",
			"label": "SUSAN WINGET"
	},
	{
			"value": "SUSAN WINGET CMAS",
			"label": "SUSAN WINGET CMAS"
	},
	{
			"value": "SWAG",
			"label": "SWAG"
	},
	{
			"value": "SWAGS & CASCADES",
			"label": "SWAGS & CASCADES"
	},
	{
			"value": "SWAR HOT FIX CRYSTAL",
			"label": "SWAR HOT FIX CRYSTAL"
	},
	{
			"value": "SWAROVSKI IOT DSGN",
			"label": "SWAROVSKI IOT DSGN"
	},
	{
			"value": "SWAR TECH CRYSTAL",
			"label": "SWAR TECH CRYSTAL"
	},
	{
			"value": "SWEATER",
			"label": "SWEATER"
	},
	{
			"value": "SWEATER KNIT",
			"label": "SWEATER KNIT"
	},
	{
			"value": "SWEATER PILLOW",
			"label": "SWEATER PILLOW"
	},
	{
			"value": "SWEATER WEATHER",
			"label": "SWEATER WEATHER"
	},
	{
			"value": "SWEDISH WEAVE DESIGN",
			"label": "SWEDISH WEAVE DESIGN"
	},
	{
			"value": "SWEET BAY",
			"label": "SWEET BAY"
	},
	{
			"value": "SWEET CAT DOG",
			"label": "SWEET CAT DOG"
	},
	{
			"value": "SWEET PEA",
			"label": "SWEET PEA"
	},
	{
			"value": "SWEETS",
			"label": "SWEETS"
	},
	{
			"value": "SWEETS BLOCK",
			"label": "SWEETS BLOCK"
	},
	{
			"value": "SWEET TOOTH FAIRY",
			"label": "SWEET TOOTH FAIRY"
	},
	{
			"value": "SWEET TREATS",
			"label": "SWEET TREATS"
	},
	{
			"value": "SWEET TREATS QUE",
			"label": "SWEET TREATS QUE"
	},
	{
			"value": "SWFE0003",
			"label": "SWFE0003"
	},
	{
			"value": "SWIM",
			"label": "SWIM"
	},
	{
			"value": "SWIM COORDINATE",
			"label": "SWIM COORDINATE"
	},
	{
			"value": "SWIM & DANCE",
			"label": "SWIM & DANCE"
	},
	{
			"value": "SWIM/DANCE",
			"label": "SWIM/DANCE"
	},
	{
			"value": "SWIM/DANCE KNITS",
			"label": "SWIM/DANCE KNITS"
	},
	{
			"value": "SWIM ELASTIC",
			"label": "SWIM ELASTIC"
	},
	{
			"value": "SWIM PRINT OPP BUY",
			"label": "SWIM PRINT OPP BUY"
	},
	{
			"value": "SWING",
			"label": "SWING"
	},
	{
			"value": "SWIRL",
			"label": "SWIRL"
	},
	{
			"value": "SWIRL FUR",
			"label": "SWIRL FUR"
	},
	{
			"value": "SWIRL METAL WALL",
			"label": "SWIRL METAL WALL"
	},
	{
			"value": "SWIRLS",
			"label": "SWIRLS"
	},
	{
			"value": "SWIRLS & SCROLLS",
			"label": "SWIRLS & SCROLLS"
	},
	{
			"value": "SWISS DOT",
			"label": "SWISS DOT"
	},
	{
			"value": "SWMCH",
			"label": "SWMCH"
	},
	{
			"value": "SWMCHE00",
			"label": "SWMCHE00"
	},
	{
			"value": "SW RED TRUCK",
			"label": "SW RED TRUCK"
	},
	{
			"value": "SYLVANIA",
			"label": "SYLVANIA"
	},
	{
			"value": "SYMBOL",
			"label": "SYMBOL"
	},
	{
			"value": "SYMPHONY SOLID",
			"label": "SYMPHONY SOLID"
	},
	{
			"value": "SYNDICATE",
			"label": "SYNDICATE"
	},
	{
			"value": "SYNGONIUM",
			"label": "SYNGONIUM"
	},
	{
			"value": "SYRUP",
			"label": "SYRUP"
	},
	{
			"value": "TABLE",
			"label": "TABLE"
	},
	{
			"value": "TABLECL",
			"label": "TABLECL"
	},
	{
			"value": "TABLE CLOTH",
			"label": "TABLE CLOTH"
	},
	{
			"value": "TABLECLOTH",
			"label": "TABLECLOTH"
	},
	{
			"value": "TABLE DECOR",
			"label": "TABLE DECOR"
	},
	{
			"value": "TABLE DÉCOR",
			"label": "TABLE DÉCOR"
	},
	{
			"value": "TABLE DEOOR",
			"label": "TABLE DEOOR"
	},
	{
			"value": "TABLE/FLOOR",
			"label": "TABLE/FLOOR"
	},
	{
			"value": "TABLE LAMP",
			"label": "TABLE LAMP"
	},
	{
			"value": "TABLE NAVY",
			"label": "TABLE NAVY"
	},
	{
			"value": "TABLE RUNNER",
			"label": "TABLE RUNNER"
	},
	{
			"value": "TABLERUNNER",
			"label": "TABLERUNNER"
	},
	{
			"value": "TABLES",
			"label": "TABLES"
	},
	{
			"value": "TABLE SETTING/NPK/PL",
			"label": "TABLE SETTING/NPK/PL"
	},
	{
			"value": "TABLE TOP",
			"label": "TABLE TOP"
	},
	{
			"value": "TABLETOP",
			"label": "TABLETOP"
	},
	{
			"value": "TABLETOP 4 IN A ROW",
			"label": "TABLETOP 4 IN A ROW"
	},
	{
			"value": "TABLETOP CORNHOLE",
			"label": "TABLETOP CORNHOLE"
	},
	{
			"value": "TABLE TOP DECOR",
			"label": "TABLE TOP DECOR"
	},
	{
			"value": "TABLETOP DECOR",
			"label": "TABLETOP DECOR"
	},
	{
			"value": "TABLETOP DICE",
			"label": "TABLETOP DICE"
	},
	{
			"value": "TABLETOP DOMINOS",
			"label": "TABLETOP DOMINOS"
	},
	{
			"value": "TABLETOP GAMES",
			"label": "TABLETOP GAMES"
	},
	{
			"value": "TABLETOP TIC TAC TOE",
			"label": "TABLETOP TIC TAC TOE"
	},
	{
			"value": "TABLETOP TREE",
			"label": "TABLETOP TREE"
	},
	{
			"value": "TABLETOP TREES",
			"label": "TABLETOP TREES"
	},
	{
			"value": "TABLE WITH MAG",
			"label": "TABLE WITH MAG"
	},
	{
			"value": "TABLE YELLOW",
			"label": "TABLE YELLOW"
	},
	{
			"value": "TACK",
			"label": "TACK"
	},
	{
			"value": "TAG",
			"label": "TAG"
	},
	{
			"value": "TAGS",
			"label": "TAGS"
	},
	{
			"value": "TAIL",
			"label": "TAIL"
	},
	{
			"value": "TAKEOUT",
			"label": "TAKEOUT"
	},
	{
			"value": "TAKE OUT BOX",
			"label": "TAKE OUT BOX"
	},
	{
			"value": "TAKEOUT BOX",
			"label": "TAKEOUT BOX"
	},
	{
			"value": "TAKEOUT BOXES",
			"label": "TAKEOUT BOXES"
	},
	{
			"value": "TALBECLOTH",
			"label": "TALBECLOTH"
	},
	{
			"value": "TALL CANNISTER",
			"label": "TALL CANNISTER"
	},
	{
			"value": "TALL PUMPKIN",
			"label": "TALL PUMPKIN"
	},
	{
			"value": "TALL STEM",
			"label": "TALL STEM"
	},
	{
			"value": "TALL TIN",
			"label": "TALL TIN"
	},
	{
			"value": "TAN",
			"label": "TAN"
	},
	{
			"value": "TANGERINE",
			"label": "TANGERINE"
	},
	{
			"value": "TANK",
			"label": "TANK"
	},
	{
			"value": "TANK TOP",
			"label": "TANK TOP"
	},
	{
			"value": "TAPE",
			"label": "TAPE"
	},
	{
			"value": "TAPE DISPENSER",
			"label": "TAPE DISPENSER"
	},
	{
			"value": "TAPER",
			"label": "TAPER"
	},
	{
			"value": "TAPER CANDLE",
			"label": "TAPER CANDLE"
	},
	{
			"value": "TAPER HOLDER",
			"label": "TAPER HOLDER"
	},
	{
			"value": "TAPERS",
			"label": "TAPERS"
	},
	{
			"value": "TAPESTRY BY CR GIBSO",
			"label": "TAPESTRY BY CR GIBSO"
	},
	{
			"value": "TARGET",
			"label": "TARGET"
	},
	{
			"value": "TAROT CARD",
			"label": "TAROT CARD"
	},
	{
			"value": "TAROT CARDS",
			"label": "TAROT CARDS"
	},
	{
			"value": "TARTAIN PLAID",
			"label": "TARTAIN PLAID"
	},
	{
			"value": "TARTAN",
			"label": "TARTAN"
	},
	{
			"value": "TARTAN COVERLET",
			"label": "TARTAN COVERLET"
	},
	{
			"value": "TARTAN PLAID",
			"label": "TARTAN PLAID"
	},
	{
			"value": "TASSEL",
			"label": "TASSEL"
	},
	{
			"value": "TASSEL FRINGE",
			"label": "TASSEL FRINGE"
	},
	{
			"value": "TASSELS",
			"label": "TASSELS"
	},
	{
			"value": "TATTERED ANGELS",
			"label": "TATTERED ANGELS"
	},
	{
			"value": "TATTERSALL PLAID",
			"label": "TATTERSALL PLAID"
	},
	{
			"value": "TATTOO",
			"label": "TATTOO"
	},
	{
			"value": "TATTOOS",
			"label": "TATTOOS"
	},
	{
			"value": "TATTOO SLEEVE",
			"label": "TATTOO SLEEVE"
	},
	{
			"value": "TAUNTON PRESS",
			"label": "TAUNTON PRESS"
	},
	{
			"value": "TBD",
			"label": "TBD"
	},
	{
			"value": "TC",
			"label": "TC"
	},
	{
			"value": "TCHSTB00",
			"label": "TCHSTB00"
	},
	{
			"value": "TCHSTC00",
			"label": "TCHSTC00"
	},
	{
			"value": "TCHSTE00",
			"label": "TCHSTE00"
	},
	{
			"value": "TCHSTG01",
			"label": "TCHSTG01"
	},
	{
			"value": "TDC GAMES",
			"label": "TDC GAMES"
	},
	{
			"value": "TEA",
			"label": "TEA"
	},
	{
			"value": "TEACHER",
			"label": "TEACHER"
	},
	{
			"value": "TEACHER POWER PANEL",
			"label": "TEACHER POWER PANEL"
	},
	{
			"value": "TEACHER RESOURCES",
			"label": "TEACHER RESOURCES"
	},
	{
			"value": "TEA CUP",
			"label": "TEA CUP"
	},
	{
			"value": "TEA CUP FEEDER",
			"label": "TEA CUP FEEDER"
	},
	{
			"value": "TEACUP FEEDER",
			"label": "TEACUP FEEDER"
	},
	{
			"value": "TEACUPS",
			"label": "TEACUPS"
	},
	{
			"value": "TEA DYED",
			"label": "TEA DYED"
	},
	{
			"value": "TEAL",
			"label": "TEAL"
	},
	{
			"value": "TEALIGHT",
			"label": "TEALIGHT"
	},
	{
			"value": "TEALIGHT 6PK",
			"label": "TEALIGHT 6PK"
	},
	{
			"value": "TEALIGHT CANDLE",
			"label": "TEALIGHT CANDLE"
	},
	{
			"value": "TEALIGHT CANDLES",
			"label": "TEALIGHT CANDLES"
	},
	{
			"value": "TEA LIGHT HOLDER",
			"label": "TEA LIGHT HOLDER"
	},
	{
			"value": "TEALIGHT HOLDER",
			"label": "TEALIGHT HOLDER"
	},
	{
			"value": "TEA LIGHTS",
			"label": "TEA LIGHTS"
	},
	{
			"value": "TEALIGHTS",
			"label": "TEALIGHTS"
	},
	{
			"value": "TEAL PRINCESS",
			"label": "TEAL PRINCESS"
	},
	{
			"value": "TEAL STRIPE",
			"label": "TEAL STRIPE"
	},
	{
			"value": "TEAL THROW",
			"label": "TEAL THROW"
	},
	{
			"value": "TEAM MISC PKGD",
			"label": "TEAM MISC PKGD"
	},
	{
			"value": "TEAM-NFL",
			"label": "TEAM-NFL"
	},
	{
			"value": "TEAM SHOP - MLB",
			"label": "TEAM SHOP - MLB"
	},
	{
			"value": "TEAM SHOP - NFL",
			"label": "TEAM SHOP - NFL"
	},
	{
			"value": "TEA PARTY ACCESSORY",
			"label": "TEA PARTY ACCESSORY"
	},
	{
			"value": "TEA POT",
			"label": "TEA POT"
	},
	{
			"value": "TEAPOT",
			"label": "TEAPOT"
	},
	{
			"value": "TEA POT FEEDER",
			"label": "TEA POT FEEDER"
	},
	{
			"value": "TEAPOT FEEDER",
			"label": "TEAPOT FEEDER"
	},
	{
			"value": "TEARDROP",
			"label": "TEARDROP"
	},
	{
			"value": "TEARDROP TULLE",
			"label": "TEARDROP TULLE"
	},
	{
			"value": "TEAR EASY",
			"label": "TEAR EASY"
	},
	{
			"value": "TEA TIME COLLECTION",
			"label": "TEA TIME COLLECTION"
	},
	{
			"value": "TEA TOWEL",
			"label": "TEA TOWEL"
	},
	{
			"value": "TECHNIQUE TUESDAY",
			"label": "TECHNIQUE TUESDAY"
	},
	{
			"value": "TECHSA00",
			"label": "TECHSA00"
	},
	{
			"value": "TECHSB00",
			"label": "TECHSB00"
	},
	{
			"value": "TEDDY BEARS",
			"label": "TEDDY BEARS"
	},
	{
			"value": "TEDDY FUR",
			"label": "TEDDY FUR"
	},
	{
			"value": "TEENAGE MUTANT NINJA",
			"label": "TEENAGE MUTANT NINJA"
	},
	{
			"value": "TEEN EDUCATION",
			"label": "TEEN EDUCATION"
	},
	{
			"value": "TEENY TY",
			"label": "TEENY TY"
	},
	{
			"value": "TEEPEE",
			"label": "TEEPEE"
	},
	{
			"value": "TEES",
			"label": "TEES"
	},
	{
			"value": "TEETH",
			"label": "TEETH"
	},
	{
			"value": "TEMPERATURE GAUGE",
			"label": "TEMPERATURE GAUGE"
	},
	{
			"value": "TEMPLATE",
			"label": "TEMPLATE"
	},
	{
			"value": "TEMPO BOWL",
			"label": "TEMPO BOWL"
	},
	{
			"value": "TENDRIL",
			"label": "TENDRIL"
	},
	{
			"value": "TENNESSEE",
			"label": "TENNESSEE"
	},
	{
			"value": "TENNIS BALLS",
			"label": "TENNIS BALLS"
	},
	{
			"value": "TEN SECONDS STUDIO",
			"label": "TEN SECONDS STUDIO"
	},
	{
			"value": "TERESA COLLINS",
			"label": "TERESA COLLINS"
	},
	{
			"value": "TERESA COLLINS STAMP",
			"label": "TERESA COLLINS STAMP"
	},
	{
			"value": "TERRA COTTA",
			"label": "TERRA COTTA"
	},
	{
			"value": "TERRACOTTA",
			"label": "TERRACOTTA"
	},
	{
			"value": "TERRARIUM",
			"label": "TERRARIUM"
	},
	{
			"value": "TERRARIUM HANGING",
			"label": "TERRARIUM HANGING"
	},
	{
			"value": "TERRARIUM KIT",
			"label": "TERRARIUM KIT"
	},
	{
			"value": "TERRARIUMS",
			"label": "TERRARIUMS"
	},
	{
			"value": "TERRY",
			"label": "TERRY"
	},
	{
			"value": "TERRY FLORAL",
			"label": "TERRY FLORAL"
	},
	{
			"value": "TERRY TOWEL",
			"label": "TERRY TOWEL"
	},
	{
			"value": "TESTER",
			"label": "TESTER"
	},
	{
			"value": "TEXAS",
			"label": "TEXAS"
	},
	{
			"value": "TEXAS A&M",
			"label": "TEXAS A&M"
	},
	{
			"value": "TEXAS TECH",
			"label": "TEXAS TECH"
	},
	{
			"value": "TEXTILE",
			"label": "TEXTILE"
	},
	{
			"value": "TEXTILES",
			"label": "TEXTILES"
	},
	{
			"value": "TEXTURE",
			"label": "TEXTURE"
	},
	{
			"value": "TEXTURED",
			"label": "TEXTURED"
	},
	{
			"value": "TEXTURED JAR",
			"label": "TEXTURED JAR"
	},
	{
			"value": "TEXTURED PERM VINYL",
			"label": "TEXTURED PERM VINYL"
	},
	{
			"value": "TEXTURED PILLOW",
			"label": "TEXTURED PILLOW"
	},
	{
			"value": "TEXTURED PLEATHER",
			"label": "TEXTURED PLEATHER"
	},
	{
			"value": "TEXTURED RIM",
			"label": "TEXTURED RIM"
	},
	{
			"value": "TEXTURED SOLID",
			"label": "TEXTURED SOLID"
	},
	{
			"value": "TEXTURED SOLIDS",
			"label": "TEXTURED SOLIDS"
	},
	{
			"value": "TEXTURED THROW",
			"label": "TEXTURED THROW"
	},
	{
			"value": "TEXTURE FOAM 9X12",
			"label": "TEXTURE FOAM 9X12"
	},
	{
			"value": "TEXTURE PILLAR",
			"label": "TEXTURE PILLAR"
	},
	{
			"value": "TEXTURE PILLOW",
			"label": "TEXTURE PILLOW"
	},
	{
			"value": "TEXTURES",
			"label": "TEXTURES"
	},
	{
			"value": "TEXTURE SOLID",
			"label": "TEXTURE SOLID"
	},
	{
			"value": "TEXTURE THROW",
			"label": "TEXTURE THROW"
	},
	{
			"value": "THANK YOU",
			"label": "THANK YOU"
	},
	{
			"value": "THE BEADERY",
			"label": "THE BEADERY"
	},
	{
			"value": "THE BEAD SHOP",
			"label": "THE BEAD SHOP"
	},
	{
			"value": "THE LION KING",
			"label": "THE LION KING"
	},
	{
			"value": "THEME",
			"label": "THEME"
	},
	{
			"value": "THEME 2 CANDLE",
			"label": "THEME 2 CANDLE"
	},
	{
			"value": "THEME 2 CANDLE HOLDE",
			"label": "THEME 2 CANDLE HOLDE"
	},
	{
			"value": "THEME BAKING CUPS",
			"label": "THEME BAKING CUPS"
	},
	{
			"value": "THEMED BAKING CUPS",
			"label": "THEMED BAKING CUPS"
	},
	{
			"value": "THEMED DECORATION",
			"label": "THEMED DECORATION"
	},
	{
			"value": "THEMED ORNAMENTS",
			"label": "THEMED ORNAMENTS"
	},
	{
			"value": "THEMED SHATTERPROOF",
			"label": "THEMED SHATTERPROOF"
	},
	{
			"value": "THEMED SPRINKLES",
			"label": "THEMED SPRINKLES"
	},
	{
			"value": "THEMED WRAP",
			"label": "THEMED WRAP"
	},
	{
			"value": "THEME MOLDS",
			"label": "THEME MOLDS"
	},
	{
			"value": "THEME PANS",
			"label": "THEME PANS"
	},
	{
			"value": "THE NATIONAL NEEDLEA",
			"label": "THE NATIONAL NEEDLEA"
	},
	{
			"value": "THE NEW IMAGE GROUP",
			"label": "THE NEW IMAGE GROUP"
	},
	{
			"value": "THE OFFICE",
			"label": "THE OFFICE"
	},
	{
			"value": "THE RIDE",
			"label": "THE RIDE"
	},
	{
			"value": "THERMAL",
			"label": "THERMAL"
	},
	{
			"value": "THERMAL LAMINATING",
			"label": "THERMAL LAMINATING"
	},
	{
			"value": "THERMOMETER",
			"label": "THERMOMETER"
	},
	{
			"value": "THERMOWEB",
			"label": "THERMOWEB"
	},
	{
			"value": "THE SUN",
			"label": "THE SUN"
	},
	{
			"value": "THE WITCHING HOUR CA",
			"label": "THE WITCHING HOUR CA"
	},
	{
			"value": "THE WITCHING HOUR PA",
			"label": "THE WITCHING HOUR PA"
	},
	{
			"value": "THICK FOAM 9X12",
			"label": "THICK FOAM 9X12"
	},
	{
			"value": "THIMBLE",
			"label": "THIMBLE"
	},
	{
			"value": "THING TOWEL",
			"label": "THING TOWEL"
	},
	{
			"value": "THINKING SKILLS",
			"label": "THINKING SKILLS"
	},
	{
			"value": "THIS AND THAT",
			"label": "THIS AND THAT"
	},
	{
			"value": "THISTLE",
			"label": "THISTLE"
	},
	{
			"value": "THISTLE EVA",
			"label": "THISTLE EVA"
	},
	{
			"value": "THOMAS",
			"label": "THOMAS"
	},
	{
			"value": "THOMAS KINKADE CALEN",
			"label": "THOMAS KINKADE CALEN"
	},
	{
			"value": "THOMAS THE TRAIN",
			"label": "THOMAS THE TRAIN"
	},
	{
			"value": "THOMASVILLE",
			"label": "THOMASVILLE"
	},
	{
			"value": "THREAD",
			"label": "THREAD"
	},
	{
			"value": "THREAD BOBBONS",
			"label": "THREAD BOBBONS"
	},
	{
			"value": "THREADER",
			"label": "THREADER"
	},
	{
			"value": "THROW",
			"label": "THROW"
	},
	{
			"value": "THROWBACK CARTOONS",
			"label": "THROWBACK CARTOONS"
	},
	{
			"value": "THROWBACK MOVIES",
			"label": "THROWBACK MOVIES"
	},
	{
			"value": "THROWBACK TV",
			"label": "THROWBACK TV"
	},
	{
			"value": "THROW BUDDY",
			"label": "THROW BUDDY"
	},
	{
			"value": "THROW PROGRAM",
			"label": "THROW PROGRAM"
	},
	{
			"value": "THROWS",
			"label": "THROWS"
	},
	{
			"value": "THYME",
			"label": "THYME"
	},
	{
			"value": "TIARA",
			"label": "TIARA"
	},
	{
			"value": "TICKING",
			"label": "TICKING"
	},
	{
			"value": "TIDE POOLS",
			"label": "TIDE POOLS"
	},
	{
			"value": "TIEBACK",
			"label": "TIEBACK"
	},
	{
			"value": "TIE -DYE",
			"label": "TIE -DYE"
	},
	{
			"value": "TIE DYE",
			"label": "TIE DYE"
	},
	{
			"value": "TIE-DYE",
			"label": "TIE-DYE"
	},
	{
			"value": "TIEDYE",
			"label": "TIEDYE"
	},
	{
			"value": "TIE DYE/CAMO/ANIMAL",
			"label": "TIE DYE/CAMO/ANIMAL"
	},
	{
			"value": "TIE DYE/CAMO/SKINS",
			"label": "TIE DYE/CAMO/SKINS"
	},
	{
			"value": "TIE DYE KIT",
			"label": "TIE DYE KIT"
	},
	{
			"value": "TIE LXL",
			"label": "TIE LXL"
	},
	{
			"value": "TIER HOLDER",
			"label": "TIER HOLDER"
	},
	{
			"value": "TIE SMMED",
			"label": "TIE SMMED"
	},
	{
			"value": "TIGER",
			"label": "TIGER"
	},
	{
			"value": "TIGER FRAME",
			"label": "TIGER FRAME"
	},
	{
			"value": "TIGER HOOK",
			"label": "TIGER HOOK"
	},
	{
			"value": "TIGER LEAVES",
			"label": "TIGER LEAVES"
	},
	{
			"value": "TIGER LILY",
			"label": "TIGER LILY"
	},
	{
			"value": "TIGER TOWEL",
			"label": "TIGER TOWEL"
	},
	{
			"value": "TIGHTS",
			"label": "TIGHTS"
	},
	{
			"value": "TIKI TORCH",
			"label": "TIKI TORCH"
	},
	{
			"value": "TIKI TORCHES",
			"label": "TIKI TORCHES"
	},
	{
			"value": "TILE",
			"label": "TILE"
	},
	{
			"value": "TILES",
			"label": "TILES"
	},
	{
			"value": "TILLANDSIA",
			"label": "TILLANDSIA"
	},
	{
			"value": "TIM COFFEY",
			"label": "TIM COFFEY"
	},
	{
			"value": "TIME",
			"label": "TIME"
	},
	{
			"value": "TIMELESS TOTES",
			"label": "TIMELESS TOTES"
	},
	{
			"value": "TIMER",
			"label": "TIMER"
	},
	{
			"value": "TIME TO CELEBRATE",
			"label": "TIME TO CELEBRATE"
	},
	{
			"value": "TIM HOLTZ",
			"label": "TIM HOLTZ"
	},
	{
			"value": "TIN",
			"label": "TIN"
	},
	{
			"value": "TIN BASKET",
			"label": "TIN BASKET"
	},
	{
			"value": "TIN CANDLE",
			"label": "TIN CANDLE"
	},
	{
			"value": "TING",
			"label": "TING"
	},
	{
			"value": "TING SPRAY",
			"label": "TING SPRAY"
	},
	{
			"value": "TINKERBELL",
			"label": "TINKERBELL"
	},
	{
			"value": "TIN LINER",
			"label": "TIN LINER"
	},
	{
			"value": "TIN PLANTER",
			"label": "TIN PLANTER"
	},
	{
			"value": "TINS",
			"label": "TINS"
	},
	{
			"value": "TINSEL",
			"label": "TINSEL"
	},
	{
			"value": "TINSEL BALLS",
			"label": "TINSEL BALLS"
	},
	{
			"value": "TINSEL GARLAND",
			"label": "TINSEL GARLAND"
	},
	{
			"value": "TINSEL MINI TREE",
			"label": "TINSEL MINI TREE"
	},
	{
			"value": "TINSEL POP UP TREE",
			"label": "TINSEL POP UP TREE"
	},
	{
			"value": "TINSEL RIBBON",
			"label": "TINSEL RIBBON"
	},
	{
			"value": "TINSEL STEM",
			"label": "TINSEL STEM"
	},
	{
			"value": "TINSEL TREE",
			"label": "TINSEL TREE"
	},
	{
			"value": "TINSEL WREATH",
			"label": "TINSEL WREATH"
	},
	{
			"value": "TIN STEEL",
			"label": "TIN STEEL"
	},
	{
			"value": "TINTED",
			"label": "TINTED"
	},
	{
			"value": "TINTED VINYL",
			"label": "TINTED VINYL"
	},
	{
			"value": "TIN TRAY",
			"label": "TIN TRAY"
	},
	{
			"value": "TIN WATERING CAN",
			"label": "TIN WATERING CAN"
	},
	{
			"value": "TINY ANIMALS",
			"label": "TINY ANIMALS"
	},
	{
			"value": "TINY WREATH",
			"label": "TINY WREATH"
	},
	{
			"value": "TIP LUMBAR",
			"label": "TIP LUMBAR"
	},
	{
			"value": "TIPS",
			"label": "TIPS"
	},
	{
			"value": "TIP TOWEL",
			"label": "TIP TOWEL"
	},
	{
			"value": "TISSUE",
			"label": "TISSUE"
	},
	{
			"value": "TISSUE LINEN",
			"label": "TISSUE LINEN"
	},
	{
			"value": "TISSUE PAPER",
			"label": "TISSUE PAPER"
	},
	{
			"value": "TJ DESIGNS",
			"label": "TJ DESIGNS"
	},
	{
			"value": "TLFPPA00",
			"label": "TLFPPA00"
	},
	{
			"value": "TLP SFT PNT 2OZ-GLOW",
			"label": "TLP SFT PNT 2OZ-GLOW"
	},
	{
			"value": "TLP SFT PNT 2OZ-GLTR",
			"label": "TLP SFT PNT 2OZ-GLTR"
	},
	{
			"value": "TLP SFT PNT 2OZ-MATE",
			"label": "TLP SFT PNT 2OZ-MATE"
	},
	{
			"value": "TLP SFT PNT 2OZ-MTLC",
			"label": "TLP SFT PNT 2OZ-MTLC"
	},
	{
			"value": "TLP SFT PNT 4OZ-GLOW",
			"label": "TLP SFT PNT 4OZ-GLOW"
	},
	{
			"value": "TLP SFT PNT 4OZ-MATE",
			"label": "TLP SFT PNT 4OZ-MATE"
	},
	{
			"value": "TLP SFT PNT 4OZ-NEON",
			"label": "TLP SFT PNT 4OZ-NEON"
	},
	{
			"value": "TLP-SFT PNT MULTI PK",
			"label": "TLP-SFT PNT MULTI PK"
	},
	{
			"value": "TLP SPRAY ON PNT KT",
			"label": "TLP SPRAY ON PNT KT"
	},
	{
			"value": "TMNT",
			"label": "TMNT"
	},
	{
			"value": "TOASTY COTTON",
			"label": "TOASTY COTTON"
	},
	{
			"value": "TOBIN",
			"label": "TOBIN"
	},
	{
			"value": "TODAY TOWEL",
			"label": "TODAY TOWEL"
	},
	{
			"value": "TODDLER",
			"label": "TODDLER"
	},
	{
			"value": "TODDLER STICKER COLO",
			"label": "TODDLER STICKER COLO"
	},
	{
			"value": "TODDLER STICKERS COU",
			"label": "TODDLER STICKERS COU"
	},
	{
			"value": "TODDLER STICKER WORK",
			"label": "TODDLER STICKER WORK"
	},
	{
			"value": "TODDLER TRACING LETT",
			"label": "TODDLER TRACING LETT"
	},
	{
			"value": "TODDLER TRACING NUMB",
			"label": "TODDLER TRACING NUMB"
	},
	{
			"value": "TODDLER TRACING WORK",
			"label": "TODDLER TRACING WORK"
	},
	{
			"value": "TOE SOCKS",
			"label": "TOE SOCKS"
	},
	{
			"value": "TOGETHER TIME",
			"label": "TOGETHER TIME"
	},
	{
			"value": "TOGGLE CLASP",
			"label": "TOGGLE CLASP"
	},
	{
			"value": "TOILE",
			"label": "TOILE"
	},
	{
			"value": "TOILES",
			"label": "TOILES"
	},
	{
			"value": "TOILLE",
			"label": "TOILLE"
	},
	{
			"value": "TOMBOW",
			"label": "TOMBOW"
	},
	{
			"value": "TOMBSTONE",
			"label": "TOMBSTONE"
	},
	{
			"value": "TOMMY BAHAMA",
			"label": "TOMMY BAHAMA"
	},
	{
			"value": "TONAL",
			"label": "TONAL"
	},
	{
			"value": "TONALS",
			"label": "TONALS"
	},
	{
			"value": "TONER",
			"label": "TONER"
	},
	{
			"value": "TONIC STUDIOS",
			"label": "TONIC STUDIOS"
	},
	{
			"value": "TOOL",
			"label": "TOOL"
	},
	{
			"value": "TOOL CADDY",
			"label": "TOOL CADDY"
	},
	{
			"value": "TOOL CARRIER",
			"label": "TOOL CARRIER"
	},
	{
			"value": "TOOL KIT",
			"label": "TOOL KIT"
	},
	{
			"value": "TOOLS",
			"label": "TOOLS"
	},
	{
			"value": "TOOLS AND TRAYS",
			"label": "TOOLS AND TRAYS"
	},
	{
			"value": "TOOL SET",
			"label": "TOOL SET"
	},
	{
			"value": "TOOL-SET",
			"label": "TOOL-SET"
	},
	{
			"value": "TOOLS/UTENSILS",
			"label": "TOOLS/UTENSILS"
	},
	{
			"value": "TOP",
			"label": "TOP"
	},
	{
			"value": "TOP 2 PK",
			"label": "TOP 2 PK"
	},
	{
			"value": "TOP DRAWER",
			"label": "TOP DRAWER"
	},
	{
			"value": "TOP GRY",
			"label": "TOP GRY"
	},
	{
			"value": "TOPIARY",
			"label": "TOPIARY"
	},
	{
			"value": "TOPIARY TREE",
			"label": "TOPIARY TREE"
	},
	{
			"value": "TOPPER",
			"label": "TOPPER"
	},
	{
			"value": "TOPPERS",
			"label": "TOPPERS"
	},
	{
			"value": "TOPPLING TOWER",
			"label": "TOPPLING TOWER"
	},
	{
			"value": "TOPS",
			"label": "TOPS"
	},
	{
			"value": "TOP SEASHELL",
			"label": "TOP SEASHELL"
	},
	{
			"value": "TOP TREATMENTS",
			"label": "TOP TREATMENTS"
	},
	{
			"value": "TOP / VEST",
			"label": "TOP / VEST"
	},
	{
			"value": "TORSO",
			"label": "TORSO"
	},
	{
			"value": "TOSS PATTERN",
			"label": "TOSS PATTERN"
	},
	{
			"value": "TOTE",
			"label": "TOTE"
	},
	{
			"value": "TOTE BAG",
			"label": "TOTE BAG"
	},
	{
			"value": "TOUCAN TOWEL",
			"label": "TOUCAN TOWEL"
	},
	{
			"value": "TOWEL",
			"label": "TOWEL"
	},
	{
			"value": "TOWEL ACC",
			"label": "TOWEL ACC"
	},
	{
			"value": "TOWEL ET",
			"label": "TOWEL ET"
	},
	{
			"value": "TOWEL FT",
			"label": "TOWEL FT"
	},
	{
			"value": "TOWEL GAU",
			"label": "TOWEL GAU"
	},
	{
			"value": "TOWEL GRANNY",
			"label": "TOWEL GRANNY"
	},
	{
			"value": "TOWEL HOLDER",
			"label": "TOWEL HOLDER"
	},
	{
			"value": "TOWEL KITCHY",
			"label": "TOWEL KITCHY"
	},
	{
			"value": "TOWEL LINEN",
			"label": "TOWEL LINEN"
	},
	{
			"value": "TOWEL MITT SET",
			"label": "TOWEL MITT SET"
	},
	{
			"value": "TOWEL PACK",
			"label": "TOWEL PACK"
	},
	{
			"value": "TOWELS",
			"label": "TOWELS"
	},
	{
			"value": "TOWELS 2PK",
			"label": "TOWELS 2PK"
	},
	{
			"value": "TOWEL SET",
			"label": "TOWEL SET"
	},
	{
			"value": "TOWEL SJ",
			"label": "TOWEL SJ"
	},
	{
			"value": "TOWEL TIP",
			"label": "TOWEL TIP"
	},
	{
			"value": "TOWEL VT",
			"label": "TOWEL VT"
	},
	{
			"value": "TOWEL YDT",
			"label": "TOWEL YDT"
	},
	{
			"value": "TOWER",
			"label": "TOWER"
	},
	{
			"value": "TOWERA00",
			"label": "TOWERA00"
	},
	{
			"value": "TOY",
			"label": "TOY"
	},
	{
			"value": "TOY -2PK",
			"label": "TOY -2PK"
	},
	{
			"value": "TOY-2PK",
			"label": "TOY-2PK"
	},
	{
			"value": "TOY BIRD",
			"label": "TOY BIRD"
	},
	{
			"value": "TOY HEDGEHOG",
			"label": "TOY HEDGEHOG"
	},
	{
			"value": "TOY ICECREAM",
			"label": "TOY ICECREAM"
	},
	{
			"value": "TOYS",
			"label": "TOYS"
	},
	{
			"value": "TOY STORY",
			"label": "TOY STORY"
	},
	{
			"value": "TOY WATERMELON",
			"label": "TOY WATERMELON"
	},
	{
			"value": "TRACING",
			"label": "TRACING"
	},
	{
			"value": "TRACING PAD",
			"label": "TRACING PAD"
	},
	{
			"value": "TRAD CARDS",
			"label": "TRAD CARDS"
	},
	{
			"value": "TRADITIONAL",
			"label": "TRADITIONAL"
	},
	{
			"value": "TRADITIONAL ACCESSOR",
			"label": "TRADITIONAL ACCESSOR"
	},
	{
			"value": "TRADITIONAL BATTING",
			"label": "TRADITIONAL BATTING"
	},
	{
			"value": "TRADITIONAL BIRD",
			"label": "TRADITIONAL BIRD"
	},
	{
			"value": "TRADITIONAL BIRDHOUS",
			"label": "TRADITIONAL BIRDHOUS"
	},
	{
			"value": "TRADITIONAL FLORAL",
			"label": "TRADITIONAL FLORAL"
	},
	{
			"value": "TRADITIONAL MANUSCRI",
			"label": "TRADITIONAL MANUSCRI"
	},
	{
			"value": "TRADITIONAL WRAP",
			"label": "TRADITIONAL WRAP"
	},
	{
			"value": "TRADITONAL",
			"label": "TRADITONAL"
	},
	{
			"value": "TRAFALGAR SQUARE BOO",
			"label": "TRAFALGAR SQUARE BOO"
	},
	{
			"value": "TRAILER",
			"label": "TRAILER"
	},
	{
			"value": "TRAILER BIRDHOUSE",
			"label": "TRAILER BIRDHOUSE"
	},
	{
			"value": "TRAINS",
			"label": "TRAINS"
	},
	{
			"value": "TRAIN SHAPED",
			"label": "TRAIN SHAPED"
	},
	{
			"value": "TRANSFER MAGIC",
			"label": "TRANSFER MAGIC"
	},
	{
			"value": "TRANSFER PAPER",
			"label": "TRANSFER PAPER"
	},
	{
			"value": "TRANSFERS",
			"label": "TRANSFERS"
	},
	{
			"value": "TRANSFER TAPE",
			"label": "TRANSFER TAPE"
	},
	{
			"value": "TRANSPARENT",
			"label": "TRANSPARENT"
	},
	{
			"value": "TRANSPARENT TAPE",
			"label": "TRANSPARENT TAPE"
	},
	{
			"value": "TRANSPORT",
			"label": "TRANSPORT"
	},
	{
			"value": "TRANSPORTAION",
			"label": "TRANSPORTAION"
	},
	{
			"value": "TRANSPORTATION",
			"label": "TRANSPORTATION"
	},
	{
			"value": "TRAVEL",
			"label": "TRAVEL"
	},
	{
			"value": "TRAVEL CUP",
			"label": "TRAVEL CUP"
	},
	{
			"value": "TRAVEL CUPS",
			"label": "TRAVEL CUPS"
	},
	{
			"value": "TRAVEL CUP SET",
			"label": "TRAVEL CUP SET"
	},
	{
			"value": "TRAVELER TREE",
			"label": "TRAVELER TREE"
	},
	{
			"value": "TRAVEL/OCCUPATION",
			"label": "TRAVEL/OCCUPATION"
	},
	{
			"value": "TRAVEL & VACATION",
			"label": "TRAVEL & VACATION"
	},
	{
			"value": "TRAY",
			"label": "TRAY"
	},
	{
			"value": "TRAYS",
			"label": "TRAYS"
	},
	{
			"value": "TRBAS09",
			"label": "TRBAS09"
	},
	{
			"value": "TRBASA00",
			"label": "TRBASA00"
	},
	{
			"value": "TRBASC00",
			"label": "TRBASC00"
	},
	{
			"value": "TRBASE00",
			"label": "TRBASE00"
	},
	{
			"value": "TREASURE CHEST",
			"label": "TREASURE CHEST"
	},
	{
			"value": "TREAT",
			"label": "TREAT"
	},
	{
			"value": "TREAT BAG",
			"label": "TREAT BAG"
	},
	{
			"value": "TREAT BAGS",
			"label": "TREAT BAGS"
	},
	{
			"value": "TREAT BOX",
			"label": "TREAT BOX"
	},
	{
			"value": "TREAT BOXES",
			"label": "TREAT BOXES"
	},
	{
			"value": "TREAT CUPS",
			"label": "TREAT CUPS"
	},
	{
			"value": "TREAT FILLING",
			"label": "TREAT FILLING"
	},
	{
			"value": "TREAT MOLD",
			"label": "TREAT MOLD"
	},
	{
			"value": "TREAT SACK",
			"label": "TREAT SACK"
	},
	{
			"value": "TREAT STICK",
			"label": "TREAT STICK"
	},
	{
			"value": "TREAT STICKS",
			"label": "TREAT STICKS"
	},
	{
			"value": "TREAT TINS",
			"label": "TREAT TINS"
	},
	{
			"value": "TREE",
			"label": "TREE"
	},
	{
			"value": "TREE BELL",
			"label": "TREE BELL"
	},
	{
			"value": "TREE COLLAR",
			"label": "TREE COLLAR"
	},
	{
			"value": "TREE DECOR",
			"label": "TREE DECOR"
	},
	{
			"value": "TREE DECORATING CLIP",
			"label": "TREE DECORATING CLIP"
	},
	{
			"value": "TREE DECOR NEW",
			"label": "TREE DECOR NEW"
	},
	{
			"value": "TREE HOUSE",
			"label": "TREE HOUSE"
	},
	{
			"value": "TREE PILLOW",
			"label": "TREE PILLOW"
	},
	{
			"value": "TREE PLNTR",
			"label": "TREE PLNTR"
	},
	{
			"value": "TREES",
			"label": "TREES"
	},
	{
			"value": "TREE SKIRT",
			"label": "TREE SKIRT"
	},
	{
			"value": "TREESKIRT",
			"label": "TREESKIRT"
	},
	{
			"value": "TREE TABLE DECOR",
			"label": "TREE TABLE DECOR"
	},
	{
			"value": "TREE TABLE TOP",
			"label": "TREE TABLE TOP"
	},
	{
			"value": "TREE TABLETOP",
			"label": "TREE TABLETOP"
	},
	{
			"value": "TREE TOPPER",
			"label": "TREE TOPPER"
	},
	{
			"value": "TREE VALUE PACK",
			"label": "TREE VALUE PACK"
	},
	{
			"value": "TRELLIS",
			"label": "TRELLIS"
	},
	{
			"value": "TREND",
			"label": "TREND"
	},
	{
			"value": "TREND NEEDLEPOINT",
			"label": "TREND NEEDLEPOINT"
	},
	{
			"value": "TRENDS INTERNATIONAL",
			"label": "TRENDS INTERNATIONAL"
	},
	{
			"value": "TREND WRAP",
			"label": "TREND WRAP"
	},
	{
			"value": "TRIANGLE L&S",
			"label": "TRIANGLE L&S"
	},
	{
			"value": "TRIBAL",
			"label": "TRIBAL"
	},
	{
			"value": "TRIBE VIBE ANIMAL",
			"label": "TRIBE VIBE ANIMAL"
	},
	{
			"value": "TRIBE VIBE SOUTHWEST",
			"label": "TRIBE VIBE SOUTHWEST"
	},
	{
			"value": "TRIBE VIBE WOODLAND",
			"label": "TRIBE VIBE WOODLAND"
	},
	{
			"value": "TRICK OR TREAT BAG",
			"label": "TRICK OR TREAT BAG"
	},
	{
			"value": "TRI COLOR PLAID",
			"label": "TRI COLOR PLAID"
	},
	{
			"value": "TRI FOLD FOAM BOARD",
			"label": "TRI FOLD FOAM BOARD"
	},
	{
			"value": "TRIM",
			"label": "TRIM"
	},
	{
			"value": "TRIMCRAFT",
			"label": "TRIMCRAFT"
	},
	{
			"value": "TRIMMER",
			"label": "TRIMMER"
	},
	{
			"value": "TRIMMER BLADES",
			"label": "TRIMMER BLADES"
	},
	{
			"value": "TRIM PILLOW",
			"label": "TRIM PILLOW"
	},
	{
			"value": "TRIM TRAY",
			"label": "TRIM TRAY"
	},
	{
			"value": "TRINKET BOX",
			"label": "TRINKET BOX"
	},
	{
			"value": "TRINKET DISH",
			"label": "TRINKET DISH"
	},
	{
			"value": "TRINKET TRAY",
			"label": "TRINKET TRAY"
	},
	{
			"value": "TRIVET",
			"label": "TRIVET"
	},
	{
			"value": "TRIVETS",
			"label": "TRIVETS"
	},
	{
			"value": "TROLLS",
			"label": "TROLLS"
	},
	{
			"value": "TROP CANNA",
			"label": "TROP CANNA"
	},
	{
			"value": "TROPICAL",
			"label": "TROPICAL"
	},
	{
			"value": "TROPICAL & BEACH",
			"label": "TROPICAL & BEACH"
	},
	{
			"value": "TROPICAL/BEACH/NAUTI",
			"label": "TROPICAL/BEACH/NAUTI"
	},
	{
			"value": "TROPICAL FLORAL",
			"label": "TROPICAL FLORAL"
	},
	{
			"value": "TROPICAL FLOWERS",
			"label": "TROPICAL FLOWERS"
	},
	{
			"value": "TROPICAL PUNCH",
			"label": "TROPICAL PUNCH"
	},
	{
			"value": "TROPICAL SHIRTING",
			"label": "TROPICAL SHIRTING"
	},
	{
			"value": "TROUGH PLANTER",
			"label": "TROUGH PLANTER"
	},
	{
			"value": "TRUCK",
			"label": "TRUCK"
	},
	{
			"value": "TRUCKS",
			"label": "TRUCKS"
	},
	{
			"value": "TRUCKS & BIKES",
			"label": "TRUCKS & BIKES"
	},
	{
			"value": "TRUE CONTROL",
			"label": "TRUE CONTROL"
	},
	{
			"value": "TRUE TIMBER",
			"label": "TRUE TIMBER"
	},
	{
			"value": "TRUFFLE",
			"label": "TRUFFLE"
	},
	{
			"value": "TRUMPET FLOWER",
			"label": "TRUMPET FLOWER"
	},
	{
			"value": "TRUNK",
			"label": "TRUNK"
	},
	{
			"value": "TRYCYCLE",
			"label": "TRYCYCLE"
	},
	{
			"value": "TSHIRT BLANK",
			"label": "TSHIRT BLANK"
	},
	{
			"value": "TSUGA HEMLOCK",
			"label": "TSUGA HEMLOCK"
	},
	{
			"value": "TSUKINEKO",
			"label": "TSUKINEKO"
	},
	{
			"value": "TSUM TSUM",
			"label": "TSUM TSUM"
	},
	{
			"value": "TT DECOR",
			"label": "TT DECOR"
	},
	{
			"value": "TT FRAME NEW POG",
			"label": "TT FRAME NEW POG"
	},
	{
			"value": "TT FRAME POG FW22",
			"label": "TT FRAME POG FW22"
	},
	{
			"value": "TT FRAMES NEW POG",
			"label": "TT FRAMES NEW POG"
	},
	{
			"value": "TT FRAMES POG FW 22",
			"label": "TT FRAMES POG FW 22"
	},
	{
			"value": "TT FRAMES POG FW22",
			"label": "TT FRAMES POG FW22"
	},
	{
			"value": "TT HOURGLASS",
			"label": "TT HOURGLASS"
	},
	{
			"value": "TUB",
			"label": "TUB"
	},
	{
			"value": "TUBE LIGHTS BASIC",
			"label": "TUBE LIGHTS BASIC"
	},
	{
			"value": "TUBE LIGHTS NOVELTY",
			"label": "TUBE LIGHTS NOVELTY"
	},
	{
			"value": "TUBES",
			"label": "TUBES"
	},
	{
			"value": "TUBING",
			"label": "TUBING"
	},
	{
			"value": "TUCAN PLATE",
			"label": "TUCAN PLATE"
	},
	{
			"value": "TUFTED RUG",
			"label": "TUFTED RUG"
	},
	{
			"value": "TUILIP",
			"label": "TUILIP"
	},
	{
			"value": "TULIP",
			"label": "TULIP"
	},
	{
			"value": "TULIP 1.25OZ GLOW PT",
			"label": "TULIP 1.25OZ GLOW PT"
	},
	{
			"value": "TULIP 1.25OZ GLTR PT",
			"label": "TULIP 1.25OZ GLTR PT"
	},
	{
			"value": "TULIP 1.25OZ MTLC PT",
			"label": "TULIP 1.25OZ MTLC PT"
	},
	{
			"value": "TULIP 1.25OZ PUFY PT",
			"label": "TULIP 1.25OZ PUFY PT"
	},
	{
			"value": "TULIP 1.25OZ SLCK PT",
			"label": "TULIP 1.25OZ SLCK PT"
	},
	{
			"value": "TULIP 4OZ GLITR PT",
			"label": "TULIP 4OZ GLITR PT"
	},
	{
			"value": "TULIP 4OZ GLOW PAINT",
			"label": "TULIP 4OZ GLOW PAINT"
	},
	{
			"value": "TULIP 4OZ MATTE PNT",
			"label": "TULIP 4OZ MATTE PNT"
	},
	{
			"value": "TULIP 4OZ METALC PNT",
			"label": "TULIP 4OZ METALC PNT"
	},
	{
			"value": "TULIP 4OZ NEON PAINT",
			"label": "TULIP 4OZ NEON PAINT"
	},
	{
			"value": "TULIP 4OZ PUFFY PNT",
			"label": "TULIP 4OZ PUFFY PNT"
	},
	{
			"value": "TULIP 4OZ SLICK PNT",
			"label": "TULIP 4OZ SLICK PNT"
	},
	{
			"value": "TULIP 4OZ SOFT PAINT",
			"label": "TULIP 4OZ SOFT PAINT"
	},
	{
			"value": "TULIP 5 DYE KIT",
			"label": "TULIP 5 DYE KIT"
	},
	{
			"value": "TULIP- ADHESIVES",
			"label": "TULIP- ADHESIVES"
	},
	{
			"value": "TULIP COLOR SHOT",
			"label": "TULIP COLOR SHOT"
	},
	{
			"value": "TULIP CRAYONS",
			"label": "TULIP CRAYONS"
	},
	{
			"value": "TULIP- CRAYONS",
			"label": "TULIP- CRAYONS"
	},
	{
			"value": "TULIP CS UPHLST SPRY",
			"label": "TULIP CS UPHLST SPRY"
	},
	{
			"value": "TULIP- DFP KITS",
			"label": "TULIP- DFP KITS"
	},
	{
			"value": "TULIP-DFP KITS",
			"label": "TULIP-DFP KITS"
	},
	{
			"value": "TULIP FIELD",
			"label": "TULIP FIELD"
	},
	{
			"value": "TULIP- GLITTER",
			"label": "TULIP- GLITTER"
	},
	{
			"value": "TULIP- GLTR PNT",
			"label": "TULIP- GLTR PNT"
	},
	{
			"value": "TULIP- HOT DYE",
			"label": "TULIP- HOT DYE"
	},
	{
			"value": "TULIP- MARKERS",
			"label": "TULIP- MARKERS"
	},
	{
			"value": "TULIP- MIXED FAMILY",
			"label": "TULIP- MIXED FAMILY"
	},
	{
			"value": "TULIP- ONE STEP DYE",
			"label": "TULIP- ONE STEP DYE"
	},
	{
			"value": "TULIP SOCKS",
			"label": "TULIP SOCKS"
	},
	{
			"value": "TULIP SOFT PAINT 4OZ",
			"label": "TULIP SOFT PAINT 4OZ"
	},
	{
			"value": "TULIP SPRAY ON PAINT",
			"label": "TULIP SPRAY ON PAINT"
	},
	{
			"value": "TULIP- STENCILS",
			"label": "TULIP- STENCILS"
	},
	{
			"value": "TULIP- TIE DYE KITS",
			"label": "TULIP- TIE DYE KITS"
	},
	{
			"value": "TULIP- TOOLS",
			"label": "TULIP- TOOLS"
	},
	{
			"value": "TULLE",
			"label": "TULLE"
	},
	{
			"value": "TULLE 108\"\" WIDE",
			"label": "TULLE 108\"\" WIDE"
	},
	{
			"value": "TULLE 54\"\" WIDE",
			"label": "TULLE 54\"\" WIDE"
	},
	{
			"value": "TULLE CIRCLE",
			"label": "TULLE CIRCLE"
	},
	{
			"value": "TULLE SPOOLS",
			"label": "TULLE SPOOLS"
	},
	{
			"value": "TUMBLE 4PK",
			"label": "TUMBLE 4PK"
	},
	{
			"value": "TUMBLER",
			"label": "TUMBLER"
	},
	{
			"value": "TUMBLER SET",
			"label": "TUMBLER SET"
	},
	{
			"value": "TURF",
			"label": "TURF"
	},
	{
			"value": "TURKEY",
			"label": "TURKEY"
	},
	{
			"value": "TURKEYS",
			"label": "TURKEYS"
	},
	{
			"value": "TURNTABLE",
			"label": "TURNTABLE"
	},
	{
			"value": "TURTLE",
			"label": "TURTLE"
	},
	{
			"value": "TURTLES",
			"label": "TURTLES"
	},
	{
			"value": "TURTLES PINK",
			"label": "TURTLES PINK"
	},
	{
			"value": "TUSCANY",
			"label": "TUSCANY"
	},
	{
			"value": "TUSGA HEMLOCK PINECO",
			"label": "TUSGA HEMLOCK PINECO"
	},
	{
			"value": "TUTTI FRUITTI",
			"label": "TUTTI FRUITTI"
	},
	{
			"value": "TUTTI FRUITTI BASIC",
			"label": "TUTTI FRUITTI BASIC"
	},
	{
			"value": "TUTTI FRUITTI COLLEC",
			"label": "TUTTI FRUITTI COLLEC"
	},
	{
			"value": "TUTU",
			"label": "TUTU"
	},
	{
			"value": "TUTU ADULT",
			"label": "TUTU ADULT"
	},
	{
			"value": "TUTU CHILD",
			"label": "TUTU CHILD"
	},
	{
			"value": "TUTU INFANT",
			"label": "TUTU INFANT"
	},
	{
			"value": "TV/MOVIES",
			"label": "TV/MOVIES"
	},
	{
			"value": "TWAS THE NIGHT BEFOR",
			"label": "TWAS THE NIGHT BEFOR"
	},
	{
			"value": "TWEED",
			"label": "TWEED"
	},
	{
			"value": "TWEEDIA",
			"label": "TWEEDIA"
	},
	{
			"value": "TWEEN",
			"label": "TWEEN"
	},
	{
			"value": "TWEET BIRD FLNL",
			"label": "TWEET BIRD FLNL"
	},
	{
			"value": "TWEEZERS",
			"label": "TWEEZERS"
	},
	{
			"value": "TWELFTHNIGHT",
			"label": "TWELFTHNIGHT"
	},
	{
			"value": "TWIG",
			"label": "TWIG"
	},
	{
			"value": "TWIG BUNDLE",
			"label": "TWIG BUNDLE"
	},
	{
			"value": "TWIG DECOR",
			"label": "TWIG DECOR"
	},
	{
			"value": "TWIG LEAVES",
			"label": "TWIG LEAVES"
	},
	{
			"value": "TWIG WREATH",
			"label": "TWIG WREATH"
	},
	{
			"value": "TWILIGHT COLLECTION",
			"label": "TWILIGHT COLLECTION"
	},
	{
			"value": "TWILL",
			"label": "TWILL"
	},
	{
			"value": "TWILL PATCH",
			"label": "TWILL PATCH"
	},
	{
			"value": "TWILL PATCHES",
			"label": "TWILL PATCHES"
	},
	{
			"value": "TWILL TAPE",
			"label": "TWILL TAPE"
	},
	{
			"value": "TWIN",
			"label": "TWIN"
	},
	{
			"value": "TWINE",
			"label": "TWINE"
	},
	{
			"value": "TWINKLE HTV",
			"label": "TWINKLE HTV"
	},
	{
			"value": "TWISTED CORD",
			"label": "TWISTED CORD"
	},
	{
			"value": "TWISTED TAPER",
			"label": "TWISTED TAPER"
	},
	{
			"value": "TWISTED TEXTURE 2",
			"label": "TWISTED TEXTURE 2"
	},
	{
			"value": "TWIST GARLAND",
			"label": "TWIST GARLAND"
	},
	{
			"value": "TWIST TAPER",
			"label": "TWIST TAPER"
	},
	{
			"value": "TWIST TIES",
			"label": "TWIST TIES"
	},
	{
			"value": "TWO COLOR SKINS",
			"label": "TWO COLOR SKINS"
	},
	{
			"value": "TWO TONE",
			"label": "TWO TONE"
	},
	{
			"value": "TWO TONED",
			"label": "TWO TONED"
	},
	{
			"value": "TWO TONED POT",
			"label": "TWO TONED POT"
	},
	{
			"value": "TYE DYE KIT",
			"label": "TYE DYE KIT"
	},
	{
			"value": "TYPEWRITER KEYS",
			"label": "TYPEWRITER KEYS"
	},
	{
			"value": "TYPOGRAPHY",
			"label": "TYPOGRAPHY"
	},
	{
			"value": "UC",
			"label": "UC"
	},
	{
			"value": "UCHIDA",
			"label": "UCHIDA"
	},
	{
			"value": "UCHIDA- FABRIC PENS",
			"label": "UCHIDA- FABRIC PENS"
	},
	{
			"value": "UCHIDA- MARKERS",
			"label": "UCHIDA- MARKERS"
	},
	{
			"value": "UC REBUYS",
			"label": "UC REBUYS"
	},
	{
			"value": "UGLY SWEATER KIT",
			"label": "UGLY SWEATER KIT"
	},
	{
			"value": "ULTIMATE",
			"label": "ULTIMATE"
	},
	{
			"value": "ULTRA CUDDLE",
			"label": "ULTRA CUDDLE"
	},
	{
			"value": "ULTRA CUDDLE SOLID",
			"label": "ULTRA CUDDLE SOLID"
	},
	{
			"value": "ULTRA PLUSH FIBER",
			"label": "ULTRA PLUSH FIBER"
	},
	{
			"value": "ULTRA WEIGHT POLY",
			"label": "ULTRA WEIGHT POLY"
	},
	{
			"value": "UMBRELLA",
			"label": "UMBRELLA"
	},
	{
			"value": "UMBRELLA LIGHT",
			"label": "UMBRELLA LIGHT"
	},
	{
			"value": "UMBRELLAS",
			"label": "UMBRELLAS"
	},
	{
			"value": "UNBLEACHED",
			"label": "UNBLEACHED"
	},
	{
			"value": "UNCLE SAM",
			"label": "UNCLE SAM"
	},
	{
			"value": "UNDERGARMENT",
			"label": "UNDERGARMENT"
	},
	{
			"value": "UNDERLINING",
			"label": "UNDERLINING"
	},
	{
			"value": "UNDER THE SEA",
			"label": "UNDER THE SEA"
	},
	{
			"value": "UNDERWATER ANIMALS",
			"label": "UNDERWATER ANIMALS"
	},
	{
			"value": "UNDERWATER SEA LIFE",
			"label": "UNDERWATER SEA LIFE"
	},
	{
			"value": "UN DU PRODUCTS",
			"label": "UN DU PRODUCTS"
	},
	{
			"value": "UNFIN WOOD POG",
			"label": "UNFIN WOOD POG"
	},
	{
			"value": "UNIBIND",
			"label": "UNIBIND"
	},
	{
			"value": "UNICORN",
			"label": "UNICORN"
	},
	{
			"value": "UNICORN BUCKRAM",
			"label": "UNICORN BUCKRAM"
	},
	{
			"value": "UNICORN PARTY",
			"label": "UNICORN PARTY"
	},
	{
			"value": "UNICORN SEQUINS",
			"label": "UNICORN SEQUINS"
	},
	{
			"value": "UNIEK",
			"label": "UNIEK"
	},
	{
			"value": "UNIFORMED SCRAPBOOKS",
			"label": "UNIFORMED SCRAPBOOKS"
	},
	{
			"value": "UNIQUE INDUSTRIES",
			"label": "UNIQUE INDUSTRIES"
	},
	{
			"value": "UNITY STAMP",
			"label": "UNITY STAMP"
	},
	{
			"value": "UNIVERSAL DIGITIZING",
			"label": "UNIVERSAL DIGITIZING"
	},
	{
			"value": "UNPLUG",
			"label": "UNPLUG"
	},
	{
			"value": "UNWIND PILLOW",
			"label": "UNWIND PILLOW"
	},
	{
			"value": "UPHOLSTERY",
			"label": "UPHOLSTERY"
	},
	{
			"value": "UPHOLSTERY LINING",
			"label": "UPHOLSTERY LINING"
	},
	{
			"value": "UPHOLSTERY VINYL",
			"label": "UPHOLSTERY VINYL"
	},
	{
			"value": "UPSCALE",
			"label": "UPSCALE"
	},
	{
			"value": "URBAN",
			"label": "URBAN"
	},
	{
			"value": "URBAN ICE",
			"label": "URBAN ICE"
	},
	{
			"value": "URBAN WARRIOR",
			"label": "URBAN WARRIOR"
	},
	{
			"value": "URCHIN CONTAINER",
			"label": "URCHIN CONTAINER"
	},
	{
			"value": "URN DECOR",
			"label": "URN DECOR"
	},
	{
			"value": "URNS",
			"label": "URNS"
	},
	{
			"value": "US ARMY",
			"label": "US ARMY"
	},
	{
			"value": "US ARTQUEST",
			"label": "US ARTQUEST"
	},
	{
			"value": "USC",
			"label": "USC"
	},
	{
			"value": "US FLAG",
			"label": "US FLAG"
	},
	{
			"value": "US FLAGS",
			"label": "US FLAGS"
	},
	{
			"value": "U.S. SHELL",
			"label": "U.S. SHELL"
	},
	{
			"value": "UTAH",
			"label": "UTAH"
	},
	{
			"value": "UTENSIL",
			"label": "UTENSIL"
	},
	{
			"value": "UTENSIL CADDY",
			"label": "UTENSIL CADDY"
	},
	{
			"value": "UTENSIL HOLDER",
			"label": "UTENSIL HOLDER"
	},
	{
			"value": "UTENSILS",
			"label": "UTENSILS"
	},
	{
			"value": "UTILITY",
			"label": "UTILITY"
	},
	{
			"value": "UTILITY FABRIC",
			"label": "UTILITY FABRIC"
	},
	{
			"value": "U UV FOR CANDLE",
			"label": "U UV FOR CANDLE"
	},
	{
			"value": "UV SANITIZER",
			"label": "UV SANITIZER"
	},
	{
			"value": "UWOOD",
			"label": "UWOOD"
	},
	{
			"value": "UWOODB00",
			"label": "UWOODB00"
	},
	{
			"value": "UWOODD00",
			"label": "UWOODD00"
	},
	{
			"value": "UWOODG00",
			"label": "UWOODG00"
	},
	{
			"value": "VACATION",
			"label": "VACATION"
	},
	{
			"value": "VACAY TOWEL",
			"label": "VACAY TOWEL"
	},
	{
			"value": "VAL CANVAS",
			"label": "VAL CANVAS"
	},
	{
			"value": "VAL DOILEY",
			"label": "VAL DOILEY"
	},
	{
			"value": "VALENTIES DAY",
			"label": "VALENTIES DAY"
	},
	{
			"value": "VALENTINE",
			"label": "VALENTINE"
	},
	{
			"value": "VALENTINE BOW",
			"label": "VALENTINE BOW"
	},
	{
			"value": "VALENTINE BOWS",
			"label": "VALENTINE BOWS"
	},
	{
			"value": "VALENTINE CANDY",
			"label": "VALENTINE CANDY"
	},
	{
			"value": "VALENTINE CARD TEST",
			"label": "VALENTINE CARD TEST"
	},
	{
			"value": "VALENTINE RIBBON",
			"label": "VALENTINE RIBBON"
	},
	{
			"value": "VALENTINE'S",
			"label": "VALENTINE'S"
	},
	{
			"value": "VALENTINES",
			"label": "VALENTINES"
	},
	{
			"value": "VALENTINE'S DAY",
			"label": "VALENTINE'S DAY"
	},
	{
			"value": "VALENTINES DAYS",
			"label": "VALENTINES DAYS"
	},
	{
			"value": "VALENTINES ENDCAP",
			"label": "VALENTINES ENDCAP"
	},
	{
			"value": "VALENTINES POWER PAN",
			"label": "VALENTINES POWER PAN"
	},
	{
			"value": "VALERIAN",
			"label": "VALERIAN"
	},
	{
			"value": "VAL EXCHANGE",
			"label": "VAL EXCHANGE"
	},
	{
			"value": "VAL FELT BASE",
			"label": "VAL FELT BASE"
	},
	{
			"value": "VAL FOAM BASE",
			"label": "VAL FOAM BASE"
	},
	{
			"value": "VAL FOAMBASE",
			"label": "VAL FOAMBASE"
	},
	{
			"value": "VAL FOAM PLATTER",
			"label": "VAL FOAM PLATTER"
	},
	{
			"value": "VAL FOAM STICKER",
			"label": "VAL FOAM STICKER"
	},
	{
			"value": "VAL GOOGLY EYE",
			"label": "VAL GOOGLY EYE"
	},
	{
			"value": "VAL IKIT",
			"label": "VAL IKIT"
	},
	{
			"value": "VAL KIT",
			"label": "VAL KIT"
	},
	{
			"value": "VAL LITTLES",
			"label": "VAL LITTLES"
	},
	{
			"value": "VAL MELTY BEAD",
			"label": "VAL MELTY BEAD"
	},
	{
			"value": "VAL MIBI",
			"label": "VAL MIBI"
	},
	{
			"value": "VAL PLASTER",
			"label": "VAL PLASTER"
	},
	{
			"value": "VAL POM",
			"label": "VAL POM"
	},
	{
			"value": "VAL SEQUIN",
			"label": "VAL SEQUIN"
	},
	{
			"value": "VAL SEQUINS",
			"label": "VAL SEQUINS"
	},
	{
			"value": "VAL STEM",
			"label": "VAL STEM"
	},
	{
			"value": "VAL STICKER",
			"label": "VAL STICKER"
	},
	{
			"value": "VAL STICKERBOOK",
			"label": "VAL STICKERBOOK"
	},
	{
			"value": "VAL SUNCATCHER",
			"label": "VAL SUNCATCHER"
	},
	{
			"value": "VAL TASSEL",
			"label": "VAL TASSEL"
	},
	{
			"value": "VAL TWINE",
			"label": "VAL TWINE"
	},
	{
			"value": "VALUE",
			"label": "VALUE"
	},
	{
			"value": "VALUE BEAD",
			"label": "VALUE BEAD"
	},
	{
			"value": "VALUE BINS",
			"label": "VALUE BINS"
	},
	{
			"value": "VALUE CORE",
			"label": "VALUE CORE"
	},
	{
			"value": "VALUE HALLOWEEN",
			"label": "VALUE HALLOWEEN"
	},
	{
			"value": "VALUE PACK",
			"label": "VALUE PACK"
	},
	{
			"value": "VALUE PACK TREE",
			"label": "VALUE PACK TREE"
	},
	{
			"value": "VALUE RIBBON",
			"label": "VALUE RIBBON"
	},
	{
			"value": "VALUE SET",
			"label": "VALUE SET"
	},
	{
			"value": "VALUE TRIMS",
			"label": "VALUE TRIMS"
	},
	{
			"value": "VALUE WOOD--BASIC",
			"label": "VALUE WOOD--BASIC"
	},
	{
			"value": "VALUE WOOD LA COLLEC",
			"label": "VALUE WOOD LA COLLEC"
	},
	{
			"value": "VAL VELVET POSTER",
			"label": "VAL VELVET POSTER"
	},
	{
			"value": "VAL WOOD DOLL",
			"label": "VAL WOOD DOLL"
	},
	{
			"value": "VAL WOOD MASK",
			"label": "VAL WOOD MASK"
	},
	{
			"value": "VAL WOOD PADDLE",
			"label": "VAL WOOD PADDLE"
	},
	{
			"value": "VAL WOOD PAINT",
			"label": "VAL WOOD PAINT"
	},
	{
			"value": "VAL WOOD PAINT KIT",
			"label": "VAL WOOD PAINT KIT"
	},
	{
			"value": "VAMPIRE ORN",
			"label": "VAMPIRE ORN"
	},
	{
			"value": "VANILLA AND SANDALWO",
			"label": "VANILLA AND SANDALWO"
	},
	{
			"value": "VARSITY CLUB",
			"label": "VARSITY CLUB"
	},
	{
			"value": "VASE",
			"label": "VASE"
	},
	{
			"value": "VB BOWS",
			"label": "VB BOWS"
	},
	{
			"value": "VDAY",
			"label": "VDAY"
	},
	{
			"value": "VEGETABLE",
			"label": "VEGETABLE"
	},
	{
			"value": "VEGETABLES",
			"label": "VEGETABLES"
	},
	{
			"value": "VEGGIE WALL DECOR",
			"label": "VEGGIE WALL DECOR"
	},
	{
			"value": "VEHICAL KIT",
			"label": "VEHICAL KIT"
	},
	{
			"value": "VEHICLE",
			"label": "VEHICLE"
	},
	{
			"value": "VELCRO BRAND FASTENE",
			"label": "VELCRO BRAND FASTENE"
	},
	{
			"value": "VELCRO(R) BRAND FAST",
			"label": "VELCRO(R) BRAND FAST"
	},
	{
			"value": "VELCRO SETS",
			"label": "VELCRO SETS"
	},
	{
			"value": "VELCRO TAPE",
			"label": "VELCRO TAPE"
	},
	{
			"value": "VELOUR COVERLET",
			"label": "VELOUR COVERLET"
	},
	{
			"value": "VELOUR PILLOW",
			"label": "VELOUR PILLOW"
	},
	{
			"value": "VELOUR RIBBON",
			"label": "VELOUR RIBBON"
	},
	{
			"value": "VELOUR TERRY TOWEL",
			"label": "VELOUR TERRY TOWEL"
	},
	{
			"value": "VELOUR TERRY TOWELS",
			"label": "VELOUR TERRY TOWELS"
	},
	{
			"value": "VELTEX",
			"label": "VELTEX"
	},
	{
			"value": "VELTEX BOW",
			"label": "VELTEX BOW"
	},
	{
			"value": "VELVET",
			"label": "VELVET"
	},
	{
			"value": "VELVET ART",
			"label": "VELVET ART"
	},
	{
			"value": "VELVET BOW",
			"label": "VELVET BOW"
	},
	{
			"value": "VELVET POSTER",
			"label": "VELVET POSTER"
	},
	{
			"value": "VELVET PUMPKIN",
			"label": "VELVET PUMPKIN"
	},
	{
			"value": "VELVET RIBBON",
			"label": "VELVET RIBBON"
	},
	{
			"value": "VELVETS",
			"label": "VELVETS"
	},
	{
			"value": "VENICE",
			"label": "VENICE"
	},
	{
			"value": "VENICE COLLECTION",
			"label": "VENICE COLLECTION"
	},
	{
			"value": "VERANDA",
			"label": "VERANDA"
	},
	{
			"value": "VERY MERRY",
			"label": "VERY MERRY"
	},
	{
			"value": "VERY MERRY OSO",
			"label": "VERY MERRY OSO"
	},
	{
			"value": "VERY MERRY WRAP",
			"label": "VERY MERRY WRAP"
	},
	{
			"value": "VFF CHENILLE",
			"label": "VFF CHENILLE"
	},
	{
			"value": "VFF COTTON",
			"label": "VFF COTTON"
	},
	{
			"value": "VFF DRAPERY",
			"label": "VFF DRAPERY"
	},
	{
			"value": "VFF JACQUARD",
			"label": "VFF JACQUARD"
	},
	{
			"value": "VFF LINEN",
			"label": "VFF LINEN"
	},
	{
			"value": "VFF MISC.",
			"label": "VFF MISC."
	},
	{
			"value": "VFF OUTDOOR",
			"label": "VFF OUTDOOR"
	},
	{
			"value": "VFF SUEDE",
			"label": "VFF SUEDE"
	},
	{
			"value": "VFF VELVET",
			"label": "VFF VELVET"
	},
	{
			"value": "VFF  VELVET",
			"label": "VFF  VELVET"
	},
	{
			"value": "VFF VINYL",
			"label": "VFF VINYL"
	},
	{
			"value": "VIBES TOWEL",
			"label": "VIBES TOWEL"
	},
	{
			"value": "VINCA",
			"label": "VINCA"
	},
	{
			"value": "VINE",
			"label": "VINE"
	},
	{
			"value": "VINE ANGEL",
			"label": "VINE ANGEL"
	},
	{
			"value": "VINE  BASKET",
			"label": "VINE  BASKET"
	},
	{
			"value": "VINE BASKET",
			"label": "VINE BASKET"
	},
	{
			"value": "VINE CORNUCOPIA",
			"label": "VINE CORNUCOPIA"
	},
	{
			"value": "VINE IVRIVRY",
			"label": "VINE IVRIVRY"
	},
	{
			"value": "VINE LTBLULTBL",
			"label": "VINE LTBLULTBL"
	},
	{
			"value": "VINE LTGRYLTGRY",
			"label": "VINE LTGRYLTGRY"
	},
	{
			"value": "VINES",
			"label": "VINES"
	},
	{
			"value": "VINE WALL BASKET",
			"label": "VINE WALL BASKET"
	},
	{
			"value": "VINE WHTWHT",
			"label": "VINE WHTWHT"
	},
	{
			"value": "VINEYARD",
			"label": "VINEYARD"
	},
	{
			"value": "VINTAGE",
			"label": "VINTAGE"
	},
	{
			"value": "VINTAGE-",
			"label": "VINTAGE-"
	},
	{
			"value": "VINTAGE-AA",
			"label": "VINTAGE-AA"
	},
	{
			"value": "VINTAGE-BB",
			"label": "VINTAGE-BB"
	},
	{
			"value": "VINTAGE-C",
			"label": "VINTAGE-C"
	},
	{
			"value": "VINTAGE CAR",
			"label": "VINTAGE CAR"
	},
	{
			"value": "VINTAGE-CC",
			"label": "VINTAGE-CC"
	},
	{
			"value": "VINTAGE COUNTRY",
			"label": "VINTAGE COUNTRY"
	},
	{
			"value": "VINTAGE-D",
			"label": "VINTAGE-D"
	},
	{
			"value": "VINTAGE-DD",
			"label": "VINTAGE-DD"
	},
	{
			"value": "VINTAGE EC",
			"label": "VINTAGE EC"
	},
	{
			"value": "VINTAGE-EE",
			"label": "VINTAGE-EE"
	},
	{
			"value": "VINTAGE-FF",
			"label": "VINTAGE-FF"
	},
	{
			"value": "VINTAGE HOME",
			"label": "VINTAGE HOME"
	},
	{
			"value": "VINTAGE JARS",
			"label": "VINTAGE JARS"
	},
	{
			"value": "VINTAGE ORN",
			"label": "VINTAGE ORN"
	},
	{
			"value": "VINTAGE OSO",
			"label": "VINTAGE OSO"
	},
	{
			"value": "VINTAGE-T",
			"label": "VINTAGE-T"
	},
	{
			"value": "VINTAGE-U",
			"label": "VINTAGE-U"
	},
	{
			"value": "VINTAGE-V",
			"label": "VINTAGE-V"
	},
	{
			"value": "VINTAGE-W",
			"label": "VINTAGE-W"
	},
	{
			"value": "VINTAGE-Z",
			"label": "VINTAGE-Z"
	},
	{
			"value": "VINTAJ",
			"label": "VINTAJ"
	},
	{
			"value": "VINYL",
			"label": "VINYL"
	},
	{
			"value": "VINYL TABLECLOTH",
			"label": "VINYL TABLECLOTH"
	},
	{
			"value": "VINYL THEMES",
			"label": "VINYL THEMES"
	},
	{
			"value": "VIOLET",
			"label": "VIOLET"
	},
	{
			"value": "VISOR",
			"label": "VISOR"
	},
	{
			"value": "VIVA DECOR",
			"label": "VIVA DECOR"
	},
	{
			"value": "VOGUE",
			"label": "VOGUE"
	},
	{
			"value": "VOGUE BAG",
			"label": "VOGUE BAG"
	},
	{
			"value": "VOGUE OPTIONS",
			"label": "VOGUE OPTIONS"
	},
	{
			"value": "VOILE",
			"label": "VOILE"
	},
	{
			"value": "VOLLEYBALL",
			"label": "VOLLEYBALL"
	},
	{
			"value": "VOTIVE",
			"label": "VOTIVE"
	},
	{
			"value": "VOTIVES",
			"label": "VOTIVES"
	},
	{
			"value": "VOTIVE SET",
			"label": "VOTIVE SET"
	},
	{
			"value": "VOYAGER",
			"label": "VOYAGER"
	},
	{
			"value": "VP STICKERS",
			"label": "VP STICKERS"
	},
	{
			"value": "WAFFLE WEAVE",
			"label": "WAFFLE WEAVE"
	},
	{
			"value": "WAGON",
			"label": "WAGON"
	},
	{
			"value": "WAIST",
			"label": "WAIST"
	},
	{
			"value": "WAIST APRON",
			"label": "WAIST APRON"
	},
	{
			"value": "WAIST BAG",
			"label": "WAIST BAG"
	},
	{
			"value": "WAISTBAND EL",
			"label": "WAISTBAND EL"
	},
	{
			"value": "WALKING DEAD",
			"label": "WALKING DEAD"
	},
	{
			"value": "WALK WAY/BRIDGE",
			"label": "WALK WAY/BRIDGE"
	},
	{
			"value": "WALL",
			"label": "WALL"
	},
	{
			"value": "WALL ARROW",
			"label": "WALL ARROW"
	},
	{
			"value": "WALL BANNER",
			"label": "WALL BANNER"
	},
	{
			"value": "WALL BOX",
			"label": "WALL BOX"
	},
	{
			"value": "WALL CALENDAR",
			"label": "WALL CALENDAR"
	},
	{
			"value": "WALL COLLECTIONS OR",
			"label": "WALL COLLECTIONS OR"
	},
	{
			"value": "WALL DECOR",
			"label": "WALL DECOR"
	},
	{
			"value": "WALL DÉCOR",
			"label": "WALL DÉCOR"
	},
	{
			"value": "WALL DECORE",
			"label": "WALL DECORE"
	},
	{
			"value": "WALL DECOR WORDS",
			"label": "WALL DECOR WORDS"
	},
	{
			"value": "WALL FRAME",
			"label": "WALL FRAME"
	},
	{
			"value": "WALL HANG",
			"label": "WALL HANG"
	},
	{
			"value": "WALL HANGING",
			"label": "WALL HANGING"
	},
	{
			"value": "WALL HOOK",
			"label": "WALL HOOK"
	},
	{
			"value": "WALLIES",
			"label": "WALLIES"
	},
	{
			"value": "WALL LENK",
			"label": "WALL LENK"
	},
	{
			"value": "WALLPAPER FASHION",
			"label": "WALLPAPER FASHION"
	},
	{
			"value": "WALL PLANTER",
			"label": "WALL PLANTER"
	},
	{
			"value": "WALL POCKET",
			"label": "WALL POCKET"
	},
	{
			"value": "WALL SCONCE",
			"label": "WALL SCONCE"
	},
	{
			"value": "WALL/TABLETOP",
			"label": "WALL/TABLETOP"
	},
	{
			"value": "WALNUT HOLLOW",
			"label": "WALNUT HOLLOW"
	},
	{
			"value": "WALRUS",
			"label": "WALRUS"
	},
	{
			"value": "WAND",
			"label": "WAND"
	},
	{
			"value": "WANDERING JEW",
			"label": "WANDERING JEW"
	},
	{
			"value": "WANDERING SPIRIT",
			"label": "WANDERING SPIRIT"
	},
	{
			"value": "WANDERLUST TOWEL",
			"label": "WANDERLUST TOWEL"
	},
	{
			"value": "WANT2SCRAP",
			"label": "WANT2SCRAP"
	},
	{
			"value": "WARM 100",
			"label": "WARM 100"
	},
	{
			"value": "WARM AND NATURAL",
			"label": "WARM AND NATURAL"
	},
	{
			"value": "WARM AND WHITE",
			"label": "WARM AND WHITE"
	},
	{
			"value": "WARM AND WHTIE",
			"label": "WARM AND WHTIE"
	},
	{
			"value": "WARM COMPANY BATTING",
			"label": "WARM COMPANY BATTING"
	},
	{
			"value": "WARM COMPANY STEAM-A",
			"label": "WARM COMPANY STEAM-A"
	},
	{
			"value": "WARMER",
			"label": "WARMER"
	},
	{
			"value": "WARMER ACCESSORY",
			"label": "WARMER ACCESSORY"
	},
	{
			"value": "WARMER LAMP",
			"label": "WARMER LAMP"
	},
	{
			"value": "WARMER/MELT",
			"label": "WARMER/MELT"
	},
	{
			"value": "WARM FASHION",
			"label": "WARM FASHION"
	},
	{
			"value": "WARM WEATHER",
			"label": "WARM WEATHER"
	},
	{
			"value": "WARP WOOD",
			"label": "WARP WOOD"
	},
	{
			"value": "WASHI",
			"label": "WASHI"
	},
	{
			"value": "WASHINGTON",
			"label": "WASHINGTON"
	},
	{
			"value": "WASHINGTON ST",
			"label": "WASHINGTON ST"
	},
	{
			"value": "WASHI TAPE",
			"label": "WASHI TAPE"
	},
	{
			"value": "WATCH AND OWL",
			"label": "WATCH AND OWL"
	},
	{
			"value": "WATER",
			"label": "WATER"
	},
	{
			"value": "WATER ANIMALS",
			"label": "WATER ANIMALS"
	},
	{
			"value": "WATER BOTTLE",
			"label": "WATER BOTTLE"
	},
	{
			"value": "WATERBOTTLE",
			"label": "WATERBOTTLE"
	},
	{
			"value": "WATER BOWL",
			"label": "WATER BOWL"
	},
	{
			"value": "WATERCAN",
			"label": "WATERCAN"
	},
	{
			"value": "WATERCOLOR",
			"label": "WATERCOLOR"
	},
	{
			"value": "WATERCOLOR SET",
			"label": "WATERCOLOR SET"
	},
	{
			"value": "WATER FEATURE",
			"label": "WATER FEATURE"
	},
	{
			"value": "WATER FOUNTAIN",
			"label": "WATER FOUNTAIN"
	},
	{
			"value": "WATER GLOBE",
			"label": "WATER GLOBE"
	},
	{
			"value": "WATERGLOBE",
			"label": "WATERGLOBE"
	},
	{
			"value": "WATERING",
			"label": "WATERING"
	},
	{
			"value": "WATERING CAN",
			"label": "WATERING CAN"
	},
	{
			"value": "WATERING CANS",
			"label": "WATERING CANS"
	},
	{
			"value": "WATER LIGHTS",
			"label": "WATER LIGHTS"
	},
	{
			"value": "WATERMARK",
			"label": "WATERMARK"
	},
	{
			"value": "WATERMELN",
			"label": "WATERMELN"
	},
	{
			"value": "WATERMELON",
			"label": "WATERMELON"
	},
	{
			"value": "WATER PRINT",
			"label": "WATER PRINT"
	},
	{
			"value": "WATER RESISTANT",
			"label": "WATER RESISTANT"
	},
	{
			"value": "WATER WOW PAD",
			"label": "WATER WOW PAD"
	},
	{
			"value": "WAVE",
			"label": "WAVE"
	},
	{
			"value": "WAVERLY",
			"label": "WAVERLY"
	},
	{
			"value": "WAVES WD",
			"label": "WAVES WD"
	},
	{
			"value": "WAVY WILLOW",
			"label": "WAVY WILLOW"
	},
	{
			"value": "WAX",
			"label": "WAX"
	},
	{
			"value": "WAX CORD",
			"label": "WAX CORD"
	},
	{
			"value": "WAX DROPS",
			"label": "WAX DROPS"
	},
	{
			"value": "WAX FLOWER",
			"label": "WAX FLOWER"
	},
	{
			"value": "WAXFLOWER",
			"label": "WAXFLOWER"
	},
	{
			"value": "WAX MELT",
			"label": "WAX MELT"
	},
	{
			"value": "WAX MELT ARTISAN",
			"label": "WAX MELT ARTISAN"
	},
	{
			"value": "WAX MELT CORE",
			"label": "WAX MELT CORE"
	},
	{
			"value": "WAX STICKS",
			"label": "WAX STICKS"
	},
	{
			"value": "WD BASIC",
			"label": "WD BASIC"
	},
	{
			"value": "WD BASIC NO SEN",
			"label": "WD BASIC NO SEN"
	},
	{
			"value": "WD BASIC SENTIMENT",
			"label": "WD BASIC SENTIMENT"
	},
	{
			"value": "WD EVERYDAY NO SENT",
			"label": "WD EVERYDAY NO SENT"
	},
	{
			"value": "WD EVERYDAY SENT",
			"label": "WD EVERYDAY SENT"
	},
	{
			"value": "WD FALL NO SENTIMENT",
			"label": "WD FALL NO SENTIMENT"
	},
	{
			"value": "WD FALL SENTIMENT",
			"label": "WD FALL SENTIMENT"
	},
	{
			"value": "WD FIGURAL",
			"label": "WD FIGURAL"
	},
	{
			"value": "WD FIGURAL LED",
			"label": "WD FIGURAL LED"
	},
	{
			"value": "WD FIGURAL LED SENT",
			"label": "WD FIGURAL LED SENT"
	},
	{
			"value": "WD FIGURAL NO SEN",
			"label": "WD FIGURAL NO SEN"
	},
	{
			"value": "WD FIGURAL SENTIMENT",
			"label": "WD FIGURAL SENTIMENT"
	},
	{
			"value": "WD FUNCTIONAL",
			"label": "WD FUNCTIONAL"
	},
	{
			"value": "WD HOOK",
			"label": "WD HOOK"
	},
	{
			"value": "WD LED NO SENTIMENT",
			"label": "WD LED NO SENTIMENT"
	},
	{
			"value": "WD NO SENTIMENT",
			"label": "WD NO SENTIMENT"
	},
	{
			"value": "WD PUN WORDS",
			"label": "WD PUN WORDS"
	},
	{
			"value": "WD SENTIMENT",
			"label": "WD SENTIMENT"
	},
	{
			"value": "WD SPRING SENTIMENT",
			"label": "WD SPRING SENTIMENT"
	},
	{
			"value": "WD TRAY",
			"label": "WD TRAY"
	},
	{
			"value": "WEAPON",
			"label": "WEAPON"
	},
	{
			"value": "WEARABLE",
			"label": "WEARABLE"
	},
	{
			"value": "WEARABLE ACC MISC",
			"label": "WEARABLE ACC MISC"
	},
	{
			"value": "WEARABLES",
			"label": "WEARABLES"
	},
	{
			"value": "WEATHERED",
			"label": "WEATHERED"
	},
	{
			"value": "WEATHER RESISTANT",
			"label": "WEATHER RESISTANT"
	},
	{
			"value": "WEATHER SOFT",
			"label": "WEATHER SOFT"
	},
	{
			"value": "WEAVE",
			"label": "WEAVE"
	},
	{
			"value": "WEAVE TOWEL",
			"label": "WEAVE TOWEL"
	},
	{
			"value": "WEB",
			"label": "WEB"
	},
	{
			"value": "WEBBING",
			"label": "WEBBING"
	},
	{
			"value": "WEB LACE",
			"label": "WEB LACE"
	},
	{
			"value": "WEB METALLIC",
			"label": "WEB METALLIC"
	},
	{
			"value": "WEBSTER'S PAGES",
			"label": "WEBSTER'S PAGES"
	},
	{
			"value": "WEDDING",
			"label": "WEDDING"
	},
	{
			"value": "WEDDING CRAFTS",
			"label": "WEDDING CRAFTS"
	},
	{
			"value": "WEDDING/FAMILY",
			"label": "WEDDING/FAMILY"
	},
	{
			"value": "WEDDING LACE",
			"label": "WEDDING LACE"
	},
	{
			"value": "WEDDING TRADITIONS",
			"label": "WEDDING TRADITIONS"
	},
	{
			"value": "WEEKS DYE WORKS",
			"label": "WEEKS DYE WORKS"
	},
	{
			"value": "WEEPING WILLOW",
			"label": "WEEPING WILLOW"
	},
	{
			"value": "WEIGHTED BLANKET",
			"label": "WEIGHTED BLANKET"
	},
	{
			"value": "WELCOME",
			"label": "WELCOME"
	},
	{
			"value": "WELCOME LAMP",
			"label": "WELCOME LAMP"
	},
	{
			"value": "WELCOME TOWEL",
			"label": "WELCOME TOWEL"
	},
	{
			"value": "WELCOME WD",
			"label": "WELCOME WD"
	},
	{
			"value": "WELLNESS",
			"label": "WELLNESS"
	},
	{
			"value": "WELLNESS WARRIOR",
			"label": "WELLNESS WARRIOR"
	},
	{
			"value": "WE R MEMORY KEEPERS",
			"label": "WE R MEMORY KEEPERS"
	},
	{
			"value": "WESTERN",
			"label": "WESTERN"
	},
	{
			"value": "WESTRIM",
			"label": "WESTRIM"
	},
	{
			"value": "WEST VIRGINIA",
			"label": "WEST VIRGINIA"
	},
	{
			"value": "WHALE",
			"label": "WHALE"
	},
	{
			"value": "WHAT A FOX",
			"label": "WHAT A FOX"
	},
	{
			"value": "WHAT A GENTLEMEN",
			"label": "WHAT A GENTLEMEN"
	},
	{
			"value": "WHEAT",
			"label": "WHEAT"
	},
	{
			"value": "WHEAT GRASS",
			"label": "WHEAT GRASS"
	},
	{
			"value": "WHEATGRASS",
			"label": "WHEATGRASS"
	},
	{
			"value": "WHEEL",
			"label": "WHEEL"
	},
	{
			"value": "WHEEL BARROW",
			"label": "WHEEL BARROW"
	},
	{
			"value": "WHEELBARROW",
			"label": "WHEELBARROW"
	},
	{
			"value": "WHEN IN ROAM",
			"label": "WHEN IN ROAM"
	},
	{
			"value": "WHERE THE WILD THING",
			"label": "WHERE THE WILD THING"
	},
	{
			"value": "WHIMSEY WORKSHOP",
			"label": "WHIMSEY WORKSHOP"
	},
	{
			"value": "WHIMSICAL",
			"label": "WHIMSICAL"
	},
	{
			"value": "WHIMSICAL CARDS",
			"label": "WHIMSICAL CARDS"
	},
	{
			"value": "WHIMSICAL GRID",
			"label": "WHIMSICAL GRID"
	},
	{
			"value": "WHIMSY",
			"label": "WHIMSY"
	},
	{
			"value": "WHIMSY WORKSHOP",
			"label": "WHIMSY WORKSHOP"
	},
	{
			"value": "WHIMSY WORKSHOP OSO",
			"label": "WHIMSY WORKSHOP OSO"
	},
	{
			"value": "WHIMSY WRAP",
			"label": "WHIMSY WRAP"
	},
	{
			"value": "WHISKS",
			"label": "WHISKS"
	},
	{
			"value": "WHITE",
			"label": "WHITE"
	},
	{
			"value": "WHITE CHOCOLATE",
			"label": "WHITE CHOCOLATE"
	},
	{
			"value": "WHITE CORAL",
			"label": "WHITE CORAL"
	},
	{
			"value": "WHITE DOILY",
			"label": "WHITE DOILY"
	},
	{
			"value": "WHITE/GOLD",
			"label": "WHITE/GOLD"
	},
	{
			"value": "WHITE MAGNOLIA",
			"label": "WHITE MAGNOLIA"
	},
	{
			"value": "WHITE MELAMINE",
			"label": "WHITE MELAMINE"
	},
	{
			"value": "WHITE MOUNTAIN PUZZL",
			"label": "WHITE MOUNTAIN PUZZL"
	},
	{
			"value": "WHITE NYLON",
			"label": "WHITE NYLON"
	},
	{
			"value": "WHITE PINE",
			"label": "WHITE PINE"
	},
	{
			"value": "WHITE PINE DRIEDS",
			"label": "WHITE PINE DRIEDS"
	},
	{
			"value": "WHITE PINE OSO",
			"label": "WHITE PINE OSO"
	},
	{
			"value": "WHITE TAKLON",
			"label": "WHITE TAKLON"
	},
	{
			"value": "WHITE TREE",
			"label": "WHITE TREE"
	},
	{
			"value": "WHT 12 SET",
			"label": "WHT 12 SET"
	},
	{
			"value": "WHT BLU",
			"label": "WHT BLU"
	},
	{
			"value": "WHT/BLU",
			"label": "WHT/BLU"
	},
	{
			"value": "WHT GRND",
			"label": "WHT GRND"
	},
	{
			"value": "WHT/GRY",
			"label": "WHT/GRY"
	},
	{
			"value": "WHT L",
			"label": "WHT L"
	},
	{
			"value": "WHT M",
			"label": "WHT M"
	},
	{
			"value": "WHT S",
			"label": "WHT S"
	},
	{
			"value": "WICKER BASKET",
			"label": "WICKER BASKET"
	},
	{
			"value": "WICKING KNIT",
			"label": "WICKING KNIT"
	},
	{
			"value": "WIDE",
			"label": "WIDE"
	},
	{
			"value": "WIDE MOUTH",
			"label": "WIDE MOUTH"
	},
	{
			"value": "WIDGET PRODUCTS",
			"label": "WIDGET PRODUCTS"
	},
	{
			"value": "WIG",
			"label": "WIG"
	},
	{
			"value": "WIG ADULT",
			"label": "WIG ADULT"
	},
	{
			"value": "WIG CHILD",
			"label": "WIG CHILD"
	},
	{
			"value": "WIKKI STIX",
			"label": "WIKKI STIX"
	},
	{
			"value": "WILD BLOSSOM",
			"label": "WILD BLOSSOM"
	},
	{
			"value": "WILDERNESS",
			"label": "WILDERNESS"
	},
	{
			"value": "WILDERNESS ANIMALS",
			"label": "WILDERNESS ANIMALS"
	},
	{
			"value": "WILD FLOWER",
			"label": "WILD FLOWER"
	},
	{
			"value": "WILDFLOWER",
			"label": "WILDFLOWER"
	},
	{
			"value": "WILD GRASS",
			"label": "WILD GRASS"
	},
	{
			"value": "WILD INTUITIONS",
			"label": "WILD INTUITIONS"
	},
	{
			"value": "WILDNERSS",
			"label": "WILDNERSS"
	},
	{
			"value": "WILD OATS",
			"label": "WILD OATS"
	},
	{
			"value": "WILD SUMMER",
			"label": "WILD SUMMER"
	},
	{
			"value": "WILD WD",
			"label": "WILD WD"
	},
	{
			"value": "WILEY PUBLISHING",
			"label": "WILEY PUBLISHING"
	},
	{
			"value": "WILLION",
			"label": "WILLION"
	},
	{
			"value": "WILLOW",
			"label": "WILLOW"
	},
	{
			"value": "WILLOW BASKET",
			"label": "WILLOW BASKET"
	},
	{
			"value": "WILLOW WALL BASKET",
			"label": "WILLOW WALL BASKET"
	},
	{
			"value": "WILTN",
			"label": "WILTN"
	},
	{
			"value": "WILTN08",
			"label": "WILTN08"
	},
	{
			"value": "WILTN12",
			"label": "WILTN12"
	},
	{
			"value": "WILTNE00",
			"label": "WILTNE00"
	},
	{
			"value": "WILTNF00",
			"label": "WILTNF00"
	},
	{
			"value": "WILTNH00",
			"label": "WILTNH00"
	},
	{
			"value": "WILTNJ00",
			"label": "WILTNJ00"
	},
	{
			"value": "WILTON",
			"label": "WILTON"
	},
	{
			"value": "WILTON EDUCATION",
			"label": "WILTON EDUCATION"
	},
	{
			"value": "WINCHIME",
			"label": "WINCHIME"
	},
	{
			"value": "WIND CHIME",
			"label": "WIND CHIME"
	},
	{
			"value": "WINDCHIME",
			"label": "WINDCHIME"
	},
	{
			"value": "WINDCHIME ANIMAL",
			"label": "WINDCHIME ANIMAL"
	},
	{
			"value": "WINDCHIME CERAMIC",
			"label": "WINDCHIME CERAMIC"
	},
	{
			"value": "WINDCHIME KINETIC",
			"label": "WINDCHIME KINETIC"
	},
	{
			"value": "WINDCHIME METAL",
			"label": "WINDCHIME METAL"
	},
	{
			"value": "WINDCHIME NOVELTY",
			"label": "WINDCHIME NOVELTY"
	},
	{
			"value": "WINDCHIME PLANTER",
			"label": "WINDCHIME PLANTER"
	},
	{
			"value": "WINDCHIMES",
			"label": "WINDCHIMES"
	},
	{
			"value": "WINDMILL",
			"label": "WINDMILL"
	},
	{
			"value": "WINDOW",
			"label": "WINDOW"
	},
	{
			"value": "WINDOW BASKET",
			"label": "WINDOW BASKET"
	},
	{
			"value": "WINDOW BOX",
			"label": "WINDOW BOX"
	},
	{
			"value": "WINDOW CLING",
			"label": "WINDOW CLING"
	},
	{
			"value": "WINDOWPANE",
			"label": "WINDOWPANE"
	},
	{
			"value": "WINDOW PLANTER",
			"label": "WINDOW PLANTER"
	},
	{
			"value": "WINDOW TREATMNTS/SH",
			"label": "WINDOW TREATMNTS/SH"
	},
	{
			"value": "WINDOW TWIG",
			"label": "WINDOW TWIG"
	},
	{
			"value": "WINDOW WALKERS",
			"label": "WINDOW WALKERS"
	},
	{
			"value": "WIND SOCK",
			"label": "WIND SOCK"
	},
	{
			"value": "WINDSPINNER",
			"label": "WINDSPINNER"
	},
	{
			"value": "WINE",
			"label": "WINE"
	},
	{
			"value": "WINE ACC",
			"label": "WINE ACC"
	},
	{
			"value": "WINE ACCESSORY",
			"label": "WINE ACCESSORY"
	},
	{
			"value": "WINE BAF",
			"label": "WINE BAF"
	},
	{
			"value": "WINE BAG",
			"label": "WINE BAG"
	},
	{
			"value": "WINEBAG",
			"label": "WINEBAG"
	},
	{
			"value": "WINE BAGS",
			"label": "WINE BAGS"
	},
	{
			"value": "WINE BOTTLE CHARM",
			"label": "WINE BOTTLE CHARM"
	},
	{
			"value": "WINE CHARM",
			"label": "WINE CHARM"
	},
	{
			"value": "WINE CHARMS",
			"label": "WINE CHARMS"
	},
	{
			"value": "WINE GLASS",
			"label": "WINE GLASS"
	},
	{
			"value": "WINE HOLDER",
			"label": "WINE HOLDER"
	},
	{
			"value": "WINE RACK",
			"label": "WINE RACK"
	},
	{
			"value": "WINE STOPPER",
			"label": "WINE STOPPER"
	},
	{
			"value": "WINE TOPPER",
			"label": "WINE TOPPER"
	},
	{
			"value": "WINE TUBE",
			"label": "WINE TUBE"
	},
	{
			"value": "WINE TUMBLER",
			"label": "WINE TUMBLER"
	},
	{
			"value": "WING",
			"label": "WING"
	},
	{
			"value": "WINGS",
			"label": "WINGS"
	},
	{
			"value": "WINGS ADULT",
			"label": "WINGS ADULT"
	},
	{
			"value": "WINGS CHILD",
			"label": "WINGS CHILD"
	},
	{
			"value": "WINGS INFANT",
			"label": "WINGS INFANT"
	},
	{
			"value": "WINGS/TUTUS",
			"label": "WINGS/TUTUS"
	},
	{
			"value": "WINNIE THE POOH",
			"label": "WINNIE THE POOH"
	},
	{
			"value": "WINSOR NEWTON",
			"label": "WINSOR NEWTON"
	},
	{
			"value": "WINTER",
			"label": "WINTER"
	},
	{
			"value": "WINTER ANIMAL DECOR",
			"label": "WINTER ANIMAL DECOR"
	},
	{
			"value": "WINTER BERRY GIFTBAG",
			"label": "WINTER BERRY GIFTBAG"
	},
	{
			"value": "WINTERBERRY WRAP",
			"label": "WINTERBERRY WRAP"
	},
	{
			"value": "WINTERLAND",
			"label": "WINTERLAND"
	},
	{
			"value": "WINTERS FOREST",
			"label": "WINTERS FOREST"
	},
	{
			"value": "WINTERS FOREST COUNT",
			"label": "WINTERS FOREST COUNT"
	},
	{
			"value": "WINTER SOLSTICE",
			"label": "WINTER SOLSTICE"
	},
	{
			"value": "WINTER THEME",
			"label": "WINTER THEME"
	},
	{
			"value": "WINTER WONDERLAND",
			"label": "WINTER WONDERLAND"
	},
	{
			"value": "WIPE CLEAN BOARD",
			"label": "WIPE CLEAN BOARD"
	},
	{
			"value": "WIRE",
			"label": "WIRE"
	},
	{
			"value": "WIRE BASE",
			"label": "WIRE BASE"
	},
	{
			"value": "WIRE BASKET",
			"label": "WIRE BASKET"
	},
	{
			"value": "WIRE CHAIN",
			"label": "WIRE CHAIN"
	},
	{
			"value": "WIRED TULLE",
			"label": "WIRED TULLE"
	},
	{
			"value": "WIRE LED STAKE",
			"label": "WIRE LED STAKE"
	},
	{
			"value": "WIRE NECKLACE",
			"label": "WIRE NECKLACE"
	},
	{
			"value": "WIRE PUMPKIN",
			"label": "WIRE PUMPKIN"
	},
	{
			"value": "WIRE & TAPE",
			"label": "WIRE & TAPE"
	},
	{
			"value": "WIRE WALL DECOR",
			"label": "WIRE WALL DECOR"
	},
	{
			"value": "WISCONSIN",
			"label": "WISCONSIN"
	},
	{
			"value": "WISTERIA",
			"label": "WISTERIA"
	},
	{
			"value": "WISTERIA AIR2 BUNDLE",
			"label": "WISTERIA AIR2 BUNDLE"
	},
	{
			"value": "WISTERIA BUTTERFLY",
			"label": "WISTERIA BUTTERFLY"
	},
	{
			"value": "WISTERIA EP BUNDLE",
			"label": "WISTERIA EP BUNDLE"
	},
	{
			"value": "WISTYRIA EDITIONS",
			"label": "WISTYRIA EDITIONS"
	},
	{
			"value": "WITCH",
			"label": "WITCH"
	},
	{
			"value": "WITCH BROOM",
			"label": "WITCH BROOM"
	},
	{
			"value": "WITCH DOLL",
			"label": "WITCH DOLL"
	},
	{
			"value": "WITCHES",
			"label": "WITCHES"
	},
	{
			"value": "WITCH HAT",
			"label": "WITCH HAT"
	},
	{
			"value": "WITCH LEGS",
			"label": "WITCH LEGS"
	},
	{
			"value": "WITCH ORN",
			"label": "WITCH ORN"
	},
	{
			"value": "WITH HOOKS",
			"label": "WITH HOOKS"
	},
	{
			"value": "WITH LEATHER",
			"label": "WITH LEATHER"
	},
	{
			"value": "WITH STRAW",
			"label": "WITH STRAW"
	},
	{
			"value": "WITH TASSELS",
			"label": "WITH TASSELS"
	},
	{
			"value": "WM",
			"label": "WM"
	},
	{
			"value": "WOBBLER",
			"label": "WOBBLER"
	},
	{
			"value": "WOMEN",
			"label": "WOMEN"
	},
	{
			"value": "WOMEN'S EMPOWERMENT",
			"label": "WOMEN'S EMPOWERMENT"
	},
	{
			"value": "WOMENS EMPOWERMENT",
			"label": "WOMENS EMPOWERMENT"
	},
	{
			"value": "WONDERLAND",
			"label": "WONDERLAND"
	},
	{
			"value": "WONDERLAND OSO",
			"label": "WONDERLAND OSO"
	},
	{
			"value": "WONDERLAND WRAP",
			"label": "WONDERLAND WRAP"
	},
	{
			"value": "WONDER WOMAN",
			"label": "WONDER WOMAN"
	},
	{
			"value": "WOOD",
			"label": "WOOD"
	},
	{
			"value": "WOOD ANIMAL",
			"label": "WOOD ANIMAL"
	},
	{
			"value": "WOOD BANNER",
			"label": "WOOD BANNER"
	},
	{
			"value": "WOOD BASKET",
			"label": "WOOD BASKET"
	},
	{
			"value": "WOOD BEAD",
			"label": "WOOD BEAD"
	},
	{
			"value": "WOOD BEADS",
			"label": "WOOD BEADS"
	},
	{
			"value": "WOOD BIRDHOUSE",
			"label": "WOOD BIRDHOUSE"
	},
	{
			"value": "WOOD BOX",
			"label": "WOOD BOX"
	},
	{
			"value": "WOOD CHAIR",
			"label": "WOOD CHAIR"
	},
	{
			"value": "WOOD CHIP",
			"label": "WOOD CHIP"
	},
	{
			"value": "WOODCHIP",
			"label": "WOODCHIP"
	},
	{
			"value": "WOODCHIP CRITTER",
			"label": "WOODCHIP CRITTER"
	},
	{
			"value": "WOODCHIP PUMPKIN",
			"label": "WOODCHIP PUMPKIN"
	},
	{
			"value": "WOODCHIP WREATH",
			"label": "WOODCHIP WREATH"
	},
	{
			"value": "WOOD DOLL",
			"label": "WOOD DOLL"
	},
	{
			"value": "WOOD DOOR HANGER",
			"label": "WOOD DOOR HANGER"
	},
	{
			"value": "WOODEN BIRDHOUSE",
			"label": "WOODEN BIRDHOUSE"
	},
	{
			"value": "WOODEN FLOWER",
			"label": "WOODEN FLOWER"
	},
	{
			"value": "WOODEN LETTERS",
			"label": "WOODEN LETTERS"
	},
	{
			"value": "WOOD ENTERTAINING",
			"label": "WOOD ENTERTAINING"
	},
	{
			"value": "WOODEN WALL DECOR",
			"label": "WOODEN WALL DECOR"
	},
	{
			"value": "WOODEN WICK",
			"label": "WOODEN WICK"
	},
	{
			"value": "WOODEN WICK JARS",
			"label": "WOODEN WICK JARS"
	},
	{
			"value": "WOOD FRAME",
			"label": "WOOD FRAME"
	},
	{
			"value": "WOOD FRAME POT",
			"label": "WOOD FRAME POT"
	},
	{
			"value": "WOOD KITS",
			"label": "WOOD KITS"
	},
	{
			"value": "WOODLAND",
			"label": "WOODLAND"
	},
	{
			"value": "WOODLAND 1",
			"label": "WOODLAND 1"
	},
	{
			"value": "WOODLAND 2",
			"label": "WOODLAND 2"
	},
	{
			"value": "WOODLAND A",
			"label": "WOODLAND A"
	},
	{
			"value": "WOODLAND ANIMAL",
			"label": "WOODLAND ANIMAL"
	},
	{
			"value": "WOODLAND ANIMALS",
			"label": "WOODLAND ANIMALS"
	},
	{
			"value": "WOODLAND LODGE",
			"label": "WOODLAND LODGE"
	},
	{
			"value": "WOODLAND LODGE OSO",
			"label": "WOODLAND LODGE OSO"
	},
	{
			"value": "WOODLANDS",
			"label": "WOODLANDS"
	},
	{
			"value": "WOOD MASK",
			"label": "WOOD MASK"
	},
	{
			"value": "WOOD/METAL WORKING",
			"label": "WOOD/METAL WORKING"
	},
	{
			"value": "WOOD MODEL TOYS",
			"label": "WOOD MODEL TOYS"
	},
	{
			"value": "WOOD NOVELTY BH",
			"label": "WOOD NOVELTY BH"
	},
	{
			"value": "WOOD ORNAMENT",
			"label": "WOOD ORNAMENT"
	},
	{
			"value": "WOOD PADDLE",
			"label": "WOOD PADDLE"
	},
	{
			"value": "WOOD PAINT AND STITC",
			"label": "WOOD PAINT AND STITC"
	},
	{
			"value": "WOOD PAINTING KIT",
			"label": "WOOD PAINTING KIT"
	},
	{
			"value": "WOOD PAINT KIT",
			"label": "WOOD PAINT KIT"
	},
	{
			"value": "WOOD PAPER",
			"label": "WOOD PAPER"
	},
	{
			"value": "WOOD PLANTER",
			"label": "WOOD PLANTER"
	},
	{
			"value": "WOOD POLE",
			"label": "WOOD POLE"
	},
	{
			"value": "WOOD PUZZLES",
			"label": "WOOD PUZZLES"
	},
	{
			"value": "WOODSCAPES",
			"label": "WOODSCAPES"
	},
	{
			"value": "WOOD STAKE",
			"label": "WOOD STAKE"
	},
	{
			"value": "WOOD TABLE",
			"label": "WOOD TABLE"
	},
	{
			"value": "WOOD TABLE DECOR",
			"label": "WOOD TABLE DECOR"
	},
	{
			"value": "WOOD TABLE PS",
			"label": "WOOD TABLE PS"
	},
	{
			"value": "WOOD TABLE TOP DECOR",
			"label": "WOOD TABLE TOP DECOR"
	},
	{
			"value": "WOOD THREAD RACK",
			"label": "WOOD THREAD RACK"
	},
	{
			"value": "WOOD TRAY",
			"label": "WOOD TRAY"
	},
	{
			"value": "WOOD TREE",
			"label": "WOOD TREE"
	},
	{
			"value": "WOOD TROUGH",
			"label": "WOOD TROUGH"
	},
	{
			"value": "WOOD VENEER",
			"label": "WOOD VENEER"
	},
	{
			"value": "WOOD WALL DECOR",
			"label": "WOOD WALL DECOR"
	},
	{
			"value": "WOOD WALL DÉCOR",
			"label": "WOOD WALL DÉCOR"
	},
	{
			"value": "WOOD WALL HANGING",
			"label": "WOOD WALL HANGING"
	},
	{
			"value": "WOOD WINDCHIME",
			"label": "WOOD WINDCHIME"
	},
	{
			"value": "WOOL",
			"label": "WOOL"
	},
	{
			"value": "WORD",
			"label": "WORD"
	},
	{
			"value": "WORD BLOCK",
			"label": "WORD BLOCK"
	},
	{
			"value": "WORDBLOCK",
			"label": "WORDBLOCK"
	},
	{
			"value": "WORD BLOCKS",
			"label": "WORD BLOCKS"
	},
	{
			"value": "WORD DECOR",
			"label": "WORD DECOR"
	},
	{
			"value": "WORD HUNT ASSORTMENT",
			"label": "WORD HUNT ASSORTMENT"
	},
	{
			"value": "WORDS",
			"label": "WORDS"
	},
	{
			"value": "WORD SEARCH PUZZLES",
			"label": "WORD SEARCH PUZZLES"
	},
	{
			"value": "WORDS TABLE TOP DECO",
			"label": "WORDS TABLE TOP DECO"
	},
	{
			"value": "WORD TABLE DECOR",
			"label": "WORD TABLE DECOR"
	},
	{
			"value": "WORD TABLETOP DECOR",
			"label": "WORD TABLETOP DECOR"
	},
	{
			"value": "WORD WALL DECOR",
			"label": "WORD WALL DECOR"
	},
	{
			"value": "WORD WALL HANGING",
			"label": "WORD WALL HANGING"
	},
	{
			"value": "WORKBOOK",
			"label": "WORKBOOK"
	},
	{
			"value": "WORKER DINOS",
			"label": "WORKER DINOS"
	},
	{
			"value": "WORKMAN PUBLISHING",
			"label": "WORKMAN PUBLISHING"
	},
	{
			"value": "WORKS OF AHHH",
			"label": "WORKS OF AHHH"
	},
	{
			"value": "WORKS OF AHHH...",
			"label": "WORKS OF AHHH..."
	},
	{
			"value": "WORLD BAZAAR",
			"label": "WORLD BAZAAR"
	},
	{
			"value": "WORLD WD",
			"label": "WORLD WD"
	},
	{
			"value": "WOUTHWEST",
			"label": "WOUTHWEST"
	},
	{
			"value": "WOVEN",
			"label": "WOVEN"
	},
	{
			"value": "WOVEN BASKET",
			"label": "WOVEN BASKET"
	},
	{
			"value": "WOVEN GEO",
			"label": "WOVEN GEO"
	},
	{
			"value": "WOVEN LANTERN",
			"label": "WOVEN LANTERN"
	},
	{
			"value": "WOVEN PLANT STAND",
			"label": "WOVEN PLANT STAND"
	},
	{
			"value": "WOVEN RUG",
			"label": "WOVEN RUG"
	},
	{
			"value": "WOVEN RUGS",
			"label": "WOVEN RUGS"
	},
	{
			"value": "WOVENS",
			"label": "WOVENS"
	},
	{
			"value": "WOVEN SEA LIFE",
			"label": "WOVEN SEA LIFE"
	},
	{
			"value": "WOVEN STRIPE",
			"label": "WOVEN STRIPE"
	},
	{
			"value": "WOVEN STRIPES",
			"label": "WOVEN STRIPES"
	},
	{
			"value": "WOVEN TRAY",
			"label": "WOVEN TRAY"
	},
	{
			"value": "WOVEN TROPICAL",
			"label": "WOVEN TROPICAL"
	},
	{
			"value": "WRAP",
			"label": "WRAP"
	},
	{
			"value": "WRAP NECKLACE",
			"label": "WRAP NECKLACE"
	},
	{
			"value": "WRAP N ZAP COTTON",
			"label": "WRAP N ZAP COTTON"
	},
	{
			"value": "WRAPPERS",
			"label": "WRAPPERS"
	},
	{
			"value": "WRD",
			"label": "WRD"
	},
	{
			"value": "WREATH",
			"label": "WREATH"
	},
	{
			"value": "WREATH BASE",
			"label": "WREATH BASE"
	},
	{
			"value": "WREATH FORM",
			"label": "WREATH FORM"
	},
	{
			"value": "WREATH FRAME",
			"label": "WREATH FRAME"
	},
	{
			"value": "WREATH HANGER",
			"label": "WREATH HANGER"
	},
	{
			"value": "WREATH HOLDER",
			"label": "WREATH HOLDER"
	},
	{
			"value": "WREATH MAKING",
			"label": "WREATH MAKING"
	},
	{
			"value": "WREATHS",
			"label": "WREATHS"
	},
	{
			"value": "WRECA00",
			"label": "WRECA00"
	},
	{
			"value": "WRIGHTS",
			"label": "WRIGHTS"
	},
	{
			"value": "WRITE AND DRAW PAD",
			"label": "WRITE AND DRAW PAD"
	},
	{
			"value": "WRITING",
			"label": "WRITING"
	},
	{
			"value": "WRITING PEN/MARKERS",
			"label": "WRITING PEN/MARKERS"
	},
	{
			"value": "WRITING TABLET",
			"label": "WRITING TABLET"
	},
	{
			"value": "WRTH",
			"label": "WRTH"
	},
	{
			"value": "WS",
			"label": "WS"
	},
	{
			"value": "W SINGLE FOLD",
			"label": "W SINGLE FOLD"
	},
	{
			"value": "WUBBY",
			"label": "WUBBY"
	},
	{
			"value": "W WAX CERAMIC CANDLE",
			"label": "W WAX CERAMIC CANDLE"
	},
	{
			"value": "W WAX MELTS CANDLE",
			"label": "W WAX MELTS CANDLE"
	},
	{
			"value": "W WAX WAX CANDLE",
			"label": "W WAX WAX CANDLE"
	},
	{
			"value": "W WHITE WHITE CANDLE",
			"label": "W WHITE WHITE CANDLE"
	},
	{
			"value": "X 60 ROUND",
			"label": "X 60 ROUND"
	},
	{
			"value": "X6 FRAME",
			"label": "X6 FRAME"
	},
	{
			"value": "XACTO KNIVES",
			"label": "XACTO KNIVES"
	},
	{
			"value": "XFINE TUBE",
			"label": "XFINE TUBE"
	},
	{
			"value": "XLARGE",
			"label": "XLARGE"
	},
	{
			"value": "XL BAG",
			"label": "XL BAG"
	},
	{
			"value": "XL BAGS",
			"label": "XL BAGS"
	},
	{
			"value": "XL ORNAMENT",
			"label": "XL ORNAMENT"
	},
	{
			"value": "XL PUMPKIN",
			"label": "XL PUMPKIN"
	},
	{
			"value": "XL SHATTERPROOF",
			"label": "XL SHATTERPROOF"
	},
	{
			"value": "XL TAPER",
			"label": "XL TAPER"
	},
	{
			"value": "XMAS",
			"label": "XMAS"
	},
	{
			"value": "XMAS BALL",
			"label": "XMAS BALL"
	},
	{
			"value": "XMAS CANVAS",
			"label": "XMAS CANVAS"
	},
	{
			"value": "XMAS COLOR IN",
			"label": "XMAS COLOR IN"
	},
	{
			"value": "XMAS CONST PAPER",
			"label": "XMAS CONST PAPER"
	},
	{
			"value": "XMAS DECO",
			"label": "XMAS DECO"
	},
	{
			"value": "XMAS DOOR HANGER",
			"label": "XMAS DOOR HANGER"
	},
	{
			"value": "XMAS EASYPATTERN",
			"label": "XMAS EASYPATTERN"
	},
	{
			"value": "XMAS FELT ACTIVITY",
			"label": "XMAS FELT ACTIVITY"
	},
	{
			"value": "XMAS FELT BASE",
			"label": "XMAS FELT BASE"
	},
	{
			"value": "XMAS FELT STICKER",
			"label": "XMAS FELT STICKER"
	},
	{
			"value": "XMAS FOAM ACTIVITY",
			"label": "XMAS FOAM ACTIVITY"
	},
	{
			"value": "XMAS FOAM BASE",
			"label": "XMAS FOAM BASE"
	},
	{
			"value": "XMAS FOAM PLATTER",
			"label": "XMAS FOAM PLATTER"
	},
	{
			"value": "XMAS FOAM STICKER",
			"label": "XMAS FOAM STICKER"
	},
	{
			"value": "XMAS FOAM STRUCTURE",
			"label": "XMAS FOAM STRUCTURE"
	},
	{
			"value": "X'MAS GRLND",
			"label": "X'MAS GRLND"
	},
	{
			"value": "XMAS HEAD BANDS",
			"label": "XMAS HEAD BANDS"
	},
	{
			"value": "XMAS KIT",
			"label": "XMAS KIT"
	},
	{
			"value": "XMAS MELTY BEAD",
			"label": "XMAS MELTY BEAD"
	},
	{
			"value": "XMAS MIBI",
			"label": "XMAS MIBI"
	},
	{
			"value": "XMAS NUTCRACKER",
			"label": "XMAS NUTCRACKER"
	},
	{
			"value": "XMAS ORNAMENT KIT",
			"label": "XMAS ORNAMENT KIT"
	},
	{
			"value": "XMAS PLAS BOBBLE",
			"label": "XMAS PLAS BOBBLE"
	},
	{
			"value": "XMAS PLAS KIT",
			"label": "XMAS PLAS KIT"
	},
	{
			"value": "XMAS PLAS MUSIC BOX",
			"label": "XMAS PLAS MUSIC BOX"
	},
	{
			"value": "XMAS PLAS ORNA",
			"label": "XMAS PLAS ORNA"
	},
	{
			"value": "XMAS PLAS WALKER",
			"label": "XMAS PLAS WALKER"
	},
	{
			"value": "XMAS POM POM KIT",
			"label": "XMAS POM POM KIT"
	},
	{
			"value": "XMAS PUZZLES",
			"label": "XMAS PUZZLES"
	},
	{
			"value": "XMAS SCRATCH ART",
			"label": "XMAS SCRATCH ART"
	},
	{
			"value": "XMAS SEWING KIT",
			"label": "XMAS SEWING KIT"
	},
	{
			"value": "XMAS SHIRNK ART",
			"label": "XMAS SHIRNK ART"
	},
	{
			"value": "XMAS STICKER BOOK",
			"label": "XMAS STICKER BOOK"
	},
	{
			"value": "XMAS SUNCATCHER",
			"label": "XMAS SUNCATCHER"
	},
	{
			"value": "X'MAS SWAG",
			"label": "X'MAS SWAG"
	},
	{
			"value": "X-MAS TREE",
			"label": "X-MAS TREE"
	},
	{
			"value": "XMAS VELVET POSTER",
			"label": "XMAS VELVET POSTER"
	},
	{
			"value": "XMAS WOOD DOLL",
			"label": "XMAS WOOD DOLL"
	},
	{
			"value": "XMAS WOOD MARKER",
			"label": "XMAS WOOD MARKER"
	},
	{
			"value": "XMAS WOOD MASK",
			"label": "XMAS WOOD MASK"
	},
	{
			"value": "XMAS WOOD PADDLE",
			"label": "XMAS WOOD PADDLE"
	},
	{
			"value": "XMAS WOOD PAINT KIT",
			"label": "XMAS WOOD PAINT KIT"
	},
	{
			"value": "XMAS WOOD STITCH KIT",
			"label": "XMAS WOOD STITCH KIT"
	},
	{
			"value": "XMAS WOOD TRAIN",
			"label": "XMAS WOOD TRAIN"
	},
	{
			"value": "X'MAS WRTH",
			"label": "X'MAS WRTH"
	},
	{
			"value": "XMSFV",
			"label": "XMSFV"
	},
	{
			"value": "XSMALL",
			"label": "XSMALL"
	},
	{
			"value": "XS PUMPKIN",
			"label": "XS PUMPKIN"
	},
	{
			"value": "XXL BAGS",
			"label": "XXL BAGS"
	},
	{
			"value": "XYRON",
			"label": "XYRON"
	},
	{
			"value": "YALEY",
			"label": "YALEY"
	},
	{
			"value": "YARD DECOR",
			"label": "YARD DECOR"
	},
	{
			"value": "YARD SIGN",
			"label": "YARD SIGN"
	},
	{
			"value": "YARD STAKE",
			"label": "YARD STAKE"
	},
	{
			"value": "YARD STAKES",
			"label": "YARD STAKES"
	},
	{
			"value": "YARN ACC BOOKS",
			"label": "YARN ACC BOOKS"
	},
	{
			"value": "YARN BOOKS",
			"label": "YARN BOOKS"
	},
	{
			"value": "YARN DYE",
			"label": "YARN DYE"
	},
	{
			"value": "YARN DYE SHIRTING",
			"label": "YARN DYE SHIRTING"
	},
	{
			"value": "YARN MAKING",
			"label": "YARN MAKING"
	},
	{
			"value": "YARNS",
			"label": "YARNS"
	},
	{
			"value": "YARNS09",
			"label": "YARNS09"
	},
	{
			"value": "YARNS16",
			"label": "YARNS16"
	},
	{
			"value": "YARNS18",
			"label": "YARNS18"
	},
	{
			"value": "YARNS19",
			"label": "YARNS19"
	},
	{
			"value": "YARNS24",
			"label": "YARNS24"
	},
	{
			"value": "YARNS28",
			"label": "YARNS28"
	},
	{
			"value": "YARNS29",
			"label": "YARNS29"
	},
	{
			"value": "YARNS32",
			"label": "YARNS32"
	},
	{
			"value": "YARNS33",
			"label": "YARNS33"
	},
	{
			"value": "YARNS36",
			"label": "YARNS36"
	},
	{
			"value": "YARNS38",
			"label": "YARNS38"
	},
	{
			"value": "YARNS39",
			"label": "YARNS39"
	},
	{
			"value": "YARNS52",
			"label": "YARNS52"
	},
	{
			"value": "YARNS81",
			"label": "YARNS81"
	},
	{
			"value": "YARNS82",
			"label": "YARNS82"
	},
	{
			"value": "YARNS83",
			"label": "YARNS83"
	},
	{
			"value": "YARNS89",
			"label": "YARNS89"
	},
	{
			"value": "YARNSA00",
			"label": "YARNSA00"
	},
	{
			"value": "YARNSB00",
			"label": "YARNSB00"
	},
	{
			"value": "YARNSC00",
			"label": "YARNSC00"
	},
	{
			"value": "YARNSD00",
			"label": "YARNSD00"
	},
	{
			"value": "YARN TREE",
			"label": "YARN TREE"
	},
	{
			"value": "YARN WEAVING",
			"label": "YARN WEAVING"
	},
	{
			"value": "YARROW",
			"label": "YARROW"
	},
	{
			"value": "YASUTOMO",
			"label": "YASUTOMO"
	},
	{
			"value": "YELLOW",
			"label": "YELLOW"
	},
	{
			"value": "YELLOW STRIPE",
			"label": "YELLOW STRIPE"
	},
	{
			"value": "YELLOW THROW",
			"label": "YELLOW THROW"
	},
	{
			"value": "YLI CORPORATION",
			"label": "YLI CORPORATION"
	},
	{
			"value": "YLW RUDBECKIA BSH",
			"label": "YLW RUDBECKIA BSH"
	},
	{
			"value": "YODA",
			"label": "YODA"
	},
	{
			"value": "YOGA",
			"label": "YOGA"
	},
	{
			"value": "YOGA FROG",
			"label": "YOGA FROG"
	},
	{
			"value": "YORYU",
			"label": "YORYU"
	},
	{
			"value": "YOUTH",
			"label": "YOUTH"
	},
	{
			"value": "YOUTH APRON",
			"label": "YOUTH APRON"
	},
	{
			"value": "YOUTH BLUE LIGHT GL",
			"label": "YOUTH BLUE LIGHT GL"
	},
	{
			"value": "YOUTH FLIP FLOP",
			"label": "YOUTH FLIP FLOP"
	},
	{
			"value": "YOUTH SLIPPER",
			"label": "YOUTH SLIPPER"
	},
	{
			"value": "YRNACB00",
			"label": "YRNACB00"
	},
	{
			"value": "YRNACC00",
			"label": "YRNACC00"
	},
	{
			"value": "YRNFF",
			"label": "YRNFF"
	},
	{
			"value": "YRNFFA00",
			"label": "YRNFFA00"
	},
	{
			"value": "ZAMIFOLIA",
			"label": "ZAMIFOLIA"
	},
	{
			"value": "ZBCECA04",
			"label": "ZBCECA04"
	},
	{
			"value": "ZEBRA",
			"label": "ZEBRA"
	},
	{
			"value": "ZEBRA SKIN",
			"label": "ZEBRA SKIN"
	},
	{
			"value": "ZEBRINA",
			"label": "ZEBRINA"
	},
	{
			"value": "ZEN",
			"label": "ZEN"
	},
	{
			"value": "ZENSATIONS",
			"label": "ZENSATIONS"
	},
	{
			"value": "ZEN TALL PLANTER",
			"label": "ZEN TALL PLANTER"
	},
	{
			"value": "ZFLECA02",
			"label": "ZFLECA02"
	},
	{
			"value": "ZIG",
			"label": "ZIG"
	},
	{
			"value": "ZIG ZAG",
			"label": "ZIG ZAG"
	},
	{
			"value": "ZIGZAG",
			"label": "ZIGZAG"
	},
	{
			"value": "ZIG-ZAG KNITS",
			"label": "ZIG-ZAG KNITS"
	},
	{
			"value": "ZINNA",
			"label": "ZINNA"
	},
	{
			"value": "ZINNIA",
			"label": "ZINNIA"
	},
	{
			"value": "ZIPERB00",
			"label": "ZIPERB00"
	},
	{
			"value": "ZIP LOCK BAGS",
			"label": "ZIP LOCK BAGS"
	},
	{
			"value": "ZIPPER",
			"label": "ZIPPER"
	},
	{
			"value": "ZIPPER MEND",
			"label": "ZIPPER MEND"
	},
	{
			"value": "ZJWEC",
			"label": "ZJWEC"
	},
	{
			"value": "ZJWECA",
			"label": "ZJWECA"
	},
	{
			"value": "ZNFLEC",
			"label": "ZNFLEC"
	},
	{
			"value": "ZNFLEC6",
			"label": "ZNFLEC6"
	},
	{
			"value": "ZODIAC CANDLE",
			"label": "ZODIAC CANDLE"
	},
	{
			"value": "ZOMBIE",
			"label": "ZOMBIE"
	},
	{
			"value": "ZOMBIE LEGS",
			"label": "ZOMBIE LEGS"
	},
	{
			"value": "ZOO ANIMALS",
			"label": "ZOO ANIMALS"
	},
	{
			"value": "ZPCECA05",
			"label": "ZPCECA05"
	},
	{
			"value": "ZUCKER",
			"label": "ZUCKER"
	},
	{
			"value": "ZUTTER",
			"label": "ZUTTER"
	}
]

const PRODUCT_BRAND_OPTIONS = [
	{
			"value": "ABC",
			"label": "ABC"
	},
	{
			"value": "LEGION PAPER",
			"label": "LEGION PAPER"
	},
	{
			"value": "CHI BLACKHAWKS",
			"label": "CHI BLACKHAWKS"
	},
	{
			"value": "SIZZIX",
			"label": "SIZZIX"
	},
	{
			"value": "CREIGHTON",
			"label": "CREIGHTON"
	},
	{
			"value": "ABQ ISOTOPES",
			"label": "ABQ ISOTOPES"
	},
	{
			"value": "SMOKEY THE BEAR",
			"label": "SMOKEY THE BEAR"
	},
	{
			"value": "TY INC",
			"label": "TY INC"
	},
	{
			"value": "UNDERCOVER TAPE",
			"label": "UNDERCOVER TAPE"
	},
	{
			"value": "BEADALON",
			"label": "BEADALON"
	},
	{
			"value": "LEPAGE'S",
			"label": "LEPAGE'S"
	},
	{
			"value": "TOTS",
			"label": "TOTS"
	},
	{
			"value": "TWO PAPER DIVAS",
			"label": "TWO PAPER DIVAS"
	},
	{
			"value": "CARE BEARS",
			"label": "CARE BEARS"
	},
	{
			"value": "MOMENTA",
			"label": "MOMENTA"
	},
	{
			"value": "LOVE COOKING COMPANY",
			"label": "LOVE COOKING COMPANY"
	},
	{
			"value": "CLOVE",
			"label": "CLOVE"
	},
	{
			"value": "BLACK INK",
			"label": "BLACK INK"
	},
	{
			"value": "DIE-VERSIONS",
			"label": "DIE-VERSIONS"
	},
	{
			"value": "WOLFF'S",
			"label": "WOLFF'S"
	},
	{
			"value": "EUCALAN",
			"label": "EUCALAN"
	},
	{
			"value": "HOLIDAY CHEER",
			"label": "HOLIDAY CHEER"
	},
	{
			"value": "PENNY BLACK",
			"label": "PENNY BLACK"
	},
	{
			"value": "MISSISSIPPI ST",
			"label": "MISSISSIPPI ST"
	},
	{
			"value": "DARICE",
			"label": "DARICE"
	},
	{
			"value": "FAIRTIME",
			"label": "FAIRTIME"
	},
	{
			"value": "SYRACUSE",
			"label": "SYRACUSE"
	},
	{
			"value": "MAGGIE HOLMES",
			"label": "MAGGIE HOLMES"
	},
	{
			"value": "GEM TACK",
			"label": "GEM TACK"
	},
	{
			"value": "ABBEYSHEA",
			"label": "ABBEYSHEA"
	},
	{
			"value": "DEBBI MOORE DESIGNS",
			"label": "DEBBI MOORE DESIGNS"
	},
	{
			"value": "YLI",
			"label": "YLI"
	},
	{
			"value": "S LICHTENBURG",
			"label": "S LICHTENBURG"
	},
	{
			"value": "WOODSTOCK",
			"label": "WOODSTOCK"
	},
	{
			"value": "JACQUARD PROCION MX DYE",
			"label": "JACQUARD PROCION MX DYE"
	},
	{
			"value": "NATIONAL TREE",
			"label": "NATIONAL TREE"
	},
	{
			"value": "MIL BUCKS",
			"label": "MIL BUCKS"
	},
	{
			"value": "PARISIAN",
			"label": "PARISIAN"
	},
	{
			"value": "BUSY KIDS LEARNING",
			"label": "BUSY KIDS LEARNING"
	},
	{
			"value": "KANGAROO KABINETS",
			"label": "KANGAROO KABINETS"
	},
	{
			"value": "NINJA",
			"label": "NINJA"
	},
	{
			"value": "ELLEN DEGENERES",
			"label": "ELLEN DEGENERES"
	},
	{
			"value": "INDIGOBLU",
			"label": "INDIGOBLU"
	},
	{
			"value": "DYNO",
			"label": "DYNO"
	},
	{
			"value": "CRAFT ESSENTIALS",
			"label": "CRAFT ESSENTIALS"
	},
	{
			"value": "KENNEDY INTERNATIONAL",
			"label": "KENNEDY INTERNATIONAL"
	},
	{
			"value": "BORGO DE' MEDICI",
			"label": "BORGO DE' MEDICI"
	},
	{
			"value": "PHI 76ERS",
			"label": "PHI 76ERS"
	},
	{
			"value": "EVERSEWN",
			"label": "EVERSEWN"
	},
	{
			"value": "PLUSHEEZ",
			"label": "PLUSHEEZ"
	},
	{
			"value": "TON",
			"label": "TON"
	},
	{
			"value": "LITTLE DARLINGS",
			"label": "LITTLE DARLINGS"
	},
	{
			"value": "ULTRA PRO",
			"label": "ULTRA PRO"
	},
	{
			"value": "PROMAG",
			"label": "PROMAG"
	},
	{
			"value": "KNITTING BOARD",
			"label": "KNITTING BOARD"
	},
	{
			"value": "KRAZY GLUE",
			"label": "KRAZY GLUE"
	},
	{
			"value": "MELTEMS",
			"label": "MELTEMS"
	},
	{
			"value": "HEARTFELT CREATIONS",
			"label": "HEARTFELT CREATIONS"
	},
	{
			"value": "NEARLY NATURAL",
			"label": "NEARLY NATURAL"
	},
	{
			"value": "DREMEL",
			"label": "DREMEL"
	},
	{
			"value": "CRAFT-TASTIC",
			"label": "CRAFT-TASTIC"
	},
	{
			"value": "SP N CAROLINA",
			"label": "SP N CAROLINA"
	},
	{
			"value": "WASH CAPITALS",
			"label": "WASH CAPITALS"
	},
	{
			"value": "JACQUARD",
			"label": "JACQUARD"
	},
	{
			"value": "NEW JERSEY DEVILS",
			"label": "NEW JERSEY DEVILS"
	},
	{
			"value": "LA MODE",
			"label": "LA MODE"
	},
	{
			"value": "SPINRITE",
			"label": "SPINRITE"
	},
	{
			"value": "LA KINGS",
			"label": "LA KINGS"
	},
	{
			"value": "MARSHALL",
			"label": "MARSHALL"
	},
	{
			"value": "HANDY CADDY",
			"label": "HANDY CADDY"
	},
	{
			"value": "PARKS AND REC",
			"label": "PARKS AND REC"
	},
	{
			"value": "ELLIE ROSE",
			"label": "ELLIE ROSE"
	},
	{
			"value": "NETFLIX",
			"label": "NETFLIX"
	},
	{
			"value": "JOHN DEERE",
			"label": "JOHN DEERE"
	},
	{
			"value": "EDMUNDS",
			"label": "EDMUNDS"
	},
	{
			"value": "SKY CASTLE",
			"label": "SKY CASTLE"
	},
	{
			"value": "CONCORD CONFECTIONS",
			"label": "CONCORD CONFECTIONS"
	},
	{
			"value": "CRA Z SLIMY",
			"label": "CRA Z SLIMY"
	},
	{
			"value": "WASH FOOTBALL",
			"label": "WASH FOOTBALL"
	},
	{
			"value": "HUBBA BUBBA",
			"label": "HUBBA BUBBA"
	},
	{
			"value": "SP KENTUCKY",
			"label": "SP KENTUCKY"
	},
	{
			"value": "EATON SQUARE",
			"label": "EATON SQUARE"
	},
	{
			"value": "BEAD SMITH",
			"label": "BEAD SMITH"
	},
	{
			"value": "SPECTRUM NOIR",
			"label": "SPECTRUM NOIR"
	},
	{
			"value": "JANLYNN",
			"label": "JANLYNN"
	},
	{
			"value": "MASTRAD",
			"label": "MASTRAD"
	},
	{
			"value": "COLORFIN",
			"label": "COLORFIN"
	},
	{
			"value": "CLOUD 9",
			"label": "CLOUD 9"
	},
	{
			"value": "EK SUCCESS",
			"label": "EK SUCCESS"
	},
	{
			"value": "MIDWEST DESIGN",
			"label": "MIDWEST DESIGN"
	},
	{
			"value": "FAIRWAY",
			"label": "FAIRWAY"
	},
	{
			"value": "P/KAUFMANN",
			"label": "P/KAUFMANN"
	},
	{
			"value": "ALL CLEAR",
			"label": "ALL CLEAR"
	},
	{
			"value": "ECOLINE",
			"label": "ECOLINE"
	},
	{
			"value": "GUTERMANN",
			"label": "GUTERMANN"
	},
	{
			"value": "SO UNIVERSITY",
			"label": "SO UNIVERSITY"
	},
	{
			"value": "LIFETIME",
			"label": "LIFETIME"
	},
	{
			"value": "A & W OFFICE SUPPLIES",
			"label": "A & W OFFICE SUPPLIES"
	},
	{
			"value": "IMAGINATING",
			"label": "IMAGINATING"
	},
	{
			"value": "CHALK INK",
			"label": "CHALK INK"
	},
	{
			"value": "WIRE ELEMENTS",
			"label": "WIRE ELEMENTS"
	},
	{
			"value": "ELLA AND VIV",
			"label": "ELLA AND VIV"
	},
	{
			"value": "HOLBEIN",
			"label": "HOLBEIN"
	},
	{
			"value": "SULKY",
			"label": "SULKY"
	},
	{
			"value": "NEON TUESDAY",
			"label": "NEON TUESDAY"
	},
	{
			"value": "GRATEFUL DEAD",
			"label": "GRATEFUL DEAD"
	},
	{
			"value": "BOS RED SOX",
			"label": "BOS RED SOX"
	},
	{
			"value": "FOUNDATIONS DECOR",
			"label": "FOUNDATIONS DECOR"
	},
	{
			"value": "NAUTICA",
			"label": "NAUTICA"
	},
	{
			"value": "BEETLEJUICE",
			"label": "BEETLEJUICE"
	},
	{
			"value": "KOH-I-NOOR",
			"label": "KOH-I-NOOR"
	},
	{
			"value": "KC ROYALS",
			"label": "KC ROYALS"
	},
	{
			"value": "PRIMO ART",
			"label": "PRIMO ART"
	},
	{
			"value": "OOK",
			"label": "OOK"
	},
	{
			"value": "WASHINGTON",
			"label": "WASHINGTON"
	},
	{
			"value": "FANTASIA ACCESSORIES",
			"label": "FANTASIA ACCESSORIES"
	},
	{
			"value": "BACK TO THE FUTURE",
			"label": "BACK TO THE FUTURE"
	},
	{
			"value": "CROWN CANDY",
			"label": "CROWN CANDY"
	},
	{
			"value": "PALETTE MASTER",
			"label": "PALETTE MASTER"
	},
	{
			"value": "WONKA",
			"label": "WONKA"
	},
	{
			"value": "ARTESPRIX",
			"label": "ARTESPRIX"
	},
	{
			"value": "SOFTSTYLE",
			"label": "SOFTSTYLE"
	},
	{
			"value": "THREAD MAGIC",
			"label": "THREAD MAGIC"
	},
	{
			"value": "LO RAN",
			"label": "LO RAN"
	},
	{
			"value": "CARSON OPTICAL",
			"label": "CARSON OPTICAL"
	},
	{
			"value": "BETTY BANDS",
			"label": "BETTY BANDS"
	},
	{
			"value": "KENT ST",
			"label": "KENT ST"
	},
	{
			"value": "MADD CAPP PUZZLES",
			"label": "MADD CAPP PUZZLES"
	},
	{
			"value": "UTAH VALLEY UNVI",
			"label": "UTAH VALLEY UNVI"
	},
	{
			"value": "TREND",
			"label": "TREND"
	},
	{
			"value": "TIMELESS FRAMES",
			"label": "TIMELESS FRAMES"
	},
	{
			"value": "CLIP EEZ",
			"label": "CLIP EEZ"
	},
	{
			"value": "GRABBIT",
			"label": "GRABBIT"
	},
	{
			"value": "THE WHO",
			"label": "THE WHO"
	},
	{
			"value": "MAGNET SOURCE",
			"label": "MAGNET SOURCE"
	},
	{
			"value": "CRYPTON",
			"label": "CRYPTON"
	},
	{
			"value": "KANSAS",
			"label": "KANSAS"
	},
	{
			"value": "LUMI",
			"label": "LUMI"
	},
	{
			"value": "CINCINNATI",
			"label": "CINCINNATI"
	},
	{
			"value": "POPSICLE",
			"label": "POPSICLE"
	},
	{
			"value": "G&K CRAFT",
			"label": "G&K CRAFT"
	},
	{
			"value": "SQUARE BY DESIGN",
			"label": "SQUARE BY DESIGN"
	},
	{
			"value": "NCAA",
			"label": "NCAA"
	},
	{
			"value": "BUTTERCREAM",
			"label": "BUTTERCREAM"
	},
	{
			"value": "VIRGINIA TECH",
			"label": "VIRGINIA TECH"
	},
	{
			"value": "GRACE COMPANY",
			"label": "GRACE COMPANY"
	},
	{
			"value": "KOALA TOOLS",
			"label": "KOALA TOOLS"
	},
	{
			"value": "STICKO",
			"label": "STICKO"
	},
	{
			"value": "PACON",
			"label": "PACON"
	},
	{
			"value": "HAPPY HANUKKAH",
			"label": "HAPPY HANUKKAH"
	},
	{
			"value": "PREMIER PRINTS",
			"label": "PREMIER PRINTS"
	},
	{
			"value": "THE CRAFTY KIT COMPANY",
			"label": "THE CRAFTY KIT COMPANY"
	},
	{
			"value": "BEST CREATIONS",
			"label": "BEST CREATIONS"
	},
	{
			"value": "MULTICRAFT",
			"label": "MULTICRAFT"
	},
	{
			"value": "DUBLIN GIFT",
			"label": "DUBLIN GIFT"
	},
	{
			"value": "WILLIAMSBURG HANDMADE OILS",
			"label": "WILLIAMSBURG HANDMADE OILS"
	},
	{
			"value": "CENTRAL MICHIGAN",
			"label": "CENTRAL MICHIGAN"
	},
	{
			"value": "LANARTE",
			"label": "LANARTE"
	},
	{
			"value": "VIDAL",
			"label": "VIDAL"
	},
	{
			"value": "FILEXEC",
			"label": "FILEXEC"
	},
	{
			"value": "CREATIVE PUBLISHING",
			"label": "CREATIVE PUBLISHING"
	},
	{
			"value": "CARL BRANDS",
			"label": "CARL BRANDS"
	},
	{
			"value": "WORKMAN PUBLISHING",
			"label": "WORKMAN PUBLISHING"
	},
	{
			"value": "MY FAVORITE CO",
			"label": "MY FAVORITE CO"
	},
	{
			"value": "TATTERED LACE",
			"label": "TATTERED LACE"
	},
	{
			"value": "PAPER HOUSE",
			"label": "PAPER HOUSE"
	},
	{
			"value": "PAIGE EVANS",
			"label": "PAIGE EVANS"
	},
	{
			"value": "TEN ACRES",
			"label": "TEN ACRES"
	},
	{
			"value": "GHIRARDELLI",
			"label": "GHIRARDELLI"
	},
	{
			"value": "ARC CRAFTS",
			"label": "ARC CRAFTS"
	},
	{
			"value": "SUMMER INSPIRATIONS",
			"label": "SUMMER INSPIRATIONS"
	},
	{
			"value": "FLINTSTONES",
			"label": "FLINTSTONES"
	},
	{
			"value": "NY RED BULLS",
			"label": "NY RED BULLS"
	},
	{
			"value": "FLIPSIDE",
			"label": "FLIPSIDE"
	},
	{
			"value": "NC CHARLOTTE",
			"label": "NC CHARLOTTE"
	},
	{
			"value": "WEEKS DYE WORKS",
			"label": "WEEKS DYE WORKS"
	},
	{
			"value": "QUILT SENSE",
			"label": "QUILT SENSE"
	},
	{
			"value": "GWEN STUDIOS",
			"label": "GWEN STUDIOS"
	},
	{
			"value": "GOURMET HOME PRODUCTS",
			"label": "GOURMET HOME PRODUCTS"
	},
	{
			"value": "DREAMERLANDCRAFTS",
			"label": "DREAMERLANDCRAFTS"
	},
	{
			"value": "SP MASSACHUSETTS",
			"label": "SP MASSACHUSETTS"
	},
	{
			"value": "I CRAFTER",
			"label": "I CRAFTER"
	},
	{
			"value": "WEBER",
			"label": "WEBER"
	},
	{
			"value": "IRONEZ",
			"label": "IRONEZ"
	},
	{
			"value": "CORNERSTONE PRODUCTS",
			"label": "CORNERSTONE PRODUCTS"
	},
	{
			"value": "HAZEL & RUBY",
			"label": "HAZEL & RUBY"
	},
	{
			"value": "COSPLAY",
			"label": "COSPLAY"
	},
	{
			"value": "ZINGZ & THINGZ",
			"label": "ZINGZ & THINGZ"
	},
	{
			"value": "DMC",
			"label": "DMC"
	},
	{
			"value": "LIVEGREEN",
			"label": "LIVEGREEN"
	},
	{
			"value": "ETI",
			"label": "ETI"
	},
	{
			"value": "CARE COVER",
			"label": "CARE COVER"
	},
	{
			"value": "LILY SUGAR'N CREAM",
			"label": "LILY SUGAR'N CREAM"
	},
	{
			"value": "NSYNC",
			"label": "NSYNC"
	},
	{
			"value": "ENKAUSTIKOS",
			"label": "ENKAUSTIKOS"
	},
	{
			"value": "CHECKOLITE",
			"label": "CHECKOLITE"
	},
	{
			"value": "DERIVAN",
			"label": "DERIVAN"
	},
	{
			"value": "MILESTONES",
			"label": "MILESTONES"
	},
	{
			"value": "PAPER ROSE",
			"label": "PAPER ROSE"
	},
	{
			"value": "BEAR THREAD",
			"label": "BEAR THREAD"
	},
	{
			"value": "QUILT MAGIC",
			"label": "QUILT MAGIC"
	},
	{
			"value": "EZ QUILTING",
			"label": "EZ QUILTING"
	},
	{
			"value": "KRINK",
			"label": "KRINK"
	},
	{
			"value": "GEORGIA",
			"label": "GEORGIA"
	},
	{
			"value": "ZIABICKI",
			"label": "ZIABICKI"
	},
	{
			"value": "BOB ROSS",
			"label": "BOB ROSS"
	},
	{
			"value": "TONER PLASTICS",
			"label": "TONER PLASTICS"
	},
	{
			"value": "FOX RIVER",
			"label": "FOX RIVER"
	},
	{
			"value": "VALDANI",
			"label": "VALDANI"
	},
	{
			"value": "UNIVERSITY GAMES",
			"label": "UNIVERSITY GAMES"
	},
	{
			"value": "TEMKIN INTERNATIONAL INC",
			"label": "TEMKIN INTERNATIONAL INC"
	},
	{
			"value": "RETRACTABLE METRO",
			"label": "RETRACTABLE METRO"
	},
	{
			"value": "THERMOWEB",
			"label": "THERMOWEB"
	},
	{
			"value": "OXFORD",
			"label": "OXFORD"
	},
	{
			"value": "SUMMER LIVING",
			"label": "SUMMER LIVING"
	},
	{
			"value": "ALL THE RAGES",
			"label": "ALL THE RAGES"
	},
	{
			"value": "TAOS",
			"label": "TAOS"
	},
	{
			"value": "SEARCH PRESS",
			"label": "SEARCH PRESS"
	},
	{
			"value": "KELLOGGS",
			"label": "KELLOGGS"
	},
	{
			"value": "JILLIBEAN SOUP",
			"label": "JILLIBEAN SOUP"
	},
	{
			"value": "BO-NASH",
			"label": "BO-NASH"
	},
	{
			"value": "CUTTERPILLAR",
			"label": "CUTTERPILLAR"
	},
	{
			"value": "IMPRESSART",
			"label": "IMPRESSART"
	},
	{
			"value": "THOMAS KINKADE",
			"label": "THOMAS KINKADE"
	},
	{
			"value": "ESCAPE TO PARADISE",
			"label": "ESCAPE TO PARADISE"
	},
	{
			"value": "INK JET",
			"label": "INK JET"
	},
	{
			"value": "VALENTINE'S DAY",
			"label": "VALENTINE'S DAY"
	},
	{
			"value": "LAWN FAWN",
			"label": "LAWN FAWN"
	},
	{
			"value": "COMMONWEALTH BASKET",
			"label": "COMMONWEALTH BASKET"
	},
	{
			"value": "MOXXIE",
			"label": "MOXXIE"
	},
	{
			"value": "HOOOKED",
			"label": "HOOOKED"
	},
	{
			"value": "UNIVERSAL PICTURES",
			"label": "UNIVERSAL PICTURES"
	},
	{
			"value": "I LOVE LUCY",
			"label": "I LOVE LUCY"
	},
	{
			"value": "CERAMCOAT",
			"label": "CERAMCOAT"
	},
	{
			"value": "HARRY & DAVID",
			"label": "HARRY & DAVID"
	},
	{
			"value": "PEDAL",
			"label": "PEDAL"
	},
	{
			"value": "ZIPPER MEND",
			"label": "ZIPPER MEND"
	},
	{
			"value": "LIFESTYLE",
			"label": "LIFESTYLE"
	},
	{
			"value": "ULTIMATE CRAFTS",
			"label": "ULTIMATE CRAFTS"
	},
	{
			"value": "IMPULSE TOY",
			"label": "IMPULSE TOY"
	},
	{
			"value": "LA DODGERS",
			"label": "LA DODGERS"
	},
	{
			"value": "LEGO",
			"label": "LEGO"
	},
	{
			"value": "RICHLOOM FABRICS",
			"label": "RICHLOOM FABRICS"
	},
	{
			"value": "ACELLORIES",
			"label": "ACELLORIES"
	},
	{
			"value": "NICOLE MILLER",
			"label": "NICOLE MILLER"
	},
	{
			"value": "PEBBLES",
			"label": "PEBBLES"
	},
	{
			"value": "SAKURA",
			"label": "SAKURA"
	},
	{
			"value": "BEAVIS BUTTHEAD",
			"label": "BEAVIS BUTTHEAD"
	},
	{
			"value": "VERSAFINE",
			"label": "VERSAFINE"
	},
	{
			"value": "SP NEBRASKA",
			"label": "SP NEBRASKA"
	},
	{
			"value": "SMART SWEETS",
			"label": "SMART SWEETS"
	},
	{
			"value": "PEPPA PIG",
			"label": "PEPPA PIG"
	},
	{
			"value": "4M",
			"label": "4M"
	},
	{
			"value": "ARTBIN",
			"label": "ARTBIN"
	},
	{
			"value": "HERSHEY",
			"label": "HERSHEY"
	},
	{
			"value": "BRUSHO",
			"label": "BRUSHO"
	},
	{
			"value": "COTTONWOOD ARTS",
			"label": "COTTONWOOD ARTS"
	},
	{
			"value": "SP VERMONT",
			"label": "SP VERMONT"
	},
	{
			"value": "COLOR-AID",
			"label": "COLOR-AID"
	},
	{
			"value": "WENDY VECCHI",
			"label": "WENDY VECCHI"
	},
	{
			"value": "QUEEN & CO",
			"label": "QUEEN & CO"
	},
	{
			"value": "NATURE'S WAY",
			"label": "NATURE'S WAY"
	},
	{
			"value": "LITTLETON",
			"label": "LITTLETON"
	},
	{
			"value": "SUNBELT FASTENERS",
			"label": "SUNBELT FASTENERS"
	},
	{
			"value": "PITT PIRATES",
			"label": "PITT PIRATES"
	},
	{
			"value": "GOLDEN",
			"label": "GOLDEN"
	},
	{
			"value": "DECORPRINT",
			"label": "DECORPRINT"
	},
	{
			"value": "SP UTAH",
			"label": "SP UTAH"
	},
	{
			"value": "NY YANKEES",
			"label": "NY YANKEES"
	},
	{
			"value": "KNITTER'S PRIDE",
			"label": "KNITTER'S PRIDE"
	},
	{
			"value": "AUTHENTIC KNITTING BOARD",
			"label": "AUTHENTIC KNITTING BOARD"
	},
	{
			"value": "PINK POWER",
			"label": "PINK POWER"
	},
	{
			"value": "COU",
			"label": "COU"
	},
	{
			"value": "RHODIA",
			"label": "RHODIA"
	},
	{
			"value": "ITOYA",
			"label": "ITOYA"
	},
	{
			"value": "GIRL SCOUTS",
			"label": "GIRL SCOUTS"
	},
	{
			"value": "GREASE",
			"label": "GREASE"
	},
	{
			"value": "HONEY CAN DO",
			"label": "HONEY CAN DO"
	},
	{
			"value": "WONDERFUL PISTACHIOS",
			"label": "WONDERFUL PISTACHIOS"
	},
	{
			"value": "VIVA DECOR",
			"label": "VIVA DECOR"
	},
	{
			"value": "MONSTERS POP",
			"label": "MONSTERS POP"
	},
	{
			"value": "HILD",
			"label": "HILD"
	},
	{
			"value": "PREMO",
			"label": "PREMO"
	},
	{
			"value": "SATIN FLOSS",
			"label": "SATIN FLOSS"
	},
	{
			"value": "ARMADA",
			"label": "ARMADA"
	},
	{
			"value": "BEAUTY INTUITION",
			"label": "BEAUTY INTUITION"
	},
	{
			"value": "MAXI-LOCK",
			"label": "MAXI-LOCK"
	},
	{
			"value": "ERGO",
			"label": "ERGO"
	},
	{
			"value": "SHRINKY DINK",
			"label": "SHRINKY DINK"
	},
	{
			"value": "SP NEW YORK",
			"label": "SP NEW YORK"
	},
	{
			"value": "SUREBONDER",
			"label": "SUREBONDER"
	},
	{
			"value": "WILLY WONKA",
			"label": "WILLY WONKA"
	},
	{
			"value": "USA SHARPENERS",
			"label": "USA SHARPENERS"
	},
	{
			"value": "WRIGHTS",
			"label": "WRIGHTS"
	},
	{
			"value": "DA VINCI",
			"label": "DA VINCI"
	},
	{
			"value": "ARTEMIO",
			"label": "ARTEMIO"
	},
	{
			"value": "GRAB PAK",
			"label": "GRAB PAK"
	},
	{
			"value": "MAKIN'S",
			"label": "MAKIN'S"
	},
	{
			"value": "STEPHANIE RYAN",
			"label": "STEPHANIE RYAN"
	},
	{
			"value": "CHAR HORNETS",
			"label": "CHAR HORNETS"
	},
	{
			"value": "PERMAPAQUE",
			"label": "PERMAPAQUE"
	},
	{
			"value": "WASH WIZARDS",
			"label": "WASH WIZARDS"
	},
	{
			"value": "BUTTONS GALORE",
			"label": "BUTTONS GALORE"
	},
	{
			"value": "CAMPBELLS",
			"label": "CAMPBELLS"
	},
	{
			"value": "SKYLINE FURNITURE",
			"label": "SKYLINE FURNITURE"
	},
	{
			"value": "STEWART/SUPERIOR",
			"label": "STEWART/SUPERIOR"
	},
	{
			"value": "MANUSCRIPT",
			"label": "MANUSCRIPT"
	},
	{
			"value": "TEAM BEANS",
			"label": "TEAM BEANS"
	},
	{
			"value": "BEADSMITH",
			"label": "BEADSMITH"
	},
	{
			"value": "1CANOE2",
			"label": "1CANOE2"
	},
	{
			"value": "SP OHIO",
			"label": "SP OHIO"
	},
	{
			"value": "BLINGER",
			"label": "BLINGER"
	},
	{
			"value": "BUFFALO SNOW",
			"label": "BUFFALO SNOW"
	},
	{
			"value": "MY SISTIE",
			"label": "MY SISTIE"
	},
	{
			"value": "JAX JAGUARS",
			"label": "JAX JAGUARS"
	},
	{
			"value": "REN AND STIMPY",
			"label": "REN AND STIMPY"
	},
	{
			"value": "CONAGRA",
			"label": "CONAGRA"
	},
	{
			"value": "HAMMONDS",
			"label": "HAMMONDS"
	},
	{
			"value": "LITTLE MOMMY",
			"label": "LITTLE MOMMY"
	},
	{
			"value": "SP N DAKOTA",
			"label": "SP N DAKOTA"
	},
	{
			"value": "MINIONS",
			"label": "MINIONS"
	},
	{
			"value": "PORT SEA DOGS",
			"label": "PORT SEA DOGS"
	},
	{
			"value": "DUNCAN TOYS",
			"label": "DUNCAN TOYS"
	},
	{
			"value": "CIN REDS",
			"label": "CIN REDS"
	},
	{
			"value": "SANDYLION",
			"label": "SANDYLION"
	},
	{
			"value": "TOP NOTCH",
			"label": "TOP NOTCH"
	},
	{
			"value": "EXPO",
			"label": "EXPO"
	},
	{
			"value": "DEMETS",
			"label": "DEMETS"
	},
	{
			"value": "DESIGN",
			"label": "DESIGN"
	},
	{
			"value": "NORTH TEXAS",
			"label": "NORTH TEXAS"
	},
	{
			"value": "MODERN GOURMET",
			"label": "MODERN GOURMET"
	},
	{
			"value": "NATURAL ESSENTIALS INC",
			"label": "NATURAL ESSENTIALS INC"
	},
	{
			"value": "OKC THUNDER",
			"label": "OKC THUNDER"
	},
	{
			"value": "BOY SCOUTS",
			"label": "BOY SCOUTS"
	},
	{
			"value": "POKEMON",
			"label": "POKEMON"
	},
	{
			"value": "VISION FABRICS",
			"label": "VISION FABRICS"
	},
	{
			"value": "PROP-IT",
			"label": "PROP-IT"
	},
	{
			"value": "NATIONAL CHRISTMAS PRODUCTS",
			"label": "NATIONAL CHRISTMAS PRODUCTS"
	},
	{
			"value": "GLOBAL ART MATERIALS",
			"label": "GLOBAL ART MATERIALS"
	},
	{
			"value": "STA-KLEEN",
			"label": "STA-KLEEN"
	},
	{
			"value": "DUNECRAFT",
			"label": "DUNECRAFT"
	},
	{
			"value": "IMPACT",
			"label": "IMPACT"
	},
	{
			"value": "LV AVIATORS",
			"label": "LV AVIATORS"
	},
	{
			"value": "PERLER",
			"label": "PERLER"
	},
	{
			"value": "MICADOR JR",
			"label": "MICADOR JR"
	},
	{
			"value": "STEAMPUNK",
			"label": "STEAMPUNK"
	},
	{
			"value": "FLEXCUT",
			"label": "FLEXCUT"
	},
	{
			"value": "THE WARM COMPANY",
			"label": "THE WARM COMPANY"
	},
	{
			"value": "NO BABY CAKES",
			"label": "NO BABY CAKES"
	},
	{
			"value": "AUBURN",
			"label": "AUBURN"
	},
	{
			"value": "DYLUSIONS",
			"label": "DYLUSIONS"
	},
	{
			"value": "BABY CAKES",
			"label": "BABY CAKES"
	},
	{
			"value": "FOOD ITEMS",
			"label": "FOOD ITEMS"
	},
	{
			"value": "HARRISON HOWARD",
			"label": "HARRISON HOWARD"
	},
	{
			"value": "SP NEW MEXICO",
			"label": "SP NEW MEXICO"
	},
	{
			"value": "WEAVE GOT MAILLE",
			"label": "WEAVE GOT MAILLE"
	},
	{
			"value": "RUB'N BUFF",
			"label": "RUB'N BUFF"
	},
	{
			"value": "SEA KRAKEN",
			"label": "SEA KRAKEN"
	},
	{
			"value": "PELIKAN",
			"label": "PELIKAN"
	},
	{
			"value": "COLORBOX",
			"label": "COLORBOX"
	},
	{
			"value": "CHROMA",
			"label": "CHROMA"
	},
	{
			"value": "UNI-BALL",
			"label": "UNI-BALL"
	},
	{
			"value": "SPIROGRAPH",
			"label": "SPIROGRAPH"
	},
	{
			"value": "THE LITTLE COTTAGE",
			"label": "THE LITTLE COTTAGE"
	},
	{
			"value": "OGO",
			"label": "OGO"
	},
	{
			"value": "LIFE OF THE PARTY",
			"label": "LIFE OF THE PARTY"
	},
	{
			"value": "COLORADO ST",
			"label": "COLORADO ST"
	},
	{
			"value": "SP KANSAS",
			"label": "SP KANSAS"
	},
	{
			"value": "MARVY UCHIDA",
			"label": "MARVY UCHIDA"
	},
	{
			"value": "RICE UNI",
			"label": "RICE UNI"
	},
	{
			"value": "AMAZING PUTTY",
			"label": "AMAZING PUTTY"
	},
	{
			"value": "PAISLEY BANDANA",
			"label": "PAISLEY BANDANA"
	},
	{
			"value": "SALVAGED",
			"label": "SALVAGED"
	},
	{
			"value": "U-CREATE",
			"label": "U-CREATE"
	},
	{
			"value": "SLICE",
			"label": "SLICE"
	},
	{
			"value": "MY LITTLE PONY",
			"label": "MY LITTLE PONY"
	},
	{
			"value": "SPECTRAFIX",
			"label": "SPECTRAFIX"
	},
	{
			"value": "ORIGINAL GOURMET",
			"label": "ORIGINAL GOURMET"
	},
	{
			"value": "NEW IMAGE",
			"label": "NEW IMAGE"
	},
	{
			"value": "PAPIER MACHE",
			"label": "PAPIER MACHE"
	},
	{
			"value": "STAR WARS",
			"label": "STAR WARS"
	},
	{
			"value": "CITRA SOLVE",
			"label": "CITRA SOLVE"
	},
	{
			"value": "PROGRESSIVE",
			"label": "PROGRESSIVE"
	},
	{
			"value": "ETOILE",
			"label": "ETOILE"
	},
	{
			"value": "PUNCH BUNCH",
			"label": "PUNCH BUNCH"
	},
	{
			"value": "PENTEL",
			"label": "PENTEL"
	},
	{
			"value": "WORTHINGTON CYLINDERS",
			"label": "WORTHINGTON CYLINDERS"
	},
	{
			"value": "PILOT PEN",
			"label": "PILOT PEN"
	},
	{
			"value": "AR WORKSHOP",
			"label": "AR WORKSHOP"
	},
	{
			"value": "SPECTRUM",
			"label": "SPECTRUM"
	},
	{
			"value": "BODY LUXURIES",
			"label": "BODY LUXURIES"
	},
	{
			"value": "POST-IT",
			"label": "POST-IT"
	},
	{
			"value": "SMOOTHFOAM",
			"label": "SMOOTHFOAM"
	},
	{
			"value": "THE POUT POUT FISH",
			"label": "THE POUT POUT FISH"
	},
	{
			"value": "LYRA",
			"label": "LYRA"
	},
	{
			"value": "YARN ACC",
			"label": "YARN ACC"
	},
	{
			"value": "FLAIR",
			"label": "FLAIR"
	},
	{
			"value": "ERIC CARLE",
			"label": "ERIC CARLE"
	},
	{
			"value": "TANDY LEATHER FACTORY",
			"label": "TANDY LEATHER FACTORY"
	},
	{
			"value": "HOU COUGARS",
			"label": "HOU COUGARS"
	},
	{
			"value": "KRAFT TEX",
			"label": "KRAFT TEX"
	},
	{
			"value": "MARQUETTE",
			"label": "MARQUETTE"
	},
	{
			"value": "STUDIO 71",
			"label": "STUDIO 71"
	},
	{
			"value": "LITTLE FOOT",
			"label": "LITTLE FOOT"
	},
	{
			"value": "FIDGET FUN",
			"label": "FIDGET FUN"
	},
	{
			"value": "SHOPKINS",
			"label": "SHOPKINS"
	},
	{
			"value": "DONNA DEWBERRY",
			"label": "DONNA DEWBERRY"
	},
	{
			"value": "HARTFORD WHALERS",
			"label": "HARTFORD WHALERS"
	},
	{
			"value": "CANSON",
			"label": "CANSON"
	},
	{
			"value": "HELMAR",
			"label": "HELMAR"
	},
	{
			"value": "INQUE BOUTIQUE",
			"label": "INQUE BOUTIQUE"
	},
	{
			"value": "FAMOWOOD",
			"label": "FAMOWOOD"
	},
	{
			"value": "HOU DYNAMO",
			"label": "HOU DYNAMO"
	},
	{
			"value": "MEAN GIRLS",
			"label": "MEAN GIRLS"
	},
	{
			"value": "DOVER",
			"label": "DOVER"
	},
	{
			"value": "IWATA-MEDEA",
			"label": "IWATA-MEDEA"
	},
	{
			"value": "LA ANGELS",
			"label": "LA ANGELS"
	},
	{
			"value": "PAASCHE",
			"label": "PAASCHE"
	},
	{
			"value": "CLECKHEATON",
			"label": "CLECKHEATON"
	},
	{
			"value": "JELLY BELLY",
			"label": "JELLY BELLY"
	},
	{
			"value": "ARROW",
			"label": "ARROW"
	},
	{
			"value": "CAVALLINI",
			"label": "CAVALLINI"
	},
	{
			"value": "MULTI MIX",
			"label": "MULTI MIX"
	},
	{
			"value": "MILLER COORS",
			"label": "MILLER COORS"
	},
	{
			"value": "SIMPLY AUTUMN",
			"label": "SIMPLY AUTUMN"
	},
	{
			"value": "NFL",
			"label": "NFL"
	},
	{
			"value": "MIN VIKINGS",
			"label": "MIN VIKINGS"
	},
	{
			"value": "KANSAS ST",
			"label": "KANSAS ST"
	},
	{
			"value": "BEE INTERNATIONAL",
			"label": "BEE INTERNATIONAL"
	},
	{
			"value": "ST. LOUIS",
			"label": "ST. LOUIS"
	},
	{
			"value": "COLORBOK",
			"label": "COLORBOK"
	},
	{
			"value": "POPPYSTAMPS",
			"label": "POPPYSTAMPS"
	},
	{
			"value": "SAN ANTONIO SPURS",
			"label": "SAN ANTONIO SPURS"
	},
	{
			"value": "NEXCARE",
			"label": "NEXCARE"
	},
	{
			"value": "MARLEN TEXTILES",
			"label": "MARLEN TEXTILES"
	},
	{
			"value": "LIL TWIST",
			"label": "LIL TWIST"
	},
	{
			"value": "PRETTY WOMAN",
			"label": "PRETTY WOMAN"
	},
	{
			"value": "SOMERSET DESIGNS",
			"label": "SOMERSET DESIGNS"
	},
	{
			"value": "WERTHERS",
			"label": "WERTHERS"
	},
	{
			"value": "GLUE ART",
			"label": "GLUE ART"
	},
	{
			"value": "NSI INTERNATIONAL",
			"label": "NSI INTERNATIONAL"
	},
	{
			"value": "PRODUCTS FROM ABROAD",
			"label": "PRODUCTS FROM ABROAD"
	},
	{
			"value": "COTTAGE MILLS",
			"label": "COTTAGE MILLS"
	},
	{
			"value": "TOYSMITH",
			"label": "TOYSMITH"
	},
	{
			"value": "KIDS LICENSES",
			"label": "KIDS LICENSES"
	},
	{
			"value": "CANVAS HOME BASICS",
			"label": "CANVAS HOME BASICS"
	},
	{
			"value": "WEVEEL",
			"label": "WEVEEL"
	},
	{
			"value": "WATSON-GUPTILL",
			"label": "WATSON-GUPTILL"
	},
	{
			"value": "WALNUT HOLLOW",
			"label": "WALNUT HOLLOW"
	},
	{
			"value": "SEI",
			"label": "SEI"
	},
	{
			"value": "ELANA GABRIELLE",
			"label": "ELANA GABRIELLE"
	},
	{
			"value": "TOMMY BAHAMA",
			"label": "TOMMY BAHAMA"
	},
	{
			"value": "SWINGLINE",
			"label": "SWINGLINE"
	},
	{
			"value": "LITTLE YELLOW BICYCLE",
			"label": "LITTLE YELLOW BICYCLE"
	},
	{
			"value": "CONNECTICUT",
			"label": "CONNECTICUT"
	},
	{
			"value": "PANASONIC",
			"label": "PANASONIC"
	},
	{
			"value": "SEA TO SHINING SEA",
			"label": "SEA TO SHINING SEA"
	},
	{
			"value": "IMAGINISTA",
			"label": "IMAGINISTA"
	},
	{
			"value": "FRIENDLY PLASTIC",
			"label": "FRIENDLY PLASTIC"
	},
	{
			"value": "MARK RICHARDS",
			"label": "MARK RICHARDS"
	},
	{
			"value": "BAL ORIOLES",
			"label": "BAL ORIOLES"
	},
	{
			"value": "KNIT HAPPY",
			"label": "KNIT HAPPY"
	},
	{
			"value": "PHIFERTEX",
			"label": "PHIFERTEX"
	},
	{
			"value": "THE COLLECTION",
			"label": "THE COLLECTION"
	},
	{
			"value": "MIAMI",
			"label": "MIAMI"
	},
	{
			"value": "PARAMOUNT",
			"label": "PARAMOUNT"
	},
	{
			"value": "FASINATIONS",
			"label": "FASINATIONS"
	},
	{
			"value": "STORAGE DESIGNS",
			"label": "STORAGE DESIGNS"
	},
	{
			"value": "SYMPHONY",
			"label": "SYMPHONY"
	},
	{
			"value": "MAKE-RAME",
			"label": "MAKE-RAME"
	},
	{
			"value": "NICKELODEON",
			"label": "NICKELODEON"
	},
	{
			"value": "ROYAL TALENS",
			"label": "ROYAL TALENS"
	},
	{
			"value": "AVERY DENNISON",
			"label": "AVERY DENNISON"
	},
	{
			"value": "SILVER BRUSH LIMITED",
			"label": "SILVER BRUSH LIMITED"
	},
	{
			"value": "BUDDEEZ",
			"label": "BUDDEEZ"
	},
	{
			"value": "ARTSMITH",
			"label": "ARTSMITH"
	},
	{
			"value": "GOLDEN HANDS",
			"label": "GOLDEN HANDS"
	},
	{
			"value": "WEAR'M",
			"label": "WEAR'M"
	},
	{
			"value": "MINN TIMBERWOLVES",
			"label": "MINN TIMBERWOLVES"
	},
	{
			"value": "EASTER",
			"label": "EASTER"
	},
	{
			"value": "AKUA",
			"label": "AKUA"
	},
	{
			"value": "WHITNEY DESIGN",
			"label": "WHITNEY DESIGN"
	},
	{
			"value": "WILLIAMSBURG",
			"label": "WILLIAMSBURG"
	},
	{
			"value": "NOTIONS MARKETING",
			"label": "NOTIONS MARKETING"
	},
	{
			"value": "BANDANNA",
			"label": "BANDANNA"
	},
	{
			"value": "SAC KINGS",
			"label": "SAC KINGS"
	},
	{
			"value": "MAPED",
			"label": "MAPED"
	},
	{
			"value": "AQUASTONE GROUP",
			"label": "AQUASTONE GROUP"
	},
	{
			"value": "A CHRISTMAS STORY",
			"label": "A CHRISTMAS STORY"
	},
	{
			"value": "HAVEL'S",
			"label": "HAVEL'S"
	},
	{
			"value": "AMERICAN & EFRID",
			"label": "AMERICAN & EFRID"
	},
	{
			"value": "NOVA",
			"label": "NOVA"
	},
	{
			"value": "SUSAN BATES",
			"label": "SUSAN BATES"
	},
	{
			"value": "GORDON COMPANIES",
			"label": "GORDON COMPANIES"
	},
	{
			"value": "POLY PELLETS",
			"label": "POLY PELLETS"
	},
	{
			"value": "SEINFELD",
			"label": "SEINFELD"
	},
	{
			"value": "SP W VIRGINIA",
			"label": "SP W VIRGINIA"
	},
	{
			"value": "SHIMELLE",
			"label": "SHIMELLE"
	},
	{
			"value": "BOBUNNY",
			"label": "BOBUNNY"
	},
	{
			"value": "BOS BRUINS",
			"label": "BOS BRUINS"
	},
	{
			"value": "BLACKSTONE",
			"label": "BLACKSTONE"
	},
	{
			"value": "CANDYRIFFIC",
			"label": "CANDYRIFFIC"
	},
	{
			"value": "TB RAYS",
			"label": "TB RAYS"
	},
	{
			"value": "CROSLEY",
			"label": "CROSLEY"
	},
	{
			"value": "PRES-ON",
			"label": "PRES-ON"
	},
	{
			"value": "FONS & PORTER",
			"label": "FONS & PORTER"
	},
	{
			"value": "HAPPY DAYS",
			"label": "HAPPY DAYS"
	},
	{
			"value": "FERRERO",
			"label": "FERRERO"
	},
	{
			"value": "BREWSTER HOME FASHIONS",
			"label": "BREWSTER HOME FASHIONS"
	},
	{
			"value": "ROALD DAHL'S MATILDA",
			"label": "ROALD DAHL'S MATILDA"
	},
	{
			"value": "GENERAL PENCIL",
			"label": "GENERAL PENCIL"
	},
	{
			"value": "GODIVA",
			"label": "GODIVA"
	},
	{
			"value": "BUF BISONS",
			"label": "BUF BISONS"
	},
	{
			"value": "STAEDTLER",
			"label": "STAEDTLER"
	},
	{
			"value": "NORTH DAKOTA ST",
			"label": "NORTH DAKOTA ST"
	},
	{
			"value": "NORTHERN ILL",
			"label": "NORTHERN ILL"
	},
	{
			"value": "IND COLTS",
			"label": "IND COLTS"
	},
	{
			"value": "EXTRAOGANZA",
			"label": "EXTRAOGANZA"
	},
	{
			"value": "ABG- EDDIE BAUER",
			"label": "ABG- EDDIE BAUER"
	},
	{
			"value": "GALERIE",
			"label": "GALERIE"
	},
	{
			"value": "BUF SABRES",
			"label": "BUF SABRES"
	},
	{
			"value": "PRECIOSA",
			"label": "PRECIOSA"
	},
	{
			"value": "SEW CUTE",
			"label": "SEW CUTE"
	},
	{
			"value": "LIL POP!",
			"label": "LIL POP!"
	},
	{
			"value": "TASTE OF HOME",
			"label": "TASTE OF HOME"
	},
	{
			"value": "SANRIO",
			"label": "SANRIO"
	},
	{
			"value": "BLOOMING HOLIDAY",
			"label": "BLOOMING HOLIDAY"
	},
	{
			"value": "MADE IN AMERICA",
			"label": "MADE IN AMERICA"
	},
	{
			"value": "GRANDMA'S SECRET",
			"label": "GRANDMA'S SECRET"
	},
	{
			"value": "NO SAINTS",
			"label": "NO SAINTS"
	},
	{
			"value": "TOR BLUEJAYS",
			"label": "TOR BLUEJAYS"
	},
	{
			"value": "INK EFFECTS",
			"label": "INK EFFECTS"
	},
	{
			"value": "CLIFFORD",
			"label": "CLIFFORD"
	},
	{
			"value": "DENISE NEEDLES",
			"label": "DENISE NEEDLES"
	},
	{
			"value": "BESTEMP",
			"label": "BESTEMP"
	},
	{
			"value": "ARROW SEWING",
			"label": "ARROW SEWING"
	},
	{
			"value": "ILLUSTRATED FAITH",
			"label": "ILLUSTRATED FAITH"
	},
	{
			"value": "TROLLS",
			"label": "TROLLS"
	},
	{
			"value": "FULLERTON TRADING COMPANY",
			"label": "FULLERTON TRADING COMPANY"
	},
	{
			"value": "HOU TEXANS",
			"label": "HOU TEXANS"
	},
	{
			"value": "OHIO UNIV",
			"label": "OHIO UNIV"
	},
	{
			"value": "WARNER BROTHERS",
			"label": "WARNER BROTHERS"
	},
	{
			"value": "COLORFORMS",
			"label": "COLORFORMS"
	},
	{
			"value": "CRAFTER'S CAFE",
			"label": "CRAFTER'S CAFE"
	},
	{
			"value": "JOLEE'S",
			"label": "JOLEE'S"
	},
	{
			"value": "BUTLER",
			"label": "BUTLER"
	},
	{
			"value": "BORDEN & RILEY",
			"label": "BORDEN & RILEY"
	},
	{
			"value": "ADHESIVE TECHNOLOGIES",
			"label": "ADHESIVE TECHNOLOGIES"
	},
	{
			"value": "PANPASTEL",
			"label": "PANPASTEL"
	},
	{
			"value": "GRAFIX",
			"label": "GRAFIX"
	},
	{
			"value": "OUTSET MEDIA",
			"label": "OUTSET MEDIA"
	},
	{
			"value": "BIG TWIST",
			"label": "BIG TWIST"
	},
	{
			"value": "CHUCKY",
			"label": "CHUCKY"
	},
	{
			"value": "X-ACTO",
			"label": "X-ACTO"
	},
	{
			"value": "PAPER MAGIC",
			"label": "PAPER MAGIC"
	},
	{
			"value": "DOODLEBUG",
			"label": "DOODLEBUG"
	},
	{
			"value": "JEN HADFIELD",
			"label": "JEN HADFIELD"
	},
	{
			"value": "TIM HOLTZ",
			"label": "TIM HOLTZ"
	},
	{
			"value": "SP NEVADA",
			"label": "SP NEVADA"
	},
	{
			"value": "WEST VILLAGE",
			"label": "WEST VILLAGE"
	},
	{
			"value": "4-H",
			"label": "4-H"
	},
	{
			"value": "SUREHOLD",
			"label": "SUREHOLD"
	},
	{
			"value": "SP NEW JERSEY",
			"label": "SP NEW JERSEY"
	},
	{
			"value": "TRULY YOURS",
			"label": "TRULY YOURS"
	},
	{
			"value": "NASCAR",
			"label": "NASCAR"
	},
	{
			"value": "EVO",
			"label": "EVO"
	},
	{
			"value": "TOMMY ART",
			"label": "TOMMY ART"
	},
	{
			"value": "HIGHLAND",
			"label": "HIGHLAND"
	},
	{
			"value": "DET PISTONS",
			"label": "DET PISTONS"
	},
	{
			"value": "JUDIKINS",
			"label": "JUDIKINS"
	},
	{
			"value": "MY FAVORITE THINGS",
			"label": "MY FAVORITE THINGS"
	},
	{
			"value": "MASHEMS",
			"label": "MASHEMS"
	},
	{
			"value": "OHIO ST",
			"label": "OHIO ST"
	},
	{
			"value": "SUN-STACHES",
			"label": "SUN-STACHES"
	},
	{
			"value": "SNAPWARE",
			"label": "SNAPWARE"
	},
	{
			"value": "EK TOOLS",
			"label": "EK TOOLS"
	},
	{
			"value": "CUPCAKE CREATIONS",
			"label": "CUPCAKE CREATIONS"
	},
	{
			"value": "FRIDAY THE 13TH",
			"label": "FRIDAY THE 13TH"
	},
	{
			"value": "CRA-Z-ART",
			"label": "CRA-Z-ART"
	},
	{
			"value": "ARMADILLO",
			"label": "ARMADILLO"
	},
	{
			"value": "DWELL STUDIO",
			"label": "DWELL STUDIO"
	},
	{
			"value": "SCULPEY",
			"label": "SCULPEY"
	},
	{
			"value": "TOOTSIE ROLL",
			"label": "TOOTSIE ROLL"
	},
	{
			"value": "MARTHA STEWART",
			"label": "MARTHA STEWART"
	},
	{
			"value": "MLB",
			"label": "MLB"
	},
	{
			"value": "THE MONKEES",
			"label": "THE MONKEES"
	},
	{
			"value": "SP S DAKOTA",
			"label": "SP S DAKOTA"
	},
	{
			"value": "ITS SEW EMMA",
			"label": "ITS SEW EMMA"
	},
	{
			"value": "TRICKY TREATS",
			"label": "TRICKY TREATS"
	},
	{
			"value": "OFFRAY",
			"label": "OFFRAY"
	},
	{
			"value": "JOANN EASTER",
			"label": "JOANN EASTER"
	},
	{
			"value": "SARGENT ART",
			"label": "SARGENT ART"
	},
	{
			"value": "LILLIAN ROSE",
			"label": "LILLIAN ROSE"
	},
	{
			"value": "PUZZLED",
			"label": "PUZZLED"
	},
	{
			"value": "CASHMERETTE",
			"label": "CASHMERETTE"
	},
	{
			"value": "AMERICAN OAK",
			"label": "AMERICAN OAK"
	},
	{
			"value": "US AIR FORCE",
			"label": "US AIR FORCE"
	},
	{
			"value": "COLOR VARIATIONS",
			"label": "COLOR VARIATIONS"
	},
	{
			"value": "FUN KINGDOM",
			"label": "FUN KINGDOM"
	},
	{
			"value": "ACTIVA",
			"label": "ACTIVA"
	},
	{
			"value": "ESSELTE CORPORATION",
			"label": "ESSELTE CORPORATION"
	},
	{
			"value": "POP! SUMMER",
			"label": "POP! SUMMER"
	},
	{
			"value": "RUBY ROCK-IT",
			"label": "RUBY ROCK-IT"
	},
	{
			"value": "SEW CLASSIC",
			"label": "SEW CLASSIC"
	},
	{
			"value": "DAL STARS",
			"label": "DAL STARS"
	},
	{
			"value": "GOSSI",
			"label": "GOSSI"
	},
	{
			"value": "STITCH & SPARKLE",
			"label": "STITCH & SPARKLE"
	},
	{
			"value": "MEMORY BOOK",
			"label": "MEMORY BOOK"
	},
	{
			"value": "FROSTY",
			"label": "FROSTY"
	},
	{
			"value": "SUPERMOSS",
			"label": "SUPERMOSS"
	},
	{
			"value": "PJ MASKS",
			"label": "PJ MASKS"
	},
	{
			"value": "MADE IN THE USA",
			"label": "MADE IN THE USA"
	},
	{
			"value": "POLYFORM",
			"label": "POLYFORM"
	},
	{
			"value": "TESTRITE",
			"label": "TESTRITE"
	},
	{
			"value": "SUGAROX CANDY",
			"label": "SUGAROX CANDY"
	},
	{
			"value": "MASTERSON",
			"label": "MASTERSON"
	},
	{
			"value": "AUTHENTIQUE COLLECTION",
			"label": "AUTHENTIQUE COLLECTION"
	},
	{
			"value": "FAVORITE FINDINGS",
			"label": "FAVORITE FINDINGS"
	},
	{
			"value": "EL PASO CHIHUAHUAS",
			"label": "EL PASO CHIHUAHUAS"
	},
	{
			"value": "AUNTIE ANNE'S",
			"label": "AUNTIE ANNE'S"
	},
	{
			"value": "ESSENTIALS",
			"label": "ESSENTIALS"
	},
	{
			"value": "CRESTMONT",
			"label": "CRESTMONT"
	},
	{
			"value": "DOWNTON ABBEY",
			"label": "DOWNTON ABBEY"
	},
	{
			"value": "ORACAL",
			"label": "ORACAL"
	},
	{
			"value": "CACHET/DALER-ROWNEY",
			"label": "CACHET/DALER-ROWNEY"
	},
	{
			"value": "STIR",
			"label": "STIR"
	},
	{
			"value": "MIA MARLINS",
			"label": "MIA MARLINS"
	},
	{
			"value": "HOU ROCKETS",
			"label": "HOU ROCKETS"
	},
	{
			"value": "ELECTRIC QUILT",
			"label": "ELECTRIC QUILT"
	},
	{
			"value": "GUIDELINES4QUILTING",
			"label": "GUIDELINES4QUILTING"
	},
	{
			"value": "TOLEDO",
			"label": "TOLEDO"
	},
	{
			"value": "RAINDROPS",
			"label": "RAINDROPS"
	},
	{
			"value": "C-THRU",
			"label": "C-THRU"
	},
	{
			"value": "BLOOMING AUTUMN",
			"label": "BLOOMING AUTUMN"
	},
	{
			"value": "CARDINAL DIRECT COMPANY",
			"label": "CARDINAL DIRECT COMPANY"
	},
	{
			"value": "TULIP",
			"label": "TULIP"
	},
	{
			"value": "TRUE COLORS",
			"label": "TRUE COLORS"
	},
	{
			"value": "ROC-LON",
			"label": "ROC-LON"
	},
	{
			"value": "MINN WILD",
			"label": "MINN WILD"
	},
	{
			"value": "ELIZABETH CRAFT DESIGNS",
			"label": "ELIZABETH CRAFT DESIGNS"
	},
	{
			"value": "GENEVIEVE GORDER",
			"label": "GENEVIEVE GORDER"
	},
	{
			"value": "PAVILION BOOKS",
			"label": "PAVILION BOOKS"
	},
	{
			"value": "VENTURE PRODUCTS",
			"label": "VENTURE PRODUCTS"
	},
	{
			"value": "PLUS PLUS",
			"label": "PLUS PLUS"
	},
	{
			"value": "SWAROVSKI",
			"label": "SWAROVSKI"
	},
	{
			"value": "RICHLOOM STUDIO",
			"label": "RICHLOOM STUDIO"
	},
	{
			"value": "BCI CRAFTS",
			"label": "BCI CRAFTS"
	},
	{
			"value": "YARN VALET",
			"label": "YARN VALET"
	},
	{
			"value": "OASIS",
			"label": "OASIS"
	},
	{
			"value": "AURIENT",
			"label": "AURIENT"
	},
	{
			"value": "NEBRASKA",
			"label": "NEBRASKA"
	},
	{
			"value": "KIT KAT",
			"label": "KIT KAT"
	},
	{
			"value": "GEL PRES",
			"label": "GEL PRES"
	},
	{
			"value": "NATE BERKUS",
			"label": "NATE BERKUS"
	},
	{
			"value": "BIENFANG",
			"label": "BIENFANG"
	},
	{
			"value": "WILLIAM MITCHELL",
			"label": "WILLIAM MITCHELL"
	},
	{
			"value": "OLD WORLD",
			"label": "OLD WORLD"
	},
	{
			"value": "GALLERY GLASS",
			"label": "GALLERY GLASS"
	},
	{
			"value": "TRUDEAU",
			"label": "TRUDEAU"
	},
	{
			"value": "BLOOMING SPRING",
			"label": "BLOOMING SPRING"
	},
	{
			"value": "CLE CAVS",
			"label": "CLE CAVS"
	},
	{
			"value": "CROWN JEWELZ",
			"label": "CROWN JEWELZ"
	},
	{
			"value": "YKK",
			"label": "YKK"
	},
	{
			"value": "WESTON",
			"label": "WESTON"
	},
	{
			"value": "SEA MARINERS",
			"label": "SEA MARINERS"
	},
	{
			"value": "SP COLORADO",
			"label": "SP COLORADO"
	},
	{
			"value": "WOOKY ENTERTAINMENT",
			"label": "WOOKY ENTERTAINMENT"
	},
	{
			"value": "TRANSFER MAGIC",
			"label": "TRANSFER MAGIC"
	},
	{
			"value": "DANIEL TIGER",
			"label": "DANIEL TIGER"
	},
	{
			"value": "METAL BRAID",
			"label": "METAL BRAID"
	},
	{
			"value": "SOYTHIN",
			"label": "SOYTHIN"
	},
	{
			"value": "HANCY",
			"label": "HANCY"
	},
	{
			"value": "CHASE",
			"label": "CHASE"
	},
	{
			"value": "SANFORD",
			"label": "SANFORD"
	},
	{
			"value": "DIXON",
			"label": "DIXON"
	},
	{
			"value": "WOODLAND SCENICS",
			"label": "WOODLAND SCENICS"
	},
	{
			"value": "AUTUMN INSPIRATIONS",
			"label": "AUTUMN INSPIRATIONS"
	},
	{
			"value": "DAYLIGHT",
			"label": "DAYLIGHT"
	},
	{
			"value": "WORTHY PROMO",
			"label": "WORTHY PROMO"
	},
	{
			"value": "CAR PANTHERS",
			"label": "CAR PANTHERS"
	},
	{
			"value": "Z-POXY",
			"label": "Z-POXY"
	},
	{
			"value": "STUDIO DESIGNS",
			"label": "STUDIO DESIGNS"
	},
	{
			"value": "SIMPLY SOFT",
			"label": "SIMPLY SOFT"
	},
	{
			"value": "HERSEU",
			"label": "HERSEU"
	},
	{
			"value": "BLUE HILLS STUDIO",
			"label": "BLUE HILLS STUDIO"
	},
	{
			"value": "POP HOME",
			"label": "POP HOME"
	},
	{
			"value": "BERNAT",
			"label": "BERNAT"
	},
	{
			"value": "CLEMSON",
			"label": "CLEMSON"
	},
	{
			"value": "WALTER FOSTER",
			"label": "WALTER FOSTER"
	},
	{
			"value": "SP MARYLAND",
			"label": "SP MARYLAND"
	},
	{
			"value": "OAK AS",
			"label": "OAK AS"
	},
	{
			"value": "ME REDBIRDS",
			"label": "ME REDBIRDS"
	},
	{
			"value": "NOSTALGIC",
			"label": "NOSTALGIC"
	},
	{
			"value": "GRAND CENTRAL PUBLISHING",
			"label": "GRAND CENTRAL PUBLISHING"
	},
	{
			"value": "CROP IN STYLE",
			"label": "CROP IN STYLE"
	},
	{
			"value": "JACLYN SMITH",
			"label": "JACLYN SMITH"
	},
	{
			"value": "ART GALLERY",
			"label": "ART GALLERY"
	},
	{
			"value": "CARON",
			"label": "CARON"
	},
	{
			"value": "PINK PAISLEE",
			"label": "PINK PAISLEE"
	},
	{
			"value": "SUPER VALU",
			"label": "SUPER VALU"
	},
	{
			"value": "SLIPPER & CRAFT",
			"label": "SLIPPER & CRAFT"
	},
	{
			"value": "ZUCKER",
			"label": "ZUCKER"
	},
	{
			"value": "MYSTIC REBEL",
			"label": "MYSTIC REBEL"
	},
	{
			"value": "CREATIVE TEACHING PRESS",
			"label": "CREATIVE TEACHING PRESS"
	},
	{
			"value": "NORMAN ROCKWELL",
			"label": "NORMAN ROCKWELL"
	},
	{
			"value": "JUNE TAILOR",
			"label": "JUNE TAILOR"
	},
	{
			"value": "BETTER HOMES AND GARDENS",
			"label": "BETTER HOMES AND GARDENS"
	},
	{
			"value": "NEW VIEW GIFTS & ACCESSORIES",
			"label": "NEW VIEW GIFTS & ACCESSORIES"
	},
	{
			"value": "STARBUCKS",
			"label": "STARBUCKS"
	},
	{
			"value": "ET",
			"label": "ET"
	},
	{
			"value": "CKK HOME DECOR",
			"label": "CKK HOME DECOR"
	},
	{
			"value": "BEAD AVENUE",
			"label": "BEAD AVENUE"
	},
	{
			"value": "SATURDAY EVENING POST",
			"label": "SATURDAY EVENING POST"
	},
	{
			"value": "STANFORD",
			"label": "STANFORD"
	},
	{
			"value": "HANDY HANDS",
			"label": "HANDY HANDS"
	},
	{
			"value": "RTO",
			"label": "RTO"
	},
	{
			"value": "DREAMWORKS",
			"label": "DREAMWORKS"
	},
	{
			"value": "TOM AND JERRY",
			"label": "TOM AND JERRY"
	},
	{
			"value": "HOLIDAY STORAGE",
			"label": "HOLIDAY STORAGE"
	},
	{
			"value": "MARABU",
			"label": "MARABU"
	},
	{
			"value": "NELLIE'S CHOICE",
			"label": "NELLIE'S CHOICE"
	},
	{
			"value": "LENOX",
			"label": "LENOX"
	},
	{
			"value": "CHI CUBS",
			"label": "CHI CUBS"
	},
	{
			"value": "CHEERS",
			"label": "CHEERS"
	},
	{
			"value": "MALDEN INTERNATIONAL DESIGNS",
			"label": "MALDEN INTERNATIONAL DESIGNS"
	},
	{
			"value": "LSU",
			"label": "LSU"
	},
	{
			"value": "QUILTER'S RULE",
			"label": "QUILTER'S RULE"
	},
	{
			"value": "FLORIDA",
			"label": "FLORIDA"
	},
	{
			"value": "CREATE A TREAT",
			"label": "CREATE A TREAT"
	},
	{
			"value": "NY KNICKS",
			"label": "NY KNICKS"
	},
	{
			"value": "HOME INSPIRATIONS",
			"label": "HOME INSPIRATIONS"
	},
	{
			"value": "PALMER PAINT",
			"label": "PALMER PAINT"
	},
	{
			"value": "KITCHEN SELECTS",
			"label": "KITCHEN SELECTS"
	},
	{
			"value": "SATIN ICE",
			"label": "SATIN ICE"
	},
	{
			"value": "M C G TEXTILES",
			"label": "M C G TEXTILES"
	},
	{
			"value": "PRISM",
			"label": "PRISM"
	},
	{
			"value": "LICENSE",
			"label": "LICENSE"
	},
	{
			"value": "FOLKART",
			"label": "FOLKART"
	},
	{
			"value": "ANGRY BIRDS",
			"label": "ANGRY BIRDS"
	},
	{
			"value": "STAINED GLASS DIES",
			"label": "STAINED GLASS DIES"
	},
	{
			"value": "REAL SALT LK",
			"label": "REAL SALT LK"
	},
	{
			"value": "RELIABLE CORP",
			"label": "RELIABLE CORP"
	},
	{
			"value": "IMAN",
			"label": "IMAN"
	},
	{
			"value": "FOTORAMA",
			"label": "FOTORAMA"
	},
	{
			"value": "WESTERN MICHIGAN",
			"label": "WESTERN MICHIGAN"
	},
	{
			"value": "DUKE",
			"label": "DUKE"
	},
	{
			"value": "REALTREE",
			"label": "REALTREE"
	},
	{
			"value": "REGIS",
			"label": "REGIS"
	},
	{
			"value": "MOO",
			"label": "MOO"
	},
	{
			"value": "ART ALTERNATIVES",
			"label": "ART ALTERNATIVES"
	},
	{
			"value": "DOODLEBUG GROSGRAIN",
			"label": "DOODLEBUG GROSGRAIN"
	},
	{
			"value": "GREENLEAF DOLLHOUSES",
			"label": "GREENLEAF DOLLHOUSES"
	},
	{
			"value": "ZIG",
			"label": "ZIG"
	},
	{
			"value": "TUVA PUBLISHING",
			"label": "TUVA PUBLISHING"
	},
	{
			"value": "ALLIED",
			"label": "ALLIED"
	},
	{
			"value": "JACKSONS WOODWORKS",
			"label": "JACKSONS WOODWORKS"
	},
	{
			"value": "IMAGINE",
			"label": "IMAGINE"
	},
	{
			"value": "ME & MY BIG IDEAS",
			"label": "ME & MY BIG IDEAS"
	},
	{
			"value": "SYLVANIA",
			"label": "SYLVANIA"
	},
	{
			"value": "MABEF",
			"label": "MABEF"
	},
	{
			"value": "PULSAR",
			"label": "PULSAR"
	},
	{
			"value": "MIXED MEDIA",
			"label": "MIXED MEDIA"
	},
	{
			"value": "URBAN HOUSE DESIGN",
			"label": "URBAN HOUSE DESIGN"
	},
	{
			"value": "SP S CAROLINA",
			"label": "SP S CAROLINA"
	},
	{
			"value": "BELAGIO",
			"label": "BELAGIO"
	},
	{
			"value": "BOS CELTICS",
			"label": "BOS CELTICS"
	},
	{
			"value": "ELITE",
			"label": "ELITE"
	},
	{
			"value": "CRAFT E MAT",
			"label": "CRAFT E MAT"
	},
	{
			"value": "HOUSEHOLD ESSENTIALS",
			"label": "HOUSEHOLD ESSENTIALS"
	},
	{
			"value": "C.R. GIBSON",
			"label": "C.R. GIBSON"
	},
	{
			"value": "TV 90210",
			"label": "TV 90210"
	},
	{
			"value": "YARN TOOLS",
			"label": "YARN TOOLS"
	},
	{
			"value": "RIOLIS",
			"label": "RIOLIS"
	},
	{
			"value": "PLAY-DOH",
			"label": "PLAY-DOH"
	},
	{
			"value": "LA GALAXY",
			"label": "LA GALAXY"
	},
	{
			"value": "NOIRE",
			"label": "NOIRE"
	},
	{
			"value": "PEEPS",
			"label": "PEEPS"
	},
	{
			"value": "BABY SHARK",
			"label": "BABY SHARK"
	},
	{
			"value": "TESTORS",
			"label": "TESTORS"
	},
	{
			"value": "FAIRTON",
			"label": "FAIRTON"
	},
	{
			"value": "ANVIL BY GILDAN",
			"label": "ANVIL BY GILDAN"
	},
	{
			"value": "BEDAZZLER",
			"label": "BEDAZZLER"
	},
	{
			"value": "CRICUT CUTTLEBUG",
			"label": "CRICUT CUTTLEBUG"
	},
	{
			"value": "TURN IT ALL",
			"label": "TURN IT ALL"
	},
	{
			"value": "PINK & MAIN",
			"label": "PINK & MAIN"
	},
	{
			"value": "EMOTT",
			"label": "EMOTT"
	},
	{
			"value": "SP VIRGINIA",
			"label": "SP VIRGINIA"
	},
	{
			"value": "SUMMER SOL",
			"label": "SUMMER SOL"
	},
	{
			"value": "PORT TIMBERS",
			"label": "PORT TIMBERS"
	},
	{
			"value": "PEZ",
			"label": "PEZ"
	},
	{
			"value": "SIMPLICITY",
			"label": "SIMPLICITY"
	},
	{
			"value": "HEAT& BOND",
			"label": "HEAT& BOND"
	},
	{
			"value": "TWISTED WIRE",
			"label": "TWISTED WIRE"
	},
	{
			"value": "GINGHER",
			"label": "GINGHER"
	},
	{
			"value": "CRAFTWOOD",
			"label": "CRAFTWOOD"
	},
	{
			"value": "MINNESOTA",
			"label": "MINNESOTA"
	},
	{
			"value": "SIXTEEN CANDLES",
			"label": "SIXTEEN CANDLES"
	},
	{
			"value": "MASTERPIECE",
			"label": "MASTERPIECE"
	},
	{
			"value": "WELCOME HOME BRANDS",
			"label": "WELCOME HOME BRANDS"
	},
	{
			"value": "CRAFTER'S WORKSHOP",
			"label": "CRAFTER'S WORKSHOP"
	},
	{
			"value": "TOOL TRON",
			"label": "TOOL TRON"
	},
	{
			"value": "EAST CAROLINA",
			"label": "EAST CAROLINA"
	},
	{
			"value": "GREMLINS",
			"label": "GREMLINS"
	},
	{
			"value": "PHENTEX",
			"label": "PHENTEX"
	},
	{
			"value": "AMERICAN GRAFFITI",
			"label": "AMERICAN GRAFFITI"
	},
	{
			"value": "SURFACE STYLE",
			"label": "SURFACE STYLE"
	},
	{
			"value": "EZAKI GLICO USA",
			"label": "EZAKI GLICO USA"
	},
	{
			"value": "BAL RAVENS",
			"label": "BAL RAVENS"
	},
	{
			"value": "SP TEXAS",
			"label": "SP TEXAS"
	},
	{
			"value": "LION BRAND",
			"label": "LION BRAND"
	},
	{
			"value": "P GRAHAM DUNN",
			"label": "P GRAHAM DUNN"
	},
	{
			"value": "SF GIANTS",
			"label": "SF GIANTS"
	},
	{
			"value": "CRAFTY",
			"label": "CRAFTY"
	},
	{
			"value": "SIMPLIFY",
			"label": "SIMPLIFY"
	},
	{
			"value": "BERWICK",
			"label": "BERWICK"
	},
	{
			"value": "FILAMENTS",
			"label": "FILAMENTS"
	},
	{
			"value": "FARLEY",
			"label": "FARLEY"
	},
	{
			"value": "ARTIST WIRE",
			"label": "ARTIST WIRE"
	},
	{
			"value": "DALER-ROWNERY",
			"label": "DALER-ROWNERY"
	},
	{
			"value": "KAI",
			"label": "KAI"
	},
	{
			"value": "ANGELUS",
			"label": "ANGELUS"
	},
	{
			"value": "AUTUMN FLORAL",
			"label": "AUTUMN FLORAL"
	},
	{
			"value": "CBUS CLIPPERS",
			"label": "CBUS CLIPPERS"
	},
	{
			"value": "FEATHERWEIGHT",
			"label": "FEATHERWEIGHT"
	},
	{
			"value": "SILICOIL",
			"label": "SILICOIL"
	},
	{
			"value": "ANNIE SELKIE",
			"label": "ANNIE SELKIE"
	},
	{
			"value": "NUVO",
			"label": "NUVO"
	},
	{
			"value": "LIBBEY",
			"label": "LIBBEY"
	},
	{
			"value": "WEBSTER FINE ART LTD DBA",
			"label": "WEBSTER FINE ART LTD DBA"
	},
	{
			"value": "CRAFTY SECRETS",
			"label": "CRAFTY SECRETS"
	},
	{
			"value": "NATURAL WOOL",
			"label": "NATURAL WOOL"
	},
	{
			"value": "BODY RAVES",
			"label": "BODY RAVES"
	},
	{
			"value": "OLY-FUN",
			"label": "OLY-FUN"
	},
	{
			"value": "OREGON",
			"label": "OREGON"
	},
	{
			"value": "S A RICHARDS",
			"label": "S A RICHARDS"
	},
	{
			"value": "MBI",
			"label": "MBI"
	},
	{
			"value": "STAYHOLD",
			"label": "STAYHOLD"
	},
	{
			"value": "COATS & CLARK",
			"label": "COATS & CLARK"
	},
	{
			"value": "WIZARD OF OZ",
			"label": "WIZARD OF OZ"
	},
	{
			"value": "PENNCO",
			"label": "PENNCO"
	},
	{
			"value": "TOLEDO MUD HENS",
			"label": "TOLEDO MUD HENS"
	},
	{
			"value": "MCSTEVENS",
			"label": "MCSTEVENS"
	},
	{
			"value": "RETRO CLEAN",
			"label": "RETRO CLEAN"
	},
	{
			"value": "MISSOURI",
			"label": "MISSOURI"
	},
	{
			"value": "SAFARI",
			"label": "SAFARI"
	},
	{
			"value": "RILEY BLAKE DESIGNS",
			"label": "RILEY BLAKE DESIGNS"
	},
	{
			"value": "CLUELESS",
			"label": "CLUELESS"
	},
	{
			"value": "HAPPY",
			"label": "HAPPY"
	},
	{
			"value": "ETIMO",
			"label": "ETIMO"
	},
	{
			"value": "TULIP COLORSHOT",
			"label": "TULIP COLORSHOT"
	},
	{
			"value": "WILEY WALLABY",
			"label": "WILEY WALLABY"
	},
	{
			"value": "POLY-FIL",
			"label": "POLY-FIL"
	},
	{
			"value": "DAL COWBOYS",
			"label": "DAL COWBOYS"
	},
	{
			"value": "CAKE CRAFT",
			"label": "CAKE CRAFT"
	},
	{
			"value": "GORILLA GLUE",
			"label": "GORILLA GLUE"
	},
	{
			"value": "CONTACT USA",
			"label": "CONTACT USA"
	},
	{
			"value": "PURE LIFE LLC",
			"label": "PURE LIFE LLC"
	},
	{
			"value": "PARDO",
			"label": "PARDO"
	},
	{
			"value": "CREATIVE CONVERTING",
			"label": "CREATIVE CONVERTING"
	},
	{
			"value": "SAS",
			"label": "SAS"
	},
	{
			"value": "HAMPTON ART",
			"label": "HAMPTON ART"
	},
	{
			"value": "C&D VISIONARY",
			"label": "C&D VISIONARY"
	},
	{
			"value": "MY MIND'S EYE",
			"label": "MY MIND'S EYE"
	},
	{
			"value": "BLOOM DAILY PLANNERS",
			"label": "BLOOM DAILY PLANNERS"
	},
	{
			"value": "INNOVATIVE HOME CREATIONS",
			"label": "INNOVATIVE HOME CREATIONS"
	},
	{
			"value": "SUMMER DAZE",
			"label": "SUMMER DAZE"
	},
	{
			"value": "SMARTEK",
			"label": "SMARTEK"
	},
	{
			"value": "GS WARRIORS",
			"label": "GS WARRIORS"
	},
	{
			"value": "POP! XMAS",
			"label": "POP! XMAS"
	},
	{
			"value": "LEE PRODUCTS",
			"label": "LEE PRODUCTS"
	},
	{
			"value": "PATCH PRODUCTS",
			"label": "PATCH PRODUCTS"
	},
	{
			"value": "SP MINNESOTA",
			"label": "SP MINNESOTA"
	},
	{
			"value": "SMC DESIGNS",
			"label": "SMC DESIGNS"
	},
	{
			"value": "HELLO ARTIST!",
			"label": "HELLO ARTIST!"
	},
	{
			"value": "KODAK",
			"label": "KODAK"
	},
	{
			"value": "FLOWER GARDEN",
			"label": "FLOWER GARDEN"
	},
	{
			"value": "THE PENCIL GRIP",
			"label": "THE PENCIL GRIP"
	},
	{
			"value": "ULTRA MELLOW",
			"label": "ULTRA MELLOW"
	},
	{
			"value": "SP TENNESSEE",
			"label": "SP TENNESSEE"
	},
	{
			"value": "PHI EAGLES",
			"label": "PHI EAGLES"
	},
	{
			"value": "JOHN JAMES",
			"label": "JOHN JAMES"
	},
	{
			"value": "JOHN BEAD",
			"label": "JOHN BEAD"
	},
	{
			"value": "POWER RANGERS",
			"label": "POWER RANGERS"
	},
	{
			"value": "WOW!",
			"label": "WOW!"
	},
	{
			"value": "ELMER'S",
			"label": "ELMER'S"
	},
	{
			"value": "ACME",
			"label": "ACME"
	},
	{
			"value": "BUFFALO GAMES",
			"label": "BUFFALO GAMES"
	},
	{
			"value": "FRENCH GENERAL",
			"label": "FRENCH GENERAL"
	},
	{
			"value": "CORMARK",
			"label": "CORMARK"
	},
	{
			"value": "BECKER & MAYER",
			"label": "BECKER & MAYER"
	},
	{
			"value": "LORAN",
			"label": "LORAN"
	},
	{
			"value": "DET LIONS",
			"label": "DET LIONS"
	},
	{
			"value": "FLAIR ORIGINALS",
			"label": "FLAIR ORIGINALS"
	},
	{
			"value": "BOOM BALL",
			"label": "BOOM BALL"
	},
	{
			"value": "WILDLIFE",
			"label": "WILDLIFE"
	},
	{
			"value": "BE AMAZING TOYS",
			"label": "BE AMAZING TOYS"
	},
	{
			"value": "OMH STORM CHASERS",
			"label": "OMH STORM CHASERS"
	},
	{
			"value": "MOHAWK",
			"label": "MOHAWK"
	},
	{
			"value": "MAISON DE BASE",
			"label": "MAISON DE BASE"
	},
	{
			"value": "SPIRIT",
			"label": "SPIRIT"
	},
	{
			"value": "IOWA",
			"label": "IOWA"
	},
	{
			"value": "QUILT IN A DAY",
			"label": "QUILT IN A DAY"
	},
	{
			"value": "WAVERLY SUN N SHADE",
			"label": "WAVERLY SUN N SHADE"
	},
	{
			"value": "REESE",
			"label": "REESE"
	},
	{
			"value": "BAUMGARTENS",
			"label": "BAUMGARTENS"
	},
	{
			"value": "ART RESIN",
			"label": "ART RESIN"
	},
	{
			"value": "MIA HEAT",
			"label": "MIA HEAT"
	},
	{
			"value": "LINDOR",
			"label": "LINDOR"
	},
	{
			"value": "WEE SCAPES",
			"label": "WEE SCAPES"
	},
	{
			"value": "GOLDEN GIRLS",
			"label": "GOLDEN GIRLS"
	},
	{
			"value": "PATRIOTIC",
			"label": "PATRIOTIC"
	},
	{
			"value": "WILLAMSBURG",
			"label": "WILLAMSBURG"
	},
	{
			"value": "WILD BLOOMS",
			"label": "WILD BLOOMS"
	},
	{
			"value": "SOPHISTICATED FINISHES",
			"label": "SOPHISTICATED FINISHES"
	},
	{
			"value": "FERRARA",
			"label": "FERRARA"
	},
	{
			"value": "MADE 4 U STUDIO",
			"label": "MADE 4 U STUDIO"
	},
	{
			"value": "SCRATCH ART",
			"label": "SCRATCH ART"
	},
	{
			"value": "AMERICAN NEWS COMPANY LLC",
			"label": "AMERICAN NEWS COMPANY LLC"
	},
	{
			"value": "COMFORT COLOR",
			"label": "COMFORT COLOR"
	},
	{
			"value": "WOODWARE CRAFT COLLECTION",
			"label": "WOODWARE CRAFT COLLECTION"
	},
	{
			"value": "DAVID TUTERA",
			"label": "DAVID TUTERA"
	},
	{
			"value": "ALLARY",
			"label": "ALLARY"
	},
	{
			"value": "LOEW-CORNELL",
			"label": "LOEW-CORNELL"
	},
	{
			"value": "CUTE & TRENDY",
			"label": "CUTE & TRENDY"
	},
	{
			"value": "MARYLAND",
			"label": "MARYLAND"
	},
	{
			"value": "GHOSTBUSTERS",
			"label": "GHOSTBUSTERS"
	},
	{
			"value": "PINK INK DESIGNS",
			"label": "PINK INK DESIGNS"
	},
	{
			"value": "ATL HAWKS",
			"label": "ATL HAWKS"
	},
	{
			"value": "NORTH DAKOTA",
			"label": "NORTH DAKOTA"
	},
	{
			"value": "BRAINY BUCKS",
			"label": "BRAINY BUCKS"
	},
	{
			"value": "LEMON LAVENDER",
			"label": "LEMON LAVENDER"
	},
	{
			"value": "ESTES-COX CORP",
			"label": "ESTES-COX CORP"
	},
	{
			"value": "LIVE LAUGH LOVE",
			"label": "LIVE LAUGH LOVE"
	},
	{
			"value": "PATONS",
			"label": "PATONS"
	},
	{
			"value": "DOTS",
			"label": "DOTS"
	},
	{
			"value": "NEEDLE CREATIONS",
			"label": "NEEDLE CREATIONS"
	},
	{
			"value": "DEBORAH NORVILLE",
			"label": "DEBORAH NORVILLE"
	},
	{
			"value": "PEEL & IMPRESS",
			"label": "PEEL & IMPRESS"
	},
	{
			"value": "PETER RABBIT",
			"label": "PETER RABBIT"
	},
	{
			"value": "KIKKERLAND",
			"label": "KIKKERLAND"
	},
	{
			"value": "DURASHARP",
			"label": "DURASHARP"
	},
	{
			"value": "SOCK MONLEY",
			"label": "SOCK MONLEY"
	},
	{
			"value": "POP UPS",
			"label": "POP UPS"
	},
	{
			"value": "COMPOSIMOLD",
			"label": "COMPOSIMOLD"
	},
	{
			"value": "MOBIUS & RUPPERT",
			"label": "MOBIUS & RUPPERT"
	},
	{
			"value": "MR. SKETCH",
			"label": "MR. SKETCH"
	},
	{
			"value": "WINNIE THE POOH",
			"label": "WINNIE THE POOH"
	},
	{
			"value": "NORTH CAROLINA",
			"label": "NORTH CAROLINA"
	},
	{
			"value": "LEFRANC & BOURGEOIS",
			"label": "LEFRANC & BOURGEOIS"
	},
	{
			"value": "VICKI BOUTIN",
			"label": "VICKI BOUTIN"
	},
	{
			"value": "SPELLBINDERS",
			"label": "SPELLBINDERS"
	},
	{
			"value": "JERZEES",
			"label": "JERZEES"
	},
	{
			"value": "AMPERSAND",
			"label": "AMPERSAND"
	},
	{
			"value": "SP CONNECTICUT",
			"label": "SP CONNECTICUT"
	},
	{
			"value": "HERO ARTS",
			"label": "HERO ARTS"
	},
	{
			"value": "DR. SEUSS",
			"label": "DR. SEUSS"
	},
	{
			"value": "MARS/WRIGLEY",
			"label": "MARS/WRIGLEY"
	},
	{
			"value": "QUARRY BOOKS",
			"label": "QUARRY BOOKS"
	},
	{
			"value": "OTTLITE LIGHTING",
			"label": "OTTLITE LIGHTING"
	},
	{
			"value": "PROJECT LIFE",
			"label": "PROJECT LIFE"
	},
	{
			"value": "FRANK A. EDMUNDS CO.",
			"label": "FRANK A. EDMUNDS CO."
	},
	{
			"value": "GEORGE MASON",
			"label": "GEORGE MASON"
	},
	{
			"value": "ANGELINA FIBERS",
			"label": "ANGELINA FIBERS"
	},
	{
			"value": "DESIGN ORIGINALS",
			"label": "DESIGN ORIGINALS"
	},
	{
			"value": "BAG WORKS",
			"label": "BAG WORKS"
	},
	{
			"value": "STOREY PUBLISHING",
			"label": "STOREY PUBLISHING"
	},
	{
			"value": "GEAR AID",
			"label": "GEAR AID"
	},
	{
			"value": "SUPERTITE",
			"label": "SUPERTITE"
	},
	{
			"value": "THORNTON'S ART SUPPLY",
			"label": "THORNTON'S ART SUPPLY"
	},
	{
			"value": "SP HAWAII",
			"label": "SP HAWAII"
	},
	{
			"value": "PENGUIN & FISH",
			"label": "PENGUIN & FISH"
	},
	{
			"value": "CLEARPRINT",
			"label": "CLEARPRINT"
	},
	{
			"value": "CENTRAL FLORIDA",
			"label": "CENTRAL FLORIDA"
	},
	{
			"value": "TRIANGLES ON A ROLL",
			"label": "TRIANGLES ON A ROLL"
	},
	{
			"value": "NEEDLEART WORLD",
			"label": "NEEDLEART WORLD"
	},
	{
			"value": "POUT POUT FISH",
			"label": "POUT POUT FISH"
	},
	{
			"value": "PRIMROSE",
			"label": "PRIMROSE"
	},
	{
			"value": "WASH REDSKINS",
			"label": "WASH REDSKINS"
	},
	{
			"value": "NAUGAHYDE",
			"label": "NAUGAHYDE"
	},
	{
			"value": "SPYROSITY",
			"label": "SPYROSITY"
	},
	{
			"value": "NY RANGERS",
			"label": "NY RANGERS"
	},
	{
			"value": "SOLARIUM",
			"label": "SOLARIUM"
	},
	{
			"value": "PINEWOOD DERBY",
			"label": "PINEWOOD DERBY"
	},
	{
			"value": "COLOR WHEEL",
			"label": "COLOR WHEEL"
	},
	{
			"value": "K&S ENGINEERING",
			"label": "K&S ENGINEERING"
	},
	{
			"value": "NIGHTMARE BEFORE CHRISTMAS",
			"label": "NIGHTMARE BEFORE CHRISTMAS"
	},
	{
			"value": "CUPIXEL",
			"label": "CUPIXEL"
	},
	{
			"value": "DIAMOND DOTZ",
			"label": "DIAMOND DOTZ"
	},
	{
			"value": "HORIZON GROUP",
			"label": "HORIZON GROUP"
	},
	{
			"value": "KATO",
			"label": "KATO"
	},
	{
			"value": "DESIGN MEMORY CRAFT",
			"label": "DESIGN MEMORY CRAFT"
	},
	{
			"value": "SCRAPBERRY'S",
			"label": "SCRAPBERRY'S"
	},
	{
			"value": "DUO AQUA",
			"label": "DUO AQUA"
	},
	{
			"value": "LOCTITE",
			"label": "LOCTITE"
	},
	{
			"value": "NOBLE NOTIONS",
			"label": "NOBLE NOTIONS"
	},
	{
			"value": "CREATIVITY AT HOME",
			"label": "CREATIVITY AT HOME"
	},
	{
			"value": "VILLAGE LIGHTING COMPANY",
			"label": "VILLAGE LIGHTING COMPANY"
	},
	{
			"value": "KURETAKE",
			"label": "KURETAKE"
	},
	{
			"value": "DEF LEPPARD",
			"label": "DEF LEPPARD"
	},
	{
			"value": "CARTA BELLA",
			"label": "CARTA BELLA"
	},
	{
			"value": "ROBISON-ANTON",
			"label": "ROBISON-ANTON"
	},
	{
			"value": "FRANKFORD",
			"label": "FRANKFORD"
	},
	{
			"value": "NEXT LEVEL",
			"label": "NEXT LEVEL"
	},
	{
			"value": "FM BRUSH",
			"label": "FM BRUSH"
	},
	{
			"value": "ONE DIRECTION",
			"label": "ONE DIRECTION"
	},
	{
			"value": "SP STATE PRIDE",
			"label": "SP STATE PRIDE"
	},
	{
			"value": "STL CARDINALS",
			"label": "STL CARDINALS"
	},
	{
			"value": "TONKA",
			"label": "TONKA"
	},
	{
			"value": "MACMILLAN PUBLISHERS",
			"label": "MACMILLAN PUBLISHERS"
	},
	{
			"value": "DEKKO",
			"label": "DEKKO"
	},
	{
			"value": "ROSELLE",
			"label": "ROSELLE"
	},
	{
			"value": "HAMILTON BEACH",
			"label": "HAMILTON BEACH"
	},
	{
			"value": "DECORATING MAGIC",
			"label": "DECORATING MAGIC"
	},
	{
			"value": "OKLAHOMA",
			"label": "OKLAHOMA"
	},
	{
			"value": "SOFFT",
			"label": "SOFFT"
	},
	{
			"value": "CIAO BELLA",
			"label": "CIAO BELLA"
	},
	{
			"value": "ALBANESE",
			"label": "ALBANESE"
	},
	{
			"value": "BIRCH PRESS",
			"label": "BIRCH PRESS"
	},
	{
			"value": "SAWGRASS",
			"label": "SAWGRASS"
	},
	{
			"value": "JURASSIC PARK",
			"label": "JURASSIC PARK"
	},
	{
			"value": "O'LIPFA",
			"label": "O'LIPFA"
	},
	{
			"value": "PETALOO",
			"label": "PETALOO"
	},
	{
			"value": "FAIRFIELD",
			"label": "FAIRFIELD"
	},
	{
			"value": "PRIMA RE-DESIGN",
			"label": "PRIMA RE-DESIGN"
	},
	{
			"value": "SEA SEAHAWKS",
			"label": "SEA SEAHAWKS"
	},
	{
			"value": "FABRIFLAIR",
			"label": "FABRIFLAIR"
	},
	{
			"value": "CRAFT MEDLEY",
			"label": "CRAFT MEDLEY"
	},
	{
			"value": "WOW EMBOSSING POWDER",
			"label": "WOW EMBOSSING POWDER"
	},
	{
			"value": "KUNIN",
			"label": "KUNIN"
	},
	{
			"value": "PACKAGING SOURCE",
			"label": "PACKAGING SOURCE"
	},
	{
			"value": "PLAYMONSTER",
			"label": "PLAYMONSTER"
	},
	{
			"value": "COLOR ME",
			"label": "COLOR ME"
	},
	{
			"value": "MARTELLI",
			"label": "MARTELLI"
	},
	{
			"value": "CRAFT EXPRESS",
			"label": "CRAFT EXPRESS"
	},
	{
			"value": "SCOTT LIVING",
			"label": "SCOTT LIVING"
	},
	{
			"value": "HILCO",
			"label": "HILCO"
	},
	{
			"value": "TOOTSIE",
			"label": "TOOTSIE"
	},
	{
			"value": "WISCONSIN",
			"label": "WISCONSIN"
	},
	{
			"value": "BEADERS PARADISE",
			"label": "BEADERS PARADISE"
	},
	{
			"value": "PANACEA",
			"label": "PANACEA"
	},
	{
			"value": "DOSCHER",
			"label": "DOSCHER"
	},
	{
			"value": "REGIONAL SUPPLY",
			"label": "REGIONAL SUPPLY"
	},
	{
			"value": "K'NEX",
			"label": "K'NEX"
	},
	{
			"value": "SP GEORGIA",
			"label": "SP GEORGIA"
	},
	{
			"value": "WALLPOPS",
			"label": "WALLPOPS"
	},
	{
			"value": "CLE BROWNS",
			"label": "CLE BROWNS"
	},
	{
			"value": "UNIQUELY CREATIVE",
			"label": "UNIQUELY CREATIVE"
	},
	{
			"value": "CONAIR",
			"label": "CONAIR"
	},
	{
			"value": "WAKE FOREST",
			"label": "WAKE FOREST"
	},
	{
			"value": "GOOD THREAD",
			"label": "GOOD THREAD"
	},
	{
			"value": "YARN DYE",
			"label": "YARN DYE"
	},
	{
			"value": "JAMES AND THE GIANT PEACH",
			"label": "JAMES AND THE GIANT PEACH"
	},
	{
			"value": "AZ CARDINALS",
			"label": "AZ CARDINALS"
	},
	{
			"value": "STAMPING BELLA",
			"label": "STAMPING BELLA"
	},
	{
			"value": "PEBEO",
			"label": "PEBEO"
	},
	{
			"value": "SKULLDUGGERY",
			"label": "SKULLDUGGERY"
	},
	{
			"value": "QUARTET",
			"label": "QUARTET"
	},
	{
			"value": "DAL MAVS",
			"label": "DAL MAVS"
	},
	{
			"value": "POWER XL",
			"label": "POWER XL"
	},
	{
			"value": "STAR TREK",
			"label": "STAR TREK"
	},
	{
			"value": "BLUE FEATHER",
			"label": "BLUE FEATHER"
	},
	{
			"value": "IT",
			"label": "IT"
	},
	{
			"value": "NATURE'S GARDEN",
			"label": "NATURE'S GARDEN"
	},
	{
			"value": "DIAMOND ART CLUB",
			"label": "DIAMOND ART CLUB"
	},
	{
			"value": "LIQUITEX",
			"label": "LIQUITEX"
	},
	{
			"value": "LOUISVILLE BATS",
			"label": "LOUISVILLE BATS"
	},
	{
			"value": "BRAND CASTLE",
			"label": "BRAND CASTLE"
	},
	{
			"value": "FOAM ALIVE",
			"label": "FOAM ALIVE"
	},
	{
			"value": "MONA LISA",
			"label": "MONA LISA"
	},
	{
			"value": "CHICAGO METALLIC",
			"label": "CHICAGO METALLIC"
	},
	{
			"value": "AMERICAN FIBERS",
			"label": "AMERICAN FIBERS"
	},
	{
			"value": "PRANG",
			"label": "PRANG"
	},
	{
			"value": "HOCUS POCUS",
			"label": "HOCUS POCUS"
	},
	{
			"value": "PARIS SAIN-GERMAIN",
			"label": "PARIS SAIN-GERMAIN"
	},
	{
			"value": "DODGE",
			"label": "DODGE"
	},
	{
			"value": "SMARTLAB",
			"label": "SMARTLAB"
	},
	{
			"value": "COLORBASH COSMETICS",
			"label": "COLORBASH COSMETICS"
	},
	{
			"value": "J.T. TRADING",
			"label": "J.T. TRADING"
	},
	{
			"value": "ON TREND",
			"label": "ON TREND"
	},
	{
			"value": "OBED MARSHALL",
			"label": "OBED MARSHALL"
	},
	{
			"value": "SCRAPBOOK ADHESIVES",
			"label": "SCRAPBOOK ADHESIVES"
	},
	{
			"value": "FIX-A-STITCH",
			"label": "FIX-A-STITCH"
	},
	{
			"value": "THE BEATLES",
			"label": "THE BEATLES"
	},
	{
			"value": "NORTHLIGHT",
			"label": "NORTHLIGHT"
	},
	{
			"value": "SMARTMAX",
			"label": "SMARTMAX"
	},
	{
			"value": "AZTEC IMPORTS",
			"label": "AZTEC IMPORTS"
	},
	{
			"value": "DEWEYS",
			"label": "DEWEYS"
	},
	{
			"value": "DURACELL",
			"label": "DURACELL"
	},
	{
			"value": "CAFFCO INTERNATIONAL LTD",
			"label": "CAFFCO INTERNATIONAL LTD"
	},
	{
			"value": "NORDIC WARE",
			"label": "NORDIC WARE"
	},
	{
			"value": "PRYM",
			"label": "PRYM"
	},
	{
			"value": "OLISO",
			"label": "OLISO"
	},
	{
			"value": "DESIGN MASTER",
			"label": "DESIGN MASTER"
	},
	{
			"value": "ALUMICOLOR",
			"label": "ALUMICOLOR"
	},
	{
			"value": "NOTRE DAME",
			"label": "NOTRE DAME"
	},
	{
			"value": "PK LIFESTYLES",
			"label": "PK LIFESTYLES"
	},
	{
			"value": "FINETEC",
			"label": "FINETEC"
	},
	{
			"value": "SHOE GOO",
			"label": "SHOE GOO"
	},
	{
			"value": "COBBLE HILL",
			"label": "COBBLE HILL"
	},
	{
			"value": "JOSEPH GILLOTT",
			"label": "JOSEPH GILLOTT"
	},
	{
			"value": "SOUR PUNCH",
			"label": "SOUR PUNCH"
	},
	{
			"value": "WESTCOTT",
			"label": "WESTCOTT"
	},
	{
			"value": "CURIOUS CHEF",
			"label": "CURIOUS CHEF"
	},
	{
			"value": "PLUS-PLUS",
			"label": "PLUS-PLUS"
	},
	{
			"value": "LOVE MY TAPES",
			"label": "LOVE MY TAPES"
	},
	{
			"value": "ANGELINA",
			"label": "ANGELINA"
	},
	{
			"value": "SCHMETZ",
			"label": "SCHMETZ"
	},
	{
			"value": "ENTERTAINMENT EARTH INC",
			"label": "ENTERTAINMENT EARTH INC"
	},
	{
			"value": "GLAMOUR GEMS",
			"label": "GLAMOUR GEMS"
	},
	{
			"value": "49 AND MARKET",
			"label": "49 AND MARKET"
	},
	{
			"value": "SPICE DROPS",
			"label": "SPICE DROPS"
	},
	{
			"value": "SUGAR SWEET SUNSHINE",
			"label": "SUGAR SWEET SUNSHINE"
	},
	{
			"value": "HUNKYDORY CRAFTS",
			"label": "HUNKYDORY CRAFTS"
	},
	{
			"value": "GARFIELD",
			"label": "GARFIELD"
	},
	{
			"value": "GILMORE GIRLS",
			"label": "GILMORE GIRLS"
	},
	{
			"value": "PAVELKA",
			"label": "PAVELKA"
	},
	{
			"value": "AG",
			"label": "AG"
	},
	{
			"value": "APP STATE",
			"label": "APP STATE"
	},
	{
			"value": "JOJO",
			"label": "JOJO"
	},
	{
			"value": "HILDIE & JO",
			"label": "HILDIE & JO"
	},
	{
			"value": "SPACE JAM 2",
			"label": "SPACE JAM 2"
	},
	{
			"value": "PERFORMANCE",
			"label": "PERFORMANCE"
	},
	{
			"value": "JAMES THOMPSON",
			"label": "JAMES THOMPSON"
	},
	{
			"value": "DENIK",
			"label": "DENIK"
	},
	{
			"value": "SWEETWORKS",
			"label": "SWEETWORKS"
	},
	{
			"value": "CAKE MATE",
			"label": "CAKE MATE"
	},
	{
			"value": "ONE SISTER",
			"label": "ONE SISTER"
	},
	{
			"value": "SOLID OAK",
			"label": "SOLID OAK"
	},
	{
			"value": "UN DU",
			"label": "UN DU"
	},
	{
			"value": "WWE",
			"label": "WWE"
	},
	{
			"value": "ROYAL LANGNICKEL",
			"label": "ROYAL LANGNICKEL"
	},
	{
			"value": "US NAVY",
			"label": "US NAVY"
	},
	{
			"value": "ESTES INDUSTRIES",
			"label": "ESTES INDUSTRIES"
	},
	{
			"value": "KAREN FOSTER",
			"label": "KAREN FOSTER"
	},
	{
			"value": "GEORGIA TECH",
			"label": "GEORGIA TECH"
	},
	{
			"value": "NE PATS",
			"label": "NE PATS"
	},
	{
			"value": "SCENTSCLES",
			"label": "SCENTSCLES"
	},
	{
			"value": "LV RAIDERS",
			"label": "LV RAIDERS"
	},
	{
			"value": "WIKKI STIX",
			"label": "WIKKI STIX"
	},
	{
			"value": "YOU THE FAN",
			"label": "YOU THE FAN"
	},
	{
			"value": "ZIP TOP",
			"label": "ZIP TOP"
	},
	{
			"value": "ANCHOR",
			"label": "ANCHOR"
	},
	{
			"value": "MAKER'S HALLOWEEN",
			"label": "MAKER'S HALLOWEEN"
	},
	{
			"value": "BASIC GREY",
			"label": "BASIC GREY"
	},
	{
			"value": "EX CRAFT",
			"label": "EX CRAFT"
	},
	{
			"value": "FLIX",
			"label": "FLIX"
	},
	{
			"value": "PRISMACOLOR",
			"label": "PRISMACOLOR"
	},
	{
			"value": "BIC",
			"label": "BIC"
	},
	{
			"value": "BREA REESE",
			"label": "BREA REESE"
	},
	{
			"value": "VANCOUVER CANUCKS",
			"label": "VANCOUVER CANUCKS"
	},
	{
			"value": "BABYVILLE",
			"label": "BABYVILLE"
	},
	{
			"value": "KAOS",
			"label": "KAOS"
	},
	{
			"value": "MELISSA & DOUG",
			"label": "MELISSA & DOUG"
	},
	{
			"value": "FLORIDA ST",
			"label": "FLORIDA ST"
	},
	{
			"value": "PROCTOR SILEX",
			"label": "PROCTOR SILEX"
	},
	{
			"value": "DINA WAKLEY MEDIA",
			"label": "DINA WAKLEY MEDIA"
	},
	{
			"value": "THE BEADERY",
			"label": "THE BEADERY"
	},
	{
			"value": "SILHOUETTE AMERICA",
			"label": "SILHOUETTE AMERICA"
	},
	{
			"value": "OOLY",
			"label": "OOLY"
	},
	{
			"value": "DOVECRAFT",
			"label": "DOVECRAFT"
	},
	{
			"value": "OMNIGRID",
			"label": "OMNIGRID"
	},
	{
			"value": "CASPER",
			"label": "CASPER"
	},
	{
			"value": "MICHIGAN",
			"label": "MICHIGAN"
	},
	{
			"value": "MINN NORTH STARS",
			"label": "MINN NORTH STARS"
	},
	{
			"value": "CORE'DINATIONS",
			"label": "CORE'DINATIONS"
	},
	{
			"value": "CONSTRUCTION HOLIDAY",
			"label": "CONSTRUCTION HOLIDAY"
	},
	{
			"value": "GREEN LIFE",
			"label": "GREEN LIFE"
	},
	{
			"value": "TUTTO",
			"label": "TUTTO"
	},
	{
			"value": "BELLA BLVD",
			"label": "BELLA BLVD"
	},
	{
			"value": "GOO GONE",
			"label": "GOO GONE"
	},
	{
			"value": "YASUTOMO",
			"label": "YASUTOMO"
	},
	{
			"value": "USC",
			"label": "USC"
	},
	{
			"value": "CRAFT CRUSH",
			"label": "CRAFT CRUSH"
	},
	{
			"value": "LEPEN",
			"label": "LEPEN"
	},
	{
			"value": "ANDES",
			"label": "ANDES"
	},
	{
			"value": "HEADLINE/GEOTYPE",
			"label": "HEADLINE/GEOTYPE"
	},
	{
			"value": "AMY TANGERINE",
			"label": "AMY TANGERINE"
	},
	{
			"value": "DEN NUGGETS",
			"label": "DEN NUGGETS"
	},
	{
			"value": "MPI",
			"label": "MPI"
	},
	{
			"value": "PRO ART",
			"label": "PRO ART"
	},
	{
			"value": "MARTIN F. WEBER",
			"label": "MARTIN F. WEBER"
	},
	{
			"value": "MINNESOTA DULUTH",
			"label": "MINNESOTA DULUTH"
	},
	{
			"value": "ST. PATRICK'S DAY",
			"label": "ST. PATRICK'S DAY"
	},
	{
			"value": "GAMBLIN",
			"label": "GAMBLIN"
	},
	{
			"value": "DOCRAFTS",
			"label": "DOCRAFTS"
	},
	{
			"value": "MARIANNE DESIGNS",
			"label": "MARIANNE DESIGNS"
	},
	{
			"value": "KLASS",
			"label": "KLASS"
	},
	{
			"value": "COLORADO CRAFT COMPANY",
			"label": "COLORADO CRAFT COMPANY"
	},
	{
			"value": "CC INTERNATIONAL LLC",
			"label": "CC INTERNATIONAL LLC"
	},
	{
			"value": "JUST CLIP IT",
			"label": "JUST CLIP IT"
	},
	{
			"value": "TEAM SHOP",
			"label": "TEAM SHOP"
	},
	{
			"value": "YAYA HAN",
			"label": "YAYA HAN"
	},
	{
			"value": "MOUNTAIN MIST",
			"label": "MOUNTAIN MIST"
	},
	{
			"value": "SILVER CREEK",
			"label": "SILVER CREEK"
	},
	{
			"value": "FINE BRAID",
			"label": "FINE BRAID"
	},
	{
			"value": "HOFFMASTER GROUP DBA CREATIVE",
			"label": "HOFFMASTER GROUP DBA CREATIVE"
	},
	{
			"value": "POP HOLIDAY",
			"label": "POP HOLIDAY"
	},
	{
			"value": "ELECTROLUX",
			"label": "ELECTROLUX"
	},
	{
			"value": "SP ILLINOIS",
			"label": "SP ILLINOIS"
	},
	{
			"value": "COVINGTON",
			"label": "COVINGTON"
	},
	{
			"value": "DUNROVEN HOUSE",
			"label": "DUNROVEN HOUSE"
	},
	{
			"value": "SLINKY",
			"label": "SLINKY"
	},
	{
			"value": "MACPHERSONS",
			"label": "MACPHERSONS"
	},
	{
			"value": "NY METS",
			"label": "NY METS"
	},
	{
			"value": "NITRAM",
			"label": "NITRAM"
	},
	{
			"value": "K&COMPANY",
			"label": "K&COMPANY"
	},
	{
			"value": "USMC",
			"label": "USMC"
	},
	{
			"value": "SAN JOSE SHARKS",
			"label": "SAN JOSE SHARKS"
	},
	{
			"value": "U-PAINT",
			"label": "U-PAINT"
	},
	{
			"value": "JUVENTUS",
			"label": "JUVENTUS"
	},
	{
			"value": "SPRADLING",
			"label": "SPRADLING"
	},
	{
			"value": "LEADER",
			"label": "LEADER"
	},
	{
			"value": "CHARLES CRAFT",
			"label": "CHARLES CRAFT"
	},
	{
			"value": "SEA SONICS",
			"label": "SEA SONICS"
	},
	{
			"value": "TULIP NEEDLE COMPANY",
			"label": "TULIP NEEDLE COMPANY"
	},
	{
			"value": "JETSONS",
			"label": "JETSONS"
	},
	{
			"value": "DYLON",
			"label": "DYLON"
	},
	{
			"value": "LITTLE ELF",
			"label": "LITTLE ELF"
	},
	{
			"value": "RANDOM HOUSE",
			"label": "RANDOM HOUSE"
	},
	{
			"value": "INNOVATIVE CREATIONS",
			"label": "INNOVATIVE CREATIONS"
	},
	{
			"value": "CHILL CLINIC",
			"label": "CHILL CLINIC"
	},
	{
			"value": "AC FOODCRAFTING",
			"label": "AC FOODCRAFTING"
	},
	{
			"value": "TRUE HEMP",
			"label": "TRUE HEMP"
	},
	{
			"value": "SAVOIR FAIRE",
			"label": "SAVOIR FAIRE"
	},
	{
			"value": "PEARL",
			"label": "PEARL"
	},
	{
			"value": "KAREN BURNISTON",
			"label": "KAREN BURNISTON"
	},
	{
			"value": "SPORTING KC",
			"label": "SPORTING KC"
	},
	{
			"value": "MEMORY BOX",
			"label": "MEMORY BOX"
	},
	{
			"value": "FASHION & FLUFF",
			"label": "FASHION & FLUFF"
	},
	{
			"value": "YARN TOP THIS",
			"label": "YARN TOP THIS"
	},
	{
			"value": "MADE BY ME",
			"label": "MADE BY ME"
	},
	{
			"value": "OKLAHOMA ST",
			"label": "OKLAHOMA ST"
	},
	{
			"value": "VAN AKEN",
			"label": "VAN AKEN"
	},
	{
			"value": "PORT TRAILBLAZERS",
			"label": "PORT TRAILBLAZERS"
	},
	{
			"value": "QUEEN",
			"label": "QUEEN"
	},
	{
			"value": "NASHVILLE SOUNDS",
			"label": "NASHVILLE SOUNDS"
	},
	{
			"value": "RAINBOW LOOM",
			"label": "RAINBOW LOOM"
	},
	{
			"value": "MARTI MICHELL",
			"label": "MARTI MICHELL"
	},
	{
			"value": "JB MARTIN",
			"label": "JB MARTIN"
	},
	{
			"value": "WOMEN SUPPORTING WOMEN",
			"label": "WOMEN SUPPORTING WOMEN"
	},
	{
			"value": "NATIONAL GEOGRAPHIC",
			"label": "NATIONAL GEOGRAPHIC"
	},
	{
			"value": "ORNAMENT",
			"label": "ORNAMENT"
	},
	{
			"value": "REGAL FABRICS",
			"label": "REGAL FABRICS"
	},
	{
			"value": "BEE CREATIVE",
			"label": "BEE CREATIVE"
	},
	{
			"value": "STAMPERIA",
			"label": "STAMPERIA"
	},
	{
			"value": "TCG",
			"label": "TCG"
	},
	{
			"value": "TIGER TAIL",
			"label": "TIGER TAIL"
	},
	{
			"value": "CONTE",
			"label": "CONTE"
	},
	{
			"value": "MSRF",
			"label": "MSRF"
	},
	{
			"value": "MEAD",
			"label": "MEAD"
	},
	{
			"value": "SP IOWA",
			"label": "SP IOWA"
	},
	{
			"value": "BOTTLE CAP INC",
			"label": "BOTTLE CAP INC"
	},
	{
			"value": "QUILTER'S SHOWCASE",
			"label": "QUILTER'S SHOWCASE"
	},
	{
			"value": "COPIC MARKER",
			"label": "COPIC MARKER"
	},
	{
			"value": "BLOOM ROOM LITTLES",
			"label": "BLOOM ROOM LITTLES"
	},
	{
			"value": "SP N HAMPSHIRE",
			"label": "SP N HAMPSHIRE"
	},
	{
			"value": "GB PACKERS",
			"label": "GB PACKERS"
	},
	{
			"value": "CRAFTWELL",
			"label": "CRAFTWELL"
	},
	{
			"value": "TAILS & NOSES",
			"label": "TAILS & NOSES"
	},
	{
			"value": "NEAT & TANGLED",
			"label": "NEAT & TANGLED"
	},
	{
			"value": "PAPER SWRILS",
			"label": "PAPER SWRILS"
	},
	{
			"value": "SINGER",
			"label": "SINGER"
	},
	{
			"value": "WAFFLE FLOWER CRAFTS",
			"label": "WAFFLE FLOWER CRAFTS"
	},
	{
			"value": "SWEET SUGARBELLE",
			"label": "SWEET SUGARBELLE"
	},
	{
			"value": "STORAGE STUDIOS",
			"label": "STORAGE STUDIOS"
	},
	{
			"value": "DINA WAKLEY",
			"label": "DINA WAKLEY"
	},
	{
			"value": "OHIO",
			"label": "OHIO"
	},
	{
			"value": "INDIANA",
			"label": "INDIANA"
	},
	{
			"value": "COSMO CRICKET",
			"label": "COSMO CRICKET"
	},
	{
			"value": "THE BUTTON ARTIST",
			"label": "THE BUTTON ARTIST"
	},
	{
			"value": "METTLER",
			"label": "METTLER"
	},
	{
			"value": "GENERATIONS",
			"label": "GENERATIONS"
	},
	{
			"value": "SIMPLY CREATIVE",
			"label": "SIMPLY CREATIVE"
	},
	{
			"value": "MISSOURI ST",
			"label": "MISSOURI ST"
	},
	{
			"value": "EURO GRAPHICS",
			"label": "EURO GRAPHICS"
	},
	{
			"value": "MUPPETS",
			"label": "MUPPETS"
	},
	{
			"value": "MICKEY AND MINNIE",
			"label": "MICKEY AND MINNIE"
	},
	{
			"value": "DESIGN IMPORTS",
			"label": "DESIGN IMPORTS"
	},
	{
			"value": "DANIEL SMITH",
			"label": "DANIEL SMITH"
	},
	{
			"value": "UCHIDA",
			"label": "UCHIDA"
	},
	{
			"value": "TRANSOTYPE",
			"label": "TRANSOTYPE"
	},
	{
			"value": "SHASHIBO",
			"label": "SHASHIBO"
	},
	{
			"value": "ARTEZA",
			"label": "ARTEZA"
	},
	{
			"value": "ANNE OF GREEN GABLES",
			"label": "ANNE OF GREEN GABLES"
	},
	{
			"value": "THOMAS THE TANK ENGINE",
			"label": "THOMAS THE TANK ENGINE"
	},
	{
			"value": "JUST 4 GIRLS",
			"label": "JUST 4 GIRLS"
	},
	{
			"value": "BLUE FERN STUDIOS",
			"label": "BLUE FERN STUDIOS"
	},
	{
			"value": "TEMPO PRODUCTS",
			"label": "TEMPO PRODUCTS"
	},
	{
			"value": "INK JOY",
			"label": "INK JOY"
	},
	{
			"value": "GINA K DESIGNS",
			"label": "GINA K DESIGNS"
	},
	{
			"value": "LAMODE",
			"label": "LAMODE"
	},
	{
			"value": "LIVERPOOL",
			"label": "LIVERPOOL"
	},
	{
			"value": "BUCILLA",
			"label": "BUCILLA"
	},
	{
			"value": "GLOW",
			"label": "GLOW"
	},
	{
			"value": "PICKETT",
			"label": "PICKETT"
	},
	{
			"value": "STRETCHRITE",
			"label": "STRETCHRITE"
	},
	{
			"value": "SP IDAHO",
			"label": "SP IDAHO"
	},
	{
			"value": "ATL BRAVES",
			"label": "ATL BRAVES"
	},
	{
			"value": "LENNON/MCCARTNEY",
			"label": "LENNON/MCCARTNEY"
	},
	{
			"value": "CATWALK",
			"label": "CATWALK"
	},
	{
			"value": "BADGLEY MISCHKA",
			"label": "BADGLEY MISCHKA"
	},
	{
			"value": "SYMPHONY BROADCLOTH",
			"label": "SYMPHONY BROADCLOTH"
	},
	{
			"value": "MEMPHIS",
			"label": "MEMPHIS"
	},
	{
			"value": "NAVAL ACADEMY",
			"label": "NAVAL ACADEMY"
	},
	{
			"value": "FLEX SEAL",
			"label": "FLEX SEAL"
	},
	{
			"value": "DREAMHAPPY",
			"label": "DREAMHAPPY"
	},
	{
			"value": "NAKO",
			"label": "NAKO"
	},
	{
			"value": "THE OFFICE",
			"label": "THE OFFICE"
	},
	{
			"value": "LA LAKERS",
			"label": "LA LAKERS"
	},
	{
			"value": "JMW SALES",
			"label": "JMW SALES"
	},
	{
			"value": "STEADY BETTY",
			"label": "STEADY BETTY"
	},
	{
			"value": "FC DALLAS",
			"label": "FC DALLAS"
	},
	{
			"value": "MEMORY WIRE",
			"label": "MEMORY WIRE"
	},
	{
			"value": "CRAFTTEX",
			"label": "CRAFTTEX"
	},
	{
			"value": "SHEAFFER",
			"label": "SHEAFFER"
	},
	{
			"value": "LILY",
			"label": "LILY"
	},
	{
			"value": "JACK DEMPSEY",
			"label": "JACK DEMPSEY"
	},
	{
			"value": "FUNKO",
			"label": "FUNKO"
	},
	{
			"value": "SRM PRESS",
			"label": "SRM PRESS"
	},
	{
			"value": "CHAMELEON",
			"label": "CHAMELEON"
	},
	{
			"value": "GREEN CANTEEN",
			"label": "GREEN CANTEEN"
	},
	{
			"value": "CHOCO MAKER",
			"label": "CHOCO MAKER"
	},
	{
			"value": "CHELSEA",
			"label": "CHELSEA"
	},
	{
			"value": "UCLA",
			"label": "UCLA"
	},
	{
			"value": "PROGGY",
			"label": "PROGGY"
	},
	{
			"value": "GLIMMER",
			"label": "GLIMMER"
	},
	{
			"value": "CHARMED",
			"label": "CHARMED"
	},
	{
			"value": "GRUMBACHER",
			"label": "GRUMBACHER"
	},
	{
			"value": "DURABLE",
			"label": "DURABLE"
	},
	{
			"value": "COMMAND",
			"label": "COMMAND"
	},
	{
			"value": "KAHOOTZ",
			"label": "KAHOOTZ"
	},
	{
			"value": "THE LITTLE PRINCE",
			"label": "THE LITTLE PRINCE"
	},
	{
			"value": "FRED",
			"label": "FRED"
	},
	{
			"value": "WILEY PUBLISHING",
			"label": "WILEY PUBLISHING"
	},
	{
			"value": "BIKE",
			"label": "BIKE"
	},
	{
			"value": "CASTLE POINT BOOKS",
			"label": "CASTLE POINT BOOKS"
	},
	{
			"value": "ART INSTITUTE GLITTER",
			"label": "ART INSTITUTE GLITTER"
	},
	{
			"value": "PAPER MATE",
			"label": "PAPER MATE"
	},
	{
			"value": "KEN OLIVER",
			"label": "KEN OLIVER"
	},
	{
			"value": "UTAH",
			"label": "UTAH"
	},
	{
			"value": "MONTANA",
			"label": "MONTANA"
	},
	{
			"value": "DEEP RED STAMPS",
			"label": "DEEP RED STAMPS"
	},
	{
			"value": "CALGARY FLAMES",
			"label": "CALGARY FLAMES"
	},
	{
			"value": "SP MONTANA",
			"label": "SP MONTANA"
	},
	{
			"value": "TWINKLE",
			"label": "TWINKLE"
	},
	{
			"value": "ZOOB",
			"label": "ZOOB"
	},
	{
			"value": "K1C2",
			"label": "K1C2"
	},
	{
			"value": "J. P. COATS",
			"label": "J. P. COATS"
	},
	{
			"value": "SPEEDBALL ART PRODUCTS",
			"label": "SPEEDBALL ART PRODUCTS"
	},
	{
			"value": "STAMPERS ANONYMOUS",
			"label": "STAMPERS ANONYMOUS"
	},
	{
			"value": "HOT OFF THE PRESS",
			"label": "HOT OFF THE PRESS"
	},
	{
			"value": "SNIPS",
			"label": "SNIPS"
	},
	{
			"value": "ALLTON",
			"label": "ALLTON"
	},
	{
			"value": "TEXAS TECH",
			"label": "TEXAS TECH"
	},
	{
			"value": "PRECISION COMFORT",
			"label": "PRECISION COMFORT"
	},
	{
			"value": "ARTLAB",
			"label": "ARTLAB"
	},
	{
			"value": "DR. PH. MARTIN'S",
			"label": "DR. PH. MARTIN'S"
	},
	{
			"value": "STENSOURCE",
			"label": "STENSOURCE"
	},
	{
			"value": "TALKING TABLES",
			"label": "TALKING TABLES"
	},
	{
			"value": "MAGNOLIA",
			"label": "MAGNOLIA"
	},
	{
			"value": "MOSSY OAK",
			"label": "MOSSY OAK"
	},
	{
			"value": "SP FLORIDA",
			"label": "SP FLORIDA"
	},
	{
			"value": "SHURTAPE TECHNOLOGIES LLC",
			"label": "SHURTAPE TECHNOLOGIES LLC"
	},
	{
			"value": "NESTLE",
			"label": "NESTLE"
	},
	{
			"value": "UTAH JAZZ",
			"label": "UTAH JAZZ"
	},
	{
			"value": "CHEFMASTER",
			"label": "CHEFMASTER"
	},
	{
			"value": "CARDINAL",
			"label": "CARDINAL"
	},
	{
			"value": "AUNT MARTHA",
			"label": "AUNT MARTHA"
	},
	{
			"value": "CROPPER HOPPER",
			"label": "CROPPER HOPPER"
	},
	{
			"value": "TEACHER CREATED RESOURCES",
			"label": "TEACHER CREATED RESOURCES"
	},
	{
			"value": "CREATIVE CO-OP",
			"label": "CREATIVE CO-OP"
	},
	{
			"value": "BRITTANY",
			"label": "BRITTANY"
	},
	{
			"value": "MUNDIAL",
			"label": "MUNDIAL"
	},
	{
			"value": "YUPO PAPER",
			"label": "YUPO PAPER"
	},
	{
			"value": "BIT O HONEY",
			"label": "BIT O HONEY"
	},
	{
			"value": "CALICO CRITTERS",
			"label": "CALICO CRITTERS"
	},
	{
			"value": "TARA FREDRIX",
			"label": "TARA FREDRIX"
	},
	{
			"value": "VERSACHALK",
			"label": "VERSACHALK"
	},
	{
			"value": "PARADISE EXOTIC",
			"label": "PARADISE EXOTIC"
	},
	{
			"value": "LYLE",
			"label": "LYLE"
	},
	{
			"value": "CROSCILL HOME",
			"label": "CROSCILL HOME"
	},
	{
			"value": "FRENCH EUROPEAN INC",
			"label": "FRENCH EUROPEAN INC"
	},
	{
			"value": "SANTA'S WORKSHOP INC.",
			"label": "SANTA'S WORKSHOP INC."
	},
	{
			"value": "STICKY THUMB",
			"label": "STICKY THUMB"
	},
	{
			"value": "SPRINGS CREATIVE",
			"label": "SPRINGS CREATIVE"
	},
	{
			"value": "3D PAPERCRAFT",
			"label": "3D PAPERCRAFT"
	},
	{
			"value": "CEACO",
			"label": "CEACO"
	},
	{
			"value": "PEPPERELL BRAIDING",
			"label": "PEPPERELL BRAIDING"
	},
	{
			"value": "ENCHANTE",
			"label": "ENCHANTE"
	},
	{
			"value": "PHI FLYERS",
			"label": "PHI FLYERS"
	},
	{
			"value": "FABRIC QUARTERS",
			"label": "FABRIC QUARTERS"
	},
	{
			"value": "LA CLIPPERS",
			"label": "LA CLIPPERS"
	},
	{
			"value": "LITTLE B",
			"label": "LITTLE B"
	},
	{
			"value": "XCUT",
			"label": "XCUT"
	},
	{
			"value": "FANCY NANCY",
			"label": "FANCY NANCY"
	},
	{
			"value": "CLE GUARDIANS",
			"label": "CLE GUARDIANS"
	},
	{
			"value": "PIECES BE WITH YOU",
			"label": "PIECES BE WITH YOU"
	},
	{
			"value": "RIVERRIDGE",
			"label": "RIVERRIDGE"
	},
	{
			"value": "SECURIT",
			"label": "SECURIT"
	},
	{
			"value": "AIR-LITE",
			"label": "AIR-LITE"
	},
	{
			"value": "YARN HUG",
			"label": "YARN HUG"
	},
	{
			"value": "JOANN SUMMER",
			"label": "JOANN SUMMER"
	},
	{
			"value": "K&C",
			"label": "K&C"
	},
	{
			"value": "MY FAVORITE COMPANY",
			"label": "MY FAVORITE COMPANY"
	},
	{
			"value": "DONEGAN OPTICAL",
			"label": "DONEGAN OPTICAL"
	},
	{
			"value": "EXCEL",
			"label": "EXCEL"
	},
	{
			"value": "QUICK GRIP",
			"label": "QUICK GRIP"
	},
	{
			"value": "OREO",
			"label": "OREO"
	},
	{
			"value": "SP MISSOURI",
			"label": "SP MISSOURI"
	},
	{
			"value": "STENCIL1",
			"label": "STENCIL1"
	},
	{
			"value": "7 GYPSIES",
			"label": "7 GYPSIES"
	},
	{
			"value": "AITOH",
			"label": "AITOH"
	},
	{
			"value": "JOLLY HOLLY",
			"label": "JOLLY HOLLY"
	},
	{
			"value": "ANN WILLIAMS",
			"label": "ANN WILLIAMS"
	},
	{
			"value": "LIA GRIFFITH",
			"label": "LIA GRIFFITH"
	},
	{
			"value": "MY FAVORITE COLORS",
			"label": "MY FAVORITE COLORS"
	},
	{
			"value": "DERWENT",
			"label": "DERWENT"
	},
	{
			"value": "TRAN PRODUCTS",
			"label": "TRAN PRODUCTS"
	},
	{
			"value": "ILLINOIS ST",
			"label": "ILLINOIS ST"
	},
	{
			"value": "QOR",
			"label": "QOR"
	},
	{
			"value": "BBC",
			"label": "BBC"
	},
	{
			"value": "EDDING",
			"label": "EDDING"
	},
	{
			"value": "SPRING INSPIRATIONS",
			"label": "SPRING INSPIRATIONS"
	},
	{
			"value": "VERVACO",
			"label": "VERVACO"
	},
	{
			"value": "PRECIOUS MARIEKE",
			"label": "PRECIOUS MARIEKE"
	},
	{
			"value": "CREATIVE IMPRESSIONS",
			"label": "CREATIVE IMPRESSIONS"
	},
	{
			"value": "CASCADE",
			"label": "CASCADE"
	},
	{
			"value": "SCENEARAMA",
			"label": "SCENEARAMA"
	},
	{
			"value": "CREATIVE GIRL",
			"label": "CREATIVE GIRL"
	},
	{
			"value": "FABSCRAPS",
			"label": "FABSCRAPS"
	},
	{
			"value": "IDEAL",
			"label": "IDEAL"
	},
	{
			"value": "TENNESSEE",
			"label": "TENNESSEE"
	},
	{
			"value": "MERRIMAC TEXTILES",
			"label": "MERRIMAC TEXTILES"
	},
	{
			"value": "SCIENTIFIC EXPLORER",
			"label": "SCIENTIFIC EXPLORER"
	},
	{
			"value": "NORTHERN LIGHTS",
			"label": "NORTHERN LIGHTS"
	},
	{
			"value": "IN THE GARDEN",
			"label": "IN THE GARDEN"
	},
	{
			"value": "SPANGLER",
			"label": "SPANGLER"
	},
	{
			"value": "PEANUTS",
			"label": "PEANUTS"
	},
	{
			"value": "TECHNIQUE TUESDAY",
			"label": "TECHNIQUE TUESDAY"
	},
	{
			"value": "BENDON",
			"label": "BENDON"
	},
	{
			"value": "CRESCENT CARDBOARD CO",
			"label": "CRESCENT CARDBOARD CO"
	},
	{
			"value": "AI ROOT CANDLES",
			"label": "AI ROOT CANDLES"
	},
	{
			"value": "CYSTAL LANE",
			"label": "CYSTAL LANE"
	},
	{
			"value": "KENNEY",
			"label": "KENNEY"
	},
	{
			"value": "ICONIC CANDY",
			"label": "ICONIC CANDY"
	},
	{
			"value": "DYAN REAVELEY",
			"label": "DYAN REAVELEY"
	},
	{
			"value": "ACCUQUILT",
			"label": "ACCUQUILT"
	},
	{
			"value": "SP WISCONSIN",
			"label": "SP WISCONSIN"
	},
	{
			"value": "JOHN WAYNE",
			"label": "JOHN WAYNE"
	},
	{
			"value": "AIROME",
			"label": "AIROME"
	},
	{
			"value": "HOTFIX ADHESIVE",
			"label": "HOTFIX ADHESIVE"
	},
	{
			"value": "RICHMOND FLY SQRLS",
			"label": "RICHMOND FLY SQRLS"
	},
	{
			"value": "ELLE MENTS",
			"label": "ELLE MENTS"
	},
	{
			"value": "LINZER BRUSH",
			"label": "LINZER BRUSH"
	},
	{
			"value": "NEVADA RENO",
			"label": "NEVADA RENO"
	},
	{
			"value": "TB LIGHTNING",
			"label": "TB LIGHTNING"
	},
	{
			"value": "WAVERLY",
			"label": "WAVERLY"
	},
	{
			"value": "CRAFTY DAB",
			"label": "CRAFTY DAB"
	},
	{
			"value": "AURIFIL",
			"label": "AURIFIL"
	},
	{
			"value": "ANNABELLE",
			"label": "ANNABELLE"
	},
	{
			"value": "HOLIDAY INSPIRATIONS",
			"label": "HOLIDAY INSPIRATIONS"
	},
	{
			"value": "CAROLINA HURRICANES",
			"label": "CAROLINA HURRICANES"
	},
	{
			"value": "MAYA ROAD",
			"label": "MAYA ROAD"
	},
	{
			"value": "SCHITT'S CREEK",
			"label": "SCHITT'S CREEK"
	},
	{
			"value": "SD PADRES",
			"label": "SD PADRES"
	},
	{
			"value": "HALCRAFT",
			"label": "HALCRAFT"
	},
	{
			"value": "PLUSHCRAFT",
			"label": "PLUSHCRAFT"
	},
	{
			"value": "STMT",
			"label": "STMT"
	},
	{
			"value": "MR. CHRISTMAS",
			"label": "MR. CHRISTMAS"
	},
	{
			"value": "HGTV",
			"label": "HGTV"
	},
	{
			"value": "ELF",
			"label": "ELF"
	},
	{
			"value": "STELLA LIGHTING",
			"label": "STELLA LIGHTING"
	},
	{
			"value": "DEPAUL",
			"label": "DEPAUL"
	},
	{
			"value": "YOUNGSTOWN ST",
			"label": "YOUNGSTOWN ST"
	},
	{
			"value": "GILDAN",
			"label": "GILDAN"
	},
	{
			"value": "SP ARIZONA",
			"label": "SP ARIZONA"
	},
	{
			"value": "KOOL TAK",
			"label": "KOOL TAK"
	},
	{
			"value": "EMOJI",
			"label": "EMOJI"
	},
	{
			"value": "LAURDIY",
			"label": "LAURDIY"
	},
	{
			"value": "VAN GOGH",
			"label": "VAN GOGH"
	},
	{
			"value": "OTTOWA SENATORS",
			"label": "OTTOWA SENATORS"
	},
	{
			"value": "SP ARKANSAS",
			"label": "SP ARKANSAS"
	},
	{
			"value": "COL ROCKIES",
			"label": "COL ROCKIES"
	},
	{
			"value": "OREGON ST",
			"label": "OREGON ST"
	},
	{
			"value": "FARMALL",
			"label": "FARMALL"
	},
	{
			"value": "HOLSTEIN",
			"label": "HOLSTEIN"
	},
	{
			"value": "BLOOM ROOM",
			"label": "BLOOM ROOM"
	},
	{
			"value": "TWO LUMPS OF SUGAR",
			"label": "TWO LUMPS OF SUGAR"
	},
	{
			"value": "US MARINES",
			"label": "US MARINES"
	},
	{
			"value": "MCG TEXTILES",
			"label": "MCG TEXTILES"
	},
	{
			"value": "DECOART",
			"label": "DECOART"
	},
	{
			"value": "CRAFT CONSORTIUM",
			"label": "CRAFT CONSORTIUM"
	},
	{
			"value": "NIAGARA CHOCOLATES",
			"label": "NIAGARA CHOCOLATES"
	},
	{
			"value": "HOLIDAY",
			"label": "HOLIDAY"
	},
	{
			"value": "YORK",
			"label": "YORK"
	},
	{
			"value": "GONZAGA",
			"label": "GONZAGA"
	},
	{
			"value": "UNCANNY BRANDS",
			"label": "UNCANNY BRANDS"
	},
	{
			"value": "NATIONAL BOOK NETWORK",
			"label": "NATIONAL BOOK NETWORK"
	},
	{
			"value": "MORGAN",
			"label": "MORGAN"
	},
	{
			"value": "MEM GRIZZLIES",
			"label": "MEM GRIZZLIES"
	},
	{
			"value": "VANDERBILT",
			"label": "VANDERBILT"
	},
	{
			"value": "OKEEFFES",
			"label": "OKEEFFES"
	},
	{
			"value": "NECCO",
			"label": "NECCO"
	},
	{
			"value": "COUNTRY LANE",
			"label": "COUNTRY LANE"
	},
	{
			"value": "DAP",
			"label": "DAP"
	},
	{
			"value": "STONEY CREEK",
			"label": "STONEY CREEK"
	},
	{
			"value": "AMERICAN DREAM",
			"label": "AMERICAN DREAM"
	},
	{
			"value": "M&M'S",
			"label": "M&M'S"
	},
	{
			"value": "MAKE IT REAL",
			"label": "MAKE IT REAL"
	},
	{
			"value": "JOYCE CHEN",
			"label": "JOYCE CHEN"
	},
	{
			"value": "SUNNY SEED",
			"label": "SUNNY SEED"
	},
	{
			"value": "LAVISHEA",
			"label": "LAVISHEA"
	},
	{
			"value": "ZUTTER",
			"label": "ZUTTER"
	},
	{
			"value": "SUPER STACKER",
			"label": "SUPER STACKER"
	},
	{
			"value": "STERLING PUBLISHING",
			"label": "STERLING PUBLISHING"
	},
	{
			"value": "SOUTH CAROLINA",
			"label": "SOUTH CAROLINA"
	},
	{
			"value": "CQ PRODUCTS",
			"label": "CQ PRODUCTS"
	},
	{
			"value": "SOFTSTYLE CVC",
			"label": "SOFTSTYLE CVC"
	},
	{
			"value": "FARM WORLD",
			"label": "FARM WORLD"
	},
	{
			"value": "JOANN SUMMER DAZE",
			"label": "JOANN SUMMER DAZE"
	},
	{
			"value": "AEROSMITH",
			"label": "AEROSMITH"
	},
	{
			"value": "QUINCRAFTS",
			"label": "QUINCRAFTS"
	},
	{
			"value": "AUTHENTIQUE PAPER",
			"label": "AUTHENTIQUE PAPER"
	},
	{
			"value": "BANANA BOAT",
			"label": "BANANA BOAT"
	},
	{
			"value": "PAKO",
			"label": "PAKO"
	},
	{
			"value": "JEWELRY BASICS",
			"label": "JEWELRY BASICS"
	},
	{
			"value": "VERILUX",
			"label": "VERILUX"
	},
	{
			"value": "OCEANSTAR",
			"label": "OCEANSTAR"
	},
	{
			"value": "FABRIC",
			"label": "FABRIC"
	},
	{
			"value": "ST JUDE",
			"label": "ST JUDE"
	},
	{
			"value": "LALTITUDE LC",
			"label": "LALTITUDE LC"
	},
	{
			"value": "3L",
			"label": "3L"
	},
	{
			"value": "SUNDAY INT",
			"label": "SUNDAY INT"
	},
	{
			"value": "HG GLOBAL",
			"label": "HG GLOBAL"
	},
	{
			"value": "RM PALMER",
			"label": "RM PALMER"
	},
	{
			"value": "ORIGINAL WOOLLY NYLON",
			"label": "ORIGINAL WOOLLY NYLON"
	},
	{
			"value": "TORONTO MAPLE LEAFS",
			"label": "TORONTO MAPLE LEAFS"
	},
	{
			"value": "CALCULATED INDUSTRIES",
			"label": "CALCULATED INDUSTRIES"
	},
	{
			"value": "CREATIVE COMMODITIES",
			"label": "CREATIVE COMMODITIES"
	},
	{
			"value": "FLOSS WEEKS O-DYED",
			"label": "FLOSS WEEKS O-DYED"
	},
	{
			"value": "COLART",
			"label": "COLART"
	},
	{
			"value": "ART SUPPLY BASIC",
			"label": "ART SUPPLY BASIC"
	},
	{
			"value": "ANGORA",
			"label": "ANGORA"
	},
	{
			"value": "MCS INDUSTRIES",
			"label": "MCS INDUSTRIES"
	},
	{
			"value": "JESSE JAMES",
			"label": "JESSE JAMES"
	},
	{
			"value": "PLUS CORPORATION",
			"label": "PLUS CORPORATION"
	},
	{
			"value": "SMART GAMES",
			"label": "SMART GAMES"
	},
	{
			"value": "COTMAN",
			"label": "COTMAN"
	},
	{
			"value": "JUST MY STYLE",
			"label": "JUST MY STYLE"
	},
	{
			"value": "MONTANA ST",
			"label": "MONTANA ST"
	},
	{
			"value": "HEY DOLL",
			"label": "HEY DOLL"
	},
	{
			"value": "VISION TRIMS",
			"label": "VISION TRIMS"
	},
	{
			"value": "MOSAIC MERCANTILE",
			"label": "MOSAIC MERCANTILE"
	},
	{
			"value": "WINNIPEG JETS",
			"label": "WINNIPEG JETS"
	},
	{
			"value": "HANDY ART",
			"label": "HANDY ART"
	},
	{
			"value": "HERITAGE HOUSE",
			"label": "HERITAGE HOUSE"
	},
	{
			"value": "HYGLOSS",
			"label": "HYGLOSS"
	},
	{
			"value": "OLD DOMINION",
			"label": "OLD DOMINION"
	},
	{
			"value": "HIGH AND MIGHTY",
			"label": "HIGH AND MIGHTY"
	},
	{
			"value": "PATCHES FOR EVERYONE",
			"label": "PATCHES FOR EVERYONE"
	},
	{
			"value": "REMBRANDT",
			"label": "REMBRANDT"
	},
	{
			"value": "FINNABAIR",
			"label": "FINNABAIR"
	},
	{
			"value": "MAKERSTOCK",
			"label": "MAKERSTOCK"
	},
	{
			"value": "YOU NIVERSE",
			"label": "YOU NIVERSE"
	},
	{
			"value": "PITTSBURGH",
			"label": "PITTSBURGH"
	},
	{
			"value": "FIRST EDITION",
			"label": "FIRST EDITION"
	},
	{
			"value": "PAPERMANIA",
			"label": "PAPERMANIA"
	},
	{
			"value": "SP ALABAMA",
			"label": "SP ALABAMA"
	},
	{
			"value": "DUQUESNE",
			"label": "DUQUESNE"
	},
	{
			"value": "TRUE TIMBER",
			"label": "TRUE TIMBER"
	},
	{
			"value": "HALLOWEEN",
			"label": "HALLOWEEN"
	},
	{
			"value": "MAY ARTS",
			"label": "MAY ARTS"
	},
	{
			"value": "KINETIC SAND",
			"label": "KINETIC SAND"
	},
	{
			"value": "ZINK",
			"label": "ZINK"
	},
	{
			"value": "FINELINE",
			"label": "FINELINE"
	},
	{
			"value": "TOUCH OF NATURE",
			"label": "TOUCH OF NATURE"
	},
	{
			"value": "RUSSELL STOVER",
			"label": "RUSSELL STOVER"
	},
	{
			"value": "WHITMAN",
			"label": "WHITMAN"
	},
	{
			"value": "BOYE",
			"label": "BOYE"
	},
	{
			"value": "GRAND & BENEDICTS INC",
			"label": "GRAND & BENEDICTS INC"
	},
	{
			"value": "NEENAH PAPER",
			"label": "NEENAH PAPER"
	},
	{
			"value": "LEANNE CREATIEF",
			"label": "LEANNE CREATIEF"
	},
	{
			"value": "BOSAL",
			"label": "BOSAL"
	},
	{
			"value": "CRETACOLOR",
			"label": "CRETACOLOR"
	},
	{
			"value": "TARZANIMALS",
			"label": "TARZANIMALS"
	},
	{
			"value": "RUTGERS",
			"label": "RUTGERS"
	},
	{
			"value": "BP INDUSTRIES",
			"label": "BP INDUSTRIES"
	},
	{
			"value": "GOOSEBUMPS",
			"label": "GOOSEBUMPS"
	},
	{
			"value": "THE HAPPY PLANNER",
			"label": "THE HAPPY PLANNER"
	},
	{
			"value": "HI LITES",
			"label": "HI LITES"
	},
	{
			"value": "HOU ASTROS",
			"label": "HOU ASTROS"
	},
	{
			"value": "TEX RANGERS",
			"label": "TEX RANGERS"
	},
	{
			"value": "CORA'S",
			"label": "CORA'S"
	},
	{
			"value": "DECOR PAPER",
			"label": "DECOR PAPER"
	},
	{
			"value": "JOANN AMERICANA",
			"label": "JOANN AMERICANA"
	},
	{
			"value": "WIDGET PRODUCTS",
			"label": "WIDGET PRODUCTS"
	},
	{
			"value": "SAFAVIEH",
			"label": "SAFAVIEH"
	},
	{
			"value": "CARS",
			"label": "CARS"
	},
	{
			"value": "C&T PUBLISHING",
			"label": "C&T PUBLISHING"
	},
	{
			"value": "ARCHES",
			"label": "ARCHES"
	},
	{
			"value": "AROMAFIELDS",
			"label": "AROMAFIELDS"
	},
	{
			"value": "RESTOR-IT",
			"label": "RESTOR-IT"
	},
	{
			"value": "ZAKKA",
			"label": "ZAKKA"
	},
	{
			"value": "MATTEL",
			"label": "MATTEL"
	},
	{
			"value": "LOYOLA CHICAGO",
			"label": "LOYOLA CHICAGO"
	},
	{
			"value": "TREEKEEPER",
			"label": "TREEKEEPER"
	},
	{
			"value": "WESTING BRIDGE",
			"label": "WESTING BRIDGE"
	},
	{
			"value": "ART OF AUTUMN",
			"label": "ART OF AUTUMN"
	},
	{
			"value": "WILD ROSE STUDIO",
			"label": "WILD ROSE STUDIO"
	},
	{
			"value": "TRANSFORMERS",
			"label": "TRANSFORMERS"
	},
	{
			"value": "JAS",
			"label": "JAS"
	},
	{
			"value": "CASTIN CRAFT",
			"label": "CASTIN CRAFT"
	},
	{
			"value": "HERITAGE",
			"label": "HERITAGE"
	},
	{
			"value": "FEDERAL PROCESS",
			"label": "FEDERAL PROCESS"
	},
	{
			"value": "REAL CREEK",
			"label": "REAL CREEK"
	},
	{
			"value": "IOWA ST",
			"label": "IOWA ST"
	},
	{
			"value": "CRAFT MATES",
			"label": "CRAFT MATES"
	},
	{
			"value": "CARABELLE STUDIO",
			"label": "CARABELLE STUDIO"
	},
	{
			"value": "GRACE",
			"label": "GRACE"
	},
	{
			"value": "LEHIGH VAL IRONPIGS",
			"label": "LEHIGH VAL IRONPIGS"
	},
	{
			"value": "CHICAGO MAILING TUBE",
			"label": "CHICAGO MAILING TUBE"
	},
	{
			"value": "E6000",
			"label": "E6000"
	},
	{
			"value": "ART IMPRESSIONS",
			"label": "ART IMPRESSIONS"
	},
	{
			"value": "ST. CLOUD",
			"label": "ST. CLOUD"
	},
	{
			"value": "UHU",
			"label": "UHU"
	},
	{
			"value": "BLOCKWALLAH",
			"label": "BLOCKWALLAH"
	},
	{
			"value": "NY ISLANDERS",
			"label": "NY ISLANDERS"
	},
	{
			"value": "CIN BENGALS",
			"label": "CIN BENGALS"
	},
	{
			"value": "KB RILEY",
			"label": "KB RILEY"
	},
	{
			"value": "IDYE",
			"label": "IDYE"
	},
	{
			"value": "ROWENTA",
			"label": "ROWENTA"
	},
	{
			"value": "PLASTEEL CORP",
			"label": "PLASTEEL CORP"
	},
	{
			"value": "SENTIMENTS",
			"label": "SENTIMENTS"
	},
	{
			"value": "STUDIO CREATOR",
			"label": "STUDIO CREATOR"
	},
	{
			"value": "3DOODLER",
			"label": "3DOODLER"
	},
	{
			"value": "VIRGINIA",
			"label": "VIRGINIA"
	},
	{
			"value": "YVONNE CREATIONS",
			"label": "YVONNE CREATIONS"
	},
	{
			"value": "TONIC STUDIOS",
			"label": "TONIC STUDIOS"
	},
	{
			"value": "BROTHER",
			"label": "BROTHER"
	},
	{
			"value": "CHRISTMAS FLORAL",
			"label": "CHRISTMAS FLORAL"
	},
	{
			"value": "TB BUCS",
			"label": "TB BUCS"
	},
	{
			"value": "STUDD D FOR THE OCCASION",
			"label": "STUDD D FOR THE OCCASION"
	},
	{
			"value": "VIEWTAINER",
			"label": "VIEWTAINER"
	},
	{
			"value": "FABRIC EDITIONS",
			"label": "FABRIC EDITIONS"
	},
	{
			"value": "SISER",
			"label": "SISER"
	},
	{
			"value": "BOSTON COLLEGE",
			"label": "BOSTON COLLEGE"
	},
	{
			"value": "MRS. FIELDS",
			"label": "MRS. FIELDS"
	},
	{
			"value": "EURO-NOTIONS",
			"label": "EURO-NOTIONS"
	},
	{
			"value": "POP! VALENTINE'S",
			"label": "POP! VALENTINE'S"
	},
	{
			"value": "MAKIT & BAKIT",
			"label": "MAKIT & BAKIT"
	},
	{
			"value": "DAMASK LOVE",
			"label": "DAMASK LOVE"
	},
	{
			"value": "VISTA PARTNERS INC (ECOM)",
			"label": "VISTA PARTNERS INC (ECOM)"
	},
	{
			"value": "LOGAN",
			"label": "LOGAN"
	},
	{
			"value": "NORTH CAROLINA A&T",
			"label": "NORTH CAROLINA A&T"
	},
	{
			"value": "BODY PRESCRIPTION",
			"label": "BODY PRESCRIPTION"
	},
	{
			"value": "STITCH ACC",
			"label": "STITCH ACC"
	},
	{
			"value": "TEAR MENDER",
			"label": "TEAR MENDER"
	},
	{
			"value": "KENNY’S",
			"label": "KENNY’S"
	},
	{
			"value": "DOT BOX",
			"label": "DOT BOX"
	},
	{
			"value": "XYRON",
			"label": "XYRON"
	},
	{
			"value": "CRAFTER'S COMPANION",
			"label": "CRAFTER'S COMPANION"
	},
	{
			"value": "MODERN MASTERS",
			"label": "MODERN MASTERS"
	},
	{
			"value": "LOLLY",
			"label": "LOLLY"
	},
	{
			"value": "DIAMOND ART",
			"label": "DIAMOND ART"
	},
	{
			"value": "DAHLE",
			"label": "DAHLE"
	},
	{
			"value": "BAG-O-RAGS",
			"label": "BAG-O-RAGS"
	},
	{
			"value": "SPOT",
			"label": "SPOT"
	},
	{
			"value": "C-LINE",
			"label": "C-LINE"
	},
	{
			"value": "WASH NATIONALS",
			"label": "WASH NATIONALS"
	},
	{
			"value": "REVELL",
			"label": "REVELL"
	},
	{
			"value": "MAGIC PALETTE",
			"label": "MAGIC PALETTE"
	},
	{
			"value": "STITCH KITS",
			"label": "STITCH KITS"
	},
	{
			"value": "GI JOE",
			"label": "GI JOE"
	},
	{
			"value": "SARAL",
			"label": "SARAL"
	},
	{
			"value": "MIGHTY BRIGHT",
			"label": "MIGHTY BRIGHT"
	},
	{
			"value": "NC STATE",
			"label": "NC STATE"
	},
	{
			"value": "COUNTRY CLASSIC",
			"label": "COUNTRY CLASSIC"
	},
	{
			"value": "SPIN MASTER",
			"label": "SPIN MASTER"
	},
	{
			"value": "WALLIES",
			"label": "WALLIES"
	},
	{
			"value": "SF 49ERS",
			"label": "SF 49ERS"
	},
	{
			"value": "FIESTA",
			"label": "FIESTA"
	},
	{
			"value": "PLASTER OF PARIS",
			"label": "PLASTER OF PARIS"
	},
	{
			"value": "LAURETTE DESIGN",
			"label": "LAURETTE DESIGN"
	},
	{
			"value": "JOANN VALENTINE'S DAY",
			"label": "JOANN VALENTINE'S DAY"
	},
	{
			"value": "MR. HEATER",
			"label": "MR. HEATER"
	},
	{
			"value": "PEARLIZED",
			"label": "PEARLIZED"
	},
	{
			"value": "PEARL COTTON",
			"label": "PEARL COTTON"
	},
	{
			"value": "BALL JARS",
			"label": "BALL JARS"
	},
	{
			"value": "MOLESKINE",
			"label": "MOLESKINE"
	},
	{
			"value": "WARM 100",
			"label": "WARM 100"
	},
	{
			"value": "VERSAMAGIC",
			"label": "VERSAMAGIC"
	},
	{
			"value": "MADEIRA",
			"label": "MADEIRA"
	},
	{
			"value": "CHI WHITE SOX",
			"label": "CHI WHITE SOX"
	},
	{
			"value": "CHARTPAK",
			"label": "CHARTPAK"
	},
	{
			"value": "DET RED WINGS",
			"label": "DET RED WINGS"
	},
	{
			"value": "COUSIN",
			"label": "COUSIN"
	},
	{
			"value": "GATHERED",
			"label": "GATHERED"
	},
	{
			"value": "LILLY LUNA",
			"label": "LILLY LUNA"
	},
	{
			"value": "EDUCATORS RESOURCE",
			"label": "EDUCATORS RESOURCE"
	},
	{
			"value": "I LOVE TO CREATE",
			"label": "I LOVE TO CREATE"
	},
	{
			"value": "HELIX",
			"label": "HELIX"
	},
	{
			"value": "ARKANSAS",
			"label": "ARKANSAS"
	},
	{
			"value": "COLOR EVALUATOR",
			"label": "COLOR EVALUATOR"
	},
	{
			"value": "REMINISCE",
			"label": "REMINISCE"
	},
	{
			"value": "COL AVALANCHE",
			"label": "COL AVALANCHE"
	},
	{
			"value": "WARM PRODUCTS",
			"label": "WARM PRODUCTS"
	},
	{
			"value": "PLACE & TIME",
			"label": "PLACE & TIME"
	},
	{
			"value": "BAKER'S STO N' GO",
			"label": "BAKER'S STO N' GO"
	},
	{
			"value": "FASHION FABRIC",
			"label": "FASHION FABRIC"
	},
	{
			"value": "LOTS OF DOTS",
			"label": "LOTS OF DOTS"
	},
	{
			"value": "KIDZLABS",
			"label": "KIDZLABS"
	},
	{
			"value": "PRESSMAN",
			"label": "PRESSMAN"
	},
	{
			"value": "BIG BANG THEORY",
			"label": "BIG BANG THEORY"
	},
	{
			"value": "CHI FIRE",
			"label": "CHI FIRE"
	},
	{
			"value": "JAWS",
			"label": "JAWS"
	},
	{
			"value": "TUBE WRINGER",
			"label": "TUBE WRINGER"
	},
	{
			"value": "BEPUZZLED",
			"label": "BEPUZZLED"
	},
	{
			"value": "CHAR KNIGHTS",
			"label": "CHAR KNIGHTS"
	},
	{
			"value": "UBER",
			"label": "UBER"
	},
	{
			"value": "ADVANTUS",
			"label": "ADVANTUS"
	},
	{
			"value": "DO-A-DOT",
			"label": "DO-A-DOT"
	},
	{
			"value": "MAKER HOLIDAY",
			"label": "MAKER HOLIDAY"
	},
	{
			"value": "ANDERSON & SONS",
			"label": "ANDERSON & SONS"
	},
	{
			"value": "LA CHARGERS",
			"label": "LA CHARGERS"
	},
	{
			"value": "GERRIT VERBERG",
			"label": "GERRIT VERBERG"
	},
	{
			"value": "MIXY SQUISH",
			"label": "MIXY SQUISH"
	},
	{
			"value": "REAL MADRID",
			"label": "REAL MADRID"
	},
	{
			"value": "SCONZA",
			"label": "SCONZA"
	},
	{
			"value": "CHARMS",
			"label": "CHARMS"
	},
	{
			"value": "FLORIDA PANTHERS",
			"label": "FLORIDA PANTHERS"
	},
	{
			"value": "ROBERT ALLEN",
			"label": "ROBERT ALLEN"
	},
	{
			"value": "DEAR LIZZY",
			"label": "DEAR LIZZY"
	},
	{
			"value": "PAINTSTIKS",
			"label": "PAINTSTIKS"
	},
	{
			"value": "ALB ISOTOPES",
			"label": "ALB ISOTOPES"
	},
	{
			"value": "SP DELAWARE",
			"label": "SP DELAWARE"
	},
	{
			"value": "FLOSS",
			"label": "FLOSS"
	},
	{
			"value": "HOLSTER BRANDS",
			"label": "HOLSTER BRANDS"
	},
	{
			"value": "MONTREAL CANADIENS",
			"label": "MONTREAL CANADIENS"
	},
	{
			"value": "DELTA",
			"label": "DELTA"
	},
	{
			"value": "MARVEL",
			"label": "MARVEL"
	},
	{
			"value": "WESTRIM CRAFTS",
			"label": "WESTRIM CRAFTS"
	},
	{
			"value": "TACONY",
			"label": "TACONY"
	},
	{
			"value": "OMNIGRIP",
			"label": "OMNIGRIP"
	},
	{
			"value": "CHRISTMAS VACATION",
			"label": "CHRISTMAS VACATION"
	},
	{
			"value": "NASA",
			"label": "NASA"
	},
	{
			"value": "NBC",
			"label": "NBC"
	},
	{
			"value": "LICENSE 2 PLAY",
			"label": "LICENSE 2 PLAY"
	},
	{
			"value": "SPRING VALLEY",
			"label": "SPRING VALLEY"
	},
	{
			"value": "IMAGE",
			"label": "IMAGE"
	},
	{
			"value": "GRAPHIC 45",
			"label": "GRAPHIC 45"
	},
	{
			"value": "PELLON",
			"label": "PELLON"
	},
	{
			"value": "SP WYOMING",
			"label": "SP WYOMING"
	},
	{
			"value": "C-GULL",
			"label": "C-GULL"
	},
	{
			"value": "TALENS ART CREATION",
			"label": "TALENS ART CREATION"
	},
	{
			"value": "WEST BROADWAY",
			"label": "WEST BROADWAY"
	},
	{
			"value": "COOKIES UNITED",
			"label": "COOKIES UNITED"
	},
	{
			"value": "IMPULSE HOLIDAY",
			"label": "IMPULSE HOLIDAY"
	},
	{
			"value": "SMART SKETCHER",
			"label": "SMART SKETCHER"
	},
	{
			"value": "MONTANA CANS",
			"label": "MONTANA CANS"
	},
	{
			"value": "MIL BREWERS",
			"label": "MIL BREWERS"
	},
	{
			"value": "IMAGINISCE",
			"label": "IMAGINISCE"
	},
	{
			"value": "SP MICHIGAN",
			"label": "SP MICHIGAN"
	},
	{
			"value": "AMMEE'S BABIES",
			"label": "AMMEE'S BABIES"
	},
	{
			"value": "SNL",
			"label": "SNL"
	},
	{
			"value": "COZY HOME",
			"label": "COZY HOME"
	},
	{
			"value": "FELTWORKS",
			"label": "FELTWORKS"
	},
	{
			"value": "PINTY PLUS",
			"label": "PINTY PLUS"
	},
	{
			"value": "VILLANOVA",
			"label": "VILLANOVA"
	},
	{
			"value": "AMERICAN CRAFTS",
			"label": "AMERICAN CRAFTS"
	},
	{
			"value": "WICHITA ST",
			"label": "WICHITA ST"
	},
	{
			"value": "WHITE MOUNTAIN PUZZLES",
			"label": "WHITE MOUNTAIN PUZZLES"
	},
	{
			"value": "STILLMAN & BIRN",
			"label": "STILLMAN & BIRN"
	},
	{
			"value": "DRESS IT UP",
			"label": "DRESS IT UP"
	},
	{
			"value": "ORLANDO MAGIC",
			"label": "ORLANDO MAGIC"
	},
	{
			"value": "NEILSEN BAINBRIDGE",
			"label": "NEILSEN BAINBRIDGE"
	},
	{
			"value": "ALEXANDRA RENKE",
			"label": "ALEXANDRA RENKE"
	},
	{
			"value": "TEXAS",
			"label": "TEXAS"
	},
	{
			"value": "STUDIO NYC",
			"label": "STUDIO NYC"
	},
	{
			"value": "NY CAKE",
			"label": "NY CAKE"
	},
	{
			"value": "CREATEX",
			"label": "CREATEX"
	},
	{
			"value": "KITCHEN AID",
			"label": "KITCHEN AID"
	},
	{
			"value": "F & M BRUSH COMPANY",
			"label": "F & M BRUSH COMPANY"
	},
	{
			"value": "IDYE JACQUARD",
			"label": "IDYE JACQUARD"
	},
	{
			"value": "CRAYOLA",
			"label": "CRAYOLA"
	},
	{
			"value": "BUNNEEZ",
			"label": "BUNNEEZ"
	},
	{
			"value": "SPS",
			"label": "SPS"
	},
	{
			"value": "ROLIE POLIE",
			"label": "ROLIE POLIE"
	},
	{
			"value": "QUILLED CREATIONS",
			"label": "QUILLED CREATIONS"
	},
	{
			"value": "SEW ESSENTIALS",
			"label": "SEW ESSENTIALS"
	},
	{
			"value": "AL BAKERS",
			"label": "AL BAKERS"
	},
	{
			"value": "C.C. DESIGNS",
			"label": "C.C. DESIGNS"
	},
	{
			"value": "PENELOPE DEE",
			"label": "PENELOPE DEE"
	},
	{
			"value": "BROOKLYN NETS",
			"label": "BROOKLYN NETS"
	},
	{
			"value": "MIRACLE POINT",
			"label": "MIRACLE POINT"
	},
	{
			"value": "ROBERT SIMMONS",
			"label": "ROBERT SIMMONS"
	},
	{
			"value": "BRAEMORE",
			"label": "BRAEMORE"
	},
	{
			"value": "ZURU",
			"label": "ZURU"
	},
	{
			"value": "HOME ESSENTIALS",
			"label": "HOME ESSENTIALS"
	},
	{
			"value": "SALT LAKE BEES",
			"label": "SALT LAKE BEES"
	},
	{
			"value": "QUINT MEASURING SYSTEMS",
			"label": "QUINT MEASURING SYSTEMS"
	},
	{
			"value": "Q-SNAP",
			"label": "Q-SNAP"
	},
	{
			"value": "BLITSY INC",
			"label": "BLITSY INC"
	},
	{
			"value": "MIBI",
			"label": "MIBI"
	},
	{
			"value": "REESES",
			"label": "REESES"
	},
	{
			"value": "CALIFORNIA",
			"label": "CALIFORNIA"
	},
	{
			"value": "CRAFTY INDIVIDUALS",
			"label": "CRAFTY INDIVIDUALS"
	},
	{
			"value": "KAISERCRAFT",
			"label": "KAISERCRAFT"
	},
	{
			"value": "CURIOUS GEORGE",
			"label": "CURIOUS GEORGE"
	},
	{
			"value": "SMASHMALLOW",
			"label": "SMASHMALLOW"
	},
	{
			"value": "ILLINOIS",
			"label": "ILLINOIS"
	},
	{
			"value": "FASHION ANGELS",
			"label": "FASHION ANGELS"
	},
	{
			"value": "FANCY PANTS",
			"label": "FANCY PANTS"
	},
	{
			"value": "MILL HILL",
			"label": "MILL HILL"
	},
	{
			"value": "K'S CREATION",
			"label": "K'S CREATION"
	},
	{
			"value": "PENN ST",
			"label": "PENN ST"
	},
	{
			"value": "LIFESTYLE CRAFTS",
			"label": "LIFESTYLE CRAFTS"
	},
	{
			"value": "CARSON DELLOSA",
			"label": "CARSON DELLOSA"
	},
	{
			"value": "LINDT",
			"label": "LINDT"
	},
	{
			"value": "NO BRAND",
			"label": "NO BRAND"
	},
	{
			"value": "VERY FINE BRAID",
			"label": "VERY FINE BRAID"
	},
	{
			"value": "DURHAM BULLS",
			"label": "DURHAM BULLS"
	},
	{
			"value": "MAYIM",
			"label": "MAYIM"
	},
	{
			"value": "APOTHEHAPPY",
			"label": "APOTHEHAPPY"
	},
	{
			"value": "JOHN PIKE",
			"label": "JOHN PIKE"
	},
	{
			"value": "POP! HALLOWEEN",
			"label": "POP! HALLOWEEN"
	},
	{
			"value": "THE CRAFTER'S WORKSHOP",
			"label": "THE CRAFTER'S WORKSHOP"
	},
	{
			"value": "METALLIC FLOSS",
			"label": "METALLIC FLOSS"
	},
	{
			"value": "ICRAFT",
			"label": "ICRAFT"
	},
	{
			"value": "WILTON",
			"label": "WILTON"
	},
	{
			"value": "NONMERCH",
			"label": "NONMERCH"
	},
	{
			"value": "VANTAGE",
			"label": "VANTAGE"
	},
	{
			"value": "PHX SUNS",
			"label": "PHX SUNS"
	},
	{
			"value": "SIGNATURE SERIES",
			"label": "SIGNATURE SERIES"
	},
	{
			"value": "PINE CAR",
			"label": "PINE CAR"
	},
	{
			"value": "HOT WHEELS",
			"label": "HOT WHEELS"
	},
	{
			"value": "LAROSE",
			"label": "LAROSE"
	},
	{
			"value": "ENVIROTEX",
			"label": "ENVIROTEX"
	},
	{
			"value": "R&F HANDMADE PAINTS",
			"label": "R&F HANDMADE PAINTS"
	},
	{
			"value": "MAUD BORUP",
			"label": "MAUD BORUP"
	},
	{
			"value": "FABRIANO",
			"label": "FABRIANO"
	},
	{
			"value": "BRUTUS MONROE",
			"label": "BRUTUS MONROE"
	},
	{
			"value": "BONNIE",
			"label": "BONNIE"
	},
	{
			"value": "HER ACCESSORIES",
			"label": "HER ACCESSORIES"
	},
	{
			"value": "PINK FLOYD",
			"label": "PINK FLOYD"
	},
	{
			"value": "SP WASHINGTON",
			"label": "SP WASHINGTON"
	},
	{
			"value": "IDAHO",
			"label": "IDAHO"
	},
	{
			"value": "LOUNGE PANTS",
			"label": "LOUNGE PANTS"
	},
	{
			"value": "SOFT & BRIGHT",
			"label": "SOFT & BRIGHT"
	},
	{
			"value": "SEA SOUNDERS",
			"label": "SEA SOUNDERS"
	},
	{
			"value": "SUNBRELLA",
			"label": "SUNBRELLA"
	},
	{
			"value": "ZLIDEON",
			"label": "ZLIDEON"
	},
	{
			"value": "VINTAGE FLAG POST",
			"label": "VINTAGE FLAG POST"
	},
	{
			"value": "CRAFT THREAD",
			"label": "CRAFT THREAD"
	},
	{
			"value": "ROXETTE",
			"label": "ROXETTE"
	},
	{
			"value": "HAHNEMUEHLE",
			"label": "HAHNEMUEHLE"
	},
	{
			"value": "SPRINGFIELD COLLECTION",
			"label": "SPRINGFIELD COLLECTION"
	},
	{
			"value": "DREAMWORLD BY EMILY W",
			"label": "DREAMWORLD BY EMILY W"
	},
	{
			"value": "SOFTEE",
			"label": "SOFTEE"
	},
	{
			"value": "STEN SOURCE",
			"label": "STEN SOURCE"
	},
	{
			"value": "RAPHAEL",
			"label": "RAPHAEL"
	},
	{
			"value": "SIMPLY SPRAY",
			"label": "SIMPLY SPRAY"
	},
	{
			"value": "HP",
			"label": "HP"
	},
	{
			"value": "PINEPRO",
			"label": "PINEPRO"
	},
	{
			"value": "AVERY ELLE",
			"label": "AVERY ELLE"
	},
	{
			"value": "ARIZONA",
			"label": "ARIZONA"
	},
	{
			"value": "MAGEYES",
			"label": "MAGEYES"
	},
	{
			"value": "ADVANTUS CORP/SULYN INDUSTRI",
			"label": "ADVANTUS CORP/SULYN INDUSTRI"
	},
	{
			"value": "MARY MAXIM",
			"label": "MARY MAXIM"
	},
	{
			"value": "ALL OCCASION",
			"label": "ALL OCCASION"
	},
	{
			"value": "CAR HURRICANES",
			"label": "CAR HURRICANES"
	},
	{
			"value": "LOVE LABELS",
			"label": "LOVE LABELS"
	},
	{
			"value": "JIMMY BUFFET",
			"label": "JIMMY BUFFET"
	},
	{
			"value": "BAZOOKA",
			"label": "BAZOOKA"
	},
	{
			"value": "DEN BRONCOS",
			"label": "DEN BRONCOS"
	},
	{
			"value": "GLOWFORGE",
			"label": "GLOWFORGE"
	},
	{
			"value": "AZ COYOTES",
			"label": "AZ COYOTES"
	},
	{
			"value": "PARRAGON BOOKS",
			"label": "PARRAGON BOOKS"
	},
	{
			"value": "MUNSTERS",
			"label": "MUNSTERS"
	},
	{
			"value": "HEMPTIQUE",
			"label": "HEMPTIQUE"
	},
	{
			"value": "STORCK",
			"label": "STORCK"
	},
	{
			"value": "JUST GOOD DAYS",
			"label": "JUST GOOD DAYS"
	},
	{
			"value": "DC COMICS",
			"label": "DC COMICS"
	},
	{
			"value": "CRICUT CAKE",
			"label": "CRICUT CAKE"
	},
	{
			"value": "INKADINKADO",
			"label": "INKADINKADO"
	},
	{
			"value": "HASBRO",
			"label": "HASBRO"
	},
	{
			"value": "AKRON",
			"label": "AKRON"
	},
	{
			"value": "JUKI",
			"label": "JUKI"
	},
	{
			"value": "SCHOOL ZONE",
			"label": "SCHOOL ZONE"
	},
	{
			"value": "SNOW TEX",
			"label": "SNOW TEX"
	},
	{
			"value": "XAVIER",
			"label": "XAVIER"
	},
	{
			"value": "FAIR ISLE",
			"label": "FAIR ISLE"
	},
	{
			"value": "NORFOLK TIDES",
			"label": "NORFOLK TIDES"
	},
	{
			"value": "LALIBERI",
			"label": "LALIBERI"
	},
	{
			"value": "FPC",
			"label": "FPC"
	},
	{
			"value": "COTSWOLD",
			"label": "COTSWOLD"
	},
	{
			"value": "POP!",
			"label": "POP!"
	},
	{
			"value": "TORONTO RAPTORS",
			"label": "TORONTO RAPTORS"
	},
	{
			"value": "DCWV",
			"label": "DCWV"
	},
	{
			"value": "YARN TREE",
			"label": "YARN TREE"
	},
	{
			"value": "CREATIVE HANDS",
			"label": "CREATIVE HANDS"
	},
	{
			"value": "KUMIHIMO",
			"label": "KUMIHIMO"
	},
	{
			"value": "MAGNUM MAGNETICS CORP",
			"label": "MAGNUM MAGNETICS CORP"
	},
	{
			"value": "ROSEWOOD",
			"label": "ROSEWOOD"
	},
	{
			"value": "ECHO PARK PAPER",
			"label": "ECHO PARK PAPER"
	},
	{
			"value": "ANNA GRIFFIN",
			"label": "ANNA GRIFFIN"
	},
	{
			"value": "AT-A-GLANCE",
			"label": "AT-A-GLANCE"
	},
	{
			"value": "TAYLOR SEVILLE",
			"label": "TAYLOR SEVILLE"
	},
	{
			"value": "PAW PATROL",
			"label": "PAW PATROL"
	},
	{
			"value": "HELLO KITTY",
			"label": "HELLO KITTY"
	},
	{
			"value": "CM DESIGNS",
			"label": "CM DESIGNS"
	},
	{
			"value": "EDMONTON OILERS",
			"label": "EDMONTON OILERS"
	},
	{
			"value": "TOM NORTON",
			"label": "TOM NORTON"
	},
	{
			"value": "TWIZZLER",
			"label": "TWIZZLER"
	},
	{
			"value": "US COAST GUARD",
			"label": "US COAST GUARD"
	},
	{
			"value": "LANG",
			"label": "LANG"
	},
	{
			"value": "SUNNYSIDE",
			"label": "SUNNYSIDE"
	},
	{
			"value": "AIRTEX",
			"label": "AIRTEX"
	},
	{
			"value": "BUF BILLS",
			"label": "BUF BILLS"
	},
	{
			"value": "NOVOGRATZ",
			"label": "NOVOGRATZ"
	},
	{
			"value": "SP MISSISSIPPI",
			"label": "SP MISSISSIPPI"
	},
	{
			"value": "ABG- THOMASVILLE",
			"label": "ABG- THOMASVILLE"
	},
	{
			"value": "DOWLING MAGNETS",
			"label": "DOWLING MAGNETS"
	},
	{
			"value": "COLLINS",
			"label": "COLLINS"
	},
	{
			"value": "NUFOAM",
			"label": "NUFOAM"
	},
	{
			"value": "TOTALLY-TIFFANY",
			"label": "TOTALLY-TIFFANY"
	},
	{
			"value": "MIA DOLPHINS",
			"label": "MIA DOLPHINS"
	},
	{
			"value": "ELEMENTAL AROMATICS",
			"label": "ELEMENTAL AROMATICS"
	},
	{
			"value": "STUDIO 490/WENDY VECCHI",
			"label": "STUDIO 490/WENDY VECCHI"
	},
	{
			"value": "DRESS YOUR DOLL",
			"label": "DRESS YOUR DOLL"
	},
	{
			"value": "COLOR FORMS",
			"label": "COLOR FORMS"
	},
	{
			"value": "HARRY POTTER",
			"label": "HARRY POTTER"
	},
	{
			"value": "BADGER AIR-BRUSH COMPANY",
			"label": "BADGER AIR-BRUSH COMPANY"
	},
	{
			"value": "KENT DISPLAYS",
			"label": "KENT DISPLAYS"
	},
	{
			"value": "NORTHWESTERN",
			"label": "NORTHWESTERN"
	},
	{
			"value": "KENTUCKY",
			"label": "KENTUCKY"
	},
	{
			"value": "DUNCAN",
			"label": "DUNCAN"
	},
	{
			"value": "100% RECYCLABLE",
			"label": "100% RECYCLABLE"
	},
	{
			"value": "SNICKERS",
			"label": "SNICKERS"
	},
	{
			"value": "SO GLOW",
			"label": "SO GLOW"
	},
	{
			"value": "CANDAMAR DESIGNS",
			"label": "CANDAMAR DESIGNS"
	},
	{
			"value": "WESTEX",
			"label": "WESTEX"
	},
	{
			"value": "PIROULINE",
			"label": "PIROULINE"
	},
	{
			"value": "WELLNESS",
			"label": "WELLNESS"
	},
	{
			"value": "STL BLUES",
			"label": "STL BLUES"
	},
	{
			"value": "KREINIK",
			"label": "KREINIK"
	},
	{
			"value": "GENERAL MILLS",
			"label": "GENERAL MILLS"
	},
	{
			"value": "WELCHS",
			"label": "WELCHS"
	},
	{
			"value": "PALO SANTO",
			"label": "PALO SANTO"
	},
	{
			"value": "NEW WAVE",
			"label": "NEW WAVE"
	},
	{
			"value": "PHI PHILLIES",
			"label": "PHI PHILLIES"
	},
	{
			"value": "HIGGINS",
			"label": "HIGGINS"
	},
	{
			"value": "ROYAL LACE",
			"label": "ROYAL LACE"
	},
	{
			"value": "BEADERY",
			"label": "BEADERY"
	},
	{
			"value": "FALL FARMHOUSE",
			"label": "FALL FARMHOUSE"
	},
	{
			"value": "BOISE ST",
			"label": "BOISE ST"
	},
	{
			"value": "DURA",
			"label": "DURA"
	},
	{
			"value": "EYELET OUTLET",
			"label": "EYELET OUTLET"
	},
	{
			"value": "SP ALASKA",
			"label": "SP ALASKA"
	},
	{
			"value": "BLAIR",
			"label": "BLAIR"
	},
	{
			"value": "PHOTOPLAY",
			"label": "PHOTOPLAY"
	},
	{
			"value": "AMERICAN TRADITIONAL",
			"label": "AMERICAN TRADITIONAL"
	},
	{
			"value": "JO-ANN ESSENTIALS",
			"label": "JO-ANN ESSENTIALS"
	},
	{
			"value": "K9 CAKERY",
			"label": "K9 CAKERY"
	},
	{
			"value": "JOANN HOLIDAY",
			"label": "JOANN HOLIDAY"
	},
	{
			"value": "ALLSTATE",
			"label": "ALLSTATE"
	},
	{
			"value": "STUDIO CALICO",
			"label": "STUDIO CALICO"
	},
	{
			"value": "EZ CRAFT",
			"label": "EZ CRAFT"
	},
	{
			"value": "ARETE ATHENE",
			"label": "ARETE ATHENE"
	},
	{
			"value": "CLAUSS",
			"label": "CLAUSS"
	},
	{
			"value": "CURTIS WAGNER",
			"label": "CURTIS WAGNER"
	},
	{
			"value": "SPORTS NYLON",
			"label": "SPORTS NYLON"
	},
	{
			"value": "ADORN-IT",
			"label": "ADORN-IT"
	},
	{
			"value": "IRIS",
			"label": "IRIS"
	},
	{
			"value": "AMSTERDAM",
			"label": "AMSTERDAM"
	},
	{
			"value": "ECO-HOUSE",
			"label": "ECO-HOUSE"
	},
	{
			"value": "COLOR POUR",
			"label": "COLOR POUR"
	},
	{
			"value": "SHIMMER N SPARKLE",
			"label": "SHIMMER N SPARKLE"
	},
	{
			"value": "COTTAGECUTZ",
			"label": "COTTAGECUTZ"
	},
	{
			"value": "LIFE SAVERS",
			"label": "LIFE SAVERS"
	},
	{
			"value": "DURO ART",
			"label": "DURO ART"
	},
	{
			"value": "MOD PODGE",
			"label": "MOD PODGE"
	},
	{
			"value": "BELLA",
			"label": "BELLA"
	},
	{
			"value": "MAD BEAUTY",
			"label": "MAD BEAUTY"
	},
	{
			"value": "BRACHS",
			"label": "BRACHS"
	},
	{
			"value": "GEMINI",
			"label": "GEMINI"
	},
	{
			"value": "AMERICAN GIRL",
			"label": "AMERICAN GIRL"
	},
	{
			"value": "MAKERS MIX",
			"label": "MAKERS MIX"
	},
	{
			"value": "PROMOTION IN MOTION",
			"label": "PROMOTION IN MOTION"
	},
	{
			"value": "DRESS MY CRAFT",
			"label": "DRESS MY CRAFT"
	},
	{
			"value": "GRINCH",
			"label": "GRINCH"
	},
	{
			"value": "STRETCH MAGIC",
			"label": "STRETCH MAGIC"
	},
	{
			"value": "ANOLON",
			"label": "ANOLON"
	},
	{
			"value": "BALLOON BUDDY",
			"label": "BALLOON BUDDY"
	},
	{
			"value": "MAGNI-CLIPS",
			"label": "MAGNI-CLIPS"
	},
	{
			"value": "GOSSIP GIRL",
			"label": "GOSSIP GIRL"
	},
	{
			"value": "MICHIGAN ST",
			"label": "MICHIGAN ST"
	},
	{
			"value": "RIT DYE",
			"label": "RIT DYE"
	},
	{
			"value": "KAREN KAY BUCKLEY",
			"label": "KAREN KAY BUCKLEY"
	},
	{
			"value": "STEM PROJECTS",
			"label": "STEM PROJECTS"
	},
	{
			"value": "GIDDY UP",
			"label": "GIDDY UP"
	},
	{
			"value": "CHRISTOPHER LOWELL",
			"label": "CHRISTOPHER LOWELL"
	},
	{
			"value": "MRS. GROSSMAN'S",
			"label": "MRS. GROSSMAN'S"
	},
	{
			"value": "STABILO",
			"label": "STABILO"
	},
	{
			"value": "TRUE CUT",
			"label": "TRUE CUT"
	},
	{
			"value": "PRINCETON BRUSH",
			"label": "PRINCETON BRUSH"
	},
	{
			"value": "MICADOR",
			"label": "MICADOR"
	},
	{
			"value": "SCOTCH",
			"label": "SCOTCH"
	},
	{
			"value": "THE KRAFTY GOURMET",
			"label": "THE KRAFTY GOURMET"
	},
	{
			"value": "FLUFFY STUFF",
			"label": "FLUFFY STUFF"
	},
	{
			"value": "DISTRIVALTO",
			"label": "DISTRIVALTO"
	},
	{
			"value": "CRAFTY CHIC",
			"label": "CRAFTY CHIC"
	},
	{
			"value": "PAPER SMOOCHES",
			"label": "PAPER SMOOCHES"
	},
	{
			"value": "ROYAL BRUSH",
			"label": "ROYAL BRUSH"
	},
	{
			"value": "PRIMA",
			"label": "PRIMA"
	},
	{
			"value": "WORLDS SMALLEST",
			"label": "WORLDS SMALLEST"
	},
	{
			"value": "DEFLECTO",
			"label": "DEFLECTO"
	},
	{
			"value": "US ARMY",
			"label": "US ARMY"
	},
	{
			"value": "ROSE ART",
			"label": "ROSE ART"
	},
	{
			"value": "RUBIKS CUBE",
			"label": "RUBIKS CUBE"
	},
	{
			"value": "AMONG US",
			"label": "AMONG US"
	},
	{
			"value": "X-PRESS IT",
			"label": "X-PRESS IT"
	},
	{
			"value": "PLAID",
			"label": "PLAID"
	},
	{
			"value": "FROZEN",
			"label": "FROZEN"
	},
	{
			"value": "LILY & VAL LLC",
			"label": "LILY & VAL LLC"
	},
	{
			"value": "AMERICAN LICORICE",
			"label": "AMERICAN LICORICE"
	},
	{
			"value": "SPRING FLORAL",
			"label": "SPRING FLORAL"
	},
	{
			"value": "S FLORIDA",
			"label": "S FLORIDA"
	},
	{
			"value": "FIBRE CRAFT",
			"label": "FIBRE CRAFT"
	},
	{
			"value": "SP INDIANA",
			"label": "SP INDIANA"
	},
	{
			"value": "SWAVELLE MILLCREEK",
			"label": "SWAVELLE MILLCREEK"
	},
	{
			"value": "LORANN OILS",
			"label": "LORANN OILS"
	},
	{
			"value": "SP PENNSYLVANIA",
			"label": "SP PENNSYLVANIA"
	},
	{
			"value": "HOLLYWOOD FASHION SECRETS",
			"label": "HOLLYWOOD FASHION SECRETS"
	},
	{
			"value": "SMITHSONIAN",
			"label": "SMITHSONIAN"
	},
	{
			"value": "45'' HOME ESSENTIALS",
			"label": "45'' HOME ESSENTIALS"
	},
	{
			"value": "SUMMER",
			"label": "SUMMER"
	},
	{
			"value": "JEANINE'S ART",
			"label": "JEANINE'S ART"
	},
	{
			"value": "STERILITE",
			"label": "STERILITE"
	},
	{
			"value": "NY GIANTS",
			"label": "NY GIANTS"
	},
	{
			"value": "GOOD HUMOR",
			"label": "GOOD HUMOR"
	},
	{
			"value": "KINGART",
			"label": "KINGART"
	},
	{
			"value": "DBEST",
			"label": "DBEST"
	},
	{
			"value": "TEN TITANS",
			"label": "TEN TITANS"
	},
	{
			"value": "MARY ELLEN PRODUCTS",
			"label": "MARY ELLEN PRODUCTS"
	},
	{
			"value": "MARTIN UNIVERSAL",
			"label": "MARTIN UNIVERSAL"
	},
	{
			"value": "RED HEART",
			"label": "RED HEART"
	},
	{
			"value": "TACOMA RAINIERS",
			"label": "TACOMA RAINIERS"
	},
	{
			"value": "LA RAMS",
			"label": "LA RAMS"
	},
	{
			"value": "CONNIE HALEY",
			"label": "CONNIE HALEY"
	},
	{
			"value": "ENDURPEL",
			"label": "ENDURPEL"
	},
	{
			"value": "MANCHESTER CITY",
			"label": "MANCHESTER CITY"
	},
	{
			"value": "KNIT & CROCHET",
			"label": "KNIT & CROCHET"
	},
	{
			"value": "PURELL",
			"label": "PURELL"
	},
	{
			"value": "EVERYTHING MARY",
			"label": "EVERYTHING MARY"
	},
	{
			"value": "LOONEY TUNES",
			"label": "LOONEY TUNES"
	},
	{
			"value": "BEE PAPER",
			"label": "BEE PAPER"
	},
	{
			"value": "AC GIFT",
			"label": "AC GIFT"
	},
	{
			"value": "AUNT LYDIA",
			"label": "AUNT LYDIA"
	},
	{
			"value": "JEWELRY MADE BY ME",
			"label": "JEWELRY MADE BY ME"
	},
	{
			"value": "PRETTY TWISTED CRAFTS",
			"label": "PRETTY TWISTED CRAFTS"
	},
	{
			"value": "HOMESPUN",
			"label": "HOMESPUN"
	},
	{
			"value": "STRATHMORE",
			"label": "STRATHMORE"
	},
	{
			"value": "GRABAROOS",
			"label": "GRABAROOS"
	},
	{
			"value": "DONNA DOWNEY STENCILS",
			"label": "DONNA DOWNEY STENCILS"
	},
	{
			"value": "SANTORO",
			"label": "SANTORO"
	},
	{
			"value": "PURDUE",
			"label": "PURDUE"
	},
	{
			"value": "SP MAINE",
			"label": "SP MAINE"
	},
	{
			"value": "BODY RITE",
			"label": "BODY RITE"
	},
	{
			"value": "REEVES",
			"label": "REEVES"
	},
	{
			"value": "TEXAS A&M",
			"label": "TEXAS A&M"
	},
	{
			"value": "MAGNETIC SPECIALTY",
			"label": "MAGNETIC SPECIALTY"
	},
	{
			"value": "PINKFRESH STUDIO",
			"label": "PINKFRESH STUDIO"
	},
	{
			"value": "SLIME SHAKER",
			"label": "SLIME SHAKER"
	},
	{
			"value": "MAGIC MOON",
			"label": "MAGIC MOON"
	},
	{
			"value": "SP OREGON",
			"label": "SP OREGON"
	},
	{
			"value": "CHI BEARS",
			"label": "CHI BEARS"
	},
	{
			"value": "NBA",
			"label": "NBA"
	},
	{
			"value": "DENA HOME",
			"label": "DENA HOME"
	},
	{
			"value": "UNICORN SPIT",
			"label": "UNICORN SPIT"
	},
	{
			"value": "LEJOUR",
			"label": "LEJOUR"
	},
	{
			"value": "PITT PENGUINS",
			"label": "PITT PENGUINS"
	},
	{
			"value": "US ARTQUEST",
			"label": "US ARTQUEST"
	},
	{
			"value": "CREATIVE EXPRESSIONS",
			"label": "CREATIVE EXPRESSIONS"
	},
	{
			"value": "DIMENSIONS",
			"label": "DIMENSIONS"
	},
	{
			"value": "MISSISSIPPI",
			"label": "MISSISSIPPI"
	},
	{
			"value": "LOCALFARE",
			"label": "LOCALFARE"
	},
	{
			"value": "LAUREL BURCH",
			"label": "LAUREL BURCH"
	},
	{
			"value": "SCULPTO",
			"label": "SCULPTO"
	},
	{
			"value": "POLAROID",
			"label": "POLAROID"
	},
	{
			"value": "PRECIOUS MOMENTS",
			"label": "PRECIOUS MOMENTS"
	},
	{
			"value": "SNAZAROO",
			"label": "SNAZAROO"
	},
	{
			"value": "TOBIN",
			"label": "TOBIN"
	},
	{
			"value": "RIP STOP",
			"label": "RIP STOP"
	},
	{
			"value": "FAB LAB",
			"label": "FAB LAB"
	},
	{
			"value": "CREATIVE OPTIONS",
			"label": "CREATIVE OPTIONS"
	},
	{
			"value": "EASYCAST",
			"label": "EASYCAST"
	},
	{
			"value": "BEAD BUDDY",
			"label": "BEAD BUDDY"
	},
	{
			"value": "VIVILUX",
			"label": "VIVILUX"
	},
	{
			"value": "FRIENDS",
			"label": "FRIENDS"
	},
	{
			"value": "KARIN",
			"label": "KARIN"
	},
	{
			"value": "THE SHINING",
			"label": "THE SHINING"
	},
	{
			"value": "ABRAHAM HUNTER",
			"label": "ABRAHAM HUNTER"
	},
	{
			"value": "KRYLON",
			"label": "KRYLON"
	},
	{
			"value": "IMPULSE",
			"label": "IMPULSE"
	},
	{
			"value": "BACK TO CAMPUS",
			"label": "BACK TO CAMPUS"
	},
	{
			"value": "SP CALIFORNIA",
			"label": "SP CALIFORNIA"
	},
	{
			"value": "STICKY ASS",
			"label": "STICKY ASS"
	},
	{
			"value": "ADTECH",
			"label": "ADTECH"
	},
	{
			"value": "AMY DESIGN",
			"label": "AMY DESIGN"
	},
	{
			"value": "STOREX INDUSTRIES",
			"label": "STOREX INDUSTRIES"
	},
	{
			"value": "TOOB",
			"label": "TOOB"
	},
	{
			"value": "HARVEST",
			"label": "HARVEST"
	},
	{
			"value": "NUTRIBULLET",
			"label": "NUTRIBULLET"
	},
	{
			"value": "ALEENE'S",
			"label": "ALEENE'S"
	},
	{
			"value": "HELLO LOVELY",
			"label": "HELLO LOVELY"
	},
	{
			"value": "DECORWARE",
			"label": "DECORWARE"
	},
	{
			"value": "ANITA'S",
			"label": "ANITA'S"
	},
	{
			"value": "PRIMA MARKETING",
			"label": "PRIMA MARKETING"
	},
	{
			"value": "THE NEW IMAGE GROUP",
			"label": "THE NEW IMAGE GROUP"
	},
	{
			"value": "KROY",
			"label": "KROY"
	},
	{
			"value": "WE MADE IT",
			"label": "WE MADE IT"
	},
	{
			"value": "SUSAN WINGET",
			"label": "SUSAN WINGET"
	},
	{
			"value": "SOUTH PARK",
			"label": "SOUTH PARK"
	},
	{
			"value": "HOLIDAY WONDERS",
			"label": "HOLIDAY WONDERS"
	},
	{
			"value": "TROPIX",
			"label": "TROPIX"
	},
	{
			"value": "CASA",
			"label": "CASA"
	},
	{
			"value": "PRINTWORKS",
			"label": "PRINTWORKS"
	},
	{
			"value": "ICE RESIN",
			"label": "ICE RESIN"
	},
	{
			"value": "LINECO/UNIVERSITY PRODUCTS",
			"label": "LINECO/UNIVERSITY PRODUCTS"
	},
	{
			"value": "BRIARPATCH",
			"label": "BRIARPATCH"
	},
	{
			"value": "RED WHITE & BOLD",
			"label": "RED WHITE & BOLD"
	},
	{
			"value": "COLEMAN",
			"label": "COLEMAN"
	},
	{
			"value": "WORSTED",
			"label": "WORSTED"
	},
	{
			"value": "BREAKFAST CLUB",
			"label": "BREAKFAST CLUB"
	},
	{
			"value": "U BRANDS",
			"label": "U BRANDS"
	},
	{
			"value": "COLONIAL NEEDLE",
			"label": "COLONIAL NEEDLE"
	},
	{
			"value": "BANANYA KITTY",
			"label": "BANANYA KITTY"
	},
	{
			"value": "ALLSOP HOME & GARDEN",
			"label": "ALLSOP HOME & GARDEN"
	},
	{
			"value": "PURSE-N-ALIZE",
			"label": "PURSE-N-ALIZE"
	},
	{
			"value": "DOMESTIC",
			"label": "DOMESTIC"
	},
	{
			"value": "SP LOUISIANA",
			"label": "SP LOUISIANA"
	},
	{
			"value": "SPRING SUMMER FLORAL",
			"label": "SPRING SUMMER FLORAL"
	},
	{
			"value": "RANGER",
			"label": "RANGER"
	},
	{
			"value": "JANE'S DOODLES",
			"label": "JANE'S DOODLES"
	},
	{
			"value": "POP! ST PATRICK'S",
			"label": "POP! ST PATRICK'S"
	},
	{
			"value": "DIE-NAMITES",
			"label": "DIE-NAMITES"
	},
	{
			"value": "CHIPS AHOY",
			"label": "CHIPS AHOY"
	},
	{
			"value": "LIFE SAVER",
			"label": "LIFE SAVER"
	},
	{
			"value": "UNI THE UNICORN",
			"label": "UNI THE UNICORN"
	},
	{
			"value": "OLIVIA MOSS",
			"label": "OLIVIA MOSS"
	},
	{
			"value": "MELROSE",
			"label": "MELROSE"
	},
	{
			"value": "TASTE OF NATURE",
			"label": "TASTE OF NATURE"
	},
	{
			"value": "AZ DBACKS",
			"label": "AZ DBACKS"
	},
	{
			"value": "MAKE IT LOVE IT",
			"label": "MAKE IT LOVE IT"
	},
	{
			"value": "CRAF-T PRODUCTS",
			"label": "CRAF-T PRODUCTS"
	},
	{
			"value": "MRS WAGES",
			"label": "MRS WAGES"
	},
	{
			"value": "ARTOGRAPH",
			"label": "ARTOGRAPH"
	},
	{
			"value": "NOBRAND",
			"label": "NOBRAND"
	},
	{
			"value": "PIONEER",
			"label": "PIONEER"
	},
	{
			"value": "MECCANO",
			"label": "MECCANO"
	},
	{
			"value": "BETTY BOOP",
			"label": "BETTY BOOP"
	},
	{
			"value": "SCRIBBLES",
			"label": "SCRIBBLES"
	},
	{
			"value": "FIMO",
			"label": "FIMO"
	},
	{
			"value": "HANES",
			"label": "HANES"
	},
	{
			"value": "RGM",
			"label": "RGM"
	},
	{
			"value": "GIFTWARES",
			"label": "GIFTWARES"
	},
	{
			"value": "MARBLEOCITY",
			"label": "MARBLEOCITY"
	},
	{
			"value": "VINTAJ",
			"label": "VINTAJ"
	},
	{
			"value": "JOANN ST PATRICK'S DAY",
			"label": "JOANN ST PATRICK'S DAY"
	},
	{
			"value": "DEXTER",
			"label": "DEXTER"
	},
	{
			"value": "GAME OF THRONES",
			"label": "GAME OF THRONES"
	},
	{
			"value": "AMSCAN",
			"label": "AMSCAN"
	},
	{
			"value": "SOAK",
			"label": "SOAK"
	},
	{
			"value": "BYU",
			"label": "BYU"
	},
	{
			"value": "KOI",
			"label": "KOI"
	},
	{
			"value": "ELF ON THE SHELF",
			"label": "ELF ON THE SHELF"
	},
	{
			"value": "BRIGHTECH",
			"label": "BRIGHTECH"
	},
	{
			"value": "VIRGINIA CW",
			"label": "VIRGINIA CW"
	},
	{
			"value": "LOOPDEDOO",
			"label": "LOOPDEDOO"
	},
	{
			"value": "CRAFT A BREW",
			"label": "CRAFT A BREW"
	},
	{
			"value": "KLUTZ",
			"label": "KLUTZ"
	},
	{
			"value": "JANE DAVENPORT",
			"label": "JANE DAVENPORT"
	},
	{
			"value": "JUNIOR MINTS",
			"label": "JUNIOR MINTS"
	},
	{
			"value": "POOF-SLINKY",
			"label": "POOF-SLINKY"
	},
	{
			"value": "JAMES MADISON",
			"label": "JAMES MADISON"
	},
	{
			"value": "ROYAL",
			"label": "ROYAL"
	},
	{
			"value": "WALKER’S",
			"label": "WALKER’S"
	},
	{
			"value": "OLFA",
			"label": "OLFA"
	},
	{
			"value": "SUGRU",
			"label": "SUGRU"
	},
	{
			"value": "AMERICANA",
			"label": "AMERICANA"
	},
	{
			"value": "CORNINGWARE",
			"label": "CORNINGWARE"
	},
	{
			"value": "SULLIVANS",
			"label": "SULLIVANS"
	},
	{
			"value": "NIGHTMARE ON ELM ST",
			"label": "NIGHTMARE ON ELM ST"
	},
	{
			"value": "ISABEY",
			"label": "ISABEY"
	},
	{
			"value": "JANOME",
			"label": "JANOME"
	},
	{
			"value": "MIRACLE CHALK",
			"label": "MIRACLE CHALK"
	},
	{
			"value": "SCOR BUDDY",
			"label": "SCOR BUDDY"
	},
	{
			"value": "ARIZONA ST",
			"label": "ARIZONA ST"
	},
	{
			"value": "WORKS OF AHHH",
			"label": "WORKS OF AHHH"
	},
	{
			"value": "FLORA & FAUNA",
			"label": "FLORA & FAUNA"
	},
	{
			"value": "LEGACY STUDIO",
			"label": "LEGACY STUDIO"
	},
	{
			"value": "MAGIC NUUDLE",
			"label": "MAGIC NUUDLE"
	},
	{
			"value": "SANTA'S BAGS",
			"label": "SANTA'S BAGS"
	},
	{
			"value": "RICHLOOM SOLARIUM",
			"label": "RICHLOOM SOLARIUM"
	},
	{
			"value": "1 SHOT",
			"label": "1 SHOT"
	},
	{
			"value": "BOARD DUDES",
			"label": "BOARD DUDES"
	},
	{
			"value": "HEIDI SWAPP",
			"label": "HEIDI SWAPP"
	},
	{
			"value": "TRENDS INTERNATIONAL",
			"label": "TRENDS INTERNATIONAL"
	},
	{
			"value": "SIGMA DISTRIBUTORS",
			"label": "SIGMA DISTRIBUTORS"
	},
	{
			"value": "DOTZIES",
			"label": "DOTZIES"
	},
	{
			"value": "DISNEY",
			"label": "DISNEY"
	},
	{
			"value": "BEST VALUE",
			"label": "BEST VALUE"
	},
	{
			"value": "AMACO",
			"label": "AMACO"
	},
	{
			"value": "FUJI",
			"label": "FUJI"
	},
	{
			"value": "RUDOLPH",
			"label": "RUDOLPH"
	},
	{
			"value": "LOOME",
			"label": "LOOME"
	},
	{
			"value": "STUDIO LIGHT",
			"label": "STUDIO LIGHT"
	},
	{
			"value": "OXO",
			"label": "OXO"
	},
	{
			"value": "INKED BY DANI",
			"label": "INKED BY DANI"
	},
	{
			"value": "KEMPER",
			"label": "KEMPER"
	},
	{
			"value": "SAVE THE DATE",
			"label": "SAVE THE DATE"
	},
	{
			"value": "AMAZING GOOP",
			"label": "AMAZING GOOP"
	},
	{
			"value": "PME",
			"label": "PME"
	},
	{
			"value": "FISKARS",
			"label": "FISKARS"
	},
	{
			"value": "MINN TWINS",
			"label": "MINN TWINS"
	},
	{
			"value": "ALABAMA",
			"label": "ALABAMA"
	},
	{
			"value": "KIMBER CAKEWARE",
			"label": "KIMBER CAKEWARE"
	},
	{
			"value": "TOASTMASTER",
			"label": "TOASTMASTER"
	},
	{
			"value": "CARAN D'ACHE",
			"label": "CARAN D'ACHE"
	},
	{
			"value": "RUPERT GIBBON AND SPIDER INC",
			"label": "RUPERT GIBBON AND SPIDER INC"
	},
	{
			"value": "BELLA DURA",
			"label": "BELLA DURA"
	},
	{
			"value": "MASTERPIECES",
			"label": "MASTERPIECES"
	},
	{
			"value": "TERESA COLLINS",
			"label": "TERESA COLLINS"
	},
	{
			"value": "FOUNDRY",
			"label": "FOUNDRY"
	},
	{
			"value": "PRIDE",
			"label": "PRIDE"
	},
	{
			"value": "CAROLINA",
			"label": "CAROLINA"
	},
	{
			"value": "HUSQVARNA",
			"label": "HUSQVARNA"
	},
	{
			"value": "SP OKLAHOMA",
			"label": "SP OKLAHOMA"
	},
	{
			"value": "MY COLORS",
			"label": "MY COLORS"
	},
	{
			"value": "HTV CRAFTS",
			"label": "HTV CRAFTS"
	},
	{
			"value": "GRUMPY CAT",
			"label": "GRUMPY CAT"
	},
	{
			"value": "HATCHIMAL",
			"label": "HATCHIMAL"
	},
	{
			"value": "CANON",
			"label": "CANON"
	},
	{
			"value": "HUDSON 43",
			"label": "HUDSON 43"
	},
	{
			"value": "FOSS PERFORMANCE MATERIALS LLC",
			"label": "FOSS PERFORMANCE MATERIALS LLC"
	},
	{
			"value": "SCHMINCKE",
			"label": "SCHMINCKE"
	},
	{
			"value": "DARKROOM DOOR",
			"label": "DARKROOM DOOR"
	},
	{
			"value": "LOL SURPRISE",
			"label": "LOL SURPRISE"
	},
	{
			"value": "ACDC",
			"label": "ACDC"
	},
	{
			"value": "WASHINGTON ST",
			"label": "WASHINGTON ST"
	},
	{
			"value": "FIRELINE",
			"label": "FIRELINE"
	},
	{
			"value": "NINTENDO",
			"label": "NINTENDO"
	},
	{
			"value": "SCRAPERFECT",
			"label": "SCRAPERFECT"
	},
	{
			"value": "KC CHIEFS",
			"label": "KC CHIEFS"
	},
	{
			"value": "THE WIGGLES",
			"label": "THE WIGGLES"
	},
	{
			"value": "PUZZLE",
			"label": "PUZZLE"
	},
	{
			"value": "WEST VIRGINIA",
			"label": "WEST VIRGINIA"
	},
	{
			"value": "ORB FACTORY",
			"label": "ORB FACTORY"
	},
	{
			"value": "ROUND 2",
			"label": "ROUND 2"
	},
	{
			"value": "FEATHER LOFT",
			"label": "FEATHER LOFT"
	},
	{
			"value": "ORGANIZING ESSENTIALS",
			"label": "ORGANIZING ESSENTIALS"
	},
	{
			"value": "ART CLAY",
			"label": "ART CLAY"
	},
	{
			"value": "SIMBRIX",
			"label": "SIMBRIX"
	},
	{
			"value": "LEISURE ARTS",
			"label": "LEISURE ARTS"
	},
	{
			"value": "SILVALUME",
			"label": "SILVALUME"
	},
	{
			"value": "FOUR SEASONS",
			"label": "FOUR SEASONS"
	},
	{
			"value": "POO-POURRI",
			"label": "POO-POURRI"
	},
	{
			"value": "REUSABLE TOTE",
			"label": "REUSABLE TOTE"
	},
	{
			"value": "STONEHENGE",
			"label": "STONEHENGE"
	},
	{
			"value": "CHALK AND BUBBLES",
			"label": "CHALK AND BUBBLES"
	},
	{
			"value": "ELEGANT WRITER",
			"label": "ELEGANT WRITER"
	},
	{
			"value": "GLUE DOTS",
			"label": "GLUE DOTS"
	},
	{
			"value": "LITTLE MAKERS",
			"label": "LITTLE MAKERS"
	},
	{
			"value": "KUM",
			"label": "KUM"
	},
	{
			"value": "RUST-OLEUM",
			"label": "RUST-OLEUM"
	},
	{
			"value": "APPLE BARREL",
			"label": "APPLE BARREL"
	},
	{
			"value": "CREATIVITY FOR KIDS",
			"label": "CREATIVITY FOR KIDS"
	},
	{
			"value": "MONSTER JAM",
			"label": "MONSTER JAM"
	},
	{
			"value": "CRAFT UK",
			"label": "CRAFT UK"
	},
	{
			"value": "PRAT",
			"label": "PRAT"
	},
	{
			"value": "CRICUT",
			"label": "CRICUT"
	},
	{
			"value": "KELLY CREATES",
			"label": "KELLY CREATES"
	},
	{
			"value": "SIMPLE STORIES",
			"label": "SIMPLE STORIES"
	},
	{
			"value": "GOURMET RUBBER STAMPS",
			"label": "GOURMET RUBBER STAMPS"
	},
	{
			"value": "MOREHOUSE",
			"label": "MOREHOUSE"
	},
	{
			"value": "MOLANG",
			"label": "MOLANG"
	},
	{
			"value": "POP! EASTER",
			"label": "POP! EASTER"
	},
	{
			"value": "STAMPENDOUS",
			"label": "STAMPENDOUS"
	},
	{
			"value": "NERDS",
			"label": "NERDS"
	},
	{
			"value": "BUSY KIDS KIDS CAMP",
			"label": "BUSY KIDS KIDS CAMP"
	},
	{
			"value": "UKIDZ",
			"label": "UKIDZ"
	},
	{
			"value": "COBRA",
			"label": "COBRA"
	},
	{
			"value": "ALEXANDER HENRY",
			"label": "ALEXANDER HENRY"
	},
	{
			"value": "COSMIC SHIMMER",
			"label": "COSMIC SHIMMER"
	},
	{
			"value": "KISS",
			"label": "KISS"
	},
	{
			"value": "HEAT PRESS BATTING",
			"label": "HEAT PRESS BATTING"
	},
	{
			"value": "DIAMOND TECH",
			"label": "DIAMOND TECH"
	},
	{
			"value": "COMFORTLOFT",
			"label": "COMFORTLOFT"
	},
	{
			"value": "HOBB'S HEIRLOOM",
			"label": "HOBB'S HEIRLOOM"
	},
	{
			"value": "SOUR PATCH",
			"label": "SOUR PATCH"
	},
	{
			"value": "PITT STEELERS",
			"label": "PITT STEELERS"
	},
	{
			"value": "MAXANT",
			"label": "MAXANT"
	},
	{
			"value": "GILDERS PASTE",
			"label": "GILDERS PASTE"
	},
	{
			"value": "WILDFIRE",
			"label": "WILDFIRE"
	},
	{
			"value": "SISTERS GOURMET",
			"label": "SISTERS GOURMET"
	},
	{
			"value": "SULYN INDUSTRIES",
			"label": "SULYN INDUSTRIES"
	},
	{
			"value": "JAKKS",
			"label": "JAKKS"
	},
	{
			"value": "TEXAS CHRISTIAN",
			"label": "TEXAS CHRISTIAN"
	},
	{
			"value": "CUB SCOUTS",
			"label": "CUB SCOUTS"
	},
	{
			"value": "MEM REDBIRDS",
			"label": "MEM REDBIRDS"
	},
	{
			"value": "CRYSTAL NINJA",
			"label": "CRYSTAL NINJA"
	},
	{
			"value": "CHI BULLS",
			"label": "CHI BULLS"
	},
	{
			"value": "GREENSMART DEKOR",
			"label": "GREENSMART DEKOR"
	},
	{
			"value": "BERGERE DE FRANCE",
			"label": "BERGERE DE FRANCE"
	},
	{
			"value": "FABER-CASTELL",
			"label": "FABER-CASTELL"
	},
	{
			"value": "FRESH",
			"label": "FRESH"
	},
	{
			"value": "SWEDISH FISH",
			"label": "SWEDISH FISH"
	},
	{
			"value": "NASHVILLE PREDATORS",
			"label": "NASHVILLE PREDATORS"
	},
	{
			"value": "HEXBUG VEX ROBOTICS",
			"label": "HEXBUG VEX ROBOTICS"
	},
	{
			"value": "ONE TOGETHER",
			"label": "ONE TOGETHER"
	},
	{
			"value": "PBS",
			"label": "PBS"
	},
	{
			"value": "BARROW",
			"label": "BARROW"
	},
	{
			"value": "ED",
			"label": "ED"
	},
	{
			"value": "WINTER WOODS",
			"label": "WINTER WOODS"
	},
	{
			"value": "ODIF",
			"label": "ODIF"
	},
	{
			"value": "CLOVER",
			"label": "CLOVER"
	},
	{
			"value": "WILD REPUBLIC",
			"label": "WILD REPUBLIC"
	},
	{
			"value": "UTAH ST",
			"label": "UTAH ST"
	},
	{
			"value": "ZEBRA",
			"label": "ZEBRA"
	},
	{
			"value": "WISTYRIA EDITIONS",
			"label": "WISTYRIA EDITIONS"
	},
	{
			"value": "HAPPY ST PATRICK'S DAY",
			"label": "HAPPY ST PATRICK'S DAY"
	},
	{
			"value": "KEEPSAKE CALICO",
			"label": "KEEPSAKE CALICO"
	},
	{
			"value": "BOHIN",
			"label": "BOHIN"
	},
	{
			"value": "FIRE/POLICE/EMS",
			"label": "FIRE/POLICE/EMS"
	},
	{
			"value": "DREAMWEAVER",
			"label": "DREAMWEAVER"
	},
	{
			"value": "APPLE PIE MEMORIES",
			"label": "APPLE PIE MEMORIES"
	},
	{
			"value": "CONCORD & 9TH",
			"label": "CONCORD & 9TH"
	},
	{
			"value": "FITKICKS",
			"label": "FITKICKS"
	},
	{
			"value": "WHERE THE WILD THINGS ARE",
			"label": "WHERE THE WILD THINGS ARE"
	},
	{
			"value": "REALEATHER CRAFTS",
			"label": "REALEATHER CRAFTS"
	},
	{
			"value": "ELVIS",
			"label": "ELVIS"
	},
	{
			"value": "SCOR-PAL",
			"label": "SCOR-PAL"
	},
	{
			"value": "TRIPAR INTERNATIONAL INC",
			"label": "TRIPAR INTERNATIONAL INC"
	},
	{
			"value": "WEBSTER'S PAGES",
			"label": "WEBSTER'S PAGES"
	},
	{
			"value": "24 HOUR CRAFTS",
			"label": "24 HOUR CRAFTS"
	},
	{
			"value": "CLE INDIANS",
			"label": "CLE INDIANS"
	},
	{
			"value": "SESAME STREET",
			"label": "SESAME STREET"
	},
	{
			"value": "KONA",
			"label": "KONA"
	},
	{
			"value": "AMAZING CLEAR CAST",
			"label": "AMAZING CLEAR CAST"
	},
	{
			"value": "SP RHODE ISLAND",
			"label": "SP RHODE ISLAND"
	},
	{
			"value": "BLUMENTHAL",
			"label": "BLUMENTHAL"
	},
	{
			"value": "LA-LA LAND CRAFTS",
			"label": "LA-LA LAND CRAFTS"
	},
	{
			"value": "QUILT HAPPY",
			"label": "QUILT HAPPY"
	},
	{
			"value": "METALLIC YARN",
			"label": "METALLIC YARN"
	},
	{
			"value": "RACHEL'S OF GREENFIELD",
			"label": "RACHEL'S OF GREENFIELD"
	},
	{
			"value": "THE EXORCIST",
			"label": "THE EXORCIST"
	},
	{
			"value": "FASHION PLATES",
			"label": "FASHION PLATES"
	},
	{
			"value": "KELLY'S CRAFTS",
			"label": "KELLY'S CRAFTS"
	},
	{
			"value": "LINDY'S STAMP GANG",
			"label": "LINDY'S STAMP GANG"
	},
	{
			"value": "MINECRAFT",
			"label": "MINECRAFT"
	},
	{
			"value": "JOY! CRAFTS",
			"label": "JOY! CRAFTS"
	},
	{
			"value": "CRYSTAL WATERS",
			"label": "CRYSTAL WATERS"
	},
	{
			"value": "SLIMLINE",
			"label": "SLIMLINE"
	},
	{
			"value": "PARK LANE",
			"label": "PARK LANE"
	},
	{
			"value": "SANDS ALIVE",
			"label": "SANDS ALIVE"
	},
	{
			"value": "THE DAYLIGHT COMPANY (ECOM)",
			"label": "THE DAYLIGHT COMPANY (ECOM)"
	},
	{
			"value": "ZACHARY",
			"label": "ZACHARY"
	},
	{
			"value": "DET TIGERS",
			"label": "DET TIGERS"
	},
	{
			"value": "BAYLOR",
			"label": "BAYLOR"
	},
	{
			"value": "STYLE 4 EVER",
			"label": "STYLE 4 EVER"
	},
	{
			"value": "PAINT WORKS",
			"label": "PAINT WORKS"
	},
	{
			"value": "VELCRO",
			"label": "VELCRO"
	},
	{
			"value": "COLLECTION D'ART",
			"label": "COLLECTION D'ART"
	},
	{
			"value": "FLORACRAFT",
			"label": "FLORACRAFT"
	},
	{
			"value": "",
			"label": ""
	},
	{
			"value": "ECSTASY CRAFTS",
			"label": "ECSTASY CRAFTS"
	},
	{
			"value": "GOLDIE BLOX",
			"label": "GOLDIE BLOX"
	},
	{
			"value": "ORIGINAL SIX",
			"label": "ORIGINAL SIX"
	},
	{
			"value": "METALLIC BRAID",
			"label": "METALLIC BRAID"
	},
	{
			"value": "HEMLINE",
			"label": "HEMLINE"
	},
	{
			"value": "MS. SPARKLE",
			"label": "MS. SPARKLE"
	},
	{
			"value": "GREAT CREATE",
			"label": "GREAT CREATE"
	},
	{
			"value": "ARMOUR",
			"label": "ARMOUR"
	},
	{
			"value": "BRUYNZEEL",
			"label": "BRUYNZEEL"
	},
	{
			"value": "COUTURE CREATIONS",
			"label": "COUTURE CREATIONS"
	},
	{
			"value": "DRITZ",
			"label": "DRITZ"
	},
	{
			"value": "ARTSKILLS",
			"label": "ARTSKILLS"
	},
	{
			"value": "PROVO CRAFT",
			"label": "PROVO CRAFT"
	},
	{
			"value": "ATL FALCONS",
			"label": "ATL FALCONS"
	},
	{
			"value": "THRD MAGIC",
			"label": "THRD MAGIC"
	},
	{
			"value": "FX",
			"label": "FX"
	},
	{
			"value": "BERNINA",
			"label": "BERNINA"
	},
	{
			"value": "COLLEGE",
			"label": "COLLEGE"
	},
	{
			"value": "FLORIDA A&M",
			"label": "FLORIDA A&M"
	},
	{
			"value": "SENNELIER",
			"label": "SENNELIER"
	},
	{
			"value": "STARLETTE",
			"label": "STARLETTE"
	},
	{
			"value": "ROXANNE",
			"label": "ROXANNE"
	},
	{
			"value": "NKOK",
			"label": "NKOK"
	},
	{
			"value": "LITTLEMAKERS",
			"label": "LITTLEMAKERS"
	},
	{
			"value": "AS SEEN ON TV",
			"label": "AS SEEN ON TV"
	},
	{
			"value": "HARIBO",
			"label": "HARIBO"
	},
	{
			"value": "THE LEATHER FACTORY",
			"label": "THE LEATHER FACTORY"
	},
	{
			"value": "THE SIMPSONS",
			"label": "THE SIMPSONS"
	},
	{
			"value": "KELLY RIPA",
			"label": "KELLY RIPA"
	},
	{
			"value": "FIND IT TRADING",
			"label": "FIND IT TRADING"
	},
	{
			"value": "BALGER BLEND",
			"label": "BALGER BLEND"
	},
	{
			"value": "QUALITY GROWERS",
			"label": "QUALITY GROWERS"
	},
	{
			"value": "WE R MEMORY KEEPERS",
			"label": "WE R MEMORY KEEPERS"
	},
	{
			"value": "JUST BORN",
			"label": "JUST BORN"
	},
	{
			"value": "LACIS",
			"label": "LACIS"
	},
	{
			"value": "ROBERT BATEMAN",
			"label": "ROBERT BATEMAN"
	},
	{
			"value": "BOSTON AMERICA",
			"label": "BOSTON AMERICA"
	},
	{
			"value": "HANGMAN",
			"label": "HANGMAN"
	},
	{
			"value": "UNI PIN",
			"label": "UNI PIN"
	},
	{
			"value": "TOSAFOS",
			"label": "TOSAFOS"
	},
	{
			"value": "CARPIE DIEM",
			"label": "CARPIE DIEM"
	},
	{
			"value": "SHARPIE",
			"label": "SHARPIE"
	},
	{
			"value": "BLOSSOM",
			"label": "BLOSSOM"
	},
	{
			"value": "ANAHEIM DUCKS",
			"label": "ANAHEIM DUCKS"
	},
	{
			"value": "POSCA",
			"label": "POSCA"
	},
	{
			"value": "SNAP SOURCE",
			"label": "SNAP SOURCE"
	},
	{
			"value": "JEEP",
			"label": "JEEP"
	},
	{
			"value": "ELASTI PLASTI",
			"label": "ELASTI PLASTI"
	},
	{
			"value": "WOOL NOVELTY COMPANY",
			"label": "WOOL NOVELTY COMPANY"
	},
	{
			"value": "BLUE MOON",
			"label": "BLUE MOON"
	},
	{
			"value": "3M",
			"label": "3M"
	},
	{
			"value": "DESIGN WORKS",
			"label": "DESIGN WORKS"
	},
	{
			"value": "TRACY PORTER",
			"label": "TRACY PORTER"
	},
	{
			"value": "TRIMCRAFT",
			"label": "TRIMCRAFT"
	},
	{
			"value": "LOVELY",
			"label": "LOVELY"
	},
	{
			"value": "MADE MODERN",
			"label": "MADE MODERN"
	},
	{
			"value": "DUCK",
			"label": "DUCK"
	},
	{
			"value": "CLEARSNAP",
			"label": "CLEARSNAP"
	},
	{
			"value": "ART-C",
			"label": "ART-C"
	},
	{
			"value": "JANETBASKET",
			"label": "JANETBASKET"
	},
	{
			"value": "WINSOR & NEWTON",
			"label": "WINSOR & NEWTON"
	},
	{
			"value": "PKL",
			"label": "PKL"
	},
	{
			"value": "STAR PRODUCTS",
			"label": "STAR PRODUCTS"
	},
	{
			"value": "ANGELA POOLE",
			"label": "ANGELA POOLE"
	},
	{
			"value": "PAPER ADVENTURES",
			"label": "PAPER ADVENTURES"
	},
	{
			"value": "ORGANICS ELEMENTS",
			"label": "ORGANICS ELEMENTS"
	},
	{
			"value": "PURELY COTTON",
			"label": "PURELY COTTON"
	},
	{
			"value": "UGLU",
			"label": "UGLU"
	},
	{
			"value": "THEA GOUVERNEUR",
			"label": "THEA GOUVERNEUR"
	},
	{
			"value": "COLUMBUS BLUEJACKETS",
			"label": "COLUMBUS BLUEJACKETS"
	},
	{
			"value": "LV GOLDEN KNIGHTS",
			"label": "LV GOLDEN KNIGHTS"
	},
	{
			"value": "REAL LEATHER",
			"label": "REAL LEATHER"
	},
	{
			"value": "TOMBOW",
			"label": "TOMBOW"
	},
	{
			"value": "BARBIE",
			"label": "BARBIE"
	},
	{
			"value": "TEXAS SAN ANTONIO",
			"label": "TEXAS SAN ANTONIO"
	},
	{
			"value": "CRATE PAPER",
			"label": "CRATE PAPER"
	},
	{
			"value": "ZOID",
			"label": "ZOID"
	},
	{
			"value": "NO PELICANS",
			"label": "NO PELICANS"
	},
	{
			"value": "IND PACERS",
			"label": "IND PACERS"
	},
	{
			"value": "SOFT FLEX",
			"label": "SOFT FLEX"
	},
	{
			"value": "FALL FOR ALL",
			"label": "FALL FOR ALL"
	},
	{
			"value": "TERVIS",
			"label": "TERVIS"
	},
	{
			"value": "SWEETSHOP",
			"label": "SWEETSHOP"
	},
	{
			"value": "LOUISVILLE",
			"label": "LOUISVILLE"
	},
	{
			"value": "SAVVI",
			"label": "SAVVI"
	},
	{
			"value": "KWIK STIX",
			"label": "KWIK STIX"
	},
	{
			"value": "MSE",
			"label": "MSE"
	},
	{
			"value": "SIMPLY ART",
			"label": "SIMPLY ART"
	},
	{
			"value": "CREATIVE TEACHING MATERIALS",
			"label": "CREATIVE TEACHING MATERIALS"
	},
	{
			"value": "SUNCOAST BOTANICALS",
			"label": "SUNCOAST BOTANICALS"
	},
	{
			"value": "CROREY CREATIONS",
			"label": "CROREY CREATIONS"
	},
	{
			"value": "BAZZILL",
			"label": "BAZZILL"
	},
	{
			"value": "DESIGNS",
			"label": "DESIGNS"
	},
	{
			"value": "PREMIER YARNS",
			"label": "PREMIER YARNS"
	},
	{
			"value": "ALEX TOYS",
			"label": "ALEX TOYS"
	},
	{
			"value": "US MILITARY",
			"label": "US MILITARY"
	},
	{
			"value": "COLORPLAY",
			"label": "COLORPLAY"
	},
	{
			"value": "ADDAMS FAMILY",
			"label": "ADDAMS FAMILY"
	},
	{
			"value": "MOXY",
			"label": "MOXY"
	},
	{
			"value": "MTV",
			"label": "MTV"
	},
	{
			"value": "TSUKINEKO",
			"label": "TSUKINEKO"
	},
	{
			"value": "L & S BRANDS LLC",
			"label": "L & S BRANDS LLC"
	},
	{
			"value": "YOUR NEXT DIE",
			"label": "YOUR NEXT DIE"
	},
	{
			"value": "DAS",
			"label": "DAS"
	},
	{
			"value": "YARN STUDIO",
			"label": "YARN STUDIO"
	},
	{
			"value": "GERBER",
			"label": "GERBER"
	},
	{
			"value": "NY JETS",
			"label": "NY JETS"
	},
	{
			"value": "DAYTON DRAGONS",
			"label": "DAYTON DRAGONS"
	},
	{
			"value": "SWEET TOOTH FAIRY",
			"label": "SWEET TOOTH FAIRY"
	},
	{
			"value": "BETTY CROCKER",
			"label": "BETTY CROCKER"
	},
	{
			"value": "DECOPATCH",
			"label": "DECOPATCH"
	},
	{
			"value": "SOUTH DAKOTA",
			"label": "SOUTH DAKOTA"
	},
	{
			"value": "INDIE COLLECTION",
			"label": "INDIE COLLECTION"
	},
	{
			"value": "THINGAMAJIG",
			"label": "THINGAMAJIG"
	},
	{
			"value": "OAK RAIDERS",
			"label": "OAK RAIDERS"
	},
	{
			"value": "PENGUIN PUTNAM INC.",
			"label": "PENGUIN PUTNAM INC."
	},
	{
			"value": "CREATIVITY STREET",
			"label": "CREATIVITY STREET"
	},
	{
			"value": "MEGA BULKLY 2PK",
			"label": "MEGA BULKLY 2PK"
	},
	{
			"value": "BEACON",
			"label": "BEACON"
	},
	{
			"value": "SUDBERRY HOUSE",
			"label": "SUDBERRY HOUSE"
	},
	{
			"value": "PIXAR",
			"label": "PIXAR"
	}
]


module.exports = {
	MERCH_CAT_OPTIONS: MERCH_CAT_OPTIONS,
	CATEGORY_OPTIONS: CATEGORY_OPTIONS,
	SHOP_DESCRIPTION_OPTIONS: SHOP_DESCRIPTION_OPTIONS,
	AREA_OPTIONS: AREA_OPTIONS,
	BUSINESS_OPTIONS: BUSINESS_OPTIONS,
	CUSTOMERGROUP_OPTIONS: CUSTOMERGROUP_OPTIONS,
	VENDOR_OPTIONS: VENDOR_OPTIONS,
	ATTRIBUTE_COLOR_OPTIONS: ATTRIBUTE_COLOR_OPTIONS,
	ATTRIBUTE_FABRIC_OPTIONS: ATTRIBUTE_FABRIC_OPTIONS,
	ATTRIBUTE_SIZE_OPTIONS: ATTRIBUTE_SIZE_OPTIONS,
	ATTRIBUTE_THEME_OPTIONS: ATTRIBUTE_THEME_OPTIONS,
	PRODUCT_BRAND_OPTIONS: PRODUCT_BRAND_OPTIONS
}