import React, { Component } from "react";
import { connect } from "react-redux";
import Modal from "react-awesome-modal";
import PageLoader from "../../../../utils/Loader";
import DataTable from "../../../tables";
import AlertSuccess from "../../../../utils/AlertMessage/alertSuccess";
import AlertDanger from "../../../../utils/AlertMessage/alertDanger";
import _ from "lodash";
import {
  editReferenceSku,
  infoReferenceSku,
  similarSkuProductsList
} from "../../../../redux/actions/referenceSkuActions";
import RefSkuChildMessageRendrer from "../ReferenceSku/RefSkuChildMessageRendrer";
import RefSkuActionRenderer from "../ReferenceSku/RefSkuActionRenferer";
import ColumnDef from "../../../tables/colDefs/SkuListColDefs";
import PropTypes from "prop-types";
import ReferenceSkuDetails from "../../../../utils/ReferenceSkuDetails";
import { AdgroupMasterGA } from '../../../../utils/GA/GAUtils';
import { alertTimer } from "../../../../utils/alertTimer";

var productdata = [];

class AdGroupSelectProducts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disableSave: false,
      rowSelection: "multiple",
      rowData: [],
      productData: [],
      savedRefSkuMsg: false,
      adgroupLoader: false,
      showDetailPopup: false,
      refSkuInfoResponse: "",
      skuListDownload: false,
      noRowsSelected: false,
      deleteConfirm: false,
      selectRefSkuError: false,
      refSkuOptions: [],
      refreshRows: false
    };
  }

  async componentDidMount() {
    this.productDataUpdateHandler();
    let refSkuSearchRes = await this.props.similarSkuProductsList({
      modelable_sku: true,
      search_key: ""
    });
    let list = _.map(refSkuSearchRes.data, item => {
      return {
        value: item.unique_id1,
        label: item.unique_id1
      };
    });
    list.unshift({ value: "none", label: "none" });
    this.setState({ refSkuOptions: list });
  }
  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      if (prevProps.productSelectedData !== this.props.productSelectedData) {
        this.productDataUpdateHandler();
      }
      if (this.props.couponSaved) {
        this.setState({ productData: [] });
      }
    }
  }

  saveRefSkuDetail = async rowData => {
    let params = null;
    if (Object.keys(rowData).length) {
      this.setState({
        adgroupLoader: true
      });
      params = {
        skuId: rowData.unique_id1,
        referenceSku: rowData.selected_sku === "none" ? null : rowData.selected_sku
      };
      let saveRefSkuResponse = await this.props.editReferenceSku(params);

      if (saveRefSkuResponse.status) {
        let { productData } = this.state;

        let found = _.find(productData.data, {
          unique_id1: rowData.unique_id1
        });
        found.reference_sku = rowData.selected_sku;

        this.setState({
          productData,
          adgroupLoader: false,
          savedRefSkuMsg: true
        });

        setTimeout(() => {
          this.setState({
            savedRefSkuMsg: false
          });
        }, alertTimer);
        this.props.skuModifiedFromReviewSkuHandler(rowData.unique_id1)
        //making api call to get refreshed data
      }
    } else {
      this.setState({
        selectRefSkuError: true
      });

      setTimeout(() => {
        this.setState({
          selectRefSkuError: false
        });
      }, alertTimer);
    }
  };

  refSkuSearchHandler = async (key, id) => {
    let { productData } = this.state;
    let refSkuSearchRes = await this.props.similarSkuProductsList({
      modelable_sku: true,
      search_key: key
    });
    let list = _.map(refSkuSearchRes.data, item => {
      return {
        value: item.unique_id1,
        label: item.unique_id1
      };
    });
    list.unshift({ value: "none", label: "none" });

    let found = _.find(productData.data, { unique_id1: id });
    found.refSkuOptions = list;

    this.setState({ refSkuOptions: list, refreshRows: true, productData });
  };
  refreshRowsHandler = () => {
    this.setState({ refreshRows: false });
  };
  productDataUpdateHandler = () => {
    let rowCount = _.cloneDeep(this.props.productSelectedData),
      dataArr = [];
    let productSelected = _.map(rowCount, item => {
      return item.unique_id1;
    });
    this.props.createAdGroupValue(productSelected);

    if (this.props.productSelectedData) {
      let clonedData = _.cloneDeep(this.props.productSelectedData);
      dataArr = clonedData.map(item => {
        item.saveRefSkuDetail = this.saveRefSkuDetail;
        item.refSkuSearchHandler = this.refSkuSearchHandler;
        item.refSkuOptions = this.state.refSkuOptions;
        item.original_reference_sku= item.reference_sku;
        return item;
      });
    }
    let array = ColumnDef.skuHeader,
    excludeColDef = _.slice(array, [0], [array.length - 1])
    let headerCalc = this.props.excludedSkuList
      ? excludeColDef
      : array;
    productdata = {
      header: headerCalc,
      defaultColDef: ColumnDef.deleteDefaultColDef,
      data: dataArr
    };

    this.setState({
      productData: productdata
    });

    if (this.props.clearProductList === true) {
      this.setState({
        productData: []
      });
    }
  };

  skuListDownloadHandler = () => {
    this.setState({ skuListDownload: !this.state.skuListDownload });
  };
  deleteRowHandler = () => {
    if (_.isEmpty(this.gridApi.getSelectedRows())) {
      this.setState({ noRowsSelected: true });
      setTimeout(() => {
        this.setState({ noRowsSelected: false });
      }, alertTimer);
    } else {
      this.setState({ deleteConfirm: true });
    }
  };
  deleteRowConfirmed = name => {
    if (name === "create") {
      this.props.productSelectedDataHandler(this.gridApi.getSelectedRows());
      return;
    }
    this.props.sendDeletedProducts(this.gridApi.getSelectedRows());
  };
  ExcludeIncludeRowHandler = name => {
    if (this.gridApi.getSelectedRows().length) {
      if (name === "exclude") {
        this.props.sendExcludedProducts(this.gridApi.getSelectedRows());
        return;
      }
      this.props.includeProductsHandler(this.gridApi.getSelectedRows());
    } else {
      this.setState({
        noRowsSelected: true
      });
      setTimeout(() => {
        this.setState({
          noRowsSelected: false
        });
      }, alertTimer);
    }
  };
  onSelectionChanged = (row) => {
    this.submitGA("Click", "Selected_SKU");
  }
  submitGA(action,label){
    AdgroupMasterGA({ action, label});
  }
  render() {
    var noRowMessage = "No products selected for this criteria...",
      refSkuDetailTableData = {
        header: ColumnDef.skuHeader,
        defaultColDef: ColumnDef.deleteDefaultColDef,
        data: this.state.refSkuInfoResponse
      };

    return (
      <div>
        <div className="alertDivs">
          {this.state.noRowsSelected ? (
            <AlertDanger
              message="Please select rows to exclude!"
              show={this.state.noRowsSelected}
            />
          ) : null}
          {this.state.selectRefSkuError ? (
            <div>
              <AlertDanger
                message="Please select Reference SKU to proceed"
                show={this.state.selectRefSkuError}
              />
            </div>
          ) : null}

          {this.state.savedRefSkuMsg ? (
            <div>
              <AlertSuccess
                message="Reference SKU details saved!"
                show={this.state.savedRefSkuMsg}
              />
            </div>
          ) : null}
        </div>
        <PageLoader loader={this.props.loader || this.state.adgroupLoader}>
          <div className="card" style={{ marginTop: "1%" }}>
            <div>
              {!this.props.excludedSkuList ? (
                <>
                  <button
                    type="button"
                    className="btn delete-sku-btn-styling pipeline-button-class mr-3"
                    title="Download"
                    onClick={() => {
                      this.skuListDownloadHandler();
                      this.submitGA("Click", "Clicked_DownloadButton");
                    }}
                  >
                    <i className="fa fa-download" aria-hidden="true" />
                  </button>
                  {this.props.createAdgroup ? (
                    <button
                      type="button"
                      className="btn delete-sku-btn-styling pipeline-button-class"
                      title="Delete"
                      onClick={() => this.deleteRowHandler()}
                    >
                      <i
                        className="fa fa-trash"
                        aria-hidden="true"
                        title="Delete"
                      />
                    </button>
                  ) : null}

                  {this.props.fromCoupon || this.props.createAdgroup ? null : (
                    <button
                      type="button"
                      className="btn delete-sku-btn-styling pipeline-button-class mr-3 icon-exclude"
                      title="Exclude"
                      onClick={() => {
                        this.ExcludeIncludeRowHandler("exclude");
                        this.submitGA("Click", "Clicked_ExcludeButton");
                      }}
                    >
                      <span class="icon"></span>
                    </button>
                  )}
                </>
              ) : null}
              {this.props.excludedSkuList ? (
                <button
                  type="button"
                  className="btn delete-sku-btn-styling pipeline-button-class mr-3 icon-include"
                  title="Include"
                  onClick={() => {
                    this.ExcludeIncludeRowHandler();
                    this.submitGA("Click", "Clicked_IncludeButton");
                  }}
                >
                  <span class="icon"></span>
                </button>
              ) : null}
            </div>
            <div className="card select-products-card skuTableStyling">
              <div className="ag-theme-balham">
                <DataTable
                  tableData={this.state.productData}
                  suppressRowClickSelection={true}
                  overlayNoRowsTemplate={noRowMessage}
                  rowSelection={this.state.rowSelection}
                  getGridAPI={api => {
                    this.gridApi = api;
                  }}
                  pagination={true}
                  // autoSizeCol={true}
                  skuListDownload={this.state.skuListDownload}
                  skuListDownloadHandler={this.skuListDownloadHandler}
                  downloadName="products_table"
                  refreshRows={this.state.refreshRows}
                  refreshRowsHandler={this.refreshRowsHandler}
                  frameworkComponents={{
                    refSkuChildMessageRenderer: RefSkuChildMessageRendrer,
                    refSkuActionRenderer: RefSkuActionRenderer
                  }}
                  selectOfferRow={this.onSelectionChanged}
                />
              </div>
            </div>
          </div>
        </PageLoader>

        <Modal
          visible={this.state.deleteConfirm}
          width="475"
          height="200"
          effect="fadeInDown"
          onClickAway={() => this.setState({ deleteConfirm: false })}
        >
          <h5 className="mx-5 mt-4 pt-3">
            <i
              className="fa fa-exclamation-triangle"
              style={{ color: "yellow" }}
              aria-hidden="true"
              title="Warning"
            />
            &nbsp;Warning
          </h5>
          <p className="mx-5">
            Are you sure you want to delete these products?
          </p>
          <p className="mx-5 mt-4 text-right">
            <button
              className="btn btn-primary mr-4"
              style={{ minWidth: "70px" }}
              onClick={() => this.setState({ deleteConfirm: false })}
            >
              No
            </button>
            <button
              className="btn btn-primary mr-4"
              style={{ minWidth: "70px" }}
              onClick={() => {
                this.setState({ deleteConfirm: false });
                if (this.props.createAdgroup) {
                  this.deleteRowConfirmed("create");
                  return;
                }
                this.deleteRowConfirmed();
              }}
            >
              Yes
            </button>
          </p>
        </Modal>

        {this.state.showDetailPopup ? (
          <Modal
            visible={true}
            //width="80%"
            //height="220px"
            width="1000px"
            height="auto"
            effect="fadeInDown"
            onClickAway={() => {
              this.setState({
                showDetailPopup: !this.state.showDetailPopup
              });
            }}
          >
            <PageLoader loader={!this.state.refSkuInfoResponse.length}>
              <div className="closeicon">
                <span style={{ cursor: "pointer" }}>
                  <i
                    className="fa fa-times"
                    aria-hidden="true"
                    onClick={() => this.setState({ showDetailPopup: false })}
                  />
                </span>
              </div>
              <h5 className="text-center font-weight-bold mt-1">
                Reference SKU details
              </h5>
              <ReferenceSkuDetails infoRefSkuData={refSkuDetailTableData} />
            </PageLoader>
          </Modal>
        ) : null}
      </div>
    );
  }
}

AdGroupSelectProducts.propTypes = {
  editReferenceSku: PropTypes.func.isRequired,
  similarSkuProductsList: PropTypes.func.isRequired,
  infoReferenceSku: PropTypes.func.isRequired
};
const mapStateToProps = state => ({});

export default connect(mapStateToProps, {
  editReferenceSku,
  infoReferenceSku,
  similarSkuProductsList
})(AdGroupSelectProducts);
