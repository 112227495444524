import React, { Component } from "react";
import "./promotionstyling.css";
import AlertDanger from "../../../utils/AlertMessage/alertDanger";
import SimulatePromotion from "./Promo Simulation/PromoSimulate";
import PromoOfferModal from "./ReviewOffer/PromoOfferModal";
import SkuList from "./SkuList/SkuList";
import { listCampaigns } from "../../../redux/actions/createPromoAction";
import {
  getCampaignsDetails,
  pipelineGetTyEvents,
} from "../../../redux/actions/calendarAction";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import {
  getSimulationData,
  getAdgroupOffer,
  offerBuilderApi,
} from "../../../redux/actions/SimulationAction";
import { usersLogReport } from "../../../redux/actions/userAction";
import BackToCalendar from "../../../utils/BackToCalendar";
import { Element, scroller } from "react-scroll";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import PageLoader from "../../../utils/Loader";
import ComparePromotion from "./PromoCompare/ComparePromotionNew";
import SimulateTopScreen from "./Promo Simulation/PromoSimulateTopScreen";
import _ from "lodash";
import Select from "react-select";
import queryString from "query-string";
import { getAdgroupDetailsFromId } from "../../../redux/actions/adgroup";
import { PromotionCalendarGA } from '../../../utils/GA/GAUtils';
import {alertTimer} from '../../../utils/alertTimer';

var moment = require("moment");

class Subcontent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      simulateTabKey: "home", // to control tab(simulation n compare)
      showOfferModal: false, // review offer popup hide-show controller
      copyCampaignData: [],
      offerDetails: [],
      simulationData: [],
      defaultSelectedradio: "P",
      adgroupOptions: [], // adgroup dropdown options
      selectedAdgroup: "", //currently active adgroup value
      adgroupWithEventType: [], //event type options are stored respective of their adgroup name
      activeEventType: "", // currently active eventtype value
      intermediateActiveEvent: "",
      showSkuList: false, //skulist popup hide-show controller
      showLoader: true,
      somethingWentWrong: false, // api call gave status false handler
      simulateLoader: false,
      simulateTableData: [
        { key: 0, metric: "Recommended By Sales" },
        { key: 1, metric: "Recommended By Margin" },
        { key: 2, metric: "Recommended By GM%" },
        { key: 3, metric: "TY Offer" },
      ], // simulate table data storage
      refreshTable: false, //simuate ag-grid table refresh
      campaignOptions: [], //promotion name dropdown options
      selectedCampaign: [], // currently active promotion
      fromPipelineIntermediate: false,
      noCoefficientAlert: false,
      offerBuilderError: false,
      fixedBundleText: 2,
      addRowLoader: false,
      offerBuildeRes: [],
      cumulativeOfferBuilderRes: [],
      enableReviewOffer: false,
      promoIdArray: [],
      selectedAdgroupId: "",
      simulateBtnClicked: false,
    };
  }

  async componentDidMount() {
    let promoIdFromEdit = localStorage.getItem("promoIdsForUrl").split`,`.map(
        (x) => +x
      ),
      adgroupNamesFromPipeline = JSON.parse(
        localStorage.getItem("adgroupNamesForUrl")
      ),
      adgroupIdFromPipeline = localStorage.getItem("adgroupIdsForUrl"),
      campId = localStorage.getItem("campaignIdsForUrl"),
      queries = queryString.parse(this.props.location.search);

    let tyEventsRes = [];

    if (queries.pipelineInt) {
      this.setState({
        fromPipelineIntermediate: true,
      });

      let adgroupOptionsPipeline = [];

      _.map(adgroupNamesFromPipeline, (item) => {
        adgroupOptionsPipeline.push({
          label: item.adgroup_name,
          value: item.adgroup_name,
          adgroup_id: item.adgroup_id,
        });
      });

      this.setState({
        selectedAdgroupId: adgroupOptionsPipeline[0].adgroup_id,
      });

      // this.props.usersLogReport({ screen: "simulation", action: "visit", userId: localStorage.getItem("email") });

      let res = await this.props.getAdgroupDetailsFromId(
        adgroupOptionsPipeline[0].adgroup_id
      );
      tyEventsRes = await this.props.pipelineGetTyEvents({
        campaign_id: parseInt(localStorage.getItem("campaignId")),
      });

      if (res) {
        this.setState({
          adgroupDetailsFromPipeline: {
            adgroupName: res.data[0].name,
            adgroupId: adgroupIdFromPipeline[0],
            productList: res.data[0].product_list,
          },
        });
      }
    } else {
      this.setState({
        fromPipelineIntermediate: false,
      });
    }
    let campaign_start_date = localStorage.getItem("campStartDate")
    let campaign_end_date = localStorage.getItem("campEndDate")
    let campaignId = {
      campaignId: campId,
      selectedAdgroupId:
        localStorage.getItem("adgroupIdsForUrl") != null
          ? localStorage.getItem("adgroupIdsForUrl").split(",")
          : null,
      selectedPromoId:
        localStorage.getItem("promoIdsForUrl") !== "null"
          ? localStorage.getItem("promoIdsForUrl").split(",")
          : null,
          campaign_start_date,campaign_end_date
    };

    let campaignDetailsRes = await this.props.getCampaignsDetails(campaignId); //copy campaign API

    if (!campaignDetailsRes.length) {
      this.setState({ somethingWentWrong: true });
      setTimeout(() => {
        this.setState({ somethingWentWrong: true });
      }, alertTimer);
      return;
    }
    let campaignOptions = [
      {
        value: campaignDetailsRes[0].campaignname,
        label: campaignDetailsRes[0].campaignname,
      },
    ];

    let offerDetails = await this.props.getAdgroupOffer({
      //details API
      promoIds:
        queries.pipelineInt && campaignDetailsRes[0].promoid
          ? [campaignDetailsRes[0].promoid]
          : queries.pipelineInt && !campaignDetailsRes[0].promoid
          ? null
          : promoIdFromEdit,
      startDate: moment(
        campaignDetailsRes[0].campaign_start_date,
        "MM-DD-YYYY"
      ).format("YYYY-MM-DD"),
      endDate: moment(
        campaignDetailsRes[0].campaign_end_date,
        "MM-DD-YYYY"
      ).format("YYYY-MM-DD"),
    });

    this.setState({
      promoIdFromEdit,
      offerDetails,
      savedPromoId: campaignDetailsRes[0].promoid,
      copyCampaignData: campaignDetailsRes,
      campaignOptions,
      selectedCampaign: campaignOptions[0],
      campaignDetailsRes,
    });
    if (queries.pipelineInt) {
      this.intermidiateAdgroupUpdateHandler(campaignDetailsRes, tyEventsRes);
      return;
    }
    this.adgroupUpdateHandler(offerDetails); // no need of details api
  }
  promoTypeFormatter = (value) => {
    let promoTypeSet = JSON.parse(localStorage.getItem("promo_type"));
    if (value) {
      return promoTypeSet ? promoTypeSet[value] : value;
    }
  };
  intermidiateAdgroupUpdateHandler = (copyCampData, tyEventsRes) => {
    let adgroupOptions = [],
      adgroupWithEventType = {};

    _.map(copyCampData, (adgroup) => {
      adgroupOptions.push({
        label: adgroup.adgroupname,
        value: adgroup.adgroupname,
        promo_id: adgroup.promoid,
        adgroup_id: adgroup.adgroup_id,
      });
      let tyEventsData = [];
      if (tyEventsRes.data.status) {
        tyEventsRes.data.data.map((tyEvents) => {
          let eventLabel = this.promoTypeFormatter(tyEvents.event_type);
          let labelValue =
          tyEvents.type + 
          "-" +
          tyEvents.event_type +
            "-" +
            moment(tyEvents.start_date).format("MM/DD/YYYY") +
            "-" +
            moment(tyEvents.end_date).format("MM/DD/YYYY");

          let initialOfferCalc = this.deeperOfferValueCalc(
            adgroup.offer_type,
            adgroup.offer_value,
            adgroup.min_quantity,
            adgroup.bonus_by_quantity,
            adgroup.fixed_price_quantity
          );

          let objVar = {
            value: labelValue,
            label: labelValue,
            startDate: tyEvents.start_date,
            endDate: tyEvents.end_date,
            promoId: adgroup.promoid,
            offerType: adgroup.offer_type ? adgroup.offer_type : "P",
            offerValue: adgroup.offer_value,
            promoType: tyEvents.event_type,
            productList: adgroup.product_list,
            name: adgroup.adgroupname,
            event_id: tyEvents.event_id,
            initialOffer: initialOfferCalc,
          };

          tyEventsData.push(objVar);
        });
      }
      adgroupWithEventType[adgroup.adgroupname] = tyEventsData;
      // eventtype(promo-type) values are stored according to their adgroup name n promo id
      // adgroupWithEventType[adgroup.adgroupname] = adgroupWithEventType[adgroup.adgroupname]
      //   ? [...adgroupWithEventType[adgroup.adgroupname], ...tyEventsData]
      //   : tyEventsData;
      return adgroup;
    });
    /*********filtering to get unique list of adgroup names start*********/
    let a = adgroupOptions,
      b = adgroupOptions;
    let labelArray = [];
    let filteredList = b.filter((item) => {
      for (var i = 0; i < a.length; i++) {
        if (a[i].value === item.value && !labelArray.includes(item.value)) {
          //filtering duplicates
          labelArray.push(item.value);
          return item;
        }
      }
    });

    adgroupOptions = filteredList;

    /*********filtering to get unique list of adgroup names end*********/
    let event = !_.isEmpty(adgroupWithEventType)
      ? adgroupWithEventType[adgroupOptions[0].value][0]
      : [];

    this.setState({
      adgroupOptions,
      selectedAdgroup: adgroupOptions[0],
      adgroupWithEventType,
      activeEventType: event,
      showLoader: false,
      defaultSelectedradio: event ? event.offerType : "P",
    });
  };

  adgroupUpdateHandler = (offerDetails) => {
    let adgroupOptions = [],
      adgroupWithEventType = {};

    _.map(offerDetails, (adgroup) => {
      adgroupOptions.push({
        label: adgroup.name,
        value: adgroup.name,
        promo_id: adgroup.promo_id,
        adgroup_id: adgroup.adgroup_id,
      });
      let eventLabel = this.promoTypeFormatter(adgroup.promo_type);
      let eventType =
        adgroup.type + 
        "-" +
        adgroup.promo_type +
        "-" +
        moment(adgroup.start_date).format("MM/DD/YYYY") +
        "-" +
        moment(adgroup.end_date).format("MM/DD/YYYY");

      let initialOfferCalc = this.deeperOfferValueCalc(
        adgroup.offer_type,
        adgroup.offer_value,
        adgroup.min_quantity,
        adgroup.bonus_by_quantity,
        adgroup.fixed_price_quantity
      );

      let objVar = {
        value: adgroup.promo_id,
        label: eventType, //adgroup.promo_type
        promoId: adgroup.promo_id,
        offerType: adgroup.offer_type,
        offerValue: adgroup.offer_value,
        promoType: adgroup.promo_type,
        productList: adgroup.product_list,
        startDate: adgroup.start_date,
        endDate: adgroup.end_date,
        name: adgroup.name,
        initialOffer: initialOfferCalc,
        fixed_price_quantity: adgroup.fixed_price_quantity,
        min_quantity: adgroup.min_quantity,
        bonus_by_quantity: adgroup.bonus_by_quantity,
        eventType,
        event_id: adgroup.event_id
      };
      // eventtype(promo-type) values are stored according to their adgroup name n promo id
      adgroupWithEventType[adgroup.name] = adgroupWithEventType[adgroup.name]
        ? [...adgroupWithEventType[adgroup.name], objVar]
        : [objVar];
      return adgroup;
    });
    /*********filtering to get unique list of adgroup names start*********/
    let a = adgroupOptions,
      b = adgroupOptions;
    let labelArray = [];
    let filteredList = b.filter((item) => {
      for (var i = 0; i < a.length; i++) {
        if (a[i].value === item.value && !labelArray.includes(item.value)) {
          //filtering duplicates
          labelArray.push(item.value);
          return item;
        }
      }
    });

    adgroupOptions = filteredList;

    /*********filtering to get unique list of adgroup names end*********/
    let event = !_.isEmpty(adgroupWithEventType)
      ? adgroupWithEventType[adgroupOptions[0].value][0]
      : [];
    this.setState({
      adgroupOptions,
      selectedAdgroup: adgroupOptions[0],
      adgroupWithEventType,
      activeEventType: event,
      showLoader: false,
      defaultSelectedradio:
        event && event.offerType === "F" && event.fixed_price_quantity > 1
          ? "nForM"
          : event
          ? event.offerType
          : "P",
    });

    let promoId =
      adgroupWithEventType[this.state.selectedAdgroup.value][0].promoId;

    let campaignRow = _.find(this.state.campaignDetailsRes, {
      promoid: promoId,
    });

    if (campaignRow.fixed_price_quantity > 1) {
      this.setState({
        fixedBundleText: campaignRow.fixed_price_quantity,
      });
    }
  };

  offerDetailsApiCall = async (sDate, eDate) => {
    // offer details api call
    let response = await this.props.getAdgroupOffer({
      promoIds: this.state.activeEventType.promoId
        ? this.state.activeEventType.promoId
        : null,
      startDate: moment(sDate).format("YYYY-MM-DD"),
      endDate: moment(eDate).format("YYYY-MM-DD"),
    });

    this.setState({ offerDetails: response });
  };

  offerValueCalc = (type, value, initialOffer, num) => {
    if ((type === "P" || type === "F" || type === "A") && value === null) {
      return "-";
    } else if (type === "P") {
      return value + " % OFF";
    } else if (type === "F" || type === "nForM") {
      return "$ " + value;
    } else if (type === "A") {
      return "$ " + value + " OFF";
    } else if (
      (type === "B" || type === "G" || type === "C" || type === "D") &&
      num === 0
    ) {
      return initialOffer;
    } else if (
      (type === "B" || type === "G" || type === "C" || type === "D") &&
      num !== 0
    ) {
      return value;
    } else {
      return "-";
    }
  };

  showRadioClickedValue = (e) => {
    let val = e.target.value;
    this.setState({
      defaultSelectedradio: val,
    });
    this.submitGA("Click", "Selected_Simulation_OfferType");
  };

  showSkuListHandler = (val) => {
    this.setState({
      showSkuList: val,
    });
    this.submitGA("Click", "Clicked_Simulation_ViewSKUButton");
  };

  addComparedValues = async (
    type,
    val,
    fixedPriceQty,
    minQty,
    bonusBuy,
    buyPercent,
    getPercent
  ) => {
    this.setState({ addRowLoader: true });
    let {
        simulateTableData,
        simulationData,
        offerDetails,
        activeEventType,
        fromPipelineIntermediate,
        cumulativeOfferBuilderRes,
        adgroupWithEventType,
      } = this.state,
      keyVar = simulateTableData[simulateTableData.length - 1].key + 1;

    let newRow = {
      key: keyVar,
      metric: `Scenario ${simulateTableData.length - 3}`,
    };

    let offerBuildeRes = "";
    simulateTableData.push(newRow); // add a new row to the simulate table

    let findActiveAdgroup =
      !fromPipelineIntermediate &&
      _.find(adgroupWithEventType[activeEventType.name], {
        value: activeEventType.value,
      });
    offerBuildeRes = await this.props.offerBuilderApi({
      adgroupName: fromPipelineIntermediate
        ? this.state.activeEventType.name
        : findActiveAdgroup.name,
      selectedAdgroupSkus: fromPipelineIntermediate
        ? this.state.activeEventType.productList
        : findActiveAdgroup.productList,
      startDate: moment(
        fromPipelineIntermediate
          ? this.state.activeEventType.startDate
          : findActiveAdgroup.startDate
      ).format("YYYY-MM-DD"),
      endDate: moment(
        fromPipelineIntermediate
          ? this.state.activeEventType.endDate
          : findActiveAdgroup.endDate
      ).format("YYYY-MM-DD"),
      fiscalYear: "2019-01-01",
      offerType: type.value,
      offerValue: val.length ? parseFloat(val) : 0,
      promoType: fromPipelineIntermediate
        ? this.state.activeEventType.promoType
        : findActiveAdgroup.promoType,
      promo_id: this.state.activeEventType.promoId
        ? this.state.activeEventType.promoId
        : null,
      offerquantity: 0,
      campaignId: parseInt(localStorage.getItem("campaignIdsForUrl")),
      bonusbyquantity: parseInt(bonusBuy),
      minquantity: parseInt(minQty),
      fixedpricequantity: parseInt(fixedPriceQty),
    });

    if (offerBuildeRes.status) {
      this.setState({ addRowLoader: false });
      let adgroupSelected = fromPipelineIntermediate
        ? activeEventType
        : _.find(offerDetails, {
            promo_id: activeEventType.promoId,
          });

      cumulativeOfferBuilderRes.push(offerBuildeRes.data);

      this.simuateTableRowData(
        adgroupSelected,
        newRow,
        simulationData.data,
        "",
        0,
        type.value,
        val,
        fixedPriceQty,
        minQty,
        bonusBuy,
        buyPercent,
        getPercent,
        offerBuildeRes.data,
        "Add"
      );

      scroller.scrollTo("scrollHandler", {
        duration: 900,
        delay: 200,
        smooth: "easeInOutQuart",
      });
      this.setState({
        simulateTableData,
        offerBuildeRes: offerBuildeRes.data,
        cumulativeOfferBuilderRes,
      });
    } else {
      this.setState({
        offerBuilderError: true,
        addRowLoader: false,
      });

      setTimeout(() => {
        this.setState({
          offerBuilderError: false,
        });
      }, alertTimer);
    }
  };

  adgroupChangeHandler = async (val) => {
    this.submitGA("Click", "Selected_Simulation_AdgroupDropdown");
    let activeEventOption = this.state.adgroupWithEventType[val.value];

    if (this.state.fromPipelineIntermediate) {
      let selectedRadio =
        activeEventOption[0].offerType &&
        activeEventOption[0].fixed_price_quantity &&
        activeEventOption[0].offerType === "F" &&
        activeEventOption[0].fixed_price_quantity > 1
          ? "nForM"
          : activeEventOption[0].offerType;

      this.setState({
        selectedAdgroup: val,
        activeEventType: activeEventOption[0],
        defaultSelectedradio: activeEventOption[0].offerType
          ? selectedRadio //activeEventOption[0].offerType
          : "P",
        selectedAdgroupId: val.adgroup_id,
      });
      let res = await this.props.getAdgroupDetailsFromId(val.adgroup_id);
      let adgroupDetailsFromPipeline = [];
      if (res) {
        adgroupDetailsFromPipeline = {
          adgroupName: res.data[0].name,
          adgroupId: val.adgroup_id,
          productList: res.data[0].product_list,
        };
        this.setState({
          adgroupDetailsFromPipeline,
        });
      }
      if (!this.state.showPromoSimulate) {
        //in case simulate element is not active yet
        return;
      }
      this.simulateHandler(activeEventOption[0], adgroupDetailsFromPipeline); //in case simulate element active make simulation api call
      return;
    }
    //from pipeline code

    let promoId = activeEventOption[0].promoId;

    let campaignRow = _.find(this.state.campaignDetailsRes, {
      promoid: promoId,
    });

    if (campaignRow.fixed_price_quantity > 1) {
      this.setState({
        fixedBundleText: campaignRow.fixed_price_quantity,
      });
    }
    let selectedRadio =
      activeEventOption[0].offerType === "F" &&
      activeEventOption[0].fixed_price_quantity > 1
        ? "nForM"
        : activeEventOption[0].offerType;
    this.setState({
      selectedAdgroup: val,
      activeEventType: activeEventOption[0],
      defaultSelectedradio: selectedRadio, //activeEventOption[0].offerType
    });

    if (!this.state.showPromoSimulate) {
      //in case simulate element is not active yet
      return;
    }
    this.simulateHandler(activeEventOption[0]); //in case simulate element active make simulation api call
  };

  eventTypeChangeHandler = (val) => {
    this.submitGA("Click", "Selected_Simulation_EventTypeDropdown");
    if (this.state.fromPipelineIntermediate) {
      this.setState({
        activeEventType: val,
        defaultSelectedradio: val.offerType,
      });
      if (!this.state.showPromoSimulate) {
        //in case simulate element is not active yet
        return;
      }
      this.simulateHandler(val, this.state.adgroupDetailsFromPipeline, val); //in case simulate element active make simulation api call
      return;
    }
    //from pipeline code

    let promoId = val.promoId;

    let campaignRow = _.find(this.state.campaignDetailsRes, {
      promoid: promoId,
    });

    if (campaignRow.fixed_price_quantity > 1) {
      this.setState({
        fixedBundleText: campaignRow.fixed_price_quantity,
      });
    }

    this.setState({
      activeEventType: val,
      defaultSelectedradio: val.offerType,
    });
    if (!this.state.showPromoSimulate) {
      //in case simulate element is not active yet
      return;
    }
    this.simulateHandler(val); //in case simulate element active make simulation api call
  };
  simulationActionhandler = async (adgroup, event, noNeedForSimulation) => {
    // simulation api call and set state
    let response = [],
      list = {},
      campaignStartDate = localStorage.getItem("campStartDate"),
      campaignEndDate = localStorage.getItem("campEndDate");

    list[adgroup.name] = [adgroup.product_list];
    if (noNeedForSimulation === "noNeedForSimulation") {
      let simulateTableData = await this.simulateTableDataCalc(
        this.state.simulationData.data,
        adgroup
      );
      this.setState({
        simulateLoader: false,
        simulateTableData,
      });
      return;
    }
    if (event) {
      response = await this.props.getSimulationData({
        selectedAdgroupSkus: list,
        startDate: moment(adgroup.start_date, "MM-DD-YYYY").format(
          "YYYY-MM-DD"
        ),
        endDate: moment(adgroup.end_date, "MM-DD-YYYY").format("YYYY-MM-DD"),
        fiscalYear: "2019-01-01",
        currentYearOfferType: adgroup.offer_type,
        currentYearOfferValue:
          adgroup.offer_type === "P" ||
          adgroup.offer_type === "F" ||
          adgroup.offer_type === "A" ||
          adgroup.offer_type === "nForM"
            ? adgroup.offer_value
            : adgroup.title,
        promoType: adgroup.promo_type.toLowerCase(),
        promoId: adgroup.promo_id ? adgroup.promo_id : null,
        fixedpricequantity: parseInt(this.state.fixedBundleText),

        campaignId: adgroup.campaign_id
          ? adgroup.campaign_id
          : parseInt(localStorage.getItem("campaignId")),
        campaignStartDate: moment(campaignStartDate).format("YYYY-MM-DD"),
        campaignEndDate: moment(campaignEndDate).format("YYYY-MM-DD"), 
        adgroupId: adgroup.adgroup_id,
        eventId: adgroup.event_id
      });
    } else {
      if (
        (adgroup.offer_type === "P" ||
          adgroup.offer_type === "F" ||
          adgroup.offer_type === "A" ||
          adgroup.offer_type === "nForM") &&
        adgroup.offer_value === 0
      ) {
        response = await this.props.getSimulationData({
          selectedAdgroupSkus: list,
          startDate: moment(adgroup.start_date, "MM-DD-YYYY").format(
            "YYYY-MM-DD"
          ),
          endDate: moment(adgroup.end_date, "MM-DD-YYYY").format("YYYY-MM-DD"),
          fiscalYear: "2019-01-01",
          promoType: adgroup.promo_type.toLowerCase(),
          promoId: adgroup.promo_id ? adgroup.promo_id : null,

          campaignId: adgroup.campaign_id
            ? adgroup.campaign_id
            : parseInt(localStorage.getItem("campaignId")),
          campaignStartDate: moment(campaignStartDate).format("YYYY-MM-DD"),
          campaignEndDate: moment(campaignEndDate).format("YYYY-MM-DD"),
          eventId: adgroup.event_id,
          adgroupId: adgroup.adgroup_id
        });
      } else {
        response = await this.props.getSimulationData({
          selectedAdgroupSkus: list,
          startDate: moment(adgroup.start_date, "MM-DD-YYYY").format(
            "YYYY-MM-DD"
          ),
          endDate: moment(adgroup.end_date, "MM-DD-YYYY").format("YYYY-MM-DD"),
          fiscalYear: "2019-01-01",
          currentYearOfferType: adgroup.offer_type,
          currentYearOfferValue:
            adgroup.offer_type === "P" ||
            adgroup.offer_type === "F" ||
            adgroup.offer_type === "A" ||
            adgroup.offer_type === "nForM"
              ? adgroup.offer_value
              : adgroup.title,
          promoType: adgroup.promo_type.toLowerCase(),
          promoId: adgroup.promo_id ? adgroup.promo_id : null,

          campaignId: adgroup.campaign_id
            ? adgroup.campaign_id
            : parseInt(localStorage.getItem("campaignId")),
          campaignStartDate: moment(campaignStartDate).format("YYYY-MM-DD"),
          campaignEndDate: moment(campaignEndDate).format("YYYY-MM-DD"),
          eventId: adgroup.event_id,
          adgroupId: adgroup.adgroup_id
        });
      }
    }

    if (!response.status) {
      this.setState({ somethingWentWrong: true });
      setTimeout(() => {
        this.setState({ somethingWentWrong: true });
      }, alertTimer);
      return;
    }
    let simulateTableData = await this.simulateTableDataCalc(
      response.data,
      adgroup
    );
    let findOutput = _.find(response.data, {
      adgroupName: this.state.activeEventType.name,
      offerType: this.state.activeEventType.offerType,
    });

    this.setState({
      simulationData: response,
      simulateLoader: false,
      simulateTableData,
      noCoefficientAlert:
        findOutput && findOutput.sufficientCoefficients
          ? !findOutput.sufficientCoefficients
          : null,
    });

    if (findOutput && !findOutput.sufficientCoefficients)
      setTimeout(() => {
        this.setState({ noCoefficientAlert: false });
      }, alertTimer);
  };

  bogoOffersCalculation = (
    offerType,
    offerValue,
    minQty,
    bonusBuy,
    fixedpricequantity,
    buyPercent,
    getPercent
  ) => {
    if (offerType === "B") {
      return `BUY ${minQty} GET ${bonusBuy} @ ${offerValue}% OFF`;
    }
    if (offerType === "G") {
      return `BUY ${minQty} GET ${bonusBuy}`;
    }
    if (offerType === "D") {
      return `BUY ${minQty} GET ${offerValue}% OFF EACH`;
    }
    if (offerType === "C") {
      return `BUY ${minQty} GET ${bonusBuy} @ $ ${offerValue} OFF`;
    }
    if (offerType === "F" && fixedpricequantity > 1) {
      return `${fixedpricequantity} for $ ${offerValue}`;
    }
  };
  simulateTableDataCalc = (data, adgroup) => {
    //simulate table data rearraging according to the ag-grid table requirement
    let {
      simulateTableData,
      activeEventType,
      intermediateActiveEvent,
      fromPipelineIntermediate,
    } = this.state;

    let activeType = activeEventType;

    let clonedArray = _.cloneDeep(simulateTableData),
      newTableData =
        clonedArray.length > 3 ? _.slice(clonedArray, [0], [4]) : clonedArray,
      bySales = _.find(newTableData, { key: 0 }),
      byMargin = _.find(newTableData, { key: 1 }),
      byGM = _.find(newTableData, { key: 2 }),
      byTY = _.find(newTableData, { key: 3 });

    //initial three rows data calculations
    this.simuateTableRowData(adgroup, bySales, data, "bestByRevenue", 3);
    this.simuateTableRowData(adgroup, byMargin, data, "bestByMargin", 2);
    this.simuateTableRowData(adgroup, byGM, data, "bestByGmPer", 1);
    this.simuateTableRowDataForTY(
      adgroup,
      byTY,
      activeType.offerType,
      activeType.offerValue,
      activeType.fixed_price_quantity,
      activeType.min_quantity,
      activeType.bonus_by_quantity
    );

    return newTableData;
  };

  simuateTableRowData = (
    adgroup,
    row,
    data,
    name,
    num,
    type,
    val,
    fixedpricequantity,
    minquantity,
    bonusbyquantity,
    buyPercent,
    getPercent,
    offerBuildeRes,
    from
  ) => {
    let bestType =
      num === 0 ? type : data[data.length - num][name].forecastedOfferType; //for first two rows forecastedOfferType
    let bestVal =
      num === 0 ? val : data[data.length - num][name].forecastedOfferValue; //for first two rows forecastedOfferValue

    let activeType = this.state.activeEventType;

    let findOutput = _.find(data, {
      adgroupName: activeType.name,
      offerType: bestType,
    });
    row.offer =
      bestVal !== "-"
        ? type === "P" ||
          (type === "F" && fixedpricequantity <= 1) ||
          type === "A"
          ? this.offerValueCalc(bestType, bestVal, null, num)
          : from === "Add"
          ? this.bogoOffersCalculation(
              type,
              bestVal,
              minquantity,
              bonusbyquantity,
              fixedpricequantity,
              buyPercent,
              getPercent
            )
          : this.offerValueCalc(
              bestType,
              bestVal,
              this.state.activeEventType.initialOffer,
              num
            )
        : "-"; //offer column value calculation

    row.offerType = bestType; //for future reference
    row.offerValue = bestVal;
    row.margin_forecast_override = adgroup.margin_forecast_override
      ? `${adgroup.margin_forecast_override}`
      : "";
    row.revenue_forecast_override = adgroup.revenue_forecast_override
      ? `${adgroup.revenue_forecast_override}`
      : "";

    row.total_units = name.length
      ? bestVal === null
        ? null
        : bestVal !== 0 && findOutput && findOutput.output[bestVal]
        ? Math.round(findOutput.output[bestVal].totalquantity)
        : 0
      : type === "P" || type === "F" || type === "A"
      ? offerBuildeRes.output[val].totalquantity
      : offerBuildeRes.output[row.offer].totalquantity;
    row.inc_units = name.length
      ? bestVal === null
        ? null
        : bestVal !== 0 && findOutput && findOutput.output[bestVal]
        ? Math.round(findOutput.output[bestVal].totalIncrementalQuantity)
        : 0
      : type === "P" || type === "F" || type === "A"
      ? offerBuildeRes.output[val].totalIncrementalQuantity
      : offerBuildeRes.output[row.offer].totalIncrementalQuantity;
    row.override_units = name.length
      ? name !== "TY"
        ? ""
        : adgroup.quantity_forecast_override
        ? adgroup.quantity_forecast_override
        : ""
      : ""; //To change

    row.total_sales = name.length
      ? bestVal === null
        ? null
        : bestVal !== 0 && findOutput && findOutput.output[bestVal]
        ? Math.round(findOutput.output[bestVal].totalRevenue)
        : 0
      : type === "P" || type === "F" || type === "A"
      ? offerBuildeRes.output[val].totalRevenue
      : offerBuildeRes.output[row.offer].totalRevenue;
    row.inc_sales = name.length
      ? bestVal === null
        ? null
        : bestVal !== 0 && findOutput && findOutput.output[bestVal]
        ? Math.round(findOutput.output[bestVal].totalIncrementalRevenue)
        : 0
      : type === "P" || type === "F" || type === "A"
      ? offerBuildeRes.output[val].totalIncrementalRevenue
      : offerBuildeRes.output[row.offer].totalIncrementalRevenue;
    row.override_sales = name.length
      ? name !== "TY"
        ? ""
        : adgroup.revenue_forecast_override
        ? adgroup.revenue_forecast_override
        : ""
      : ""; //To change;

    row.total_margin = name.length
      ? bestVal === null
        ? null
        : bestVal !== 0 && findOutput && findOutput.output[bestVal]
        ? Math.round(findOutput.output[bestVal].totalMargin)
        : 0
      : type === "P" || type === "F" || type === "A"
      ? offerBuildeRes.output[val].totalMargin
      : offerBuildeRes.output[row.offer].totalMargin;
    row.inc_margin = name.length
      ? bestVal === null
        ? null
        : bestVal !== 0 && findOutput && findOutput.output[bestVal]
        ? Math.round(findOutput.output[bestVal].totalIncrementalMargin)
        : 0
      : type === "P" || type === "F" || type === "A"
      ? offerBuildeRes.output[val].totalIncrementalMargin
      : offerBuildeRes.output[row.offer].totalIncrementalMargin;
    row.override_margin = name.length
      ? name !== "TY"
        ? ""
        : adgroup.margin_forecast_override
        ? adgroup.margin_forecast_override
        : ""
      : "";

    row.gm_percentage = name.length
      ? bestVal === null
        ? null
        : bestVal !== 0 && findOutput && findOutput.output[bestVal]
        ? Math.round(findOutput.output[bestVal].GMP * 100) / 100
        : 0
      : type === "P" || type === "F" || type === "A"
      ? offerBuildeRes.output[val].GMP
      : offerBuildeRes.output[row.offer].GMP;

    row.minquantity = minquantity > 0 ? minquantity : 0;
    row.bonusbyquantity = bonusbyquantity > 0 ? bonusbyquantity : 0;
    row.fixedpricequantity = fixedpricequantity > 0 ? fixedpricequantity : 0;
    row.confidence = name.length?adgroup.confidence:offerBuildeRes.confidence;
  };

  simuateTableRowDataForTY = (
    adgroup,
    row,
    type,
    val,
    fixedpricequantity,
    minquantity,
    bonusbyquantity
  ) => {
    let bestType = adgroup.offer_type;
    // num === 0 ? type : data[data.length - num][name].forecastedOfferType; //for first two rows forecastedOfferType
    let bestVal = adgroup.offer_value;

    row.offer =
      bestVal !== "-"
        ? this.deeperOfferValueCalc(
            bestType,
            bestVal,
            minquantity,
            bonusbyquantity,
            fixedpricequantity
          )
        : "-"; //offer column value calculation

    row.offerType = bestType; //for future reference
    row.offerValue = bestVal;

    row.margin_forecast_override = adgroup.margin_forecast_override
      ? `${adgroup.margin_forecast_override}`
      : "";
    row.revenue_forecast_override = adgroup.revenue_forecast_override
      ? `${adgroup.revenue_forecast_override}`
      : "";

    row.total_units = adgroup.total_quantity ? `${adgroup.total_quantity}` : "";
    row.inc_units = adgroup.promo_inc_quantity
      ? `${adgroup.promo_inc_quantity}`
      : "";
    row.override_units = adgroup.quantity_forecast_override
      ? `${adgroup.quantity_forecast_override}`
      : ""; //To change

    row.total_sales = adgroup.total_revenue ? `${adgroup.total_revenue}` : "";
    row.inc_sales = adgroup.promo_inc_revenue
      ? `${adgroup.promo_inc_revenue}`
      : "";
    row.override_sales = adgroup.revenue_forecast_override
      ? `${adgroup.revenue_forecast_override}`
      : ""; //To change;

    row.total_margin = adgroup.total_margin ? `${adgroup.total_margin}` : "";
    row.inc_margin = adgroup.promo_inc_margin
      ? `${adgroup.promo_inc_margin}`
      : "";
    row.override_margin = adgroup.margin_forecast_override
      ? `${adgroup.margin_forecast_override}`
      : "";

    row.gm_percentage = adgroup.gm ? `${adgroup.gm}` : "";

    row.minquantity = adgroup.min_quantity ? `${adgroup.min_quantity}` : 0;
    row.bonusbyquantity = adgroup.bonus_by_quantity
      ? `${adgroup.bonus_by_quantity}`
      : 0;
    row.fixedpricequantity = adgroup.fixed_price_quantity
      ? `${adgroup.fixed_price_quantity}`
      : 0;
    row.confidence = adgroup.confidence;
  };
  simulateHandlerFromPipeline = async (
    adgroup,
    intermediateActiveEvent,
    nullPromoId,
    event,
    noNeedForSimulation
  ) => {
    let list = {},
      campaignStartDate = localStorage.getItem("campStartDate"),
      campaignEndDate = localStorage.getItem("campEndDate");

    list[
      adgroup.adgroupName ? adgroup.adgroupName : adgroup.name
    ] = adgroup.productList ? adgroup.productList : adgroup.product_list;

    let response = [];
    if (noNeedForSimulation === "noNeedForSimulation") {
      let simulateTableData = await this.simulateTableDataCalc(
        this.state.simulationData.data,
        adgroup
      );
      this.setState({
        showPromoSimulate: true,
        simulateLoader: false,
        simulateTableData,
      });
      return;
    }
    if (event === "nForM") {
      response = await this.props.getSimulationData({
        selectedAdgroupSkus: list,
        startDate: moment(intermediateActiveEvent.startDate).format(
          "YYYY-MM-DD"
        ),
        endDate: moment(intermediateActiveEvent.endDate).format("YYYY-MM-DD"),
        fiscalYear: "2019-01-01",
        promoType: intermediateActiveEvent.promoType,
        promoId: intermediateActiveEvent.promoId
          ? [intermediateActiveEvent.promoId]
          : null,
        currentYearOfferType: nullPromoId
          ? null
          : intermediateActiveEvent.offerType
          ? intermediateActiveEvent.offerType
          : null,
        currentYearOfferValue: nullPromoId
          ? null
          : intermediateActiveEvent.offerValue
          ? intermediateActiveEvent.offerValue
          : null,
        fixedpricequantity: parseInt(this.state.fixedBundleText),

        campaignId: this.state.campaignDetailsRes
          ? this.state.campaignDetailsRes[0].campaignid
          : parseInt(localStorage.getItem("campaignId")),
        campaignStartDate: moment(campaignStartDate).format("YYYY-MM-DD"),
        campaignEndDate: moment(campaignEndDate).format("YYYY-MM-DD"),
        eventId: intermediateActiveEvent.even_id
      });
    } else {
      response = await this.props.getSimulationData({
        selectedAdgroupSkus: list,
        startDate: moment(intermediateActiveEvent.startDate).format(
          "YYYY-MM-DD"
        ),
        endDate: moment(intermediateActiveEvent.endDate).format("YYYY-MM-DD"),
        fiscalYear: "2019-01-01",
        promoType: intermediateActiveEvent.promoType,
        promoId: intermediateActiveEvent.promoId
          ? [intermediateActiveEvent.promoId]
          : null,
        currentYearOfferType: nullPromoId
          ? null
          : intermediateActiveEvent.offerType
          ? intermediateActiveEvent.offerType
          : null,
        currentYearOfferValue: nullPromoId
          ? null
          : intermediateActiveEvent.offerValue
          ? intermediateActiveEvent.offerValue
          : null,

        campaignId: this.state.campaignDetailsRes
          ? this.state.campaignDetailsRes[0].campaignid
          : parseInt(localStorage.getItem("campaignId")),
        campaignStartDate: moment(campaignStartDate).format("YYYY-MM-DD"),
        campaignEndDate: moment(campaignEndDate).format("YYYY-MM-DD"),
        eventId: intermediateActiveEvent.even_id
      });
    }
    this.setState({
      simulateLoader: false,
    });
    if (response.status) {
      let simulateTableData = await this.simulateTableDataCalc(
        response.data,
        adgroup
      );
      let findOutput = _.find(response.data, {
        adgroupName: intermediateActiveEvent.name,
        offerType: intermediateActiveEvent.offerType,
      });
      this.setState({
        showPromoSimulate: true,
        simulationData: response,
        simulateTableData,
        // noCoefficientAlert: !findOutput.sufficientCoefficients
      });
    }
  };

  simulateBtnClickHandler = () => {
    this.setState({ simulateBtnClicked: false });
  };

  simulateHandler = (
    activeEvent,
    adgroupDetailsFromPipeline,
    condition,
    applied,
    noNeedForSimulation
  ) => {
    this.submitGA("Click", "Clicked_Simulation_SimulateButton");
    let sDate = activeEvent.startDate
    let { offerDetails } = this.state;
    let adgroupSelected = _.find(offerDetails, {
      promo_id: activeEvent.promoId,
      event_id: activeEvent.event_id      
    });
    let responseMessage = [];
    if(moment(sDate).isAfter(moment())){
    this.props.usersLogReport({
      screen: "simulation",
      action: "visit",
      userId: localStorage.getItem("email"),
    });

    if (this.state.fromPipelineIntermediate) {
      this.setState({
        simulateLoader: true,
        simulateBtnClicked: this.state.showPromoSimulate ? true : false,
        // showPromoSimulate: true
      });
      scroller.scrollTo("simulationLoader1", {
        duration: 900,
        delay: 200,
        smooth: "easeInOutQuart",
      });

      let event = condition === "nForM" ? "nForM" : null;
      let nullPromoId = applied ? null : "nullPromoId";
      let abc = adgroupSelected ? adgroupSelected : adgroupDetailsFromPipeline;

      this.simulateHandlerFromPipeline(
        abc,
        activeEvent,
        nullPromoId,
        event,
        noNeedForSimulation
      );
      return;
    }
    //from pipeline code
    this.setState({
      simulateLoader: true,
      showPromoSimulate: true,
      simulateBtnClicked: this.state.showPromoSimulate ? true : false,
    });

    scroller.scrollTo("simulationLoader1", {
      duration: 900,
      delay: 200,
      smooth: "easeInOutQuart",
    });

    if (condition === "nForM") {
      this.simulationActionhandler(
        adgroupSelected,
        "nForM",
        noNeedForSimulation
      );
    } else {
      this.simulationActionhandler(adgroupSelected, null, noNeedForSimulation);
    }
  }else {
    responseMessage.push(activeEvent.name + " whose Event Type " + activeEvent.startDate );
    this.setState({
     
      notFutureEvent: true,
      resFutureMessage: responseMessage,
    });

    setTimeout(() => {
      this.setState({
        notFutureEvent: false,
      });
    }, alertTimer);
  }
  };

  adgroupSavedHandler = async (promoId, noNeedForSimulation) => {
    let {
      copyCampaignData,
      promoIdFromEdit,
      activeEventType,
      fromPipelineIntermediate,
      promoIdArray,
    } = this.state;

    promoIdArray.push(promoId);

    let uniqPromoIds = _.uniq(promoIdArray);

    let offerDetails = await this.props.getAdgroupOffer({
      promoIds: fromPipelineIntermediate ? uniqPromoIds : promoIdFromEdit,
      startDate: moment(
        copyCampaignData[0].campaign_start_date,
        "MM-DD-YYYY"
      ).format("YYYY-MM-DD"),
      endDate: moment(
        copyCampaignData[0].campaign_end_date,
        "MM-DD-YYYY"
      ).format("YYYY-MM-DD"),
    });
    if (!offerDetails) {
      this.setState({ somethingWentWrong: true });
      setTimeout(() => {
        this.setState({ somethingWentWrong: false });
      }, alertTimer);
      return;
    }
    let foundOffer = _.find(offerDetails, { promo_id: promoId });
    activeEventType.offerType = foundOffer.offer_type;
    activeEventType.offerValue = foundOffer.offer_value;
    activeEventType.initialOffer = this.deeperOfferValueCalc(
      foundOffer.offer_type,
      foundOffer.offer_value,
      foundOffer.min_quantity,
      foundOffer.bonus_by_quantity,
      foundOffer.fixed_price_quantity
    ); //foundOffer.initial_offer;
    activeEventType.promoId = promoId;
    activeEventType.fixed_price_quantity = foundOffer.fixed_price_quantity;
    activeEventType.min_quantity = foundOffer.min_quantity;
    activeEventType.bonus_by_quantity = foundOffer.bonus_by_quantity;

    if (this.state.fromPipelineIntermediate) {
      this.setState({
        savedPromoId: promoId,
        activeEventType,
        enableReviewOffer: true,
        promoIdArray: uniqPromoIds,
      });
    }

    this.setState({
      offerDetails,
      defaultSelectedradio: foundOffer.offer_type,
    });
    // let detailsCall = await this.adgroupUpdateHandler(offerDetails); // no need of details api
    if (this.state.showPromoSimulate) {
      this.simulateHandler(
        activeEventType,
        this.state.adgroupDetailsFromPipeline,
        this.state.activeEventType,
        true,
        noNeedForSimulation
      );
    }
  };
  simualteLoaderHandler = (val) => {
    this.setState({
      simulateLoader: val,
    });
  };

  clearOfferBuilderValue = (clearValue) => {
    if (clearValue) {
      this.setState({
        cumulativeOfferBuilderRes: [],
      });
    }
  };

  radioButtonHandler = (val, labelName) => {
    return (
      <div className={"offerRadioButtonStyling ml-1 pl-3"}>
        { val !== "" && (<input
          type="radio"
          name="p"
          value={val}
          onClick={(e) => {
            this.showRadioClickedValue(e);
          }}
          checked={this.state.defaultSelectedradio === val ? true : false}
        />)}
        <label className="offerRadioValueStyling mb-0">{labelName}</label>

        {val === "nForM" ? (
          <span className="ml-2 simulation-text-box">
            <input
              type="text"
              maxlength="2"
              title="Please enter offer quantity"
              value={this.state.fixedBundleText}
              onChange={(e) =>
                this.setState({ fixedBundleText: e.target.value })
              }
            ></input>
            <button
              className="btn btn-outline-primary promo3Buttons"
              onClick={(e) => {
                this.setState({
                  defaultSelectedradio: "nForM",
                });
                this.simulateHandler(
                  this.state.activeEventType,
                  this.state.adgroupDetailsFromPipeline,
                  "nForM"
                );
                this.submitGA("Click", "Clicked_SimulateForEnteredQuantity");
              }}
              title="Simulate for entered quantity"
              disabled={this.state.fixedBundleText < 1}
            >
              <i class="fa fa-arrow-right"></i>
            </button>
          </span>
        ) : null}
      </div>
    );
  };

  deeperOfferValueCalc = (
    offerType,
    offerValue,
    minQty,
    bonusBuy,
    fixedpricequantity,
    buyPercent,
    getPercent
  ) => {
    if (offerType === "P") {
      return offerValue + " % OFF";
    } else if (offerType === "F" && fixedpricequantity <= 1) {
      return "$ " + offerValue;
    } else if (offerType === "A") {
      return "$ " + offerValue + " OFF";
    } else if (offerType === "B") {
      return `BUY ${minQty} GET ${bonusBuy} @ ${offerValue}% OFF`;
    } else if (offerType === "G") {
      return `BUY ${minQty} GET ${bonusBuy}`;
    } else if (offerType === "D") {
      return `BUY ${minQty} GET ${offerValue}% OFF EACH`;
    } else if (offerType === "C") {
      return `BUY ${minQty} GET ${bonusBuy} @ $ ${offerValue} OFF`;
    } else if (fixedpricequantity > 1) {
      return `${fixedpricequantity} for $ ${offerValue}`;
    } else {
      return "-";
    }
  };
  submitGA(action,label){
    PromotionCalendarGA({ action, label});
  }

  render() {
    let {
      adgroupOptions,
      selectedAdgroup,
      adgroupWithEventType,
      activeEventType,
      offerDetails,
      showLoader,
      simulationData,
      simulateLoader,
      simulateTableData,
    } = this.state;

    let findLYOffer = !showLoader
      ? _.find(offerDetails, { promo_id: activeEventType.promoId })
      : "";

    let LYOfferVal =
      !_.isEmpty(findLYOffer) && !_.isEmpty(findLYOffer.ly_data)
        ? this.offerValueCalc(
            findLYOffer.ly_data.last_year_offer_type,
            findLYOffer.ly_data.last_year_offer_value
          )
        : "-";

    let regPrice =
      !_.isEmpty(findLYOffer) && findLYOffer.reg_price
        ? "$" + findLYOffer.reg_price.toFixed(2)
        : "-";
    return (
      <div>
        {this.state.showLoader ? (
          <div id="cardbox2" style={{ marginTop: "7%" }}>
            <div className="promoDefinitionDiv">
              <PageLoader loader={this.state.showLoader} color="#fff" />
            </div>
          </div>
        ) : (
          <div className="card cardStyles">
            <div className="alertDivs">
              {this.state.showCannotSimulate ? (
                <AlertDanger
                  message="No LY Data to Simulate!"
                  show={this.state.showCannotSimulate}
                />
              ) : null}
              {this.state.somethingWentWrong ? (
                <AlertDanger
                  message="Something went wrong! Please try again."
                  show={this.state.somethingWentWrong}
                />
              ) : null}
                {this.state.notFutureEvent ? (
                <AlertDanger
                  message={`The Adroup ${this.state.resFutureMessage.toString()} is of a past date,  
                  Please try again with different Event type`}
                  show={this.state.notFutureEvent}
                />
              ) : null}
            </div>

            <div className="promoDefinitionDiv">
              <div className="card mb-4">
                <Tabs
                  id="controlled-tab-example"
                  activeKey={this.state.simulateTabKey}
                  style={{ overflowY: "hidden" }}
                  onSelect={(key) => this.setState({ simulateTabKey: key })}
                >
                  <Tab eventKey="home" title="Simulate">
                    <div className="row ml-3">
                      <BackToCalendar 
                        history={this.props.history} 
                        onClickHandler={() => {
                          this.submitGA("Click", "Clicked_Simulation_BackToCalendarButton");
                        }}
                        />
                      <div className="col-md-6">
                        <h4 className="promoHeading">Simulation Result</h4>
                      </div>
                    </div>

                    <div className="card mx-3">
                      <div className="row m-3">
                        <div className="col-3 pl-1">
                          <span
                            className="promoLabelss1 mb-0"
                            style={{ display: "inlineBlock" }}
                          >
                            Promotion Name
                          </span>
                          {/* <Select
                            placeholder="Choose AdGroup"
                            options={this.state.campaignOptions}
                            value={this.state.selectedCampaign}
                            isSearchable={true}
                          /> */}
                          <input
                            type="text"
                            value={this.state.selectedCampaign.value}
                            style={{
                              width: "98%",
                              height: "34px",
                              paddingLeft: "10px",
                            }}
                          ></input>
                        </div>
                        <div className="col-2 align-self-end">
                          <button
                            type="button"
                            className="btn btn-outline-primary promo3Buttons btGroup1 mb-0"
                            onClick={() => {
                              this.setState({
                                showOfferModal: true,
                              });
                              this.submitGA("Click", "Clicked_Simulation_ReviewOffers");
                            }}
                            disabled={
                              !this.state.fromPipelineIntermediate ||
                              (this.state.fromPipelineIntermediate &&
                                this.state.enableReviewOffer)
                                ? false
                                : true
                            }
                          >
                            Review Offers
                          </button>
                        </div>
                      </div>
                      {this.state.showLoader ? null : (
                        // <div className="card cardGap mx-3">
                        <div className="simulationDiv createClusterDiv mb-4">
                          <SimulateTopScreen
                            adgroupListWithLabels={adgroupOptions}
                            selectedAdgroup={selectedAdgroup}
                            adgroupChangeHandler={this.adgroupChangeHandler}
                            adgroupWithEventType={adgroupWithEventType} //event type is campaign dependent
                            tyEventsData={this.state.tyEventsData}
                            fromPipelineIntermediate={
                              this.state.fromPipelineIntermediate
                            }
                            eventTypeChangeHandler={this.eventTypeChangeHandler}
                            activeEventType={activeEventType}
                            intermediateActiveEvent={this.state.activeEventType}
                            regPrice={regPrice}
                            TYOfferValue={activeEventType.initialOffer}
                            lastYearOffer={LYOfferVal}
                            showSkuList={this.showSkuListHandler}
                            showPromoSimulate={() => {
                              this.simulateHandler(
                                this.state.activeEventType,
                                this.state.adgroupDetailsFromPipeline
                              );
                            }}
                          />
                        </div>
                        // </div>
                      )}
                    </div>

                    {this.state.showLoader ? null : (
                      <>
                        <div name="offerBuilder" className="card cardGap mx-3">
                          <div className="mt-3">
                            {/* <p className="labelOfferType mb-1 pl-3">
                              Offer Type
                              <span className="pr-3 float-right">
                                BB- Bonus Buy
                              </span>
                            </p> */}
                            <div className="offerTypeParentDiv ml-2">
                              <div className="row offerTypeDiv justify-content-around mr-3">
                                {this.radioButtonHandler(
                                  "P",
                                  "P - % price change"
                                )}
                                {this.radioButtonHandler(
                                  "F",
                                  "F - Fixed price"
                                )}
                                {this.radioButtonHandler(
                                  "A",
                                  "A - Relative price change"
                                )}
                                {this.radioButtonHandler(
                                  "",
                                  ""
                                )}
                                {/* {this.radioButtonHandler(
                                  "D",
                                  "D - BB% discount"
                                )} */}
                              </div>
                              <div className="row offerTypeDiv justify-content-around mr-3 mb-3">
                                {this.radioButtonHandler(
                                  "G",
                                  "G - BB free goods"
                                )}
                                {this.radioButtonHandler(
                                  "B",
                                  "B - BB% disc with get"
                                )}
                                {this.radioButtonHandler(
                                  "C",
                                  "C - BB fixed disc with get"
                                )}
                                {this.radioButtonHandler(
                                  "D",
                                  "D - BB% discount"
                                )}
                                {/* {this.radioButtonHandler(
                                  "nForM",
                                  "F - Fixed bundle offer"
                                )} */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                    {this.state.showPromoSimulate ? (
                      <SimulatePromotion
                        simulateTableData={simulateTableData}
                        simulateLoader={simulateLoader}
                        simualteLoaderHandler={this.simualteLoaderHandler}
                        simulationDataFromParent={simulationData}
                        offerDetails={offerDetails}
                        activeEventType={activeEventType}
                        addComparedValues={this.addComparedValues}
                        adgroupSavedHandler={this.adgroupSavedHandler}
                        noCoefficientAlert={this.state.noCoefficientAlert}
                        fromPipelineIntermediate={
                          this.state.fromPipelineIntermediate
                        }
                        tyEventsData={this.state.tyEventsData}
                        defaultSelectedradio={this.state.defaultSelectedradio}
                        intermediateActiveEvent={this.state.activeEventType}
                        selectedAdgroup={selectedAdgroup}
                        savedPromoId={this.state.savedPromoId}
                        adgroupWithEventType={adgroupWithEventType} //event type is campaign dependent
                        addRowLoader={this.state.addRowLoader}
                        offerBuildeRes={this.state.cumulativeOfferBuilderRes}
                        clearOfferBuilderValue={this.clearOfferBuilderValue}
                        offerBuilderError={this.state.offerBuilderError}
                        simulateBtnClickHandler={this.simulateBtnClickHandler}
                        simulateBtnClicked={this.state.simulateBtnClicked}
                      />
                    ) : null}

                    <PageLoader loader={this.state.simulateLoader}>
                      <div
                        className={
                          this.state.simulateLoader
                            ? "card simulationLoaderCard m-3"
                            : "invisible"
                        }
                        name="simulationLoader1"
                      />
                    </PageLoader>
                  </Tab>
                  <Tab eventKey="compare" title="Compare">
                    <ComparePromotion />
                  </Tab>
                </Tabs>

                {this.state.showOfferModal ? (
                  <PromoOfferModal
                    showOfferModal={this.state.showOfferModal}
                    showOfferModalHandler={() =>
                      this.setState({ showOfferModal: false })
                    }
                    offerDetails={offerDetails}
                    offerDetailsApiCall={this.offerDetailsApiCall}
                    adgroupSavedHandler={this.adgroupSavedHandler}
                  />
                ) : null}

                {this.state.showSkuList ? (
                  <SkuList
                    adgroupListWithLabels={adgroupOptions}
                    selectedAdgroup={selectedAdgroup}
                    activeEventType={activeEventType}
                    adgroupWithEventType={adgroupWithEventType}
                    offerDetails={offerDetails}
                    showSkuList={this.state.showSkuList}
                    showSkuListHandler={this.showSkuListHandler}
                    fromPipelineIntermediate={
                      this.state.fromPipelineIntermediate
                    }
                  />
                ) : null}
                <div name="scrollHandler" />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

Subcontent.propTypes = {
  getSimulationData: PropTypes.func.isRequired,
  getCampaignsDetails: PropTypes.func.isRequired,
  pipelineGetTyEvents: PropTypes.func.isRequired,
  listCampaigns: PropTypes.func.isRequired,
  getAdgroupOffer: PropTypes.func.isRequired,
  getAdgroupDetailsFromId: PropTypes.func.isRequired,
  offerBuilderApi: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  simulationData: state.simulationData,
  campaignsList: state.createPromoData.campaignsList,
});

export default connect(mapStateToProps, {
  listCampaigns,
  getSimulationData,
  getCampaignsDetails,
  pipelineGetTyEvents,
  getAdgroupOffer,
  getAdgroupDetailsFromId,
  offerBuilderApi,
  usersLogReport,
})(withRouter(Subcontent));
