import { Button } from "antd";
import React, { useState } from "react";

const PromoEditRenderer = (props) => {
  const [show, setShow] = useState(false);

  const handleEditModal = () => {
    console.log("🚀 ~ handleEditModal ~ props:", props);
    setShow(true);
    props.data.handleEditUpdateModal(true, props);
  };

  const isDisabled = (
  (localStorage.getItem("role") !== "3" &&
  localStorage.getItem("role") !== "6") ||

  ((localStorage.getItem("role") === "3" && props.data && props.data.mar_approval_status &&  props.data.mar_approval_status !== "PENDING")|| (localStorage.getItem("role") === "6" && props.data && props.data.di_approval_status && props.data.di_approval_status !== "PENDING")) );

  const entry_reason = !(props.data && props.data.entry_reason && props.data.entry_reason == "Delete Offer");

  return (
    <div style={{ textAlign: "center" }}>
      {entry_reason && (
      <Button
        type="link"
        title="Edit Offer"
        onClick={handleEditModal}
        disabled={isDisabled}
        icon={<i className="fa fa-edit" aria-hidden="true"/>}
        style={ (isDisabled) ? { fontSize: "10px", height: "25px" } : {}}
      ></Button>
    )}
    </div>
  );
};

export default PromoEditRenderer;