export default {
  defaultColDef: {
    resizable: true,
    filter: true,
    sortable: true,
  },
  promoDetailsColdef: [
    { 
      headerName: "Adgroup", 
      field: "name",
      headerClass: "blue-header-bg",
      filter: "agTextColumnFilter",
      pinned : "left" ,
      width : 150
   },
    { 
      headerName: "Adgroup Desc.", 
      field: "adgroup_description",
      headerClass: "blue-header-bg",
      filter: "agTextColumnFilter",
     },
    // { 
    //   headerName: "Event Id", 
    //   field: "g_eid",
    //   headerClass: "blue-header-bg",
    //   filter: "agTextColumnFilter",
    //  },
    { 
      headerName: "Sign shell",
     field: "promo_sign_shell",
     headerClass: "blue-header-bg",
     width : 140,
     filter: "agTextColumnFilter",
   },
   {
    headerName: "Promo type",
    field: "promo_event_type",
    headerClass: "blue-header-bg",
    width: 150,
    filter: "agTextColumnFilter",
    unSortIcon: true
  },
  //   { 
  //     headerName: "TY Event Type", 
  //     field: "promo_event_type",
  //     headerClass: "blue-header-bg",
  //     filter: "agTextColumnFilter",
  //  },
    {
      headerName: "Offer Type",
      field: "offer_type",
      headerClass: "blue-header-bg",
      width: 150,
      filter: "agTextColumnFilter",
      unSortIcon: true
    },
    {
      headerName: "Offer Amt",
      field: "offer_value",
      filter: "agTextColumnFilter",
      width: 150,
      headerClass: "blue-header-bg",
      suppressSizeToFit: true,
      unSortIcon: true
    },
    {
      headerName: "Fixed price Qty",
      field: "fixed_price_quantity",
      filter: "agTextColumnFilter",
      width: 200,
      headerClass: "blue-header-bg",
      suppressSizeToFit: true,
      unSortIcon: true
    },
    {
      headerName: "Min Qty",
      field: "min_quantity",
      filter: "agTextColumnFilter",
      headerClass: "blue-header-bg",
      width: 150,
      suppressSizeToFit: true,
      unSortIcon: true
    },
    {
      headerName: "Bonus Buy",
      field: "bonus_by_quantity",
      filter: "agTextColumnFilter",
      headerClass: "blue-header-bg",
      width: 200,
      suppressSizeToFit: true,
      unSortIcon: true
    },
    {
      headerName: "Min Value (Buy$)",
      field: "ty_buy_min_value",
      filter: "agTextColumnFilter",
      headerClass: "blue-header-bg",
      width: 200,
      suppressSizeToFit: true,
      filterParams: { defaultOption: "startsWith" },
      unSortIcon: true
    },
    {
      
      headerName: "Max Value (Get$)",
      field: "ty_get_max_value",
      filter: "agTextColumnFilter",
      headerClass: "blue-header-bg",
      width: 200,
      suppressSizeToFit: true,
      unSortIcon: true
    },
    {
      headerName: "UAS",
      field: "ty_uas",
      headerClass: "blue-header-bg",
      width: 100,
      filter: "agTextColumnFilter",
      valueGetter: tyuasFormatter,
      unSortIcon: true
    },
    // {
    //   headerName: "TY promo status",
    //   field: "promo_build_status",
    //   headerClass: "blue-header-bg",
    //   width: 300,
    //   // cellRenderer: "pipelineDropdownRenderer",
    //   filter: "agTextColumnFilter",
    //   unSortIcon: true
    // },
  ],
  promoEventErrorColdef: [
    {
      headerName: "Adgroup", 
      field: "adgroup_name",
      headerClass: "blue-header-bg",
      filter: "agTextColumnFilter", 
      checkboxSelection: false,
    },
    { 
      headerName: "Event", 
      field: "event_detail",
      headerClass: "blue-header-bg",
      filter: "agTextColumnFilter",
      checkboxSelection: false,
    },
    { 
      headerName: "Offer Type", 
      field: "offer_type",
      headerClass: "blue-header-bg",
      filter: "agTextColumnFilter",
      checkboxSelection: false,
    },
    { 
      headerName: "Offer Value", 
      field: "offer_value",
      headerClass: "blue-header-bg",
      filter: "agTextColumnFilter",
      checkboxSelection: false,
    },
    {
      headerName: "Status",
      field: 'status',
      cellRenderer: "approvalStatusRenderer",
      tooltipField: 'validationErrors',
      pinned: 'right',
      headerClass: "blue-header-bg",
      width: 100,
      filter: "agTextColumnFilter"
    }
    // { headerName: "Offer", field: "offer_detail" },
  ]
}

function tyuasFormatter(params) {
  if (params.data.ty_uas == "x") {
    return "Yes"
  }

  return "No"
}