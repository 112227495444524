import React, { useEffect, useState, useRef } from "react";
import Modal from "react-awesome-modal";
import PageLoader from "../../../../utils/Loader";
import BackToCalendar from "../../../../utils/BackToCalendar";
import LoadingOverlay from "react-loading-overlay";
import moment from "moment";
import "./promoPipeline.css";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { uploadFile, downloadUploadOfferCSV, uploadFileToBulkModify, unsetUploadErrorMessage } from "../../../../redux/actions/calendarAction";
import { saveAdgroupFromIntermediate, fetchRegSalesPrices, getPromosData } from "../../../../redux/actions/adgroup";
import { withRouter } from "react-router";
import ColumnDef from "../../../tables/colDefs/UploadOffersColDef";
import DataTable from "../../../tables";
import _ from 'lodash';
import { render } from "react-dom";
import AlertDanger from "../../../../utils/AlertMessage/alertWarning";
import AlertSuccess from "../../../../utils/AlertMessage/alertSuccess";
import { PromotionCalendarGA } from '../../../../utils/GA/GAUtils';
import ApprovalStatusRenderer from "./ApprovalStatusRenderer";
import PromoStatusRenderer from "./PromoStatusRenderer";
import { alertTimer } from "../../../../utils/alertTimer";
import {isIt} from "../../../../utils/getRoleName";
import {getOfferTitle} from "../../../../utils/offerTitle";

let upload_offer_file = null

const UploadOffersModal = props => {
    let historyCampId = localStorage.getItem("campaignId");
    const [loader, setLoader] = useState(false);
    const [promotionName, setPromotionName] = useState('');
    const [campaignStartDate, setCampaignStartDate] = useState('');
    const [campaignEndDate, setCampaignEndDate] = useState('');
    const [noOfDays, setNoOfDays] = useState('');
    const [reviewScreen, setReviewScreen] = useState(false);
    const [uploadFileName, setFileName] = useState('');
    const [reviewOfferTableData, setTableData] = useState({});
    const [Errordata, setErrorData] = useState({});
    const [modifyOfferApprovalErrorData, setModifyOfferApprovalErrorData] = useState({});
    const [disableSaveBtn, setDisableSaveBtn] = useState(true);
    const [addPipelineAdGroupRow, setAddPipelineAdGroupRow] = useState([]);
    const [selectedAdgroupId, setSelectedAdgroupId] = useState([]);
    const [selectedAdgroupName, setSelectedAdgroupName] = useState([]);
    const [somethingWrongAlert, setSomethingWrongAlert] = useState(false);
    const [resMessage, setResMessage] = useState([]);
    const [adgroupAddedAlert, setAdgroupAddedAlert] = useState(false);
    const [uploadError, setUploadError] = useState(false);
    const [isModify, setModify] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [successMessage, setSuccessMessage] = useState(false);
    const [errorFromPromoApproval, seterrorFromPromoApproval] = useState(false);
    const [notModifiedError,setNotModifiedError] = useState(false);
    // const [calculationChanged, setCalculationChanged] = useState(false);
    // const [calcRowIndex, setRowIndex] = useState("");
    const calculationChanged = useRef(false);
    const calcRowIndex = useRef("")
    const hiddenFileInput = useRef();
    const reviewedTableData = useRef({});

    useEffect(() => {
        setPromotionName(localStorage.getItem("campaignName"));
        setCampaignStartDate(localStorage.getItem("campStartDate"));
        setCampaignEndDate(localStorage.getItem("campEndDate"));
        let campSD = new Date(localStorage.getItem("campStartDate"))
        let campED = new Date(localStorage.getItem("campEndDate"))
        let diffTime = Math.abs(campSD.getTime() - campED.getTime()) + 1
        let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        setNoOfDays(diffDays);
        upload_offer_file = !_.isEmpty(localStorage.getItem("sample_upload_offer_link"))
            ? JSON.parse(localStorage.getItem("sample_upload_offer_link"))
            : "#";
        if (localStorage.getItem("modifyOffers") == 1) {
            setModify(true);
        }
        return () => {
            localStorage.setItem("modifyOffers", 0);
        }
    }, []);

    useEffect(() => {

        if (props.uploadData && props.uploadData.data && props.uploadData.data.length > 0) {
            let columnDefs = ColumnDef.header(reviewScreen);

            let uploadedData = props.uploadData.data;

            uploadedData.forEach(row => {
                row.getRegSalesPrices = getRegSalesPrices;
                row.isModify = isModify;
                // row.updateCalculationChanged = updateCalculationChanged;
            })

            let tableData = {
                header: columnDefs,
                defaultColDef: ColumnDef.defaultColDef,
                data: uploadedData
            }
            setTableData(tableData);
            reviewedTableData.current = tableData;
        }
    }, [props.uploadData.data]);

    useEffect(() => {
        if (props.uploadErrorMsg) {
            setUploadError(true);
        }
        setTimeout(() => {
            setUploadError(false);
            props.unsetUploadErrorMessage();
        }, alertTimer)
    }, [props.uploadErrorMsg])


    const handleClick = (event) => {
        hiddenFileInput.current.click();

    }

    const handleChange = (event) => {
        const fileUploaded = event.target.files[0];
        setFileName(fileUploaded.name);
        let extension = fileUploaded.name.split('.').pop();
        const data = new FormData();
        data.append('name', fileUploaded.name)
        data.append('file', fileUploaded);
        data.append('campaign_id', historyCampId);

        if (isModify) {
            props.uploadFileToBulkModify(data)
        } else {
            props.uploadFile(data);
        }
        event.target.value = '';
        setReviewScreen(true);
    }

    const handleDragOver = (event) => {//executed when file is dragged over drag-area
        event.preventDefault();
    }

    const handleDragLeave = (event) => {// executed when control leaves out of drag-area
        event.preventDefault();
    }

    const handleDragEnter = (event) => {// executed when file enters in drag-area
        event.preventDefault();
    }

    const handleDrop = (event) => {// executed when file is dropped on drag-area
        event.preventDefault();
        const fileUploaded = event.dataTransfer.files[0]; //e.dataTransfer.files contains the dragged local files as a FileList
        setFileName(fileUploaded.name);
        let data = new FormData();
        data.append('name', fileUploaded.name)
        data.append('file', fileUploaded);
        data.append('campaign_id', historyCampId);

        const fileSize = fileUploaded.size;
        if (fileSize > 10 ^ 7) {
            console.log("file size")
        }

        if (isModify) {
            props.uploadFileToBulkModify(data)
        } else {
            props.uploadFile(data);
        }
        setReviewScreen(true);
    }

    const uploadOnClick = () => {
        setReviewScreen(false);
        setTableData({});
        setErrorData({});
        setModifyOfferApprovalErrorData({});
        reviewedTableData.current = {};
    }
    const getRowStyle = (params) => {
        let status = params.data.uploadStatus;
        let status2 = params.status
        if (status === 'Failed' || status2 == false) {
            return { background: '#ffd6eb' };
        }
    }

    const onSelectionChanged = (rowValue) => {
        let addPipelineAdGroupRow = [];

        let selectedAdgroupId = [];
        let selectedAdgroupName = [];

        selectedAdgroupId = _.map(rowValue, (item) => {
            selectedAdgroupName.push({
                adgroup_id: item.adgroup_id,
                adgroup_name: item.adgroup_name,
                ly_start_date: item.ly_start_date,
                ly_end_date: item.ly_end_date,
                ly_event_type: item.ly_event_type,
                ly_offer: item.ly_offer,
            });
            return item.adgroup_id;
        });

        _.map(rowValue, (row) => {
            row.isRowSaved = false;
            row.start_date = moment(row.ty_start_date).format("YYYY-MM-DD")
            row.end_date = moment(row.ty_end_date).format("YYYY-MM-DD")
            if(!isModify)row.addNewOffer = true;
            if(isModify)row.isModify = true;
            row.user_role =  localStorage.getItem("role");
            if (row.uploadStatus === "Successful") {
                addPipelineAdGroupRow.push(row);
            }
        });

        if (addPipelineAdGroupRow.length) {
            setAddPipelineAdGroupRow(addPipelineAdGroupRow)
            setSelectedAdgroupId(selectedAdgroupId)
            setSelectedAdgroupName(selectedAdgroupName)
            setDisableSaveBtn(false)
        } else {
            setDisableSaveBtn(true)
        }
    };

    const addAdgroupToPipeline = async () => {
        setLoader(true)
        setErrorData(_.cloneDeep({}));
        setModifyOfferApprovalErrorData(_.cloneDeep({}));
        seterrorFromPromoApproval(false);
        setNotModifiedError(false);
        // GA
        let addPipelineAdGroupRow1 = addPipelineAdGroupRow;
        const totalOffersToUpload = _.size(addPipelineAdGroupRow);
        submitGA("Click", "Clicked_UploadOffers_SaveButton", totalOffersToUpload);

        let errorTableData, tableData ;

    if(isModify && localStorage.getItem("role")==2) {

        let eventId = [],adgroupId = [], campaignId = [];
        for(let it of addPipelineAdGroupRow) {
            console.log("eventId",eventId);
            eventId.push(it.eventId);
            adgroupId.push(it.adgroup_id);
            campaignId.push(localStorage.getItem("campaignIdsForUrl"));
        }

        let promosData = await props.getPromosData({eventId, adgroupId, campaignId});
        let futureDataValues = {}, tempFutureData = {}, currentDataValues = {};

        for(let it of addPipelineAdGroupRow) {
            let titleObject = {
                offerType: it.ty_offer_type,
                offerValue: it.ty_planned_amount>0  ? it.ty_planned_amount : 0,
                offer: it.ty_planned_amount>0  ? it.ty_planned_amount : 0,
                bonusbyquantity: !it.ty_bonus_pay ? null : it.ty_bonus_pay,
                minquantity: !it.ty_min_qty ? null : it.ty_min_qty,
                fixedpricequantity: !it.fixed_price_quantity ? null : it.fixed_price_quantity,
              };
            let offer_title = getOfferTitle(titleObject.offerType, titleObject.offerValue, titleObject.fixedpricequantity, titleObject.minquantity, titleObject.bonusbyquantity, titleObject.offer);
            it["offer_title"] = offer_title;
        }
        
        for(let it of addPipelineAdGroupRow) {
            let key = it.adgroup_id + '_' + it.eventId + '_' + promosData.data[0].campaign_id;
            futureDataValues[key] = 
            (it.uas ? it.uas : it.ty_uas)+'_'+
            (it.ty_offer_type)+'_'+
            (it.ty_planned_amount>0  ? it.ty_planned_amount : 0)+'_'+
            (it.fixed_price_quantity>0  ? it.fixed_price_quantity : 0)+'_'+
            (it.ty_min_qty>0  ? it.ty_min_qty : 0)+'_'+
            (it.ty_bonus_pay>0  ? it.ty_bonus_pay : 0)+'_'+
            (it.ty_buy_min_value>0  ? it.ty_buy_min_value : 0)+'_'+
            (it.ty_get_max_value>0  ? it.ty_get_max_value : 0)
            
            tempFutureData[key] = 
            (it.ty_type) + '-' +
            (it.ty_event_type) + '-' +
            (it.start_date) + '-' +
            (it.end_date)+'^^'+
            (it.adgroup_name)+'~~'+
            (it.offer_title)+ 'offer_type' +
            (it.ty_offer_type)

            it["unique_combination"] = key;
        }

        for(let it of promosData.data){
            let key = it.adgroup_id + '_' + it.event_id + '_' + it.campaign_id;
            currentDataValues[key] = 
            (it.uas)+'_'+
            (it.offer_type)+'_'+
            (it.offer_value>0  ? it.offer_value : 0)+'_'+
            (it.fixed_price_quantity>0  ? it.fixed_price_quantity : 0)+'_'+
            (it.min_quantity>0  ? it.min_quantity : 0)+'_'+
            (it.bonus_by_quantity>0  ? it.bonus_by_quantity : 0)+'_'+
            (it.buy_min_value>0  ? it.buy_min_value : 0)+'_'+
            (it.get_max_value>0  ? it.get_max_value : 0)
        }

        let notModifiedAdgroups=[], filteredPayloadData = [];

        for(let key in futureDataValues){
            if(currentDataValues[key] == futureDataValues[key]) {
                let ty_event_data = tempFutureData[key];
                let data = ty_event_data.split("^^");
                let seperateOfferTitle = data[1].split("~~");
                ty_event_data = data[0];
                let offer_title = seperateOfferTitle[1].split('offer_type');
                offer_title = offer_title[0];
                let offer_type = tempFutureData[key].split('offer_type');
                let adgroup_name = seperateOfferTitle[0];
                let  error_arr= [];
                error_arr.push(adgroup_name);
                error_arr.push(ty_event_data);
                error_arr.push(offer_title);
                error_arr.push(offer_type[1]);
                notModifiedAdgroups.push(error_arr);
            }
            else {
                let matchingElements = addPipelineAdGroupRow.filter(element => {return key == element.unique_combination});
                filteredPayloadData = filteredPayloadData.concat(matchingElements);
            }
        }
        addPipelineAdGroupRow1 = filteredPayloadData;
    if(notModifiedAdgroups.length > 0 && notModifiedAdgroups.length == Object.keys(futureDataValues).length && isModify) {
        setNotModifiedError(true);
        errorTableData = notModifiedAdgroups.map(item => ({
                adgroup_name: item[0],
                event_detail: item[1],
                status : false,
                offer_type : item[3],
                offer_value : item[2],
                validationErrors : "User is not allowed to modify the offer with same details"
            
    }));
        tableData = {
            header: ColumnDef.uploadOffersPromoApprovalErrorsData,
            defaultColDef: ColumnDef.defaultColDef,
            data: errorTableData
        } 
        const clonedTempData = _.cloneDeep(tableData);
        setErrorData(clonedTempData);
        setLoader(false);
        return ;
    }

    else if (notModifiedAdgroups.length > 0 && isModify){
        setNotModifiedError(true);
        errorTableData = notModifiedAdgroups.map(item => ({
            adgroup_name: item[0],
            event_detail: item[1],
            status : false,
            offer_type : item[3],
            offer_value : item[2],
            validationErrors : "User is not allowed to modify the offer with same details"
        
}));
        tableData = {
            header: ColumnDef.uploadOffersPromoApprovalErrorsData,
            defaultColDef: ColumnDef.defaultColDef,
            data: errorTableData
        } 
        const clonedTempData = _.cloneDeep(tableData);
        setErrorData(clonedTempData);
    }

}

        let res = await props.saveAdgroupFromIntermediate({
            multiplePromos: isModify ? addPipelineAdGroupRow1 : addPipelineAdGroupRow,
            campaignId: parseInt(localStorage.getItem("campaignIdsForUrl")),
        });

        // setTimeout(() => {
        //   props.updatePipelineData([], "intermediate");
        // }, 2500);

        let foundPromoApprovalRes = _.filter(res, { inPromoApproval: true });
        let foundRes = _.filter(res, { status: false });
        let foundSuccessRes = _.filter(res, { status: true });

        if(foundPromoApprovalRes && foundPromoApprovalRes.length > 0) {
            // this.setState({
            //   isSuccess: true,
            //   successMessage: "Promo operation has been submiited for approval",
            // });
            setIsSuccess(true);
            setSuccessMessage("Promo offer/s has been submitted for approval")
            setLoader(false)

            setTimeout(() => {
                //   this.setState({
                //     isSuccess: false,
                //     successMessage: ''
                //   });
                setIsSuccess(false);
                setSuccessMessage("")
            }, alertTimer);
        } 
        // else
         if( (foundRes && foundRes.length) || (foundSuccessRes && foundSuccessRes.length) ) {

            // let foundRes = _.filter(res, { status: false });
            // let foundSuccessRes = _.filter(res, { status: true });


            if(foundSuccessRes.length > 0) {

                const message =  isModify ? "Offers modified Successfully" : "Offers created Successfully" ;
                setIsSuccess(true);
                setSuccessMessage(message)
    
                setTimeout(() => {
                    setIsSuccess(false);
                    setSuccessMessage("")
                }, alertTimer);
            }

            let responseMessage = [];

            if (foundRes && (foundRes.length > 0)) {
                    seterrorFromPromoApproval(true);
                    let errorTableData2 = foundRes.map(error => {
                        return {
                          adgroup_name: error.data.adgroupName,
                          event_detail: error.data.ty_event,
                          offer_type : error.data.offer_type,
                          offer_value : error.data.offer_value,
                          status : error.status,
                          validationErrors : error.data.validationErrors
                        };
                    });
                    if(errorTableData && errorTableData.length>0){
                        errorTableData = errorTableData.concat(errorTableData2);
                      }
                      else{
                        errorTableData = errorTableData2
                      }
                    tableData = {
                      header: ColumnDef.uploadOffersPromoApprovalErrorsData,
                      defaultColDef: ColumnDef.defaultColDef,
                      data : errorTableData
                }
                const clonedTempData = _.cloneDeep(tableData);
                setErrorData(clonedTempData); 
            }

            if (res[0].status) {
                addPipelineAdGroupRow.map((item) => {
                    item.isRowSaved = true;
                    return item;
                });

                setLoader(false)
                setAdgroupAddedAlert(true)
                setAddPipelineAdGroupRow(addPipelineAdGroupRow);


                setTimeout(() => {
                    setAdgroupAddedAlert(false);
                    setAddPipelineAdGroupRow([])
                    setSelectedAdgroupId([])
                    setSelectedAdgroupName([])
                    setDisableSaveBtn(true)
                    // props.history.push("/promotion");
                }, alertTimer);
            }
            setLoader(false)
        }
    }

    const simulateHandler = () => {
        localStorage.setItem("promoIdsForUrl", null);
        localStorage.setItem("adgroupIdsForUrl", selectedAdgroupId);
        localStorage.setItem(
            "adgroupNamesForUrl",
            JSON.stringify(selectedAdgroupName)
        );
        props.history.push(`/createpromotion?pipelineInt=true`);
    };

    const submitGA = (action, label, value) => {
        PromotionCalendarGA({ action, label, value });
    }

    const getRegSalesPrices = (rowData, nodeIndex) => {
        let eventType = rowData.ty_event_type;
        let offerType = rowData.ty_offer_type;
        let minQuantity = rowData.ty_min_qty;
        let bonusPay = rowData.ty_bonus_pay;
        let plannedAmount = rowData.ty_planned_amount;
        let fixedPriceQuantity = rowData.fixed_price_quantity;

        const reqObj = {
            eventId: rowData.eventId,
            adgroup_id: rowData.adgroup_id,
            offerType: offerType,
            minQuantity: minQuantity,
            bonusPay: bonusPay,
            plannedAmount: plannedAmount,
            fixedPriceQuantity: fixedPriceQuantity
        }

        setLoader(true);

        props.fetchRegSalesPrices(reqObj).then(res => {
            console.log(res);
            const result = res.data;
            let tempData = reviewedTableData.current.data;
            console.log("****reviewedTableData", reviewedTableData)
            tempData.forEach(item => {

                if (item.adgroup_id == rowData.adgroup_id && item.eventId == rowData.eventId) {
                    item.regLow = result[0].regLow;
                    item.regHigh = result[0].regHigh
                    item.saleLow = result[0].saleLow
                    item.saleHigh = result[0].saleHigh
                }
            });
            // tableData.data = tempData;
            updateCalculationChanged(nodeIndex);
            setTableData(tempData);
            reviewedTableData.current.data = tempData;
            setLoader(false);

        })
    }

    const updateCalculationChanged = (index) => {
        // this.setState({
        //   tyEventChanged: !this.state.tyEventChanged,
        //   tyRowIndex: index,
        // });
        calcRowIndex.current = index;
        let tempstatus = calculationChanged.current;
        calculationChanged.current = !tempstatus;
        // setCalculationChanged(!calculationChanged);
        // setRowIndex(index);

    };

    return (
        <PageLoader loader={props.loader || loader}>
            {/* {console.log("tabledata:", reviewOfferTableData)} */}
            <div className="card cardStyles cardGap">
                <div id="cardbox1" style={{ padding: "1px 12px 10px 20px" }}>
                    <div className="createClusterDiv">
                        <div className="row pl-3">
                            <BackToCalendar history={props.history} />
                            <div className="col-md-6">
                                {" "}
                                <h4 className="promoHeading">
                                    {isModify ? "Modify Offers" : "Upload Offers"}
                                </h4>
                            </div>
                        </div>
                        <div className="row" style={{ marginLeft: "20px", marginTop: "10px" }}>
                            <div className="col-md-12">
                                <div className="row" //style={{ marginLeft: "12px" }}
                                >
                                    <div className="col-md-3" style={{ marginRight: "20px" }}>
                                        <span className="promoNameText row">
                                            Campaign name:{" "}
                                            <p className="promonameResult">{`${moment(campaignStartDate).format("MM/DD/YYYY")} - ${moment(campaignEndDate).format("MM/DD/YYYY")} `}</p>
                                        </span>
                                    </div>
                                    <div className="col-md-3">
                                        <span
                                            className="promoNameText row"
                                            style={{ paddingLeft: "2%" }}
                                        >
                                            Start Date:
                                            <p className="promonameResult">
                                                {moment(campaignStartDate).format("MM/DD/YYYY")}
                                            </p>
                                        </span>
                                    </div>
                                    <div className="col-md-3">
                                        <span
                                            className="promoNameText row"
                                            style={{ paddingLeft: "2%" }}
                                        >
                                            End Date:
                                            <p className="promonameResult">
                                                {moment(campaignEndDate).format("MM/DD/YYYY")}
                                            </p>
                                        </span>
                                    </div>
                                    <div className="col-md-2">
                                        <span
                                            className="promoNameText row"
                                            style={{ paddingLeft: "2%" }}
                                        >
                                            # of days:
                                            <p className="promonameResult">{noOfDays}</p>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {
                            reviewScreen ? (
                                <div className="reviewOffer row">
                                    <p>Upload file name:&nbsp;<b>{uploadFileName}</b><span><i class="fa fa-times-circle" aria-hidden="true"></i> </span></p>
                                    <button
                                        className="uploadButton btn btn-outline-primary"
                                        onClick={uploadOnClick}
                                    >
                                        {isModify ? "Modify Offers" : "Upload Offers"}
                                    </button>
                                </div>
                            ) : null
                        }
                    </div>
                    {
                        reviewScreen ? (
                            <div className="review-offers-table">
                                <hr style={{ borderColor: 'black' }}></hr>
                                <h4 className="review-heading">Review Offer</h4>
                                {
                                    reviewedTableData.current && reviewedTableData.current.header ? (
                                        <div>
                                            <div
                                                style={{ margin: "1% 1% 0% 2%", height: "55%" }}
                                                className="add-adgroup-pipeline"
                                            >
                                                <DataTable
                                                    parentId="myGridForPipeline"
                                                    parentStyle={{
                                                        // height: "calc(100vh - 380px)",
                                                        height: "20vh",
                                                        width: "100%",
                                                        position: "relative",
                                                    }}
                                                    parentClass="ag-theme-balham"
                                                    tableData={reviewedTableData.current}
                                                    getRowStyle={getRowStyle}
                                                    rowSelection="multiple"
                                                    suppressRowClickSelection={true}
                                                    selectOfferRow={onSelectionChanged}
                                                    frameworkComponents={{
                                                        promoStatusRenderer: PromoStatusRenderer
                                                    }}
                                                    updateCalculationChanged={updateCalculationChanged}
                                                    calcRowIndex={calcRowIndex.current}
                                                    calculationChanged={calculationChanged.current}
                                                />


                                                {
                                                
                                                    Errordata.data &&  Errordata.data.length>0 &&
                                                    <>
                                                        <p className="errorMessage">{`Following offers cannot be ${isModify ? "modified" : "created"}`}</p>
                                                        <div className="review-offers-table">
                                                            <DataTable
                                                                parentId="myGridForPipeline"
                                                                tableData={Errordata}
                                                                parentStyle={{
                                                                    height: "20vh",
                                                                    width: "100%",
                                                                    position: "relative",
                                                                }}
                                                                // getRowStyle={getRowStyle}
                                                                frameworkComponents={{
                                                                    approvalStatusRenderer: ApprovalStatusRenderer
                                                                }}
                                                                parentClass="ag-theme-balham"
                                                                autoSizeCol={true}
                                                            />
                                                        </div>
                                                    </>
                                                }


                                            </div>
                                            <div className="text-center mt-3">
                                                <button
                                                    className="btn btn-outline-primary promo3Buttons"
                                                    disabled={disableSaveBtn || isIt()}
                                                    onClick={() => {
                                                        addAdgroupToPipeline();
                                                    }}
                                                >
                                                    Save
                                                </button>
                                                {/* <button
                                                    className="btn btn-outline-primary promo3Buttons ml-2"
                                                    disabled={disableSaveBtn}
                                                    onClick={() => {
                                                        simulateHandler()
                                                    }}
                                                >
                                                    Simulate
                                                </button> */}
                                            </div>
                                        </div>
                                    ) : null
                                }
                            </div>
                        ) :
                            (
                                <div className="container">
                                    <div className="drag-area"
                                        onDragOver={handleDragOver}
                                        onDragLeave={handleDragLeave}
                                        onDragEnter={handleDragEnter}
                                        onDrop={handleDrop}
                                    >
                                        <div className="upload-icon"><i class="fa fa-cloud-upload" aria-hidden="true"></i></div>
                                        <div className="upload-text" style={{ textAlign: 'center' }}>
                                            <h4 style={{ display: 'inline-block' }}>Drag and Drop CSV files here or&nbsp;
                                                <a href="#" className="upload-file" onClick={handleClick}>choose</a>&nbsp;
                                                <input type="file" name="file"
                                                    ref={hiddenFileInput}
                                                    accept=".csv"
                                                    onChange={handleChange}
                                                    hidden />
                                                files
                                            </h4>
                                            <p>(Recommended csv file size upto 10MB)</p>
                                        </div>
                                        <div className="download-file">
                                            <div style={{ color: "#007bff", cursor: "pointer" }} onClick={() => props.downloadUploadOfferCSV()} ><i className="fa fa-download"></i>&nbsp;Download csv template</div>
                                        </div>
                                    </div>
                                </div>
                            )
                    }
                </div>
                <div className="alertDivs">
                    {adgroupAddedAlert ? (
                        <AlertSuccess
                            message={isModify ? "Offer(s) modified successfully" : "Offer(s) added successfully"}
                            show={adgroupAddedAlert}
                        />
                    ) : null}
                    {uploadError ? (
                        <AlertDanger
                            message={props.uploadErrorMsg}
                            show={uploadError}
                        />
                    ) : null}
                    {somethingWrongAlert ? (
                        <AlertDanger
                            message={resMessage.toString()}
                            show={somethingWrongAlert}

                        />
                    ) : null}
                    {isSuccess ? (
                        <AlertSuccess
                            message={successMessage}
                            show={isSuccess}
                        />
                    ) : null}
                </div>
            </div>
        </PageLoader>
    )
}
UploadOffersModal.propTypes = {
    uploadFile: PropTypes.func.isRequired
}
const mapStateToProps = (state) => ({
    uploadData: state.calendar.uploadOfferData,
    loader: state.calendar.loader,
    uploadErrorMsg: state.calendar.uploadErrorMsg
})
export default connect(mapStateToProps, {
    uploadFile,
    unsetUploadErrorMessage,
    uploadFileToBulkModify,
    saveAdgroupFromIntermediate,
    getPromosData,
    downloadUploadOfferCSV,
    fetchRegSalesPrices
})(UploadOffersModal);