import moment from "moment";
import numeral from "numeral";
import { getBrowserTimeZone, valueFormatterHandler ,comparator } from "../../../utils/valueFormatters";

let tyLyCommonHeaders = [
  {
    headerName: "Adgroup Description",
    field: "adgroup_description",
    filter: "agTextColumnFilter",
    suppressSizeToFit: true,
    width: 250,
    valueGetter: emptyValueFormatterForString,
  },
  {
    headerName: "Merchant ID",
    field: "adgroup_merch_id",
    filter: "agTextColumnFilter",
    filterParams: { defaultOption: "equals" },
    suppressSizeToFit: true,
    valueGetter: emptyValueFormatterForString,
    width : 180
  },
  {
    headerName: "Shop",
    field: "shop_desc",
    filter: "agTextColumnFilter",
    valueGetter: emptyValueFormatterForString,
    suppressSizeToFit: true,
  },
  {
    headerName: "Event ID",
    field: "event_id",
    filter: "agTextColumnFilter",
    suppressSizeToFit: true,
    width: 250,
    valueGetter: function(params) {
      if(params.data && params.data.event_id){
        const eventId = params.data.event_id.toString(); // Convert event_id to string
        const paddedEventId = eventId.padStart(9, '0'); // Pad with zeros if less than 9 digits
        return paddedEventId;
      }else{
        return '-'
      }
      
    }
  },
  {
    headerName: "Adgroup Desc.",
    field: "adgroup_description",
    filter: "agTextColumnFilter",
    suppressSizeToFit: true,
    hide: true,
  },
  {
    headerName: "Promo Sign Shell",
    field: "promo_sign_shell",
    filter: "agTextColumnFilter",
    suppressSizeToFit: true,
    hide: true,
  },
  {
    headerName: "Label",
    field: "vendor_label",
    filter: "agTextColumnFilter",
    suppressSizeToFit: true,
    hide: true,
  },
  {
    headerName: "Sign Description",
    field: "sign_description",
    filter: "agTextColumnFilter",
    suppressSizeToFit: true,
    hide: true,
  },
  {
    headerName: "Subline",
    field: "subline",
    filter: "agTextColumnFilter",
    suppressSizeToFit: true,
    hide: true,
  },
  {
    headerName: "Start Date",
    field: "start_date",
    filter: "agTextColumnFilter",
    valueGetter: function (params) {
      // if (params.data.start_date && params.data.rank === "1") {
      //   return moment(params.data.start_date).format("MM-DD-YYYY");
      // }
      if (params.data.start_date ) {
        return moment(params.data.start_date).format("MM-DD-YYYY");
      }
      return "-";
    },
    suppressSizeToFit: true,
    sortable: true,
    comparator: function (date1, date2) {
      return comparator(date1, date2, "MM-DD-YYYY");
    },
    width : 150
  },
  {
    headerName: "End Date",
    field: "end_date",
    filter: "agTextColumnFilter",
    valueGetter: function (params) {
      // if (params.data.end_date && params.data.rank === "1") {
      //   return moment(params.data.end_date).format("MM-DD-YYYY");
      // }
      if (params.data.end_date ) {
        return moment(params.data.end_date).format("MM-DD-YYYY");
      }
      return "-";
    },
    suppressSizeToFit: true,
    sortable: true,
    comparator: function (date1, date2) {
      return comparator(date1, date2, "MM-DD-YYYY");
    },
    width : 150
  },
  {
    headerName: "TY No. of days",
    field: "no_of_days",
    hide: true,
    filter: "agTextColumnFilter",
    suppressSizeToFit: true,
    valueGetter: function (params) {
      // if (
      //   (params.data.no_of_days || params.data.no_of_days === 0) &&
      //   params.data.rank === "1"
      // ) {
      //   return params.data.no_of_days;
      // }
      if (
        (params.data.no_of_days || params.data.no_of_days === 0) //removed rank logic
      ) {
        return params.data.no_of_days;
      }
      return "-";
    },
  },
  {
    headerName: "Event",
    field: "promo_type",
    filter: "agTextColumnFilter",
    suppressSizeToFit: true,
    valueGetter: promoTypeFormatter,
    width : 150
  },
  {
    headerName: "UAS",
    field: "uas",
    filter: "agTextColumnFilter",
    suppressSizeToFit: true,
    valueGetter: function(params) {
      if (
        params.data.uas 
      ) {
        return offerFormatValidation(params.data.uas);
      }
      return "-";
    },
    width : 100
  },
  {
    headerName: "Promo Type",
    field: "promo_event_type",
    filter: "agTextColumnFilter",
    suppressSizeToFit: true,
    valueGetter: function(params) {
      if (
        params.data.promo_event_type 
      ) {
        return offerFormatValidation(params.data.promo_event_type);
      }
      return "-";
    },
    width : 150
  },
  {
    headerName: "Function",
    field: "getfun",
    hide: true,
  },
  {
    headerName: "Function2",
    field: "getfun2",
    hide: true,
  },
  {
    headerName: "Function3",
    field: "getfun3",
    hide: true,
  },
  {
    headerName: "Function4",
    field: "getfun4",
    hide: true,
  },
  {
    headerName: "Promo ID",
    field: "promo_id",
    suppressSizeToFit: true,
    hide: true,
  },
  {
    headerName: "Rank",
    field: "rank",
    suppressSizeToFit: true,
    hide: true,
  },
];
let lyHeaderBeforeZpes = [
  {
    headerName: "Adgroup",
    field: "adgroup_name",
    headerCheckboxSelection: null, //headerCheck
    width: 180,
    pinned: "left",
    filter: "agTextColumnFilter",
    suppressSizeToFit: true,
    cellStyle: null, //cellStyleMetho
  },
  ...tyLyCommonHeaders,
],
  lyHeaderAfterZpes = [
    {
      // headerName: "Planned Offer",
      headerName: "Promosmart Offer",
      field: "offer",
      filter: "agTextColumnFilter",
      suppressSizeToFit: true,
      filterParams: { defaultOption: "startsWith" },
      valueGetter: function (params) {
        // if (
        //   params.data.offer &&
        //   params.data.rank === "1"
        //   // && params.data.zpes_feed_indicator !== "deleted_from_tool_added_from_zpes"
        // ) {
        //   return offerFormatValidation(params.data.offer);
        // }
        if (
          params.data.offer
          // && params.data.zpes_feed_indicator !== "deleted_from_tool_added_from_zpes"
        ) {
          return offerFormatValidation(params.data.offer);
        }
        return "-";
      },
    },
    // {
    //   // headerName: "Planned Offer",
    //   headerName: "Actual Offer",
    //   field: "actual_offer",
    //   filter: "agTextColumnFilter",
    //   suppressSizeToFit: true,
    //   filterParams: { defaultOption: "startsWith" },
    //   valueGetter: function (params) {
    //     if (
    //       params.data.actual_offer &&
    //       params.data.rank === "1"
    //       // && params.data.zpes_feed_indicator !== "deleted_from_tool_added_from_zpes"
    //     ) {
    //       return offerFormatValidation(params.data.actual_offer);
    //     }
    //     return "-";
    //   },
    //   hide: true,
    // },
    {
      headerName: "Promosmart Offer Type",
      field: "offer_type",
      filter: "agTextColumnFilter",
      suppressSizeToFit: true,
      filterParams: { defaultOption: "startsWith" },
      valueGetter: function (params) {
        // if (params.data.offer_type && params.data.rank === "1") {
        //   return params.data.offer_type;
        // }
        if (params.data.offer_type) { //removed rank logic - 1 
          return params.data.offer_type;
        }
        return "-";
      },
    },
    {
      headerName: "Sku Count",
      field: "sku_count",
      filter: "agTextColumnFilter",
      suppressSizeToFit: true,
      filterParams: { defaultOption: "startsWith" },
      valueFormatter: valueFormatterHandler,
      hide: true,
    },
    {
      headerName: "*IA forecasted units",
      field: "ia_forecasted_units",
      filter: "agTextColumnFilter",
      suppressSizeToFit: true,
      filterParams: { defaultOption: "equals" },
      valueFormatter: rankCheckIA,
    },
    {
      headerName: "Total Units Override",
      field: "total_override_units",
      filter: "agTextColumnFilter",
      suppressSizeToFit: true,
      filterParams: { defaultOption: "equals" },
      editable: false,
      valueFormatter: rankCheck,
      valueGetter: commonOverrrideValueGetter,
      cellStyle: function (params) {
        if (params) {
          let isAfter = moment(new Date()).isAfter(
            params.data.campaign_start_date
          );
          if (isAfter) {
            return {
              pointerEvents: "none",
            };
          }
          return {
            borderColor: "#d3d3d3",
            borderRight: "3px solid #d3d3d3 !important",
            borderLeft: "2px solid #d3d3d3 !important",
          };
        }
      },
      // cellEditor: "numericEditor"
    },
    {
      headerName: "Total Forecasted Units", //headerStar
      field: "total_forecasted_units",
      filter: "agTextColumnFilter",
      suppressSizeToFit: true,
      valueFormatter: rankCheck,
      filterParams: { defaultOption: "equals" },
    },
    {
      headerName: "UPDATE",
      field: "update_flag",
      hide: true,
    },
    {
      header: "Original Update flag",
      field: "original_update_flag",
      hide: true,
    },
    {
      headerName: "Total Actual Units", //headerStar
      field: "total_actual_units",
      filter: "agTextColumnFilter",
      suppressSizeToFit: true,
      valueFormatter: rankCheck,
      filterParams: { defaultOption: "startsWith" },
      hide: true,
    },
    {
      headerName: "Total Adjusted Forecasted Units", //headerStar
      field: "total_adjusted_units",
      filter: "agTextColumnFilter",
      suppressSizeToFit: true,
      valueFormatter: rankCheck,
      filterParams: { defaultOption: "startsWith" },
      hide: true,
    },
    {
      headerName: "*IA forecasted sales($)",
      field: "ia_forecasted_sales",
      filter: "agTextColumnFilter",
      suppressSizeToFit: true,
      filterParams: { defaultOption: "equals" },
      valueFormatter: rankCheckWithDollaIA,
    },
    {
      headerName: "Total Sales Override($)",
      field: "total_override_sales",
      filter: "agTextColumnFilter",
      suppressSizeToFit: true,
      filterParams: { defaultOption: "equals" },
      editable: false,
      valueFormatter: function (params) {
        // if ((params.value || params.value === 0) && params.data.rank === "1") {
        //   let value = params.value;
        //   return value === null || value === ""
        //     ? ""
        //     : value > 0
        //       ? "$ " + numeral(value).format("0,0")
        //       : 0;
        // }
        if (params.value || params.value === 0) { //removed rank logic , if in future they ask for rank logic , uncomment the above code
          let value = params.value;
          return value === null || value === ""
            ? ""
            : value > 0
              ? "$ " + numeral(value).format("0,0")
              : 0;
        }
        return "";
      },
      valueGetter: commonOverrrideValueGetter,
      cellStyle: function (params) {
        if (params) {
          let isAfter = moment(new Date()).isAfter(
            params.data.campaign_start_date
          );
          if (isAfter) {
            return {
              pointerEvents: "none",
            };
          }
          return {
            borderColor: "#d3d3d3",
            borderRight: "3px solid #d3d3d3 !important",
            borderLeft: "2px solid #d3d3d3 !important",
          };
        }
      },
      // cellEditor: "decimalNumericEditor"
    },
    {
      headerName: "Total Forecasted Sales($)", //headerStar
      field: "total_forecasted_sales",
      filter: "agTextColumnFilter",
      suppressSizeToFit: true,
      valueFormatter: rankCheckWithDolla,
      filterParams: { defaultOption: "equals" },
    },
    {
      headerName: "Total Actual Sales($)", //headerStar
      field: "total_actual_sales",
      filter: "agTextColumnFilter",
      suppressSizeToFit: true,
      valueFormatter: rankCheckWithDolla,
      filterParams: { defaultOption: "startsWith" },
      hide: true,
    },
    {
      headerName: "Total Adjusted Forecasted Sales($)", //headerStar
      field: "total_adjusted_sales",
      filter: "agTextColumnFilter",
      suppressSizeToFit: true,
      valueFormatter: rankCheckWithDolla,
      filterParams: { defaultOption: "startsWith" },
      hide: true,
    },
    {
      headerName: "*IA forecasted Margin($)",
      field: "ia_forecasted_margin",
      filter: "agTextColumnFilter",
      suppressSizeToFit: true,
      filterParams: { defaultOption: "equals" },
      valueFormatter: rankCheckWithDollaIA,
      width : 250
    },
    {
      headerName: "Total Margin Override($)",
      field: "total_override_margin",
      filter: "agTextColumnFilter",
      suppressSizeToFit: true,
      filterParams: { defaultOption: "equals" },
      editable: false,
      valueFormatter: function (params) {
        // if ((params.value || params.value === 0) && params.data.rank === "1") {
        //   let value = params.value;
        //   return value === null || value === ""
        //     ? ""
        //     : value > 0
        //       ? "$ " + numeral(value).format("0,0")
        //       : 0;
        // }
        if (params.value || params.value === 0) {
          let value = params.value;
          return value === null || value === ""
            ? ""
            : value > 0
              ? "$ " + numeral(value).format("0,0")
              : 0;
        }
        return "";
      },
      valueGetter: commonOverrrideValueGetter,
      cellStyle: function (params) {
        if (params) {
          let isAfter = moment(new Date()).isAfter(
            params.data.campaign_start_date
          );
          if (isAfter) {
            return {
              pointerEvents: "none",
            };
          }
          return {
            borderColor: "#d3d3d3",
            borderRight: "3px solid #d3d3d3 !important",
            borderLeft: "2px solid #d3d3d3 !important",
          };
        }
      },
      width : 250
      // cellEditor: "decimalNumericEditor"
    },
    {
      headerName: "Total Forecasted Margin($)", //headerStar
      field: "total_forecasted_margin",
      filter: "agTextColumnFilter",
      suppressSizeToFit: true,
      valueFormatter: rankCheckWithDolla,
      filterParams: { defaultOption: "equals" },
    },

    {
      headerName: "Total Actual Margin($)", //headerStar
      field: "total_actual_margin",
      filter: "agTextColumnFilter",
      suppressSizeToFit: true,
      valueFormatter: rankCheckWithDolla,
      filterParams: { defaultOption: "startsWith" },
    },

    {
      headerName: "Total Adjusted Forecasted Margin($)", //headerStar
      field: "total_adjusted_margin",
      filter: "agTextColumnFilter",
      suppressSizeToFit: true,
      valueFormatter: rankCheckWithDolla,
      filterParams: { defaultOption: "startsWith" },
      hide: true,
    },
    {
      headerName: "*IA Forecasted GM (%)",
      field: "ia_forecasted_gm",
      filter: "agTextColumnFilter",
      suppressSizeToFit: true,
      valueFormatter: function (params) {
        // if (params.data.ia_forecasted_gm && params.data.rank === "1") {
        //   let sign = Math.sign(params.data.ia_forecasted_gm);
        //   let valueVar = Math.round(params.data.ia_forecasted_gm * 100) / 100;
        //   return sign === 1 || sign === 0
        //     ? numeral(valueVar).format("0.00") + "%"
        //     : numeral(valueVar).format("(0.00)") + "%";
        // }
        if (params.data.ia_forecasted_gm) {
          let sign = Math.sign(params.data.ia_forecasted_gm);
          let valueVar = Math.round(params.data.ia_forecasted_gm * 100) / 100;
          return sign === 1 || sign === 0
            ? numeral(valueVar).format("0.00") + "%"
            : numeral(valueVar).format("(0.00)") + "%";
        }
        return "-";
      },
      filterParams: { defaultOption: "equals" },
    },
    {
      headerName: "Total GM Override(%)",
      field: "gm_override",
      filter: "agTextColumnFilter",
      suppressSizeToFit: true,
      valueFormatter: function (params) {
        if (
          // !params.data.rank !== "1" || !params.data.total_override_units
          !params.data.total_override_units
          ) {
          return "";
        } else if (
          // (params.data.gm_override || params.data.gm_override === 0) &&
          // params.data.rank === "1"
          (params.data.gm_override || params.data.gm_override === 0) 
        ) 
        {
          let sign = Math.sign(params.data.gm_override);
          let valueVar = Math.round(params.data.gm_override * 100) / 100;
          return sign === 1 || sign === 0
            ? numeral(valueVar).format("0.00") + "%"
            : numeral(valueVar).format("(0.00)") + "%";
        } else if (!params.data.gm_override) {
          return "";
        }
        return "-";
      },
      filterParams: { defaultOption: "equals" },
      editable: false,
      cellStyle: function (params) {
        if (params) {
          let isAfter = moment(new Date()).isAfter(
            params.data.campaign_start_date
          );
          if (isAfter) {
            return {
              pointerEvents: "none",
            };
          }
          return {
            borderColor: "#d3d3d3",
            borderRight: "3px solid #d3d3d3 !important",
            borderLeft: "2px solid #d3d3d3 !important",
          };
        }
      },
      // cellEditor: "decimalNumericEditor"
    },
    {
      headerName: "Forecasted GM (%)",
      field: "forecasted_gm",
      filter: "agTextColumnFilter",
      suppressSizeToFit: true,
      valueFormatter: function (params) {
        if (
          // (params.data.forecasted_gm || params.data.forecasted_gm === 0) &&
          // params.data.rank === "1"
          params.data.forecasted_gm || params.data.forecasted_gm === 0
        ) {
          let sign = Math.sign(params.data.forecasted_gm);
          let valueVar = Math.round(params.data.forecasted_gm * 100) / 100;
          return sign === 1 || sign === 0
            ? numeral(valueVar).format("0.00") + "%"
            : numeral(valueVar).format("(0.00)") + "%";
        } else if (!params.data.forecasted_gm) {
          return "-";
        }
        return "-";
      },
      filterParams: { defaultOption: "equals" },
    },
    {
      headerName: "Actual GM (%)",
      field: "actual_gm",
      filter: "agTextColumnFilter",
      suppressSizeToFit: true,
      valueFormatter: function (params) {
        if (
          // (params.data.actual_gm || params.data.actual_gm === 0) &&
          // params.data.rank === "1"
          params.data.actual_gm || params.data.actual_gm === 0
          
        ) {
          let sign = Math.sign(params.data.actual_gm);
          let valueVar = Math.round(params.data.actual_gm * 100) / 100;
          return sign === 1 || sign === 0
            ? numeral(valueVar).format("0.00") + "%"
            : numeral(valueVar).format("(0.00)") + "%";
        }
        return "-";
      },
      filterParams: { defaultOption: "startsWith" },
      hide: true,
    },
    {
      headerName: "Adjusted GM (%)",
      field: "adjusted_gm",
      filter: "agTextColumnFilter",
      suppressSizeToFit: true,
      valueFormatter: function (params) {
        if (
          // (params.data.adjusted_gm || params.data.adjusted_gm === 0) &&
          // params.data.rank === "1"
          params.data.adjusted_gm || params.data.adjusted_gm === 0
        ) {
          // let valueVar =
          //   params.data.original_update_flag === 2
          //     ? Math.round(params.data.gm_actual * 100) / 100 + " %"
          //     : Math.round(params.data.gm * 100) / 100 + " %";
          let sign = Math.sign(params.data.adjusted_gm);
          let valueVar = Math.round(params.data.adjusted_gm * 100) / 100;
          return sign === 1 || sign === 0
            ? numeral(valueVar).format("0.00") + "%"
            : numeral(valueVar).format("(0.00)") + "%";
        }
        return "-";
      },
      filterParams: { defaultOption: "startsWith" },
      hide: true,
    },
    {
      headerName: "LY Start Date",
      field: "ly_start_date",
      filter: "agTextColumnFilter",
      valueGetter: function (params) {
        if (params.data.ly_start_date) {
          // return getBrowserTimeZone(params.data.ly_start_date,"MM-DD-YYYY");
          return params.data.ly_start_date;
        }
        return "-";
      },
      headerClass: "gray-header-bg",
      suppressSizeToFit: true,
      filterParams: { defaultOption: "startsWith" },
      sortable: true,
      comparator: function (date1, date2) {
        return comparator(date1, date2, "MM-DD-YYYY");
      },
    },
    {
      headerName: "LY End Date",
      field: "ly_end_date",
      filter: "agTextColumnFilter",
      valueGetter: function (params) {
        if (params.data.ly_end_date) {
          // return getBrowserTimeZone(params.data.ly_end_date,"MM-DD-YYYY");
          return params.data.ly_end_date;
        }
        return "-";
      },
      headerClass: "gray-header-bg",
      suppressSizeToFit: true,
      filterParams: { defaultOption: "startsWith" },
      sortable: true,
      comparator: function (date1, date2) {
        return comparator(date1, date2, "MM-DD-YYYY");
      },
    },
    {
      headerName: "LY Offer",
      field: "ly_offer",
      filter: "agTextColumnFilter",
      valueGetter: function (params) {
        if (params.data.ly_offer) {
          return offerFormatValidation(params.data.ly_offer);
        }
        return "-";
      },
      headerClass: "gray-header-bg",
      suppressSizeToFit: true,
      filterParams: { defaultOption: "startsWith" },
    },
    {
      headerName: "LY Offer Type",
      field: "ly_offer_type",
      filter: "agTextColumnFilter",
      valueGetter: function (params) {
        if (params.data.ly_offer_type) {
          return params.data.ly_offer_type;
        }
        return "-";
      },
      headerClass: "gray-header-bg",
      suppressSizeToFit: true,
      filterParams: { defaultOption: "startsWith" },
    },
    {
      headerName: "LY Event Type",
      field: "ly_event_type",
      filter: "agTextColumnFilter",
      // valueFormatter: function(params) {
      //   return params.value ? params.value : "-";
      // },
      valueGetter: promoTypeFormatterLY,
      headerClass: "gray-header-bg",
      suppressSizeToFit: true,
      filterParams: { defaultOption: "startsWith" },
    },
    {
      headerName: "LY No. of days",
      field: "ly_no_of_days",
      filter: "agTextColumnFilter",
      hide: true,
      // valueFormatter: function(params) {
      //   return params.value ? params.value : "-";
      // },
      headerClass: "gray-header-bg",
      suppressSizeToFit: true,
      filterParams: { defaultOption: "startsWith" },
    },
    {
      headerName: "LY Total Units",
      field: "ly_total_units",
      filter: "agTextColumnFilter",
      valueFormatter: valueFormatterHandler,
      suppressSizeToFit: true,
      headerClass: "gray-header-bg",
      filterParams: { defaultOption: "equals" },
    },
    {
      headerName: "LY Total Sales($)",
      field: "ly_total_sales",
      filter: "agTextColumnFilter",
      valueFormatter: valueFormatterHandler,
      suppressSizeToFit: true,
      headerClass: "gray-header-bg",
      filterParams: { defaultOption: "equals" },
    },
    {
      headerName: "LY Total Margin($)",
      field: "ly_total_margin",
      filter: "agTextColumnFilter",
      valueFormatter: valueFormatterHandler,
      suppressSizeToFit: true,
      headerClass: "gray-header-bg",
      filterParams: { defaultOption: "equals" },
    },
    {
      headerName: "LY GM %",
      field: "ly_gm",
      filter: "agTextColumnFilter",
      valueFormatter: function (params) {
        if (params.data.ly_gm || params.data.ly_gm === 0) {
          let sign = Math.sign(params.data.ly_gm);
          if (sign === 1 || sign === 0) {
            return params.data.ly_gm.toFixed(2) + " %";
          }
          return numeral(params.data.ly_gm).format("(0.00)") + " %";
        }
        return "-";
      },
      headerClass: "gray-header-bg",
      suppressSizeToFit: true,
      filterParams: { defaultOption: "equals" },
    },
  ],
  tyHeaderBeforeZpes = [
    {
      headerName: "Adgroup",
      field: "adgroup_name",
      headerCheckboxSelection: null, //headerCheck
      width: 180,
      pinned: "left",
      filter: "agTextColumnFilter",
      suppressSizeToFit: true,
      cellStyle: function (params) {
        if ((params.data.campaign_start_date > new Date(moment().format("MM-DD-YYYY"))) || moment(params.data.end_date).isSameOrAfter(moment(), 'day')) {
          return {
            color: "#2d69eb",
            cursor: "pointer",
          }
        } else {
          return {
            color: "black",
            textDecoration: "none",
          }
        }
      }, //cellStyleMethod
      cellClass: function (rowNode) {
        return (rowNode.data &&
          moment(rowNode.data.campaign_start_date).isAfter(moment()) &&
          rowNode.data.rank > 1) ||
          (rowNode.data && rowNode.data.promo_id === null)
          ? "adgrp-checkbox-disabled"
          : "";
      },
      checkboxSelection: params => {
        if (moment(params.data.end_date).isSameOrAfter(moment(), 'day')) {
          return true
        } else {
          return false
        }
      },
      cellRenderer: "adgroupNameRendrer",
    },
    ...tyLyCommonHeaders,
  ],
  tyHeaderAfterZpes = [
    {
      // headerName: "Planned Offer",
      headerName: "Offer",
      field: "offer",
      filter: "agTextColumnFilter",
      suppressSizeToFit: true,
      filterParams: { defaultOption: "startsWith" },
      valueGetter: function (params) {
        if (
          // params.data.offer &&
          // params.data.rank === "1"
          params.data.offer 
          // && params.data.zpes_feed_indicator !== "deleted_from_tool_added_from_zpes"
        ) {
          return offerFormatValidation(params.data.offer, params.data.offer_type);
        }
        return "-";
      },
      width : 180
    },
    // {
    //   // headerName: "Planned Offer",
    //   headerName: "Actual Offer",
    //   field: "actual_offer",
    //   filter: "agTextColumnFilter",
    //   suppressSizeToFit: true,
    //   filterParams: { defaultOption: "startsWith" },
    //   valueGetter: function (params) {
    //     if (
    //       params.data.actual_offer &&
    //       params.data.rank === "1"
    //       // && params.data.zpes_feed_indicator !== "deleted_from_tool_added_from_zpes"
    //     ) {
    //       return offerFormatValidation(params.data.actual_offer);
    //     }
    //     return "-";
    //   },
    //   hide: true,
    // },
    {
      headerName: "Offer Type",
      field: "offer_type",
      filter: "agTextColumnFilter",
      suppressSizeToFit: true,
      filterParams: { defaultOption: "startsWith" },
      valueGetter: function (params) {
        // if (params.data.offer_type && params.data.rank === "1") {
        //   return params.data.offer_type;
        // }
        if (params.data.offer_type) {
          return params.data.offer_type;
        }
        return "-";
      },
      width : 150
    },
    {
      headerName: "Sku Count",
      field: "sku_count",
      filter: "agTextColumnFilter",
      suppressSizeToFit: true,
      filterParams: { defaultOption: "startsWith" },
      valueFormatter: valueFormatterHandler,
      hide: true,
    },
    {
      headerName: "*IA forecasted units",
      field: "ia_forecasted_units",
      filter: "agTextColumnFilter",
      suppressSizeToFit: true,
      filterParams: { defaultOption: "startsWith" },
      valueFormatter: rankCheckIA,
    },
    {
      headerName: "Total Units Override",
      field: "total_override_units",
      filter: "agTextColumnFilter",
      suppressSizeToFit: true,
      filterParams: { defaultOption: "startsWith" },
      editable:
        (navigator.cookieEnabled && localStorage.getItem("role") === "3") ||
          (navigator.cookieEnabled && localStorage.getItem("role") === "5")
          ? false
          : true,
      valueFormatter: function (params) {
        // if (params.value && params.data.rank === "1") {
        //   let value = params.value;
        //   return value === null || value === ""
        //     ? ""
        //     : value > 0
        //       ? numeral(value).format("0,0")
        //       : 0;
        // }
        if (params.value) {
          let value = params.value;
          return value === null || value === ""
            ? ""
            : value > 0
              ? numeral(value).format("0,0")
              : 0;
        }
        return "";
      },
      valueGetter: commonOverrrideValueGetter,
      cellStyle: function (params) {
        if (params) {
          let isAfter = moment(new Date()).isAfter(
            params.data.campaign_start_date
          );
          let startDate = moment(new Date()).isAfter(
            params.data.start_date
          );
          // if ((isAfter && startDate) || params.data.rank !== "1") {
          //   return {
          //     pointerEvents: "none",
          //     // backgroundColor: "#EEEEE8",
          //   };
          // }
          if (isAfter && startDate) {
            return {
              pointerEvents: "none",
              // backgroundColor: "#EEEEE8",
            };
          }
          return {
            borderColor: "#d3d3d3",
            borderRight: "3px solid #d3d3d3 !important",
            borderLeft: "2px solid #d3d3d3 !important",
          };
        }
      },
      // cellEditor: "numericEditor"
    },
    {
      headerName: "Total Forecasted Units", //headerStar
      field: "total_forecasted_units",
      filter: "agTextColumnFilter",
      suppressSizeToFit: true,
      valueFormatter: rankCheck,
      filterParams: { defaultOption: "startsWith" },
      width : 230
    },
    {
      headerName: "UPDATE",
      field: "update_flag",
      hide: true,
    },
    {
      header: "Original Update flag",
      field: "original_update_flag",
      hide: true,
    },
    {
      headerName: "Total Actual Units", //headerStar
      field: "total_actual_units",
      filter: "agTextColumnFilter",
      suppressSizeToFit: true,
      valueFormatter: rankCheck,
      filterParams: { defaultOption: "startsWith" },
      hide: true,
    },
    {
      headerName: "Total Adjusted Forecasted Units", //headerStar
      field: "total_adjusted_units",
      filter: "agTextColumnFilter",
      suppressSizeToFit: true,
      valueFormatter: rankCheck,
      filterParams: { defaultOption: "startsWith" },
      hide: true,
    },
    {
      headerName: "*IA forecasted sales($)",
      field: "ia_forecasted_sales",
      filter: "agTextColumnFilter",
      suppressSizeToFit: true,
      filterParams: { defaultOption: "startsWith" },
      valueFormatter: rankCheckWithDollaIA,
      width : 250
    },
    {
      headerName: "Total Sales Override($)",
      field: "total_override_sales",
      filter: "agTextColumnFilter",
      suppressSizeToFit: true,
      filterParams: { defaultOption: "startsWith" },
      // editable:
      //   localStorage.getItem("role") === "3" ||
      //   localStorage.getItem("role") === "5"
      //     ? false
      //     : true,
      // valueFormatter: function(params) {
      //   if ((params.value || params.value === 0) && params.data.rank === "1") {
      //     let value = params.value;
      //     return value === null || value === ""
      //       ? ""
      //       : value > 0
      //       ? "$ " + numeral(value).format("0,0")
      //       : 0;
      //   }
      //   return "";
      // },
      // valueGetter: aPlusBValueGetterCommon,
      cellStyle: function (params) {
        if (params) {
          let isAfter = moment(new Date()).isAfter(
            params.data.campaign_start_date
          );
          let startDate = moment(new Date()).isAfter(
            params.data.start_date
          );
          if (isAfter && startDate) {
            return {
              pointerEvents: "none",
            };
          }
          return {
            borderColor: "#d3d3d3",
            borderRight: "3px solid #d3d3d3 !important",
            borderLeft: "2px solid #d3d3d3 !important",
          };
        }
      },
      cellRenderer: "pipelineCellRenderer",
      // cellEditor: "numericEditor"
      width : 250
    },
    {
      headerName: "Total Forecasted Sales($)", //headerStar
      field: "total_forecasted_sales",
      filter: "agTextColumnFilter",
      suppressSizeToFit: true,
      valueFormatter: rankCheckWithDolla,
      filterParams: { defaultOption: "startsWith" },
      width : 250
    },
    {
      headerName: "Total Actual Sales($)", //headerStar
      field: "total_actual_sales",
      filter: "agTextColumnFilter",
      suppressSizeToFit: true,
      valueFormatter: rankCheckWithDolla,
      filterParams: { defaultOption: "startsWith" },
      hide: true,
    },
    {
      headerName: "Total Adjusted Forecasted Sales($)", //headerStar
      field: "total_adjusted_sales",
      filter: "agTextColumnFilter",
      suppressSizeToFit: true,
      valueFormatter: rankCheckWithDolla,
      filterParams: { defaultOption: "startsWith" },
      hide: true,
    },
    {
      headerName: "*IA forecasted Margin($)",
      field: "ia_forecasted_margin",
      filter: "agTextColumnFilter",
      suppressSizeToFit: true,
      filterParams: { defaultOption: "startsWith" },
      valueFormatter: rankCheckWithDollaIA,
      width : 250
    },
    {
      headerName: "Total Margin Override($)",
      field: "total_override_margin",
      filter: "agTextColumnFilter",
      suppressSizeToFit: true,
      filterParams: { defaultOption: "startsWith" },
      // editable:
      //   localStorage.getItem("role") === "3" ||
      //   localStorage.getItem("role") === "5"
      //     ? false
      //     : true,
      // valueFormatter: function(params) {
      //   if ((params.value || params.value === 0) && params.data.rank === "1") {
      //     let value = params.value;
      //     return value === null || value === ""
      //       ? ""
      //       : value > 0
      //       ? "$ " + numeral(value).format("0,0")
      //       : 0;
      //   }
      //   return "";
      // },
      // valueGetter: aPlusBValueGetterCommon,
      cellStyle: function (params) {
        if (params) {
          let isAfter = moment(new Date()).isAfter(
            params.data.campaign_start_date
          );
          let startDate = moment(new Date()).isAfter(
            params.data.start_date
          );
          if (isAfter && startDate) {
            return {
              pointerEvents: "none",
            };
          }
          return {
            borderColor: "#d3d3d3",
            borderRight: "3px solid #d3d3d3 !important",
            borderLeft: "2px solid #d3d3d3 !important",
          };
        }
      },
      cellRenderer: "pipelineCellRenderer",
       width : 250
      // cellEditor: "numericEditor"
    },
    {
      headerName: "Total Forecasted Margin($)", //headerStar
      field: "total_forecasted_margin",
      filter: "agTextColumnFilter",
      suppressSizeToFit: true,
      valueFormatter: rankCheckWithDolla,
      filterParams: { defaultOption: "startsWith" },
      width : 250
    },

    {
      headerName: "Total Actual Margin($)", //headerStar
      field: "total_actual_margin",
      filter: "agTextColumnFilter",
      suppressSizeToFit: true,
      valueFormatter: rankCheckWithDolla,
      filterParams: { defaultOption: "startsWith" },
    },

    {
      headerName: "Total Adjusted Forecasted Margin($)", //headerStar
      field: "total_adjusted_margin",
      filter: "agTextColumnFilter",
      suppressSizeToFit: true,
      valueFormatter: rankCheckWithDolla,
      filterParams: { defaultOption: "startsWith" },
      hide: true,
    },
    {
      headerName: "*IA Forecasted GM (%)",
      field: "ia_forecasted_gm",
      filter: "agTextColumnFilter",
      suppressSizeToFit: true,
      valueFormatter: function (params) {
        if (
          // params.data.ia_forecasted_gm && params.data.rank === "1"
          params.data.ia_forecasted_gm
          ) {
          let sign = Math.sign(params.data.ia_forecasted_gm);
          let valueVar = Math.round(params.data.ia_forecasted_gm * 100) / 100;
          return sign === 1 || sign === 0
            ? numeral(valueVar).format("0.00") + "%"
            : numeral(valueVar).format("(0.00)") + "%";
        } else if (!params.data.ia_forecasted_gm) {
          return "-";
        }
        return "-";
      },
      // valueFormatter: function(params) {
      //   if (params.value && params.data.rank === "1") {
      //     let sign = Math.sign(params.data.ia_forecasted_gm);
      //     let valueVar = Math.round(params.data.ia_forecasted_gm * 100) / 100;

      //     return sign === 1 || sign === 0
      //       ? `${valueVar} %`
      //       : `${numeral(valueVar).format("(0,0)")} %`;
      //   }
      //   return "-";
      // },
      filterParams: { defaultOption: "startsWith" },
    },
    {
      headerName: "Total GM Override(%)",
      field: "gm_override",
      filter: "agTextColumnFilter",
      suppressSizeToFit: true,
      // valueFormatter: function(params) {
      //   if ((params.value || params.value === 0) && params.data.rank === "1") {
      //     return params.value + " %";
      //   }
      //   return "";
      // },
      // valueGetter: function(params) {
      //   if (
      //     (params.data.gm_override || params.data.gm_override === 0) &&
      //     params.data.rank === "1"
      //   ) {
      //     let value = params.data.gm_override;
      //     let val =
      //       typeof value === "string" && value.includes("$")
      //         ? value.replace("$", "")
      //         : value;
      //     let sign = Math.sign(val);
      //     let valueVar = Math.round(val * 100) / 100;
      //     return sign === 1 || sign === 0
      //       ? numeral(valueVar).format("0.00")
      //       : numeral(valueVar).format("(0.00)");
      //   } else if (!params.data.gm_override) {
      //     return params.data.gm_override === 0 ? "0" : "";
      //   }
      //   return "-";
      // },
      filterParams: { defaultOption: "startsWith" },
      // editable: false,
      cellStyle: function (params) {
        if (params) {
          let isAfter = moment(new Date()).isAfter(
            params.data.campaign_start_date
          );
          let startDate = moment(new Date()).isAfter(
            params.data.start_date
          );
          if (isAfter && startDate) {
            return {
              pointerEvents: "none",
            };
          }
          return {
            borderColor: "#d3d3d3",
            borderRight: "3px solid #d3d3d3 !important",
            borderLeft: "2px solid #d3d3d3 !important",
          };
        }
      },
      cellRenderer: "pipelineCellRenderer",
      // cellEditor: "decimalNumericEditor"
    },
    {
      headerName: "Forecasted GM (%)",
      field: "forecasted_gm",
      filter: "agTextColumnFilter",
      suppressSizeToFit: true,
      valueFormatter: function (params) {
        if (
          // (params.data.forecasted_gm || params.data.forecasted_gm === 0) &&
          // params.data.rank === "1"
          params.data.forecasted_gm || params.data.forecasted_gm === 0
        ) {
          let sign = Math.sign(params.data.forecasted_gm);
          let valueVar = Math.round(params.data.forecasted_gm * 100) / 100;
          return sign === 1 || sign === 0
            ? numeral(valueVar).format("0.00") + "%"
            : numeral(valueVar).format("(0.00)") + "%";
        } else if (!params.data.forecasted_gm) {
          return "-";
        }
        return "-";
      },
      // valueFormatter: function(params) {
      //   if (params.value && params.data.rank === "1") {
      //     let valueVar =
      //       Math.round(params.data.forecasted_gm * 100) / 100 + " %";
      //     return valueVar;
      //   }
      //   return "-";
      // },
      filterParams: { defaultOption: "startsWith" },
    },
    {
      headerName: "Actual GM (%)",
      field: "actual_gm",
      filter: "agTextColumnFilter",
      suppressSizeToFit: true,
      valueFormatter: function (params) {
        if (
          // (params.data.actual_gm || params.data.actual_gm === 0) &&
          // params.data.rank === "1"
          params.data.actual_gm || params.data.actual_gm === 0
        ) {
          let sign = Math.sign(params.data.actual_gm);
          let valueVar = Math.round(params.data.actual_gm * 100) / 100;
          return sign === 1 || sign === 0
            ? numeral(valueVar).format("0.00") + "%"
            : numeral(valueVar).format("(0.00)") + "%";
          // return Math.round(params.data.actual_gm * 100) / 100 + "%";
        }
        return "-";
      },
      filterParams: { defaultOption: "startsWith" },
      hide: true,
    },
    {
      headerName: "Adjusted GM (%)",
      field: "adjusted_gm",
      filter: "agTextColumnFilter",
      suppressSizeToFit: true,
      valueFormatter: function (params) {
        if (
          // (params.value || params.value === 0) && params.data.rank === "1"
          params.value || params.value === 0
        ) {
          // let valueVar =
          //   params.data.original_update_flag === 2
          //     ? Math.round(params.data.gm_actual * 100) / 100 + " %"
          //     : Math.round(params.data.gm * 100) / 100 + " %";
          let sign = Math.sign(params.data.adjusted_gm);
          let valueVar = Math.round(params.data.adjusted_gm * 100) / 100;
          return sign === 1 || sign === 0
            ? numeral(valueVar).format("0.00") + "%"
            : numeral(valueVar).format("(0.00)") + "%";
        }
        return "-";
      },
      filterParams: { defaultOption: "startsWith" },
      hide: true,
    },
    {
      headerName: "Confidence",
      field: "confidence",
      filter: "agTextColumnFilter",
      suppressSizeToFit: true,
      headerTooltip: `
High: More than 85% of these forecasts will have less than 20% error.
Medium: Nearly 30% of these forecasts have more than 20% error and some manual intervention might be required 
            to adjust the forecast depending on the reason, which include:
            • The promo or event is planned only for 1 day
            • The adgroup has less than 10 SKUs
            • The adgroup contains a high number of new and/or seasonal SKUs
Low:  The chance of the forecast being within the 20% range of error is just 50%. Occurs when >80% of SKUs have a 
            sales history of fewer than 90 days.`,
      tooltipShowDelay: 0,
    },
    {
      headerName: "LY Start Date",
      field: "ly_start_date",
      filter: "agTextColumnFilter",
      valueGetter: function (params) {
        if (params.data.ly_start_date) {
          // return getBrowserTimeZone(params.data.ly_start_date,"MM-DD-YYYY");
          return params.data.ly_start_date;
        }
        return "-";
      },
      headerClass: "gray-header-bg",
      suppressSizeToFit: true,
      filterParams: { defaultOption: "startsWith" },
      sortable: true,
      comparator: function (date1, date2) {
      if (date1 === "-" || date2 === "-") {
        return date1 === date2 ? 0 : date1 === "-" ? 1 : -1;
      }
      const parsedDate1 = moment(date1, "MM-DD-YYYY").toDate();
      const parsedDate2 = moment(date2, "MM-DD-YYYY").toDate();
  
      return parsedDate1 - parsedDate2;
    },
    },
    {
      headerName: "LY End Date",
      field: "ly_end_date",
      filter: "agTextColumnFilter",
      valueGetter: function (params) {
        if (params.data.ly_end_date) {
          // return moment(params.data.ly_end_date).format("MM-DD-YYYY");
          return params.data.ly_end_date;
        }
        return "-";
      },
      headerClass: "gray-header-bg",
      suppressSizeToFit: true,
      filterParams: { defaultOption: "startsWith" },
      sortable: true,
      comparator: function (date1, date2) {
      if (date1 === "-" || date2 === "-") {
        return date1 === date2 ? 0 : date1 === "-" ? 1 : -1;
      }
      const parsedDate1 = moment(date1, "MM-DD-YYYY").toDate();
      const parsedDate2 = moment(date2, "MM-DD-YYYY").toDate();
  
      return parsedDate1 - parsedDate2;
    },
    },
    {
      headerName: "LY Offer",
      field: "ly_offer",
      filter: "agTextColumnFilter",
      valueGetter: function (params) {
        if (params.data.ly_offer) {
          return offerFormatValidation(params.data.ly_offer);
        }
        return "-";
      },
      headerClass: "gray-header-bg",
      suppressSizeToFit: true,
      filterParams: { defaultOption: "startsWith" },
    },
    {
      headerName: "LY Offer Type",
      field: "ly_offer_type",
      filter: "agTextColumnFilter",
      valueGetter: function (params) {
        if (params.data.ly_offer_type) {
          return params.data.ly_offer_type;
        }
        return "-";
      },
      headerClass: "gray-header-bg",
      suppressSizeToFit: true,
      filterParams: { defaultOption: "startsWith" },
    },
    {
      headerName: "LY Event Type",
      field: "ly_event_type",
      filter: "agTextColumnFilter",
      // valueFormatter: function(params) {
      //   return params.value ? params.value : "-";
      // },
      valueGetter: promoTypeFormatterLY,
      headerClass: "gray-header-bg",
      suppressSizeToFit: true,
      filterParams: { defaultOption: "startsWith" },
    },
    {
      headerName: "LY No. of days",
      field: "ly_no_of_days",
      filter: "agTextColumnFilter",
      hide: true,
      // valueFormatter: function(params) {
      //   return params.value ? params.value : "-";
      // },
      headerClass: "gray-header-bg",
      suppressSizeToFit: true,
      filterParams: { defaultOption: "startsWith" },
    },
    {
      headerName: "LY Total Units",
      field: "ly_total_units",
      filter: "agTextColumnFilter",
      valueFormatter: valueFormatterHandler,
      suppressSizeToFit: true,
      headerClass: "gray-header-bg",
      filterParams: { defaultOption: "startsWith" },
    },
    {
      headerName: "LY Total Sales($)",
      field: "ly_total_sales",
      filter: "agTextColumnFilter",
      valueFormatter: valueFormatterHandler,
      suppressSizeToFit: true,
      headerClass: "gray-header-bg",
      filterParams: { defaultOption: "startsWith" },
    },
    {
      headerName: "LY Total Margin($)",
      field: "ly_total_margin",
      filter: "agTextColumnFilter",
      valueFormatter: valueFormatterHandler,
      suppressSizeToFit: true,
      headerClass: "gray-header-bg",
      filterParams: { defaultOption: "startsWith" },
    },
    {
      headerName: "LY GM %",
      field: "ly_gm",
      filter: "agTextColumnFilter",
      valueFormatter: function (params) {
        if (params.data.ly_gm || params.data.ly_gm === 0) {
          let sign = Math.sign(params.data.ly_gm);
          if (sign === 1 || sign === 0) {
            return params.data.ly_gm.toFixed(2) + " %";
          }
          return numeral(params.data.ly_gm).format("(0.00)") + " %";
        }
        return "-";
      },
      headerClass: "gray-header-bg",
      suppressSizeToFit: true,
      filterParams: { defaultOption: "startsWith" },
    },
    {
      headerName: "Created By",
      field: "created_by",
      width: 300,
      filter: "agTextColumnFilter",
      unSortIcon: true,
      resizable: true,
      sortable: true,
      valueGetter: function (params) {
        if (params.data.created_by) {
          return params.data.created_by;
        }
        return "-";
      },
    },
    {
      headerName: "Created At",
      field: "created_at",
      width: 200,
      filter: "agTextColumnFilter",
      unSortIcon: true,
      resizable: true,
      sortable: true,
      comparator: function (date1, date2) {
        return comparator(date1, date2, "MM-DD-YYYY HH:mm:ss");
      },
      valueGetter: function (params) {
        if (params.data.created_at) {
          return getBrowserTimeZone(params.data.created_at);
        }
        return "-";
      },
    },
    {
      headerName: "Changed By",
      field: "updated_by",
      width: 300,
      filter: "agTextColumnFilter",
      unSortIcon: true,
      resizable: true,
      sortable: true,
      valueGetter: function (params) {
        if (params.data.updated_by && params.data.updated_at != params.data.created_at) {
          return params.data.updated_by;
        }
        return "-";
      },
    },
    {
      headerName: "Changed At",
      field: "updated_at",
      width: 200,
      filter: "agTextColumnFilter",
      unSortIcon: true,
      resizable: true,
      sortable: true,
      comparator: function (date1, date2) {
        return comparator(date1, date2, "MM-DD-YYYY HH:mm:ss");
      },
      valueGetter: function (params) {
        if (params.data.updated_at && params.data.updated_at != params.data.created_at) {
          return getBrowserTimeZone(params.data.updated_at);
        }
        return "-";
      },
    },
    {
      headerName: "Extra",
      field: "extra",
      hide: true,
    },
    {
      headerName: "Action",
      field: "action",
      cellRenderer: "childMessageRenderer",
      pinned: "right",
      suppressSizeToFit: true,
      cellStyle: null, //cellStyleMethodNew
      filter: false,
      width: 100,
    },
  ];
export default {
  defaultColDef: {
    headerCheckboxSelection: isFirstColumn,
    headerCheckboxSelectionFilteredOnly: true,
    checkboxSelection: isFirstColumn,
    resizable: true,
    filter: true,
    filterParams: { newRowsAction: "keep" },
    sortable: true,
  },
  LYHeader: [...lyHeaderBeforeZpes, ...lyHeaderAfterZpes],

  TYHeader: [...tyHeaderBeforeZpes, ...tyHeaderAfterZpes],

  zpesLYHeader: [
    ...lyHeaderBeforeZpes,
    // {
    //   headerName: "ZPES Offer",
    //   field: "zpes_offer",
    //   filter: "agTextColumnFilter",
    //   suppressSizeToFit: true,
    //   filterParams: { defaultOption: "startsWith" },
    //   valueGetter: function aPlusBValueGetter(params) {
    //     if (params.data.zpes_offer === "null") {
    //       return "-";
    //     } else if (
    //       params.data.zpes_offer &&
    //       params.data.rank === "1"
    //       // && params.data.zpes_feed_indicator !== "deleted_from_tool_added_from_zpes"
    //     ) {
    //       return offerFormatValidation(params.data.zpes_offer);
    //     }
    //     return "-";
    //   },
    //   hide: true,
    // },
    ...lyHeaderAfterZpes,
  ],

  zpesTYHeader: [
    ...tyHeaderBeforeZpes,
    // {
    //   headerName: "ZPES Offer",
    //   field: "zpes_offer",
    //   filter: "agTextColumnFilter",
    //   suppressSizeToFit: true,
    //   filterParams: { defaultOption: "contains" },
    //   valueGetter: function aPlusBValueGetter(params) {
    //     if (params.data.zpes_offer === "null") {
    //       return "-";
    //     } else if (
    //       params.data.zpes_offer &&
    //       params.data.rank === "1"
    //       // && params.data.zpes_feed_indicator !== "deleted_from_tool_added_from_zpes"
    //     ) {
    //       // if (!(params.getValue("original_update_flag") === 3)) {
    //       if (params.data.zpes_offer) {
    //         return offerFormatValidation(params.data.zpes_offer);
    //       } else {
    //         return "-";
    //       }
    //     }
    //     return "-";
    //   },
    //   hide: true,
    // },
    ...tyHeaderAfterZpes,
  ],
  deleteDefaultColDef: {
    headerCheckboxSelection: isFirstColumn,
    checkboxSelection: isFirstColumn,
    resizable: true,
    sortable: true,
    //filter: true
  },
  deleteHeader: [
    {
      headerName: "Adgroup",
      field: "adgroup_name",
      filter: "agTextColumnFilter",
      suppressSizeToFit: true,
    },
    {
      headerName: "Adgroup Desc.",
      field: "adgroup_description",
      filter: "agTextColumnFilter",
      suppressSizeToFit: true,
      width: 300,
    },
    {
      headerName: "Event",
      field: "start_date",
      filter: "agTextColumnFilter",
      suppressSizeToFit: true,
      width: 300,
      valueGetter: function sumField(params) {
        return (
          moment(params.data.start_date).format("MM-DD-YYYY") +
          " - " +
          moment(params.data.end_date).format("MM-DD-YYYY") +
          "   (" +
          promoTypeFormatter(params.data.promo_type, "delete_adgroup") +
          ")"
        );
      },
    },
    {
      headerName: "Offer Value",
      field: "offer",
      filter: "agTextColumnFilter",
      suppressSizeToFit: true,
    },
    {
      headerName: "Offer Type",
      field: "offer_type",
      filter: "agTextColumnFilter",
      suppressSizeToFit: true,
    },
  ],
  userCommentDefaultColDef: {
    sortable: true,
    filter: true,
  },
  userCommentHeader: [
    {
      headerName: "User Name",
      field: "userName",
      valueGetter: emptyValueFormatterForString,
      width: 120,
      filter: "agTextColumnFilter",
      filterParams: { defaultOption: "startsWith" },
    },
    {
      headerName: "Comment",
      field: "comment_text",
      filter: "agTextColumnFilter",
      filterParams: { defaultOption: "startsWith" },
    },
    {
      headerName: "Date",
      field: "date",
      filter: "agTextColumnFilter",
      filterParams: { defaultOption: "startsWith" },
      width: 120,
    },
    {
      headerName: "Comment ID",
      field: "comment_id",
      hide: true,
    },
    {
      headerName: "Comment Type",
      field: "comment_type",
      hide: true,
    },
    {
      headerName: "ViewSKU",
      field: "viewSku",
      hide: true,
    },
    {
      headerName: "Action",
      field: "action",
      cellRenderer: "commentChangeLogsRenderer",
      pinned: "right",
      suppressSizeToFit: true,
      cellStyle: null, //cellStyleMethodNew
      filter: false,
      width: 100,
    },
  ],
  deactivateApprovalErrorData : [
    {
      headerName: "Adgroup", 
      field: "adgroup_name",
      headerClass: "blue-header-bg",
      filter: "agTextColumnFilter", 
      checkboxSelection: false,
      headerCheckboxSelection: !isFirstColumn,
    },
    { 
      headerName: "Event", 
      field: "event_detail",
      headerClass: "blue-header-bg",
      filter: "agTextColumnFilter",
      checkboxSelection: false,
      headerCheckboxSelection: !isFirstColumn,
    },
    { 
      headerName: "Offer Type", 
      field: "offer_type",
      headerClass: "blue-header-bg",
      filter: "agTextColumnFilter",
      checkboxSelection: false,
      headerCheckboxSelection: !isFirstColumn,
    },
    { 
      headerName: "Offer Value", 
      field: "offer_value",
      headerClass: "blue-header-bg",
      filter: "agTextColumnFilter",
      checkboxSelection: false,
      headerCheckboxSelection: !isFirstColumn,
    },
    {
      headerName: "Status",
      field: 'status',
      cellRenderer: "approvalStatusRenderer",
      tooltipField: 'validationErrors',
      pinned: 'right',
      headerClass: "blue-header-bg",
      width: 100,
      filter: "agTextColumnFilter"
    }
  ],
};

function emptyValueFormatterForString(params) {
  if (params.data[params.colDef.field]) {
    return params.data[params.colDef.field];
  } else {
    return "-";
  }
}

function isFirstColumn(params) {
  var displayedColumns = params.columnApi.getAllDisplayedColumns();
  var thisIsFirstColumn = displayedColumns[0] === params.column;
  return thisIsFirstColumn;
}

function promoTypeFormatter(params, value) {
  let promoTypeSet = JSON.parse(localStorage.getItem("promo_type"));
  if (value === "delete_adgroup") {
    if (params) {
      // return promoTypeSet ? promoTypeSet[params] : params;
      return  params;
    }
  } else {
    if (
      // params.data.promo_type && params.data.rank === "1"
      params.data.promo_type 
      ) {
      // return promoTypeSet
      //   ? promoTypeSet[params.data.promo_type]
      //   : params.data.promo_type;
      return params.data.promo_type
    }
  }

  return "-";
}
function promoTypeFormatterLY(params) {
  let promoTypeSet = JSON.parse(localStorage.getItem("promo_type"));
  if (params.data.ly_event_type) {
    // return promoTypeSet
    //   ? promoTypeSet[params.data.ly_event_type]
    //   : params.data.ly_event_type;
    return params.data.ly_event_type;
  }
  return "-";
}

function aPlusBValueGetterCommon(params) {
  if (
    // new Date(params.data.campaign_start_date) >
    // new Date(moment().format("MM-DD-YYYY")) &&
    // params.data.rank === "1"
    new Date(params.data.campaign_start_date) >
    new Date(moment().format("MM-DD-YYYY")) 
  ) {
    let field = params.colDef.field,
      value = field ? params.data[field] : "";

    return value === null || value === ""
      ? ""
      : value > 0
        ? numeral(value).format("0")
        : "0";
  } else {
    return "";
  }
}

function commonOverrrideValueGetter(params) {
  // if (params.data.rank === "1") {
  //   let field = params.colDef.field,
  //     value = field ? params.data[field] : "";

  //   return value === null || value === ""
  //     ? ""
  //     : value > 0
  //       ? numeral(value).format("0")
  //       : "0";
  // } else {
  //   return "";
  // }
    let field = params.colDef.field,
      value = field ? params.data[field] : "";

    return value === null || value === ""
      ? ""
      : value > 0
        ? numeral(value).format("0")
        : "0";
}

function rankCheck(params) {
  if (
    // (params.value || params.value === 0) && params.data.rank === "1") 
    params.value || params.value === 0
    ) 
    {
    let value = params.value;
    let sign = Math.sign(value);
    let valueVar = Math.round(value * 100) / 100;

    return value === null || value === ""
      ? "-"
      : (sign === 1 || sign === 0) && valueVar !== 0
        ? numeral(valueVar).format("0,0")
        : numeral(valueVar).format("(0,0)");
  }
  return "-";
}

function rankCheckWithDolla(params) {
  if (
    // (params.value || params.value === 0) && params.data.rank === "1"
    params.value || params.value === 0
  ) {
    let value = params.value;
    let sign = Math.sign(value);
    let valueVar = Math.round(value * 100) / 100;

    return value === null || value === ""
      ? "-"
      : (sign === 1 || sign === 0) && valueVar !== 0
        ? "$ " + numeral(valueVar).format("0,0")
        : "$ " + numeral(valueVar).format("(0,0)");
  }
  return "-";
}

function rankCheckIA(params) {
  if (
    // params.value && params.data.rank === "1"
    params.value
  ) {
    let value = params.value;
    let sign = Math.sign(value);
    let valueVar = Math.round(value * 100) / 100;

    return value === null || value === "" || valueVar < 1
      ? "-"
      : (sign === 1 || sign === 0) && valueVar !== 0
        ? numeral(valueVar).format("0,0")
        : numeral(valueVar).format("(0,0)");
  }
  return "-";
}

function rankCheckWithDollaIA(params) {
  if (params.value) {
    let value = params.value;
    let sign = Math.sign(value);
    let valueVar = Math.round(value * 100) / 100;

    return value === null || value === "" || valueVar < 1
      ? "-"
      : (sign === 1 || sign === 0) && valueVar !== 0
        ? "$ " + numeral(valueVar).format("0,0")
        : "$ " + numeral(valueVar).format("(0,0)");
  }
  return "-";
}
function offerFormatValidation(offerValue, offerType) {
  // if (offerValue.includes("$") && offerValue.includes("OFF")) {
  //   return offerValue.replace("OFF", " OFF");
  // }
  // if (offerValue.includes("$ ") && offerValue.includes("FOR")) {
  //   let val = offerValue.replace("$ ", "$");
  //   return offerValue.includes(".00") ? val.replace(".00", "") : val;
  // }
  // if (offerValue.includes(".00")) {
  //   return offerValue.replace(".00", "");
  // }
  // if (offerValue.includes(".")) {
  //   //'$2.5' to '$2.50' case handling
  //   let splitArr = offerValue.split(".");
  //   if (splitArr[1].length === 1) {
  //     return offerValue + "0";
  //   }
  // }
  ///////////////////////////////////////////////////////////////////////////////
  ////////////all $ values converted to float values with 2 decimal points //////
  //////////// $3 will show as $3.00 and $3.5 will show as $3.50/////////////////
  ////////////applies for OFF and FOR case as well //////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////
  if (offerValue.includes("$") && offerValue.includes("OFF") && offerType == 'C') {
    return offerValue
  }
  if (offerValue.includes("$") && offerValue.includes("OFF")) {
    let splitArr = offerValue.split("O");
    let newVal = splitArr[0] && splitArr[0].replace("$", "");
    return "$" + numeral(newVal).format("0.00") + " OFF";
  }
  if (offerValue.includes("$") && offerValue.includes("FOR")) {
    let splitArr = offerValue.split("$");
    let newVal = splitArr[0] + "$" + numeral(splitArr[1]).format("0.00");
    return newVal.includes("$ ") ? newVal.replace("$ ", "$") : newVal;
  }
  if (
    offerValue.includes("$") &&
    !offerValue.includes("OFF") &&
    !offerValue.includes("FOR")
  ) {
    let splitArr = offerValue.split("$");
    return splitArr[1] ? "$" + numeral(splitArr[1]).format("0.00") : offerValue;
  }
  return offerValue;
}
