import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Container, Row, Col } from 'react-bootstrap';
import Modal from "react-awesome-modal";
import DateTimePicker from 'react-datetime-picker';
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';
import moment from "moment";
import _ from "lodash";

import PageLoader from "../../../../utils/Loader";
import AlertSuccess from "../../../../utils/AlertMessage/alertSuccess";
import AlertDanger from "../../../../utils/AlertMessage/alertDanger";
import { EventMAsterGA } from "../../../../utils/GA/GAUtils";

import { Typography, Select, Input, Button, Checkbox } from 'antd';
import CloseCircleFilled from '@ant-design/icons/CloseCircleFilled';

import queryString from "query-string";
import MoveEvent from "./MoveEvent";
import MultiSelectAll from "./MultiSelectDropdown";
import "./EventMasterStyling.css"
import { alertTimer } from "../../../../utils/alertTimer";
import {getSignShellOptions} from '../../../../redux/actions/signShellAction'

import {
  getEventDetails,
  getCampaignsData,
  getLYEventData,
  getVehicleData,
  validateMainEvent,
  getPromoGroupData,
  createEvent,
  createPromoGroup,
  updateEvent,
  moveEvent,
  validateMoveEvent,
  overrideEvent
} from "../../../../redux/actions/eventMasterAction";

import {
  EVENT_TYPE_CONSTANTS,
  EVENT_VEHICLE_CONSTANTS,
  EVENT_SIGNSHELL_CONSTANTS,
  EVENT_PRICE_LIST_CONSTANTS,
  VIEW,
  EDIT,
  CREATE,
  MANDATORY_EXCEPTION_KEYS
} from "./constants";
import { getBrowserTimeZone } from "../../../../utils/valueFormatters";
import { isIt } from "../../../../utils/getRoleName";

const intialeventDetails = {
  campaign_id: null,
  campaign_start_date: null,
  campaign_end_date: null,
  g_eid: '-',
  description: null,
  event_start_date: null,
  event_end_date: null,
  type: null,
  vehicle: null,
  sign_shell: null,
  ly_event: null,
  price_list: EVENT_PRICE_LIST_CONSTANTS,
  site_specific: false,
  promo_group_id: null,
  promo_group_description: null,
  e_id: null,
  merch_lockout_date: null
}

export class EditEvent extends Component {

  constructor(props) {
    super(props);
    this.state = {
      pageHeader: '',
      pageState: null,
      eventDetails: _.cloneDeep(intialeventDetails),
      isPromoGroupCreate: false,
      promoGroup: '',
      minDate: null,
      maxDate: null,
      isMainEvent: false,
      isBtnDisabled: true,
      eventTypeOptions: _.cloneDeep(EVENT_TYPE_CONSTANTS),
      isSuccess: false,
      isFailed: false,
      successMessage: '',
      failureMessage: '',
      isMoveEvent: false,
      destinationCampaign: null,
      isMoveConflict: false,
      moveConflictMsg: '',
      isUpdated: false ,
      EVENT_SIGNSHELL_CONSTANTS2  : [] 
    }
  }

  async componentDidMount() {
      let tempData = await this.props.getSignShellOptions();
      
    const signShellArray = tempData.signShell;
      const formattedData = signShellArray.map(item => ({
          value: item.value,
          label: item.label,
      }));
      this.setState({EVENT_SIGNSHELL_CONSTANTS2 : formattedData}, () => {
        console.log(this.state.EVENT_SIGNSHELL_CONSTANTS2)
      }
      )
    console.log("temp data upar hai")
    const locationObj = this.props.location;
    let queries = queryString.parse(locationObj.search);

    if (queries.eventId) {
      const reqObj = {
        eventId: queries.eventId
      };

      if (locationObj.pathname.includes(EDIT)) {
        this.setState({
          pageHeader: 'Edit event',
          pageState: EDIT,
        });
      } else {
        this.setState({
          pageHeader: 'View event',
          pageState: VIEW,
        });
      }

      try {
        this.fetchEventDetails(reqObj)
      } catch (error) {
        console.error("Error fetching event details:", error);
      }
    } else {
      let locationObj = this.props.location;
      if (locationObj.pathname.includes(CREATE)) {
        this.setState({
          pageHeader: 'Create event',
          pageState: CREATE,
        });
      }
      try {
        this.props.getCampaignsData();
        await this.props.getVehicleData();
        await this.props.getPromoGroupData();
      } catch (error) {
        console.error("Error fetching campaigns or vehicle data:", error);
      }
    }
  }

  fetchEventDetails = reqObj => {
    const locationObj = this.props.location;
    let queries = queryString.parse(locationObj.search);
    this.props.getEventDetails(reqObj).then(async (response) => {
      if (response.data[0]) {

        const result = response.data[0];

        if (locationObj.pathname.includes(VIEW)) {
          const typeValue = EVENT_TYPE_CONSTANTS.find(obj => obj['value'] == response.data[0].type);

          // handle null case
          result['type'] = typeValue && typeValue.label ? typeValue.label : '-';
          result['price_list'] = result['price_list'] && result['price_list'].length > 1 ? result['price_list'].join(', ') : result['price_list'];
        }
        if (locationObj.pathname.includes(EDIT)) {
          if (response.data[0]['error_field']) {
            result['event_start_date'] = null;
            result['event_end_date'] = null;
          } else {
            result['event_start_date'] = new Date(response.data[0]['event_start_date']);
            result['event_end_date'] = new Date(response.data[0]['event_end_date']);
          }
          result['price_list'] = result['price_list'] && (result['price_list'].length == EVENT_PRICE_LIST_CONSTANTS.length - 1)
            ? EVENT_PRICE_LIST_CONSTANTS
            : EVENT_PRICE_LIST_CONSTANTS.filter(list => result['price_list'].includes(list.value))
        }
        this.setState({
          eventDetails: _.cloneDeep(result)
        });

        const lyEventReqObj = {
          campaignId: result.campaign_id
        };

        this.props.getLYEventData(lyEventReqObj);
      }
      await this.props.getCampaignsData();
      await this.props.getVehicleData();
      await this.props.getPromoGroupData();
    });
  }


  componentDidUpdate(prevProps, prevState) {
    if (prevProps.campaignOptions != this.props.campaignOptions) {
      if (this.state.eventDetails.campaign_id) {
        const selectedCampaign = this.props.campaignData.filter(data => data.campaign_id == this.state.eventDetails.campaign_id);

        if (selectedCampaign.length > 0) {
          this.setState({
            minDate: new Date(selectedCampaign[0].campaign_start_date) < new Date() ? new Date() : new Date(`${selectedCampaign[0].campaign_start_date} 00:00`),
            maxDate: new Date(`${selectedCampaign[0].campaign_end_date} 23:59`)
          })
        }
      }
    }
    if (prevState.eventDetails != this.state.eventDetails) {
      const isValid = this.checkMandatoryExceptions();

      if (isValid) {
        this.setState({ isBtnDisabled: false })
      } else {
        this.setState({ isBtnDisabled: true })
      }
    }
  }

  checkMandatoryExceptions = (eventObj) => {
    const data = eventObj ? _.cloneDeep(eventObj) : _.cloneDeep(this.state.eventDetails);
    const keysWithNullValues = _.pickBy(data, value => {
      return _.isEmpty(value) || (Array.isArray(value) && value.length === 0);
    });

    let nullKeys = [];
    for (const key in data) {
      if (data[key] === null || data[key] === '' || (Array.isArray(data[key]) && data[key].length === 0)) {
        nullKeys.push(key);
      }
    }

    if (nullKeys.length == 0) {
      return true;
    }

    if (nullKeys.length > MANDATORY_EXCEPTION_KEYS.length) {
      return false;
    }

    // check if  MANDATORY_EXCEPTION_KEYS has all the values of nullKeys
    const isAllExceptionKeys = nullKeys.every(value => MANDATORY_EXCEPTION_KEYS.includes(value));

    if (!isAllExceptionKeys) {
      return false;
    }

    if (this.state.eventDetails.site_specific && (nullKeys.includes("promo_group_id") && nullKeys.includes("promo_group_description"))) {
      return false
    }

    return true

  }

  submitGA(action, label) {
    EventMAsterGA({ action, label });
  }

  handleCancel = () => {
    this.setState({ eventDetails: {} });

    this.submitGA("Click", "Clicked Cancel Edit");

    this.props.history.push("/eventMaster")
  }

  handleCreate = () => {
    const reqObj = {}

    this.submitGA("Click", "Clicked Create Event");
    if (this.state.eventDetails.event_end_date <= this.state.eventDetails.event_start_date) {
      this.setState({
        isFailed: true,
        failureMessage: 'Event end date should be greater than event start date',
        isBtnDisabled: true
      });

      setTimeout(() => {
        this.setState({
          isFailed: false,
          failureMessage: ''
        });

      }, alertTimer);

      return
    }

    if (this.state.eventDetails.event_start_date <= new Date()) {
      this.setState({
        isFailed: true,
        failureMessage: 'Event start date should be greater than current date and time',
        isBtnDisabled: true
      });

      setTimeout(() => {
        this.setState({
          isFailed: false,
          failureMessage: ''
        });

      }, alertTimer);

      return
    }

    Object.keys(this.state.eventDetails).forEach(key => {
      if (this.state.eventDetails[key] instanceof Date) {
        reqObj[key] = moment(this.state.eventDetails[key]).format('YYYY-MM-DD HH:mm:ss')
        // reqObj[key] = moment.utc(moment(this.state.eventDetails[key])).format()
      } else if (key == 'ly_event' && this.state.eventDetails[key]) {
        const lyObj = this.props.lyeventData.filter(item =>
          this.state.eventDetails[key].includes(item[key])
        )

        reqObj[key] = lyObj;
      } else {
        reqObj[key] = this.state.eventDetails[key]
      }
    });

    this.props.createEvent(reqObj).then(res => {
      if (res.status) {

        const tempEventDetails = _.cloneDeep(this.state.eventDetails);

        tempEventDetails['g_eid'] = res.data[0].g_eid;

        this.setState({
          isSuccess: true,
          eventDetails: _.cloneDeep(tempEventDetails),
          successMessage: `${res.data[0].g_eid} event created`
        })

        setTimeout(() => {
          this.setState({
            isSuccess: false,
            successMessage: ''
          });

          this.props.history.push('/eventMaster');
        }, alertTimer);

      } else {
        this.setState({
          isFailed: true,
          failureMessage: `${res.msg}`
        })

        setTimeout(() => {
          this.setState({
            isFailed: false,
            failureMessage: ''
          });
        }, alertTimer);
      }
    })

  }

  handleEdit = () => {
    const reqObj = {}

    this.submitGA("Click", "Clicked Edit Event");

    if (this.state.eventDetails.event_end_date <= this.state.eventDetails.event_start_date) {
      this.setState({
        isFailed: true,
        failureMessage: 'Event end date time should be greater than event start date time',
        isBtnDisabled: true
      });

      setTimeout(() => {
        this.setState({
          isFailed: false,
          failureMessage: ''
        });

      }, alertTimer);

      return
    }

    Object.keys(this.state.eventDetails).forEach(key => {
      if (this.state.eventDetails[key] instanceof Date) {
        reqObj[key] = moment(this.state.eventDetails[key]).format('YYYY-MM-DD HH:mm:ss')
        // reqObj[key] = moment.utc(moment(this.state.eventDetails[key])).format()
      } else if (key == 'ly_event' && this.state.eventDetails[key]) {
        console.log("**********lyeventData", this.props.lyeventData)
        const lyObj = this.props.lyeventData.filter(item =>
          this.state.eventDetails[key].includes(item[key])
        )

        reqObj[key] = lyObj;
      } else {
        reqObj[key] = this.state.eventDetails[key]
      }
    });

    this.props.updateEvent(reqObj).then(res => {
      if (res.status) {

        const tempEventDetails = _.cloneDeep(this.state.eventDetails);

        tempEventDetails['g_eid'] = res.data[0].g_eid;

        this.setState({
          isSuccess: true,
          eventDetails: _.cloneDeep(tempEventDetails),
          successMessage: `${res.data[0].g_eid} event updated`
        })

        setTimeout(() => {
          this.setState({
            isSuccess: false,
            successMessage: ''
          });

          this.props.history.push('/eventMaster');
        }, alertTimer);

      } else {
        this.setState({
          isFailed: true,
          failureMessage: `${res.msg}`
        })

        setTimeout(() => {
          this.setState({
            isFailed: false,
            failureMessage: ''
          });
        }, alertTimer);
      }
    })
  }

  handleEventdetailChange = async (value, key) => {
    const tempObj = _.cloneDeep(this.state.eventDetails);
    tempObj[key] = value;

    console.log("eventDetails:::", this.state.eventDetails);

    if (key == 'campaign_id') {
      //call validation api and set default values

      const reqObj = {
        campaignId: value
      }

      this.props.getLYEventData(reqObj);

      const selectedCampaign = this.props.campaignData.filter(data => data.campaign_id == value);

      this.setState({
        minDate: new Date(selectedCampaign[0].campaign_start_date) < new Date() ? new Date() : new Date(`${selectedCampaign[0].campaign_start_date} 00:00`),
        maxDate: new Date(`${selectedCampaign[0].campaign_end_date} 23:59`)
      })

      tempObj['campaign_start_date'] = selectedCampaign[0].campaign_start_date;
      tempObj['campaign_end_date'] = selectedCampaign[0].campaign_start_date;

      this.props.validateMainEvent(reqObj).then(result => {
        if (result.status) {

          const tempEventDetails = {
            vehicle: 'DM',
            type: 'M',
            event_start_date: new Date(new Date(selectedCampaign[0].campaign_start_date).setHours(0, 0)),
            event_end_date: new Date(new Date(selectedCampaign[0].campaign_end_date).setHours(23, 59)),
            campaign_start_date: selectedCampaign[0].campaign_start_date,
            campaign_end_date: selectedCampaign[0].campaign_end_date
          }

          this.setState({
            eventDetails: _.merge({}, tempObj, tempEventDetails)
          })

        } else {
          const tempEventDetails = _.cloneDeep(intialeventDetails);
          tempEventDetails[key] = value;
          tempEventDetails['campaign_start_date'] = selectedCampaign[0].campaign_start_date;
          tempEventDetails['campaign_end_date'] = selectedCampaign[0].campaign_start_date;

          const tempTypeOtions = this.state.eventTypeOptions.map(data => {
            let option = data;
            if (option.value == 'M') {
              option['disabled'] = true;
            }

            return option;
          });
          this.setState({
            eventDetails: _.cloneDeep(tempEventDetails),
            // eventTypeOptions: _.cloneDeep(tempTypeOtions)
          })

        }

      });
    }

    if (key == 'site_specific') {
      if(value) {
        tempObj['price_list'] = _.cloneDeep(EVENT_PRICE_LIST_CONSTANTS);
      } else {
      tempObj['promo_group_id'] = null;
      tempObj['promo_group_description'] = null;
      this.setState({
        isPromoGroupCreate: false,
        promoGroup: ''
      })
    }
    }

    if (key == "event_end_date") {
      const prevEndDate = _.cloneDeep(this.state.eventDetails.event_end_date);
      const startDate = _.cloneDeep(this.state.eventDetails.event_start_date);

      if (prevEndDate) {
        if (prevEndDate.toDateString() === value.toDateString() || (this.state.eventDetails.event_end_date && this.state.eventDetails.event_end_date.toDateString() === value.toDateString())) {
          tempObj["event_end_date"] = value
        } else {
          tempObj["event_end_date"] = new Date(value.setHours(23, 59));
        }
      } else {
        tempObj["event_start_date"] = startDate;
        tempObj["event_end_date"] = new Date(value.setHours(23, 59));
      }
    }

    if (key == "event_start_date") {
      const currentDate = new Date();
      const eventEndDate = _.cloneDeep(this.state.eventDetails.event_end_date);

      if (currentDate.toDateString() === value.toDateString() || (this.state.eventDetails.event_start_date && this.state.eventDetails.event_start_date.toDateString() === value.toDateString())) {
        tempObj["event_start_date"] = value
      } else {
        tempObj["event_start_date"] = new Date(value.setHours(0, 0))
      }

      if (eventEndDate < value) {
        tempObj["event_end_date"] = null;
      }
    }

    this.setState({
      eventDetails: _.cloneDeep(tempObj),
      isUpdated: true
    })
  }

  enableCreatePromoGroup = () => {
    if (this.state.eventDetails.site_specific) {
      this.setState({
        isPromoGroupCreate: true
      })
    }
  }

  handlePromogroupCreate = () => {
    const reqObj = {
      description: this.state.promoGroup
    }

    this.submitGA("Click", "Clicked Promo group create");
    // call create promo group api 
    this.props.createPromoGroup(reqObj).then(res => {
      if (res.status) {
        this.setState({
          isSuccess: true,
          successMessage: `${res.data[0].description} promo group created`
        })

        this.props.getPromoGroupData().then(result => {
          //set promogroup to the newly created promo group
          const tempEventDetails = this.state.eventDetails;
          tempEventDetails['promo_group_id'] = res.data[0].id;

          this.setState({
            promo_group_id: res.data[0].id,
            isPromoGroupCreate: false,
            promoGroup: '',
            eventDetails: tempEventDetails
          });

          const isValid = this.checkMandatoryExceptions(tempEventDetails);

          if (isValid) {
            this.setState({ isBtnDisabled: false })
          } else {
            this.setState({ isBtnDisabled: true })
          }
        });


        setTimeout(() => {
          this.setState({
            isSuccess: false,
            successMessage: ''
          });
        }, alertTimer);
      } else {
        this.setState({
          isFailed: true,
          failureMessage: res.msg
        });

        setTimeout(() => {
          this.setState({
            isFailed: false,
            failureMessage: ''
          });

        }, alertTimer);
      }
    }).catch((error) => {

    })
  }

  handleCancelPromogroupcreate = () => {
    this.submitGA("Click", "Clicked cancel promo group create");
    this.setState({
      isPromoGroupCreate: false,
      promoGroup: ''
    })
  }

  minimumEndDate = () => {
    if (this.state.eventDetails.event_start_date) {
      const tempdate = this.state.eventDetails.event_start_date;

      // tempdate.setMinutes(this.state.eventDetails.event_start_date.getMinutes() + 1);

      return tempdate
    } else if (this.state.minDate) {
      return this.state.minDate
    }
    return new Date()
  }

  closeModal = () => {
    this.setState({
      isMoveEvent: false,
      destinationCampaign: null,
    })
  }

  closeMoveConflictModal = () => {
    this.setState({
      destinationCampaign: null,
      isMoveConflict: false
    })
  }

  moveEventModal = () => {
    this.setState({
      isMoveEvent: true
    });

    console.log("eventdetails", this.state.eventDetails);
    const reqObj = {
      campaign_name: this.state.eventDetails.campaign_name
    }

    this.props.getCampaignsData(reqObj);
  }

  handleMoveEvent = () => {
    console.log(this.state.destinationCampaign);
    const locationObj = this.props.location;
    let queries = queryString.parse(locationObj.search);

    const moveReqObj = {
      dest_cid: this.state.destinationCampaign,
      event_id: queries.eventId,
    }

    const selectedCampaign = this.props.campaignData.filter(data => data.campaign_id == this.state.destinationCampaign);

    this.setState({
      minDate: new Date(selectedCampaign[0].campaign_start_date) < new Date() ? new Date() : new Date(`${selectedCampaign[0].campaign_start_date} 00:00`),
      maxDate: new Date(`${selectedCampaign[0].campaign_end_date} 23:59`)
    })

    this.props.moveEvent(moveReqObj).then(res => {
      if (res.status) {
        this.setState({
          isSuccess: true,
          successMessage: `${res.data[0].g_eid} event moved successfully`,
          isMoveConflict: false,
          isMoveEvent: false,
          destinationCampaign: null
        })

        setTimeout(() => {
          this.setState({
            isSuccess: false,
            successMessage: ''
          });
        }, alertTimer);

        const getReqObj = {
          eventId: res.data[0].id
        };
        this.fetchEventDetails(getReqObj)
      }
    })
  }

  handleValidateMoveEvent = () => {
    console.log(this.state.destinationCampaign);
    const locationObj = this.props.location;
    let queries = queryString.parse(locationObj.search);

    this.submitGA("Click", "Clicked Move Event");

    const reqObj = {
      dest_cid: this.state.destinationCampaign,
      event_id: this.state.e_id,
      source_cid: this.state.eventDetails.campaign_id,
      type: this.state.eventDetails.type,
      vehicle: this.state.eventDetails.vehicle,
    }

    this.props.validateMoveEvent(reqObj).then(res => {
      if (res.status) {
        this.handleMoveEvent();
        this.setState({
          isMoveEvent: false,
          destinationCampaign: null
        })
      } else {
        this.setState({
          isMoveConflict: true,
          moveConflictMsg: res.msg,
          isMoveEvent: false
        })
      }
    })
  }

  handleCampaignChange = val => {
    this.setState({
      destinationCampaign: val
    })
  }

  handleOverrideEvent = () => {
    const reqObj = {
      dest_cid: this.state.destinationCampaign,
      event_id: this.state.eventDetails.e_id,
      type: this.state.eventDetails.type,
      vehicle: this.state.eventDetails.vehicle,
    }

    this.setState({
      isMoveConflict: false,
    });

    this.props.overrideEvent(reqObj).then(res => {
      if (res.status) {
        this.setState({
          isSuccess: true,
          successMessage: `${res.msg}`,
          destinationCampaign: null
        })

        setTimeout(() => {
          this.setState({
            isSuccess: false,
            successMessage: ''
          });
          this.props.history.push("/eventMaster")
        }, alertTimer);
      }

    })
  }

  isPastMechLockoutDate = () => {
    const currentDate = new Date();
    const lockoutDate = new Date(this.state.eventDetails.merch_lockout_date);

    if(lockoutDate < currentDate){
      return true
    }

    return false
  }

  handlePriceListChange = (val)=> {
    const newDetails = Object.assign(this.state.eventDetails);
    newDetails.price_list = val;
    // this.setState({eventDetails: newDetails});
    this.handleEventdetailChange(val, 'price_list');
  }

  render() {

    const disableMultiSelecter = this.state.pageState == EDIT? ((this.state.isSuccess || this.isPastMechLockoutDate() || this.state.eventDetails.site_specific)? true : false) : (this.state.isSuccess || this.state.eventDetails.site_specific? true : false)
    return (

      <PageLoader loader={this.props.loader}>
        <div className="alertDivs">
          {this.state.isSuccess &&
            <AlertSuccess
              message={this.state.successMessage}
              show={this.state.isSuccess}
            />
          }
          {this.state.isFailed &&
            <AlertDanger
              message={this.state.failureMessage}
              show={this.state.isFailed}
            />
          }
          {this.state.isMoveEvent && <MoveEvent
            isOpen={this.state.isMoveEvent}
            closeModal={this.closeModal}
            destinationCampaign={this.state.destinationCampaign}
            onChange={this.handleCampaignChange}
            options={this.props.campaignOptions}
            handlePrimaryBtnClick={this.handleMoveEvent}
            handleSecondaryBtnClick={this.closeModal}
            modalHeader={"Move event"}
            isCampaignDropdown={true}
            primaryBtnText={"Move"}
            secondaryBtnText={"Cancel"}
            width="400"
            height="250"
          />
          }
          {this.state.isMoveConflict && <MoveEvent
            isOpen={this.state.isMoveConflict}
            closeModal={this.closeMoveConflictModal}
            handlePrimaryBtnClick={this.handleOverrideEvent}
            handleSecondaryBtnClick={this.handleMoveEvent}
            modalHeader={"Move event conflict"}
            primaryBtnText={"Overwrite"}
            secondaryBtnText={"Move"}
            message={this.state.moveConflictMsg}
            width="400"
            height="300"
          />
          }
        </div>
        <div className="card cardStyles cardGap createEvent">
          <div id="cardbox1" style={{ padding: "1px 12px 10px 20px" }}>
            <div className="createClusterDiv">
              <div className="row pl-3">
                <div className="row col-md-3">
                  <i
                    className="fa fa-angle-double-left double-left-icon"
                    onClick={() =>
                      this.props.history.push("/eventMaster")
                    }
                    aria-hidden="true"
                  ></i>
                  <p
                    id="backToCalLink"
                    onClick={() =>
                      this.props.history.push("/eventMaster")
                    }
                  >
                    Back To Event Master
                  </p>
                </div>
                <div className="col-md-6">
                  {" "}
                  <h4 className="promoHeading">
                    {this.state.pageHeader}
                  </h4>
                </div>
              </div>

              <div className="childCompStyling" style={{ border: "none" }}>
                <div className="createClusterDiv">
                  <Container fluid>
                    <Row style={{ marginTop: '10px', marginBottom: '10px' }}>
                      <Col lg>
                        <p className="eventDetailHeader">Campaign name <span style={{ color: "red", marginLeft: "2px" }}>*</span></p>
                        {this.state.pageState == VIEW ?
                          <p className="eventDetailValue">{this.state.eventDetails.campaign_name ? (`${moment(this.state.eventDetails.campaign_start_date).format("MM/DD/YYYY")} - ${moment(this.state.eventDetails.campaign_end_date).format("MM/DD/YYYY")}`) : '-'}</p>
                          :
                          <Select
                            placeholder="Select campaign name"
                            // defaultValue="lucy"
                            value={this.state.eventDetails.campaign_id ? this.state.eventDetails.campaign_id : []}
                            style={{
                              width: this.state.pageState == EDIT ? '50%' : '25%',
                            }}
                            onChange={e => this.handleEventdetailChange(e, 'campaign_id')}
                            options={this.props.campaignOptions}
                            disabled={this.state.pageState == EDIT || this.state.isSuccess}

                          />}
                      </Col>
                      {this.state.pageState == EDIT &&
                        <Col lg style={{ textAlign: "end" }}>
                          <Button
                            type="primary"
                            disabled={this.state.eventDetails.type && this.state.eventDetails.type == "M"}
                            onClick={this.moveEventModal}
                          >
                            Move Event
                          </Button>
                        </Col>
                      }
                    </Row>
                    <hr style={{ height: '1px', borderWidth: '0', backgroundColor: '#d3d3d3' }} />
                    {this.state.eventDetails.campaign_id && <>
                      <Row style={{ marginTop: '10px', marginBottom: '10px' }}>
                        <Col lg>
                          <p className="eventDetailHeader">Event ID</p>
                          {this.state.pageState == VIEW ?
                            <p className="eventDetailValue">{this.state.eventDetails.g_eid ? this.state.eventDetails.g_eid : '-'}</p>
                            :
                            <Input
                              id="e_id"
                              placeholder="Event Id"
                              disabled={true}
                              value={this.state.eventDetails.g_eid ? this.state.eventDetails.g_eid : '-'}
                            />
                          }
                        </Col>
                        <Col lg>
                          <p className="eventDetailHeader">Description <span style={{ color: "red", marginLeft: "2px" }}>*</span></p>
                          {this.state.pageState == VIEW ?
                            <p className="eventDetailValue">{this.state.eventDetails.description ? this.state.eventDetails.description : '-'}</p>
                            :
                            <Input
                              id="description"
                              placeholder="Description"
                              value={this.state.eventDetails.description ? this.state.eventDetails.description : ''}
                              maxLength={20}
                              onChange={e => this.handleEventdetailChange(e.target.value, 'description')}
                              showCount={true}
                              disabled={this.state.isSuccess}
                            />}
                        </Col>
                        <Col lg>
                          <p className="eventDetailHeader">Start Date <span style={{ color: "red", marginLeft: "2px" }}>*</span></p>
                          {this.state.pageState == VIEW ?
                            // <p className="eventDetailValue">{getBrowserTimeZone(this.state.eventDetails.event_start_date,"MM/DD/YYYY HH:mm")}</p>
                            <p className="eventDetailValue">{this.state.eventDetails.event_start_date}</p>
                            :
                            <DateTimePicker
                              calendarType={'gregory'}
                              onChange={e => this.handleEventdetailChange(e, 'event_start_date')}
                              value={this.state.eventDetails.event_start_date || null}
                              format={'MM/dd/yyyy HH:mm'}
                              isClockOpen={false}
                              disableClock={true}
                              disabled={(this.state.eventDetails.type && this.state.eventDetails.type == "M") || this.state.isSuccess || false}
                              minDate={this.state.minDate}
                              maxDate={this.state.maxDate}
                              autoFocus={false}
                            />
                          }
                        </Col>
                        <Col lg>
                          <p className="eventDetailHeader">End Date <span style={{ color: "red", marginLeft: "2px" }}>*</span></p>
                          {this.state.pageState == VIEW ?
                            // <p className="eventDetailValue">{getBrowserTimeZone(this.state.eventDetails.event_end_date,"MM/DD/YYYY HH:mm")}</p>
                            <p className="eventDetailValue">{this.state.eventDetails.event_end_date}</p>
                            :
                            <DateTimePicker
                              calendarType={'gregory'}
                              onChange={e => this.handleEventdetailChange(e, 'event_end_date')}
                              value={this.state.eventDetails.event_end_date || null}
                              format={'MM/dd/yyyy HH:mm'}
                              isClockOpen={false}
                              disableClock={true}
                              disabled={(this.state.eventDetails.type && this.state.eventDetails.type == "M") || this.state.isSuccess || false}
                              minDate={this.minimumEndDate()}
                              maxDate={this.state.maxDate}
                            />
                          }
                        </Col>
                      </Row>
                      <Row style={{ marginTop: '10px', marginBottom: '10px' }}>
                        <Col lg>
                          <p className="eventDetailHeader">Type <span style={{ color: "red", marginLeft: "2px" }}>*</span></p>
                          {this.state.pageState == VIEW ?
                            <p className="eventDetailValue">{this.state.eventDetails.type ? this.state.eventDetails.type : '-'}</p>
                            :
                            <Select
                              placeholder="Select type"
                              // defaultValue="lucy"
                              value={this.state.eventDetails.type || null}
                              style={{
                                width: '100%',
                              }}
                              onChange={e => this.handleEventdetailChange(e, 'type')}
                              options={this.state.eventTypeOptions}
                              disabled={(this.state.eventDetails.type && this.state.eventDetails.type == "M") || this.state.isSuccess || false}
                            />
                          }
                        </Col>
                        <Col lg>
                          <p className="eventDetailHeader">Vehicle <span style={{ color: "red", marginLeft: "2px" }}>*</span></p>
                          {this.state.pageState == VIEW ?
                            <p className="eventDetailValue">{this.state.eventDetails.vehicle ? this.state.eventDetails.vehicle : '-'}</p>
                            :
                            <Select
                              placeholder="Select vehicle"
                              value={this.state.eventDetails.vehicle || null}
                              style={{
                                width: '100%',
                              }}
                              onChange={e => this.handleEventdetailChange(e, 'vehicle')}
                              options={this.props.vehicleData}
                              disabled={(this.state.eventDetails.type && this.state.eventDetails.type == "M") || this.state.isSuccess || false}
                            />}
                        </Col>
                        <Col lg>
                          <p className="eventDetailHeader">Sign Shell <span style={{ color: "red", marginLeft: "2px" }}>*</span></p>
                          {this.state.pageState == VIEW ?
                            <p className="eventDetailValue">{this.state.eventDetails.sign_shell ? this.state.eventDetails.sign_shell : '-'}</p>
                            :
                            <Select
                              placeholder="Select sign shell"
                              value={this.state.eventDetails.sign_shell || null}
                              style={{
                                width: '100%',
                              }}
                              onChange={e => this.handleEventdetailChange(e, 'sign_shell')}
                              options={this.state.EVENT_SIGNSHELL_CONSTANTS2}
                              disabled={this.state.isSuccess}
                            />}
                        </Col>
                        <Col lg>
                          <p className="eventDetailHeader">LY Event
                            {/* <span style={{ color: "red", marginLeft: "2px" }}>*</span> */}
                          </p>
                          {this.state.pageState == VIEW ?
                            <p className="eventDetailValue">{this.state.eventDetails.ly_event ? this.state.eventDetails.ly_event.join(', ') : '-'}</p>
                            :
                            <Select
                              placeholder="Select LY event"
                              value={this.state.eventDetails.ly_event || []} //? this.state.eventDetails.ly_event : this.props.lyEventOptions.length == 0 ? '-' :
                              style={{
                                width: '100%',
                              }}
                              onChange={e => this.handleEventdetailChange(e, 'ly_event')}
                              options={this.props.lyEventOptions}
                              disabled={this.state.isSuccess} //this.props.lyEventOptions.length == 0 || 
                              mode="multiple"
                            />}
                        </Col>
                      </Row>
                      <Row style={{ marginTop: '10px', marginBottom: '10px' }}>
                        <Col lg style={{ width: '25%' }}>
                          <p className="eventDetailHeader">Price List <span style={{ color: "red", marginLeft: "2px" }}>*</span></p>
                          {this.state.pageState == VIEW ?
                            <p className="eventDetailValue">{this.state.eventDetails.price_list ? this.state.eventDetails.price_list : '-'}</p>
                            :
                            <>
                              {/* <Select
                                placeholder="Select price list"
                                value={this.state.eventDetails.price_list ? this.state.eventDetails.price_list : []}
                                style={{
                                  width: '100%',
                                }}
                                onChange={e => this.handleEventdetailChange(e, 'price_list')}
                                options={EVENT_PRICE_LIST_CONSTANTS}
                                mode="multiple"
                                disabled={this.state.isSuccess}
                              /> */}
                              <MultiSelectAll
                                placeholder={"Select price list"}
                                options={EVENT_PRICE_LIST_CONSTANTS}
                                value={this.state.eventDetails.price_list ? this.state.eventDetails.price_list : []}
                                onchange={this.handlePriceListChange}
                                keyValue={'price_list'}
                                style={{
                                  width: '100%',
                                }}
                                disabled={disableMultiSelecter} 
                              />
                              {!disableMultiSelecter && !_.isEmpty(this.state.eventDetails.price_list) ? (
                                <i
                                  className="fa fa-times close1 price-list "
                                  aria-hidden="true"
                                  onClick={() => this.handleEventdetailChange([], 'price_list')}
                                />
                              ) : null}
                            </>
                          }
                        </Col>
                        <Col lg style={{ marginTop: '35px' }}>
                          <Checkbox
                            disabled={this.state.pageState == VIEW || this.state.isSuccess}
                            checked={this.state.eventDetails.site_specific || false}
                            onChange={e => this.handleEventdetailChange(e.target.checked, 'site_specific')}>
                            Site Specific
                          </Checkbox>
                        </Col>

                        <Col lg>
                          <p className="eventDetailHeader">Promo Group
                            {this.state.eventDetails.site_specific && <span style={{ color: "red", marginLeft: "2px" }}>*</span>}
                          </p>
                          {this.state.pageState == VIEW ?
                            <p className="eventDetailValue">{this.state.eventDetails.promo_group_description ? this.state.eventDetails.promo_group_description : '-'}</p>
                            :
                            this.state.isPromoGroupCreate ?
                              <div className="promoGroup">
                                <Input
                                  id="promoGroup"
                                  placeholder="Promo group"
                                  disabled={false}
                                  value={this.state.promoGroup}
                                  onChange={(e) => this.setState({ promoGroup: e.target.value })}
                                />
                                <i
                                  className={this.state.eventDetails.site_specific ? `fa fa-check activeBtn` : `fa fa-check disabledBtn`}
                                  aria-hidden="true"
                                  onClick={this.handlePromogroupCreate}
                                />
                                <i
                                  className={this.state.eventDetails.site_specific ? `fa fa-times activeBtn` : `fa fa-times disabledBtn`}
                                  aria-hidden="true"
                                  onClick={this.handleCancelPromogroupcreate}
                                />

                              </div> :
                              <div className="promoGroup">
                                <Select
                                  placeholder="Select promo group"
                                  options={this.props.promoGroupOptions}
                                  value={this.state.eventDetails.promo_group_id || null}
                                  onChange={(e) => this.handleEventdetailChange(e, 'promo_group_id')}
                                  style={{ width: '100%' }}
                                  allowClear={false}
                                  disabled={this.state.eventDetails.site_specific ? false : true}
                                />
                                <i className={this.state.eventDetails.site_specific ? `fa fa-plus activeBtn` : `fa fa-plus disabledBtn`}
                                  aria-hidden="true"
                                  onClick={this.enableCreatePromoGroup}
                                  disabled={!this.state.eventDetails.site_specific || this.state.isSuccess ? true : false} />
                              </div>
                          }
                        </Col>
                        <Col lg></Col>
                      </Row>
                      {this.state.pageState == VIEW || this.state.pageState == EDIT ? 
                      <Row style={{ marginTop: '10px', marginBottom: '10px' }}>
                      <Col lg style={{ width: '25%' }}>
                        <p className="eventDetailHeader">Created by </p>
                        <p className="eventDetailValue">{this.state.eventDetails.created_by_user} </p>
                      </Col>
                      <Col lg style={{ width: '25%' }}>
                        <p className="eventDetailHeader">Created at </p>
                        <p className="eventDetailValue">{getBrowserTimeZone(this.state.eventDetails.created_at,"MONTH")} </p>
                      </Col>
                      <Col lg style={{ width: '25%' }}>
                        <p className="eventDetailHeader">Changed by </p>
                        <p className="eventDetailValue">{this.state.eventDetails.updated_at == this.state.eventDetails.created_at ? "-" : this.state.eventDetails.updated_by_user} </p>
                      </Col>
                      <Col lg style={{ width: '25%' }}>
                        <p className="eventDetailHeader">Changed at </p>
                        <p className="eventDetailValue">{this.state.eventDetails.updated_at == this.state.eventDetails.created_at ? "-" : getBrowserTimeZone(this.state.eventDetails.updated_at,"MONTH")} </p>
                      </Col>
                      </Row>
                      : null}
                      <Row style={{ marginTop: '10px', marginBottom: '10px', float: 'right' }}>
                        {
                          this.state.pageState == EDIT &&
                          <>
                            <Button
                              type="link"
                              onClick={this.handleCancel}
                            >
                              Cancel
                            </Button>
                            <Button
                              type="primary"
                              disabled={this.state.isBtnDisabled || !this.state.isUpdated || isIt()}
                              onClick={this.handleEdit}
                            >
                              Update
                            </Button>
                          </>
                        }
                        {
                          this.state.pageState == CREATE &&
                          <>
                            <Button
                              type="primary"
                              disabled={this.state.isBtnDisabled || isIt()}
                              onClick={this.handleCreate}
                            >
                              Create
                            </Button>
                          </>
                        }
                        {
                          this.state.pageState == VIEW &&
                          <>
                            <Button
                              type="primary"
                              // disabled={isBtnDisabled}
                              onClick={this.handleCancel}
                            >
                              Close
                            </Button>
                          </>
                        }
                      </Row>
                    </>}
                  </Container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </PageLoader>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    loader: state.eventMaster.loader,
    eventDetails: state.eventMaster.eventDetails,
    campaignOptions: state.eventMaster.campaignOptions,
    campaignData: state.eventMaster.campaignData,
    vehicleData: state.eventMaster.vehicleData,
    lyEventOptions: state.eventMaster.lyEventOptions,
    promoGroupOptions: state.eventMaster.promoGroupOptions,
    lyeventData: state.eventMaster.lyeventData,
  };
};

export default connect(mapStateToProps, {
  getEventDetails,
  getCampaignsData,
  getLYEventData,
  getVehicleData,
  validateMainEvent,
  getPromoGroupData,
  createEvent,
  createPromoGroup,
  updateEvent,
  moveEvent,
  validateMoveEvent,
  overrideEvent ,
  getSignShellOptions
})(withRouter(EditEvent));