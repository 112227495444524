
export default {
    defaultColDef: {
        checkboxSelection: true,
        resizable: true,
        sortable: true,
        filter: true,
    },
    header: [
        {
            headerName: "Campaign List",
            field: "label",
            width: 380,
            //filterParams: { defaultOption: "startsWith" },
            filter: "agTextColumnFilter",
            suppressSizeToFit: true,
            unSortIcon: true
        },

    ]
}
