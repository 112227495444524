import React, { Component } from "react";
import Modal from "react-awesome-modal";
import { Select, Button, } from 'antd';
import { isIt } from "../../../../utils/getRoleName";

const MoveEvent = props => {

  console.log("props", props);
  return (
    <>
      <Modal visible={props.isOpen} width={props.width} height={props.height} effect="fadeInUp" onClickAway={() => props.closeModal()}>
        <div className="modalContent">
          <div className="modalHeader">
            <h5>{props.modalHeader}</h5>
            <i class="fa fa-times" aria-hidden="true" onClick={() => props.closeModal()}></i>
          </div>
          {props.isCampaignDropdown && <>
            <p className="campaignHeader">Campaign</p>
            <Select
              placeholder="Select campaign name"
              // defaultValue="lucy"
              value={props.destinationCampaign ? props.destinationCampaign : []}
              onChange={e => props.onChange(e)}
              options={props.options}
              dropdownStyle={{
                zIndex: 10000
              }
              }
              style={{
                width: '70%'
              }}
            />
          </>
          }
          {props.message && <>
            <p>{props.message}</p>
            <p>
              <strong>Click on :</strong>
              <br />
              <span style={{ fontWeight: 600 }}>Move : </span>To move this event to a campaign which will have a duplicate event type and vehicle.
              <br />
              <span style={{ fontWeight: 600 }}>Overwrite: </span>To overwrite the existing event in the campaign
            </p>
          </>
          }
          <div className="modalButtoncontainer">
            {props.secondaryBtnText &&
              <Button
                type="secondary"
                onClick={() => props.handleSecondaryBtnClick()}
              // style={{
              //   color: "black"
              // }}
              >
                {props.secondaryBtnText}
              </Button>}
            {props.primaryBtnText && <Button
              type="primary"
              disabled={isIt()}
              onClick={props.handlePrimaryBtnClick}
            >
              {props.primaryBtnText}
            </Button>}
          </div>
        </div>
      </Modal>
    </>
  )
}

export default MoveEvent;