import React from "react";
import Modal from "react-awesome-modal";

const AdgroupChoiceModal = props => {
  return (
    <div className="adgrp-choice-modal">
      <Modal
        visible={true}
        width="650px"
        height="175px"
        effect="fadeInDown"
        onClickAway={() => props.showChooseAlert()}
      >
        <div className="closeicon">
          <span style={{ cursor: "pointer" }}>
            <i
              className="fa fa-times"
              aria-hidden="true"
              onClick={() => props.showChooseAlert()}
            />
          </span>
        </div>
        <div className="text-center delete-btns row mx-3">
          <div className="col pr-0">
            <button
              id="add-ly-offers-btn"
              className="btn btn-outline-primary btnCalendarPopover popoverButton"
              onClick={() => props.handleFirstButtonClick()}
              style={{ padding: "4%", fontSize: "0.813rem", width: "100%" }}
              disabled={props.firstBtndisabled || false}
            >
              {props.fisrtBtnText}
            </button>
          </div>
          <div className="col pr-0">
            <button
              id="add-new-offer-btn"
              className="btn btn-outline-primary btnCalendarPopover popoverButton"
              onClick={() => props.handleSecondButtonClick()}
              style={{ padding: "4%", fontSize: "0.813rem", width: "100%" }}
              disabled={props.secondBtndisabled || false}
            >
              {props.secondBtnText}
            </button>
          </div>
          {props.isShowUploadBtn &&
            <div className="col">
              <button
                id="add-new-offer-btn"
                className="btn btn-outline-primary btnCalendarPopover popoverButton"
                onClick={() => props.handleUploadClick()}
                style={{ padding: "4%", fontSize: "0.813rem", width: "100%" }}
                disabled={props.uploadBtndisabled || false}
              >
                {props.uploadText}
              </button>
            </div>
          }
        </div>
      </Modal>
    </div>
  );
};

export default AdgroupChoiceModal;
