import numeral from "numeral";
import moment from "moment";

export function emptyStringValueFormatter(params){
  let field = params.colDef.field,
    value = field ? params.data[field] : "";
    if(value && value.indexOf("$") > -1){
      let splittedVal = value.split('$')
      let numericPart = numeral(splittedVal[1].replace(/\s/g, "")).format("(0.00)")
       return splittedVal[0].toLowerCase().search('for') >=0 ? `${splittedVal[0]} $${numericPart}`: "$" + numericPart
    }
    else if (value) {
      return value;
    } else {
      return "-";
    }
}
export function emptyValueFormatter(value) {
  if (value) {
    var toSting = Math.round(value);
    if (value > 0) {
      return toSting.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else {
      var splitAvalue = Math.abs(toSting);
      return (
        "(" + splitAvalue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ")"
      );
    }
  } else {
    if (value === 0) {
      return 0;
    } else {
      return "-";
    }
  }
}

// export function emptyValueFormatterWithDollar(value) {
//   if (value.value) {
//     var toSting = Math.round(value.value);
//     if (value.colDef.headerName.indexOf("$") > -1) {
//       if (value.value >= 0) {
//         return "$ " + toSting.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
//       } else {
//         var splitAvalue = Math.abs(toSting);
//         return (
//           "$ " +
//           "(" +
//           splitAvalue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
//           ")"
//         );
//       }
//     } else {
//       return toSting.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
//     }
//   } else {
//     return "-";
//   }
// }

// export function emptyValueFormatterForPrice(params) {
//   if (params.value) {
//     let sign = Math.sign(params.value);
//     return sign === 1 || sign === 0
//       ? numeral(params.value).format("0.00")
//       : numeral(params.value).format("(0.00)");
//   } else {
//     return "-";
//   }
// }

// export function dateFormatter(params) {
//   return moment(params.value).format("MM-DD-YYYY");
// }

export function emptyValueFormatterNew(params) {
  if (params.value) {
    let sign = Math.sign(params.value);

    if (params.colDef.headerName.indexOf("$") > -1) {
      return sign === 1 || sign === 0
        ? "$ " + numeral(params.value).format("0,0")
        : "$ " + numeral(params.value).format("(0,0)");
    } else {
      return sign === 1 || sign === 0
        ? numeral(params.value).format("0,0")
        : numeral(params.value).format("(0,0)");
    }
  } else {
    return "-";
  }
}

export function valueFormatterHandler(params) {
  let field = params.colDef.field,
    value = field ? params.data[field] : "";

  if (value !== "") {
    let sign = Math.sign(value);
    let valueVar = Math.round(value * 100) / 100;

    if(params.colDef.headerName.indexOf("$") > -1){
      return value === null || value === "" || !value
        ? "-"
        : (sign === 1 || sign === 0) && valueVar !== 0
        ? "$ " + numeral(valueVar).format("0,0")
        : "$ " + numeral(valueVar).format("(0,0)"); 
    } else {
      return value === null || value === "" || !value
      ? "-"
      : (sign === 1 || sign === 0) && valueVar !== 0
      ? numeral(valueVar).format("0,0")
      : numeral(valueVar).format("(0,0)");
    }

    // return value === null || value === ""
    //   ? "-"
    //   : params.colDef.headerName.indexOf("$") > -1 &&
    //     (sign === 1 || sign === 0) &&
    //     valueVar !== 0
    //   ? "$ " + numeral(valueVar).format("0,0")
    //   : params.colDef.headerName.indexOf("$") > -1 &&
    //     sign !== 1 &&
    //     sign !== 0 &&
    //     valueVar !== 0
    //   ? "$ " + numeral(valueVar).format("(0,0)")
    //   : !params.colDef.headerName.indexOf("$") > -1 &&
    //     (sign === 1 || sign === 0) &&
    //     valueVar !== 0
    //   ? numeral(valueVar).format("0,0")
    //   : numeral(valueVar).format("(0,0)");
  }
  return "-";
}

export function valueAURFormatterHandler(params) {
  let field = params.colDef.field,
    value = field ? params.data[field] : "";

  if (value !== "") {
    let sign = Math.sign(value);
    let valueVar = Math.round(value * 100) / 100;
      return value === null || value === "" || !value
      ? "-"
      : (sign === 1 || sign === 0) && valueVar !== 0
      ? numeral(valueVar)._value
      : numeral(valueVar)._value;
  }
  return "-";
}

export function valueGMFormatterHandler(params) {
  let field = params.colDef.field,
    value = field ? params.data[field] : "";
    
  if ((value || value === 0) && value !== "") {
    let sign = Math.sign(value);
    let valueVar = Math.round(value * 100) / 100;
    return sign === 1 || sign === 0
      ? numeral(valueVar).format("0.00") + " %"
      : numeral(valueVar).format("(0.00)") + " %";
  }
  return "-";
}

export function varPerHandler(params) {
  let field = params.colDef.field,
    filed1,
    field2;
  if (field === "var_quantity") {
    filed1 = "ly_total_units";
    field2 = "ty_total_units";
  }
  if (field === "var_total_revenue") {
    filed1 = "ly_total_sales";
    field2 = "ty_total_sales";
  }
  if (field === "var_total_margin") {
    filed1 = "ly_total_margin";
    field2 = "ty_total_margin";
  }

  if (
    params.data[filed1] !== null &&
    params.data[filed1] !== 0 &&
    params.data[filed1] > 0 &&
    params.data[field2] !== null &&
    params.data[field2] !== 0
  ) {
    let val =
      ((params.data[field2] - params.data[filed1]) / params.data[filed1]) * 100;
    let sign = Math.sign(val);

    return sign === 1 || sign === 0
      ? numeral(val).format("0.00") + " %"
      : numeral(val).format("(0.00)") + " %";
  }
  return "-";
}


export function getBrowserTimeZone(datetime,type){
  var dateUtc = moment.utc(datetime);
  var localDateTime = ""
  switch (type) {
    case "MONTH":
       localDateTime = moment(dateUtc).local().format('MM/DD/YYYY HH:mm:ss')
      break;
    case "YEAR":
        // localDateTime = moment(dateUtc).local().format('YY/MM/DD')
        localDateTime = moment(dateUtc).local().format('MM/DD/YYYY')
       break;
    case "YYYY-MM-DD":
        // localDateTime = moment(dateUtc).local().format('YYYY-MM-DD')
        localDateTime = moment(dateUtc).local().format('MM-DD-YYYY')
       break;

    case "MM-DD-YYYY":
        localDateTime = moment(dateUtc).local().format('MM-DD-YYYY')
       break;

    case "MM/DD/YYYY":
        localDateTime = moment(dateUtc).local().format('MM/DD/YYYY')
       break;
    case "MM/DD/YYYY HH:mm":
        localDateTime = moment(dateUtc).local().format('MM/DD/YYYY HH:mm')
       break;
    default:
      // localDateTime = moment(dateUtc).local().format("YYYY-MM-DD HH:mm:ss");
      localDateTime = moment(dateUtc).local().format("MM-DD-YYYY HH:mm:ss");
      break;
      
  }
  // console.log("DAte Times",dateUtc,datetime,localDateTime)
  return localDateTime;
}

export function getClientTimezone(){
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
  console.log(timeZone);
  return timeZone;
}
export function comparator(date1, date2, format) {
  if (date1 === "-" || date2 === "-") {
    return date1 === date2 ? 0 : date1 === "-" ? 1 : -1;
  }

  const parsedDate1 = moment(date1, format).toDate();
  const parsedDate2 = moment(date2, format).toDate();

  if (parsedDate1 < parsedDate2) {
    return -1;
  } else if (parsedDate1 > parsedDate2) {
    return 1;
  } else {
    return 0;
  }
}
