import React, { Component } from "react";
import { connect } from "react-redux";
import Modal from "react-awesome-modal";
import DatePicker from "react-datepicker";
import PageLoader from "../../../../utils/Loader";
import DateRangePicker from "../../../../utils/DateRangePicker/DateRangePicker.js";
import AlertDanger from "../../../../utils/AlertMessage/alertDanger";
import _ from "lodash";
import moment from "moment";
import { PromotionCalendarGA } from '../../../../utils/GA/GAUtils';

import { createDM } from "../../../../redux/actions/calendarAction";
import { alertTimer } from "../../../../utils/alertTimer";
import {isIt} from '../../../../utils/getRoleName.js'

export class CreateDm extends Component {
  state = {
    showLoader: false,
    date: [],
    DMName: "",
    description: "",
    somethingWentWrong: false,
    merchantLockoutDate: null
  };

  isOutsideRangeHandler = day => {
    let formattedDate = moment(day).format(),
      todayDate = moment().format();
    return moment(todayDate).isAfter(formattedDate);
  };
  onSaveCreateCampaign = async () => {
    let { description, date, merchantLockoutDate } = this.state;
    this.setState({
      showLoader: true
    });
    let response = await this.props.createDM({
      start_date: moment(date.startDate).format("YYYY-MM-DD"),
      end_date: moment(date.endDate).format("YYYY-MM-DD"),
      merchant_lockout_date: merchantLockoutDate == "" || merchantLockoutDate == null ? "" : moment(merchantLockoutDate).format("YYYY-MM-DD")
    });

    if (!response.status) {
      this.setState({ somethingWentWrong: response.msg ? response.msg : true, showLoader: false });
      setTimeout(() => {
        this.setState({ somethingWentWrong: false });
      }, alertTimer);
      return;
    }
    this.setState({
      showLoader: false
    });
    this.props.createDMClickHandler(false, "createDM");
    this.props.onDmCreate();
  };
  submitGA(action, label) {
    PromotionCalendarGA({ action, label });
  }

  getMaxDate() {
    const startDate = this.state.date.startDate;
    // const previousWednesday = startDate.clone().subtract((startDate.day() + 7 - 3) % 7, 'days');
    // const resultDate = previousWednesday.clone().subtract(10, 'days');
    // return new Date(moment(resultDate).format("MM/DD/YYYY"));

  const dateIs = moment(startDate, 'YYYY-MM-DD').subtract(5,'days');
  const startDay = dateIs.day();
  console.log('Current: ', startDay);

  switch (startDay) {
    case 0:
      dateIs.add(-4, 'days');
      break;
    case 1:
      dateIs.add(-5, 'days');
      break;
    case 2:
      dateIs.add(-6, 'days');
      break;
    case 3:
      dateIs.add(-7, 'days');
      break;
    case 4:
      dateIs.add(-1, 'days');
      break;
    case 5:
      dateIs.add(-2, 'days');
      break;
    case 6:
      dateIs.add(-3, 'days');
      break;
    default:
      console.log('Default')
  }
  // dateIs.day(3 - 7)

  const promoBuildDate = dateIs.format('YYYY-MM-DD');
  console.log('promoBuildDate: ', promoBuildDate);
  let marketingLockDate = moment(promoBuildDate, 'YYYY-MM-DD')
  let promoBuildStartDay =  marketingLockDate.day();
  switch (promoBuildStartDay) {
    case 0:
      marketingLockDate.add(-1, 'days');
      break;
    case 1:
      marketingLockDate.add(-2, 'days');
      break;
    case 2:
      marketingLockDate.add(-3, 'days');
      break;
    case 3:
      marketingLockDate.add(-4, 'days');
      break;
    case 4:
      marketingLockDate.add(-5, 'days');
      break;
    case 5:
      marketingLockDate.add(-6, 'days');
      break;
    case 6:
      marketingLockDate.add(-7, 'days');
      break;
    default:
      console.log('Default')
  }

  // dateIs.add(-5, 'days');
  const marketingDate = marketingLockDate.format('YYYY-MM-DD');
  console.log('marketingDate: ', marketingDate);

  const marketingLockoutstartDate = moment(marketingDate, 'YYYY-MM-DD');
  const merchantLockoutDate = marketingLockoutstartDate.add(-1, 'days');

  return new Date(moment(merchantLockoutDate).format("MM/DD/YYYY"));

  }
  render() {
    let { date } = this.state;
    return (
      <div>
        <div className="alertDivs">
          {this.state.somethingWentWrong ? (
            <AlertDanger
              message={
                this.state.somethingWentWrong.length
                  ? this.state.somethingWentWrong
                  : "Something went wrong! Please try again."
              }
              show={true}
            />
          ) : null}
        </div>
        <Modal
          visible="true"
          width="65%"
          height="250" //325
          effect="fadeInDown"
          onClickAway={() => this.props.createDMClickHandler(false)}
        >
          <PageLoader loader={this.state.showLoader}>
            <div className="closeicon">
              <span style={{ cursor: "pointer" }}>
                <i
                  className="fa fa-times"
                  aria-hidden="true"
                  onClick={() => this.props.createDMClickHandler(false)}
                />
              </span>
              <h5 className="promoNameText text-center mt-2">Create campaign</h5>
            </div>

            <div className="createClusterDiv create-cluster-edit-dm">
              <div className="row editDm-selection-styling">
                <div className="col-4 date-picker-coupon text-center">
                  <p className="labelAdGroup mb-0">Time Period</p>
                  <div
                    style={{ textAlign: "center" }}
                    className="d-inline-block ml-2"
                  >
                    <DateRangePicker
                      startDate={this.state.date.startDate}
                      endDate={this.state.date.endDate}
                      onDatesChange={date => {
                        this.setState({
                          date,
                          DMName:
                            date.startDate && date.endDate
                              ? moment(date.startDate).format("MM/DD/YYYY")
                              : ""
                        });
                        this.submitGA("Click", "Selected_CreateDM_Date");
                      }}
                      isOutsideRange={day => this.isOutsideRangeHandler(day)}
                    />
                  </div>
                </div>

                <div className="col-4 text-center">
                  <p className="labelAdGroup mb-0">Campaign Name</p>
                  <input
                    type="text"
                    className="adGroupInputStyling ml-2 edit-dm-input"
                    disabled={true}
                    // value={this.state.DMName}
                    value= {this.state.date.startDate && this.state.date.endDate ?  `${moment(this.state.date.startDate).format("MM/DD/YYYY")} - ${moment(this.state.date.endDate).format("MM/DD/YYYY")}` : null }
                  />
                </div>
                <div className="col-4 text-center merchant-lockout">
                  <p className="labelAdGroup mb-0">Merchant Lockout Date</p>
                  <DatePicker
                    className="datePickerValueStyling"
                    selected={
                      this.state.merchantLockoutDate ?
                        this.state.merchantLockoutDate
                        : ""
                    }
                    onChange={(date) => {
                      this.setState({
                        merchantLockoutDate: date
                      })
                      // this.submitGA("Click", "Clicked_CalendarDropdown");
                    }}
                    dateFormat="MM/dd/yyyy"
                    minDate={
                      new Date()
                    }
                    maxDate={
                      this.state.date.startDate ? this.getMaxDate() : new Date()
                    }
                    placeholderText="Select Merchant Lockout Date"
                    disabled={!this.state.date.startDate}
                    showIcon
                    popperPlacement="bottom-end"
                    popperProps={{
                      positionFixed: true
                    }}
                  />
                </div>
              </div>



              {/* <div className="mx-3 my-4">
                <p className="labelAdGroup mb-0">Description</p>
                <input
                  type="text"
                  className="form-control card commentAreaInput"
                  rows="5"
                  placeholder="Description"
                  value={this.state.description}
                  onChange={e => this.setState({ description: e.target.value })}
                />
              </div> */}
            </div>

            <div className="text-center mt-4 pt-2">
              <button
                type="button"
                className="btn btn-outline-primary promo3Buttons"
                disabled={isIt() || (date.startDate && date.endDate &&  this.state.merchantLockoutDate? false : true)}
                onClick={() => {
                  this.onSaveCreateCampaign();
                  this.submitGA("Click", "Clicked_CreateDM_CreateButton");
                }}
              >
                Create
              </button>
            </div>
          </PageLoader>
        </Modal>
      </div>
    );
  }
}

export default connect(null, { createDM })(CreateDm);
