import React from "react";
import Modal from "react-awesome-modal";
import PageLoader from "../../../../utils/Loader";
import DataTable from "../../../tables";
import ColumnDef from "../../../tables/colDefs/SkuListColDefs";
import "../ReferenceSku/ReferenceSkuStyling.css";
import RefSkuChildMessageRendrer from "../ReferenceSku/RefSkuChildMessageRendrer";
import RefSkuActionRenderer from "../ReferenceSku/RefSkuActionRenferer";
import ReferenceSkuDetails from "../../../../utils/ReferenceSkuDetails";
import { AdgroupMasterGA } from '../../../../utils/GA/GAUtils';
import { Row, Col } from 'react-bootstrap';
import { Typography } from 'antd';
import _ from "lodash";

const { Text } = Typography;

class SkuModalForAdgroupCreate extends React.Component {
  state = {
    skuListExport: false,
    showLoader: false,
    z1Min: 0,
    z1Max: 0,
    z3Min: 0,
    z3Max: 0,
    z4Min: 0,
    z4Max: 0,
    z5Min: 0,
    z5Max: 0
  };

  componentDidMount() {

    const data = _.get(this.props, 'tableData', []);
        let z1Min = _.get(data[0], 'zone1_retail_price', 0);
        let z1Max = _.get(data[0], 'zone1_retail_price', 0);
        let z3Min = _.get(data[0], 'zone3_retail_price', 0);
        let z3Max = _.get(data[0], 'zone3_retail_price', 0);
        let z4Min = _.get(data[0], 'zone4_retail_price', 0);
        let z4Max = _.get(data[0], 'zone4_retail_price', 0);
        let z5Min = _.get(data[0], 'zone5_retail_price', 0);
        let z5Max = _.get(data[0], 'zone5_retail_price', 0);
        _.map(data, (row) => {
          if(row.zone1_retail_price < z1Min){
            z1Min = row.zone1_retail_price;
          }
          if(row.zone1_retail_price > z1Max){
            z1Max = row.zone1_retail_price;
          }
          if(row.zone3_retail_price < z3Min){
            z3Min = row.zone3_retail_price;
          }
          if(row.zone3_retail_price > z3Max){
            z3Max = row.zone3_retail_price;
          }
          if(row.zone4_retail_price < z4Min){
            z4Min = row.zone4_retail_price;
          }
          if(row.zone4_retail_price > z4Max){
            z4Max = row.zone4_retail_price;
          }
          if(row.zone5_retail_price < z5Min){
            z5Min = row.zone5_retail_price;
          }
          if(row.zone5_retail_price > z5Max){
            z5Max = row.zone5_retail_price;
          }
  })

  this.setState({
    z1Min,
    z1Max,
    z3Min,
    z3Max,
    z4Min,
    z4Max,
    z5Min,
    z5Max
  })

  }

  componentDidUpdate(prevProps) {
    if (prevProps.tableData !== this.props.tableData) {
        const data = _.get(this.props, 'tableData', []);
        let z1Min = _.get(data[0], 'zone1_retail_price', 0);
        let z1Max = _.get(data[0], 'zone1_retail_price', 0);
        let z3Min = _.get(data[0], 'zone3_retail_price', 0);
        let z3Max = _.get(data[0], 'zone3_retail_price', 0);
        let z4Min = _.get(data[0], 'zone4_retail_price', 0);
        let z4Max = _.get(data[0], 'zone4_retail_price', 0);
        let z5Min = _.get(data[0], 'zone5_retail_price', 0);
        let z5Max = _.get(data[0], 'zone5_retail_price', 0);
        _.map(data, (row) => {
          if(row.zone1_retail_price < z1Min){
            z1Min = row.zone1_retail_price;
          }
          if(row.zone1_retail_price > z1Max){
            z1Max = row.zone1_retail_price;
          }
          if(row.zone3_retail_price < z3Min){
            z3Min = row.zone3_retail_price;
          }
          if(row.zone3_retail_price > z3Max){
            z3Max = row.zone3_retail_price;
          }
          if(row.zone4_retail_price < z4Min){
            z4Min = row.zone4_retail_price;
          }
          if(row.zone4_retail_price > z4Max){
            z4Max = row.zone4_retail_price;
          }
          if(row.zone5_retail_price < z5Min){
            z5Min = row.zone5_retail_price;
          }
          if(row.zone5_retail_price > z5Max){
            z5Max = row.zone5_retail_price;
          }
        })
    
        this.setState({
          z1Min,
          z1Max,
          z3Min,
          z3Max,
          z4Min,
          z4Max,
          z5Min,
          z5Max
        })
      }
    }
  // } 
  

  skuListDownloadHandler = () => {
    this.setState({ skuListExport: !this.state.skuListExport });
  };

  submitGA(action,label){
    AdgroupMasterGA({ action, label});
  }

  render() {
    const {
      z1Min,
      z1Max,
      z3Min,
      z3Max,
      z4Min,
      z4Max,
      z5Min,
      z5Max
    } = this.state;
    let tableData = [],
      refSkuDetailTableData = [];
    if (this.props.tableData) {
      tableData = {
        header: ColumnDef.skuHeader,
        defaultColDef: ColumnDef.defaultColDef,
        data: this.props.tableData.length ? this.props.tableData : []
      };
    }
    if (this.props.refSkuInfoResponse) {
      refSkuDetailTableData = {
        header: ColumnDef.skuHeader,
        defaultColDef: ColumnDef.defaultColDef,
        data: this.props.refSkuInfoResponse ? this.props.refSkuInfoResponse : []
      };
    }

    const totalSKU = _.size(this.props.tableData);
    // console.log('totalSKU:', totalSKU);

    return (
      <Modal
        visible={true}
        width="90%"
        height="85%"
        effect="fadeInDown"
        onClickAway={() => this.props.closeSkuModal()}
      >
        <PageLoader loader={this.props.showLoader}>
          <div className="closeicon">
            <span style={{ cursor: "pointer" }}>
              <i
                className="fa fa-times"
                aria-hidden="true"
                onClick={() => this.props.closeSkuModal()}
              />
            </span>
            <h5 className="promoNameText text-center">SKU List</h5>
          </div>

          
          <Row>
            <Col>
              <div>
                <button
                  type="button"
                  className="btn pipeline-button-class mr-3 float-right"
                  title="Download"
                  disabled={this.props.tableData.length ? false : true}
                  onClick={() => {
                    this.skuListDownloadHandler();
                    this.submitGA("Click", "Clicked_Active_Inactive_Adgroup_DownloadSKUListButton");
                  }}
                >
                  <i className="fa fa-download" aria-hidden="true" />
                </button>
              </div>
            </Col>
          </Row>

          <Row>
            <Col></Col>
          </Row>

          <Row style={{ marginLeft: '2px'}}>
            <Col   md={4} lg="auto" >
              <Text className="summaryPText">Zone1 PriceRange <b>${z1Min} - ${z1Max}</b></Text>
           </Col>
            <Col   md={4} lg="auto" >
              <Text className="summaryPText">Zone3 PriceRange <b>${z3Min} - ${z3Max}</b></Text>
            </Col>
            <Col   md={4} lg="auto" >
              <Text className="summaryPText">Zone4 PriceRange <b>${z4Min} - ${z4Max}</b></Text>
            </Col>
            <Col   md={4} lg="auto" >
              <Text className="summaryPText">Zone5 PriceRange <b>${z5Min} - ${z5Max}</b></Text>
            </Col>
            <Col md={4} lg="auto">
              <Text className="summaryPText">No. of SKUs <b> - {totalSKU} </b></Text>
            </Col>
          </Row>

          <div
            className="card reference-sku-table-cont"
            style={{ width: "100%", height: "80%" }}
          >
            <DataTable
              tableData={tableData}
              parentId="delete-ag-grid"
              overlayNoRowsTemplate='<span class="pipeline-span">No Data to show</span>'
              animateRows={true}
              refreshRows={this.props.refreshRows}
              refreshRowsHandler={this.props.refreshRowsHandler}
              // autoSizeCol={true} //col messed up if uncommented
              frameworkComponents={{
                refSkuChildMessageRenderer: RefSkuChildMessageRendrer,
                refSkuActionRenderer: RefSkuActionRenderer
              }}
              skuListDownload={this.state.skuListExport}
              downloadName="sku_list_table"
              skuListDownloadHandler={this.skuListDownloadHandler}
            />
            {this.props.footnote.length ? (
              <div className="card">
                <p className="footnotSKuList">
                  <i className="fa fa-exclamation-triangle" />
                  {this.props.footnote.length ? this.props.footnote : ""}
                </p>
              </div>
            ) : null}
          </div>
        </PageLoader>

        {this.props.showDetailPopup ? (
          <Modal
            visible={true}
            width="1000px"
            height="auto"
            effect="fadeInDown"
            onClickAway={() => this.props.closeDetailPopup()}
          >
            <PageLoader loader={!this.props.refSkuInfoResponse.length}>
              <div className="closeicon">
                <span style={{ cursor: "pointer" }}>
                  <i
                    className="fa fa-times"
                    aria-hidden="true"
                    onClick={() => this.props.closeDetailPopup()}
                  />
                </span>
              </div>
              <h5 className="text-center font-weight-bold mt-1">
                Reference SKU details
              </h5>
              <ReferenceSkuDetails infoRefSkuData={refSkuDetailTableData} />
            </PageLoader>
          </Modal>
        ) : null}
      </Modal>
    );
  }
}

export default SkuModalForAdgroupCreate;