import React, { useState, useEffect } from "react";
import axios from "../../../../utils/axios";
// import { Select } from 'antd';
import {config} from "../../../../utils/config";
import Select from "react-select";
import _ from "lodash";
import OfferTypes from "../../../../utils/OfferTypes";
import moment from "moment";
import { Container, Row, Col } from 'react-bootstrap';
import {
  SIGNSHELL_OPTIONS,
  UAS_OPTIONS,
  PROMO_TYPE_OPTIONS
} from "./constants";

// import { EVENT_SIGNSHELL_CONSTANTS } from "../EventMaster/constants";

import { Button } from 'antd';
import { CalculatorOutlined } from '@ant-design/icons';
import {isIt} from "../../../../utils/getRoleName";

const AddAdgroupRow = props => {

  const [EVENT_SIGNSHELL_CONSTANTS, SET_EVENT_SIGNSHELL_CONSTANTS] = useState([]);

  useEffect(() => {
    fetchSignShellOptions();
  }, []);

  const fetchSignShellOptions = async () => {
    try {
      const response = await axios.get(
        `${config.baseUrl}/promo/signShellValues`
      );
      const data = response.data;
      console.log(data);
      SET_EVENT_SIGNSHELL_CONSTANTS(data.signShell);
    } catch (error) {
      console.error("Error fetching sign shell options:", error);
    }
  };


  const currentOfferType = props.row.offerType && props.row.offerType.value? props.row.offerType.value : null;
  const filterOption = (input, option) =>
    ((option && option.label) ? option.label : '').toLowerCase().includes(input.toLowerCase());
  const campaignMerchLockoutDate = localStorage.getItem("campMerchLockoutDate");
  const loggedUserRole = localStorage.getItem("role");

  return (
    <div className="createClusterDiv">
      <Container fluid>
        <Row style={{ marginTop: '10px', marginBottom: '10px' }}>
          <Col lg>
            <p className="eventDetailHeader">Ad Group <span style={{ color: "red", marginLeft: "2px" }}>*</span></p>
            {/* <Select
              showSearch
              placeholder="Select Adgroup"
              optionFilterProp="children"
              onChange={val =>
                props.rowValueUpdateHandler(props.row.key, "adgroupName", val)
              }
              onSearch={val => {
                props.adgroupKeyDownHandler(val.toUpperCase());
              }}
              filterOption={filterOption}
              options={props.adgroupOptionArray}
              isDisabled={props.row.disableRowSave ? true : false}
            /> */}
            <Select
              placeholder="Select Adgroup"
              isDisabled={props.row.disableRowSave ? true : false}
              options={props.adgroupOptionArray}
              value={props.row.adgroupName}
              onInputChange={val => {
                props.adgroupKeyDownHandler(val.toUpperCase());
              }}
              onChange={val =>
                props.rowValueUpdateHandler(props.row.key, "adgroupName", val)
              }
            />
          </Col>
          <Col lg>
            <p className="eventDetailHeader">Ad group type</p>
            <input
              placeholder="Ad group type"
              autocomplete="off"
              className="adGroupInputStyling"
              disabled={true}
              id="adGroupType"
              value={props.row.adgroupType || '-'}
            />
          </Col>
          <Col lg>
            <p className="eventDetailHeader">Merch ID</p>
            <input
              placeholder="Merch ID"
              autocomplete="off"
              className="adGroupInputStyling"
              disabled={true}
              id="merchId"
              value={props.row.merchId}
            />
          </Col>
          <Col lg>
            <p className="eventDetailHeader">Event <span style={{ color: "red", marginLeft: "2px" }}>*</span></p>
            <Select
              placeholder="Event"
              options={props.eventTypeOptions}
              onChange={value =>
                props.rowValueUpdateHandler(
                  props.row.key,
                  "eventType",
                  value.event_type,
                  value
                )
              }
              isDisabled={
                !_.size(props.row.adgroupName) || props.row.disableRowSave
                  ? true
                  : false
              }
              value={props.row.selectedEvent}
            />
          </Col>
          <Col lg>
            <p className="eventDetailHeader">Event ID</p>
            <input
              placeholder="Event ID"
              autocomplete="off"
              className="adGroupInputStyling"
              disabled={true}
              id="eventId"
              value={props.row.eventGId}
            />
          </Col>
          <Col lg>
            <p className="eventDetailHeader">Event description</p>
            <input
              placeholder="Event description"
              autocomplete="off"
              className="adGroupInputStyling"
              disabled={true}
              id="eventDescription"
              value={props.row.eventDesc}
            />
          </Col>
          <Col lg>
            <p className="eventDetailHeader">Sign shell</p>
            <Select
              placeholder="Sign shell"
              options={EVENT_SIGNSHELL_CONSTANTS}
              onChange={value =>
                props.rowValueUpdateHandler(
                  props.row.key,
                  "promo_sign_shell",
                  value
                )
              }
              isDisabled={
                props.row.disableRowSave || !_.size(props.row.selectedEvent) || (!(loggedUserRole == 3 || loggedUserRole == 1)) 
                  ? true
                  : false
              }
              value={props.row.promo_sign_shell && props.row.promo_sign_shell.length > 0 ? props.row.promo_sign_shell[0] : []}
            />
          </Col>
        </Row>
        <Row style={{ marginTop: '10px', marginBottom: '10px' }}>
          <Col lg>
            <p className="eventDetailHeader">Offer Type <span style={{ color: "red", marginLeft: "2px" }}>*</span></p>
            <Select
              // className="create-adgrp-select"
              placeholder="Offer type"
              options={OfferTypes.newAdgroupOfferTypes}
              isDisabled={
                !_.size(props.row.selectedEvent) || props.row.disableRowSave
                  ? true
                  : false
              }
              value={props.row.offerType}
              onChange={val =>
                props.rowValueUpdateHandler(props.row.key, "offerType", val)
              }
            />
          </Col>
          <Col lg>
            <p className="eventDetailHeader">Planned Offer Amt.</p>
            <input
              type="number"
              autocomplete="off"
              placeholder="Planned offer amt"
              className="adGroupInputStyling"
              disabled={
                !_.size(props.row.offerType) ||
                  props.row.disableRowSave ||
                  (props.row.offerType && props.row.offerType.value === "G")
                  ? true
                  : false
              }
              id="groupNameInput"
              value={props.row.offerValue}
              onChange={e =>
                props.rowValueUpdateHandler(
                  props.row.key,
                  "offerValue",
                  e.target.value
                )
              }
            />
          </Col>
          <Col lg>
          {/* {currentOfferType=='F'? <span style={{ color: "red", marginLeft: "2px" }}>*</span> : null} */}
            <p className="eventDetailHeader">Fixed price qty.</p>
            <input
              type="number"
              autocomplete="off"
              placeholder="Fixed price qty"
              className="adGroupInputStyling"
              disabled={
                !_.size(props.row.offerType) ||
                  props.row.disableRowSave ||
                  (props.row.offerType && props.row.offerType.value !== "F")
                  ? true
                  : false
              }
              id="groupNameInput"
              value={props.row.fixedPriceQty}
              onChange={e =>
                props.rowValueUpdateHandler(
                  props.row.key,
                  "fixedPriceQty",
                  e.target.value
                )
              }
            />
          </Col>
          <Col lg>
            <p className="eventDetailHeader">Min Qty. for offer</p>
            <input
              type="number"
              placeholder="Min Qty for Offer"
              className="adGroupInputStyling"
              autocomplete="off"
              disabled={
                !_.size(props.row.offerType) ||
                  props.row.disableRowSave ||
                  (props.row.offerType &&
                    (props.row.offerType.value === "P" ||
                      props.row.offerType.value === "F" ||
                      props.row.offerType.value === "A"))
                  ? true
                  : false
              }
              id="groupNameInput"
              value={props.row.minQtyForOffer}
              onChange={e =>
                props.rowValueUpdateHandler(
                  props.row.key,
                  "minQtyForOffer",
                  e.target.value
                )
              }
            />
          </Col>
          <Col lg>
            <p className="eventDetailHeader">Bonus Buy</p>
            <input
              type="number"
              placeholder="Bonus buy"
              autocomplete="off"
              className="adGroupInputStyling"
              disabled={
                !_.size(props.row.offerType) ||
                  props.row.disableRowSave ||
                  (props.row.offerType &&
                    (props.row.offerType.value !== "G" &&
                      props.row.offerType.value !== "B" &&
                      props.row.offerType.value !== "C"))
                  ? true
                  : false
              }
              id="groupNameInput"
              value={props.row.bonusBuy}
              onChange={e =>
                props.rowValueUpdateHandler(
                  props.row.key,
                  "bonusBuy",
                  e.target.value
                )
              }
            />
          </Col>
          <Col lg>
            <p className="eventDetailHeader">Buy $ (Min Value)</p>
            <input
              type="number"
              placeholder="Buy $ (min value)"
              autocomplete="off"
              className="adGroupInputStyling"
              disabled={
                !_.size(props.row.offerType) ||
                  props.row.disableRowSave ||
                  (props.row.offerType &&
                   (props.row.offerType.value !== "C" &&  props.row.offerType.value !== "G") )
                  ? true
                  : false
              }
              id="groupNameInput"
              value={props.row.ty_buy_min_value}
              onChange={e =>
                props.rowValueUpdateHandler(
                  props.row.key,
                  "ty_buy_min_value",
                  e.target.value
                )
              }
            />
          </Col>
          <Col lg>
            <p className="eventDetailHeader">Get $ (Max Value)</p>
            <input
              type="number"
              placeholder="Get $ (max value)"
              autocomplete="off"
              className="adGroupInputStyling"
              disabled={
                !_.size(props.row.offerType) ||
                  props.row.disableRowSave ||
                  (props.row.offerType &&
                    (props.row.offerType.value !== "C" &&  props.row.offerType.value !== "G"))
                  ? true
                  : false
              }
              id="groupNameInput"
              value={props.row.ty_get_max_value}
              onChange={e =>
                props.rowValueUpdateHandler(
                  props.row.key,
                  "ty_get_max_value",
                  e.target.value
                )
              }
            />
          </Col>
        </Row>
        <Row style={{ marginTop: '10px', marginBottom: '10px' }}>
          <Col lg>
            <p className="eventDetailHeader">Promo type</p>
            <Select
              placeholder="Promo type"
              options={PROMO_TYPE_OPTIONS}
              onChange={value =>
                props.rowValueUpdateHandler(
                  props.row.key,
                  "promo_event_type",
                  value
                )
              }
              isDisabled={
                props.row.disableRowSave || !_.size(props.row.selectedEvent) || (!(loggedUserRole == 3 || loggedUserRole == 1)) 
                  ? true
                  : false
              }
              value={props.row.promo_event_type && props.row.promo_event_type.length > 0 ? props.row.promo_event_type[0] : []}
            />
          </Col>
          <Col lg>
            <p className="eventDetailHeader">UAS</p>
            <Select
              placeholder="UAS"
              options={UAS_OPTIONS}
              onChange={value =>
                props.rowValueUpdateHandler(
                  props.row.key,
                  "uas",
                  value
                )
              }
              // isDisabled={
              //   !_.size(props.row.adgroupName) || props.row.disableRowSave || !_.size(props.row.selectedEvent)
              //     ? true
              //     : false
              // }
              value={props.row.uas && props.row.uas.length > 0 ? props.row.uas : UAS_OPTIONS[1]}
            />
          </Col>
          <Col lg>
            <p className="eventDetailHeader">Reg Low Amount</p>
            <input
              placeholder="Reg Low Amount"
              autocomplete="off"
              className="adGroupInputStyling"
              disabled={true}
              id="regLow"
              value={props.row.regLow || props.row.regLow == 0 ? props.row.regLow : '-'}
            />
          </Col>
          <Col lg>
            <p className="eventDetailHeader">Reg High Amount</p>
            <input
              placeholder="Reg High Amount"
              autocomplete="off"
              className="adGroupInputStyling"
              disabled={true}
              id="regHigh"
              value={props.row.regHigh || props.row.regHigh == 0 ? props.row.regHigh : '-'}
            />
          </Col>
          <Col lg>
            <p className="eventDetailHeader">Sale Low Amount</p>
            <input
              placeholder="Sale Low Amount"
              autocomplete="off"
              className="adGroupInputStyling"
              disabled={true}
              id="saleLow"
              value={props.row.saleLow || props.row.saleLow == 0 ? props.row.saleLow : '-'}
            />
          </Col>
          <Col lg>
            <p className="eventDetailHeader">Sale High Amount</p>
            <input
              placeholder="Sale High Amount"
              autocomplete="off"
              className="adGroupInputStyling"
              disabled={true}
              id="saleHigh"
              value={props.row.saleHigh || props.row.saleHigh == 0 ? props.row.saleHigh : '-'}
            />
          </Col>
          <Col lg>
          <p className="eventDetailHeader">Promo Status</p>
            <input
              placeholder="Promo Satus"
              autocomplete="off"
              className="adGroupInputStyling"
              disabled={true}
              id="promo_status"
              value={props.promoBuildStatus}
            />
          </Col>
        </Row>
        <Row style={{ marginTop: '10px', marginBottom: '10px' }}>
          <Col lg style={{ display: 'flex', gap: '10px', justifyContent: 'right' }}>
            <Button
              type="primary"
              title="Calculate reg sales price"
              onClick={() => {
                props.getRegSalesPrices(props.row.key);
                // this.submitGA("Click", "Clicked_EditAdgroupButton");
              }}
              disabled={
                !_.size(props.row.offerType) || props.row.disableRowSave || !_.size(props.row.adgroupName)
                  ? true
                  : false
              }
              icon={<CalculatorOutlined />}
            >
            </Button>
            <Button
              onClick={() =>
                props.SKUListHandler(props.row.adgroupName.adgroupId)
              }
              disabled={
                !_.size(props.row.adgroupName) // !_.size(props.row.offerType) || props.row.disableRowSave -> removed to fix LT-242
                  ? true
                  : false
              }
            >
              View SKU list
            </Button>
            <Button
              type="primary"
              data-toggle="tooltip"
              data-placement="top"
              title="Save"
              onClick={() => props.saveAdgroupValues(props.row.key, false)}
              disabled={
                !_.size(props.row.offerType) || props.row.disableRowSave || !_.size(props.row.adgroupName) || !_.size(props.row.selectedEvent) || isIt()
                  ? true
                  : false
              }
            >
              Save
            </Button>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AddAdgroupRow;

