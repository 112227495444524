import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import "./couponStyling.css";
import CreateCouponsMain from "./CreateCouponsMain";

export class EditCoupons extends Component {
  render() {
    return <CreateCouponsMain editCoupon={true} />;
  }
}

const mapStateToProps = state => ({});

export default connect(mapStateToProps, {})(withRouter(EditCoupons));
