import moment from "moment";
import {
  emptyValueFormatterNew
} from "../../../utils/valueFormatters.js";

export default {
  defaultColDef: {
    headerCheckboxSelection: isFirstColumn,
    headerCheckboxSelectionFilteredOnly: true,
    checkboxSelection: isFirstColumn,
    resizable: true,
    sortable: true,
    filter: true
  },
  header: function (editOffers) {
    return [
      {
        headerName: "Adgroup ID",
        field: "adgroup_id",
        hide: "true"
      },
      {
        headerName: "Adgroup",
        field: editOffers ? "name" : "adgroup_name",
        headerClass: "gray-header-bg",
        width : 150,
        headerTooltip : "Adgroup",
        tooltipField : "Adgroup",
        pinned: "left",
        filter: "agTextColumnFilter",
        suppressSizeToFit: true,
        cellStyle: null,
        unSortIcon: true
      },
      {
        headerName: "Adgroup Desc.",
        field: editOffers ? "adgroup_description" : "adgroup_desc",
        filter: "agTextColumnFilter",
        headerClass: "gray-header-bg",
        suppressSizeToFit: true,
        pinned: "left",
        unSortIcon: true,
        headerTooltip : "Adgroup Desc.",
        tooltipField : "Adgroup Desc."
      },
      {
        headerName: "Adgroup Type",
        field: editOffers ? "adgroup_type" : "adgroup_type",
        filter: "agTextColumnFilter",
        headerClass: "blue-header-bg",
        suppressSizeToFit: true,
        unSortIcon: true,
        width : 150,
        headerTooltip : "Adgroup Type",
        tooltipField : "Adgroup Type",
        hide : editOffers ? true : false
      },
      {
        headerName: "Merch ID",
        field: editOffers ? "merch_id" : "merch_id",
        filter: "agTextColumnFilter",
        headerClass: "blue-header-bg",
        suppressSizeToFit: true,
        unSortIcon: true,
        width : 150,
        headerTooltip : "Merch ID",
        tooltipField : "Merch ID"
      },
      {
        headerName: editOffers ? "Event" : "TY event",
        field: editOffers ? "selected_ty_event" : "ty_event_type",
        headerClass: "blue-header-bg",
        width: 350,
        cellRenderer: editOffers ? "pipelineDropdownRenderer" : "pipelineDropdownRenderer",
        // cellRenderer: "pipelineDropdownRenderer",
        valueGetter: promoTypeFormatter,
        filter: "agTextColumnFilter",
        unSortIcon: true,
        headerTooltip : editOffers ? "Event" : "TY event",
        tooltipField : editOffers ? "Event" : "TY event"
      },
      { headerName: "TY Start Date", field: editOffers ? "start_date" : "ty_start_date", hide: true },
      { headerName: "TY End Date", field: editOffers ? "end_date" : "ty_end_date", hide: true },
      { headerName: "Offer quantity", field: "offer_quantity", hide: true },
      {
        headerName: "Event ID",
        field: editOffers ? "g_eid" : "g_eid",
        headerClass: "blue-header-bg",
        filter: "agTextColumnFilter",
        valueGetter: eventIdGetter,
        unSortIcon: true,
        width : 150,
        headerTooltip : "Event ID",
        tooltipField : "Event ID"
      },
      {
        headerName: editOffers ? "Sign Shell" : "TY Sign shell",
        field: editOffers ? "promo_sign_shell" : "promo_sign_shell",
        headerClass: "blue-header-bg",
        // width: 300,
        cellRenderer: "pipelineDropdownRenderer",
        filter: "agTextColumnFilter",
        unSortIcon: true,
        width: 260,
        headerTooltip : editOffers ? "Sign Shell" : "TY Sign shell",
        tooltipField : editOffers ? "Sign Shell" : "TY Sign shell"
      },
      {
        headerName: editOffers ? "Promo Type" : "TY promo type",
        field: editOffers ? "promo_event_type" : "promo_event_type",
        headerClass: "blue-header-bg",
        // width: editOffers ? 150 : 200,
        width : 250,
        cellRenderer: "pipelineDropdownRenderer",
        filter: "agTextColumnFilter",
        unSortIcon: true,
        headerTooltip : editOffers ? "Promo Type" : "TY promo type",
        tooltipField : editOffers ? "Promo Type" : "TY promo type"
      },
      {
        headerName: editOffers ? "UAS" : "TY UAS",
        field: editOffers ? ("ty_uas" ? "ty_uas" : "uas") : "ty_uas",
        headerClass: "blue-header-bg",
        // width : editOffers ? 150 : 150,
        width : 250,
        cellRenderer: "pipelineDropdownRenderer",
        filter: "agTextColumnFilter",
        unSortIcon: true,
        headerTooltip: editOffers ? "UAS" : "TY UAS",
        tooltipField : editOffers ? "UAS" : "TY UAS",
      },
      {
        headerName: editOffers ? "Promo Status" : "TY promo status",
        field: editOffers ? "promo_build_status" : "promo_build_status",
        headerClass: "blue-header-bg",
        width : editOffers ? 150 : 200,
        // cellRenderer: "pipelineDropdownRenderer",
        filter: "agTextColumnFilter",
        unSortIcon: true,
        headerTooltip : editOffers ? "Promo Status" : "TY promo status",
        tooltipField : editOffers ? "Promo Status" : "TY promo status"
      },
      {
        headerName: "Event type options", //Non-view purpose, used only for storing options value change
        field: "eventTypeOptions",
        headerClass: "blue-header-bg",
        filter: "agTextColumnFilter",
        unSortIcon: true,
        hide: true
      },
      {
        headerName: editOffers ? "Offer Type" : "TY Planned Offer Type",
        field: editOffers ? "offer_type" : "ty_offer_type",
        headerClass: "blue-header-bg",
        // width : editOffers ? 150 : 225,
        width : 280,
        cellRenderer: "pipelineDropdownRenderer",
        filter: "agTextColumnFilter",
        unSortIcon: true,
        headerTooltip : editOffers ? "Offer Type" : "TY Planned Offer Type",
        tooltipField : editOffers ? "Offer Type" : "TY Planned Offer Type"
      },
      {
        headerName: "Offer type options", //Non-view purpose, used only for storing options value change
        field: "offerTypeOptions",
        filter: "agTextColumnFilter",
        headerClass: "blue-header-bg",
        unSortIcon: true,
        hide: true
      },
      {
        headerName: editOffers ? "Offer Amt" : "TY Planned Offer Amount",
        field: editOffers ? "offer_value" : "ty_planned_amount",
        filter: "agTextColumnFilter",
        width : editOffers ? 150 : 230,
        tooltipField : "TY Planned Offer Amount",
        headerClass: "blue-header-bg",
        cellRenderer: "pipelineInputRenderer",
        suppressSizeToFit: true,
        unSortIcon: true,
        headerTooltip : editOffers ? "Offer Amt" : "TY Planned Offer Amount",
        tooltipField : editOffers ? "Offer Amt" : "TY Planned Offer Amount"
      },
      {
        headerName: "Fixed price Qty",
        field: "fixed_price_quantity",
        filter: "agTextColumnFilter",
        width : 200,
        headerClass: "blue-header-bg",
        cellRenderer: "pipelineInputRenderer",
        suppressSizeToFit: true,
        unSortIcon: true,
        headerTooltip : "Fixed price Qty",
        tooltipField : "Fixed price Qty"
      },
      {
        headerName: editOffers ? "Min Qty" : "TY Min Qty for offer",
        field: editOffers ? "min_quantity" : "ty_min_qty",
        filter: "agTextColumnFilter",
        headerClass: "blue-header-bg",
        width : editOffers ? 150 : 210,
        suppressSizeToFit: true,
        cellRenderer: "pipelineInputRenderer",
        unSortIcon: true,
        headerTooltip : editOffers ? "Min Qty" : "TY Min Qty for offer",
        tooltipField : editOffers ? "Min Qty" : "TY Min Qty for offer"
      },
      {
        headerName: editOffers ? "Bonus Buy" : "TY Bonus Buy (Free Goods Qty)",
        field: editOffers ? "bonus_by_quantity" : "ty_bonus_pay",
        filter: "agTextColumnFilter",
        headerClass: "blue-header-bg",
        width: editOffers ? 150 : 275,
        cellRenderer: "pipelineInputRenderer",
        suppressSizeToFit: true,
        unSortIcon: true,
        headerTooltip : editOffers ? "Bonus Buy" : "TY Bonus Buy (Free Goods Qty)",
        tooltipField : editOffers ? "Bonus Buy" : "TY Bonus Buy (Free Goods Qty)"
      },
      {
        headerName: editOffers ? "Min Value (Buy $)" : "TY (Buy $) Min value",
        field: "ty_buy_min_value",
        filter: "agTextColumnFilter",
        headerClass: "blue-header-bg",
        cellRenderer: "pipelineInputRenderer",
        width: editOffers ? 200 : 210,
        suppressSizeToFit: true,
        // filterParams: { defaultOption: "startsWith" },
        unSortIcon: true,
        headerTooltip : editOffers ? "Min Value (Buy $)" : "TY (Buy $) Min value",
        tooltipField : editOffers ? "Min Value (Buy $)" : "TY (Buy $) Min value"
      },
      {
        headerName: editOffers ? "Max Value (Get $)" : "TY (Get $) Max value",
        field: "ty_get_max_value",
        filter: "agTextColumnFilter",
        headerClass: "blue-header-bg",
        cellRenderer: "pipelineInputRenderer",
        width: editOffers ? 200 : 250,
        suppressSizeToFit: true,
        unSortIcon: true,
        headerTooltip : editOffers ? "Max Value (Get $)" : "TY (Get $) Max value",
        tooltipField : editOffers ? "Max Value (Get $)" : "TY (Get $) Max value"
      },
      {
        headerName: editOffers ? "Reg Low" : "Reg. low amt.",
        field: "regLow",
        headerClass: "blue-header-bg",
        filter: "agTextColumnFilter",
        unSortIcon: true,
        width : editOffers ? 150 : 180,
        headerTooltip : editOffers ? "Reg Low" : "Reg. low amt.",
        tooltipField : editOffers ? "Reg Low" : "Reg. low amt."
      },
      {
        headerName: editOffers ? "Reg High" : "Reg. high amt",
        field: "regHigh",
        headerClass: "blue-header-bg",
        filter: "agTextColumnFilter",
        unSortIcon: true,
        width : editOffers ? 150 : 180,
        headerTooltip : editOffers ? "Reg High" : "Reg. high amt",
        tooltipField : editOffers ? "Reg High" : "Reg. high amt"
      },
      {
        headerName: editOffers ? "Sale Low" : "Sale low amt.",
        field: "saleLow",
        headerClass: "blue-header-bg",
        filter: "agTextColumnFilter",
        unSortIcon: true,
        width : editOffers ? 150 : 180,
        headerTooltip : editOffers ? "Sale Low" : "Sale low amt.",
        tooltipField : editOffers ? "Sale Low" : "Sale low amt."
      },
      {
        headerName: editOffers ? "Sale High" : "Sale high amt.",
        field: "saleHigh",
        headerClass: "blue-header-bg",
        filter: "agTextColumnFilter",
        unSortIcon: true,
        width : editOffers ? 150 :180,
        headerTooltip : editOffers ? "Sale High" : "Sale high amt.",
        tooltipField : editOffers ? "Sale High" : "Sale high amt."
      },
      {
        headerName: "LY Event Type",
        hide: editOffers,
        field: "ly_event_type",
        filter: "agTextColumnFilter",
        headerClass: "gray-header-bg",
        suppressSizeToFit: true,
        valueGetter: promoTypeFormatter,
        unSortIcon: true,
        width : 200,
        headerTooltip : "LY Event Type",
        tooltipField : "LY Event Type"
      },
      {
        headerName: "LY Start Date",
        hide: editOffers,
        field: "ly_start_date",
        filter: "agTextColumnFilter",
        headerClass: "gray-header-bg",
        suppressSizeToFit: true,
        unSortIcon: true,
        valueFormatter: function (params) {
          if (params.value) {
            let valueVar = moment(params.data.ly_start_date).format("MM/DD/YYYY");
            return valueVar;
          }
          return "-";
        },
        width : 180,
        headerTooltip : "LY Start Date",
        tooltipField : "LY Start Date"
      },
      {
        headerName: "LY End Date",
        hide: editOffers,
        field: "ly_end_date",
        filter: "agTextColumnFilter",
        headerClass: "gray-header-bg",
        suppressSizeToFit: true,
        unSortIcon: true,
        valueFormatter: function (params) {
          if (params.value) {
            let valueVar = moment(params.data.ly_end_date).format("MM/DD/YYYY");
            return valueVar;
          }
          return "-";
        },
        width : 180,
        headerTooltip : "LY End Date",
        tooltipField : "LY End Date"
      },
      {
        headerName: "LY offer",
        hide: editOffers,
        field: "ly_offer",
        filter: "agTextColumnFilter",
        headerClass: "gray-header-bg",
        suppressSizeToFit: true,
        unSortIcon: true,
        width : 160,
        headerTooltip : "LY offer",
        tooltipField : "LY offer"
      },
      {
        headerName: "LY offer type",
        hide: editOffers,
        field: "ly_offer_type",
        filter: "agTextColumnFilter",
        headerClass: "gray-header-bg",
        suppressSizeToFit: true,
        unSortIcon: true,
        width : 200,
        headerTooltip : "LY offer type",
        tooltipField : "LY offer type"
      },
      {
        headerName: "LY SKU Count",
        field: "ly_sku_count",
        filter: "agTextColumnFilter",
        headerClass: "gray-header-bg",
        suppressSizeToFit: true,
        unSortIcon: true,
        hide: true,
        width : 200,
        headerTooltip : "LY SKU Count",
        tooltipField : "LY SKU Count"
      },
      {
        headerName: "LY Actual Units",
        hide: editOffers,
        field: "ly_actual_units",
        filter: "agTextColumnFilter",
        headerClass: "gray-header-bg",
        suppressSizeToFit: true,
        valueFormatter: emptyValueFormatterNew, //emptyValueFormatter,
        unSortIcon: true,
        width : 200,
        headerTooltip : "LY Actual Units",
        tooltipField : "LY Actual Units"
      },
      {
        headerName: "LY Actual Sales ($)",
        hide: editOffers,
        field: "ly_actual_sales",
        filter: "agTextColumnFilter",
        headerClass: "gray-header-bg",
        valueFormatter: emptyValueFormatterNew, //emptyValueFormatterWithDollar,
        suppressSizeToFit: true,
        unSortIcon: true,
        width : 200,
        headerTooltip : "LY Actual Sales ($)",
        tooltipField : "LY Actual Sales ($)"
      },
      {
        headerName: "LY Actual Margin ($)",
        hide: editOffers,
        field: "ly_actual_margin",
        filter: "agTextColumnFilter",
        valueFormatter: emptyValueFormatterNew, //emptyValueFormatterWithDollar,
        headerClass: "gray-header-bg",
        suppressSizeToFit: true,
        unSortIcon: true,
        width : 230,
        headerTooltip : "LY Actual Margin ($)",
        tooltipField : "LY Actual Margin ($)"
      },
      {
        headerName: "LY GM (%)",
        hide: editOffers,
        field: "ly_gm",
        filter: "agTextColumnFilter",
        headerClass: "gray-header-bg",
        suppressSizeToFit: true,
        unSortIcon: true,
        valueFormatter: function (params) {
          if (params.value) {
            let valueVar = Math.round(params.data.ly_gm * 100) / 100 + " %";
            return valueVar;
          }
          return "-";
        },
        width : 150,
        headerTooltip : "LY GM (%)",
        tooltipField : "LY GM (%)"
      },
      {
        headerName: "Action",
        field: "action",
        pinned: "right",
        cellRenderer: "promoActionRenderer",
        width: 90,
        filter: "agTextColumnFilter",
      }
    ]
  },
  addOfferFromLYPromoApprovalErrorsData : [
    {
      headerName: "Adgroup", 
      field: "adgroup_name",
      headerClass: "blue-header-bg",
      filter: "agTextColumnFilter", 
      checkboxSelection: false,
      headerCheckboxSelection: !isFirstColumn,
    },
    { 
      headerName: "Event", 
      field: "event_detail",
      headerClass: "blue-header-bg",
      filter: "agTextColumnFilter",
      checkboxSelection: false,
      headerCheckboxSelection: !isFirstColumn,
    },
    { 
      headerName: "Offer Type", 
      field: "offer_type",
      headerClass: "blue-header-bg",
      filter: "agTextColumnFilter",
      checkboxSelection: false,
      headerCheckboxSelection: !isFirstColumn,
    },
    { 
      headerName: "Offer Value", 
      field: "offer_value",
      headerClass: "blue-header-bg",
      filter: "agTextColumnFilter",
      checkboxSelection: false,
      headerCheckboxSelection: !isFirstColumn,
    },
    {
      headerName: "Status",
      field: 'status',
      cellRenderer: "approvalStatusRenderer",
      tooltipField: 'validationErrors',
      pinned: 'right',
      headerClass: "blue-header-bg",
      width: 100,
      filter: "agTextColumnFilter"
    }
  ],
  addOfferFromLYErrorsData : [
    {
      headerName: "Adgroup", 
      field: "adgroup_name",
      headerClass: "blue-header-bg",
      filter: "agTextColumnFilter", 
      checkboxSelection: false,
      headerCheckboxSelection: !isFirstColumn,
    },
    { 
      headerName: "Event", 
      field: "event_detail",
      headerClass: "blue-header-bg",
      filter: "agTextColumnFilter",
      checkboxSelection: false,
      headerCheckboxSelection: !isFirstColumn,
    }
  ]
};
function isFirstColumn(params) {
  var displayedColumns = params.columnApi.getAllDisplayedColumns();
  var thisIsFirstColumn = displayedColumns[0] === params.column;
  return thisIsFirstColumn;
}

// function emptyValueFormatter(value) {
//   if (value.value) {
//     var toSting = Math.round(value.value);
//     if (value.colDef.headerName.indexOf("$") > -1) {
//       return "$ " + toSting.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
//     } else {
//       return toSting.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
//     }
//   } else {
//     return "-";
//   }
// }

function promoTypeFormatter(params) {
  let promoTypeSet = JSON.parse(localStorage.getItem("promo_type"));
  if (params.data.ly_event_type) {
    return promoTypeSet
      ? promoTypeSet[params.data.ly_event_type]
      : params.data.ly_event_type;
  } else if (params.data.ty_event_type) {
    return promoTypeSet
      ? promoTypeSet[params.data.ty_event_type]
      : params.data.ty_event_type;
  } else if (params.data.promo_type) {
    return promoTypeSet
      ? promoTypeSet[params.data.promo_type]
      : params.data.promo_type;
  }
  return "-";
}

function eventIdGetter(params) {
  return params.data.g_eid ? params.data.g_eid : '-'
}
