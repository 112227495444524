import React, { Component } from "react";
import Select from "react-select";
import Multiselect from "../../../../utils/Multiselect";
import _ from "lodash";
import AddEventRow from "./CreateUserEventRow";
import { getCumulativeFilters } from "../../../../redux/actions/summaryAction";
import { editUser, merchIdList } from "../../../../redux/actions/userAction";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import AlertDanger from "../../../../utils/AlertMessage/alertDanger";
import AlertSuccess from "../../../../utils/AlertMessage/alertSuccess";
import { setTimeout } from "timers";
import PageLoader from "../../../../utils/Loader";
import { alertTimer } from "../../../../utils/alertTimer";

var hierarchyOptionArray = [
  {
    value: "Customer Group",
    label: "Customer Group",
    key: 0,
  },
  {
    value: "Business Desc",
    label: "Business Desc",
    key: 1,
  },
  {
    value: "Shop",
    label: "Shop",
    key: 2,
  },
];
var merchIdAll = false;
export class CreateUser extends Component {
  hierarchyAccess = React.createRef();
  state = {
    userNameVal: null,
    userEmailVal: null,
    rolesOptions: [
      { value: 1, label: "Super User" },
      { value: 2, label: "Merchant" },
      { value: 3, label: "Marketing" },
      { value: 4, label: "Admin" },
      { value: 5, label: "Executive" },
      { value: 6, label: "Data Integrity" },
      { value: 7, label: "IT"}
    ],
    selectedStatus: null,
    selectedRole: null,
    selectedMerchId: null,
    merchIdArray: [],
    password: null,
    passwordInt: null,
    showAlertDanger: false,
    hierarchyValueOptions: null,
    enterAllFieldsAlert: false,
    showPasswordDivs: false,
    passwordMismatchAlert: false,
    wrongEmailAlert: false,
    savedUserAlert: null,
    showLoader: true,
    showLoaderForUser: false,
    disableSave: false,
    selectedValues: [
      {
        key: 1,
        selectedHierarchy: [],
        selectedValue: [],
      },
    ],
  };
  async componentDidMount() {
    if (this.props.editUserData) {
      let selectedStatus = {
          value: this.props.editUserData.user_status,
          label: this.props.editUserData.user_status,
        },
        name = this.props.editUserData.role_name,
        selectedRole = {
          value:
            name === "Super User"
              ? 1
              : name === "Merchant"
              ? 2
              : name === "Marketing"
              ? 3
              : name === "Admin"
              ? 4
              : 5,
          label: name,
        },
        selectedMerchId = [];

      if (
        this.props.editUserData.merch_id &&
        this.props.editUserData.merch_id.length
      ) {
        this.props.editUserData.merch_id.map((merchId) => {
          selectedMerchId.push({
            value: merchId,
            label: merchId,
          });
        });
      }

      this.setState({
        userNameVal: this.props.editUserData.user_name,
        userEmailVal: this.props.editUserData.email,
        selectedStatus: selectedStatus,
        selectedRole: selectedRole,
        selectedMerchId: selectedMerchId,
      });

      // var getCollapseElement = this.hierarchyAccess.current
      //     .getAttribute("href")
      //     .replace("#", ""),
      //   item = document.getElementById(getCollapseElement);
      // this.hierarchyAccess.current.classList.remove("collapsed");
      // item.classList.remove("collapsed", "hide");
      // item.classList.add("show"); //open the accordian by default in case of Edit user
    } else {
      this.setState({
        showLoader: false,
      });
    }

    this.setState({
      showLoaderForUser: true,
    });

    let merchIdArrayResponse = await this.props.merchIdList();

    if (merchIdArrayResponse.status) {
      let merchIdArray = _.map(
        merchIdArrayResponse.data,
        (item) => {
          let labelVar = item.shops ? item.merch_id +" - "+ item.shops : item.merch_id;
          return {
            value: item.merch_id,
            label: labelVar,
          };
        }
      );
      let all = { value: "All", label: "All" };
      if (!_.isEmpty(merchIdArray)) {
        merchIdArray.unshift(all);
      }
      this.setState({ merchIdArray, showLoaderForUser: false });
      if (
        this.props.editUserData &&
        _.isEmpty(this.props.editUserData.merch_id)
      ) {
        this.merchIdSelected([all], merchIdArray)
      }
    }

    this.props.getCumulativeFilters({
      required_column: [
        {
          required_column: "h6",
        },
        {
          required_column: "h5",
        },
        {
          required_column: "h3",
        },
      ],
    });
  }
  componentDidUpdate() {
    if (
      this.props.cumulativeFilters &&
      Object.keys(this.props.cumulativeFilters).length > 0 &&
      this.props.cumulativeFilters[0][0] !== undefined &&
      this.state.hierarchyValueOptions === null
    ) {
      var all = { value: "All", label: "All" };
      let custGrpOptions = [all],
        businessOptions = [all],
        shopOptions = [all];

      if (this.props.cumulativeFilters.length) {
        this.props.cumulativeFilters.map((item) => {
          let itemArray = item[0].h6
              ? item[0].h6
              : item[0].h5
              ? item[0].h5
              : item[0].h3,
            arrayName = item[0].h6
              ? custGrpOptions
              : item[0].h5
              ? businessOptions
              : shopOptions;
          itemArray.map((item) => {
            arrayName.push({
              value: item,
              label: item,
            });
            return item;
          });
          return item;
        });
      }

      this.setState({
        hierarchyValueOptions: [custGrpOptions, businessOptions, shopOptions],
      });

      let tempRow = [];
      if (
        _.size(this.props.editUserData) &&
        this.props.editUserData.user_attributes[0].attr_type
      ) {
        this.props.editUserData.user_attributes.map(
          (selectedHierarchyValues, index) => {
            let selectedVal =
              selectedHierarchyValues.attr_values !== null &&
              !selectedHierarchyValues.attr_values.length
                ? true
                : false;
            tempRow.push({
              key: index,
              selectedHierarchy:
                selectedHierarchyValues.attr_type === "h3"
                  ? [{ value: "Shop", label: "Shop", key: 2 }]
                  : selectedHierarchyValues.attr_type === "h5"
                  ? [{ value: "Business Desc", label: "Business Desc", key: 1 }]
                  : selectedHierarchyValues.attr_type === "h6"
                  ? [
                      {
                        value: "Customer Group",
                        label: "Customer Group",
                        key: 0,
                      },
                    ]
                  : null,
              selectedValue:
                selectedVal && selectedHierarchyValues.attr_type === "h3"
                  ? shopOptions
                  : selectedVal && selectedHierarchyValues.attr_type === "h5"
                  ? businessOptions
                  : selectedVal && selectedHierarchyValues.attr_type === "h6"
                  ? custGrpOptions
                  : selectedHierarchyValues.attr_values.map((attr_value) => {
                      return { value: attr_value, label: attr_value };
                    }),
            }); //used to pre-fill selected hierarchy and its hierarchy value when user clicks on Edit
            return selectedHierarchyValues;
          }
        );
        this.setState({ selectedValues: tempRow, showLoader: false });
      } else {
        this.setState({ showLoader: false });
      }
    }
  }

  addRows = () => {
    let { selectedValues } = this.state;
    selectedValues.push({
      key: selectedValues[selectedValues.length - 1].key + 1,
      selectedHierarchy: [],
      selectedValue: [],
    });

    this.setState({ selectedValues });
  };

  deleteRows = (keyNum) => {
    let { selectedValues } = this.state,
      newSelectedValues = _.filter(selectedValues, (item) =>
        item.key !== keyNum ? item : null
      );

    this.setState({ selectedValues: newSelectedValues });
  };

  merchIdSelected = (value, option) => {
    let tempArr = [];
    _.map(value, (selectedMerchId) => {
      tempArr.push(selectedMerchId.value);
    });
    this.setState({
      selectedMerchId: tempArr.includes("All")
        ? option
        : !tempArr.includes("All") && merchIdAll
        ? []
        : value,
    });
    merchIdAll = tempArr.includes("All") ? true : false;
  };

  disableSaveHandler = () => {
    let { selectedValues } = this.state;
    let selectedHierarchyValueEmpty = _.find(selectedValues, (item) =>
      _.isEmpty(item.selectedValue)
    );

    //Save has to disabled if hierarchy value is not chosen when hierarchy value after selected
    if (
      selectedHierarchyValueEmpty &&
      !_.isEmpty(selectedHierarchyValueEmpty.selectedHierarchy)
    ) {
      this.setState({ disableSave: true });
    } else {
      this.setState({ disableSave: false });
    }
  };

  onHierarchySelect = (val, keyNum) => {
    let { selectedValues } = this.state,
      showAlertDanger = _.find(selectedValues, { selectedHierarchy: [val] }); //check if hierarchy is already selected
    if (showAlertDanger && showAlertDanger.key !== keyNum) {
      this.setState({ showAlertDanger: true });
      setTimeout(() => {
        this.setState({ showAlertDanger: false });
      }, alertTimer);
    } else {
      let found = _.find(selectedValues, { key: keyNum });

      found.selectedHierarchy = [val];
      found.selectedValue = [];
      this.disableSaveHandler();
      this.setState({ selectedValues });
    }
  };

  onHierarchyValueChanged = (val, keyNum, options) => {
    let tempArr = [];
    val.forEach((ele) => {
      tempArr.push(ele.value);
    });

    let { selectedValues } = this.state,
      found = _.find(selectedValues, { key: keyNum });

    if (tempArr.includes("All")) {
      found.selectedValue = options;
    } else {
      found.selectedValue = val;
    }

    this.disableSaveHandler();
    this.setState({ selectedValues });
  };

  changePasswordClick = () => {
    this.setState({
      showPasswordDivs: true,
    });
  };

  clearFilters = (keyNum) => {
    let { selectedValues } = this.state,
      found = _.find(selectedValues, { key: keyNum });

    found.selectedValue = [];

    this.disableSaveHandler();
    this.setState({ selectedValues });
  };

  clearHierarchyValues = (keyNum) => {
    let { selectedValues } = this.state,
      found = _.find(selectedValues, { key: keyNum });

    found.selectedHierarchy = [];
    found.selectedValue = [];

    this.disableSaveHandler();
    this.setState({ selectedValues });
  };

  enterAllFieldsAlert = () => {
    this.setState({ enterAllFieldsAlert: true });
    setTimeout(() => {
      this.setState({ enterAllFieldsAlert: false });
    }, alertTimer);
  };

  saveHandler = async () => {
    this.setState({
      showLoaderForUser: true,
    });
    let {
        userNameVal,
        userEmailVal,
        password,
        selectedRole,
        selectedMerchId,
        selectedValues,
      } = this.state,
      checkPassword =
        (this.props.editUserData && this.state.showPasswordDivs) ||
        !this.props.editUserData
          ? !_.size(password)
          : false,
      checkSelectedValues = _.map(selectedValues, (item) =>
        item.selectedValue.length ? true : false
      ),
      reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/,
      merchIdArray = [];
    _.map(selectedMerchId, (item) => {
      merchIdArray.push(item.value);
    });

    if (
      !_.size(userNameVal) ||
      !_.size(userEmailVal) ||
      !_.size(selectedRole) ||
      !_.size(merchIdArray) ||
      checkPassword
    ) {
      this.setState({ showLoaderForUser: false });
      this.enterAllFieldsAlert();
    } else if (!reg.test(userEmailVal)) {
      this.setState({
        wrongEmailAlert: true,
        showLoaderForUser: false,
      });
      setTimeout(() => {
        this.setState({ wrongEmailAlert: false });
      }, alertTimer);
    } else if (this.state.password !== this.state.passwordInt) {
      this.setState({
        passwordMismatchAlert: true,
        showLoaderForUser: false,
      });
      setTimeout(() => {
        this.setState({ passwordMismatchAlert: false });
      }, alertTimer);
    } else {
      let params = null,
        userAtributes = [];

      if (
        selectedValues.length === 1 &&
        _.isEmpty(selectedValues[0].selectedValue)
      ) {
        userAtributes = [];
      } else if (
        selectedValues.length > 1 ||
        (selectedValues.length === 1 &&
          !_.isEmpty(selectedValues[0].selectedValue))
      ) {
        let selectedHierarchyValues = _.filter(
          selectedValues,
          (item) => !_.isEmpty(item.selectedValue)
        ); //Ignores rows wheere hierarchy value is not selected, returns the rest

        userAtributes = _.map(selectedHierarchyValues, (item) => {
          let splicedArray = [];
          item.selectedValue.filter((arr, index) => {
            if (index > 0) {
              return splicedArray.push(arr.value);
            }
          });
          let key = item.selectedHierarchy[0].key,
            attrType = key === 0 ? "h6" : key === 1 ? "h5" : "h3",
            attrVals =
              _.find(item.selectedValue, {
                value: "All",
                label: "All",
              }) && _.size(selectedHierarchyValues)
                ? splicedArray
                : item.selectedValue.map((item) => item.value);
          return {
            attr_type: attrType,
            attr_values: attrVals,
            status: 1,
          };
        });
      }
      if (this.props.editUserData) {
        params = {
          id: this.props.editUserData.id,
          name: this.state.userNameVal,
          new_password: this.state.password,
          email: this.state.userEmailVal,
          roles: [this.state.selectedRole.value],
          merch_id: merchIdArray.includes("All") ? null : merchIdArray,
          user_attributes: userAtributes,
        };
      } else {
        params = {
          name: this.state.userNameVal,
          email: this.state.userEmailVal,
          password: this.state.password,
          roles: [this.state.selectedRole.value],
          merch_id: merchIdArray.includes("All") ? null : merchIdArray,
          user_attributes: userAtributes,
        };
      }

      let response = await this.props.editUser(params);
      this.setState({ savedUserAlert: response, showLoaderForUser: false });
      setTimeout(() => {
        this.setState({ savedUserAlert: null });
      }, alertTimer);
    }
  };

  columnRendrer = (label, val, stateVal) => {
    return (
      <div className="col-md-3 ">
        <label className=" createUserLabelDiv">{label}</label>
        <input
          placeholder="Password"
          value={val}
          className="form-control card forecastedValuesInputStyling createUserInputDiv passwordInput"
          onChange={(e) => {
            stateVal === "password"
              ? this.setState({ password: e.target.value })
              : this.setState({ passwordInt: e.target.value });
          }}
        />
      </div>
    );
  };
  alertDangerRendrer = (msg, stateVal) => {
    return <AlertDanger message={msg} show={stateVal} />;
  };
  render() {
    return (
      <div>
        <div className="alertDivs">
          {this.state.showAlertDanger
            ? this.alertDangerRendrer(
                "Selection already made!",
                this.state.showAlertDanger
              )
            : null}
          {this.state.enterAllFieldsAlert
            ? this.alertDangerRendrer(
                "Please enter all the fields",
                this.state.enterAllFieldsAlert
              )
            : null}

          {this.state.passwordMismatchAlert
            ? this.alertDangerRendrer(
                "Passwords do not match!",
                this.state.passwordMismatchAlert
              )
            : null}

          {this.state.wrongEmailAlert
            ? this.alertDangerRendrer(
                "Invalid email entered!",
                this.state.wrongEmailAlert
              )
            : null}
          {_.size(this.state.savedUserAlert) &&
          this.state.savedUserAlert.status ? (
            <AlertSuccess
              message={this.state.savedUserAlert.msg}
              show={this.state.savedUserAlert.status}
            />
          ) : _.size(this.state.savedUserAlert) &&
            !this.state.savedUserAlert.status ? (
            this.alertDangerRendrer(
              this.state.savedUserAlert.msg,
              this.state.savedUserAlert.status
            )
          ) : null}
        </div>
        <PageLoader loader={this.state.showLoaderForUser}>
          <div className="card cardStyles createUserCardStyling">
            <div className="row createUserInputRow mt-3">
              <div className="col-md-3 ">
                <label className=" createUserLabelDiv">Name</label>
                <input
                  type="text"
                  placeholder="Name"
                  className="form-control card forecastedValuesInputStyling createUserInputDiv"
                  value={this.state.userNameVal}
                  onChange={(e) =>
                    this.setState({ userNameVal: e.target.value })
                  }
                />
              </div>

              <div className="col-md-3 ">
                <label className=" createUserLabelDiv">Email</label>
                <input
                  type="email"
                  placeholder="Email"
                  className="form-control card forecastedValuesInputStyling createUserInputDiv"
                  value={this.state.userEmailVal}
                  onChange={(e) =>
                    this.setState({ userEmailVal: e.target.value })
                  }
                />
              </div>

              <div className="col-md-3 ">
                <label className=" createUserLabelDiv">Role</label>
                <Select
                  placeholder="Select role"
                  className="createUserInputDiv"
                  options={this.state.rolesOptions}
                  value={this.state.selectedRole}
                  onChange={(val) => {
                    this.setState({ selectedRole: val });
                  }}
                />
              </div>

              <div className="col-md-3 merch-id-col">
                <label className=" createUserLabelDiv">Merch ID</label>
                <Multiselect
                  placeHolder="Select Merch ID"
                  optionsArray={this.state.merchIdArray}
                  onDropDownChange={(value) =>
                    this.merchIdSelected(value, this.state.merchIdArray)
                  }
                  dropdownValue={this.state.selectedMerchId}
                />
                {!_.isEmpty(this.state.selectedMerchId) ? (
                  <i
                    className="fa fa-times close1"
                    aria-hidden="true"
                    onClick={() => {
                      this.setState({
                        selectedMerchId: [],
                      });
                    }}
                  />
                ) : null}
              </div>
            </div>

            <div className="row createUserInputRow mt-3 h-100 align-items-center">
              {this.props.editUserData ? (
                <div className="col-md-3 ">
                  <p
                    className="changePasswordLink"
                    onClick={() => this.changePasswordClick()}
                  >
                    Change password
                  </p>
                </div>
              ) : null}
              {(this.props.editUserData && this.state.showPasswordDivs) ||
              !this.props.editUserData ? (
                <>
                  {this.columnRendrer(
                    "Enter password",
                    this.state.passwordInt,
                    "passwordInt"
                  )}
                  {this.columnRendrer(
                    "Confirm password",
                    this.state.password,
                    "password"
                  )}
                </>
              ) : null}
            </div>
            {/* 
            <div className="collapseDivInner mt-8 mb-8 createUserAccordionStyling">
              <div id="accordion" className="accordion">
                <div
                  className={
                    this.state.userNameVal !== null &&
                    this.state.userEmailVal !== null
                      ? "card-header collapsed"
                      : "enableaccordian card-header collapsed"
                  }
                  data-toggle="collapse"
                  href="#collapseOne"
                  ref={this.hierarchyAccess}
                >
                  <a className="card-title">Hierarchy Access</a>
                </div>
                <div
                  id="collapseOne"
                  className="collapse"
                  data-parent="#accordion"
                >
                  <PageLoader
                    loader={this.state.showLoader}
                    loaderHeight="300%"
                  >
                    <div>
                      {this.state.selectedValues.map((rowItem, index) => (
                        <AddEventRow
                          row={rowItem}
                          valueOptions={this.state.hierarchyValueOptions}
                          hierarchyOptionArray={hierarchyOptionArray}
                          rowLength={this.state.selectedValues.length}
                          index={index}
                          addRows={this.addRows}
                          deleteRows={this.deleteRows}
                          onHierarchySelect={this.onHierarchySelect}
                          onHierarchyValueChanged={this.onHierarchyValueChanged}
                          clearFilters={this.clearFilters}
                          clearHierarchyValues={this.clearHierarchyValues}
                        />
                      ))}
                    </div>
                  </PageLoader>
                </div>
              </div>
            </div> */}

            <div className="row justify-content-center mt-4">
              <div className="col-6 saveUserBtnStyling">
                <button
                  className="btn btn-outline-primary promo3Buttons"
                  disabled={this.state.disableSave}
                  onClick={(e) => {
                    e.preventDefault();
                    this.saveHandler();
                  }}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </PageLoader>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    cumulativeFilters: state.summaryData.summaryCumulativeFilters,
    createUserData: state.userDataReducer.createUserData,
    statusUpdateData: state.userDataReducer.statusUpdateData,
    loader: state.userDataReducer.loader,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCumulativeFilters: (val) => dispatch(getCumulativeFilters(val)),
    editUser: (val) => dispatch(editUser(val)),
    merchIdList: (val) => dispatch(merchIdList(val)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CreateUser));
