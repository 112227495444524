import React, { Component } from "react";
import Select from "react-select";

export class RefSkuChildMessageRendrer extends Component {
  state = {
    value: this.props.data.description,
  };
  // used for ag-grid refresh cells purpose
  componentDidMount() {
    this.props.data.sku_saved = true;
    this.props.data.selected_sku = this.props.data.reference_sku;
  }
  refresh(params) {
    this.setState({
      value: params.data.description,
    });
    return true;
  }
  onChangeHandler = (val) => {
    this.props.data.sku_saved = false;
    this.props.data.selected_sku = val.value;
    this.props.data.reference_sku = val.value;
  };
  onKeyChangeHandler = (key) => {
    if (key) {
      this.props.data.refSkuSearchHandler(key, this.props.data.unique_id1);
    }
  };
  render() {
    return (
      <>
        {this.props.data.sku_tag !== "prod_sim" || 
        this.props.data.reference_sku === "00000000" ? (
          "-"
        ) : (
          <Select
            className="reference-sku-select"
            placeholder={
              this.props.data.reference_sku === "00000000"
                ? "-"
                : this.props.data.reference_sku === "null"
                ? "None"
                : this.props.data.reference_sku
                ? `${this.props.data.reference_sku}`
                : "Select Reference SKU"
            }
            options={this.props.data.refSkuOptions}
            onChange={(val) => this.onChangeHandler(val)}
            onInputChange={(e) => this.onKeyChangeHandler(e)}
          />
        )}
      </>
    );
  }
}

export default RefSkuChildMessageRendrer;
