import React, { Component } from "react";
import _ from "lodash";

export class ChildMessageRenderer extends Component {
  invokeParentMethod = () => {};

  showRefSkuDetail = () => {
    if (!_.isEmpty(this.props.data.selected_sku)) {
      this.props.data.showRefSkuDetail(this.props.data);
    } else {
      this.props.data.showRefSkuDetail("");
    }
  };
  saveReferenceSku = () => {
    if (!_.isEmpty(this.props.data.selected_sku)) {
      this.props.data.sku_saved = true;
      this.props.data.saveRefSkuDetail(this.props.data);
    } else {
      this.props.data.saveRefSkuDetail("");
    }
  };

  render() {
    return (
      <div>
        {this.props.data.sku_tag !== "prod_sim" ? null : (
          <span>
            <i
              className="fa fa-save actionIcons"
              style={{
                color: "#357ef2",
                cursor: "pointer",
                marginLeft: "15px",
                fontSize: "15px",
              }}
              title="Save"
              onClick={this.saveReferenceSku}
            />
          </span>
        )}
      </div>
    );
  }
}

export default ChildMessageRenderer;
