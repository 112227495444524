import React, { Component } from "react";
import DataTable from "../../../tables";
import ColumnDef from "../../../tables/colDefs/AddOffersFromLYColDef";
import PageLoader from "../../../../utils/Loader";
import { pipelineIntermediateApi } from "../../../../redux/actions/calendarAction";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

export class LYOfferTable extends Component {
  state = {
    tableData: [],
    showLoader: false
  };

  async componentDidMount() {
    let campaignId = localStorage.getItem("campaignIdsForUrl");

    let pipelineRes = await this.props.pipelineIntermediateApi({
      campaign_id: campaignId,
      adgroup_id: [
        this.props.selectedAdgroup ? this.props.selectedAdgroup.adgroup_id : ""
      ]
    });

    this.setState({
      showLoader: true
    });

    let headerDefination = ColumnDef.header() || []

    let lyOfferYTable = headerDefination.map(item => {
      if (
        item.field === "ty_event_type" ||
        item.field === "ty_offer_type" ||
        item.field === "ty_planned_amount" ||
        item.field === "fixed_price_quantity" ||
        item.field === "ty_min_qty" ||
        item.field === "ty_bonus_pay" ||
        item.field === "ty_buy_min_value" ||
        item.field === "ty_get_max_value"
      ) {
        return {
          ...item,
          hide: true
        };
      }
      return item;
    });

    if (pipelineRes.data.status) {
      let tableData = {
        header: lyOfferYTable,
        defaultColDef: ColumnDef.deleteDefaultColDef,
        data: pipelineRes.data.data
      };

      this.setState({
        showLoader: false,
        tableData
      });
    } else {
      this.setState({
        showLoader: false
      });
    }
  }
  render() {
    return (
      <div style={{ height: "55vh" }}>
        <PageLoader loader={this.state.showLoader}>
          <DataTable
            tableData={this.state.tableData}
            parentId="delete-ag-grid"
            overlayNoRowsTemplate='<span class="pipeline-span">No Data to show</span>'
            animateRows={true}
            autoSizeCol={true}
          />
        </PageLoader>
      </div>
    );
  }
}

export default connect(null, {
  pipelineIntermediateApi
})(withRouter(LYOfferTable));
