import React, { useState,useEffect } from "react";
import moment from "moment";
import { alertTimer } from "../../../../utils/alertTimer";
import AlertDanger from "../../../../utils/AlertMessage/alertDanger";
import Modal from "react-awesome-modal";
import { Button, Input } from 'antd';
const { TextArea } = Input;

const PromoApprovalModal = props => {
  const [isFailure, setIsFailure] = useState(false);
  const [failureMessage, setFailureMessage] = useState("");
  const [expiredOrInactiveEvent, setExpiredOrInactiveEvent] = useState(0);
  const [comment, setComment] = useState('');


  useEffect(() => {
    if (props.propsData) {
       let event_end_date_time,event_status;
       if(props && props.propsData && props.propsData.event_end_date_time){
        event_end_date_time = props.propsData.event_end_date_time;
       }
       if(props && props.propsData && props.propsData.event_status){
        event_status = props.propsData.event_status;
       }
      let parsedDate = moment(event_end_date_time, 'YYYY-MM-DD HH:mm:ss')
      let currentDate = moment();
      let expiredEvent = parsedDate.isBefore(currentDate);
      const isExpiredOrInactive = expiredEvent || !event_status;
      setExpiredOrInactiveEvent(isExpiredOrInactive);

      if (isExpiredOrInactive) {
        setIsFailure(true);
        setFailureMessage("Event is Expired/Inactive");
        setTimeout(() => {
          setIsFailure(false);
          setFailureMessage("");
        }, alertTimer);
      }
    }
  }, [props.propsData]);



  const handleInputChaneg  = (e) => {
  //  console.log(e);
   setComment(e.target.value);
  }

  const handleModalConfirm = () => {
    if(props.showTextArea) {
      props.handleConfirm(comment)
    } else {
      props.handleConfirm()
    }
    setComment('');
  }
  return(
  <>
    <div className="alertDivs">
          {isFailure &&
            <AlertDanger
              message={failureMessage}
              show={isFailure}
            />
          }
        </div>
    <Modal visible={props.showModal} width="600px" height={props.showMsg == 'Reason for rejection'? "200px" : "150px"} effect="fadeInUp" onClickAway={() => props.closeModal()}>
    <div className="modalContent">
      <h5>{props.showMsg}</h5>
      {props.showTextArea && <div>
        <TextArea rows={3} placeholder="" maxLength={200} value={comment} onChange={handleInputChaneg}/>
      </div>}
      <div className="modalButtoncontainer">
        <Button
          // type="link"
          onClick={() => {setComment(''); props.closeModal()}}
          style={{
            marginRight: "15px"
          }}
        >
          No
        </Button>
        <Button
          type="primary"
          onClick={handleModalConfirm}
          disabled={props && props.propsData && expiredOrInactiveEvent}
        >
          Yes
        </Button>
      </div>
    </div>

  </Modal>
  </>
  )

}

export default PromoApprovalModal;