import React from "react";
import _ from "lodash";
import DataTable from "../../../tables";
import ColumnDef from "../../../tables/colDefs/SimulationColDefs";
import "./simulationStyle.css";

export class SimulateTable extends React.Component {
  render() {
    let tableData = [],
      tableHeader = _.cloneDeep(ColumnDef.header);
    let { simulateTableData } = this.props;

    if (this.props.topTable) {
      let firstThree = _.slice(simulateTableData, [0], [3]);

      tableHeader = tableHeader.map((item) => {
        if (item.children) {
          _.map(item.children, (item) => {
            if (
              item.field === "override_units" ||
              item.field === "override_sales" ||
              item.field === "override_margin"
            ) {
              item.hide = true;
              return item;
            }
          });
        }
        return item;
      });

      tableData = {
        header: tableHeader,
        defaultColDef: ColumnDef.deleteDefaultColDef,
        data: firstThree,
      };
    } else {
      let remaining = _.slice(
        simulateTableData,
        [3],
        [simulateTableData.length]
      );
      tableData = {
        header: ColumnDef.header,
        defaultColDef: ColumnDef.deleteDefaultColDef,
        data: remaining,
      };
    }
    return (
      <div
        className="simulation-table"
        style={{ height: this.props.topTable ? "226px" : "270px" }}
      >
        <DataTable
          tableData={tableData}
          parentId="delete-ag-grid"
          overlayNoRowsTemplate='<span class="pipeline-span">No Data to show</span>'
          animateRows={true}
          autoSizeCol={true}
          selectOfferRow={this.props.onSelectionChanged}
          refreshTable={this.props.topTable ? false : this.props.refreshTable}
          refreshTableHandler={this.props.refreshTableHandler}
          summaryTableExport={this.props.summaryTableExport} 
          summaryTableExportHandler={this.props.summaryTableExportHandler}
          isRowSelectable={function (rowNode) {
            if (
              localStorage.getItem("role") === "3" ||
              localStorage.getItem("role") === "5"
            ) {
              return false;
            } else {
              if (
                rowNode.data.metric === "Recommended By Margin" ||
                rowNode.data.metric === "Recommended By Sales" ||
                rowNode.data.metric === "Recommended By GM%"
              ) {
                return false;
              }

              return true;
            }
          }}
        />
      </div>
    );
  }
}

export default SimulateTable;
