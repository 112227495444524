import PageLoader from "../../../../utils/Loader";
import DataTable from "../../../tables";
import ColumnDef from "../../../tables/colDefs/calendarColDefs";
import React, { Component } from "react";
import { getTYPipelineData } from "../../../../redux/actions/calendarAction";
import Modal from "react-awesome-modal";
import moment from "moment";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import AlertDanger from "../../../../utils/AlertMessage/alertDanger";
import AlertSuccess from "../../../../utils/AlertMessage/alertSuccess";
import Toggle from "react-toggle";
import { PromotionCalendarGA } from '../../../../utils/GA/GAUtils';
import { alertTimer } from "../../../../utils/alertTimer";

class DownloadCampaignDataModal extends Component {
  constructor() {
    super();
    this.state = {
      campaignIdList: [],
      showDownloadSuccessfull: false,
      showMaximumSelectionWarning: false,
      showLoader: false
    };
  }
  selectOfferRow = val => {
    this.setState({ campaignIdList: val.map(item => item.id) });
    if (val.length > 3) {
      this.setState({ showMaximumSelectionWarning: true });
      setTimeout(() => {
        this.setState({ showMaximumSelectionWarning: false });
      }, alertTimer);
    }
  };
  downloadCalData = async () => {
    this.submitGA("Click", "Clicked_DownloadCampaignData_DownloadButton");
    this.props.downloadActiveMethod(true);
    this.setState({
      showLoader: true
    });
    let res = await this.props.getTYPipelineData({
      campaign_id: this.state.campaignIdList,
      download: "TY",
      adgroups: [],
      status: [],
      h3: null,
      h5: null,
      h6: null
    });

    this.setState({
      showDownloadSuccessfull: true,
      showLoader: false
    });

    setTimeout(() => {
      this.setState({
        showDownloadSuccessfull: false
      });
      this.props.closeModal();
    }, alertTimer);
  };
  submitGA(action,label){
    PromotionCalendarGA({ action, label});
  }

  render() {
    let dmOptions = [];
    var dmOptionsData = this.props.getCampaignDmList;

    if (dmOptionsData !== undefined && Object.keys(dmOptionsData).length > 0) {
      dmOptionsData.map(dmList => {
        dmOptions.push({
          value: dmList.campaign_name + "-" + dmList.promo_type,
          label:
            moment(dmList.start_date).format("L") +
            "-" +
            moment(dmList.end_date).format("L"),
          startDate: dmList.start_date,
          endDate: dmList.end_date,
          promoType: dmList.promo_type,
          id: dmList.id
        });
      });
    }

    let tableData = {
      header: ColumnDef.header,
      defaultColDef: ColumnDef.defaultColDef,
      data: dmOptions
    };

    return (
      <div>
        <div className="alertDivs">
          {this.state.showMaximumSelectionWarning ? (
            <AlertDanger
              message="Maximum of 3 selections allowed!"
              show={this.state.showMaximumSelectionWarning}
            />
          ) : null}

          {this.state.showDownloadSuccessfull ? (
            <AlertSuccess
              message="Downloaded successfully!"
              show={this.state.showDownloadSuccessfull}
            />
          ) : null}
        </div>
        <Modal
          visible={this.props.showCampaignList}
          width="486"
          height="440"
          effect="fadeInDown"
          onClickAway={() => this.props.closeModal()}
        >
          <PageLoader loader={this.props.loaderAg || this.state.showLoader}>
            <div className="downloadModal">
              <h5 className="text-left">Download Campaign Data</h5>
              <div className=""></div>
              <DataTable
                tableData={tableData}
                parentStyle={{
                  height: "80%",
                  margin: "15px 0"
                }}
                rowSelection="multiple"
                selectOfferRow={this.selectOfferRow}
                parentId="myGridForcomments"
                overlayNoRowsTemplate='<span className="gridMessage">No Data to show</span>'
                animateRows={true}
                autoSizeCol={true}
              />
              <button
                className="btn btn-primary"
                style={{ fontSize: "0.813rem" }}
                onClick={() => this.downloadCalData()}
                disabled={
                  this.state.campaignIdList.length &&
                  this.state.campaignIdList.length <= 3
                    ? false
                    : true
                }
              >
                Download <i className="fa fa-download" aria-hidden="true" />
              </button>
            </div>
          </PageLoader>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    pipelineData: state.calendar.pipelineData
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getTYPipelineData: val => dispatch(getTYPipelineData(val))
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(DownloadCampaignDataModal));
