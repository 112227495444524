export default {
  couponOfferTypes: [
    { value: "Digital", label: "Digital" },
    { value: "Category", label: "Category" }
  ],
  couponType: [
    { value: "tp", label: "TP" },
    { value: "rpi", label: "RPI" },
    { value: "tpr", label: "TPR" },
    { value: "threshold", label: "Threshold" }
  ],
  adgroupOfferTypes: [
    { value: "P", label: "Percentage" },
    { value: "F", label: "Fixed" },
    { value: "A", label: "Dollar Off" }
  ],
  newAdgroupOfferTypes: [
    { value: "P", label: "P- % price change", offerTypeOptions: "1" },
    { value: "F", label: "F- Fixed price", offerTypeOptions: "2" },
    { value: "A", label: "A- Relative price change", offerTypeOptions: "3" },
    { value: "G", label: "G- BB free goods", offerTypeOptions: "4" },
    { value: "B", label: "B- BB% discount with get", offerTypeOptions: "5" },
    { value: "C", label: "C- BB fixed disc with get", offerTypeOptions: "6" },
    { value: "D", label: "D- BB% discount", offerTypeOptions: "7" }
  ],
  nameWithFields: {
    P: ["ty_planned_amount"],
    F: ["ty_planned_amount", "fixed_price_quantity"],
    A: ["ty_planned_amount"],
    G: ["ty_min_qty", "ty_bonus_pay", "ty_buy_min_value", "ty_get_max_value"], //  removed  "ty_buy_min_value", "ty_get_max_value" as per offer entry brd
    B: ["ty_planned_amount", "ty_min_qty", "ty_bonus_pay"],
    C: [
      "ty_planned_amount",
      "ty_min_qty",
      "ty_bonus_pay",
      "ty_buy_min_value",
      "ty_get_max_value"
    ],
    D: ["ty_planned_amount", "ty_min_qty"]//, "ty_bonus_pay"
  },
  nameWithFieldsEditOffer: {
    P: ["offer_value"],
    F: ["offer_value", "fixed_price_quantity"],
    A: ["offer_value"],
    G: ["min_quantity", "bonus_by_quantity", "ty_buy_min_value", "ty_get_max_value"],  //removed "ty_buy_min_value", "ty_get_max_value" as per offer entry brd
    B: ["offer_value", "min_quantity", "bonus_by_quantity"],
    C: [
      "offer_value",
      "min_quantity",
      "bonus_by_quantity",
      "ty_buy_min_value",
      "ty_get_max_value"
    ],
    D: ["offer_value", "min_quantity"]//, "ty_bonus_pay"
  },
  fieldValidation: {
    P: ["ty_planned_amount"],
    F: ["ty_planned_amount"], //fixed_price_quantity
    A: ["ty_planned_amount"],
    G: ["ty_min_qty", "ty_bonus_pay"],
    B: ["ty_planned_amount", "ty_min_qty", "ty_bonus_pay"],
    C: ["ty_planned_amount", "ty_min_qty", "ty_bonus_pay"],
    D: ["ty_planned_amount", "ty_min_qty"]//, "ty_bonus_pay"
  },
  fieldValidationEditOffer: {
    P: ["offer_value"],
    F: ["offer_value"], //fixed_price_quantity
    A: ["offer_value"],
    G: ["min_quantity", "bonus_by_quantity"],
    B: ["offer_value", "min_quantity", "bonus_by_quantity"],
    C: ["offer_value", "min_quantity", "bonus_by_quantity"],
    D: ["offer_value", "min_quantity"]//, "ty_bonus_pay"
  },
  fieldValidation1: {
    P: ["offerValue"],
    F: ["offerValue"], //fixed_price_quantity
    A: ["offerValue"],
    G: ["minQtyForOffer", "bonusBuy"],
    B: ["offerValue", "minQtyForOffer", "bonusBuy"],
    C: ["offerValue", "minQtyForOffer", "bonusBuy"],
    D: ["offerValue", "minQtyForOffer"]//, "bonusBuy"
  }
};
