import ReactGA from 'react-ga';
import environment from './environments/environment';

class GaUtils {
  constructor() {
    const userId = localStorage.getItem('userid') || '';
    const userEmail = localStorage.getItem('email') || '';
    const emailHash = localStorage.getItem("emailHash");
    // ReactGA.initialize({
    //   trackingId: environment.trackingID,
    // });
    ReactGA.initialize(environment.trackingID, {
        // debug: true,
        gaOptions: {
            userId: emailHash,
            // userEmailId: userEmail
        }
    })
    ReactGA.pageview(window.location.pathname + window.location.search);
  }
  setUserId = (userId) => {
    ReactGA.set({ userId })
  }
  setEmailId = (emailId) => {
    ReactGA.set({ emailId });
  }
  trackEvent = (category) => (args) => {
    // console.log('Google Analytics args:', args);

    let params = {
      category,
      action:args.action,
      label:args.label
    };
    if(args.value){
      params.value = args.value;
    }
    ReactGA.event(params);
  };
}

export default GaUtils;
