import React from "react";
import LoadingOverlay from "react-loading-overlay";
import PulseLoader from "react-spinners/PulseLoader";
const PageLoader = ({ loader, color, children, loaderHeight }) => {
  return (
    <LoadingOverlay
      active={loader}
      spinner={<PulseLoader size={10} color={color ? color : "#2d69eb"} />}
      text="Thank you for your patience"
      styles={{
        overlay: base => ({
          ...base,
          background: "rgba(255, 255, 255, 0.89)",
          borderRadius: "40px",
          height: loaderHeight ? loaderHeight : "98%"
        }),
        spinner: base => ({
          ...base,
          width: "50px",
          "& svg circle": {
            stroke: color ? color : "#194ec1"
          }
        }),
        content: base => ({
          ...base,
          color: color ? color : "#2d69eb"
        })
      }}
    >
      {children}
    </LoadingOverlay>
  );
};

export default PageLoader;
