import {
  ADGROUP_DATA,
  GET_ADGROUP_DATA,
  GET_SHOP_DATA,
  GET_MERCHCAT,
  GET_DISTINCT_ADGROUP,
  UPDATE_PRODUCTS_ADGROUP,
  PROMO_FILTERS_SHOP,
  PROMO_FILTERS_MERCH,
  PROMO_FILTERS_ADGROUP,
  PROMO_FILTERS_CUSTGROUP,
  PROMO_FILTERS_BUSINESS,
  PROMO_FILTERS_CATEGORY,
  PRODUCT_DETAILS_ID,
  GET_CAMPAIGN_DM,
  LOADER,
  SET_ALL_HIERARCHIES_DATA,
  SET_ALL_ATTRIBUTES_DATA
} from "../actions/types";
const initialState = {
  adGroupData: {},
  getDistinctAdGroup: [],
  getMerchCatData: [],
  getShopData: [],
  getFilterDataShop: [],
  getFilterDataMerch: [],
  getFilterDataAdgroup: [],
  getFilterDataCustomerGroup: [],
  getFilterDataBusiness: [],
  getFilterDataCategory: [],
  getProductDetailsPromoId: [],
  getCampaignDmList: [],
  loading: true,
  getAllHierarchies:{},
  getAllAttributesData:{}
};

export default function (state = initialState, action) {
  switch (action.type) {
    case ADGROUP_DATA:
      return {
        ...state,
        adGroupData: action.payload
      };

    case GET_ADGROUP_DATA:
      return {
        ...state,
        getAdGroupData: action.payload
      };
    case LOADER:
      return {
        ...state,
        loader: action.payload
      };
    case GET_SHOP_DATA:
      return {
        ...state,
        getShopData: action.payload
      };
    case GET_MERCHCAT:
      return {
        ...state,
        getMerchCatData: action.payload
      };

    case GET_DISTINCT_ADGROUP:
      return {
        ...state,
        getDistinctAdGroup: action.payload
      };

    case UPDATE_PRODUCTS_ADGROUP:
      return {
        ...state,
        getProductAdgroup: action.payload
      };

    case PROMO_FILTERS_SHOP:
      return {
        ...state,
        getFilterDataShop: action.payload,
        loading: false
      };

    case PROMO_FILTERS_MERCH:
      return {
        ...state,
        getFilterDataMerch: action.payload
      };

    case PROMO_FILTERS_ADGROUP:
      return {
        ...state,
        getFilterDataAdgroup: action.payload
      };

    case PROMO_FILTERS_CUSTGROUP:
      return {
        ...state,
        getFilterDataCustomerGroup: action.payload
      };

    case PROMO_FILTERS_BUSINESS:
      return {
        ...state,
        getFilterDataBusiness: action.payload
      };

    case PROMO_FILTERS_CATEGORY:
      return {
        ...state,
        getFilterDataCategory: action.payload
      };

    case PRODUCT_DETAILS_ID:
      return {
        ...state,
        getProductDetailsPromoId: action.payload
      };

    case GET_CAMPAIGN_DM:
      return {
        ...state,
        getCampaignDmList: action.payload
      };
    case SET_ALL_HIERARCHIES_DATA:
      return {
        ...state,
        getAllHierarchiesData: action.payload
      };

    case SET_ALL_ATTRIBUTES_DATA:
      return {
        ...state,
        getAllAttributesData: action.payload
      };

    default:
      return state;
  }
}
