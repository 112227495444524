// import axios from "axios";
import axios from "../../utils/axios/index";
import FileDownload from 'js-file-download'
import { config } from "../../utils/config";
import _ from "lodash";
import {
  PROMOLOADER,
  SAVEPIPELINECELLS,
  PIPELINETABLE_DATE,
  GET_ERRORS,
  USER_LOGIN,
  CALENDAR_DATA,
  CAMPAIGNS_DETAILS,
  CALENDAR_STRUCURE,
  LOADER,
  USER_LOGGED_OUT,
  CALENDAR_EMPTY_DATA,
  SHOW_VALIDATION,
  USERCOMMENTS,
  COPY_CAMPAIGN_DETAILS,
  UPLOAD_ERROR_MSG
} from "../actions/types";

export const savePipelineCellsMethod = reqObject => async dispatch => {
  dispatch({
    type: LOADER,
    payload: true
  });
  let response = await axios
    .post(`${config.baseUrl}/promo/saveOverrideValuesToPromosTable`, reqObject)
    .then(res => {
      if (
        localStorage.getItem("token") !== undefined ||
        localStorage.getItem("token") !== null
      ) {
        dispatch({
          type: USER_LOGIN,
          payload: res.data.data
        });
      } else {
        dispatch({
          type: USER_LOGGED_OUT,
          payload: res.data.data
        });
      }
      dispatch({
        type: SAVEPIPELINECELLS,
        payload: res.data
      });
      dispatch({
        type: LOADER,
        payload: false
      });
      return res.data;
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err
      });
      return err;
    });
  return response;
};

export const userComments = reqObject => dispatch => {
  dispatch({
    type: LOADER,
    payload: true
  });
  axios
    .post(`${config.baseUrl}/user/userComments`, reqObject)
    .then(res => {
      if (
        localStorage.getItem("token") !== undefined ||
        localStorage.getItem("token") !== null
      ) {
        dispatch({
          type: USER_LOGIN,
          payload: res.data.data
        });
      } else {
        dispatch({
          type: USER_LOGGED_OUT,
          payload: res.data.data
        });
      }
      dispatch({
        type: USERCOMMENTS,
        payload: res.data.data
      });
      dispatch({
        type: LOADER,
        payload: false
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err
      });
    });
};

export const saveUserComment = reqObject => async dispatch => {
  let response = await axios
    .post(`${config.baseUrl}/user/addComments`, reqObject)
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
  return response;
};
export const setPromoStatus = reqObject => dispatch => {
  dispatch({
    type: PROMOLOADER,
    payload: false
  });
};

export const getTYPipelineData = reqObject => async dispatch => {
  let response = await axios
    .post(`${config.baseUrl}/promo/promo_pipeline`, reqObject)
    .then(res => {
      dispatch({
        type: PIPELINETABLE_DATE,
        payload: res.data.data
      });
      return res.data;
    })
    .catch(err => {
      return err;
    });
  return response;
};

export const calendarDatePlot = reqObject => async dispatch => {
  let bbb = document.cookie;

  dispatch({
    type: LOADER,
    payload: true
  });
  axios
    .post(`${config.baseUrl}/calendar/calendarDatePlot`, reqObject)
    .then(res => {
      if (
        localStorage.getItem("token") !== undefined ||
        localStorage.getItem("token") !== null
      ) {
        dispatch({
          type: USER_LOGIN,
          payload: res.data.data
        });
      } else {
        dispatch({
          type: USER_LOGGED_OUT,
          payload: res.data.data
        });
      }

      dispatch({
        type: CALENDAR_STRUCURE,
        payload: res.data.data
      });
      dispatch({
        type: LOADER,
        payload: false
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err
      });
    });
};
export const calendarData = reqObject => dispatch => {
  let bbb = document.cookie;
  dispatch({
    type: SHOW_VALIDATION,
    payload: true
  });
  axios
    .post(`${config.baseUrl}/calendar/calendarData`, reqObject)
    .then(res => {
      if (
        localStorage.getItem("token") !== undefined ||
        localStorage.getItem("token") !== null
      ) {
        dispatch({
          type: USER_LOGIN,
          payload: res.data.data
        });
      } else {
        dispatch({
          type: USER_LOGGED_OUT,
          payload: res.data.data
        });
      }

      if (res.data.msg === "No data") {
        dispatch({
          type: CALENDAR_EMPTY_DATA,
          payload: res.data.data
        });
        dispatch({
          type: SHOW_VALIDATION,
          payload: false
        });
      } else {
        dispatch({
          type: CALENDAR_DATA,
          payload: res.data.data
        });
        dispatch({
          type: SHOW_VALIDATION,
          payload: false
        });
      }
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err
      });
      dispatch({
        type: SHOW_VALIDATION,
        payload: false
      });
    });
};

export const getCampaignsDetails = reqObject => async dispatch => {
  dispatch({
    type: LOADER,
    payload: true
  });
  let response = await axios
    .post(`${config.baseUrl}/calendar/copyCampaign`, reqObject)
    .then(res => {
      if (
        localStorage.getItem("token") !== undefined ||
        localStorage.getItem("token") !== null
      ) {
        dispatch({
          type: USER_LOGIN,
          payload: res.data.data
        });
      } else {
        dispatch({
          type: USER_LOGGED_OUT,
          payload: res.data.data
        });
      }

      dispatch({
        type: CAMPAIGNS_DETAILS,
        payload: res.data.data
      });
      dispatch({
        type: LOADER,
        payload: false
      });

      return res.data.data;
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err
      });
      return err;
    });

  return response;
};

export const getCopyCampaignDetails = reqObject => dispatch => {
  dispatch({
    type: COPY_CAMPAIGN_DETAILS,
    payload: reqObject
  });
};

export const getEditDMList = reqObject => async dispatch => {
  const response = await axios.post(`${config.baseUrl}/event/list`, reqObject);

  return response.data;
};

export const getEventList = reqObject => async dispatch => {
  dispatch({
    type: LOADER,
    payload: true
  });

  const response = await axios.post(`${config.baseUrl}/event/getEventsList`, reqObject);

  dispatch({
    type: LOADER,
    payload: false,
  });
  return response.data;
};

export const editDM = reqObject => async dispatch => {
  const response = await axios.post(`${config.baseUrl}/event/edit`, reqObject);

  return response.data;
};

export const editCampaign = reqObject => async dispatch => {
  const response = await axios.post(
    `${config.baseUrl}/event/editCampaign`,
    reqObject
  );

  return response.data;
};

export const createDM = reqObject => async dispatch => {
  const response = await axios
    .post(`${config.baseUrl}/editDM/createDm`, reqObject)
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });

  return response;
};

export const editDMLyDateMapping = reqObject => async () => {
  const response = await axios
    .post(`${config.baseUrl}/editDM/dmEdit`, reqObject)
    .then(res => {
      return res;
    })
    .catch(err => {
      return err;
    });
  return response;
};

export const editDmGetLyEvents = reqObject => async () => {
  const response = await axios
    .post(`${config.baseUrl}/editDM/lyDm`, reqObject)
    .then(res => {
      return res;
    })
    .catch(err => {
      return err;
    });
  return response;
};

export const pipelineIntermediateApi = reqObject => async () => {
  const response = await axios
    .post(`${config.baseUrl}/editDM/futureDm`, reqObject)
    .then(res => {
      return res;
    })
    .catch(err => {
      return err;
    });

  return response;
};

export const pipelineGetTyEvents = reqObject => async () => {
  const response = await axios
    .post(`${config.baseUrl}/editDM/tyDm`, reqObject)
    .then(res => {
      return res;
    })
    .catch(err => {
      return err;
    });

  return response;
};

export const uploadFile = reqObj => dispatch => {
  dispatch({
    type: LOADER,
    payload: true
  });
  axios
    .post(`${config.baseUrl}/editDM/uploadOffers`, reqObj)
    .then(response => {
      dispatch({
        type: 'uploadOffers_data',
        payload: response.data,
      });
      dispatch({
        type: UPLOAD_ERROR_MSG,
        payload: '',
      });
      dispatch({
        type: LOADER,
        payload: false,
      });
    })
    .catch(err => {
      dispatch({
        type: LOADER,
        payload: false,
      });
      dispatch({
        type: UPLOAD_ERROR_MSG,
        payload: err.response.data.msg,
      });
      return err;
    });
}

export const unsetUploadErrorMessage = () => dispatch => {
  dispatch({
    type: UPLOAD_ERROR_MSG,
    payload: '',
  });
}

export const uploadFileToBulkModify = reqObj => dispatch => {
  dispatch({
    type: LOADER,
    payload: true
  });
  axios
    .post(`${config.baseUrl}/editDM/uploadOffersBM`, reqObj)
    .then(response => {
      dispatch({
        type: 'uploadOffers_data',
        payload: response.data,
      });
      dispatch({
        type: UPLOAD_ERROR_MSG,
        payload: '',
      });
      dispatch({
        type: LOADER,
        payload: false,
      });
    })
    .catch(err => {
      dispatch({
        type: LOADER,
        payload: false,
      });
      dispatch({
        type: UPLOAD_ERROR_MSG,
        payload: err.response.data.msg,
      });
      return err;
    });
}

export const downloadUploadOfferCSV = () => (dispatch) => {
  return axios({
    url: `${config.baseUrl}/editDm/offersTemplate`,
    method: "GET",
    responseType: "blob"
  })
    .then((res) => {
      FileDownload(res.data, 'uploadOfferInputTemplate.csv');
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err,
      });
      return err;
    });
};

export const copyPromos = reqObject => async dispatch => {
  dispatch({
    type: LOADER,
    payload: true
  });
  let response = await axios
    .post(`${config.baseUrl}/editDM/copyPromos`, reqObject)
    .then(response => {
      dispatch({
        type: LOADER,
        payload: false,
      });
      return response.data;
    })
    .catch(err => {
      dispatch({
        type: LOADER,
        payload: false,
      });
      return err;
    });
  return response;
};

export const getDeactivatedPromos = reqObj => async dispatch => {
  dispatch({
    type: LOADER,
    payload: true
  });
  let response = await axios
    .post(`${config.baseUrl}/promo/deactivatedPromos`, reqObj)
    .then(response => {
      dispatch({
        type: LOADER,
        payload: false,
      });
      return response.data;
    })
    .catch(err => {
      dispatch({
        type: LOADER,
        payload: false,
      });
      return err;
    });
  return response;
}