import React, { Component } from "react";
import { connect } from "react-redux";
import DataTable from "../../../tables";
import ColumnDef from "../../../tables/colDefs/SkuListColDefs";
import RefSkuChildMessageRendrer from "./RefSkuChildMessageRendrer";
import RefSkuActionRenderer from "./RefSkuActionRenferer";

export class ReferenceSkuTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      referenceSkuExport: false,
      rowClassRules: {
        "update-flag-data": function(params) {
          if (params.data.is_similar_sku_updated === 0) {
            return true;
          } else {
            return false;
          }
        }
      }
    };
  }

  referenceSkuExportHandler = () => {
    this.setState({ referenceSkuExport: !this.state.referenceSkuExport });
  };

  render() {
    let tableDataVar = {
      header: ColumnDef.skuHeader,
      defaultColDef: ColumnDef.defaultColDef,
      data: this.props.tableData
    };
    return (
      <div style={{ padding: "0 12px" }}>
        <div
          className=" card cardStyles stylingForCompareCard"
          style={{ padding: "0.5%" }}
        >
          <div>
            <button
              type="button"
              className="btn delete-sku-btn-styling pipeline-button-class mr-3"
              title="Download"
              disabled={this.props.tableData.length ? false : true}
              onClick={() => {
                this.referenceSkuExportHandler();
                if (this.props.gaHandler) {
                  this.props.gaHandler();
                }
              }}
            >
              <i className="fa fa-download" aria-hidden="true" />
            </button>
          </div>
          <div
            className="card reference-sku-table-cont"
            style={{ width: "100%", height: "80%" }}
          >
            <DataTable
              parentId="myGrid"
              parentStyle={{
                height: "calc(100vh - 300px)",
                width: "100%"
              }}
              overlayNoRowsTemplate='<span class="pipeline-span">Please make a selection</span>'
              parentClass="ag-theme-balham"
              tableData={tableDataVar}
              refreshRows={this.props.refreshRows}
              refreshRowsHandler={this.props.refreshRowsHandler}
              referenceSkuExport={this.state.referenceSkuExport}
              referenceSkuExportHandler={this.referenceSkuExportHandler}
              downloadName="reference_sku_table"
              frameworkComponents={{
                refSkuChildMessageRenderer: RefSkuChildMessageRendrer,
                refSkuActionRenderer: RefSkuActionRenderer
              }}
              rowClassRules={this.state.rowClassRules}
              // autoSizeCol={true} //col messed up if uncommented
            />
          </div>
        </div>
      </div>
    );
  }
}

export default ReferenceSkuTable;
