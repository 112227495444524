import moment from 'moment';
import { ticketStatusCodes, ticketPriorityCodes, ticketTypes } from '../../constants';

export default {
  defaultColDef: {
    flex: 1,
    cellClass: 'cell-wrap-text',
    autoHeight: true,
    sortable: true,
    resizable: true,
  },
  columnDefs: [
    {
      headerName: 'Ticket ID',
      field: 'id',
      cellStyle: {
        color: 'blue',
        cursor: 'pointer',
        textDecoration: 'underline',
      },
      filter: "agTextColumnFilter"
    },
    {
      headerName: 'Title',
      field: 'title',
      cellStyle: { 'white-space': 'normal' },
      width: 250,
      filter: "agTextColumnFilter"
    },
    {
      headerName: 'Description',
      field: 'description',
      width: 500,
      valueGetter: function(params){
        if(params.data.description){
          let str = params.data.description;
          str = str.replace("<div>", "");
          str = str.replace("</div>", "");
          return str;
        }
        return "-";
      },
      cellStyle: { 'white-space': 'normal' },
      filter: "agTextColumnFilter"
    },
    {
      headerName: 'Status',
      field: 'status_id',
      cellRenderer: function (params) {
        // put the value in bold
        return `<div style='background-color:${
          ticketStatusCodes[params.value].color
        };color:#fff;border-radius:8px;text-align:center'>${ticketStatusCodes[params.value].value}</div>`;
      },
      filter: "agTextColumnFilter"
    },
    {
      headerName: 'Priority',
      field: 'priority_id',
      cellRenderer: function (params) {
        // put the value in bold
        return `<div style='background-color:${
          ticketPriorityCodes[params.value].color
        };color:#fff;border-radius:8px;text-align:center'>${ticketPriorityCodes[params.value].label}</div>`;
      },
      filter: "agTextColumnFilter"
    },
    {
      headerName: 'Ticket Type',
      field: 'ticket_type_id',
      valueFormatter: (params) => params.value && ticketTypes[params.value].label,
      filter: "agTextColumnFilter"
    },
    {
      headerName: 'Updated On',
      field: 'updated_on',
      valueFormatter: (params) => moment(params.value).format('YYYY-MM-DD HH:mm'),
      filter: "agTextColumnFilter"
    },
    {
      headerName: 'Created On',
      field: 'created_on',
      valueFormatter: (params) => moment(params.value).format('YYYY-MM-DD HH:mm'),
      filter: "agTextColumnFilter"
    },
  ],
};
