import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import moment from "moment";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import Popover from "./popover";
import EditDm from "./EditDm";
import { getCopyCampaignDetails } from "../../../../redux/actions/calendarAction";
import { batchProcessCheck } from "../../../../redux/actions/batchProcess";
import "./promoCalendarStyle.css";

class CalendarWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      campaignId: "",
      startDate: "",
      campaignName: "",
      endDate: "",
      lyStartDate: "",
      lyEndDate: "",
      styleMargin: [],
      showEditDmModal: false
    };
    this.Month = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ];
  }
  componentDidUpdate(old, prevState) {
    if (
      old.calendarDataArr !== this.props.calendarDataArr ||
      old.calendarWrapperArr !== this.props.calendarWrapperArr
    ) {
      if (this.props.calendarDataArr && this.props.calendarDataArr.length) {
        this.getPromotionRange();
      }
    }
  }

  getPromotionRange = () => {
    let { calendarWrapperArr, calendarDataArr } = this.props;
    let styleMargin = [];
    let startDate = calendarWrapperArr[0].start_date;
    let endDate;

    if (
      calendarWrapperArr[calendarWrapperArr.length - 1]
        .fiscal_week === 52 &&
      calendarWrapperArr[calendarWrapperArr.length - 1]
        .fiscal_quarter === 1
    ) {
      endDate = calendarWrapperArr[
        calendarWrapperArr.length - 2
      ].end_date;
    } else {
      endDate = calendarWrapperArr[
        calendarWrapperArr.length - 1
      ].end_date;
    }

    let diffDays = this.getDaysCount(startDate, endDate) + 1; /// +1 added

    typeof (calendarDataArr) == "object" && calendarDataArr.length && calendarDataArr.forEach(ele => {
      var dt = new Date(ele.start_date);
      // let marginEndDate = new Date(dt.setDate(dt.getDate() + 1)).toString();
      let marginEndDate;
      let width = 0;
      if (
        moment(ele.start_date).isSame(moment(ele.end_date))
        //new Date(ele.start_date).getDate() == new Date(ele.end_date).getDate()
      ) {
        width = 1;
        marginEndDate = new Date(dt.setDate(dt.getDate())).toString();/// +1 removed
      } else {
        marginEndDate = new Date(dt.setDate(dt.getDate())).toString();/// +1 removed
        if (calendarWrapperArr[0].start_date >= ele.start_date) {
          width =
            this.getDaysCount(
              calendarWrapperArr[0].start_date,
              ele.end_date
            ) + 1;
        } else {
          width = this.getDaysCount(ele.start_date, ele.end_date) + 1;
        }
      }

      let margin = this.getDaysCount(
        calendarWrapperArr[0].start_date,
        marginEndDate
      );

      if (margin < 0) {
        margin = 0;
      } else {
        margin = margin - 1;
      }
      let marginLeft, flexBasis;

      flexBasis = (width / diffDays) * 100; //If an Event lasts for 7 days, the flexBasis will be 7/28 which is 25% of the view

      if (calendarWrapperArr[0].start_date >= moment(ele.start_date).format('YYYY-MM-DD')) {
        marginLeft = 0; //For Events that is continued from previous view or starts from the day of the current view
      } else if (
        calendarWrapperArr[0].start_date < moment(ele.start_date).format('YYYY-MM-DD') &&
        this.props.view === 'Month'
      ) {
        marginLeft = (margin / diffDays) * 100 + 2.9; //Fix for margin left plot issue. This is for a view which has greater than 30 days
      } else {
        marginLeft = (margin / diffDays) * 100 + 1; //For Quarter view alone, since margin is subtracted by -1 above, need to compensate
      }

      if (100 - marginLeft < flexBasis) {
        flexBasis = 100 - marginLeft;
      }

      styleMargin.push({
        flexBasisInt: flexBasis,
        flexBasis: `${flexBasis}%`,
        marginLeft: `${marginLeft}%`
      });
    });
    this.setState({ styleMargin });
  };
  getDaysCount = (val1, val2) => {
    let date1 = moment(val1).format('YYYY-MM-DD');
    let date2 = moment(val2).format('YYYY-MM-DD');
    let oneDay = 24 * 60 * 60 * 1000;
    let firstDate = new Date(date1);
    let secondDate = new Date(date2);
    let diffDays = Math.round(
      (secondDate.getTime() - firstDate.getTime()) / oneDay
    );
    return diffDays;
  };

  editDm = (campaignId, startDate, endDate, name, lyStartDate, lyEndDate) => {
    this.props.batchProcessCheck();
    this.setState({
      showEditDmModal: true,
      campaignId,
      startDate,
      endDate,
      campaignName: name,
      lyStartDate,
      lyEndDate
    });
  };

  addPipelineAdgroup = (campaignId, startDate, endDate, name) => {
    this.setState({
      campaignId,
      startDate,
      endDate,
      campaignName: name
    });
  };

  editDmCloseModal = () => {
    this.setState({ showEditDmModal: false });
  };

  onDmEdit = () => {
    this.editDmCloseModal();
    this.props.onDmEdit();
  }

  render() {
    let sDate =
      !_.isEmpty(this.props.calendarWrapperArr) &&
        !_.isEmpty(this.props.calendarWrapperArr[0])
        ? this.props.calendarWrapperArr[0].start_date
        : null,
      quarterNum = sDate ? moment(sDate).quarter() : null;

    return (
      <div>
        <div className="calendarWeek row">
          <div
            align="right"
            className="col-6 centreCalendarText"
            style={{ color: "black" }}
          >
            <p id="caltext">
              {this.props.calendarWrapperArr &&
                this.props.calendarWrapperArr.length <= 6
                ? this.Month[
                Number(
                  new Date(
                    this.props.calendarWrapperArr[0].end_date
                  ).getMonth()
                )
                ] +
                " " +
                new Date(
                  this.props.calendarWrapperArr[0].end_date
                ).getFullYear()
                : `Quarter ${Number(
                  this.props.calendarWrapperArr[0].fiscal_quarter
                )}`}
              {/* &nbsp;- FY {this.props.selectedYear + 1} */}
            </p>
          </div>
        </div>

        <div className="CalendarWrapper">
          <div className="RowWrapper">
            {/* -----------------number of weeks in header------------------- */}
            {this.props.calendarWrapperArr.length ? (
              _.times(this.props.calendarWrapperArr.length, index1 => {
                let Sdate =
                  !_.isEmpty(this.props.calendarWrapperArr) &&
                    !_.isEmpty(this.props.calendarWrapperArr[index1])
                    ? this.props.calendarWrapperArr[index1].start_date
                    : null,
                  weekNum = Sdate ? moment(Sdate).isoWeek() : null;
                return (
                  <div
                    key={index1}
                    style={{ width: "25%" }}
                    className="CellWrapper HeaderWrapper"
                  >
                    <div>
                      <p className="calendarWeek1 text-center">
                        Week {weekNum}
                        {/* {this.props.calendarWrapperArr[index1].fiscal_week} */}
                      </p>
                      <p className="calendarWeekDate">
                        {moment(
                          this.props.calendarWrapperArr[
                            index1
                          ].start_date.substr(0, 10)
                        ).format("MM/DD ")}
                        -
                        {moment(
                          this.props.calendarWrapperArr[index1].end_date.substr(
                            0,
                            10
                          )
                        ).format(" MM/DD")}
                      </p>
                    </div>
                  </div>
                );
              })
            ) : (
              <div>No Data To Show</div>
            )}
          </div>
          <div className="calendarWrapDiv1" />
          <div className="calendarStyleCommon">
            <div className="scrollCalender calendarStyleCommon">
              {/* -----------------number of rows per data-------------- */}
              {this.props.calendarDataArr &&
                this.props.calendarDataArr.length &&
                this.state.styleMargin.length &&
                this.props.calendarDataArr.length ===
                this.state.styleMargin.length ? (
                _.times(this.props.calendarDataArr.length, indexRow => {
                  return (
                    <div key={indexRow} className="RowWrapper">
                      <div className="RowBodyWrapper" style={{ width: "100%" }}>
                        <div className="BgWrapper">
                          {/* --------------number of weeks per row------------- */}
                          {_.times(
                            this.props.calendarWrapperArr.length,
                            indextr => {
                              return (
                                <div
                                  key={indextr}
                                  style={{ width: "25%" }}
                                  className="CellWrapper row-cell-wrapper"
                                >
                                  <p className="calendarWeekDate">
                                    06/01 - 06/07
                                  </p>
                                </div>
                              );
                            }
                          )}
                        </div>
                        {/* --------------------Promotion Div----------------------- */}
                        <div className="RowContent" style={{ width: "100%" }}>
                          {_.times(1, indexTM => {
                            let colorCode = this.props.calendarDataArr[indexRow]
                              .color_code
                              ? this.props.calendarDataArr[indexRow].color_code
                              : "5ab4ff";

                            return (
                              <div key={indexTM} className="calendarRow">
                                {this.state.styleMargin[indexRow]
                                  .flexBasisInt ? (
                                  <div
                                    className="RowSegment RowSegmentStyle calendar-warning-cont"
                                    style={{
                                      backgroundColor: "#" + colorCode,
                                      flexBasis: this.state.styleMargin[
                                        indexRow
                                      ].flexBasis,
                                      marginLeft: this.state.styleMargin[
                                        indexRow
                                      ].marginLeft,
                                      boder: "1px solid red"
                                    }}
                                    ref="Progress1"
                                  >
                                    {this.props.calendarDataArr[indexRow]
                                      .warning_status === 1 ? (
                                      <i
                                        className="fa fa-exclamation-triangle calendar-warning-icon"
                                        aria-hidden="true"
                                        title={`Campaign (${this.props.calendarDataArr[indexRow].start_date} - ${this.props.calendarDataArr[indexRow].end_date}) events and coupons dates are affecting. Please make necessary changes`}
                                      />
                                    ) : null}
                                    {/* {JSON.stringify(this.props.calendarDataArr[indexRow])} */}
                                    {this.state.styleMargin[indexRow]
                                      .flexBasisInt > 1
                                      ? (<>
                                      { `${moment(this.props.calendarDataArr[indexRow]
                                          .start_date).format("MM/DD/YYYY")} - ${moment(this.props.calendarDataArr[indexRow]
                                          .end_date).format("MM/DD/YYYY")}` }
                                      </> )
                                      : "..."}
                                    <div className="popoverContainer">
                                      <Popover
                                        id={indexRow}
                                        showPromotionMain={
                                          this.showPromotionMain
                                        }
                                        showPromotionMainWithValues={
                                          this.showPromotionMainWithValues
                                        }
                                        promoInfo={
                                          this.props.calendarDataArr[indexRow]
                                        }
                                        onTabClick={this.props.onTabClick}
                                        name="test"
                                        onClickCopyAdgroup={(
                                          campaignId,
                                          startDate,
                                          endDate,
                                          name,
                                          lyStartDate,
                                          lyEndDate
                                        ) => {
                                          this.editDm(
                                            campaignId,
                                            startDate,
                                            endDate,
                                            name,
                                            lyStartDate,
                                            lyEndDate
                                          );
                                        }}
                                        onClickAddPipelineAdgroup={(
                                          campaignId,
                                          startDate,
                                          endDate,
                                          name
                                        ) => {
                                          this.addPipelineAdgroup(
                                            campaignId,
                                            startDate,
                                            endDate,
                                            name
                                          );
                                        }}
                                      />
                                    </div>
                                  </div>
                                ) : (
                                  <div className="Row" height="200"></div>
                                )}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : this.props.showLoading === true ? (
                <div className="Row noDataMessage2">No data to show!</div>
              ) : (
                <div className="Row noDataMessage2">
                  Please wait while data is loading...
                </div>
              )}
            </div>
          </div>
        </div>
        <div align="right" className="" style={{ marginBottom: "-10px" }}>
          <span className="calendar-color-description">Completed DMs</span>
          <span className="calendar-color-description">Matched DMs</span>
          <span className="calendar-color-description">Future DMs</span>
          <span className="calendar-color-description">Unmatched DMs</span>
          <span className="calendar-color-description">Ongoing DMs</span>
        </div>

        {this.state.showEditDmModal ? (
          <EditDm
            closeModal={this.editDmCloseModal}
            campaignId={this.state.campaignId}
            startDate={this.state.startDate}
            endDate={this.state.endDate}
            campaignName={this.state.campaignName}
            onDmEdit={this.onDmEdit}
            lyStartDate={this.state.lyStartDate}
            lyEndDate={this.state.lyEndDate}
          />
        ) : (
          <div />
        )}
      </div>
    );
  }
}

CalendarWrapper.propTypes = {
  getCopyCampaignDetails: PropTypes.func.isRequired,
  batchProcessCheck: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  showLoading: state.calendar.nodata
});

export default connect(mapStateToProps, { getCopyCampaignDetails, batchProcessCheck })(
  withRouter(CalendarWrapper)
);
