import React, { Component } from "react";
import { Bar } from "react-chartjs-2";
import AlertDanger from "../../../../utils/AlertMessage/alertDanger";
import AlertWarning from "../../../../utils/AlertMessage/alertWarning";
import AlertSuccess from "../../../../utils/AlertMessage/alertSuccess";
import AlertLoader from "../../../../utils/AlertMessage/alertMiniLoader";
import "./simulationStyle.css";
import _ from "lodash";
import {
  saveAdgroup,
  saveComment,
} from "../../../../redux/actions/SimulationAction";
import { usersLogReport } from "../../../../redux/actions/userAction";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import WaterfallChart from "../../../charts/WaterfallChart";
import WaterfallRevenue from "../../../charts/WaterfallRevenue";
import moment from "moment";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import CommentModal from "../Promo Pipeline/OverrideCommentModalPrompt";
import { emptyValueFormatter } from "../../../../utils/valueFormatters.js";
import SimulateTable from "./SimulateTable";
import LYOfferTable from "./LYOfferTable";
import OfferBuilder from "./OfferBuilder";
import OfferReplaceModal from "./OfferReplaceModal";
import PageLoader from "../../../../utils/Loader";
import { PromotionCalendarGA } from '../../../../utils/GA/GAUtils';
import { alertTimer } from "../../../../utils/alertTimer.js";

let globalOfferValue = [],
  cumulativeFilterArray = [];
export class PromoSimulate extends Component {
  state = {
    summaryTableExport:false,
    selectRow: [],
    showNoValueClickedWarning: false,
    inputAllValuesAlert: false,
    showSaveCommentModal: false,
    showOfferSaveWarning: false,
    commentAreaValue: "",
    commentAreaValueForMarketing: "",
    refreshTable: false,
    showDataExistsWarning: false,
    commentSavedMessage: false,
    showNoCommentWarning: false,
    showOfferReplaceModal: false,
    showOfferReplaceText: "",
    commentString: "",
    cumulativeFilterArray: [],
    enterAllValuesAlert: false,
    enterOverrideValuesAlert: false,
    gmOverride: null,
  };

  componentDidUpdate(prevProps) {
    if (
      !_.isEqual(this.props.selectedAdgroup, prevProps.selectedAdgroup) ||
      !_.isEqual(this.props.activeEventType, prevProps.activeEventType) ||
      (!_.isEmpty(this.props.offerBuildeRes) && this.props.simulateBtnClicked)
    ) {
      cumulativeFilterArray = [];
      this.setState({ cumulativeFilterArray: [] });
      this.props.clearOfferBuilderValue(true);
      if (this.props.simulateBtnClicked) {
        this.props.simulateBtnClickHandler();
      }
    }
  }

  refreshTableHandler = () => {
    this.setState({ refreshTable: false });
  };
  onSelectionChanged = (row) => {
    if (row.length) {
      let fromVal = this.offerValueCalc(
          this.props.activeEventType && this.props.activeEventType.offerType
            ? this.props.activeEventType.offerType
            : "",
          this.props.activeEventType && this.props.activeEventType.offerValue
            ? this.props.activeEventType.offerValue
            : ""
        ),
        toVal = this.deeperOfferValueCalc(
          row[0].offerType,
          row[0].offerValue,
          row[0].minquantity,
          row[0].bonusbyquantity,
          row[0].fixedpricequantity
        );

      this.setState({
        selectRow: row,
        commentAreaValue: `Offer Value changed from ${fromVal} to ${toVal} `,
      });
    }
  };

  onReplaceOffer = async () => {
    this.props.simualteLoaderHandler(true);

    let { selectRow } = this.state;
    let { activeEventType, offerDetails } = this.props,
      selectedAdgroupSkus = {};
    let list = {},
      campaignStartDate = localStorage.getItem("campStartDate"),
      campaignEndDate = localStorage.getItem("campEndDate");

    let activeOfferDetails = [
      _.find(offerDetails, {
        name: activeEventType.name,
        promo_id: activeEventType.promoId,
      }),
    ];

    selectedAdgroupSkus[activeEventType.name] = activeEventType.productList;

    if (this.props.fromPipelineIntermediate) {
      list[
        this.props.intermediateActiveEvent.name
      ] = this.props.intermediateActiveEvent.productList;
    }

    let res = await this.props.saveAdgroup({
      selectedAdgroupSkus: this.props.fromPipelineIntermediate
        ? list
        : selectedAdgroupSkus,
      cluster: null,
      startDate: moment(
        this.props.fromPipelineIntermediate
          ? this.props.intermediateActiveEvent.startDate
          : activeOfferDetails[0].start_date
      ).format("YYYY-MM-DD"),
      endDate: moment(
        this.props.fromPipelineIntermediate
          ? this.props.intermediateActiveEvent.endDate
          : activeOfferDetails[0].end_date
      ).format("YYYY-MM-DD"),
      fiscalYear: "2019-01-01",
      preSaveOfferType:
        selectRow[0].fixedpricequantity > 1 ? "nForM" : selectRow[0].offerType,
      preSaveOfferValue: selectRow[0].offerValue
        ? parseFloat(selectRow[0].offerValue)
        : 0,
      campaignId: this.props.fromPipelineIntermediate
        ? parseInt(localStorage.getItem("campaignId"))
        : activeOfferDetails[0].campaign_id,
      campaignStartDate: this.props.fromPipelineIntermediate
        ? moment(campaignStartDate).format("YYYY-MM-DD")
        : moment(activeOfferDetails[0].start_date).format("YYYY-MM-DD"),
      campaignEndDate: this.props.fromPipelineIntermediate
        ? moment(campaignEndDate).format("YYYY-MM-DD")
        : moment(activeOfferDetails[0].end_date).format("YYYY-MM-DD"),

      adgroupId: this.props.fromPipelineIntermediate
        ? this.props.selectedAdgroup.adgroup_id
        : activeOfferDetails[0].adgroup_id,
      promoId: this.props.fromPipelineIntermediate
        ? this.props.savedPromoId != null
          ? this.props.savedPromoId
          : null
        : activeOfferDetails[0].promo_id,
      index: 0,
      promoType: this.props.fromPipelineIntermediate
        ? this.props.intermediateActiveEvent.promoType.toLowerCase()
        : activeOfferDetails[0].promo_type.toLowerCase(),
      commentText: this.state.commentAreaValue,
      override_comment: this.state.commentString,
      updateFlag: 1,
      quantity_forecast_override:
        selectRow[0].override_units === "" ? null : selectRow[0].override_units,
      revenue_forecast_override:
        selectRow[0].override_sales === "" ? null : selectRow[0].override_sales,
      margin_forecast_override:
        selectRow[0].override_margin === ""
          ? null
          : selectRow[0].override_margin,
      gm_override: this.state.gmOverride * 100,
      bonusbyquantity: selectRow[0].bonusbyquantity,
      minquantity: selectRow[0].minquantity,
      fixedpricequantity: selectRow[0].fixedpricequantity,
      offerquantity: 0,
      replace: true,
      eventId: this.props.fromPipelineIntermediate
        ? null
        : activeEventType.event_id,
    });
    this.props.simualteLoaderHandler(false);
    if (res.status) {
      cumulativeFilterArray = [];
      this.setState({
        showOfferSaveWarning: true,
        commentAreaValue: "",
        showOfferReplaceModal: false,
        cumulativeFilterArray: [],
      });
      setTimeout(() => {
        this.setState({ showOfferSaveWarning: false });
      }, alertTimer);
      this.props.adgroupSavedHandler(
        this.props.fromPipelineIntermediate
          ? res.data.promoId
          : activeOfferDetails[0].promo_id,
        "noNeedForSimulation"
      );
    } else {
    }
    this.props.clearOfferBuilderValue(true);
  };

  onSaveOverrideComment = async (commentString) => {
    this.props.simualteLoaderHandler(true);
    if (commentString.length) {
      this.setState({
        commentString,
      });
    }

    let { selectRow } = this.state;
    let { activeEventType, offerDetails } = this.props,
      selectedAdgroupSkus = {},
      list = {},
      campaignStartDate = localStorage.getItem("campStartDate"),
      campaignEndDate = localStorage.getItem("campEndDate");

    let activeOfferDetails = [
      _.find(offerDetails, {
        name: activeEventType.name,
        promo_id: activeEventType.promoId,
      }),
    ];

    selectedAdgroupSkus[activeEventType.name] = activeEventType.productList;

    if (
      (selectRow[0].override_units.length &&
        (!selectRow[0].override_sales.length ||
          !selectRow[0].override_margin.length)) ||
      (selectRow[0].override_sales.length &&
        (!selectRow[0].override_units.length ||
          !selectRow[0].override_margin.length)) ||
      (selectRow[0].override_margin.length &&
        (!selectRow[0].override_units.length ||
          !selectRow[0].override_sales.length))
    ) {
      this.setState({
        enterOverrideValuesAlert: true,
      });

      setTimeout(() => {
        this.setState({
          enterOverrideValuesAlert: false,
        });
      }, alertTimer);
    } else {
      if (this.props.fromPipelineIntermediate) {
        list[
          this.props.intermediateActiveEvent.name
        ] = this.props.intermediateActiveEvent.productList;
      }
      let gmOverride =
        commentString === ""
          ? null
          : parseFloat(selectRow[0].override_margin) /
            parseFloat(selectRow[0].override_sales);

      this.setState({
        gmOverride,
      });

      this.props.usersLogReport({
        screen: "simulation",
        action: "save",
        userId: localStorage.getItem("email"),
      });

      let res = await this.props.saveAdgroup({
        selectedAdgroupSkus: this.props.fromPipelineIntermediate
          ? list
          : selectedAdgroupSkus,
        cluster: null,
        startDate: moment(
          this.props.fromPipelineIntermediate
            ? this.props.intermediateActiveEvent.startDate
            : activeOfferDetails[0].start_date
        ).format("YYYY-MM-DD"),
        endDate: moment(
          this.props.fromPipelineIntermediate
            ? this.props.intermediateActiveEvent.endDate
            : activeOfferDetails[0].end_date
        ).format("YYYY-MM-DD"),
        fiscalYear: "2019-01-01",
        preSaveOfferType:
          selectRow[0].fixedpricequantity > 1 && selectRow[0].minquantity === 0
            ? "nForM"
            : selectRow[0].offerType,
        preSaveOfferValue: selectRow[0].offerValue
          ? parseFloat(selectRow[0].offerValue)
          : 0,
        campaignId: this.props.fromPipelineIntermediate
          ? parseInt(localStorage.getItem("campaignId"))
          : activeOfferDetails[0].campaign_id,
        campaignStartDate: this.props.fromPipelineIntermediate
          ? moment(campaignStartDate).format("YYYY-MM-DD")
          : moment(activeOfferDetails[0].start_date).format("YYYY-MM-DD"),
        campaignEndDate: this.props.fromPipelineIntermediate
          ? moment(campaignEndDate).format("YYYY-MM-DD")
          : moment(activeOfferDetails[0].end_date).format("YYYY-MM-DD"),

        adgroupId: this.props.fromPipelineIntermediate
          ? this.props.selectedAdgroup.adgroup_id
          : activeOfferDetails[0].adgroup_id,
        promoId: this.props.fromPipelineIntermediate
          ? this.props.savedPromoId != null
            ? this.props.savedPromoId
            : null
          : activeOfferDetails[0].promo_id,
        index: 0,
        promoType: this.props.fromPipelineIntermediate
          ? this.props.intermediateActiveEvent.promoType.toLowerCase()
          : activeOfferDetails[0].promo_type.toLowerCase(),
        commentText: this.state.commentAreaValue,
        override_comment: this.state.commentString,
        updateFlag: 1,
        quantity_forecast_override:
          selectRow[0].override_units === ""
            ? null
            : selectRow[0].override_units,
        revenue_forecast_override:
          selectRow[0].override_sales === ""
            ? null
            : selectRow[0].override_sales,
        margin_forecast_override:
          selectRow[0].override_margin === ""
            ? null
            : selectRow[0].override_margin,
        gm_override: gmOverride * 100,
        bonusbyquantity: selectRow[0].bonusbyquantity,
        minquantity: selectRow[0].minquantity,
        fixedpricequantity: selectRow[0].fixedpricequantity,
        offerquantity: 0,
        replace: false,
        eventId: this.props.fromPipelineIntermediate
          ? null
          : activeEventType.event_id,
      });

      this.props.simualteLoaderHandler(false);

      if (res.status) {
        this.setState({ showOfferSaveWarning: true, commentAreaValue: "" });
        setTimeout(() => {
          this.setState({ showOfferSaveWarning: false });
        }, alertTimer);
        this.props.adgroupSavedHandler(
          this.props.fromPipelineIntermediate
            ? res.data.promoId
            : activeOfferDetails[0].promo_id,
          "noNeedForSimulation"
        );
      } else {
        this.setState({
          showOfferReplaceModal: true,
          showOfferReplaceText: res.msg,
        });
      }
    }
  };
  onSubmitClick = () => {
    let { selectRow } = this.state;
    if (!_.isEmpty(selectRow)) {
      let salesOverrideLength = selectRow[0].override_sales ? true : false;
      let unitsOverrideLength = selectRow[0].override_units ? true : false;
      let marginOverrideLength = selectRow[0].override_margin ? true : false;

      if (
        (salesOverrideLength && unitsOverrideLength && marginOverrideLength) ||
        (!salesOverrideLength && !unitsOverrideLength && !marginOverrideLength)
      ) {
        if (
          selectRow[0].override_sales > 0 &&
          selectRow[0].override_units > 0 &&
          selectRow[0].override_margin
        ) {
          this.setState({
            showSaveCommentModal: true,
          });
        } else {
          this.onSaveOverrideComment("");
        }
      } else {
        this.setState({
          inputAllValuesAlert: true,
        });

        setTimeout(() => {
          this.setState({
            inputAllValuesAlert: false,
          });
        }, alertTimer);
      }
    } else {
      this.setState({
        showNoValueClickedWarning: true,
      });

      setTimeout(() => {
        this.setState({
          showNoValueClickedWarning: false,
        });
      }, alertTimer);
    }
  };
//code added----------------------------
summaryTableExportHandler = () =>{
  console.log(this.state.summaryTableExport);
  this.setState({ summaryTableExport: false });
  console.log(this.state.summaryTableExport);
}
onDownloadSummaryData = () =>{
    console.log('Before');
    console.log(this.state.summaryTableExport);
    this.setState({ summaryTableExport: true });
    console.log(this.state.summaryTableExport);
}

  offerValueCalc = (type, value) => {
    if (type === "P") {
      return value + " % OFF";
    } else if (type === "F" || type === "nForM") {
      return "$ " + value;
    } else if (type === "A") {
      return "$ " + value + " OFF";
    } else if (type === "B" || type === "G" || type === "C" || type === "D") {
      return value;
    } else {
      return "-";
    }
  };

  deeperOfferValueCalc = (
    offerType,
    offerValue,
    minQty,
    bonusBuy,
    fixedpricequantity,
    buyPercent,
    getPercent
  ) => {
    if (offerType === "P") {
      return offerValue + " % OFF";
    } else if (offerType === "F" && fixedpricequantity <= 1) {
      return "$ " + offerValue;
    } else if (offerType === "A") {
      return "$ " + offerValue + " OFF";
    } else if (offerType === "B") {
      return `BUY ${minQty} GET ${bonusBuy} @ ${offerValue}% OFF`;
    } else if (offerType === "G") {
      return `BUY ${minQty} GET ${bonusBuy}`;
    } else if (offerType === "D") {
      return `BUY ${minQty} GET ${offerValue}% OFF EACH`;
    } else if (offerType === "C") {
      return `BUY ${minQty} GET ${bonusBuy} @ $ ${offerValue} OFF`;
    } else if (fixedpricequantity > 1) {
      return `${fixedpricequantity} for $ ${offerValue}`;
    } else {
      return "-";
    }
  };

  chartOptionsHandler = (name, promoArray, offerValueCalc) => {
    let chartTypeOptions = this.props.defaultSelectedradio;
    return {
      plugins: {
        datalabels: {
          display: false,
        },
        labels: false,
      },
      tooltips: {
        backgroundColor: "white",
        titleFontColor: "#2d69eb",
        bodyFontColor: "#2d69eb",
        borderColor: "#f4f4f4",
        boxShadow: "0 1px 15px 1px rgba(62, 57, 107, 0.07)",
        callbacks: {
          title: function ([tooltipItems]) {
            let val = offerValueCalc(chartTypeOptions, tooltipItems.xLabel);
            return "Offer: " + val;
          },
          label: function (t, d) {
            // if (t.datasetIndex === 0) {
            //   return `${name}: $` + emptyValueFormatter(t.yLabel.toFixed());
            // } else if (t.datasetIndex === 1) {
            //   return (
            //     Math.round(
            //       +t.yLabel.toString().replace(/(\d{2})(.*)/, "$1.$2")
            //     ) + "M"
            //   );
            // }
          },
          afterBody: function ([tooltipItems]) {
            var multistringText = promoArray[tooltipItems.index];
            return `    SKU Count: ${multistringText.skuCount}`;
          },
        },
      },
      scales: {
        xAxes: [
          {
            gridLines: {
              display: false,
              drawBorder: true,
              color: "#DCDCDC",
            },
            ticks: {
              fontSize: 10,
              opacity: 0.7,
              display: true,
            },
            scaleLabel: {
              display: true,
              labelString: globalOfferValue
                ? `Offer (${globalOfferValue})`
                : `Offer`,
            },
            barPercentage: 1.0,
          },
        ],
        yAxes: [
          {
            gridLines: {
              display: false,
              drawBorder: true,
              color: "#DCDCDC",
            },
            ticks: {
              display: false,
              fontSize: 10,
              beginAtZero: true,
              userCallback: function (value, index, values) {
                // Convert the number to a string and splite the string every 3 charaters from the end
                value = value.toString();
                value = value.split(/(?=(?:...)*$)/);
                value = value.join(",");
                return value;
              },
            },
            scaleLabel: {
              display: false,
              labelString: `${name} ($)`,
            },
          },
        ],
      },
      legend: {
        labels: {
          boxWidth: 20,
        },
      },
    };
  };
  chartRendrer = (data, chartOptions) => {
    return (
      <div className="col-md-6">
        <div className="chartDataDiv">
          <div>
            <Bar data={data} options={chartOptions} />
            <div
              className={
                this.state.showInsuffiecientData ? "chart-no-data" : "d-none"
              }
            >
              Insufficient data to forecast
            </div>
          </div>
        </div>
      </div>
    );
  };

  chartDataHandler = (labels, name, color, bgColor, promoArray) => {
    return {
      labels: labels,
      datasets: [
        {
          label: `Incremental ${name}`,
          backgroundColor: color,
          borderColor: color,
          borderWidth: 1,
          hoverBackgroundColor: bgColor,
          hoverBorderColor: bgColor,
          data: promoArray.map((item) => {
            if (name === "Sales") {
              return item.cannibalisationRevenue;
            }
            return item.cannibalisationMargin;
          }),
        },
      ],
    };
  };
  RecommendedTabDataRendrer = (rowData, SimulationItems, key, tyOffer) => {
    let offerValueCalc =
      rowData.offerType === "P" ||
      rowData.offerType === "F" ||
      rowData.offerType === "A"
        ? rowData.offerValue
        : rowData.offer;

    return (
      <div
        className="row simulationChartsForTab ScrollStyle1"
        id="simulaiton-table"
      >
        <div className="col-md-6 waterfallChartDivStyling">
          <div>
            <WaterfallChart
              offerType={rowData.offerType}
              offerValue={offerValueCalc}
              offerBuildeRes={this.props.offerBuildeRes}
              waterfallData={SimulationItems}
              responseKey={key - 4}
              sales_forecast_override={rowData.revenue_forecast_override}
              margin_forecast_override={rowData.margin_forecast_override}
              tyOffer={tyOffer}
            />
            <div className="waterfalldiv" />
          </div>
        </div>
        <div className="col-md-6 waterfallChartDivStyling">
          <div>
            <WaterfallRevenue
              offerType={rowData.offerType}
              offerValue={offerValueCalc}
              waterfallData={SimulationItems}
              sales_forecast_override={rowData.revenue_forecast_override}
              margin_forecast_override={rowData.margin_forecast_override}
              offerBuildeRes={this.props.offerBuildeRes}
              responseKey={key - 4}
              tyOffer={tyOffer}
            />
            <div className="waterfalldiv" />
          </div>
        </div>
      </div>
    );
  };
  addComparedValues = async (
    type,
    val,
    fixedPriceQty,
    minQty,
    bonusBuy,
    buyPercent,
    getPercent
  ) => {
    let cumulativeOfferValue =
      type.value +
      val +
      fixedPriceQty.toString() +
      minQty.toString() +
      bonusBuy.toString() +
      buyPercent.toString() +
      getPercent;

    if (
      ((type.value === "G" || type.value === "C") &&
        (minQty < 1 || bonusBuy < 1 || !minQty.length || !bonusBuy.length)) ||
      (type.value === "B" &&
        (minQty < 1 || bonusBuy < 1 || !minQty.length || !bonusBuy.length)) ||
      (type.value === "D" && (minQty < 1 || !minQty.length)) ||
      (type.value === "F" && !val.length) ||
      ((type.value === "P" || type.value === "A") && !val.length) ||
      _.isEmpty(type.value)
    ) {
      this.setState({
        enterAllValuesAlert: true,
      });

      setTimeout(() => {
        this.setState({
          enterAllValuesAlert: false,
        });
      }, alertTimer);
    } else if (
      this.state.cumulativeFilterArray.includes(cumulativeOfferValue)
    ) {
      this.setState({
        showDataExistsWarning: true,
      });
      setTimeout(() => {
        this.setState({
          showDataExistsWarning: false,
        });
      }, alertTimer);
      return;
    } else {
      cumulativeFilterArray.push(cumulativeOfferValue);
      let res = await this.props.addComparedValues(
        type,
        val,
        fixedPriceQty,
        minQty,
        bonusBuy,
        buyPercent,
        getPercent
      );
      this.setState({
        refreshTable: true,
        cumulativeFilterArray,
      });
    }
  };

  componentWillUnmount() {
    cumulativeFilterArray = [];
  }
  onSaveComment = () => {
    if (
      this.state.commentAreaValueForMarketing.length > 0 &&
      /\S/.test(this.state.commentAreaValueForMarketing)
    ) {
      this.props.saveComment({
        promoId: this.props.savedPromoId,
        commentText: this.state.commentAreaValueForMarketing,
      });

      this.setState({
        commentSavedMessage: true,
      });

      setTimeout(() => {
        this.setState({
          commentSavedMessage: false,
        });
      }, alertTimer);
    } else {
      this.setState({
        showNoCommentWarning: true,
      });

      setTimeout(() => {
        this.setState({
          showNoCommentWarning: false,
        });
      }, alertTimer);
    }
  };

  handleSimulationComments = (e) => {
    this.setState({ commentAreaValue: e.target.value }, () => {});
  };

  handleSimulationCommentsForMarketing = (e) => {
    this.setState({ commentAreaValueForMarketing: e.target.value }, () => {});
  };

  closeModal = () => {
    this.setState({
      showOfferReplaceModal: !this.state.showOfferReplaceModal,
    });
  };
  submitGA(action,label){
    PromotionCalendarGA({ action, label});
  }

  render() {
    let dataRevenue = {};
    let dataMargin = {};
    let promoRevenueArray = [];
    let promoMarginArray = [];
    let offerValues = [];

    let tyOffer = [];
    let SimulationItems = [],
      activeSimuateData = [];

    let {
      activeEventType,
      offerDetails,
      simulationDataFromParent,
      simulateTableData,
      fromPipelineIntermediate,
    } = this.props;

    if (
      !_.isEmpty(simulationDataFromParent) &&
      !_.isEmpty(simulationDataFromParent.data)
    ) {
      SimulationItems = simulationDataFromParent.data;
      if (!_.isEmpty(offerDetails)) {
        tyOffer = [
          _.find(offerDetails, {
            name: activeEventType.name,
            promo_id: activeEventType.promoId,
          }),
        ];

        globalOfferValue = !tyOffer[0]
          ? ""
          : tyOffer[0].offer_type === "P"
          ? "%"
          : "$";
      }

      if (fromPipelineIntermediate) {
        globalOfferValue =
          this.props.defaultSelectedradio === "P"
            ? "%"
            : this.props.defaultSelectedradio === "F" ||
              this.props.defaultSelectedradio === "A" ||
              this.props.defaultSelectedradio === "nForM"
            ? "$"
            : null;

        activeSimuateData = _.find(SimulationItems, {
          adgroupName: this.props.selectedAdgroup.value,
          offerType: this.props.defaultSelectedradio,
        });
      } else {
        activeSimuateData = _.find(SimulationItems, {
          adgroupName: tyOffer[0].name,
          offerType: this.props.defaultSelectedradio, //tyOffer[0].offer_type
        });
      }

      if (!_.isEmpty(activeSimuateData)) {
        offerValues = activeSimuateData.possibleOfferValues.map(
          (roundeOffVal) => {
            return roundeOffVal;
          }
        );

        _.forEach(activeSimuateData.output, (adgroupData) => {
          promoRevenueArray.push({
            cannibalisationRevenue:
              Math.round(adgroupData["total_Aff_Cann_Inc_Revenue"] * 100) / 100,
            skuCount: adgroupData["modellable_sku_count"],
          });

          promoMarginArray.push({
            cannibalisationMargin:
              Math.round(adgroupData["total_Aff_Cann_Inc_Margin"] * 100) / 100,
            skuCount: adgroupData["modellable_sku_count"],
          });
          return adgroupData;
        });

        dataRevenue = this.chartDataHandler(
          offerValues,
          "Sales",
          "#3085E0",
          "rgb(234, 173, 81)",
          promoRevenueArray
        );
        dataMargin = this.chartDataHandler(
          offerValues,
          "Margin",
          "#FFCD56",
          "rgba(45,105,235,0.8)",
          promoMarginArray
        );
      }
    }
    const chartOptionsRevenue = this.chartOptionsHandler(
      "Sales",
      promoRevenueArray,
      this.offerValueCalc
    );

    const chartOptionsMargin = this.chartOptionsHandler(
      "Margin",
      promoMarginArray,
      this.offerValueCalc
    );

    return (
      <div>
        <div className="alertDivs">
          {this.state.showNoSimulationData ? (
            <AlertDanger
              message="No simulation data for this Adgroup!"
              show={this.state.showNoSimulationData}
            />
          ) : null}

          {this.state.inputAllValuesAlert ? (
            <AlertDanger
              message="Please enter all 3 fields!"
              show={this.state.inputAllValuesAlert}
            />
          ) : null}

          {this.state.showDataExistsWarning ? (
            <div style={{ margin: "0rem 2rem" }}>
              <AlertDanger
                message="Data already exists for this selection!"
                show={this.state.showDataExistsWarning}
              />
            </div>
          ) : null}

          {this.state.showDataNotWarning ? (
            <div style={{ margin: "0rem 2rem" }}>
              <AlertDanger
                message="Please Select Offer Value"
                show={this.state.showDataNotWarning}
              />
            </div>
          ) : null}

          {this.state.enterOverrideValuesAlert ? (
            <div style={{ margin: "0rem 2rem" }}>
              <AlertDanger
                message="Please enter all override values"
                show={this.state.enterOverrideValuesAlert}
              />
            </div>
          ) : null}

          {this.state.showOfferSaveWarning ? (
            <div style={{ margin: "0rem 2rem" }}>
              <AlertSuccess
                message="Offer saved successfully!"
                show={this.state.showOfferSaveWarning}
              />
            </div>
          ) : null}

          {this.state.commentSavedMessage ? (
            <div style={{ margin: "0rem 2rem" }}>
              <AlertSuccess
                message="Comment saved successfully!"
                show={this.state.commentSavedMessage}
              />
            </div>
          ) : null}

          {this.state.showNoValueClickedWarning ? (
            <div style={{ margin: "0rem 2rem" }}>
              <AlertDanger
                message="Please select an offer!"
                show={this.state.showNoValueClickedWarning}
              />
            </div>
          ) : null}

          {this.state.showNoCommentWarning ? (
            <div style={{ margin: "0rem 2rem" }}>
              <AlertDanger
                message="Please enter a comment!"
                show={this.state.showNoCommentWarning}
              />
            </div>
          ) : null}

          {this.state.showNoZeroWarning ? (
            <div style={{ margin: "0rem 2rem" }}>
              <AlertDanger
                message="Saved offer cannot be zero!"
                show={this.state.showNoZeroWarning}
              />
            </div>
          ) : null}

          {this.state.enterAllValuesAlert ? (
            <div style={{ margin: "0rem 2rem" }}>
              <AlertDanger
                message="Please enter all fields!"
                show={this.state.enterAllValuesAlert}
              />
            </div>
          ) : null}

          {this.props.noCoefficientAlert ? (
            <AlertWarning
              message="Insufficient data to forecast!"
              show={this.props.noCoefficientAlert}
            />
          ) : null}

          {this.props.offerBuilderError ? (
            <AlertDanger
              message="No coefficients data"
              show={this.props.offerBuilderError}
            />
          ) : null}
        </div>

        <>
          {!this.props.simulateLoader ? (
            <div>
              <div className="card cardGap mx-3">
                <div className="row px-1 my-3 mx-0">
                  {this.chartRendrer(dataMargin, chartOptionsMargin)}
                  {this.chartRendrer(dataRevenue, chartOptionsRevenue)}
                </div>
              </div>
              <div>{
                !this.props.simulateLoader ?null: <div className="card cardGap mx-3">
                <h5 className="offerBuilderText ml-3">
                  Recommendation Section
                </h5>

                <SimulateTable
                  simulateTableData={this.props.simulateTableData}
                  topTable={true}
                  // addRowLoader={this.props.addRowLoader}
                  onSelectionChanged={this.onSelectionChanged}
                  refreshTable={this.state.refreshTable}
                  refreshTableHandler={this.refreshTableHandler}
                />

                <div className="row simulationCharts">
                  <div className=" col-md-10 simulationAddOfferOption tooltipDiv">
                    <div className="footerTextSimulation">
                      <p className="mb-0 simulationFooterText">
                        <i className="fa fa-exclamation-triangle" /> Please note
                        these recommendations should be used directionally, as
                        they do not incorporate best deal overlapping logic
                      </p>
                    </div>
                  </div>
                </div>
              </div>
                }
              </div>
              <div className="card cardGap mx-3">
                <OfferBuilder
                  addComparedValues={this.addComparedValues}
                  adgroupWithEventType={this.props.adgroupWithEventType}
                  intermediateActiveEvent={this.props.intermediateActiveEvent}
                  fromPipelineIntermediate={this.props.fromPipelineIntermediate}
                  simulationDataFromParent={simulationDataFromParent}
                />
              </div>
              <div className="card cardGap mx-3">
                <div className="card simulateSummaryTable">
                  <Tabs
                    id="controlled-tab-example"
                    activeKey={this.state.key2}
                    style={{ overflowY: "hidden" }}
                    onSelect={(key2) => {
                      this.setState({ key2 })
                      this.submitGA("Click", "OfferBuilder_SwitchedTabs");
                    }}
                    className="simulatn-table-tab simSummary-tab-content"
                  >
                    <Tab eventKey="home2" title="Summary">
                    <div className="summary-list-download">
                      {/* Export button added for summary table */}
                      <button
                        type="button"
                        title="Download"
                        className="btn btnSaveProducts"
                        disabled={this.props.simulateTableData
                          ?false: true 
                        }
                        onClick={() => {
                          this.onDownloadSummaryData()
                          this.submitGA("Click", "Clicked_OfferBuilder_DownloadButton");
                        }}
                      >
                        <i className="fa fa-download" aria-hidden="true" />
                      </button>
                    </div>
                      <PageLoader loader={this.props.addRowLoader}>                      
                        <SimulateTable
                          simulateTableData={this.props.simulateTableData}
                          topTable={false}
                          onSelectionChanged={this.onSelectionChanged}
                          refreshTable={this.state.refreshTable}
                          refreshTableHandler={this.refreshTableHandler}
                          summaryTableExport={this.state.summaryTableExport}
                          summaryTableExportHandler={this.summaryTableExportHandler}
                        />
                      </PageLoader>
                    </Tab>
                    {_.map(simulateTableData, (rowData, key) => {
                      let eventKey =
                        key === 0
                          ? "recommendedMargin"
                          : key === 1
                          ? "recommendedRevenue"
                          : key === 2
                          ? "recommendedGM"
                          : key === 3
                          ? "tyOffer"
                          : key;
                      let title =
                        key === 0
                          ? "Rec. Margin"
                          : key === 1
                          ? "Rec. Sales"
                          : key === 2
                          ? "Rec. GM"
                          : key === 3
                          ? "TY Offer"
                          : `Scenario ${key - 3}`;
                      return (
                        <Tab eventKey={eventKey} title={title}>
                          {this.RecommendedTabDataRendrer(
                            rowData,
                            SimulationItems,
                            key,
                            tyOffer
                          )}
                        </Tab>
                      );
                    })}
                  </Tabs>
                  <div style={{display: "flex",
                              justifyContent: "left", marginLeft:"15px"}}>
                  <div style={{color:"red", marginRight:"2px"}}>*</div>
                  <div style={{color:"gray"}}>This table does not take best deal overlapping logic into account</div> 
                  </div>
                 <div className="row commentAreaDiv">
                    <div className="col-md-8 commentAreaInnerDiv">
                      <input
                        type="text"
                        className="form-control card commentAreaInput"
                        rows="5"
                        placeholder="Enter your comment ⃰"
                        value={
                          localStorage.getItem("role") === "3" ||
                          localStorage.getItem("role") === "5"
                            ? this.state.commentAreaValueForMarketing
                            : this.state.finalOfferTypeClicked !== null
                            ? this.state.commentAreaValue
                            : ""
                        }
                        onChange={
                          localStorage.getItem("role") === "3" ||
                          localStorage.getItem("role") === "5"
                            ? this.handleSimulationCommentsForMarketing
                            : this.handleSimulationComments
                        }
                      />
                    </div>
                    <div className="col-md-2 commentBtnDiv">
                      <button
                        type="button"
                        title={
                          localStorage.getItem("role") === "3" ||
                          localStorage.getItem("role") === "5"
                            ? "Save comment"
                            : "Save simulation"
                        }
                        className="btn btn-outline-primary btnPromo btnsimuApply"
                        onClick={
                          localStorage.getItem("role") === "3" ||
                          localStorage.getItem("role") === "5"
                            ? () => {
                                this.onSaveComment();
                              }
                            : () => {
                                this.onSubmitClick();
                                this.submitGA("Click", "Clicked_OfferBuilder_ApplyButton");
                              }
                        }
                        disabled={
                          localStorage.getItem("role") === "3" ||
                          localStorage.getItem("role") === "5"
                            ? false
                            : this.state.isLYActive
                        }
                      >
                        {localStorage.getItem("role") === "3" ||
                        localStorage.getItem("role") === "5"
                          ? "Save Comment"
                          : "Apply"}
                      </button>
                    </div>
                  </div>
                </div>
                {this.state.showSaveCommentModal ? (
                  <CommentModal
                    sendCommentValue={(commentString) => {
                      this.onSaveOverrideComment(commentString);
                    }}
                    closeDeleteModal={() =>
                      this.setState({ showSaveCommentModal: false })
                    }
                  />
                ) : null}

                {this.state.showOfferReplaceModal ? (
                  <OfferReplaceModal
                    closeModal={this.closeModal}
                    onReplaceOffer={this.onReplaceOffer}
                    showOfferReplaceText={this.state.showOfferReplaceText}
                  />
                ) : null}
              </div>

              <div className="card m-3">
                <div className="card simulateSummaryTable">
                  <div id="accordion" className="accordion">
                    <div
                      className={"card-header collapsed"}
                      data-toggle="collapse"
                      data-parent="#accordion"
                      href="#collapseSearchOne"
                    >
                      <a onClick={() => this.submitGA("Click", "Clicked_OfferBuilder_LYOfferButton")} className="card-title">LY Offer</a>
                    </div>
                    <div
                      id="collapseSearchOne"
                      className="pb-5 card-body collapse"
                      data-parent="#accordion"
                    >
                      <LYOfferTable
                        fromPipelineIntermediate={
                          this.props.fromPipelineIntermediate
                        }
                        selectedAdgroup={this.props.selectedAdgroup}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </>
      </div>
    );
  }
}

PromoSimulate.propTypes = {
  saveAdgroup: PropTypes.func.isRequired,
  saveComment: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  loader: state.simulationData.loader,
});

export default connect(mapStateToProps, {
  saveComment,
  saveAdgroup,
  usersLogReport,
})(withRouter(PromoSimulate));
