import {
  LOADER,
  SET_AGPAR_REPORTS_DATA,
  SET_AGDAR_REPORTS_DATA,
  SET_BUYER_OPTIONS,
  SET_ADGROUP_OPTIONS,
  SET_THEME_OPTIONS,
  SET_ARTICLE_OPTIONS,
  SET_EVENT_OPTIONS,
  SET_GMM_OPTIONS,
  SET_PRICE_LIST_OPTIONS
} from "../actions/types";

import _ from "lodash";

const initialState = {
  loader: false,
  agparReportsData: [],
  agdarReportsData: [],
  buyerOptions: [],
  adGroupOptions : [],
  themeOptions : [],
  articleOptions: [],
  eventIdOptions: [],
  gmmOptions: [],
  priceListOptions: [],
};

const formatBuyerOptions = buyerData => {
  const tempbuyerOptions = buyerData.buyerDetails.map(buyer => {
    return {
      value: buyer.buyer_id,
      label: buyer.buyer_id
    }
  });
  return tempbuyerOptions
}

    const formatThemeOptions = themeData => {
    const tempThemeOptions = themeData.themes.map(theme => {
      const startDate = new Date(theme.start_date).toLocaleDateString();
        const endDate = new Date(theme.end_date).toLocaleDateString();
      return {
        value: theme.theme,
        label: `${theme.theme} - (${startDate} - ${endDate})` ,
        id : theme.id

      }
    });
    return tempThemeOptions
  }

    const formatAdGroupOptions = adGroupData => {
    const tempadGroupOptions = adGroupData.adGroups.map(adGroup => {
      return {
        value: adGroup.id,
        label: adGroup.name
      }
    });
    return tempadGroupOptions
  }

  const formatArticleOptions = articleData => {
    const tempArticleData = articleData.map(id => {
      return {
        // value: id.unique_id1,
        // label: id.unique_id1
        value: id,
        label: id
      }
    })
    return tempArticleData
  }

  const formatEventOptions = eventIdData => {
    const tempeventIdData = eventIdData.eventIds.map(event => {
      return {
        value: event.g_eid,
        label: event.id
      }
    })
    return tempeventIdData
  }

  const formatGmmOptions = gmmData => {
    const tempGmmData = gmmData.gmm.map(id => {
      return {
        value: id.gmm,
        label: id.gmm
      }
    })
    return tempGmmData
  }

  const formatPriceListOptions = priceListData => {
    const tempPriceListOptions = priceListData.priceList.map(price => {
      return {
        value: price.price_list,
        label: price.price_list
      }
    })
    return tempPriceListOptions;
  }

export default function (state = initialState, action) {
  switch (action.type) {

    case LOADER:
      return {
        ...state,
        loader: action.payload
      };
    case SET_AGPAR_REPORTS_DATA:
      return {
        ...state,
        agparReportsData: action.payload
      };
    case SET_AGDAR_REPORTS_DATA:
      return {
        ...state,
        agdarReportsData: action.payload
      };
      case SET_BUYER_OPTIONS:
        return {
          ...state,
          buyerOptions: formatBuyerOptions(action.payload)
        };
      case SET_ADGROUP_OPTIONS:
        return {
          ...state,
          adGroupOptions: formatAdGroupOptions(action.payload)
        };
      case SET_THEME_OPTIONS:
        return {
          ...state,
          themeOptions: formatThemeOptions(action.payload)
        };
      case SET_ARTICLE_OPTIONS:
        console.log("-=======",formatArticleOptions(action.payload))
        return {
          ...state,
          articleOptions: formatArticleOptions(action.payload)
        };
      case SET_EVENT_OPTIONS:
        return {
          ...state,
          eventIdOptions: formatEventOptions(action.payload)
        };
      case SET_GMM_OPTIONS:
        return {
          ...state,
          gmmOptions: formatGmmOptions(action.payload)
        };
      case SET_PRICE_LIST_OPTIONS:
        return {
          ...state,
          priceListOptions: formatPriceListOptions(action.payload)
        };
    default:
      return state;
  }
}
