import moment from "moment";
import {
  emptyValueFormatterNew
} from "../../../utils/valueFormatters.js";

export default {
  defaultColDef: {
    headerCheckboxSelection: isFirstColumn,
    headerCheckboxSelectionFilteredOnly: true,
    checkboxSelection: isFirstColumn,
    resizable: true,
    sortable: true,
    filter: true
  },
  header: function (editOffers) {
    return [
      {
        headerName: "Adgroup ID",
        field: "adgroup_id",
        hide: "true"
      },
      {
        headerName: "Adgroup",
        field: "adgroup_name",
        headerClass: "gray-header-bg",
        width: 180,
        pinned: "left",
        filter: "agTextColumnFilter",
        suppressSizeToFit: true,
        cellStyle: null,
        unSortIcon: true,
        checkboxSelection: params => {
          if (params.data.uploadStatus === "Successful") {
            return true
          } else {
            return false
          }
        }
      },
      {
        headerName: "Adgroup Desc.",
        field: "adgroup_desc",
        filter: "agTextColumnFilter",
        headerClass: "gray-header-bg",
        suppressSizeToFit: true,
        pinned: "left",
        unSortIcon: true
      },
      {
        headerName: "Adgroup Type",
        field: "adgroup_type",
        filter: "agTextColumnFilter",
        headerClass: "blue-header-bg",
        suppressSizeToFit: true,
        unSortIcon: true
      },
      {
        headerName: "Merch ID",
        field: "merch_id",
        filter: "agTextColumnFilter",
        headerClass: "blue-header-bg",
        suppressSizeToFit: true,
        unSortIcon: true
      },
      {
        headerName: "TY event",
        field: "ty_event_type",
        headerClass: "blue-header-bg",
        width: 350,
        filter: "agTextColumnFilter",
        valueGetter: tyEventTypeFormatter,
        unSortIcon: true
      },
      { headerName: "TY Start Date", field: editOffers ? "start_date" : "ty_start_date", hide: true },
      { headerName: "TY End Date", field: editOffers ? "end_date" : "ty_end_date", hide: true },
      { headerName: "Offer quantity", field: "offer_quantity", hide: true },
      { headerName: "Event ID old", field: "eventId", hide: true },
      { headerName: "Bulk modifies", field: "is_bulkModified", hide: true },
      {
        headerName: "Event ID", //Non-view purpose, used only for storing options value change
        field: "g_eid",
        headerClass: "blue-header-bg",
        filter: "agTextColumnFilter",
        unSortIcon: true,
      },
      {
        headerName: "TY Sign shell",
        field: "promo_sign_shell",
        headerClass: "blue-header-bg",
        width: 300,
        // cellRenderer: "pipelineDropdownRenderer",
        filter: "agTextColumnFilter",
        unSortIcon: true
      },
      {
        headerName: "TY promo type",
        field: "promo_event_type",
        headerClass: "blue-header-bg",
        width: 300,
        // cellRenderer: "pipelineDropdownRenderer",
        filter: "agTextColumnFilter",
        unSortIcon: true
      },
      {
        headerName: "TY UAS",
        field: "ty_uas",
        headerClass: "blue-header-bg",
        width: 300,
        // cellRenderer: "pipelineDropdownRenderer",
        filter: "agTextColumnFilter",
        valueGetter: tyuasFormatter,
        unSortIcon: true
      },
      {
        headerName: "TY promo status",
        field: "promo_build_status",
        headerClass: "blue-header-bg",
        width: 300,
        // cellRenderer: "pipelineDropdownRenderer",
        filter: "agTextColumnFilter",
        unSortIcon: true
      },
      {
        headerName: "Event type options", //Non-view purpose, used only for storing options value change
        field: "eventTypeOptions",
        headerClass: "blue-header-bg",
        filter: "agTextColumnFilter",
        unSortIcon: true,
        hide: true
      },
      {
        headerName: "TY Planned Offer Type",
        field: "ty_offer_type",
        headerClass: "blue-header-bg",
        width: 300,
        filter: "agTextColumnFilter",
        unSortIcon: true
      },
      {
        headerName: "Offer type options", //Non-view purpose, used only for storing options value change
        field: "offerTypeOptions",
        filter: "agTextColumnFilter",
        headerClass: "blue-header-bg",
        unSortIcon: true,
        hide: true
      },
      {
        headerName: "TY Planned Offer Amount",
        field: "ty_planned_amount",
        filter: "agTextColumnFilter",
        width: 250,
        headerClass: "blue-header-bg",
        suppressSizeToFit: true,
        unSortIcon: true
      },
      {
        headerName: "Fixed price Qty",
        field: "fixed_price_quantity",
        filter: "agTextColumnFilter",
        width: 200,
        headerClass: "blue-header-bg",
        suppressSizeToFit: true,
        unSortIcon: true
      },
      {
        headerName: "TY Min Qty for offer",
        field: "ty_min_qty",
        filter: "agTextColumnFilter",
        headerClass: "blue-header-bg",
        width: 250,
        suppressSizeToFit: true,
        unSortIcon: true
      },
      {
        headerName: "TY Bonus Buy (Free Goods Qty)",
        field: "ty_bonus_pay",
        filter: "agTextColumnFilter",
        headerClass: "blue-header-bg",
        width: 275,
        suppressSizeToFit: true,
        unSortIcon: true
      },
      {
        headerName: "TY (Buy $) Min value",
        field: "ty_buy_min_value",
        filter: "agTextColumnFilter",
        headerClass: "blue-header-bg",
        width: 250,
        suppressSizeToFit: true,
        filterParams: { defaultOption: "startsWith" },
        unSortIcon: true
      },
      {
        headerName: "TY (Get $) Max value",
        field: "ty_get_max_value",
        filter: "agTextColumnFilter",
        headerClass: "blue-header-bg",
        width: 250,
        suppressSizeToFit: true,
        unSortIcon: true
      },
      {
        headerName: "Reg. low amt.",
        field: "regLow",
        headerClass: "blue-header-bg",
        filter: "agTextColumnFilter",
        unSortIcon: true,
      },
      {
        headerName: "Reg. high amt",
        field: "regHigh",
        headerClass: "blue-header-bg",
        filter: "agTextColumnFilter",
        unSortIcon: true,
      },
      {
        headerName: "Sale low amt.",
        field: "saleLow",
        headerClass: "blue-header-bg",
        filter: "agTextColumnFilter",
        unSortIcon: true,
      },
      {
        headerName: "Sale high amt.",
        field: "saleHigh",
        headerClass: "blue-header-bg",
        filter: "agTextColumnFilter",
        unSortIcon: true,
      },
      {
        headerName: "LY Event Type",
        field: "ly_event_type",
        filter: "agTextColumnFilter",
        headerClass: "gray-header-bg",
        suppressSizeToFit: true,
        unSortIcon: true
      },
      {
        headerName: "LY Start Date",
        field: "ly_start_date",
        filter: "agTextColumnFilter",
        headerClass: "gray-header-bg",
        suppressSizeToFit: true,
        unSortIcon: true,
        valueFormatter: function (params) {
          if (params.value) {
            let valueVar = moment(params.data.ly_start_date).format("MM/DD/YYYY");
            return valueVar;
          }
          return "-";
        }
      },
      {
        headerName: "LY End Date",
        field: "ly_end_date",
        filter: "agTextColumnFilter",
        headerClass: "gray-header-bg",
        suppressSizeToFit: true,
        unSortIcon: true,
        valueFormatter: function (params) {
          if (params.value) {
            let valueVar = moment(params.data.ly_end_date).format("MM/DD/YYYY");
            return valueVar;
          }
          return "-";
        }
      },
      {
        headerName: "LY offer",
        field: "ly_offer",
        filter: "agTextColumnFilter",
        headerClass: "gray-header-bg",
        suppressSizeToFit: true,
        unSortIcon: true
      },
      {
        headerName: "LY offer type",
        field: "ly_offer_type",
        filter: "agTextColumnFilter",
        headerClass: "gray-header-bg",
        suppressSizeToFit: true,
        unSortIcon: true
      },
      {
        headerName: "LY SKU Count",
        field: "ly_sku_count",
        filter: "agTextColumnFilter",
        headerClass: "gray-header-bg",
        suppressSizeToFit: true,
        unSortIcon: true,
      },
      {
        headerName: "LY Actual Units",
        field: "ly_actual_units",
        filter: "agTextColumnFilter",
        headerClass: "gray-header-bg",
        suppressSizeToFit: true,
        valueFormatter: emptyValueFormatterNew, //emptyValueFormatter,
        unSortIcon: true
      },
      {
        headerName: "LY Actual Sales ($)",
        field: "ly_actual_sales",
        filter: "agTextColumnFilter",
        headerClass: "gray-header-bg",
        valueFormatter: emptyValueFormatterNew, //emptyValueFormatterWithDollar,
        suppressSizeToFit: true,
        unSortIcon: true
      },
      {
        headerName: "LY Actual Margin ($)",
        field: "ly_actual_margin",
        filter: "agTextColumnFilter",
        valueFormatter: emptyValueFormatterNew, //emptyValueFormatterWithDollar,
        headerClass: "gray-header-bg",
        suppressSizeToFit: true,
        unSortIcon: true
      },
      {
        headerName: "LY GM (%)",
        field: "ly_gm",
        filter: "agTextColumnFilter",
        headerClass: "gray-header-bg",
        suppressSizeToFit: true,
        unSortIcon: true,
        valueFormatter: function (params) {
          if (params.value) {
            let valueVar = Math.round(params.data.ly_gm * 100) / 100 + " %";
            return valueVar;
          }
          return "-";
        }
      },
      {
        headerName: "Upload Status",
        field: 'uploadStatus',
        // cellRenderer: function (params) {
        //   if (params.value === "Failed") {
        //     return '<span class="upload-fail">' + params.value + ' <i class="fa fa-info-circle"></i></span>'
        //   } else {
        //     return '<span class="upload-success">' + params.value + '</span>'
        //   }
        // },
        cellRenderer: "promoStatusRenderer",
        tooltipField: 'validationErrors',
        pinned: 'right',
        headerClass: "blue-header-bg",
        width: 180,
        filter: "agTextColumnFilter"
      }
    ]
  },
  uploadErrorsData : [
    {
      headerName: "Adgroup", 
      field: "adgroup_name",
      headerClass: "blue-header-bg",
      filter: "agTextColumnFilter", 
      checkboxSelection: false,
      headerCheckboxSelection: !isFirstColumn,
    },
    { 
      headerName: "Event", 
      field: "event_detail",
      headerClass: "blue-header-bg",
      filter: "agTextColumnFilter",
      checkboxSelection: false,
      headerCheckboxSelection: !isFirstColumn,
    }
  ],
  uploadOffersPromoApprovalErrorsData : [
    {
      headerName: "Adgroup", 
      field: "adgroup_name",
      headerClass: "blue-header-bg",
      filter: "agTextColumnFilter", 
      checkboxSelection: false,
      headerCheckboxSelection: !isFirstColumn,
    },
    { 
      headerName: "Event", 
      field: "event_detail",
      headerClass: "blue-header-bg",
      filter: "agTextColumnFilter",
      checkboxSelection: false,
      headerCheckboxSelection: !isFirstColumn,
    },
    { 
      headerName: "Offer Type", 
      field: "offer_type",
      headerClass: "blue-header-bg",
      filter: "agTextColumnFilter",
      checkboxSelection: false,
      headerCheckboxSelection: !isFirstColumn,
    },
    { 
      headerName: "Offer Value", 
      field: "offer_value",
      headerClass: "blue-header-bg",
      filter: "agTextColumnFilter",
      checkboxSelection: false,
      headerCheckboxSelection: !isFirstColumn,
    },
    {
      headerName: "Status",
      field: 'status',
      cellRenderer: "approvalStatusRenderer",
      tooltipField: 'validationErrors',
      pinned: 'right',
      headerClass: "blue-header-bg",
      width: 180,
      filter: "agTextColumnFilter"
    }
  ],
};
function isFirstColumn(params) {
  var displayedColumns = params.columnApi.getAllDisplayedColumns();
  var thisIsFirstColumn = displayedColumns[0] === params.column;
  return thisIsFirstColumn;
}

// function emptyValueFormatter(value) {
//   if (value.value) {
//     var toSting = Math.round(value.value);
//     if (value.colDef.headerName.indexOf("$") > -1) {
//       return "$ " + toSting.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
//     } else {
//       return toSting.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
//     }
//   } else {
//     return "-";
//   }
// }

function promoTypeFormatter(params) {
  let promoTypeSet = JSON.parse(localStorage.getItem("promo_type"));
  if (params.data.ly_event_type) {
    return promoTypeSet
      ? promoTypeSet[params.data.ly_event_type]
      : params.data.ly_event_type;
  } else if (params.data.ty_event_type) {
    return promoTypeSet
      ? promoTypeSet[params.data.ty_event_type]
      : params.data.ty_event_type;
  } else if (params.data.promo_type) {
    return promoTypeSet
      ? promoTypeSet[params.data.promo_type]
      : params.data.promo_type;
  }
  return "-";
}

let eventObj = {
  "SPC-D": "Sub Event Digital",
  "SPC-P": "Sub Event Print",
  "SPC": "Sub Event"
}

function tyEventTypeFormatter(params) {
  let labelValue =
    params.data.ty_type +
    "-" +
    params.data.ty_event_type +
    "-" + params.data.ty_start_date +
    // (moment(params.data.ty_start_date).isValid() ? moment(params.data.ty_start_date).format("MM/DD/YYYY") : params.data.ty_start_date) +
    "-" + params.data.ty_end_date
    // (moment(params.data.ty_end_date).isValid() ? moment(params.data.ty_end_date).format("MM/DD/YYYY") : params.data.ty_end_date);

  return labelValue
}

const tyuasFormatter = params => {
  if (params.data.ty_uas == "x") {
    return "Yes"
  }

  return "No"
}
