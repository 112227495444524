import React from "react";
import { connect } from "react-redux";
import DataTable from "../../../tables";
import ColumnDef from "../../../tables/colDefs/AddOffersFromLYColDef";
import _ from "lodash";
import Modal from "react-awesome-modal";
import PipelineDropdownRenderer from "./PipelineDropdownRenderer.js";
import PipelineInputRenderer from "./PipelineInputRenderer";
import NumericEditor from "./NumericEditor";
import PromoActionRenderer from "./PromoActionRenderer";
import AlertDanger from "../../../../utils/AlertMessage/alertDanger";
import AlertWarning from "../../../../utils/AlertMessage/alertWarning";
import offerTypeOptions from "../../../../utils/OfferTypes";
import PageLoader from "../../../../utils/Loader";
import {
  AtypeOfferValidator,
  PtypeOfferValidator,
  FtypeOfferValidator,
  GtypeOfferValidator,
  BtypeOfferValidator,
  CtypeOfferValidator,
  DtypeOfferValidator
} from "../../../../utils/offerValueValidators";
import moment from "moment";
import { saveAdgroupFromIntermediate, fetchRegSalesPrices, fetchRegPrices, fetchPromoBuildStatus } from "../../../../redux/actions/adgroup";
import { getOfferTitle } from "../../../../utils/offerTitle.js";
import ApprovalStatusRenderer from "./ApprovalStatusRenderer";
import { usersLogReport } from "../../../../redux/actions/userAction";
import AlertSuccess from "../../../../utils/AlertMessage/alertSuccess";
import { PromotionCalendarGA } from '../../../../utils/GA/GAUtils';
import { SIGNSHELL_OPTIONS, UAS_OPTIONS, PROMO_EVENT_TYPE_OPTIONS } from "./constants";
import { alertTimer } from "../../../../utils/alertTimer";
// import { EVENT_SIGNSHELL_CONSTANTS } from "../EventMaster/constants.js";
import {SIGNSHELL_OPTIONS_NEW} from "./constants.js";
import {getSignShellOptions} from '../../../../redux/actions/signShellAction'
import { isIt } from "../../../../utils/getRoleName.js";

let tableData = [];
export class AddOffersFromLYModal extends React.PureComponent {
  state = {
    editableAdgroups: [],
    disableSaveBtn: true,
    eventTypeOptions: [],
    offerTypeOptions: [],
    selectedAdgroupId: [],
    currentDataValues : {},
    selectedAdgroupName: [],
    adgroupValuesEmptyError: false,
    plannedOfferChanged: false,
    integerValidationAlert: false,
    addOfferForAdgroupError: false,
    cannotSimulateAlert: false,
    rowIndex: "",
    minQuantityAlert: false,
    showLoader: true,
    somethingWrongAlert: false,
    addPipelineAdGroupRow: [],
    saveBeforeSimulate: false,
    duplicateValueError: false,
    resMessage: "",
    tyEventChanged: false,
    tyRowIndex: "",
    isSuccess: false,
    successMessage: "",
    offerFixedTypeAlert: false,
    offerFixedErrorMessage: "",
    addOffersLyErrorsData : {},
    modifyOfferApprovalErrorData : {},
    isFailure: false,
    failureMessage: "" ,
    SIGNSHELL_OPTIONS_NEW2 : [] ,
    errorFromPromoApproval : false
  };

   getRowStyle = (params) => {
    let status = params.status;
    if (status == false) {
        return { background: '#ffd6eb' };
    }
}

  promoTypeFormatter = (value) => {
    let promoTypeSet = JSON.parse(localStorage.getItem("promo_type"));
    if (value) {
      // return promoTypeSet ? promoTypeSet[value] : value;
      return value;
    }
  };
  componentDidMount = async () => {
    if(this.props.editOffers){
        for(let it of this.props.pipelineIntermediateData) {
            this.state.currentDataValues[it.promo_id] = 
            (it.type) + '-' +
            (it.promo_type) + '-' +
            (it.event_start_date) + '-' +
            (it.event_end_date) +
            '@@' + '_' +
            (it.type)+'_'+
            (it.promo_sign_shell)+'_'+
            (it.promo_event_type)+'_'+
            (it.uas ? it.uas : it.ty_uas)+'_'+
            (it.promo_type)+'_'+
            (it.offer_type)+'_'+
            (it.offer_value || it.offer_value == 0 ? it.offer_value : 0)+'_'+
            (it.fixed_price_quantity || it.fixed_price_quantity==0 ? it.fixed_price_quantity : 0)+'_'+
            (it.min_quantity || it.min_quantity==0 ? it.min_quantity : 0)+'_'+
            (it.bonus_by_quantity || it.bonus_by_quantity==0 ? it.bonus_by_quantity : 0)+'_'+
            (it.ty_buy_min_value || it.ty_buy_min_value==0 ? it.ty_buy_min_value : 0)+'_'+
            (it.ty_get_max_value || it.ty_get_max_value==0 ? it.ty_get_max_value : 0)+'^^'+
            (it.name);
        }
        // console.log("this.state.currentDataValues",this.state.currentDataValues);
  }

    let tempData = await this.props.getSignShellOptions();
    const singShellArray = tempData.signShell;
    const formattedData = singShellArray.map(item => ({
      value : item.value ,
      label : item.label
    }))
    this.setState({SIGNSHELL_OPTIONS_NEW2 : formattedData}, ()=> {
      console.log(this.state.SIGNSHELL_OPTIONS_NEW2)
    })
    console.log("Temp data is above")
    let campaignID = localStorage.getItem("campaignId");

    this.setState({
      showLoader: true,
    });
    var sampleTableData = [],
      tyEventsData = [];

    if (this.props.pipelineIntermediateData) {
      sampleTableData = this.props.editOffers
        ? this.props.pipelineIntermediateData
        : this.props.pipelineIntermediateData.data;
    }

    console.log("🚀 ~ componentDidMount= ~ sampleTableData:", sampleTableData)

    const reqObj = { 
      campaign_id: campaignID
    }
    console.log("🚀 ~ componentDidMount= ~ reqObj:", reqObj)

    const promoBuildDetails = await this.props.fetchPromoBuildStatus(reqObj);

    if (this.props.tyEventsData) {
      this.props.tyEventsData && this.props.tyEventsData.data && this.props.tyEventsData.data.length && this.props.tyEventsData.data.map((tyEvents, index) => {
        let eventLabel = this.promoTypeFormatter(tyEvents.event_type);
        let labelValue =
          tyEvents.type +
          "-" +
          eventLabel +
          "-" +
          moment(tyEvents.start_date).format("MM/DD/YYYY") +
          "-" +
          moment(tyEvents.end_date).format("MM/DD/YYYY")
          + "-" + `${tyEvents.description}`;


          let val =
          tyEvents.type +
          "-" +
          eventLabel +
          "-" +
          moment(tyEvents.start_date).format("MM/DD/YYYY") +
          "-" +
          moment(tyEvents.end_date).format("MM/DD/YYYY") + 
          "-" + 
          tyEvents.promo_group_id


        tyEventsData.push({
          value: val,
          label: labelValue,
          event_type: tyEvents.event_type,
          eventId: tyEvents.event_id,
          start_date: moment(tyEvents.start_date).format("YYYY-MM-DD"),
          end_date: moment(tyEvents.end_date).format("YYYY-MM-DD"),
          eventTypeOptions: index + 1,
          type: tyEvents.type,
          promo_sign_shell: tyEvents.sign_shell,
          g_eid: tyEvents.g_eid
        });
      });
        console.log("🚀 ~ map ~ tyEventsData:", tyEventsData)

      let dataArr = sampleTableData.map((item) => {
        // console.log(item, 'iteeemmmmmm')
        let selectedEvent = "";
        if (this.props.editOffers) {
          let selectedEventObj = tyEventsData.filter(ele => ele.eventId == item.event_id);
          selectedEvent = selectedEventObj.length > 0 ? selectedEventObj[0].label : "";
        }
        item.eventTypeOptions = tyEventsData;
        item.selected_ty_event = selectedEvent;
        item.val_selected_ty_event = selectedEvent;
        item.offerTypeOptions = offerTypeOptions.newAdgroupOfferTypes;
        // item.signShellOptions = SIGNSHELL_OPTIONS;
        // item.signShellOptions = SIGNSHELL_OPTIONS_NEW;
        item.signShellOptions = this.state.SIGNSHELL_OPTIONS_NEW2;
        item.uasOptions = UAS_OPTIONS;
        item.promoEventTypeOptions = PROMO_EVENT_TYPE_OPTIONS;
        item.plannedOfferHandler = this.plannedOfferHandler;
        item.tyOfferdropdownChange = this.tyOfferdropdownChange;
        item.promo_build_status = this.props.editOffers ? item.promo_build_status : promoBuildDetails[0] ? promoBuildDetails[0].promo_build_status : 'P';
        item.getRegSalesPrices = this.getRegSalesPrices;
        item.isModify = this.props.editOffers;
        // item.promo_type = this.props.editOffers ? selectedEvent : null;
        return item;
      });
      console.log("🚀 ~ dataArr ~ dataArr:", dataArr)

      tableData = {
        header: ColumnDef.header(this.props.editOffers),
        defaultColDef: ColumnDef.defaultColDef,
        data: dataArr,
      };


      this.setState({ showLoader: false, tableData: dataArr });

      this.setState({
        eventTypeOptions: tyEventsData,
        offerTypeOptions: offerTypeOptions.newAdgroupOfferTypes,
      });
    }
  };

  getRegSalesPrices = (rowData, nodeIndex) => {
    let findError = _.find([rowData], (item) => {
      let eventType = item.ty_event_type || item.promo_type
      let offerType = item.ty_offer_type || item.offer_type
      let minQuantity = item.ty_min_qty || item.ty_min_qty == 0 ? item.ty_min_qty :item.min_quantity
      let bonusPay = item.ty_bonus_pay || item.ty_bonus_pay == 0 ? item.ty_bonus_pay : item.bonus_by_quantity
      let plannedAmount = item.ty_planned_amount || item.ty_planned_amount == 0 ? item.ty_planned_amount : item.offer_value
      let fixedPriceQuantity = item.fixed_price_quantity
      let adgroupName = item.name || item.adgroup_name

      if (_.isEmpty(eventType)) {
        this.setState({ eventTypeMandatory: true });
        setTimeout(() => {
          this.setState({ eventTypeMandatory: false });
        }, alertTimer);
        return item;
      }

      // if (
      //   ((offerType === "G") &&
      //     (minQuantity < 1 || bonusPay < 1)) ||
      //   (offerType === "B" &&
      //     (minQuantity < 1 || bonusPay < 1 || plannedAmount < 1)) ||
      //     (offerType === "C" &&
      //     (minQuantity < 1 || bonusPay < 1 || plannedAmount < 0)) ||
      //   (offerType === "D" && minQuantity < 1 && plannedAmount < 0) ||
      //   (offerType === "F" && (plannedAmount < 0  || fixedPriceQuantity < 1)) ||
      //   (offerType === "P" && plannedAmount < 1)
      // ) {
      //   //Check for minimum values in fields
      //   this.setState({ minQuantityAlert: true });
      //   setTimeout(() => {
      //     this.setState({ minQuantityAlert: false });
      //   }, 2500);
      //   return item;
      // } else if (
      //   ((
      //     offerType === "B") &&
      //     (minQuantity % 1 !== 0 || bonusPay % 1 !== 0 || plannedAmount % 1 !== 0)) ||
      //     (( offerType === "C" || offerType === "G") && (minQuantity % 1 !== 0 || bonusPay % 1 !== 0 )) ||
      //   ((offerType === "D" && (minQuantity % 1 !== 0 || plannedAmount % 1 !== 0))) ||
      //   (offerType === "F" && fixedPriceQuantity % 1 !== 0) ||
      //   (offerType === "P" && plannedAmount % 1 !== 0)
      // ) {
      //   //Check for integer or decimal values

      //   this.setState({ integerValidationAlert: true });
      //   setTimeout(() => {
      //     this.setState({ integerValidationAlert: false });
      //   }, 3000);
      //   return item;
      // } else if (
      //   (item.ty_buy_min_value !== 0 && item.ty_get_max_value === 0) ||
      //   (item.ty_buy_min_value === 0 && item.ty_get_max_value !== 0)
      // ) {
      //   //If buy percent is entered
      //   this.setState({
      //     adgroupValuesEmptyError: true,
      //   });
      //   setTimeout(() => {
      //     this.setState({
      //       adgroupValuesEmptyError: false,
      //     });
      //   }, 2500);
      //   return item;
      // } else {
      //   let fieldName = this.props.editOffers
      //     ? offerTypeOptions.fieldValidationEditOffer[item.offer_type]
      //     : offerTypeOptions.fieldValidation[item.ty_offer_type];

      //   if (fieldName) {
      //     let findEmptyFields = _.find(fieldName, (field) => {
      //       if(offerType === "A" || offerType === "C" || offerType === "F") {
      //         return (
      //           item[field] === null || item[field] === "" || item[field] < 0
      //         );
      //       }
      //       return (
      //         item[field] === null || item[field] === "" || item[field] <= 0
      //       );
      //     });
      //     if (!_.isEmpty(findEmptyFields)) {
      //       this.setState({
      //         adgroupValuesEmptyError: true,
      //       });
      //       setTimeout(() => {
      //         this.setState({
      //           adgroupValuesEmptyError: false,
      //         });
      //       }, 2500);
      //       return item;
      //     }
      //   } else {
      //     //Check if values is entered or not
      //     this.setState({
      //       addOfferForAdgroupError: true,
      //     });
      //     setTimeout(() => {
      //       this.setState({
      //         addOfferForAdgroupError: false,
      //       });
      //     }, 2500);
      //     return item;
      //   }
      // }

      if(offerType === "A") {
        const values = {
          plannedAmount: plannedAmount,
          adgroupName: adgroupName
        }
  
        const promoRegPrices = {
          regLow : null,
          regHigh: null
        }
  
        const offerValueValidationRes = AtypeOfferValidator(values, promoRegPrices, true);
  
        if(offerValueValidationRes.isInvalid){
          this.setState({
            isFailure: offerValueValidationRes.isInvalid,
            failureMessage: offerValueValidationRes.invalidationMsg
          });
  
          setTimeout(() => {
            this.setState({ isFailure: false, failureMessage: "" });
          }, alertTimer);
          return;
        }
      }
  
      if(offerType === "P") {
        const values = {
          plannedAmount: plannedAmount,
          adgroupName: adgroupName
        }
  
        const promoRegPrices = {
          regLow : null,
          regHigh: null
        }
  
        const offerValueValidationRes = PtypeOfferValidator(values, promoRegPrices, true);
  
        if(offerValueValidationRes.isInvalid){
          this.setState({
            isFailure: offerValueValidationRes.isInvalid,
            failureMessage: offerValueValidationRes.invalidationMsg
          });
  
          setTimeout(() => {
            this.setState({ isFailure: false, failureMessage: "" });
          }, alertTimer);
          return;
        }
      }
  
      if(offerType === "F") {
        const values = {
          plannedAmount: plannedAmount,
          fixedPriceQty: fixedPriceQuantity,
          adgroupName: adgroupName
        }
  
        const promoRegPrices = {
          regLow : null,
          regHigh: null
        }
  
        const offerValueValidationRes = FtypeOfferValidator(values, promoRegPrices, true);
  
        if(offerValueValidationRes.isInvalid){
          this.setState({
            isFailure: offerValueValidationRes.isInvalid,
            failureMessage: offerValueValidationRes.invalidationMsg
          });
  
          setTimeout(() => {
            this.setState({ isFailure: false, failureMessage: "" });
          }, alertTimer);
          return;
        }
      }
  
      if(offerType === "G") {
        const values = {
          minQuantity: minQuantity, 
          bonusBuy: bonusPay, 
          buyMinVal: item.ty_buy_min_value, 
          getMaxVal: item.ty_get_max_value,
          adgroupName: adgroupName
        }
  
        const promoRegPrices = {
          regLow : null,
          regHigh: null
        }
  
        const offerValueValidationRes = GtypeOfferValidator(values, promoRegPrices, true);
  
        if(offerValueValidationRes.isInvalid){
          this.setState({
            isFailure: offerValueValidationRes.isInvalid,
            failureMessage: offerValueValidationRes.invalidationMsg
          });
  
          setTimeout(() => {
            this.setState({ isFailure: false, failureMessage: "" });
          }, alertTimer);
          return;
        }
      }
  
      if(offerType === "B") {
        const values = {
          minQuantity: minQuantity, 
          bonusBuy: bonusPay, 
          plannedAmount: plannedAmount,
          adgroupName: adgroupName
        }
  
        const promoRegPrices = {
          regLow : null,
          regHigh: null
        }
  
        const offerValueValidationRes = BtypeOfferValidator(values, promoRegPrices, true);
  
        if(offerValueValidationRes.isInvalid){
          this.setState({
            isFailure: offerValueValidationRes.isInvalid,
            failureMessage: offerValueValidationRes.invalidationMsg
          });
  
          setTimeout(() => {
            this.setState({ isFailure: false, failureMessage: "" });
          }, alertTimer);
          return;
        }
      }
  
      if(offerType === "C") {
        const values = {
          plannedAmount: plannedAmount,
          minQuantity: minQuantity, 
          bonusBuy: bonusPay, 
          buyMinVal: item.ty_buy_min_value, 
          getMaxVal: item.ty_get_max_value,
          adgroupName: adgroupName
        }
        const promoRegPrices = {
          regLow : null,
          regHigh: null
        }
        const offerValueValidationRes = CtypeOfferValidator(values, promoRegPrices, true);
  
        if(offerValueValidationRes.isInvalid){
          this.setState({
            isFailure: offerValueValidationRes.isInvalid,
            failureMessage: offerValueValidationRes.invalidationMsg
          });
  
          setTimeout(() => {
            this.setState({ isFailure: false, failureMessage: "" });
          }, alertTimer);
          return;
        }
      }
  
      if(offerType === "D") {
        const values = {
          minQuantity: minQuantity, 
          plannedAmount: plannedAmount,
          adgroupName: adgroupName
        }
        const promoRegPrices = {
          regLow : null,
          regHigh: null
        }
        const offerValueValidationRes = DtypeOfferValidator(values, promoRegPrices, true);
  
        if(offerValueValidationRes.isInvalid){
          this.setState({
            isFailure: offerValueValidationRes.isInvalid,
            failureMessage: offerValueValidationRes.invalidationMsg
          });
  
          setTimeout(() => {
            this.setState({ isFailure: false, failureMessage: "" });
          }, alertTimer);
          return;
        }
      }
    });

    console.log("*************findError", findError)
    if (_.isEmpty(findError)) {
      // if (data.ty_offer_type === "F" && data.fixed_price_quantity > 1) {
      //   data.ty_offer_type = "nForM";
      // }

      let offerType = rowData.ty_offer_type || rowData.offer_type;
      let minQuantity = rowData.ty_min_qty || rowData.min_quantity
      let bonusPay = rowData.ty_bonus_pay || rowData.bonus_by_quantity
      let plannedAmount = rowData.ty_planned_amount || rowData.offer_value
      let fixedPriceQuantity = rowData.fixed_price_quantity

      const reqObj = {
        eventId: rowData.eventId || rowData.event_id,
        adgroup_id: rowData.adgroup_id,
        offerType: offerType,
        minQuantity: minQuantity,
        bonusPay: bonusPay,
        plannedAmount: plannedAmount,
        fixedPriceQuantity: fixedPriceQuantity
      }

      this.setState({ showLoader: true });

      this.props.fetchRegSalesPrices(reqObj).then(res => {
        console.log(res);
        const result = res.data;
        let tempData = tableData.data;
        tempData.forEach(item => {
          if (item.promo_id) {
            if (item.promo_id == rowData.promo_id) {
              item.regLow = result[0].regLow;
              item.regHigh = result[0].regHigh
              item.saleLow = result[0].saleLow
              item.saleHigh = result[0].saleHigh
            }
          } else {
            if (item.adgroup_id == rowData.adgroup_id && item.eventId == rowData.eventId) {
              item.regLow = result[0].regLow;
              item.regHigh = result[0].regHigh
              item.saleLow = result[0].saleLow
              item.saleHigh = result[0].saleHigh
            }
          }
        });
        tableData.data = tempData;
        this.tyOfferdropdownChange(nodeIndex);
        this.setState({ showLoader: false });
      })
    }
  }

  onSelectionChanged = (rowValue) => {
    let addPipelineAdGroupRow = [];

    var selectedAdgroupId = [],
      selectedAdgroupName = [];

    selectedAdgroupId = _.map(rowValue, (item) => {
      selectedAdgroupName.push({
        adgroup_id: item.adgroup_id,
        adgroup_name: item.adgroup_name,
        ly_start_date: item.ly_start_date,
        ly_end_date: item.ly_end_date,
        ly_event_type: item.ly_event_type,
        ly_offer: item.ly_offer,
      });
      return item.adgroup_id;
    });

    _.map(rowValue, (row) => {
      row.isRowSaved = false;
      row.start_date = moment(row.start_date).format("YYYY-MM-DD")
      row.end_date = moment(row.end_date).format("YYYY-MM-DD")
      if(!this.props.editOffers)row.addNewOffer = true;
      if(this.props.editOffers)row.isModify = true;
      row.user_role =  localStorage.getItem("role");
      addPipelineAdGroupRow.push(row);
    });

    if (addPipelineAdGroupRow.length) {
      this.setState({
        addPipelineAdGroupRow,
        selectedAdgroupId,
        selectedAdgroupName,
        disableSaveBtn: false,
      });
    } else {
      this.setState({
        disableSaveBtn: true,
      });
    }
  };
  editableRowsHandler = (cellValue) => {
    console.log("🚀 ~ cellValue:", cellValue)
    let { editableAdgroups } = this.state;
    editableAdgroups.push(cellValue);
    this.setState({ editableAdgroups });
  };

  isnumberInRange = (value, minValue, maxValue) => {
    if(value >= minValue && value <= maxValue )
      return true;

    return false;
  }

  addAdgroupToPipeline = async () => {
    let { addPipelineAdGroupRow } = this.state;
    let context = this

    this.setState({
      addOffersLyErrorsData: _.cloneDeep({}),
      modifyOfferApprovalErrorData: _.cloneDeep({}),
      showLoader: true,
      errorFromPromoApproval : false
    })
    // GA
    if (context.props.editOffers) {
      this.submitGA("Click", "Clicked_EditOffer_SaveButton");
    } else {
      const totalSelectedAdgroups = _.size(addPipelineAdGroupRow);
      this.submitGA("Click", "Clicked_AddOffersFromLY_SaveButton", totalSelectedAdgroups);
    }
    let foundRes = _.filter(addPipelineAdGroupRow, function (o) {
      let flagEditOffers = context.props.editOffers ? o.end_date : o.ty_end_date

      return !(moment((flagEditOffers)).isSameOrAfter(moment(), 'day'))
    });

    let responseMessage = [];

    if (foundRes && foundRes.length) {
      addPipelineAdGroupRow.forEach((responseData) => {
        let flagEditOffers = context.props.editOffers ? responseData.end_date : responseData.ty_end_date
        if (!(moment(flagEditOffers).isSameOrAfter(moment(), 'day'))) {
          responseMessage.push((responseData.adgroup_name || responseData.name) + " whose Event Type " + (flagEditOffers));
        }
      });

      if (responseMessage && responseMessage.length) {

        this.setState({

          notFutureEvent: true,
          resFutureMessage: responseMessage,
        });

        setTimeout(() => {
          this.setState({
            notFutureEvent: false,
          });
        }, alertTimer);
      }
    }

    const regReqObj = addPipelineAdGroupRow.map(row => {
      return ({ 
        adgroup_id: row.adgroup_id,
        eventId: row.eventId || row.event_id})
    });

    const regPricesRes = await this.props.fetchRegPrices(regReqObj);

    const regpricesObj = {};

    regPricesRes.forEach(ele => {
      let eventId = ele.eventId || ele.event_id;
      const promoKey = `${eventId}_${ele.adgroup_id}`;
      regpricesObj[promoKey] = ele;
    })

    this.setState({
      showLoader: false
    })

    // let findError = _.find(addPipelineAdGroupRow, (item) => {
      let findError = addPipelineAdGroupRow.map((item) => {
      let eventType = item.ty_event_type || item.promo_type
      let offerType = item.ty_offer_type || item.offer_type
      let minQuantity = item.ty_min_qty || item.ty_min_qty == 0 ? item.ty_min_qty : item.min_quantity
      let bonusPay = item.ty_bonus_pay || item.ty_bonus_pay == 0 ? item.ty_bonus_pay : item.bonus_by_quantity
      let plannedAmount = item.ty_planned_amount || item.ty_planned_amount == 0 ? item.ty_planned_amount : item.offer_value
      let fixedPriceQuantity = item.fixed_price_quantity;
      let buyMinVal = item.ty_buy_min_value;
      let getMaxVal = item.ty_get_max_value;
      let eventId = item.eventId || item.event_id;
      let adgroupName = item.adgroup_name;

      const promoKey = `${eventId}_${item.adgroup_id}`
      const promoRegPrices = regpricesObj[promoKey];

      if (_.isEmpty(eventType)) {
        this.setState({ eventTypeMandatory: true });
        setTimeout(() => {
          this.setState({ eventTypeMandatory: false });
        }, alertTimer);
        return item;
      }

      // if((offerType == 'F' || offerType == 'P') && (!plannedAmount || plannedAmount<1)) {
      //   this.setState({ offerFixedTypeAlert: true, offerFixedErrorMessage: 'Fixed price offer planned offer amount should be greater than 0' });
      //   setTimeout(() => {
      //     this.setState({ offerFixedTypeAlert: false, offerFixedErrorMessage: '' });
      //   }, 2500);
      //   return item;
      // }

      // if(offerType == 'F' && (!fixedPriceQuantity || fixedPriceQuantity<2)) {
      //   this.setState({ offerFixedTypeAlert: true, offerFixedErrorMessage: 'Fixed price offer fixed price quantity should be greater than 1' });
      //   setTimeout(() => {
      //     this.setState({ offerFixedTypeAlert: false, offerFixedErrorMessage: '' });
      //   }, 2500);
      //   return item;
      // }

      // if (
      //   ((offerType === "G") &&
      //     (minQuantity < 1 || bonusPay < 1)) ||
      //   (offerType === "B" &&
      //     (minQuantity < 1 || bonusPay < 1 || plannedAmount < 1)) ||
      //     (offerType === "C" &&
      //     (minQuantity < 1 || bonusPay < 1 || plannedAmount < 0)) ||
      //   (offerType === "D" && minQuantity < 1 && plannedAmount < 0) ||
      //   (offerType === "F" && (plannedAmount < 0 || fixedPriceQuantity < 1)) ||
      //   (offerType === "P" && plannedAmount < 1)
      // ) {
      //   //Check for minimum values in fields
      //   this.setState({ minQuantityAlert: true });
      //   setTimeout(() => {
      //     this.setState({ minQuantityAlert: false });
      //   }, 2500);
      //   return item;
      // } else if (
      //   ((
      //     offerType === "B") &&
      //     (minQuantity % 1 !== 0 || bonusPay % 1 !== 0 || plannedAmount % 1 !== 0)) ||
      //     (( offerType === "C" || offerType === "G" )&& (minQuantity % 1 !== 0 || bonusPay % 1 !== 0 )) ||
      //   ((offerType === "D" && (minQuantity % 1 !== 0 || plannedAmount % 1 !== 0))) ||
      //   (offerType === "F" && fixedPriceQuantity % 1 !== 0) ||
      //   (offerType === "P" && plannedAmount % 1 !== 0)
      // ) {
      //   //Check for integer or decimal values

      //   this.setState({ integerValidationAlert: true });
      //   setTimeout(() => {
      //     this.setState({ integerValidationAlert: false });
      //   }, 3000);
      //   return item;
      // } else if(offerType === "A" && parseFloat(plannedAmount) > parseFloat(promoRegPrices.regLow)) {
      //   this.setState({
      //     isFailure: true,
      //     failureMessage: `Planned offer amount should be less than  ${promoRegPrices.regLow}`
      //   });
      //   setTimeout(() => {
      //     this.setState({
      //       isFailure: false,
      //       failureMessage: ""
      //     });
      //   }, 2500);
      //   return item;
      // } else if(offerType === "G" && 
      // !(this.isnumberInRange(parseFloat(buyMinVal), parseFloat(promoRegPrices.regLow), parseFloat(promoRegPrices.regHigh)) && this.isnumberInRange(parseFloat(getMaxVal), parseFloat(promoRegPrices.regLow), parseFloat(promoRegPrices.regHigh)) )) {
      //   this.setState({
      //     isFailure: true,
      //     failureMessage: `Please enter Min and max value between ${promoRegPrices.regLow} - ${promoRegPrices.regHigh}`
      //   });
      //   setTimeout(() => {
      //     this.setState({
      //       isFailure: false,
      //       failureMessage: ""
      //     });
      //   }, 2500);
      //   return item;
      // }else if(offerType === "C" && 
      // !(this.isnumberInRange(parseFloat(buyMinVal), parseFloat(promoRegPrices.regLow), parseFloat(promoRegPrices.regHigh)) && this.isnumberInRange(parseFloat(getMaxVal), parseFloat(promoRegPrices.regLow), parseFloat(promoRegPrices.regHigh))&& plannedAmount < ((minQuantity+bonusPay) * promoRegPrices.regLow)) ) {
      //   this.setState({
      //     isFailure: true,
      //     failureMessage: `Please enter Min and max value between ${promoRegPrices.regLow} - ${promoRegPrices.regHigh} and Planned offer amount should be less than  ${(minQuantity+bonusPay) * promoRegPrices.regLow}`
      //   });
      //   setTimeout(() => {
      //     this.setState({
      //       isFailure: false,
      //       failureMessage: ""
      //     });
      //   }, 2500);
      //   return item;
      // } else if (
      //   (item.ty_buy_min_value !== 0 && item.ty_get_max_value === 0) ||
      //   (item.ty_buy_min_value === 0 && item.ty_get_max_value !== 0)
      // ) {
      //   //If buy percent is entered
      //   this.setState({
      //     adgroupValuesEmptyError: true,
      //   });
      //   setTimeout(() => {
      //     this.setState({
      //       adgroupValuesEmptyError: false,
      //     });
      //   }, 2500);
      //   return item;
      // } else {
      //   let fieldName = this.props.editOffers
      //     ? offerTypeOptions.fieldValidationEditOffer[item.offer_type]
      //     : offerTypeOptions.fieldValidation[item.ty_offer_type];

      //   if (fieldName) {
      //     let findEmptyFields = _.find(fieldName, (field) => {
      //       if(offerType === "A" || offerType === "C" || offerType === "F") {
      //         return (
      //           item[field] === null || item[field] === "" || item[field] < 0
      //         );
      //       }
      //       return (
      //         item[field] === null || item[field] === "" || item[field] <= 0
      //       );
      //     });
      //     if (!_.isEmpty(findEmptyFields)) {
      //       this.setState({
      //         adgroupValuesEmptyError: true,
      //       });
      //       setTimeout(() => {
      //         this.setState({
      //           adgroupValuesEmptyError: false,
      //         });
      //       }, 2500);
      //       return item;
      //     }
      //   } else {
      //     //Check if values is entered or not
      //     this.setState({
      //       addOfferForAdgroupError: true,
      //     });
      //     setTimeout(() => {
      //       this.setState({
      //         addOfferForAdgroupError: false,
      //       });
      //     }, 2500);
      //     return item;
      //   }
      // }

      if(offerType === "A") {
        const values = {
          plannedAmount: plannedAmount,
          adgroupName: item.name || item.adgroup_name
        }
        const offerValueValidationRes = AtypeOfferValidator(values, promoRegPrices);
  
        if(offerValueValidationRes.isInvalid){
          this.setState({
            isFailure: offerValueValidationRes.isInvalid,
            failureMessage: offerValueValidationRes.invalidationMsg
          });
  
          setTimeout(() => {
            this.setState({ isFailure: false, failureMessage: "" });
          }, alertTimer);
          return item;
        }
      }
  
      if(offerType === "P") {
        const values = {
          plannedAmount: plannedAmount,
          adgroupName: item.name || item.adgroup_name
        }
        const offerValueValidationRes = PtypeOfferValidator(values, promoRegPrices);
  
        if(offerValueValidationRes.isInvalid){
        this.setState({ isFailure: offerValueValidationRes.isInvalid, failureMessage: offerValueValidationRes.invalidationMsg });
        setTimeout(() => {
          this.setState({ isFailure: false, failureMessage: "" });
        }, alertTimer);
        return item;
      }
    }
  
      if(offerType === "F") {
        const values = {
          plannedAmount: plannedAmount,
          fixedPriceQty: fixedPriceQuantity,
          adgroupName: item.name || item.adgroup_name
        }
        const offerValueValidationRes = FtypeOfferValidator(values, promoRegPrices);
  
        if(offerValueValidationRes.isInvalid){
          this.setState({
            isFailure: offerValueValidationRes.isInvalid,
            failureMessage: offerValueValidationRes.invalidationMsg
          });
  
          setTimeout(() => {
            this.setState({ isFailure: false, failureMessage: "" });
          }, alertTimer);
          return item;
        }
      }
  
      if(offerType === "G") {
        const values = {
          minQuantity: minQuantity, 
          bonusBuy: bonusPay, 
          buyMinVal: buyMinVal, 
          getMaxVal: getMaxVal,
          adgroupName: item.name || item.adgroup_name
        }
        const offerValueValidationRes = GtypeOfferValidator(values, promoRegPrices);
  
        if(offerValueValidationRes.isInvalid){
          this.setState({
            isFailure: offerValueValidationRes.isInvalid,
            failureMessage: offerValueValidationRes.invalidationMsg
          });
  
          setTimeout(() => {
            this.setState({ isFailure: false, failureMessage: "" });
          }, alertTimer);
          return item;
        }
      }
  
      if(offerType === "B") {
        const values = {
          minQuantity: minQuantity, 
          bonusBuy: bonusPay, 
          plannedAmount: plannedAmount,
          adgroupName: item.name || item.adgroup_name
        }
        const offerValueValidationRes = BtypeOfferValidator(values, promoRegPrices);
  
        if(offerValueValidationRes.isInvalid){
          this.setState({
            isFailure: offerValueValidationRes.isInvalid,
            failureMessage: offerValueValidationRes.invalidationMsg
          });
  
          setTimeout(() => {
            this.setState({ isFailure: false, failureMessage: "" });
          }, alertTimer);
          return item;
        }
      }
  
      if(offerType === "C") {
        const values = {
          plannedAmount: plannedAmount,
          minQuantity: minQuantity, 
          bonusBuy: bonusPay, 
          buyMinVal: buyMinVal, 
          getMaxVal: getMaxVal,
          adgroupName: item.name || item.adgroup_name
        }
        const offerValueValidationRes = CtypeOfferValidator(values, promoRegPrices);
  
        if(offerValueValidationRes.isInvalid){
          this.setState({
            isFailure: offerValueValidationRes.isInvalid,
            failureMessage: offerValueValidationRes.invalidationMsg
          });
  
          setTimeout(() => {
            this.setState({ isFailure: false, failureMessage: "" });
          }, alertTimer);
          return item;
        }
      }
  
      if(offerType === "D") {
        const values = {
          minQuantity: minQuantity, 
          plannedAmount: plannedAmount,
          adgroupName: item.name || item.adgroup_name
        }
        const offerValueValidationRes = DtypeOfferValidator(values, promoRegPrices);
  
        if(offerValueValidationRes.isInvalid){
          this.setState({
            isFailure: offerValueValidationRes.isInvalid,
            failureMessage: offerValueValidationRes.invalidationMsg
          });
  
          setTimeout(() => {
            this.setState({ isFailure: false, failureMessage: "" });
          }, alertTimer);
          return item;
        }
      }
    });

    const filteredfindError = findError.filter(element => element !== undefined);

    if (_.isEmpty(filteredfindError) && !foundRes.length) {
      let cumulativeOffersArray = [];
      let cumulativeOffersString = ''

      this.setState({
        showLoader: true,
      });
      addPipelineAdGroupRow.forEach((item) => {
        if (this.props.editOffers) {
          if (item.offer_type === "F" && item.fixed_price_quantity > 1) {
            item.offer_type = "nForM";
          }

          cumulativeOffersString =
            item.adgroup_id +
            item.promo_type +
            item.start_date +
            item.end_date +
            item.offer_type +
            item.offer_value +
            item.fixed_price_quantity +
            item.min_quantity +
            item.bonus_by_quantity +
            item.ty_buy_min_value +
            item.ty_get_max_value +
            item.type
        } else {
          if (item.ty_offer_type === "F" && item.fixed_price_quantity > 1) {
            item.ty_offer_type = "nForM";
          }

          cumulativeOffersString =
            item.adgroup_id +
            item.ty_event_type +
            item.ty_start_date +
            item.ty_end_date +
            item.ty_offer_type +
            item.ty_planned_amount +
            item.fixed_price_quantity +
            item.ty_min_qty +
            item.ty_bonus_pay +
            item.ty_buy_min_value +
            item.ty_get_max_value +
            item.type;
        }
        item.eventId = item.eventId || item.event_id
        cumulativeOffersArray.push(cumulativeOffersString);
      });

      let duplicates =
        _.uniq(cumulativeOffersArray).length !== cumulativeOffersArray.length;

      if (duplicates) {
        this.setState({
          showLoader: false,
          duplicateValueError: true,
        });

        setTimeout(() => {
          this.setState({
            duplicateValueError: false,
          });
        }, alertTimer);
      } else {
        //save adgroup findError is empty i.e, when all validations are success
        // return
        this.props.usersLogReport({
          screen: "add_new_offer_ly",
          action: "save",
          userId: localStorage.getItem("email"),
        });

        let futureDataValues = {},tempFutureData = {};
        let errorTableData ;

    if(this.props.editOffers){

      for(let it of addPipelineAdGroupRow) {
        let titleObject = {
            offerType: it.offer_type,
            offerValue: it.offer_value>0  ? it.offer_value : 0,
            offer: it.offer_value>0  ? it.offer_value : 0,
            bonusbyquantity: !it.bonus_by_quantity ? null : it.bonus_by_quantity,
            minquantity: !it.min_quantity ? null : it.min_quantity,
            fixedpricequantity: !it.fixed_price_quantity ? null : it.fixed_price_quantity,
          };
        let offer_title = getOfferTitle(titleObject.offerType, titleObject.offerValue, titleObject.fixedpricequantity, titleObject.minquantity, titleObject.bonusbyquantity, titleObject.offer);
        it["offer_title"] = offer_title;
    }

        for(let it of this.state.addPipelineAdGroupRow) {
          futureDataValues[it.promo_id] = 
          (it.type) + '-' +
          (it.promo_type) + '-' +
          (it.event_start_date) + '-' +
          (it.event_end_date) +
          '@@' + '_' +
          (it.type)+'_'+
          (it.promo_sign_shell)+'_'+
          (it.promo_event_type)+'_'+
          (it.uas ? it.uas : it.ty_uas)+'_'+
          (it.promo_type)+'_'+
          (it.offer_type)+'_'+
          (it.offer_value || it.offer_value == 0 ? it.offer_value : 0)+'_'+
          (it.fixed_price_quantity || it.fixed_price_quantity==0 ? it.fixed_price_quantity : 0)+'_'+
          (it.min_quantity || it.min_quantity==0 ? it.min_quantity : 0)+'_'+
          (it.bonus_by_quantity || it.bonus_by_quantity==0 ? it.bonus_by_quantity : 0)+'_'+
          (it.ty_buy_min_value || it.ty_buy_min_value==0 ? it.ty_buy_min_value : 0)+'_'+
          (it.ty_get_max_value || it.ty_get_max_value==0 ? it.ty_get_max_value : 0)+'^^'+
          (it.name);

          tempFutureData[it.promo_id] = 
          (it.offer_title) + 'offer_type' +
          (it.offer_type);
      }
    }

      let notModifiedAdgroups=[], filteredPayloadData = [];

      if(this.props.editOffers){
        for(let key in futureDataValues){
        if(this.state.currentDataValues[key] == futureDataValues[key]) {
          let temp = this.state.currentDataValues[key];
          temp = temp.split("").reverse().join("");
          temp = temp.split("^^");
          let ty_event_data = this.state.currentDataValues[key];
          ty_event_data = ty_event_data.split("@@");
          let error_arr = [];
          error_arr.push(temp[0].split("").reverse().join(""));
          error_arr.push(ty_event_data[0]);
          let arr = (tempFutureData[key].split('offer_type'));
          error_arr.push(arr[0]);
          error_arr.push(arr[1]);
          notModifiedAdgroups.push(error_arr);
        }
        else {
          let matchingElements = this.state.addPipelineAdGroupRow.filter(element => key == element.promo_id);
          filteredPayloadData = filteredPayloadData.concat(matchingElements);
        }
      }
    }

      if(this.props.editOffers && localStorage.getItem("role")==2){
        addPipelineAdGroupRow = filteredPayloadData;
      }

      // console.log("notModifiedAdgroupsTogether",notModifiedAdgroups);
  if(notModifiedAdgroups.length > 0 && notModifiedAdgroups.length == Object.keys(futureDataValues).length && this.props.editOffers && localStorage.getItem("role")==2) {

    errorTableData = notModifiedAdgroups.map(item => ({
      adgroup_name: item[0],
      event_detail: item[1],
      status : false,
      offer_type : item[3],
      offer_value : item[2],
      validationErrors : "User is not allowed to modify the offer with same details"
  
}));

    let tableData = {
      header: ColumnDef.addOfferFromLYPromoApprovalErrorsData,
      defaultColDef: ColumnDef.defaultColDef,
      data : errorTableData
    }
    this.setState({ modifyOfferApprovalErrorData: _.cloneDeep(tableData) });

    // setTimeout(()=>{
    //   if (this.props.editOffers) {
    //     this.props.closeAdgroupModal(true);
    //   }
    //   this.onSelectionChanged([])
    //  },alertTimer)

     this.setState({
        showLoader: false,
    });
      return ;
    }
    else if (notModifiedAdgroups.length > 0 && this.props.editOffers && localStorage.getItem("role")==2){

       errorTableData = notModifiedAdgroups.map(item => ({
        adgroup_name: item[0],
        event_detail: item[1],
        status : false,
        offer_type : item[3],
        offer_value : item[2],
        validationErrors : "User is not allowed to modify the offer with same details"
    
  }));

      let tableData = {
        header: ColumnDef.addOfferFromLYPromoApprovalErrorsData,
        defaultColDef: ColumnDef.defaultColDef,
        data : errorTableData
      }
      this.setState({ modifyOfferApprovalErrorData: _.cloneDeep(tableData) });

    //  this.setState({
    //   showLoader: false,
    // });
    }
        let res = await this.props.saveAdgroupFromIntermediate({
          multiplePromos: addPipelineAdGroupRow,
          campaignId: parseInt(localStorage.getItem("campaignIdsForUrl")),
        });
        // if (res.status === false) {
        //   this.setState({ showLoader: false, somethingWrongAlert: true, resMessage: "Something went wrong!" });
        //   setTimeout(() => {
        //     this.setState({
        //       somethingWrongAlert: false
        //     });
        //   }, 4500);
        //   return;
        // }

        let foundPromoApprovalRes = _.filter(res, { inPromoApproval: true });

        let foundRes = _.filter(res, { status: false });
        console.log("🚀 ~ //findError ~ foundRes:", foundRes)
        let foundSuccessRes = _.filter(res, { status: true });


        if(foundPromoApprovalRes && foundPromoApprovalRes.length > 0) {
          this.setState({
            isSuccess: true,
            successMessage: "Promo offer/s has been submitted for approval",
            showLoader: false,
          });

          setTimeout(() => {
            this.setState({
              isSuccess: false,
              successMessage: ''
            });
            
            if (this.props.editOffers) {
              this.props.closeAdgroupModal(true);
            }
            this.onSelectionChanged([])
          }, alertTimer);
        } 
        // else
        if( (foundRes && foundRes.length) || (foundSuccessRes && foundSuccessRes.length) ){
        setTimeout(() => {
          this.props.updatePipelineData([], "intermediate", this.props.editOffers ? "editOffers" : "");
        }, alertTimer);

        if(foundSuccessRes.length > 0) {
          this.setState({
            isSuccess: true,
            successMessage: `Offers ${this.props.editOffers ? 'modified' : 'created'} Successfully`,
            showLoader: false,
          });

          setTimeout(() => {
            this.setState({
              isSuccess: false,
              successMessage: ''
            });
          }, alertTimer);
        }
        let responseMessage = [];

        if (foundRes && foundRes.length>0) {
          console.log("error");
          // if(foundRes[0].msg == "Duplicate Approval Record Found"){
            this.setState({errorFromPromoApproval : true})
            let errorTableData2 = foundRes.map(error => {
            return {
              adgroup_name: error.data.adgroupName,
              event_detail: error.data.ty_event,
              offer_type : error.data.offer_type,
              offer_value : error.data.offer_value,
              validationErrors : error.data.validationErrors,
              status : error.status,
            };
        });
        if(errorTableData && errorTableData.length>0){
          errorTableData = errorTableData.concat(errorTableData2);
        }
        else{
          errorTableData = errorTableData2
        }
        let tableData = {
          header: ColumnDef.addOfferFromLYPromoApprovalErrorsData,
          defaultColDef: ColumnDef.defaultColDef,
          data : errorTableData
        }
        this.setState({modifyOfferApprovalErrorData: _.cloneDeep(tableData) });
       console.log("tableData",tableData);

}

        if (res[0].status) {
          addPipelineAdGroupRow.map((item) => {
            item.isRowSaved = true;
            return item;
          });
          this.setState({
            showLoader: false,
            adgroupAddedAlert: true,
            // addPipelineAdGroupRow: [],
            disableSaveBtn : true ,
          });
          setTimeout(() => {
            this.setState({
              adgroupAddedAlert: false,
            });
            this.onSelectionChanged([])
            if (this.props.editOffers) {
              this.props.closeAdgroupModal(true)
            }
          }, alertTimer);
        }
        this.setState({
          showLoader: false,
        });
      }
      }
    }
  };

  simulateHandler = () => {
    let { addPipelineAdGroupRow } = this.state;

    let foundRes = _.filter(addPipelineAdGroupRow, function (o) { return !(moment(o.ty_start_date).isAfter(moment())) });

    let responseMessage = [];

    if (foundRes && foundRes.length) {
      addPipelineAdGroupRow.forEach((responseData) => {
        if (!(moment(responseData.ty_start_date).isAfter(moment()))) {
          responseMessage.push(responseData.adgroup_name + " whose Event Type " + responseData.ty_start_date);
        }
      });

      if (responseMessage && responseMessage.length) {

        this.setState({

          notFutureEvent: true,
          resFutureMessage: responseMessage,
        });

        setTimeout(() => {
          this.setState({
            notFutureEvent: false,
          });
        }, alertTimer);
      }
    }
    let findError = _.find(addPipelineAdGroupRow, (item) => {
      let fieldName = offerTypeOptions.fieldValidation[item.ty_offer_type];
      if (fieldName) {
        let findEmptyFields = _.every(fieldName, (field) => {
          return item[field] > 0 && !item.isRowSaved;
        });

        if (findEmptyFields && !foundRes.length) {
          this.setState({
            saveBeforeSimulate: true,
          });
          // setTimeout(() => {
          //   this.setState({
          //     saveBeforeSimulate: false
          //   });
          // }, 2500);
          return item;
        }
      }
    });
    if (_.isEmpty(findError) && !foundRes.length) {
      this.goToSimulateHandler();
    }
  };
  goToSimulateHandler = () => {
    if (this.state.eventTypeOptions.length) {
      localStorage.setItem("promoIdsForUrl", null);
      localStorage.setItem("adgroupIdsForUrl", this.state.selectedAdgroupId);
      localStorage.setItem(
        "adgroupNamesForUrl",
        JSON.stringify(this.state.selectedAdgroupName)
      );
      this.props.history.push(`/createpromotion?pipelineInt=true`);
    } else {
      this.setState({
        cannotSimulateAlert: true,
      });

      setTimeout(() => {
        this.setState({
          cannotSimulateAlert: false,
        });
      }, alertTimer);
    }
  };
  plannedOfferHandler = (index) => {
    this.setState({
      plannedOfferChanged: !this.state.plannedOfferChanged,
      rowIndex: index,
    });
  };

  tyOfferdropdownChange = (index) => {
    this.setState({
      tyEventChanged: !this.state.tyEventChanged,
      tyRowIndex: index,
    });
  };
  submitGA(action, label, value) {
    PromotionCalendarGA({ action, label, value });
  }
  render() {
    return (
      <>
        <Modal
          visible={true}
          width="90%"
          height="86%"
          effect="fadeInDown"
          onClickAway={() => this.props.closeAdgroupModal()}
        >
          <PageLoader loader={this.state.showLoader}>
            <div className="alertDivs">
              {this.state.adgroupValuesEmptyError ? (
                <AlertDanger
                  message="Please enter all the fields for selected Adgroup"
                  show={this.state.adgroupValuesEmptyError}
                />
              ) : null}
              {this.state.addOfferForAdgroupError ? (
                <AlertDanger
                  message="Please select offer for selected Adgroup"
                  show={this.state.addOfferForAdgroupError}
                />
              ) : null}
              {this.state.minQuantityAlert ? (
                <AlertDanger
                  message="Please enter minimum value"
                  show={this.state.minQuantityAlert}
                />
              ) : null}
              {this.state.eventTypeMandatory ? (
                <AlertDanger
                  message="Please select event type"
                  show={this.state.eventTypeMandatory}
                />
              ) : null}
              {this.state.notFutureEvent ? (
                <AlertDanger
                  message={`The Adroup ${this.state.resFutureMessage.toString()} is of a past date,  
                  Please try again with different Event type`}
                  show={this.state.notFutureEvent}
                />
              ) : null}
              {this.state.offerFixedTypeAlert? (
              <AlertDanger
                  message={this.state.offerFixedErrorMessage}
                  show={this.state.offerFixedTypeAlert}
                />
                ): null}
              {this.state.integerValidationAlert ? (
                <AlertDanger
                  message="Entered fields cannot contain decimals"
                  show={this.state.integerValidationAlert}
                />
              ) : null}
              {this.state.cannotSimulateAlert ? (
                <AlertDanger
                  message="No events are mapped to this campaign"
                  show={this.state.cannotSimulateAlert}
                />
              ) : null}
              {this.state.somethingWrongAlert ? (
                <AlertDanger
                  message={this.state.resMessage.toString()}
                  show={this.state.somethingWrongAlert}
                />
              ) : null}
              {this.state.adgroupAddedAlert ? (
                <AlertSuccess
                  message={this.props.editOffers ? "Offer(s) modified successfully" : "Offer(s) added successfully"}
                  show={this.state.adgroupAddedAlert}
                />
              ) : null}

              {this.state.isSuccess ? (
                <AlertSuccess
                  message={this.state.successMessage}
                  show={this.state.isSuccess}
                />
              ) : null}

              {this.state.duplicateValueError ? (
                <AlertDanger
                  message="Duplicate values entered"
                  show={this.state.duplicateValueError}
                />
              ) : null}
                {this.state.isFailure ? (
                <AlertDanger
                  message={this.state.failureMessage}
                  show={this.state.isFailure}
                />
              ) : null}
            </div>
            <div className="closeicon">
              <span style={{ cursor: "pointer" }}>
                <i
                  className="fa fa-times"
                  aria-hidden="true"
                  onClick={() => this.props.closeAdgroupModal()}
                />
              </span>
            </div>
            <h4 className="promoHeading mb-4">
              {this.props.editOffers ? "Modify Offers" : "Add Offers From LY"}
            </h4>
            <div className="row pipelineCopydiv" style={{ marginTop: "-1%" }}>
              <div className="col-md-10">
                <div className="row" style={{ marginLeft: "7%" }}>
                  <div className="col-md-4">
                    <span className="promoNameText row">
                      Promotion Name:
                      <p className="promonameResult">
                        {/* {this.props.campaignName} */}
                        {`${moment(this.props.campaignStartDate).format("MM/DD/YYYY")} - ${moment(this.props.campaignEndDate).format("MM/DD/YYYY")}`}
                      </p>
                    </span>
                  </div>
                  <div className="col-md-3">
                    <span
                      className="promoNameText row"
                      style={{ paddingLeft: "2%" }}
                    >
                      Start Date:
                      <p className="promonameResult">
                        {moment(this.props.campaignStartDate).format("MM/DD/YYYY")}
                      </p>
                    </span>
                  </div>
                  <div className="col-md-3">
                    <span
                      className="promoNameText row"
                      style={{ paddingLeft: "2%" }}
                    >
                      End Date:
                      <p className="promonameResult">
                        {moment(this.props.campaignEndDate).format("MM/DD/YYYY")}
                      </p>
                    </span>
                  </div>
                  <div className="col-md-2">
                    <span
                      className="promoNameText row"
                      style={{ paddingLeft: "2%" }}
                    >
                      # of days:
                      <p className="promonameResult">{this.props.diffDays}</p>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{ margin: "1% 1% 0% 1%", height: this.state.modifyOfferApprovalErrorData.data ? "50%" : "80%", }}
              className="add-adgroup-pipeline"
            >
              <DataTable
                parentId="myGridForPipeline"
                parentStyle={{
                  // height: this.state.addOffersLyErrorsData.data ? "95%" : "95%",
                  height : "75%",
                  width: "100%",
                  position: "relative",
                }}
                parentClass="ag-theme-balham"
                tableData={tableData}
                rowClassRules={this.state.rowClassRules}
                frameworkComponents={{
                  pipelineDropdownRenderer: PipelineDropdownRenderer,
                  pipelineInputRenderer: PipelineInputRenderer,
                  numericEditor: NumericEditor,
                  promoActionRenderer: PromoActionRenderer
                }}
                rowSelection="multiple"
                suppressRowClickSelection={true}
                selectOfferRow={this.onSelectionChanged}
                overlayNoRowsTemplate={"No LY events mapped to this campaign"}
                editableRowsHandler={this.editableRowsHandler}
                plannedOfferChanged={this.state.plannedOfferChanged}
                tyEventChanged={this.state.tyEventChanged}
                plannedOfferHandler={this.plannedOfferHandler}
                tyOfferdropdownChange={this.tyOfferdropdownChange}
                rowIndex={this.state.rowIndex}
                tyRowIndex={this.state.tyRowIndex}
              //   pipelineTableExport={this.state.pipelineTableExport}
              //   pipelineTableExportHandler={this.pipelineTableExportHandler}
              />
           <div className="text-center mt-2">
              <button
                className="btn btn-outline-primary promo3Buttons"
                disabled={this.state.disableSaveBtn || isIt()}
                onClick={() => {
                  this.addAdgroupToPipeline();
                }}
              >
                Save
              </button>

              {/* {!this.props.editOffers ? (
                <button
                  className="btn btn-outline-primary promo3Buttons ml-2"
                  disabled={this.state.disableSaveBtn}
                  onClick={() => this.simulateHandler()}
                >
                  Simulate
                </button>
              ) : (
                ""
              )} */}
            </div>

            </div>


          {
              this.state.modifyOfferApprovalErrorData.data && this.state.modifyOfferApprovalErrorData.data.length>0 &&
              <>
                <p className="errorMessage" 
                style={{marginTop:"-3%"}} >
                  {this.props.editOffers ? "Following offers cannot be modified" : "Following offers cannot be created"}
                  </p>
                <div className="copyOffertableContainer">
                  <DataTable
                    parentId="myGridForCopyPromoErrorDetails"
                    tableData={this.state.modifyOfferApprovalErrorData}
                    parentStyle={{
                      height : "20vh",
                      width: "100%",
                      position: "relative",
                    }}
                    frameworkComponents={{
                      approvalStatusRenderer: ApprovalStatusRenderer
                  }}
                  // getRowStyle = {getRowStyle}
                  autoSizeCol={true}
                  />
                </div>
              </>
            }

            
          </PageLoader>
        </Modal>
        {this.state.saveBeforeSimulate ? (
          <Modal
            visible={true}
            width="400px"
            height="200px"
            effect="fadeInDown"
          >
            <h5 className="delete-confirmatn-modal text-left mt-3">
              There are some unsaved changes. Do you wish to continue?
            </h5>
            <div className="text-center delete-btns">
              <button
                className="btn btn-outline-secondary"
                onClick={() => {
                  this.setState({ saveBeforeSimulate: false });
                }}
              >
                No
              </button>
              <button
                className="btn btn-outline-secondary ml-3"
                onClick={() => this.goToSimulateHandler()}
              >
                Yes
              </button>
            </div>
          </Modal>
        ) : null}
      </>
    );
  }
}

export default connect(null, {
  saveAdgroupFromIntermediate,
  usersLogReport,
  fetchRegSalesPrices,
  fetchRegPrices,
  fetchPromoBuildStatus ,
  getSignShellOptions
})(AddOffersFromLYModal);
