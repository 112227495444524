import React from "react";
import { withRouter } from "react-router-dom";
// import { connect } from "react-redux";s
import Modal from "react-awesome-modal";
// import { samplLogin } from "../../redux/actions/loginAction";
import queryString from "query-string";
import { config } from "../../utils/config";
import axios from "axios";

export class SessionTimedOutModal extends React.Component {
  state = {
    msg: null,
    heading: null,
    redirectUrl: null,
    closeModal:false
  };
  componentDidMount() {
    let queries = queryString.parse(this.props.location.search);
    if (queries.msg) {
      this.setState({
        msg: queries.msg,
        redirectUrl: queries.redirect_url,
        heading: queries.heading,
        closeModal:true
      });
    }
  }
  onClickHandler = () => {
    /*
    if (this.state.redirectUrl) {
      localStorage.clear();
      window.location.href = this.state.redirectUrl;
      return;
    }
    */
   // let res = await this.props.samplLogin();
    localStorage.clear();
    this.setState({
      closeModal:false
    })
    window.location.href = `${config.baseUrl}/user/samlLogin`;
   // return await axios.get(`${config.baseUrl}/user/samlLogin`)
  }
  // samplLoginHandler = async () => {
  //   let res = await this.props.samplLogin();

  //   if (res.redirect) {
  //     window.location.href = res.url;
  //   }
  // };
  render() {
    return (
      <Modal
        visible={this.state.closeModal}
        width="400px"
        height="250px"
        effect="fadeInDown"
        onClickAway={this.onClickHandler}
      >
        <div className="closeicon">
          <span style={{ cursor: "pointer" }}>
            <i
              className="fa fa-times"
              aria-hidden="true"
              onClick={this.onClickHandler}
            />
          </span>
        </div>
        <h5 className="delete-confirmatn-modal text-left">
          {this.state.heading}
        </h5>
        <p className="session-timedout-padding text-left">
          {this.state.msg}
        </p>
        <div className="text-right delete-btns session-timedout-padding">
          <button
            className="btn btn-outline-primary promo3Buttons ml-3"
            onClick={this.onClickHandler}
          >
            Ok
          </button>
        </div>
      </Modal>
    );
  }
}

export default withRouter(SessionTimedOutModal);
