import { fill, filter } from "lodash";

let MapTable = [
    {
        headerName: "TY Adgroup",
        field: "TyAdgroup",
        width:150
    },
    {
        headerName: "LY Adgroup",
        field: "LyAdgroup",
        width:200,
        cellRenderer: "editCellRenderer",
    }
];

export default {
    defaultColDef: {
        headerCheckboxSelectionFilteredOnly: true,
        resizable: true,
        width: 200,
        filter: "agTextColumnFilter",
        suppressSizeToFit: true,
        pagination : false
      },
    MapAdgroupTable: [...MapTable]
}