import React, { Component } from "react";

export class ChildMessageRenderer extends Component {
  viewSku = () => {
    this.props.data.viewSku({
      comment_id: this.props.data.comment_id
    });
  };

  render() {
    return (
      <div>
        {this.props.data.comment_type === 3 ? (
          <i
            className="fa fa-file text-cyan"
            title="View SKU list"
            style={{
              //   color: this.props.data.promo_id === null ? "grey" : "#357ef2",
              color: "#357ef2",
              cursor: "pointer",
              marginLeft: "15px",
              fontSize: "15px",
              //   pointerEvents: this.props.data.promo_id === null ? "none" : "auto"
              pointerEvents: "auto"
            }}
            onClick={this.viewSku}
          />
        ) : null}
      </div>
    );
  }
}

export default ChildMessageRenderer;
