import {
  PROMOLOADER,
  SAVEPIPELINECELLS,
  CAMPAIGNS_DETAILS,
  CALENDAR_EMPTY_DATA,
  SETADGROUP,
  HISTORICPERFORMANCEFILTERADGRP,
  HISTORICPERFORMANCEFILTERSHOP,
  HISTORICPERFORMANCECURRENT,
  HISTORICPERFORMANCELAST,
  PIPELINETABLE_DATE,
  CALENDAR_DATA,
  CALENDAR_STRUCURE,
  PIPELINETABLE_DATE_SKU,
  LOADER,
  HISTORICALPROMOS,
  HISTORICPERFORMANCEFILTEROFFERCURRENT,
  HISTORICPERFORMANCEFILTEROFFERLAST,
  SHOW_VALIDATION,
  COPY_CAMPAIGN_DETAILS,
  USERCOMMENTS,
  GET_ERRORS,
  UPLOAD_ERROR_MSG,
} from "../actions/types";
const initialState = {
  view: 4,
  pipelineData: [],
  pipelineDataSku: [],
  savePipelineCells: [],
  calendarStrucure: null,
  calendarDataPerYear: [],
  historicalPromoData: [],
  historicPerformanceDataCurrent: [],
  historicPerformanceDataLast: [],
  historicPerformanceFilterShop: [],
  historicPerformanceFilterAdGrp: [],
  historicPerformanceFilterOfferLast: [],
  historicPerformanceFilterOfferCurrent: [],
  selectedAdGrp: [],
  campaignsDetails: null,
  copyCampaignDetails: [],
  nodata: false,
  userComments: [],
  nodataPipeline: false,
  loader: false,
  loading: false,
  errorMessage: [],
  uploadOfferData:[],
  uploadErrorMsg:'',
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SAVEPIPELINECELLS:
      return {
        ...state,
        savePipelineCells: action.payload
      };
    case USERCOMMENTS:
      return {
        ...state,
        userComments: action.payload
      };
    case SETADGROUP:
      return {
        ...state,
        selectedAdGrp: action.payload
      };
    case HISTORICPERFORMANCEFILTERSHOP:
      return {
        ...state,
        historicPerformanceFilterShop: action.payload
      };
    case HISTORICPERFORMANCEFILTERADGRP:
      return {
        ...state,
        historicPerformanceFilterAdGrp: action.payload
      };
    case HISTORICPERFORMANCEFILTEROFFERCURRENT:
      return {
        ...state,
        historicPerformanceFilterOfferCurrent: action.payload
      };
    case HISTORICPERFORMANCEFILTEROFFERLAST:
      return {
        ...state,
        historicPerformanceFilterOfferLast: action.payload
      };
    case HISTORICPERFORMANCELAST:
      return {
        ...state,
        historicPerformanceDataLast: action.payload
      };
    case HISTORICPERFORMANCECURRENT:
      return {
        ...state,
        historicPerformanceDataCurrent: action.payload
      };
    case HISTORICALPROMOS:
      return {
        ...state,
        historicalPromoData: action.payload
      };
    case CALENDAR_STRUCURE:
      return {
        ...state,
        calendarStrucure: action.payload
      };
    case LOADER:
      return {
        ...state,
        loader: action.payload
      };
    case CALENDAR_DATA:
      let historyCampId = localStorage.getItem("campaignId");

      if (historyCampId && historyCampId !== "null") {
        return {
          ...state,
          calendarDataPerYear: action.payload,
          nodata: false
        };
      } else {
        return {
          ...state,
          pipelineData: [],
          calendarDataPerYear: action.payload,
          nodata: false
        };
      }

    case CALENDAR_EMPTY_DATA:
      return {
        ...state,
        calendarDataPerYear: action.payload,
        pipelineData: action.payload,
        pipelineDataSku: action.payload,
        nodata: true,
        nodataPipeline: true
      };
    case PIPELINETABLE_DATE:
      return {
        ...state,
        pipelineData: action.payload,
        nodataPipeline: false
      };
    case PIPELINETABLE_DATE_SKU:
      return {
        ...state,
        pipelineDataSku: action.payload,
        nodataPipeline: false
      };
    case SHOW_VALIDATION:     
      return {
        ...state,
        loading: action.payload
      };
    case GET_ERRORS:
      return {
        ...state,
        errorMessage: action.payload
      };
    case CAMPAIGNS_DETAILS:
      return {
        ...state,
        campaignsDetails: action.payload
      };
    case COPY_CAMPAIGN_DETAILS:
      return {
        copyCampaignDetails: action.payload
      };
    case 'uploadOffers_data':
      return{
        ...state,
        uploadOfferData:action.payload
      }
    case UPLOAD_ERROR_MSG:
      return{
        ...state,
        uploadErrorMsg: action.payload? action.payload : ''
      }
    default:
      return {
        ...state
      };
  }
}
