import React, { Component } from 'react';
import { Card, Button } from 'react-bootstrap';
import Modal from 'react-awesome-modal';
import PageLoader from "../../utils/Loader";
import { alertTimer } from '../../utils/alertTimer';
import TicketsTable from './views/TicketsTable';
import TicketForm from './views/TicketForm';
import api from './apiHelper';
import './index.css';
import AlertSuccess from "../../utils/AlertMessage/alertSuccess";
// import AlertDanger from "../../utils/AlertMessage/alertDanger";
import _ from "lodash";
import { SupportGA } from '../../utils/GA/GAUtils';

const queueId = 153104;//148040; // This is ticket queue id for BJ's PS
const access_key = "2e3e909bd1c7692cbd15050e646d0fe07cea31d2";
let userInfo = {};
export default class MojoSupport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tickets: [],
      cardLoader: false,
      modalLoader: false,
      isModalVisible: false,
      ticketDetails: {},
      successMsg: false,
      alertMsg: ""
    };
  }
  componentWillMount(){
    let mail = localStorage.getItem("email");
    userInfo = {
      email: mail
    }
  }
  componentDidMount() {
    this.getTickets();
  }

  getTickets = () => {
    this.setState({ cardLoader: true });
    const { email } = userInfo;
    api
      .getTickets({ access_key: access_key, per_page: 1000 }, email, queueId)
      .then((resp) => {
        this.setState({
          tickets: (resp.data && resp.data.data) || [],
          cardLoader: false,
        });
      });
  };

  createTicket = (payload, resetForm) => {
    this.submitGA("Click", "Clicked_CreateButton");
    this.setState({ modalLoader: true });
    api.createTicket({ access_key: access_key }, payload).then((resp) => {
      let counter = { value: 0 };
      this.attachFiles({
        ticketId: resp.data.data.id,
        counter,
        files: payload.files,
        resetForm,
        isCreateFlow: true,
      });
      if(_.isEmpty(payload.files)){
        this.setState({ 
          tickets: [], 
          isModalVisible: false,
          successMsg: true, 
          modalLoader: false,
          alertMsg: "Ticket created successfully"
         }, () => {
          this.getTickets();
          resetForm();
        })
        setTimeout(() => { this.setState({ successMsg: false }) }, alertTimer);
      }
    });
  };

  attachFiles = ({ ticketId, counter, files, resetForm, isCreateFlow }) => {    
    this.setState({ modalLoader: true });
    const noOfFiles = files.length;
    const formData = new FormData();
    formData.append('file', files[counter.value]);
    api.attachFile({ ticketId, access_key: access_key, file: formData }).then((resp) => {
      if (counter.value === noOfFiles - 1) {
        this.setState({ 
          successMsg: true, 
          alertMsg: isCreateFlow ? "Ticket created successfully" : "Attachment added successfully" })
        setTimeout(() => { this.setState({ successMsg: false }) }, alertTimer);

        counter.value = 0;
        isCreateFlow
          ? this.setState({ tickets: [], isModalVisible: false, modalLoader: false }, () => {
              this.getTickets();
              resetForm();
            })
          : this.onTicketClick(ticketId);
      } else {
        ++counter.value;
        this.attachFiles({ ticketId, counter, files, resetForm, isCreateFlow });
      }
    });
  };

  createComment = (ticketId, payload) => {
    this.setState({ modalLoader: true });
    api.createComment(ticketId, { access_key: access_key }, payload).then((resp) => {
      this.onTicketClick(ticketId);
      this.setState({ successMsg: true, alertMsg: "Comment added successfully" })
      setTimeout(() => { this.setState({ successMsg: false }) }, alertTimer);
    });
  };
  downloadAttachment = (attachmentId) => {
    this.setState({ modalLoader: true });
    setTimeout(() => {
      this.setState({ modalLoader: false, successMsg: true, alertMsg: "Downloaded successfully" });
      setTimeout(() => { this.setState({ successMsg: false }) }, alertTimer);
    }, alertTimer)
  };

  onTicketClick = (id) => {
    !this.state.modalLoader && this.setState({ cardLoader: true });
    api.getTicket({ access_key: access_key, id }).then((resp) => {
      this.setState({
        ticketDetails: resp.data.data,
        isModalVisible: true,
        modalLoader: false,
        cardLoader: false,
      });
    });
    this.submitGA("Click", "Clicked_TicketID");
  };
  submitGA(action,label){
    SupportGA({ action, label});
  }

  render() {
    return (
      <>
      <div className="alertDivs">
          {this.state.successMsg ? (
            <AlertSuccess
              message={this.state.alertMsg}
              show={true}
            />
          ) : null}
          {/* {!_.isEmpty(this.state.passwordError) ? (
            <AlertDanger
              message={this.state.passwordError}
              show={this.state.passwordError.length}
            />
          ) : null} */}
        </div>
        <PageLoader loader={this.state.cardLoader}>
          <Card>
            <Card.Body>
              <Card.Title>
                <h5 className="text-dark">Tickets</h5>
              </Card.Title>
              <div style={{ textAlign: 'right', margin: '1rem 0' }}>
                <Button
                  variant="primary"
                  onClick={() => {
                    this.setState({ isModalVisible: true, ticketDetails: {} });
                    this.submitGA("Click", "Clicked_CreateTicketButton");
                  }}>
                  Create Ticket
                </Button>
              </div>
              <TicketsTable
                access_key={access_key}
                rowData={this.state.tickets}
                onTicketClick={this.onTicketClick}
              />
            </Card.Body>
          </Card>
        </PageLoader>
        <Modal
          visible={this.state.isModalVisible}
          width="55%"
          effect="fadeInDown"
          onClickAway={() => this.setState({ isModalVisible: false })}>
            <div className="closeicon">
              <span style={{ cursor: "pointer" }}>
                <i
                  className="fa fa-times"
                  aria-hidden="true"
                  onClick={() => this.setState({ isModalVisible: false })}
                />
              </span>
            </div>
          <PageLoader loader={this.state.modalLoader}>
            <div className="modal-body pt-0">
              <h4>{this.state.ticketDetails.id ? 'Ticket View' : 'Ticket Creation'}</h4>
              <TicketForm
                queueId={queueId}
                attachFiles={this.attachFiles}
                downloadAttachment={this.downloadAttachment}
                access_key={access_key}
                createTicket={this.createTicket}
                createComment={this.createComment}
                userInfo={userInfo}
                ticketDetails={this.state.ticketDetails}
                onHandleGA={this.submitGA}
              />
            </div>
          </PageLoader>
        </Modal>
      </>
    );
  }
}
