import moment from "moment";
import numeral from "numeral";

export default {
  defaultColDef: {
    resizable: true,
    filter: true,
    sortable: true,
  },
  deleteDefaultColDef: {
    headerCheckboxSelection: isFirstColumn,
    headerCheckboxSelectionFilteredOnly: true,
    checkboxSelection: isFirstColumn,
    resizable: true,
    filter: true,
    sortable: true,
  },
  skuHeader: [
    {
      headerName: "SKU",
      field: "unique_id1",
      width: 200,
      pinned: "left",
      filterParams: { defaultOption: "contains" },
      filter: "agTextColumnFilter",
      unSortIcon: true,
    },
    {
      headerName: "SKU Description",
      field: "name",
      width: 250,
      filterParams: { defaultOption: "contains" },
      valueGetter: function (params) {
        if (params.data.name) return params.data.name;
        else if (params.data.description) return params.data.description;
        return "-";
      },
      filter: "agTextColumnFilter",
      pinned: "left",
      unSortIcon: true,
    },
    {
      headerName: "Merchandise Category",
      field: "merch_cat",
      filterParams: { defaultOption: "contains" },
      filter: "agTextColumnFilter",
      unSortIcon: true,
      valueGetter: function (params) {
        if (params.data.merch_cat) {
          return `${params.data.merch_cat_id} - ${params.data.merch_cat}`;
        }
        return "-";
      },
    },
    {
      headerName: "Category",
      field: "catagory_desc",
      filterParams: { defaultOption: "contains" },
      filter: "agTextColumnFilter",
      unSortIcon: true,
      valueGetter: function (params) {
        if (params.data.catagory_desc) {
          return `${params.data.catergory_desc_id} - ${params.data.catagory_desc}`;
        }
        return "-";
      },
    },
    {
      headerName: "Shop",
      field: "shop_desc",
      filterParams: { defaultOption: "contains" },
      filter: "agTextColumnFilter",
      unSortIcon: true,
      valueGetter: function (params) {
        if (params.data.shop_desc) {
          return `${params.data.shop_desc_id} - ${params.data.shop_desc}`;
        }
        return "-";
      },
    },
    {
      headerName: "Article Status",
      field: "article_status",
      filterParams: { defaultOption: "contains" },
      filter: "agTextColumnFilter",
      unSortIcon: true,
      valueGetter: function (params) {
        if (params.data.article_status) {
          return `${params.data.article_id} - ${params.data.article_status}`;
        }
        return "-";
      },
      width: 250,
    },
    {
      headerName: "Vendor",
      field: "vendor_name",
      filterParams: { defaultOption: "contains" },
      filter: "agTextColumnFilter",
      unSortIcon: true,
      valueGetter: function (params) {
        if (params.data.vendor_name) {
          return `${params.data.vendor_code} - ${params.data.vendor_name}`;
        }
        return "-";
      },
    },
    {
      headerName: "Zone 1 Retail Price ($)",
      valueFormatter: emptyValueFormatter,
      field: "zone1_retail_price",
      filterParams: { defaultOption: "equals" },
      filter: "agTextColumnFilter",
      unSortIcon: true,
    },
    {
      headerName: "Zone 3 Retail Price ($)",
      valueFormatter: emptyValueFormatter,
      field: "zone3_retail_price",
      filterParams: { defaultOption: "equals" },
      filter: "agTextColumnFilter",
      unSortIcon: true,
    },
    {
      headerName: "Zone 4 Retail Price ($)",
      valueFormatter: emptyValueFormatter,
      field: "zone4_retail_price",
      filterParams: { defaultOption: "equals" },
      filter: "agTextColumnFilter",
      unSortIcon: true,
    },
    {
      headerName: "Zone 5 Retail Price ($)",
      valueFormatter: emptyValueFormatter,
      field: "zone5_retail_price",
      filterParams: { defaultOption: "equals" },
      filter: "agTextColumnFilter",
      unSortIcon: true,
    },
    {
      headerName: "Cost ($)",
      valueFormatter: emptyValueFormatter,
      field: "cost",
      filterParams: { defaultOption: "equals" },
      filter: "agTextColumnFilter",
      unSortIcon: true,
    },
    {
      headerName: "Collection",
      field: "collection",
      filterParams: { defaultOption: "contains" },
      filter: "agTextColumnFilter",
      unSortIcon: true,
      valueGetter: function (params) {
        if (params.data.collection) {
          return params.data.collection;
        }
        return "-";
      },
    },
    {
      headerName: "Product Brand",
      field: "product_brand",
      filterParams: { defaultOption: "contains" },
      filter: "agTextColumnFilter",
      unSortIcon: true,
      valueGetter: function (params) {
        if (params.data.product_brand) {
          return params.data.product_brand;
        }
        return "-";
      },
    },
    {
      headerName: "Color",
      field: "color",
      filterParams: { defaultOption: "contains" },
      filter: "agTextColumnFilter",
      unSortIcon: true,
      valueGetter: function (params) {
        if (params.data.color) {
          return params.data.color;
        }
        return "-";
      },
      hide: true,
    },
    {
      headerName: "Color",
      field: "attribute_color",
      filterParams: { defaultOption: "contains" },
      filter: "agTextColumnFilter",
      unSortIcon: true,
      valueGetter: function (params) {
        if (params.data.attribute_color) {
          return params.data.attribute_color;
        }
        return "-";
      },
      hide: true,
    },
    {
      headerName: "Material",
      field: "material",
      filterParams: { defaultOption: "contains" },
      filter: "agTextColumnFilter",
      unSortIcon: true,
      valueGetter: function (params) {
        if (params.data.material) {
          return params.data.material;
        }
        return "-";
      },
      hide: true,
    },
    {
      headerName: "Joann Reference SKU",
      field: "joann_ref_sku",
      filterParams: { defaultOption: "contains" },
      filter: "agTextColumnFilter",
      unSortIcon: true,
      valueGetter: function (params) {
        if (params.data.joann_ref_sku) {
          return params.data.joann_ref_sku;
        }
        return "-";
      },
      hide: true
    },
    {
      headerName: "IA Reference SKU",
      field: "ia_ref_sku",
      filterParams: { defaultOption: "contains" },
      filter: "agTextColumnFilter",
      unSortIcon: true,
      valueGetter: function (params) {
        if (params.data.ia_ref_sku && params.data.ia_ref_sku !== "00000000") {
          return params.data.ia_ref_sku;
        }
        return "-";
      },
      hide: true
    },
    {
      headerName: "Reference SKU",
      field: "selected_sku",
      width: 300,
      //cellRenderer: "refSkuChildMessageRenderer",
      filter: "agTextColumnFilter",
      unSortIcon: true,
      hide: true,
    },
    {
      headerName: "Final reference SKU",
      field: "reference_sku",
      width: 250,
      cellRenderer: "refSkuChildMessageRenderer",
      filter: "agTextColumnFilter",
      unSortIcon: true,
      hide: true,
    },
    {
      headerName: "Final reference SKU",
      field: "original_reference_sku",
      width: 250,
      filter: "agTextColumnFilter",
      unSortIcon: true,
      hide: true,
    },
    {
      headerName: "Reference SKU description",
      field: "similar_sku_description",
      width: 250,
      filter: "agTextColumnFilter",
      unSortIcon: true,
      valueFormatter: function (params) {
        if (params.data.unique_id1 === params.data.reference_sku || 
          params.data.reference_sku === "00000000" ||
          params.data.sku_tag !== "prod_sim") {
          return "-";
        } else {
          return params.data.similar_sku_description;
        }
      },
      hide: true,
    },
    {
      headerName: "Reference SKU price",
      field: "similar_sku_price",
      filter: "agTextColumnFilter",
      unSortIcon: true,
      valueFormatter: function (params) {
        if (params.data.unique_id1 === params.data.reference_sku || 
          params.data.reference_sku === "00000000" ||
          params.data.sku_tag !== "prod_sim") {
          return "-";
        } else {
          return params.data.similar_sku_price;
        }
      },
      hide: true,
    },
    {
      headerName: "Reference SKU cost",
      field: "similar_sku_cost",
      filter: "agTextColumnFilter",
      unSortIcon: true,
      valueFormatter: function (params) {
        if (params.data.unique_id1 === params.data.reference_sku || 
          params.data.reference_sku === "00000000" ||
          params.data.sku_tag !== "prod_sim") {
          return "-";
        } else {
          return params.data.similar_sku_cost;
        }
      },
      hide: true,
    },
    {
      headerName: "Reference SKU options",
      field: "refSkuOptions",
      width: 300,
      filter: "agTextColumnFilter",
      unSortIcon: true,
      hide: true,
    },
    {
      headerName: "Ref art Multiplier",
      field: "similar_sku_multiplier",
      filter: "agTextColumnFilter",
      unSortIcon: true,
      valueGetter: function (params) {
        if (params.data.unique_id1 === params.data.reference_sku || 
        params.data.reference_sku === "00000000" ||
        params.data.sku_tag !== "prod_sim") {
          return "-";
        } else if (params.data.similar_sku_multiplier) {
          return params.data.similar_sku_multiplier;
        }
        return "-";
      },
      hide: true,
    },
    {
      headerName: "Pattern",
      field: "pattern",
      filter: "agTextColumnFilter",
      unSortIcon: true,
      valueGetter: function (params) {
        if (params.data.pattern) {
          return params.data.pattern;
        }
        return "-";
      },
      hide: true,
    },
    {
      headerName: "Attribute Pattern",
      field: "attribute_pattern",
      filter: "agTextColumnFilter",
      unSortIcon: true,
      valueGetter: function (params) {
        if (params.data.pattern) {
          return params.data.pattern;
        }
        return "-";
      },
      hide: true,
    },
    {
      headerName: "Web online",
      field: "online_sku",
      filter: "agTextColumnFilter",
      unSortIcon: true,
      valueGetter: function (params) {
        if (params.data.online_sku === 0) {
          return "No";
        }
        return "Yes";
      },
      hide: true,
    },
    {
      headerName: "In store date",
      field: "in_store_date",
      filterParams: { defaultOption: "contains" },
      filter: "agTextColumnFilter",
      unSortIcon: true,
      valueGetter: function sumField(params) {
        if (params.data.in_store_date !== null) {
          return moment(params.data.in_store_date).format("MM-DD-YYYY");
        } else {
          return "-";
        }
      },
    },
    {
      headerName: "Create date",
      field: "prod_creation_date",
      filterParams: { defaultOption: "contains" },
      filter: "agTextColumnFilter",
      unSortIcon: true,
      valueGetter: function sumField(params) {
        if (params.data.prod_creation_date !== null) {
          return moment(params.data.prod_creation_date).format("MM-DD-YYYY");
        } else {
          return "-";
        }
      },
    },
    {
      headerName: "Sales Channel",
      field: "sales_channel",
      filterParams: { defaultOption: "contains" },
      filter: "agTextColumnFilter",
      unSortIcon: true,
      valueGetter: function (params) {
        if (params.data.sales_channel) {
          return params.data.sales_channel
        }
        return "-";
      },
    },
    {
      headerName: "Fashion Basic Type Code Descr",
      field: "fashion_basic_type_code_descr",
      filter: "agTextColumnFilter",
      unSortIcon: true,
      valueGetter: function (params) {
        if (params.data.fashion_basic_type_code_descr) {
          return params.data.fashion_basic_type_code_descr;
        }
        return "-";
      },
      hide: true,
    },
    {
      headerName: "Season Code Descr",
      field: "season_code_descr",
      filter: "agTextColumnFilter",
      unSortIcon: true,
      valueGetter: function (params) {
        if (params.data.season_code_descr) {
          return params.data.season_code_descr;
        }
        return "-";
      },
      hide: true,
    },
    {
      headerName: "SKU Saved",
      field: "sku_saved",
      hide: true,
    },
    {
      headerName: "Action",
      field: "action",
      cellRenderer: "refSkuActionRenderer",
      pinned: "right",
      suppressSizeToFit: true,
      cellStyle: null, //cellStyleMethodNew
      filter: false,
      width: 100,
      hide: true,
    },
  ],
};

function isFirstColumn(params) {
  var displayedColumns = params.columnApi.getAllDisplayedColumns();
  var thisIsFirstColumn = displayedColumns[0] === params.column;
  return thisIsFirstColumn;
}

function emptyValueFormatter(params) {
  let field = params.column.userProvidedColDef.field,
    value = field ? params.data[field] : "";

  if (value !== "") {
    let sign = Math.sign(value);
    let valueVar = Math.round(value * 100) / 100;

    return value === null || value === ""
      ? "-"
      : params.colDef.headerName.indexOf("$") > -1 &&
        (sign === 1 || sign === 0) &&
        valueVar !== 0
      ? "$ " + numeral(valueVar).format("0.00")
      : "$ " + numeral(valueVar).format("(0.00)")
      ? !params.colDef.headerName.indexOf("$") > -1 &&
        (sign === 1 || sign === 0) &&
        valueVar !== 0
        ? numeral(valueVar).format("0.00")
        : numeral(valueVar).format("(0.00)")
      : "-";
  }
  return "-";
}
