import React from "react";
import Modal from "react-awesome-modal";

const DeleteCouponConfirmationModal = props => {
  return (
    <Modal
      visible={true}
      width="400px"
      height="200px"
      effect="fadeInDown"
      onClickAway={() => props.showConfirmAlert()}
    >
      <div className="closeicon">
        <span style={{ cursor: "pointer" }}>
          <i
            className="fa fa-times"
            aria-hidden="true"
            onClick={() => props.showConfirmAlert()}
          />
        </span>
      </div>
      <h5 className="delete-confirmatn-modal text-left">
        Are you sure you want to delete this coupon?
      </h5>
      <div className="text-center delete-btns">
        <button
          className="btn btn-outline-secondary"
          onClick={() => props.showConfirmAlert()}
        >
          No
        </button>
        <button
          className="btn btn-outline-secondary ml-3"
          onClick={() => props.deleteCoupon(props.couponRowDetails)}
        >
          Yes
        </button>
      </div>
    </Modal>
  );
};

export default DeleteCouponConfirmationModal;
