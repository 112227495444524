import React, { Component } from 'react';
import { Breadcrumb } from 'reactstrap';
import "./breadcrumbStyle.css";
class PromoContainer extends Component {

  render() {
    return (
      <div className="bradcrumbDiv">
      <Breadcrumb>
      </Breadcrumb>
    </div>

    );
  }
}

export default PromoContainer;
