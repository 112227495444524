import { emptyValueFormatterNew } from "../../../utils/valueFormatters";

export default {
  defaultColDef: {
    resizable: true,
    sortable: true,
    filter: true
  },
  header: [
    {
      headerName: "Metric",
      field: "metric",
      checkboxSelection: true,
      filter: "agTextColumnFilter",
      width: 250,
      pinned: "left",
      suppressSizeToFit: true,
      cellStyle: function(params) {
        if (
          params.value === "Rec. By Revenue" ||
          params.value === "Rec. By Margin"
        ) {
          return {
            color: "gray"
          };
        } else {
          return {
            color: "#2d69eb"
          };
        }
      },
      unSortIcon: true
    },
    {
      headerName: "Offer",
      field: "offer",
      filter: "agTextColumnFilter",
      suppressSizeToFit: true,
      cellStyle: function(params) {
        return {
          color: "orange"
        };
      },
      width: 250,
      unSortIcon: true
    },
    {
      headerName: "Min Qty.",
      field: "minquantity",
      hide: true
    },
    {
      headerName: "Bonus Buy",
      field: "bonusbyquantity",
      hide: true
    },
    {
      headerName: "Fixed price quantity",
      field: "fixedpricequantity",
      hide: true
    },
    {
      headerName: "Buy Min",
      field: "buy_min",
      hide: true
    },
    {
      headerName: "Get Max",
      field: "get_max",
      hide: true
    },
    {
      headerName: "Total Units",
      field: "total_units",
      filter: "agTextColumnFilter",
      suppressSizeToFit: true,
      valueFormatter: emptyValueFormatterNew, //negativeValueFormatter,
      width: 250,
      unSortIcon: true
    },
    {
      headerName: "Inc. Units",
      field: "inc_units",
      filter: "agTextColumnFilter",
      suppressSizeToFit: true,
      valueFormatter: emptyValueFormatterNew, //negativeValueFormatter,
      // width: 100,
      unSortIcon: true,
      hide: true
    },
    {
      headerName: "Total override Units",
      field: "override_units",
      filter: "agTextColumnFilter",
      suppressSizeToFit: true,
      valueFormatter: emptyValueFormatterNew, //negativeValueFormatter,
      // width: 150,
      unSortIcon: true,
      hide: true
    },
    {
      headerName: "Total Sales ($)",
      field: "total_sales",
      filter: "agTextColumnFilter",
      suppressSizeToFit: true,
      valueFormatter: emptyValueFormatterNew, //negativeValueFormatter,
      width: 250,
      unSortIcon: true
    },
    {
      headerName: "Inc. Sales ($)",
      field: "inc_sales",
      filter: "agTextColumnFilter",
      suppressSizeToFit: true,
      valueFormatter: emptyValueFormatterNew, //negativeValueFormatter,
      // width: 100,
      unSortIcon: true,
      hide: true
    },
    {
      headerName: "Total override Sales ($)",
      field: "override_sales",
      filter: "agTextColumnFilter",
      suppressSizeToFit: true,
      valueFormatter: emptyValueFormatterNew,
      // width: 150,
      unSortIcon: true,
      hide: true
    },
    {
      headerName: "Total Margin ($)",
      field: "total_margin",
      filter: "agTextColumnFilter",
      suppressSizeToFit: true,
      valueFormatter: emptyValueFormatterNew, //negativeValueFormatter,
      width: 250,
      // width: 100,
      unSortIcon: true
    },
    {
      headerName: "Inc. Margin ($)",
      field: "inc_margin",
      filter: "agTextColumnFilter",
      suppressSizeToFit: true,
      valueFormatter: emptyValueFormatterNew, //negativeValueFormatter,
      // width: 100,
      unSortIcon: true,
      hide: true
    },
    {
      headerName: "Total override Margin ($)",
      field: "override_margin",
      filter: "agTextColumnFilter",
      suppressSizeToFit: true,
      valueFormatter: emptyValueFormatterNew,
      // width: 150,
      unSortIcon: true,
      hide: true
    },
    {
      headerName: "GM %",
      field: "gm_percentage",
      filter: "agTextColumnFilter",
      suppressSizeToFit: true,
      valueFormatter: negativeValueFormatter,
      unSortIcon: true,
      width: 250,
    },
    {
      headerName: "Confidence",
      field: "confidence",
      filter: "agTextColumnFilter",
      suppressSizeToFit: true,
      unSortIcon: true,
      width: 250,
      headerTooltip:`
High: More than 85% of these forecasts will have less than 20% error.
Medium: Nearly 30% of these forecasts have more than 20% error and some manual intervention might be required 
                  to adjust the forecast depending on the reason, which include:
                  • The promo or event is planned only for 1 day
                  • The adgroup has less than 10 SKUs
                  • The adgroup contains a high number of new and/or seasonal SKUs
Low:  The chance of the forecast being within the 20% range of error is just 50%. Occurs when >80% of SKUs have a 
                  sales history of fewer than 90 days.`,
      tooltipShowDelay: 0,
    }
  ]
};

function negativeValueFormatter(val) {
  let value = val.value,
    toSting;
  if (value) {
    if (
      val.colDef &&
      val.colDef.headerName &&
      val.colDef.headerName.indexOf("%") > -1
    ) {
      toSting = Math.round(value * 100) / 100;
    } else {
      toSting = Math.round(value);
    }
    if (value > 0) {
      if (
        val.colDef &&
        val.colDef.headerName &&
        val.colDef.headerName.indexOf("%") > -1
      ) {
        return toSting.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "%";
      } else {
        return toSting.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    } else {
      var splitAvalue = Math.abs(toSting);
      if (
        val.colDef &&
        val.colDef.headerName &&
        val.colDef.headerName.indexOf("%") > -1
      ) {
        return (
          "(" +
          splitAvalue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
          ") %"
        );
      } else {
        return (
          "(" +
          splitAvalue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
          ")"
        );
      }
    }
  } else {
    if (value === 0) {
      return 0;
    } else {
      return "-";
    }
  }
}
