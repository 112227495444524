import axios from "../../utils/axios/index";
import { config } from "../../utils/config";
import {
  LOADER,
  GET_EVENTS_DATA,
  SET_EVENT_MASTER_DATA,
  SET_EVENT_DETAILS,
  GET_CAMPAIGNS_DATA,
  SET_LYEVENT_DATA,
  SET_VEHICLE_DATA,
  SET_PROMO_GROUP_DATA
} from "./types";

export const getEventMasterData = payload => async dispatch => {
  dispatch({
    type: LOADER,
    payload: true,
  });

  // const reqObject = { flag: "1" };
  const response = await axios
    .post(`${config.baseUrl}/event/getEvents`, payload)
    .then(res => {

      dispatch({
        type: SET_EVENT_MASTER_DATA,
        payload: res.data.data
      })
      dispatch({
        type: LOADER,
        payload: false,
      });
      return res;
    })
    .catch(err => {
      dispatch({
        type: LOADER,
        payload: false,
      });
      return err;
    });
  return response;
}

export const getEventDetails = payload => async dispatch => {
  dispatch({
    type: LOADER,
    payload: true,
  });

  const response = await axios
    .post(`${config.baseUrl}/event/getEventById`, payload)
    .then(res => {

      // dispatch({
      //   type: SET_EVENT_DETAILS,
      //   payload: res.data.data[0]
      // });
      dispatch({
        type: LOADER,
        payload: false,
      });
      return res.data;
    })
    .catch(err => {
      dispatch({
        type: LOADER,
        payload: false,
      });
      return err;
    });
  return response;
}

export const deleteEvent = payload => async dispatch => {
  dispatch({
    type: LOADER,
    payload: true,
  });

  const response = await axios
    .post(`${config.baseUrl}/event/deactivateEvent`, payload)
    .then(res => {

      dispatch({
        type: LOADER,
        payload: false,
      });
      return res.data;
    })
    .catch(err => {
      dispatch({
        type: LOADER,
        payload: false,
      });
      return err;
    });
  return response;
}

export const getCampaignsData = (payload) => async dispatch => {
  dispatch({
    type: LOADER,
    payload: true,
  });

  // const reqObject = { flag: "1" };
  const response = await axios
    .post(`${config.baseUrl}/event/getCampaigns`, payload)
    .then(res => {

      let campaignsData = res.data.data;
      if (payload && payload.campaign_name) {
        campaignsData = res.data.data.filter(dt => dt.campaign_name != payload.campaign_name)
      }
      dispatch({
        type: GET_CAMPAIGNS_DATA,
        payload: campaignsData
      })
      dispatch({
        type: LOADER,
        payload: false,
      });
      return res.data;
    })
    .catch(err => {
      dispatch({
        type: LOADER,
        payload: false,
      });
      return err;
    });
  return response;
}

export const getVehicleData = () => async dispatch => {
  dispatch({
    type: LOADER,
    payload: true,
  });

  // const reqObject = { flag: "1" };
  const response = await axios
    .get(`${config.baseUrl}/event/getVehicle`)
    .then(res => {

      dispatch({
        type: SET_VEHICLE_DATA,
        payload: res.data.data
      })
      dispatch({
        type: LOADER,
        payload: false,
      });
      return res;
    })
    .catch(err => {
      dispatch({
        type: LOADER,
        payload: false,
      });
      return err;
    });
  return response;
}

export const getLYEventData = (payload) => async dispatch => {
  dispatch({
    type: LOADER,
    payload: true,
  });

  // const reqObject = { flag: "1" };
  const response = await axios
    .post(`${config.baseUrl}/event/getLyEvent`, payload)
    .then(res => {

      dispatch({
        type: SET_LYEVENT_DATA,
        payload: res.data.data
      })
      dispatch({
        type: LOADER,
        payload: false,
      });
      return res;
    })
    .catch(err => {
      dispatch({
        type: LOADER,
        payload: false,
      });
      return err;
    });
  return response;
}

export const validateMainEvent = payload => async dispatch => {
  dispatch({
    type: LOADER,
    payload: true,
  });
  const response = await axios
    .post(`${config.baseUrl}/event/checkMainEvent`, payload)
    .then(res => {

      console.log(res)

      dispatch({
        type: LOADER,
        payload: false,
      });

      return res.data;
    })
    .catch(err => {
      dispatch({
        type: LOADER,
        payload: false,
      });
      return err;
    });

  return response;
}

export const getPromoGroupData = (payload) => async dispatch => {
  dispatch({
    type: LOADER,
    payload: true,
  });

  // const reqObject = { flag: "1" };
  const response = await axios
    .get(`${config.baseUrl}/event/getPromoGroup`)
    .then(res => {

      dispatch({
        type: SET_PROMO_GROUP_DATA,
        payload: res.data.data
      })
      dispatch({
        type: LOADER,
        payload: false,
      });
      return res;
    })
    .catch(err => {
      dispatch({
        type: LOADER,
        payload: false,
      });
      return err;
    });
  return response;
}

export const createEvent = payload => async dispatch => {
  dispatch({
    type: LOADER,
    payload: true,
  });

  const response = await axios
    .post(`${config.baseUrl}/event/createEvent`, payload)
    .then(res => {

      dispatch({
        type: LOADER,
        payload: false,
      });
      return res.data;
    })
    .catch(err => {
      dispatch({
        type: LOADER,
        payload: false,
      });
      return err;
    });
  return response;
}

export const createPromoGroup = payload => async dispatch => {
  dispatch({
    type: LOADER,
    payload: true,
  });

  const response = await axios
    .post(`${config.baseUrl}/event/createPromoGroup`, payload)
    .then(res => {

      dispatch({
        type: LOADER,
        payload: false,
      });
      return res.data;
    })
    .catch(err => {
      dispatch({
        type: LOADER,
        payload: false,
      });
      return err;
    });
  return response;
}

export const updateEvent = payload => async dispatch => {
  dispatch({
    type: LOADER,
    payload: true,
  });

  const response = await axios
    .post(`${config.baseUrl}/event/updateEvent`, payload)
    .then(res => {

      dispatch({
        type: LOADER,
        payload: false,
      });
      return res.data;
    })
    .catch(err => {
      dispatch({
        type: LOADER,
        payload: false,
      });
      return err;
    });
  return response;
}

export const moveEvent = payload => async dispatch => {
  dispatch({
    type: LOADER,
    payload: true,
  });

  const response = await axios
    .post(`${config.baseUrl}/event/moveEvent`, payload)
    .then(res => {

      dispatch({
        type: LOADER,
        payload: false,
      });
      return res.data;
    })
    .catch(err => {
      dispatch({
        type: LOADER,
        payload: false,
      });
      return err;
    });
  return response;
}

export const validateMoveEvent = payload => async dispatch => {
  dispatch({
    type: LOADER,
    payload: true,
  });

  const response = await axios
    .post(`${config.baseUrl}/event/checkDestCampaign`, payload)
    .then(res => {

      dispatch({
        type: LOADER,
        payload: false,
      });

      const result = {
        status: false,
        msg: `Events exists in the future campaign with event type  and type `
      }
      return res.data;
      // return result
    })
    .catch(err => {
      dispatch({
        type: LOADER,
        payload: false,
      });
      return err;
    });
  return response;
}

export const overrideEvent = payload => async dispatch => {
  dispatch({
    type: LOADER,
    payload: true
  })

  const response = await axios
    .post(`${config.baseUrl}/event/overrideEvent`, payload)
    .then(res => {

      dispatch({
        type: LOADER,
        payload: false,
      });
      return res.data;
    })
    .catch(err => {
      dispatch({
        type: LOADER,
        payload: false,
      });
      return err;
    });
  return response;
}

export const getEventMasterExpiredData = payload => async dispatch => {
  dispatch({
    type: LOADER,
    payload: true,
  });

  // const reqObject = { flag: "1" };
  const response = await axios
    .get(`${config.baseUrl}/event/expiredEvents`, payload)
    .then(res => {

      dispatch({
        type: SET_EVENT_MASTER_DATA,
        payload: res.data.data
      })
      dispatch({
        type: LOADER,
        payload: false,
      });
      return res;
    })
    .catch(err => {
      dispatch({
        type: LOADER,
        payload: false,
      });
      return err;
    });
  return response;
}
