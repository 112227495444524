import React from "react";
import { Route, Redirect } from "react-router-dom";

const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    // Show the component only when the user is logged in
    // Otherwise, redirect the user to /signin page
    <Route
      {...rest}
      // render={props =>
      //   <Component {...props} />
      // }
      render={props =>
        navigator.cookieEnabled ? (
          <Component {...props} />
        ) : (
          <Redirect to="/cookiedisabled" />
        )
      }
    />
  );
};

export default PrivateRoute;
