
import axios from "../../utils/axios/index";
import { config } from "../../utils/config";
import {CAMPAIGNS_DATA, GET_ERRORS, LOADER } from "./types";

export const listCampaigns = reqObject => dispatch => {
     

  dispatch({
    type: LOADER,
    payload: true
  });
  axios
    .get(`${config.baseUrl}/adgroup/listCampaigns`)
    .then(res => {
      dispatch({
        type: CAMPAIGNS_DATA,
        payload: res.data.data
      });
      dispatch({
        type: LOADER,
        payload: false
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err
      });
    });
};
