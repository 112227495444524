import React, { useState, useEffect } from "react";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";

const MultiSelectAll = (props) => {
  const [selectedOptions, setSelectedOptions] = useState([]);

  useEffect(() => {
    setSelectedOptions([...props.options]);
  }, []);

  function getDropdownButtonLabel({ placeholderButtonLabel, value }) {
    if (value && value.some((o) => o.value === "all")) {
      return `All selected`;
    } else if (value && value.length > 0) {
      return `${value.length} selected`;
    } else {
      return props.placeholder
    }
  }

  function onChange(value, event) {
    let selectedValue = [];
    if (event.action === "select-option" && event.option.value === "all") {
      selectedValue = props.options;
    } else if (
      event.action === "deselect-option" &&
      event.option.value === "all"
    ) {
      selectedValue = [];
    } else if (event.action === "deselect-option") {
      selectedValue = value.filter((o) => o.value !== "all")
    } else if (value.length === props.options.length - 1) {
      selectedValue = props.options
    } else {
      selectedValue = value
    }
    props.onchange(selectedValue, props.keyValue)
  }

  function onMenuClosehandler() {
    if (props.onMenuClose) {
      props.onMenuClose();
    }
  }

  return (
    <ReactMultiSelectCheckboxes
      options={[...props.options]}
      placeholderButtonLabel={props.placeholder}
      getDropdownButtonLabel={getDropdownButtonLabel}
      value={props.value}
      onChange={onChange}
      setState={setSelectedOptions}
      isDisabled={props.disabled || false}
      onMenuClose={onMenuClosehandler}
      filterOption={props.filterOption}
    />
  );
};

export default MultiSelectAll;
