import axios from "../../utils/axios/index";
import { config } from "../../utils/config";
import {
  RESET_DAYLEVELOFFER_DATA,
  GET_ERRORS,
  LOADER,
  DAYLEVELOFFER_BUYER,
  DAYLEVELOFFER_ADGROUP_RANGE_WISE,
  TYPED_DAYLEVELOFFER_ADGROUP_RANGE_WISE,
  RESET_DAYLEVELOFFER_ADGROUP_RANGE_WISE,
  DAYLEVELOFFER_SHOP,
  DAYLEVELOFFER_ADGROUP,
  TYPED_DAYLEVELOFFER_ADGROUP,
  DAYLEVELOFFER_FISCDATA,
  SET_MAPPED_LYTY,
  SET_SAVED_VIEWS_NAMES,
  SET_BUYER_LIST,
  SET_SHOP_LIST,
  SET_ADGROUP_LIST,
  SET_LYTYROW_DATA,
  SET_DLOFFER_TABLE_DEF,
  SET_DLO_TABLE_DATA,
  SET_MAPLYTY_DLOFFER_TABLE_DEF,
  SAVED_VIEW_STATUS,
  SHOW_ADGROUP_ALERT,
  SET_ADDITIONAL_METRICS,
} from "./types";
import _, { reject } from "lodash";
import { rest } from "underscore";

const getFilterData = (response) => {
  let buyerResponse = [];
  let shopResponse = [];
  let adgroupResponse = [];
  let savedViewResponse = [];
  response[0].forEach((filter) => {
    if (filter.data.data.view_names) {
      savedViewResponse = filter.data.data.view_names;
    } else if (filter.data && filter.data.data && filter.data.data) {
      let filterMetricData = filter.data.data;
      if (filterMetricData[0].merch_id) {
        buyerResponse = filterMetricData;
      } else if (filterMetricData[0].shop_descr) {
        shopResponse = filterMetricData;
      } else if (filterMetricData[0].adgroup_name) {
        adgroupResponse = filterMetricData;
      }
    }
  });
  var shopList = [];
  var buyerList = [];
  var adgroupList = [];
  var savedViewList = [];

  var all = { value: "All", label: "All" };
  if (buyerResponse.length > 0) {
    buyerResponse.sort((a, b) => a.merch_id.localeCompare(b.merch_id));
    buyerList = [all];
    buyerResponse.forEach((buyerListArray) => {
      if (buyerListArray.merch_id) {
        buyerList.push({
          value: buyerListArray.merch_id,
          label: buyerListArray.merch_id,
        });
      }
    });
  }
  if (shopResponse.length > 0) {
    function compare(a, b) {
      if (a.shop_descr < b.shop_descr) {
        return -1;
      }
      if (a.shop_descr > b.shop_descr) {
        return 1;
      }
      return 0;
    }

    shopResponse.sort(compare);
    shopList = [all];
    shopResponse.forEach((shopListArray) => {
      if(shopListArray.shop_descr !=''){
        shopList.push({
          value: shopListArray.shop_descr,
          label: shopListArray.shop_descr,
        });
      }
      
    });
  }

  if (adgroupResponse.length > 0) {
    adgroupList = [];
    adgroupResponse.forEach((adgroupListArray) => {
      adgroupList.push({
        value: adgroupListArray.adgroup_id,
        label: adgroupListArray.adgroup_name,
        adgroup_desc: adgroupListArray.adgroup_desc,
      });
    });
  }
  adgroupList.sort((a, b) => a.label.localeCompare(b.label));
  if (savedViewResponse.length > 0) {
    savedViewResponse.forEach((savedView) => {
      savedViewList.push({
        value: savedView,
        label: savedView,
      });
    });
  }
  return { buyerList, shopList, adgroupList, savedViewList };
};
export const getFiscalData = (reqObj) => (dispatch) => {
  return axios({
    url: `${config.baseUrl}/dayLevelOffer/getFiscalData`, // Add API for dayLevelOffer
    method: "POST",
    data: reqObj,
  })
    .then((res) => {
      let response = res.data && res.data.data && res.data.data[0];
      dispatch({
        type: DAYLEVELOFFER_FISCDATA,
        payload: response,
      });
      return res;
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err,
      });
      return err;
    });
};
export const getDayLevelFilters = (reqObj) => (dispatch) => {
  let daylevelfilterAPI = `${config.baseUrl}/dayLevelOffer/dayLevelFilters`;
  // let adGroupAPI = `${config.baseUrl}/dayLevelOffer/getAllAdgroups`;
  let savedViewsAPI = `${config.baseUrl}/dayLevelOffer/getViewNames`;

  const buyerRequest = axios.post(daylevelfilterAPI, reqObj.buyer);
  const shopRequest = axios.post(daylevelfilterAPI, reqObj.shop);
  // const adgroupRequest = axios.post(adGroupAPI, reqObj.adgroup);
  const savedViewRequest = axios.post(savedViewsAPI);

  dispatch({
    type: LOADER,
    payload: true,
  });

  // Promise.all([buyerRequest, shopRequest, adgroupRequest, savedViewRequest])
  Promise.all([buyerRequest, shopRequest, savedViewRequest])
    .then((...responses) => {
      let { buyerList, shopList, savedViewList } =
        getFilterData(responses);

      dispatch({
        type: DAYLEVELOFFER_BUYER,
        payload: buyerList,
      });
      dispatch({
        type: DAYLEVELOFFER_SHOP,
        payload: shopList,
      });
      // dispatch({
      //   type: DAYLEVELOFFER_ADGROUP,
      //   payload: adgroupList,
      // });
      dispatch({
        type: SET_SAVED_VIEWS_NAMES,
        payload: savedViewList,
      });
      dispatch({
        type: LOADER,
        payload: false,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err,
      });
      dispatch({
        type: LOADER,
        payload: false,
      });
    });
};
export const getDayLevelOffers = (reqObject) => async(dispatch) => {
  dispatch({
    type: LOADER,
    payload: true,
  });

  return await axios({
    url: `${config.baseUrl}/dayLevelOffer/fetchDayLevelOffer`,
    method: "POST",
    data: reqObject.offerReq,
  })
    .then((res) => {
      if (reqObject.updateMappedData && reqObject.LYTYrowData) {
        dispatch(saveLyAdgroupData(reqObject));
      }
      dispatch({
        // Reducer for populating Table Data
        type: SET_DLO_TABLE_DATA,
        payload: res.data.data,
      });
      dispatch({
        type: LOADER,
        payload: false,
      });
      return res;
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err,
      });
      dispatch({
        type: LOADER,
        payload: false,
      });
      return err;
    });
};

export const getSavedViewData = (reqObject) => (dispatch) => {
  let shopList = [],
    buyerList = [],
    adgroupList = [],
    allmappedlyty = [];
  dispatch({
    type: LOADER,
    payload: true,
  });
  let resp = axios({
    url: `${config.baseUrl}/dayLevelOffer/getSaveMapAdgroupData`,
    method: "POST",
    data: reqObject.savedViewReq,
  })
    .then((res) => {
      let response = res.data.data && res.data.data.view_data;
      let buyerResponse, shopResponse, adgroupResponse, moreGroups;
      response.forEach((resp) => {
        if (resp.buyer) {
          buyerResponse = resp.buyer;
        } else if (resp.shop_desc) {
          shopResponse = resp.shop_desc;
        } else if (resp.map_adgroups) {
          adgroupResponse = resp.map_adgroups;
        } else if (resp.additionalMetrics) {
          moreGroups = resp.additionalMetrics.map((metric) => {
            return { label: metric, value: metric };
          });
        }
      });

      if (buyerResponse.length > 0) {
        buyerResponse.map((buyerListArray) => {
          buyerList.push({
            value: buyerListArray,
            label: buyerListArray,
          });
          return buyerListArray;
        });
      }
      if (shopResponse.length > 0) {
        function compare(a, b) {
          if (a < b) {
            return -1;
          }
          if (a > b) {
            return 1;
          }
          return 0;
        }

        shopResponse.sort(compare);
        shopResponse.map((shopListArray) => {
          shopList.push({
            value: shopListArray,
            label: shopListArray,
          });
          return shopListArray;
        });
      }
      if (adgroupResponse.length > 0) {
        //adgroupList = [];
        adgroupResponse.map((adgroupListArray) => {
          adgroupList.push({
            value: adgroupListArray.ty_value,
            label: adgroupListArray.ty_adgroup,
            adgroup_desc: adgroupListArray.ty_ad_desc,
          });
          allmappedlyty.push({
            ty_adgroup: adgroupListArray.ty_adgroup,
            ty_ad_desc: adgroupListArray.ty_ad_desc,
            ly_adgroup: adgroupListArray.ly_adgroup,
            ly_ad_desc: adgroupListArray.ly_ad_desc,
            ty_value: adgroupListArray.ty_value,
            ly_value: adgroupListArray.ly_value,
          });
          return adgroupListArray;
        });
      }
      dispatch({
        type: RESET_DAYLEVELOFFER_DATA,
        payload: [],
      });
      dispatch(selectedShopList(shopList, reqObject.filtersReq.shop));
      dispatch(selectedBuyersList(buyerList, reqObject.filtersReq.buyer));
      dispatch(
        selectedAdgroupList(adgroupList, allmappedlyty, {
          adgroupdata: reqObject.filtersReq.adgroup,
        })
      );
      dispatch(moreGroupSelected(moreGroups));
      dispatch({
        type: LOADER,
        payload: false,
      });
      return res.data.status;
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err,
      });
      dispatch({
        type: LOADER,
        payload: false,
      });
    });
  return resp;
};
export const resetDayLevelOffer = () => (dispatch) => {
  dispatch({
    type: RESET_DAYLEVELOFFER_DATA,
    payload: [],
  });
};
export const getLyAdgroupData = (reqObject) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch({
      type: LOADER,
      payload: true,
    });

    axios({
      url: `${config.baseUrl}/dayLevelOffer/getAllAdgroups`,
      method: "POST",
      data: reqObject.input,
    })
      .then((res) => {
        let adgroupResponse = res.data.data;
        console.log("🚀 ~ .then ~ adgroupResponse:", adgroupResponse);
        console.log("false", adgroupResponse.length > 0 && reqObject.input.key === "typed");

        let adgroupList = [];
        let transformedArray = [];

        if (adgroupResponse) {
          transformedArray = adgroupResponse.map((obj) => ({
            label: obj.adgroup_name,
            value: obj.adgroup_id,
            adgroup_desc: obj.adgroup_desc,
          }));
        }

        if (adgroupResponse.length > 0 && reqObject.input.key !== "typed") {
          adgroupList.push(...reqObject.selectedAdgroups);

          adgroupResponse.forEach((adgroupListArray) => {
            let duplicateAdgroups = reqObject.selectedAdgroups.filter((value) => adgroupListArray.adgroup_id === value.value);

            if (!duplicateAdgroups.length) {
              adgroupList.push({
                value: adgroupListArray.adgroup_id,
                label: adgroupListArray.adgroup_name,
                adgroup_desc: adgroupListArray.adgroup_desc,
              });
            }
          });

          adgroupList.sort((a, b) => a.label.localeCompare(b.label));
        }

        dispatch({
          type: TYPED_DAYLEVELOFFER_ADGROUP,
          payload: adgroupResponse.length > 0 && reqObject.input.key === "typed" ? transformedArray : adgroupList,
        });

        dispatch({
          type: LOADER,
          payload: false,
        });

        resolve(adgroupResponse.length > 0 && reqObject.input.key === "typed" ? transformedArray : adgroupList);
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: err,
        });

        dispatch({
          type: LOADER,
          payload: false,
        });

        reject(err);
      });
  });
};

export const saveLyAdgroupData = (reqObject) => (dispatch) => {
  dispatch({
    type: SET_MAPLYTY_DLOFFER_TABLE_DEF,
    payload: reqObject.updateMappedData, // Set Offer Table Data
  });
  dispatch({
    type: SET_MAPPED_LYTY,
    payload: reqObject.updateMappedData, // Only the changed LY-TY
  });
  dispatch({
    type: SET_LYTYROW_DATA,
    payload: reqObject.LYTYrowData, // All LY-TY (adgroups selected by User)
  });
};
export const getSavedViewNames = () => (dispatch) => {
  axios({
    url: `${config.baseUrl}/dayLevelOffer/getViewNames`,
    method: "POST",
  })
    .then((res) => {
      let savedViewList = [];
      let savedViewResponse =
        res && res.data && res.data.data && res.data.data.view_names;
      if (savedViewResponse.length > 0) {
        savedViewResponse.map((savedView) => {
          savedViewList.push({
            value: savedView,
            label: savedView,
          });
          return savedView;
        });
      }
      dispatch({
        type: SET_SAVED_VIEWS_NAMES,
        payload: savedViewList,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err,
      });
    });
};
export const saveCampaignViewName = (reqObject) => (dispatch) => {
  dispatch({
    type: LOADER,
    payload: true,
  });
  let resp = axios({
    url: `${config.baseUrl}/dayLevelOffer/saveMapAdgroupData`,
    method: "POST",
    data: reqObject,
  })
    .then((res) => {
      if (res.data.status) {
        dispatch(getSavedViewNames());
      }
      dispatch({
        type: SAVED_VIEW_STATUS,
        payload: res.data,
      });
      dispatch({
        type: LOADER,
        payload: false,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err,
      });
    });
  return resp;
};
export const selectedBuyersList = (value, optionsArray) => (dispatch) => {
  let shopFlag = false;
  var buyerListArray = [],
    shopListArrayApi = [],
    buyerArrayWithoutAll = [];

  let buyerSelectedValue = [];
  let buyerListForFilter = [];

  value.map((buyerList) => {
    buyerListArray.push(buyerList.value);
    return buyerList;
  });

  let isAllPresentnt = value.some((el) => el.value == "All");
  if (!isAllPresentnt && value.length == optionsArray.length - 1) {
    shopFlag = true;
  } else {
    if (
      !(value.length == optionsArray.length) &&
      !(value.length == 1 && isAllPresentnt)
    ) {
      let indexOfAll = buyerListArray.indexOf("All");

      if (indexOfAll >= 0) {
        buyerListArray.shift(0);
        shopFlag = false;
      }
    }
  }

  if (buyerListArray.includes("All")) {
    shopFlag = true;
    optionsArray.map((item) => {
      if (item.value !== "All") {
        buyerArrayWithoutAll.push(item.value);
      }
      return item;
    });

    buyerSelectedValue = optionsArray;
    buyerListForFilter = buyerArrayWithoutAll;
  } else {
    if (value && value[0] && value[0].value == "All") {
      value.shift(0);
    }

    buyerSelectedValue = value;
    buyerListForFilter = buyerListArray;
  }
  // this.setState({
  //tableData= {};
  // });
  dispatch({
    type: SET_BUYER_LIST,
    payload: {
      buyerSelectedValue: buyerSelectedValue,
      buyerListForFilter: buyerListForFilter,
    },
  });
};
export const selectedShopList = (value, optionsArray) => (dispatch) => {
  var shopListArray = [],
    shopListArrayApi = [],
    shopArrayWithoutAll = [];
  let shopFlag = false;
  let shopSelectedValue = [];
  let shopListForFilter = [];

  value.map((shopList) => {
    shopListArray.push(shopList.value);
    return shopList;
  });

  let isAllPresentnt = value.some((el) => el.value == "All");
  if (!isAllPresentnt && value.length == optionsArray.length - 1) {
    shopFlag = true;
  } else {
    if (
      !(value.length == optionsArray.length) &&
      !(value.length == 1 && isAllPresentnt)
    ) {
      let indexOfAll = shopListArray.indexOf("All");

      if (indexOfAll >= 0) {
        shopListArray.shift(0);
        shopFlag = false;
      }
    }
  }

  if (shopListArray.includes("All")) {
    shopFlag = true;
    optionsArray.map((item) => {
      if (item.value !== "All") {
        shopArrayWithoutAll.push(item.value);
      }
      return item;
    });

    shopSelectedValue = optionsArray;
    shopListForFilter = shopArrayWithoutAll;
  } else {
    if (value && value[0] && value[0].value == "All") {
      value.shift(0);
    }

    shopSelectedValue = value;
    shopListForFilter = shopListArray;
  }
  //this.setState({ tableData: {} });
  dispatch({
    type: SET_SHOP_LIST,
    payload: {
      shopSelectedValue: shopSelectedValue,
      shopListForFilter: shopListForFilter,
    },
  });
};
export const selectedAdgroupList =
  (value, allSavedLYTY, adgroupDL) => (dispatch) => {
    var adgroupListArray = [];
    let tempArray = [];
    let allSavedMapLYTY = allSavedLYTY;
    let adgroupSelectedValue = [];
    let adgroupListForFilter = [],
      rowData = [];
    value.map((adgroupList) => {
      if (allSavedMapLYTY && allSavedMapLYTY.length) {
        allSavedMapLYTY.filter((lyty) => {
          if (lyty.ty_value == adgroupList.value) {
            tempArray.push({
              ty_adgroup: lyty.ty_adgroup,
              ty_value: lyty.ty_value,
              ly_value: lyty.ly_value,
              ly_adgroup: lyty.ly_adgroup,
              ty_ad_desc: lyty.ty_ad_desc,
              ly_ad_desc: lyty.ly_ad_desc,
              adgroupArray: adgroupDL.adgroupdata,
            });
          }
        });
      } else {
        tempArray.push({
          ty_adgroup: adgroupList.label,
          ly_adgroup: adgroupList.label,
          ty_value: adgroupList.value,
          ly_value: adgroupList.value,
          ty_ad_desc: adgroupList.adgroup_desc,
          ly_ad_desc: adgroupList.adgroup_desc,
          adgroupArray: adgroupDL.adgroupdata,
        });
      }
      adgroupListArray.push(adgroupList);
      return adgroupList;
    });

    if (adgroupListArray && adgroupListArray.length) {
      adgroupSelectedValue = value;
      adgroupListForFilter = adgroupListArray;
      rowData = tempArray; // Setting Map LYTY Table rowData.
    } else {
      adgroupSelectedValue = [];
      adgroupListForFilter = [];
      rowData = [];
    }
    // if (adgroupListArray.length > 10) {
    //   adgroupSelectedValue.pop();
    //   adgroupListForFilter.pop();
    //   rowData.pop();
    //   dispatch({
    //     type: SHOW_ADGROUP_ALERT,
    //     payload: true,
    //   });
    //   setTimeout(() => {
    //     dispatch({
    //       type: SHOW_ADGROUP_ALERT,
    //       payload: false,
    //     });
    //   }, 3000);
    // }

    let adgroupList = [];
    adgroupList.push(...adgroupSelectedValue);
    adgroupDL.adgroupdata.map((adgroupListArray) => {
      let duplicateAdgroups = adgroupSelectedValue.filter((value) => {
        if (adgroupListArray.value == value.value) {
          return adgroupListArray;
        }
      });
      if (!duplicateAdgroups.length) {
        adgroupList.push(adgroupListArray);
      }
      adgroupList.sort((a, b) => a.label.localeCompare(b.label));
    });
    dispatch({
      type: SET_ADGROUP_LIST,
      payload: {
        adgroupSelectedValue: adgroupSelectedValue,
        adgroupListForFilter: adgroupListForFilter,
      },
    });

    dispatch({
      type: DAYLEVELOFFER_ADGROUP,
      payload: adgroupList,
    });
    dispatch({
      type: SET_LYTYROW_DATA,
      payload: rowData,
    });

    dispatch({
      type: SET_DLOFFER_TABLE_DEF,
      payload: rowData,
    });

    return rowData;
  };
export const moreGroupSelected = (value, optionsArray) => (dispatch) => {
  let moreGroupFlag = false;
  let moreGroupListArray = [];
  let moreGroupArrayWithoutAll = [];
  let moreGroupSelectedValue = [];
  let moreGroupListForFilter = [];
  value.map((moreGroupList) => {
    moreGroupListArray.push(moreGroupList.value);
    return moreGroupList;
  });
  if (moreGroupListArray.includes("All")) {
    moreGroupFlag = true;
    optionsArray.map((item) => {
      if (item.value !== "All") {
        moreGroupArrayWithoutAll.push(item.value);
      }
      return item;
    });
    moreGroupSelectedValue = optionsArray;
    moreGroupListForFilter = moreGroupArrayWithoutAll;
  } else if (!moreGroupListArray.includes("All") && moreGroupFlag) {
    moreGroupFlag = false;
    moreGroupSelectedValue = [];
    moreGroupListForFilter = [];
  } else {
    moreGroupSelectedValue = value;
    moreGroupListForFilter = moreGroupListArray;
  }
  dispatch({
    type: SET_ADDITIONAL_METRICS,
    payload: {
      moreGroupSelectedValue: moreGroupSelectedValue,
      moreGroupListForFilter: moreGroupListForFilter,
    },
  });
};

export const downloadMasterExportAction = () => (dispatch) => {
  dispatch({
    type: LOADER,
    payload: true,
  });
  return axios({
    url: `${config.baseUrl}/dayLevelOffer/masterDownload`,
    method: "POST",
  })
    .then((res) => {
      dispatch({
        type: LOADER,
        payload: false,
      });
      let file_path = res.data.url;
      window.open(file_path, "_blank");
      return res;
    })
    .catch((err) => {
      dispatch({
        type: LOADER,
        payload: false,
      });
      dispatch({
        type: GET_ERRORS,
        payload: err,
      });
      return err;
    });
};


export const downloadDayLevelAction = (reqObject) => (dispatch) => {
  dispatch({
    type: LOADER,
    payload: true,
  });
  return axios({
    url: `${config.baseUrl}/dayLevelOffer/fetchDayLevelOffer`,
    method: "POST",
    data: reqObject.offerReq,
  })
    .then((res) => {
      dispatch({
        type: LOADER,
        payload: false,
      });
      let file_path = res.data.url;
      window.open(file_path, "_blank");
      return res;
    })
    .catch((err) => {
      dispatch({
        type: LOADER,
        payload: false,
      });
      dispatch({
        type: GET_ERRORS,
        payload: err,
      });
      return err;
    });
};

export const getAdgroupDataRangeWise = payload => async dispatch => {
  dispatch({
    type: LOADER,
    payload: true,
  });

  const response = await axios
    .post(`${config.baseUrl}/dayLevelOffer/getAdgroupDataRangeWise`, payload)
    .then(res => {

      let adgroupResponse = res.data.data;

        let transformedArray = [];

        if (adgroupResponse) {
          transformedArray = adgroupResponse.map((obj) => ({
            label: obj.adgroup_name,
            value: obj.adgroup_id,
            adgroup_desc: obj.adgroup_desc,
          }));
        }

        console.log("transformedArray",transformedArray);

      if(payload.input && payload.input.key === "typed"){
         dispatch({
        type : TYPED_DAYLEVELOFFER_ADGROUP_RANGE_WISE,
        payload: transformedArray
      })
    }

    else{
      dispatch({
        type: DAYLEVELOFFER_ADGROUP_RANGE_WISE,
        payload: res.data,
      });
    }

      dispatch({
        type: LOADER,
        payload: false,
      });
      return res.data;

    })
    .catch(err => {
      dispatch({
        type: LOADER,
        payload: false,
      });
      return err;
    });
  return response;
}

export const updateFiscalDataAction = (data) => (dispatch) => {
  dispatch({
    type: DAYLEVELOFFER_FISCDATA,
    payload: data,
  });
};