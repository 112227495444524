import { SHOP_DATA, LOADER, CLEAR_DATA } from "../actions/types";
const initialState = {
  shopData: [],
  loader: false
};
export default function (state = initialState, action) {
  switch (action.type) {
    case SHOP_DATA:
      return {
        ...state,
        shopData: action.payload
      };
    case CLEAR_DATA:
      return {
        ...state,
        shopData: [],

      };
    default:
      return {
        ...state
      };
  }
}
