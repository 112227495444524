import React from "react";

const PipelineFootnote = props => {
  return (
    <div className="footnotePipeline">
      <div className="card" style={{ height: "60px" }}>
        <p className="footnotSKuList" style={{ paddingLeft: "8px" }}>
          {/* <i
            className="fa fa-exclamation-triangle"
            style={{ paddingRight: "4px" }}
          /> */}
          <b>*</b> - Forecasted values,
          <span className="editable-cells-footnote">
            <span style={{ paddingLeft: "10px" }}>
              &nbsp; - Editable fields,
              <br />
              This table correctly takes into account best deal overlapping logic
            </span>
          </span>
        </p>
      </div>
    </div>
  );
};

export default PipelineFootnote;
