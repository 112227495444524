import React, { Component } from "react";
import CreateMasterAdGroup from "../AdgroupMaster/CreateMasterAdgroup";
import AddAdgroupRow from "./AddAdgroupRow";
import {
  getSKUDetailsFromAdgroupId,
  adgroupListForCreate,
  createAdgroup,
  createBulkAdgroup
} from "../../../../redux/actions/adgroup";
import { createMasterAdgroup } from "../../../../redux/actions/adgroupMaster";
import {
  editReferenceSku,
  infoReferenceSku
} from "../../../../redux/actions/referenceSkuActions";
import { usersLogReport } from "../../../../redux/actions/userAction";
import { saveAdgroup } from "../../../../redux/actions/SimulationAction";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import AlertDanger from "../../../../utils/AlertMessage/alertDanger";
import AlertSuccess from "../../../../utils/AlertMessage/alertSuccess";
import SkuModal from "./SkuModalForAdgroupCreate";
import { getEditDMList } from "../../../../redux/actions/calendarAction";
import { similarSkuProductsList } from "../../../../redux/actions/referenceSkuActions";
import _ from "lodash";
import PageLoader from "../../../../utils/Loader";
import BackToCalendar from "../../../../utils/BackToCalendar";
import queryString from "query-string";
import moment from "moment";
import offerTypeOptions from "../../../../utils/OfferTypes";
import OfferReplaceModal from "../Promo Simulation/OfferReplaceModal";
import Modal from "react-awesome-modal";
import { maxProductCount } from '../../constants';
import { PromotionCalendarGA } from '../../../../utils/GA/GAUtils';
import { alertTimer } from "../../../../utils/alertTimer";

let promotionName = null,
  campaignStartDate = null,
  campaignEndDate = null,
  campaignDetailsForCreateGlobal;

export class AdGroupTab extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      isSaveClicked: false,
      key: "existingAdgrp",
      campaignDetailsForCreate: "",
      showSelectProducts: false,
      showSave: false,
      disableSave: true,
      adgroupName: "",
      adgroupDesc: "",
      adgroupEventId: "",
      adgroupOfferType: "",
      adgroupOfferValue: "",
      adgroupStartDate: "",
      adgroupEndDate: "",
      eventType: "",
      adgroupCampaignId: "",
      productListCreate: "",
      adgroupOptionArray: [],
      showErrorFlag: false,
      showAdgroupSavedMessage: false,
      clearAdGroupName: false,
      showEmptyFieldsMessage: false,
      adgroupsEditedMessage: false,
      clearProductList: false,
      eventTypeOptions: [],
      showSkuModal: false,
      SKUTableData: [],
      skuFootnote: [],
      tableLoader: true,
      adgroupSaved: false,
      showSaveBtnAddFromExisting: false,
      savedRefSkuMsg: false,
      adgroupLoader: false,
      somethingWentWrong: false,
      adgroupExistsMsg: "",
      selectRefSkuError: false,
      showDetailPopup: false,
      refSkuInfoResponse: "",
      refSkuOptions: [],
      refreshRows: false,
      adgroupRowValue: [
        {
          key: 0,
          adgroupName: [],
          adgroupDesc: "",
          selectedDate: [],
          selectedEvent: [],
          startDate: "",
          endDate: "",
          offerType: [],
          offerValue: "",
          fixedPriceQty: "",
          minQtyForOffer: "",
          bonusBuy: "",
          disableRowSave: false
        }
      ],
      fromAdgroupMaster: false,
      alertDanger: false,
      alertDangerMsg: "",
      showOfferReplaceModal: false,
      showOfferReplaceText: "",
      keyStore: null,
      merchId: "",
      saveBeforeClose: false,
      errorMsg: ''
    };
  }

  promoTypeFormatter = value => {
    let promoTypeSet = JSON.parse(localStorage.getItem("promo_type"));
    if (value) {
      return promoTypeSet ? promoTypeSet[value] : value;
    }
  };
  componentDidMount = async () => {
    let queries = queryString.parse(this.props.location.search);
    if (queries.fromAdgroupMaster) {
      this.setState({
        fromAdgroupMaster: true
      });
    }
    var eventTypeOptions = [],
      adgroupDropdownList = [];
    campaignDetailsForCreateGlobal = localStorage.getItem(
      "campaignDetailsForCreate"
    );

    if (!_.isEmpty(campaignDetailsForCreateGlobal)) {
      this.setState({
        campaignDetailsForCreate: campaignDetailsForCreateGlobal
      });

      // let resForAdgroup = await this.props.adgroupListForCreate({
      //   campaignId: parseInt(campaignDetailsForCreateGlobal),
      //   searchKey: "",
      //   h3:
      //     localStorage.getItem("shop") &&
      //     localStorage.getItem("shop") !== "null"
      //       ? localStorage.getItem("shop").split(",")
      //       : [],
      //   h5:
      //     localStorage.getItem("businessDesc") &&
      //     localStorage.getItem("businessDesc") !== "null"
      //       ? localStorage.getItem("businessDesc").split(",")
      //       : [],
      //   h6:
      //     localStorage.getItem("customerGrp") &&
      //     localStorage.getItem("customerGrp") !== "null"
      //       ? localStorage.getItem("customerGrp").split(",")
      //       : []
      // });

      // if (resForAdgroup.status) {
      //   resForAdgroup.data.map(resData => {
      //     adgroupDropdownList.push({
      //       value: resData.name,
      //       label: resData.name,
      //       adgroupId: resData.adgroup_id,
      //       adgroup_desc: resData.description,
      //       productList: resData.product_list
      //     });
      //     return resData;
      //   });

      //   this.setState({
      //     adgroupOptionArray: adgroupDropdownList
      //   });
      // } else {
      //   this.props.history.push("/promotion");
      // }
      if (queries.fromAdgroupMaster) {
        return;
      }
      let res = await this.props.getEditDMList({
        campaignId: campaignDetailsForCreateGlobal
      });

      if (res.status) {
        res.data.all_event_data.map(eventOptions => {
          let promoTypeSet = JSON.parse(localStorage.getItem("promo_type")),
            found = _.filter(res.data, {
              event_type: eventOptions.event_type
            }),
            dateRangeArr = _.map(found, item => {
              return {
                value:
                  item.start_date.slice(0, 10) +
                  "-" +
                  item.end_date.slice(0, 10),
                label:
                  item.start_date.slice(5, 10).replace(/-/g, "/") +
                  "-" +
                  item.end_date.slice(5, 10).replace(/-/g, "/")
              };
            });
          let eventLabel = this.promoTypeFormatter(eventOptions.event_type);
          eventTypeOptions.push({
            value:
              eventLabel +
              "-" +
              moment(eventOptions.start_date).format("MM/DD/YYYY") +
              "-" +
              moment(eventOptions.end_date).format("MM/DD/YYYY"),

            // label: promoTypeSet
            //   ? promoTypeSet[eventOptions.event_type]
            //   : eventOptions.event_type,
            label:
              eventLabel +
              "-" +
              moment(eventOptions.start_date).format("MM/DD/YYYY") +
              "-" +
              moment(eventOptions.end_date).format("MM/DD/YYYY"),

            eventId: eventOptions.event_id,
            event_type: eventOptions.event_type,
            dateRange: dateRangeArr,
            start_date: eventOptions.start_date,
            end_date: eventOptions.end_date
          });
          return eventOptions;
        });
        this.setState({
          eventTypeOptions: eventTypeOptions // _.uniqBy(eventTypeOptions, "value")
        });
      }
    }

    let refSkuSearchRes = await this.props.similarSkuProductsList({
      modelable_sku: true,
      search_key: ""
    });
    let list = _.map(refSkuSearchRes.data, item => {
      return {
        value: item.unique_id1,
        label: item.unique_id1
      };
    });
    list.unshift({ value: "none", label: "none" });
    this.setState({
      refSkuOptions: list,
      fromAdgroupMaster: queries.fromAdgroupMaster ? true : false
    });
  };

  componentWillMount = () => {
    promotionName = localStorage.getItem("campaignName");
    campaignStartDate = localStorage.getItem("campStartDate");
    campaignEndDate = localStorage.getItem("campEndDate");
  };
  componentWillReceiveProps(nextProps) {
    if (nextProps.clearAdGroupName === true) {
      this.setState({
        clearAdGroupName: true
      });

      setTimeout(() => {
        this.setState({
          clearAdGroupName: false
        });
      }, 100);
    }

    if (nextProps.clearProductList === true) {
      this.setState({
        clearProductList: true
      });

      setTimeout(() => {
        this.setState({
          clearProductList: false
        });
      }, 200);
    }
  }
  merchIdHandler = merchId => {
    this.setState({
      merchId
    });
  };
  adgroupKeyDownHandler = async val => {
    if (val.length) {
      let adgroupDropdownList = [];

      let resForAdgroup = await this.props.adgroupListForCreate({
        campaignId: parseInt(campaignDetailsForCreateGlobal),
        searchKey: val,
        h3:
          localStorage.getItem("shop") &&
            localStorage.getItem("shop") !== "null"
            ? localStorage.getItem("shop").split(",")
            : [],
        h5:
          localStorage.getItem("businessDesc") &&
            localStorage.getItem("businessDesc") !== "null"
            ? localStorage.getItem("businessDesc").split(",")
            : [],
        h6:
          localStorage.getItem("customerGrp") &&
            localStorage.getItem("customerGrp") !== "null"
            ? localStorage.getItem("customerGrp").split(",")
            : []
      });

      if (resForAdgroup.status) {
        resForAdgroup.data.map(resData => {
          adgroupDropdownList.push({
            value: resData.name,
            label: resData.name,
            adgroupId: resData.adgroup_id,
            adgroup_desc: resData.description,
            productList: resData.product_list
          });
          return resData;
        });

        this.setState({
          adgroupOptionArray: adgroupDropdownList
        });
      } else {
        this.props.history.push("/promotion");
      }
    }
  };
  showSelectProducts = e => {
    this.setState({
      showSelectProducts: !this.state.showSelectProducts
    });
  };

  saveAdgroupValues = async () => {
    this.setState({
      adgroupLoader: true
    });

    if (this.state.fromAdgroupMaster) {
      let res = await this.props.createMasterAdgroup({
        name: this.state.adgroupName,
        productList: this.state.productListCreate,
        adgroupDesc: this.state.adgroupDesc,
        merchId: this.state.merchId ? this.state.merchId.value : null
      });
      if (res.status) {
        this.setState({
          adgroupSaved: true,
          productListCreate: [],
          showAdgroupSavedMessage: true,
          adgroupLoader: false
        });

        setTimeout(() => {
          this.setState({
            showAdgroupSavedMessage: false
          });
        }, alertTimer);
      } else {
        this.setState({
          somethingWentWrong: true,
          adgroupLoader: false,
          adgroupExistsMsg: res.msg
        });

        setTimeout(() => {
          this.setState({ somethingWentWrong: false });
        }, alertTimer);
      }
      return;
    }
    if (!_.isEmpty(this.state.productListCreate) && this.state.productListCreate.length <= maxProductCount) {
      let createAdgroupResponse = await this.props.createAdgroup({
        adgroupName: this.state.adgroupName,
        adgroupDesc: this.state.adgroupDesc,
        productList: this.state.productListCreate,
        eventId: this.state.adgroupEventId,
        campaignId: parseInt(this.state.adgroupCampaignId),
        offerValue: parseFloat(this.state.adgroupOfferValue),
        offerType: this.state.adgroupOfferType,
        startDate: this.state.adgroupStartDate,
        endDate: this.state.adgroupEndDate,
        eventType: this.state.eventType,
        createNewAdgroup: true
      });

      if (createAdgroupResponse.status) {
        this.setState({
          adgroupSaved: true,
          productListCreate: [],
          showAdgroupSavedMessage: true,
          adgroupLoader: false
        });

        setTimeout(() => {
          this.setState({
            showAdgroupSavedMessage: false
          });
        }, alertTimer);
      } else {
        this.setState({
          somethingWentWrong: true,
          adgroupLoader: false,
          adgroupExistsMsg: "Something went wrong"
        });

        setTimeout(() => {
          this.setState({ somethingWentWrong: false });
        }, alertTimer);
      }
    } else {

      let errorMsg = _.isEmpty(this.state.productListCreate) ? "Please select product list!" : `Number of selected products should be less than ${maxProductCount} `
      this.setState({
        showErrorFlag: true,
        errorMsg
      });

      setTimeout(() => {
        this.setState({ showErrorFlag: false, errorMsg: '' });
      }, alertTimer);
    }
  };

  saveAdgroupValuesAddFromExisting = async (key, replace) => {

    let { adgroupRowValue } = this.state,
      campaignDetailsForCreate = localStorage.getItem(
        "campaignDetailsForCreate"
      );
    let selectedDateValidity = moment(adgroupRowValue[key] && adgroupRowValue[key].selectedEvent && adgroupRowValue[key].selectedEvent[0] && adgroupRowValue[key].selectedEvent[0].start_date).isAfter(moment())
    let adgroupRow = _.cloneDeep(adgroupRowValue);
    let adgroupRowForApi = JSON.parse(JSON.stringify(adgroupRowValue));

    let adgroupRowFound = _.find(adgroupRow, { key: key });

    let fieldName =
      offerTypeOptions.fieldValidation1[adgroupRowFound.offerType.value];

    // return;
    if (fieldName) {
      let findEmptyFields = _.find(fieldName, field => {
        return (
          !adgroupRowFound[field] ||
          adgroupRowFound[field] === "" ||
          adgroupRowFound[field] === 0
        );
      });
      if (!_.isEmpty(findEmptyFields)) {
        this.setState({
          alertDanger: true,
          alertDangerMsg: "Please enter all the fields"
        });
        setTimeout(() => {
          this.setState({ alertDanger: false, alertDangerMsg: "" });
        }, alertTimer);
        return;
      }
    } else {
      //Check if values is entered or not
      this.setState({
        alertDanger: true,
        alertDangerMsg: "Please select offer for selected Adgroup"
      });
      setTimeout(() => {
        this.setState({ alertDanger: false, alertDangerMsg: "" });
      }, alertTimer);
      return;
    }
    if (
      ((adgroupRowFound.offerType.value === "G" ||
        adgroupRowFound.offerType.value === "C") &&
        (adgroupRowFound.minQtyForOffer < 1 || adgroupRowFound.bonusBuy < 1)) ||
      (adgroupRowFound.offerType.value === "B" &&
        (adgroupRowFound.minQtyForOffer < 1 || adgroupRowFound.bonusBuy < 1)) ||
      (adgroupRowFound.offerType.value === "D" &&
        adgroupRowFound.minQtyForOffer < 1) ||
      ((adgroupRowFound.offerType.value === "F" || adgroupRowFound.offerType.value == "P") &&
        adgroupRowFound.offerValue < 1)
    ) {
      //Check for minimum values in fields
      this.setState({
        alertDanger: true,
        alertDangerMsg: "Please enter minimum value"
      });
      setTimeout(() => {
        this.setState({ alertDanger: false, alertDangerMsg: "" });
      }, alertTimer);
      return;
    }
    if (
      ((adgroupRowFound.offerType.value === "G" ||
        adgroupRowFound.offerType.value === "C" ||
        adgroupRowFound.offerType.value === "B" ||
        adgroupRowFound.offerType.value === "D") &&
        (adgroupRowFound.minQtyForOffer % 1 !== 0 ||
          adgroupRowFound.bonusBuy % 1 !== 0)) ||
      (adgroupRowFound.offerType.value === "F" &&
        adgroupRowFound.fixedPriceQty % 1 !== 0) ||
      (adgroupRowFound.offerType.value === "P" &&
        adgroupRowFound.offerValue % 1 !== 0)
    ) {
      //Check for integer or decimal values

      this.setState({
        alertDanger: true,
        alertDangerMsg: "Entered fields cannot contain decimals"
      });
      setTimeout(() => {
        this.setState({ alertDanger: false, alertDangerMsg: "" });
      }, alertTimer);
      return;
    }

    this.setState({
      adgroupLoader: true
    });

    let selectedAdgroupSkus = {};
    selectedAdgroupSkus[adgroupRowFound.adgroupName.label] =
      adgroupRowFound.adgroupName.productList;

    let obj = {
      selectedAdgroupSkus: selectedAdgroupSkus,
      cluster: null,
      startDate: moment(adgroupRowFound.selectedEvent[0].start_date).format(
        "YYYY-MM-DD"
      ),
      endDate: moment(adgroupRowFound.selectedEvent[0].end_date).format(
        "YYYY-MM-DD"
      ),
      fiscalYear: "2019-01-01",
      preSaveOfferType:
        adgroupRowFound.fixedPriceQty > 1
          ? "nForM"
          : adgroupRowFound.offerType.value,
      preSaveOfferValue: adgroupRowFound.offerValue
        ? parseFloat(adgroupRowFound.offerValue)
        : 0,
      campaignId: parseInt(campaignDetailsForCreate),
      campaignStartDate: moment(campaignStartDate).format("YYYY-MM-DD"),
      campaignEndDate: moment(campaignEndDate).format("YYYY-MM-DD"),
      adgroupId: adgroupRowFound.adgroupName.adgroupId,
      promoId: null,
      index: 0,
      promoType: adgroupRowFound.selectedEvent[0].event_type.toLowerCase(),
      commentText: "",
      override_comment: "",
      updateFlag: 1,
      quantity_forecast_override: null, //"",
      revenue_forecast_override: null, //"",
      margin_forecast_override: null, //"",
      gm_override: null, //null,//"",
      bonusbyquantity: adgroupRowFound.bonusBuy
        ? parseFloat(adgroupRowFound.bonusBuy)
        : 0,
      minquantity: adgroupRowFound.minQtyForOffer
        ? parseFloat(adgroupRowFound.minQtyForOffer)
        : 0,
      fixedpricequantity: adgroupRowFound.fixedPriceQty
        ? parseFloat(adgroupRowFound.fixedPriceQty)
        : 0,
      offerquantity: 0,
      replace: replace ? true : false,
      eventId: adgroupRowFound.selectedEvent[0].eventId
    };

    if (selectedDateValidity) {
      this.props.usersLogReport({ screen: "add_new_offer", action: "save", userId: localStorage.getItem("email") });

      //GA 
      this.submitGA("Click", "Clicked_AddOffer_SaveButton", 1);

      let res = await this.props.saveAdgroup(obj);

      this.setState({
        adgroupLoader: false
      });
      if (res.status) {
        let found = _.find(adgroupRowValue, { key: key });
        found.disableRowSave = true;

        this.setState({
          showOfferReplaceModal: false,
          adgroupsEditedMessage: true,
          adgroupRowValue
        });
        setTimeout(() => {
          this.setState({ adgroupsEditedMessage: false });
        }, alertTimer);
      } else if (res.isAxiosError) {
        this.setState({
          somethingWentWrong: true,
          adgroupExistsMsg: "Something went wrong! Please try again"
        });
        setTimeout(() => {
          this.setState({ somethingWentWrong: false, adgroupExistsMsg: "" });
        }, alertTimer);
      } else {
        this.setState({
          keyStore: key,
          showOfferReplaceModal: true,
          showOfferReplaceText: res.msg
        });
      }
    } else {
      this.setState({
        adgroupLoader: false,
        notFutureEvent: true,
        adgroupName: adgroupRowFound.adgroupName.label,
        adGroupStartDate: adgroupRowFound.selectedEvent[0].start_date
      })
      setTimeout(() => {
        this.setState({
          notFutureEvent: false,
        });
      }, alertTimer);
    }
  };

  createAdgroupData = productList => {
    this.setState({
      productListCreate: productList
    });
    if (productList.length) {
      this.setState({
        showSave: true
      });
    } else {
      this.setState({
        showSave: false
      });
    }
  };

  closeModal = () => {
    this.setState({
      showOfferReplaceModal: !this.state.showOfferReplaceModal
    });
  };
  addRows = () => {
    let { adgroupRowValue } = this.state;
    adgroupRowValue.push({
      key: adgroupRowValue[adgroupRowValue.length - 1].key + 1,
      adgroupName: [],
      adgroupDesc: "",
      selectedDate: [],
      selectedEvent: [],
      startDate: "",
      endDate: "",
      offerType: [],
      offerValue: "",
      fixedPriceQty: "",
      minQtyForOffer: "",
      bonusBuy: "",
      productList: []
    });

    this.setState({ adgroupRowValue });
  };

  rowValueUpdateHandler = (keyNum, name, val, value) => {
    let { adgroupRowValue } = this.state,
      found = _.find(adgroupRowValue, { key: keyNum });

    if (name === "adgroupName") {
      this.setState({
        SKUTableData: true
      });
      found.adgroupName = val;
      found.adgroupDesc = val.adgroup_desc;
    } else if (name === "DM") {
      found.selectedDate = value;
      //found.selectedEvent = [];
      found.startDate = val.slice(0, 10);
      found.endDate = val.slice(11, 21);
    } else if (name === "eventType") {
      found.selectedEvent = [value];
      found.selectedDate = [];
    } else if (name === "offerType") {
      found.offerType = val;
      found.offerValue = "";
      found.fixedPriceQty = "";
      found.minQtyForOffer = "";
      found.bonusBuy = "";
      this.setState({
        showSaveBtnAddFromExisting: true
      });
    } else if (name === "offerValue") {
      let type = found.offerType.value;
      if ((type === "P" || type === "B" || type === "D") && val > 99) return;
      else if ((type === "F" || type === "A" || type === "C") && val > 99999)
        return;
      found.offerValue = val;
    } else if (name === "fixedPriceQty") {
      if (val > 99) return;
      found.fixedPriceQty = val;
    } else if (name === "minQtyForOffer") {
      if (val > 99) return;
      found.minQtyForOffer = val;
    } else if (name === "bonusBuy") {
      if (val > 99) return;
      found.bonusBuy = val;
    }
    this.setState({ adgroupRowValue });
  };

  saveRefSkuDetail = async rowData => {
    let params = null;
    if (Object.keys(rowData).length) {
      this.setState({
        adgroupLoader: true
      });
      params = {
        skuId: rowData.unique_id1,
        referenceSku: rowData.selected_sku === "none" ? null : rowData.selected_sku
      };
      let saveRefSkuResponse = await this.props.editReferenceSku(params);

      if (saveRefSkuResponse.status) {
        this.setState({
          adgroupLoader: false,
          savedRefSkuMsg: true
        });

        setTimeout(() => {
          this.setState({
            savedRefSkuMsg: false
          });
        }, alertTimer);
        this.SKUListHandler(rowData.adgroupId);
      }
    } else {
      this.setState({
        selectRefSkuError: true
      });

      setTimeout(() => {
        this.setState({
          selectRefSkuError: false
        });
      }, alertTimer);
    }
  };

  refSkuSearchHandler = async (key, id) => {
    let { SKUTableData } = this.state;
    let refSkuSearchRes = await this.props.similarSkuProductsList({
      modelable_sku: true,
      search_key: key
    });
    let list = _.map(refSkuSearchRes.data, item => {
      return {
        value: item.unique_id1,
        label: item.unique_id1
      };
    });
    list.unshift({ value: "none", label: "none" });

    let found = _.find(SKUTableData, { unique_id1: id });
    found.refSkuOptions = list;

    this.setState({ refSkuOptions: list, refreshRows: true, SKUTableData });
  };

  refreshRowsHandler = () => {
    this.setState({ refreshRows: false });
  };

  SKUListHandler = async adgroupId => {
    let skuFootnote = [];
    this.setState({ showSkuModal: true, tableLoader: true });
    let res = await this.props.getSKUDetailsFromAdgroupId({
      adgroupId: adgroupId
    });

    if (res.status) {
      if (res.data[0].footnote != null) {
        skuFootnote.push(res.data[0].footnote);
      }

      this.setState({
        skuFootnote
      });

      res.data.map(item => {
        item.saveRefSkuDetail = this.saveRefSkuDetail;
        item.refSkuSearchHandler = this.refSkuSearchHandler;
        item.refSkuOptions = this.state.refSkuOptions;
        item.original_reference_sku = item.reference_sku;
        item.adgroupId = adgroupId;
        return item;
      });
    } else {
      this.props.history.push("/promotion");
    }

    this.setState({ SKUTableData: res.data, tableLoader: false });
  };

  receiveAdgroupDetails = (
    name,
    desc,
    eventType,
    eventId,
    offerType,
    offerValue,
    campaignDetail,
    selectedDate
  ) => {
    this.setState({
      adgroupName: name,
      adgroupStartDate: selectedDate ? selectedDate.value.slice(0, 10) : "",
      adgroupEndDate: selectedDate ? selectedDate.value.slice(11, 21) : "",
      eventType: eventType,
      adgroupDesc: desc,
      adgroupEventId: eventId,
      adgroupOfferType: offerType,
      adgroupOfferValue: offerValue,
      adgroupCampaignId: campaignDetail
    });
  };
  receiveAdgroupName = name => {
    this.setState({
      adgroupName: name
    });
  };
  receiveAdgroupDesc = desc => {
    this.setState({
      adgroupDesc: desc
    });
  };
  receiveEventType = eventType => {
    this.setState({
      eventType: eventType
    });
  };
  receiveSelectedDate = (selectedDate, eventId) => {
    this.setState({
      adgroupStartDate: selectedDate.value.slice(0, 10),
      adgroupEndDate: selectedDate.value.slice(11, 21),
      adgroupEventId: eventId
    });
  };
  receiveOfferType = offerType => {
    this.setState({
      adgroupOfferType: offerType
    });
  };

  receiveOfferValue = offerVal => {
    this.setState({
      adgroupOfferValue: offerVal
    });
  };
  submitGA(action, label, value) {
    PromotionCalendarGA({ action, label, value });
  }
  render() { //
    return (
      <div>
        <div className="alertDivs">
          {this.state.showErrorFlag ? (
            <div>
              <AlertDanger
                message={this.state.errorMsg}
                show={this.state.showErrorFlag}
              />
            </div>
          ) : null}

          {this.state.alertDanger ? (
            <AlertDanger
              message={this.state.alertDangerMsg}
              show={this.state.alertDanger}
            />
          ) : null}
          {this.state.showAdgroupSavedMessage ? (
            <div>
              <AlertSuccess
                message="Adgroup saved successfully!"
                show={this.state.showAdgroupSavedMessage}
              />
            </div>
          ) : null}

          {this.state.notFutureEvent ? (
            <AlertDanger
              message={`The Adroup ${this.state.adgroupName} whose Event Type ${this.state.adGroupStartDate} is of a past date,  
                  Please try again with different Event type`}
              show={this.state.notFutureEvent}
            />
          ) : null}

          {this.state.adgroupsEditedMessage ? (
            <div>
              <AlertSuccess
                message="Adgroups saved successfully!"
                show={this.state.adgroupsEditedMessage}
              />
            </div>
          ) : null}

          {this.state.savedRefSkuMsg ? (
            <div>
              <AlertSuccess
                message="Reference SKU details saved!"
                show={this.state.savedRefSkuMsg}
              />
            </div>
          ) : null}

          {this.state.showEmptyFieldsMessage ? (
            <div>
              <AlertDanger
                message="Please make selection for all fields!"
                show={this.state.showEmptyFieldsMessage}
              />
            </div>
          ) : null}
          {this.state.somethingWentWrong ? (
            <div>
              <AlertDanger
                message={`${this.state.adgroupExistsMsg}`}
                show={this.state.somethingWentWrong}
              />
            </div>
          ) : null}

          {this.state.selectRefSkuError ? (
            <div>
              <AlertDanger
                message="Please select Reference SKU to proceed"
                show={this.state.selectRefSkuError}
              />
            </div>
          ) : null}
        </div>

        <PageLoader loader={this.state.adgroupLoader || this.props.loader}>
          <div className="card cardStyles cardGap">
            <div id="cardbox1" style={{ padding: "1px 12px 10px 20px" }}>
              <div className="createClusterDiv">
                <div className="row pl-3">
                  {this.state.fromAdgroupMaster ? (
                    <div className="row col-md-3">
                      <i
                        className="fa fa-angle-double-left double-left-icon"
                        onClick={() =>
                          this.props.history.push("/adgroupMaster")
                        }
                        aria-hidden="true"
                      ></i>
                      <p
                        id="backToCalLink"
                        onClick={() =>
                          this.props.history.push("/adgroupMaster")
                        }
                      >
                        Back to Adgroup Master
                      </p>
                    </div>
                  ) : (
                    <BackToCalendar history={this.props.history} />
                  )}

                  <div className="col-md-6">
                    {" "}
                    <h4 className="promoHeading">
                      {this.state.fromAdgroupMaster
                        ? "Create New AdGroup"
                        : "Add New Offer"}
                    </h4>
                  </div>
                </div>

                {!this.state.fromAdgroupMaster ? (
                  <div className="row">
                    <div className="col-md-8">
                      <div className="row" style={{ marginLeft: "12px" }}>
                        <div className="col-md-4">
                          <span className="promoNameText row">
                            Promotion Name:{" "}
                            <p className="promonameResult">{promotionName}</p>
                          </span>
                        </div>
                        <div className="col-md-3">
                          <span
                            className="promoNameText row"
                            style={{ paddingLeft: "2%" }}
                          >
                            Start Date:
                            <p className="promonameResult">
                              {moment(campaignStartDate).format("MM/DD/YYYY")}
                            </p>
                          </span>
                        </div>
                        <div className="col-md-3">
                          <span
                            className="promoNameText row"
                            style={{ paddingLeft: "2%" }}
                          >
                            End Date:
                            <p className="promonameResult">
                              {moment(campaignEndDate).format("MM/DD/YYYY")}
                            </p>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
                {!this.state.fromAdgroupMaster ? (
                  <>
                    {this.state.adgroupRowValue.map((rowItem, index) => (
                      <>
                        <AddAdgroupRow
                          row={rowItem}
                          adgroupOptionArray={this.state.adgroupOptionArray}
                          rowLength={this.state.adgroupRowValue.length}
                          saveAdgroupValues={
                            this.saveAdgroupValuesAddFromExisting
                          }
                          index={index}
                          addRows={this.addRows}
                          deleteRows={this.deleteRows}
                          rowValueUpdateHandler={this.rowValueUpdateHandler}
                          SKUListHandler={this.SKUListHandler}
                          eventTypeOptions={this.state.eventTypeOptions}
                          adgroupKeyDownHandler={this.adgroupKeyDownHandler}
                        />
                        {index < this.state.adgroupRowValue.length - 1 ? (
                          <hr />
                        ) : null}
                      </>
                    ))}

                    {this.state.showSaveBtnAddFromExisting ? (
                      <div className="selectProductsBtn text-center">
                        <button
                          type="button"
                          className="btn btnSaveProducts"
                          onClick={() => {
                            this.addRows();
                          }}
                        >
                          Add row
                        </button>
                      </div>
                    ) : (
                      <div />
                    )}
                  </>
                ) : (
                  // <Tabs
                  //   id="controlled-tab-example"
                  //   activeKey={this.state.key}
                  //   onSelect={key => this.setState({ key })}
                  //   style={{ overflowY: "hidden" }}
                  // >
                  //   <Tab
                  //     eventKey="existingAdgrp"
                  //     title="Add from Existing AdGroup"
                  //   >

                  //   </Tab>

                  //  <Tab eventKey="createNew" title="Create New AdGroup">
                  //   <CreateAdGroup
                  //     showErrorMessage={() => {
                  //       this.showErrorMessage();
                  //     }}
                  //     // hideCreateAdGroup={adgroupName => {
                  //     //   this.hideCreateAdGroup(adgroupName);
                  //     // }}
                  //     createAdGroupValue={productList => {
                  //       this.createAdgroupData(productList);
                  //     }}
                  //     sendAdGroupValuesCreate={name => {
                  //       this.receiveAdGroupCreateValues(name);
                  //     }}
                  //     clearAdGroupName={this.state.clearAdGroupName}
                  //     clearProductList={this.state.clearProductList}
                  //     sendAdgroupDetails={(
                  //       name,
                  //       desc,
                  //       eventType,
                  //       eventId,
                  //       offerType,
                  //       offerValue,
                  //       campaignDetailsForCreate,
                  //       selectedDate
                  //     ) => {
                  //       this.receiveAdgroupDetails(
                  //         name,
                  //         desc,
                  //         eventType,
                  //         eventId,
                  //         offerType,
                  //         offerValue,
                  //         campaignDetailsForCreate,
                  //         selectedDate
                  //       );
                  //     }}
                  //     sendAdgroupName={name => {
                  //       this.receiveAdgroupName(name);
                  //     }}
                  //     sendAdgroupDesc={desc => {
                  //       this.receiveAdgroupDesc(desc);
                  //     }}
                  //     sendEventType={eventType => {
                  //       this.receiveEventType(eventType);
                  //     }}
                  //     sendSelectedDate={(date, eventId) => {
                  //       this.receiveSelectedDate(date, eventId);
                  //     }}
                  //     sendOfferType={offerType => {
                  //       this.receiveOfferType(offerType);
                  //     }}
                  //     sendOfferValue={offerVal => {
                  //       this.receiveOfferValue(offerVal);
                  //     }}
                  //     couponSaved={this.state.adgroupSaved}
                  //     couponSavedHandler={() =>
                  //       this.setState({ adgroupSaved: false })
                  //     }
                  //   />
                  // </Tab> */}

                  // </Tabs>
                  <CreateMasterAdGroup
                    showErrorMessage={() => {
                      this.showErrorMessage();
                    }}
                    hideCreateAdGroup={adgroupName => {
                      this.hideCreateAdGroup(adgroupName);
                    }}
                    createAdGroupValue={productList => {
                      this.createAdgroupData(productList);
                    }}
                    sendAdGroupValuesCreate={name => {
                      this.receiveAdGroupCreateValues(name);
                    }}
                    clearAdGroupName={this.state.clearAdGroupName}
                    clearProductList={this.state.clearProductList}
                    sendAdgroupDetails={(
                      name,
                      desc,
                      eventType,
                      eventId,
                      offerType,
                      offerValue,
                      campaignDetailsForCreate,
                      selectedDate
                    ) => {
                      this.receiveAdgroupDetails(
                        name,
                        desc,
                        eventType,
                        eventId,
                        offerType,
                        offerValue,
                        campaignDetailsForCreate,
                        selectedDate
                      );
                    }}
                    sendAdgroupName={name => {
                      this.receiveAdgroupName(name);
                    }}
                    sendAdgroupDesc={desc => {
                      this.receiveAdgroupDesc(desc);
                    }}
                    sendEventType={eventType => {
                      this.receiveEventType(eventType);
                    }}
                    sendSelectedDate={(date, eventId) => {
                      this.receiveSelectedDate(date, eventId);
                    }}
                    sendOfferType={offerType => {
                      this.receiveOfferType(offerType);
                    }}
                    sendOfferValue={offerVal => {
                      this.receiveOfferValue(offerVal);
                    }}
                    couponSaved={this.state.adgroupSaved}
                    couponSavedHandler={() =>
                      this.setState({ adgroupSaved: false })
                    }
                    merchIdHandler={this.merchIdHandler}
                    merchId={this.state.merchId}
                  />
                )}
                {/* {this.state.showSave || this.state.fromAdgroupMaster ? (
                  <div className="selectProductsBtn text-center">
                    <button
                      type="button"
                      className="btn btnSaveProducts"
                      disabled={
                        this.state.fromAdgroupMaster &&
                        _.isEmpty(this.state.adgroupName)
                          ? true
                          : false
                      }
                      onClick={() => {
                        this.saveAdgroupValues();
                        // Master save button
                      }}
                    >
                      Save
                    </button>
                  </div>
                ) : (
                  <div />
                )} */}
              </div>
            </div>
          </div>
        </PageLoader>

        {this.state.showOfferReplaceModal ? (
          <OfferReplaceModal
            closeModal={this.closeModal}
            onReplaceOffer={() => {
              this.closeModal();
              this.saveAdgroupValuesAddFromExisting(this.state.keyStore, true);
            }}
            showOfferReplaceText={this.state.showOfferReplaceText}
          />
        ) : null}
        {this.state.showSkuModal ? (
          <SkuModal
            tableData={this.state.SKUTableData}
            refreshRows={this.state.refreshRows}
            refreshRowsHandler={this.refreshRowsHandler}
            closeSkuModal={() => {
              var refSkuNotSaved = _.find(this.state.SKUTableData, {
                sku_saved: false
              });
              if (refSkuNotSaved) {
                this.setState({ saveBeforeClose: true });
              } else {
                this.setState({
                  showSkuModal: false,
                  showDetailPopup: false,
                  saveBeforeClose: false
                });
              }
            }}
            closeDetailPopup={() => this.setState({ showDetailPopup: false })}
            loaderAg={this.state.tableLoader}
            footnote={this.state.skuFootnote}
            showDetailPopup={this.state.showDetailPopup}
            refSkuInfoResponse={this.state.refSkuInfoResponse}
          />
        ) : null}

        {this.state.saveBeforeClose ? (
          <Modal
            visible={true}
            width="400px"
            height="200px"
            effect="fadeInDown"
          >
            <h5 className="delete-confirmatn-modal text-left mt-3">
              There are some unsaved changes. Do you wish to continue?
            </h5>
            <div className="text-center delete-btns">
              <button
                className="btn btn-outline-secondary"
                onClick={() => {
                  this.setState({ saveBeforeClose: false });
                }}
              >
                No
              </button>
              <button
                className="btn btn-outline-secondary ml-3"
                onClick={() =>
                  this.setState({
                    showSkuModal: false,
                    showDetailPopup: false,
                    saveBeforeClose: false
                  })
                }
              >
                Yes
              </button>
            </div>
          </Modal>
        ) : null}
      </div>
    );
  }
}

AdGroupTab.propTypes = {
  createBulkAdgroup: PropTypes.func.isRequired,
  createAdgroup: PropTypes.func.isRequired,
  getSKUDetailsFromAdgroupId: PropTypes.func.isRequired,
  getEditDMList: PropTypes.func.isRequired,
  adgroupListForCreate: PropTypes.func.isRequired,
  similarSkuProductsList: PropTypes.func.isRequired,
  editReferenceSku: PropTypes.func.isRequired,
  infoReferenceSku: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  getAdGroupData: state.adGroupData.getAdGroupData,
  loader: state.adGroupData.loader,
  errors: state.errors
});

export default connect(mapStateToProps, {
  createAdgroup,
  createBulkAdgroup,
  similarSkuProductsList,
  getSKUDetailsFromAdgroupId,
  getEditDMList,
  editReferenceSku,
  infoReferenceSku,
  adgroupListForCreate,
  createMasterAdgroup,
  saveAdgroup,
  usersLogReport
})(withRouter(AdGroupTab));
