import axios from "../../utils/axios/index";
import { config } from "../../utils/config";
import {
  LOADER,
  SET_PROMO_APPROVAL_DATA,
  UPDATE_PROMO_APPROVAL_DATA
} from "./types";

export const getBuyerDetails = payload => async dispatch => {
  dispatch({
    type: LOADER,
    payload: true,
  });

  const response = await axios
    .post(`${config.baseUrl}/promo/getMerchantDetails`, payload)
    .then(res => {

      dispatch({
        type: LOADER,
        payload: false,
      });
      return res.data;
    })
    .catch(err => {
      dispatch({
        type: LOADER,
        payload: false,
      });
      return err;
    });
  return response;
}

export const getCampaignDetails = payload => async dispatch => {
  dispatch({
    type: LOADER,
    payload: true,
  });

  const response = await axios
    .post(`${config.baseUrl}/promo/getCampaignDetails`, payload)
    .then(res => {

      dispatch({
        type: LOADER,
        payload: false,
      });
      return res.data;
    })
    .catch(err => {
      dispatch({
        type: LOADER,
        payload: false,
      });
      return err;
    });
  return response;
}

export const getPromoApprovalData = payload => async dispatch => {
  dispatch({
    type: LOADER,
    payload: true,
  });

  const response = await axios
    .post(`${config.baseUrl}/promoApproval/fetch`, payload)
    .then(res => {

      dispatch({
        type: SET_PROMO_APPROVAL_DATA,
        payload: res.data.data
      })
      dispatch({
        type: LOADER,
        payload: false,
      });
      return res.data;
    })
    .catch(err => {
      dispatch({
        type: LOADER,
        payload: false,
      });
      return err;
    });
  return response;
}

export const updatePromoApprovalStatus = payload => async dispatch => {
  dispatch({
    type: LOADER,
    payload: true,
  });
  
  const response = await axios
    .post(`${config.baseUrl}/promoApproval/status`, payload)
    .then(res => {

      dispatch({
        type: LOADER,
        payload: false,
      });
      return res.data;
    })
    .catch(err => {
      dispatch({
        type: LOADER,
        payload: false,
      });
      return err;
    });
  return response;
}

export const updateNotes = payload => async dispatch => {
  dispatch({
    type: LOADER,
    payload: true,
  });
  
  const response = await axios
    .post(`${config.baseUrl}/promoApproval/note`, payload)
    .then(res => {

      dispatch({
        type: LOADER,
        payload: false,
      });
      return res.data;
    })
    .catch(err => {
      dispatch({
        type: LOADER,
        payload: false,
      });
      return err;
    });
  return response;
}

export const downloadReport = payload => async dispatch => {
  dispatch({
    type: LOADER,
    payload: true,
  });

  const response = await axios
  .post(`${config.baseUrl}/promoApproval/report`, payload)
  .then(res => {
   
    dispatch({
      type: LOADER,
      payload: false,
    });

    return res.data
  })
  .catch(err => {
    dispatch({
      type: LOADER,
      payload: false,
    });
    return err;
  });
return response;
}

export const updatePromoApprovalData = (updatedData) => {
  return {
    type: UPDATE_PROMO_APPROVAL_DATA,
    payload: updatedData
  };
};

