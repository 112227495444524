import React, { Component } from "react";
import moment from "moment";

export class couponChildMsgRenderer extends Component {
  deleteCouponHandler = () => {
    this.props.data.getfun1(this.props.data);
  };

  editCouponHandler = () => {
    this.props.data.getfun(this.props.data.coupon_id);
  };
  showComments = () => {
    // this.props.data.getfun2({
    //   promoId: [this.props.data.promoId]
    // });
    this.props.data.getfun2();
  };
  render() {
    return (
      <>
        <span>
          <i
            title="Delete"
            className="fa fa-trash"
            style={
              moment(this.props.data.campaignStartDate).isSameOrBefore(moment())
                ? {
                    color: "gray",
                    pointerEvents: "none",
                    marginLeft: "5px",
                    fontSize: "15px"
                  }
                : {
                    color: "red",
                    cursor: "pointer",
                    marginLeft: "5px",
                    fontSize: "15px"
                  }
            }
            onClick={this.deleteCouponHandler}
          />

          <i
            title="Edit"
            data-trigger="hover"
            className="fa fa-pencil font-weight-bold"
            style={
              moment(this.props.data.campaignStartDate).isSameOrBefore(moment())
                ? {
                    color: "gray",
                    pointerEvents: "none",
                    marginLeft: "15px",
                    fontSize: "15px"
                  }
                : {
                    color: "#357ef2",
                    cursor: "pointer",
                    marginLeft: "15px",
                    fontSize: "15px"
                  }
            }
            onClick={this.editCouponHandler}
          />
          {/* <i
            data-toggle="popover2"
            data-target="#myModal"
            title="Comments"
            data-trigger="hover"
            className="fa fa-comments"
            style={{
              color: "#357ef2",
              cursor: "pointer",
              fontSize: "15px",
              marginLeft: "15px"
            }}
            onClick={this.showComments}
          /> */}
        </span>
      </>
    );
  }
}

export default couponChildMsgRenderer;
