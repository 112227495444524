import moment from "moment";
import {
  emptyValueFormatterNew
} from "../../../utils/valueFormatters.js";

export default {
  defaultColDef: {
    resizable: true,
    sortable: true,
    filter: true
  },
  header: [
    {
      headerName: "Adgroup ID",
      field: "adgroup_id",
      hide: "true"
    },
    {
      headerName: "Adgroup",
      field: "name",
      headerClass: "gray-header-bg",
      width: 180,
      pinned: "left",
      filter: "agTextColumnFilter",
      suppressSizeToFit: true,
      cellStyle: null,
      unSortIcon: true
    },
    {
      headerName: "Adgroup Desc.",
      field: "adgroup_description",
      filter: "agTextColumnFilter",
      headerClass: "gray-header-bg",
      suppressSizeToFit: true,
      pinned: "left",
      unSortIcon: true
    },
    // {
    //   headerName: "Adgroup Type",
    //   field: "adgroup_type",
    //   filter: "agTextColumnFilter",
    //   headerClass: "blue-header-bg",
    //   suppressSizeToFit: true,
    //   unSortIcon: true
    // },
    {
      headerName: "Merch ID",
      field: "merch_id",
      width : 150,
      filter: "agTextColumnFilter",
      headerClass: "blue-header-bg",
      suppressSizeToFit: true,
      unSortIcon: true
    },
    {
      headerName: "Event",
      field: "ty_event_type",
      headerClass: "blue-header-bg",
      width: 250,
      filter: "agTextColumnFilter",
      valueGetter: tyEventTypeFormatter,
      unSortIcon: true
    },
    { headerName: "TY Start Date", field: "start_date", hide: true },
    { headerName: "TY End Date", field: "end_date", hide: true },
    { headerName: "Offer quantity", field: "offer_quantity", hide: true },
    { headerName: "Event ID old", field: "eventId", hide: true },
    { headerName: "Promo ID", field: "promo_id", hide: true },
    {
      headerName: "Event ID", //Non-view purpose, used only for storing options value change
      field: "g_eid",
      width: 150,
      headerClass: "blue-header-bg",
      filter: "agTextColumnFilter",
      unSortIcon: true,
    },
    {
      headerName: "Sign shell",
      field: "promo_sign_shell",
      headerClass: "blue-header-bg",
      width: 300,
      filter: "agTextColumnFilter",
      unSortIcon: true,
      width : 150
    },
    {
      headerName: "Offer Type", 
      field: "offer_type",
      headerClass: "blue-header-bg",
      filter: "agTextColumnFilter",
      unSortIcon: true,
      width : 150
    },
    {
      headerName: "Promo Type",
      field: "promo_event_type",
      headerClass: "blue-header-bg",
      width: 200,
      filter: "agTextColumnFilter",
      unSortIcon: true
    },
    {
      headerName: "UAS",
      width : 100,
      field: "ty_uas",
      headerClass: "blue-header-bg",
      filter: "agTextColumnFilter",
      valueGetter: tyuasFormatter,
      unSortIcon: true
    },
    {
      headerName: "Offer Amount",
      field: "offer_value" ,
      valueGetter: function (params) {
        if (params.data.offer_value>=0) {
          return params.data.offer_value;
        }
        return "-";
      },
      headerClass: "blue-header-bg",
      filter: "agTextColumnFilter",
      unSortIcon: true,
      width : 150
    },
    {
      headerName: "Fixed price qty",
      field: "fixed_price_quantity" ,
      valueGetter: function (params) {
        if (params.data.fixed_price_quantity) {
          return params.data.fixed_price_quantity;
        }
        return "-";
      },
      headerClass: "blue-header-bg",
      filter: "agTextColumnFilter",
      unSortIcon: true,
    },
    {
      headerName: "Min Qty",
      field: "min_quantity",
      valueGetter: function (params) {
        if (params.data.min_quantity) {
          return params.data.min_quantity;
        }
        return "-";
      },
      headerClass: "blue-header-bg",
      filter: "agTextColumnFilter",
      unSortIcon: true,
      width : 150
    },
    {
      headerName: "Bonus Buy",
      field: "bonus_by_quantity",
      valueGetter: function (params) {
        if (params.data.bonus_by_quantity) {
          return params.data.bonus_by_quantity;
        }
        return "-";
      },
      headerClass: "blue-header-bg",
      filter: "agTextColumnFilter",
      unSortIcon: true,
      width : 150
    },
    {
      headerName: "Buy$ Min Value",
      field: "ty_buy_min_value",
      valueGetter: function (params) {
        if (params.data.ty_buy_min_value) {
          return params.data.ty_buy_min_value;
        }
        return "-";
      },
      headerClass: "blue-header-bg",
      filter: "agTextColumnFilter",
      unSortIcon: true,
    },
    {
      headerName: "Get$ Max Value",
      field: "ty_get_max_value",
      valueGetter: function (params) {
        if (params.data.ty_get_max_value) {
          return params.data.ty_get_max_value;
        }
        return "-";
      },
      headerClass: "blue-header-bg",
      width: 200,
      filter: "agTextColumnFilter",
      unSortIcon: true
    },
    // {
    //   headerName: "TY promo status",
    //   field: "promo_build_status",
    //   headerClass: "blue-header-bg",
    //   width: 300,
    //   // cellRenderer: "pipelineDropdownRenderer",
    //   filter: "agTextColumnFilter",
    //   unSortIcon: true
    // },
    // {
    //   headerName: "TY Planned Offer Type",
    //   field: "offer_type",
    //   headerClass: "blue-header-bg",
    //   width: 300,
    //   filter: "agTextColumnFilter",
    //   unSortIcon: true
    // },
    // {
    //   headerName: "TY Planned Offer Amount",
    //   field: "offer_value",
    //   filter: "agTextColumnFilter",
    //   width: 250,
    //   headerClass: "blue-header-bg",
    //   suppressSizeToFit: true,
    //   unSortIcon: true
    // },
    // {
    //   headerName: "Fixed price Qty",
    //   field: "fixed_price_quantity",
    //   filter: "agTextColumnFilter",
    //   width: 200,
    //   headerClass: "blue-header-bg",
    //   suppressSizeToFit: true,
    //   unSortIcon: true
    // },
    // {
    //   headerName: "TY Min Qty for offer",
    //   field: "min_quantity",
    //   filter: "agTextColumnFilter",
    //   headerClass: "blue-header-bg",
    //   width: 250,
    //   suppressSizeToFit: true,
    //   unSortIcon: true
    // },
    // {
    //   headerName: "TY Bonus Buy (Free Goods Qty)",
    //   field: "bonus_by_quantity",
    //   filter: "agTextColumnFilter",
    //   headerClass: "blue-header-bg",
    //   width: 275,
    //   suppressSizeToFit: true,
    //   unSortIcon: true
    // },
    // {
    //   headerName: "TY (Buy %) Min value",
    //   field: "ty_buy_min_value",
    //   filter: "agTextColumnFilter",
    //   headerClass: "blue-header-bg",
    //   width: 250,
    //   suppressSizeToFit: true,
    //   filterParams: { defaultOption: "startsWith" },
    //   unSortIcon: true
    // },
    // {
    //   headerName: "TY (Get %) Max value",
    //   field: "ty_get_max_value",
    //   filter: "agTextColumnFilter",
    //   headerClass: "blue-header-bg",
    //   width: 250,
    //   suppressSizeToFit: true,
    //   unSortIcon: true
    // },
    // {
    //   headerName: "Reg. low amt.",
    //   field: "regLow",
    //   headerClass: "blue-header-bg",
    //   filter: "agTextColumnFilter",
    //   unSortIcon: true,
    // },
    // {
    //   headerName: "Reg. high amt",
    //   field: "regHigh",
    //   headerClass: "blue-header-bg",
    //   filter: "agTextColumnFilter",
    //   unSortIcon: true,
    // },
    // {
    //   headerName: "Sale low amt.",
    //   field: "saleLow",
    //   headerClass: "blue-header-bg",
    //   filter: "agTextColumnFilter",
    //   unSortIcon: true,
    // },
    // {
    //   headerName: "Sale high amt.",
    //   field: "saleHigh",
    //   headerClass: "blue-header-bg",
    //   filter: "agTextColumnFilter",
    //   unSortIcon: true,
    // },
    // {
    //   headerName: "Action",
    //   field: "action",
    //   pinned: "right",
    //   cellRenderer: "promoActionRenderer",
    //   width: 90,
    //   filter: "agTextColumnFilter",
    // }
  ],
  bulkModifyOfferErrorsData : [
    {
      headerName: "Adgroup", 
      field: "adgroup_name",
      headerClass: "blue-header-bg",
      filter: "agTextColumnFilter", 
      checkboxSelection: false,
    },
    { 
      headerName: "Event", 
      field: "event_detail",
      headerClass: "blue-header-bg",
      filter: "agTextColumnFilter",
      checkboxSelection: false,
    },
  ],
  bulkModifyApprovalErrorsData : [
    {
      headerName: "Adgroup", 
      field: "adgroup_name",
      headerClass: "blue-header-bg",
      filter: "agTextColumnFilter", 
      checkboxSelection: false,
    },
    { 
      headerName: "Event", 
      field: "event_detail",
      headerClass: "blue-header-bg",
      filter: "agTextColumnFilter",
      checkboxSelection: false,
    },
    { 
      headerName: "Offer Type", 
      field: "offer_type",
      headerClass: "blue-header-bg",
      filter: "agTextColumnFilter",
      checkboxSelection: false,
    },
    { 
      headerName: "Offer Value", 
      field: "offer_value",
      headerClass: "blue-header-bg",
      filter: "agTextColumnFilter",
      checkboxSelection: false,
    },
    {
      headerName: "Status",
      field: 'status',
      cellRenderer: "approvalStatusRenderer",
      tooltipField: 'validationErrors',
      pinned: 'right',
      headerClass: "blue-header-bg",
      width: 100,
      filter: "agTextColumnFilter"
    }
  ]
}

function tyEventTypeFormatter(params) {
  let labelValue =
    params.data.type +
    "-" +
    params.data.promo_type +
    "-" + params.data.event_start_date +
    // (moment(params.data.event_start_date).isValid() ? moment(params.data.event_start_date).format("MM/DD/YYYY") : params.data.event_start_date) +
    "-" + params.data.event_end_date
    // (moment(params.data.event_end_date).isValid() ? moment(params.data.event_end_date).format("MM/DD/YYYY") : params.data.event_end_date);

  return labelValue
}

function tyuasFormatter(params) {
  if (params.data.ty_uas == "x") {
    return "Yes"
  }

  return "No"
}