import React from "react";
import { EVENT_MASTER_USERS } from "./constants";
import { Button } from 'antd';
import { isIt } from "../../../../utils/getRoleName";

const EventMasterActionRenderer = props => {

  return (
    <>

      {(props.node && props.node.selectable && props.data.status) ?
        <div className="action-button">
          <Button
            type="link"
            title="View Event"
            onClick={() => {
              props.data.viewEvent(props.data);
              // this.submitGA("Click", "Clicked_EditAdgroupButton");
            }}
            disabled={!EVENT_MASTER_USERS.includes(localStorage.getItem("role")) && !(localStorage.getItem("role") == "2")}
            icon={<i
              className="fa fa-file text-cyan"
              title="View event"
            />}
          >
          </Button>
          {(localStorage.getItem("role") !== "6" && EVENT_MASTER_USERS.includes(localStorage.getItem("role"))) && <Button
            type="link"
            title="Edit Event"
            onClick={() => {
              props.data.editEvent(props.data);
              // this.submitGA("Click", "Clicked_EditAdgroupButton");
            }}
            disabled={!EVENT_MASTER_USERS.includes(localStorage.getItem("role")) || new Date() > new Date(props.data.event_start_date)}
            icon={<i
              className="fa fa-edit"
              aria-hidden="true"
            />}
          >
          </Button>
          }

          <Button
            type="link"
            title="Deactivate Event"
            onClick={() => props.data.deleteEvent(props.data)}
            disabled={!EVENT_MASTER_USERS.includes(localStorage.getItem("role")) || new Date() > new Date(props.data.event_start_date) || isIt()}
            icon={
              <i className="fa fa-trash" aria-hidden="true" />
            }
          >
          </Button>
        </div>
        :
        <div className="action-button">
          <Button
            type="link"
            title="View Event"
            onClick={() => {
              props.data.viewEvent(props.data);
              // this.submitGA("Click", "Clicked_EditAdgroupButton");
            }}
            disabled={!EVENT_MASTER_USERS.includes(localStorage.getItem("role")) && (!localStorage.getItem("role") == "2")}
            icon={<i
              className="fa fa-file text-cyan"
              title="View event"
            />}
          >
          </Button>
        </div>
      }
    </>
  )
}

export default EventMasterActionRenderer;
