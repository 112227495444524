function isFirstColumn(params) {
  var displayedColumns = params.columnApi.getAllDisplayedColumns();
  var thisIsFirstColumn = displayedColumns[0] === params.column;
  return thisIsFirstColumn;
}

export default {
  defaultColDef: {
    headerCheckboxSelection: isFirstColumn,
    checkboxSelection: isFirstColumn,
    resizable: true,
    filter: true,
    sortable: true
  },
  defaultColDefInactive: {
    resizable: true,
    filter: true,
    sortable: true
  },
  adgroupMasterHeader: [
    {
      headerName: "Adgroup ID",
      field: "id",
      hide: true
    },
    {
      headerName: "Adgroup",
      field: "name",
      width: 300,
      filter: "agTextColumnFilter",
      unSortIcon: true
    },
    {
      headerName: "Adgroup Description",
      field: "description",
      width: 400,
      filter: "agTextColumnFilter",
      unSortIcon: true,
      valueGetter: function (params) {
        if (params.data.description) {
          return params.data.description;
        }
        return "-";
      }
    },
    {
      headerName: "Merch ID",
      field: "merch_id",
      width: 323,
      filter: "agTextColumnFilter",
      valueGetter: function (params) {
        if (params.data.merch_id) {
          return params.data.merch_id;
        }
        return "-";
      },
      unSortIcon: true
    },
    {
      headerName: "No. of SKU",
      field: "product_list",
      width: 200,
      filter: "agTextColumnFilter",
      unSortIcon: true,
      hide: true
    },
    {
      headerName: "Sign Description",
      field: "sign_description",
      width: 330,
      filter: "agTextColumnFilter",
      unSortIcon: true,
      valueGetter: function (params) {
        if (params.data.sign_description) {
          return params.data.sign_description;
        }
        return "-";
      },
      hide: true
    },
    {
      headerName: "Label",
      field: "vendor_label",
      width: 400,
      filter: "agTextColumnFilter",
      unSortIcon: true,
      valueGetter: function (params) {
        if (params.data.vendor_label) {
          return params.data.vendor_label;
        }
        return "-";
      }
    },
    {
      headerName: "Subline",
      field: "subline",
      width: 330,
      filter: "agTextColumnFilter",
      unSortIcon: true,
      valueGetter: function (params) {
        if (params.data.subline) {
          return params.data.subline;
        }
        return "-";
      },
      hide: true
    },
    {
      headerName: "GMM",
      field: "gmm",
      width: 330,
      filter: "agTextColumnFilter",
      unSortIcon: true,
      valueGetter: function (params) {
        if (params.data.gmm) {
          return params.data.gmm;
        }
        return "-";
      },
      hide: true
    },
    {
      headerName: "UOM",
      field: "uom",
      width: 330,
      filter: "agTextColumnFilter",
      unSortIcon: true,
      valueGetter: function (params) {
        if (params.data.uom) {
          return params.data.uom;
        }
        return "-";
      },
      hide: true
    },
    {
      headerName: "SKU Price range",
      field: "price_range",
      filter: "agTextColumnFilter",
      width: 300,
      valueFormatter: function(params) {
        if (params.value === "$ - $") {
          return "-";
        }
        return params.value;
      },
      unSortIcon: true,
      hide: true
    },
    {
      headerName: "Cost range",
      field: "cost_range",
      filter: "agTextColumnFilter",
      width: 300,
      valueFormatter: function(params) {
        if (params.value === "$ - $") {
          return "-";
        }
        return params.value;
      },
      unSortIcon: true,
      hide: true
    },
    {
      headerName: "Reference SKU options",
      field: "refSkuOptions",
      width: 300,
      filter: "agTextColumnFilter",
      unSortIcon: true,
      hide: true
    },
    {
      headerName: "Reference SKU",
      field: "selected_sku",
      width: 300,
      filter: "agTextColumnFilter",
      unSortIcon: true,
      hide: true
    },
    {
      headerName: "Action",
      field: "action",
      pinned: "right",
      width: 125,
      filter: "agTextColumnFilter",
      cellRenderer: "adgroupMasterChildMessageRendrer"
    }
  ]
};
