import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import _ from "lodash";
import PageLoader from "../../../../utils/Loader";
import "./EventMasterStyling.css";

import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Modal from "react-awesome-modal";
import Toggle from "react-toggle";

import { getEventMasterData, getEventDetails, deleteEvent, getEventMasterExpiredData } from "../../../../redux/actions/eventMasterAction";
import AlertDanger from "../../../../utils/AlertMessage/alertDanger";
import AlertSuccess from "../../../../utils/AlertMessage/alertSuccess";

import ColumnDef from "../../../tables/colDefs/EventMasterColDef";
import DataTable from "../../../tables";
import { EventMAsterGA } from "../../../../utils/GA/GAUtils";

import EventNameRenderer from "./EventNameRenderer";
import EventMasterActionRenderer from "./EventMasterActionRenderer";
import { EVENT_MASTER_USERS } from "./constants";
import { alertTimer } from "../../../../utils/alertTimer";

import { Button } from 'antd';
import { isIt } from "../../../../utils/getRoleName";

export class EventMaster extends Component {

  constructor(props) {
    super(props);
    this.state = {
      activeTab: 1,
      eventMasterData: [],
      tableData: {},
      showLoader: false,
      selectedEventsRows: [],
      isViewEvent: false,
      isDelete: false,
      selectedEvent: [],
      isSuccess: false,
      successMessage: '',
      showExpiredEvent: false,
      isFailure: false,
      failureMessage: ""
    }
  }

  async componentDidMount() {
    const reqObj = {
      isActive: parseInt(this.state.activeTab)
    }

    // fetch event master data for active events
    const result = await this.props.getEventMasterData(reqObj);
    // setEventMasteData(result.data.data);

    // this.setState({
    //   eventMasterData: result.data.data
    // })
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.eventMasterData != this.props.eventMasterData && this.props.eventMasterData) {
      let updatedeventMasterData = this.props.eventMasterData.map((item) => {
        item.viewEvent = this.handleViewEvent;
        item.editEvent = this.handleEditEvent;
        item.deleteEvent = this.handleDeleteEvent;
        return item;
      });

      let tableData = {
        header: parseInt(this.state.activeTab) == 1 && !this.state.showExpiredEvent ? ColumnDef.activeEventMasterHeader : ColumnDef.inactiveEventMasterHeader,
        defaultColDef: parseInt(this.state.activeTab) == 1 ? ColumnDef.defaultColDef : ColumnDef.defaultColDefInactive,
        data: updatedeventMasterData
      };

      this.setState({
        tableData: tableData
      })
    }
  }

  handleViewEvent = async (eventDetails) => {
    console.log(eventDetails);
    const eventId = `${eventDetails.e_id}`;

    const reqObj = {
      eventId: eventId
    };

    console.log(this.props);

    this.submitGA("Click", "Clicked View Event");

    // await this.props.viewEvent(reqObj);

    // setIsViewEvent(true);
    this.setState({
      isViewEvent: true
    })


    this.props.history.push(`/viewEvent?eventId=${eventId}`)
  }

  submitGA(action, label) {
    EventMAsterGA({ action, label });
  }

  closeModal = () => {
    this.setState({
      isDelete: false,
      selectedEvent: []
    })
  }

  handleConfirmDelete = (eventData) => {
    this.closeModal();
    let selectedEventsIds = [];
    if (this.state.selectedEvent && this.state.selectedEvent.length > 0) {
      selectedEventsIds = this.state.selectedEvent.map(event => `${event.e_id}`);
    } else {

      selectedEventsIds = this.state.selectedEventsRows.map(event => `${event.e_id}`);
    }

    console.log(selectedEventsIds);

    this.props.deleteEvent({ eventId: selectedEventsIds }).then(res => {

      if(res.response && res.response.data && !res.response.data.status) {
        this.setState({
          isFailure: true,
          failureMessage: res.response.data.msg
        });

        setTimeout(() => {
          this.setState({
            isFailure: false,
            failureMessage: ''
          });
        }, alertTimer);
      } else {
        this.setState({
          selectedEventsRows: [],
          isDelete: false,
          selectedEventsIds: [],
          isSuccess: true,
          successMessage: `${res.msg}`
        })
    
        setTimeout(() => {
          this.setState({
            isSuccess: false,
            successMessage: ''
          });
        }, alertTimer);
        const reqObj = {
          isActive: 1
        }
        this.props.getEventMasterData(reqObj);
    }
    });
  }

  handleDeleteEvent = async (eventData) => {
    let selectedEventsIds = [];
    let mainEvents = [];
    if (eventData && eventData.e_id) {
      selectedEventsIds = [eventData.e_id];
      if (eventData.type == "M") {
        mainEvents.push(eventData.e_id)
      }
    } else {
      selectedEventsIds = this.state.selectedEventsRows.map(event => `${event.e_id}`);
      mainEvents = this.state.selectedEventsRows.filter(event => event.type == "M")
    }

    console.log(selectedEventsIds);
    this.submitGA("Click", "Clicked Delete Event");

    if (mainEvents.length > 0) {
      this.setState({
        isFailure: true,
        failureMessage: "Main event can not be deactivated!"
      });

      setTimeout(() => {
        this.setState({
          isFailure: false,
          failureMessage: ""
        });
      }, alertTimer);
    } else if (selectedEventsIds.length <= 5) {

      this.setState({
        isDelete: true,
        selectedEvent: eventData && eventData.e_id ? [eventData] : []
      })
    } else {
      this.setState({
        isFailure: true,
        failureMessage: "A maximum of 5 events can be selected!"
      });

      setTimeout(() => {
        this.setState({
          isFailure: false,
          failureMessage: ""
        });
      }, alertTimer);
    }
  }

  handleEditEvent = async (eventData) => {

    console.log("******eventData", eventData);
    let selectedEventsIds = [];
    if (eventData && eventData.e_id) {
      selectedEventsIds = [eventData.e_id]
    } else {

      selectedEventsIds = this.state.selectedEventsRows.map(event => `${event.e_id}`);
    }

    this.submitGA("Click", "Clicked Edit Event");

    if (selectedEventsIds.length != 1) {
      this.setState({
        isFailure: true,
        failureMessage: "Please select only one event to edit"
      });

      setTimeout(() => {
        this.setState({
          isFailure: false,
          failureMessage: ""
        });
      }, alertTimer);

      return;
    }

    this.props.history.push(`/editEvent?eventId=${selectedEventsIds[0]}`)
  }

  handleEventDisplayToggle = async (val) => {
    const updatedToggleStatus = !this.state.showExpiredEvent;
    console.log(updatedToggleStatus);

    this.setState({
      showExpiredEvent: updatedToggleStatus,
      selectedEventsRows: []
    })

    if (updatedToggleStatus) {
      this.submitGA("Click", "Clicked actve tab to get expired events.");

      const result = await this.props.getEventMasterExpiredData();
    } else {
      const reqObj = {
        isActive: 1
      }
      this.submitGA("Click", "Clicked actve tav to get actve evnets.");

      const result = await this.props.getEventMasterData(reqObj);
    }
  }

  render() {


    const handleTabChange = async key => {
      this.setState({
        activeTab: key,
        selectedEventsRows: []
      })

      const reqObj = {
        isActive: parseInt(key)
      }
      this.submitGA("Click", "Clicked tab change");

      const result = await this.props.getEventMasterData(reqObj);

    }

    const onSelectionChanged = async (rowData) => {
      console.log(rowData);
      this.setState({
        selectedEventsRows: rowData
      })
    }


    return (
      <PageLoader loader={this.props.loader} className="eventMaster">
        <div className="alertDivs">
          {this.state.isFailure &&
            <AlertDanger
              message={this.state.failureMessage}
              show={this.state.isFailure}
            />
          }
          {this.state.isSuccess &&
            <AlertSuccess
              message={this.state.successMessage}
              show={this.state.isSuccess}
            />
          }
          <Modal visible={this.state.isDelete} width="500px" height="200px" effect="fadeInUp" onClickAway={() => this.closeModal()}>
            <div className="modalContent">
              <h5>Confirm to deactivate</h5>
              <p className="campaignHeader">Deactivating selected events will also deactivate the associated promos. Are you sure you want to proceed with the deactivate?</p>
              <div className="modalButtoncontainer">
                <Button
                  type="link"
                  onClick={() => this.closeModal()}
                  style={{
                    color: "black"
                  }}
                >
                  Cancel
                </Button>
                <Button
                  type="primary"
                  onClick={this.handleConfirmDelete}
                  disabled={isIt()}
                >
                  Deactivate
                </Button>
              </div>
            </div>

          </Modal>
        </div>
        <div className="card cardStyles cardGap">
          <div className="px-4 mb-4">
            <div className="createClusterDiv">
              <div className="row mt-4 mx-0">
                <div className="col text-center">
                  <h4 className="promoHeading mb-0">Event Master</h4>
                </div>
                <span className="text-right">
                  {this.state.activeTab == 1 && (
                    <>
                      {/* <button
                        type="button"
                        title="Edit Event"
                        className="btn pipeline-button-class mr-1 pipeline-btn-styling"
                        onClick={(e) => {
                          this.handleEditEvent();
                          // this.submitGA("Click", "Clicked_EditAdgroupButton");
                        }}
                        disabled={!this.state.selectedEventsRows.length || !EVENT_MASTER_USERS.includes(localStorage.getItem("role"))}
                      >
                        <i
                          className="fa fa-edit text-cyan"
                          aria-hidden="true"
                        />
                      </button> */}

                      <button
                        type="button"
                        title="Deactivate Events"
                        className="btn pipeline-button-class mr-1 pipeline-btn-styling"
                        onClick={(e) => this.handleDeleteEvent(e)}
                        disabled={!this.state.selectedEventsRows.length || !EVENT_MASTER_USERS.includes(localStorage.getItem("role"))}
                      >
                        <i className="fa fa-trash" aria-hidden="true" />
                      </button>

                      <button
                        type="button"
                        title="Create Event"
                        className="btn pipeline-button-class mr-1 pipeline-btn-styling"
                        onClick={(e) => {
                          this.props.history.push(
                            "/createEvent"
                          );
                          this.submitGA("Click", "Clicked Create Event");
                        }}
                        disabled={
                          (!EVENT_MASTER_USERS.includes(localStorage.getItem("role"))) ||
                          (localStorage.getItem("role") === "6")
                        }
                      >
                        <i className="fa fa-plus" aria-hidden="true" />
                      </button>
                    </>
                  )}

                </span>
              </div>

              <Tabs
                activeKey={this.state.activeTab}
                onSelect={(key) => handleTabChange(key)}
                style={{ marginTop: "-1%" }}
                className="simulatn-table-tab"
              >
                <Tab eventKey={1} title="Active">
                  <>
                    <div
                      id="eventToggleBtn"
                      className="btn-group event-toggle-btn"
                    >
                      <label>
                        <span
                          className={
                            this.state.showExpiredEvent
                              ? ""
                              : "toggle-active-class"
                          }
                        >
                          Active events
                        </span>

                        <Toggle
                          defaultChecked={this.state.showExpiredEvent}
                          icons={false}
                          onChange={(e) => this.handleEventDisplayToggle(e)}
                        />
                        <span
                          className={
                            this.state.showExpiredEvent
                              ? "toggle-active-class"
                              : ""
                          }
                        >
                          Expired events
                        </span>
                      </label>
                    </div>
                    {this.state.tableData.data &&
                      <DataTable
                        tableData={this.state.tableData}
                        // autoSizeCol={true}
                        rowSelection="multiple"
                        suppressRowClickSelection={true}
                        selectOfferRow={(data) => onSelectionChanged(data)}
                        frameworkComponents={{
                          eventActionRenderer: EventMasterActionRenderer,
                          eventNameRenderer: EventNameRenderer,
                        }}
                        isRowSelectable={(node) => {
                          const currentDateTime = new Date();
                          const endDateTime = new Date(node.data.event_end_date);
                          return endDateTime > currentDateTime
                        }}
                      // downloadName={name}
                      />
                    }
                  </>
                </Tab>
                <Tab eventKey={0} title="Inactive">
                  {this.state.tableData.data &&
                    <DataTable
                      tableData={this.state.tableData}
                      // autoSizeCol={true}
                      suppressRowClickSelection={true}
                      frameworkComponents={{
                        eventNameRenderer: EventNameRenderer,
                        eventActionRenderer: EventMasterActionRenderer,
                      }}
                    // downloadName={name}
                    />
                  }
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
        {
          this.state.isViewEvent && <>

          </>
        }
      </PageLoader>
    )

  }
}

const mapStateToProps = (state) => {
  return {
    loader: state.eventMaster.loader,
    eventMasterData: state.eventMaster.eventMasterData,
  };
};

export default connect(
  mapStateToProps,
  {
    getEventMasterData,
    getEventDetails,
    deleteEvent,
    getEventMasterExpiredData
  }
)(EventMaster);