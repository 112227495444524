import React, { Component } from "react";
import DateRangePicker from "../../../../utils/DateRangePicker/DateRangePicker.js";
import Multiselect from "../../../../utils/Multiselect";
import DataTable from "../../../tables";
import ColumnDef from "../../../tables/colDefs/ComparePromoColDefs";
import CompareGraphTab from "../PromoCompare/CompareGraphTab";
import AlertDanger from "../../../../utils/AlertMessage/alertDanger";
import AlertLoader from "../../../../utils/AlertMessage/alertMiniLoader";
import {
  getCompareFilters,
  getCompareFiltersPromoType,
  getCompareFiltersOffer,
  getCompareFiltersShop,
  getCompareData,
  getCompareGraph
} from "../../../../redux/actions/compareAction";
import PageLoader from "../../../../utils/Loader";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { Element, animateScroll as scroll } from "react-scroll";
import moment from "moment";
import _ from "lodash";
import "react-dates/lib/css/_datepicker.css";
import "../../../../utils/DateRangePicker/dateRangeStyling.css";
import "react-dates/initialize";
import { PromoCompareGA } from '../../../../utils/GA/GAUtils';

let shopFlag = false,
  adgrpFlag = false,
  promoTypeFlag = false,
  offerFlag = false,
  yearArray = [];

export class ComparePromotionNew extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: null,
      endDate: null,
      rowSelection: "multiple",
      initialCheckArray: [],
      finalCheckArray: [],
      shopSelectionDisabled: true,
      adgroupSelectionDisabled: true,
      promoTypeSelectionDisabled: true,
      offerSelectionDisabled: true,
      compareButtonDisabled: true,
      showCompareTableView: false,
      resetTableDataDisabled: true,
      addForGraphBtnDisabled: true,
      downloadTableDataDisabled: true,
      showOfferGraph: false,
      initialMethodCall: true,
      showMaximumSelectionWarning: false,
      showMakeAllSelectionWarning: false,
      showTableDataEmptyWarning: false,
      showDataExistsWarning: false,
      noDataWarning: false,
      shopListArray: [],
      adgroupListArray: [],
      waitAlert: false,
      timePeriod: [],
      promoTypeArray: [],
      shopListForCompare: [],
      adgroupListForCompare: [],
      promoTypeForCompare: [],
      offerSelectedValue: [],
      shopSelectedValue: [],
      adgroupSelectedValue: [],
      offerValueForCompare: [],
      promoTypeSelectedValue: [],
      compareFiltersChanged: true,
      rowDataForCompare: [],
      selectedCompareRow: [],
      dateFiltersValue: false,
      compareTableDataArray: [],
      updateTable: false,
      resetTable: false,
      focusedInput: "",
      dateRangeExceededAlert: false,
      tableLoader: false
    };
  }
  componentDidMount() {
    let minDate = localStorage.getItem("minDateForCalendar"),
      maxDate = localStorage.getItem("maxDateForCalendar");
    for (
      let i = moment(minDate).format("YYYY");
      i <= moment(maxDate).format("YYYY");
      i++
    ) {
      yearArray.push(parseInt(i));
    }
  }
  componentWillReceiveProps(nextProps) {
    var shopList = [];
    var adgroupList = [];
    var promoTypeList = [];
    var offerList = [];
    var all = { value: "All", label: "All" };

    var compareTableDataArray = [];
    var initialTableData = [];
    var initialCheckArray = [];
    var finalCheckArray = "";

    if (
      nextProps.compareFiltersShop &&
      Object.keys(nextProps.compareFiltersShop).length > 0 &&
      nextProps.compareFiltersShop.status === true
    ) {
      if (nextProps.compareFiltersShop.data.length > 0) {
        shopList = [all];
      }

      nextProps.compareFiltersShop.data.map(shopListArray => {
        shopList.push({
          value: shopListArray.shop_descr,
          label: shopListArray.shop_descr
        });
        return shopListArray;
      });

      this.setState({
        shopListArray: shopList
      });
    }

    if (
      nextProps.compareFilters &&
      Object.keys(nextProps.compareFilters).length > 0 &&
      nextProps.compareFilters.status === true
    ) {
      if (nextProps.compareFilters.data.length > 0) {
        adgroupList = [all];
      }
      nextProps.compareFilters.data.map(adgroupListArray => {
        adgroupList.push({
          value: adgroupListArray.name, //adgroupListArray.ad_group,
          label: adgroupListArray.name // adgroupListArray.ad_group
        });
        return adgroupListArray;
      });
      this.setState({
        adgroupListArray: adgroupList
      });
    }
    if (!nextProps.compareFilters.data && this.state.dateFiltersValue) {
      this.setState({
        waitAlert: true
      });
    } else {
      this.setState({
        waitAlert: false
      });
    }
    if (
      nextProps.compareFiltersPromoType &&
      Object.keys(nextProps.compareFiltersPromoType).length > 0 &&
      nextProps.compareFiltersPromoType.status === true
    ) {
      if (nextProps.compareFiltersPromoType.data.length > 0) {
        promoTypeList = [all];
      }
      nextProps.compareFiltersPromoType.data.map(adgroupListArray => {
        promoTypeList.push({
          value: adgroupListArray.event_type, //adgroupListArray.event_type,
          label: adgroupListArray.event_type
            // adgroupListArray.event_type === "SPC-D"
            //   ? "Sub Event Digital"
            //   : adgroupListArray.event_type === "SPC-P"
            //   ? "Sub Event Print"
            //   : adgroupListArray.event_type === "SPC"
            //   ? "Sub Event"
            //   : adgroupListArray.event_type //adgroupListArray.event_type
        });
        return adgroupListArray;
      });

      this.setState({
        promoTypeArray: promoTypeList
      });
    }

    if (
      nextProps.compareFiltersOffer &&
      Object.keys(nextProps.compareFiltersOffer).length > 0
    ) {
      if (nextProps.compareFiltersOffer.data.length > 0) {
        offerList = [all];
      }
      nextProps.compareFiltersOffer.data.map(offerListArray => {
        offerList.push({
          value: offerListArray.offer, //offerListArray.offer,
          label: offerListArray.offer //offerListArray.offer
        });
        return offerListArray;
      });
      this.setState({
        offerListArray: offerList
      });
    }
    if (
      this.props.compareData &&
      Object.keys(this.props.compareData).length > 0 &&
      this.state.initialMethodCall
    ) {
      if (this.props.compareData.data.length > 0) {
        this.props.compareData.data.map(compareTableData => {
          initialTableData.push({
            adgroup: compareTableData.adgroup,
            adgroup_desc: compareTableData.adgroup_desc,
            shop_desc: compareTableData.shop_desc,
            offer: compareTableData.offer,
            promo_type: compareTableData.promo_type,
            offer_type: compareTableData.offer_type,
            days: compareTableData.days,
            start_date: compareTableData.start_date,
            end_date: compareTableData.end_date,
            time_period: compareTableData.time_period,
            avg_urp: compareTableData.avg_urp,
            day_total_quantity: compareTableData.day_total_quantity,
            weeks_total_quantity: compareTableData.weeks_total_quantity,
            total_revenue: compareTableData.total_revenue,
            total_margin: compareTableData.total_margin,
            total_quantity: compareTableData.total_quantity,
            day_total_revenue: compareTableData.day_total_revenue,
            weeks_total_revenue: compareTableData.weeks_total_revenue,
            day_total_margin: compareTableData.day_total_margin,
            weeks_total_margin: compareTableData.weeks_total_margin,
            gm_percentage: compareTableData.gm_per
          });
          initialCheckArray.push(
            compareTableData.shop_descr +
              compareTableData.adgroup +
              compareTableData.promo_type +
              compareTableData.offer_value +
              compareTableData.end_date +
              compareTableData.start_date
          );
          return compareTableData;
        });
        this.setState({
          initialCheckArray
        });
      }
      if (_.isEmpty(nextProps.compareData.data)) {
        this.setState({
          noDataWarning: true
        });
        setTimeout(() => {
          this.setState({
            noDataWarning: false
          });
        }, 2500);
      }

      this.setState({
        rowDataForCompare: _.isEmpty(initialTableData)
          ? this.state.rowDataForCompare
          : initialTableData,
        initialMethodCall: false,
        downloadTableDataDisabled: false
      });
    }
    if (
      nextProps.compareData &&
      Object.keys(nextProps.compareData).length > 0 &&
      nextProps.compareData !== this.props.compareData
    ) {
      let shouldAppend = true;

      for (var i = 0; i < nextProps.compareData.data.length; i++) {
        let item = nextProps.compareData.data[i];

        let checkArray =
          item.shop_descr +
          item.adgroup +
          item.promo_type +
          item.offer_value +
          item.end_date +
          item.start_date;

        if (this.state.initialCheckArray.includes(checkArray)) {
          shouldAppend = false;
        } else {
          shouldAppend = true;
          break;
        }
      }
      if (shouldAppend) {
        let newItems = [];
        nextProps.compareData.data.map(compareTableData => {
          finalCheckArray =
            compareTableData.shop_descr +
            compareTableData.adgroup +
            compareTableData.promo_type +
            compareTableData.offer_value +
            compareTableData.end_date +
            compareTableData.start_date;

          if (!this.state.initialCheckArray.includes(finalCheckArray)) {
            newItems.push(
              compareTableData.shop_descr +
                compareTableData.adgroup +
                compareTableData.promo_type +
                compareTableData.offer_value +
                compareTableData.end_date +
                compareTableData.start_date
            );

            compareTableDataArray.push({
              adgroup: compareTableData.adgroup,
              adgroup_desc: compareTableData.adgroup_desc,
              shop_desc: compareTableData.shop_desc,
              offer: compareTableData.offer,
              promo_type: compareTableData.promo_type,
              offer_type: compareTableData.offer_type,
              days: compareTableData.days,
              start_date: compareTableData.start_date,
              end_date: compareTableData.end_date,
              time_period: compareTableData.time_period,
              avg_urp: compareTableData.avg_urp,
              day_total_quantity: compareTableData.day_total_quantity,
              weeks_total_quantity: compareTableData.weeks_total_quantity,
              total_revenue: compareTableData.total_revenue,
              total_margin: compareTableData.total_margin,
              total_quantity: compareTableData.total_quantity,
              day_total_revenue: compareTableData.day_total_revenue,
              weeks_total_revenue: compareTableData.weeks_total_revenue,
              day_total_margin: compareTableData.day_total_margin,
              weeks_total_margin: compareTableData.weeks_total_margin,
              gm_percentage: compareTableData.gm_per
            });
          }
          return compareTableData;
        });

        let checkArrayModified = _.concat(
          this.state.initialCheckArray,
          newItems
        );
        this.setState({
          downloadTableDataDisabled: false,
          compareTableDataArray,
          updateTable: true,
          tableLoader: false,
          initialCheckArray: checkArrayModified
        });
      } else {
        this.setState({
          tableLoader: false,
          showDataExistsWarning: true
        });
        setTimeout(() => {
          this.setState({
            showDataExistsWarning: false
          });
        }, 2500);
      }
    } else if (
      nextProps.length &&
      this.props.compareData === nextProps.compareData
    ) {
      this.setState({
        showDataExistsWarning: true
      });
      setTimeout(() => {
        this.setState({
          showDataExistsWarning: false
        });
      }, 2500);
    }

    if (
      this.props.compareGraphData &&
      Object.keys(this.props.compareGraphData).length > 0
    ) {
      this.setState({
        compareGraphData: this.props.compareGraphData
      });
    }
  }
  updateTableHandler = () => {
    this.setState({
      updateTable: false
    });
  };
  onDatesChange(startDate) {
    this.setState({
      startDate: startDate.startDate,
      endDate: startDate.endDate
    });
    let startDateFormat = JSON.stringify(startDate.startDate);
    let resultStartDate = startDateFormat.slice(1, 11);
    let endDateFormat = JSON.stringify(startDate.endDate);
    let resultEndDate = endDateFormat.slice(1, 11);

    this.setState({
      resultStartDate,
      resultEndDate,
      shopSelectedValue: [],
      adgroupSelectedValue: [],
      shopListForCompare: [],
      promoTypeForCompare: [],
      offerValueForCompare: [],
      adgroupListForCompare: [],
      promoTypeSelectedValue: [],
      offerSelectedValue: []
    });

    if (resultStartDate.length > 4 && resultEndDate > 4) {
      this.setState({
        dateFiltersValue: true
      });
    }
    if (resultStartDate.length > 4 && resultEndDate.length > 4) {
      this.setState({
        compareButtonDisabled: false,
        shopSelectionDisabled: false,
        adgroupSelectionDisabled: false,
        promoTypeSelectionDisabled: false,
        offerSelectionDisabled: false
      });
      this.props.getCompareFiltersShop({
        startDate: resultStartDate,
        endDate: resultEndDate,
        requiredColumn: "shop_descr"
      });

      this.props.getCompareFilters({
        startDate: resultStartDate,
        endDate: resultEndDate,
        requiredColumn: "ad_group" //Ad_group
      });

      this.props.getCompareFiltersPromoType({
        startDate: resultStartDate,
        endDate: resultEndDate,
        requiredColumn: "event_type" //Promo_Type
      });

      this.props.getCompareFiltersOffer({
        startDate: resultStartDate,
        endDate: resultEndDate,
        requiredColumn: "offer" //Offer
      });
    } else {
      this.setState({
        compareButtonDisabled: true
      });
    }
  }

  isOutsideRangeHandler = day => {
    let formattedDate = moment(day).format(),
      endDate = moment().format(),
      isSameOrBefore = moment(formattedDate).isSameOrBefore(endDate);

    return !isSameOrBefore;
  };

  shopSelected = (value, compareFiltersChanged, optionsArray) => {
    var shopListArray = [],
      shopListArrayApi = [],
      shopArrayWithoutAll = [];

    value.map(shopList => {
      shopListArray.push(shopList.value);
      return shopList;
    });

    if (shopListArray.includes("All")) {
      shopFlag = true;
      optionsArray.map(item => {
        if (item.value !== "All") {
          shopArrayWithoutAll.push(item.value);
        }
        return item;
      });
      shopListArrayApi = shopArrayWithoutAll;
      this.setState({
        shopSelectedValue: optionsArray,
        shopListForCompare: shopArrayWithoutAll
      });
    } else if (!shopListArray.includes("All") && shopFlag) {
      shopFlag = false;
      shopListArrayApi = [];
      this.setState({
        shopSelectedValue: [],
        shopListForCompare: []
      });
    } else {
      shopListArrayApi = shopListArray;
      this.setState({
        shopSelectedValue: value,
        shopListForCompare: shopListArray
      });
    }
    // this.adGroupSelected([], false);
    // this.promoTypeSelected([], false);
    // this.offerSelected([], false);
  };

  adGroupSelected = (value, compareFiltersChanged, optionsArray) => {
    var adgroupListArray = [],
      adgrpArrayWithoutAll = [];

    value.map(adgroupList => {
      adgroupListArray.push(adgroupList.value);
      return adgroupList;
    });

    if (adgroupListArray.includes("All")) {
      adgrpFlag = true;
      optionsArray.map(item => {
        if (item.value !== "All") {
          adgrpArrayWithoutAll.push(item.value);
        }
        return item;
      });
      this.setState({
        adgroupSelectedValue: optionsArray,
        adgroupListForCompare: adgrpArrayWithoutAll
      });
    } else if (!adgroupListArray.includes("All") && adgrpFlag) {
      adgrpFlag = false;
      this.setState({
        adgroupSelectedValue: [],
        adgroupListForCompare: []
      });
    } else {
      this.setState({
        adgroupSelectedValue: value,
        adgroupListForCompare: adgroupListArray
      });
    }
    // this.promoTypeSelected([], false);
    // this.offerSelected([], false);
  };

  promoTypeSelected = (value, compareFiltersChanged, optionsArray) => {
    var promoTypeArray = [],
      promoTypeArrayWithoutAll = [];

    value.map(promoType => {
      promoTypeArray.push(promoType.value);
      return promoType;
    });

    if (promoTypeArray.includes("All")) {
      promoTypeFlag = true;
      optionsArray.map(item => {
        if (item.value !== "All") {
          promoTypeArrayWithoutAll.push(item.value);
        }
        return item;
      });
      this.setState({
        promoTypeSelectedValue: optionsArray,
        promoTypeForCompare: promoTypeArrayWithoutAll
      });
    } else if (!promoTypeArray.includes("All") && promoTypeFlag) {
      promoTypeFlag = false;
      this.setState({
        promoTypeSelectedValue: [],
        promoTypeForCompare: []
      });
    } else {
      this.setState({
        promoTypeSelectedValue: value,
        promoTypeForCompare: promoTypeArray
      });
    }
    // this.offerSelected([], false);
  };

  offerSelected = (value, compareFiltersChanged, optionsArray) => {
    var offerValueArray = [],
      offerValArryWithoutAll = [];

    value.map(offerValue => {
      offerValueArray.push(offerValue.value);
      return offerValue;
    });

    if (offerValueArray.includes("All")) {
      offerFlag = true;
      optionsArray.map(item => {
        if (item.value !== "All") {
          offerValArryWithoutAll.push(item.value);
        }
        return item;
      });
      this.setState({
        offerSelectedValue: optionsArray,
        offerValueForCompare: offerValArryWithoutAll
      });
    } else if (!offerValueArray.includes("All") && offerFlag) {
      offerFlag = false;
      this.setState({
        offerSelectedValue: [],
        offerValueForCompare: []
      });
    } else {
      this.setState({
        offerValueForCompare: offerValueArray,
        offerSelectedValue: value
      });
    }
  };

  compareButtonClicked = (
    startDate,
    endDate,
    shop,
    adgroupVal,
    promoTypeVal,
    offerVal
  ) => {
    this.setState({
      showCompareTableView: true,
      resetTableDataDisabled: false,
      tableLoader: true
    });
    if (startDate.length > 0 && endDate.length > 0) {
      this.props.getCompareData({
        startDate: startDate,
        endDate: endDate,
        shopDesc: shop,
        adgroup: adgroupVal,
        promoType: promoTypeVal,
        offer: offerVal
      });
      this.setState({ initialMethodCall: true });
    } else {
      this.setState({
        showMakeAllSelectionWarning: true
      });
      setTimeout(() => {
        this.setState({
          showMakeAllSelectionWarning: false
        });
      }, 2500);
    }
  };

  selectOfferRow = selectedRow => {
    //var selectedRow = this.gridApi.getSelectedRows();
    if (selectedRow.length < 2) {
      this.setState({ addForGraphBtnDisabled: true });
    } else if (selectedRow.length >= 2) {
      this.setState({
        selectedCompareRow: selectedRow,
        addForGraphBtnDisabled: false
      });
      if (selectedRow.length >= 4) {
        this.setState({
          showMaximumSelectionWarning: true
        });
        setTimeout(() => {
          this.setState({
            showMaximumSelectionWarning: false
          });
        }, 3000);
      }
    }
  };

  resetTableData = e => {
    //this.gridApi.setRowData([]);
    this.setState({
      resetTable: true,
      addForGraphBtnDisabled: true,
      initialCheckArray: [],
      rowDataForCompare: []
    });
  };
  resetTableHandler = () => {
    this.setState({
      resetTable: false
    });
  };
  addForGraph = e => {
    var compareGraphRequestArray = [];
    var timePeriodArray = [];

    if (
      this.state.selectedCompareRow.length === 2 ||
      this.state.selectedCompareRow.length === 3
    ) {
      this.state.selectedCompareRow.forEach(selectedRow => {
        compareGraphRequestArray.push({
          startDate: moment(selectedRow.start_date).format("YYYY-MM-DD"),
          endDate: moment(selectedRow.end_date).format("YYYY-MM-DD"),
          adgroup: [selectedRow.adgroup],
          promoType: [selectedRow.promo_type],
          offer: [selectedRow.offer]
        });
      });

      this.state.selectedCompareRow.forEach(promoType => {
        timePeriodArray.push({
          timePeriod:
            moment(promoType.start_date).format("MM-DD-YYYY") +
            " - " +
            moment(promoType.end_date).format("MM-DD-YYYY")
        });
      });

      this.props.getCompareGraph({
        compareGraphRequestArray
      });

      this.setState({
        showOfferGraph: true,
        timePeriod: timePeriodArray
      });

      scroll.scrollToBottom();
    } else {
      this.setState({
        showMaximumSelectionWarning: true
      });
      setTimeout(() => {
        this.setState({
          showMaximumSelectionWarning: false
        });
      }, 3000);
    }
  };

  downloadCompareData = () => {
    if (this.state.rowDataForCompare.length > 0) {
      // const params = {
      //   columnGroups: true,
      //   allColumns: true,
      //   fileName: "CompareOffersData"
      // };
      //this.gridApi.exportDataAsCsv(params);
      this.setState({ exportCompareData: true });
    } else {
      this.setState({ showTableDataEmptyWarning: true });
      setTimeout(() => {
        this.setState({ showTableDataEmptyWarning: false });
      }, 3000);
    }
  };
  exportDataHandler = () => {
    this.setState({ exportCompareData: false });
  };
  clearData = value => {
    if (value === "adgroup") {
      this.setState({
        adgroupSelectedValue: [],
        adgroupListForCompare: []
      });
    } else if (value === "promoType") {
      this.setState({
        promoTypeSelectedValue: [],
        promoTypeForCompare: []
      });
    } else if (value === "offer") {
      this.setState({
        offerSelectedValue: [],
        offerValueForCompare: []
      });
    } else if (value === "shop") {
      this.setState({
        shopSelectedValue: [],
        shopListForCompare: []
      });
    }
  };
  submitGA(action,label){
    PromoCompareGA({ action, label});
  }

  render() {
    let tableData = {
      header: ColumnDef.colDefForCompare,
      defaultColDef: ColumnDef.defaultColDef,
      data: [] //this.state.rowDataForCompare
    };
    return (
      <div>
        <div className="alertDivs">
          {this.state.showMaximumSelectionWarning ? (
            <AlertDanger
              message="Maximum of 3 selections allowed!"
              show={this.state.showMaximumSelectionWarning}
            />
          ) : null}

          {this.state.showDataExistsWarning ? (
            <AlertDanger
              message="Data already exists for this selection!"
              show={this.state.showDataExistsWarning}
            />
          ) : null}

          {this.state.noDataWarning ? (
            <AlertDanger
              message="No data for this selection!"
              show={this.state.noDataWarning}
            />
          ) : null}

          {this.state.showMakeAllSelectionWarning ? (
            <AlertDanger
              message="Please make selection for all mandatory fields!"
              show={this.state.showMakeAllSelectionWarning}
            />
          ) : null}

          {this.state.showTableDataEmptyWarning ? (
            <AlertDanger
              message="Table data is empty!"
              show={this.state.showTableDataEmptyWarning}
            />
          ) : null}

          {this.state.dateRangeExceededAlert ? (
            <AlertDanger
              message="A maximum date range of 3 months can be selected!"
              show={this.state.dateRangeExceededAlert}
            />
          ) : null}

          {this.state.waitAlert ? (
            <div style={{ margin: "0rem 2rem" }}>
              <AlertLoader
                message="Loading...Please wait..."
                show={this.state.showNoValueClickedWarning}
              />
            </div>
          ) : null}
        </div>
        <div className="stylingForCompareCard">
          <PageLoader loader={this.props.loader || this.state.tableLoader}>
            <div className=" card cardStyles">
              <h4 className="promoHeading">Promo Compare</h4>
              <div
                className="row selectionsDiv mx-2"
                style={{ marginBottom: "1%" }}
              >
                <div
                  className="col-md-2 pr-0 compare-date-range-picker"
                  style={{ marginLeft: "2% !important" }}
                >
                  {/* compare-date-range-picker pl-4 */}
                  <label className="promoLbels">
                    Time Period <span className="requiredField"> * </span>
                  </label>
                  <DateRangePicker
                    focusedInput={this.state.focusedInput}
                    onFocusChange={focusedInput =>
                      this.setState({ focusedInput })
                    }
                    startDate={this.state.startDate}
                    endDate={this.state.endDate}
                    onDatesChange={({ startDate, endDate }) => {
                      this.onDatesChange({ startDate, endDate });
                      this.submitGA("Filter", "Selected_Compare_Timeperiod");
                    }}
                    showClearDates
                    isOutsideRange={() => false}
                    renderMonthElement={true}
                    isOutsideRange={day => this.isOutsideRangeHandler(day)}
                  />
                </div>
                <div className="col-md-2 pl-4 pr-0">
                  <div
                    className={
                      this.state.shopSelectionDisabled ? "disable-true" : ""
                    }
                  >
                    <label className="promoLbels">Shop</label>
                    <Multiselect
                      placeholder="Select Shop"
                      optionsArray={this.state.shopListArray}
                      onDropDownChange={value => {
                        this.shopSelected(
                          value,
                          this.state.compareFiltersChanged,
                          this.state.shopListArray
                        );
                        this.submitGA("Filter", "Selected_Compare_Shop");
                      }}
                      isDisabled={this.state.shopSelectionDisabled}
                      dropdownValue={this.state.shopSelectedValue}
                    />
                    {this.state.shopSelectedValue.length ? (
                      <i
                        className="fa fa-times close3"
                        style={{ left: "77%" }}
                        aria-hidden="true"
                        onClick={() => {
                          this.clearData("shop");
                          this.submitGA("Filter", "Selected_Compare_Shop");
                        }}
                      />
                    ) : null}{" "}
                  </div>
                </div>
                <div className="col-md-2 pl-4 pr-0">
                  <label className="promoLbels">AdGroup</label>
                  <div
                    className={
                      this.state.adgroupSelectionDisabled ? "disable-true" : ""
                    }
                  >
                    <Multiselect
                      placeholder="Select AdGroup"
                      optionsArray={this.state.adgroupListArray}
                      onDropDownChange={value => {
                        this.adGroupSelected(
                          value,
                          this.state.compareFiltersChanged,
                          this.state.adgroupListArray
                        );
                        this.submitGA("Filter", "Selected_Compare_Adgroup");
                      }}
                      isDisabled={this.state.adgroupSelectionDisabled}
                      dropdownValue={this.state.adgroupSelectedValue}
                    />
                    {this.state.adgroupSelectedValue.length ? (
                      <i
                        className="fa fa-times close3"
                        aria-hidden="true"
                        onClick={() => {
                          this.clearData("adgroup");
                          this.submitGA("Filter", "Selected_Compare_Adgroup");
                        }}
                      />
                    ) : null}{" "}
                  </div>
                </div>
                <div className="col-md-2 pl-4 pr-0">
                  <label className="promoLbels">Promo Type</label>
                  <div
                    className={
                      this.state.promoTypeSelectionDisabled
                        ? "disable-true"
                        : ""
                    }
                  >
                    <Multiselect
                      placeholder="Select Promo Type"
                      optionsArray={this.state.promoTypeArray}
                      onDropDownChange={value => {
                        this.promoTypeSelected(
                          value,
                          this.state.compareFiltersChanged,
                          this.state.promoTypeArray
                        );
                        this.submitGA("Filter", "Selected_Compare_PromoType");
                      }}
                      isDisabled={this.state.promoTypeSelectionDisabled}
                      dropdownValue={this.state.promoTypeSelectedValue}
                    />
                    {this.state.promoTypeSelectedValue.length ? (
                      <i
                        className="fa fa-times close3"
                        aria-hidden="true"
                        onClick={() => {
                          this.clearData("promoType");
                          this.submitGA("Filter", "Selected_Compare_PromoType");
                        }}
                      />
                    ) : null}{" "}
                  </div>
                </div>
                <div className="col-md-2 pl-4 pr-0">
                  <label className="promoLbels">Offer</label>
                  <div
                    className={
                      this.state.offerSelectionDisabled ? "disable-true" : ""
                    }
                  >
                    <Multiselect
                      placeholder="Select Offer"
                      optionsArray={this.state.offerListArray}
                      onDropDownChange={value => {
                        this.offerSelected(
                          value,
                          this.state.compareFiltersChanged,
                          this.state.offerListArray
                        );
                        this.submitGA("Filter", "Selected_Compare_Offer");
                      }}
                      isDisabled={this.state.offerSelectionDisabled}
                      dropdownValue={this.state.offerSelectedValue}
                    />
                    {this.state.offerSelectedValue.length ? (
                      <i
                        className="fa fa-times close3"
                        aria-hidden="true"
                        onClick={() => {
                          this.clearData("offer");
                          this.submitGA("Filter", "Selected_Compare_Offer");
                        }}
                      />
                    ) : null}{" "}
                  </div>
                </div>

                <div className="col-md-2">
                  <p className="promoLbels mb-2" style={{ color: "white" }}>
                    {" "}
                    .
                  </p>
                  <button
                    type="button"
                    className="btn btn-outline-primary promo3Buttons"
                    style={{ width: "100%" }}
                    onClick={e => {
                      this.compareButtonClicked(
                        this.state.resultStartDate,
                        this.state.resultEndDate,
                        this.state.shopListForCompare,
                        this.state.adgroupListForCompare,
                        this.state.promoTypeForCompare,
                        this.state.offerValueForCompare
                      );
                      this.submitGA("Click", "Clicked_Compare_AddToCompareButton");
                    }}
                    disabled={this.state.compareButtonDisabled}
                  >
                    Add to compare
                  </button>
                </div>
              </div>
            </div>

            {this.state.showCompareTableView ? (
              <div
                className=" card cardStyles stylingForCompareCard"
                style={{ padding: "0.5%" }}
              >
                <div className="card" style={{ width: "100%", height: "100%" }}>
                  <DataTable
                    parentId="myGrid"
                    parentStyle={{
                      height: "calc(100vh - 300px)",
                      width: "100%"
                    }}
                    parentClass="ag-theme-balham"
                    tableData={tableData}
                    rowSelection={this.state.rowSelection}
                    selectOfferRow={this.selectOfferRow}
                    suppressRowClickSelection={true}
                    compareTableDataArray={this.state.compareTableDataArray}
                    updateTable={this.state.updateTable}
                    updateTableHandler={this.updateTableHandler}
                    resetTable={this.state.resetTable}
                    resetTableHandler={this.resetTableHandler}
                    exportCompareData={this.state.exportCompareData}
                    exportDataHandler={this.exportDataHandler}
                    // autoSizeCol={true} //col messed up if uncommented
                  />
                </div>

                <div className="row">
                  <div className="col-md-6 " />
                  <div className="col-md-6 row">
                    <div className="col-md-4">
                      {" "}
                      <button
                        type="button"
                        className="btn btn-outline-primary promo3Buttons"
                        style={{ margin: "14% 4px 4px", width: "100%" }}
                        onClick={e => {
                          this.resetTableData(e);
                          this.submitGA("Click", "Clicked_Compare_ResetTableDataButton");
                        }}
                        disabled={this.state.resetTableDataDisabled}
                      >
                        Reset table data
                      </button>{" "}
                    </div>
                    <div className="col-md-4">
                      {" "}
                      <button
                        type="button"
                        className="btn btn-outline-primary promo3Buttons"
                        style={{ margin: "14% 4px 4px", width: "100%" }}
                        onClick={e => {
                          this.addForGraph(e);
                          this.submitGA("Click", "Clicked_Compare_AddForGraphButton");
                        }}
                        disabled={this.state.addForGraphBtnDisabled}
                      >
                        Add for graph
                      </button>
                    </div>

                    <div className="col-md-4">
                      {" "}
                      <button
                        type="button"
                        className="btn btn-outline-primary promo3Buttons"
                        style={{ margin: "14% 4px 4px", width: "100%" }}
                        onClick={e => {
                          this.downloadCompareData(e);
                          this.submitGA("Click", "Clicked_Compare_DownloadButton");
                        }}
                        disabled={this.state.downloadTableDataDisabled}
                      >
                        Download
                      </button>{" "}
                    </div>
                  </div>
                </div>
              </div>
            ) : null}

            {this.state.showOfferGraph ? (
              <Element name="showCompareGraph" className="element">
                <CompareGraphTab
                  compareGraphData={this.state.compareGraphData}
                  timePeriodArray={this.state.timePeriod}
                />
              </Element>
            ) : null}
          </PageLoader>
        </div>
      </div>
    );
  }
}

ComparePromotionNew.propTypes = {
  getCompareFilters: PropTypes.func.isRequired,
  getCompareFiltersShop: PropTypes.func.isRequired,
  getCompareFiltersPromoType: PropTypes.func.isRequired,
  getCompareFiltersOffer: PropTypes.func.isRequired,
  getCompareData: PropTypes.func.isRequired,
  getCompareGraph: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  compareFilters: state.compareReducer.compareFilters,
  compareFiltersShop: state.compareReducer.compareFiltersShop,
  compareFiltersPromoType: state.compareReducer.compareFiltersPromoType,
  compareFiltersOffer: state.compareReducer.compareFiltersOffer,
  compareData: state.compareReducer.compareData,
  compareGraphData: state.compareReducer.compareGraph,
  loader: state.compareReducer.loader
});

export default connect(mapStateToProps, {
  getCompareFilters,
  getCompareFiltersShop,
  getCompareFiltersPromoType,
  getCompareFiltersOffer,
  getCompareData,
  getCompareGraph
})(withRouter(ComparePromotionNew));
