import React, { Component } from "react";
import Select from "react-select";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import PageLoader from "../../../../utils/Loader";
import AlertDanger from "../../../../utils/AlertMessage/alertDanger";
import AlertSuccess from "../../../../utils/AlertMessage/alertSuccess";
import "./AdGroupStyling.css";
import {
  editReferenceSku,
  infoReferenceSku,
  similarSkuProductsList
} from "../../../../redux/actions/referenceSkuActions";
import {
  updateProductsWithAdgroup,
  getAdgroupData,
  productListFilters
} from "../../../../redux/actions/adgroup";
import RefSkuChildMessageRendrer from "../ReferenceSku/RefSkuChildMessageRendrer";
import RefSkuActionRenderer from "../ReferenceSku/RefSkuActionRenferer";
import { getCumulativeFilters } from "../../../../redux/actions/summaryAction";
import _ from "lodash";
import DataTable from "../../../tables";
import ColumnDef from "../../../tables/colDefs/SkuListColDefs";
import { Element, scroller } from "react-scroll";
import ReactFileReader from "react-file-reader";
import ReferenceSkuDetails from "../../../../utils/ReferenceSkuDetails";
import Modal from "react-awesome-modal";
import { AdgroupMasterGA } from '../../../../utils/GA/GAUtils';
import { alertTimer } from "../../../../utils/alertTimer";

let selectedShop = [];
let selectedMerchCat = [];
let selectedAdgroups = [];
var csvSkuList = "";
let selectedMerchCatArray = [];
var productData = [];
let selectedArticle = [];
let selectedCharCollection = [];

let sku_file = null;
class AdGroupSearchProducts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rowSelection: "multiple",
      showSkuTable: false,
      selectedShopState: null,
      csvSkuList: "",
      skuNameInput: "",
      showcategoryFilter: false,
      showSearchFilter: false,
      showFileFilter: false,
      showAlertFailure: false,
      showSelectProductAlert: false,
      showSpanErrorMessage: false,
      selectedMerchCatValue: [],
      selectedAdgroupvalue: [],
      selectedArticleStatus: [],
      selectedCharCollection: [],
      productData: null,
      minThreeValuesAlert: false,
      selectedShop: [],
      adGroupItems: [],
      skuIdArray: [],
      csvUploadSelected: false,
      showDifferenceAlert: false,
      differenceString: "",
      selectRefSkuError: false,
      csvFileName: "",
      refSkuOptions: [],
      refSkuInfoResponse: "",
      showDetailPopup: false,
      refreshRows: false,
      fileUploadedAlert: false,
      articleStatusOptions: [],
      charCollectionOptions: [],
      showLoader: false,
      newRowModified: false
    };
  }

  async componentDidMount() {
    this.tableDataUpdateHandler();

    this.props.getCumulativeFilters({
      required_column: [
        {
          required_column: "h1"
        },
        {
          required_column: "h3"
        }
      ]
    });

    sku_file = !_.isEmpty(localStorage.getItem("sample_skufile_link"))
      ? JSON.parse(localStorage.getItem("sample_skufile_link"))
      : "#";

    let res = await this.props.getAdgroupData("");
    let adGroupItems = [];
    if (!_.isEmpty(res.data)) {
      res.data.map(val => {
        adGroupItems.push({
          value: val.name,
          label: val.name
        });
        return val;
      });
    }

    let resForArticleStatus = await this.props.productListFilters({
      required_column: "article_status",
      searchKey: ""
    });

    let resForCollection = await this.props.productListFilters({
      required_column: "collection",
      searchKey: ""
    });

    if (resForArticleStatus.status) {
      let articleStatusOptions = _.map(
        resForArticleStatus.data[0].article_status,
        item => {
          return {
            value: item,
            label: item
          };
        }
      );
      this.setState({ articleStatusOptions });

    }

    if (resForCollection.status) {
      let charCollectionOptions = _.map(
        resForCollection.data[0].collection,
        item => {
          return {
            value: item,
            label: item
          };
        }
      );
      this.setState({ charCollectionOptions });
    }

    this.setState({ adGroupItems });
    let refSkuSearchRes = await this.props.similarSkuProductsList({
      modelable_sku: true,
      search_key: ""
    });
    let list = _.map(refSkuSearchRes.data, item => {
      return {
        value: item.unique_id1,
        label: item.unique_id1
      };
    });
    list.unshift({ value: "none", label: "none" });
    this.setState({ refSkuOptions: list });
  }
  componentDidUpdate(prevProps, prevState) {
    let skuIdArray = [];
    if (this.props !== prevProps) {
      if (prevProps.getProductAdgroup !== this.props.getProductAdgroup) {
        if (this.props.getProductAdgroup.status) {
          this.tableDataUpdateHandler();

          if (this.state.csvUploadSelected) {
            this.props.getProductAdgroup.data.map(productdata => {
              skuIdArray.push(productdata.unique_id1);
              return productdata;
            });

            this.setState({
              skuIdArray
            });

            let isDifferent = _.difference(this.state.csvSkuList, skuIdArray);

            if (isDifferent.length) {
              this.setState({
                differenceString: isDifferent.toString(),
                showDifferenceAlert: true
              });

              setTimeout(() => {
                this.setState({
                  showDifferenceAlert: false
                });
              }, alertTimer);
            }
          }
        } else {
          this.props.history.push("/promotion");
        }
      }

      if (this.props.couponSaved) {
        this.setState({
          productData: [],
          skuNameInput: "",
          showSearchFilter: false,
          showSkuTable: false,
          showFileFilter: false,
          selectedShop: "",
          selectedMerchCatValue: "",
          selectedAdgroupvalue: "",
          showcategoryFilter: false
        });
        this.props.couponSavedHandler();
      }

      if (this.props.newRowModified) {
        let skuNameInputArray = _.filter(
          this.state.skuNameInput.split(","),
          item => item.length
        );
        if (this.state.skuNameInput.length >= 3) {
          this.props.updateProductsWithAdgroup({
            sku: skuNameInputArray
          });
          // cant use asyn await since it causes maxdepth exceeded issue
        }
        this.setState({ newRowModified: this.props.newRowModified });
        //storing in state for further use in tabHandler
        this.props.newRowModifiedHandler();
      }
    }
  }

  collectionKeyDownHandler = async val => {
    if (val.length) {
      this.setState({
        showLoader: true
      });
      let resForCollection = await this.props.productListFilters({
        required_column: "collection",
        searchKey: val
      });

      if (resForCollection.status) {
        this.setState({
          showLoader: false
        });
        let charCollectionOptions = _.map(
          resForCollection.data[0].collection,
          item => {
            return {
              value: item,
              label: item
            };
          }
        );
        this.setState({ charCollectionOptions });
      }
    }
  };

  // showRefSkuDetail = async rowData => {
  //   if (Object.keys(rowData).length) {
  //     let infoRefSkuResponse = await this.props.infoReferenceSku(
  //       rowData.selected_sku
  //     );

  //     if (infoRefSkuResponse.status) {
  //       this.setState({
  //         showDetailPopup: true,
  //         refSkuInfoResponse: infoRefSkuResponse.data
  //       });
  //     }
  //   } else {
  //     this.setState({
  //       selectRefSkuError: true,
  //       showDetailPopup: false
  //     });

  //     setTimeout(() => {
  //       this.setState({
  //         selectRefSkuError: false
  //       });
  //     }, 3000);
  //   }
  // };

  saveRefSkuDetail = async rowData => {
    let params = null;
    if (Object.keys(rowData).length) {
      this.setState({
        adgroupLoader: true
      });
      params = {
        skuId: rowData.unique_id1,
        referenceSku: rowData.selected_sku === "none" ? null : rowData.selected_sku
      };
      let saveRefSkuResponse = await this.props.editReferenceSku(params);

      if (saveRefSkuResponse.status) {
        let { productData } = this.state;

        let found = _.find(productData.data, {
          unique_id1: rowData.unique_id1
        });
        found.reference_sku = rowData.selected_sku;

        this.setState({
          productData,
          adgroupLoader: false,
          savedRefSkuMsg: true
        });

        setTimeout(() => {
          this.setState({
            savedRefSkuMsg: false
          });
        }, alertTimer);
        let skuNameInputArray = _.filter(
          this.state.skuNameInput.split(","),
          item => item.length
        );
        if (this.state.skuNameInput.length >= 3) {
          let isRowPresentInReviewTable = _.find(this.props.productDataFromChild, {
            unique_id1: rowData.unique_id1
          }),
          isRowPresentInExcludeTable = _.find(this.props.excludedDataFromChild, {
            unique_id1: rowData.unique_id1
          })
          if(!_.isEmpty(isRowPresentInReviewTable) || !_.isEmpty(isRowPresentInExcludeTable)){
            this.setState({ newRowModified: rowData.unique_id1 })
          }
          this.props.updateProductsWithAdgroup({
            sku: skuNameInputArray
          });
          
        }
      }
    } else {
      this.setState({
        selectRefSkuError: true
      });

      setTimeout(() => {
        this.setState({
          selectRefSkuError: false
        });
      }, alertTimer);
    }
  };

  refSkuSearchHandler = async (key, id) => {
    let { productData } = this.state;
    let refSkuSearchRes = await this.props.similarSkuProductsList({
      modelable_sku: true,
      search_key: key
    });
    let list = _.map(refSkuSearchRes.data, item => {
      return {
        value: item.unique_id1,
        label: item.unique_id1
      };
    });
    list.unshift({ value: "none", label: "none" });

    let found = _.find(productData.data, { unique_id1: id });
    found.refSkuOptions = list;

    this.setState({ refSkuOptions: list, refreshRows: true, productData });
  };
  tableDataUpdateHandler = () => {
    let dataArr = [];
    if (this.props.getProductAdgroup) {
      let clonedData = _.cloneDeep(this.props.getProductAdgroup.data);
      dataArr = clonedData.map(item => {
        // item.showRefSkuDetail = this.showRefSkuDetail;
        item.saveRefSkuDetail = this.saveRefSkuDetail;
        item.refSkuSearchHandler = this.refSkuSearchHandler;
        item.refSkuOptions = this.state.refSkuOptions;
        item.original_reference_sku= item.reference_sku;
        return item;
      });
    }
    productData = {
      header: ColumnDef.skuHeader, //createAdgroupHeader,
      defaultColDef: ColumnDef.deleteDefaultColDef,
      data: dataArr
    };
    if (this.state.newRowModified) {
      // got new res n sending the new data back to parent
      let found = _.find(dataArr, { unique_id1: this.state.newRowModified });

      this.props.newRowModifiedHandler(found);
      this.setState({ newRowModified: false })
    }
    this.setState({
      productData
    });
  };
  showFilterData = () => {
    this.setState({
      showcategoryFilter: true,
      showSearchFilter: false,
      showFileFilter: false,
      showSkuTable: false
    });
    scroller.scrollTo("showcategoryFilter", {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart"
    });
  };
  showSearchData = () => {
    this.setState({
      showcategoryFilter: false,
      showSearchFilter: true,
      showFileFilter: false,
      showSkuTable: false
    });
    scroller.scrollTo("showSearchFilter", {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart"
    });
  };
  showFileUploadData = () => {
    this.setState({
      showcategoryFilter: false,
      showSearchFilter: false,
      showFileFilter: true,
      showSkuTable: false
    });
    scroller.scrollTo("showFileFilter", {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart"
    });
  };

  handleFiles = data => {
    var reader = new FileReader(),
      csvSkuList2 = "",
      csvSkuListRendered = [];

    reader.onload = e => {
      csvSkuList = reader.result
        .replace(/\n/gi, " ")
        .split(/(\s+)/)
        .splice(1, reader.result.length);

      this.setState({
        csvSkuList,
        csvFileName: data[0].name,
        fileUploadedAlert: true
      });
      setTimeout(() => {
        this.setState({ fileUploadedAlert: false });
      }, alertTimer);
    };
    reader.readAsText(data[0]);

    setTimeout(() => {
      csvSkuList2 = _.filter(csvSkuList, dataId => {
        return dataId.length > 2;
      });

      csvSkuList2.map(skuId => {
        if (skuId.length < 8) {
          csvSkuListRendered.push(skuId.padStart(8, "0"));
        } else {
          csvSkuListRendered.push(skuId);
        }
        return skuId;
      });
      this.setState({
        csvSkuList: csvSkuListRendered
      });
    }, 500);
  };

  onSkuEnter = e => {
    this.setState({
      skuNameInput: `${e.target.value}`.replace(/[\s,]+/g, ",")
    });
  };

  shopSelected = value => {
    selectedShop = [value.value];

    this.setState({
      shop_desc: value.value,
      selectedShopState: selectedShop,
      showPlaceholder: true,
      selectedShop: value
    });
    this.submitGA("Click", "Selected_ShopDescription");
  };

  merchCatSelected = value => {
    selectedMerchCat = value;

    selectedMerchCatArray =
      selectedMerchCat.length === 0
        ? []
        : selectedMerchCat.map(merchItem => {
            return merchItem.value;
          });

    this.setState({
      selectedMerchCatValue: value
    });
    this.submitGA("Click", "Selected_MerchantCategory");
  };

  adGroupSelected = value => {
    selectedAdgroups = value.map(valueOfAdGroup => {
      return valueOfAdGroup.label;
    });

    this.setState({
      selectedAdgroupvalue: value
    });
    this.submitGA("Click", "Selected_Adgroup");
  };

  articleStatusSelected = value => {
    selectedArticle = value.map(selectedArticle => {
      return selectedArticle.label;
    });

    this.setState({
      selectedArticleStatus: value
    });
    this.submitGA("Click", "Selected_ArticleStatus");
  };

  charCollectionSelected = value => {
    selectedCharCollection = value.map(selectedCharCollection => {
      return selectedCharCollection.label;
    });

    this.setState({
      selectedCharCollection: value
    });
    this.submitGA("Click", "Selected_CharCollection");
  };

  adgroupKeyDownHandler = async val => {
    let adGroupItems = [];
    let resForAdgroup = await this.props.getAdgroupData(val);

    if (!_.isEmpty(resForAdgroup.data)) {
      resForAdgroup.data.map(resData => {
        adGroupItems.push({
          value: resData.name,
          label: resData.name
        });
        return resData;
      });

      this.setState({
        adGroupItems
      });
    }
  };

  handleSkuModal = () => {
    if (this.gridApi.getSelectedRows().length) {
      this.props.sendSelectedProducts(this.gridApi.getSelectedRows());
      this.gridApi.deselectAll();
    } else {
      this.setState({
        showSelectProductAlert: true
      });
      setTimeout(() => {
        this.setState({
          showSelectProductAlert: false
        });
      }, alertTimer);
    }
  };

  showProdModalForCsv = e => {
    productData = [];
    //Import .CSV file
    if (this.state.csvSkuList.length !== 0) {
      this.props.updateProductsWithAdgroup({
        sku: this.state.csvSkuList
      });
      this.setState({
        showSkuTable: true,
        csvUploadSelected: true,
        showSpanErrorMessage: true
      });

      scroller.scrollTo("showSkuTable", {
        duration: 800,
        delay: 0,
        smooth: "easeInOutQuart"
      });
    } else {
      this.setState({
        showAlertFailure: true,
        showSkuTable: false
      });
    }

    setTimeout(() => {
      this.setState({
        showAlertFailure: false
      });
    }, alertTimer);
  };

  showProdModalForInput = e => {
    productData = [];
    let skuNameInputArray = _.filter(
      this.state.skuNameInput.split(","),
      item => {
        return item.length;
      }
    );

    //Enter SKU name
    if (this.state.skuNameInput.length >= 3) {
      this.props.updateProductsWithAdgroup({
        sku: skuNameInputArray
      });
      this.setState({
        showSkuTable: true,
        csvUploadSelected: false,
        showSpanErrorMessage: true
      });

      scroller.scrollTo("showSkuTable", {
        duration: 800,
        delay: 0,
        smooth: "easeInOutQuart"
      });
    } else {
      this.setState({
        minThreeValuesAlert: true,
        showSkuTable: false
      });
    }

    setTimeout(() => {
      this.setState({
        minThreeValuesAlert: false
      });
    }, alertTimer);
  };

  showProdModalForFilters = e => {
    productData = [];
    let actionParameter = null;

    actionParameter = {
      h3: selectedShop,
      h1: selectedMerchCatArray,
      adgroup: selectedAdgroups,
      article_status: selectedArticle,
      char_collection: selectedCharCollection
    };
    this.props.updateProductsWithAdgroup(actionParameter);

    this.setState({
      showSkuTable: true,
      csvUploadSelected: false,
      showSpanErrorMessage: true
    });

    if (
      this.state.selectedShopState === null &&
      selectedMerchCatArray.length === 0 &&
      selectedAdgroups.length === 0 &&
      !this.state.selectedArticleStatus &&
      !this.state.selectedCharCollection.length
    ) {
      this.setState({
        showAlertFailure: true,
        showSkuTable: false
      });
    }

    setTimeout(() => {
      this.setState({
        showAlertFailure: false
      });
    }, alertTimer);

    scroller.scrollTo("showSkuTable", {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart"
    });
  };
  clearData = value => {
    if (value === "adgroup") {
      selectedAdgroups = "";
      this.setState({
        selectedAdgroupvalue: []
      });
    } else if (value === "merch") {
      selectedMerchCatArray = "";
      this.setState({
        selectedMerchCatValue: []
      });
    } else if (value === "shop") {
      selectedShop = null;
      this.setState({
        selectedShop: null,
        selectedShopState: null
      });
    } else if (value === "article") {
      selectedArticle = null;
      this.setState({
        selectedArticleStatus: []
      });
    } else if (value === "collection") {
      selectedCharCollection = null;
      this.setState({
        selectedCharCollection: []
      });
    }
  };
  refreshRowsHandler = () => {
    this.setState({ refreshRows: false });
  };
  onSelectionChanged = (row) => {
    this.submitGA("Click", "Selected_SelectProducts_SKU");
  }
  submitGA(action,label){
    AdgroupMasterGA({ action, label});
  }
  render() {
    let shopArray = [],
      merchItems = [];
    let filtersVar = this.props.summaryCumulativeFilters;

    if (!_.isEmpty(filtersVar)) {
      filtersVar.map(item => {
        if (item[0].h3) {
          item[0].h3.map(val => {
            shopArray.push({
              value: val,
              label: val
            });
            return val;
          });
        }
        if (item[0].h1) {
          item[0].h1.map(val => {
            merchItems.push({
              value: val,
              label: val
            });
            return val;
          });
        }
        return item;
      });
    }
    let refSkuDetailTableData = {
      header: ColumnDef.skuHeader,
      defaultColDef: ColumnDef.deleteDefaultColDef,
      data: this.state.refSkuInfoResponse
    };
    return (
      <div>
        <div className="alertDivs">
          {this.state.selectRefSkuError ? (
            <div>
              <AlertDanger
                message="Please select Reference SKU to proceed"
                show={this.state.selectRefSkuError}
              />
            </div>
          ) : null}
        </div>

        <PageLoader
          loader={
            this.props.loader ||
            this.props.filterLoader ||
            this.state.showLoader
          }
        >
          <div>
            <div className="alertDivs">
              {this.state.fileUploadedAlert ? (
                <AlertSuccess
                  message="File Uploaded Successfully!"
                  show={this.state.fileUploadedAlert}
                />
              ) : null}
              {this.state.showAlertFailure ? (
                <AlertDanger
                  message="Please make an appropriate selection!"
                  show={this.state.showAlertFailure}
                />
              ) : null}
              {this.state.showSelectProductAlert ? (
                <AlertDanger
                  message="Please select a product!"
                  show={this.state.showSelectProductAlert}
                />
              ) : null}
              {this.state.minThreeValuesAlert ? (
                <AlertDanger
                  message="Please enter minimum three values!"
                  show={this.state.minThreeValuesAlert}
                />
              ) : null}
              {this.state.showDifferenceAlert ? (
                <AlertDanger
                  message={`Product data doesn't exist for ${this.state.differenceString} `}
                  show={this.state.showDifferenceAlert}
                />
              ) : null}
            </div>
            <div className="childCompStyling">
              <div className="card pb-3 pt-4 text-center">
                <div className="row w-75 mx-auto">
                  <div className="col-md-3">
                    <button
                      type="button"
                      className="btn btn-primery btn-circle btn-lg searchFilterIcons1"
                      onClick={() => {
                        this.showFilterData()
                        this.submitGA("Click", "Clicked_FilterButton");
                      }}
                    >
                      <i
                        id="filterIco1"
                        className="fa fa-filter promoIcon"
                        aria-hidden="true"
                      />
                    </button>
                    <p className="prmoIconText1">Filter</p>
                  </div>
                  <div className="col-md-1">
                    <p className="textMiddle">Or</p>
                  </div>
                  <div className="col-md-3">
                    <button
                      type="button"
                      className="btn btn-primery btn-circle btn-lg searchFilterIcons2"
                      onClick={() => {
                        this.showSearchData();
                        this.submitGA("Click", "Clicked_SearchButton");
                      }}
                    >
                      <i
                        id="filterIco2"
                        className="fa fa-search promoIcon"
                        aria-hidden="true"
                      />
                    </button>
                    <p className="prmoIconText1">Search</p>
                  </div>
                  <div className="col-md-1">
                    <p className="textMiddle">Or</p>
                  </div>
                  <div className="col-md-3">
                    <button
                      type="button"
                      className="btn btn-primery btn-circle btn-lg searchFilterIcons3"
                      onClick={() => {
                        this.showFileUploadData();
                        this.submitGA("Click", "Clicked_UploadButton");
                      }}
                    >
                      {" "}
                      <i
                        id="filterIco3"
                        className="fa fa-upload promoIcon"
                        aria-hidden="true"
                      />
                    </button>
                    <p className="prmoIconText1">Upload</p>
                  </div>
                </div>
              </div>

              <div className=" createClusterDiv">
                <Element name="showcategoryFilter" className="element">
                  {this.state.showcategoryFilter ? (
                    <div className="card">
                      <div className="row filter1 select-sku-filters mx-2">
                        <div className="col">
                          <p className="adGroupSearchProdText">
                            Shop Description
                          </p>
                          <Select
                            placeholder="Select Shop Description"
                            options={shopArray}
                            value={this.state.selectedShop}
                            onChange={value => this.shopSelected(value)}
                          />

                          {!_.isEmpty(this.state.selectedShop) ? (
                            <i
                              className="fa fa-times close3 create-adgrp-close"
                              aria-hidden="true"
                              onClick={() => this.clearData("shop")}
                            />
                          ) : null}
                        </div>
                        <div className="col">
                          <p className="adGroupSearchProdText">
                            Merchant Category
                          </p>

                          <ReactMultiSelectCheckboxes
                            placeholderButtonLabel="Select Merch Cat"
                            options={merchItems}
                            onChange={value => this.merchCatSelected(value)}
                            value={this.state.selectedMerchCatValue}
                          />

                          {!_.isEmpty(this.state.selectedMerchCatValue) ? (
                            <i
                              className="fa fa-times close3 create-adgrp-close"
                              aria-hidden="true"
                              onClick={() => this.clearData("merch")}
                            />
                          ) : null}
                        </div>
                        <div className="col">
                          <p className="adGroupSearchProdText">AdGroup</p>

                          <ReactMultiSelectCheckboxes
                            placeholderButtonLabel="Select AdGroup"
                            options={this.state.adGroupItems}
                            onChange={value => {
                              this.adGroupSelected(value);
                            }}
                            onInputChange={val => {
                              this.adgroupKeyDownHandler(val.toUpperCase());
                            }}
                            value={this.state.selectedAdgroupvalue}
                          />

                          {!_.isEmpty(this.state.selectedAdgroupvalue) ? (
                            <i
                              className="fa fa-times close3 create-adgrp-close"
                              aria-hidden="true"
                              onClick={() => this.clearData("adgroup")}
                            />
                          ) : null}
                        </div>
                        <div className="col">
                          <p className="adGroupSearchProdText">
                            Article status
                          </p>
                          <ReactMultiSelectCheckboxes
                            placeholderButtonLabel="Select Article status"
                            options={this.state.articleStatusOptions}
                            value={this.state.selectedArticleStatus}
                            onChange={value => {
                              this.articleStatusSelected(value);
                            }}
                          />

                          {!_.isEmpty(this.state.selectedArticleStatus) ? (
                            <i
                              className="fa fa-times close3 create-adgrp-close"
                              aria-hidden="true"
                              onClick={() => this.clearData("article")}
                            />
                          ) : null}
                        </div>
                        <div className="col">
                          <p className="adGroupSearchProdText">
                            Char collection
                          </p>
                          <ReactMultiSelectCheckboxes
                            placeholderButtonLabel="Select char collection"
                            options={this.state.charCollectionOptions}
                            value={this.state.selectedCharCollection}
                            onChange={value => {
                              this.charCollectionSelected(value);
                            }}
                            onInputChange={val => {
                              this.collectionKeyDownHandler(val.toUpperCase());
                            }}
                          />

                          {!_.isEmpty(this.state.selectedCharCollection) ? (
                            <i
                              className="fa fa-times close3 create-adgrp-close"
                              aria-hidden="true"
                              onClick={() => this.clearData("collection")}
                            />
                          ) : null}
                        </div>
                      </div>
                      <div className="searchProductsBtn1 text-center">
                        <button
                          type="button"
                          className="btn btnPromosearch"
                          onClick={e => {
                            this.showProdModalForFilters(e);
                            this.submitGA("Click", "Clicked_SelectProducts");
                          }}
                          disabled={
                            this.state.selectedShopState === null &&
                            selectedMerchCatArray.length === 0 &&
                            selectedAdgroups.length === 0 &&
                            !this.state.selectedCharCollection.length
                              ? true
                              : false
                          }
                        >
                          Select products
                        </button>
                      </div>
                    </div>
                  ) : null}
                </Element>

                <Element name="showFileFilter" className="element">
                  {this.state.showFileFilter ? (
                    <div
                      className="card"
                      style={{ textAlign: "center", marginTop: "20px" }}
                    >
                      <div className="createClusterDiv">
                        <div
                          className="card csvCardDiv"
                          style={{ width: "35%", margin: "8px auto" }}
                        >
                          <div onClick={() => {
                            this.submitGA("Click", "Clicked_Upload_DownloadSampleFile");
                          }} className="mb-2 text-center">
                            <a
                              href={sku_file}
                              target="_blank"
                              style={{
                                textDecoration: "underline",
                                color: "#2d69eb"
                              }}
                              download
                            >
                              Download sample file
                            </a>
                          </div>
                          <p>
                            <i
                              className="fa fa-upload importIcon"
                              aria-hidden="true"
                              style={{
                                fontSize: "2rem",
                                paddingTop: "5px",
                                color: "#908f8f"
                              }}
                            />
                          </p>
                          <div className="row">
                            <div className="col-md-6">
                              <p id="dragFileText">Drag and Drop File Here</p>
                            </div>
                            <div className="col-md-6">
                              <div
                                id="csv1"
                                className="csvReaderDiv csv-upload-text"
                              >
                                <ReactFileReader
                                  fileTypes={[".csv"]}
                                  handleFiles={data => this.handleFiles(data)}
                                >
                                  <button className="btn"
                                    onClick={() => {
                                      this.submitGA("Click", "Clicked_Upload_UploadExcelFile");
                                    }}
                                  >
                                    Upload Excel file
                                  </button>
                                </ReactFileReader>
                              </div>
                            </div>
                            <p className="w-100 text-center">
                              {this.state.csvFileName}
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="searchProductsBtn1 text-center">
                        <button
                          type="button"
                          className="btn btnPromosearch"
                          onClick={e => {
                            this.showProdModalForCsv(e);
                            this.submitGA("Click", "Clicked_Upload_SelectProducts");
                          }}
                        >
                          Select products
                        </button>
                      </div>
                    </div>
                  ) : null}
                </Element>
              </div>

              <Element name="showSearchFilter" className="element">
                {this.state.showSearchFilter ? (
                  <div className="card" style={{ marginTop: "20px" }}>
                    <div className="inner-addon right-addon">
                      <div className="searchDiv">
                        <p className="adGroupSearchProdText">
                          Search/Copy paste comma-separated SKU's
                        </p>
                        <input
                          id="skuInput"
                          type="text"
                          className="form-control searchTextBoxStyling"
                          placeholder="Search/ Copy-Paste comma-separated Sku's"
                          value={this.state.skuNameInput}
                          onChange={e => this.onSkuEnter(e)}
                        />
                      </div>
                    </div>
                    <div className="searchProductsBtn1 text-center">
                      <button
                        type="button"
                        className="btn btnPromosearch"
                        onClick={e => {
                          this.showProdModalForInput(e);
                          this.submitGA("Click", "Clicked_Search_SelectProducts");
                        }}
                      >
                        Select products
                      </button>
                    </div>
                  </div>
                ) : null}
              </Element>

              <Element name="showSkuTable">
                {this.state.showSkuTable ? (
                  <div className="card cardGap">
                    <div
                      style={{
                        float: "right"
                      }}
                    >
                      <button
                        type="button"
                        className="btn add-sku-btn-styling pipeline-button-class"
                        onClick={() => {
                          this.handleSkuModal()
                          this.submitGA("Click", "Clicked_CheckMark");
                        }}
                      >
                        <i
                          className="fa fa-check"
                          aria-hidden="true"
                          title={null}
                        />
                      </button>
                    </div>

                    <div
                      id="myGridForSkuTable"
                      className="ag-theme-balham skuTableStyling"
                    >
                      <DataTable
                        tableData={this.state.productData}
                        suppressRowClickSelection={true}
                        overlayNoRowsTemplate={
                          this.state.showSpanErrorMessage
                            ? "Please enter a valid SKU"
                            : "Loading..."
                        }
                        getGridAPI={api => {
                          this.gridApi = api;
                        }}
                        rowSelection={this.state.rowSelection}
                        pagination={true}
                        refreshRows={this.state.refreshRows}
                        refreshRowsHandler={this.refreshRowsHandler}
                        frameworkComponents={{
                          refSkuChildMessageRenderer: RefSkuChildMessageRendrer,
                          refSkuActionRenderer: RefSkuActionRenderer
                        }}
                        selectOfferRow={this.onSelectionChanged}
                        // autoSizeCol={true}//col messed up if uncommented
                      />
                    </div>
                  </div>
                ) : null}
              </Element>
            </div>
          </div>
        </PageLoader>

        {this.state.showDetailPopup ? (
          <Modal
            visible={true}
            //width="80%"
            //height="220px"
            width="1000px"
            height="auto"
            effect="fadeInDown"
            onClickAway={() => {
              this.setState({
                showDetailPopup: !this.state.showDetailPopup
              });
            }}
          >
            <PageLoader loader={!this.state.refSkuInfoResponse.length}>
              <div className="closeicon">
                <span style={{ cursor: "pointer" }}>
                  <i
                    className="fa fa-times"
                    aria-hidden="true"
                    onClick={() => this.setState({ showDetailPopup: false })}
                  />
                </span>
              </div>
              <h5 className="text-center font-weight-bold mt-1">
                Reference SKU details
              </h5>
              <ReferenceSkuDetails infoRefSkuData={refSkuDetailTableData} />
            </PageLoader>
          </Modal>
        ) : null}
      </div>
    );
  }
}

function isFirstColumn(params) {
  var displayedColumns = params.columnApi.getAllDisplayedColumns();
  var thisIsFirstColumn = displayedColumns[0] === params.column;
  return thisIsFirstColumn;
}

AdGroupSearchProducts.propTypes = {
  updateProductsWithAdgroup: PropTypes.func.isRequired,
  getCumulativeFilters: PropTypes.func.isRequired,
  productListFilters: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  getFilterDataShop: state.adGroupData.getFilterDataShop,
  getFilterDataMerch: state.adGroupData.getFilterDataMerch,
  getAdgroupData: state.adGroupData.getAdgroupData,
  getFilterDataAdgroup: state.adGroupData.getFilterDataAdgroup,
  getProductAdgroup: state.adGroupData.getProductAdgroup,
  loader: state.adGroupData.loader,
  filterLoader: state.summaryData.loading,
  adGroupData: state.adGroupData,
  summaryCumulativeFilters: state.summaryData.summaryCumulativeFilters
});

export default connect(mapStateToProps, {
  updateProductsWithAdgroup,
  getCumulativeFilters,
  productListFilters,
  getAdgroupData,
  editReferenceSku,
  infoReferenceSku,
  similarSkuProductsList
})(withRouter(AdGroupSearchProducts));
