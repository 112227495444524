import React,{useState, useEffect} from "react";
import { Button } from "antd";

const PromoApprovalInputRenderer = props => {
  const handleNotesUpdateModal = () => {
    props.data.handleNotesUpdateModal(true,props,props.data)
  }
  return (
    <>
      {/* <Button type="primary"  onClick={()=>handleNotesUpdateModal()} style={{width : "100px", marginLeft : "25px", height:"25px"}}><span style={{ fontSize: "12px"}}>Click For Notes</span></Button> */}

     {props.data && props.data.note_mar && props.data.note_mar ? ( <i
          className="fa-solid fa-eye"
          style={{
            cursor: "pointer",
            marginLeft: "65px",
            fontSize: "15px",
          }}
          onClick={() => {
            handleNotesUpdateModal();
          }}
          title="View Notes"
        /> ) : (
          <i
            className="fa-solid fa-note-sticky"
            style={{
              cursor: "pointer",
              marginLeft: "65px",
              fontSize: "15px",
            }}
          onClick={() => {
            handleNotesUpdateModal();
          }}
          title="Add Notes"
        />
        ) } 
    </>
  )
}

export default PromoApprovalInputRenderer;
