import React, { useState, useEffect } from "react";
import Modal from "react-awesome-modal";
import DataTable from "../../../tables";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ColumnDef from "../../../tables/colDefs/dayLevelOfferColdef";
import { getMapAdgroupData, getLyAdgroupData } from "../../../../redux/actions/dayLevelOfferAction";
import { withRouter } from "react-router";
import "./dayLevelOfferStyling.css";

const MapAdGroupModal = (props) => {

  const [LYTYtableData, setLYTYtableData] = useState({
    defaultColDef: {
      headerCheckboxSelectionFilteredOnly: true,
      resizable: true,
      width: 200,
      filter: "agTextColumnFilter",
      suppressSizeToFit: true,
      pagination: false,
    },
    header: [
      {
        headerName: "TY Adgroup",
        field: "ty_adgroup",
        width: 170,
      },
      {
        headerName: "LY Adgroup",
        field: "ly_adgroup",
        width: 200,
        cellRenderer: "lYCellRenderer",
        cellRendererParams: {
          onEditLYTYMapping: props.onEditLYTYMapping,
          onAdGroupSearch: props.onAdGroupSearch,
        },
      },
    ],
    data: props.adgroupList
  });
  const [refreshLYTYRows, setRefreshLYTYRows] = useState(false);

  useEffect(() => {
    setLYTYtableData({
      defaultColDef: {
        headerCheckboxSelectionFilteredOnly: true,
        resizable: true,
        width: 200,
        filter: "agTextColumnFilter",
        suppressSizeToFit: true,
        pagination: false,
      },
      header: [
        {
          headerName: "TY Adgroup",
          field: "ty_adgroup",
          width: 170,
        },
        {
          headerName: "LY Adgroup",
          field: "ly_adgroup",
          width: 200,
          cellRenderer: "lYCellRenderer",
          cellRendererParams: {
            onEditLYTYMapping: props.onEditLYTYMapping,
            onAdGroupSearch: props.onAdGroupSearch,
          },
        },
      ],
      data: props.adgroupList
    });
    setRefreshLYTYRows(true);
  }
    , [props.adgroupList]
  );


  return (
    <div>
      <Modal
        visible={true}
        effect="fadeInDown"
        width="50%"
        height="70%"
        onClickAway={() => props.closeMapAdGroupModal()}
      >
        <span
          className="pull-right select-store-span cross-btn">
          <i
            style={{ cursor: 'pointer', fontSize: '1.35em' }}
            className="fa fa-times"
            onClick={() => props.closeMapAdGroupModal()}
            aria-hidden="true"
          />
        </span>
        <div className="mapHeading">
          <h4 className="promoHeading">Map LY Adgroup</h4>
        </div>
        <div className="ag-theme-balham mapAdgroup-grid-div">
          <DataTable
            parentId="myGridForDayLevelOffer"
            parentStyle={{
              width: "380px",
              position: "center",
            }}
            parentClass="ag-theme-balham"
            tableData={LYTYtableData}
            pagination={false}
            frameworkComponents={props.frameworkComponents}
            refreshLYTYRows = {refreshLYTYRows}
            refreshLYTYRowsHandler = {setRefreshLYTYRows}

          />
        </div>
        {/* <h3 className="adgroup-header">Map LY Adgroup</h3> */}
        <div className="mapAdGroup-footer pt-1 pb-3 ">
          <div className="text-center mt-2">
            <button className="btn btn-primary mr-2"
              onClick={() => props.closeMapAdGroupModal()}>
              Cancel
              </button>
            <button
              className="btn btn-primary"
              onClick={() => props.onSaveAdGroupModal()}
            >
              Apply
              </button>

          </div>
        </div>
      </Modal>
    </div>
  );
}


export default MapAdGroupModal;