import React, { Component } from "react";
import { Alert } from "reactstrap";
import "./alertStyle.css";

const AlertLoader = (props) => {
    return (
        <div>
            <Alert
                className="alertstyle alertStyling"
                color="white"
                show={props.show}
                id="AlertDanger"
            >
                {" "}
                <img id="imgLoading" src="../img/loading.gif" aria-hidden="true" />
                <p className="alertLoaderText">{props.message}</p>
            </Alert>
        </div>
    )
}

export default AlertLoader;