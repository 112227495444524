import React from "react";
import { Button, Tooltip } from 'antd';
import { CalculatorOutlined } from '@ant-design/icons';

const PromoStatusRenderer = props => {
  return (
    <>
      {
        props.data.uploadStatus == "Failed" ?
          <div className="upload-fail">
            <span>{props.data.uploadStatus}</span>
            <Tooltip title={props.data.validationErrors.join(" ,")}>
              {/* <Button */}
              {/* type="link"
              title={props.data.validationErrors.join(" ,")}
              icon={ */}
              <i
                className="fa fa-info-circle"
                title="Upload failed"
                style={{
                  color: "red"
                }}
              />
              {/* } */}

              {/* /> */}
            </Tooltip>
          </div>
          :
          <div className="upload-success">
            <span>{props.data.uploadStatus}</span>
            <Button
              type="link"
              title="Calculate reg sales price"
              onClick={() => {
                props.data.getRegSalesPrices(props.data, props.node.id);
                // this.submitGA("Click", "Clicked_EditAdgroupButton");
              }}
              // disabled={!EVENT_MASTER_USERS.includes(localStorage.getItem("role"))}
              icon={<CalculatorOutlined />}
            >
            </Button>
          </div>
      }
    </>
  )
};

export default PromoStatusRenderer;