import React, { Component } from "react";
import { DateRangePicker } from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import "./dateRangeStyling.css";
import moment from "moment";
import _ from "lodash";

let yearArray = [];

export class DateRangePickerUtil extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      startDate: "", 
      endDate: ""
     };
  }

  componentDidMount() {
    let minDate = localStorage.getItem("minDateForCalendar"),
      maxDate = localStorage.getItem("maxDateForCalendar");
    for (
      let i = moment(minDate).format("YYYY");
      i <= moment(maxDate).format("YYYY");
      i++
    ) {
      yearArray.push(parseInt(i));
    }
    //Commenting following so that after 2021, 2022 year can be seen
    // if (yearArray.length > 5) {
    //   let diff = yearArray.length - 5;
    //   yearArray.splice(5, diff);
    // }
  }

  onDatesChange(startDate, endDate) {
    this.setState(startDate, endDate);
  }

  render() {
    return (
      <DateRangePicker
        disabled={this.props.disabled}
        focusedInput={this.state.focusedInput}
        onFocusChange={focusedInput => this.setState({ focusedInput })}
        startDate={this.props.startDate}
        endDate={this.props.endDate}
        onDatesChange={this.props.onDatesChange}
        isOutsideRange={
          this.props.isOutsideRange ? this.props.isOutsideRange : () => false
        }
        renderMonthElement={
          this.props.renderMonthElement
            ? function noRefCheck({ month, onMonthSelect, onYearSelect }) {
                return (
                  <div className="month-year-dropdown">
                    {yearArray.length ? (
                      <>
                        <div>
                          <select
                            value={month.month()}
                            onChange={e => onMonthSelect(month, e.target.value)}
                          >
                            {moment.months().map((label, value) => (
                              <option value={value}>{label}</option>
                            ))}
                          </select>
                        </div>
                        <div>
                          <select
                            value={month.year()}
                            onChange={e => onYearSelect(month, e.target.value)}
                          >
                            {yearArray.map(item => (
                              <option value={item}>{item}</option>
                            ))}
                          </select>
                        </div>
                      </>
                    ) : (
                      <div />
                    )}
                  </div>
                );
              }
            : null
        }
        minimumNights={0}
        initialVisibleMonth={() => {
          return _.isEmpty(this.props.startDate) ? moment() : moment(this.props.startDate);
        }}
      />
    );
  }
}

export default DateRangePickerUtil;
