import numeral from "numeral";
import moment from "moment";
import {
  valueFormatterHandler,
  valueGMFormatterHandler
} from "../../../utils/valueFormatters";

export default {
  defaultColDef: {
    resizable: true,
    filter: true,
    sortable: true
  },
  couponHeader: [
    {
      headerName: "Start Date",
      field: "start_date",
      filter: "agTextColumnFilter",
      // valueFormatter: dateFormatter,
      valueGetter: function(params) {
        if (params.data.start_date) {
          return moment(params.data.start_date).format("MM-DD-YYYY");
        }
        return "-";
      },
      suppressSizeToFit: true,
      unSortIcon: true
    },
    {
      headerName: "End Date",
      field: "end_date",
      filter: "agTextColumnFilter",
      // valueFormatter: dateFormatter,
      valueGetter: function(params) {
        if (params.data.end_date) {
          return moment(params.data.end_date).format("MM-DD-YYYY");
        }
        return "-";
      },
      suppressSizeToFit: true,
      unSortIcon: true
    },
    {
      headerName: "Category Description",
      field: "description",
      width: 260,
      filter: "agTextColumnFilter",
      suppressSizeToFit: true,
      unSortIcon: true
    },
    {
      headerName: "Function",
      field: "getfun",
      hide: true
    },
    {
      headerName: "Event Type",
      field: "event_type",
      filter: "agTextColumnFilter",
      suppressSizeToFit: true,
      unSortIcon: true
    },
    {
      headerName: "Coupon Type",
      field: "coupon_type",
      filter: "agTextColumnFilter",
      valueGetter: function(params) {
        if (params.data.coupon_type) {
          return params.data.coupon_type.toUpperCase();
        }
        return "-";
      },
      suppressSizeToFit: true,
      unSortIcon: true
    },
    {
      headerName: "Discount Coupon",
      field: "coupon_discount",
      filter: "agTextColumnFilter",
      suppressSizeToFit: true,
      unSortIcon: true
    },
    {
      headerName: "Forecast Units",
      field: "forecasted_units",
      filter: "agTextColumnFilter",
      // valueFormatter: emptyValueFormatter1,
      valueFormatter: valueFormatterHandler,
      suppressSizeToFit: true,
      unSortIcon: true,
      hide: false
    },
    {
      headerName: "Forecast Revenue ($)",
      field: "forecasted_revenue",
      filter: "agTextColumnFilter",
      // valueFormatter: emptyValueFormatter1,
      valueFormatter: valueFormatterHandler,
      suppressSizeToFit: true,
      unSortIcon: true,
      hide: false
    },
    {
      headerName: "Forecast Margin ($)",
      field: "forecasted_margin",
      filter: "agTextColumnFilter",
      // valueFormatter: emptyValueFormatter1,
      valueFormatter: valueFormatterHandler,
      suppressSizeToFit: true,
      unSortIcon: true,
      hide: false
    },
    {
      headerName: "GM (%)",
      field: "gm",
      filter: "agTextColumnFilter",
      // valueFormatter: emptyValueFormatterForPrice,
      valueFormatter: valueGMFormatterHandler,
      suppressSizeToFit: true,
      unSortIcon: true,
      hide: false
    },
    {
      headerName: "Action",
      field: "action",
      cellRenderer: "childMessageRenderer",
      pinned: "right",
      suppressSizeToFit: true,
      cellStyle: null, //cellStyleMethodNew
      filter: false,
      width: 100
    }
  ],
  pastCouponHeader: [
    {
      headerName: "Start Date",
      field: "start_date",
      filter: "agTextColumnFilter",
      // valueFormatter: dateFormatter,
      valueGetter: function(params) {
        if (params.data.start_date) {
          return moment(params.data.start_date).format("MM-DD-YYYY");
        }
        return "-";
      },
      suppressSizeToFit: true,
      unSortIcon: true
    },
    {
      headerName: "End Date",
      field: "end_date",
      filter: "agTextColumnFilter",
      // valueFormatter: dateFormatter,
      valueGetter: function(params) {
        if (params.data.end_date) {
          return moment(params.data.end_date).format("MM-DD-YYYY");
        }
        return "-";
      },
      suppressSizeToFit: true,
      unSortIcon: true
    },
    {
      headerName: "Category Description",
      field: "description",
      width: 260,
      filter: "agTextColumnFilter",
      suppressSizeToFit: true,
      unSortIcon: true
    },
    {
      headerName: "Function",
      field: "getfun",
      hide: true
    },
    {
      headerName: "Event Type",
      field: "event_type",
      filter: "agTextColumnFilter",
      suppressSizeToFit: true,
      unSortIcon: true
    },
    {
      headerName: "Coupon Type",
      field: "coupon_type",
      filter: "agTextColumnFilter",
      valueGetter: function(params) {
        if (params.data.coupon_type) {
          return params.data.coupon_type.toUpperCase();
        }
        return "-";
      },
      suppressSizeToFit: true,
      unSortIcon: true
    },
    {
      headerName: "Discount Coupon",
      field: "coupon_discount",
      filter: "agTextColumnFilter",
      suppressSizeToFit: true,
      unSortIcon: true
    },
    {
      headerName: "Units",
      field: "actual_units",
      filter: "agTextColumnFilter",
      suppressSizeToFit: true,
      unSortIcon: true,
      // valueFormatter: emptyValueFormatter1
      valueFormatter: valueFormatterHandler
    },
    {
      headerName: "Revenue ($)",
      field: "actual_revenue",
      filter: "agTextColumnFilter",
      suppressSizeToFit: true,
      unSortIcon: true,
      // valueFormatter: emptyValueFormatter1
      valueFormatter: valueFormatterHandler
    },
    {
      headerName: "Margin ($)",
      field: "actual_margin",
      filter: "agTextColumnFilter",
      suppressSizeToFit: true,
      unSortIcon: true,
      // valueFormatter: emptyValueFormatter1
      valueFormatter: valueFormatterHandler
    },
    {
      headerName: "GM (%)",
      field: "actual_gm",
      filter: "agTextColumnFilter",
      // valueFormatter: emptyValueFormatterForPrice,
      valueFormatter: valueGMFormatterHandler,
      suppressSizeToFit: true,
      unSortIcon: true
    },

    {
      headerName: "Action",
      field: "action",
      cellRenderer: "childMessageRenderer",
      pinned: "right",
      suppressSizeToFit: true,
      cellStyle: null, //cellStyleMethodNew
      filter: false,
      width: 100
    }
  ]
};
