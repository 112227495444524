import React, { Component, PureComponent } from "react";
import CanvasJSReact from "../../assets/canvasjs.react";
import { emptyValueFormatter } from "../../utils/valueFormatters.js";
import _ from "lodash";

var CanvasJSChart = CanvasJSReact.CanvasJSChart;

class WaterfallChartRevenue extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      options: null,
    };
  }
  render() {
    let baselineRevenue = 0;
    let offerValue = this.props.offerValue;
    let incrementalRevenue = 0;
    let affinityRevenue = 0;
    let cannibalisationrevenue = 0;
    let totalRevenue = 0;
    let otherRevenue = 0;
    let miniTotalRevenue = 0;
    let waterfallChartData = null;
    let finalCumulativeRevenue = 0;
    let sales_forecast_override = this.props.sales_forecast_override;

    if (this.props.responseKey === -1 && this.props.tyOffer[0]) {
      //Ty offer Waterfall tab section
      baselineRevenue = this.props.tyOffer[0].baseline_revenue;
      incrementalRevenue = this.props.tyOffer[0].promo_inc_revenue;
      affinityRevenue = this.props.tyOffer[0].affinity_revenue;
      cannibalisationrevenue = this.props.tyOffer[0].cannibalization_revenue;
      otherRevenue =
        this.props.tyOffer[0].total_revenue -
        (baselineRevenue + incrementalRevenue);
      miniTotalRevenue = this.props.tyOffer[0].total_revenue;
    } else if (
      this.props.responseKey !== -1 &&
      (this.props.responseKey < 0 || _.isEmpty(this.props.offerBuildeRes))
    ) {
      //Recommended tab sections
      if (this.props.offerType === "P") {
        waterfallChartData = this.props.waterfallData[0];
      } else if (this.props.offerType === "F") {
        waterfallChartData = this.props.waterfallData[1];
      } else {
        waterfallChartData = this.props.waterfallData[2];
      }

      if (waterfallChartData["output"][offerValue] !== undefined) {
        if (
          waterfallChartData["output"][offerValue]["totalBaselineRevenue"] !==
          undefined
        ) {
          baselineRevenue =
            waterfallChartData["output"][offerValue]["totalBaselineRevenue"];
        }

        if (
          waterfallChartData["output"][offerValue][
            "totalIncrementalRevenue"
          ] !== undefined
        ) {
          incrementalRevenue =
            waterfallChartData["output"][offerValue]["totalIncrementalRevenue"];
        }
        if (
          waterfallChartData["output"][offerValue]["totalAffinityrevenue"] !==
          undefined
        ) {
          affinityRevenue =
            waterfallChartData["output"][offerValue]["totalAffinityrevenue"];
        }
        if (
          waterfallChartData["output"][offerValue][
            "totalCannibalisationrevenue"
          ] !== undefined
        ) {
          cannibalisationrevenue =
            waterfallChartData["output"][offerValue][
              "totalCannibalisationrevenue"
            ];
        }

        if (
          waterfallChartData["output"][offerValue]["totalRevenue"] !== undefined
        ) {
          otherRevenue =
            waterfallChartData["output"][offerValue]["totalRevenue"] -
            (baselineRevenue + incrementalRevenue);

          miniTotalRevenue =
            waterfallChartData["output"][offerValue]["totalRevenue"];
        }
      }
    } else {
      if (
        this.props.responseKey >= 0 &&
        this.props.offerBuildeRes &&
        this.props.offerValue &&
        this.props.offerType &&
        this.props.offerBuildeRes[this.props.responseKey]
      ) {
        baselineRevenue = this.props.offerBuildeRes[this.props.responseKey][
          "output"
        ][this.props.offerValue]["totalBaselineRevenue"];

        incrementalRevenue = this.props.offerBuildeRes[this.props.responseKey][
          "output"
        ][this.props.offerValue]["totalIncrementalRevenue"];

        affinityRevenue = this.props.offerBuildeRes[this.props.responseKey][
          "output"
        ][this.props.offerValue]["totalAffinityrevenue"];

        cannibalisationrevenue = this.props.offerBuildeRes[
          this.props.responseKey
        ]["output"][this.props.offerValue]["totalCannibalisationrevenue"];

        otherRevenue =
          this.props.offerBuildeRes[this.props.responseKey]["output"][
            this.props.offerValue
          ]["totalRevenue"] -
          (baselineRevenue + incrementalRevenue);

        miniTotalRevenue = this.props.offerBuildeRes[this.props.responseKey][
          "output"
        ][this.props.offerValue]["totalRevenue"];
      }
    }

    totalRevenue =
      otherRevenue +
      baselineRevenue +
      incrementalRevenue +
      affinityRevenue +
      cannibalisationrevenue;

    finalCumulativeRevenue =
      incrementalRevenue + affinityRevenue + cannibalisationrevenue;

    const optionsWaterfall = {
      animationEnabled: true,
      exportEnabled: true,
      title: {
        text: "Sales",
        fontSize: 14,
        fontFamily: "Poppins",
      },
      axisY: {
        // valueFormatString: "$#,#0,.K",
        labelFontSize: 12,
        labelMaxWidth: 70,
        labelFontColor: "gray",
        gridThickness: 0,
        stripLines: [
          {
            value: 0,
            showOnTop: true,
            color: "gray",
            thickness: 2,
          },
        ],
      },
      toolTip: {
        shared: true,
        contentFormatter: function (e) {
          return (
            e.entries[0].dataPoint.label +
            ":" +
            emptyValueFormatter(e.entries[0].dataPoint.y)
          );
        },
      },
      axisX: {
        labelFontColor: "gray",
        labelFontSize: 12,
      },
      height: 250,
      data: [
        {
          risingColor: "#5B9BD5",
          fallingColor: "#ED7D31",
          type: "waterfall",
          // yValueFormatString: "$#,##0,.00K",
          indexLabelOrientation: "vertical",
          lineColor: "gray",
          dataPoints: [
            { label: "Baseline", y: Math.round(baselineRevenue) },
            { label: "Incremental", y: Math.round(incrementalRevenue) },
            { label: "Other", y: Math.round(otherRevenue) },
            {
              label: "Total",
              y: Math.round(miniTotalRevenue),
              isIntermediateSum: true,
            },
            { label: "Affinity", y: Math.round(affinityRevenue) },
            {
              label: "Cannibalisation",
              y: Math.round(cannibalisationrevenue),
            },
            {
              label: "Total with Aff. & Cann.",
              y: Math.round(totalRevenue),
              isCumulativeSum: true,
            },
            {
              label: "Revenue Forecast Override",
              y: Math.round(sales_forecast_override),
            },
          ],
        },
      ],
    };

    var roundedBaselineRevenue = emptyValueFormatter(baselineRevenue);
    var roundedIncrementalRevenue = emptyValueFormatter(incrementalRevenue);
    var roundedAffinityRevenue = emptyValueFormatter(affinityRevenue);
    var roundedCannibalisationRevenue = emptyValueFormatter(
      cannibalisationrevenue
    );
    var roundedTotalRevenue = emptyValueFormatter(totalRevenue);
    var roundedOtherRevenue = emptyValueFormatter(otherRevenue);
    var roundedMiniTotalRevenue = emptyValueFormatter(miniTotalRevenue);
    var roundedFinalCumulativeRevenue = emptyValueFormatter(
      finalCumulativeRevenue
    );

    return (
      <div>
        <div className="card" style={{ marginTop: "6px" }}>
          <CanvasJSChart options={optionsWaterfall} />
          <div className="watermarkdiv" />
        </div>
        <div className="card" style={{ marginTop: "10px" }}>
          <table className="table text-center">
            <thead className="theadstyle">
              <tr>
                <th> Metrics </th>
                <th>Values</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="waterfallTabledta" style={{ width: "60%" }}>
                  Baseline Sales
                </td>
                <td className="waterfallTabledta">
                  {roundedBaselineRevenue != 0
                    ? "$ " + roundedBaselineRevenue
                    : "-"}
                </td>
              </tr>
              <tr>
                <td className="waterfallTabledta">Incremental Sales (1)</td>
                <td className="waterfallTabledta">
                  {roundedIncrementalRevenue != 0
                    ? "$ " + roundedIncrementalRevenue
                    : "-"}
                </td>
              </tr>
              <tr>
                <td className="waterfallTabledta">Other Sales</td>
                <td className="waterfallTabledta">
                  {roundedOtherRevenue != 0 ? "$ " + roundedOtherRevenue : "-"}
                </td>
              </tr>
              <tr>
                <td className="waterfallTabledta" style={{ color: "orange" }}>
                  Total Sales
                </td>
                <td className="waterfallTabledta" style={{ color: "orange" }}>
                  {roundedMiniTotalRevenue != 0
                    ? "$ " + roundedMiniTotalRevenue
                    : "-"}
                </td>
              </tr>

              <tr>
                <td className="waterfallTabledta">Affinity Sales (2)</td>
                <td className="waterfallTabledta">
                  {roundedAffinityRevenue != 0
                    ? "$ " + roundedAffinityRevenue
                    : "-"}
                </td>
              </tr>
              <tr>
                <td className="waterfallTabledta">Cannibalisation Sales (3)</td>
                <td className="waterfallTabledta">
                  {roundedCannibalisationRevenue != 0
                    ? "$ " + roundedCannibalisationRevenue
                    : "-"}
                </td>
              </tr>
              <tr>
                <td className="waterfallTabledta" style={{ color: "orange" }}>
                  Total Sales with Affinity & Cannibalisation
                </td>
                <td className="waterfallTabledta" style={{ color: "orange" }}>
                  {roundedTotalRevenue != 0 ? "$ " + roundedTotalRevenue : "-"}
                </td>
              </tr>

              <tr>
                <td className="waterfallTabledta" style={{ color: "orange" }}>
                  Total Incremental Sales (1+2+3)
                </td>
                <td className="waterfallTabledta" style={{ color: "orange" }}>
                  {roundedFinalCumulativeRevenue != 0
                    ? "$ " + roundedFinalCumulativeRevenue
                    : "-"}
                </td>
              </tr>

              <tr>
                <td className="waterfallTabledta" style={{ color: "orange" }}>
                  Sales Forecast Override
                </td>
                <td className="waterfallTabledta" style={{ color: "orange" }}>
                  {sales_forecast_override
                    ? "$ " + sales_forecast_override
                    : "-"}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default WaterfallChartRevenue;
