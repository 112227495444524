import axios from "axios";
import { config } from "../../utils/config";
import { USER_LOGIN, LOADER, GET_ERRORS, USER_LOGGED_OUT } from "./types";
import setAuthToken from "../../utils/setAuthToken";

export const loginUser = reqObject => dispatch => {
  dispatch({
    type: LOADER,
    payload: true
  });
  axios
    .post(`${config.baseUrl}/user/login`, reqObject)
    .then(res => {
      if (res.data.status) {
        const {
          token,
          email,
          emailHash,
          id,
          roles,
          role_name,
          offer_type,
          download_file,
          similar_sku_download_file,
          merch_id_shops,
          max_historical_end_date,
          master_download_file
        } = res.data.data;

        localStorage.setItem("token", token);
        localStorage.setItem("merchid", JSON.stringify(merch_id_shops));
        localStorage.setItem("email", email);
        localStorage.setItem("emailHash", emailHash);
        localStorage.setItem("userid", id);
        localStorage.setItem("role", roles[0]);
        localStorage.setItem("role_name", role_name);
        localStorage.setItem("promo_type", JSON.stringify(offer_type));
        localStorage.setItem(
          "sample_skufile_link",
          JSON.stringify(download_file)
        );
        localStorage.setItem(
          "similar_skufile_link",
          JSON.stringify(similar_sku_download_file)
        );
        localStorage.setItem(
          "max_historical_end_date",
          max_historical_end_date
        );
        localStorage.setItem(
          "master_download_file_link",
          JSON.stringify(master_download_file)
        );
        //Set token to Auth header
        setAuthToken(token);
        dispatch({
          type: LOADER,
          payload: false
        });
        dispatch({
          type: USER_LOGIN,
          payload: res.data.data
        });
      } else {
        let payload = {
          message: res.data.msg,
          error: res.data.error
        };
        dispatch({
          type: LOADER,
          payload: false
        });
        dispatch({
          type: GET_ERRORS,
          payload: payload
        });
        dispatch({
          type: USER_LOGGED_OUT
        });
      }
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err
      });
    });
};

export const resetPassword = reqObject => async dispatch => {
  axios.defaults.headers.common["token"] = localStorage.getItem("token");
  const response = await axios.post(
    `${config.baseUrl}/user/resetPassword`,
    reqObject
  );
  return response.data;
};

export const checkUserExistenceNSendOTP = reqObject => async dispatch => {
  //axios.defaults.headers.common['token'] = localStorage.getItem("token");
  const response = await axios.post(
    `${config.baseUrl}/user/forgotPassword`,
    reqObject
  );
  return response.data;
};

export const forgotPassword = reqObject => async dispatch => {
  //axios.defaults.headers.common['token'] = localStorage.getItem("token");
  const response = await axios.post(
    `${config.baseUrl}/user/forgotPassword`,
    reqObject
  );
  return response.data;
};

export const logoutUser = token => async dispatch => {
  localStorage.removeItem("token");
  localStorage.removeItem("email");
  localStorage.removeItem("emailHash");
  localStorage.removeItem("role");
  localStorage.removeItem("role_name");
  localStorage.removeItem("promo_type");

  // setAuthToken(false);
  const respons = await axios
    .get(`${config.baseUrl}/user/logout`)
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
    if(respons.response && respons.response.data && respons.response.data.redirect){
      // window.location.href = respons.response.data.redirect_url; 
      window.location.href = `${config.baseUrl}/user/samlLogin`;
  } 
  return respons;
};

export const samplLogin = reqObject => async dispatch => {
  const response = await axios
    .get(`${config.baseUrl}/user/samlLogin`)
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
  return response;
};

export const getLogedInUserDetails = reqObject => async dispatch => {
  const response = await axios
    .get(`${config.baseUrl}/user/getLogedInUserDetails`)
    .then(res => {
      if (res.data.status) {
        const {
          token,
          email,
          emailHash,
          id,
          roles,
          role_name,
          offer_type,
          download_file,
          similar_sku_download_file,
          merch_id,
          merch_id_shops,
          max_historical_end_date,
          login_all_flag,
          master_download_file
        } = res.data.data;
        localStorage.setItem("token", null);
        localStorage.setItem("merchid", JSON.stringify(merch_id_shops));
        localStorage.setItem("email", email);
        localStorage.setItem("emailHash", emailHash);
        localStorage.setItem("loginallflag", login_all_flag);
        localStorage.setItem("userid", id);
        localStorage.setItem("role", roles[0]);
        localStorage.setItem("role_name", role_name);
        localStorage.setItem("promo_type", JSON.stringify(offer_type));
        localStorage.setItem(
          "sample_skufile_link",
          JSON.stringify(download_file)
        );
        localStorage.setItem(
          "similar_skufile_link",
          JSON.stringify(similar_sku_download_file)
        );
        localStorage.setItem(
          "max_historical_end_date",
          max_historical_end_date
        );
        localStorage.setItem(
          "master_download_file_link",
          JSON.stringify(master_download_file)
        );
        //Set token to Auth header
        setAuthToken(token);
        dispatch({
          type: LOADER,
          payload: false
        });
        dispatch({
          type: USER_LOGIN,
          payload: res.data.data
        });
      } else {
        let payload = {
          message: res.data.msg,
          error: res.data.error
        };
        dispatch({
          type: LOADER,
          payload: false
        });
        dispatch({
          type: GET_ERRORS,
          payload: payload
        });
        dispatch({
          type: USER_LOGGED_OUT
        });
      }
      return res.data;
    })
    .catch(err => {
      return err;
    });
  return response;
};
