import React, { Component } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';

class Table extends Component {
  handleFirstDataRendered = (event) => {
    this.props.sizeToFit && this.sizeToFit();
    this.props.autoSizeAll && this.autoSizeAll();
    // : event.columnApi.autoSizeAllColumns();
  };
  gridSizeChanged = (event) => {
    this.props.sizeToFit && this.sizeToFit();
    this.props.autoSizeAll && this.autoSizeAll();
    // : event.columnApi.autoSizeAllColumns();
  };

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.props.onGridReady && this.props.onGridReady(params.api);
  };
  sizeToFit = () => {
    this.gridApi.sizeColumnsToFit();
  };
  autoSizeAll = (skipHeader) => {
    var allColumnIds = [];
    this.gridColumnApi.getAllColumns().forEach(function (column) {
      allColumnIds.push(column.colId);
    });
    this.gridColumnApi.autoSizeColumns(allColumnIds, skipHeader);
  };

  render() {
    return (
      <div
        className="ag-theme-balham"
        style={{
          height: '100%',
          width: '100%',
        }}>
        <AgGridReact
          {...this.props}
          onGridReady={this.onGridReady}
          animateRows={true}
          defaultColDef={{
            sortable: true,
            resizable: true,
            filter: true
          }}
          floatingFilter={this.props.disableFilter ? false : true}
          onFirstDataRendered={this.handleFirstDataRendered}
          onGridSizeChanged={this.gridSizeChanged} enableCellTextSelection={true}></AgGridReact>
      </div>
    );
  }
}

export default Table;
