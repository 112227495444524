import moment from "moment";
import { emptyStringValueFormatter } from "../../../utils/valueFormatters";
const GroupOptions = [
    {
      label: "AUR",
      value: "AUR",
    },
    {
      label: "Buyer",
      value: "Buyer",
    },
    {
      label: "OfferType",
      value: "OfferType",
    },
    {
      label: "GM%",
      value: "GM%",
    },
    {
      label: "Margin ($)",
      value: "Margin ($)",
    },
    {
      label: "Units",
      value: "Units",
    },
    {
      label: "Sales ($)",
      value: "Sales ($)",
    },
    {
      label: "ShopDesc",
      value: "ShopDesc",
    },
    {
      label: "Offer Comparison",
      value: "Offer Comparison",
    },
  ];
let DMTable = [
    {
    headerName: "TY Date",
    headerTooltip: "TY Date",
    field: "TyDate",
    pinned: "left",
    tooltipField: "TyDate",
    width: 115,
    valueFormatter: (params) => moment(params.value).format('MM/DD/YYYY'),
   // unSortIcon: true,
    sort:  'asc',
    },
    {
    headerName: "LY Date",
    headerTooltip: "LY Date",
    field: "LyDate",
    pinned: "left",
    tooltipField: "LyDate",
    width: 115,
    valueFormatter: (params) => moment(params.value).format('MM/DD/YYYY'),
    },
    // {
    // headerName: "Day",
    // headerTooltip: "Day",
    // field: "Day",
    // pinned: "left",
    // tooltipField: 'Day',
    // width: 80,
    // },
    {
    headerName: "FW",
    headerTooltip: "FW",
    field: "FW",
    pinned: "left",
    tooltipField: 'FW',
    width: 80,
    },
    // {
    // headerName: "FM",
    // headerTooltip: "FM",
    // field: "FM",
    // pinned: "left",
    // tooltipField: 'FM',
    // width: 80,
    // },
    // {
    // headerName: "FQ",
    // headerTooltip: "FQ",
    // field: "FQ",
    // pinned: "left",
    // tooltipField: 'FQ',
    // width: 80,
    // },
    {
    headerName: "DM",
    headerTooltip: "DM",
    field: "DM",
    pinned: "left",
    tooltipField: 'DM',
    width: 100,
    valueGetter: emptyStringValueFormatter,
    },
]

export default {
    defaultColDef: {
        headerCheckboxSelectionFilteredOnly: true,
        resizable: true,
        // filter: true,
        width: 120,
        filter: "agTextColumnFilter",
        suppressSizeToFit: true,
       // sortable: true,
        //filterParams: { newRowsAction: "keep" },
      },
    DayLevelOfferTable: [...DMTable],
    GroupOptions: [...GroupOptions],
}
  