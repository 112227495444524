import React, { Component } from "react";
import { Typography, Select, Input, Button, Checkbox } from 'antd';
import {REPORT_TYPE} from './constants'


class FilterReport extends Component {

  render() {
    return (
      <div className="filterContainer"> 
        <div className="filterFieldContainer">
          <div className="filterField" >
            <p className="dropdownLabel">Report Type  <span style={{ color: "red", marginLeft: "2px" }}>*</span>  </p>
            <Select
              placeholder="Select Report Type"
              // value={this.state.eventDetails.sign_shell || null}
              style={{
                width: '100%',
              }}
              onChange={this.props.onReportTypeChange}
              options={REPORT_TYPE}
              // disabled={this.state.isSuccess}
            />
          </div>

        </div>
      </div>
    )
  }


}

export default FilterReport