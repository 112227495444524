import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { resetPassword, logoutUser } from "../../redux/actions/loginAction";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Modal from "react-awesome-modal";
import PageLoader from "../../utils/Loader";
import AlertDanger from "../../utils/AlertMessage/alertDanger";
import AlertSuccess from "../../utils/AlertMessage/alertSuccess";
import { batchProcessCheck } from "../../redux/actions/batchProcess";
import _ from "lodash";
import { isIt } from "../../utils/getRoleName";

var userEmail;

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showResetModal: false,
      password: null,
      passwordConf: null,
      passwordMismatchAlert: false,
      showLoader: false,
      passwordResetAlert: false,
      batchProcessRunning: false,
      emailAddress: ''
    };
  }
  async componentDidMount() {
    userEmail = navigator.cookieEnabled && localStorage.getItem("email");
    let res = await this.props.batchProcessCheck();
    if(res && res.batch_process_running) {
      this.setState({ batchProcessRunning: true })
    }
    this.setState({ emailAddress: userEmail })
  }
  componentDidUpdate(prevProps){
      if(//!_.isEqual(prevProps.disableBatchBtn, this.props.disableBatchBtn)
        this.props.disableBatchBtn &&
        this.state.batchProcessRunning !== this.props.disableBatchBtn.batch_process_running
      ){
        this.setState({ batchProcessRunning: this.props.disableBatchBtn.batch_process_running })        
      }
      if(_.isEmpty(this.state.emailAddress) && this.props.userData && this.props.userData.email){
        this.setState({ emailAddress: this.props.userData.email })
      }
  }
  onManageUsersClick(e) {
    e.preventDefault();
    this.props.history.push("/manageUsers");
  }

  onResetPasswordClick() {
    this.setState({
      showResetModal: !this.state.showResetModal
    });
  }

  onLogoutClick(e) {
    e.preventDefault();
    this.props.logoutUser();
    // this.props.history.push("/promotion");
  }

  saveNewPassword = async () => {
    if (this.state.password === this.state.passwordConf) {
      this.setState({
        showLoader: true
      });
      let resetPasswordResponse = await this.props.resetPassword({
        user_email: localStorage.getItem("email"),
        new_password: this.state.password
      });

      if (resetPasswordResponse.status) {
        this.setState({
          showLoader: false,
          passwordResetAlert: true
        });

        setTimeout(() => {
          this.setState({
            passwordResetAlert: false
          });
        }, 3000);
      }
    } else {
      this.setState({
        passwordMismatchAlert: true
      });
      setTimeout(() => {
        this.setState({
          passwordMismatchAlert: false
        });
      }, 3000);
    }
  };

  render() {
    var userEmail = navigator.cookieEnabled && localStorage.getItem("email");
    return (
      <div className={this.state.batchProcessRunning ? "headerContainer batch-process" : "headerContainer"}>
        <div className="alertDivs">
          {this.state.passwordMismatchAlert ? (
            <AlertDanger
              message="Passwords do not match!"
              show={this.state.passwordMismatchAlert}
            />
          ) : null}

          {this.state.passwordResetAlert ? (
            <AlertSuccess
              message="Password reset success!"
              show={this.state.passwordResetAlert}
            />
          ) : null}
        </div>
        {
          this.state.batchProcessRunning ?
            <div className="batch-process-notification">
              Batch process currently underway. Tool's performance will be slow as a result
            </div> 
          : null
        }
        <nav 
          className="navbar navbar-expand-sm navbar-light bg-light" 
          style={this.state.batchProcessRunning ? { top: "27px" } : {}}
          >
          <a href="/promotion" className="navbar-brand">
            <img id="brandlogo" alt="Joann logo" src="./img/promoSmart.png" />
          </a>
          <button
            className="navbar-toggler"
            data-toggle="collapse"
            data-target="#navbarMenu"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse" id="navbarMenu">
            <ul className="navbar-nav ml-auto">
              <li className="nav-item">
                <p className="userText">{
                  userEmail
                // this.state.emailAddress
                }</p>
              </li>
              <li className="nav-item">
                <div className="dropdown">
                  <button
                    className="dropdown-toggle"
                    type="button"
                    id="dropdownMenu2"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                  >
                    <i
                      className="fa fa-user-circle-o navicon"
                      style={{
                        fontSize: "20px",
                        paddingTop: "8px",
                        color: "#007bff",
                        cursor: "pointer"
                      }}
                      aria-hidden="true"
                    />
                  </button>
                  <div
                    id="dropDownProfile"
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenu2"
                  >
                    <ul style={{ paddingLeft: "14px" }}>
                      {navigator.cookieEnabled && localStorage.getItem("role") === "1" ||
                      navigator.cookieEnabled && localStorage.getItem("role") === "3" ||
                      navigator.cookieEnabled && localStorage.getItem("role") === "4" ||
                      navigator.cookieEnabled && localStorage.getItem("role") === "6" ? (
                      ( !isIt() && 
                        <li
                          className="UserIconList"
                          onClick={e => this.onManageUsersClick(e)}
                        >
                          <Link to="/manageUsers">
                            <i className="fa fa-users" aria-hidden="true" />{" "}
                            Manage user
                          </Link>
                        </li>
                      )
                      ) : (
                        <li
                          className="UserIconList"
                          onClick={() => this.onResetPasswordClick()}
                          style={{ cursor: "pointer" }}
                        >
                          <i className="fa fa-lock" aria-hidden="true" /> Reset
                          password
                        </li>
                      )}

                      <li
                        className="UserIconList"
                        onClick={e => this.onLogoutClick(e)}
                        style={{ cursor: "pointer" }}
                      >
                        {/* <Link to="/login"> */}
                          <i className="fa fa-sign-out " aria-hidden="true" />{" "}
                          Logout
                        {/* </Link> */}
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </nav>

        {this.state.showResetModal ? (
          <Modal
            visible={true}
            width="600px"
            height="325px"
            effect="fadeInDown"
            onClickAway={() => this.onResetPasswordClick()}
          >
            <PageLoader loader={this.state.showLoader}>
              <div className="closeicon">
                <span style={{ cursor: "pointer" }}>
                  <i
                    className="fa fa-times"
                    aria-hidden="true"
                    onClick={() => this.onResetPasswordClick()}
                  />
                </span>
              </div>
              <p className="promoNameText text-center">
                User email:
                <span style={{ color: "grey", marginLeft: "2%" }}>
                  {navigator.cookieEnabled && localStorage.getItem("email")}
                </span>
              </p>

              <div className="row" style={{ margin: "2% 1%" }}>
                <div className="col-md-4" style={{ marginTop: "1%" }}>
                  New password:{" "}
                </div>
                <div className="col-md-8">
                  <input
                    placeholder="Password"
                    className="form-control card forecastedValuesInputStyling createUserInputDiv passwordInput"
                    placeholder="Enter your password ⃰"
                    value={this.state.password}
                    onChange={e => {
                      this.setState({
                        password: e.target.value
                      });
                    }}
                  />
                </div>
              </div>

              <div className="row" style={{ margin: "2% 1%" }}>
                <div className="col-md-4" style={{ marginTop: "1%" }}>
                  Confirm password:{" "}
                </div>
                <div className="col-md-8">
                  <input
                    placeholder="Password"
                    className="form-control card forecastedValuesInputStyling createUserInputDiv passwordInput"
                    placeholder="Confirm password ⃰"
                    value={this.state.passwordConf}
                    onChange={e => {
                      this.setState({
                        passwordConf: e.target.value
                      });
                    }}
                  />
                </div>
              </div>

              <div className="text-center delete-btns mt-5">
                <button
                  className="btn btn-outline-primary promo3Buttons"
                  onClick={() => this.saveNewPassword()}
                >
                  Save
                </button>
              </div>
            </PageLoader>
          </Modal>
        ) : null}
      </div>
    );
  }
}
// export default Header;
Header.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  resetPassword: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  isAuthenticated: state.userLoginDetails.isAuthenticated,
  disableBatchBtn: state.userDataReducer.disableBatchBtn,
  userData: state.userLoginDetails.userData
});

export default connect(mapStateToProps, { logoutUser, resetPassword, batchProcessCheck })(
  withRouter(Header)
);
