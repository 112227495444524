export const EVENT_TYPE_CONSTANTS = [
  {
    value: 'M',
    label: 'M - Main promo',
    disabled: true
  },
  {
    value: 'S',
    label: 'S - Sub - Promo',
  },
  {
    value: 'D',
    label: 'D - Sub - Doorbuster',
  },
]

export const EVENT_VEHICLE_CONSTANTS = [
  {
    value: 'DM',
    label: 'DM',
  },
  {
    value: 'UAS',
    label: 'UAS',
  },
  {
    value: 'SPC',
    label: 'SPC',
  },
  {
    value: 'SPC-P',
    label: 'SPC-P',
  },
  {
    value: 'SPC-D',
    label: 'SPC-D',
  },
];

export const EVENT_SIGNSHELL_CONSTANTS = [
  {
    value: 'SPC1',
    label: 'SPC1 - Feature',
  },
  {
    value: 'SPC2',
    label: 'SPC2 - Doorbuster',
  },
  {
    value: 'SPC3',
    label: 'SPC3 - 1-Day Sale',
  },
  {
    value: 'SPC4',
    label: 'SPC4 - 2-Day Sale',
  },
  {
    value: 'SPC5',
    label: 'SPC5 - 3-Day Sale',
  },
  {
    value: 'SPC6',
    label: 'SPC6 - Last Chance',
  },
  {
    value: 'SPC7',
    label: 'SPC7 - 4-Day Sale',
  },
  {
    value: 'SPC8',
    label: 'SPC8 - Now Sewing Month',
  },
  {
    value: 'SPC10',
    label: 'SPC10 - Colossal Savings Sale',
  },
  {
    value: 'Clrnce',
    label: 'Clrnce - Clearance',
  },
  {
    value: 'GO',
    label: 'GO - New Store Savings',
  },
  {
    value: 'Sale',
    label: 'Sale',
  },
]

export const EVENT_PRICE_LIST_CONSTANTS = [
  {
    label: "Select all",
    value: "all"
  },
  {
    value: "01",
    label: '01',
  },
  {
    value: "03",
    label: '03',
  },
  {
    value: "04",
    label: '04',
  },
]

export const VIEW = 'view';
export const EDIT = 'edit';
export const CREATE = 'create';
export const MANDATORY_EXCEPTION_KEYS = [
  'ly_event',
  'promo_group_id',
  'promo_group_description',
  'e_id',
  'error_field',
  'merch_lockout_date'
]

export const EVENT_MASTER_USERS = ["1", "3", "6", "7"];