export default {
  headersDaily: [
    { label: "Adgroup", key: "adgroup" },
    { label: "Offer", key: "offer" },
    { label: "Time Period", key: "transdate_dt" },
    { label: "Total Units", key: "total_quantity" },
    { label: "Total Sales", key: "total_revenue" },
    { label: "Total Margin", key: "total_margin" }
  ],

  headersWeekly: [
    { label: "Adgroup", key: "adgroup" },
    { label: "Offer", key: "offer" },
    { label: "Week Number", key: "week_no" },
    { label: "Start Date", key: "start_date" },
    { label: "End Date", key: "end_date" },
    { label: "Total Units", key: "total_quantity" },
    { label: "Total Sales", key: "total_revenue" },
    { label: "Total Margin", key: "total_margin" }
  ]
};
