import React, { Component } from "react";
import { connect } from "react-redux";
import Modal from "react-awesome-modal";
import DatePicker from "react-datepicker";

import PageLoader from "../../../../utils/Loader";
import DateRangePicker from "../../../../utils/DateRangePicker/DateRangePicker.js";
import AddEventRow from "./EditDmEventRow";
import AddEventRowForCoupons from "../Coupons/EditDmCouponRow";
import AlertSuccess from "../../../../utils/AlertMessage/alertSuccess";
import AlertDanger from "../../../../utils/AlertMessage/alertDanger";
import AlertWarning from "../../../../utils/AlertMessage/alertWarning";
import {
  getEditDMList,
  editDM,
  editCampaign,
  editDMLyDateMapping,
  editDmGetLyEvents,
} from "../../../../redux/actions/calendarAction";
import { getEventCouponList } from "../../../../redux/actions/couponAction";
import _ from "lodash";
import moment from "moment";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import "react-dates/lib/css/_datepicker.css";
import "../../../../utils/DateRangePicker/dateRangeStyling.css";
import "react-dates/initialize";
import { PromotionCalendarGA } from '../../../../utils/GA/GAUtils';
import { alertTimer } from "../../../../utils/alertTimer.js";
import {isIt} from "../../../../utils/getRoleName.js";

var eventIdDeleted = [],
  eventCouponIdDeleted = [];

export class EditDm extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      showLoader: true,
      campaignId: "",
      eventName: "",
      key: "event",
      startDateOfCampaign: null,
      endDateOfCampaign: null,
      lyStartDateOfCampaign: "",
      lyEndDateOfCampaign: "",
      warningText: "",
      deletedEvents: [],
      deletedCoupons: [],
      showDateRangeMismatchAlert: false,
      dateRangeConfirmAlert: false,
      showNoDataAvailableError: false,
      showNoDataAvailableErrorCoupon: false,
      DmEdited: false,
      eventDataEdited: false,
      campaignDataEdited: false,
      applyClicked: false,
      eventDescEventRow: [
        {
          key: 0,
          status: 0,
          startDate: null,
          endDate: null,
          eventType: "",
          description: "",
          eventId: null,
          campaignId: this.props.campaignId,
          lyEventType: [],
          edit_flag: 0,
          offer_count: 0,
          warning_status: 0,
        },
      ],
      couponDescEventRow: [
        {
          key: 0,
          status: 0,
          startDate: null,
          endDate: null,
          eventType: "",
          coupon_events_id: null,
          campaignId: this.props.campaignId,
          warning_status: 0,
        },
      ],
      somethingWentWrong: false,
      noLyEventsError: false,
      lyEventOptions: [],
      applyDisabled: true,
      isTyEdited: false,
      disableClearLYEvents: false,
      eventTabWarning: false,
      couponEventTabWarning: false,
      dmEdited: false,
      pastDmEdit: false,
      merchantLockoutDate: null
    };
  }
  componentDidMount = async () => {
    this.setState({
      campaignId: this.props.campaignId,
    });
    this.listApiCall();
    if (this.props.startDate && this.props.endDate && this.props.campaignName) {
      let startDateFormat = moment(this.props.startDate).format("YYYY-MM-DD");

      this.setState({
        startDateOfCampaign: moment(this.props.startDate),
        endDateOfCampaign: moment(this.props.endDate),
        eventName: this.props.campaignName,
        pastDmEdit: !moment().isSameOrBefore(startDateFormat),
      });
    }

    console.log("document", document );
    document.body.style.overflow = 'hidden';
  };

  componentWillUnmount = () => {
    document.body.style.overflow = 'unset';
  }

  promoTypeFormatter = (data) => {
    let promoTypeSet = JSON.parse(localStorage.getItem("promo_type"));
    if (data) {
      return promoTypeSet ? promoTypeSet[data] : data;
    }
    return "-";
  };

  eventTypeNOptionsHandler = (value) => {
    _.map(value, (item) => {
      let labelValue =
        this.promoTypeFormatter(item.ly_eventType) +
        "-" +
        moment(item.ly_startDate).format("MM/DD/YYYY") +
        "-" +
        moment(item.ly_endDate).format("MM/DD/YYYY");
      // let labelValue =
      //   (options ? item.event_type : item.ly_eventType) +
      //   "-" +
      //   (options
      //     ? moment(item.start_date).format("MM/DD/YYYY")
      //     : moment(item.ly_startDate).format("MM/DD/YYYY")) +
      //   "-" +
      //   (options
      //     ? moment(item.end_date).format("MM/DD/YYYY")
      //     : moment(item.ly_endDate).format("MM/DD/YYYY"));
      item.label = labelValue;
      item.value = labelValue;

      return item;
    });
  };
  promoTypeFormatter = (value) => {
    let promoTypeSet = JSON.parse(localStorage.getItem("promo_type"));
    if (value) {
      let temp = promoTypeSet && promoTypeSet[value] ? promoTypeSet[value] : value;
      return temp;
    }
  };
  listApiCall = async (noAlert) => {
    let eventDescEventRow = [],
      couponDescEventRow = [];

    let res = await this.props.getEditDMList({
      campaignId: this.props.campaignId,
    });

    let resForCoupons = await this.props.getEventCouponList({
      campaignId: parseInt(this.props.campaignId),
    });
    couponDescEventRow = resForCoupons.data;

    if (res.status) {
      let lyEventOptions = [];
      if (res.data && !_.isEmpty(res.data.campaign_data)) {
        this.setState({
          lyStartDateOfCampaign: res.data.campaign_data[0].ly_start_date
            ? moment(res.data.campaign_data[0].ly_start_date)
            : "",
          lyEndDateOfCampaign: res.data.campaign_data[0].ly_end_date
            ? moment(res.data.campaign_data[0].ly_end_date)
            : "",
          merchantLockoutDate: res.data.campaign_data[0].merchant_lockout_date
            ? new Date(moment(res.data.campaign_data[0].merchant_lockout_date).format("MM/DD/YYYY"))
            : "",
        });
      }

      //this if is not reqd
      //   if (res.data && !_.isEmpty(res.data.all_event_data)) {
      //     // let resForEditDm = await this.props.editDMLyDateMapping({
      //     //   campaign_id: this.state.campaignId,
      //     //   ly_start_date: JSON.stringify(
      //     //     moment(res.data.campaign_data[0].ly_start_date).format("YYYY-MM-DD")
      //     //   ).slice(1, 11),
      //     //   ly_end_date: JSON.stringify(
      //     //     moment(res.data.campaign_data[0].ly_end_date).format("YYYY-MM-DD")
      //     //   ).slice(1, 11)
      //     // });

      //     // if (resForEditDm.status) {
      //     let lyStartD = res.data.campaign_data[0].ly_start_date
      //       ? moment(res.data.campaign_data[0].ly_start_date).format(
      //         "YYYY-MM-DD"
      //       )
      //       : null,
      //       lyEndD = res.data.campaign_data[0].ly_end_date
      //         ? moment(res.data.campaign_data[0].ly_end_date).format("YYYY-MM-DD")
      //         : null;

      //     let resForEditDmLyEvents = await this.props.editDmGetLyEvents({
      //       campaign_id: this.state.campaignId.toString(),
      //       ly_start_date: lyStartD,
      //       ly_end_date: lyEndD,
      //     });

      //     if (resForEditDmLyEvents.status) {
      //       this.eventTypeNOptionsHandler(resForEditDmLyEvents.data.data);
      //       lyEventOptions = resForEditDmLyEvents.data.data;
      //     }
      //     // }

      //     eventDescEventRow = res.data.all_event_data.map((item, idx) => {
      //       let lyEventData = _.find(res.data.event_mapping_data, {
      //         ly_events_dropdown_data: { event_id: item.event_id }, //filtering in nested array using lodash
      //       }),
      //         lyEventType = [];
      //       if (lyEventData) {
      //         let allData = lyEventData.ly_events_dropdown_data.all_data;

      //         this.eventTypeNOptionsHandler(allData);
      //         lyEventType = allData;
      //       }
      //       let eventLabel = this.promoTypeFormatter(item.event_type);
      //       return {
      //         key: idx,
      //         status: item.status,
      //         startDate: moment(item.start_date),
      //         endDate: moment(item.end_date),
      //         eventType: { value: item.event_type, label: eventLabel },
      //         description:
      //           item.description === "null" || item.description === null
      //             ? ""
      //             : item.description,
      //         eventId: item.event_id,
      //         campaignId: this.props.campaignId,
      //         lyEventType: lyEventType,
      //         edit_flag: item.edit_flag,
      //         offer_count: item.offer_count,
      //         warning_status: item.status,
      //       };
      //     });
      //     let findLY = _.find(eventDescEventRow, (item) => {
      //       return !_.isEmpty(item.lyEventType);
      //     });
      //     let findStatus = _.find(eventDescEventRow, { warning_status: 1 });
      //     if (eventDescEventRow.length) {
      //       this.setState({
      //         eventDescEventRow,
      //         lyEventOptions: lyEventOptions,
      //         disableClearLYEvents: _.isEmpty(findLY) ? true : false,
      //         eventTabWarning: _.isEmpty(findStatus) ? false : true,
      //       });
      //     }
      //   } else {
      //     this.setState({
      //       eventDescEventRow: JSON.parse(
      //         JSON.stringify(this.state.eventDescEventRow)
      //       ),
      //       showNoDataAvailableError: true,
      //     });
      //     setTimeout(() => {
      //       this.setState({
      //         showNoDataAvailableError: false,
      //       });
      //     }, 3000);
      //   }
      // }

      // if (resForCoupons.status) {
      //   if (resForCoupons.data.length > 0) {
      //     couponDescEventRow = resForCoupons.data.map((item, idx) => {
      //       return {
      //         key: idx,
      //         status: item.status,
      //         startDate: moment(item.start_date),
      //         endDate: moment(item.end_date),
      //         eventType: { value: item.event_type, label: item.event_type },
      //         coupon_events_id: item.coupon_events_id,
      //         campaignId: this.props.campaignId,
      //         warning_status: item.status,
      //       };
      //     });
      //     let findStatus = _.find(couponDescEventRow, { warning_status: 1 });
      //     this.setState({
      //       couponDescEventRow,
      //       couponEventTabWarning: _.isEmpty(findStatus) ? false : true,
      //     });
      //   } else {
      //     let couponDescEventRowEmpty = [
      //       {
      //         key: 0,
      //         status: 0,
      //         startDate: null,
      //         endDate: null,
      //         eventType: "",
      //         coupon_events_id: null,
      //         campaignId: this.props.campaignId,
      //         warning_status: 0,
      //       },
      //     ];

      //     this.setState({
      //       couponDescEventRow: couponDescEventRowEmpty,
      //       showNoDataAvailableErrorCoupon: noAlert ? false : true,
      //     });
      //     if (!noAlert) {
      //       setTimeout(() => {
      //         this.setState({
      //           showNoDataAvailableErrorCoupon: false,
      //         });
      //       }, 3000);
      //     }
      //   }
    }
    this.setState({ showLoader: false });
  };

  onDatesChangeCampaign = (startDate) => {
    this.setState({
      startDateOfCampaign: startDate.startDate,
      endDateOfCampaign: startDate.endDate,
      eventName: moment(startDate.startDate).format("MM/DD/YYYY"),
      campaignDataEdited: true,
    });

    let { eventDescEventRow, couponDescEventRow } = this.state,
      campSD = moment(startDate.startDate).format("YYYY-MM-DD"),
      campED = moment(startDate.endDate).format("YYYY-MM-DD");

    _.map(eventDescEventRow, (item, index) => {
      if (index < 3) {
        item.startDate = startDate.startDate;
        item.endDate = startDate.endDate;
      }

      let stD = moment(item.startDate).format("YYYY-MM-DD"),
        enD = moment(item.endDate).format("YYYY-MM-DD");
      if (
        new Date(stD) >= new Date(campSD) &&
        new Date(enD) <= new Date(campED)
      ) {
        item.warning_status = 0;
      }

      return item;
    });

    _.map(couponDescEventRow, (item, index) => {
      let stD = moment(item.startDate).format("YYYY-MM-DD"),
        enD = moment(item.endDate).format("YYYY-MM-DD");
      if (
        new Date(stD) >= new Date(campSD) &&
        new Date(enD) <= new Date(campED)
      ) {
        item.warning_status = 0;
      }

      return item;
    });

    this.setState({
      eventDescEventRow,
      couponDescEventRow,
      isTyEdited: true,
      dmEdited: true,
    });
  };

  onDatesChangeCampaignLy = (startDate) => {
    this.setState({
      lyStartDateOfCampaign: startDate.startDate,
      lyEndDateOfCampaign: startDate.endDate,
      applyDisabled: false,
    });
  };

  onDatesChange = (startDate, index) => {
    this.submitGA("Click", "Selected_EditDM_Event_TYTimePeriod");
    let { eventDescEventRow } = this.state,
      found = _.find(eventDescEventRow, { key: index });

    let stD = moment(startDate.startDate).format("YYYY-MM-DD"),
      enD = moment(startDate.endDate).format("YYYY-MM-DD"),
      campSD = moment(this.state.startDateOfCampaign).format("YYYY-MM-DD"),
      campED = moment(this.state.endDateOfCampaign).format("YYYY-MM-DD");

    if (
      new Date(stD) >= new Date(campSD) &&
      new Date(enD) <= new Date(campED)
    ) {
      found.warning_status = 0;
    }

    found.startDate = startDate.startDate;
    found.endDate = startDate.endDate;
    if (parseInt(found.offer_count) > 0) {
      found.edit_flag = 1;
    }

    let eventTabWarning = this.state.eventTabWarning ? true : false;
    if (startDate.startDate && startDate.endDate && eventTabWarning) {
      let stD = moment(startDate.startDate).format("YYYY-MM-DD"),
        enD = moment(startDate.endDate).format("YYYY-MM-DD"),
        campSD = moment(this.state.startDateOfCampaign).format("YYYY-MM-DD"),
        campED = moment(this.state.endDateOfCampaign).format("YYYY-MM-DD");

      eventTabWarning = !(
        new Date(stD) >= new Date(campSD) && new Date(enD) <= new Date(campED)
      )
        ? true
        : false;
    }

    this.setState({
      eventDescEventRow,
      eventDataEdited: true,
      eventTabWarning,
      dmEdited: !this.state.dmEdited && found.newRow ? false : true,
    });
  };
  onDatesChangeForCoupon = (startDate, index) => {
    this.submitGA("Click", "Selected_EditDM_Coupon_TYTimePeriod");
    let { couponDescEventRow } = this.state,
      found = _.find(couponDescEventRow, { key: index });

    let stD = moment(startDate.startDate).format("YYYY-MM-DD"),
      enD = moment(startDate.endDate).format("YYYY-MM-DD"),
      campSD = moment(this.state.startDateOfCampaign).format("YYYY-MM-DD"),
      campED = moment(this.state.endDateOfCampaign).format("YYYY-MM-DD");

    if (
      new Date(stD) >= new Date(campSD) &&
      new Date(enD) <= new Date(campED)
    ) {
      found.warning_status = 0;
    }

    found.startDate = startDate.startDate;
    found.endDate = startDate.endDate;
    let couponTabWarning = this.state.couponEventTabWarning ? true : false;
    if (startDate.startDate && startDate.endDate && couponTabWarning) {
      let stD = moment(startDate.startDate).format("YYYY-MM-DD"),
        enD = moment(startDate.endDate).format("YYYY-MM-DD"),
        campSD = moment(this.state.startDateOfCampaign).format("YYYY-MM-DD"),
        campED = moment(this.state.endDateOfCampaign).format("YYYY-MM-DD");

      couponTabWarning = !(
        new Date(stD) >= new Date(campSD) && new Date(enD) <= new Date(campED)
      )
        ? true
        : false;
    }

    this.setState({
      couponDescEventRow,
      couponEventTabWarning: couponTabWarning,
      dmEdited: !this.state.dmEdited && found.newRow ? false : true,
    });
  };

  isOutsideRangeHandler = (day) => {
    let formattedDate = moment(day).format(),
      startDate = moment().subtract(1, "d").format(),
      isSameOrAfter = moment(formattedDate).isSameOrAfter(startDate);

    return !isSameOrAfter;
  };

  isOutsideRangeHandlerLY = (day) => {
    // let formattedDate = moment(day).format(),
    //   endDate = moment(this.state.startDateOfCampaign).format(),
    //   isSameOrBefore = moment(formattedDate).isBefore(endDate);

    // return !isSameOrBefore;
    let formattedDate = moment(day).format(),
      endDate = moment().format(),
      isSameOrBefore = moment(formattedDate).isSameOrBefore(endDate);

    return !isSameOrBefore;
  };

  isOutsideRangeHandlerEvents = (day) => {
    let formattedDate = moment(day).format(),
      startDate = moment(this.state.startDateOfCampaign).format(),
      endDate = moment(this.state.endDateOfCampaign).add(1, "d").format(),
      isSameOrAfter = moment(formattedDate).isSameOrAfter(startDate),
      isSameOrBefore = moment(formattedDate).isBefore(endDate);

    return !(isSameOrAfter && isSameOrBefore);
  };

  onEventTypeChange = (val, index) => {
    this.submitGA("Click", "Selected_EditDM_Event_TYEventType");
    let { eventDescEventRow } = this.state,
      found = _.find(eventDescEventRow, { key: index });
    found.eventType = val;
    if (parseInt(found.offer_count) > 0) {
      found.edit_flag = 1;
    }

    this.setState({
      eventDescEventRow,
      eventDataEdited: true,
    });
  };

  onLyEventTypeChange = (val, index) => {
    this.submitGA("Click", "Selected_EditDM_Event_LYEvent");
    let { eventDescEventRow } = this.state,
      found = _.find(eventDescEventRow, { key: index });
    found.lyEventType = val;

    this.setState({
      eventDescEventRow,
      eventDataEdited: true,
      disableClearLYEvents: false,
    });
  };
  clearLyEvents = () => {
    let { eventDescEventRow } = this.state;
    _.map(eventDescEventRow, (item) => {
      item.lyEventType = [];
      return item;
    });
    this.setState({ eventDescEventRow, disableClearLYEvents: true });
  };
  onEventTypeChangeCoupons = (val, index) => {
    this.submitGA("Click", "Selected_EditDM_Coupon_EventType");
    let { couponDescEventRow } = this.state,
      found = _.find(couponDescEventRow, { key: index });
    found.eventType = val;

    this.setState({ couponDescEventRow });
  };

  onDescriptionChange = (val, keyNum) => {
    let { eventDescEventRow } = this.state,
      found = _.find(eventDescEventRow, { key: keyNum });

    found.description = val;

    this.setState({ eventDescEventRow, eventDataEdited: true });
  };

  addRows = (index, selection) => {
    if (selection === "event") {
      let { eventDescEventRow } = this.state;
      let newRowForEvent = {
        key: eventDescEventRow[eventDescEventRow.length - 1].key + 1,
        startDate: null,
        endDate: null,
        eventType: null,
        description: "",
        eventId: null,
        status: 0,
        campaignId: this.state.campaignId,
        lyEventType: [],
        offer_count: 0,
        edit_flag: 0,
        warning_status: 0,
        newRow: true,
      };
      this.state.eventDescEventRow.push(newRowForEvent);
      this.setState(this.state.eventDescEventRow);
      this.setState({
        eventDataEdited: true,
      });
    } else {
      let { couponDescEventRow } = this.state;
      let newRowForCoupon = {
        key: couponDescEventRow[couponDescEventRow.length - 1].key + 1,
        startDate: null,
        status: 0,
        endDate: null,
        eventType: "",
        coupon_events_id: null,
        campaignId: this.state.campaignId,
        warning_status: 0,
        newRow: true,
      };
      this.state.couponDescEventRow.push(newRowForCoupon);
      this.setState(this.state.couponDescEventRow);
    }
  };

  deleteRows = (index, selection) => {
    if (selection === "event") {
      this.setState({
        showLoader: true,
      });

      let { eventDescEventRow } = this.state,
        found = _.find(eventDescEventRow, { key: index }),
        deletedEventRow = _.filter(eventDescEventRow, (item) => {
          return item.key !== index ? item : null;
        });

      eventIdDeleted.push(found.eventId);

      this.setState({ deletedEvents: eventIdDeleted });
      setTimeout(() => {
        this.setState({
          showLoader: false,
        });
      }, alertTimer);
      this.setState({
        eventDescEventRow: deletedEventRow,
        eventDataEdited: true,
      });
    } else {
      this.setState({
        showLoader: true,
      });

      let { couponDescEventRow } = this.state,
        foundCoupon = _.find(couponDescEventRow, { key: index }),
        deletedCouponRow = _.filter(couponDescEventRow, (item) => {
          return item.key !== index ? item : null;
        });

      eventCouponIdDeleted.push(foundCoupon.coupon_events_id);

      this.setState({ deletedCoupons: eventCouponIdDeleted });
      setTimeout(() => {
        this.setState({
          showLoader: false,
        });
      }, alertTimer);

      this.setState({ couponDescEventRow: deletedCouponRow });
    }
  };

  onSaveEditCampaign = async (dataFromApplyLY) => {
    let { couponDescEventRow } = this.state,
      couponDescEventRw = _.cloneDeep(couponDescEventRow);
    let eventDescEventRow = _.isEmpty(dataFromApplyLY)
      ? _.cloneDeep(this.state.eventDescEventRow)
      : _.cloneDeep(dataFromApplyLY);
    // let foundRow = _.find(eventDescEventRow, (item) => {
    //   return (
    //     _.isEmpty(item.startDate) ||
    //     _.isEmpty(item.endDate) ||
    //     _.isEmpty(item.eventType)
    //   );
    // }),
    //   foundCouponRow = _.find(couponDescEventRw, (item) => {
    //     // coupon events validations for empty field on save
    //     return (
    //       (!_.isEmpty(item.startDate) &&
    //         (_.isEmpty(item.endDate) || _.isEmpty(item.eventType))) ||
    //       (!_.isEmpty(item.endDate) &&
    //         (_.isEmpty(item.startDate) || _.isEmpty(item.eventType))) ||
    //       (!_.isEmpty(item.eventType) &&
    //         (_.isEmpty(item.endDate) || _.isEmpty(item.startDate)))
    //     );
    //   });
    // if ((_.isEmpty(dataFromApplyLY) && foundRow) || foundCouponRow) {
    //   this.setState({ enterAllFileds: true });
    //   setTimeout(() => {
    //     this.setState({ enterAllFileds: false });
    //   }, 2500);
    //   return;
    // }

    // couponDescEventRw = _.filter(couponDescEventRw, (item) => {
    //   return !(
    //     _.isEmpty(item.startDate) ||
    //     _.isEmpty(item.endDate) ||
    //     _.isEmpty(item.eventType)
    //   );
    // });

    // let foundCoupon = _.find(couponDescEventRw, (item) => {
    //   let stD = moment(item.startDate).format("YYYY-MM-DD"),
    //     enD = moment(item.endDate).format("YYYY-MM-DD"),
    //     campSD = moment(this.state.startDateOfCampaign).format("YYYY-MM-DD"),
    //     campED = moment(this.state.endDateOfCampaign).format("YYYY-MM-DD");

    //   return !(
    //     new Date(stD) >= new Date(campSD) && new Date(enD) <= new Date(campED)
    //   );
    // }),
    //   eventDescEventRw = _.cloneDeep(eventDescEventRow);
    // eventDescEventRw = _.filter(eventDescEventRw, (item) => {
    //   return !(
    //     _.isEmpty(item.startDate) ||
    //     _.isEmpty(item.endDate) ||
    //     _.isEmpty(item.eventType)
    //   );
    // });
    // this.setState({
    //   eventDescEventRow: eventDescEventRw,
    // });
    // let found = _.find(eventDescEventRw, (item) => {
    //   let stD = moment(item.startDate).format("YYYY-MM-DD"),
    //     enD = moment(item.endDate).format("YYYY-MM-DD"),
    //     campSD = moment(this.state.startDateOfCampaign).format("YYYY-MM-DD"),
    //     campED = moment(this.state.endDateOfCampaign).format("YYYY-MM-DD");

    //   return !(
    //     new Date(stD) >= new Date(campSD) && new Date(enD) <= new Date(campED)
    //   );
    // });
    // if (_.isEmpty(found) && _.isEmpty(foundCoupon)) {
    await this.saveChangesMethod();
    // } else {
    //   let couponWarnig = _.map(couponDescEventRw, (item) => {
    //     let stD = moment(item.startDate).format("YYYY-MM-DD"),
    //       enD = moment(item.endDate).format("YYYY-MM-DD"),
    //       campSD = moment(this.state.startDateOfCampaign).format("YYYY-MM-DD"),
    //       campED = moment(this.state.endDateOfCampaign).format("YYYY-MM-DD");

    //     if (
    //       !(
    //         new Date(stD) >= new Date(campSD) &&
    //         new Date(enD) <= new Date(campED)
    //       )
    //     ) {
    //       item.warning_status = 1;
    //       return item;
    //     }
    //     return item;
    //   });
    //   let eventWarning = _.map(eventDescEventRw, (item) => {
    //     let stD = moment(item.startDate).format("YYYY-MM-DD"),
    //       enD = moment(item.endDate).format("YYYY-MM-DD"),
    //       campSD = moment(this.state.startDateOfCampaign).format("YYYY-MM-DD"),
    //       campED = moment(this.state.endDateOfCampaign).format("YYYY-MM-DD");

    //     if (
    //       !(
    //         new Date(stD) >= new Date(campSD) &&
    //         new Date(enD) <= new Date(campED)
    //       )
    //     ) {
    //       item.warning_status = 1;
    //       return item;
    //     }
    //     return item;
    //   });
    //   this.saveChangesMethod(eventWarning, couponWarnig);
    //   this.setState({
    //     // showDateRangeMismatchAlert: true,
    //     couponDescEventRow: couponWarnig,
    //     eventDescEventRow: eventWarning,
    //     eventTabWarning: _.isEmpty(found) ? false : true,
    //     couponEventTabWarning: _.isEmpty(foundCoupon) ? false : true,
    //   });

    //   // setTimeout(() => {
    //   //   this.setState({ showDateRangeMismatchAlert: false });
    //   // }, 7000);
    // }
  };
  applyLyPeriodClicked = async () => {
    this.setState({
      showLoader: true,
    });
    let resForEditDm = await this.props.editDMLyDateMapping({
      campaign_id: this.state.campaignId,
      ly_start_date: !_.isEmpty(this.state.lyStartDateOfCampaign)
        ? JSON.stringify(
          moment(this.state.lyStartDateOfCampaign).format("YYYY-MM-DD")
        ).slice(1, 11)
        : null,
      ly_end_date: !_.isEmpty(this.state.lyEndDateOfCampaign)
        ? JSON.stringify(
          moment(this.state.lyEndDateOfCampaign).format("YYYY-MM-DD")
        ).slice(1, 11)
        : null,
    });

    // if (resForEditDm.status) {
    //   let lyStartD = !_.isEmpty(this.state.lyStartDateOfCampaign)
    //     ? moment(this.state.lyStartDateOfCampaign).format("YYYY-MM-DD")
    //     : null,
    //     lyEndD = !_.isEmpty(this.state.lyEndDateOfCampaign)
    //       ? moment(this.state.lyEndDateOfCampaign).format("YYYY-MM-DD")
    //       : null;

    //   let resForEditDmLyEvents = await this.props.editDmGetLyEvents({
    //     campaign_id: this.state.campaignId.toString(),
    //     ly_start_date: lyStartD,
    //     ly_end_date: lyEndD,
    //   });

    //   if (resForEditDmLyEvents.status) {
    //     let lyEventOptions = [];
    //     let { eventDescEventRow } = this.state;
    //     this.eventTypeNOptionsHandler(resForEditDmLyEvents.data.data);
    //     lyEventOptions = resForEditDmLyEvents.data.data;

    //     _.map(eventDescEventRow, (item) => {
    //       item.lyEventType = [];
    //       return item;
    //     });

    //     this.setState({
    //       eventDescEventRow,
    //       lyEventOptions,
    //       applyClicked: true,
    //       showLoader: false,
    //       disableClearLYEvents: true,
    //     });
    //     this.onSaveEditCampaign(eventDescEventRow);
    //   } else {
    //     this.setState({ somethingWentWrong: true });
    //     setTimeout(() => {
    //       this.setState({ somethingWentWrong: false });
    //     }, 2500);
    //   }
    // }
    if (!resForEditDm.status) {
      this.setState({ noLyEventsError: true });
      setTimeout(() => {
        this.setState({ noLyEventsError: false });
      }, alertTimer);
    } else {
      this.setState({
        DmEdited: true,
        applyDisabled: true
      });

      setTimeout(() => {
        this.setState({ DmEdited: false });
      }, alertTimer);
    }

    this.setState({
      showLoader: false,
    });
  };

  saveChangesMethod = async (eventData, couponData) => {
    this.setState({
      showLoader: true,
    });
    let { eventDescEventRow, couponDescEventRow, merchantLockoutDate } = this.state;

    let resForCampaign = "";
    if (this.state.campaignDataEdited || this.state.eventDataEdited) {
      let findEditFlag = _.find(eventDescEventRow, { edit_flag: 1 });

      resForCampaign = await this.props.editCampaign({
        campaignId: this.state.campaignId,
        startDate: JSON.stringify(
          moment(this.state.startDateOfCampaign).format("YYYY-MM-DD")
        ).slice(1, 11),
        endDate: JSON.stringify(
          moment(this.state.endDateOfCampaign).format("YYYY-MM-DD")
        ).slice(1, 11),
        campaignName: this.state.eventName,
        isTyEdited:
          this.state.isTyEdited || !_.isEmpty(findEditFlag) ? true : false,
        merchant_lockout_date: merchantLockoutDate == "" || merchantLockoutDate == null ?
          ""
          : JSON.stringify(
            moment(this.state.merchantLockoutDate).format("YYYY-MM-DD")
          ).slice(1, 11),
      });

      if (resForCampaign.status && resForCampaign.warnings.length) {
        this.setState({
          dateRangeConfirmAlert: true,
          warningText: resForCampaign.warnings[0].msg,
        });
      }

      if (resForCampaign.status) {
        this.setState({
          DmEdited: true
        });

        setTimeout(() => {
          this.setState({ DmEdited: false });
          this.props.onDmEdit();
        }, alertTimer);


      }
    }

    // var event_data = _.isEmpty(eventData)
    //   ? _.cloneDeep(eventDescEventRow)
    //   : _.cloneDeep(eventData); //deep cloning the variable
    // var coupon_data = _.isEmpty(couponData)
    //   ? _.cloneDeep(couponDescEventRow)
    //   : _.cloneDeep(couponData); //deep cloning the variable

    // event_data = _.filter(event_data, (item) => {
    //   return !(
    //     _.isEmpty(item.startDate) ||
    //     _.isEmpty(item.endDate) ||
    //     _.isEmpty(item.eventType)
    //   );
    // });

    // coupon_data = _.filter(coupon_data, (item) => {
    //   return !(
    //     _.isEmpty(item.startDate) ||
    //     _.isEmpty(item.endDate) ||
    //     _.isEmpty(item.eventType)
    //   );
    // });

    // event_data.map((item) => {
    //   item.startDate = moment(item.startDate).format("YYYY-MM-DD");
    //   item.endDate = moment(item.endDate).format("YYYY-MM-DD");
    //   item.eventType = item.eventType.value;
    //   item.warning_status = item.warning_status ? item.warning_status : 0;
    //   let newEventType = [];
    //   if (item.lyEventType.length) {
    //     item.lyEventType.map((lyEvents) => {
    //       // lyEvents.ly_eventType = lyEvents.event_type;
    //       // lyEvents.ly_startDate = moment(lyEvents.start_date).format(
    //       //   "YYYY-MM-DD"
    //       // );
    //       // lyEvents.ly_endDate = moment(lyEvents.end_date).format("YYYY-MM-DD");
    //       // newEventType.push({
    //       //   ly_eventType: lyEvents.event_type,
    //       //   ly_startDate: moment(lyEvents.start_date).format("YYYY-MM-DD"),
    //       //   ly_endDate: moment(lyEvents.end_date).format("YYYY-MM-DD")
    //       // });
    //     });
    //     // item.lyEventType = newEventType;
    //   }
    //   return item;
    // });
    // // return;
    // coupon_data.map((item) => {
    //   item.startDate = moment(item.startDate).format("YYYY-MM-DD");
    //   item.endDate = moment(item.endDate).format("YYYY-MM-DD");
    //   item.eventType = item.eventType.value;
    //   item.warning_status = item.warning_status ? item.warning_status : 0;
    //   return item;
    // });

    // let findEventWarning = _.find(
    //   event_data,
    //   (item) => item.warning_status === 1
    // ),
    //   findCouponWarning = _.find(
    //     coupon_data,
    //     (item) => item.warning_status === 1
    //   ),
    //   camapignWarrningStatus =
    //     _.isEmpty(findEventWarning) && _.isEmpty(findCouponWarning) ? 0 : 1;

    // let resForEvent;
    // if (
    //   this.state.deletedEvents.length > 0 &&
    //   this.state.deletedCoupons.length > 0
    // ) {
    //   resForEvent = await this.props.editDM({
    //     events: event_data,
    //     campaignId: this.state.campaignId,
    //     couponsData: coupon_data,
    //     deletedEvents: this.state.deletedEvents,
    //     deletedCoupons: this.state.deletedCoupons,
    //     camapign_warrning_status: camapignWarrningStatus,
    //     dm_edited_flag: this.state.dmEdited,
    //   });
    // } else if (this.state.deletedEvents.length > 0) {
    //   resForEvent = await this.props.editDM({
    //     events: event_data,
    //     campaignId: this.state.campaignId,
    //     couponsData: coupon_data,
    //     deletedEvents: this.state.deletedEvents,
    //     camapign_warrning_status: camapignWarrningStatus,
    //     dm_edited_flag: this.state.dmEdited,
    //   });
    // } else if (this.state.deletedCoupons.length > 0) {
    //   resForEvent = await this.props.editDM({
    //     events: event_data,
    //     campaignId: this.state.campaignId,
    //     couponsData: coupon_data,
    //     deletedCoupons: this.state.deletedCoupons,
    //     camapign_warrning_status: camapignWarrningStatus,
    //     dm_edited_flag: this.state.dmEdited,
    //   });
    // } else {
    //   resForEvent = await this.props.editDM({
    //     events: event_data,
    //     campaignId: this.state.campaignId,
    //     couponsData: coupon_data,
    //     camapign_warrning_status: camapignWarrningStatus,
    //     dm_edited_flag: this.state.dmEdited,
    //   });
    // }

    // if (resForEvent.status) {
    //   this.setState({ DmEdited: true, showLoader: false });
    //   this.props.onDmEdit();
    //   this.listApiCall("noAlert");

    //   setTimeout(() => {
    //     this.setState({ DmEdited: false });
    //   }, 2500);
    // }
    this.setState({ showLoader: false });
  };
  submitGA(action, label) {
    PromotionCalendarGA({ action, label });
  }

  getMaxDate() {
    const startDate = this.state.startDateOfCampaign;
    // const previousWednesday = startDate.clone().subtract((startDate.day() + 7 - 3) % 7, 'days');
    // const resultDate = previousWednesday.clone().subtract(10, 'days');

    // return new Date(moment(resultDate).format("MM/DD/YYYY"));

    const dateIs = moment(startDate, 'YYYY-MM-DD').subtract(5,'days');
  const startDay = dateIs.day();
  console.log('Current: ', startDay);

  switch (startDay) {
    case 0:
      dateIs.add(-4, 'days');
      break;
    case 1:
      dateIs.add(-5, 'days');
      break;
    case 2:
      dateIs.add(-6, 'days');
      break;
    case 3:
      dateIs.add(-7, 'days');
      break;
    case 4:
      dateIs.add(-1, 'days');
      break;
    case 5:
      dateIs.add(-2, 'days');
      break;
    case 6:
      dateIs.add(-3, 'days');
      break;
    default:
      console.log('Default')
  }
  // dateIs.day(3 - 7)

  const promoBuildDate = dateIs.format('YYYY-MM-DD');
  console.log('promoBuildDate: ', promoBuildDate);
  let marketingLockDate = moment(promoBuildDate, 'YYYY-MM-DD')
  let promoBuildStartDay =  marketingLockDate.day();
  switch (promoBuildStartDay) {
    case 0:
      marketingLockDate.add(-1, 'days');
      break;
    case 1:
      marketingLockDate.add(-2, 'days');
      break;
    case 2:
      marketingLockDate.add(-3, 'days');
      break;
    case 3:
      marketingLockDate.add(-4, 'days');
      break;
    case 4:
      marketingLockDate.add(-5, 'days');
      break;
    case 5:
      marketingLockDate.add(-6, 'days');
      break;
    case 6:
      marketingLockDate.add(-7, 'days');
      break;
    default:
      console.log('Default')
  }

  // dateIs.add(-5, 'days');
  const marketingDate = marketingLockDate.format('YYYY-MM-DD');
  const marketingLockoutstartDate = moment(marketingDate, 'YYYY-MM-DD');
  const merchantLockoutDate = marketingLockoutstartDate.add(-1, 'days');

  return new Date(moment(merchantLockoutDate).format("MM/DD/YYYY"));

  }

  handleMechLockoutDateChange(merchLockDate) {
    this.setState({
      merchantLockoutDate: merchLockDate,
      campaignDataEdited: true,
      dmEdited: true,
    })
    this.submitGA("Click", "Clicked_MechantLockoutDate");
  }

  render() {
    return (
      <div className={this.state.pastDmEdit ? "disable-edit-dm" : ""}>
        <div className="alertDivs">
          {this.state.DmEdited ? (
            <AlertSuccess
              message="Campaign edited successfully!"
              show={this.state.DmEdited}
            />
          ) : null}

          {this.state.showNoDataAvailableError ? (
            <AlertWarning
              message="No events for this campaign !"
              show={this.state.showNoDataAvailableError}
            />
          ) : null}

          {this.state.enterAllFileds ? (
            <AlertWarning
              message="Please enter all the fields to continue!"
              show={this.state.enterAllFileds}
            />
          ) : null}
          {this.state.showNoDataAvailableErrorCoupon ? (
            <AlertWarning
              message="No coupon events for this campaign !"
              show={this.state.showNoDataAvailableErrorCoupon}
            />
          ) : null}

          {this.state.showDateRangeMismatchAlert ? (
            <AlertDanger
              message="Start date and end date are outside the Campaign range !"
              show={this.state.showDateRangeMismatchAlert}
            />
          ) : null}

          {this.state.somethingWentWrong ? (
            <AlertDanger
              message="Something went wrong!"
              show={this.state.somethingWentWrong}
            />
          ) : null}

          {this.state.noLyEventsError ? (
            <AlertDanger
              message="No LY events found for this period!"
              show={this.state.noLyEventsError}
            />
          ) : null}
        </div>
        <Modal
          visible="true"
          width="65%"
          height="300"
          effect="fadeInDown"
          onClickAway={() => this.props.closeModal()}
        >
          <PageLoader loader={this.state.showLoader}>
            <div className="closeicon">
              <span style={{ cursor: "pointer" }}>
                <i
                  className="fa fa-times"
                  aria-hidden="true"
                  onClick={() => this.props.closeModal()}
                />
              </span>
              <h5 className="promoNameText text-center mb-0">Edit Campaign</h5>
            </div>

            <div className="createClusterDiv create-cluster-edit-dm card cardStyles">
              <div className="row editDm-selection-styling">
                <div className="col-4">
                  <p className="labelAdGroup mb-0">Campaign Name</p>
                  <input
                    type="text"
                    className="adGroupInputStyling ml-2 edit-dm-input"
                    disabled={true}
                    value={`${moment(this.state.startDateOfCampaign).format("MM/DD/YYYY")} - ${moment(this.state.endDateOfCampaign).format("MM/DD/YYYY")}`}
                    onChange={(e) => {
                      this.setState({
                        eventName: e.target.value,
                      });
                    }}
                  />
                </div>

                <div className="col-4 date-picker-coupon">
                  <p className="labelAdGroup mb-0">TY Time Period</p>
                  <div
                    style={{ textAlign: "center" }}
                    className="d-inline-block ml-2"
                  >
                    <DateRangePicker
                      startDate={this.state.startDateOfCampaign}
                      endDate={this.state.endDateOfCampaign}
                      onDatesChange={({ startDate, endDate }) => {
                        this.onDatesChangeCampaign({ startDate, endDate })
                        this.submitGA("Click", "Selected_EditDM_TYTimePeriod");
                      }}
                      isOutsideRange={(day) => this.isOutsideRangeHandler(day)}
                      renderMonthElement={true}
                    />
                  </div>
                </div>
                <div className="col-4 text-center merchant-lockout">
                  <p className="labelAdGroup mb-0">Merchant Lockout Date</p>
                  <DatePicker
                    // id="merchLoackoutDate"
                    className="datePickerValueStyling"
                    selected={
                      this.state.merchantLockoutDate ?
                        this.state.merchantLockoutDate
                        : ""
                    }
                    onChange={(date) => {
                      this.handleMechLockoutDateChange(date)
                    }}
                    dateFormat="MM/dd/yyyy"
                    minDate={
                      new Date()
                    }
                    maxDate={
                      this.state.startDateOfCampaign ? this.getMaxDate() : null
                    }
                    placeholderText="Select Merchant Lockout Date"
                    // disabled={!this.state.date.startDate}
                    // showIcon
                    popperPlacement="bottom"
                    // popperProps={{
                    //   positionFixed: true
                    // }}
                  />
                </div>
              </div>
              <div className="row editDm-selection-styling">

                <div className="col-4 date-picker-coupon">
                  <p className="labelAdGroup mb-0">LY Time Period</p>
                  <div
                    style={{ textAlign: "center" }}
                    className="d-inline-block ml-2 edit-dm-ly-date-picker"
                  >
                    <DateRangePicker
                      startDate={this.state.lyStartDateOfCampaign}
                      endDate={this.state.lyEndDateOfCampaign}
                      onDatesChange={({ startDate, endDate }) => {
                        this.onDatesChangeCampaignLy({ startDate, endDate })
                        this.submitGA("Click", "Selected_EditDM_LYTimePeriod");
                      }}
                      isOutsideRange={(day) =>
                        this.isOutsideRangeHandlerLY(day)
                      }
                      renderMonthElement={true}
                    />
                    {!_.isEmpty(this.state.lyStartDateOfCampaign) &&
                      !_.isEmpty(this.state.lyEndDateOfCampaign) ? (
                      <i
                        className="fa fa-times"
                        aria-hidden="true"
                        onClick={() =>
                          this.onDatesChangeCampaignLy({
                            startDate: "",
                            endDate: "",
                          })
                        }
                      />
                    ) : null}
                  </div>
                </div>
                <div className="col-4 align-self-end pl-4">
                  <button
                    class="btn btn-primary px-4"
                    disabled={
                      (!_.isEmpty(this.state.lyStartDateOfCampaign) &&
                        _.isEmpty(this.state.lyEndDateOfCampaign)) ||
                      (_.isEmpty(this.state.lyStartDateOfCampaign) &&
                        !_.isEmpty(this.state.lyEndDateOfCampaign)) ||
                      this.state.applyDisabled || 
                      isIt()
                    }
                    onClick={() => {
                      this.applyLyPeriodClicked()
                      this.submitGA("Click", "Clicked_ApplyLYMapping");
                    }}
                  >
                    Apply LY Mapping
                  </button>
                </div>
              </div>
            </div>

            {/* <Tabs
              id="controlled-tab-example"
              activeKey={this.state.key}
              onSelect={(key) => {
                this.setState({ key });
                this.submitGA("Click", "Switched_EventCouponTabs");
              }}
              style={{ height: "7%" }}
            >
              <Tab
                eventKey="event"
                title={
                  <span>
                    {this.state.eventTabWarning ? (
                      <i className="fa fa-exclamation-triangle calendar-warning-icon pr-2" />
                    ) : null}
                    Event
                  </span>
                }
              >
                <div
                  className="create-cluster-edit-dm card cardStyles"
                  style={{ height: "350px" }}
                >
                  {this.state.eventDescEventRow.length ? (
                    <div className="row editDm-label-styling ml-0">
                      <div className="col-md-1" />
                      <div className="col edit-dm-col">
                        <p className="labelAdGroup mb-1">TY Time Period</p>
                      </div>
                      <div className="col edit-dm-col">
                        <p className="labelAdGroup mb-1">TY event</p>
                      </div>
                      <div className="col edit-dm-col">
                        <p className="labelAdGroup mb-1">TY Event Desc</p>
                      </div>
                      <div className="col edit-dm-col">
                        <p className="labelAdGroup mb-1">LY Event</p>
                      </div>
                      <div className="col-md-1" />
                    </div>
                  ) : null}
                  <div className="editdm-row-container">
                    {this.state.eventDescEventRow.map(
                      (rowEventValues, index) => (
                        <AddEventRow
                          row={rowEventValues}
                          rowLength={this.state.eventDescEventRow.length}
                          index={index}
                          onDatesChange={this.onDatesChange}
                          addRows={(row) => {
                            this.addRows(row, "event");
                            this.submitGA("Click", "Clicked_EditDM_Event_+Button");
                          }}
                          deleteRows={(row) => {
                            this.deleteRows(row, "event");
                            this.submitGA("Click", "Clicked_EditDM_Event_-Button");
                          }}
                          onDescriptionChange={this.onDescriptionChange}
                          onEventTypeChange={this.onEventTypeChange}
                          isOutsideRangeHandler={
                            this.isOutsideRangeHandlerEvents
                          }
                          startDateOfCampaign={this.state.startDateOfCampaign}
                          applyClicked={this.state.applyClicked}
                          lyEventOptions={this.state.lyEventOptions}
                          onLyEventTypeChange={this.onLyEventTypeChange}
                        />
                      )
                    )}
                    <div className="edit-dm-space-maker"></div>
                  </div>
                </div>
              </Tab>
              <Tab
                eventKey="coupon"
                title={
                  <span>
                    {this.state.couponEventTabWarning ? (
                      <i className="fa fa-exclamation-triangle calendar-warning-icon pr-2" />
                    ) : null}
                    Coupon Events
                  </span>
                }
              >
                <div className="createClusterDiv create-cluster-edit-dm card cardStyles">
                  <div className="row editDm-label-styling">
                    <div className="col-md-1" />
                    <div className="col-md-3">
                      <p className="labelAdGroup mb-1">Time Period</p>
                    </div>
                    <div className="col-md-3">
                      <p className="labelAdGroup mb-1">Event Type</p>
                    </div>
                  </div>

                  <div className="editdm-row-container editdm-coupon">
                    {this.state.couponDescEventRow.map(
                      (rowEventValues, index) => (
                        <AddEventRowForCoupons
                          row={rowEventValues}
                          index={index}
                          rowLength={this.state.couponDescEventRow.length}
                          addRows={(row) => {
                            this.addRows(row, "coupon");
                            this.submitGA("Click", "Clicked_EditDM_Coupon_+Button");
                          }}
                          deleteRows={(row) => {
                            this.deleteRows(row, "coupon");
                            this.submitGA("Click", "Clicked_EditDM_Coupon_-Button");
                          }}
                          onDatesChange={this.onDatesChangeForCoupon}
                          onEventTypeChange={this.onEventTypeChangeCoupons}
                          isOutsideRangeHandler={
                            this.isOutsideRangeHandlerEvents
                          }
                          startDateOfCampaign={this.state.startDateOfCampaign}
                        />
                      )
                    )}
                  </div>
                </div>
              </Tab>
            </Tabs> */}
            <div className="text-center mt-4 pt-2">
              {/* {this.state.key === "event" ? (
                <button
                  type="button"
                  className="btn btn-outline-primary promo3Buttons mr-3"
                  disabled={
                    this.state.disableClearLYEvents || this.state.pastDmEdit
                  }
                  onClick={() => {
                    this.clearLyEvents();
                    this.submitGA("Click", "Clicked_EditDM_Event_ClearAllLYEventsButton");
                  }}
                >
                  Clear All LY Events
                </button>
              ) : null} */}
              <button
                disabled={this.state.pastDmEdit || !this.state.merchantLockoutDate || !this.state.dmEdited || isIt()}
                type="button"
                className="btn btn-outline-primary promo3Buttons"
                onClick={() => {
                  this.onSaveEditCampaign();
                  this.submitGA("Click", "Clicked_EditDM_EventCoupon_SaveButton");
                }}
              >
                Save
              </button>
            </div>
          </PageLoader>
        </Modal>

        <Modal
          visible={this.state.dateRangeConfirmAlert}
          width="475"
          height="auto"
          effect="fadeInDown"
          onClickAway={() => this.setState({ dateRangeConfirmAlert: false })}
        >
          <div className="position-relative">
            <h5 className="mx-5 mt-4 pt-3">
              <i
                className="fa fa-exclamation-triangle"
                style={{ color: "yellow" }}
                aria-hidden="true"
                title="Warning"
              />
              &nbsp;Warning
            </h5>
            <p className="mx-5">{this.state.warningText}</p>
            <p className="mx-5 mt-4 text-right">
              <button
                className="btn btn-primary mr-4"
                onClick={() => this.setState({ dateRangeConfirmAlert: false })}
              >
                Close
              </button>
            </p>
            <div className="modal-background"></div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default connect(null, {
  getEditDMList,
  getEventCouponList,
  editDM,
  editCampaign,
  editDMLyDateMapping,
  editDmGetLyEvents,
})(EditDm);
