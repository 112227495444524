import React, { Component } from "react";
import "./errorMsgStyle.css";

export class ErrorMsg extends Component {
  render() {
    return (
      <div
        className="h-md-100 align-items-center"
        style={{ background: "white", overflow: "hidden", marginTop: "-103px" }}
      >
        <div id="not-found">
          <p
            id="msgError"
            onClick={() => this.props.history.push("/promotion")}
          >
            Back to Home
          </p>
          <div className="circles">
            <p>
              404
              <br />
              <small>PAGE NOT FOUND</small>
            </p>
            <span className="circle big"></span>
            <span className="circle med"></span>
            <span className="circle small"></span>
          </div>
        </div>
      </div>
    );
  }
}

export default ErrorMsg;
