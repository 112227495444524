import React, { Component } from "react";
import Modal from "react-awesome-modal";

class OfferReplaceModal extends Component {
  render() {
    return (
      <Modal
        visible={true}
        width="475"
        height="auto"
        effect="fadeInDown"
        onClickAway={this.props.closeModal}
      >
        <div className="position-relative">
          <h5 className="mx-5 mt-4 pt-3">
            <i
              className="fa fa-exclamation-triangle"
              style={{ color: "orange" }}
              aria-hidden="true"
              title="Warning"
            />
            &nbsp;Warning
          </h5>
          <p className="mx-5">
            {this.props.showOfferReplaceText} 
            . Do you want to override the values
            ?
          </p>
          <p className="mx-5 mt-4 text-right">
            <button
              className="btn btn-primary mr-4"
              onClick={this.props.closeModal}
            >
              Close
            </button>

            <button
              className="btn btn-primary mr-4"
              onClick={this.props.onReplaceOffer}
            >
              Save
            </button>
          </p>
          <div className="modal-background"></div>
        </div>
      </Modal>
    );
  }
}

export default OfferReplaceModal;
