import React, { Component } from "react";
import Modal from "react-awesome-modal";
import { PromotionCalendarGA } from '../../../../utils/GA/GAUtils';
import { isIt } from "../../../../utils/getRoleName";

export class CommentModalPrompt extends Component {
  constructor(props) {
    super(props);
    this.state = {
      commentValueForSave: ""
    };
  }

  componentDidMount() {
    if (this.props.preSavedComment) {
       this.setState({
        commentValueForSave: this.props.preSavedComment
      });
    }
  }

  saveComment = () => {
    if (this.state.commentValueForSave.length) {
      this.props.closeDeleteModal();
      this.props.sendCommentValue(this.state.commentValueForSave);
    }
  };
  submitGA(action,label){
    PromotionCalendarGA({ action, label});
  }
  render() {
    return (
      <Modal
        visible={true}
        width="500px"
        height="250px"
        effect="fadeInDown"
        onClickAway={() => this.props.closeDeleteModal()}
      >
        <div className="closeicon">
          <span style={{ cursor: "pointer" }}>
            <i
              className="fa fa-times"
              aria-hidden="true"
              onClick={() => this.props.closeDeleteModal()}
            />
          </span>
        </div>
        <p className="promoLbels text-center">Comment:</p>
        <input
          type="text"
          className="form-control card commentAreaInput comment-for-save"
          rows="6"
          placeholder="Enter your comment ⃰"
          value={this.state.commentValueForSave}
          onChange={e => {
            this.setState({
              commentValueForSave: e.target.value
            });
          }}
          disabled={isIt()}
        />

        <div className="text-center delete-btns">
          <button
            className="btn btn-outline-primary promo3Buttons"
            onClick={() => {
              this.saveComment();
              this.submitGA("Click", "Clicked_Comment_SaveButton");
            }}
              disabled={ (isIt()) || ((this.state.commentValueForSave).trim().length ? false : true)}
          >
            Save
          </button>
        </div>
      </Modal>
    );
  }
}

export default CommentModalPrompt;
