import {VALIDATION_DATA, VALIDATION_SKU_DATA, LOADER, SHOW_VALIDATION} from "../actions/types";
const initialState = {
  validationData: [],
  validationSkuData: [],
  loading: null
};

export default function(state = initialState, action) {
  switch (action.type) {
    case VALIDATION_DATA:
      return {
        ...state,
        validationData: action.payload,
        
      };
      case SHOW_VALIDATION:
        return {
          ...state,
         loading: action.payload
        };
      case LOADER:
      return {
        ...state,
         loader: action.payload,
      };
      case VALIDATION_SKU_DATA:
      return {
        ...state,
        validationSkuData: action.payload
      };
    default:
      return {
        ...state
      };
  }
}