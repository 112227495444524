import React, { Component } from "react";

  export class UserChildMsgRenderer extends Component {
    activateDisableHandler = () => {
      this.props.data.activateOrDisableUser({
        email: this.props.data.email,
        user_id: this.props.data.id,
        status: this.props.data.user_status === "ACTIVE" ? -1 : 1,
      });
    };
  
    editHandler = () => {
      this.props.data.editUser(this.props.data);
    };
    loginAllAccessHandler = () => {
      this.props.data.loginAllAccessHandler(this.props.data);
    };
  
    render() {
      let loginAllFlag = localStorage.getItem("loginallflag");
      
      return (
        <>
          <p className="text-center">
            {this.props.data.user_status === "IN-ACTIVE" ? (
              <i
                data-toggle="popover"
                title="Activate"
                data-trigger="hover"
                className="fa fa-check-circle"
                style={{
                  color: "#007BFF",
                  cursor: "pointer",
                  fontSize: "15px",
                }}
                onClick={this.activateDisableHandler}
              />
            ) : (
              <i
                title="Disable"
                className="fa fa-ban"
                style={{
                  color: "red",
                  cursor: "pointer",
                  fontSize: "15px",
                }}
                onClick={this.activateDisableHandler}
              />
            )}
  
            <i
              title="Edit"
              data-trigger="hover"
              className="fa fa-pencil font-weight-bold"
              style={{
                color: "#357ef2",
                cursor: "pointer",
                margin: "0 15px",
                fontSize: "15px",
              }}
              onClick={this.editHandler}
            />
            {loginAllFlag === "1" && this.props.data.user_status !== "IN-ACTIVE" ? (
              <i
                title={`Login as ${this.props.data.user_name}`}
                data-trigger="hover"
                className="fa fa-sign-in font-weight-bold"
                style={{
                  color: "#357ef2",
                  cursor: "pointer",
                  fontSize: "15px",
                }}
                onClick={this.loginAllAccessHandler}
              />
            ) : null}
          </p>
        </>
      );
    }
  }
  
  export default UserChildMsgRenderer;