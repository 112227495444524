// import axios from "axios";
import axios from "../../utils/axios/index";
import { config } from "../../utils/config";
import {
  CUMULATIVE_FILTERS,
  GET_ERRORS,
  LOADER,
  SHOW_VALIDATION
} from "./types";

export const getSummaryData = reqObject => async dispatch => {
  dispatch({
    type: SHOW_VALIDATION,
    payload: true
  });
  let res = await axios.post(
    `${config.baseUrl}/executive/executiveSummaryLocal`,
    reqObject
  );
  dispatch({
    type: SHOW_VALIDATION,
    payload: false
  });
  dispatch({
    type: LOADER,
    payload: false
  });
  return res.data;
};

export const getCumulativeFilters = reqObject => dispatch => {
  dispatch({
    type: SHOW_VALIDATION,
    payload: true
  });
  axios
    .post(`${config.baseUrl}/promo/individualFiltersApi`, reqObject)
    .then(res => {
      dispatch({
        type: CUMULATIVE_FILTERS,
        payload: res.data.data
      });
      dispatch({
        type: SHOW_VALIDATION,
        payload: false
      });
      dispatch({
        type: LOADER,
        payload: false
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err
      });
    });
};
