export const SIGNSHELL_OPTIONS = [
  {
    value: 'SPC1',
    label: 'SPC1',
    // signShellOptions: 1
  },
  {
    value: 'SPC2',
    label: 'SPC2',
    // signShellOptions: 2
  },
  {
    value: 'SPC3',
    label: 'SPC3',
    // signShellOptions: 3
  },
  {
    value: 'SPC4',
    label: 'SPC4',
    // signShellOptions: 4
  },
  {
    value: 'SPC5',
    label: 'SPC5',
    // signShellOptions: 5
  },
  {
    value: 'SPC6',
    label: 'SPC6',
    // signShellOptions: 6
  },
  {
    value: 'SPC7',
    label: 'SPC7',
    // signShellOptions: 7
  },
  {
    value: 'SPC8',
    label: 'SPC8',
    // signShellOptions: 8
  },
  {
    value: 'SPC10',
    label: 'SPC10',
    signShellOptions: 9
  },
  {
    value: 'Clrnce',
    label: 'Clrnce',
    // signShellOptions: 10
  },
  {
    value: 'GO',
    label: 'GO',
    // signShellOptions: 11
  },
  {
    value: 'Sale',
    label: 'Sale',
    // signShellOptions: 12
  },
];

export const UAS_OPTIONS = [
  {
    value: 'x',
    label: 'Yes',
    uasOptions: 1,
  },
  {
    value: '-',
    label: 'No',
    uasOptions: 2,
  },
]

export const PROMO_EVENT_TYPE_OPTIONS = [
  {
    value: 'P',
    label: 'P',
    promoEventTypeOptions: 1,
  },
  {
    value: 'D',
    label: 'D',
    promoEventTypeOptions: 2,
  },
]

export const SIGNSHELL_OPTIONS_NEW = [
  {
    value: 'SPC1',
    label: 'SPC1 - Feature',
    // signShellOptions: 1
  },
  {
    value: 'SPC2',
    label: 'SPC2 - Doorbuster',
    // signShellOptions: 2
  },
  {
    value: 'SPC3',
    label: 'SPC3 - 1-Day Sale',
    // signShellOptions: 3
  },
  {
    value: 'SPC4',
    label: 'SPC4 - 2-Day Sale',
    // signShellOptions: 4
  },
  {
    value: 'SPC5',
    label: 'SPC5 - 3-Day Sale',
    // signShellOptions: 5
  },
  {
    value: 'SPC6',
    label: 'SPC6 - Last Chance',
    // signShellOptions: 6
  },
  {
    value: 'SPC7',
    label: 'SPC7 - 4-Day Sale',
    // signShellOptions: 7
  },
  {
    value: 'SPC8',
    label: 'SPC8 - Now Sewing Month',
    signShellOptions: 8
  },
  {
    value: 'SPC10',
    label: 'SPC10 - Colossal Savings Sale',
    signShellOptions: 9
  },
  {
    value: 'Clrnce',
    label: 'Clrnce - Clearance',
    // signShellOptions: 10
  },
  {
    value: 'GO',
    label: 'GO - New Store Savings',
    // signShellOptions: 11
  },
  {
    value: 'Sale',
    label: 'Sale',
    // signShellOptions: 12
  },
];

export const MARKETING_USER = "3";
export const DI_USER = "6";
export const SUPER_USER = "1";
export const MERCHANT_USER = "2";