import React, { Component } from "react";
import Select from "react-select";
import { UAS_OPTIONS, PROMO_EVENT_TYPE_OPTIONS, MARKETING_USER, SUPER_USER, DI_USER, MERCHANT_USER } from "./constants"
export class PipelineDropdownRenderer extends Component {
  // used for ag-grid refresh cells purpose
  state = { value: "" };
  onChangeHandler = (val) => {
        console.log(val)
        if (val.eventTypeOptions) {
      if (this.props.data.promo_type) {
        this.props.data.promo_type = val.event_type;
      } else {
        this.props.data.ty_event_type = val.event_type;
      }

      // this.props.data.selected_ty_event = val.value;
      this.props.data.selected_ty_event = val.label;
      this.props.data.val_selected_ty_event = val.value;
      this.props.data.ty_start_date = val.start_date;
      this.props.data.ty_end_date = val.end_date;
      this.props.data.eventId = val.eventId;
      this.props.data.isRowSaved = false;
      this.props.data.g_eid = val.g_eid;
      this.props.data.promo_sign_shell = val.promo_sign_shell;
      this.props.data.promo_event_type = val.type == "D" ? "D" : "P";
      this.props.data.type = val.type;
      this.props.data.tyOfferdropdownChange(this.props.node.id)

    } else if (val.offerTypeOptions) {
      if (this.props.data.offer_type) {
        this.props.data.offer_type = val.value;
      } else {
        this.props.data.ty_offer_type = val.value;
        this.props.data.ty_planned_amount = '';
        this.props.data.ty_min_qty = '';
        this.props.data.ty_bonus_pay = '';
      }
      if (val.value == 'F') {
        this.props.data.fixed_price_quantity = 1;
      } else {
        this.props.data.fixed_price_quantity = 0;
      }
      this.props.data.isRowSaved = false;
      this.props.data.ty_buy_min_value = "";
      this.props.data.ty_get_max_value = "";
      this.props.data.plannedOfferHandler(this.props.node.id);
    }
    // } else if (val.value) {
    //   console.log(val.value)
    //   this.props.data.promo_sign_shell = val.value;
    // }
     else if (val.uasOptions) {
      this.props.data.uas = val.value
    } else if (val.promoEventTypeOptions) {
      this.props.data.promo_event_type = val.value
    } else if(val == 'clear_offer_type') {
      this.props.data.offer_type = '';
      this.props.data.ty_offer_type = '';
      this.props.data.ty_planned_amount = '';
      this.props.data.ty_min_qty = '';
      this.props.data.ty_bonus_pay = '';
      this.props.data.isRowSaved = false;
      this.props.data.ty_buy_min_value = '';
      this.props.data.ty_get_max_value = '';
      this.props.data.fixed_price_quantity = null;
      this.props.data.plannedOfferHandler(this.props.node.id);
    }
    else if (val.value) {
      console.log(val.value)
      this.props.data.promo_sign_shell = val.value;
    }
    this.setState({ value: "" });
  };
  onKeyChangeHandler = (key) => { };

  getClassName = () => {
    if (this.props.colDef.headerName === "TY Planned Offer Type" || this.props.colDef.headerName === "Offer Type") {
      return "reference-sku-select intermediate-offer-type mt-0"
    }

    if (this.props.colDef.headerName === "TY Sign shell" || this.props.colDef.headerName === "Sign Shell") {
      return "reference-sku-select sign-shell mt-0"
    }

    if (this.props.colDef.headerName === "TY UAS" || this.props.colDef.headerName === "UAS") {
      return "reference-sku-select ty-uas mt-0"
    }

    if (this.props.colDef.headerName === "TY promo type" || this.props.colDef.headerName === "Promo Type") {
      return "reference-sku-select ty-promo-event-type mt-0"
    }

    return "reference-sku-select intermediate-event-type mt-0"
  }

  getPlaceholder = () => {
    if (this.props.colDef.headerName === "TY Planned Offer Type" || this.props.colDef.headerName === "Offer Type") {
      return "Select Offer type"
    }

    if (this.props.colDef.headerName === "TY Sign shell" || this.props.colDef.headerName === "Sign Shell") {
      return "Select sign shell"
    }

    if (this.props.colDef.headerName === "TY UAS" || this.props.colDef.headerName === "UAS") {
      return "select UAS"
    }

    if (this.props.colDef.headerName === "TY promo type" || this.props.colDef.headerName === "Promo Type") {
      return "Select promo type"
    }

    return "Select Event type"
  }

  getOptions = () => {
    if (this.props.colDef.headerName === "TY Planned Offer Type" || this.props.colDef.headerName === "Offer Type") {
      return this.props.data.offerTypeOptions
    }

    if (this.props.colDef.headerName === "TY Sign shell" || this.props.colDef.headerName === "Sign Shell") {
      // return this.props.data.sighShellOptions
      return this.props.data.signShellOptions
    }

    if (this.props.colDef.headerName === "TY UAS" || this.props.colDef.headerName === "UAS") {
      return this.props.data.uasOptions
    }

    if (this.props.colDef.headerName === "TY promo type" || this.props.colDef.headerName === "Promo Type") {
      return this.props.data.promoEventTypeOptions
    }

    return this.props.data.eventTypeOptions
  }

  getValue = () => {
    if (this.props.colDef.headerName === "TY Planned Offer Type" || this.props.colDef.headerName === "Offer Type") {
      if ((this.props.data.ty_offer_type !== null ||
        this.props.data.offer_type !== null) &&
        this.props.data[this.props.colDef.field] !== 0
      ) {
        return {
          value: this.props.data.offer_type
            ? this.props.data.offer_type
            : this.props.data.ty_offer_type,
          label: this.props.data.offer_type
            ? this.props.data.offer_type
            : this.props.data.ty_offer_type,
        }
      }
    }

    if (this.props.colDef.headerName === "TY Sign shell" || this.props.colDef.headerName === "Sign Shell") {
            if ((this.props.data.promo_sign_shell ||
        this.props.data.ty_event_type !== null) &&
        this.props.data[this.props.colDef.field] !== 0
      ) {
        return {
          value: this.props.data.promo_sign_shell,
          label: this.props.data.promo_sign_shell,
        }
      }
    }

    if (this.props.colDef.headerName === "TY event" || this.props.colDef.headerName === "Event") {
      if ((this.props.data.promo_type ||
        this.props.data.ty_event_type !== null) &&
        this.props.data[this.props.colDef.field] !== 0
      ) {
        return {
          value: this.props.data.val_selected_ty_event,
          label: this.props.data.selected_ty_event,
        }
      }
    }

    if (this.props.colDef.headerName === "TY UAS" || this.props.colDef.headerName === "UAS") {

      console.log("this.props.data",this.props.data);

      const tempUas = this.props.data.uas || this.props.data.ty_uas;
      if (tempUas &&
        this.props.data[this.props.colDef.field] !== 0
      ) {
        const selectedOption = UAS_OPTIONS.filter(ele => ele.value == tempUas);
        return selectedOption
      } else {
        return {
          value: "-",
          label: "No"
        }
      }
    }

    if (this.props.colDef.headerName === "TY promo type" || this.props.colDef.headerName === "Promo Type") {
      if (this.props.data.promo_event_type && this.props.data[this.props.colDef.field] !== 0) {
        const selectedOption = PROMO_EVENT_TYPE_OPTIONS.filter(ele => ele.value == this.props.data.promo_event_type);
        return selectedOption
      }
    }

    return ""
  }

  getIsDisabled = () => {
    const loggedUserRole = localStorage.getItem("role");
    const campMerchLockoutDate = localStorage.getItem("campMerchLockoutDate");

    if (this.props.colDef.headerName === "TY Sign shell" || this.props.colDef.headerName === "Sign Shell") {

      console.log(localStorage.getItem("campMerchLockoutDate"));   
      if (!(loggedUserRole == MARKETING_USER || loggedUserRole == SUPER_USER)){
        return true;
      }
      // if(loggedUserRole == MERCHANT_USER && (new Date() > new Date(campMerchLockoutDate))){
      //   return true;
      // }
     }

    if (this.props.colDef.headerName === "TY promo type" || this.props.colDef.headerName === "Promo Type") {

      if (!(loggedUserRole == MARKETING_USER  || loggedUserRole == SUPER_USER || (loggedUserRole == 6 && this.props.colDef.headerName === "Promo Type")) ) {
        return true
      }

      // if(loggedUserRole == MERCHANT_USER && (new Date() > new Date(campMerchLockoutDate))){
      //   return true;
      // }
    }

    if (this.props.colDef.headerName === "TY event" || this.props.colDef.headerName === "Event") {

      if (this.props.data.isModify) {
        return true
      }
    }

    return false;
  }

  render() {
    return (
      <div className="pipeline-adgroup-add add-from-ly-modal">
        <Select
          className={this.getClassName()}
          placeholder={this.getPlaceholder()}
          options={this.getOptions()}
          isDisabled={this.getIsDisabled()}
          value={this.getValue()}
          onChange={(val) => this.onChangeHandler(val)}
          onInputChange={(e) => this.onKeyChangeHandler(e)}
          styles={{ menuPortal: (base) => ({ ...base, zIndex: 20002 }) }}
          menuPortalTarget={document.body}
          menuPosition={'fixed'}
        />
        {/* { (this.props.colDef.headerName === "TY Planned Offer Type" ||  this.props.colDef.headerName === "Offer Type") &&
          (this.props.data.offer_type !== 0 ||
            this.props.data.ty_offer_type !== 0) &&
          (!this.props.data.title) ? (
          <i
            className="fa fa-times close1"
            aria-hidden="true"
            onClick={() => {
              // this.props.data.offer_type = 0;
              // this.props.data.ty_offer_type = 0;
              this.setState({ value: "" });
              this.onChangeHandler('clear_offer_type')
            }}
            style={{ left: "64%", top: "21%" }}
          />
        ) : null} */}

        {/* {(this.props.colDef.headerName === "TY event" || this.props.colDef.headerName === "Event") &&
          (this.props.data.promo_type || this.props.data.ty_event_type) &&
          !this.props.data.title && !this.props.data.isModify? (
          <i
            className="fa fa-times close1"
            aria-hidden="true"
            onClick={() => {
              this.props.data.ty_event_type = null;
              this.props.data.promo_type = null;
              this.setState({ value: "" });
            }}
            style={{ left: "89%", top: "21%" }}
          />
        ) : null} */}

        {/* {(this.props.colDef.headerName === "TY Sign shell" || this.props.colDef.headerName === "Sign Shell")&&
          this.props.data.promo_sign_shell &&
          !this.props.data.title ? (
          <i
            className="fa fa-times close1"
            aria-hidden="true"
            onClick={() => {
              this.props.data.promo_sign_shell = null;
              this.setState({ value: "" });
            }}
            style={{ left: "89%", top: "21%" }}
          />
        ) : null} */}

        {/* {(this.props.colDef.headerName === "TY UAS" || this.props.colDef.headerName === "UAS") && 
          ( (this.props.data.ty_uas || this.props.data.uas) && !this.props.data.title) ? (
          <i
            className="fa fa-times close1"
            aria-hidden="true"
            onClick={() => {
              this.props.data.ty_uas = null;
              this.setState({ value: "" });
            }}
            style={{ left: "89%", top: "21%" }}
          />
        ) : null} */}

        {/* {(this.props.colDef.headerName === "TY promo type" || this.props.colDef.headerName === "Promo Type")&&
          this.props.data.promo_event_type &&
          !this.props.data.title ? (
          <i
            className="fa fa-times close1"
            aria-hidden="true"
            onClick={() => {
              this.props.data.promo_event_type = null;
              this.setState({ value: "" });
            }}
            style={{ left: "89%", top: "21%" }}
          />
        ) : null} */}
      </div>
    );
  }
}

export default PipelineDropdownRenderer;
