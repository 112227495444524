import React, { Component } from "react";
import moment from "moment";
import _ from "lodash";
import { connect } from "react-redux";
import { PromotionCalendarGA } from '../../../../utils/GA/GAUtils';
import { FileExcelOutlined } from '@ant-design/icons';

class PipelineActionButtons extends Component {

  constructor(props) {
    super(props);
    this.isCampaignExpired = this.isCampaignExpired.bind(this);
  }

  isCampaignExpired() {
    const { campaignEndDate } = this.props;
    const currentDate = moment();
    console.log(currentDate)
    // Checking if the current date is after the campaign end date
    const campaignEndMoment = moment(campaignEndDate).endOf('day');
    console.log("🚀 ~ PipelineActionButtons ~ isCampaignExpired ~ campaignEndMoment:", campaignEndMoment)
    const isAfterEnd = moment(currentDate).isAfter(campaignEndMoment);
    return isAfterEnd;
  }

  submitGA(action, label) {
    PromotionCalendarGA({ action, label });
  }
  render() {
    const isExpired = this.isCampaignExpired();


    return (
      <div className="text-right">
        <button
          type="button"
          title="Add Offers"
          className="btn pipeline-button-class mr-1 pipeline-btn-styling"
          /* As per discussion with Sahana, Offers can be created by anybody
          So, I Biplab commening the disable condition
          */
          disabled={isExpired}
          // disabled={
          //   (moment(this.props.campaignStartDate).isSameOrBefore(moment()) && !(moment(this.props.campaignEndDate).isSameOrAfter(moment()))) ||
          //     localStorage.getItem("role") === "3" ||
          //     localStorage.getItem("role") === "5" ||
          //     this.props.disableDeeperOfferBtn
          //     ? true
          //     : false
          // }
          onClick={e => {
            e.preventDefault();
            let campId = !_.isEmpty(this.props.pipelineDataTY)
              ? this.props.pipelineDataTY[0].campaign_id
              : this.props.selectedPromo;

            localStorage.setItem("campaignDetailsForCreate", campId);
            localStorage.setItem("campStartDate", this.props.campaignStartDate);
            localStorage.setItem("campEndDate", this.props.campaignEndDate);
            this.props.showAdgroupChoiceModalMethod(true);
            this.submitGA("Click", "Clicked_AddAdgroupButton");
          }}
        >
          <i className="fa fa-plus" aria-hidden="true" />
        </button>
        <button
          type="button"
          title="Modify Offer"
          className="btn pipeline-button-class mr-1 pipeline-btn-styling"
          disabled={this.props.disableCopyBtn || isExpired}
          onClick={e => {
            e.preventDefault();
            let campId = !_.isEmpty(this.props.pipelineDataTY)
              ? this.props.pipelineDataTY[0].campaign_id
              : this.props.selectedPromo;
            localStorage.setItem("campaignDetailsForCreate", campId);
            localStorage.setItem("campStartDate", this.props.campaignStartDate);
            localStorage.setItem("campEndDate", this.props.campaignEndDate);
            this.props.showModifyChoiceModalMethod(true);
            this.submitGA("Click", "Clicked_EditOfferButton");
          }}
        >
          <i className="fa fa-sliders fa-rotate-90" aria-hidden="true" />
        </button>
        <button
          type="button"
          title="Copy Offer"
          className="btn pipeline-button-class mr-1 pipeline-btn-styling"
          disabled={this.props.disableCopyBtn || isExpired}
          onClick={e => {
            e.preventDefault();
            let campId = !_.isEmpty(this.props.pipelineDataTY)
              ? this.props.pipelineDataTY[0].campaign_id
              : this.props.selectedPromo;
            localStorage.setItem("campaignDetailsForCreate", campId);
            localStorage.setItem("campStartDate", this.props.campaignStartDate);
            localStorage.setItem("campEndDate", this.props.campaignEndDate);
            this.props.setShowCopyOfferModal(true);
            this.submitGA("Click", "Clicked_CopyOfferButton");
          }}
        >
          <i className="fa fa-copy" aria-hidden="true" />
        </button>
        <button
          type="button"
          title="Modify Offer through CSV"
          className="btn pipeline-button-class mr-1 pipeline-btn-styling"
          // disabled={this.props.disableCopyBtn}
          disabled = {isExpired}
          onClick={e => {
            e.preventDefault();
            let campId = !_.isEmpty(this.props.pipelineDataTY)
              ? this.props.pipelineDataTY[0].campaign_id
              : this.props.selectedPromo;
            localStorage.setItem("campaignDetailsForCreate", campId);
            localStorage.setItem("campStartDate", this.props.campaignStartDate);
            localStorage.setItem("campEndDate", this.props.campaignEndDate);
            localStorage.setItem("modifyOffers", 1);
            this.props.handleModifyOffersCSV();
            this.submitGA("Click", "Clicked_ModifyOfferThroughCsvButton");
          }}
        >
          <i class="fa fa-file-csv"></i>
        </button>
        <button
          type="button"
          title="Deactivate Promos"
          className="btn pipeline-button-class mr-1 pipeline-btn-styling"
          disabled={
            this.props.disableCopyBtn ||
              localStorage.getItem("role") === "5"
              ? true
              : false
          }
          onClick={e => {
            e.preventDefault();
            let { promoIdArray } = this.props;
            let filteredArray = _.filter(this.props.pipelineDataTY, item => {
              return promoIdArray.includes(item.promo_id);
            });
            this.props.deletePromoMethod(true, filteredArray);
            this.submitGA("Click", "Clicked_DeactivatePromosButton");
          }}
        >
          <i className="fa fa-trash" aria-hidden="true" />
        </button>
        <button
          type="button"
          title="Simulate"
          className="btn pipeline-button-class mr-1 pipeline-btn-styling"
          disabled={this.props.disableCopyBtn}
          onClick={e => {
            e.preventDefault();
            let campId = !_.isEmpty(this.props.pipelineDataTY)
              ? this.props.pipelineDataTY[0].campaign_id
              : this.props.selectedPromo;
            localStorage.setItem("campaignDetailsForCreate", campId);
            localStorage.setItem("campStartDate", this.props.campaignStartDate);
            localStorage.setItem("campEndDate", this.props.campaignEndDate);
            this.props.onCopyPromotions();
            this.submitGA("Click", "Clicked_SimulateButton");
          }}
        >
          <i className="fa fa-play-circle" aria-hidden="true" />
        </button>
        <button
          type="button"
          title="Summary"
          className="btn pipeline-button-class mr-1 pipeline-btn-styling"
          // disabled={!_.isEmpty(this.props.pipelineDataTY)}

          onClick={e => {
            e.preventDefault();
            let campId = this.props.pipelineDataTY[0].campaign_id;
            localStorage.setItem("campaignDetailsForSummary", campId);
            localStorage.setItem(
              "campStartDateForSummary",
              this.props.campaignStartDate
            );
            localStorage.setItem(
              "campEndDateForSummary",
              this.props.campaignEndDate
            );
            localStorage.setItem("campaignDetailsForCreate", campId);
            localStorage.setItem("campStartDate", this.props.campaignStartDate);
            localStorage.setItem("campEndDate", this.props.campaignEndDate);
            this.props.history.push(`/executivesummary`);
            this.submitGA("Click", "Clicked_SummaryButton");
          }}
          disabled={this.props.disableDeeperOfferBtn || isExpired}
        >
          <i className="fa fa-newspaper-o" aria-hidden="true" />
        </button>
        {/* <button
          type="button"
          className="btn pipeline-button-class mr-1 pipeline-btn-styling"
          disabled={
            this.props.disableCopyBtn ||
              localStorage.getItem("role") === "3" ||
              localStorage.getItem("role") === "5"
              ? true
              : false
          }
          title="Edit Adgroup"
          onClick={e => {
            e.preventDefault();
            let { promoIdArray } = this.props;
            let filteredArray = _.filter(this.props.pipelineDataTY, item => {
              return promoIdArray.includes(item.promo_id);
            });
            let campId = this.props.pipelineDataTY[0].campaign_id;
            localStorage.setItem("campaignDetailsForCreate", campId);
            localStorage.setItem(
              "campaignDetailsForEdit",
              JSON.stringify(filteredArray)
            );
            localStorage.setItem("campStartDate", this.props.campaignStartDate);
            localStorage.setItem("campEndDate", this.props.campaignEndDate);
            this.props.history.push(`/editadgroup`);
            this.submitGA("Click", "Clicked_EditAdgroupButton");
          }}
        >
          <i className="fa fa-pencil" aria-hidden="true" />
        </button> */}
        <button
          type="button"
          title="Download"
          className="btn pipeline-button-class mr-1 pipeline-btn-styling"
          disabled={
            this.props.toggleLYSelected
              ? !_.isEmpty(this.props.pipelineDataLY)
                ? (false || this.props.disableDeeperOfferBtn)
                : true
              : !_.isEmpty(this.props.pipelineDataTY)
                ? (false || this.props.disableDeeperOfferBtn)
                : true
          }
          onClick={e => {
            this.props.onDownloadPipelineData();
            this.submitGA("Click", "Clicked_DownloadButton");
          }}
        >
          <i className="fa fa-download" aria-hidden="true" />
        </button>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  disableDeeperOfferBtn: state.userDataReducer.disableDeeperOfferBtn,
});
export default connect(mapStateToProps)(PipelineActionButtons);
