import React, { Component } from "react";
import { Alert } from "reactstrap";
import "./alertStyle.css";
export class AlertDanger extends Component {
    constructor(props) { //
        super(props);
        this.state = {
            alertFlag: false,
            
        }
    }
    render() {
        return (
            <div>  
                <Alert
                className="alertStyling alertstyleDanger"
                show={this.props.show}
                color="white" >
                <img id="imgDanger" src="../img/error.gif" aria-hidden="true" />
                <p className="dangerText">{this.props.message}</p>
            </Alert></div>
        )
    }
}

export default AlertDanger;