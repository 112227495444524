export const GET_ERRORS = "GET_ERRORS";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const DATA_LOADING = "DATA_LOADING";
export const GET_CHART_DATA = "GET_CHART_DATA";
export const GET_CHART_DATA_FILTERS = "GET_CHART_DATA_FILTERS";
export const CLEAR_CHART_DATA = "CLEAR_CHART_DATA";
export const ADGROUP_DATA = "ADGROUP_DATA";
export const SIMULATION_DATA = "SIMULATION_DATA";
export const LOADER = "LOADER";
export const GET_ADGROUP_DATA = "GET_ADGROUP_DATA";
export const CREATEPROMO_DATA = "CREATEPROMO_DATA";
export const GET_SHOP_DATA = "GET_SHOP_DATA";
export const GET_MERCHCAT = "GET_MERCHCAT";
export const GET_DISTINCT_ADGROUP = "GET_DISTINCT_ADGROUP";
export const UPDATE_PRODUCTS_ADGROUP = "UPDATE_PRODUCTS_ADGROUP";
export const CAMPAIGNS_DATA = "CAMPAIGNS_DATA";
export const PIPELINETABLE_DATE = "PIPELINETABLE_DATE";
export const CALENDAR_STRUCURE = "CALENDAR_STRUCURE";
export const CALENDAR_DATA = "CALENDAR_DATA";
export const PIPELINETABLE_DATE_SKU = "PIPELINETABLE_DATE_SKU";
export const VALIDATION_DATA = "VALIDATION_DATA";
export const CLEAR_SIMULATION_DATA = "CLEAR_SIMULATION_DATA";
export const VALIDATION_SKU_DATA = "VALIDATION_SKU_DATA";
export const SAVE_PROMO_POST_SIMULATION_DATA =
  "SAVE_PROMO_POST_SIMULATION_DATA";
export const CLEAR_SAVE_SIMULATION_DATA = "CLEAR_SAVE_SIMULATION_DATA";
export const PROMO_FILTERS_SHOP = "PROMO_FILTERS_SHOP";
export const PROMO_FILTERS_MERCH = "PROMO_FILTERS_MERCH";
export const PROMO_FILTERS_ADGROUP = "PROMO_FILTERS_ADGROUP";
export const USER_LOGIN = "USER_LOGIN";
export const USER_LOGGED_OUT = "USER_LOGGED_OUT";
export const SHOP_DATA = "SHOP_DATA";
export const COMPARE_DATA = "COMPARE_DATA";
export const HISTORICALPROMOS = "HISTORICALPROMOS";
export const HISTORICPERFORMANCECURRENT = "HISTORICPERFORMANCECURRENT";
export const HISTORICPERFORMANCELAST = "HISTORICPERFORMANCELAST";
export const HISTORICPERFORMANCEFILTERSHOP = "HISTORICPERFORMANCEFILTERSHOP";
export const HISTORICPERFORMANCEFILTERADGRP = "HISTORICPERFORMANCEFILTERADGRP";
export const HISTORICPERFORMANCEFILTEROFFERCURRENT =
  "HISTORICPERFORMANCEFILTEROFFERCURRENT";
export const HISTORICPERFORMANCEFILTEROFFERLAST =
  "HISTORICPERFORMANCEFILTEROFFERLAST";
export const SETADGROUP = "SETADGROUP";
export const OFFER_DATA = "OFFER_DATA";
export const CAMPAIGNS_DETAILS = "CAMPAIGNS_DETAILS";
export const CLEAR_DATA = "CLEAR_DATA";
export const PROMOLOADER = "PROMOLOADER";
export const DELETE_ADGROUP_DATA = "DELETE_ADGROUP_DATA";
export const PROMO_FILTERS_CUSTGROUP = "PROMO_FILTERS_CUSTGROUP";
export const PROMO_FILTERS_BUSINESS = "PROMO_FILTERS_BUSINESS";
export const PROMO_FILTERS_CATEGORY = "PROMO_FILTERS_CATEGORY";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const GET_EDA_CHART_DATA = "GET_EDA_CHART_DATA";
export const CLEAR_EDA_CHART_DATA = "CLEAR_EDA_CHART_DATA";
export const GET_EDA_CHART_DATA_FILTERS = "GET_EDA_CHART_DATA_FILTERS";
export const GET_EDA_DEPART_DATA_FILTERS = "GET_EDA_DEPART_DATA_FILTERS";
export const GET_EDA_ERRORS = "GET_EDA_ERRORS";
export const SHOW_VALIDATION = "SHOW_VALIDATION";
export const CALENDAR_EMPTY_DATA = "CALENDAR_EMPTY_DATA";
export const GET_OFFER_DATA = "GET_OFFER_DATA";
export const PRODUCT_DETAILS_ID = "PRODUCT_DETAILS_ID";
export const USERCOMMENTS = "USERCOMMENTS";
export const SAVE_COMMENT = "SAVE_COMMENT";
export const GET_CAMPAIGN_DM = "GET_CAMPAIGN_DM";
export const COMPARE_FILTERS = "COMPARE_FILTERS";
export const COMPARE_FILTERS_OFFER = "COMPARE_FILTERS_OFFER";
export const COMPARE_GRAPH = "COMPARE_GRAPH";
export const COMPARE_PROMO_TYPE = "COMPARE_PROMO_TYPE";
export const CUMULATIVE_FILTERS = "CUMULATIVE_FILTERS";
export const COPY_CAMPAIGN_DETAILS = "COPY_CAMPAIGN_DETAILS";
export const GET_USERS_LIST = "GET_USERS_LIST";
export const CREATE_USER_DATA = "CREATE_USER_DATA";
export const USER_STATUS_UPDATE_DATA = "USER_STATUS_UPDATE_DATA";
export const SAVEPIPELINECELLS = "SAVEPIPELINECELLS";
export const PROMO_TYPE_NAMES = "PROMO_TYPE_NAMES";
export const COMPARE_SHOP = "COMPARE_SHOP";
export const EDIT_REFERENCE_SKU = "EDIT_REFERENCE_SKU";
export const SIMILAR_SKU_PRODUCTLIST = "SIMILAR_SKU_PRODUCTLIST";
export const SAVE_REFERENCE_SKU = "SAVE_REFERENCE_SKU";
export const BULK_UPLOAD_SKU = "BULK_UPLOAD_SKU";
export const BATCH_PROCESS_RUNNING = "BATCH_PROCESS_RUNNING";
export const DISABLE_BATCH_BTN = "DISABLE_BATCH_BTN";
export const SET_DAYLEVELOFFER_DATA = "SET_DAYLEVELOFFER_DATA";
export const RESET_DAYLEVELOFFER_DATA = "RESET_DAYLEVELOFFER_DATA";
export const DAYLEVELOFFER_SHOP = "DAYLEVELOFFER_SHOP";
export const DAYLEVELOFFER_ADGROUP = "DAYLEVELOFFER_ADGROUP";
export const TYPED_DAYLEVELOFFER_ADGROUP = "TYPED_DAYLEVELOFFER_ADGROUP";
export const DAYLEVELOFFER_BUYER = "DAYLEVELOFFER_BUYER";
export const DAYLEVELOFFER_ADGROUP_RANGE_WISE = "DAYLEVELOFFER_ADGROUP_RANGE_WISE";
export const TYPED_DAYLEVELOFFER_ADGROUP_RANGE_WISE = "TYPED_DAYLEVELOFFER_ADGROUP_RANGE_WISE";
export const RESET_DAYLEVELOFFER_ADGROUP_RANGE_WISE = RESET_DAYLEVELOFFER_ADGROUP_RANGE_WISE
export const DAYLEVELOFFER_FISCDATA = "DAYLEVELOFFER_FISCDATA";
export const SET_MAPPED_LYTY = "SET_MAPPED_LYTY";
export const SET_SAVED_VIEWS_NAMES = "SET_SAVED_VIEWS_NAMES";
export const SET_BUYER_LIST = "SET_BUYER_LIST";
export const SET_SHOP_LIST = "SET_SHOP_LIST";
export const SET_ADGROUP_LIST = "SET_ADGROUP_LIST";
export const SET_ADDITIONAL_METRICS = "SET_ADDITIONAL_METRICS";
export const SET_DOWNLOAD_URL = "SET_DOWNLOAD_URL";
export const SET_LYTYROW_DATA = "SET_LYTYROW_DATA";
export const SET_DLOFFER_TABLE_DEF = "SET_DLOFFER_TABLE_DEF";
export const SET_DLO_TABLE_DATA = "SET_DLO_TABLE_DATA";
export const SET_MAPLYTY_DLOFFER_TABLE_DEF = "SET_MAPLYTY_DLOFFER_TABLE_DEF";
export const SAVED_VIEW_STATUS = "SAVED_VIEW_STATUS";
export const SHOW_ADGROUP_ALERT = "SHOW_ADGROUP_ALERT";
export const DISABLE_DEEPEROFFER_BTN = "DISABLE_DEEPEROFFER_BTN";
export const DEEPEROFFER_RUN_MSG = "DEEPEROFFER_RUN_MSG";
export const UPLOAD_ERROR_MSG = "UPLOAD_ERROR_MSG";
//event Master
export const GET_EVENTS_DATA = "GET_EVENTS_DATA";
export const SET_EVENT_MASTER_DATA = "SET_EVENT_MASTER_DATA";
export const SET_EVENT_DETAILS = "SET_EVENT_DETAILS";
export const GET_CAMPAIGNS_DATA = "GET_CAMPAIGNS_DATA";
export const SET_LYEVENT_DATA = "SET_LYEVENT_DATA";
export const SET_VEHICLE_DATA = "SET_VEHICLE_DATA";
export const SET_PROMO_GROUP_DATA = "SET_PROMO_GROUP_DATA";
export const SET_ALL_HIERARCHIES_DATA = "SET_ALL_HIERARCHIES_DATA"
export const SET_ALL_ATTRIBUTES_DATA = "SET_ALL_ATTRIBUTES_DATA"
// Promo approval
export const SET_PROMO_APPROVAL_DATA = "SET_PROMO_APPROVAL_DATA";
export const UPDATE_PROMO_APPROVAL_DATA = "UPDATE_PROMO_APPROVAL_DATA";
//Reprots
export const SET_AGPAR_REPORTS_DATA = "SET_AGPAR_REPORTS_DATA";
export const SET_AGDAR_REPORTS_DATA = "SET_AGDAR_REPORTS_DATA";
export const SET_BUYER_OPTIONS = "SET_BUYER_OPTIONS";
export const SET_ADGROUP_OPTIONS = "SET_ADGROUP_OPTIONS";
export const SET_THEME_OPTIONS = "SET_THEME_OPTIONS";
export const SET_EVENT_OPTIONS = "SET_EVENT_OPTIONS";
export const SET_ARTICLE_OPTIONS = "SET_ARTICLE_OPTIONS";
export const SET_GMM_OPTIONS = "SET_GMM_OPTIONS";
export const SET_PRICE_LIST_OPTIONS = "SET_PRICE_LIST_OPTIONS";

