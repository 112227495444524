import React, { Component } from "react";
import Select from "react-select";
import _ from "lodash";
import { isIt } from "../../../../utils/getRoleName";

const PromoSimulateTopScreen = props => {
  return (
    <div className="row">
      <div className="col-2 pl-4">
        <p className="labelAdGroup mb-1">AdGroup</p>
        <Select
          placeholder="Choose AdGroup"
          options={props.adgroupListWithLabels}
          onChange={value => props.adgroupChangeHandler(value)}
          value={props.selectedAdgroup}
          isSearchable={true}
        />
      </div>
      <div className="col-3 px-0">
        <p className="labelAdGroup mb-1">Event</p>
        <Select
          placeholder="Choose PromoType"
          options={
            // props.fromPipelineIntermediate
            //   ? props.tyEventsData :
            !_.isEmpty(props.adgroupWithEventType)
              ? props.adgroupWithEventType[props.selectedAdgroup.value]
              : []
          }
          onChange={value => props.eventTypeChangeHandler(value)}
          value={props.activeEventType}
        />
      </div>
      <div className="col-2 text-center">
        <p />
        <button
          type="button"
          className="btn btn-outline-primary promo3Buttons btGroup1"
          style={{ marginTop: "4%" }}
          onClick={e => {
            props.showSkuList(true);
          }}
        >
          View SKU
        </button>
      </div>
      <div className="col-2 text-center">
        <p />
        <button
          type="button"
          className="btn btn-outline-primary promo3Buttons btGroup1"
          style={{ marginTop: "4%" }}
          onClick={() => {
            props.showPromoSimulate();
          }}
          disabled={isIt()}
        >
          Simulate
        </button>
      </div>
      <div className="col-3 offerMainDiv">
        <div className="row px-4">
          <div className="col-6">
            <p className=" labelOfferType mb-1">Reg. price</p>
            <p className="offerTextSpan">
              {props.regPrice === "" ? "-" : props.regPrice}
            </p>
          </div>
          <div className="col-6">
            <p className=" labelOfferType mb-1">TY Offer</p>
            <p className="offerTextSpan">{props.TYOfferValue ? props.TYOfferValue : "-"}</p>
          </div>
          {/* <div className="col-4 lastYearOfferDiv">
            <p className="finalSimulateOfferStyling labelOfferType mb-1">
              LY Offer
            </p>
            <p className="offerTextSpan">{props.lastYearOffer}</p>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default PromoSimulateTopScreen;
