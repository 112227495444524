import React, { Component } from "react";
import "./summaryStyle.css";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Multiselect from "../../../../utils/Multiselect";
import { withRouter } from "react-router-dom";
import PageLoader from "../../../../utils/Loader";
import { getAdgroupData } from "../../../../redux/actions/adgroup";
import _ from "underscore";
import AlertSuccess from "../../../../utils/AlertMessage/alertSuccess";
import AlertDanger from "../../../../utils/AlertMessage/alertDanger";
import {
  getSummaryData,
  getCumulativeFilters
} from "../../../../redux/actions/summaryAction";
import { savePipelineCellsMethod } from "../../../../redux/actions/calendarAction";
import DataTable from "../../../tables";
import ColumnDef from "../../../tables/colDefs/SummaryColDefs";
import BackToCalendar from "../../../../utils/BackToCalendar";
import DateRangePicker from "../../../../utils/DateRangePicker/DateRangePicker.js";
import { SummaryGA } from '../../../../utils/GA/GAUtils';
import { alertTimer } from "../../../../utils/alertTimer.js";

var moment = require("moment");
let selectedAdGroup = [];
let custGrpAll = false;
let businessAll = false;
let shopAll = false;
let adgroupAll = false;

export class PromoSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      key: "summary",
      startDate: moment()
        .startOf("month")
        .subtract(7, "months"),
      endDate: moment(),
      inputCategoryDescDisabled: true,
      inputCustGroupDisabled: true,
      selectedShopValue: [],
      showFootnote: false,
      selectedAdgroupValue: [],
      selectedDmStartDate: "",
      selectedDmEndDate: "",
      collapsibleTabsDisabled: true,
      selectedCustomerGropuValue: [],
      selectedCustomerGrp: [],
      selectedBusinessDescValue: [],
      selectedGrpVal: [],
      selectedShopVal: [],
      selectedAdgroupVal: [],
      ty_start_date: "",
      ly_start_date: "",
      ty_end_date: "",
      ly_end_date: "",
      summaryExport1: false,
      summaryExport2: false,
      summaryExport3: false,
      summaryExport4: false,
      summaryExport5: false,
      summaryExport6: false,
      summaryExport7: false,
      summaryExport8: false,
      summaryExport9: false,
      summaryExport10: false,
      summaryExport11: false,
      summaryExport12: false,
      summaryExport13: false,
      summaryDataFromStateAdgroup: [],
      summaryDataFromStatePromo: [],
      summaryDataFromStateTotal: [],
      dmOptions: [],
      disabledSimulateBtn: true,
      selectedCampaignId: null,
      adgroupDataArray: null,
      promoIdArray: [],
      adGroupCheckBoxDisabled: "",
      TYStartDate: "",
      TYEndDate: "",
      LYStartDate: "",
      LYEndDate: "",
      dateRangeExceededAlert: false
    };
  }

  componentDidMount() {
    let campaignDetailsForSummary = localStorage.getItem(
      "campaignDetailsForSummary"
    );
    if (!_.isEmpty(campaignDetailsForSummary)) {
      this.collapseTabsOnChange();
      let SD = localStorage.getItem("campStartDateForSummary"),
        ED = localStorage.getItem("campEndDateForSummary");
      let startDateVar = moment(SD).format("YYYY-MM-DD"),
        endDateVar = moment(ED).format("YYYY-MM-DD");
      let selectedCampaignFromPipeline =
        moment(localStorage.getItem("campStartDateForSummary")).format("L") +
        "-" +
        moment(localStorage.getItem("campEndDateForSummary")).format("L");

      this.props.getAdgroupData("");
      this.setState({
        selectedDmStartDate: startDateVar,
        selectedDmEndDate: endDateVar,
        TYStartDate: moment(SD),
        TYEndDate: moment(ED),
        LYStartDate: moment(SD).subtract(364, "days"),
        LYEndDate: moment(ED).subtract(364, "days"),
        collapsibleTabsDisabled: false,
        inputCustGroupDisabled: false,
        showFootnote: false,
        summaryDataFromStateForCust: [],
        summaryDataFromStateForCustEvent: [],
        summaryDataFromStateForBus: [],
        summaryDataFromStateForBusEvent: [],
        summaryDataFromStateForCategory: [],
        summaryDataFromStateForCategoryEvent: [],
        summaryDataFromStateForShop: [],
        summaryDataFromStateForShopEvent: [],
        summaryDataFromStateMerch: [],
        summaryDataFromStateMerchEvent: [],
        summaryDataFromStateAdgroup: [],
        summaryDataFromStatePromo: [],
        summaryDataFromStateTotal: []
      });
    }
    this.props.getCumulativeFilters({
      required_column: [
        {
          required_column: "h6"
        },
        {
          required_column: "h5"
        },
        {
          required_column: "h3"
        }
      ]
    });
    this.props.getAdgroupData("");
  }

  TYDateChangeHandler = ({ startDate, endDate }) => {
    this.setState({
      TYStartDate: startDate,
      TYEndDate: endDate
    });

    if (_.isEmpty(startDate) || _.isEmpty(endDate)) return;
    else {
      this.collapseTabsOnChange();

      if (
        moment(JSON.stringify(endDate).slice(1, 11), "YYYY-MM-DD").diff(
          moment(JSON.stringify(startDate).slice(1, 11), "YYYY-MM-DD"),
          "days"
        ) > 90
      ) {
        this.setState({
          dateRangeExceededAlert: true,
          collapsibleTabsDisabled: true,
          inputCustGroupDisabled: true
        });

        setTimeout(() => {
          this.setState({
            dateRangeExceededAlert: false
          });
        }, alertTimer);
      } else {
        let adGroupCheckBoxDisabled =
          new Date(startDate) <= new Date() ? "pipelineCheckboxDisabled" : "";
        this.setState({
          LYStartDate: moment(startDate).subtract(364, "days"),
          LYEndDate: moment(endDate).subtract(364, "days"),
          collapsibleTabsDisabled: false,
          inputCustGroupDisabled: false,
          showFootnote: false,
          summaryDataFromStateForCust: [],
          summaryDataFromStateForCustEvent: [],
          summaryDataFromStateForBus: [],
          summaryDataFromStateForBusEvent: [],
          summaryDataFromStateForCategory: [],
          summaryDataFromStateForCategoryEvent: [],
          summaryDataFromStateForShop: [],
          summaryDataFromStateForShopEvent: [],
          summaryDataFromStateMerch: [],
          summaryDataFromStateMerchEvent: [],
          summaryDataFromStateAdgroup: [],
          summaryDataFromStatePromo: [],
          summaryDataFromStateTotal: [],
          adGroupCheckBoxDisabled
        });
      }
    }
  };

  collapseTabsOnChange = () => {
    var x = document.getElementsByClassName("collapse");
    for (let item of x) {
      item.classList.remove("show");
    }

    var y = document.getElementsByClassName("card-header");
    for (let item of y) {
      item.classList.add("collapsed");
    }
  };

  summaryExporthandler = () => {
    this.setState({
      summaryExport1: false,
      summaryExport2: false,
      summaryExport3: false,
      summaryExport4: false,
      summaryExport5: false,
      summaryExport6: false,
      summaryExport7: false,
      summaryExport8: false,
      summaryExport9: false,
      summaryExport10: false,
      summaryExport11: false,
      summaryExport12: false,
      summaryExport13: false
    });
  };

  showSummaryData = async accordianValue => {
    this.setState({
      showFootnote: true
    });

    let res = await this.props.getSummaryData({
      required_category:
        accordianValue === "customer" || accordianValue === "customerEvent"
          ? "h6"
          : accordianValue === "business" || accordianValue === "businessEvent"
            ? "h5"
            : accordianValue === "shop" || accordianValue === "shopEvent"
              ? "h3"
              : accordianValue === "category" || accordianValue === "categoryEvent"
                ? "h2"
                : accordianValue === "merchantCategory" ||
                  accordianValue === "merchantCategoryEvent"
                  ? "h1"
                  : accordianValue === "adgroup"
                    ? "adgroup"
                    : accordianValue === "eventLevel"
                      ? "promo_type"
                      : "total",
      required_category_events:
        accordianValue === "customerEvent" ||
          accordianValue === "businessEvent" ||
          accordianValue === "shopEvent" ||
          accordianValue === "categoryEvent" ||
          accordianValue === "merchantCategoryEvent"
          ? true
          : false,
      ty_start_date: this.state.TYStartDate.format("YYYY-MM-DD"),
      ty_end_date: this.state.TYEndDate.format("YYYY-MM-DD"),
      ly_start_date: this.state.LYStartDate.format("YYYY-MM-DD"),
      ly_end_date: this.state.LYEndDate.format("YYYY-MM-DD"),
      h6: this.state.selectedCustomerGropuValue,
      h5: this.state.selectedBusinessDescValue,
      h3: this.state.selectedShopValue,
      adgroup: this.state.selectedAdgroupValue
    });

    if (res.status) {
      this.setState({
        ty_start_date: res.data.ty_start_date,
        ly_start_date: res.data.ly_start_date,
        ty_end_date: res.data.ty_end_date,
        ly_end_date: res.data.ly_end_date
      });

      if (accordianValue === "customer") {
        this.setState({
          summaryDataFromStateForCust: res.data.summary_data
        });
      } else if (accordianValue === "customerEvent") {
        this.setState({
          summaryDataFromStateForCustEvent: res.data.summary_data
        });
      } else if (accordianValue === "business") {
        this.setState({
          summaryDataFromStateForBus: res.data.summary_data
        });
      } else if (accordianValue === "businessEvent") {
        this.setState({
          summaryDataFromStateForBusEvent: res.data.summary_data
        });
      } else if (accordianValue === "category") {
        this.setState({
          summaryDataFromStateForCategory: res.data.summary_data
        });
      } else if (accordianValue === "categoryEvent") {
        this.setState({
          summaryDataFromStateForCategoryEvent: res.data.summary_data
        });
      } else if (accordianValue === "shop") {
        this.setState({
          summaryDataFromStateForShop: res.data.summary_data
        });
      } else if (accordianValue === "shopEvent") {
        this.setState({
          summaryDataFromStateForShopEvent: res.data.summary_data
        });
      } else if (accordianValue === "merchantCategory") {
        this.setState({
          summaryDataFromStateMerch: res.data.summary_data
        });
      } else if (accordianValue === "merchantCategoryEvent") {
        this.setState({
          summaryDataFromStateMerchEvent: res.data.summary_data
        });
      } else if (accordianValue === "adgroup") {
        this.setState({
          summaryDataFromStateAdgroup: res.data.summary_data
        });
      } else if (accordianValue === "eventLevel") {
        this.setState({
          summaryDataFromStatePromo: res.data.summary_data
        });
      } else if (accordianValue === "totalLevel") {
        this.setState({
          summaryDataFromStateTotal: res.data.summary_data
        });
      }
    }
  };

  custGroupSelected = (value, custArray) => {
    this.collapseTabsOnChange();
    var tempCustGroupArray = [];

    value.map(custValue => {
      tempCustGroupArray.push(custValue.value);
      return custValue;
    });

    custGrpAll = custGrpAll ? false : true;
    let allCheck = tempCustGroupArray.includes("All");
    this.setState({
      selectedCustomerGropuValue: allCheck ? [] : tempCustGroupArray,
      selectedCustomerGrp: allCheck ? custArray : value
    });

    // if (tempCustGroupArray.includes("All")) {
    //   custGrpAll = custGrpAll ? false : true;
    //   this.setState({
    //     selectedCustomerGropuValue: [],
    //     selectedCustomerGrp: custArray
    //   });
    // } else {
    //   this.setState({
    //     selectedCustomerGropuValue: tempCustGroupArray,
    //     selectedCustomerGrp: value
    //   });
    // }
  };

  businessDescSelected = (value, busArray) => {
    this.collapseTabsOnChange();

    var tempBusinessDescArray = [];
    value.map(businessValue => {
      tempBusinessDescArray.push(businessValue.value);
      return businessValue;
    });

    businessAll = businessAll ? false : true;
    let allCheck = tempBusinessDescArray.includes("All");
    this.setState({
      selectedBusinessDescValue: allCheck ? [] : tempBusinessDescArray,
      selectedGrpVal: allCheck ? busArray : value
    });
    // if (tempBusinessDescArray.includes("All")) {
    //   businessAll = businessAll ? false : true;
    //   this.setState({
    //     selectedBusinessDescValue: [],
    //     selectedGrpVal: busArray
    //   });
    // } else {
    //   this.setState({
    //     selectedBusinessDescValue: tempBusinessDescArray,
    //     selectedGrpVal: value
    //   });
    // }
  };

  shopSelected = (value, shopArray) => {
    this.collapseTabsOnChange();

    var tempSelectedShopArray = [];
    value.map(shopValue => {
      tempSelectedShopArray.push(shopValue.value);
      return shopValue;
    });
    shopAll = shopAll ? false : true;
    let allCheck = tempSelectedShopArray.includes("All");
    this.setState({
      selectedShopValue: allCheck ? [] : tempSelectedShopArray,
      selectedShopVal: allCheck ? shopArray : value
    });
    // if (tempSelectedShopArray.includes("All")) {
    //   shopAll = shopAll ? false : true;
    //   this.setState({
    //     selectedShopValue: [],
    //     selectedShopVal: shopArray
    //   });
    // } else {
    //   this.setState({
    //     selectedShopValue: tempSelectedShopArray,
    //     selectedShopVal: value
    //   });
    // }
  };

  onAdGroupSearch = e => {
    var adgroupSearchText = "";

    if (/^[a-z0-9]+$/i.test(e.key) && e.key.length == 1) {
      adgroupSearchText = e.target.value + e.key;
    } else if (e.key === "Backspace") {
      adgroupSearchText = e.target.value.slice(0, -1);
    }
    this.props.getAdgroupData(adgroupSearchText.toUpperCase());
  };

  adGroupSelected = (value, adgroupArray) => {
    this.collapseTabsOnChange();

    selectedAdGroup = value.map(adItem => {
      return adItem.value;
    });
    adgroupAll = adgroupAll ? false : true;
    let allCheck = selectedAdGroup.includes("All");
    this.setState({
      selectedAdgroupValue: allCheck ? [] : selectedAdGroup,
      selectedAdgroupVal: allCheck ? adgroupArray : value
    });
  };
  clearData = value => {
    if (value === "customerGroup") {
      this.setState({
        selectedCustomerGrp: [],
        selectedCustomerGropuValue: []
      });
    } else if (value === "business") {
      this.setState({
        selectedGrpVal: [],
        selectedBusinessDescValue: []
      });
    } else if (value === "shop") {
      this.setState({
        selectedShopVal: [],
        selectedShopValue: []
      });
    } else if (value === "adGroup") {
      this.setState({
        selectedAdgroupVal: [],
        selectedAdgroupValue: []
      });
    }

    this.collapseTabsOnChange();
  };

  isOutsideRangeHandler = day => {
    let formattedDate = moment(day).format(),
      endDate = moment().format(),
      isSameOrBefore = moment(formattedDate).isSameOrBefore(endDate);

    return !isSameOrBefore;
  };

  isOutsideRangeHandlerLy = day => {
    let formattedDate = moment(day).format(),
      endDate = moment().format(),
      isSameOrBefore = moment(formattedDate).isSameOrBefore(endDate);

    return !isSameOrBefore;
  };

  TYLYDateRendrer = () => {
    return (
      <>
        <div className="col-md-3 px-0">
          <span className="promoSummaryText ml-0">TY Start Date:&nbsp;</span>
          {/* {moment(this.state.ty_start_date).format("MM/DD/YYYY")} */}
          {this.state.ty_start_date ? moment(this.state.ty_start_date).format("MM/DD/YYYY") : "-"}
        </div>
        <div className="col-md-3 px-0">
          <span className="promoSummaryText">TY End Date:&nbsp;</span>
          {/* {moment(this.state.ty_end_date).format("MM/DD/YYYY")} */}
          {this.state.ty_end_date ? moment(this.state.ty_end_date).format("MM/DD/YYYY") : "-"}
        </div>
        <div className="col-md-3 px-0">
          <span className="promoSummaryText">LY Start Date:&nbsp;</span>
          {/* {moment(this.state.ly_start_date).format("MM/DD/YYYY")} */}
          {this.state.ly_start_date ? moment(this.state.ly_start_date).format("MM/DD/YYYY") : "-"}
        </div>
        <div className="col-md-3 px-0">
          <span className="promoSummaryText">LY End Date:&nbsp;</span>
          {/* {moment(this.state.ly_end_date).format("MM/DD/YYYY")} */}
          {this.state.ly_end_date ? moment(this.state.ly_end_date).format("MM/DD/YYYY") : "-"}
        </div>
      </>
    );
  };

  promoSummaryTableComponent = (
    disableaccordian,
    href,
    accordianTitle,
    summaryExport,
    tableData
  ) => {
    return (
      <>
        <div
          className={disableaccordian}
          data-toggle="collapse"
          href={"#" + href}
          onClick={() => {
            if (summaryExport === "summaryExport1") {
              this.showSummaryData("customer");
            } else if (summaryExport === "summaryExport2") {
              this.showSummaryData("customerEvent");
            } else if (summaryExport === "summaryExport3") {
              this.showSummaryData("business");
            } else if (summaryExport === "summaryExport4") {
              this.showSummaryData("businessEvent");
            } else if (summaryExport === "summaryExport5") {
              this.showSummaryData("category");
            } else if (summaryExport === "summaryExport6") {
              this.showSummaryData("categoryEvent");
            } else if (summaryExport === "summaryExport7") {
              this.showSummaryData("shop");
            } else if (summaryExport === "summaryExport8") {
              this.showSummaryData("shopEvent");
            } else if (summaryExport === "summaryExport9") {
              this.showSummaryData("merchantCategory");
            } else if (summaryExport === "summaryExport10") {
              this.showSummaryData("merchantCategoryEvent");
            } else if (summaryExport === "summaryExport11") {
              this.showSummaryData("adgroup");
            } else if (summaryExport === "summaryExport12") {
              this.showSummaryData("eventLevel");
            } else if (summaryExport === "summaryExport13") {
              this.showSummaryData("totalLevel");
            }
            this.submitGA("Click", `Clicked_${accordianTitle}_Accordian`);
          }}
        >
          <a className="card-title">{accordianTitle}</a>
        </div>
        <div id={href} className="card-body collapse">
          <div className="row mx-1 mt-2 text-left">
            <div className="row col-md-9">{this.TYLYDateRendrer()}</div>
            <div className="col-md-3 pr-0 text-right">
              <button
                id="btnCustomerDownload"
                className="btn btnSaveProducts"
                onClick={() => {
                  if (summaryExport === "summaryExport1") {
                    this.setState({ summaryExport1: true });
                  } else if (summaryExport === "summaryExport2") {
                    this.setState({ summaryExport2: true });
                  } else if (summaryExport === "summaryExport3") {
                    this.setState({ summaryExport3: true });
                  } else if (summaryExport === "summaryExport4") {
                    this.setState({ summaryExport4: true });
                  } else if (summaryExport === "summaryExport5") {
                    this.setState({ summaryExport5: true });
                  } else if (summaryExport === "summaryExport6") {
                    this.setState({ summaryExport6: true });
                  } else if (summaryExport === "summaryExport7") {
                    this.setState({ summaryExport7: true });
                  } else if (summaryExport === "summaryExport8") {
                    this.setState({ summaryExport8: true });
                  } else if (summaryExport === "summaryExport9") {
                    this.setState({ summaryExport9: true });
                  } else if (summaryExport === "summaryExport10") {
                    this.setState({ summaryExport10: true });
                  } else if (summaryExport === "summaryExport11") {
                    this.setState({ summaryExport11: true });
                  } else if (summaryExport === "summaryExport12") {
                    this.setState({ summaryExport12: true });
                  } else if (summaryExport === "summaryExport13") {
                    this.setState({ summaryExport13: true });
                  }
                  this.submitGA("Click", `Clicked_${accordianTitle}_DownloadButton`);
                }}
              >
                Download
              </button>
            </div>
          </div>
          <div className="aggridDiv card">
            <div className="aggridDivInner">
              <DataTable
                parentId="myGrid"
                parentClass="ag-theme-balham"
                parentStyle={{
                  height:
                    summaryExport === "summaryExport13"
                      ? "calc(100vh - 500px)"
                      : "calc(100vh - 340px)",
                  textAlign: "center"
                }}
                tableData={tableData}
                overlayNoRowsTemplate={"No rows to show"}
                animateRows={true}
                expand={true}
                overlayLoadingTemplate={"No rows to show"}
                columnGroups={true}
                summaryExport1={
                  summaryExport === "summaryExport1"
                    ? this.state.summaryExport1
                    : summaryExport === "summaryExport2"
                      ? this.state.summaryExport2
                      : summaryExport === "summaryExport3"
                        ? this.state.summaryExport3
                        : summaryExport === "summaryExport4"
                          ? this.state.summaryExport4
                          : summaryExport === "summaryExport5"
                            ? this.state.summaryExport5
                            : summaryExport === "summaryExport6"
                              ? this.state.summaryExport6
                              : summaryExport === "summaryExport7"
                                ? this.state.summaryExport7
                                : summaryExport === "summaryExport8"
                                  ? this.state.summaryExport8
                                  : summaryExport === "summaryExport9"
                                    ? this.state.summaryExport9
                                    : summaryExport === "summaryExport10"
                                      ? this.state.summaryExport10
                                      : summaryExport === "summaryExport11"
                                        ? this.state.summaryExport11
                                        : summaryExport === "summaryExport12"
                                          ? this.state.summaryExport12
                                          : summaryExport === "summaryExport13"
                                            ? this.state.summaryExport13
                                            : false
                }
                summaryExporthandler={this.summaryExporthandler}
                exportFilename={accordianTitle}
                autoSizeCol={true}
              />
            </div>
          </div>
        </div>
      </>
    );
  };
  submitGA(action, label) {
    SummaryGA({ action, label });
  }
  render() {
    let disableaccordian = this.state.collapsibleTabsDisabled
      ? "enableaccordian card-header collapsed"
      : "card-header collapsed";

    var all = { value: "All", label: "All" };
    let shopArray = [];
    let custGroupArray = [];
    let businessDescArray = [];
    let adGroupArray = [];

    adGroupArray.unshift(all);
    if (
      this.props.summaryCumulativeFilters &&
      Object.keys(this.props.summaryCumulativeFilters).length > 0 &&
      this.props.summaryCumulativeFilters[0][0] != undefined
    ) {
      if (this.props.summaryCumulativeFilters[0][0].h6 != null) {
        this.props.summaryCumulativeFilters[0][0].h6.map(filterData => {
          custGroupArray.push({
            value: filterData,
            label: filterData
          });
          return filterData;
        });
        custGroupArray.unshift(all);
      }

      if (this.props.summaryCumulativeFilters[1][0].h5 != null) {
        this.props.summaryCumulativeFilters[1][0].h5.map(filterData => {
          businessDescArray.push({
            value: filterData,
            label: filterData
          });
          return filterData;
        });
        businessDescArray.unshift(all);
      }

      if (this.props.summaryCumulativeFilters[2][0].h3 != null) {
        this.props.summaryCumulativeFilters[2][0].h3.map(filterData => {
          shopArray.push({
            value: filterData,
            label: filterData
          });
          return filterData;
        });
        shopArray.unshift(all);
      }
    }

    if (
      this.props.adGroupData &&
      Object.keys(this.props.adGroupData).length > 0
    ) {
      this.props.adGroupData.map(adgroup => {
        adGroupArray.push({
          value: adgroup.name,
          label: adgroup.name
        });
        return adgroup;
      });
    }

    let tableData1 = {
      header: ColumnDef.columnDefsForCust,
      defaultColDef: ColumnDef.defaultColDef,
      data: this.state.summaryDataFromStateForCust
    };
    let tableData2 = {
      header: ColumnDef.columnDefsForCustEvent,
      defaultColDef: ColumnDef.defaultColDef,
      data: this.state.summaryDataFromStateForCustEvent
    };
    let tableData3 = {
      header: ColumnDef.columnDefsForBusiness,
      defaultColDef: ColumnDef.defaultColDef,
      data: this.state.summaryDataFromStateForBus
    };
    let tableData4 = {
      header: ColumnDef.columnDefsForBusinessEvent,
      defaultColDef: ColumnDef.defaultColDef,
      data: this.state.summaryDataFromStateForBusEvent
    };
    let tableData5 = {
      header: ColumnDef.columnDefsForCategory,
      defaultColDef: ColumnDef.defaultColDef,
      data: this.state.summaryDataFromStateForCategory
    };
    let tableData6 = {
      header: ColumnDef.columnDefsForCategoryEvent,
      defaultColDef: ColumnDef.defaultColDef,
      data: this.state.summaryDataFromStateForCategoryEvent
    };
    let tableData7 = {
      header: ColumnDef.columnDefsForShop,
      defaultColDef: ColumnDef.defaultColDef,
      data: this.state.summaryDataFromStateForShop
    };
    let tableData8 = {
      header: ColumnDef.columnDefsForShopEvent,
      defaultColDef: ColumnDef.defaultColDef,
      data: this.state.summaryDataFromStateForShopEvent
    };
    let tableData9 = {
      header: ColumnDef.columnDefsForMerch,
      defaultColDef: ColumnDef.defaultColDef,
      data: this.state.summaryDataFromStateMerch
    };
    let tableData10 = {
      header: ColumnDef.columnDefsForMerchEvent,
      defaultColDef: ColumnDef.defaultColDef,
      data: this.state.summaryDataFromStateMerchEvent
    };
    let tableData11 = {
      header: ColumnDef.columnDefsForAdgroup,
      defaultColDef: ColumnDef.defaultColDefForAdgroup,
      data: this.state.summaryDataFromStateAdgroup
    };
    let tableData12 = {
      header: ColumnDef.columnDefsForPromo,
      defaultColDef: ColumnDef.defaultColDef,
      data: this.state.summaryDataFromStatePromo
    };

    let tableData13 = {
      header: ColumnDef.columnDefsForTotal,
      defaultColDef: ColumnDef.defaultColDef,
      data: this.state.summaryDataFromStateTotal
    };

    return (
      <div>
        <div className="alertDivs">
          {this.state.dateRangeExceededAlert ? (
            <AlertDanger
              message="A maximum date range of 3 months can be selected!"
              show={this.state.dateRangeExceededAlert}
            />
          ) : null}

          {this.state.rowSavedAlert ? (
            <AlertSuccess
              message="Changes Saved Successfully."
              show={this.state.rowSavedAlert}
            />
          ) : null}
        </div>
        <div className="card cardStyles childCompStyling">
          <PageLoader
            loader={
              this.props.validationLoader.validationDatas.loading ||
              this.props.loader
            }
          >
            <div>
              <div className="createClusterDivForSummary">
                <div className="text-center">
                  <h4 className="promoHeading">Summary</h4>
                </div>
                <div className="row summary-top-filters mx-0 pl-3">
                  <div className="col-4 pr-0">
                    <p className="summaryPText">TY Time Period</p>
                    <DateRangePicker
                      startDate={this.state.TYStartDate}
                      endDate={this.state.TYEndDate}
                      onDatesChange={dateRange => {
                        this.TYDateChangeHandler(dateRange)
                        this.submitGA("Filter", "Selected_TYTimePeriod");
                      }}
                      showClearDates
                      // isOutsideRange={false}
                      renderMonthElement={true}
                    />
                  </div>
                  <div className="col-4 pl-0">
                    <p className="summaryPText">LY Time Period</p>
                    <DateRangePicker
                      disabled={
                        _.isEmpty(this.state.TYStartDate) ||
                          _.isEmpty(this.state.TYEndDate)
                          ? true
                          : false
                      }
                      startDate={this.state.LYStartDate}
                      endDate={this.state.LYEndDate}
                      onDatesChange={({ startDate, endDate }) => {
                        this.collapseTabsOnChange();
                        this.setState({
                          LYStartDate: startDate,
                          LYEndDate: endDate
                        });

                        if (
                          moment(
                            JSON.stringify(endDate).slice(1, 11),
                            "YYYY-MM-DD"
                          ).diff(
                            moment(
                              JSON.stringify(startDate).slice(1, 11),
                              "YYYY-MM-DD"
                            ),
                            "days"
                          ) > 90
                        ) {
                          this.setState({
                            dateRangeExceededAlert: true,
                            collapsibleTabsDisabled: true,
                            inputCustGroupDisabled: true
                          });
                          setTimeout(() => {
                            this.setState({
                              dateRangeExceededAlert: false
                            });
                          }, alertTimer);
                        } else {
                          this.setState({
                            collapsibleTabsDisabled: false,
                            inputCustGroupDisabled: false
                          });
                        }
                      }}
                      showClearDates
                      renderMonthElement={true}
                      isOutsideRange={day => this.isOutsideRangeHandlerLy(day)}
                    />
                  </div>
                  {/* <div className="col-md-3"></div> */}
                </div>
                <div className="row mt-3 summary-top-filters mx-0 pl-3">
                  {/* <div className="col-2" /> */}
                  <div className="col">
                    <p className="summaryPText">Customer Group</p>
                    <div
                      className={
                        this.state.inputCustGroupDisabled
                          ? "disable-true summary-filter"
                          : "summary-filter"
                      }
                    >
                      <Multiselect
                        placeHolder="Customer Group"
                        optionsArray={custGroupArray}
                        dropdownDisabled={this.state.inputCustGroupDisabled}
                        onDropDownChange={value => {
                          this.custGroupSelected(value, custGroupArray);
                          this.submitGA("Filter", "Selected_CustomerGroup");
                        }}
                        dropdownValue={this.state.selectedCustomerGrp}
                      />

                      {this.state.selectedCustomerGrp.length ? (
                        <i
                          className="fa fa-times close2"
                          onClick={() => {
                            this.clearData("customerGroup")
                            this.submitGA("Filter", "Selected_CustomerGroup");
                          }}
                        />
                      ) : null}
                    </div>
                  </div>
                  <div className="col">
                    <p className="summaryPText">Business</p>
                    <div
                      className={
                        this.state.inputCustGroupDisabled
                          ? "disable-true summary-filter"
                          : "summary-filter"
                      }
                    >
                      <Multiselect
                        placeHolder="Business"
                        optionsArray={businessDescArray}
                        dropdownDisabled={this.state.inputCustGroupDisabled}
                        onDropDownChange={value => {
                          this.businessDescSelected(value, businessDescArray)
                          this.submitGA("Filter", "Selected_Business");
                        }}
                        dropdownValue={this.state.selectedGrpVal}
                      />

                      {this.state.selectedGrpVal.length ? (
                        <i
                          className="fa fa-times close2"
                          onClick={() => {
                            this.clearData("business");
                            this.submitGA("Filter", "Selected_Business");
                          }}
                        />
                      ) : null}
                    </div>
                  </div>
                  <div className="col">
                    <p className="summaryPText">Shop</p>
                    <div
                      className={
                        this.state.inputCustGroupDisabled
                          ? "disable-true summary-filter"
                          : "summary-filter"
                      }
                    >
                      <Multiselect
                        placeHolder="Shop"
                        optionsArray={shopArray}
                        dropdownDisabled={this.state.inputCustGroupDisabled}
                        onDropDownChange={value => {
                          this.shopSelected(value, shopArray);
                          this.submitGA("Filter", "Selected_Shop");
                        }}
                        dropdownValue={this.state.selectedShopVal}
                      />

                      {this.state.selectedShopVal.length ? (
                        <i
                          className="fa fa-times close2"
                          onClick={() => {
                            this.clearData("shop");
                            this.submitGA("Filter", "Selected_Shop");
                          }}
                        />
                      ) : null}
                    </div>
                  </div>
                  <div className="col">
                    <p className="summaryPText">AdGroup</p>
                    <div
                      className={
                        this.state.inputCustGroupDisabled
                          ? "disable-true summary-filter"
                          : "summary-filter"
                      }
                    >
                      <Multiselect
                        placeHolder="AdGroup"
                        optionsArray={adGroupArray}
                        dropdownDisabled={this.state.inputCustGroupDisabled}
                        onDropDownChange={value => {
                          this.adGroupSelected(value, adGroupArray)
                          this.submitGA("Filter", "Selected_Adgroup");
                        }}
                        onKeyDown={e => this.onAdGroupSearch(e)}
                        dropdownValue={this.state.selectedAdgroupVal}
                      />

                      {this.state.selectedAdgroupVal.length ? (
                        <i
                          className="fa fa-times close2"
                          onClick={() => {
                            this.clearData("adGroup");
                            this.submitGA("Filter", "Selected_Adgroup");
                          }}
                        />
                      ) : null}
                    </div>
                  </div>
                  {/* <div className="col-2" /> */}
                </div>
              </div>
            </div>
            <div className="card summaryTabs">
              <div className="collapseDivInner">
                <div id="accordion" className="accordion">
                  <div className="card mb-0">
                    {this.promoSummaryTableComponent(
                      disableaccordian,
                      "collapseThirteen",
                      "Total - level",
                      "summaryExport13",
                      tableData13
                    )}
                    {this.promoSummaryTableComponent(
                      disableaccordian,
                      "collapseOne",
                      "Customer Group",
                      "summaryExport1",
                      tableData1
                    )}
                    {this.promoSummaryTableComponent(
                      disableaccordian,
                      "collapseTwo",
                      "Customer Group - Event",
                      "summaryExport2",
                      tableData2
                    )}
                    {this.promoSummaryTableComponent(
                      disableaccordian,
                      "collapseThree",
                      "Business Group",
                      "summaryExport3",
                      tableData3
                    )}
                    {this.promoSummaryTableComponent(
                      disableaccordian,
                      "collapseFour",
                      "Business Group - Event",
                      "summaryExport4",
                      tableData4
                    )}
                    {this.promoSummaryTableComponent(
                      disableaccordian,
                      "collapseSeven",
                      "Shop",
                      "summaryExport7",
                      tableData7
                    )}
                    {this.promoSummaryTableComponent(
                      disableaccordian,
                      "collapseEight",
                      "Shop - Event",
                      "summaryExport8",
                      tableData8
                    )}
                    {this.promoSummaryTableComponent(
                      disableaccordian,
                      "collapseFive",
                      "Category",
                      "summaryExport5",
                      tableData5
                    )}
                    {this.promoSummaryTableComponent(
                      disableaccordian,
                      "collapseSix",
                      "Category -  Event",
                      "summaryExport6",
                      tableData6
                    )}

                    {this.promoSummaryTableComponent(
                      disableaccordian,
                      "collapseNine",
                      "Merchant Category",
                      "summaryExport9",
                      tableData9
                    )}
                    {this.promoSummaryTableComponent(
                      disableaccordian,
                      "collapseTen",
                      "Merchant Category - Event",
                      "summaryExport10",
                      tableData10
                    )}
                    {this.promoSummaryTableComponent(
                      disableaccordian,
                      "collapseEleven",
                      "Adgroup",
                      "summaryExport11",
                      tableData11
                    )}
                    {this.promoSummaryTableComponent(
                      disableaccordian,
                      "collapseTwelve",
                      "Event - level",
                      "summaryExport12",
                      tableData12
                    )}
                  </div>
                </div>
              </div>
            </div>
            {this.state.showFootnote ? (
              <div className="footnotesummaryy">
                <div className="card footnoteSummaryinner">
                  <p className="footnoteSummary1">
                    <i
                      className="fa fa-exclamation-triangle"
                      style={{ paddingRight: "4px" }}
                    />
                    This includes Promo Sales only
                  </p>
                </div>
              </div>
            ) : null}
          </PageLoader>
        </div>
      </div>
    );
  }
}

PromoSummary.propTypes = {
  getSummaryData: PropTypes.func.isRequired,
  getAdgroupData: PropTypes.func.isRequired,
  savePipelineCellsMethod: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  getCampaignDmList: state.adGroupData.getCampaignDmList,
  loader: state.summaryData.loader,
  validationLoader: state,
  summaryCumulativeFilters: state.summaryData.summaryCumulativeFilters,
  adGroupData: state.adGroupData.adGroupData
});

export default connect(mapStateToProps, {
  getSummaryData,
  getAdgroupData,
  getCumulativeFilters,
  savePipelineCellsMethod
})(withRouter(PromoSummary));
