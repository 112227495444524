import axios from "../../utils/axios/index";
import { config } from "../../utils/config";
import { BATCH_PROCESS_RUNNING, DISABLE_BATCH_BTN, DISABLE_DEEPEROFFER_BTN, DEEPEROFFER_RUN_MSG } from "./types";

export const batchProcessCheck = reqObject => async dispatch => {
  const response = await axios
    .post(`${config.baseUrl}/promo/batchProcessCheck`)
    .then(res => {
      dispatch({
        type: DISABLE_BATCH_BTN,
        payload: res.data
      });
      return res.data;
    })
    .catch(err => {
      return err;
    });
  return response;
};

export const runBatchProcess = reqObject => async dispatch => {
    const response = await axios
      .post(`${config.baseUrl}/promo/batchProcessButton`)
      .then(res => {
        dispatch({
          type: BATCH_PROCESS_RUNNING,
          payload: res.data
        }); // stored in userReducer.js file
        return res.data;
      })
      .catch(err => {
        return err;
      });
    return response;
  };

  export const runDeeperOfferLogic = reqObject => async dispatch => {
    dispatch({
      type: DISABLE_DEEPEROFFER_BTN,
      payload: true
    })
    const response = await axios
      .post(`${config.baseUrl}/deeperOffer/deeperOfferLogicBulkDataRun`)
      .then(res => {
        dispatch({
          type: DISABLE_DEEPEROFFER_BTN,
          payload: false
        })
        dispatch({
          type: DEEPEROFFER_RUN_MSG,
          payload: res && res.data && res.data.msg
        })
        return res;
      })
      .catch(err => {
        return err;
      });
    return response;
  };