import { GET_USERS_LIST, CREATE_USER_DATA, USER_STATUS_UPDATE_DATA, BATCH_PROCESS_RUNNING, 
  DISABLE_BATCH_BTN, DISABLE_DEEPEROFFER_BTN, DEEPEROFFER_RUN_MSG} from "../actions/types";
const initialState = {
  usersList: [],
  loading: true,
  batchProcessRunning: null,
  disableBatchBtn: false,
  disableDeeperOfferBtn: false,
  deeperOfferMsg: "",
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_USERS_LIST:
      return {
        ...state,
        usersList: action.payload
      };

    case CREATE_USER_DATA:
      return {
        ...state,
        createUserData: action.payload
      };

    case USER_STATUS_UPDATE_DATA:
      return {
        ...state,
        statusUpdateData: action.payload
      };
    case BATCH_PROCESS_RUNNING: 
      return {
        ...state,
        batchProcessRunning: action.payload
      };
    case DISABLE_BATCH_BTN: 
      return {
        ...state,
        disableBatchBtn: action.payload
      };
    case DISABLE_DEEPEROFFER_BTN:
      return{
        ...state,
        disableDeeperOfferBtn: action.payload
      }
    case DEEPEROFFER_RUN_MSG:
      return{
        ...state,
        deeperOfferMsg: action.payload
      }
    default:
      return state;
  }
}
