import React, { Component } from "react";
import { connect } from "react-redux";
import "./AdgroupMasterStyling.css";
import PageLoader from "../../../../utils/Loader";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import DataTable from "../../../tables";
import ColumnDef from "../../../tables/colDefs/AdgroupMasterColDef";
import AdgroupMasterChildMessageRendrer from "./AdgroupMasterChildMessageRendrer";
import SkuModalForAdgroupCreate from "../Adgroup/SkuModalForAdgroupCreate";
import {
  adgroupMasterData,
  updateMasterAdgroup,
} from "../../../../redux/actions/adgroupMaster";
import { deleteAdgroup } from "../../../../redux/actions/adgroup";
import AlertDanger from "../../../../utils/AlertMessage/alertDanger";
import AlertWarning from "../../../../utils/AlertMessage/alertWarning";
import AlertSuccess from "../../../../utils/AlertMessage/alertSuccess";
import { similarSkuProductsList } from "../../../../redux/actions/referenceSkuActions";
import _ from "lodash";
import PropTypes from "prop-types";
import { getSKUDetailsFromAdgroupId } from "../../../../redux/actions/adgroup";
import {
  editReferenceSku,
  infoReferenceSku,
} from "../../../../redux/actions/referenceSkuActions";
import Modal from "react-awesome-modal";
import { AdgroupMasterGA } from '../../../../utils/GA/GAUtils';
import { alertTimer } from "../../../../utils/alertTimer";
import {isIt} from '../../../../utils/getRoleName.js'

class AdgroupMasterRoot extends Component {
  state = {
    key: "active",
    adgroupMasterActive: [],
    adgroupMasterInactive: [],
    showViewSkuModal: false,
    activeData: [],
    inactiveData: [],
    somethingWentWrong: false,
    adgroupId: "",
    skuFootnote: [],
    SKUTableData: [],
    refSkuOptions: [],
    refreshRows: false,
    showLoader: false,
    showDetailPopup: false,
    alert: false,
    alertMsg: "",
    edited: false,
    selectedAdgroupDetails: [],
    adgroupLimitExceededAlert: false,
    rowSelection: "multiple",
    saveBeforeClose: false,
    selectedAdgroupId: "",
    savedRefSkuMsg: false,
  };

  async componentDidMount() {
    this.activeApiCall();
    let refSkuSearchRes = await this.props.similarSkuProductsList({
      modelable_sku: true,
      search_key: "",
    });
    let list = _.map(refSkuSearchRes.data, (item) => {
      return {
        value: item.unique_id1,
        label: item.unique_id1,
      };
    });
    list.unshift({ value: "none", label: "none" });
    this.setState({ refSkuOptions: list });
  }
  activeApiCall = async () => {
    var dataTable = [];
    this.setState({
      showLoader: true,
    });
    let res = await this.props.adgroupMasterData({ flag: 1 });
    if (!res) {
      this.setState({ somethingWentWrong: true });
      setTimeout(() => {
        this.setState({ somethingWentWrong: false });
      }, alertTimer);
      return;
    } else {
      dataTable = res.data ? res.data.data : [];
    }

    dataTable.map((item) => {
      item.viewSku = this.viewSku;
      return item;
    });

    var adgroupMasterActive = {
      header: ColumnDef.adgroupMasterHeader,
      defaultColDef: ColumnDef.defaultColDef,
      data: dataTable,
    };

    this.setState({
      adgroupMasterActive,
      showLoader: false,
    });
  };

  saveRefSkuDetail = async (rowData) => {
    let params = null;
    if (Object.keys(rowData).length) {
      this.setState({
        adgroupLoader: true,
      });
      params = {
        skuId: rowData.unique_id1,
        referenceSku:
          rowData.selected_sku === "none" ? null : rowData.selected_sku,
      };
      let saveRefSkuResponse = await this.props.editReferenceSku(params);

      if (saveRefSkuResponse.status) {
        this.setState({
          adgroupLoader: false,
          savedRefSkuMsg: true,
        });

        setTimeout(() => {
          this.setState({
            savedRefSkuMsg: false,
          });
        }, alertTimer);

        let res = await this.props.getSKUDetailsFromAdgroupId({
          adgroupId: this.state.selectedAdgroupId,
        });

        if (res.status) {
          this.setState({
            SKUTableData: res.data,
          });

          res.data.map((item) => {
            item.saveRefSkuDetail = this.saveRefSkuDetail;
            item.refSkuSearchHandler = this.refSkuSearchHandler;
            item.refSkuOptions = this.state.refSkuOptions;
            item.original_reference_sku = item.reference_sku;
            return item;
          });
        }
      }
    } else {
      this.setState({
        selectRefSkuError: true,
      });

      setTimeout(() => {
        this.setState({
          selectRefSkuError: false,
        });
      }, alertTimer);
    }
  };

  refSkuSearchHandler = async (key, id) => {
    let { SKUTableData } = this.state;
    let refSkuSearchRes = await this.props.similarSkuProductsList({
      modelable_sku: true,
      search_key: key,
    });
    let list = _.map(refSkuSearchRes.data, (item) => {
      return {
        value: item.unique_id1,
        label: item.unique_id1,
      };
    });
    list.unshift({ value: "none", label: "none" });

    let found = _.find(SKUTableData, { unique_id1: id });
    found.refSkuOptions = list;

    this.setState({ refSkuOptions: list, refreshRows: true, SKUTableData });
  };

  refreshRowsHandler = () => {
    this.setState({ refreshRows: false });
  };

  viewSku = async (val) => { 
    let skuFootnote = [];
    this.setState({ showLoader: true, selectedAdgroupId: val.id });
    let res = await this.props.getSKUDetailsFromAdgroupId({
      adgroupId: val.id,
    });

    if (res.status) {
      if (res.data.length && res.data[0].footnote != null) {
        skuFootnote.push(res.data[0].footnote);
      }

      this.setState({
        skuFootnote,
        SKUTableData: res.data,
      });

      res.data.map((item) => {
        item.saveRefSkuDetail = this.saveRefSkuDetail;
        item.refSkuSearchHandler = this.refSkuSearchHandler;
        item.refSkuOptions = this.state.refSkuOptions;
        item.original_reference_sku = item.reference_sku;
        return item;
      });
    } else {
      this.props.history.push("/promotion");
    }

    this.setState({
      SKUTableData: res.data,
      showLoader: false,
      showViewSkuModal: true,
    });
  };

  onSelectionChanged = (row) => {
    this.submitGA("Click", "Clicked_AdgroupCheckbox");
    let selectedAdgroupDetails = [];

    row.map((adgroupData) => {
      selectedAdgroupDetails.push({
        adgroupId: adgroupData.id,
        adgroupName: adgroupData.name,
        adgroupDesc: adgroupData.description,
        adgroup_merch_id: adgroupData.adgroup_merch_id
      });
    });

    this.setState({
      selectedAdgroupDetails,
    });
  };

  switchAdgroupMasterTab = async (key, call) => {
    this.setState({ key });

    var lyDataTable = [];

    if (key === "active" && this.state.edited) {
      this.activeApiCall();
      this.setState({ edited: false });
    }
    if (!this.state.adgroupMasterInactive.data || call || this.state.edited) {
      this.setState({
        showLoader: true,
        edited: false,
      });

      let res = await this.props.adgroupMasterData({ flag: "0" });
      if (!res.data.status) {
        this.setState({
          alert: true,
          alertMsg: res.data.msg,
          showLoader: false,
        });
        setTimeout(() => {
          this.setState({ alert: false, alertMsg: "" });
        }, alertTimer);
        return;
      } else {
        lyDataTable = res.data.data;
      }
      lyDataTable.map((item) => {
        item.status = "LY";
        item.viewSku = this.viewSku;
        item.deleteAdgroup = this.deleteAdgroup;
        return item;
      });

      var adgroupMasterInactive = {
        header: ColumnDef.adgroupMasterHeader,
        defaultColDef: ColumnDef.defaultColDefInactive,
        data: lyDataTable,
      };

      this.setState({
        adgroupMasterInactive,
        showLoader: false,
      });
    }
  };

  editAdgroup = (selectedAdgroupData) => {
    if (selectedAdgroupData.length <= 5) {
      localStorage.setItem(
        "adgroupDetailsFromMaster",
        JSON.stringify(selectedAdgroupData)
      );
      
      this.props.history.push("/editadgroup?fromAdgroupMaster=true");
    } else {
      this.setState({
        adgroupLimitExceededAlert: true,
      });

      setTimeout(() => {
        this.setState({
          adgroupLimitExceededAlert: false,
        });
      }, alertTimer);
    }
  };

  deleteAdgroup = async (id, flag) => {
    this.setState({ showLoader: true });
    let response = [];
    if (flag) {
      response = await this.props.updateMasterAdgroup({ flag, id });
    } else {
      if (this.state.selectedAdgroupDetails.length <= 5) {
        let adgroupID = id.map((adgroupIdArray) => {
          return adgroupIdArray.adgroupId;
        });
    
        response = await this.props.deleteAdgroup({ adgroupId: adgroupID });
      } else {
        this.setState({
          adgroupLimitExceededAlert: true,
        });

        setTimeout(() => {
          this.setState({
            adgroupLimitExceededAlert: false,
          });
        }, alertTimer);
      }
    }
    if (response.status || response.success) {
      if (flag) {
        this.setState({ successAlert: true });
        setTimeout(() => {
          this.setState({ successAlert: false });
        }, alertTimer);
      } else {
        this.setState({ successDeleteAlert: true });
        setTimeout(() => {
          this.setState({ successDeleteAlert: false });
        }, alertTimer);
      }

      if (flag === 1) {
        let a = await this.switchAdgroupMasterTab("inactive", true);
        this.setState({ edited: true });
        return;
      }
      let a = await this.activeApiCall();
      this.setState({ edited: true });
    }
    this.setState({ showLoader: false });
  };

  tabContent = (adgroupTableProducts, index, name) => {
    return (
      <div
        id={`agGridTableskuList${index}`}
        className="card mt-3"
        // style={{ margin: "10px 25px" }}
      >
        <DataTable
          index={index}
          tableData={adgroupTableProducts}
          autoSizeCol={true}
          downloadName={name}
          frameworkComponents={{
            adgroupMasterChildMessageRendrer: (props) => {
              return <AdgroupMasterChildMessageRendrer history={this.props.history} deleteAdgroup={this.deleteAdgroup} {...props} />
            }
          }}
          selectOfferRow={this.onSelectionChanged}
          rowSelection={this.state.rowSelection}
        />
      </div>
    );
  };
  submitGA(action,label){
    AdgroupMasterGA({ action, label});
  }

  enableDeleteButton(){
    let role = localStorage.getItem("role")
    if(["2","3"].indexOf(role)!==-1){
      return true;
    }else{
      return this.state.selectedAdgroupDetails.length > 0 ? false : true;
    }
  }

  render() {
    return (
      <div>
        <PageLoader loader={this.state.showLoader}>
          <div className="alertDivs">
            {this.state.adgroupLimitExceededAlert ? (
              <AlertDanger
                message="A maximum of 5 adgroups can be selected!"
                show={this.state.adgroupLimitExceededAlert}
              />
            ) : null}
            {this.state.somethingWentWrong ? (
              <AlertWarning
                message="Something went wrong! Please try again."
                show={this.state.somethingWentWrong}
              />
            ) : null}
            {this.state.alert ? (
              <AlertWarning
                message={this.state.alertMsg}
                show={this.state.alert}
              />
            ) : null}
            {this.state.successAlert ? (
              <AlertSuccess
                message="Adgroup Activated Successfully."
                show={this.state.successAlert}
              />
            ) : null}

            {this.state.savedRefSkuMsg ? (
              <div>
                <AlertSuccess
                  message="Reference SKU details saved!"
                  show={this.state.savedRefSkuMsg}
                />
              </div>
            ) : null}

            {this.state.successDeleteAlert ? (
              <AlertSuccess
                message="Deactivated Successfully."
                show={this.state.successDeleteAlert}
              />
            ) : null}
          </div>
          <div className="card cardStyles cardGap">
            <div className="px-4 mb-4">
              <div className="createClusterDiv">
                <div className="row mt-4 mx-0">
                  <div className="col text-center">
                    <h4 className="promoHeading mb-0">Adgroup Master</h4>
                  </div>
                  <span className="text-right">
                    {this.state.key === "active" ? (
                      <>
                        {/* <button
                          type="button"
                          title="Edit Adgroups"
                          className="btn pipeline-button-class mr-1 pipeline-btn-styling"
                          onClick={(e) => {
                            this.editAdgroup(this.state.selectedAdgroupDetails);
                            this.submitGA("Click", "Clicked_EditAdgroupButton");
                          }}
                          disabled={!this.state.selectedAdgroupDetails.length}
                          // disabled="true"
                        >
                          <i
                            className="fa fa-edit text-cyan"
                            aria-hidden="true"
                          />
                        </button> */}
                        <button
                          type="button"
                          title="Deactivate Adgroups"
                          className="btn pipeline-button-class mr-1 pipeline-btn-styling"
                          onClick={(e) => {
                            this.deleteAdgroup(
                              this.state.selectedAdgroupDetails,
                              0
                            );
                            this.submitGA("Click", "Clicked_DeleteAdgroupButton");
                          }}
                          // changed this as per the new reqmts
                          // disabled={(!this.state.selectedAdgroupDetails.length) && (localStorage.getItem("role") === "2" ||
                          // localStorage.getItem("role") === "3" || localStorage.getItem("role") === "4" || localStorage.getItem("role") === "5"
                          //   ? false
                          //   : true)}
                          // disabled={(!this.state.selectedAdgroupDetails.length) && (localStorage.getItem("role") === "2" || localStorage.getItem("role") === "3")}
                          disabled={this.enableDeleteButton() || isIt()}
                        >
                          <i className="fa fa-trash" aria-hidden="true" />
                        </button>
                      </>
                    ) : (
                      <div />
                    )}
                    <button
                      type="button"
                      title="Create Adgroup"
                      className="btn pipeline-button-class mr-1 pipeline-btn-styling"
                      onClick={(e) => {
                        this.props.history.push(
                          "/createadgroup?fromAdgroupMaster=true"
                        );
                        this.submitGA("Click", "Clicked_CreateAdgroupButton");
                      }}
                      // changed this as per the new reqmts
                      //  disabled={
                      //   localStorage.getItem("role") === "3" ||
                      //   localStorage.getItem("role") === "5"
                      //     ? true
                      //     : false
                      // }
                      disabled={
                        localStorage.getItem("role") === "4" ||
                        localStorage.getItem("role") === "5" || 
                        localStorage.getItem("role") === "3"
                          ? true
                          : false
                      }
                    >
                      <i className="fa fa-plus" aria-hidden="true" />
                    </button>
                    {/* <p className="mb-0">Create Adgroup</p> */}
                  </span>
                </div>

                <Tabs
                  activeKey={this.state.key}
                  onSelect={(key) => {
                    this.switchAdgroupMasterTab(key);
                    this.submitGA("Click", "Clicked_ActiveInactiveToggle");
                  }}
                  style={{ marginTop: "-1%" }}
                  className="simulatn-table-tab"
                >
                  <Tab eventKey="active" title="Active">
                    {this.tabContent(
                      this.state.adgroupMasterActive,
                      1,
                      "Active"
                    )}
                  </Tab>
                  <Tab eventKey="inactive" title="Inactive">
                    {this.tabContent(
                      this.state.adgroupMasterInactive,
                      1,
                      "Inactive"
                    )}
                  </Tab>
                </Tabs>
              </div>
            </div>
          </div>
        </PageLoader>

        {this.state.showViewSkuModal ? (
          <SkuModalForAdgroupCreate
            showLoader={this.state.showLoader}
            closeSkuModal={() => {
              var refSkuNotSaved = _.find(this.state.SKUTableData, {
                sku_saved: false,
              });
              if (refSkuNotSaved) {
                this.setState({ saveBeforeClose: true });
              } else {
                this.setState({
                  showViewSkuModal: !this.state.showViewSkuModal,
                  saveBeforeClose: false,
                });
              }
            }}
            tableData={this.state.SKUTableData}
            footnote={this.state.skuFootnote}
            refreshRows={this.state.refreshRows}
            refreshRowsHandler={this.refreshRowsHandler}
            closeDetailPopup={() => this.setState({ showDetailPopup: false })}
            showDetailPopup={this.state.showDetailPopup}
            refSkuInfoResponse={this.state.refSkuInfoResponse}
          />
        ) : null}

        {this.state.saveBeforeClose ? (
          <Modal
            visible={true}
            width="400px"
            height="200px"
            effect="fadeInDown"
          >
            <h5 className="delete-confirmatn-modal text-left mt-3">
              There are some unsaved changes. Do you wish to continue?
            </h5>
            <div className="text-center delete-btns">
              <button
                className="btn btn-outline-secondary"
                onClick={() => {
                  this.setState({ saveBeforeClose: false });
                }}
              >
                No
              </button>
              <button
                className="btn btn-outline-secondary ml-3"
                onClick={() =>
                  this.setState({
                    showViewSkuModal: !this.state.showViewSkuModal,
                    saveBeforeClose: false,
                  })
                }
              >
                Yes
              </button>
            </div>
          </Modal>
        ) : null}
      </div>
    );
  }
}

AdgroupMasterRoot.propTypes = {
  similarSkuProductsList: PropTypes.func.isRequired,
  getSKUDetailsFromAdgroupId: PropTypes.func.isRequired,
  editReferenceSku: PropTypes.func.isRequired,
  infoReferenceSku: PropTypes.func.isRequired,
};

export default connect(null, {
  adgroupMasterData,
  updateMasterAdgroup,
  similarSkuProductsList,
  editReferenceSku,
  infoReferenceSku,
  getSKUDetailsFromAdgroupId,
  deleteAdgroup,
})(AdgroupMasterRoot);
