import moment from "moment";
import numeral from "numeral";
import {
  valueFormatterHandler,
  valueGMFormatterHandler
} from "../../../utils/valueFormatters";

function isFirstColumn(params) {
  var displayedColumns = params.columnApi.getAllDisplayedColumns();
  var thisIsFirstColumn = displayedColumns[0] === params.column;
  return thisIsFirstColumn;
}
export default {
  defaultColDef: {
    checkboxSelection: isFirstColumn,
    resizable: true,
    sortable: true
  },
  colDefForCompare: [
    {
      headerName: "AdGroup",
      field: "adgroup",
      //filterParams: { defaultOption: "startsWith" },
      filter: "agTextColumnFilter",
      pinned: "left",
      unSortIcon: true
    },
    {
      headerName: "AdGroup Desc.",
      field: "adgroup_desc",
      //filterParams: { defaultOption: "startsWith" },
      filter: "agTextColumnFilter",
      pinned: "left",
      unSortIcon: true
    },
    {
      headerName: "Shop Desc.",
      field: "shop_desc",
      //filterParams: { defaultOption: "startsWith" },
      filter: "agTextColumnFilter",
      unSortIcon: true
    },
    {
      headerName: "Time Period",
      field: "time_period",
      //filterParams: { defaultOption: "startsWith" },
      filter: "agTextColumnFilter",
      unSortIcon: true,
      valueGetter: function sumField(params) {
        return (
          moment(params.data.start_date).format("MM-DD-YYYY") +
          " - " +
          moment(params.data.end_date).format("MM-DD-YYYY")
        );
      }
    },
    {
      headerName: "Promo Type",
      field: "promo_type",
      //filterParams: { defaultOption: "startsWith" },
      filter: "agTextColumnFilter",
      unSortIcon: true,
      // valueGetter: promoTypeFormatter
    },
    {
      headerName: "Offer Type",
      field: "offer_type",
      //filterParams: { defaultOption: "startsWith" },
      filter: "agTextColumnFilter",
      unSortIcon: true
    },
    {
      headerName: "Metrics/ Offer",
      field: "offer",
      filter: "agTextColumnFilter",
      //filterParams: { defaultOption: "startsWith" },
      cellStyle: function(params) {
        return {
          color: "orange"
        };
      },
      unSortIcon: true
    },
    // {
    //   headerName: "No. of days",
    //   field: "days",
    //   //filterParams: { defaultOption: "startsWith" },
    //   filter: "agTextColumnFilter",
    //   unSortIcon: true
    // },
    {
      headerName: "Start Date",
      field: "start_date",
      hide: true
    },
    {
      headerName: "End Date",
      field: "end_date",
      hide: true
    },
    // {
    //   headerName: "Avg. URP ($)",
    //   field: "avg_urp",
    //   valueGetter: valueFormatterHandler,
    //   filter: "agTextColumnFilter",
    //   width: 140,
    //   unSortIcon: true,
    //   hide: true
    // },
    {
      headerName: "Units",
      children: [
        {
          headerName: "Total",
          //filterParams: { defaultOption: "startsWith" },
          valueFormatter: valueFormatterHandler,
          valueGetter: function(params) {
            return parseFloat(params.data.total_quantity);
          },
          filter: "agTextColumnFilter",
          field: "total_quantity",
          width: 120,
          unSortIcon: true
        }
        // {
        //   headerName: "Avg. per day",
        //   //filterParams: { defaultOption: "startsWith" },
        //   valueGetter: valueFormatterHandler,
        //   filter: "agTextColumnFilter",
        //   field: "day_total_quantity",
        //   width: 140,
        //   unSortIcon: true
        // },
        // {
        //   headerName: "Avg. per week",
        //   //filterParams: { defaultOption: "startsWith" },
        //   valueGetter: valueFormatterHandler,
        //   filter: "agTextColumnFilter",
        //   field: "weeks_total_quantity",
        //   width: 160,
        //   unSortIcon: true
        // }
      ]
    },
    {
      headerName: "Sales ($)",
      children: [
        {
          headerName: "Total ($)",
          //filterParams: { defaultOption: "startsWith" },
          valueFormatter: valueFormatterHandler,
          valueGetter: function(params) {
            return parseFloat(params.data.total_revenue);
          },
          filter: "agTextColumnFilter",
          field: "total_revenue",
          width: 120,
          unSortIcon: true
        }
        // {
        //   headerName: "Avg. per day ($)",
        //   //filterParams: { defaultOption: "startsWith" },
        //   valueGetter: valueFormatterHandler,
        //   filter: "agTextColumnFilter",
        //   field: "day_total_revenue",
        //   width: 140,
        //   unSortIcon: true
        // },
        // {
        //   headerName: "Avg. per week ($)",
        //   filter: "agTextColumnFilter",
        //   //filterParams: { defaultOption: "startsWith" },
        //   valueGetter: valueFormatterHandler,
        //   field: "weeks_total_revenue",
        //   width: 160,
        //   unSortIcon: true
        // }
      ]
    },
    {
      headerName: "Margin ($)",
      children: [
        {
          headerName: "Total ($)",
          //filterParams: { defaultOption: "startsWith" },
          valueFormatter: valueFormatterHandler,
          valueGetter: function(params) {
            return parseFloat(params.data.total_margin);
          },
          filter: "agTextColumnFilter",
          field: "total_margin",
          width: 120,
          unSortIcon: true
        }
        // {
        //   headerName: "Avg. per day ($)",
        //   //filterParams: { defaultOption: "startsWith" },
        //   valueGetter: valueFormatterHandler,
        //   filter: "agTextColumnFilter",
        //   field: "day_total_margin",
        //   width: 140,
        //   unSortIcon: true
        // },
        // {
        //   headerName: "Avg. per week ($)",
        //   //filterParams: { defaultOption: "startsWith" },
        //   valueGetter: valueFormatterHandler,
        //   filter: "agTextColumnFilter",
        //   field: "weeks_total_margin",
        //   width: 160,
        //   unSortIcon: true
        // }
      ]
    },
    {
      headerName: "GM %",
      valueFormatter: valueGMFormatterHandler,
      //filterParams: { defaultOption: "startsWith" },
      valueGetter: function(params) {
        return parseFloat(params.data.gm_percentage);
      },
      filter: "agTextColumnFilter",
      field: "gm_percentage",
      width: 100,
      unSortIcon: true
    }
  ]
};

function promoTypeFormatter(params) {
  let promoTypeSet = JSON.parse(localStorage.getItem("promo_type"));
  if (params.data.promo_type) {
    return promoTypeSet
      ? promoTypeSet[params.data.promo_type]
      : params.data.promo_type;
  }
  return "-";
}
