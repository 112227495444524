
import axios from "../../utils/axios/index";
import { config } from "../../utils/config";

export const getCouponList = reqObject => async dispatch => {
     

  const response = await axios.post(
    `${config.baseUrl}/coupon/couponsList`,
    reqObject
  );
  return response.data;
};

export const getEventCouponList = reqObject => async dispatch => {
     

  const response = await axios.post(
    `${config.baseUrl}/coupon/couponsEventsList`,
    reqObject
  );

  return response.data;
};

export const createCoupon = reqObject => async dispatch => {
     

  const response = await axios.post(
    `${config.baseUrl}/coupon/createEditCoupons`,
    reqObject
  );

  return response.data;
};

export const deleteCoupons = reqObject => async dispatch => {
     

  const response = await axios.post(
    `${config.baseUrl}/coupon/deleteCoupons`,
    reqObject
  );

  return response.data;
};
