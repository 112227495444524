const getOfferTitle = (offerType, offerValue, fixedpricequantity, minquantity, bonusbyquantity, offer) => {
  let title = '';

  if (offerType == "P") {
    title = offerValue + "% OFF";
  } else if (offerType == "F" && fixedpricequantity <= 1) {
    title = "$ " + offerValue;
  } else if (offerType == "A") {
    title = "$ " + offerValue + " OFF";
  } else {
    title = offerValue;
  }

  if (offerType == "B") {
    title = `BUY ${minquantity} GET ${bonusbyquantity} @ ${offer}% OFF`;
  }
  if (offerType == "G") {
    title = `BUY ${minquantity} GET ${bonusbyquantity}`;
  }
  if (offerType == "D") {
    title = `BUY ${minquantity} GET ${offer}% OFF EACH`;
  }
  if (offerType == "C") {
    title = `BUY ${minquantity} GET ${bonusbyquantity} @ $ ${offer} OFF`;
  }
  if (offerType == "F" && fixedpricequantity > 1) {
    title = `${fixedpricequantity} FOR $ ${offer}`;
  }

  return title;
}

export  {getOfferTitle};