import React from "react";
import { withRouter } from "react-router-dom";
import Modal from "react-awesome-modal";

export class CookieAlert extends React.Component {
  componentWillMount(){
    if(navigator.cookieEnabled){
        this.props.history.push("/");
    }     
  }
  onClickHandler = () => window.location.reload();

  render() {
    return (
      <Modal
        visible={true}
        width="400px"
        height="250px"
        effect="fadeInDown"
        onClickAway={this.onClickHandler}
      >
        <div className="closeicon">
          <span style={{ cursor: "pointer" }}>
            <i
              className="fa fa-times"
              aria-hidden="true"
              onClick={this.onClickHandler}
            />
          </span>
        </div>
        <h5 className="delete-confirmatn-modal text-left">
          Cookies Disabled!
        </h5>
        <p className="session-timedout-padding text-left">
          Please enable cookies in your browser to proceed.
        </p>
        <div className="text-right delete-btns session-timedout-padding">
          <button
            className="btn btn-outline-primary promo3Buttons ml-3"
            onClick={this.onClickHandler}
          >
            Ok
          </button>
        </div>
      </Modal>
    );
  }
}

export default withRouter(CookieAlert);
