import React from "react";
import { Route, Redirect, withRouter } from "react-router-dom";

var pathNameArray = [
  "referenceSku",
  "promotion",
  "validation",
  "shop",
  "executivesummary",
  "ComparePromotion",
  "historicperformane",
  "createpromotion",
  "eda",
  "createadgroup",
  "manageUsers",
  "createcoupon",
  "editcoupon",
  "editadgroup",
  "adgroupMaster",
  "ticketGenerate",
  "dayleveloffer",
  "uploadOffers",
  "eventMaster",
  "editEvent",
  "createEvent",
  "viewEvent",
  "createoffer",
  "promoApproval",
  "reports"
];

const ErrRoute = ({ component: Component, location, ...rest }) => {
  let currentPathname = location.pathname.slice(1, location.pathname.length);
  return (
    <Route
      {...rest}
      render={props =>
        !pathNameArray.includes(currentPathname) ? (
          <Component {...props} />
        ) : null
      }
    />
  );
};

export default withRouter(ErrRoute);
