import React, { Component } from "react";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import CompareGraphData from "./CompareGraphData";
import { PromotionCalendarGA } from '../../../../utils/GA/GAUtils';

export class CompareGraphTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedView: "Week",
      key: "Units"
    };
  }

  onTabClick = val => {
    this.setState({ key: val });
  };
  submitGA(action,label){
    PromotionCalendarGA({ action, label});
  }

  render() {
    return (
      <div className="performanceMainTab">
        <div className="card performanceTab">
          <Tabs
            id="controlled-tab-example"
            activeKey={this.state.key}
            onSelect={key => {
              this.onTabClick(key);
              this.submitGA("Click", "Switched_Compare_UnitsSalesMarginTabs");
            }}
            style={{ overflowY: "hidden" }}
          >
            <Tab eventKey="Units" title="Units" />
            <Tab eventKey="Sales" title="Sales" />
            <Tab eventKey="Margin" title="Margin" />
          </Tabs>
          <CompareGraphData
            compareGraphData={this.props.compareGraphData}
            timePeriodArray= {this.props.timePeriodArray}
            view={this.state.key}
          />
        </div>
      </div>
    );
  }
}

export default CompareGraphTab;
