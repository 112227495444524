export default {
  defaultColDef: {
    resizable: true,
    filter: true,
    sortable: true
  },
  reviewOfferHeader: [
    {
      headerName: "AdGroup",
      field: "name",
      filter: "agTextColumnFilter",
      filterParams: { defaultOption: "contains" },
      suppressSizeToFit: true,
      unSortIcon: true
    },
    {
      headerName: "Adgroup Desc.",
      field: "adgroup_description",
      filter: "agTextColumnFilter",
      filterParams: { defaultOption: "contains" },
      suppressSizeToFit: true,
      unSortIcon: true
    },
    {
      headerName: "Promotion Type",
      field: "promo_type",
      width: 160,
      filter: "agTextColumnFilter",
      filterParams: { defaultOption: "contains" },
      suppressSizeToFit: true,
      unSortIcon: true
    },
    {
      headerName: "Last Year Offer",
      field: "ly_data",
      filter: "agTextColumnFilter",
      filterParams: { defaultOption: "contains" },
      valueFormatter: function(params) {
        if (params.data.ly_data !== null) {
          let lyData =
            Math.round(params.data.ly_data.last_year_offer_value * 100) / 100;
          let lyType = params.data.ly_data.last_year_offer_type;
          let value =
            lyType === "P"
              ? lyData + "% OFF"
              : lyType === "F"
              ? "$" + lyData
              : lyType === "A"
              ? "$ " + lyData + " OFF"
              : " - ";
          return value;
        }

        return "-";
      },
      suppressSizeToFit: true,
      unSortIcon: true,
      hide: true
    },
    {
      headerName: "Initial Offer",
      field: "initial_offer",
      filter: "agTextColumnFilter",
      filterParams: { defaultOption: "contains" },
      suppressSizeToFit: true,
      valueGetter: function(params) {
        if (params.data.initial_offer) {
          if (
            params.data.initial_offer.includes("$") &&
            params.data.initial_offer.includes("OFF")
          ) {
            return params.data.initial_offer
              .replace("$", "$ ")
              .replace("OFF", " OFF");
          }
          return params.data.initial_offer;
        }
        return "-";
      },
      unSortIcon: true
    },
    {
      headerName: "Planned Offer",
      field: "offer_value",
      filter: "agTextColumnFilter",
      filterParams: { defaultOption: "contains" },
      valueFormatter: function(params) {
        let offerVal = Math.round(params.data.offer_value * 100) / 100;
        return params.data.offer_type === "P"
          ? offerVal + "% OFF"
          : params.data.offer_type === "F"
          ? "$ " + offerVal
          : "$ " + offerVal + " OFF";
      },
      suppressSizeToFit: true,
      unSortIcon: true
    },
    {
      headerName: "TY Planned Offer Type",
      field: "ty_offer_type",
      // headerClass: "blue-header-bg",
      width: 225,
      cellRenderer: "OfferTypeRenderer",
      filterParams: { defaultOption: "contains" },
      suppressSizeToFit: true,
      filter: "agTextColumnFilter",
      unSortIcon: true
    },
    {
      headerName: "Offer type options", //Non-view purpose, used only for storing options value change
      field: "offerTypeOptions",
      filter: "agTextColumnFilter",
      filterParams: { defaultOption: "contains" },
      // headerClass: "blue-header-bg",
      unSortIcon: true,
      hide: true
    },
    {
      headerName: "TY Planned Offer Amount",
      field: "ty_planned_amount",
      filter: "agTextColumnFilter",
      width: 250,
      filterParams: { defaultOption: "contains" },
      // headerClass: "blue-header-bg",
      cellRenderer: "pipelineInputRenderer",
      suppressSizeToFit: true,
      unSortIcon: true
    },
    {
      headerName: "Fixed price Qty",
      field: "fixed_price_quantity",
      filterParams: { defaultOption: "contains" },
      filter: "agTextColumnFilter",
      width: 200,
      // headerClass: "blue-header-bg",
      cellRenderer: "pipelineInputRenderer",
      suppressSizeToFit: true,
      unSortIcon: true
    },
    {
      headerName: "TY Min Qty for offer",
      field: "ty_min_qty",
      filter: "agTextColumnFilter",
      filterParams: { defaultOption: "contains" },
      // headerClass: "blue-header-bg",
      width: 250,
      suppressSizeToFit: true,
      cellRenderer: "pipelineInputRenderer",
      unSortIcon: true
    },
    {
      headerName: "TY Bonus Buy (Free Goods Qty)",
      field: "ty_bonus_pay",
      filter: "agTextColumnFilter",
      filterParams: { defaultOption: "contains" },
      // headerClass: "blue-header-bg",
      width: 275,
      cellRenderer: "pipelineInputRenderer",
      suppressSizeToFit: true,
      unSortIcon: true
    },
    {
      headerName: "errorFlag",
      field: "error_flag",
      filterParams: { defaultOption: "contains" },
      suppressSizeToFit: true,
      hide: true,
      filter: false,
      width: 100
    }
  ]
};
