import React, { Component } from "react";
import { Container, Row, Col } from 'react-bootstrap';
import _ from "lodash";
import moment from "moment";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { Typography, Select, Input, Button  } from 'antd';
import SkuSelectionMaster from "./SkuSelectionMaster";
import PageLoader from "../../../../utils/Loader";
import CloseCircleFilled from '@ant-design/icons/CloseCircleFilled';
import {
  getAdgroup,getAllHierarchies,getAllAttributes, getMerchDetails, getLabelAndUOM
} from "../../../../redux/actions/adgroup";
import { getBrowserTimeZone } from "../../../../utils/valueFormatters";

const { Text } = Typography;

const {
  // ADGROUP_LABELS,
  // ADGROUP_UOM_LIST,
  // MERCHID_MAPPING_LIST,
  ADGROUP_TYPE_OPTIONS
} = require('./utils/adgroup-constants');

export class EditAdgroup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      merchOptions: [],
      MERCHID_MAPPING_LIST : [],
      adgroup_labels : [],
      adgroup_uom_list : [],
      adgroupNameValue: null,
      adgroupDescriptionValue: null,
      selectedAdgroupTypeValue: null,
      selectedMerchId: null,
      signDescriptionValue: null,
      selectedLabelValue: null,
      sublineValue: null,
      selected_UOM_Value: null,

      hierarchyBtnClicked: true,
      pageLoading: false,

      existingAdgroupData: null
    };
  }

  async componentDidMount() {
    this.setState({ pageLoading: true });
    this.props.getAllHierarchies()
    this.props.getAllAttributes()
    let adgroupDetailsFromMaster = JSON.parse(
      localStorage.getItem("adgroupDetailsFromMaster")
    );
    const adgroupId = _.get(adgroupDetailsFromMaster, '[0].adgroupId', null);


    const merchDetails = await this.props.getMerchDetails();
    const AdgroupDropDownData = await this.props.getLabelAndUOM();
    console.log("getLabelAndUOM",AdgroupDropDownData);

    const tempAdGroupLabels = AdgroupDropDownData.data[0].vendor_label.map(data => {
      return {
         label : data,
         value : data
      }
 })

 this.setState ({adgroup_labels :  _.cloneDeep(tempAdGroupLabels)});

 const tempUomLabels = AdgroupDropDownData.data[0].uom.map(data => {
   return {
      label : data,
      value : data
   }
})

 this.setState ({adgroup_uom_list :  _.cloneDeep(tempUomLabels)});

    const tempMerchDetailsList = merchDetails.buyerDetails.map(buyer => {
      return {
        Merch_Id: buyer.buyer_id,
        GMM : buyer.gmm,
        MerchandiseBuyerName: buyer.buyer_name  ?  buyer.buyer_name  : "N/A"
      }
    })

    this.setState ({MERCHID_MAPPING_LIST :  _.cloneDeep(tempMerchDetailsList)});

    const tempMerchDetails = merchDetails.buyerDetails.map(buyer => {
      const key = buyer.buyer_name ? `${buyer.buyer_id}_${buyer.buyer_name}` : buyer.buyer_id;
      return {
        label: buyer.buyer_id,
        value: buyer.buyer_id,
        key: key
      }
    })

    this.setState({merchOptions : tempMerchDetails});
    
    // let merchId = localStorage.getItem("merchid"),
    //   merchIdArry = merchId && merchId !== "null" ? JSON.parse(merchId) : [],
    //   merchOptions = [];

    // _.map(merchIdArry, (item) => {
    //   // let labelVar = item.shops
    //   //   ? item.merch_id + " - " + item.shops
    //   //   : item.merch_id;
    //   let labelVar = item.shops
    //     ? item.merch_id
    //     : item.merch_id;
    //   merchOptions.push({ value: item.merch_id, label: labelVar });
    // });
    // let val = !_.isEmpty(merchIdArry) ? merchOptions[0] : "";

    await this.props.getAdgroup({
        id: adgroupId
    })
    .then((response) => {
      const data = _.get(response, 'data[0]', []);
      console.log('data',data);

      this.setState({ 
        pageLoading: false, 
        existingAdgroupData: data,
        adgroupNameValue: _.get(data, 'name', null),
        adgroupDescriptionValue: _.get(data, 'description', null),
        selectedAdgroupTypeValue: _.get(data, 'adgroup_type', null),
        selectedMerchId: _.get(data, 'merch_id', null),
        signDescriptionValue: _.get(data, 'sign_description', null),
        selectedLabelValue: _.get(data, 'vendor_label', null),
        // selectedLabelValue: "Hello",
        sublineValue: _.get(data, 'subline', null),
        selected_UOM_Value: _.get(data, 'uom', null) 
      });
    })
    .catch(() => {
    });
    // const sortedMerchOptions = _.sortBy(merchOptions, ['value']);

    // this.setState({ merchOptions: sortedMerchOptions, pageLoading: false, });
  };


  handleSelectionChange = (e, columnName) => {
    const inputValue = _.get(e, 'target.value', null);

    if (_.isNil(columnName) || _.isNil(inputValue)) {
      return null;
    }
    this.setState({ [columnName]: inputValue })
  }

  handleSelectionChangeV2 = (value, columnName) => {
    if (_.isNil(columnName) || _.isNil(value)) {
      return null;
    }
    this.setState({ [columnName]: value })
  }

  togglePageLoading = (v) => {
    this.setState({ pageLoading: v })
  }

  disabledAdgroup(){
    let role = localStorage.getItem("role")
    if(["2","3"].indexOf(role)!==-1){
      return true;
    }
    return false;
  }

  disabledSign(){
    let role = localStorage.getItem("role")
    if(["2"].indexOf(role)!==-1){
      return true;
    }
    return false;
  }

  marketingDisabled() {
    let role = localStorage.getItem('role')
    if(['3'].indexOf(role) !== -1) {
      return true;
    }
    return false;
  }
  render() {
    const { 
      adgroupNameValue, 
      adgroupDescriptionValue,
      selectedAdgroupTypeValue, 
      selectedMerchId,
      signDescriptionValue,
      selectedLabelValue,
      selected_UOM_Value,
      sublineValue,
      hierarchyBtnClicked,
      pageLoading,

      existingAdgroupData
    } = this.state;

    const isHierarchyBtnEnabled= !_.isEmpty(adgroupNameValue) && !_.isEmpty(adgroupDescriptionValue) && !_.isEmpty(selectedAdgroupTypeValue) && !_.isEmpty(selectedMerchId) 
    && !_.isEmpty(signDescriptionValue) && !_.isEmpty(selectedLabelValue) && !_.isEmpty(selected_UOM_Value) ;

   
    let merchantBuyerName = null;
    let GMM = null;

    if(!_.isEmpty(selectedMerchId)) {
      const mapping = _.find(this.state.MERCHID_MAPPING_LIST, (o) => _.eq(o.Merch_Id, selectedMerchId));
      merchantBuyerName = _.get(mapping, 'MerchandiseBuyerName', null);
      GMM = _.get(mapping, 'GMM', null);
    }

    const Created = _.get(existingAdgroupData, 'createdAt', null) ? getBrowserTimeZone(_.get(existingAdgroupData, 'createdAt', null),"MONTH") : '-';
    const CreatedBy = _.get(existingAdgroupData, 'created_by_user', null) ? _.get(existingAdgroupData, 'created_by_user', null) : '-';
    const Changed = _.get(existingAdgroupData, 'updatedAt', null) ? getBrowserTimeZone(_.get(existingAdgroupData, 'updatedAt', null),"MONTH") : '-';
    const ChangedBy = _.get(existingAdgroupData, 'updated_by_user', null) ? _.get(existingAdgroupData, 'updated_by_user', null) : '-';
    
    return (
      <div>
        <PageLoader loader={pageLoading}>
          <div className="card cardStyles cardGap">
            <div id="cardbox1" style={{ padding: "1px 12px 10px 20px" }}>
              <div className="createClusterDiv">

                <div className="row pl-3">
                  <div className="row col-md-3">
                    <i
                      className="fa fa-angle-double-left double-left-icon"
                      onClick={() =>
                        this.props.history.push("/adgroupMaster")
                      }
                      aria-hidden="true"
                    ></i>
                    <p
                      id="backToCalLink"
                      onClick={() =>
                        this.props.history.push("/adgroupMaster")
                      }
                    >
                      Back To Adgroup Master
                    </p>
                  </div>
                  <div className="col-md-6">
                    {" "}
                    <h4 className="promoHeading">
                      Edit Adgroup
                    </h4>
                  </div>
                </div>

                <div className="childCompStyling" style={{ border: "none" }}>

                  <div className="createClusterDiv card cardStyles cardGap">
                
                    <Container fluid>

                      <Row style={{ marginTop: '10px', marginBottom: '10px'}}>
                        <Col lg>
                          <p className="summaryPText">Adgroup Name</p>
                          <Input
                            id="adgroupNameInput" 
                            showCount 
                            maxLength={10}
                            placeholder="Please Enter Adgroup Name"
                            onChange={(e) => this.handleSelectionChange(e, 'adgroupNameValue')}
                            value={adgroupNameValue}
                            style={{ width: '100%'}}
                            allowClear={false}
                            status={_.isEmpty(adgroupNameValue) ? "error" : null}
                            suffix={_.isEmpty(adgroupNameValue) ? <CloseCircleFilled /> : null}
                            disabled
                          />
                        </Col>
                        <Col lg>
                          <p className="summaryPText">Adgroup Description</p>
                          <Input
                            id="adgroupDescriptionInput" 
                            showCount 
                            maxLength={100}
                            placeholder="Please Enter Adgroup Description"
                            onChange={(e) => this.handleSelectionChange(e, 'adgroupDescriptionValue')}
                            value={adgroupDescriptionValue}
                            style={{ width: '100%'}}
                            allowClear={false}
                            status={_.isEmpty(adgroupDescriptionValue) ? "error" : null}
                            suffix={_.isEmpty(adgroupDescriptionValue) ? <CloseCircleFilled /> : null}
                            disabled = {this.marketingDisabled()}
                          />
                        </Col>
                        <Col lg>
                          <p className="summaryPText">Adgroup Type</p>
                          <Select
                            placeholder="Please Select Adgroup Type"
                            value={selectedAdgroupTypeValue}
                            options={ADGROUP_TYPE_OPTIONS}
                            onChange={(e) => this.handleSelectionChangeV2(e, 'selectedAdgroupTypeValue')}
                            style={{ width: '100%'}}
                            allowClear={false}
                            suffixIcon={_.isEmpty(selectedAdgroupTypeValue) ? <CloseCircleFilled style={{ color: 'red'}} /> : null}
                            disabled={this.disabledAdgroup()}
                          />
                        </Col>
                        <Col lg>
                          <p className="summaryPText">Merch Id</p>
                          <Select
                            placeholder="Please Select Merch Id"
                            value={selectedMerchId}
                            options={this.state.merchOptions}
                            onChange={(e) => this.handleSelectionChangeV2(e, 'selectedMerchId')}
                            style={{ width: '100%'}}
                            allowClear={false}
                            showSearch={true}
                            filterOption={(input, option) =>
                              option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                              option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            suffixIcon={_.isEmpty(selectedMerchId) ? <CloseCircleFilled style={{ color: 'red'}} /> : null}
                            disabled  = {this.marketingDisabled()}
                          />
                        </Col>
                      </Row>

                      <Row style={{ marginTop: '10px', marginBottom: '10px'}}>
                        <Col lg>
                          <p className="summaryPText">Sign Description</p>
                          <Input
                            id="signDescriptionInput" 
                            showCount 
                            maxLength={128}
                            placeholder="Please Enter Sign Description"
                            onChange={(e) => this.handleSelectionChange(e, 'signDescriptionValue')}
                            value={signDescriptionValue}
                            style={{ width: '100%'}}
                            allowClear={false}
                            status={_.isEmpty(signDescriptionValue) ? "error" : null}
                            suffix={_.isEmpty(signDescriptionValue) ? <CloseCircleFilled /> : null}
                            disabled={this.disabledSign()}
                          />
                        </Col>
                        <Col lg>
                          <p className="summaryPText">Label</p>
                          <Select
                            placeholder="Please Select Label"
                            value={selectedLabelValue}
                            options={this.state.adgroup_labels}
                            onChange={(e) => this.handleSelectionChangeV2(e, 'selectedLabelValue')}
                            style={{ width: '100%'}}
                            allowClear={false}
                            suffixIcon={_.isEmpty(selectedLabelValue) ? <CloseCircleFilled style={{ color: 'red'}} /> : null}
                            showSearch={true}
                            filterOption={(input, option) =>
                              option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                              option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            // disabled = {this.marketingDisabled()}
                          />
                          {selectedLabelValue && this.state.adgroup_labels.map(obj=> obj.label).indexOf(selectedLabelValue)===-1 && (<p style={{ color: 'red'}}>{selectedLabelValue} doesn't exist in promosmart</p>)}
                        </Col>
                        <Col lg>
                          <p className="summaryPText">Unit Of Measure</p>
                          <Select
                            placeholder="Please Select Unit of Measure"
                            value={selected_UOM_Value}
                            options={this.state.adgroup_uom_list}
                            onChange={(e) => this.handleSelectionChangeV2(e, 'selected_UOM_Value')}
                            style={{ width: '100%'}}
                            allowClear={false}
                            suffixIcon={_.isEmpty(selected_UOM_Value) ? <CloseCircleFilled style={{ color: 'red'}} /> : null}
                            // disabled = {this.marketingDisabled()}
                          />
                        </Col>
                        <Col lg>
                          <p className="summaryPText">Subline</p>
                          <Input
                            id="sublineInput" 
                            showCount 
                            maxLength={75}
                            placeholder="Subline"
                            onChange={(e) => this.handleSelectionChange(e, 'sublineValue')}
                            value={sublineValue}
                            style={{ width: '100%'}}
                            allowClear={false}
                            // disabled = {this.marketingDisabled()}
                          />
                        </Col>
                      </Row>

                      <Row style={{ marginTop: '10px'}}>
                        <Col>
                          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '10px'}}>
                            <div className="col-md-3" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                              <p className="summaryPText invisible">text</p>

                              <Button 
                                type="primary" 
                                onClick={() => {
                                  this.setState({ hierarchyBtnClicked: true })
                                }}
                                disabled={!isHierarchyBtnEnabled}
                              >
                                Hierarchy & Attribute Selection
                              </Button>
                            </div>
                          </div>
                        </Col>
                      </Row>
                      
                      <hr style={{height: '1px', borderWidth: '0', backgroundColor: '#d3d3d3'}} />
                      <Row>
                        <Col lg>
                          <Row>
                          </Row>

                          <Row>
                            <Col lg>
                                <Text className="summaryPText">Merch Mgr. Name: </Text>
                                <Text code>{merchantBuyerName}</Text>
                            </Col>
                            <Col sm>
                              <Text className="summaryPText">GMM: </Text>
                              <Text code>{GMM}</Text>
                            </Col>
                            <Col>
                              <Text className="summaryPText">Created By: </Text>
                              <Text code>{CreatedBy}</Text>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <Text className="summaryPText">Created: </Text>
                              <Text code>{Created}</Text>
                            </Col>
                            <Col>
                              <Text className="summaryPText">Changed: </Text>
                              <Text code>{Changed}</Text>
                            </Col>
                            <Col>
                              <Text className="summaryPText">Changed By: </Text>
                              <Text code>{ChangedBy}</Text>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Container>


                  </div>

                  <SkuSelectionMaster 
                    adgroupName={adgroupNameValue}
                    adgroupDescription={adgroupDescriptionValue}
                    selectedAdgroupType={selectedAdgroupTypeValue}
                    merchId={selectedMerchId}
                    signDescription={signDescriptionValue}
                    label={selectedLabelValue}
                    uom={selected_UOM_Value}
                    subline={sublineValue}
                    gmm={GMM}
                    merchantBuyerName={merchantBuyerName}
                    isHierarchyBtnEnabled={(isHierarchyBtnEnabled)}
                    hierarchyBtnClicked={hierarchyBtnClicked}
                    togglePageLoading={this.togglePageLoading}
                    pageLoading={pageLoading}
                    pageType={'edit'}
                    existingAdgroupData={existingAdgroupData}
                  />

                </div>

              </div>
            </div>
          </div>
        </PageLoader>

      </div>
    );
  }
}

EditAdgroup.propTypes = {
};

const mapStateToProps = (state) => ({
  getAdgroup: PropTypes.func.isRequired
});

export default connect(mapStateToProps, {
  getAdgroup,
  getAllHierarchies,
  getAllAttributes,
  getMerchDetails,
  getLabelAndUOM
})(withRouter(EditAdgroup));
