import moment from "moment";
import numeral from "numeral";
import { getBrowserTimeZone, valueFormatterHandler , comparator } from "../../../utils/valueFormatters";

export default {

  defaultColDef: {
    resizable: true,
    // filter: true,
    sortable: true,
  },

  deactivatePromoColdef : [
    {
      headerName: "Adgroup",
      field: "adgroup_name",
      width: 180,
      pinned: "left",
      filter: "agTextColumnFilter",
      suppressSizeToFit: true,
    },
    {
      headerName: "Adgroup Description",
      field: "adgroup_desc",
      filter: "agTextColumnFilter",
      suppressSizeToFit: true,
      width: 250,
      // valueGetter: emptyValueFormatterForString,
    },
    {
      headerName: "Merch Manager ID",
      field: "merch_id",
      filter: "agTextColumnFilter",
      suppressSizeToFit: true,
      // valueGetter: emptyValueFormatterForString,
    },
    {
      headerName: "Shop",
      field: "shop_desc",
      filter: "agTextColumnFilter",
      valueGetter: emptyValueFormatterForString,
      suppressSizeToFit: true,
    },
    {
      headerName: "Event ID",
      field: "event_id",
      filter: "agTextColumnFilter",
      suppressSizeToFit: true,
      width: 250,
      valueGetter: function(params) {
        if(params.data && params.data.event_id){
          const eventId = params.data.event_id.toString(); // Convert event_id to string
          const paddedEventId = eventId.padStart(9, '0'); // Pad with zeros if less than 9 digits
          return paddedEventId;
        }else{
          return '-'
        }
        
      }
    },
    {
      headerName: "Start Date",
      field: "start_date",
      filter: "agTextColumnFilter",
      valueGetter: function (params) {
        // if (params.data.start_date && params.data.rank === "1") {
        if (params.data.start_date) {
          return moment(params.data.start_date).format("MM-DD-YYYY");
        }
        return "-";
      },
      suppressSizeToFit: true,
      sortable: true,
      comparator: function (date1, date2) {
      return comparator(date1, date2, "MM-DD-YYYY");
    },
    },
    {
      headerName: "End Date",
      field: "end_date",
      filter: "agTextColumnFilter",
      valueGetter: function (params) {
        // if (params.data.end_date && params.data.rank === "1") {
        if(params.data.end_date ) {
          return moment(params.data.end_date).format("MM-DD-YYYY");
        }
        return "-";
      },
      suppressSizeToFit: true,
      sortable: true,
      comparator: function (date1, date2) {
      return comparator(date1, date2, "MM-DD-YYYY");
    },
    },
    {
      headerName: "Event",
      field: "event_type",
      filter: "agTextColumnFilter",
      suppressSizeToFit: true,
      // valueGetter: promoTypeFormatter,
    },
    {
      headerName: "UAS",
      field: "uas",
      filter: "agTextColumnFilter",
      suppressSizeToFit: true,
      valueGetter: function(params) {
        if (
          params.data.uas 
        ) {
          return offerFormatValidation(params.data.uas);
        }
        return "-";
      },
      width : 100
    },
    {
      headerName: "Promo Type",
      field: "promo_event_type",
      filter: "agTextColumnFilter",
      suppressSizeToFit: true,
      valueGetter: function(params) {
        if (
          params.data.promo_event_type 
        ) {
          return offerFormatValidation(params.data.promo_event_type);
        }
        return "-";
      },
      width : 150
    },
    {
      headerName: "Promosmart Offer",
      field: "offer",
      filter: "agTextColumnFilter",
      suppressSizeToFit: true,
      // valueGetter: promoTypeFormatter,
    },
    {
      headerName: "Promosmart Offer Type",
      field: "offer_type",
      filter: "agTextColumnFilter",
      suppressSizeToFit: true,
      // valueGetter: promoTypeFormatter,
    },
    // {
    //   headerName: "Total Units Override",
    //   field: "total_override_units",
    //   filter: "agTextColumnFilter",
    //   suppressSizeToFit: true,
    //   filterParams: { defaultOption: "equals" },
    //   editable: false,
    //   valueFormatter: rankCheck,
    //   valueGetter: commonOverrrideValueGetter,
    //   cellStyle: function (params) {
    //     if (params) {
    //       let isAfter = moment(new Date()).isAfter(
    //         params.data.campaign_start_date
    //       );
    //       if (isAfter) {
    //         return {
    //           pointerEvents: "none",
    //         };
    //       }
    //       return {
    //         borderColor: "#d3d3d3",
    //         borderRight: "3px solid #d3d3d3 !important",
    //         borderLeft: "2px solid #d3d3d3 !important",
    //       };
    //     }
    //   },
    //   // cellEditor: "numericEditor"
    // },
    // {
    //   headerName: "Total Sales Override($)",
    //   field: "total_override_sales",
    //   filter: "agTextColumnFilter",
    //   suppressSizeToFit: true,
    //   filterParams: { defaultOption: "equals" },
    //   editable: false,
    //   valueFormatter: function (params) {
    //     // if ((params.value || params.value === 0) && params.data.rank === "1") {
    //     //   let value = params.value;
    //     //   return value === null || value === ""
    //     //     ? ""
    //     //     : value > 0
    //     //       ? "$ " + numeral(value).format("0,0")
    //     //       : 0;
    //     // }
    //     if (params.value || params.value === 0) { //removed rank logic , if in future they ask for rank logic , uncomment the above code
    //       let value = params.value;
    //       return value === null || value === ""
    //         ? ""
    //         : value > 0
    //           ? "$ " + numeral(value).format("0,0")
    //           : 0;
    //     }
    //     return "";
    //   },
    //   valueGetter: commonOverrrideValueGetter,
    //   cellStyle: function (params) {
    //     if (params) {
    //       let isAfter = moment(new Date()).isAfter(
    //         params.data.campaign_start_date
    //       );
    //       if (isAfter) {
    //         return {
    //           pointerEvents: "none",
    //         };
    //       }
    //       return {
    //         borderColor: "#d3d3d3",
    //         borderRight: "3px solid #d3d3d3 !important",
    //         borderLeft: "2px solid #d3d3d3 !important",
    //       };
    //     }
    //   },
    //   // cellEditor: "decimalNumericEditor"
    // },
    // {
    //   headerName: "Total Margin Override($)",
    //   field: "total_override_margin",
    //   filter: "agTextColumnFilter",
    //   suppressSizeToFit: true,
    //   filterParams: { defaultOption: "equals" },
    //   editable: false,
    //   valueFormatter: function (params) {
    //     // if ((params.value || params.value === 0) && params.data.rank === "1") {
    //     //   let value = params.value;
    //     //   return value === null || value === ""
    //     //     ? ""
    //     //     : value > 0
    //     //       ? "$ " + numeral(value).format("0,0")
    //     //       : 0;
    //     // }
    //     if (params.value || params.value === 0) {
    //       let value = params.value;
    //       return value === null || value === ""
    //         ? ""
    //         : value > 0
    //           ? "$ " + numeral(value).format("0,0")
    //           : 0;
    //     }
    //     return "";
    //   },
    //   valueGetter: commonOverrrideValueGetter,
    //   cellStyle: function (params) {
    //     if (params) {
    //       let isAfter = moment(new Date()).isAfter(
    //         params.data.campaign_start_date
    //       );
    //       if (isAfter) {
    //         return {
    //           pointerEvents: "none",
    //         };
    //       }
    //       return {
    //         borderColor: "#d3d3d3",
    //         borderRight: "3px solid #d3d3d3 !important",
    //         borderLeft: "2px solid #d3d3d3 !important",
    //       };
    //     }
    //   },
    //   width : 250
    //   // cellEditor: "decimalNumericEditor"
    // },
    // {
    //   headerName: "Total GM Override(%)",
    //   field: "gm_override",
    //   filter: "agTextColumnFilter",
    //   suppressSizeToFit: true,
    //   valueFormatter: function (params) {
    //     if (
    //       // !params.data.rank !== "1" || !params.data.total_override_units
    //       !params.data.total_override_units
    //       ) {
    //       return "";
    //     } else if (
    //       // (params.data.gm_override || params.data.gm_override === 0) &&
    //       // params.data.rank === "1"
    //       (params.data.gm_override || params.data.gm_override === 0) 
    //     ) 
    //     {
    //       let sign = Math.sign(params.data.gm_override);
    //       let valueVar = Math.round(params.data.gm_override * 100) / 100;
    //       return sign === 1 || sign === 0
    //         ? numeral(valueVar).format("0.00") + "%"
    //         : numeral(valueVar).format("(0.00)") + "%";
    //     } else if (!params.data.gm_override) {
    //       return "";
    //     }
    //     return "-";
    //   },
    //   filterParams: { defaultOption: "equals" },
    //   editable: false,
    //   cellStyle: function (params) {
    //     if (params) {
    //       let isAfter = moment(new Date()).isAfter(
    //         params.data.campaign_start_date
    //       );
    //       if (isAfter) {
    //         return {
    //           pointerEvents: "none",
    //         };
    //       }
    //       return {
    //         borderColor: "#d3d3d3",
    //         borderRight: "3px solid #d3d3d3 !important",
    //         borderLeft: "2px solid #d3d3d3 !important",
    //       };
    //     }
    //   },
    //   // cellEditor: "decimalNumericEditor"
    // },
    {
      headerName: "Deactivated By",
      field: "deactivated_by",
      filter: "agTextColumnFilter",
      suppressSizeToFit: true,
      // valueGetter: promoTypeFormatter,
    },
    {
      headerName: "Deactivated At",
      field: "deactivated_at",
      filter: "agTextColumnFilter",
      suppressSizeToFit: true,
      valueGetter: function (params) {
        if (params.data.deactivated_at) {
          return getBrowserTimeZone(params.data.deactivated_at);
        }
        return "-";
      },
      sortable: true,
      comparator: function (date1, date2) {
        return comparator(date1, date2, "MM-DD-YYYY HH:mm:ss");
      },
      // valueGetter: promoTypeFormatter,
    }
  ]
}



function commonOverrrideValueGetter(params) {
  // if (params.data.rank === "1") {
  //   let field = params.colDef.field,
  //     value = field ? params.data[field] : "";

  //   return value === null || value === ""
  //     ? ""
  //     : value > 0
  //       ? numeral(value).format("0")
  //       : "0";
  // } else {
  //   return "";
  // }
    let field = params.colDef.field,
      value = field ? params.data[field] : "";

    return value === null || value === ""
      ? ""
      : value > 0
        ? numeral(value).format("0")
        : "0";
}

function rankCheck(params) {
  if (
    // (params.value || params.value === 0) && params.data.rank === "1") 
    params.value || params.value === 0
    ) 
    {
    let value = params.value;
    let sign = Math.sign(value);
    let valueVar = Math.round(value * 100) / 100;

    return value === null || value === ""
      ? "-"
      : (sign === 1 || sign === 0) && valueVar !== 0
        ? numeral(valueVar).format("0,0")
        : numeral(valueVar).format("(0,0)");
  }
  return "-";
}

function emptyValueFormatterForString(params) {
  if (params.data[params.colDef.field]) {
    return params.data[params.colDef.field];
  } else {
    return "-";
  }
}

function offerFormatValidation(offerValue, offerType) {
  // if (offerValue.includes("$") && offerValue.includes("OFF")) {
  //   return offerValue.replace("OFF", " OFF");
  // }
  // if (offerValue.includes("$ ") && offerValue.includes("FOR")) {
  //   let val = offerValue.replace("$ ", "$");
  //   return offerValue.includes(".00") ? val.replace(".00", "") : val;
  // }
  // if (offerValue.includes(".00")) {
  //   return offerValue.replace(".00", "");
  // }
  // if (offerValue.includes(".")) {
  //   //'$2.5' to '$2.50' case handling
  //   let splitArr = offerValue.split(".");
  //   if (splitArr[1].length === 1) {
  //     return offerValue + "0";
  //   }
  // }
  ///////////////////////////////////////////////////////////////////////////////
  ////////////all $ values converted to float values with 2 decimal points //////
  //////////// $3 will show as $3.00 and $3.5 will show as $3.50/////////////////
  ////////////applies for OFF and FOR case as well //////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////
  if (offerValue.includes("$") && offerValue.includes("OFF") && offerType == 'C') {
    return offerValue
  }
  if (offerValue.includes("$") && offerValue.includes("OFF")) {
    let splitArr = offerValue.split("O");
    let newVal = splitArr[0] && splitArr[0].replace("$", "");
    return "$" + numeral(newVal).format("0.00") + " OFF";
  }
  if (offerValue.includes("$") && offerValue.includes("FOR")) {
    let splitArr = offerValue.split("$");
    let newVal = splitArr[0] + "$" + numeral(splitArr[1]).format("0.00");
    return newVal.includes("$ ") ? newVal.replace("$ ", "$") : newVal;
  }
  if (
    offerValue.includes("$") &&
    !offerValue.includes("OFF") &&
    !offerValue.includes("FOR")
  ) {
    let splitArr = offerValue.split("$");
    return splitArr[1] ? "$" + numeral(splitArr[1]).format("0.00") : offerValue;
  }
  return offerValue;
}


