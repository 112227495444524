import axios from "../../utils/axios/index";
import { config } from "../../utils/config";
import {
  LOADER,
  SET_AGDAR_REPORTS_DATA,
  SET_AGPAR_REPORTS_DATA,
  SET_BUYER_OPTIONS,
  SET_ADGROUP_OPTIONS,
  SET_THEME_OPTIONS,
  SET_PRICE_LIST_OPTIONS,
  SET_EVENT_OPTIONS,
  SET_GMM_OPTIONS,
  SET_ARTICLE_OPTIONS
} from "./types";
import {uniq,flatten} from 'lodash'

export const getAgparReportData = payload => async dispatch => {
  dispatch({
    type: LOADER,
    payload: true,
  });

  const reqObject = { flag: "1" };
  const response = await axios
    .post(`${config.baseUrl}/reports/getAGPARReport`, payload)
    .then(res => {

      dispatch({
        type: SET_AGPAR_REPORTS_DATA,
        payload: res.data.data
      })
      dispatch({
        type: LOADER,
        payload: false,
      });
      return res.data.data;
    })
    .catch(err => {
      dispatch({
        type: LOADER,
        payload: false,
      });
      return err;
    });

  return response;
}

export const getAgdarReportData = payload => async dispatch => {

   dispatch({
    type: LOADER,
    payload: true,
  });

  const reqObject = { flag: "1" };
  const response = await axios
    .post(`${config.baseUrl}/reports/getAgdarReport`, payload)
    .then(res => {

      dispatch({
        type: SET_AGDAR_REPORTS_DATA,
        payload: res.data.data
      })
      dispatch({
        type: LOADER,
        payload: false,
      });
      return res.data.data;
    })
    .catch(err => {
      dispatch({
        type: LOADER,
        payload: false,
      });
      return err;
    });
  return response;
}


export const getAdGroupDetails = payload => async dispatch => {
  dispatch({
    type: LOADER,
    payload: true,
  });

  const response = await axios
    .get(`${config.baseUrl}/reports/getAdgroups`,payload)
    .then(res => {

      dispatch({
        type: SET_ADGROUP_OPTIONS,
        payload: res.data,
      });

      dispatch({
        type: LOADER,
        payload: false,
      });
      return res.data;
    })
    .catch(err => {
      dispatch({
        type: LOADER,
        payload: false,
      });
      return err;
    });
  return response;
}


export const getThemeDetails = payload => async dispatch => {
  dispatch({
    type: LOADER,
    payload: true,
  });

  const response = await axios
    .get(`${config.baseUrl}/reports/getThemes`,payload)
    .then(res => {

      dispatch({
        type: SET_THEME_OPTIONS,
        payload: res.data,
      });

      dispatch({
        type: LOADER,
        payload: false,
      });
      return res.data;
    })
    .catch(err => {
      dispatch({
        type: LOADER,
        payload: false,
      });
      return err;
    });
  return response;
}

export const getPriceListDetails = payload => async dispatch => {
  dispatch({
    type: LOADER,
    payload: true,
  });

  const response = await axios
    .get(`${config.baseUrl}/reports/getPriceList`,payload)
    .then(res => {

      dispatch({
        type: SET_PRICE_LIST_OPTIONS,
        payload: res.data,
      });

      dispatch({
        type: LOADER,
        payload: false,
      });
      return res.data;
    })
    .catch(err => {
      dispatch({
        type: LOADER,
        payload: false,
      });
      return err;
    });
  return response;
}

export const getArticleDetails = payload => async dispatch => {
  dispatch({
    type: LOADER,
    payload: true,
  });

  const response = await axios
    .post(`${config.baseUrl}/reports/getArticle`,payload)
    .then(res => {
      console.log("payload",payload);
      const result = res.data.articleIds.map(obj=> obj.unique_id1)
      dispatch({
        type: SET_ARTICLE_OPTIONS,
        // payload: res.data,
        payload: uniq(flatten(result))
      }); 

      dispatch({
        type: LOADER,
        payload: false,
      });
      return res.data;
    })
    .catch(err => {
      dispatch({
        type: LOADER,
        payload: false,
      });
      return err;
    });
  return response;
}


export const getEventIdDetails = payload => async dispatch => {
  dispatch({
    type: LOADER,
    payload: true,
  });

  const response = await axios
    .get(`${config.baseUrl}/reports/getEventIds`,payload)
    .then(res => {

      dispatch({
        type: SET_EVENT_OPTIONS,
        payload: res.data,
      });

      dispatch({
        type: LOADER,
        payload: false,
      });
      return res.data;
    })
    .catch(err => {
      dispatch({
        type: LOADER,
        payload: false,
      });
      return err;
    });
  return response;
}

export const getGmmDetails = payload => async dispatch => {
  dispatch({
    type: LOADER,
    payload: true,
  });

  const response = await axios
    .get(`${config.baseUrl}/reports/getGmm`,payload)
    .then(res => {

      dispatch({
        type: SET_GMM_OPTIONS,
        payload: res.data,
      });

      dispatch({
        type: LOADER,
        payload: false,
      });
      return res.data;
    })
    .catch(err => {
      dispatch({
        type: LOADER,
        payload: false,
      });
      return err;
    });
  return response;
}

export const getBuyerDetails = payload => async dispatch => {
  dispatch({
    type: LOADER,
    payload: true,
  });

  const response = await axios
    .post(`${config.baseUrl}/promo/getMerchantDetails`, payload)
    .then(res => {

      dispatch({
        type: SET_BUYER_OPTIONS,
        payload: res.data,
      });

      dispatch({
        type: LOADER,
        payload: false,
      });
      return res.data;
    })
    .catch(err => {
      dispatch({
        type: LOADER,
        payload: false,
      });
      return err;
    });
  return response;
}

