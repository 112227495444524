import React from "react";
import { withRouter } from "react-router-dom";
import DataTable from "../../../tables";
import ColumnDef from "../../../tables/colDefs/CouponColDefs";
import couponChildMsgRenderer from "./couponChildMsgRendrer";
import _ from "lodash";
import moment from "moment";
import { PromotionCalendarGA } from '../../../../utils/GA/GAUtils';

export class CouponPipeline extends React.PureComponent {
  state = {
    couponTableExport: false,
    couponTableData: []
  };

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.tableData &&
      nextProps.tableData !== this.props.tableData
    ) {
      let couponTableData = {
        header:
          new Date(this.props.campaignStartDate) >= new Date()
            ? ColumnDef.couponHeader
            : ColumnDef.pastCouponHeader,
        defaultColDef: ColumnDef.defaultColDef,
        data: nextProps.tableData
      };

      this.setState({
        couponTableData
      });
    }
  }
  submitGA(action, label) {
    PromotionCalendarGA({ action, label });
  }
  render() {
    return (
      <>
        {/* {this.props.pipelineDataAdGroup &&
        this.props.pipelineDataAdGroup.data ? ( */}
        <div className="row pipelineCopydiv">
          <div className="col-md-8">
            <div className="row" style={{ marginLeft: "12px" }}>
              <div className="col-md-5">
                <span className="promoNameText row">
                  Promotion Name:&nbsp;&nbsp;
                  {this.props.calendarDataPerYear !== undefined &&
                    this.props.calendarDataPerYear.length > 0 ? (
                    <p className="promonameResult">
                      {/* {this.props.promotionName} */}
                      {`${moment(this.props.campaignStartDate).format("MM/DD/YYYY")} - ${moment(this.props.campaignEndDate).format("MM/DD/YYYY")}`}
                    </p>
                  ) : null}
                </span>
              </div>
              <div className="col-md-2">
                <span
                  className="promoNameText row"
                  style={{ paddingLeft: "2%" }}
                >
                  Start Date:
                  {this.props.calendarDataPerYear !== undefined &&
                    this.props.calendarDataPerYear.length > 0 ? (
                    <p className="promonameResult">
                      {moment(this.props.campaignStartDate).format("MM/DD/YYYY")}
                    </p>
                  ) : null}
                </span>
              </div>
              <div className="col-md-2">
                <span
                  className="promoNameText row"
                  style={{ paddingLeft: "2%" }}
                >
                  End Date:
                  {this.props.calendarDataPerYear !== undefined &&
                    this.props.calendarDataPerYear.length > 0 ? (
                    <p className="promonameResult">
                      {moment(this.props.campaignEndDate).format("MM/DD/YYYY")}
                    </p>
                  ) : null}
                </span>
              </div>
              <div className="col-md-2">
                <span
                  className="promoNameText row"
                  style={{ paddingLeft: "2%" }}
                >
                  # of days:
                  {this.props.calendarDataPerYear !== undefined &&
                    this.props.calendarDataPerYear.length > 0 ? (
                    <p className="promonameResult">
                      {this.props.diffDays ? this.props.diffDays : ""}
                    </p>
                  ) : null}
                </span>
              </div>
            </div>
          </div>
          <div className="col-md-4 text-right">
            <button
              type="button"
              title="Create coupon"
              className="btn pipeline-button-class"
              disabled={moment(this.props.campaignStartDate).isSameOrBefore(
                moment()
              )}
              onClick={e => {
                e.preventDefault();
                localStorage.setItem(
                  "campStartDate",
                  this.props.campaignStartDate
                );
                localStorage.setItem("campEndDate", this.props.campaignEndDate);
                localStorage.setItem("edit_coupon_id", null);
                this.props.history.push(`/createcoupon`);
                this.submitGA("Click", "Clicked_CreateCouponButton");
              }}
            >
              <i className="fa fa-plus" aria-hidden="true" />
            </button>

            <button
              type="button"
              title="Download"
              className="btn pipeline-button-class"
              disabled={_.isEmpty(this.props.tableData) ? true : false}
              onClick={() => this.setState({ couponTableExport: true })}
            >
              <i className="fa fa-download" aria-hidden="true" />
            </button>
          </div>
        </div>
        {/* ) : null} */}
        <div className="pipeline-grid-div">
          <DataTable
            parentId="myGridForPipeline"
            parentStyle={{
              height: !_.size(this.props.tableData)
                ? "calc(100vh - 400px)"
                : _.size(this.props.tableData) <= 7 &&
                  _.size(this.props.tableData)
                  ? "calc(100vh - 450px)"
                  : "calc(100vh - 380px)",
              width: "100%",
              position: "relative"
            }}
            parentClass="ag-theme-balham"
            tableData={this.state.couponTableData}
            suppressRowClickSelection={true}
            overlayNoRowsTemplate='<span class="pipeline-span">No Data to show</span>'
            autoSizeCol={true}
            couponTableExport={this.state.couponTableExport}
            couponTableExportHandler={() =>
              this.setState({ couponTableExport: false })
            }
            startDate={this.props.campaignStartDate}
            endDate={this.props.campaignEndDate}
            frameworkComponents={{
              childMessageRenderer: couponChildMsgRenderer
            }}
          />
        </div>
      </>
    );
  }
}

export default withRouter(CouponPipeline);
