import React, { useEffect, useState } from "react";
import Modal from "react-awesome-modal";
import "./dayLevelOfferStyling.css";

const ViewNameModal = (props) => {
  const [viewName, setViewName] = useState(props.savedViewSelected && props.savedViewSelected.value);
  const [infoMsg, setInfoMsg] = useState('');
  const [infoCode, setInfoCode] = useState("");
  const [disableSave, setDisableSave] = useState(false);
  const [override, setOverride] = useState(false);

  const setUserInfoMsgs = (info, code, saveStatus) => {
    setInfoMsg(info);
    setInfoCode(code)
    setDisableSave(saveStatus);
  }

  useEffect(() => {
    if (!(viewName)) { // Validate no view name
      setUserInfoMsgs("*Create new view", "green", true);
    } else if (props.savedViewSelected) { // Saved view selected
      if (viewName == props.savedViewSelected.value) {
        setUserInfoMsgs("*Editing existing saved view", "orange", false);
      } else if (viewName !== props.savedViewSelected.value) {
        setUserInfoMsgs("*Create new view", "green", false);
        if (props.listSavedViews.filter((item) => item.value === viewName).length > 0) {
          setUserInfoMsgs("*View name already exists", "red", true);
          // Validate view name is not equal to existing saved view names
          // but can be equal to the selected saved view name
        }
      }
    } else if (!props.savedViewSelected) { // Save view in new flow
      if (!(props.listSavedViews.filter((item) => item.value === viewName).length === 0)) {
        setUserInfoMsgs("*View name already exists", "red", true);
        // Validate view name is not equal to existing saved view names
      } else {
        setUserInfoMsgs("*Create new view", "green", false);
      }
    }
    if (override && viewName) {
      setDisableSave(false);
    }
    if(infoCode !== "red"){
      setOverride(false);
    }
  })
  const viewNameChange = (e) => {
    let inputName = e.target.value;
    setViewName(inputName.trim());
  }
  const overrideSavedView = () => {
    setOverride(!override);
  }
  return (
    <div>
      <Modal
        visible={true}
        effect="fadeInDown"
        width="34%"
        height="250px"
        style={{
          display: "flex",
          flexDirection: "column"
        }}
        onClickAway={() => props.closeViewNameModal()}
      >
        <span className="pull-right select-store-span cross-btn">
          <i
            style={{ cursor: "pointer", fontSize: "1.35em" }}
            className="fa fa-times"
            onClick={() => props.closeViewNameModal()}
            aria-hidden="true"
          />
        </span>
        <h4 className="view-name">Enter View Name</h4>
        <input
          className="input-name"
          type="text"
          name="saveViewName"
          onChange={(e) => viewNameChange(e)}
          value={viewName}
          maxlength="30"
        />

        <div className="savename-footer">
          <div style={{
            color: `${infoCode}`,
            fontSize: "1em",
            marginLeft: "12.5%",
            marginTop: "0.5%",
            marginBottom: "1%",
            fontWeight: "bold"
          }}>
            {infoMsg}</div>
          {infoCode === "red" ?
            <>
              <span style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginBottom: "1%",
              }}>
                <input
                  className="overrideRadioButton"
                  type="radio"
                  onClick={() => overrideSavedView()}
                  checked={override}
                />
                <div style={{
                  color: "#2d69eb",
                  fontSize: "1em",
                  fontWeight: "bold"
                }}>Do you want to override ?</div>
              </span>

            </> : null
          }
          <div className="text-center">
            <button
              className="btn btn-primary mr-3"
              style={{ width: "120px" }}
              onClick={() => props.closeViewNameModal()}
            >
              Cancel
            </button>
            <button
              className="btn btn-primary ml-3"
              style={{ width: "120px" }}
              onClick={() => props.saveViewNameModal(viewName)}
              disabled={disableSave}
            >
              Save
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};
export default ViewNameModal;
