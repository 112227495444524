import React, { Component } from "react";
import moment from "moment";
import { PromotionCalendarGA } from '../../../../utils/GA/GAUtils';

export class ChildMessageRenderer extends Component {
  showComments = () => {
    this.props.data.getfun2({
      promoId: [this.props.data.promo_id],
      adgroup_name: [this.props.data.adgroup_name],
      offer_name: [this.props.data.offer],
    });
    this.props.data.getfun3();
  };
  editPipelineCell = () => {
    this.props.data.getfun4(this.props.data);
  };
  viewSku = () => {
    this.props.data.viewSku({
      promoId: [this.props.data.promo_id],
      adgroup_name: [this.props.data.adgroup_name],
      offer_name: [this.props.data.offer],
    });
  };
  submitGA(action,label){
    PromotionCalendarGA({ action, label});
  }

  render() {
    return this.props.data &&
      (new Date(this.props.data.campaign_start_date) <
        new Date(moment().format("MM-DD-YYYY")) && moment(new Date()).isAfter(this.props.data.start_date)) &&
      this.props.data.rank === "1" || this.props.data.disableDeeperOfferBtn? (
      <span>
        <i
          className="fa fa-save actionIcons"
          style={{
            color: "gray",
            pointerEvents: "none",
            fontSize: "15px",
          }}
          title="Save"
          onClick={() => {
            this.editPipelineCell();
            this.submitGA("Click", "Clicked_SaveCommentButton");
          }}
        />
        <i
          data-toggle="popover2"
          data-target="#myModal"
          title="Comments"
          disabled={(this.props.data.promo_id === null || this.props.data.disableDeeperOfferBtn) ? true : false}
          data-trigger="hover"
          className="fa fa-comments"
          style={{
            color:
              this.props.data.promo_id === null || this.props.data.disableDeeperOfferBtn
                ? "grey"
                : parseInt(this.props.data.comment_count) > 0
                ? "#357ef2"
                : "#5d5d5d",

            cursor: "pointer",
            marginLeft: "15px",
            fontSize: "15px",
            pointerEvents: this.props.data.promo_id === null 
             || this.props.data.disableDeeperOfferBtn ? "none" : "auto",
          }}
          onClick={() => {
            this.showComments();
            this.submitGA("Click", "Clicked_CommentsButton");
          }}
        />
      </span>
    ) : (
      <span>
        <i
          className="fa fa-save actionIcons"
          style={
            localStorage.getItem("role") === "3" ||
            localStorage.getItem("role") === "5" ||
            this.props.data.rank !== "1"
              ? {
                  color: "gray",
                  pointerEvents: "none",
                  fontSize: "15px",
                }
              : { fontSize: "15px" }
          }
          title="Save"
          onClick={() => {
            this.editPipelineCell();
            this.submitGA("Click", "Clicked_SaveCommentButton");
          }}
        />
        <i
          data-toggle="popover2"
          data-target="#myModal"
          title="Comments"
          data-trigger="hover"
          className="fa fa-comments"
          style={{
            color:
              this.props.data.promo_id === null  || this.props.data.disableDeeperOfferBtn
                ? "grey"
                : parseInt(this.props.data.comment_count) > 0
                ? "#357ef2"
                : "#5d5d5d",
            cursor: "pointer",
            fontSize: "15px",
            pointerEvents: this.props.data.promo_id === null  || this.props.data.disableDeeperOfferBtn? "none" : "auto",
            marginLeft: "15px",
          }}
          onClick={() => {
            this.showComments();
            this.submitGA("Click", "Clicked_CommentsButton");
          }}
        />
      </span>
    );
  }
}

export default ChildMessageRenderer;
