import {
  COMPARE_FILTERS,
  COMPARE_FILTERS_OFFER,
  COMPARE_DATA,
  COMPARE_PROMO_TYPE,
  COMPARE_GRAPH,
  COMPARE_SHOP,
  LOADER
} from "../actions/types";
const initialState = {
  compareFilters: [],
  compareFiltersOffer: [],
  compareFiltersPromoType: [],
  compareFiltersShop: [],
  compareData: [],
  compareGraph: [],
  loader: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case COMPARE_FILTERS:
      return {
        ...state,
        compareFilters: action.payload
      };

    case COMPARE_FILTERS_OFFER:
      return {
        ...state,
        compareFiltersOffer: action.payload
      };

    case COMPARE_DATA:
      return {
        ...state,
        compareData: action.payload
      };

    case COMPARE_GRAPH:
      return {
        ...state,
        compareGraph: action.payload
      };

    case COMPARE_PROMO_TYPE:
      return {
        ...state,
        compareFiltersPromoType: action.payload
      };

    case COMPARE_SHOP:
      return {
        ...state,
        compareFiltersShop: action.payload
      };

    case LOADER:
      return {
        ...state,
        loader: action.payload
      };
    default:
      return {
        ...state
      };
  }
}
