import axios from 'axios';

const axiosInstance  = axios.create()

const requestHandler = (request) => {
    request.headers['token'] = localStorage.getItem("token");
    return request;
  }

const errorHandler = (error) => {
    if (error.response && error.response.status === 401) {
        // if(error.response && data && error.response.data.redirect){
        //     // window.location.href = error.response.data.url; 
        //     window.history.pushState({urlPath:'/sessiontimedout'},"",'/sessiontimedout');
        //     window.location.reload();
        // } else{
            let data = error.response.data;
            let url = `/sessiontimedout?msg=${data.msg}&heading=${data.heading}&redirect_url=${data.redirect_url}`;
            window.history.pushState({urlPath: url}, "", url);
            window.location.reload();
        // }
    }
    // window.history.pushState({urlPath:'/error'},"",'/error');
    // window.location.reload();
    // if (isHandlerEnabled(error.config)) {
    //     // Handle errors
    // }
    return Promise.reject({ ...error })
}

const successHandler = (response) => {
    // if (!response.data.status && response.data.msg === "Invalid Token") {
    //     window.history.pushState({urlPath:'/sessiontimedout'},"",'/sessiontimedout');
    //     window.location.reload();
    // }
    return response;
}
axiosInstance.interceptors.request.use(
  request => requestHandler(request)
)
axiosInstance.interceptors.response.use(
    response => successHandler(response),
    error => errorHandler(error)
)

export default axiosInstance;