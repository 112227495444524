import React from "react";
import moment from "moment";
import { Radio } from 'antd';
import { isIt } from "../../../../utils/getRoleName";

const PromoActionRenderer = props => {
  let event_end_date_time; 
  if(props && props.data && props.data.event_end_date_time){
    event_end_date_time = props.data.event_end_date_time;
  }
  let expiredEvent = 0;
  if(props && props.data && props.data.event_end_date_time){
    const parsedDate = moment(event_end_date_time, 'YYYY-MM-DD HH:mm:ss')
    const currentDate = moment();
    expiredEvent = parsedDate.isBefore(currentDate);
  }

  const handleChange = (e) => {
    props.data.handleApproveReject(e.target.value, props.data, props.node.id);
  };
  return (
    <>
      <Radio.Group onChange={handleChange} value={props.value} disabled={props.value == "ACCEPTED" || props.value == "REJECTED" || !(props.data && props.data.allow_approval) || (isIt()) || (props && props.data && !props.data.event_status) || (expiredEvent) || (props.data.is_promo_deactivated) }>
        <Radio value={"ACCEPTED"}>{props.value == "ACCEPTED" ? "Approved" : "Approve"}</Radio>
        <Radio value={"REJECTED"}>{props.value == "REJECTED" ? "Rejected" : "Reject"}</Radio>
      </Radio.Group>
    </>
  )
}

export default PromoActionRenderer;
