import React, { Component } from "react";
import { connect } from "react-redux";
import Modal from "react-awesome-modal";
import PageLoader from "../../../../utils/Loader";
import DataTable from "../../../tables";
import ColumnDef from "../../../tables/colDefs/PipelineColDefs";
import moment from "moment";
import AlertSuccess from "../../../../utils/AlertMessage/alertSuccess";
import AlertDanger from "../../../../utils/AlertMessage/alertDanger";
import _ from "lodash";
import { saveUserComment } from "../../../../redux/actions/calendarAction";
import { userCommentsProducts } from "../../../../redux/actions/userAction";
import PipelineViewSkuModal from "./PipelineViewSkuModal";
import CommentChangeLogsRenderer from "./CommentChangeLogsRenderer";
import { updateProductsWithAdgroupAsync } from "../../../../redux/actions/adgroup";
import { PromotionCalendarGA } from '../../../../utils/GA/GAUtils';
import { alertTimer } from "../../../../utils/alertTimer";
import { isIt } from "../../../../utils/getRoleName";

class UserCommentsModal extends Component {
  state = {
    commentTextArea: "",
    alertDanger: false,
    alertSuccess: false,
    showLoader: false,
    showLoaderPopup: false,
    showPipelineViewSkuModal: false,
    frameworkComponents: {
      commentChangeLogsRenderer: CommentChangeLogsRenderer
    },
    skuTableDataCurrent: [],
    skuTableDataPrevious: []
  };
  addCommentHandler = async () => {
    this.setState({
      showLoader: true
    });
    let res = await this.props.saveUserComment({
      commentText: this.state.commentTextArea,
      promoId: this.props.commentPromoId[0].promoId,
      userId: localStorage.getItem("userid")
    });
    if (res.status) {
      this.setState({ alertSuccess: true, showLoader: false });
      this.props.commentListCall(this.props.commentPromoId, true);
      setTimeout(() => {
        this.setState({ alertSuccess: false });
      }, alertTimer);
      return;
    }
    this.setState({ alertDanger: true, showLoader: false });
    setTimeout(() => {
      this.setState({ alertDanger: false });
    }, alertTimer);
  };

  pipelineViewSkuModal = async val => {
    this.setState({
      showPipelineViewSkuModal: true,
      showLoaderPopup: true
    });
    let res = await this.props.userCommentsProducts({
      comment_id: val.comment_id
      // comment_id: 212
    });

    if (res.status) {
      let previousSkuTableData = await this.props.updateProductsWithAdgroupAsync(
        {
          sku: res.data[0].old_product_list
        }
      );

      let currentSkuTableData = await this.props.updateProductsWithAdgroupAsync(
        {
          sku: res.data[0].new_product_list
        }
      );

      if (previousSkuTableData.status && currentSkuTableData.status) {
        this.setState({
          skuTableDataCurrent: currentSkuTableData.data.length
            ? currentSkuTableData.data
            : [],
          skuTableDataPrevious: previousSkuTableData.data.length
            ? previousSkuTableData.data
            : [],
          showLoaderPopup: false
        });
      }
    }
  };
  closeSkuModal = () => {
    this.setState({
      showPipelineViewSkuModal: !this.state.showPipelineViewSkuModal
    });
  };
  submitGA(action,label){
    PromotionCalendarGA({ action, label});
  }
  render() {
    let tableData1 = {
      header: ColumnDef.userCommentHeader,
      defaultColDef: ColumnDef.userCommentDefaultColDef,
      data:
        this.props.promoUserComments !== undefined &&
        this.props.promoUserComments.length > 0
          ? this.props.promoUserComments.map(commentsData => {
              return {
                adgroupName: commentsData.adgroup_name,
                offer_type: commentsData.offer_name,
                offer_source: commentsData.offer_source,
                userName: commentsData.email,
                comment_text:
                  commentsData.comment_text === "undefined"
                    ? "--"
                    : commentsData.comment_text,
                date: moment(commentsData.created_at).format(
                  "MM-DD-YYYY h:mm a"
                ),
                comment_id: commentsData.comment_id,
                comment_type: commentsData.comment_type,
                viewSku: this.pipelineViewSkuModal
              };
            })
          : []
    };
    return (
      <>
        <div className="alertDivs">
          {this.state.alertDanger ? (
            <div>
              <AlertDanger
                message="Something went wrong!"
                show={this.state.alertDanger}
              />
            </div>
          ) : null}
          {this.state.alertSuccess ? (
            <div>
              <AlertSuccess
                message="New comment added successfully"
                show={this.state.alertSuccess}
              />
            </div>
          ) : null}
        </div>
        <Modal
          visible={this.props.showModelOpen}
          width="800"
          height="500"
          effect="fadeInDown"
          onClickAway={() => this.props.closeModal()}
        >
          <PageLoader loader={this.props.loaderAg || this.state.showLoader}>
            <div className="closeicon">
              <span style={{ cursor: "pointer" }}>
                <i
                  className="fa fa-times"
                  aria-hidden="true"
                  onClick={() => this.props.closeModal()}
                />
              </span>
              <div className="row offerDetails">
                <>
                  <div className="col-md-4">
                    <span className="promoNameText row">
                      Adgroup Name:
                      {this.props.promoUserComments !== undefined &&
                      this.props.promoUserComments.length > 0 ? (
                        <p className="promonameResult">
                          {this.props.promoUserComments[0].adgroup_name}
                        </p>
                      ) : (
                        <p className="promonameResult">
                          {this.props.commentPromoId[0].adgroup_name}
                        </p>
                      )}
                    </span>
                  </div>
                  <div className="col-md-5">
                    <span
                      className="promoNameText row"
                      style={{ paddingLeft: "2%" }}
                    >
                      Offer:
                      {this.props.promoUserComments !== undefined &&
                      this.props.promoUserComments.length > 0 ? (
                        <p className="promonameResult">
                          {this.props.promoUserComments[0].offer_name}
                        </p>
                      ) : (
                        <p className="promonameResult">
                          {this.props.commentPromoId[0].offer_name}
                        </p>
                      )}
                    </span>
                  </div>
                </>
              </div>
            </div>

            <div className="myagGrid1" style={{ height: "75%" }}>
              <DataTable
                tableData={tableData1}
                parentId="myGridForcomments"
                animateRows={true}
                autoSizeCol={true}
                frameworkComponents={this.state.frameworkComponents}
              />

              <div className="row mt-5 align-items-end">
                <div className="col-md-9">
                  <textarea
                    id="w3mission"
                    className="w-100"
                    // rows="3"
                    // cols="50"
                    style={{ lineHeight: "1.2", padding: "12px" }}
                    value={this.state.commentTextArea}
                    onChange={e => {
                      this.setState({
                        commentTextArea: e.target.value
                      });
                    }}
                    disabled={isIt()}
                  />
                </div>
                <div className="col-md-3 pb-3">
                  <button
                    type="button"
                    className="btn btn-outline-primary promo3Buttons btGroup1 mb-0"
                    onClick={() => {
                      this.addCommentHandler();
                      this.submitGA("Click", "Clicked_AddComment");
                    }}
                    disabled={(isIt()) || _.isEmpty(this.state.commentTextArea)}
                  >
                    Add comment
                  </button>
                </div>
              </div>
            </div>
          </PageLoader>
        </Modal>

        {this.state.showPipelineViewSkuModal ? (
          <PipelineViewSkuModal
            showLoaderPopup={this.state.showLoaderPopup}
            showModelOpen={this.state.showPipelineViewSkuModal}
            closeModal={this.closeSkuModal}
            promoIdData={this.props.commentPromoId}
            skuTableDataCurrent={this.state.skuTableDataCurrent}
            skuTableDataPrevious={this.state.skuTableDataPrevious}
          />
        ) : null}
      </>
    );
  }
}

const mapStateToProps = state => ({});

export default connect(mapStateToProps, {
  saveUserComment,
  userCommentsProducts,
  updateProductsWithAdgroupAsync
})(UserCommentsModal);
