import { CREATEPROMO_DATA, CAMPAIGNS_DATA, LOADER } from "../actions/types";
const initialState = {
  createPromoData: {},
  campaignsList: {},
  loader: false
};

export default function (state = initialState, action) {
  switch (action.type) {
    case CREATEPROMO_DATA:
      return {
        ...state,
        createPromoData: action.payload,
      };
    case LOADER:
      return {
        ...state,
        loader: action.payload,
      };
    case CAMPAIGNS_DATA:
      return {
        ...state,
        campaignsList: action.payload,
      };

    default:
      return state;
  }
}
