
import axios from "../../utils/axios/index";
import { config } from "../../utils/config";
import {
  COMPARE_FILTERS,
  COMPARE_FILTERS_OFFER,
  GET_ERRORS,
  LOADER,
  COMPARE_DATA,
  COMPARE_GRAPH,
  COMPARE_PROMO_TYPE,
  COMPARE_SHOP
} from "./types";

export const getCompareFilters = reqObject => dispatch => {
     
  dispatch({
    type: LOADER,
    payload: true
  });
  axios
    .post(`${config.baseUrl}/compare/compareFilters`, reqObject)
    .then(res => {
      dispatch({
        type: COMPARE_FILTERS,
        payload: res.data
      });
      dispatch({
        type: LOADER,
        payload: false
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err
      });
    });
};

export const getCompareFiltersOffer = reqObject => dispatch => {
     
  dispatch({
    type: LOADER,
    payload: true
  });
  axios
    .post(`${config.baseUrl}/compare/compareFilters`, reqObject)
    .then(res => {
      dispatch({
        type: COMPARE_FILTERS_OFFER,
        payload: res.data
      });
      dispatch({
        type: LOADER,
        payload: false
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err
      });
    });
};

export const getCompareData = reqObject => dispatch => {
     
  dispatch({
    type: LOADER,
    payload: true
  });
  axios
    .post(`${config.baseUrl}/compare/compareData`, reqObject)
    .then(res => {
      dispatch({
        type: COMPARE_DATA,
        payload: res.data
      });
      dispatch({
        type: LOADER,
        payload: false
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err
      });
    });
};

export const getCompareGraph = reqObject => dispatch => {
     
  dispatch({
    type: LOADER,
    payload: true
  });
  axios
    .post(`${config.baseUrl}/compare/compareGraphs`, reqObject)
    .then(res => {
      dispatch({
        type: COMPARE_GRAPH,
        payload: res.data
      });
      dispatch({
        type: LOADER,
        payload: false
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err
      });
    });
};

export const getCompareFiltersPromoType = reqObject => dispatch => {
     
  dispatch({
    type: LOADER,
    payload: true
  });
  axios
    .post(`${config.baseUrl}/compare/compareFilters`, reqObject)
    .then(res => {
      dispatch({
        type: COMPARE_PROMO_TYPE,
        payload: res.data
      });
      dispatch({
        type: LOADER,
        payload: false
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err
      });
    });
};

export const getCompareFiltersShop = reqObject => dispatch => {
     
  dispatch({
    type: LOADER,
    payload: true
  });
  axios
    .post(`${config.baseUrl}/compare/compareFilters`, reqObject)
    .then(res => {
      dispatch({
        type: COMPARE_SHOP,
        payload: res.data
      });
      dispatch({
        type: LOADER,
        payload: false
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err
      });
    });
};
