import React, { Component } from "react";
import excelHeaders from "../Promo Pipeline/excelHeadersForPipeline";
import Multiselect from "../../../../utils/Multiselect";
import DatePicker from "react-datepicker";
import classNames from "classnames";
import moment from "moment";
import { usersLogReport } from "../../../../redux/actions/userAction";
import { getCumulativeFilters } from "../../../../redux/actions/summaryAction";
import {
  runBatchProcess,
  batchProcessCheck,
  runDeeperOfferLogic,
} from "../../../../redux/actions/batchProcess";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import _ from "underscore";
import DataTable from "../../../tables";
import ColumnDef from "../../../tables/colDefs/CampaignColDef";
import AlertSuccess from "../../../../utils/AlertMessage/alertSuccess";
import AlertDanger from "../../../../utils/AlertMessage/alertDanger";
import AlertWarning from "../../../../utils/AlertMessage/alertWarning";
import AlertLoader from "../../../../utils/AlertMessage/alertMiniLoader";
import { PromotionCalendarGA } from '../../../../utils/GA/GAUtils';
import bestDeal from "./Best deal.png";
import { alertTimer } from "../../../../utils/alertTimer";
import {isIt} from '../../../../utils/getRoleName.js'
class CalendarTopFilters extends Component {
  constructor() {
    super();
    this.state = {
      custGroupArray: [],
      businessDescArray: [],
      shopArray: [],
      batchProcessRunning: false,
      batchProcessWarning: false,
      batchProcessFail: false,
      batchProcessMsg: "",
      batchBtnDisable: false,
      showDeeperOfferMsg: false,
    };
  }
  componentDidMount() {
    console.log("========",this.props.summaryCumulativeFilters)
    if(this.props.summaryCumulativeFilters.length === 0){
      this.props.getCumulativeFilters({
        required_column: [
          {
            required_column: "h6",
          },
          {
            required_column: "h5",
          },
          {
            required_column: "h3",
          },
        ],
      });
      this.props.sendFiltersLoading(true);
    }else{
      var all = { value: "All", label: "All" };
      let shopArray = [];
      let custGroupArray = [];
      let businessDescArray = [];

      if (this.props.summaryCumulativeFilters[0][0].h6 != null) {
        this.props.summaryCumulativeFilters[0][0].h6.map((filterData) => {
          custGroupArray.push({
            value: filterData,
            label: filterData,
          });
        });
        custGroupArray.unshift(all);
      }

      if (this.props.summaryCumulativeFilters[1][0].h5 != null) {
        this.props.summaryCumulativeFilters[1][0].h5.map((filterData) => {
          businessDescArray.push({
            value: filterData,
            label: filterData,
          });
        });
        businessDescArray.unshift(all);
      }

      if (
        !_.isEmpty(this.props.summaryCumulativeFilters[2]) &&
        this.props.summaryCumulativeFilters[2][0].h3 != null
      ) {
        this.props.summaryCumulativeFilters[2][0].h3.map((filterData) => {
          shopArray.push({
            value: filterData,
            label: filterData,
          });
        });
        shopArray.unshift(all);
      }

      if (
        shopArray.length &&
        businessDescArray.length &&
        custGroupArray.length
      ) {
        this.props.sendFiltersLoading(false);
      }

      this.setState({
        custGroupArray,
        businessDescArray,
        shopArray,
      });
    }


    
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.summaryCumulativeFilters &&
      nextProps.summaryCumulativeFilters !== this.props.summaryCumulativeFilters
    ) {
      var all = { value: "All", label: "All" };
      let shopArray = [];
      let custGroupArray = [];
      let businessDescArray = [];

      if (
        nextProps.summaryCumulativeFilters &&
        Object.keys(nextProps.summaryCumulativeFilters).length > 0 &&
        nextProps.summaryCumulativeFilters[0][0] != undefined
      ) {
        if (nextProps.summaryCumulativeFilters[0][0].h6 != null) {
          nextProps.summaryCumulativeFilters[0][0].h6.map((filterData) => {
            custGroupArray.push({
              value: filterData,
              label: filterData,
            });
          });
          custGroupArray.unshift(all);
        }

        if (nextProps.summaryCumulativeFilters[1][0].h5 != null) {
          nextProps.summaryCumulativeFilters[1][0].h5.map((filterData) => {
            businessDescArray.push({
              value: filterData,
              label: filterData,
            });
          });
          businessDescArray.unshift(all);
        }

        if (
          !_.isEmpty(nextProps.summaryCumulativeFilters[2]) &&
          nextProps.summaryCumulativeFilters[2][0].h3 != null
        ) {
          nextProps.summaryCumulativeFilters[2][0].h3.map((filterData) => {
            shopArray.push({
              value: filterData,
              label: filterData,
            });
          });
          shopArray.unshift(all);
        }
      }

      if (
        shopArray.length &&
        businessDescArray.length &&
        custGroupArray.length
      ) {
        this.props.sendFiltersLoading(false);
      }

      this.setState({
        custGroupArray,
        businessDescArray,
        shopArray,
      });
    }
    if (
      //!_.isEqual(nextProps.disableBatchBtn, this.props.disableBatchBtn)
      // && !this.state.batchBtnDisable
      nextProps.disableBatchBtn &&
      this.state.batchBtnDisable !==
      nextProps.disableBatchBtn.batch_process_running
      // && nextProps.disableBatchBtn.batch_process_running
    ) {
      this.setState({
        batchBtnDisable: nextProps.disableBatchBtn.batch_process_running,
      });
    }
  }

  runBatchProcessing = async () => {
    this.props.usersLogReport({
      screen: "promo_pipeline",
      action: "run_batch_process",
      userId: localStorage.getItem("email"),
    });
    let res = await this.props.runBatchProcess();
    if (res.status && res.process_start) {
      this.props.batchProcessCheck();
      this.setState({
        batchProcessRunning: true,
        batchProcessMsg: res.msg,
        batchBtnDisable: true,
      });
      setTimeout(() => {
        this.setState({ batchProcessRunning: false, batchProcessMsg: "" });
      }, alertTimer);
      return;
    }
    if (res.status && !res.process_start) {
      this.setState({
        batchProcessWarning: true,
        batchProcessMsg: res.msg,
        batchBtnDisable: false,
      });
      setTimeout(() => {
        this.setState({ batchProcessWarning: false, batchProcessMsg: "" });
      }, alertTimer);
      return;
    }
    this.setState({
      batchProcessFail: true,
      batchProcessMsg: "Something went wrong!",
    });
    setTimeout(() => {
      this.setState({ batchProcessFail: false, batchProcessMsg: "" });
    }, alertTimer);
  };
  runDeeperOfferBulkData = async () => {
    this.props.usersLogReport({
      screen: "promo_pipeline",
      action: "run_best_deal_logic",
      userId: localStorage.getItem("email"),
    });
    await this.props.runDeeperOfferLogic();
    this.setState({ showDeeperOfferMsg: true });
    setTimeout(() => {
      this.setState({ showDeeperOfferMsg: false });
    }, alertTimer);
  };
  submitGA(action, label, value) {
    PromotionCalendarGA({ action, label, value });
  }
  render() {
    let tableData = {
      header: ColumnDef.header,
      defaultColDef: ColumnDef.defaultColDef,
      data: this.props.pipelineData,
    };
    return (
      <div>
        <div className="alertDivs">
          {this.state.batchProcessRunning ? (
            <AlertSuccess
              message={this.state.batchProcessMsg}
              show={this.state.batchProcessRunning}
            />
          ) : null}

          {this.state.batchProcessWarning ? (
            <AlertWarning
              message={this.state.batchProcessMsg}
              show={this.state.batchProcessWarning}
            />
          ) : null}

          {this.state.batchProcessFail ? (
            <AlertDanger
              message={this.state.batchProcessMsg}
              show={this.state.batchProcessFail}
            />
          ) : null}
          {this.state.showDeeperOfferMsg ? (
            <AlertLoader
              message={this.props.deeperOfferMsg}
              show={this.state.showDeeperOfferMsg}
            />
          ) : null}
        </div>
        <h4 className="promoHeading mb-0">Promo Calendar</h4>

        <div className="row CalederMainRow mb-2">
          <div className="col-md-9 SelectRow">
            <div className="row">
              <div className="col-4 pr-0 pl-2">
                <label className="LabelDatePic">Customer Group:</label>
                <div className="calenadr-filter-cont">
                  <Multiselect
                    placeHolder="Customer Group"
                    optionsArray={this.state.custGroupArray}
                    onDropDownChange={(value) => {
                      this.props.custGroupSelected(
                        value,
                        this.state.custGroupArray
                      )
                      this.submitGA("Click", "Clicked_CustomerGroupDropdown");
                    }}
                    dropdownValue={this.props.selectedCustomerGrp}
                  />
                  {this.props.selectedCustomerGrp.length ? (
                    <i
                      className="fa fa-times close1"
                      aria-hidden="true"
                      onClick={() => {
                        this.props.clearFilters("custGroup");
                        this.submitGA("Click", "Clicked_CustomerGroupDropdown");
                      }}
                    />
                  ) : null}
                </div>
              </div>
              <div className="col-4 pr-0 text-center">
                <label className="LabelDatePic">Business:</label>
                <div className="calenadr-filter-cont">
                  <Multiselect
                    placeHolder="Business"
                    optionsArray={this.state.businessDescArray}
                    onDropDownChange={(value) => {
                      this.props.businessDescSelected(
                        value,
                        this.state.businessDescArray
                      )
                      this.submitGA("Click", "Clicked_BusinessDropdown");
                    }}
                    dropdownValue={this.props.selectedGrpVal}
                  />
                  {this.props.selectedGrpVal.length ? (
                    <i
                      className="fa fa-times close1"
                      aria-hidden="true"
                      onClick={() => {
                        this.props.clearFilters("businessDesc");
                        this.submitGA("Click", "Clicked_BusinessDropdown");
                      }}
                    />
                  ) : null}
                </div>
              </div>
              <div className="col-4 pr-0 text-center">
                <label className="LabelDatePic">Shop:</label>
                <div className="calenadr-filter-cont">
                  <Multiselect
                    placeHolder="Shop"
                    optionsArray={this.state.shopArray}
                    onDropDownChange={(value) => {
                      this.props.shopSelected(value, this.state.shopArray);
                      this.submitGA("Click", "Clicked_ShopDropdown");
                    }}
                    dropdownValue={this.props.selectedShopVal}
                  />
                  {this.props.selectedShopVal.length ? (
                    <i
                      className="fa fa-times close1"
                      aria-hidden="true"
                      onClick={() => {
                        this.props.clearFilters("shopDesc");
                        this.submitGA("Click", "Clicked_ShopDropdown");
                      }}
                    />
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="fiscal-year-column">
              <label className="LabelDatePic">Calendar:</label>
              <DatePicker
                id="dateMonthpicker"
                className="datePickerValueStyling form-control"
                selected={
                  this.props.TYSelectedDate == null
                    ? new Date(moment().add(1, "years"))
                    : this.props.TYSelectedDate
                }
                onChange={(d) => {
                  this.props.handleYearData(d)
                  this.submitGA("Click", "Clicked_CalendarDropdown");
                }}
                dateFormat="MM/yyyy"
                minDate={
                  new Date(moment(this.props.minDateState).subtract(0, "years"))
                }
                maxDate={
                  new Date(moment(this.props.maxDateState).subtract(0, "years"))
                }
                placeholderText="Select Year & Month"
                showMonthYearPicker
              />
              <i
                id="calendarIco"
                className="fa fa-calendar"
                aria-hidden="true"
              />
            </div>
          </div>
          {/* <div className="col-md-1"></div> */}
        </div>
        <div className="row justify-content-between mediaStyle">
          <div className="col-md-9 BtnGroupRow">
            <div id="promoCal" className="divInn btn-group text-center">
              <button
                type="button"
                className={classNames({
                  active: this.props.selectedView == "Month",
                  " btn": true,
                  "btn-outline-primary": true,
                  btnCalendr: true,
                  calPromo: true,
                })}
                onClick={() => {
                  this.props.toggleMonthQuarter("Month");
                  this.submitGA("Click", "Clicked_Month&QuarterToggleButton");
                }}
              >
                Month
              </button>
              <button
                type="button"
                className={classNames({
                  active: this.props.selectedView == "Quarter",
                  " btn": true,
                  "btn-outline-primary": true,
                  btnCalendr: true,
                  calPromo: true,
                })}
                onClick={() => {
                  this.props.toggleMonthQuarter("Quarter");
                  this.submitGA("Click", "Clicked_Month&QuarterToggleButton");
                }}
              >
                Quarter
              </button>
            </div>
          </div>
          <div className="col-md-3 text-right">
            <button
              type="button"
              title="Create campaign"
              className="btn pipeline-button-class mr-1"
              onClick={() => {
                this.props.createDMClickHandler(true);
                this.submitGA("Click", "Clicked_CreateDMButton");
              }}
              disabled={
                localStorage.getItem("role") === "2" ||
                  localStorage.getItem("role") === "5" ||
                  localStorage.getItem("role") === "6" 
                  ? true
                  : false
              }
            >
              <i className="fa fa-plus" aria-hidden="true" />
            </button>
            <button
              type="button"
              title="Download campaign data"
              className="btn pipeline-button-class mr-1 position-relative"
              onClick={() => {
                this.props.calDownloadHandler();
                this.submitGA("Click", "Clicked_DownloadCampaignDataButton");
              }}
            >
              <i className="fa fa-download" aria-hidden="true" />
              <div
                className="invisible position-absolute w-100"
                style={{ zIndex: "-9" }}
              >
                <DataTable
                  tableData={tableData}
                  campTableExport={this.props.campTableExport}
                  campTableExportHandler={this.props.campTableExportHandler}
                />
              </div>
            </button>

            <button
              type="button"
              className="btn pipeline-button-class mr-1 icon-batch"
              title="Run batch process"
              disabled={this.state.batchBtnDisable || isIt()}
              onClick={() => {
                this.runBatchProcessing();
                this.submitGA("Click", "Clicked_RunBatchProcessButton");
              }}
            >
              <span class="icon"></span>
            </button>
            { ( (localStorage.getItem("role") === "1") || (isIt()) ) ? (
              <button
                type="button"
                className="btn bestDeal pipeline-button-class mr-3"
                title="Run best deal logic"
                disabled={this.props.disableDeeperOfferBtn || isIt()}
                onClick={() => {
                  this.runDeeperOfferBulkData();
                  this.submitGA("Click", "Clicked_RunBestDealLogicButton");
                }}
              >
                <img src={bestDeal} style={{ height: "29px" }} />
              </button>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    summaryCumulativeFilters: state.summaryData.summaryCumulativeFilters,
    disableBatchBtn: state.userDataReducer.disableBatchBtn,
    disableDeeperOfferBtn: state.userDataReducer.disableDeeperOfferBtn,
    deeperOfferMsg: state.userDataReducer.deeperOfferMsg,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCumulativeFilters: (val) => dispatch(getCumulativeFilters(val)),
    runBatchProcess: (val) => dispatch(runBatchProcess(val)),
    batchProcessCheck: (val) => dispatch(batchProcessCheck(val)),
    runDeeperOfferLogic: (val) => dispatch(runDeeperOfferLogic(val)),
    usersLogReport: (val) => dispatch(usersLogReport(val)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CalendarTopFilters));
