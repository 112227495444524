import React, { Component } from "react";
import { connect } from "react-redux";
import PromotionMain from "../PromotionMain";
import PromotionPipeline from "../Promo Pipeline/PromotionPipeline";
import CalendarWrapper from "./CalendarWrapper";
import { withRouter } from "react-router-dom";
import PageLoader from "../../../../utils/Loader";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { isEqual } from "lodash";
import "./promoCalendarStyle.css";
import {
  calendarDatePlot,
  calendarData,
} from "../../../../redux/actions/calendarAction";
import { getLogedInUserDetails } from "../../../../redux/actions/loginAction";
import { batchProcessCheck } from "../../../../redux/actions/batchProcess";
import { getCampaignDm } from "../../../../redux/actions/adgroup";
import _ from "underscore";
import { Element, animateScroll as scroll, scroller } from "react-scroll";
import "../promotionstyling.css";
import DownloadCampaignDataModal from "./DownloadCampaignDataModal";
import CalendarTopFilters from "./CalendarTopFilters";
import AlertSuccess from "../../../../utils/AlertMessage/alertSuccess";
import "../Adgroup/AdGroupStyling.css";
import CreateDM from "./CreateDM";
import { PromotionCalendarGA } from '../../../../utils/GA/GAUtils';
import { alertTimer } from "../../../../utils/alertTimer";

let custGrpAll = false,
  businessAll = false,
  shopAll = false;

export class PromoCalendar extends Component {
  constructor(props) {
    super(props);
    this.csvLinkRef = React.createRef();
    this.state = {
      styleGetPrev: {
        position: "absolute",
        bottom: "13%",
        left: "-0.2rem",
        cursor: "pointer",
      },
      styleGetNext: {
        position: "absolute",
        bottom: "13%",
        right: "-0.2rem",
        cursor: "pointer",
      },
      adGrpArr: [],
      selectedCustomerGrp: [],
      selectedGrpVal: [],
      selectedShopVal: [],
      selectedShopState: [],
      selectedCustomerGropuValue: [],
      selectedBusinessDescValue: [],
      showSelectedValues: false,
      selectedPromo: "promo_1",
      campaignStartDate: "",
      campMerchLockoutDate: "",
      campaignEndDate: "",
      showPromotionMain: false,
      startDate: new Date(),
      selectedYear: null,
      selectedView: "Month",
      fiscal_monthnum:
        Number(new Date().getMonth()) === 0
          ? 12
          : Number(new Date().getMonth()),
      fiscal_quarter: 2,
      calendarWrapperArr: [],
      historyStartDate: "null",
      historyEndDate: "null",
      historyQuarter: "null",
      histortFiscalMonth: "null",
      historyCampaignId: "null",
      historyCampaignName: "null",
      historyCustomer: "null",
      historyBusiness: "null",
      historyShop: "null",
      showCampaignList: false,
      downloadActive: false,
      loaderAg: false,
      calendarDataArr: [],
      initialLoadFlag: true,
      selectedDate: null,
      minDateState: null,
      maxDateState: null,
      detailsBtnClicked: false,
      clearPipelineTable: false,
      componentLoaded: false,
      loaderValue: false,
      showCreateDM: false,
      DMCreated: false,
      loaderForPipeline: false,
      callPipelineTY: false,
      campTableExport: false,
      TYSelectedDate: null,
      campaignType: "",
      createdBy: null,
      createdAt: null,
      changedBy: null,
      changedAt: null,
    };
    this.handleYearData = this.handleYearData.bind(this);
  }

  componentWillMount() {
    let bbbb = document.cookie;
    this.props.getLogedInUserDetails();

    let startDate = localStorage.getItem("startDate"),
      //endDate = localStorage.getItem("endDate"),
      Quarter = localStorage.getItem("quarter"),
      historyCampId = localStorage.getItem("campaignId"),
      historyCampName = localStorage.getItem("campaignName"),
      historyCustomer = localStorage.getItem("customerGrp"),
      historyBusiness = localStorage.getItem("businessDesc"),
      historyCreatedBy = localStorage.getItem("campaignCreatedBy"),
      historyCreatedAt = localStorage.getItem("campaignCreatedAt"),
      historyChangedBy = localStorage.getItem("campaignChangedBy"),
      historyChangedAt = localStorage.getItem("campaignChangedAt"),
      historyShop = localStorage.getItem("shop");
    let custArry = [],
      businessArry = [],
      shopArry = [],
      statusArry = [],
      date = startDate && startDate !== "null" ? startDate : new Date();

    let year =
      new Date(date).getMonth() === 0 // for january fiscal year will be same year
        ? new Date(date).getFullYear() - 1
        : new Date(date).getFullYear(),
      fiscalDate = new Date(date);

    this.setState({
      selectedYear: year,
      selectedDate: fiscalDate,
      TYSelectedDate: fiscalDate,
    });

    if (Quarter && Quarter !== "null") {
      this.setState({
        selectedView: "Quarter",
      });
    }
    this.props.calendarDatePlot({
      fiscal_year: year + 1,
    });

    if (historyCustomer && historyCustomer !== "null") {
      custArry = historyCustomer.split(",");
      this.setState({
        selectedCustomerGropuValue: custArry,
        selectedCustomerGrp: custArry.map((item) => ({
          value: item,
          label: item,
        })),
      });
    }
    if (historyBusiness && historyBusiness !== "null") {
      businessArry = historyBusiness.split(",");
      this.setState({
        selectedBusinessDescValue: businessArry,
        selectedGrpVal: businessArry.map((item) => ({
          value: item,
          label: item,
        })),
      });
    }
    if (historyShop && historyShop !== "null") {
      shopArry = historyShop.split(",");
      this.setState({
        selectedShopState: shopArry,
        selectedShopVal: shopArry.map((item) => ({ value: item, label: item })),
      });
    }

    if (historyCampId && historyCampId !== "null") {
      this.setState({
        historyCampaignId: historyCampId,
        historyCampaignName: historyCampName,
        detailsBtnClicked: true,
        createdBy : historyCreatedBy,
        createdAt : historyCreatedAt,
        changedBy : historyChangedBy,
        changedAt : historyChangedAt,
      });
    }
  }
  campTableExportHandler = () => {
    this.setState({ campTableExport: false });
  };
  componentDidUpdate(oldProps, oldState) {
    if (
      this.props.pipelineData !== oldProps.pipelineData &&
      this.state.downloadActive
    ) {
      this.setState({ downloadActive: false, campTableExport: true });
      // this.csvLinkRef.current.link.click();
    }

    // debugger
    let startDate = localStorage.getItem("startDate"),
      endDate = localStorage.getItem("endDate"),
      Quarter = localStorage.getItem("quarter"),
      fiscalMonth1 = localStorage.getItem("fiscalMonth"),
      campaignName = localStorage.getItem("campaignName"),
      historyCampId = localStorage.getItem("campaignId"),
      campSD = localStorage.getItem("campStartDate"),
      campED = localStorage.getItem("campEndDate"),
      campMLD = localStorage.getItem("campMerchLockoutDate"),
      fiscalMonth = 2,
      Month = new Date().getMonth();
    if (Month > 0 && Month < 4) {
      fiscalMonth = 1;
    } else if (Month > 0 && Month < 7) {
      fiscalMonth = 2;
    } else if (Month > 0 && Month < 10) {
      fiscalMonth = 3;
    } else if (Month === 0 || (Month > 10 && Month < 12)) {
      fiscalMonth = 4;
    }
    if (
      oldProps.calendarStrucure != this.props.calendarStrucure &&
      this.state.initialLoadFlag
    ) {
      let tempMonth =
        fiscalMonth1 && fiscalMonth1 !== "null"
          ? fiscalMonth1
          : new Date().getMonth();

      let fiscalQuarter = Quarter && Quarter !== "null" ? Quarter : fiscalMonth;

      this.setState({
        fiscal_monthnum: tempMonth === 0 ? 12 : tempMonth,
        fiscal_quarter: fiscalQuarter,
        initialLoadFlag: false,
        componentLoaded: true,
      });

      this.getCalendarWrapper(this.state.selectedView);
    }
    if (
      !isEqual(oldProps.calendarStrucure, this.props.calendarStrucure) &&
      !this.state.initialLoadFlag
    ) {
      this.getCalendarWrapper(this.state.selectedView);
    }
    if (oldProps.getMerchCatData != this.props.getMerchCatData) {
      let tempArr = [];
      this.props.getMerchCatData.forEach((ele) => {
        tempArr = tempArr.concat(ele.ad_groups);
      });
      this.setState({ adGrpArr: _.uniq(tempArr) });

      this.getCalendarWrapper(this.state.selectedView);
    }
    if (
      this.state.selectedCustomerGropuValue !=
      oldState.selectedCustomerGropuValue ||
      this.state.selectedBusinessDescValue !=
      oldState.selectedBusinessDescValue ||
      this.state.selectedShopState != oldState.selectedShopState
    ) {
      this.getCalendarWrapper(this.state.selectedView);
    }
    if (oldProps.calendarDataPerYear != this.props.calendarDataPerYear) {
      /*------------------yearly navigation enable/disable function------------------ */
      if (
        this.props.calendarDataPerYear &&
        this.props.calendarDataPerYear.length
      ) {
        let selectedCampaignName =
          campaignName && campaignName !== "null"
            ? campaignName
            : this.props.calendarDataPerYear[0].name;
        let selectedhistoryCampId =
          historyCampId && historyCampId !== "null"
            ? historyCampId
            : this.props.calendarDataPerYear[0].id;
        let selectedStartDate =
          campSD && campSD !== "null"
            ? campSD
            : this.props.calendarDataPerYear[0].start_date;
        let selectedEndDate =
          campED && campED !== "null"
            ? campED
            : this.props.calendarDataPerYear[0].end_date;
      let selectedMerchLockoutDate =
            campMLD && campMLD !== "null"
              ? campMLD
              : this.props.calendarDataPerYear[0].merchant_lockout_date;

        //update campMerchLockoutDate
        this.setState({
          selectedPromo: selectedhistoryCampId,
          campaignStartDate: selectedStartDate,
          campaignEndDate: selectedEndDate,
          selectedPromoName: selectedCampaignName,
          campaignType: this.props.calendarDataPerYear[0].campaign_type,
          campMerchLockoutDate: selectedMerchLockoutDate
        });
      }
      if (
        this.state.selectedYear - 1 >
        new Date(
          this.props.calendarStrucure.overall_dates[0].start_date
        ).getFullYear()
      ) {
        // this.setState({ styleHandlePrev: { cursor: "pointer" } });
      }

      if (this.state.selectedView === "Month") {
        if (
          this.state.fiscal_monthnum === 11 &&
          this.state.selectedYear >=
          new Date(
            this.props.calendarStrucure.overall_dates[0].end_date
          ).getFullYear()
        ) {
          this.setState({
            styleGetNext: {
              position: "absolute",
              bottom: "13%",
              right: "-0.2rem",
              cursor: "not-allowed",
              pointerEvents: "none",
              color: "grey",
              background: "#e8e7e7",
            },
          });
        } else {
          this.setState({
            styleGetNext: {
              position: "absolute",
              bottom: "13%",
              right: "-0.2rem",
              cursor: "pointer",
            },
          });
        }
        if (
          this.state.fiscal_monthnum === 12 &&
          this.state.selectedYear <=
          new Date(
            this.props.calendarStrucure.overall_dates[0].start_date
          ).getFullYear()
        ) {
          this.setState({
            styleGetPrev: {
              position: "absolute",
              bottom: "13%",
              left: "-0.2rem",
              cursor: "not-allowed",
              pointerEvents: "none",
              color: "grey",
              background: "#e8e7e7",
            },
          });
        } else {
          this.setState({
            styleGetPrev: {
              position: "absolute",
              bottom: "13%",
              left: "-0.2rem",
              cursor: "pointer",
            },
          });
        }
      } else if (this.state.selectedView === "Quarter") {
        if (
          this.state.fiscal_quarter === 4 &&
          this.state.selectedYear >=
          new Date(
            this.props.calendarStrucure.overall_dates[0].end_date
          ).getFullYear()
        ) {
          this.setState({
            styleGetNext: {
              position: "absolute",
              bottom: "13%",
              right: "-0.2rem",
              cursor: "not-allowed",
              pointerEvents: "none",
              color: "grey",
              background: "#e8e7e7",
            },
          });
        } else {
          this.setState({
            styleGetNext: {
              position: "absolute",
              bottom: "13%",
              right: "-0.2rem",
              cursor: "pointer",
            },
          });
        }
        if (
          this.state.fiscal_quarter === 12 &&
          this.state.selectedYear <=
          new Date(
            this.props.calendarStrucure.overall_dates[0].start_date
          ).getFullYear()
        ) {
          this.setState({
            styleGetPrev: {
              position: "absolute",
              bottom: "13%",
              left: "-0.2rem",
              cursor: "not-allowed",
              pointerEvents: "none",
              color: "grey",
              background: "#e8e7e7",
            },
          });
        } else {
          this.setState({
            styleGetPrev: {
              position: "absolute",
              bottom: "13%",
              left: "-0.2rem",
              cursor: "pointer",
            },
          });
        }
      }

      //   if (this.state.selectedView === "Month") {
      //     if (
      //       this.state.fiscal_monthnum > 1 &&
      //       this.state.selectedYear >
      //         new Date(
      //           this.props.calendarStrucure.overall_dates[0].start_date
      //         ).getFullYear()
      //     )
      //       this.setState({
      //         styleGetPrev: {
      //           position: "absolute",
      //           bottom: "13%",
      //           left: "-0.2rem",
      //           cursor: "pointer"
      //         }
      //       });
      //     else if (
      //       this.state.fiscal_monthnum == 1 &&
      //       this.state.selectedYear - 1 >
      //         new Date(
      //           this.props.calendarStrucure.overall_dates[0].start_date
      //         ).getFullYear()
      //     ) {
      //       this.setState({
      //         styleGetPrev: {
      //           position: "absolute",
      //           bottom: "13%",
      //           left: "-0.2rem",
      //           cursor: "pointer"
      //         }
      //       });
      //     } else {
      //       this.setState({
      //         styleGetPrev: {
      //           position: "absolute",
      //           bottom: "13%",
      //           left: "-0.2rem",
      //           cursor: "not-allowed"
      //         }
      //       });
      //     }
      //   } else if (this.state.selectedView === "Quarter") {
      //     if (
      //       this.state.fiscal_quarter > 1 &&
      //       this.state.selectedYear >
      //         new Date(
      //           this.props.calendarStrucure.overall_dates[0].start_date
      //         ).getFullYear()
      //     ) {
      //       this.setState({
      //         styleGetPrev: {
      //           position: "absolute",
      //           bottom: "13%",
      //           left: "-0.2rem",
      //           cursor: "pointer"
      //         }
      //       });
      //     } else if (
      //       this.state.fiscal_quarter == 1 &&
      //       this.state.selectedYear - 1 >
      //         new Date(
      //           this.props.calendarStrucure.overall_dates[0].start_date
      //         ).getFullYear()
      //     ) {
      //       this.setState({
      //         styleGetPrev: {
      //           position: "absolute",
      //           bottom: "13%",
      //           left: "-0.2rem",
      //           cursor: "pointer"
      //         }
      //       });
      //     } else {
      //       this.setState({
      //         styleGetPrev: {
      //           position: "absolute",
      //           bottom: "13%",
      //           left: "-0.2rem",
      //           cursor: "not-allowed"
      //         }
      //       });
      //     }
      //   }
      /*-------------------------setting Calendar Data--------------------------*/
      this.setState({
        calendarDataArr: this.props.calendarDataPerYear,
      });
    }
    if (
      oldState.fiscal_monthnum != this.state.fiscal_monthnum ||
      (this.state.fiscal_quarter &&
        oldState.fiscal_quarter != this.state.fiscal_quarter) ||
      oldState.selectedYear != this.state.selectedYear
    ) {
      if (oldState.selectedYear != this.state.selectedYear) {
        this.props.calendarDatePlot({
          fiscal_year: this.state.selectedYear + 1,
          adgroups: _.uniq(this.state.adGrpArr),
        });
      } else {
        this.getCalendarWrapper(this.state.selectedView);
      }
    }

    if (
      !this.state.minDateState &&
      !this.state.maxDateState &&
      this.props.calendarStrucure
    ) {
      this.setState({
        minDateState: this.props.calendarStrucure.overall_dates[0].start_date,
        maxDateState: this.props.calendarStrucure.overall_dates[0].end_date,
      });

      localStorage.setItem(
        "maxDateForCalendar",
        this.props.calendarStrucure.overall_dates[0].end_date
      );
      localStorage.setItem(
        "minDateForCalendar",
        this.props.calendarStrucure.overall_dates[0].start_date
      );
    }

    if (
      this.state.loaderAg &&
      this.props.getCampaignDmList !== undefined &&
      Object.keys(this.props.getCampaignDmList).length > 0
    ) {
      this.setState({ loaderAg: false });
    }
  }
  componentWillUnmount() {
    //clearTimeout(setTimeoutVar);
    localStorage.setItem("startDate", this.state.historyStartDate);
    localStorage.setItem("endDate", this.state.historyEndDate);
    localStorage.setItem("fiscalMonth", this.state.histortFiscalMonth);
    localStorage.setItem("customerGrp", this.state.selectedCustomerGropuValue);
    localStorage.setItem("businessDesc", this.state.selectedBusinessDescValue);
    localStorage.setItem("shop", this.state.selectedShopState);
    if (this.state.selectedView === "Quarter") {
      localStorage.setItem("quarter", this.state.historyQuarter);
    }
  }
  handleYearData(date) {
    this.clearHistoryValues();
    this.props.batchProcessCheck();
    let tempYear = moment(date).format("YYYY-MMM");
    let tempYear1 = Number(tempYear.slice(0, 4));
    let tempMonth = Number(date.getMonth());
    if (tempMonth == 0) {
      tempMonth = 12;
    }
    if (tempMonth === 12) tempYear1 = tempYear1 - 1;

    // let date1 = moment(date).format("YYYY-MMM");
    let selectedDate = new Date(moment(date).add(0, "years"));
    let tempQtr = parseInt((Number(tempMonth) - 1) / 3 + 1);
    this.setState({
      fiscal_quarter: tempQtr,
      selectedDate: selectedDate,
      fiscal_monthnum: tempMonth,
      selectedYear:
        this.state.selectedYear !== tempYear1 || tempMonth === 12
          ? tempYear1
          : this.state.selectedYear,
      detailsBtnClicked: false,
      clearPipelineTable: true,
      TYSelectedDate: selectedDate,
    });
    // if (this.state.selectedYear != tempYear1) {
    //   this.setState({
    //     fiscal_quarter: tempQtr,
    //     selectedDate: selectedDate,
    //     fiscal_monthnum: tempMonth,
    //     selectedYear: tempYear1,
    //     detailsBtnClicked: false,
    //     clearPipelineTable: true
    //   });
    // } else {
    //   this.setState({
    //     fiscal_quarter: tempQtr,
    //     selectedDate: selectedDate,
    //     fiscal_monthnum: tempMonth,
    //     detailsBtnClicked: false,
    //     clearPipelineTable: true
    //   });
    // }
  }

  clearFilters = (value) => {
    if (value == "custGroup") {
      this.setState({
        selectedCustomerGropuValue: [],
        selectedCustomerGrp: [],
        clearPipelineTable: true,
        detailsBtnClicked: false,
      });
    } else if (value == "businessDesc") {
      this.setState({
        selectedBusinessDescValue: [],
        selectedGrpVal: [],
        clearPipelineTable: true,
        detailsBtnClicked: false,
      });
    } else if (value == "shopDesc") {
      this.setState({
        selectedShopState: [],
        selectedShopVal: [],
        clearPipelineTable: true,
        detailsBtnClicked: false,
      });
    }

    setTimeout(() => {
      this.props.calendarData({
        start_date: this.state.startDateForCampaign,
        end_date: this.state.endDateForCampaign,
        h3: this.state.selectedShopState,
        h5: this.state.selectedBusinessDescValue,
        h6: this.state.selectedCustomerGropuValue,
        status: [],
      });
    }, 700);
  };

  onDmEditHandler = (intermediate, editOffers) => {
    
    if (!editOffers) {
      this.props.calendarData({
        start_date: this.state.startDateForCampaign,
        end_date: this.state.endDateForCampaign,
        h3: this.state.selectedShopState,
        h5: this.state.selectedBusinessDescValue,
        h6: this.state.selectedCustomerGropuValue,
        status: [],
      });
    }
    if (intermediate === "intermediate") {
      return;
    }
    localStorage.setItem("campaignId", "null");
    localStorage.setItem("campaignName", "null");
    localStorage.setItem("campStartDate", "null");
    localStorage.setItem("campMerchLockoutDate", "null");
    localStorage.setItem("campEndDate", "null");
    localStorage.setItem("campaignCreatedBy", "null");
    localStorage.setItem("campaignCreatedAt", "null");
    localStorage.setItem("campaignChangedBy", "null");
    localStorage.setItem("campaignChangedAt", "null");

    this.setState({ clearPipelineTable: true,  detailsBtnClicked: false });
    
    this.clearHistoryValues();
  };
  custGroupSelected = (value, cust) => {
    this.clearHistoryValues();
    let tempArr = [];
    value.forEach((ele) => {
      tempArr.push(ele.value);
    });
    if (tempArr.includes("All")) {
      custGrpAll = true;
      this.setState({
        selectedCustomerGropuValue: [],
        selectedCustomerGrp: cust,
        clearPipelineTable: true,
        detailsBtnClicked: false,
      });
    } else if (!tempArr.includes("All") && custGrpAll) {
      custGrpAll = false;
      this.setState({
        selectedCustomerGropuValue: [],
        selectedCustomerGrp: [],
        clearPipelineTable: true,
        detailsBtnClicked: false,
      });
    } else {
      this.setState({
        selectedCustomerGropuValue: tempArr,
        selectedCustomerGrp: value,
        clearPipelineTable: true,
        detailsBtnClicked: false,
      });
    }
  };

  businessDescSelected = (value, array) => {
    this.clearHistoryValues();
    let tempArr = [];
    value.forEach((ele) => {
      tempArr.push(ele.value);
    });
    if (tempArr.includes("All")) {
      businessAll = true;
      this.setState({
        selectedBusinessDescValue: [],
        selectedGrpVal: array,
        clearPipelineTable: true,
        detailsBtnClicked: false,
      });
    } else if (!tempArr.includes("All") && businessAll) {
      businessAll = false;
      this.setState({
        selectedBusinessDescValue: [],
        selectedGrpVal: [],
        clearPipelineTable: true,
        detailsBtnClicked: false,
      });
    } else {
      this.setState({
        selectedBusinessDescValue: tempArr,
        selectedGrpVal: value,
        clearPipelineTable: true,
        detailsBtnClicked: false,
      });
    }
  };

  shopSelected = (value, adgroupsArray) => {
    this.clearHistoryValues();
    let tempArr = [];
    value.forEach((ele) => {
      tempArr.push(ele.value);
    });
    if (tempArr.includes("All")) {
      shopAll = true;
      this.setState({
        selectedShopState: [],
        selectedShopVal: adgroupsArray,
        clearPipelineTable: true,
        detailsBtnClicked: false,
      });
    } else if (!tempArr.includes("All") && shopAll) {
      shopAll = false;
      this.setState({
        selectedShopState: [],
        selectedShopVal: [],
        clearPipelineTable: true,
        detailsBtnClicked: false,
      });
    } else {
      this.setState({
        selectedShopState: tempArr,
        selectedShopVal: value,
        clearPipelineTable: true,
        detailsBtnClicked: false,
      });
    }
  };

  clearHistoryValues = () => {
    this.setState({
      historyCampaignId: "null",
      historyCampaignName: "null",
    });
  };

  getCalendarWrapper = (val) => {
    let context = this;
    let calendarWrapperArr = [],
      start_date = null,
      end_date;

    let startDate = localStorage.getItem("startDate"),
      endDate = localStorage.getItem("endDate");

    if (
      this.props.calendarStrucure !== undefined ||
      this.props.calendarStrucure !== null
    ) {
      if (
        this.props.calendarStrucure.plot_data &&
        this.props.calendarStrucure.plot_data.length
      ) {
        var fiscal_week_52_patch = false;
        var curr_date = moment(new Date()).format("YYYY-MM-DD");
        var temp_fiscal_week = 0;
        var temp_fiscal_monthnum = 0;
        context.props.calendarStrucure.plot_data.forEach((ele) => {
          if (
            ele.start_date <= curr_date &&
            ele.end_date >= curr_date &&
            context.state.fiscal_monthnum === ele.fiscal_monthnum &&
            ((context.state.fiscal_monthnum < 12 &&
              new Date(ele.start_date).getFullYear() + 1 ==
              context.state.selectedYear) ||
              (context.state.fiscal_monthnum == 12 &&
                new Date(ele.start_date).getFullYear() ==
                context.state.selectedYear))
          ) {
            temp_fiscal_week = Number(ele.fiscal_week);
            temp_fiscal_monthnum = Number(ele.fiscal_monthnum);
            // calendarWrapperArr.push(ele);
          }
        });
        if (val == "Month") {
          context.props.calendarStrucure.plot_data.forEach((ele) => {
            if (
              temp_fiscal_week != 0 &&
              Number(ele.fiscal_week) == temp_fiscal_week &&
              Number(ele.fiscal_monthnum) != context.state.fiscal_monthnum &&
              context.state.fiscal_monthnum - temp_fiscal_monthnum == 1
            ) {
              calendarWrapperArr.push(ele);
            }

            if (
              Number(ele.fiscal_monthnum) == context.state.fiscal_monthnum //&&
              //   ((context.state.fiscal_monthnum < 12 &&
              //     new Date(ele.start_date).getFullYear() + 1 ==
              //       context.state.selectedYear) ||
              //     (context.state.fiscal_monthnum == 12 &&
              //       new Date(ele.start_date).getFullYear() ==
              //         context.state.selectedYear))
            ) {
              calendarWrapperArr.push(ele);
            }
          });
        } else {
          let tempArr = context.props.calendarStrucure.plot_data;
          for (let index = 0; index < tempArr.length; index++) {
            if (
              tempArr[index].fiscal_quarter == 1 &&
              tempArr[index].fiscal_week == 52
            ) {
              fiscal_week_52_patch = true;
            }
            if (
              Number(tempArr[index].fiscal_quarter) ==
              context.state.fiscal_quarter &&
              (new Date(tempArr[index].start_date).getFullYear() - 1 ==
                context.state.selectedYear ||
                new Date(tempArr[index].start_date).getFullYear() ==
                context.state.selectedYear)
            ) {
              if (
                index < tempArr.length - 1 &&
                tempArr[index].fiscal_week == tempArr[index + 1].fiscal_week
              ) {
                tempArr[index].end_date = tempArr[index + 1].end_date;
                calendarWrapperArr.push(tempArr[index]);
                index++;
              } else {
                calendarWrapperArr.push(tempArr[index]);
              }
            }
          }
        }

        if (startDate && startDate !== "null") {
          this.props.calendarData({
            start_date: startDate,
            end_date: endDate,
            h3: context.state.selectedShopState,
            h5: context.state.selectedBusinessDescValue,
            h6: context.state.selectedCustomerGropuValue,
            status: [],
          });
          start_date = startDate;
          end_date = endDate;
          localStorage.setItem("startDate", "null");
          localStorage.setItem("endDate", "null");
          localStorage.setItem("quarter", "null");
          localStorage.setItem("fiscalMonth", "null");
          localStorage.setItem("customerGrp", "null");
          localStorage.setItem("businessDesc", "null");
          localStorage.setItem("shop", "null");
        } else {
          start_date = calendarWrapperArr[0].start_date;

          if (fiscal_week_52_patch == true) {
            end_date =
              calendarWrapperArr[calendarWrapperArr.length - 2].end_date;
          } else {
            end_date =
              calendarWrapperArr[calendarWrapperArr.length - 1].end_date;
          }

          this.setState({
            startDateForCampaign: start_date,
            endDateForCampaign: end_date,
          });
          this.props.calendarData({
            start_date: start_date,
            end_date: end_date,
            h3:
              context.state.selectedShopState &&
                context.state.selectedShopState[0] === "All"
                ? []
                : context.state.selectedShopState,
            h5:
              context.state.selectedBusinessDescValue &&
                context.state.selectedBusinessDescValue[0] === "All"
                ? []
                : context.state.selectedBusinessDescValue,
            h6:
              context.state.selectedCustomerGropuValue &&
                context.state.selectedCustomerGropuValue[0] === "All"
                ? []
                : context.state.selectedCustomerGropuValue,
            status: [],
          });
        }
      }
    }

    this.setState((state) => {
      return {
        ...state,
        calendarWrapperArr,
        historyStartDate: start_date,
        historyEndDate: end_date,
        histortFiscalMonth: state.fiscal_monthnum,
        historyQuarter: state.fiscal_quarter,

        historyCustomer: context.state.selectedCustomerGropuValue
          ? context.state.selectedCustomerGropuValue
          : "null",
        historyBusiness: context.state.selectedBusinessDescValue
          ? context.state.selectedBusinessDescValue
          : "null",
        historyShop: context.state.selectedShopState
          ? context.state.selectedShopState
          : "null",
      };
    });
  };

  toggleMonthQuarter = (val) => {
    //let temp = val;
    if (this.state.selectedView != val) {
      this.setState({ selectedView: val });

      this.getCalendarWrapper(val);
    }
  };
  showPromotionMain = (e) => {
    if (e == "showSelectedValues") {
      this.setState({ showSelectedValues: true });
    }
    this.setState({ showPromotionMain: true });
  };

  sendFiltersLoading = (loadingVal, screen) => {
    if (screen) {
      this.setState({
        loaderForPipeline: loadingVal,
      });
    } else {
      this.setState({
        loaderValue: loadingVal,
      });
    }
  };

  getNext = () => {
    // let historyCampId = localStorage.getItem("campaignId");
    //if(historyCampId && historyCampId !== "null"){
    localStorage.setItem("campaignId", "null");
    localStorage.setItem("campaignName", "null");

    this.clearHistoryValues();
    this.props.batchProcessCheck();
    //}
    let month = {
      1: 2,
      2: 5,
      3: 8,
      4: 11,
    };

    if (this.state.selectedView === "Month") {
      if (
        this.state.fiscal_monthnum < 12 &&
        this.state.selectedYear <=
        new Date(
          this.props.calendarStrucure.overall_dates[0].end_date
        ).getFullYear()
      ) {
        let date =
          this.state.fiscal_monthnum + 2 > 12
            ? new Date(`1-1-${this.state.selectedYear}`)
            : new Date(
              `${this.state.fiscal_monthnum + 2}-1-${this.state.selectedYear
              }`
            ),
          selectedDate = new Date(moment(date).add(0, "years"));
        this.setState({
          fiscal_monthnum: Number(this.state.fiscal_monthnum) + 1,
          fiscal_quarter: parseInt(Number(this.state.fiscal_monthnum) / 3 + 1),
          selectedDate: selectedDate,
          detailsBtnClicked: false,
          TYSelectedDate:
            this.state.fiscal_monthnum === 11
              ? new Date(moment(selectedDate).add(1, "years"))
              : selectedDate,
        });
      } else if (
        this.state.fiscal_monthnum == 12 &&
        this.state.selectedYear <
        new Date(
          this.props.calendarStrucure.overall_dates[0].end_date
        ).getFullYear()
      ) {
        let date = new Date(`2-1-${this.state.selectedYear + 1}`),
          selectedDate = new Date(moment(date).add(0, "years"));
        this.setState({
          fiscal_monthnum: 1,
          fiscal_quarter: 1,
          selectedYear: this.state.selectedYear + 1,
          selectedDate: selectedDate,
          detailsBtnClicked: false,
          TYSelectedDate: selectedDate,
        });
      }
    } else if (this.state.selectedView === "Quarter") {
      if (
        this.state.fiscal_quarter < 4 &&
        this.state.selectedYear <=
        new Date(
          this.props.calendarStrucure.overall_dates[0].end_date
        ).getFullYear()
      ) {
        let quarter = Number(this.state.fiscal_quarter) + 1,
          date = new Date(`${month[quarter]}-1-${this.state.selectedYear}`),
          selectedDate = new Date(moment(date).add(0, "years"));
        this.setState({
          fiscal_quarter: Number(this.state.fiscal_quarter) + 1,
          fiscal_monthnum: Number(this.state.fiscal_quarter) * 3 + 1,
          selectedDate: selectedDate,
          detailsBtnClicked: false,
          TYSelectedDate:
            this.state.fiscal_monthnum === 11
              ? new Date(moment(selectedDate).add(1, "years"))
              : selectedDate,
        });
      } else if (
        this.state.fiscal_quarter == 4 &&
        this.state.selectedYear <
        new Date(
          this.props.calendarStrucure.overall_dates[0].end_date
        ).getFullYear()
      ) {
        let date = new Date(`2-1-${this.state.selectedYear + 1}`),
          selectedDate = new Date(moment(date).add(0, "years"));

        this.setState({
          fiscal_quarter: 1,
          fiscal_monthnum: 1,
          selectedYear: this.state.selectedYear + 1,
          selectedDate: selectedDate,
          detailsBtnClicked: false,
          TYSelectedDate: selectedDate,
        });
      }
    }
    this.setState({
      clearPipelineTable: true,
    });
  };
  getPrev = () => {
    localStorage.setItem("campaignId", "null");
    localStorage.setItem("campaignName", "null");
    this.clearHistoryValues();
    this.props.batchProcessCheck();
    let month = {
      1: 2,
      2: 5,
      3: 8,
      4: 11,
    };
    if (this.state.selectedView === "Month") {
      if (
        this.state.fiscal_monthnum > 1 &&
        this.state.selectedYear >=
        new Date(
          this.props.calendarStrucure.overall_dates[0].start_date
        ).getFullYear() -
        1
      ) {
        let date = new Date(
          `${this.state.fiscal_monthnum}-1-${this.state.selectedYear}`
        ),
          selectedDate = new Date(moment(date).add(0, "years"));

        this.setState({
          fiscal_monthnum: Number(this.state.fiscal_monthnum) - 1,
          fiscal_quarter: parseInt(
            (Number(this.state.fiscal_monthnum) - 2) / 3 + 1
          ),
          selectedDate: selectedDate,
          detailsBtnClicked: false,
          TYSelectedDate: selectedDate,
        });
      } else if (
        this.state.fiscal_monthnum == 1 &&
        this.state.selectedYear - 1 >=
        new Date(
          this.props.calendarStrucure.overall_dates[0].start_date
        ).getFullYear() -
        1
      ) {
        let date = new Date(
          `${this.state.fiscal_monthnum}-1-${this.state.selectedYear - 1}`
        ),
          selectedDate = new Date(moment(date).add(0, "years"));

        this.setState({
          fiscal_monthnum: 12,
          fiscal_quarter: 4,
          selectedYear: this.state.selectedYear - 1,
          selectedDate: selectedDate,
          detailsBtnClicked: false,
          TYSelectedDate: new Date(moment(selectedDate).add(1, "years")),
        });
      }
    } else if (this.state.selectedView === "Quarter") {
      if (
        this.state.fiscal_quarter > 1 &&
        this.state.selectedYear >=
        new Date(
          this.props.calendarStrucure.overall_dates[0].start_date
        ).getFullYear() -
        1
      ) {
        let quarter = Number(this.state.fiscal_quarter) - 1,
          date = new Date(`${month[quarter]}-1-${this.state.selectedYear}`),
          selectedDate = new Date(moment(date).add(0, "years"));
        this.setState({
          fiscal_quarter: Number(this.state.fiscal_quarter) - 1,
          fiscal_monthnum: (Number(this.state.fiscal_quarter) - 1) * 3 - 2,
          selectedDate: selectedDate,
          detailsBtnClicked: false,
          TYSelectedDate: selectedDate,
        });
      } else if (
        this.state.fiscal_quarter == 1 &&
        this.state.selectedYear - 1 >=
        new Date(
          this.props.calendarStrucure.overall_dates[0].start_date
        ).getFullYear() -
        1
      ) {
        let date = new Date(`11-1-${this.state.selectedYear - 1}`),
          selectedDate = new Date(moment(date).add(0, "years"));

        this.setState({
          fiscal_quarter: 4,
          fiscal_monthnum: 10,
          selectedYear: this.state.selectedYear - 1,
          selectedDate: selectedDate,
          detailsBtnClicked: false,
          TYSelectedDate: new Date(moment(selectedDate).add(1, "years")),
        });
      }
    }

    this.setState({
      clearPipelineTable: true,
    });
  };

  downloadActiveMethod = (val) => {
    if (val) {
      this.setState({
        downloadActive: true,
      });
    }
  };
  showPromotionPipeline = (val) => {
    localStorage.setItem("campaignIdsForUrl", val.id);
    this.props.batchProcessCheck();
    this.setState({
      selectedPromo: val.id,
      campaignStartDate: val.start_date,
      campaignEndDate: val.end_date,
      selectedPromoName: val.name,
      historyCampaignId: val.id,
      historyCampaignName: val.name,
      detailsBtnClicked: true,
      clearPipelineTable: false,
      callPipelineTY: true,
      loaderForPipeline: true,
      campaignType: val.campaign_type,
      createdBy: val.created_by_user,
      createdAt: val.created_at,
      changedBy: val.updated_by_user,
      changedAt: val.updated_at,
      campMerchLockoutDate: val.merchant_lockout_date
    });

    scroller.scrollTo("showPromotionTag", {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart",
    });
  };

  callPipelineTYHandler = () => {
    this.setState({ callPipelineTY: false });
  };
  call_edit_adgroup = (data) => {
    localStorage.setItem("campaignIdsForUrl", data.campaign_id);
    localStorage.setItem("promoIdsForUrl", data.promo_id);
    localStorage.setItem("adgroupIdsForUrl", data.adgroup_id);

    this.props.history.push(`/createpromotion?copyCampaign=true`);
  };
  calDownloadHandler = () => {
    this.setState({
      showCampaignList: true,
      loaderAg: true,
    });

    this.props.getCampaignDm("");
  };

  createDMClickHandler = (val, create) => {
    this.setState({ showCreateDM: val });
    if (create === "createDM") {
      this.setState({ DMCreated: true });
      setTimeout(() => {
        this.setState({ DMCreated: false });
      }, alertTimer);
    }
  };

  closeModal = () => {
    this.setState({
      showCampaignList: false,
    });
  };

  submitGA(action, label) {
    PromotionCalendarGA({ action, label });
  }

  render() {
    let downloadData = _.isEmpty(this.props.pipelineData)
      ? []
      : _.flatten(this.props.pipelineData);

    return (
      <div className="promoCalendarConatainer">
        <div className="alertDivs">
          {this.state.DMCreated ? (
            <AlertSuccess
              message="Campaign has been created successfully."
              show={this.state.DMCreated}
            />
          ) : null}
        </div>
        <PageLoader
          loader={
            this.props.validationLoader.validationDatas.loading ||
            this.props.loader ||
            this.state.loaderValue ||
            this.state.loaderForPipeline
          }
        >
          {!this.state.showPromotionMain ? (
            <div className="calendarContainer" id="main-calendar-container">
              <div className="promo">
                <div
                  className="card CalendarMain promoCalendarDiv1"
                  id="promocalendar-maincontainer"
                >
                  <CalendarTopFilters
                    custGroupSelected={this.custGroupSelected}
                    businessDescSelected={this.businessDescSelected}
                    shopSelected={this.shopSelected}
                    selectedCustomerGrp={this.state.selectedCustomerGrp}
                    selectedGrpVal={this.state.selectedGrpVal}
                    selectedShopVal={this.state.selectedShopVal}
                    clearFilters={this.clearFilters}
                    selectedDate={this.state.selectedDate}
                    minDateState={this.state.minDateState}
                    maxDateState={this.state.maxDateState}
                    handleYearData={this.handleYearData}
                    toggleMonthQuarter={this.toggleMonthQuarter}
                    selectedView={this.state.selectedView}
                    calDownloadHandler={this.calDownloadHandler}
                    pipelineData={downloadData}
                    // csvLinkRef={this.csvLinkRef}
                    campTableExport={this.state.campTableExport}
                    campTableExportHandler={this.campTableExportHandler}
                    createDMClickHandler={this.createDMClickHandler}
                    sendFiltersLoading={this.sendFiltersLoading}
                    TYSelectedDate={this.state.TYSelectedDate}
                  />
                  {this.state.calendarWrapperArr.length ? (
                    <div>
                      <CalendarWrapper
                        showPromotionMain={this.showPromotionMain}
                        onTabClick={this.showPromotionPipeline}
                        selectedYear={Number(this.state.selectedYear) - 1}
                        calendarDataArr={this.props.calendarDataPerYear}
                        calendarWrapperArr={this.state.calendarWrapperArr}
                        onDmEdit={this.onDmEditHandler}
                        view={this.state.selectedView}
                      />
                      <button
                        type="button"
                        className="btn btn-primery btn-calendar btn-lg"
                        id="cal-prev-btn"
                        style={this.state.styleGetPrev}
                        onClick={() => {
                          this.getPrev();
                          this.submitGA("Click", "Clicked_ArrowButton");
                        }}
                      >
                        <i className="fa fa-angle-left " />
                      </button>

                      <button
                        style={this.state.styleGetNext}
                        type="button"
                        id="cal-next-btn"
                        className="btn btn-primery btn-calendar btn-lg"
                        onClick={() => {
                          this.getNext();
                          this.submitGA("Click", "Clicked_ArrowButton");
                        }}
                      >
                        <i className="fa fa-angle-right promoIcon" />
                      </button>
                    </div>
                  ) : !this.state.calendarWrapperArr.length &&
                    this.state.componentLoaded ? (
                    <div className="Row noDataDiv">No data to show</div>
                  ) : (
                    <div className="Row noDataDiv"></div>
                  )}
                </div>
              </div>

              <Element name="showPromotionTag" className="element">
                <PromotionPipeline
                  call_edit_adgroup_in_createPromo={(adgroupData) =>
                    this.call_edit_adgroup(adgroupData)
                  }
                  selectedPromo={this.state.selectedPromo}
                  campaignStartDate={this.state.campaignStartDate}
                  campaignEndDate={this.state.campaignEndDate}
                  selectedPromoName={this.state.selectedPromoName}
                  selectedCustomerGropu={this.state.selectedCustomerGropuValue}
                  selectedBusinessDesc={this.state.selectedBusinessDescValue}
                  selectedShop={this.state.selectedShopState}
                  historyCampaignId={this.state.historyCampaignId}
                  historyCampaignName={this.state.historyCampaignName}
                  detailsBtnClicked={this.state.detailsBtnClicked}
                  clearPipelineTable={this.state.clearPipelineTable}
                  sendFiltersLoading={this.sendFiltersLoading}
                  callPipelineTY={this.state.callPipelineTY}
                  callPipelineTYHandler={this.callPipelineTYHandler}
                  adGrpArr={_.uniq(this.state.adGrpArr)}
                  onDmEditHandler={this.onDmEditHandler}
                  campaignType={this.state.campaignType}
                  createdBy={this.state.createdBy}
                  createdAt={this.state.createdAt}
                  changedBy={this.state.changedBy}
                  changedAt={this.state.changedAt}
                  campMerchLockoutDate={this.state.campMerchLockoutDate}
                />
              </Element>
              <div />
            </div>
          ) : (
            <PromotionMain showSelectedValues={this.state.showSelectedValues} />
          )}
        </PageLoader>

        {this.state.showCampaignList ? (
          <DownloadCampaignDataModal
            showCampaignList={this.state.showCampaignList}
            closeModal={this.closeModal}
            loaderAg={this.state.loaderAg}
            getCampaignDmList={this.props.getCampaignDmList}
            downloadActiveMethod={this.downloadActiveMethod}
          />
        ) : null}

        {this.state.showCreateDM ? (
          <CreateDM
            createDMClickHandler={this.createDMClickHandler}
            onDmCreate={this.onDmEditHandler}
          />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    view: state.calendar.view,
    getShopData: state.adGroupData.getFilterDataShop,
    getFilterDataCustomerGroup: state.adGroupData.getFilterDataCustomerGroup,
    getFilterDataBusiness: state.adGroupData.getFilterDataBusiness,
    calendarStrucure: state.calendar.calendarStrucure,
    calendarDataPerYear: state.calendar.calendarDataPerYear,
    getMerchCatData: state.adGroupData.getFilterDataMerch,
    getDistinctAdGroup: state.adGroupData.getDistinctAdGroup,
    pipelineData: state.calendar.pipelineData,
    authentication: state.userLoginDetails,
    loader: state.calendar.loader,
    validationLoader: state,
    summaryCumulativeFilters: state.summaryData.summaryCumulativeFilters,
    getCampaignDmList: state.adGroupData.getCampaignDmList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSubmit: (val) => dispatch({ type: val }),
    calendarDatePlot: (val) => dispatch(calendarDatePlot(val)),
    calendarData: (val) => dispatch(calendarData(val)),
    getCampaignDm: (val) => dispatch(getCampaignDm(val)),
    batchProcessCheck: (val) => dispatch(batchProcessCheck(val)),
    getLogedInUserDetails: (val) => dispatch(getLogedInUserDetails(val)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PromoCalendar));
