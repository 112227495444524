import {
  valueFormatterHandler,
  valueGMFormatterHandler,
  varPerHandler
} from "../../../utils/valueFormatters";

function promoTypeFormatter(params) {
  let promoTypeSet = JSON.parse(localStorage.getItem("promo_type"));

  return promoTypeSet
    ? promoTypeSet[params.data.event_type]
    : params.data.event_type;
}

function emptyValueFormatterForString(params) {
  if (params.data[params.colDef.field]) {
    return params.data[params.colDef.field];
  } else {
    return "-";
  }
}

let GMPercentage = [
  {
    headerName: "GM %",
    children: [
      {
        headerName: "TY (%)",
        field: "ty_gm",
        valueFormatter: valueGMFormatterHandler,
        filter: "agTextColumnFilter",
        width: 100,
        unSortIcon: true
      },
      {
        headerName: "LY (%)",
        field: "ly_gm",
        valueFormatter: valueGMFormatterHandler,
        filter: "agTextColumnFilter",
        width: 100,
        unSortIcon: true
      }
    ]
  }
];

let uniqueHeaderVals = [
  {
    headerName: "Units",
    children: [
      {
        headerName: "TY",
        field: "ty_total_units",
        valueFormatter: valueFormatterHandler,
        aggFunc: "sum",
        filter: "agTextColumnFilter",
        width: 150,
        unSortIcon: true
      },
      {
        headerName: "LY",
        // valueFormatter: emptyValueFormatter,
        field: "ly_total_units",
        valueFormatter: valueFormatterHandler,
        aggFunc: "sum",
        filter: "agTextColumnFilter",
        width: 150,
        unSortIcon: true
      },
      {
        headerName: "VAR%",
        field: "var_quantity",
        filter: "agTextColumnFilter",
        // valueFormatter: emptyValueFormatterForVar,
        // valueGetter: valueFormatterHandler,
        valueFormatter: varPerHandler,
        aggFunc: "avg",
        width: 150,
        unSortIcon: true
      }
    ]
  },
  {
    headerName: "Net Sales ($)",
    children: [
      {
        headerName: "TY ($)",
        field: "ty_total_sales",
        valueFormatter: valueFormatterHandler,
        aggFunc: "sum",
        filter: "agTextColumnFilter",
        width: 150,
        unSortIcon: true
      },
      {
        headerName: "LY ($)",
        field: "ly_total_sales",
        valueFormatter: valueFormatterHandler,
        aggFunc: "sum",
        filter: "agTextColumnFilter",
        width: 150,
        unSortIcon: true
      },
      {
        headerName: "VAR%",
        field: "var_total_revenue",
        filter: "agTextColumnFilter",
        valueFormatter: varPerHandler,
        aggFunc: "avg",
        width: 150,
        unSortIcon: true
      }
    ]
  },
  {
    headerName: "Margin ($)",
    children: [
      {
        headerName: "TY ($)",
        field: "ty_total_margin",
        valueFormatter: valueFormatterHandler,
        aggFunc: "sum",
        filter: "agTextColumnFilter",
        width: 150,
        unSortIcon: true
      },
      {
        headerName: "LY ($)",
        field: "ly_total_margin",
        valueFormatter: valueFormatterHandler,
        aggFunc: "sum",
        filter: "agTextColumnFilter",
        width: 150,
        unSortIcon: true
      },
      {
        headerName: "VAR%",
        field: "var_total_margin",
        filter: "agTextColumnFilter",
        valueFormatter: varPerHandler,
        aggFunc: "avg",
        width: 150,
        unSortIcon: true
      }
    ]
  },
  ...GMPercentage
];

export default {
  defaultColDef: {
    sortable: true
  },
  defaultColDefForAdgroup: {
    resizable: true,
    sortable: true,
    filter: true
  },
  columnDefsForCust: [
    {
      headerName: "Customer Group",
      field: "h6",
      width: 250,
      filter: "agTextColumnFilter",
      unSortIcon: true
    },
    ...uniqueHeaderVals
  ],
  columnDefsForCustEvent: [
    {
      headerName: "Customer Group",
      field: "h6",
      width: 250,
      filter: "agTextColumnFilter",
      unSortIcon: true
    },
    {
      headerName: "Event type",
      field: "event_type",
      width: 250,
      filter: "agTextColumnFilter",
      unSortIcon: true,
      // valueGetter: promoTypeFormatter
    },
    ...uniqueHeaderVals
  ],
  columnDefsForBusiness: [
    {
      headerName: "Customer Group",
      field: "h6",
      width: 250,
      filter: "agTextColumnFilter",
      unSortIcon: true
    },
    {
      headerName: "Business Group",
      field: "h5",
      width: 250,
      filter: "agTextColumnFilter",
      unSortIcon: true
    },

    ...uniqueHeaderVals
  ],
  columnDefsForBusinessEvent: [
    {
      headerName: "Customer Group",
      field: "h6",
      width: 250,
      filter: "agTextColumnFilter",
      unSortIcon: true
    },
    {
      headerName: "Business Group",
      field: "h5",
      width: 250,
      filter: "agTextColumnFilter",
      unSortIcon: true
    },
    {
      headerName: "Event type",
      field: "event_type",
      width: 250,
      filter: "agTextColumnFilter",
      unSortIcon: true,
      // valueGetter: promoTypeFormatter
    },
    ...uniqueHeaderVals
  ],
  columnDefsForCategory: [
    {
      headerName: "Customer Group",
      field: "h6",
      width: 250,
      filter: "agTextColumnFilter",
      unSortIcon: true
    },
    {
      headerName: "Business Group",
      field: "h5",
      width: 250,
      filter: "agTextColumnFilter",
      unSortIcon: true
    },
    {
      headerName: "Shop",
      field: "h3",
      width: 250,
      filter: "agTextColumnFilter",
      unSortIcon: true
    },
    {
      headerName: "Category",
      field: "h2",
      width: 250,
      filter: "agTextColumnFilter",
      unSortIcon: true
    },
    ...uniqueHeaderVals
  ],
  columnDefsForCategoryEvent: [
    {
      headerName: "Customer Group",
      field: "h6",
      width: 250,
      filter: "agTextColumnFilter",
      unSortIcon: true
    },
    {
      headerName: "Business Group",
      field: "h5",
      width: 250,
      filter: "agTextColumnFilter",
      unSortIcon: true
    },
    {
      headerName: "Shop",
      field: "h3",
      width: 250,
      filter: "agTextColumnFilter",
      unSortIcon: true
    },
    {
      headerName: "Category",
      field: "h2",
      width: 250,
      filter: "agTextColumnFilter",
      unSortIcon: true
    },
    {
      headerName: "Event type",
      field: "event_type",
      width: 250,
      filter: "agTextColumnFilter",
      unSortIcon: true,
      // valueGetter: promoTypeFormatter
    },
    ...uniqueHeaderVals
  ],
  columnDefsForShop: [
    {
      headerName: "Customer Group",
      field: "h6",
      width: 250,
      filter: "agTextColumnFilter",
      unSortIcon: true
    },
    {
      headerName: "Business Group",
      field: "h5",
      width: 250,
      filter: "agTextColumnFilter",
      unSortIcon: true
    },
    {
      headerName: "Shop",
      field: "h3",
      width: 250,
      filter: "agTextColumnFilter",
      unSortIcon: true
    },
    ...uniqueHeaderVals
  ],
  columnDefsForShopEvent: [
    {
      headerName: "Customer Group",
      field: "h6",
      width: 250,
      filter: "agTextColumnFilter",
      unSortIcon: true
    },
    {
      headerName: "Business Group",
      field: "h5",
      width: 250,
      filter: "agTextColumnFilter",
      unSortIcon: true
    },
    {
      headerName: "Shop",
      field: "h3",
      width: 250,
      filter: "agTextColumnFilter",
      unSortIcon: true
    },
    {
      headerName: "Event type",
      field: "event_type",
      width: 250,
      filter: "agTextColumnFilter",
      unSortIcon: true,
      // valueGetter: promoTypeFormatter
    },
    ...uniqueHeaderVals
  ],
  columnDefsForMerch: [
    {
      headerName: "Customer Group",
      field: "h6",
      width: 250,
      filter: "agTextColumnFilter",
      unSortIcon: true
    },
    {
      headerName: "Business Group",
      field: "h5",
      width: 250,
      filter: "agTextColumnFilter",
      unSortIcon: true
    },
    {
      headerName: "Shop",
      field: "h3",
      width: 250,
      filter: "agTextColumnFilter",
      unSortIcon: true
    },
    {
      headerName: "Category",
      field: "h2",
      width: 250,
      filter: "agTextColumnFilter",
      unSortIcon: true
    },
    {
      headerName: "Merchant Category",
      field: "h1",
      width: 250,
      filter: "agTextColumnFilter",
      unSortIcon: true
    },
    ...uniqueHeaderVals
  ],
  columnDefsForMerchEvent: [
    {
      headerName: "Customer Group",
      field: "h6",
      width: 250,
      filter: "agTextColumnFilter",
      unSortIcon: true
    },
    {
      headerName: "Business Group",
      field: "h5",
      width: 250,
      filter: "agTextColumnFilter",
      unSortIcon: true
    },
    {
      headerName: "Shop",
      field: "h3",
      width: 250,
      filter: "agTextColumnFilter",
      unSortIcon: true
    },
    {
      headerName: "Category",
      field: "h2",
      width: 250,
      filter: "agTextColumnFilter",
      unSortIcon: true
    },
    {
      headerName: "Merchant Category",
      field: "h1",
      width: 250,
      filter: "agTextColumnFilter",
      unSortIcon: true
    },
    {
      headerName: "Event type",
      field: "event_type",
      width: 250,
      filter: "agTextColumnFilter",
      unSortIcon: true,
      // valueGetter: promoTypeFormatter
    },
    ...uniqueHeaderVals
  ],
  columnDefsForAdgroup: [
    {
      headerName: "Merch Manager ID",
      field: "merch_id",
      width: 150,
      filter: "agTextColumnFilter",
      valueGetter: emptyValueFormatterForString,
      unSortIcon: true
    },
    {
      headerName: "Shop",
      field: "shop",
      width: 150,
      filter: "agTextColumnFilter",
      unSortIcon: true
    },
    {
      headerName: "Adgroup",
      field: "adgroup_name",
      width: 150,
      filter: "agTextColumnFilter",
      unSortIcon: true
    },
    {
      headerName: "Adgroup Description",
      field: "adgroup_desc",
      width: 250,
      filter: "agTextColumnFilter",
      unSortIcon: true
    },
    {
      headerName: "Event Type",
      field: "event_type",
      width: 125,
      filter: "agTextColumnFilter",
      unSortIcon: true,
      // valueGetter: promoTypeFormatter
    },
    ...uniqueHeaderVals
  ],
  columnDefsForPromo: [
    {
      headerName: "Promo Type",
      // field: "promo_type",
      field: "event_type",
      width: 180,
      filter: "agTextColumnFilter",
      unSortIcon: true,
      // valueGetter: promoTypeFormatter
    },
    ...uniqueHeaderVals
  ],
  columnDefsForTotal: [
    {
      headerName: "Total - level",
      field: "total",
      width: 180,
      filter: "agTextColumnFilter",
      unSortIcon: true
    },
    ...uniqueHeaderVals
  ]
};
