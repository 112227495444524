import React, { Component } from "react";
import _ from "lodash";
import { AdgroupMasterGA } from '../../../../utils/GA/GAUtils';
import { DeleteTwoTone } from '@ant-design/icons';
import {isIt} from '../../../../utils/getRoleName.js'
import './AdgroupMasterStyling.css'

export class ChildMessageRenderer extends Component {
  viewSku = () => {
    this.props.data.viewSku(this.props.data);
    this.submitGA("Click", "Clicked_ViewSKUListButton");
  };

  deleteAdgroup = () => {
    let flag = _.isEmpty(this.props.data.status) ? 0 : 1;
    this.props.data.deleteAdgroup(this.props.data.id, flag);
    this.submitGA("Click", "Clicked_ActivateAdgroupButton");

  };
  submitGA(action,label){
    AdgroupMasterGA({ action, label});
  }

  editAdgroup = (adgroupData) => {
    localStorage.setItem(
      "adgroupDetailsFromMaster",
      JSON.stringify([adgroupData])
    );
    this.props.history.push("/editadgroup?fromAdgroupMaster=true");
  }

  deactivateAdgroup = (adgroupData) => {
    this.props.deleteAdgroup(
      [adgroupData],
      0
    );
  }
  
  hideDeleteButton =()=>{
    let role = localStorage.getItem("role")
    if(["2","3"].indexOf(role)!==-1){
      return false;
    }
    return true;
  }

  render() {
    const data = _.get(this.props, 'data', null);
    const finalAdgroupData = {};
    finalAdgroupData.adgroupId = _.get(data, 'id', null);

    return (
      <div className="row justify-content-center">
        <div className="col-3 text-center">
          <i
            className="fa fa-file text-cyan"
            title="View SKU list"
            onClick={this.viewSku}
          />
        </div>

        {_.isEmpty(this.props.data.status) && (
          <div className="col-3 text-center">
            <i
              className="fa fa-edit text-cyan"
              aria-hidden="true"
              title="Edit Adgroup"
              onClick={() => this.editAdgroup(finalAdgroupData)}
            />
          </div>
        )}

        {_.isEmpty(this.props.data.status) && this.hideDeleteButton() && (
          <div className="col-3 text-center">
            <DeleteTwoTone
            className={isIt() ? "disableCursor" : "" }
              // onClick={() => this.deactivateAdgroup(finalAdgroupData)}
              onClick={isIt() ? (e)=>{e.preventDefault()} : () => this.deactivateAdgroup(finalAdgroupData)}
            />
          </div>
        )}
        
        <div className="col-3 text-center">
          { (_.isEmpty(this.props.data.status)) ? null : (
            <i
              className={ isIt() ? "fa fa-check text-cyan disableCursor" : "fa fa-check text-cyan"}
              title="Activate Adgroup"
              // onClick={this.deleteAdgroup}
              onClick={isIt() ? (e)=>{e.preventDefault()} : this.deleteAdgroup}
            />
          )}
        </div>
      </div>
    );
  }
}

export default ChildMessageRenderer;
