import {
  LOADER,
  SET_PROMO_APPROVAL_DATA,
  UPDATE_PROMO_APPROVAL_DATA
} from "../actions/types";

import _ from "lodash";

const initialState = {
  loader: false,
  promoApprovalData: [],
};


export default function (state = initialState, action) {
  switch (action.type) {

    case LOADER:
      return {
        ...state,
        loader: action.payload
      };
    case SET_PROMO_APPROVAL_DATA:
      return {
        ...state,
        promoApprovalData: action.payload
      };
      case UPDATE_PROMO_APPROVAL_DATA:
      const updatedData = action.payload; 
      const updatedPromoApprovalData = state.promoApprovalData.map(item => {
        if (item.id === updatedData.id) { 
          return {
            ...item,
            note_mar: action.payload.notes
            
          };
        }
        return item;
      });
      return {
        ...state,
        promoApprovalData: updatedPromoApprovalData
      };
    default:
      return state;
  }
}
