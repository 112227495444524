import React from 'react';
import Table from '../../Components/Table';
import columns from './columns';

export default function TicketsTable(props) {
  const onCellClicked = (params) => {
    if (params.colDef.field === 'id') {
      props.onTicketClick(params.data.id);
    }
  };

  const getRowHeight = (params) => {
    const len = params.data.description.length;
    return len > 50 ? 30 * Math.ceil(len / 70) : 30;
  };

  return (
    <div className="ticket-table" style={{ height: 'calc(100vh - 227px)' }}>
      <Table
        getRowHeight={getRowHeight}
        defaultColDef={columns.defaultColDef}
        columnDefs={columns.columnDefs}
        onCellClicked={onCellClicked}
        rowData={props.rowData}
      />
    </div>
  );
}
