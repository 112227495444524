import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PageLoader from "../../../../utils/Loader";
import PropTypes from "prop-types";
import AlertSuccess from "../../../../utils/AlertMessage/alertSuccess";
import AlertDanger from "../../../../utils/AlertMessage/alertDanger";
import { getCampaignsDetails } from "../../../../redux/actions/calendarAction";
import DataTable from "../../../tables";
import ColumnDef from "../../../tables/colDefs/ReviewOfferColDefs";
import PipelineDropdownRenderer from "../Promo Pipeline/PipelineDropdownRenderer";
import PipelineInputRenderer from "../Promo Pipeline/PipelineInputRenderer";
import _ from "lodash";
import Modal from "react-awesome-modal";
import offerTypeOptions from "../../../../utils/OfferTypes";

var index = null;
export class PromoOfferModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      saveButtonFlag: true,
      showAlertSuccess: false,
      selectedOfferType: [],
      offerValueArray: [],
      clusterArray: [],
      deleteOfferApiCall: false,
      defaultSelectedOffer: [],
      offerValuess: [],
      dateArray: [],
      getAdgroupOfferState: [],
      inputValidationID: null,
      reviewSearchOn: false,
      reviewOfferCommentString: "",
      saveClicked: false,
      rowIndex: "",
      showModal: true,
      overlayNoRowsTemplate:
        '<span style="padding: 10px; boxShadow: 0 1px 15px 1px rgba(62, 57, 107); color: #2d69eb;  border: 1px solid lightgray">No TY SKU</span>',
      offerData: [],
      plannedOfferChanged: false,
      alertDanger: false,
      alertDangerMsg: "",
    };
  }

  componentDidMount() {
    var offerData = [];
    var offerDataClone = this.props.offerDetails;

    if (this.props.offerDetails !== undefined) {
      _.map(offerDataClone, (item) => {
        item.rowRefreshHandler = this.rowRefreshHandler;
        item.offerTypeOptions = offerTypeOptions.newAdgroupOfferTypes;
        item.ty_offer_type =
          item.offer_type === "nForM" ? "F" : item.offer_type;
        item.ty_planned_amount = item.offer_value;
        item.ty_min_qty = item.min_quantity;
        item.ty_bonus_pay = item.bonus_by_quantity;
        item.plannedOfferHandler = this.plannedOfferHandler;
        item.error_flag = false;
        return item;
      });

      offerData = {
        header: ColumnDef.reviewOfferHeader,
        defaultColDef: ColumnDef.defaultColDef,
        data: offerDataClone,
      };
      this.setState({ offerData });
    }
  }
  plannedOfferHandler = (index) => {
    this.setState({
      plannedOfferChanged: !this.state.plannedOfferChanged,
      rowIndex: index,
    });
  };

  rowRefreshHandler = (index) => {
    this.setState({
      saveClicked: !this.state.saveClicked,
      rowIndex: index,
    });
  };

  render() {
    return (
      <div className="card" style={{ marginTop: "30px" }}>
        <div className="alertDivs">
          {this.state.showAlertSuccess ? (
            <AlertSuccess
              message="Saved Successfully!"
              show={this.state.showAlertSuccess}
            />
          ) : null}
          {this.state.alertDanger ? (
            <AlertDanger
              message={this.state.alertDangerMsg}
              show={this.state.alertDanger}
            />
          ) : null}
        </div>
        <Modal
          visible={this.props.showOfferModal}
          width="90%"
          height="85%"
          effect="fadeInDown"
          onClickAway={() => this.props.showOfferModalHandler()}
        >
          <PageLoader
            loader={
              this.props.loader ||
              !this.props.offerDetails ||
              this.props.offerDetails.length === 0
            }
          >
            <div className="mx-3">
              <div className="closeicon">
                <span style={{ cursor: "pointer" }}>
                  <i
                    className="fa fa-times"
                    aria-hidden="true"
                    onClick={() => this.props.showOfferModalHandler()}
                  />
                </span>
              </div>
              <h4 className="promoHeading">Review Offers</h4>

              <DataTable
                index={index}
                tableData={this.state.offerData}
                overlayNoRowsTemplate={this.state.overlayNoRowsTemplate}
                autoSizeCol={true}
                frameworkComponents={{
                  OfferTypeRenderer: PipelineDropdownRenderer,
                  pipelineInputRenderer: PipelineInputRenderer,
                }}
                saveClicked={this.state.saveClicked}
                rowRefreshHandler={this.rowRefreshHandler}
                rowIndex={this.state.rowIndex}
                plannedOfferChanged={this.state.plannedOfferChanged}
                plannedOfferHandler={this.plannedOfferHandler}
              />
            </div>
          </PageLoader>
        </Modal>
      </div>
    );
  }
}

PromoOfferModal.propTypes = {
  getCampaignsDetails: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  saveAdgroupData: state.simulationData.offerData,
  loader: state.simulationData.loader,
  getOfferData: state.simulationData.getOfferData,
});

export default connect(mapStateToProps, {
  getCampaignsDetails,
})(withRouter(PromoOfferModal));
