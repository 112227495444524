import React, { Component, PureComponent } from "react";
import CanvasJSReact from "../../assets/canvasjs.react";
import { emptyValueFormatter } from "../../utils/valueFormatters.js";
import _ from "lodash";
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

class WaterfallChart extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      options: null,
    };
  }

  render() {
    let baselineMargin = 0;
    let incrementalMargin = 0;
    let otherMargin = 0;
    let miniTotalMargin = 0;
    let affinityMargin = 0;
    let cannibalisationmargin = 0;
    let totalMargin = 0;
    let offerValue = this.props.offerValue;
    let waterfallChartData = null;
    let margin_forecast_override = this.props.margin_forecast_override;
    let finalCumulativeMargin = 0;

    if (this.props.responseKey === -1 && this.props.tyOffer[0]) {
      //Ty offer Waterfall tab section
      baselineMargin = this.props.tyOffer[0].baseline_margin;

      incrementalMargin = this.props.tyOffer[0].promo_inc_margin;
      affinityMargin = this.props.tyOffer[0].affinity_margin;
      cannibalisationmargin = this.props.tyOffer[0].cannibalization_margin;
      otherMargin =
        this.props.tyOffer[0].total_margin -
        (baselineMargin + incrementalMargin);
      miniTotalMargin = this.props.tyOffer[0].total_margin;
    } else if (
      this.props.responseKey !== -1 &&
      (this.props.responseKey < 0 || _.isEmpty(this.props.offerBuildeRes))
    ) {
      //Recommended tab sections

      if (this.props.offerType === "P") {
        waterfallChartData = this.props.waterfallData[0];
      } else if (this.props.offerType === "F") {
        waterfallChartData = this.props.waterfallData[1];
      } else {
        waterfallChartData = this.props.waterfallData[2];
      }
      if (waterfallChartData["output"][offerValue] !== undefined) {
        if (
          waterfallChartData["output"][offerValue]["totalBaselineMargin"] !==
          undefined
        ) {
          baselineMargin =
            waterfallChartData["output"][offerValue]["totalBaselineMargin"];
        }
        if (
          waterfallChartData["output"][offerValue]["totalIncrementalMargin"] !==
          undefined
        ) {
          incrementalMargin =
            waterfallChartData["output"][offerValue]["totalIncrementalMargin"];
        }
        if (
          waterfallChartData["output"][offerValue]["totalAffinitymargin"] !==
          undefined
        ) {
          affinityMargin =
            waterfallChartData["output"][offerValue]["totalAffinitymargin"];
        }
        if (
          waterfallChartData["output"][offerValue][
            "totalCannibalisationmargin"
          ] !== undefined
        ) {
          cannibalisationmargin =
            waterfallChartData["output"][offerValue][
              "totalCannibalisationmargin"
            ];
        }

        if (
          waterfallChartData["output"][offerValue]["totalMargin"] !== undefined
        ) {
          otherMargin =
            waterfallChartData["output"][offerValue]["totalMargin"] -
            (baselineMargin + incrementalMargin);

          miniTotalMargin =
            waterfallChartData["output"][offerValue]["totalMargin"];
        }
      }
    } else {
      if (
        this.props.responseKey >= 0 &&
        this.props.offerBuildeRes &&
        this.props.offerValue &&
        this.props.offerType
      ) {
        baselineMargin = this.props.offerBuildeRes[this.props.responseKey][
          "output"
        ][this.props.offerValue]["totalBaselineMargin"];

        incrementalMargin = this.props.offerBuildeRes[this.props.responseKey][
          "output"
        ][this.props.offerValue]["totalIncrementalMargin"];

        affinityMargin = this.props.offerBuildeRes[this.props.responseKey][
          "output"
        ][this.props.offerValue]["totalAffinitymargin"];

        cannibalisationmargin = this.props.offerBuildeRes[
          this.props.responseKey
        ]["output"][this.props.offerValue]["totalCannibalisationmargin"];

        otherMargin =
          this.props.offerBuildeRes[this.props.responseKey]["output"][
            this.props.offerValue
          ]["totalMargin"] -
          (baselineMargin + incrementalMargin);

        miniTotalMargin = this.props.offerBuildeRes[this.props.responseKey][
          "output"
        ][this.props.offerValue]["totalMargin"];
      }
    }

    totalMargin =
      otherMargin +
      baselineMargin +
      incrementalMargin +
      affinityMargin +
      cannibalisationmargin;

    finalCumulativeMargin =
      incrementalMargin + affinityMargin + cannibalisationmargin;

    const optionsWaterfall = {
      animationEnabled: true,
      exportEnabled: true,
      title: {
        text: "Margin",
        fontSize: 14,
        fontFamily: "Poppins",
      },
      axisY: {
        // valueFormatString: "$#,#0,.K",
        labelFontSize: 12,
        labelMaxWidth: 70,
        labelFontColor: "gray",
        gridThickness: 0,
        stripLines: [
          {
            value: 0,
            showOnTop: true,
            color: "gray",
            thickness: 2,
          },
        ],
      },
      axisX: {
        labelFontColor: "gray",
        labelFontSize: 12,
      },
      toolTip: {
        shared: true,
        contentFormatter: function (e) {
          return (
            e.entries[0].dataPoint.label +
            ":" +
            emptyValueFormatter(e.entries[0].dataPoint.y)
          );
        },
      },
      height: 250,
      data: [
        {
          risingColor: "#5B9BD5",
          fallingColor: "#ED7D31",
          type: "waterfall",
          // yValueFormatString: "$#,##0,.00K",
          indexLabelOrientation: "vertical",
          lineColor: "gray",
          dataPoints: [
            { label: "Baseline", y: Math.round(baselineMargin) },
            { label: "Incremental", y: Math.round(incrementalMargin) },
            { label: "Other", y: Math.round(otherMargin) },
            {
              label: "Total",
              y: Math.round(miniTotalMargin),
              isIntermediateSum: true,
            },
            { label: "Affinity", y: Math.round(affinityMargin) },
            { label: "Cannibalisation", y: Math.round(cannibalisationmargin) },
            {
              label: "Total with Aff. & Cann.",
              y: Math.round(totalMargin),
              isCumulativeSum: true,
            },
            {
              label: "Margin Forecast Override",
              y: Math.round(margin_forecast_override),
            },
          ],
        },
      ],
    };

    var roundedBaselineMargin = emptyValueFormatter(baselineMargin);
    var roundedIncrementalMargin = emptyValueFormatter(incrementalMargin);
    var roundedAffinityMargin = emptyValueFormatter(affinityMargin);
    var roundedCannibalisationMargin = emptyValueFormatter(
      cannibalisationmargin
    );
    var roundedTotalMargin = emptyValueFormatter(totalMargin);
    var roundedOtherMargin = emptyValueFormatter(otherMargin);
    var roundedMiniTotalMargin = emptyValueFormatter(miniTotalMargin);
    var roundedFinalCumulativeMargin = emptyValueFormatter(
      finalCumulativeMargin
    );

    return (
      <div>
        <div className="card" style={{ marginTop: "6px" }}>
          <CanvasJSChart options={optionsWaterfall} />
          <div className="watermarkdiv" />
        </div>
        <div className="card" style={{ marginTop: "10px" }}>
          <table className="table text-center">
            <thead className="theadstyle">
              <tr>
                <th> Metrics </th>
                <th>Values</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="waterfallTabledta" style={{ width: "60%" }}>
                  Baseline Margin
                </td>
                <td className="waterfallTabledta">
                  {roundedBaselineMargin != 0
                    ? "$ " + roundedBaselineMargin
                    : "-"}
                </td>
              </tr>
              <tr>
                <td className="waterfallTabledta">Incremental Margin (1)</td>
                <td className="waterfallTabledta">
                  {roundedIncrementalMargin != 0
                    ? "$ " + roundedIncrementalMargin
                    : "-"}
                </td>
              </tr>
              <tr>
                <td className="waterfallTabledta">Other Margin</td>
                <td className="waterfallTabledta">
                  {roundedOtherMargin != 0 ? "$ " + roundedOtherMargin : "-"}
                </td>
              </tr>
              <tr>
                <td className="waterfallTabledta" style={{ color: "orange" }}>
                  Total Margin
                </td>
                <td className="waterfallTabledta" style={{ color: "orange" }}>
                  {roundedMiniTotalMargin != 0
                    ? "$ " + roundedMiniTotalMargin
                    : "-"}
                </td>
              </tr>
              <tr>
                <td className="waterfallTabledta">Affinity Margin (2)</td>
                <td className="waterfallTabledta">
                  {roundedAffinityMargin != 0
                    ? "$ " + roundedAffinityMargin
                    : "-"}
                </td>
              </tr>
              <tr>
                <td className="waterfallTabledta">
                  Cannibalisation Margin (3)
                </td>
                <td className="waterfallTabledta">
                  {roundedCannibalisationMargin != 0
                    ? "$ " + roundedCannibalisationMargin
                    : "-"}
                </td>
              </tr>
              <tr>
                <td className="waterfallTabledta" style={{ color: "orange" }}>
                  Total Margin with Affinity & Cannibalisation
                </td>
                <td className="waterfallTabledta" style={{ color: "orange" }}>
                  {roundedTotalMargin != 0 ? "$ " + roundedTotalMargin : "-"}
                </td>
              </tr>
              <tr>
                <td className="waterfallTabledta" style={{ color: "orange" }}>
                  Total Incremental Margin (1+2+3)
                </td>
                <td className="waterfallTabledta" style={{ color: "orange" }}>
                  {roundedFinalCumulativeMargin != 0
                    ? "$ " + roundedFinalCumulativeMargin
                    : "-"}
                </td>
              </tr>
              <tr>
                <td className="waterfallTabledta" style={{ color: "orange" }}>
                  Margin Forecast Override
                </td>
                <td className="waterfallTabledta" style={{ color: "orange" }}>
                  {margin_forecast_override
                    ? "$ " + margin_forecast_override
                    : "-"}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default WaterfallChart;
