import React, { Component } from "react";
import PageLoader from "../../../../utils/Loader";
import FilterReport from "./filterReport";
import AgdarReport from './AgdarReport';
import AgparReport from './AgparReport';
import { connect } from "react-redux";
import { getAgdarReportData, getAgparReportData, getAdGroupDetails, getThemeDetails, getBuyerDetails, getEventIdDetails, getArticleDetails, getPriceListDetails, getGmmDetails } from "../../../../redux/actions/reportsAction";

class ReportContainer extends Component {

  constructor(props) {
    super(props);
    this.state = {
      selectedReportType: null,
    };
  }

  async componentDidMount() {
    await this.props.getBuyerDetails();
    await this.props.getAdGroupDetails();
    await this.props.getThemeDetails();
    // await this.props.getArticleDetails();
    await this.props.getGmmDetails();
    await this.props.getPriceListDetails();
    await this.props.getEventIdDetails();
  }

  handleReportTypeChange = (value) => {
    this.setState({ selectedReportType: value });
  };

  renderReportComponent = () => {
    const { selectedReportType } = this.state;  

    switch (selectedReportType) {
      case 'AGPAR':
        return <AgparReport />;
      case 'AGDAR':
        return <AgdarReport />;
      default:
        return null;
    }
  };


    render () {
      return (
        <PageLoader loader={this.props.loader} className="promoApproval">
        <div className="alertDivs">
          {/* {this.state.isFailure &&
            <AlertDanger
              message={this.state.failureMessage}
              show={this.state.isFailure}
            />
          }
          {this.state.isSuccess &&
            <AlertSuccess
              message={this.state.successMessage}
              show={this.state.isSuccess}
            />
          } */}
        </div>

          {/* Reports Title      */}
        <div className="card cardStyles cardGap">
          <div className="px-4 mb-4">
            <div className="createClusterDiv">
              <div className="row mt-4 mx-0">
                <div className="col text-center">
                  <h4 className="promoHeading mb-0">Reports</h4>
                </div>
              </div>

             {/* After Reports */}
              <div className="reportContainer">
                 <FilterReport onReportTypeChange={this.handleReportTypeChange}/>
              <hr style={{ height: '1px', borderWidth: '0', backgroundColor: '#d3d3d3' }} />
              {this.renderReportComponent()}
              </div>



            </div>
          </div>
        </div>

      </PageLoader>
      )
    }
}

const mapStateToProps = (state) => {
  return {
    loader: state.reports.loader,
  };
};

export default connect(
  mapStateToProps,
  {
    getBuyerDetails,
    getAdGroupDetails,
    getThemeDetails,
    getAgparReportData,
    getAgdarReportData,
    getArticleDetails,
    getGmmDetails,
    getPriceListDetails,
    getEventIdDetails
  }
)(ReportContainer);