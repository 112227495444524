import React from "react";
import { connect } from "react-redux";
import _, { isEmpty, update } from "lodash";
import Modal from "react-awesome-modal";
import { Container, Row, Col } from 'react-bootstrap';
import "./promoPipeline.css";
import Select from "react-select";
import moment from "moment";

import DataTable from "../../../tables";
import ColumnDef from "../../../tables/colDefs/BulkModifyOffersColDef";

import AlertDanger from "../../../../utils/AlertMessage/alertDanger";
import PageLoader from "../../../../utils/Loader";
import AlertSuccess from "../../../../utils/AlertMessage/alertSuccess";
import OfferTypes from "../../../../utils/OfferTypes";
import ApprovalStatusRenderer from "./ApprovalStatusRenderer";
import {
  AtypeOfferValidator,
  PtypeOfferValidator,
  FtypeOfferValidator,
  GtypeOfferValidator,
  BtypeOfferValidator,
  CtypeOfferValidator,
  DtypeOfferValidator
} from "../../../../utils/offerValueValidators";

import { saveAdgroupFromIntermediate, fetchRegSalesPrices, fetchRegPrices } from "../../../../redux/actions/adgroup";
import { usersLogReport } from "../../../../redux/actions/userAction";
import { alertTimer } from "../../../../utils/alertTimer";
import {getSignShellOptions} from '../../../../redux/actions/signShellAction'
import {getOfferTitle} from "../../../../utils/offerTitle";
import PromoActionRenderer from "./PromoActionRenderer";
import {
  SIGNSHELL_OPTIONS,
  UAS_OPTIONS,
  PROMO_EVENT_TYPE_OPTIONS
} from "./constants";

import { EVENT_SIGNSHELL_CONSTANTS } from "../EventMaster/constants";
import {isIt} from "../../../../utils/getRoleName";

const initialOfferValues = {
  offer_amt: "",
  fixed_qty: "",
  min_qty: "",
  bonus_buy: "",
  buy_min_val: "",
  get_max_val: "",
}

export class BulkModifyOffersModal extends React.PureComponent {
  state = {
    showLoader: false,
    isFailure: false,
    failureMessage: "",
    isSuccess: false,
    successMessage: "",
    bulkModifyErrors : {},
    bulkModifyErrorsFromApproval : {},
    currentDataValues : {},
    modify : false,
    tableData: {},
    offer_type: [],
    offerValueObj: initialOfferValues,
    sign_shell: [],
    uas: [],
    promo_type: [],
    calculationChanged: false,
    calcRowIndex: "",
    isEdited: false,
    loggedUserRole: null,
    campaignMerchLockoutDate: null ,
    EVENT_SIGNSHELL_CONSTANTS2 : []
  }

  async componentDidMount() {
    let tempData = await this.props.getSignShellOptions()
    console.log(tempData)
    const signShellArray = tempData.signShell
    console.log("🚀 ~ componentDidMount ~ signShellArray:", signShellArray)
    const formattedData = signShellArray.map(item => ({
      value : item.value , 
      label : item.label
    }))
    this.setState({EVENT_SIGNSHELL_CONSTANTS2 : formattedData})
    console.log("************pipelineIntermediateData :::: ", this.props.pipelineIntermediateData);

    for(let it of this.props.pipelineIntermediateData) {
      this.state.currentDataValues[it.promo_id] = 
      (it.type) + '-' +
      (it.promo_type) + '-' +
      (it.event_start_date) + '-' +
      (it.event_end_date) +
      '@@' + '_' +
      (it.type)+'_'+
      (it.promo_sign_shell)+'_'+
      (it.promo_event_type)+'_'+
      (it.uas ? it.uas : it.ty_uas)+'_'+
      (it.promo_type)+'_'+
      (it.offer_type)+'_'+
      (it.offer_value || it.offer_value == 0 ? it.offer_value : 0)+'_'+
      (it.fixed_price_quantity || it.fixed_price_quantity==0 ? it.fixed_price_quantity : 0)+'_'+
      (it.min_quantity || it.min_quantity==0 ? it.min_quantity : 0)+'_'+
      (it.bonus_by_quantity || it.bonus_by_quantity==0 ? it.bonus_by_quantity : 0)+'_'+
      (it.ty_buy_min_value || it.ty_buy_min_value==0 ? it.ty_buy_min_value : 0)+'_'+
      (it.ty_get_max_value || it.ty_get_max_value==0 ? it.ty_get_max_value : 0)+'^^'+
      (it.name);
  }
    // console.log("currentDataValues",this.state.currentDataValues);
    console.log("************ColumnDef :::: ", ColumnDef);
    const tempcampaignMerchLockoutDate = localStorage.getItem("campMerchLockoutDate");
    const temploggedUserRole = localStorage.getItem("role");

    let sampleTableData = this.props.pipelineIntermediateData;

    sampleTableData.forEach(item => {
      item.getRegSalesPrices = this.getRegSalesPrices;
    });
    let tempTableData = {
      header: ColumnDef.header,
      defaultColDef: ColumnDef.defaultColDef,
      data: sampleTableData,
    };
    this.setState({
      tableData: tempTableData,
      loggedUserRole: temploggedUserRole,
      campaignMerchLockoutDate: tempcampaignMerchLockoutDate
    })
  }

  getRowStyle = (params) => {
    let status = params.data.uploadStatus;
    let status2 = params.status
    if (status === 'Failed' || status2 == false) {
        return { background: '#ffd6eb' };
    }
}

  modifyOffers = async () => {
    console.log("************state", this.state);

    this.setState({
      bulkModifyErrors: _.cloneDeep({}),
      bulkModifyErrorsFromApproval : _.cloneDeep({}),
      modify : false,
      showLoader: true
    })

    const regReqObj = [];
    let updatedData = this.state.tableData.data.map(row => {
      const tempObj = {
        adgroup_id: row.adgroup_id,
        fixed_price_quantity: !_.size(this.state.offer_type) ? row.fixed_price_quantity : this.state.offerValueObj.fixed_qty,
        ty_planned_amount: !_.size(this.state.offer_type) ? row.offer_value : this.state.offerValueObj.offer_amt,
        ty_min_qty: !_.size(this.state.offer_type) ? row.min_quantity : this.state.offerValueObj.min_qty,
        ty_bonus_pay: !_.size(this.state.offer_type) ? row.bonus_by_quantity : this.state.offerValueObj.bonus_buy,
        ty_buy_min_value: !_.size(this.state.offer_type) ? row.ty_buy_min_value : this.state.offerValueObj.buy_min_val,
        ty_get_max_value: !_.size(this.state.offer_type) ? row.ty_get_max_value : this.state.offerValueObj.get_max_val,
        promo_build_status: row.promo_build_status,
        regLow: row.regLow,
        regHigh: row.regHigh,
        saleLow: row.saleLow,
        saleHigh: row.saleHigh,
        adgroup_name: row.name,
        adgroup_desc: row.adgroup_description,
        adgroup_type: row.adgroup_type,
        merch_id: row.merch_id,
        ty_offer_type: !_.size(this.state.offer_type) ? row.offer_type : this.state.offer_type.value,
        ty_uas: !_.size(this.state.uas) ? row.ty_uas : this.state.uas.value,
        g_eid: row.g_eid,
        eventId: row.event_id,
        promo_sign_shell: !_.size(this.state.sign_shell) ? row.promo_sign_shell : this.state.sign_shell.value,
        promo_event_type: !_.size(this.state.promo_type) ? row.promo_event_type : this.state.promo_type.value,
        ty_event_type: row.promo_type,
        ty_type: row.type,
        start_date: row.event_start_date,
        end_date: row.event_end_date,
        promo_id: row.promo_id,
        isModify : true,
        user_role : localStorage.getItem("role")
      }

      regReqObj.push({
        adgroup_id: row.adgroup_id,
        eventId: row.event_id,
      })

      return tempObj;
    });

    const regPricesRes = await this.props.fetchRegPrices(regReqObj);

    console.log("*********regprices",regPricesRes);
    this.setState({
      showLoader: false
    })
    const regpricesObj = {};

    regPricesRes.forEach(ele => {
      const promoKey = `${ele.eventId}_${ele.adgroup_id}`;
      regpricesObj[promoKey] = ele;
    })

    console.log("**********updatedData,", updatedData);
    let foundRes = _.filter(updatedData, function (o) {
      let flagEditOffers = o.end_date;

      return !(moment((flagEditOffers)).isSameOrAfter(moment(), 'day'))
    });

    let responseMessage = [];

    if (foundRes && foundRes.length) {
      updatedData.forEach((responseData) => {
        let flagEditOffers = responseData.end_date;
        if (!(moment(flagEditOffers).isSameOrAfter(moment(), 'day'))) {
          responseMessage.push((responseData.adgroup_name || responseData.name) + " whose Event Type " + (flagEditOffers));
        }
      });

      if (responseMessage && responseMessage.length) {

        this.setState({

          isFailure: true,
          failureMessage: responseMessage,
        });

        setTimeout(() => {
          this.setState({
            isFailure: false,
            failureMessage: ""
          });
        }, alertTimer);
      }
    }

    // let findError = _.find(updatedData, async (item) => {
      let findError = updatedData.map((item) => {
      let eventType = item.ty_event_type;
      let offerType = item.ty_offer_type;
      let minQuantity = item.ty_min_qty;
      let bonusPay = item.ty_bonus_pay;
      let plannedAmount = item.ty_planned_amount;
      let fixedPriceQuantity = item.fixed_price_quantity;
      let buyMinVal = item.ty_buy_min_value;
      let getMaxVal = item.ty_get_max_value;
      let adgroupName = item.adgroup_name;

      const promoKey = `${item.eventId}_${item.adgroup_id}`
      const promoRegPrices = regpricesObj[promoKey];

      if (_.isEmpty(eventType)) {
        this.setState({ isFailure: true, failureMessage: "Please select event type" });
        setTimeout(() => {
          this.setState({ isFailure: false, failureMessage: "" });
        }, alertTimer);

        console.log("++++++++++++++++++++++++++++++++return 191");
        return item;
      }

      if(offerType === "A") {
        const values = {
          plannedAmount: plannedAmount,
          adgroupName: adgroupName
        }
        const offerValueValidationRes = AtypeOfferValidator(values, promoRegPrices);
  
        if(offerValueValidationRes.isInvalid){
          this.setState({
            isFailure: offerValueValidationRes.isInvalid,
            failureMessage: offerValueValidationRes.invalidationMsg
          });
  
          setTimeout(() => {
            this.setState({ isFailure: false, failureMessage: "" });
          }, alertTimer);
          return item;
        }
      }
  
      if(offerType === "P") {
        const values = {
          plannedAmount: plannedAmount,
          adgroupName: adgroupName
        }
        const offerValueValidationRes = PtypeOfferValidator(values, promoRegPrices);
  
        if(offerValueValidationRes.isInvalid){
        this.setState({ isFailure: offerValueValidationRes.isInvalid, failureMessage: offerValueValidationRes.invalidationMsg });
        setTimeout(() => {
          this.setState({ isFailure: false, failureMessage: "" });
        }, alertTimer);
        return item;
      }
    }
  
      if(offerType === "F") {
        const values = {
          plannedAmount: plannedAmount,
          fixedPriceQty: fixedPriceQuantity,
          adgroupName: adgroupName
        }
        const offerValueValidationRes = FtypeOfferValidator(values, promoRegPrices);
  
        if(offerValueValidationRes.isInvalid){
          this.setState({
            isFailure: offerValueValidationRes.isInvalid,
            failureMessage: offerValueValidationRes.invalidationMsg
          });
  
          setTimeout(() => {
            this.setState({ isFailure: false, failureMessage: "" });
          }, alertTimer);
          return item;
        }
      }
  
      if(offerType === "G") {
        const values = {
          minQuantity: minQuantity, 
          bonusBuy: bonusPay, 
          buyMinVal: buyMinVal, 
          getMaxVal: getMaxVal,
          adgroupName: adgroupName
        }
        const offerValueValidationRes = GtypeOfferValidator(values, promoRegPrices);
  
        if(offerValueValidationRes.isInvalid){
          this.setState({
            isFailure: offerValueValidationRes.isInvalid,
            failureMessage: offerValueValidationRes.invalidationMsg
          });
  
          setTimeout(() => {
            this.setState({ isFailure: false, failureMessage: "" });
          }, alertTimer);
          return item;
        }
      }
  
      if(offerType === "B") {
        const values = {
          minQuantity: minQuantity, 
          bonusBuy: bonusPay, 
          plannedAmount: plannedAmount,
          adgroupName: adgroupName
        }
        const offerValueValidationRes = BtypeOfferValidator(values, promoRegPrices);
  
        if(offerValueValidationRes.isInvalid){
          this.setState({
            isFailure: offerValueValidationRes.isInvalid,
            failureMessage: offerValueValidationRes.invalidationMsg
          });
  
          setTimeout(() => {
            this.setState({ isFailure: false, failureMessage: "" });
          }, alertTimer);
          return item;
        }
      }
  
      if(offerType === "C") {
        const values = {
          plannedAmount: plannedAmount,
          minQuantity: minQuantity, 
          bonusBuy: bonusPay, 
          buyMinVal: buyMinVal, 
          getMaxVal: getMaxVal,
          adgroupName: adgroupName
        }
        const offerValueValidationRes = CtypeOfferValidator(values, promoRegPrices);
  
        if(offerValueValidationRes.isInvalid){
          this.setState({
            isFailure: offerValueValidationRes.isInvalid,
            failureMessage: offerValueValidationRes.invalidationMsg
          });
  
          setTimeout(() => {
            this.setState({ isFailure: false, failureMessage: "" });
          }, alertTimer);
          return item;
        }
      }
  
      if(offerType === "D") {
        const values = {
          minQuantity: minQuantity, 
          plannedAmount: plannedAmount,
          adgroupName: adgroupName
        }
        const offerValueValidationRes = DtypeOfferValidator(values, promoRegPrices);
  
        if(offerValueValidationRes.isInvalid){
          this.setState({
            isFailure: offerValueValidationRes.isInvalid,
            failureMessage: offerValueValidationRes.invalidationMsg
          });
  
          setTimeout(() => {
            this.setState({ isFailure: false, failureMessage: "" });
          }, alertTimer);
          return item;
        }
      }

    });

    console.log("************findError", findError);

    const filteredfindError = findError.filter(element => element !== undefined);

    if (_.isEmpty(filteredfindError) && !foundRes.length) {
      let cumulativeOffersArray = [];
      let cumulativeOffersString = '';

      this.setState({
        showLoader: true,
      });

      updatedData.forEach((item) => {

        if (item.ty_offer_type === "F" && item.fixed_price_quantity > 1) {
          item.ty_offer_type = "nForM";
        }

        cumulativeOffersString =
          item.adgroup_id +
          item.ty_type +
          item.ty_event_type +
          item.start_date +
          item.end_date +
          item.ty_offer_type +
          item.ty_planned_amount +
          item.fixed_price_quantity +
          item.ty_min_qty +
          item.ty_bonus_pay +
          item.ty_buy_min_value +
          item.ty_get_max_value;

        cumulativeOffersArray.push(cumulativeOffersString);
      });

      let duplicates =
        _.uniq(cumulativeOffersArray).length !== cumulativeOffersArray.length;

      if (duplicates) {
        this.setState({
          showLoader: false,
          isFailure: true,
          failureMessage: "Duplicate values entered"
        });

        setTimeout(() => {
          this.setState({
            isFailure: false,
            failureMessage: ""
          });
        }, alertTimer);
      } else {
        //save adgroup findError is empty i.e, when all validations are success
        // return
        this.props.usersLogReport({
          screen: "Modify_bulk_offers",
          action: "save",
          userId: localStorage.getItem("email"),
        });

        // console.log("🚀 ~ //findError ~ updatedData:", updatedData)
        let errorTableData;

        for(let it of updatedData) {
          let titleObject = {
              offerType: it.ty_offer_type,
              offerValue: it.ty_planned_amount>0  ? it.ty_planned_amount : 0,
              offer: it.ty_planned_amount>0  ? it.ty_planned_amount : 0,
              bonusbyquantity: !it.ty_bonus_pay ? null : it.ty_bonus_pay,
              minquantity: !it.ty_min_qty ? null : it.ty_min_qty,
              fixedpricequantity: !it.fixed_price_quantity ? null : it.fixed_price_quantity,
            };
          let offer_title = getOfferTitle(titleObject.offerType, titleObject.offerValue, titleObject.fixedpricequantity, titleObject.minquantity, titleObject.bonusbyquantity, titleObject.offer);
          it["offer_title"] = offer_title;
      }

        let futureDataValues = {}, tempFutureData = {};
        for(let it of updatedData) {
          futureDataValues[it.promo_id] = 
          (it.ty_type) + '-' +
          (it.ty_event_type) + '-' +
          (it.start_date) + '-' +
          (it.end_date) +
          '@@' + '_' +
          (it.ty_type)+'_'+
          (it.promo_sign_shell)+'_'+
          (it.promo_event_type)+'_'+
          (it.uas ? it.uas : it.ty_uas)+'_'+
          (it.ty_event_type)+'_'+
          (it.ty_offer_type)+'_'+
          (it.ty_planned_amount>0  ? it.ty_planned_amount : 0)+'_'+
          (it.fixed_price_quantity>0  ? it.fixed_price_quantity : 0)+'_'+
          (it.ty_min_qty>0  ? it.ty_min_qty : 0)+'_'+
          (it.ty_bonus_pay>0  ? it.ty_bonus_pay : 0)+'_'+
          (it.ty_buy_min_value>0  ? it.ty_buy_min_value : 0)+'_'+
          (it.ty_get_max_value>0  ? it.ty_get_max_value : 0)+'^^'+
          (it.adgroup_name);

          tempFutureData[it.promo_id] = 
          (it.offer_title) + 'offer_type' +
          (it.ty_offer_type);
      }
      let notModifiedAdgroups=[], filteredPayloadData = [];

      for(let key in futureDataValues){
        if(this.state.currentDataValues[key] == futureDataValues[key]) {
          let temp = this.state.currentDataValues[key];
          temp = temp.split("").reverse().join("");
          temp = temp.split("^^");
          let ty_event_data = this.state.currentDataValues[key];
          ty_event_data = ty_event_data.split("@@");
          let error_arr = [];
          error_arr.push(temp[0].split("").reverse().join(""));
          error_arr.push(ty_event_data[0]);
          let arr = (tempFutureData[key].split('offer_type'));
          error_arr.push(arr[0]);
          error_arr.push(arr[1]);
          notModifiedAdgroups.push(error_arr)
        }
        else {
          let matchingElements = updatedData.filter(element => key == element.promo_id);
          filteredPayloadData = filteredPayloadData.concat(matchingElements);
        }
      }


       if(localStorage.getItem("role")==2){
        updatedData = filteredPayloadData;
       }

      if(notModifiedAdgroups.length > 0 && localStorage.getItem("role")==2) {

        this.setState({modify : true})

        errorTableData = notModifiedAdgroups.map(item => ({
          adgroup_name: item[0],
          event_detail: item[1],
          status : false,
          offer_type : item[3],
          offer_value : item[2],
          validationErrors : "User is not allowed to modify the offer with same details"
      
}));
      let tableData = {
        header: ColumnDef.bulkModifyApprovalErrorsData,
        defaultColDef: ColumnDef.defaultColDef,
        data : errorTableData
      }
      this.setState({bulkModifyErrors : _.cloneDeep(tableData)})
      if(notModifiedAdgroups.length == Object.keys(futureDataValues).length){
      this.setState({
        showLoader: false,
      });
      return ;
    }
}

       if(updatedData.length > 0) {
        let res = await this.props.saveAdgroupFromIntermediate({
          multiplePromos: updatedData,
          campaignId: parseInt(localStorage.getItem("campaignIdsForUrl")),
        });
        let foundPromoApprovalRes = _.filter(res, { inPromoApproval: true });

        if(foundPromoApprovalRes && foundPromoApprovalRes.length > 0) {
          this.setState({
            isSuccess: true,
            successMessage: "Promo offer/s has been submitted for approval",
            showLoader: false,
          });

          setTimeout(() => {
            this.setState({
              isSuccess: false,
              successMessage: ''
            });
            this.props.closeBulkModifyModal(true);
            this.props.updatePipelineData([], "intermediate", "editOffers");
          }, alertTimer);
        } else {
        setTimeout(() => {
          this.props.updatePipelineData([], "intermediate", "editOffers");
        }, alertTimer);

        let foundRes = _.filter(res, { status: false });
        let responseMessage = [];
        let foundSuccessRes = _.filter(res, { status: true });

        if(foundSuccessRes.length > 0) {
          this.setState({
            isSuccess: true,
            successMessage: `Offers modified Successfully`,
            showLoader: false,
          });

          setTimeout(() => {
            this.setState({
              isSuccess: false,
              successMessage: ''
            });
          }, alertTimer);
        }

        if (foundRes && foundRes.length>0) {
   
          // if(foundRes[0].msg == "Duplicate Approval Record Found"){
            console.log("hiiiiiiiiiii");
            // this.setState({errorFromPromoApproval : true})
            let errorTableData2 = foundRes.map(error => {
            return {
              adgroup_name: error.data.adgroupName,
              event_detail: error.data.ty_event,
              offer_type : error.data.offer_type,
              offer_value : error.data.offer_value,
              status : error.status,
              validationErrors : error.data.validationErrors
            };
        });
        if(errorTableData && errorTableData.length>0){
          errorTableData = errorTableData.concat(errorTableData2);
        }
        else{
          errorTableData = errorTableData2
        }
        let tableData = {
          header: ColumnDef.bulkModifyApprovalErrorsData,
          defaultColDef: ColumnDef.defaultColDef,
          data : errorTableData
        }
        this.setState({bulkModifyErrors : _.cloneDeep(tableData)}) 
        } else {
          this.setState({
            showLoader: false,
            isSuccess: true,
            successMessage: "Offers(s) updated successfully",
          });

          setTimeout(() => {
            this.setState({
              isSuccess: false,
              successMessage: "",
              isEdited: false
            });

            this.props.closeBulkModifyModal(true)
          }, alertTimer)
        }

        this.setState({
          showLoader: false,
        });
      }
    }
      }
    }
  }

  handleOfferTypeChange = (offerType) => {
    let tempValue = initialOfferValues;
    if (offerType.value == "F") {
      tempValue.fixed_qty = 1;
      // tempValue.offer_amt = 1;
    } else {
      tempValue.fixed_qty = ""
    }

    this.setState(prevState => ({
      offer_type: offerType,
      isEdited: true,
      offerValueObj: {
        ...prevState.offerValueObj,
        ...tempValue
      }
    }));
  }

  handleOfferValueChange = (value, key) => {
    let tempValue = null;

    if (key === "offer_amt") {
      let type = this.state.offer_type.value;
      if ((type === "P" || type === "B" || type === "D") && value > 99) return;
      else if ((type === "F" || type === "A" || type === "C") && value > 99999)
        return;
      tempValue = value;
    } else if (key === "fixed_qty") {
      if (value > 99) return;
      tempValue = value;
    } else if (key === "min_qty") {
      if (value > 99) return;
      tempValue = value;
    } else if (key === "bonus_buy") {
      if (value > 99) return;
      tempValue = value;
    } else if (key === "buy_min_val") {
      if (value > 99999) return;
      tempValue = value;
    } else if (key === "get_max_val") {
      if (value > 99999) return;
      tempValue = value;
    }

    if (tempValue || tempValue != null) {
      this.setState(prevState => ({
        isEdited: true,
        offerValueObj: {
          ...prevState.offerValueObj,
          [key]: tempValue
        }
      }));
    }
  }

  getRegSalesPrices = (rowData, nodeIndex) => {

    let offerType = this.state.offer_type.value;
    let minQuantity = this.state.offerValueObj.min_qty;
    let bonusPay = this.state.offerValueObj.bonus_buy;
    let plannedAmount = this.state.offerValueObj.offer_amt;
    let fixedPriceQuantity = this.state.offerValueObj.fixed_qty;

    let findError = _.find([rowData], (item) => {
      let eventType = item.promo_type;


      if (_.isEmpty(eventType)) {
        this.setState({ isFailure: true, failureMessage: "Please select event type" });
        setTimeout(() => {
          this.setState({ isFailure: false, failureMessage: "" });
        }, alertTimer);
        return item;
      }
      if (_.isEmpty(offerType)) {
        this.setState({ isFailure: true, failureMessage: "Please select offer type" });
        setTimeout(() => {
          this.setState({ isFailure: false, failureMessage: "" });
        }, alertTimer);
        return item;
      }

      if(offerType === "A") {
        const values = {
          plannedAmount: plannedAmount,
          adgroupName: item.adgroup_name
        }
  
        const promoRegPrices = {
          regLow : null,
          regHigh: null
        }
  
        const offerValueValidationRes = AtypeOfferValidator(values, promoRegPrices, true);
  
        if(offerValueValidationRes.isInvalid){
          this.setState({
            isFailure: offerValueValidationRes.isInvalid,
            failureMessage: offerValueValidationRes.invalidationMsg
          });
  
          setTimeout(() => {
            this.setState({ isFailure: false, failureMessage: "" });
          }, alertTimer);
          return;
        }
      }
  
      if(offerType === "P") {
        const values = {
          plannedAmount: plannedAmount,
          adgroupName: item.adgroup_name
        }
  
        const promoRegPrices = {
          regLow : null,
          regHigh: null
        }
  
        const offerValueValidationRes = PtypeOfferValidator(values, promoRegPrices, true);
  
        if(offerValueValidationRes.isInvalid){
          this.setState({
            isFailure: offerValueValidationRes.isInvalid,
            failureMessage: offerValueValidationRes.invalidationMsg
          });
  
          setTimeout(() => {
            this.setState({ isFailure: false, failureMessage: "" });
          }, alertTimer);
          return;
        }
      }
  
      if(offerType === "F") {
        const values = {
          plannedAmount: plannedAmount,
          fixedPriceQty: fixedPriceQuantity,
          adgroupName: item.adgroup_name
        }
  
        const promoRegPrices = {
          regLow : null,
          regHigh: null
        }
  
        const offerValueValidationRes = FtypeOfferValidator(values, promoRegPrices, true);
  
        if(offerValueValidationRes.isInvalid){
          this.setState({
            isFailure: offerValueValidationRes.isInvalid,
            failureMessage: offerValueValidationRes.invalidationMsg
          });
  
          setTimeout(() => {
            this.setState({ isFailure: false, failureMessage: "" });
          }, alertTimer);
          return;
        }
      }
  
      if(offerType === "G") {
        const values = {
          minQuantity: minQuantity, 
          bonusBuy: bonusPay, 
          buyMinVal: item.ty_buy_min_value, 
          getMaxVal: item.ty_get_max_value,
          adgroupName: item.adgroup_name
        }
  
        const promoRegPrices = {
          regLow : null,
          regHigh: null
        }
  
        const offerValueValidationRes = GtypeOfferValidator(values, promoRegPrices, true);
  
        if(offerValueValidationRes.isInvalid){
          this.setState({
            isFailure: offerValueValidationRes.isInvalid,
            failureMessage: offerValueValidationRes.invalidationMsg
          });
  
          setTimeout(() => {
            this.setState({ isFailure: false, failureMessage: "" });
          }, alertTimer);
          return;
        }
      }
  
      if(offerType === "B") {
        const values = {
          minQuantity: minQuantity, 
          bonusBuy: bonusPay, 
          plannedAmount: plannedAmount,
          adgroupName: item.adgroup_name
        }
  
        const promoRegPrices = {
          regLow : null,
          regHigh: null
        }
  
        const offerValueValidationRes = BtypeOfferValidator(values, promoRegPrices, true);
  
        if(offerValueValidationRes.isInvalid){
          this.setState({
            isFailure: offerValueValidationRes.isInvalid,
            failureMessage: offerValueValidationRes.invalidationMsg
          });
  
          setTimeout(() => {
            this.setState({ isFailure: false, failureMessage: "" });
          }, alertTimer);
          return;
        }
      }
  
      if(offerType === "C") {
        const values = {
          plannedAmount: plannedAmount,
          minQuantity: minQuantity, 
          bonusBuy: bonusPay, 
          buyMinVal: item.ty_buy_min_value, 
          getMaxVal: item.ty_get_max_value,
          adgroupName: item.adgroup_name
        }
        const promoRegPrices = {
          regLow : null,
          regHigh: null
        }
        const offerValueValidationRes = CtypeOfferValidator(values, promoRegPrices, true);
  
        if(offerValueValidationRes.isInvalid){
          this.setState({
            isFailure: offerValueValidationRes.isInvalid,
            failureMessage: offerValueValidationRes.invalidationMsg
          });
  
          setTimeout(() => {
            this.setState({ isFailure: false, failureMessage: "" });
          }, alertTimer);
          return;
        }
      }
  
      if(offerType === "D") {
        const values = {
          minQuantity: minQuantity, 
          plannedAmount: plannedAmount,
          adgroupName: item.adgroup_name
        }
        const promoRegPrices = {
          regLow : null,
          regHigh: null
        }
        const offerValueValidationRes = DtypeOfferValidator(values, promoRegPrices, true);
  
        if(offerValueValidationRes.isInvalid){
          this.setState({
            isFailure: offerValueValidationRes.isInvalid,
            failureMessage: offerValueValidationRes.invalidationMsg
          });
  
          setTimeout(() => {
            this.setState({ isFailure: false, failureMessage: "" });
          }, alertTimer);
          return;
        }
      }
    });

    console.log("*************findError", findError)
    if (_.isEmpty(findError)) {

      const reqObj = {
        eventId: rowData.eventId || rowData.event_id,
        adgroup_id: rowData.adgroup_id,
        offerType: offerType,
        minQuantity: _.isEmpty(minQuantity) ? 0 : minQuantity,
        bonusPay: _.isEmpty(bonusPay) ? 0 : bonusPay,
        plannedAmount: _.isEmpty(plannedAmount) ? 0 : plannedAmount,
        fixedPriceQuantity: _.isEmpty(fixedPriceQuantity) ? 0 : fixedPriceQuantity
      }

      this.setState({ showLoader: true });

      this.props.fetchRegSalesPrices(reqObj).then(res => {
        console.log(res);
        if (res.data) {
          const result = res.data;
          let tempData = this.state.tableData.data;
          tempData.forEach(item => {
            if (item.promo_id) {
              if (item.promo_id == rowData.promo_id) {
                item.regLow = result[0].regLow;
                item.regHigh = result[0].regHigh
                item.saleLow = result[0].saleLow
                item.saleHigh = result[0].saleHigh
              }
            } else {
              if (item.adgroup_id == rowData.adgroup_id && item.eventId == rowData.eventId) {
                item.regLow = result[0].regLow;
                item.regHigh = result[0].regHigh
                item.saleLow = result[0].saleLow
                item.saleHigh = result[0].saleHigh
              }
            }
          });
          let tempTableData = {
            header: ColumnDef.header,
            defaultColDef: ColumnDef.defaultColDef,
            data: tempData,
          };
          this.setState({
            tableData: tempTableData
          })
          this.updateCalculationChanged(nodeIndex);
          this.setState({ showLoader: false });
        }
      })
    }
  }

  updateCalculationChanged = (index) => {
    this.setState({
      calculationChanged: !this.state.calculationChanged,
      calcRowIndex: index,
    });
  };

  handleChanges = (value, key) => {
    this.setState({
      [key]: value,
      isEdited: true
    })
  }

  handleModalClose = () => {
    this.setState({
      isEdited: false,
      offerValueObj: initialOfferValues,
    });

    this.props.closeBulkModifyModal()
  }

  render() {
    return (
      <>
        <Modal
          visible={true}
          width="90%"
          height="90%"
          effect="fadeInDown"
          onClickAway={() => this.handleModalClose()}
        >
          <PageLoader loader={this.state.showLoader}>
            <div className="alertDivs">
              {
                this.state.isFailure &&
                <AlertDanger
                  message={this.state.failureMessage}
                  show={this.state.isFailure}
                />
              }
              {
                this.state.isSuccess &&
                <AlertSuccess
                  message={this.state.successMessage}
                  show={this.state.isSuccess}
                />
              }
            </div>
            <div className="closeicon">
              <span style={{ cursor: "pointer" }}>
                <i
                  className="fa fa-times"
                  aria-hidden="true"
                  onClick={() => this.handleModalClose()}
                />
              </span>
            </div>
            <h4 className="promoHeading mb-4">
              Bulk Modify Offers
            </h4>
            <div className="row pipelineCopydiv" style={{ marginTop: "-1%" }}>
              <div className="col-md-10">
                <div className="row" style={{ marginLeft: "7%" }}>
                  <div className="col-md-4">
                    <span className="promoNameText row">
                      Promotion Name:
                      <p className="promonameResult">
                        {/* {this.props.campaignName} */}
                        {moment(this.props.campaignStartDate).format("MM/DD/YYYY")} - {moment(this.props.campaignEndDate).format("MM/DD/YYYY")}
                      </p>
                    </span>
                  </div>
                  <div className="col-md-3">
                    <span
                      className="promoNameText row"
                      style={{ paddingLeft: "2%" }}
                    >
                      Start Date:
                      <p className="promonameResult">
                        {moment(this.props.campaignStartDate).format("MM/DD/YYYY")}
                      </p>
                    </span>
                  </div>
                  <div className="col-md-3">
                    <span
                      className="promoNameText row"
                      style={{ paddingLeft: "2%" }}
                    >
                      End Date:
                      <p className="promonameResult">
                        {moment(this.props.campaignEndDate).format("MM/DD/YYYY")}
                      </p>
                    </span>
                  </div>
                  <div className="col-md-2">
                    <span
                      className="promoNameText row"
                      style={{ paddingLeft: "2%" }}
                    >
                      # of days:
                      <p className="promonameResult">{this.props.diffDays}</p>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="bulkEditDiv">
              <Container fluid>
                <Row style={{ marginTop: '10px', marginBottom: '10px' }}>
                  <Col lg>
                    <p className="promoNameText">Offer Type</p>
                    <Select
                      placeholder="Offer type"
                      options={OfferTypes.newAdgroupOfferTypes}
                      value={this.state.offer_type}
                      onChange={val =>
                        this.handleOfferTypeChange(val)
                      }
                    />
                  </Col>
                  <Col lg>
                    <p className="promoNameText">Offer amount </p>
                    <input
                      type="number"
                      autocomplete="off"
                      placeholder="Offer amount"
                      className="offerInputStyling"
                      disabled={
                        !_.size(this.state.offer_type) ||
                          (this.state.offer_type && this.state.offer_type.value === "G")
                          ? true
                          : false
                      }
                      id="offer_amt"
                      value={this.state.offerValueObj.offer_amt}
                      onChange={e =>
                        this.handleOfferValueChange(
                          e.target.value,
                          "offer_amt"
                        )
                      }
                    />
                  </Col>
                  <Col lg>
                    <p className="promoNameText">Fixed price qty</p>
                    <input
                      type="number"
                      autocomplete="off"
                      placeholder="Fixed price qty"
                      className="offerInputStyling"
                      disabled={
                        !_.size(this.state.offer_type) ||
                          (this.state.offer_type && this.state.offer_type.value !== "F")
                          ? true
                          : false
                      }
                      id="fixed_qty"
                      value={this.state.offerValueObj.fixed_qty}
                      onChange={e =>
                        this.handleOfferValueChange(
                          e.target.value,
                          "fixed_qty"
                        )
                      }
                    />
                  </Col>
                  <Col lg>
                    <p className="promoNameText">Min qty</p>
                    <input
                      type="number"
                      placeholder="Min Qty for Offer"
                      className="offerInputStyling"
                      autocomplete="off"
                      disabled={
                        !_.size(this.state.offer_type) ||
                          (this.state.offer_type &&
                            (this.state.offer_type.value === "P" ||
                              this.state.offer_type.value === "F" ||
                              this.state.offer_type.value === "A"))
                          ? true
                          : false
                      }
                      id="min_qty"
                      value={this.state.offerValueObj.min_qty}
                      onChange={e =>
                        this.handleOfferValueChange(
                          e.target.value,
                          "min_qty"
                        )
                      }
                    />
                  </Col>
                  <Col lg>
                    <p className="promoNameText">Bonus buy</p>
                    <input
                      type="number"
                      placeholder="Bonus buy"
                      autocomplete="off"
                      className="offerInputStyling"
                      disabled={
                        !_.size(this.state.offer_type) ||
                          (this.state.offer_type &&
                            (this.state.offer_type.value !== "G" &&
                              this.state.offer_type.value !== "B" &&
                              this.state.offer_type.value !== "C"))
                          ? true
                          : false
                      }
                      id="bonus_buy"
                      value={this.state.offerValueObj.bonus_buy}
                      onChange={e =>
                        this.handleOfferValueChange(
                          e.target.value,
                          "bonus_buy"
                        )
                      }
                    />
                  </Col>
                  <Col lg>
                    <p className="promoNameText">Buy $ Min value</p>
                    <input
                      type="number"
                      placeholder="Buy $ min value"
                      autocomplete="off"
                      className="offerInputStyling"
                      disabled={
                        !_.size(this.state.offer_type) ||
                          (this.state.offer_type &&
                            (this.state.offer_type.value !== "C" && this.state.offer_type.value !== "G"))
                          ? true
                          : false
                      }
                      id="buy_min_val"
                      value={this.state.offerValueObj.buy_min_val}
                      onChange={e =>
                        this.handleOfferValueChange(
                          e.target.value,
                          "buy_min_val"
                        )
                      }
                    />
                  </Col>
                  <Col lg>
                    <p className="promoNameText">Get $ Max value</p>
                    <input
                      type="number"
                      placeholder="Get $ Max value"
                      autocomplete="off"
                      className="offerInputStyling"
                      disabled={
                        !_.size(this.state.offer_type) ||
                          (this.state.offer_type &&
                            (this.state.offer_type.value !== "C" && this.state.offer_type.value !== "G"))
                          ? true
                          : false
                      }
                      id="get_max_val"
                      value={this.state.offerValueObj.get_max_val}
                      onChange={e =>
                        this.handleOfferValueChange(
                          e.target.value,
                          "get_max_val"
                        )
                      }
                    />
                  </Col>
                </Row>
                <Row style={{ marginTop: '10px', marginBottom: '10px' }}>
                  <Col lg>
                    <p className="promoNameText">Sign shell</p>
                    <Select
                      placeholder="Sign shell"
                      options={this.state.EVENT_SIGNSHELL_CONSTANTS2}
                      value={this.state.sign_shell}
                      onChange={val =>
                        // this.setState({ sign_shell: val })
                        this.handleChanges(val, "sign_shell")
                      }
                      isDisabled={ !(this.state.loggedUserRole == 3 || this.state.loggedUserRole == 1)}
                    />
                  </Col>
                  <Col lg>
                    <p className="promoNameText">UAS</p>
                    <Select
                      placeholder="UAS"
                      options={UAS_OPTIONS}
                      value={this.state.uas}
                      onChange={val =>
                        // this.setState({ uas: val })
                        this.handleChanges(val, "uas")
                      }
                    />
                  </Col>
                  <Col lg>
                    <p className="promoNameText">Promo type</p>
                    <Select
                      placeholder="Promo type"
                      options={PROMO_EVENT_TYPE_OPTIONS}
                      onChange={val =>
                        // this.setState({ promo_type: val })
                        this.handleChanges(val, "promo_type")
                      }
                      value={this.state.promo_type}
                      isDisabled= {!(this.state.loggedUserRole == 3 || this.state.loggedUserRole == 1 || this.state.loggedUserRole == 6)}
                    />
                  </Col>
                  <Col lg></Col>
                  <Col lg></Col>
                  <Col lg></Col>
                  <Col lg></Col>
                </Row>
              </Container>
            </div>
            {this.state.tableData.data && this.state.tableData.data.length > 0 &&
              <div>
                <div
                  style={{ margin: "1% 1% 0% 2%", height: "50%" }}
                  className="copyOffertableContainer"
                >
                  <DataTable
                    parentId="myGridForCopyPromoErrorDetails"
                    parentStyle={{
                      height: this.state.bulkModifyErrors.data ? "20vh" : "40vh",
                      width: "100%",
                      position: "relative",
                    }}
                    parentClass="ag-theme-balham"
                    tableData={this.state.tableData}
                    frameworkComponents={{
                      promoActionRenderer: PromoActionRenderer
                    }}
                    updateCalculationChanged={this.updateCalculationChanged}
                    calcRowIndex={this.state.calcRowIndex}
                    calculationChanged={this.state.calculationChanged}
                  />
                </div>
               </div>
            }

                {
              this.state.bulkModifyErrors.data && this.state.bulkModifyErrors.data.length>0 &&
              <>
                <p className="errorMessage">Following offers cannot be modified</p>
                <div className="copyOffertableContainer">
                  <DataTable
                    parentId="myGridForCopyPromoErrorDetails"
                    tableData={this.state.bulkModifyErrors}
                    parentStyle={{
                      height: "20vh",
                      width: "100%",
                      position: "relative",
                    }}
                    frameworkComponents={{
                      approvalStatusRenderer: ApprovalStatusRenderer
                  }}
                  getRowStyle={this.state.getRowStyle}
                    autoSizeCol={true}
                  />
                </div>
              </>
            }

                <div className="text-center mt-3">
                  <button
                    className="btn btn-outline-primary promo3Buttons"
                    disabled={!this.state.isEdited || isIt()}
                    onClick={() => {
                      this.modifyOffers();
                    }}
                  >
                    Save
                  </button>
                {/* </div> */}
              </div>
              
      
          </PageLoader>
        </Modal>
      </>
    )
  }

}

export default connect(null, {
  saveAdgroupFromIntermediate,
  usersLogReport,
  fetchRegSalesPrices,
  fetchRegPrices ,
  getSignShellOptions
})(BulkModifyOffersModal);