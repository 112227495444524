export const SIGNSHELL_OPTIONS = [
  {
    value: 'SPC1',
    label: 'SPC1',
  },
  {
    value: 'SPC2',
    label: 'SPC2',
  },
  {
    value: 'SPC3',
    label: 'SPC3',
  },
  {
    value: 'SPC4',
    label: 'SPC4',
  },
  {
    value: 'SPC5',
    label: 'SPC5',
  },
  {
    value: 'SPC6',
    label: 'SPC6',
  },
  {
    value: 'SPC7',
    label: 'SPC7',
  },
  {
    value: 'SPC8',
    label: 'SPC8',
  },
  {
    value: 'SPC10',
    label: 'SPC10',
  },
  {
    value: 'Clrnce',
    label: 'Clrnce',
  },
  {
    value: 'GO',
    label: 'GO',
  },
  {
    value: 'Sale',
    label: 'Sale',
  },
]

export const UAS_OPTIONS = [
  {
    value: 'x',
    label: 'Yes',
  },
  {
    value: '-',
    label: 'No',
  },
]

export const PROMO_TYPE_OPTIONS = [
  {
    value: 'P',
    label: 'P',
  },
  {
    value: 'D',
    label: 'D',
  },
]