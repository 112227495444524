import _ from "lodash";

export default {
  defaultColDef: {
    resizable: true,
    sortable: true
  },
  colDefForUsersList: [
    {
      headerName: "Name",
      field: "user_name",
      filterParams: { defaultOption: "startsWith" },
      filter: "agTextColumnFilter",
      unSortIcon: true
    },
    {
      headerName: "Email",
      field: "email",
      filterParams: { defaultOption: "startsWith" },
      filter: "agTextColumnFilter",
      unSortIcon: true
    },
    {
      headerName: "Roles",
      field: "role_name",
      filterParams: { defaultOption: "startsWith" },
      filter: "agTextColumnFilter",
      unSortIcon: true
    },
    {
      headerName: "Created By",
      field: "created_by",
      filter: "agTextColumnFilter",
      filterParams: { defaultOption: "startsWith" },
      unSortIcon: true
    },
    {
      headerName: "Updated By",
      filterParams: { defaultOption: "startsWith" },
      filter: "agTextColumnFilter",
      field: "updated_by",
      unSortIcon: true
    },
    {
      headerName: "Merch ID",
      filterParams: { defaultOption: "startsWith" },
      filter: "agTextColumnFilter",
      suppressSizeToFit: true,
      field: "merch_id",
      valueGetter: function(params){        
        if(!_.isEmpty(params.data.merch_id)) return params.data.merch_id;
        return "All";
      },
      unSortIcon: true
    },
    {
      headerName: "Status",
      field: "user_status",
      filterParams: { defaultOption: "startsWith" },
      filter: "agTextColumnFilter",
      unSortIcon: true
    },
    {
      headerName: "Action",
      field: "action",
      cellRenderer: "childMessageRenderer",
      pinned: "right",
      suppressSizeToFit: true,
      cellStyle: null, //cellStyleMethodNew
      filter: false,
      width: 100
    },
    {
      headerName: "Function",
      field: "getfun",
      hide: true
    },
    {
      headerName: "Function1",
      field: "getfun1",
      hide: true
    }
  ]
};
