import moment from "moment";
import numeral from "numeral";
import { valueFormatterHandler } from "../../../utils/valueFormatters";

export default {
  defaultColDef: {
    sortable: true,
    filter: true
  },
  header: [
    {
      headerName: "TY Start Date",
      field: "start_date",
      valueGetter: function (params) {
        if (params.data.start_date && params.data.rank === "1") {
          return moment(params.data.start_date).format("MM-DD-YYYY");
        }
        return "-";
      }
    },
    {
      headerName: "TY End Date",
      field: "end_date",
      valueGetter: function (params) {
        if (params.data.end_date && params.data.rank === "1") {
          return moment(params.data.end_date).format("MM-DD-YYYY");
        }
        return "-";
      }
    },
    {
      headerName: "Promo Type",
      field: "promo_type",
      valueGetter: promoTypeFormatter
    },
    { headerName: "Adgroup", field: "adgroup_name" },
    { headerName: "Merch Manager ID", field: "adgroup_merch_id" },
    { headerName: "Shop", field: "shop_desc" },
    { headerName: "Adgroup Desc.", field: "adgroup_description" },
    {
      headerName: "TY No. of days",
      field: "no_of_days",
      valueGetter: function (params) {
        if (params.data.no_of_days && params.data.rank === "1") {
          return params.data.no_of_days;
        }
        return "-";
      }
    },
    {
      headerName: "TY event",
      field: "promo_type",
      valueGetter: promoTypeFormatter
    },
    {
      headerName: "Promosmart Offer",
      field: "offer",
      valueGetter: function (params) {
        if (params.data.offer && params.data.rank === "1") {
          return params.data.offer;
        }
        return "-";
      }
    },
    {
      headerName: "Promosmart Offer Type",
      field: "offer_type",
      valueGetter: function (params) {
        if (params.data.offer_type && params.data.rank === "1") {
          return params.data.offer_type;
        }
        return "-";
      }
    },
    {
      headerName: "IA forecasted units",
      field: "ia_forecasted_units",
      valueGetter: rankCheckIA
    },
    {
      headerName: "Total Units Override",
      field: "total_override_units",
      valueGetter: rankCheck
    },
    {
      headerName: "Total Forecasted Units",
      field: "total_forecasted_units",
      valueGetter: rankCheck
    },
    {
      headerName: "IA forecasted sales($)",
      field: "ia_forecasted_sales",
      valueGetter: rankCheckWithDollaIA
    },
    {
      headerName: "Total Sales Override($)",
      field: "total_override_sales",
      valueGetter: rankCheckWithDolla
    },
    {
      headerName: "Total Forecasted Sales($)",
      field: "total_forecasted_sales",
      valueGetter: rankCheckWithDolla
    },
    {
      headerName: "IA forecasted Margin($)",
      field: "ia_forecasted_margin",
      valueGetter: rankCheckWithDollaIA
    },
    {
      headerName: "Total Margin Override($)",
      field: "total_override_margin",
      valueGetter: rankCheckWithDolla //aPlusBValueGetterCommon
    },
    {
      headerName: "Total Forecasted Margin($)",
      field: "total_forecasted_margin",
      valueGetter: rankCheckWithDolla
    },
    {
      headerName: "Total Actual Margin($)",
      field: "total_actual_margin",
      valueGetter: rankCheckWithDolla
    },
    {
      headerName: "IA Forecasted GM (%)",
      field: "ia_forecasted_gm",
      valueGetter: function (params) {
        if (
          (params.data.ia_forecasted_gm) &&
          params.data.rank === "1"
        ) {
          let sign = Math.sign(params.data.ia_forecasted_gm);
          let valueVar = Math.round(params.data.ia_forecasted_gm * 100) / 100;
          return sign === 1 || sign === 0
            ? numeral(valueVar).format("0.00") + "%"
            : numeral(valueVar).format("(0.00)") + "%";
        } else if (!params.data.ia_forecasted_gm) {
          return "-";
        }
        return "-";
      }
    },
    {
      headerName: "Total GM Override(%)",
      field: "gm_override",
      valueGetter: function (params) {
        if (
          (params.data.gm_override || params.data.gm_override === 0) &&
          params.data.rank === "1"
        ) {
          let sign = Math.sign(params.data.gm_override);
          let valueVar = Math.round(params.data.gm_override * 100) / 100;
          return sign === 1 || sign === 0
            ? numeral(valueVar).format("0.00") + "%"
            : numeral(valueVar).format("(0.00)") + "%";
        }
        return "-";
      }
    },
    {
      headerName: "Forecasted GM (%)",
      field: "forecasted_gm",
      valueGetter: function (params) {
        if (
          params.data.forecasted_gm ||
          (params.data.forecasted_gm === 0 && params.data.rank === "1")
        ) {
          let sign = Math.sign(params.data.forecasted_gm);
          let valueVar = Math.round(params.data.forecasted_gm * 100) / 100;
          return sign === 1 || sign === 0
            ? numeral(valueVar).format("0.00") + "%"
            : numeral(valueVar).format("(0.00)") + "%";
        } else if (!params.data.forecasted_gm) {
          return "-";
        }
        return "-";
      }
    },
    {
      headerName: "LY Start Date",
      field: "ly_start_date",
      valueGetter: function (params) {
        if (params.data.ly_start_date) {
          return moment(params.data.ly_start_date).format("MM-DD-YYYY");
        }
        return "-";
      }
    },
    {
      headerName: "LY End Date",
      field: "ly_end_date",
      valueGetter: function (params) {
        if (params.data.ly_end_date) {
          return moment(params.data.ly_end_date).format("MM-DD-YYYY");
        }
        return "-";
      }
    },
    {
      headerName: "LY Offer",
      field: "ly_offer",
      valueGetter: function (params) {
        if (params.data.ly_offer) {
          return params.data.ly_offer;
        }
        return "-";
      }
    },
    {
      headerName: "LY Offer Type",
      field: "ly_offer_type",
      valueGetter: function (params) {
        if (params.data.ly_offer_type) {
          return params.data.ly_offer_type;
        }
        return "-";
      }
    },
    {
      headerName: "LY Event Type",
      field: "ly_event_type",
      valueGetter: promoTypeFormatterLY
    },
    {
      headerName: "LY No. of days",
      field: "ly_no_of_days",
      valueGetter: function (params) {
        if (params.data.ly_no_of_days) {
          return params.data.ly_no_of_days;
        }
        return "-";
      }
    },
    {
      headerName: "LY Total Units",
      field: "ly_total_units",
      valueGetter: valueFormatterHandler
    },
    {
      headerName: "LY Total Sales($)",
      field: "ly_total_sales",
      valueGetter: valueFormatterHandler
    },
    {
      headerName: "LY Total Margin($)",
      field: "ly_total_margin",
      valueGetter: valueFormatterHandler
    },
    {
      headerName: "LY GM %",
      field: "ly_gm",
      valueGetter: function (params) {
        if (params.data.ly_gm && params.data.rank === "1") {
          let sign = Math.sign(params.data.ly_gm);
          let valueVar = Math.round(params.data.ly_gm * 100) / 100;
          return sign === 1 || sign === 0
            ? numeral(valueVar).format("0.00") + "%"
            : numeral(valueVar).format("(0.00)") + "%";
        } else if (!params.data.ly_gm) {
          return "-";
        }
        return "-";
      }
    }
  ]
};

function promoTypeFormatter(params, value) {
  let promoTypeSet = JSON.parse(localStorage.getItem("promo_type"));
  if (value === "delete_adgroup") {
    if (params) {
      return promoTypeSet ? promoTypeSet[params] : params;
    }
  } else {
    if (params.data.promo_type && params.data.rank === "1") {
      return promoTypeSet
        ? promoTypeSet[params.data.promo_type]
        : params.data.promo_type;
    }
  }

  return "-";
}

function promoTypeFormatterLY(params) {
  let promoTypeSet = JSON.parse(localStorage.getItem("promo_type"));
  if (params.data.ly_event_type) {
    return promoTypeSet
      ? promoTypeSet[params.data.ly_event_type]
      : params.data.ly_event_type;
  }
  return "-";
}

function rankCheck(params) {
  let field = params.colDef.field,
    value = field ? params.data[field] : "";
  if ((value || value === 0) && params.data.rank === "1") {
    let sign = Math.sign(value);
    let valueVar = Math.round(value * 100) / 100;

    return value === null || value === ""
      ? "-"
      : (sign === 1 || sign === 0) && valueVar !== 0
        ? numeral(valueVar).format("0,0")
        : numeral(valueVar).format("(0,0)");
  }
  return "-";
}

function rankCheckWithDolla(params) {
  let field = params.colDef.field,
    value = field ? params.data[field] : "";
  if ((value || value === 0) && params.data.rank === "1") {
    let sign = Math.sign(value);
    let valueVar = Math.round(value * 100) / 100;

    return value === null || value === ""
      ? "-"
      : (sign === 1 || sign === 0) && valueVar !== 0
        ? "$ " + numeral(valueVar).format("0,0")
        : "$ " + numeral(valueVar).format("(0,0)");
  }
  return "-";
}

function rankCheckIA(params) {
  let field = params.colDef.field,
    value = field ? params.data[field] : "";
  if ((value) && params.data.rank === "1") {
    let sign = Math.sign(value);
    let valueVar = Math.round(value * 100) / 100;

    return value === null || value === "" || valueVar < 1
      ? "-"
      : (sign === 1 || sign === 0) && valueVar !== 0
        ? numeral(valueVar).format("0,0")
        : numeral(valueVar).format("(0,0)");
  }
  return "-";
}

function rankCheckWithDollaIA(params) {
  let field = params.colDef.field,
    value = field ? params.data[field] : "";
  if ((value) && params.data.rank === "1") {
    let sign = Math.sign(value);
    let valueVar = Math.round(value * 100) / 100;

    return value === null || value === "" || valueVar < 1
      ? "-"
      : (sign === 1 || sign === 0) && valueVar !== 0
        ? "$ " + numeral(valueVar).format("0,0")
        : "$ " + numeral(valueVar).format("(0,0)");
  }
  return "-";
}
