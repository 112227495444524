import { getBrowserTimeZone , comparator} from "../../../utils/valueFormatters";
import moment from 'moment';
function isFirstColumn(params) {
  var displayedColumns = params.columnApi.getAllDisplayedColumns();
  var thisIsFirstColumn = displayedColumns[0] === params.column;
  return thisIsFirstColumn;
}
const dateComparator = function (date1, date2, format = "MM/DD/YYYY") {
  const parsedDate1 = moment(date1, format).toDate();
  const parsedDate2 = moment(date2, format).toDate();
  return parsedDate1.getTime() - parsedDate2.getTime();
};
export default {
  defaultColDef: {
    // headerCheckboxSelection: isFirstColumn,
    // checkboxSelection: isFirstColumn,
    // resizable: true,
    // filter: true,
    // sortable: true,
  },
  defaultColDefInactive: {
    // resizable: true,
    // filter: true,
    // sortable: true,
  },
  activeEventMasterHeader: [
    {
      headerName: "Event ID",
      field: "g_eid",
      width: 150,
      filter: "agTextColumnFilter",
      unSortIcon: true,
      pinned: "left",
      cellRenderer: "eventNameRenderer",
      checkboxSelection: true,
      headerCheckboxSelection: true,
      enableColResize: true,
      resizable: true,
      sortable: true,
    },
    {
      headerName: "Event Description",
      field: "description",
      width: 300,
      filter: "agTextColumnFilter",
      unSortIcon: true,
      resizable: true,
      sortable: true,
      valueGetter: function (params) {
        if (params.data.description) {
          return params.data.description;
        }
        return "-";
      }
    },
    {
      headerName: "Event Start Date",
      field: "event_start_date",
      width: 200,
      filter: "agTextColumnFilter",
      unSortIcon: true,
      enableColResize: true,
      resizable: true,
      sortable: true,
      comparator: function (date1, date2) {
        return comparator(date1, date2, "MM-DD-YYYY HH:mm:ss");
      },
      valueGetter: function (params) {
        if (params.data.event_start_date) {
          // return getBrowserTimeZone(params.data.event_start_date,"MM/DD/YYYY HH:mm");
          return params.data.event_start_date;
        }
        return "-";
      },
      
    },
    {
      headerName: "Event End Date",
      field: "event_end_date",
      width: 200,
      filter: "agTextColumnFilter",
      unSortIcon: true,
      resizable: true,
      sortable: true,
      valueGetter: function (params) {
        if (params.data.event_end_date) {
          // return getBrowserTimeZone(params.data.event_end_date,"MM/DD/YYYY HH:mm");
          return params.data.event_end_date;
        }
        return "-";
      },
      comparator: function (date1, date2) {
        return comparator(date1, date2, "MM-DD-YYYY HH:mm:ss");
      },
    },
    {
      headerName: "Campaign Name",
      field: "campaign_name",
      width: 250,
      filter: "agTextColumnFilter",
      unSortIcon: true,
      sortable: true,
      comparator: function (campaignName1, campaignName2) {
        const startDate1 = campaignName1.split(" - ")[0];
        const startDate2 = campaignName2.split(" - ")[0];
        return dateComparator(startDate1, startDate2, "MM/DD/YYYY");
      },
      valueGetter: function (params) {
        if (params.data.campaign_name) {
          // return params.data.campaign_name;
          return `${params.data.campaign_start_date} - ${params.data.campaign_end_date}`
        }
        return "-";
      },
    },
    /** 
    {
      headerName: "Campaign Start Date",
      field: "campaign_start_date",
      width: 200,
      filter: "agTextColumnFilter",
      unSortIcon: true,
      resizable: true,
      sortable: true,
      comparator: function (date1, date2) {
        return comparator(date1, date2, "MM-DD-YYYY");
      },
    },
    {
      headerName: "Campaign End Date",
      field: "campaign_end_date",
      width: 200,
      filter: "agTextColumnFilter",
      unSortIcon: true,
      resizable: true,
      sortable: true,
      comparator: function (date1, date2) {
        return comparator(date1, date2, "MM-DD-YYYY");
      },
    },
    */
    {
      headerName: "Type",
      field: "type",
      width: 150,
      filter: "agTextColumnFilter",
      unSortIcon: true,
      resizable: true,
      sortable: true,
      valueGetter: function (params) {
        if (params.data.type) {
          return params.data.type;
        }
        return "-";
      }
    },
    {
      headerName: "Vehicle",
      field: "vehicle",
      width: 150,
      filter: "agTextColumnFilter",
      unSortIcon: true,
      resizable: true,
      sortable: true,
      valueGetter: function (params) {
        if (params.data.vehicle) {
          return params.data.vehicle;
        }
        return "-";
      },
    },
    {
      headerName: "Sign shell",
      field: "sign_shell",
      width: 150,
      filter: "agTextColumnFilter",
      unSortIcon: true,
      sortable: true,
      resizable: true,
      valueGetter: function (params) {
        if (params.data.sign_shell) {
          return params.data.sign_shell;
        }
        return "-";
      },
    },
    {
      headerName: "Theme",
      field: "theme",
      width: 150,
      filter: "agTextColumnFilter",
      unSortIcon: true,
      resizable: true,
      sortable: true,
      valueGetter: function (params) {
        if (params.data.theme) {
          return params.data.theme;
        }
        return "-";
      },
    },
    {
      headerName: "Created By",
      field: "created_by_user",
      width: 300,
      filter: "agTextColumnFilter",
      unSortIcon: true,
      resizable: true,
      sortable: true,
      valueGetter: function (params) {
        if (params.data.created_by_user) {
          return params.data.created_by_user;
        }
        return "-";
      },
    },
    {
      headerName: "Created At",
      field: "created_at",
      width: 200,
      filter: "agTextColumnFilter",
      unSortIcon: true,
      resizable: true,
      sortable: true,
      comparator: function (date1, date2) {
        return comparator(date1, date2, "MM-DD-YYYY HH:mm:ss");
      },
      valueGetter: function (params) {
        if (params.data.created_at) {
          return getBrowserTimeZone(params.data.created_at);
        }
        return "-";
      },
      
    },
    
    {
      headerName: "Changed By",
      field: "updated_by_user",
      width: 300,
      filter: "agTextColumnFilter",
      unSortIcon: true,
      resizable: true,
      sortable: true,
      valueGetter: function (params) {
        if (params.data.updated_by_user && params.data.updated_at != params.data.created_at) {
          return params.data.updated_by_user;
        }
        return "-";
      },
    },
    {
      headerName: "Changed At",
      field: "updated_at",
      width: 200,
      filter: "agTextColumnFilter",
      unSortIcon: true,
      resizable: true,
      sortable: true,
      comparator: function (date1, date2) {
        return comparator(date1, date2, "MM-DD-YYYY HH:mm:ss");
      },
      valueGetter: function (params) {
        if (params.data.updated_at && params.data.updated_at != params.data.created_at) {
          return getBrowserTimeZone(params.data.updated_at);
        }
        return "-";
      },
    },
    {
      headerName: "Action",
      field: "action",
      pinned: "right",
      cellRenderer: "eventActionRenderer",
      width: 100,
      filter: "agTextColumnFilter",
    }
  ],
  inactiveEventMasterHeader: [
    {
      headerName: "Event ID",
      field: "g_eid",
      width: 150,
      filter: "agTextColumnFilter",
      unSortIcon: true,
      pinned: "left",
      cellRenderer: "eventNameRenderer",
      sortable: true,
      resizable: true,
    },
    {
      headerName: "Event Description",
      field: "description",
      width: 300,
      filter: "agTextColumnFilter",
      unSortIcon: true,
      sortable: true,
      valueGetter: function (params) {
        if (params.data.description) {
          return params.data.description;
        }
        return "-";
      },
      resizable: true,
    },
    {
      headerName: "Event Start Date",
      field: "event_start_date",
      width: 200,
      filter: "agTextColumnFilter",
      unSortIcon: true,
      sortable: true,
      resizable: true,
      valueGetter: function (params) {
        if (params.data.event_start_date) {
          // return getBrowserTimeZone(params.data.event_start_date,"MM/DD/YYYY HH:mm");
          return params.data.event_start_date;
        }
        return "-";
      },
      comparator: function (date1, date2) {
        return comparator(date1, date2, "MM-DD-YYYY HH:mm:ss");
      },
    },
    {
      headerName: "Event End Date",
      field: "event_end_date",
      width: 200,
      filter: "agTextColumnFilter",
      unSortIcon: true,
      sortable: true,
      resizable: true,
      valueGetter: function (params) {
        if (params.data.event_end_date) {
          // return getBrowserTimeZone(params.data.event_end_date,"MM/DD/YYYY HH:mm");
          return params.data.event_end_date;
        }
        return "-";
      },
      comparator: function (date1, date2) {
        return comparator(date1, date2, "MM-DD-YYYY HH:mm:ss");
      },
    },
    {
      headerName: "Campaign Name",
      field: "campaign_name",
      width: 250,
      filter: "agTextColumnFilter",
      unSortIcon: true,
      sortable: true,
      resizable: true,
      comparator: function (campaignName1, campaignName2) {
        const startDate1 = campaignName1.split(" - ")[0];
        const startDate2 = campaignName2.split(" - ")[0];
        return dateComparator(startDate1, startDate2, "MM/DD/YYYY");
      },
      valueGetter: function (params) {
        if (params.data.campaign_name) {
          // return params.data.campaign_name;
          return `${params.data.campaign_start_date} - ${params.data.campaign_end_date}`
        }
        return "-";
      },
    },
    {
      headerName: "Campaign Start Date",
      field: "campaign_start_date",
      width: 200,
      filter: "agTextColumnFilter",
      unSortIcon: true,
      resizable: true,
      sortable: true,
      comparator: function (date1, date2) {
        return comparator(date1, date2, "MM-DD-YYYY");
      },
    },
    {
      headerName: "Campaign End Date",
      field: "campaign_end_date",
      width: 200,
      filter: "agTextColumnFilter",
      unSortIcon: true,
      resizable: true,
      sortable: true,
      comparator: function (date1, date2) {
        return comparator(date1, date2, "MM-DD-YYYY");
      },
    },
    {
      headerName: "Type",
      field: "type",
      width: 150,
      filter: "agTextColumnFilter",
      unSortIcon: true,
      sortable: true,
      resizable: true,
      valueGetter: function (params) {
        if (params.data.type) {
          return params.data.type;
        }
        return "-";
      }
    },
    {
      headerName: "Vehicle",
      field: "vehicle",
      width: 150,
      filter: "agTextColumnFilter",
      unSortIcon: true,
      sortable: true,
      resizable: true,
      valueGetter: function (params) {
        if (params.data.vehicle) {
          return params.data.vehicle;
        }
        return "-";
      },
    },
    {
      headerName: "Sign shell",
      field: "sign_shell",
      width: 150,
      filter: "agTextColumnFilter",
      unSortIcon: true,
      sortable: true,
      resizable: true,
      valueGetter: function (params) {
        if (params.data.sign_shell) {
          return params.data.sign_shell;
        }
        return "-";
      },
    },
    {
      headerName: "Theme",
      field: "theme",
      width: 150,
      filter: "agTextColumnFilter",
      unSortIcon: true,
      sortable: true,
      resizable: true,
      valueGetter: function (params) {
        if (params.data.theme) {
          return params.data.theme;
        }
        return "-";
      },
    },
    {
      headerName: "Action",
      field: "action",
      pinned: "right",
      cellRenderer: "eventActionRenderer",
      width: 90,
      filter: "agTextColumnFilter",
    }
  ]
};
