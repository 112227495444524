import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import _ from "lodash";
import Modal from "react-awesome-modal";
import Multiselect from "../../../../utils/Multiselect";
import Select from "react-select";
import ReferenceSkuTable from "./ReferenceSkuTable";
import ReferenceExcelUpload from "./ReferenceSkuExcelUpload";
import PageLoader from "../../../../utils/Loader";
import { getCumulativeFilters } from "../../../../redux/actions/summaryAction";
import { getAdgroupData } from "../../../../redux/actions/adgroup";
import { similarSkuProductsList } from "../../../../redux/actions/referenceSkuActions";
import PropTypes from "prop-types";
import "./ReferenceSkuStyling.css";
import AlertWarning from "../../../../utils/AlertMessage/alertWarning";
import AlertDanger from "../../../../utils/AlertMessage/alertDanger";
import AlertSuccess from "../../../../utils/AlertMessage/alertSuccess";
import {
  editReferenceSku,
  infoReferenceSku
} from "../../../../redux/actions/referenceSkuActions";
import ColumnDef from "../../../tables/colDefs/SkuListColDefs";
import ReferenceSkuDetails from "../../../../utils/ReferenceSkuDetails";
import { ReferenceSKUGA } from '../../../../utils/GA/GAUtils';
import { alertTimer } from "../../../../utils/alertTimer";

export class ReferenceSku extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCustomerGrpVal: [],
      selectedCustomerGrpValue: [],
      selectedBusinessDescVal: [],
      selectedBusinessDescValue: [],
      selectedShopVal: [],
      selectedShopValue: [],
      selectedCategoryVal: [],
      selectedCategoryValue: [],
      selectedMerchCatVal: [],
      selectedMerchCatValue: [],
      selectedAdgroupVal: [],
      selectedAdgroupValue: [],
      loader: false,
      tableData: [],
      somethingWentWrong: false,
      selectRefSkuError: false,
      skuDetailsSaved: false,
      refSkuOptions: [],
      refreshRows: false,
      infoRefSkuData: {
        header: ColumnDef.skuHeader,
        defaultColDef: { sortable: true },
        data: []
      },
      adgroupSelected: false
    };
  }

  async componentDidMount() {
    this.props.getCumulativeFilters({
      required_column: [
        {
          required_column: "h6"
        },
        {
          required_column: "h5"
        },
        {
          required_column: "h3"
        },
        {
          required_column: "h2"
        },
        {
          required_column: "h1"
        }
      ]
    });
    this.props.getAdgroupData("");

    let refSkuSearchRes = await this.props.similarSkuProductsList({
      modelable_sku: true,
      search_key: ""
    });
    let list = _.map(refSkuSearchRes.data, item => {
      return {
        value: item.unique_id1,
        label: item.unique_id1
      };
    });
    list.unshift({ value: "None", label: "None" });
    this.setState({ refSkuOptions: list });
  }

  custGroupSelected = value => {
    this.setState({
      selectedCustomerGrpVal: value,
      selectedCustomerGrpValue: [value.value],
      selectedAdgroupVal: [],
      selectedAdgroupValue: [],
      adgroupSelected: false
    });
    if (value.value.length) {
      this.getRefSkuDataFromFilter(
        [value.value],
        this.state.selectedBusinessDescValue,
        this.state.selectedShopValue,
        this.state.selectedCategoryValue,
        this.state.selectedMerchCatValue,
        this.state.selectedAdgroupValue
      );
    }
  };

  businessDescSelected = value => {
    let tempArr = [];
    value.forEach(ele => {
      tempArr.push(ele.value);
    });
    this.setState({
      selectedBusinessDescVal: value,
      selectedBusinessDescValue: tempArr,
      selectedAdgroupVal: [],
      selectedAdgroupValue: [],
      adgroupSelected: false
    });
    if (tempArr.length) {
      this.getRefSkuDataFromFilter(
        this.state.selectedCustomerGrpValue,
        tempArr,
        this.state.selectedShopValue,
        this.state.selectedCategoryValue,
        this.state.selectedMerchCatValue,
        this.state.selectedAdgroupValue
      );
    }
  };

  shopSelected = value => {
    let tempArr = [];
    value.forEach(ele => {
      tempArr.push(ele.value);
    });
    this.setState({
      selectedShopVal: value,
      selectedShopValue: tempArr,
      selectedAdgroupVal: [],
      selectedAdgroupValue: [],
      adgroupSelected: false
    });
    if (tempArr.length) {
      this.getRefSkuDataFromFilter(
        this.state.selectedCustomerGrpValue,
        this.state.selectedBusinessDescValue,
        tempArr,
        this.state.selectedCategoryValue,
        this.state.selectedMerchCatValue,
        this.state.selectedAdgroupValue
      );
    }
  };

  categorySelected = value => {
    let tempArr = [];
    value.forEach(ele => {
      tempArr.push(ele.value);
    });
    this.setState({
      selectedCategoryVal: value,
      selectedCategoryValue: tempArr,
      selectedAdgroupVal: [],
      selectedAdgroupValue: [],
      adgroupSelected: false
    });
    if (tempArr.length) {
      this.getRefSkuDataFromFilter(
        this.state.selectedCustomerGrpValue,
        this.state.selectedBusinessDescValue,
        this.state.selectedShopValue,
        tempArr,
        this.state.selectedMerchCatValue,
        this.state.selectedAdgroupValue
      );
    }
  };

  merchCatSelected = value => {
    let tempArr = [];
    value.forEach(ele => {
      tempArr.push(ele.value);
    });
    this.setState({
      selectedMerchCatVal: value,
      selectedMerchCatValue: tempArr,
      selectedAdgroupVal: [],
      selectedAdgroupValue: [],
      adgroupSelected: false
    });
    if (tempArr.length) {
      this.getRefSkuDataFromFilter(
        this.state.selectedCustomerGrpValue,
        this.state.selectedBusinessDescValue,
        this.state.selectedShopValue,
        this.state.selectedCategoryValue,
        tempArr,
        this.state.selectedAdgroupValue
      );
    }
  };

  adGroupSelectedSingleSelection = value => {
    this.setState({
      selectedAdgroupVal: value,
      selectedAdgroupValue: [value.value],
      selectedCustomerGrpVal: [],
      selectedCustomerGrpValue: [],
      selectedBusinessDescVal: [],
      selectedBusinessDescValue: [],
      selectedShopVal: [],
      selectedShopValue: [],
      selectedCategoryVal: [],
      selectedCategoryValue: [],
      selectedMerchCatVal: [],
      selectedMerchCatValue: [],
      adgroupSelected: true
    });
    if (value.value) {
      this.getRefSkuDataFromFilter(
        this.state.selectedCustomerGrpValue,
        this.state.selectedBusinessDescValue,
        this.state.selectedShopValue,
        this.state.selectedCategoryValue,
        this.state.selectedMerchCatValue,
        [value.value],
        "adgroupSelected"
      );
    }
  };

  getRefSkuDataFromFilter = async (
    custGroup,
    businessDesc,
    shop,
    category,
    merchcat,
    adgroup,
    condition
  ) => {
    this.setState({ loader: true });
    let params =
      condition === "adgroupSelected"
        ? { adgroup: adgroup, modelable_sku: false, search_key: "" }
        : {
            h6: custGroup,
            h5: businessDesc,
            h3: shop,
            h2: category,
            h1: merchcat,
            modelable_sku: false,
            search_key: ""
          };

    let getRefSkuDataResponse = await this.props.similarSkuProductsList(params);
    if (getRefSkuDataResponse.status) {
      let dataArr = [];
      if (getRefSkuDataResponse.data) {
        let clonedData = _.cloneDeep(getRefSkuDataResponse);
        dataArr = clonedData.data.map(item => {
          item.saveRefSkuDetail = this.saveRefSkuDetail;
          item.refSkuSearchHandler = this.refSkuSearchHandler;
          item.refSkuOptions = this.state.refSkuOptions;
          item.original_reference_sku= item.reference_sku;
          return item;
        });
      }
      this.setState({ loader: false, tableData: dataArr });
    } else {
      this.setState({ loader: false, somethingWentWrong: true });
      setTimeout(() => {
        this.setState({ somethingWentWrong: false });
      }, alertTimer);
    }
  };

  saveRefSkuDetail = async rowData => {
    let params = null;
    if (Object.keys(rowData).length) {
      params = {
        skuId: rowData.unique_id1,
        referenceSku: rowData.selected_sku === "none" ? null : rowData.selected_sku
      };
      this.setState({ loader: true });
      let saveRefSkuResponse = await this.props.editReferenceSku(params);
      if (saveRefSkuResponse.status) {
        this.setState({ loader: false, skuDetailsSaved: true });
        setTimeout(() => {
          this.setState({ skuDetailsSaved: false });
        }, alertTimer);

        this.getRefSkuDataFromFilter(
          this.state.selectedCustomerGrpValue,
          this.state.selectedBusinessDescValue,
          this.state.selectedShopValue,
          this.state.selectedCategoryValue,
          this.state.selectedMerchCatValue,
          this.state.selectedAdgroupValue,
          this.state.adgroupSelected ? "adgroupSelected" : ""
        );
      } else {
        this.setState({ loader: false, somethingWentWrong: true });
        setTimeout(() => {
          this.setState({ somethingWentWrong: false });
        }, alertTimer);
      }
    } else {
      this.setState({
        selectRefSkuError: true
      });

      setTimeout(() => {
        this.setState({
          selectRefSkuError: false
        });
      }, alertTimer);
    }
  };
  refSkuSearchHandler = async (key, id) => {
    let { tableData } = this.state;
    let refSkuSearchRes = await this.props.similarSkuProductsList({
      modelable_sku: true,
      search_key: key
    });
    let list = _.map(refSkuSearchRes.data, item => {
      return {
        value: item.unique_id1,
        label: item.unique_id1
      };
    });
    list.unshift({ value: "none", label: "None" });

    let found = _.find(tableData, { unique_id1: id });
    found.refSkuOptions = list;

    this.setState({ refSkuOptions: list, refreshRows: true, tableData });
  };
  refreshRowsHandler = () => {
    this.setState({ refreshRows: false });
  };
  onAdGroupSearch = val => {
    if (!_.isEmpty(val)) {
      this.props.getAdgroupData(val.toUpperCase());
    }
  };

  clearData = value => {
    if (value === "adGroup") {
      this.setState({
        selectedAdgroupVal: [],
        selectedAdgroupValue: []
      });

      setTimeout(() => {
        if (this.state.selectedAdgroupValue.length) {
          this.getRefSkuDataFromFilter(
            this.state.selectedCustomerGrpValue,
            this.state.selectedBusinessDescValue,
            this.state.selectedShopValue,
            this.state.selectedCategoryValue,
            this.state.selectedMerchCatValue,
            [],
            "adgroupSelected"
          );
        }
      }, 500);
    } else {
      if (value === "customerGroup") {
        this.setState({
          selectedCustomerGrpVal: [],
          selectedCustomerGrpValue: []
        });
      } else if (value === "business") {
        this.setState({
          selectedBusinessDescVal: [],
          selectedBusinessDescValue: []
        });
      } else if (value === "shop") {
        this.setState({
          selectedShopVal: [],
          selectedShopValue: []
        });
      } else if (value === "category") {
        this.setState({
          selectedCategoryVal: [],
          selectedCategoryValue: []
        });
      } else if (value === "merchCat") {
        this.setState({
          selectedMerchCatVal: [],
          selectedMerchCatValue: []
        });
      }

      setTimeout(() => {
        if (
          this.state.selectedCustomerGrpValue.length ||
          this.state.selectedBusinessDescValue.length ||
          this.state.selectedShopValue.length ||
          this.state.selectedCategoryValue.length ||
          this.state.selectedMerchCatValue.length
        ) {
          this.getRefSkuDataFromFilter(
            this.state.selectedCustomerGrpValue,
            this.state.selectedBusinessDescValue,
            this.state.selectedShopValue,
            this.state.selectedCategoryValue,
            this.state.selectedMerchCatValue,
            this.state.selectedAdgroupValue
          );
        }
      }, 500);
    }
  };
  submitGA(action,label){
    ReferenceSKUGA({ action, label});
  }

  render() {
    let shopArray = [];
    let custGroupArray = [];
    let businessDescArray = [];
    let merchCatArray = [];
    let categoryArray = [];
    let adGroupArray = [];

    if (
      this.props.summaryCumulativeFilters &&
      Object.keys(this.props.summaryCumulativeFilters).length > 0 &&
      this.props.summaryCumulativeFilters[0][0] != undefined
    ) {
      if (this.props.summaryCumulativeFilters[0][0].h6 != null) {
        this.props.summaryCumulativeFilters[0][0].h6.map(filterData => {
          custGroupArray.push({
            value: filterData,
            label: filterData
          });
        });
      } else {
      }

      if (this.props.summaryCumulativeFilters[1][0].h5 != null) {
        this.props.summaryCumulativeFilters[1][0].h5.map(filterData => {
          businessDescArray.push({
            value: filterData,
            label: filterData
          });
        });
      } else {
      }

      if (this.props.summaryCumulativeFilters[2][0].h3 != null) {
        this.props.summaryCumulativeFilters[2][0].h3.map(filterData => {
          shopArray.push({
            value: filterData,
            label: filterData
          });
        });
      } else {
      }

      if (
        this.props.summaryCumulativeFilters &&
        Object.keys(this.props.summaryCumulativeFilters).length > 0 &&
        this.props.summaryCumulativeFilters[3][0] != undefined
      ) {
        if (this.props.summaryCumulativeFilters[3][0].h2 != null) {
          this.props.summaryCumulativeFilters[3][0].h2.map(filterData => {
            categoryArray.push({
              value: filterData,
              label: filterData
            });
          });
        } else {
        }
      }

      if (
        this.props.summaryCumulativeFilters &&
        Object.keys(this.props.summaryCumulativeFilters).length > 0 &&
        this.props.summaryCumulativeFilters[4][0] != undefined
      ) {
        if (this.props.summaryCumulativeFilters[4][0].h1 != null) {
          this.props.summaryCumulativeFilters[4][0].h1.map(filterData => {
            merchCatArray.push({
              value: filterData,
              label: filterData
            });
          });
        } else {
        }
      }

      if (
        this.props.adGroupData &&
        Object.keys(this.props.adGroupData).length > 0
      ) {
        this.props.adGroupData.map(adgroup => {
          adGroupArray.push({
            value: adgroup.name,
            label: adgroup.name
          });
        });
      } else {
      }
    }
    return (
      <div>
        <div className="alertDivs">
          {this.state.somethingWentWrong ? (
            <AlertWarning
              message="Something went wrong! Please try again."
              show={this.state.somethingWentWrong}
            />
          ) : null}
          {this.state.selectRefSkuError ? (
            <AlertDanger
              message="Please select Reference SKU to proceed"
              show={this.state.selectRefSkuError}
            />
          ) : null}
          {this.state.skuDetailsSaved ? (
            <AlertSuccess
              message="Reference SKU details saved!"
              show={this.state.skuDetailsSaved}
            />
          ) : null}
        </div>
        <div className="card validationStyles">
          <PageLoader loader={!custGroupArray.length || this.state.loader}>
            <div className="validationContent">
              <h4 className="promoHeading">Reference SKU</h4>
              <div className="row promoSelect ref-sku-header-cont">
                <div className="col-md-2 validationdiv">
                  <div className="ref-sku-border pt-0">
                    <label className="promoLbels">Adgroup</label>
                    <Select
                      placeHolder="AdGroup"
                      options={adGroupArray}
                      onChange={value => {
                        this.adGroupSelectedSingleSelection(value)
                        this.submitGA("Filter", "Selected_Adgroup");
                      }}
                      value={this.state.selectedAdgroupVal}
                      // onKeyDown={e => this.onAdGroupSearch(e)}
                      onInputChange={e => this.onAdGroupSearch(e)}
                    />
                    {Object.keys(this.state.selectedAdgroupVal).length ? (
                      <i
                        className="fa fa-times close2"
                        aria-hidden="true"
                        onClick={() => {
                          this.clearData("adGroup");
                          this.submitGA("Filter", "Selected_Adgroup");
                        }}
                      />
                    ) : null}
                    {/* {this.state.selectedAdgroupVal.length ? (
                      <i
                        className="fa fa-times close2"
                        aria-hidden="true"
                        onClick={() => this.clearData("adGroup")}
                      />
                    ) : null} */}
                  </div>
                </div>

                <div className="col-1">
                  <p className="ref-sku-or">OR</p>
                </div>

                <div className="col-md-9">
                  <div className="row ref-sku-row">
                    <div className="col">
                      <label className="promoLbels">
                        Customer Group{" "}
                        <span className="requiredField"> * </span>
                      </label>
                      <Select
                        placeHolder="Customer Group"
                        options={custGroupArray}
                        onChange={value => {
                          this.custGroupSelected(value);
                          this.submitGA("Filter", "Selected_CustomerGroup");
                        }}
                        value={this.state.selectedCustomerGrpVal}
                      />
                      {Object.keys(this.state.selectedCustomerGrpVal).length ? (
                        <i
                          className="fa fa-times close2"
                          aria-hidden="true"
                          onClick={() => {
                            this.clearData("customerGroup");
                            this.submitGA("Filter", "Selected_CustomerGroup");
                          }}
                        />
                      ) : null}
                    </div>
                    <div className="col">
                      <label className="promoLbels">Business</label>
                      <Multiselect
                        placeHolder="Business"
                        optionsArray={businessDescArray}
                        onDropDownChange={value => {
                          this.businessDescSelected(value);
                          this.submitGA("Filter", "Selected_Business");
                        }}
                        dropdownValue={this.state.selectedBusinessDescVal}
                      />

                      {this.state.selectedBusinessDescVal.length ? (
                        <i
                          className="fa fa-times close2"
                          aria-hidden="true"
                          onClick={() => {
                            this.clearData("business");
                            this.submitGA("Filter", "Selected_Business");
                          }}
                        />
                      ) : null}
                    </div>
                    <div className="col">
                      <label className="promoLbels">Shop</label>
                      <Multiselect
                        placeHolder="Shop"
                        optionsArray={shopArray}
                        onDropDownChange={value => {
                          this.shopSelected(value);
                          this.submitGA("Filter", "Selected_Shop");
                        }}
                        dropdownValue={this.state.selectedShopVal}
                      />

                      {this.state.selectedShopVal.length ? (
                        <i
                          className="fa fa-times close2"
                          aria-hidden="true"
                          onClick={() => {
                            this.clearData("shop");
                            this.submitGA("Filter", "Selected_Shop");
                          }}
                        />
                      ) : null}
                    </div>
                    <div className="col">
                      <label className="promoLbels">Category</label>
                      <Multiselect
                        placeHolder="Category"
                        optionsArray={categoryArray}
                        onDropDownChange={value => {
                          this.categorySelected(value);
                          this.submitGA("Filter", "Selected_Category");
                        }}
                        dropdownValue={this.state.selectedCategoryVal}
                      />

                      {this.state.selectedCategoryVal.length ? (
                        <i
                          className="fa fa-times close2"
                          aria-hidden="true"
                          onClick={() => {
                            this.clearData("category");
                            this.submitGA("Filter", "Selected_Category");
                          }}
                        />
                      ) : null}
                    </div>
                    <div className="col ref-sku-last-child">
                      <label className="promoLbels">Merch Cat</label>
                      <Multiselect
                        placeHolder="Merch Cat"
                        optionsArray={merchCatArray}
                        onDropDownChange={value => {
                          this.merchCatSelected(value);
                          this.submitGA("Filter", "Selected_MerchCat");
                        }}
                        dropdownValue={this.state.selectedMerchCatVal}
                      />

                      {this.state.selectedMerchCatVal.length ? (
                        <i
                          className="fa fa-times close2"
                          aria-hidden="true"
                          onClick={() => {
                            this.clearData("merchCat");
                            this.submitGA("Filter", "Selected_MerchCat");
                          }}
                        />
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <ReferenceSkuTable
              tableData={this.state.tableData}
              refreshRows={this.state.refreshRows}
              refreshRowsHandler={this.refreshRowsHandler}
              gaHandler={() => {
                this.submitGA("Click", "Clicked_DownloadButton");
              }}
            />
            <ReferenceExcelUpload 
              gaHandler={this.submitGA}
            />
            {this.state.showSkuModal ? (
              <Modal
                visible={true}
                //width="80%"
                //height="220px"
                width="1000px"
                height="auto"
                effect="fadeInDown"
                onClickAway={() => this.setState({ showSkuModal: false })}
              >
                <PageLoader loader={this.state.loader}>
                  <div className="closeicon">
                    <span style={{ cursor: "pointer" }}>
                      <i
                        className="fa fa-times"
                        aria-hidden="true"
                        onClick={() => this.setState({ showSkuModal: false })}
                      />
                    </span>
                  </div>
                  <h5 className="text-center font-weight-bold mt-1">
                    Reference SKU details
                  </h5>
                  <ReferenceSkuDetails
                    infoRefSkuData={this.state.infoRefSkuData}
                  />
                </PageLoader>
              </Modal>
            ) : null}
          </PageLoader>
        </div>
      </div>
    );
  }
}

ReferenceSku.propTypes = {
  getAdgroupData: PropTypes.func.isRequired,
  similarSkuProductsList: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  summaryCumulativeFilters: state.summaryData.summaryCumulativeFilters,
  adGroupData: state.adGroupData.adGroupData,
  loader: state.summaryData.loader
});

export default connect(mapStateToProps, {
  getCumulativeFilters,
  similarSkuProductsList,
  getAdgroupData,
  editReferenceSku,
  infoReferenceSku
})(withRouter(ReferenceSku));
