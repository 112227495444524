import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import _ from "lodash";
import { bulkRefSkuUpload } from "../../../../redux/actions/referenceSkuActions";
import AlertSuccess from "../../../../utils/AlertMessage/alertSuccess";
import PageLoader from "../../../../utils/Loader";
import AlertDanger from "../../../../utils/AlertMessage/alertDanger";
import { alertTimer } from "../../../../utils/alertTimer";

let sku_file_ref_sku = null;

export class ReferenceSkuExcelUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bulkUploadFile: null,
      showLoader: false,
      showBulkUploadAlert: false,
      showBulkUploadFailed: false,
      bulkUploadResponseMsg: "",
      fileUploadedAlert: false
    };
  }

  componentDidMount() {
    sku_file_ref_sku = !_.isEmpty(localStorage.getItem("similar_skufile_link"))
      ? JSON.parse(localStorage.getItem("similar_skufile_link"))
      : "#";
  }

  handleFiles = data => {
    this.setState({
      bulkUploadFile: data.target.files[0],
      fileUploadedAlert: true
    });
    setTimeout(() => { this.setState({ fileUploadedAlert: false})}, alertTimer);
  };

  bulkUpload = async () => {
    this.setState({
      showLoader: true
    });
    const data = new FormData();
    data.append("file", this.state.bulkUploadFile);

    let bulkUploadResponse = await this.props.bulkRefSkuUpload(data);

    if (bulkUploadResponse.status) {
      this.setState({
        bulkUploadResponseMsg: bulkUploadResponse.msg,
        showBulkUploadAlert: true,
        showLoader: false
      });

      setTimeout(() => {
        this.setState({
          showBulkUploadAlert: false
        });
      }, alertTimer);
    } else {
      this.setState({
        showBulkUploadFailed: true,
        showLoader: false
      });

      setTimeout(() => {
        this.setState({
          showBulkUploadFailed: false
        });
      }, alertTimer);
    }
  };

  render() {
    return (
      <div style={{ padding: "0 12px"}}>
        <div className="alertDivs">
          {this.state.showBulkUploadAlert ? (
            <AlertSuccess
              message="Bulk upload successful. Please check your e-mail for the result"
              show={this.state.showBulkUploadAlert}
            />
          ) : null}
          {this.state.fileUploadedAlert ? (
            <AlertSuccess
            message="File Uploaded Successfully!"
            show={this.state.fileUploadedAlert}
          />
          ) : null}

          {this.state.showBulkUploadFailed ? (
            <AlertDanger
              message="Bulk upload failed. Please check your file format"
              show={this.state.showBulkUploadFailed}
            />
          ) : null}
        </div>
        <PageLoader loader={this.state.showLoader}>
          <div className="or-0ption-styling">
            <p
              className="textMiddle"
              style={{
                color: "#2d69eb"
              }}
            >
              Or
            </p>
          </div>

          <div className="card validationStyles">
            {" "}
            <h4 className="promoHeading" style={{ fontSize: "1rem" }}>
              Upload Reference SKU
            </h4>
            <div
              className="card csvCardDiv"
              style={{ width: "40%", margin: "8px auto" }}
            >
              <div className="mb-2 text-center">
                <a
                  href={sku_file_ref_sku}
                  target="_blank"
                  style={{
                    textDecoration: "underline",
                    color: "#2d69eb"
                  }}
                  download
                  onClick={() => this.props.gaHandler('Click', 'Clicked_DownloadSampleFileButton')}
                >
                  Download sample file
                </a>
              </div>

              <i
                className="fa fa-upload importIcon upload-excel-icon-styling "
                aria-hidden="true"
              />

              <div className="row" style={{ marginTop: "2%" }}>
                <div className="col-md-5">
                  <p id="dragFileText">Drag and Drop File Here</p>
                </div>
                <div className="col-md-7" style={{ marginLeft: "-5%" }}>
                  <div id="csv1" className="csvReaderDiv csv-upload-text">
                    <input
                      type="file"
                      name="file"
                      onChange={this.handleFiles}
                      onClick={() => {
                        this.props.gaHandler('Click', 'Clicked_ChooseFileButton');
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="row" style={{ margin: "1% auto" }}>
                <button
                  type="button"
                  className="btn btn-outline-primary promo3Buttons"
                  onClick={e => {
                    this.bulkUpload();
                    this.props.gaHandler('Click', 'Clicked_SaveChangesButton');
                  }}
                  disabled={this.state.bulkUploadFile === null}
                >
                  Save changes
                </button>
              </div>
            </div>
          </div>
        </PageLoader>
      </div>
    );
  }
}
ReferenceSkuExcelUpload.propTypes = {
  bulkRefSkuUpload: PropTypes.func.isRequired
};

const mapStateToProps = state => ({});

export default connect(mapStateToProps, {
  bulkRefSkuUpload
})(withRouter(ReferenceSkuExcelUpload));
