import gaUtils from './Analytics';

const gaUtilsObj = new gaUtils();
const PromotionCalendarGA = gaUtilsObj.trackEvent('Promotion Calendar');
const PromoCompareGA = gaUtilsObj.trackEvent('Promo Compare');
const DayLevelOfferGA = gaUtilsObj.trackEvent('Day Level Offer');
const SummaryGA = gaUtilsObj.trackEvent('Summary');
const ReferenceSKUGA = gaUtilsObj.trackEvent('Reference SKU');
const AdgroupMasterGA = gaUtilsObj.trackEvent('Adgroup Master');
const SupportGA = gaUtilsObj.trackEvent('Support');
const EventMAsterGA = gaUtilsObj.trackEvent('Event Master');

export {
  gaUtilsObj,
  PromotionCalendarGA,
  PromoCompareGA,
  DayLevelOfferGA,
  SummaryGA,
  ReferenceSKUGA,
  AdgroupMasterGA,
  SupportGA,
  EventMAsterGA
};
