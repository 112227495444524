import React, { Component } from "react";
import Modal from "react-awesome-modal";
import PageLoader from "../../../../utils/Loader";
import DataTable from "../../../tables";
import ColumnDef from "../../../tables/colDefs/SkuListColDefs";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import _ from "lodash";
import RefSkuChildMessageRendrer from "../ReferenceSku/RefSkuChildMessageRendrer";
import RefSkuActionRenderer from "../ReferenceSku/RefSkuActionRenferer";

class PipelineViewSkuModal extends Component {
  state = {
    pipelineKey: "tySku",
  };

  tabHandler = (key) => {
    this.setState({
      pipelineKey: key,
    });
  };

  tabContent = (adgroupTableProducts, index, name) => {
    return (
      <DataTable
        index={index}
        tableData={adgroupTableProducts}
        frameworkComponents={{
          refSkuChildMessageRenderer: RefSkuChildMessageRendrer,
          refSkuActionRenderer: RefSkuActionRenderer,
        }}
      />
    );
  };

  render() {
    let array = ColumnDef.skuHeader,
      excludeColDef = _.slice(array, [0], [array.length - 1]);

    var currentSkuTableData = {
      header: excludeColDef,
      defaultColDef: ColumnDef.defaultColDef,
      data: _.isEmpty(this.props.skuTableDataCurrent)
        ? []
        : this.props.skuTableDataCurrent,
    };
    var previousSkuTableData = {
      header: excludeColDef,
      defaultColDef: ColumnDef.defaultColDef,
      data: _.isEmpty(this.props.skuTableDataPrevious)
        ? []
        : this.props.skuTableDataPrevious,
    };
    return (
      <>
        <Modal
          visible={this.props.showModelOpen}
          width="90%"
          height="85%"
          effect="fadeInDown"
        >
          <PageLoader loader={this.props.showLoaderPopup}>
            <div className="closeicon">
              <span style={{ cursor: "pointer" }}>
                <i
                  className="fa fa-times"
                  aria-hidden="true"
                  onClick={() => this.props.closeModal()}
                />
              </span>
              <h4 className="text-center">SKU List logs</h4>
              <div className="row offerDetails">
                <>
                  <div className="col-md-4">
                    <span className="promoNameText row">
                      Adgroup Name:
                      <p className="promonameResult">
                        {this.props.promoIdData.length
                          ? this.props.promoIdData[0].adgroup_name
                          : null}
                      </p>
                    </span>
                  </div>
                  <div className="col-md-3">
                    <span
                      className="promoNameText row"
                      style={{ paddingLeft: "2%" }}
                    >
                      Offer:
                      <p className="promonameResult">
                        {this.props.promoIdData.length
                          ? this.props.promoIdData[0].offer_name
                          : null}
                      </p>
                    </span>
                  </div>
                </>
              </div>
            </div>

            <Tabs
              id="view-sku-list-tab"
              activeKey={this.state.pipelineKey}
              onSelect={(key) => this.tabHandler(key)}
              style={{ height: "9%" }}
            >
              <Tab eventKey="tySku" title="Current Sku List">
                <div
                  id={`agGridTableskuList1`}
                  className="card reference-sku-table-cont"
                  style={{ marginRight: "21px", marginLeft: "21px" }}
                >
                  {this.tabContent(currentSkuTableData, 1, "CurrentSkuList")}
                </div>
              </Tab>

              <Tab eventKey="lySku" title="Previous Sku List">
                <div
                  id={`agGridTableskuList1`}
                  className="card reference-sku-table-cont"
                  style={{ marginRight: "21px", marginLeft: "21px" }}
                >
                  {this.tabContent(previousSkuTableData, 1, "PreviousSkuList")}
                </div>
              </Tab>
            </Tabs>
          </PageLoader>
        </Modal>
      </>
    );
  }
}

export default PipelineViewSkuModal;
