import React, { Component } from "react";
import { connect } from "react-redux";
import "react-dates/lib/css/_datepicker.css";
import MultiSelectAll from "../EventMaster/MultiSelectDropdown";
import ColumnDef from "../../../tables/colDefs/ReportsColDef";
import DataTable from "../../../tables";
import './Reports.css'

import {
  getAgdarReportData, getAdGroupDetails, getThemeDetails, getPriceListDetails, getArticleDetails, getEventIdDetails, getGmmDetails, getBuyerDetails
} from "../../../../redux/actions/reportsAction";


import _, { update } from "lodash";

import { Container, Row, Col } from 'react-bootstrap';
import "react-dates/initialize";
import { InputNumber, Typography, Divider, Select, Button, Table, Input } from 'antd';
import AlertSuccess from "../../../../utils/AlertMessage/alertSuccess";
import { event } from "react-ga";
import AlertDanger from "../../../../utils/AlertMessage/alertDanger";
import { alertTimer } from "../../../../utils/alertTimer";
const { TextArea } = Input;


class AgdarReport extends Component {

  constructor(props) {
    super(props);
    this.state = {
      tableData: {},
      buyerOptions: [],
      uasOptions: [],
      selectedFilters: {},
      typedAdGroupId: [],
      typedBuyerId: [],
      typedThemeId: [],
      typedEventId: [],
      typedPriceList: [],
      typedGmm: [],
      typedArticleId: [],
      isSuccess: false,
      successMessage: '',
      isFailure: false,
      failureMessage: "",
      alretFailure: false,
      alretFailureMessage: null,
      articleSearchKey: null 
    }
  }

  handleTextareaChange = (e, fields) => {
    // const values = e.target.value ? e.target.value.split(',') : [];
    let themeId;
    let values ;
    if(fields === "typedAdGroupId"){
      values = e.target.value
    ? e.target.value
        .split('\n')
        .flatMap(line => line.split(','))
        .map(value => value.trimStart().toUpperCase())
    : [];
    }
    
    else {
      values = e.target.value
  ? e.target.value
      .split('\n')
      .flatMap(line => line.split(','))
      .map(value => value.trim())
  : [];
    }
    
    let updatedValues = {}
    updatedValues[`${fields}`] = values;
    if(fields === "typedThemeId" && values.length > 1){
        this.setState({
          alretFailure: true,
          alretFailureMessage: "Maximum of 1 theme selection is allowed"
        });
        setTimeout(() => {
          this.setState({ alretFailure: false, alretFailureMessage: '' });
        }, alertTimer);
        return true;
    }
    switch (fields) {
      case "typedBuyerId":
        let selectedBuyers = this.props.buyerOptions.filter(obj => {
          if (values.indexOf(String(obj.label)) !== -1) {
            obj['pastedValue'] = true;
            if (obj.hasOwnProperty("dropdownValue")) {
              delete obj["dropdownValue"]
            }
          } else {
            delete obj["pastedValue"]
          }
          return obj;
        }).filter(obj => obj.pastedValue)
        if (this.state.selectedFilters.buyer) {
          const dropdownValues = this.state.selectedFilters.buyer.filter(obj => !obj.hasOwnProperty("pastedValue") && (obj.hasOwnProperty("dropdownValue") && obj.dropdownValue))
          const copiedValues = this.state.selectedFilters.buyer.filter(obj => values.indexOf(String(obj.label)) !== -1 && obj['pastedValue'])
          const buyers = dropdownValues.concat(copiedValues).concat([...selectedBuyers])
          const buyersList = _.uniqBy(buyers, 'label');
          if (buyersList.length == this.props.buyerOptions.length) {
            buyersList.unshift({
              label: "Select all",
              value: "all"
            })
          }
          updatedValues["selectedFilters"] = { ...this.state.selectedFilters, buyer: [...buyersList] }
        } else {
          if (selectedBuyers.length == this.props.buyerOptions.length) {
            selectedBuyers.unshift({
              label: "Select all",
              value: "all"
            })
          }
          updatedValues["selectedFilters"] = { ...this.state.selectedFilters, buyer: selectedBuyers }
        }
        break;
      case "typedEventId":
        let eventId = this.props.eventIdOptions.filter(obj => {
          if (values.indexOf(String(obj.label)) !== -1) {
            obj['pastedValue'] = true;
            if (obj.hasOwnProperty("dropdownValue")) {
              delete obj["dropdownValue"]
            }
          } else {
            delete obj["pastedValue"]
          }
          return obj;
        }).filter(obj => obj.pastedValue)
        if (this.state.selectedFilters.eventId) {
          const dropdownValues = this.state.selectedFilters.eventId.filter(obj => !obj.hasOwnProperty("pastedValue") && (obj.hasOwnProperty("dropdownValue") && obj.dropdownValue))
          const copiedValues = this.state.selectedFilters.eventId.filter(obj => values.indexOf(String(obj.label)) !== -1 && obj['pastedValue'])
          const eventIds = dropdownValues.concat(copiedValues).concat([...eventId])
          const eventIdList = _.uniqBy(eventIds, 'label');
          if (eventIdList.length == this.props.eventIdOptions.length) {
            eventIdList.unshift({
              label: "Select all",
              value: "all"
            })
          }
          updatedValues["selectedFilters"] = { ...this.state.selectedFilters, eventId: [...eventIdList] }
        } else {
          if (eventId.length == this.props.eventIdOptions.length) {
            eventId.unshift({
              label: "Select all",
              value: "all"
            })
          }
          updatedValues["selectedFilters"] = { ...this.state.selectedFilters, eventId: eventId }
        }
        break;

      case "typedAdGroupId":
        let adGroupId = this.props.adGroupOptions.filter(obj => {
          if (values.indexOf(String(obj.label)) !== -1) {
            obj['pastedValue'] = true;
            if (obj.hasOwnProperty("dropdownValue")) {
              delete obj["dropdownValue"]
            }
          } else {
            delete obj["pastedValue"]
          }
          return obj;
        }).filter(obj => obj.pastedValue)
        if (this.state.selectedFilters.adGroupId) {
          const dropdownValues = this.state.selectedFilters.adGroupId.filter(obj => !obj.hasOwnProperty("pastedValue") && (obj.hasOwnProperty("dropdownValue") && obj.dropdownValue))
          const copiedValues = this.state.selectedFilters.adGroupId.filter(obj => values.indexOf(String(obj.label)) !== -1 && obj['pastedValue'])
          const adGroupIds = dropdownValues.concat(copiedValues).concat([...adGroupId])
          const adGroupIdList = _.uniqBy(adGroupIds, 'label');
          if (adGroupIdList.length == this.props.adGroupOptions.length) {
            adGroupIdList.unshift({
              label: "Select all",
              value: "all"
            })
          }
          updatedValues["selectedFilters"] = { ...this.state.selectedFilters, adGroupId: [...adGroupIdList] }
        } else {
          if (adGroupId.length == this.props.adGroupOptions.length) {
            adGroupId.unshift({
              label: "Select all",
              value: "all"
            })
          }
          updatedValues["selectedFilters"] = { ...this.state.selectedFilters, adGroupId: adGroupId }
        }
        break;

      case "typedThemeId":
         themeId = this.props.themeOptions.filter(obj => {
          if (values.indexOf(String(obj.value)) !== -1) {
            obj['pastedValue'] = true;
            if (obj.hasOwnProperty("dropdownValue")) {
              delete obj["dropdownValue"]
            }
          } else {
            delete obj["pastedValue"]
          }
          return obj;
        }).filter(obj => obj.pastedValue)
        if (this.state.selectedFilters.themeId) {

          const dropdownValues = this.state.selectedFilters.themeId.filter(obj => !obj.hasOwnProperty("pastedValue") && (obj.hasOwnProperty("dropdownValue") && obj.dropdownValue))
          const copiedValues = this.state.selectedFilters.themeId.filter(obj => values.indexOf(String(obj.value)) !== -1 && obj['pastedValue'])
          const themeIds = dropdownValues.concat(copiedValues).concat([...themeId])
          const themeIdList = _.uniqBy(themeIds, 'id');
          if (themeIdList.length == this.props.themeOptions.length) {
            themeIdList.unshift({
              label: "Select all",
              value: "all"
            })
          }
          updatedValues["selectedFilters"] = { ...this.state.selectedFilters, themeId: [...themeIdList] }
        } else {
          if (themeId.length == this.props.themeOptions.length) {
            themeId.unshift({
              label: "Select all",
              value: "all"
            })
          }
          updatedValues["selectedFilters"] = { ...this.state.selectedFilters, themeId: themeId }
        }
        break;
      case "typedArticleId":
        let articleId = this.props.articleOptions.filter(obj => {
          if (values.indexOf(String(obj.label)) !== -1) {
            obj['pastedValue'] = true;
            if (obj.hasOwnProperty("dropdownValue")) {
              delete obj["dropdownValue"]
            }
          } else {
            delete obj["pastedValue"]
          }
          return obj;
        }).filter(obj => obj.pastedValue)
        if (this.state.selectedFilters.articleId) {

          const dropdownValues = this.state.selectedFilters.articleId.filter(obj => !obj.hasOwnProperty("pastedValue") && (obj.hasOwnProperty("dropdownValue") && obj.dropdownValue))
          const copiedValues = this.state.selectedFilters.articleId.filter(obj => values.indexOf(String(obj.label)) !== -1 && obj['pastedValue'])
          const articleIds = dropdownValues.concat(copiedValues).concat([...articleId])
          const articleIdList = _.uniqBy(articleIds, 'label');
          if (articleIdList.length == this.props.articleOptions.length && this.props.articleOptions.length != 0) {
            articleIdList.unshift({
              label: "Select all",
              value: "all"
            })
          }
          updatedValues["selectedFilters"] = { ...this.state.selectedFilters, articleId: [...articleIdList] }
        } else {
          if (articleId.length == this.props.articleOptions.length && this.props.articleOptions.length != 0) {
            articleId.unshift({
              label: "Select all",
              value: "all"
            })
          }
          updatedValues["selectedFilters"] = { ...this.state.selectedFilters, articleId: articleId }
        }
        break;

      case "typedGmm":
        let gmmId = this.props.gmmOptions.filter(obj => values.indexOf(String(obj.label)) !== -1)
        if (this.state.selectedFilters.gmmId) {

          const dropdownValues = this.state.selectedFilters.gmmId.filter(obj => !obj.hasOwnProperty("pastedValue") && (obj.hasOwnProperty("dropdownValue") && obj.dropdownValue))
          const copiedValues = this.state.selectedFilters.gmmId.filter(obj => values.indexOf(String(obj.label)) !== -1 && obj['pastedValue'])
          const gmmIds = dropdownValues.concat(copiedValues).concat([...gmmId])
          const gmmIdList = _.uniqBy(gmmIds, 'label');
          if (gmmIdList.length == this.props.gmmOptions.length) {
            gmmIdList.unshift({
              label: "Select all",
              value: "all"
            })
          }
          updatedValues["selectedFilters"] = { ...this.state.selectedFilters, gmmId: [...gmmIdList] }
        } else {
          if (gmmId.length == this.props.gmmOptions.length) {
            gmmId.unshift({
              label: "Select all",
              value: "all"
            })
          }
          updatedValues["selectedFilters"] = { ...this.state.selectedFilters, gmmId: gmmId }
        }
        break;

      case "typedPriceList":
        let priceList = this.props.priceListOptions.filter(obj => values.indexOf(String(obj.label)) !== -1)
        if (this.state.selectedFilters.priceList) {

          const dropdownValues = this.state.selectedFilters.priceList.filter(obj => !obj.hasOwnProperty("pastedValue") && (obj.hasOwnProperty("dropdownValue") && obj.dropdownValue))
          const copiedValues = this.state.selectedFilters.priceList.filter(obj => values.indexOf(String(obj.label)) !== -1 && obj['pastedValue'])
          const priceLists = dropdownValues.concat(copiedValues).concat([...priceList])
          const priceListList = _.uniqBy(priceLists, 'label');
          if (priceListList.length == this.props.priceListOptions.length) {
            priceListList.unshift({
              label: "Select all",
              value: "all"
            })
          }
          updatedValues["selectedFilters"] = { ...this.state.selectedFilters, priceList: [...priceListList] }
        } else {
          if (priceList.length == this.props.priceListOptions.length) {
            priceList.unshift({
              label: "Select all",
              value: "all"
            })
          }
          updatedValues["selectedFilters"] = { ...this.state.selectedFilters, priceList: priceList }
        }

        break;

      default:
        break;
    }
    
    this.setState({ ...updatedValues },async()=>{
      if(fields === "typedThemeId" ){
        await this.props.getArticleDetails({
          "campaign_ids": themeId ? themeId.map(obj=> obj.id) : [],
          // selectedArticleIds: this.state.selectedFilters.articleId ? this.state.selectedFilters.articleId.map(obj=> obj.value) :[],
          // textAreaArticleIds: values
        });
      }

      // if(fields === "typedArticleId" ){
      //   await this.props.getArticleDetails({
      //     "campaign_ids": this.state.selectedFilters.themeId ? this.state.selectedFilters.themeId.map(obj=> obj.id) : [],
      //     selectedArticleIds: this.state.selectedFilters.articleId ? this.state.selectedFilters.articleId.map(obj=> obj.value) :[],
      //     textAreaArticleIds: values
      //   });
      // }
    });
  }


  handleFilterSelectionChange = async(value, key) => {
    const tempSelectedfilters = _.cloneDeep(this.state.selectedFilters);
    tempSelectedfilters[key] = [...value].filter(obj => {
      if (obj.hasOwnProperty("pastedValue")) {
        delete obj.pastedValue;
      }
      obj["dropdownValue"] = true;
      return obj;
    });

    if(key === "themeId" && value.length > 1){
      this.setState({
        alretFailure: true,
        alretFailureMessage: "Maximum of 1 theme selection is allowed"
      });
      setTimeout(() => {
        this.setState({ alretFailure: false, alretFailureMessage: '' });
      }, alertTimer);
      return true;
    }

    this.setState({ selectedFilters: _.cloneDeep(tempSelectedfilters) },async()=>{
      console.log("Values",value, key)
      if(key ==="themeId"){
        this.props.getArticleDetails({
          "campaign_ids": value.map(obj=> obj.id)
        })
      }
      
    })
  }

  isSelectAllSelected = (data) => {
    return data.some(item => item.value === "all");
  }

  handleFilter = () => {
    const reqObj = {};

    this.props.handleFilter(this.state.selectedFilters)
  }

  handleCancel = () => {

    this.setState({
      selectedFilters: {}
    })
  }


  handleUasdetailChange = (selectedOption) => {
    this.setState({ uasOptions: selectedOption });
  };

  handleGenerateReport = async (type) => {
    const reqObj = {
      themes: [],
      events: [],
      adgroups: [],
      buyers: [],
      articles: [],
      gmm: [],
      price_list: [],
      //uas: this.state.uasOptions === 'Yes' ? ['x'] : ['-']
    };

    if(this.state.uasOptions) {
      if(this.state.uasOptions === 'Yes') {
        reqObj.uas = ['x']
      } else if(this.state.uasOptions === 'No') {
        reqObj.uas = ['-']
      } else {
        reqObj.uas = []
      }
    }

    const convertTypedBuyer = this.state.typedBuyerId.map(data => parseInt(data));
    if (this.state.selectedFilters.buyer) {
      const buyers_value = this.state.selectedFilters.buyer.filter(data => data.value !== "all").map(data => data.value);
      reqObj.buyers = buyers_value.concat(convertTypedBuyer);
    }
    else {
      reqObj.buyers = convertTypedBuyer;
    }

    if (this.state.selectedFilters.themeId) {
      const themes_labels = this.state.selectedFilters.themeId.filter(data => data.value !== "Select all").map(data => data.value);
      reqObj.themes = themes_labels.concat(this.state.typedThemeId);
    }
    else {
      reqObj.themes = this.state.typedThemeId;
    }

    if (this.state.selectedFilters.eventId) {
      const events_value = this.state.selectedFilters.eventId.filter(data => data.value !== "all").map(data => data.value);
      reqObj.events = events_value.concat(this.state.typedEventId);
    }
    else {
      reqObj.events = this.state.typedEventId;
    }


    const convertTypedAdgroup = this.state.typedAdGroupId.map(data => parseInt(data));
    if (this.state.selectedFilters.adGroupId) {
      const adgroups_value = this.state.selectedFilters.adGroupId.filter(data => data.value !== "all").map(data => data.value);
      reqObj.adgroups = adgroups_value.concat(convertTypedAdgroup);
    }
    else {
      reqObj.adgroups = convertTypedAdgroup;
    }


    if (this.state.selectedFilters.articleId) {
      const articles_value = this.state.selectedFilters.articleId.filter(data => data.value !== "all").map(data => data.value);
      reqObj.articles = articles_value.concat(this.state.typedArticleId);
    }
    else {
      reqObj.articles = this.state.typedArticleId;
    }


    if (this.state.selectedFilters.priceList) {
      const price_list_value = this.state.selectedFilters.priceList.filter(data => data.value !== "all").map(data => data.value);
      reqObj.price_list = price_list_value.concat(this.state.typedPriceList);
    }
    else {
      reqObj.price_list = this.state.typedPriceList;
    }

    const convertTypedGmm = this.state.typedGmm.map(data => parseInt(data));
    if (this.state.selectedFilters.gmmId) {
      const gmm_value = this.state.selectedFilters.gmmId.filter(data => data.value !== "all").map(data => data.value);
      reqObj.gmm = gmm_value.concat(convertTypedGmm);
    }
    else {
      reqObj.gmm = convertTypedGmm;
    }

    //  console.log("reqObj",reqObj);
    if (type == 'download') {
      reqObj['type'] = 'download';
    }


    const result = await this.props.getAgdarReportData(reqObj);
    console.log("Result", result)
    if (type != 'download') {
      let tableDataObj = {
        header: ColumnDef.agdarReportHeader,
        defaultColDef: ColumnDef.defaultColDef,
        data: result,
      };

      this.setState({
        tableData: _.cloneDeep(tableDataObj)
      })
    } else {
      if (result.downloadUrl) {
        window.open(result.downloadUrl, '_self');
        this.setState({
          showAlert: true,
          alretMessage: "File Downloaded successfully"
        })
      } else {
        this.setState({
          showAlert: true,
          alretMessage: "No data found"
        })
      }
      setTimeout(() => {
        this.setState({ showAlert: false, alretMessage: '' });
      }, alertTimer);
    }
  }


  customFilterOption =async(option, input)=>{
    if(input){
      console.log(option , input , this.state.selectedFilters)
      if(this.state.articleSearchKey !== input){
        this.setState({
          articleSearchKey: input
        })
        this.props.getArticleDetails({
          "campaign_ids": this.state.selectedFilters.themeId ? this.state.selectedFilters.themeId.map(obj=> obj.id) : [],
          searchKey: input,
          selectedArticleIds: this.state.selectedFilters.articleId ? this.state.selectedFilters.articleId.map(obj=> obj.value) :[]
        })
      }
      
    }
    
  }
  resetSelections = () => {
    // Reset all the selections and filters
    this.setState({
      selectedFilters: {},
      typedAdGroupId: [],
      typedBuyerId: [],
      typedThemeId: [],
      typedEventId: [],
      typedPriceList: [],
      typedGmm: [],
      typedArticleId: [],
      isSuccess: false,
      successMessage: '',
      isFailure: false,
      failureMessage: "",
      alretFailure: false,
      alretFailureMessage: null,
      articleSearchKey: null,
      dateRanges: { 
        first: { from: null, to: null },
        second: { from: null, to: null },
        third: { from: null, to: null },
        fourth: { from: null, to: null },
      },
      tableData: {},
      uasOptions: []
    });
  };
  
  

  render() {
    return (

      <>
        <div className="alertDivs">
          {this.state.alretMessage ? (
            <div>
              <AlertSuccess
                message={this.state.alretMessage}
                show={this.state.showAlert}
              />
            </div>
          ) : null}
          {this.state.alretFailure ? (
          <div>
              <AlertDanger
                message={this.state.alretFailureMessage}
                show={this.state.alretFailure}
              />
            </div>
               ) : null}
        </div>
        <div className="reportContainer">
          <div className="filterContainer">
            <Container fluid>
              <Row style={{ marginTop: '10px', marginBottom: '10px' }}>

              <Col lg>
                  <div className="filterField" >
                    <p className="dropdownLabel">Themes <span style={{ color: "red", marginLeft: "2px" }}>*</span></p>
                    <MultiSelectAll
                      placeholder="Select Themes"
                      options={this.props.themeOptions.length > 0 ? [
                      // {
                      //   label: "Select all",
                      //   value: "all"
                      // },
                      ...this.props.themeOptions] : []}
                      value={this.state.selectedFilters.themeId ? this.state.selectedFilters.themeId : []}
                      onchange={this.handleFilterSelectionChange}
                      keyValue={'themeId'}
                    />
                  </div>
                </Col>
                <Col lg>
                  <p className="summaryPText">Themes</p>
                  <TextArea
                    placeholder="Enter Themes"
                    autoSize={{
                      minRows: 1,
                      maxRows: 10,
                    }}
                    maxLength={1000}
                    value={this.state.typedThemeId.join(',')}
                    onChange={(e) => this.handleTextareaChange(e, 'typedThemeId')}
                  />
                </Col>

                <Col lg>
                  <div className="filterField" >
                    <p className="dropdownLabel">Event ID <span style={{ color: "red", marginLeft: "2px" }}>*</span></p>
                    <MultiSelectAll
                      placeholder="Select Event ID"
                      options={this.props.eventIdOptions.length > 0 ? [{
                        label: "Select all",
                        value: "all"
                      },
                      ...this.props.eventIdOptions]:[]}
                      value={this.state.selectedFilters.eventId ? this.state.selectedFilters.eventId : []}
                      onchange={this.handleFilterSelectionChange}
                      keyValue={'eventId'}
                    />
                  </div>
                </Col>
                <Col lg>
                  <p className="summaryPText">Event ID</p>
                  <TextArea
                    placeholder="Enter Event ID"
                    autoSize={{
                      minRows: 1,
                      maxRows: 10,
                    }}
                    maxLength={1000}
                    value={this.state.typedEventId.join(',')}
                    onChange={(e) => this.handleTextareaChange(e, 'typedEventId')}
                  />
                </Col>
              </Row>


              <Row style={{ marginTop: '10px', marginBottom: '10px' }}>
                <Col lg>
                  <div className="filterField" >
                    <p className="dropdownLabel">Adgroup name</p>
                    <MultiSelectAll
                      placeholder="Select Adgroup name"
                      options={this.props.adGroupOptions.length > 0 ? [
                        {
                          label: "Select all",
                          value: "all"
                        },
                        ...this.props.adGroupOptions] : []}
                      value={this.state.selectedFilters.adGroupId ? this.state.selectedFilters.adGroupId : []}
                      onchange={this.handleFilterSelectionChange}
                      keyValue={'adGroupId'}
                    />
                  </div>
                </Col>

                <Col lg>
                  <p className="summaryPText">Adgroup name</p>
                  <TextArea
                    placeholder="Enter Adgroup name"
                    autoSize={{
                      minRows: 1,
                      maxRows: 10,
                    }}
                    maxLength={1000}
                    value={this.state.typedAdGroupId.join(',')}
                    onChange={(e) => this.handleTextareaChange(e, 'typedAdGroupId')}
                  />
                </Col>

                <Col lg>
                  <div className="filterField" >
                    <p className="dropdownLabel">Buyer ID</p>
                    <MultiSelectAll
                      placeholder="Select Buyer ID"
                      options={this.props.buyerOptions.length > 0 ? [{
                        label: "Select all",
                        value: "all"
                      },
                      ...this.props.buyerOptions] : []}
                      value={this.state.selectedFilters.buyer ? this.state.selectedFilters.buyer : []}
                      onchange={this.handleFilterSelectionChange}
                      keyValue={'buyer'}
                    />
                  </div>
                </Col>

                <Col lg>
                  <p className="summaryPText">Buyer ID</p>
                  <TextArea
                    placeholder="Enter Buyer ID"
                    autoSize={{
                      minRows: 1,
                      maxRows: 10,
                    }}
                    maxLength={1000}
                    value={this.state.typedBuyerId.join(',')}
                    onChange={(e) => this.handleTextareaChange(e, 'typedBuyerId')}
                  />
                </Col>
              </Row>

              <Row style={{ marginTop: '10px', marginBottom: '10px' }}>
                <Col lg>
                  <div className="filterField" >
                    <p className="dropdownLabel">Article ID</p>
                    <MultiSelectAll
                      placeholder="Select Article ID"
                      options={this.props.articleOptions.length > 0 ? [{
                        label: "Select all",
                        value: "all"
                      },
                      ...this.props.articleOptions]: []}
                      value={this.state.selectedFilters.articleId ? this.state.selectedFilters.articleId : []}
                      onchange={this.handleFilterSelectionChange}
                      // filterOption={this.customFilterOption}
                      keyValue={'articleId'}
                    />
                  </div>
                </Col>

                <Col lg>
                  <p className="summaryPText">Article ID</p>
                  <TextArea
                    placeholder="Enter Article ID"
                    autoSize={{
                      minRows: 1,
                      maxRows: 10,
                    }}
                    maxLength={1000}
                    value={this.state.typedArticleId.join(',')}
                    onChange={(e) => this.handleTextareaChange(e, 'typedArticleId')}
                  />
                </Col>

                <Col lg>
                  <div className="filterField" >
                    <p className="dropdownLabel">GMM ID</p>
                    <MultiSelectAll
                      placeholder="Select GMM ID"
                      options={this.props.gmmOptions.length > 0 ? [{
                        label: "Select all",
                        value: "all"
                      },
                      ...this.props.gmmOptions]:[]}
                      value={this.state.selectedFilters.gmmId ? this.state.selectedFilters.gmmId : []}
                      onchange={this.handleFilterSelectionChange}
                      keyValue={'gmmId'}
                    />
                  </div>
                </Col>
                <Col lg>
                  <p className="summaryPText">GMM ID</p>
                  <TextArea
                    placeholder="Enter GMM ID"
                    autoSize={{
                      minRows: 1,
                      maxRows: 10,
                    }}
                    maxLength={1000}
                    value={this.state.typedGmm.join(',')}
                    onChange={(e) => this.handleTextareaChange(e, 'typedGmm')}
                  />
                </Col>
              </Row>

              <Row style={{ marginTop: '10px', marginBottom: '10px' }}>
                <Col lg={3}>
                  <div className="filterField" >
                    <p className="dropdownLabel">Price List</p>
                    <MultiSelectAll
                      placeholder="Select Price List"
                      options={this.props.priceListOptions.length > 0 ? [{
                        label: "Select all",
                        value: "all"
                      },
                      ...this.props.priceListOptions]:[]}
                      value={this.state.selectedFilters.priceList ? this.state.selectedFilters.priceList : []}
                      onchange={this.handleFilterSelectionChange}
                      keyValue={'priceList'}
                    />
                  </div>
                </Col>
                <Col lg={3}>
                  <p className="summaryPText">Price List</p>
                  <TextArea
                    placeholder="Enter Price List"
                    autoSize={{
                      minRows: 1,
                      maxRows: 10,
                    }}
                    maxLength={1000}
                    value={this.state.typedPriceList.join(',')}
                    onChange={(e) => this.handleTextareaChange(e, 'typedPriceList')}
                  />
                </Col>

                <Col lg={3}>
                  <div className="filterField" >
                    <p className="dropdownLabel">UAS</p>
                    <Select
                      placeholder="Select UAS"
                      value={this.state.uasOptions}
                      style={{
                        width: '100%',
                      }}
                      onChange={this.handleUasdetailChange}
                      options={[
                        { label: 'Yes', value: 'Yes' },
                        { label: 'No', value: 'No' },
                      ]}
                    />
                  </div>
                </Col>
              </Row>


              <Row style={{ marginTop: '10px', marginBottom: '10px' }}>
                <Col lg className="d-flex justify-content-end">
                  <div className="filterBtnContainer">
                  <Button
                      type="primary"
                      onClick={this.resetSelections}
                      style={{ marginLeft: '10px' }}
                      disabled={!(
                        (this.state.selectedFilters.themeId && this.state.selectedFilters.themeId.length > 0) ||
                        (this.state.selectedFilters.eventId && this.state.selectedFilters.eventId.length > 0) ||
                        (this.state.selectedFilters.adGroupId && this.state.selectedFilters.adGroupId.length > 0) ||
                        (this.state.selectedFilters.buyer && this.state.selectedFilters.buyer.length > 0) ||
                        (this.state.selectedFilters.articleId && this.state.selectedFilters.articleId.length > 0) ||
                        (this.state.selectedFilters.gmmId && this.state.selectedFilters.gmmId.length > 0) ||
                        (this.state.selectedFilters.priceList && this.state.selectedFilters.priceList.length > 0) ||
                        (this.state.uasOptions && this.state.uasOptions.length > 0) ||
                        (this.state.typedBuyerId.length > 0) || (this.state.typedThemeId.length > 0) ||
                        (this.state.typedEventId.length > 0) || (this.state.typedAdGroupId.length > 0) || 
                        (this.state.typedArticleId.length > 0) || (this.state.typedGmm.length > 0) || (this.state.typedPriceList.length > 0)
                      )}
                  >
                    Reset
                  </Button>

                    <Button
                      type="primary"
                      onClick={() => this.handleGenerateReport('download')}
                      className="downloadBtn"
                      disabled={!(
                        (this.state.selectedFilters.themeId && this.state.selectedFilters.themeId.length > 0) ||
                        (this.state.selectedFilters.eventId && this.state.selectedFilters.eventId.length > 0) ||
                        (this.state.typedBuyerId.length > 0) || (this.state.typedThemeId.length > 0) ||
                        (this.state.typedEventId.length > 0) || (this.state.typedAdGroupId.length > 0) 
                      )}
                    >
                      Download <i className="fa fa-download" aria-hidden="true" />
                    </Button>
                    <Button
                      type="primary"
                      onClick={() => this.handleGenerateReport('generate')}
                      disabled={!(
                        (this.state.selectedFilters.themeId && this.state.selectedFilters.themeId.length > 0) ||
                        (this.state.selectedFilters.eventId && this.state.selectedFilters.eventId.length > 0) ||
                        (this.state.typedBuyerId.length > 0) || (this.state.typedThemeId.length > 0) ||
                        (this.state.typedEventId.length > 0) || (this.state.typedAdGroupId.length > 0) )}
                    >
                      Generate
                    </Button>
                  </div>
                </Col>
              </Row>

            </Container>
          </div>

        </div>
        <hr style={{ height: '1px', borderWidth: '0', backgroundColor: '#d3d3d3' }} />
        <div>

          {
            this.state.tableData.data &&
            <div>
              <DataTable
                tableData={this.state.tableData}
                suppressRowClickSelection={true}
              />
            </div>
          }
        </div>
      </>
    )
  }
};

const mapStateToProps = (state) => {
  return {
    // loader: state.eventMaster.loader,
    buyerOptions: state.reports.buyerOptions,
    adGroupOptions: state.reports.adGroupOptions,
    themeOptions: state.reports.themeOptions,
    articleOptions: state.reports.articleOptions,
    gmmOptions: state.reports.gmmOptions,
    priceListOptions: state.reports.priceListOptions,
    eventIdOptions: state.reports.eventIdOptions
  };
};

export default connect(
  mapStateToProps,
  {
    getBuyerDetails,
    getAdGroupDetails,
    getArticleDetails,
    getGmmDetails,
    getEventIdDetails,
    getThemeDetails,
    getPriceListDetails,
    getAgdarReportData
  }
)(AgdarReport);